import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardImplementation } from '../../dashboard.implementation';
import { ScbService } from '@data/services/scb/scb.service';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ScbDashboardTranslations } from './scb.translation';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-scb',
  templateUrl: './scb.component.html',
})
export class DashboardSCBComponent implements OnInit, OnDestroy {
  accountID = '';
  researchList: { [klass: string]: any }[] = [];

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    public dbI: DashboardImplementation,
    private scbService: ScbService,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: ScbDashboardTranslations
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    if (sessionStorage.getItem('aid')) {
      this.accountID = sessionStorage.getItem('aid');
    }
    this.getResearchList();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getResearchList(): void {
    this.scbService.scbDownloadList().subscribe((result) => {
      if (result && result['result'] && result['result']['downloads'] && result['result']['downloads'].length > 0) {
        this.researchList = result['result']['downloads'].slice(0, 5);
      }
    });
  }
}
