import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '@data/services/user/user.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { heapReset } from 'app/analytics-scripts/heap-scripts';

@Component({
  selector: 'app-trophies',
  templateUrl: './trophies.component.html',
  styleUrls: ['./trophies.component.scss'],
  providers: [UserService],
})
export class TrophiesComponent implements OnInit, OnDestroy {
  user_id: string;
  trophiesList: Array<{ [klass: string]: any }>;

  subscriptiongetTrophies: Subscription;

  constructor(private UserService: UserService, private router: Router, private translateService: TranslateService, public trans: Translations) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit(): void {
    this.user_id = sessionStorage.getItem('uid');
    this.getTrophies();
  }
  ngOnDestroy(): void {
    if (this.subscriptiongetTrophies) {
      this.subscriptiongetTrophies.unsubscribe();
    }
  }

  getTrophies(): void {
    if (this.user_id) {
      const user_id = parseInt(this.user_id, 10);
      this.subscriptiongetTrophies = this.UserService.getTrophies(user_id).subscribe((result) => {
        this.trophiesList = result.result.trophies;
      });
    } else {
      heapReset();
      localStorage.removeItem('AnalyticsSession');
      this.router.navigate(['/login']);
    }
  }
}
