import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
    @Input() colors: Array<string>;
    @Input() modelName: string;
    @Input() currentColor: string;

    showColor: boolean = false;

    toggleColors() {
        this.showColor = !this.showColor;
    }

    setColor(color) {
        this.currentColor = color;
        this.toggleColors();
    }
}
