import { Injectable } from '@angular/core';

@Injectable ()

export class PartnersProfileTranslations {

    public config: any = {
        component: 'partnersProfile',
        display_name: 'Partners Profile',
        description: 'Partners list in profile'
    };

    public trans: any = {
        manage_partners: 'Manage Partners',
		name: 'Name',
		type: 'Type',
		actions: 'Actions',
        owner: 'Owner',
        addPartnerType: 'Add Partner Type',
        description: 'Description (Optional)',
        newPartnerType: 'New Partner Type',
        partnerType: 'Partner Type (Optional)',
        availableParnters: 'Available Partners',
        Update: 'Update',
        Create: 'Create',
        delete: 'Delete',
        add: 'Add',
        areYouSure: 'Are You Sure',
        save: 'Save',
        Cancel: 'Cancel',
        backToProfile: 'Back to Profile',
        editPartner: 'Edit Partner',
        addPartner: 'Add Partner',
        search: 'Search',
        rowLimit: 'Row Limit:',
        settings: 'Settings',
        dashboard: 'Dashboard',
    };
}
