import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { Subscription } from 'rxjs';
import { StyleService } from 'app/style.service';
import { Category } from '@data/services/valueposition/models/category.interface';

@Component({
	selector: 'app-valuemaps',
	templateUrl: './valuemaps.component.html',
	styleUrls: ['./valuemaps.component.scss'],
	providers: [ApiServiceService]
})
export class ValuemapsComponent implements OnInit, OnDestroy {
	data: any;
  
  isChartActive = true;
  isCategoryActive = false;
  isListActive = false;
  solutionCategory: Category;
	subscriptionvalidateUser: Subscription;

	constructor(
		private apiServiceService: ApiServiceService,
		private styleService: StyleService
	) { }

	ngOnInit(): void {
		const loginDetails = {
			action: 'Validate',
			loginPassword: 'demo',
			loginUsername: 'vadivel@test.com'
		};
		
		if (sessionStorage.getItem('vcu') === null) {
			this.subscriptionvalidateUser = this.apiServiceService.validateUser(loginDetails).subscribe((result) => {
				sessionStorage.setItem('uid', result.user_id);
				sessionStorage.setItem('vcu', result.token);
				sessionStorage.setItem('aid', result.account_id);
				sessionStorage.setItem('features', result.features);
				this.styleService.updateStyle2022();
				sessionStorage.setItem('rid', result.role_type_id);
				sessionStorage.setItem('utype', result.user_type_id);
				sessionStorage.setItem('logo', result.logo_filepath);
			});
		}
	}

  setActiveStatus(chartActive: boolean, categoryActive: boolean, listActive: boolean): void {
    this.isChartActive = chartActive;
    this.isCategoryActive = categoryActive;
    this.isListActive = listActive;
  }

	ngOnDestroy(): void {
		if (this.subscriptionvalidateUser) {
      this.subscriptionvalidateUser.unsubscribe();
    }
	}
}

