import { Injectable } from '@angular/core';

@Injectable()
export class RThresholdTranslations {

    public config = {
        component: 'readThreshold',
        display_name: 'Thresholds View',
		description: 'View an individual Threshold'
    };

    public trans: any = {
        viewThresholds: 'View Thresholds',
        name: 'Name 	',
        min: 'Min 	',
        max: 'Max 	',
        margin: 'Margin (%)',
        addNew: 'Add New',
        edit_translations: 'Edit Translations'
    };
}
