<div class='panel panel-white' style='padding-bottom: 0px;'>
	<div class='panel-heading' style='height: inherit !important; background-color: #f5f5f5;'>
		<h3 class="panel-title">{{trans.Scalers.typical_customers | translate}}</h3>
	</div>
	<div class='panel-body' style='margin-top: 20px; max-height: inherit; overflow-y: inherit !important;'>
		<div class="scalerstab">
			<div class="scalersList col-md-12" *ngIf="!accountScalerShow">
				<div class="row m-b-xxl">
					<div class="col-md-5">
						<p-multiSelect class="keywest_dropdown" [options]="scalers" [(ngModel)]="selectedItems" optionLabel="name"></p-multiSelect>
						<!-- <angular2-multiselect [data]="scalers" [(ngModel)]="selectedItems" [settings]="dropdownSettings">
							<c-item>
								<ng-template let-item="item">
									<label>
										{{item.name}}
										<span *ngIf="item.value"> - ({{item.value}})</span>
									</label>
								</ng-template>
							</c-item>
							<c-badge>
								<ng-template let-item="item">
									<label>
										{{item.name}}
										<span *ngIf="item.value"> - ({{item.value}})</span>
									</label>
								</ng-template>
							</c-badge>
						</angular2-multiselect> -->
					</div>

					<div class="col-md-5">
						<button class="btn btn_customizable marg-h" *ngIf="canEdit" [disabled]="selectedItems.length == 0" (click)="addScalersforEdit()">{{trans.Scalers.add | translate}}</button>
						<button class="btn btn_customizable" *ngIf="canEdit" (click)="toggleScalersCreate()">{{trans.Scalers.create_account_scalers | translate}}</button>
					</div>
				</div>
				<!-- *ngIf="showEditScalersForm" -->
				<div class="row m-b-xxl" class="editScalers">
					<div class="col-md-6">
						<form class="form-horizontal" name="editScalers" #editScalersForm="ngForm">
							<div class="form-group" *ngFor="let item of selectedScalers">
								<label class="col-sm-6 control-label">{{item.name}} <i class="icon-info tooltip-custom" [matTooltip]="item.description" matTooltipPosition= "right"></i></label>
								<div class="col-sm-5">
									<input type="text" class="form-control" placeholder="{{item.name}}" [name]="'scaler-' + item.scale_type_id" [ngModel]="item.value" />
								</div>
								<div class="col-md-1 m-t-xs">
									<i class="icon-trash" role="button" *ngIf="canEdit" (click)="deleteScaler(item.scale_type_id)"></i>
								</div>
							</div>
							<div class="form-group" *ngIf="selectedScalers.length > 0">
								<div class="col-sm-offset-3 col-sm-9">
									<button type="button" class="btn btn_customizable" *ngIf="canEdit" (click)="editScalers(editScalersForm.value)">{{trans.Scalers.save | translate}}</button>
									<button type="button" class="btn btn-light marg-h" *ngIf="canEdit" (click)="resetFormValues(editScalersForm)">{{trans.Scalers.reset | translate}}</button>
									<img class="actionLoader" *ngIf="showActionLoader" src="{{fullImagePath}}" />
								</div>
							</div>
						</form>
					</div>
				</div>

			</div>

			<div class="scalersList col-md-12" *ngIf="accountScalerShow">
				<form class="form-horizontal" name="createAccountScalersForm" #createAccountScalersForm="ngForm">
					<div class="form-group">
						<label class="col-sm-3 control-label">{{trans.Scalers.scaler_name | translate}}</label>
						<div class="col-sm-8">
							<input type="text" class="form-control" placeholder="Scaler Name" name="scalerName" [(ngModel)]="accountScaler.name" required />
						</div>
					</div>
					<div class="form-group">
						<label class="col-sm-3 control-label">{{trans.Scalers.scaler_description | translate}}</label>
						<div class="col-sm-8">
							<input type="text" class="form-control" placeholder="Scaler Description" name="scalerdesc" [(ngModel)]="accountScaler.description" required />
						</div>
					</div>
					<div class="form-group">
						<div class="col-sm-offset-3 col-sm-9">
							<button type="button" class="btn btn_customizable" (click)="createAccountScalers()" [disabled]="createAccountScalersForm.invalid">{{trans.Scalers.create | translate}}</button>
							<button type="button" class="btn btn-light marg-h" (click)="toggleScalersCreate()">{{trans.Scalers.cancel | translate}}</button>
							<img class="actionLoader" *ngIf="showActionLoader" src="{{fullImagePath}}" />
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>