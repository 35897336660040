import { Injectable } from '@angular/core';

@Injectable()
export class EditTranslationTranslations {
  public config: any = {
    component: 'editTranslations',
    display_name: 'Edit Translations Box',
    description: 'Popup to edit translations',
  };

  public trans: any = {
    'currentLanguage': 'Current Language:',
    'original': 'Original:',
    'translate': 'Translate',
    'English': 'English',
    'English UK': 'English UK',
    'Spanish': 'Spanish',
    'French': 'French',
    'Chinese': 'Chinese',
    'Portuguese': 'Portuguese',
    'German': 'German',
    'Japanese': 'Japanese',
    'Korean': 'Korean',
    'Italian': 'Italian',
  };
}
