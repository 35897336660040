import { Component, OnDestroy, OnInit } from '@angular/core';
import { Model } from '@data/services/account/models/model.interface';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardImplementation } from '../../dashboard.implementation';
import { SolutionDashboardTranslation } from './solution.translation';

@Component({
  selector: 'app-dashboard-solution',
  templateUrl: './solution.component.html',
})
export class DashboardSolutionComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  showTranslate = false;
  modelsList: Model[] = [];
  loading = false;
  feature38 = false;
  style2022$: Observable<boolean>;
  columns:Array<{field: string; header: string, sortField: string}> = [];

  constructor(
    public dbI: DashboardImplementation,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: SolutionDashboardTranslation,
    private solutionService: SolutionService,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.feature38 = this.commonService.checkFeature('38');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
         this.columns = [
          {
            field: 'models_name',
            header: '',
            sortField: ''
          },
          {
            field: 'models_timeused',
            header: '',
            sortField: ''
          }
         ];
      });
      
  }

  getData(): void {
    this.loading = true;
    const searchLimit = '/limit/' + 5 + '/page/' + 1 + '/search/' + 'all' + '/sortby/name/order/asc';
    this.solutionService.getAvailableSolutions(searchLimit).subscribe((response) => {
      if (response.result) {
        this.modelsList = response.result.data;
      }
      this.loading = false;
    });
  }
}
