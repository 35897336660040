 <div class="right product-list" [ngStyle]="{'backgroundColor': toggleProductList ? 'transparent' : '#ffffff'}">
                <div id="product-nav">
                  <img
                    class="icon"
                    src="https://www.xfactor.io/hubfs/dam/images/logos/xf-icon--xdl-dk.svg"
                    alt="Value Execution Logo - Dark"
                    *ngIf="!toggleProductList"
                  />
                  <h3 *ngIf="!toggleProductList">
                View by <span>Product</span>
                  </h3>

                  <button
                    class="hamburger hamburger--collapse"
                    type="button"
                    (click)="showProductList()"
                    #productButton
                  >
                    <span class="hamburger-box">
                      <span class="hamburger-inner"></span>
                    </span>
                  </button>
                  <div class="product-list__overlay" #productOverlay 
                    *ngIf="toggleProductList" 
                    [ngStyle]="{'backgroundColor': toggleProductList ? '#ffffff' : 'transparent'}"
                    [class.is-active]="toggleProductList"
		   >
                    <div class="header">
                      <h4 color="#F5831F !important">
                        XFactor.io <span>Products</span>
                      </h4>
                      <div class="back"
                        (click)="showProductList()"
		      >
                        <img
                          src="https://www.xfactor.io/hubfs/favicon-orange-transparent.png"
                          alt="Back"
                        />
                      </div>
                    </div>
                    <div class="listing-wrap">
                      <a href="{{ xredirect }}/capacity">
                        <div class="product--single">
                          <div class="icon">
                            <img
                              src="https://www.xfactor.io/hubfs/dam/images/logos/xf-logo-platform--sales-capacity.svg"
                              alt="XFactor.io Sales Capacity - Logo"
                            />
                          </div>
                          <div class="description">
                            <span class="num">01</span>
                            <div class="product-name">
                              <span class="name">Sales Capacity</span>
                              <span class="abbr">(XSC)</span>
                            </div>
                          </div>
                        </div>
		</a>
                      <div class="product--single">
                        <div class="icon">
                          <img
                            src="https://www.xfactor.io/hubfs/dam/images/logos/xf-logo-platform--territory-planning.svg"
                            alt="XFactor.io - Territory Planning"
                          />
                        </div>
                        <div class="description">
                          <span class="num">02</span>
                          <div class="product-name">
                            <span class="name">Territory Planning</span>
                            <span class="abbr">(XTP)</span>
                            <div>
                              Coming Soon
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="product--single">
                        <div class="icon">
                          <img
                            src="https://www.xfactor.io/hubfs/dam/images/logos/xf-logo-platform--demand-generation.svg"
                            alt="XFactor.io Demand Generation"
                          />
                        </div>
                        <div class="description">
                          <span class="num">03</span>
                          <div class="product-name">
                            <span class="name">Demand Generation</span>
                            <span class="abbr">(XDG)</span>
                            <div>
                              Coming Soon
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="product--single">
                        <div class="icon">
                          <img
                            src="https://www.xfactor.io/hubfs/dam/images/logos/xf-logo-platform--account-management.svg"
                            alt="XFactor.io Account Management - Logo"
                          />
                        </div>
                        <div class="description">
                          <span class="num">04</span>
                          <div class="product-name">
                            <span class="name">Account Management</span>
                            <span class="abbr">(XAM)</span>
                            <div>
                              Coming Soon
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="product--single">
                        <div class="icon">
                          <img
                            src="https://www.xfactor.io/hubfs/dam/images/logos/xf-logo-platform--brand-awareness.svg"
                            alt="XFactor.io - Brand Awareness"
                          />
                        </div>
                        <div class="description">
                          <span class="num">05</span>
                          <div class="product-name">
                            <span class="name">Brand Awareness</span>
                            <span class="abbr">(XBA)</span>
                            <div>
                              Coming Soon
			    </div>
                          </div>
                        </div>
                      </div>
                      <a
                        href="https://powernetwork.xfactor.io/"
                        target="_blank"
                      >
                        <div class="product--single">
                          <div class="icon">
                            <img
                              src="https://www.xfactor.io/hubfs/dam/images/logos/xf-logo-platform--product-labs.svg"
                              alt="XFactor.io Demand Generation"
                            />
                          </div>
                          <div class="description">
                            <span class="num">06</span>
                            <div class="product-name">
                              <span class="name">X-Force Labs</span>
                              <span class="abbr">(XFL)</span>
                            </div>
                          </div>
                        </div>
                      </a>
                      <div
                        class="product--single"
                      >
                        <div class="icon">
                          <img
                            src="https://www.xfactor.io/hubfs/dam/images/logos/xf-logo-platform--decisionlink.svg"
                            alt="XFactor.io Sales Capacity - Logo"
                          />
                        </div>
                        <div class="description">
                          <span class="num">07</span>
                          <div class="product-name">
                            <span class="name">DecisionLink</span>
                            <span class="abbr">(Value Execution)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
		  <!-- end overlay -->
                </div>
	</div>
  <div class="clearfix" style="height:30px"></div>

