import { Injectable } from '@angular/core';

@Injectable()
export class SalesManagerDashboardTranslations {
  public config: any = {
    component: 'salesManagerDashboard',
    display_name: 'Sales Manager Dashboard',
    description: 'Sales manager component for main dashboard',
  };

  public trans: any = {
    save: 'Save',
    dashboard: 'Dashboard',
    select_custom_date: 'Select a custom date range',
    start_date: 'Start Date',
    end_date: 'End Date',
    search: 'Search by Customer Name',
    user_adoption_share: 'Active User Share',
    last_quarter: 'Last Quarter',
    users: 'Users',
    per_user_vp_created: 'Value Propositions Created per User',
    average: 'Average',
    per_user_vp_edited: 'Value Propositions Edited per User',
    per_user_logins: 'Users Who Have Logged In',
    value_props_created: 'Value Propositions Created',
    value_props_edited: 'Value Propositions Edited',
    logins: 'Users Who Have Logged In',
    logins_org_view: 'Logins',
    vp_edited_created_logins: 'Value Propositions Created, Edited and Logins by Month',
    chart_view: 'Chart View',
    table_view: 'Table View',
    created_by_level: 'Value Propositions Created by Directs',
    edited_by_level: 'Value Propositions Edited by Directs',
    logins_by_level: 'Value Realizer Logins by Directs',
    created_by_level_alt: 'Value Propositions Created by Level',
    edited_by_level_alt: 'Value Propositions Edited by Level',
    logins_by_level_alt: 'Value Realizer Logins by Level',
    no_logins: 'Users with No Logins',
    no_vps: 'Users with No Value Propositions Created',
    logins_by_vp_created: 'Value Propositions Created',
    logins_by_vp_modified: 'Value Propositions Edited',
    logins_by_month: 'Users Who Have Logged In',
    no_edits: 'Users with No Value Propositions Edited',
    company_name: 'Customer Name',
    totalbenefits_fmt: 'Projected Benefits',
    totalcosts_fmt: 'Project Costs',
    created: 'Created',
    value_props: 'Value Propositions',
    modified: 'Edited',
    valuetovm: 'Value to VMWare',
    vp_edited_created_logins_alt: 'Value Props Created, Edited and Logins',
    status_count: 'Status count by phase',
    status_percent: 'Status % by phase',
    status_summary: 'Opportunity status summary',
    datePickerPlaceholder: 'Select a date or range from the date picker',
    dateRangeLabel: 'Select your Range:',
    panelTooltip: 'The number of value propositions edited per user for your organization in the selected time period.',
    panelChartTooltip: 'List of users from your organization who have not created a value proposition in the selected time period.',
    panelTooltipV2: 'If the current quarter is selected, “Active Users” are those that created a value proposition in the last 90 days. If any other time period is selected, “Active Users” are those that created a business case during the chosen time period. The denominator is a count of all users in your organization in the WVR.'
  };
}
