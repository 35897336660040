import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rangePipe'
})
export class RangePipe implements PipeTransform {
    
	transform(array: Array<any>, start: number = 1, limit: number = 3, show: number = 3): Array<any> {
    if (!array) { return;}
		if (!start) { return array.slice(0, 3); }
		start = start - 1;
		if (start < 0) { start = 0;}
		if (start + limit > array.length) {
			return array.slice(-show);
		}
		return array.slice(start, start + show);
    }

}
