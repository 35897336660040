<div class="row">
	<div class="col-md-12">
		<div class="form-group">
			<form class="" name="CompanySearchForm" #CompanySearchForm="ngForm" (ngSubmit)="getRecentSearchComp()">
				<div class='input-group'>
					<input type="text" class="form-control" name="companyname" id="companyname" (keyup.enter)='getRecentSearchComp()' [placeholder]="trans.trans.search_for_a_company.value" [(ngModel)]="companyname">
					<div class='input-group-addon' *ngIf="!showActionLoader" (click)="getRecentSearchComp()">
						<i class="fa fa-search"></i>
					</div>
					<div class='input-group-btn' *ngIf="showCompanyAdd">
						<button class="btn btn-primary" (click)="openAddCompany()" [matTooltip]="trans.trans.add_new.value" matTooltipPosition="above"><i class="fa fa-plus"></i>{{trans.trans.add_new.value}}</button>
					</div>
					<div class='input-group-addon' *ngIf="showActionLoader">
						<img class="actionLoader" [src]="fullImagePath" />
					</div>
				</div>
			</form>
		</div>
	</div>

	<div class="col-md-12">
		<div class="table-responsive" [hidden]="companyList">
			<div class="searchTableContainer">
                <p-table [value]="companiesList" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
							<th>{{trans.trans.name.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th>{{trans.trans.account_id.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th>{{trans.trans.revenue.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.revenue" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th>{{trans.trans.employees.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th>{{trans.trans.country.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.country" (callback)="getTranslations()"></app-editTranslation>
							</th>
						</tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-company>
                        <tr class="grid-row company-link">
							<td data-title="Name">
								<a (click)="selectCompany(company.id)" class='clickable'>{{company.name}}</a>
								<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle" style='margin-left: 5px;' [ngbTooltip]="tipContent" placement="right" container="body"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" style="margin-left: 5px;" [ngbTooltip]="tipContent" placement="right" container="body">
									info
								</span>
								<ng-template #tipContent class="toolTipContent">
									<p>
										<strong>{{company.name}} </strong>
									</p>
									<div *ngIf="company.account_defined_id">{{company.account_defined_id}}</div>
									<div *ngIf="company.location_type">{{company.location_type}}</div>
									<div *ngIf="company.country">{{company.country}}</div>
									<div>
										<div *ngIf="company.address1">{{company.address1}}</div>
										<div *ngIf="company.city && company.state && company.zip">{{company.city}}, {{company.state}}
											{{company.zip}}
										</div>
									</div>
								</ng-template>
							</td>
							<td data-title="Account ID">{{company.account_defined_id}}</td>
							<td data-title="Revenue">{{company.revenue_fmt}}(M)</td>
							<td data-title="Employees">{{company.employees_fmt}}</td>
							<td data-title="Country">{{company.country}}</td>
						</tr>
                    </ng-template>
                </p-table>

			</div>

		</div>
		<div *ngIf="companiesNoResult" class="m-t-md col-sm-10 comp_no_data">
			<div class="searchTableContainer text-center">
				<h4>{{trans.trans.no_companies_found.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.no_companies_found" (callback)="getTranslations()"></app-editTranslation>
				</h4>
			</div>
		</div>
	</div>

	<div class="col-md-12" *ngIf="companyDetails && companyDetails.name">
		<h4>{{trans.trans.heres_what_we_know_about_the_account_you_have_selected.value}}
			<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.heres_what_we_know_about_the_account_you_have_selected" (callback)="getTranslations()"></app-editTranslation>
		</h4>
	</div>

	<div class="col-md-12" *ngIf="companyDetails && companyDetails.name">
		<ul class="list-unstyled account-info-wrapper">
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.name.value}}:</div>
				<div class="col-md-8 account-info">{{companyDetails.name}}</div>
			</li>
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.account_id.value}}:</div>
				<div class="col-md-8 account-info">{{companyDetails.account_defined_id}}</div>
			</li>
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.address" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.address.value}}:</div>
				<div class="col-md-8 account-info">{{companyDetails.address1}}</div>
			</li>
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.sector" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.sector.value}}:</div>
				<div class="col-md-8 account-info">{{companyDetails.dl_sector_name}}</div>
			</li>
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.website" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.website.value}}:</div>
				<div class="col-md-8 account-info"><a [href]='companyDetails && companyDetails.url && companyDetails.url.substring(0,4) == "http" ? companyDetails.url : "https://" + companyDetails.url' target='_blank' class='clickable'>{{companyDetails.url}}</a></div>
			</li>
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.revenue" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.revenue.value}}:</div>
				<div class="col-md-8 account-info">{{companyDetails.revenue_fmt}} (M)</div>
			</li>
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.employees.value}}:</div>
				<div class="col-md-8 account-info">{{companyDetails.employees_fmt}}</div>
			</li>
			<li class="row">
				<div class="col-md-4 account-label"><app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.description.value}}</div>
			</li>
			<li class="row">
				<div class="col-md-12 account-label">
					<p>{{companyDetails.synopsis}}</p>
				</div>
			</li>
		</ul>
	</div>
</div>

<p-sidebar position='right' styleClass="p-sidebar-md" [(visible)]="addCompany">
	<app-vp-add-company [modalCreate]="true" (companyCreated)="companyCreated($event)" (enableSave)="enableSave($event)"></app-vp-add-company>
</p-sidebar>
