import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@data/services/user/user.service';
import { StyleService } from 'app/style.service';
import { Company } from '@shared/models/company.model';

@Component({
  selector: 'app-bridge',
  template: '',
})
export class BridgeComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  hash: string;
  user_id: string;
  relay: string;
  token: string;
  crm: Company;
  constructor(private route: ActivatedRoute, private userService: UserService, private styleService: StyleService) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.hash = params['hash'];
      this.crm = {};

      const transport = JSON.parse(atob(this.hash));

      this.user_id = transport.user_id;
      this.token = transport.token;
      this.relay = transport.relay;

      if (transport.value_prop_id) {
        this.relay = this.relay + '/' + transport.value_prop_id + '/4';
      }

      const payload = {
        user_id: this.user_id,
        token: this.token,
      };

      this.userService.verifySAML(payload).subscribe((response) => {
        if (response.result.success) {
          const user_data = response.result.userInfo;

          if (transport.company_id) {
            this.crm.company_id = transport.company_id;
          }

          if (transport.opportunity_id) {
            this.crm.opportunity_id = transport.opportunity_id;
          }

          this.store(user_data);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  store(data: any): void {
    const user_id = this.user_id;
    const account_id = data.account_id;
    const role_type_id = data.role_type_id;
    const features = data.features;
    const privs = data.privileges;
    const vcu = this.token;
    const account_name = data.account_name;
    const asset_gen_file = data.asset_gen_file;
    const header_color = data.header_color;
    const language_type_id = data.language_type_id;
    const button_color = data.button_color;
    const button_text_color = data.button_text_color;
    const email = data.email;
    const username = data.username;
    const role_type = data.role_type;

    const chart_colors = data.chart_colors.account_colors;
    if (data.chart_colors.user_colors) {
      const ccolors = [];
      chart_colors.forEach(function (item) {
        ccolors.push(item.color);
      });

      const enc_chart_colors = JSON.stringify(ccolors);

      sessionStorage.setItem('header_color', header_color);
      sessionStorage.setItem('chart_colors', enc_chart_colors);
      sessionStorage.setItem('button_color', button_color);
      sessionStorage.setItem('button_text_color', button_text_color);

      sessionStorage.setItem('asset_gen_file', asset_gen_file);
      sessionStorage.setItem('language_type_id', language_type_id);
      sessionStorage.setItem('account_name', account_name);
      sessionStorage.setItem('vcu', vcu);
      sessionStorage.setItem('uid', user_id);
      sessionStorage.setItem('aid', account_id);

      sessionStorage.setItem('rid', role_type_id);
      sessionStorage.setItem('features', features);
      this.styleService.updateStyle2022();
      sessionStorage.setItem('privileges', privs);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('role', role_type);

      if (this.crm) {
        sessionStorage.setItem('crm', JSON.stringify(this.crm));
      }
      if (this.relay) {
        window.location.href = window.location.origin + '/' + this.relay;
      }
    }
  }
}
