import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { RQuoteTranslations } from './r_quote.transaltion';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { Quote } from 'app/value-map/solution-detail/case-studies/quote/models/quote.model';

@Component({
  selector: 'app-r-quote',
  templateUrl: './r_quote.component.html',
})
export class RQuoteComponent implements OnInit, OnDestroy {
  @Input() quoteView: Quote;
  sidebar_title = 'View Quote';

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(public trans: RQuoteTranslations, private translationService: TranslationsV2Service, private commonService: CommonService) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = false;
    });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
