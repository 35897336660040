import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ConfigurableWorkflow } from '@data/services/workflow/models/workflow.interface';
import { NotificationService } from '@services/notification.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfigurableWorkFlowsTranslations } from './configurable-workflows.translation';

// ** Get new data that was added to configurable workflow endpoint on branch https://vcx-1370.api.value-cloud.com/ **
@Component({
  selector: 'app-configurable-workflows',
  templateUrl: './configurable-workflows.component.html',
  styleUrls: ['./configurable-workflows.component.scss']
})
export class ConfigurableWorkflowsComponent implements OnInit, OnDestroy {
  public breadcrumbKeyValues: Breadcrumbs;
  public columns: Array<{ field: string; header: string; }> = [];
  public configurableWorkflows: ConfigurableWorkflow[] = [];
  public isOpen = false;
  public workflowTitle: string;
  public workflowSelected: ConfigurableWorkflow;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public trans: ConfigurableWorkFlowsTranslations,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    private configurableWorkflowService: ConfigurableWorkflowService,
    private accountWorkflowService: AccountWorkflowService,
    private notificationService: NotificationService
    ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.getWorkflows();
  }

  public getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.configurableWorkflow.value]: { routerLink: '/configurable-workflows', active: true },
        };
        this.columns = [
          {field: 'name', header: this.trans.trans.name.value},
          {field: 'system_defined', header: this.trans.trans.systemStatus.value},
          {field: 'user_groups', header: this.trans.trans.userGroups.value},
          {field: 'creator', header: this.trans.trans.createdBy.value},
          {field: 'created', header: this.trans.trans.createdDate.value},
          {field: 'active', header: this.trans.trans.status.value},
        ];
      });
  }

  public getWorkflows() {
    this.configurableWorkflowService.getWorkflows()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data: any) => {
      if(data && data.result) {
        // Changing active prop to a bool so that input switches work properly 
        this.configurableWorkflows = data.result.workflow
        .map((workFlow: ConfigurableWorkflow) =>{
          return{...workFlow, active: workFlow.active === '1' ? true : false}
        });
      }
    })
  }

  public onStatusChange(workflow: ConfigurableWorkflow) {
    const workFlowClone = {...workflow};
    workFlowClone.active = workflow.active ? '1' : '0';
     this.configurableWorkflowService.updateTopWorkflow(workFlowClone)
     .pipe(take(1))
     .subscribe((data) => {
      if(data.result.success) {
        this.notificationService.success(this.trans.trans.changeSuccessful.value, false);
      } else {
        this.getWorkflows();
      }
     })
  }

  public onAdd() {
    this.workflowTitle = this.trans.trans.addNewWorkflow.value;
    this.isOpen = true;
    this.workflowSelected = null;
  }

  public onDelete(workflow: ConfigurableWorkflow) {
    this.accountWorkflowService.deleteAccountWorkflow(workflow)
    .pipe(take(1))
    .subscribe((res) => {
      if(res.success) {
        this.notificationService.success(this.trans.trans.changeSuccessful.value, false);
        this.configurableWorkflows = this.configurableWorkflows.filter(w => w.id !== workflow.id);
      }
    })
  } 

  public onEdit(workflow: ConfigurableWorkflow) {
    this.workflowTitle = this.trans.trans.editWorkflow.value;
    this.isOpen = true;
    this.workflowSelected = workflow;
  } 

  public onClone(workflow: ConfigurableWorkflow) {
    this.configurableWorkflowService.cloneWorkflow(workflow.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if(res) {
          this.notificationService.success(this.trans.trans.changeSuccessful.value, false);
          this.getWorkflows();
        }
      })
  } 

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onHide(event: boolean): void {
    this.isOpen = event;
  }

}
