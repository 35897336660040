<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{trans.trans.viewCost.value}}
	</div>

	<div class='sidebar_container'>
		<div class='keywest_panel'>
			<div class='row'>
				<div class="col-xs-12">
					<label for="name" class="sidebar_label_readonly">Description</label><br>
					{{costFormObj.description}}
				</div>
			</div>
			<div class='row'>
				<div class="col-xs-12">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.costType.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.costType" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{costFormObj.name}}
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-12">
					<label for="alterEgo" class="sidebar_label_readonly">{{trans.trans.accrue.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.accrue" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{costFormObj.accrual_type_id}}
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-12">
					<label for="name" class="sidebar_label_readonly"> {{trans.trans.unitPrice.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.unitPrice" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{costFormObj.cost}}
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-12">
					<label class="sidebar_label_readonly">Factor</label><br>
					{{costFormObj.scale_type_name}}
				</div>
			</div>
			<!-- {{costFormObj.scale_type_id}} -->
			<div *ngIf="costFormObj.scale_type_id && costFormObj.scale_type_id == '2'">
				<div class='row'>
					<div class='col-xs-1'>&nbsp;</div>
					<div class="col-md-4">
						<label for="name" class="sidebar_label_readonly">Scales How?</label><br>
						{{costFormObj.scales_how_name}}
					</div>
					<div class='col-xs-4' *ngIf="costFormObj.scales_how == '2'">
						<label for="name" class="sidebar_label_readonly">Ratio</label><br>
						{{costFormObj.ratio}}
					</div>
					<div class='col-xs-4' *ngIf="costFormObj.scales_how == '3'">
						<label for="name" class="sidebar_label_readonly">Formula</label><br>
						{{costFormObj.formula}}
					</div>

				</div>
			</div>


			<div class='row'>
				<div class="col-xs-12">
					<label for="expense_type_id" class='sidebar_label_readonly'>{{trans.trans.answerBaseline.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.answerBaseline" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{costFormObj.cost_baseline_answer}}
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-12">
					<label for="cost_category_type_id" class='sidebar_label_readonly'>{{trans.trans.costCategory.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.costCategory" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{costFormObj.cost_category_type_id}}
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-12">
					<label for="expense_type_id" class='sidebar_label_readonly'>{{trans.trans.expenseType.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.expenseType" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{costFormObj.company_field_name}}
				</div>
			</div>

			<div class='row' *ngIf="situationsTied.length > 0">
				<div class="col-xs-12">
					<label for="" class='sidebar_label_readonly'>{{trans.trans.situations.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.situations" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					<ul>
						<li *ngFor="let situation of situationsTied">
							{{situation.name}}
						</li>
					</ul>
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-12">
					<label class='sidebar_label_readonly'>{{trans.trans.includedInValueProp.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.includedInValueProp" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{costFormObj.costInValueprop ? trans.trans.yes.value : trans.trans.no.value}}
				</div>
			</div>
		</div>
	</div>
</div>