import { Injectable } from '@angular/core';

@Injectable()

export class ValuePropTranslations {

	public config: any = {
		component: 'valuePropList',
		display_name: 'Value Prop',
		description: 'List of value props'
	};

	public trans: any = {
		add: 'Add',
		search: 'Search',
		rowLimit: 'Rowlimit:',
		rowLimits: 'Row Limit:',
		name: 'Name',
		vp_name: 'Value Prop Name',
		models: 'Model(s)',
		year1: 'Year 1 Benefits',
		total_benefits: 'Total Benefits',
		roi: 'ROI',
		payback: 'Payback Period',
		yourValueProps: 'Your Value Props',
		valueProp: 'Your Recent Business Cases / Value Assessments',
		Delete: 'Delete',
		chooseOptionToContinue: 'Choose Option to Continue',
		custom: 'Custom',
		dealdesk: 'Deal Desk',
		guidedDiscovery: 'Guided Discovery',
		masterValueProp: 'Master Business Case / Value Assessment',
		quickCreate: 'Quick Create',
		start: 'Start',
		typical: 'Typical',
		all: 'All',
		mine: 'Mine',
		sharedMe: 'Shared with me',
		company_name: 'Company',
		total_benefits_fmt: 'Benefits',
		total_costs_fmt: 'Total Costs',
		vp_creator_name: 'Creator',
		created: 'Created On',
		status: 'Status',
		ch_add_value_prop: 'Add Value Prop',
		stepByStep: 'Step by Step (New)',
		traditionalCreate: 'Traditional Create (Old)',
		addVPOptionTitle: 'Business Case Create Options',
		revenue: "Value to",
		value_to_us_fmt: "Value to",
		clone: "Clone",
		share: "Share",
		dashboard: 'Dashboard',
		cases: 'Cases',
		addNew: 'Add New',
		areYouSure: 'Are you sure?',
		sharedWithMe: 'Shared with me',
		unsort: 'Reset Sort',
		benefits_total: 'Benefits',
		costs_total: 'Costs',
		created_on: 'Created On',
		creator: 'Creator',
    noResults: 'No results for ',
    noRecords: 'Click Add New to Create a Business Case',
		showUnfinished: 'Show unfinished web calculator cases?',
	};
}
