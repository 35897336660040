<div class="modal-header">
  <h4 class="modal-title">{{ trans.release_to_partners.release_to_partners | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body clearfix">
  <div class="realeaseToPartners">
    <div class="control-group m-b-sm">
      <div class="controls radio-control" *ngFor="let releaseType of releaseTypes">
        <label *ngIf="releaseType.id != '1'">
          <input type="radio" name="releaseType" (change)="selectPartnerTypes(releaseType.id)" />&nbsp;&nbsp;
          <span>{{ releaseType.name }}</span>
        </label>
      </div>
    </div>
  </div>

  <div class="col-md-12 impactBusinessWrapper" *ngIf="releaseTypeId == '3' || releaseTypeId == '4'">
    <div class="form-group">
      <div class="impactBusiness">
        <label for="">{{ trans.release_to_partners.partners | translate }}</label>
      </div>
      <div class="dropdown impactList">
        <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">+</button>
        <ul class="dropdown-menu">
          <li *ngFor="let partner of partnerTypeList">
            <div class="checkbox custom-checkbox">
              <label>
                {{ partner.PartnerName }}
                <input type="checkbox" [(ngModel)]="partner.selectedPartner" />
                <span class="check"></span>
              </label>
            </div>
          </li>
          <li *ngIf="!partnerTypeList.length">
            <label class="noDataFoundLabel">{{ trans.release_to_partners.no_partners_found | translate }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="table-responsive col-sm-12">
    <div class="searchTableContainer impactAreaContainer">
      <table class="table custom-table">
        <tbody>
          <tr class="grid-row" *ngFor="let partner of partnerTypeList">
            <td *ngIf="partner.selectedPartner">
              {{ partner.PartnerName }}
              <div class="pull-right" (click)="partner.selectedPartner = !partner.selectedPartner">&times;</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <button
    type="submit"
    class="btn btn-primary m-t-xs m-b-xs"
    (click)="realeaseToPartners()"
    [disabled]="releaseTypeId == '' || (partnerTypeList.length == 0 && releaseTypeId != '2')"
  >
    {{ trans.General.save | translate }}
  </button>
</div>
