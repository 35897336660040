import { Injectable } from '@angular/core';

@Injectable()
export class HelpTooltipValueRealizedTranslations {
  public config: any = {
    component: 'helpTooltipValueRealizedComponent',
    display_name: 'Help Tooltip Value Realized Component',
    description: 'Help Tooltip for Value Realized section',
  };

  public trans: any = {
    tooltipActions: 'Actions which can be taken from Value Realized include:',
    tooltipActions1: 'Select account for Creating / Viewing a Tracker',
    tooltipActions2: 'Select Value Proposition to use as a basis for your Tracker',
    tooltipActions3: 'Configure / View Tracker Baselines',
    tooltipActions4: 'View Trackers for an account',
    tooltipActions5: 'Create / View Measurement Data for a Tracker',
    tooltipActions6: 'Review Expected vs Achieved for a Tracker',
    needHelp: 'Need Help?',
  };
}
