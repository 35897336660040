import { Injectable } from '@angular/core';

@Injectable ()

export class DeliverableVpDashTranslations {

    public config: any = {
        component: 'deliverableVpDash',
        display_name: 'Value Prop Final Deliverable',
        description: 'Final deliverable component in value prop'
    };

    public trans: any = {
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        uploadAssets: 'Upload Asset',
        name: 'Name',
        final_customer_deliverable: 'Final Customer Deliverable',
        file: 'File',
        filename: 'FileName',
        description: 'Description',
        upload: 'Upload',
        no_deliverables_available: 'No Deliverables available',
        edit_flyout_title: 'Edit Asset',
        upload_flyout_title: 'Upload Asset',
        delete_success_message: 'Final Deliverable succesfully deleted.',
        delete_error_message: 'Final Deliverable could not be deleted.',
        edit_success_message: 'Final Deliverable succesfully edited.',
        edit_error_message: 'Final Deliverable could not be edited.',
        noname_error_message: 'File name can\'t be empty.'
    };
}
