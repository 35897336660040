import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader } from '@ngx-translate/core';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TranslationsV2Component } from 'app/account/translationsv2/translationsv2.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ApplicationPipesModule } from '../../../../pipe/pipe.module';
import { BenefitsMiniModule } from '../../benefits/benefits-mini/mini.module';
import { BenefitsMiniV2Module } from '../../benefits_v2/views/mini/mini.module';
import { ScratchPadModule } from '../../benefits_v2/views/scratchpad/scratchpad.module';
import { AssumptionsFastFillComponent } from './assumptions.component';
import { DefaultTranslations } from './assumptions.translation';
import {DropdownModule} from 'primeng/dropdown';
import { ResultsAnalysisModule } from '@shared_components/results-analysis/results-analysis.module';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';
import { MatTooltipModule } from '@angular/material/tooltip';



export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		CurrencyMaskModule,
		ScratchPadModule,
		BenefitsMiniV2Module,
		SliderModule,
    MatTooltipModule,
		BenefitsMiniModule,
		OverlayPanelModule,
		TableModule,
		MenubarModule,
		ApplicationPipesModule,
		SidebarModule,
		CommonModule,
		NgbModule,
		EditTranslationsModule,
		FormsModule,
		ProgressBarModule,
		DropdownModule,
		ResultsAnalysisModule,
    ValuePropFlowButtonsModule,
	],
	declarations: [
		AssumptionsFastFillComponent,

	],
	providers: [Translations, DefaultTranslations, TranslationsV2Component],
	exports: [AssumptionsFastFillComponent]
})
export class AssumptionsFastFillModule { }
