<p-treeTable [value]="list">
	<ng-template pTemplate="header">
		<tr>
			<th>{{trans.trans.name.value}}</th>
			<th>{{trans.trans.value.value}}</th>
			<th>{{trans.trans.factor_type.value}}</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
		<tr [ttRow]="rowNode">
			<td>
				<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
				{{rowData.name}}
			</td>
			<td>{{rowData.value}}</td>
			<td>{{rowData.factor_type}}</td>
		</tr>
	</ng-template>
</p-treeTable>

<!-- <pre>
	{{list | json}}
</pre> -->