import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChooseAdventureGuard implements CanActivate {

  constructor( private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const workflows: ConversationWorkflow[] = JSON.parse(sessionStorage.getItem('workflows'));
    if(workflows.length > 1) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
