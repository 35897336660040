<div class="row" *ngIf="style2022$ | async">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10 old-breadcrumb">
	  <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.back_to_profile.value }}</a>
	</div>
  </div>

<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-7">
          <div class="title">
            {{ trans.trans.translations.value
            }}<app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.translations"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>

        <div class="col-xs-5">
          <app-select-language (languageChange)="setLanguage($event)" ></app-select-language>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 20px">
      <div class="col-xs-12" *ngIf="isLoading">
        <p-progressBar class='keywest_progressbar' mode="indeterminate"></p-progressBar>
      </div>
      <div class="col-xs-4">
        <div class="input-group keywest_search" *ngIf="!isLoading">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input type="text" class="form-control" [formControl]="searchField" />
          <span *ngIf="searchField.value !== ''" (click)="clearSearch()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>

      <div class="table-responsive col-sm-12 soln-table" *ngIf="!isLoading">
        <div *ngFor="let comp of filteredComponents">
          <ng-container *ngIf="filterTable(comp) as componentTranslationValues">
            <div *ngIf="(style2022$ | async)" class="row">
              <div class="col-xs-12" *ngIf="componentTranslationValues.length > 0">
                <h3 (click)="comp.expand = !comp.expand" class="clickable dflex">
                  <i class="fa fa-chevron-right" *ngIf="!comp.expand"></i>
                  <i class="fa fa-chevron-down" *ngIf="comp.expand"></i>
                  <div class="dflex f-column">
                    <span class="accordion-title" style="padding-top: 0px">
                      {{ comp.display_name }} {{componentTranslationValues.length}}
                    </span>
                    <span class="margin-v" style="font-size: 14px">{{ comp.description }}</span>
                  </div>
                </h3>
              </div>
            </div>
            <div class="panel panel-white panel-dashed" *ngIf="!(style2022$ | async)">
              <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
                <div class="row">
                  <div class="col-xs-12" style="padding-right: 30px">
                    <table style="table-layout: fixed; width: 100%">
                      <tr>
                        <td width="40px" style="width: 40px !important; vertical-align: top">
                          <ng-container *ngIf="allTranslationsv2Keys !== undefined; else loading">
                            <div (click)="comp.expand = !comp.expand"><i class="fa fa-plus clickable" *ngIf="!comp.expand" style="margin-right: 5px"></i></div>
                            <div (click)="comp.expand = !comp.expand"><i class="fa fa-minus clickable" *ngIf="comp.expand" style="margin-right: 5px"></i></div>
                          </ng-container>
                          <ng-template #loading>
                            <img class="actionLoader pull-right" [src]="fullImagePath" />
                          </ng-template>
                        </td>
                        <td width="100%">
                          <button style="border: none; background: transparent" [disabled]="allTranslationsv2Keys === undefined" (click)="comp.expand = !comp.expand">
                            <span class="title_benefits clickable" style="font-weight: bold; color: #00f">
                              {{ comp.display_name }}
                            </span></button
                          ><br />
                          <small style="font-size: 70%; color: green; margin: 5px">{{ comp.description }}</small>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="keywest_translation_panel" *ngIf="comp.expand && languages">
              <p-table
                #translationsTable
                [value]="componentTranslationValues"
                [rows]="50"
                [scrollable]="true"
                [paginator]="true"
                [columns]="languages"
                [styleClass]="(style2022$ | async) ? 'keywest_table' : ''"
                [tableStyleClass]="(style2022$ | async) ? 'keywest_table' : ''"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 250px">
                      {{ trans.trans.k.value }}
                    </th>
                    <th style="width: 250px">System Label</th>
                    <th *ngFor="let col of languages" style="width: 250px">
                      <span *ngIf="style2022$ | async" [ngClass]="returnLanguageIcon(col.id)" style="margin-right: 10px"></span>
                      <span>{{ col.name }}</span>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                  <tr>
                    <td style="width: 250px; overflow-wrap: break-word">
                      {{ rowData['en']?.key }}
                    </td>
                    <td style="width: 250px; overflow-wrap: break-word">
                      {{ rowData['org']?.val }}
                    </td>
                    <td *ngFor="let col of languages" pEditableColumn style="width: 250px">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div>
                            <span *ngIf="rowData[col.lang]">
                              <input
                                type="text"
                                [class.form-control]="style2022$ | async"
                                [(ngModel)]="rowData[col.lang].val"
                                (focus)="deactivateCDR()"
                                (blur)="activateCDR()"
                                (keydown.Escape)="escapeKeyEvent($event)"
                                (keydown.Enter)="saveEnter($any($event), rowData[col.lang])"
                                (change)="updateTranslationV2(rowData[col.lang])"
                              />
                            </span>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <div class="clickable">
                            {{ rowData[col.lang]?.val}}
                          </div>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
