import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BenefitsComponent } from './benefits.component';
import { BenefitPanelComponent } from './views/panel/benefits-panel.component';
import { BenefitTableComponent } from './views/table/benefits-table.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { ApplicationPipesModule } from '../../../pipe/pipe.module';
import { TableModule } from 'primeng/table';
import { BenefitPanelCreateFlowComponent } from './views/create-flow/benefits-panel-create-flow.component';
import { DLInputSwitchModule } from '@shared_components/primeng_toggle/toggle.module';

import { CheckboxModule } from 'primeng/checkbox';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { BenefitPanelSimpleComponent } from './views/rep-simple/benefits-panel-simple.component';
import { BenefitDetailsModule } from './views/detail/benefit-detail.module';
import { PortalModule } from '@angular/cdk/portal';
import { BenefitsMenuComponent } from './views/menu/menu.component';
import { BenefitsImplementation } from './benefits.implementation';
import { BenefitsPhasingModule } from './views/phasing/phasing.module';
import { BenefitsGridComponent } from './views/grid-view/grid.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { BenefitFullModule } from './views/full/full.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BenefitsSurveyComponent } from './views/survey/survey.component';
import { BenefitsTableTranslations } from './views/table/benefits-table.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsSubtypesTranslations } from './views/sub-types/sub-types.translation';
import { BenefitsImplentationTranslations } from './benefits.translation';
import { BenefitsSimpleTranslations } from './views/rep-simple/benefits-panel-simple.translation';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { RSV2DiscoveryQuestionsModule } from 'app/repv2/views/discovery_questions/questions.module';
import { BenefitPanel2022Component } from './views/benefit-panel-2022/benefit-panel-2022.component';
import { BenefitActionsMenuComponent } from './views/benefit-menu-actions-2022/benefit-menu-actions-2022.component';
import { AddBenefitsModule } from '../add-benefits-component/add-benefits-component.module';
import { ModelAEBenefits } from 'app/value-mapv2/solution-detail/benefits/ae_benefits/ae_benefits.module';
import { BenefitsMenuTranslations } from './views/menu/menu.translation';
import { ResultsAnalysisModule } from '@shared_components/results-analysis/results-analysis.module';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterBenefitTypeByStatusPipe } from './pipes/filter-benefit-type-by-status.pipe';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { HeightCacheModule } from '@shared/height-cache/height-cache.module';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    DLProgressbarModule,
    BenefitFullModule,
    MatTooltipModule,
    DropdownModule,
    PortalModule,
    CheckboxModule,
    EditTranslationsModule,
    DLInputSwitchModule,
    InputSwitchModule,
    TableModule,
    MenubarModule,
    ApplicationPipesModule,
    ProgressBarModule,
    SidebarModule,
    CommonModule,
    NgbModule,
    FormsModule,
    MenuModule,
    AreYouSureModule,
    BenefitDetailsModule,
    BenefitsPhasingModule,
    RSV2DiscoveryQuestionsModule,
    AddBenefitsModule,
    ModelAEBenefits,
    ValuePropFlowButtonsModule,
    ResultsAnalysisModule,
    DragDropModule,
    CommonModule,
    FunctionPipeModule,
    HeightCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  declarations: [
    BenefitsSurveyComponent,
    BenefitsGridComponent,
    BenefitsMenuComponent,
    BenefitPanelCreateFlowComponent,
    BenefitPanelComponent,
    BenefitPanel2022Component,
    BenefitPanelSimpleComponent,
    BenefitTableComponent,
    BenefitsComponent,
    BenefitActionsMenuComponent,
    FilterBenefitTypeByStatusPipe,
  ],
  entryComponents: [
    BenefitsSurveyComponent,
    BenefitsMenuComponent,
    BenefitsGridComponent,
    BenefitPanelSimpleComponent,
    BenefitPanelCreateFlowComponent,
    BenefitPanelComponent,
    BenefitPanel2022Component,
    BenefitTableComponent,
    BenefitsComponent,
    BenefitActionsMenuComponent,
  ],
  providers: [
    BenefitsImplementation,
    BenefitsTableTranslations,
    TranslationsV2Service,
    BenefitsSubtypesTranslations,
    BenefitsImplentationTranslations,
    Translations,
    BenefitsSimpleTranslations,
    BenefitsMenuTranslations,
  ],
  exports: [BenefitsComponent],
})
export class BenefitsV2Module {}
