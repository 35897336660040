import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrackerBenefitNotesComponent } from './tracker-benefit-notes.component';
import { FormsModule } from '@angular/forms';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DLNGErrorDirectiveModule,
        NGFormSaveButtonModule
    ],
    exports: [TrackerBenefitNotesComponent],
    declarations: [TrackerBenefitNotesComponent],
    providers: [],
})
export class TrackerBenefitNotesModule { }
