import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ActivatedRoute } from '@angular/router';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { Subscription, Subject } from 'rxjs';
import { CollateralVpDashTranslations } from './collateral.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { ValueProp } from '@shared/models/value-prop.model';
import { ValuePropCollateral } from '@shared/models/collateral.model';
import { FormatType } from '@shared/models/format-type.model';

interface Collateral {
  asset_id: number;
  format_type_id: string;
  name: string;
  description: string;
  url: string;
  value_prop_id: number | string;
}

@Component({
  selector: 'app-dashboard-collateral',
  templateUrl: './collateral.component.html',
  providers: [FactsService],
})
export class CollateralComponent implements OnInit, OnDestroy {
  @Input() hideHeader = false;
  @Input() can_edit = false;
  @Input() showHelp: boolean;
  @Input() valueProp: ValueProp;
  @Input() solutionID: string | number;
  @Input() valuePropId: string | number;
  @Input() tabInit: number;
  @Input() showTranslate = false;

  loadcollateralTable = false;
  collateral: ValuePropCollateral[] = [];
  image_url: string;
  fullImagePath: string;
  fileFormatTypes: FormatType[] = [];
  modalReference: NgbModalRef;
  CollateralModel: Collateral;
  accountSolutionId: number | string;
  asset_id: number;
  format: number;
  name: string;
  description: string;
  url: string;
  id: number;
  collateralId: string;
  typeOfCollateral: string;
  hideActionLoader = true;
  toggleAdd = false;
  locked = false;

  subscriptiongetCollateralList: Subscription;
  subscriptionreadCollateral: Subscription;
  subscriptiongetFileFormatTypes: Subscription;
  subscriptioneditCollateral: Subscription;
  subscriptiondeleteCollateral: Subscription;
  subscriptioncreateCollateralList: Subscription;
  subscriptionupdateCollateral: Subscription;

  ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private solutionService: SolutionService,
    private valuepropService: ValuepropService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private factsService: FactsService,
    private commonService: CommonService,
    private notification: NotificationService,
    public trans: CollateralVpDashTranslations,
    private translationService: TranslationsV2Service
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.locked = +this.valueProp.closed === 1;

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.typeOfCollateral = 'Add';

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => (this.accountSolutionId = params['id']));

    if (!this.accountSolutionId) {
      this.accountSolutionId = this.solutionID;
    }
    this.getCollateralList();
    this.getFileFormats();
  }

  ngOnDestroy() {
    if (this.subscriptiongetCollateralList) {
      this.subscriptiongetCollateralList.unsubscribe();
    }
    if (this.subscriptionreadCollateral) {
      this.subscriptionreadCollateral.unsubscribe();
    }
    if (this.subscriptiongetFileFormatTypes) {
      this.subscriptiongetFileFormatTypes.unsubscribe();
    }
    if (this.subscriptioneditCollateral) {
      this.subscriptioneditCollateral.unsubscribe();
    }
    if (this.subscriptiondeleteCollateral) {
      this.subscriptiondeleteCollateral.unsubscribe();
    }
    if (this.subscriptioncreateCollateralList) {
      this.subscriptioncreateCollateralList.unsubscribe();
    }
    if (this.subscriptionupdateCollateral) {
      this.subscriptionupdateCollateral.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  refreshTranslation() {
    this.getTranslations();
  }

  // Imported from https://www.npmjs.com/package/prepend-http
  prependHttp(url: string, { https = true } = {}) {
    url = url.trim();
    if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
      return url;
    }
    return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  processgetCollateralList(response) {
    if (response.result) {
      this.collateral = response.result;
    } else {
      this.collateral = [];
    }
    this.loadcollateralTable = false;
  }

  getCollateralList() {
    if (this.solutionID) {
      this.subscriptiongetCollateralList = this.solutionService.getCollateralList(this.solutionID).subscribe((response) => {
        this.processgetCollateralList(response);
      });
    } else {
      this.subscriptionreadCollateral = this.valuepropService.readCollateral(this.valuePropId).subscribe((response) => {
        this.processgetCollateralList(response);
      });
    }
  }

  getFileFormats() {
    this.subscriptiongetFileFormatTypes = this.factsService.getFileFormatTypes().subscribe((result) => {
      this.fileFormatTypes = result.result;
    });
  }

  openAddCollateral() {
    this.resetValues();
    this.toggleAdd = !this.toggleAdd;
    this.typeOfCollateral = 'Add';
  }

  editCollateral(collateral: ValuePropCollateral) {
    this.typeOfCollateral = 'Edit';
    this.name = collateral.name;
    this.format = collateral.format_type_id;
    this.url = collateral.url;
    this.description = collateral.description;
    this.id = collateral.id;
    this.toggleAdd = true;
  }

  deleteCollateral() {
    this.hideActionLoader = false;
    this.subscriptiondeleteCollateral = this.solutionService.deleteCollateral(this.collateralId).subscribe(() => {
      this.hideActionLoader = true;
      this.modalReference.close();
      this.getCollateralList();
      this.notification.success('Collaterals deleted successfully', false); // Params {message, islogout}
    });
  }

  resetValues() {
    this.id = 0;
    this.asset_id = 0;
    this.name = '';
    this.format = 0;
    this.description = '';
    this.url = '';
  }

  SaveCollateral(form: NgForm) {
    this.CollateralModel = {
      asset_id: this.id,
      format_type_id: form.value.format,
      name: form.value.name,
      description: form.value.description,
      url: form.value.url,
      value_prop_id: this.valuePropId,
    };

    if (this.typeOfCollateral == 'Add') {
      this.CollateralModel['account_solution_id'] = this.accountSolutionId;
      this.subscriptioncreateCollateralList = this.solutionService.createCollateralList(this.CollateralModel).subscribe(() => {
        this.notification.success('Collateral created successfully', false); // Params {message, islogout}
        this.getCollateralList();
      });
    } else {
      if (this.valuePropId) {
        this.CollateralModel['account_solution_id'] = this.accountSolutionId;
      } else {
        this.CollateralModel['account_solution_id'] = this.accountSolutionId;
      }

      this.subscriptionupdateCollateral = this.solutionService.updateCollateral(this.CollateralModel).subscribe(() => {
        this.notification.success('Collateral updated successfully', false); // Params {message, islogout}
        this.getCollateralList();
      });
    }
    this.toggleAdd = false;
  }

  open(content, id) {
    this.collateralId = id;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }
}
