import { Component, Input, OnInit } from '@angular/core';
import { SettingsLink } from '@data/services/settings/settings.service';

@Component({
  selector: 'app-settings-link-card',
  templateUrl: './settings-link-card.component.html',
  styleUrls: ['./settings-link-card.component.scss']
})
export class SettingsLinkCardComponent implements OnInit {
@Input()link: SettingsLink
  constructor() { }

  ngOnInit() {
  }

}
