import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListDetailTranslations } from './list_detail.translation';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-dl-list-detail',
  templateUrl: 'list_detail.component.html',
})
export class DLListDetailComponent implements OnInit, OnDestroy {
  searchText = '';
  @Input() list: { [klass: string]: any }[] = [];
  filteredList: { [klass: string]: any }[] = [];

  @Output() callback = new EventEmitter();
  @Output () closeCallback = new EventEmitter();

  @Input() selectedList: { [klass: string]: any }[] = [];
  currentSelectedList: { [klass: string]: any }[];
  @Input() type: { [klass: string]: any }[] = [];
  @Input() callbackSave = false;

  @Input() typeKey = 'all';
  typeFilter: string[] = ['all'];

  @Input() key: string;
  @Input() canEdit = false;
  @Input() title = '';

  frst = 0;
  rows = 10;
  rowLimit = 10;
  style2022$: Observable<boolean>;
  ngUnsubscribe$ = new Subject();

  constructor(
    private cd: ChangeDetectorRef, 
    private styleService: StyleService,
    private translationsService: TranslationsV2Service,
    public trans: ListDetailTranslations,
    private commonService: CommonService,
    ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.filteredList = this.list ? cloneDeep(this.list) : [];
    this.currentSelectedList = this.selectedList ? cloneDeep(this.selectedList) : [];

    if (this.currentSelectedList && this.currentSelectedList.length) {
      for (let i = 0; i < this.currentSelectedList.length; i++) {
        const elem = this.currentSelectedList[i];
        const index = this.filteredList.findIndex((x) => x.id == elem[this.key]);
        if (index > -1) {
          this.filteredList[index]['disabled'] = true;
        }
      }
    }
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
			this.getTranslations();
		});
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  filterByText(): void {
    this.filteredList = this.list.filter((el) => el.name.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  addElemToSelected(elem: { [klass: string]: any }): void {
    if (!this.currentSelectedList) {
      this.currentSelectedList = [];
    }
    elem.disabled = true;
    this.currentSelectedList.push(elem);
    if (!this.callbackSave) {
      this.callback.emit(this.currentSelectedList);
    }
  }

  deleteSelectedElem(elem: { [klass: string]: any }, index: number) {
    this.filteredList.forEach((el) => {
      if (el.id === elem.id) {
        el.disabled = false;
      }
    });

    this.currentSelectedList.splice(index, 1);
    if (!this.callbackSave) {
      this.callback.emit(this.currentSelectedList);
    }
  }

  clearSelected(): void {
    this.filteredList.forEach((el) => {
        el.disabled = false;
    });
    this.currentSelectedList = [];
    if (!this.callbackSave) {
      this.callback.emit(this.currentSelectedList);
    }
  }

  funcFilter(event: { value: any }): void {
    this.filteredList = [];
    const list = event.value;

    if (list.includes('all') && list.length === 1) {
      this.filteredList = this.list;
    }

    if (list.length > 1) {
      const index = list.indexOf('all');
      if (index !== -1) {
        list.splice(index, 1);
      }
    }

    this.list.forEach((elem) => {
      list.forEach((l) => {
        if (elem[this.typeKey] == l) {
          this.filteredList.push(elem);
        }
      });
    });
  }

  funcSave(): void {
    this.callback.emit(this.currentSelectedList);
  }

  getTranslations(): void {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationsService.getLanguageAbbr(langId);

		let payload = {
			'account_id': sessionStorage.getItem("aid"),
			'component': this.trans.config.component,
			'lang': langAbbr
		};

		this.translationsService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

  paginate(event: { page: number; first: number }): void {
    this.frst = event.first;
    if (event.page === 0) {
      this.rows = this.rowLimit;
    }
    if (event.page === 1) {
      this.rows = 20;
    }
    if (event.page > 1) {
      this.rows = (event.page + 1) * this.rowLimit;
    }
    this.cd.detectChanges();
  }
}
