import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AssetsValuePrompterComponent } from './assets-value-prompter.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { Translations } from '@data/services/translation/translation.service';
import { FileUploadModule } from 'primeng/fileupload';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { AssetPrompterVpDashTranslations } from './asset-value-prompter.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';


@NgModule({
	imports: [
		SidebarModule,
        FileUploadModule,
        EditTranslationsModule,
        CommonModule,
        FormsModule,
        ApplicationPipesModule
    ],
    declarations: [
        AssetsValuePrompterComponent
    ],
    providers: [
        Translations,
        TranslationsV2Service,
        AssetPrompterVpDashTranslations
    ],
    exports: [AssetsValuePrompterComponent]
})
export class AssetsValuePrompterModule { }
