import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { StyleService } from 'app/style.service';
import { take } from 'rxjs/operators';
import { UserActionItem } from '@shared/models/user-action-item.model';
import { DefaultTranslations } from '../solution-list.translation';
import { Model } from '@data/services/account/models/model.interface';
import { ColumnMeta } from '@shared/models/common.models';
import { Shares, ToShare } from 'app/value-map/solution-list/share/models/share.model';

interface Customer {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  account_name: string;
}

@Component({
  selector: 'app-modelv2-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ModelV2ShareComponent implements OnInit {
  @Input() models: Model;
  @Output() close = new EventEmitter();

  fullImagePath: string;
  image_url: string;
  sharesLoader = false;
  shares: Shares[] = [];
  addShare = false;
  users: Array<{ name: string; id: string }> = [];
  shareRoles: Array<{ name: string; id: string }> = [];
  share: ToShare;
  customer: Customer = {
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    account_name: '',
  };
  type = 'add';
  hideActionLoader = false;
  hideShareActionLoader = false;
  hideDeleteActionLoader = false;
  userShare = true;

  cols: ColumnMeta[] = [];
  strSearch = '';
  style2022 = false;
  items: UserActionItem[] = [];

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private solutionService: SolutionService,
    private styleService: StyleService,
    public trans: DefaultTranslations
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.styleService.style2022.pipe(take(1)).subscribe((style) => (this.style2022 = style));

    this.cols = [
      { field: 'solution_name', header: 'Solution' },
      { field: 'user_name', header: 'Name' },
      { field: 'share_role', header: 'Role' },
    ];

    this.loadShares();
    this.getUsers();
  }

  loadShares(): void {
    this.share = {
      account_solution_id: this.models.id,
      share_to_user_id: '',
      share_role_type_id: this.shareRoles.length ? this.shareRoles[0].id : '',
    };

    const searchLimit = '/' + this.models.id;
    this.sharesLoader = true;
    this.solutionService.getmodelsShares(searchLimit).subscribe((response) => {
      if (response.result) {
        this.shares = response.result;
        this.sharesLoader = false;
      }
    });
  }

  getUsers(): void {
    const account_id = sessionStorage.getItem('aid');
    this.valuepropService.getUsers(account_id).subscribe((response) => {
      if (response.result) {
        this.users = response.result.data;
      }
    });
    this.getRoles();
  }

  getRoles(): void {
    this.valuepropService.getShareRoles().subscribe((response) => {
      if (response.result) {
        this.shareRoles = response.result;
        this.share.share_role_type_id = this.shareRoles.length ? this.shareRoles[0].id : '';
      }
    });
  }

  openAddShare(): void {
    this.addShare = true;
    this.type = 'add';
    this.userShare = true;
    this.share.name = '';
  }

  closeAddShare(): void {
    this.addShare = false;
    this.share.share_to_user_id = '';
    this.share.share_role_type_id = this.shareRoles.length ? this.shareRoles[0].id : '';
  }

  saveShare(): void {
    this.hideShareActionLoader = true;
    if (this.type === 'add') {
      this.solutionService.saveModelsPrivilege(this.share).subscribe((response) => {
        if (response.result) {
          this.loadShares();
          this.closeAddShare();
          this.hideShareActionLoader = false;
        }
      });
    } else if (this.type === 'edit') {
      this.solutionService.updateModelsPrivilege(this.models.id, this.share).subscribe((response) => {
        if (response.result) {
          this.loadShares();
          this.closeAddShare();
          this.hideShareActionLoader = false;
        }
      });
    }
  }

  editPrivilege(share): void {
    this.addShare = true;
    this.type = 'edit';
    this.userShare = false;
    this.share = {
      account_solution_share_id: share.id,
      share_to_user_id: share.user_id,
      share_role_type_id: share.share_role_type_id,
      name: share.user_name,
    };
  }

  deletePrivilege(share): void {
    this.hideDeleteActionLoader = true;
    this.solutionService.deleteModelsPrivilege(share.id).subscribe((response) => {
      if (response.result) {
        this.closeAddShare();
        this.loadShares();
      }
      this.hideDeleteActionLoader = false;
    });
  }

  closeShareFlyout(): void {
    this.close.emit();
  }
}
