import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProfileSubTranslation } from './profile-sub.translation';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { UserInfo } from '@shared/models/user-info.model';

@Component({
  selector: 'app-profile-sub',
  templateUrl: './profile-sub.component.html',
  styleUrls: ['./profile-sub.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileSubComponent implements OnInit, OnDestroy {
  fullImagePath: string;

  @Output() toggle: EventEmitter<null> = new EventEmitter();

  @Input() user: UserInfo;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  style2022$: Observable<boolean>;

  constructor(public trans: ProfileSubTranslation, private translationService: TranslationsV2Service, private commonService: CommonService, private styleService: StyleService) {
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  showProfileFullView() {
    this.toggle.emit();
  }
}
