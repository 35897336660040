import { CustomerValidation, FactorGroup, Unit } from '@data/services/valueposition/models/factor-group.interface';
import { Feedback } from './company.model';
import { DiscoveryQuestion } from './discovery-question.model';
import { FactorValidation } from './factors.models';

export interface ValueProp {
  value_prop_group_name?: string;
  value_prop_group_id?: string;
  models?: string;
  showOverlay?: boolean;
  id?: string;
  company_id?: string;
  user_id?: string;
  name?: string;
  mission_statement?: string;
  wacc?: string;
  roi_range?: string;
  gross_profit?: string;
  baseline_type_id?: string;
  checkbox?: boolean;
  customer_type_id?: string;
  currency_type_id?: string;
  conversion_rate?: string;
  number_format?: string;
  calculator_public_key?: string;
  term?: number;
  deployment_type_id?: string;
  industry_id?: string;
  country_id?: string;
  year_minus5_benefits?: string;
  year_minus4_benefits?: string;
  year_minus3_benefits?: string;
  year_minus2_benefits?: string;
  year_minus1_benefits?: string;
  year1_benefits?: string;
  year2_benefits?: string;
  year3_benefits?: string;
  year4_benefits?: string;
  year5_benefits?: string;
  year6_benefits?: string;
  year7_benefits?: string;
  year8_benefits?: string;
  year9_benefits?: string;
  year10_benefits?: string;
  year_minus5_costs?: string;
  year_minus4_costs?: string;
  year_minus3_costs?: string;
  year_minus2_costs?: string;
  year_minus1_costs?: string;
  year1_costs?: string;
  year2_costs?: string;
  year3_costs?: string;
  year4_costs?: string;
  year5_costs?: string;
  year6_costs?: string;
  year7_costs?: string;
  year8_costs?: string;
  year9_costs?: string;
  year10_costs?: string;
  total_benefits?: string;
  total_costs?: string;
  total_realized?: string;
  total_expected?: string;
  is_slave?: string;
  is_grouped?: string;
  closed?: string | boolean | number;
  init_calc?: string;
  opportunity_id?: string;
  vmw_q_1?: string;
  vmw_q_2?: string;
  language_type_id?: string;
  created?: Date;
  modified?: Date;
  dep_value_prop_id?: string;
  step?: string;
  custom_value_realized_date?: string;
  done?: string;
  scenario_done?: string;
  situation_done?: string;
  scalers_done?: string;
  q2_done?: string;
  final_to_be_counted?: string;
  company_name?: string;
  industry_name?: string;
  customer_type?: string;
  city?: string;
  state?: string;
  metrics?: { [key: string]: string }[];
  has_deployment_schedule?: boolean;
  creators_name?: string;
  creators_email?: string;
  share_role_type_id?: string;
  vp_can_edit?: boolean;
  vp_can_view?: boolean;
  weighted_discount?: string;
  cxo_asset?: number;
  cxo_asset_message?: string;
  has_key_assumptions?: number;
  transactions?: number;
  transactions_annual?: number;
  transactions_annual_p2?: string;
  transactions_annual_fr?: string;
  transactions_annual_fmt?: string;
  benefits_per_transaction?: number;
  benefits_per_transaction_p2?: string;
  benefits_per_transaction_fmt?: string;
  dif_total?: string;
  dif_total_fmt?: string;
  dif_total_p2?: string;
  dif_total_fr?: string;
  share_role_type_name?: string;
  payback?: string;
  payback_raw?: number;
  payback_fmt?: string;
  pb_fmt?: string;
  solutions?: Solution[];
  solutions_display?: string;
  cashflow?: Cashflow;
  npv?: string;
  npv_raw?: number;
  npv_p2?: string;
  npv_fmt?: string;
  year1_benefits_decorated?: string;
  year1_benefits_decorated_fmt?: string;
  year1_benefits_fr?: string;
  year1_benefits_p2?: string;
  total_benefits_raw?: string;
  total_benefits_fmt?: string;
  total_benefits_fr?: string;
  total_benefits_p2?: string;
  total_costs_fmt?: string;
  status?: string;
  total_costs_p2?: string;
  value_to_us_fmt?: string;
  roi?: string;
  roi_decorated?: string;
  roi_fr?: string;
  roi_fmt?: string;
  roi_range_fmt?: string;
  irr?: number;
  value_prop_status?: string;
  vp_status?: string;
  vp_status_comment?: string;
  benefit_count?: number;
  three_month_cost_of_inaction?: number;
  three_month_cost_of_inaction_p2?: string;
  three_month_cost_of_inaction_fmt?: string;
  tco?: number;
  tco_p2?: string;
  tco_fmt?: number;
  csfs?: null;
  assets?: Assets;
  created_via_discovery?: boolean;
  master_value_prop?: MasterValueProp;
  discovery_questions?: DiscoveryQuestion[];
  currency_abbr?: string;
  modified_by?: string;
  last_modified_by_name?: string;
  last_modified_date?: Date;
  benefits_loading?: boolean;
  benefits?: Benefit[];

  value_props?: any;
  q2?: string;
  showAssets?: boolean;
  description?: string;
  account_asset_template_id?: string;
  format?: string;
  valuePropStatus_editable?: boolean;
  cost_of_delay_fmt?: string;
  deleteLoading?: string;
  changeName?: boolean;
  showScalers?: boolean;
  scalers?: any;
  expand?: boolean;
  year1_benefits_fmt?: string;
  net_cashflow_fmt?: string;
  thousands?: string;
  decimal?: string;
}

export interface MasterValueProp {
  id?: string;
  master_value_prop_id?: string;
  name?: string;
  user_id?: string;
  company_id?: string;
  group_type_id?: string;
  status_type_id?: string;
  created?: Date;
  modified?: Date;
  company_name?: string;
  status?: string;
  value_prop_status_type_id?: string;
  is_group?: number;
  vp_creator_name?: string;
  share_role_type_id?: string;
  share_role_type_name?: string;
  total_costs?: number;
  total_costs_fmt?: string;
  total_costs_p2?: string;
  total_costs_fr?: string;
  total_benefits?: number;
  total_benefits_fmt?: string;
  total_benefits_p2?: string;
  total_benefits_fr?: string;
  year1_benefits?: number;
  year1_benefits_fmt?: string;
  year1_benefits_p2?: string;
  year1_benefits_fr?: string;
  year2_benefits?: number;
  year2_benefits_fmt?: string;
  year2_benefits_fr?: string;
  year2_benefits_p2?: string;
  year3_benefits?: number;
  year3_benefits_fmt?: string;
  year3_benefits_fr?: string;
  year3_benefits_p2?: string;
  year1_costs?: number;
  year1_costs_fmt?: string;
  year1_costs_fr?: string;
  year1_costs_p2?: string;
  year2_costs?: number;
  year2_costs_fmt?: string;
  year2_costs_fr?: string;
  year2_costs_p2?: string;
  year3_costs?: number;
  year3_costs_fmt?: string;
  year3_costs_fr?: string;
  year3_costs_p2?: string;
  nb1?: number;
  nb1_fmt?: string;
  nb1_fr?: string;
  nb1_p2?: string;
  nb2?: number;
  nb2_fmt?: string;
  nb2_fr?: string;
  nb2_p2?: string;
  nb3?: number;
  nb3_fmt?: string;
  nb3_fr?: string;
  nb3_p2?: string;
  roi?: string;
  roi_p2?: string;
  roi_fr?: string;
  payback?: string;
  npv?: string;
  npv_raw?: number;
  npv_p2?: string;
  npv_fr?: string;
  tco?: string;
  tco_raw?: number;
  tco_p2?: string;
  tco_fr?: string;
  cost_of_delay?: string;
  cost_of_delay_raw?: number;
  cost_of_delay_p2?: string;
  cost_of_delay_fr?: string;
  benefits_per_transaction?: number;
  benefits_per_transaction_fr?: string;
  benefits_per_transaction_p2?: string;
  benefits_per_transaction_fmt?: string;
  transactions_annual?: number;
  transactions_annual_fmt?: string;
  transactions_annual_fr?: string;
  transactions_annual_p2?: string;
  last_modified_by_name?: string;
  last_modified_date?: Date;
  value_props?: any;
  description?: string;
  format?: string;
  artifact_name?: string;
  account_asset_template_id?: string;
  isCollapsed?: boolean | string;
}

export interface Assets {
  success: boolean;
  message: Message[];
}

export interface Message {
  id: string;
  account_asset_id: string;
  language_type_id: string;
  account_solution_id: string;
  sequence: string;
  name: string;
  description: string;
  template_path: string;
  artifact_name: string;
  default_active: string;
  web_calculator: string;
  pdf: string | number;
  created: Date;
  modified: Date;
  account_asset_template_id: string;
  format_type_id: string;
  format: string;
}

export interface Benefit {
  id: string;
  value_category_id: string;
  name: string;
  description: string;
  is_financial: string;
  fastfill_enabled: string;
  percent_driven: string;
  vc_sequence: string;
  vc_total: string;
  vc_dif_total: string;
  vc_yr1_total: string;
  current_state_1: string;
  current_state_2: string;
  current_state_3: string;
  current_state_4: string;
  current_state_5: string;
  current_state_6: string;
  current_state_7: string;
  current_state_8: string;
  current_state_9: string;
  current_state_10: string;
  current_total: string;
  future_state_1: string;
  future_state_2: string;
  future_state_3: string;
  future_state_4: string;
  future_state_5: string;
  future_state_6: string;
  future_state_7: string;
  future_state_8: string;
  future_state_9: string;
  future_state_10: string;
  future_total: string;
  vc_yr2_total: string;
  vc_yr3_total: string;
  vc_yr4_total: string;
  vc_yr5_total: string;
  vc_yr6_total: string;
  vc_yr7_total: string;
  vc_yr8_total: string;
  vc_yr9_total: string;
  vc_yr10_total: string;
  vc_yr2_sum: string;
  vc_yr3_sum: string;
  vc_yr4_sum: string;
  vc_yr5_sum: string;
  vc_yr6_sum: string;
  vc_yr7_sum: string;
  vc_yr8_sum: string;
  vc_yr9_sum: string;
  vc_yr10_sum: string;
  metrics: Metric[];
  active_count: number;
  na_count: number;
  soft_count: number;
  vc_yr1_total_fmt: string;
  vc_yr1_total_fr: string;
  vc_yr1_total_p2: string;
  vc_total_fmt: string;
  vc_total_fr: string;
  vc_total_p2: string;
  vc_total_percent: string;
  vc_dif_total_fmt: string;
  vc_dif_total_fr: string;
  vc_dif_total_p2: string;
  term: string;
  vc_yr2_total_fmt: string;
  vc_yr2_total_p2: string;
  vc_yr2_sum_fr: string;
  vc_yr2_sum_p2: string;
  vc_yr3_total_fmt: string;
  vc_yr3_total_p2: string;
  vc_yr3_sum_fr: string;
  vc_yr3_sum_p2: string;
  current_state_1_fmt: string;
  current_state_2_fmt: string;
  current_state_3_fmt: string;
  current_state_4_fmt: string;
  current_state_5_fmt: string;
  future_state_1_fmt: string;
  future_state_2_fmt: string;
  future_state_3_fmt: string;
  future_state_4_fmt: string;
  future_state_5_fmt: string;
  color: string;
  alt_metric_name?: string;
  metric_name?: string;
  account_solution_metric_id: string;
  account_solution_id?: string;
  comments?: any[];
  default_na: any;
  situations?: Situation[];
  disabled?: boolean;
  benefitImpValue: any;
  benefitImpType: any;
  improvement?: any;
  improvement_type_id?: string;
  improvement_type_name?: any;
  acc;
}

export interface Phase {
  phase_0: number;
  phase_1: number;
  phase_2: number;
  phase_3: number;
  phase_4: number;
}

export interface Metric {
  id: string;
  value_prop_metric_id: string;
  driver_value_fmt?: string;
  future_effort_fmt?: string;
  driver_value: string;
  impact_conservative: number | string;
  act_impact_conservative: string;
  impact_probable: number | string;
  act_impact_probable: string;
  impact_aggressive: number | string;
  act_impact_aggressive: string;
  impact: number | string;
  financial_value: string;
  value_category_id: string;
  current_effort: number | string;
  term: string;
  driver_factor: string;
  driver_description: string;
  d_precision: string;
  f_precision: string;
  i_precision: string;
  account_solution_metric_id: string;
  account_solution_id: string;
  solution_name: string;
  sequence: string;
  metric_name: string;
  original_metric_name: string;
  alt_metric_name: string;
  value_statement: string;
  metric_note: string;
  metric_note_2: string;
  metric_note_3: string;
  driver_estimate: string;
  driver_override: string;
  driver_baseline_answer: string;
  driver_source_type_id: string;
  driver_display_source_type_id?: string;
  driver_phase_1: string;
  driver_phase_2: string;
  driver_phase_3: string;
  driver_phase_4: string;
  driver_phase_5: string;
  driver_phase_6: string;
  driver_phase_7: string;
  driver_phase_8: string;
  driver_phase_9: string;
  driver_phase_10: string;
  metric_phase_0: string;
  metric_phase_1: string;
  metric_phase_2: string;
  metric_phase_3: string;
  metric_phase_4: string;
  metric_phase_5: string;
  metric_phase_6: string;
  metric_phase_7: string;
  metric_phase_8: string;
  metric_phase_9: string;
  metric_phase_10: string;
  quantity: string;
  deployment_time: string;
  expense_type_id: string;
  description: string;
  scale_type_id: string;
  unique_type_id: string;
  driver_factor_id: string;
  financial_factor_id: string;
  quantified: number | string | boolean;
  soft: number | string | boolean;
  tco_name: string;
  benefit_type_id: string;
  note_2_label: string;
  note_3_label: string;
  delta: string;
  delta1: string;
  delta2: string;
  delta3: string;
  delta4: string;
  delta5: string;
  delta6: string;
  delta7: string;
  delta8: string;
  delta9: string;
  delta10: string;
  unique_type: string;
  unique_description: string;
  impact_type: string;
  driver_factor_vr_name: string;
  financial_factor_vr_name: string;
  improvement_factor_vr_name: string;
  financial_factor: string;
  financial_description: string;
  d_unit_type_id: string;
  f_unit_type_id: string;
  i_unit_type_id: string;
  accrual_type: string;
  impact_override: string;
  impact_source_type_id: string;
  impact_display_source_type_id?: string;
  subtypes_enabled: string;
  vp_specific: string;
  features: string;
  financial_estimate: string;
  financial_override: string;
  financial_baseline_answer: string;
  financial_source_type_id: string;
  financial_display_source_type_id?: string;
  percent_driven: string;
  value_category: string;
  vc_description: string;
  is_financial: string;
  fastfill_enabled: string;
  vc_sequence: string;
  course_correction: string;
  unit_type_id: string;
  abbr: string;
  divisor: string;
  gross_profit: string;
  has_deployment: string;
  scale_type: string;
  scale_description: string;
  straight_scaling: string;
  quantification_type_id: string;
  quantification_type: string;
  quantification_description: string;
  accrual_type_id: string;
  active: string;
  impact_type_id: string;
  improvement_factor_id: string;
  improvement_factor: string;
  improvement_description: string;
  benefits?: string;
  year1_benefits: string | number;
  year2_benefits: string;
  year3_benefits: string;
  year4_benefits: string;
  year5_benefits: string;
  year6_benefits: string;
  year7_benefits: string;
  year8_benefits: string;
  year9_benefits: string;
  year10_benefits: string;
  total_benefits: string | number;
  percent_improvement: string | number;
  cap_metric_name: string;
  year1_driver: string;
  year2_driver: string;
  year3_driver: string;
  year4_driver: string;
  year5_driver: string;
  year6_driver: string;
  year7_driver: string;
  year8_driver: string;
  year9_driver: string;
  year10_driver: string;
  yr1: string;
  yr2: string;
  yr3: string;
  yr4: string;
  yr5: string;
  yr6: string;
  yr7: string;
  yr8: string;
  yr9: string;
  yr10: string;
  yr2_sum: string;
  yr3_sum: string;
  yr4_sum: string;
  yr5_sum: string;
  yr6_sum: string;
  yr7_sum: string;
  yr8_sum: string;
  yr9_sum: string;
  yr10_sum: string;
  current_state_1: string;
  current_state_2: string;
  current_state_3: string;
  current_state_4: string;
  current_state_5: string;
  current_state_6: string;
  current_state_7: string;
  current_state_8: string;
  current_state_9: string;
  current_state_10: string;
  future_state_1: string;
  future_state_2: string;
  future_state_3: string;
  future_state_4: string;
  future_state_5: string;
  future_state_6: string;
  future_state_7: string;
  future_state_8: string;
  future_state_9: string;
  future_state_10: string;
  future_driver_1: string;
  future_driver_2: string;
  future_driver_3: string;
  future_driver_4: string;
  future_driver_5: string;
  future_driver_6: string;
  future_driver_7: string;
  future_driver_8: string;
  future_driver_9: string;
  future_driver_10: string;
  year1_ben_con: string;
  year1_ben_prob: string;
  year1_ben_agg: string;
  year2_ben_con: string;
  year2_ben_prob: string;
  year2_ben_agg: string;
  year3_ben_con: string;
  year3_ben_prob: string;
  year3_ben_agg: string;
  year4_ben_con: string;
  year4_ben_prob: string;
  year4_ben_agg: string;
  year5_ben_con: string;
  year5_ben_prob: string;
  year5_ben_agg: string;
  year6_ben_con: string;
  year6_ben_prob: string;
  year6_ben_agg: string;
  year7_ben_con: string;
  year7_ben_prob: string;
  year7_ben_agg: string;
  year8_ben_con: string;
  year8_ben_prob: string;
  year8_ben_agg: string;
  year9_ben_con: string;
  year9_ben_prob: string;
  year9_ben_agg: string;
  year10_ben_con: string;
  year10_ben_prob: string;
  year10_ben_agg: string;
  dif: string;
  future_effort: number | string;
  unit_type: string;
  year1_benefits_fmt: string;
  year1_benefits_fr: string;
  year1_benefits_p2: string;
  yr1_fr: string;
  yr1_p2: string;
  yr1_fmt: string;
  total_benefits_fmt: string;
  total_benefits_fr: string;
  total_benefits_p2: string;
  year2_benefits_fmt: string;
  year2_benefits_p2: string;
  yr2_sum_fmt: string;
  yr2_sum_fr: string;
  yr2_sum_p2: string;
  year3_benefits_fmt: string;
  year3_benefits_p2: string;
  yr3_sum_fmt: string;
  yr3_sum_fr: string;
  yr3_sum_p2: string;
  d_abbr: string;
  i_abbr: string;
  f_abbr: string;
  has_driver_scratchpad: number;
  driver_sp: string;
  driver_formula: string;
  has_subtypes: number;
  types: any[];
  has_financial_scratchpad: number;
  financial_sp: string;
  financial_formula: string;
  has_improvement_scratchpad: number;
  improvement_sp: string;
  improvement_formula: string;
  cost_of_problem: number;
  cost_of_problem_fr: string;
  cost_of_problem_fmt: string;
  b_imgs: BImg[];
  cs_imgs: any[];
  pain_points: MetricCapability[];
  capabilities: MetricCapability[];
  imp_x: string;
  financial_value_p2?: string;
  financial_value_fr?: string;
  financial_value_fmt?: string;
  fin_x: string;
  improvement_scratchpad_id?: string;
  value_prop_id: string;
  metric_note_2_cf: string;
  metric_note_3_cf: string;
  driver_phase_minus_5: string;
  driver_phase_minus_4: string;
  driver_phase_minus_3: string;
  driver_phase_minus_2: string;
  driver_phase_minus_1: string;
  metric_phase_minus_5: string;
  metric_phase_minus_4: string;
  metric_phase_minus_3: string;
  metric_phase_minus_2: string;
  metric_phase_minus_1: string;
  total_benefit: string;
  current_effort_override: string;
  created: Date;
  modified: Date;
  cumulative_bens: string;
  conservative: string;
  probable: string;
  aggressive: string;
  benefit_type: string;
  benefit_type_description: string;
  d_literal_number: string;
  f_literal_number: string;
  i_literal_number: string;
  i_unit_type: string;
  operation_objectives: any[];
  vp_case_studies: number;
  caps: any[];
  areas: unknown[];
  driver_source: string;
  financial_source: string;
  impact_source: string;
  driver_sp_fr: string;
  financial_sp_fr: string;
  days: number;
  quote: string;
  situation: Situation;
  situation_effected: number;
  imp_con: string;
  imp_prob: string;
  imp_agg: string;
  impact_fmt: string;
  driver_value_fr: string;
  first_benefits: string;
  addl_info: AddlInfo[];
  dif_fmt: string;
  current_effort_fmt: string;
  year1_driver_fmt: string;
  monthly: number;
  year2_driver_fmt: string;
  year2_benefits_fr: string;
  year3_driver_fmt: string;
  year3_benefits_fr: string;
  cumulative: number[];
  formula: string;
  formula1: string;
  formula2: string;
  formula3: string;
  operational_objectives: MetricCapability[];
  functional_objectives: MetricCapability[];
  expanded?: boolean;
  updated?: boolean;
  current_effort_error?: string;
  future_effort_error?: string;
  valid_error?: string;
  connected_object_id: MetricConnectedObj;
  calculated_value_fmt?: string;
  validation?: CustomerValidation;
}

export interface BImg {
  img_path: string;
  description: string;
}

export interface MetricCapability {
  id?: string;
  account_solution_metric_id?: string;
  account_capability_id?: string;
  created?: Date;
  modified?: Date;
  name?: string;
  description?: string;
  account_painpoint_id?: string;
  account_functional_objective_id?: string;
  expand?: boolean;
}

export interface Cashflow {
  costs: Benefits;
  benefits: Benefits;
  net_cash_flow: DiscCashFlow;
  disc_cash_flow: DiscCashFlow;
  cumulative_net: number[];
  positive_year: number;
  cumulative_ben: Array<number | string>;
  cumulative_costs: Array<number | string>;
  npv: DiscCashFlow;
  npv_formula_text: string;
  npv_formula: string;
  name?: string;
  Total_p2?: string;
  Total_fmt: string;
  Year5_fmt: string;
  Year4_fmt: string;
  Year3_fmt: string;
  Year2_fmt: string;
  Year1_fmt: string;
  key: string;
}

export interface CashflowsStats {
  name: string;
  Year1: string;
  Year1_fmt: string;
  Year1_fr: string;
  Year1_p2: string;
  Year2: string;
  Year2_fmt: string;
  Year2_fr: string;
  Year2_p2: string;
  Year3: string;
  Year3_fmt: string;
  Year3_fr: string;
  Year3_p2: string;
  Total: string;
  Total_fmt: string;
  Total_fr: string;
  Total_p2: string;
  key: string;
}

export interface Benefits {
  name: string;
  improvement_factor_id: string;
  value_category_id: string;
  Year1: string;
  Year2: string;
  Year3: string;
  Total: string;
  expand: boolean;
  values?: any;
}

export interface Impact {
  id: string;
  name: string;
}

export interface Scale {
  id: string;
  company_field: string;
  description: string;
  driver_factor_id: string;
  name: string;
  primary_scaler: string;
  ratio: string;
  straight_scaling: string;
}

export interface Driver {
  id: string;
  name: string;
  description: string;
  driver_qualifier_id?: string;
}

export interface DiscCashFlow {
  name: string;
  Year1: number;
  Total: number;
  Year2: number;
  Year3: number;
}

export interface Solution {
  id?: string;
  value_prop_id?: string;
  account_solution_id?: string;
  quantity?: string | number;
  deployment_time?: string;
  created?: string;
  modified?: string;
  name?: string;
  description?: string;
  quantity_enabled?: string;
  account_asset_template_id?: string;
}

export interface AvailableValueProp {
  id: string;
  name: string;
  description: string;
  status_type_id: string;
  total_benefits: string;
  total_costs: string;
  total_benefits_fmt: string;
  total_costs_fmt: string;
}

export interface ValueGroupsResponse {
  data: ValueGroupListData[];
}

export interface ValueGroupListData {
  data: ValueGroupData;
  children: Child[];
}

export interface Child {
  data: ValueGroupData;
}

export interface ValueGroupData {
  value_prop_group_id?: string;
  id: string;
  name: string;
  total_benefits: string;
  total_costs: string;
  total_benefits_fmt: string;
  total_costs_fmt: string;
  parent: boolean;
}

export interface ValueGroupTemplate {
  id: string;
  account_asset_id: string;
  language_type_id: string;
  account_solution_id: string;
  sequence: string;
  name: string;
  description: string;
  template_path: string;
  artifact_name: string;
  default_active: string;
  web_calculator: string;
  pdf: string;
  created: Date;
  modified: Date;
  account_asset_template_id: string;
  format_type_id: string;
  format: string;
}

export interface ValuePropGroup {
  master_value_prop: MasterValueProp;
  value_props: ValueProp[];
}

export interface CreateValueGroupPayload {
  company_id?: string;
  sfoide?: string;
  group_type_id: string;
}

export interface CompetitiveTCO {
  rows?: Array<string[]>;
  comps?: Comp[];
  costs_table?: CostsTable[];
  hard_costs_chart?: string[];
  soft_costs_chart?: number[];
  chart_names?: string[];
  ui_hard_costs_chart?: number[];
  columns?: any;
  labels?: any;
}

export interface Comp {
  account_competitor_id?: string;
  competitor_name?: string;
  yr1_sum?: string;
  yr2_sum?: string;
  yr3_sum?: number;
  yr4_sum?: string;
  yr5_sum?: number;
  yr6_sum?: string;
  yr7_sum?: string;
  yr8_sum?: string;
  yr9_sum?: string;
  yr10_sum?: string;
  grand_total_sum?: string;
  costs?: { [key: string]: string }[];
  yr1_sum_fmt?: string;
  yr3_sum_fmt?: string;
  yr5_sum_fmt?: string;
  grand_total_sum_fmt?: string;
}

export interface CostsTable {
  competitor_name?: string;
  hard_costs_1?: string;
  hard_costs_1_fmt?: string;
  hard_costs_3?: number;
  hard_costs_3_fmt?: string;
  hard_costs_5?: number;
  hard_costs_5_fmt?: string;
  soft_costs_1?: string;
  soft_costs_3?: number;
  soft_costs_5?: number;
  total1?: number;
  total1_fmt?: string;
  total3?: number;
  total3_fmt?: string;
  total5?: number;
  total5_fmt?: string;
  soft_costs_1_fmt?: string;
  soft_costs_3_fmt?: string;
  soft_costs_5_fmt?: string;
}

export interface ValuePropSolutionPayload {
  account_solution_id: string;
  value_prop_id: string | number;
  quantity: string;
  delete_vp_solutions: number;
}

export interface UpdateValuePropDonePayload {
  done?: number;
  q2_done?: number;
  scalers_done?: number;
  scenario_done?: number;
  situation_done?: number;
  step?: number;
}

export interface ValuePropBenefitMetrics {
  total_costs: number;
  total_benefits: number;
  total_benefits_fmt: string;
  year1_benefits: string;
  year1_benefits_fmt: string;
  year1_benefits_fr: string;
  year2_benefits: string;
  year3_benefits: string;
  year4_benefits: string;
  year5_benefits: string;
  year6_benefits: string;
  year7_benefits: string;
  year8_benefits: string;
  year9_benefits: string;
  year10_benefits: string;
  year1_costs: string;
  year2_costs: string;
  year3_costs: string;
  year4_costs: string;
  year5_costs: string;
  year6_costs: string;
  year7_costs: string;
  year8_costs: string;
  year9_costs: string;
  year10_costs: string;
  year1_ben_con: number;
  year2_ben_con: number;
  year3_ben_con: number;
  year4_ben_con: number;
  year5_ben_con: number;
  year6_ben_con: number;
  year7_ben_con: number;
  year8_ben_con: number;
  year9_ben_con: number;
  year10_ben_con: number;
  year1_ben_prob: number;
  year2_ben_prob: number;
  year3_ben_prob: number;
  year4_ben_prob: number;
  year5_ben_prob: number;
  year6_ben_prob: number;
  year7_ben_prob: number;
  year8_ben_prob: number;
  year9_ben_prob: number;
  year10_ben_prob: number;
  year1_ben_agg: number;
  year2_ben_agg: number;
  year3_ben_agg: number;
  year4_ben_agg: number;
  year5_ben_agg: number;
  year6_ben_agg: number;
  year7_ben_agg: number;
  year8_ben_agg: number;
  year9_ben_agg: number;
  year10_ben_agg: number;
  benefits_low: number;
  benefits_high: number;
  competitor_price: number;
  competitor_name: string;
  deployment_time: string;
  metrics: Metric[];
  vcs: Vc[];
  addl_info?: AddlInfo[];
}

export interface AddlInfo {
  info: string;
}

export interface Situation {
  account_solution_metric_id: null;
  active: null;
  conservative: null;
  probable: null;
  aggressive: null;
  current_effort: null;
  driver_baseline_answer: null;
  financial_baseline_answer: null;
  name?: string;
}

export interface FactorSituation {
  id: string;
  account_solution_metric_id: string;
  situation_type_id: string;
  name: string;
  solution_name?: string; // need
  box_label?: string;
  child_element_type_id?: string;
  created?: string;
  default_active?: string;
  dep_element_id?: string;
  description?: string;
  description_override?: string;
  factor_override?: string;
  guided_default_active?: string;
  modified?: string;
  name_override?: string;
  parent_id?: string;
  situation_element_id?: string;
  account_situation_type_id?: string;
  is_tree?: boolean;
  is_leaf?: true;
}

export interface SituationOnFactor {
  data: FactorSituation;
  children: FactorSituation[];
  expanded: boolean;
}

export interface SituationOnFactorRes {
  data: SituationOnFactor[];
  ids: string[];
  situations: SituationOnFactor[];
}

export interface Vc {
  value_category_id: string;
  name: string;
  cost_of_problem: number;
  vc_yr1_total: number;
  vc_yr1_total_fr: string;
  vc_yr2_total: number;
  vc_yr2_sum: number;
  vc_yr3_total: number;
  vc_yr3_sum: number;
  vc_yr3_sum_fr: string;
  vc_yr4_total: number;
  vc_yr4_sum: number;
  vc_yr5_total: number;
  vc_yr5_sum: number;
  vc_yr10_total: number;
  vc_yr10_sum: number;
  vc_total: number;
  monthly: number;
  cs_yr1_total: number;
  cs_yr2_total: number;
  cs_yr2_sum: number;
  cs_yr3_total: number;
  cs_yr3_sum: number;
  cs_yr4_total: number;
  cs_yr4_sum: number;
  cs_yr5_total: number;
  cs_yr5_sum: number;
  cs_yr10_total: number;
  cs_yr10_sum: number;
  cs_total: number;
  fs_yr1_total: number;
  fs_yr2_total: number;
  fs_yr2_sum: number;
  fs_yr3_total: number;
  fs_yr3_sum: number;
  fs_yr4_total: number;
  fs_yr4_sum: number;
  fs_yr5_total: number;
  fs_yr5_sum: number;
  fs_yr10_total: number;
  fs_yr10_sum: number;
  fs_total: number;
  savings: number;
  metrics: VcMetric[];
}

export interface VcMetric {
  metric_name: string;
  value_prop_metric_id: string;
  solution_name: string;
  cost_of_problem: number;
  yr1: number;
  yr2: number;
  yr2_sum: number;
  yr3: number;
  yr3_sum: number;
  yr3_sum_fr: boolean;
  yr4: number;
  yr4_sum: number;
  yr5: number;
  yr5_sum: number;
  yr10: number;
  vc_total: number;
  monthly: number;
  yr1_fmt: string;
  vc_total_fmt: string;
  cs_yr1: number;
  cs_yr2: number;
  cs_yr2_sum: number;
  cs_yr3: number;
  cs_yr3_sum: number;
  cs_yr4: number;
  cs_yr4_sum: number;
  cs_yr5: number;
  cs_yr5_sum: number;
  cs_yr10: number;
  cs_total: number;
  fs_yr1: number;
  fs_yr2: number;
  fs_yr2_sum: number;
  fs_yr3: number;
  fs_yr3_sum: number;
  fs_yr4: null;
  fs_yr4_sum: number;
  fs_yr5: null;
  fs_yr5_sum: number;
  fs_yr10: null;
  fs_total: number;
  types: any[];
  feedbackAvg: null;
}

export interface FeedbackResponse {
  average: string;
  feedback: Feedback[];
  login: unknown;
  success: boolean;
  profile_pic_filepath?: string;
  user_name?: string;
  score?: string | number;
  modified?: string;
  comment?: string;
}

export interface ValuePropPhasing {
  term: string;
  metric_phasing: MetricPhasing;
  driver_phasing: DriverPhasing;
  overrides: DriverPhasingOverride;
}

export interface DriverPhasingOverride {
  driver_phase_2_override: boolean;
  driver_phase_3_override: boolean;
  driver_phase_4_override: boolean;
  driver_phase_5_override: boolean;
  driver_phase_6_override: boolean;
  driver_phase_7_override: boolean;
  driver_phase_8_override: boolean;
  driver_phase_9_override: boolean;
  driver_phase_10_override: boolean;
}

export interface DriverPhasing {
  driver_phase_2: number;
  driver_phase_3: number;
  driver_phase_4: number;
  driver_phase_5: number;
  driver_phase_6: number;
  driver_phase_7: number;
  driver_phase_8: number;
  driver_phase_9: number;
  driver_phase_10: number;
}

export interface MetricPhasing {
  metric_phase_0: string;
  metric_phase_1: string;
  metric_phase_2: string;
  metric_phase_3: string;
  metric_phase_4: string;
  metric_phase_5: string;
  metric_phase_6: string;
  metric_phase_7: string;
  metric_phase_8: string;
  metric_phase_9: string;
  metric_phase_10: string;
}

export interface ValuePropFactorGroupResponse {
  factor_groups: FactorGroup[];
  has_factor_groups: number;
}

export interface KeyAssumptionsResponse {
  drivers: Driver[];
  improvements: any[];
  financials: AssumptionsFinancial[];
  units: Unit[];
  all_assumptions: AllAssumption[];
}

export interface AllAssumption {
  driver_factor_id?: string;
  value_prop_metric_id: string;
  account_agg_primary_id: string;
  driver_value?: string;
  unit_type_id: string;
  factor_precision: string;
  driver_override?: string;
  driver_estimate?: string;
  driver_factor?: string;
  description: string;
  min: string;
  max: string;
  increment: string;
  driver_source?: string;
  driver_source_type_id?: string;
  metrics: AllAssumptionMetric[];
  driver_estimate_fmt?: string;
  driver_estimate_un_fmt?: string;
  driver_override_fmt?: string;
  driver_override_un_fmt?: string;
  driver_value_fmt?: string;
  driver_value_un_fmt?: string;
  max_fmt: string;
  max_fr: string;
  min_fmt: string;
  min_fr: string;
  icon?: null;
  has_scratchpad: number;
  financial_factor_id?: string;
  financial_value?: string;
  financial_override?: string;
  financial_estimate?: string;
  financial_source_type_id?: string;
  financial_factor?: string;
  financial_source?: string;
  financial_estimate_fmt?: string;
  financial_estimate_un_fmt?: string;
  financial_override_fmt?: string;
  financial_override_un_fmt?: string;
  financial_value_fmt?: string;
  financial_value_un_fmt?: string;
}

export interface AllAssumptionMetric {
  id: string;
  driver_source_type_id?: string;
  metric_name: string;
  driver_factor_id: string;
  financial_factor_id: string;
  driver_estimate?: string;
  driver_override?: string;
  active: string;
  driver_value?: string;
  impact: number | string;
  year1_benefits: string;
  total_benefits: string;
  abbr: string;
  unit_type_id: string;
  improvement_factor_id: string;
  active_type: string;
  subtypes_enabled: string;
  impact_type: string;
  i_unit_type_id?: string;
  year1_benefits_fmt: string;
  total_benefits_fmt: string;
  financial_source_type_id?: string;
}

export interface Driver {
  driver_factor_id: string;
  value_prop_metric_id: string;
  account_agg_primary_id: string;
  driver_value: string;
  unit_type_id: string;
  factor_precision: string;
  driver_override: string;
  driver_estimate: string;
  driver_factor: string;
  description: string;
  min: string;
  max: string;
  increment: string;
  driver_source: string;
  driver_source_type_id: string;
  metrics: DriverMetric[];
  driver_estimate_fmt: string;
  driver_estimate_un_fmt: string;
  driver_override_fmt: string;
  driver_override_un_fmt: string;
  driver_value_fmt: string;
  driver_value_un_fmt: string;
  max_fmt: string;
  max_fr: string;
  min_fmt: string;
  min_fr: string;
  icon: null;
  has_scratchpad: number;
  type?: string;
  updated?: boolean;
  is_telemetry_data?: string;
  isTdData?: boolean;
}

export interface DriverMetric {
  id: string;
  driver_source_type_id: string;
  metric_name: string;
  driver_factor_id: string;
  financial_factor_id: string;
  driver_estimate: string;
  driver_override: string;
  active: string;
  driver_value: string;
  impact: number;
  year1_benefits: string;
  total_benefits: string;
  abbr: string;
  unit_type_id: string;
  improvement_factor_id: string;
  active_type: string;
  subtypes_enabled: string;
  impact_type: string;
  i_unit_type_id: string;
  year1_benefits_fmt: string;
  total_benefits_fmt: string;
}

export interface AssumptionsFinancial {
  financial_factor_id: string;
  account_agg_primary_id: string;
  financial_value: string;
  financial_override: string;
  financial_estimate: string;
  unit_type_id: string;
  factor_precision: string;
  financial_source_type_id: string;
  financial_factor: string;
  description: string;
  value_prop_metric_id: string;
  min: string;
  max: string;
  increment: string;
  financial_source: string;
  metrics: FinancialMetric[];
  financial_estimate_fmt: string;
  financial_estimate_un_fmt: string;
  financial_override_fmt: string;
  financial_override_un_fmt: string;
  financial_value_fmt: string;
  financial_value_un_fmt: string;
  min_fmt: string;
  min_fr: string;
  max_fmt: string;
  max_fr: string;
  has_scratchpad: number;
  type?: string;
  updated?: boolean;
  is_telemetry_data?: string;
  isTdData?: boolean;
}

export interface FinancialMetric {
  id: string;
  financial_source_type_id: string;
  metric_name: string;
  driver_factor_id: string;
  financial_factor_id: string;
  active: string;
  impact: string;
  year1_benefits: string;
  total_benefits: string;
  unit_type_id: string;
  abbr: string;
  improvement_factor_id: string;
  impact_type: string;
  active_type: string;
  subtypes_enabled: string;
  year1_benefits_fmt: string;
  total_benefits_fmt: string;
}

export interface ValuePropListItem extends ValueProp {
  solutionItems?: any[];
}

export interface MetricConnectedObj {
  result: {
    id: string;
    account_factor_id: string;
    connected_object_type_id: string;
    connected_object_id: string;
    created: string;
    modified: string;
    metric_name: string;
    goal_factor_name: string;
    goal_factor_value: string;
    goal_factor_value_fmt: string;
    calculated_value: number;
    calculated_value_fmt: string;
    driver_factor?: string;
    driver_value?: string;
    financial_factor?: string;
    financial_value?: string;
  }[];
}

export interface Phasing {
  value_prop_id: number;
  value_prop_metric_id: number;
  apply_all: number;
  accrual_type_id?: string;
}
