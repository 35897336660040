<div class="row">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="models_panel tag-page-wrapper">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <app-editTranslation [styleClass]="'title'" [transComp]="trans" [transObj]="trans.trans.customAssetTags"></app-editTranslation>
        </div>
      </div>
    </div>
  </div>
  <div class="row marg-v">
    <div class="col-xs-4">
      <div class="input-group keywest_search">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
        <input [formControl]="searchInput" type="text" placeholder="{{ trans.trans.search.value }}" class="form-control" />
        <span *ngIf="searchInput.value !== ''" (click)="resetSearch()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
      </div>
    </div>
  </div>

  <p-tabView>
    <p-tabPanel [header]="trans.trans.factorTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="factorAssetTags$ | async | filterTags: searchString as factorAssetTags; else noRecords">
          <p-table
            id="customAssetTagsTable"
            *ngIf="factorAssetTags?.length > 0; else noRecords"
            [value]="factorAssetTags"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="getTranslations()"
                    key="trans.name"
                    [isTranslationV2]="true"
                    [component]="trans.config.component"
                    [transObj]="trans.trans.tagId"
                  ></app-editTranslation>
                </th>
                <th>
                  {{ trans.trans.factorValueTag.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="getTranslations()"
                    key="trans.factorValueTag"
                    [isTranslationV2]="true"
                    [component]="trans.config.component"
                    [transObj]="trans.trans.factorValueTag"
                  ></app-editTranslation>
                </th>
                <th>
                  {{ trans.trans.factorNameTag.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="getTranslations()"
                    key="trans.factorNameTag"
                    [isTranslationV2]="true"
                    [component]="trans.config.component"
                    [transObj]="trans.trans.factorNameTag"
                  ></app-editTranslation>
                </th>
                <th>
                  {{ trans.trans.factorDescriptionTag.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="getTranslations()"
                    key="trans.factorDescriptionTag"
                    [isTranslationV2]="true"
                    [component]="trans.config.component"
                    [transObj]="trans.trans.factorDescriptionTag"
                  ></app-editTranslation>
                </th>
                <th>
                  {{ trans.trans.status.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="getTranslations()"
                    key="trans.status"
                    [isTranslationV2]="true"
                    [component]="trans.config.component"
                    [transObj]="trans.trans.status"
                  ></app-editTranslation>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  <!-- Tag Name -->
                  {{ tag.name }}
                </td>
                <td class="tag-data">
                  <!-- Tag Value -->
                  {{ tag.tag | assetTagFormat }}
                </td>
                <td class="tag-data">
                  <!-- Tag Value -->
                  {{ tag.tag | assetTagFormat: 'name' }}
                </td>
                <td class="tag-data">
                  <!-- Tag Value -->
                  {{ tag.tag | assetTagFormat: 'description' }}
                </td>
                <td>
                  <!-- Status -->
                  <p-inputSwitch (onChange)="onUpdateActive(tag)" [(ngModel)]="tag.active"></p-inputSwitch>
                </td>
                <td>
                  <!-- actions -->
                  <app-ellipsis-menu>
                    <ul id="actionList" class="list-group" style="width: 70px">
                      <!-- edit -->
                      <li class="list-group-item" style="padding-left: 12px" (click)="editAssetTag(tag)">
                        <i style="margin-right: 4px" class="clickable fa fa-pencil"></i>{{ trans.trans.edit.value }}
                      </li>
                      <!-- Delete -->
                      <li class="list-group-item">
                        <app-are-you-sure
                          (callbackYes)="deleteTag(tag.id)"
                          [message]="trans.trans.areYouSure.value"
                          icon="fa fa-trash fix-center"
                          iconColor="red"
                          textColor="warning"
                        ></app-are-you-sure>
                      </li>
                    </ul>
                  </app-ellipsis-menu>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel [header]="trans.trans.benefitTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="benefitAssetTags$ | async | filterTags: searchString as benefitAssetTags">
          <p-table
            [value]="benefitAssetTags"
            id="customBenefitAssetTagsTable"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
            *ngIf="benefitAssetTags?.length > 0; else noRecords"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.benefitNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitTcoNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitDescriptionTag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitNotesTag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitNotes2Tag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitsNotes3Tag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.benefitYear.value }} {{ year }} {{ trans.trans.tag.value }}</th>
                <th>
                  {{ trans.trans.benefitsTotalTag.value }}
                </th>
                <th *ngFor="let year of phasingYears">{{ trans.trans.phasingPercentYear.value }} {{ year }} {{ trans.trans.tag.value }}</th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'metric_name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'tco_name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'metric_note' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'metric_note_2' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'metric_note_3' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'year' + year + '_benefits' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'total_benefits' }}</td>
                <td *ngFor="let year of phasingYears">{{ tag.tag | assetTagFormat: 'metric_phase_' + year }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container></div
    ></p-tabPanel>
    <p-tabPanel [header]="trans.trans.benefitGroupTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="benefitGroupAssetTags$ | async | filterTags: searchString as benefitGroupAssetTags">
          <p-table
            [value]="benefitGroupAssetTags"
            id="customBenefitAssetTagsTable"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
            *ngIf="benefitGroupAssetTags?.length > 0; else noRecords"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.benefitGroupNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitGroupTcoNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitGroupDescriptionTag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.benefitGroupYear.value }} {{ year }} {{ trans.trans.tag.value }}</th>
                <th>
                  {{ trans.trans.benefitGroupTotalTag2.value }}
                </th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'tco_name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'vc_yr' + year + '_total' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'vc_total' }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="trans.trans.benefitTypeTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="benefitTypeAssetTags$ | async | filterTags: searchString as benefitTypeAssetTags">
          <p-table
            [value]="benefitTypeAssetTags"
            id="customBenefitAssetTagsTable"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
            *ngIf="benefitTypeAssetTags?.length > 0; else noRecords"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.benefitTypeNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.benefitTypeDescriptionTag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.benefitTypeYear.value }} {{ year }} {{ trans.trans.tag.value }}</th>
                <th>
                  {{ trans.trans.benefitTypeTotalTag2.value }}
                </th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'year' + year + '_total' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'total' }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="trans.trans.functionalObjectiveTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="functionalObjectiveTags$ | async | filterTags: searchString as functionalObjectiveTags; else noRecords">
          <p-table
            id="customBenefitAssetTagsTable"
            *ngIf="functionalObjectiveTags?.length > 0; else noRecords"
            [value]="functionalObjectiveTags"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.functionalObjectNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.functionalObjectiveDescriptionTag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.functionalObjectiveYear.value }} {{ year }} {{ trans.trans.benefitTag.value }}</th>
                <th>
                  {{ trans.trans.functionalObjectiveTotalBenefitsTag.value }}
                </th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'vc_yr' + year + '_total' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'vc_total' }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel [header]="trans.trans.cabilitiesTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="capabilityTags$ | async | filterTags: searchString as capabilitiesTags; else noRecords">
          <p-table
            id="customBenefitAssetTagsTable"
            *ngIf="capabilitiesTags?.length > 0; else noRecords"
            [value]="capabilitiesTags"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.capabilityNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.capabilityDescriptionTag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.capabilityYear.value }} {{ year }} {{ trans.trans.benefitTag.value }}</th>
                <th>
                  {{ trans.trans.capabilityTotalBenefitsTag.value }}
                </th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'vc_yr' + year + '_total' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'vc_total' }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel [header]="trans.trans.operationalObjectiveTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="ooTags$ | async | filterTags: searchString as ooAssetTags">
          <p-table
            [value]="ooAssetTags"
            id="customBenefitAssetTagsTable"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
            *ngIf="ooAssetTags?.length > 0; else noRecords"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.operationalObjectiveNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.operationalObjectiveDescriptionTag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.operationalObjectiveYear.value }} {{ year }} {{ trans.trans.benefitTag.value }}</th>
                <th>
                  {{ trans.trans.operationalObjectiveTotal.value }}
                </th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'vc_yr' + year + '_total' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'vc_total' }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel [header]="trans.trans.ppTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="ppTags$ | async | filterTags: searchString as ppAssetTags">
          <p-table
            [value]="ppAssetTags"
            id="customBenefitAssetTagsTable"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
            *ngIf="ppAssetTags?.length > 0; else noRecords"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.ppNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.ppDescriptionTag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.ppYear.value }} {{ year }} {{ trans.trans.benefitTag.value }}</th>
                <th>
                  {{ trans.trans.ppTotal.value }}
                </th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'name' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'vc_yr' + year + '_total' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'vc_total' }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel [header]="trans.trans.costTags.value">
      <div class="custom-tag-table-wrapper">
        <ng-container *ngIf="costTags$ | async | filterTags: searchString as costAssetTags">
          <p-table
            [value]="costAssetTags"
            id="customBenefitAssetTagsTable"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
            *ngIf="costAssetTags?.length > 0; else noRecords"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  {{ trans.trans.tagId.value }}
                </th>
                <th>{{ trans.trans.tagValue.value }}</th>
                <th>
                  {{ trans.trans.costNameTag.value }}
                </th>
                <th>
                  {{ trans.trans.costDescriptionTag.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.costYear.value }} {{ year }} {{ trans.trans.costTag.value }}</th>
                <th>
                  {{ trans.trans.costQuantity.value }}
                </th>
                <th *ngFor="let year of years">{{ trans.trans.costYear.value }} {{ year }} {{ trans.trans.quantityTag.value }}</th>
                <th>
                  {{ trans.trans.costSubtotal.value }}
                </th>
                <th>
                  {{ trans.trans.costDiscount.value }}
                </th>
                <th>
                  {{ trans.trans.costGrandTotal.value }}
                </th>
                <th class="frozen2">
                  {{ trans.trans.status.value }}
                </th>
                <th class="frozen"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tag let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover">
                <td>
                  {{ tag.name }}
                </td>
                <td>{{ tag.tag | assetTagFormat }}</td>
                <td>{{ tag.tag | assetTagFormat: 'cost_type' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'description' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'yr' + year + '_costs' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'quantity' }}</td>
                <td *ngFor="let year of years">{{ tag.tag | assetTagFormat: 'yr' + year + '_quantity' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'subtotal' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'discount' }}</td>
                <td>{{ tag.tag | assetTagFormat: 'grand_total' }}</td>
                <ng-template [ngTemplateOutlet]="frozenCols" [ngTemplateOutletContext]="{ tag }"></ng-template>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<app-manage-tags
  *ngIf="isOpen"
  (sidebarClose)="onHide($event)"
  (updatedTags)="getTags($event)"
  [trans]="trans"
  [sidebarOpen]="isOpen"
  [tagSelected]="tagSelected"
></app-manage-tags>

<ng-template #noRecords>
  <app-no-records>{{ trans.trans.noTags.value }}</app-no-records>
</ng-template>

<ng-template #frozenCols let-tag="tag">
  <td class="frozen2">
    <!-- Status -->
    <p-inputSwitch (onChange)="onUpdateActive(tag)" [(ngModel)]="tag.active"></p-inputSwitch>
  </td>
  <td class="frozen">
    <!-- actions -->
    <app-ellipsis-menu>
      <ul id="actionList" class="list-group" style="width: 70px">
        <!-- edit -->
        <li class="list-group-item" style="padding-left: 12px" (click)="editAssetTag(tag)">
          <i style="margin-right: 4px" class="clickable fa fa-pencil"></i>{{ trans.trans.edit.value }}
        </li>
        <!-- Delete -->
        <li class="list-group-item">
          <app-are-you-sure
            (callbackYes)="deleteTag(tag.id)"
            [message]="trans.trans.areYouSure.value"
            icon="fa fa-trash fix-center"
            iconColor="red"
            textColor="warning"
          ></app-are-you-sure>
        </li>
      </ul>
    </app-ellipsis-menu>
  </td>
</ng-template>
