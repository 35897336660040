import { Component, OnInit, OnDestroy } from '@angular/core';
import { DlAdminTranslation } from './dladmin.translation';
import { Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dladmin',
  templateUrl: './dladmin.component.html',
  styleUrls: ['./dladmin.component.scss']
})
export class DladminComponent implements OnInit, OnDestroy {

  ngUnsubscribe = new Subject<boolean>();
  showTranslate = false;

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: DlAdminTranslation
    ) {
   }

  ngOnInit() {
      this.getTranslations();
      this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.getTranslations();
      });
      this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
        this.showTranslate = res;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
   let langId = sessionStorage.getItem('language_type_id');
   let langAbbr = this.translationService.getLanguageAbbr(langId);

   const payload = {
    account_id: sessionStorage.getItem('aid'),
    component: this.trans.config.component,
    lang: langAbbr,
    localTranslations: this.trans.trans
  };

   this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
   });
  }

}
