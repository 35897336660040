<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ !!survey ? trans.trans.editSurvey.value : trans.trans.newSurvey.value }}
  </div>
  <div class="sidebar_container">
    <div *ngIf="!selectedTemplate; else surveyTemplate" class="survey-selection-container">
      <div class="period-title">
        {{ trans.trans.selectBusinessReviewPeriod.value }}
      </div>
      <div class="spacer"></div>
      <div class="period-dropdown">
        <p-dropdown
          appendTo="body"
          [autoDisplayFirst]="false"
          [panelStyleClass]="'dropdown-item-font'"
          class="keywest_dropdown dropdown-theme"
          [options]="businessReviewData"
          [placeholder]="trans.trans.selectPeriod.value"
          optionLabel="name"
          [(ngModel)]="businessReview"
        ></p-dropdown>
      </div>

      <div class="spacer"></div>
      <div class="survey-template-title">
        {{ trans.trans.selectSurveyTemplate.value }}
        <span class="choose-one"> ({{ trans.trans.chooseOne.value }}) </span>
      </div>
      <div class="spacer"></div>
      <div class="option-container">
        <div class="option-box" *ngFor="let template of surveyTemplates" (click)="selectSurveyTemplate(template)">
          {{ template.name }}
        </div>
      </div>
    </div>
    <ng-template #surveyTemplate>
      <app-create-survey
        *ngIf="!!selectedSurvey"
        [surveyId]="+selectedSurveyId"
        [activeSurveyTemplate]="selectedSurvey"
        [account_question_template_id]="+selectedTemplate.id"
        [isValueRealized]="true"
        [valuePropId]="valuePropId"
        [saveDisabled]="(businessReviewService.canEdit | async) === false"
        (close)="closeSidebar()"
      ></app-create-survey>
    </ng-template>
  </div>
</div>
