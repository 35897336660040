<form name="addDriverFactorForm" #addDriverFactorForm="ngForm">
	<div class='sidebar_header_container'>
		<div class='sidebar_header'>
			{{trans.trans.view_scratchpad.value}}
			<app-editTranslation *ngIf="showTranslate"  [component]="'r_scratchpads'" [isTranslationV2]="true" 
			[transObj]="trans.trans.view_scratchpad" (callback)="getTranslations()"></app-editTranslation>
		</div>
		<div class='sidebar_container' style='margin-bottom: 0px;'>
			<div class='keywest_panel'>
				<div class="row">
					<div class="col-xs-12">
						<div class='panel panel-white'>
							<div class='panel-heading' style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
								{{trans.trans.formula.value}}
								<app-editTranslation *ngIf="showTranslate"  [component]="'r_scratchpads'" [isTranslationV2]="true" 
								[transObj]="trans.trans.formula" (callback)="getTranslations()"></app-editTranslation>
							</div>
							<div class='panel-body' style='margin: 0px; border-spacing: 0px;'>
								<div class='scratchpad_formula' style='float: left;'>
									<span style='color: orange;'>{{OpenParen_default}}</span> <span style='color: blue;'>{{driverScratchpadFactor_default['name']}}</span> <span style='color: black;'>{{driverScratchpadFactorValue_default}}</span> <span style='color: orange;'>{{CloseParen_default}}</span></div>
								<div class='scratchpad_formula' style='float: left;' *ngFor="let driverScratchpadField of driverScratchpadFields;let i = index;">
									<span style='color: green;'>{{funcGetSign(driverScratchpadField.operator)}}</span> <span style='color: orange;'>{{driverScratchpadField.openParen}}</span> <span style='color: blue;'>{{driverScratchpadField.factor?.name}}</span> <span style='color: black;'>{{driverScratchpadField.operand}}</span> <span style='color: orange;'>{{driverScratchpadField.closeParen}}</span></div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</form>