import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Category } from '@data/services/valueposition/models/category.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelsV2RModelCategoryTranslations } from './r_category.translation';

@Component({
  selector: 'app-r-model-category',
  templateUrl: './r_category.component.html',
})
export class RModelCategoryComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() mode = 'view';

  sidebar_title = 'View Model Category';

  @Input() selectedElem: Category = {
    category: '',
    description: '',
    id: '',
  };

  private ngUnsubscribe$ = new Subject();

  constructor(
    private translationService: TranslationsV2Service,
    public trans: ModelsV2RModelCategoryTranslations,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.getTranslations();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
       this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      this.sidebar_title = this.trans.trans.view_model_group.value;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
