import { NgModule } from '@angular/core';

import { ThresholdsComponent } from "./thresholds.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';
 
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { AEThresholdsModule } from './ae_thresholds/ae_thresholds.module';
import { RThresholdsModule } from './r_thresholds/r_thresholds.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ThresholdTranslations } from './thresholds.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		ThresholdsComponent,
	],
	imports: [
		AreYouSureModule,
    MatTooltipModule,
		RThresholdsModule,
		AEThresholdsModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		MenuModule,
		SidebarModule,
		TableModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		ThresholdTranslations
	],
	entryComponents: [
		ThresholdsComponent,
	],
	exports: [ThresholdsComponent]
})
export class ModelThresholdsModule { }