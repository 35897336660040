<div class='row' style='margin-bottom: 20px;'>
	<div class='col-xs-6'>
		<div class='title'>{{trans.trans.costs.value}}</div>
	</div>
	<div class='col-xs-6'>
		<a class="btn btn_customizable pull-right value-execution-add-a-new-value-proposition-add-cost-button" *ngIf="valueProp.closed == '0' && valueProp.vp_can_edit" (click)="openAddCosts()" data-heap="value-execution-add-a-new-value-proposition-add-cost-button">
			<i class="fa fa-plus"></i>
			{{trans.trans.add_cost.value}}
		</a>
	</div>
</div>
<div class="table-responsive col-sm-12 costs-table" style="padding: 0px;">
	<table id="costs-simple" class="table custom-table">
		<thead>
			<tr>
				<th width="50%">{{trans.trans.Type.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Type" (callback)="getTranslations()"></app-editTranslation>
				</th>
				<th style='white-space: nowrap; width: 100px;'>{{trans.trans.Accrues.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Accrues" (callback)="getTranslations()"></app-editTranslation>
				</th>
				<th style='white-space: nowrap;' class="text-right">{{trans.trans.unitPrice.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.unitPrice" (callback)="getTranslations()"></app-editTranslation>
				</th>
				<th *ngIf="!feature97" matTooltip="{{trans.trans.quantity.value}}" matTooltipPosition="above" style='width: 40px; white-space: nowrap;' class="text-right">{{trans.trans.qty.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.qty" (callback)="getTranslations()"></app-editTranslation>
				</th>
				<th *ngIf="!feature97" style='width: 120px; white-space: nowrap;' class="text-right">{{trans.trans.Subtotal.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Subtotal" (callback)="getTranslations()"></app-editTranslation>
				</th>
				<th *ngIf="!feature97" matTooltip="{{trans.trans.discount.value}}" matTooltipPosition="above" style='width: 40px; white-space: nowrap;' class="text-right" [hidden]="hideCostsFeature">{{trans.trans.DISC.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.DISC" (callback)="getTranslations()"></app-editTranslation>
				</th>
				<th style='width: 10px; white-space: nowrap;' *ngIf="term > 3 && years && start !== 1">
					<i class='fa fa-angle-left clickable' (click)='start = rangeLeftArrow(start)' style='float: left; margin-top: 15px;'></i>
				</th>

				<th *ngFor="let year of years | rangePipe: start; let i = index" style='width: 60px; white-space: nowrap;' class="text-right">{{year.label}}</th>

				<th style='width: 10px; white-space: nowrap;' *ngIf="term > 3 && years && (start !== term -2)">
					<i class='fa fa-angle-right clickable' (click)='start = rangeRightArrow(start)' style='margin-top: 15px;'></i>
				</th>

				<th style='width: 120px; white-space: nowrap;' class="text-right">{{trans.trans.GrandTotal.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.GrandTotal" (callback)="getTranslations()"></app-editTranslation>
				</th>
				<th *ngIf="(valueProp.vp_can_edit && !isCustomerShareType) || dealdesk">

				</th>
			</tr>
		</thead>
		<tbody *ngIf="costsLoader">
			<tr>
				<td [attr.colspan]='colspan'>
					<img class="loader" [src]="fullImagePath" />
				</td>
			</tr>
		</tbody>

		<tbody *ngIf="!costs && !costsLoader">
			<tr>
				<td [attr.colspan]='colspan'>
					{{trans.trans.NoCostsFound.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.NoCostsFound" (callback)="getTranslations()"></app-editTranslation>
				</td>
			</tr>
		</tbody>
		<tbody *ngIf="costs && costs.length">

			<ng-container *ngFor="let cost of costs">
				<ng-container *ngIf="!costCategories">
					<ng-container *ngTemplateOutlet="costRow; context: { cost: cost }"></ng-container>
				</ng-container>

				<ng-container *ngIf="costCategories">
					<tr class="costGroupHeader">
						<td [attr.colspan]='colspan'><b>{{cost.name}}</b></td>
						<td *ngIf="feature91" class='text-right'>{{cost.grand_total_fmt}}</td>
						<td></td>
					</tr>

					<ng-container *ngIf="cost.costs && cost.costs.length > 0">
						<ng-container *ngFor="let cost of cost.costs">
							<ng-container *ngTemplateOutlet="costRow; context: { cost: cost }"></ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>

		</tbody>
	</table>

  <app-value-prop-flow-buttons 
    [moveToBottom]="!crm"
    (goNext)="callbackNext.emit()"
    (goBack)="back.emit()"
	[next]="showContinue"
  ></app-value-prop-flow-buttons>

</div>

<ng-template #costRow let-cost="cost" let-index>
	<tr class="grid-row">
		<td>
			<div *ngIf="!cost.editing">
				<a *ngIf="!locked" (click)='editValuePropCosts(cost)' class='clickable'>{{cost.cost_type}}</a>
				<span *ngIf="locked">{{cost.cost_type}}</span>
				<i *ngIf="!(style2022$ | async) && (cost.cost_description || cost.alt_cost_description)" [matTooltip]='cost.alt_cost_description ? cost.alt_cost_description : cost.cost_description' class='fa fa-info-circle'></i>
				<span *ngIf="(style2022$ | async)  && (cost.cost_description || cost.alt_cost_description)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="cost.alt_cost_description ? cost.alt_cost_description : cost.cost_description">
					info
				</span>
			</div>

			<div class='input-group' *ngIf="cost.editing">
				<input #rename class='form-control' [value]="cost.cost_type" />
				<div class='input-group-btn'>
					<ng-container *ngIf="!(style2022$ | async); else newButtons">
						<a role="button" (click)="funcUpdateCostTitle(rename.value, cost)" class='btn btn-success'><i class='fa fa-save'></i></a>
						<a role="button" (click)="cancelRename(cost)" class='btn btn-danger'><i class='fa fa-times'></i></a>
					</ng-container>
					<ng-template #newButtons>
						<span (click)="cancelRename(cost)" class="clickable input-group-addon action-button"><i class='fa fa-times'></i></span>
						<span (click)="funcUpdateCostTitle(rename.value, cost)" class="clickable input-group-addon action-button"><i class='fa fa-save'></i></span>
					</ng-template>
				</div>
			</div>
		</td>
		<td style='padding-right: 7px !important; white-space: nowrap !important;'>{{cost.accrual_type}}</td>
		<td class="text-right" style='padding-right: 7px !important; white-space: nowrap !important;'>{{cost.cost_fmt}}</td>
		<td class="text-right" *ngIf="!feature97" style='padding-right: 7px !important; white-space: nowrap !important;'>{{cost.quantity}}</td>
		<td class="text-right" *ngIf="!feature97" style='padding-right: 7px !important; white-space: nowrap !important;'>{{cost.subtotal_fmt}}</td>
		<td class="text-right" *ngIf="!feature97" style='padding-right: 7px !important; white-space: nowrap !important;' [hidden]="hideCostsFeature">{{cost.discount}}%</td>
		<td *ngIf="term > 3 && cost.years && start !== 1"></td>
		<td *ngFor="let year of cost.years | rangePipe: start; let i = index" class='text-right' style='padding-right: 7px !important; white-space: nowrap;'>{{year.value}}</td>
		<td *ngIf="term > 3 && cost.years && (start !== term -2)"></td>
		<td class="text-right" style='padding-right: 7px !important; white-space: nowrap !important;'>{{cost.grand_total_fmt}}</td>
		<td *ngIf="(valueProp.vp_can_edit && !isCustomerShareType) || dealdesk">
			<app-ellipsis-menu [narrow]="true">
				<ul class="list-group">
					<li class="list-group-item" (click)="editValuePropCosts(cost)">
						{{trans.trans.edit.value}}
					</li>
					<li class="list-group-item" (click)="cost.editing = !cost.editing">
						{{trans.trans.rename.value}}
					</li>
					<li class="list-group-item">
						<app-are-you-sure [text]="trans.trans.delete.value" icon="" [message]="trans.trans.areYouSure.value" (callbackYes)="deleteValuePropCosts(cost.id)"></app-are-you-sure>
					</li>
				</ul>
			</app-ellipsis-menu>
		</td>
	</tr>
</ng-template>

<p-sidebar [(visible)]="showAddCost && !!costModal.cost" (onHide)='showAddCost = false;' position='right' styleClass="keywest_sidebar p-sidebar-lg">
	<app-add-cost-modal *ngIf="showAddCost && costModal.cost" (callback)='funcCloseAddEditCosts()' [edit]='!!costModal.edit' [data]='costModal.valueProp' [cost]='costModal.cost'></app-add-cost-modal>
</p-sidebar>
