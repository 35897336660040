<div class="workflow-assigments-wrapper">
    <div class="title-wrapper">
        <span class="assignment-title" >{{trans.trans.workflowAssigments.value}}</span>
    </div>
    <div class="search-wrapper">
        <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="strSearch"
              (keyup)="workflowTable.filter($any($event).target.value, 'name', 'contains')"
              type="text"
              placeholder="{{ trans.trans.search.value }}"
              class="form-control"
            />
            <span *ngIf="strSearch !== ''" (click)="strSearch = ''; workflowTable.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
          </div>
    </div>
    <p-progressBar *ngIf="loading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
    <div class="assinment-table-wrapper">
        <div [hidden]="loading">
            <p-table id="assignments-table" #workflowTable [columns]="cols" [value]="userWfAssignments" scrollHeight="200px" styleClass='keywest_table' tableStyleClass='keywest_table'>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" >{{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon></th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-wf>
                    <tr>
                        <td width="40%">{{wf.name}}</td>
                        <td class="muted">{{wf.active}}</td>
                        <td class="bold" [style.color]="wf.assignment_color">{{wf.default_assignment ? wf.default_assignment : trans.trans.notAssigned.value}}</td>
                        <td>
                            <span [matTooltip]="trans.trans.manuallyAssign.value" class="material-icons-outlined click" (click)="manuallyAssign(wf)">add</span>
                            <span [matTooltip]="trans.trans.manuallySuppress.value" class="material-icons-outlined click" (click)="unAssign(wf)">remove</span>
                        </td>
                        <td>
                            <span [matTooltip]="trans.trans.reset.value" class="material-icons-outlined click" (click)="reset(wf)">replay</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="no-records-wrapper" *ngIf="!userWfAssignments || userWfAssignments.length === 0">
                <app-no-records>{{trans.trans.noRecords.value}}</app-no-records>
            </div>
        </div>
    </div>
</div>
