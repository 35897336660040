import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { CaseStudySidebarModule } from '@shared_components/case-study-sidebar/case-study-sidebar.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ModelBenefitsCaseStudyModule } from 'app/value-mapv2/solution-detail/case-studies/benefits-casestudy/benefits-casestudy.module';
import { ModelQuoteModule } from 'app/value-mapv2/solution-detail/case-studies/quote/quote.module';
import { ModelSectionsModule } from 'app/value-mapv2/solution-detail/case-studies/sections/sections.module';
import { CaseStudyTagsModule } from 'app/value-mapv2/solution-detail/case-studies/tags/tags.module';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { AECaseStudiesComponent } from './ae_case_studies.component';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { CaseStudyTranslations } from './ae_case_studies.translations';

const routes: Routes = [
  {
    path: 'case_study/new',
    component: AECaseStudiesComponent,
  },
  {
    path: 'case_study/:case_study_id/:is_account_solution',
    component: AECaseStudiesComponent,
  },
  {
    path: 'case_study/:case_study_id/:is_account_solution/:fromVP',
    component: AECaseStudiesComponent,
  },
];
@NgModule({
  imports: [
    CaseStudyTagsModule,
    ChipsModule,
    SidebarModule,
    FieldsetModule,
    ModelQuoteModule,
    ModelBenefitsCaseStudyModule,
    ModelSectionsModule,
    ModelQuoteModule,
    InputSwitchModule,
    DropdownModule,
    ChipsModule,
    SidebarModule,
    MenuModule,
    ProgressBarModule,
    TableModule,
    MultiSelectModule,
    EditTranslationsModule,
    AreYouSureModule,
    MatTooltipModule,
    SelectButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CaseStudySidebarModule,
    ErrorInputWrapperModule,
    RouterModule.forChild(routes),
  ],
  declarations: [AECaseStudiesComponent],
  providers: [CaseStudyTranslations],
  exports: [AECaseStudiesComponent],
  entryComponents: [AECaseStudiesComponent],
})
export class AECaseStudiesModule {}
