<div class='row'>
	<div class='col-xs-12'>
		<button (click)='addContact = !addContact' class='btn btn-primary pull-right'>Add Contact</button>
	</div>
</div>
<div class='row'>
	<div class='col-xs-12'>
		<table class="table table-responsive">
			<thead>
				<tr>
					<th style='width: 22.5%;'>First Name</th>
					<th style='width: 22.5%;'>Last Name</th>
					<th style='width: 22.5%;'>Title</th>
					<th style='width: 22.5%;'>Email Address</th>
					<th></th>
				</tr>
			</thead>

			<tbody *ngIf="addContact">
				<tr>
					<td><input type='text' [(ngModel)]="newContact.fname" name='fname' class='form-control'></td>
					<td><input type='text' [(ngModel)]="newContact.lname" name='lname' class='form-control'></td>
					<td><input type='text' [(ngModel)]="newContact.title" name='title' class='form-control'></td>
					<td><input type='text' [(ngModel)]="newContact.email" name='email' class='form-control'></td>
					<td><button (click)='funcAddContact()' class='btn btn-success btn-sm'>Add Contact</button></td>
				</tr>
			</tbody>

			<tbody *ngFor="let item of contactList">
				<tr>
					<td>{{item.fname}}</td>
					<td>{{item.lname}}</td>
					<td>{{item.title}}</td>
					<td>{{item.email}}</td>
					<td></td>
				</tr>
			</tbody>

			<tbody *ngIf="!contactList.length">
				<tr>
					<td colspan='5'>No contacts found</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>