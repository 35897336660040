import { Injectable } from '@angular/core';

@Injectable()
export class RCollateralTranslations {

    public config = {
        component: 'collateral',
        display_name: 'Collaterl View',
		description: 'View of an individual collateral'
    };

    public trans: any = {
        viewCollateral: 'View Collateral',
        format: 'Format',
        name: 'Name',
        url: 'Url',
        description: 'Description',
        edit_translations: 'Edit Translations'
    };
}
