<p-menu #menuStatus appendTo='body' [popup]="true" [model]="valuePropStatusTypes" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div *ngIf="dashboard == 'v1'">
	<div class='models_panel' style='margin-bottom: 20px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header_small'>
				<div class='row'>
					<div class='col-xs-12'>
						<div class='title'>
							<i class='fa fa-plus clickable' *ngIf="!showBody" (click)='showBody = !showBody'></i>
							<i class='fa fa-minus clickable' *ngIf="showBody" (click)='showBody = !showBody'></i>
							{{ trans.trans.title.value}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.title" key='keyFigures.title' [isTranslationV2]="true"></app-editTranslation>
						</div>

					</div>
				</div>
			</div>
			<div class='row' *ngIf="showBody">
				<div class='col-xs-12'>
					<div class="key-features">
						<div class="key-features-list">
							<div class="key-features-label">
								{{ trans.trans.totalBenefits.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.totalBenefits" key='keyFigures.totalBenefits' [isTranslationV2]="true"></app-editTranslation>
							</div>
							<div class="key-features-value">
								{{valueProp.total_benefits_fmt}}
								<i class='fa fa-info-circle' *ngIf="isDev && !(style2022$ | async)" [ngbTooltip]="prev.total_benefits_fmt" container="body" placement="right"></i>
								<span *ngIf="isDev && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="prev.total_benefits_fmt" container="body" placement="right">
									info
								</span>
							</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">
								{{ trans.trans.totalCosts.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.totalCosts" key='keyFigures.totalCosts' [isTranslationV2]="true"></app-editTranslation>
							</div>
							<div class="key-features-value">
								{{valueProp.total_costs_fmt}}
								<i class='fa fa-info-circle' *ngIf="isDev && !(style2022$ | async)" [ngbTooltip]="prev.total_costs_fmt" container="body" placement="right"></i>
								<span *ngIf="isDev && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="prev.total_costs_fmt" container="body" placement="right">
									info
								</span>
							</div>
						</div>
						<div class="key-features-list" *ngIf="!feature62">
							<div class="key-features-label">{{ trans.trans.ROI.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.ROI" key='keyFigures.ROI' [isTranslationV2]="true"></app-editTranslation>
							</div>
							<div class="key-features-value">{{valueProp.roi_decorated}}
								<i class='fa fa-info-circle' *ngIf="isDev && !(style2022$ | async)" [ngbTooltip]="prev.total_benefits_fmt" container="body" placement="right"></i>
								<span *ngIf="isDev && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="prev.total_benefits_fmt" container="body" placement="right">
									info
								</span>
							</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{ trans.trans.NPV.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.NPV" key='keyFigures.NPV' [isTranslationV2]="true"></app-editTranslation>
							</div>
							<div class="key-features-value">{{valueProp.npv_fmt}}
								<i class='fa fa-info-circle' *ngIf="isDev" [ngbTooltip]="prev.npv_fmt" container="body" placement="right"></i>
							</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">
								{{ trans.trans.paybackPeriod.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.paybackPeriod" key='keyFigures.paybackPeriod' [isTranslationV2]="true"></app-editTranslation>
							</div>
							<div class="key-features-value">{{valueProp.payback}}
								<i class='fa fa-info-circle' *ngIf="isDev && !(style2022$ | async)" [ngbTooltip]="prev.payback" container="body" placement="right"></i>
								<span *ngIf="isDev && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="prev.payback" container="body" placement="right">
									info
								</span>
							</div>
						</div>
						<div class="key-features-list" *ngIf="tcoFeature">
							<div class="key-features-label">{{ trans.trans.tco.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.tco" key='keyFigures.tco' [isTranslationV2]="true"></app-editTranslation>
							</div>
							<div class="key-features-value">{{valueProp.tco_fmt}}
								<i class='fa fa-info-circle' *ngIf="isDev && !(style2022$ | async)" [ngbTooltip]="prev.tco_fmt" container="body" placement="right"></i>
								<span *ngIf="isDev && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="prev.tco_fmt" container="body" placement="right">
									info
								</span>
							</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{ trans.trans.mi.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.mi" key='keyFigures.mi' [isTranslationV2]="true"></app-editTranslation>
							</div>
							<div class="key-features-value">{{valueProp.three_month_cost_of_inaction_fmt}}
								<i class='fa fa-info-circle' *ngIf="isDev && !(style2022$ | async)" [ngbTooltip]="prev.three_month_cost_of_inaction_fmt" container="body" placement="right"></i>
								<span *ngIf="isDev && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="prev.three_month_cost_of_inaction_fmt" container="body" placement="right">
									info
								</span>
							</div>
						</div>
						<div *ngIf="valuePropLoader" class="loader-container-figures">
							<!-- <img class="loader" src="{{fullImagePath}}" /> -->
						</div>

						<div>
							<span [matTooltip]="trans.trans.ch_click_to_change_status.value" container="body" matTooltipPosition="right" (click)="canEdit ? menuStatus.toggle($event) : null" *ngIf="!valuePropStatusEditable && canEdit" class="clickable editable">
								{{trans.trans.status.value}} {{valueProp.vp_status ? valueProp.vp_status : "Click to change status"}}
							</span>
							<p *ngIf="valuePropStatusComment && !valuePropStatusEditable"><b>Comment: </b>{{valuePropStatusComment}}
							<p>
						</div>
						<div *ngIf="valuePropStatusEditable" class="clickable editableBox">
							<div class='input-group'>

								<!-- <select class="form-control" style='max-width: 100%; margin-right: 0px;' name="valuePropStatus" [(ngModel)]="valuePropStatus">
									<option *ngFor="let propstatus of valuePropStatusTypes" [ngValue]="propstatus" [selected]='valueProp.vp_status == propstatus.name ? "selected" : ""'>
										{{propstatus.name}}
									</option>
								</select> -->

								<!-- <div class='input-group-btn' style='width: 60px;'>
									<button style='height: 34px;' (click)="updateValuePropStatus(valuePropStatus); " class="btn btn-success">
										<i class="fa fa-check"></i>
									</button>
									<button style='height: 34px;' (click)="valuePropStatusEditable = !valuePropStatusEditable" class="btn btn-danger">
										<i class="fa fa-times"></i>
									</button>
								</div> -->
							</div>
							<!-- <div *ngIf="valuePropStatus && valuePropStatus.comment == 1">
								<label>{{ trans.trans.comments.value}} <small style='color: red;'>{{ trans.trans.required.value}}</small></label>
								<textarea class='form-control' name='valuePropStatusComment' [(ngModel)]="valuePropStatusComment" rows='3'></textarea>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="dashboard == 'v2'">
	<div class="key-features">

		<div *ngIf="feature77" style='margin-bottom: 15px;;'>
			<div class="row" style='font-weight: bold;'>
				<div class='col-xs-12'>
					Value Prop Name
				</div>
			</div>
			<div class="row">
				<div class='col-xs-12'>
					{{valueProp.name}}
					<a *ngIf="!changeName && valueProp.closed !== '1'" matTooltip='Rename Business Case' (click)="changeName = !changeName"><i style='margin-right: 10px;' class='fa fa-pencil'></i></a>
				</div>
			</div>
			<div class='input-group' *ngIf="changeName">
				<input [(ngModel)]="vp_name" class='form-control' />
				<div class='input-group-btn'>
					<button (click)="changeValuePropName(valueProp.id)" class='btn btn-success' matTooltip='Save' matTooltipPosition="above" container='body'><i class='fa fa-save'></i></button>
					<button (click)="cancelValuePropName()" class='btn btn-danger' matTooltip='Cancel' matTooltipPosition="above" container='body'><i class='fa fa-times'></i></button>
				</div>
			</div>
		</div>

		<div class="key-features-list">
			<div class="key-features-label" style='font-weight: bold;'>
				{{ trans.trans.totalBenefits.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.totalBenefits" key='keyFigures.totalBenefits' [isTranslationV2]="true"></app-editTranslation>
			</div>
			<div class="key-features-value">{{valueProp.total_benefits_fmt}}</div>
		</div>
		<div class="key-features-list">
			<div class="key-features-label" style='font-weight: bold;'>
				{{ trans.trans.totalCosts.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.totalCosts" key='keyFigures.totalCosts' [isTranslationV2]="true"></app-editTranslation>
			</div>
			<div class="key-features-value">{{valueProp.total_costs_fmt}}</div>
		</div>
		<div class="key-features-list" *ngIf="!feature62">
			<div class="key-features-label" style='font-weight: bold;'>{{ trans.trans.ROI.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.ROI" key='keyFigures.ROI' [isTranslationV2]="true"></app-editTranslation>
			</div>
			<div class="key-features-value">{{valueProp.roi_decorated}}</div>
		</div>

		<div class="key-features-list" *ngIf="feature37">
			<div class="key-features-label">{{ trans.trans.NPV.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.NPV" key='keyFigures.NPV' [isTranslationV2]="true"></app-editTranslation>
			</div>
			<div class="key-features-value">{{valueProp.npv_fmt}}</div>
		</div>
		<div class="key-features-list">
			<div class="key-features-label" style='font-weight: bold;'>
				{{ trans.trans.paybackPeriod.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.paybackPeriod" key='keyFigures.paybackPeriod' [isTranslationV2]="true"></app-editTranslation>
			</div>
			<div class="key-features-value">{{valueProp.payback}}</div>
		</div>
		<div class="key-features-list" *ngIf="tcoFeature">
			<div class="key-features-label" style='font-weight: bold;'>{{ trans.trans.tco.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.tco" key='keyFigures.tco' [isTranslationV2]="true"></app-editTranslation>
			</div>
			<div class="key-features-value">{{valueProp.tco_fmt}}</div>
		</div>
		<div class="key-features-list">
			<div class="key-features-label" style='font-weight: bold;'>{{ trans.trans.mi.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' [component]="trans.config.component" [transObj]="trans.trans.mi" key='keyFigures.mi' [isTranslationV2]="true"></app-editTranslation>
			</div>
			<div class="key-features-value">{{valueProp.three_month_cost_of_inaction_fmt}}</div>
		</div>
		<div *ngIf="valuePropLoader" class="loader-container-figures">
			<!-- <img class="loader" src="{{fullImagePath}}" /> -->
		</div>

		<div>
			<span (click)="canEdit ? menuStatus.toggle($event) : null" *ngIf="!valuePropStatusEditable" class="clickable" [matTooltip]="trans.trans.ch_click_to_change_status.value" container="body" matTooltipPosition="below">
				Status: <span class='link_customizable'>{{valueProp.vp_status ? valueProp.vp_status : trans.trans.change_status.value}}</span>
			</span>
			<p *ngIf="valuePropStatusComment && !valuePropStatusEditable"><b>{{ trans.trans.comment.value}} </b>{{valuePropStatusComment}}
			<p>
		</div>
		<div *ngIf="valuePropStatusEditable" class="clickable editableBox">
			<div class='input-group'>
				<!-- <select class="form-control" style='max-width: 100%; margin-right: 0px;' name="valuePropStatus" [(ngModel)]="valuePropStatus">
					<option *ngFor="let propstatus of valuePropStatusTypes" [ngValue]="propstatus" [selected]='valueProp.vp_status == propstatus.name ? "selected" : ""'>
						{{propstatus.name}}
					</option>
				</select> -->

				<!-- <div class='input-group-btn' style='width: 60px;'>
					<button style='height: 34px;' (click)="updateValuePropStatus(valuePropStatus); " class="btn btn-success">
						<i class="fa fa-check"></i>
					</button>
					<button style='height: 34px;' (click)="valuePropStatusEditable = !valuePropStatusEditable" class="btn btn-danger">
						<i class="fa fa-times"></i>
					</button>
				</div> -->
			</div>

		</div>
	</div>
</div>
