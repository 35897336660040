import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VGPService } from '@data/services/value-prop-group/vgp.service';
import { ValuePropGroupDashboardTranslations } from './vpg.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DashboardImplementation } from '../../dashboard.implementation';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ValueGroupListData } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-dashboard-vpg',
  templateUrl: './vpg.component.html',
})
export class DashboardValuePropGroupComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  vpgList: ValueGroupListData[] = [];
  uid: string;
  headerColor: string;
  feature34 = false;
  showTranslate = false;
  toggleAdd = false;
  selectedElem = { name: '' };

  constructor(
    public commonService: CommonService,
    private VPGService: VGPService,
    private translationService: TranslationsV2Service,
    public trans: ValuePropGroupDashboardTranslations,
    public dbI: DashboardImplementation,
    private router: Router
  ) {}

  ngOnInit(): void{
    this.headerColor = localStorage.getItem('header_color');
    this.feature34 = this.commonService.checkFeature('34');
    this.uid = sessionStorage.getItem('uid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getData(): void {
    this.VPGService.getVPGGroupList(this.uid, 1, '&limit=5')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.vpgList = res.result.data;
      });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  openAddVPG(): void {
    this.toggleAdd = true;
  }

  funcPostGroup(form: NgForm): void {
    const payload = {
      group_type_id: '1',
      name: form.controls.name.value,
    };

    const crm = sessionStorage.getItem('crm');

    if (crm) {
      const crmJson: any = JSON.parse(crm);
      const sfoide = crmJson.sfoide;
      payload['sfoide'] = sfoide;
    }

    this.VPGService.postCreateVPG(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.toggleAdd = false;
        const id = res.result.value_prop_group.id;
        this.router.navigate(['/vpg/' + id]);
      });
  }
}
