import { Injectable } from '@angular/core';

@Injectable ()

export class KeyFigureTranslations {

    public config: any = {
        component: 'keyFiguresVpDashboard',
        display_name: 'Value Prop Key Figures',
        description: 'Key figures for value prop dashboard'
    };

    public trans: any = {
        title: 'Key Figures',
        mi: '3 Month Cost of Delay',
        NPV: 'NPV',
        ROI: 'ROI',
        paybackPeriod: 'Payback Period (Months)',
        tco: 'TCO Improvements',
        totalBenefits: 'Total Benefits',
        totalCosts: 'Total Costs',
        change_status: 'Click to change status',
        comment: 'Comment:',
        comments: 'Comments',
        figures: 'Key Figures',
        required: 'Required',
        ch_click_to_change_status: 'Click to change Status',
        status: 'Status:'
    };
}



