<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ trans.add_casestudy.add_case_study | translate }}
  </div>
  <div class="sidebar_container">
    <div class="keywest_panel">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label for="" class="control-label stepsheader marg-h">{{ trans.add_casestudy.step_1_redact | translate }}</label>
            <label class="marg-h">
              <p-inputSwitch name="redact" ret-data="redact" [(ngModel)]="isRedact"></p-inputSwitch>

              <span class="marg-h" *ngIf="isRedact">{{ trans.General.Yes | translate }}</span>
              <span class="marg-h" *ngIf="!isRedact">{{ trans.General.No | translate }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="companiesNoResult">
          <button class="btn btn-primary pull-right" (click)="addCompanyDisplay()" matTooltipPosition="left" matTooltip="Add New">
            <i class="fa fa-plus"></i>{{ trans.add_casestudy.add_new | translate }}
          </button>
        </div>
        <div class="col-md-8">
          <form class="" name="CompanySearchForm" #CompanySearchForm="ngForm" (ngSubmit)="getRecentSearchComp()">
            <div class="form-group">
              <label for="" class="control-label stepsheader">{{ trans.add_casestudy.step_2_pick_your_account | translate }}</label>
              <input type="text" class="form-control" name="companyname" id="companyname" placeholder="Search for a Company" [(ngModel)]="companyname" />
              <i class="fa fa-search name-search" (click)="getRecentSearchComp()"></i>
            </div>
          </form>
        </div>
      </div>
      <div class="text-center actionLoad">
        <img class="actionLoader" *ngIf="showActionLoader" src="{{ fullImagePath }}" />
      </div>

      <p-table *ngIf="!showCompanyDetails" [value]="companiesList" [columns]="columns" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-colum>
          <tr>
            <th>{{ trans.General.name_label | translate }}</th>
            <th class="text-right">{{ trans.Research.revenue | translate }}</th>
            <th class="text-right">{{ trans.Research.employees | translate }}</th>
            <th>{{ trans.Research.ticker | translate }}</th>
            <th>{{ trans.Research.city | translate }}</th>
            <th>{{ trans.Research.country | translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
          <tr>
            <td (click)="selectCompany(company.id)" class="a-link-color clickable">{{ company.name }}</td>
            <td>{{ company.net_sales }}</td>
            <td>{{ company.employees }}</td>
            <td>{{ company.ticker }}</td>
            <td>{{ company.city }}</td>
            <td>{{ company.country }}</td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngIf="companiesNoResult" class="m-t-md col-sm-12 comp_no_data">
        <div class="searchTableContainer text-center">
          <h4>{{ trans.Research.noCompaniesFound | translate }}</h4>
        </div>
      </div>
      <div class="col-sm-12 add-company-container" *ngIf="openAddCompany">
        <h4 class="add-company-title">{{ trans.General.addNew | translate }}</h4>
        <app-add-company [modalCreate]="true" #addCompany [modalCreate]="false" (companyCreated)="companyCreated($event)" (enableSave)="enableSave($event)"></app-add-company>
        <div>
          <button type="button" [disabled]="!enableSaveBtn" class="btn btn-primary pull-right" (click)="addCompany.addCompany()">{{ trans.General.save | translate }}</button>
          <button type="button" class="btn btn-secondary pull-right" (click)="openAddCompany = false; addCompany.ngOnInit()">{{ trans.General.Cancel | translate }}</button>
        </div>
      </div>
      <div class="row" [hidden]="detailedDescription">
        <div *ngIf="showCompanyDetails">
          <div class="col-md-5">
            <h3 class="detailedDescriptionHeader">{{ trans.add_casestudy.heres_what_we_know_about_the_account_you_have_selected | translate }}</h3>
            <ul class="list-unstyled account-info-wrapper">
              <li class="row">
                <div class="col-md-4 account-label">{{ trans.General.name_label | translate }}:</div>
                <div class="col-md-8 account-info">{{ companyDetails.name }}</div>
              </li>
              <li class="row">
                <div class="col-md-4 account-label">{{ trans.add_casestudy.address | translate }}:</div>
                <div class="col-md-8 account-info">{{ companyDetails.address1 }}</div>
              </li>
              <li class="row">
                <div class="col-md-4 account-label">{{ trans.add_casestudy.sector | translate }}:</div>
                <div class="col-md-8 account-info">{{ companyDetails.dl_sector_name }}</div>
              </li>
              <li class="row">
                <div class="col-md-4 account-label">{{ trans.add_casestudy.website | translate }}:</div>
                <div class="col-md-8 account-info">{{ companyDetails.url }}</div>
              </li>
              <li class="row">
                <div class="col-md-4 account-label">{{ trans.add_casestudy.revenue | translate }}:</div>
                <div class="col-md-8 account-info">{{ companyDetails.revenue }}</div>
              </li>
              <li class="row">
                <div class="col-md-4 account-label">{{ trans.add_casestudy.employees | translate }}:</div>
                <div class="col-md-8 account-info">{{ companyDetails.employees }}</div>
              </li>
            </ul>
          </div>
          <div class="col-md-7" *ngIf="companyDetails.synopsis">
            <div class="account-label description-label">{{ trans.add_casestudy.description | translate }}</div>
            <div class="account-info">
              <p>{{ companyDetails.synopsis }}</p>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-md-6">
            <label for="" class="control-label stepsheader"
              >{{ trans.add_casestudy.tags | translate }}
              <small>({{ trans.add_casestudy.enter_comma_separated_keywords_for_tags | translate }})</small>
            </label>
            <div class="account-info">
              <textarea class="tags" [(ngModel)]="tags"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn_customizable m-t-xs m-b-xs" (click)="saveCaseStudy()" [disabled]="detailedDescription">{{ trans.General.save | translate }}</button>
    </div>
  </div>
</div>
