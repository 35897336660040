<p-toast position="top-right"></p-toast>

<div class="row">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="models_panel style-2022">
  <div class="sequencing-layout">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">
            {{ trans.trans?.sequencing?.value }}
          </div>
        </div>
      </div>
    </div>

    <div class="menu-border">
      <dl-menubar *ngIf="menuItems" styleClass="details_menu_bar" class="details_menu_bar" [model]="menuItems"></dl-menubar>
    </div>
    <div class="row" class="search-container">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input type="text" [placeholder]="trans.trans.search.value" [formControl]="searchInput" class="form-control" />
          <span *ngIf="searchInput.value !== ''" (click)="searchInput.reset()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
      <div class="col-xs-8"></div>
    </div>
    <div [ngClass]="{ 'item-grid': currentPage !== 'costs', 'cost-item-grid': currentPage === 'costs' }" class="m-l-md">
      <div></div>
      <div></div>
      <div></div>
      <div>{{ trans.trans.name.value }}</div>
      <div></div>
      <div *ngIf="currentPage === 'costs'">{{ trans.trans.solution_name.value }}</div>
      <div *ngIf="currentPage === 'costs'"></div>
      <div>{{ trans.trans.description.value }}</div>
      <div></div>
    </div>
    <div cdkScrollable class="drag-drop-container m-l-md">
      <div *ngIf="isLoading; else noItems" class="flex-omni-c">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      </div>

      <div *ngIf="!isLoading && allCurrentItems.length > 0" cdkDropListGroup>
        <app-multi-drag-drop (itemsUpdated)="onDrop($event)" [items]="filteredOptions | async" [disabled]="!!searchInput.value">
          <ng-template let-item let-index let-selected="selected">
            <div [ngClass]="{ 'item-grid': currentPage !== 'costs', 'cost-item-grid': currentPage === 'costs' }" class="item-container" [class.selected]="selected">
              <div></div>
              <div>
                <div *ngIf="selected" class="tooltip-container" [matTooltip]="trans.trans.tooltip.value" tooltipPosition="bottom">
                  <i class="pi pi-ellipsis-v"></i>
                  <i class="pi pi-ellipsis-v"></i>
                </div>
              </div>
              <div>
                <ng-container *ngIf="!isLoading">
                  <span>{{ item.sequence }} </span>
                </ng-container>
              </div>
              <div class="item-name">{{ item.name ?? item.metric_name ?? item.category }}</div>
              <div></div>
              <div *ngIf="currentPage === 'costs'">{{ item.solution_name }}</div>
              <div *ngIf="currentPage === 'costs'"></div>
              <div class="item-description">{{ item.description }}</div>
              <div (click)="$event.stopPropagation()" class="ellipsis-menu-container">
                <app-ellipsis-menu [hide]="hideMenu">
                  <ul class="list-group">
                    <li class="list-group-item menu-grid" (click)="moveUp(item.id)">
                      <div><i class="pi pi-arrow-up"></i></div>
                      <span>{{ trans.trans?.moveUp?.value }}</span>
                    </li>
                    <li class="list-group-item menu-grid" (click)="moveDown(item.id)">
                      <div><i class="pi pi-arrow-down"></i></div>
                      <span>{{ trans.trans?.moveDown?.value }}</span>
                    </li>
                    <li class="list-group-item menu-grid" (click)="handleCustomSequenceClick(item.id)">
                      <div><i class="pi pi-sort-alt"></i></div>
                      <span>{{ trans.trans?.customSquence?.value }}</span>
                    </li>
                  </ul>
                </app-ellipsis-menu>
              </div>
            </div>
          </ng-template>
        </app-multi-drag-drop>
        <br />
      </div>
      <ng-template #noItems>
        <div *ngIf="allCurrentItems.length === 0" class="flex-omni-c margin-15-y">
          <h2>{{ trans.trans.noItemsFound.value }}</h2>
        </div>
      </ng-template>
    </div>
    <div class="save-button-container">
      <div class="save-button-spacer">
        <app-are-you-sure
          *ngIf="!isLoading && allCurrentItems.length > 0"
          class="btn btn_customizable"
          [message]="resetPrompt"
          [text]="trans.trans.reset.value"
          icon=""
          (callbackYes)="onReset()"
          [no]="trans.trans.cancel.value"
        ></app-are-you-sure>
        <button class="btn cancel-button" [disabled]="!currentSequenceEdited" (click)="onCancel()">{{ trans.trans.cancel.value }}</button>
        <button class="btn btn_customizable" [disabled]="!currentSequenceEdited" (click)="onSave()">{{ trans.trans.save.value }}</button>
      </div>
    </div>
  </div>
</div>
<p-dialog [modal]="true" [style]="{ width: '20vw' }" (onHide)="showConfirmDialog = false" [header]="trans.trans.unsavedShort.value" [(visible)]="showConfirmDialog">
  {{ trans.trans.unsavedChanges.value }}
</p-dialog>

<p-sidebar [(visible)]="showSidebar" *ngIf="showSidebar" styleClass="keywest_sidebar p-sidebar-md" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans?.customSquence?.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="radio-grid">
            <p-radioButton name="up" value="up" label="Move Before" [(ngModel)]="moveDirection"></p-radioButton>
            <p-radioButton name="down" value="down" label="Move After" [(ngModel)]="moveDirection"></p-radioButton>
            <div></div>
          </div>
          <div class="dropdown-title">{{ trans.trans[currentPage].value }}</div>
          <p-dropdown
            [panelStyleClass]="'dropdown-item-font'"
            (onChange)="handleDropdownChange($event)"
            class="keywest_dropdown dropdown-theme"
            [options]="allCurrentItems | filterArrayByIndex: indexOfSelection"
            [(ngModel)]="selectedMove"
            placeholder=""
            [filter]="true"
            [filterBy]="currentPage === SequencePage.ModelCategories ? 'category' : 'name'"
            [resetFilterOnHide]="true"
            [optionLabel]="currentPage === SequencePage.ModelCategories ? 'category' : 'name'"
            [showClear]="true"
            [autoDisplayFirst]="false"
          ></p-dropdown>
        </div>
        <div class="row sequence-button-spacer">
          <button class="btn btn_customizable pull-right" (click)="saveMoveForm()">{{ trans.trans.move.value }}</button>
          <button class="btn cancel-button pull-right" (click)="closeSidebar()">{{ trans.trans.cancel.value }}</button>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
