import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { DeploymentScheduleComponent } from './deployment-schedule.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {SidebarModule} from 'primeng/sidebar';


import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule} from '@shared_components/editTranslation/edit.directive.module';
import { ProgressBarModule } from 'primeng/progressbar';
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
	imports: [
		ProgressBarModule,
		EditTranslationsModule,
		SidebarModule,
		CommonModule,
		NgbModule,
		FormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [
		DeploymentScheduleComponent
	],
	providers: [Translations],
	exports: [DeploymentScheduleComponent]
})
export class DeploymentScheduleModule { }
