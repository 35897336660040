<div class="col-sm-12 caseStudiestab">
  <div class="pull-right m-b-md m-t-xxl">
    <button class="btn btn-primary" (click)="openAddQuoteModal(addQuoteModal)"><i class="fa fa-plus"></i> {{ trans.trans.add_testimonial.value }}</button>
  </div>
  <div class="clearfix"></div>
  <div class="soln_table_cont" *ngIf="quotes">
    <div class="table-responsive col-sm-12 soln-table">
      <div class="searchTableContainer">
        <div class="">
          <table class="table custom-table">
            <thead>
              <tr>
                <th>{{ trans.trans.title.value }}</th>
                <th>{{ trans.trans.testimonial.value }}</th>
                <th>{{ trans.trans.first_name.value }}</th>
                <th>{{ trans.trans.last_name.value }}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let quote of quotes" class="soln_row grid-row">
                <td>{{ quote.title }}</td>
                <td>{{ quote.quote }}</td>
                <td>{{ quote.first_name }}</td>
                <td>{{ quote.last_name }}</td>
                <td>
                  <div class="dropdown pull-right">
                    <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                      <li class="dropdown-menu-list" (click)="openAddQuoteModal(addQuoteModal, quote)"><i class="icon-pencil"></i> {{ trans.trans.update.value }}</li>
                      <li class="dropdown-menu-list" (click)="openDeleteQuote(deleteQuoteModal, quote.id)"><i class="icon-trash"></i> {{ trans.trans.delete.value }}</li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="quotes.length == 0" class="m-t-md col-sm-12">
      <div class="searchTableContainer text-center">
        <h4>{{ trans.trans.no_testimonials_found.value }}</h4>
      </div>
    </div>
  </div>
</div>

<ng-template #addQuoteModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.add_testimonial.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="addQuoteForm" #addQuoteForm="ngForm">
      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.title.value }}</label>
        <input type="text" class="form-control" name="title" [(ngModel)]="quote.title" required />
      </div>
      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.testimonial.value }}</label>

        <textarea class="form-control" name="quote" [(ngModel)]="quote.quote" required></textarea>
      </div>
      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.first_name.value }}</label>
        <input type="text" class="form-control" name="contact_first_name" [(ngModel)]="quote.contact_first_name" required />
      </div>
      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.last_name.value }}</label>
        <input type="text" class="form-control" name="contact_last_name" [(ngModel)]="quote.contact_last_name" required />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="addQuote(); d('Cross click')" [disabled]="addQuoteForm.invalid">{{ trans.trans.save.value }}</button>
    <p>
      <label class="labelsRequiredInfo m-t-md">{{ trans.trans.isRequired.value }}</label>
    </p>
  </div>
</ng-template>

<ng-template #deleteQuoteModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.delete_case_study_testimonial.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ trans.trans.are_you_sure_that_you_want_to_remove_this_testimonial.value }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="deleteQuote(); c('Close click')">{{ trans.trans.Yes.value }}</button>
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ trans.trans.No.value }}</button>
  </div>
</ng-template>
