import { ScoreCard } from '@shared/models/score-card.model';
import { ValueProp } from './../../../shared/models/value-prop.model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CommonService } from '@data/services/common/common.service';
import { Observable, Subject } from 'rxjs';
import { DefaultTranslations } from './score-card.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
})
export class ScoreCardComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() contextualHelp: any[];
  @Input() valueProp: ValueProp;
  @Input() tabInit: number;
  @Input() showTranslate = false;

  scoreCard: ScoreCard;
  actionLoader = false;
  fullImagePath: string;
  image_url: string;

  ngUnsubscribe = new Subject();
  style2022$: Observable<boolean>;

  constructor(
    private valuepropService: ValuepropService,
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getScoreCard();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }
  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkStatus(vpStatus: string): boolean {
    return parseInt(vpStatus, 10) > 4 ? true : false;
  }

  getScoreCard(): void {
    const value_prop_id = this.valueProp['id'];
    this.actionLoader = true;
    this.valuepropService
      .getScoreCard(value_prop_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.scoreCard = response.result;
        }
        this.actionLoader = false;
      });
  }
}
