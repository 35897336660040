export function customErrors(trans: any) {
  return {
    maxTerm: (term: number) => `${trans.valuePropTerm.value} ${term} ${trans.months.value}`,
    url: (_notUsed: unknown) => trans.url.value,
    unchangedValue: (_notUsed: unknown) => trans.unchangedValue.value,
    twoDecimal: (_notUsed: unknown) => trans.twoDecimal.value,
    noDecimal: (_notUsed: unknown) => trans.noDecimal.value,
    noSpecialCharacter: (_notUsed: unknown) => trans.noSpecialCharacter.value,
    maxTotalValue: (value: number) => trans.maxTotalValue.value,
  };
}
