<div [ngClass]="expand ? 'col-md-12 col-sm-12 col-xs-12' : condensed ? col : 'col-md-6 col-sm-12 col-xs-12'">
	<div class='models_panel' style='margin-bottom: 20px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-6'>
						<div class='title accordion-title'>
							<span class='tourExpandCollapse'>
								<i *ngIf="!expand" (click)='funcToggleExpand()' class='fa fa-expand clickable' style='margin-right: 5px;'></i>
								<i *ngIf="expand" (click)='funcToggleExpand()' class='fa fa-compress clickable' style='margin-right: 5px;'></i>
							</span>
							{{title}}
						</div>
					</div>
					<div class='col-xs-6'>
						<div class='input-group keywest_input_group'>
							<p-dropdown [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''" id='tourChartType' *ngIf="toggleable && toggle" (onChange)='funcDropdownChanged()' appendTo='body' [options]="chartTypes" [(ngModel)]="chartType" class='keywest_dropdown pull-right'></p-dropdown>
							<div class='input-group-addon'>
								<p-toggleButton id="tourChartToggle" *ngIf="toggleable" (onChange)="funcChartToggle()" offLabel="" onLabel="" offIcon="fa fa-bar-chart" onIcon="fa fa-table" [(ngModel)]="toggle" class="pull-right"></p-toggleButton>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class='row'>
				<div class='col-xs-12'>
					<div *ngIf="!data" class='panel-body panel-body-fixed' [ngClass]="condensed ? 'body_condensed' : ''">
						<p class='text-center'>No data found</p>
					</div>

					<div *ngIf="data && data.length" [ngClass]="condensed ? 'body_condensed' : ''">
						<div *ngIf="toggle == false">
							<p-table #dt [columns]="cols" [scrollable]="true" [style]="{width:'100%'}" [value]="data" selectionMode="multiple" [(selection)]="selectedRows" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
								<ng-template pTemplate="caption">
									<div class="p-helper-clearfix">
										<button matTooltip="Export All Table Data" matTooltipPosition="above" id='tourExportTable' type="button" pButton icon="fa fa-download" iconPos="left" (click)="dt.exportCSV()" style="margin-left: 10px;
										margin-top: 10px;"></button>
									</div>
								</ng-template>
								<ng-template pTemplate="header" let-columns>
									<tr>
										<th style='width: 50px;'></th>
										<th *ngFor="let col of columns" style='width: 350px;'>
											{{col.header}}
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData let-columns="columns">
									<tr [pSelectableRow]="rowData">
										<td style='width: 50px; text-align: center;'>
											<a *ngIf="rowData['email']" ngbTooltip="Email {{ rowData['first_name'] ? rowData['first_name'] : rowData['name'] ? rowData['name'] : rowData['email']}}" placement="bottom" container="body" href='mailto:{{rowData["email"]}}'><i class='fa fa-envelope'></i></a>
										</td>
										<td *ngFor="let col of columns" style='width: 350px;'>
											<div *ngIf="rowData['value_prop_id'] !== '' && col.field == 'name'">
												<a href="/valueprop/{{rowData['value_prop_id']}}" matTooltip="Click to view this Value Prop's Details" placement="bottom" container="body">{{rowData[col.field]}}</a>
											</div>
											<div *ngIf="rowData['value_prop_id'] !== '' && col.field !== 'name'" >
												{{rowData[col.field]}}
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
						<div *ngIf="toggle == true">
							<p-chart id='chart' *ngIf="!reload" [options]='options' [height]='condensed ? "320" : "450px"' [type]="chartType.value ? chartType.value : chartType" [data]="chartData"></p-chart>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>