<div class="benefit-detail-container" appHeightCache [trigger]="bI.heightCacheTrigger">
  <div id="width" style="width: 100%"></div>
  <dl-progressbar *ngIf="loading" mode="indeterminate"></dl-progressbar>

  <div *ngIf="!loading" class="table-responsive benefitsDetailTable" style="width: 100%; padding-bottom: 0px">
    <div class="row" *ngIf="benefit && benefit !== null" style="margin-bottom: 20px">
      <div [ngClass]="sidebar ? 'col-lg-12' : 'col-lg-6'">
        <div class="row">
          <div class="col-xs-12" style="margin-bottom: 2.5rem" *ngIf="!canEdit">
            <h3 style="margin-top: 0px" [matTooltip]="trans.trans.ch_renameBenefit.value" matTooltipPosition="above">
              <span class="xf-benefit-title" *ngIf="!valueProp.vp_can_edit || !canEdit">{{ benefit.alt_metric_name ? benefit.alt_metric_name : benefit?.metric_name }} </span>
            </h3>

            <div *ngIf="benefit.tco_name && !canEdit && tcoFeature">
              <h4>{{ trans.trans.tco_name.value }}</h4>
              <span *ngIf="!valueProp.vp_can_edit && !canEdit">{{ benefit.tco_name }} </span>
            </div>
          </div>

          <div class="col-xs-12" style="margin-bottom: 2.5rem" *ngIf="canEdit">
            <h3 for="name" *ngIf="!feature57">
              <span>{{ benefit.alt_metric_name ? benefit.alt_metric_name : benefit?.metric_name }}</span>
              <i (click)="editBenefitName()" *ngIf="!feature57 && !bI.locked && !locked" class="fa fa-pencil clickable" style="margin-left: 10px"></i>
            </h3>
            <div *ngIf="benefit.alt_metric_name">
              <small>{{ trans.trans.original_name.value }}: {{ benefit?.metric_name }}</small>
            </div>

            <div *ngIf="feature57 ? true : benefit.edit_name" class="input-group" style="width: 100%" [matTooltip]="trans.trans.ch_renameBenefit.value" matTooltipPosition="above">
              <input type="text" name="metric_name" *ngIf="!benefit.alt_metric_name" [(ngModel)]="tempBenefit.metric_name" class="form-control" />
              <input type="text" name="metric_name" *ngIf="benefit.alt_metric_name" [(ngModel)]="tempBenefit.alt_metric_name" class="form-control" />
              <div class="input-group-btn" *ngIf="!feature57">
                <span
                  (click)="closeBenefit(); benefit.edit_name = !benefit.edit_name; benefit.showeditable_benefit = !benefit.showeditable_benefit"
                  class="clickable input-group-addon action-button"
                >
                  <i class="fa fa-times"></i>
                </span>
                <span
                  (click)="updateAltMetricName(); benefit.edit_name = !benefit.edit_name; benefit.showeditable_benefit = !benefit.showeditable_benefit"
                  class="clickable input-group-addon action-button"
                >
                  <i class="fa fa-save"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="col-xs-2" *ngIf="feature39">
            <div
              class="editable-controls form-group benefitStatusElement"
              *ngIf="hasFeature46"
              [matTooltip]="trans.trans.is_this_benefit_considered_soft.value"
              matTooltipPosition="above"
            >
              <select class="form-control active-type benefitStatusElement" name="soft" [(ngModel)]="soft" (change)="feature57 ? funcLiveSave('soft', soft) : updateParamSoft()">
                <option value=""></option>
                <option *ngFor="let p of hardSoftDropdown" [ngValue]="p.value">
                  {{ p.label }}
                </option>
              </select>
            </div>
            <a *ngIf="+soft === 2 && strategicMask && hasFeature46" (click)="funcSetMask()" class="btn btn_customizable pull-right">MASK Strategic Benefits</a>
            <a *ngIf="+soft === 2 && !strategicMask && hasFeature46" (click)="funcSetMask()" class="btn btn_customizable pull-right">UNMASK Strategic Benefits</a>
          </div>
        </div>

        <div *ngIf="benefit.tco_name && canEdit && tcoFeature" [matTooltip]="trans.trans.tco_name.value" matTooltipPosition="above" style="margin: 10px 0">
          <h4>{{ trans.trans.tco.value }}</h4>
          <span>{{ benefit.tco_name }}</span>
        </div>

        <div *ngIf="!feature57 && benefit.description" style="min-height: 0px; padding: 0px; margin-bottom: 10px; box-shadow: inherit">
          <h4>
            {{ trans.trans.description.value }}
            <i
              *ngIf="canEdit && !disableEdits"
              (click)=" editDescription()"
              class="clickable fa fa-pencil"
            ></i>
          </h4>
          <div *ngIf="!benefit.edit_description">
            <span [innerHtml]="benefit.description | filterUrlToLink"></span>
          </div>
          <div *ngIf="benefit.edit_description">
            <textarea
              rows="5"
              class="form-control"
              style="width: 100% !important"
              [(ngModel)]="benefit.description_edited"
            ></textarea>
            <button (click)="benefit.edit_description = !benefit.edit_description; benefit.description_edited = benefit.description" class="btn btn_customizable" style="margin-top: 10px">
              Cancel
            </button>
            <button class="btn btn_customizable marg-h" *ngIf="!bI.locked && !locked" (click)="saveMetricDescription()" style="margin-top: 10px">
              {{ trans.trans.save_description.value }}
            </button>
          </div>
        </div>

        <div *ngIf="feature84" style="min-height: 0px; padding: 0px; margin-bottom: 10px; box-shadow: inherit">
          <h4>
            {{ trans.trans.notes2.value }}
            <i
              *ngIf="canEdit"
              (click)="benefit.note_2_edit = true; benefit.metric_note_2_edited = benefit.metric_note_2; benefit.showNote2 = true"
              class="clickable fa fa-pencil"
            ></i>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.notes2"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </h4>
          <div *ngIf="!benefit.note_2_edit">
            <span class="pre-wrap" [innerHtml]="benefit.metric_note_2 | filterUrlToLink"></span>
          </div>
          <form *ngIf="benefit.note_2_edit" name="benefitNotesForm" id="ngForm" #benefitNotesForm="ngForm">
            <textarea
              [placeholder]="trans.trans.writeNoteHere.value"
              rows="5"
              class="form-control"
              style="width: 100% !important"
              [(ngModel)]="benefit.metric_note_2_edited"
              name="metric_note"
            ></textarea>
            <button (click)="benefit.note_2_edit = !benefit.note_2_edit; benefit.metric_note_2_edited = benefit.metric_note_2" class="btn btn_customizable" style="margin-top: 10px">
              Cancel
            </button>
            <button class="btn btn_customizable marg-h" *ngIf="!bI.locked && !locked" (click)="saveMetricNote(2)" style="margin-top: 10px">
              {{ trans.trans.save_note.value }}
            </button>
          </form>
        </div>

        <div *ngIf="feature84" style="min-height: 0px; padding: 0px; margin-bottom: 10px; box-shadow: inherit">
          <h4>
            {{ trans.trans.notes3.value }}
            <i
              *ngIf="canEdit"
              (click)="benefit.showNote3 = true; benefit.metric_note_3_edited = benefit.metric_note_3; benefit.note_3_edit = true"
              class="clickable fa fa-pencil"
            ></i>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.notes3"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </h4>
          <div *ngIf="!benefit.note_3_edit">
            <span class="pre-wrap" [innerHtml]="benefit.metric_note_3 | filterUrlToLink"></span>
          </div>
          <form *ngIf="benefit.note_3_edit" name="benefitNotesForm" id="ngForm" #benefitNotesForm="ngForm">
            <textarea
              placeholder="{{ trans.trans.writeNoteHere.value }}"
              rows="5"
              class="form-control"
              style="width: 100% !important"
              [(ngModel)]="benefit.metric_note_3_edited"
              name="metric_note"
            ></textarea>
            <button
              (click)="benefit.note_3_edit = !benefit.note_3_edit; benefit.metric_note_3_edited = benefit.metric_note_3"
              class="btn btn_customizable"
              style="margin-top: 10px"
            >
              Cancel
            </button>
            <button class="btn btn_customizable marg-h" *ngIf="!bI.locked && !locked" (click)="saveMetricNote(3)" style="margin-top: 10px">
              {{ trans.trans.save_note.value }}
            </button>
          </form>
        </div>

        <div *ngIf="!feature57 && benefit.features" style="min-height: 0px; padding: 0px; margin-bottom: 10px; box-shadow: inherit">
          <h4>
            {{ trans.trans.features.value }}
            <i
              *ngIf="canEdit && !disableEdits"
              (click)="editFeatures()"
              class="clickable fa fa-pencil"
            ></i>
          </h4>
          <div *ngIf="!benefit.edit_features">
            <span [innerHtml]="benefit.features | filterUrlToLink"></span>
          </div>
          <div *ngIf="benefit.edit_features">
            <textarea
              rows="5"
              class="form-control"
              style="width: 100% !important"
              [(ngModel)]="benefit.features_edited"
            ></textarea>
            <button (click)="benefit.edit_features = !benefit.edit_features; benefit.features_edited = benefit.features" class="btn btn_customizable" style="margin-top: 10px">
              Cancel
            </button>
            <button class="btn btn_customizable marg-h" *ngIf="!bI.locked && !locked" (click)="saveMetricFeatures()" style="margin-top: 10px">
              {{ trans.trans.save_features.value }}
            </button>
          </div>
        </div>

        <div style="box-shadow: inherit" *ngIf="benefit.vp_case_studies">
          <h4>{{ trans.trans.caseStudies.value }}</h4>
          <table class="table custom-table benefits-table">
            <tbody *ngIf="benefitCaseStudies">
              <tr *ngFor="let caseStudy of benefitCaseStudies" class="grid-row">
                <ng-container *ngIf="caseStudy.improvement_name !== 'Improvement'; else textImprovement">
                  <td>
                    <a class="link" *ngIf="useHrefLink(caseStudy)" [href]="caseStudy.reference_url" target="_blank">
                      {{ caseStudy.display_name }}
                    </a>
                    <a class="link" *ngIf="useRouterLink(caseStudy)" [routerLink]="funcGetCaseStudiesLink(caseStudy)">
                      <strong>{{ caseStudy.display_name }}</strong>
                    </a>
                    <strong *ngIf="!useHrefLink(caseStudy) && !useRouterLink(caseStudy)">{{ caseStudy.display_name }}</strong>
                    {{ trans.trans.experience_an_improvement_of.value }}
                    <strong>{{ caseStudy.improvement_fmt }}</strong>
                    {{ trans.trans.with_this_benefit.value }}
                  </td>
                </ng-container>
                <ng-template #textImprovement>
                  <td>
                    <a class="link" *ngIf="useHrefLink(caseStudy)" [href]="caseStudy.reference_url" target="_blank"> {{ caseStudy.display_name }}&nbsp;</a>
                    <a class="link" *ngIf="useRouterLink(caseStudy)" [routerLink]="funcGetCaseStudiesLink(caseStudy)">
                      <strong>{{ caseStudy.display_name }}&nbsp;</strong>
                    </a>
                    <strong *ngIf="!useHrefLink(caseStudy) && !useRouterLink(caseStudy)">{{ caseStudy.display_name }}&nbsp;</strong>
                    <strong>{{ caseStudy.improvement }}</strong>
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="hasSubtypes">
          <h4>{{ trans.trans.subtypes.value }}</h4>
          <app-sub-types
            [valueMetric]="valueMetric"
            [valueProp]="valueProp"
            (closeForm)="benefit.subtypes_expanded = false"
            (refreshBenefit)="loadBenefits(true); bI.loadBenefits(benefit.id)"
          >
          </app-sub-types>
        </div>

        <div *ngIf="benefit.capabilities && benefit.capabilities.length" style="min-height: 0px; padding: 0px; margin-bottom: 10px; box-shadow: inherit">
          <h4>{{ trans.trans.capabilities.value }}</h4>
          <div *ngFor="let elem of benefit.capabilities" style="margin-bottom: 10px">
            <div style="margin-left: 20px">
              <b
                >{{ elem.name }}
                <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
              </b>
            </div>
          </div>
        </div>

        <div *ngIf="benefit.pain_points && benefit.pain_points.length" style="min-height: 0px; padding: 0px; margin-bottom: 10px; box-shadow: inherit">
          <h4>{{ trans.trans.painPoints.value }}</h4>
          <div *ngFor="let elem of benefit.pain_points" style="margin-bottom: 10px">
            <div style="margin-left: 20px">
              <b
                >{{ elem.name }}
                <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
              </b>
            </div>
          </div>
        </div>

        <div *ngIf="benefit.operation_objectives && benefit.operation_objectives.length" style="min-height: 0px; padding: 0px; margin-bottom: 10px; box-shadow: inherit">
          <h4>{{ trans.trans.operationalObjectives.value }}</h4>
          <div *ngFor="let elem of benefit.operation_objectives" style="margin-bottom: 10px">
            <div style="margin-left: 20px">
              <b
                >{{ elem.name }}
                <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
              </b>
            </div>
          </div>
        </div>

        <div class="col-xs-12" *ngIf="feature39" style="margin-top: 15px">
          <h4>{{ trans.trans.additionalDetails.value }}</h4>
          <div class="row">
            <div class="col-xs-12">
              <div class="col-xs-4" *ngIf="impact">
                <label>{{ trans.trans.impact.value }}:</label> {{ impact.name }}
              </div>

              <div class="col-xs-4" *ngIf="annualBenefits">
                <label>{{ trans.trans.accrual.value }}:</label> {{ annualBenefits.name }}
              </div>
              <div class="col-xs-4" *ngIf="!hasFeature46">
                <label>{{ trans.trans.is_this_benefit_considered_soft.value }}:</label>
                <div *ngIf="!editSoft">
                  <span>{{ soft ? trans.trans.yes.value : trans.trans.no.value }}</span>
                  <i *ngIf="canEdit && !bI.locked" (click)="editSoft = !editSoft" style="margin-left: 10px" class="fa fa-pencil"></i>
                </div>
                <div *ngIf="editSoft && canEdit">
                  <p-inputSwitch styleClass="inputwitch_header" name="optional_toggle" [(ngModel)]="soft"> </p-inputSwitch>
                  <i (click)="editSoft = !editSoft" style="margin-left: 10px" class="fa fa-times"></i>
                  <i (click)="updateParamSoft()" style="margin-left: 10px" class="fa fa-save"></i>
                </div>
              </div>

              <div class="col-xs-4" *ngIf="expense_type_id">
                <label>{{ trans.trans.expense_type.value }}:</label> {{ expense_type_id.name }}
              </div>

              <div class="col-xs-4" *ngIf="benefitType && !hasFeature46">
                <label>{{ trans.trans.what_type_of_benefit_is_this.value }}:</label>
                <div *ngIf="!editBenefitType">
                  {{ benefitType.name }} <i *ngIf="canEdit && !bI.locked" (click)="editBenefitType = !editBenefitType" style="margin-left: 10px" class="fa fa-pencil"></i>
                </div>
                <div *ngIf="editBenefitType && canEdit" class="input-group">
                  <select *ngIf="benefitTypeList && benefitTypeList.length" class="form-control" name="benefitType" [(ngModel)]="benefitType">
                    <option value="" selected="selected"></option>
                    <option *ngFor="let p of benefitTypeList" [ngValue]="p.id">
                      {{ p.name }}
                    </option>
                  </select>
                  <div class="input-group-addon">
                    <i (click)="editBenefitType = !editBenefitType" class="fa fa-times"></i>
                  </div>
                  <div class="input-group-addon">
                    <i (click)="updateParamBenefitType()" class="fa fa-save"></i>
                  </div>
                </div>
              </div>

              <div class="col-xs-4" *ngIf="!hasFeature46">
                <label>{{ trans.trans.should_this_benefit_be_quantified.value }}:</label>
                <div *ngIf="!editQuantified">
                  <span>{{ quantified ? trans.trans.yes.value : trans.trans.no.value }}</span>
                  <i *ngIf="canEdit && !bI.locked" (click)="editQuantified = !editQuantified" style="margin-left: 10px" class="fa fa-pencil"></i>
                </div>
                <div *ngIf="editQuantified && canEdit">
                  <p-inputSwitch styleClass="inputwitch_header" name="optional_toggle" [(ngModel)]="quantified"> </p-inputSwitch>
                  <i *ngIf="canEdit" (click)="editQuantified = !editQuantified" style="margin-left: 10px" class="fa fa-times"></i>
                  <i (click)="updateParamQuantified()" style="margin-left: 10px" class="fa fa-save"></i>
                </div>
              </div>

              <div class="col-xs-4">
                <label>{{ trans.trans.model.value }}</label> {{ valueMetric.solution_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="sidebar ? 'col-lg-12' : 'col-lg-6'">
        <div>
          <div class="col-xs-5" style="padding: 0px; margin-top: 1rem" *ngIf="canEdit">
            <div class="editable-controls form-group benefitStatusElement" matTooltip="Benefit Status" matTooltipPosition="above">
              <select
                class="form-control active-type benefitStatusElement"
                name="benefit_active"
                [(ngModel)]="benefit.active"
                (change)="!bI.showBatchUpdateCheckboxes ? statusChange() : feature57 ? funcLiveSave('active', benefit.active) : ''"
              >
                <option value="" class="benefitStatusElement" disabled>{{ trans.trans.choose.value }}</option>
                <option *ngFor="let activeType of activeTypes" [value]="activeType.value" class="benefitStatusElement">
                  {{ activeType.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-xs-7 row marg-v" *ngIf="!feature57">
            <div class="col-xs-12">
              <a (click)="editing_scratch = !editing_scratch" *ngIf="shareView === 'simple' && !editing_scratch && !locked && canEdit" class="btn btn_customizable pull-right xf-continue-button value-execution-add-a-new-value-proposition-results-benefit-edit-button" data-heap="value-execution-add-a-new-value-proposition-results-benefit-edit-button">{{
                trans.trans.edit.value
              }}</a>
              <a (click)="editing_scratch = !editing_scratch; updateBenefit()" *ngIf="editing_scratch" class="btn btn_customizable pull-right xf-continue-button value-execution-add-a-new-value-proposition-results-benefit-save-changes-button" data-heap="value-execution-add-a-new-value-proposition-results-benefit-save-changes-button">{{
                trans.trans.save_changes.value
              }}</a>
              <a (click)="editing_scratch = !editing_scratch" *ngIf="editing_scratch" class="btn btn_customizable pull-right marg-h xf-continue-button value-execution-add-a-new-value-proposition-results-benefit-cancel-changes-button" data-heap="value-execution-add-a-new-value-proposition-results-benefit-cancel-changes-button">{{ trans.trans.cancel.value }}</a>
            </div>
          </div>
        </div>

        <div class="col-xs-12" style="padding: 0px; margin: 0">
          <p-table
            #dt2
            id="formula-table"
            dataKey="formula"
            [columns]="selectedColumns"
            [value]="tableData"
            [resizableColumns]="true"
            [styleClass]="'keywest_table'"
            [tableStyleClass]="'keywest_table'"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngIf="!embedded" style="width: 3em"></th>
                <th
                  *ngFor="let col of columns"
                  [ngStyle]="{ 'white-space': 'nowrap', 'width': col.width ? col.width : 'inherit', 'min-width': col.minwidth ? col.minwidth : 'inherit' }"
                >
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
              <ng-container *ngIf="!rowData.hideRow">
                <tr [ngClass]="{ total: rowData['type'] === 'total' }">
                  <td *ngIf="!embedded">
                    <span
                      [matTooltip]="trans.trans.growth.value"
                      *ngIf="rowData.showGrowthDecline && !hideGrowthDecline && +selectedBenefit.driver_factor_id !== 2"
                      (click)="onShowGrowthDecline()"
                      class="material-icons growth-decline-icon"
                    >
                      expand
                    </span>
                    <a href="#" (click)="rowData['showPhasing'] = !expanded" *ngIf="rowData.type === 'phasing' && !locked && canEdit" [pRowToggler]="rowData">
                      <i class="fa fa-signal"></i>
                    </a>
                    <a href="#" *ngIf="!editing_scratch && rowData['hasScratchpad']" [pRowToggler]="rowData">
                      <img *ngIf="rowData.scratchpad_active" style="width: 15px" src="assets/images/scratchpad/calculator.png" alt="" />
                      <img
                        *ngIf="!rowData.scratchpad_active"
                        [matTooltip]="trans.trans.inactiveScratchpad.value"
                        style="width: 21px"
                        src="assets/images/scratchpad/calculator-inactive.png"
                        alt=""
                      />
                    </a>
                  </td>
                  <td *ngFor="let col of columns; let i = index" [attr.data-index]="i" [ngStyle]="{ 'text-align': col.style }" class="td-formula">
                    <span
                      [ngClass]="{ phase_override: rowData['year' + i + '_phase_override'] }"
                      *ngIf="useFmtField(rowData, col)"
                      >{{ rowData[col.field + '_fmt'] }}</span
                    >
                    <span
                      [ngClass]="{ phase_override: rowData['year' + i + '_phase_override'] }"
                      *ngIf="useField(rowData, col)"
                      >{{ rowData[col.field] }}</span
                    >
                    <span
                      *ngIf="rowData.description && col.field === 'formula' && !rowData.editing"
                      class="material-icons-outlined tooltip-custom info-icon"
                      [matTooltip]="rowData.description"
                    >
                      info
                    </span>
                    <div *ngIf="rowData.editing && col.field === 'formula'" class="input-group">
                      <input [(ngModel)]="rowData.formula_edited" type="text" class="form-control" />
                      <span class="clickable input-group-addon" (click)="rowData.formula_edited = rowData.formula; rowData.editing = false"><i class="fa fa-times"></i></span>
                      <span class="clickable input-group-addon" (click)="funcSaveFactorName(rowData)"><i class="fa fa-save"></i></span>
                    </div>
                    <i
                      *ngIf="!embedded && col.field === 'formula' && !rowData.editing && !(rowData.type === 'total' || rowData.type === 'phasing') && canEdit"
                      (click)="rowData.editing = !rowData.editing"
                      style="margin-left: 10px"
                      class="clickable fa fa-pencil"
                    ></i>

                    <div *ngIf="col.editable && editing_scratch && rowData.editable && !rowData.editing">
                      <input
                        type="text"
                        *ngIf="funcShowHideInput(rowData, col)"
                        class="form-control edit-formula-control"
                        [disabled]="+rowData.literal_number === 1"
                        (keyup)="improvementKeyPress(col, rowData)"
                        [(ngModel)]="rowData[col.field]"
                      />
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
              <tr class="not-hover">
                <td [attr.colspan]="columns.length | functionPipe : colClamp">
                  <app-benefit-phasing *ngIf="rowData.type === 'phasing'" [mini]="bI.mini" [valueProp]="valueProp" (reloadVP)="phasingChanged($event)" [benefit]="benefit">
                  </app-benefit-phasing>
                  <div *ngIf="termYears.length > 0">
                    <p-tabView>
                      <p-tabPanel *ngFor="let yr of termYears" header="{{ yr.label }}">
                        <app-scratchpad-component
                          *ngIf="!rowData['showPhasing'] && !(rowData.type === 'phasing')"
                          [locked]="locked || !canEdit"
                          [mini]="bI.mini"
                          (reloadVP)="reloadVP.emit()"
                          (SaveAndUpdateCallback)="dt2.toggleRow(rowData); loadBenefits(true)"
                          (benefitUpdated)="scratchpadCallback()"
                          [totalColumns]="totalColumns"
                          [factorTypeId]="rowData['factorTypeId']"
                          [valuePropId]="valueProp.id"
                          [valueMetric]="valueMetric"
                          [nameEditable]="true"
                          [manualUpdate]="true"
                          [year]="yr.value"
                        >
                        </app-scratchpad-component>
                      </p-tabPanel>
                    </p-tabView>
                  </div>
                  <div *ngIf="termYears.length === 0">
                    <app-scratchpad-component
                      *ngIf="!rowData['showPhasing'] && !(rowData.type === 'phasing')"
                      [locked]="locked || !canEdit"
                      [mini]="bI.mini"
                      (reloadVP)="reloadVP.emit()"
                      (SaveAndUpdateCallback)="dt2.toggleRow(rowData); loadBenefits(true)"
                      (benefitUpdated)="scratchpadCallback()"
                      [totalColumns]="totalColumns"
                      [factorTypeId]="rowData['factorTypeId']"
                      [valuePropId]="valueProp.id"
                      [valueMetric]="valueMetric"
                      [nameEditable]="true"
                      [manualUpdate]="true"
                      [year]="1"
                    >
                    </app-scratchpad-component>
                  </div>
                </td>
                <td *ngIf="columns.length > 9" [attr.colspan]="columns.length - 9 + 1"></td>
              </tr>
            </ng-template>
          </p-table>

          <div style="margin-top: 1.5rem">
            <div *ngIf="hasOverrides" class="phase_override_txt pull-right">
              {{ trans.trans.containsOverriddenValue.value }}
              <button (click)="op.toggle($event)" class="btn btn_customizable_grey ng-star-inserted"><span style="font-weight: bold">x</span> Clear Overrides</button>
            </div>
            <p-overlayPanel #op appendTo="body" class="are-you-sure-overlay">
              <div class="panel-overlay panel-white">
                <div class="panel-heading" style="height: inherit !important; text-align: center">
                  <div class="panel-title">{{ trans.trans.clearOverrides.value }}</div>
                </div>
              </div>
              <div class="panel-body panel-body-overlay" style="padding: 0px">
                <div class="you-sure-content">
                  <div style="padding: 10px 10px 20px 10px">{{ trans.trans.clearOverridesWarning.value }}</div>
                  <div class="panel-btn-container">
                    <button (click)="clearOverrides(benefit)" class="btn btn-overlay btn-success" style="float: left; width: 50%">Yes</button>
                    <button (click)="cancelClear()" class="btn btn-overlay btn-danger" style="float: left; width: 50%">No</button>
                  </div>
                </div>
              </div>
            </p-overlayPanel>
            <h4>
              {{ trans.trans.notes.value }}
              <i *ngIf="canEdit" (click)="benefit.showNote = true; benefit.metric_note_edited = benefit.metric_note; benefit.editNote = true" class="clickable fa fa-pencil"></i>
            </h4>
            <div *ngIf="!benefit.editNote">
              <span class="pre-wrap" [innerHtml]="benefit.metric_note | filterUrlToLink"></span>
            </div>
            <form *ngIf="benefit.editNote" name="benefitNotesForm" id="ngForm" #benefitNotesForm="ngForm">
              <textarea
                placeholder="{{ trans.trans.writeNoteHere.value }}"
                rows="5"
                class="form-control"
                style="width: 100% !important"
                [(ngModel)]="benefit.metric_note_edited"
                name="metric_note"
              ></textarea>
              <button
                (click)="benefit.editNote = !benefit.editNote; benefit.metric_note_edited = benefit.metric_note"
                class="btn btn_customizable"
                style="margin-top: 10px; margin-right: 10px"
              >
                {{ trans.trans.cancel.value }}
              </button>
              <button class="btn btn_customizable marg-h" *ngIf="!bI.locked && !locked" (click)="saveMetricNote(1)" style="margin-top: 10px">
                {{ trans.trans.save_note.value }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-sidebar [(visible)]="showGrowthDecline" *ngIf="showGrowthDecline" styleClass="keywest_sidebar p-sidebar-lg" position="right" [modal]="false">
    <form name="addMetricPhasing" #addMetricPhasing="ngForm">
      <div class="sidebar_subpanel">
        <div class="row">
          <div class="col-xs-10" style="margin-left: 2rem">
            <div class="title">
              {{ trans.trans.growthAndDecline.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.growthAndDecline"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </div>
          </div>
        </div>
        <div>
          <h1 class="subTitle">{{ trans.trans.GrowthAfter1stYear.value }}</h1>
        </div>
        <div class="sidebar_container">
          <div class="row driver-wrapper">
            <div *ngFor="let driver of metricbenefits; let index = index">
              <!-- Input -->
              <div class="col-xs-9 slider-wrapper">
                <label>
                  {{ trans.trans.year.value }} {{ index + 2 }}
                  <span class="phase_override_txt" *ngIf="phasingOverrides[driver + '_override']">*</span>
                </label>
                <input type="range" min="-100" max="100" name="driveinput{{ index }}" [(ngModel)]="driverPhasing[driver]" />
              </div>
              <!-- Range -->
              <div class="col-xs-3">
                <input [readonly]="!canEdit" type="number" min="-100" max="100" name="driveinput{{ index }}" class="form-control" [(ngModel)]="driverPhasing[driver]" />
              </div>
            </div>
          </div>
          <div *ngIf="hasPhasingOverrides" class="phase_override_txt pull-right" style="padding-left: 10px !important">
            {{ trans.trans.phasingContainsOverriddenValue.value }}
          </div>
        </div>

        <div class="button-wrapper">
          <button [disabled]="hideMetricLoader" class="btn btn_customizable pull-right" *ngIf="valueProp.vp_can_edit && !locked && canEdit" (click)="saveMetricPhase()">
            {{ trans.trans.save.value }}
          </button>
          <button class="btn btn_cancel pull-right" (click)="this.showGrowthDecline = !this.showGrowthDecline">{{ trans.trans.cancel.value }}</button>
        </div>
      </div>
    </form>
  </p-sidebar>
</div>
