import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BenefitTypesService } from '@data/services/benefit-types/benefit-types.service';
import { CapabilitiesService } from '@data/services/capabilities/capabilities.service';
import { FunctionalObjectivesService } from '@data/services/func_objectives/func_objectives.service';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { PainPointsService } from '@data/services/pain_points/pain.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { DirectivesModule } from 'app/_directives/directives.module';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { AeBenefitTypeComponent } from '../benefit-types/ae-benefit-type/ae-benefit-type.component';
import { BenefitTypeComponent } from '../benefit-types/benefit-type.component';
import { BenefitTypeTranslations } from '../benefit-types/benefit-type.translation';
import { RBenefitTypeComponent } from '../benefit-types/r-benefit-type/r-benefit-type.component';
import { AECapabilitiesComponent } from '../capabilities/ae_capabilities/ae_capabilities.component';
import { ModelsV2AECapabilitiesTranslations } from '../capabilities/ae_capabilities/ae_capabilities.translation';
import { ValueMapCapabilitiesComponent } from '../capabilities/capabilities.component';
import { ModelsV2CapabilitiesTranslations } from '../capabilities/capabilities.translation';
import { RCapabilitiesComponent } from '../capabilities/r_capabilities/r_capabilities.component';
import { ModelsV2RCapabilitiesTranslations } from '../capabilities/r_capabilities/r_capabilities.translation';
import { AEFuncObjectivesComponent } from '../func_objectives/ae_func_objectives/ae_func_objectives.component';
import { ModelV2AEFuncObjectiveTranslations } from '../func_objectives/ae_func_objectives/ae_func_objectives.translation';
import { ValueMapFuncObjectivesComponent } from '../func_objectives/func.component';
import { ModelV2FuncObjectiveTranslations } from '../func_objectives/func.translation';
import { RFuncObjectivesComponent } from '../func_objectives/r_func_objectives/r_func_objectives.component';
import { ModelV2RFuncObjectiveTranslations } from '../func_objectives/r_func_objectives/r_func_objectives.translation';
import { AEModelCategoryComponent } from '../model_category/ae_category/ae_category.component';
import { ModelsV2AEModelCategoryTranslations } from '../model_category/ae_category/ae_category.translation';
import { ModelCategoryComponent } from '../model_category/category.component';
import { ModelsV2ModelCategoryTranslations } from '../model_category/category.translation';
import { RModelCategoryComponent } from '../model_category/r_category/r_category.component';
import { ModelsV2RModelCategoryTranslations } from '../model_category/r_category/r_category.translation';
import { AEOpObjectivesComponent } from '../op_objectives/ae_op_objectives/ae_op_objectives.component';
import { ModelV2AEOPObjectiveTranslations } from '../op_objectives/ae_op_objectives/ae_op_objectives.translation';
import { ValueMapOpObjectivesComponent } from '../op_objectives/op_objectives.component';
import { ModelV2OPObjectiveTranslations } from '../op_objectives/op_objectives.translation';
import { ROpObjectivesComponent } from '../op_objectives/r_op_objectives/r_op_objectives.component';
import { ModelV2ROPObjectiveTranslations } from '../op_objectives/r_op_objectives/r_op_objectives.translation';
import { AEPainPointsComponent } from '../pain_points/ae_painpoints/ae_painpoints.component';
import { ModelV2AEPainPointTranslations } from '../pain_points/ae_painpoints/ae_painpoints.translation';
import { ValueMapPainPointsComponent } from '../pain_points/pain.component';
import { ModelV2PainPointsTranslations } from '../pain_points/pain.translation';
import { RPainPointsComponent } from '../pain_points/r_painpoints/r_painpoints.component';
import { ModelsV2RPainPointsTranslations } from '../pain_points/r_painpoints/r_painpoints.translation';
import { AEValueCategoryComponent } from './ae_value_category/ae_value_category.component';
import { ModelV2EAValueCategoryTranslations } from './ae_value_category/ae_value_category.translation';
import { RValueCategoryComponent } from './r_value_category/r_value_category.component';
import { ModelsV2RValueCategoryTranslations } from './r_value_category/r_value_category.translation';
import { ValueCategoryComponent } from './value-category.component';
import { ModelV2ValueCategoryTranslations } from './value-category.translation';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const components: any[] = [
  ValueCategoryComponent,
  AEValueCategoryComponent,
  RValueCategoryComponent,
  RPainPointsComponent,
  AEPainPointsComponent,
  ValueMapPainPointsComponent,
  ValueMapOpObjectivesComponent,
  ROpObjectivesComponent,
  AEOpObjectivesComponent,
  ModelCategoryComponent,
  RModelCategoryComponent,
  AEModelCategoryComponent,
  ValueMapFuncObjectivesComponent,
  RFuncObjectivesComponent,
  AEFuncObjectivesComponent,
  ValueMapCapabilitiesComponent,
  RCapabilitiesComponent,
  AECapabilitiesComponent,
  BenefitTypeComponent,
  AeBenefitTypeComponent,
  RBenefitTypeComponent,
];
@NgModule({
  declarations: components,
  imports: [
    ProgressBarModule,
    AreYouSureModule,
    MatTooltipModule,
    NGFormSaveButtonModule,
    DLNGErrorDirectiveModule,
    SidebarModule,
    MenuModule,
    TableModule,
    NgbModule,
    FormsModule,
    CommonModule,
    EditTranslationsModule,
    InputSwitchModule,
    DirectivesModule,
    ApplicationPipesModule,
    ButtonModule,
  ],
  providers: [
    ModelV2ValueCategoryTranslations,
    ModelV2EAValueCategoryTranslations,
    ModelsV2RValueCategoryTranslations,
    ModelsV2RPainPointsTranslations,
    ModelV2AEPainPointTranslations,
    ModelV2AEOPObjectiveTranslations,
    ModelV2ROPObjectiveTranslations,
    ModelV2OPObjectiveTranslations,
    PainPointsService,
    OperationalObjectivesService,
    ModelV2PainPointsTranslations,
    ModelsV2ModelCategoryTranslations,
    ModelsV2AEModelCategoryTranslations,
    ModelsV2RModelCategoryTranslations,
    ModelV2FuncObjectiveTranslations,
    ModelV2RFuncObjectiveTranslations,
    ModelV2AEFuncObjectiveTranslations,
    ModelsV2CapabilitiesTranslations,
    ModelsV2RCapabilitiesTranslations,
    ModelsV2AECapabilitiesTranslations,
    FunctionalObjectivesService,
    BenefitTypeTranslations,
    BenefitTypesService,
    CapabilitiesService,
    TranslationsV2Service,
  ],
  entryComponents: components,
  exports: components,
})
export class ModelValueCategoryModule {}
