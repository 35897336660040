<p-menu #menu appendTo="body" [popup]="true" [model]="chartToggle" [styleClass]="style2022 ? 'style-2022' : null"></p-menu>
<p-menu #menuOpportunity appendTo="body" [popup]="true" [model]="chartOpportunityToggle" [styleClass]="style2022 ? 'style-2022' : null"></p-menu>
<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="style2022 ? 'style-2022' : null"></p-menu>
<p-menu #custom appendTo="body" [popup]="true" [model]="term" [styleClass]="style2022 ? 'style-2022' : null"></p-menu>
<p-menu #datesMenu appendTo="body" [popup]="true" [model]="datesToggle" [styleClass]="style2022 ? 'style-2022' : null"></p-menu>

<div *ngIf="false" class="keywest_header" [ngClass]="aI.menuToggle ? 'sticky_sales_manager' : 'sticky_sales_manager_collapsed'" style="margin-bottom: 20px">
  <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
      <a (click)="toggleView('dashboard')" class="btn btn_customizable_left" [ngClass]="view == 'dashboard' ? 'btn_customizable' : null">Dashboard</a>
      <a (click)="toggleView('org')" class="btn btn_customizable_right" [ngClass]="view == 'org' ? 'btn_customizable' : null">Organization Details</a>
    </div>
  </div>
</div>
<div *ngIf="loadingCustom && term_filter.start_end" >
  <dl-progressbar
    class="dl_progress"
    [showValue]="false"
    [showPercent]="false"
    mode="indeterminate"
    [value]="'Loading ' + funcCleanupPChartDate(term_filter.start_end) + ' - ' + term_filter.end_date"
  ></dl-progressbar>
</div>

<div [hidden]="!loading" >
  <p-progressBar [value]="loadingTimeValue"></p-progressBar>
  <dl-progressbar
    class="dl_progress"
    [showValue]="true"
    [value]="cachePercent"
    [label]="'Loading Summary Activity (' + cacheProgress + ' of ' + (term.length - 1) + ')'"
  ></dl-progressbar>
</div>

<div *ngIf="view == 'dashboard'">
  <div [hidden]="loading" >
    <div class="date-range">
      <div class="clickable" (click)="datesMenu.toggle($event)"><i class="fa fa-calendar"></i> {{ dateLabel }} <i class="fa fa-caret-down"></i></div>
    </div>
    <div class="custom-range-container">
      <label for=""></label>
    </div>
    <div *ngIf="dateLabel === 'Custom Range'" class="form-group">
      <label>{{trans.trans.dateRangeLabel.value}}</label>

      <input
        type="text"
        class="form-control"
        ngxDaterangepickerMd
        (datesUpdated)="getCustomDates($event)"
        [alwaysShowCalendars]="true"
        [keepCalendarOpeningWithRange]="true"
        [ranges]="ranges"
        class="form-control"
        [placeholder]="trans.trans.datePickerPlaceholder.value"
      />
    </div>

    <div class="row" *ngIf="!loading">
      <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="dashboard_panel tile">
          <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"
            >{{ trans.trans.user_adoption_share.value }}
            <i *ngIf="!style2022" [matTooltip]="trans.trans.panelTooltipV2.value"
              class="fa fa-info-circle"
            ></i>
            <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.panelTooltipV2.value">
              info
            </span>
          </span>
          <div class="dashboard_value dashboard_value-margin">{{ total_created_vps.user_adoption_share_percentage }}%</div>
        </div>
      </div>

      <div *ngIf="term_filter" class="col-md-3 col-sm-6 col-xs-12">
        <div class="dashboard_panel tile">
          <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"
            >{{ trans.trans.per_user_vp_created.value }}
            <i *ngIf="!style2022" [matTooltip]="trans.trans.panelTooltip.value" class="fa fa-info-circle"></i>
            <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.panelTooltip.value">
              info
            </span>
          </span>
          <div class="dashboard_value dashboard_value-margin">{{ total_created_vps.average_vp_created_per_user }}</div>
          <div style="display: inline" *ngIf="!term_filter.custom">
            <div *ngIf="total_created_vps.percent_change && total_created_vps.change == 'increase'" class="dashboard_label_green">
              <i class="fa fa-arrow-up"></i> {{ total_created_vps.percent_change }}% {{ trans.trans.last_quarter.value }}
            </div>

            <div *ngIf="total_created_vps.percent_change && total_created_vps.change == 'decrease'" class="dashboard_label_red">
              <i class="fa fa-arrow-down"></i> {{ total_created_vps.percent_change }}% {{ trans.trans.last_quarter.value }}
            </div>
            <span *ngIf="total_created_vps.percent_change" class="dashboard_label_right">{{ trans.trans.average.value }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-xs-12" *ngIf="term_filter">
        <div class="dashboard_panel tile">
          <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"
            >{{ trans.trans.per_user_vp_edited.value }}
            <i *ngIf="!style2022" [matTooltip]="trans.trans.panelTooltip.value" class="fa fa-info-circle"></i>
            <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.panelTooltip.value">
              info
            </span>
          </span>
          <div class="dashboard_value dashboard_value-margin">{{ modified_vps.average_vp_modified_per_user }}</div>
          <div style="display: inline" *ngIf="!term_filter.custom">
            <div *ngIf="modified_vps.percent_change && modified_vps.change == 'increase'" class="dashboard_label_green">
              <i class="fa fa-arrow-up"></i> {{ modified_vps.percent_change }}% {{ trans.trans.last_quarter.value }}
            </div>

            <div *ngIf="modified_vps.percent_change && modified_vps.change == 'decrease'" class="dashboard_label_red">
              <i class="fa fa-arrow-down"></i> {{ modified_vps.percent_change }}% {{ trans.trans.last_quarter.value }}
            </div>
            <span *ngIf="modified_vps.percent_change" class="dashboard_label_right">{{ trans.trans.average.value }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-xs-12" *ngIf="term_filter">
        <div class="dashboard_panel tile">
          <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"
            >{{ trans.trans.per_user_logins.value }}
            <i *ngIf="!style2022" matTooltip="The number of WVR logins per user for your organization in the selected time period." class="fa fa-info-circle"></i>
            <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" matTooltip='The number of WVR logins per user for your organization in the selected time period.'>
              info
            </span>
          </span>
          <div class="dashboard_value dashboard_value-margin">{{ logins.average_logins_per_user }}</div>
          <div style="display: inline" *ngIf="!term_filter.custom">
            <div *ngIf="logins.percent_change && logins.change == 'increase'" class="dashboard_label_green">
              <i class="fa fa-arrow-up"></i> {{ logins.percent_change }}% {{ trans.trans.last_quarter.value }}
            </div>

            <div *ngIf="logins.percent_change && logins.change == 'decrease'" class="dashboard_label_red">
              <i class="fa fa-arrow-down"></i> {{ logins.percent_change }}% {{ trans.trans.last_quarter.value }}
            </div>
            <span *ngIf="logins.percent_change" class="dashboard_label_right">{{ trans.trans.average.value }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 20px" *ngIf="!loading">
      <div class="col-md-4 col-xs-12">
        <div class="dashboard_panel dashboard_panel_chart tile">
          <div class="row">
            <div class="col-xs-12">
              <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"
                >{{ trans.trans.value_props_created.value }}
                <i *ngIf="!style2022" [matTooltip]="trans.trans.panelTooltip.value" class="fa fa-info-circle"></i>
                <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.panelTooltip.value">
                  info
                </span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <div class="dashboard_value dashboard_value-margin">{{ total_created_vps.count }}</div>
              <div style="display: inline" *ngIf="!term_filter.custom">
                <div *ngIf="total_created_vps.percent_change && total_created_vps.change == 'increase'" class="dashboard_label_green">
                  <i class="fa fa-arrow-up"></i> {{ total_created_vps.percent_change }}% {{ trans.trans.last_quarter.value }}
                </div>

                <div *ngIf="total_created_vps.percent_change && total_created_vps.change == 'decrease'" class="dashboard_label_red">
                  <i class="fa fa-arrow-down"></i> {{ total_created_vps.percent_change }}% {{ trans.trans.last_quarter.value }}
                </div>
              </div>
            </div>

            <div class="col-xs-6">
              <dl-chart
                *ngIf="chartCreated"
                #pBarCreated
                idd="pBarCreated"
                type="bar"
                [responsive]="true"
                height="56px"
                width="100%"
                [options]="optionsCreated"
                [data]="chartCreated"
              ></dl-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-12" *ngIf="total_modified_vps">
        <div class="dashboard_panel dashboard_panel_chart tile">
          <div class="row">
            <div class="col-xs-12">
              <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"
                >{{ trans.trans.value_props_edited.value }}
                <i *ngIf="!style2022" [matTooltip]="trans.trans.panelTooltip.value" class="fa fa-info-circle"></i>
                <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.panelTooltip.value">
                  info
                </span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-5">
              <div class="dashboard_value dashboard_value-margin">{{ total_modified_vps.count }}</div>

            </div>
            <div class="col-xs-7">
              <dl-chart
                *ngIf="chartCreated"
                #pBarModified
                idd="pBarModified"
                type="bar"
                [responsive]="true"
                height="56px"
                width="100%"
                [options]="optionsModified"
                [data]="chartModified"
              ></dl-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-12" *ngIf="logins">
        <div class="dashboard_panel dashboard_panel_chart tile">
          <div class="row">
            <div class="col-xs-12">
              <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"
                >Users Who Have Logged In
                <i *ngIf="!style2022" matTooltip="The total number of unique users who have logged in during the selected time period." class="fa fa-info-circle"></i>
                <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" matTooltip='The total number of unique users who have logged in during the selected time period.'>
                  info
                </span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-5">
              <div class="dashboard_value dashboard_value-margin">{{ logins.count }}</div>
              <div style="display: inline" *ngIf="!term_filter.custom">
                <div *ngIf="logins.percent_change && logins.change == 'increase'" class="dashboard_label_green">
                  <i class="fa fa-arrow-up"></i> {{ logins.percent_change }}% {{ trans.trans.last_quarter.value }}
                </div>

                <div *ngIf="logins.percent_change && logins.change == 'decrease'" class="dashboard_label_red">
                  <i class="fa fa-arrow-down"></i> {{ logins.percent_change }}% {{ trans.trans.last_quarter.value }}
                </div>
              </div>
            </div>
            <div class="col-xs-7">
              <dl-chart
                *ngIf="chartLogins"
                #pLineLogin
                idd="pLineLogin"
                type="line"
                [responsive]="true"
                height="56px"
                width="100%"
                [options]="optionsLogins"
                [data]="chartLogins"
              ></dl-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 20px">
      <div class="col-xs-12">
        <div class="dashboard_panel dashboard_panel_chart" id="hero-chart">
          <div class="row">
            <div class="col-xs-6" >
              <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}">{{ trans.trans.vp_edited_created_logins_alt.value }}
                <i *ngIf="!style2022" matTooltip="Tooltip" class="fa fa-info-circle"></i>
                <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" matTooltip='Tooltip'>
                  info
                </span>
              </span>
            </div>
          </div>
          <div>
            <div class="row chart">
              <div class="chart-container">
                <dl-chart #pBar1 idd="pBar1" type="bar" [options]="options" height="250px" width="100%" [data]="chart"></dl-chart>
                <div class="sales-manager-legend-container">
                    <div class="sales-manager-legend">
                    <ng-container  *ngFor="let dataset of chart?.datasets; index as i">
                      <span class="dl_chart_legend_line" [ngStyle]="{ 'background-color': options.legend.labels.fontColor[i] }">
                      </span>
                      {{ dataset.label }}
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 20px">
      <div class="col-xs-12">
        <div class="dashboard_panel dashboard_panel_chart" id="value-props-chart">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <span [ngClass]="{'title': style2022, 'vmware_panel_title': !style2022}"> {{ trans.trans.status_summary.value }}
                <i *ngIf="!style2022" matTooltip="All opportunities (count) for your organization in the selected time period broken out by Sales stage." class="fa fa-info-circle"></i>
                <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" matTooltip='All opportunities (count) for your organization in the selected time period out by Sales stage.'>
                  info
                </span>
              </span>
            </div>

          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12 chart-full-width opp-status-container">
              <div class="status-title">{{ trans.trans.status_count.value }}
                <i *ngIf="!style2022" matTooltip="All opportunities (%) for your organization in the selected time period broken out by Sales stage." class="fa fa-info-circle"></i>
                <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" matTooltip='All opportunities (%) for your organization in the selected time period broken out by Sales stage'>
                  info
                </span>
              </div>
              <app-horizontal-bar
              class="horizontal-bar-chart"
                *ngIf="opportunityBarChartData"
                [colors]="opportunityBarChartColors"
                [labels]="opportunityBarChartLabels"
                [legend]="false"
                [data]="opportunityBarChartData"
                [fontColor]="opportunityBarChartFontColor"
              ></app-horizontal-bar>
            </div>
            <div class="col-md-6 col-xs-12 opp-status-container">
              <div class="status-title">{{ trans.trans.status_percent.value }}
                <i *ngIf="!style2022" matTooltip="All opportunities (%) for your organization in the selected time period broken out by Sales stage." class="fa fa-info-circle"></i>
                <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" matTooltip='All opportunities (%) for your organization in the selected time period broken out by Sales stage.'>
                  info
                </span>
              </div>
              <div class="doughnut-chart-container">
                <p-chart
                  type="doughnut"
                  *ngIf="opportunityDoughnutChartData"
                  height="242px"
                  width="450px"
                  [options]="opportunityDoughnutChartOptions"
                  [data]="opportunityDoughnutChartData"
                ></p-chart>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 20px; display: flex; justify-content: center; align-items: stretch">
      <div class="col-md-4 col-xs-12">
        <div class="dashboard_panel dashboard_panel_chart">
          <div class="row">
            <div class="col-xs-12 margin-12-y">
              <div style="margin-bottom: 20px">
                <span [ngClass]="{ 'title w-100': style2022, vmware_panel_title: !style2022 }"
                  >{{ trans.trans.no_vps.value }} ( {{ naughtyCreatedTotal }} )

                  <i *ngIf="!style2022" [matTooltip]="trans.trans.panelChartTooltip.value" class="fa fa-info-circle"></i>
                  <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.panelChartTooltip.value">
                    info
                  </span>
                  <i (click)="naughtyCreateds.exportCSV({ selectionOnly: false })" class="clickable pull-right fa fa-download"></i
                ></span>
              </div>
            </div>
          </div>
          <p-table #naughtyCreateds [columns]="columnsCreated" [value]="naughtyCreated" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{ col.header }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <td>
                  <span class="field-with-avatar"
                    ><div class="avatar-circle">{{ data.name | nameInitials }}</div>
                    {{ data.name === ' ' ? 'No Name' : data.name }}
                  </span>
                </td>
                <td>
                  <span *ngIf="data.modified" style="margin-top: 7px; display: block">{{
                    data.modified.split(' ')[0] == '0000-00-00' ? 'Never Created' : data.modified.split(' ')[0]
                  }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="col-md-4 col-xs-12">
        <div class="dashboard_panel dashboard_panel_chart">
          <div class="row">
            <div class="col-xs-12 margin-12-y">
              <div style="margin-bottom: 20px">
                <span [ngClass]="{ 'title w-100': style2022, vmware_panel_title: !style2022 }"
                  >{{ trans.trans.no_edits.value }} ( {{ naughtyEditedTotal }} )
                  <i *ngIf="!style2022" [matTooltip]="trans.trans.panelChartTooltip.value" class="fa fa-info-circle"></i>
                  <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.panelChartTooltip.value">
                    info
                  </span>
                  <i (click)="naughtyModified.exportCSV({ selectionOnly: false })" class="clickable pull-right fa fa-download"></i>
                </span>
              </div>
            </div>
          </div>

          <p-table #naughtyModified [columns]="columnsModified" [value]="naughtyEdited" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{ col.header }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <td>
                  <span class="field-with-avatar"
                    ><div class="avatar-circle">{{ data.name | nameInitials }}</div>
                    {{ data.name === ' ' ? 'No Name' : data.name }}
                  </span>
                </td>
                <td>
                  <span *ngIf="data.modified" style="margin-top: 7px; display: block">
                    {{ data.modified.split(' ')[0] == '0000-00-00' ? 'Never Edited' : data.modified.split(' ')[0] }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="col-md-4 col-xs-12">
        <div class="dashboard_panel dashboard_panel_chart">
          <div class="row">
            <div class="col-xs-12 margin-12-y">
              <div style="margin-bottom: 20px">
                <span [ngClass]="{ 'title w-100': style2022, vmware_panel_title: !style2022 }"
                  >{{ trans.trans.no_logins.value }} ( {{ naughtyLoginTotal }} )

                  <i *ngIf="!style2022" matTooltip="List of users from your organization who have not logged in to the WVR in the selected time period." class="fa fa-info-circle"></i>
                  <span *ngIf="style2022" class="material-icons-outlined tooltip-custom info-icon" matTooltip='List of users from your organization who have not logged into the WVR in the selected time period.'>
                    info
                  </span>
                  <i (click)="naughtyLogins.exportCSV({ selectionOnly: false })" class="clickable pull-right fa fa-download"></i
                ></span>
              </div>
            </div>
          </div>
          <p-table
            #naughtyLogins
            [columns]="columnsLogin"
            [value]="naughtyLogin"
            [paginator]="true"
            [rows]="10"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
            scrollHeight="flex"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{ col.header }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
              <tr>
                <td>
                  <span class="field-with-avatar"
                    ><div class="avatar-circle">{{ data.name | nameInitials }}</div>
                    {{ data.name === ' ' ? 'No Name' : data.name }}
                  </span>
                </td>
                <td>
                  <span *ngIf="data.modified" style="margin-top: 7px; display: block">
                    {{ data.modified.split(' ')[0] == '0000-00-00' ? 'Never Logged In' : data.modified.split(' ')[0] }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="toggleDebug" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">Debug</div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <h3>Cache</h3>
        <ngx-json-viewer [json]="cache" [expanded]="true"></ngx-json-viewer>
      </div>
    </div>
  </div>
</p-sidebar>
