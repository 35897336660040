import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RSV2CreateTranslations } from './create.translation';

@Component({
  selector: 'app-rsv2-create',
  templateUrl: './create.component.html',
})
export class RSV2CreateComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  showTranslate = false;
  isValueProp = false;

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: RSV2CreateTranslations,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const type = this.route.snapshot.paramMap.get('type');
    if (type === 'valueprop') {
      this.isValueProp = true;
    } else {
      this.isValueProp = false;
    }

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcGotoVPID(vpid): void {
    this.router.navigate(['/repv2/', vpid.valuePropId]);
  }
}
