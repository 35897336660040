import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { DeliverableVpDashTranslations } from './deliverable.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-vpdashboard-assets-deliverables',
  templateUrl: './deliverable.component.html',
})
export class VPDashboardAssetsDeliverableComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() contextualHelp: boolean;
  @Input() valuePropId: string | number;
  @Input() isDev: boolean;
  @Input() showTranslate = false;

  ngUnsubscribe: Subject<any> = new Subject();
  file: File;
  file_param = { name: '', description: '', location: '' };
  deliverables: { [klass: string]: any }[] = [];
  deliverable: { [klass: string]: any };
  uploadToggle = false;
  editToggle = false;
  assetUrl: string = '';

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  constructor(
    private translationService: TranslationsV2Service,
    public trans: DeliverableVpDashTranslations,
    private commonService: CommonService,
    private valuePropService: ValuepropService,
    private notificationsService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.deliverable = {};
    this.deliverables = [];
    this.assetUrl = this.commonService.getAssetUrl();
    this.funcGetDeliverables();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcGetDeliverables() {
    this.valuePropService
      .readFinalDeliverables(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.deliverables = response.result;
      });
  }

  handleInputChange(event) {
    this.file = event.target.files.item(0);
  }

  onSidebarClose(): void {
    this.uploadToggle = false;
    this.editToggle = false;

    this.resetFileFileParam();
  }

  funcUploadDeliverable() {
    let payload = new FormData();

    if (this.file) {
      payload.append('file', this.file);
      payload.append('name', this.file_param['name']);
      payload.append('description', this.file_param['description']);
    }

    if (this.file_param['name'] !== '') {
      this.valuePropService
        .uploadFinalDeliverable(this.valuePropId, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result.success) {
            this.funcGetDeliverables();
            this.funcOpenAddDeliverable();
            this.resetFileFileParam();
            this.notificationsService.success(response.result.message, false);
          } else {
            this.notificationsService.error(response.result.message, false);
          }
        });
    } else {
      this.notificationsService.error(this.trans.trans.noname_error_message.value, false);
    }
  }

  funcUpdateDeliverable() {
    let payload: FormData = new FormData();

    if (this.file) {
      payload.append('file', this.file);
    }

    payload.append('deliverable_id', this.file_param['id']);
    payload.append('name', this.file_param['name']);
    payload.append('description', this.file_param['description']);

    if (this.file_param['name'] !== '') {
      this.valuePropService
        .updateFinalDeliverable(this.valuePropId, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result.success) {
            this.notificationsService.success(this.trans.trans.edit_success_message.value, false);
            this.resetFileFileParam();
            this.funccloseEditDeliverable();
            this.funcGetDeliverables();
          } else {
            this.notificationsService.error(this.trans.trans.edit_error_message.value, false);
          }
        });
    } else {
      this.notificationsService.error(this.trans.trans.noname_error_message.value, false);
    }
  }

  funcDeleteDeliverable(id) {
    this.valuePropService
      .deleteFinalDeliverable(this.valuePropId, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result.success) {
          this.notificationsService.success(this.trans.trans.delete_success_message.value, false);
          this.funcGetDeliverables();
        } else {
          this.notificationsService.error(this.trans.trans.delete_error_message.value, false);
        }
      });
  }

  funcOpenAddDeliverable() {
    this.uploadToggle = !this.uploadToggle;
  }

  funccloseEditDeliverable() {
    this.editToggle = !this.editToggle;
    this.resetFileFileParam();
  }

  resetFileFileParam() {
    this.file = null;
    this.fileInput.nativeElement.value = '';
    this.file_param = { name: '', description: '', location: '' };
  }

  refreshTranslation() {
    this.getTranslations();
  }

  funcOpenEditDeliverable(deliverable) {
    this.editToggle = !this.editToggle;
    this.file_param = deliverable;
  }

  toggleFlyout() {
    this.editToggle = !this.editToggle;
    this.resetFileFileParam();
  }
}
