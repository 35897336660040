<h3>{{benefits.length}} {{ trans.vp_dashboard.included_benefits | translate }}</h3><br>
<table id="benefits" class="table custom-table" style='width: 100% !important; border-top: 0px;'>
	<thead>
		<tr>
			<th style='border: 0px; padding-bottom: 0px !important;' width="10%">{{ trans.vp_dashboard.benefits.status | translate}}</th>
			<th style='border: 0px; padding-bottom: 0px !important;' width="35%">{{ trans.vp_dashboard.benefits.benefits | translate }}</th>
			<th width="10%" style='border: 0px; padding-bottom: 0px !important; white-space: nowrap;'>{{ trans.vp_dashboard.benefits.impact | translate }}</th>
			<th style='border: 0px; padding-bottom: 0px !important; white-space: nowrap;' width="10%">{{ trans.vp_dashboard.benefits.improvement | translate}}</th>
			<th style='border: 0px; padding-bottom: 0px !important;' width="17%" class="text-right">{{ trans.vp_dashboard.benefits.year1 | translate }}</th>
			<th width="16%" style='border: 0px; padding-bottom: 0px !important; text-align: right;'>{{ trans.vp_dashboard.benefits.total | translate }}</th>
		</tr>
	</thead>

	<tbody>
		<tr *ngFor="let benefit of benefits" class="grid-row">

			<td class="benefit-status benefitStatusElement">
				<a class="dashedLink benefitStatusElement">
					<span class="activeStatusType benefitStatusElement" [ngStyle]="{'background-color':getBenefits(benefit)}"></span>
				</a>
			</td>

			<td class="benefit-status" [matTooltip]="benefit.description" matTooltipPosition="above">
				<span role="button" (click)="gotoBenefit(benefit);" class='link'>{{benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name}}</span>
			</td>
			<td>{{benefit.impact_type}}</td>
			<td>{{benefit.impact}} {{benefit.abbr}}</td>
			<td class="preventWordBreak text-right">{{benefit.year1_benefits_fmt}}</td>
			<td class="preventWordBreak text-right">{{benefit.total_benefits_fmt}}</td>
		</tr>
	</tbody>
</table>