<div class="keywest_header">
  <div class="row">
    <div class="col-xs-6">
      <div *ngIf="!hideHeader" class="title">
        {{ trans.trans.collateral.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="'collateral'"
          [isTranslationV2]="true"
          [transObj]="trans.trans.collateral"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
    </div>
    <div class="col-xs-6">
      <button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="funcShowAddCollateral()" [matTooltip]="trans.trans.ch_addNew.value" matTooltipPosition="above">
        <i class="fa fa-plus"></i>{{ trans.trans.addNew.value }}
      </button>
    </div>
  </div>
</div>

<div class="row marg-v">
  <div class="col-xs-4">
    <div class="input-group keywest_search">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input
        [(ngModel)]="strSearch"
        (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')"
        type="text"
        placeholder="{{ trans.trans.search.value }}"
        class="form-control"
      />
      <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"
        ><i class="fa fa-times"></i
      ></span>
    </div>
  </div>
</div>

<p-menu #rowMenu appendTo="body" [popup]="true" [model]="dropdown" [styleClass]="'style-2022'"></p-menu>

<div class="row">
  <div class="col-xs-12">
    <p-table #tableSolutionList [value]="collateral" [columns]="cols" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ trans.trans[col.field].value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="'collateral'"
              [isTranslationV2]="true"
              [transObj]="trans.trans[col.field]"
              (callback)="getTranslations()"
              [key]="trans.trans[col.field].key"
            ></app-editTranslation>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-collateral>
        <tr class="not-hover">
          <td (click)="funcToggleView(collateral)" class="clickable">
            {{ collateral.name }}
            <span *ngIf="collateral.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="collateral.description" matTooltipPosition="above">
              info
            </span>
          </td>
          <td>{{ collateral.format }}</td>
          <td>
            <a (click)="openWindow(collateral.url)" class="clickable">{{ collateral.url }}</a>
          </td>
          <td class="pull-right">
            <span *ngIf="can_edit" class="keywest_icon_group">
              <span><i (click)="showEditCollateral(collateral)" [matTooltip]="trans.trans.edit.value" matTooltipPosition="above" class="clickable fa fa-pencil"></i></span>
              <span>
                <app-are-you-sure
                  [matTooltip]="trans.trans.delete.value"
                  matTooltipPosition="above"
                  [message]="trans.trans.areYouSure.value"
                  text=""
                  icon="fieldset_icon fa fa-trash"
                  (callbackYes)="deleteCollateral(collateral.id)"
                ></app-are-you-sure>
              </span>
            </span>
            <span *ngIf="!can_edit" class="keywest_icon_group">
              <span><i (click)="funcToggleView(collateral)" class="fa fa-eye clickable pull-right" [matTooltip]="trans.trans.view.value" matTooltipPosition="above"></i></span>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-sidebar [(visible)]="toggleAddCollateral" styleClass="keywest_sidebar p-sidebar-lg" position="right" [modal]="false">
  <app-ae-collateral
    [typeOfCollateral]="typeOfCollateral"
    [collateralFormObj]="collateralFormObj"
    [solutionID]="solutionID"
    (callback)="getCollateralList(); toggleAddCollateral = false"
    *ngIf="toggleAddCollateral && can_edit"
  ></app-ae-collateral>
  <app-r-collateral [collateralFormObj]="collateralFormObj" *ngIf="toggleAddCollateral && !can_edit"></app-r-collateral>
</p-sidebar>
