import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '@data/services/account/account.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiServiceService } from 'app/api-service.service';
import { CommonService } from '@data/services/common/common.service';
import { Translations } from '@data/services/translation/translation.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { accountDefaultColors } from '@shared/utils';
import { IPInfo } from '@data/services/user/models/ip-info';
import { UserService } from '@data/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@services/alert.service';
import { UserPrivilege } from '@shared/models/user-info.model';
import { setupAnalytics } from 'app/analytics-scripts/analytics';
import { StyleService } from 'app/style.service';
import { Subject, Subscription, of } from 'rxjs';
import { LoginResponse } from './interfaces/login-response';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  login_data: { result: LoginResponse };
  isForgotPassword: boolean;
  IP: IPInfo;
  currYear = new Date().getFullYear();
  ngUnsubscribe = new Subject();

  subscriptionlogin: Subscription;
  @Output() closeLogin: EventEmitter<null> = new EventEmitter();
  userDetails: any;
  profileImagePath: string;
  companyLogo: string;
  companyIcon: string;
  flagUserLanguage: boolean;
  currentColor: string;
  systemColor: boolean;
  headerColor = '#90a4ae';
  mediumChartColors: string[] = [
    '#e57373',
    '#f06292',
    '#ba68c8',
    '#9575cd',
    '#7986cb',
    '#64b5f6',
    '#4fc3f7',
    '#4dd0e1',
    '#4db6ac',
    '#81c784',
    '#aed581',
    '#dce775',
    '#fff176',
    '#ffd54f',
    '#ffb74d',
    '#ff8a65',
    '#a1887f',
    '#e0e0e0',
    '#90a4ae',
  ];

  loading = false;

  loginForm: FormGroup;

  invalidCredentials = false;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private accountService: AccountService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
    private apiServiceService: ApiServiceService,
    private commonservice: CommonService,
    private userService: UserService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService,
    private fb: FormBuilder
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit(): void {
    this.commonservice.notifyLoggedOut.next();
    this.userService.getIPAddress().pipe(
        catchError((err) => of(err)),
        takeUntil(this.ngUnsubscribe)
    ).subscribe((res) => (this.IP = res));
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.subscriptionlogin) {
      this.subscriptionlogin.unsubscribe();
    }
  }

  submitUserCredentials(): void {
    this.invalidCredentials = false;
    this.loading = true;
    this.alertService.clear();

    const payload = {
      loginUsername: this.loginForm.get('email').value,
      loginPassword: this.loginForm.get('password').value,
    };
    this.alertService.redirectLogin = false;
    this.subscriptionlogin = this.userService
      .login(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => {
          this.login_data = result;
          this.loading = false;
          this.userService.setUid = Number(result.result.user_id);

          if (!result.hasOwnProperty('errors')) {
            const priv = this.login_data.result.privileges;
            if (priv.includes('1')) {
              sessionStorage.setItem('isAdmin', 'true');
            }

            const uuid = `${this.login_data.result.account_id}-${this.login_data.result.user_id}`;
            sessionStorage.setItem('workflows', JSON.stringify(this.login_data.result.conversation_workflows));
            sessionStorage.setItem('uid', this.login_data.result.user_id);
            sessionStorage.setItem('vcu', this.login_data.result.token);
            sessionStorage.setItem('aid', this.login_data.result.account_id);
            sessionStorage.setItem('account_name', this.login_data.result.account_name);
            sessionStorage.setItem('features', this.login_data.result.features);
            // Triggering feature check
            this.commonservice.notifyFeatureChange.next('0');
            sessionStorage.setItem('privileges', this.login_data.result.privileges);
            sessionStorage.setItem('trophies', this.login_data.result.trophies);
            sessionStorage.setItem('rid', this.login_data.result.role_type_id);
            sessionStorage.setItem('uuid', uuid);
            sessionStorage.setItem('header_color', this.login_data.result.header_color ? this.login_data.result.header_color : accountDefaultColors.header);
            sessionStorage.setItem('button_color', this.login_data.result.button_color ? this.login_data.result.button_color : accountDefaultColors.btnBg);
            sessionStorage.setItem('button_text_color', this.login_data.result.button_text_color ? this.login_data.result.button_text_color : accountDefaultColors.btnText);
            this.apiServiceService.changeHeaderColor.next(this.login_data.result.header_color ? this.login_data.result.header_color : accountDefaultColors.header);
            this.commonservice.setAccountColors(
              this.login_data.result.header_color ? this.login_data.result.header_color : accountDefaultColors.header,
              this.login_data.result.button_color ? this.login_data.result.button_color : accountDefaultColors.btnBg,
              this.login_data.result.button_text_color ? this.login_data.result.button_text_color : accountDefaultColors.btnText
            );
            this.styleService.updateStyle2022();

            // XF custom
            if ( this.login_data.result.account_id == '25' || this.login_data.result.account_id == '50180' ) {
              this.commonservice.setXFCSSFile();
            }

            this.userService.getUserCountry(this.IP).pipe(
                catchError((err) => of(err)),
                takeUntil(this.ngUnsubscribe)
            ).subscribe(({ result }) => {
              let will_setup_analytics = 'no';
              if (!!result) {
                if (result.country_code !== 'CN') {
                  will_setup_analytics = 'yes';
                }
              }
              sessionStorage.setItem('will_setup_analytics', will_setup_analytics);
              setupAnalytics();
            });

            const out = [];

            for (let c = 0; c < this.login_data.result.chart_colors.length; c++) {
              const elem = this.login_data.result.chart_colors[c];

              out.push(elem.color);
            }

            sessionStorage.setItem('chart_colors', JSON.stringify(out));

            this.headerColor = this.login_data.result.header_color;

            sessionStorage.setItem('new_asset_gen', this.login_data.result.new_asset_gen);
            sessionStorage.setItem('asset_gen_file', this.login_data.result.asset_gen_file);
            sessionStorage.setItem('language_type_id', this.login_data.result.language_type_id);
            sessionStorage.setItem('logo', this.login_data.result.logo_filepath);
            sessionStorage.setItem('username', this.login_data.result.user_name);
            sessionStorage.setItem('email', this.login_data.result.email);
            sessionStorage.setItem('role', this.login_data.result.role_type);

            if (this.login_data.result.fact_labels) {
              const out = [];
              const label_list = this.login_data.result.fact_labels;
              for (let i = 0; i < label_list.length; i++) {
                out.push({ k: label_list[i].name, v: label_list[i].description, language_type_id: label_list[i].language_type_id });
              }
              sessionStorage.setItem('all_dictionaries', JSON.stringify(out));
            }

            if (this.login_data.result.labels) {
              const out: {language_type_id: string, k: string, v: string}[] = [];
              const label_list = this.login_data.result.labels;
              const labels = label_list.filter((x) => x.language_type_id === this.login_data.result.language_type_id);

              for (let i = 0; i < labels.length; i++) {
                out.push({ k: labels[i].key, v: labels[i].value, language_type_id: labels[i].language_type_id });
              }

              sessionStorage.setItem('labels', JSON.stringify(out));
            }
            this.accountService.startExpInterval();
            this.apiServiceService.changeLoggedInVal(true);
            if (this.login_data.result.account_id === '50000') {
              this.alertService.notifyLoggedIn(this.login_data.result);
              this.router.navigate(['/dashboard']);

              return;
            } else {
              this.userService
                .getUser(parseInt(this.login_data.result.user_id, 10))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                  const priv: UserPrivilege[] = res['result']['privileges'];

                  if (priv) {
                    if (priv.find((x) => x.privilege_id === '1')) {
                      sessionStorage.setItem('isAdmin', 'true');
                    }

                    const activePrivileges = priv.map((details) => details.name).join();
                    sessionStorage.setItem('active_privileges', activePrivileges);
                    sessionStorage.setItem('manager_email', res.result.manager_email);
                  }
                });
              const termsAccepted = Number(this.login_data.result.terms_accepted) === 1 ? 'true' : 'false';
              sessionStorage.setItem('termsAccepted', termsAccepted);
              if (Number(this.login_data.result.first_login) === 1 && Number(this.login_data.result.temp_password) === 1) {
                this.router.navigate(['/intake']);
                sessionStorage.setItem('intake', 'true');
              } else {
                this.setProfileImage(this.login_data.result.user_id);
              }
              this.activeModal.close();
            }
            this.alertService.notifyLoggedIn(this.login_data.result);
            const incomingUrl = sessionStorage.getItem('redirect_url');
            if (incomingUrl) {
              sessionStorage.removeItem('redirect_url');
              this.router.navigate([incomingUrl]);
            }
          }
        },
        () => {
          this.loading = false;
          this.invalidCredentials = true;
          this.alertService.error('Your Email Address or Password is Invalid');
        }
      );
  }

  setProfileImage(user_id): void {
    this.userService
      .getUser(parseInt(user_id, 10))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.userDetails = response.result;
        this.userService.userInfo = this.userDetails;
        this.profileImagePath = response.result.profile_pic_filepath;

        sessionStorage.setItem('user_lang', this.userDetails.language);
        sessionStorage.setItem('products', JSON.stringify(this.userDetails.products));
        sessionStorage.setItem('user_Info', JSON.stringify(response.result.account_details));
        if (response.result.account_details) {
          this.companyLogo = response.result.account_details.logo_filepath;
          if (this.companyLogo === '') {
            this.companyLogo = this.commonservice.getAccountLogoUrl();
          }
          this.companyIcon = response.result.account_details.icon_filepath;
          if (this.companyIcon === '') {
            this.companyIcon = this.commonservice.getAccountIconUrl();
          }
        }
        this.flagUserLanguage = true;
        this.apiServiceService.changeLanguage.next('languageChange');
        this.apiServiceService.profiledetails.next('languageChange');

        sessionStorage.setItem('intake', 'false');

        const featuresOptions = sessionStorage.getItem('features');
        const featuresopt = featuresOptions.split(',');

        if (featuresopt.indexOf('32') >= 0) {
          this.router.navigate(['/valueprop']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
  }

  setCustomColor(): void {
    throw new Error('Method not implemented.');
  }

  openForgotPasswordModal(): void {
    this.alertService.notifyShowForgotPassword();
  }
}
