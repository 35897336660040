import { Component, OnInit, OnDestroy } from '@angular/core';

import { Alert, AlertType } from '../_models/alert';
import { AlertService } from '@services/alert.service';
import { Subscription } from 'rxjs';

@Component({
	moduleId: module.id,
	selector: 'alert',
	templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
	alerts: Alert[] = [];
	subscriptionAlert: Subscription;

	constructor(private alertService: AlertService) { }

	ngOnInit() {
		this.subscriptionAlert = this.alertService.getAlert().subscribe((alert: Alert) => {
			if (!alert) {
				this.alerts = [];
				return;
			}
			this.alerts.push(alert);
		});
	}

	ngOnDestroy() {
		if (this.subscriptionAlert) { this.subscriptionAlert.unsubscribe(); }
	}

	removeAlert(alert: Alert) {
		this.alerts = this.alerts.filter(x => x !== alert);
	}

	cssClass(alert: Alert) {
		if (!alert) {
			return;
		}

		switch (alert.type) {
			case AlertType.Success:
				return 'alert alert-success';
			case AlertType.Error:
				return 'alert alert-danger';
			case AlertType.Info:
				return 'alert alert-info';
			case AlertType.Warning:
				return 'alert alert-warning';
		}
	}
}