import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class UserAdoptionTranslation extends TranslationsBaseService {

  public config: any = {
    component: 'user-adoption',
    display_name: 'User Adoption',
    description: 'User Adoption',
  };

  public trans: any = {
    settings: 'Settings',
    dashboard: 'Dashboard',
    analytics: 'User Adoption',
    back_to_profile: 'Back to Profile',
    userAdoption: 'User Adoption'
  };
}
