import {
    Directive,
    Input,
    ElementRef,
    HostListener,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    OnInit, ChangeDetectorRef
} from '@angular/core';

@Directive({
    selector: '[rangeInput]'
})
export class RangeInputDirective implements OnChanges, OnInit {

    @Input()
    rangeInput: [number, number?];

    @Input()
    rangeInputModel: string;

    @Input()
    rangeInclusive: boolean | [boolean, boolean];

    @Input()
    lowerValidationMessage: string;

    @Input()
    upperValidationMessage: string;

    @Output()
    rangeInputModelChange = new EventEmitter<string>();

    debounceTimerRef: NodeJS.Timer;
    errorTimerRef: NodeJS.Timer;
    errorMessageRef: HTMLSpanElement;

    constructor(private ref: ElementRef<HTMLInputElement>, private cdr: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['rangeInputModel']) {
            this.ref.nativeElement.value = this.rangeInputModel;
            this.checkValue(false);
        }
    }

    ngOnInit() {
        this.checkValue(false);
        this.cdr.detectChanges();

        this.errorMessageRef = document.createElement('span');
        this.errorMessageRef.setAttribute('style', 'color: #de4d4d; font-size: 12px;');
        this.ref.nativeElement.parentElement.insertAdjacentElement('beforeend', this.errorMessageRef);
    }

    checkValue(showError: boolean): void {
        let val = this.ref.nativeElement.value ? parseFloat(this.ref.nativeElement.value) : 0;
        let errorUpper = false;
        let errorLower = false;
        if (this.rangeInput && this.rangeInput[1] !== undefined) {
            const inclusive = this.isUpperRangeInclusive();
            if (inclusive && val > this.rangeInput[1]) {
                this.ref.nativeElement.value = this.rangeInput[1].toString();
                errorUpper = true;
            } else if (!inclusive && val >= this.rangeInput[1]) {
                this.ref.nativeElement.value = (this.rangeInput[1] - 1).toString();
                errorUpper = true;
            }

            if (showError && errorUpper) {
                this.showErrorMessage(this.upperValidationMessage.replace('0', this.rangeInput[1].toString()));
            }
        }

        if (this.rangeInput && this.rangeInput[0] !== undefined) {
            const inclusive = this.isLowerRangeInclusive();
            if (inclusive && val < this.rangeInput[0]) {
                this.ref.nativeElement.value = this.rangeInput[0].toString();
                errorLower = true;
            } else if (!inclusive && val <= this.rangeInput[0]) {
                this.ref.nativeElement.value = (this.rangeInput[0] + 1).toString();
                errorLower = true;
            }

            if (showError && errorLower) {
                this.showErrorMessage(this.lowerValidationMessage.replace('0', this.rangeInput[0].toString()));
            }
        }

        this.rangeInputModelChange.emit(this.ref.nativeElement.value);
    }

    @HostListener('input', [ '$event' ])
    public onInput(): void {
        if (this.debounceTimerRef) {
            clearTimeout(this.debounceTimerRef);
        }

        this.debounceTimerRef = setTimeout(() => {
            this.checkValue(true);
        }, 500);
    }

    isLowerRangeInclusive(): boolean {
        return this.rangeInclusive === undefined || this.rangeInclusive === null ?
            true :
            (typeof this.rangeInclusive === 'boolean' ? this.rangeInclusive : this.rangeInclusive[0]);
    }

    isUpperRangeInclusive(): boolean {
        return this.rangeInclusive === undefined || this.rangeInclusive === null ?
            true :
            (typeof this.rangeInclusive === 'boolean' ? this.rangeInclusive : this.rangeInclusive[1]);
    }

    showErrorMessage(message: string) {
        if (this.errorTimerRef) {
            clearTimeout(this.errorTimerRef)
        }

        this.errorMessageRef.textContent = message;
        this.errorTimerRef = setTimeout(() => {
            this.errorTimerRef = null;
            this.errorMessageRef.textContent = '';
        }, 2000);
    }
}
