import { Scalers } from './scalers.interface';
import { ValueProp } from '@shared/models/value-prop.model';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SimpleImplementation } from '../../../../simple.implementation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ScenarioCombinedScalersTranslations } from './sc_scalers.translation';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-dashboard-sc-vpg-scalers-repv2',
  templateUrl: './scalers.component.html',
})
export class SCVPGScalersComponentRepv2 implements OnInit, OnDestroy {
  @Input() edit = false;
  @Input() valueProp: ValueProp;
  @Input() canEdit = false;
  @Output() callback = new EventEmitter();
  @Output() reloadVP = new EventEmitter();

  loading = false;
  scalers: Scalers[] = [];
  scalersControl: Scalers[] = [];
  ngUnsubscribe = new Subject();
  is_default: string;
  saveLoading = false;
  MasterScalerList: Scalers[] = [];
  feature26 = false;
  showTranslate = false;
  jumpToResultsFeature = false;
  style2022$: Observable<boolean>;

  constructor(
    private valuepropService: ValuepropService,
    public sI: SimpleImplementation,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private RS: RepSimpleViewService,
    private translationService: TranslationsV2Service,
    public trans: ScenarioCombinedScalersTranslations
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.valuepropService.editSituations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.edit = false;
    });
    this.valuepropService.reloadScalers$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.loadScalers();
      }
    });
    this.canEdit = this.valueProp.closed === '0' && this.valueProp.vp_can_edit;
    this.feature26 = this.commonService.checkFeature('26');
    this.jumpToResultsFeature = this.commonService.checkFeature('109');
    this.edit = this.valueProp.scalers_done === '1' ? true : false;
    this.loadScalers();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  closeScaler(): void {
    this.reloadVP.emit();
  }

  loadScalers(): void {
    this.loading = true;
    this.scalers = [];
    const searchLimit = this.valueProp.id;
    this.valuepropService
      .getScalers(searchLimit, 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.scalers = response.result.scalers;

          if (this.scalers.length === 0 || !this.scalers) {
            this.loading = false;
          }
          this.is_default = response.result.is_default;

          this.scalers = this.scalers.map((item) => {
            item['updated'] = false;

            if (item['name'] === 'includeDNS') {
              item['value'] = 0;
            }
            if (item['greater_than_zero'] === '1') {
              item['greater_than_zero'] = '1';
            } else {
              {
                item['greater_than_zero'] = '0';
              }
            }
            return item;
          });
          this.scalers = this.scalers.filter((item) => {
            return item['name'] !== 'Does Not Scale';
          });
          this.loading = false;
          this.scalersControl = cloneDeep(this.scalers);
        } else {
          this.loading = false;
        }
      });
  }

  createScaler(results?): boolean {
    this.saveLoading    = true;
    let updatedScalers  = [];
    for (let s = 0; s < this.scalers.length; s++) {
      const scale = this.scalers[s];
      scale.source_type_id = this.checkScaler(scale.id) ? '4' : scale.source_type_id;

      if ( scale.updated === true ) {
        updatedScalers.push(scale);
      }

      if (scale.greater_than_zero === '1' && (scale.value === 0 || !scale.value)) {
        this.saveLoading = false;
        this.notificationService.error(scale.name + ' is required', false);
        return false;

      } else if ( scale.value === null ) {
        this.saveLoading = false;
        this.notificationService.error('Please enter value for ' + scale.name, false);
        return false;

      }
    }

    let scalersToSave = this.scalers;
    if ( this.is_default == '0' ) {
      // User has already save and there are no default scalers
      // Only send values that have been updated
      scalersToSave = updatedScalers;
    }

    const payload = {
      value_prop_scalers: scalersToSave,
      angular_dev: 1,
    };
    const searchLimit = this.valueProp.id;

    this.valuepropService
      .createScalers(searchLimit, payload)
      .pipe(take(1))
      .subscribe((response) => {
        const payload2 = { scalers_done: 1, done: 1 };
        this.RS.putDone(this.valueProp.id, payload2)
          .pipe(take(1))
          .subscribe(() => {
            this.valueProp.scalers_done = '1';
            this.valueProp.done = '1';
            this.edit = true;
            this.loadScalers();
            this.callback.emit(results);
          });

        if (response.result && response.result.success !== false) {
          this.notificationService.success('Scalers updated', false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.saveLoading = false;
      });
  }

  editScaler(): void {
    this.edit = false;
  }

  scalerUpdated(scaler) {
    scaler.updated = true;
  }

  private checkScaler(id: string): boolean {
    const scaler = this.scalers.find((scaler) => scaler.id === id);
    const controlScaler = this.scalersControl.find((scaler) => scaler.id === id);
    if (scaler && controlScaler) {
      return String(scaler.value) !== String(controlScaler.value);
    } else {
      return false;
    }
  }
}
