import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SituationsSharedComponent } from './situations-shared.component';
import { SituationInputComponent } from './situation-input/situation-input.component';

import { SidebarModule } from 'primeng/sidebar';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { FormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

@NgModule({
  declarations: [SituationsSharedComponent, SituationInputComponent],
  imports: [
    TextFieldModule,
    CommonModule,
    SidebarModule,
    MatTooltipModule,
    EditTranslationsModule,
    FormsModule,
    ProgressBarModule,
    DLProgressbarModule,
    AreYouSureModule,
    DropdownModule,
    MultiSelectModule,
    RadioButtonModule,
    CheckboxModule,
    InputSwitchModule,
    ApplicationPipesModule,
  ],
  exports: [SituationsSharedComponent, SituationInputComponent],
})
export class SituationsSharedModule {}
