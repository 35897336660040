import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ModelsVpDashboardTranslations } from './models.translation';
import { Solution, ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-models',
	templateUrl: './models.component.html'
})
export class DashboardModelsComponent implements OnInit, OnDestroy {
	@Input() showHelp: boolean;
	@Input() contextualHelp: any;
	@Input() valueProp: ValueProp;
	@Input() showTranslate = false;
	@Input() user = 'system';
	selectedSolutions: Solution[] = [];
	selectedSolution: Solution;
	selectedSolutionRemove: Solution;
	selectedSolutionsList: Solution[] = [];

	// tslint:disable-next-line: max-line-length
	availableSolutions: Array<Solution & {solution_category_id: string; isChecked: boolean; selectedCategory: boolean; showeditable: boolean; tempQuantity: string | number}> = [];
	solutionCategoryList: Array<{category: string; id: string}> = [];
	showCategoryTypeLoader = false;
	showModelsLoader = false;
	categoryType: string;
	selectedModels: Array<{solutionId: string; quantity: number}> = [];
	situationLength = 0;
	showCategoryLoader = false;
	showActionLoaderModel = false;
	showSituation = false;
	fullImagePath: string;
	selectedSolutionsLength = 0;

	ngUnsubscribe = new Subject();
	loading = false;
	locked = false;

	hasRadioButtonFeature = false;
	style2022$: Observable<boolean>;

	constructor(
		private solutionService: SolutionService,
		private valuepositionService: ValuepositionService,
		private notificationService: NotificationService,
		private valuepropService: ValuepropService,
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: ModelsVpDashboardTranslations,
		private styleService: StyleService
	) {

		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;
		// tslint:disable-next-line: triple-equals
		this.locked = this.valueProp.closed == '1';
		this.getAvailableSolutions();

		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.hasRadioButtonFeature = this.commonService.checkFeature(54);

	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans.trans
		};

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	getSolution(categoryId): void {
		this.showCategoryTypeLoader = true;
		this.selectedSolutionsLength = 0;
		if (this.availableSolutions) {
			this.availableSolutions = this.availableSolutions.map(item => {
				item['selectedCategory'] = false;
				if (item.solution_category_id != null && (item.solution_category_id.indexOf(categoryId) >= 0)) {
					this.selectedSolutionsLength++;
					item['selectedCategory'] = true;
				}
				return item;
			});

			this.showCategoryTypeLoader = false;
		}
	}

	getSolutionCategoryList(): void {
		this.solutionService.getSolutionCategoryListwithVPID().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.solutionCategoryList = result.result;
			if (this.solutionCategoryList && this.solutionCategoryList.length) {
				this.categoryType = this.solutionCategoryList[0]['id'];
				if (this.categoryType) {
					this.getSolution(this.solutionCategoryList[0]['id']);
				}
			}

			this.showModelsLoader = false;
		});
	}

	getAvailableSolutions(): void {
		this.showModelsLoader = true;
		this.solutionService.getAvailableSolutionsPlain(this.valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
			this.availableSolutions = result.result;
			if (this.availableSolutions) {
				this.availableSolutions = this.availableSolutions.map(item => {
					item['quantity'] = 1;
					return item;
				});
			}

			this.getSolutionCategoryList();
			this.getSolutionsUsedInValueProps();
		});
	}

	quantityAssign(id, quantityValue): void {
		const quantityCheck = this.selectedModels.filter(item => {
			// tslint:disable-next-line: triple-equals
			return item['solutionId'] == id;
		});

		if (quantityCheck.length > 0) {
			if (this.selectedModels && this.selectedModels.length) {
				for (let i = 0; i < this.selectedModels.length; i++) {
					if (this.selectedModels[i]['solutionId'] == id) {
						this.selectedModels[i]['quantity'] = quantityValue;
					}
				}
			}
		}
		this.createSolution();
	}

	checkModels(params, quantity, event): void {
		if (this.hasRadioButtonFeature) {
			this.selectedModels = [];
		}

		if (event.target.checked) {
			this.showCategoryLoader = true;
			const tempModel = {
				'solutionId': params,
				'quantity': quantity
			};
			this.selectedModels.push(tempModel);
		} else {
			this.situationLength = 0;

			this.selectedModels = this.selectedModels.filter((impact: any) => {
				return impact['solutionId'] !== params;
			});

		}
	}

	createSolution(): void {
		this.loading = true;
		if (this.selectedModels) {

			const selectedModels = this.selectedModels.map((impact) => {
				return impact['solutionId'];
			});

			const selectedQuantity = this.selectedModels.map((impact) => {
				return impact['quantity'];
			});


			this.showActionLoaderModel = true;

			const payload = {
				'account_solution_id': selectedModels.join(','),
				'value_prop_id': this.valueProp.id,
				'quantity': selectedQuantity.join(','),
				'delete_vp_solutions': 1
			};
			this.valuepositionService.quickCreateSolution(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
				if (result.result) {
					this.loading = false;
					this.showActionLoaderModel = false;
					this.valuepropService.refreshDashboard.next('rebuildMenu');
					this.notificationService.success('Solution assigned Successfully', false);
				}
				this.showSituation = true;
			});

		}
	}

	getSolutionsUsedInValueProps(): void {
		this.valuepositionService.getValuePropSolutions(this.valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				const selectedSolutions = response.result;
				const solutionIds = selectedSolutions.map(solution => solution['account_solution_id']);
				if (this.availableSolutions && this.availableSolutions.length) {
					this.availableSolutions.forEach(solution => {
						const index = solutionIds.indexOf(solution['id']);
						if (index !== -1) {
							this.selectedModels.push({
								'solutionId': solution['id'],
								'quantity': Number(selectedSolutions[index]['quantity'])
							});

							solution['isChecked'] = true;
							solution['quantity'] = selectedSolutions[index]['quantity'];
						}
					});
				}
			}
		});
	}
}
