import { MenuItem } from 'primeng/api';
import { ValueProp } from '@shared/models/value-prop.model';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { ValuePropTranslations } from './valueprop.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil, map } from 'rxjs/operators';
import { FactsService } from '@data/services/facts/facts.service';
import { extractInitials } from '@shared/utils';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { ValuePropStatusType } from '@shared/models/facts.models';
import { Table } from 'primeng/table';
import { UserService } from '@data/services/user/user.service';
import { ChooseAdventureInternalComponent } from '@shared/choose-adventure-internal/choose-adventure-internal.component';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ListService } from '@data/services/list/list.service';

@Component({
  selector: 'app-valueprop',
  templateUrl: './valueprop.component.html',
  styleUrls: ['./valueprop.component.scss'],
})
export class ValuepropComponent implements OnInit, OnDestroy {
  pageNumber: number;
  order = 'desc';
  sortBy = 'created';
  searchText = 'all';
  creator_filter = '';

  valueProps: ValueProp[] = [];

  valuePropLoader = false;

  fullImagePath: string;
  image_url: string;
  searchTypeList: Array<{ [klass: string]: any }> = [];
  showActionLoader = false;
  showTooltipActionLoader = false;
  valuepropname: string;
  modalReference: NgbModalRef;
  valuePropsDetails: ValueProp;
  contextualHelp: string;
  dealdeskFeature = false;
  masterValuePropFeature = false;
  guidedDiscoveryFeature = false;
  accountFeatures: string[] = [];
  valuePropCreateFeatureCount: number;
  accountID = sessionStorage.getItem('aid');
  accountUID = sessionStorage.getItem('uid');
  isGuest = false;
  isValuePositionUser = false;
  valuePropGroupsFeature = false;
  isValuePropUser = false;
  caseStudyFeature = false;
  page: string;
  repSimpleFeature = false;
  selectedOption: string;

  showRouteOptions = false;
  showSearch = false;
  includeCalcs: boolean = false;
  hasUnfinishedVps: boolean = false;
  viewAll: boolean = false;

  showTranslate = false;
  isAdmin = false;
  showAddButton = true;
  feature41 = false;
  pageLimit = 10;
  rowLimit: MenuItem[] = [];
  sharedWith: { label: string; value: string }[] = [];
  strSearch = '';
  columns: { field?: string; header?: string; key?: string }[] = [];
  valueposition: ValueProp;
  loadingVPDetails = false;
  loading = false;

  ngUnsubscribe = new Subject();

  rtOption = '/repv2';
  rtOptionFeature = false;
  snRouteOption = false;
  valuePropStatusTypes: MenuItem[] = [];
  feature22 = false;
  feature55 = false;
  valuePropTypes: ValuePropStatusType[] = [];

  account_name = '';
  selectedVP: ValueProp;
  toggleShare = false;
  showClone = false;
  containerMargin = '0px';
  public breadcrumbKeyValues: Breadcrumbs;
  sorted = false;
  unsortedValuePropList: ValueProp[] = [];
  @ViewChild('tableValuePosition', { static: true }) table: Table;
  tableRecords$: Observable<number>;
  currentTableRecords$ = new Subject<number>();
  public showStepSelection = false;
  public navigationRoute = '';
  public existingVp = false;
  public ff134 = this.commonService.checkFeature('134');
  public priv1 = this.commonService.checkPrivilege('1');
  public priv12 = this.commonService.checkPrivilege('12');
  public workflows: ConversationWorkflow[] = [];
  @ViewChild('chooseAdventure') chooseAdventureDialog: ChooseAdventureInternalComponent;

  constructor(
    public commonService: CommonService,
    private valuepropService: ValuepropService,
    private notification: NotificationService,
    private router: Router,
    private modalService: NgbModal,
    private translationService: TranslationsV2Service,
    public trans: ValuePropTranslations,
    private factsService: FactsService,
    private route: ActivatedRoute,
    private userService: UserService,
    private configurableWorkflowService: ConfigurableWorkflowService,
    private listService: ListService,
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.account_name = sessionStorage.getItem('account_name');
    this.feature22 = this.commonService.checkFeature(22);
    this.feature55 = this.commonService.checkFeature(55);
    if (sessionStorage.getItem('workflows') && sessionStorage.getItem('workflows') !== 'undefined') {
      this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
    }
    this.userService.replaceFF134$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((replaceFF134) => {
      if (replaceFF134) {
        let features = sessionStorage.getItem('features').split(',');
        if (!features.includes('134')) {
          features.push('134');
          sessionStorage.setItem('features', features.join(','));
        } else {
          const newFeatures = features.filter((f) => f !== '134');
          if (newFeatures.length) {
            const featuresString = newFeatures.join(',');
            sessionStorage.setItem('features', featuresString);
          }
        }
      }
    });

    this.factsService
      .getValuePropStatusTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.valuePropTypes = response.result;
      });

    this.getTranslations();

    this.factsService
      .getValuePropStatusTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => (this.valuePropTypes = response.result));

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.viewAll = this.commonService.checkPrivilege("11") || this.commonService.checkPrivilege("12");
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true' ? true : false;
    this.loadValueProps();
    this.funcBuildPageLimit();

    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.contextualHelp = response['Valueprop'];
    });

    this.parseFeatures();

    const rid = sessionStorage.getItem('rid');
    if (rid == '7') {
      this.showAddButton = false;
    }
    if (this.commonService.checkFeature(41)) {
      this.showAddButton = false;
    }
    this.getProductDetails();

    this.subscribeToTableRecords();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.columns = [
          {
            field: 'name',
            header: this.trans.trans.name.value,
            key: 'value_prop.name',
          },
          {
            field: 'company_name',
            header: this.trans.trans.company_name.value,
            key: 'value_prop.company',
          },
          {
            field: 'models',
            header: this.trans.trans.models.value,
            key: 'value_prop.models',
          },
          {
            field: 'benefits_total',
            header: this.trans.trans.benefits_total.value,
            key: 'value_prop.totalBenefits',
          },
          {
            field: 'costs_total',
            header: this.trans.trans.costs_total.value,
            key: 'value_position.totalCosts',
          },
          {
            field: 'vp_creator_name',
            header: this.trans.trans.creator.value,
            key: 'value_position.creatorName',
          },
          {
            field: 'created',
            header: this.trans.trans.created_on.value,
            key: 'value_position.created',
          },
          {
            field: 'status',
            header: this.trans.trans.status.value,
            key: 'value_position.status',
          },
        ];
        this.sharedWith = [
          { label: this.trans.trans.all.value, value: '' },
          { label: this.trans.trans.mine.value, value: '0' },
          { label: this.trans.trans.sharedWithMe.value, value: '1' },
        ];
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.cases.value]: { routerLink: '/valueprop', active: true },
        };
      });
  }

  public chooseStep(route: string, id: string) {
    this.chooseAdventureDialog.navigationRoute = this.commonService.getLinkVPRoute(route, id, 4);
    this.chooseAdventureDialog.existingVp = true;
    if (this.workflows.length <= 1) {
      if (this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      } else if (this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0]);
      }
      this.router.navigate([this.commonService.getLinkVPRoute(route, id, 4)]);
      this.userService.replaceFF134$.next(false);
    } else {
      this.showStepSelection = true;
    }
  }

  public onAddHypoClicked(event: string) {
    this.chooseAdventureDialog.existingVp = false;
    if (this.workflows.length <= 1) {
      if (this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      } else if (this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0]);
      }
      this.funcGetAddRoute(event);
      this.userService.replaceFF134$.next(false);
    } else {
      this.showStepSelection = true;
    }
  }

  public onHidden(hidden: boolean) {
    this.showStepSelection = hidden;
  }

  subscribeToTableRecords(): void {
    this.tableRecords$ = merge(this.table.onFilter.pipe(map((source) => source.filteredValue.length)), this.currentTableRecords$);
  }

  funcGetAddRoute(incoming) {
    if (this.rtOptionFeature || this.snRouteOption) {
      this.showRouteOptions = true;
    } else {
      this.router.navigate([this.commonService.getCreateVPRoute(incoming)], { relativeTo: this.route });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  funcSort(event) {
    event.data.sort((data1, data2) => {
      let field = '';
      switch (event.field) {
        case 'benefits_total':
          field = 'totalbenefits';
          break;
        case 'costs_total':
          field = 'totalcosts';
          break;
        default:
          field = event.field;
          break;
      }

      const value1 = field !== event.field ? Math.trunc(Number(data1[field])) : data1[field];
      const value2 = field !== event.field ? Math.trunc(Number(data2[field])) : data2[field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }
      this.sorted = true;
      return event.order * result;
    });
  }

  setRowLimit(num) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  getValuePropDetails(data, op, event, valuePropId, index) {
    op.hide();
    op.show(event);
    this.loadingVPDetails = true;
    this.valueposition = data;

    this.valuepropService
      .getValuePropDetail(valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.valuePropsDetails = response.result.value_prop;
          this.valueposition.solutions = this.valuePropsDetails.solutions;
          if (this.valueposition.solutions) {
            this.valueposition.solutions = this.valueposition.solutions.map((item) => {
              return { name: item['name'] };
            });
          }
          this.loadingVPDetails = false;
        }
      });
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  funcShowTranslate() {
    this.showTranslate = !this.showTranslate;
  }

  refreshTranslation() {
    this.getTranslations();
  }

  getProductDetails() {
    const products = JSON.parse(sessionStorage.getItem('products'));
    products.forEach((item) => {
      if (item.name === 'Value Positions') {
        this.isValuePositionUser = true;
      }
    });
  }

  getValuePropGroup(data, op, event) {
    op.hide();
    this.valueposition = data;
    op.show(event);
  }

  loadValueProps(): void {
    this.loading = true;
    const payload = {
      search: '',
      page: 'all',
      limit: 'all',
      sortby: this.sortBy,
      order: this.order,
      include_calcs: this.includeCalcs,
    };

    this.valuePropLoader = true;
    this.listService
      .getValueProps(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.unsortedValuePropList = [...response.result.data];
          switch (this.creator_filter) {
            case '':
              this.valueProps = response.result.data;
              break;
            case '0':
              this.valueProps = response.result.data.filter((x) => x.user_id == sessionStorage.getItem('uid'));
              break;
            case '1':
              this.valueProps = response.result.data.filter((x) => x.share_role_type_id == '1' || x.share_role_type_id == '2');
              break;
          }
          this.valueProps.forEach((elem) => {
            elem.showOverlay = false;
            elem.models = elem['solutions_display'];
          });
          this.currentTableRecords$.next(this.valueProps.length);

          this.hasUnfinishedVps = response.result.has_unfinished_vps;
          this.loading = false;

          this.valuePropLoader = false;
        }

        this.showActionLoader = false;
      });
  }

  deleteValueProp(valuepropId): void {
    this.valuePropLoader = true;
    this.valuepropService
      .deleteValueProp(valuepropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.valuePropLoader = false;
          this.notification.success(response.result, false);
          this.loadValueProps();
        }
      });
  }

  openAddValuePosition(content, page): void {
    this.page = page;

    let optionCount = 1;

    if (this.guidedDiscoveryFeature) {
      optionCount++;
    }

    if (optionCount === 1) {
      if (this.dealdeskFeature && this.accountID === '20') {
        if (!this.isValuePositionUser) {
          this.page = 'valuePosition';
        }

        if (this.valuePropGroupsFeature) {
          this.selectOptions('quickcreate');
        } else {
          this.selectOptions('dealdesk');
        }
      } else if (this.dealdeskFeature) {
        this.modalReference = this.modalService.open(content, {
          windowClass: 'deleteModal',
          backdrop: 'static',
          keyboard: false,
        });
      } else {
        const navRoute = this.repSimpleFeature ? 'rep' : 'quickcreate';
        this.selectOptions(navRoute);
      }
    } else {
      const navRoute = this.repSimpleFeature ? 'rep' : 'quickcreate';
      this.selectOptions(navRoute);
    }
  }

  selectOptions(selectedOpt?: string) {
    const aid = sessionStorage.getItem('aid');

    if (selectedOpt === 'quickcreate') {
      if (this.page === 'valuePosition') {
        if (this.valuePropGroupsFeature) {
          this.router.navigate(['addValuePositionV2']);
        } else {
          this.router.navigate(['addValuePosition']);
        }
      } else {
        if (aid === '464') {
          this.router.navigate(['addValuePositionV2']);
        } else {
          this.router.navigate(['addvalueprop']);
        }
      }
    } else if (selectedOpt === 'guideddiscovery') {
      if (this.page === 'valuePosition') {
        if (aid === '50009') {
          this.router.navigate(['masterValueProps/discovery']);
        } else {
          this.router.navigate(['guidedDiscovery']);
        }
      } else if (aid === '464' || aid === '50009') {
        this.router.navigate(['addValuePositionV2']);
      } else {
        this.router.navigate(['valueprop/guidedDiscovery']);
      }
    } else if (selectedOpt === 'dealdesk') {
      if (this.page === 'valuePosition') {
        this.router.navigate(['dealdesk']);
      } else {
        this.router.navigate(['valueprop/dealdesk']);
      }
    } else if (selectedOpt === 'masterValueProp') {
      if (this.page === 'valuePosition') {
        this.router.navigate(['addMasterValueProp']);
      } else {
        this.router.navigate(['addMasterValueProp']);
      }
    } else if (selectedOpt === 'rep') {
      this.router.navigate(['rep']);
    }

    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  getValuePropStatusTypes(vp): void {
    this.valuePropStatusTypes = [];
    for (let s = 0; s < this.valuePropTypes.length; s++) {
      const elem = this.valuePropTypes[s];
      this.valuePropStatusTypes.push({
        label: elem.name,
        command: () => this.updateValuePropStatus(elem.id, vp),
      });
    }
  }

  updateValuePropStatus(statusTypeid, vp) {
    if (!statusTypeid) {
      return;
    }
    const statusTypeObj = { value_prop_status_type_id: statusTypeid };

    this.valuepropService
      .updateValuePropStatus(vp.id, statusTypeObj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          vp.status = response.result.value_prop_status.name;
          this.notification.success('Status Changed Successfully', false);
        } else {
          this.notification.error('Status Change Failed', false);
        }
      });
  }

  parseFeatures() {
    const features = sessionStorage.getItem('features');
    const aid = sessionStorage.getItem('aid');

    this.valuePropCreateFeatureCount = 0;
    this.accountFeatures = features.split(',');

    if (this.accountFeatures.indexOf('15') >= 0) {
      this.masterValuePropFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.masterValuePropFeature = false;
    }

    if (this.accountFeatures.indexOf('31') >= 0) {
      this.valuePropGroupsFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.masterValuePropFeature = false;
    }

    if (this.accountFeatures.indexOf('16') >= 0) {
      this.dealdeskFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.dealdeskFeature = false;
    }

    if (this.accountFeatures.indexOf('17') >= 0) {
      this.guidedDiscoveryFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.guidedDiscoveryFeature = false;
    }

    if (this.accountFeatures.indexOf('18') >= 0) {
      this.caseStudyFeature = true;
    } else {
      this.caseStudyFeature = false;
    }

    if (aid === '50000') {
      this.isGuest = true;
      this.masterValuePropFeature = true;
      this.isValuePropUser = true;
    }

    if (this.accountFeatures.indexOf('34') >= 0) {
      this.repSimpleFeature = true;
    }

    if ((this.feature41 = this.commonService.checkFeature(41))) {
      if (this.isGuest) {
        this.showAddButton = false;
      }
    } else {
      if (this.isGuest) {
        this.showAddButton = false;
      } else {
        this.showAddButton = true;
      }
    }

    this.rtOptionFeature = this.commonService.checkFeature(71);
    if (this.rtOptionFeature) {
      this.rtOption = '/repv2';
    }

    this.snRouteOption = this.commonService.checkFeature(73);
  }

  optionsNavigate() {
    if (this.rtOptionFeature) {
      this.router.navigate([this.rtOption]);
      return;
    }
    if (this.snRouteOption) {
      sessionStorage.setItem('cxo_deck', this.rtOption);
      this.router.navigate([this.commonService.getCreateVPRoute('valueprop')]);
      return;
    }
  }

  resetSort() {
    this.valueProps = [...this.unsortedValuePropList];
    this.sorted = false;
    this.table.reset();
  }

  getInitials(name: string): string {
    return extractInitials(name);
  }

  checkDeletePermission(vp: ValueProp, privs: { priv1: boolean; priv12: boolean }): boolean {
    if (sessionStorage.getItem('uid') === vp.user_id) {
      return true;
    }
    if (+vp.share_role_type_id === 4) {
      return false;
    }
    if (privs.priv1 || privs.priv12) {
      return true;
    }
    if (+vp.share_role_type_id === 3 && +vp.share_role_type_id === 5) {
      return true;
    }
  }
}
