import { Component, OnInit, ViewChild, Injector, ElementRef, AfterViewInit } from '@angular/core';
import { AccountService } from '../data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { UserService } from '../data/services/user/user.service';
import { Translations } from '@data/services/translation/translation.service';
import { createDomPortalHost } from '@shared/utils';
import { AppImplementation } from './app.implementation';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { AppSystemKeyWestView } from './views/keywest/keywest.component';
import { AppSystemCalculatorView } from './views/calculators/calculator.component';
import { AppSystemShareView } from './views/share/share.component';
import { SvgIconRegistryService } from '@shared_components/svg-icon/services/svg-icon-registry.service';
import { APP_ICONS } from 'app/_utils/app-icons';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  providers: [UserService, AccountService],
})
export class AppComponent implements OnInit, AfterViewInit {
  portalHost: DomPortalHost;
  @ViewChild('portalHost', { static: true }) elRef: ElementRef;
  view: string = '';
  comp: any;
  crm: string;
  aid: string;
  xdl: string;
  apiError = false;
  bypassCheck = false;

  constructor(
    public aI: AppImplementation,
    public trans: Translations,
    readonly injector: Injector,
    private UserService: UserService,
    private commonservice: CommonService,
    private iconRegistryService: SvgIconRegistryService,
    private http: HttpClient
  ) {
    this.loadIcons();
  }

  ngOnInit() {
    console.log('initing');
    let incomingUrl = window.location.pathname;
    if (incomingUrl.substr(0, 6) == '/repv2') {
      sessionStorage.setItem('redirect_url', incomingUrl);
    }

    this.aid = sessionStorage.getItem('aid');
    this.xdl = sessionStorage.getItem('xdl_referer');

    this.UserService.toggleSupportSidebar.subscribe(() => {
      this.aI.toggleSupport = !this.aI.toggleSupport;
    });

    this.portalHost = createDomPortalHost(this.elRef, this.injector);
    this.crm = sessionStorage.getItem('crm');
    if (this.crm) {
      this.view = 'crm';
    }

    if (localStorage.getItem('keywest') == 'true') {
      this.view = 'keywest';
    }

  }

  ngAfterViewInit() {
    let r = window.location.pathname;

    if (r.includes('/calculators')) {
      this.view = 'calculators';
    }
    if (r.includes('/vp/')) {
      this.view = 'share';
    }

    if (sessionStorage.getItem('calc') !== null) {
      this.view = 'calculators';
    }

    switch (this.view) {
      case 'calculators':
        this.comp = AppSystemCalculatorView;
        break;

      case 'keywest':
        this.comp = AppSystemKeyWestView;
        break;

      case 'share':
        this.comp = AppSystemShareView;
        break;

      default:
        this.comp = AppSystemKeyWestView;
        break;
    }

    this.aI.ngOnInit();

    if ( (this.aid && this.aid == '25') || (this.xdl !== null && this.xdl !== undefined) || (this.aid && this.aid == '50180') ) {
      // User logged in already
      // If XF or coming from Mikey, add the CSS
      this.commonservice.setXFCSSFile();
    } else {
      console.log(this.aid);
    }

    const myPortal = new ComponentPortal(this.comp);

    this.portalHost.detach();
    this.portalHost.attach(myPortal);
  }

  setServiceUrl() {
    if (this.aI.server !== '' && this.bypassCheck) {
      this.aI.setServicesUrl(this.aI.server);
    }
    const isLocalHost = this.aI.server.includes('localhost');

    if (!this.aI.server.endsWith('.com') && !this.aI.server.endsWith('.com/') && !isLocalHost) {
      this.aI.server = this.aI.server + '.api.value-cloud.com/';
    } else if (!this.aI.server.endsWith('/')) {
      this.aI.server = this.aI.server + '/';
    }
    this.aI.server = this.aI.prependHttp(this.aI.server, { https: !isLocalHost });
    this.http
      .get(this.aI.server + 'account/supportUrl')
      .pipe(
        catchError((err) => {
          if (err.status === 0) {
            this.apiError = true;
          } else if (err.status === 401) {
            this.aI.setServicesUrl(this.aI.server);
          }
          return throwError(err);
        })
      )
      .subscribe();
  }

  loadIcons(): void {
    this.iconRegistryService.registerIcons(APP_ICONS);
  }
}
