export enum AccrualTypeId {
  OneTime = 1,
  Annual = 2,
  Variable = 3,
  OneTimeGraduated = 4,
  AnnualGraduated = 5,
  CapitalLease = 6,
  FairMarket = 7,
  Max100 = 8,
}

export interface CostResponse {
  has_scratchpad: 0 | 1;
  account_competitor_id: string;
  account_solution_id: string;
  solution_name?: string;
  accrual_type_id: AccrualTypeId;
  already_added: boolean;
  buyout_value: string;
  company_field: string;
  cost: string | number;
  alt_cost_name: string;
  alt_cost_description: string;
  value_prop_cost_id: string;
  value_prop_id: string;
  discount: number | string;
  alt_expense_type_id: string;
  cost_baseline_answer: string;
  cost_category_type_id: string | number;
  cost_type_id: string;
  cost_to_us: string;
  created: string;
  default_include: string;
  dep_cost_id: string;
  description: string;
  driver_factor_id: string;
  expense_type_id: string;
  formula: string;
  id: string;
  investment_situation_id: string;
  lease_term: string;
  modified: string;
  name: string;
  present_value: string;
  quantity: number;
  rate: string;
  ratio: string;
  residual_value: string;
  scale_type: string;
  scale_type_id: string;
  sequence: string;
  situation_id: string;
  straight_scaling: string;
  yr1_costs: string;
  yr2_costs: string;
  yr3_costs: string;
  yr4_costs: string;
  yr5_costs: string;
  yr6_costs: string;
  yr7_costs: string;
  yr8_costs: string;
  yr9_costs: string;
  yr10_costs: string;
}

export interface CostRequest {
  id: string;
  has_scratchpad: 0 | 1;
  accrual_type_id: AccrualTypeId;
  cost: string;
  alt_cost_description: string;
  alt_cost_name: string;
  cost_type_id: string;
  discount: string | number;
  quantity: string;
  value_prop_id: string;
  yr1_costs: string;
  yr2_costs: string;
  yr3_costs: string;
  yr4_costs: string;
  yr5_costs: string;
  yr6_costs: string;
  yr7_costs: string;
  yr8_costs: string;
  yr9_costs: string;
  yr10_costs: string;
  cost_category_type_id: string;
  value_prop_cost_id: string;
  alt_expense_type_id: string;
}

export interface AccrualType {
  description?: string;
  id: AccrualTypeId;
  name: string;
}

export interface ExpenseType {
  abbr: string;
  description: string;
  id: string;
  name: string;
  divisor?: string;
}

export interface CompetitorPickList {
  description: string;
  id: string;
  name: string;
}

export interface CostType {
  has_scratchpad: 0 | 1;
  alt_cost_name: string;
  alt_cost_description: string;
  value_prop_cost_id: string;
  value_prop_id: string;
  discount: number;
  cost_type_id: string;
  alt_expense_type_id: string;
  expand: boolean;
  account_competitor_id: string;
  account_solution_id: string;
  accrual_type_id: AccrualTypeId;
  already_added: boolean;
  buyout_value: string;
  company_field: string;
  cost: string;
  cost_baseline_answer: string;
  cost_category_type_id: string;
  cost_to_us: string;
  created: string;
  default_include: string;
  dep_cost_id: string;
  description: string;
  driver_factor_id: string;
  expense_type_id: string;
  formula: string;
  id: string;
  investment_situation_id: string;
  lease_term: string;
  modified: string;
  name: string;
  present_value: string;
  quantity: number;
  rate: string;
  ratio: string;
  residual_value: string;
  scale_type: string;
  scale_type_id: string;
  sequence: string;
  situation_id: string;
  straight_scaling: string;
  yr1_costs: string;
  yr2_costs: string;
  yr3_costs: string;
  yr4_costs: string;
  yr5_costs: string;
  yr6_costs: string;
  yr7_costs: string;
  yr8_costs: string;
  yr9_costs: string;
  yr10_costs: string;
  cost_id?: number | string;
  values?: any;
}

export interface CostCategoryType {
  id: string;
  sequence: string;
  name: string;
  name_en_GB: string;
  name_es: string;
  name_fr: string;
  name_zh_CN: string;
  name_pt_PT: string;
  name_de: string;
  name_it: string;
  name_nl: string;
  name_th: string;
  name_ms: string;
  name_id: string;
  name_ko: string;
  name_ja: string;
  created: Date;
  modified: Date;
}

export interface CostSaveResponsePayload {
  result: {
    success: boolean;
    vp_cost: CostResponse;
    message?: string;
  };
}
