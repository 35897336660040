import { SvgIcon } from '@shared_components/svg-icon/interfaces/svg-icon.interface';

const assetsPath = 'assets/images/icons/';

export const APP_ICONS: SvgIcon[] = [
  {
    name: 'google-drive',
    path: `${assetsPath}google-drive.svg`
  },
  {
    name: 'google-docs',
    path: `${assetsPath}google-docs.svg`
  },
  {
    name: 'google-sheets',
    path: `${assetsPath}google-sheets.svg`
  },
  {
    name: 'google-slides',
    path: `${assetsPath}google-slides.svg`
  },
  {
    name: 'pdf-file',
    path: `${assetsPath}pdf-file.svg`
  }
];