<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>{{trans.trans.whatProblemDescribe.value}}</div>
				</div>
			</div>
		</div>
		<div class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<small style='font-size: 70%;'>{{1000 - valueProp.vmw_q_1.length}} {{trans.trans.numCharachterLeft.value}}</small>
				<textarea name='q1answer' maxlength="1000" [(ngModel)]="valueProp.vmw_q_1" class='form-control' rows='3'></textarea>
			</div>
		</div>
		<div class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<a (click)='funcSaveQ1()' class='btn btn_customizable'>{{trans.trans.save.value}}</a>
			</div>
		</div>
	</div>
</div>