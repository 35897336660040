import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { BenefitElement } from './../benefits.model';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { RBenefitsTranslations } from './r_benefits.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { DroppedFile } from '@shared/models/dropped-file.model';

@Component({
  selector: 'app-r-benefits',
  templateUrl: './r_benefits.component.html',
})
export class RBenefitsComponent implements OnInit, OnDestroy {
  sidebar_title = 'View Benefit';
  @Input() solutionId;
  @Input() selectedBenefit;
  @Input() default_term = '3';
  @Input() mode = 'add';
  @Input() can_edit = false;

  @Output() callback = new EventEmitter();


  ngUnsubscribe = new Subject();
  masterValuePropFeature = false;
  account_id: string;

  impactAreaToggle = false;
  painPointToggle = false;
  capabilitiesToggle = false;
  opObjectivesToggle = false;
  showTranslate = false;
  driver_term: { [klass: string]: any }[] = [];
  phasing_term: { [klass: string]: any }[] = [];
  dropdownValues: { [klass: string]: any };

  dropdownValuesLoading = false;
  files: DroppedFile[] = [];

  showFactor = false;
  selectedFactor: Factor;

  hasFeature84 = false;

  @Input() selectedElem: BenefitElement = {
    metric_name: '',
    value_category_id: '',
    description: '',
    impact_type_id: '',
    accrual_type_id: '',
    scale_type_id: '',
    unique_type_id: undefined,
    default_na: undefined,
    driver_toggle: '',
    driver_factor_id: '',
    driver_baseline_answer: '',
    improvement_toggle: false,
    improvement_factor_id: '',
    unit_type_id: '',
    current_effort: '',
    conservative: '',
    probable: '',
    aggressive: '',
    financial_toggle: '',
    financial_factor_id: '',
    financial_baseline_answer: '',
    optional_toggle: '',
    tco_name: '',
    value_statement: '',
    features: '',
    account_functional_objective_id: '',
    project_id: '',
    expense_type_id: '',
    area_type_id: '',
    account_painpoint_id: '',
    account_capability_id: '',
    account_operational_objective_id: '',
    account_solution_id: '',
    has_phasing: false,
    term0: '0',
    term1: '0',
    term2: '0',
    term3: '0',
    term4: '0',
    term5: '0',
    term6: '0',
    term7: '0',
    term8: '0',
    term9: '0',
    term_val0: '',
    term_val1: '',
    term_val2: '',
    term_val3: '',
    term_val4: '',
    term_val5: '',
    term_val6: '',
    term_val7: '',
    term_val8: '',
    term_val9: '',
    selectedFuncObjectives: '',
    selectedPainPoints: '',
    areas: undefined,
    painPoints: undefined,
    capabilities: undefined,
    opObjectives: undefined,
    phase0: 100,
    phase1: 100,
    phase2: 100,
    phase3: 100,
    phase4: 100,
  };
  isDev = false;
  toggleDebug = false;
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    public trans: RBenefitsTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.hasFeature84 = this.commonService.checkFeature(84);
    console.log('selectedElem', this.selectedElem);
    this.isDev = this.commonService.isDev$;

    this.driver_term = [];
    this.phasing_term = [];
    for (let i = 0; i < Number(this.default_term); i++) {
      this.driver_term.push({ val: 0 });
      this.phasing_term.push({ val: 0 });
    }

    if (Number(this.selectedElem.default_na === 1)) {
      this.selectedElem.default_na = true;
    } else {
      this.selectedElem.default_na = false;
    }

    if (Number(this.selectedElem.unique_type_id === 1)) {
      this.selectedElem.unique_type_id = true;
    } else {
      this.selectedElem.unique_type_id = false;
    }

    this.selectedElem.driver_toggle = true;
    this.selectedElem.improvement_toggle = true;
    this.selectedElem.financial_toggle = true;

    this.files = this.selectedElem.images;

    if (
      this.selectedElem.tco_name ||
      this.selectedElem.value_statement ||
      this.selectedElem.features ||
      this.selectedElem.selectedFuncObjectives ||
      this.selectedElem.expense_type_id ||
      this.selectedElem.situations
    ) {
      this.selectedElem.optional_toggle = true;
    }

    for (let i = 0; i < Number(this.default_term); i++) {
      this.driver_term[i].val = this.selectedElem['driver_phase_' + (i + 1)];
      this.phasing_term[i].val = this.selectedElem['phase_' + i];
    }

    this.dropdownValuesLoading = true;

    this.account_id = sessionStorage.getItem('aid');
    const features = sessionStorage.getItem('features');

    if (features.indexOf('15') >= 0) {
      this.masterValuePropFeature = true;
    }

    this.getDropdownValues();

    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.sidebar_title = this.mode === 'add' ? this.trans.trans.addBenefit.value : this.trans.trans.editBenefit.value;
      });
  }

  switchChanged(event, val) {
    val = event.checked;
  }
  getDropdownValues() {
    this.dropdownValues = undefined;
    const payload = {
      driver_factor_type_id: '0',
      account_solution_id: this.solutionId,
      exclude_dns: '0',
    };
    this.solutionService.getBenefitsDropdownAggregate(this.account_id, payload).subscribe((res) => {
      this.dropdownValuesLoading = false;
      this.dropdownValues = res.result;

      this.funcPopulateDropdowns();
    });
  }

  funcPopulateDropdowns() {
    this.selectedElem.value_category_id = this.funcFindObject('value_categories', 'value_category_id', this.selectedElem.value_category_id);
    this.selectedElem.impact_type_id = this.funcFindObject('readImpactTypes', 'id', this.selectedElem.impact_type_id);
    this.selectedElem.benefit_type_id = this.funcFindObject('benefitTypes', 'id', this.selectedElem.benefit_type_id);
    this.selectedElem.accrual_type_id = this.funcFindObject('readAccrualTypes', 'id', this.selectedElem.accrual_type_id);
    this.selectedElem.driver_factor_id = this.funcFindObject('readAccountAggregatedFactors', 'id', this.selectedElem.driver_factor_id);
    this.selectedElem.improvement_factor_id = this.funcFindObject('readAccountAggregatedFactors', 'id', this.selectedElem.improvement_factor_id);
    this.selectedElem.unit_type_id = this.funcFindObject('readUnitTypes', 'id', this.selectedElem.unit_type_id);
    this.selectedElem.financial_factor_id = this.funcFindObject('readAccountAggregatedFactors', 'id', this.selectedElem.financial_factor_id);
    this.selectedElem.functional_objectives = this.funcFindObject(
      'readAccountFunctionalObjectives',
      'id',
      this.selectedElem.functional_objectives[0].account_functional_objective_id
    );
    this.selectedElem.expense_type_id = this.funcFindObject('expense', 'id', this.selectedElem.expense_type_id);
    console.log('this.selectedElem', this.selectedElem);
  }

  funcFindObject(dropdown, left, right) {
    return this.dropdownValues[dropdown].find((x) => x[left] === right);
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element);
    }
  }

  checkFeatures() {
    const features = sessionStorage.getItem('features');
    const options = features.split(',');
    if (options.indexOf('14') >= 0) {
      return true;
    }
    return false;
  }

  funcGetSelectedDropdownElem(arr: [], param, val) {
    const out = arr.find((x) => x[param] === val);
    return out;
  }
}
