import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { AccountService } from '@data/services/account/account.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ShareType {
	id?: number;
	share_id: string;
	share_role_type_id: string;
}

@Component({
	selector: 'app-master-share',
	templateUrl: './master-share.component.html',
	styleUrls: ['./master-share.component.scss']
})
export class MasterShareComponent implements OnInit, OnDestroy {
	@Input('masterValuePropId') masterValuePropId: string;
	@Input() isVPGroup = false;

	fullImagePath: string;

	// @ViewChild('tabs', { static: true })
	// private tabs: NgbTabset;

	// @ViewChild('tabsCustomer')
	// private tabsCustomer: NgbTabset;

	sharesLoader: boolean = false;
	newCustomerLoader: boolean = false;
	existingCustomerLoader: boolean = false;
	shares = [];
	addShare: boolean = false;
	users = [];
	shareRoles = [];
	share: ShareType;
	currentJustify = 'center';
	selectedGuest: number;
	guestList: Array<object> = [];
	activeIdString: string = 'Existing_Customer';
	customer = {
		'first_name': '',
		'last_name': '',
		'title': '',
		'email': '',
		'account_name': ''
	};
	type = "add";
	user_name: string;
	modalReference: NgbModalRef;
	hideValuePropActionLoader = true;

	dropdownSettings = {};

	ngUnsubscribe = new Subject();

	constructor(private CommonService: CommonService,
		private MasterValuePropService: MasterValuePropService,
		private NotificationService: NotificationService,
		private AccountService: AccountService,
		private modalService: NgbModal,
		private ValuepropService: ValuepropService,
		private translateService: TranslateService,
		public trans: Translations
	) {
		this.fullImagePath = this.CommonService.getLoaderImageUrl();
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit() {

		this.dropdownSettings = {
			singleSelection: true,
			text: "Select User",
			enableCheckAll: false,
			enableSearchFilter: true,
			labelKey: "name",
			searchBy: ['name'],
			primaryKey: "id",
			badgeShowLimit: 3,
			lazyLoading: false
		};


		if (this.isVPGroup) { this.getVPGroupShares(); } else { this.readMasterValuePropShares(); }
		// this.getGuestList();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getVPGroupShares() {


		this.sharesLoader = true;
		this.MasterValuePropService.getVPGroupShares(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			this.shares = response.result;
			this.sharesLoader = false;
		});
	}

	readMasterValuePropShares() {
		this.sharesLoader = true;
		this.MasterValuePropService.readMasterValuePropShares(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			this.shares = response.result;
			this.sharesLoader = false;
		});
	}

	onTabChange(event) {
		if (event.nextId === 'Existing_Customer') {
			if (this.isVPGroup) { this.getGuestListVP(); } else { this.getGuestList(); }
			// this.getGuestList();
		}
	}

	getGuestListVP() {


		const account_id = sessionStorage.getItem('aid');
		this.AccountService.getExistingCustomersVPGroup(account_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			if (response.result && response.result['guests']) {
				this.guestList = response.result['guests'];

			}
		});
	}

	getGuestList() {
		const account_id = sessionStorage.getItem('aid');
		this.AccountService.getExistingCustomersMVP(account_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			if (response.result && response.result['guests']) {
				this.guestList = response.result['guests'];

			}
		});
	}

	addExistingCustomer() {
		this.share = {
			share_id: this.selectedGuest['share_id'],
			share_role_type_id: this.selectedGuest['share_role_type_id']
		};

		this.saveShare('customer');
	}

	addNewCustomer() {
		this.newCustomerLoader = true;
		this.MasterValuePropService.addNewCustomer(this.masterValuePropId, this.customer).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && response.result.success) {
				this.NotificationService.success("Customer Created Successfully", false);
				// this.tabsCustomer.select('Existing_Customer');
				if (this.isVPGroup) { this.getGuestListVP(); } else { this.getGuestList(); }
				this.newCustomerLoader = false;
			}
			else if (response.result && !response.result.success) {
				this.NotificationService.error(response.result.errors.reason, false);
			}
		});
	}

	addNewCustomerVPGroup() {

		this.newCustomerLoader = true;
		this.MasterValuePropService.addNewCustomerVPGroup(this.masterValuePropId, this.customer).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && response.result.success) {
				this.NotificationService.success("Customer Created Successfully", false);
				// this.tabsCustomer.select('Existing_Customer');
				if (this.isVPGroup) { this.getGuestListVP(); } else { this.getGuestList(); }
				this.newCustomerLoader = false;
			}
			else if (response.result && !response.result.success) {
				this.NotificationService.error(response.result.errors.reason, false);
			}
		});
	}

	editShareValueProp(share) {
		this.openAddShare();
		this.type = "edit";
		this.user_name = share.first_name + ' ' + share.last_name;
		this.share = {
			share_id: share.id,
			share_role_type_id: share.share_role_type_id
		};
	}

	openAddShare() {
		this.addShare = true;
		this.type = "add";
		this.share = {
			share_id: "",
			share_role_type_id: ""
		};
		let account_id = sessionStorage.getItem('aid');
		this.ValuepropService.getUsers(account_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.users = response.result.data;
			}
		});
		this.ValuepropService.getShareRoles().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.shareRoles = response.result.filter(item => {
					return item.id != '5'
				});
			}
		});
	}

	closeAddShare() {
		this.addShare = false;
	}

	saveShare(type) {
		let id = this.share.id;
		this.share.share_id = id.toString();

		if (this.type == "add") {
			if (type == 'customer') {
				this.existingCustomerLoader = true;
			}
			if (this.isVPGroup) {
				this.MasterValuePropService.shareVPGroup(this.masterValuePropId, this.share).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					if (response.result) {
						this.closeAddShare();
						if (type == 'customer') {
							this.existingCustomerLoader = false;
							// this.tabs.select('share_user');
						}
					}
				});

			} else {
				this.MasterValuePropService.shareMasterValueProp(this.masterValuePropId, this.share).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					if (response.result) {
						this.closeAddShare();
						if (type == 'customer') {
							this.existingCustomerLoader = false;
							// this.tabs.select('share_user');
						}
					}
				});

			}
		}
		else if (this.type == "edit") {
			if (this.isVPGroup) {
				this.MasterValuePropService.updateVPGroupShare(this.masterValuePropId, this.share).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					if (response.result) {
						this.NotificationService.success("Updated Successfully", false);
						this.closeAddShare();
					}
				});
			} else {
				this.MasterValuePropService.updateMasterValuePropShare(this.masterValuePropId, this.share).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					if (response.result) {
						this.NotificationService.success("Updated Successfully", false);
						this.closeAddShare();
					}
				});
			}

		}
	}

	open(content, id) {
		this.share = {
			share_id: "",
			share_role_type_id: ""
		};
		this.share['share_id'] = id;
		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal secondLayerModalWindow', backdropClass: 'secondLayerModalBackdrop', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}

	deleteVPGroupShare() {


		this.hideValuePropActionLoader = true;
		this.MasterValuePropService.deleteVPGroupShare(this.masterValuePropId, this.share.share_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.NotificationService.success(response.result, false);
			} else {
				this.NotificationService.error(response.result, false);
			}
			this.modalReference.close();
			if (this.isVPGroup) { this.getVPGroupShares(); } else { this.readMasterValuePropShares(); }
			// this.readMasterValuePropShares();
		}
		);
	}

	deleteMasterValuePropShare() {
		this.hideValuePropActionLoader = true;
		this.MasterValuePropService.deleteMasterValuePropShare(this.masterValuePropId, this.share.share_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.NotificationService.success(response.result, false);
			} else {
				this.NotificationService.error(response.result, false);
			}
			this.modalReference.close();
			if (this.isVPGroup) { this.getVPGroupShares(); } else { this.readMasterValuePropShares(); }
			// this.readMasterValuePropShares();
		}
		);
	}

}
