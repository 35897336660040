import { DLChartComponent } from './chart.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChartModule } from 'primeng/chart';

@NgModule({
	imports: [
		ChartModule,
		CommonModule,
		FormsModule
	],
	declarations: [DLChartComponent],
	exports: [DLChartComponent],
	entryComponents: [DLChartComponent]
	})
export class DLChartModule { }