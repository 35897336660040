import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, Output, EventEmitter, Injector, AfterViewInit, OnChanges } from '@angular/core';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { createDomPortalHost } from '@shared/utils';
import { Translations } from '@data/services/translation/translation.service';

import { BenefitsImplementation } from './benefits.implementation';

import { BenefitsGridComponent } from './views/grid-view/grid.component';
import { BenefitPanelSimpleComponent } from './views/rep-simple/benefits-panel-simple.component';
import { BenefitPanelComponent } from './views/panel/benefits-panel.component';
import { BenefitTableComponent } from './views/table/benefits-table.component';
import { BenefitPanelCreateFlowComponent } from './views/create-flow/benefits-panel-create-flow.component';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { BenefitsSurveyComponent } from './views/survey/survey.component';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { StyleService } from 'app/style.service';
import { BenefitPanel2022Component } from './views/benefit-panel-2022/benefit-panel-2022.component';
import { ValueProp } from '@shared/models/value-prop.model';
import { ViewType } from '../dashboard_simple/simple.implementation';

@Component({
  selector: 'app-benefits-v2',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() view: string;
  @Input() shareView: ViewType;
  @Input() showTranslate = false;
  @Input() locked = false;
  @Input() mini = false;
  @Input() showContinue: boolean = true;

  @Input() valueProp: ValueProp;
  @Input() valuePropId: string;
  @Input() showHelp: boolean;
  @Output() funcCallback = new EventEmitter();
  @Output() callbackUpdateCurrent = new EventEmitter();
  @Output() callbackNext = new EventEmitter();
  @Output() back = new EventEmitter();

  comp: any;

  ngUnsubscribe: Subject<any> = new Subject();

  @Input() permissions: { [klass: string]: any } = {
    account_info_editable: true,
    account_info_view: true,
    assumptions_editable: true,
    assumptions_view: true,
    benefits_editable: true,
    benefits_view: true,
    costs_view: true,
    re_share: true,
    result_view: true,
    expires: null,
  };
  feature25 = false;
  style2022: boolean;

  portalHost: DomPortalHost;
  @ViewChild('portalHost', { static: true }) elRef: ElementRef;

  constructor(
    private bI: BenefitsImplementation,
    public trans: Translations,
    readonly injector: Injector,
    private RepSimpleService: RepSimpleViewService,
    private commonService: CommonService,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.styleService.style2022.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => (this.style2022 = status));
    this.locked = +this.valueProp.closed === 1;

    this.portalHost = createDomPortalHost(this.elRef, this.injector);
    this.bI.valueProp = this.valueProp;
    this.bI.valuePropId = this.valuePropId;
    this.bI.permissions = this.permissions;
    this.bI.shareView = this.shareView;
    this.bI.showTranslate = this.showTranslate;
    this.bI.locked = this.locked;
    this.bI.mini = this.mini;

    this.bI.modelsv2 = this.commonService.checkFeature(38);
    this.bI.feature39 = this.commonService.checkFeature(39);
    this.bI.feature48 = this.commonService.checkFeature(48);
    this.bI.feature46 = this.commonService.checkFeature(46);
    if (!this.valuePropId) {
      this.valuePropId = this.valueProp.id;
      this.bI.valuePropId = this.valueProp.id;
    }

    this.RepSimpleService.subscribeNotifyCallback()
      .pipe(take(1))
      .subscribe((res) => {
        this.funcCallback.emit(res);
      });

    this.bI.valuePropService.refreshBenefitDetail.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.bI.loadBenefits();
    });
  }

  ngOnChanges() {
    this.bI.valueProp = this.valueProp;
    this.bI.permissions = this.permissions;
  }

  ngOnDestroy() {
    this.bI.ngOnDestroy();
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
    this.portalHost.dispose();
    sessionStorage.removeItem('benefitListExpanded');
    sessionStorage.removeItem('metricsExpanded');
  }

  funcSaveBenefits() {
    return this.bI.funcSaveBenefits(null);
  }

  ngAfterViewInit() {
    this.trans.toggleShowTranslate.pipe(take(1)).subscribe((res) => {
      this.showTranslate = res;
      this.bI.showTranslate = res;
    });

    this.feature25 = this.commonService.checkFeature(25);
    if (this.feature25) {
      this.comp = this.style2022 ? BenefitPanel2022Component : BenefitPanelComponent;
      if (this.view !== 'rep-simple' && this.view !== 'repv1') {
        this.view = 'panel';
      }
    }

    switch (this.view) {
      case 'rep-simple':
        this.bI.expandCollapse = true;
        this.comp = BenefitPanelSimpleComponent;
        break;

      case 'repv1':
        this.bI.expandCollapse = true;
        this.comp = BenefitPanelSimpleComponent;
        break;

      case 'panel':
        this.bI.expandCollapse = true;
        this.comp = this.style2022 ? BenefitPanel2022Component : BenefitPanelComponent;
        break;

      case 'table':
        this.bI.expandCollapse = false;
        this.comp = BenefitTableComponent;
        break;

      case 'grid':
        this.comp = BenefitsGridComponent;
        break;

      case 'create-flow':
        this.bI.expandCollapse = true;
        this.bI.createFlow = true;
        this.comp = BenefitPanelCreateFlowComponent;
        break;

      case 'survey':
        this.bI.expandCollapse = true;
        this.comp = BenefitsSurveyComponent;
        break;

      default:
        this.bI.expandCollapse = false;
        this.comp = BenefitTableComponent;
        break;
    }
    this.bI.showTranslate = this.showTranslate;

    this.bI.benefitsView = this.view;
    this.bI.view = this.view;
    this.bI.ngOnInit();

    const myPortal = new ComponentPortal(this.comp);

    this.portalHost.detach(); // remove previously added component if needed
    const compRef = this.portalHost.attach(myPortal);
    if (compRef) {
      compRef.instance['valueProp'] = this.valueProp;
    }
    if (this.view == 'rep-simple' || this.view === 'repv1') {
      this.bI.funcCallback.pipe(take(1)).subscribe((res) => {
        this.funcCallback.emit(res);
      });

      this.bI.callbackNext.pipe(take(1)).subscribe((res) => {
        this.callbackNext.emit(res);
      });

      this.bI.back.pipe(take(1)).subscribe((res) => {
        this.back.emit();
      });
    }

    if (this.view == 'create-flow') {
      this.bI.callbackUpdateCurrent.pipe(take(1)).subscribe(() => {
        this.callbackUpdateCurrent.emit();
      });
    }
  }
}
