import { Component, OnInit, OnDestroy } from '@angular/core';

export class ModelNewContact {
	fname: string;
	lname: string;
	title: string;
	email: string;
}

@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {
	addContact = false;
	contactList: any[] = [];
	newContact: ModelNewContact;

	ngOnInit() {
		this.newContact = new ModelNewContact;
	}

	funcAddContact() {
		this.newContact = new ModelNewContact();
		this.addContact = !this.addContact;
	}
}
