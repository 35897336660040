import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { DefaultTranslations } from './log.translation';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() contextualHelp: any[];
  @Input() valueProp: ValueProp;
  @Input() tabInit: number;
  @Input() showTranslate = false;

  fullImagePath: string;
  image_url: string;
  changeLogLoader = false;
  changeLog: { modified: string; section: string; log_text: string }[] = [];

  ngUnsubscribe: Subject<any> = new Subject();

  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }
  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.loadChangeLog();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }
  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadChangeLog() {
    const searchLimit = this.valueProp.id;
    this.changeLogLoader = true;
    this.valuepropService
      .getValuePropChangeLog(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.value_prop_log) {
          this.changeLog = response.result.value_prop_log.map((result) => {
            result.modified = result.modified.split(' ').join('T');
            return result;
          });
        }
        this.changeLogLoader = false;
      });
  }
}
