<div id='system_status' *ngIf="aI.isDev" (click)='aI.showStatus()'><i [matTooltip]='aI.servicesUrl' class='fa fa-question' style='font-size: 24px; color: black;'></i></div>
<div id='wrapper' [ngClass]="!aI.isLoggedIn$ && !aI.hash_id ? 'bg' : 'app_wrapper'">
	<div id='left' *ngIf="aI.isLoggedIn$ && (!aI.crm || aI.showNavigation) && !aI.hash_id" [ngClass]="aI.menuToggle ? 'menu_180' : 'menu_40'">
		<div class="logo-box logo-container">
			<a *ngIf="aI.menuToggle" routerLink="/dashboard" class="logo-text ">
				<i class="logo"><img src="{{aI.companyLogo}}" alt="{{aI.companyLogo}}" style='display: block; height: auto; width: 100%;'></i>
			</a>

			<a *ngIf="!aI.menuToggle" routerLink="/dashboard" class="logo-text ">
				<i class="small-logo"><img src="{{aI.companyIcon}}" alt="{{aI.companyIcon}}" style='width: 100%;'></i>
			</a>
		</div>
		<app-leftnavi *ngIf="aI.isLoggedIn$ && !aI.isIntake$ && !aI.hash_id && (!aI.crm || aI.showNavigation)" [isMobile]='aI.isMobile' (toggleMenu)='aI.menuToggle = !aI.menuToggle' [menuToggle]='aI.menuToggle'></app-leftnavi>

	</div>

	<div id='center' [ngClass]="aI.funcSetMainCenterClass()" [ngClass]="!aI.isLoggedIn$ || aI.hash_id || aI.showNavigation ? 'center_no_padding' : ''">
		<div class="navbar" *ngIf="aI.isLoggedIn$ && !aI.isIntake$ && !aI.hash_id && (!aI.crm || aI.showNavigation)">
			<div class="navbar-inner">

				<div class="topmenu-outer" [ngStyle]="{'background': aI.currentColor}" style='margin-left: 0px !important;'>
					<a *ngIf="!aI.hash_id" (click)='aI.menuToggle = !aI.menuToggle; aI.funcSetMainCenterClass()' style='float: left; color: white; padding: 20px; padding-top: 15px; display: block;'><i class="fa fa-bars" style='font-size: 28px;'></i></a>
					<div class="top-menu">
						<ul class="nav navbar-nav navbar-left header-left">

						</ul>
						<ul class="nav navbar-nav navbar-right header-right">
							<li class="dropdown">
								<a href="#" class="dropdown-toggle" data-toggle="dropdown">
									<span class="user-name user">{{aI.user}}<i class="fa fa-angle-down"></i></span>
									<img class="img-circle avatar" *ngIf="aI.isLoggedIn$ && (aI.profileImagePath || aI.profilepicdefaultpath)" [src]="aI.profileImagePath !== '' ? aI.profileImagePath : aI.profilepicdefaultpath" width="40" height="40" alt="">
								</a>
								<ul class="dropdown-menu dropdown-list list-view" role="menu">
									<li role="presentation" *ngIf="!aI.isIntake$"><a routerLink="/profile"><i class="fa fa-user-o"></i>{{ aI.trans.trans.profile | translate }}</a></li>
									<li role="presentation"><a role="button" (click)="aI.logout()"><i class="fa fa-key"></i>{{ aI.trans.trans.logout | translate }}</a></li>
								</ul>
							</li>
						</ul>
					</div>
					<!-- <a *ngIf="!aI.hash_id" (click)='languageMenu.toggle($event)' [ngbTooltip]="aI.trans.menu.switch_languages | translate" container="body" placement="bottom" class='clickable' style='float: left; color: white; padding: 20px; padding-top: 15px; display: block;'><i class='fa fa-language' style='font-size: 28px;'></i></a> -->
					<a *ngIf="!aI.hash_id" (click)='languageMenu.toggle($event)' matTooltip="{{trans.menu.switch_languages | translate}}" matTooltipPosition='below' class='clickable' style='float: left; color: white; padding: 20px; padding-top: 15px; display: block;'><i class='fa fa-language' style='font-size: 28px;'></i></a>
					<div class='col-xs-3'>
						<app-global-search-header></app-global-search-header>
					</div>
					<p-menu #languageMenu appendTo='body' [popup]="true" [model]="aI.languages" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>
				</div>
			</div>
		</div>

		<div class="container_interior">
			<div id="main-wrapper" class="route-container" style='padding: 0px !important; margin-bottom: 90px; margin-top: 0px;'>
				<div class='row' *ngIf="aI.crm">
					<div class='col-xs-12'>
						<a *ngIf="!aI.feature42" (click)='aI.toggleNavigation()' [ngStyle]="{'color': aI.showNavigation ? 'orange' : 'inherit'}" class='clickable pull-right'>Toggle Navigation</a>
					</div>
				</div>

				<alert *ngIf="aI.isLoggedIn$ || aI.tokenExpired || aI.redirectLogin"></alert>
				<notification></notification>
				<router-outlet></router-outlet>
			</div>
			<spinner></spinner>

			<div class="page-footer" style="height: 0px;"></div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='aI.toggleSupport' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-support *ngIf="aI.toggleSupport"></app-support>
</p-sidebar>