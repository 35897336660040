<div class="models_panel">
  <div class="models_page_header_container_small mr-0">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">{{ trans.trans.title.value }}
            <i *ngIf="!(style2022$ | async)" [matTooltip]="trans.trans.ch_fastFillAssumptions.value" class="fa fa-info-circle"></i>
            <span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_fastFillAssumptions.value">
              info
            </span>
          </div>
        </div>
        <div class="pull-right">
          <button (click)="updateAll('no emit')" class="btn btn_customizable value-execution-add-a-new-value-proposition-save-data-assumptions" [disabled]="assumptionSaveLoader" data-heap="value-execution-add-a-new-value-proposition-save-data-assumptions">
            {{ trans.trans.save.value }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="valueProp">
      <div *ngFor="let group of factors">
        <div *ngIf="group.fastfill_enabled == '1'">
          <div class="row" style="margin-bottom: 10px; margin-top: 20px; font-weight: bold">
            <div class="col-xs-4">
              <span class="keywest_badge_grey">{{ group.name }}</span>
            </div>
            <div class="col-xs-2" style="text-align: center">{{ trans.trans.inputs.value }}</div>
            <div class="col-xs-2">{{ trans.trans.unit_type.value }}</div>
            <div class="col-xs-2">{{ trans.trans.status.value }}</div>
            <div class="col-xs-2">{{ trans.trans.verifiedBy.value }}</div>
          </div>

          <div
            *ngFor="let factor of group.factors"
            class="row"
            style="background-color: #f8fafc; padding-top: 2px; padding-bottom: 8px; margin-bottom: 5px; border-bottom: 1px solid #f5f5f7"
          >
            <div class="col-xs-4" style="padding-top: 10px">
                {{ factor.name }}
                <i *ngIf="factor.description && !(style2022$ | async)" [matTooltip]="factor.description" class="fa fa-info-circle"></i>
                <span *ngIf="factor.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="factor.description">
                  info
                </span>
                <i 
                  *ngIf="featureProvideNumberScratchpads && factor.has_scratchpad && +factor.scratchpad_used === 1" 
                  class="clickable fa fa-calculator factor-calculator" 
                  (click)="editScratchpad(factor)"
                ></i>
                <img 
                  *ngIf="featureProvideNumberScratchpads && factor.has_scratchpad && +factor.scratchpad_used === 0" 
                  style="width: 21px" 
                  src="assets/images/scratchpad/calculator-inactive.png" 
                  alt=""
                  (click)="editScratchpad(factor)"
                />
            </div>
            <div class="col-xs-2 readonly-center">
              <small *ngIf="factor.error !== ''" style="color: red">{{ factor.error }}</small>
              <input
                type="text"
                *ngIf="!locked"
                (click)="$any($event.target).select()"
                currencyMask
                [options]="{ prefix: '', thousands: valueProp.thousands, precision: +factor.factor_precision >= 0 ? factor.factor_precision : 2, decimal: valueProp.decimal, allowNegative: factor.allowNegative }"
                (keyup)="factor.updated = true"
                (ngModelChange)="modelChangeFn(factor)"
                [(ngModel)]="factor.value"
                class="form-control"
              />
              <span *ngIf="locked">{{ factor.value }}</span>
            </div>
            <div class="col-xs-2" style="padding-top: 10px">{{ factor.unit_type }}</div>
            <div class="col-xs-2">
              <p-dropdown appendTo="body" [options]="sourceTypes" optionLabel="name" [disabled]="locked" [placeholder]="funcGetSourceType(factor.display_source_type_id, 'factor')">
                <ng-template let-target pTemplate="item">
                  <div (click)="funcSetSource(factor, target.id, 'factor')">
                    <i *ngIf="!(style2022$ | async)" [matTooltip]="target.description" matTooltipPosition="left" class="fa fa-info-circle item-tooltip"></i>
                    <span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="target.description" matTooltipPosition="left">
                      info
                    </span>
                    <span style="margin-top: 12px">{{ target.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-xs-2" *ngIf="factor.source_type_id === '2'">
              <small *ngIf="factor.valid_error !== ''" style="color: red !important">{{ factor.valid_error }}</small>
              <input
                type="text"
                *ngIf="!locked"
                (click)="$any($event.target).select()"
                (keyup)="factor.updated = true"
                (ngModelChange)="modelChangeFn(factor)"
                [(ngModel)]="factor.validation.validating_user_name"
                class="form-control"
                required
              />
              <span *ngIf="locked">{{ factor.validation.validating_user_name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let group of benefits">
        <div *ngIf="group.fastfill_enabled == '1'">
          <div class="row" style="margin-bottom: 10px; margin-top: 20px; font-weight: bold">
            <div class="col-xs-2">
              <span class="keywest_badge_grey">{{ group.name }}</span>
            </div>
            <div class="col-xs-2" style="text-align: center">{{ trans.trans.current_effort.value }}</div>
            <!-- <div class='col-xs-2' style='text-align: center;'>Improvement %</div> -->
            <div *ngIf="group.percent_driven == '1'" class="col-xs-1" style="text-align: center">Improvement %</div>
            <div class="col-xs-2" style="text-align: center">{{ trans.trans.with.value }} {{ company_name }}</div>
            <div [ngClass]="group.percent_driven == '1' ? 'col-xs-1' : 'col-xs-2'">{{ trans.trans.units.value }}</div>
            <div class="col-xs-2">{{ trans.trans.status.value }}</div>
            <div class="col-xs-2">{{ trans.trans.verifiedBy.value }}</div>
          </div>
          <div
            *ngFor="let metric of group.metrics"
            class="row"
            style="background-color: #f8fafc; padding-top: 2px; padding-bottom: 8px; margin-bottom: 5px; border-bottom: 1px solid #f5f5f7"
          >
            <div class="col-xs-2" style="padding-top: 10px">
              {{ metric.metric_name }}
              <i *ngIf="metric.description && !(style2022$ | async)" [matTooltip]="metric.description" class="fa fa-info-circle"></i>
              <span *ngIf="metric.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="metric.description">
                info
              </span>
            </div>
            <div class="col-xs-2 readonly-center">
              <small *ngIf="metric.current_effort_error !== ''" style="color: red">{{ metric.current_effort_error }}</small>
              <input
                type="text"
                *ngIf="!locked"
                (click)="$any($event.target).select()"
                (keyup)="metric.updated = true"
                currencyMask
                [options]="{ prefix: '', thousands: valueProp.thousands, decimal: valueProp.decimal, precision: 1 }"
                [(ngModel)]="metric.current_effort"
                (ngModelChange)="modelChangeFn(metric)"
                class="form-control"
                style="text-align: right"
              />
              <span *ngIf="locked">{{ metric.current_effort }}</span>
            </div>
            <div class="col-xs-1" *ngIf="group.percent_driven == '1'">
              <input
                type="text"
                (keyup)="metric.updated = true; funcCalcImprovementPercent(metric)"
                (click)="$any($event.target).select()"
                currencyMask
                [options]="{ prefix: '', suffix: '%', thousands: valueProp.thousands, decimal: valueProp.decimal, precision: 1 }"
                [(ngModel)]="metric.percent_improvement"
                (ngModelChange)="modelChangeFn(metric)"
                class="form-control"
                style="text-align: right"
              />
            </div>
            <div class="col-xs-2 readonly-center">
              <small *ngIf="metric.future_effort_error !== ''" style="color: red">{{ metric.future_effort_error }}</small>
              <input
                type="text"
                *ngIf="!locked"
                (click)="$any($event.target).select()"
                (keyup)="metric.updated = true; funcCalcWithUs(metric)"
                currencyMask
                [options]="{ prefix: '', thousands: valueProp.thousands, decimal: valueProp.decimal, precision: 1 }"
                [(ngModel)]="metric.future_effort"
                (ngModelChange)="modelChangeFn(metric, 'future_effort')"
                class="form-control"
                style="text-align: right"
              />
              <span *ngIf="locked">{{ metric.future_effort }}</span>
            </div>
            <div [ngClass]="group.percent_driven == '1' ? 'col-xs-1' : 'col-xs-2'" style="padding-top: 10px">{{ metric.unit_type }}</div>
            <div class="col-xs-2">
              <p-dropdown appendTo="body" #dropdown [options]="sourceTypes" optionLabel="name" [disabled]="locked" [style]="{'position': 'absolute'}" [placeholder]="funcGetSourceType(metric.impact_display_source_type_id)">
                <ng-template let-target pTemplate="item">
                  <div (click)="funcSetSource(metric, target.id, 'benefit')">
                    <i *ngIf="!(style2022$ | async)" [matTooltip]="target.description" matTooltipPosition="left" class="fa fa-info-circle item-tooltip"></i>
                    <span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="target.description" matTooltipPosition="left">
                      info
                    </span>
                    <span style="margin-top: 12px">{{ target.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-xs-2" *ngIf="metric.impact_display_source_type_id === '2'">
              <small *ngIf="metric.valid_error !== ''" style="color: red !important">{{ metric.valid_error }}</small>
              <input
                type="text"
                *ngIf="!locked"
                (click)="$any($event.target).select()"
                (keyup)="metric.updated = true"
                [(ngModel)]="metric.validation.validating_user_name"
                (ngModelChange)="modelChangeFn(metric)"
                class="form-control"
                required
              />
              <span *ngIf="locked">{{ metric.validation.validating_user_name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="view == 'full'">
        <div class="col-xs-12" [hidden]="!formChanged">
          <img *ngIf="loading" class="pull-right actionLoader" [src]="fullImagePath" />
          <a *ngIf="!loading" (click)="updateAll()" class="btn btn_customizable pull-right"><i class="fa fa-save"></i> {{ trans.trans.save.value }}</a>
        </div>
      </div>
      <app-value-prop-flow-buttons
        *ngIf="view === 'simple'"
        [moveToBottom]="!crm"
        (goNext)="updateAll()"
        (goBack)="back.emit()"
        [next]="showContinue"
      ></app-value-prop-flow-buttons>
    </div>
  </div>
</div>


<p-sidebar [(visible)]='showEditScratchpad' styleClass="keywest_sidebar p-sidebar-xl" position='right'>
    <div class="sidebar_wrapper">
        <div class='sidebar_header'>
            {{trans.trans.editScratchpad.value}}
        </div>
        <div class='sidebar_container'>
            <div class='keywest_panel'>
                <app-scratchpad-component 
                  *ngIf="showEditScratchpad" 
                  factorTypeId="1" 
                  [valuePropId]='valueProp.id' 
                  [valueMetric]="metric"  
                  (SaveAndUpdateCallback)="onScratchpadSave()"
                  [year]="1"
                ></app-scratchpad-component>
            </div>
        </div>
    </div>
</p-sidebar>
