import { Injectable } from '@angular/core';

@Injectable()
export class AssumptionsVpDashTranslations {
  public config: any = {
    component: 'assumptionsVpDash',
    display_name: 'Value Prop Assumptions',
    description: 'Assumprions tab in value prop dashboard',
  };

  public trans: any = {
    assumprions: 'Assumptions',
    ch_key_assumptions: 'Specific factors which driver your value formulas',
    search: 'Search',
    assumptions_text: ' Use the slider or input the numbers in the text box to make any changes to the key data points used in the calculations',
    ch_assumptions_text: ' Use the slider or input the numbers in the text box to make any changes to the key data points used in the calculations',
    no_key_assumptions_found: 'No Key Assumptions Found',
    hasScratchpad: 'This factor has a scratchpad. Click to view or edit it.',
    editScratchpad: 'Edit Scratchpad',
    rowLimit: 'Row limit:',
    edit_all_values: 'Edit all values',
    cancel_changes: 'Cancel changes',
    save_changes: 'Save changes',
    drivers: 'Drivers',
    financials: 'Financials',
    scratchpads: 'Scratchpads',
    scroll_to_drivers: 'Scroll to Drivers',
    scroll_to_financials: 'Scroll to Financials',
    scroll_to_scratchpads: 'Scroll to Scratchpad',
    collapse: 'Collapse all',
    expand: 'Expand all',
    DriverFactors: 'Driver Factors',
    viewBenefits: 'Click to view included benefits',
    DriverEstimate: 'Driver Estimate',
    DriverOverride: 'Driver Override',
    financial: 'Financial Factors',
    FinancialEstimate: 'Financial Estimate',
    FinancialOverride: 'Financial Override',
    scratchpad: 'Scratchpad Units',
    records_drivers: 'records',
    records_financial: 'records',
    records_scratch: 'records',
    driver_factor: 'Driver Factor',
    driver_value_un_fmt: 'Driver Value',
    driver_source: 'Driver Source Typ',
    driver_source_type: 'Driver Source Type',
    financial_factor: 'Financial Factor',
    financial_value_un_fmt: 'Financial Value',
    financial_source: 'Financial Source Type',
    name: 'Scratchpad Units',
    unit_type: 'Type',
    unit_abbr: 'Unit',
    operand_value: 'Value',
    importedData: 'Imported Data',
    view_all: 'View All',
    driver_update_success: 'Driver factor updated successfully',
    financial_update_success: 'Financial factor updated successfully',
    scratchpad_units_update_success: 'Scratchpad units updated successfully',
    values_update_success: 'Values updated successfully',
    scratchpad_tooltip: 'This factor has a scratchpad. Click to view or edit it.',
    loading_assumptions: 'Loading Assumptions'
  };
}
