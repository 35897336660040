import { Injectable } from '@angular/core';

@Injectable()
export class SolutionDashboardTranslation {
  public config: any = {
    component: 'solutionDashboard',
    display_name: 'Dashboard Solutions',
    description: 'Solutions in main dashboard',
  };

  public trans: any = {
    models_modelsHeading: 'Recent Value Models',
    models_name: 'Name',
    models_timeused: 'Times Used',
    models_seemore: 'See More',
    model_noValueModels: 'No Models Found',
    add: 'Add',
  };
}
