import { Injectable } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import {
	Company,
	CompanyDetails,
	CompanyReviewPayload,
	CompanyReviewResponse,
	CompanyWithScraper,
	CompanyWithScraperSearchPayload,
	CreditsResponse,
	RecentCompany,
	UserCreditsPayload,
} from '@shared/models/company.model';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CommonService } from '../common/common.service';
import { catchError } from 'rxjs/operators';


@Injectable()
export class CompanyService {
	serviceUrl: string;
	public recentCompanies$ = new BehaviorSubject<Company[]>(this.getRecentCompanies());

	constructor(private commonService: CommonService) {
		this.serviceUrl = this.commonService.getServiceUrl();
	}

	public getCompanyDetail(companyId: number): Observable<APIResult<CompanyDetails>> {
		let endpoint = "companies/overview/" + companyId;
		return this.commonService.getAPIService(endpoint);
	}


	public getCompanyReview(companyId: number): Observable<APIResult<CompanyReviewResponse>> {
		let endpoint = "companies/feedback/" + companyId;
		return this.commonService.getAPIService(endpoint);
	}

	public addReview(companyId: number, payload: CompanyReviewPayload): Observable<APIResult<CompanyReviewResponse>> {
		let endpoint = "companies/userFeedback/" + companyId;
		return this.commonService.postAPIService(endpoint, payload);
	}

	public assignUserCredits(params: UserCreditsPayload): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "account/assignCreditsFromAccountToUser/" + sessionStorage.getItem('aid');
		return this.commonService.postAPIService(endpoint, params);
	}

	public getAccountCredits(): Observable<APIResult<CreditsResponse>> {
		let endpoint = "account/credits/" + sessionStorage.getItem('aid');
		return this.commonService.getAPIService(endpoint);
	}
	public addCompany(payload: Partial<Company>): Observable<APIResult<Company | SuccessFailResponse>> {
		let endpoint = "companies/createCompany";
		return this.commonService.postAPIService(endpoint, payload);
	}
	public getUserCredits(): Observable<APIResult<CreditsResponse>> {
		let userId = sessionStorage.getItem('uid');
		let endpoint = "credits/" + userId;
		return this.commonService.getAPIService(endpoint);
	}

	public getCompanySummary(companyId: number): Observable<APIResult<{ company: Company }>> {
		let endpoint = "companies/summary/" + companyId;
		return this.commonService.getAPIService(endpoint);
	}

	public searchWithScraper(payload: CompanyWithScraperSearchPayload): Observable<APIResult<CompanyWithScraper>> {
		let endpoint = "companies/scraper";
		return this.commonService.postAPIService(endpoint, payload);
	}

	public checkCompanyLogo(urlImage: string): Observable<any> {
        return this.commonService.getHttpNonBaseService(urlImage).pipe(
            catchError((err) => {
                const hasImg = err?.status === 200;
                return of(hasImg);
            }));
    }

	public getLogo(value: string, url = true): string {
		const path = url ? value : `${value}.com/`
		return `https://logo.clearbit.com/${path}`;
	}

	public addCompanyLogo(formData: FormData): Observable<any> {
		const endpoint = 'companies/logo';
		return this.commonService.postAPIService(endpoint, formData, 'multipart/form-data');
	}

	public addRecentCompany(company: Company) {
		const newRecentCompany: RecentCompany = {
			company,
			created: new Date()
		};
		const currentCompanies = this.getRecentCompaniesRaw();
		if(currentCompanies.length) {
			const dupe = currentCompanies.find(recentCompany => recentCompany?.company?.name === company?.name);
			if (!dupe) {
				localStorage.setItem('recentCompanies', JSON.stringify([newRecentCompany, ...currentCompanies]));
				this.recentCompanies$.next(this.getRecentCompanies());
			}
		} else {
			localStorage.setItem('recentCompanies', JSON.stringify([newRecentCompany]));
			this.recentCompanies$.next(this.getRecentCompanies());
		}
	}

	public getRecentCompaniesRaw(): RecentCompany[] {
		const now = new Date();
		const allRecents: RecentCompany[] = JSON.parse(localStorage.getItem('recentCompanies'));
		if(allRecents && allRecents.length > 0) {
			const lastTwoWeeks = allRecents.filter(company => (new Date(company.created).getDate() >= (now.getDate() - 14)));
			if(lastTwoWeeks && lastTwoWeeks.length > 0) {
				return lastTwoWeeks
			}
		}
		return [];
	}

	public getRecentCompanies(): Company[] {
		const lastTwoWeeks = this.getRecentCompaniesRaw();
		return lastTwoWeeks.map(company => company.company);
	}

	public purgeRecentCompanies() {
		localStorage.setItem('recentCompanies', JSON.stringify([...this.getRecentCompaniesRaw()]));
		this.recentCompanies$.next(this.getRecentCompanies());
	}

	public getFavoriteCompanies(): Observable<Company[]> {
		const endpoint = `user/favorite-companies/page/1/limit/all`;
		return this.commonService.getAPIService(endpoint);
	}

	public getCompanyFavorite(companyId: string): Observable<any> {
		const endpoint = `user/company/${companyId}/isfavorite`;
		return this.commonService.getAPIService(endpoint);
	}

	public addCompanyToFavorites(companyId: string):Observable<any> {
		const endpoint = 'user/company/favorite';
		return this.commonService.postAPIService(endpoint, {company_id: companyId} );
	}

	public removeCompanyFromFavorites(companyId: string):Observable<any> {
		const endpoint = `user/company/remove-favorite/${companyId}`;
		return this.commonService.deleteAPIService(endpoint);
	}
}

