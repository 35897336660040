import { Component, EventEmitter, OnInit, OnDestroy, Input, Output } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

import { TrackerGoalChartTranslation } from './tracker-goal-chart.translation';

import { ValueRealizationGoal } from '@shared/models/goal-group.model';
import { PrimengChartData } from '@shared/models/primeng-chart-data.model';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-tracker-goal-chart',
  templateUrl: './tracker-goal-chart.component.html',
  styleUrls: ['./tracker-goal-chart.component.scss'],
})
export class TrackerGoalChartComponent implements OnInit, OnDestroy {
  @Input() goalData: ValueRealizationGoal;
  @Input() loadingSave: boolean;
  @Output() saveClick = new EventEmitter<ValueRealizationGoal>();
  @Output() cancelClick = new EventEmitter<void>();

  ngUnsubscribe = new Subject();
  showTranslate: boolean;

  loadingSurveys = true;

  chartData: PrimengChartData;
  chartOptions: ChartOptions = {
    legend: {
      position: 'bottom',
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  constructor(public trans: TrackerGoalChartTranslation, private translationService: TranslationsV2Service, private commonService: CommonService) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.loadingSurveys = false;
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    let payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.buildChart();
      });
  }

  save(): void {
    this.saveClick.next(this.goalData);
  }

  cancel(): void {
    this.cancelClick.next();
  }

  buildChart(): void {
    const chartColors = this.commonService.getChartColors();

    this.chartData = {
      data: {
        labels: this.goalData.survey_factors.map((survey) => survey.survey_effective),
        datasets: [
          {
            title: '',
            label: this.trans.trans.goalChart.value,
            data: this.goalData.survey_factors.map(() => Number(this.goalData.value)),
            borderColor: chartColors[0],
            backgroundColor: chartColors[0],
            fill: false,
            type: 'line',
            pointStyle: 'line',
            order: 0,
          },
          {
            title: '',
            label: this.trans.trans.surveyChart.value,
            data: this.goalData.survey_factors.map((survey) => Number(survey.factor_value)),
            backgroundColor: chartColors[1],
            order: 1,
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }
}
