<p-menu #filters appendTo="body" [popup]="true" [model]="bI.filters" [styleClass]="'style-2022'"></p-menu>
<p-menu #actions appendTo="body" [popup]="true" [model]="bI.actions" [styleClass]="'style-2022'"></p-menu>
<p-menu #sort appendTo="body" [popup]="true" [model]="bI.sort" [styleClass]="'style-2022'"></p-menu>
<p-menu #group appendTo="body" [popup]="true" [model]="bI.group" [styleClass]="'style-2022'"></p-menu>
<div class="row" style="margin-bottom: 20px">
  <div class="col-xs-4">
    <div class="input-group keywest_search">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input [(ngModel)]="bI.menuFilter" (keyup)="bI.funcExpand(true)" type="text" placeholder="{{ trans.trans.search.value }}" class="form-control" />
      <span *ngIf="bI.menuFilter !== ''" (click)="bI.menuFilter = ''" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <div class="col-xs-5" *ngIf="bI.showBatchUpdateCheckboxes">
    <div class="pull-right">
      <div class="input-group" style="max-width: 300px">
        <select class="form-control active-type" name="benefit_active_batch" [(ngModel)]="bI.batchActive">
          <option *ngFor="let activeType of bI.activeTypes" [value]="activeType.value">
            {{ activeType.name }}
          </option>
        </select>
        <div class="input-group-btn">
          <button type="button" class="btn btn-success" style="height: 34px" (click)="bI.statusBatchUpdate()"><i class="fa fa-save"></i></button>
          <button type="button" class="btn btn-danger" style="height: 34px" (click)="bI.showBatchUpdateCheckboxes = false"><i class="fa fa-times"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="bI.showBatchUpdateCheckboxes ? 'col-xs-3' : 'col-xs-8'">
    <div (click)="actions.toggle($event)" class="pull-right toolbar_icons"><i class="fa fa-ellipsis-h"></i> {{ trans.trans.action.value }}</div>

    <div (click)="filters.toggle($event)" class="pull-right toolbar_icons">
      <i class="fa fa-filter"></i> {{ trans.trans.filter.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [isTranslationV2]="true"
        [component]="trans.config.component"
        [transObj]="trans.trans.filter"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>

    <div *ngIf="bI && bI.has_sequence" (click)="sort.toggle($event)" class="pull-right toolbar_icons">
      <i class="fa fa-sort-amount-desc" aria-hidden="true"></i> {{ trans.trans.sort.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [isTranslationV2]="true"
        [component]="trans.config.component"
        [transObj]="trans.trans.sort"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>

    <div
      *ngIf="(bI.has_pain_point || bI.has_funcobj || bI.has_capabilities || bI.has_opobj) && bI.feature48"
      (click)="group.toggle($event)"
      class="pull-right toolbar_icons"
    >
      <i class="fa fa-filter" aria-hidden="true"></i> {{ trans.trans.group.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [isTranslationV2]="true"
        [component]="trans.config.component"
        [transObj]="trans.trans.group"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
  </div>
</div>
