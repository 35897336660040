import { Injectable } from '@angular/core';

@Injectable()
export class AdvancedVpDashboardTranslations {
  public config: any = {
    component: 'advancedVpDash',
    display_name: 'Value Prop Advanced',
    description: 'Advanced tab in value prop dashboard in bottom panel',
  };

  public trans: any = {
    advanced: 'Advanced',
    ch_advanced: 'Fine tune your value even further with advanced options',
    save: 'Save',
    CustomerType: 'Customer Type',
    ChooseOne: 'Choose One',
    BaselineType: 'Baseline Type',
    CostofCapital: 'Cost of Capital (%)',
    Term: 'Term',
    DeploymentTime: 'Deployment Time',
    deployment_time: 'Deployment Time',
    GrossProfitMargin: 'Gross Profit Margin (%)',
    opportunity_id: 'Opportunity ID',
    currency_format: 'Number Format',
    currency_type_id: 'Currency Type',
    conversion_rate: 'Conversion Rate',
    roi_range: 'Roi Range',
    ch_baseline_type: `Set all of your projected improvements to conservative,
         probable, or aggressive.  This will not effect any improvements you have manually overwritten.`,
    ch_term: `The length in years of evaluating the value to a customer.`,
    ch_cost_of_capital: `The internal interest rate assigned for a company;
         this helps to define the future value of money calculations like NPV.`,
    ch_deployment_time: `Number of months it will take the customer after contract signing to start achieving any value.`,
    ch_opportunity_id: `Your CRM opportunity ID which will help administrators report on attach rates of your Value Propositions.`,
    ch_currency: `The currency you would like your Value Proposition displayed.`,
    ch_conversion_rate: `The current conversion rate between your desired currency and the US Dollar.`,
    ch_gross_profit_margin: `The revenue achieved by a company minus the cost of goods sold.`,
    ch_roi_range: 'ROI range',
    updateSuccess: 'Advanced options updated successfully',
  };
}
