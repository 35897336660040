import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { SpinnerService } from '@services/spinner.service';


@Component({
	selector: 'spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
	loading: boolean = false;
	fullImagePath: string;
	loaderHeight: number;

	subscriptionGetSpinner: Subscription;

	constructor(private spinnerService: SpinnerService,
		private CommonService: CommonService) {
		this.fullImagePath = this.CommonService.getLoaderImageUrl();
	}

	ngOnInit() {
		this.subscriptionGetSpinner = this.spinnerService.getSpinner().subscribe((loading: boolean) => {
			if(!loading) {
				setTimeout(() => {
					this.loading = loading;
				}, 1000);
			}
			else {
				this.loading = loading;
			}
			
			this.loaderHeight = window.innerHeight / 2;
		});
	}

	ngOnDestroy() {
		if (this.subscriptionGetSpinner) { this.subscriptionGetSpinner.unsubscribe(); }
	}
}
