import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { APIResult } from '@shared/models/api-result.model';
import { CreateSharePayload, ShareLog, ShareLoginPayload, UpdateShareSettingsPayload } from '@shared/models/share.models';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { ExternalUserInfo } from '@shared/models/user-info.model';
import { Observable } from 'rxjs';

@Injectable()
export class CustomerShareServices {
	serviceUrl: string;
	constructor(private commonService: CommonService) {
		this.serviceUrl = this.commonService.getServiceUrl();
	}

	public ShareLogin(payload: ShareLoginPayload): Observable<APIResult<ExternalUserInfo>> {
		let endpoint = "users/externalShareValidate";
		return this.commonService.postAPIService(endpoint, payload);
	}

	public updateShareSettings(vpid: string, payload: UpdateShareSettingsPayload): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/updateShare/" + vpid;
		return this.commonService.putAPIService(endpoint, payload);
	}

	public deleteExternalShare(vpid: string, hash: string): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/deleteExternalShare/" + vpid + "/hash/" + hash;
		return this.commonService.deleteAPIService(endpoint);
	}

	public updateExistingCustomer(vpid: string, payload: CreateSharePayload): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/externalShare/" + vpid;
		return this.commonService.postAPIService(endpoint, payload);
	}
	
	public createExternalShareSettings(vpid: string, payload: CreateSharePayload): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/externalShare/" + vpid;
		return this.commonService.postAPIService(endpoint, payload);
	}

	public updateExternalShareSettings(vpid: string, payload: CreateSharePayload): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/externalShare/" + vpid;
		return this.commonService.putAPIService(endpoint, payload);
	}

	public getReport(vpid: string, userId: string): Observable<APIResult<{ value_prop_log: ShareLog[] }>> {
		let endpoint = "valueProps/changeLog/" + vpid + "/changeId/" + userId;
		return this.commonService.getAPIService(endpoint);
	}
}
