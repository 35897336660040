import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EllipsisMenuComponent } from '@shared_components/ellipsis-menu/ellipsis-menu.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';


@NgModule({
  declarations: [EllipsisMenuComponent],
  imports: [OverlayPanelModule,CommonModule],
  exports: [EllipsisMenuComponent],
})
export class EllipsisMenuModule {}
