import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { Observable } from 'rxjs';

@Injectable()
export class EditDirectiveService {
  serviceUrl: string;
  constructor(private commonService: CommonService) {
    this.serviceUrl = this.commonService.getServiceUrl();
  }

  public postUpdateKey(payload: { labels: { [klass: string]: any } }): Observable<{ result: { labels: { [klass: string]: any } } }> {
    const endpoint = 'account/labelType';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateLanguage(userId: string | number, payload: { language_type_id: string | number }): Observable<{ language_type_id: string }> {
    const endpoint = 'users/updateLanguage/' + userId;
    return this.commonService.putAPIService(endpoint, payload);
  }
}
