import { Injectable } from '@angular/core';

@Injectable ()

export class AECostTranslations {
    
    public config: any = {
        component: 'addEditCost',
        display_name: 'Cost Form',
        description: 'Form for creating and editing a cost'
    };

    public trans: any = {
        addCost: 'Add Cost',
        editCost: 'Edit Cost',
        costType: 'Cost Type',
        accrue: 'How does this accrue?',
        unitPrice: ' Unit Price',
        scaleFactor: 'Scale Factor',
        driverFactor: 'Driver Factor (Optional)',
        answerBaseline: 'Cost Answer Baseline',
        costCategory: 'Cost Category (Optional)',
        expenseType: 'Expense Type',
        situations: 'Situations',
        includedInValueProp: 'Include this cost by default in ValueProps?',
        yes: 'Yes',
        no: 'No',
        placeholderCostType: 'Enter Cost Type',
        placeholderUnitPrice: 'Enter Unit Price',
        placeholderBaselinAnswer: 'Enter Cost Answer Baseline',
        chooseOne: 'Choose One',
        AddCostCategory: 'Add Cost Category',
        categoryName: 'Category Name',
        save: 'Save',
        add: 'Add',
        year1: 'Year 1 Cost',
        year2: 'Year 2 Cost',
        year3: 'Year 3 Cost',
        year4: 'Year 4 Cost',
        year5: 'Year 5 Cost',
        year6: 'Year 6 Cost',
        year7: 'Year 7 Cost',
        year8: 'Year 8 Cost',
        year9: 'Year 9 Cost',
        year10: 'Year 10 Cost',
        placeholderYear1: 'yr1 costs',
        placeholderYear2: 'yr2 costs',
        placeholderYear3: 'yr3 costs',
        placeholderYear4: 'yr4 costs',
        placeholderYear5: 'yr5 costs',
        placeholderYear6: 'yr6 costs',
        placeholderYear7: 'yr7 costs',
        placeholderYear8: 'yr8 costs',
        placeholderYear9: 'yr9 costs',
        placeholderYear10: 'yr10 costs',
        description: 'Description',
        accrualType: 'Accrual type',
        initialCost: 'Initial Cost',
        initialCostScaled: 'Initial Cost Scaled by Factor',
        annualRate: 'Annual Interest Rate',
        leaseTermMonths: 'Lease Term (Months)',
        categoryCost: 'Cost Category',
        scalesHow: 'Scales How?',
        ratio: 'Ratio',
        formula: 'Formula',
        scaled: 'Scaled by Factor',
        residualValue: 'Residual Value (% of Present Value)',
        buyoutValue: 'Buyout Value (% of Present Value)',
        buyoutResidualValue: 'Buyout Value (% of Residual Value)',
        yoyGrowthRate: 'YoY Growth Rate',
        minValue: 'The minimum value is 0',
        maxValue: 'The maximum value is 0',
        minValueExclude: 'Must be greater than 0',
        back: 'Back',
        are_you_sure: 'Are you sure?',
        createCustomAssetTag: 'Create custom asset tag for this cost?',
        costValueTag: 'Cost Value Tag',
        valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
        onShow: 'onshow.',
        value: 'value',
        tagsSpecialCharactersError: 'Tags cannot contain special characters',
        changesSavedSuccess: 'Changes saved successfully.',
        changesFailed: 'Cost add failed.',
        customCostTagWarning: 'Ensure that you have removed this custom cost tag from your assets to avoid disruption.',
    };
}


