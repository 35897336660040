import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2OPObjectiveTranslations {
  public config: any = {
    component: 'op_objectives',
    display_name: 'Solutions Operational Objectives'
  };

  public trans: any = {
    op_ob: 'Operational Objectives',
    add_new: 'Add new',
    delete: 'Delete',
    edit: 'Edit',
    are_you_sure: 'Are you sure?',
    ch_view: 'View',
    name: 'Operational Objective'
  };
}
