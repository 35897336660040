<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{title_factor}}
		<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
		[transObj]="trans.trans.view_factor" (callback)="getTranslations()"></app-editTranslation>
	</div>
	<div class='sidebar_container'>
		<div class='keywest_panel'>
			<div class="row">
				<div class="col-md-12">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.name.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.name}}
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<label for="description" class="sidebar_label_readonly">{{trans.trans.description.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.description}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<label for="account_factor_category_id" class="sidebar_label_readonly">{{trans.trans.factor_groups.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.factor_groups" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.account_factor_category_id}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.unit_type.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.unit_type" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.unit_type_id}}
				</div>
				<div class="col-md-6">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.precision.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.precision" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.factor_precision}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.scaled_by.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.scaled_by" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.scales_by}}
				</div>
				<div class="col-md-6">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.scales_how.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.scales_how" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.scales_how}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.is_key_factor.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.is_key_factor" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.is_key_factor}}
				</div>
				<div class="col-md-4">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.literal_number.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.literal_number" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.literal_number}}
				</div>
				<div class="col-md-4">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.greater_than_zero.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.greater_than_zero" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.greater_than_zero}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.min.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.min" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.min}}
				</div>
				<div class="col-md-4">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.max.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.max" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.max}}
				</div>
				<div class="col-md-4">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.increment.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.increment" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.increment}}
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.company_element.value}}
						<app-editTranslation *ngIf="showTranslate"  [component]="'r_factors'" [isTranslationV2]="true" 
						[transObj]="trans.trans.company_element" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{formObjFactor.company_field}}
				</div>
			</div>
		</div>
	</div>
</div>