<div [routerLink]="link.routerLink" class="link-card-wrapper">
    <div class="row" style="height: 100%; margin:auto">
        <!-- Icon Wrapper -->
         <div class="col-xs-2 icon-wrapper" [style.background-color]="link.iconColor + '1a'">
             <span [ngClass]="link.iconClass" class="icon" [style.color]="link.iconColor">{{link.iconName}}</span>
         </div>
         <div class="col-xs-9 settings-card-title">
            {{link.title}}
         </div>
    </div>
</div>