import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'translations',
    display_name: 'Translations',
    description: 'Translations list in profile',
  };
  public trans: any = {
    back_to_profile: 'Back to Profile',
    translations: 'Translations',
    k: 'Key',
    1: 'English US',
    2: 'English UK',
    3: 'Spanish',
    4: 'French',
    5: 'Chinese',
    6: 'Portugese',
    7: 'German',
    8: 'Japanese',
  };
}
