import { Injectable } from '@angular/core';

@Injectable()
export class RepSimpleResultsTranslaions {
  public config: any = {
    component: 'simpleResults',
    display_name: 'Rep Simple Results',
    description: 'Results panel in rep simple',
  };

  public trans: any = {
    'cancel': 'Cancel',
    'createdBy': 'Created By:',
    'your_return_on_investment': 'ROI',
    'your_total_cost_of_ownership': 'TCO',
    'loading_results': 'Loading Results',
    'edit_status': 'Click to edit status',
    'cost_vs_benefit': 'Cost vs. Benefit',
    'year_return_on_investment': 'yr Return on Investment',
    'year_total_cost_of_ownership': 'yr Total Cost of Ownership',
    'year_net_present_value': 'yr Net Present Value',
    'your_payback_period_formula': 'Your Payback Period Formula',
    'the_roi_formula': 'The ROI Formula',
    'your_roi_formula': 'Your ROI Formula',
    'your_npv_formula': ' Your NPV Formula',
    'your_three_months_cost_of_delay_formula': ' Your 3 Months Cost of Delay Formula',
    'potential_total_benefit': 'Potential Total Benefit',
    'potential_total_financial_benefit': 'Potential Total Financial Benefit',
    'payback_period_in_months': 'Payback Period in Months',
    'payback_period': 'Payback Period',
    'return_on_investment': 'Return On Investment (ROI)',
    'ratio_of_cumulative_net_benefit_investment_over_the_product_period':
      'Ratio of cumulative net benefits to cumulative investment over the product period. An ROI of 200% means that every dollar spent on a project nets two dollars in return. You want to align your ROI to be compelling enough for action by the buyer but also not too large to be unbelievable.',
    'net_present_value': 'Net Present Value (NPV)',
    'total_benefits': 'Total Benefits',
    'total_costs': 'Total Costs',
    'total_cost_of_ownership_estimate':
      'Total cost of ownership (an estimate of all the direct and indirect costs involved in acquiring and operating a product or system over its lifetime)',
    'the_tco_formula': 'The TCO formula',
    'your_tco_formula': 'Your TCO formula',
    'the_payback_period_formula': ' The Payback Period Formula',
    'the_npv_formula': ' The NPV Formula',
    'total_current_costs': 'Total Current Costs',
    'total_future_costs': ' Total Future Costs',
    'three_month_cost_of_delay': '3 Month Cost of Delay',
    'three_months_cost_of_delay': ' 3 Month Cost of Delay',
    'the_three_months_cost_of_delay_formula': 'The 3 Months Cost of Delay Formula',
    'the_length_of_time_required_for_an_investment_to_recover_its_initial_outlay_in_terms_of_profits':
      'The length of time required for an investment to recover its initial outlay in terms of profits or savings. If insulation costs $110 and saves $55 a year, its payback period would be two years ',
    'is_the_difference_between_the_present_value_of_cash':
      'Is the difference between the present value of cash inflows and the present value of cash outflows over a period of time. NPV is used in capital budgeting and investment planning to analyze the profitability of a projected investment or project',
    'what_is_the_cost_of_the_customer_delaying_the_decision_to_buy':
      'What is the cost of the customer delaying the decision to buy by 1 full quarter (3 months) due to lost opportunity of growth, productivity, cost removal or risk? ',
    'results_text':
      'This step gives a summary of the results. Click on the percentage breakdown to the right of the donut to view the benefit calculation in detail.Note that you may need to scroll down depending on your screen resolution.',
    'close': 'Close',
    'business_case': 'Business Case',
    'notes': 'Notes',
    'benefits': 'Benefits',
    'bpt_note':
      'The benefit per transaction is the value each transaction provides to a customer. It is calculated by Maximum Annual Value divided by the number of transactions in the use case.',
    'benefits_per_transaction': 'Benefits per Transaction',
    'discoveryQuestions': 'Discovery Questions',
    'statusUpdateSuccess': 'Status Updated successfully',
    'your_roi_status_is': 'Your ROI status is',
    'return': 'Return',
    'your_payback_status_is': 'Your Payback Status is',
    'your_npv_status_is': 'Your NPV status is',
    'roi': 'ROI',
    'costs': 'Costs',
    'currentCosts': 'Current Costs',
    'futureCosts': 'Future Costs',
    'netBenefits': 'Net Benefits',
    'npv': 'NPV',
    'year1': 'Year 1',
    'lock': 'Lock Value Prop',
    'unlock': 'Unlock Value Prop',
    'vpLockedSucces': 'Value Prop Locked Successfully',
    'vpUnlockedSucces': 'Value Prop Unlocked Successfully',
    'total_cost_of_ownership': 'Total Cost of Ownership (TCO)',
    'save': 'Save',
    'benefitsAndCosts': 'Benefits & Costs',
    'Year 1': 'Year 1',
    'Year 2': 'Year 2',
    'Year 3': 'Year 3',
    'Year 4': 'Year 4',
    'Year 5': 'Year 5',
    'green': 'GREEN',
    'red': 'RED',
    'orange': 'ORANGE',
    'grey': 'GREY',
    'grey_explanation': 'your administrator has not entered a threshold range for this value.',
    'between': 'between',
    'not_between': 'not between',
    'and': 'and',
    'but_within_the_tolerance': 'but within the tolerance of',
    'or_within_the_tolerance': 'or within the tolerance of',
    'overview': 'Overview',
    'results': 'Results',
    'no_benefits': 'No benefits found. Please return to step 2 and activate a few benefits',
    'click_benefit_info': 'Click to see the benefits',
    'netCashFlow': 'Net Cashflow',
    'benefitPerTransaction': 'Benefit Per Transaction',
    'clickToDisplay': 'Click to display',
  };
}
