<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
<ng-template #loadedContent>
  <div class="col-xs-4">
    <div class="input-group keywest_search search-container" *ngIf="!loading">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input type="text" class="form-control" [formControl]="searchField" />
      <span *ngIf="searchField.value !== ''" (click)="resetSearchField()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <p-table #tableSuccessCriteria [value]="successQuestionsFiltered" styleClass="keywest_table" tableStyleClass="keywest_table" >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              {{ trans.trans.name.value }}
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th>
              {{ trans.trans.description.value }}
            </th>
            <th *ngIf="showNotes">
              {{ trans.trans.notes.value }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr>
            <td class="col-name-success clickable" (click)="editCriteria(rowData, rowIndex)">
              {{ rowData.name }}
            </td>
            <td>
              <span>{{ rowData.answer }}</span>
            </td>
            <td *ngIf="showNotes" class="col-notes-success">
              <div class="notes-count">{{ rowData?.notes?.length }}</div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2" class="text-center">{{ trans.trans.emptyTable.value }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-template>

<p-sidebar [(visible)]="successSidebarOpen" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="noteControl.reset()">
  <div *ngIf="successSidebarOpen" class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.successCriteria.value }}
    </div>
    <div class="sidebar_container">
      <div class="question-grid" [formGroup]="questionForm">
        <div class="name-title">{{ selectedQuestion?.name }}</div>
        <div class="spacer"></div>
        <div class="content-box-success">
          <app-error-input-wrapper *ngIf="!showNotes; else staticAnswer" [showOnTouched]="true" [errorMessage]="errorDict.get('answer')">
            <textarea rows="5" cols="30" formControlName="answer" pInputTextarea [autoResize]="true"></textarea>
          </app-error-input-wrapper>
          <ng-template #staticAnswer>
            <div class="static-answer">{{ selectedQuestion?.answer }}</div>
          </ng-template>
        </div>
        <div class="spacer"></div>
        <div class="dflex justify-ce" *ngIf="!showNotes; else notes">
          <button class="btn btn_customizable cancel pull-right marg-r" (click)="cancel()">{{ trans.trans.cancel.value }}</button>
          <button class="btn btn_customizable pull-right" [disabled]="questionForm.invalid" (click)="saveQuestion()">{{ trans.trans.save.value }}</button>
        </div>
        <ng-template #notes>
          <div class="notes-container">
            <div class="notes-header">
              {{ trans.trans.notes.value }}
            </div>
            <div class="notes-inner">
              <div *ngIf="businessReviewService.canEdit | async" class="note-textarea">
                <textarea rows="5" cols="30" [formControl]="noteControl" pInputTextarea [autoResize]="true" [placeholder]="trans.trans.addNewNote.value"></textarea>
                <button class="btn btn_customizable pull-right" [disabled]="noteControl.invalid || loadingNoteSave" (click)="addNote()">{{ trans.trans.addNote.value }}</button>
              </div>
              <p-progressBar *ngIf="loadingNoteSave" mode="indeterminate"></p-progressBar>

              <div class="note-layout-grid" *ngFor="let note of selectedQuestion.notes">
                <div class="avatar-circle">{{ note.first_name + ' ' + note.last_name | nameInitials }}</div>

                <div class="note-content">
                  <div class="note-header">
                    <span class="note-author">{{ note.first_name + ' ' + note.last_name }}</span>
                    <div class="emdash">—</div>
                    <span class="note-date">{{ note.modified | safariDate | date }}</span>
                    <div></div>
                    <app-are-you-sure
                      *ngIf="businessReviewService.canEdit | async"
                      [matTooltip]="trans.trans.deleteNote.value"
                      matTooltipPosition="above"
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="deleteNote(note.id)"
                    ></app-are-you-sure>
                  </div>
                  <div class="note-text">
                    {{ note.note }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</p-sidebar>
