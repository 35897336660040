import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValuePropNameChangerTranslations } from '../valueprop-name-changer.translation';

@Component({
  selector: 'app-valueprop-name-changer',
  templateUrl: './valueprop-name-changer.component.html',
  styleUrls: ['./valueprop-name-changer.component.scss'],
})
export class ValuepropNameChangerComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Output() nameSubmit = new EventEmitter<string>();
  @Output() cancel = new EventEmitter();

  nameForm: FormGroup;
  ngUnsubscribe = new Subject();

  constructor(private fb: FormBuilder, public trans: ValuePropNameChangerTranslations, private translationService: TranslationsV2Service, private commonService: CommonService) {}

  ngOnInit(): void {
    this.nameForm = this.fb.group({
      name: [this.name || '', [Validators.required]],
    });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  submitName() {
    this.nameSubmit.emit(this.nameForm.get('name').value);
  }

  private getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
