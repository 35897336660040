import { snakeCase } from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { PainPointsService } from '@data/services/pain_points/pain.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PainPoint } from '@data/services/valueposition/models/painpoint.interface';
import { NotificationService } from '@services/notification.service';
import { of, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ModelV2AEPainPointTranslations } from './ae_painpoints.translation';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';

@Component({
  selector: 'app-ae-painpoints',
  templateUrl: './ae_painpoints.component.html',
})
export class AEPainPointsComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Output() callback = new EventEmitter();
  @Input() mode = 'add';
  @Output() closeCallback = new EventEmitter();

  account_id: string;

  @ViewChild('formAddEditPainPoint') formAddEditPainPoint: NgForm;
  sidebar_title = 'Add Pain Point';
  ngUnsubscribe$ = new Subject();

  @Input() selectedElem: PainPoint = {
    name: '',
    description: '',
    id: '',
  };
  showTranslate = false;
  isLoading = false;

  constructor(
    private painPoint: PainPointsService,
    private commonService: CommonService,
    public trans: ModelV2AEPainPointTranslations,
    private translationService: TranslationsV2Service,
    private notificationService: NotificationService,
    private customAssetTagService: CustomAssetTagService
  ) {}

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  insertPainPoint(form: NgForm): void {
    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
    };

    this.painPoint
      .postPainPoint(this.account_id, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.ngUnsubscribe$),
        switchMap((capRes) => {
          if (this.selectedElem.has_tag) {
            const payload = this.customAssetTagService.createTagPayload(capRes.result, this.selectedElem.name, this.selectedElem.tag, AssetTagArray.PainPoints, true);
            return this.customAssetTagService.createAssetTag(payload);
          } else {
            return of(capRes);
          }
        })
      )
      .subscribe((response) => {
        if (response?.result && response.result === 'The tags must be unique.') {
          this.notify('tagMustBeUnique', 'error');
          return;
        }
        this.notify('successCreate');
        this.callback.emit();
      });
  }

  updatePainPoint(form: NgForm): void {
    const payload = {
      account_painpoint_id: form.controls.id.value,
      name: form.controls.name.value,
      description: form.controls.description.value,
    };
    this.painPoint
      .putPainPoint(this.account_id, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((response) => {
          if (this.selectedElem.has_tag) {
            const assetId = this.selectedElem.tag_data?.id;
            const payload = this.customAssetTagService.createTagPayload(
              this.selectedElem.id,
              this.selectedElem.name,
              this.selectedElem.tag,
              AssetTagArray.PainPoints,
              true,
              assetId
            );
            return this.customAssetTagService.createAssetTag(payload);
          } else if (this.selectedElem.tag_data) {
            return this.customAssetTagService.deleteAssetTag(this.selectedElem.tag_data?.id);
          } else {
            return of(response);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((response) => {
        if (response?.result && response.result === 'The tags must be unique.') {
          this.notify('tagMustBeUnique', 'error');
          return;
        }
        this.notify('successUpdate');
        this.callback.emit();
      });
  }
  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_painpoint.value : this.trans.trans.edit_painpoint.value;
      });
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.selectedElem = {
        ...this.selectedElem,
        tag: snakeCase(this.selectedElem.name),
      };
    } else if (this.selectedElem.tag_data?.id) {
      this.notify('ppTagWarning');
    }
  }

  private notify(transString: string, type: 'error' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
