<div class="row" style='padding-top: 100px;'>
    <div class="col-md-3 center">
        <div class="panel panel-white login-panel" id="js-alerts">
            <div class="panel-body">
                <alert></alert>
                <div class="login-box">
                    <div class="text-center"><img src="@assets/images/xDLIcon.png"
                            alt="Value Execution by Xfactor.io" style="max-height: 40px;"></div>
                    <h1 class="text-center">Welcome to Value Execution by Xfactor.io.</h1>
                    <div *ngIf="showYea">
                        <p>Glad you're here! You will be redirected to your destination in a moment...</p>
                        <div><i class="fa fa-refresh fa-spin" style="font-size:24px"></i></div>
                    </div>
                    <div *ngIf="showNea">Something has gone wrong.</div>
                </div>
            </div>
        </div>
    </div>
</div>