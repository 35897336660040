import { HttpClient } from '@angular/common/http';
import { map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';

import { Subscription, Subject } from 'rxjs';
import { CaseStudySectionsTranslations } from './sections.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
})
export class SectionsComponent implements OnInit, OnDestroy {
  @Input() caseStudy: { [klass: string]: any };
  @Input() type: string;
  @Output() valueChange = new EventEmitter();

  caseStudyLoader: boolean;
  fullImagePath: string;
  imageUrl: string;
  files: FileList;
  sectionTypes: Array<{ [klass: string]: any }> = [];
  sectionType: { [klass: string]: any } = {};
  caseStudyObject: { [klass: string]: any };
  addSectionLoader = false;
  addSectionTypeLoader = false;
  modalReference: NgbModalRef;
  modalReferenceMedia: NgbModalRef;
  isCollapsed = true;
  section: { [klass: string]: any } = {
    type: '',
    description: '',
    media: '',
    mediadescription: '',
  };
  newSectionType: { name: string; description: string; } = {
    name: '',
    description: '',
  };
  currentSection: { [klass: string]: any } = {};
  currentMedia: { [klass: string]: any } = {};
  mediadescription = '';

  subscriptionsaveSection: Subscription;
  subscriptionupdateSection: Subscription;
  subscriptioncreateSectionType: Subscription;
  subscriptionCaseStudySectionTypes: Subscription;
  subscriptionaddMediaToSection: Subscription;
  subscriptiondeleteCaeStudySection: Subscription;
  subscriptiondeleteSectionMedia: Subscription;
  subscriptionupdateSectionMedia: Subscription;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    private factsService: FactsService,
    private solutionService: SolutionService,
    private httpClient: HttpClient,
    private notification: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: CaseStudySectionsTranslations
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.getSections();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  openMedia(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    if (this.subscriptionsaveSection) {
      this.subscriptionsaveSection.unsubscribe();
    }
    if (this.subscriptionupdateSection) {
      this.subscriptionupdateSection.unsubscribe();
    }
    if (this.subscriptioncreateSectionType) {
      this.subscriptioncreateSectionType.unsubscribe();
    }
    if (this.subscriptionCaseStudySectionTypes) {
      this.subscriptionCaseStudySectionTypes.unsubscribe();
    }
    if (this.subscriptionaddMediaToSection) {
      this.subscriptionaddMediaToSection.unsubscribe();
    }
    if (this.subscriptiondeleteCaeStudySection) {
      this.subscriptiondeleteCaeStudySection.unsubscribe();
    }
    if (this.subscriptiondeleteSectionMedia) {
      this.subscriptiondeleteSectionMedia.unsubscribe();
    }
    if (this.subscriptionupdateSectionMedia) {
      this.subscriptionupdateSectionMedia.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  openAddSection(content: any) {
    this.resetSection();
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  openEditSection(content: any, caseStudySection: { [klass: string]: any }) {
    this.resetSection();

    this.currentSection['type'] = caseStudySection['name'];
    this.currentSection['description'] = caseStudySection['description'];
    this.currentSection['case_study_section_id'] = caseStudySection['id'];

    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
  }

  resetSection() {
    this.section = {
      type: '',
      description: '',
      media: '',
      mediadescription: '',
    };
  }

  handleInputChange(event: Event) {
    const el = event.target as HTMLInputElement;
    this.files = el.files;
  }

  addSection() {
    this.addSectionLoader = true;

    const payload = new FormData();
    payload.append('company_id', this.caseStudy['company_id']);
    payload.append('case_study_id', this.caseStudy['id']);

    payload.append('sections[0][section]', this.sectionType['name']);
    payload.append('sections[0][description]', this.section['description']);
    payload.append('sections[0][section_type_id]', this.sectionType['id']);

    for (let i = 0; i < this.files.length; i++) {
      payload.append(this.sectionType['name'] + '[' + i + ']', this.files[i]);
      payload.append('description[' + this.sectionType['name'] + '][' + i + ']', this.section['mediadescription']);
    }

    this.subscriptionsaveSection = this.solutionService.saveSection(payload).subscribe(() => {
      this.addSectionLoader = false;
      this.modalReference.close();
      this.valueChange.emit();
    });
  }

  updateSection() {
    this.addSectionLoader = true;

    this.subscriptionupdateSection = this.solutionService.updateSection(this.currentSection).subscribe((response) => {
      this.addSectionLoader = false;
      this.modalReference.close();
      this.valueChange.emit();

      if (response.result) {
        this.notification.success(response.result, false); // Params {message, islogout}
      }
    });
  }

  addSectionType() {
    this.addSectionTypeLoader = true;
    this.subscriptioncreateSectionType = this.factsService.createSectionType(this.newSectionType).subscribe(() => {
      this.addSectionTypeLoader = false;
      this.closeAddSectionType();
      this.getSections();
    });
  }

  closeAddSectionType() {
    this.isCollapsed = true;
    this.newSectionType = {
      name: '',
      description: '',
    };
  }

  getSections() {
    this.subscriptionCaseStudySectionTypes = this.factsService.CaseStudySectionTypes().subscribe((response) => {
      if (response.result && response.result['sectionTypes']) {
        this.sectionTypes = response.result['sectionTypes'];
      }
    });
  }

  openAddMedia(content: any, caseStudySection: { [klass: string]: any }) {
    this.caseStudyObject = caseStudySection;
    this.modalReferenceMedia = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });

    this.modalReferenceMedia.result.then(
      () => {},
      () => {}
    );
  }

  addMedia() {
    this.addSectionLoader = true;

    const payload = new FormData();
    payload.append('section_id', this.caseStudyObject['id']);

    for (let i = 0; i < this.files.length; i++) {
      payload.append('images' + '[' + i + ']', this.files[i]);
      payload.append('description' + '[' + i + ']', this.section['mediadescription']);
    }

    this.subscriptionaddMediaToSection = this.solutionService.addMediaToSection(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false); // Params {message, islogout}
        this.addSectionLoader = false;
        this.modalReferenceMedia.close();
        this.valueChange.emit();
      }
    });
  }
  downAsset(imageUrl: string) {
    this.httpClient
      .get(imageUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return {
            filename: imageUrl.substring(imageUrl.lastIndexOf('/') + 1),
            data: res,
          };
        })
      )
      .subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        () => {},
        () => {}
      );
  }

  deleteSection(sectionId: any) {
    this.subscriptiondeleteCaeStudySection = this.solutionService.deleteCaeStudySection(sectionId).subscribe((response) => {
      if (response.result) {
        this.notification.success(response.result, false); // Params {message, islogout}
        this.valueChange.emit();
      }
    });
  }

  deleteMedia(mediaId: any) {
    this.subscriptiondeleteSectionMedia = this.solutionService.deleteSectionMedia(mediaId).subscribe((response) => {
      if (response.result) {
        this.notification.success(response.result, false); // Params {message, islogout}
        this.valueChange.emit();
      }
    });
  }

  openEditMedia(content: any, media: { [klass: string]: any }) {
    this.mediadescription = media.description;
    this.currentMedia = {
      case_study_section_image_id: media.id,
    };
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  editMedia() {
    this.currentMedia['description'] = this.mediadescription;

    this.subscriptionupdateSectionMedia = this.solutionService.updateSectionMedia(this.currentMedia).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false); // Params {message, islogout}
        this.valueChange.emit();
      }
      this.modalReference.close();
    });
  }
}
