import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { AppImplementation } from '../../app.implementation';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { KeywestTranslations } from './keywest.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, switchMap, take } from 'rxjs/operators';
import { ApiServiceService } from 'app/api-service.service';
import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { UserService } from '@data/services/user/user.service';
import { UserInfo, UserProduct } from '@shared/models/user-info.model';
import { SessionUserInfo } from '@shared/models/session-user-info.model';
import { SettingsService } from '@data/services/settings/settings.service';
import { MenuItem } from 'primeng/api/menuitem';
import { SolutionService } from '@data/services/solution/solution.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-component-keywest',
  templateUrl: './keywest.component.html',
  styleUrls: ['./keywest.component.scss'],
})
export class AppSystemKeyWestView implements OnInit, AfterViewInit, OnDestroy {
  ngUnsubscribe = new Subject<boolean>();
  isLoggedIn$: boolean;
  isIntake$: boolean;
  dlAdmin: boolean = false;
  accountAdmin: boolean = false;
  isValuePropUser: boolean = false;
  isValuePositionUser: boolean = false;
  isValueRealizationUser: boolean = false;
  isValueInsightUser: boolean = false;
  isValueRealized2 = false;
  isSolutionAdmin: boolean = false;
  masterValuePropFeature: boolean = false;
  account_id: string;
  lmsUrl: string;
  isGuest: boolean = false;
  @Input() isMobile: boolean = false;
  isDev: boolean = false;

  iconUrl = '/dashboard';

  translationReady = false;

  @Input() menuToggle: boolean = true;
  @Output() toggleMenu = new EventEmitter<any>();

  showTranslate: boolean = false;
  isAdmin: boolean = false;
  @Input() hash_id: boolean = false;

  modelsV2: boolean = false;
  loaded: boolean = false;
  langId;
  language_type_id;
  languages: any[] = [
    { field: '1', lang: 'en', header: 'English US' },
    { field: '3', lang: 'es', header: 'Spanish' },
    { field: '4', lang: 'fr', header: 'French' },
    { field: '5', lang: 'zh', header: 'Chinese' },
    { field: '6', lang: 'pt', header: 'Portuguese' },
    { field: '7', lang: 'de', header: 'German' },
    { field: '8', lang: 'ja', header: 'Japanese' },
  ];
  flag: string;
  vpg: boolean = false;
  url: string = '';

  feature18: boolean = false;
  isDefaultcompanyLogo = false;
  user: SessionUserInfo;
  loggedUser: UserInfo;
  profilepicdefaultpath = 'https://media.value-cloud.com/images/default_user.png';
  avatarActions: MenuItem[];
  hasUserPrivileges = false;
  supportUrl: string;
  communityUrl: string;
  feature122: boolean = false;
  hasSettingsLinks = false;
  currentNav: string;
  customerSuccessPriv = false;
  xdl: string;
  add_xdl_btn = false;

  constructor(
    private translationService: TranslationsV2Service,
    public trans: KeywestTranslations,
    public aI: AppImplementation,
    private accountService: AccountService,
    private commonService: CommonService,
    private router: Router,
    private apiServiceService: ApiServiceService,
    private userService: UserService,
    private settingService: SettingsService,
    private solutionService: SolutionService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {

    this.CheckForXVP();
    this.userService.profileImage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((image) => {
      this.loggedUser.profile_pic_filepath = image;
    });
    this.accountService.getSupportUrl();
    this.accountService.supportUrl$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((url) => {
      if (url) {
        this.supportUrl = this.prependHttp(url);
      } else {
        this.supportUrl = null;
      }
    });
    this.accountService.getCommunityUrl().pipe(take(1)).subscribe();
    this.accountService.communityUrl$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((url) => {
      if (url) {
        this.communityUrl = this.prependHttp(url);
      } else {
        this.communityUrl = null;
      }
    });
    this.accountService.lmsPath$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((url) => {
      if (url) {
        this.lmsUrl = this.prependHttp(url);
      } else {
        this.lmsUrl = 'https://decisionlink.talentlms.com';
      }
    });

    this.aI.isDefaultcompanyLogo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.isDefaultcompanyLogo = res));
    this.user = JSON.parse(sessionStorage.getItem('user_Info'));
    this.feature18 = this.commonService.checkFeature(18);
    this.feature122 = this.commonService.checkFeature(122);

    this.commonService.notifyFeatureChange.subscribe((featureId) => {
      if (featureId) {
        switch (featureId) {
          case '18':
            this.feature18 = this.commonService.checkFeature(18);
            break;
          case '122':
            this.feature122 = this.commonService.checkFeature(122);
            break;
          case '134':
            this.userService.replaceFF134$.next(this.commonService.checkFeature(134));
            break;
          case '58':
            this.aI.feature58 = this.commonService.checkFeature(featureId);
            break;
          default:
            this.feature18 = this.commonService.checkFeature(18);
            this.feature122 = this.commonService.checkFeature(122);
        }
      }
    });

    this.account_id = sessionStorage.getItem('aid');
    this.url = this.commonService.getHostName();

    const rid = sessionStorage.getItem('rid');
    if (rid == '7') {
      this.isGuest = true;
    }

    if (this.aI.isLoggedIn$) {
      this.getTranslations();
    }

    this.langId = sessionStorage.getItem('language_type_id');
    this.apiServiceService.profiledetails.subscribe(() => {
      this.langId = sessionStorage.getItem('language_type_id');

      let feature56 = this.commonService.checkFeature('56'); // VPG v2
      this.settingService.feature74 = this.commonService.checkFeature(74);
      this.settingService.feature87 = this.commonService.checkFeature(87);
      let priv7 = this.commonService.checkPrivilege('7');
      this.vpg = feature56 && priv7;

      this.flagIcon(this.langId);
      this.setModuleLicense();
      this.parseFeatures();
      this.readLMSPath();

      this.getTranslations();
      this.hasUserPrivileges = this.commonService.hasPrivileges();
      this.hasSettingsLinks = this.settingService.hasSettingsLinks();
    });
    this.flagIcon(this.langId);
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.flag = this.flagIcon(res);
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.userService.uid.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (sessionStorage.getItem('vcu')) {
        this.retrieveUserData(id);
      }
    });
    this.userService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((info) => (this.loggedUser = info));
    const uid = sessionStorage.getItem('uid');

    if (!this.loggedUser && uid) {
      this.retrieveUserData(Number(uid));
    }
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route: ActivatedRoute) => route.firstChild.data),
        switchMap((routeData) => routeData)
      )
      .subscribe((data) => {
        if (data.currentNav) {
          if (data.currentNav === 'value-case') {
            this.currentNav = data.caseType;
          } else {
            this.currentNav = data.currentNav;
          }
        } else {
          this.currentNav = null;
        }
      });
  }

  ngAfterViewInit() {
    this.setModuleLicense();
    this.parseFeatures();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  // Imported from https://www.npmjs.com/package/prepend-http
  prependHttp(url: string, { https = true } = {}) {
    url = url.trim();
    if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
      return url;
    }
    return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
  }

  gotoProfile() {
    this.router.navigateByUrl('/profile');
  }

  flagIcon(langId) {
    this.flag;
    switch (langId) {
      case '1': {
        this.flag = 'flag-icon flag-icon-um';
        break;
      }
      case '3': {
        this.flag = 'flag-icon flag-icon-es';
        break;
      }
      case '4': {
        this.flag = 'flag-icon flag-icon-fr';
        break;
      }
      case '5': {
        this.flag = 'flag-icon flag-icon-cn';
        break;
      }
      case '6': {
        this.flag = 'flag-icon flag-icon-pt';
        break;
      }
      case '7': {
        this.flag = 'flag-icon flag-icon-de';
        break;
      }
      case '8': {
        this.flag = 'flag-icon flag-icon-jp';
        break;
      }
    }
    return this.flag;
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.translationReady = true;

        this.avatarActions = [
          {
            label: this.trans.trans.profile.value,
            icon: 'fa fa-user',
            command: () => this.router.navigate(['/full-profile']),
          },
          {
            label: this.trans.trans.learn.value,
            icon: 'fa fa-graduation-cap',
            command: () => window.open(this.lmsUrl, '_blank'),
          },
          {
            label: this.trans.trans.support.value,
            icon: 'fa fa-question-circle-o',
            command: () => (this.supportUrl ? window.open(this.supportUrl, '_blank') : this.router.navigate(['/support'])),
          },
          {
            label: this.trans.trans.logout.value,
            icon: 'fa fa-sign-out',
            command: () => this.logout(),
          },
        ];
      });
  }

  logout() {
    if (!!sessionStorage.getItem('xpt') && !!sessionStorage.getItem('xdl_referer')){
    
      const token = sessionStorage.getItem('xpt');
      const xp_domain = sessionStorage.getItem('xdl_referer');
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const xp_logout_endpoint = xp_domain + '/api/auth/logout';

      this.http.get(xp_logout_endpoint, { headers, observe: 'response' }).subscribe(response => {
        
        if (response.status === 200) {

          this.commonService.notifyLoggedOut.next();
          this.translationService.destroyTranslationCache();
          this.solutionService.destroySituationTreeMap();
          this.aI.resetBranding();
          sessionStorage.clear();
          localStorage.clear();
          heapReset();
          localStorage.removeItem('AnalyticsSession');
          this.router.navigate(['/login']);
          
      } else {
        // handle other status codes

        this.notificationService.error('X Platform logout failed. Please Try again.', false);

      }
    });
  } else {

    this.commonService.notifyLoggedOut.next();
    this.translationService.destroyTranslationCache();
    this.solutionService.destroySituationTreeMap();
    this.aI.resetBranding();
    sessionStorage.clear();
    localStorage.clear();
    heapReset();
    localStorage.removeItem('AnalyticsSession');

    if ( document.getElementById('custom-theme') ) {
      document.getElementById('custom-theme').remove();
    }

    this.router.navigate(['/login']);

  }
  }

  setModuleLicense() {
    let products: UserProduct[] = JSON.parse(sessionStorage.getItem('products'));

    if (products && products.length) {
      this.isValuePositionUser = products.some((item) => item.product_id === '2');
      this.isValuePropUser = products.some((item) => item.product_id === '3');
      this.isValueRealizationUser = products.some((item) => item.product_id === '4');
      this.isValueInsightUser = products.some((item) => item.name === 'Value Insights');
      this.isValueRealized2 = products.some((item) => item.product_id === '5');
    } else {
      this.isValuePositionUser = false;
      this.isValuePropUser = false;
      this.isValueRealizationUser = false;
      this.isValueInsightUser = false;
      this.isValueRealized2 = false;
    }

    if (sessionStorage.getItem('privileges')) {
      const privsString = sessionStorage.getItem('privileges');
      const privs = privsString.split(',');
      this.isSolutionAdmin = privs.includes('2');
      this.accountAdmin = privs.includes('1');
      this.customerSuccessPriv = privs.includes('14');
    } else {
      this.isSolutionAdmin = false;
      this.accountAdmin = false;
      this.customerSuccessPriv = false;
    }
  }
  parseFeatures() {
    this.masterValuePropFeature = false;
    if (sessionStorage.getItem('features')) {
      const features = sessionStorage.getItem('features');
      const accountFeatures = features.split(',');
      this.masterValuePropFeature = false;
      this.modelsV2 = false;

      if (accountFeatures.indexOf('15') >= 0) {
        this.masterValuePropFeature = true;
      }

      if (accountFeatures.indexOf('38') >= 0) {
        this.modelsV2 = true;
      }
    }
  }

  readLMSPath() {
    this.accountService
      .readLMSPath(this.account_id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result.success === true) {
          this.lmsUrl = (response.result.result as { lms_custom_path: string }).lms_custom_path;
        } else {
          this.lmsUrl = 'https://decisionlink.talentlms.com';
        }
      });
  }

  private retrieveUserData(uid: number): void {
    this.userService.getUser(Number(uid)).subscribe((response) => {
      this.userService.userInfo = response.result;
    });
  }

  CheckForXVP(){
    this.xdl = sessionStorage.getItem('xdl_referer');
    if (this.xdl !== null && this.xdl !== undefined) {
      this.add_xdl_btn = true;
      this.iconUrl = this.xdl;
    }
  }

  GoToXFactor(){
    window.location.href = this.xdl + '/mikey.html';
  }
}
