import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { ValuePropCashflowTranslations } from './cashflow.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { Cashflow, ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';

interface Column {
	col: string;
}


@Component({
	selector: 'app-cashflow',
	templateUrl: './cashflow.component.html',
	styleUrls: ['./cashflow.component.scss']
})
export class CashflowComponent implements OnInit, OnDestroy {
	@Input() contextualHelp: any[];
	@Input() valueProp: ValueProp;
	@Input() tabInit: number;
	@Input() showTranslate = false;

	fullImagePath: string;
	image_url: string;
	cashflowLoader = false;
	cashflowObj: Cashflow;
	cashflowObjCurrent: Cashflow;
	cashflowObjFuture: Cashflow;
	cashflowObjTotal: Cashflow;
	cashflow: Cashflow[] = [];

	years = 3;
	subscriptiongetCashflow: Subscription;
	subscriptionrefreshDashboard: Subscription;
	style2022$: Observable<boolean>;

	ngUnsubscribe: Subject<any> = new Subject();

	cols: Column[] = [];

	constructor(
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		public trans: ValuePropCashflowTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

	}
	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.loadCashflow();
		this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.loadCashflow();
		})
		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.getTranslations();
		})

		this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}
	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		})
	}
	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	loadCashflow() {
		var self = this;
		let searchLimit = this.valueProp.id;
		this.cashflowLoader = true;
		this.valuepropService.getCashflow(searchLimit).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			this.cashflowLoader = false;
			if (response.result) {
				for(let idx = 1; idx < 11; idx++) {
					if (response.result.benefits[`Year${idx}`]) {
						this.years = idx;
						this.cols.push({ col: `${typeof this.trans.trans.year1 === 'string' ? this.trans.trans.year1.split(' ')[0] : this.trans.trans.year1.value.split(' ')[0] } ${idx}` });
					} else {
						break;
					}
				}
				this.cashflowObj = response.result;
				self.cashflow = [];
				Object.keys(this.cashflowObj).forEach(function (key, index) {
					self.cashflow.push(self.cashflowObj[key]);
				});
			}
		});
	}

	refreshTranslation() {
		this.getTranslations();
	}
}