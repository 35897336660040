import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'r_list',
    display_name: 'Solution List Read',
    description: 'List of Solutions'
  };

  public trans: any = {
    view_model: 'View Model',
    model_name: 'Model name',
    categories: 'Categories',
    average_dep_time: 'Average Deployment Time',
    description: 'Description'
  };
}
