<div class="panel panel-white" *ngIf="!companyId">
	<div class='panel-heading' style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
		<div class='row'>
			<div class='col-xs-6'>
				<div class='panel-title'>{{ trans.search_company.pick_your_account | translate }}</div>
			</div>
		</div>

	</div>
	<div class="panel-body addForm">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<form class="" name="CompanySearchForm" #CompanySearchForm="ngForm"
						(ngSubmit)="getRecentSearchComp()">
						<div class='input-group' style='width: 100% !important;'>
							<input type="text" class="form-rounded form-control" style='width: 100% !important;'
								name="companyname" id="companyname" (keyup.enter)='getRecentSearchComp()'
								placeholder="{{ trans.search_company.search_for_a_company | translate }}"
								[(ngModel)]="companyname">
							<div class='input-group-addon' *ngIf="!showActionLoader" (click)="getRecentSearchComp()">
								<i class="fa fa-search"></i>
							</div>
							<div class='input-group-addon' *ngIf="showActionLoader">
								<img class="actionLoader" src="{{fullImagePath}}" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-md-12">
				<div *ngIf="!companyList" class="table-container">
					<p-paginator [rows]="rowsPerPage" [totalRecords]="companiesList.length" (onPageChange)="paginate($event)" #p></p-paginator>
					<table id='search-simple' class="table custom-table" style='width: 100%;'>
						<thead>
							<tr>
								<th>{{ trans.general.name_label | translate }}</th>
								<th>Account ID</th>
								<th>{{ trans.search_company.revenue | translate }}</th>
								<th>{{ trans.search_company.employees | translate }}</th>
								<th>{{ trans.search_company.ticker | translate }}</th>
								<th>{{ trans.search_company.city | translate }}</th>
								<th>{{ trans.search_company.country | translate }}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let company of companiesPaginatedList">
								<td>
									<a (click)="selectCompany(company.id, company.name)"
										class='clickable'>{{company.name}}</a>
									<i class="icon-info tooltip-custom m-l-xs" [ngbTooltip]="tipContent"
										placement="right" container="body"></i>
									<ng-template #tipContent class="toolTipContent">
										<p>
											<strong></strong>{{company.name}}
										</p>
										<div *ngIf="company.account_defined_id">{{company.account_defined_id}}</div>
										<div *ngIf="company.location_type">{{company.location_type}}</div>
										<div *ngIf="company.country">{{company.country}}</div>
										<div>
											<div *ngIf="company.address1">{{company.address1}}</div>
											<div *ngIf="company.city && company.state && company.zip">{{company.city}},
												{{company.state}}
												{{company.zip}}
											</div>
										</div>
									</ng-template>
								</td>
								<td data-title="Account ID">{{company.account_defined_id}}</td>
								<td data-title="Revenue">{{company.revenue_fmt}}(M)</td>
								<td data-title="Employees">{{company.employees_fmt}}</td>
								<td data-title="Ticker">{{company.ticker}}</td>
								<td data-title="City">{{company.city}}</td>
								<td data-title="Country">{{company.country}}</td>
							</tr>
						</tbody>
					</table>

				</div>
				<div *ngIf="companiesNoResult" class="m-t-md col-sm-10 comp_no_data">
					<div class="searchTableContainer text-center">
						<h4>{{ trans.search_company.no_companies_found | translate }}</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="panel panel-white" *ngIf="companyId">
	<div class='panel-heading' style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
		<div class="panel-title">{{ trans.search_company.heres_what_we_know_about_the_account_you_have_selected |
			translate }}</div>
		<button class="btn btn-back pull-right"
			(click)="companyId = null; quickCompanyDetailsDescription = false; detailedDescription = false;">
			<i class="fa fa-chevron-left"></i>{{ trans.button.back | translate }}
		</button>
	</div>
	<div class="panel-body addForm">
		<div class="row" [hidden]="detailedDescription">
			<div class="col-md-5">
				<ul class="list-unstyled param-wrapper">
					<li class="row">
						<div class="col-md-4 key">{{ trans.general.name_label | translate }}:</div>
						<div class="col-md-8 param">{{companyDetails?.name}}</div>
					</li>
					<li class="row">
						<div class="col-md-4 key">Account ID:</div>
						<div class="col-md-8 param">{{companyDetails?.account_defined_id}}</div>
					</li>
					<li class="row" *ngIf="!feature89">
						<div class="col-md-4 key">{{ trans.search_company.address | translate }}:</div>
						<div class="col-md-8 param">{{companyDetails?.address1}}</div>
					</li>
					<li class="row" *ngIf="!feature89">
						<div class="col-md-4 key">{{ trans.search_company.country | translate }}:</div>
						<div class="col-md-8 param">{{companyDetails?.country}}</div>
					</li>
					<li class="row" *ngIf="!feature89">
						<div class="col-md-4 key">{{ trans.search_company.sector | translate }}:</div>
						<div class="col-md-8 param">{{companyDetails?.dl_sector_name}}</div>
					</li>
					<li class="row" *ngIf="!feature89 && companyDetails?.url">
						<div class="col-md-4 key">{{ trans.search_company.website | translate }}:</div>
						<div class="col-md-8 param"><a
								href='{{companyDetails.url.substring(0,4) == "http" ? companyDetails.url : "https://" + companyDetails.url }}'
								target='_blank' class='clickable'>{{companyDetails?.url}}</a></div>
					</li>
					<li class="row" *ngIf="!feature89">
						<div class="col-md-4 key">{{ trans.search_company.revenue | translate }}:</div>
						<div class="col-md-8 param">{{companyDetails?.revenue_fmt}} (M)</div>
					</li>
					<li class="row" *ngIf="!feature89">
						<div class="col-md-4 key">{{ trans.search_company.employees | translate }}:</div>
						<div class="col-md-8 param">{{companyDetails?.employees_fmt}}</div>
					</li>
				</ul>
			</div>
			<div class="col-md-7" *ngIf="companyDetails?.synopsis">
				<div class="description-label" style='font-weight: bold; margin: 0px;'>{{
					trans.search_company.description | translate }}</div>
				<div>
					<p>{{companyDetails.synopsis}}</p>
				</div>
			</div>
		</div>
		<div class='row' style='margin-top: 20px;' *ngIf="!loading">
			<div class='col-xs-12'>
				<a (click)='funcContinue()' class='btn btn-next'>Let's get started</a>
			</div>
		</div>
		<div class='row' *ngIf="loading">
			<div class='col-xs-12'>
				<img class="actionLoader" src="{{fullImagePath}}" />
			</div>
		</div>
	</div>
</div>


<p-sidebar position='right' styleClass="keywest_sidebar p-sidebar-lg" [(visible)]="addCompany">
	<app-vp-add-company-no-trans *ngIf="addCompany" [modalCreate]="true" (companyCreated)="companyCreated($event)"
		(enableSave)="enableSave($event)" (cancelCreation)="cancelCompany()"></app-vp-add-company-no-trans>
</p-sidebar>
