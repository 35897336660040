import { NgModule } from '@angular/core';

import { SolutionListComponent } from "./solution-list.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';
 
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import {MultiSelectModule} from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { AESolutionListModule } from './ae_list/ae_list.module';
import { RSolutionListModule } from './r_list/r_list.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './solution-list.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ModelV2ShareComponent } from './share/share.component';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { MatTooltipModule } from '@angular/material/tooltip';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		SolutionListComponent,
		ModelV2ShareComponent
	],
	imports: [
		
		ProgressBarModule,
		AESolutionListModule,
		RSolutionListModule,
		AreYouSureModule,
		DropdownModule,
    MatTooltipModule,
		MultiSelectModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		MenuModule,
		SidebarModule,
		TableModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		TabViewModule,
		EditTranslationsModule,
		EllipsisMenuModule
	],
	providers: [
		TranslationsV2Service, DefaultTranslations
	],
	entryComponents: [
		SolutionListComponent,
	],
	exports: [SolutionListComponent]
})
export class SolutionListModule { }