import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFactors',
})
export class FilterFactorsPipe implements PipeTransform {
  transform(
    factors: Array<{ label: string; value: string; linked: boolean }>,
    goalId: string,
    linkedFactorsId: string[],
    selectedFactors
  ): Array<{ label: string; value: string; linked: boolean }> {
    if (!linkedFactorsId || !linkedFactorsId?.length) {
      return factors;
    }
    return factors.map((factor) => {
      return {
        label: factor.label,
        value: factor.value,
        linked: linkedFactorsId.includes(factor.value) && !selectedFactors[goalId].includes(factor.value),
      };
    });
  }
}
