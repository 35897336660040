import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
})
export class CompaniesComponent {
  active = 1;
  page = 'company';
  constructor(private translateService: TranslateService, public trans: Translations) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }
}
