import { Injectable } from '@angular/core';

@Injectable()
export class CasstudyQuoteTranslations {
  public config: any = {
    component: 'quoteCasestudy',
    display_name: 'Case Study Quote',
    description: 'Testimonial of a case study',
  };

  public trans: any = {
    save: 'Save',
    isRequired: 'Required',
    Yes: 'Yes',
    No: 'No',
    add_testimonial: 'Add Testimonial',
    are_you_sure_that_you_want_to_remove_this_testimonial: 'Are you sure that you want to remove this testimonial?',
    delete: 'Delete',
    delete_case_study_testimonial: 'Delete Case Study Testimonial?',
    first_name: 'First Name',
    is_required: 'Is Required',
    last_name: 'Last Name',
    no_testimonials_found: 'No Testimonials Found',
    testimonial: 'Testimonial',
    title: 'Title',
    update: 'Update',
  };
}
