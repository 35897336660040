import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { UserService } from '@data/services/user/user.service';

import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CollateralTranslations } from './collateral.translation';
import { ValuePropCollateral } from '@shared/models/collateral.model';

@Component({
  selector: 'app-collateral',
  templateUrl: './collateral.component.html',
  styleUrls: ['./collateral.component.scss'],
  providers: [FactsService],
})
export class CollateralComponent implements OnInit, OnDestroy {
  @Input() showHelp;
  @Input() can_edit: boolean = false;
  @Input() contextualHelp;
  @Input() solutionID: string;
  @Input() valuePropId: number;
  @Input() tabInit: number;
  @Input() showTranslate: boolean = false;
  @Input() hideHeader: boolean = false;

  ngUnsubscribe = new Subject();

  collateral: ValuePropCollateral[] = [];
  typeOfCollateral: 'Add' | 'Edit';
  hideActionLoader = true;

  collateralFormObj: Partial<ValuePropCollateral> = {
    id: null,
    format_type_id: null,
    name: null,
    description: null,
    url: null,
  };

  toggleAddCollateral: boolean = false;
  dropdown: any[] = [];
  strSearch = '';

  cols = [
    { field: 'name', header: 'Name' },
    { field: 'format', header: 'Format' },
    { field: 'url', header: 'URL' },
  ];

  constructor(
    private solutionService: SolutionService,
    private valuepropService: ValuepropService,
    private commonService: CommonService,
    private notification: NotificationService,
    private userService: UserService,
    public trans: CollateralTranslations,
    private translationService: TranslationsV2Service
  ) {}

  ngOnInit() {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.userService
      .getContextualHelpList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.commonService.contextualHelp.next(result.result);
        }
      });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.typeOfCollateral = 'Add';

    this.getCollateralList();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcToggleView(elem: ValuePropCollateral): void {
    this.collateralFormObj = Object.assign({}, elem);
    this.toggleAddCollateral = true;
    this.typeOfCollateral = 'Edit';
  }

  funcShowAddCollateral(): void {
    this.toggleAddCollateral = !this.toggleAddCollateral;
    for (var k in this.collateralFormObj) {
      this.collateralFormObj[k] = null;
    }
    this.typeOfCollateral = 'Add';
  }

  showEditCollateral(elem: ValuePropCollateral): void {
    this.toggleAddCollateral = true;
    this.collateralFormObj = Object.assign({}, elem);
    this.collateralFormObj.id = this.collateralFormObj.id;
    this.typeOfCollateral = 'Edit';
  }

  getCollateralList(): void {
    if (this.solutionID) {
      this.solutionService
        .getCollateralList(this.solutionID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.collateral = response.result;
        });
    } else {
      this.valuepropService
        .readCollateral(this.valuePropId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.collateral = response.result;
        });
    }
  }

  openWindow(url: string): void {
    window.open(url, '_blank');
  }

  deleteCollateral(id: number): void {
    this.hideActionLoader = false;
    this.solutionService
      .deleteCollateral(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.hideActionLoader = true;
        this.getCollateralList();
        this.notification.success('Collaterals deleted successfully', false); // Params {message, islogout}
      });
  }
}
