import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { RowLimit } from 'app/_models/row-limit.model';
import * as Chart from 'chart.js';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { AlertService } from '../../_services/alert.service';
import { ReleaseToPartnersComponent } from './release-to-partners/release-to-partners.component';

@Component({
  selector: 'app-solution-list',
  templateUrl: './solution-list.component.html',
  styleUrls: ['./solution-list.component.scss'],
})
export class SolutionListComponent implements OnInit, OnDestroy {
  @ViewChild('createSolutionModal') createSolutionModal: NgbModalRef;
  companiesNoResult = false;
  image_url: string;
  fullImagePath: string;
  solutions: { [klass: string]: any }[] = [];
  solutionCategoryList: { id: string; category: string }[] = [];
  solutionCategoryChart: { [klass: string]: any }[] = [];
  solutionsLoader: boolean;
  solutionsCategoryLoader: boolean;
  solutionChartLoader: boolean;
  solutionId: number | string;
  modalReference: NgbModalRef;
  solutionName: string;
  solutionCategory: { [klass: string]: any };
  hideActionLoader = true;
  isSolutionAdmin = false;
  categoryName: string;
  hideaddCategorySolution = false;
  hideEditCategorySolution = true;
  hideDeleteCategorySolution = true;
  hidecategoryName: boolean;
  hideSolutionCategoriesList = false;
  modalChartData: number;
  topUsedSolutionChartLoader = true;
  releaseTypes: { [klass: string]: any }[];
  removePartnermodalReference: NgbModalRef;
  removesolutionId: number;
  selectedSolution: { [klass: string]: any };
  categoryModal = false;
  labelCount: number;
  contextualHelp: { [klass: string]: any } = {};

  pageNumber = 1;
  pageLimit = 10;
  searchText = '';

  order = 'desc';
  sortBy = 'id';

  subscriptioncontextualHelp: Subscription;
  subscriptionreadSolution: Subscription;
  subscriptiongetAvailableSolutions: Subscription;
  subscriptiongetSolutionCategoryList: Subscription;
  subscriptiongetSolutionCategoryChart: Subscription;
  subscriptioncreateSolutionCategory: Subscription;
  subscriptionupdateSolutionCategory: Subscription;
  subscriptiondeleteSolutionCategory: Subscription;
  subscriptiongetTopFiveSolutions: Subscription;
  subscriptionreleaseToAllUsers: Subscription;
  subscriptiondeleteSolution: Subscription;
  subscriptionrealeaseToPartners: Subscription;
  subscriptiongetReleaseTypes: Subscription;
  subscriptionSubscribeLoggedIn: Subscription;

  view = 'models';
  items: MenuItem[] = [];
  is_conversation: boolean;

  rowLimit: MenuItem[];
  columns = [
    { header: 'Model Name', field: 'name' },
    { header: 'Category', field: 'category' },
    { header: 'Released to Users', field: 'userReleaseStatusId' },
    { header: 'Released to partners', field: 'partnerReleaseStatusId' },
  ];
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private notification: NotificationService,
    private factsService: FactsService,
    private alertService: AlertService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.funcDrawMenu();

    this.getSolutions();

    this.getSolutionCategoryList();
    this.getSolutionCategoryChart();
    this.hidecategoryName = false;
    this.getTopUsedSolutions();
    this.getReleaseTypes();
    this.funcBuildPageLimit();

    this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();

    this.subscriptionreadSolution = this.solutionService.readSolution.subscribe(() => {
      this.getSolutions();
    });
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.subscriptionSubscribeLoggedIn = this.alertService.subscribeLoggedIn().subscribe(() => {
      this.getSolutions();

      this.getSolutionCategoryList();
      this.getSolutionCategoryChart();
      this.hidecategoryName = false;
      this.getTopUsedSolutions();
      this.getReleaseTypes();

      this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();
    });
  }

  ngOnDestroy() {
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptionreadSolution) {
      this.subscriptionreadSolution.unsubscribe();
    }
    if (this.subscriptiongetAvailableSolutions) {
      this.subscriptiongetAvailableSolutions.unsubscribe();
    }
    if (this.subscriptiongetSolutionCategoryList) {
      this.subscriptiongetSolutionCategoryList.unsubscribe();
    }
    if (this.subscriptiongetSolutionCategoryChart) {
      this.subscriptiongetSolutionCategoryChart.unsubscribe();
    }
    if (this.subscriptioncreateSolutionCategory) {
      this.subscriptioncreateSolutionCategory.unsubscribe();
    }
    if (this.subscriptionupdateSolutionCategory) {
      this.subscriptionupdateSolutionCategory.unsubscribe();
    }
    if (this.subscriptiondeleteSolutionCategory) {
      this.subscriptiondeleteSolutionCategory.unsubscribe();
    }
    if (this.subscriptiongetTopFiveSolutions) {
      this.subscriptiongetTopFiveSolutions.unsubscribe();
    }
    if (this.subscriptionreleaseToAllUsers) {
      this.subscriptionreleaseToAllUsers.unsubscribe();
    }
    if (this.subscriptiondeleteSolution) {
      this.subscriptiondeleteSolution.unsubscribe();
    }
    if (this.subscriptionrealeaseToPartners) {
      this.subscriptionrealeaseToPartners.unsubscribe();
    }
    if (this.subscriptiongetReleaseTypes) {
      this.subscriptiongetReleaseTypes.unsubscribe();
    }
    if (this.subscriptionSubscribeLoggedIn) {
      this.subscriptionSubscribeLoggedIn.unsubscribe();
    }
  }

  funcDrawMenu() {
    this.items = [
      {
        label: 'Models',
        styleClass: this.view === 'models' ? 'menuSelected' : '',
        command: () => {
          this.view = 'models';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Model Categories',
        styleClass: this.view === 'model_categories' ? 'menuSelected' : '',
        command: () => {
          this.view = 'model_categories';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Value Categories',
        styleClass: this.view === 'value_category' ? 'menuSelected' : '',
        command: () => {
          this.view = 'value_category';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Situations',
        styleClass: this.view === 'situations' ? 'menuSelected' : '',
        command: () => {
          this.view = 'situations';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Pain Points',
        styleClass: this.view === 'pain_points' ? 'menuSelected' : '',
        command: () => {
          this.view = 'pain_points';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Capabilities',
        styleClass: this.view === 'capabilities' ? 'menuSelected' : '',
        command: () => {
          this.view = 'capabilities';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Operational Objectives',
        styleClass: this.view === 'operational_objectives' ? 'menuSelected' : '',
        command: () => {
          this.view = 'operational_objectives';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Functional Objectives',
        styleClass: this.view === 'functional_objectives' ? 'menuSelected' : '',
        command: () => {
          this.view = 'functional_objectives';
          this.funcDrawMenu();
        },
      },
    ];
  }

  getSolutions() {
    let searchLimit = '/limit/' + this.pageLimit + '/page/' + this.pageNumber + '/search/' + 'all' + '/sortby/' + this.sortBy + '/order/' + this.order;
    this.solutionsLoader = true;
    this.subscriptiongetAvailableSolutions = this.solutionService.getAvailableSolutions(searchLimit).subscribe((response) => {
      if (response.result) {
        this.solutions = response.result.data;
      }
      this.solutionsLoader = false;
    });
  }
  getSolutionCategoryList() {
    this.solutionsCategoryLoader = true;
    this.subscriptiongetSolutionCategoryList = this.solutionService.getSolutionCategoryList().subscribe((response) => {
      if (response.result) {
        this.solutionCategoryList = response.result;
      }
      this.solutionsCategoryLoader = false;
    });
  }

  funcCloneModel(elem: { [klas: string]: any }) {
    elem.cloneInProgress = true;
    this.solutionService.cloneModel(elem.id, {}).subscribe(() => {
      this.getSolutions();
      this.notification.success('Clone Successful', false);
    });
  }

  getSolutionCategoryChart() {
    this.solutionChartLoader = true;
    const chartData = { labels: [], values: [] };
    this.subscriptiongetSolutionCategoryChart = this.solutionService.getSolutionCategoryChart().subscribe((response) => {
      if (response.result) {
        const solutionCategoryChart = response.result;
        if (response && response.result) {
          for (let i = 0; i < solutionCategoryChart.length; i++) {
            chartData.labels.push(solutionCategoryChart[i].category);
            chartData.values.push(solutionCategoryChart[i].count);
          }
        }

        this.chartCategoryOverview(chartData);
        if (this.modalChartData === 4) {
          this.chartCategoryOverviewModal(chartData);
        }
      }

      this.solutionChartLoader = false;
    });
  }

  open(content: any, id: number, name: string) {
    this.solutionId = id;
    this.solutionName = name;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  openSolution(content: number, id: string, name: string, elem?: { [klass: string]: any }) {
    this.solutionId = id;
    this.solutionName = name;
    this.categoryName = this.solutionName;
    // tslint:disable-next-line: triple-equals
    this.is_conversation = elem && elem.is_conversation == 1 ? true : false;
    if (content === 2) {
      this.hideaddCategorySolution = true;
      this.hideEditCategorySolution = false;
      this.hideDeleteCategorySolution = true;
    } else if (content === 3) {
      this.hideaddCategorySolution = true;
      this.hideEditCategorySolution = true;
      this.hideDeleteCategorySolution = false;
      this.hidecategoryName = true;
      this.hideSolutionCategoriesList = true;
    }
  }

  openCategoryChart(categorylist: any, tempflag: number) {
    this.modalChartData = tempflag;
    this.getSolutionCategoryChart();
    this.modalReference = this.modalService.open(categorylist, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  saveCategorySolution() {
    if (!this.categoryName) {
      return false;
    }

    this.solutionCategory = {
      category: this.categoryName,
      is_conversation: this.is_conversation ? 1 : 0,
    };
    this.subscriptioncreateSolutionCategory = this.solutionService.createSolutionCategory(this.solutionCategory).subscribe(() => {
      this.hideActionLoader = true;
      this.getSolutionCategoryList();
      this.closeClick();
      this.notification.success('Solution Category created successfully', false);
    });
  }

  updateCategorySolution() {
    if (!this.categoryName) {
      return false;
    }

    this.solutionCategory = {
      category: this.categoryName,
      solution_category_id: this.solutionId,
      is_conversation: this.is_conversation ? 1 : 0,
    };
    this.subscriptionupdateSolutionCategory = this.solutionService.updateSolutionCategory(this.solutionCategory).subscribe(() => {
      this.hideActionLoader = true;
      this.getSolutionCategoryList();
      this.closeClick();
      this.notification.success('Solution Category updated successfully', false);
    });
  }

  deleteCategorySolution() {
    this.subscriptiondeleteSolutionCategory = this.solutionService.deleteSolutionCategory(this.solutionId).subscribe(() => {
      this.hideActionLoader = true;
      this.getSolutionCategoryList();
      this.closeClick();
      this.notification.success('Solution Category deleted successfully', false);
    });
  }

  closeClick() {
    this.categoryName = '';
    this.hidecategoryName = false;
    this.hideaddCategorySolution = false;
    this.hideEditCategorySolution = true;
    this.hideDeleteCategorySolution = true;
    this.hideSolutionCategoriesList = false;
  }

  openModal(content: any, solution: { [klass: string]: any }) {
    this.selectedSolution = solution;
    this.modalReference = this.modalService.open(content, { size: 'lg', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  chartCategoryOverview(chartData: { [klass: string]: any }) {
    const color = [];
    const labelsUnit = [];
    const efficiency = [];
    const chart_colors = this.commonService.getChartColors();

    const dynamicColors = function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    };
    const chartLabel: string[] = chartData.labels.filter((item) => {
      return item !== '';
    });
    this.labelCount = chartLabel.length;
    if (this.labelCount > 6) {
      this.categoryModal = true;
      this.labelCount = this.labelCount - 6;
    }
    for (let i = 0; i < chartData.labels.length; i++) {
      if (chartData.labels[i] !== undefined && chartData.labels[i] !== '') {
        labelsUnit.push(chartData.labels[i]);
        efficiency.push(chartData.values[i]);

        if (i < chart_colors.length) {
          color.push(chart_colors[i]);
        } else {
          color.push(dynamicColors());
        }
      }
    }

    const dataByStatus = {
      labels: labelsUnit,
      datasets: [
        {
          data: efficiency,
          backgroundColor: color,
        },
      ],
    };
    const ctxCategoryOverview = <HTMLCanvasElement>document.getElementById('categoryOverview');
    const categoryOverview = new Chart(ctxCategoryOverview, {
      type: 'doughnut',
      data: dataByStatus,
      options: {
        cutoutPercentage: 90,
        legend: { display: false },
        legendCallback: function (chart) {
          const legendHtml = [];
          legendHtml.push('<ul>');
          const item = chart.data.datasets[0];
          for (let i = 0; i < 6; i++) {
            if (chart.data.labels[i] !== undefined && chart.data.labels[i] !== '') {
              legendHtml.push('<li class="clearfix">');
              legendHtml.push('<div class="chart-legend-label pull-left">');
              legendHtml.push('<p class="chart-legend-color pull-left" style="background-color:' + item.backgroundColor[i] + '"></p>');
              legendHtml.push('<p class="chart-legend-label-text">' + chart.data.labels[i] + '</p>');
              legendHtml.push('</div>');
              legendHtml.push('</li>');
            }
          }

          legendHtml.push('</ul>');
          return legendHtml.join('');
        },
      },
    });

    document.getElementById('categoryOverviewLegend').innerHTML = categoryOverview.generateLegend() as string;
  }

  chartCategoryOverviewModal(chartData) {
    const color = [];
    const labelsUnit = [];
    const efficiency = [];
    const chart_colors = this.commonService.getChartColors();

    const dynamicColors = function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    };

    for (let i = 0; i < chartData.labels.length; i++) {
      if (chartData.labels[i] !== undefined && chartData.labels[i] !== '') {
        labelsUnit.push(chartData.labels[i]);
        efficiency.push(chartData.values[i]);
        if (i < chart_colors.length) {
          color.push(chart_colors[i]);
        } else {
          color.push(dynamicColors());
        }
      }
    }

    const dataByStatus = {
      labels: labelsUnit,
      datasets: [
        {
          data: efficiency,
          backgroundColor: color,
        },
      ],
    };
    const ctxCategoryOverview = <HTMLCanvasElement>document.getElementById('categoryOverviewModal');
    const categoryOverview = new Chart(ctxCategoryOverview, {
      type: 'doughnut',
      data: dataByStatus,
      options: {
        cutoutPercentage: 90,
        legend: { display: false },
        legendCallback: function (chart) {
          const legendHtml = [];
          legendHtml.push('<ul>');
          const item = chart.data.datasets[0];
          for (let i = 0; i < item.data.length; i++) {
            legendHtml.push('<li class="clearfix">');
            legendHtml.push('<div class="chart-legend-label pull-left">');
            legendHtml.push('<p class="chart-legend-color pull-left" style="background-color:' + item.backgroundColor[i] + '"></p>');
            legendHtml.push('<p class="chart-legend-label-text">' + chart.data.labels[i] + '</p>');
            legendHtml.push('</div>');
            legendHtml.push('</li>');
          }
          legendHtml.push('</ul>');
          return legendHtml.join('');
        },
      },
    });

    document.getElementById('categoryOverviewLegendModal').innerHTML = categoryOverview.generateLegend() as string;
  }

  getTopUsedSolutions() {
    this.subscriptiongetTopFiveSolutions = this.solutionService.getTopFiveSolutions().subscribe((response) => {
      const result = response.result;
      if (result) {
        const labels = [];
        const data = [];
        for (let i = 0; i < result.length; i++) {
          labels.push(result[i].name);
          data.push(result[i].count);
        }
        this.chartSolutionOverview(labels, data);
        this.topUsedSolutionChartLoader = false;
      }
    });
  }

  chartSolutionOverview(labels: string[], data: any) {
    const color = [];
    const labelsUnit = [];
    const efficiency = [];
    const chart_colors = this.commonService.getChartColors();

    const dynamicColors = function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    };

    for (let i = 0; i < labels.length; i++) {
      if (labels[i] !== undefined && labels[i] !== '') {
        labelsUnit.push(labels[i]);
        efficiency.push(data[i]);
        if (i < chart_colors.length) {
          color.push(chart_colors[i]);
        } else {
          color.push(dynamicColors());
        }
      }
    }

    const data2 = {
      labels: labelsUnit,
      datasets: [
        {
          label: '',
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          data: efficiency,
        },
      ],
    };

    const ctx2 = <HTMLCanvasElement>document.getElementById('barChartSolutions');
    // tslint:disable-next-line: no-unused-expression
    new Chart(ctx2, {
      type: 'bar',
      data: data2,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
              ticks: {
                autoSkip: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            fontSize: 0,
            boxWidth: 0,
          },
        },
      },
    });
  }

  releaseToAllUsers(solutionId: number) {
    const payload = {
      account_solution_id: solutionId,
    };
    this.subscriptionreleaseToAllUsers = this.solutionService.releaseToAllUsers(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.getSolutions();
      }
    });
  }

  deleteSolution(solutionId: number) {
    this.subscriptiondeleteSolution = this.solutionService.deleteSolution(solutionId).subscribe((response) => {
      if (response.result) {
        this.notification.success(response.result, false);
        this.getSolutions();
      }
    });
  }

  releaseToPartners(solutionId: number) {
    const modalReference = this.modalService.open(ReleaseToPartnersComponent, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    modalReference.componentInstance.solutionId = solutionId;
    modalReference.result.then(
      () => {},
      () => {}
    );
  }

  removeFromPartnersModel(content: any, solutionId: number) {
    this.removePartnermodalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.removesolutionId = solutionId;
  }

  removeFromPartners() {
    const payload = {
      account_solution_id: this.removesolutionId,
      release_type_id: 1,
    };

    this.subscriptionrealeaseToPartners = this.solutionService.realeaseToPartners(payload).subscribe((response) => {
      if (response.result) {
        this.notification.success('Partner Removed Successfully', false);
        this.solutionService.readSolution.next('updateSolution');
        this.removePartnermodalReference.close();
      }
    });
  }

  getReleaseTypes() {
    this.subscriptiongetReleaseTypes = this.factsService.getReleaseTypes().subscribe((response) => {
      if (response.result) {
        this.releaseTypes = response.result.releaseTypes;
      }
    });
  }

  setRowLimit(num: number) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }
}
