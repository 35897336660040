
<div>
    <p-chart type="line" [data]="data" [options]="options"></p-chart>
</div>


<div class="models_page_header_container_small" *ngIf="toggleLogPoint" >
    <div class='keywest_header' style="padding: 7px; margin-left: 5px">
        <div class='row'>
            <div class='col-xs-10'>
                <div class='title'>Add a Note</div>
            </div>
    
            <div class="col-xs-2">
                <i class="fa fa-times pull-right" (click)="toggleLogPoint = false"></i>
            </div>
        </div>
    </div>
    
    
    <div class="row" style="padding: 14px;">
        <div class="col-xs-12">
    
            <div class="row">
                <div style="padding: 14px;">
                    <span>{{logPointData.label}}: {{logPointData.data}}</span>
    
                    
                </div>
            </div>
        
            <div class="col-xs-12">
                <textarea class="keywest_textarea form-control" [(ngModel)]="dataLogPoint" matTooltip="Press enter to save note" matTooltipPosition="above" placeholder="make a note" (keydown.enter)="saveNote(dataLogPoint)"></textarea>
            </div>
        </div>
    
    </div>
</div>
