import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './top.translation';

@Component({
  selector: 'app-panel-top',
  templateUrl: './top.component.html'
})
export class DashboardTopComponent implements OnInit, OnDestroy {
  @Input() showTranslate: boolean;
  @Input() showHelp: boolean;
  @Input() isDev: boolean;
  @Input() valueProp: ValueProp;
  @Input() contextualHelp: any[];
  @Input() dashboard: string;
  @Input() year1BreakDownChartData: {[klass: string]: any};
  @Input() costBenefitChartData: {[klass: string]: any};
  @Input() viewTop = 'roi';

  menuTop: MenuItem[];
  tcoFeature = false;
  competitiveTco = false;
  showCharts = true;

  viewTopTitle = 'Key Figures & ROI';
  ngUnsubscribe$: Subject<any> = new Subject();

  feature62 = false;

  constructor(
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.feature62 = this.commonService.checkFeature('62');
    this.competitiveTco = this.commonService.checkFeature('75');

    if (localStorage.getItem('showCharts') == null) {
      localStorage.setItem('showCharts', this.showCharts.toString());
    }

    this.showCharts = JSON.parse(localStorage.getItem('showCharts'));

    if (localStorage.getItem('vpdashboardMenuTop') == null) {
      localStorage.setItem('vpdashboardMenuTop', 'roi');
    }

    this.viewTop = localStorage.getItem('vpdashboardMenuTop');

    if (localStorage.getItem('vpdashboardMenuTopTitle') == null) {
      localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.roi_full);
    }

    this.viewTopTitle = localStorage.getItem('vpdashboardMenuTopTitle');

    if (this.commonService.checkFeature('10')) {
      this.tcoFeature = true;
    }

    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.funcBuildMenu();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
       this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      this.funcBuildMenu();
    });
  }

  funcBuildMenu(): void {
    this.menuTop = [];

    if (this.dashboard === 'v1') {
      this.menuTop.push({
        label: (this.feature62 ? this.trans.trans.roi.value : 'Key Figures'),
        styleClass: this.viewTop === 'roi' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'roi';
          this.viewTopTitle = this.trans.trans.key_figures_and_roi.value;
          localStorage.setItem('vpdashboardMenuTop', 'roi');
          localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.roi_full.value);
          this.funcBuildMenu();
        }
      });
    }
    // tslint:disable-next-line: one-line
    else if (this.dashboard === 'v2') {
      this.menuTop.push({
        label: (this.feature62 ? this.trans.trans.key_figures_and_roi.value : 'Key Figures'),
        styleClass: this.viewTop === 'roi' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'roi';
          this.viewTopTitle = this.trans.trans.key_figures_and_roi.value;
          localStorage.setItem('vpdashboardMenuTop', 'roi');
          localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.roi_full.value);
          this.funcBuildMenu();
        }
      });
    }

    this.menuTop.push({
      label: this.trans.trans.cashflow.value,
      styleClass: this.viewTop === 'cashflow' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'cashflow';
        this.viewTopTitle = this.trans.trans.cashflow.value;
        localStorage.setItem('vpdashboardMenuTop', 'cashflow');
        localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.cashflow.value);
        this.funcBuildMenu();
      }
    });

    if (this.tcoFeature) {
      this.menuTop.push({
        label: this.trans.trans.tco.value,
        styleClass: this.viewTop === 'tco' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'tco';
          this.viewTopTitle = this.trans.trans.tco.value;
          localStorage.setItem('vpdashboardMenuTop', 'tco');
          localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.tco.value);
          this.funcBuildMenu();
        }
      });
    }

    if (this.competitiveTco) {
      this.menuTop.push({
        label: 'Cost Evaluation',
        styleClass: this.viewTop === 'competitive-tco' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'competitive-tco';
          this.viewTopTitle = 'Cost Evaluation';
          localStorage.setItem('vpdashboardMenuTop', 'competitive-tco');
          localStorage.setItem('vpdashboardMenuTopTitle', 'Cost Evaluation');
          this.funcBuildMenu();
        }
      });
    }

    this.menuTop.push({
      label: this.trans.trans.notes.value, styleClass:
        this.viewTop === 'notes' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'notes';
        this.viewTopTitle = this.trans.trans.notes.value;
        localStorage.setItem('vpdashboardMenuTop', 'notes');
        localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.notes.value);
        this.funcBuildMenu();
      }
    });

    this.menuTop.push({
      label: this.trans.trans.logs.value,
      styleClass: this.viewTop === 'log' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'log';
        this.viewTopTitle = this.trans.trans.logs.value;
        localStorage.setItem('vpdashboardMenuTop', 'logs');
        localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.logs.value);
        this.funcBuildMenu();
      }
    });

    this.menuTop.push({
      label: this.trans.trans.scorecard.value,
      styleClass: this.viewTop === 'scorecard' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'scorecard';
        this.viewTopTitle = this.trans.trans.scorecard.value;
        localStorage.setItem('vpdashboardMenuTop', 'scorecard');
        localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.scorecard.value);
        this.funcBuildMenu();
      }
    });

    if (this.valueProp.vp_can_edit) {
      this.menuTop.push({
        label: this.trans.trans.share.value,
        styleClass: this.viewTop === 'share' ? 'menuSelected' : '',
        icon: 'prop-icon icon-share',
        command: () => {
          this.viewTop = 'share';
          this.viewTopTitle = this.trans.trans.share.value;
          localStorage.setItem('vpdashboardMenuTop', 'share');
          localStorage.setItem('vpdashboardMenuTopTitle', this.trans.trans.share.value);
          this.funcBuildMenu();
        }
      });
    }
  }

  funcShowCharts(): void {
    this.showCharts = !this.showCharts;
    localStorage.setItem('showCharts', this.showCharts.toString());
  }

}
