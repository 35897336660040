<form name="addCompanyForm" #addCompanyForm="ngForm" (ngSubmit)="addCompany()">
	<div class="sidebar_wrapper">
		<div class='sidebar_header'>
			{{ trans.trans.add_a_company.value }}
		</div>
		<div class='sidebar_container'>
			<div *ngIf="!hasFeature115; else simplifiedForm" class='keywest_panel'>

				<p-fieldset id='required' legend="Required">
					<div class="row">
						<div class="col-xs-12">
							<label class="labelsRequired">{{ trans.trans.name.value }}</label>
							<input type="text" class="form-control" id="name" [(ngModel)]="company.name" name="name" placeholder="" required>
							<i class="fa fa-search name-search" matTooltip="Add New" matTooltipPosition="above" (click)="searchWithScraper()"></i>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label class="labelsRequired">{{ trans.trans.industry_sector.value }}</label>
							<select (change)="getIndustryBySectors(company.industry_sector_id)" class="form-control" name="industryCategory" required [(ngModel)]="company.industry_sector_id">
								<option value="" disabled="disabled">{{ trans.trans.select_industry_sector.value }}</option>
								<option *ngFor="let sector of industrySectors" id="{{sector.id}}" [ngValue]="sector.id">
									{{ sector.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label class="labelsRequired">
								{{ trans.trans.industry.value }}
							</label>
							<img class="industryloader loader" [src]="fullImagePath" *ngIf="industriesLoader" />

							<select [disabled]="industriesShow" class="form-control" name="industry" required [(ngModel)]="company.industry_id">
								<option value="" disabled="disabled">{{ trans.trans.select_industry.value }}</option>
								<option *ngFor="let industry of industries" id="{{industry.id}}" [ngValue]="industry.id">
									{{ industry.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label class="labelsRequired">{{ trans.trans.country.value }}</label><br>
							<p-dropdown appendTo="body" [filter]="true" [options]="countries" name='country' [(ngModel)]="countryDetails" (onChange)='getStatesByCountries(countryDetails)' optionLabel="name" class='keywest_dropdown'></p-dropdown>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label class="labelsRequired">{{ trans.trans.revenue_in_millions.value }}</label>
							<input type="number" class="form-control" id="revenue" [(ngModel)]="company.net_sales" name="revenue" placeholder="" required>
						</div>
					</div>
				</p-fieldset>
				<p-fieldset [toggleable]="true" [collapsed]='true' [legend]='trans.trans.additionalInformation.value'>

					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.employees.value }}</label>
							<input type="number" class="form-control" id="employees" [(ngModel)]="company.employees" name="employees" placeholder="">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.address.value }}</label>
							<input type="text" class="form-control" id="address" [(ngModel)]="company.address1" name="address" placeholder="">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.city.value }}</label>
							<input type="text" class="form-control" id="city" [(ngModel)]="company.city" name="city" placeholder="">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label for="name">{{ trans.trans.state.value }}</label>
							<select [disabled]="statesLoader" class="form-control" name="state" [(ngModel)]="company.state">
								<option value="" disabled="disabled">{{ trans.trans.select_state.value }}</option>
								<option *ngFor="let state of states" [id]="state.id" [ngValue]="state.abbreviation">
									{{ state.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.postal_code.value }}</label>
							<input type="text" class="form-control" id="zipcode" [(ngModel)]="company.zip" name="zipcode" placeholder="">
						</div>
					</div>
				</p-fieldset>
			</div>

			<ng-template #simplifiedForm>
				<p-fieldset id='required' legend="Required">
					<div class="row">
						<div class="col-xs-12">
							<label class="labelsRequired">{{ trans.trans.name.value }}</label>
							<input type="text" class="form-control" id="name" [(ngModel)]="company.name" name="name" placeholder="" required>
							<i class="fa fa-search name-search" matTooltip="Add New" matTooltipPosition="above" (click)="searchWithScraper()"></i>
						</div>
					</div>
				</p-fieldset>
				<p-fieldset [toggleable]="true" [collapsed]='true' [legend]='trans.trans.additionalInformation.value'>
					<div class="row">
						<div class="col-xs-12">
							<label class="labels">{{ trans.trans.industry_sector.value }}</label>
							<select (change)="getIndustryBySectors(company.industry_sector_id)" class="form-control" name="industryCategory" [(ngModel)]="company.industry_sector_id">
								<option value="" disabled="disabled">{{ trans.trans.select_industry_sector.value }}</option>
								<option *ngFor="let sector of industrySectors" [id]="sector.id" [ngValue]="sector.id">
									{{ sector.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label class="labels">
								{{ trans.trans.industry.value }}
							</label>
							<img class="industryloader loader" [src]="fullImagePath" *ngIf="industriesLoader" />

							<select [disabled]="industriesShow" class="form-control" name="industry" [(ngModel)]="company.industry_id">
								<option value="" disabled="disabled">{{ trans.trans.select_industry.value }}</option>
								<option *ngFor="let industry of industries" [id]="industry.id" [ngValue]="industry.id">
									{{ industry.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label class="labels">{{ trans.trans.country.value }}</label><br>

							<p-dropdown appendTo="body" [filter]="true" [options]="countries" name='country' [(ngModel)]="countryDetails" (onChange)='getStatesByCountries(countryDetails)' optionLabel="name" class='keywest_dropdown'></p-dropdown>

						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label class="labels">{{ trans.trans.revenue_in_millions.value }}</label>
							<input type="number" class="form-control" id="revenue" [(ngModel)]="company.net_sales" name="revenue" placeholder="">
						</div>
					</div>

					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.employees.value }}</label>
							<input type="number" class="form-control" id="employees" [(ngModel)]="company.employees" name="employees" placeholder="">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.address.value }}</label>
							<input type="text" class="form-control" id="address" [(ngModel)]="company.address1" name="address" placeholder="">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.city.value }}</label>
							<input type="text" class="form-control" id="city" [(ngModel)]="company.city" name="city" placeholder="">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label for="name">{{ trans.trans.state.value }}</label>
							<select [disabled]="statesLoader" class="form-control" name="state" [(ngModel)]="company.state">
								<option value="" disabled="disabled">{{ trans.trans.select_state.value }}</option>
								<option *ngFor="let state of states" [id]="state.id" [ngValue]="state.abbreviation">
									{{ state.name }}
								</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label>{{ trans.trans.postal_code.value }}</label>
							<input type="text" class="form-control" id="zipcode" [(ngModel)]="company.zip" name="zipcode" placeholder="">
						</div>
					</div>
				</p-fieldset>
			</ng-template>
		</div>
		<div class='btn_container'>
			<div class='col-xs-12'>
				<button type="submit" *ngIf="!addCompanyLoader" [disabled]="addCompanyForm.invalid" class='btn btn_customizable pull-right' (click)="addCompany()">
					{{ trans.trans.save.value }}
				</button>
				<app-are-you-sure
					[ngClass]="{
						'btn cancel-confirm': !(style2022$ | async),
						'btn-light': style2022$ | async
					}"
					matTooltipPosition="above"
					*ngIf="!addCompanyLoader"
					[message]="trans.trans.are_you_sure.value"
					class="btn pull-right"
					icon=""
					[text]="trans.trans.back.value"
					(callbackYes)="cancel()"
				></app-are-you-sure>
				<img *ngIf="addCompanyLoader" class="loader" [src]="fullImagePath" />
			</div>
		</div>

	</div>

</form>