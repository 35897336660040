<div class="row" style='padding-top: 100px;'>
    <div class="col-md-8 center">
        <div class="panel panel-white login-panel" id="js-alerts">
            <div class="panel-body">
                <alert></alert>
                <div class="login-box">
                    <div class="text-center"><img src="@assets/images/xDLIcon.png"
                            alt="Value Execution by Xfactor.io" style="max-height: 40px;"></div>
                    <div *ngIf="!isForgotPassword">
                        <p class="text-center m-t-md">Connect this application to Value Execution by Xfactor.io.</p>
                        <form class="m-t-md" name="LoginForm" #loginForm="ngForm" (ngSubmit)="ngOnSubmit()">
                            <div *ngIf="user_details">
                                <div class="col-md-6 center"><img
                                        src="https://media.value-cloud.com/images/default_user.png" alt="user image">
                                </div>
                                <div class="text-center">
                                    <div style="overflow-wrap: break-word">{{user_details.email}}</div>
                                    <div>{{user_details.first_name}} {{user_details.last_name}}</div>
                                </div>
                            </div>
                            <!-- <div *ngIf="no-bueno">
                                <div>You are not licensed to use Value Execution by Xfactor.io.. Please contact your administrator.</div>
                            </div> -->
                            <button *ngIf="user_details" type="submit"
                                class="btn btn-success btn-block login-submit">Authorize</button>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                    <div class="forgot-password" *ngIf="isForgotPassword">
                        <!-- <p class="forgot_pwd_tit">
                            {{ trans.login.forgot_your_password_enter_your_email_address | translate}}</p> -->
                        <form class="m-t-md" name="LoginForm" #loginForm="ngForm" (ngSubmit)="ngOnSubmit()">
                            <div class="form-group">
                                <input type="email" class="form-control" name="loginUsername" id="forgotUsername"
                                    placeholder="{{ trans.general.email_address | translate}}" required=""
                                    aria-invalid="true" ngModel>
                            </div>
                            <button type="submit" class="btn btn-success btn-block reset-pwd-btn"
                                (click)="isForgotPassword=false">{{ trans.login.reset_password | translate}}</button>
                            <div class="m-t-md login-btn-grp">
                                <button class="display-block text-center text-sm pull-left login-btn"
                                    (click)="isForgotPassword=false">{{ trans.login.login | translate}} </button>
                                <span class="pull-left forgot-register-pipe"> | </span>
                                <button class="display-block text-center text-sm register-link-btn pull-left"
                                    [disabled]="true"> {{ trans.login.register | translate}}</button>
                            </div>
                            <div class="clearfix"></div>
                        </form>
                    </div>

                    <p class="text-center m-t-xs text-sm">2011 - {{currYear}} ©
                        {{ trans.login.decisionLink_corporation | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>