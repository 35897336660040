import { Injectable } from '@angular/core';

@Injectable()
export class ValuePositionDashboardTranslations {
  public config: any = {
    component: 'valuePositionsDash',
    display_name: 'Value Positions Dashboard',
    description: 'Value positions list in main dashboard',
  };

  public trans: any = {
    valuepos_name: 'Name',
    valuepos_totalbenefits: 'Total Benefits',
    valuepos_seemore: 'See More',
    valuepos_noValuePositions: 'No Value Hypotheses Found',
    valueprop_valuepropname: 'Business Case / Value Assessment Name',
    valueprop_models: 'Model(s)',
    valueprop_year1benfits: 'Year 1 Benefits',
    valueprop_totalBenefits: 'Total Benefits',
    valueprop_ROI: 'ROI',
    valueprop_paybackPeriod: 'Payback Period',
    valueprop_creatorName: 'Creator Name',
    valuePositionsHeading: 'Recent Value Hypotheses',
    add: 'Add',
    vp_name: 'Value Prop Name',
    models: 'Model(s)',
    year1: 'Year 1 Benefits',
    roi: 'ROI',
    payback: 'Payback Period',
    months: 'months',
  };
}
