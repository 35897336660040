<div class="col-xs-12 card-wrapper">
    <!-- Title -->
    <div class="row">
        <div class="col-xs-12 mxy-10">
            <span class="light-h">{{trans.trans.myPrivileges.value}}</span>
        </div>
    </div>
    <!-- privileges -->
    <div class="privilege-wrapper">
        <span *ngFor="let privilege of privileges" class="privilege dark-t privileges-bg">{{privilege.name}}</span>
    </div>

</div>