import { Component, OnInit, ViewChild, OnDestroy, Input, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import * as Chart from 'chart.js';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { ReleaseToPartnersComponent } from './release-to-partners/release-to-partners.component';
import { FactsService } from '@data/services/facts/facts.service';
import { AlertService } from '../../_services/alert.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { AccountService } from '@data/services/account/account.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './solution-list.translation';
import { Solution } from '@shared/models/value-prop.model';
import { ModelCategory } from '@data/services/account/models/model-category.interface';
import { Table } from 'primeng/table';
import { ColumnMeta } from '@shared/models/common.models';
import { Model } from '@data/services/account/models/model.interface';
import { MenuItem } from 'primeng/api';
import { RowLimit } from 'app/_models/row-limit.model';
import { Owner } from '@shared/models/owner.model';

@Component({
  selector: 'app-solution-list',
  templateUrl: './solution-list.component.html',
  styleUrls: ['./solution-list.component.scss'],
})
export class SolutionListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() can_edit = false;

  companiesNoResult = false;
  image_url: string;
  fullImagePath: string;
  solutions: Solution[] = [];
  solutionCategoryList: ModelCategory[] = [];
  solutionCategoryChart: Array<{ category: string; count: string }> = [];
  solutionsCategoryLoader: boolean;
  solutionChartLoader: boolean;
  solutionId: string;
  modalReference: NgbModalRef;
  solutionName: string;
  hideActionLoader = true;
  isSolutionAdmin = false;
  hidecategoryName: boolean;
  modalChartData: number;
  topUsedSolutionChartLoader = true;
  releaseTypes: Array<{ id: string; name: string }> = [];
  removePartnermodalReference: NgbModalRef;
  removesolutionId = '';
  selectedSolution: Solution;
  categoryModal = false;
  labelCount: number | string;
  contextualHelp: unknown = {};
  selectedModel: Model;
  userList: Array<{ id: string }> = [];
  selectedUser: { id: string };

  @ViewChild('tableSolutionList', { static: false }) tableSolutionList: Table;
  toggleModelShare = false;
  sidebar_title = '';

  pageNumber: number;
  pageLimit: number;
  searchText: string;

  order = 'desc';
  sortBy = 'id';

  chartVC: Chart.ChartConfiguration;
  chartVCLegend: Array<{ val: string; color: string; name: string }> = [];

  view = 'models';
  items: RowLimit[] = [];

  @ViewChild('formAddEditModel', { static: false }) formAddEditModel: NgForm;
  @ViewChild('formAddEditShare', { static: false }) formAddEditShare: NgForm;
  ngUnsubscribe = new Subject();

  cols: ColumnMeta[] = [
    { field: 'name', header: 'Model Name', sortBy: 'name' },
    { field: 'releasedToUsers', header: 'Released to Users', sortBy: 'userReleaseStatusId' },
    { field: 'releasedToPartners', header: 'Released to Partners', sortBy: 'partnerReleaseStatusId' },
    { field: 'modelAdmins', header: 'Model Admins' },
  ];

  formObj: Partial<Model> = {
    name: '',
    description: '',
    avg_deployment_time: '',
    typical_deployment_type: '',
    quantity_enabled: '',
    avg_sale_price: '',
    avg_sale_cycle: '',
    solution_category_id: [],
    id: '',
    partnerTypeListSpecific: [],
    partnerTypeListGroup: [],
  };

  categoryList: Array<Partial<ModelCategory>> = [];
  mode = 'add';
  strSearch = '';

  toggleAddEditModel = false;
  toggleReleaseToPartners = false;

  barChartData: Chart.ChartConfiguration;
  header_color: string;
  dropdown: MenuItem[] = [];
  selectedSolutionId = '';
  releaseTypeId: string;
  partnerTypeListSpecific: Array<Owner> = [];
  partnerTypeListGroup: Array<{ name: string; id: string }> = [];
  @Input() filterList = '';
  account_id: string;

  frmSolutionObj: Partial<Model> = {
    name: '',
    description: '',
    avg_deployment_time: '',
    typical_deployment_type: '',
    quantity_enabled: '',
    avg_sale_price: '',
    avg_sale_cycle: '',
    id: '',
  };

  loading = true;
  showTranslate = false;
  menuPartners: Array<{ label: string }> = [];

  constructor(
    private solutionService: SolutionService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private notification: NotificationService,
    private factsService: FactsService,
    private alertService: AlertService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private accountService: AccountService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.hidecategoryName = false;

    this.funcDrawMenu();
    this.getSolutions();
    this.getTranslations();
    this.getPartnerTypesSpecific();
    this.getPartnerTypesGroup();
    this.getSolutionCategoryList();
    this.getSolutionCategoryChart();
    this.getTopUsedSolutions();
    this.getReleaseTypes();
    this.getSolutionCategories();
    this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();

    this.solutionService.readSolution.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getSolutions();
    });
    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.alertService
      .subscribeLoggedIn()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getSolutions();

        this.getSolutionCategoryList();
        this.getSolutionCategoryChart();
        this.hidecategoryName = false;
        this.getTopUsedSolutions();
        this.getReleaseTypes();

        this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();
      });

    this.solutionService.gotoAddModel.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.openAddEditModel();
    });

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes.filterList;
    if (currentItem && currentItem.currentValue) {
      this.tableSolutionList.filter(currentItem.currentValue, 'category_names', 'contains');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  closeReleaseToPartners(): void {
    this.toggleReleaseToPartners = false;
    this.formObj = {
      name: '',
      description: '',
      avg_deployment_time: '',
      typical_deployment_type: '',
      quantity_enabled: '',
      avg_sale_price: '',
      avg_sale_cycle: '',
      solution_category_id: [],
      id: '',
      partnerTypeListSpecific: [],
      partnerTypeListGroup: [],
    };
    this.releaseTypeId = '';
  }

  funcDrawMenu(): void {
    this.items = [
      {
        label: this.trans.trans.models,
        styleClass: this.view === 'models' ? 'menuSelected' : '',
        command: () => {
          this.view = 'models';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.model_categories,
        styleClass: this.view === 'model_categories' ? 'menuSelected' : '',
        command: () => {
          this.view = 'model_categories';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.value_categories,
        styleClass: this.view === 'value_category' ? 'menuSelected' : '',
        command: () => {
          this.view = 'value_category';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.situations,
        styleClass: this.view === 'situations' ? 'menuSelected' : '',
        command: () => {
          this.view = 'situations';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.pain_points,
        styleClass: this.view === 'pain_points' ? 'menuSelected' : '',
        command: () => {
          this.view = 'pain_points';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.capabilities,
        styleClass: this.view === 'capabilities' ? 'menuSelected' : '',
        command: () => {
          this.view = 'capabilities';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.operational_objectives,
        styleClass: this.view === 'operational_objectives' ? 'menuSelected' : '',
        command: () => {
          this.view = 'operational_objectives';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.functional_objectives,
        styleClass: this.view === 'functional_objectives' ? 'menuSelected' : '',
        command: () => {
          this.view = 'functional_objectives';
          this.funcDrawMenu();
        },
      },
    ];
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.funcDrawMenu();
      });
  }

  openAddEditModel(): void {
    this.mode = 'add';
    this.toggleAddEditModel = true;

    for (let k in this.frmSolutionObj) {
      this.frmSolutionObj[k] = null;
    }
  }

  funcBuildMenu(elem, menu, event): void {
    this.dropdown = [];
    this.dropdown.push({
      label: 'Edit',
      icon: 'fa fa-pencil',
      command: () => {
        this.mode = 'edit';
        this.sidebar_title = 'Edit Model';
        this.formAddEditModel.controls['name'].setValue(elem.name);
        this.formAddEditModel.controls['description'].setValue(elem.description);
        this.formAddEditModel.controls['avg_deployment_time'].setValue(elem.avg_deployment_time);
        this.formAddEditModel.controls['typical_deployment_type'].setValue(elem.typical_deployment_type);
        this.formAddEditModel.controls['quantity_enabled'].setValue(elem.quantity_enabled);
        this.formAddEditModel.controls['avg_sale_price'].setValue(elem.avg_sale_price);
        this.formAddEditModel.controls['avg_sale_cycle'].setValue(elem.avg_sale_cycle);
        this.formAddEditModel.controls['id'].setValue(elem.id);

        let selectedCategories = [];
        let list = elem.category.split(',');
        selectedCategories = this.categoryList.filter(function (array_el) {
          return (
            list.filter(function (anotherOne_el) {
              return anotherOne_el == array_el.category;
            }).length == 1
          );
        });

        this.formAddEditModel.controls['solution_category_id'].setValue(selectedCategories);
        this.toggleAddEditModel = true;
      },
    });
    this.dropdown.push({
      label: 'Release to all users',
      icon: 'fa fa-save',
      command: () => {
        this.releaseToAllUsers(elem.id);
      },
    });

    this.dropdown.push({
      label: 'Release to all partners',
      icon: 'fa fa-save',
      command: () => {
        this.selectedSolutionId = elem.id;
        this.toggleReleaseToPartners = true;
      },
    });

    if (elem.partnerReleaseStatusId != 1 && elem.share_role_type_id != 2) {
      this.dropdown.push({
        label: 'Remove from parters models',
        icon: 'fa fa-times',
        command: () => {
          this.removeFromPartnersModel(null, elem.id);
        },
      });
    }
    this.dropdown.push({
      label: 'Delete',
      icon: 'fa fa-times',
      command: () => {
        this.deleteSolution(elem.id);
      },
    });

    this.dropdown.push({
      label: 'Assign model admin',
      icon: 'fa fa-save',
      command: () => {
        this.solutionService
          .getShares(this.account_id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            this.userList = response.result;
            this.selectedModel = elem;
            this.toggleModelShare = true;
          });
      },
    });

    menu.toggle(event);
  }

  funcToggleView(elem): void {
    this.frmSolutionObj = Object.assign({}, elem);
    this.toggleAddEditModel = true;
  }

  funcToggleEdit(elem): void {
    this.mode = 'edit';
    this.sidebar_title = 'Edit Model';
    this.toggleAddEditModel = true;
    this.frmSolutionObj = Object.assign({}, elem);
  }

  releaseToPartners(solutionId): void {
    const modalReference = this.modalService.open(ReleaseToPartnersComponent, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    modalReference.componentInstance.solutionId = solutionId;
    modalReference.result.then(
      () => {},
      () => {}
    );
  }

  funcCloneModel(elem): void {
    elem.cloneInProgress = true;
    this.solutionService
      .cloneModel(elem.id, {})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getSolutions();
        elem.cloneInProgress = false;
        this.notification.success('Clone Successful', false);
      });
  }

  funcAddExistingUser(): void {
    let payload = {
      account_solution_id: this.selectedModel['id'],
      share_role_type_id: 3,
      share_to_user_id: this.selectedUser.id,
    };

    this.solutionService.postCreateShare(payload).subscribe(() => {
      this.toggleModelShare = false;
      this.getSolutions();
      this.notification.success('Share added successfully', false);
    });
  }

  getSolutions(): void {
    this.loading = true;
    this.pageLimit = 10;
    this.pageNumber = 1;

    let searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc?exclude_shared=true';
    this.solutionService.getAvailableSolutionsFull(searchLimit).subscribe((response) => {
      if (response.result) {
        this.solutions = response.result.data.sort((modA, modB) => modA.sequence - modB.sequence);
      }
      this.loading = false;
    });
  }

  funcMenuPartners(solution): void {
    if (solution.partners) {
      this.menuPartners = [];
      if (solution.partnerReleaseStatusId == 4) {
        solution.partners.forEach((elem) => {
          this.menuPartners.push({ label: elem.partner_type });
        });
      }

      if (solution.partnerReleaseStatusId == 3) {
        solution.partners.forEach((elem) => {
          this.menuPartners.push({ label: elem.account_name });
        });
      }
    }
  }

  getSolutionCategories(): void {
    this.solutionService.getSolutionCategoryList().subscribe((res) => {
      this.categoryList = res.result;
    });
  }

  insertSolution(form: NgForm): void {
    let selectedCategories = [];
    let categories = form.controls.solution_category_id.value;
    for (let i = 0; i < categories.length; i++) {
      selectedCategories.push(categories[i].id);
    }
    let payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
      quantity_enabled: 0,
      avg_deployment_time: form.controls.avg_deployment_time.value,
      typical_deployment_type: 0,
      avg_sale_price: form.controls.avg_sale_price.value,
      avg_sale_cycle: form.controls.avg_sale_cycle.value,
      solution_category_id: selectedCategories,
    };

    this.solutionService.createSolutionModel(payload).subscribe(() => {
      this.notification.success('Solution created successfully', false);
      this.toggleAddEditModel = false;
      this.getSolutions();
    });
  }

  updateSolution(form: NgForm): void {
    const selectedCategories = [];
    const categories = form.controls.solution_category_id.value;
    for (let i = 0; i < categories.length; i++) {
      selectedCategories.push(categories[i].id);
    }

    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
      avg_deployment_time: form.controls.avg_deployment_time.value,
      typical_deployment_type: form.controls.typical_deployment_type.value,
      quantity_enabled: form.controls.quantity_enabled.value,
      avg_sale_price: form.controls.avg_sale_price.value,
      avg_sale_cycle: form.controls.avg_sale_cycle.value,
      solution_category_id: selectedCategories,
    };

    this.solutionService.updateSolutionDetail(form.controls.id.value, payload).subscribe((response) => {
      if (response.result) {
        this.getSolutions();
        this.notification.success('Solution updated successfully', false);
        this.toggleAddEditModel = false;
      }
    });
  }

  getSolutionCategoryList(): void {
    this.solutionsCategoryLoader = true;
    this.solutionService.getSolutionCategoryList().subscribe((response) => {
      if (response.result) {
        this.solutionCategoryList = response.result;
      }
      this.solutionsCategoryLoader = false;
    });
  }
  getSolutionCategoryChart(): void {
    this.solutionChartLoader = true;
    const chartData = { labels: [], values: [] };
    this.solutionService.getSolutionCategoryChart().subscribe((response) => {
      if (response.result) {
        const solutionCategoryChart = response.result;
        if (response && response.result) {
          for (let i = 0; i < solutionCategoryChart.length; i++) {
            chartData.labels.push(solutionCategoryChart[i].category);
            chartData.values.push(solutionCategoryChart[i].count);
          }
        }

        this.funcGetVCChart(chartData);
        if (this.modalChartData == 4) {
          this.chartCategoryOverviewModal(chartData);
        }
      }

      this.solutionChartLoader = false;
    });
  }

  chartCategoryOverview(chartData): void {
    const color = [];
    const labelsUnit = [];
    const efficiency = [];
    const chart_colors = this.commonService.getChartColors();

    const dynamicColors = function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    };
    const chartLabel = chartData.labels.filter((item) => {
      return item !== '';
    });
    this.labelCount = chartLabel.length;
    if (this.labelCount > 6) {
      this.categoryModal = true;
      this.labelCount = +this.labelCount - 6;
    }
    for (let i = 0; i < chartData.labels.length; i++) {
      if (chartData.labels[i] != undefined && chartData.labels[i] != '') {
        labelsUnit.push(chartData.labels[i]);
        efficiency.push(chartData.values[i]);

        if (i < chart_colors.length) {
          color.push(chart_colors[i]);
        } else {
          color.push(dynamicColors());
        }
      }
    }

    const dataByStatus = {
      labels: labelsUnit,
      datasets: [
        {
          data: efficiency,
          backgroundColor: color,
        },
      ],
    };
    const ctxCategoryOverview = <HTMLCanvasElement>document.getElementById('categoryOverview');
    const categoryOverview = new Chart(ctxCategoryOverview, {
      type: 'doughnut',
      data: dataByStatus,
      options: {
        cutoutPercentage: 90,
        legend: {
          display: false,
        },
        legendCallback: function (chart) {
          const legendHtml = [];
          legendHtml.push('<ul>');
          const item = chart.data.datasets[0];
          for (let i = 0; i < 6; i++) {
            if (chart.data.labels[i] != undefined && chart.data.labels[i] != '') {
              legendHtml.push('<li class="clearfix">');
              legendHtml.push('<div class="chart-legend-label pull-left">');
              legendHtml.push('<p class="chart-legend-color pull-left" style="background-color:' + item.backgroundColor[i] + '"></p>');
              legendHtml.push('<p class="chart-legend-label-text">' + chart.data.labels[i] + '</p>');
              legendHtml.push('</div>');
              legendHtml.push('</li>');
            }
          }

          legendHtml.push('</ul>');
          return legendHtml.join('');
        },
      },
    });

    document.getElementById('categoryOverviewLegend').innerHTML = categoryOverview.generateLegend() as string;
  }

  funcGetVCChart(chartData): void {
    const chartColors = this.commonService.getChartColors();
    const labels = [];
    for (let i = 0; i < chartData.labels.length; i++) {
      labels.push({
        name: chartData.labels[i],
        color: chartColors[i],
        val: chartData.values[i],
      });
    }

    this.chartVC = {
      data: {
        datasets: [
          {
            label: 'Benefits',
            data: chartData.values,
            backgroundColor: chartColors,
          },
        ],
        labels: labels,
      },
    };

    this.chartVCLegend = labels;
  }

  chartCategoryOverviewModal(chartData): void {
    const color = [];
    const labelsUnit = [];
    const efficiency = [];
    const chart_colors = this.commonService.getChartColors();

    const dynamicColors = function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    };

    for (let i = 0; i < chartData.labels.length; i++) {
      if (chartData.labels[i] != undefined && chartData.labels[i] != '') {
        labelsUnit.push(chartData.labels[i]);
        efficiency.push(chartData.values[i]);
        if (i < chart_colors.length) {
          color.push(chart_colors[i]);
        } else {
          color.push(dynamicColors());
        }
      }
    }

    const dataByStatus = {
      labels: labelsUnit,
      datasets: [
        {
          data: efficiency,
          backgroundColor: color,
        },
      ],
    };
    const ctxCategoryOverview = <HTMLCanvasElement>document.getElementById('categoryOverviewModal');
    const categoryOverview = new Chart(ctxCategoryOverview, {
      type: 'doughnut',
      data: dataByStatus,
      options: {
        cutoutPercentage: 90,
        legend: { display: false },
        legendCallback: function (chart) {
          const legendHtml = [];
          legendHtml.push('<ul>');
          const item = chart.data.datasets[0];
          for (let i = 0; i < item.data.length; i++) {
            legendHtml.push('<li class="clearfix">');
            legendHtml.push('<div class="chart-legend-label pull-left">');
            legendHtml.push('<p class="chart-legend-color pull-left" style="background-color:' + item.backgroundColor[i] + '"></p>');
            legendHtml.push('<p class="chart-legend-label-text">' + chart.data.labels[i] + '</p>');
            legendHtml.push('</div>');
            legendHtml.push('</li>');
          }
          legendHtml.push('</ul>');
          return legendHtml.join('');
        },
      },
    });

    document.getElementById('categoryOverviewLegendModal').innerHTML = categoryOverview.generateLegend() as string;
  }

  getTopUsedSolutions() {
    this.solutionService.getTopFiveSolutions().subscribe((response) => {
      const result = response.result;
      if (result) {
        const labels = [];
        const data = [];
        for (let i = 0; i < result.length; i++) {
          labels.push(result[i].name);
          data.push(result[i].count);
        }
        this.chartSolutionOverview(labels, data);
        this.topUsedSolutionChartLoader = false;
      }
    });
  }

  chartSolutionOverview(labels, data): void {
    const color = [];
    const labelsUnit = [];
    const efficiency = [];
    const chart_colors = this.commonService.getChartColors();

    const dynamicColors = function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return 'rgb(' + r + ',' + g + ',' + b + ')';
    };

    for (let i = 0; i < labels.length; i++) {
      if (labels[i] != undefined && labels[i] != '') {
        labelsUnit.push(labels[i]);
        efficiency.push(data[i]);
        if (i < chart_colors.length) {
          color.push(chart_colors[i]);
        } else {
          color.push(dynamicColors());
        }
      }
    }

    this.barChartData = {
      data: {
        datasets: [
          {
            data: efficiency,
            backgroundColor: color,
          },
        ],
        labels: labelsUnit,
      },
    };
  }

  releaseToAllUsers(solutionId): void {
    const payload = {
      account_solution_id: solutionId,
    };

    this.solutionService.releaseToAllUsers(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.getSolutions();
      }
    });
  }

  deleteSolution(solutionId): void {
    this.solutionService.deleteSolution(solutionId).subscribe((response) => {
      if (response.result) {
        this.notification.success(response.result, false);
        this.getSolutions();
      }
    });
  }

  removeFromPartnersModel(content, solutionId): void {
    this.removePartnermodalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.removesolutionId = solutionId;
  }

  removeFromPartners(solutionId): void {
    const payload = {
      account_solution_id: solutionId,
      release_type_id: 1,
    };

    this.solutionService.realeaseToPartners(payload).subscribe((response) => {
      if (response.result) {
        this.notification.success('Partner Removed Successfully', false);
        this.solutionService.readSolution.next('updateSolution');
      }
    });
  }

  getReleaseTypes(): void {
    this.factsService.getReleaseTypes().subscribe((response) => {
      if (response.result) {
        this.releaseTypes = response.result.releaseTypes;
      }
    });
  }

  selectPartnerTypes(releaseTypeId): void {
    this.releaseTypeId = releaseTypeId;

    if (releaseTypeId == '3') {
      this.resetPartners();
      this.getPartnerTypesSpecific();
    }

    if (releaseTypeId == '4') {
      this.resetPartners();
      this.getPartnerTypesGroup();
    }
  }

  getPartnerTypesSpecific(): void {
    const accId = sessionStorage.getItem('aid');
    this.accountService.getPartnersFieldData(accId, 'accoutnPartners').subscribe((result) => {
      this.partnerTypeListSpecific = result.result.data;
    });
  }

  getPartnerTypesGroup(): void {
    const accId = sessionStorage.getItem('aid');
    this.accountService.getPartnerGroups(accId).subscribe((result) => {
      this.partnerTypeListGroup = result.result;
    });
  }

  resetPartners(): void {
    this.partnerTypeListSpecific = [];
  }

  realeaseToPartners(): void {
    const selectedPartners = [];
    let outSelectedPartners;
    let payload = null;

    switch (this.releaseTypeId) {
      case '2':
        // all
        payload = {
          account_solution_id: this.selectedSolutionId,
          release_type_id: '2',
        };
        break;

      case '3':
        // specific
        for (let i = 0; i < this.formObj.partnerTypeListSpecific.length; i++) {
          selectedPartners.push(this.formObj.partnerTypeListSpecific[i].id);
        }
        outSelectedPartners = selectedPartners.join(',');

        payload = {
          account_solution_id: this.selectedSolutionId,
          release_type_id: this.releaseTypeId,
          account_partner_id: outSelectedPartners,
        };
        break;

      case '4':
        // group
        for (let i = 0; i < this.formObj.partnerTypeListGroup.length; i++) {
          selectedPartners.push(this.formObj.partnerTypeListGroup[i].id);
        }
        outSelectedPartners = selectedPartners.join(',');

        payload = {
          account_solution_id: this.selectedSolutionId,
          release_type_id: this.releaseTypeId,
          partner_type_id: outSelectedPartners,
        };
        break;

      default:
        return;
    }

    if (!payload) {
      return;
    }

    this.solutionService.realeaseToPartners(payload).subscribe((response) => {
      if (response.result) {
        this.notification.success('Partner Release Successfully', false);
        this.toggleReleaseToPartners = false;
        this.releaseTypeId = '';
        this.formObj.partnerTypeListSpecific = [];
        this.formObj.partnerTypeListGroup = [];
        this.getReleaseTypes();
        this.getSolutions();
      }
    });
  }

  closeAddEditModel(): void {
    this.toggleAddEditModel = false;
  }

  closeModelShare(): void {
    this.toggleModelShare = false;
  }

  removeFilter(): void {
    this.filterList = '';
    this.tableSolutionList.filter('', 'category_names', 'contains');
  }
}
