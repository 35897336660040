import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';

@Injectable()
export class PainPointsService {
	service_url: string;

	constructor(private CommonService: CommonService) {
		this.service_url = this.CommonService.getServiceUrl();
	}

	public postPainPoint(account_id, payload) {
		let endpoint = "account/painPoint/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getPainPoints(account_id) {
		let endpoint = "account/painPoints/" + account_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public getPainPointsSolution(account_solution_id) {
		let endpoint = "solutions/painPoints/" + account_solution_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public putPainPoint(account_id, payload) {
		let endpoint = "account/painPoint/" + account_id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public deletePainPoint(account_id, account_painpoint_id) {
		let endpoint = "account/painPoint/" + account_id + "/delete/" + account_painpoint_id;
		return this.CommonService.deleteAPIService(endpoint);
	}
}