import { Component, OnInit, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { takeUntil } from 'rxjs/operators';

import { Observable, Subject } from 'rxjs';
import { DefaultTranslations } from './assumptions.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Navstep$ } from 'app/repv2/simple.implementation';
import { StyleService } from 'app/style.service';
import { CustomerValidation, Factor, FactorGroup } from '@data/services/valueposition/models/factor-group.interface';
import { Benefit, Metric, ValueProp } from '@shared/models/value-prop.model';
import { BasicInfo } from '@shared/models/basic-info.model';
import { ValueCategory } from '@shared/models/value_category.model';

@Component({
  selector: 'app-assumptions-fastfill',
  templateUrl: './assumptions.component.html',
  styleUrls: ['./assumptions.component.scss'],
})
export class AssumptionsFastFillComponent implements OnInit, OnDestroy {
  @Input() showHelp;
  @Input() contextualHelp;
  @Input() valueProp: ValueProp;
  @Input() keyAssumptions: boolean = false;
  @Input() showTranslate: boolean = false;
  @Input() dealdesk: boolean = false;
  @Input() showContinue: boolean = true;

  @Output() valueChange = new EventEmitter();
  @Output() callbackSelectedBenefit = new EventEmitter();
  @Output() funcCallback = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  @Output() back = new EventEmitter();
  @Input() view: string = 'full';
  fullImagePath: string;

  ngUnsubscribe = new Subject();

  factors: FactorGroup[] = [];
  company_name: string = '';
  sourceTypes: BasicInfo[] = [];
  benefits: Benefit[] = [];
  image_url: string;
  loading: boolean = false;
  assumptionSaveLoader: boolean = false;
  locked: boolean = false;
  onlyKeyFactors = false;
  crm: string;
  formChanged = false;
  navStep: number;

  showEditScratchpad = false;
  editScratchpadFactor: Factor;
  metric: Partial<Metric>;
  featureProvideNumberScratchpads = false;
  style2022$: Observable<boolean>;

  constructor(
    private CommonService: CommonService,
    private ValuepropService: ValuepropService,
    private NotificationService: NotificationService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private cd: ChangeDetectorRef, // private ScalersComponent: ScalersComponent
    private styleService: StyleService
  ) {
    this.image_url = this.CommonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    Navstep$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((step) => {
      this.navStep = step;
    });
    this.crm = sessionStorage.getItem('crm');
    this.locked = this.valueProp.closed == '1' ? true : false;
    this.featureProvideNumberScratchpads = this.CommonService.checkFeature(44);

    if (!this.valueProp.vp_can_edit) {
      this.locked = true;
    }
    this.onlyKeyFactors = this.CommonService.checkFeature(104);
    this.company_name = sessionStorage.getItem('account_name');
    this.funcGetDropdownMenuItems();
    this.funcGetList();
    this.funcGetBenefits();

    this.getTranslations();
    this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.getTranslations();
    });

    this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  get isSomeUpdated() {
    const anyFactor = this.factors.some((group) => group.factors.some((gf) => gf.updated));
    const anyBenefit = this.benefits.some((group) => group.metrics.some((gm) => gm.updated));
    return anyFactor || anyBenefit;
  }

  modelChangeFn(row: Factor | Metric, input?: 'future_effort') {
    this.markChanges(row);
    if (input && input === 'future_effort') {
      this.funcCalcWithUs(row as Metric);
    } else {
      this.funcCalcImprovementPercent(row as Metric);
    }
    this.formChanged = true;
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  funcGetDropdownMenuItems(): void {
    this.ValuepropService.getSourceTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.sourceTypes = res.result;
      });
  }

  setUnderlyingSource(currentSource: string, newSource: string ) {
    let returnSource = newSource;

    if ( newSource === '1' && currentSource === '5' ) {
      // Currently set to situation projected, we need to keep the as the source behind the scenes
      returnSource = '5';
    }

    return returnSource;
  }

  funcSetSource(elem: Factor | Metric, id: string, type: string) {
    if (type === 'benefit') {
      (elem as Metric).impact_display_source_type_id = id;
      (elem as Metric).impact_source_type_id = this.setUnderlyingSource((elem as Metric).impact_source_type_id, id);

      // Initialize validating user name
      if ( (elem as Metric).impact_source_type_id === '2' && (elem as Metric).validation === undefined  ) {
        (elem as Metric).validation.validating_user_name = '';
      }

    } else {
      (elem as Factor).display_source_type_id = id;
      (elem as Factor).source_type_id = this.setUnderlyingSource((elem as Factor).source_type_id, id);
      
      // Initialize validating user name
      if ( (elem as Factor).source_type_id === '2' && (elem as Factor).validation === undefined  ) {
        (elem as Factor).validation.validating_user_name = '';
      }
    }

    elem.updated = true;
    this.formChanged = true;
  }

  funcGetSourceType(id: string, type?: string) {
    return this.sourceTypes.filter((x) => x.id == id)[0].name;
  }

  funcGetList() {
    let feature78 = this.CommonService.checkFeature(78);
    let ignoreImprovements = null;
    const keyFactors = this.onlyKeyFactors ? 1 : 0;
    if (feature78) {
      ignoreImprovements = 1;
    } else {
      ignoreImprovements = 0;
    }

    this.ValuepropService.valuePropGetFactorGroups(this.valueProp.id, keyFactors, ignoreImprovements)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.factors = res.result.factor_groups.map((group) => ({
          ...group,
          factors: group.factors.map((factor) => ({
            ...factor,
            allowNegative: factor.greater_than_zero !== '1',
            validation: this.returnValidation(factor)
          })),
        }));
      });
  }

  returnValidation(elem: Factor | Metric) {
    if ( elem.validation === undefined ) {
      let validation: CustomerValidation = {
        value_prop_id: +this.valueProp.id,
        validating_user_name: '',
        account_factor_id: 0,
        year: 1
      }

      return validation;
    } else {
      return elem.validation;
    }
  }

  funcGetBenefits(): void {
    this.ValuepropService.quickFillGetBenefits(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.benefits = res.result.vcs.map((vc) => ({
          ...vc,
          metrics: vc.metrics.map((metric) => ({
            ...metric,
            validation: this.returnValidation(metric)
          }))
        }));
      });
  }

  funcCalcImprovementPercent(row: Metric): void {
    let p: number = +row.percent_improvement > 100 ? (row.percent_improvement = 100) : (row.percent_improvement as number);
    let c: number = row.current_effort as number;
    row.future_effort = c - (c * p) / 100;
    this.cd.detectChanges();
  }

  funcCalcWithUs(row: Metric): void {
    let f = +row.future_effort < 0 ? (row.future_effort = 0) : (row.future_effort as number);
    let c = row.current_effort as number;
    row.percent_improvement = ((c - f) / c) * 100;
    this.cd.detectChanges();
  }

  updateAll(resstep?: 'no emit'): Promise<boolean> {
    const noEmit = resstep === 'no emit';
    this.assumptionSaveLoader = true;
    return new Promise((resolve) => {
      this.loading = true;
      let factors = [];
      let metrics = [];
      let errorFound = false;
      this.factors.forEach((group) => {
        group.factors.forEach((elem) => {
          if (elem.updated) {
            if (elem.source_type_id === '2' && elem.validation.validating_user_name === '') {
              errorFound = true;
              elem.valid_error = 'Please provide a name';
            } else if (elem.value !== null) {
              let fr: string = elem.value;
              factors.push({
                account_factor_id: elem.id,
                value: fr,
                source_type_id: elem.source_type_id,
                validating_user_name: elem.validation?.validating_user_name
              });
            } else {
              errorFound = true;
              elem.error = 'Please provide a value';
            }
          }
        });
      });

      this.benefits.forEach((group) => {
        group.metrics.forEach((elem) => {
          if (elem.updated) {
            if (elem.impact_source_type_id === '2' && elem.validation.validating_user_name === '') {
              errorFound = true;
              elem.valid_error = 'Please provide a name';
            } else if (elem.current_effort !== null || elem.future_effort !== null) {
              metrics.push({
                value_prop_metric_id: elem.value_prop_metric_id,
                improvement_factor_id: elem.improvement_factor_id,
                percent_improvement: elem.percent_improvement,
                current_effort: elem.current_effort,
                future_effort: elem.future_effort,
                impact_source_type_id: elem.impact_source_type_id,
                validating_user_name: elem.validation?.validating_user_name
              });
            } else {
              if (elem.current_effort == null) {
                errorFound = true;
                elem.current_effort_error = 'Please provide a value';
              }

              if (elem.future_effort == null) {
                errorFound = true;
                elem.future_effort_error = 'Please provide a value';
              }
            }
          }
        });
      });

      if (errorFound) {
        this.loading = false;
        return false;
      }

      let payload = {
        factors: factors,
        metrics: metrics,
      };

      if (factors.length || metrics.length) {
        this.ValuepropService.quickFillPut(this.valueProp.id, payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.funcGetList();
            this.funcGetBenefits();
            this.assumptionSaveLoader = false;
            this.NotificationService.success('Update successful', false);
            if (!noEmit) {
              this.reloadVP.emit();
              this.ValuepropService.refreshDashboard.next();
            } else {
              this.funcGetList();
              this.ValuepropService.refreshDashboard.next();
            }
            this.loading = false;
            resolve(true);
          });
      } else {
        this.assumptionSaveLoader = false;
        if (!noEmit) {
          this.reloadVP.emit();
        }
        this.ValuepropService.refreshDashboard.next();
        resolve(true);
      }
    });
  }

  markChanges(row): void {
    row.updated = true;
    this.formChanged = true;
  }

  editScratchpad(factor?: Factor): void {
    this.showEditScratchpad = !this.showEditScratchpad;
    if (this.showEditScratchpad) {
      this.editScratchpadFactor = factor;
      this.metric = {
        driver_factor_id: this.editScratchpadFactor.id.toString(),
      };
    } else {
      this.editScratchpadFactor = null;
    }
  }

  onScratchpadSave(): void {
    this.showEditScratchpad = false;
    this.editScratchpadFactor = null;
    this.funcGetList();
    this.funcGetBenefits();
  }
}
