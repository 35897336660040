import { Location } from '@angular/common';
import { HostListener, Injectable, Input, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { CustomerShareServices } from '@data/services/customer_share/share.service';
import { FactsService } from '@data/services/facts/facts.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AlertService } from '@services/alert.service';
import { NotificationService } from '@services/notification.service';
import { ApiServiceService } from 'app/api-service.service';
import { StyleService } from 'app/style.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Permissions } from '../value-prop/value-prop-dashboard/dashboard_customer/permissions';
import { ScenarioCombinedComponent } from '../value-prop/value-prop-dashboard/scenario_combined/combined.component';
import { RepSimpleTranslations } from './simple.translation';

export const Navstep$ = new BehaviorSubject<number>(0);

@Directive()
@Injectable()
export class SimpleImplementation {
  @Input() childCombined: ScenarioCombinedComponent;
  @Input() childBenefits;
  @Input() childAssumptions;
  @Input() view: string = 'simple';
  @Input() lowRes: boolean;

  @Input() valueProp: any = [];
  @Input() ValuePropId: any;
  @Input() permissions: { [klass: string]: any };
  @Input() showTranslate: boolean = false;

  showLogin: boolean = true;

  userId: string;
  token: string;
  contextualHelp: any[] = [];
  menuTop: any[] = [];
  viewTop: string = 'account';

  valuePropLoader: boolean = false;
  AssetsMenu: boolean = false;

  waitingButton: boolean = false;

  h: any;
  @Input() panelH: any;
  step: any = 0;

  header_color: string = 'blue';
  menuAssets: any[] = [];

  locked: boolean = false;
  valuePropStatusTypes: any[] = [];
  valuePropStatusComment: any;
  done: boolean = false;
  image_url: string;
  fullImagePath: string;
  assetUrl: string;
  hasKeyAssumptions: boolean = false;
  discoveryQuestions: boolean = false;

  ngUnsubscribe = new Subject();
  showClone: boolean = false;
  clonedName: string = '';
  cloneLoader: boolean;
  firstLoad: boolean = true;

  clone_option: number = 0;
  clone_userid: any;
  opportunity_id: any;
  owner_of_clone: boolean = false;
  clone_company_id: any;
  clone_company_name: string = '';

  resultsCanEdit = true;

  promoteToRealization = false;
  feature58 = false;
  isValueReaiztionUser = false;

  sc_vpg: boolean = false;
  first_run: boolean = true;
  showFooter: boolean = false;

  direct: boolean = false;
  feature52: boolean = false;

  showBack: boolean = false;
  showBackLink: string = '';

  scenario_done: boolean = false;
  situation_done: boolean = false;
  scalers_done: boolean = false;
  q2_done: boolean = false;
  activeIndex: number = 0;
  feature67: boolean = false;
  showAllTcoMetrics: boolean = false;
  style2022ViewTop: string;

  constructor(
    private commonService: CommonService,
    public RS: RepSimpleViewService,

    private userService: UserService,
    private valuepropService: ValuepropService,
    public trans: RepSimpleTranslations,
    private translationService: TranslationsV2Service,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private location: Location,
    private customerShare: CustomerShareServices,
    private apiServiceService: ApiServiceService,
    private alertService: AlertService,

    private router: Router,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    this.assetUrl = this.commonService.getAssetUrl();
  }

  OnInit() {
    Navstep$.next(this.step)
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    let feature31 = this.commonService.checkFeature('31');
    let feature34 = this.commonService.checkFeature('34');
    this.feature52 = this.commonService.checkFeature('52');
    let feature56 = this.commonService.checkFeature('56');
    this.feature58 = this.commonService.checkFeature('58');
    this.feature67 = this.commonService.checkFeature('67');

    this.showFooter = false;

    if (this.feature67 && feature34) {
      this.sc_vpg = true;
      this.showFooter = false;
    }

    if (!feature31 && !feature56 && feature34) {
      this.showFooter = true;
    }

    let products = JSON.parse(sessionStorage.getItem('products'));

    let hasVR = products.find((prod) => prod.product_id == 4);

    this.isValueReaiztionUser = !!hasVR;

    this.userId = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');

    this.header_color = sessionStorage.getItem('header_color');

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'customercolors';

    let entry = '.btn-next { background-color: ' + this.header_color + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    entry = '.btn-back { color: ' + this.header_color + ' !important; border-color: ' + this.header_color + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    entry = '.badge_current { color: ' + this.header_color + ' !important; border-color: ' + this.header_color + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    entry = '.arrow-steps .step.current { color: ' + this.header_color + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    entry = '.badge_done { background-color: ' + this.header_color + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    entry = 'body .p-slider .p-slider-range { background-color: ' + this.header_color + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    entry = '.toolbar_icons { color: ' + this.header_color + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.firstLoad = false;

      this.getValuePropStatusTypes();
    });

    this.valuepropService.refreshBenefitDetail.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getValuePropStatusTypes();
    });

  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.funcBuild();
      });
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event) {
      this.h = window.innerHeight;
      this.panelH = this.h - 45 + 'px';
    }
  }

  funcCallback(res) {
    if (!res) {
      this.waitingButton = false;
      return false;
    }
    this.waitingButton = false;

    switch (res.res) {
      case 'gotoStep':
        this.gotoStepCallback(res.id);
        break;
      case 'back':
        this.backCallback();
        break;
      case 'next':
        this.nextCallback();
        break;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  funcSendStepToServer() {
    let payload = {
      step: this.step,
    };
    this.RS.updateVPStep(this.ValuePropId, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (this.step == 4) {
          let payload2 = { done: 1 };
          this.RS.putDone(this.ValuePropId, payload2)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.done = true;
            });
        }
      });
  }

  funcCloneCompany(event) {
    this.clone_company_id = event.id;
    this.clone_company_name = event.name;
  }

  cloneValueProp() {
    this.notificationService.info('Cloning in progress...', false);
    this.cloneLoader = true;

    if (!this.clonedName) {
      this.notificationService.error('Value Prop Name is required', false);
      this.cloneLoader = false;
      return false;
    }

    if (this.clone_option == 1) {
      if (!this.clone_company_id) {
        this.notificationService.error('Please select a company', false);
        this.cloneLoader = false;
        return false;
      }
      if (!this.opportunity_id) {
        this.notificationService.error('Please enter in an Opportunity ID', false);
        this.cloneLoader = false;
        return false;
      }
    }

    if (this.clone_option == 2) {
      if (!this.opportunity_id) {
        this.notificationService.error('Please enter in an Opportunity ID', false);
        this.cloneLoader = false;
        return false;
      }
    }

    let payload = {
      name: this.clonedName,
      user_id: !this.owner_of_clone ? this.userId : null,
      opportunity_id: this.opportunity_id ? this.opportunity_id : null,
      company_id: this.clone_company_id ? this.clone_company_id : null,
    };

    this.valuepropService.cloneValueProp(this.valueProp.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.cloneLoader = false;
          this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() => this.router.navigate(['rep/', response.result.value_prop.id]));
        } else if (response.result.success === false) {
          this.cloneLoader = false;
          this.notificationService.error(response.result.message, false);
        }
        this.showClone = !this.showClone;
      });
  }

  funcGotoStepDirect(id) {
    switch (id) {
      case '0':
        this.viewTop = 'account';
        break;
      case '1':
        this.viewTop = 'benefits';
        break;
      case '2':
        this.viewTop = 'keyassumptions';
        break;
      case '3':
        this.viewTop = 'costs';
        break;
      case '4':
        this.viewTop = 'results';
        break;
      default:
        this.viewTop = 'results';
        break;
    }
    this.step = id;
  }

  funcBuild() {
    this.assetUrl = this.commonService.getAssetUrl();

    if (!this.contextualHelp.length) {
      this.userService
        .getContextualHelpList()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          if (result.result) {
            this.commonService.contextualHelp.next(result.result);
          }
        });
    }

    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.contextualHelp = response['Valueprop'];
    });

    this.menuTop = [];
    if (this.permissions.account_info_view) {
      this.menuTop.push({
        label: this.view == 'simple' ? 'Define Scenario' : 'About You',
        viewTop: 'account',
        done: this.step > 0 ? true : false,
        command: () => {
          this.viewTop = 'account';
          this.step = 0;
          this.funcSwitchStep();
        },
        funcBack: () => {
          if (this.permissions.account_info_editable && this.view == 'simple') {
            this.childCombined.funcCombinedModelsScalers('callback').then((res) => {
              if (res) {
                this.menuTop[this.step].done = true;
                this.nextCallback();
              } else {
                this.waitingButton = false;
              }
            });
          } else {
            this.menuTop[this.step].done = true;
            this.nextCallback();
          }
        },
        funcNext: () => {
          if (this.permissions.account_info_editable && this.view == 'simple') {
            this.childCombined.funcCombinedModelsScalers('callback').then((res) => {
              if (res) {
                this.menuTop[this.step].done = true;
                this.nextCallback();
              } else {
                this.waitingButton = false;
              }
            });
          } else {
            this.menuTop[this.step].done = true;
            this.nextCallback();
          }
        },
      });
    }
    if (this.permissions.benefits_view) {
      this.menuTop.push({
        label: this.view == 'simple' ? 'Review Benefits' : 'Prioritize Benefits',
        viewTop: 'benefits',
        done: this.step > 1 ? true : false,
        command: () => {
          this.viewTop = 'benefits';
          this.step = 1;
          this.funcSwitchStep();
        },
        funcBack: () => {
          this.backCallback();
        },
        funcNext: () => {
          this.menuTop[this.step].done = true;
          this.nextCallback();
        },
      });
    }

    let show_assumptions = false;

    if (this.view == 'simple') {
      show_assumptions = true;
    } else {
      if (this.permissions.assumptions_view) {
        if (this.valueProp.has_key_assumptions == '1') {
          show_assumptions = true;
        } else {
          show_assumptions = false;
        }
      } else {
        show_assumptions = false;
      }
    }

    if (show_assumptions) {
      this.menuTop.push({
        label: this.view == 'simple' ? 'Provide Numbers' : 'Provide Number',
        viewTop: 'keyassumptions',
        done: this.step > 2 ? true : false,
        command: () => {
          this.viewTop = 'keyassumptions';
          this.step = 2;
          this.funcSwitchStep();
        },
        funcBack: () => {
          if (this.permissions.assumptions_editable) {
            this.waitingButton = true;
            this.childAssumptions.updateAll('callback').then(() => {
              this.waitingButton = false;
              this.backCallback();
            });
          } else {
            this.backCallback();
          }
        },
        funcNext: () => {
          if (this.permissions.assumptions_editable) {
            this.waitingButton = true;
            this.childAssumptions.updateAll('callback').then(() => {
              this.waitingButton = false;
              this.menuTop[this.step].done = true;
              this.nextCallback();
            });
          } else {
            this.menuTop[this.step].done = true;
            this.nextCallback();
          }
        },
      });
    }

    if (this.permissions.costs_view) {
      this.menuTop.push({
        label: this.view == 'simple' ? this.trans.trans.addCosts.value : 'Review Costs',
        viewTop: 'costs',
        done: this.step > 3 ? true : false,
        command: () => {
          this.viewTop = 'costs';
          this.step = 3;
          this.funcSwitchStep();
        },
        funcBack: () => {
          this.backCallback();
        },
        funcNext: () => {
          this.menuTop[this.step].done = true;
          this.nextCallback();
        },
      });
    }
    if (this.permissions.result_view) {
      this.menuTop.push({
        label: this.view == 'simple' ? this.trans.trans.reviewResults.value : 'Review Results',
        viewTop: 'results',
        done: this.step > 4 ? true : false,
        command: () => {
          this.viewTop = 'results';
          this.step = 4;
          this.funcSwitchStep();
        },
        funcBack: () => {
          this.backCallback();
        },
        funcNext: () => {
          this.menuTop[this.step].done = true;
          this.nextCallback();
        },
      });
    }
  }

  gotoStep(id) {
    if (this.view == 'simple') {
      if (!this.done) {
        this.waitingButton = false;
        return false;
      }
    }

    this.step = id;
    this.showFooter = true;
    this.funcSwitchStep();
  }
  gotoStepCallback(id) {
    this.step = id;
    this.funcSendStepToServer();

    this.menuTop[this.step].done = false;
    this.funcSwitchStep();
  }

  funcToggleSupport() {
    this.userService.toggleSupportSidebar.next('');
  }

  back() {
    if (this.step < 0) {
      this.step = 0;
    }

    this.waitingButton = true;
    if (!this.menuTop[this.step]) {
      this.step = 0;
    }
    this.menuTop[this.step].funcBack();
  }

  backCallback() {
    this.step = this.step - 1;

    this.funcSendStepToServer();
    this.waitingButton = false;
    this.funcSwitchStep();
  }

  next() {
    if (this.step > this.menuTop.length) {
      this.step = this.menuTop.length - 1;
    }

    this.menuTop[this.step].funcNext();

    this.showFooter = true;
  }

  nextCallback() {
    this.waitingButton = false;
    this.menuTop[this.step].done = true;
    this.step = this.step + 1;

    this.funcSendStepToServer();

    this.funcSwitchStep();
  }

  funcGotoVPID(id) {
    this.showFooter = true;

    this.step = 0;
    this.viewTop = 'account';
    this.valueProp.id = id.valuePropId;
    this.ValuePropId = id.valuePropId;
    this.location.go('/rep/' + id.valuePropId);
  }

  resetMenuColors() {
    for (let i = 0; i < this.menuTop.length; i++) {}
  }

  funcSwitchStep() {
    if (this.step > this.menuTop.length) {
      this.step = this.menuTop.length - 1;
    }
    if (this.step < 0) {
      this.step = 0;
    }

    this.viewTop = this.menuTop[this.step] && this.menuTop[this.step].viewTop ? this.menuTop[this.step].viewTop : 'account';
  }

  lockValueProp(type) {
    if (this.valueProp.vp_can_edit && this.resultsCanEdit) {
      if (type == 'lock') {
        const payload = {
          closed: '1',
        };
        this.valuepropService.lockValueProp(this.valueProp.id, payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            if (response.result) {
              this.locked = true;
              this.valueProp.closed = 1;

              this.valuepropService.refreshDashboard.next('refreshDashboard');
              this.notificationService.success(this.trans.trans.vpLockedSucces.value, false);
            }
          });
      }
      if (type == 'unlock') {
        const payload = {
          closed: '0',
        };
        this.valuepropService.lockValueProp(this.valueProp.id, payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            if (response.result) {
              this.locked = false;
              this.valueProp.closed = 0;
              this.valuepropService.refreshDashboard.next('refreshDashboard');
              this.notificationService.success(this.trans.trans.vpUnlockedSucces.value, false);
            }
          });
      }
    }
  }

  getValuePropStatusTypes() {
    this.factsService
      .getValuePropStatusTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.valuePropStatusTypes = [];

        for (let s = 0; s < response.result.length; s++) {
          let elem = response.result[s];
          this.valuePropStatusTypes.push({ label: elem.name, command: () => this.updateValuePropStatus(elem.id) });
        }
      });
  }

  updateValuePropStatus(statusTypeid) {
    if (!statusTypeid) {
      return;
    }

    let statusTypeObj = { value_prop_status_type_id: statusTypeid };

    this.valuepropService.updateValuePropStatus(this.valueProp.id, statusTypeObj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success(this.trans.trans.statusUpdateSuccess.value, false);
          this.getValuePropStatusTypes();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  funcNavigateNotes() {
    this.RS.navNotes.next();
  }

  funcNavDiscoveryQuestions() {
    this.RS.navDiscoveryQuestions.next('');
    var chElem = document.getElementById('panel_container');
    chElem.scrollIntoView();
  }

  funcProcessOneZero(input) {
    if (input == '1') {
      return true;
    } else {
      return false;
    }
  }
  loading: boolean = false;
  hash_id: string = '';
  password: string = '';
  login_data: any;
  creator_name: string;
  creator_email: string;
  creator_phone: string;

  funcLogin() {
    if (!this.password) {
      return false;
    }
    this.loading = true;
    let payload = {
      hash: this.hash_id,
      password: this.password,
    };
    this.customerShare
      .ShareLogin(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.result.success == false) {
          this.notificationService.error(res.result.message, false);
          return;
        }

        if (res.result.value_prop_id) {
          this.showLogin = false;
          if (this.view == 'share') {
            this.permissions = {
              account_info_editable: this.funcProcessOneZero(res.result.account_info_editable),
              account_info_view: this.funcProcessOneZero(res.result.account_info_view),
              assumptions_editable: this.funcProcessOneZero(res.result.assumptions_editable),
              assumptions_view: this.funcProcessOneZero(res.result.assumptions_view),
              benefits_editable: this.funcProcessOneZero(res.result.benefits_editable),
              benefits_view: this.funcProcessOneZero(res.result.benefits_view),
              costs_view: this.funcProcessOneZero(res.result.costs_view),
              costs_editable: this.funcProcessOneZero(res.result.costs_editable),
              re_share: this.funcProcessOneZero(res.result.re_share),
              result_view: this.funcProcessOneZero(res.result.result_view),
              result_editable: this.funcProcessOneZero(res.result.result_editable),
              expires: res.result.expires,
            };
            this.resultsCanEdit = this.permissions.result_editable;
          }

          this.login_data = res;
          this.ValuePropId = this.login_data.result.value_prop_id;

          if (!res.result.hasOwnProperty('errors')) {
            sessionStorage.setItem('hash_id', this.hash_id);

            sessionStorage.setItem('uid', this.login_data.result.user_id);
            sessionStorage.setItem('vcu', this.login_data.result.token);
            sessionStorage.setItem('aid', this.login_data.result.account_id);
            sessionStorage.setItem('account_name', this.login_data.result.account_name);
            sessionStorage.setItem('features', this.login_data.result.features);
            this.styleService.updateStyle2022();
            sessionStorage.setItem('privileges', this.login_data.result.privileges);
            sessionStorage.setItem('trophies', this.login_data.result.trophies);
            sessionStorage.setItem('rid', this.login_data.result.role_type_id);

            localStorage.setItem('creator_name', this.login_data.result.creator_name);
            localStorage.setItem('creator_email', this.login_data.result.creator_email);
            localStorage.setItem('creator_phone', this.login_data.result.creator_phone);
            this.creator_name = this.login_data.result.creator_name;
            this.creator_email = this.login_data.result.creator_email;
            this.creator_phone = this.login_data.result.creator_phone;

            sessionStorage.setItem('header_color', this.login_data.result.header_color);
            this.apiServiceService.changeHeaderColor.next(this.login_data.result.header_color);
            let out = [];

            for (let c = 0; c < this.login_data.result.chart_colors.length; c++) {
              let elem = this.login_data.result.chart_colors[c];

              out.push(elem.color);
            }

            sessionStorage.setItem('chart_colors', JSON.stringify(out));

            this.header_color = this.login_data.result.header_color;

            sessionStorage.setItem('new_asset_gen', this.login_data.result.new_asset_gen);
            sessionStorage.setItem('asset_gen_file', this.login_data.result.asset_gen_file);
            sessionStorage.setItem('language_type_id', this.login_data.result.language_type_id);

            sessionStorage.setItem('logo', this.login_data.result.logo_filepath);
            sessionStorage.setItem('username', this.login_data.result.user_name);
            sessionStorage.setItem('email', this.login_data.result.email);

            if (this.login_data.result.fact_labels) {
              let out = [];
              let label_list = this.login_data.result.fact_labels;

              for (let i = 0; i < label_list.length; i++) {
                out.push({ k: label_list[i].name, v: label_list[i].description, language_type_id: label_list[i].language_type_id });
              }

              sessionStorage.setItem('all_dictionaries', JSON.stringify(out));
            }

            if (this.login_data.result.labels) {
              let out = [];
              let label_list = this.login_data.result.labels;
              let labels = label_list.filter((x) => (x.language_type_id = this.login_data.result.language_type_id));

              for (let i = 0; i < labels.length; i++) {
                out.push({ k: labels[i].key, v: labels[i].value, language_type_id: labels[i].language_type_id });
              }

              sessionStorage.setItem('labels', JSON.stringify(out));
            }

            this.apiServiceService.changeLoggedInVal(true);

            this.alertService.notifyLoggedIn(this.login_data.result);
          }
        } else {
          this.loading = false;
          this.alertService.error(this.trans.trans.logginError.value, true);
          return;
        }
      });
  }

  collapseBenefitMini() {
    sessionStorage.removeItem("metricsExpandedMini");
	}
}
