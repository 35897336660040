import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'modelsFactors',
    display_name: 'Factors ',
    description: 'Models Factors'
  };

  public trans: any = {
    factor_groups: 'Factor Groups',
    add_new_group: 'Add New Group',
    add_factor_group: 'Add Factor Group',
    edit_group: 'Edit Group',
    add_new: 'Add New',
    expand_all: 'Expand All',
    collapse_all: 'Collapse All',
    ch_edit: 'Edit Factor',
    ch_view: 'View',
    ch_delete: 'Delete',
    ch_edit_scratchpad: 'Edit Scratchpad',
    ch_edit_scalers: 'Edit Scalers',
    save: 'Save',
    update: 'Update',
    name: 'Name',
    description: 'Description',
    created: 'Created On',
    scaled_by: 'Scaled By',
    scales_how: 'Scales How?',
    ratio: 'Ratio',
    formula: 'Formula',
    are_you_sure: 'Are you sure?',
    back: 'Back'
  };
}
