import { Injectable } from '@angular/core';

@Injectable ()

export class RCostTranslations {
    
    public config: any = {
        component: 'readCost',
        display_name: 'Cost View',
        description: 'View of an individual cost'
    };

    public trans: any = {
        viewCost: 'View Cost',
        costType: 'Cost Type',
        accrue: 'How does this accrue?',
        unitPrice: ' Unit Price',
        scaleFactor: 'Scale Factor',
        driverFactor: 'Driver Factor (Optional)',
        answerBaseline: 'Cost Answer Baseline',
        costCategory: 'Cost Category (Optional)',
        expenseType: 'Expense Type',
        situations: 'Situations',
        includedInValueProp: 'Include this cost by default in ValueProps?',
        yes: 'Yes',
        no: 'No'
    };
}
