import { Injectable } from '@angular/core';

@Injectable()
export class SearchTranslations {
  public config: any = {
    component: 'search',
    display_name: 'Global Search',
    description: 'Global search at the top bar of the website',
  };

  public trans: any = {
    search: 'Search',
  };
}
