import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { NotificationService } from '@services/notification.service';
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { AlertService } from '@services/alert.service';
import { MVPListTranslations } from './master-value-prop-list.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-list',
	templateUrl: './master-value-prop-list.component.html',
	styleUrls: ['./master-value-prop-list.component.scss']
})
export class MasterValuePropListComponent implements OnInit, OnDestroy {

	pageNumber: number;
	pageLimit = 10;
	order = 'desc';
	sortBy = 'modified';
	searchText = 'all';
	strSearch = "";

	valueProps: ValueProp[] = [];
	valuePropsMaster: ValueProp[] = [];
	valuePropLoader = false;
	fullImagePath: string;
	showActionLoader = false;
	modalReference: NgbModalRef;
	valuepropname: string;

	// Contextual Help
	contextualHelp = {};
	masterValuePropFeature = false;
	dealdeskFeature = false;
	guidedDiscoveryFeature = false;
	caseStudyFeature = false;
	page: string;
	accountFeatures = [];
	valuePropCreateFeatureCount: number;
	accountID = sessionStorage.getItem('aid');
	selectedOption: string;
	isGuest = false;
	search = false;
	feature41 = false;
	showAddButtons = true;

	columns = [];
	rowLimit = [];

	loading = false;
	isAdmin = false;
	showTranslate = false;
	ngUnsubscribe: Subject<any> = new Subject();
	style2022$: Observable<boolean>;

	constructor(
		private masterValuePropService: MasterValuePropService,
		private commonService: CommonService,
		private notification: NotificationService,
		private router: Router,
		private alertService: AlertService,
		public trans: MVPListTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {
		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
		this.isAdmin = (sessionStorage.getItem("isAdmin") == "true" ? true : false);
		this.columns = [
			{ field: 'name', header: "Name" },
			{ field: 'total_benefits_fmt', header: "Total Benefits" },
			{ field: 'total_costs_fmt', header: "Total Costs" },
			{ field: 'created', header: "Created Date" }
		]
		this.loadValueProps();


		this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			this.contextualHelp = response['Valueprop'];
		});

		const rid = sessionStorage.getItem('rid');
		if (rid == "7") {
			this.isGuest = true;
		}
		this.parseFeatures();

		this.alertService.subscribeLoggedIn().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.loadValueProps();
		})
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

	setRowLimit(num) {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit() {
		this.rowLimit = [
			{ label: "10", icon: this.pageLimit == 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: "25", icon: this.pageLimit == 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: "50", icon: this.pageLimit == 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: "100", icon: this.pageLimit == 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		]
	}

	funcSort(event) {
		event.data.sort((data1, data2) => {
			let field = "";
			switch (event.field) {
				case "total_benefits_fmt":
					field = "totalbenefits";
					break;
				case "total_costs_fmt":
					field = "totalcosts";
					break;
				default:
					field = event.field;
					break;
			}

			let value1 = (field !== event.field ? Math.trunc(Number(data1[field])) : data1[field]);
			let value2 = (field !== event.field ? Math.trunc(Number(data2[field])) : data2[field]);
			let result = null;

			if (value1 == null && value2 != null) { result = -1; }
			else if (value1 != null && value2 == null) { result = 1; }
			else if (value1 == null && value2 == null) { result = 0; }
			else if (typeof value1 === 'string' && typeof value2 === 'string') { result = value1.localeCompare(value2); }
			else { result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0; }

			return (event.order * result);
		});
	}

	funcShowTranslate() {
		this.showTranslate = !this.showTranslate;
	}
	loadValueProps() {
		this.loading = true;

		let payload = {
			"search": "all",
			"page": 1,
			"limit": "all",
			"sortby": "modified",
			"order": "desc"
		}

		// let payload = {
		// 	"search": this.searchText,
		// 	"page": this.pageNumber,
		// 	"limit": this.pageLimit,
		// 	"sortby": this.sortBy,
		// 	"order": this.order
		// }
		this.masterValuePropService.readAndSearchMasterValueProps(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.valueProps = response.result.master_value_props.data;
				this.valuePropsMaster = response.result.master_value_props.data;
				this.valuePropLoader = false;
			}
			this.loading = false;
		});
	}



	openAddValuePosition() {
		// Default set to one as valueprop or masterValueProp will be true in any case

		// Mario Giambanco 3-26-2019
		// Per Lewis -
		// That Add button should work like the Add button on the dashboard for the master value props, but right now it simply goes to addvalueprop
		this.selectOptions('masterValueProp');
		return;
		// var optionCount = 1;
		// var options;

		// if (this.guidedDiscoveryFeature) {
		// 	optionCount++;
		// }

		// if (optionCount == 1) {
		// 	if (this.dealdeskFeature && this.accountID == '20') {
		// 		this.selectOptions('dealdesk');
		// 	}
		// 	else if (this.dealdeskFeature) {
		// 		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal' });
		// 	}
		// 	else {
		// 		this.selectOptions('quickcreate');
		// 	}
		// }
		// else {
		// 	this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal' });
		// }
	}

	selectOptions(selectedOpt?) {
		const aid = sessionStorage.getItem('aid');

		if (!selectedOpt) {
			var selectedOpt: any = this.selectedOption;
		}

		if (selectedOpt == "quickcreate") {
			if (this.page == 'valuePosition') {
				this.router.navigate(['addValuePosition']);
			} else {
				this.router.navigate(['addvalueprop']);
			}

		}
		else if (selectedOpt == "guideddiscovery") {
			if (this.page == 'valuePosition') {
				if (aid == '50009') {

					this.router.navigate(['masterValueProps/discovery']);
				} else {

					this.router.navigate(['guidedDiscovery']);
				}
			}
			else if (aid == '464' || aid == '50009') {
				this.router.navigate(['masterValueProps/discovery']);
			}
			else {
				this.router.navigate(['valueprop/guidedDiscovery']);
			}
		}
		else if (selectedOpt == "dealdesk") {
			if (this.page == 'valuePosition') {
				this.router.navigate(['dealdesk']);
			} else {
				this.router.navigate(['valueprop/dealdesk']);
			}
		}
		else if (selectedOpt == "masterValueProp") {
			if (this.page == 'valuePosition') {
				this.router.navigate(['addMasterValueProp']);
			} else {
				this.router.navigate(['addMasterValueProp']);
			}
		}

		if (this.modalReference) {
			this.modalReference.close();
		}
	}

	deleteValueProp(valuepropId) {
		this.valuePropLoader = true;
		this.masterValuePropService.deleteMasterValueProp(valuepropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.valuePropLoader = false;
				this.notification.success(response.result, false); // Params {message, islogout}
				this.loadValueProps();
			}
		});
	}

	parseFeatures() {
		const features = sessionStorage.getItem('features');
		this.valuePropCreateFeatureCount = 0;
		this.accountFeatures = features.split(",");

		if (this.accountFeatures.indexOf('15') >= 0) {
			this.masterValuePropFeature = true;
			this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
		} else {

			this.masterValuePropFeature = false;
		}

		if (this.accountFeatures.indexOf('16') >= 0) {
			this.dealdeskFeature = true;
			this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;

		} else {

			this.dealdeskFeature = false;
		}

		if (this.accountFeatures.indexOf('17') >= 0) {
			this.guidedDiscoveryFeature = true;
			this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
		} else {

			this.guidedDiscoveryFeature = false;
		}
		/* 		if (this.feature41 = this.CommonService.checkFeature(41) ){
					this.showAddButtons = false;
				}
		 */


		this.feature41 = this.commonService.checkFeature(41);
		if (this.feature41) { this.showAddButtons = false; }
		if (this.isGuest) { this.showAddButtons = false; }
	}



}
