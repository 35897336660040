import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Shares, ToShare } from './models/share.model';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit, OnDestroy {
  @Input('data') models: { [klass: string]: any };

  fullImagePath: string;
  image_url: string;
  sharesLoader = false;
  shares: Shares[] = [];
  addShare = false;
  users: { id: unknown; name: string }[] = [];
  shareRoles: { id: unknown; name: string }[] = [];
  share: ToShare;
  type = 'add';
  modalReference: NgbModalRef;
  hideShareActionLoader = false;
  hideDeleteActionLoader = false;
  UserShare = true;

  subscriptiongetmodelsShares: Subscription;
  subscriptiongetUsers: Subscription;
  subscriptiongetShareRoles: Subscription;
  subscriptionsaveModelsPrivilege: Subscription;
  subscriptionupdateModelsPrivilege: Subscription;
  subscriptiondeleteModelsPrivilege: Subscription;

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private modalService: NgbModal,
    private solutionService: SolutionService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.sharesLoader = true;
    this.loadShares();
    this.getUsers();
  }

  ngOnDestroy() {
    if (this.subscriptiongetmodelsShares) {
      this.subscriptiongetmodelsShares.unsubscribe();
    }
    if (this.subscriptiongetUsers) {
      this.subscriptiongetUsers.unsubscribe();
    }
    if (this.subscriptiongetShareRoles) {
      this.subscriptiongetShareRoles.unsubscribe();
    }
    if (this.subscriptionsaveModelsPrivilege) {
      this.subscriptionsaveModelsPrivilege.unsubscribe();
    }
    if (this.subscriptionupdateModelsPrivilege) {
      this.subscriptionupdateModelsPrivilege.unsubscribe();
    }
    if (this.subscriptiondeleteModelsPrivilege) {
      this.subscriptiondeleteModelsPrivilege.unsubscribe();
    }
  }

  loadShares() {
    this.share = {
      account_solution_id: this.models.id,
      share_to_user_id: '',
      share_role_type_id: '',
    };
    const searchLimit = '/' + this.models.id;
    this.sharesLoader = true;
    this.subscriptiongetmodelsShares = this.solutionService
      .getmodelsShares(searchLimit)
      .pipe(finalize(() => (this.sharesLoader = false)))
      .subscribe((response) => {
        if (response.result) {
          this.shares = response.result;
          this.shares = this.shares.filter((item) => {
            // tslint:disable-next-line: triple-equals
            return item.share_role_type_id !== '5';
          });
        }
      });
  }

  getUsers() {
    const account_id = sessionStorage.getItem('aid');
    this.subscriptiongetUsers = this.valuepropService.getUsers(account_id).subscribe((response) => {
      if (response.result) {
        this.users = response.result.data;
      }
    });
    this.getRoles();
  }

  getRoles() {
    this.subscriptiongetShareRoles = this.valuepropService.getShareRoles().subscribe((response) => {
      if (response.result) {
        this.shareRoles = response.result;
      }
    });
  }

  openAddShare() {
    this.addShare = true;
    this.type = 'add';
    this.UserShare = true;
  }
  closeAddShare() {
    this.addShare = false;
  }
  saveShare() {
    this.hideShareActionLoader = true;
    if (this.type === 'add') {
      this.subscriptionsaveModelsPrivilege = this.solutionService
        .saveModelsPrivilege(this.share)
        .pipe(finalize(() => (this.hideShareActionLoader = false)))
        .subscribe((response) => {
          if (response.result) {
            this.closeAddShare();
          }
        });
    } else if (this.type === 'edit') {
      this.subscriptionupdateModelsPrivilege = this.solutionService
        .updateModelsPrivilege(this.models.id, this.share)
        .pipe(finalize(() => (this.hideShareActionLoader = false)))
        .subscribe((response) => {
          if (response.result) {
            this.closeAddShare();
          }
        });
    }
  }

  editPrivilege(share: Shares) {
    this.addShare = true;
    this.type = 'edit';
    this.UserShare = false;
    this.share = {
      account_solution_share_id: share.id,
      share_to_user_id: share.user_id,
      share_role_type_id: share.share_role_type_id,
    };
  }

  deletePrivilege() {
    this.hideDeleteActionLoader = true;
    this.subscriptiondeleteModelsPrivilege = this.solutionService
      .deleteModelsPrivilege(this.share.account_solution_share_id)
      .pipe(finalize(() => (this.hideDeleteActionLoader = false)))
      .subscribe((response) => {
        if (response.result) {
          this.closeAddShare();
        }
        this.modalReference.close();
      });
    this.loadShares();
  }

  open(content: any, id: unknown) {
    this.share.account_solution_share_id = id;
    this.modalReference = this.modalService.open(content, {
      windowClass: 'deleteModal secondLayerModalWindow',
      backdropClass: 'secondLayerModalBackdrop',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }
}
