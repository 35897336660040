<app-choose-adventure-internal
  #chooseAdventure
  (onHidden)="onHidden($event)"
  [ff134]="ff134"
  [showStepSelection]="showStepSelection"
  [workflows]="workflows"
></app-choose-adventure-internal>

<div class="row">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
  <div class="pull-right mr-5">
    <app-help-tooltip-value-realized [stepIndex]="9"></app-help-tooltip-value-realized>
  </div>
</div>
<div class="row marg-v padding-15-top flex-acc">
  <div class="col-md-6 mr-auto no-wrap flex">
    <div class="mr-1">{{ trans.trans.trackerTitleLong.value }}</div>
    <div [hidden]="changeVPName">
      {{ valueProp?.name }}
      <a *ngIf="businessReviewService.canEdit | async" class="normal-t" [matTooltip]="trans.trans.renameTracker.value" (click)="changeVPName = !changeVPName"
        ><i style="margin-left: 10px" class="fa fa-pencil"></i
      ></a>
    </div>
    <app-edit-name
      *ngIf="changeVPName"
      [closeEdition]="!changeVPName"
      [name]="valueProp?.name"
      (cancel)="cancelChangeName()"
      (submit)="submitChangeName($event)"
      (hideValue)="hideVPName($event)"
      [editTip]="trans.trans.renameTracker.value"
    >
    </app-edit-name>
  </div>
  <div class="col-md-2 pull-right">{{ trans.trans.assignedBy.value }}: {{ trackerData?.creators_name }}</div>
</div>
<div class="models_panel">
  <div class="panel-header">
    <div class="panel-title">
      {{ trans.trans.trackValueLongTitle.value }}
      <span class="clickable" (click)="chooseStep('valueposition', trackerData?.original_value_prop_id)">{{ trackerData?.original_value_prop_name }}</span>
    </div>

    <app-rsv2-toolbar
      *ngIf="valueProp"
      [showTranslate]="showTranslate"
      [isValueTracker]="true"
      [valueProp]="valueProp"
      [step]="1"
      [showAllTcoMetrics]="false"
      [businessReview]="businessReview"
      (triggerEditEmit)="triggerEdit()"
      [noExternalShare]="true"
      [businessReviewRequired]="true"
    ></app-rsv2-toolbar>
  </div>
  <div class="dashboard-layout">
    <div class="dropdown-container marg-v">
      <div>{{ trans.trans.longHelperText.value }}</div>
    </div>
    <div class="overlap-grid">
      <div class="tile-layout overlap-child">
        <div class="tile-container">
          <div class="tile">
            <div class="tile-title">{{ trans.trans.expected.value }} {{ valueProp?.term }} {{ trans.trans.yearValue.value }}</div>
            <div class="tile-value">{{ trackerData?.benefits.expected_benefits }}</div>
          </div>
          <div class="tile">
            <div class="tile-title">{{ trans.trans.expectedValueToDate.value }}</div>
            <div class="tile-value">{{ trackerData?.benefits.expected_to_date_benefits }}</div>
          </div>
        </div>
        <div class="tile-container">
          <div class="tile">
            <div class="tile-title">{{ trans.trans.achievedValueToDate.value }}</div>
            <div class="tile-value">{{ trackerData?.benefits.achieved_benefits }}</div>
            <div class="tile-percent" *ngIf="achievedPercentage && achievedPercentage !== 0" [class.warning]="achievedPercentage < 0">
              {{ achievedPercentage }}%
            </div>
            <div class="tile-icon" *ngIf="achievedPercentage && achievedPercentage !== 0" [class.warning]="achievedPercentage < 0">
              <span class="material-icons-outlined">{{ achievedPercentage > 0 ? 'arrow_upward' : 'arrow_downward' }}</span>
            </div>
          </div>
          <div class="tile">
            <div class="tile-title">{{ trans.trans.trendOverTime.value }}</div>
            <div class="tile-chart">
              <dl-chart
                *ngIf="trackerData"
                #pLineLogin
                idd="pLineLogin"
                type="line"
                [responsive]="true"
                height="40px"
                width="100%"
                [options]="optionsTrend"
                [data]="chartTrend"
              ></dl-chart>
            </div>
          </div>
        </div>
        <div class="tile-container">
          <div class="large-chart">
            <div class="chart-title">
              {{ trans.trans.expectedVsAchieved.value }}
            </div>
            <dl-chart *ngIf="chartTrendLarge" #pBar1 idd="pBar1" type="line" [options]="optionsTrendLarge" height="250px" width="100%" [data]="chartTrendLarge"></dl-chart>
          </div>
        </div>
        <div class="tile-container-border">
          <div class="chart-title">
            <ng-container *ngIf="barType === barTypes[1]; else benefit">{{ trans.trans.expectedVsByGoal.value }}</ng-container>
            <ng-template #benefit> {{ trans.trans.expectedVsByBenefit.value }}</ng-template>
          </div>
          <div class="flex-jcc w-100 select-buttons">
            <p-selectButton
              [options]="barTypes"
              [(ngModel)]="barType"
              (ngModelChange)="switchBarType()"
              styleClass="keywest_select_button select-button-tabs-small"
              optionDisabled="inactive"
            ></p-selectButton>
          </div>
          <div class="large-chart">
            <dl-chart *ngIf="chartBarLarge" #pBar2 idd="pBar2" type="bar" [options]="optionsBarLarge" height="220px" width="100%" [data]="chartBarLarge"></dl-chart>
          </div>
        </div>
      </div>
      <div class="loading-shade overlap-child" *ngIf="isLoading">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      </div>
    </div>

    <div class="margin-15-y" #tabElement>
      <app-track-value-tabs [valuePropId]="valuePropId" [valueProp]="valueProp"></app-track-value-tabs>
    </div>
  </div>
</div>
