import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';

import { GlobalSearchComponent } from './search.component';
import { Routes, RouterModule } from '@angular/router';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { GlobalSearchService } from '@data/services/search/search.service';
import { ProgressBarModule } from 'primeng/progressbar';

const routes: Routes = [
  {
    path: 'search/:term',
    component: GlobalSearchComponent,
  },
];

@NgModule({
  imports: [ProgressBarModule, EditTranslationsModule, TableModule, CommonModule, FormsModule, RouterModule.forChild(routes)],
  declarations: [GlobalSearchComponent],
  providers: [GlobalSearchService],
  entryComponents: [GlobalSearchComponent],
  exports: [GlobalSearchComponent],
})
export class GlobalSearchModule {}
