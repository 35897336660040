import { NgModule } from '@angular/core';

import { DLDragDropComponent } from './upload.component';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [AreYouSureModule, MatTooltipModule, TableModule, FormsModule, CommonModule],
  exports: [DLDragDropComponent],
  declarations: [DLDragDropComponent],
  providers: [],
})
export class DLDragDropModule {}
