import { Component, OnInit, OnDestroy, Input, } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { UserService } from '@data/services/user/user.service';
import { CommonService } from '@data/services/common/common.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { TeamDetailTranslation } from './team-detail.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { MenuItem } from 'primeng/api';
import { Privilege, TeamMember } from '@data/services/valueprop/models/teamMember.model';
import { Owner } from '@shared/models/owner.model';

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  providers: [UserService],
})
export class TeamDetailComponent implements OnInit, OnDestroy {
  @Input() defaultToSideBar = true
  memberId: string;
  modalReference: NgbModalRef;
  hideActionLoader = true;
  fullImagePath: string;
  teamMembersList: TeamMember[] = [];
  usersList: Owner[] = [];
  strSearch = '';
  username: Owner;
  userid: string;
  profilepicpath: string;
  showAddmember = false;
  showActionLoader = false;
  privilegeId: string;
  is_editable = '0';
  selectedItems = [];
  privilegesList: Array<Privilege> = [
    {
      privilegeId: '1',
      is_editable: '0',
      name: 'View',
    },
    {
      is_editable: '1',
      name: 'Edit',
      privilegeId: '2',
    },
  ];
  profilepicdefaultpath = 'https://media.value-cloud.com/images/default_user.png';

  subscriptiongetteamUsersList: Subscription;
  subscriptionCreateteammember: Subscription;
  subscriptiongetUsersList: Subscription;
  subscriptiondeleteTeamMember: Subscription;

  pageLimit = 10;
  rowLimit: MenuItem[] = [];

  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;

  constructor(
    private notification: NotificationService,
    private userService: UserService,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: TeamDetailTranslation,
    private styleService: StyleService
  ) {
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    if (this.defaultToSideBar) {
      this.addTeamMember();
    }
    this.funcBuildPageLimit();
    this.getTeamUsersListFunc();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  setRowLimit(num) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  ngOnDestroy() {
    if (this.subscriptiongetteamUsersList) {
      this.subscriptiongetteamUsersList.unsubscribe();
    }
    if (this.subscriptionCreateteammember) {
      this.subscriptionCreateteammember.unsubscribe();
    }
    if (this.subscriptiongetUsersList) {
      this.subscriptiongetUsersList.unsubscribe();
    }
    if (this.subscriptiondeleteTeamMember) {
      this.subscriptiondeleteTeamMember.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  ngOnSubmit(form: NgForm) {
    this.hideActionLoader = false;
    this.userid = form.value.username.id || this.usersList[0].id;
    this.privilegeId === '1' ? (this.is_editable = '0') : (this.is_editable = '1');
    const params = {
      team_member_id: this.userid,
      is_editable: this.is_editable,
    };
    const checkUserExist = this.teamMembersList.filter((item) => {
      return item.team_member_id === this.userid;
    });
    if (checkUserExist.length === 0) {
      this.subscriptionCreateteammember = this.userService.Createteammember(params).subscribe((response) => {
        form.reset();
        this.notification.success('Team member is added successfully', false);
        if (response.result && response.result != null && response.result.length > 0) {
          this.teamMembersList = response.result;
        } else {
          this.teamMembersList = [];
        }
      });
    } else if (checkUserExist.length > 0) {
      this.notification.error('Selected team member is already added. Please check the list', false);
    }
    this.hideActionLoader = true;
    this.showAddmember = false;
  }

  getTeamUsersListFunc() {
    this.userService.getteamUsersList().subscribe((response) => {
      if (response.result && response.result.team_members && response.result.team_members != null && response.result.team_members.length > 0) {
        this.teamMembersList = response.result.team_members;
      } else {
        this.teamMembersList = [];
      }
    });
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  editMember(user_id, editable) {
    const params = {
      team_member_id: user_id,
      is_editable: editable,
    };
    this.subscriptionCreateteammember = this.userService.Createteammember(params).subscribe((response) => {
      this.hideActionLoader = true;
      this.notification.success('Team member privilege is updated successfully', false);
      if (response.result && response.result != null && response.result.length > 0) {
        this.teamMembersList = response.result;
      } else {
        this.teamMembersList = [];
      }
      this.showAddmember = false;
    });
  }

  addTeamMember() {
    this.selectedItems = [];
    this.showAddmember = true;
    this.subscriptiongetUsersList = this.userService.getUsersList('').subscribe((result) => {
      if (result.result) {
        this.usersList = result.result.data.map((item) => {
          item['itemName'] = item['name'];
          return item;
        });
      }
    });
  }

  cancel() {
    this.showAddmember = false;
  }

  deleteMember(id) {
    this.subscriptiondeleteTeamMember = this.userService.deleteTeamMember(id).subscribe((response) => {
      if (response.result && response.result != null && response.result.length > 0) {
        this.teamMembersList = response.result;
      } else {
        this.teamMembersList = [];
      }
      this.notification.success('Team member is deleted successfully', false);
    });
  }
}
