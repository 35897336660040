import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { AddUserGroupPayload, UpdateUserGroupPayload, UserGroup } from '@shared/models/account-groups.models';
import { APIResult } from '@shared/models/api-result.model';
import { BasicUserData } from '@shared/models/user-info.model';
import { Observable } from 'rxjs';

@Injectable()
export class AdminUserGroupsService {
  serviceUrl: string;

  constructor(private commonService: CommonService) {
    this.serviceUrl = this.commonService.getServiceUrl();
  }

  public postGroup(accountId: string, payload: AddUserGroupPayload): Observable<APIResult<number>> {
    const endpoint = 'account/geo/' + accountId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getGroups(accountId: string): Observable<APIResult<UserGroup[]>> {
    const endpoint = 'account/geos/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public putGroup(accountId: string, payload: UpdateUserGroupPayload): Observable<APIResult<string>> {
    const endpoint = 'account/geo/' + accountId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteGroup(accountId: string, accountPainpointId: string): Observable<APIResult<string>> {
    const endpoint = 'account/geo/' + accountId + '/delete/' + accountPainpointId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public readUsersByGroup(userGroupId: string): Observable<APIResult<{ success: boolean; users: BasicUserData[] }>> {
    const endpoint = `account/user-group/${userGroupId}/users`;
    return this.commonService.getAPIService(endpoint);
  }
}
