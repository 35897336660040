import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NGFormSaveButtonComponent } from './save.component';
@NgModule({
  declarations: [NGFormSaveButtonComponent],
  entryComponents: [NGFormSaveButtonComponent],
  imports: [OverlayPanelModule, CommonModule, FormsModule, NgbModule],
  exports: [NGFormSaveButtonComponent],
})
export class NGFormSaveButtonModule {}
