import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
	@Input() solutionId: number;
	@Input() tabInit: number

	projects: any[] = [];

	subscriptionreadAllProjects: Subscription;
	subscriptiongetProjects: Subscription;
	subscriptiondeleteProject: Subscription;
	subscriptionupdateProject: Subscription;

	constructor(
		private solutionService: SolutionService,
		private modalService: NgbModal,
		private notification: NotificationService
	) { }

	ngOnInit() {
		this.getProjects();

		this.subscriptionreadAllProjects = this.solutionService.readAllProjects.subscribe(() => {
			this.getProjects();
		});
	}

	ngOnDestroy() {
		if (this.subscriptionreadAllProjects) { this.subscriptionreadAllProjects.unsubscribe(); }
		if (this.subscriptiongetProjects) { this.subscriptiongetProjects.unsubscribe(); }
		if (this.subscriptiondeleteProject) { this.subscriptiondeleteProject.unsubscribe(); }
		if (this.subscriptionupdateProject) { this.subscriptionupdateProject.unsubscribe(); }
	}

	getProjects() {
		this.subscriptiongetProjects = this.solutionService.getProjects(this.solutionId).subscribe((response) => {
			this.projects = [];
			if (response.result) {
				this.projects = response.result;
			}
		});
	}

	deleteProject(project) {
		this.subscriptiondeleteProject = this.solutionService.deleteProject(project.account_solution_id, project.account_project_type_id).subscribe((response) => {
			if (response.result) {
				this.getProjects();
				this.solutionService.triggerTabs.next('projects');
				this.notification.success(response.result, false); // Params {message, islogout}        
			}
		});
	}

	updateProject(project, active) {
		let payload = {
			"account_solution_project_type_id": project['id'],
			"account_project_type_id": project['account_project_type_id'],
			"active": active
		}


		this.subscriptionupdateProject = this.solutionService.updateProject(payload, project['account_solution_id']).subscribe((response) => {
			if (response.result && response.result.success) {
				this.getProjects();
				this.solutionService.triggerTabs.next('projects');
				this.notification.success(response.result.message, false); // Params {message, islogout}        
			}
		});
	}

}
