import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolutionValidationService } from '@data/services/validation/solution-validation.service';
import { BenefitValidationItem } from '@shared/models/benefits.models';
import { ValidationTranslations } from '../../validation.translations';
export type TranslationStrings = keyof typeof ValidationTranslations.prototype.trans;

@Component({
  selector: 'app-benefit-validation-table',
  templateUrl: './benefit-validation-table.component.html',
  styleUrls: ['./benefit-validation-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitValidationTableComponent {
  private solutionId: string;
  @Input() benefits: BenefitValidationItem[] = [];
  @Input() validationCategory: string;
  @Input() set trans(val: ValidationTranslations) {
    if (val) {
      this.t = val.t;
    }
  }

  public t: { [key in TranslationStrings]: string };

  constructor(private solutionValidationService: SolutionValidationService, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => (this.solutionId = params['id']));
  }

  public onSelected(item: BenefitValidationItem) {
    this.solutionValidationService.selectedValidationBenefit$.next(item);
  }

  public onAcknowledge(item: BenefitValidationItem) {
    const payload = {
      metrics: [
        {
          metric_id: +item.id,
          [this.validationCategory]: item.acknowledged ? 1 : 0,
        },
      ],
    };
    this.solutionValidationService.acknowledgeValidationBenefit(this.solutionId, payload).subscribe((res) => {
      if (res.result.status_code === 200) {
        this.solutionValidationService.validationErrorCount$.next(item.acknowledged ? -1 : 1);
      }
    });
  }
}
