import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { RealizationSurveysTranslations } from './survey.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { takeUntil } from 'rxjs/operators';
import { SurveyTemplateWithQuestions } from '@data/services/valueprop/models/survey-template';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, OnDestroy {
  @Input() activeSurveyTemplate: SurveyTemplateWithQuestions;
  @Input() realizaionIdInput;
  @Input() can_edit: boolean = false;
  showSurveysList = true;
  surveyId: string;

  surveys: any = [];
  survey: any[];
  valueRealizationId: number;
  loadSurveysTable = false;
  surveyDetailLoader = false;
  surveyName: string;
  quaters_in: number;
  quatersList: Array<any> = [];

  customSurveyDate: Date;

  fullImagePath: string;
  image_url: string;
  showActionLoader = false;
  account_question_template_id: number;
  assetUrl = '';
  assetGenerator = '';
  toggleSurveySettings = false;

  strSearch = '';
  pageLimit = 25;
  rowLimit = [];

  columns = [
    { field: 'name', header: 'Name' },
    { field: 'created', header: 'Survey Created Date' },
    { field: 'survey_name', header: 'Template' },
    { field: 'actions', header: 'Actions' },
  ];

  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private valueRealizationService: ValuerealizationService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationsV2Service,
    public trans: RealizationSurveysTranslations,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => this.changeUrl(params.id));
    this.loadSurveys();

    this.assetUrl = this.commonService.getAssetUrl();

    this.valueRealizationService.readSurveyList.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response) {
        this.openSurveyDetail(response);
      }
      this.loadSurveys();
    });

    this.setQuatersList();
    this.funcBuildPageLimit();

    if (this.realizaionIdInput) {
      this.columns.splice(2, 0, {
        field: 'custom_survey_date_fmt',
        header: 'Survey Effective Date',
      });
    }

    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  refreshTranslation() {
    this.getTranslations();
  }

  setRowLimit(num) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit == 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit == 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit == 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit == 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  changeUrl(id) {
    if (this.realizaionIdInput) {
      this.valueRealizationId = this.realizaionIdInput;
    } else {
      this.valueRealizationId = id;
      this.router.navigate(['valuerealization', id, 'survey']);
    }
  }

  setQuatersList() {
    for (let i = 1; i <= 20; i++) {
      this.quatersList.push({
        name: i + ' Quarter(s)',
        value: i,
      });
    }
  }

  loadSurveys() {
    this.loadSurveysTable = false;

    this.valueRealizationService
      .getValueRealizationSurveys(this.valueRealizationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.value_surveys) {
          this.surveys = response.result.value_surveys;
        } else {
          this.surveys = [];
        }

        this.loadSurveysTable = true;
      });
  }

  openSurveyDetail(survey) {
    this.surveyId = survey['id'];
    this.showSurveysList = false;
    this.account_question_template_id = survey['account_question_template_id'];
  }

  downAsset(survey) {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.valueRealizationService
      .getvalueRealizedTemplate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response && response !== null && response !== '') {
          const imageUrl =
            this.assetUrl +
            'assets/' +
            this.assetGenerator +
            '?account_asset_template_id=' +
            response.result.id +
            '&user_id=' +
            userId +
            '&token=' +
            token +
            '&value_prop_id=' +
            this.valueRealizationId +
            '&value_survey_id=' +
            survey.id;
          window.location.assign(imageUrl);
        } else {
          this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
        }
      });
  }

  delete(survey_id) {
    this.valueRealizationService
      .deleteSurvey(survey_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notificationService.success(response.result, false);
          this.valueRealizationService.readSurveyList.next();
        }
      });
  }

  openSurveySettings(survey) {
    this.surveyId = survey['id'];
    this.surveyName = survey['name'];
    this.quaters_in = parseInt(survey['quarters_in']);
    this.toggleSurveySettings = true;
    this.customSurveyDate = new Date(survey['custom_survey_date'].replace(/ /, 'T'));
  }

  updateSurveySettings() {
    let formattedDate = this.customSurveyDate.toISOString().replace(/T/, ' ');

    let params = {
      custom_survey_date: formattedDate,
      value_prop_id: this.valueRealizationId,
      name: this.surveyName,
      quarters_in: this.quaters_in,
      value_survey_id: this.surveyId,
    };

    this.valueRealizationService
      .updateSurveySettings(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notificationService.success('Survey Updated Successfully', false);
          this.valueRealizationService.readSurveyList.next();
          this.toggleSurveySettings = false;
        }
      });
  }

  closeCreateSurvey() {
    this.showSurveysList = true;
  }
}
