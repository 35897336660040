<p-confirmPopup></p-confirmPopup>

<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ benefit.metric_name }}
  </div>
  <div *ngIf="!benefitUpdated" class="close-icon" (click)="closeSidebarCallback()"><i class="pi pi-times"></i></div>

  <div class="sidebar_container">
    <div class="keywest_panel">
      <div class="row">
        <div class="col-xs-12" *ngIf="!loading">
          <p-accordion>
            <p-accordionTab [header]="trans.trans.benefitDetails.value">
              <div class="benefit-details-wrapper">
                <div class="section" *ngIf="benefit.description">
                  <span>{{ trans.trans.description.value }}</span>
                  <p>{{ benefit.description }}</p>
                </div>
                <div class="section" *ngIf="benefit.metric_note">
                  <span>{{ trans.trans.notes.value }}</span>
                  <p>{{ benefit.metric_note }}</p>
                </div>
                <div class="section" *ngIf="benefit.metric_note_2">
                  <span>{{ trans.trans.issues.value }}</span>
                  <p>{{ benefit.metric_note_2 }}</p>
                </div>
                <div *ngIf="benefit.metric_note_3">
                  <span>{{ trans.trans.resolutions.value }}</span>
                  <p>{{ benefit.metric_note_3 }}</p>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
          <div class="expected-section">
            <p-table
              #dt2
              dataKey="formula"
              [columns]="benefitTableColumns"
              [value]="benefitTableData"
              [resizableColumns]="true"
              [styleClass]="'keywest_table'"
              [tableStyleClass]="'keywest_table'"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [ngStyle]="{
                      'white-space': 'nowrap',
                      'width': col.width ? col.width : 'inherit',
                      'min-width': col.minwidth ? col.minwidth : 'inherit',
                      'font-weight': col.field === 'formula' ? '700' : 'normal',
                      'font-size': col.field === 'formula' ? '16px' : '13px'
                    }"
                  >
                    {{ col.header }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                <tr [ngClass]="{ total: rowData['type'] === 'total' }">
                  <td
                    *ngFor="let col of columns"
                    [ngStyle]="{ 'text-align': col.style, 'max-width': col.field === 'formula' ? '400px' : 'none', 'white-space': col.field === 'formula' ? 'normal' : 'nowrap' }"
                  >
                    <span *ngIf="(!col.editable || !rowData.editable) && col.type === 'improvement'">{{ rowData[col.field + '_fmt'] }}</span>
                    <span *ngIf="(!col.editable || !rowData.editable) && col.type !== 'improvement'">{{ rowData[col.field] }}</span>
                    <span *ngIf="col.editable && rowData.editable">{{ rowData[col.field + '_fmt'] }}</span>
                    <span *ngIf="rowData.description && col.field === 'formula'" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.description">
                      info
                    </span>

                    <div *ngIf="rowData.editing_name && col.field === 'formula'" class="input-group">
                      <input type="text" [(ngModel)]="rowData[col.field]" class="form-control" />
                      <div class="input-group-btn">
                        <span (click)="funcSaveFactorName(rowData)" matTooltip="Save" [class]="'clickable input-group-addon action-button'"><i class="fa fa-save"></i></span>
                        <span
                          (click)="rowData[col.field] = rowData.original_name; rowData.editing_name = !rowData.editing_name"
                          matTooltip="Cancel"
                          class="clickable input-group-addon action-button"
                        >
                          <i class="fa fa-times"></i>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                <tr>
                  <td [attr.colspan]="columns.length + 1">
                    <app-benefit-phasing [valueProp]="{ id: valuePropId }" [benefit]="valuePropBenefit" (reloadVP)="loadBenefitData(valuePropId)"> </app-benefit-phasing>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <div *ngIf="loading">
          <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
        </div>
      </div>

      <div class="row" *ngIf="!loading">
        <div class="col-md-12">
          <app-benefit-flyout-input-table
            [valuePropId]="valuePropId"
            [benefitData]="benefit"
            [toggleEditExpected]="editExpected"
            [resetChanges]="resetChanges"
            (update)="triggerUpdate()"
            (closeSidebar)="closeSidebarCallback()"
            (benefitUpdatedTrigger)="updateBenefitStatus($event)"
            (scratchpadToggledTrigger)="updateScratchpadOpen($event)"
          ></app-benefit-flyout-input-table>
          <div *ngIf="this.latestVariance < 0 && !!this.benefit.course_correction">
            <span class="course-correction">{{ trans.trans.courseCorrection.value }}</span
            >: {{ this.benefit.course_correction }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="!benefitUpdated && !scratchpadOpen; else saveButton">
        <button *ngIf="canEdit" type="submit" class="btn btn_customizable pull-right" (click)="toggleEdit()">
          {{ trans.trans.edit.value }}
        </button>
        <button class="btn cancel-confirm pull-right" (click)="closeSidebarCallback()">{{ trans.trans.back.value }}</button>
      </ng-container>
      <ng-template #saveButton>
        <button *ngIf="canEdit" type="submit" class="btn btn_customizable pull-right save" pButton (click)="save()" [loading]="loadingUpdate">
          {{ trans.trans.saveChanges.value }}
        </button>
        <button *ngIf="canEdit" (click)="confirm($event)" class="btn cancel-confirm pull-right">{{ trans.trans.cancelChanges.value }}</button>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="flex-jbetween notes-header">
          <div class="notes-title">
            <span *ngIf="brsWithNotes && brsWithNotes.length > 0">{{ trans.trans.notes.value }}</span>
          </div>
          <div>
            <button *ngIf="canEdit" class="btn btn_customizable pull-right" (click)="showNoteSidebar = true">{{ trans.trans.addOrEditNotes.value }}</button>
          </div>
        </div>
        <app-business-review-notes-table [valuePropId]="valuePropId" [disableEdit]="true" [objectId]="benefit.account_solution_metric_id" [section]="'benefit'"></app-business-review-notes-table>
      </div>
    </div>
  </div>

  <p-sidebar *ngIf="showNoteSidebar" [(visible)]="showNoteSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" [modal]="false">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.addNote.value }}
      </div>
      <div class="sidebar_container">
        <app-business-review-notes-sidebar
          [valuePropId]="valuePropId"
          (backClicked)="closeNotesSidebar()"
          [objectId]="benefit.account_solution_metric_id"
          [section]="'benefit'"
        ></app-business-review-notes-sidebar>
      </div>
    </div>
  </p-sidebar>
</div>
