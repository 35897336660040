disabled 3-23-2020 by CW during cleanup of angular2-multiselect - delete after 6-23-2020
<!-- <div class="modal-header">
	<h4 class="modal-title">{{ 'props.tabDetails.benefits.Add.AddYourBenefits' | translate }}
		<img class="actionLoader" *ngIf="showeditLoader && selectedBenefit" src="{{fullImagePath}}" />
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form name="addBenefitsForm" #addBenefitsForm="ngForm" (ngSubmit)="saveBenefits(addBenefitsForm)">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.benefitsName?.invalid && addBenefitsForm.controls.benefitsName?.touched)}">
					<label for="benefitsName" class="benefitsName labels">{{ trans.general.name_label | translate }}</label>
					<input type="text" class="form-control m-t-xxs" id="benefitsName" placeholder="Enter Name" name="benefitsName" [(ngModel)]="benefitsName" required>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for="benefitsDescription">{{ 'general.description-label' | translate }}</label>
					<textarea name="description" class="form-control m-t-xxs" id="benefitsDescription" placeholder="Description" [(ngModel)]="benefitsDescription"></textarea>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for="benefitsFeatures">{{ 'props.tabDetails.benefits.Add.Features' | translate }}</label>
					<textarea name="features" class="form-control m-t-xxs" id="benefitsFeatures" placeholder="Features And Capabilities" [(ngModel)]="benefitsFeatures"></textarea>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.annualBenefits?.invalid && addBenefitsForm.controls.annualBenefits?.touched)}">
					<label for="" class="labels">{{ 'props.tabDetails.benefits.Add.IsAnnualBenefits' | translate }}</label>
					<select class="form-control m-t-xxs" name="annualBenefits" [(ngModel)]="annualBenefits" required>
						<option *ngFor="let s of annualBenefitsList" [ngValue]="s.id">
							{{s.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.impact?.invalid && addBenefitsForm.controls.impact?.touched)}">
					<label for="" class="labels">{{ 'props.tabDetails.benefits.Add.Impact' | translate }}</label>
					<select class="form-control m-t-xxs" name="impact" [(ngModel)]="impact" required>
						<option *ngFor="let s of impactList" [ngValue]="s.id">
							{{s.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.solutionType?.invalid && addBenefitsForm.controls.solutionType?.touched)}">
					<label for="" class="labels">{{ 'props.tabDetails.benefits.Add.Solutions' | translate }}</label>
					<select class="form-control m-t-xxs" name="solutionType" [(ngModel)]="solutionType" #solution (change)="readValueCategories(solutionType)" required>
						<option *ngFor="let s of solutionsList" [ngValue]="s.account_solution_id">
							{{s.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.valueCategoryBenefits?.invalid && addBenefitsForm.controls.valueCategoryBenefits?.touched)}">
					<label for="valueCategoryBenefits" class="labels">{{ 'props.tabDetails.benefits.Add.WhichValueCategory' |
            translate }}</label>
					<select class="form-control m-t-xxs" id="valueCategoryBenefits" name="valueCategoryBenefits" [(ngModel)]="valueCategoryBenefits" required>
						<option *ngFor="let s of valueCategoryBenefitsList" [ngValue]="s.value_category_id">
							{{s.value_category}}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group benefits-scale" [ngClass]="{'has-error': (addBenefitsForm.controls.scale?.invalid && addBenefitsForm.controls.scale?.touched)}">
					<label for="" class="labels">{{ 'props.tabDetails.benefits.Add.DoesBenefitScale' | translate }}</label>
					<select class="form-control m-t-xxs" name="scale" [(ngModel)]="scale" required>
						<option *ngFor="let s of scaleList" [ngValue]="s.id">
							{{s.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.uniqueTypes?.invalid && addBenefitsForm.controls.uniqueTypes?.touched)}">
					<label for="" class="labels">{{ 'props.tabDetails.benefits.Add.Uniqueness' | translate }}</label>
					<select class="form-control m-t-xxs" name="uniqueTypes" [(ngModel)]="uniqueTypes" required>
						<option *ngFor="let s of uniqueList" [ngValue]="s.id">
							{{s.name}}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 impactBusinessWrapper">
				<div class="form-group">
					<div class="impactBusiness">
						<label for="" class="labels">{{ 'props.tabDetails.benefits.Add.ImpactAreas' | translate }}</label>
					</div>
					<div class="dropdown impactList">
						<button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">+
						</button>
						<ul class="dropdown-menu dropdown-menu-right">
							<li>
								<input type="text" class="form-control" placeholder="search" name="area" [(ngModel)]="searchText" />
							</li>
							<li *ngFor="let area of impactAreas | filter : searchText"> 
								<div class="checkbox custom-checkbox">
									<label>
										{{area.name}}
										<input type="checkbox" (click)="checkArea(area,$event)" [checked]="area.checked" />
										<span class="check"></span>
									</label>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="table-responsive col-sm-12" [ngClass]="{'has-error': (addBenefitsForm.controls.impactArea?.invalid && addBenefitsForm.controls.impactArea?.touched)}">
			<div class="searchTableContainer impactAreaContainer">
				<table class="table custom-table">
					<tbody>
						<tr class="grid-row" *ngFor="let impactArea of impactAreasList">
							<td name="impactArea" required>{{impactArea.name}}
								<div class="pull-right" (click)="impactAreaClose(impactArea)">&times;</div>
							</td>
						</tr>
						<tr class="grid-row" [hidden]="!noAreaSelect">
							<td>{{ 'props.tabDetails.benefits.Add.NoAreasSelected' | translate }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.driverFactor?.invalid && addBenefitsForm.controls.driverFactor?.touched)}">
					<label for="driverFactor" class="labels">{{ 'props.tabDetails.benefits.Add.DriverFactor' | translate }}</label>
					<div class="driverInline">
						<angular2-multiselect *ngIf="driverFactorList" [data]="driverFactorList" name="driverFactor" [(ngModel)]="driverFactor" [settings]="dropdownSettings" required>
						</angular2-multiselect>
					</div>
					<div class="btnInline">
						<a href="javascript:;" class="driverFactor" (click)="createFactor = !createFactor">
							<span class="icon-plus"></span>
						</a>
					</div>
				</div>
				<div class="form-group clearfix" id="createDriverFactor" *ngIf="createFactor">
					<label for="createDriverFactor" class="labels">{{ 'props.tabDetails.benefits.Add.CreateDriverFactor' |
            translate }}</label>
					<input type="text" name="createDriverFactor" class="form-control m-t-xxs driverInline" id="createDriverFactor" [(ngModel)]="newDriverFactor">
					<span class="icon-check btnInline" *ngIf="!showActionLoader" (click)="createDriverFactor()"></span>
					<span class="btnInline">
						<img class="actionLoader" *ngIf="showActionLoader" src="{{fullImagePath}}" />
					</span>
				</div>
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.driverBaselineAnswer?.invalid && addBenefitsForm.controls.driverBaselineAnswer?.touched)}">
					<label for="driverBaselineAnswer" class="labels">{{ 'props.tabDetails.benefits.Add.Answer' | translate }}</label>
					<input type="text" name="driverBaselineAnswer" class="form-control m-t-xxs" id="driverBaselineAnswer" name="driverBaselineAnswer" [(ngModel)]="driverBaselineAnswer" required>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<label>{{ 'props.tabDetails.benefits.Add.ImprovementFactor' |
            translate }} (Optional)</label>
					<div class="driverInline">
						<angular2-multiselect *ngIf="improvementTypeList"  [data]="improvementTypeList" name="selectedImprovementType" [(ngModel)]="selectedImprovementType" [settings]="improvementTypeSettings">
						</angular2-multiselect>
					</div>
					<div class="btnInline">
						<a class="financialFactor" href="javascript:;" (click)="createImprovementFlag = !createImprovementFlag">
							<span class="icon-plus"></span>
						</a>
					</div>
				</div>
				<div class="form-group clearfix" id="createImprovementFactor" *ngIf="createImprovementFlag">
					<label for="createImprovementFactor" class="labels">{{ 'props.tabDetails.benefits.Add.createImprovementFactor' |
            translate }}</label>
					<input type="text" name="createImprovementFactor" class="form-control m-t-xxs driverInline" id="createImprovementFactor" [(ngModel)]="newImprovementFactor">
					<span class="icon-check btnInline" *ngIf="!showImprovementActionLoader" (click)="createImprovementFactor()"></span>
					<span class="btnInline">
						<img class="actionLoader" *ngIf="showImprovementActionLoader" src="{{fullImagePath}}" />
					</span>
				</div>
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.unitType?.invalid && addBenefitsForm.controls.unitType?.touched)}">
					<label for="improvementFactor" class="labels">{{ 'props.tabDetails.benefits.Add.units' | translate }}</label>
					<select class="form-control m-t-xxs" name="unitType" [(ngModel)]="unitType" (change)="checkCurrentEffort()" required>
						<option *ngFor="let s of unitTypeList" [ngValue]="s.id">
							{{s.name}}
						</option>
					</select>
				</div>
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.conservative?.invalid && addBenefitsForm.controls.conservative?.touched)}">
					<label for="" class="labels">{{ 'props.tabDetails.benefits.Add.ImprovementConservative' | translate }}</label>
					<input type="text" name="conservative" class="form-control m-t-xxs" id="" [(ngModel)]="conservative" required>
				</div>
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.probable?.invalid && addBenefitsForm.controls.probable?.touched)}">
					<label for="probable" class="labels">{{ 'props.tabDetails.benefits.Add.Probable' | translate }}</label>
					<input type="text" name="probable" class="form-control m-t-xxs" id="probable" placeholder="" [(ngModel)]="probable" required>
				</div>
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.aggressive?.invalid && addBenefitsForm.controls.aggressive?.touched)}">
					<label for="aggressive" class="labels">{{ 'props.tabDetails.benefits.Add.Aggressive' | translate }}</label>
					<input type="text" name="aggressive" class="form-control m-t-xxs" id="aggressive" placeholder="" [(ngModel)]="aggressive" required>
				</div>
				<div *ngIf="accountFeatures && showCurrentEffort" class="form-group">
					<label for="">{{ 'props.tabDetails.benefits.Add.CurrentEffort' | translate }}</label>
					<input type="text" class="form-control m-t-xxs" id="currentEffort" placeholder="Enter Current Effort" name="currentEffort" [(ngModel)]="currentEffort">
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.financialDriverFactor?.invalid && addBenefitsForm.controls.financialDriverFactor?.touched)}">
					<label for="financialFactorType" class="labels">{{ 'props.tabDetails.benefits.Add.FinancialFactorType' |
            translate }}</label>
					<div class="driverInline">
						<angular2-multiselect *ngIf="financialDriverFactorList" class="driverInline" [data]="financialDriverFactorList" name="financialDriverFactor" [(ngModel)]="financialDriverFactor" [settings]="dropdownSettings1" required>
						</angular2-multiselect>
					</div>
					<div class="btnInline">
						<a class="financialFactor" href="javascript:;" (click)="createDriverFlag = !createDriverFlag">
							<span class="icon-plus"></span>
						</a>
					</div>
				</div>
				<div class="form-group clearfix" id="createFinancialFactor" *ngIf="createDriverFlag">
					<label for="createFinancialFactor" class="labels">{{ 'props.tabDetails.benefits.Add.CreateFinancialFactor' |
            translate }}</label>
					<input type="text" name="createFinancialFactor" class="form-control m-t-xxs driverInline" id="createFinancialFactor" [(ngModel)]="FinancialFactor">
					<span class="icon-check btnInline" *ngIf="!showActionLoader" (click)="createFinancialFactor()"></span>
					<span class="btnInline">
						<img class="actionLoader" *ngIf="showActionLoader" src="{{fullImagePath}}" />
					</span>
				</div>
				<div class="form-group" [ngClass]="{'has-error': (addBenefitsForm.controls.financicalAnswer?.invalid && addBenefitsForm.controls.financicalAnswer?.touched)}">
					<label for="financicalAnswer" class="labels">{{ 'props.tabDetails.benefits.Add.Answer' | translate }}</label>
					<input type="text" name="financicalAnswer" class="form-control m-t-xxs" id="financicalAnswer" [(ngModel)]="financicalAnswer" required>
				</div>
			</div>
		</div>
	
		<button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="activeModal.close('Cross click');saveBenefits(addBenefitsForm)" [disabled]="addBenefitsForm.invalid">{{ 'general.save' | translate }}</button>
		<div class="pull-right">
			<label class="labelsRequired">{{ 'general.isRequired' | translate }}</label>
		</div>
	</form>
</div> -->