import { Injectable } from '@angular/core';

@Injectable()
export class ValuePropFlowButtonsTranslations {
  public config: any = {
    component: 'value-prop-flow-buttons',
    display_name: 'Rep Simple Flow Buttons',
    description: '',
  };
  public trans: any = {
    continue: 'Continue',
    back: 'Back',
    cancel: 'Cancel',
    reviewResults: 'Review Results'
  };
}
