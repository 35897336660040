import { Injectable } from '@angular/core';

@Injectable ()

export class DlAdminTranslation {

    public config: any = {
        component: 'dlAdmin',
        display_name: 'Xfactor.io Admin',
        description: ''
    };

    public trans: any = {
        create_a_new_account: 'Create A New Account',
		impersonate_a_user: 'Impersonate A User',
		create_new_faq: 'Create New FAQ'
    };
}
