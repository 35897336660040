import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FactorsService } from '@data/services/factors/factors.service';
import { DefaultTranslations } from '../ae_factors.translation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SituationOnFactor } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-factors-situation-table',
  templateUrl: './factors-situation-table.component.html',
  styleUrls: ['./factors-situation-table.component.scss']
})
export class FactorsSituationTableComponent implements OnInit, OnDestroy {

  @Input() factorId: string;
  @Input() trans: DefaultTranslations;

  public situations: SituationOnFactor[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(private factorService: FactorsService) { }

  ngOnInit(): void {
    if(this.factorId) {
      this.factorService.getFactorSituationUsage(this.factorId) // 552 or 181 to test or factorId
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if(data && data.result && data.result.data) {
          this.situations = data.result.data;
          this.expandAll();
        }
      });
    }
  }

  public expandAll(): void {
    this.situations.forEach(node => this.expandCollapseRecursive(node, true));
  }

  public collapseAll(): void {
      this.situations.forEach(node => this.expandCollapseRecursive(node, false));
  }

  private expandCollapseRecursive(node: SituationOnFactor, isExpand: boolean): void {
      node.expanded = isExpand;
      if (node.children) {
          node.children.forEach(childNode => this.expandCollapseRecursive(childNode as any, isExpand));
      }
    }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}