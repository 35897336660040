import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2CapabilitiesTranslations {
  public config: any = {
    component: 'capabilities',
    display_name: 'Capabilities',
    description: 'Capabilities '
  };

  public trans: any = {
    capabilities: 'Capabilities',
    add_new: 'Add new',
    delete: 'Delete',
    edit: 'Edit',
    search: 'Search',
    ch_view: 'View',
    are_you_sure: 'Are you sure?'
  };
}
