import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { ColumnMeta } from '@shared/models/common.models';
import { SolutionCategory } from '@data/services/solution/models/solution-category.interface';
import { Rsv2ScModelsTranslations } from './rsv2-sc-models.translation';
import { Solution } from '@data/services/solution/models/vp-solution.interface';

@Component({
  selector: 'app-rsv2-sc-models',
  templateUrl: './rsv2-sc-models.component.html',
  styleUrls: ['./rsv2-sc-models.component.scss'],
  providers: [
    Rsv2ScModelsTranslations
  ]
})
export class RSV2SCModelsComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  @Output() reloadVP = new EventEmitter();
  @Output() showNext = new EventEmitter();

  ValuePropId: string;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  hasModels = false;

  canEdit: boolean;
  showCategoryTypeLoader: boolean;
  selectedSolutionsLength: number;
  availableSolutions: Solution[] = [];
  solutionCategoryList: SolutionCategory[];
  selectedSolutionCategoryId: string;
  showModelsLoader: boolean;
  selectedModels: { solutionId: number | string; quantity: number | string }[] = [];
  hasRadioButtonFeature = false;
  showModelsEditing = false;
  showActionLoaderModel = false;
  crm: string;
  style2022$: Observable<boolean>;
  strSearch = '';

  cols: ColumnMeta[] = [
    { field: 'name', header: 'Model Name'}
  ] ;

  fullImagePath = '';
  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: Rsv2ScModelsTranslations,
    private solutionService: SolutionService,
    private valuepositionService: ValuepositionService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private styleService: StyleService,
    private workflowService: AccountWorkflowService
  ) {}

  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.style2022$ = this.styleService.style2022;
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.ValuePropId = params['vpid'];
    });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.fullImagePath = this.commonService.getLoaderImageUrl();
    this.getAvailableSolutions();
    this.hasRadioButtonFeature = this.commonService.checkFeature(54);
    this.canEdit = this.valueProp.vp_can_edit;

    this.workflowService.workflowRefreshModel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((reload) => {
      if (reload) {
        this.getAvailableSolutions();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.cols[0].header = this.trans.trans.nameHeader.value;
      });
  }

  getSolution(categoryId: string): void {
    this.showCategoryTypeLoader = true;
    this.selectedSolutionsLength = 0;
    if (this.availableSolutions) {
      this.availableSolutions = this.availableSolutions.map((item) => {
        item.selectedCategory = false;
        if (item.solution_category_id != null && item.solution_category_id.indexOf(categoryId) >= 0) {
          this.selectedSolutionsLength++;
          item.selectedCategory = true;
        }
        return item;
      });
    }

    this.showCategoryTypeLoader = false;
    this.strSearch = '';
  }

  getSolutionCategoryList(): void {
    this.solutionService
      .getSolutionCategoryListwithVPID()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.solutionCategoryList = result.result;
        if (this.solutionCategoryList && this.solutionCategoryList.length > 0) {
          this.selectedSolutionCategoryId = this.solutionCategoryList[0]['id'];
          this.getSolution(this.selectedSolutionCategoryId);
        }
        this.showModelsLoader = false;
      });
  }

  getAvailableSolutions(): void {
    this.hasModels = false;
    this.showModelsLoader = true;

    this.solutionService
      .getAvailableSolutionsPlain(this.ValuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.availableSolutions = result.result;
        if (this.availableSolutions) {
          this.availableSolutions = this.availableSolutions.map((item) => {
            item['quantity'] = 1;
            return item;
          });
        }
        if (this.availableSolutions.length === 1) {
          this.availableSolutions = this.availableSolutions.map((item) => {
            item['quantity'] = 1;

            return item;
          });
        }

        this.getSolutionCategoryList();
        this.getSolutionsUsedInValueProps();
      });
  }

  quantityAssign(id, quantityValue): void {
    const quantityCheck = this.selectedModels.filter((item) => {
      return item['solutionId'] === id;
    });

    if (quantityCheck.length > 0) {
      if (this.selectedModels && this.selectedModels.length) {
        for (let i = 0; i < this.selectedModels.length; i++) {
          if (this.selectedModels[i]['solutionId'] === id) {
            this.selectedModels[i]['quantity'] = quantityValue;
          }
        }
      }
    }
    this.createSolution();
  }

  checkModels(modelId, quantity, isChecked): void {
    if (this.hasRadioButtonFeature) {
      this.availableSolutions.map((solution) => {
        if (solution.id !== modelId) {
          solution.isChecked = false;
        }
        return solution;
      });
      this.selectedModels = [];
      const tempModel = {
        solutionId: modelId,
        quantity: quantity,
      };
      this.selectedModels.push(tempModel);

    } else {
      if (isChecked) {
        const tempModel = {
          solutionId: modelId,
          quantity: quantity,
        };
        this.selectedModels.push(tempModel);
      } else {
        this.selectedModels = this.selectedModels.filter((impact: any) => {
          return impact['solutionId'] !== modelId;
        });
      }
    }
  }

  quantityEnabled(event): void {
    event.preventDefault();
  }

  updateSolutions(): void {
    this.getAvailableSolutions();
  }

  closeSolution(): void {
    this.getAvailableSolutions();
    this.showModelsEditing = false;
  }

  createSolution(): void {
    this.showActionLoaderModel = true;
    if (this.selectedModels) {
      const selectedModels = this.selectedModels.map((model) => {
        return model['solutionId'];
      });

      const selectedQuantity = this.selectedModels.map((model) => {
        return model['quantity'];
      });

      this.showActionLoaderModel = true;

      const payload = {
        account_solution_id: selectedModels.join(','),
        value_prop_id: this.ValuePropId,
        quantity: selectedQuantity.join(','),
        delete_vp_solutions: 1,
      };

      this.valuepositionService
        .quickCreateSolution(this.ValuePropId, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          if (result.result) {
            this.showActionLoaderModel = false;
            this.showModelsEditing = false;
            this.getSolutionsUsedInValueProps();
            this.reloadVP.emit();
            this.notificationService.success(this.trans.trans.solutionsAssignedMessage.value, false);
          }

          this.strSearch = '';
        });
    }
  }

  getSolutionsUsedInValueProps(): void {
    this.hasModels = false;
    this.valuepositionService
      .getValuePropSolutions(this.ValuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          const selectedSolutions = response.result;
          const solutionIds = selectedSolutions.map((solution) => solution['account_solution_id']);
          if (this.availableSolutions && this.availableSolutions.length) {
            this.availableSolutions.forEach((solution) => {
              const index = solutionIds.indexOf(solution['id']);
              if (index !== -1) {
                this.selectedModels.push({
                  solutionId: solution['id'],
                  quantity: selectedSolutions[index]['quantity'],
                });

                solution['isChecked'] = true;
                this.hasModels = true;

                solution['quantity'] = selectedSolutions[index]['quantity'];
              }
            });
          }
        }

        this.showNext.emit(this.hasModels);
      });
  }

  selectedCategorySolutions(solutions: any[]) {
    return solutions.filter((solution) => solution.selectedCategory);
  }

  filterSolutions(solutions: any, strSearch = '') {
    return solutions.filter((solution) => solution.name.toLowerCase().includes(strSearch.toLowerCase()));
  }
}
