<div [ngClass]="{ sidebar_wrapper: isSidebar, models_panel: !isSidebar }">
  <div [ngClass]="{ sidebar_header: isSidebar, keywest_header: !isSidebar }">
    <div [class.title]="!isSidebar">
      {{ trans.trans.discoveryQuestions.value }}
    </div>
  </div>
  <div [ngClass]="{ sidebar_container: isSidebar, models_page_header_container_small: !isSidebar }">
    <div class="keywest_panel">
      <p-fieldset id="required" [legend]="trans.trans.required.value" *ngIf="questionsRequired && questionsRequired.length">
        <div class="row" *ngFor="let question of questionsRequired">
          <div class="col-xs-12">
            <label class="marg-v"
              >{{ question.name }}
              <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="question.description"> info </span>
            </label>
            <span [ngSwitch]="question.question_type_id">
              <div *ngSwitchCase="1">
                <input type="text" [disabled]="locked" name="type1" [(ngModel)]="question.answer" class="form-control" />
              </div>
              <div *ngSwitchCase="2">
                <textarea name="type2" [disabled]="locked" [(ngModel)]="question.answer" rows="3" class="form-control"></textarea>
              </div>
              <div *ngSwitchCase="3">
                <p-inputSwitch name="type3" [disabled]="locked" [(ngModel)]="question.answer"></p-inputSwitch>
              </div>
              <p *ngSwitchDefault></p>
            </span>
          </div>
        </div>
      </p-fieldset>

      <p-fieldset [legend]="trans.trans.dq.value" [toggleable]="true" *ngIf="questionsOptional && questionsOptional.length">
        <div class="row" *ngFor="let question of questionsOptional">
          <div class="col-xs-12">
            <label class="marg-v"
              >{{ question.name }}
              <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="question.description"> info </span>
            </label>
            <span [ngSwitch]="question.question_type_id">
              <div *ngSwitchCase="1">
                <input type="text" [disabled]="locked" name="type1" [(ngModel)]="question.answer" class="form-control" />
              </div>
              <div *ngSwitchCase="2">
                <textarea name="type2" [disabled]="locked" [(ngModel)]="question.answer" rows="3" class="form-control"></textarea>
              </div>
              <div *ngSwitchCase="3">
                <p-inputSwitch name="type3" [disabled]="locked" [(ngModel)]="question.answer"></p-inputSwitch>
              </div>
              <p *ngSwitchDefault></p>
            </span>
          </div>
        </div>
      </p-fieldset>

      <div *ngIf="!questionsRequired.length && !questionsOptional.length && !questionLoader" class="row form-group">
        <div class="col-xs-12">
          <b>{{ trans.trans.nothing.value }}</b>
        </div>
      </div>
      <div *ngIf="questionLoader" class="row">
        <div class="col-xs-12"><img class="loader" [src]="fullImagePath" /></div>
      </div>
    </div>
  </div>
  <div [ngClass]="{ row: crm || !isSidebar, btn_container: !crm && isSidebar }">
    <div class="col-xs-12">
      <img *ngIf="saveLoader" class="loader" [src]="fullImagePath" />
      <a
        *ngIf="!saveLoader && (questionsRequired.length || questionsOptional.length) && !locked"
        (click)="funcSaveQuestions()"
        class="btn btn_customizable xf-continue-button"
        [class.pull-right]="isSidebar"
        >{{ trans.trans.save.value }}</a
      >
      <a
        *ngIf="!saveLoader && !questionsRequired.length && !questionsOptional.length && !locked"
        (click)="funcSaveQuestions()"
        class="btn btn_customizable"
        [class.pull-right]="isSidebar"
        >{{ trans.trans.next.value }}</a
      >
      <app-are-you-sure
        #scalerCancel
        *ngIf="isSidebar && !saveLoader"
        matTooltipPosition="above"
        [message]="trans.trans.areYouSure.value"
        class="btn cancel-confirm pull-right marg-h"
        icon=""
        [text]="trans.trans.back.value"
        (callbackYes)="closeDiscovery()"
      ></app-are-you-sure>
    </div>
  </div>
</div>
