import { Injectable } from '@angular/core';

@Injectable ()

export class AssetsVpDashTranslations {

    public config: any = {
        component: 'assetsVpDash',
        display_name: 'Value Prop Assets',
        description: 'Assets tab in value prop dashboard'
    };

    public trans: any = {
        ch_assets: 'Sales-ready Assets usable in different sales stages.',
        title: 'Assets',
		name: 'Name',
    };
}
