import { Injectable } from "@angular/core";

@Injectable()
export class AddNewCompanyTranslations {
    public config: any = {
      component: 'addCompony',
      display_name: 'Add New Company',
      description: 'Adds a new company'
    };
  
    public trans: any = {
      basic: 'Basic',
      addtionalInfo: 'Addtional Info',
      finish: 'Finish',
      addACompany: 'Add A Company',
      optional: 'Optional',
      name: 'Name',
      company: 'Company',
      industrySector: 'Industry Sector',
      industry: 'Industry',
      country: 'Country',
      revenueInMillions: 'Revenue in Millions',
      revenue: 'Revenue',
      addLogo: 'Add Logo',
      clickToDrag: 'Click or drag file to this area to upload',
      cancel: 'Cancel',
      next: 'Next',
      back: 'Back',
      inputPlaceholder: 'Input Placeholder',
      selectOne: 'Select One',
      enterRevenue: 'Enter Revenue',
      description: 'Description',
      url: 'Url',
      wrongUrlFormat: 'Url is not valid',
      noDecimal: 'Value cannot include a decimal',
      employees: 'Employees',
      address: 'Address',
      city: 'City',
      state: 'State',
      postalCode: 'Postal Code',
      addCompany: 'Add Company',
      accountId: 'Account ID',
      sector: 'Sector',
      nameRequired: 'Company name is required',
      industryRequired: 'Industry is required',
      industrySectorRequired: 'Industry sector is required',
      countryRequired: 'Country is required',
      revenueRequired: 'Revenue is required ',
      companyCreated: 'Company was created successfully',
      fileNotUploaded: 'File could not be uploaded.'
    };
  }
  