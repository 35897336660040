import { NgModule } from '@angular/core';

import { ScalersComponent } from "./scalers.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; 
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import {DropdownModule} from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		ScalersComponent,
	],
	imports: [
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		DropdownModule,
		TableModule,
		SidebarModule,
		MenuModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	providers: [
	],
	entryComponents: [
		ScalersComponent,
	],
	exports: [ScalersComponent]
})
export class ModelScalersModule { }