<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>How does your selection solve the customers problem?</div>
				</div>
			</div>
		</div>
		<div class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<small style='font-size: 70%;'>{{1200 - valueProp.vmw_q_2.length}} of 1200 characters left</small>
				<textarea name='q2answer' maxlength="1200" [(ngModel)]="valueProp.vmw_q_2" class='form-control' rows='3'></textarea>
			</div>
		</div>
		<div class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<a (click)='funcSaveQ2()' class='btn btn_customizable'>Save</a>
			</div>
		</div>
	</div>
</div>