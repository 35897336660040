import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { AssetsVpDashTranslations } from './assets.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { Assets } from '@shared/models/value-prop.model';
import { ColumnMeta } from '@shared/models/common.models';
import { StyleService } from 'app/style.service';
import { AssetTemplate } from '@shared/models/asset.models';

@Component({
	selector: 'app-assets',
	templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit, OnDestroy {
	@Input() showHelp: boolean;
	@Input() contextualHelp: boolean;
	@Input() valueProp: any;
	@Input() valuePropId: string | number;
	@Input() tabInit: number;
	@Input() showTranslate = false;
    @Input() showAllTcoMetrics = false;

	ngUnsubscribe: Subject<any> = new Subject();
	assets: AssetTemplate[] = [];
	assetsLoader = false;
	fullImagePath: string;
	userId: string;
	token: string;
	readValuePropAssetTemplatesSubscription: Subscription = new Subscription();
    featureShowMoreTcoCosts = false;

	assetUrl = "";
	assetGenerator = "";

	cols: ColumnMeta[] = [
		{ field: "name", header: "Name" }
	];
	style2022$: Observable<boolean>;

	constructor(
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		public trans: AssetsVpDashTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {
		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
		this.userId = sessionStorage.getItem('uid');
		this.token = sessionStorage.getItem('vcu');

		this.readAssets();
		this.assetUrl = this.commonService.getAssetUrl();
		this.assetGenerator = sessionStorage.getItem('asset_gen_file');

        this.featureShowMoreTcoCosts = this.commonService.checkFeature(76);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	refreshTranslation() {
		this.getTranslations();
	}

	// Fix to call API's on tab first time load alone and skip next loads
	ngOnChanges() {
		// if (this.tabInit == 1) {
		// 	this.readAssets();
		// }
	}

	readAssets() {
		this.assetsLoader = true;
		this.valuepropService.readValuePropAssetTemplates(this.valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.assets = [];
			if (result.result) {
				this.assets = result.result.map((asset) => ({
                    ...asset,
                    url: this.getAssetUrl(asset),
                    pdfUrl: this.getAssetUrl(asset, asset.pdf == '1')
                }));
			}
			this.assetsLoader = false;
		});
	}

	downloadAsset() {

	}

    getAssetUrl(asset: any, pdf: boolean = false): string {
		let public_key = '';
		if(this.valueProp.calculator_public_key && this.valueProp.calculator_public_key !== '') {
			public_key = this.valueProp.calculator_public_key;
		}
        let url = this.assetUrl + 'assets/' + this.assetGenerator + '?account_asset_template_id=' + asset.account_asset_template_id + '&user_id=' + this.userId + '&token=' + this.token + '&value_prop_id=' + this.valueProp.id + public_key;
        if(pdf) {
            url = url + '&pdf=1';
        }
        if (this.featureShowMoreTcoCosts && this.showAllTcoMetrics) {
            url = url + '&tco_include_all=1'
        }
        return url;
    }

	getFAIcon(format) {
		switch (format) {
			case "MS Word":
				return "fa fa-file-word-o"

			case "MS Excel":
				return "fa fa-file-excel-o"

			case "MS PowerPoint":
				return "fa fa-file-powerpoint-o"

			case "MS PDF":
				return "fa fa-file-pdf-o"

			default:
				return "fa fa-cloud-download"

		}
	}

	getFAColor(format) {
		switch (format) {
			case "MS Word":
				return { "color": "#2e5b97" };

			case "MS Excel":
				return { "color": "#3a6e46" };

			case "MS PowerPoint":
				return { "color": "#c55737" };

			case "MS PDF":
				return { "color": "#c60a09" };

			default:
				return { "color": "black" }
		}
	}
}
