import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AESCrypto {
  constructor() {}

  encrypt(value, key) {
    return CryptoJS.AES.encrypt(value, key).toString();
  }

  decrypt(textToDecrypt, key) {
    return CryptoJS.AES.decrypt(textToDecrypt, key);
  }
}
