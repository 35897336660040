import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactorsService } from '@data/services/factors/factors.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { ColumnMeta } from '@shared/models/common.models';
import { FactorCategoriesResponse } from '@shared/models/factors.models';
import { GoalGroup, Goal, ConnectedObjectWithoutResult } from '@shared/models/goal-group.model';
import { Table } from 'primeng/table';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { GoalGroupTranslations } from './goal-groups.translation';

@Component({
  selector: 'app-goal-groups',
  templateUrl: './goal-groups.component.html',
  styleUrls: ['./goal-groups.component.scss'],
})
export class GoalGroupsComponent implements OnInit, OnDestroy {
  @Input() canEdit: boolean;
  @ViewChildren(Table) tableList: QueryList<Table>;
  @ViewChild('closeOverlay') closeOverlay: ElementRef;

  ngUnsubscribe$ = new Subject();
  showTranslate = false;
  isLoading = false;
  cols: ColumnMeta[] = [];

  goalGroups: GoalGroup[] = [];
  goal: Goal;
  search = new FormControl('');
  goalGroups$ = new BehaviorSubject([]);
  isSidebarOpen = false;
  aeElement: 'goal' | 'goal-group' | 'scratchpad' = 'goal-group';
  accountId: string;
  unassignedId = 0;

  factorGroups: FactorCategoriesResponse[] = [];

  goalGroupSelected: GoalGroup;
  goalSelected: Goal;
  linkedBenefitsId: string[] = [];

  constructor(
    private translationsService: TranslationsV2Service,
    private goalGroupsService: GoalGroupsService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    public trans: GoalGroupTranslations
  ) {}

  ngOnInit(): void {
    this.accountId = sessionStorage.getItem('aid');

    this.getGroups();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getGroups(): void {
    this.isLoading = true;

    this.goalGroupsService
      .getGoalGroups()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((goalGroups) => {
        this.goalGroups = goalGroups;
        this.linkedBenefitsId = [];
        goalGroups.forEach((group) => {
          group.factors.forEach((factor) => {
            if (factor.connected_object_id){
              (factor.connected_object_id as ConnectedObjectWithoutResult[]).forEach((object) => this.linkedBenefitsId.push(object.connected_object_id));
            }
          })
        });
        this.goalGroups$.next(this.goalGroups);

        this.search.valueChanges
          .pipe(
            startWith(''),
            debounceTime(100),
            takeUntil(this.ngUnsubscribe$),
            map((value) => this.filterBySearch(value)),
            tap((value) => {
              this.goalGroups$.next(value);
            })
          )
          .subscribe();
      });
  }

  private filterBySearch(value: string): GoalGroup[] {
    if (!value) {
      this.tableList.forEach((table) => table.filterGlobal('', 'contains'));

      return this.goalGroups;
    }

    return this.goalGroups.filter((goalGroup) => {
      const groupNameMatches = goalGroup.name.toLowerCase().includes(value.toLowerCase());

      if (!groupNameMatches) {
        const goalNameMatches = goalGroup.factors.some((goal) => goal.name.toLowerCase().includes(value.toLowerCase()));

        if (goalNameMatches) {
          // Using timeout to ensure filtering table is rendered
          setTimeout(() => {
            const tableGroup = this.tableList.find((table) => table.dataKey === `table-${goalGroup.id}`);

            if (tableGroup) {
              tableGroup.filterGlobal(value, 'contains');
            }
          });

          return true;
        }

        return false;
      }

      const tableGroup = this.tableList.find((table) => table.dataKey === `table-${goalGroup.id}`);

      if (tableGroup) {
        tableGroup.filterGlobal('', 'contains');
      }

      return true;
    });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationsService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationsService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.cols = [
          { field: 'name', header: this.trans.trans.name.value },
          { field: 'connected_factors_seprated_by_commas', header: this.trans.trans.connectedFactors.value },
          { field: 'created', header: this.trans.trans.createdOn.value },
        ]
      });
  }

  onDeleteGoal(goal: Goal) {
    this.isLoading = true;

    this.goalGroupsService
      .deleteGoal(goal.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (result) => {
          if ( result.result.success === true ) {
            this.notificationService.success(this.trans.trans.deleteGoal.value, false);

            this.goalGroups = this.goalGroups.map((group) => {
              return {
                ...group,
                factors: group.factors.filter((factor) => factor.id !== goal.id),
              };
            });

            this.goalGroups$.next(this.goalGroups);
          } else {
            this.notificationService.error(this.trans.trans.requestError.value, false);
          }
        }
      );
  }

  onDeleteGoalGroup(goalGroup: GoalGroup) {
    this.isLoading = true;

    this.goalGroupsService
      .deleteGoalGroup(this.accountId, goalGroup.id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.notificationService.success(this.trans.trans.deleteGoalGroup.value, false);
          this.getGroups();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  onOpenGoalGroup(goalGroup?: GoalGroup): void {
    this.closeOverlay.nativeElement.click();
    this.aeElement = 'goal-group';
    this.goalGroupSelected = goalGroup;
    this.isSidebarOpen = true;
  }

  onOpenGoal(goal?: Goal): void {
    this.closeOverlay.nativeElement.click();
    this.aeElement = 'goal';
    this.goalSelected = goal;
    this.isSidebarOpen = true;
  }

  groupIdentity(_idx: number, group: GoalGroup): string {
    return group.id;
  }

  openScratchpad(goal: Goal): void {
    this.aeElement = 'scratchpad';
    this.isSidebarOpen = true;
    this.goal = goal;
  }
}
