import { Injectable } from '@angular/core';

@Injectable()
export class ScalersVpDashTranslations {
  public config: any = {
    component: 'scalersVpDash',
    display_name: 'Value Prop Scalers',
    description: 'Scalers tab in value prop dashboard',
  };

  public trans: any = {
    scalers: 'Scalers',
    ch_scalers: `The determining factors of sizing your customer.
        Often these will influence how large or small your value and costs are for a given account.`,
    save: 'Save',
    explain_changes: 'Explain changes',
    primary_scalers: 'Primary Scalers',
    calculate: 'Calculate',
    all_scalers: 'All Scalers',
    viewIncluded: 'Click to view included benefits',
    isRequired: 'is required',
    scalersUpdated: 'Scalers updated',
  };
}
