import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-maintenance-situationsv1',
  templateUrl: './situations.component.html',
  styleUrls: ['./situations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaintenanceSituationsComponent implements OnInit, OnDestroy {
  @Input() solutionId: number | string;
  fullImagePath: string;

  situations: { [klass: string]: any }[] = [];
  situationTree: { [klass: string]: any }[] = [];

  toggleSituationAddEdit = false;

  objSituation = {
    name: '',
    description: '',
    type: '',
    id: '',
    situation_type_id: '',
  };

  mode = 'add';
  modeChild = 'add';

  currentSituationId = '';

  accountId: string;
  ngUnsubscribe = new Subject();
  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    public trans: Translations,
  ) {
    const imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.accountId = sessionStorage.getItem('aid');
    this.funcGetSituationList();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  funcAddSituation() {
    this.mode = 'add';
    this.objSituation = {
      name: '',
      description: '',
      type: '',
      id: '',
      situation_type_id: '',
    };
    this.toggleSituationAddEdit = true;
  }

  funcToggleAddEdit(elem: { [klass: string]: any }) {
    this.mode = 'edit';

    this.objSituation = {
      name: elem.name,
      description: elem.description,
      type: elem.element_type_id,
      id: elem.id,
      situation_type_id: elem.situation_type_id,
    };
    this.currentSituationId = elem.id;

    this.funcGetSituationTree(elem.id);
    this.toggleSituationAddEdit = true;
  }

  funcToggleAddEditChild(rowData: { [klass: string]: any }) {
    rowData.childName = '';
    rowData.childDescription = '';
    this.modeChild = 'add';
    rowData.expanded = !rowData.expanded;
  }

  funcGetSituationTree(id: string | number) {
    this.situationTree = [];

    this.solutionService.getSituationTree(id).subscribe((res) => {
      this.situationTree = res.result.data;
    });
  }

  funcSaveSituation() {
    const payload = {
      account_id: this.accountId,

      element_type_id: this.objSituation.type,
      name: this.objSituation.name,
      description: this.objSituation.description,
    };

    this.solutionService.saveSituationType(payload).subscribe(() => {
      this.funcGetSituationList();
      this.toggleSituationAddEdit = false;
    });
  }

  funcUpdateSituation() {
    const payload = {
      id: this.objSituation.id,
      account_id: this.accountId,
      situation_type_id: this.objSituation.situation_type_id,
      element_type_id: this.objSituation.type,
      name: this.objSituation.name,
      description: this.objSituation.description,
    };

    this.solutionService.updateSituationType(payload).subscribe(() => {
      this.notificationService.success('Situation updated successfully', false);

      this.funcGetSituationList();
      this.toggleSituationAddEdit = false;
    });
  }

  funcGetSituationList() {
    this.solutionService.getAccountSituationTypes().subscribe((res) => {
      this.situations = res.result;
    });
  }

  funcAddChild(row) {
    if (!row.childName) {
      return false;
    }
    const payload = {
      name: row.childName,
      description: row.childDescription,
      parent_id: row.situation_element_id,
      account_situation_type_id: row.situation_type_id,
    };

    this.solutionService.saveSituationElement(payload).subscribe((res) => {
      this.notificationService.success('Child node added successfully', false);

      row.expanded = false;
      this.funcGetSituationTree(row.situation_type_id);
    });
  }

  funcEditChild(row: { [klass: string]: any }) {
    this.modeChild = 'edit';
    row.childName = row.name;
    row.childDescription = row.description;
    row.expanded = true;
  }

  funcDeleteChild(row: { [klass: string]: any }) {
    this.solutionService.deleteSituationElement(row.situation_element_id).subscribe((res) => {
      if (res.result.success === false) {
        this.notificationService.error('This element is currently being used in a situation and cannot be deleted at this time', false);
        return false;
      }
      this.funcGetSituationTree(this.currentSituationId);
    });
  }

  funcUpdateChild(row) {
    if (!row.childName) {
      return false;
    }

    const payload = {
      name: row.childName,
      description: row.childDescription,
    };

    this.solutionService.updateSituationElement(row.situation_element_id, payload).subscribe(() => {
      row.expanded = false;
      this.funcGetSituationTree(this.currentSituationId);
    });
  }

  drop(dropEvent: CdkDragDrop<unknown[]>, dropArray: any[]) {
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
    this.funcUpdateSituationOrder();
  }

  funcUpdateSituationOrder() {
    const situationStackOrder = this.situations.map((item, index) => {
      const situation = {};
      situation['stack_order'] = index;
      situation['situation_type_id'] = item['id'];
      return situation;
    });

    const payload = {
      situation_types: situationStackOrder,
    };

    this.solutionService.updateSituationTypeStackOrder(payload).subscribe((response) => {
      if (response.result && response.result['success']) {
        this.notificationService.success('Situation order updated successfully', false);
      }
    });
  }
}
