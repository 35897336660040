import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';

@Injectable()
export class SalesManagerDashboardService {

	constructor(
		private CommonService: CommonService,
	) {}
	public downloadReport(payload, user_id) {
		let endpoint = "account/readHierarchyDataUserId/" + user_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public downloadAsset(asset_id) {
		let endpoint = "asset/readAssetTemplateByAssetTypeId/" + asset_id;
		return this.CommonService.getAPIService(endpoint);
	}
	
	public getTerms(account_id) {
		let endpoint = "account/readQuarterTerms/" + account_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public getData(account_id, payload) {
		let endpoint = "account/readUserDashboardStats/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getDataGeo(account_id, payload) {
		let endpoint = "account/readUserDashboardHierarchy/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getDataOrgChart(account_id, payload) {
		let endpoint = "account/readUserDashboardStatsByOpportunityStatuses/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getGeos(account_id, payload) {
		let endpoint = "account/readUserDashboardStatsByAccountGeos/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getGeoDrilldown(account_id, payload) {
		let endpoint = "account/readUserDashboardHierarchy/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getNaughtyList(account_id, payload) {
		let endpoint = "account/readUserDashboardListData/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}
}