import { Injectable } from '@angular/core';

@Injectable ()

export class ShareTranslations {
    
    public config: any = {
        component: 'valueTrackerShare',
        display_name: 'Value Tracker Share',
        description: 'Form to Share Value Tracker'
    };

    public trans: any = {
      share_tracker: 'Share Tracker',
      employee: 'Employee',
      role: 'Role',
      share: 'Share',
      currently_shared_with: 'Currently Shared With',
      name: 'Name',
      delete: 'Delete',
      are_you_sure: 'Are you sure?',
      back: 'Back'
    };
}
