import { Injectable } from '@angular/core';

@Injectable()
export class BenefitsFullTranslations {
  public config: any = {
    component: 'benefitsFull',
    display_name: 'Benefits Component',
    description: 'Benefit Component',
  };

  public trans: any = {
    'total': 'Total',
    'phasing': 'Phasing',
    'status': 'Status',
    'benefits': 'Benefits',
    'update': 'Update',
    'impact': 'Impact Type',
    'applyToAll': 'Apply to all in category',
    'ch_phaseOverTime': 'Phase benefits over time.',
    'ch_benefit_status': 'Change benefit status',
    'addedManually': 'This Benefit was added to this ValueProp manually.',
    'areYouSure': 'Are you sure?',
    'delete': 'Delete',
    'benefit_type': 'Benefit Type',
    'benefit_unit': 'Benefit Unit',
    'financial_benefit': 'Financial Benefit',
    'non_financial_benefit': ' Non Financial Benefit',
    'Year 1': 'Year 1',
    'Year 2': 'Year 2',
    'Year 3': 'Year 3',
    'Year 4': 'Year 4',
    'Year 5': 'Year 5',
    'save': 'Save',
    'benefitDetails': 'Benefit Details',
    'thousands': 'K',
    'millions': 'M',
    'billions': 'B',
    'trillions': 'T',
    'quintillions': 'Q',
  };
}
