<div class="center valueCloud-login" style="padding-top: 100px">
  <div class="panel panel-white panel-style" id="js-alerts">
    <div class="text-center login-bg"><img src="assets/images/xDLlogo.png" alt="Value Execution by Xfactor.io" style="max-height: 42px" /></div>
    <div class="login-box">
      <alert></alert>
      <div class="register-box" *ngIf="!emailSuccess; else emailSentConfirmation">
        <div>
          <p class="login-title">Forgot Password</p>
          <p class="text-center login-sub-header">Enter your email address and we’ll send you an email with instructions to reset your password</p>
        </div>
        <div class="">
          <form class="m-t-md" name="ForgotPwdForm" [formGroup]="passwordForm">
            <div class="login-label">Email address</div>
            <div class="form-group">
              <input
                type="email"
                formControlName="email"
                class="form-control login-input"
                [class.login-invalid-input]="passwordForm.touched && passwordForm.get('email').errors"

                name="email"
                id="forgotUsername"
                placeholder="Enter your email"
                required
                aria-invalid="true"
                ngModel
              />
            </div>
            <div [class.login-error-label-active]="passwordForm.touched && passwordForm.get('email').hasError('email')" style="margin-top: -12px" class="login-error-label">
              Please enter a valid email address
            </div>
            <div style="margin-top: 34px;" class="login-btn-container">
              <img class="actionLoader text-center" *ngIf="showActionLoader" src="{{ fullImagePath }}" />
              <button type="submit" *ngIf="!showActionLoader" (click)="submit()" [disabled]="passwordForm.invalid" class="btn btn-success btn-block reset-pwd-btn">Reset Password</button>
            </div>
            <div class="text-center" style="margin-bottom: 63px;"><a class="login-link" routerLink="/login">Back to Login </a></div>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
      <ng-template #emailSentConfirmation>
        <div class="email-confirmation">
          <i class="fa fa-check"></i>
          <p>If an account with the email provided exists, you will receive an e-mail with reset link</p>
          <button class="btn btn-success btn-block reset-pwd-btn" (click)="redirectToLogin()">Go back to Login</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
