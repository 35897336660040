<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>
<div *ngIf="loadingDataGeo" style="margin-top: 70px;">
	<dl-progressbar class='dl_progress' [showValue]='true' [value]='cachePercent' [label]='"Loading Organization Details (" + cacheProgress + " of " + (term.length -1) + ")"'></dl-progressbar>
</div>
<div *ngIf="!loadingDataGeo" style="margin-top: 70px;">
	<div class='row'>
		<div class='col-xs-12'>
			<div class='models_panel'>
				<div class='models_page_header_container_small'>
					<div class='row'>
						<div class='col-xs-4'></div>

						<div class='col-xs-8' *ngIf="details && details.name == 'undefined undefined'">
							<h2>Please select an employee to the left</h2>
						</div>

						<div class='col-xs-8' *ngIf="details && details.name !== 'undefined undefined'">
							<a href='mailto:{{details.email}}' [matTooltip]='details.email' style='font-size: 12px; float: left;' class='clickable link'>
								<h2 style='font-weight: bold !important; margin-top: 0px;'>{{details.name}} Organization</h2>
							</a>
							<a *ngIf="selectedTreeUser" (click)='downloadReport()' class='btn btn_customizable pull-right'>Download Report</a>
						</div>
					</div>
					<div class='row'>
						<div class='col-xs-4'>
							<p-treeTable [value]="cache[geoIndex]" styleClass='treetable_details' (onNodeExpand)="onNodeExpand($event)" (onNodeCollapse)="onNodeCollapse($event)">
								<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
									<tr>
										<td [ngClass]="{'selected': highlightUserColor(rowData)}">
											<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
											<div *ngIf="rowData && rowData.profile_pic_filepath" class='clickable' (click)='selectedGeoData = rowData; loadDrilldownDetails(rowData);'>
												<span style='padding-top: 7px; padding-bottom: 7px; display: block;'>{{rowData.first_name}} {{rowData.last_name}}</span>
											</div>
											<div *ngIf="!rowData.profile_pic_filepath" class='clickable' (click)='selectedGeoData = rowData; loadDrilldownDetails(rowData);'>
												<span style='padding-top: 7px; padding-bottom: 7px; display: block;'>{{rowData.first_name}} {{rowData.last_name}}</span>
											</div>
										</td>
									</tr>
								</ng-template>
							</p-treeTable>
						</div>


						<div class='col-xs-8' *ngIf="details && details.name !== 'undefined undefined'">
							<div class='row'>
								<div class='col-xs-12'>
									<div class='rollup_container' style='margin-top: 14px;'>
										<div (click)='funcToggleGeoData("revenue")' class='rollup' [ngStyle]="{'background-color': orgView == 'revenue' ? primary_color : 'white'}" style='width: 25%;'>
											<div class='clickable rollup_total' style='width: 100px; display: block; width: 110px; white-space: nowrap; margin: 0 auto;'>
												<span [ngStyle]="{'color': orgView == 'revenue' ? 'white' : 'inherit'}">{{details.fmt_total_revenue}}</span>
											</div>
											<div class='clickable rollup_text' [ngStyle]="{'color': orgView == 'revenue' ? 'white' : 'inherit'}" style='clear: both;'>{{trans.trans.valuetovm.value}}
												<i *ngIf="!(style2022$ | async)" matTooltip="The value to VMware of unique business cases created by the selected user's organization in the selected time frame. This figure purposefully excludes potential third party costs such as Azure, AWS hosting, etc. This figure counts only the value from the most recent opportunity within an Opp ID to avoid double-counting." class='fa fa-info-circle'></i>
												<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="The value to VMware of unique business cases created by the selected user's organization in the selected time frame. This figure purposefully excludes potential third party costs such as Azure, AWS hosting, etc. This figure counts only the value from the most recent opportunity within an Opp ID to avoid double-counting.">
													info
												</span>
											</div>
											<small [ngStyle]="{'color': orgView == 'revenue' ? 'white' : 'inherit'}" style='font-size: 8px; line-height: 10px; width: 200px; margin: 0 auto; display: block; padding-top: 5px;'>*Represents potential value of business cases created during the selected time period.</small>
										</div>

										<div (click)='funcToggleGeoData("created")' class='rollup' [ngStyle]="{'background-color': orgView == 'created' ? primary_color : 'white'}" style='width: 25%;'>
											<div class='clickable rollup_total' style='width: 100px; display: block; width: 80px; margin: 0 auto;'>
												<span [ngStyle]="{'color': orgView == 'created' ? 'white' : 'inherit'}">{{details.created}}</span>
											</div>
											<div class='clickable rollup_text' [ngStyle]="{'color': orgView == 'created' ? 'white' : 'inherit'}" style='clear: both;'>{{trans.trans.value_props.value}}<br>{{trans.trans.created.value}}
												<i *ngIf="!(style2022$ | async)" matTooltip="The number of business cases created by the selected user's organization in the selected time frame. If the user has no organization, this figure applies only to his or her business cases created." class='fa fa-info-circle'></i>
												<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="The number of business cases created by the selected user's organization in the selected time frame. If the user has no organization, this figure applies only to his or her business cases created.">
													info
												</span>
											</div>
										</div>

										<div (click)='funcToggleGeoData("modified")' class='rollup' [ngStyle]="{'background-color': orgView == 'modified' ? primary_color : 'white'}" style='width: 25%;'>
											<div class='clickable rollup_total' style='width: 100px; display: block; width: 80px; margin: 0 auto;'>
												<span [ngStyle]="{'color': orgView == 'modified' ? 'white' : 'inherit'}">{{details.modified}}</span>
											</div>
											<div class='clickable rollup_text' [ngStyle]="{'color': orgView == 'modified' ? 'white' : 'inherit'}" style='clear: both;'>{{trans.trans.value_props.value}}<br>{{trans.trans.modified.value}}
												<i *ngIf="!(style2022$ | async)" matTooltip="The number of business cases edited by the selected user's organization in the selected time frame. If the user has no organization, this figure applies only to his or her business cases edited." class='fa fa-info-circle'></i>
												<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="The number of business cases edited by the selected user's organization in the selected time frame. If the user has no organization, this figure applies to his or her business cases edited.">
													info
												</span>
											</div>
										</div>

										<div (click)='funcToggleGeoData("login")' class='rollup' [ngStyle]="{'background-color': orgView == 'login' ? primary_color : 'white'}" style='width: 25%;'>
											<div class='clickable rollup_total' style='width: 100px; display: block; width: 80px; margin: 0 auto;'>
												<span [ngStyle]="{'color': orgView == 'login' ? 'white' : 'inherit'}">{{details.login}}</span>
											</div>
											<div class='clickable rollup_text' [ngStyle]="{'color': orgView == 'login' ? 'white' : 'inherit'}" style='clear: both;'>{{trans.trans.logins_org_view.value}}
												<i *ngIf="!(style2022$ | async)" matTooltip="The number of logins by the selected user’s organization in the selected time frame. If the user has no organization, this figure applies only to his or her logins." class='fa fa-info-circle'></i>
												<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="The number of logins by the sleected user's organization in the selected time frame. If the user has no organization, this figure applies only to his or her logins.">
													info
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="chart_loading">
								<p-progressBar mode="indeterminate"></p-progressBar>
							</div>

							<div [ngSwitch]="details.chart" *ngIf="!chart_loading">
								<!-- case 1 -->
								<div *ngSwitchCase="1" style='padding-top: 20px;'>
									<i *ngIf="isDev" matTooltip='case 1' class='fa fa-code'></i>
									<p-chart *ngIf="chartData" type='horizontalBar' height='400px' [options]='details.chart_options' [data]='chartData'></p-chart>
								</div>

								<!-- case 0 -->
								<div *ngSwitchCase="0" style='padding-top: 20px;'>
									<i *ngIf="isDev" matTooltip='case 0' class='fa fa-code'></i>
									<div *ngIf="!details.value_props.length">
										<img src='assets/images/no_value_props.png' class='img-responsive'>
										<span style='position: absolute; width: 400px; top: 550px; left: 30%; font-size: 24px;'>No Business Cases Created</span>
									</div>
									<div *ngIf="details.value_props && details.value_props.length" style='overflow-x: auto;'>
										<div class='row'>
											<div class='col-xs-12'>
												<h2 style='padding-bottom: 10px; font-weight: bold;'>{{details.first_name}} {{details.last_name}} User Details</h2>
											</div>
										</div>

										<div class='row'>
											<div class='col-xs-6'>

											</div>
											<div class='col-xs-6'>
												<span class="clickable pull-right" (click)='row.toggle($event)'>Row limit: {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
											</div>
										</div>

										<div class='row'>
											<div class='col-xs-12'>
												<a (click)='drillDownTable.exportCSV({selectionOnly:false})' class='link clickable pull-right'><i class='fa fa-file-o'></i> Export to CSV</a>
											</div>
										</div>


										<p-table #drillDownTable [columns]='colsValueProps' [value]='details.value_props' [(first)]="first" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
											<ng-template pTemplate="header" let-columns>
												<tr>
													<th *ngFor="let col of columns" [pSortableColumn]="col.field" style='white-space: nowrap;' [ngStyle]="{'text-align': col.field == 'company_name' || col.field == 'login_date' ? 'left' : 'right'}">
														{{col.header}}
														<p-sortIcon [field]="col.field"></p-sortIcon>
													</th>
												</tr>
											</ng-template>
											<ng-template pTemplate="body" let-data>
												<tr>
													<td *ngFor="let col of colsValueProps" [ngStyle]="{'text-align': col.field == 'company_name' || col.field == 'login_date' ? 'left' : 'right'}">
														<span *ngIf="col.field == 'company_name'"><a routerLink='/repv2/{{data.value_prop_id}}/4' class='link clickable'>{{data.company_name}}</a></span>
														<span *ngIf="col.field !== 'company_name'" style='padding-right: 30px;'>{{data[col.field]}}</span>
													</td>
												</tr>
											</ng-template>
										</p-table>
									</div>
								</div>

								<div *ngSwitchCase="2" style='padding-top: 20px;'>
									<i *ngIf="isDev" matTooltip='case 2' class='fa fa-code'></i>
									<p-chart *ngIf="chartData" type='horizontalBar' height='400px' [options]='details.chart_options' [data]='chartData'></p-chart>

									<div *ngIf="!details.value_props.length"><img src='assets/images/no_value_props.png' class='img-responsive'></div>
									<div *ngIf="details.value_props && details.value_props.length" style='margin-top: 20px; overflow-x: auto;'>
										<div class='row'>
											<div class='col-xs-12'>
												<h2 style='padding-bottom: 10px; font-weight: bold;'>{{details.first_name}} {{details.last_name}} User Details</h2>
											</div>
										</div>
										<div class='row'>
											<div class='col-xs-6'>
												<!-- <div class="input-group keywest_search" *ngIf="orgView != 'login'">
													<span class="input-group-addon"><i class='fa fa-search'></i></span>
													<input [(ngModel)]="strSearch" (keyup)="drillDownTable.filter($any($event).target.value, 'company_name', 'contains')" type='text' placeholder='Search by Customer Name' class='form-control'>
													<span *ngIf="strSearch !== ''" (click)="strSearch = ''; drillDownTable.filter('', 'company_name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
												</div> -->
											</div>
											<div class='col-xs-6'>
												<span class="clickable pull-right" (click)='row.toggle($event)'>Row limit: {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
											</div>
										</div>

										<div class='row'>
											<div class='col-xs-12'>
												<a (click)='drillDownTable.exportCSV({selectionOnly:false})' class='link clickable pull-right'><i class='fa fa-file-o'></i> Export to CSV</a>
											</div>
										</div>



										<p-table #drillDownTable [columns]='colsValueProps' [value]='details.value_props' [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
											<ng-template pTemplate="header" let-columns>
												<tr>
													<th *ngFor="let col of columns" [pSortableColumn]="col.field" style='white-space: nowrap;' [ngStyle]="{'text-align': col.field == 'company_name' || col.field == 'login_date' ? 'left' : 'right'}">
														{{col.header}}
														<p-sortIcon [field]="col.field"></p-sortIcon>
													</th>
												</tr>
											</ng-template>
											<ng-template pTemplate="body" let-data>
												<tr>
													<td *ngFor="let col of colsValueProps" [ngStyle]="{'text-align': col.field == 'company_name' || col.field == 'login_date' ? 'left' : 'right'}">
														<span *ngIf="col.field == 'company_name'"><a routerLink='/repv2/{{data.value_prop_id}}/4' class='link clickable'>{{data.company_name}}</a></span>
														<span *ngIf="col.field !== 'company_name'" style='padding-right: 30px;'>{{data[col.field]}}</span>
													</td>
												</tr>
											</ng-template>
										</p-table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>