import { Component, OnInit, Input, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AccountService } from '@data/services/account/account.service';
import { NotificationService } from '@services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { isEmpty } from 'lodash';
import { Company } from '@shared/models/company.model';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
})
export class CompanySearchComponent implements OnInit, OnDestroy {
  @Input() page;
  searchTable = true;
  showActionLoader = false;
  popularSearch = true;
  searchTypeList: Array<{ [klass: string]: any }> = [
    {
      name: 'Search By Name',
      value: 1,
    },
  ];
  fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  companyname = '';
  recentSearch = true;
  companiesNoResult = false;
  companiesList: Array<Company> = [];
  companiesResult = false;

  pageNumber: number;
  pageNumberSearch = 1;

  pageLimit = 10;
  pageLimitSearch = 10;
  searchTypErr = false;
  order = 'asc';
  sortBy = 'name';
  strSearch = '';

  subscriptiongetSearchCompanies: Subscription;
  subscriptiongetRecentSearchCompanies: Subscription;
  rowLimit: MenuItem[] = [];
  tipContent: string | TemplateRef<any>;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit(): void {
    this.loadSearchDataTable();
  }

  ngOnDestroy(): void {
    if (this.subscriptiongetSearchCompanies) {
      this.subscriptiongetSearchCompanies.unsubscribe();
    }
    if (this.subscriptiongetRecentSearchCompanies) {
      this.subscriptiongetRecentSearchCompanies.unsubscribe();
    }
  }

  setRowLimit(num: number): void {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit(): void {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  searchCompany(form: NgForm): void {
    this.popularSearch = false;
    if (!isEmpty(form.value.companyname)) {
      this.showActionLoader = true;

      this.loadSearchDataTable();
    } else {
      this.companiesNoResult = true;
      this.companiesResult = false;
    }
  }
  loadSearchDataTable(): void {
    this.recentSearch = false;
    const payload = {
      limit: 'all',
      page: 'all',
      sortBy: 'name',
      order: 'asc',
    };

    this.subscriptiongetSearchCompanies = this.accountService.getSearchCompanies(payload).subscribe(
      (result) => {
        if (result.result && result.result.data && result.result.data.length > 0) {
          this.companiesList = result.result.data;
          this.companiesResult = true;
          this.companiesNoResult = false;
        } else {
          this.companiesNoResult = true;
          this.companiesResult = false;
        }
        this.showActionLoader = false;
      },
      (error) => {
        this.notificationService.error(error.result, false);
      }
    );
  }
  getRecentSearchComp(): void {
    const queryString = '/limit/all/page/all/sortby/name/order/asc';
    this.recentSearch = true;
    this.subscriptiongetRecentSearchCompanies = this.accountService.getRecentSearchCompanies(queryString).subscribe((result) => {
      if (result.result && result.result.data.length > 0) {
        this.companiesList = result.result.data;
        this.companiesResult = true;
        this.companiesNoResult = false;
      } else {
        this.companiesNoResult = true;
        this.companiesResult = false;
      }
      this.showActionLoader = false;
    });
  }

  onTypeChange(): void {
    this.pageNumberSearch = 1;
  }

  selectCompany(company: Company): void {
    this.router.navigate(['/company/' + company.id]);
  }
}
