import { Component, OnInit, Input } from '@angular/core';
import { PrimengData, PrimengLabels } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';

@Component({
  selector: 'app-tracker-stacked-bar',
  templateUrl: 'trackers-stacked-bar.component.html',
})
export class TrackerStackedBarComponent implements OnInit {
  @Input() data: PrimengData;
  @Input() chartColors: string[];
  @Input() currency: string;

  options: CustomChartOptions;

  ngOnInit(): void {
    this.buildData();
  }

  buildData(): void {
    this.data.labels = this.data.labels ? this.data.labels.map((label) => this.labelFormatter(label)) : [];

    const that = this;
    this.options = {
      responsive: true,
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (label) => {
                const num = Number(label).toLocaleString('en');
                return this.currency ? this.currency + ' ' + num : num;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltip) {
            const num = Number(tooltip.yLabel).toLocaleString('en');
            return that.currency ? that.currency + ' ' + num : num;
          },
        },
      },
    };
  }

  labelFormatter(label: string): string {
    if (Array.isArray(label)) {
      return label;
    }
    const labelArr = label.split(' ');
    if (labelArr.length < 3 && label.length < 10) {
      return label;
    } else {
      const formattedLabel = labelArr.reduce((acc, curr, index) => {
        if (index === 0) {
          acc = [curr];
        } else {
          const currIndexLabel = acc[acc.length - 1];
          if (currIndexLabel.length > 10) {
            acc.push(curr);
          } else {
            acc[acc.length - 1] = currIndexLabel + ' ' + curr;
          }
        }

        return acc;
      }, []);

      return formattedLabel.join();
    }
  }
}
