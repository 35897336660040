import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitSelect, BulkDeleteResponse, ValueCategory, ValueCategoryMultiSelect } from '@data/services/valueposition/models/value-category.interface';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { Benefit, Solution } from '@shared/models/value-prop.model';
import { FeedbackResponse } from '@shared/models/value-prop.model';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { BenefitElement, Carousel } from './benefits.model';
import { BenefitsTranslations } from './benefits.translation';
import { SolutionValidationService } from '@data/services/validation/solution-validation.service';
import { BenefitValidationItem } from '@shared/models/benefits.models';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnInit, OnDestroy {
  @Input() tabInit: number;
  @Input() mode = 'list';
  @Input() default_term = '3';
  @Input() can_edit = false;
  @Input() modelEditor = false;

  ngUnsubscribe = new Subject();

  solutionId: number;
  driverFactorId: number;
  scratchpadsInfo: { [klass: string]: any };
  valueCategories: ValueCategoryMultiSelect[] = [];
  initialValueCategories: ValueCategoryMultiSelect[] = [];
  valueCategory: ValueCategory;
  currentValueCategory: ValueCategory;
  currentValueCategoryName: string;
  currentBenefit: Benefit;
  benefitLoader = false;
  valueCategoryBenefitLoader = false;
  isSolutionAdmin = false;
  isAccountAdmin = false;
  image_url: string;
  profilepicdefaultpath: string;
  fullImagePath: string;
  selectedCategory: { [klass: string]: any };
  tableViewBenefits = true;
  cardViewBenefits: boolean;
  modalReference: NgbModalRef;
  deleteModalReference: NgbModalRef;
  selectedBenefit: Benefit;
  feedback: { [klass: string]: any };
  feedbackList: FeedbackResponse[];
  driverScratchpadFactor_default: Array<object> = [];
  driverScratchpadFactorValue_default = 0;
  OpenParen_default: string;
  CloseParen_default: string;
  templateSetting: string;
  benefitSelectedList: { [klass: string]: any };
  benefitDeleteLoader: boolean;
  dropdownSettings: { [klass: string]: any } = {};
  parenthesis: Array<{ name: string; sign: string }> = [
    {
      name: 'openParen',
      sign: '(',
    },
    {
      name: 'closeParen',
      sign: ')',
    },
  ];

  textInputFields: Array<any> = [
    {
      id: 1,
      name: '',
    },
  ];
  driverScratchpadFields: Array<any> = [
    {
      id: 1,
      factor: [],
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: 0,
    },
  ];
  scratchpadDriverFactorList: Array<{ [klass: string]: any }>;
  operatorTypesList: Array<{ [klass: string]: any }>;
  scratchpadImprovementList: Array<{ [klass: string]: any }>;

  subDriverFactorList: Array<{ [klass: string]: any }> = [
    {
      id: 1,
      factortype1: '',
      operatortype: '+',
      factortype2: '',
      close: '',
    },
  ];

  contextualHelp: { [klass: string]: any } = {};

  masterValuePropFeature: boolean;
  projectsList: { [klass: string]: any }[] = [];
  selectedProject: { [klass: string]: any };
  newFactor: string;
  toggleScratchpadFactor = true;
  factorCreateLoader = false;

  addScratchPadError = '';

  subscriptioncontextualHelp: Subscription;
  subscriptionreadAllValueCategory: Subscription;
  subscriptiontriggerBenefit: Subscription;
  subscriptiongetScratchpadFactorTypes: Subscription;
  subscriptiongetScatchpadOperatorTypes: Subscription;
  subscriptiongetScratchpadImprovementTypes: Subscription;
  subscriptionreadBenefitsByValueCategory: Subscription;
  subscriptiongetDriverFactorSubTypes: Subscription;
  subscriptioncreateDriverFactorSubTypes: Subscription;
  subscriptiondeleteDriverFactorSubTypes: Subscription;
  subscriptiongetSolutionsScratchpads: Subscription;
  subscriptiondeleteSolutionsScratchpads: Subscription;
  subscriptioncreateSolutionScratchpad: Subscription;
  subscriptionsaveBenefitFeedback: Subscription;
  subscriptionreadFeedback: Subscription;
  subscriptiondeleteSolutionMetric: Subscription;
  subscriptionsaveBenefitSequence: Subscription;
  subscriptionreadBenefitsByProject: Subscription;
  subscriptiongetProjects: Subscription;
  subscriptionreadBenefitsByValueCategoryAndProject: Subscription;
  subscriptioncreateDriverFactor: Subscription;
  subscriptioncreateFinancialFactor: Subscription;
  subscriptioncreateImprovementFactor: Subscription;

  dropdown: { [klass: string]: any };
  tableSolutionList: { [klass: string]: any };
  toggleAddDriver: { [klass: string]: any };

  cols = [
    { field: 'metric_name', header: 'Benefit Name' },
    { field: 'driver_factor', header: 'Driver Factor' },
    { field: 'driver_baseline_answer', header: 'Driver Answer' },
  ];
  selectedColumns: { [klass: string]: any }[] = [];
  selectedColumn = 'driver_factor';
  // TODO: Carousel type can't be used for a PrimeNG menu as it is here
  carousel: any[] = [];
  carouselSelected: Carousel = { field: 'financial_factor', label: 'Financial Factor' };
  carouselIndex = 0;

  loaded = false;
  toggleOptions = false;
  expanded = false;
  rows = 10;
  showTranslate = false;

  selectedElem: BenefitElement = {
    metric_name: '',
    value_category_id: '',
    description: '',
    impact_type_id: '',
    accrual_type_id: '',
    scale_type_id: '',
    unique_type_id: false,
    default_na: false,
    driver_toggle: '',
    driver_factor_id: '2',
    driver_baseline_answer: '1',
    improvement_toggle: true,
    improvement_factor_id: '',
    unit_type_id: '',
    current_effort: '',
    conservative: '',
    probable: '',
    aggressive: '',
    financial_toggle: '',
    financial_factor_id: '2',
    financial_baseline_answer: '1',
    optional_toggle: '',
    tco_name: '',
    value_statement: '',
    features: '',
    account_functional_objective_id: '',
    project_id: '',
    expense_type_id: '',
    benefit_type_id: '',
    area_type_id: '',
    account_painpoint_id: '',
    account_capability_id: '',
    account_operational_objective_id: '',
    account_solution_id: '',
    has_phasing: false,
    term0: '0',
    term1: '0',
    term2: '0',
    term3: '0',
    term4: '0',
    term5: '0',
    term6: '0',
    term7: '0',
    term8: '0',
    term9: '0',
    term_val0: '',
    term_val1: '',
    term_val2: '',
    term_val3: '',
    term_val4: '',
    term_val5: '',
    term_val6: '',
    term_val7: '',
    term_val8: '',
    term_val9: '',
    selectedFuncObjectives: '',
    selectedPainPoints: '',
    areas: [],
    painPoints: [],
    capabilities: [],
    opObjectives: [],
    phase0: 100,
    phase1: 100,
    phase2: 100,
    phase3: 100,
    phase4: 100,
    quantified: '1',
  };

  toggleAddSubType = false;
  toggleClone = false;
  benefitCloneAllowed = this.commonService.checkPrivilege('1') || this.commonService.checkPrivilege('2');
  benefitsCount = 0;
  adminActionbar = false;

  benefitsSelectedCheckbox: BenefitSelect[];
  solutionsLoading = false;
  solutions: Solution[];
  solutionSelected: Solution;
  cloning = false;

  @ViewChild('addSubtypeForm') addSubtypeForm: NgForm;

  constructor(
    private solutionService: SolutionService,
    private factsService: FactsService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private modalService: NgbModal,
    private notification: NotificationService,
    public trans: BenefitsTranslations,
    private translationService: TranslationsV2Service,
    private solutionValidationService: SolutionValidationService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
    this.profilepicdefaultpath = this.image_url + 'images/default_user.png';
  }

  ngOnInit(): void {
    this.getTranslations();
    this.getSolutions();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.selectedColumns = this.cols;
    this.getAggregatedFactors();

    this.route.params.subscribe((params) => (this.solutionId = params['id']));

    this.subscriptionreadAllValueCategory = this.solutionService.readAllValueCategory.subscribe(() => {
      this.getBenefits('all', this.solutionId);
    });

    this.subscriptiontriggerBenefit = this.solutionService.triggerBenefit.subscribe(() => {
      this.getBenefits('all', this.solutionId);
    });

    this.masterValuePropFeature = false;

    if (this.commonService.checkFeature(15)) {
      this.masterValuePropFeature = true;
      this.getProjects();
      this.readBenefitsByProject('all');
    }

    this.getBenefits('all', this.solutionId);

    this.solutionValidationService.selectedValidationBenefit$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((benefit: BenefitValidationItem) => {
      if (benefit) {
        this.solutionService
          .readBenefitsByValueCategory('all', this.solutionId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            const enhancedResult = this.insertCheckControl(response.result);
            this.initialValueCategories = enhancedResult;
            this.valueCategories = enhancedResult;
            const selectedBenefit = this.valueCategories
              ?.find((v) => v?.benefits?.find((b) => b.metric_name === benefit.name && b.id === benefit.id))
              .benefits?.find((b) => b.id === benefit.id);
            if (selectedBenefit) {
              this.funcToggleEdit(selectedBenefit);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  cancelSubtypeChange(): void {
    this.toggleAddSubType = false;
    this.addSubtypeForm.reset();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.funcBuildCarousel();
      });
  }

  funcBuildCarousel(): void {
    this.carousel = [];
    this.carousel.push({
      icon: this.carouselIndex === 0 ? 'fa fa-check' : '',
      field: 'financial_factor',
      label: this.trans.trans.financial_factor.value,
      command: () => {
        this.funcGotoCarouselItem('financial_factor');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 1 ? 'fa fa-check' : '',
      field: 'financial_baseline_answer',
      label: this.trans.trans.financial_baseline_answer.value,
      command: () => {
        this.funcGotoCarouselItem('financial_baseline_answer');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 2 ? 'fa fa-check' : '',
      field: 'improvement_factor_name',
      label: this.trans.trans.improvement_factor_name.value,
      command: () => {
        this.funcGotoCarouselItem('improvement_factor_name');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 3 ? 'fa fa-check' : '',
      field: 'conservative',
      label: this.trans.trans.conservative.value,
      command: () => {
        this.funcGotoCarouselItem('conservative');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 4 ? 'fa fa-check' : '',
      field: 'probable',
      label: this.trans.trans.probable.value,
      command: () => {
        this.funcGotoCarouselItem('probable');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 5 ? 'fa fa-check' : '',
      field: 'aggressive',
      label: this.trans.trans.aggressive.value,
      command: () => {
        this.funcGotoCarouselItem('aggressive');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 6 ? 'fa fa-check' : '',
      field: 'current_effort',
      label: this.trans.trans.current_effort.value,
      command: () => {
        this.funcGotoCarouselItem('current_effort');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 7 ? 'fa fa-check' : '',
      field: 'impact_type',
      label: this.trans.trans.impact_type.value,
      command: () => {
        this.funcGotoCarouselItem('impact_type');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 8 ? 'fa fa-check' : '',
      field: 'unique_type',
      label: this.trans.trans.unique_type.value,
      command: () => {
        this.funcGotoCarouselItem('unique_type');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 9 ? 'fa fa-check' : '',
      field: 'tco_name',
      label: this.trans.trans.tco_name.value,
      command: () => {
        this.funcGotoCarouselItem('tco_name');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 10 ? 'fa fa-check' : '',
      field: 'value_statement',
      label: this.trans.trans.value_statement.value,
      command: () => {
        this.funcGotoCarouselItem('value_statement');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 11 ? 'fa fa-check' : '',
      field: 'created',
      label: this.trans.trans.created.value,
      command: () => {
        this.funcGotoCarouselItem('created');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 12 ? 'fa fa-check' : '',
      field: 'modified',
      label: this.trans.trans.modified.value,
      command: () => {
        this.funcGotoCarouselItem('modified');
        this.funcBuildCarousel();
      },
    });
    this.carousel.push({
      icon: this.carouselIndex === 13 ? 'fa fa-check' : '',
      field: 'files',
      label: this.trans.trans.files.value,
      command: () => {
        this.funcGotoCarouselItem('files');
        this.funcBuildCarousel();
      },
    });
  }
  funcCarouselLeft(): void {
    let i = this.carouselIndex - 1;
    if (i < 0) {
      i = 0;
    }
    this.carouselIndex = i;
    this.carouselSelected = this.carousel[i];
  }

  funcCarouselRight(): void {
    let i = this.carouselIndex + 1;
    if (i > this.carousel.length) {
      i = this.carousel.length;
    }
    this.carouselIndex = i;
    this.carouselSelected = this.carousel[i];
  }

  funcGotoCarouselItem(elem): void {
    const out = this.carousel.find((x) => x.field === elem);
    this.carouselSelected = out;
    const i = this.carousel.findIndex((x) => x.field === elem);
    this.carouselIndex = i;
  }

  funcGetUnitTypeIcon(type): string {
    if (type === 'Percent') {
      return 'percent';
    }
    if (type === 'Hours') {
      return 'clock-o';
    }
    if (type === 'Days') {
      return 'clock-o';
    }
    if (type === 'Months') {
      return 'clock-o';
    }
    if (type === 'Years') {
      return 'clock-o';
    }
    return 'hashtag';
  }

  funcToggleColumns(col): boolean {
    const elem = this.selectedColumns.find((x) => x.field === col);
    if (elem && elem.field === col) {
      return true;
    } else {
      return false;
    }
  }

  funcToggleEdit(elem): void {
    this.mode = 'edit';
    this.selectedElem = elem;
  }

  funcAddEditBenefitDone(): void {
    this.mode = 'dummy';
    this.getBenefits('all', this.solutionId);
  }

  toggleExpandCollapse(): void {
    this.expanded = !this.expanded;
    this.valueCategories.map((elem) => {
      elem['collapsed'] = this.expanded;
      return elem;
    });
    this.toggleOptions = false;
  }

  setRows(rows): void {
    this.rows = rows;
    this.toggleOptions = false;
  }

  getBenefits(value_category_id: string, solution_id: number): void {
    this.mode = 'dummy';
    this.loaded = false;
    this.valueCategories = [];
    this.initialValueCategories = [];
    this.subscriptionreadBenefitsByValueCategory = this.solutionService.readBenefitsByValueCategory(value_category_id, solution_id).subscribe((response) => {
      this.mode = 'list';
      const enhancedResult = this.insertCheckControl(response.result);
      this.initialValueCategories = enhancedResult;
      this.valueCategories = enhancedResult;

      this.valueCategories.map((elem) => {
        elem['strSearch'] = '';
        elem['collapsed'] = false;
        return elem;
      });
      this.loaded = true;
    });
  }

  funcShowAddEditBenefit(mode): void {
    for (const k in this.selectedElem) {
      this.selectedElem[k] = null;
      if (k === 'quantified' && mode === 'add') {
        this.selectedElem[k] = '1';
      }
    }

    this.selectedElem.driver_toggle = true;
    this.selectedElem.improvement_toggle = true;
    this.mode = mode;
  }

  funcToggleView(elem): void {
    this.selectedElem = Object.assign({}, elem);
    this.mode = 'view';
  }

  funcDeleteBenefit(elem, div): void {
    const payload = elem.account_solution_id + '/accountSolutionMetricID/' + elem.id;
    this.subscriptiondeleteSolutionMetric = this.solutionService.deleteSolutionMetric(payload).subscribe((response) => {
      if (response.result.success === false) {
        this.notification.error(response.result.message, false);
        div.funcCancel();
      } else {
        this.notification.success(response.result.message, false);
        this.getBenefits('all', this.solutionId);
      }
    });
  }

  getAggregatedFactors(): void {
    this.scratchpadDriverFactorList = [];

    this.benefitLoader = true;
    this.subscriptiongetScratchpadFactorTypes = this.factsService.getScratchpadFactorTypes().subscribe((response) => {
      this.scratchpadDriverFactorList = response.result;
      this.benefitLoader = false;
    });
  }
  getScratchpadOperatorTypes(): void {
    this.subscriptiongetScatchpadOperatorTypes = this.factsService.getScatchpadOperatorTypes().subscribe((response) => {
      this.operatorTypesList = response.result.filter((operator: any) => {
        return operator.id !== '5';
      });
    });
  }

  funcGetSign(id): unknown {
    const out = this.operatorTypesList.filter((x) => x['id'] === id);

    return out.length ? out[0]['name'] : '';
  }

  getImprovementTypes(): void {
    this.benefitLoader = true;
    this.subscriptiongetScratchpadImprovementTypes = this.factsService.getScratchpadImprovementTypes().subscribe((response) => {
      this.scratchpadImprovementList = response.result;
      this.benefitLoader = false;
    });
  }

  loadSelectedValueCategoryBenefits(selectedCategory): void {
    if (selectedCategory) {
      this.valueCategories = [selectedCategory];
    } else {
      this.valueCategories = this.initialValueCategories;
    }
  }

  textClose(params): void {
    this.textInputFields = this.textInputFields.map((textField: any) => {
      if (textField.id === params) {
        textField.name = '';
      }
      return textField;
    });
    this.textInputFields = this.textInputFields.filter((textField: any) => {
      return textField.id !== params;
    });
  }

  addSubTypes(): void {
    const idRandom = 'textfield-' + 1 + Math.floor(Math.random() * 1000) + '-inputEl';
    const subTypes = { id: idRandom };
    this.textInputFields.push(subTypes);
  }

  addDriverFactor(): void {
    const idRandom = Math.floor(Math.random() * 1000);
    this.subDriverFactorList.push({
      id: idRandom,
      factortype1: '',
      operatortype: '+',
      factortype2: '',
      close: '',
    });
  }

  checkIfNotApplicable(financialFactorId): boolean {
    return Number.parseInt(financialFactorId, 10) !== 2;
  }

  driverFactorClose(params): void {
    this.subDriverFactorList = this.subDriverFactorList.filter((textField: any) => {
      return textField.id !== params;
    });
  }

  openScratchpad(content): void {
    const modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    modalReference.componentInstance.solutionId = this.solutionId;
    modalReference.result.then(
      () => {},
      () => {}
    );
  }

  addDriverSubTypes(benefit): void {
    this.textInputFields = [
      {
        id: 1,
        name: '',
        driver_value: 0,
        improvement_value: 0,
        financial_value: 0,
      },
    ];
    this.getDriverSubTypes(benefit.driver_factor_id);
    this.driverFactorId = benefit.driver_factor_id;
    this.benefitSelectedList = benefit;
  }

  getDriverSubTypes(id): void {
    const updatedTextFields = [];
    this.benefitLoader = true;
    this.subscriptiongetDriverFactorSubTypes = this.solutionService.getDriverFactorSubTypes(id).subscribe((response) => {
      if (response.result) {
        for (let i = 0; i < response.result.length; i++) {
          const textInput = {
            id: response.result[i].id,
            name: response.result[i].name,
            driver_value: response.result[i].driver_value,
            improvement_value: response.result[i].improvement_value,
            financial_value: response.result[i].financial_value,
          };
          updatedTextFields.push(textInput);
        }
        this.textInputFields = updatedTextFields;
      }
      this.benefitLoader = false;
    });
  }

  saveDriverSubTypes(form: NgForm): void {
    this.benefitLoader = true;
    let name = '';
    let driver_value = '';
    let improvement_value = '';
    let financial_value = '';
    for (const i in form.value) {
      if (form.value[i] && i.includes('driverSubTypesName')) {
        name += form.value[i] + ',';
      } else if (form.value[i] && i.includes('driverSubTypesDriverValue')) {
        driver_value += form.value[i] + ',';
      } else if (form.value[i] && i.includes('driverSubTypesImpValue')) {
        improvement_value += form.value[i] + ',';
      } else if (form.value[i] && i.includes('driverSubTypesFinancialValue')) {
        financial_value += form.value[i] === '0' ? '1,' : form.value[i] + ',';
      }
    }
    name = name.slice(0, -1);
    const payload = {
      driver_factor_id: this.driverFactorId,
      name: name,
      driver_value: driver_value,
      improvement_value: improvement_value,
      financial_value: financial_value,
    };
    this.subscriptioncreateDriverFactorSubTypes = this.solutionService.createDriverFactorSubTypes(payload).subscribe(() => {
      this.benefitLoader = false;
      this.getBenefits('all', this.solutionId);
      this.notification.success('Driver SubTypes added successfully', false);
      this.toggleAddSubType = false;
    });
  }

  open(content): void {
    this.deleteModalReference = this.modalService.open(content, {
      windowClass: 'deleteModal secondLayerModalWindow',
      backdropClass: 'secondLayerModalBackdrop',
      backdrop: 'static',
      keyboard: false,
    });
    this.deleteModalReference.result.then(
      () => {},
      () => {}
    );
  }

  deleteDriverSubType(id): void {
    this.benefitLoader = true;
    this.subscriptiondeleteDriverFactorSubTypes = this.solutionService.deleteDriverFactorSubTypes(id).subscribe((result) => {
      this.notification.success(result.result, false);
      this.benefitLoader = false;
      this.getBenefits('all', this.solutionId);
      this.toggleAddSubType = false;
    });
  }

  addScratchpads(content, benefit, type): void {
    this.driverScratchpadFields = [
      {
        id: 1,
        factor: [],
        operator: undefined,
        openParen: undefined,
        closeParen: undefined,
        operand: 0,
      },
    ];
    if (type === 'driverFactor') {
      this.templateSetting = type;
    } else if (type === 'financialFactor') {
      this.templateSetting = type;
    } else if (type === 'improvementFactor') {
      this.templateSetting = type;
    }
    this.driverScratchpadFactor_default = [];
    this.driverScratchpadFactorValue_default = 0;
    this.OpenParen_default = undefined;
    this.CloseParen_default = undefined;
    this.getSolutionsScratchpad(benefit);
    this.toggleScratchpadFactor = true;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    this.scratchpadsInfo = benefit;
  }

  driverScratchpadClose(params): void {
    this.driverScratchpadFields = this.driverScratchpadFields.map((driverField: any) => {
      if (driverField.id === params) {
        driverField.factor = [];
        driverField.operator = undefined;
        driverField.openParen = undefined;
        driverField.closeParen = undefined;
        driverField.operand = 0;
      }
      return driverField;
    });
    this.driverScratchpadFields = this.driverScratchpadFields.filter((driverField: any) => {
      return driverField.id !== params;
    });
  }

  getSolutionsScratchpad(benefit): void {
    const driverScratchpadFields = [];
    this.benefitLoader = true;
    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    const factorId =
      this.templateSetting === 'driverFactor' ? benefit.driver_factor_id : this.templateSetting === 'financialFactor' ? benefit.financial_factor_id : benefit.improvement_factor_id;
    this.subscriptiongetSolutionsScratchpads = this.solutionService.getSolutionsScratchpads(typeId, factorId, benefit.id).subscribe((response) => {
      if (response.result.units) {
        this.driverScratchpadFactor_default = [
          {
            id: response.result.units[0].factor_id,
            name: response.result.units[0].name,
          },
        ];
        this.OpenParen_default = response.result.units[0].open_paren === '1' ? '(' : undefined;
        this.CloseParen_default = response.result.units[0].close_paren === '1' ? ')' : undefined;
        this.driverScratchpadFactorValue_default = response.result.units[0].operand;
        for (let i = 1; i < response.result.units.length; i++) {
          const j = i - 1;
          const scratchpadInput = {
            id: response.result.units[i].id,
            factor: [
              {
                id: response.result.units[i].factor_id,
                name: response.result.units[i].name,
              },
            ],
            operator: response.result.units[j].operator_id,
            openParen: response.result.units[i].open_paren === '1' ? '(' : undefined,
            closeParen: response.result.units[i].close_paren === '1' ? ')' : undefined,
            operand: response.result.units[i].operand,
          };
          driverScratchpadFields.push(scratchpadInput);
        }
        this.benefitLoader = false;
        this.driverScratchpadFields = driverScratchpadFields;
      } else {
        this.benefitLoader = false;
      }
    });
  }
  deleteScratchpad(): void {
    this.benefitLoader = true;
    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    const factorId =
      this.templateSetting === 'driverFactor'
        ? this.scratchpadsInfo.driver_factor_id
        : this.templateSetting === 'financialFactor'
        ? this.scratchpadsInfo.financial_factor_id
        : this.scratchpadsInfo.improvement_factor_id;
    this.subscriptiondeleteSolutionsScratchpads = this.solutionService.deleteSolutionsScratchpads(typeId, factorId, this.scratchpadsInfo.id).subscribe((result) => {
      this.notification.success(result.result, false);
      this.benefitLoader = false;
      this.modalReference.close();
      this.getBenefits('all', this.solutionId);
      this.deleteModalReference.close();
    });
  }

  saveDriverScratchpads(form: NgForm): boolean {
    if (!form.valid) {
      this.addScratchPadError = 'An Operator, Driver Factor and a Value are required.';
      return false;
    }
    this.addScratchPadError = '';
    this.benefitLoader = true;
    let factorValue = this.driverScratchpadFactor_default[0]['id'] + (this.driverScratchpadFactor_default[0]['id'] ? ',' : '');
    let openParenValue = (this.OpenParen_default ? '1' : '0') + (this.OpenParen_default ? ',' : ',');
    let closeParenValue = (this.CloseParen_default ? '1' : '0') + (this.CloseParen_default ? ',' : ',');

    let operand = this.driverScratchpadFactorValue_default + ',';
    let operatorValue = '';
    for (const i in form.value) {
      if (i.includes('scratchpadDriverFactor')) {
        factorValue += form.value[i][0]['id'] + ',';
      } else if (i.includes('scratchpadDriverValue')) {
        operand += form.value[i] + ',';
      } else if (form.value[i] && i.includes('selectOperator')) {
        operatorValue += form.value[i] + ',';
      } else if (i.includes('scratchpadOpenParen')) {
        if (form.value[i]) {
          openParenValue += (form.value[i] === '(' ? '1' : '0') + ',';
        } else {
          openParenValue += '0,';
        }
      } else if (i.includes('scratchpadCloseParen')) {
        if (form.value[i]) {
          closeParenValue += (form.value[i] === ')' ? '1' : '0') + ',';
        } else {
          closeParenValue += '0,';
        }
      }
    }
    factorValue = factorValue.slice(0, -1);
    operatorValue = operatorValue.slice(0, -1);
    openParenValue = openParenValue.slice(0, -1);
    closeParenValue = closeParenValue.slice(0, -1);
    operand = operand.slice(0, -1);
    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    const payload = {
      scratchpad_factor_id:
        this.templateSetting === 'driverFactor'
          ? this.scratchpadsInfo.driver_factor_id
          : this.templateSetting === 'financialFactor'
          ? this.scratchpadsInfo.financial_factor_id
          : this.scratchpadsInfo.improvement_factor_id,
      scratchpad_factor_type_id: typeId,
      account_solution_metric_id: this.scratchpadsInfo.id,
      factor_id: factorValue,
      operator_id: operatorValue + (operatorValue ? ',5' : ''),
      open_paren: openParenValue,
      close_paren: closeParenValue,
      operand: operand,
    };

    this.subscriptioncreateSolutionScratchpad = this.solutionService.createSolutionScratchpad(payload).subscribe((result) => {
      this.benefitLoader = false;
      this.modalReference.close();
      if (result.result.message) {
        this.notification.success(result.result.message, false);
      } else {
        this.notification.success('Scratchpad added successfully', false);
      }
      this.getBenefits('all', this.solutionId);
      this.solutionService.triggerTabs.next('benefits');
    });
  }
  openBenefitRating(content): void {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  saveBenefitRating(): void {
    const ratingObj = {
      account_solution_metric_id: this.selectedBenefit.id,
      score: this.feedback.rating,
      comment: this.feedback.comment,
    };

    this.subscriptionsaveBenefitFeedback = this.solutionService.saveBenefitFeedback(ratingObj, this.selectedBenefit.id).subscribe(() => {
      this.modalReference.close();
      this.getBenefits('all', this.solutionId);
    });
  }

  showFeedback(content, benefitId): void {
    const modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.subscriptionreadFeedback = this.solutionService.readFeedback(benefitId).subscribe((response) => {
      if (response.result) {
        this.feedbackList = response.result;
      }
    });
  }

  deleteVPBenefit(content, data): void {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
    this.benefitSelectedList = data;
  }

  deleteBenefits(): void {
    this.benefitDeleteLoader = true;
    const payload = this.benefitSelectedList.account_solution_id + '/accountSolutionMetricID/' + this.benefitSelectedList.id;
    this.subscriptiondeleteSolutionMetric = this.solutionService.deleteSolutionMetric(payload).subscribe((response) => {
      if (response.result.success === false) {
        this.benefitDeleteLoader = false;
        this.notification.error(response.result.message, false);
      } else {
        this.benefitDeleteLoader = false;
        this.notification.success(response.result.message, false);
      }
      this.modalReference.close();
    });
  }

  openScratchpadTypes(content, data): void {
    const modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.benefitSelectedList = data;
  }

  showSituations(content, data) {
    const modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.benefitSelectedList = data;
  }

  saveBenefitSequence(value_category_id): void {
    const valueCategories = this.valueCategories.find((valueCategory) => valueCategory['value_category_id'] === value_category_id);
    const benefits = valueCategories['benefits'];

    const payload = {
      value_category_id: value_category_id,
      benefit_sequence: [],
    };

    payload['benefit_sequence'] = benefits.map((benefit, index) => {
      return {
        account_solution_metric_id: benefit['id'],
        sequence: index,
      };
    });

    this.subscriptionsaveBenefitSequence = this.solutionService.saveBenefitSequence(payload, this.solutionId).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
      }
    });
  }

  readBenefitsByProject(project_id): void {
    this.subscriptionreadBenefitsByProject = this.solutionService.readBenefitsByProject(project_id, this.solutionId).subscribe(() => {});
  }

  getProjects(): void {
    this.subscriptiongetProjects = this.solutionService.getProjects(this.solutionId).subscribe((response) => {
      this.projectsList = [];
      if (response.result) {
        this.projectsList = response.result;
      }
    });
  }

  loadSelectedProjectBenefits(selectedProject): void {
    this.selectedProject = selectedProject.id;
    this.valueCategoryBenefitLoader = true;
    this.subscriptionreadBenefitsByValueCategoryAndProject = this.solutionService
      .readBenefitsByValueCategoryAndProject('all', this.selectedProject, this.solutionId)
      .subscribe((response) => {
        this.valueCategoryBenefitLoader = false;
        this.initialValueCategories = response.result;
        this.valueCategories = response.result;
      });
  }

  processData(): void {
    this.newFactor = '';
    this.factorCreateLoader = false;
    this.toggleScratchpadFactor = true;
    this.notification.success('Factor created successfully', false);
    this.getAggregatedFactors();
  }

  createScratchpadFactor(): void {
    this.factorCreateLoader = true;
    const FactorName = {
      name: this.newFactor,
      account_id: sessionStorage.getItem('aid'),
    };

    if (this.templateSetting === 'driverFactor') {
      this.subscriptioncreateDriverFactor = this.factsService.createDriverFactor(FactorName).subscribe(() => {
        this.processData();
      });
    } else if (this.templateSetting === 'financialFactor') {
      this.subscriptioncreateFinancialFactor = this.factsService.createFinancialFactor(FactorName).subscribe(() => {
        this.processData();
      });
    } else if (this.templateSetting === 'improvementFactor') {
      this.subscriptioncreateImprovementFactor = this.factsService.createImprovementFactor(FactorName).subscribe(() => {
        this.processData();
      });
    }
  }

  checkBenefits(category: ValueCategoryMultiSelect, benefit: BenefitSelect): void {
    benefit.checked = !benefit.checked;
    if (category.benefits.every((benefit) => benefit.checked)) {
      category.allCheck = true;
      category.partialCheck = false;
    } else if (category.benefits.some((benefit) => benefit.checked)) {
      category.allCheck = false;
      category.partialCheck = true;
    } else {
      category.allCheck = false;
      category.partialCheck = false;
    }
    this.updateActionBar();
  }

  checkBenefitCategory(category: ValueCategoryMultiSelect) {
    if (category.benefits.every((benefit) => benefit.checked)) {
      category.benefits = category.benefits.map((benefit) => ({ ...benefit, checked: false }));
      category.allCheck = false;
      category.partialCheck = false;
    } else {
      category.benefits = category.benefits.map((benefit) => ({ ...benefit, checked: true }));
      category.allCheck = true;
      category.partialCheck = false;
    }
    this.updateActionBar();
  }

  cloneStart(): void {
    this.checkBenefitsSelected();
    this.toggleClone = true;
  }

  cancelClone(): void {
    this.toggleClone = false;
  }

  cloneSpecific(): void {
    this.benefitsSelectedCheckbox = [];
    this.benefitsSelectedCheckbox.push({ ...this.selectedElem, checked: false } as unknown as BenefitSelect);
    this.toggleClone = true;
  }

  triggerClone(): void {
    this.cloning = true;
    const benefitToClone = this.benefitsSelectedCheckbox.map((benefit) => ({ id: +benefit.id }));
    this.solutionService
      .cloneBenefits(this.solutionId, +this.solutionSelected.id, benefitToClone)
      .pipe(finalize(() => (this.cloning = false)))
      .subscribe({
        next: () => {
          if (+this.solutionId === +this.solutionSelected.id) {
            this.getBenefits('all', this.solutionId);
          }
          this.notification.success(this.trans.trans.benefitsClonedSuccess.value, false);
          this.toggleClone = false;
          this.cancelBenefitSelection();
        },
        error: () => {
          this.notification.error(this.trans.trans.benefitsCloneError.value, false);
        },
      });
  }

  deleteBenefitsBundle(): void {
    this.adminActionbar = false;
    this.loaded = false;
    this.checkBenefitsSelected();
    const deleteBundle: { [klass: string]: Observable<{ result: { success: boolean } }> } = this.benefitsSelectedCheckbox.reduce((requestObj, benefit, index) => {
      requestObj[index] = this.solutionService.deleteSolutionMetric(`${benefit.account_solution_id}/accountSolutionMetricID/${benefit.id}`).pipe(
        catchError((response) =>
          of({
            result: {
              success: false,
              message: response.result.message,
              benefitName: `${benefit.metric_name}`,
            },
          })
        )
      );
      return requestObj;
    }, {});
    forkJoin(deleteBundle).subscribe((response: BulkDeleteResponse) => {
      const responseArray = Object.values(response);
      if (responseArray.every((response) => response.result.success)) {
        this.notification.success(responseArray[0].result.message, false);
      } else if (responseArray.some((response) => response.result.success)) {
        const benefitsError = responseArray.filter((response) => !response.result.success).map((response) => response.result.benefitName);
        this.notification.warning(`${this.trans.trans.partialDelete.value}  ${benefitsError.join(', ')}`, false);
      } else {
        this.notification.error(responseArray[0].result.message, false);
      }
      this.getBenefits('all', this.solutionId);
    });
  }

  cancelBenefitSelection(): void {
    this.valueCategories.forEach((category) => {
      category.allCheck = false;
      category.partialCheck = false;
      category.benefits.forEach((benefit) => (benefit.checked = false));
    });
    this.adminActionbar = false;
  }

  closeCloneSidebar(): void {
    this.toggleClone = false;
    this.solutionSelected = null;
  }

  private checkBenefitsSelected() {
    this.benefitsSelectedCheckbox = [];
    this.valueCategories.forEach((category) => {
      const selectedBenefits = category.benefits.filter((benefit) => benefit.checked);
      this.benefitsSelectedCheckbox = [...this.benefitsSelectedCheckbox, ...selectedBenefits];
    });
  }

  private getSolutions(): void {
    this.solutionsLoading = true;

    let searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc?exclude_shared=true';
    this.solutionService.getAvailableSolutionsFull(searchLimit).subscribe((response) => {
      if (response.result) {
        this.solutions = response.result.data.sort((modA, modB) => modA.sequence - modB.sequence);
      }
      this.solutionsLoading = false;
    });
  }

  private insertCheckControl(categories: ValueCategory[]): ValueCategoryMultiSelect[] {
    return categories.map((category) => {
      return {
        ...category,
        allCheck: false,
        partialCheck: false,
        benefits: category.benefits ? category.benefits.map((benefit) => ({ ...benefit, checked: false })) : null,
      };
    });
  }

  private updateActionBar(): void {
    let benefits = 0;
    this.valueCategories.forEach((category) => {
      benefits += category.benefits.filter((benefit) => benefit.checked).length;
    });
    this.benefitsCount = benefits;
    if (this.benefitsCount) {
      this.adminActionbar = true;
    } else {
      this.adminActionbar = false;
    }
  }
}
