import { Component, OnInit, Input, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ActivatedRoute } from '@angular/router';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AECollateralTranslations } from './ae_collateral.translation';
import { ValuePropCollateral } from '@shared/models/collateral.model';
import { FormatType } from '@shared/models/format-type.model';

@Component({
	selector: 'app-ae-collateral',
	templateUrl: './ae_collateral.component.html',
	providers: [FactsService]
})
export class AECollateralComponent implements OnInit, OnDestroy {
	@Input() showHelp;
	@Input() can_edit: boolean = false;
	@Input() contextualHelp;
	@Input() solutionID: number | string;
	@Input() valuePropId: number;
	@Input() typeOfCollateral: 'Add' | 'Edit' = "Add";

	@Input() showTranslate: boolean = false;

	@Output() callback = new EventEmitter();

	ngUnsubscribe = new Subject();

	collateral: any = [];
	fileFormatTypes: FormatType[] = [];
	accountSolutionId: number;

	@ViewChild('formAddEditCollateral') formAddEditCollateral: NgForm;

	@Input() collateralFormObj: Partial<ValuePropCollateral> = {
        id: null,
		format_type_id: undefined,
		name: undefined,
		description: undefined,
		url: undefined
	};

	loadingSave: boolean = false;
	toggleAddCollateral: boolean = false;
	sidebar_title: any = {};


	cols = [
		{ field: 'format', header: 'Format' },
		{ field: 'name', header: 'Name' },
		{ field: 'url', header: 'URL' },
		{ field: 'url', header: 'Default Active' }
	]

	constructor(private solutionService: SolutionService,
		// private ValuepropService: ValuepropService,
		private route: ActivatedRoute,
		private factsService: FactsService,
		private commonService: CommonService,
		private notification: NotificationService,
		private translationService: TranslationsV2Service,
		public trans: AECollateralTranslations

	) { }

	ngOnInit() {

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => this.accountSolutionId = params['id']);

		if (!this.accountSolutionId) {
			this.accountSolutionId = +this.solutionID;
		}

		this.getFileFormats();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	close(): void {
		this.callback.emit();
	}


	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

			this.typeOfCollateral == "Add" ? this.sidebar_title = this.trans.trans.addCollateral :
			 this.sidebar_title = this.trans.trans.editCollateral;
		});
	}

	getFileFormats() {
		this.factsService.getFileFormatTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.fileFormatTypes = result.result;
		});
	}



	SaveCollateral(form: NgForm) {
		this.loadingSave = true;
		let payload = {
			"asset_id": this.collateralFormObj.id,
			"format_type_id": form.controls.format_type_id.value,
			"name": form.controls.name.value,
			"description": form.controls.description.value,
			"url": form.controls.url.value
		};

		payload['account_solution_id'] = this.accountSolutionId;

		if (this.typeOfCollateral == "Add") {

			this.solutionService.createCollateralList(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
				this.toggleAddCollateral = false;

				this.notification.success("Collateral created successfully", false); // Params {message, islogout}
				this.callback.emit("");
			});
		}
		else {

			this.solutionService.updateCollateral(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
				this.toggleAddCollateral = false;

				this.notification.success("Collateral updated successfully", false); // Params {message, islogout}
				this.callback.emit("");
			});
		}

	}

}
