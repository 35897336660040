import { Injectable } from '@angular/core';

@Injectable()
export class ModelScalerDefaultsTranslation {
    public config: any = {
        component: 'model-scaler-defaults',
        display_name: 'Model Scaler Defaults',
        description: 'All the scaler defaults in a solution',
    };

    public trans: any = {
        scalers: 'Set Default Scaler Values',
        availableScalers: 'Available Scalers',
        savedMessage: 'Scalers updated successfully',
        setDefault: 'Set Default',
        save: 'Save',
        reset: 'Reset',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
        requiredValidation: 'Please provide a value for each selected scaler',
    };
}
