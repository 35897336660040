import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { GlobalSearchService } from '@data/services/search/search.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SearchTranslations } from './search.translation';

@Component({
  selector: 'app-global-search-header',
  templateUrl: 'search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class GlobalSearchComponent implements OnInit {
  @ViewChild('g_overlay') gOverlay: ElementRef;
  ngUnsubscribe = new Subject();
  toggleGlobalSearch = false;
  strSearch = '';
  res: { [klass: string]: any }[] = [];
  style2022$: Observable<boolean>;

  rollup: { type: string; url: string; name: string }[] = [];
  constructor(
    private elementRef: ElementRef,
    private searchService: GlobalSearchService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private styleService: StyleService,
    public trans: SearchTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
  }

  funcToggleOverlay(): void {
    this.funcGlobalSearch();
  }

  funcGlobalSearch(): void {
    this.changeDetector.detectChanges();
    this.toggleGlobalSearch = true;

    const myDiv = this.elementRef.nativeElement.querySelector('.global_overlay');
    myDiv.style.display = 'block';

    this.funcSearch();
  }

  funcCloseSearch(): void {
    this.toggleGlobalSearch = false;

    this.rollup = [];
    this.strSearch = '';
    const myDiv = this.elementRef.nativeElement.querySelector('.global_overlay');
    myDiv.style.display = 'none';
  }

  funcShowAllResults(): void {
    this.searchService.notifySearch.next({ term: this.strSearch, res: this.res });

    this.router.navigate(['/search', this.strSearch]);

    this.funcCloseSearch();
  }

  funcSearch(): void {
    if (this.strSearch) {
      const payload = {
        searchTerm: this.strSearch,
        publicOnly: 0,
        synopsisOnly: 1,
        limit: 'all',
        page: 1,
        sortBy: 'name',
        order: 'asc',
      };

      this.searchService
        .getGlobalSearch(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(debounceTime(1000))
        .subscribe((res) => {
          this.res = res;
          this.rollup = res.result.rollup;
        });
    }
  }

  funcGetStyle(): { 'border-bottom-left-radius': string } {
    if (this.toggleGlobalSearch) {
      return { 'border-bottom-left-radius': '15px !important' };
    } else {
      return { 'border-bottom-left-radius': '0px !important' };
    }
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
