

<div class="keywest_panel">


    <div class="row">
      <div class="col-xs-12">
        <div class="title">
          {{this.situationView.name}}
        </div>
      </div>
    </div>

      <div class="row">
        <div class="col-md-12">
          <div>
            <label for="situation_type" class="sidebar_label_readonly">{{trans.trans.situationType.value}}
              			<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.situationType" (callback)="getTranslations()"></app-editTranslation>
            </label><br>
            {{situationTypeSelected?.name}}
          </div>
        </div>
      </div>

    <div class="row">
      <div class="col-md-12">
        <label class="sidebar_label_readonly">{{trans.trans.selectSituationElement.value}}
          			<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.selectSituationElement" (callback)="getTranslations()"></app-editTranslation>
        </label><br>
        {{situationView.situation_element_id}}

      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="sidebar_label_readonly">{{trans.trans.description.value}}
          			<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
        </label><br>
        {{situationView.description}}
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="sidebar_label_readonly">{{trans.trans.activeDefault.value}}
          			<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.activeDefault" (callback)="getTranslations()"></app-editTranslation>
        </label><br>
        {{situationView.default_active === '0' ? trans.trans.notActive.value : trans.trans.active.value}}
        <!-- <p-inputSwitch readonly="true"  [(ngModel)]="situationView.defaultActive" ret-data="Default Active"></p-inputSwitch> -->
      </div>

    </div>


    <div class="sidebar_container_full_page" *ngIf="situationsItemsMenuView">


      <div class='keywest_page_header'>
          <div class='row'>
            <div class="col-xs-6">
              <span><i (click)='toggleBenefits = !toggleBenefits' *ngIf="!toggleBenefits" class='fa fa-chevron-right' style='margin-right: 10px;'></i></span>
              <span><i (click)='toggleBenefits = !toggleBenefits' *ngIf="toggleBenefits" class='fa fa-chevron-down' style='margin-right: 10px;'></i></span>
              <span (click)='toggleBenefits =!toggleBenefits' class='clickable title_no_float'>{{trans.trans.benefits.value}}
                <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
                [transObj]="trans.trans.benefits" (callback)="getTranslations()"></app-editTranslation>
              </span>
            </div>
          </div>
       </div>

      <div *ngIf="toggleBenefits">

        <div>
            <div class="row">
              <div class='col-xs-4'>
                <div class="input-group keywest_search">
                  <span class="input-group-addon"><i class='fa fa-search'></i></span>
                  <input [(ngModel)]="strSearchBenefits" (keyup)="benefitsTable.filter($any($event).target.value, 'metric_name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control' [ngModelOptions]="{standalone: true}">
                  <span *ngIf="strSearchBenefits !== ''" (click)="strSearchBenefits = ''; benefitsTable.filter('', 'metric_name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
                </div>
              </div>
            </div>

        <p-table #benefitsTable [columns]="benefitsCols" [value]="benefits" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{trans.trans[col.field].value}}
                <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-situationBenefit>
            <tr>
              <td>{{situationBenefit.account_solution_metric_name}}
                <i *ngIf="situationBenefit.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="situationBenefit.description" matTooltipPosition="above"></i>
                <span *ngIf="situationBenefit.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situationBenefit.description" matTooltipPosition="above">
                  info
                </span>
              </td>
              <td>
                <span class="off" *ngIf="situationBenefit.default_na === '1'">
                  {{trans.trans.non_applicable_by_default.value}}
                </span>
              </td>
              <td>
                <div *ngIf="situationBenefit.changeToggle">
                  <span class="off" *ngIf="situationBenefit.changedActive==0"><strong>{{trans.trans.off.value}}</strong></span>
                  <span class="on" *ngIf="situationBenefit.changedActive==1"><strong>{{trans.trans.on.value}}</strong></span>
                </div>
              </td>
              <td>
                <div *ngIf="situationBenefit.valueChangeToggle">
                  <span class="on">{{trans.trans.values_changed.value}}</span>
                </div>
              </td>


              <td></td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      </div>

      <div class='keywest_page_header'>
        <div class='row'>
          <div class="col-xs-6">
          <span><i (click)='toggleCosts = !toggleCosts' *ngIf="!toggleCosts" class='fa fa-chevron-right' style='margin-right: 10px;'></i></span>
          <span><i (click)='toggleCosts = !toggleCosts' *ngIf="toggleCosts" class='fa fa-chevron-down' style='margin-right: 10px;'></i></span>
          <span (click)='toggleCosts =!toggleCosts' class='clickable title_no_float'>{{trans.trans.costs.value}}
            <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.costs" (callback)="getTranslations()"></app-editTranslation>
          </span>
          </div>
        </div>
     </div>

      <div *ngIf="toggleCosts">
        <div>
          <div class="row">
            <div class='col-xs-4'>
              <div class="input-group keywest_search">
                <span class="input-group-addon"><i class='fa fa-search'></i></span>
                <input [(ngModel)]="strSearchCosts" (keyup)="costsTable.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control' [ngModelOptions]="{standalone: true}">
                <span *ngIf="strSearchCosts !== ''" (click)="strSearchCosts = ''; costsTable.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
              </div>
            </div>
        </div>

        <p-table #costsTable [columns]="costsCols" [value]="costs" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{trans.trans[col.field].value}}
                  <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-situationCost>
            <tr>
              <td>{{situationCost.account_solution_cost_name}}
                <i *ngIf="situationCost.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="situationCost.description" matTooltipPosition="above"></i>
                <span *ngIf="situationCost.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situationCost.description" matTooltipPosition="above">
                  info
                </span>
              </td>

              <td>
                <div *ngIf="situationCost.changeToggleAvailable == 1">
                  <span class="on">{{trans.trans.cost_added_to_available_cost.value}}</span>
                </div>
              </td>
              <td>
                <div *ngIf="situationCost.changeToggleAutoload == 1">
                  <span class="on">{{trans.trans.cost_added_to_autoload.value}}</span>
                </div>
                <div *ngIf="situationCost.changeToggleAutoload === '0'">
                  <span class="off">{{trans.trans.removed_from_autoload.value}}</span>
                </div>
              </td>

            </tr>
          </ng-template>
        </p-table>
      </div>

      </div>

      <div class='keywest_page_header'>
        <div class='row'>
          <div class="col-xs-6">
          <span><i (click)='toggleCaseStudies = !toggleCaseStudies' *ngIf="!toggleCaseStudies" class='fa fa-chevron-right' style='margin-right: 10px;'></i></span>
          <span><i (click)='toggleCaseStudies = !toggleCaseStudies' *ngIf="toggleCaseStudies" class='fa fa-chevron-down' style='margin-right: 10px;'></i></span>
          <span (click)='toggleCaseStudies =!toggleCaseStudies' class='clickable title_no_float'>{{trans.trans.caseStudies.value}}
              <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
              [transObj]="trans.trans.caseStudies" (callback)="getTranslations()"></app-editTranslation>
          </span>
          </div>
        </div>
     </div>

      <div *ngIf="toggleCaseStudies">
        <div>
          <div class="row">
            <div class='col-xs-4'>
              <div class="input-group keywest_search">
                <span class="input-group-addon"><i class='fa fa-search'></i></span>
                <input [(ngModel)]="strSearchCaseStudies" (keyup)="caseStudiesTable.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}'class='form-control' [ngModelOptions]="{standalone: true}">
                <span *ngIf="strSearchCaseStudies !== ''" (click)="strSearchCaseStudies = ''; caseStudiesTable.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
              </div>
            </div>
        </div>

        <p-table #caseStudiesTable [columns]="costsCols" [value]="caseStudies" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{trans.trans[col.field].value}}
                  <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-situationCaseStudies>
            <tr>
              <td>{{situationCaseStudies.account_case_study_name}}
                <i *ngIf="situationCaseStudies.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="situationCaseStudies.description" matTooltipPosition="above"></i>
                <span *ngIf="situationCaseStudies.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situationCaseStudies.description" matTooltipPosition="above">
                  info
                </span>
              </td>

              <td>
                <div *ngIf="situationCaseStudies.changedActive !== null && situationCaseStudies.changedActive !== false">
                  <span class="off" *ngIf="situationCaseStudies.changedActive=='0'"><strong>{{trans.trans.off.value}}</strong></span>
                  <span class="on" *ngIf="situationCaseStudies.changedActive=='1'"><strong>{{trans.trans.on.value}}</strong></span>
                </div>
              </td>


            </tr>
          </ng-template>
        </p-table>
      </div>

          <div *ngIf="situations.length == 0" class="m-t-md col-sm-10 comp_no_data">
            <div class="searchTableContainer text-center">
              <h4>{{trans.trans.no_case_studies_yet.value}}</h4>
            </div>
          </div>

      </div>

      <div class='keywest_page_header'>
        <div class='row'>
          <div class="col-xs-6">
          <span><i (click)='toggleAssets = !toggleAssets' *ngIf="!toggleAssets" class='fa fa-chevron-right' style='margin-right: 10px;'></i></span>
          <span><i (click)='toggleAssets = !toggleAssets' *ngIf="toggleAssets" class='fa fa-chevron-down' style='margin-right: 10px;'></i></span>
          <span (click)='toggleAssets =!toggleAssets' class='clickable title_no_float'>{{trans.trans.assets.value}}
              <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
              [transObj]="trans.trans.assets" (callback)="getTranslations()"></app-editTranslation>
          </span>
          </div>
        </div>
     </div>

      <div *ngIf="toggleAssets">
        <div>
          <div class="row">
            <div class='col-xs-4'>
              <div class="input-group keywest_search">
                <span class="input-group-addon"><i class='fa fa-search'></i></span>
                <input [(ngModel)]="strSearchCaseStudies" (keyup)="assetsTable.filter($any($event.target).value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}'class='form-control' [ngModelOptions]="{standalone: true}">
                <span *ngIf="strSearchCaseStudies !== ''" (click)="strSearchCaseStudies = ''; assetsTable.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
              </div>
            </div>
        </div>

        <p-table #assetsTable [columns]="assetsCols" [value]="assets" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{trans.trans[col.field].value}}
                <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-situationAssets>
            <tr>
              <td>
                <i class='{{situationAssets.icon}}' [ngStyle]="getFAColor(situationAssets.format)"></i>
                {{situationAssets.id}}
                <i *ngIf="situationAssets.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="situationAssets.description" matTooltipPosition="above"></i>
                <span *ngIf="situationAssets.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situationAssets.description" matTooltipPosition="above">
                  info
                </span>
              </td>

              <td>
                <div *ngIf="situationAssets.changedActive !== null && situationAssets.changedActive !== false">
                  <span class="off" *ngIf="situationAssets.changedActive=='0'"><strong>{{trans.trans.off.value}}</strong></span>
                  <span class="on" *ngIf="situationAssets.changedActive=='1'"><strong>{{trans.trans.on.value}}</strong></span>
                </div>
              </td>

            </tr>
          </ng-template>
        </p-table>
      </div>

      </div>

      <div class='keywest_page_header'>
        <div class='row'>
          <div class="col-xs-6">
          <span><i (click)='toggleCollateral = !toggleCollateral' *ngIf="!toggleCollateral" class='fa fa-chevron-right' style='margin-right: 10px;'></i></span>
          <span><i (click)='toggleCollateral = !toggleCollateral' *ngIf="toggleCollateral" class='fa fa-chevron-down' style='margin-right: 10px;'></i></span>
          <span (click)='toggleCollateral =!toggleCollateral' class='clickable title_no_float'>{{trans.trans.collateral.value}}
              <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
              [transObj]="trans.trans.collateral" (callback)="getTranslations()"></app-editTranslation>
          </span>
          </div>
        </div>
     </div>

      <div *ngIf="toggleCollateral">
        <div>
          <div class="row">
            <div class='col-xs-4'>
              <div class="input-group keywest_search">
                <span class="input-group-addon"><i class='fa fa-search'></i></span>
                <input [(ngModel)]="strSearchCaseStudies" type='text' placeholder='{{trans.trans.search.value}}'class='form-control' [ngModelOptions]="{standalone: true}">
                <span *ngIf="strSearchCaseStudies !== ''" (click)="strSearchCaseStudies = ''" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
              </div>
            </div>
        </div>

        <p-table #assetsTable [columns]="collateralCols" [value]="collateral" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{trans.trans[col.field].value}}
                  <app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-situationCollateral>
            <tr>
              <td>{{situationCollateral.id}}
                <i *ngIf="situationCollateral.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="situationCollateral.description" matTooltipPosition="above"></i>
                <span *ngIf="situationCollateral.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situationCollateral.description" matTooltipPosition="above">
                  info
                </span>
              </td>

              <td>
                <div *ngIf="situationCollateral.changedActive !== null && situationCollateral.changedActive !== false">
                  <span class="off" *ngIf="situationCollateral.changedActive=='0'"><strong>{{trans.trans.off.value}}</strong></span>
                  <span class="on" *ngIf="situationCollateral.changedActive=='1'"><strong>{{trans.trans.on.value}}</strong></span>
                </div>

            </tr>
          </ng-template>
        </p-table>
      </div>

      </div>


      <div class='keywest_page_header' *ngIf="masterValuePropFeature">
        <div class='row'>
          <div class="col-xs-6">
          <span><i (click)='toggleProjects = !toggleProjects' *ngIf="!toggleProjects" class='fa fa-chevron-right' style='margin-right: 10px;'></i></span>
          <span><i (click)='toggleProjects = !toggleProjects' *ngIf="toggleProjects" class='fa fa-chevron-down' style='margin-right: 10px;'></i></span>
          <span (click)='toggleProjects =!toggleProjects' class='clickable title_no_float'>{{trans.trans.project.value}}</span>
          </div>
        </div>
     </div>

      <div *ngIf="toggleProjects">
        <div>
          <div class="row">
            <div class='col-xs-4'>
              <div class="input-group keywest_search">
                <span class="input-group-addon"><i class='fa fa-search'></i></span>
                <input [(ngModel)]="strSearchCaseStudies" type='text' placeholder='{{trans.trans.search.value}}'class='form-control' [ngModelOptions]="{standalone: true}">
                <span *ngIf="strSearchCaseStudies !== ''" (click)="strSearchCaseStudies = ''" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
              </div>
            </div>
        </div>

        <p-table #projectsTable [columns]="projectCols" [value]="projectsList" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{trans.trans[col.field].value}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-situationProject>
            <tr>
              <td>{{situationProject.id}} - {{situationProject.id}}
                <i *ngIf="situationProject.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="situationProject.description" matTooltipPosition="above"></i>
                <span *ngIf="situationProject.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situationProject.description" matTooltipPosition="above">
                  info
                </span>
              </td>

              <td>
                <div *ngIf="situationProject.active == '1'">
                  <span class="on">{{trans.trans.included_by_default.value}}</span>
                </div>
              </td>
              <td>
                <div *ngIf="situationProject.changedRecommend == '1'">
                  <span class="on">{{trans.trans.recommended.value}}</span>
                </div>
              </td>
              <td>
                <div *ngIf="situationProject.changedActive == '1'">
                  <span class="on">{{trans.trans.add_to_list.value}}</span>
                </div>
                <div *ngIf="situationProject.changedActive === '0'">
                  <span class="off">{{trans.trans.removed_from_list.value}}</span>
                </div>
              </td>

            </tr>
          </ng-template>
        </p-table>
      </div>

      </div>

    </div>
