import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { BenefitsCasestudyComponent } from './benefits-casestudy.component';
import { BenefitsCasestudyTranslations } from './benefits-casestudy.translation';

@NgModule({
  declarations: [BenefitsCasestudyComponent],
  imports: [NgbModule, InputMaskModule, FormsModule, CommonModule, TabViewModule],
  providers: [Translations, TranslationsV2Service, BenefitsCasestudyTranslations],
  entryComponents: [BenefitsCasestudyComponent],
  exports: [BenefitsCasestudyComponent],
})
export class ModelBenefitsCaseStudyModule {}
