<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header_small">
      <div class="row">
        <div class="col-xs-10">
          <div [class.title]="!(style2022$ | async)" class="settings-title">
            {{ trans.trans.heading.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.heading"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-2">
          <span
            *ngIf="style2022$ | async"
            routerLink="/team"
            [class.link]="style2022$ | async"
            class="pull-right clickable"
            [matTooltip]="trans.trans.your_team.value"
            matTooltipPosition="below"
            container="body"
            >{{trans.trans.edit.value}}</span
          >
          <i
            *ngIf="!(style2022$ | async)"
            routerLink="/team"
            class="fa fa-pencil keywest_header_icon pull-right clickable"
            [matTooltip]="trans.trans.your_team.value"
            matTooltipPosition="below"
            container="body"
          ></i>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'row-maui': style2022$ | async }">
      <div [class.marg-h]="style2022$ | async" [class.row]="!(style2022$ | async)" class="card-maui padding-12-x" *ngFor="let member of teamMembersList">
        <div [class.marg-v]="style2022$ | async" [class.col-xs-2]="!(style2022$ | async)" class="flex-jcc">
          <img
            class="img-responsive img-circle"
            style="width: 40px; height: 40px; max-width: 40px"
            [src]="member.profile_pic_filepath != '' ? member.profile_pic_filepath : profilepicdefaultpath"
          />
        </div>
        <div [class.col-xs-10]="!(style2022$ | async)">
          <span *ngIf="!(style2022$ | async)">{{ member.user_name }}</span>
          <h5 class="h5-title" *ngIf="style2022$ | async">{{ member.user_name }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
