export function setWalkMe() {
  const win = window as Window &
    typeof globalThis & { _walkmeConfig: { smartLoad: boolean } };
  const hostname = window.location.hostname;
  let walkMeKey;
  switch (hostname) {
    case "localhost":
    case "develop.ui.value-cloud.com":
      // no walkMe for develop or localhost
      break;

    case "stage.value-cloud.com":
    case "stage-2.value-cloud.com":
      walkMeKey = "https://cdn.walkme.com/users/3194a0fbe4d948588bb5a2b2a1ce111b/test/walkme_3194a0fbe4d948588bb5a2b2a1ce111b_https.js";
      break;

    default:
      walkMeKey = "https://cdn.walkme.com/users/3194a0fbe4d948588bb5a2b2a1ce111b/walkme_3194a0fbe4d948588bb5a2b2a1ce111b_https.js";
      break;
  }
  const walkme = document.createElement('script');
  walkme.type = 'text/javascript';
  walkme.async = true;
  walkme.src = walkMeKey;
  const s = document.getElementsByTagName('script')[0];
  if (walkMeKey) {
    s.parentNode.insertBefore(walkme, s);
    win._walkmeConfig = { smartLoad: true };
  }
}

