import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AccountModule } from './account/account.module';
import { ApiServiceService } from './api-service.service';
import { AppComponent } from './app/app.component';
import { CommonService } from './data/services/common/common.service';
import { ScbHelperService } from './data/services/scb/scb.helper.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { IntakeComponent } from './intake/intake.component';
import { LeftnaviComponent } from './leftnavi/leftnavi.component';
import { LoginComponent } from './login/login.component';
import { MainDashboardModule } from './main-dashboard/main-dashboard.module';
// import { RegistrationComponent } from './registration/registration.component';
import { ScbModule } from './scb/scb.module';
import { SharedModule } from './shared/shared.module';
import { SupportModule } from './support/support.module';
import { ValueMapModule } from './value-map/value-map.module';
import { ValuePositionModule } from './value-position/value-position.module';
import { ValuePropModule } from './value-prop/value-prop.module';
import { ValueRealizationModule } from './value-realization/value-realization.module';

// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// import { LoaderInterceptor, LoaderService, LoaderSharedModule } from '@services/http.interceptor';
import { ModalComponent } from './modal/modal.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ModalLoginComponent } from './login/login.modal';
import { WelcomeComponent } from './welcome/welcome.component';
import { ModalForgotPasswordComponent } from './forgot-password/forgot-password.modal';
import { UserService } from '@data/services/user/user.service';

import { SidebarModule } from 'primeng/sidebar';
import { DashboardComponent } from './main-dashboard/dashboard/dashboard.component';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MenuModule } from 'primeng/menu';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ValueTrackersModule } from './value-trackers/value-trackers.module';

import { ValueMapV2Module } from './value-mapv2/value-map.module';
import { AppSystemComponent } from './app/views/system/system.component';
import { AppImplementation } from './app/app.implementation';
import { AppSystemKeyWestView } from './app/views/keywest/keywest.component';
import { AppSystemCalculatorView } from './app/views/calculators/calculator.component';
import { AppSystemShareView } from './app/views/share/share.component';
import { KeywestTranslations } from './app/views/keywest/keywest.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { InputMaskModule } from 'primeng/inputmask';
import { ForgotPasswordTranslations } from './forgot-password/forgot-password.translation';
import { AuthorizeComponent } from './authorize/authorize.component';
import { GlobalSearchModule } from './search/search.module';
import { GlobalSearchService } from '@data/services/search/search.service';
import { GlobalSearchHeaderModule } from './global_search/search.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { RSV2Module } from './repv2/simple.module';
import { SFDCService } from '@data/services/sfdc/sfdc.service';
import { SamlComponent } from './login/saml/saml.component';
import { SNCxOModule } from './cxo/cxo.module';
import { BridgeModule } from './bridge/bridge.module';
import { UserAuthGuard } from './account/admin-auth-guard/user.guard.service';
import { AssetAuthGuard } from './account/admin-auth-guard/asset.guard.service';
import { AdminAuthGuard } from './account/admin-auth-guard/admin.guard.service';
import { CaseStudiesModule } from './case_studies/case_studies.module';
import { StyleService } from './style.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChooseAdventureComponent } from './value-position/choose-adventure/choose-adventure.component';
import { ChooseAdventureGuard } from './guards/choose-adventure.guard';
import { NotificationService } from '@services/notification.service';
import { MikeynavComponent } from './mikeynav/mikeynav.component';

// Core.module
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`./data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

const appRoutes: Routes = [
  { path: '', component: DashboardComponent, pathMatch: 'full' },
  {
    path: 'vpg',
    loadChildren: () => import('./value-prop-group/list/vpg.list.module').then((m) => m.VPGListModule),
    data: { view: 'vpg', page: 'vpg' },
  },
  {
    path: 'vpg/:vpid',
    loadChildren: () => import('./value-prop-group/group/vpg.module').then((m) => m.VPGModule),
    data: { view: 'vpg', page: 'vpg' },
  },
  {
    path: 'vpg_new',
    loadChildren: () => import('./value-prop-group/new/new.module').then((m) => m.VPGNewModule),
    data: { view: 'vpg', page: 'vpg' },
  },
  {
    path: 'vp/:vphash',
    loadChildren: () => import('./value-prop/value-prop-dashboard/dashboard_simple/simple.module').then((m) => m.DashboardSimpleViewModule),
    data: { view: 'share', page: 'vp' },
  },
  {
    path: 'rep/:vpid',
    loadChildren: () => import('./value-prop/value-prop-dashboard/dashboard_simple/simple.module').then((m) => m.DashboardSimpleViewModule),
    data: { view: 'simple', page: 'dashboard' },
  },
  {
    path: 'rep/:vpid/:step',
    loadChildren: () => import('./value-prop/value-prop-dashboard/dashboard_simple/simple.module').then((m) => m.DashboardSimpleViewModule),
    data: { view: 'simple', page: 'dashboard' },
  },
  {
    path: 'rep',
    loadChildren: () => import('./value-prop/value-prop-dashboard/dashboard_simple/simple.module').then((m) => m.DashboardSimpleViewModule),
    data: { view: 'simple', page: 'dashboard' },
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then((m) => m.GlobalSearchModule),
    data: { view: 'simple', page: 'search' },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    // loadChildren: './main-dashboard/main-dashboard.module#MainDashboardModule',
    data: { page: 'dashboard' },
  },
  {
    path: 'valueprop',
    loadChildren: () => import('./value-prop/value-prop.module').then((m) => m.ValuePropModule),
    data: { page: 'valueprop' },
  },
  {
    path: 'valuemaps',
    loadChildren: () => import('./value-map/value-map.module').then((m) => m.ValueMapModule),
    data: { page: 'valuemaps' },
  },
  {
    path: 'profile',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    data: { page: 'profile' },
  },
  {
    path: 'scb',
    loadChildren: () => import('./scb/scb.module').then((m) => m.ScbModule),
    data: { page: 'scb' },
  },
  {
    path: 'valueposition',
    loadChildren: () => import('./value-position/value-position.module').then((m) => m.ValuePositionModule),
    data: { page: 'valueposition' },
  },
  {
    path: 'valuerealization',
    loadChildren: () => import('./value-realization/value-realization.module').then((m) => m.ValueRealizationModule),
    data: { page: 'valuerealization' },
  },
  {
    path: 'valuerealized',
    loadChildren: () => import('./value-realized/value-realized.module').then((m) => m.ValueRealizedModule),
    data: { page: 'valuerealized' },
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then((m) => m.TestModule),
    data: { page: 'valuerealization' },
  },
  {
    path: 'intake',
    component: IntakeComponent,
  },
  // { path: 'register', component: RegistrationComponent },
  { path: 'choose-adventure/:value_prop_id', component: ChooseAdventureComponent, canActivate: [ChooseAdventureGuard] },
  { path: 'choose-adventure', component: ChooseAdventureComponent, canActivate: [ChooseAdventureGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'authorize', component: AuthorizeComponent },
  { path: 'saml/:hash', component: SamlComponent },
  { path: '**', redirectTo: '/dashboard' },
];

const config = {
  issuer: 'https://dev-610182.oktapreview.com/oauth2/default',
  redirectUri: 'https://jamaicadev.value-cloud.com/implicit/callback',
  clientId: '0oaggbv6hoAHOmEi90h7',
};

@NgModule({
  declarations: [
    AppSystemShareView,
    AppSystemKeyWestView,
    AppSystemComponent,
    ModalComponent,
    AppComponent,
    LeftnaviComponent,
    LoginComponent,
    IntakeComponent,
    ForgotPasswordComponent,
    ModalForgotPasswordComponent,
    ModalComponent,
    WelcomeComponent,
    AppSystemCalculatorView,
    AuthorizeComponent,
    SamlComponent,
    MikeynavComponent,
  ],
  imports: [
    CaseStudiesModule,
    BridgeModule,
    SNCxOModule,
    RSV2Module,
    ProgressBarModule,
    GlobalSearchHeaderModule,
    GlobalSearchModule,
    InputMaskModule,
    SupportModule,
    MatTooltipModule,
    MenuModule,
    EditTranslationsModule,
    SidebarModule,
    BrowserModule,
    SharedModule,
    MainDashboardModule,
    ValueMapModule,
    ValueMapV2Module,
    FormsModule,
    ReactiveFormsModule,
    ValuePropModule,
    AccountModule,
    BrowserAnimationsModule,
    ScbModule,
    ValueRealizationModule,
    ValueTrackersModule,
    ValuePositionModule,
    SupportModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    NgbModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
      // <-- debugging purposes only
    ),
    // OktaAuthModule.initAuth(config)
  ],
  providers: [
    SFDCService,
    AppImplementation,
    NgbActiveModal,
    ApiServiceService,
    CommonService,
    ScbHelperService,
    UserService,
    Translations,
    // DashboardImplementation,
    KeywestTranslations,
    TranslationsV2Service,
    ForgotPasswordTranslations,
    GlobalSearchService,
    AdminAuthGuard,
    AssetAuthGuard,
    UserAuthGuard,
    StyleService,
    SolutionService,
    NotificationService,
  ],
  entryComponents: [
    // ModalLoginComponent,
    AppSystemKeyWestView,
    AppSystemComponent,
    AppSystemCalculatorView,
    AppSystemShareView,
    ModalForgotPasswordComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
