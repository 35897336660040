import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute } from "@angular/router";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ThresholdTranslations } from './thresholds.translation';

@Component({
	selector: 'app-thresholds',
	templateUrl: './thresholds.component.html',
	styleUrls: ['./thresholds.component.scss']
})
export class ThresholdsComponent implements OnInit, OnDestroy {
	@Input() solutionID: string;
	@Input() tabInit: number;


	ngUnsubscribe = new Subject();

	solutionThreshold: any[] = [];
	accountSolutionId: number;
	accountId: number;
	loadthresholdTable: boolean;
	thresholdTypes: any = [];
	// Contextual Help
	contextualHelp: object = {};
	fullImagePath: string = "";
	strSearch: string = "";
	showTranslate = false;

	@Input() can_edit: boolean = false; // can the record be edited or not

	@ViewChild('formAddThreshold') formAddThreshold: NgForm;

	thresholdFormObj = {
		threshold_type_id: null,
		name: "",
		min: null,
		max: null,
		margin: null,
		id: 0
	};

	// new
	addEditThreshold: any;
	loadingSave: boolean = false;
	toggleAddThreshold: boolean = false;
	dropdown: any[] = [];
	sidebar_title: string = "Add New Threshold";
	mode;

	cols = [
		{ field: 'name', header: 'Name' },
		{ field: 'min', header: 'Min' },
		{ field: 'max', header: 'Max' },
		{ field: 'margin', header: 'Margin' }
	]

	constructor(
		private solutionService: SolutionService,
		private commonService: CommonService,
		private notification: NotificationService,
		private route: ActivatedRoute,
		private translationService: TranslationsV2Service,
		public trans: ThresholdTranslations
	) {
		this.fullImagePath = commonService.getLoaderImageUrl();

	}

	ngOnInit() {
		this.accountSolutionId = parseInt(this.route.snapshot.paramMap.get('id'));
		this.accountId = parseInt(sessionStorage.getItem('aid'));
		this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			this.contextualHelp = response['Model'];
		});
		this.getThresholdList();

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	funcToggleView(elem) {
		this.sidebar_title = this.trans.trans.sidebar_title_view.value;
		this.thresholdFormObj = Object.assign({}, elem);
		this.toggleAddThreshold = true;
		this.mode = this.can_edit ? 'edit' : 'view';
	}
	funcShowAddThreshold() {
		this.toggleAddThreshold = !this.toggleAddThreshold;
		this.sidebar_title = this.trans.trans.sidebar_title_add.value;
		this.mode = 'add';
	}

	funcToggleEdit(elem) {
		this.sidebar_title = this.trans.trans.sidebar_title_edit.value;
		this.mode = 'edit';
		this.toggleAddThreshold = true;
		this.thresholdFormObj = Object.assign({}, elem);
	}

	getThresholdList() {
		this.loadthresholdTable = true;
		this.solutionService.getThresholds(this.accountId, this.accountSolutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result) {
				this.solutionThreshold = response.result;
			}
			this.loadthresholdTable = false;
		});
	}

	deleteThreshold(thresholdtypeid) {
		this.solutionService.deleteThreshold(this.accountSolutionId, thresholdtypeid).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.notification.success("Solution Threshold deleted successfully", false); // Params {message, islogout}
			this.getThresholdList();
		});
	}

	resetThreshold() {
		this.thresholdFormObj = {
			threshold_type_id: null,
			name: "",
			min: null,
			max: null,
			margin: null,
			id: 0
		};
	}


}


