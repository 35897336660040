import { Injectable } from '@angular/core';

@Injectable ()

export class RSituationTranslations {
    public config: any = {
        component: 'readSituation',
        display_name: 'Situation View',
        description: 'View of an individual situation'
    };

    public trans: any = {
        back: 'Back',
        search: 'Search',
        situationType: 'Situation Type',
        selectSituation: 'Select a Situation',
        selectSituationElement: 'Situation Type Elements',
        selectSituationToProceed: 'Select a situation type from the dropdown above to proceed.',
        description: 'Description',
        activeDefault: 'Active by default?',
        no: 'No',
        availableGuidedDiscovery: 'Available for Guided Discovery?',
        yes: 'Yes',
        active: 'Active',
        notActive: 'Not Active',
        benefits: 'Benefits',
        factors: 'Factors',
        costs: 'Costs',
        caseStudies: 'Case Studies',
        assets: 'Assets',
        collateral: 'Collateral',
        project: 'Project',
        expandAll: 'Expand All',
        collapseAll: 'Collapse All',
        add_to_the_available_costs: 'Add to the available costs',
        autoload_in_valueprop: 'Autoload in ValueProp',
        cost_added_to_autoload: 'Cost Added to Autoload',
        cost_added_to_available_cost: 'Cost Added to available cost',
        exclude_from_available_costs: ' Exclude from available costs',
        remove_from_valueprop: 'Revove from Valueprop',
        removed_from_autoload: 'Removed from Autoload',
        non_applicable_by_default: 'Non-Applicable By Default',
        values_changed: 'Values Changed',
        name: 'Name',
        metric_name: 'Name',
        conservative: 'Conservative',
        probable: 'Probable',
        aggressive: 'Aggressive',
        currentEffort: 'Current Effort',
        reset: 'Reset',
        changeIt: 'Chage It',
        modelBaseline: 'Model Baseline',
        editFactorBaseline: 'Edit Factor Baseline',
        situationOverride: 'Situation Override ',
        on: 'On',
        off: 'Off',
        no_assets_yet: 'No Assets Yet!',
		no_benefits_yet: 'No Benefits Yet!',
		no_case_studies_yet: 'No Case Studies Yet!',
		no_costs_yet: 'No Costs Yet!',
		no_projects_yet: 'No Projects Yet!',
		no_situation_yet: 'No Situation Yet!',
        no_collaterals_yet: 'No Collaterals Yet!',
        add_to_list: 'Add to list',
        included_by_default: 'Included by Default',
        recommended: 'Recommended',
        removed_from_list: 'Removed from List',
    };
}
