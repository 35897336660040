<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title">
            {{ trans.trans.benefitTypes.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.benefitTypes"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-6">
          <a *ngIf="can_edit" (click)="openAddBenefitTypes()" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i>{{ trans.trans.add_new.value }}</a>
        </div>
      </div>
    </div>
    <div class="row marg-v">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input
            [(ngModel)]="strSearch"
            (keyup)="tableBenefitTypes.filter($any($event).target.value, 'name', 'contains')"
            type="text"
            [placeholder]="trans.trans.benefitTypes.value"
            class="form-control"
          />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableBenefitTypes.filter('', 'name', 'contains')" class="input-group-addon clickable"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <p-progressBar *ngIf="loading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
      <p-table #tableBenefitTypes [columns]="cols" [value]="benefitTypes" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.benefitTypes"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-benefitType>
          <tr>
            <td>
              <span class="clickable" (click)="can_edit ? funcToggleEdit(benefitType) : funcToggleView(benefitType)">{{ benefitType.name }}</span>
              <i *ngIf="benefitType.description && !(style2022$ | async)" class="fa fa-info-circle" [matTooltip]="benefitType.description" matTooltipPosition="above"></i>
              <span
                *ngIf="benefitType.description && (style2022$ | async)"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="benefitType.description"
                matTooltipPosition="above"
              >
                info
              </span>
            </td>
            <td>
              <span *ngIf="can_edit" class="keywest_icon_group pull-right">
                <span>
                  <i
                    (click)="funcToggleEdit(benefitType)"
                    [matTooltip]="account_id === benefitType.account_id ? trans.trans.edit.value : trans.trans.ch_view.value"
                    matTooltipPosition="above"
                    class="clickable fa"
                    [ngClass]="{
                      'fa-pencil': account_id === benefitType.account_id,
                      'fa-eye': account_id !== benefitType.account_id
                    }"
                  ></i
                ></span>
                <span *ngIf="account_id === benefitType.account_id">
                  <app-are-you-sure
                    [matTooltip]="trans.trans.delete.value"
                    matTooltipPosition="above"
                    [message]="trans.trans.are_you_sure.value"
                    text=""
                    icon="fieldset_icon fa fa-trash"
                    (callbackYes)="deleteBenefitType(benefitType)"
                  ></app-are-you-sure>
                </span>
              </span>
              <span *ngIf="!can_edit" class="keywest_icon_group pull-right">
                <span><i (click)="funcToggleView(benefitType)" class="fa fa-eye clickable" [matTooltip]="trans.trans.ch_view.value" matTooltipPosition="above"></i></span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="toggleAddEditBenefitType" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-ae-benefit-types
    *ngIf="toggleAddEditBenefitType && can_edit"
    [mode]="mode"
    [selectedElem]="selectedElem"
    (callback)="getAllBenfitTypes(); toggleAddEditBenefitType = false"
    (closeCallback)="closeAddEditBenefitType()"
  ></app-ae-benefit-types>
  <app-r-benefit-types *ngIf="toggleAddEditBenefitType && !can_edit" [selectedElem]="selectedElem"></app-r-benefit-types>
</p-sidebar>

<p-menu #rowMenu appendTo="body" [popup]="true" [model]="menu" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>
