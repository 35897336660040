import { Injectable } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { CreateValueGroupPayload, ValueProp, ValuePropGroup } from '@shared/models/value-prop.model';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable()
export class CreateVPService {
	serviceUrl: string;
	constructor(private commonService: CommonService) {
		this.serviceUrl = this.commonService.getServiceUrl();
	}

	public createValuePropGroup(payload: CreateValueGroupPayload): Observable<APIResult<{ value_prop_group: ValueProp}>> {
		let endpoint = "valueProps/valuePropGroup/create";
		return this.commonService.postAPIService(endpoint, payload);
	}

	public getValuePropGroup(valuePropGroupId: string): Observable<APIResult<ValuePropGroup>> {
		let endpoint = "valueProps/valuePropGroup/" + valuePropGroupId;
		return this.commonService.getAPIService(endpoint);
	}

	public deleteValuePropGroup(valuePropGroupId: string | number): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/valuePropGroup/" + valuePropGroupId;
		return this.commonService.deleteAPIService(endpoint);
	}

	public getValuePropGroupsByUser(userId: string): Observable<APIResult<{ value_prop_groups: ValuePropGroup[] }>>  {
		let endpoint = "users/valuePropGroups/" + userId;
		return this.commonService.getAPIService(endpoint);
	}
}
