import { Injectable } from '@angular/core';

@Injectable ()

export class SolutionReportTranslations {

    public config: any = {
        component: 'solutionReports',
        display_name: 'Solution Report',
        description: 'Solution report in profile'
    };

    public trans: any = {
        backToProfile: 'Back to Profile',
        baseline: 'Baseline',
        benefitsOftenRemoved: 'Benefits Often Removed',
        changed: 'Changed',
        factorsWithBiggestVariations: 'Factors with Biggest Variations',
        frequency: 'Frequency',
        lowestRatedBenefits: 'Lowest Rated Benefits',
        noDataFound: 'No Data Found',
        standardDeviation: 'Standard Deviation',
        topOnTheFlyBenefits: 'Top On The Fly Benefits',
        userCommentsOnBenefits: 'User Comments on Benefits',
        business_case: 'Business Case',
        name: 'Name',
        modified: 'Modified',
        solution_reports: 'Solutions and Value Props',
        name_label: 'Name',
        driverFactor: 'Driver Factor',
        financialFactor: 'Financial Factor',
        improvementFactor: 'Improvement Factor',
        settings: 'Settings',
        dashboard: 'Dashboard',
    };
}
