<p-menu #inlinestatus appendTo="body" [popup]="true" [model]="bI.inlinestatus" [styleClass]="'style-2022'"></p-menu>

<ng-container *ngIf="financialBenefits.length > 0 && translationsLoaded">
  <table id="benefits" class="keywest_table style-2022">
    <!-- <p-table #tableUsers [value]="benefitCategory.metrics" styleClass='keywest_table' tableStyleClass='keywest_table'> -->
    <!-- <ng-template pTemplate="header" let-columns> -->
    <thead>
      <tr>
        <th class="benefit-name-col">
          {{ featureNonFinancial ? trans.trans.financial_benefit.value : trans.trans.benefits.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="featureNonFinancial ? trans.trans.financial_benefit : trans.trans.benefits"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th *ngIf="bI.showBatchUpdateCheckboxes">
          <div class="select-all">
            <p-checkbox [binary]="true" (onChange)="bI.checkAll(benefitCategory)" [matTooltip]="trans.trans.applyToAll.value" matTooltipPosition="above"></p-checkbox>
          </div>
        </th>
        <th width="20px" *ngIf="!bI.showBatchUpdateCheckboxes">
          {{ trans.trans.status.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.status"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th width="20px" *ngIf="featureNonFinancial" style="white-space: nowrap">
          &nbsp;{{ trans.trans.benefit_unit.value }} &nbsp;
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.benefit_unit"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th width="20px" *ngIf="!feature70" style="white-space: nowrap">
          {{ trans.trans.impact.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.impact"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th width="20px" *ngIf="feature70" style="white-space: nowrap">
          {{ trans.trans.benefit_type.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.impact"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th width="20px"></th>
        <th width="20px" style="vertical-align: top">
          <div style="float: left">
            <i
              class="fa fa-chevron-circle-left clickable"
              (click)="benefitCategory.start = bI.rangeLeftArrow(benefitCategory.start, benefitCategory.term)"
              *ngIf="+benefitCategory.term > termShow && benefitCategory.start !== 1"
              style="color: green; font-weight: bold; float: left; margin-top: 15px"
            ></i>
          </div>
        </th>
        <th
          *ngFor="let year of benefitCategory.years | rangePipe: benefitCategory.start:termLimit:termShow; let i = index"
          style="white-space: nowrap; padding-left: 0px !important; padding-right: 0px !important; width: 100px !important"
          [ngClass]="bI.alignYearTbl(i)"
        >
          {{ getTranslatedYear(year.label) }}
        </th>
        <th width="20px" style="vertical-align: top">
          <div style="float: right; text-align: right">
            <i
              class="fa fa-chevron-circle-right clickable"
              (click)="benefitCategory.start = bI.rangeRightArrow(benefitCategory.start, benefitCategory.term)"
              *ngIf="+benefitCategory.term > termShow && benefitCategory.start !== +benefitCategory.term - termLimit"
              style="color: green; font-weight: bold; margin-top: 15px"
            ></i>
          </div>
        </th>
        <th width="90px" style="padding-left: 0px !important; text-align: center">
          {{ trans.trans.total.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.total"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th style="white-space: nowrap; text-align: right; width: 10%">
          {{ trans.trans.phasing.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.phasing"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
      </tr>
    </thead>
    <!-- </ng-template> -->
    <!-- <ng-template pTemplate="body" let-benefit> -->
    <tbody>
      <ng-container *ngFor="let benefit of financialBenefits | filterBenefits: bI.menuFilter:'metric_name' | filterStatus: bI.showActive:bI.showNA:bI.showSoft; let i = index">
        <tr *ngIf="bI.filterBenefit(benefit)">
          <td class="benefit-status">
            <!-- {{feature57}} -->
            <div style="display: inline-flex; align-items: center">
              <a (click)="feature57 ? (selectedBenefit = benefit) : bI.expandBenefit(benefit)" class="link clickable" style="display: inline" [attr.data-heap]="'value-execution-add-a-new-value-proposition-results-benefit-' + (benefit.alt_metric_name ? benefit.alt_metric_name.split(' ').join('-') : benefit.metric_name.split(' ').join('-'))" [ngClass]="'value-execution-add-a-new-value-proposition-results-benefit-' + i">
                <span class="material-icons-outlined accordion-child xf-black-chevron">
                  {{ benefit.expanded ? 'arrow_drop_down' : 'arrow_right' }}
                </span>
                {{ benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name }}
              </a>
              <span *ngIf="benefit.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="benefit.description" matTooltipPosition="above"> info </span>
              <div class="keywest_icon_group" id="icon-grid" *ngIf="+benefit.vp_specific === 1">
                <span><i class="fa fa-bookmark" aria-hidden="true" [matTooltip]="trans.trans.addedManually.value" matTooltipPosition="above"></i></span>
                <span>
                  <a *ngIf="canEdit && bI.benefitOnTheFlyPrivilege" (click)="benefit.editing = !benefit.editing" [matTooltip]="trans.trans.update.value" matTooltipPosition="above">
                    <i style="color: green" class="fa fa-pencil"></i>
                  </a>
                </span>
                <span>
                  <app-are-you-sure
                    [message]="trans.trans.areYouSure.value"
                    text=""
                    [tooltip]="trans.trans.delete.value"
                    icon="fa fa-times"
                    [class]="'no_btn'"
                    iconColor="#ff5d5d"
                    *ngIf="canEdit"
                    (callbackYes)="deleteBenefit(benefit, 'financial')"
                  ></app-are-you-sure>
                </span>
              </div>
            </div>
          </td>
          <td *ngIf="bI.showBatchUpdateCheckboxes">
            <p-checkbox name="checkbox{{ i }}" [binary]="true" [(ngModel)]="benefit.updateBatch"></p-checkbox>
          </td>
          <td class="benefit-status benefitStatusElement" *ngIf="!bI.showBatchUpdateCheckboxes" [matTooltip]="trans.trans.ch_benefit_status.value" matTooltipPosition="above">
            <a
              (click)="canEdit ? bI.openActiveTypesPmenu(benefit, inlinestatus, $event) : ''"
              class="dashedLink benefitStatusElement"
              data-toggle="tooltip"
              title="Change Benefits Status"
            >
              <span class="activeStatusType benefitStatusElement" [ngStyle]="{ 'background-color': bI.getBenefits(benefit.active) }"></span>
            </a>
          </td>
          <td style="white-space: nowrap" *ngIf="featureNonFinancial">{{ benefit.benefit_type }}</td>
          <td style="white-space: nowrap" *ngIf="!feature70">{{ benefit.impact_type }}</td>
          <td style="white-space: nowrap" *ngIf="feature70">{{ getBenefitType(benefit.soft) }}</td>
          <td></td>
          <td></td>
          <td
            *ngFor="let year of benefit.years | rangePipe: benefitCategory.start:termLimit:termShow; let i = index"
            style="white-space: nowrap; padding-left: 0px !important; padding-right: 0px !important"
            [ngClass]="bI.alignYearTbl(i)"
          >
            {{ feature70 && +benefit.soft === 2 ? '----' : year.value }}
          </td>
          <td></td>
          <td class="preventWordBreak" style="text-align: center">
            {{
              feature70 && +benefit.soft === 2
                ? '----'
                : frontSymbol
                ? this.currencySymbol + (benefit.total_benefits | shortNumber: numberAbbreviations)
                : (benefit.total_benefits | shortNumber: numberAbbreviations) + this.currencySymbol
            }}
          </td>
          <td>
            <div class="pull-right">
              <i
                (click)="showPhasing(benefit)"
                class="fa fa-signal clickable"
                [ngStyle]="{ color: benefit.phasing ? 'green' : 'inherit' }"
                [matTooltip]="trans.trans.ch_phaseOverTime.value"
                matTooltipPosition="above"
                role="button"
              ></i>
            </div>
          </td>
        </tr>

        <tr [ngClass]="{ row_selected: benefit.expanded || benefit.phasing || benefit.editing }" *ngIf="benefit.phasing">
          <td colspan="20">
            <app-benefit-phasing
              [mini]="false"
              (callBack)="benefit.phasing = false"
              [valueProp]="bI.valueProp"
              [benefit]="benefit"
              (reloadVP)="phasingChanged($event, benefit.id)"
            ></app-benefit-phasing>
          </td>
        </tr>

        <tr *ngIf="!feature57 && benefit.expanded">
          <td colspan="20" style="padding: 24px">
            <app-benefit-detail
              (reloadVP)="reload()"
              [mini]="false"
              [locked]="bI.locked"
              [valueProp]="bI.valueProp"
              [selectedBenefit]="benefit"
              [canEdit]="bI.permissions.benefits_editable && bI.valueProp.vp_can_edit"
              (closeBenefitDetail)="benefit.expanded = false"
              [permissions]="bI.permissions"
            ></app-benefit-detail>
          </td>
        </tr>

        <tr [ngClass]="{ row_selected: benefit.expanded || benefit.phasing || benefit.editing }" *ngIf="benefit.editing">
          <td colspan="20">
            <app-add-benefits-component
              *ngIf="!bI.modelsv2"
              (cancelBenefitCallback)="benefit.editing = !benefit.editing"
              (saveBenefitCallback)="benefit.editing = false"
              [selectedBenefit]="benefit"
              [showHelp]="bI.showHelp"
              [contextualHelp]="bI.contextualHelp"
              [valueProp]="bI.valueProp"
            ></app-add-benefits-component>
            <app-ae-benefits
              *ngIf="benefit.editing && bI.modelsv2"
              [solutionId]="benefit.account_solution_id"
              [mode]="'edit'"
              [selectedElem]="benefit"
              [can_edit]="true"
              [isOnTheFlyBenefit]="true"
              [valuePropId]="bI.valueProp.id"
              (callback)="benefit.editing = false"
            ></app-ae-benefits>
          </td>
        </tr>
      </ng-container>
    </tbody>
    <!-- </ng-template> -->
    <!-- </p-table> -->
  </table>
</ng-container>

<ng-container *ngIf="featureNonFinancial && nonFinancialBenefits.length > 0 && translationsLoaded">
  <table id="benefits" class="keywest_table style-2022" [style]="financialBenefits.length > 0 ? 'margin-top: 20px;' : ''">
    <thead>
      <tr>
        <th class="benefit-name-col">
          {{ trans.trans.non_financial_benefit.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.benefits"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th *ngIf="bI.showBatchUpdateCheckboxes">
          <div class="select-all">
            <p-checkbox
              [binary]="true"
              [value]=""
              (onChange)="bI.checkAll(benefitCategory)"
              [(ngModel)]="benefitCategory.allOn"
              [matTooltip]="trans.trans.applyToAll.value"
              matTooltipPosition="above"
            ></p-checkbox>
          </div>
        </th>
        <th width="20px" *ngIf="!bI.showBatchUpdateCheckboxes">
          {{ trans.trans.status.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.status"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th width="20px" *ngIf="featureNonFinancial" style="white-space: nowrap">
          &nbsp; {{ trans.trans.benefit_unit.value }} &nbsp;
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.benefit_unit"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th width="20px" *ngIf="!feature70" style="white-space: nowrap">
          {{ trans.trans.impact.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.impact"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th width="20px" *ngIf="feature70" style="white-space: nowrap">
          {{ trans.trans.benefit_type.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.impact"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th></th>
        <th width="20px" style="vertical-align: top">
          <div style="float: left">
            <i
              class="fa fa-chevron-circle-left clickable"
              (click)="benefitCategory.start = bI.rangeLeftArrow(benefitCategory.start, benefitCategory.term)"
              *ngIf="+benefitCategory.term > termShow && benefitCategory.start !== 1"
              style="color: green; font-weight: bold; float: left"
            ></i>
          </div>
        </th>
        <th
          *ngFor="let year of benefitCategory.years | rangePipe: benefitCategory.start:termLimit:termShow; let i = index"
          style="white-space: nowrap; padding-left: 0px !important; padding-right: 0px !important; width: 100px !important"
          [ngClass]="bI.alignYearTbl(i)"
        >
          {{ year.label }}
        </th>
        <th width="20px" style="vertical-align: top">
          <div style="float: right; text-align: right">
            <i
              class="fa fa-chevron-circle-right clickable"
              (click)="benefitCategory.start = bI.rangeRightArrow(benefitCategory.start, benefitCategory.term)"
              *ngIf="+benefitCategory.term > termShow && benefitCategory.start !== +benefitCategory.term - termLimit"
              style="color: green; font-weight: bold"
            ></i>
          </div>
        </th>
        <th width="90px" style="padding-left: 0px !important; text-align: center">
          {{ trans.trans.total.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.total"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
        <th style="white-space: nowrap; text-align: right; width: 10%">
          {{ trans.trans.phasing.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [isTranslationV2]="true"
            [component]="trans.config.component"
            [transObj]="trans.trans.phasing"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let benefit of nonFinancialBenefits | filterBenefits: bI.menuFilter:'metric_name' | filterStatus: bI.showActive:bI.showNA:bI.showSoft; let i = index">
        <tr *ngIf="bI.filterBenefit(benefit)">
          <td class="benefit-status">
            <div style="display: inline-flex">
              <a (click)="feature57 ? (selectedBenefit = benefit) : bI.expandBenefit(benefit)" class="link clickable" style="display: inline">
                <span class="material-icons-outlined accordion-child">
                  {{ benefit.expanded ? 'arrow_drop_down' : 'arrow_right' }}
                </span>
                {{ benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name }}
                <span *ngIf="benefit.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="benefit.description" matTooltipPosition="above">
                  info
                </span>
              </a>
              <div class="keywest_icon_group" id="icon-grid" style="margin-left: 10px" *ngIf="benefit.vp_specific === 1">
                <span><i class="fa fa-bookmark" aria-hidden="true" [matTooltip]="trans.trans.addedManually.value" matTooltipPosition="above"></i></span>
                <span
                  *ngIf="canEdit && bI.benefitOnTheFlyPrivilege"
                  (click)="benefit.editing = !benefit.editing"
                  [matTooltip]="trans.trans.update.value"
                  matTooltipPosition="above"
                  class="clickable"
                >
                  <i style="color: green !important" class="fa fa-pencil"></i>
                </span>
                <span>
                  <app-are-you-sure
                    [message]="trans.trans.areYouSure.value"
                    text=""
                    [tooltip]="trans.trans.delete.value"
                    icon="fa fa-times"
                    [class]="'no_btn'"
                    iconColor="#ff5d5d"
                    *ngIf="canEdit"
                    (callbackYes)="deleteBenefit(benefit, 'non-financial')"
                  ></app-are-you-sure>
                </span>
              </div>
            </div>
          </td>
          <td *ngIf="bI.showBatchUpdateCheckboxes">
            <p-checkbox name="checkbox{{ i }}" [binary]="true" [(ngModel)]="benefit.updateBatch"></p-checkbox>
          </td>
          <td class="benefit-status benefitStatusElement" *ngIf="!bI.showBatchUpdateCheckboxes" [matTooltip]="trans.trans.ch_benefit_status.value" matTooltipPosition="above">
            <a
              (click)="canEdit ? bI.openActiveTypesPmenu(benefit, inlinestatus, $event) : ''"
              class="dashedLink benefitStatusElement"
              data-toggle="tooltip"
              title="Change Benefits Status"
            >
              <span class="activeStatusType benefitStatusElement" [ngStyle]="{ 'background-color': bI.getBenefits(benefit.active) }"></span>
            </a>
          </td>
          <td style="white-space: nowrap" *ngIf="featureNonFinancial">{{ benefit.benefit_type }}</td>
          <td style="white-space: nowrap" *ngIf="!feature70">{{ benefit.impact_type }}</td>
          <td style="white-space: nowrap" *ngIf="feature70">{{ getBenefitType(benefit.soft) }}</td>
          <td></td>
          <td></td>
          <td
            *ngFor="let year of benefit.years | rangePipe: benefitCategory.start:termLimit:termShow; let i = index"
            style="white-space: nowrap; padding-left: 0px !important; padding-right: 0px !important"
            [ngClass]="bI.alignYearTbl(i)"
          >
            {{ feature70 && +benefit.soft === 2 ? '----' : year.value }}
          </td>
          <td></td>
          <td class="preventWordBreak" style="text-align: center">
            {{
              feature70 && +benefit.soft === 2
                ? '----'
                : frontSymbol
                ? this.currencySymbol + (benefit.total_benefits | shortNumber: numberAbbreviations)
                : (benefit.total_benefits | shortNumber: numberAbbreviations) + this.currencySymbol
            }}
          </td>
          <td (click)="showPhasing(benefit)">
            <div class="pull-right">
              <i
                class="fa fa-signal"
                [ngStyle]="{ color: benefit.phasing ? 'green' : 'inherit' }"
                [matTooltip]="trans.trans.ch_phaseOverTime.value"
                matTooltipPosition="above"
                role="button"
              ></i>
            </div>
          </td>
        </tr>

        <tr [ngClass]="{ row_selected: benefit.expanded || benefit.phasing || benefit.editing }" *ngIf="benefit.phasing">
          <td colspan="20">
            <app-benefit-phasing
              [mini]="false"
              (callBack)="benefit.phasing = false"
              [valueProp]="bI.valueProp"
              [benefit]="benefit"
              (reloadVP)="phasingChanged($event, benefit.id)"
            ></app-benefit-phasing>
          </td>
        </tr>

        <tr *ngIf="!feature57 && benefit.expanded">
          <td colspan="20">
            <app-benefit-detail
              (reloadVP)="reload()"
              [mini]="false"
              [locked]="bI.locked"
              [valueProp]="bI.valueProp"
              [selectedBenefit]="benefit"
              [canEdit]="bI.permissions.benefits_editable && bI.valueProp.vp_can_edit"
              (closeBenefitDetail)="benefit.expanded = false"
              [permissions]="bI.permissions"
            ></app-benefit-detail>
          </td>
        </tr>

        <tr [ngClass]="{ row_selected: benefit.expanded || benefit.phasing || benefit.editing }" *ngIf="benefit.editing">
          <td colspan="20">
            <app-add-benefits-component
              *ngIf="!bI.modelsv2"
              (cancelBenefitCallback)="benefit.editing = !benefit.editing"
              (saveBenefitCallback)="benefit.editing = false"
              [selectedBenefit]="benefit"
              [showHelp]="bI.showHelp"
              [contextualHelp]="bI.contextualHelp"
              [valueProp]="bI.valueProp"
            ></app-add-benefits-component>
            <app-ae-benefits
              *ngIf="benefit.editing && bI.modelsv2"
              [solutionId]="benefit.account_solution_id"
              [mode]="'edit'"
              [selectedElem]="benefit"
              [can_edit]="true"
              [isOnTheFlyBenefit]="true"
              [valuePropId]="bI.valueProp.id"
              (callback)="benefit.editing = false"
            ></app-ae-benefits>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
<dl-sidebar
  *ngIf="feature57 && selectedBenefit"
  message="Are you sure you want to close without saving first?"
  [visible]="feature57 && selectedBenefit"
  (callback)="selectedBenefit = null; reload()"
  styleClass="keywest_sidebar p-sidebar-xl"
  position="right"
  (onHide)="selectedBenefit = null; reload()"
  [dismissible]="false"
>
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ this.trans.trans.benefitDetails.value }}
      <a (click)="benefitDetail.funcLiveSave('all', '')" class="btn btn_customizable pull-right" style="margin-top: -4px; margin-right: 30px">{{ this.trans.trans.save.value }}</a>
    </div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <app-benefit-detail
          (reloadVP)="reload()"
          #benefitDetail
          [locked]="bI.locked"
          [valueProp]="bI.valueProp"
          [selectedBenefit]="selectedBenefit"
          [canEdit]="bI.permissions.benefits_editable && canEdit"
          (closeBenefitDetail)="selectedBenefit = null"
          [permissions]="bI.permissions"
        ></app-benefit-detail>
      </div>
    </div>
    <div class="btn_container">
      <div class="row">
        <div class="col-xs-12">
          <a (click)="benefitDetail.funcLiveSave('all', '')" class="btn btn_customizable pull-right">{{ this.trans.trans.save.value }}</a>
        </div>
      </div>
    </div>
  </div>
</dl-sidebar>
