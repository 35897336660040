import { Injectable } from '@angular/core';

@Injectable ()

export class InfoDetailTranslation {

    public config: any = {
        component: 'infoDetailHeader',
        display_name: 'Rep Simple Info Header',
        description: 'The header for rep simple with information about the value prop'
    };

    public trans: any = {
        dashboard: 'Dashboard',
        hypothesis: 'Hypothesis',
        businessCase: 'Business Case',
        createdBy: 'Business Case created by:',
        vpCreatedBy: 'created by:',
        modifiedBy: 'Last modified by:',
        lock: 'Lock Value Prop',
		unlock: 'Unlock Value Prop',
        selectWF: 'Select Workflow',
    };
}