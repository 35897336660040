import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { AccountRoutingModule } from './account-routing.module';
import { UsersComponent } from './users/users.component';
import { CompetitorsComponent } from './competitors/competitors.component';
import { PartnersComponent } from './partners/partners.component';
import { DladminComponent } from './dladmin/dladmin.component';
import { ProfileSubComponent } from './profile-sub/profile-sub.component';
import { ProfileDetailComponent } from './profile-detail/profile-detail.component';
import { TeamComponent } from './team/team.component';
import { TeamDetailComponent } from './team-detail/team-detail.component';
import { CreditsComponent } from './credits/credits.component';
import { TrophiesComponent } from './trophies/trophies.component';
import { PrevilegesChangeComponent } from './previleges-change/previleges-change.component';
import { ColorPickerComponent } from '../_directives/color-picker/color-picker.component';
import { ApplicationsSettingsComponent } from './applications-settings/applications-settings.component';
import { AssetService } from '@data/services/asset/asset.service';
import { AssetsComponent } from './assets/assets.component';
import { SolutionReportComponent } from './solution-report/solution-report.component';
import { AccountStageTypesComponent } from './applications-settings/account-stage-types/account-stage-types.component';
import { SidebarModule } from 'primeng/sidebar';
import { AdminDiscoveryQuestionsComponent } from './discovery-questions/discovery-questions.component';
import { AdoptionReportModule } from './adoption-reports/adoption-reports.module';
import { AdminTranslationsModule } from './translations/translations.module';
import { ColorPickerModule } from 'primeng/colorpicker';
import { TranslateLoader } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { DropdownModule } from 'primeng/dropdown';
import { AdminUserGroupsComponent } from './user_groups/groups.component';
import { MenuModule } from 'primeng/menu';
import { AdminUserGroupsService } from '@data/services/account/user_groups.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SurveyTemplateComponent } from './survey-template/survey-template.component';
import { SurveyTemplateCreateComponent } from 'app/account/survey-template-create/survey-template-create.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { StepsModule } from 'primeng/steps';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ProfileSubTranslation } from './profile-sub/profile-sub.translation';
import { CreditsTranslation } from './credits/credits.translation';
import { ProfileDetailTranslation } from './profile-detail/profile-detail.translation';
import { TeamDetailTranslation } from './team-detail/team-detail.translation';
import { UsersProfileTranslation } from './users/users.translation';
import { UserGroupsProfileTranslations } from './user_groups/groups.translation';
import { DefaultTranslations } from './applications-settings/applications-settings.translation';
import { CompetitorsProfileTranslations } from './competitors/competitors.translation';
import { PartnersProfileTranslations } from './partners/partners.translation';
import { SolutionReportTranslations } from './solution-report/solution-report.translation';
import { AssetsManageProfileTranslations } from './assets/assets.translation';
import { DlAdminTranslation } from './dladmin/dladmin.translation';
import { PanelModule } from 'primeng/panel';
import { AEAssetsComponent } from './assets/ae_asset/ae_asset.component';
import { AdminMautiryModule } from './maturity/maturity.module';
import { AdminCurrencyModule } from './currency/currency.module';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TeamTranslation } from './team/team.translation';
import { DiscoveryQuestionsDefaultTranslations } from './discovery-questions/discovery-questions.translation';
import { SurveyTemplateDefaultTranslations } from './survey-template/survey-template.translation';
import { SurveyTemplateCreateDefaultTranslations } from './survey-template-create/survey-template-create.translation';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileTranslation } from './user-profile/user-profile.translation';
import { PrivilegesCardComponent } from './user-profile/privileges-card/privileges-card.component';
import { TeamCardComponent } from './user-profile/team-card/team-card.component';
import { UserCardComponent } from './user-profile/user-card/user-card.component';
import { ChangePasswordComponent } from './user-profile/change-password/change-password.component';
import { DialogModule } from 'primeng/dialog';
import { EditProfileInfoComponent } from './user-profile/edit-profile-info/edit-profile-info.component';
import { EditTeamComponent } from './user-profile/edit-team/edit-team.component';
import { OtherReportsComponent } from './other-reports/other-reports.component';
import { OtherReportsTranslations } from './other-reports/other-reports.translation';
import { OtherReportsService } from '@data/services/other-reports/other-reports.service';
import { SettingsComponent } from './settings/settings.component';
import { SettingsTranslation } from './settings/settings.translation';
import { SettingsLinkCardComponent } from './settings/settings-link-card/settings-link-card.component';
import { EditSequencesComponent } from './edit-sequences/edit-sequences.component';
import { MultiDragDropModule } from '@shared_components/multi-drag-drop/multi-drag-drop.module';
import { EditSequencesDefaultTranslations } from './edit-sequences/edit-sequences.translation';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { CountryFlagService } from '@data/services/country-flag/country-flag.service';
import { ToastModule } from 'primeng/toast';
import { CustomAssetTagsModule } from './custom-asset-tags/custom-asset-tags.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AssetLogComponent } from './assets/asset-log/asset-log.component';
import { TimelineModule } from 'primeng/timeline';
import { FilterEventsPipe } from './assets/asset-log/filter-events.pipe';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AssetBasicsComponent } from './assets/asset-basics/asset-basics.component';
import { AssetTemplatesComponent } from './assets/asset-templates/asset-templates.component';
import { AssetSettingsComponent } from './assets/asset-settings/asset-settings.component';
import { AssetPreviewComponent } from './assets/asset-preview/asset-preview.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { TextTruncateModule } from '@shared/pipes/text-truncate.module';
import { ConfigurableWorkflowsModule } from './configurable-workflows/configurable-workflows.module';
import { WorkflowConfigTableComponent } from './users/workflow-config-table/workflow-config-table.component';
import { DividerModule } from 'primeng/divider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { BulkUpdateModule } from './bulk-update/bulk-update.module';
import { AnalyticsModule } from './analytics/analytics.module';
import { AssetPreviewTranslations } from './assets/asset-preview/asset-preview.translation';
import { AssetThemeComponent } from './assets/asset-theme/asset-theme.component';
import { AccordionModule } from 'primeng/accordion';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    AdminCurrencyModule,
    AdminMautiryModule,
    PanelModule,
    StepsModule,
    ProgressBarModule,
    BreadcrumbsModule,
    MultiSelectModule,
    DropdownModule,
    TableModule,
    MatTooltipModule,
    EditTranslationsModule,
    AreYouSureModule,
    ColorPickerModule,
    SharedModule,
    AccountRoutingModule,
    SidebarModule,
    CheckboxModule,
    AdoptionReportModule,
    MenuModule,
    AdminTranslationsModule,
    ApplicationPipesModule,
    InputSwitchModule,
    EllipsisMenuModule,
    DragDropModule,
    ReactiveFormsModule,
    MultiDragDropModule,
    DragDropModule,
    DLMenubarModule,
    RadioButtonModule,
    ErrorInputWrapperModule,
    TabViewModule,
    DialogModule,
    CustomAssetTagsModule,
    TimelineModule,
    ToastModule,
    ProgressSpinnerModule,
    ErrorInputWrapperModule,
    InputTextareaModule,
    TextTruncateModule,
    ConfigurableWorkflowsModule,
    DividerModule,
    SelectButtonModule,
    NoRecordsModule,
    ListboxModule,
    BulkUpdateModule,
    AnalyticsModule,
    AccordionModule,
    CheckboxModule,
  ],
  declarations: [
    AEAssetsComponent,
    SurveyTemplateCreateComponent,
    SurveyTemplateComponent,
    AdminUserGroupsComponent,
    UsersComponent,
    CompetitorsComponent,
    PartnersComponent,
    DladminComponent,
    ProfileSubComponent,
    ProfileDetailComponent,
    TeamComponent,
    TeamDetailComponent,
    CreditsComponent,
    TrophiesComponent,
    PrevilegesChangeComponent,
    ColorPickerComponent,
    ApplicationsSettingsComponent,
    AssetsComponent,
    SolutionReportComponent,
    AccountStageTypesComponent,
    AdminDiscoveryQuestionsComponent,
    UserProfileComponent,
    PrivilegesCardComponent,
    TeamCardComponent,
    UserCardComponent,
    ChangePasswordComponent,
    EditProfileInfoComponent,
    EditTeamComponent,
    OtherReportsComponent,
    SettingsComponent,
    SettingsLinkCardComponent,
    EditSequencesComponent,
    AssetLogComponent,
    FilterEventsPipe,
    AssetBasicsComponent,
    AssetTemplatesComponent,
    AssetSettingsComponent,
    AssetPreviewComponent,
    WorkflowConfigTableComponent,
    AssetThemeComponent,
  ],

  providers: [
    OtherReportsService,
    DefaultTranslations,
    TranslationsV2Service,
    AssetService,
    Translations,
    AdminUserGroupsService,
    TranslationsV2Service,
    ProfileSubTranslation,
    CreditsTranslation,
    ProfileDetailTranslation,
    TeamDetailTranslation,
    UsersProfileTranslation,
    UserGroupsProfileTranslations,
    CompetitorsProfileTranslations,
    PartnersProfileTranslations,
    SolutionReportTranslations,
    OtherReportsTranslations,
    AssetsManageProfileTranslations,
    DlAdminTranslation,
    TeamTranslation,
    DiscoveryQuestionsDefaultTranslations,
    SurveyTemplateDefaultTranslations,
    SurveyTemplateCreateDefaultTranslations,
    UserProfileTranslation,
    CountryFlagService,
    SettingsTranslation,
    EditSequencesDefaultTranslations,
    AssetPreviewTranslations
  ],
})
export class AccountModule {}
