import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TrackValueProofTabTranslations } from './track-value-proof-tab.translation';
import { Model } from '@data/services/account/models/model.interface';
import { SolutionService } from '@data/services/solution/solution.service';
import { CaseStudy } from '@shared/models/case-study.model';

@Component({
  selector: 'app-track-value-proof-tab',
  templateUrl: './track-value-proof-tab.component.html',
  styleUrls: ['./track-value-proof-tab.component.scss'],
})
export class TrackValueProofTabComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;

  caseStudySidebar = false;
  caseStudies: any[];
  caseStudyId: string;
  companyId: string;
  loading = false;
  modelList: Model[];
  ngUnsubscribe$ = new Subject();
  pageNumber = 1;
  searchField = new FormControl('');
  trackerId: string;
  selectedProofpoint: CaseStudy;
  canEdit = true;

  constructor(
    private valueRealizationService: ValuerealizationService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    private solutionService: SolutionService,
    public trans: TrackValueProofTabTranslations
  ) {}

  ngOnInit(): void {
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.trackerId = this.route.snapshot.paramMap.get('trackerId');
    this.getProofPoints();
    this.getModels();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  editCaseStudy(rowData: CaseStudy): void {
    this.selectedProofpoint = rowData;
    this.caseStudySidebar = true;
  }

  addProofPoint(): void {
    this.selectedProofpoint = null;
    this.caseStudySidebar = true;
  }

  closeProofPointSidebar(saved?: boolean): void {
    this.caseStudySidebar = false;
    if (saved) {
      this.getProofPoints();
    }
  }

  getModels() {
    const searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc';
    this.solutionService
      .getAvailableSolutionsFull(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result) {
          this.modelList = response.result.data;
        }
      });
  }

  resetSearchField(): void {
    this.searchField.reset();
  }

  refreshProofpoints(): void {
    this.getProofPoints(true);
  }

  private getProofPoints(refresh = false): void {
    let searchLimit = `/limit/all/page/${this.pageNumber}`;
    this.valueRealizationService
      .getCaseStudies(`/${this.companyId}`, this.valuePropId, 0, searchLimit)
      .pipe(take(1))
      .subscribe((res) => {
        this.caseStudies = res.result.case_studies.data;
        if (refresh) {
          const updatedProofpoint = this.caseStudies.find((caseStudy) => caseStudy.id === this.selectedProofpoint.id);
          if (updatedProofpoint) {
            this.selectedProofpoint = {
              ...updatedProofpoint,
              name: this.selectedProofpoint.name,
            };
          }
        }
      });
  }

  private getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
