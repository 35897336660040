<form name="formAddEditCollateral" #formAddEditCollateral="ngForm">
	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			{{sidebar_title.value}}
			<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
			[isTranslationV2]="true" [transObj]="sidebar_title" (callback)="getTranslations()"></app-editTranslation>
		</div>

		<div class='sidebar_container'>
			<div class='keywest_panel'>

				<div class='row'>
					<div class="col-md-12" appNGError [elemControl]="formAddEditCollateral.controls.format_type_id">
						<label class="sidebar_label" for="alterEgo">{{ trans.trans.format.value }}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.format" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<select class="form-control role-dropdown" [(ngModel)]="collateralFormObj.format_type_id" name="format_type_id" type="text" ret-data="Format" required>
							<option disabled="disabled">{{ trans.trans.chooseOne.value}}</option>
							<option *ngFor="let formatTypes of fileFormatTypes" [value]="formatTypes.id">
								{{formatTypes.name}}
							</option>
						</select>
					</div>
				</div>

				<div class='row'>
					<div class="col-md-12" appNGError [elemControl]="formAddEditCollateral.controls.name">
						<label for="alterEgo" class="sidebar_label"> {{ trans.trans.name.value }}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<input type="text" class="form-control" id="name" name="name" [(ngModel)]="collateralFormObj.name" ret-data="Name" placeholder="{{ trans.trans.name.value }}" required>
					</div>
				</div>

				<div class='row'>
					<div class="col-md-12" appNGError [elemControl]="formAddEditCollateral.controls.url">
						<label class="sidebar_label"> {{ trans.trans.url.value }}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.url" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<input type="text" class="form-control" id="url" required [(ngModel)]="collateralFormObj.url" ret-data="Url" name="url" ngModel placeholder="{{ trans.trans.url.value }}">
					</div>
				</div>

				<div class='row'>
					<div class="col-md-12" appNGError [elemControl]="formAddEditCollateral.controls.description">
						<label for="alterEgo" class='sidebar_label'>{{ trans.trans.description.value }}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea class="form-control keywest_textarea" id="Description" [(ngModel)]="collateralFormObj.description" ret-data="Description" name="description" placeholder="{{ trans.trans.description.value }}"></textarea>
					</div>
				</div>
			</div>
		</div>

		<div class='btn_container'>
			<div class="col-md-12">
				<app-btn-save [form]='formAddEditCollateral' (callback)='SaveCollateral(formAddEditCollateral)' [text]='trans.trans.save.value' class='btn btn_customizable pull-right'></app-btn-save>
				<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
			</div>
		</div>

	</div>
</form>