<ul *ngIf="style2022" class="keywest_breadcrumb no-arrow flex-omni-c" style="margin-top: 3vh; margin-bottom: 3vh;">
  <li [ngClass]="{ current: step == i }" *ngFor="let elem of menu; let i = index" (click)="gotoStep(i)">
    <a [ngClass]="{ current: step == i }">
      <div class="badge badge_off" [ngClass]="{ badge_current: step == i }">{{ i + 1 }}</div>
      <span [ngClass]="{ text_current: step == i }">{{ elem.label }}</span>
    </a>
  </li>
</ul>

<ul *ngIf="!style2022" class="keywest_breadcrumb">
  <li *ngFor="let elem of menu; let i = index" (click)="gotoStep(i)">
    <a [ngClass]="{ current: step == i }">
      <div class="badge badge_off" *ngIf="!elem.done" [ngClass]="{ badge_current: step == i }">{{ i + 1 }}</div>
      <div class="badge badge_off" *ngIf="elem.done" [ngClass]="{ badge_current: step == i }"><i class="fa fa-check"></i></div>
      <span [ngClass]="{ text_current: step == i }">{{ elem.label }}</span>
      <i class="fa fa-info-circle" *ngIf="elem.desc && !style2022" [matTooltip]="elem.desc"></i>
      <span *ngIf="elem.desc && style2022" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.desc"> info </span>
    </a>
  </li>
</ul>
