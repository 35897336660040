import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BenefitsCasestudyTranslations } from './benefits-casestudy.translation';

@Component({
  selector: 'app-benefits-casestudy',
  templateUrl: './benefits-casestudy.component.html',
  styleUrls: ['./benefits-casestudy.component.scss'],
})
export class BenefitsCasestudyComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Output() valueChange = new EventEmitter();
  @Input() caseStudy: { [klass: string]: any };

  modalReference: NgbModalRef;
  selectedBenefits: Array<{ [klass: string]: any }> = [];
  selectedBenefit: { [klass: string]: any };
  selectedBenefitRemove: { [klass: string]: any };
  selectedBenefitMove: { [klass: string]: any };
  imageUrl: string;
  fullImagePath: string;
  actionLoader = false;
  BenefitsList: Array<{ [klass: string]: any }> = [];
  unitTypeList: ExpenseType;
  valuePropId: number;
  benefitImpValue: any;
  benefitImpType: { [klass: string]: any };
  impTypesList: Array<{ [klass: string]: any }> = [];

  subscriptiongetBenefitsList: Subscription;
  subscriptiongetValuePropBenefits: Subscription;
  subscriptiongetUnitType: Subscription;
  subscriptiongetImpTypes: Subscription;
  subscriptionsaveCaseStudyBenefits: Subscription;
  subscriptiondeleteCaseStudyBenefit: Subscription;
  subscriptionupdateCaseStudyBenefit: Subscription;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private valuepropService: ValuepropService,
    private valuerealizationService: ValuerealizationService,
    private translationService: TranslationsV2Service,
    public trans: BenefitsCasestudyTranslations
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getUnitType();

    if (this.type === 'valueprop') {
      this.route.params.subscribe((params) => (this.valuePropId = params['id']));
    }
    this.getBenefitsList();
    this.getImpTypes();
  }

  ngOnDestroy() {
    if (this.subscriptiongetBenefitsList) {
      this.subscriptiongetBenefitsList.unsubscribe();
    }
    if (this.subscriptiongetValuePropBenefits) {
      this.subscriptiongetValuePropBenefits.unsubscribe();
    }
    if (this.subscriptiongetUnitType) {
      this.subscriptiongetUnitType.unsubscribe();
    }
    if (this.subscriptiongetImpTypes) {
      this.subscriptiongetImpTypes.unsubscribe();
    }
    if (this.subscriptionsaveCaseStudyBenefits) {
      this.subscriptionsaveCaseStudyBenefits.unsubscribe();
    }
    if (this.subscriptiondeleteCaseStudyBenefit) {
      this.subscriptiondeleteCaseStudyBenefit.unsubscribe();
    }
    if (this.subscriptionupdateCaseStudyBenefit) {
      this.subscriptionupdateCaseStudyBenefit.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  openTieToBenefits(content: any) {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
  }
  selectBenefitToMove(selectedBenefit: { [klass: string]: any }) {
    this.getImpTypes();
    this.benefitImpValue = '';
    this.selectedBenefitMove = selectedBenefit;
    this.selectedBenefitRemove = null;
  }
  selectBenefitToRemove(selectedBenefit: { [klass: string]: any }) {
    this.selectedBenefitMove = null;
    this.selectedBenefitRemove = selectedBenefit;
  }
  moveSelectedBenefit() {
    if (this.selectedBenefits.indexOf(this.selectedBenefitMove) === -1 && this.selectedBenefitMove !== null && this.benefitImpValue) {
      for (const i in this.impTypesList) {
        if (this.impTypesList[i]['id'] === this.benefitImpType) {
          this.selectedBenefitMove['improvement'] = {
            value: this.benefitImpValue,
            type: this.benefitImpType,
            typeAbbr: this.impTypesList[i]['abbr'],
          };
        }
      }
      this.selectedBenefits.push(this.selectedBenefitMove);
    }
  }
  removeSelectedBenefit() {
    if (this.selectedBenefits.length > 0 && this.selectedBenefits.indexOf(this.selectedBenefitRemove) !== -1 && this.selectedBenefitRemove !== null) {
      this.selectedBenefits.splice(this.selectedBenefits.indexOf(this.selectedBenefitRemove), 1);
    }
  }

  getBenefitsList() {
    this.actionLoader = true;
    if (this.type === 'solution') {
      this.subscriptiongetBenefitsList = this.solutionService.getBenefitsList(this.caseStudy['account_solution_id']).subscribe((response) => {
        if (response.result) {
          this.removeSavedBenefits(response.result.solution_metrics);
        }
      });
    } else {
      const searchLimit = this.valuePropId;
      this.subscriptiongetValuePropBenefits = this.valuepropService.getValuePropBenefits(searchLimit, 0, 0).subscribe((response) => {
        if (response.result) {
          this.removeSavedBenefits(response.result.metrics);
        }
      });
    }

    this.actionLoader = false;
  }

  removeSavedBenefits(benefits: { [klass: string]: any }[]) {
    if (this.caseStudy['benefits']) {
      const savedBenefits = this.caseStudy['benefits'].map((benefit: { [klass: string]: any }) => benefit['account_solution_metric_id']);
      for (let i = 0; i < benefits.length; i++) {
        if (savedBenefits.indexOf(benefits[i]['account_solution_metric_id']) !== -1) {
          benefits.splice(i, 1);
        }
      }
    }
    this.BenefitsList = benefits;
  }

  getUnitType() {
    this.subscriptiongetUnitType = this.solutionService.getUnitType().subscribe((response) => {
      if (response.result) {
        this.unitTypeList = response.result;
      }
    });
  }
  getImpTypes() {
    this.subscriptiongetImpTypes = this.solutionService.getImpTypes().subscribe((response) => {
      if (response.result) {
        this.impTypesList = response.result.improvementTypes;
        this.benefitImpType = this.impTypesList[0]['id'];
      }
    });
  }

  processBenefitsSave(response: any) {
    if (response.result) {
      this.benefitImpValue = '';
      this.getImpTypes();
      this.modalReference.close();
      this.valueChange.emit();
      this.notification.success('Benefit Tied successfully', false); // Params {message, islogout}
    }
  }

  benefitsSave() {
    if (!this.selectedBenefits.length) {
      return;
    }
    const benefitsList = [];
    for (let i in this.selectedBenefits) {
      const benefit = {
        benefit_id: this.selectedBenefits[i]['id'],
        improvement: this.selectedBenefits[i]['improvement']['value'],
        improvement_type_id: this.selectedBenefits[i]['improvement']['type'],
      };

      if (this.type !== 'solution') {
        benefit['account_solution_metric_id'] = this.selectedBenefits[i]['account_solution_metric_id'];
      }

      benefitsList.push(benefit);
    }
    const payload = {
      company_id: this.caseStudy.company_id,
      case_study_id: this.caseStudy.id,
      benefits: benefitsList,
    };

    if (this.type === 'solution') {
      this.subscriptionsaveCaseStudyBenefits = this.solutionService.saveCaseStudyBenefits(payload).subscribe((response) => {
        this.processBenefitsSave(response);
      });
    } else {
      this.subscriptionsaveCaseStudyBenefits = this.valuerealizationService.saveCaseStudyBenefits(payload).subscribe((response) => {
        this.processBenefitsSave(response);
      });
    }
  }

  processDeleteBenefit(response: { result: { success: boolean } }) {
    if (response.result && response.result.success) {
      this.notification.success('Benefit deleted successfully', false); // Params {message, islogout}
      this.valueChange.emit();
    }
  }

  deleteBenefit(benefit: { [klass: string]: any }) {
    const payload = {
      company_id: benefit['company_id'],
      case_study_id: benefit['case_study_id'],
      benefits: [
        {
          case_study_benefit_id: benefit['id'],
        },
      ],
    };

    if (this.type === 'solution') {
      this.subscriptiondeleteCaseStudyBenefit = this.solutionService.deleteCaseStudyBenefit(payload).subscribe((response) => {
        this.processDeleteBenefit(response);
      });
    } else {
      this.subscriptiondeleteCaseStudyBenefit = this.valuerealizationService.deleteCaseStudyBenefit(payload).subscribe((response) => {
        this.processDeleteBenefit(response);
      });
    }
  }

  openEditBenefit(content: any, benefit: { [klass: string]: any }) {
    this.selectedBenefit = benefit;

    this.benefitImpValue = this.selectedBenefit['improvement'];
    this.benefitImpType = this.selectedBenefit['improvement_type_id'];

    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  processEditBenefit(response: { result: { success: boolean } }) {
    if (response.result && response.result.success) {
      this.notification.success('Benefit Updated successfully', false); // Params {message, islogout}
      this.modalReference.close();
      this.valueChange.emit();
    }
  }

  editBenefit() {
    const payload = {
      company_id: this.selectedBenefit['company_id'],
      case_study_id: this.selectedBenefit['case_study_id'],
      benefits: [
        {
          case_study_benefit_id: this.selectedBenefit['id'],
          improvement: this.benefitImpValue,
          improvement_type_id: this.benefitImpType,
        },
      ],
    };

    if (this.type === 'solution') {
      this.subscriptionupdateCaseStudyBenefit = this.solutionService.updateCaseStudyBenefit(payload).subscribe((response) => {
        this.processEditBenefit(response);
      });
    } else {
      this.subscriptionupdateCaseStudyBenefit = this.valuerealizationService.updateCaseStudyBenefit(payload).subscribe((response) => {
        this.processEditBenefit(response);
      });
    }
  }
}
