<div class="col-md-6 " style='margin-bottom: 10px;'>
	<div class='models_panel dashboard-card' style='height: 400px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>{{ trans.trans.models_modelsHeading.value}}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.models_modelsHeading'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.models_modelsHeading"></app-editTranslation>

						</div>
					</div>
					<div class='col-xs-2'>
						<button routerLink="/model/create" class="btn btn_customizable pull-right" *ngIf="!feature38 && dbI.showAddButtons"><i class='fa fa-plus'></i> {{ trans.trans.add.value }}</button>
						<button routerLink="/modelsv2" class="btn btn_customizable pull-right" *ngIf="feature38 && dbI.showAddButtons"><i class='fa fa-plus'></i> {{ trans.trans.add.value }}</button>
					</div>
				</div>
			</div>
		</div>
		<div style='overflow-y: auto; max-height: 270px;'>
			<div [hidden]="!loading">
				<app-table-skeleton rowHeight="35px" [columns]="columns" [trans]="trans.trans" [rowLimit]="5"></app-table-skeleton>
			</div>
			<table *ngIf="!loading" class="table custom-table dashboard-table fixedTable">
				<thead>
					<tr>
						<th width="80%">{{ trans.trans.models_name.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.models_name'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.models_name"></app-editTranslation>
						</th>
						<th width="20%" class="text-right">
							{{ trans.trans.models_timeused.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.models_timeused'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.models_timeused"></app-editTranslation>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="grid-row company-row" *ngFor="let model of modelsList">
						<td>
							<a *ngIf="!feature38" routerLink="/model/{{model.id}}" class='link'>{{model.name}}</a>
							<a *ngIf="feature38" routerLink="/modelv2/{{model.id}}" class='link'>{{model.name}}</a>
							<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle tooltip-custom" [ngbTooltip]="model.name" placement="bottom" container="body"></i>
							<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="model.name" placement="bottom" container="body">
								info
							</span>
						</td>
						<td class="text-right">{{model.times_used}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="modelsList.length==0" class="m-t-md col-sm-12">
			<div class="text-center">
				<h4>{{ trans.trans.model_noValueModels.value}}</h4>
			</div>
		</div>
		<a *ngIf="!feature38" routerLink="/models" class="keywest_seeMore">{{ trans.trans.models_seemore.value }}</a>
		<a *ngIf="feature38" routerLink="/modelsv2" class="keywest_seeMore">{{ trans.trans.models_seemore.value }}</a>
		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.models_seemore'
		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.models_seemore"></app-editTranslation>
	</div>
</div>
