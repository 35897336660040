import { NgModule } from '@angular/core';
import { DLNGErrorDirective } from './error.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DLNGErrorDirective],
  imports: [FormsModule, CommonModule],
  providers: [],
  exports: [DLNGErrorDirective],
})
export class DLNGErrorDirectiveModule {}
