import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { MaturityComponent } from './maturity.component';
import { TableModule } from 'primeng/table';
import { CXOService } from '@data/services/cxo/cxo.service';
import { DropdownModule } from 'primeng/dropdown';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		DropdownModule,
		TableModule,
    MatTooltipModule,
		CommonModule,
		FormsModule,
	],
	declarations: [
		MaturityComponent
	],
	providers: [CXOService],
	exports: [MaturityComponent],
	entryComponents: [MaturityComponent]
})
export class MaturityModule { }
