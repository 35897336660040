import { Injectable } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import { BenefitValidation, BenefitValidationItem, BenefitValidationPayload, BenefitValidationResponse, FactorValidationPayload } from '@shared/models/benefits.models';
import { FactorValidation, ValidationFactorItem } from '@shared/models/factors.models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable()
export class SolutionValidationService {
  public selectedValidationBenefit$ = new BehaviorSubject<BenefitValidationItem>(null);
  public selectedValidationFacotor$ = new BehaviorSubject<ValidationFactorItem>(null);
  public validationErrorCount$ = new Subject<number>();

  constructor(private commonService: CommonService) {}

  public getBenefitValidation(solutionID: string): Observable<APIResult<BenefitValidation>> {
    const endpoint = `solutions/${solutionID}/benefit-validation`;
    return this.commonService.getAPIService(endpoint);
  }

  public getFactorValidation(solutionID: string): Observable<APIResult<FactorValidation>> {
    const endpoint = `solutions/${solutionID}/factor-validation`;
    return this.commonService.getAPIService(endpoint);
  }

  public acknowledgeValidationBenefit(solutionId: string, payload: BenefitValidationPayload): Observable<BenefitValidationResponse> {
    const endpoint = `solutions/${solutionId}/set-acknowleged-benefits`;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public acknowledgeValidationFactor(solutionId: string, payload: FactorValidationPayload): Observable<BenefitValidationResponse> {
    const endpoint = `solutions/${solutionId}/set-acknowleged-factors`;
    return this.commonService.postAPIService(endpoint, payload);
  }
}
