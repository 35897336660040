import { Injectable } from '@angular/core';

@Injectable()
export class ValuepropDashboardTranslations {
  public config: any = {
    component: 'valupropDash',
    display_name: 'Value Prop Main Dashboard',
    description: 'Value prop list in main dashboard',
  };

  public trans: any = {
    vpGroup: 'Value Prop Group',
    valueprop_name: 'Name',
    valueprop_totalbenefits: 'Total Benefits',
    valueprop_valuepropname: 'Business Case / Value Assessment Name',
    valueprop_models: 'Model(s)',
    valueprop_year1benfits: 'Year 1 Benefits',
    valueprop_totalBenefits: 'Total Benefits',
    valueprop_ROI: 'ROI',
    valueprop_paybackPeriod: 'Payback Period',
    valueprop_months: 'Months',
    valueprop_creatorName: 'Creator Name',
    valueprop_noValueProps: 'No Business Cases / Value Assessments Found',
    valueprop_notLicensedForValueProps: 'Please wait as your Business Cases / Value Assessments load.',
    valueprop_seeMore: 'See More',
    valuePropsHeading: 'Recent Business Cases / Value Assessments',
    valueRealizationsHeading: 'Recent Value Realizations (Different from VR from Insight)',
    start: 'Start',
    dealdesk: 'Dealdesk',
    custom: 'Custom',
    guidedDiscovery: 'Guided Discovery',
    chooseOptionToContinue: 'Choose an option to continue',
    quickCreate: 'Quick Create',
    add: 'Add',
    typical: 'Typical',
  };
}
