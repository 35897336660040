import { NgModule } from '@angular/core';

import { CollateralComponent } from './collateral.component';
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';

import { RouterModule } from '@angular/router';

import { Translations } from '@data/services/translation/translation.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CollateralVpDashTranslations } from './collateral.translation';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';

@NgModule({
  declarations: [CollateralComponent],
  imports: [
    AreYouSureModule,
    SidebarModule,
    EditTranslationsModule,
    RouterModule,
    NgbModule,
    MatTooltipModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    EllipsisMenuModule,
    FunctionPipeModule,
  ],
  providers: [TranslationsV2Service, CollateralVpDashTranslations, Translations],
  entryComponents: [CollateralComponent],
  exports: [CollateralComponent],
})
export class DashboardCollateralModule {}
