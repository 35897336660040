import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ShareFeatureModule } from '../share-feature/share-feature.module';
import { BreadCrumbModule } from '../shared/breadcrumb/breadcrumb.module';
import { ValueMapRoutingV2Module } from './value-map-routing.module';
import { ValuemapsComponent } from './valuemaps/valuemaps.component';
import { SolutionCategoryComponent } from './solution-category/solution-category.component';
import { ModelSolutionDetailModule } from './solution-detail/solution-detail/solution-detail.module';
import { SolutionService } from '@data/services/solution/solution.service';
import { AddSolutionComponent } from './add-solution/add-solution.component';
import { ModelOverviewModule } from './solution-detail/overview/overview.module';
import { ModelBenefitsModule } from './solution-detail/benefits/benefits.module';
import { ModelScalersModule } from './solution-detail/scalers/scalers.module';
import { LibrarySolutionsComponent } from './library-solutions/library-solutions.component';
import { ModelCaseStudiesModule } from './solution-detail/case-studies/case-studies.module';
import { ModelCostsModule } from './solution-detail/cost/cost.module';
import { ReleaseToPartnersComponent } from './solution-list/release-to-partners/release-to-partners.component';
import { ModelCaseStudyDetailModule } from './solution-detail/case-studies/casestudy-detail/casestudy-detail.module';
import { ModelAssumptionsModule } from './solution-detail/assumptions/assumptions.module';
import { ModelValueCategoryModule } from './solution-detail/value-category/value-category.module';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { SidebarModule } from 'primeng/sidebar';
import { FieldsetModule } from 'primeng/fieldset';
import { ApplicationPipesModule } from '../pipe/pipe.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { DoughnutModelsChartModule } from 'app/common/chart/doughnut-models/doughnut-models-chart.module';
import { TableModule } from 'primeng/table';
import { BarModelsModule } from 'app/common/chart/bar-models/bar-models.module';
import { MenuModule } from 'primeng/menu';
import { ModelsModule } from './models_container/models.module';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    ModelsModule,
    MenuModule,
    BarModelsModule,
    SidebarModule,
    TableModule,
    DoughnutModelsChartModule,
    ValueMapRoutingV2Module,
    DropdownModule,
    AutoCompleteModule,
    ModelOverviewModule,
    FieldsetModule,
    SidebarModule,
    SharedModule,
    ShareFeatureModule,
    BreadCrumbModule,
    AddCompanyModule,
    ApplicationPipesModule,
    ModelScalersModule,
    ModelSolutionDetailModule,
    ModelValueCategoryModule,
    ModelAssumptionsModule,
    ModelBenefitsModule,
    ModelCostsModule,
    ModelCaseStudiesModule,
    ModelCaseStudyDetailModule,
    TableChartModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  declarations: [ValuemapsComponent, SolutionCategoryComponent, AddSolutionComponent, LibrarySolutionsComponent, ReleaseToPartnersComponent],
  providers: [SolutionService, Translations],
  entryComponents: [ReleaseToPartnersComponent],
  exports: [],
})
export class ValueMapV2Module {}
