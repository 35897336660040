import { NgModule } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { AddCasestudyComponent } from './add-casestudy.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    CommonModule,
    TableModule,
    InputSwitchModule,
    FormsModule,
    AddCompanyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [AddCasestudyComponent],
  declarations: [AddCasestudyComponent],
  entryComponents: [AddCasestudyComponent],
  providers: [SolutionService, Translations],
})
export class AddCaseStudyModule {}
