<div class="categoryOverview" *ngIf="isSolutionAdmin">
  <div class="overview-container">
    <div class="model-category-container">
      <div class="panel panel-white">
        <div class="panel-heading">
          <h3 class="chart_title">{{ trans.model.listing.modelsCategoryOverview | translate }}</h3>
          <i class="icon-info m-l-xxs tooltip-custom" matTooltip="Models Category Overview" matTooltipPosition="right"></i>
        </div>
        <div class="panel-body">
          <div [hidden]="!solutionChartLoader" class="col-12 text-center">
            <img class="actionLoader" [src]="fullImagePath" />
          </div>
          <div [hidden]="solutionChartLoader" class="row">
            <div class="col-md-6 no-p">
              <canvas id="categoryOverview" height="250px"></canvas>
            </div>
            <div class="col-md-6 no-p">
              <div id="categoryOverviewLegend" class="chartLegend"></div>
              <a *ngIf="categoryModal" (click)="openCategoryChart(categoryViewModal, 4)">+{{ labelCount }} {{ trans.model.listing?.more | translate }} </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top-models-container">
      <div class="panel panel-white">
        <div class="panel-heading">
          <h3 class="chart_title">{{ trans.model.listing.top5Models | translate }}</h3>
          <i class="icon-info m-l-xxs tooltip-custom" matTooltip="Top 5 Models" matTooltipPosition="right"></i>
          <div class="panel-control"></div>
        </div>
        <div class="panel-body">
          <canvas id="barChartSolutions"></canvas>
          <div [hidden]="!topUsedSolutionChartLoader" class="text-center">
            <img class="actionLoader" [src]="fullImagePath" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createSolutionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.model.listing.modelCategories | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form form-inline">
      <div class="form-group category-form-elements">
        <span [hidden]="hidecategoryName">
          <input
            type="text"
            [placeholder]="'model.listing.categoryName' | translate"
            class="form-control"
            name="categoryName"
            required
            [(ngModel)]="categoryName"
            required
            [(ngModel)]="categoryName"
          />
        </span>
      </div>
      <div class="form-group">
        <span class="marg-h" [hidden]="hideaddCategorySolution">
          <button type="button" class="btn btn_customizable" (click)="saveCategorySolution()">{{ trans.General.Create | translate }}</button>
        </span>
        <span class="marg-h" [hidden]="hideEditCategorySolution">
          <button type="button" class="btn btn_customizable" [hidden]="" (click)="updateCategorySolution()">{{ trans.General.Update | translate }}</button>
        </span>
        <div [hidden]="hideDeleteCategorySolution">
          <p>{{ trans.model.listing.removecategoryConfirmation | translate }}</p>
        </div>
        <span class="marg-h" [hidden]="hideDeleteCategorySolution">
          <button type="button" class="btn btn_customizable" (click)="deleteCategorySolution()">{{ trans.General.Yes | translate }}</button>
        </span>
        <button type="button" class="btn btn-light marg-h" (click)="closeClick()">{{ trans.General.Cancel | translate }}</button>
      </div>
    </form>
    <div class="solutionCategoriesList" [hidden]="hideSolutionCategoriesList">
      <div [hidden]="!solutionsCategoryLoader" class="col-12 text-center">
        <img class="actionLoader" [src]="fullImagePath" />
      </div>
      <div *ngIf="solutionCategoryList.length == 0 && !solutionsCategoryLoader" class="m-t-md col-sm-12">
        <div class="searchTableContainer text-center">
          <h4>{{ trans.model.listing.noModelCategoriesFound | translate }}</h4>
        </div>
      </div>
      <ul [hidden]="solutionsCategoryLoader">
        <li *ngFor="let solutionCategory of solutionCategoryList" class="clearfix">
          <p class="pull-left">{{ solutionCategory.category }}</p>
          <p class="pull-right actions">
            <i class="icon-pencil" role="button" (click)="openSolution(2, solutionCategory.id, solutionCategory.category)"></i>
            <i class="icon-trash" role="button" (click)="openSolution(3, solutionCategory.id, solutionCategory.category)"></i>
          </p>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #categoryViewModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.model.listing.modelsCategoryOverview | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [hidden]="!solutionChartLoader" class="col-12 text-center">
      <img class="actionLoader" [src]="fullImagePath" />
    </div>
    <div [hidden]="solutionChartLoader" class="row">
      <div class="col-md-6 no-p">
        <canvas id="categoryOverviewModal" height="250px"></canvas>
      </div>
      <div class="col-md-6 no-p">
        <div id="categoryOverviewLegendModal" class="chartLegend"></div>
      </div>
    </div>
  </div>
</ng-template>

<div class="row soln-search-row">
  <div class="col-md-12">
    <div class="panel panel-white">
      <div class="panel-heading clearfix" style="height: inherit !important; background-color: #f5f5f5">
        <div class="row">
          <div class="col-xs-12">
            <dl-menubar styleClass="details_menu_bar" class="details_menu_bar" [model]="items"></dl-menubar>
          </div>
        </div>
      </div>
      <div class="panel-body" style="margin-top: 20px">
        <app-maintenance-situationsv1 [solutionId]="solutionId" *ngIf="view == 'situations'"></app-maintenance-situationsv1>
        <app-value-map-pain-points *ngIf="view == 'pain_points'" [can_edit]="isSolutionAdmin"></app-value-map-pain-points>
        <app-value-capabilities *ngIf="view == 'capabilities'" [can_edit]="isSolutionAdmin"></app-value-capabilities>
        <app-value-map-op-objectives *ngIf="view == 'operational_objectives'" [can_edit]="isSolutionAdmin"></app-value-map-op-objectives>
        <app-value-map-func-objectives *ngIf="view == 'functional_objectives'" [can_edit]="isSolutionAdmin"></app-value-map-func-objectives>
        <app-value-category *ngIf="view == 'value_category'" [can_edit]="isSolutionAdmin" [solutionId]="solutionId"></app-value-category>

        <div *ngIf="view == 'model_categories'">
          <div class="row">
            <div class="col-xs-6">
              <h3 class="title">{{ trans.model.listing.modelCategories | translate }}</h3>
            </div>
          </div>

          <div class="row marg-v">
            <div class="col-xs-12">
              <form class="form form-inline">
                <div class="form-group category-form-elements">
                  <span [hidden]="hidecategoryName">
                    <input
                      type="text"
                      [placeholder]="'model.listing.categoryName' | translate"
                      class="form-control"
                      name="categoryName"
                      required
                      [(ngModel)]="categoryName"
                      required
                      [(ngModel)]="categoryName"
                    />
                  </span>
                </div>
                <div class="form-group">
                  <span [hidden]="hideaddCategorySolution">
                    <button type="button" class="btn btn_customizable marg-h" (click)="saveCategorySolution()">{{ trans.General.Create | translate }}</button>
                  </span>
                  <span [hidden]="hideEditCategorySolution">
                    <button type="button" class="btn btn_customizable marg-h" [hidden]="" (click)="updateCategorySolution()">{{ trans.General.Update | translate }}</button>
                  </span>
                  <div [hidden]="hideDeleteCategorySolution">
                    <p>{{ trans.model.listing.removecategoryConfirmation | translate }}</p>
                  </div>
                  <span [hidden]="hideDeleteCategorySolution">
                    <button type="button" class="btn btn_customizable marg-h" (click)="deleteCategorySolution()">{{ trans.General.Yes | translate }}</button>
                  </span>
                  <button type="button" class="btn btn-light marg-h" (click)="closeClick()">{{ trans.General.Cancel | translate }}</button>
                </div>
                <div class="row marg-v">
                  <div class="col-xs-12"><input type="checkbox" name="is_conversation" id="is_conversation" [(ngModel)]="is_conversation" /> Is Conversion?</div>
                </div>
              </form>
              <div class="solutionCategoriesList" [hidden]="hideSolutionCategoriesList">
                <div [hidden]="!solutionsCategoryLoader" class="col-12 text-center">
                  <img class="actionLoader" [src]="fullImagePath" />
                </div>
                <div *ngIf="solutionCategoryList.length == 0 && !solutionsCategoryLoader" class="m-t-md col-sm-12">
                  <div class="searchTableContainer text-center">
                    <h4>{{ trans.model.listing.noModelCategoriesFound | translate }}</h4>
                  </div>
                </div>
                <ul [hidden]="solutionsCategoryLoader">
                  <li *ngFor="let solutionCategory of solutionCategoryList" class="clearfix">
                    <p class="pull-left">{{ solutionCategory.category }}</p>
                    <p class="pull-right actions">
                      <i class="fa fa-pencil" role="button" (click)="openSolution(2, solutionCategory.id, solutionCategory.category, solutionCategory)"></i>
                      <i class="fa fa-times" role="button" (click)="openSolution(3, solutionCategory.id, solutionCategory.category)"></i>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="soln_table_cont" *ngIf="view == 'models'">
          <div class="row">
            <div class="col-xs-6">
              <h3 class="title">{{ trans.model.listing.Models | translate }}</h3>
            </div>

            <div class="col-xs-6">
              <button
                class="btn btn_customizable pull-right"
                routerLink="/model/create"
                *ngIf="isSolutionAdmin"
                [matTooltip]="trans.models_v2.add_new_model | translate"
                matTooltipPosition="below"
              >
                <i class="fa fa-plus"></i>{{ trans.General.addNew | translate }}
              </button>
            </div>
          </div>

          <p-menu #rowLimitMenu appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

          <div class="row">
            <div class="col-xs-4">
              <div class="input-group keywest_search">
                <span class="input-group-addon"><i class="fa fa-search"></i></span>
                <input [(ngModel)]="searchText" (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" type="text" placeholder="Search" class="form-control" />
                <span *ngIf="searchText !== ''" (click)="searchText = ''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"
                  ><i class="fa fa-times"></i
                ></span>
              </div>
            </div>

            <div class="col-xs-8">
              <span class="clickable pull-right" (click)="rowLimitMenu.toggle($event)">Row Limit {{ pageLimit }} <i class="fa fa-chevron-down"></i></span>
            </div>
          </div>

          <div class="models_page_header_container_small">
            <div class="row">
              <div class="col-xs-12">
                <p-table #tableSolutionList [columns]="columns" [value]="solutions" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th>{{ trans.model.listing.modelName | translate }}</th>
                      <th>{{ trans.model.listing.Category | translate }}</th>
                      <th>{{ trans.model.listing.releasedToUsers | translate }}</th>
                      <th>{{ trans.model.listing.releasedToPartners | translate }}</th>
                      <th *ngIf="isSolutionAdmin"></th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-solution>
                    <tr class="soln_row">
                      <td data-title="Model Name">
                        <a routerLink="/model/{{ solution.id }}" class="link">{{ solution.name }}</a>
                      </td>
                      <td data-title="Category">{{ solution.category }}</td>
                      <td data-title="Released to Users">
                        <a *ngIf="solution.userReleaseStatusId == 0" class="link">-</a>
                        <a *ngIf="solution.userReleaseStatusId == 2" class="link">{{ trans.model.listing.releasedToUsers | translate }} </a>
                      </td>
                      <td data-title="Released to partners">
                        <a *ngIf="solution.partnerReleaseStatusId == 1" class="link">-</a>
                        <a *ngIf="solution.partnerReleaseStatusId == 2" class="link">{{ trans.model.listing.releasedToPartners | translate }} </a>
                        <a *ngIf="solution.partnerReleaseStatusId == 3" class="link">{{ trans.model.listing.Specific | translate }}</a>
                        <a *ngIf="solution.partnerReleaseStatusId == 4" class="link">{{ trans.model.listing.Group | translate }}</a>
                      </td>
                      <td data-title="Actions">
                        <app-ellipsis-menu *ngIf="isSolutionAdmin && solution.share_role_type_id != 1 && solution.share_role_type_id != 4">
                          <ul class="list-group">
                            <li class="list-group-item" (click)="releaseToAllUsers(solution.id)" *ngIf="solution.share_role_type_id != 2">
                              <i class="icon-users"></i> {{ trans.model.listing.releaseToAllUsers | translate }}
                            </li>
                            <li class="list-group-item" (click)="releaseToPartners(solution.id)" *ngIf="solution.partnerReleaseStatusId == 1 && solution.share_role_type_id != 2">
                              <i class="icon-user-follow"></i> {{ trans.model.listing.releaseToPartners | translate }}
                            </li>
                            <li
                              class="list-group-item"
                              *ngIf="solution.partnerReleaseStatusId != 1 && solution.share_role_type_id != 2"
                              (click)="removeFromPartnersModel(removePartnersModal, solution.id)"
                            >
                              <i class="icon-close"></i> {{ trans.model.listing.removeFromPartners | translate }}
                            </li>
                            <li class="list-group-item" (click)="deleteSolution(solution.id)"><i class="icon-trash"></i> {{ trans.General.Delete | translate }}</li>
                            <li class="list-group-item" (click)="openModal(modelsShare, solution)"><i class="prop-icon icon-share"></i> {{ trans.model.listing.assignModelAdmin | translate }}</li>
                            <li class="list-group-item" (click)="funcCloneModel(solution)"><i class="fa fa-files-o"></i> Clone Model</li>
                          </ul>
                        </app-ellipsis-menu>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>

          <div [hidden]="!solutionsLoader" class="col-12 text-center">
            <img class="actionLoader" [src]="fullImagePath" />
          </div>
          <div *ngIf="solutions.length == 0 && !solutionsLoader" class="m-t-md col-sm-12">
            <div class="searchTableContainer text-center">
              <h4>{{ trans.dashboard.noValueModels | translate }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #removePartnersModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.model.listing.removeThisRelease | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ trans.model.listing.removeReleaseFromPartnersConfirmation | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-light" (click)="removeFromPartners()">{{ trans.General.Yes | translate }}</button>
    <button type="submit" class="btn btn_customizable" (click)="d('Cross click')">{{ trans.General.No | translate }}</button>
  </div>
</ng-template>

<ng-template #modelsShare let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.model.listing.modelAdministrators | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-share [data]="selectedSolution"></app-share>
  </div>
</ng-template>
