import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelCaseStudyDetailModule } from 'app/value-mapv2/solution-detail/case-studies/casestudy-detail/casestudy-detail.module';
import { RealizationCaseStudyModule } from 'app/value-realization/case-studies/case-studies.module';
import { SidebarModule } from 'primeng/sidebar';
import { TrackerCaseStudiesComponent } from './tracker-case-studies.component';

@NgModule({
  imports: [CommonModule, RealizationCaseStudyModule, ModelCaseStudyDetailModule, SidebarModule],
  exports: [TrackerCaseStudiesComponent],
  declarations: [TrackerCaseStudiesComponent],
  providers: [],
})
export class TrackerCaseStudiesModule {}
