<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
<p-menu #inlinestatus appendTo="body" [popup]="true" [model]="tableStatus" styleClass="style-2022"></p-menu>

<ng-template #loadedContent>
  <div class="benefits-tab">
    <div class="row marg-v">
      <div class="col-xs-3">
        <div class="input-group keywest_search no-background">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input [(ngModel)]="strSearch" (ngModelChange)="searchBenefits($event)" type="text" [placeholder]="trans.trans.search.value" class="form-control" />
          <span *ngIf="strSearch !== ''" (click)="searchBenefits()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 24px">
      <div class="col-md-6 col-xs-12">
        <span
          class="full-badge full-badge-clear clickable"
          style="margin-left: 0px"
          (click)="selectAllFilters(!allSelected)"
          [ngClass]="{ 'full-badge-clear-selected': allSelected }"
          >{{ trans.trans.all.value }}</span
        >
        <ng-container *ngFor="let filtr of filters">
          <span (click)="filtr.command()" [ngClass]="{ 'full-badge-clear-selected': filtr.active }" class="full-badge full-badge-clear clickable">{{ filtr.label }}</span>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="searchedBenefitList.length > 0; else emptyList">
      <ng-container *ngFor="let benefitType of searchedBenefitList">
        <h3>
          {{ trans.trans.benefits.value }} <span *ngIf="benefitType.id !== '1'">{{ trans.trans.non.value }} </span> {{ trans.trans.financial.value
          }}<span *ngIf="benefitType.id !== '1'">: {{ benefitType.benefit_type_name }} </span>
        </h3>

        <ng-container *ngFor="let benefitCategory of benefitType.vcs">
          <div class="bordered-panel-tile" *ngIf="benefitCategory.metrics.length">
            <div class="category-card-resume">
              <div class="category-card-title">
                <span class="material-icons-outlined accordion" (click)="toggleBenefitTable(benefitCategory)">
                  {{ benefitCategory.expand ? 'arrow_drop_down' : 'arrow_right' }}
                </span>

                <span class="clickable benefit-text" (click)="toggleBenefitTable(benefitCategory)">
                  {{ benefitCategory.value_category_name }}

                  <span
                    *ngIf="benefitCategory.description"
                    class="material-icons-outlined tooltip-custom info-icon"
                    [matTooltip]="benefitCategory.description"
                    matTooltipPosition="above"
                    container="body"
                  >
                    info
                  </span> </span
                ><br />
                <div class="row left-pad">
                  <div class="badge-wrapper">
                    <span class="full-badge full-badge-success">{{ benefitCategory.activeCount }} {{ trans.trans.active.value }}</span>
                  </div>
                  <div class="badge-wrapper">
                    <span class="full-badge marg-h full-badge-danger">{{ benefitCategory.naCount }} {{ trans.trans.na.value }}</span>
                  </div>
                  <div class="badge-wrapper">
                    <ng-container *ngIf="!feature39 && !feature46">
                      <span class="full-badge full-badge-warning">{{ benefitCategory.softCount }} {{ trans.trans.soft.value }}</span>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="category-card-details">
                <div class="status column">
                  <label>{{ trans.trans.status.value }}</label>
                  <span></span>
                </div>
                <div class="expected-total column">
                  <label>{{ trans.trans.expectedTotalValue.value }}</label>
                  <span>{{ benefitCategory.expected_annual_value_fmt }}</span>
                </div>
                <div class="expected-date column">
                  <label>{{ trans.trans.expectedValueToDate.value }}</label>
                  <span>{{ benefitCategory.expected_value_to_date_fmt }}</span>
                </div>
                <div class="achieved-date column">
                  <label>{{ trans.trans.achievedValueToDate.value }}</label>
                  <span>{{ benefitCategory.realized_value_to_date_fmt }}</span>
                </div>
                <div class="variance-date column">
                  <label>{{ trans.trans.varianceToDate.value }}</label>
                  <span>
                    <span [ngStyle]="{ color: benefitCategory.variance_to_date > 0 ? 'green' : 'red' }" class="material-icons-outlined">
                      {{ benefitCategory.variance_to_date > 0 ? 'arrow_upward' : ' arrow_downward' }}
                    </span>
                    {{ benefitCategory.variance_to_date_fmt }}
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="benefitCategory.expand" class="benefits-table padding-15-top">
              <p-table [value]="benefitCategory.metrics" #baselineBenefitsTable responsiveLayout="scroll" styleClass="keywest_table" tableStyleClass="keywest_table">
                <ng-template pTemplate="body" let-benefit>
                  <tr>
                    <td *ngFor="let col of columns; let i = index" [style]="i === 0 ? 'width: 33.5%' : i === 1 ? 'width: 7.9%' : 'width: 14.71%'">
                      <ng-container *ngIf="col.field === 'metric_name'">
                        <span class="clickable" (click)="openBenefitScratchpad(benefit)">
                          {{ benefit.metric_name }}
                        </span>
                      </ng-container>

                      <ng-container *ngIf="col.field === 'status'">
                        <a
                          [matTooltip]="trans.trans.chBenefitStatus.value"
                          matTooltipPosition="above"
                          (click)="openActiveTypesPmenu(benefit, inlinestatus, $event)"
                          class="dashedLink benefitStatusElement"
                          data-toggle="tooltip"
                          title="Change Benefits Status"
                        >
                          <span class="activeStatusType benefitStatusElement" [ngStyle]="{ 'background-color': benefit.active | functionPipe: getBenefitsStatus }"></span>
                        </a>
                      </ng-container>

                      <ng-container *ngIf="col.field !== 'metric_name' && col.field !== 'status'">
                        <span>
                          <ng-container *ngIf="col.showIcon">
                            <span [ngStyle]="{ color: benefit.iconColor }" class="material-icons-outlined"> {{ benefit.icon }} </span>
                          </ng-container>
                          {{ benefit[col.field] }}
                        </span>
                      </ng-container>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </ng-container>
        <div *ngIf="benefitType.vcs | functionPipe: noBenefitGroupToShow">
          {{ trans.trans.noMatches.value }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #emptyList>
  <app-no-records [records]="true">
    {{ trans.trans.businessReviewWarning.value }}
  </app-no-records>
</ng-template>

<p-sidebar
  [(visible)]="showBenefitScratchpad"
  *ngIf="showBenefitScratchpad"
  styleClass="keywest_sidebar p-sidebar-lg"
  position="right"
  [showCloseIcon]="false"
  [dismissible]="false"
  [closeOnEscape]="false"
  (onHide)="closeBenefitScratchpad()"
>
  <app-benefit-flyout-detail
    *ngIf="showBenefitScratchpad && !cleanFlyout"
    [benefitData]="benefitScratchpad"
    [term]="valueProp?.term"
    [can_edit]="true"
    [valuePropId]="valuePropId"
    (callback)="updateValues()"
    (closeSidebar)="closeBenefitScratchpad()"
  ></app-benefit-flyout-detail>
</p-sidebar>
