import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { Template, TemplateLink } from '@data/services/valueprop/models/asset.model';
import { LanguageType } from '@shared/models/user-info.model';
import { Subject } from 'rxjs';
import { Solution } from '@data/services/valueprop/models/solution.model';
import { takeUntil, finalize } from 'rxjs/operators';
import { AssetsManageProfileTranslations } from '../assets.translation';
import { AssetTemplate } from '@shared/models/asset.models';
import { SelectedAsset } from '../interfaces/selected-asset.interface';
import { AssetService } from '@data/services/asset/asset.service';

@Component({
  selector: 'app-asset-templates',
  templateUrl: './asset-templates.component.html',
  styleUrls: ['./asset-templates.component.scss']
})
export class AssetTemplatesComponent implements OnInit, OnDestroy {

  showTranslate = false;
  ngUnsubscribe = new Subject();
  loadingLanguages = false;
  languages: LanguageType[];
  showPotentialFiles: boolean = false;

  @Input() selectedElem: Partial<AssetTemplate>;
  @Input() templates: Template[] = [];
  @Input() solutions: Solution[] = [];
  @Input() selectedAsset: SelectedAsset;
  @Output() changes = new EventEmitter<void>();
  @Output() deleteTemplate = new EventEmitter<Template>();
  @Output() saveInPlace = new EventEmitter<boolean>();

  extensions: string = '';

  constructor(
    private assetService: AssetService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: AssetsManageProfileTranslations,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.getLanguages();
    this.setAcceptedExtensions();

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  setAcceptedExtensions() {
    switch ( this.selectedAsset.format_type_id ) {
      case "1":
        // Word
        this.extensions = ".docs, .docx"
        break;
      case "2":
        // Excel
        this.extensions = ".xls, .xlsx"
        break;
      case "3":
        // Powerpoint
        this.extensions = ".ppt, .pptx"
        break;
      default:
        this.extensions = ".xls, .xlsx, .ppt, .doc, .docs, .docx, .pptx"
    }
  }

  getLanguages(): void {
    this.loadingLanguages = true;
    this.userService
      .getLanguages()
      .pipe(
        finalize(() => this.loadingLanguages = false)
      )
      .subscribe((result) => {
        this.languages = result.result;
      });
  }

  handleInputChange(event, index: number): void {
    this.templates[index].file = event.target.files.item(0);
    this.notifyChanges();
  }

  saveUploadedFile() {
    this.saveInPlace.emit();
  }

  solutionsOptionsChanges(template: Template): void {
    if (template.solutionsSelected.length === this.solutions.length) {
      template.default_active = '1';
    } else if (template.solutionsSelected.length > 0) {
      template.default_active = null;
    } else {
      template.default_active = '0';
    }
    this.notifyChanges();
  }

  addTemplate(): void {
    this.templates.push({
      language_type_id: '',
      name: '',
      description: '',
      artifact_name: '',
      default_active: '0',
    });

    this.notifyChanges();
  }

  manuallyAssign(template: Template, file: TemplateLink) {
    let payload = {
      asset_type_id: this.selectedAsset.asset_type_id,
      format_type_id: template.format_type_id,
      account_asset_template_id: template.id,
      template_path: file.directory_path,
    }

    this.assetService
      .manuallyAssignTemplatePath(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.assetService.refreshTemplate$.next();
      });
    
  }

  deleteTemplateFile(file: TemplateLink) {
    this.assetService
      .deleteTemplateFile(file.template_path_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.assetService.refreshTemplate$.next();
      });
  }

  notifyChanges() {
    this.changes.emit();
  }

}
