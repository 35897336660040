import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-value-category',
	templateUrl: './value-category.component.html',
	styleUrls: ['./value-category.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ValueCategoryComponent implements OnInit, OnDestroy {
	@Input() solutionId: number;
	@Input() tabInit: number

	valueCategories: any[] = [];
	// valueCategoryToEdit: {value_category_id: "", value_category: ""};
	valueCatName: string = "";
	valueCatId: string = "";
	subscriptionreadAllValueCategory: Subscription;
	subscriptiongetValueCategories: Subscription;
	subscriptiondeleteSolutionValueCategory: Subscription;
	subscriptioneditSolutionValueCategory: Subscription;

	// new
	addEditThreshold: any;
	loadingSave: boolean = false;
	toggleAddCategory: boolean = false;
	dropdown: any[] = [];
	sidebar_title: string = "Add New Benefit Group";
	mode: string = "add";

	cols = [
		{ field: 'value_category', header: 'Name' }
	];
	style2022$: Observable<boolean>;


	constructor(
		private solutionService: SolutionService,
		private notification: NotificationService,
		private styleService: StyleService
	) { }

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getValueCategories();

		this.subscriptionreadAllValueCategory = this.solutionService.readAllValueCategory.subscribe(() => {
			this.getValueCategories();
		});
	}

	ngOnDestroy() {
		if (this.subscriptionreadAllValueCategory) { this.subscriptionreadAllValueCategory.unsubscribe(); }
		if (this.subscriptiongetValueCategories) { this.subscriptiongetValueCategories.unsubscribe(); }
		if (this.subscriptiondeleteSolutionValueCategory) { this.subscriptiondeleteSolutionValueCategory.unsubscribe(); }
		if (this.subscriptioneditSolutionValueCategory) { this.subscriptioneditSolutionValueCategory.unsubscribe(); }
	}

	openAddValueCategory() {
		this.mode = "add";
		this.sidebar_title = "Add Benefit Group";
		this.valueCatName = "";
		this.valueCatId = "";
		this.toggleAddCategory = !this.toggleAddCategory;
	}

	funcBuildMenu(elem, menu, event) {
		this.dropdown = [
			{
				label: "Edit", icon: "fa fa-pencil", command: () => {
					this.sidebar_title = "Edit Benefit Group";
					this.mode = "edit";
					this.valueCatId = elem.value_category_id;
					this.valueCatName = elem.value_category;
					this.toggleAddCategory = true;

				}
			},
			{
				label: "Delete", icon: "fa fa-times", command: () => {
					this.deleteSolutionValueCategory(elem.value_category_id)
				}
			},
		]

		menu.toggle(event)
	}

	getValueCategories() {
		this.subscriptiongetValueCategories = this.solutionService.getValueCategories(this.solutionId).subscribe((response) => {
			console.log("getValueCategories", response.result.value_categories);
			if (response.result && response.result.value_categories) {
				this.valueCategories = response.result.value_categories;
			}
		});
	}

	deleteSolutionValueCategory(valueCategoryId) {
		this.subscriptiondeleteSolutionValueCategory = this.solutionService.deleteSolutionValueCategory(valueCategoryId, this.solutionId).subscribe((response) => {

			if (response.result) {
				this.notification.success(response.result, false); // Params {message, islogout}				
				this.getValueCategories();

			}
		});

	}
	
	addNewValueCategory(newCategory) {
		let params = {
			'name': newCategory
		}

		let aid = sessionStorage.getItem('aid');

		this.solutionService.addNewValueCategory(params, aid).subscribe((response) => {
			if (response.result && response.result.success) {
				let categoryInfluence = {
					'id': response.result.value_category_id
				};

				let valueCatObj = {
					"account_solution_id": this.solutionId,
					"value_category_id": categoryInfluence.id
				}
				this.solutionService.saveValueCategory(valueCatObj).subscribe((response) => {
					if (response.result) {
						this.notification.success("Value Category Added successfully", false); // Params {message, islogout}
						this.solutionService.readAllValueCategory.next('readList');
						this.toggleAddCategory = !this.toggleAddCategory;
					}
				});
			}
		});
	}

	updateValueCategory() {
		let payload = {
			"value_category_id": this.valueCatId,
			"name": this.valueCatName
		};
		let accountId = sessionStorage.getItem("aid");
		this.subscriptioneditSolutionValueCategory = this.solutionService.editSolutionValueCategory(payload, accountId).subscribe((response) => {
			if (response.result) {
				this.notification.success(response.result.message, false); // Params {message, islogout}				
				this.solutionService.readAllValueCategory.next('readList');
				this.toggleAddCategory = !this.toggleAddCategory;
			}
		});
	}

}
