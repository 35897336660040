<div class="row">
<p class="sidebar_header">{{trans.trans.editInfo.value}}</p>
</div>
<div class="row mx-15">
    <form class="form-horizontal" name="userInfoForm" #userInfoForm="ngForm" (ngSubmit)="onSubmit(userInfoForm)">
    
        <div class="row mx-15">
            <div class="col-xs-12">
                <label for="phone">
                    {{trans.trans.phone.value}}
                    <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
                        [transObj]="trans.trans.phone" (callback)="getTranslations()"></app-editTranslation>
                </label>
                <input class="form-control" name="phone" [(ngModel)]="formUser.phone">
            </div>
        </div>
    
        <div class="row spacer"></div>
        
        <div class="row mx-15">
            <div class="col-xs-12">
                <span class="pull-right">
                    <button type="button" class="btn btn-secondary mr-15" (click)="close()">
                        {{trans.trans.cancel.value}}
                    </button>
                    <button type="submit" class="btn btn_customizable" [disabled]="userInfoForm.invalid">
                        {{trans.trans.save.value}}
                    </button>
                </span>
               
            </div>
        </div>
    </form>
</div>
