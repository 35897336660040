
    <p-sidebar *ngIf="successSidebarOpen" [(visible)]="successSidebarOpen" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="closeNotesSidebar()">
        <div *ngIf="successSidebarOpen" class="sidebar_wrapper">
            <div class="sidebar_header">
                {{ trans.trans.successCriteria.value }}
            </div>
            <div class="sidebar_container">
                <div class="goal-grid">
                    <div class="goal-title">{{ trans.trans.name.value }}</div>
                    <div class="content-box">{{ selectedQuestion?.name }}</div>
                    <div class="spacer"></div>
                    <div *ngIf="selectedQuestion?.answer" class="goal-title">{{ trans.trans.description.value }}</div>
                    <div *ngIf="selectedQuestion?.answer" class="content-box">{{ selectedQuestion?.answer }}</div>
                </div>

                <div class="flex-jbetween notes-header">
                    <div class="notes-title">
                    <span *ngIf="brsWithNotes && brsWithNotes.length > 0">{{ trans.trans.notes.value }}</span>
                    </div>
                    <div>
                    <button *ngIf="canEdit" class="btn btn_customizable pull-right" (click)="showNoteSidebar = true">{{ trans.trans.addOrEditNotes.value }}</button>
                    </div>
                </div>

                <app-business-review-notes-table [valuePropId]="valuePropId" [disableEdit]="true" [objectId]="selectedQuestion.account_discovery_question_id" [section]="'discoveryQuestion'"></app-business-review-notes-table>
            </div>
        
        </div>
    </p-sidebar>

    <p-sidebar *ngIf="showNoteSidebar" [(visible)]="showNoteSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" [modal]="false">
        <div class="sidebar_wrapper">
            <div class="sidebar_header">
                {{ trans.trans.addNote.value }}
            </div>
            <div class="sidebar_container">
                <app-business-review-notes-sidebar
                [valuePropId]="valuePropId"
                (backClicked)="closeNotesSidebar()"
                [objectId]="selectedQuestion.account_discovery_question_id"
                [section]="'discoveryQuestion'"
                ></app-business-review-notes-sidebar>
            </div>
        </div>
    </p-sidebar>

  