import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2RFuncObjectiveTranslations {
  public config: any = {
    component: 'r_func_objectives',
    display_name: 'Functional Objectives Read',
    description: 'Read Functional Objectives'
  };

  public trans: any = {
    view_func_objective: 'View Functional Objective',
    name: 'Name',
    description: 'Description'
  };
}
