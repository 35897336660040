import { Injectable } from '@angular/core';

@Injectable()
export class TrackValueBenefitTabTranslations {
  public config: any = {
    component: 'trackValueBenefitTab',
    display_name: 'Track Value Benefit Tab',
    description: 'The benefit tab found in the track value detail dashboard',
  };

  public trans: any = {
    active: 'Active',
    soft: 'Considered Soft',
    na: 'N/A',
    search: 'Search',
    benefits: 'Benefits',
    non: 'Non',
    financial: 'Financial',
    setStatus: 'Set Status',
    status: 'Status',
    benefit: 'Benefit',
    changeStatusSuccess: 'Status changed successfully',
    expectedTotalValue: 'Expected Total Value',
    expectedValueToDate: 'Expected Value to Date',
    varianceToDate: 'Variance to Date',
    achievedValueToDate: 'Achieved Value to Date',
    businessReviewWarning: 'Benefit data will appear when business review data has been entered.',
    all: 'All',
    noMatches: 'No benefit matches the current filter',
    chBenefitStatus: 'Change benefit status',
    benefitUpdateSuccess: 'Benefit status updated Successfully',
    benefitPerformance: 'Benefit Performance successfully saved',
  };
}
