import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';

import { CompetitiveTcoComponent } from './competitive-tco.component';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ChartModule } from 'primeng/chart';
import { CompetitiveTCOTranslations } from './competitive-tco.translation';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		ProgressBarModule,
		EditTranslationsModule,
		ChartModule,
    ValuePropFlowButtonsModule,
	],
	exports: [CompetitiveTcoComponent],
	declarations: [CompetitiveTcoComponent],
	providers: [TranslationsV2Service, CompetitiveTCOTranslations],
})
export class CompetitiveTCOModule { }
