<div id="style-wrapper" class="style-2022">
  <style #st></style>

  <p-sidebar [(visible)]="aI.showSystemStatus" position="left" styleClass="keywest_sidebar p-sidebar-md">
    <div class="sidebar_wrapper" *ngIf="aI.showSystemStatus">
      <div class="sidebar_header">System Control</div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-xs-12">
              Services URL: {{ aI.commonService.getServiceUrl() }}<br /><br />
              Assets URL: {{ aI.commonService.getAssetUrl() }}<br /><br />
              Media Server URL: {{ aI.commonService.getMediaServerName() }}<br /><br />
              Current Services URL: {{ aI.servicesUrl }}<br />
              <a (click)="aI.setServicesUrl('https://jamaicadevservices.value-cloud.com/')" class="clickable">Set Jamaica Dev Services URL</a><br />
              <a (click)="aI.setServicesUrl('https://develop.api.value-cloud.com/')" class="clickable">Set Develop Services URL</a><br /><br />
              <div class="row">
                <div class="col-xs-12">
                  <div class="input-group">
                    <input type="text" [(ngModel)]="aI.server" class="form-control" placeholder="Enter in a backend api URL" (keyup.enter)="setServiceUrl()" />
                    <div (click)="setServiceUrl()" class="input-group-addon clickable">
                      <span>Go</span>
                    </div>
                  </div>
                  <label for="bypass" class="mr-1">Bypass URL check</label><input type="checkbox" id="bypass" [(ngModel)]="bypassCheck" />
                </div>
                <span *ngIf="apiError" class="col-xs-12" style="margin-top: 10px; color: red">API URL does not exist</span>
              </div>
              <a (click)="aI.setV2()" class="clickable">Switch to KeyWest Layout</a><br />
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <label>SFDC Login</label>
              <div class="input-group">
                <input type="text" [(ngModel)]="aI.cuie1" class="form-control" placeholder="Enter in the cuie1 HASH from SFDC iFrame" />
                <input type="text" [(ngModel)]="aI.dlke2" class="form-control" placeholder="Enter in the DLKE2 HASH from SFDC iFrame" />
                <input type="text" [(ngModel)]="aI.sfdcRedirect" class="form-control" placeholder="Redirect Param" />
                <div class="input-group-addon">
                  <a (click)="aI.SFDCLogin()">Go</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>

  <div
    *ngIf="aI.notify_refresh"
    style="z-index: 999999; background-color: red; color: white; display: block; height: 40px; width: 100%; position: absolute; top: 0px; left: 0px; text-align: center"
  >
    <p style="font-size: 22px; padding-top: 6px">
      A new version of Value Execution is available. Please <a (click)="aI.reload()" class="clickable link" style="color: white; text-decoration: underline">refresh</a> to view it now.
    </p>
  </div>

  <div #portalHost></div>
</div>
