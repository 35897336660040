import { Injectable } from '@angular/core';

@Injectable ()

export class BenefitsTableTranslations {

    public config: any = {
        component: 'benefitsTable',
        display_name: 'Benefits Table',
        description: 'Benefits table in value prop dashboard'
    };

    public trans: any = {
        benefits: 'Benefits',
        ch_benefits: 'The metrics used to quantify the improvement to the customer after successfully deploying your solution.',
        active: 'Active',
        na: 'N/A',
        soft: 'Considered Soft',
        no_benefits_found: 'No benefits found',
        financial: 'Financial',
        nonFinancial: 'Non-Financial',
    };
}
