import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FactsService } from '@data/services/facts/facts.service';
import { CompanyService } from '@data/services/company/company.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { AddCompanyTransTranslations } from './add-company_no_trans.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { Countries, Industries, IndustrySectors, States } from '../interfaces/company.interface';
import { Company } from '@shared/models/company.model';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-vp-add-company-no-trans',
	templateUrl: './add-company.component.html',
	styleUrls: ['./add-company.component.scss'],
	providers: [CompanyService],
	encapsulation: ViewEncapsulation.None
})
export class AddVPCompanyNoTransComponent implements OnInit, OnDestroy {

	@Input() modalCreate: boolean;
	@Output() companyCreated = new EventEmitter();
	@Output() enableSave = new EventEmitter();
	@Output() cancelCreation = new EventEmitter();
	@ViewChild('addCompanyForm', { static: true }) addCompanyForm;

	company: Partial<Company>;
	industrySectors: IndustrySectors;
	countries: Countries;
	industries: Industries;
	industriesShow = true;
	industriesLoader = false;
	statesLoader = true;
	states: States;
	addCompanyLoader: boolean;
	fullImagePath: string;
	countryDetails: string;
	contextualHelp = {};

	subscriptionstatusChanges: Subscription;
	subscriptioncontextualHelp: Subscription;
	subscriptiongetSectors: Subscription;
	subscriptiongetCountries: Subscription;
	subscriptiongetStatesByCountries: Subscription;
	subscriptiongetIndustriesBySector: Subscription;
	subscriptionaddCompany: Subscription;
	subscriptionsearchWithScraper: Subscription;

	ngUnsubscribe$ = new Subject();
	showTranslate = false;
	hasFeature115 = false;
	style2022$: Observable<boolean>;


	constructor(
		private factsService: FactsService,
		private companyService: CompanyService,
		private location: Location,
		private router: Router,
		private notificationService: NotificationService,
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: AddCompanyTransTranslations,
		private styleService: StyleService,
	) {
		const image_url = this.commonService.getImageUrl();
		this.fullImagePath = image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;

		this.hasFeature115 = this.commonService.checkFeature(115);
		  this.getTranslations();
		  this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
			this.getTranslations();
		  });
		  this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
			this.showTranslate = res;
		  });

		this.countryDetails = '';
		this.company = {
			'name': '',
			'industry_sector_id': '',
			'industry_id': '',
			'address1': '',
			'city': '',
			'state': '',
			'country_id': '',
			'employees': '',
			'net_sales': 0,
			'zip': '',
			'country': ''
		};
		this.subscriptionstatusChanges = this.addCompanyForm.statusChanges.subscribe(data => {
			if (data === 'VALID') {
				this.enableSave.emit('true');
			} else {
				this.enableSave.emit('false');
			}
		});
		this.getIndustrySectors();
		this.getCountries();
		this.getContextualHelp();

	}

	ngOnDestroy(): void {
		if (this.subscriptionstatusChanges) { this.subscriptionstatusChanges.unsubscribe(); }
		if (this.subscriptioncontextualHelp) { this.subscriptioncontextualHelp.unsubscribe(); }
		if (this.subscriptiongetSectors) { this.subscriptiongetSectors.unsubscribe(); }
		if (this.subscriptiongetCountries) { this.subscriptiongetCountries.unsubscribe(); }
		if (this.subscriptiongetStatesByCountries) { this.subscriptiongetStatesByCountries.unsubscribe(); }
		if (this.subscriptiongetIndustriesBySector) { this.subscriptiongetIndustriesBySector.unsubscribe(); }
		if (this.subscriptionaddCompany) { this.subscriptionaddCompany.unsubscribe(); }
		if (this.subscriptionsearchWithScraper) { this.subscriptionsearchWithScraper.unsubscribe(); }

		this.ngUnsubscribe$.next(false);
		this.ngUnsubscribe$.complete();
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans.trans
		};

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

	getContextualHelp(): void {
		this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe(response => {
			this.contextualHelp = response['Valueprop'];
		});
	}

	getIndustrySectors(): void {
		this.subscriptiongetSectors = this.factsService.getSectors().subscribe((response) => {
			this.industrySectors = response.result;
		});
	}

  getCountries(): void {
		this.subscriptiongetCountries = this.factsService.getCountries().subscribe((response) => {
			this.countries = response.result.countries;
		});
	}

	getStatesByCountries(country): void {
		this.company.country_id = country.id;
		this.company.country = country.name;
		this.statesLoader = true;
		this.subscriptiongetStatesByCountries = this.factsService.getStatesByCountries(country.id).subscribe((response) => {
			this.statesLoader = false;
			this.states = response.result.states;
		});
	}
	getIndustryBySectors(sectorId): void {
		this.industriesLoader = this.industriesShow = true;
		this.subscriptiongetIndustriesBySector = this.factsService.getIndustriesBySector(sectorId).subscribe((response) => {
			this.industriesLoader = this.industriesShow = false;
			this.industries = response.result.dl_industries;
		});
	}
	addCompany(): void {
		this.addCompanyLoader = true;
		this.subscriptionaddCompany = this.companyService.addCompany(this.company).subscribe((response) => {

			this.addCompanyLoader = false;
			if (response.result && response.result.id && !this.modalCreate) {
				this.router.navigate(['company/' + response.result.id]);
			}
			else if (response.result && response.result.id && this.modalCreate) {
				this.companyCreated.emit(response.result);
			}
			else if (response.result && 'success' in response.result &&response.result.success === false) {
				this.notificationService.error(response.result.message, false);
			}

		});
	}

	backClicked(): void {
		this.location.back();
	}

	cancel(): void {
		this.cancelCreation.emit();
	}

	searchWithScraper(): void {
		const payload = {
			companyName: this.company.name
		};

		this.subscriptionsearchWithScraper = this.companyService.searchWithScraper(payload).subscribe();
	}
}
