import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'ae_scratchpads',
    display_name: 'Factors Scratchpads Add/Edit ',
    description: 'Adding/Editing Scratchpads for Factors'
  };

  public trans: any = {
    edit_scratchpad: 'Edit Scratchpad',
    add_scratchpad: 'Add Scratchpad',
    note: 'Note: Scratchpad values are applied to the factor, wherever the factor is used.',
    select: 'Select',
    operator: 'Operator',
    formula: 'Formula',
    ch_delete_row: 'Delete Row',
    are_you_sure: 'Are you sure?',
    ch_save_scratchpad: 'Save Scratchpad',
    ch_add_row: 'Add Row',
    ch_delete_scratchpad: 'Delete Scratchpad',
    ch_calculate_scratchpad: 'Calculate the result of the formula',
    goal_name: 'Goal Name',
    back: 'Back',
    save: 'Save',
    scratchpad_added: 'Scratchpad added successfully',
    invalid_scratchpad_error: 'An Operator, Driver Factor and a Value are required.',
    goalName: 'Goal Name',
    addFactor: 'Add Factor',
  };
}
