import { Model } from '@data/services/account/models/model.interface';
import { DiscoveryAccountSolution } from './discovery-account-solution.model';

export interface DiscoveryQuestionRequest {
  name: string;
  description: string;
  required: string | number;
  tag_type_id: string;
  question_tag: string;
  question_type_id: string;
  placeholder_text: string;
  default_active: number;
  account_solutions: Model[];
  account_discovery_question_id?: string;
  sections: Section[];
}

export interface DiscoveryQuestion {
  id: string;
  account_id: string;
  name: string;
  description: string;
  question_type_id: string;
  tag_type_id: string;
  required: string;
  created: Date;
  modified: Date;
  placeholder_text: string;
  question_tag: string;
  sections: Section[];
  default_active?: string;
  account_solutions?: DiscoveryAccountSolution[];
  sequence?: string;
}

export interface Section {
  id: string;
  name: string;
  name_en_GB: string;
  name_es: string;
  name_fr: string;
  name_zh_CN: string;
  name_pt_PT: string;
  name_de: string;
  name_it: string;
  name_nl: string;
  name_th: string;
  name_ms: string;
  name_id: string;
  name_ko: string;
  name_ja: string;
  description: string;
  description_en_GB: string;
  description_es: string;
  description_fr: string;
  description_zh_CN: string;
  description_pt_PT: string;
  description_de: string;
  description_it: string;
  description_nl: string;
  description_th: string;
  description_ms: string;
  description_id: string;
  description_ko: string;
  description_ja: string;
  created: Date;
  modified: Date;
  account_discovery_question_id: string;
  section_type_id: string;
}

export interface DiscoveryQuestionTag {
  created: Date;
  id: string;
  modified: Date;
  tag: string;
}

export interface DiscoveryQuestionNotePayload {
  account_discovery_question_id?: string;
  note: string;
}

export interface DiscoveryQuestionNoteResponse {
  id: string;
  value_prop_id: string;
  account_discovery_question_id: string;
  user_id: string;
  note: string;
  date: string;
  created: string;
  modified: string;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
}
