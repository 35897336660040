import { Injectable } from '@angular/core';

@Injectable()
export class TrackerBaselineViewTranslation {
  public config: any = {
    component: 'trackerBaselineView',
    display_name: 'Tracker Baseline View',
    description: 'Tabs with baseline tabs for trackers',
  };

  public trans: any = {
    dashboard: 'Dashboard',
    valueRealized: 'Value Realized',
    addNew: 'Add New',
    assignedBy: 'Assigned by',
    createTrackerBaseline: 'Create Tracker Baseline',
    selectVP: 'Select value Proposition to Create new Value Tracker',
    reviewBaseline: 'Review Baseline',
    renameTracker: 'Rename Tracker',
    nameUpdateSuccess: 'Name updated successfully',
    tooltipActions: 'Actions which can be taken from Value Realized include:',
    tooltipActions1: 'Select account for Creating / Viewing a Tracker',
    tooltipActions2: 'Select Value Proposition to use as a basis for your Tracker',
    tooltipActions3: 'Configure / View Tracker Baselines',
    tooltipActions4: 'View Trackers for an account',
    tooltipActions5: 'Create / View Measurement Data for a Tracker',
    tooltipActions6: 'Review Expected vs Achieved for a Tracker',
    needHelp: 'Need Help?',
    doItLater: "I'll Do it later",
    continuteToTracker: 'Continue to Track Value',
    valueTrackerSuccess: 'Value Tracker defined Successfully',
    startTracking: 'Ready to start tracking value?',
  };
}
