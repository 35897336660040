<div class="row" *ngIf="style2022$ | async">
	<div class="col-md-4 mr-auto breadcrumb-container">
	  <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
	</div>
  </div>

  <div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10 old-breadcrumb">
	  <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToProf.value }}</a>
	</div>
  </div>

<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>
						{{trans.trans.manage_competitors.value}}<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.manage_competitors'
						[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.manage_competitors"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-2'>
					<button class="btn btn_customizable pull-right" (click)="createUpdateCompetitors('add')"><i class="fa fa-plus"></i>{{trans.trans.add.value}}</button>
				</div>
			</div>
		</div>
		<div class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tableCompetitors.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableCompetitors.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
			<div class='col-xs-8'>
				<span class="clickable pull-right" (click)='row.toggle($event)'>{{trans.trans.rowLimit.value}} {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>

				<p-table #tableCompetitors [value]="competitorsListdata" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th>{{trans.trans.name_label.value}}<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.name_label'
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
							</th>
							<th>{{trans.trans.description.value}}<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.actions'
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.actions"></app-editTranslation>
							</th>
							<!-- Header for Actions -->
							<th></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-competitors>
						<tr>
							<td class="clickable" (click)="createUpdateCompetitors('update', competitors.name, competitors.description, competitors.company_id, competitors.id, competitors.Test_Status)">{{competitors.name}}</td>
							<td>{{competitors.description}}</td>
							<td>
								<span class='keywest_icon_group pull-right'>
									<span><i (click)="createUpdateCompetitors('update', competitors.name, competitors.description, competitors.company_id, competitors.id, competitors.Test_Status)" [matTooltip]="trans.trans.edit.values" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
									<span>
										<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteCompetitor(competitors.id)'></app-are-you-sure>
									</span>
								</span>
							</td>
						</tr>
					</ng-template>
				</p-table>

			</div>
		</div>
	</div>
</div>
<!-- Add Edit Sidebar -->
<p-sidebar [(visible)]='showCompetitorsList' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<form name="competitorForm" #competitorForm="ngForm" (ngSubmit)="ngOnSubmit(competitorForm)">
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				{{sidebarTitle.value}}
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class="row">
						<div class="col-xs-12">
							<label for="competitor" class="sidebar_label">{{trans.trans.competitor.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.competitor '
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.competitor"></app-editTranslation>
							</label>
							<input type="text" class="form-control" name="competitor" id="competitor" placeholder="{{trans.trans.placeholderCompanyName.value}}" required [(ngModel)]="competitor">
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label for="competitor" class="sidebar_label">{{trans.trans.company_optional.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.company_optional"></app-editTranslation>
							</label>
							<p-dropdown appendTo="body"
								[panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
								class="keywest_dropdown dropdown-theme"
								[options]="searchTypeList"
								[(ngModel)]="searchType"
								name="seachType"
								[optionLabel]="'name'"
							></p-dropdown>
							<input type="text" class="form-control col-sm-8" name="companyname" id="companyname" [placeholder]="trans.trans.placeholderSearchCompany.value" [(ngModel)]="companyname">
							<input type="hidden" class="form-control col-sm-8" name="companyid" id="companyid" [(ngModel)]="companyid">
							<button type="button" class="btn btn_customizable col-sm-4" (click)="searchCompanyList(companyname)">{{trans.trans.search.value}}</button>
						</div>
					</div>
					<div class="row" *ngIf="searchTable">
						<div class="col-xs-12">

							<h3>{{trans.trans.select_company.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.select_company"></app-editTranslation>
							</h3>

                            <ng-container *ngIf="searchResults">

                                <p-table #tableCompetitors [value]="searchResults" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
                                    <ng-template pTemplate="header" let-columns>

                                        <tr>
                                            <th style="padding: 0.95rem 1.5rem">{{trans.trans.name_label.value}}
                                                <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
                                                    [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
                                            </th>
                                            <th style="padding: 0.95rem 1.5rem">{{trans.trans.revenue.value}}
                                                <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
                                                    [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.revenue"></app-editTranslation>
                                            </th>
                                            <th style="padding: 0.95rem 1.5rem">{{trans.trans.employees.value}}
                                                <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
                                                    [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.employees"></app-editTranslation>
                                            </th>
                                            <th style="padding: 0.95rem 1.5rem">{{trans.trans.ticker.value}}
                                                <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
                                                    [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.ticker"></app-editTranslation>
                                            </th>
                                            <th style="padding: 0.95rem 1.5rem">{{trans.trans.city.value}}
                                                <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
                                                    [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.ciy"></app-editTranslation>
                                            </th>
                                            <th style="padding: 0.95rem 1.5rem">{{trans.trans.country.value}}
                                                <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
                                                    [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.country"></app-editTranslation>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-company>
                                        <tr (click)="selectCompany(company)" [class.highlighted]="company.name === currentCompany">
                                            <td>{{company.name}}</td>
                                            <td>{{company.revenue_fmt}}</td>
                                            <td>{{company.employees}}</td>
                                            <td>{{company.ticker}}</td>
                                            <td>{{company.city}}</td>
                                            <td>{{company.country}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </ng-container>

						</div>
					</div>
					<div class="row">
						<div class="col-xs-12">
							<label for="competitor" class="sidebar_label">{{trans.trans.description.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.description"></app-editTranslation>
							</label>
							<textarea name="companydescription" class="form-control" id="companydescription" [placeholder]="trans.trans.company_description.value" required [(ngModel)]="companydescription"></textarea>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 flex">
							<label for="isTestItem" class="sidebar_label">{{trans.trans.is_this_a_test_item.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.company_optional '
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.is_this_a_test_item"></app-editTranslation>
							</label>
							<input type="checkbox" *ngIf="!(style2022$ | async)" name="isTestItem" id="isTestItem" [(ngModel)]="isTestItem">
							<p-checkbox [binary]="true"class="marg-h" *ngIf="style2022$ | async" name="ssoOnly" [(ngModel)]="isTestItem" [value]="isTestItem"></p-checkbox>
						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<div class='row'>
					<div class="col-xs-12">
						<button type="submit" class="btn btn_customizable pull-right" [disabled]="competitorForm.invalid">{{trans.trans.save.value}}</button>
						<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.areYouSure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='cancelEdit()'></app-are-you-sure>
					</div>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>