import { ValueProp } from '@shared/models/value-prop.model';
import { Company } from '@shared/models/company.model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from '../../combined.translation';

@Component({
  selector: 'app-rsv2-sc-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class RSV2SCAccountComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;

  company: Company;
  countryDetails: { id: string; name: string };
  featureHideRevenueEmployees = false;
  loading = false;
  ngUnsubscribe = new Subject();
  showAccountEdit = false;
  showEdit: boolean;
  showTranslate = false;
  simpleAccountNameFeature: boolean;
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: DefaultTranslations,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.simpleAccountNameFeature = this.commonService.checkFeature(101);

    this.getValuePropCompany(parseInt(this.valueProp.id, 10));

    this.featureHideRevenueEmployees = this.commonService.checkFeature(100);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getValuePropCompany(id: number): void {
    this.loading = true;
    this.valuepropService.getValuePropCompany(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.loading = false;
          this.company = response.result.company;
        }
      });
  }

  funcUpdateCompany(company): void {
    this.loading = true;
    this.valuepropService.updateCompany(this.company.id, company)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          if (!response.result.success) {
            this.showAccountEdit = false;
            this.showEdit = false;
            this.notificationService.error(response.result.message, false);
          } else {
            this.company = { ...company };
            this.showAccountEdit = false;
            this.notificationService.success('Company info updated successfully', false);
          }
        }
        this.loading = false;
      });
  }

  closeAccount(): void {
    this.showAccountEdit = false;
  }
}
