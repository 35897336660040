import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subscription, Subject } from 'rxjs';
import { AlertService } from '../../_services/alert.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueRealizedDetailTranslations } from './value-realization-detail.translation';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-value-realization-detail',
	templateUrl: './value-realization-detail.component.html',
	styleUrls: ['./value-realization-detail.component.scss'],
	providers: [ValuepropService]
})
export class ValueRealizationDetailComponent implements OnInit, OnDestroy {
	@ViewChild('t') t;
	locationTab: any;

	public location = '';
	valueRealizationID: string;
	surveyDetails: any;
	loadTabs: boolean = false;
	caseStudy: any = {};
	valueRealization: any = {};
	caseStudyId: number;
	isAccountAdmin: boolean = false;
	showSurveyQuestionsList: boolean = true;
	activeSurveyTemplate: any;
	createSurveyTemplateLoader: boolean = false;
	createUserSurveyLoader: boolean = false;
	fullImagePath: string;
	image_url: string;
	contextualHelp: any = {};
	loaded: boolean = false;

	subscriptioncontextualHelp: Subscription;
	subscriptionActiveRouteParams: Subscription;
	subscriptiongetValuePropDetail: Subscription;
	subscriptionreadActiveSurveyTemplate: Subscription;
	subscriptioncreateSurveys: Subscription;
	subscriptioncreateSurveyTemplate: Subscription;
	subscriptiongetCaseStudyDetail: Subscription;
	subscriptionSubscribeLoggedIn: Subscription;
	toggleAddCaseStudy: boolean = false;
	companyID: any;
	valuepropId: any;
	solutions: any;

	menuItems = [];
	menuView = 'survey';

	ngUnsubscribe = new Subject();
	showTranslate = false;

	customSurveyDate;
	surveyDatePicker = false

	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		private valuerealizationService: ValuerealizationService,
		private valuePropService: ValuepropService,
		private notification: NotificationService,
		private commonService: CommonService,
		private alertService: AlertService,
		private translationService: TranslationsV2Service,
		public trans: ValueRealizedDetailTranslations
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

	}

	ngOnInit() {
		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.activeRoute.params.subscribe(params => this.valueRealizationID = params['id']);
		this.activeRoute.params.subscribe(params => this.locationTab = params['type']);
		this.activeRoute.params.subscribe(params => this.caseStudyId = params['typeId']);
		this.getValuePropDetails();

		let privilegeOption = sessionStorage.getItem('privileges');
		let options = privilegeOption.split(',');
		if (options.indexOf('1') >= 0) {
			this.isAccountAdmin = true;
		}

		this.getActiveSurveyTemplate(null);

		this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe(response => {
			this.contextualHelp = response['Valuerealization'];
		});

		this.subscriptionSubscribeLoggedIn = this.alertService.subscribeLoggedIn().subscribe(() => {
			this.getValuePropDetails();
		})
	}

	ngOnDestroy() {
		if (this.subscriptioncontextualHelp) { this.subscriptioncontextualHelp.unsubscribe(); }
		if (this.subscriptionActiveRouteParams) { this.subscriptionActiveRouteParams.unsubscribe(); }
		if (this.subscriptiongetValuePropDetail) { this.subscriptiongetValuePropDetail.unsubscribe(); }
		if (this.subscriptionreadActiveSurveyTemplate) { this.subscriptionreadActiveSurveyTemplate.unsubscribe(); }
		if (this.subscriptioncreateSurveys) { this.subscriptioncreateSurveys.unsubscribe(); }
		if (this.subscriptioncreateSurveyTemplate) { this.subscriptioncreateSurveyTemplate.unsubscribe(); }
		if (this.subscriptiongetCaseStudyDetail) { this.subscriptiongetCaseStudyDetail.unsubscribe(); }
		if (this.subscriptionSubscribeLoggedIn) { this.subscriptionSubscribeLoggedIn.unsubscribe(); }

		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	closeAddCaseStudy(): void {
		this.toggleAddCaseStudy = false;
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

			this.funcBuildmenu();
		});
	}

	funcBuildmenu() {
		this.menuItems = [
			{
				label: this.trans.trans.surveys.value,
				tooltip: this.trans.trans.measurePerceptionTime.value,
				styleClass: this.menuView === 'survey' ? 'menuSelected' : '',
				command: () => {
					this.menuView = 'survey';
					this.funcBuildmenu();
				}
			},
			{
				label: this.trans.trans.caseStudies.value,
				tooltip: this.trans.trans.captureImprovements.value,
				styleClass: this.menuView === 'case studies' ? 'menuSelected' : '',
				command: () => {
					this.menuView = 'case studies';
					this.funcBuildmenu();
					;
				}
			}
		];
	}

	getValuePropDetails() {
		this.subscriptiongetValuePropDetail = this.valuePropService.getValuePropDetail(this.valueRealizationID).subscribe((response) => {
			if (response.result) {
				this.valueRealization = response.result;

				this.loaded = false;

				this.loaded = true;
			}
		});
	}

	getActiveSurveyTemplate(surveyCallBack) {
		let accountId = sessionStorage.getItem('aid');
		this.subscriptionreadActiveSurveyTemplate = this.valuerealizationService.readActiveSurveyTemplate(accountId).subscribe((response) => {
			if (response.result) {
				this.activeSurveyTemplate = response.result[0];

				if (surveyCallBack) {
					this.surveyCallBack();
				}
			}
		});
	}

	createSurvey() {
		if (!this.activeSurveyTemplate) {
			this.notification.info("No Survey Template Available", false);
		}
		else {
			this.getActiveSurveyTemplate(true);
		}
	}

	pickDateForNewSurvey() {
		this.surveyDatePicker = true;
	}

	surveyCallBack() {
		this.createUserSurveyLoader = true;
		let params = {
			'value_prop_id': this.valueRealizationID,
			'account_question_template_id': this.activeSurveyTemplate['id']
		}
		this.subscriptioncreateSurveys = this.valuerealizationService.createSurveys(params).subscribe((response) => {
			if (response.result && response.result['value_survey']) {
				this.valuerealizationService.readSurveyList.next(response.result['value_survey']);
				this.createUserSurveyLoader = false;
				this.surveyDatePicker = false;
			}
		});
	}

	createSurveyTemplate() {
		this.createSurveyTemplateLoader = true;
		let accountId = sessionStorage.getItem('aid');
		let payload = {
			name: 'Account Survey Question Template ' + this.formatDateYYYYMMDD_HHMM()
		};
		this.subscriptioncreateSurveyTemplate = this.valuerealizationService.createSurveyTemplate(accountId, payload).subscribe((response) => {
			if (response.result) {
				this.createSurveyTemplateLoader = false;
				this.valuerealizationService.readSurveyTemplateList.next(response.result);
			}
		});
	}

	public formatDateYYYYMMDD_HHMM() {
		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth() + 1;
		let yyyy = today.getFullYear();
		return yyyy + '-' + mm + '-' + dd + ' ' + today.getHours() + ':' + today.getMinutes();
	}

	openAddCaseStudy() {
		this.toggleAddCaseStudy = true;
		this.companyID = this.valueRealization['value_prop']['company_id'];
		this.valuepropId = this.valueRealization['value_prop']['id'];
		this.solutions = this.valueRealization['value_prop']['solutions'];
	}

	getCaseStudyDetail(caseStudyId) {
		let searchLimit = "/" + caseStudyId;
		this.subscriptiongetCaseStudyDetail = this.valuerealizationService.getCaseStudyDetail(searchLimit).subscribe((response) => {
			if (response.result) {
				this.caseStudy = response.result.case_study;
			}
		});
	}

	casestudiesList() {
		this.menuView = 'case studies';
		this.router.navigate(['valuerealization', this.valueRealizationID]);
	}
}
