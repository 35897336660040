<div class="row">
  <div class="col-xs-12">
    <h2>{{ trans.trans.createModel.value }}</h2>
  </div>
</div>
<form name="CreateSolutionForm" #CreateSolutionForm="ngForm" (ngSubmit)="saveSolution()">
  <p-fieldset id="required" [legend]="'vpdashboard.benefits.required' | translate">
    <div class="row">
      <div class="col-xs-12" [ngClass]="{ 'has-error': CreateSolutionForm.controls.name?.invalid && CreateSolutionForm.controls.name?.touched }">
        <label for="name" class="labelsRequired">{{ trans.trans.modelName.value }}</label>
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="solution.name" [placeholder]="trans.trans.enterName.value" required />
      </div>
      <div class="col-xs-12" [ngClass]="{ 'has-error': CreateSolutionForm.controls.selectedSolutionItems?.invalid && CreateSolutionForm.controls.selectedSolutionItems?.touched }">
        <label class="labelsRequired">{{ trans.trans.modelCategory.value }}</label>
        <p-multiSelect [options]="solutionCategories" [(ngModel)]="selectedItems" name="selectedSolutionItems"></p-multiSelect>
      </div>

      <div class="col-xs-12" [ngClass]="{ 'has-error': CreateSolutionForm.controls.avgdeploymenttime?.invalid && CreateSolutionForm.controls.avgdeploymenttime?.touched }">
        <label class="labelsRequired">{{ trans.trans.averageDeployment.value }}</label>
        <input
          type="text"
          class="form-control"
          id="avgdeploymenttime"
          [(ngModel)]="solution.avg_deployment_time"
          name="avgdeploymenttime"
          [placeholder]="trans.trans.enterMonths.value + ' (ie-12)'"
          required
        />
      </div>
      <div class="col-xs-12" [ngClass]="{ 'has-error': CreateSolutionForm.controls.avg_sale_price?.invalid && CreateSolutionForm.controls.avg_sale_price?.touched }">
        <label for="avg_sale_price" class="labelsRequired">{{ trans.trans.averageSalesPrice.value }}</label>
        <input type="text" class="form-control" id="avg_sale_price" name="avg_sale_price" [(ngModel)]="solution.avg_sale_price" [placeholder]="trans.trans.averageSalesPrice" required />
      </div>
      <div class="col-xs-12" [ngClass]="{ 'has-error': CreateSolutionForm.controls.avg_sale_cycle?.invalid && CreateSolutionForm.controls.avg_sale_cycle?.touched }">
        <label for="avg_sale_cycle" class="labelsRequired">{{ trans.trans.averageSalesCycle.value }}</label>
        <input type="text" class="form-control" id="avg_sale_cycle" name="avg_sale_cycle" [(ngModel)]="solution.avg_sale_cycle" [placeholder]="trans.trans.averageSalesCycle.value" required />
      </div>
    </div>
  </p-fieldset>
  <p-fieldset [toggleable]="true" [collapsed]="true" [legend]="'vpdashboard.benefits.additional' | translate">
    <div class="row">
      <div class="col-xs-12">
        <label>{{ trans.trans.description.value }}</label>
        <textarea class="form-control" id="Enter Description" [(ngModel)]="solution.description" name="description" [placeholder]="trans.trans.description.value"></textarea>
      </div>
    </div>
  </p-fieldset>
  <div class="row">
    <div class="col-xs-12">
      <button type="submit" class="btn btn-success" (click)="saveSolution()" [disabled]="CreateSolutionForm.invalid || selectedItems.length == 0">
        <span aria-hidden="true">{{ trans.trans.save.value }}</span>
      </button>
    </div>
  </div>
</form>
