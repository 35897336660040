import { Injectable } from '@angular/core';

@Injectable ()

export class CreateSurveyRealizationTranslation {

    public config: any = {
        component: 'createSurveyRealized',
        display_name: 'Create Survey',
        description: 'Create survey for value realized'
    };

    public trans: any = {
        surveys: 'Surveys',
        answer: 'Answer',
        Yes: 'Yes',
        No: 'No',
        benefit_performance: 'Benefit Performance',
        no_benefit_performance_found: 'No Benefit Performance Found',
        save: 'Save',
        are_you_sure: 'Are you sure?',
        cancel: 'Cancel'
    };
}
