import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Translations } from '@data/services/translation/translation.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-survey-template',
  templateUrl: './survey-template.component.html',
  styleUrls: ['./survey-template.component.scss'],
})
export class SurveyTemplateComponent implements OnInit, OnDestroy {
  @Input() showSurveyQuestionsList: boolean;
  @Output() getActiveSurveyTemplateList = new EventEmitter();
  surveyDetails: any;
  accountId: any;
  sliderStartValue: number = 2;

  questionTypes: Array<object> = [];
  loadSurveysTable: boolean = false;
  surveys: any = [];
  valuePropId: string;
  surveyTemplateId: string;

  subscriptionRouterParams: Subscription;
  subscriptionreadSurveyTemplateList: Subscription;
  subscriptiongetValueRealization_SurveyTemplates: Subscription;

  constructor(
    private valuerealizationService: ValuerealizationService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.accountId = sessionStorage.getItem('aid');
    this.getSurveyList();
    this.subscriptionRouterParams = this.route.params.subscribe((params) => this.changeUrl(params.id));

    this.subscriptionreadSurveyTemplateList = this.valuerealizationService.readSurveyTemplateList.subscribe((response) => {
      this.getSurveyList();
      this.openSurveyTemplateDetail(response);
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionRouterParams) {
      this.subscriptionRouterParams.unsubscribe();
    }
    if (this.subscriptionreadSurveyTemplateList) {
      this.subscriptionreadSurveyTemplateList.unsubscribe();
    }
    if (this.subscriptiongetValueRealization_SurveyTemplates) {
      this.subscriptiongetValueRealization_SurveyTemplates.unsubscribe();
    }
  }

  changeUrl(id) {
    this.valuePropId = id;
    this.router.navigate(['valuerealization', id, 'Templates']);
  }

  getSurveyList() {
    this.loadSurveysTable = false;
    this.subscriptiongetValueRealization_SurveyTemplates = this.valuerealizationService.getValueRealization_SurveyTemplates(this.accountId).subscribe((response) => {
      if (response.result && response.result) {
        this.surveys = response.result;
      }
      this.loadSurveysTable = true;
    });
  }

  openSurveyTemplateDetail(id) {
    this.surveyTemplateId = id;
    this.showSurveyQuestionsList = false;
  }

  gettemplateList() {
    this.showSurveyQuestionsList = true;
    this.getSurveyList();
    this.getActiveSurveyTemplateList.emit();
  }
}
