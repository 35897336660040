import { Component, forwardRef, ChangeDetectorRef, ViewEncapsulation, Renderer2, ElementRef, Input, OnDestroy } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { MenuItem } from 'primeng/api/public_api';
@Component({
  selector: 'dl-menubarSub',
  templateUrl: './menubarsub.component.html',
  styleUrls: ['./menubarsub.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DLMenubarSubComponent),
    },
  ],
})
export class DLMenubarSubComponent implements OnDestroy {
  @Input() set item(value: MenuItem | MenuItem[]) {
    if(Array.isArray(value)) {
      this.options = value;
    } else {
      this.options = value.items;
    }
  };

  @Input() root: boolean;

  @Input() autoDisplay = true;

  @Input() autoZIndex = true;

  @Input() baseZIndex = 0;

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }
  set parentActive(value) {
    if (!this.root) {
      this._parentActive = value;

      if (!value) {
        this.activeItem = null;
      }
    }
  }

  _parentActive: boolean;

  documentClickListener: any;

  menuClick: boolean;

  menuHoverActive = false;

  activeItemByTouch: MenuItem = null;

  activeItem: any;

  activeMenu: any;

  zindex = 1000;

  options: MenuItem[];

  constructor(
      public renderer: Renderer2,
      public cd: ChangeDetectorRef,
      private translateService: TranslateService,
      public trans: Translations
      ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  onItemMouseLeave(event: Event) {
    this.activeMenu = null;
    this.autoDisplay = null;
    this.activeItem = null;
    this.menuHoverActive = null;
  }

  onItemMenuClick(event: Event, item: HTMLLIElement, menuitem: MenuItem) {
    if (!this.autoDisplay) {
      if (menuitem.disabled) {
        return;
      }

      this.activeItem = this.activeMenu ? (this.activeMenu.isEqualNode(item) ? null : item) : item;
      const nextElement = <HTMLLIElement>item.children[0].nextElementSibling;
      if (nextElement) {
        const sublist = <HTMLUListElement>nextElement.children[0];
        if (this.autoZIndex) {
          sublist.style.zIndex = String(this.baseZIndex + ++this.zindex);
        }

        if (this.root) {
          sublist.style.top = this.getOuterHeight(item.children[0]) + 'px';
          sublist.style.left = '0px';
        } else {
          sublist.style.top = '0px';
          sublist.style.left = this.getOuterWidth(item.children[0]) + 'px';
        }
      }

      this.menuClick = true;
      this.menuHoverActive = this.activeMenu ? !this.activeMenu.isEqualNode(item) : true;
      this.activeMenu = this.activeMenu ? (this.activeMenu.isEqualNode(item) ? null : item) : item;
      this.bindEventListener();
    }
  }

  getOuterHeight(el, margin?) {
    let height = el.offsetHeight;

    if (margin) {
      const style = getComputedStyle(el);
      height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    }

    return height;
  }

  getOuterWidth(el, margin?) {
    let width = el.offsetWidth;

    if (margin) {
      const style = getComputedStyle(el);
      width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    }

    return width;
  }

  bindEventListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', (event) => {
        if (!this.menuClick) {
          this.activeItem = null;
          this.menuHoverActive = false;
          this.activeMenu = false;
        }
        this.menuClick = false;
      });
    }
  }

  onItemMouseEnter(_event: Event, item: HTMLLIElement, menuitem: MenuItem) {
    this.autoDisplay = true;

    if (this.autoDisplay || (!this.autoDisplay && this.root && this.menuHoverActive)) {
      if (menuitem.disabled) {
        return;
      }

      if ((this.activeItem && !this.activeItem.isEqualNode(item)) || !this.activeItem) {
        this.activeItem = item;
        const nextElement = <HTMLLIElement>item.children[0].nextElementSibling;
        if (nextElement) {
          const sublist = <HTMLUListElement>nextElement.children[0];
          sublist.style.zIndex = String(++this.zindex);

          if (this.root) {
            sublist.style.top = this.getOuterHeight(item.children[0]) + 'px';
            sublist.style.left = '0px';
          } else {
            sublist.style.top = '0px';
            sublist.style.left = this.getOuterWidth(item.children[0]) + 'px';
          }
        }
        this.activeMenu = item;
      }
    }
  }

  itemClick(event, item: MenuItem, list: HTMLLIElement) {
    const touchControl = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    if(touchControl && list.classList.contains('ui-menu-parent')) {
      if(this.activeItemByTouch !== item) {
        this.activeItemByTouch = item;
        event.preventDefault();
        return;
      } else {
        this.activeItemByTouch = null;
      }
    } else if (touchControl) {
      this.activeItemByTouch = null;
    }
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }

    this.activeItem = null;
  }

  listClick(event) {
    const touchControl = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
    if (this.autoDisplay && (!touchControl || (touchControl && !this.activeItemByTouch))) {
      this.activeItem = null;
    }
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}
