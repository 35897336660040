<div *ngFor="let benefitCategory of benefitsList; let b = index">
	<!-- {{benefitCategory.metrics[0] | json}} -->
	<div class='panel panel-white' *ngIf="showGroup(benefitCategory.metrics);">
		<div class="panel-heading" style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
			<div class='row'>
				<div class='col-xs-12 clickable' style='padding-right: 30px;'>
					<table style='table-layout:fixed;width:100%'>
						<tr>
							<td width='20px' style='vertical-align: top;' (click)='updateBenefitsLocalStorage(benefitCategory.value_category_id);'>
								<i class='fa fa-plus clickable' *ngIf="!benefitsListExpanded[b].expand" style='margin-right: 5px;'></i>
								<i class='fa fa-minus clickable' *ngIf="benefitsListExpanded[b].expand" style='margin-right: 5px;'></i>
							</td>
							<td width='100%' (click)='updateBenefitsLocalStorage(benefitCategory.value_category_id);'>
								<span class='clickable'>{{benefitCategory.name}}</span><br>
								<small style='font-size: 70%; color: green; margin: 5px;'>{{filterActive(benefitCategory, 1)}} {{trans.vp_dashboard.benefits.active | translate }}</small>
								<small style='font-size: 70%; color: red; margin: 5px;'>{{filterActive(benefitCategory, 0)}} {{trans.vp_dashboard.benefits.na | translate }}</small>
								<small style='font-size: 70%; color: orange; margin: 5px;'>{{filterActive(benefitCategory, 3)}} {{trans.vp_dashboard.benefits.soft | translate }}</small>
							</td>
							<td width='20px'>
								<div style='float: left;'>
									<i class='fa fa-chevron-circle-left clickable' (click)='benefitCategory.start = rangeLeftArrow(benefitCategory.start)' *ngIf="benefitCategory.term > termShow && (benefitCategory.start !== 1)" style='color: green; font-weight: bold; float: left; margin-top: 15px;'></i>
								</div>
							</td>
							<td *ngFor="let year of benefitCategory.years | rangePipe: benefitCategory.start : termLimit : termShow; let i = index" width='100px'>
								<div [ngClass]="alignYear(i)">
									{{year.value}}<br>
									<small style='font-size: 70%; color: #a5a5a5;'>{{year.label}}</small>
								</div>
							</td>
							<td width='20px'>
								<div style='float: right; text-align: right;'>
									<i class='fa fa-chevron-circle-right clickable' (click)='benefitCategory.start = rangeRightArrow(benefitCategory.start)' *ngIf="benefitCategory.term > termShow && (benefitCategory.start !== benefitCategory.term - termLimit)" style='color: green; font-weight: bold; margin-top: 15px;'></i>
								</div>
							</td>
							<td style='text-align: right;' width='90px'>
								{{benefitCategory.vc_total_fmt}}<br>
								<small style='font-size: 70%; color: #a5a5a5;'>{{trans.vp_dashboard.benefits.total | translate }}</small>
							</td>
						</tr>
					</table>


				</div>
			</div>
			<!-- <div class='col-xs-2 text-right' style='float: right; width: 100px;'>

				</div> -->
		</div>
		<div class="panel-body" *ngIf="getBenefitsListExpanded(benefitCategory.value_category_id)">
			<!-- <div class="panel-body" *ngIf="benefitsListExpanded[i].expand"> -->
			<div *ngIf="isTable" class="table-responsive col-sm-12 soln-table" style='padding: 0px;'>
				<table id="benefits" class="table custom-table" style='margin-bottom: 0px;' style='table-layout: fixed; width: 100%;'>
					<thead>
						<tr>
							<th width='70px'>{{ trans.vp_dashboard.benefits.phasing | translate }}</th>
							<th width='20px' *ngIf="showBatchUpdateCheckboxes">
								<input type="checkbox" (click)='checkAll(benefitCategory)' matTooltip="Apply to all in category" matTooltipPosition="above" />
							</th>
							<th width='30%'>{{ trans.vp_dashboard.benefits.status | translate }}</th>
							<th style="width: 100%">{{ trans.vp_dashboard.benefits.benefits | translate }}</th>
							<th width="100px" style='white-space: nowrap;'>{{ trans.vp_dashboard.benefits.impact | translate }}</th>
							<th width='20px'>&nbsp;</th>
							<th width='100px' *ngFor="let year of benefitCategory.years | rangePipe: benefitCategory.start : termLimit : termShow; let i = index" style='padding-left: 0px !important; padding-right: 0px !important;' [ngClass]="alignYearTbl(i)">
								{{year.label}}
							</th>
							<th width='20px'>&nbsp;</th>
							<th class='text-right' width='90px' style='padding-left: 0px !important;'>
								{{ trans.vp_dashboard.benefits.total | translate }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let benefit of benefitCategory.metrics | filter : menuFilter : 'metric_name' | filterStatus: showActive : showNA : showSoft; let i = index " class="grid-row">
							<!-- {{benefit.active}} -->
							<ng-container>
								<td>
									<div class="pull-left">
										<i class="glyphicon glyphicon-equalizer" (click)="openAddPhasing(benefit.id)" matTooltip="{{trans.contextual_help.vp_dashboard.position24 | translate}}" container="body" placement="top" role="button"></i>
									</div>
								</td>
								<td *ngIf="showBatchUpdateCheckboxes">
										<p-checkbox name="checkbox{{b}}{{i}}" [binary]='true' [(ngModel)]="benefit.updateBatch"></p-checkbox>
									<!-- <label>
										<input type="checkbox" [(ngModel)]="benefit.updateBatch" />
										<span class="check"></span>
									</label> -->
								</td>
								<td class="benefit-status benefitStatusElement" *ngIf="(!inputOpened || !benefit.changeActive)" (click)="openActiveTypes(benefit)" matTooltip="{{trans.contextual_help.vp_dashboard.benefit_status | translate}}" matTooltipPosition="above">
									<a href="javascript:;" class="dashedLink benefitStatusElement" data-toggle="tooltip" title="Change Benefits Status">
										<span class="activeStatusType benefitStatusElement" [ngStyle]="{'background-color':getBenefits(benefit.active_type)}"></span>
									</a>
								</td>
								<td *ngIf="inputOpened && benefit.changeActive" class="benefitStatusElement">
									<form class="form-inline benefitStatusElement">
										<div class="editable-controls form-group benefitStatusElement">
											<select class="form-control active-type benefitStatusElement" name="benefit_active_{{benefit.id}}" [(ngModel)]="benefit.active" (change)="statusChange(benefit)">
												<option value="" class="benefitStatusElement" disabled>{{ trans.vp_dashboard.benefits.choose | translate }}</option>
												<option *ngFor="let activeType of activeTypes" [value]="activeType.value" class="benefitStatusElement">
													{{activeType.name}}</option>
											</select>
										</div>
									</form>
								</td>
								<td class="benefit-status" [matTooltip]="benefit.description" matTooltipPosition="above">
									<span role="button" (click)="openBenefitDetail(benefit)" class='link'>
										{{benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name}}
										<i class="fa fa-bookmark" style='margin-left: 10px;' *ngIf="benefit.vp_specific == 1" aria-hidden="true" matTooltip="This Benefit was added to this ValueProp manually." matTooltipPosition="above"></i>
									</span>

									<a *ngIf="valueProp.vp_can_edit && benefit.vp_specific == 1 && benefitOnTheFlyPrivilege" (click)="openUpdateBenefit(benefit)" matTooltip="Update" matTooltipPosition="above">
										<span style='color: green !important; margin-left: 10px;' class="fa fa-pencil"></span>
									</a>
									<a *ngIf="valueProp.vp_can_edit && benefit.vp_specific == 1 && benefitOnTheFlyPrivilege" (click)="opendeleteCustomBenefit(benefit)" matTooltip="Delete" matTooltipPosition="above">
										<span style='color: red !important; margin-left: 10px;' class="fa fa-times"></span>
									</a>
								</td>
								<td>{{benefit.impact_type}}</td>
								<td></td>
								<td *ngFor="let year of benefit.years | rangePipe: benefitCategory.start : termLimit : termShow; let i = index" style='padding-left: 0px !important; padding-right: 0px !important;' [ngClass]="alignYearTbl(i)">{{year.value}}</td>
								<td></td>
								<td class="preventWordBreak text-right" style='padding-left: 0px !important;'>{{benefit.total_benefits_fmt}}</td>
							</ng-container>
						</tr>
					</tbody>
				</table>

			</div>
			<div *ngIf="benefitsLoader" class="col-sm-12 m-t-md text-center">
				<p class="text-center">
					<img class="loader" src="{{fullImagePath}}" />
				</p>
			</div>
			<div *ngIf="(benefitsList == null || benefitsList.length==0) && !benefitsLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4>{{ 'props.tabDetails.benefits.NoBenefitsFound' | translate }}</h4>
				</div>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]="showDeleteCustomBenefit" position='left' styleClass="p-sidebar-md">
	<h2>{{ trans.vp_dashboard.benefits.no_benefits_found | translate }}</h2>

	<p>{{ trans.vp_dashboard.benefits.delete | translate }}</p>

	<button type="button" class="btn btn-primary" (click)="deleteCustomBenefit()">{{ trans.button.Yes | translate }}</button>
	<button type="button" class="btn btn-secondary" (click)="showDeleteCustomBenefit = !showDeleteCustomBenefit">{{ trans.button.No | translate }}</button>
</p-sidebar>