import { Injectable } from '@angular/core';

@Injectable ()

export class PrivilegesChangeTranslation {

    public config: any = {
        component: 'privilegesChangesComponent',
        display_name: 'Privileges Change Request',
        description: 'Request privilege change in profile section'
    };

    public trans: any = {
        save: 'Save',
        requestChange: 'request change',
        changePrivilege: 'Change Privilege',
        selectPrivilege: 'Select Privilege'
    };
}
