import { NgModule } from '@angular/core';

import { CaseStudyTagsComponent } from "./tags.component";

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChipsModule } from 'primeng/chips';
import { TagsTranslations } from './tags.translation';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		CaseStudyTagsComponent,
	],
	imports: [
		ChipsModule,
		NgbModule,
		FormsModule,
		CommonModule,
    MatTooltipModule,
	],
	providers: [
		TagsTranslations
	],
	entryComponents: [
		CaseStudyTagsComponent,
	],
	exports: [CaseStudyTagsComponent]
})
export class CaseStudyTagsModule { }
