<p-table styleClass='keywest_table' tableStyleClass='keywest_table' [value]="usedBenefitFactors" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th>{{trans.trans.model.value}}</th>
            <th>{{trans.trans.benefit.value}}</th>
            <th>{{trans.trans.baslineValue.value}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-benefitFactor>
        <tr>
            <td>{{benefitFactor.model_name}}</td>
            <td>{{benefitFactor.benefit_name}}</td>
            <td>{{benefitFactor.baseline_value}}</td>
        </tr>
    </ng-template>
</p-table>
<app-no-records class="no-records" *ngIf="!usedBenefitFactors || !usedBenefitFactors.length">{{trans.trans.noRecords.value}}</app-no-records>