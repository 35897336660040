import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchCompanyV3Component } from './search-companyv3.component';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AddVPCompanyNoTransModule } from '../add-value-positionv2/dynamic_components/add-company_no_trans/vp-add-company.module';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SearchCompanyTranslations } from './search-companyv3.translation';
import { InputMaskModule } from 'primeng/inputmask';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { AddCompanyComponent } from './add-company/add-company.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AddNewCompanyTranslations } from './add-company/add-company.translation';
import { InputTextModule } from 'primeng/inputtext';
import { DLDragDropModule } from '@shared_components/dd_file_upload/upload.module';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { CompanyTableComponent } from './company-table/company-table.component';
import { OpportunityFormComponent } from './opportunity-form/opportunity-form.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [
    InputMaskModule,
    TableModule,
    SidebarModule,
    AddVPCompanyNoTransModule,
    MatTooltipModule,
    CommonModule,
    NgbModule,
    FormsModule,
    AddCompanyModule,
    ReactiveFormsModule,
    EditTranslationsModule,
    BreadcrumbsModule,
    AutoCompleteModule,
    SelectButtonModule,
    InputTextModule,
    DropdownModule,
    DLDragDropModule,
    NoRecordsModule,
    OverlayPanelModule,
    EllipsisMenuModule,
    AreYouSureModule,
    ButtonModule,
    FunctionPipeModule,
  ],
  declarations: [SearchCompanyV3Component, CompanyTableComponent, OpportunityFormComponent, AddCompanyComponent],
  providers: [TranslationsV2Service, SearchCompanyTranslations, AddNewCompanyTranslations],
  exports: [SearchCompanyV3Component],
})
export class SearchCompanyV3Module {}
