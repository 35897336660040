import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CountryFlagService } from '@data/services/country-flag/country-flag.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CountryFlagPipe } from '@shared/pipes/country-flag.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { SelectLanguageComponent } from './select-language.component';
import { SelectLanguageTranslations } from './select-language.translation';

@NgModule({
  declarations: [
    SelectLanguageComponent,
    CountryFlagPipe
  ],
  exports: [
    SelectLanguageComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ],
  providers: [
    SelectLanguageTranslations,
    TranslationsV2Service,
    CountryFlagService
  ]
})
export class SelectLanguageModule { }

