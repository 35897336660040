import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { BusinessFactor } from '@data/services/goal-groups/models/business-goals-response.interface';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';
import { ValueTrackerDetails } from '@data/services/valuerealization/models/value-tracker.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Company } from '@shared/models/company.model';
import { ValueProp } from '@shared/models/value-prop.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { ShortNumberPipe } from 'app/pipe/short-number.pipe';
import { smallLineChartConf } from 'app/value-realized/chartConfigs/small-linechart';
import { ChartData, ChartOptions } from 'chart.js';
import { Subject, Observable } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { TrackValueDashboardTranslation } from './track-value-dashboard.translation';
import { Router } from '@angular/router';
import { ChooseAdventureInternalComponent } from '@shared/choose-adventure-internal/choose-adventure-internal.component';
import { UserService } from '@data/services/user/user.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { ValuePropGoal } from '@shared/models/goal-group.model';
@Component({
  selector: 'app-track-value-dashboard',
  templateUrl: './track-value-dashboard.component.html',
  styleUrls: ['./track-value-dashboard.component.scss'],
})
export class TrackValueDashboardComponent implements OnInit, OnDestroy {
  public workflows: ConversationWorkflow[] = [];
  ngUnsubscribe = new Subject();
  showTranslate = false;
  breadcrumbKeyValues: Breadcrumbs;
  barType: string = 'Benefits';
  barTypes: string[] = ['Benefits', 'Goals'];
  changeVPName = false;
  valueProp: ValueProp;
  valuePropId: string;
  companyDetails: Company;
  companyId: string;
  trackerId: string;
  trackerData: ValueTrackerDetails;
  achievedPercentage: number;
  optionsTrend: ChartOptions;
  chartTrend: ChartData;
  chartTrendLarge: ChartData;
  chartBarLarge: ChartData;
  optionsTrendLarge: ChartOptions;
  // Must be any because of chart.js types, which will not allow the use of a custom unit type
  optionsBarLarge: any;
  chartColors: string[] = [];
  numberAbbreviations = new Map();
  quarterAbbr: string;
  isLoading = true;
  expectedLabel: string;
  achievedLabel: string;
  businessReviewData: BusinessReviewData[];
  businessReview: BusinessReviewData;
  screenWidth: number;
  goals: ValuePropGoal[];
  @ViewChild('tabElement') tabComponent: ElementRef;

  unitTypes = [
    '',
    'currency',
    'currencyThousands',
    'currencyMillions',
    'currencyBillions',
    'thousands',
    'millions',
    'billions',
    'seconds',
    'minutes',
    'hours',
    'days',
    'months',
    'years',
    'percent',
    'basisPoints',
    'miles',
    'number',
    'weeks',
  ];

  public ff134 = this.commonService.checkFeature('134');
  private priv15 = this.commonService.checkPrivilege('15');
  private priv16 = this.commonService.checkPrivilege('16');
  showStepSelection = false;
  @ViewChild('chooseAdventure') chooseAdventureDialog: ChooseAdventureInternalComponent;

  constructor(
    private commonService: CommonService,
    private valuerealizationService: ValuerealizationService,
    public trans: TrackValueDashboardTranslation,
    private translationService: TranslationsV2Service,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private valuePropService: ValuepropService,
    private route: ActivatedRoute,
    private shortNumber: ShortNumberPipe,
    public businessReviewService: TrackerBusinessReviewService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (sessionStorage.getItem('workflows') && sessionStorage.getItem('workflows') !== 'undefined') {
      this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
    }
    this.chartColors = this.commonService.getChartColors();
    this.screenWidth = window.innerWidth;
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.valuePropId = this.route.snapshot.paramMap.get('trackerId');
    this.getTranslations();
    this.businessReviewService.valuePropId = this.valuePropId;
    this.getValuePropDetails();
    this.getBusinessReview();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.setGoalsObserver();
    this.businessReviewService.brsWithNotes$.next(null);

    this.businessReviewService.businessReview
      .pipe(
        filter((businessReview) => !!businessReview),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((review) => {
        if (!review) {
          return;
        }
        this.businessReview = review;
      });

    this.setNewBusinessReviewObserver();

    this.businessReviewService.businessReviewRefresh.pipe(takeUntil(this.ngUnsubscribe)).subscribe((refresh) => {
      if (refresh) {
        this.getData();
      }
    });

    this.valuerealizationService.refreshDashboardData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((refresh) => {
      if (refresh) {
        this.getData();
      }
    });

    this.userService.replaceFF134$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((replaceFF134) => {
      if (replaceFF134) {
        let features = sessionStorage.getItem('features').split(',');
        if (!features.includes('134')) {
          features.push('134');
          sessionStorage.setItem('features', features.join(','));
          this.ff134 = this.commonService.checkFeature('134');
        } else {
          const newFeatures = features.filter((f) => f !== '134');
          if (newFeatures.length) {
            const featuresString = newFeatures.join(',');
            sessionStorage.setItem('features', featuresString);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
    this.businessReviewService.clearBusinessReviews();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.buildChartTrend();
  }

  getData() {
    this.valuerealizationService
      .getValueRealizationDetailDashboard(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.trackerData = response.result;
        this.businessReviewService.setCanEdit(this.trackerData.vp_can_edit);
        this.buildChartTrend();
        this.achievedPercentage = this.trackerData.benefits.variance_to_date_percent;
      });
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.numberAbbreviations.set('Q', this.trans.trans.quintillions.value);
        this.numberAbbreviations.set('T', this.trans.trans.trillions.value);
        this.numberAbbreviations.set('B', this.trans.trans.billions.value);
        this.numberAbbreviations.set('M', this.trans.trans.millions.value);
        this.numberAbbreviations.set('K', this.trans.trans.thousands.value);
        this.quarterAbbr = this.trans.trans.quarter.value;
        this.expectedLabel = this.trans.trans.expected.value;
        this.achievedLabel = this.trans.trans.achieved.value;
        this.getData();
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.valueRealized.value]: { routerLink: '/valuerealized', active: false },
          [this.trans.trans.addNew.value]: { routerLink: null, active: false },
        };
        this.getCompanyDetail(this.companyId);
        this.barType = this.trans.trans.benefits.value;
        this.barTypes = [this.trans.trans.benefits.value, this.trans.trans.goals.value];
      });
  }

  getCompanyDetail(id: string): void {
    this.companyService
      .getCompanySummary(+id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => {
          if (result.result) {
            this.companyDetails = result.result.company;
            this.breadcrumbKeyValues = {
              ...this.breadcrumbKeyValues,
              [this.companyDetails.name]: { routerLink: null, active: true },
            };
          }
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );
  }

  getValuePropDetails() {
    this.valuePropService
      .getValuePropDetail(this.valuePropId.toString())
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.valueProp = response.result.value_prop;
          this.valuePropService.valueProp.next(response.result.value_prop);
        }
      });
  }

  cancelChangeName(): void {
    this.changeVPName = false;
  }

  hideVPName(event: boolean): void {
    this.changeVPName = event;
  }

  submitChangeName(newName: string): void {
    this.valuePropService.updateValueProp(this.valueProp.id, { name: newName }).subscribe(() => {
      this.valueProp = { ...this.valueProp, name: newName };
      this.changeVPName = false;
      this.notificationService.success(this.trans.trans.nameUpdateSuccess.value, false);
    });
  }

  getBusinessReview(selectedReview?: number): void {
    this.businessReviewService
      .businessReviewData()
      .pipe(take(1))
      .subscribe((data) => {
        this.businessReviewData = data;
        if (selectedReview) {
          const createdReview = this.businessReviewData.filter((review) => review.id === selectedReview);
          this.businessReview = createdReview[0] ?? data[0];
          this.setBusinessReview(this.businessReview);
        } else {
          this.businessReview = data[0];
          this.setBusinessReview(this.businessReview);
        }
      });
  }

  setBusinessReview(review: BusinessReviewData): void {
    this.businessReviewService.selectBusinessReview(review);
  }

  buildChartTrend() {
    const { chartColors, shortNumber, expectedLabel, achievedLabel } = this;
    const abbr = this.numberAbbreviations;
    const lineDataExpected = [];
    const lineDataAchieved = [];
    const labels = [];

    this.trackerData?.line_graph.expected_by_quarter.forEach((el, index) => {
      labels.push(this.trackerData.line_graph.labels[index]);
      lineDataExpected.push(el);
    });

    this.trackerData.line_graph.realized_by_quarter.forEach((el, _index) => {
      lineDataAchieved.push(el);
    });

    this.chartTrend = {
      datasets: [
        {
          label: '',
          data: lineDataAchieved,
          borderColor: chartColors[2],
          backgroundColor: chartColors[2],
          fill: false,
        },
      ],
      labels: labels,
    };

    this.chartTrendLarge = {
      datasets: [
        {
          label: expectedLabel,
          data: lineDataExpected,
          borderColor: chartColors[2],
          backgroundColor: chartColors[2],
          fill: false,
        },
        {
          label: achievedLabel,
          data: lineDataAchieved,
          borderColor: chartColors[1],
          backgroundColor: chartColors[1],
          fill: false,
        },
      ],
      labels: labels,
    };

    this.buildBarChartLarge();

    this.optionsTrend = smallLineChartConf;

    this.optionsTrendLarge = {
      elements: {
        point: {
          radius: 3,
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: this.trans.trans.currency.value,
            },
            ticks: {
              fontColor: '#AFBBD1',
              callback: function (value) {
                return shortNumber.transform(value, abbr);
              },
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#AFBBD1',
            },
          },
        ],
      },
      tooltips: {
        mode: 'index',
        enabled: false,

        custom: function (tooltipModel) {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div id="tooltip-inner" style="border-collapse: collapse;	border-radius: 5px;	width: 100%;"></div>';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const bodyLinesSorted = bodyLines.sort(function (a, b) {
              return a === b ? 0 : a < b ? -1 : 1;
            });

            let innerHtml = '<div style="background-color: #DDE5EB; border-radius: 5px 5px 0 0;">';

            titleLines.forEach(function (title) {
              innerHtml += '<div><div style="padding: 6px 7px 4px 12px; font-weight: normal">' + title + '</div></div>';
            });
            innerHtml += '</div><div style="padding: 4px 16px 12px 0;">';

            bodyLinesSorted.forEach((body, i) => {
              const labelArr = body[0].split(': ');
              let style = 'background: ' + this._chart.config.options.legend.labels.fontColor[i];
              style += '; width:7px; height: 7px; margin-top: 4px; margin-left: 5px; border-radius: 50%';
              const div = '<div style="' + style + '"></div>';
              innerHtml +=
                '<div><div style="display: grid; grid-template-columns: 18px 162px 7% 3%; padding: 3px 0px 0px 9px; color: #7E899E;">' +
                div +
                labelArr[0] +
                '<div style="display: flex; justify-content: flex-end; color: #3C4351;">' +
                shortNumber.transform(labelArr[1], abbr) +
                '</div><div></div></div></div>';
            });
            innerHtml += '</div>';

            const tableRoot = tooltipEl.querySelector('#tooltip-inner');
            tableRoot.innerHTML = innerHtml;
          }

          const position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 120 + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.backgroundColor = '#FFFFFF';
          tooltipEl.style.borderRadius = '5px';
          tooltipEl.style.boxShadow = '0px 0px 20px rgba(0,0,0,0.10),0px 10px 20px rgba(0,0,0,0.05), 0px 20px 20px rgba(0,0,0,0.05),0px 30px 20px rgba(0,0,0,0.05)';
        },
      },

      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: [chartColors[0], chartColors[2], chartColors[chartColors.length - 1]],
          fontSize: 10,
          boxWidth: 5,
          usePointStyle: true,
        },
      },
      animation: {
        duration: 500,
      },
      responsive: true,
    };

    this.isLoading = false;
  }

  switchBarType(): void {
    this.buildBarChartLarge();
  }

  private setNewBusinessReviewObserver(): void {
    this.businessReviewService.newBusinessReview.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      this.getBusinessReview(id);
    });
  }

  private buildBarChartLarge(): void {
    let chartDatasetExpected: number[];
    let chartDatasetAchieved: number[];
    let chartLabels: string[];
    if (this.barType == this.barTypes[0]) {
      chartDatasetExpected = this.trackerData.bar_chart_dollar.expected_to_date;
      chartDatasetAchieved = this.trackerData.bar_chart_dollar.realized_to_date;
      chartLabels = this.trackerData.bar_chart_dollar.labels;
    } else {
      chartDatasetExpected = this.goals.map((goal) => goal.survey_factors.find((factor) => factor.business_review_id == this.businessReview.id)?.target_value ?? 0);
      chartDatasetAchieved = this.goals.map((goal) => goal.survey_factors.find((factor) => factor.business_review_id == this.businessReview.id)?.factor_value ?? 0);
      chartLabels = this.goals.map((goal) => goal.factor_name);
    }

    this.chartBarLarge = {
      datasets: [
        {
          label: this.expectedLabel,
          data: chartDatasetExpected,
          borderColor: this.chartColors[2],
          backgroundColor: this.chartColors[2],
          barPercentage: 0.8,
          categoryPercentage: this.trackerData.bar_chart_dollar.expected_to_date?.length < 8 ? (this.screenWidth <= 1024 ? 0.5 : 0.3) : 1,
          maxBarThickness: 12,
        },
        {
          label: this.achievedLabel,
          data: chartDatasetAchieved,
          borderColor: this.chartColors[1],
          backgroundColor: this.chartColors[1],
          barPercentage: 0.8,
          categoryPercentage: this.trackerData.bar_chart_dollar.expected_to_date?.length < 8 ? (this.screenWidth <= 1024 ? 0.5 : 0.3) : 1,
          maxBarThickness: 12,
        },
      ],
      labels: chartLabels,
    };

    this.setbarChartOptions(chartLabels);
  }

  private setbarChartOptions(labels: string[]): void {
    const { shortNumber } = this;
    const abbr = this.numberAbbreviations;
    const unitMap = new Map();

    if (this.barType == this.barTypes[1]) {
      const unitArr = this.goals.map((goal) => this.trans.trans[this.unitTypes[+goal.unit_type_id]].value);
      unitArr.forEach((unit, i) => {
        unitMap.set(labels[i], unit);
      });
    }

    this.optionsBarLarge = {
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: this.barType === this.barTypes[0] ? this.trans.trans.currency.value : '',
            },
            ticks: {
              fontColor: '#AFBBD1',
              callback: function (value) {
                return shortNumber.transform(value, abbr);
              },
              suggestedMin: 0,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              fontColor: '#AFBBD1',
              callback: (value: string) => {
                if (labels && 21 > labels.length && labels.length > 1) {
                  const limit = Math.ceil((labels.length * -10) / 13 + 226 / 13);
                  return value.slice(0, limit) + '...';
                } else if (labels && labels.length > 20) {
                  return value.slice(0, 2) + '...';
                } else {
                  return value;
                }
              },
            },
          },
        ],
      },
      units: unitMap,
      tooltips: {
        mode: 'index',
        enabled: false,

        custom: function (tooltipModel) {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div id="tooltip-inner" style="border-collapse: collapse;	border-radius: 5px;	width: 100%;"></div>';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const bodyLinesSorted = bodyLines.sort(function (a, b) {
              return a === b ? 0 : a < b ? -1 : 1;
            });

            let innerHtml = '<div style="background-color: #DDE5EB; border-radius: 5px 5px 0 0;">';

            titleLines.forEach(function (title) {
              innerHtml += '<div><div style="padding: 6px 7px 4px 12px; font-weight: normal">' + title + '</div></div>';
            });
            innerHtml += '</div><div style="padding: 4px 16px 12px 0;">';

            bodyLinesSorted.forEach((body, i) => {
              const labelArr = body[0].split(': ');
              let style = 'background: ' + this._chart.config.options.legend.labels.fontColor[i];
              style += '; width:7px; height: 7px; margin-top: 4px; margin-left: 5px; border-radius: 50%';
              const div = '<div style="' + style + '"></div>';
              const unit = this._chart.config?.options?.units.get(titleLines[0]) ? this._chart.config.options.units.get(titleLines[0]) : '';
              innerHtml +=
                '<div><div style="display: grid; grid-template-columns: 18px 60px minmax(80px, 1fr) 1%; padding: 3px 0px 0px 9px; color: #7E899E;">' +
                div +
                labelArr[0] +
                '<div style="display: flex; justify-content: flex-end; color: #3C4351;">' +
                shortNumber.transform(labelArr[1], abbr) +
                ' ' +
                unit +
                '</div><div></div></div></div>';
            });
            innerHtml += '</div>';

            const tableRoot = tooltipEl.querySelector('#tooltip-inner');
            tableRoot.innerHTML = innerHtml;
          }

          const position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 120 + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.backgroundColor = '#FFFFFF';
          tooltipEl.style.borderRadius = '5px';
          tooltipEl.style.boxShadow = '0px 0px 20px rgba(0,0,0,0.10),0px 10px 20px rgba(0,0,0,0.05), 0px 20px 20px rgba(0,0,0,0.05),0px 30px 20px rgba(0,0,0,0.05)';
        },
      },

      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: [this.chartColors[0], this.chartColors[2], this.chartColors[this.chartColors.length - 1]],
          fontSize: 10,
          boxWidth: 5,
          usePointStyle: true,
        },
      },
      animation: {
        duration: 500,
      },
      responsive: true,
    };
  }

  private setGoalsObserver(): void {
    this.businessReviewService.goals.pipe(takeUntil(this.ngUnsubscribe)).subscribe((saveGoals) => {
      this.goals = saveGoals;
      if (this.barType === this.barTypes[1]) {
        this.buildBarChartLarge();
      }
    });
  }

  triggerEdit(): void {
    this.router.navigate(['edit'], { relativeTo: this.route });
  }

  openVp(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([this.commonService.getLinkVPRoute('valueposition', id, 4)]));
    window.open(url, '_blank');
  }

  public chooseStep(route: string, id: string) {
    this.chooseAdventureDialog.newWindow = true;
    this.chooseAdventureDialog.navigationRoute = this.commonService.getLinkVPRoute(route, id, 4);
    this.chooseAdventureDialog.existingVp = true;
    if (this.priv15 && this.priv16) {
      this.showStepSelection = true;
    } else if (this.priv15 && !this.priv16) {
      this.chooseAdventureDialog.onProcessSelected('standard', route);
    } else if (this.priv16 && !this.priv15) {
      this.chooseAdventureDialog.onProcessSelected('express', route);
    } else {
      this.userService.replaceFF134$.next(false);
      this.openVp(id);
    }
  }

  public onHidden(hidden: boolean) {
    this.showStepSelection = hidden;
  }
}
