<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-2">
          <div class="title">
            {{ trans.trans.situations.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="'situations'"
              [isTranslationV2]="true"
              [transObj]="trans.trans.situations"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="helper-text">
            <app-editTranslation [transComp]="trans" [transObj]="trans.trans.editSituation"></app-editTranslation><br />
            <app-editTranslation [transComp]="trans" [transObj]="trans.trans.setProcessingOrder"></app-editTranslation><br />
            <app-editTranslation [transComp]="trans" [transObj]="trans.trans.displayOrder"></app-editTranslation><br />
          </div>
        </div>
        <div class="col-xs-4" *ngIf="can_edit">
          <a (click)="funcAddSituation()" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i>{{ trans.trans.add_new.value }}</a>
          <a (click)="funcUpdateSituationOrder()" class="btn btn_customizable pull-right marg-h" [ngClass]="{ disabled: !this.currentSequenceEdited }">{{
            trans.trans.saveProcessing.value
          }}</a>
        </div>
      </div>
    </div>
    <div class="row marg-v">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input [(ngModel)]="strSearch" (keyup)="tableSituations.filter($any($event).target.value, 'name', 'contains')" type="text" placeholder="Search" class="form-control" />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableSituations.filter('', 'name', 'contains')" class="input-group-addon clickable"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <p-progressBar *ngIf="loading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
      <p-table #tableSituations [columns]="cols" [value]="situations" styleClass="keywest_table" tableStyleClass="keywest_table" (onRowReorder)="drop()">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'situations'"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-situation let-index="rowIndex">
          <tr [pReorderableRow]="index">
            <td [pReorderableRowHandle]="index">
              <span class="clickable" (click)="can_edit ? funcEditSituation(situation) : funcViewSituation(situation)">{{ situation.name }}</span>
              <span *ngIf="situation.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situation.description" matTooltipPosition="above">
                info
              </span>
            </td>
            <td class="centered">{{ situation.processingSequence }}</td>
            <td class="centered">{{ situation.sequence }}</td>
            <td>
              <div (click)="$event.stopPropagation()" class="ellipsis-menu-container">
                <app-ellipsis-menu [hide]="hideMenu">
                  <ul class="list-group">
                    <li *ngIf="can_edit" class="list-group-item menu-grid" (click)="funcEditSituation(situation)">
                      <div><i class="fa fa-pencil"></i></div>
                      <span>{{ trans.trans.ch_edit.value }}</span>
                    </li>
                    <li *ngIf="!can_edit" class="list-group-item menu-grid" (click)="funcViewSituation(situation)">
                      <div><i class="fa fa-eye"></i></div>
                      <span>{{ trans.trans.ch_view.value }}</span>
                    </li>
                    <li class="list-group-item menu-grid" (click)="moveUp(situation.id)">
                      <div><i class="pi pi-arrow-up"></i></div>
                      <span>{{ trans.trans.moveUp.value }}</span>
                    </li>
                    <li class="list-group-item menu-grid" (click)="moveDown(situation.id)">
                      <div><i class="pi pi-arrow-down"></i></div>
                      <span>{{ trans.trans.moveDown.value }}</span>
                    </li>
                    <li class="list-group-item menu-grid" (click)="handleCustomSequenceClick(situation.id)">
                      <div><i class="pi pi-sort-alt"></i></div>
                      <span>{{ trans.trans.customSquence.value }}</span>
                    </li>
                  </ul>
                </app-ellipsis-menu>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="toggleAddEditSituation" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="resetValues()">
  <app-r-maintenance-situations *ngIf="toggleAddEditSituation && !can_edit" [objSituation]="objSituation"></app-r-maintenance-situations>
  <app-ae-maintenance-situations
    *ngIf="toggleAddEditSituation && can_edit"
    [mode]="mode"
    [objSituation]="objSituation"
    [solutionId]="solutionId"
    (valueChange)="funcCloseSidebar()"
    (closeCallback)="closeAddEditSituation()"
  ></app-ae-maintenance-situations>
</p-sidebar>

<p-sidebar [(visible)]="showSidebar" styleClass="keywest_sidebar p-sidebar-md" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans?.customSquence?.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="radio-grid">
            <p-radioButton name="up" value="up" label="Move Before" [(ngModel)]="moveDirection"></p-radioButton>
            <p-radioButton name="down" value="down" label="Move After" [(ngModel)]="moveDirection"></p-radioButton>
            <div></div>
          </div>
          <div class="dropdown-title">{{ trans.trans.situationName.value }}</div>
          <p-dropdown
            [panelStyleClass]="'dropdown-item-font'"
            (onChange)="handleDropdownChange($event)"
            class="keywest_dropdown dropdown-theme"
            [options]="situations | filterArrayByIndex: indexOfSelection"
            [(ngModel)]="selectedMove"
            placeholder=""
            optionLabel="name"
            [showClear]="true"
          ></p-dropdown>
        </div>
        <div class="row sequence-button-spacer">
          <button class="btn btn_customizable pull-right" (click)="saveMoveForm()">{{ trans.trans.move.value }}</button>
          <button class="btn cancel-button pull-right" (click)="closeSidebar()">{{ trans.trans.cancel.value }}</button>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
