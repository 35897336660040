import { Injectable } from "@angular/core";
import { CommonService } from "../common/common.service";
import { Collaboration, CreateCollaborationPayload, UpdateCollaborationPayload } from "@shared/models/collaboration.models";
import { Observable } from "rxjs";
import { APIResult } from "@shared/models/api-result.model";
import { SuccessFailResponse } from "@shared/models/success-fail-response.model";

@Injectable()
export class CollaborationServices {
	serviceUrl: string;
	constructor(private commonService: CommonService) {
		this.serviceUrl = this.commonService.getServiceUrl();
	}

    public createCollaboration(vpid: string, payload: CreateCollaborationPayload): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/collaboration/" + vpid;
		return this.commonService.postAPIService(endpoint, payload);
	}

	public getCollaborations(id: string): Observable<APIResult<{ collaborations: Collaboration[] }>> {
    let endpoint = 'valueProps/collaboration/' + id;
    return this.commonService.getAPIService(endpoint);
  }

	public updateCollaboration(vpid: string, payload: UpdateCollaborationPayload): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "valueProps/collaboration/" + vpid;
		return this.commonService.putAPIService(endpoint, payload);
	}

}