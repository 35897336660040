import { Injectable } from '@angular/core';

@Injectable()
export class AddCompanyTransTranslations {

  public config: any = {
    component: 'addCompanyNoTrans',
    display_name: 'Add Company',
    description: 'Add a company'
  };

  public trans: any = {
    industry_sector: 'Industry Sector',
    save: 'Save',
    add_a_company: 'Add A Company',
    name: 'Name',
    industry: 'Industry',
    country: 'Country',
    revenue_in_millions: 'Revenue in Millions',
    employees: 'Employees',
    address: 'Address',
    city: 'City',
    state: 'State',
    postal_code: 'Postal Code',
    additionalInformation: 'Additional Information',
    select_industry_sector: 'Select Industry Sector',
    select_industry: 'Select Industry',
    select_state: 'Select State',
    are_you_sure: 'Are you sure?',
    back: 'Back'
  };
}
