import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { ModelScalerDefaultsTranslation } from './model-scaler-defaults.translation';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { SolutionService } from '@data/services/solution/solution.service';
import { FactsService } from '@data/services/facts/facts.service';

@Component({
    selector: 'app-model-scaler-defaults',
    templateUrl: './model-scaler-defaults.component.html',
    styleUrls: ['./model-scaler-defaults.component.scss'],
})
export class ModelScalerDefaultsComponent implements OnInit, OnDestroy {
    @Input() canEdit: boolean;
    @Input() modelId: string;
    @Output() onUpdate = new EventEmitter();

    loading = false;
    loadingSave = false;

    ngUnsubscribe = new Subject();
    showTranslate: boolean;
    accountId: string;

    scalers: Array<BasicInfo | Factor> = [];
    factScalers: Array<BasicInfo> = [];
    selectedScalers: Array<{ [klass: string]: any }> = [];
    accountScaler: { name: string; description: string } = {
        name: '',
        description: '',
    };

    selectedItems: Array<{name: string, id: string} | Factor> = [];

    constructor(
        public trans: ModelScalerDefaultsTranslation,
        private translationService: TranslationsV2Service,
        private commonService: CommonService,
        private notificationService: NotificationService,
        private solutionService: SolutionService,
        private factService: FactsService,
    ) {}

    ngOnInit(): void {
        this.accountId = sessionStorage.getItem('aid');

        this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.getTranslations();
        });

        this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.showTranslate = res;
        });

        this.getTranslations();
        this.getScalers();
    }

    getTranslations(): void {
        const langId = sessionStorage.getItem('language_type_id');
        const langAbbr = this.translationService.getLanguageAbbr(langId);

        const payload = {
            account_id: this.accountId,
            component: this.trans.config.component,
            lang: langAbbr,
            localTranslations: this.trans.trans,
        };
        this.translationService
            .getComponentTrans(payload)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
            });
    }

    getScalers() {
        this.loading = true;
        this.factService.getScaleTypes(1).subscribe((response) => {
            if (response.result) {
                this.factScalers = response.result;
            }
            this.getAccountScalers();
        });
    }

    getAccountScalers() {
        this.factService.getAccountScaleTypes().subscribe((response) => {
            this.scalers = this.factScalers;
            if (response.result) {
                this.scalers = this.scalers.concat(response.result);
            }
            this.getAvailableScalers();
        });
    }

    getAvailableScalers() {
        this.solutionService.getAvailableScalers(this.modelId, 1).subscribe((response) => {
            if (response.result) {
                this.selectedScalers = response.result;
                this.selectedItems = this.scalers.filter(scaler => this.selectedScalers.some(selectedScaler => scaler.id === selectedScaler.scale_type_id));
                this.loading = false;
            }
        });
    }

    addScalersForEdit(): void {
        this.selectedItems.forEach(item => {
            if (this.selectedScalers.every(scaler => scaler.scale_type_id !== item.id)) {
                item['scale_type_id'] = item['id'];
                this.selectedScalers.push(item);
            }
        });
    }

    editScalers(form: NgForm): void {
        if(form.valid) {
            this.loadingSave = true;
            const accountSolutionScalers = [];

            Object.keys(form.value).forEach(key => {
                accountSolutionScalers.push({
                    scale_type_id: parseInt(key.split('-')[1], 0),
                    value: form.value[key],
                });
            });

            this.solutionService.updateScalers(this.modelId, { account_solution_scalers: accountSolutionScalers }).subscribe((response) => {
                if (response.result) {
                    this.notificationService.success(this.trans.trans.savedMessage.value, false);
                    this.loadingSave = false;
                    this.selectedScalers.forEach(scaler => scaler.value = accountSolutionScalers.find(s => s.scale_type_id == scaler.scale_type_id)?.value);
                    this.onUpdate.emit();
                }
            });
        } else {
            this.notificationService.error(this.trans.trans.requiredValidation.value, false);
        }
    }

    deleteScaler(id: string): void {
        this.loadingSave = true;
        this.solutionService.deleteScaler(this.modelId, id).subscribe((response) => {
            if (response.result) {
                this.selectedScalers = this.selectedScalers.filter(scaler => scaler.scale_type_id !== id);
                this.selectedItems = this.selectedItems.filter(scaler => scaler.id !== id);
                this.notificationService.success(response.result, false);
                this.loadingSave = false;
                this.onUpdate.emit();
            }
        });
    }

    resetFormValues(form: NgForm): void {
        Object.keys(form.value).forEach(key => {
            const id = key.split('-')[1];
            const oldValue = this.selectedScalers.find(scaler => scaler['scale_type_id'] === id)?.value;
            form.controls[key].setValue(oldValue);
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(false);
        this.ngUnsubscribe.complete();
    }
}
