<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>

<div *ngIf="!loading">
  <div class="models_panel" *ngIf="hypotheses && hypotheses.length" style="margin-bottom: 20px">
    <div class="models_page_header_container_small">
      <div class="competitors_header">
        <div class="row">
          <div class="col-xs-6">
            <div class="title">
              <i (click)="hypotheses.expanded = !hypotheses.expanded" *ngIf="!hypotheses.expanded" class="fa fa-chevron-right"></i>
              <i (click)="hypotheses.expanded = !hypotheses.expanded" *ngIf="hypotheses.expanded" class="fa fa-chevron-down"></i>
              <i class="fa fa-bullhorn" style="margin-left: 10px"></i> Hypotheses {{ hypotheses.length }} result{{ hypotheses.length > 1 ? 's' : '' }} for "{{ term }}"
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="hypotheses.expanded">
      <div class="col-xs-12">
        <p-table [value]="hypotheses" [paginator]="true" [rows]="pageLimit" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-elem>
            <tr>
              <td>
                <a [routerLink]="elem.url">{{ elem.name }}</a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="models_panel" *ngIf="propositions && propositions.length" style="margin-bottom: 20px">
    <div class="models_page_header_container_small">
      <div class="competitors_header">
        <div class="row">
          <div class="col-xs-6">
            <div class="title">
              <i (click)="propositions.expanded = !propositions.expanded" *ngIf="!propositions.expanded" class="fa fa-chevron-right"></i>
              <i (click)="propositions.expanded = !propositions.expanded" *ngIf="propositions.expanded" class="fa fa-chevron-down"></i>
              <i class="fa fa-pie-chart" style="margin-left: 10px"></i> Propositions {{ propositions.length }} result{{ propositions.length > 1 ? 's' : '' }} for "{{ term }}"
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="propositions.expanded">
      <div class="col-xs-12">
        <p-table [value]="propositions" [paginator]="true" [rows]="pageLimit" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-elem>
            <tr>
              <td>
                <a [routerLink]="elem.url">{{ elem.name }}</a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="models_panel" *ngIf="realizations && realizations.length" style="margin-bottom: 20px">
    <div class="models_page_header_container_small">
      <div class="competitors_header">
        <div class="row">
          <div class="col-xs-6">
            <div class="title">
              <i (click)="realizations.expanded = !realizations.expanded" *ngIf="!realizations.expanded" class="fa fa-chevron-right"></i>
              <i (click)="realizations.expanded = !realizations.expanded" *ngIf="realizations.expanded" class="fa fa-chevron-down"></i>
              <i class="icon-loop" style="margin-left: 10px"></i> Realizations {{ realizations.length }} result{{ realizations.length > 1 ? 's' : '' }} for "{{ term }}"
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="realizations.expanded">
      <div class="col-xs-12">
        <p-table [value]="realizations" [paginator]="true" [rows]="pageLimit" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-elem>
            <tr>
              <td>
                <a [routerLink]="elem.url">{{ elem.name }}</a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="models_panel" *ngIf="case_studies && case_studies.length" style="margin-bottom: 20px">
    <div class="models_page_header_container_small">
      <div class="competitors_header">
        <div class="row">
          <div class="col-xs-6">
            <div class="title">
              <i (click)="case_studies.expanded = !case_studies.expanded" *ngIf="!case_studies.expanded" class="fa fa-chevron-right"></i>
              <i (click)="case_studies.expanded = !case_studies.expanded" *ngIf="case_studies.expanded" class="fa fa-chevron-down"></i>
              Case Studies {{ case_studies.length }} result{{ case_studies.length > 1 ? 's' : '' }} for "{{ term }}"
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="case_studies.expanded">
      <div class="col-xs-12">
        <p-table [value]="case_studies" [paginator]="true" [rows]="pageLimit" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-elem>
            <tr>
              <td>
                <a [routerLink]="funcGetCaseStudiesLink(elem)">{{ elem.name }}</a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
