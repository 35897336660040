<app-add-value-position [dealdesk]=true [isvalueProp]="isvalueProp" (quickCreate)="quickCreateCompleted($event)"></app-add-value-position>

<div *ngIf="showDeployment">
	<div class='models_panel' style='margin-bottom: 20px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-12'>
						<div class='title'>
							{{ trans.deployment_schedule.graduated_deployment | translate }}
						</div>
					</div>
				</div>
			</div>
			<div class='row'>
				<div class='col-xs-12'>
					<app-deployment-schedule [dealdesk]="true" [data]="valueProp" (deploymentScheduleEmitter)="initCosts()"></app-deployment-schedule>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="showCosts">
	<app-costs-valueprop [dealdesk]="true" [valueProp]="valueProp"></app-costs-valueprop>
	<div class='row'>
		<div class='col-xs-12'>
			<button type="button" class="btn btn_customizable" (click)="showBenefit()">
				<i class="fa fa-plus"></i>{{ trans.add_value_position.next | translate }}
			</button>
		</div>
	</div>
</div>
<div *ngIf="showBenefits">
	<div class='models_panel' style='margin-bottom: 20px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-12'>
						<div class='title'>
							{{ trans.props.tabs.Benefits | translate }}
						</div>
					</div>
				</div>
			</div>
			<div class='row'>
				<app-benefits-valueprop [dealdesk]=true [data]="valueProp" [refreshBenefits]="refreshBenefits" [contextualHelp]="contextualHelp"></app-benefits-valueprop>
				<button type="button" class="btn btn_customizable" (click)="showAssumptions()">
					<i class="fa fa-plus"></i>{{ trans.add_value_position.next | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="showAssumption">
	<app-assumptions [valueProp]="valueProp"></app-assumptions>

	<div class="row">
		<div class="pull-right">
			<button type="submit" class="btn btn_customizable" (click)="finishandReview()">{{ trans.add_value_position.finish_and_review | translate }}</button>
		</div>
	</div>
</div>