import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessagesTranslations {
  public config: any = {
    component: 'ErrorMessagesService',
    display_name: 'Error Messages Service',
    description: 'All of the form error messages for the applicaion',
  };

  public trans: any = {
    min: 'The minimum is',
    max: 'The maximum is',
    required: 'This input is required',
    valuePropTerm: 'Cannot exceed the value proposition term:',
    months: 'months',
    url: 'Invalid URL',
    email: 'Invalid email',
    unchangedValue: 'Please enter a new value',
    noDecimal: 'Please enter a whole number',
    twoDecimal: 'Please enter a number with no more than two decimal places',
    maxChars: 'Maximum characters:',
    noSpecialCharacter: 'Only letters, numbers, and underscores are allowed',
    maxTotalValue: 'Max100 Benefit Accrual Type can not have phasing with a sum greater than 100%',
  };
}
