import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentUser } from '../user-profile.component';
import { UserProfileTranslation } from '../user-profile.translation';

export interface UserUpdate {
  phone: string,
}

@Component({
  selector: 'app-edit-profile-info',
  templateUrl: './edit-profile-info.component.html',
  styleUrls: ['./edit-profile-info.component.scss']
})
export class EditProfileInfoComponent implements OnInit, OnDestroy {

  @Input() user: CurrentUser;
  @Output() closeFlyout = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<UserUpdate>();

  private unsubscribe = new Subject<void>();
  public formUser: CurrentUser;
  public showTranslate = false;

  constructor(
    public trans: UserProfileTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService
  ) { }


  ngOnInit() {
    this.formUser= { ...this.user };
    this.getTranslations();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
    });
  }

  onSubmit(form: NgForm): void {
    const { phone, email, manager } = form.value
    const payload = {
      phone,
    };
    this.update.emit(payload);
  }

  close() {
    this.closeFlyout.emit(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
