import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-project-modal',
  templateUrl: './add-project-modal.component.html',
  styleUrls: ['./add-project-modal.component.scss'],
})
export class AddProjectModalComponent implements OnInit, OnDestroy {
  @Input() solutionId: number;

  accountProjects: Array<{ [klass: string]: any }> = [];
  accountProjectsAllLevel: Array<{ [klass: string]: any }> = [];
  accountProject: { [klass: string]: any } = {};
  accountProjectParent: { [klass: string]: any } = {};
  imageUrl: string;
  fullImagePath: string;
  newProject: { [klass: string]: any };
  newProjectLoader = false;
  accountProjectsLoader = false;
  toggleAddView = false;
  existingParent = false;
  dropdownSettings: { [klass: string]: any } = {};
  parentName: string;
  parentDescription: string;
  childrens: Array<{ [klass: string]: any }> = [];
  savedAccountProject: Array<string> = [];
  isActive = false;

  subscriptionsaveProject: Subscription;
  subscriptiongetAccountProjects: Subscription;
  subscriptionaddNewProject: Subscription;

  constructor(
    private solutionService: SolutionService,
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select Project',
      enableSearchFilter: true,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
      lazyLoading: false,
    };

    this.resetProject();

    this.getAccountProjects();
  }

  ngOnDestroy() {
    if (this.subscriptionsaveProject) {
      this.subscriptionsaveProject.unsubscribe();
    }
    if (this.subscriptiongetAccountProjects) {
      this.subscriptiongetAccountProjects.unsubscribe();
    }
    if (this.subscriptionaddNewProject) {
      this.subscriptionaddNewProject.unsubscribe();
    }
  }

  resetProject() {
    this.parentName = '';
    this.parentDescription = '';

    this.childrens = [];

    this.accountProjectParent = {};

    this.savedAccountProject = [];
  }

  getAccountProjects() {
    const aid = sessionStorage.getItem('aid');
    this.subscriptiongetAccountProjects = this.solutionService.getAccountProjects(aid).subscribe((response) => {
      this.accountProjects = [];
      this.accountProjectsAllLevel = [];
      if (response.result) {
        this.accountProjects = response.result;

        response.result.forEach((project: { [klass: string]: any }) => {
          this.accountProjectsAllLevel.push(project);
          if (project['children'] && project['children'].length > 0) {
            project['children'].forEach((children: { [klass: string]: any }) => {
              children['name'] = project['name'] + ' - ' + children['name'];
              this.accountProjectsAllLevel.push(children);
            });
          }
        });
      }
    });
  }

  saveProject() {
    const payload = [];
    if (this.savedAccountProject.length > 0) {
      this.savedAccountProject.forEach((project) => {
        payload.push({
          account_project_type_id: project,
          active: 0,
        });
      });
    } else {
      payload.push({
        account_project_type_id: this.accountProject['id'],
        active: this.isActive,
      });
    }

    const payloadProject = {
      solution_projects: payload,
    };

    this.subscriptionsaveProject = this.solutionService.saveProject(this.solutionId, payloadProject).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Project Added successfully', false);
        this.solutionService.readAllProjects.next('readProjects');
        this.solutionService.triggerTabs.next('projects');
        this.activeModal.close();
      }
    });
  }

  toggleAddViews(parent?: string) {
    this.toggleAddView = !this.toggleAddView;
    this.resetProject();

    if (parent) {
      this.existingParent = true;
    } else {
      this.existingParent = false;
    }
  }

  addNewProject() {
    const payload = {};

    if (this.existingParent && this.accountProjectParent['id']) {
      payload['parent_id'] = this.accountProjectParent['id'];
      payload['children'] = this.childrens;
    } else {
      payload['name'] = this.parentName;
      payload['description'] = this.parentDescription;
      payload['children'] = this.childrens;
    }

    const aid = sessionStorage.getItem('aid');

    this.newProjectLoader = true;
    this.subscriptionaddNewProject = this.solutionService.addNewProject(aid, payload).subscribe((response) => {
      if (response.result) {
        this.savedAccountProject = [];

        if (response.result['children']) {
          this.savedAccountProject = this.savedAccountProject.concat(response.result['children']);
        }

        this.accountProject = {
          id: response.result,
        };

        this.getAccountProjects();
        this.saveProject();

        this.newProjectLoader = false;
      }
    });
  }

  addChild() {
    this.childrens.push({
      name: '',
      description: '',
    });
  }

  removeChild(index: number) {
    this.childrens.splice(index, 1);
  }
}
