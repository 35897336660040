import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddCostModalComponent } from './add-cost-modal.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { ScratchPadModule } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/scratchpad/scratchpad.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AddCostVpDashTranslations } from './add-cost-modal.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ScratchPadModule,
    EditTranslationsModule,
    AreYouSureModule,
    CurrencyMaskModule,
    ApplicationPipesModule,
    ErrorInputWrapperModule,
    DropdownModule,
  ],
  declarations: [AddCostModalComponent],
  exports: [AddCostModalComponent],
  entryComponents: [AddCostModalComponent],
  providers: [TranslationsV2Service, AddCostVpDashTranslations],
})
export class AddCostModalModule {}
