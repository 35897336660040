import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateSurveyComponent } from './create-survey.component';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';



@NgModule({
  declarations: [CreateSurveyComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputSwitchModule,
    AreYouSureModule
  ],
  exports: [
    CreateSurveyComponent
  ]
})
export class CreateSurveyCorrectModule { }
