import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseAdventureInternalComponent } from './choose-adventure-internal.component';
import { DialogModule } from 'primeng/dialog';
import { WorkFlowTranslation } from './choose-adventure-internal.translation';

@NgModule({
  declarations: [
    ChooseAdventureInternalComponent
  ],
  imports: [
    DialogModule,
    CommonModule
  ],
  providers: [
    WorkFlowTranslation,
  ],
  exports: [ChooseAdventureInternalComponent]
})
export class ChooseAdventureInternalModule { }
