import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Translations } from '@data/services/translation/translation.service';
import { NumberTruncateModule } from '@shared/pipes/number-truncate.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { BarLinechartModule } from 'app/common/chart/bar-line-chart/bar-line-chart.module';
import { BarModule } from 'app/common/chart/bar/bar.module';
import { Doughnut5ChartModule } from 'app/common/chart/doughnut5/doughnut5-chart.module';
import { HorizontalBarModule } from 'app/common/chart/horizontalbar/horizontalbar.module';
import { LineChartModule } from 'app/common/chart/line/line.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { SimpleImplementation } from 'app/repv2/simple.implementation';
import { ModelAEBenefits } from 'app/value-mapv2/solution-detail/benefits/ae_benefits/ae_benefits.module';
import { DeploymentScheduleModule } from 'app/value-position/deployment-schedule/deployment-schedule.module';
import { AssetsValuePrompterModule } from 'app/value-prop/value-prop-dashboard/assets-value-prompter/assets-value-prompter.module';
import { AssetsModule } from 'app/value-prop/value-prop-dashboard/assets/assets.module';
import { VPDashboardAssetsDeliverableModule } from 'app/value-prop/value-prop-dashboard/assets_customer_deliverable/deliverable.module';
import { BenefitDetailsModule } from 'app/value-prop/value-prop-dashboard/benefits/benefit-detail/benefit-detail.module';
import { BenefitsV2Module } from 'app/value-prop/value-prop-dashboard/benefits_v2/benefits.module';
import { BenefitsMenuTranslations } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/menu/menu.translation';
import { DashboardCollateralModule } from 'app/value-prop/value-prop-dashboard/collateral/collateral.module';
import { CompetitiveTCOModule } from 'app/value-prop/value-prop-dashboard/competitive-tco/competitive-tco.module';
import { CostsModule } from 'app/value-prop/value-prop-dashboard/costs/costs.module';
import { DashboardSimpleImplementation } from 'app/value-prop/value-prop-dashboard/dashboard_simple/simple.implementation';
import { RepSimpleTranslations } from 'app/value-prop/value-prop-dashboard/dashboard_simple/simple.translation';
import { AssumptionsModule } from 'app/value-prop/value-prop-dashboard/discovery/assumptions/assumptions.module';
import { AssumptionsVpDashTranslations } from 'app/value-prop/value-prop-dashboard/discovery/assumptions/assumptions.translation';
import { AssumptionsFastFillModule } from 'app/value-prop/value-prop-dashboard/discovery/fast_fill/assumptions.module';
import { ProblemModule } from 'app/value-prop/value-prop-dashboard/discovery/problem/problem.module';
import { SolutionModule } from 'app/value-prop/value-prop-dashboard/discovery/solution/solution.module';
import { ExplainedModule } from 'app/value-prop/value-prop-dashboard/explained/explained.module';
import { LogModule } from 'app/value-prop/value-prop-dashboard/log/log.module';
import { MaturityModule } from 'app/value-prop/value-prop-dashboard/maturity/maturity.module';
import { DashboardBottomModule } from 'app/value-prop/value-prop-dashboard/panel_bottom/bottom.module';
import { AccountModule } from 'app/value-prop/value-prop-dashboard/scenario/account/account.module';
import { AccountVpDashTranslations } from 'app/value-prop/value-prop-dashboard/scenario/account/account.translation';
import { ModelsModule } from 'app/value-prop/value-prop-dashboard/scenario/models/models.module';
import { ScoreCardModule } from 'app/value-prop/value-prop-dashboard/score-card/score-card.module';
import { ShareModule } from 'app/value-prop/value-prop-dashboard/sharev2/share.module';
import { TCOModule } from 'app/value-prop/value-prop-dashboard/tco/tco.module';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { BenefitsMiniV2Module } from '../../../value-prop/value-prop-dashboard/benefits_v2/views/mini/mini.module';
import { RSV2DiscoveryQuestionsModule } from '../discovery_questions/questions.module';
import { KeyFiguresModule } from '../../../value-prop/value-prop-dashboard/key_figures/key_figures.module';
import { NotesModule } from '../../../value-prop/value-prop-dashboard/notes/notes.module';
import { RSV2CashflowSimpleModule } from '../cashflow_simple/cashflow_simple.module';
import { ResultDetailsComponent } from '../style2022/result-details/result-details.component';
import { ResultsStyle2022Component } from '../style2022/results-style2022/results-style2022.component';
import { ValuepropNameChangerModule } from '@shared_components/valueprop-name-changer/valueprop-name-changer/valueprop-name-changer.module';
import { Resultscomponent } from './results.component';
import { RepSimpleResultsTranslaions } from './results.translation';
import { SituationsSharedModule } from '@shared_components/situations-shared/situations-shared.module';
import { ValuePropGoalsModule } from 'app/value-prop/value-prop-dashboard/value-prop-goals/value-prop-goals.module';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    ChartModule,
    DLProgressbarModule,
    NotesModule,
    EditTranslationsModule,
    ApplicationPipesModule,
    MatTooltipModule,
    RSV2CashflowSimpleModule,
    TableChartModule,
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    KeyFiguresModule,
    BarLinechartModule,
    Doughnut5ChartModule,
    RSV2DiscoveryQuestionsModule,
    BarModule,
    LineChartModule,
    HorizontalBarModule,
    BenefitsMiniV2Module,
    ProgressBarModule,
    SidebarModule,
    ValuepropNameChangerModule,
    MenuModule,
    TCOModule,
    DLMenubarModule,
    CompetitiveTCOModule,
    LogModule,
    ScoreCardModule,
    ShareModule,
    AssumptionsFastFillModule,
    ExplainedModule,
    AccountModule,
    ModelsModule,
    MaturityModule,
    BenefitsV2Module,
    BenefitDetailsModule,
    ModelAEBenefits,
    CostsModule,
    DeploymentScheduleModule,
    AssumptionsModule,
    ProblemModule,
    SolutionModule,
    AssetsModule,
    DashboardCollateralModule,
    VPDashboardAssetsDeliverableModule,
    AssetsValuePrompterModule,
    DashboardBottomModule,
    NumberTruncateModule,
    SituationsSharedModule,
    ValuePropGoalsModule,
    ValuePropFlowButtonsModule
  ],
  declarations: [Resultscomponent, ResultsStyle2022Component, ResultDetailsComponent],
  providers: [
    Translations,
    SimpleImplementation,
    RepSimpleResultsTranslaions,
    DashboardSimpleImplementation,
    RepSimpleTranslations,
    AssumptionsVpDashTranslations,
    AccountVpDashTranslations,
    BenefitsMenuTranslations,
  ],
  entryComponents: [Resultscomponent],
  exports: [Resultscomponent, ResultsStyle2022Component],
})
export class RSV2DashboardResultsModule {}
