import { Injectable } from '@angular/core';

@Injectable()
export class TrackerGoalsTranslation {
    public config: any = {
        component: 'tracker-goals',
        display_name: 'Tracker Goals',
        description: 'All the goals in a value tracker',
    };

    public trans: any = {
        nameCol: 'Goal Name',
        valueCol: 'Value',
        addGoal: 'Add New',
        editGoal: 'Edit',
        deleteGoal: 'Delete',
        deleteMessage: 'Goal deleted successfully',
        saveError: 'There was a problem, please try again',
        areYouSure: 'Are you sure?',
        emptyTable: 'No data found',
        addSuccess: 'Goal added successfully',
        editSuccess: 'Goal edited successfully',
    };
}
