import { Injectable } from '@angular/core';

@Injectable()
export class TrackValueProofTabTranslations {
  public config: any = {
    component: 'trackValueProoflTab',
    display_name: 'Track Value Proof Points Tab',
    description: 'The proof points tab found in the track value detail dashboard',
  };

  public trans: any = {
    proofPointName: 'Proof Point Name',
    emptyTable: 'No data found',
    add: 'Add new',
  };
}
