import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Translations } from '@data/services/translation/translation.service';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { WorkflowQuestionsModule } from '@shared_components/workflow-questions/workflow-questions.module';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';
import { GuidedDiscoveryComponent } from './guided-discovery/guided-discovery.component';
import { GuidedDiscoveryTranslation } from './guided-discovery/guided-discovery.translation';
import { GuidedModelComponent } from './guided-model/guided-model.component';
import { GuidedModelTranslation } from './guided-model/guided-model.translation';
import { QuestionsAnswersGuidedDiscoveryComponent } from './questions-answers-guided-discovery/questions-answers-guided-discovery.component';
import { QuestionsAnswersGuidedDiscoveryTranslations } from './questions-answers-guided-discovery/questions-answers-guided-discovery.translation';
import { SimpleGuidedDiscoveryComponent } from './simple-guided-discovery/simple-guided-discovery.component';
import { SimpleGuidedDiscoveryTranslation } from './simple-guided-discovery/simple-guided-discovery.translation';

@NgModule({
  declarations: [GuidedDiscoveryComponent, SimpleGuidedDiscoveryComponent, QuestionsAnswersGuidedDiscoveryComponent, GuidedModelComponent],
  exports: [GuidedDiscoveryComponent],
  imports: [CommonModule, FormsModule, CheckboxModule, WorkflowQuestionsModule, ProgressBarModule, EditTranslationsModule],
  providers: [
    Translations,
    AccountWorkflowService,
    SimpleGuidedDiscoveryTranslation,
    QuestionsAnswersGuidedDiscoveryTranslations,
    GuidedDiscoveryTranslation,
    GuidedModelTranslation,
  ],
})
export class GuidedDiscoveryModule {}
