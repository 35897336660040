<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
<ng-template #loadedContent>
  <div class="col-xs-4">
    <div class="input-group keywest_search search-container" *ngIf="!loading">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input type="text" class="form-control" [formControl]="searchField" />
      <span *ngIf="searchField.value !== ''" (click)="resetSearchField()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <p-table #tableSuccessCriteria [value]="successQuestionsFiltered" styleClass="keywest_table" tableStyleClass="keywest_table" >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              {{ trans.trans.name.value }}
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th>
              {{ trans.trans.description.value }}
            </th>
            <th *ngIf="showNotes">
              {{ trans.trans.notes.value }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr>
            <td class="col-name-success clickable" (click)="toggleSidebar(rowData, rowIndex)">
              {{ rowData.name }}
            </td>
            <td>
              <span>{{ rowData.answer }}</span>
            </td>
            <td *ngIf="showNotes" class="col-notes-success">
              <div class="notes-count">{{ rowData?.notes?.length }}</div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2" class="text-center">{{ trans.trans.emptyTable.value }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-template>

<app-success-criteria-sidebar
        *ngIf="!!selectedQuestion && successSidebarOpen"
        (closeSidebar)="closeSidebar($event)"
        [selectedQuestion]="selectedQuestion"
        [valuePropId]="valuePropId"
        (backClicked)="closeSidebar($event)"
        (onHide)="successSidebarOpen = false"
></app-success-criteria-sidebar>
