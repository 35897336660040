import { Benefit, ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { TranslateService } from '@ngx-translate/core';
import { SimpleImplementation } from 'app/repv2/simple.implementation';
import { BottomPanelTranslations } from 'app/value-prop/value-prop-dashboard/panel_bottom/bottom.translation';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RepSimpleResultsTranslaions } from '../../results/results.translation';
import { UserService } from '@data/services/user/user.service';
import { StepperMenuItem } from '@shared/models/stepper-menu.modles';
import { BenefitsImplementation } from 'app/value-prop/value-prop-dashboard/benefits_v2/benefits.implementation';
@Component({
  selector: 'app-rsv2-dashboard-results-style2022',
  templateUrl: './results-style2022.component.html',
  styleUrls: ['./results-style2022.component.scss'],
})
export class ResultsStyle2022Component implements OnInit, OnDestroy {
  @Input() contextualHelp: any;
  @Input() dashboard: string;
  @Input() hasKeyAssumptions: boolean;
  @Input() isDev: boolean;
  @Input() locked = false;
  @Input() menu: StepperMenuItem[] = [];
  @Input() selectedBenefit: Benefit;
  @Input() shareView = 'Simple';
  @Input() showHelp: boolean;
  @Input() showTranslate = false;
  @Input() set valuePropData(vp: ValueProp) {
    this.valueProp = vp;
    this.setNoCostForTabs();
  }

  valueProp: ValueProp;
  @Output() reloadVP = new EventEmitter();
  @Output() back = new EventEmitter();

  assetsDeliverable = this.commonService.checkFeature(35);
  assetsPrompters = false;

  benefits = { metrics: [] };
  benefitTotal: string;
  benefitOnTheFlyPrivilege = this.commonService.checkPrivilege(6);

  competitiveTco = this.commonService.checkFeature(75);
  csmPrivilege = this.commonService.checkPrivilege(14);
  feature131 = this.commonService.checkFeature(131);
  feature59 = this.commonService.checkFeature(59);
  feature62 = this.commonService.checkFeature(62);
  feature76 = this.commonService.checkFeature(76);
  feature106 = this.commonService.checkFeature(106);
  feature141 = this.commonService.checkFeature(141);
  showTCO = this.commonService.checkFeature(10);
  alwaysShowTCO = this.commonService.checkFeature(144);
  hideNPV = this.commonService.checkFeature(37);
  hidePaybackFeature = this.commonService.checkFeature(121);
  rollupBenefitsByValueCategory = this.commonService.checkFeature(25);
  hideCashFlowFF = this.commonService.checkFeature(137);

  noCostSuperSimpleRep = false;

  loadingMenu = false;
  menuTop: MenuItem[];
  ngUnsubscribe$ = new Subject();
  npvColor: string;
  npvFormulaText: string;
  npvStatus: string;
  npvText: string;

  optionsVC: any;

  pbpColor: string;
  pbpStatus: string;
  paybackText: string;
  paybackFormulaText: string;
  permissions: { [klass: string]: boolean };

  roiColor: string;
  roiStatus = '';
  roiText: string;

  selectedDescription: string;
  selectedName = '';
  selectedVal = '';
  selectedCLR = '';
  selectedId: number;
  showAllMetrics = false;

  tcoFeature = false;
  tcoText: string;
  threemoFormula: string;
  threemoText: string;
  toggleChart: boolean;

  valuePropStatusTypes: MenuItem[] = [];
  VCchart: { labels: any; datasets: any };
  view = 'case';
  smallerView: string;
  viewTop = 'results';
  viewTopTitle = 'Results';

  crm: string;
  replaceFF134 = false;

  constructor(
    private commonService: CommonService,
    private translateService: TranslateService,
    public trans: RepSimpleResultsTranslaions,
    private repSimpleService: RepSimpleViewService,
    public sI: SimpleImplementation,
    private valuePropService: ValuepropService,
    public rAS: ResultsAnalysisService,
    private transBottom: BottomPanelTranslations,
    private userService: UserService,
    private bI: BenefitsImplementation
  ) {
    this.translateService.setDefaultLang('defaultLang');
  }

  ngOnInit(): void {
    this.userService.replaceFF134$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((replaceFF134) => {
      this.replaceFF134 = replaceFF134;
    });
    this.crm = sessionStorage.getItem('crm');

    if (this.sI.style2022ViewTop) {
      this.viewTop = this.sI.style2022ViewTop;
    } else {
      this.viewTop = 'results';
    }

    const menuLength = this.menu.length - 1;
    let done = true;
    for (let i = 0; i < menuLength; i++) {
      if (!this.menu[i].done) {
        done = false;
      }
    }

    if (this.valueProp?.done === '1') {
      done = true;
    }

    if (done) {
      const payload2 = { done: 1 };
      this.repSimpleService
        .putDone(this.valueProp.id, payload2)
        .pipe(take(1))
        .subscribe((res) => {
          this.valueProp.done = '1';
          for (let i = 0; i < menuLength; i++) {
            this.menu[i].done = true;
          }
        });
    }

    this.repSimpleService.navNotes.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.view = 'notes';
    });

    this.refreshTranslation();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.refreshTranslation();
      this.setNoCostForTabs();
    });

    this.valuePropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      if (res !== 'changeCost' && res !== 'no_reload') {
        this.refreshTranslation();
      } else if (res === 'changeCost') {
        this.rAS.refreshROIChart(this.valueProp, this.feature62);
      }
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    sessionStorage.removeItem('repsimple_results_view');
    sessionStorage.removeItem('metricsExpandedMini');

    this.rAS.headerColor = sessionStorage.getItem('header_color');

    if (sessionStorage.getItem('repsimple_results_view')) {
      this.view = sessionStorage.getItem('repsimple_results_view');
    }

    this.repSimpleService.navNotes.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.view = 'notes';
    });

    const benefitIndex = sessionStorage.getItem('benefit_index');
    if (benefitIndex && this.valueProp.benefits ) {
      const out = this.valueProp.benefits.filter((x) => x.value_category_id === benefitIndex)[0];
      this.rAS.benefits.metrics = out.metrics;
      this.view = 'benefits';
    }

    if (this.commonService.checkFeature('10')) {
      this.rAS.tcoFeature = true;
    }

    this.rAS.translationsLoaded$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((loaded) => {
      if (loaded) {
        this.funcBuildMenu();
      }
    });

    this.setNoCostForTabs();
    this.rAS.buildChart(this.valueProp);
    this.bI.loadBenefits();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.rAS.clearServiceData();
    this.bI.clearServiceData();
  }

  clickBenefitClick(item, i) {
    this.smallerView = 'benefits';
    this.viewTop = null;
    this.funcBuildMenu();
    this.selectedDescription = item.description;
    this.rAS.clickBenefitClick(item, i, null, false);
  }

  closeBenefits() {
    sessionStorage.removeItem('benefit_index');
  }

  funcChangeView(view) {
    this.view = view;
  }

  refreshTranslation() {
    this.rAS.getTranslations(this.valueProp, this.view, this.feature62);
  }

  reload() {
    this.valuePropService
      .quickFillGetBenefits(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.valueProp.benefits = response.result.vcs;
        this.rAS.funcGetCharts(this.valueProp, this.view, this.feature62);
        this.rAS.buildChart(this.valueProp);
        this.setNoCostForTabs();
        this.reloadVP.emit();
      });
  }

  public funcBuildMenu() {
    this.loadingMenu = true;
    this.menuTop = [];
    this.addScenarioToMenu();
    this.addInputsToMenu();
    this.addBenefitsToMenu();
    this.addCostsToMenu();
    this.addGoalsToMenu();
    this.addNotesToMenu();
    this.loadingMenu = false;
  }

  addNotesToMenu() {
    this.menuTop.push({
      label: this.rAS.transTop.trans.notes.value,
      styleClass: this.viewTop === 'notes' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'notes';
        this.sI.style2022ViewTop = this.viewTop;
        this.smallerView = null;
        this.funcBuildMenu();
      },
    });
  }

  addGoalsToMenu() {
    if (this.csmPrivilege && this.feature131) {
      this.menuTop.push({
        label: this.rAS.transBottom.trans.goals.value,
        styleClass: this.viewTop === 'goals' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'goals';
          this.sI.style2022ViewTop = this.viewTop;
          this.smallerView = null;
          this.funcBuildMenu();
        },
      });
    }
  }

  addBenefitsToMenu() {
    const benefitsView = {
      label: this.transBottom.trans.benefits.value,
      styleClass: this.viewTop === 'benefits' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'benefits';
        this.smallerView = null;
        this.funcBuildMenu();
      },
    };

    const addBenefitItem = {
      label: this.transBottom.trans.add_benefit.value,
      styleClass: this.viewTop === 'addbenefit' ? 'menuSelected' : '',
      command: () => {
        this.selectedBenefit = null;
        this.viewTop = 'addbenefit';
        this.funcBuildMenu();
      },
    };

    if (!this.rollupBenefitsByValueCategory && this.benefitOnTheFlyPrivilege) {
      this.menuTop.push({
        label: this.transBottom.trans.benefits.value,
        styleClass: this.viewTop == 'benefits' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'benefits';
          this.sI.style2022ViewTop = this.viewTop;
          this.funcBuildMenu();
        },
        items: [benefitsView, addBenefitItem],
      });
    } else {
      this.menuTop.push(benefitsView);
    }
  }

  addCostsToMenu() {
    this.menuTop.push({
      label: this.transBottom.trans.costs.value,
      styleClass: this.viewTop === 'costs' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'costs';
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });

    if (this.competitiveTco) {
      this.menuTop.push({
        label: 'Cost Evaluation',
        styleClass: this.viewTop === 'competitive-tco' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'competitive-tco';
          this.sI.style2022ViewTop = this.viewTop;
          this.funcBuildMenu();
        },
      });
    }
  }

  addScenarioToMenu() {
    const items = [];
    items.push({
      label: this.transBottom.trans.account.value,
      styleClass: this.viewTop === 'account' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'account';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });
    items.push({
      label: this.transBottom.trans.models.value,
      styleClass: this.viewTop === 'models' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'models';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });
    items.push({
      label: this.transBottom.trans.situations.value,
      styleClass: this.viewTop === 'situations' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'situations';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });
    items.push({
      label: this.transBottom.trans.advanced.value,
      styleClass: this.viewTop === 'advanced' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'advanced';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });

    this.menuTop.push({
      label: this.transBottom.trans.scenario.value,
      styleClass: ['account', 'models', 'situations', 'advanced'].includes(this.viewTop) ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'account';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
      items: items,
    });
  }

  addInputsToMenu() {
    const items = [];
    items.push({
      label: this.transBottom.trans.scalers.value,
      styleClass: this.viewTop === 'scalers' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'scalers';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });

    items.push({
      label: this.transBottom.trans.dataAndAssumptions.value,
      styleClass: this.viewTop === 'fastfill' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'fastfill';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });

    if (this.hasKeyAssumptions === true) {
      items.push({
        label: this.transBottom.trans.key_assumptions.value,
        styleClass: this.viewTop === 'keyassumptions' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'keyassumptions';
          this.smallerView = null;
          this.sI.style2022ViewTop = this.viewTop;
          this.funcBuildMenu();
        },
      });
    }

    items.push({
      label: this.transBottom.trans.allFactors.value,
      styleClass: this.viewTop === 'allassumptions' ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'allassumptions';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
    });

    if (this.valueProp.vmw_q_1) {
      items.push({
        label: this.transBottom.trans.problem.value,
        styleClass: this.viewTop === 'problem' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'problem';
          this.smallerView = null;
          this.sI.style2022ViewTop = this.viewTop;
          this.funcBuildMenu();
        },
      });
    }

    if (this.valueProp.vmw_q_2) {
      items.push({
        label: this.transBottom.trans.solution.value,
        styleClass: this.viewTop === 'solution' ? 'menuSelected' : '',
        command: () => {
          this.viewTop = 'solution';
          this.smallerView = null;
          this.sI.style2022ViewTop = this.viewTop;
          this.funcBuildMenu();
        },
      });
    }

    this.menuTop.push({
      label: this.transBottom.trans.inputs.value,
      styleClass: ['scalers', 'questions', 'solution', 'problem', 'allassumptions', 'keyassumptions'].includes(this.viewTop) ? 'menuSelected' : '',
      command: () => {
        this.viewTop = 'scalers';
        this.smallerView = null;
        this.sI.style2022ViewTop = this.viewTop;
        this.funcBuildMenu();
      },
      items: items,
    });
  }

  setNoCostForTabs() {
    const noCosts = +this.valueProp.total_costs === 0;
    this.noCostSuperSimpleRep = noCosts;
  }
}
