<dl-menubar
    *ngIf="!loadingMenu && menuBottom && menuBottom.length"
    styleClass="details_menu_bar"
    class="details_menu_bar"
    [model]="menuBottom"
></dl-menubar>
<!-- <p-menubar [model]="menuBottom" class='details_menu_bar'></p-menubar> -->

<div *ngIf="viewBottom == 'interstitial'">
    <p-progressBar mode="indeterminate"></p-progressBar>
</div>

<div *ngIf="viewBottom !== 'interstitial'">
    <app-assumptions-fastfill
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        [contextualHelp]="contextualHelp"
        *ngIf="viewBottom == 'fastfill'"
        (callbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
        [valueProp]="valueProp"
    ></app-assumptions-fastfill>
    <app-explained
        (callbackNoData)="viewBottom = 'account'"
        [explained]="explained"
        *ngIf="viewBottom == 'explained'"
    ></app-explained>
    <app-account
        [companyId]="valueProp.id"
        [valueProp]="valueProp"
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'account' && valueProp"
    ></app-account>
    <app-models
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'models' && valueProp"
        [valueProp]="valueProp"
    ></app-models>
    <app-situations-shared
        [showTranslate]="showTranslate"
        [showEditSituations]="true"
        *ngIf="viewBottom == 'situations' && valueProp"
        [valueProp]="valueProp"
        [canEdit]="valueProp.vp_can_edit"
    ></app-situations-shared>
    <app-advanced
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'advanced' && valueProp"
        [valueProp]="valueProp"
    ></app-advanced>
    <app-maturity *ngIf="viewBottom == 'maturity' && valueProp" [valueProp]="valueProp"></app-maturity>

    <app-benefits-v2
        *ngIf="viewBottom == 'benefits'"
        [mini]="false"
        [showTranslate]="showTranslate"
        [valueProp]="valueProp"
        [showHelp]="showHelp"
    ></app-benefits-v2>
    <app-benefit-detail
        *ngIf="viewBottom == 'benefit_detail'"
        [selectedBenefit]="selectedBenefit"
        [data]="selectedBenefit"
        [data]="selectedBenefit"
        [valueProp]="valueProp"
        (closeBenefitDetail)="viewBottom = 'benefits'"
    >
    </app-benefit-detail>
    <app-add-benefits-component
        *ngIf="viewBottom == 'addbenefit' && !modelsV2"
        [showTranslate]="showTranslate"
        (cancelBenefitCallback)="viewBottom = 'benefits'"
        (saveBenefitCallback)="viewBottom = 'benefits'"
        [selectedBenefit]="selectedBenefit"
        [showHelp]="showHelp"
        [valueProp]="valueProp"
    >
    </app-add-benefits-component>

    <div *ngIf="viewBottom == 'addbenefit' && solutionId && modelsV2" class="models_panel" style="margin-bottom: 20px">
        <app-ae-benefits
            [solutionId]="solutionId.toString()"
            [can_edit]="true"
            [isOnTheFlyBenefit]="true"
            [valuePropId]="valueProp.id"
            (callback)="viewBottom = 'benefits'"
        ></app-ae-benefits>
    </div>
    <app-costs-valueprop
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'costs' && valueProp"
        [valueProp]="valueProp"
    ></app-costs-valueprop>
    <app-deployment-schedule
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'deploymentschedule' && valueProp"
        [data]="valueProp"
    ></app-deployment-schedule>

    <app-scalers
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        [permissions]="permissions"
        (callbackExplained)="funcGotoExplain($event)"
        (callbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
        *ngIf="viewBottom == 'scalers' && valueProp"
        [data]="valueProp"

    ></app-scalers>

    <app-assumptions
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'allassumptions'"
        (callbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
        [valueProp]="valueProp"
        [permissions]="permissions"
    ></app-assumptions>
    <app-mission-statement
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'mission' && valueProp"
        [data]="valueProp"
    ></app-mission-statement>
    <app-initiatives
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'initiatives' && valueProp"
        [valueProp]="valueProp"
    ></app-initiatives>

    <app-assumptions
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'keyassumptions'"
        [valueProp]="valueProp"
        [keyAssumptions]="true"
        [permissions]="permissions"
    ></app-assumptions>

    <app-discovery-questions
        [showTranslate]="showTranslate"
        *ngIf="viewBottom == 'questions' && valueProp"
        [valuePropId]="valuePropId"
        [valueProp]="valueProp"
    ></app-discovery-questions>

    <app-problem [valueProp]="valueProp" *ngIf="viewBottom == 'problem' && valueProp"></app-problem>
    <app-solution [valueProp]="valueProp" *ngIf="viewBottom == 'solution' && valueProp"></app-solution>

    <app-assets
        [showTranslate]="showTranslate"
        [valueProp]="valueProp"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'assets' && valueProp"
        [valuePropId]="valuePropId"
        [showAllTcoMetrics]="showAllTcoMetrics"
    >
    </app-assets>
    <app-case-studies-prop
        [valueProp]="valueProp"
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'casestudies' && valueProp"
        [valuePropId]="valuePropId"
    ></app-case-studies-prop>
    <app-dashboard-collateral
        [can_edit]="valueProp.vp_can_edit"
        [valueProp]="valueProp"
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'collateral' && valueProp"
        [valuePropId]="valueProp.id"
    ></app-dashboard-collateral>
    <app-vpdashboard-assets-deliverables
        [valuePropId]="valuePropId"
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'deliverable' && valueProp"
    >
    </app-vpdashboard-assets-deliverables>

    <app-vpdashboard-assets-prompters
        [valuePropId]="valuePropId"
        [showTranslate]="showTranslate"
        [showHelp]="showHelp"
        *ngIf="viewBottom == 'prompter' && valueProp"
    >
    </app-vpdashboard-assets-prompters>
</div>
