import { Pipe, PipeTransform } from '@angular/core';

interface UnkownObject {
  [key: string]: unknown;
  disabled?: boolean;
}

@Pipe({
    name: 'populateDropdownDisabled'
})
export class PopulateDropdownDisabledPipe implements PipeTransform {
    
	transform(array: UnkownObject[]): UnkownObject[] {
    
    if(!array) {
      return array;
    }

    return array.map(item => {
      return {...item, disabled: true, label: item.name};
    });
  }
}
