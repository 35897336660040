import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { Workflow } from '@data/services/workflow/models/workflow.interface';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SimpleGuidedDiscoveryTranslation } from './simple-guided-discovery.translation';

@Component({
  selector: 'app-simple-guided-discovery',
  templateUrl: './simple-guided-discovery.component.html',
  styleUrls: ['./simple-guided-discovery.component.scss'],
})
export class SimpleGuidedDiscoveryComponent implements OnInit {
  @Output() workflowSelected = new EventEmitter<string>();
  @Input() workflows: Workflow[];
  showTranslate: boolean;
  ngUnsubscribe = new Subject();
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    public trans: SimpleGuidedDiscoveryTranslation,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  changeStep(workflow: Workflow): void {
    this.workflowSelected.emit(workflow.id);
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
