import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Sidebar } from 'primeng/sidebar';

@Component({
	selector: 'dl-sidebar',
	templateUrl: './sidebar.component.html',
	animations: [
		trigger('panelState', [
			state('hidden', style({
				opacity: 0
			})),
			state('visible', style({
				opacity: 1
			})),
			transition('visible => hidden', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
			transition('hidden => visible', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
		])
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DLSidebarComponent)
		}
	]
})

export class DLSidebarComponent extends Sidebar {
	@Input() message: string = "";
	@Input() dismissible: any;
	@Output() callback = new EventEmitter();

	funcCallback() {
		this.callback.emit();
	}
}