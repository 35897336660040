import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class BenefitFlyoutInputTableTranslation extends TranslationsBaseService {
  public config: any = {
    component: 'PerformanceBenefitTable',
    display_name: 'Table with benefits values and scratchpads for trackers',
    description: 'Table used to update the benefit performance of a tracker',
  };

  public trans = this.typeObjectKeys({
    formula: 'Formula',
    scratchpadSuccess: 'Scratchpad successfully saved',
    scratchpadError: 'There was an error while saving the scratchpad values',
    benefitPerformance: 'Benefit Performance successfully saved',
    scratchpadEdit: 'Editing Scratchpad',
    save: 'Save',
    periodOfPerformance: 'Period of Performance',
    valueAchieved: 'Value Achieved this Period',
    valueAchievedToDate: 'Value Achieved to Date',
    courseCorrection: 'Course Correction',
    varianceToDate: 'Variance to Date',
    expectedValueToDate: 'Expected Value to Date',
    areYouSure: 'Are you sure?',
    back: 'Back',
    achievedValue: 'Achieved Value',
    editScratchpad: 'Edit Scratchpad',
    total: 'Total',
    edit: 'Edit',
    saveChanges: 'Save Changes',
    cancelChanges: 'Cancel Changes',
    factorNameUpdated: 'Factor name updated',
    scratchpadNotInUse: 'scratchpad not in use',
    unsavedChanges: 'There are unsaved changes. Please save or discard before closing scratchpad'
  });
}
