import { NgModule } from '@angular/core';
import { RThresholdsComponent } from "./r_thresholds.component";
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { RThresholdTranslations } from './r_thresholds.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';


@NgModule({
	declarations: [
		RThresholdsComponent,
	],
	imports: [
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		SidebarModule,
		TableModule,
		NgbModule,
		CommonModule,
		EditTranslationsModule
	],
	providers: [
		RThresholdTranslations,
		TranslationsV2Service
	],
	entryComponents: [
		RThresholdsComponent,
	],
	exports: [RThresholdsComponent]
})
export class RThresholdsModule { }