import { Injectable } from '@angular/core';

@Injectable ()

export class ValueRealizationTranslations {

    public config: any = {
        component: 'valueRealizations',
        display_name: 'Value Realizations',
        description: 'Value realization list'
    };

    public trans: any = {
        add_new_value_realization: 'Add new value realization',
        yourValueRealization: 'Your Value Realizations (Different from VR from Insight)',
        yourValueRealizations: 'Your Value Realizations',
        addNewValueRealization: 'Add New Value Realization',
        youCanMeasureValueForAnyLockedValueProposition: '(You can measure value for any locked Business Case / Value Assessment)',
        search: 'Search',
        rowLimit: 'Row limit:',
        viewVp: 'View this Value Prop',
        company: 'Company',
        expectedTo: 'Expected To',
        realizedTo: 'Realized To',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
        searchName: 'Search By Name',
        searchAccount: 'Search By Account',
        searchCreator: 'Search By Creator',
        name: 'Name',
        companyname: 'Company',
        total_benefits_fmt: 'Expected to Date',
        improvementfmt: 'Realized to Date',
        row_limit: 'Row limit',
        
    };
}
