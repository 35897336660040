import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactorsService } from '@data/services/factors/factors.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { defaultFactor, Factor, FactorGroup } from '@data/services/valueposition/models/factor-group.interface';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './factors.translation';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-value-map-factors',
  templateUrl: './factors.component.html',
  styleUrls: ['./factors.component.scss'],
})
export class ValueMapFactorsComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @ViewChild('formAddEditFactorGroup') formAddEditFactorGroup: NgForm;
  @ViewChild('groupFilter') gorupFilter: HTMLInputElement;

  name = '';
  description = '';
  showAdd = false;
  factorGroups: FactorGroup[] = [];
  filteredFactorGroups: FactorGroup[] = [];
  rowLimit: MenuItem[] = [];
  account_id: string;
  strSearch = new FormControl('');

  menu: MenuItem[] = [];

  cols: Array<{ field: string; header: string }> = [
    { field: 'name', header: 'Name' },
    { field: 'created', header: 'Created On' },
  ];

  sidebar_title_factor = 'Add Factor';
  sidebar_title_factor_group = 'Add Factor Group';

  mode_factor: string = 'add';
  mode_group: string = 'add';

  toggleAddEditFactor: boolean = false;
  toggleAddEditFactorGroup: boolean = false;

  factorEdit: Factor = {};

  ngUnsubscribe$ = new Subject();

  formObj: { name: string; description: string; id: string } = {
    name: '',
    description: '',
    id: '',
  };

  formObjFactor: Factor = cloneDeep(defaultFactor);

  toggleOptions = false;
  expanded = false;
  rows = 10;
  tmpRows = 10;
  loading = true;
  showTranslate = false;
  style2022$: Observable<boolean>;
  loadingPages = false;

  constructor(
    private factorsService: FactorsService,
    private notificationService: NotificationService,
    private solutionService: SolutionService,
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');
    this.getFactorCategories();
    this.funcBuildPageLimit();

    this.solutionService.gotoAddFactor.subscribe(() => {
      this.openAddFactor();
    });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.strSearch.valueChanges.pipe(debounceTime(700), distinctUntilChanged()).subscribe(() => this.filterCategories());
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  closeAddEditFactor(): void {
    this.toggleAddEditFactor = false;
  }

  closeAddEditFactorGroup(): void {
    this.toggleAddEditFactorGroup = false;
  }

  filterCategories(): void {
    if (this.strSearch.value) {
      this.filteredFactorGroups = cloneDeep(this.factorGroups).map((category) => ({
        ...category,
        factors: category.factors.filter((factor) => factor.name.toLowerCase().includes(this.strSearch.value.toLowerCase())),
      }));
    } else {
      this.cleanFilter();
    }
  }

  getFactorCategories(): void {
    this.loading = true;
    this.factorsService
      .getFactorCategories(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.factorGroups = res && res.result && res.result.length ? res.result : [];

        this.factorGroups.map((elem) => {
          elem['strSearch'] = '';
          elem['collapsed'] = false;
          elem['factors'] = elem.factors.sort((factA, factB) => +factA.sequence - +factB.sequence);
          return elem;
        });
        this.filteredFactorGroups = cloneDeep(this.factorGroups);
        this.loading = false;
        this.solutionService.refreshModelsTotals.next();
        this.toggleAddEditFactor = false;
      });
  }

  funcToggleEdit(elem: Factor): void {
    this.factorEdit = elem;
    this.mode_factor = 'edit';
    this.sidebar_title_factor = 'Edit Factor';
    this.formObjFactor = Object.assign({}, elem);

    if (typeof elem.icon !== 'object') {
      this.formObjFactor.icon = {
        id: 0,
        value: elem.icon,
        label: '',
      };
    }
    this.toggleAddEditFactor = true;
  }

  funcToggleView(elem: Factor): void {
    this.toggleAddEditFactor = true;
    this.formObjFactor = elem;
    this.formObjFactor = Object.assign({}, elem);
  }

  toggleExpandCollapse(): void {
    this.expanded = !this.expanded;
    this.filteredFactorGroups.map((elem) => {
      elem['collapsed'] = this.expanded;
      return elem;
    });
    this.toggleOptions = false;
  }

  setRows(rows: number): void {
    this.loadingPages = true;
    this.rows = rows;
    this.tmpRows = this.rows;
    this.toggleOptions = false;
    setTimeout(() => {
      this.loadingPages = false;
      this.funcBuildPageLimit();
    }, 100);
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.rows == 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRows(10);
        },
      },
      {
        label: '25',
        icon: this.rows == 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRows(25);
        },
      },
      {
        label: '50',
        icon: this.rows == 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRows(50);
        },
      },
      {
        label: '100',
        icon: this.rows == 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRows(100);
        },
      },
    ];
  }

  openAddFactor() {
    this.mode_factor = 'add';
    this.sidebar_title_factor = this.trans.trans.add_factor_group.value;
    this.formObjFactor = cloneDeep(defaultFactor);
    this.toggleAddEditFactor = true;
  }

  openAddFactorGroup(): void {
    this.mode_group = 'add';
    this.sidebar_title_factor_group = this.trans.trans.add_factor_group.value;
    this.toggleAddEditFactor = false;
    this.toggleAddEditFactorGroup = true;
    if (this.formAddEditFactorGroup) {
      this.formAddEditFactorGroup.reset();
    }
  }

  deleteFactor(elem: Factor): void {
    this.factorsService
      .deleteFactorV2(elem.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result.success) {
          this.notificationService.success(response.result.message, false);
          this.getFactorCategories();
        } else if (!response.result.success) {
          this.notificationService.success(response.result.message, false);
        }
      });
  }

  toggleEditGroup(elem: FactorGroup): void {
    this.mode_group = 'edit';
    this.sidebar_title_factor_group = this.trans.trans.edit_group.value;
    if (this.formAddEditFactorGroup) {
      this.formAddEditFactorGroup.reset();
    }
    this.formAddEditFactorGroup.controls.name.setValue(elem.name);
    this.formAddEditFactorGroup.controls.description.setValue(elem.description);
    this.formAddEditFactorGroup.controls.id.setValue(elem.id);
    this.toggleAddEditFactorGroup = true;
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  insertFactorGroup(form: NgForm): void {
    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
    };

    this.factorsService
      .postFactorCategory(this.account_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.notificationService.success('Factor Group successfully created.', false);
        this.toggleAddEditFactorGroup = false;
        this.getFactorCategories();
      });
  }

  updateFactorGroup(form: NgForm): void {
    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
      account_factor_category_id: form.controls.id.value,
    };

    this.factorsService
      .putFactorGroup(this.account_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.notificationService.success('Factor Group updated', false);
        this.getFactorCategories();
        this.toggleAddEditFactorGroup = false;
      });
  }

  deleteFactorGroup(category: FactorGroup): void {
    this.factorsService
      .deleteFactorGroup(this.account_id, category.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.notificationService.success('Factor Group deleted', false);
        this.getFactorCategories();
      });
  }

  onCloseSideBar(refresh = false): void {
    this.toggleAddEditFactor = false;
    refresh && this.getFactorCategories();
  }

  toggleRowExtension(factor: Factor, type: string): void {
    if (factor.rowView === type) {
      factor.rowView = null;
    } else {
      factor.rowView = type;
    }
  }

  cleanFilter(): void {
    this.strSearch.patchValue('', { emitEvent: false });
    this.filteredFactorGroups = cloneDeep(this.factorGroups);
  }
}
