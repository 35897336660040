import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValuePositionComponent } from './value-position/value-position.component';
import { AddValuePositionComponent } from './add-value-position/add-value-position.component';
import { ValuePropDashboardComponent } from '../value-prop/value-prop-dashboard/dashboard_system/value-prop-dashboard.component';
import { DealdeskComponent } from './dealdesk/dealdesk.component';
import { AddMasterValuePropComponent } from './add-master-value-prop/add-master-value-prop.component';
import { DocusignComponent } from './guided-discovery/docusign/docusign.component';
import { DefaultTranslations } from './value-position/value-position.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RSV2CreateComponent } from 'app/repv2/views/create/create.component';

const routes: Routes = [
	{
		path: 'valueposition',
		component: ValuePositionComponent
	},
	{
		path: 'addValuePosition',
		component: AddValuePositionComponent,
	},
	{
		path: 'addMasterValueProp',
		component: AddMasterValuePropComponent,
	},
	{
		path: 'vmware/discovery',
		loadChildren: () => import('./guided-discovery/vmware/vmware.module').then(m => m.VMWareModule)
	},
	{
		path: 'masterValueProps/discovery',
		component: DocusignComponent
	},
	{
		path: 'dealdesk',
		component: DealdeskComponent,
	},
	{
		path: 'valueposition/repv2',
		component: RSV2CreateComponent,
	},
	{
		path: 'valueposition/:id',
		component: ValuePropDashboardComponent,
		data: { page: 'valuePropDashboard' }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [DefaultTranslations, TranslationsV2Service],
})
export class ValuePositionRoutingModule { }
