import { NgModule } from '@angular/core';

import { CollateralComponent } from "./collateral.component";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { Translations } from '@data/services/translation/translation.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { AECollateralModule } from './ae_collateral/ae_collateral.module';
import { RCollateralModule } from './r_collateral/r_collateral.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CollateralTranslations } from './collateral.translation';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		CollateralComponent,
	],
	imports: [
		AreYouSureModule,
		RCollateralModule,
		AECollateralModule,
		SidebarModule,
		TableModule,
        MenuModule,
		EditTranslationsModule,
		NgbModule,
		FormsModule,
		CommonModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		CollateralTranslations,
		Translations
	],
	entryComponents: [
		CollateralComponent,
	],
	exports: [CollateralComponent]
})
export class ModelCollateralModule { }