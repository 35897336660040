import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Breadcrumbs } from './breadcrumbs.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})

export class BreadcrumbsComponent {
  @Input() breadcrumbKeyValues: Breadcrumbs;
  keepOrder = (_a: KeyValue<string, { routerLink: string; active: boolean }>, _b: KeyValue<string, { routerLink: string; active: boolean }>): number => {
    return 1;
  };
}
