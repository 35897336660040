import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RSV2CostsSimpleModule } from 'app/repv2/views/costs_simple/costs.simple.module';
import { TrackerCostsComponent } from './tracker-costs.component';

@NgModule({
  imports: [CommonModule, RSV2CostsSimpleModule],
  exports: [TrackerCostsComponent],
  declarations: [TrackerCostsComponent],
  providers: [],
})
export class TrackerCostsComponentModule {}
