<div class="vmware-discovery">
	<div *ngIf="!toggleDiscovery">
		<form name="VmwareDiscoveryForm" #VmwareDiscoveryForm="ngForm" (ngSubmit)="ngOnSubmit()" *ngIf="!questionsLoader">
			<div class="row">
				<div class="col-md-10">
					<ng-container *ngFor="let question of questions">
						<div class="form-group clearfix">
							<label class="col-sm-6 col-form-label">
								{{question.question}}
								<i *ngIf="question.description" class="icon-info tooltip-custom" [matTooltip]="question.description" matTooltipPosition="right"></i>
							</label>

							<div class="col-sm-6" [ngSwitch]="question.question_type_id">
								<ng-container *ngSwitchCase="1">
									<select class="form-control" name="question_{{question.id}}" [(ngModel)]="question.value">
										<option disabled>{{ trans.General.ChooseOne | translate }}</option>
										<option *ngFor="let option of question.options" [value]="option.val">
											{{option.label}}
										</option>
									</select>
								</ng-container>

								<ng-container *ngSwitchCase="3">
									<ui-switch name="question_{{question.id}}" [(ngModel)]="question.value" checkedLabel="Yes" uncheckedLabel="No" color="rgb(102, 255, 153)"></ui-switch>
								</ng-container>

								<ng-container *ngSwitchDefault>
									<input type="text" class="form-control" name="question_{{question.id}}" [(ngModel)]="question.value" />
								</ng-container>
							</div>
						</div>
					</ng-container>

					<div class="form-group clearfix">
						<div class="col-sm-6 pull-left">
							<button type="submit" class="btn btn-primary">{{ trans.General.save | translate }}</button>
							<img class="actionLoader m-l-md" [src]="fullImagePath" *ngIf="questionsSaveLoader" />
						</div>
					</div>
				</div>
			</div>
		</form>

		<div *ngIf="questionsLoader" class="col-12 text-center m-t-lg">
			<img class="actionLoader" [src]="fullImagePath" />
		</div>
	</div>

	<div *ngIf="toggleDiscovery">
		<div class="addForm suggestedProjects m-t-lg">
			<label for="" class="control-label stepsheader">
				{{ trans.Vmware.based_on_your_initial_input_we_recommend_the_following_projects | translate }}
				{{ trans.Vmware.you_can_override_the_suggestion_and_add_subtract_project_as_appropriate | translate }}
			</label>
			<div class="table-responsive no-p">
				<table class="table">
					<thead>
						<tr>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let projects of suggestedProjects">
							<td>
								<div class="checkbox custom-checkbox">
									<label>
										<input type="checkbox" name="suggestedProjects{{projects.id}}" [(ngModel)]="projects.checked" />
										<span class="check"></span>
									</label>
								</div>
							</td>
							<td>{{projects.name}}</td>
							<td class="status">
								<i class="fa" [ngClass]="getStatusClass(projects.select_status)"></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div *ngIf="suggestedProjectsLoader" class="col-12 text-center m-t-lg">
				<img class="actionLoader" [src]="fullImagePath" />
			</div>

			<button type="button" class="btn btn-primary m-t-md" (click)="suggestedProjectsSave()">
				{{ trans.Vmware.continue | translate }}
			</button>
			<button type="button" class="btn btn-primary m-t-md" (click)="reset()">
				{{ trans.Vmware.reset | translate }}
			</button>
		</div>
	</div>
</div>