import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tracker-benefit',
  templateUrl: 'tracker-benefits.component.html',
  styleUrls: ['tracker-benefits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerBenefitComponent implements OnInit, OnDestroy, OnChanges {
  @Input('benefit') set benefitData(ben: any) {
    this.benefit = { ...ben };
  }
  @Input() valuePropId: any;
  @Input() term: string;
  @Input() can_edit: boolean = false;

  @Output() callback = new EventEmitter();

  ngUnsubscribe = new Subject();

  benefit: any;
  benefitTableData = [];
  benefitTableColumns = [];

  benefitSurveyTable = [];
  benefitSurveyTableColumns = [];
  feature14 = false;
  showCurrentEffort = false;
  hasProfit = false;
  editing_scratch = false;

  editExpected = false;
  performanceType = 2;
  surveyMetric: any;

  surveys: any[];

  chartColors: any;
  chartData: any;
  embedded = false;

  surveyNote: string;
  selectedCol: number = 0;
  selectedColPadding: any[] = [];
  selectedColLabelPadding: number = 0;
  selectedScratchpad: any;
  operatorList: any[] = [];
  scratchpadCalcTotalToggle: boolean = false;
  scratchpadCalcTotal: any;
  editBenefitName = true;
  isModelsV2 = true;
  style2022$: Observable<boolean>;

  constructor(
    private CommonService: CommonService,
    private valuePropService: ValuepropService,
    private valuerealizationService: ValuerealizationService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.isModelsV2 = this.CommonService.checkFeature(38);
  }

  ngOnChanges() {
    this.scratchpadOperatorTypes();
    this.feature14 = this.CommonService.checkFeature(14);
    this.chartColors = this.CommonService.getChartColors();

    this.hasProfit = this.benefit.expected_to_date - this.benefit.realized_to_date <= 0;

    this.buildChartData(this.benefit);

    this.funcBuildTableColumns();

    this.extractScratchpadData(this.benefit);

    this.getRecentSurveyData();

    this.surveys = this.benefit.survey_columns.map((surv) => Object.assign({}, surv));

    if (this.editExpected) {
      this.createBenefitSurveyDataTable();
    }
    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  funcGetScratchpadPadding(i, row) {
    let a = i - 2;
    this.selectedColLabelPadding = i + 1;
    this.selectedColPadding = [];
    this.selectedCol = a;
    for (let i = 0; i < this.selectedCol; i++) {
      this.selectedColPadding.push(i);
    }

    let s = i - 1;
    this.selectedScratchpad = row['year' + s + '_scratchpad'];

    this.calcScratchpad();
  }

  scratchpadOperatorTypes() {
    this.valuePropService
      .scratchpadOperatorTypes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.operatorList = response.result;
        }
      });
  }

  calcScratchpad() {
    let factor_id = [];
    let operand = [];
    let operator_id = [];
    let open_paren = [];
    let close_paren = [];

    this.selectedScratchpad.units.forEach((elem) => {
      factor_id.push(elem.factor_id);
      operand.push(elem.operand);
      operator_id.push(elem.operator_id);
      open_paren.push(elem.open_paren);
      close_paren.push(elem.close_paren);
    });
    let payload = {
      scratchpad_factor_id: this.selectedScratchpad.factor_id,
      scratchpad_unit_type_id: this.selectedScratchpad.unit_type_id,
      factor_id: factor_id.join(','),
      operand: operand.join(','),
      operator_id: operator_id.join(','),
      open_paren: open_paren.join(','),
      close_paren: close_paren.join(','),
    };

    this.valuerealizationService
      .postCalcScratchpadTotal(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.scratchpadCalcTotal = res.result.total;
        this.scratchpadCalcTotalToggle = true;
        this.cd.detectChanges();
      });
  }

  saveScratchpad() {
    let payload = {
      value_survey_id: this.selectedScratchpad.value_survey_id,
      units: this.selectedScratchpad.units,
    };

    this.valuerealizationService
      .postSaveScratchpadTotal(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success('Scratchpad saved successfully', false);
        this.callback.emit(this.benefit.account_solution_metric_id);
      });
  }

  funcSaveFactorName(data) {
    let id = '';
    switch (data.type) {
      case 'driver':
        id = data.driver_factor_id;
        break;
      case 'improvement':
        id = data.improvement_factor_id;
        break;
      case 'financial':
        id = data.financial_factor_id;
        break;
    }

    let payload = {
      factor_id: id,
      override_name: data.formula,
    };

    this.valuerealizationService
      .postSaveFactorName(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success('Factor name saved successfully', false);
        this.callback.emit(this.benefit.account_solution_metric_id);
      });
  }

  funcBuildTableColumns() {
    let cols = [];

    const term = Number(this.term);
    this.benefitTableColumns = [];
    cols.push({ field: 'formula', style: 'left', header: 'Formula', width: '450px', editable: false });

    cols.push({ field: 'calc', style: 'center', header: '+ / -', width: '40px', editable: false });
    if (!this.embedded) {
      if (this.showCurrentEffort && this.feature14) {
        cols.push({ field: 'cost', style: 'left', header: 'Current Effort', width: '120px', editable: true });
      }
      if (this.showCurrentEffort && this.feature14) {
        cols.push({ field: 'future_effort', style: 'left', header: 'With' + ' ', width: '120px', editable: true });
      }
      for (let i = 1; i <= term; i++) {
        let year = { field: 'year' + i, style: 'left', header: `Year${i}`, width: '120px', editable: i == 1 ? true : false };
        cols.push(year);
      }
    }
    this.benefitTableColumns = cols;
  }

  extractScratchpadData(ben) {
    this.checkCurrentEffort(ben.unit_type_id, ben.impact_type_id);
    const term = Number(this.term);
    this.benefitTableData = [];
    let r = {};

    if (this.benefit.driver_factor) {
      r = {};
      r['factorTypeId'] = 1;
      r['hasScratchpad'] = this.benefit.has_driver_scratchpad == 1 ? true : false;
      r['formula'] = this.benefit.driver_factor;
      r['cost'] = '';
      r['editable'] = true;
      r['type'] = 'driver';
      r['calc'] = 'x';
      r['description'] = this.benefit.driver_description;
      r['driver_factor_id'] = this.benefit.driver_factor_id;
      r['driver_source_type_id'] = this.benefit.driver_source_type_id;
      r['driver_override'] = this.benefit['year' + 1 + '_driver'];
      for (let i = 1; i <= term; i++) {
        r['year' + i + '_fmt'] = this.benefit['year' + i + '_driver_fmt'];
        r['year' + i] = i == 1 ? this.benefit['year' + i + '_driver'] : this.benefit['year' + i + '_driver_fmt'];
      }
      this.benefitTableData.push(r);
    }

    if (this.benefit.improvement_factor) {
      r = {};
      r = { formula: (this.benefit.improvement_factor ? this.benefit.improvement_factor : 'Improvement') + ' (' + this.benefit.unit_type + ')' };

      r['factorTypeId'] = 3;
      r['hasScratchpad'] = this.benefit.has_improvement_scratchpad == 1 ? true : false;
      r['editable'] = true;
      if (this.feature14) {
        r['cost'] = this.benefit.current_effort_fmt;
        r['cost_fmt'] = this.benefit.current_effort_fmt;
      }
      r['calc'] = 'x';
      r['type'] = 'improvement';
      r['future_effort'] = this.benefit.future_effort;
      r['future_effort_fmt'] = this.benefit.future_effort_fmt;
      r['description'] = this.benefit.improvement_description;
      r['improvement_factor_id'] = this.benefit.improvement_factor_id;
      r['impact'] = this.benefit.impact_fmt;
      r['impact_source_type_id'] = '1';
      r['value_prop_metric_id'] = this.benefit.value_prop_metric_id;

      for (let i = 1; i <= term; i++) {
        r['year' + i + '_fmt'] = this.benefit.impact_fmt + this.benefit.abbr;
        r['year' + i] = i == 1 ? this.benefit.impact_fmt : this.benefit.impact_fmt + this.benefit.abbr;
      }
      this.benefitTableData.push(r);
    }

    if (this.benefit.financial_factor) {
      r = {};
      r = { formula: this.benefit.financial_factor };
      r['factorTypeId'] = 2;
      r['hasScratchpad'] = this.benefit.has_financial_scratchpad == 1 ? true : false;
      r['editable'] = true;
      r['type'] = 'financial';
      r['calc'] = 'x';
      r['description'] = this.benefit.financial_description;

      r['financial_factor_id'] = this.benefit.financial_factor_id;
      r['financial_override'] = this.benefit.financial_override;
      r['financial_source_type_id'] = this.benefit.financial_source_type_id;

      r['cost'] = '';
      for (let i = 1; i <= term; i++) {
        r['year' + i + '_fmt'] = this.benefit.financial_value_fr;
        r['year' + i] = i == 1 ? this.benefit.financial_value : this.benefit.financial_value_fr;
      }
      this.benefitTableData.push(r);
    }
    if (!this.embedded) {
      r = {};
      r = { formula: 'Phasing' };

      r['calc'] = '=';
      r['cost'] = '';
      r['editable'] = false;
      r['hasScratchpad'] = false;
      for (let i = 1; i <= term; i++) {
        r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
        r['year' + i] = this.benefit['metric_phase_' + i] + '%';
      }
      this.benefitTableData.push(r);

      r = {};
      r = { formula: 'Total' };

      r['cost'] = '';
      r['type'] = 'total';
      r['hasScratchpad'] = false;
      r['editable'] = false;
      for (let i = 1; i <= term; i++) {
        r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
        r['year' + i] = this.benefit['year' + i + '_benefits_fmt'];
      }
      this.benefitTableData.push(r);
    }
  }

  checkCurrentEffort(unit_type, impact_type) {
    const utype = parseInt(unit_type);

    if (impact_type == 1 || utype === 8 || utype === 9 || utype === 10 || utype === 11 || utype === 12 || utype === 13) {
      this.showCurrentEffort = true;
    } else {
      this.showCurrentEffort = false;
    }
  }

  buildChartData(ben) {
    this.chartData = {
      labels: ben.expected_benefits.map((_exp, index) => index.toString()),
      datasets: [
        {
          label: 'Expected',
          data: ben.expected_benefits,
          fill: false,
          borderColor: this.chartColors[0],
        },
        {
          label: 'Achieved',
          data: ben.realized_benefits,
          fill: false,
          borderColor: this.chartColors[1],
        },
      ],
    };
  }

  determineGainLoss(num, num2 = 0) {
    let num1 = Number(num);
    let num22 = Number(num2);
    return num1 - num22 > 0;
  }

  funcCalcCostWithYear(row, changed) {
    if (changed == 'cost' || changed == 'future_effort') {
      row.year1 = parseFloat(row.cost) - parseFloat(row.future_effort);
    } else {
      row.future_effort = parseFloat(row.cost) - parseFloat(row.year1);
    }
  }

  funcShowHideInput(row, col) {
    if (row.type == 'improvement' && (col.field == 'cost' || col.field == 'future_effort')) {
      return true;
    }
    if (col.field == 'year1') {
      return true;
    }
    return false;
  }

  improvementKeyPress(col, rowData) {
    this.funcCalcCostWithYear(rowData, col.field);

    if (col.field == 'cost') {
      rowData['cost_updated'] = true;
    } else {
      if (col.field == 'future_effort') {
        rowData['future_effort_updated'] = true;
      } else {
        rowData['updated'] = true;
      }
    }
  }

  saveBenefitPerformance() {
    let payload = {
      value_prop_id: this.valuePropId,
      value_survey_id: this.benefit.recent_survey_id,
      value_survey_improvement: this.benefit.value_survey_improvement,
      value_performance_type_id: this.performanceType,
    };

    this.valuerealizationService
      .updateSurveyMetric(this.benefit.account_solution_metric_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notificationService.success('Value Metric updated successfully', false);
        this.callback.emit(this.benefit.account_solution_metric_id);
      });
  }

  getRecentSurveyData() {
    this.valuerealizationService
      .readValueRealizationSurvey(this.benefit.recent_survey_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.surveyMetric = res.result.value_survey_metrics.find((surv) => surv.account_solution_metric_id === this.benefit.account_solution_metric_id);
        if (this.surveyMetric) {
          this.performanceType = this.surveyMetric.value_performance_type_id;
          this.benefit.value_survey_improvement = this.surveyMetric.value_survey_improvement;
        }
      });
  }

  funcOpenBenefitSurveyTable() {
    this.editExpected = true;
    this.surveys = this.benefit.survey_columns.map((surv) => Object.assign({}, surv));
    this.buildBenefitSurveyDataTableRows();
    this.createBenefitSurveyDataTable();
  }

  funcCloseBenefitSurveyTable() {
    this.editExpected = false;
    this.surveys = [];
  }

  buildBenefitSurveyDataTableRows() {
    let cols = [];

    const term = this.surveys.length;
    this.benefitSurveyTableColumns = [];
    cols.push({ field: 'formula', style: 'left', header: 'Formula', width: '450px', editable: false });

    cols.push({ field: 'calc', style: 'center', header: '+ / -', width: '40px', editable: false });
    if (!this.embedded) {
      for (let i = 1; i <= term; i++) {
        let year = { field: 'year' + i, style: 'left', header: this.surveys[i - 1].name, width: '220px', editable: true, index: i - 1 };
        cols.push(year);
      }
    }
    this.benefitSurveyTableColumns = cols;
  }

  createBenefitSurveyDataTable() {
    this.benefitSurveyTable = [];

    const term = this.surveys.length;

    let r = {};

    r['formula'] = 'Period of Performance';
    r['editable'] = false;
    r['benefitField'] = 'period_of_performance';
    r['benefitField'].replace(' - ', "<b style='color: #f4f4f4;'> - </b>");
    for (let i = 1; i <= term; i++) {
      r['year' + i + '_fmt'] = this.surveys[i - 1]['period_of_performance'].replace(' - ', "<b style='color: #f4f4f4;'> - </b>");
      r['year' + i] =
        i == 1
          ? this.surveys[i - 1]['period_of_performance'].replace(' - ', "<b style='color: #f4f4f4;'> - </b>")
          : this.surveys[i - 1]['period_of_performance'].replace(' - ', "<b style='color: #f4f4f4;'> - </b>");
    }
    this.benefitSurveyTable.push(r);

    if (this.benefit.driver_factor) {
      r = {};
      r['factorTypeId'] = 1;
      r['formula'] = this.benefit.driver_factor;
      r['editable'] = true;
      r['type'] = 'driver';
      r['calc'] = 'x';

      r['description'] = this.benefit.driver_description;
      r['driver_factor_id'] = this.benefit.driver_factor_id;
      r['driver_source_type_id'] = this.benefit.driver_source_type_id;
      r['driver_override'] = this.benefit['year' + 1 + '_driver'];
      r['benefitField'] = 'value_survey_driver';
      for (let i = 1; i <= term; i++) {
        r['year' + i + '_has_scratchpad'] = this.surveys[i - 1].has_driver_scratchpad;
        r['year' + i + '_scratchpad_id'] = this.surveys[i - 1].driver_scratchpad_id;

        if (this.surveys[i - 1].has_driver_scratchpad) {
          r['year' + i + '_scratchpad'] = this.surveys[i - 1].driver_scratchpad;
        }

        r['year' + i + '_fmt'] = this.surveys[i - 1]['value_survey_driver'];
        r['year' + i] = i == 1 ? this.surveys[i - 1]['value_survey_driver'] : this.surveys[i - 1]['value_survey_driver'];
      }
      this.benefitSurveyTable.push(r);
    }

    if (this.benefit.improvement_factor) {
      r = {};
      r = { formula: (this.benefit.improvement_factor ? this.benefit.improvement_factor : 'Improvement') + ' (' + this.benefit.unit_type + ')' };
      r['factorTypeId'] = 3;
      r['hasScratchpad'] = this.benefit.has_improvement_scratchpad == 1 ? true : false;
      r['editable'] = true;
      if (this.feature14) {
        r['cost'] = this.benefit.current_effort_fmt;
        r['cost_fmt'] = this.benefit.current_effort_fmt;
      }
      r['calc'] = 'x';

      r['type'] = 'improvement';
      r['future_effort'] = this.benefit.future_effort;
      r['future_effort_fmt'] = this.benefit.future_effort_fmt;
      r['description'] = this.benefit.improvement_description;
      r['improvement_factor_id'] = this.benefit.improvement_factor_id;
      r['impact'] = this.benefit.impact_fmt;
      r['impact_source_type_id'] = '1';
      r['value_prop_metric_id'] = this.benefit.value_prop_metric_id;

      r['benefitField'] = 'value_survey_improvement';
      for (let i = 1; i <= term; i++) {
        r['year' + i + '_has_scratchpad'] = this.surveys[i - 1].has_improvement_scratchpad;
        r['year' + i + '_scratchpad_id'] = this.surveys[i - 1].improvement_scratchpad_id;

        if (this.surveys[i - 1].has_improvement_scratchpad) {
          r['year' + i + '_scratchpad'] = this.surveys[i - 1].improvement_scratchpad;
        }

        r['year' + i + '_fmt'] = this.surveys[i - 1].value_survey_improvement + this.benefit.abbr;
        r['year' + i] = i == 1 ? this.surveys[i - 1].value_survey_improvement : this.surveys[i - 1].value_survey_improvement + this.benefit.abbr;
      }
      this.benefitSurveyTable.push(r);
    }

    if (this.benefit.financial_factor) {
      r = {};
      r = { formula: this.benefit.financial_factor };
      r['factorTypeId'] = 2;
      r['hasScratchpad'] = this.benefit.has_financial_scratchpad == 1 ? true : false;
      r['editable'] = true;
      r['type'] = 'financial';
      r['calc'] = 'x';

      r['description'] = this.benefit.financial_description;

      r['financial_factor_id'] = this.benefit.financial_factor_id;
      r['financial_override'] = this.benefit.financial_override;
      r['financial_source_type_id'] = this.benefit.financial_source_type_id;
      r['cost'] = '';
      r['benefitField'] = 'value_survey_financial';
      for (let i = 1; i <= term; i++) {
        r['year' + i + '_has_scratchpad'] = this.surveys[i - 1].has_financial_scratchpad;
        r['year' + i + '_scratchpad_id'] = this.surveys[i - 1].financial_scratchpad_id;

        if (this.surveys[i - 1].has_financial_scratchpad) {
          r['year' + i + '_scratchpad'] = this.surveys[i - 1].financial_scratchpad;
        }

        r['year' + i + '_fmt'] = this.surveys[i - 1].value_survey_financial;
        r['year' + i] = i == 1 ? this.surveys[i - 1].value_survey_financial : this.surveys[i - 1].value_survey_financial;
      }
      this.benefitSurveyTable.push(r);

      r = {};
      r = { formula: 'Incremental value this survey period' };

      r['cost'] = '';
      r['type'] = 'total';
      r['hasScratchpad'] = false;
      r['editable'] = false;
      for (let i = 1; i <= term; i++) {
        r['year_' + i + '_fmt'] = this.surveys[i - 1]['single_survey_total_fmt'];
        r['year' + i] = this.surveys[i - 1]['single_survey_total_fmt'];
      }
      this.benefitSurveyTable.push(r);

      r = {};
      r = { formula: 'Total since contract date' };

      r['cost'] = '';
      r['type'] = 'total';
      r['hasScratchpad'] = false;
      r['editable'] = false;
      for (let i = 1; i <= term; i++) {
        r['year_' + i + '_fmt'] = this.surveys[i - 1]['survey_metric_total_fmt' + i];
        r['year' + i] = this.surveys[i - 1]['survey_metric_total_fmt'];
      }
      this.benefitSurveyTable.push(r);
    }
  }

  saveBenefitSurveyData() {
    let payload = {
      value_prop_id: this.valuePropId,
      surveys: this.surveys,
    };

    this.valuerealizationService
      .updateValueSurveyMetricFresh(this.benefit.account_solution_metric_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.result.success) {
          this.notificationService.success('Benefit updated successfully', false);
          this.callback.emit(this.benefit.account_solution_metric_id);
        }
      });
  }

  saveNote() {
    let payload = {
      value_survey_id: this.benefit.recent_survey_id,
      account_solution_metric_id: this.benefit.account_solution_metric_id,
      survey_note: this.benefit['value_survey_notes '],
    };
    this.valuerealizationService
      .addValueRealizationBenefitNote(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.result.success) {
          this.notificationService.success('Survey note was successfully saved', false);
          this.callback.emit(this.benefit.account_solution_metric_id);
        }
      });
  }

  editName() {
    this.editBenefitName = !this.editBenefitName;
  }

  overrideBenefitName(newName: string) {
    this.valuerealizationService
      .overrideValueRealizationName({
        name_override: newName,
        account_solution_metric_id: this.benefit.account_solution_metric_id,
        value_prop_id: this.valuePropId,
      })
      .subscribe((response) => {
        if (response.result.value) {
          this.benefit.metric_name = newName;
          this.editName();
          this.cd.markForCheck();
        }
      });
  }

  reloadVP() {
    this.callback.emit(this.benefit.account_solution_metric_id);
  }
}
