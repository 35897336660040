import { Injectable } from '@angular/core';

@Injectable ()

export class InitiativeVpDashTranslations {

    public config: any = {
        component: 'initiativesVpDash',
        display_name: 'Value Prop Initiatives',
        description: 'Initiatives tab in value prop dashboard'
    };

    public trans: any = {
        ch_initiatives: `Projects and priorities of the account to which you can align and drive impact.`,
        add: 'Add',
        update: 'Update',
        ImpactArea: 'Impact Area',
        name: 'Name',
        NoInitiavesFound: 'No initiatives found',
        DeleteImpact: 'Delete impact',
        RemoveImpactWarning: 'Remove impact',
        AddInitiatives: 'Add Initiatives',
        Selectimpact: 'Select Impact',
        Initiativeisrequired: 'Initiative is required',
        UpdateInitiatives: 'Update Initiative',
        initiatives: 'Initiatives',
        delete: 'Delete',
        save: 'Save '
    };
}
