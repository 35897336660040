<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ benefit.metric_name }}
  </div>

  <div class="sidebar_container">
    <div class="keywest_panel">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<ng-template #goalMessage>
    <div class="row margin-15-y">
        <div class="col-md-12">
            <p class="content-box">{{trans.trans.missingGoalMessage.value}}</p>
        </div>
    </div>
</ng-template>