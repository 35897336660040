<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{sidebar_title}}
	</div>
	<div class='sidebar_container'>
		<div class='keywest_panel'>
			<div class="row">
				<div class="col-md-12">
					<label for="category" class="sidebar_label_readonly">{{trans.trans.name.value}}</label><br>
					{{selectedElem.category}}
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<label for="description" class="sidebar_label_readonly">{{trans.trans.description.value}}</label><br>
					{{selectedElem.description}}
				</div>
			</div>
		</div>
	</div>
</div>