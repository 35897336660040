import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { Subscription, Subject } from 'rxjs';
import { DeploymentScheduleVpDashTranslations } from './deployment-schedule.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { ValueProp } from '@shared/models/value-prop.model';

interface DeploymentSchedule {
  yr1_percent?: string | number;
  yr2_percent?: string | number;
  yr3_percent?: string | number;
  yr4_percent?: string | number;
  yr5_percent?: string | number;
  value_prop_id?: string | number;
  name?: string;
}

interface NetPercents extends DeploymentSchedule {
  net_percents: DeploymentSchedule;
  cumulative_values: DeploymentSchedule;
  net_values: DeploymentSchedule;
}

@Component({
	selector: 'app-deployment-schedule',
	templateUrl: './deployment-schedule.component.html'
})
export class DeploymentScheduleComponent implements OnInit, OnDestroy {
	@Input() showHelp;
	@Input('data') valueProp: ValueProp;
	@Input() dealdesk: boolean;
	@Input() tabInit: number;
	@Input() contextualHelp: any;
	@Output() deploymentScheduleEmitter = new EventEmitter();
	@Input() showTranslate = false;

	ngUnsubscribe = new Subject();
	deploymentSchedule: DeploymentSchedule;
	netPercents: NetPercents;
	term: number;
	graduatedDeploymentLoader = true;
	image_url: string;
	fullImagePath: string;
	showDeployment = false;
	subscriptionrefreshDashboard: Subscription;
	subscriptiongetValuePropDetail: Subscription;
	subscriptionreadDeploymentSchedule: Subscription;
	subscriptionreadNetPercents: Subscription;
	subscriptioncreateDeploymentSchedule: Subscription;
	graduatedRadio: string;
	cumulative_values: boolean | number | string;
	net_values: boolean | number | string;

	constructor(
		private valuepropService: ValuepropService,
		private commonService: CommonService,
		private notificationService: NotificationService,
		private translationService: TranslationsV2Service,
		public trans: DeploymentScheduleVpDashTranslations,

	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit(): void {
		this.getTranslations();

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
		this.graduatedDeploymentLoader = false;

    if (!this.dealdesk) {
			this.loadDeployment();
		}

		this.subscriptionrefreshDashboard = this.valuepropService.refreshDashboard.subscribe(() => {
			this.loadDeployment();
		});
		this.readDeploymentSchedule();
		this.readNetPercents();
	}

	ngOnDestroy(): void {
		if (this.subscriptionrefreshDashboard) { this.subscriptionrefreshDashboard.unsubscribe(); }
		if (this.subscriptiongetValuePropDetail) { this.subscriptiongetValuePropDetail.unsubscribe(); }
		if (this.subscriptionreadDeploymentSchedule) { this.subscriptionreadDeploymentSchedule.unsubscribe(); }
		if (this.subscriptionreadNetPercents) { this.subscriptionreadNetPercents.unsubscribe(); }
		if (this.subscriptioncreateDeploymentSchedule) { this.subscriptioncreateDeploymentSchedule.unsubscribe(); }

		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans.trans
		};

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	loadDeployment(): void {
		this.subscriptiongetValuePropDetail = this.valuepropService.getValuePropDetail(this.valueProp.id).subscribe((response) => {
			if (response.result) {
				this.valueProp = response.result.value_prop;

				this.term = +this.valueProp.term;
				this.readDeploymentSchedule();
				this.readNetPercents();
			}
		});

		if (!this.dealdesk) {
			this.showDeployment = true;
		}
	}

	promptForDeployment(answer): void {
		if (answer) {
			this.showDeployment = true;
			this.graduatedDeploymentLoader = true;
			this.graduatedRadio = 'yes';
			this.loadDeployment();
		} else {
			this.showDeployment = false;
			this.graduatedRadio = 'no';
			this.graduatedDeploymentLoader = false;
			this.deploymentScheduleEmitter.emit();
		}

	}

	readDeploymentSchedule(): void {
		this.graduatedDeploymentLoader = true;
		this.subscriptionreadDeploymentSchedule = this.valuepropService.readDeploymentSchedule(this.valueProp.id).subscribe(response => {
			this.deploymentSchedule = response.result.deployment_schedule;
			this.deploymentSchedule.value_prop_id = this.valueProp.id;
			this.cumulative_values = response.result.cumulative_values;
			this.net_values = response.result.net_values;
			this.graduatedDeploymentLoader = false;
		});
	}

	readNetPercents(): void {
		this.subscriptionreadNetPercents = this.valuepropService.readNetPercents(this.deploymentSchedule).subscribe(response => {
			this.netPercents = response.result;

			this.graduatedDeploymentLoader = false;
		});
	}

	updateGraduatuedDeplyment(): void {
		this.graduatedDeploymentLoader = true;

		this.subscriptioncreateDeploymentSchedule = this.valuepropService.createDeploymentSchedule(this.valueProp.id, this.deploymentSchedule).subscribe(response => {
			if (response.result && (response.result.success !== false)) {

				this.readDeploymentSchedule();
				this.readNetPercents();

				if (this.dealdesk) {
					this.deploymentScheduleEmitter.emit();
				}

				this.valuepropService.refreshDashboard.next('refreshDashboard');
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false);
			}
			this.graduatedDeploymentLoader = false;

		});
	}

}
