import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DashboardBottomComponent } from './bottom.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'primeng/sidebar';
import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ExplainedModule } from '../explained/explained.module';
import { AddBenefitsModelComponent } from '../../value-prop-dashboard/add-benefits-model/add-benefits-model.component';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { AdvancedComponent } from '../scenario/advanced/advanced.component';
import { BenefitsModule } from '../benefits/benefits.module';
import { AddBenefitsModule } from '../../value-prop-dashboard/add-benefits-component/add-benefits-component.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { DeploymentScheduleModule } from 'app/value-position/deployment-schedule/deployment-schedule.module';
import { CostsModule } from '../../value-prop-dashboard/costs/costs.module';
import { ScalersComponent } from '../../value-prop-dashboard/discovery/scalers/scalers.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AssumptionsModule } from '../../value-prop-dashboard/discovery/assumptions/assumptions.module';
import { MissionStatementComponent } from '../../value-prop-dashboard/discovery/mission/mission.component';
import { InitiativesComponent } from '../../value-prop-dashboard/discovery/initiatives/initiatives.component';
import { KeyDiscoveryComponent } from '../../value-prop-dashboard/discovery/key_discovery/key_discovery.component';
import { DiscoveryQuestionsModule } from '../discovery/discovery_questions/questions.module';
import { CaseStudiesComponent } from '../../value-prop-dashboard/case-studies/case-studies.component';
import { DashboardCollateralModule } from '../collateral/collateral.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { BenefitDetailsModule } from '../benefits/benefit-detail/benefit-detail.module';
import { BenefitsMiniModule } from '../benefits/benefits-mini/mini.module';
import { AccountModule } from '../scenario/account/account.module';
import { ModelsModule } from '../scenario/models/models.module';
import { BenefitsV2Module } from '../benefits_v2/benefits.module';
import { BenefitsMiniV2Module } from '../benefits_v2/views/mini/mini.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { VPDashboardAssetsDeliverableModule } from '../assets_customer_deliverable/deliverable.module';
import { AssetsValuePrompterModule } from '../assets-value-prompter/assets-value-prompter.module';
import { ModelAEBenefits } from 'app/value-mapv2/solution-detail/benefits/ae_benefits/ae_benefits.module';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { ProblemModule } from '../discovery/problem/problem.module';
import { SolutionModule } from '../discovery/solution/solution.module';
import { AssetsModule } from '../assets/assets.module';
import { BottomPanelTranslations } from './bottom.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AdvancedVpDashboardTranslations } from '../scenario/advanced/advanced.translation';
import { ScalersVpDashTranslations } from '../discovery/scalers/scalers.translation';
import { MissionVpDashTranslations } from '../discovery/mission/mission.translation';
import { InitiativeVpDashTranslations } from '../discovery/initiatives/initiatives.translation';
import { CaseStudiesVpDashTranslations } from '../case-studies/case-studies.translation';
import { AssumptionsFastFillModule } from '../discovery/fast_fill/assumptions.module';
import { MaturityModule } from '../maturity/maturity.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ModelCaseStudyDetailModule as ModelCaseStudyDetailV2 } from 'app/value-mapv2/solution-detail/case-studies/casestudy-detail/casestudy-detail.module';
import { SituationsSharedModule } from '@shared_components/situations-shared/situations-shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputNumberModule } from 'primeng/inputnumber';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		MaturityModule,
		AssetsModule,
		ProblemModule,
		SolutionModule,
		DLMenubarModule,
		AssumptionsFastFillModule,
        MatTooltipModule,
        InputNumberModule,
		AssetsValuePrompterModule,
		VPDashboardAssetsDeliverableModule,
		AddBenefitsModule,
		AutoCompleteModule,
		BenefitsMiniV2Module,
		BenefitsV2Module,
		BenefitsMiniModule,
		BenefitDetailsModule,
		DashboardCollateralModule,
		ModelCaseStudyDetailV2,
		AssumptionsModule,
		DeploymentScheduleModule,
		CostsModule,
		ExplainedModule,
		CommonModule,
		FormsModule,
		ApplicationPipesModule,
		EditTranslationsModule,
		DropdownModule,
		FieldsetModule,
		SidebarModule,
		NgbModule,
		BenefitsModule,
		MultiSelectModule,
		OverlayPanelModule,
		DiscoveryQuestionsModule,
		ProgressBarModule,
		AccountModule,
		ModelsModule,
		ModelAEBenefits,
		DropdownModule,
        CurrencyMaskModule,
        SituationsSharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader
            }
        }),
    ],
	declarations: [
		CaseStudiesComponent,
		KeyDiscoveryComponent,
		InitiativesComponent,
		MissionStatementComponent,
		ScalersComponent,
		AdvancedComponent,
		AddBenefitsModelComponent,
		DashboardBottomComponent
	],
	providers: [
		Translations,
		TranslationsV2Service,
		BottomPanelTranslations,
		AdvancedVpDashboardTranslations,
		ScalersVpDashTranslations,
		MissionVpDashTranslations,
		InitiativeVpDashTranslations,
		CaseStudiesVpDashTranslations,
	],
	exports: [DashboardBottomComponent, CaseStudiesComponent, AdvancedComponent, ScalersComponent, MissionStatementComponent, InitiativesComponent]
})
export class DashboardBottomModule { }
