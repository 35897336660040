import { Injectable } from '@angular/core';

@Injectable()
export class FactorScaledByTranslations {
  public config: any = {
    component: 'modelsFactorsScaled',
    display_name: 'Factors Scaled By',
    description: 'Scaled by component in model factor groups'
  };

  public trans: any = {
    scaled_by: 'Scaled By',
    scales_how: 'Scales How?',
    ratio: 'Ratio',
    formula: 'Formula',
  };
}
