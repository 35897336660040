<div class="">
	<div class="table-responsive col-sm-12 drivers-table">
		<div class="">
			<div class="">
				<table id="drivers" class="table custom-table dataTable">
					<thead>
						<tr>
							<th>{{ 'props.tabDetails.discovery.assumptions.DriverFactor' | translate }}</th>
							<th class="text-right">{{ 'props.tabDetails.discovery.assumptions.DriverValue' | translate }}</th>
							<th *ngIf="!isCustomerShareType">
								{{ 'props.tabDetails.discovery.assumptions.DriverSourceType' | translate }}</th>
						</tr>
					</thead>
					<tbody *ngIf="drivers && drivers!==null && drivers.length>0">
						<tr *ngFor="let driver of drivers" class="grid-row">
							<td>
								{{driver.driver_factor}}
							</td>
							<td class="text-right preventWordBreak">
								<span (click)="driver.showeditable = !driver.showeditable" *ngIf="!driver.showeditable && driver.driver_value" class="editable">
									{{driver.driver_value}}
								</span>
								<div *ngIf="driver.showeditable" class="editableBox">
									<input type="number" name="driverFactor" [(ngModel)]="driver.driver_value">
									<button (click)="editDriverValue(driver); driver.showeditable = !driver.showeditable;" class="btn btn-primary">
										<i class="fa fa-check"></i>
									</button>
									<button (click)="driver.showeditable = !driver.showeditable" class="btn btn-default">
										<i class="fa fa-times"></i>
									</button>
								</div>
							</td>

							<td *ngIf="!isCustomerShareType">
								<span (click)="driver.showeditableSourceType = !driver.showeditableSourceType"
									*ngIf="!driver.showeditableSourceType"
									class="editable">
									{{driver.driver_source}}
								</span>

								<div class="batchEdit editableBox" *ngIf="driver.showeditableSourceType">
									<select class="form-control m-t-xxs" name="driverFactorSourceType"
										[(ngModel)]="driver.driver_source_type_id">
										<option *ngFor="let s of sourceTypes" [ngValue]="s.id">
											{{s.name}}
										</option>
									</select>
								</div>
								<div *ngIf="driver.showeditableSourceType" class="editableBox">
									<select class="form-control m-t-xxs" name="driverFactorSourceType"
										[(ngModel)]="driver.driver_source_type_id">
										<option *ngFor="let s of sourceTypes" [ngValue]="s.id">
											{{s.name}}
										</option>
									</select>
									<button
										(click)="editDriverValue(driver); driver.showeditableSourceType = !driver.showeditableSourceType;"
										class="btn btn-primary">
										<i class="fa fa-check"></i>
									</button>
									<button (click)="driver.showeditableSourceType = !driver.showeditableSourceType"
										class="btn btn-default">
										<i class="fa fa-times"></i>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="driversLoader" class="col-sm-12 m-t-md text-center">
				<p class="text-center">
					<img class="loader" src="{{fullImagePath}}" />
				</p>
			</div>
			<div *ngIf="(drivers==null || drivers.length==0) && !driversLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4>{{ 'props.tabDetails.discovery.assumptions.NoDriversFound' | translate }}</h4>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="">
	<div class="table-responsive col-sm-12 financials-table">
		<div class="">
			<div class="">
				<table id="financials" class="table custom-table dataTable">
					<thead>
						<tr>
							<!-- <th style='width: 40px;'></th> -->
							<th>{{ 'props.tabDetails.discovery.assumptions.FinancialFactor' | translate }}</th>
							<th class="text-right">{{ 'props.tabDetails.discovery.assumptions.FinancialValue' | translate }}</th>
						</tr>
					</thead>
					<tbody *ngIf="financials && financials!==null && financials.length>0">
						<tr *ngFor="let financial of financials" class="grid-row">
							<!-- <td><i class='fa fa-star clickable' (click)='toggleStared(financial, 4)' [ngStyle]="{'color': financial.is_key == 1 ? 'gold' : 'grey'}"></i></td> -->
							<td>
								{{financial.financial_factor}}
								<i class="icon-info m-l-xxs tooltip-custom" [ngbTooltip]="tipContentMetric" container="body" placement="right"></i>
								<ng-template #tipContentMetric class="toolTipContent">
									<p>{{ 'props.tabDetails.discovery.assumptions.Includedbenefits' | translate }}:</p>
									<ul *ngFor="let metric of financial.metrics">
										<li>{{metric.metric_name}}</li>
									</ul>
								</ng-template>
							</td>
							<td class="text-right preventWordBreak">
								<span (click)="financial.showeditable = !financial.showeditable" *ngIf="!financial.showeditable && financial.financial_value" class="editable">
									{{financial.financial_value}}
								</span>
								<div *ngIf="financial.showeditable" class="editableBox">
									<input type="number" name="financialFactor" [(ngModel)]="financial.financial_value">
									<button (click)="editFinancialValue(financial); financial.showeditable = !financial.showeditable;" class="btn btn-primary">
										<i class="fa fa-check"></i>
									</button>
									<button (click)="financial.showeditable = !financial.showeditable" class="btn btn-default">
										<i class="fa fa-times"></i>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="financialsLoader" class="col-sm-12 m-t-md text-center">
				<p class="text-center">
					<img class="loader" src="{{fullImagePath}}" />
				</p>
			</div>
			<div *ngIf="(financials==null || financials.length==0) && !financialsLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4>{{ 'props.tabDetails.discovery.assumptions.NoFinancialsFound' | translate }}</h4>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="">
	<div class="table-responsive col-sm-12 dataTable">
		<div class="">
			<div class="">
				<table class="table custom-table">
					<thead>
						<tr>
							<!-- <th style='width: 40px;'></th> -->
							<th>{{ 'props.tabDetails.discovery.assumptions.ScratchpadUnits' | translate }}</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody *ngIf="scratchPads && scratchPads!==null && scratchPads.length>0">
						<tr *ngFor="let scratchPad of scratchPads" class="grid-row">
							<!-- <td><i class='fa fa-star' (click)='toggleStared(scratchPad, 4)' [ngStyle]="{'color': scratchPad.is_key == 1 ? 'gold' : 'grey'}"></i></td> -->
							<td>
								{{scratchPad.name}}
								<i class="icon-info m-l-xxs tooltip-custom" [ngbTooltip]="tipContentMetric" container="body" placement="right"></i>
								<ng-template #tipContentMetric class="toolTipContent">
									<p>{{ 'props.tabDetails.discovery.assumptions.Includedbenefits' | translate }}:</p>
									<ul *ngFor="let metric of scratchPad.metrics">
										<li>{{metric.metric_name}}</li>
									</ul>
								</ng-template>
							</td>
							<td class="text-right preventWordBreak">
								<span (click)="scratchPad.showeditable = !scratchPad.showeditable" *ngIf="!scratchPad.showeditable && scratchPad.operand" class="editable">
									{{scratchPad.operand}}
								</span>
								<div *ngIf="scratchPad.showeditable" class="editableBox">
									<input type="number" name="scratchpadUnit" [(ngModel)]="scratchPad.operand">

									<button (click)="scratchPad.showeditable = !scratchPad.showeditable" class="btn btn-default">
										<i class="fa fa-times"></i>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="scratchPadLoader" class="col-sm-12 m-t-md text-center">
				<p class="text-center">
					<img class="loader" src="{{fullImagePath}}" />
				</p>
			</div>
			<div *ngIf="(scratchPads==null || scratchPads.length==0) && !financialsLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4>{{ 'props.tabDetails.discovery.assumptions.NoScratchpadUnitsFound' | translate }}</h4>
				</div>
			</div>
		</div>
	</div>
</div>