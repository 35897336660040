



	<div class="sidebar_wrapper">
		<div class='sidebar_header'>
			Download
		</div>
		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<div *ngIf="mappedAssetList.length > 0">
					<div class='row' *ngFor="let asset of mappedAssetList">
						<div class='col-xs-12'>
							<a [href]='asset.url' target='_blank'><i [class]='asset.icon' [ngStyle]="getFAColor(asset.format)"></i> {{asset.label}}</a><br>
							<small style='font-size: 70%;'>{{asset.description}}</small>
						</div>
					</div>
				</div>			
			</div>
		</div>
	</div>