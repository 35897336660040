import { cloneDeep } from 'lodash';
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { BusinessFactor } from '@data/services/goal-groups/models/business-goals-response.interface';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';
import { NotificationService } from '@services/notification.service';
import { ConnectedObject, SimpleGoalReactiveForm, ValuePropGoal, ValueRealizationGoal, ValueRealizationGoalGroup } from '@shared/models/goal-group.model';
import { UserInfo } from '@shared/models/user-info.model';
import { smallLineChartConf } from 'app/value-realized/chartConfigs/small-linechart';
import { ChartData, ChartOptions } from 'chart.js';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { takeUntil, take, finalize, filter } from 'rxjs/operators';
import { TrackValueDashboardTranslation } from '../track-value-dashboard/track-value-dashboard.translation';
import { TrackValueGoalTabTranslations } from './track-value-goal-tab.translation';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { BusinessReviewUpdate, FactorUpdate } from '@data/services/goal-groups/models/business-goals-update.interface';
import { SurveyFactor } from '@data/services/goal-groups/models/business-goals-response.interface';
import { SortEvent } from 'primeng/api';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { APIResult } from '@shared/models/api-result.model';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
export type TranslationStrings = keyof typeof TrackValueDashboardTranslation.prototype.trans;

@Component({
  selector: 'app-track-value-goal-tab',
  templateUrl: './track-value-goal-tab.component.html',
  styleUrls: ['./track-value-goal-tab.component.scss'],
})
export class TrackValueGoalTabComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @ViewChild('op', { static: false }) overlayPanel: OverlayPanel;

  goalList: ValuePropGoal[];
  goalListCache: ValuePropGoal[];
  endDateGroup = this.fb.group({
    endDate: ['', Validators.required],
  });
  showEndDateInput = false;
  selectedGoalOrignal: BusinessFactor;
  loading = false;
  cols: { header: string; sortBy?: string; field: string; class?: string; dynamicIndex?: number; width?: string }[];
  showInputs = false;
  reviewGoalSidebar = false;
  tableColumns: { header: string; sortBy?: string; field: string; class?: string; dynamicIndex?: number; width?: string; businessReviewId?: number }[] = [
    { header: 'Goal Name', sortBy: 'factor_name', field: 'factor_name', class: 'clickable' },
    { header: 'Unit Type', sortBy: 'unit_type_id', field: 'unit_type_id', width: 'unit-type' },
    { header: 'Baseline Value', field: 'baseline_value_fmt' },
    { header: 'Target Value', field: 'value_fmt' },
    { header: 'Trend', field: 'trendChart' },
  ];

  selectedGoal: SimpleGoalReactiveForm;
  goalTrendOptions: ChartOptions;
  goalsTrend: ChartData[];
  allTrendData = [];
  chartColors: string[] = [];
  numberLocales = ['en-US', 'en-US', 'de-DE', 'fr-FR'];
  userData: UserInfo;

  ngUnsubscribe = new Subject();
  businessReview: BusinessReviewData;
  businessReviewCount: number;
  businessReviewIndex: number;
  newlyCreatedReview = false;
  businessReviewData$: Observable<BusinessReviewData[]>;
  disableBusinessReview$: Observable<boolean>;
  businessReviewData: BusinessReviewData[] = [];
  goalGroups: ValueRealizationGoalGroup[];
  goalGroupArrays: FormArray[] = [];
  public t: { [key in TranslationStrings]: string };
  public tableHeaderFormArray: FormArray = this.fb.array([]);
  public tableHeaderFormArrays: FormArray[] = [];
  public tableHeaderIndexMap = new Map();
  canEdit = false;
  scratchpadEdit = false;
  tableRefreshing$ = new BehaviorSubject<boolean>(false);
  reviewGroupSize = 4;
  savingEndDate = false;
  addingNewData = false;
  reviewToDelete: BusinessReviewData = undefined;

  constructor(
    public trans: TrackValueDashboardTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private goalGroupsService: GoalGroupsService,
    private userService: UserService,
    private notificationService: NotificationService,
    public goalTrans: TrackValueGoalTabTranslations,
    public businessReviewService: TrackerBusinessReviewService,
    private fb: FormBuilder,
    private valuepropService: ValuepropService,
    private valueRealizationService: ValuerealizationService
  ) {}

  ngOnInit(): void {
    this.businessReviewService.toggleEdition(false);
    this.chartColors = this.commonService.getChartColors();
    this.disableBusinessReview$ = this.businessReviewService.businessReviewEdition;
    this.businessReviewData$ = this.businessReviewService.businessReviews$;
    this.getBusinessReviewsData();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.setBusinessReviewObserver();
    this.setEditObserver();
    this.businessReviewService.canEdit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((canEdit) => {
      this.canEdit = canEdit;
    });
    this.valueRealizationService.refreshDashboardData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (true) {
        this.getGoals(this.valuePropId);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  editBusinessReview(): void {
    this.businessReviewService.toggleEdition(true);
  }

  setReviewToDelete() {
    this.reviewToDelete = this.businessReviewData.slice(-1)[0];
  }

  cancelDeleteLatestReview() {
    this.reviewToDelete = undefined;
  }

  deleteLatestReview() {
    this.goalGroupsService
    .deleteBusinessReview(this.reviewToDelete.id)
    .pipe(take(1))
    .subscribe(() => {
      this.businessReviewService.deleteBusinessReview(this.reviewToDelete.id);
      this.toggleInputs(false);
      this.newlyCreatedReview = false;
      this.reviewToDelete = null;
    });
  }

  getBusinessReviewsData(callback?: Function): void {
    this.businessReviewService
      .businessReviewData()
      .pipe(
        take(1),
        finalize(() => {
          if (callback) {
            callback(0);
          }
        })
      )
      .subscribe((data) => {
        this.businessReviewData = data.reverse();
      });
  }

  getTranslations() {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
        this.t = this.trans.t;
        this.toggleInputs(this.showInputs);
      });
    this.translationService
      .trans(this.goalTrans)
      .pipe(take(1))
      .subscribe((res) => {
        this.goalTrans = res;
      });
  }

  getGoals(valuePropId: string, businessReviewId: number | null = null, isScratchpadRefresh = false, goalIdToUpdate: string | null = null, nameUpdate = false): void {
    if (!nameUpdate) {
      this.loading = true;
      this.tableRefreshing$.next(true);
    }
    if (!this.goalGroups) {
      this.goalGroups = [];
    }
    this.goalGroupsService
      .getValueRealizationsGoalsGrouped(valuePropId, businessReviewId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (newGoalGroups) => {
          if (newGoalGroups) {
            this.goalGroupArrays = [];
            newGoalGroups.forEach((group, index) => {
              this.goalGroupArrays.push(this.createFormStructure(group));
              group.goalList = group.factors.map((goal): ValueRealizationGoal => {
                const linkedFactors = (goal.connected_objects as ConnectedObject)?.result?.length ? (goal.connected_objects as ConnectedObject).result : null;
                const linkedFactorNames: string[] = [];
                if (linkedFactors && linkedFactors.length > 0) {
                  linkedFactors.forEach((factor) => {
                    linkedFactorNames.push(factor.name);
                  });
                }

                const goalData: ValueRealizationGoal = {
                  ...goal,
                  linkedFactors: linkedFactorNames,
                  survey_factors: goal.survey_factors.map((factor) => ({
                    ...factor,
                    currentValue: factor.factor_value,
                  })),
                };

                return goalData;
              });
              this.businessReviewService.saveGoalsInMemory(group.goalList);
              this.setGoalsTrend(group.factors);

              if (isScratchpadRefresh) {
                group.goalList.forEach((goal) => {
                  goal.currentValue = this.goalListCache.find((goalCache) => goalCache.account_factor_id === goal.account_factor_id)?.currentValue ?? goal.currentValue;
                  if (goal.account_factor_id.toString() === goalIdToUpdate.toString())
                    goal.currentValue = goal.survey_factors.find((br) => br.business_review_id === this.businessReview.id).factor_value;
                });
              }
              this.upsertGroup(group, index);
            });
            this.getBusinessReviewsData(() =>
              this.insertBusinessReviewColumns(this.businessReviewData.length > this.reviewGroupSize ? this.businessReviewData.length - this.reviewGroupSize : 0)
            );
          }
        },
        () => this.tableRefreshing$.next(false)
      );
  }

  private upsertGroup(group: ValueRealizationGoalGroup, index: number): void {
    const currentIndex = this.goalGroups.findIndex((current) => current.id == group.id);
    if (currentIndex !== -1) {
      this.goalGroups[currentIndex].goalList = group.goalList;
    } else {
      this.goalGroups.splice(index, 0, group);
    }
  }

  setBusinessReview(review: BusinessReviewData): void {
    this.businessReviewService.selectBusinessReview(review);
  }

  setGoalsTrend(goals: ValuePropGoal[]) {
    this.goalTrendOptions = smallLineChartConf;
    this.goalsTrend = [];
    goals?.forEach((goal) => {
      this.goalsTrend.push({
        datasets: [
          {
            label: '',
            data: goal.survey_factors.map((survey) => (survey.factor_value ? survey.factor_value : 0)),
            borderColor: this.chartColors[2],
            backgroundColor: this.chartColors[2],
            fill: false,
          },
        ],
        labels: goal.survey_factors.map((survey) => survey.business_review_name),
      });
    });

    this.allTrendData.push(this.goalsTrend);
  }

  toggleInputs(toggle: boolean) {
    this.showInputs = toggle;
  }

  toggleScratchpadSidebar(goal: BusinessFactor, goalSurveyFactor: SurveyFactor) {
    this.selectedGoalOrignal = goal;
    this.selectedGoalOrignal.goalSurveyFactor = goalSurveyFactor;
    this.scratchpadEdit = !!goalSurveyFactor;
    this.selectedGoal = {
      accountFactorId: goal.account_factor_id.toString(),
      name: goal.factor_name,
      value: goal.value.toString(),
      baselineValue: goal.baseline_value.toString(),
      precision: goal.factor_precision.toString(),
      scratchpadString: goal.scratchpad_str,
      unitType: goal.unit_type_id.toString(),
      description: goal.factor_description,
      linkedFactors: goal?.linkedFactors.length > 0 ? goal?.linkedFactors : null,
    };
    this.reviewGoalSidebar = !this.reviewGoalSidebar;
  }

  createNewBusinessReview(): void {
    this.addingNewData = true;
    this.goalGroupsService.saveBusinessReview(this.valuePropId, { name: null }).subscribe({
      next: (response) => {
        this.getGoals(this.valuePropId);
        this.notificationService.success(this.goalTrans.trans.reviewSuccess.value, false);
        this.notifyNewBusinessReview(response.result.business_review_id);
        this.newlyCreatedReview = true;
        this.addingNewData = false;
      },
      error: () => {
        this.notificationService.error(this.goalTrans.trans.reviewFailure.value, false);
        this.addingNewData = false;
      },
    });
  }

  saveReviewData(): void {
    const surveyFactorUpdates = new Map<number, FactorUpdate[]>();
    this.goalGroups.forEach((group) => {
      group.goalList.forEach((goal) => {
        goal.survey_factors.forEach((survey) => {
          if (survey.currentValue !== survey.factor_value) {
            const factorPayload = {
              factor_id: +goal.account_factor_id,
              unit_type_id: +goal.unit_type_id,
              factor_value: survey.currentValue,
            };
            surveyFactorUpdates.set(
              survey.business_review_id,
              surveyFactorUpdates.get(survey.business_review_id) ? [...surveyFactorUpdates.get(survey.business_review_id), factorPayload] : [factorPayload]
            );
          }
        });
      });
    });

    const forkRequest: { [klass: number]: Observable<APIResult<SuccessFailResponse>> } = {};

    surveyFactorUpdates.forEach((factors, businessReviewId) => {
      forkRequest[businessReviewId] = this.goalGroupsService.updateBusinessReview(Number(this.valuePropId), {
        business_review_id: businessReviewId,
        factors: factors,
      });
    });

    forkJoin(forkRequest).subscribe({
      next: () => {
        this.notificationService.success(this.goalTrans.trans.reviewUpdateSuccess.value, false);
      },
      error: () => {
        this.notificationService.error(this.goalTrans.trans.reviewUpdateFailure.value, false);
      },
      complete: () => {
        this.toggleInputs(false);
        this.getGoals(this.valuePropId);
        this.getBusinessReviewsData(this.insertBusinessReviewColumns);
        this.businessReviewService.refreshBusinessReview();
        this.businessReviewService.toggleEdition(false);
        this.newlyCreatedReview = false;
        this.businessReviewService.benefitRefresh();
      }
    });
  }

  closeSidebar(refreshGoals: { refresh: boolean; goalId: string }): void {
    this.reviewGoalSidebar = false;
    if (refreshGoals.refresh) {
      this.goalListCache = [...this.goalGroups.flatMap((goal) => goal.goalList)];
      this.getGoals(this.valuePropId, this.businessReview.id, true, refreshGoals.goalId);
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  prevReview(): void {
    if (this.businessReviewIndex > 0) {
      this.businessReviewIndex -= 1;
      this.insertBusinessReviewColumns(this.businessReviewIndex);
    }
  }

  nextReview(): void {
    if (this.businessReviewIndex < this.businessReviewCount - 4) {
      this.businessReviewIndex += 1;
      this.insertBusinessReviewColumns(this.businessReviewIndex);
    }
  }

  private setBusinessReviewObserver(): void {
    this.businessReviewService.businessReview
      .pipe(
        filter((businessReview) => businessReview !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((review) => {
        if (review === undefined) {
          this.getGoals(this.valuePropId);
        } else {
          this.businessReview = review;
          this.getGoals(this.valuePropId, this.businessReview.id);
        }
      });

    this.toggleInputs(false);
    this.userService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userInfo) => {
      this.userData = userInfo;
    });
  }

  private setEditObserver(): void {
    this.businessReviewService.businessReviewEdition.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
      this.toggleInputs(state);
    });
  }

  private notifyNewBusinessReview(id: number): void {
    this.businessReviewService.shareNewBusinessReviewId(id);
    this.businessReviewService.toggleEdition(true);
  }

  cancelBusinessReview() {
    this.businessReviewService.toggleEdition(false);
    const deleteId = this.businessReview.id;
    if (!this.newlyCreatedReview) {
      this.newlyCreatedReview = false;
      this.toggleInputs(false);
      return;
    }
    this.goalGroupsService
      .deleteBusinessReview(deleteId)
      .pipe(take(1))
      .subscribe(() => {
        this.businessReviewService.deleteBusinessReview(deleteId);
        this.toggleInputs(false);
        this.newlyCreatedReview = false;
      });
  }

  sortSurveyFactors(surveyFactors: SurveyFactor[]): SurveyFactor[] {
    return surveyFactors.sort((a: SurveyFactor, b: SurveyFactor) => {
      if (a.business_review_id > b.business_review_id) {
        return 1;
      } else if (a.business_review_id < b.business_review_id) {
        return -1;
      } 
      return 0;
    });
  }

  private insertBusinessReviewColumns(index = 0): void {
    this.tableHeaderIndexMap = new Map();
    this.businessReviewCount = this.businessReviewData.length;
    this.tableHeaderFormArray.clear();
    this.businessReviewIndex = index;
    const entries = Math.min(this.reviewGroupSize, this.businessReviewCount);
    if (this.businessReviewCount === 0) {
      this.tableColumns = [
        { header: this.t.goalName, sortBy: 'factor_name', field: 'factor_name', class: 'clickable', width: 'factor-name' },
        { header: this.t.unitType, sortBy: 'unit_type_id', field: 'unit_type_id', width: 'unit-type' },
        { header: this.t.baselineValue, field: 'baseline_value_fmt' },
        { header: this.t.targetValue, field: 'value_fmt' },
        { header: this.t.trend, field: 'trendChart' },
      ];
    } else {
      this.tableColumns = [
        { header: this.t.goalName, sortBy: 'factor_name', field: 'factor_name', class: 'clickable', width: 'factor-name' },
        { header: this.t.unitType, sortBy: 'unit_type_id', field: 'unit_type_id', width: 'unit-type' },
        { header: this.t.baselineValue, field: 'baseline_value_fmt' },
        { header: this.t.targetValue, field: 'value_fmt' },
      ];
      this.tableColumns.push({ header: '', field: 'prevReview', width: 'prev-next-review' });
      for (let index = 0; index < entries; index++) {
        const surveyFactorIndex = index + this.businessReviewIndex;
        // why is it only index 0, what about the other goal groups and their goals?
        const goal = this.goalGroups[0].goalList[0];
        const surveyFactors = this.sortSurveyFactors(goal.survey_factors);
        const reviewName = surveyFactors[surveyFactorIndex].business_review_name;
        const businessReviewId = surveyFactors[surveyFactorIndex].business_review_id;
        this.tableHeaderIndexMap.set(businessReviewId, index);
        this.tableHeaderFormArray.push(
          this.fb.group({
            name: new FormControl(reviewName, [Validators.maxLength(20), Validators.required]),
            editing: new FormControl(false),
          })
        );
        this.tableColumns.push({
          header: reviewName,
          field: 'cDynamic',
          width: 'dynamic',
          dynamicIndex: surveyFactorIndex,
          businessReviewId,
        });
      }
      this.tableColumns.push({ header: '', field: 'nextReview', width: 'prev-next-review' });
      this.tableColumns.push({ header: this.t.trend, field: 'trendChart' });
    }
    this.cols = this.tableColumns;
    this.tableHeaderFormArrays = Array.from({ length: this.goalGroups.length }, () => cloneDeep(this.tableHeaderFormArray) as FormArray);
    this.tableRefreshing$.next(false);
  }

  createFormStructure(goalGroup: ValueRealizationGoalGroup): FormArray {
    const factorsFormArray = this.fb.array([]);

    goalGroup.factors.forEach((factor) => {
      const factorFormArray = this.fb.group({
        name: new FormControl(factor.factor_name, [Validators.maxLength(255), Validators.required]),
        editing: new FormControl(false),
      });
      factorsFormArray.push(factorFormArray);
    });

    return factorsFormArray;
  }

  editName(index: number, rowIndex: number, goal: ValueRealizationGoal | null): void {
    this.goalGroupArrays[index].at(rowIndex).setValue({
      name: goal ? goal.factor_name : '',
      editing: !this.goalGroupArrays[index].at(rowIndex).get('editing').value,
    });
  }

  saveName(index: number, rowIndex: number, factor_id: string): void {
    if (this.goalGroupArrays[index].at(rowIndex).get('name').invalid) {
      return;
    }
    const vr_override_name = this.goalGroupArrays[index].at(rowIndex).get('name').value;
    const payload = {
      factor_id,
      vr_override_name,
    };

    this.valuepropService
      .postEditFactorNameFromBenefits(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.goalNameSaved.value, false);
        this.editName(index, rowIndex, null);
        this.updateNameInGroup(index, rowIndex, vr_override_name);
        this.businessReviewService.benefitRefresh();
      });
  }

  updateNameInGroup(index: number, rowIndex: number, goalName: string): void {
    this.goalGroups[index].goalList[rowIndex].factor_name = goalName;
  }

  editSurveyName(index: number, reviewName: string, goalGroupIndex: number, editing = null): void {
    this.tableHeaderFormArrays[goalGroupIndex].at(index).patchValue({
      name: reviewName,
      editing: editing ?? !this.tableHeaderFormArrays[goalGroupIndex].at(index).get('editing').value,
    });
  }

  saveSurveyName(index: number, goalGroupIndex: number, brId: number): void {
    if (this.tableHeaderFormArrays[goalGroupIndex].at(index).get('name').invalid) {
      return;
    }
    const name = this.tableHeaderFormArrays[goalGroupIndex].at(index).get('name').value;
    const payload: BusinessReviewUpdate = {
      name,
      end_date: '',
    };
    this.goalGroupsService
      .updateBusinessReviewNameDate(brId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.businessReviewNameSaved.value, false);
        this.tableHeaderFormArrays.forEach((_formArray, ggIndex) => {
          this.editSurveyName(index, name, ggIndex, false);
        });
        this.updateNameInHeaders(brId, name);
        this.businessReviewService.businessReviewData(this.valuePropId).pipe(take(1)).subscribe();
        this.businessReviewService.refreshBusinessReview();
        this.getGoals(this.valuePropId, null, false, null, true);
      });
  }

  updateNameInHeaders(brId: number, goalName: string): void {
    this.tableColumns.map((column) => {
      if (column.field === 'cDynamic' && column.businessReviewId === brId) {
        column.header = goalName;
      }
    });
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  editEndDate(brId: number, name: string): void {
    const end_date = this.formatDate(this.endDateGroup.get('endDate').value);

    const payload: BusinessReviewUpdate = {
      name,
      end_date,
    };
    this.savingEndDate = true;
    const canEditTemp = this.canEdit;
    this.canEdit = false;

    this.goalGroupsService
      .updateBusinessReviewNameDate(brId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.overlayPanel.hide();
        this.notificationService.success(this.t.businessReviewDateSaved, false);
        this.businessReviewData.map((br) => {
          if (br.id === brId) {
            br.end_date = end_date;
          }
        });
        this.savingEndDate = false;
        this.canEdit = canEditTemp;
        this.businessReviewService.benefitRefresh();
      });
  }

  hideOverlayPanel(): void {
    this.overlayPanel.hide();
  }
}
