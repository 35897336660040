import { Component } from '@angular/core';
import { AppImplementation } from '../../app.implementation';

@Component({
	selector: 'app-system-calculator',
	templateUrl: './calculator.component.html',
	styleUrls: ['./calculator.component.scss']
})
export class AppSystemCalculatorView {
	constructor(public aI: AppImplementation) {}
}