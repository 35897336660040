import { Injectable } from '@angular/core';

@Injectable()
export class RSectionsTranslations {
	public config: any = {
		component: 'readSections',
		display_name: 'Case Studies Sections View',
		description: 'View for section of case studies'
	};

	public trans: any = {
		viewSection: 'View Section',
		section_type: 'Section Type',
		description: 'Description',
		media: 'Media',
        edit_translations: 'Edit Translations'
	};
}
