import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'r_situations',
    display_name: 'Situations Read',
    description: 'Situations Read'
  };

  public trans: any = {
    view_situation: 'View Situationn',
    name: 'Name',
    description: 'Description',
    type: 'Type'
  };
}
