import { Injectable } from '@angular/core';

@Injectable ()

export class ValuePropNameChangerTranslations {

    public config: any = {
        component: 'NameChanger',
        display_name: 'Value Prop Name Edit',
        description: 'Flyout to edit the name of a business case'
    };

    public trans: any = {
        rename: 'Rename',
        name: 'Name',
        save: 'Save',
        areYouSure: 'Are you sure?',
        back: 'Back'
    };
}
