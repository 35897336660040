import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'r_scratchpads',
    display_name: 'Factors Scratchpads',
    description: 'Factors Scratchpads'
  };

  public trans: any = {
    formula: 'Formula',
    view_scratchpad: 'View Scratchpad'
  };
}
