import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-tracker-cost',
  templateUrl: 'tracker-costs.component.html',
})
export class TrackerCostsComponent implements OnInit {
  @Input() vpRealization: any;
  @Input() can_edit: boolean = false;
  @Input() embedded: boolean = false;

  @Output() callback = new EventEmitter();

  valueProp: Partial<ValueProp>;
  constructor() {}

  ngOnInit() {
    this.valueProp = {
      id: this.vpRealization.value_prop_id,
      term: this.vpRealization.term,
      vp_can_edit: this.can_edit,
      closed: '0'
    };
  }

  funcOnCostAddEditClose() {
    this.callback.emit();
  }
}
