import { Injectable } from '@angular/core';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { UserService } from '../user/user.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

export interface CountryFlag {
  unicodeIcon: string;
  countryAbb: string;
  name: string
  dLLanguageCode: string; //(dL)Xfactor.io
  description: string
  id?: string
}

/**
 * Add new languages to this map
 * with the country abbreviation that is used in 
 * countries from country-flag-icons as the value 
 * and the key beong the language.name as it comes form the api
 */
export enum CountryFlagEnum {
  _1 = 'US',
  _2 = 'GB',
  _3 = 'ES',
  _4 = 'FR',
  _5 = 'CN',
  _6 = 'PT',
  _7 = 'DE',
  _8 = 'JP',
  _9 = 'KR',
  _10 = 'IT',
   _11= 'NL',
  _12 = 'TH',
  _13 = 'MY',
  _14 = 'ID',
}

@Injectable ()
export class CountryFlagService {

  /**
   * When Needing a list of Country codes use
   * import { countries } from 'country-flag-icons'
   * Documentation of flag icon package: https://www.npmjs.com/package/country-flag-icons
   */

  public countryFlagMap$ = new BehaviorSubject<Map<string, CountryFlag>>(new Map<string, CountryFlag>());

  constructor(private userService: UserService) {
    this.generateFlagMap();
  }

  private generateFlagMap(): void {
    this.userService.getLanguages()
      .pipe(take(1))
      .subscribe(langs => {
        if (langs) {
          const map = new Map<string, CountryFlag>();
          langs.result.forEach(lang => {
            const { name, description, id, language_code } = lang
            const countryFlag: CountryFlag = {
              id,
              name,
              description,
              dLLanguageCode: language_code,
              countryAbb: CountryFlagEnum[name] ? CountryFlagEnum[name] : '',
              unicodeIcon: getUnicodeFlagIcon(CountryFlagEnum[`_${id}`]) ? getUnicodeFlagIcon(CountryFlagEnum[`_${id}`]) : ''
            }
            map.set(lang.id, countryFlag);
          });
          this.countryFlagMap$.next(map);
        }
      })
  }
}