import { Injectable } from '@angular/core';

@Injectable ()

export class CompetitorsProfileTranslations {

    public config: any = {
        component: 'competitorsProfile',
        display_name: 'Competitors',
        description: 'List of competitors in profile'
    };

    public trans: any = {
        company_optional: 'Company (Optional)',
        manage_competitors: 'Manage Competitors',
        competitor: 'Competitor',
        name_label: 'Name',
        actions: 'Actions',
        description: 'Description',
        is_this_a_test_item: 'Is this a test Item',
        add: 'Add',
        backToProf: 'Back to Profile',
        backToProfile: 'Back to',
        edit: 'Edit',
        delete: 'Delete',
        areYouSure: 'Are You Sure?',
        search: 'Search',
        rowLimit: 'Row Limit:',
        editCompetitor: 'Edit Competitor',
        addCompetitor: 'Add Competitor',
        save: 'Save',
        company_description: 'Company Description',
        placeholderSearchCompany: 'Search for a company',
        placeholderCompanyName: 'Competitor Name',
        city: 'City',
        country: 'Country',
        employees: 'Employees',
        revenue: 'Revenue',
        ticker: 'Ticker',
        select_company: 'Select Company',
        settings: 'Settings',
        dashboard: 'Dashboard',
        back: 'Back'
    };
}
