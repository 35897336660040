import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2AEFuncObjectiveTranslations {
  typeObjectKeys<T extends { [key: string]: any }>(object: T) {
    type propType = keyof typeof object;
    return object as { [originalPropType in propType]: any };
  }

  public config: any = {
    component: 'ae_func_objectives',
    display_name: 'Functional Objectives Add/Edit',
    description: 'Add/Edit Functional Objectives',
  };

  public trans: any = {
    add_func_objective: 'Add Functional Objective',
    edit_func_objective: 'Edit Functional Objective',
    name: 'Name',
    description: 'Description',
    save: 'Save',
    update: 'Update',
    func_objective_name: 'Funcitonal Objective Name',
    are_you_sure: 'Are you sure?',
    back: 'Back',
    createCustomAssetTag: 'Create custom asset tag for this Functional Objective?',
    functionalObjectivePrefix: 'Functional Objective Tag Prefix',
    value: 'Value',
    tagsSpecialCharactersError: 'Tags cannot contain special characters',
    valueTag: 'Value Tag',
    valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    changesUpdatedSuccess: 'Functional Objective updated successfully',
    changesAddedSuccess: 'Functional Objective added successfully',
    changesFailed: 'Failed to update functional objective.',
    customFactorTagWarning: 'Ensure that you have removed this custom functional objective tag from your assets to avoid disruption.',
    functionalObjectiveTag: 'Functional Objective Tag',
    tagMustBeUnique: 'Tags must be unique',
  };
}
