import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2AEModelCategoryTranslations {
  public config: any = {
    component: 'ae_category',
    display_name: 'Solutions Category add/edit',
    description: 'Add/Edit solutions category '
  };

  public trans: any = {
    name: 'Name',
    description: 'Description',
    add_model_group: 'Add Model Group',
    edit_model_group: 'Edit Model Group',
    save: 'Save',
    update: 'Update',
    model_category_name: 'Model Category Name',
    back: 'Back',
    areYouSure: 'Are you sure?'
  };
}
