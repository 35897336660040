<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{trans.trans.view_model.value}}
		<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
		[transObj]="trans.trans.view_model" (callback)="getTranslations()"></app-editTranslation>
	</div>
	<div class='sidebar_container'>
		<div class='keywest_panel'>
			<div class="row">
				<div class="col-md-12">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.model_name.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
						[transObj]="trans.trans.model_name" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{frmSolutionObj.name}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.categories.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
						[transObj]="trans.trans.categories" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					<span class='btn casestudy_tag' *ngFor="let item of frmSolutionObj.category">{{item}}</span>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.avergae_dep_time.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
						[transObj]="trans.trans.average_dep_time" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{frmSolutionObj.avg_deployment_time}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<label for="name" class="sidebar_label_readonly">{{trans.trans.description.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
						[transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{frmSolutionObj.description}}
				</div>
			</div>

		</div>
	</div>
</div>