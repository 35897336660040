import { Injectable } from '@angular/core';

@Injectable ()

export class InfoActionsHeaderTranslation {

    public config: any = {
        component: 'infoActionsHeader',
        display_name: 'Rep Simple Info Action Header',
        description: 'The header for rep simple with information and actions about the value prop'
    };

    public trans: any = {
        dashboard: 'Dashboard',
        hypothesis: 'Hypothesis',
        businessCase: 'Business Case',
        createdBy: 'Business Case created by:',
        vpCreatedBy: 'created by:',
        modifiedBy: 'Last modified by:',
        lock: 'Lock Value Prop',
		unlock: 'Unlock Value Prop',
        selectWF: 'Select Workflow',
        renameBusinessCase: 'Rename Business Case',
        valuePropStatus: 'Value Prop Status',
    };
}