import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { FactorValidationTableTypes } from 'app/value-map/solution-detail/validation/factors/factor-validation-table/factor-validation-table.component';

export interface SolutionFactor {
  metric_name: string;
  id: string;
  account_solution_id: string;
  avg_impact_prob: string;
  avg_impact_ovr: string;
  avg_diff_prob_ovr: string;
  avg_impact_prob_fmt: string;
  avg_impact_ovr_fmt: string;
  avg_diff_prob_ovr_fmt: string;
  name?: string;
  avg_driver_est_fmt?: string;
  avg_diff_est_ovr_fmt?: string;
  avg_fin_est_fmt?: string;
}

export interface FactorCategoriesResponse {
  id: string | number;
  account_id: string;
  sequence: string;
  name: string;
  description: string;
  is_financial: string;
  fastfill_enabled: string;
  created: Date;
  modified: Date;
  factors: Factor[];
}

export interface AccountScratchpad {
  id: string;
  account_id: string;
  factor_id: string;
  factor_type_id: string;
  account_solution_metric_id: string;
  is_current_effort: string;
  created: Date;
  modified: Date;
  dep_scratchpad_id: string;
  scratchpad_units: unknown[];
}

export interface CompanyField {
  company_field: string;
  created: Date;
  description: string;
  id: string;
  modified: Date;
  name: string;
}

export interface EscalerFactor {
  id: string;
  account_id: string;
  name: string;
  description: string;
  unit_type_id: string;
  literal_number: string;
  scalers_page: string;
  baseline_answer: string;
  greater_than_zero: string;
  is_key_factor: string;
  min: string;
  max: string;
  increment: string;
  factor_precision: string;
  company_field: string;
  scale_type_id: string;
  straight_scaling: string;
  ratio: string;
  formula: string;
  web_included_factor: string;
  sequence: string;
  primary_scaler: string;
  icon: string;
  is_telemetry_data: string;
  created: Date;
  modified: Date;
  dep_factor_type_id: string;
  dep_factor_id: string;
  value_realization_name: string;
  unit_type: string;
}

export interface EscaleHowResponse {
  success: boolean;
  scaling_options: ScalingOption[];
}

export interface ScalingOption {
  id: string | number;
  name: string;
  description?: string;
  created?: Date;
  modified?: Date;
  label?: string;
  value?: string | number;
}

export interface AccountFactorResponse {
  account_factor_id: number;
  factor: Factor;
  success: boolean;
}

export interface FactorTagData {
  account_id: string;
  created: string;
  description: string;
  id: string;
  metadata: {
    array_name: string;
    field: string;
    format_type: string;
    id: number;
  };
  modified: string;
  name: string;
  tag: string;
}

export interface AccountFactorTagPayload {
  tags: TagPayload[];
}

export interface TagPayload {
  id?: string; // Send if updating, if not sent, new tag is created
  name: string;
  description: string;
  tag: string; // This is the tag put in onshow,
  metadata: {
    array_name: 'factors_indexed_by_id';
    id: string; // account_factor_id
    field: 'value';
    format_type: 'check_unit';
    additionalTags: MetaDataItem[];
  };
}

export interface MetaDataItem {
  field: string;
}

export interface CreateTagData {
  factorId: string;
  assetId: string;
  name: string;
  value: string;
  description?: string;
}

export interface FactorValidation {
  factor_mismatch: ValidationFactorItem[];
  no_default_scalers: ValidationDefaultScaler[];
  zero_factors: ValidationFactorItem[];
}

export interface FactorValidationUpdate {
  factor_id: string;
  unit_type_id: string;
  value: string;
}
export interface FactorValidationValueChange {
  update: FactorValidationUpdate;
  tableType: FactorValidationTableTypes;
}

export interface ValidationDefaultScaler {
  id: string;
  name: string;
  description: string;
}
export interface ValidationFactorItem {
  id: string;
  name: string;
  description: string;
  unit_type_id: string;
  effected_benefits: ValidationEffectedBenefit[];
}

export interface ValidationEffectedBenefit {
  id: string;
  name: string;
  description: string;
  value: string;
}

export interface UsedBenefitFactor {
  benefit_id?: string;
  baseline_value: string;
  benefit_name: string;
  is_scratchpad: boolean;
  model_name: string;
}

export interface Scratchpad {
  factor_id: string;
  factor_name: string;
  type: string;
}

export interface FactorScale {
  id: string;
  name: string;
  scales_how: string;
  ratio: string;
}

export interface FactorRelationship {
  connected_goals: ConnectedGoalRelationship[];
  scratchpads: Scratchpad[];
  factor_scales: FactorScale[];
}

export interface ConnectedGoalRelationship {
  factor_name: string;
  id: string;
}
