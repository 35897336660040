<app-value-prop-info-details *ngIf="valueProp" [valueprop]="valueProp" [isNotSalesForce]="!crm" (reloadVP)="retrieveValuePropDetails()"></app-value-prop-info-details>

<p-confirmDialog #cd [message]="trans.trans.want_save_changes.value">
  <p-footer>
    <button type="button" class="btn btn_customizable" (click)="cd.accept()">{{ trans.trans.yes.value }}</button>
    <button [ngClass]="'btn-light'" type="button" class="btn btn_customizable_clear marg-h" (click)="isRejected = true; cd.reject()">
      {{ trans.trans.no.value }}
    </button>
  </p-footer>
</p-confirmDialog>

<div class="row" *ngIf="sI.valuePropLoader">
  <div class="col-xs-12">
    <dl-progressbar class="dl_progress" [showValue]="true" [value]="'Loading Value Prop Details'" mode="indeterminate"></dl-progressbar>
  </div>
</div>

<div class="row marg-18-y" *ngIf="valueProp">
  <div class="col-md-6 col-xs-12 full-width">
    <span class="flexed full-width" style="background: white;">
      <app-value-prop-info-actions-header
        *ngIf="valueProp"
        [valueprop]="valueProp"
        [isNotSalesForce]="!crm"
        (reloadVP)="retrieveValuePropDetails()"
        [step]="step"
        [menu]="menu"
        [showAllTcoMetrics]="sI.showAllTcoMetrics"
        class="full-width"
      ></app-value-prop-info-actions-header>
    </span>
  </div>
</div>

<app-rsv2-nav [valueProp]="valueProp" [step]="step" [menu]="menu" (callbackMenuClick)="askSave($event)"></app-rsv2-nav>

<!-- ============= -->

<div *ngIf="valueProp">
  <div class="row" *ngIf="+valueProp.closed === 1">
    <div class="col-xs-12">
      <div class="well text-center well-custom">
        <p>This Business Case is Locked</p>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="viewTop === 'full_view'" style="margin-top: 15px">
    <a class="btn btn-back pull-right" (click)="viewTop = 'results'; step = 4; retrieveValuePropDetails()" style="margin-right: 20px"
      ><i class="fa fa-chevron-left"></i> {{ trans.trans.back.value }}</a
    >
  </div>

  <!-- Step 5 Review Results - Feature 77 -->
  <app-competitive-tco
    *ngIf="feature77 && viewTop === 'results' && valueProp?.id"
    [menu]="menu"
    [embedded]="true"
    (back)="confirmBackNavigation()"
    [showTranslate]="showTranslate"
    [valueProp]="valueProp"
  ></app-competitive-tco>

  <!-- Step 5 Review Results -->
  <app-rsv2-dashboard-results-style2022
    *ngIf="!feature77 && viewTop === 'results'"
    (reloadVP)="retrieveValuePropDetails()"
    (back)="confirmBackNavigation()"
    [menu]="menu"
    [valuePropData]="valueProp"
    [showTranslate]="showTranslate"
    dashboard="v2"
  ></app-rsv2-dashboard-results-style2022>

  <!-- Stepper Content -->
  <div class="panel panel-white" *ngIf="viewTop !== 'full_view' && viewTop !== 'results'" style="margin-top: 0">
    <div class="panel-body" id="panel_wrapper" style="padding: 20px !important">
      <!-- Step 1 Define Scenario -->
      <app-dashboard-scenario-combined-repv2
        (reloadVP)="retrieveValuePropDetails()"
        (callbackNext)="callbackNext($event)"
        (callback)="retrieveValuePropDetails()"
        (back)="cancel()"
        [valueProp]="valueProp"
        *ngIf="!sc_vpg && viewTop === 'account'"
        [showTranslate]="showTranslate"
        [showContinue]="showContinue()"
      ></app-dashboard-scenario-combined-repv2>

      <!-- Step 1 feature67 ON -->
      <app-dashboard-sc-vpg
        (callbackNext)="callbackNext($event)"
        (reloadVP)="retrieveValuePropDetails()"
        (callback)="retrieveValuePropDetails()"
        (back)="cancel()"
        [valueProp]="valueProp"
        *ngIf="sc_vpg && viewTop === 'account'"
        [showTranslate]="showTranslate"
        [showContinue]="showContinue()"
      ></app-dashboard-sc-vpg>

      <!-- Step 2 Review Benefits -->
      <app-benefits-v2
        (callbackNext)="callbackNext()"
        (back)="confirmBackNavigation()"
        [locked]="valueProp.closed === '0' ? false : true"
        view="rep-simple"
        *ngIf="viewTop === 'benefits'"
        [valueProp]="valueProp"
        [showTranslate]="showTranslate"
        [showContinue]="showContinue()"
      ></app-benefits-v2>

      <!-- Step 3 Provide Numbers -->
      <app-assumptions
        #assumptions
        (callbackNext)="callbackNext()"
        (back)="confirmBackNavigation()"
        [viewSimple]="true"
        view="simple"
        [keyAssumptions]="true"
        [showTranslate]="showTranslate"
        *ngIf="!feature52 && viewTop === 'keyassumptions'"
        [valueProp]="valueProp"
        [showContinue]="showContinue()"
      ></app-assumptions>

      <!-- Step 3 Provide Numbers & Feature 52 ON-->
      <app-assumptions-fastfill
        #assumptionsFastfill
        (callbackNext)="callbackNext()"
        (reloadVP)="callbackNext()"
        (back)="confirmBackNavigation()"
        view="simple"
        [showTranslate]="showTranslate"
        *ngIf="feature52 && viewTop === 'keyassumptions'"
        [valueProp]="valueProp"
        [showContinue]="showContinue()"
      ></app-assumptions-fastfill>

      <!-- Step 4 Add Costs -->
      <app-costs-simple-valueprop
        (callbackNext)="callbackNext()"
        (back)="confirmBackNavigation()"
        [viewSimple]="true"
        [showTranslate]="showTranslate"
        *ngIf="viewTop === 'costs'"
        [valueProp]="valueProp"
        [showContinue]="showContinue()"
      ></app-costs-simple-valueprop>
    </div>
  </div>

  <div class="row" *ngIf="viewTop === 'full_view'">
    <div class="col-xs-12">
      <div class="panel panel-white">
        <div class="panel-body">
          <app-panel-top [valueProp]="valueProp" [isDev]="isDev" dashboard="v2" [viewTop]="'notes'"></app-panel-top>
        </div>
      </div>
      <div class="panel panel-white">
        <div class="panel-body">
          <app-panel-bottom [firstLoad]="true" [fromSimple]="true" [valueProp]="valueProp" [showAllTcoMetrics]="sI.showAllTcoMetrics"></app-panel-bottom>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-guided-tour></ngx-guided-tour>

<p-sidebar [(visible)]="toggleDebug" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">Debug</div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <pre>
					{{ valueProp | json }}
				</pre
        >

        <pre>
					{{ permissions | json }}
				</pre
        >
      </div>
    </div>
  </div>
</p-sidebar>
