<div *ngIf="showCaseStudy && caseStudyItem.length != 0">
  <div class="col-md-6">
    <p>
      <label *ngIf="!changeName"
        ><strong>{{ trans.trans.name_label.value }}: </strong> {{ caseStudyItem.name }}</label
      >
      <input *ngIf="changeName" [(ngModel)]="caseStudyItem.name" />
      <a *ngIf="!changeName" (click)="changeName = true" container="body" placement="right">{{ trans.trans.change.value }}</a>
      <a *ngIf="changeName" (click)="changeCaseStudyName()">{{ trans.trans.save.value }}</a>
      <a *ngIf="changeName" (click)="cancelNameChange()">{{ trans.trans.Cancel.value }}</a>
    </p>
    <p>
      <label
        ><strong>{{ trans.trans.model.value }}</strong> {{ caseStudyItem.account_solution_name }}</label
      >
    </p>
    <p>
      <label
        ><strong>{{ trans.trans.tags.value }} </strong> {{ caseStudyItem.tag }}</label
      >
    </p>
  </div>

  <div class="pull-right m-b-xxl m-r-xxl">
    <button class="btn btn-primary" (click)="downloadAsset()">
      <i class="icon-cloud-download"></i>
      {{ trans.trans.download_asset.value }}
    </button>
  </div>
  <div class="clearfix"></div>

  <ngb-accordion [closeOthers]="true" activeIds="static-1">
    <ngb-panel id="static-1" title="{{ trans.trans.testimonials.value }}">
      <ng-template ngbPanelContent>
        <div class="caseStudyDetailSections">
          <div class="clearfix">
            <app-quote [caseStudyId]="caseStudyId" [quotes]="caseStudyItem['quotes']" (valueChange)="getCaseStudyDetail()"></app-quote>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="static-2" title="{{ trans.trans.sections.value }}">
      <ng-template ngbPanelContent>
        <div class="caseStudyDetailSections">
          <div class="clearfix">
            <app-sections [caseStudy]="caseStudyItem" [type]="type" (valueChange)="getCaseStudyDetail()"></app-sections>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="static-3" title="{{ trans.trans.benefits.value }}">
      <ng-template ngbPanelContent>
        <div class="caseStudyDetailSections">
          <div class="clearfix">
            <app-benefits-casestudy [caseStudy]="caseStudyItem" [type]="type" (valueChange)="getCaseStudyDetail()"></app-benefits-casestudy>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
