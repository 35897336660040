import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class CustomAssetTagsTranslation extends TranslationsBaseService {

  public config: any = {
    component: 'customAssetTags',
    display_name: 'Custom Asset Tags',
    description: 'Customize Asset Tags',
  };

  public trans = this.typeObjectKeys({
    customAssetTags: 'Custom Asset Tags',
    editAssetTag: 'Edit Custom Asset Tag',
    tagId: 'Tag ID',
    factorValueTag: 'Factor Value Tag',
    factorDescriptionTag: 'Factor Description Tag',
    factorNameTag: 'Factor Name Tag',
    status: 'Status',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add',
    save: 'Save',
    noTags: 'No Custom Asset Tags Added',
    areYouSure: 'Are you sure?',
    settings: 'Settings',
    dashboard: 'Dashboard',
    deleteSuccess: 'Tag was deleted successfully.',
    deleteError: 'Tag could not be deleted.',
    saveSuccess: 'Changes saved succesfully',
    statusOff: 'Ensure that you have removed this custom factor tag from your assets to avoid disruption.',
    tooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    factorTags: 'Factor Tags',
    benefitTags: 'Benefit Tags',
    benefitTag: 'Benefit Tag',
    benefitNameTag: 'Benefit Name Tag',
    benefitTcoNameTag: 'Benefit TCO Name Tag',
    benefitDescriptionTag: 'Benefit Description Tag',
    benefitNotesTag: 'Benefit Notes Tag',
    benefitNotes2Tag: 'Benefit Notes 2 Tag',
    benefitsNotes3Tag: 'Benefit Notes 3 Tag',
    benefitYear: 'Benefit Year',
    tag: 'Tag',
    benefitsTotalTag: 'Benefit Total Benefits Tag',
    tagValue: 'Tag Value',
    functionalObjectiveTags: 'Functional Objective Tags',
    functionalObjectNameTag: 'Functional Objective Name Tag',
    functionalObjectiveDescriptionTag: 'Functional Objective Description Tag',
    functionalObjectiveYear: 'Functional Objective Year',
    functionalObjectiveTotalBenefitsTag: 'Functional Objective Total Benefits Tag',
    benefitGroupTags: 'Benefit Group Tags',
    benefitGroupNameTag: 'Benefit Group Name Tag',
    benefitGroupTcoNameTag: 'Benefit Group TCO Name Tag',
    benefitGroupDescriptionTag: 'Benefit Group Description Tag',
    benefitGroupYear: 'Benefit Group Year',
    benefitGroupTotalTag2: 'Benefit Group Total Benefits Tag',
    benefitsTag: 'Benefits Tag',
    benefitTypeTags: 'Benefit Type Tags',
    benefitTypeNameTag: 'Benefit Type Name Tag',
    benefitTypeDescriptionTag: 'Benefit Type Description Tag',
    benefitTypeYear: 'Benefit Type Year',
    benefitTypeTotalTag2: 'Benefit Type Total Benefits Tag',
    foName: 'Functional Objective Name Tag',
    foDescription: 'Functional Objective Description Tag',
    foYear: 'Functional Objective Year',
    foTotal: 'Functional Objective Total Tag',
    cabilitiesTags: 'Capability Tags',
    capabilityNameTag: 'Capability Name Tag',
    capabilityDescriptionTag: 'Capability Description Tag',
    capabilityYear: 'Capability Year',
    capabilityTotalBenefitsTag: 'Capability Total Benefits Tag',
    tagMustBeUnique: 'Tag must be unique',
    operationalObjectiveTags: 'Operational Objective Tags',
    operationalObjectiveNameTag: 'Operational Objective Name Tag',
    operationalObjectiveDescriptionTag: 'Operational Objective Description Tag',
    operationalObjectiveYear: 'Operational Objective Year',
    operationalObjectiveTotal: 'Operational Objective Total Benefits Tag',
    ooNameTag: 'Operational Objective Name Tag',
    ooDescriptionTag: 'Operational Objective Description Tag',
    ooYear: 'Operational Objective Year',
    ooTotal: 'Operational Objective Total Benefits Tag',
    ppNameTag: 'Pain Point Name Tag',
    ppDescriptionTag: 'Pain Point Description Tag',
    ppYear: 'Pain Point Year',
    ppTotal: 'Pain Point Total Benefits Tag',
    ppTags: 'Pain Point Tags',
    costNameTag: 'Cost Name Tag',
    costDescriptionTag: 'Cost Description Tag',
    costYear: 'Cost Year',
    costQuantity: 'Cost Quantity',
    quantityTag: 'Quantity',
    costSubtotal: 'Cost Subtotal Tag',
    costDiscount: 'Discount',
    costGrandTotal: 'Cost Grand Total Tag',
    costTags: 'Cost Tags',
    costTag: 'Cost',
    phasingPercentYear: 'Phasing Percent Year',
    search: 'Search',
  });
}
