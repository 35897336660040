import { Injectable } from '@angular/core';

@Injectable()
export class CreateMapTranslations {
  public config: any = {
    component: 'createMapComponent',
    display_name: 'Create Model',
    description: 'Form with required information to create a model',
  };

  public trans: any = {
    createModel: 'Create a Model',
    modelName: 'Model Name',
    enterName: 'Enter Name',
    modelCategory: 'Model Category',
    averageDeployment: 'Average Deployment Time',
    enterMonths: 'Enter Months',
    averageSalesPrice: 'Average Sales Price',
    averageSalesCycle: 'Average Sales Cycle',
    description: 'Description',
    save: 'Save'
  };
}
