import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { AccountService } from '@data/services/account/account.service';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { StyleService } from 'app/style.service';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';

@Component({
	selector: 'app-scalers',
	templateUrl: './scalers.component.html',
	styleUrls: ['./scalers.component.scss']
})
export class ScalersComponent implements OnInit, OnDestroy {

	ngUnsubscribe = new Subject();
	image_url: string;
	solutionId: number;
	scalers: any[] = [];
	factScalers: Array<BasicInfo | Factor> = [];
	selectedScalers: any[] = [];
	showEditScalersForm: boolean = false;
	showActionLoader: boolean = false;
	accountScalerShow: boolean = false;

	@ViewChild('addEditScalerForm') addEditScalerForm: NgForm;

	@ViewChild('createNewScalerForm') createNewScalerForm: NgForm;

	accountScaler = {
		name: '',
		description: ''
	};

	addScalerObj = {
		scaler: undefined,
		value: undefined
	};

	selectedItems: any[] = [];
	dropdownSettings = {};

	toggleAddEditNewScaler = false;
	toggleCreateNewScaler = false;
	sidebar_title = 'Add New Scaler';
	mode = 'add';
	strSearch = '';
	dropdown = [];
	cols = [
		{ field: 'name', header: 'Name' },
		{ field: 'value', header: 'Value' }
	]

	mappedSelectedIndexes = {};
	style2022$: Observable<boolean>;


	constructor(
		private solutionService: SolutionService,
		private CommonService: CommonService,
		private factService: FactsService,
		private notification: NotificationService,
		private route: ActivatedRoute,
		private accountService: AccountService,
		private styleService: StyleService
	) {
		this.image_url = this.CommonService.getImageUrl();
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => this.solutionId = params['id']);

		// this.solutionId = +this.route.snapshot.paramMap.get('id');
		this.getScalers();
		//this.getAvailableScalers();
		this.dropdownSettings = {
			singleSelection: false,
			text: "Available Scalers",
			enableCheckAll: false,
			enableSearchFilter: false,
			labelKey: "name",
			badgeShowLimit: 2,
			lazyLoading: false
		};
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	funcBuildMenu(elem, menu, event) {
		this.dropdown = [
			{
				label: "Edit", icon: "fa fa-pencil", command: () => {
					this.sidebar_title = 'Edit Scaler';
					this.toggleAddEditNewScaler = true;
					this.mode = 'edit';

					this.addEditScalerForm.controls.value.setValue(elem.value);
					this.addEditScalerForm.controls.scaler.setValue(elem);
					this.addScalerObj.scaler = elem;

					this.setIndexesForEdit();
				}
			},
			{
				label: "Delete", icon: "fa fa-times", command: () => {
					this.deleteScaler(elem.scale_type_id);
				}
			},
		]
		menu.toggle(event)
	}

	getScalers() {
		this.factService.getScaleTypes(1).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.factScalers = response.result;

			}
			this.getAccountScalers();
		});
	}

	getAccountScalers() {
		this.factService.getAccountScaleTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result) {
				let allScalers: any[] = this.factScalers.concat(response.result);

				let existingScalers = this.selectedScalers.reduce((acc, curr: any) => {
					return {
						...acc,
						[curr.id]: curr
					};
				}, {});

				this.scalers = allScalers.reduce((accumulated, scale: any) => {
					if (!existingScalers[scale.id]) {
						let newScale = {
							label: scale.name,
							value: scale
						};
					accumulated = [...accumulated, newScale];
					}
					return accumulated;
				}, []);
			}
			this.getAvailableScalers();
		});
	}

	getAvailableScalers() {
		this.solutionService.getAvailableScalers(this.solutionId, 1).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.selectedScalers = response.result ? response.result : [];
				this.selectedItems = this.selectedScalers.map(item => {
					item['id'] = item['scale_type_id'];
					return item;
				});

			}
		});
	}

	addScalers() {

		this.mode = 'add';
		this.sidebar_title = 'Add Scaler';
		this.toggleAddEditNewScaler = !this.toggleAddEditNewScaler;

		this.getAccountScalers();
		this.resetValues();
	}

	editScalers(form: NgForm) {
		let payload
		let newScaler = {
			scale_type_id: form.controls.scaler.value.id,
			value: form.controls.value.value
		};

		if (this.mode === 'add') {
			payload = {
				account_solution_scalers: [
					...this.selectedScalers,
					newScaler
				]
			};
		} else if (this.mode === 'edit') {
			let tempScalers = this.selectedScalers;
			tempScalers[this.mappedSelectedIndexes[newScaler.scale_type_id]].value = newScaler.value;
			payload = {
				account_solution_scalers: tempScalers
			};
		}

		this.solutionService.updateScalers(this.solutionId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(response) => {
				if (response.result) {
					this.toggleAddEditNewScaler = false;
					this.getAvailableScalers();
					this.notification.success("Scalers updated successfully", false);

				}
			}
		);

	}

	deleteScaler(id) {
		this.showActionLoader = true;
		this.solutionService.deleteScaler(this.solutionId, id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(response) => {
				if (response.result) {
					for (let i = 0; i < this.selectedScalers.length; i++) {
						if (this.selectedScalers[i]['id'] == id) {
							this.selectedScalers.splice(i, 1);
						}
					}
					this.notification.success(response.result, false);
					this.getAvailableScalers();
					this.showActionLoader = false;
				}
			}
		);
	}

	resetFormValues(form) {
		for (let key in form.value) {
			let oldValue = this.getScalerResetValue(key.split('-')[1]);
			form.controls[key].setValue(oldValue);
		}
	}

	getScalerResetValue(id) {
		for (let i = 0; i < this.selectedScalers.length; i++) {
			if (this.selectedScalers[i]['scale_type_id'] == id) {
				return this.selectedScalers[i]['value'];
			}
		}
	}

	toggleScalersCreate() {
		this.toggleCreateNewScaler = true;
		this.resetValues();
	}

	createAccountScalers(form: NgForm) {
		let account_id = sessionStorage.getItem('aid');
		let payload = {
			name: form.controls.name.value,
			description: form.controls.description.value
		};
		this.accountService.createAccountScalers(account_id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			if (response.result) {
				this.notification.success("Account Scale Type created successfully", false);
				this.toggleCreateNewScaler = false;
				this.getAccountScalers();
			}
		});
	}

	resetValues() {
		this.addEditScalerForm ? this.addEditScalerForm.reset() : this.createNewScalerForm.reset();
		this.createNewScalerForm.reset();
	}

	setIndexesForEdit() {
		this.mappedSelectedIndexes = this.selectedScalers.reduce((acc, sclr: any, index) => ({
			...acc,
			[sclr.id]: index
		}));
	}


}
