import { Injectable } from '@angular/core';

@Injectable ()

export class ProfileSubTranslation {

    public config: any = {
        component: 'profileSubComponent',
        display_name: 'Profile User Detail',
        description: 'Detail of a user in profile section'
    };

    public trans: any = {
        heading: 'Your Profile',
        edit: 'Edit'
    };
}
