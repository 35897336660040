import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyTrackersComponent } from './views/company-trackers/company-trackers.component';
import { TrackValueDashboardComponent } from './views/track-value-dashboard/track-value-dashboard.component';
import { TrackerBaselineViewComponent } from './views/tracker-baselines/tracker-baseline-view.component';
import { ValueRealizedHomeComponent } from './views/value-realized-home/value-realized-home.component';

const routes: Routes = [
  {
    path: '',
    component: ValueRealizedHomeComponent,
  },
  {
    path: 'company/:companyId',
    component: TrackerBaselineViewComponent
  },
  {
    path: 'company/:companyId/trackers',
    component: CompanyTrackersComponent,
  },
  {
    path: 'company/:companyId/trackers/:trackerId',
    component: TrackValueDashboardComponent
  },
  {
    path: 'company/:companyId/trackers/:trackerId/edit',
    component: TrackerBaselineViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ValueRealizedRoutingModule {}
