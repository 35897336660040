import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { httpUrlRegex, urlRegex } from './url-pattern';

export class CustomValidators {
  static isEmptyInputValue(value: any): boolean {
    return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
  }

  /**
   * This is a custom validator for leaseTerms
   */
  static maxTermValidator(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isEmptyInputValue(control.value) || this.isEmptyInputValue(max)) {
        return null;
      }
      const value = parseFloat(control.value);
      return !isNaN(value) && value > max ? { maxTerm: { maxTerm: max, actual: control.value } } : null;
    };
  }

  /**
   * This is a custom validator for url patterns
   */
  static urlValidator(http = false): ValidatorFn {
    const pattern = http ? httpUrlRegex() : urlRegex();

    const regexStr = pattern.toString();
    const regex = pattern;
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value: string = control.value;
      return regex.test(value) ? null : { url: { url: regexStr, actualValue: value } };
    };
  }

  /**
   * This is a custom validator to throw an error if there are more than two decimals in the input
   */
  static twoDecimalValidator(): ValidatorFn {
    const regex = /^-?\d*[.,]?\d{0,2}$/;
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value: string = control.value.toString();
      return regex.test(value) ? null : { twoDecimal: { twoDecimal: true, actualValue: value } };
    };
  }

  /**
   * This is a custom validator to throw an error if there are decimals in the input
   */
  static noDecimalValidator(): ValidatorFn {
    const regex = /^-?\d+(\.0+)?$/;

    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value: string = control.value.toString();
      return regex.test(value) ? null : { noDecimal: { noDecimal: true, actualValue: value } };
    };
  }

  /**
   * This is a custom validator to throw an error if there are special characters in the input
   */
  static noSpecialCharacterValidator(): ValidatorFn {
    const regex = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;

    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value: string = control.value.toString();
      return !regex.test(value) ? null : { noSpecialCharacter: { noSpecialCharacter: true, actualValue: value } };
    };
  }

  static maxTotalValueValidator(value: number): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: any } | null => {
      const formArray = formGroup.get('phasingTermArray') as FormArray;
      const totalValue = formArray.controls.reduce((acc, control) => acc + Number(control.value.val), 0);
      return totalValue > value ? { maxTotalValue: value } : null;
    };
  }
}
