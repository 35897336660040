<div class="soln_table_cont" *ngIf="showSurveyQuestionsList">
	<div class="table-responsive col-sm-12 soln-table">
		<div class="searchTableContainer">
			<div class="">
				<table id="surveys" class="table custom-table">
					<thead>
						<tr>
							<th>{{'general.name_label' | translate}}</th>
							<th>{{'realization.templateCreatedDate' | translate}}</th>
							<th>{{'realization.status' | translate}}</th>
						</tr>
					</thead>

					<tbody *ngIf="loadSurveysTable">
						<tr *ngFor="let survey of surveys" class="grid-row">
							<td>
								<a (click)="openSurveyTemplateDetail(survey.id)" class='link'>{{ survey.name }}</a>
							</td>

							<td>
								{{ survey.created }}
							</td>

							<td>
								<div class="score-card-list">
									<div class="score-label">{{(survey.is_active == '0') ? ('survey-template.in_active' | translate) : ('survey-template.active' | translate)}}</div>
									<div class="score" [ngClass]="[survey.is_active == '0' ? 'red' : 'green']">
										<i class="fa" [ngClass]="[survey.is_active == '0' ? 'fa-times' : 'fa-check']"></i>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div *ngIf="surveys.length == 0 && loadSurveysTable" class="m-t-md col-sm-12">
		<div class="searchTableContainer">
			<h4>{{'survey-template.no_surveys_found' | translate}}</h4>
		</div>
	</div>

</div>

<div class="clearfix"></div>
<div *ngIf="!showSurveyQuestionsList">
	<div (click)="showSurveyQuestionsList = !showSurveyQuestionsList" role="button" class="m-b-xxl m-l-xxl">
		<i class="fa fa-chevron-left"></i> {{'general.back' | translate}}
	</div>
	<app-survey-template-create-trackers [surveyTemplateId]="surveyTemplateId" (valueChange)="gettemplateList()"></app-survey-template-create-trackers>
</div>