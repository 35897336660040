import { Component, Input, forwardRef } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProgressBar } from 'primeng/progressbar';
@Component({
	selector: 'dl-progressbar',
	templateUrl: './progress.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DLProgressbarComponent)
		}
	]
})

export class DLProgressbarComponent extends ProgressBar {
	@Input() label: string = "";
	@Input() showPercent: boolean = true;

	constructor() {
		super()
	}
}