import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BenefitsImplementation } from '../../benefits.implementation';

import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsFullTranslations } from './full.translation';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { BenefitVCS, BenefitVCSMetric } from '@shared/models/benefit-type.model';
import { Benefit } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-benefit-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class BenefitsFullComponent implements OnInit, OnDestroy {
  @Input() benefitCategory: BenefitVCS;
  @Input() index: number;
  @Input() locked = false;
  @Input() mini = false;
  @Input() valueProp: any;
  @Input() termShow: number;
  @Input() termLimit: number;

  canEdit = false;
  feature57 = false;
  feature70 = false;
  featureNonFinancial = true;
  financialBenefits: BenefitVCSMetric[] = [];
  ngUnsubscribe = new Subject();
  nonFinancialBenefits: BenefitVCSMetric[] = [];
  pageLimit = 10;
  selectedBenefit: any;
  showTranslate = false;
  tot: number = 0;
  numberAbbreviations = new Map();
  translationsLoaded = false;
  currencySymbol = '';
  frontSymbol: boolean;

  constructor(
    public trans: BenefitsFullTranslations,
    public bI: BenefitsImplementation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private valuePropService: ValuepropService
  ) {}

  ngOnInit() {
    this.benefitCategory.allOn = false;
    if (this.benefitCategory.metrics.length > 0) {
      this.findCurrencyValue();
    }
    this.canEdit = this.bI.permissions.benefits_editable && this.bI.valueProp.vp_can_edit;
    if (this.canEdit === undefined) {
      this.canEdit = true;
    }
    const privs = sessionStorage.getItem('privileges');
    let privileges = privs.split(',');
    if (privileges.length) {
      if (privileges.indexOf('6') >= 0) {
        this.bI.benefitOnTheFlyPrivilege = true;
      }
    }
    this.bI.benefitsUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      setTimeout(() => {
        this.sortBenefits();
        this.manualUpdateAll(); // setting this as default for now since there is an issue of factors being shared in multiple benefits.
      }, 800); // waiting for parent to update its values.
    });

    this.featureNonFinancial = this.commonService.checkFeature('94'); // non financial benefits
    this.sortBenefits();

    this.locked = !!(this.valueProp && this.valueProp.closed);
    this.canEdit = this.bI.permissions.benefits_editable && this.bI.valueProp.vp_can_edit;

    this.feature57 = this.commonService.checkFeature('57'); // benefit fly out
    this.feature70 = this.commonService.checkFeature('70'); // benefit fly out

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    if (!this.termLimit) {
      this.termLimit = this.bI.termLimit;
    }

    if (!this.termShow) {
      this.termShow = this.bI.termShow;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.numberAbbreviations.set('Q', this.trans.trans.quintillions.value);
        this.numberAbbreviations.set('T', this.trans.trans.trillions.value);
        this.numberAbbreviations.set('B', this.trans.trans.billions.value);
        this.numberAbbreviations.set('M', this.trans.trans.millions.value);
        this.numberAbbreviations.set('K', this.trans.trans.thousands.value);
        this.translationsLoaded = true;
      });
  }

  funcTot(c) {
    this.tot = c;
  }

  reload() {
    if (this.feature57) {
      this.valuePropService.refreshDashboard.next('no_reload');
      return;
    }
    this.valuePropService.refreshDashboard.next('refreshDashboard');
  }

  getBenefitType(status) {
    const benefitTypes = {
      '0': 'Hard',
      '1': 'Soft',
      '2': 'Strategic',
    };
    return benefitTypes[status];
  }

  removeBenefitFromTypeList(benefit: Benefit, id: string) {
    if (!this.bI.benefitsListByType || this.bI.benefitsListByType.length === 0) {
      return;
    }
    const foundBenefitType = this.bI.benefitsListByType.find((b) => +b.id === +id);
    if (!foundBenefitType) {
      return;
    }
    foundBenefitType.vcs.forEach((vcs, index) => {
      const foundIndex = vcs.metrics.findIndex((ben) => benefit.account_solution_metric_id === ben.account_solution_metric_id);
      if (foundIndex > -1) {
        vcs.metrics.splice(foundIndex, 1);
      }
      if (vcs.metrics.length === 0) {
        foundBenefitType.vcs.splice(index, 1);
      }
    });
    this.bI.benefitsListByType$.next(this.bI.benefitsListByType);
  }

  deleteBenefit(benefit: any, type: string) {
    if (type === 'financial') {
      const index = this.financialBenefits.findIndex((ben) => benefit.account_solution_metric_id === ben.account_solution_metric_id);
      this.financialBenefits.splice(index, 1);
    } else {
      const index = this.nonFinancialBenefits.findIndex((ben) => benefit.account_solution_metric_id === ben.account_solution_metric_id);
      this.nonFinancialBenefits.splice(index, 1);
    }
    this.removeBenefitFromTypeList(benefit, benefit.benefit_type_id);
    this.bI.deleteCustomBenefit(benefit);
    this.valuePropService.refreshDashboard.next();
  }

  getTranslatedYear(year: string): string {
    const yearTrans = this.trans.trans[year];
    return yearTrans ? yearTrans.value : year;
  }

  showPhasing(benefit: any) {
    if (this.canEdit) {
      benefit.phasing = !benefit.phasing;
    }
  }

  getDateTest(): string {
    return new Date().toString();
  }

  private sortBenefits(): void {
    if (this.featureNonFinancial) {
      const financialBenefits = this.benefitCategory.metrics.filter((benefit) => benefit.benefit_type_id === '1');
      const nonFinancialBenefits = this.benefitCategory.metrics.filter((benefit) => benefit.benefit_type_id !== '1');

      this.nonFinancialBenefits = nonFinancialBenefits;
      this.financialBenefits = financialBenefits;
    } else {
      this.financialBenefits = this.benefitCategory.metrics;
    }
  }

  private findCurrencyValue(): void {
    /// checks symbol manually as a workaround for missing field on BE to have this ready.
    const valueEvaluate = this.benefitCategory.metrics[0].year1_benefits_fmt;
    const notNumbericalRegex = /[^0-9.,]/g;
    const foundValues = valueEvaluate.trim().match(notNumbericalRegex);
    if (foundValues?.length) {
      if (valueEvaluate.indexOf(foundValues[0]) === 0) {
        this.frontSymbol = true;
        this.currencySymbol = '';
        for (let i = 0; i < foundValues.length; i++) {
          if (valueEvaluate.indexOf(foundValues[i]) === i) {
            this.currencySymbol += foundValues[i];
          }
        }
      } else {
        this.frontSymbol = false;
        const numbericalValues = valueEvaluate.match(/[0-9.,]/g);
        let lastNumberIndex = valueEvaluate.indexOf(numbericalValues[numbericalValues.length - 1]);
        if (+this.benefitCategory.metrics[0].year1_benefits > 999) {
          lastNumberIndex += 1;
        }
        for (let i = 0; i < foundValues.length; i++) {
          if (valueEvaluate.indexOf(foundValues[i]) > lastNumberIndex) {
            this.currencySymbol += foundValues[i];
          }
        }
      }
    }
  }

  private manualUpdate(id: string): void {
    const updatedBenefit = this.financialBenefits.find((benefit) => benefit.id === id) ?? this.nonFinancialBenefits.find((benefit) => benefit.id === id);
    if (updatedBenefit) {
      const type = this.bI.benefitsListByType.find((benefitType) => benefitType.id === this.benefitCategory.benefit_type_id);
      const benefitVCS = type.vcs.find((vcs) => vcs.id === this.benefitCategory.id);
      const benefit = benefitVCS.metrics.find((newBenefit) => newBenefit.id === id);
      updatedBenefit.alt_metric_name = benefit.alt_metric_name;
      updatedBenefit.metric_name = benefit.metric_name;
      updatedBenefit.description = benefit.description;
      updatedBenefit.vp_specific = benefit.vp_specific;
      updatedBenefit.active = benefit.active;
      updatedBenefit.total_benefits = benefit.total_benefits;
    }
  }

  private manualUpdateAll(): void {
    this.benefitCategory.metrics.forEach((benefit) => this.manualUpdate(benefit.id));
  }

  phasingChanged(info: string, benefitId: string): void {
    if (info === 'all') {
      this.bI.loadBenefits('all');
    } else {
      this.bI.loadBenefits(benefitId);
    }
  }
}
