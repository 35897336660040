<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="'style-2022'"></p-menu>
<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div *ngIf="view !== 'full' && loading" class="text-center" style="padding-bottom: 15px">
      <dl-progressbar class="dl_progress" [showValue]="true" [value]="trans.trans.loading_assumptions.value" mode="indeterminate"></dl-progressbar>
    </div>

    <div *ngIf="view !== 'full'" class="row" style="margin-bottom: 20px">
      <div class="col-xs-4">
        <div class="input-group keywest_search no-background">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input [(ngModel)]="globalSearch" type="text" [placeholder]="trans.trans.search.value" class="form-control" (ngModelChange)="filterAssumptions($event)" />
          <span *ngIf="globalSearch !== ''" (click)="globalSearch = ''; filterAssumptions('')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
      <div class="pull-right">
        <button [disabled]="assumptionSaveLoader" (click)="updateAll('no emit')" class="btn btn_customizable">
          {{ trans.trans.save_changes.value }}
        </button>
      </div>
    </div>

    <div *ngIf="view !== 'full' && !loading && valueProp && modelsV2">
      <div class="row">
        <div class="col-xs-12">
          <span class="badge clickable filter-badge" [ngClass]="{ active: allFactorGroups }" (click)="funcFilterGroups(null)">{{ trans.trans.view_all.value }}</span>
          <span
            class="badge clickable filter-badge"
            [ngClass]="{
              active: selected_factor_groups.includes(elem) && !allFactorGroups
            }"
            (click)="funcFilterGroups(elem)"
            [ngStyle]="{ 'border-color': elem.color }"
            *ngFor="let elem of factorGroups"
            >{{ elem.name }}</span
          >
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div *ngFor="let group of selected_factor_groups">
          <div class="col-xs-4" *ngFor="let elem of group.factors">
            <div class="panel provide-numbers-panel" [ngStyle]="{ 'border-color': group.color }">
              <div class="panel-heading" style="height: 80px; border-bottom: solid 1px #e2eff7 !important">
                <span class="key_title">
                  <i [class]="'fa ' + elem.icon ? elem.icon : 'fa-area-chart'"></i> {{ elem.name }}
                  <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
                </span>
                <div *ngIf="elem.isTdData">
                  <i class="fa fa-exclamation-triangle" style="color: orange" aria-hidden="true"></i> &nbsp;
                  <small>
                    {{ trans.trans.importedData.value }}
                  </small>
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="getTranslations()"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.importedData"
                  ></app-editTranslation>
                </div>
              </div>
              <div class="panel-body">
                <div style="vertical-align: bottom">
                  <div class="p-inputgroup">
                    <input
                      [disabled]="locked || !canEdit"
                      currencyMask
                      [options]="{
                        prefix: '',
                        thousands: groupSeparator,
                        precision: +elem.factor_precision >= 0 ? elem.factor_precision : 2,
                        decimal: decimalSeparator,
                        allowNegative: elem.allowNegative
                      }"
                      [(ngModel)]="elem.value"
                      (keyup)="elem.updated = true"
                      class="form-control"
                    />
                    <div
                      [matTooltip]="trans.trans.scratchpad_tooltip.value"
                      matTooltipPosition="above"
                      (click)="onFactorClick(elem)"
                      *ngIf="+elem.has_scratchpad === 1 && feature44"
                      class="clickable p-inputgroup-addon"
                    >
                      <i *ngIf="+elem.scratchpad_used === 1" class="fa fa-calculator" style="color: #06adad"></i>
                      <img *ngIf="+elem.scratchpad_used === 0" style="width: 21px" src="assets/images/scratchpad/calculator-inactive.png" alt="" />
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 4px; margin-top: 8px">
                    <div class="col-xs-6 minmax">{{ (elem.min ? elem.min : 0) | number }}</div>
                    <div class="col-xs-6 minmax text-right">{{ (elem.max ? elem.max : 100) | number }}</div>
                  </div>
                  <input
                    type="range"
                    [disabled]="locked || !canEdit"
                    [min]="elem.min"
                    [max]="elem.max"
                    [(ngModel)]="elem.value"
                    (change)="elem.updated = true"
                    [step]="elem.increment"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-value-prop-flow-buttons
        *ngIf="viewSimple"
        [disabled]="assumptionSaveLoader"
        [moveToBottom]="!crm"
        (goNext)="updateAll()"
        (goBack)="back.emit()"
        [next]="showContinue"
      ></app-value-prop-flow-buttons>
    </div>
    <div *ngIf="view !== 'full' && !loading && valueProp && !modelsV2">
      <div class="row">
        <div class="col-xs-12" *ngIf="!allKeyAssumptions.length">
          <p class="text-center">{{ trans.trans.no_key_assumptions_found.value }}</p>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12" *ngFor="let elem of allKeyAssumptions | filterAssumptionsAll: globalSearch">
          <div class="panel" [ngSwitch]="elem.type" style="background-color: #f4f8f9; border-radius: 10px">
            <div *ngSwitchCase="'driver'" class="panel-heading" style="height: 80px; border-bottom: solid 1px #e2eff7 !important">
              <span class="key_title">
                <i class="fa {{ elem.icon ? elem.icon : 'fa-area-chart' }}"></i> {{ elem.driver_factor }}
                <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
              </span>
              <div *ngIf="elem.isTdData">
                <i class="fa fa-exclamation-triangle" style="color: orange" aria-hidden="true"></i> &nbsp;
                <small>
                  {{ trans.trans.importedData.value }}
                </small>
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.importedData"
                ></app-editTranslation>
              </div>
            </div>

            <div *ngSwitchCase="'financial'" class="panel-heading" style="height: 80px; border-bottom: solid 1px #e2eff7 !important">
              <span class="key_title">
                <i class="fa {{ elem.icon ? elem.icon : 'fa-area-chart' }}"></i> {{ elem.financial_factor }}
                <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
              </span>
              <div *ngIf="elem.isTdData">
                <i class="fa fa-exclamation-triangle" style="color: orange" aria-hidden="true"></i> &nbsp;
                <small>
                  {{ trans.trans.importedData.value }}
                </small>
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.importedData"
                ></app-editTranslation>
              </div>
            </div>

            <div *ngSwitchCase="'improvement'" class="panel-heading" style="height: 80px; border-bottom: solid 1px #e2eff7 !important">
              <span class="key_title">
                <i class="fa {{ elem.icon ? elem.icon : 'fa-area-chart' }}"></i> {{ elem.improvement_factor }}
                <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
              </span>
              <div *ngIf="elem.isTdData">
                <i class="fa fa-exclamation-triangle" style="color: orange" aria-hidden="true"></i> &nbsp;
                <small>
                  {{ trans.trans.importedData.value }}
                </small>
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.importedData"
                ></app-editTranslation>
              </div>
            </div>

            <div *ngSwitchCase="'scratch'" class="panel-heading" style="height: 80px; border-bottom: solid 1px #e2eff7 !important">
              <span class="key_title">
                <i class="fa {{ elem.icon ? elem.icon : 'fa-area-chart' }}"></i> {{ elem.name }}
                <span *ngIf="elem.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description"> info </span>
              </span>
              <div *ngIf="elem.isTdData">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;
                <small>
                  {{ trans.trans.importedData.value }}
                </small>
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.importedData"
                ></app-editTranslation>
              </div>
            </div>

            <div *ngSwitchCase="'driver'" class="panel-body">
              <div style="vertical-align: bottom">
                <div class="p-inputgroup">
                  <input
                    currencyMask
                    [disabled]="locked || !canEdit"
                    [options]="{ prefix: '', decimal: decimalSeparator, thousands: groupSeparator, precision: elem.factor_precision >= 0 ? elem.factor_precision : 2 }"
                    [(ngModel)]="elem.driver_value"
                    (keyup)="elem.updated = true"
                    class="form-control"
                  />
                  <div
                    [matTooltip]="trans.trans.scratchpad_tooltip.value"
                    matTooltipPosition="above"
                    (click)="onDriverFactorCalcClick(elem)"
                    *ngIf="elem.driver_factor_id && elem.has_scratchpad && feature44"
                    class="clickable p-inputgroup-addon"
                  >
                    <i class="fa fa-calculator" style="color: green"></i>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 15px">
                  <div class="col-xs-6 minmax">{{ (elem.min ? elem.min : 0) | number }}</div>
                  <div class="col-xs-6 minmax text-right">{{ (elem.max ? elem.max : 100) | number }}</div>
                </div>
                <input
                  type="range"
                  [disabled]="locked || !canEdit"
                  [min]="elem.min"
                  [max]="elem.max"
                  [(ngModel)]="elem.driver_value"
                  (change)="elem.updated = true"
                  [step]="elem.increment"
                />
              </div>
            </div>

            <div *ngSwitchCase="'financial'" class="panel-body">
              <div style="vertical-align: bottom">
                <div class="p-inputgroup">
                  <input
                    currencyMask
                    [disabled]="locked || !canEdit"
                    [options]="{ prefix: '', decimal: decimalSeparator, thousands: groupSeparator, precision: elem.factor_precision >= 0 ? elem.factor_precision : 2 }"
                    [(ngModel)]="elem.financial_value"
                    (keyup)="elem.updated = true"
                    class="form-control"
                  />
                  <div
                    [matTooltip]="trans.trans.scratchpad_tooltip.value"
                    matTooltipPosition="above"
                    (click)="onFinancialFactorCalcClick(elem)"
                    *ngIf="elem.financial_factor_id && elem.has_scratchpad && feature44"
                    class="clickable p-inputgroup-addon"
                  >
                    <i class="fa fa-calculator" style="color: green"></i>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 15px">
                  <div class="col-xs-6 minmax">{{ (elem.min ? elem.min : 0) | number }}</div>
                  <div class="col-xs-6 minmax text-right">{{ (elem.max ? elem.max : 100) | number }}</div>
                </div>
                <input
                  type="range"
                  [disabled]="locked || !canEdit"
                  [min]="elem.min"
                  [max]="elem.max"
                  [(ngModel)]="elem.financial_value"
                  (change)="elem.updated = true"
                  [step]="elem.increment"
                />
              </div>
            </div>

            <div *ngSwitchCase="'improvement'" class="panel-body">
              <div style="vertical-align: bottom">
                <div class="p-inputgroup">
                  <input
                    currencyMask
                    [disabled]="locked || !canEdit"
                    [options]="{
                      prefix: '',
                      decimal: decimalSeparator,
                      thousands: groupSeparator,
                      precision: elem.factor_precision >= 0 ? elem.factor_precision : 2,
                      allowNegative: elem.allowNegative
                    }"
                    [(ngModel)]="elem.impact"
                    (keyup)="elem.updated = true"
                    class="form-control"
                  />
                </div>
                <div class="row" style="margin-bottom: 15px">
                  <div class="col-xs-6 minmax">{{ (elem.min ? elem.min : 0) | number }}</div>
                  <div class="col-xs-6 minmax text-right">{{ (elem.max ? elem.max : 100) | number }}</div>
                </div>
                <input
                  type="range"
                  [disabled]="locked || !canEdit"
                  [min]="elem.min"
                  [max]="elem.max"
                  [(ngModel)]="elem.impact"
                  (change)="elem.updated = true"
                  [step]="elem.increment"
                />
              </div>
            </div>

            <div *ngSwitchCase="'scratch'" class="panel-body">
              <div style="vertical-align: bottom">
                <input
                  currencyMask
                  [disabled]="locked || !canEdit"
                  [options]="{
                    prefix: '',
                    decimal: decimalSeparator,
                    thousands: groupSeparator,
                    precision: elem.factor_precision >= 0 ? elem.factor_precision : 2,
                    allowNegative: elem.allowNegative
                  }"
                  [(ngModel)]="elem.operand"
                  (keyup)="elem.updated = true"
                  class="form-control"
                  style="margin-bottom: 10px"
                />
                <div class="row" style="margin-bottom: 15px">
                  <div class="col-xs-6 minmax">{{ (elem.min ? elem.min : 0) | number }}</div>
                  <div class="col-xs-6 minmax text-right">{{ (elem.max ? elem.max : 100) | number }}</div>
                </div>
                <input
                  type="range"
                  [disabled]="locked || !canEdit"
                  [min]="elem.min"
                  [max]="elem.max"
                  [(ngModel)]="elem.operand"
                  (change)="elem.updated = true"
                  [step]="elem.increment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-value-prop-flow-buttons
        *ngIf="view !== 'share'"
        [disabled]="assumptionSaveLoader"
        [moveToBottom]="!crm"
        (goNext)="updateAll()"
        (goBack)="back.emit()"
      ></app-value-prop-flow-buttons>
    </div>

    <p-sidebar [(visible)]="showFactor" styleClass="keywest_sidebar p-sidebar-xl" position="right">
      <div class="sidebar_wrapper">
        <div class="sidebar_header">
          {{ trans.trans.editScratchpad.value }}
        </div>
        <div class="sidebar_container">
          <div class="keywest_panel">
            <app-scratchpad-component
              *ngIf="showFactor"
              [valuePropId]="valueProp.id"
              (SaveAndUpdateCallback)="showFactor = false; loadFactorGroups(false, $event)"
              [factorTypeId]="templateSetting"
              [valueMetricId]="valueMetricId"
              [valueMetric]="valueMetric"
              [locked]="locked || !canEdit"
              [year]="1"
            ></app-scratchpad-component>
          </div>
        </div>
        <div class="btn_container">
          <app-are-you-sure
            *ngIf="!showFactor"
            matTooltipPosition="above"
            message="Are you sure?"
            class="btn cancel-confirm pull-right"
            icon=""
            text="Back"
            (callbackYes)="closeScratchpad()"
          ></app-are-you-sure>
        </div>
      </div>
    </p-sidebar>

    <div *ngIf="view === 'full' && valueProp">
      <!-- {{globalSearch}} -->
      <div class="row">
        <div class="col-md-4 col-xs-12">
          <div class="input-group keywest_search no-background">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input [(ngModel)]="globalSearch" type="text" [placeholder]="trans.trans.search.value" class="form-control" (ngModelChange)="filterAllTables($event)" />
            <span *ngIf="globalSearch !== ''" (click)="globalSearch = ''; filterAllTables('')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12">
          <div class="row">
            <div class="col-xs-12 marg-v">
              <span class="clickable pull-right" (click)="row.toggle($event)"
                >{{ trans.trans.rowLimit.value }} {{ pageLimit }}
                <span class="material-icons-outlined accordion"> arrow_drop_down </span>
              </span>
            </div>
          </div>
          <div class="row">
            <div [ngClass]="[valueProp.vp_can_edit ? 'col-xs-4' : 'col-xs-1']">
              <div class="batchEdit" *ngIf="valueProp.vp_can_edit">
                <button class="btn btn_customizable" *ngIf="false" (click)="assumptionsBatchEditMode(); funcExpand(true)">{{ trans.trans.edit_all_values.value }}</button>
                <button class="btn btn-danger btn-sm" *ngIf="!assumptionSaveLoader && assumptionsEditing" (click)="assumptionsCancelEdit()">
                  <i class="fa fa-times"></i> {{ trans.trans.cancel_changes.value }}
                </button>
                <button class="btn btn-success btn-sm" *ngIf="!assumptionSaveLoader && isAssumptionUpdated" (click)="updateAll()">
                  <i class="fa fa-save"></i> {{ trans.trans.save_changes.value }}
                </button>
                <img *ngIf="assumptionSaveLoader && isAssumptionUpdated" class="actionLoader" [src]="fullImagePath" />
              </div>
            </div>
            <div [ngClass]="[valueProp.vp_can_edit ? 'col-xs-8' : 'col-xs-11']">
              <div class="btn-group pull-right">
                <button ngbTooltip="{{ trans.trans.scroll_to_drivers.value }}" container="body" placement="top" (click)="scroll('driverPanel')" class="btn btn_customizable">
                  <i class="fa fa-arrow-right"></i> {{ trans.trans.drivers.value }}
                </button>
                <button ngbTooltip="{{ trans.trans.scroll_to_financials.value }}" container="body" placement="top" (click)="scroll('financialsPanel')" class="btn btn_customizable">
                  <i class="fa fa-arrow-right"></i> {{ trans.trans.financials.value }}
                </button>
                <button
                  ngbTooltip="{{ trans.trans.scroll_to_scratchpads.value }}"
                  container="body"
                  placement="top"
                  (click)="scroll('scratchpadsPanel')"
                  class="btn btn_customizable"
                >
                  <i class="fa fa-arrow-right"></i> {{ trans.trans.scratchpads.value }}
                </button>

                <button *ngIf="!expandAll" (click)="funcExpand(false)" class="btn btn_customizable"><i class="fa fa-minus"></i> {{ trans.trans.collapse.value }}</button>
                <button *ngIf="expandAll" (click)="funcExpand(true)" class="btn btn_customizable"><i class="fa fa-plus"></i> {{ trans.trans.expand.value }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="models_panel" style="margin-bottom: 20px">
        <div class="models_page_header_container_small">
          <div class="keywest_header">
            <div class="row">
              <div class="col-xs-12">
                <div class="title" id="driverPanel">
                  <div style="float: left">
                    <span class="material-icons-outlined accordion raised" (click)="expandDrivers = !expandDrivers">
                      {{ expandDrivers ? 'arrow_drop_down' : 'arrow_right' }}
                    </span>
                  </div>
                  <div style="float: left">
                    <span class="clickable" (click)="expandDrivers = !expandDrivers"
                      >{{ trans.trans.DriverFactors.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        (callback)="getTranslations()"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.DriverFactors"
                        key="vp_dashboard.assumptions.DriverFactors"
                      ></app-editTranslation></span
                    ><br />
                    <small style="font-size: 70%"
                      >{{ drivers?.length ? drivers.length : 0 }} {{ trans.trans.records_drivers.value
                      }}<app-editTranslation
                        *ngIf="showTranslate"
                        (callback)="getTranslations()"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.records_drivers"
                        key="vp_dashboard.assumptions.records_drivers"
                      ></app-editTranslation
                    ></small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="expandDrivers">
            <div class="col-xs-12">
              <p-table
                #tableAssumptionsDrivers
                [value]="drivers"
                [columns]="col_driver"
                [paginator]="true"
                [rows]="pageLimit"
                styleClass="keywest_table"
                tableStyleClass="keywest_table"
              >
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 48px"></th>
                    <th *ngFor="let col of columns; let index = index" [pSortableColumn]="col.field">
                      <div [ngStyle]="{ float: index > 0 ? 'right' : null }">
                        {{ trans.trans[col.transField].value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans[col.transField]"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-driver>
                  <tr>
                    <td style="width: 40px">
                      <span
                        class="material-icons-outlined accordion"
                        *ngIf="driver.metrics && driver.metrics.length"
                        (click)="driver.showBenefits = !driver.showBenefits"
                        placement="bottom"
                        [matTooltip]="trans.trans.viewBenefits.value"
                        matTooltipPosition="above"
                        container="body"
                      >
                        {{ driver.showBenefits ? 'arrow_drop_down' : 'arrow_right' }}
                      </span>
                    </td>
                    <td>
                      {{ driver.driver_factor }}
                      <span *ngIf="driver.driver_description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="driver.driver_description"> info </span>
                    </td>
                    <td class="text-right preventWordBreak">
                      <span
                        (click)="driver.showeditable = !driver.showeditable"
                        *ngIf="!driver.showeditable && driver.driver_value_fmt && valueProp.vp_can_edit && !assumptionsEditing"
                        class="editable"
                        placement="bottom"
                        [ngbTooltip]="tipContent"
                        container="body"
                      >
                        {{ driver.driver_value_un_fmt }}
                      </span>
                      <span *ngIf="!valueProp.vp_can_edit">
                        {{ driver.driver_value_un_fmt }}
                      </span>
                      <div class="batchEdit" *ngIf="assumptionsEditing">
                        <input
                          type="text"
                          [disabled]="locked || !canEdit"
                          name="driverFactor"
                          [(ngModel)]="driver.driver_value"
                          (input)="driver.updated = true; isAssumptionUpdated = true"
                          class="pull-right form-control"
                          currencyMask
                          [options]="{ prefix: '', thousands: groupSeparator, decimal: decimalSeparator, precision: driver.factor_precision >= 0 ? driver.factor_precision : 2, allowNegative: driver.allowNegative }"
                        />
                      </div>
                      <div class="input-group pull-right" style="display: inline-table" *ngIf="driver.showeditable && !assumptionsEditing">
                        <input
                          type="text"
                          [disabled]="locked || !canEdit"
                          name="driverFactor"
                          [(ngModel)]="driver.driver_value"
                          class="form-control"
                          currencyMask
                          [options]="{ prefix: '', thousands: groupSeparator, decimal: decimalSeparator, precision: driver.factor_precision >= 0 ? driver.factor_precision : 2, allowNegative: driver.allowNegative }"
                        />
                        <div class="input-group-btn">
                          <button (click)="editDriverValue(driver); driver.showeditable = !driver.showeditable" class="btn btn-success">
                            <i class="fa fa-check"></i>
                          </button>
                          <button (click)="driver.showeditable = !driver.showeditable" class="btn btn-danger">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>

                      <ng-template #tipContent>
                        <p>{{ trans.trans.DriverEstimate.value }}: {{ driver.driver_estimate_final }}</p>
                        <p>{{ trans.trans.DriverOverride.value }}: {{ driver.driver_override_final }}</p>
                      </ng-template>
                    </td>
                    <td *ngIf="!isCustomerShareType" class="text-right">
                      <span
                        (click)="driver.showeditableSourceType = !driver.showeditableSourceType"
                        *ngIf="!driver.showeditableSourceType && valueProp.vp_can_edit && !assumptionsEditing"
                        class="editable"
                      >
                        {{ driver.driver_source }}
                      </span>
                      <span *ngIf="!valueProp.vp_can_edit">
                        {{ driver.driver_source }}
                      </span>
                      <div class="batchEdit" *ngIf="assumptionsEditing">
                        <select
                          class="pull-right form-control m-t-xxs"
                          name="driverFactorSourceType"
                          [(ngModel)]="driver.driver_display_source_type_id"
                          (change)="sourceChanged(driver)"
                        >
                          <option *ngFor="let s of sourceTypes" [ngValue]="s.id">
                            {{ s.name }}
                          </option>
                        </select>
                      </div>
                      <div class="input-group pull-right" *ngIf="driver.showeditableSourceType && !assumptionsEditing">
                        <select class="form-control m-t-xxs" name="driverFactorSourceType" [(ngModel)]="driver.driver_source_type_id">
                          <option *ngFor="let s of sourceTypes" [ngValue]="s.id">
                            {{ s.name }}
                          </option>
                        </select>
                        <div class="input-group-btn">
                          <button (click)="editDriverValue(driver); driver.showeditableSourceType = !driver.showeditableSourceType" class="btn btn-success">
                            <i class="fa fa-check"></i>
                          </button>
                          <button (click)="driver.showeditableSourceType = !driver.showeditableSourceType" class="btn btn-danger">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="not-hover" *ngIf="driver.showBenefits" style="max-height: 400px; overflow-y: scroll">
                    <td colspan="4">
                      <app-benefits-mini-v2 [clean]="true" [valueProp]="valueProp" [benefits]="driver.metrics" [permissions]="permissions"></app-benefits-mini-v2>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>

      <div class="models_panel" style="margin-bottom: 20px">
        <div class="models_page_header_container_small">
          <div class="keywest_header">
            <div class="row">
              <div class="col-xs-12">
                <div class="title" id="financialsPanel">
                  <div style="float: left">
                    <span class="material-icons-outlined accordion raised" (click)="expandFinancial = !expandFinancial">
                      {{ expandFinancial ? 'arrow_drop_down' : 'arrow_right' }}
                    </span>
                  </div>
                  <div style="float: left">
                    <span class="clickable" (click)="expandFinancial = !expandFinancial"
                      >{{ trans.trans.financial.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        (callback)="getTranslations()"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.financial"
                        key="vp_dashboard.assumptions.financial"
                      ></app-editTranslation></span
                    ><br />
                    <small style="font-size: 70%"
                      >{{ financials?.length ? financials.length : 0 }} {{ trans.trans.records_financial.value
                      }}<app-editTranslation
                        *ngIf="showTranslate"
                        (callback)="getTranslations()"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.records_financial"
                        key="vp_dashboard.assumptions.records_financial"
                      ></app-editTranslation
                    ></small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="expandFinancial">
            <div class="col-xs-12">
              <p-table
                #tableAssumptionsFinancial
                [value]="financials"
                [columns]="colFinancial"
                [paginator]="true"
                [rows]="pageLimit"
                styleClass="keywest_table"
                tableStyleClass="keywest_table"
              >
                <ng-template pTemplate="header" let-columns let-index="rowIndex">
                  <tr>
                    <th style="width: 48px"></th>
                    <th *ngFor="let col of columns; let index = index" [pSortableColumn]="col.field">
                      <div [ngStyle]="{ float: index > 0 ? 'right' : null }">
                        {{ trans.trans[col.field].value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans[col.field]"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-financial>
                  <tr>
                    <td>
                      <span
                        class="material-icons-outlined accordion"
                        *ngIf="financial.metrics && financial.metrics.length"
                        (click)="financial.showBenefits = !financial.showBenefits"
                        placement="bottom"
                        [matTooltip]="trans.trans.viewBenefits.value"
                        matTooltipPosition="above"
                        container="body"
                      >
                        {{ financial.showBenefits ? 'arrow_drop_down' : 'arrow_right' }}
                      </span>
                    </td>
                    <td>
                      {{ financial.financial_factor }}
                      <span *ngIf="financial.financial_description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="financial.financial_description">
                        info
                      </span>
                    </td>
                    <td class="text-right preventWordBreak">
                      <span
                        (click)="financial.showeditable = !financial.showeditable"
                        *ngIf="!financial.showeditable && financial.financial_value_fmt && valueProp.vp_can_edit && !assumptionsEditing"
                        class="editable pull-right"
                        placement="bottom"
                        [ngbTooltip]="tipContent"
                        container="body"
                      >
                        {{ financial.financial_value_un_fmt }}
                      </span>
                      <span *ngIf="!valueProp.vp_can_edit" class="pull-right">
                        {{ financial.financial_value_un_fmt }}
                      </span>
                      <div class="batchEdit" *ngIf="assumptionsEditing">
                        <input
                          type="text"
                          [disabled]="locked || !canEdit"
                          name="driverFactor"
                          [(ngModel)]="financial.financial_value"
                          (input)="financial.updated = true; isAssumptionUpdated = true"
                          class="pull-right form-control"
                          currencyMask
                          [options]="{
                            prefix: '',
                            decimal: decimalSeparator,
                            thousands: groupSeparator,
                            precision: financial.factor_precision >= 0 ? financial.factor_precision : 2,
                            allowNegative: financial.allowNegative
                          }"
                        />
                      </div>
                      <div class="input-group pull-right" style="display: inline-table" *ngIf="financial.showeditable && !assumptionsEditing">
                        <input
                          type="text"
                          [disabled]="locked || !canEdit"
                          name="driverFactor"
                          [(ngModel)]="financial.financial_value"
                          class="form-control"
                          currencyMask
                          [options]="{
                            prefix: '',
                            decimal: decimalSeparator,
                            thousands: groupSeparator,
                            precision: financial.factor_precision >= 0 ? financial.factor_precision : 2,
                            allowNegative: financial.allowNegative
                          }"
                        />
                        <div class="input-group-btn">
                          <button (click)="editFinancialValue(financial); financial.showeditable = !financial.showeditable" class="btn btn-success">
                            <i class="fa fa-check"></i>
                          </button>
                          <button (click)="financial.showeditable = !financial.showeditable" class="btn btn-danger">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>

                      <ng-template #tipContent>
                        <p>{{ trans.trans.FinancialEstimate.value }}: {{ financial.financial_estimate_final }}</p>
                        <p>{{ trans.trans.FinancialOverride.value }}: {{ financial.financial_override_final }}</p>
                      </ng-template>
                    </td>

                    <td *ngIf="!isCustomerShareType">
                      <span
                        (click)="financial.showeditableSourceType = !financial.showeditableSourceType"
                        *ngIf="!financial.showeditableSourceType && valueProp.vp_can_edit && !assumptionsEditing"
                        class="editable pull-right"
                      >
                        {{ financial.financial_source }}
                      </span>
                      <span *ngIf="!valueProp.vp_can_edit" class="pull-right">
                        {{ financial.financial_source }}
                      </span>

                      <div class="batchEdit" *ngIf="assumptionsEditing">
                        <select
                          class="pull-right form-control m-t-xxs"
                          name="driverFactorSourceType"
                          [(ngModel)]="financial.financial_source_type_id"
                          (change)="sourceChanged(financial)"
                        >
                          <option *ngFor="let s of sourceTypes" [ngValue]="s.id">
                            {{ s.name }}
                          </option>
                        </select>
                      </div>
                      <div class="pull-right input-group" *ngIf="financial.showeditableSourceType && !assumptionsEditing">
                        <select class="form-control m-t-xxs" name="driverFactorSourceType" [(ngModel)]="financial.financial_source_type_id">
                          <option *ngFor="let s of sourceTypes" [ngValue]="s.id">
                            {{ s.name }}
                          </option>
                        </select>
                        <div class="input-group-btn">
                          <button (click)="editFinancialValue(financial); financial.showeditableSourceType = !financial.showeditableSourceType" class="btn btn-success">
                            <i class="fa fa-check"></i>
                          </button>
                          <button (click)="financial.showeditableSourceType = !financial.showeditableSourceType" class="btn btn-danger">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="not-hover" *ngIf="financial.showBenefits" style="max-height: 400px; overflow-y: scroll">
                    <td colspan="4">
                      <app-benefits-mini-v2 [clean]="true" [valueProp]="valueProp" [benefits]="financial.metrics" [permissions]="permissions"></app-benefits-mini-v2>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>

      <div class="models_panel" style="margin-bottom: 20px">
        <div class="models_page_header_container_small">
          <div class="keywest_header">
            <div class="row">
              <div class="col-xs-12">
                <div class="title" id="scratchpadsPanel">
                  <div style="float: left">
                    <span class="material-icons-outlined accordion raised" (click)="expandScratchpads = !expandScratchpads">
                      {{ expandScratchpads ? 'arrow_drop_down' : 'arrow_right' }}
                    </span>
                  </div>
                  <div style="float: left">
                    <span class="clickable" (click)="expandScratchpads = !expandScratchpads"
                      >{{ trans.trans.scratchpad.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        (callback)="getTranslations()"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.scratchpad"
                        key="vp_dashboard.assumptions.scratchpad"
                      ></app-editTranslation></span
                    ><br />
                    <small style="font-size: 70%"
                      >{{ scratchpadUnits?.length ? scratchpadUnits.length : 0 }} {{ trans.trans.records_scratch.value
                      }}<app-editTranslation
                        *ngIf="showTranslate"
                        (callback)="getTranslations()"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.records_scratch"
                        key="vp_dashboard.assumptions.records_scratch"
                      ></app-editTranslation
                    ></small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="expandScratchpads">
            <div class="col-xs-12">
              <p-table
                #tableAssumptionsScratchpads
                [value]="scratchpadUnits"
                [columns]="colScratchpads"
                [paginator]="true"
                [rows]="pageLimit"
                styleClass="keywest_table"
                tableStyleClass="keywest_table"
              >
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th width="48px" style="width: 48px"></th>
                    <th *ngFor="let col of columns; let index = index" [pSortableColumn]="col.field">
                      <div [ngStyle]="{ float: index > 0 ? 'right' : null }">
                        {{ trans.trans[col.field].value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans[col.field]"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-scratchPad let-rowIndex="rowIndex">
                  <tr>
                    <td width="48px">
                      <span
                        class="material-icons-outlined accordion"
                        *ngIf="scratchPad.metrics && scratchPad.metrics.length"
                        (click)="scratchPad.showBenefits = !scratchPad.showBenefits"
                        [matTooltip]="trans.trans.viewBenefits.value"
                        matTooltipPosition="above"
                      >
                        {{ scratchPad.showBenefits ? 'arrow_drop_down' : 'arrow_right' }}
                      </span>
                    </td>
                    <td>
                      {{ scratchPad.name }}
                      <span *ngIf="scratchPad.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="scratchPad.description"> info </span>
                    </td>
                    <td class="text-right" style="width: 125px; white-space: nowrap">{{ scratchPad.unit_type }}</td>
                    <td class="text-right" style="width: 125px; white-space: nowrap">{{ scratchPad.unit_abbr }}</td>
                    <td style="width: 250px" class="text-right preventWordBreak">
                      <span (click)="scratchPad.showeditable = !scratchPad.showeditable" *ngIf="!scratchPad.showeditable && !assumptionsEditing" class="editable">
                        {{ scratchPad.operand_value }}
                        {{ scratchPad.unit_abbr !== '#' ? scratchPad.unit_abbr : '' }}
                      </span>

                      <div *ngIf="assumptionsEditing">
                        <input
                          type="text"
                          [disabled]="locked || !canEdit"
                          name="scratchPad"
                          [(ngModel)]="scratchPad.operand"
                          (input)="scratchPad.updated = true; isAssumptionUpdated = true"
                          class="pull-right form-control"
                          currencyMask
                          [options]="{
                            prefix: '',
                            decimal: decimalSeparator,
                            thousands: groupSeparator,
                            precision: scratchPad.factor_precision >= 0 ? scratchPad.factor_precision : 2,
                            allowNegative: scratchPad.allowNegative
                          }"
                        />
                      </div>

                      <div class="batchEdit" *ngIf="scratchPad.showeditable && !assumptionsEditing">
                        <div class="input-group pull-right" style="display: inline-table">
                          <input
                            type="text"
                            [disabled]="locked || !canEdit"
                            name="scratchPad"
                            [(ngModel)]="scratchPad.operand"
                            (input)="scratchPad.updated = true; isAssumptionUpdated = true"
                            class="pull-right form-control"
                            currencyMask
                            [options]="{
                              prefix: '',
                              decimal: decimalSeparator,
                              thousands: groupSeparator,
                              precision: scratchPad.factor_precision >= 0 ? scratchPad.factor_precision : 2,
                              allowNegative: scratchPad.allowNegative
                            }"
                          />
                          <div class="input-group-btn">
                            <span (click)="editScratchpadUnit(scratchPad, rowIndex)" class="btn btn-success">
                              <i class="fa fa-check"></i>
                            </span>
                            <span (click)="scratchPad.showeditable = !scratchPad.showeditable" class="btn btn-danger">
                              <i class="fa fa-times"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="not-hover" *ngIf="scratchPad.showBenefits" style="max-height: 400px; overflow-y: scroll">
                    <td colspan="5">
                      <app-benefits-mini-v2 [clean]="true" [valueProp]="valueProp" [benefits]="scratchPad.metrics" [permissions]="permissions"></app-benefits-mini-v2>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
