import { Injectable } from '@angular/core';
import { AssetTags, Tag } from '@shared/models/asset-tag.models';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';
import { AssetTagArray } from './asset-tag-array.enum';

@Injectable()
export class CustomAssetTagService {
  constructor(private commonService: CommonService) {}

  public getAssetTags(arrayName?: string): Observable<any> {
    let endpoint = 'account/asset-tags/?show_all=1';
    if (arrayName) {
      endpoint = `account/asset-tags/?show_all=1&array_name=${arrayName}`;
    }
    return this.commonService.getAPIService(endpoint);
  }

  public createAssetTag(payload: AssetTags): Observable<any> {
    let endpoint = 'account/asset-tags';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public deleteAssetTag(id: string): Observable<any> {
    let endpoint = `account/delete-asset-tag/${id}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  public editAssetTag(payload: AssetTags): Observable<any> {
    let endpoint = 'account/asset-tags';
    return this.commonService.postAPIService(endpoint, payload);
  }

  createTagPayload(id: number | string, name: string, tag: string, array_name: AssetTagArray, active: boolean, assetId?: string): AssetTags {
    const tagOut: Tag = {
      id: assetId, // Send if updating, if not sent, new tag is created
      name,
      description: '',
      tag,
      active: active ? '1' : '0',
      metadata: {
        array_name,
        id: id.toString(),
        field: 'name',
        format_type: 'string',
        additional_tags: [
          {
            field: 'name',
          },
          {
            field: 'description',
          },
          {
            field: 'vc_yr1_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr2_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr3_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr4_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr5_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr6_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr7_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr8_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr9_total',
            format_type: 'currency',
          },
          {
            field: 'vc_yr10_total',
            format_type: 'currency',
          },
          {
            field: 'vc_total',
            format_type: 'currency',
          },
        ],
      },
    };
    if (array_name === AssetTagArray.BenefitGroups) {
      tagOut.metadata.additional_tags.push({
        field: 'tco_name',
      });
    }

    if (array_name === AssetTagArray.Benefits) {
      tagOut.metadata.additional_tags = [
        {
          field: 'metric_name',
        },
        {
          field: 'tco_name',
        },
        {
          field: 'description',
        },
        {
          field: 'metric_note',
        },
        {
          field: 'metric_note_2',
        },
        {
          field: 'metric_note_3',
        },
        {
          field: 'features',
        },
        {
          field: 'year1_benefits',
          format_type: 'currency',
        },
        {
          field: 'year2_benefits',
          format_type: 'currency',
        },
        {
          field: 'year3_benefits',
          format_type: 'currency',
        },
        {
          field: 'year4_benefits',
          format_type: 'currency',
        },
        {
          field: 'year5_benefits',
          format_type: 'currency',
        },
        {
          field: 'year6_benefits',
          format_type: 'currency',
        },
        {
          field: 'year7_benefits',
          format_type: 'currency',
        },
        {
          field: 'year8_benefits',
          format_type: 'currency',
        },
        {
          field: 'year9_benefits',
          format_type: 'currency',
        },
        {
          field: 'year10_benefits',
          format_type: 'currency',
        },
        {
          field: 'total_benefits',
          format_type: 'currency',
        },
        {
          field: 'metric_phase_0',
        },
        {
          field: 'metric_phase_1',
        },
        {
          field: 'metric_phase_2',
        },
        {
          field: 'metric_phase_3',
        },
        {
          field: 'metric_phase_4',
        },
        {
          field: 'metric_phase_5',
        },
        {
          field: 'metric_phase_6',
        },
        {
          field: 'metric_phase_7',
        },
        {
          field: 'metric_phase_8',
        },
        {
          field: 'metric_phase_9',
        },
        {
          field: 'metric_phase_10',
        },
      ];
    }
    if (array_name === AssetTagArray.BenefitTypes) {
      tagOut.metadata.additional_tags = [
        {
          field: 'name',
        },
        {
          field: 'description',
        },
        {
          field: 'year1_total',
          format_type: 'currency',
        },
        {
          field: 'year2_total',
          format_type: 'currency',
        },
        {
          field: 'year3_total',
          format_type: 'currency',
        },
        {
          field: 'year4_total',
          format_type: 'currency',
        },
        {
          field: 'year5_total',
          format_type: 'currency',
        },
        {
          field: 'year6_total',
          format_type: 'currency',
        },
        {
          field: 'year7_total',
          format_type: 'currency',
        },
        {
          field: 'year8_total',
          format_type: 'currency',
        },
        {
          field: 'year9_total',
          format_type: 'currency',
        },
        {
          field: 'year10_total',
          format_type: 'currency',
        },
        {
          field: 'total',
          format_type: 'currency',
        },
      ];
    }
    return { tags: [tagOut] };
  }
}
