<div class='row'>
	<div class='col-xs-12'>
		<span *ngFor="let item of caseStudy.tags; let i = index" class='btn casestudy_tag'>
			{{item}}
			<i *ngIf="can_edit" (click)='removeTag(i)' [matTooltip]='trans.trans.remove_tag.value' class='clickable link remove-icon fa fa-times'></i>
		</span>
	</div>
</div>

<div class="row" *ngIf="can_edit">
	<div class="col-md-12">
		<p-chips [(ngModel)]="tags"></p-chips><br>
		<small>{{ trans.trans.save_action.value }}</small>
		<a (click)='saveCaseStudy()' class='btn btn_customizable marg-h'>{{ trans.trans.save.value }}</a><br>
	</div>
</div>