import { Injectable } from '@angular/core';

@Injectable ()

export class ScenarioCombinedScalersTranslations {

    public config: any = {
        component: 'scenarioCombinedScalers',
        display_name: 'Define Scenario Scalers',
        description: 'Scalers component for the define scenario step process'
    };

    public trans: any = {
        scalerTitle: '3. Scale Your Value',
        sizeYourValue: 'Size Your Value',
        primaryScaler: 'Primary Scalers',
        continue: 'Continue',
        caclulate: 'Calculate',
        areYouSure: 'Are you sure?',
        cancel: 'Cancel',
        save: 'Save',
        checkScalers: 'Please check these scalers before proceeding'
    };
}