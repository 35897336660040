import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { SituationsSharedModule } from '@shared_components/situations-shared/situations-shared.module';
import { SituationsSharedTranslations } from '@shared_components/situations-shared/situations-shared.translation';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';
import { ScenarioCombinedComponentRepv2 } from './combined.component';
import { DefaultTranslations } from './combined.translation';
import { RSV2AccountModule } from './views/account/account.module';
import { RSV2SCAdvancedComponent } from './views/advanced/advanced.component';
import { RSV2SCModelsComponent } from './views/models/rsv2-sc-models.component';
import { RSV2SCScalersComponent } from './views/scalers/scalers.component';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { GuidedDiscoveryModule } from '../guided_discovery/guided-discovery.module';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [
    InputSwitchModule,
    RSV2AccountModule,
    DLProgressbarModule,
    AreYouSureModule,
    TreeModule,
    NgbModule,
    MatTooltipModule,
    SidebarModule,
    DropdownModule,
    FieldsetModule,
    TableChartModule,
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    EditTranslationsModule,
    CurrencyMaskModule,
    GuidedDiscoveryModule,
    SituationsSharedModule,
    CheckboxModule,
    TableModule,
    NoRecordsModule,
    RadioButtonModule,
    ValuePropFlowButtonsModule,
    FunctionPipeModule,
    ProgressBarModule,
  ],
  declarations: [RSV2SCAdvancedComponent, RSV2SCModelsComponent, RSV2SCScalersComponent, ScenarioCombinedComponentRepv2],
  providers: [DefaultTranslations, TranslationsV2Service, AccountWorkflowService, SituationsSharedTranslations],
  exports: [ScenarioCombinedComponentRepv2],
})
export class RSV2ScenarioCombinedModule {}
