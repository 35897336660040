<div class="row" *ngIf="style2022$ | async">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10">
	  <a class="back-profile" [routerLink]="(style2022$ | async) ? ['/settings'] : ['/profile']"><i class="fa fa-chevron-left"></i> {{ (style2022$ | async) ? trans.trans.backToSettings.value : trans.trans.backToProfile.value }}</a>
	</div>
  </div>

<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">{{ trans.trans.exchangeRates.value }}</div>
        </div>
        <div class="col-xs-2">
          <a (click)="funcSave()" class="btn btn_customizable pull-right">{{ trans.trans.save.value }}</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input [(ngModel)]="strSearch" (keyup)="tableCurrency.filter($any($event).target.value, 'name', 'contains')" type="text" placeholder="Search" class="form-control" />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableCurrency.filter('', 'name', 'contains')" class="input-group-addon clickable"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>

        <p-table #tableCurrency [value]="currencies" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th>{{ trans.trans.currency.value }}</th>
              <th>{{ trans.trans.enabled.value }}</th>
              <th>{{ trans.trans.webCalcEnabled.value }}</th>
              <th>{{ trans.trans.exchangeRates.value }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-elem>
            <tr>
              <td>{{ elem.name }}</td>
              <td>
                <input type="checkbox" [(ngModel)]="elem.checked" *ngIf="!(style2022$ | async)" style="margin-right: 10px; margin-top: 10px; float: left" />
                <p-checkbox [binary]="true" [(ngModel)]="elem.checked" [value]="elem.checked" *ngIf="style2022$ | async"></p-checkbox>
              </td>
              <td>
                <input type="checkbox" [disabled]="!elem.checked" [(ngModel)]="+elem.web_calc_enabled" *ngIf="!(style2022$ | async)" style="margin-right: 10px; margin-top: 10px; float: left" />
                <p-checkbox [binary]="true" [disabled]="!elem.checked" [(ngModel)]="+elem.web_calc_enabled" [value]="+elem.web_calc_enabled" *ngIf="style2022$ | async"></p-checkbox>
              </td>
              <td>
                <input type="number" min= 0 [disabled]="!elem.checked" [(ngModel)]="elem.value" class="form-control" style="max-width: 100px" />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
