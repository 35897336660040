import { Injectable } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import { Solution, ValuePropSolutionPayload } from '@shared/models/value-prop.model';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable()
export class ValuepositionService {
  service_url: string;
  constructor(private commonService: CommonService) {
    this.service_url = this.commonService.getServiceUrl();
  }

  public getValuePositionList(payload) {
    let endpoint = "valuePosition/propShort";
    return this.commonService.postAPIService(endpoint, payload);
  }


  public getCompanyExecutive(params) {
    let endpoint = "companies/executives/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public getImpactTypes() {
    let endpoint = "valuePosition/ImpactTypes";
    return this.commonService.getAPIService(endpoint);
  }

  public getBusinessSituations(params) {
    let endpoint = "valuePosition/guidedDiscovery/situations/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public getBusinessBenefits(params) {
    let endpoint = "valuePosition/guidedDiscovery/benefits/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public getBusinessModels(params) {
    let endpoint = "valuePosition/guidedDiscovery/solutions/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public getBusinessModelsQuickCreate(params) {
    let endpoint = "solutions/solutionList/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public saveCompanySession(payload) {
    let endpoint = "valuePosition/session/create";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveImpactTypes(payload) {
    let endpoint = "valuePosition/impact/create";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveArea(payload) {
    let endpoint = "valuePosition/area/create";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getBusinessOutcomes(params) {
    let endpoint = "valuePosition/availableBusinessOutcomes/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public BusinessValueCategories(params) {
    let endpoint = "valuePosition/availableValueCategories/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public getAccountAreas(params) {
    let endpoint = "valuePosition/AccountAreas/impacts/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public saveBusinessOutcomes(payload) {
    let endpoint = "valuePosition/guidedDiscovery/businessOutcome/new";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveValueCategories(payload) {
    let endpoint = "valuePosition/guidedDiscovery/valueCategory/new";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getCompanySituations(payload) {
    let endpoint = "solutions/situation/bySolution/id/" + payload;
    return this.commonService.getAPIService(endpoint);
  }

  public createValueProp(payload) {
    let endpoint = "valueProps/create";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getAvailableScalers(params) {
    let endpoint = "valueProps/availableScalers/" + params;
    return this.commonService.getAPIService(endpoint);
  }

  public saveBenfits(payload) {
    let endpoint = "valuePosition/guidedDiscovery/create/benefits";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveSituations(payload) {
    let endpoint = "valuePosition/guidedDiscovery/create/situations";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public discoveryFinishReview(payload) {
    let endpoint = "valuePosition/guidedDiscovery/create/valueProp";
    return this.commonService.postAPIService(endpoint, payload);
  }

  public quickCreateSolution(id: string | number, payload: ValuePropSolutionPayload) {
    let endpoint = "valueProps/solution/create/" + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public quickCreateSituation(id, payload) {
    let endpoint = "valueProps/situation/" + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public quickCreateScaler(params, payload) {
    let endpoint = "valueProps/scaler/create/" + params;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public deleteValuePropSolution(valuePropId, solutionId) {
    let endpoint = "valueProps/deleteValuePropSolution/" + valuePropId + "/solutionId/" + solutionId;
    return this.commonService.deleteAPIService(endpoint);
  }
  public getValuePropSolutions(valuePropId: string | number): Observable<APIResult<Solution[]>> {
    let endpoint = "valueProps/solutions/" + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public saveGuidedDiscoveryAnswers(params, payload) {
    let endpoint = "valuePosition/guidedDiscovery/answers/" + params;
    return this.commonService.postAPIService(endpoint, payload);
  }

}
