import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DiscoveryQuestion } from '@data/services/valueprop/models/discovery-question.model';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ErrorMessagesService } from '@services/error-messages.service';
import { NotificationService } from '@services/notification.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, startWith, takeUntil } from 'rxjs/operators';
import { ReviewBaselineTranslation } from '../review-baseline/review-baseline.translation';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';

@Component({
  selector: 'app-success-criteria',
  templateUrl: './success-criteria.component.html',
  styleUrls: ['./success-criteria.component.scss'],
})
export class SuccessCriteriaComponent implements OnInit {
  @Input() valuePropId: string;
  @Input() showNotes = false;

  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;
  breadcrumbKeyValues: Breadcrumbs;
  loading = false;
  loadingNoteSave = false;
  successQuestions: DiscoveryQuestion[];
  successQuestionsFiltered: DiscoveryQuestion[];

  searchField = new FormControl('');
  successSidebarOpen = false;
  selectedQuestion: DiscoveryQuestion;
  questionForm: FormGroup;
  errorDict: Map<string, string> = new Map();
  editIndex: number;
  noteControl = new FormControl('', [Validators.required]);

  constructor(
    private commonService: CommonService,
    public trans: ReviewBaselineTranslation,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private valuePropService: ValuepropService,
    private errorMessageService: ErrorMessagesService,
    public businessReviewService: TrackerBusinessReviewService
  ) {}

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      answer: ['', [Validators.required]],
    });

    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getQuestions();
    this.searchField.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), startWith(''), takeUntil(this.ngUnsubscribe)).subscribe((searchTerm: string) => {
      if (!this.successQuestionsFiltered) {
        return;
      }
      if (this.isEmptyInputValue(searchTerm)) {
        this.successQuestionsFiltered = [...this.successQuestions];
        return;
      }
      this.successQuestionsFiltered = this.successQuestions.filter((q) => {
        return q.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    });

    this.errorDict = new Map(this.errorMessageService.getFormErrors(this.questionForm.controls));

    this.questionForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe), debounceTime(100)).subscribe(() => {
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.questionForm.controls));
    });
  }

  isEmptyInputValue(value: any): boolean {
    return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
  }

  resetSearchField(): void {
    this.searchField.reset();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getQuestions() {
    this.loading = true;
    this.valuePropService
      .getDiscoveryQuestions(this.valuePropId, '42')
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        if (response.result.length) {
          this.successQuestions = response.result.sort((a, b) => +a.sequence - +b.sequence);
          this.successQuestionsFiltered = response.result.sort((a, b) => +a.sequence - +b.sequence);
        }
      });
  }

  editCriteria(question: DiscoveryQuestion, index: number) {
    this.selectedQuestion = question;
    this.editIndex = index;
    this.questionForm.patchValue({
      name: question.name,
      answer: question.answer,
    });
    this.successSidebarOpen = true;
  }

  saveQuestion() {
    if (this.questionForm.invalid) {
      return;
    }
    this.loading = true;
    this.successSidebarOpen = false;
    const payload = [
      {
        value_prop_id: this.valuePropId,
        account_discovery_question_id: this.selectedQuestion.account_discovery_question_id,
        answer: this.questionForm.get('answer').value,
      },
    ];

    this.valuePropService
      .putDiscoveryQuestions(this.valuePropId, { discovery_questions: payload })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        if (response.result) {
          const foundIndex = this.successQuestions.findIndex((q) => q.account_discovery_question_id === this.selectedQuestion.account_discovery_question_id);
          this.successQuestions[foundIndex].answer = this.questionForm.get('answer').value;
          this.successQuestionsFiltered = [...this.successQuestions];
          this.notificationService.success(this.trans.trans.questionUpdatedSuccess.value, false);
        } else {
          this.notificationService.error(this.trans.trans.questionUpdatedFailed.value, false);
        }
      });
  }

  addNote() {
    const payload = {
      account_discovery_question_id: this.selectedQuestion.account_discovery_question_id,
      note: this.noteControl.value,
    };
    this.valuePropService
      .postDiscoveryQuestionNotes(this.valuePropId, payload)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingNoteSave = false))
      )
      .subscribe((response) => {
        if (response) {
          this.noteControl.reset();
          this.selectedQuestion.notes.unshift(response);
          this.successQuestions = [...this.successQuestionsFiltered];
        }
      });
  }

  deleteNote(noteId: string) {
    this.valuePropService
      .deleteDiscoveryQuestionNotes(noteId, this.valuePropId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingNoteSave = false))
      )
      .subscribe((response) => {
        if (response) {
          this.selectedQuestion.notes = this.selectedQuestion.notes.filter((n) => n.id !== noteId);
          this.successQuestions = [...this.successQuestionsFiltered];
        }
      });
  }

  cancel() {
    this.successSidebarOpen = false;
    this.questionForm.reset();
  }
}
