import { Injectable } from '@angular/core';

@Injectable()
export class Rsv2ScModelsTranslations {
  public config: any = {
    component: 'rsv2-sc-models',
    display_name: 'Models',
    description: 'Scenario Section in rep simple - Models',
  };
  public trans: any = {
    models: 'Models',
    no_models: 'Please click on the Pencil icon in this section to select the Model (solution) you are selling',
    choose_category_type: 'Choose Category Type',
    no_models_category: 'No Models for this category, please try a different one by selecting from the dropdown above.',
    save: 'Save',
    are_you_sure: 'Are you sure?',
    nameHeader: 'Model Name',
    solutionsAssignedMessage: 'Solution assigned Successfully',
    tooltipRequire: 'Models are required to continue',
    loadingModels: 'Loading Models',
    placeholderSearch: 'Search',
    noRecords: 'No Records Found',
    cancel: 'Cancel',
    back: 'Back'
  };
}
