import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CapabilitiesService } from '@data/services/capabilities/capabilities.service';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { FunctionalObjectivesService } from '@data/services/func_objectives/func_objectives.service';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { PainPointsService } from '@data/services/pain_points/pain.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { AddValueCategoryModalComponent } from '../add-value-category-modal/add-value-category-modal.component';

@Component({
  selector: 'app-add-benefit-modal',
  templateUrl: './add-benefit-modal.component.html',
  styleUrls: ['./add-benefit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddBenefitModalComponent implements OnInit, OnDestroy {
  @Input() solutionId: number;
  @Input() selectedBenefit: { [klass: string]: any };
  @Input() selectedImportBenefit: { [klass: string]: any };
  @Input() defaultTerm = '3';
  @Input() canEdit = false;

  @ViewChild('addBenefitsForm') addBenefitsForm: NgForm;
  @Output() callbackDone = new EventEmitter();
  unitTypeList: ExpenseType[] = [];
  benefitTypeList: Array<{ [klass: string]: any }> = [];
  unitType: string;

  impactAreas: { [klass: string]: any }[] = [];
  filteredImpactAreas: { [klass: string]: any }[] = [];
  selectedImpactAreas: { [klass: string]: any }[] = [];

  painPoints: { [klass: string]: any }[] = [];
  filteredPainPoints: { [klass: string]: any }[] = [];
  selectedPainPoints: { [klass: string]: any }[] = [];

  capabilities: { [klass: string]: any }[] = [];
  filteredCapabilities: { [klass: string]: any }[] = [];
  selectedCapabilities: { [klass: string]: any }[] = [];

  opObjectives: { [klass: string]: any }[] = [];
  filteredOpObjectives: { [klass: string]: any }[] = [];
  selectedOpObjectives: { [klass: string]: any }[] = [];

  funcObjectives: { [klass: string]: any }[] = [];
  filteredFuncObjectives: { [klass: string]: any }[] = [];
  selectedFuncObjectives: any;

  annualBenefitsList: Array<{ [klass: string]: any }> = [];
  annualBenefits: { [klass: string]: any };
  financialDriverFactorList: Array<{ [klass: string]: any }> = [];
  improvementTypeList: Array<{ [klass: string]: any }> = [];
  financialDriverFactor: any;
  selectedImprovementType: { [klass: string]: any } = [];
  impactList: Array<{ [klass: string]: any }> = [];
  impact: { [klass: string]: any };
  scaleList: Array<{ [klass: string]: any }> = [];
  scale: { [klass: string]: any };
  defaultNA: boolean;
  driverFactorList: Array<{ [klass: string]: any }> = [];
  driverFactor: any;
  uniqueList: Array<{ [klass: string]: any }> = [];
  uniqueTypes: { [klass: string]: any };
  valueCategoryBenefits: { [klass: string]: any };
  valueCategoryBenefitsList: Array<{ [klass: string]: any }> = [];
  impactAreasList: Array<{ [klass: string]: any }> = [];
  noAreaSelect = true;
  impactAreaIdList: Array<{ [klass: string]: any }> = [];
  benefit: { [klass: string]: any } = {
    phase_0: 100,
    phase_1: 100,
    phase_2: 100,
    phase_3: 100,
    phase_4: 100,
  };

  benefitsName: string;
  benefitsDescription: string;
  benefitsFeatures: string;
  driverBaselineAnswer: string;
  conservative: string;
  aggressive: string;
  financicalAnswer: string;
  porbable: string;
  situationsList: Array<{ [klass: string]: any }> = [];
  createFactor = false;
  showActionLoader = false;
  showImprovementActionLoader = false;
  newDriverFactor: string;
  FinancialFactor: string;
  newImprovementFactor: string;
  createDriverFlag = false;
  createImprovementFlag = false;
  fullImagePath: string;
  imageUrl: string;

  dropdownSettings: { [klass: string]: any } = {};
  dropdownSettings1: { [klass: string]: any } = {};
  improvementTypeSettings: { [klass: string]: any } = {};
  currentEffort: { [klass: string]: any };
  accountFeatures = false;
  driverFactorLoader = false;
  projects: { [klass: string]: any }[] = [];
  projectId: { [klass: string]: any };
  masterValuePropFeature = false;
  showCurrentEffort = false;
  hardSoftDropdown: { [klass: string]: any }[];

  subscription: Subscription;
  subscriptiongetUnitTypes: Subscription;
  subscriptiongetAreaTypes: Subscription;
  subscriptiongetAccrualTypes: Subscription;
  subscriptiongetFinancialTypes: Subscription;
  subscriptiongetScratchpadImprovementTypes: Subscription;
  subscriptiongetImpactTypes: Subscription;
  subscriptiongetAvailableScalers: Subscription;
  subscriptiongetDriverFactorTypes: Subscription;
  subscriptiongetDriverFactor: Subscription;
  subscriptiongetUniqueTypes: Subscription;
  subscriptiongetValueCategories: Subscription;
  subscriptionsaveSolutionBenefits: Subscription;
  subscriptionupdateSolutionBenefits: Subscription;
  subscriptioncreateDriverFactor: Subscription;
  subscriptioncreateFinancialFactor: Subscription;
  subscriptioncreateImprovementFactor: Subscription;
  subscriptiongetProjects: Subscription;
  subscriptionGetExpenseTypes: Subscription;
  expenseTypes: { [klass: string]: any }[] = [];
  expenseTypeId: unknown;
  accountId: string;

  driverTerm: { [klass: string]: any }[] = [];
  phasingTerm: { [klass: string]: any }[] = [];
  quant = true;
  soft: unknown;
  benefitType: { [klass: string]: any };
  tcoName: string;
  hasPhasing: { [klass: string]: any };

  loadedTot = 0;

  hasFeature46 = false;

  constructor(
    private solutionService: SolutionService,
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public trans: Translations,
    private painPointsService: PainPointsService,
    private capabilitiesService: CapabilitiesService,
    private OpObjectivesService: OperationalObjectivesService,
    private FuncObjectivesService: FunctionalObjectivesService
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    this.imageUrl = this.commonService.getImageUrl();

    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    if (this.addBenefitsForm) {
      this.addBenefitsForm.reset();
    }
    const term = Number(this.defaultTerm);
    this.driverTerm = [];
    this.phasingTerm = [];

    for (let i = 0; i < term; i++) {
      this.driverTerm.push({ val: 0 });
      this.phasingTerm.push({ val: 100 });
    }
    this.quant = true;

    const features = sessionStorage.getItem('features');

    if (features.indexOf('15') >= 0) {
      this.masterValuePropFeature = true;
      this.getProjects();
    }

    if (features.indexOf('46') >= 0) {
      this.hasFeature46 = true;
    }

    this.hardSoftDropdown = [
      { value: '0', label: 'Hard' },
      { value: '1', label: 'Soft' },
      { value: '2', label: 'Strategic' },
    ];

    this.accountId = sessionStorage.getItem('aid');

    this.readFinancialTypes(null);
    this.readBenefitTypes();
    this.readImprovementTypes();
    this.readUnitTypes();
    this.readAreaTypes();
    this.readAccrualTypes();
    this.readImpactTypes();
    this.readScaleTypes();
    this.readUniqueTypes();
    this.readValueCategories();
    this.getExpenseTypes();

    this.subscription = this.solutionService.readAllValueCategory.subscribe(() => {
      this.readValueCategories();
    });

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select DriverFactors',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
    };

    this.dropdownSettings1 = {
      singleSelection: true,
      text: 'Select Financial Factors',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
    };
    this.improvementTypeSettings = {
      singleSelection: true,
      text: 'Select Improvement Type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
    };
    this.accountFeatures = this.checkFeatures();
    this.getFilters();
    this.selectedFuncObjectives = 0;

    if (this.selectedBenefit) {
      this.tcoName = this.selectedBenefit.tco_name;
      this.hasPhasing = this.selectedBenefit.has_phasing;

      this.driverTerm = [];
      this.phasingTerm = [];

      if (this.hasFeature46) {
        this.soft = this.selectedBenefit.soft;
      } else {
        this.quant = this.selectedBenefit.quantified == 1 ? true : false;
        this.soft = this.selectedBenefit.soft == 1 ? true : false;
        this.benefitType = this.selectedBenefit.benefit_type_id;
      }

      for (let i = 1; i <= Number(this.defaultTerm); i++) {
        this.driverTerm.push({ val: this.selectedBenefit['driver_phase_' + i] });
      }

      for (let i = 0; i < Number(this.defaultTerm); i++) {
        this.phasingTerm.push({ val: this.selectedBenefit['phase_' + i] });
      }
    } else {
      this.quant = true;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptiongetUnitTypes) {
      this.subscriptiongetUnitTypes.unsubscribe();
    }
    if (this.subscriptiongetAreaTypes) {
      this.subscriptiongetAreaTypes.unsubscribe();
    }
    if (this.subscriptiongetAccrualTypes) {
      this.subscriptiongetAccrualTypes.unsubscribe();
    }
    if (this.subscriptiongetFinancialTypes) {
      this.subscriptiongetFinancialTypes.unsubscribe();
    }
    if (this.subscriptiongetScratchpadImprovementTypes) {
      this.subscriptiongetScratchpadImprovementTypes.unsubscribe();
    }
    if (this.subscriptiongetImpactTypes) {
      this.subscriptiongetImpactTypes.unsubscribe();
    }
    if (this.subscriptiongetAvailableScalers) {
      this.subscriptiongetAvailableScalers.unsubscribe();
    }
    if (this.subscriptiongetDriverFactorTypes) {
      this.subscriptiongetDriverFactorTypes.unsubscribe();
    }
    if (this.subscriptiongetDriverFactor) {
      this.subscriptiongetDriverFactor.unsubscribe();
    }
    if (this.subscriptiongetUniqueTypes) {
      this.subscriptiongetUniqueTypes.unsubscribe();
    }
    if (this.subscriptiongetValueCategories) {
      this.subscriptiongetValueCategories.unsubscribe();
    }
    if (this.subscriptionsaveSolutionBenefits) {
      this.subscriptionsaveSolutionBenefits.unsubscribe();
    }
    if (this.subscriptionupdateSolutionBenefits) {
      this.subscriptionupdateSolutionBenefits.unsubscribe();
    }
    if (this.subscriptioncreateDriverFactor) {
      this.subscriptioncreateDriverFactor.unsubscribe();
    }
    if (this.subscriptioncreateFinancialFactor) {
      this.subscriptioncreateFinancialFactor.unsubscribe();
    }
    if (this.subscriptioncreateImprovementFactor) {
      this.subscriptioncreateImprovementFactor.unsubscribe();
    }
    if (this.subscriptiongetProjects) {
      this.subscriptiongetProjects.unsubscribe();
    }
    if (this.subscriptionGetExpenseTypes) {
      this.subscriptionGetExpenseTypes.unsubscribe();
    }
  }

  completeImpactAreaFilter(event: { [klass: string]: any }) {
    const out = this.impactAreas.filter((x) => x.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    this.filteredImpactAreas = out;
  }

  completePainPointsFilter(event: { [klass: string]: any }) {
    const out = this.painPoints.filter((x) => x.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    this.filteredPainPoints = out;
  }
  completeCapabilitiesFilter(event: { [klass: string]: any }) {
    const out = this.capabilities.filter((x) => x.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    this.filteredCapabilities = out;
  }
  completeOpObjectivesFilter(event: { [klass: string]: any }) {
    const out = this.opObjectives.filter((x) => x.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1);
    this.filteredOpObjectives = out;
  }

  getFilters() {
    this.painPointsService.getPainPoints(this.accountId).subscribe((res) => {
      this.painPoints = res && res.result && res.result.length ? res.result : [];
      this.loadedTot++;
    });

    this.capabilitiesService.getCapabilities(this.accountId).subscribe((res) => {
      this.capabilities = res && res.result && res.result.length ? res.result : [];

      this.loadedTot++;
    });

    this.OpObjectivesService.getOperationalObjectives(this.accountId).subscribe((res) => {
      this.opObjectives = res && res.result && res.result.length ? res.result : [];

      this.loadedTot++;
    });

    this.FuncObjectivesService.getFunctionalObjectives(this.accountId).subscribe((res) => {
      this.funcObjectives = res && res.result && res.result.length ? res.result : [];

      this.loadedTot++;
    });
  }

  getExpenseTypes() {
    this.subscriptionGetExpenseTypes = this.solutionService.getExpenseTypes().subscribe((response) => {
      if (response.result) {
        this.expenseTypes = response.result;
        this.loadedTot++;
      }
    });
  }
  checkFeatures() {
    const features = sessionStorage.getItem('features');
    const options = features.split(',');
    if (options.indexOf('14') >= 0) {
      return true;
    }
    return false;
  }

  checkCurrentEffort() {
    const utype = parseInt(this.unitType);

    if (utype == 8 || utype == 9 || utype == 10 || utype == 11 || utype == 12 || utype == 13) {
      this.showCurrentEffort = true;
    } else {
      this.showCurrentEffort = false;
    }
  }

  readBenefitTypes() {
    this.subscriptiongetUnitTypes = this.solutionService.getBenefitTypes(this.accountId).subscribe((response) => {
      if (response.result) {
        this.benefitTypeList = response.result;
        this.loadedTot++;
      }
    });
  }
  readUnitTypes() {
    this.subscriptiongetUnitTypes = this.solutionService.getUnitTypes().subscribe((response) => {
      if (response.result) {
        this.unitTypeList = response.result;
      }

      this.loadedTot++;
    });
  }

  readAreaTypes() {
    this.subscriptiongetAreaTypes = this.solutionService.getAreaTypes().subscribe((response) => {
      if (response.result) {
        this.impactAreas = response.result;
      }

      if (this.selectedBenefit && this.selectedBenefit['areas'] && this.selectedBenefit['areas'].length > 0) {
        const areaIds = this.selectedBenefit['areas'].map((item: { [klass: string]: any }) => {
          return item['area_type_id'];
        });
        this.impactAreaIdList = areaIds;

        this.impactAreas = this.impactAreas.map((item) => {
          if (this.impactAreaIdList.indexOf(item['id']) != -1) {
            this.impactAreasList.push(item);
            item['checked'] = true;
          }
          return item;
        });

        this.noAreaSelect = false;
      }
      this.loadedTot++;
    });
  }

  readAccrualTypes() {
    this.subscriptiongetAccrualTypes = this.solutionService.getAccrualTypes().subscribe((response) => {
      if (response.result) {
        this.annualBenefitsList = response.result;
      }

      this.loadedTot++;
    });
  }

  readFinancialTypes(params: string) {
    this.driverFactorLoader = true;
    this.subscriptiongetFinancialTypes = this.solutionService.getFinancialTypes().subscribe((response) => {
      if (response.result) {
        this.financialDriverFactorList = response.result;
        this.financialDriverFactorList = response.result.map((item: { [klass: string]: any }) => {
          item['itemName'] = item['name'];
          return item;
        });
        if (params != null) {
          this.financialDriverFactor = this.financialDriverFactorList.filter((item) => {
            return item['id'] == params;
          });
        }
        this.readDriverFactor(null);
      }
      if (!this.financialDriverFactor) {
        this.financialDriverFactor = this.financialDriverFactorList[0]['id'];
      }

      this.loadedTot++;
    });
  }

  readImprovementTypes(params?: string) {
    this.subscriptiongetScratchpadImprovementTypes = this.factsService.getScratchpadImprovementTypes().subscribe((response) => {
      if (response.result) {
        this.improvementTypeList = response.result;
        this.selectedImprovementType = [];

        if (params) {
          this.selectedImprovementType = this.improvementTypeList.filter((item) => {
            return item['id'] == params;
          });
        }
      }

      this.loadedTot++;
    });
  }

  readImpactTypes() {
    this.subscriptiongetImpactTypes = this.solutionService.getImpactTypes().subscribe((response) => {
      if (response.result) {
        this.impactList = response.result;
      }

      this.loadedTot++;
    });
  }

  readScaleTypes() {
    this.subscriptiongetAvailableScalers = this.solutionService.getAvailableScalers(this.solutionId, 0).subscribe((response) => {
      if (response.result) {
        this.scaleList = response.result;
      }
      this.loadedTot++;
    });
  }

  readDriverFactor(params: string) {
    const factorType = 'all';
    const aid = sessionStorage.getItem('aid');
    this.subscriptiongetDriverFactor = this.solutionService.getDriverFactor(factorType, aid).subscribe((response) => {
      if (response.result) {
        this.driverFactorList = response.result;

        this.driverFactorList = response.result.map((item) => {
          item['itemName'] = item['name'];
          return item;
        });

        if (params != null) {
          this.driverFactor = this.driverFactorList.filter((item) => {
            return item['id'] == params;
          });
        }

        if (this.selectedBenefit) {
          this.editBenefit();
        }

        if (this.selectedImportBenefit) {
          this.importBenefit();
        }
      }
      if (!this.driverFactor) {
        this.driverFactor = this.driverFactorList[0]['id'];
      }
      this.driverFactorLoader = false;
      this.loadedTot++;
    });
  }

  readUniqueTypes() {
    this.subscriptiongetUniqueTypes = this.solutionService.getUniqueTypes().subscribe((response) => {
      if (response.result) {
        this.uniqueList = response.result;
      }

      this.loadedTot++;
    });
  }

  readValueCategories() {
    this.subscriptiongetValueCategories = this.solutionService.getValueCategories(this.solutionId).subscribe((response) => {
      if (response.result) {
        this.valueCategoryBenefitsList = response.result.value_categories;
      }

      this.loadedTot++;
    });
  }

  saveBenefits(form: NgForm) {
    const account_functional_objective_id = [];
    const account_operational_objective_id = [];
    const account_capability_id = [];
    const account_painpoint_id = [];
    const impactArea = [];

    if (this.selectedPainPoints && this.selectedPainPoints.length) {
      for (let i = 0; i < this.selectedPainPoints.length; i++) {
        if (this.selectedPainPoints[i].account_painpoint_id) {
          account_painpoint_id.push(this.selectedPainPoints[i].account_painpoint_id);
        } else {
          account_painpoint_id.push(this.selectedPainPoints[i].id);
        }
      }
    }
    if (this.selectedCapabilities && this.selectedCapabilities.length) {
      for (let i = 0; i < this.selectedCapabilities.length; i++) {
        if (this.selectedCapabilities[i].account_capability_id) {
          account_capability_id.push(this.selectedCapabilities[i].account_capability_id);
        } else {
          account_capability_id.push(this.selectedCapabilities[i].id);
        }
      }
    }
    if (this.selectedOpObjectives && this.selectedOpObjectives.length) {
      for (let i = 0; i < this.selectedOpObjectives.length; i++) {
        if (this.selectedOpObjectives[i].account_operational_objective_id) {
          account_operational_objective_id.push(this.selectedOpObjectives[i].account_operational_objective_id);
        } else {
          account_operational_objective_id.push(this.selectedOpObjectives[i].id);
        }
      }
    }
    if (this.selectedFuncObjectives && this.selectedFuncObjectives.length) {
      if (this.selectedFuncObjectives) {
        account_functional_objective_id.push(this.selectedFuncObjectives);
      }
    }
    if (this.selectedImpactAreas && this.selectedImpactAreas.length) {
      for (let i = 0; i < this.selectedImpactAreas.length; i++) {
        if (this.selectedImpactAreas[i].id) {
          impactArea.push(this.selectedImpactAreas[i].id);
        } else {
          impactArea.push(this.selectedImpactAreas[i].id);
        }
      }
    }

    const benfitsObj = {
      tco_name: form.value.tcoName,
      account_solution_id: this.solutionId,
      value_category_id: parseInt(form.value.valueCategoryBenefits),
      impact_type_id: parseInt(form.value.impact),
      metric_name: form.value.benefitsName,
      scale_type_id: parseInt(form.value.scale.scale_type_id),
      unique_type_id: parseInt(form.value.uniqueTypes),
      default_na: form.value.default_na,
      accrual_type_id: parseInt(form.value.annualBenefits),
      description: form.value.description,
      features: form.value.features,
      area_type_id: impactArea.join(','),
      account_painpoint_id: account_painpoint_id.join(','),
      account_capability_id: account_capability_id.join(','),
      account_operational_objective_id: account_operational_objective_id.join(','),
      account_functional_objective_id: account_functional_objective_id.join(','),
      driver_factor_id: parseInt(form.value.driverFactor.id),
      driver_baseline_answer: form.value.driverBaselineAnswer,
      conservative: form.value.conservative,
      probable: form.value.porbable,
      aggressive: form.value.aggressive,
      unit_type_id: parseInt(form.value.unitType),
      financial_factor_id: parseInt(form.value.financialDriverFactor.id),
      financial_baseline_answer: form.value.financicalAnswer,
      current_effort: form.value.currentEffort,
      project_id: form.value.project_id,
      improvement_factor_id: form.value.selectedImprovementType.id,
      expense_type_id: form.value.expense_type_id,
    };

    if (this.hasFeature46) {
      benfitsObj['soft'] = form.value.soft;
    } else {
      benfitsObj['quantified'] = form.value.quant == true ? 1 : 0;
      benfitsObj['soft'] = form.value.soft == true ? 1 : 0;
      benfitsObj['benefit_type_id'] = form.value.benefitType;
    }

    if (this.hasPhasing) {
      for (let i = 0; i < this.driverTerm.length; i++) {
        benfitsObj['driver_phase_' + (i + 1)] = this.driverTerm[i].val;
      }
    } else {
      for (let i = 0; i < this.driverTerm.length; i++) {
        benfitsObj['driver_phase_' + (i + 1)] = 0;
      }
    }

    for (let i = 0; i < this.phasingTerm.length; i++) {
      benfitsObj['phase_' + i] = this.phasingTerm[i].val;
    }

    if (!this.selectedBenefit || this.selectedImportBenefit) {
      this.saveBenefit(benfitsObj);
    } else if (this.selectedBenefit) {
      benfitsObj['account_solution_metric_id'] = this.selectedBenefit['id'];
      benfitsObj['sequence'] = this.selectedBenefit['sequence'];
      this.updateBenefit(benfitsObj);
    }
  }

  saveBenefit(benfitsObj: { [klass: string]: any }) {
    this.subscriptionsaveSolutionBenefits = this.solutionService.saveSolutionBenefits(benfitsObj).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Benefit Added successfully', false);
        this.solutionService.triggerTabs.next('benefits');
        this.callbackDone.emit();
      }
      this.solutionService.readAllValueCategory.next('readList');
      this.selectedFuncObjectives = 0;
    });
  }

  updateBenefit(benfitsObj: { [klass: string]: any }) {
    this.subscriptionupdateSolutionBenefits = this.solutionService.updateSolutionBenefits(this.solutionId, benfitsObj).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Benefit Updated successfully', false);
        this.solutionService.triggerTabs.next('benefits');
        this.callbackDone.emit();
      }
      this.solutionService.readAllValueCategory.next('readList');
      this.selectedFuncObjectives = 0;
    });
  }

  editBenefit() {
    this.expenseTypeId = this.selectedBenefit['expense_type_id'];
    this.benefitsName = this.selectedBenefit['metric_name'];
    this.benefitsDescription = this.selectedBenefit['description'];
    this.annualBenefits = this.selectedBenefit['accrual_type_id'];
    this.impact = this.selectedBenefit['impact_type_id'];
    this.valueCategoryBenefits = this.selectedBenefit['value_category_id'];
    const scale = this.scaleList.filter((item) => item['scale_type_id'] == this.selectedBenefit['scale_type_id']);
    this.scale = scale[0];
    this.tcoName = this.selectedBenefit.tco_name;
    this.uniqueTypes = this.selectedBenefit['unique_type_id'];

    this.driverFactor = this.driverFactorList.filter((item) => {
      return item['id'] == this.selectedBenefit['driver_factor_id'];
    });
    this.currentEffort = this.selectedBenefit['current_effort'];
    this.driverBaselineAnswer = this.selectedBenefit['driver_baseline_answer'];
    this.unitType = this.selectedBenefit['unit_type_id'];
    this.conservative = this.selectedBenefit['conservative'];
    this.porbable = this.selectedBenefit['probable'];
    this.aggressive = this.selectedBenefit['aggressive'];

    this.financialDriverFactor = this.financialDriverFactorList.filter((item) => {
      return item['id'] == this.selectedBenefit['financial_factor_id'];
    });
    this.selectedImprovementType = this.improvementTypeList.filter((item) => {
      return item['id'] == this.selectedBenefit.improvement_factor_id;
    });

    this.financicalAnswer = this.selectedBenefit['financial_baseline_answer'];
    this.situationsList = this.selectedBenefit['situations'];
    this.benefitsFeatures = this.selectedBenefit['features'];
    if (this.selectedBenefit['default_na'] == '0') {
      this.defaultNA = false;
    } else if (this.selectedBenefit['default_na'] == '1') {
      this.defaultNA = true;
    }

    if (this.unitType) {
      this.checkCurrentEffort();
    }

    this.driverFactor = this.driverFactorList.filter((x) => x['id'] == this.selectedBenefit.driver_factor_id)[0];
    this.selectedImprovementType = this.improvementTypeList.filter((x) => x['id'] == this.selectedBenefit.improvement_factor_id)[0];
    this.financialDriverFactor = this.financialDriverFactorList.filter((x) => x['id'] == this.selectedBenefit.financial_factor_id)[0];

    if (this.hasFeature46) {
      this.soft = this.selectedBenefit.soft;
    } else {
      if (this.selectedBenefit.quantified == '1') {
        this.selectedBenefit.quantified = true;
      } else {
        this.selectedBenefit.quantified = false;
      }
      if (this.selectedBenefit.soft == '1') {
        this.selectedBenefit.soft = true;
      } else {
        this.selectedBenefit.soft = false;
      }
    }
    this.benefit = {
      phase_0: parseInt(this.selectedBenefit['phase_0']),
      phase_1: parseInt(this.selectedBenefit['phase_1']),
      phase_2: parseInt(this.selectedBenefit['phase_2']),
      phase_3: parseInt(this.selectedBenefit['phase_3']),
      phase_4: parseInt(this.selectedBenefit['phase_4']),
    };

    this.projectId = this.selectedBenefit['project_id'];
    if (typeof this.selectedBenefit.functional_objectives[0] !== 'undefined') {
      this.selectedFuncObjectives = this.selectedBenefit.functional_objectives[0].account_functional_objective_id;
    }

    if (typeof this.selectedBenefit.areas[0] !== 'undefined') {
      this.selectedImpactAreas = [];
      for (let i = 0; i < this.selectedBenefit.areas.length; i++) {
        this.selectedImpactAreas.push({ name: this.selectedBenefit.areas[i].name, id: this.selectedBenefit.areas[i].area_type_id });
      }
    }

    if (typeof this.selectedBenefit.pain_points[0] !== 'undefined') {
      this.selectedPainPoints = [];
      for (let i = 0; i < this.selectedBenefit.pain_points.length; i++) {
        this.selectedPainPoints.push(this.selectedBenefit.pain_points[i]);
      }
    }
    if (typeof this.selectedBenefit.operational_objectives[0] !== 'undefined') {
      this.selectedOpObjectives = [];
      for (let i = 0; i < this.selectedBenefit.operational_objectives.length; i++) {
        this.selectedOpObjectives.push(this.selectedBenefit.operational_objectives[i]);
      }
    }

    if (typeof this.selectedBenefit.capabilities[0] !== 'undefined') {
      this.selectedCapabilities = [];
      for (let i = 0; i < this.selectedBenefit.capabilities.length; i++) {
        this.selectedCapabilities.push(this.selectedBenefit.capabilities[i]);
      }
    }
  }

  importBenefit() {
    this.benefitsName = this.selectedImportBenefit['metric_name'];
    this.benefitsDescription = this.selectedImportBenefit['description'];
    this.annualBenefits = this.selectedImportBenefit['accrual_type_id'];
    this.impact = this.selectedImportBenefit['impact_type_id'];
    this.valueCategoryBenefits = this.selectedImportBenefit['value_category_id'];
    const scale = this.scaleList.filter((item) => item['scale_type_id'] == this.selectedBenefit['scale_type_id']);
    this.scale = scale[0];
    this.uniqueTypes = this.selectedImportBenefit['unique_type_id'];

    this.driverFactor = this.driverFactorList.filter((item) => {
      return item['id'] == this.selectedImportBenefit['driver_factor_id'];
    });
    this.driverBaselineAnswer = this.selectedImportBenefit['driver_baseline_answer'];
    this.unitType = this.selectedImportBenefit['unit_type_id'];
    this.conservative = this.selectedImportBenefit['conservative'];
    this.porbable = this.selectedImportBenefit['probable'];
    this.aggressive = this.selectedImportBenefit['aggressive'];

    this.financialDriverFactor = this.financialDriverFactorList.filter((item) => {
      return item['id'] == this.selectedImportBenefit['financial_factor_id'];
    });
    this.financicalAnswer = this.selectedImportBenefit['financial_baseline_answer'];
    this.situationsList = this.selectedImportBenefit['situations'];
    this.benefitsFeatures = this.selectedImportBenefit['features'];
    this.defaultNA = this.selectedImportBenefit['default_na'];

    this.benefit = {
      phase_0: parseInt(this.selectedImportBenefit['phase_0']),
      phase_1: parseInt(this.selectedImportBenefit['phase_1']),
      phase_2: parseInt(this.selectedImportBenefit['phase_2']),
      phase_3: parseInt(this.selectedImportBenefit['phase_3']),
      phase_4: parseInt(this.selectedImportBenefit['phase_4']),
    };
  }

  createDriverFactor() {
    this.showActionLoader = true;
    const driverFactorName = {
      name: this.newDriverFactor,
      account_id: sessionStorage.getItem('aid'),
    };
    if (driverFactorName) {
      this.subscriptioncreateDriverFactor = this.factsService.createDriverFactor(driverFactorName).subscribe((response) => {
        if (response.result && response.result.success) {
          this.readDriverFactor(response.result.driverFactor['id']);
          this.notificationService.success('Driver Factor created successfully', false);
        } else if (response.result && !response.result.success) {
          this.notificationService.error(response.result.message, false);
        }
        this.newDriverFactor = '';

        this.showActionLoader = false;
        this.createFactor = false;
      });
    }
  }

  createFinancialFactor() {
    this.showActionLoader = true;
    const driverFactorName = { name: this.FinancialFactor };
    if (driverFactorName) {
      this.subscriptioncreateFinancialFactor = this.factsService.createFinancialFactor(driverFactorName).subscribe((response) => {
        if (response.result && response.result.success) {
          this.readFinancialTypes(response.result.financialFactor['id']);
          this.notificationService.success('Financial Factor created successfully', false);
        } else if (response.result && !response.result.success) {
          this.notificationService.error(response.result.message, false);
        }

        this.FinancialFactor = '';
        this.createDriverFlag = false;
        this.showActionLoader = false;
      });
    }
  }

  createImprovementFactor() {
    this.showImprovementActionLoader = true;
    const improvementFactorName = { name: this.newImprovementFactor };
    if (improvementFactorName) {
      this.subscriptioncreateImprovementFactor = this.factsService.createImprovementFactor(improvementFactorName).subscribe((response) => {
        if (response.result && response.result.success) {
          this.readImprovementTypes(response.result.improvementFactor['id']);
          this.notificationService.success('Improvement Factor created successfully', false);
        } else if (response.result && !response.result.success) {
          this.notificationService.error(response.result.message, false);
        }

        this.newImprovementFactor = '';
        this.createImprovementFlag = false;
        this.showImprovementActionLoader = false;
      });
    }
  }

  openAddValueCategory() {
    const modalReferenceValueCategory = this.modalService.open(AddValueCategoryModalComponent, {
      windowClass: 'deleteModal secondLayerModalWindow',
      backdrop: 'static',
      keyboard: false,
      backdropClass: 'secondLayerModalBackdrop',
    });
    modalReferenceValueCategory.componentInstance.solutionId = this.solutionId;
    modalReferenceValueCategory.result.then(
      () => {},
      () => {}
    );
  }
  getProjects() {
    this.subscriptiongetProjects = this.solutionService.getProjects(this.solutionId).subscribe((response) => {
      this.projects = [];
      if (response.result) {
        this.projects = response.result;
      }
    });
  }
}
