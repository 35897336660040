<div class="row breadcrumb-wrapper" *ngIf="style2022$ | async">
  <div class="col-md-8 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="row" *ngIf="!(style2022$ | async)">
  <div class="col-md-10">
    <a class="back-profile" [routerLink]="(style2022$ | async) ? ['/settings'] : ['/profile']">
      <i class="fa fa-chevron-left"></i> {{ (style2022$ | async) ? 'Back to Settings' : trans.trans.backToProfile.value }}</a
    >
  </div>
</div>

<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="row" style="margin-bottom: 20px">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input #search (keyup)="onSearch($event)" type="text" [placeholder]="trans?.trans?.search?.value" class="form-control" />
          <span *ngIf="search?.value" (click)="clearSearch()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!events" class="progress-spinner-container">
    <p-progressSpinner styleClass="custom-spinner" ></p-progressSpinner>
  </div>
  <div class="row timeline-spacer">

    <p-timeline [value]="events | filterEvents: strSearch: verbTranslations" align="alternate">
      <ng-template pTemplate="marker" let-event>
        <span class="custom-marker" [style.backgroundColor]="actionStyles[event.action_id].color">
          <i [style.color]="actionStyles[event.action_id].iconColor" [ngClass]="actionStyles[event.action_id].icon"></i>
        </span>
      </ng-template>
      <ng-template pTemplate="content" let-event>
        <div class="log-card">
          <ng-container [ngSwitch]="+event.action_id">
            <ng-container *ngSwitchCase="1">
              <div class="log-title"> {{ trans.trans.newTemplate.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.newTemplate"
                  (callback)="getTranslations()"
                ></app-editTranslation></div>
              <div class="action-text"><span>{{ event.user_name }}</span> {{ trans.trans.added.value | lowercase }} <span class="asset-name">{{ event.asset_template_name }}</span></div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <div class="log-title">{{ trans.trans.edited.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.newTemplate"
                  (callback)="getTranslations()"
                ></app-editTranslation></div>
              <div class="action-text"><span>{{ event.user_name }}</span> {{ trans.trans.edited.value | lowercase }} <span class="asset-name">{{ event.asset_template_name }}</span></div>
            </ng-container>
            <ng-container *ngSwitchCase="3">
              <div class="log-title">{{ trans.trans.deleted.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.newTemplate"
                  (callback)="getTranslations()"
                ></app-editTranslation></div>
              <div class="action-text"><span>{{ event.user_name }}</span> {{ trans.trans.deleted.value | lowercase }} <span class="asset-name">{{ event.asset_template_name }}</span></div>
            </ng-container>
          </ng-container>

          <div class="action-date">{{ event.created | date: 'medium' }}</div>
        </div>
      </ng-template>
    </p-timeline>
  </div>
</div>
