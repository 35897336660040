import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { ScaleType } from 'chart.js';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, Subscription } from 'rxjs';
import * as $ from 'jquery';
import { BasicInfo } from '@shared/models/basic-info.model';

@Component({
  selector: 'app-situation',
  templateUrl: './situation.component.html',
  styleUrls: ['./situation.component.scss'],
})
export class SituationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() solutionId: number | string;
  @Input() tabInit: number;
  @Input() canEdit = false;

  customcheckboxlabel: string;
  toggles: { [klass: string]: any };
  accountSolutionId: number | string;
  accountSituationId: number;
  situationTypeId: number;
  situationTypeIdObj: { [klass: string]: any };
  modalReference: NgbModalRef;
  situationTypes: { [klass: string]: any }[];
  situationType: { [klass: string]: any };
  accountSituationType: Array<{ [klass: string]: any }> = [];
  situationElementTypes: { [klass: string]: any }[];
  situation: { [klass: string]: any };
  situationElement: { [klass: string]: any };
  situationTypeElements: { [klass: string]: any }[];
  situations: { [klass: string]: any }[];
  costs: { [klass: string]: any }[];
  cost: { [klass: string]: any };
  benefits: { [klass: string]: any }[];
  caseStudies: { [klass: string]: any }[];
  assets: { [klass: string]: any }[] = [];
  collateral: { [klass: string]: any }[] = [];
  benefit: { [klass: string]: any };
  benefitConf: { [klass: string]: any };

  SituationTypeElementsSettings = {};
  situationTypeElementsLoader = false;
  accrualTypes: { [klass: string]: any }[];
  scaleTypes: BasicInfo[] = [];
  edit = false;
  situationLoader = false;

  fullImagePath: string;

  contextualHelp: { [klass: string]: any } = {};

  elementChildren1Toggle: boolean;
  elementChildren2Toggle: boolean;
  elementChildren3Toggle: boolean;
  elementChildren4Toggle: boolean;
  elementChildren5Toggle: boolean;

  elementChildren1: { [klass: string]: any }[] = [];
  elementChildren2: { [klass: string]: any }[] = [];
  elementChildren3: { [klass: string]: any }[] = [];
  elementChildren4: { [klass: string]: any }[] = [];
  elementChildren5: { [klass: string]: any }[] = [];

  parent1Name: string;
  parent2Name: string;
  parent3Name: string;
  parent4Name: string;
  parent5Name: string;

  dropdownSettings: { [klass: string]: any };

  masterValuePropFeature: boolean;
  projectsList: { [klass: string]: any }[] = [];
  selectedProject: { [klass: string]: any };

  contextualHelpSub: Subscription;
  subscriptioncontextualHelp: Subscription;
  subscriptiongetSituationTypes: Subscription;
  subscriptiongetAccountSituationTypes: Subscription;
  subscriptiongetSituationElementTypes: Subscription;
  subscriptionsaveSituationType: Subscription;
  subscriptionupdateSituationType: Subscription;
  subscriptionsaveSituation: Subscription;
  subscriptionupdateSituation: Subscription;
  subscriptionsaveSituationElement: Subscription;
  subscriptiongetSituationsBySolution: Subscription;
  subscriptiongetSolutionCosts: Subscription;
  subscriptiongetSituationTypeElements: Subscription;
  subscriptiongetAccrualTypes: Subscription;
  subscriptiongetScaleTypes: Subscription;
  subscriptionsaveSituationBenefitActive: Subscription;
  subscriptionsaveSolutionSituationBenefit: Subscription;
  subscriptiondeleteSituation: Subscription;
  subscriptionsaveSituationTypeElement: Subscription;
  subscriptionsaveSituationCost: Subscription;
  subscriptionupdateSituationTypeStackOrder: Subscription;
  subscriptiongetSituationElementChildren: Subscription;
  subscriptiongetCaseStudiesPlain: Subscription;
  subscriptionsaveSituationCaseStudyActive: Subscription;
  subscriptionreadValuePropAssetTemplatesWithoutId: Subscription;
  subscriptionsaveSituationAssetActive: Subscription;
  subscriptiongetCollateralList: Subscription;
  subscriptionsaveSituationCollateralActive: Subscription;
  subscriptiongetProjects: Subscription;
  subscriptionsaveSituationProjectsActive: Subscription;

  mode = 'add';

  tree: { [klass: string]: any }[] = [];
  showAddView = false;
  addNewObj = {
    name: '',
    description: '',
    parent_id: '',
    account_situation_type_id: '',
  };
  style2022$: Observable<boolean>;
  type: string;

  constructor(
    private solutionService: SolutionService,
    private valuepropService: ValuepropService,
    private commonService: CommonService,
    private factsService: FactsService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    const imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.accountSolutionId = this.solutionId;

    this.situations = [];
    this.benefits = [];
    this.costs = [];
    this.caseStudies = [];
    this.cost = {};
    this.situationTypeElements = [];
    this.benefit = {};
    this.benefitConf = {};

    this.situation = {
      account_solution_id: this.accountSolutionId,
    };

    this.situationType = {
      account_solution_id: this.accountSolutionId,
    };

    this.situationElement = {
      account_situation_id: this.accountSituationId,
    };

    this.customcheckboxlabel = 'No';

    this.SituationTypeElementsSettings = {
      singleSelection: false,
      text: 'Situation Type Elements',
      enableCheckAll: false,
      enableSearchFilter: true,
      labelKey: 'name',
      badgeShowLimit: 100,
    };

    this.reset();

    (<any>$('input:checkbox')).uniform();

    this.masterValuePropFeature = false;
    if (this.commonService.checkFeature('15')) {
      this.masterValuePropFeature = true;
      this.getProjects();
    }

    this.solutionService.triggerTabs.subscribe((response) => {
      if (response === 'costs') {
        this.getSolutionCosts();
      } else if (response === 'caseStudy') {
        this.getSolutionCaseStudies();
      } else if (response === 'collateral') {
        this.getCollateral();
      } else if (response === 'projects') {
        this.getProjects();
      } else {
        this.getSolutionBenefits();
      }
    });

    this.subscriptioncontextualHelp = this.contextualHelpSub = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.elementChildren1Toggle = false;
    this.elementChildren2Toggle = false;
    this.elementChildren3Toggle = false;
    this.elementChildren4Toggle = false;
    this.elementChildren5Toggle = false;

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select Situation Type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
      classes: 'situationControl',
      lazyLoading: false,
    };
    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.contextualHelpSub) {
      this.contextualHelpSub.unsubscribe();
    }
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptiongetSituationTypes) {
      this.subscriptiongetSituationTypes.unsubscribe();
    }
    if (this.subscriptiongetAccountSituationTypes) {
      this.subscriptiongetAccountSituationTypes.unsubscribe();
    }
    if (this.subscriptiongetSituationElementTypes) {
      this.subscriptiongetSituationElementTypes.unsubscribe();
    }
    if (this.subscriptionsaveSituationType) {
      this.subscriptionsaveSituationType.unsubscribe();
    }
    if (this.subscriptionupdateSituationType) {
      this.subscriptionupdateSituationType.unsubscribe();
    }
    if (this.subscriptionsaveSituation) {
      this.subscriptionsaveSituation.unsubscribe();
    }
    if (this.subscriptionupdateSituation) {
      this.subscriptionupdateSituation.unsubscribe();
    }
    if (this.subscriptionsaveSituationElement) {
      this.subscriptionsaveSituationElement.unsubscribe();
    }
    if (this.subscriptiongetSituationsBySolution) {
      this.subscriptiongetSituationsBySolution.unsubscribe();
    }
    if (this.subscriptiongetSolutionCosts) {
      this.subscriptiongetSolutionCosts.unsubscribe();
    }
    if (this.subscriptiongetSituationTypeElements) {
      this.subscriptiongetSituationTypeElements.unsubscribe();
    }
    if (this.subscriptiongetAccrualTypes) {
      this.subscriptiongetAccrualTypes.unsubscribe();
    }
    if (this.subscriptiongetScaleTypes) {
      this.subscriptiongetScaleTypes.unsubscribe();
    }
    if (this.subscriptionsaveSituationBenefitActive) {
      this.subscriptionsaveSituationBenefitActive.unsubscribe();
    }
    if (this.subscriptionsaveSolutionSituationBenefit) {
      this.subscriptionsaveSolutionSituationBenefit.unsubscribe();
    }
    if (this.subscriptiondeleteSituation) {
      this.subscriptiondeleteSituation.unsubscribe();
    }
    if (this.subscriptionsaveSituationTypeElement) {
      this.subscriptionsaveSituationTypeElement.unsubscribe();
    }
    if (this.subscriptionsaveSituationCost) {
      this.subscriptionsaveSituationCost.unsubscribe();
    }
    if (this.subscriptionupdateSituationTypeStackOrder) {
      this.subscriptionupdateSituationTypeStackOrder.unsubscribe();
    }
    if (this.subscriptiongetSituationElementChildren) {
      this.subscriptiongetSituationElementChildren.unsubscribe();
    }
    if (this.subscriptiongetCaseStudiesPlain) {
      this.subscriptiongetCaseStudiesPlain.unsubscribe();
    }
    if (this.subscriptionsaveSituationCaseStudyActive) {
      this.subscriptionsaveSituationCaseStudyActive.unsubscribe();
    }
    if (this.subscriptionreadValuePropAssetTemplatesWithoutId) {
      this.subscriptionreadValuePropAssetTemplatesWithoutId.unsubscribe();
    }
    if (this.subscriptionsaveSituationAssetActive) {
      this.subscriptionsaveSituationAssetActive.unsubscribe();
    }
    if (this.subscriptiongetCollateralList) {
      this.subscriptiongetCollateralList.unsubscribe();
    }
    if (this.subscriptionsaveSituationCollateralActive) {
      this.subscriptionsaveSituationCollateralActive.unsubscribe();
    }
    if (this.subscriptiongetProjects) {
      this.subscriptiongetProjects.unsubscribe();
    }
    if (this.subscriptionsaveSituationProjectsActive) {
      this.subscriptionsaveSituationProjectsActive.unsubscribe();
    }
  }

  ngOnChanges() {
    this.reset();
    if (this.tabInit === 5) {
      this.accountSolutionId = this.solutionId;

      this.getSituationsBySolution();
      this.getSituationTypes();
      this.getAccountSituationTypes();
      this.getElementTypes();
      this.getSolutionCosts();
      this.getSolutionBenefits();
      this.getSolutionCaseStudies();
      this.getAssets();
      this.getCollateral();
      this.getAccrualTypes();
      this.getScaleTypes();
    }
  }

  reset() {
    this.toggles = {
      addType: false,
      showSituationForm: false,
      addElement: false,
      defineBenefits: false,
      defineCosts: false,
      showList: true,
      showBenefitsCosts: false,
      situation_type_stack_order: false,
      situation_type_edit: false,
    };

    this.edit = false;
    this.selectedProject = undefined;
  }

  toggleAddSituation(id: any) {
    this.mode = 'add';
    if (!id) {
      this.situation = {};
      this.situationTypeId = null;
      this.situationTypeIdObj = {};
    }

    this.toggles.showList = false;
    this.toggles.addType = false;
    this.toggles.showSituationForm = true;
    this.toggles.showBenefitsCosts = false;

    this.situationTypeElements = [];

    this.edit = false;
    this.selectedProject = undefined;

    this.resetBenefit();
    this.resetCosts();
    this.resetCaseStudies();
    this.resetAssets();
    this.resetChildren();
    this.resetCollateral();
    this.resetProjects();
  }

  resetChildren() {
    this.elementChildren1 = [];
    this.elementChildren2 = [];
    this.elementChildren3 = [];
    this.elementChildren4 = [];
    this.elementChildren5 = [];
  }

  toggleSituationTypeStackOrder() {
    this.toggles.showList = !this.toggles.showList;
    this.toggles.situation_type_stack_order = !this.toggles.situation_type_stack_order;
  }

  toggleAddElement() {
    if (this.situationType) {
      this.toggles.showList = false;
      this.toggles.showSituationForm = false;
      this.toggles.addElement = true;
    } else {
      this.notificationService.error('Please create a new situation type first', false);
    }
  }

  toggleAddNewType() {
    this.toggles.showList = false;
    this.toggles.showSituationForm = false;
    this.toggles.addElement = false;
    this.toggles.addType = true;
    this.toggles.situation_type_stack_order = false;
  }

  toggleShowList() {
    this.toggles.addType = false;
    this.toggles.showSituationForm = false;
    this.toggles.addElement = false;
    this.toggles.showList = true;
  }

  getSituationTypes() {
    this.subscriptiongetSituationTypes = this.solutionService.getSituationTypes().subscribe((result) => {
      this.situationTypes = result.result.map((sit: { [klass: string]: any }) => ({
        label: sit.name,
        value: sit,
      }));
    });
  }

  getAccountSituationTypes() {
    this.accountSituationType = [];
    this.subscriptiongetAccountSituationTypes = this.solutionService.getAccountSituationTypes().subscribe((result) => {
      if (result.result) {
        this.accountSituationType = result.result;
      }
    });
  }

  getElementTypes() {
    this.subscriptiongetSituationElementTypes = this.factsService.getSituationElementTypes().subscribe((result) => {
      this.situationElementTypes = result.result;
    });
  }

  assignSituationTypeId() {
    this.situationTypeId = this.situationTypeIdObj['id'];
    this.getSituationTypeElements(this.situationTypeId);
    this.getSituationTree();
  }

  getSituationTree() {
    this.solutionService.getSituationsBySolutionTreeV3(this.situationTypeId, this.accountSolutionId).subscribe((res) => {
      this.tree = res.result.data;
    });
  }

  drop(dropEvent: CdkDragDrop<unknown[]>, dropArray: any[]) {
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
    this.accountSituationType = [...dropArray];
  }

  funcShowAddView(situationElementId: any, situationTypeId: any) {
    this.addNewObj = {
      name: '',
      description: '',
      parent_id: situationElementId,
      account_situation_type_id: situationTypeId,
    };
    this.showAddView = true;
  }

  funcAddNestedSituation() {
    this.solutionService.saveSituationElement(this.addNewObj).subscribe(() => {
      this.showAddView = false;
      this.getSituationTree();
    });
  }

  saveSituationType() {
    if (!this.toggles.situation_type_edit) {
      const accountId = sessionStorage.getItem('aid');
      this.situationType.account_id = accountId;
      this.subscriptionsaveSituationType = this.solutionService.saveSituationType(this.situationType).subscribe((result) => {
        this.situationType.id = result.result;
        this.notificationService.success('Situation type created successfully', false);
        this.getSituationTypes();
        this.toggleAddElement();
      });
    } else {
      this.situationType['situation_type_id'] = this.situationType['id'];

      this.subscriptionupdateSituationType = this.solutionService.updateSituationType(this.situationType).subscribe(() => {
        this.getAccountSituationTypes();
        this.notificationService.success('Situation type updated successfully', false);
      });
    }
  }

  saveSituation() {
    if (!this.edit) {
      this.situation.account_solution_id = this.accountSolutionId;
      this.situation.situation_type_id = this.situationTypeId;

      this.situation['default_active'] = this.situation['default_active'] ? 1 : 0;

      const situation = Object.assign({}, this.situation);
      situation['guided_discovery'] = situation['guided_discovery'] ? '1' : '0';
      this.subscriptionsaveSituation = this.solutionService.saveSituation(situation).subscribe((result) => {
        const success = result.result.success;
        if (success) {
          this.accountSituationId = result.result.message.id;
          this.getSituationsBySolution();
          this.notificationService.success('Situation created successfully', false);
          this.toggles.showBenefitsCosts = true;
          this.edit = true;
        } else {
          this.notificationService.error(result.result.message, false);
        }
      });
    } else {
      const situation = Object.assign({}, this.situation);
      situation['account_solution_situation_id'] = situation['id'];
      situation['default_active'] = situation['default_active'] ? 1 : 0;
      situation['guided_discovery'] = situation['guided_discovery'] ? '1' : '0';

      for (let i = 0; i < this.situationTypeElements.length; i++) {
        if (situation['situation_element_id'] === this.situationTypeElements[i]['id']) {
          situation['name'] = this.situationTypeElements[i]['name'];
        }
      }

      const payload = {
        account_situations: [situation],
      };
      this.subscriptionupdateSituation = this.solutionService.updateSituation(payload).subscribe((result) => {
        this.accountSituationId = result.result[0]['solution_situation']['id'];
        this.situation['guided_discovery'] = result.result[0]['solution_situation']['guided_discovery'] == '1' ? true : false;
        this.getSituationsBySolution();
        this.notificationService.success('Situation updated successfully', false);
      });
    }
  }

  saveSituationElement() {
    if (!this.accountSituationId) {
      this.saveSituation();
    }

    this.subscriptionsaveSituationElement = this.solutionService.saveSituationElement(this.situationElement).subscribe(() => {});
  }

  getSituationsBySolution() {
    this.situationLoader = true;

    this.subscriptiongetSituationsBySolution = this.solutionService.getSituationsBySolutionTree(this.accountSolutionId).subscribe((result) => {
      this.situationLoader = false;
      if (!Array.isArray(result)) {
        this.situations = result.result.data;
      } else {
        this.situation = result;
      }
    });
  }

  getSolutionCosts() {
    this.subscriptiongetSolutionCosts = this.solutionService.getSolutionCosts(this.accountSolutionId).subscribe((result) => {
      this.costs = result.result;
    });
  }

  resetCosts() {
    if (this.costs) {
      this.costs = this.costs.map((cost) => {
        cost['changeToggleAvailable'] = null;
        cost['changeToggleAutoload'] = null;
        return cost;
      });
    }
  }

  getSolutionBenefits() {
    this.solutionService.readSolutionBenefits(this.accountSolutionId).subscribe((result) => {
      this.benefits = result.result.solution_metrics;
    });
  }

  resetBenefit() {
    if (this.benefits) {
      this.benefits = this.benefits.map((benefit: { [klass: string]: any }) => {
        benefit['changeToggle'] = false;
        benefit['valueChangeToggle'] = false;
        return benefit;
      });
    }
  }

  resetCaseStudies() {
    if (this.caseStudies) {
      this.caseStudies = this.caseStudies.map((caseStudy: { [klass: string]: any }) => {
        caseStudy['changedActive'] = false;
        return caseStudy;
      });
    }
  }

  resetAssets() {
    if (this.assets) {
      this.assets = this.assets.map((asset) => {
        asset['changedActive'] = false;
        return asset;
      });
    }
  }

  resetCollateral() {
    if (this.collateral) {
      this.collateral = this.collateral.map((collateral) => {
        collateral['changedActive'] = false;
        return collateral;
      });
    }
  }

  resetProjects() {
    if (this.projectsList) {
      this.projectsList = this.projectsList.map((project: { [klass: string]: any }) => {
        project['changedActive'] = null;
        project['changedRecommend'] = null;
        return project;
      });
    }
  }

  getSituationTypeElements(id: any) {
    this.situation.situation_type_id = id;
    this.situationTypeElementsLoader = true;
    this.situationTypeElements = [];
    this.resetChildren();
    this.subscriptiongetSituationTypeElements = this.solutionService.getSituationTypeElements(id).subscribe((response) => {
      this.situationTypeElementsLoader = false;
      if (response.result['message'] && response.result['message']['available_elements']) {
        this.situationTypeElements = response.result['message']['available_elements'];
      } else {
        if (id == 3 && response.result['message']) {
          delete response.result['message']['available_elements'];
          const responseGeo = response.result['message'];
          this.situationTypeElements = Array.from(Object.keys(responseGeo), (k) => responseGeo[k]);
        }
      }
    });
  }

  showBenefitControls(content: any, id: any) {
    this.modalReference = this.modalService.open(content, { size: 'lg', windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
    (<any>$('input:checkbox')).uniform();

    this.benefit = this.benefits.find((obj) => {
      return obj.id === id;
    });

    this.benefitConf = {};

    if (this.edit && this.situation['benefits']) {
      const benefitConf = this.situation['benefits'].find((obj) => {
        return obj.account_solution_metric_id === id;
      });

      if (benefitConf) {
        this.benefitConf = benefitConf;
      }
    }
  }

  getAccrualTypes() {
    this.subscriptiongetAccrualTypes = this.factsService.getAccrualTypes().subscribe((result) => {
      this.accrualTypes = result.result;
    });
  }

  getScaleTypes() {
    this.subscriptiongetScaleTypes = this.factsService.getScaleTypes(1).subscribe((result) => {
      this.scaleTypes = result.result;
    });
  }

  saveSituationBenefitActive(active: number | 'NULL', benefitId: any) {
    if (this.situation) {
      const data = {
        account_solution_situation_id: this.accountSituationId,
        account_solution_metric_id: benefitId,
        active: active,
      };

      this.subscriptionsaveSituationBenefitActive = this.solutionService.saveSituationBenefitActive(data).subscribe((result) => {
        if (result.result.success) {
          this.notificationService.success(`You changed the benefit's active status`, false);
          this.benefits.find((ben: { [klass: string]: any }) => ben.id === benefitId).changedActive = active;
          this.benefits.find((ben: { [klass: string]: any }) => ben.id === benefitId).changeToggle = true;

          this.getSituationsBySolution();

          this.solutionService.triggerBenefit.next('reloadBenefits');
        }
      });
    } else {
      this.notificationService.error('Please save your situation first', false);
    }
  }

  saveSolutionSituationBenefit() {
    this.benefitConf.account_solution_situation_id = this.accountSituationId;
    this.benefitConf.account_solution_metric_id = this.benefit.id;

    this.subscriptionsaveSolutionSituationBenefit = this.solutionService.saveSolutionSituationBenefit(this.benefitConf).subscribe((response) => {
      if (response.result.success) {
        this.notificationService.success('Changes to ' + this.benefit.metric_name + ' saved!', false);
        this.benefits.find((ben: { [klass: string]: any }) => ben.id === this.benefitConf.account_solution_metric_id).valueChangeToggle = true;

        this.getSituationsBySolution();

        this.solutionService.triggerBenefit.next('reloadBenefits');
      } else {
        this.notificationService.error('Something went wrong; please try again.', false);
      }
      this.modalReference.dismiss();
    });
  }

  deleteSituation(id: any) {
    this.situationLoader = true;
    this.subscriptiondeleteSituation = this.solutionService.deleteSituation(id).subscribe(() => {
      this.situationLoader = false;
      this.notificationService.success('Situation deleted', false);
      this.getSituationsBySolution();
    });
  }

  saveSituationTypeElement() {
    this.situationElement.account_situation_type_id = this.situationType.id;
    this.subscriptionsaveSituationTypeElement = this.solutionService.saveSituationTypeElement(this.situationElement).subscribe(() => {
      this.notificationService.success('You created a new element! You should add another.', false);
      this.clearElement();
      this.edit = true;
    });
  }

  clearElement() {
    this.situationElement = {};
  }

  clearSituationType() {
    this.situationType = {};
    this.edit = false;
    if (this.toggles.situation_type_edit) {
      this.toggles.situation_type_edit = false;
      this.toggles.showList = false;
      this.toggles.situation_type_stack_order = true;
      this.toggles.addType = false;
    } else {
      this.toggleShowList();
    }
  }

  clearSituation() {
    this.situation = {};
    this.resetBenefit();
    this.resetCosts();
    this.resetCaseStudies();
    this.resetAssets();
    this.resetCollateral();
    this.resetProjects();
  }

  saveSituationCosts(cost: { [klass: string]: any }, type: string) {
    if (this.situation) {
      this.type = type;

      const payload = {
        account_solution_situation_id: this.accountSituationId,
        account_solution_cost_id: cost['id'],
      };

      if (type === 'available') {
        payload['available'] = 1;
        payload['active'] = cost['changeToggleAutoload'];
      } else if (type === 'exclude') {
        payload['available'] = 0;
        payload['active'] = cost['changeToggleAutoload'];
      } else if (type === 'autoload') {
        payload['active'] = 1;
        payload['available'] = cost['changeToggleAvailable'];
      } else if (type === 'reset') {
      } else if (type === 'removeauto') {
        payload['active'] = 0;
        payload['available'] = cost['changeToggleAvailable'];
      }

      const payloadArray = {
        situation_costs: [payload],
      };

      this.subscriptionsaveSituationCost = this.solutionService.saveSituationCost(payloadArray, this.accountSituationId, cost['id']).subscribe((response) => {
        if (response.result) {
          if (this.type === 'available') {
            this.notificationService.success('Cost added successfully', false);
          } else if (this.type === 'exclude') {
            this.notificationService.success('Cost excluded successfully', false);
          } else if (this.type === 'autoload') {
            this.notificationService.success('Cost added to autoload successfully', false);
          }

          this.costs.find((x) => x.id === cost['id']).changeToggleAvailable = response.result[0]['available'];
          this.costs.find((x) => x.id === cost['id']).changeToggleAutoload = response.result[0]['active'];

          this.getSituationsBySolution();

          this.solutionService.triggerCost.next('reloadCosts');
        }
      });
    } else {
      this.notificationService.error('Please save your situation first', false);
    }
  }

  resetAllCost() {
    const payloadArray = {
      situation_costs: [],
    };

    for (let i = 0; i < this.costs.length; i++) {
      payloadArray['situation_costs'].push({
        account_solution_situation_id: this.accountSituationId,
        account_solution_cost_id: this.costs[i]['id'],
      });
    }

    this.subscriptionsaveSituationCost = this.solutionService.saveSituationCost(payloadArray, this.accountSituationId, 0).subscribe((response) => {
      if (response.result) {
        this.resetCosts();
        this.getSituationsBySolution();
        this.solutionService.triggerCost.next('reloadCosts');
      }
    });
  }

  showEditView(situation: { [klass: string]: any }) {
    this.mode = 'edit';
    this.situation = situation;
    this.situation['default_active'] = parseInt(situation['default_active']);
    this.situationTypeId = this.situation['situation_type_id'];
    const situationTypeIdObj = this.situationTypes.find((sit) => sit.value['id'] == this.situation['situation_type_id']);
    this.situationTypeIdObj = situationTypeIdObj;
    this.accountSituationId = this.situation['id'];
    this.getSituationTypeElements(this.situationTypeId);
    this.getSituationTree();
    this.toggles.showList = false;
    this.toggles.addType = false;
    this.toggles.showSituationForm = true;
    this.toggles.showBenefitsCosts = true;

    this.edit = true;
    this.selectedProject = undefined;

    this.resetBenefit();
    this.markSelectedBenefits(situation);

    this.resetCosts();
    this.markSelectedCosts(situation);

    this.resetCaseStudies();
    this.markSelectedCaseStudies(situation);

    this.resetAssets();
    this.markSelectedAssets(situation);

    this.resetCollateral();
    this.markSelectedCollateral(situation);

    this.resetProjects();
    this.markSelectedProjects(situation);

    this.resetChildren();
  }

  markSelectedBenefits(situation: { [klass: string]: any }) {
    const benefitsActivated = [];
    if (situation['benefits']) {
      for (let i = 0; i < situation['benefits'].length; i++) {
        benefitsActivated.push(situation['benefits'][i]['account_solution_metric_id']);
      }
    }

    if (this.benefits) {
      this.benefits = this.benefits.map((benefit: { [klass: string]: any }) => {
        const index = benefitsActivated.indexOf(benefit['id']);
        if (index !== -1) {
          const situationBenefit = situation['benefits'][index];
          benefit['changeToggle'] = true;
          benefit['changedActive'] = situationBenefit['active'];

          if (
            situationBenefit['driver_baseline_answer'] != null ||
            situationBenefit['financial_baseline_answer'] != null ||
            situationBenefit['conservative'] != null ||
            situationBenefit['probable'] != null ||
            situationBenefit['aggressive'] != null
          ) {
            benefit['valueChangeToggle'] = true;
          }
        }

        return benefit;
      });
    }
  }

  markSelectedCosts(situation: { [klass: string]: any }) {
    const costsAdded = [];
    if (situation['costs']) {
      for (let i = 0; i < situation['costs'].length; i++) {
        costsAdded.push(situation['costs'][i]['account_solution_cost_id']);
      }
    }

    if (this.costs) {
      this.costs = this.costs.map((cost) => {
        const index = costsAdded.indexOf(cost['id']);
        if (index !== -1) {
          cost['changeToggleAvailable'] = situation['costs'][index]['available'];
          cost['changeToggleAutoload'] = situation['costs'][index]['active'];
        }
        return cost;
      });
    }
  }

  markSelectedCaseStudies(situation: { [klass: string]: any }) {
    const caseStudiesActivated = [];
    if (situation['case_studies']) {
      for (let i = 0; i < situation['case_studies'].length; i++) {
        caseStudiesActivated.push(situation['case_studies'][i]['account_case_study_id']);
      }
    }

    if (this.caseStudies) {
      this.caseStudies = this.caseStudies.map((caseStudy: { [klass: string]: any }) => {
        const index = caseStudiesActivated.indexOf(caseStudy['id']);
        if (index !== -1) {
          const situationCaseStudy = situation['case_studies'][index];
          caseStudy['changedActive'] = situationCaseStudy['active'];
        }

        return caseStudy;
      });
    }
  }

  markSelectedAssets(situation: { [klass: string]: any }) {
    const assetsActivated = [];
    if (situation['assets']) {
      for (let i = 0; i < situation['assets'].length; i++) {
        assetsActivated.push(situation['assets'][i]['account_asset_template_id']);
      }
    }

    if (this.assets) {
      this.assets = this.assets.map((asset) => {
        const index = assetsActivated.indexOf(asset['id']);
        if (index !== -1) {
          const situationAsset = situation['assets'][index];
          asset['changedActive'] = situationAsset['active'];
        }

        return asset;
      });
    }
  }

  markSelectedCollateral(situation: { [klass: string]: any }) {
    const collateralActivated = [];
    if (situation['collaterals']) {
      for (let i = 0; i < situation['collaterals'].length; i++) {
        collateralActivated.push(situation['collaterals'][i]['account_collateral_id']);
      }
    }

    if (this.collateral) {
      this.collateral = this.collateral.map((collateral) => {
        const index = collateralActivated.indexOf(collateral['id']);
        if (index !== -1) {
          const situationCollateral = situation['collaterals'][index];
          collateral['changedActive'] = situationCollateral['active'];
        }

        return collateral;
      });
    }
  }

  markSelectedProjects(situation: { [klass: string]: any }) {
    const projectsActivated = [];
    if (situation['projects']) {
      for (let i = 0; i < situation['projects'].length; i++) {
        projectsActivated.push(situation['projects'][i]['account_project_type_id']);
      }
    }

    if (this.projectsList) {
      this.projectsList = this.projectsList.map((project: { [klass: string]: any }) => {
        const index = projectsActivated.indexOf(project['account_project_type_id']);
        if (index !== -1) {
          const situationProjects = situation['projects'][index];
          project['changedActive'] = situationProjects['active'];
          project['changedRecommend'] = situationProjects['recommend'];
        }

        return project;
      });
    }
  }

  updateSituationTypeStackOrder() {
    const situationStackOrder = this.accountSituationType.map((item, index) => {
      const situation: { [klass: string]: any } = {};
      situation['stack_order'] = index;
      situation['situation_type_id'] = item['id'];
      return situation;
    });

    const payload = {
      situation_types: situationStackOrder,
    };

    this.subscriptionupdateSituationTypeStackOrder = this.solutionService.updateSituationTypeStackOrder(payload).subscribe((response) => {
      if (response.result && response.result['success']) {
        this.accountSituationType = response.result['message'];
        this.notificationService.success('Stack Order updated successfully', false);
      }
    });
  }

  editSituationType(situationType: { [klass: string]: any }) {
    this.toggleAddNewType();
    this.situationType = situationType;
    this.toggles.situation_type_edit = true;
  }

  checkForChildren1(elementId: any) {
    if (this.situationTypeId != 3) {
      this.situationTypeElements.forEach((element) => {
        if (element.id == elementId) {
          if (element.has_children) {
            this.parent1Name = element.name;
            this.subscriptiongetSituationElementChildren = this.solutionService.getSituationElementChildren(elementId).subscribe((result) => {
              if (result.result.success) {
                this.elementChildren1 = result.result.message;
              }
            });
          }
        }
      });
    } else {
      const situationType = this.situationTypeElements.find((item) => item.id == elementId);
      this.parent1Name = situationType.name;
      this.elementChildren1 = situationType['children'];
    }
  }

  checkForChildren2(elementId: any) {
    this.elementChildren1.forEach((element) => {
      // tslint:disable-next-line: triple-equals
      if (element.id == elementId) {
        if (element.has_children) {
          this.parent2Name = element.name;
          this.subscriptiongetSituationElementChildren = this.solutionService.getSituationElementChildren(elementId).subscribe((result) => {
            if (result.result.success) {
              this.elementChildren2 = result.result.message;
            }
          });
        } else {
          this.situation.situation_element_id = elementId;
        }
      }
    });
  }

  checkForChildren3(elementId: any) {
    this.elementChildren2.forEach((element) => {
      // tslint:disable-next-line: triple-equals
      if (element.id == elementId) {
        if (element.has_children) {
          this.parent3Name = element.name;
          this.subscriptiongetSituationElementChildren = this.solutionService.getSituationElementChildren(elementId).subscribe((result) => {
            if (result.result.success) {
              this.elementChildren3 = result.result.message;
            }
          });
        } else {
          this.situation.situation_element_id = elementId;
        }
      }
    });
  }

  checkForChildren4(elementId: any) {
    this.elementChildren3.forEach((element) => {
      // tslint:disable-next-line: triple-equals
      if (element.id == elementId) {
        if (element.has_children) {
          this.parent4Name = element.name;
          this.subscriptiongetSituationElementChildren = this.solutionService.getSituationElementChildren(elementId).subscribe((result) => {
            if (result.result.success) {
              this.elementChildren4 = result.result.message;
            }
          });
        } else {
          this.situation.situation_element_id = elementId;
        }
      }
    });
  }

  checkForChildren5(elementId: any) {
    this.elementChildren4.forEach((element) => {
      // tslint:disable-next-line: triple-equals
      if (element.id == elementId) {
        if (element.has_children) {
          this.parent5Name = element.name;
          this.solutionService.getSituationElementChildren(elementId).subscribe((result) => {
            if (result.result.success) {
              this.elementChildren5 = result.result.message;
            }
          });
        } else {
          this.situation.situation_element_id = elementId;
        }
      }
    });
  }

  getSolutionCaseStudies() {
    this.subscriptiongetCaseStudiesPlain = this.solutionService.getCaseStudiesPlain(this.accountSolutionId).subscribe((result) => {
      this.caseStudies = [];
      if (result.result) {
        this.caseStudies = result.result;
      }
    });
  }

  saveSituationCaseStudyActive(active: number | 'NULL', csId: any) {
    const data = {
      situation_case_studies: [
        {
          account_solution_situation_id: this.accountSituationId,
          account_case_study_id: csId,
          active: active,
        },
      ],
    };

    this.subscriptionsaveSituationCaseStudyActive = this.solutionService.saveSituationCaseStudyActive(data, this.accountSituationId, csId).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Case Study status has been changed', false);
        this.caseStudies.find((cs: { [klass: string]: any }) => cs.id === csId).changedActive = response.result[0]['active'];
        this.getSituationsBySolution();
      }
    });
  }

  getAssets() {
    this.subscriptionreadValuePropAssetTemplatesWithoutId = this.valuepropService.readValuePropAssetTemplatesWithoutId().subscribe((result) => {
      this.assets = result.result;
    });
  }

  saveSituationAssetsActive(active: number | 'NULL', asId: any) {
    const data = {
      situation_assets: [
        {
          account_solution_situation_id: this.accountSituationId,
          account_asset_template_id: asId,
          active: active,
        },
      ],
    };

    this.subscriptionsaveSituationAssetActive = this.solutionService.saveSituationAssetActive(data, this.accountSituationId, asId).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Asset status has been changed', false);
        this.assets.find((x) => x.id === asId).changedActive = response.result[0]['active'];
        this.getSituationsBySolution();
      }
    });
  }

  getCollateral() {
    this.subscriptiongetCollateralList = this.solutionService.getCollateralList(this.accountSolutionId).subscribe((result) => {
      this.collateral = result.result;
    });
  }

  saveSituationCollateralActive(active: number | 'NULL', clId: any) {
    const data = {
      situation_collaterals: [
        {
          account_solution_situation_id: this.accountSituationId,
          account_collateral_id: clId,
          active: active,
        },
      ],
    };

    this.subscriptionsaveSituationCollateralActive = this.solutionService.saveSituationCollateralActive(data, this.accountSituationId, clId).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Collateral status has been changed', false);
        this.collateral.find((x) => x.id === clId).changedActive = response.result[0]['active'];
        this.getSituationsBySolution();
      }
    });
  }

  getProjects() {
    this.subscriptiongetProjects = this.solutionService.getProjects(this.solutionId).subscribe((response) => {
      this.projectsList = [];
      if (response.result) {
        this.projectsList = response.result;
      }
    });
  }

  saveSituationProjectsActive(type: string, project: { [klass: string]: any }) {
    const payload = {
      account_solution_situation_id: this.accountSituationId,
      account_project_type_id: project['account_project_type_id'],
    };

    if (type === 'addToList') {
      payload['active'] = 1;
      payload['recommend'] = project['changedRecommend'] === undefined ? null : project['changedRecommend'];
    } else if (type === 'removeFromList') {
      payload['active'] = 0;
      payload['recommend'] = project['changedRecommend'] === undefined ? null : project['changedRecommend'];
    } else if (type === 'addAndRecommend') {
      payload['active'] = 1;
      payload['recommend'] = 1;
    } else if (type === 'reset') {
    }

    const payloadArray = {
      situation_projects: [payload],
    };

    this.subscriptionsaveSituationProjectsActive = this.solutionService.saveSituationProjectsActive(payloadArray).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Project status has been changed', false);
        this.projectsList.find((x: { [klass: string]: any }) => x.id === project['id']).changedActive = response.result[0]['active'];
        this.projectsList.find((x: { [klass: string]: any }) => x.id === project['id']).changedRecommend = response.result[0]['recommend'];
        this.getSituationsBySolution();
      }
    });
  }
}
