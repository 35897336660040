<div class='row'>
	<div class='col-xs-12'>
		<div class='well well-info'>
			<b>{{ trans.trans.initiatives.value}}</b><br>
			{{trans.trans.ch_initiatives.value}}
		</div>
	</div>
</div>
<div class='row'>
	<div class='col-xs-12'>
		<table id="notes" class="table table-responsive" style='border-bottom: 0px;'>
			<thead>
				<tr>
					<th style="white-space: nowrap;">{{ trans.trans.ImpactArea.value }}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
							[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ImpactArea" key='vp_dashboard.initiatives.ImpactArea'></app-editTranslation></th>
					<th style="width: 100%;">{{ trans.trans.name.value }}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
							[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" key='vp_dashboard.initiatives.name'></app-editTranslation></th>
					<th style='white-space: nowrap; min-width: 150px;'>
						<div class='input-group-btn'>
							<button *ngIf="valueProp.vp_can_edit" class="btn btn-primary btn-sm pull-right" (click)="openAddUpdateInitiatives('add')"><i class="fa fa-plus"></i>{{trans.trans.add.value}}</button>
							<button *ngIf="valueProp.vp_can_edit && Initiatives.length" class="btn btn-success btn-sm pull-right" (click)="openAddUpdateInitiatives('update')"><i class="icon-pencil"></i> {{trans.trans.Update.value}}</button>
						</div>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="InitiativeLoader">
				<tr>
					<td colspan='3' style='border-top: 0px;'>
						<img class="loader" src="{{fullImagePath}}" />
					</td>
				</tr>
			</tbody>
			<tbody *ngIf="!Initiatives.length">
				<tr>
					<td colspan='3' style='border-top: 0px;'>
						{{ trans.trans.NoInitiavesFound.value }}
					</td>
				</tr>
			</tbody>
			<tbody *ngIf="Initiatives && Initiatives.length">
				<tr *ngFor="let Initiative of Initiatives" class="grid-row">
					<td style="white-space: nowrap;">{{Initiative.name}}</td>
					<td>{{Initiative.initiative}}</td>
					<td *ngIf="valueProp.vp_can_edit">
						<a class='btn btn-danger btn-sm pull-right' (click)="open(deleteUserModal, Initiative.id)"><i class="fa fa-times"></i> {{trans.trans.delete.value}}</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<ng-template #deleteUserModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ trans.trans.DeleteImpact.value }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{ trans.trans.RemoveImpactWarning.value }}<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.RemoveImpactWarning" key='vp_dashboard.initiatives.RemoveImpactWarning'></app-editTranslation></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="deleteImpact(deleteImpactId)">{{ trans.trans.Yes.value }}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ trans.trans.No.value }}</button>
		<img class="loader" *ngIf="InitiativeLoader" src="{{fullImagePath}}" />
	</div>
</ng-template>

<p-sidebar [(visible)]="showAddInitiatives" position='right' styleClass="p-sidebar-md">
	<h4>{{ trans.trans.AddInitiatives.value }}
		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.AddInitiatives" key='vp_dashboard.initiatives.AddInitiatives'></app-editTranslation></h4>
	<div class="control-sublabel">{{ trans.trans.Selectimpact.value }}
		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Selectimpact" key='vp_dashboard.initiatives.Selectimpact'></app-editTranslation></div>
	<div *ngFor="let item of ImpactAreaList">
		<div class='row'>
			<div class='col-xs-12'>
				<div class="checkbox custom-checkbox">
					<label>
						{{item.name}}
						<input type="checkbox" (click)="changeImpactArea(item, $event);" (change)="item.ischecked = !item.ischecked" />
						<span class="check"></span>
					</label>
				</div>
				<div class='row' *ngIf="item.ischecked">
					<div class='col-xs-12'>
						<textarea placeholder="Initiatives" class="form-control" rows="2" name="initiative" #initiative="ngModel" [(ngModel)]="item.initiative" required></textarea>
						<div *ngIf="initiative.errors && (initiative.dirty || initiative.touched)">
							<p *ngIf="initiative.errors.required">{{ trans.trans.Initiativeisrequired.value }}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
									[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Initiativeisrequired" key='vp_dashboard.initiatives.Initiativeisrequired'></app-editTranslation></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='row'>
		<div class='col-xs-12'>
			<button class="btn btn-success pull-right done-btn" [disabled]="saveBtnDisabled" (click)="saveImpactType()">
				{{trans.trans.save.value}}</button>
		</div>
	</div>
</p-sidebar>

<p-sidebar [(visible)]="showUpdateInitiatives" position='right' styleClass="p-sidebar-md">
	<h4>{{ trans.trans.UpdateInitiatives.value }}
		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.UpdateInitiatives" key='vp_dashboard.initiatives.UpdateInitiatives'></app-editTranslation></h4>
	<div class="control-sublabel">{{ trans.trans.Selectimpact.value }}
		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Selectimpact" key='vp_dashboard.initiatives.Selectimpact'></app-editTranslation></div>
	<div *ngFor="let item of ImpactAreaList">
		<div class='row'>
			<div class='col-xs-12'>
				<div class="checkbox custom-checkbox">
					<label>
						{{item.name}}
						<input type="checkbox" (change)="item.ischecked = !item.ischecked" [disabled]="item.ischeckedValue" [checked]="item.ischeckedValue" />
						<span class="check"></span>
					</label>
				</div>
			</div>
		</div>
		<div class='row' *ngIf="item.ischecked">
			<div class='col-xs-12'>
				<textarea placeholder="Initiatives" class="form-control" rows="2" name="initiative" #initiative="ngModel" [(ngModel)]="item.initiative" required></textarea>
				<div *ngIf="initiative.errors && (initiative.dirty || initiative.touched)">
					<p *ngIf="initiative.errors.required">{{ trans.trans.Initiativeisrequired.value }}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ImpactArea" key='vp_dashboard.initiatives.Initiativeisrequired'></app-editTranslation></p>
				</div>
			</div>
		</div>
	</div>
	<div class='row'>
		<div class='col-xs-12'>
			<button class="btn btn-success pull-right" (click)="updateImpactType()">{{trans.trans.Update.value}}</button>
		</div>
	</div>
</p-sidebar>
<!-- <ng-template #addInitiatives let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ trans.trans.AddInitiatives.value }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" id="addInitiatives">
		<div class="add-value-position">
			<div class="panel panel-white">
				<div class="panel-body">
					<div class="col-sm-12">
						<div class="step2-wrapper">
							<div class="control-sublabel">{{ trans.trans.Selectimpact.value }}</div>
							<div *ngFor="let item of ImpactAreaList">
								<div class="checkbox custom-checkbox">
									<label>
										{{item.name}}
										<input type="checkbox" (click)="changeImpactArea(item, $event);" (change)="item.ischecked = !item.ischecked" />
										<span class="check"></span>
									</label>
								</div>
								<div *ngIf="item.ischecked" class="m-b-md col-md-6">
									<textarea placeholder="Initiatives" class="form-control" rows="2" name="initiative" #initiative="ngModel" [(ngModel)]="item.initiative" required></textarea>
									<div *ngIf="initiative.errors && (initiative.dirty || initiative.touched)">
										<p *ngIf="initiative.errors.required">{{ trans.trans.Initiativeisrequired |
											translate }}</p>
									</div>
								</div>
								<div class="clearfix"></div>
							</div>
						</div>
						<button class="btn btn-primary pull-right done-btn" [disabled]="saveBtnDisabled" (click)="d('Cross click'); saveImpactType()">
							{{'general.save.value}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template> -->
<ng-template #updateInitiatives let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ trans.trans.UpdateInitiatives.value }}<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.UpdateInitiatives" key='vp_dashboard.initiatives.UpdateInitiatives'></app-editTranslation></h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" id="addInitiatives">
		<div class="add-value-position">
			<div class="panel panel-white">
				<div class="panel-body">
					<div class="col-sm-12">
						<div class="step2-wrapper">
							<div class="control-sublabel">{{ trans.trans.Selectimpact.value }}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
									[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Selectimpact" key='vp_dashboard.initiatives.Selectimpact'></app-editTranslation></div>
							<div *ngFor="let item of ImpactAreaList">
								<div class="checkbox custom-checkbox">
									<label>
										{{item.name}}
										<input type="checkbox" (change)="item.ischecked = !item.ischecked" [disabled]="item.ischeckedValue" [checked]="item.ischeckedValue" />
										<span class="check"></span>
									</label>
								</div>
								<div *ngIf="item.ischecked" class="m-b-md col-md-6">
									<textarea placeholder="Initiatives" class="form-control" rows="2" name="initiative" #initiative="ngModel" [(ngModel)]="item.initiative" required></textarea>
									<div *ngIf="initiative.errors && (initiative.dirty || initiative.touched)">
										<p *ngIf="initiative.errors.required">{{ trans.trans.Initiativeisrequired.value }}
											<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
												[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Initiativeisrequired" key='vp_dashboard.initiatives.Initiativeisrequired'></app-editTranslation></p>
									</div>
								</div>
								<div class="clearfix"></div>
							</div>
						</div>
						<button class="btn btn-primary pull-right done-btn" (click)="d('Cross click'); updateImpactType()">
							{{trans.trans.Update.value}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>