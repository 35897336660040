import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, of, Subject } from 'rxjs';
import { AlertService } from '../../../_services/alert.service';
import { MenuItem } from 'primeng/api';
import { GuidedTour, Orientation } from 'ngx-guided-tour';
import { GuidedTourService } from 'ngx-guided-tour';
import { UserService } from '@data/services/user/user.service';
import { CreateVPService } from '@data/services/create_vp/create_vp.service';
import { delay } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuePrpDashboardTranslations } from './value-prop-dashboard.translation';

@Component({
	selector: 'app-value-prop-dashboard',
	templateUrl: './value-prop-dashboard.component.html',
})
export class ValuePropDashboardComponent implements OnInit, OnDestroy {
	menuTop: MenuItem[];
	menuBottom: MenuItem[];
	menuAssets: any[] = [];
	isDev: boolean = false;
	showTranslate: boolean = false;
	showTranslateSidebar: boolean = false;

	viewTop: string = "roi";
	viewTopTitle: string = "Key Figures & ROI";
	viewBottom: string = "account";
	viewBottomTitle: string = "Account";
	dashboard: string = "v1";

	currentJustify = 'center';
	fullImagePath: string;
	image_url: string;
	year1BenefitsLoader: boolean;
	valuePropLoader: boolean;
	valueProp: any;
	year1BreakDown: any;
	year1BreakDownChartData: any;
	changeName: boolean = false;
	costBenefitChartLoader: boolean = false;
	costBenefitChartData: any;
	modalReference: any;
	valuePropId: any = null;
	valuePropStatusTypes: any = [];
	valuePropStatusComment: string = "";
	valuePropStatus: any;
	valuePropStatusName: string;
	valuePropStatus_editable: boolean = false;
	clonedName: string;
	clonedNameMVP: string;
	locked: boolean;
	enableInfoSection: boolean;
	enablePropSection: boolean;
	orientation: string = 'vertical';
	userId: string;
	token: string;
	tearsheetUrl: string;
	valueBriefUrl: string;
	valueDeckUrl: string;
	valueWorkbookUrl: string;
	// Contextual Help
	contextualHelp: any[] = [];
	clonedValuePropName: any;
	currentTab: any = {
		"toptabOrder": 'roiTab',
		"bottomtabOrder": 'scenario'
	};

	toptabOrder: object = {
		'roiTab': 0,
		'cashflowTab': 0,
		'tcoTab': 0,
		'notesTab': 0,
		'logTab': 0,
		'scorecardTab': 0
	};

	bottomtabOrder: object = {
		'scenario': 0,
		'benefits': 0,
		'cost': 0,
		'discovery': 0,
		// 'assets': 0,
		'caseStudies': 0,
		'collateral': 0,
	};

	header_color: string = "";

	graduatedDeploymentFeature: boolean = false;
	mvp: any = {};
	fromMVP: boolean = false;
	masterValuePropId: any;
	masterValuePropName: string;
	isCustomerShareType: boolean = true;
	cloneLoader: boolean = false;
	tcoFeature: boolean = false;

	loaded: boolean = false;
	// firstRun: boolean = true;

	selectedBenefit: any;

	statusesFeature: boolean = false;

	assetUrl: string = "";
	assetGenerator: string = "";
	loadBenefit: any;
	showDetails: boolean = true;
	showHelp: boolean = false;
	readValuePropAssetTemplatesSubscription: Subscription;
	AssetsMenu: boolean = false;
	privileges: string[];
	benefitOnTheFlyPrivilege: boolean;
	account_id: string;
	discoveryQuestions: boolean = false;
	isVPGroup: boolean;

	no_reload: boolean = false;

	user_id: string;
	VPGroupList: any[] = [];
	selectedGroup: any;
	showVPGroupAdd: boolean = false;
	hasKeyAssumptions: boolean = false;
	isAdmin: boolean = false;
	menuFilter: any;
	translatedMenu: any;
	benefitDetail: boolean;
	explained: any[] = [];

	ngUnsubscribe = new Subject();

	promoteToRealization = false;
	isRealizationUser = false;

	firstLoad = true;

	constructor(
		private guidedTourService: GuidedTourService,
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		private notificationService: NotificationService,
		private route: ActivatedRoute,
		private router: Router,
		private alertService: AlertService,
		private userService: UserService,
		private CreateVP: CreateVPService,
		public trans: ValuePrpDashboardTranslations,
		private cd: ChangeDetectorRef,
		private translationService: TranslationsV2Service
	) {


		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}



	ngOnInit() {

        this.valuePropId = "";
        let group_id = "";
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.valuePropId = params['id'];
            group_id = params['group_id']
        });
		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		if (group_id) { this.getGroup(group_id); }

		this.valuepropService.getDiscoveryQuestions(this.valuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result.length) { this.discoveryQuestions = true; }
		});

		this.header_color = localStorage.getItem("header_color");

		this.isAdmin = (sessionStorage.getItem("isAdmin") == "true" ? true : false);
		this.isDev = this.commonService.isDev$;

		this.isRealizationUser = this.checkForValueTrackers();

		this.viewTopTitle = this.trans.trans.key_figures_and_roi;
		this.viewBottomTitle = this.trans.trans.account;

		// get values from local storage
		if (localStorage.getItem("vpdashboardMenuTop") == null) { localStorage.setItem("vpdashboardMenuTop", "roi"); }
		this.viewTop = localStorage.getItem("vpdashboardMenuTop");

		if (localStorage.getItem("vpdashboardMenuTopTitle") == null) { localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.roi_full); }
		this.viewTopTitle = localStorage.getItem("vpdashboardMenuTopTitle");

		if (localStorage.getItem("vpdashboardMenuBottom") == null) { localStorage.setItem("vpdashboardMenuBottom", "account"); }
		this.viewBottom = localStorage.getItem("vpdashboardMenuBottom");
		if (this.viewBottom == "benefit_detail") { this.viewBottom = "benefits"; localStorage.setItem("vpdashboardMenuBottom", "benefits"); }

		if (localStorage.getItem("vpdashboardMenuBottomTitle") == null) { localStorage.setItem("vpdashboardMenuBottomTitle", this.trans.trans.account); }
		this.viewBottomTitle = localStorage.getItem("vpdashboardMenuBottomTitle");

		if (localStorage.getItem("vpdashboard") == null) { localStorage.setItem("vpdashboard", "v1"); }
		this.dashboard = localStorage.getItem("vpdashboard");

		let featuresOptions = sessionStorage.getItem('features');
		let featuresopt = featuresOptions.split(',');

		if (featuresopt.indexOf('33') >= 0) {
			this.dashboard = "v2";
		}

		if (localStorage.getItem("showDetails") == null) { localStorage.setItem("showDetails", this.showDetails.toString()); }
		this.showDetails = JSON.parse(localStorage.getItem("showDetails"));

		if (localStorage.getItem("showHelp") == null) { localStorage.setItem("showHelp", this.showHelp.toString()); }
		this.showHelp = JSON.parse(localStorage.getItem("showHelp"));


		this.user_id = sessionStorage.getItem("uid");

		this.account_id = sessionStorage.getItem('aid');

		this.getValuePropDetails(this.valuePropId);
		this.getValuePropGroupsByUser();
		// this.funcGetKeyAssumptions();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.refreshTranslation();
		})


		this.valuepropService.setSelectedBenefit.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.selectedBenefit = res;
			localStorage.setItem("vpdashboardMenuBottom", "benefit_detail");
			this.viewBottom = "benefit_detail";
		})

		this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			// this.inter
			this.loaded = false;
			if (response == "no_reload") {
				this.firstLoad = false;
				this.no_reload = true;
				// return;
			}
			this.getValuePropDetails(this.valuePropId);
			this.getYear1Benefits(this.valuePropId);
			this.getCostBenefitChart(this.valuePropId);
			this.cd.markForCheck();


			if (response == "rebuildMenu") {
				this.valuepropService.rebuildMenu.next();
			}
			if (response == "benefits_no_reload") {
				this.no_reload = true;
				this.cd.markForCheck();
				// this.getValuePropDetails(this.valuePropId);
				// this.getYear1Benefits(this.valuePropId);
				// this.getCostBenefitChart(this.valuePropId);
				return;
			}

			if (response == "ReturnToBenefits") { this.funcGotoBenefits(); return; }

			// if (response !== "no_reload") {
			// 	this.funcLoadInterstitial();
			// 	return;
			// }

			// this.funcGetKeyAssumptions();
			this.cd.detectChanges();
		});
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

			this.funcBuild();
		});
	}

	funcGotoBenefits() {
		this.viewBottom = "benefits";
		this.viewBottomTitle = this.trans.trans.benefits;
		localStorage.setItem("vpdashboardMenuBottom", "benefits");
	}

	refreshTranslation() {
		this.getTranslations();


		this.viewTopTitle = this.trans.trans.key_figures_and_roi;
		this.viewBottomTitle = this.trans.trans.account;

		// this.trans.funcLoadOverrideFromServer();
	}

	funcShowTranslate() {
		this.showTranslate = !this.showTranslate;
		// this.funcBuild();
	}

	funCcallbackSelectedBenefit(metric) {

		this.benefitDetail = true;

		this.selectedBenefit = metric;
		this.funcGotoBenefits();
	}

	getGroup(group_id) {
		this.CreateVP.getValuePropGroup(group_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

			this.masterValuePropName = result.result.master_value_prop.name
		})
	}

	getValuePropGroupsByUser() {
		this.CreateVP.getValuePropGroupsByUser(this.user_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {

			this.VPGroupList = result.result.value_prop_groups;
		})
	}

	addVpToGroup() {

		let members = [];
		members.push(this.valuePropId);
		let payload = {
			members: members
		}

		this.valuepropService.postAddVPtoVPGroup(this.selectedGroup.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result && (response.result.success !== false)) {
				this.showVPGroupAdd = false;
				this.notificationService.info("Value Prop added to Value Prop Group Successfully", false);
				this.getValuePropDetails(this.valuePropId);
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}

		});
	}

	funcBuild() {


		this.getDiscoveryQuestions(this.valuePropId);
		this.getYear1Benefits(this.valuePropId);
		this.getCostBenefitChart(this.valuePropId);
		// this.funcGetKeyAssumptions();

		this.assetUrl = this.commonService.getAssetUrl();

		this.enableInfoSection = false;
		this.enablePropSection = false;

		this.userId = sessionStorage.getItem('uid');
		this.token = sessionStorage.getItem('vcu');


		if (!this.contextualHelp.length) {
			this.userService.getContextualHelpList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

				if (result.result) {
					this.commonService.contextualHelp.next(result.result)
				}
			});
		}
		// get our help array
		this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			this.contextualHelp = response['Valueprop'];
		});

		this.valuepropService.loadMenuItem.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.selectedBenefit = res['data'];
			this.viewBottom = res['menuItem'];

		})
		// listen for our refresh data



		if (this.commonService.checkFeature('12')) {
			this.graduatedDeploymentFeature = true;
		}

		if (this.commonService.checkFeature('10')) {
			this.tcoFeature = true;
		}

		// vmware statuses
		if (this.commonService.checkFeature('29')) {
			this.statusesFeature = true;
		}

		const privs = sessionStorage.getItem('privileges');
		this.privileges = privs.split(",");
		if (this.privileges.length) {
			if (this.privileges.indexOf('6') >= 0) {
				this.benefitOnTheFlyPrivilege = true;
			}
		}


		this.mvp = sessionStorage.getItem('mvp');

		this.alertService.subscribeLoggedIn().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {

			// data that needs to be reload goes here and in ngoninit
			this.getValuePropDetails(this.valuePropId);
			this.getYear1Benefits(this.valuePropId);
			this.getCostBenefitChart(this.valuePropId);
		})




		this.valuepropService.readValuePropAssetTemplates(this.valueProp && this.valueProp.id ? this.valueProp.id : this.valuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.menuAssets = [];
			if (result.result) {
				let assets = result.result;

				for (let i = 0; i < assets.length; i++) {
					this.menuAssets.push({
						label: assets[i].name,
						icon: this.getFAIcon(assets[i].format),
						url: this.getAssetURL(assets[i]),
						target: "_blank",
						description: assets[i].description,
						format: assets[i].format
					})
				}
			}
			this.loaded = true;
		});
		// setup our menu
		this.menuTop = [];

		if (this.dashboard == "v1") {
			this.menuTop.push({ label: this.trans.trans.roi, command: () => { this.viewTop = "roi"; this.viewTopTitle = this.trans.trans.key_figures_and_roi; localStorage.setItem("vpdashboardMenuTop", "roi"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.roi_full); } });
		}
		if (this.dashboard == "v2") {
			this.menuTop.push({ label: this.trans.trans.key_figures_and_roi, command: () => { this.viewTop = "roi"; this.viewTopTitle = this.trans.trans.key_figures_and_roi; localStorage.setItem("vpdashboardMenuTop", "roi"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.roi_full); } });
		}
		this.menuTop.push({ separator: true });
		this.menuTop.push({ label: this.trans.trans.cashflow, command: () => { this.viewTop = "cashflow"; this.viewTopTitle = this.trans.trans.cashflow; localStorage.setItem("vpdashboardMenuTop", "cashflow"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.cashflow); } });
		this.menuTop.push({ separator: true });

		if (this.tcoFeature) {
			this.menuTop.push({ label: this.trans.trans.tco, command: () => { this.viewTop = "tco"; this.viewTopTitle = this.trans.trans.tco; localStorage.setItem("vpdashboardMenuTop", "tco"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.tco); } });
			this.menuTop.push({ separator: true });
		}

		this.menuTop.push({ label: this.trans.trans.notes, command: () => { this.viewTop = "notes"; this.viewTopTitle = this.trans.trans.notes; localStorage.setItem("vpdashboardMenuTop", "notes"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.notes); } });
		this.menuTop.push({ separator: true });
		this.menuTop.push({ label: this.trans.trans.logs, command: () => { this.viewTop = "log"; this.viewTopTitle = this.trans.trans.logs; localStorage.setItem("vpdashboardMenuTop", "logs"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.logs); } });
		this.menuTop.push({ separator: true });
		this.menuTop.push({ label: this.trans.trans.scorecard, command: () => { this.viewTop = "scorecard"; this.viewTopTitle = this.trans.trans.scorecard; localStorage.setItem("vpdashboardMenuTop", "scorecard"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.scorecard); } });
		this.menuTop.push({ separator: true });

		if (this.valueProp && this.valueProp.vp_can_edit) {
			this.menuTop.push({ label: this.trans.trans.share, icon: "prop-icon icon-share", command: () => { this.viewTop = "share"; this.viewTopTitle = this.trans.trans.share; localStorage.setItem("vpdashboardMenuTop", "share"); localStorage.setItem("vpdashboardMenuTopTitle", this.trans.trans.share); } });
		}

	}

	funcGotoExplain(event) {

		this.explained = event;
		this.viewBottom = "explained";
		this.viewBottomTitle = "Explanation of value changes";
	}

	funcLoadInterstitial() {
		let tmp = this.viewBottom;
		if (!tmp) { tmp = localStorage.getItem("vpdashboardMenuBottom"); }
		this.viewBottom = "interstitial";
		let delay_observable = of('').pipe(delay(500));
		delay_observable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.viewBottom = localStorage.getItem("vpdashboardMenuBottom");
		});
		delay_observable = null;

		let tmp2 = this.viewTop;
		if (!tmp2) { tmp2 = localStorage.getItem("vpdashboardMenuTop"); }
		this.viewTop = "interstitial";
		let delay_observable2 = of('').pipe(delay(500));
		delay_observable2.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.viewTop = localStorage.getItem("vpdashboardMenuTop");
		});
		delay_observable2 = null;

	}

	public dashboardTour: GuidedTour = {
		tourId: 'dashboard-tour',
		useOrb: false,
		steps: [
			{
				title: this.trans.trans.step1title,
				content: this.trans.trans.step1text,
				orientation: Orientation.Center
			},
			{
				title: this.trans.trans.step2title,
				content: this.trans.trans.step2text,
				selector: '.tourSwitchDashboard',
				orientation: Orientation.Left
			},
			{
				title: this.trans.trans.step3title,
				content: this.trans.trans.step3text,
				selector: ".tourExpandCollapse",
				orientation: Orientation.Left
			},
			{
				title: this.trans.trans.step4title,
				content: this.trans.trans.step4text,
				selector: ".tourBreadcrumb",
				orientation: Orientation.Bottom
			},
			{
				title: this.trans.trans.step5title,
				content: this.trans.trans.step5text,
				selector: ".tourMenubar",
				orientation: Orientation.Bottom
			},
			{
				title: this.trans.trans.step6title,
				content: this.trans.trans.step6text,
				orientation: Orientation.Center
			}
		]
	}

	startTour() {
		this.guidedTourService.startTour(this.dashboardTour);
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	// funcGetKeyAssumptions(): boolean {
	// this.hasKeyAssumptions = false;
	// this.ValuepropService.getKeyAssumptions(this.valueProp.id).subscribe((response) => {
	//

	// 	if (response && response.result && response.result.all_assumptions && response.result.all_assumptions.length) {
	// 		return true;
	// 	}
	// });
	// return false;
	// }

	funcSaveQ1() {
		if (!this.valueProp.vmw_q_1) { return false; }
		let payload = { answer: this.valueProp.vmw_q_1 };
		this.valuepropService.putVMWareQ1(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.notificationService.success("Question Updated Successfully", false);
		});

	}

	funcSaveQ2() {
		if (!this.valueProp.vmw_q_2) { return false; }
		let payload = { answer: this.valueProp.vmw_q_2 };
		this.valuepropService.putVMWareQ2(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.notificationService.success("Question Updated Successfully", false);
		});
	}

	funcSwitchDashboardView(view) {
		this.getYear1Benefits(this.valuePropId);
		this.getCostBenefitChart(this.valuePropId);

		this.dashboard = view;
		localStorage.setItem("vpdashboard", view);
	}

	funcShowDetails() {
		this.showDetails = !this.showDetails;
		localStorage.setItem("showDetails", this.showDetails.toString());
	}

	funcShowHelp() {
		this.showHelp = !this.showHelp;
		localStorage.setItem("showHelp", this.showHelp.toString());
	}

	showSearch() {
		if (this.viewBottom == "benefits") { return true; }
		return false;
	}

	funcGlobalRefresh() {
		this.valuepropService.refreshDashboard.next('refreshDashboard');
	}

	getAssetURL(asset) {
		let public_key = '';
		this.assetGenerator = sessionStorage.getItem('asset_gen_file');
		if(this.valueProp.calculator_public_key && this.valueProp.calculator_public_key !== '') {
			public_key = '&public_key='+this.valueProp.calculator_public_key;
		}
		return this.commonService.getAssetUrl() + "assets/" + this.assetGenerator + "?account_asset_template_id=" + asset.account_asset_template_id + "&user_id=" + this.userId + "&token=" + this.token + "&value_prop_id=" + this.valuePropId + public_key;
	}
	getFAIcon(format) {
		switch (format) {
			case "MS Word":
				return "fa fa-file-word-o"

			case "MS Excel":
				return "fa fa-file-excel-o"

			case "MS PowerPoint":
				return "fa fa-file-powerpoint-o"

			case "MS PDF":
				return "fa fa-file-pdf-o"

			default:
				return "fa fa-cloud-download"

		}
	}

	getFAColor(format) {
		switch (format) {
			case "MS Word":
				return { "color": "#2e5b97", "margin-right": "5px" };

			case "MS Excel":
				return { "color": "#3a6e46", "margin-right": "5px" };

			case "MS PowerPoint":
				return { "color": "#c55737", "margin-right": "5px" };

			case "MS PDF":
				return { "color": "#c60a09", "margin-right": "5px" };

			default:
				return { "color": "black", "margin-right": "5px" }
		}
	}

	changeValuePropName(id) {
		this.changeName = false;
		const payload = {
			"name": this.valueProp.name.trim()
		}
		this.valuepropService.updateValueProp(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.valueProp.name = response.result.value_prop.name;
				// const id = this.route.snapshot.paramMap.get('id');
				this.getValuePropDetails(this.valuePropId);
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}

		});
	}

	cancelValuePropName() {
		this.changeName = false;
		this.valueProp.name = this.clonedValuePropName;
	}

	getDiscoveryQuestions(id) {
		this.valuepropService.getDiscoveryQuestions(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result.length) { this.discoveryQuestions = true; }
		});
	}

	getValuePropDetails(id) {
		this.hasKeyAssumptions = false;
		if (!this.no_reload) {
			this.loaded = false;
		} else {
			this.loaded = true;
		}
		this.valueProp = null;
		this.valuePropLoader = true;
		this.valuepropService.getValuePropDetail(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				if (response.result.value_prop.has_key_assumptions == 1) { this.hasKeyAssumptions = true; this.valuepropService.rebuildMenu.next(); }

				this.valueProp = response.result.value_prop;
				localStorage.setItem("total_benefits_fmt", this.valueProp.total_benefits_fmt);
				localStorage.setItem("total_costs_fmt", this.valueProp.total_costs_fmt);
				localStorage.setItem("roi_decorated", this.valueProp.roi_decorated);
				localStorage.setItem("npv_fmt", this.valueProp.npv_fmt);
				localStorage.setItem("payback", this.valueProp.payback);
				localStorage.setItem("tco_fmt", this.valueProp.tco_fmt);
				localStorage.setItem("three_month_cost_of_inaction_fmt", this.valueProp.three_month_cost_of_inaction_fmt);

				// this.funcBuild();
				this.refreshTranslation();
				// this.funcGetKeyAssumptions();
				this.clonedValuePropName = this.valueProp.name;
				this.clonedName = 'Copy of ' + this.valueProp.name;
				this.valuePropStatusName = response.result.value_prop.vp_status;
				this.valuePropStatusComment = response.result.value_prop.vp_status_comment;
				this.valuePropStatus = {
					id: response.result.value_prop.value_prop_status,
					name: response.result.value_prop.vp_status,
					comment: (response.result.value_prop.vp_status_comment ? 1 : 0)
				}
				this.valuePropLoader = false;
				// this.spinnerService.loadSpinner(false);
				this.masterValuePropId = response.result.value_prop.master_value_prop.master_value_prop_id;
				this.masterValuePropName = response.result.value_prop.master_value_prop.name;
				if (this.masterValuePropId) {
					this.fromMVP = true;
				}

				if (this.valueProp['share_role_type_id'] != 4) {
					this.isCustomerShareType = false;
				}

				this.isVPGroup = (this.valueProp.is_grouped == 1 ? true : false);
				if (this.isVPGroup) { this.masterValuePropName = this.valueProp.value_prop_group_name; }
				if (this.isVPGroup) { this.masterValuePropId = this.valueProp.value_prop_group_id; }
				// this.ValuepropService.rebuildMenu.next();
				this.loaded = true;

			}
			if (!this.no_reload) {
				this.loaded = true;
			} else {
				this.loaded = true;
			}
		});
	}

	getYear1Benefits(id) {
		this.year1BreakDownChartData = null;
		// this.spinnerService.loadSpinner(true);
		this.valuepropService.year1breakdown(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.year1BreakDown = response.result.breakdown;
				if (this.year1BreakDown && this.year1BreakDown.length > 0) {
					var chartData = { labels: [], values: [], valuesfmt: [] };
					for (let i = 0; i < this.year1BreakDown.length; i++) {
						chartData.labels.push(this.year1BreakDown[i].name);
						chartData.values.push(this.year1BreakDown[i].total_benefits);
						chartData.valuesfmt.push(this.year1BreakDown[i].total_benefits_fmt);
					}
					this.year1BreakDownChartData = chartData;
				}
				// this.spinnerService.loadSpinner(false);
			}
		});
	}

	getCostBenefitChart(id) {
		this.costBenefitChartData = null;
		this.valuepropService.costBenefitChart(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				var costBenefitChart = response.result;

				if (costBenefitChart.length > 0) {
					var chartData = { labels: [], valuesLabels: [], values_1: [], values_2: [], values_1_fmt: [], values_2_fmt: [], isRoiChart: false };
					for (let i = 0; i < costBenefitChart.length; i++) {
						chartData.labels.push(costBenefitChart[i].name);
						chartData.valuesLabels = ["Benefits", "Costs"];
						chartData.values_1.push(costBenefitChart[i].benefits);
						chartData.values_2.push(costBenefitChart[i].costs);
						chartData.values_1_fmt.push(costBenefitChart[i].benefits_fmt);
						chartData.values_2_fmt.push(costBenefitChart[i].costs_fmt);
						chartData.isRoiChart = true;
					}
					this.costBenefitChartData = chartData;
				}
			}
		});
	}

	expandTabSection(type) {
		if (type === "info") {
			this.enableInfoSection = true;
		} else if (type === "prop") {
			this.enablePropSection = true;
		}
	}

	goToMasterValueProp() {
		this.router.navigate(["mastervalueprop/" + this.masterValuePropId + (this.isVPGroup ? "/group" : "")]);
	}

	navRepSimple() {
		this.router.navigate(["repv2/" + this.valuePropId]);
	}

	checkForValueTrackers() {
		let feature58 = this.commonService.checkFeature('58');

		let products = JSON.parse(sessionStorage.getItem('products'));

		let realizationUser = products.find((prod) => prod.product_id == '4');

		return feature58 && realizationUser;
	}
}
