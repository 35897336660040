import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DiscoveryQuestion } from '@data/services/valueprop/models/discovery-question.model';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Subject, Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ReviewBaselineTranslation } from '../review-baseline/review-baseline.translation';
import { BusinessReviewDataWithNote } from '@data/services/valuerealization/models/business-review.model';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';

@Component({
  selector: 'app-success-criteria-sidebar',
  templateUrl: './success-criteria-sidebar.component.html',
  styleUrls: ['./../goal-sidebar/goal-sidebar.component.scss']
})
export class SuccessCriteriaSidebarComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() objectId: string;
  @Input() section: string;
  @Input() selectedQuestion: DiscoveryQuestion;
  @Input() showNotes = true;
  @Input() canEdit = false;
  @Output() closeSidebar = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;
  breadcrumbKeyValues: Breadcrumbs;
  loading = false;
  loadingNoteSave = false;
  showNoteSidebar = false;
  successQuestions: DiscoveryQuestion[];
  successQuestionsFiltered: DiscoveryQuestion[];
  brsWithNotes: BusinessReviewDataWithNote[] = [];

  searchField = new FormControl('');
  successSidebarOpen = true;
  errorDict: Map<string, string> = new Map();
  editIndex: number;
  noteControl = new FormControl('', [Validators.required]);

  constructor(
    private commonService: CommonService,
    public trans: ReviewBaselineTranslation,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private valuePropService: ValuepropService,
    public businessReviewService: TrackerBusinessReviewService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.businessReviewService.brsWithNotes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((brWithNotes) => {
      this.brsWithNotes = brWithNotes;
      this.cd.detectChanges();
    });
    this.businessReviewService.canEdit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.canEdit = response;
    });

  }

  isEmptyInputValue(value: any): boolean {
    return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
  }

  resetSearchField(): void {
    this.searchField.reset();
  }

  ngOnDestroy(): void {
    this.businessReviewService.brsWithNotes$.next(null);
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  addNote() {
    const payload = {
      account_discovery_question_id: this.selectedQuestion.account_discovery_question_id,
      note: this.noteControl.value,
    };
    this.valuePropService
      .postDiscoveryQuestionNotes(this.valuePropId, payload)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingNoteSave = false))
      )
      .subscribe((response) => {
        if (response) {
          this.noteControl.reset();
          this.selectedQuestion.notes.unshift(response);
          this.successQuestions = [...this.successQuestionsFiltered];
        }
      });
  }

  deleteNote(noteId: string) {
    this.valuePropService
      .deleteDiscoveryQuestionNotes(noteId, this.valuePropId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingNoteSave = false))
      )
      .subscribe((response) => {
        if (response) {
          this.selectedQuestion.notes = this.selectedQuestion.notes.filter((n) => n.id !== noteId);
          this.successQuestions = [...this.successQuestionsFiltered];
        }
      });
  }

  cancel() {
    this.successSidebarOpen = false;
  }

  closeSidebarEventEmit(refresh = false): void {
    this.successSidebarOpen = false;
    this.closeSidebar.emit();
  }

  closeNotesSidebar() {
    this.showNoteSidebar = false;
    this.successSidebarOpen = false;
    this.closeSidebar.emit();
  }
}
