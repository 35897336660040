import { Injectable } from '@angular/core';

@Injectable()
export class AeGoalGroupsTranslations {
  public config: any = {
    component: 'ae-goal-groups',
    display_name: 'Add or Edit Goal Groups',
    description: 'Form to add or edit a goal group in models'
  };

  public trans: any = {
    requestError: 'Something went wrong, please try again',
    goalGroupUpdated: 'Goal Group updated successfully',
    goalGroupSaved: 'Goal Group saved successfully',
    addGoalGroup: 'Add Goal Group',
    editGoalGroup: 'Edit Goal Group',
    nameLabel: 'Name',
    descriptionLabel: 'Description',
    save: 'Save',
    back: 'Back',
  };
}
