import { Injectable } from '@angular/core';

@Injectable()
export class SearchCompanyTranslations {
  public config: any = {
    component: 'searchCompany',
    display_name: 'Search Company',
    description: 'Search for a company',
  };

  public trans: any = {
    addNew: 'Add New',
    back: 'Back',
    name_label: 'Name',
    letsGetStarted: `Let's get started`,
    accountId: 'Account ID',
    name: 'Company Name',
    account_defined_id: 'Account ID',
    crmAccountId: 'CRM Account ID',
    revenue_fmt: 'Revenue',
    vcAccountId: 'VC Account ID',
    employees_fmt: 'Employees',
    city: 'City',
    country: 'Country',
    description: 'Description',
    add_company: 'Add Company',
    address: 'Address',
    employees: 'Employees',
    heres_what_we_know_about_the_account_you_have_selected: `Here's what we know about the account you have selected`,
    no_companies_found: 'No Companies Found',
    pick_your_account: 'Pick your account',
    revenue: 'Revenue',
    revenueM: 'Revenue M',
    search_for_a_company: 'Search for a Company',
    sector: 'Sector',
    ticker: 'Ticker',
    website: 'Website',
    end_user_id: 'ECCID',
    market_area: 'Market Area',
    ch_oppurtunity_id:
      'Include the VMstar / SFDC opportunity ID to help with tracking the opportunity and reporting. If the opportunity does not have an ID yet, enter "99999999" as a place holder.',
    dashboard: 'Dashboard',
    hypotheses: 'Hypotheses',
    searchResultsFound: 'Search results found',
    companyName: 'Company Name',
    OpportunityId: 'Opportunity ID',
    letsCreateProp: `Let's create a Value Prop.`,
    searchForCompany: 'Search for the company below',
    noResults: 'No results found.',
    recents: 'Recent Searches',
    favorites: 'Favorites',
    addCompany: 'Add New Company',
    createNew: 'Create New',
    noRecents: 'No Recent Companies',
    noFavorites: 'No Favorite Companies',
    addToFav: 'Add to Favorites',
    removeFromFavorites: 'Remove from Favorites',
    removeFromFav: 'Remove from favorites',
    edit: 'Edit',
    isNowAFavorite: 'is now a favorite',
    isNoLongerAFavorite: 'is no longer a favorite',
    opportunityIdRequired: 'Opportunity ID is required',
    company: 'Company',
    whatIsTheCustomerProblem: 'What is the customer problem? Describe in detail.',
    state: 'State',
  };
}
