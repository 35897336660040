<div class="col-sm-12 Benefitstab">
  <div class="clearfix">
    <div class="pull-left">
      <p class="greyTitle">{{ trans.vpDashBoard.Benefits.sort_by | translate }}</p>

      <select class="form-control" (ngModelChange)="loadSelectedValueCategoryBenefits($event)" [(ngModel)]="selectedCategory">
        <option [ngValue]="undefined" hidden selected>{{ trans.vpDashBoard.Benefits.value_category | translate }}</option>
        <option [ngValue]="undefined">{{ trans.vpDashBoard.Benefits.all_value_categories | translate }}</option>
        <option *ngFor="let valueCategory of initialValueCategories" [ngValue]="valueCategory">
          {{ valueCategory.name }}
        </option>
      </select>
    </div>
    <div class="clearfix"></div>
    <div class="pull-left" *ngIf="masterValuePropFeature">
      <select class="form-control" autocomplete="off" (ngModelChange)="loadSelectedProjectBenefits($event)" name="selectedProject" [(ngModel)]="selectedProject">
        <option [ngValue]="undefined" hidden selected>{{ trans.vpDashBoard.Benefits.projects | translate }}</option>
        <option *ngFor="let p of projectsList" [ngValue]="p">
          {{ p.name }}
        </option>
      </select>
    </div>
    <div class="pull-right">
      <span class="changeView">
        <i class="fa fa-th-large" (click)="tableView('Grid_View')" [ngClass]="{ active: !cardViewBenefits }"></i>
        <i class="fa fa-list-ul" (click)="tableView('Table_View')" [ngClass]="{ active: cardViewBenefits }"></i>
      </span>

      <button *ngIf="canEdit && !valueCategoryBenefitLoader" class="btn btn_customizable" (click)="openSolution()" matTooltip="Add new" matTooltipPosition="above">
        <i class="fa fa-plus"></i>{{ trans.vpDashBoard.Benefits.add_new | translate }}
      </button>
    </div>
  </div>
  <div class="text-center" *ngIf="selectedProject">
    <h3>{{ selectedProject.name }}</h3>
  </div>
  <div *ngFor="let valueCategory of valueCategories">
    <div class="clearfix text-center customer_relations">
      <div class="col-sm-11 title_line">
        <h4>
          <span>{{ valueCategory.name }} ({{ valueCategory.numberOfBenefits }})</span>
        </h4>
      </div>
    </div>
    <!-- Benefit Sequencing -->
    <div
      class="row value_cat_benefits"
      *ngIf="valueCategory.benefits && valueCategory.benefits.length > 0 && !valueCategoryBenefitLoader && valueCategory.benefits[0] !== null"
      cdkDropList
      (cdkDropListDropped)="drop($event, valueCategory.benefits)"
      class="dragObjectsParent"
      [attr.data-valuecatId]="valueCategory.value_category_id"
    >
      <div class="col-sm-4 boxWrapper dragObjects" *ngFor="let benefit of valueCategory.benefits" cdkDrag [hidden]="cardViewBenefits">
        <i class="fa fa-times deleteVPBenefit" (click)="deleteVPBenefit(deleteBenefitModal, benefit)"></i>
        <div class="solution-card">
          <div class="icon-row">
            <code data-type="unit_type" *ngIf="benefit.unit_type" role="button">
              <span class="iconsSpan" matTooltipPosition="above" matTooltip="{{ benefit.unit_type }}">
                <i
                  class="icon-hourglass"
                  *ngIf="
                    benefit.unit_type == 'Seconds' ||
                    benefit.unit_type == 'Minutes' ||
                    benefit.unit_type == 'Hours' ||
                    benefit.unit_type == 'Days' ||
                    benefit.unit_type == 'Months' ||
                    benefit.unit_type == 'Years'
                  "
                ></i>

                <i
                  class="letter"
                  *ngIf="
                    benefit.unit_type == 'Dollars' ||
                    benefit.unit_type == 'Dollars - Thousands' ||
                    benefit.unit_type == 'Dollars - Millions' ||
                    benefit.unit_type == 'Dollars - Billions' ||
                    benefit.unit_type == 'Thousands' ||
                    benefit.unit_type == 'Millions' ||
                    benefit.unit_type == 'Billions'
                  "
                  >$</i
                >

                <i class="letter" *ngIf="benefit.unit_type == 'Percent'">{{ benefit.unit_type_abbr }}</i>

                <i class="letter" *ngIf="benefit.unit_type == 'Basis Points' || benefit.unit_type == 'Miles' || benefit.unit_type == 'Number'">1</i>
              </span>
            </code>

            <code data-type="impact_type" *ngIf="benefit.imapct_type" role="button">
              <span class="iconsSpan" matTooltip="{{ benefit.imapct_type }}" matTooltipPosition="above">
                <i
                  [ngClass]="
                    benefit.imapct_type == 'Revenue'
                      ? 'icon-bar-chart'
                      : benefit.imapct_type == 'Costs'
                      ? 'letter'
                      : benefit.imapct_type == 'Productivity'
                      ? ' icon-settings'
                      : benefit.imapct_type == 'Risk'
                      ? ' icon-shield'
                      : ''
                  "
                >
                  <span *ngIf="benefit.imapct_type == 'Costs'">$</span>
                </i>
              </span>
            </code>

            <code data-type="impact_type" addScratchPadModal *ngIf="benefit.accrual_type" role="button">
              <span class="iconsSpan" plaaddScratchPadModalcement="bottom" matTooltip="{{ benefit.accrual_type }}" matTooltipPosition="above">
                <i [ngClass]="benefit.accrual_type == 'One Time' ? 'icon-direction' : 'icon-refresh'"> </i>
              </span>
            </code>

            <code data-type="impact_type" *ngIf="benefit.unique_type" role="button">
              <span class="iconsSpan" matTooltip="{{ benefit.unique_type }}" matTooltipPosition="above">
                <i [ngClass]="benefit.unique_type == 'Unique' ? 'letter' : 'icon-doc'">
                  <span *ngIf="benefit.unique_type == 'Unique'">D</span>
                </i>
              </span>
            </code>
          </div>
          <div class="solution-types">
            <span>
              <button class="green_button" *ngIf="benefit.situations && benefit.situations[0]" (click)="showSituations(situationsList, benefit)">
                <i class="fa fa-check"></i>{{ trans.vpDashBoard.Benefits.situations | translate }}
              </button>
              <button class="orange_button cursorAuto" *ngIf="benefit.situations && !benefit.situations[0]">{{ trans.vpDashBoard.Benefits.situations | translate }}</button>
            </span>

            <span (click)="addDriverSubTypes(addDriverSubTypesModal, benefit)">
              <button class="green_button" *ngIf="benefit.subtypes && benefit.subtypes[0]"><i class="fa fa-check"></i>{{ trans.vpDashBoard.Benefits.subtypes | translate }}</button>
              <button class="orange_button" *ngIf="benefit.subtypes && !benefit.subtypes[0]">{{ trans.vpDashBoard.Benefits.subtypes | translate }}</button>
            </span>

            <span (click)="openScratchpadTypes(scratchpadTypes, benefit)">
              <button class="green_button" *ngIf="(benefit.scratchpads && benefit.scratchpads[0].types) || benefit.scratchpads[1].types || benefit.scratchpads[2].types">
                <i class="fa fa-check"></i>{{ trans.vpDashBoard.Benefits.scratchpads | translate }}
              </button>
              <button class="orange_button" *ngIf="benefit.scratchpads && !benefit.scratchpads[0].types && !benefit.scratchpads[1].types && !benefit.scratchpads[2].types">
                Scratchpads
              </button>
            </span>
          </div>
          <div class="benefit">
            <p class="benefit-name" (click)="editBenefit(benefit)">
              <a matTooltipPosition="below">{{ benefit.metric_name }}</a>
            </p>
          </div>
          <div class="formula">
            <p>{{ benefit.formula }}</p>
          </div>
          <div class="rating">
            <ng-template #t let-fill="fill">
              <span class="star" [class.full]="fill === 100"> &#9733; </span>
            </ng-template>
            <div class="rating-container">
              <ngb-rating [(rate)]="benefit.feedbackAvg" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
            </div>
            <div class="row">
              <div class="col-md-6" *ngIf="!isAccountAdmin">
                <button type="button" class="btn btn-light" (click)="selectedBenefit = benefit; openBenefitRating(rateBenefitModal)">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  {{ trans.vpDashBoard.Benefits.rate_this_benefit | translate }}
                </button>
              </div>
              <div class="col-md-6" *ngIf="isAccountAdmin && !benefit.feedbackAvg">
                <div class="feedback rateThisEmptyContainer">&nbsp;</div>
              </div>
              <div class="col-md-6">
                <div class="feedback" *ngIf="benefit.feedbackAvg">
                  <button type="button" class="btn btn-light" (click)="showFeedback(showFeedbackModal, benefit.id)">
                    {{ trans.vpDashBoard.Benefits.show_feedback | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive col-sm-12" [hidden]="tableViewBenefits">
        <div class="searchTableContainer">
          <table class="table custom-table">
            <thead>
              <tr>
                <th>{{ trans.vpDashBoard.Benefits.name | translate }}</th>
                <th>{{ trans.vpDashBoard.Benefits.driver_factor | translate }}</th>
                <th>{{ trans.vpDashBoard.Benefits.driver_subtypes | translate }}</th>
                <th>{{ trans.vpDashBoard.Benefits.driver_scratchpads | translate }}</th>
                <th>{{ trans.vpDashBoard.Benefits.financial_scratchpads | translate }}</th>
                <th>{{ trans.vpDashBoard.Benefits.improvement_scratchpads | translate }}</th>
                <th>{{ trans.vpDashBoard.Benefits.action | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="grid-row company-row" *ngFor="let benefit of valueCategory.benefits">
                <td class="company-link" (click)="editBenefit(benefit)" class="clickable">
                  <span [matTooltip]="benefit.description" matTooltipPosition="above">{{ benefit.metric_name }}</span>
                </td>
                <td>{{ benefit.driver_factor }}</td>
                <td>
                  <span [ngClass]="benefit.subtypes.length == 0 ? 'icon-settings' : 'icon-social-youtube'" (click)="addDriverSubTypes(addDriverSubTypesModal, benefit)"></span>
                </td>
                <td>
                  <span
                    [ngClass]="benefit.scratchpads[0]['types'] == false ? 'icon-settings' : 'icon-social-youtube'"
                    (click)="addScratchpads(addScratchPadModal, benefit, 'driverFactor')"
                  ></span>
                </td>
                <td>
                  <span
                    [ngClass]="benefit.scratchpads[1]['types'] == false ? 'icon-settings' : 'icon-social-youtube'"
                    (click)="addScratchpads(addScratchPadModal, benefit, 'financialFactor')"
                  ></span>
                </td>
                <td>
                  <span
                    [ngClass]="benefit.scratchpads[2]['types'] == false ? 'icon-settings' : 'icon-social-youtube'"
                    (click)="addScratchpads(addScratchPadModal, benefit, 'improvementFactor')"
                  ></span>
                </td>
                <td class="text-center" (click)="deleteVPBenefit(deleteBenefitModal, benefit)">
                  <span class="icon-trash"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="valueCategory.benefits && (valueCategory.benefits.length == 0 || valueCategory.benefits[0] == 'No Benefits found') && !benefitLoader" class="col-12 text-center">
        {{ trans.vpDashBoard.Benefits.no_benefits_for_this_value_category | translate }}
      </div>
    </div>
  </div>
  <div *ngIf="!valueCategoryBenefitLoader && valueCategories.length == 0" class="col-12 text-center m-t-xxs">
    <p>{{ trans.vpDashBoard.Benefits.no_benefits_found | translate }}</p>
  </div>
  <div class="row">
    <div *ngIf="valueCategoryBenefitLoader" class="col-12 text-center">
      <img class="actionLoader" src="{{ fullImagePath }}" />
    </div>
  </div>
</div>

<ng-template #addDriverSubTypesModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">Add Driver SubType</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="addSubtypeForm" #addSubtypeForm="ngForm">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <button type="button" class="btn btn_customizable m-b-sm" (click)="addSubTypes()"><i class="glyphicon glyphicon-plus"></i> Add</button>
            </div>
            <div class="form-group">
              <label for="dbAdminName" class="dbAdminName"
                >Enter the different types of <strong>{{ benefitSelectedList.driver_factor }}</strong> this covers:
              </label>
            </div>
            <div class="form-group">
              <table id="benefits" class="table custom-table benefitSubDriverTable">
                <thead>
                  <tr>
                    <th>{{ trans.props.tabDetails.benefits.SubType | translate }}</th>
                    <th>{{ trans.props.tabDetails.benefits.Value | translate }}</th>
                    <th>{{ trans.props.tabDetails.benefits.Improvement | translate }} [{{ benefitSelectedList.unit_type_abbr }}]</th>
                    <th>{{ trans.props.tabDetails.benefits.FinancialFactor | translate }}</th>
                    <th>{{ trans.props.tabDetails.benefits.FinancialValue | translate }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let textInputField of textInputFields; let i = index" class="grid-row">
                    <td>
                      <input type="text" class="form-control m-t-xxs" [(ngModel)]="textInputField.name" placeholder="Enter Name" name="driverSubTypesName_{{ i }}" />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control m-t-xxs inlineInput"
                        [(ngModel)]="textInputField.driver_value"
                        placeholder="Driver Value"
                        name="driverSubTypesDriverValue_{{ i }}"
                      />
                      X
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control m-t-xxs inlineInput"
                        [(ngModel)]="textInputField.improvement_value"
                        placeholder="Improvement Value"
                        name="driverSubTypesImpValue_{{ i }}"
                      />
                      X
                    </td>
                    <td>{{ benefitSelectedList.driver_factor }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control m-t-xxs"
                        [(ngModel)]="textInputField.financial_value"
                        placeholder="Improvement Value"
                        name="driverSubTypesFinancialValue_{{ i }}"
                      />
                    </td>
                    <td class="benefitSubTypesClose">
                      <span (click)="textClose(textInputField.id)">
                        <i class="fa fa-times text-close"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn_customizable m-t-xs m-b-xs" (click)="saveDriverSubTypes(addSubtypeForm)">{{ trans.vpDashBoard.Benefits.save | translate }}</button>
        <button type="button" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="open(deleteDriverSubTypeModal)">{{ trans.vpDashBoard.Benefits.delete | translate }}</button>
      </form>
      <div [hidden]="!benefitLoader" class="col-12 text-center">
        <img class="actionLoader" src="{{ fullImagePath }}" />
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleteDriverSubTypeModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.vpDashBoard.Benefits.delete_this_subtype | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span class="fa fa-times" aria-hidden="true"></span>
      </button>
      <div class="modal-body"></div>
      <p>{{trans.vpDashBoard.Benefits.are_you_sure_that_you_want_to_remove_these_subtypes_it_will_remove_these_subtypes_for_all_instances_of_this_driver_factor | translate</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn_customizable marg-h" (click)="deleteDriverSubType(driverFactorId)">{{ trans.vpDashBoard.Benefits.yes | translate }}</button>
      <button type="button" class="btn btn-light" (click)="c('Close click')">{{ trans.vpDashBoard.Benefits.no | translate }}</button>
      <div [hidden]="!benefitLoader" class="col-12 text-center">
        <img class="actionLoader" src="{{ fullImagePath }}" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addScratchPadModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">
        {{
          templateSetting === 'driverFactor'
            ? 'Driver Factor Scratchpad'
            : templateSetting === 'financialFactor'
            ? 'Financial
          Factor Scratchpad'
            : 'Improvement Factor Scratchpad'
        }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="addDriverFactorForm" #addDriverFactorForm="ngForm">
        <div class="row">
          <div class="col-md-9">
            <div class="form-group" *ngIf="toggleScratchpadFactor">
              <button type="button" class="btn btn_customizable" (click)="addDriverScratchpad()">
                <i class="glyphicon glyphicon-plus"></i> {{ trans.vpDashBoard.Benefits.add | translate }}
              </button>
              <button type="button" class="btn btn_customizable marg-h" (click)="toggleScratchpadFactor = !toggleScratchpadFactor">
                <i class="glyphicon glyphicon-plus"></i>Create Factor
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" *ngIf="toggleScratchpadFactor">
              <div class="SolutionsScratchpadWrapper">
                <div class="panel panel-white">
                  <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
                    {{ OpenParenDefault }} {{ driverScratchpadFactor_default.name }} {{ driverScratchpadFactorValue_default }} {{ CloseParenDefault }}
                  </div>
                  <div class="panel-body" style="overflow: inherit">
                    <div class="row">
                      <div class="col-xs-2">
                        <select class="form-control" [(ngModel)]="OpenParenDefault" name="OpenParen_default">
                          <option [ngValue]="undefined" selected>{{ trans.vpDashBoard.Benefits.select | translate }}</option>
                          <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                            {{ paren.sign }}
                          </option>
                        </select>
                      </div>
                      <div class="col-xs-6">
                        <p-dropdown appendTo="body"
                          class="keywest_dropdown"
                          [options]="scratchpadDriverFactorList"
                          name="scratchpadDriverFactorList"
                          [virtualScroll]="true"
                          [itemSize]="30"
                          [(ngModel)]="driverScratchpadFactor_default"
                          [filter]="true"
                          placeholder="Select a Driver Factor"
                          [required]="true"
                        ></p-dropdown>
                        <!-- <angular2-multiselect class="form-control" [data]="scratchpadDriverFactorList" name="driverScratchpadFactor_default" [(ngModel)]="driverScratchpadFactor_default" [settings]="dropdownSettings" required>
                                                </angular2-multiselect> -->
                      </div>

                      <div class="col-xs-2">
                        <input class="form-control" type="text" name="driverScratchpadFactorValue_default" [(ngModel)]="driverScratchpadFactorValue_default" />
                      </div>
                      <div class="col-xs-2">
                        <select class="form-control" [(ngModel)]="CloseParenDefault" name="CloseParen_default">
                          <option [ngValue]="undefined" selected>{{ trans.vpDashBoard.Benefits.select | translate }}</option>
                          <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                            {{ paren.sign }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="driverFactorScratchpad" *ngFor="let driverScratchpadField of driverScratchpadFields; let i = index">
                  <div class="panel panel-white">
                    <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
                      {{ funcGetSign(driverScratchpadField.operator) }} {{ driverScratchpadField.openParen }} {{ driverScratchpadField.operand }}
                      {{ driverScratchpadField.closeParen }}
                      <span (click)="driverScratchpadClose(driverScratchpadField.id)" class="pull-right">
                        <i class="fa fa-times"></i>
                      </span>
                    </div>
                    <div class="pane-body" style="overflow: inherit; padding: 20px">
                      <div class="row">
                        <div class="col-xs-2">
                          <select class="form-control" [(ngModel)]="driverScratchpadField.operator" name="selectOperator_{{ i }}" required>
                            <option [ngValue]="undefined" hidden selected>{{ trans.vpDashBoard.Benefits.operator | translate }}</option>
                            <option *ngFor="let operator of operatorTypesList" [ngValue]="operator.id">
                              {{ operator.sign }}
                            </option>
                          </select>
                        </div>
                        <div class="col-xs-2">
                          <select class="form-control" [(ngModel)]="driverScratchpadField.openParen" name="scratchpadOpenParen_{{ i }}">
                            <option [ngValue]="undefined" selected>{{ trans.vpDashBoard.Benefits.select | translate }}</option>
                            <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                              {{ paren.sign }}
                            </option>
                          </select>
                        </div>

                        <div class="col-xs-4">
                          <p-dropdown appendTo="body"
                            class="keywest_dropdown"
                            [options]="scratchpadDriverFactorList"
                            name="scratchpadDriverFactor_{{ i }}"
                            [(ngModel)]="driverScratchpadField.factor"
                            [filter]="true"
                            placeholder="Select a Driver Factor"
                            [virtualScroll]="true"
                            [itemSize]="30"
                            [required]="true"
                          ></p-dropdown>
                          <!-- <angular2-multiselect class="form-control" [data]="scratchpadDriverFactorList" name="scratchpadDriverFactor_{{i}}" [(ngModel)]="driverScratchpadField.factor" [settings]="dropdownSettings" required>
                                                    </angular2-multiselect> -->
                        </div>
                        <div class="col-xs-2">
                          <input class="form-control" type="text" name="scratchpadDriverValue_{{ i }}" [(ngModel)]="driverScratchpadField.operand" />
                        </div>

                        <div class="col-xs-2">
                          <select class="form-control" [(ngModel)]="driverScratchpadField.closeParen" name="scratchpadCloseParen_{{ i }}">
                            <option [ngValue]="undefined" selected>{{ trans.vpDashBoard.Benefits.select | translate }}</option>
                            <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                              {{ paren.sign }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn_customizable m-t-xs m-b-xs" (click)="saveDriverScratchpads(addDriverFactorForm)">
                {{ trans.vpDashBoard.Benefits.save | translate }}
              </button>
              <button type="button" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="open(deleteScratchpadModal)">{{ trans.vpDashBoard.Benefits.delete | translate }}</button>
            </div>

            <div class="form-group col-md-8" *ngIf="!toggleScratchpadFactor">
              <label
                ><strong>{{ trans.vpDashBoard.Benefits.new_factor | translate }}</strong></label
              >
              <input class="form-control" type="text" name="newFactor" [(ngModel)]="newFactor" />

              <button type="button" class="btn btn_customizable m-t-xs m-b-xs" (click)="createScratchpadFactor()">Create</button>
              <button type="button" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="toggleScratchpadFactor = !toggleScratchpadFactor">
                {{ trans.vpDashBoard.Benefits.back | translate }}
              </button>

              <span class="btnInline">
                <img class="actionLoader" *ngIf="factorCreateLoader" src="{{ fullImagePath }}" />
              </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="addScratchPadError">
          <div class="col-xs-12" style="color: red">{{ addScratchPadError }}</div>
        </div>
      </form>
      <div [hidden]="!benefitLoader" class="col-12 text-center">
        <img class="actionLoader" src="{{ fullImagePath }}" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteScratchpadModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.vpDashBoard.Benefits.delete_this_scratchpad | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ trans.vpDashBoard.Benefits.are_you_sure_you_want_to_remove_these_scratchpads_it_will_remove_these_subtypes_for_all_instances_of_this_scratchpads | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn_customizable" (click)="deleteScratchpad()">{{ trans.vpDashBoard.Benefits.yes | translate }}</button>
    <button type="button" class="btn btn-light marg-h" (click)="c('Close click')">{{ trans.vpDashBoard.Benefits.no | translate }}</button>
    <div [hidden]="!benefitLoader" class="col-12 text-center">
      <img class="actionLoader" src="{{ fullImagePath }}" />
    </div>
  </div>
</ng-template>

<ng-template #rateBenefitModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.vpDashBoard.Benefits.rate | translate }} {{ selectedBenefit.metric_name }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="rateBenefitForm" #rateBenefitForm="ngForm" (ngSubmit)="saveBenefitRating()">
        <div class="form-group">
          <ng-template #t let-fill="fill">
            <span class="star" [class.full]="fill === 100"> &#9733; </span>
          </ng-template>
          <ngb-rating [(rate)]="feedback.rating" [starTemplate]="t" [readonly]="readOnly" [max]="5"> </ngb-rating>
        </div>
        <div class="form-group clearfix row">
          <div class="col-md-2">
            <label for="comment">
              {{ trans.vpDashBoard.Benefits.comment | translate }}
            </label>
          </div>
          <div class="col-md-8">
            <textarea class="form-control" name="comment" id="comment" [(ngModel)]="feedback.comment" required="required"> </textarea>
          </div>
        </div>
        <div class="form-group clearfix">
          <button type="submit" class="btn btn_customizable pull-right" [disabled]="feedback.rating == 0 || rateBenefitForm.invalid">
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            {{ trans.vpDashBoard.Benefits.save | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #showFeedbackModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.vpDashBoard.Benefits.feedback | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="reviewList">
      <div class="review" *ngFor="let feedbk of feedbackList">
        <div class="row">
          <div class="col-md-2 col-xs-4 text-center">
            <p>
              <img class="profileImage" [src]="feedbk.profile_pic_filepath != '' ? feedbk.profile_pic_filepath : profilepicdefaultpath" />
            </p>
            <h5>{{ feedbk.user_name }}</h5>
          </div>
          <div class="col-md-10 col-xs-8">
            <div class="starRating">
              <ng-template #t let-fill="fill">
                <span class="star" [class.full]="fill === 100"> &#9733; </span>
              </ng-template>
              <ngb-rating [(rate)]="+feedbk.score" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
            </div>
            <p class="dateContainer"><i class="icon-calendar"></i>&nbsp; {{ feedbk.modified | date: 'dd MMM yyyy' }}</p>
            <p class="commentsForStarRating">{{ feedbk.comment }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteBenefitModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.vpDashBoard.Benefits.delete_this_benefit | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ trans.vpDashBoard.Benefits.are_you_sure_that_you_want_to_remove_these_benefits | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn_customizable" (click)="deleteBenefits()">{{ trans.vpDashBoard.Benefits.yes | translate }}</button>
      <button type="button" class="btn btn-light narg-h" (click)="c('Close click')">{{ trans.vpDashBoard.Benefits.no | translate }}</button>
      <span [hidden]="!benefitDeleteLoader" class="col-12 text-center">
        <img class="actionLoader" src="{{ fullImagePath }}" />
      </span>
    </div>
  </div>
</ng-template>

<ng-template #scratchpadTypes let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.vpDashBoard.Benefits.scratchpads | translate }} - {{ benefitSelectedList.metric_name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="solution-types m-t-md">
      <span (click)="addScratchpads(addScratchPadModal, benefitSelectedList, 'driverFactor'); d('Cross click')">
        <button class="green_button" [ngClass]="benefitSelectedList.scratchpads[0]['types'] != false ? 'green_button' : 'orange_button'">
          <i *ngIf="benefitSelectedList.scratchpads[0]['types'] != false" class="fa fa-check"></i>
          {{ trans.vpDashBoard.Benefits.driver_scratchpads | translate }}
        </button>
      </span>

      <span (click)="addScratchpads(addScratchPadModal, benefitSelectedList, 'financialFactor'); d('Cross click')">
        <button class="green_button" [ngClass]="benefitSelectedList.scratchpads[1]['types'] != false ? 'green_button' : 'orange_button'">
          <i *ngIf="benefitSelectedList.scratchpads[1]['types'] != false" class="fa fa-check"></i>
          {{ trans.vpDashBoard.Benefits.financial_scratchpads | translate }}
        </button>
      </span>

      <span (click)="addScratchpads(addScratchPadModal, benefitSelectedList, 'improvementFactor'); d('Cross click')">
        <button class="green_button" [ngClass]="benefitSelectedList.scratchpads[2]['types'] != false ? 'green_button' : 'orange_button'">
          <i *ngIf="benefitSelectedList.scratchpads[2]['types'] != false" class="fa fa-check"></i>
          {{ trans.vpDashBoard.Benefits.improvement_scratchpads | translate }}
        </button>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #situationsList let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.vpDashBoard.Benefits.situations | translate }} - {{ benefitSelectedList.metric_name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group" *ngIf="benefitSelectedList && benefitSelectedList.situations && benefitSelectedList.situations.length > 0">
      <ul>
        <li *ngFor="let situation of benefitSelectedList.situations">
          {{ situation.name }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<p-sidebar [(visible)]="toggleAddBenefit" (onHide)="selectedBenefit = null" styleClass="thresholds_sidebar p-sidebar-lg" position="right">
  <app-add-benefit-modal
    *ngIf="toggleAddBenefit && !valueCategoryBenefitLoader"
    (callbackDone)="toggleAddBenefit = false"
    [canEdit]="canEdit"
    [defaultTerm]="default_term"
    [selectedBenefit]="selectedBenefit"
    [solutionId]="solutionId"
  ></app-add-benefit-modal>
</p-sidebar>
