import { Heap, RequiredProperties } from './analytic.interface';


export function heapIdentify(id: string): void {
  const win = window as Window & typeof globalThis & { heap: Heap };
  const heap: Heap = win.heap;
  if (heap && !!heap.identify) {
    if (!heap.identity) {
      heap.identify(id);
    } else {
      heap.resetIdentity();
      heap.identify(id);
    }
  }
}

export function heapUserProperties(propertyObject: RequiredProperties): void {
  const win = window as Window & typeof globalThis & { heap: Heap };
  const heap: Heap = win.heap;
  const {
    userName,
    userEmail,
    account,
    managerEmail,
    userGroup,
    role,
    activePrivileges,
  } = propertyObject;
  if (heap && !!heap.addUserProperties) {
    heap.addUserProperties({
      'User Name': userName,
      'User Email': userEmail,
      Account: account,
      'Manager Email': managerEmail,
      'User Group': userGroup,
      Role: role,
      'Active Privileges': activePrivileges,
    });
  }
}

export function heapReset(): void {
  const win = window as Window & typeof globalThis & { heap: Heap };
  const heap: Heap = win.heap;
  if (heap && !!heap.resetIdentity && !!heap.identity) {
    heap.resetIdentity();
  }
}

export function heapIdentity(): string {
  const win = window as Window & typeof globalThis & { heap: Heap };
  const heap: Heap = win.heap;
  return heap.identity;
}

