
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				{{sidebar_title}}
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class='row'>
						<div class='col-xs-12'>
							<label class="sidebar_label_readonly">{{trans.trans.name.value}}</label><br>
							{{objSituation.name}}
						</div>
					</div>

					<div class='row'>
						<div class='col-xs-12'>
							<label class="sidebar_label_readonly">{{trans.trans.description.value}}</label>
							<br>{{objSituation.description}}
						</div>
					</div>

					<div class='row'>
						<div class='col-xs-12'>
							<label class="sidebar_label_readonly">{{trans.trans.type.value}}</label><br>
							{{objSituation.type === '1' ? 'Single Select' : 'Multi Select'}}
						</div>
					</div>


					<div class='row' *ngIf="situation_tree && situation_tree.length">
						<div class='col-xs-12'>
							<p-treeTable [value]="situation_tree" styleClass='keywest_table'>
								<ng-template pTemplate="header">
									<tr>
										<th>{{trans.trans.name.value}}</th>
										<th style='width: 100px;'></th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
									<tr [ttRow]="rowNode">
										<td>
											<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
											{{rowData.name}} 
											<i *ngIf="rowData.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="rowData.description" matTooltipPosition="above"></i>
											<span *ngIf="rowData.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.description">
												info
											</span>
										</td>
									</tr>
									<tr *ngIf="rowData.expanded">
										<td colspan='2'>
										</td>
									</tr>
								</ng-template>
							</p-treeTable>
						</div>
					</div>
				</div>
			</div>

		</div>
