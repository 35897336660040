import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsAnalysisComponent } from './results-analysis.component';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { Translations } from '@data/services/translation/translation.service';
import { SimpleImplementation } from 'app/repv2/simple.implementation';
import { RepSimpleTranslations } from 'app/repv2/simple.translation';
import { RepSimpleResultsTranslaions } from 'app/repv2/views/results/results.translation';
import { BenefitsMenuTranslations } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/menu/menu.translation';
import { DashboardSimpleImplementation } from 'app/value-prop/value-prop-dashboard/dashboard_simple/simple.implementation';
import { AssumptionsVpDashTranslations } from 'app/value-prop/value-prop-dashboard/discovery/assumptions/assumptions.translation';
import { AccountVpDashTranslations } from 'app/value-prop/value-prop-dashboard/scenario/account/account.translation';
import { ChartModule } from 'primeng/chart';
import { LineChartModule } from 'app/common/chart/line/line.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { BarLinechartModule } from 'app/common/chart/bar-line-chart/bar-line-chart.module';
import { FormsModule } from '@angular/forms';
import { BarModule } from 'app/common/chart/bar/bar.module';
import { TCOModule } from 'app/value-prop/value-prop-dashboard/tco/tco.module';
import { NumberTruncateModule } from '@shared/pipes/number-truncate.module';
import { HorizontalBarModule } from 'app/common/chart/horizontalbar/horizontalbar.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { RSV2CashflowSimpleModule } from 'app/repv2/views/cashflow_simple/cashflow_simple.module';

@NgModule({
  declarations: [ResultsAnalysisComponent],
  imports: [
    CommonModule,
    EditTranslationsModule,
    ChartModule,
    LineChartModule,
    ApplicationPipesModule,
    BarLinechartModule,
    FormsModule,
    BarModule,
    TCOModule,
    NumberTruncateModule,
    HorizontalBarModule,
    ProgressBarModule,
    RSV2CashflowSimpleModule

  ],
  exports:[ResultsAnalysisComponent],
  providers: [
    Translations,
    SimpleImplementation,
    RepSimpleResultsTranslaions,
    DashboardSimpleImplementation,
    RepSimpleTranslations,
    AssumptionsVpDashTranslations,
    AccountVpDashTranslations,
    BenefitsMenuTranslations
  ],
})
export class ResultsAnalysisModule { }