import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { CompanyService } from '@data/services/company/company.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { AddCasestudyRealizationTranslations } from './add-casestudy-realization.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { Company } from '@shared/models/company.model';
import { Solution } from '@shared/models/value-prop.model';
import { Model } from '@data/services/account/models/model.interface';
import { CaseStudy } from '@shared/models/case-study.model';

@Component({
  selector: 'app-add-casestudy-realization',
  templateUrl: './add-casestudy-realization.component.html',
  styleUrls: ['./add-casestudy-realization.component.scss'],
  providers: [CompanyService, AccountService],
})
export class AddCasestudyRealizationComponent implements OnInit, OnDestroy {
  @Input() valuepropId: string;
  @Input() companyID: string;
  @Input() solutions: Array<Solution | Model>;
  @Input() caseStudy: CaseStudy;
  @Input() canEdit: boolean;
  @Output() refreshSolutions = new EventEmitter();
  @Output() callback = new EventEmitter();
  modalReference: any;
  companyname: any;
  showActionLoader: boolean = false;
  companyList: boolean = true;
  companiesNoResult: boolean = false;
  detailedDescription: boolean = true;
  companyDetails: Company = null;
  companiesList: Array<Company> = [];
  modelList: Array<Solution>;
  companyId: number;
  model: Model | Solution;
  showCompanyDetails: boolean = false;
  isRedact: boolean = false;
  tags: string[] = [];

  fullImagePath: string;

  subscriptiongetSearchCompanies: Subscription;
  subscriptiongetCompanySummary: Subscription;
  subscriptionsaveCaseStudy: Subscription;

  ngUnsubscribe = new Subject();
  showTranslate = false;
  isLoading = false;
  editingName = false;
  originalName: string;

  constructor(
    private commonService: CommonService,
    private accountService: AccountService,
    private companyService: CompanyService,
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
    private valueRealizationService: ValuerealizationService,
    private translationService: TranslationsV2Service,
    public trans: AddCasestudyRealizationTranslations
  ) {
    const image_url = this.commonService.getImageUrl();
    this.fullImagePath = image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.getCompanyDetail();
    this.modelList = this.solutions;

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    if (this.caseStudy) {
      this.originalName = this.caseStudy.name;
      this.isRedact = !!+this.caseStudy.redacted;
      this.model = this.solutions.find((solution) => +solution.id === +this.caseStudy.account_solution_id);
      this.tags = this.caseStudy.tag && this.caseStudy.tag.length ? this.caseStudy.tag.split(',') : [];
    }
  }

  ngOnDestroy() {
    if (this.subscriptiongetSearchCompanies) {
      this.subscriptiongetSearchCompanies.unsubscribe();
    }
    if (this.subscriptiongetCompanySummary) {
      this.subscriptiongetCompanySummary.unsubscribe();
    }
    if (this.subscriptionsaveCaseStudy) {
      this.subscriptionsaveCaseStudy.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  cancelNameEdit(): void {
    this.editingName = false;
    this.caseStudy.name = this.originalName;
  }

  close(): void {
    this.callback.emit();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getRecentSearchComp(form: NgForm) {
    this.companyList = true;
    this.showActionLoader = true;
    let payload = {
      searchTerm: form.value.companyname,
      publicOnly: 0,
      synopsisOnly: 1,
    };
    this.subscriptiongetSearchCompanies = this.accountService.getSearchCompanies(payload).subscribe((result) => {
      if (result.result.data.length > 0) {
        this.companiesList = result.result.data;
        this.companyList = false;
        this.detailedDescription = true;
      } else {
        this.companiesNoResult = true;
      }
      this.showActionLoader = false;
    });
  }

  selectCompany(params) {
    this.companyList = true;
    this.companyId = params;
    this.getCompanyDetail();
  }

  getCompanyDetail() {
    this.showActionLoader = true;
    this.subscriptiongetCompanySummary = this.companyService.getCompanySummary(+this.companyID).subscribe((result) => {
      if (result.result) {
        this.companyDetails = result.result.company;
      }
      this.showCompanyDetails = true;
      this.showActionLoader = false;
      this.detailedDescription = false;
    });
  }

  refreshCaseStudy(): void {
    this.refreshSolutions.emit();
  }

  saveCaseStudy() {
    this.isLoading = true;
    if (!!this.caseStudy) {
      const payload = {
        company_id: this.companyID,
        case_study_id: this.caseStudy.id,
        name: this.caseStudy.name,
        tag: this.tags ? this.tags.join() : [],
        reference_url: this.caseStudy.reference_url,
        account_solution_id: this.model.account_solution_id ? this.model.account_solution_id : this.model.id,
        redacted: this.isRedact ? 1 : 0,
      };

      this.valueRealizationService
        .fullCaseStudyUpdate(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result.success) {
            this.valueRealizationService.readCaseStudy.next('loadCaseStudies');
            this.notificationService.success(this.trans.trans.caseStudyUpdate.value, false);
            this.refreshSolutions.emit();
            this.callback.emit(true);
          } else {
            this.notificationService.error(this.trans.trans.caseStudyUpdateFailure.value, false);
          }
        });
    } else {
      const payload = {
        company_id: this.companyID,
        account_solution_id: this.model.account_solution_id ? this.model.account_solution_id : this.model.id,
        account_id: sessionStorage.getItem('aid'),
        redacted: this.isRedact ? 1 : 0,
        value_prop_id: this.valuepropId,
        tag: this.tags ? this.tags.join() : null,
      };

      this.subscriptionsaveCaseStudy = this.valueRealizationService.saveCaseStudy(payload).subscribe((response) => {
        if (response.result && response.result.success) {
          this.valueRealizationService.readCaseStudy.next('loadCaseStudies');
          this.notificationService.success(this.trans.trans.caseStudySuccess.value, false);
          this.callback.emit(true);
        } else {
          this.notificationService.error(this.trans.trans.caseStudyfailure.value, false);
        }
        this.isLoading = false;
      });
    }
  }
}
