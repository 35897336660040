<div class='row'>
	<div class='col-xs-12'>
		<div class='title'>{{solution.name}}
			<i *ngIf="solution.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="solution.description" matTooltipPosition="above"></i>
			<span *ngIf="solution.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="solution.description" matTooltipPosition="above">
				info
			</span>
			<span *ngIf="can_edit" (click)='editOverview = !editOverview' class='clickable edit'><i class='fa fa-pencil'></i></span>
		</div>
	</div>
</div>

<div class='row'>
	<div class='col-xs-12'>
		<div class='model-ov-container'>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("factors")'>
				<div class='model-ov-card-title'>{{trans.trans.factors?.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_factors}}</div>
			</div>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("benefits")'>
				<div class='model-ov-card-title'>{{trans.trans.benefits.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_benefits}}</div>
			</div>
			<div class='model-ov-card' *ngIf="showGoalCard" (click)='callbackGotoMenuItem.emit("goals")'>
				<div class='model-ov-card-title'>{{trans.trans.goals.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_goals}}</div>
			</div>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("costs")'>
				<div class='model-ov-card-title'>{{trans.trans.costs.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_costs}}</div>
			</div>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("situations")'>
				<div class='model-ov-card-title'>{{trans.trans.situations.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_situation}}</div>
			</div>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("casestudies")'>
				<div class='model-ov-card-title'>{{trans.trans.case_studies.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_case_studies}}</div>
			</div>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("competitors")'>
				<div class='model-ov-card-title'>{{trans.trans.competitors.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_competitors}}</div>
			</div>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("thresholds")'>
				<div class='model-ov-card-title'>{{trans.trans.thresholds.value}}</div>
				<div class='model-ov-card-value'>{{solution.numbers_of_threshold}}</div>
			</div>
			<div class='model-ov-card' (click)='callbackGotoMenuItem.emit("scalers")'>
				<div class='model-ov-card-title'>{{trans.trans.scalers.value}}</div>
				<div class='model-ov-card-value'>{{solution.number_of_scalers}}</div>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='editOverview' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-ae-solution-list [frmSolutionObj]='formEditModel' [mode]='"edit"' (callback)='getSolutionsOverview();' (closeCallback)="closeOverviewEdit()" *ngIf="editOverview && can_edit"></app-ae-solution-list>

	<!-- <form name="formEditModel" #formEditModel="ngForm">
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				Edit Model Details
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class='row'>
						<div class='col-xs-12' appNGError [elemControl]='formEditModel.controls.name'>
							<label class='sidebar_label'>Name</label>
							<input type='text' name='name' ret-data='Model Name' [(ngModel)]="solution.name" class='form-control' required>
						</div>
					</div>

					<div class='row'>
						<div class='col-xs-12'>
							<label class='sidebar_label'>Description</label>
							<textarea name='description' [(ngModel)]="solution.description" class='form-control keywest_textarea'></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<div class='row'>
					<div class='col-xs-12'>
						<app-btn-save [form]='formEditModel' (callback)='updateSolution()' text='Update' class='btn btn_customizable pull-right'></app-btn-save>
					</div>
				</div>
			</div>
		</div>
	</form> -->
</p-sidebar>
