import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'ae_factors',
    display_name: 'Factors Add/Edit',
    description: 'Adding/Editing Factors',
  };

  public trans: any = {
    factor_groups: 'Factor Groups',
    unit_type: 'Unit Type',
    edit_factor: 'Edit Factor',
    add_factor: 'Add Factor',
    save: 'Save',
    update: 'Update',
    name: 'Name',
    description: 'Description',
    precision: 'Precision',
    scaled_by: 'Scaled By',
    scales_how: 'Scales How?',
    ratio: 'Ratio',
    formula: 'Formula',
    is_key_factor: 'Is Key Factor?',
    include_with_scalers: 'Include with Scalers',
    literal_number: 'Literal Number',
    greater_than_zero: 'Greater Than Zero',
    normalize_for_value: 'Normalize Factor for Value Realization',
    yes: 'Yes',
    no: 'No',
    min: 'Min',
    max: 'Max',
    increment: 'Increment',
    company_element: 'Company Element',
    value_category_name: 'Value Category Name',
    icon: 'Icon',
    createCustomAssetTag: 'Create custom asset tag for this factor?',
    nameTag: 'Name Tag',
    tagSameValueError: 'Tags cannot have the same values',
    tagsSpecialCharactersError: 'Tags cannot contain special characters',
    valueTag: 'Value Tag',
    are_you_sure: 'Are you sure?',
    back: 'Back',
    back_to_scratchpad: 'Back to Scratchpad',
    cancel: 'Cancel',
    factorValueTag: 'Factor Value Tag',
    valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    changesSavedSuccess: 'Changes saved successfully.',
    changesFailed: 'Factor add failed.',
    customFactorTagWarning: 'Ensure that you have removed this custom factor tag from your assets to avoid disruption.',
    onShow: 'onshow.',
    _name: '_name',
    _description: '_description',
    value: 'value',
    whereFactorIsUsed: 'Where Factor is Used',
    factorRelatesTo: 'Factor Relates To',
    situationsOnThisFactor: 'Situations On This Factor',
    model: 'Model',
    benefit: 'Benefit',
    baslineValue: 'Baseline Value',
    situations: 'Situations',
    overrideValue: 'Override Value',
    isAScratchPadFactorFor: 'Is a scratchpad factor for',
    scales: 'Scales',
    scalesHow: 'Scales How',
    noRecords: 'No Records Found',
    includeWithScalersTooltip:
      'By enabling this toggle, this factor will be shown on the "Scale your value" tile of "Define Scenario" when a benefit which includes the factor is present in your value proposition.  This is used to include a few key inputs in your initial value proposition created and as a best practice the number of factors included in scale your value should be kept to the critical few inputs that are both knowable early in the conversation and are most impactful to the final output.',
    type: 'Type',
    linkedToGoal: 'Linked to Goal',
  };
}
