import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SearchCompanyV3Module } from 'app/value-position/search-companyv3/search-companyv3.module';
import { RSV2CreateComponent } from './create.component';
import { RSV2CreateTranslations } from './create.translation';

const routes: Routes = [
  {
    path: '',
    component: RSV2CreateComponent,
  },
];

@NgModule({
  declarations: [RSV2CreateComponent],
  imports: [EditTranslationsModule, SearchCompanyV3Module, CommonModule, FormsModule, RouterModule.forChild(routes)],
  providers: [RSV2CreateTranslations],
  exports: [RSV2CreateComponent],
  entryComponents: [RSV2CreateComponent],
})
export class RSV2CreateModule {}
