import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StyleService } from 'app/style.service';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { AppImplementation } from '../../app.implementation';
import { TranslationsV2Service} from '@data/services/translationsv2/translationsv2.service';
import { SystemShareViewTranslations } from './system-share.translation';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { ComponentTranslationPayload } from '@shared/models/translation.models';

@Component({
	selector: 'app-system-share',
	templateUrl: './share.component.html',
	styleUrls: ['./share.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [SystemShareViewTranslations],
})
export class AppSystemShareView implements OnInit{
	profileMenu: MenuItem[];
	style2022$: Observable<boolean>;
	ngUnsubscribe$ = new Subject<boolean>();

	constructor(
		public aI: AppImplementation,
		private styleService: StyleService,
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: SystemShareViewTranslations,
	) {}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
			this.getTranslations();
		});
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);

		const payload: ComponentTranslationPayload = {
			account_id: sessionStorage.getItem('aid'),
			component: this.trans.config.component,
			lang: langAbbr,
			localTranslations: this.trans.trans,
		};
		this.translationService
			.getComponentTrans(payload)
			.pipe(takeUntil(this.ngUnsubscribe$))
			.subscribe((res) => {
				this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

				this.profileMenu = [
					{ label: this.trans.trans.logout.value, icon: 'fa fa-sign-out', command: () => { this.aI.logout(); } },
				]
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
