import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'residualBuyoutCalc'
})
export class ResidualBuyoutCalcPipe implements PipeTransform {

  transform(presentValue: number, quantity: number, residualValue: number, buyoutValue: number = null): number {
    if (buyoutValue !== null) {
      return presentValue * quantity * (residualValue / 100 ) * (buyoutValue / 100 );
    } else {
      return presentValue * quantity * (residualValue / 100 );
    }
  }

}
