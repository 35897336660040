<div class='keywest_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>Pain Points</div>
		</div>
		<div class='col-xs-6'>
			<button class="btn models_btn pull-right" (click)="funcShowAddPainPoint()" container="body" placement="top">
				<i class="fa fa-plus"></i> Add New</button>
		</div>
	</div>
</div>

<div class='row marg-v'>
	<div class='col-xs-4'>
		<div class="input-group models_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='Search' class='form-control'>
		</div>
	</div>
</div>

<p-menu #rowMenu appendTo='body' [popup]="true" [model]="menu" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='row'>
	<div class='col-xs-12'>
		<p-table #tableSolutionList [value]="painPoints" [columns]="cols" [paginator]="true" [rows]="10" styleClass='models_table' tableStyleClass='models_table'>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [pSortableColumn]="col.field">
						{{col.header}}
						<p-sortIcon [field]="col.field"></p-sortIcon>
					</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-painpoint>
				<tr>
					<td>{{painpoint.name}}</td>
					<td>{{painpoint.description}}</td>
					<td (click)='funcBuildMenu(painpoint, rowMenu, $event)'>
						<i class='fa fa-ellipsis-v clickable' style='font-size: 18px;'></i>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-sidebar [(visible)]='togglePainPoints' styleClass="thresholds_sidebar p-sidebar-lg" position='right'>
	<div class='sidebar_header_container'>
		<div class='sidebar_header'>
			{{sidebar_title}}
		</div>
	</div>
	<div class='sidebar_container'>
		<div class='thresholds_panel'>
			<div class="row">
				<div class="col-md-12">
					<label class="sidebar_label">Name</label>
					<input type='text' [(ngModel)]="name" class='form-control'>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<label class="sidebar_label">Description</label>
					<textarea [(ngModel)]="description" class='form-control'></textarea>
				</div>
			</div>
		</div>
	</div>
	<div class='btn_container'>
		<div class='row'>
			<div class="col-md-12">
				<img *ngIf="loadingSave" [src]='fullImagePath' class='loader'>
				<button *ngIf="!loadingSave" type="submit" class="btn models_btn pull-right" (click)="funcSave()">Save</button>
			</div>
		</div>
	</div>
</p-sidebar>