import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ApiServiceService } from '../../api-service.service';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { Category } from '@data/services/valueposition/models/category.interface';

@Component({
	selector: 'app-solution-category',
	templateUrl: './solution-category.component.html',
	styleUrls: ['./solution-category.component.scss']
})
export class SolutionCategoryComponent implements OnInit {
	fullImagePath: string;
	hideLoder: boolean;
	catagoryName: string;
	searchCategory: string;
	solutionCategory: Category[];

	@ViewChild('closePopup', { static: true }) closePopup: ElementRef;

	constructor(
		private apiServiceService: ApiServiceService,
		private translateService: TranslateService,
		public trans: Translations
	) {
		this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif',
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit(): void {
		const params = {
			noun: 'SolutionCategories',
			action: 'Read',
			user_id: sessionStorage.getItem('uid')
		};
		this.hideLoder = false;
		this.apiServiceService.readSolutionCategories(params).subscribe((result) => {
			this.solutionCategory = result.categories;
			this.hideLoder = true;
		});
	}

	deleteCategory(data: Category): void {
		const params = {
			noun: 'SolutionCategory',
			action: 'Delete',
			user_id: sessionStorage.getItem('uid'),
			account_id: data.account_id,
			id: data.id
		};
		if (confirm('Are you sure to delete ' + data.category + ' category ?')) {
			this.apiServiceService.deleteSolutionCategories(params).subscribe(() => {
				this.ngOnInit();
			});
		}
	}
	addCategory(catName: string): void {
		this.closePopup.nativeElement.click();
		const params = {
    noun: 'SolutionCategory',
    action: 'Create',
			user_id: sessionStorage.getItem('uid'),
			category: catName
		};

		this.apiServiceService.createSolutionCategories(params).subscribe(() => {
			this.catagoryName = '';
			this.ngOnInit();
		});
	}

}
