import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line: no-input-rename
  @Input() canEdit = false;
  imageUrl: string;
  fullImagePath: string;
  shareRoleType = '1';
  overviewLoader: boolean;
  editSolution: boolean;
  solution: { [klass: string]: any } = {};
  params: { [klass: string]: any };
  solutionId: string;
  solutionCategories: Array<{ [klass: string]: any }> = [];
  selectedItems: Array<{ [klass: string]: any }> = [];
  solutionCategoryList: Array<{ [klass: string]: any }> = [];
  contextualHelp: { [klass: string]: any } = {};

  subscriptioncontextualHelp: Subscription;
  subscriptionreadSolutionShareType: Subscription;
  subscriptiongetSolutionCategoryList: Subscription;
  subscriptiongetSolutionDetail: Subscription;
  subscriptionupdateSolutionDetail: Subscription;
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.getSolutionsOverview();
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.subscriptionreadSolutionShareType = this.solutionService.readSolutionShareType.subscribe((response) => {
      this.shareRoleType = response;
    });

    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptionreadSolutionShareType) {
      this.subscriptionreadSolutionShareType.unsubscribe();
    }
    if (this.subscriptiongetSolutionCategoryList) {
      this.subscriptiongetSolutionCategoryList.unsubscribe();
    }
    if (this.subscriptiongetSolutionDetail) {
      this.subscriptiongetSolutionDetail.unsubscribe();
    }
    if (this.subscriptionupdateSolutionDetail) {
      this.subscriptionupdateSolutionDetail.unsubscribe();
    }
  }

  getSolutionCategories() {
    this.subscriptiongetSolutionCategoryList = this.solutionService.getSolutionCategoryList().subscribe((result) => {
      if (result.result) {
        this.solutionCategories = result.result;
        this.solutionCategories = this.solutionCategories.filter((item) => {
          return item.category !== '';
        });
        const tempsolution = this.solutionCategoryList;
        let selectedItems;
        if (tempsolution) {
          selectedItems = this.solutionCategories.filter(function (obj1) {
            return tempsolution.some(function (obj2) {
              // tslint:disable-next-line: triple-equals
              return obj1.id == obj2;
            });
          });
        }
        this.selectedItems = selectedItems;
      }
    });
  }

  getSolutionsOverview() {
    this.route.params.subscribe((params) => (this.solutionId = params['id']));

    this.overviewLoader = true;
    this.subscriptiongetSolutionDetail = this.solutionService
      .getSolutionDetail(this.solutionId)
      .pipe(finalize(() => (this.overviewLoader = false)))
      .subscribe((response) => {
        if (response.result) {
          this.shareRoleType = response.result.share_role_type_id;
          this.solution = response.result;
        }
        this.solutionCategoryList = response.result.solution_category_id;
        this.getSolutionCategories();
      });
  }

  openEditPage() {
    this.editSolution = true;
  }

  cancelEditPage() {
    this.editSolution = false;
  }

  updateSolution() {
    this.route.params.subscribe((params) => (this.solutionId = params['id']));
    this.overviewLoader = true;
    this.selectedItems = this.selectedItems.map((item) => {
      return item['id'];
    });
    this.params = {
      name: this.solution.name,
      description: this.solution.description,
      solution_category_id: this.selectedItems,
    };
    this.subscriptionupdateSolutionDetail = this.solutionService
      .updateSolutionDetail(this.solution.id, this.params)
      .pipe(finalize(() => (this.overviewLoader = false)))
      .subscribe((response) => {
        if (response.result) {
          this.notification.success(response.result, false);
          this.getSolutionsOverview();
        }
      });
    this.editSolution = false;
  }
}
