<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ sidebar_title }}
    <app-editTranslation
      *ngIf="showTranslate"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.view_capability"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>
  <div class="sidebar_container">
    <div class="keywest_panel">
      <div class="row">
        <div class="col-md-12">
          <label for="name" class="sidebar_label_readonly"
            >{{ trans.trans.name.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.name"
              (callback)="getTranslations()"
            ></app-editTranslation> </label
          ><br />
          {{ selectedElem.name }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label for="description" class="sidebar_label_readonly"
            >{{ trans.trans.description.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.description"
              (callback)="getTranslations()"
            ></app-editTranslation> </label
          ><br />
          {{ selectedElem.description }}
        </div>
      </div>
    </div>
  </div>
</div>
