import { Injectable, OnDestroy } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { pluck, takeUntil } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { ConfigurableWorkflow, ConversationWorkflow, WorkflowAssignmentResponse, WorkflowAssignmentUpdateRequest, WorkflowDataResponse, WorkflowSection } from './models/workflow.interface';

@Injectable()
export class ConfigurableWorkflowService implements OnDestroy {
  private wf: ConversationWorkflow = sessionStorage.getItem('selectedConversationWorkflow') && sessionStorage.getItem('selectedConversationWorkflow') !== '' ? JSON.parse(sessionStorage.getItem('selectedConversationWorkflow')) : null;
  public selectedConversationWorkflow$ = new BehaviorSubject<ConversationWorkflow>(this.wf);
  private unsubscribe$ = new Subject<void>();
  public workflowAssignments = new Map<string, string>();
  private workflowAssignmentColorMap = new Map<string, string>();

  constructor(private commonService: CommonService) {
    this.setColorMap();
    this.selectedConversationWorkflow$
    .pipe(
      takeUntil(this.unsubscribe$))
    .subscribe((selected) => {
      if(selected) {
        sessionStorage.setItem('selectedConversationWorkflow', JSON.stringify(selected));
      }
    })
   }

   get workflowColorMap(){
    return this.workflowAssignmentColorMap;
   }
   
  public getWorkflows(): Observable<APIResult<ConfigurableWorkflow[]>> {
    const endpoint = `account/workflows/2/`;
    return this.commonService.getAPIService(endpoint);
  }

  public createWorkflow(body: ConfigurableWorkflow): Observable<APIResult<ConfigurableWorkflow[]>> {
    const endpoint = `account/fullWorkflow`;
    return this.commonService.postAPIService(endpoint, body);
  }

  public updateWorkflow(body: ConfigurableWorkflow): Observable<APIResult<ConfigurableWorkflow[]>> {
    const endpoint = `account/fullWorkflow`;
    return this.commonService.putAPIService(endpoint, body);
  }

  public updateTopWorkflow(workFlow: ConfigurableWorkflow): Observable<any> {
    const endpoint = `account/workflow`;
    return this.commonService.putAPIService(endpoint, workFlow);
  }

  public getWorkflowAssignments(userId: string | number): Observable<APIResult<WorkflowAssignmentResponse>> {
    const endpoint = `users/workflowAssignments/${userId}`;
    return this.commonService.getAPIService(endpoint);
  }

  public updateWorkflowAssigment(userId: string | number, assignment: WorkflowAssignmentUpdateRequest) {
    const endpoint = `users/${userId}/workflowAssignment`;
    return this.commonService.postAPIService(endpoint, assignment);
  }

  public resetWorkflowAssignment(userId: string | number, assignment: WorkflowAssignmentUpdateRequest ) {
    const endpoint = `users/${userId}/resetUserWorkflowAssignment`;
    return this.commonService.postAPIService(endpoint, assignment);
  }

  private setColorMap() {
    this.workflowAssignmentColorMap.set('0', '#70AD47');
    this.workflowAssignmentColorMap.set('1', '#ED7D31');
    this.workflowAssignmentColorMap.set('2', '#FF0000');
    this.workflowAssignmentColorMap.set('3', '#557FC9');
  }
    
  public getWorkflowData(set: number): Observable<APIResult<WorkflowDataResponse>> {
    const endpoint = `account/workflow-data/${set}`;
    return this.commonService.getAPIService(endpoint);
  }

  public cloneWorkflow(workflowId: string): Observable<APIResult<ConfigurableWorkflow[]>> {
    const endpoint = `account/cloneWorkflow/${workflowId}`;
    return this.commonService.postAPIService(endpoint, {});
  }


  getAccountWorkflowSections(workflowId: number | string): Observable<WorkflowSection[]> {
    const endpoint = `account/workflows/${workflowId}/sections`;

    return this.commonService.getAPIService(endpoint).pipe(
      pluck('result'),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
