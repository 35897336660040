<div class="sidebar_wrapper">
    <div class="sidebar_header">
        <div class="title flyout-title">
            {{ trans.trans.addThemes.value }}
            <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
                [transObj]="trans.trans.addThemes" (callback)="getTranslations()"></app-editTranslation>
        </div>
    </div>
    <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
    <div class="content-wrapper">
        <p>{{ trans.trans.uploadThemeFile.value }}</p>
        <p-accordion>
            <!-- Powerpoint Landscape -->
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="accordion-header-custom"
                        (click)="themesObj && !themesObj.powerpointLandScape ? $event.stopPropagation() : null">
                        <span class="bold">{{trans.trans.powerPointLandscape.value}}</span>
                        <span *ngIf="themesObj && !themesObj.powerpointLandScape">
                            <span (click)="triggerFileInput(2)" class="btn btn_customizable">
                                {{trans.trans.chooseFile.value}}
                            </span>
                            <input #fileUploadPPLS id="fileUploadPPLS" type="file" (change)="onFileChange($event, 2)"
                                style="display: none;" />
                            <span class="upload-file-label"
                                *ngIf="selectedPPLSFile.file">{{selectedPPLSFile.name}}</span>
                            <span (click)="uploadFile(2)" [matTooltip]="trans.trans.uploadFile.value"
                                *ngIf="selectedPPLSFile.file"
                                class="upload-button material-icons-outlined tooltip-custom fa fa-upload"></span>
                        </span>
                        <span *ngIf="themesObj && themesObj.powerpointLandScape" class="bold name-wrapper">
                            <span class="asset-name">{{themesObj.powerpointLandScape.name}}</span>
                            <app-are-you-sure (click)="$event.stopPropagation();" class="asset-delete" iconColor="red"
                                [text]="" [tooltip]="trans.trans.delete.value" [message]="trans.trans.areYouSure.value"
                                text="" icon="fa fa-trash"
                                (callbackYes)="deleteAsset(themesObj.powerpointLandScape)"></app-are-you-sure>
                        </span>
                    </div>
                </ng-template>
                <iframe *ngIf="themesObj && themesObj.powerpointLandScape?.download_url"
                    [src]="themesObj && themesObj.powerpointLandScape?.download_url" width="400px"
                    height="300px"></iframe>
            </p-accordionTab>
            <!-- Powerpoint Portrait -->
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="accordion-header-custom"
                        (click)="themesObj && !themesObj.powerpointPortrait ? $event.stopPropagation() : null">
                        <span class="bold">{{trans.trans.powerPointPortrait.value}}</span>
                        <span *ngIf="themesObj && !themesObj.powerpointPortrait">
                            <span (click)="triggerFileInput(0)" class="btn btn_customizable">
                                {{trans.trans.chooseFile.value}}
                            </span>
                            <input #fileUploadPPPT id="fileUploadPPPT" type="file" (change)="onFileChange($event, 0)"
                                style="display: none;" />
                            <span class="upload-file-label"
                                *ngIf="selectedPPPTFile.file">{{selectedPPPTFile.name}}</span>
                            <span (click)="uploadFile(0)" [matTooltip]="trans.trans.uploadFile.value"
                                *ngIf="selectedPPPTFile.file"
                                class="upload-button material-icons-outlined tooltip-custom fa fa-upload"></span>
                        </span>
                        <span *ngIf="themesObj && themesObj.powerpointPortrait" class="bold name-wrapper">
                            <span class="asset-name">{{themesObj.powerpointPortrait.name}}</span>
                            <app-are-you-sure (click)="$event.stopPropagation();" class="asset-delete" iconColor="red"
                                [text]="" [tooltip]="trans.trans.delete.value" [message]="trans.trans.areYouSure.value"
                                text="" icon="fa fa-trash"
                                (callbackYes)="deleteAsset(themesObj.powerpointPortrait)"></app-are-you-sure>
                        </span>
                    </div>
                </ng-template>
                <iframe *ngIf="themesObj && themesObj.powerpointPortrait?.download_url"
                    [src]="themesObj && themesObj.powerpointPortrait?.download_url" width="400px"
                    height="300px"></iframe>
            </p-accordionTab>
            <!-- Word -->
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="accordion-header-custom"
                        (click)="themesObj && !themesObj.word ? $event.stopPropagation() : null">
                        <span class="bold">{{trans.trans.word.value}}</span>
                        <span *ngIf="themesObj && !themesObj.word">
                            <span (click)="triggerFileInput(1)" class="btn btn_customizable">
                                {{trans.trans.chooseFile.value}}
                            </span>
                            <input #fileUploadWord id="fileUploadWord" type="file" (change)="onFileChange($event, 1)"
                                style="display: none;" />
                            <span class="upload-file-label"
                                *ngIf="selectedWordFile.file">{{selectedWordFile.name}}</span>
                            <span (click)="uploadFile(1)" [matTooltip]="trans.trans.uploadFile.value"
                                *ngIf="selectedWordFile.file"
                                class="upload-button material-icons-outlined tooltip-custom fa fa-upload"></span>
                        </span>
                        <span *ngIf="themesObj && themesObj.word" class="bold name-wrapper">
                            <span class="asset-name">{{themesObj.word.name}}</span>
                            <app-are-you-sure (click)="$event.stopPropagation();" class="asset-delete" iconColor="red"
                                [text]="" [tooltip]="trans.trans.delete.value" [message]="trans.trans.areYouSure.value"
                                text="" icon="fa fa-trash"
                                (callbackYes)="deleteAsset(themesObj.word)"></app-are-you-sure>
                        </span>
                    </div>
                </ng-template>
                <iframe *ngIf="themesObj && themesObj.word?.download_url"
                    [src]="themesObj && themesObj.word?.download_url" width="400px" height="300px"></iframe>
            </p-accordionTab>
        </p-accordion>

        <div class="file-upload-container">
            <div *ngIf="themes?.length > 1">
                <!-- Override -->
                <p-checkbox class="override-checkbox" [(ngModel)]="overrideExistingtemplates" [binary]="true"
                    inputId="override">

                </p-checkbox>
                <label class="ovverride-label"
                    for="override">{{trans.trans.OverrideExistingStandardTemplates.value}}</label>
                <!-- Generate Standard Assets -->
                <button (click)="onGenerateStandardAssetsClick()" class="btn btn_customizable generate-asset-btn">
                    <span>{{trans.trans.generateStandardAssets.value}}</span>
                </button>
            </div>
        </div>
    </div>
</div>