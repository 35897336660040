import { throwError as observableThrowError, Observable, Subject, of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { CommonService } from '@data/services/common/common.service';

import { NotificationService } from '@services/notification.service';

import { map, catchError, tap } from 'rxjs/operators';

import { IPInfo } from './models/ip-info';
import { LoginResponse } from 'app/login/interfaces/login-response';
import { LanguageType, UserData, UserInfo } from '@shared/models/user-info.model';
import { APIResult } from '@shared/models/api-result.model';

@Injectable()
export class UserService {
  serviceUrl: string;
  endpoint: string;
  languages: any;
  sortedLanguages: any;
  profileImage = new Subject<string>();
  public replaceFF134$ = new BehaviorSubject<boolean>(false);
  public toggleSupportSidebar = new Subject<any>();
  private _user$ = new BehaviorSubject<UserInfo>(null);
  private _uid$ = new Subject<number>();

  constructor(private http: HttpClient, private commonService: CommonService, private notification: NotificationService) {
    this.serviceUrl = this.commonService.getServiceUrl();
  }

  get user(): Observable<UserInfo> {
    return this._user$ as Observable<UserInfo>;
  }

  set userInfo(user: UserInfo) {
    this._user$.next(user);
  }

  get uid(): Observable<number> {
    return this._uid$ as Observable<number>;
  }

  set setUid(id: number) {
    this._uid$.next(id);
  }

  public jsonToUrlParam(data) {
    let params = new HttpParams();
    for (let key in data) {
      params.set(key, data[key]);
    }
    return params;
  }

  public login(payload): Observable<{ result: LoginResponse }> {
    const endpoint = 'login';
    return this.commonService.postAPIService(endpoint, payload);
  }

  resetUsersPassword(user) {
    let endpoint = `/forgotPassword`;
    return this.commonService.postAPIService(endpoint, { email: user.email });
  }
  public resetPassword(payload) {
    let endpoint = 'users/resetpassword/' + sessionStorage.getItem('uid');
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getUser(uid: number): Observable<{ result: UserInfo }> {
    const token = sessionStorage.getItem('vcu');
    if (!token) {
      return observableThrowError('No token set.');
    }
    const endpoint = this.serviceUrl + 'users/' + uid;
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'User-Id': String(uid),
        token,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      }),
    };
    return this.http.get<{ result: UserInfo }>(endpoint, HttpOptions).pipe(
      map((result) => {
        this.userInfo = result.result;
        return result;
      }),
      catchError((err) => {
        if (err.status == 403) {
          let isLogout = true;
          let logoutMessage = err.error['result'] + ' Redirecting to login page';
          this.notification.error(logoutMessage, isLogout);
        }
        return observableThrowError(err.message || 'Server error');
      })
    );
  }

  public getTrophies(uid: number) {
    let endpoint = 'users/trophies/' + uid;
    return this.commonService.getAPIService(endpoint);
  }

  public updateUser(uid: number, payload: any) {
    let endpoint = 'users/updateprofile/' + uid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateUserProfileIcon(payload) {
    let userID = sessionStorage.getItem('uid');
    let endpoint = 'users/profileIcon/update/' + userID;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public getAllUsersList(): Observable<APIResult<{ data: UserData[] }>> {
    let accountId = sessionStorage.getItem('aid');
    let endpoint = 'account/users/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public disconectFromCRM(id) {
    let endpoint = `users/clearCRMConnection/${id}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getUsersList(queryString) {
    let accountId = sessionStorage.getItem('aid');
    let endpoint = 'account/users/' + accountId + queryString;
    return this.commonService.getAPIService(endpoint);
  }

  public getteamUsersList() {
    let user_id = sessionStorage.getItem('uid');
    let endpoint = 'team/' + user_id;
    return this.commonService.getAPIService(endpoint);
  }

  public Createteammember(payload) {
    let endpoint = 'team/create';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public deleteTeamMember(data) {
    let endpoint = 'team/teamMemberId/' + data;
    return this.commonService.deleteAPIService(endpoint);
  }

  public AddNewUser(payload) {
    let endpoint = 'users/create';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public deleteUser(UserId) {
    let endpoint = 'users/' + UserId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public deleteUserAndReassign(UserId, reassignUserId) {
    let endpoint = 'users/deleteUser/' + UserId + '/assignToUser/' + reassignUserId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public hasValueProp(UserId) {
    let endpoint = 'valueProps/readValuePropCountByUser/' + UserId;
    return this.commonService.getAPIService(endpoint);
  }

  public getUsersRoles() {
    let endpoint = 'fact/roleTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public readUserTerms() {
    let endpoint = 'users/read/terms';
    return this.commonService.getAPIService(endpoint);
  }

  public acceptUserTerms() {
    let user_id = sessionStorage.getItem('uid');
    let endpoint = 'users/acceptTerms/' + user_id;
    return this.commonService.putAPIService(endpoint, '');
  }

  public changeTempPassword() {
    let user_id = sessionStorage.getItem('uid');
    let endpoint = 'users/changeTempPassword/' + user_id;
    return this.commonService.putAPIService(endpoint, '');
  }

  public forgotPwdReq(payload) {
    let endpoint = 'forgotPassword';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getLanguages(sortAlphabetically = false, refresh = false): Observable<APIResult<LanguageType[]>> {
    if (this.languages && !refresh) {
      return of(sortAlphabetically ? this.sortedLanguages : this.languages);
    } else {
      let endpoint = 'fact/languageTypes';
      return this.commonService.getAPIService(endpoint).pipe(
        tap((response) => {
          this.languages = {
            ...(response as any),
            result: [...(response as any).result],
          };
          this.sortedLanguages = {
            ...(response as any),
            result: (response as any).result.sort((l1, l2) => {
              const name1 = l1.name ? l1.name.toLowerCase() : '';
              const name2 = l2.name ? l2.name.toLowerCase() : '';
              if (name1 < name2) {
                return -1;
              }
              if (name1 > name2) {
                return 1;
              }
              return 0;
            }),
          };
        }),
        map(() => (sortAlphabetically ? this.sortedLanguages : this.languages))
      );
    }
  }

  public getCurriences() {
    let endpoint = 'fact/currencyTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public SCBHashCreate(payload) {
    let endpoint = 'users/SCBHash/create';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public SCBDownloadRead() {
    let user_id = sessionStorage.getItem('uid');
    let endpoint = 'scb/downloads/' + user_id;
    return this.commonService.getAPIService(endpoint);
  }

  public spendCredit(payload) {
    let endpoint = 'users/credits/spend';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveUserColor(userid, payload) {
    let endpoint = 'users/assignColor/' + userid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveAccountColor(accountid, payload) {
    let endpoint = 'account/accountColor/create/' + accountid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getIsAccountAdmin() {
    let privilegeOption = sessionStorage.getItem('privileges');
    let options = privilegeOption.split(',');
    if (options.indexOf('1') >= 0) {
      return true;
    }
    return false;
  }

  public hasSCBHashAlready(user_id, company_id) {
    let endpoint = 'users/hasSCBHash/' + user_id + '/' + company_id;
    return this.commonService.getAPIService(endpoint);
  }

  public getContextualHelp(app_module_id) {
    let endpoint = 'fact/contextualHelp/appModuleId/' + app_module_id;
    return this.commonService.getAPIService(endpoint);
  }

  public getContextualHelpList() {
    let endpoint = 'fact/contextualHelpList';
    return this.commonService.getAPIService(endpoint);
  }

  public saveAccountImage(payload, id) {
    let endpoint = 'account/updateLogoAndIcon/' + id;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public getFactColorList() {
    let endpoint = 'fact/readColors';
    return this.commonService.getAPIService(endpoint);
  }

  public GetValuePropStatus(account_id) {
    let endpoint = 'account/valuePropStatusTypes/' + account_id;
    return this.commonService.getAPIService(endpoint);
  }

  public CreateValuePropStatus(payload, account_id) {
    let endpoint = 'account/valuePropStatusType/' + account_id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public UpdateValuePropStatus(payload, account_id) {
    let endpoint = 'account/valuePropStatusType/' + account_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public DeleteValuePropStatus(account_id, id) {
    let endpoint = 'account/valuePropStatusType/' + account_id + '/valuePropStatusId/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public SequenceValuePropStatus(payload, account_id) {
    let endpoint = 'account/valuePropStatusTypeSequence/' + account_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public verifyUser(payload) {
    let endpoint = 'auth/verify';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public verifySAML(payload) {
    let endpoint = 'users/saml_authenticate';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public setPrivileges(payload) {
    let endpoint = 'user/privilege';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public unsetPrivilege(privilege_id) {
    let endpoint = 'user/privilege/' + privilege_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  getIPAddress(): Observable<IPInfo> {
    return this.http.get<IPInfo>('https://api.ipify.org/?format=json');
  }

  getUserCountry(ip: { ip: string }): Observable<{ result: { country_code: string } }> {
    let endpoint = 'fact/userCountry';
    return this.commonService.postAPIService(endpoint, ip);
  }

  public getUserLanguage() {
    const locale = sessionStorage.getItem('user_lang');
    return locale ? locale.replace('_', '-') : 'en-US';
  }
}
