import { Injectable } from '@angular/core';

@Injectable ()

export class CollateralVpDashTranslations {

    public config: any = {
        component: 'collateralVpDash',
        display_name: 'Value Prop Collateral',
        description: 'Collateral tab in value prop dashboard'
    };

    public trans: any = {
        collateral: 'Collateral',
        ch_collateral: `Collateral is already composed content (PDF, PowerPoint, etc.)
        that you want to use to support your value proposition to a customer.
        Common types of collateral are formal case studies, data sheets, analyst reports,
        and anything that can defend your thinking on the value you can provide.`,
        format: 'Format',
        name: 'Name',
        url: 'URL',
        noCollateralsFound: 'No collateral found',
        ChooseOne: 'Choose One',
        description: 'Description',
        isRequired: 'Is Required?',
        deleteCollateral: 'Delete Collateral',
        deleteConfirmation: 'Are you sure you want to delete this?',
        save: 'Save',
		delete: 'Delete',
		add: 'Add',
		edit: 'Edit',
		Yes: 'Yes',
		No: 'No',
        addNew: 'Add New',
        name_label: 'Name',
        descriptionLabel: 'Description',
        are_you_sure: 'Are you sure?',
        back: 'Back'
    };
}
