import { ResultStatus } from '@shared/models/result-status.models';

export function getColor(status: number) {
  if (status === 4) {
    return 'green';
  }
  if (status === 3) {
    return 'orange';
  }
  if (status === 2) {
    return 'red';
  }
  if (status === 1) {
    return 'grey';
  }
}

export function getColorText(status: number, min: number, max: number, margin: number): ResultStatus {
  return {
    colorId: status,
    min,
    max,
    margin,
  };
}
