import { Injectable } from '@angular/core';

@Injectable()
export class TagsTranslations {
	public config: any = {
		component: 'tags',
		display_name: 'Case Study Tags',
		description: 'List of tags for each case study'
	};

	public trans: any = {
		remove_tag: 'Remove Tag',
    save_action: 'Please press enter when entering in tags before clicking the Save button',
    save: 'Save'
	};
}
