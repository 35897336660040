<div class="col-sm-12 thresholdtab">
  <div class="pull-right m-b-xxl">
    <button class="btn btn_customizable" *ngIf="canEdit" (click)="openAddThreshold(addThresholdModal)" matTooltip="{{ trans.Thresholds.add_new | translate }}" matTooltipPosition="above">
      <i class="fa fa-plus"></i>{{ trans.Thresholds.add_new | translate }}
    </button>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <table id="searchThresholds" class="table table-responsive custom-table searchThreshold">
        <thead>
          <tr>
            <th style="border: 0px">{{ trans.Thresholds.name | translate }}</th>
            <th style="border: 0px" class="text-right">{{ trans.Thresholds.min | translate }}</th>
            <th style="border: 0px" class="text-right">{{ trans.Thresholds.max | translate }}</th>
            <th style="border: 0px" class="text-right">{{ trans.Thresholds.margin | translate }}</th>
            <th style="border: 0px"></th>
          </tr>
        </thead>
        <tbody>
          <tr [hidden]="!loadthresholdTable">
            <td colspan="5">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </td>
          </tr>

          <tr *ngIf="!loadthresholdTable && !solutionThreshold.length">
            <td colspan="5">
              {{ trans.Thresholds.no_thresholds_found | translate }}
            </td>
          </tr>
          <tr *ngFor="let threshold of solutionThreshold; let i = index" class="grid-row company-row">
            <td>{{ threshold.name }}</td>
            <td class="text-right preventWordBreak">{{ threshold.min }}</td>
            <td class="text-right preventWordBreak">{{ threshold.max }}</td>
            <td class="text-right preventWordBreak">{{ threshold.margin }}</td>
            <td>
              <div *ngIf="canEdit" class="dropdown pull-right">
                <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                  <li (click)="editAddThreshold(addThresholdModal, i)" form="ngForm">
                    <i class="icon-pencil"> {{ trans.Thresholds.edit | translate }}</i>
                  </li>
                  <li (click)="deleteThreshold(threshold.threshold_type_id)"><i class="icon-trash"></i> {{ trans.Thresholds.delete | translate }}</li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #addThresholdModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.Thresholds.manage_threshold | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="AddThresholdForm" id="ngForm" #AddThresholdForm="ngForm">
        <div class="row">
          <div class="col-md-6">
            <!-- <label id="id" [(ngModel)]="id" name="id"></label> -->
            <div class="form-group col-md-12">
              <label
                for="alterEgo"
                class="labelsRequired"
                [ngClass]="{ 'has-error': AddThresholdForm.controls.threshold_type_id?.invalid && AddThresholdForm.controls.threshold_type_id?.touched }"
                >{{ trans.Thresholds.name | translate }}</label
              >
              <select class="form-control role-dropdown" [(ngModel)]="thresholdTypeId" placeholder="Select Thresholds" name="threshold_type_id" type="text" required>
                <option disabled="disabled">{{ trans.Thresholds.choose_one | translate }}</option>
                <option *ngFor="let threshold of thresholdTypes" [value]="threshold.threshold_type_id">
                  {{ threshold.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-12" [ngClass]="{ 'has-error': AddThresholdForm.controls.min?.invalid && AddThresholdForm.controls.min?.touched }">
              <label for="name" class="labelsRequired">{{ trans.Thresholds.min | translate }}</label>
              <input type="number" class="form-control" id="min" [(ngModel)]="min" name="min" placeholder="Enter Minimum" minlength="ngModel" required />
            </div>
            <div class="form-group col-md-12" [ngClass]="{ 'has-error': AddThresholdForm.controls.max?.invalid && AddThresholdForm.controls.max?.touched }">
              <label for="max" class="labelsRequired"> {{ trans.Thresholds.max | translate }}</label>
              <input type="number" class="form-control" id="max" [(ngModel)]="max" name="max" placeholder="Enter Maximum" maxlength="ngModel" required />
              <span style="color: red" *ngIf="max < min">
                {{ trans.Thresholds.you_cannot_enter_minimum_value_greater_than_maximum_value | translate }}
              </span>
            </div>
            <div class="form-group col-md-12" [ngClass]="{ 'has-error': AddThresholdForm.controls.margin?.invalid && AddThresholdForm.controls.margin?.touched }">
              <label for="margin" class="labelsRequired">{{ trans.Thresholds.margin_percent | translate }}</label>
              <input type="number" class="form-control" id="margin" [(ngModel)]="margin" name="margin" placeholder="Enter Margin" required />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-sm-9">
                <button type="submit" class="btn btn_customizable" (click)="saveThreshold(AddThresholdForm)" [disabled]="AddThresholdForm.invalid || max < min">Save</button>
              </div>
            </div>
          </div>
          <div class="pull-right">
            <label class="labelsRequiredInfo">{{ trans.Thresholds.is_required | translate }}</label>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
