<div class="chartcontainer" *ngIf="chartData">
	<div class="row">
		<div class="col-md-6 no-p">
			<p-chart type='doughnut' [height]='height' [options]='options' [data]='chart'></p-chart>
			<div class="absolute-center text-center" [ngStyle]="{'color': selectedCLR}">
				<span class='chart_val' style='padding-bottom: 12px;'>{{selectedVal | numberTruncate}}</span><br>
				<span class='chart_text'>{{selectedName}}</span>
			</div>
		</div>
		<div class="col-md-6 no-p" style='overflow-y: auto;' [ngStyle]="{'max-height': height}">
			<div class='row' *ngFor="let elem of legend" style='margin-left: 0px; margin-right: 15px; border-bottom: solid 1px #d8e1e6; margin-bottom: 10px;'>
				<div class="col-xs-1" style='padding-left: 0px;'>
					<p class="chart-icon clickable" (click)="clickBenefitClick(elem)" [ngStyle]="{'border-color': elem.color}"></p>
				</div>
				<div class="col-xs-10" style='padding-right: 0px;'>
					<div class="val clickable" (click)="clickBenefitClick(elem)">
						{{elem.val}}%
					</div>
					<div (click)="clickBenefitClick(elem)" class="clickable label" style='width: 100%; display: block; text-align: left; white-space: pre-line;'>
						{{elem.name}} 
						<i *ngIf="elem.description && !(style2022$ | async)" [matTooltip]='elem.description' class='fa fa-info-circle'></i>
						<span *ngIf="elem.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]='elem.description'>
							info
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>