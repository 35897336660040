<p-menu #actionsListMenu appendTo="body" [popup]="true" [model]="actionsList" (onHide)="setActionMenu()" styleClass="style-2022"></p-menu>
<div class="row flex-jend" style="margin-bottom: 18px">
  <div class="col-xs-4 mr-auto">
    <div class="input-group keywest_search no-background">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input [(ngModel)]="bI.menuFilter" (keyup)="bI.funcExpand(true)" type="text" [placeholder]="trans.trans.search.value" class="form-control value-execution-add-a-new-value-proposition-results-search-benefits" data-heap="value-execution-add-a-new-value-proposition-results-search-benefits"/>
      <span *ngIf="bI.menuFilter !== ''" (click)="clearSearch()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <div class="col-xs-5" *ngIf="bI.showBatchUpdateCheckboxes">
    <div class="pull-right">
      <div class="input-group">
        <select class="form-control active-type" name="benefit_active_batch" [(ngModel)]="bI.batchActive">
          <option *ngFor="let activeType of bI.activeTypes" [value]="activeType.value">
            {{ activeType.name }}
          </option>
        </select>
        <span type="button" class="clickable input-group-addon a-link-color" (click)="bI.statusBatchUpdate(benefitsListByTypeFromFull)"><i class="fa fa-save"></i></span>
        <span type="button" class="clickable input-group-addon a-link-color" (click)="bI.clearBatchUpdate(benefitsListByTypeFromFull)"><i class="fa fa-times"></i></span>
      </div>
    </div>
  </div>
  <div class="col-xs-4 text-right">
    <button *ngIf="benefitOnFly" class="btn btn_customizable marg-h value-execution-add-a-new-value-proposition-results-add-new-button" (click)="showAddBenefit()" data-heap="value-execution-add-a-new-value-proposition-results-add-new-button">
      <span class="material-icons-outlined plus"> add </span>
      {{ trans.trans.addNew.value }}
    </button>

    <button class="btn btn_customizable value-execution-add-a-new-value-proposition-results-actions-button" (click)="actionsListMenu.toggle($event)" data-heap="value-execution-add-a-new-value-proposition-results-actions-button">{{ trans.trans.actions.value }}</button>
  </div>
</div>

<div class="row" style="margin-bottom: 24px">
  <div class="col-md-6 col-xs-12">
    <span
      class="full-badge full-badge-clear clickable"
      style="margin-left: 0px"
      (click)="bI.selectAllFilters(!bI.getAllSelected())"
      [ngClass]="{ 'full-badge-clear-selected': bI.getAllSelected() }"
      >{{ trans.trans.all.value }}</span
    >
    <ng-container *ngFor="let filtr of bI.filters">
      <span (click)="filtr.command()" [ngClass]="{ 'full-badge-clear-selected': filtr.active }" class="full-badge full-badge-clear clickable">{{ filtr.label }}</span>
    </ng-container>
  </div>
</div>
