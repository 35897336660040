<div class="sidebar_wrapper">
  <div *ngIf="editBenefitName" class="sidebar_header">
    {{ benefit.metric_name }}<i (click)="editName()" matTooltipPosition="above" class="clickable fa fa-pencil" style="margin-left: 4px"></i>
  </div>
  <div *ngIf="!editBenefitName" class="sidebar_header">
    
    <div class='input-group'  style="width: 45%;">
      <input
      type="text"
      name="benefit-name"
      id="benefit-name"
      #benefitName
      class="form-control"
      style="font-size: 16px"
      [value]="benefit.metric_name"
    />
      <div class='input-group-btn'>
        <ng-container *ngIf="!(style2022$ | async); else newButtons">
          <i (click)="overrideBenefitName(benefitName.value)" matTooltipPosition="above" class="clickable fa fa-check" style="margin-left: 4px"></i>
          <i (click)="editName()" matTooltipPosition="above" class="clickable fa fa-times" style="margin-left: 4px"></i>
        </ng-container>
        <ng-template #newButtons>
          <span (click)="editName()" matTooltipPosition="above" class="clickable input-group-addon action-button"><i class='fa fa-times'></i></span>
          <span (click)="overrideBenefitName(benefitName.value)" matTooltipPosition="above" class="clickable input-group-addon action-button"><i class='fa fa-save'></i></span>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="row" style="padding: 20px; margin: 10px; padding-left: 0px; padding-right: 0px; margin-left: 0px; padding-bottom: 0px">
    <div class="col-xs-12" style="margin-bottom: 0px; padding-bottom: 0px">
      <div class="col-xs-4">
        <div class="nav-title">Expected: {{ benefit.expected_to_date_fmt }}</div>
      </div>

      <div class="col-xs-4" style="text-align: center">
        <div class="nav-title" style="width: 100%; display: block; text-align: center">Achieved: {{ benefit.realized_to_date_fmt }}</div>
      </div>

      <div class="col-xs-4">
        <div class="nav-title" style="float: right" [ngStyle]="{ color: hasProfit ? 'green' : 'red' }">
          <i class="fa" [ngClass]="{ 'fa-arrow-up': hasProfit, 'fa-arrow-down': !hasProfit }"></i>
          {{ benefit.difference }}
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar_container">
    <div class="keywest_subpanel">
      <app-tracker-line [data]="chartData" [chartColors]="chartColors"></app-tracker-line>
    </div>

    <div style="margin: 25px 0">
      <div class="row">
        <div class="col-xs-12">
          <app-detail-card title="Description" [text]="benefit.description"></app-detail-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <app-detail-card title="Features" [text]="benefit.features"></app-detail-card>
        </div>
      </div>

      <div class="row" *ngIf="benefit.metric_note">
        <div class="col-xs-12">
          <app-detail-card title="Notes" [text]="benefit.metric_note"></app-detail-card>
        </div>
      </div>
    </div>

    <div class="keywest_subpanel">
      <div class="row">
        <div class="col-xs-12">
          <p-table
            #dt2
            dataKey="formula"
            [columns]="benefitTableColumns"
            [value]="benefitTableData"
            [resizableColumns]="true"
            [styleClass]="(style2022$ | async) ? 'keywest_table' : null"
            [tableStyleClass]="(style2022$ | async) ? 'keywest_table' : null"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngIf="!embedded" style="width: 3em"></th>
                <th
                  *ngFor="let col of columns"
                  [ngStyle]="{ 'white-space': 'nowrap', 'width': col.width ? col.width : 'inherit', 'min-width': col.minwidth ? col.minwidth : 'inherit' }"
                >
                  {{ col.header }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
              <tr [ngClass]="{ total: rowData['type'] == 'total' }">
                <td *ngIf="!embedded">
                  <a href="#" *ngIf="rowData['formula'] == 'Phasing'" [pRowToggler]="rowData">
                    <i class="fa fa-signal"></i>
                  </a>
                  <a href="#" *ngIf="!editing_scratch && rowData['hasScratchpad']" [pRowToggler]="rowData">
                    <i class="fa fa-calculator"></i>
                  </a>
                </td>
                <td *ngFor="let col of columns" [ngStyle]="{ 'text-align': col.style }">
                  <span *ngIf="(!col.editable || !rowData.editable) && col.type == 'improvement'">{{ rowData[col.field + '_fmt'] }} col</span>
                  <span *ngIf="(!col.editable || !rowData.editable) && col.type !== 'improvement'">{{ rowData[col.field] }}</span>
                  <span *ngIf="col.editable && !editing_scratch && rowData.editable">{{ rowData[col.field + '_fmt'] }}</span>
                  <i *ngIf="rowData.description && col.field == 'formula' && !(style2022$ | async)" [matTooltip]="rowData.description" class="fa fa-info-circle"></i>
                  <span *ngIf="rowData.description && col.field == 'formula' && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.description">
                    info
                  </span>

                  <div *ngIf="rowData.editing_name && col.field == 'formula'" class="input-group">
                    <input type="text" [(ngModel)]="rowData[col.field]" class="form-control" />
                    <div class='input-group-btn'>
                      <span (click)="funcSaveFactorName(rowData)" matTooltip="Save" [class]="(style2022$ | async) ? 'clickable input-group-addon action-button' : 'input-group-addon btn-success'"><i class="fa fa-save"></i></span>
                      <span (click)="rowData[col.field] = rowData.original_name; rowData.editing_name = !rowData.editing_name" matTooltip="Cancel" [class]="(style2022$ | async) ? 'clickable input-group-addon action-button' : 'input-group-addon btn-danger'">
                        <i class="fa fa-times"></i>
                      </span>
                    </div>
                  </div>
                  <i
                    (click)="rowData.original_name = rowData[col.field]; rowData.editing_name = !rowData.editing_name"
                    matTooltip="Edit Factor Name"
                    *ngIf="!rowData.editing_name && col.field == 'formula' && rowData['formula'] != 'Phasing' && rowData['formula'] != 'Total' && isModelsV2"
                    class="fa fa-pencil"
                    style="margin-left: 10px"
                  ></i>
                  <div *ngIf="col.editable && editing_scratch && rowData.editable">
                    <input type="text" *ngIf="funcShowHideInput(rowData, col)" class="form-control" (keyup)="improvementKeyPress(col, rowData)" [(ngModel)]="rowData[col.field]" />
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
              <tr>
                <td [attr.colspan]="columns.length + 1">
                  <app-benefit-phasing [valueProp]="{ id: valuePropId }" (reloadVP)="reloadVP()" [benefit]="benefit"> </app-benefit-phasing>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div class="row" *ngIf="can_edit">
        <div class="col-xs-12">
          <a (click)="funcOpenBenefitSurveyTable()" *ngIf="!editExpected" class="btn btn_customizable pull-right"><i class="fa fa-pencil"></i> {{ 'Edit' }}</a>
          <a (click)="saveBenefitSurveyData()" *ngIf="editExpected" class="btn btn_customizable pull-right"><i class="fa fa-save"></i> {{ 'Save' }}</a>
          <a (click)="funcCloseBenefitSurveyTable()" *ngIf="editExpected" class="btn btn_customizable pull-right marg-h"><i class="fa fa-times"></i> {{ 'Close' }}</a>
        </div>
      </div>

      <div class="row" *ngIf="editExpected">
        <div class="keywest_header">
          <div class="row">
            <div class="col-xs-12">
              <div class="title">Benefit Performance</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <p-table
              #dt2
              dataKey="formula"
              [columns]="benefitSurveyTableColumns"
              [value]="benefitSurveyTable"
              [resizableColumns]="true"
              [styleClass]="(style2022$ | async) ? 'keywest_table' : null"
              [tableStyleClass]="(style2022$ | async) ? 'keywest_table' : null"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngIf="!embedded" style="width: 3em"></th>
                  <th
                    *ngFor="let col of columns"
                    [ngStyle]="{ 'white-space': 'nowrap', 'width': col.width ? col.width : 'inherit', 'min-width': col.minwidth ? col.minwidth : 'inherit' }"
                  >
                    {{ col.header }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
                <tr [ngClass]="{ total: rowData['type'] == 'total' }">
                  <td></td>
                  <td *ngFor="let col of columns; let index = index" [ngStyle]="{ 'text-align': col.style, 'font-weight': rowData['type'] == 'total' ? 'bold' : 'normal' }">
                    <span *ngIf="(!col.editable || !rowData.editable) && col.type !== 'improvement'" [innerHTML]="rowData[col.field]"></span>
                    <span *ngIf="col.editable && !editExpected && rowData.editable">{{ rowData[col.field + '_fmt'] }}</span>
                    <div *ngIf="col.editable && editExpected && rowData.editable">
                      <div class="input-group" *ngIf="rowData[col.field + '_has_scratchpad']">
                        <input type="text" class="form-control" [(ngModel)]="surveys[col.index][rowData.benefitField]" />
                        <span class="input-group-addon">
                          <i
                            matTooltip="Edit Scratchpad"
                            (click)="funcGetScratchpadPadding(index, rowData)"
                            style="color: green"
                            [pRowToggler]="rowData"
                            class="fa fa-calculator"
                          ></i>
                        </span>
                      </div>
                      <input *ngIf="!rowData[col.field + '_has_scratchpad']" type="text" class="form-control" [(ngModel)]="surveys[col.index][rowData.benefitField]" />
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
                <tr>
                  <td [attr.colspan]="selectedColLabelPadding" style="text-align: right">
                    <h3>Editing Scratchpad</h3>
                  </td>
                  <td [attr.colspan]="columns.length - (selectedColLabelPadding - 1)"></td>
                </tr>
                <tr *ngFor="let item of selectedScratchpad.units">
                  <td [attr.colspan]="selectedColLabelPadding" style="text-align: right">
                    <span *ngIf="item.open_paren == 1"> ( </span>
                    {{ item.name }}
                    <i style="color: blue" *ngIf="operatorList[item.operator_id - 1]">{{ operatorList[item.operator_id - 1].sign }}</i>
                    <span *ngIf="item.close_paren == 1"> ) </span>
                  </td>
                  <td><input type="text" (keyup.enter)="calcScratchpad()" (blur)="calcScratchpad()" [(ngModel)]="item.operand" class="form-control" /></td>
                  <td
                    *ngIf="columns.length !== selectedColLabelPadding"
                    [attr.colspan]="columns.length == selectedColLabelPadding ? 0 : columns.length - selectedColLabelPadding"
                  ></td>
                </tr>
                <tr>
                  <td [attr.colspan]="selectedColLabelPadding" style="text-align: right">
                    <b>Total: </b>
                  </td>
                  <td>{{ scratchpadCalcTotal }}</td>
                  <td
                    *ngIf="columns.length !== selectedColLabelPadding"
                    [attr.colspan]="columns.length == selectedColLabelPadding ? 0 : columns.length - selectedColLabelPadding"
                  ></td>
                </tr>
                <tr>
                  <td [attr.colspan]="selectedColLabelPadding" style="text-align: right">
                    <a (click)="saveScratchpad()" class="btn btn-success"><i class="fa fa-save"></i> Save</a>
                  </td>
                  <td [attr.colspan]="columns.length - (selectedColLabelPadding - 1)"></td>
                  <td
                    *ngIf="columns.length !== selectedColLabelPadding"
                    [attr.colspan]="columns.length == selectedColLabelPadding ? 0 : columns.length - selectedColLabelPadding"
                  ></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="keywest_subpanel" style="margin-top: 20px">
      <div class="row">
        <div class="col-xs-12">
          <div class="sidebar_header">Survey Notes</div>
        </div>
      </div>

      <div style="padding: 15px" *ngIf="can_edit">
        <div class="row">
          <div class="col-xs-12">
            <label for="notes" class="sidebar_lable">Add a Note</label>
            <textarea class="form-control" [(ngModel)]="benefit['value_survey_notes ']" type="text" name="note"></textarea>
          </div>
        </div>

        <button class="btn btn_customizable pull-right" (click)="saveNote()"><i class="fa fa-plus"></i>Save Note</button>
      </div>

      <div>
        <div *ngFor="let note of benefit.notes">
          <app-detail-card [title]="note.date" [text]="note.note"></app-detail-card>
        </div>
      </div>
    </div>

    <div class="keywest_subpanel" *ngIf="!hasProfit">
      <div class="row">
        <div class="col-xs-12">
          <div class="sidebar_header">Course Correction</div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12"></div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          {{ benefit.course_correction }}
        </div>
      </div>
    </div>
  </div>
</div>
