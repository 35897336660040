import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { ScbRoutingModule } from './scb-routing.module';
import { ScbComponent } from './scb/scb.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyComponent } from './company/company.component';
import { CompanySearchComponent } from './company-search/company-search.component';
import { ScbService } from '@data/services/scb/scb.service';

import { AddCompanyModule } from './add-company/add-company.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    MenuModule,
    TableModule,
    SharedModule,
    ScbRoutingModule,
    AddCompanyModule,
  ],
  declarations: [ScbComponent, CompaniesComponent, CompanyComponent, CompanySearchComponent],
  exports: [CompanySearchComponent],
  providers: [ScbService, Translations],
})
export class ScbModule {}
