<button type="button" *ngIf="canEdit" class="btn btn-primary-outline pull-right" (click)="openAddProject()">
  <i class="fa fa-plus"></i>{{ trans.projects.add_new | translate }}
</button>

<div>
  <div class="table-responsive col-sm-12">
    <div class="searchTableContainer" *ngIf="projects.length > 0">
      <table class="table custom-table">
        <thead>
          <tr>
            <th>{{ trans.projects.name | translate }}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr class="grid-row company-row" *ngFor="let project of projects">
            <td>
              <span *ngIf="project.parent_name">{{ project.parent_name }} - </span>
              {{ project.name }} <i class="icon-info tooltip-custom" [matTooltip]="project.description" matTooltipPosition="right"></i>
            </td>
            <td>
              <span class="on" *ngIf="+project.active === 1">{{ trans.projects.add_to_list | translate }}</span>
            </td>
            <td>
              <div class="dropdown pull-right">
                <span *ngIf="canEdit" class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                  <li (click)="updateProject(project, 1)"><i class="icon-plus"></i> {{ trans.projects.add_to_list_by_default | translate }}</li>
                  <li (click)="updateProject(project, 0)"><i class="fa fa-minus"></i>{{ trans.projects.remove_from_list | translate }}</li>
                  <li (click)="deleteProject(project)"><i class="icon-trash"></i> {{ trans.projects.delete | translate }}</li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="projects.length == 0" class="m-t-md col-sm-10 comp_no_data">
      <div class="searchTableContainer text-center">
        <h4>{{ trans.projects.no_projects_found | translate }}</h4>
      </div>
    </div>
  </div>
</div>
<!-- value category table -->
