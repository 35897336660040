<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>Maturity Steps</div>
				</div>
				<div class='col-xs-2'>
					<a (click)='postData()' class='btn btn_customizable pull-right'>Update</a>
				</div>
			</div>
		</div>

		<div class='row' style='margin-bottom: 20px;'>
			<div class="col-xs-4">
				<p-dropdown appendTo="body" [options]="models" [(ngModel)]="selectedModel" class='keywest_dropdown'></p-dropdown>
			</div>
		</div>
	</div>
</div>