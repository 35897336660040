
import { Component, ViewChild, OnDestroy,OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RSituationTranslations } from './r_situations.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-r-detail-situation',
	templateUrl: './r_situations.component.html',
	styleUrls: ['./r_situations.component.scss']
})

export class RSituationComponent implements OnInit, OnDestroy {

	@Input() solutionId: string;
	@Input() situationView: any;

	@ViewChild('situationForm') situationForm: NgForm;

	ngUnsubscribe = new Subject();

	customcheckboxlabel: string;
	situationTypes: any[];
	situation_type_id
	accountSituationType: Array<object> = [];
	situationElementTypes: any[];
	situationElement: any;
	situationElements: any[];
	situationTypeElements: any[];
	situationBenefits: any[];
	situationCosts: any[];
	situations: any[];
	costs: any[];
	cost: any;
	benefits: any;
	caseStudies: any;
	assets: any[] = [];
	collateral: any[] = [];
	benefit: any;
	projectList
	// dropdown
	situationTypeElementsLoader = false;
	accrualTypes: any[];
	scaleTypes: any[];
	stack_order: any = 0;
	showTranslate = false;
	// Loader Images
	fullImagePath: string;

	// Contextual Help
	contextualHelp: object = {};

	// Nested Situations
	// toggles
	elementChildren1Toggle: boolean;
	elementChildren2Toggle: boolean;
	elementChildren3Toggle: boolean;
	elementChildren4Toggle: boolean;
	elementChildren5Toggle: boolean;

	elementChildren1 = [];
	elementChildren2 = [];
	elementChildren3 = [];
	elementChildren4 = [];
	elementChildren5 = [];

	parent1Name: string;
	parent2Name: string;
	parent3Name: string;
	parent4Name: string;

	situationTypeChildElements = [];

	masterValuePropFeature: boolean;
	projectsList: any = [];
	selectedProject: object;

	tree: any[] = [];


	situationTypeSelected: any;
	situationTypeElement: any;
	benefitsCols = [
		{field: 'metric_name', header: 'Name'}
	];

	costsCols = [
		{field: 'name', header: 'Name'}
	];

	caseStudiesCols = [
		{field: 'name', header: 'Name'}
	];

	assetsCols = [
		{field: 'name', header: 'Name'}
	];

	collateralCols = [
		{field: 'name', header: 'Name'}
	];

	projectCols = [
		{field: 'name', header: 'Name'}
	];

	situationItemsMenu = [];
	situationsItemsMenuView = 'benefits';

	strSearchBenefits = '';
	strSearchCosts = '';
	strSearchCaseStudies = '';
	strSearchAssets = '';
	strSearchCollateral = '';

	toggleBenefits = false;
	toggleCosts = false;
	toggleCaseStudies = false;
	toggleAssets = false;
	toggleCollateral = false;
	toggleProjects = false;
	style2022$: Observable<boolean>;

	constructor(
		private solutionService: SolutionService,
		private valuepropService: ValuepropService,
		private commonService: CommonService,
		private factsService: FactsService,
		public trans: RSituationTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {

		const image_url = this.commonService.getImageUrl();
		this.fullImagePath = image_url + '/images/jamaica/reload.gif';
		// this.translateService.setDefaultLang('defaultLang');
		// this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.getSituationTypes();
		this.assignSituationTypeId();

		this.situationView.defaultActive = this.situationView.default_active === 1;

		this.benefits = this.situationView.benefits;
		this.costs = this.situationView.costs;
		this.caseStudies = this.situationView.case_studies;
		this.assets = this.situationView.assets;
		this.collateral = this.situationView.collateral;

		this.markSelectedBenefits(this.situationView);
		this.markSelectedCosts(this.situationView);
		this.markSelectedCaseStudies(this.situationView);
		this.markSelectedCollateral(this.situationView);



		this.masterValuePropFeature = false;
		if (this.commonService.checkFeature('15')) {
			this.masterValuePropFeature = true;
			this.projectList = this.situationView.projects;

			this.markSelectedProjects(this.situationView);
		}


		// end init
		this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			this.contextualHelp = response['Model'];
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});


	}


	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}



	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}


	getSituationTypes() {
		this.solutionService.getSituationTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.situationTypes = result.result;
			this.situationTypeSelected = this.situationTypes.find((type) => type.id === this.situationView.situation_type_id);
		});
	}

	getAccountSituationTypes() {
		this.accountSituationType = [];
		this.solutionService.getAccountSituationTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			if (result.result) {
				this.accountSituationType = result.result;
			}
		});
	}

	getElementTypes() {
		this.factsService.getSituationElementTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.situationElementTypes = result.result;
		});
	}

	assignSituationTypeId() {
		this.situation_type_id = this.situationView.situation_type_id;
		this.getSituationTypeElements(this.situation_type_id);
	}

	getSituationTree() {
		this.solutionService.getSituationsBySolutionTreeV3(this.situation_type_id, this.solutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.tree = res.result.data;
		})
	}


	getSolutionCosts() {
		this.solutionService.getSolutionCosts(this.solutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
			this.costs = result.result;
		});
	}

	getSolutionBenefits() {
		this.solutionService.readSolutionBenefits(this.solutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
			this.benefits = result.result.solution_metrics;

		});
	}


	getSituationTypeElements(id) {
		this.situationView.situation_type_id = id;
		this.situationTypeElementsLoader = true;
		this.situationTypeElements = [];
		// this.resetChildren();
		this.solutionService.getSituationTypeElements(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			this.situationTypeElementsLoader = false;
			if (response.result['message'] && response.result['message']['available_elements']) {
				this.situationTypeElements = response.result['message']['available_elements'];
				this.situationTypeElement = this.situationTypeElements.find((elementType) => elementType.id === this.situationView.situation_element_id );
			} else {
				if (id == 3 && response.result['message']) { // for GEO elements
					delete response.result['message']['available_elements'];
					let responseGeo = response.result['message'];
					this.situationTypeElements = Array.from(Object.keys(responseGeo), k => responseGeo[k]);
					this.situationTypeElement = this.situationTypeElements.find((elementType) => elementType.id === this.situationView.situation_element_id );
				}

			}
		});
	}


	markSelectedBenefits(situation) {
		const benefitsActivated = [];
		if (situation['benefits']) {
			for (let i = 0; i < situation['benefits'].length; i++) {
				benefitsActivated.push(situation['benefits'][i]['account_solution_metric_id']);
			}
		}

		if (this.benefits) {
			this.benefits = this.benefits.map(benefit => {
				const index = benefitsActivated.indexOf(benefit['id']);
				if (index !== -1) {
					const situationBenefit = situation['benefits'][index];
					benefit['changeToggle'] = true;
					benefit['changedActive'] = situationBenefit['active'];

					if (situationBenefit['driver_baseline_answer'] != null ||
						situationBenefit['financial_baseline_answer'] != null ||
						situationBenefit['conservative'] != null ||
						situationBenefit['probable'] != null ||
						situationBenefit['aggressive'] != null) {
						benefit['valueChangeToggle'] = true;
					}
				}

				return benefit;
			});
		}
	}

	markSelectedCosts(situation) {
		const costsAdded = [];
		if (situation['costs']) {
			for (let i = 0; i < situation['costs'].length; i++) {
				costsAdded.push(situation['costs'][i]['account_solution_cost_id']);
			}
		}

		if (this.costs) {
			this.costs = this.costs.map(cost => {
				const index = costsAdded.indexOf(cost['id']);
				if (index !== -1) {
					cost['changeToggleAvailable'] = situation['costs'][index]['available'];
					cost['changeToggleAutoload'] = situation['costs'][index]['active'];
				}
				return cost;
			});
		}

	}

	markSelectedCaseStudies(situation) {
		const caseStudiesActivated = [];
		if (situation['case_studies']) {
			for (let i = 0; i < situation['case_studies'].length; i++) {
				caseStudiesActivated.push(situation['case_studies'][i]['account_case_study_id']);
			}
		}

		if (this.caseStudies) {
			this.caseStudies = this.caseStudies.map(caseStudy => {
				const index = caseStudiesActivated.indexOf(caseStudy['id']);
				if (index !== -1) {
					const situationCaseStudy = situation['case_studies'][index];
					caseStudy['changedActive'] = situationCaseStudy['active'];
				}

				return caseStudy;
			});
		}
	}

	markSelectedAssets(situation) {
		const assetsActivated = [];
		if (situation['assets']) {
			for (let i = 0; i < situation['assets'].length; i++) {
				assetsActivated.push(situation['assets'][i]['account_asset_template_id']);
			}
		}

		if (this.assets) {
			this.assets = this.assets.map(asset => {
				const index = assetsActivated.indexOf(asset['id']);
				if (index !== -1) {
					const situationAsset = situation['assets'][index];
					asset['changedActive'] = situationAsset['active'];
				}

				return asset;
			});
		}
	}

	markSelectedCollateral(situation) {
		const collateralActivated = [];
		if (situation['collaterals']) {
			for (let i = 0; i < situation['collaterals'].length; i++) {
				collateralActivated.push(situation['collaterals'][i]['account_collateral_id']);
			}
		}

		if (this.collateral) {
			this.collateral = this.collateral.map(collateral => {
				const index = collateralActivated.indexOf(collateral['id']);
				if (index !== -1) {
					const situationCollateral = situation['collaterals'][index];
					collateral['changedActive'] = situationCollateral['active'];
				}

				return collateral;
			});
		}
	}

	markSelectedProjects(situation) {
		const projectsActivated = [];
		if (situation['projects']) {
			for (let i = 0; i < situation['projects'].length; i++) {
				projectsActivated.push(situation['projects'][i]['account_project_type_id']);
			}
		}

		if (this.projectsList) {
			this.projectsList = this.projectsList.map(project => {
				const index = projectsActivated.indexOf(project['account_project_type_id']);
				if (index !== -1) {
					const situationProjects = situation['projects'][index];
					project['changedActive'] = situationProjects['active'];
					project['changedRecommend'] = situationProjects['recommend'];
				}

				return project;
			});
		}
	}


	getAssets() {
		this.valuepropService.readValuePropAssetTemplatesWithoutId().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

			this.assets = result.result.map((asst) => ({
				...asst,
				icon: this.getFAIcon(asst.format)
			}));
		});
	}


	getCollateral() {
		this.solutionService.getCollateralList(this.solutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

			this.collateral = result.result;
		});
	}



	getProjects() {
		this.solutionService.getProjects(this.solutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			this.projectsList = [];
			if (response.result) {
				this.projectsList = response.result;

			}
		});
	}

	getFAIcon(format) {
		switch (format) {
			case "MS Word":
				return "fa fa-file-word-o"

			case "MS Excel":
				return "fa fa-file-excel-o"

			case "MS PowerPoint":
				return "fa fa-file-powerpoint-o"

			case "MS PDF":
				return "fa fa-file-pdf-o"

			default:
				return "fa fa-cloud-download"

		}
	}

	getFAColor(format) {
		switch (format) {
			case "MS Word":
				return { "color": "#2e5b97", "margin-right": "5px" };

			case "MS Excel":
				return { "color": "#3a6e46", "margin-right": "5px" };

			case "MS PowerPoint":
				return { "color": "#c55737", "margin-right": "5px" };

			case "MS PDF":
				return { "color": "#c60a09", "margin-right": "5px" };

			default:
				return { "color": "black", "margin-right": "5px" }
		}
	}

}

