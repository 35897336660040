<div class="row">
	<div class='col-xs-12'>
		<div (click)="goToMasterValueProp()" class='clickable'>
			<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
			<span *ngIf="VPGroup">Compare Value Prop Group: {{masterValueProp?.name}}</span>
			<span *ngIf="!VPGroup">Back to: {{masterValueProp.name}}</span>
		</div>
	</div>
</div>

<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>
						{{ 'compare.master_valueprop_for' | translate }} {{masterValueProp?.name}}
						<i *ngIf="!changeNameMVP" (click)="changeNameMVP=true" style='margin-left: 10px;' class="fa fa-pencil clickable"></i>

						<div class='input-group'>
							<input class="form-control" *ngIf="changeNameMVP" [(ngModel)]="masterValueProp.name" />
							<div class='input-group-btn'>

								<a *ngIf="changeNameMVP" (click)="changeMasterValuePropName()" class='btn btn-success'><i class='fa fa-save'></i></a>
								<a *ngIf="changeNameMVP" (click)="cancelMasterValuePropName()" class='btn btn-danger'><i class='fa fa-times'></i></a>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>


		<div class="row">
			<div class="col-md-6">

				<div class='models_panel'>
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-12'>
									<div class='title'>
										{{ 'compare.totals' | translate }}
									</div>
								</div>
							</div>
						</div>


						<div *ngIf="compareKeyFigures" class="row">
							<div class="col-xs-6">
								<div class="key-features">

									<div class="key-features-list">
										<div class="key-features-label">
											{{ 'props.tabDetails.keyFigures.totalBenefits' | translate }}</div>
										<div class="key-features-value">{{compareKeyFigures.total_benefits_fmt}}</div>
									</div>
									<div class="key-features-list">
										<div class="key-features-label">
											{{ 'props.tabDetails.keyFigures.totalCosts' | translate }}</div>
										<div class="key-features-value">{{compareKeyFigures.total_costs_fmt}}</div>
									</div>
									<div class="key-features-list">
										<div class="key-features-label">{{ 'props.tabDetails.keyFigures.ROI' | translate }}
										</div>
										<div class="key-features-value">{{compareKeyFigures.roi}}</div>
									</div>
									<div class="key-features-list">
										<div class="key-features-label">{{ 'props.tabDetails.keyFigures.NPV' | translate }}
										</div>
										<div class="key-features-value">{{compareKeyFigures.npv}}</div>
									</div>
								</div>
							</div>
							<div class="col-xs-6">
								<div class="key-features">
									<div class="key-features-list">
										<div class="key-features-label">
											{{ 'props.tabDetails.keyFigures.paybackPeriod' | translate }} hii</div>
										<div class="key-features-value">{{compareKeyFigures.payback}}</div>
									</div>
									<div class="key-features-list">
										<div class="key-features-label">{{ 'props.tabDetails.keyFigures.tco' | translate }}
										</div>
										<div class="key-features-value">{{compareKeyFigures.tco}}</div>
									</div>
									<div class="key-features-list">
										<div class="key-features-label">{{ 'props.tabDetails.keyFigures.mi' | translate }}
										</div>
										<div class="key-features-value">{{compareKeyFigures.cost_of_delay}}</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="compareLoader" class="col-12 text-center m-t-lg m-b-lg">
							<img class="actionLoader" src="{{fullImagePath}}" />
						</div>
					</div>

				</div>
			</div>

			<div class="col-md-6">
				<div class='models_panel'>
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-12'>
									<div class='title'>
										{{ 'props.tabs.assets' | translate }}
										<i class="fa fa-info-circle" *ngIf="contextualHelp && contextualHelp['Position 2'] && !(style2022$ | async)" [matTooltip]="trans.contextual_help.vp_dashboard.assets | translate" matTooltipPosition="right"></i>
										<span *ngIf="contextualHelp && contextualHelp['Position 2'] && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.contextual_help.vp_dashboard.assets | translate" matTooltipPosition="right">
                      info
                    </span>
									</div>
								</div>

							</div>
						</div>

						<div class='row' style='margin-bottom: 10px;' *ngFor="let template of masterValuePropTemplates">
							<div class='col-xs-12'>
								<i style='margin-right: 10px;' [ngClass]="getFAIcon(template.format)" [ngStyle]="getFAColor(template.format)"></i>
								<a *ngIf="!VPGroup" href="{{assetUrl}}/assets/{{assetGenerator}}?account_asset_template_id={{template.account_asset_template_id}}&user_id={{userId}}&token={{token}}&master_value_prop_id={{masterValuePropId}}&compare_ids={{compareIds}}" target="_blank" [matTooltip]="template.description" matTooltipPosition="right">{{template.artifact_name}}</a>
								<a *ngIf="VPGroup" href="{{assetUrl}}/assets/{{assetGenerator}}?account_asset_template_id={{template.account_asset_template_id}}&user_id={{userId}}&token={{token}}&value_prop_group_id={{masterValuePropId}}&compare_ids={{compareIds}}" target="_blank" [matTooltip]="template.description" matTooltipPosition="right">{{template.artifact_name}}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" style='margin-top: 20px;'>
			<div class='col-xs-12'>
				<div class='models_panel'>
					<div class='models_page_header_container_small'>
						<div class='keywest_header' style='margin-bottom: 0px; padding-bottom: 0px;'>
							<div class='row'>
								<div class='col-xs-12'>
									<ul class="nav nav-tabs">
										<li class="nav-item" (click)="moveTo('KeyFigure')">
											<a class="nav-link" [ngClass]="{'active' : (currentTab == 'KeyFigure')}">{{ 'compare.key_figures' | translate}}</a>
										</li>
										<li class="nav-item" (click)="moveTo('Benefits')">
											<a class="nav-link" [ngClass]="{'active' : (currentTab == 'Benefits')}">{{ 'compare.benefits' | translate}}</a>
										</li>
										<li class="nav-item" (click)="moveTo('Costs')">
											<a class="nav-link" [ngClass]="{'active' : (currentTab == 'Costs')}">{{ 'compare.costs' | translate}}</a>
										</li>
										<li class="nav-item" (click)="moveTo('Assumptions')">
											<a class="nav-link" [ngClass]="{'active' : (currentTab == 'Assumptions')}">{{ 'compare.assumptions' | translate}}</a>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class='row'>
							<div class='col-xs-12'>

								<div id="compareSections">
									<div class='compareLoader' *ngIf="compareLoader">
										<div>
											<div class="fa fa-spinner fa-spin"></div>

											<!-- <img class="actionLoader" src="{{fullImagePath}}" /> -->
										</div>
									</div>
									<section *ngIf="currentTab == 'KeyFigure'" id="KeyFigure">
										<div class="sectionHeader clearfix">
											<h5 class="pull-left">{{ 'compare.key_figures' | translate}}</h5>
											<i class="fa fa-plus pull-right m-t-sm" role="button" (click)="isCollapsedKeyFigure = !isCollapsedKeyFigure" [attr.aria-expanded]="!isCollapsedKeyFigure" [ngClass]="isCollapsedKeyFigure ? 'fa-plus' : 'fa-minus'"></i>
										</div>
										<div class="table-responsive" [ngbCollapse]="isCollapsedKeyFigure">
											<table class="table table-bordered">
												<thead>
													<tr>
														<th style='width: 20px; max-width: 20px;'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand All" *ngIf="eKeyFigures" (click)='funceKeyFigures(false)'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse All" *ngIf="!eKeyFigures" (click)='funceKeyFigures(true)'></i> -->
														</th>
														<th style='width: 200px; max-width: 200px;'>&nbsp;</th>
														<th [ngClass]='{"col": valueProp.expand}' *ngFor="let valueProp of valueProps">
															<span class="clickable linkish" (click)="goToValuePropDash(valueProp.id)">{{valueProp.name}}</span>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{valueProp.name}}" *ngIf="valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{valueProp.name}}" *ngIf="!valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
														</th>
													</tr>
												</thead>
												<tbody>
													<tr [ngClass]='{"expanded" : eTotalBenefits}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ 'props.tabDetails.keyFigures.totalBenefits' | translate }}" *ngIf="eTotalBenefits" (click)='eTotalBenefits = !eTotalBenefits'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ 'props.tabDetails.keyFigures.totalBenefits' | translate }}" *ngIf="!eTotalBenefits" (click)='eTotalBenefits = !eTotalBenefits'></i> -->
														</td>
														<td class='cell'>
															<strong>{{ 'props.tabDetails.keyFigures.totalBenefits' | translate }}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let benefits of keyFigures[0]?.values; let i = index'>
															<span>{{benefits}}</span>
														</td>
													</tr>
													<tr [ngClass]='{"expanded" : etotalCosts}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ 'props.tabDetails.keyFigures.totalCosts' | translate }}" *ngIf="etotalCosts" (click)='etotalCosts = !etotalCosts'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ 'props.tabDetails.keyFigures.totalCosts' | translate }}" *ngIf="!etotalCosts" (click)='etotalCosts = !etotalCosts'></i> -->
														</td>
														<td class='cell'>
															<strong>{{ 'props.tabDetails.keyFigures.totalCosts' | translate }}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let costs of keyFigures[1]?.values; let i = index'>
															<span>{{costs}}</span>
														</td>
													</tr>
													<tr [ngClass]='{"expanded" : eROI}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ 'props.tabDetails.keyFigures.ROI' | translate }}" *ngIf="eROI" (click)='eROI = !eROI'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ 'props.tabDetails.keyFigures.ROI' | translate }}" *ngIf="!eROI" (click)='eROI = !eROI'></i> -->
														</td>
														<td class='cell'>
															<strong>{{ 'props.tabDetails.keyFigures.ROI' | translate }}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let roi of keyFigures[2]?.values; let i = index'>
															<span>{{roi}}</span>
														</td>
													</tr>
													<tr [ngClass]='{"expanded" : epaybackPeriod}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ 'props.tabDetails.keyFigures.paybackPeriod' | translate }}" *ngIf="epaybackPeriod" (click)='epaybackPeriod = !epaybackPeriod'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ 'props.tabDetails.keyFigures.paybackPeriod' | translate }}" *ngIf="!epaybackPeriod" (click)='epaybackPeriod = !epaybackPeriod'></i> -->
														</td>
														<td class='cell'>
															<strong>{{ 'props.tabDetails.keyFigures.paybackPeriod' | translate }}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let paybackPeriod of keyFigures[3]?.values; let i = index'>
															<span>{{paybackPeriod}}</span>
														</td>
													</tr>
													<tr [ngClass]='{"expanded" : eNPV}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ 'props.tabDetails.keyFigures.NPV' | translate }}" *ngIf="eNPV" (click)='eNPV = !eNPV'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ 'props.tabDetails.keyFigures.NPV' | translate }}" *ngIf="!eNPV" (click)='eNPV = !eNPV'></i> -->
														</td>
														<td class='cell'>
															<strong>{{ 'props.tabDetails.keyFigures.NPV' | translate }}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let npv of keyFigures[4]?.values; let i = index'>
															<span>{{npv}}</span>
														</td>
													</tr>
													<tr [ngClass]='{"expanded" : etco}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ 'props.tabDetails.keyFigures.tco' | translate }}" *ngIf="etco" (click)='etco = !etco'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ 'props.tabDetails.keyFigures.tco' | translate }}" *ngIf="!etco" (click)='etco = !etco'></i> -->
														</td>
														<td class='cell'>
															<strong>{{ 'props.tabDetails.keyFigures.tco' | translate }}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let tco of keyFigures[5]?.values; let i = index'>
															<span>{{tco}}</span>
														</td>
													</tr>
													<tr [ngClass]='{"expanded" : e3mi}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ 'props.tabDetails.keyFigures.mi' | translate }}" *ngIf="e3mi" (click)='e3mi = !e3mi'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ 'props.tabDetails.keyFigures.mi' | translate }}" *ngIf="!e3mi" (click)='e3mi = !e3mi'></i> -->
														</td>
														<td class='cell'>
															<strong>{{ 'props.tabDetails.keyFigures.mi' | translate }}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let costDelay of keyFigures[6]?.values; let i = index'>
															<span>{{costDelay}}</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</section>
									<section *ngIf="currentTab == 'Benefits'" id="Benefits">
										<div class="sectionHeader clearfix">
											<h5 class="pull-left">{{ 'compare.benefits' | translate }}</h5>
											<i class="fa fa-plus pull-right m-t-sm" role="button" (click)="isCollapsedBenefits = !isCollapsedBenefits" [attr.aria-expanded]="!isCollapsedBenefits" [ngClass]="isCollapsedBenefits ? 'fa-plus' : 'fa-minus'"></i>
										</div>
										<div class="table-responsive" [ngbCollapse]="isCollapsedBenefits">
											<table class="table table-bordered">
												<thead>
													<tr>
														<th style='width: 20px; max-width: 20px;'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand All" *ngIf="eBenefits" (click)='funcEBenefits(false)'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse All" *ngIf="!eBenefits" (click)='funcEBenefits(true)'></i> -->
														</th>
														<th width="200px">&nbsp;</th>
														<th [ngClass]='{"col": valueProp.expand}' *ngFor="let valueProp of valueProps">
															<span class="clickable linkish" (click)="goToValuePropDash(valueProp.id)">{{valueProp.name}}</span>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{valueProp.name}}" *ngIf="valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{valueProp.name}}" *ngIf="!valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
														</th>
													</tr>
												</thead>
												<tbody>
													<tr [ngClass]='{"expanded" : benefit.expand}' *ngFor='let benefit of benefits'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ benefit.name | translate }}" *ngIf="benefit.expand" (click)='benefit.expand = !benefit.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ benefit.name | translate }}" *ngIf="!benefit.expand" (click)='benefit.expand = !benefit.expand'></i> -->
														</td>
														<td class='cell'>
															<strong>{{benefit.name}}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let value of benefit.values; let i = index'>
															<span>{{value}}</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</section>
									<section *ngIf="currentTab == 'Costs'" id="Costs">
										<div class="sectionHeader clearfix">
											<h5 class="pull-left">{{ 'compare.costs' | translate }}</h5>
											<i class="fa fa-plus pull-right m-t-sm" role="button" (click)="isCollapsedCosts = !isCollapsedCosts" [attr.aria-expanded]="!isCollapsedCosts" [ngClass]="isCollapsedCosts ? 'fa-plus' : 'fa-minus'"></i>
										</div>
										<div class="table-responsive" [ngbCollapse]="isCollapsedCosts">
											<table class="table table-bordered">
												<thead>
													<tr>
														<th style='width: 20px; max-width: 20px;'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand All" *ngIf="eCosts" (click)='funceCosts(false)'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse All" *ngIf="!eCosts" (click)='funceCosts(true)'></i> -->
														</th>
														<th width="200px">&nbsp;</th>
														<th [ngClass]='{"col": valueProp.expand}' *ngFor="let valueProp of valueProps">
															<!-- {{valueProp | json}} -->
															<span class="clickable linkish" (click)="goToValuePropDash(valueProp.id)">{{valueProp.name}}</span>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{valueProp.name}}" *ngIf="valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{valueProp.name}}" *ngIf="!valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
															<span (click)="addCost(valueProp.id)" matTooltip="Add Cost" matTooltipPosition="right" class="m-l-md">
																<i class="icon-plus" role="button"></i>
															</span>
														</th>
													</tr>
												</thead>
												<tbody>
													<tr [ngClass]='{"expanded" : cost.expand}' *ngFor='let cost of costs'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ cost.name | translate }}" *ngIf="cost.expand" (click)='cost.expand = !cost.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ cost.name | translate }}" *ngIf="!cost.expand" (click)='cost.expand = !cost.expand'></i> -->
														</td>
														<td class='cell'>
															<strong>{{cost.name}}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let value of cost.values; let i = index;'>
															<ng-container *ngIf="cost.cost_id[i] != 0">
																<span>{{value}}</span>
																<span (click)="editCost(cost.cost_id[i], valueProps[i]['id'])" matTooltip="Edit Cost" matTooltipPosition="right" class="m-l-md">
																	<i class="icon-pencil" role="button"></i>
																</span>
																<span (click)="openDeleteCost(deleteCostModal, cost.cost_id[i], valueProps[i]['id'])" matTooltip="Delete Cost" matTooltipPosition="right" class="m-l-xxs">
																	<i class="icon-trash" role="button"></i>
																</span>
															</ng-container>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</section>
									<section *ngIf="currentTab == 'Assumptions'" id="Assumptions">
										<div class="sectionHeader clearfix">
											<h5 class="pull-left" style='margin-right: 10px;'>
												Assumptions</h5>
											<button class="btn btn-success btn-sm" *ngIf="!assumptionsEditing" (click)="assumptionsEditing = !assumptionsEditing"><i class='fa fa-pencil'></i>
												{{ 'compare.edit_all_values' | translate }}</button>
											<button class="btn btn-danger btn-sm" *ngIf="assumptionsEditing" (click)="assumptionsCancelEdit()">{{ 'compare.cancel_changes' | translate }}</button>
											<button class="btn btn-success btn-sm" *ngIf="isAssumptionUpdated" (disable)='!assumptionsEditing' (click)="compareBulkUpdate()">{{ 'compare.save_changes' | translate }}</button>
											<img *ngIf="assumptionSaveLoader && isAssumptionUpdated" class="actionLoader" src="{{fullImagePath}}" />
											<i class="fa fa-plus pull-right m-t-sm" role="button" (click)="isCollapsedAssumptions = !isCollapsedAssumptions" [attr.aria-expanded]="!isCollapsedAssumptions" [ngClass]="isCollapsedAssumptions ? 'fa-plus' : 'fa-minus'"></i>
										</div>
										<div class="table-responsive" [ngbCollapse]="isCollapsedAssumptions" style='padding-bottom: 0px;'>
											<table class="table table-bordered">
												<thead>
													<tr>
														<th style='width: 20px; max-width: 20px;'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand All" *ngIf="eAssumption" (click)='funceAssumption(false)'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse All" *ngIf="!eAssumption" (click)='funceAssumption(true)'></i> -->
														</th>
														<th width="200px">&nbsp;</th>
														<th [ngClass]='{"col": valueProp.expand}' *ngFor="let valueProp of valueProps">
															<span class="clickable linkish" (click)="goToValuePropDash(valueProp.id)">{{valueProp.name}}</span>

															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{valueProp.name}}" *ngIf="valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{valueProp.name}}" *ngIf="!valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
														</th>
													</tr>
												</thead>
												<tbody>
													<tr [ngClass]='{"expanded" : assumption.expand}' *ngFor='let assumption of assumptions; let assumptionIndex = index'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ assumption.name | translate }}" *ngIf="assumption.expand" (click)='assumption.expand = !assumption.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ assumption.name | translate }}" *ngIf="!assumption.expand" (click)='assumption.expand = !assumption.expand'></i> -->
														</td>
														<td class="cell boldTitle">
															<strong>{{assumption.name}}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let values of assumption.values; let i = index;'>
															<span *ngIf="!assumptionsEditing" class="editable" (click)="openModal(assumptionsModal, values, assumption, assumptionIndex, i)">
																{{values.value_fmt}}
															</span>
															<div *ngIf="assumptionsEditing" class='input-group' style='margin-bottom: 10px; display: block;'>
																<!-- <input class="form-control"
														(keydown)='onKeyUp($event, values, assumption, assumptionIndex, i); isAssumptionUpdated = true;'
														[(ngModel)]="values.value" style='max-width: 100px;' /> -->

																<input class="form-control" (keydown)='values.updated = true; isAssumptionUpdated = true;' [(ngModel)]="values.value" style='max-width: 100px;' />
															</div>
															<span *ngIf="showScratchPad(values)" (click)="showScratchPadModal(values, assumption)" class="m-l-md">
																<i class="icon-calculator" role="button"></i>
															</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>







										<div class="sectionHeader clearfix">
											<h5 class="pull-left" style='margin-right: 10px;'>{{ trans.vp_dashboard.improvements | translate }}</h5>
											<!-- <button class="btn btn-primary btn-sm" *ngIf="!improvementsEditing" (click)="improvementsEditing = !improvementsEditing">{{ 'compare.edit_all_values' | translate }}</button> -->
											<!-- <button class="btn btn-danger btn-sm" *ngIf="improvementsEditing" (click)="assumptionsCancelEdit()">{{ 'compare.cancel_changes' | translate }}</button> -->
											<!-- <button class="btn btn-success btn-sm" *ngIf="isImprovementUpdated" (disable)='!improvementsEditing' (click)="compareBulkUpdateImprovements()">{{ 'compare.save_changes' | translate }}</button> -->
											<img *ngIf="improvementSaveLoader && isImprovementUpdated" class="actionLoader" src="{{fullImagePath}}" />
											<!-- <i class="fa fa-plus pull-right m-t-sm" role="button" (click)="isCollapsedImprovements = !isCollapsedImprovements" [attr.aria-expanded]="!isCollapsedAssumptions" [ngClass]="isCollapsedAssumptions ? 'fa-plus' : 'fa-minus'"></i> -->
										</div>
										<div class="table-responsive" [ngbCollapse]="isCollapsedImprovements" style='padding-bottom: 0px;'>
											<table class="table table-bordered">
												<thead>
													<tr>
														<th style='width: 20px; max-width: 20px;'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand All" *ngIf="eImprovements" (click)='funceImprovements(false)'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse All" *ngIf="!eImprovements" (click)='funceImprovements(true)'></i> -->
														</th>
														<th width="200px">&nbsp;</th>
														<th [ngClass]='{"col": valueProp.expand}' *ngFor="let valueProp of valueProps">
															<span class="clickable linkish" (click)="goToValuePropDash(valueProp.id)">{{valueProp.name}}</span>

															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{valueProp.name}}" *ngIf="valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{valueProp.name}}" *ngIf="!valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
														</th>
													</tr>
												</thead>
												<tbody>
													<tr [ngClass]='{"expanded" : improvement.expand}' *ngFor='let improvement of improvements; let improvementIndex = index'>
														<td class='toggle'>
															<!-- {{improvement.expand ? "true" : "false"}} -->
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ improvement.name | translate }}" *ngIf="improvement.expand" (click)='improvement.expand = !improvement.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ improvement.name | translate }}" *ngIf="!improvement.expand" (click)='improvement.expand = !improvement.expand'></i> -->
														</td>
														<td class="cell boldTitle">
															<strong>{{improvement.name}}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let values of improvement.values; let i = index;'>
															<span *ngIf="!improvementsEditing" class="editable" (click)="openModal(assumptionsModal, values, improvement, improvementIndex, i)">
																{{values.impact_fmt}}
															</span>
															<div *ngIf="improvementsEditing" class='input-group' style='margin-bottom: 10px; display: block;'>
																<!-- <input class="form-control"
														(keydown)='onKeyUp($event, values, improvement, assumptionIndex, i); isImprovementUpdated = true;'
														[(ngModel)]="values.value" style='max-width: 100px;' /> -->

																<input class="form-control" (keydown)='values.updated = true; isImprovementUpdated = true;' [(ngModel)]="values.impact" style='max-width: 100px;' />
															</div>
															<span *ngIf="showScratchPad(values)" (click)="showScratchPadModal(values, improvement)" class="m-l-md">
																<i class="icon-calculator" role="button"></i>
															</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>












										<div class="sectionHeader clearfix">

											<h5 class='pull-left' style='margin-right: 10px;'>
												{{ 'props.tabDetails.discovery.assumptions.ScratchpadUnits' | translate }}</h5>
											<button class="btn btn-success btn-sm" *ngIf="!scratchpadEditing" (click)="scratchpadEditing = !scratchpadEditing"><i class='fa fa-pencil'></i>
												{{ 'compare.edit_all_values' | translate }}</button>
											<button class="btn btn-danger btn-sm" *ngIf="scratchpadEditing" (click)="scratchpadCancelEdit()">{{ 'compare.cancel_changes' | translate }}</button>
											<button class="btn btn-success btn-sm" *ngIf="scratchpadEditing" (disable)='!scratchpadEditing' (click)="compareBulkUpdateScratchpad(); scratchpadEditing = !scratchpadEditing">{{ 'compare.save_changes' | translate }}</button>
										</div>
										<div class="table-responsive" [ngbCollapse]="isCollapsedAssumptions" style='padding-bottom: 0px;'>
											<table class="table table-bordered">
												<thead>
													<tr>
														<th style='width: 20px; max-width: 20px;'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ scratchpad_unit.name | translate }}" *ngIf="scratchpad_unit.expand" (click)='scratchpad_unit.expand = !scratchpad_unit.expand'></i>
												<i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ scratchpad_unit.name | translate }}" *ngIf="!scratchpad_unit.expand" (click)='scratchpad_unit.expand = !scratchpad_unit.expand'></i> -->
														</th>
														<th width="200px">&nbsp;</th>
														<th [ngClass]='{"col": valueProp.expand}' *ngFor="let valueProp of valueProps">
															<span class="clickable linkish" (click)="goToValuePropDash(valueProp.id)">{{valueProp.name}}</span>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{valueProp.name}}" *ngIf="valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{valueProp.name}}" *ngIf="!valueProp.expand" (click)='valueProp.expand = !valueProp.expand'></i> -->
														</th>
													</tr>
												</thead>

												<tbody *ngFor='let scratchpad_unit of scratchpad_units; let scratchPadUnitIndex = index'>
													<tr [ngClass]='{"expanded" : scratchpad_unit.expand}'>
														<td class='toggle'>
															<!-- <i class="fa fa-plus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Expand {{ scratchpad_unit.name | translate }}" *ngIf="scratchpad_unit.expand" (click)='scratchpad_unit.expand = !scratchpad_unit.expand'></i> -->
															<!-- <i class="fa fa-minus-square-o clickable" aria-hidden="true" container="body" ngbTooltip="Collapse {{ scratchpad_unit.name | translate }}" *ngIf="!scratchpad_unit.expand" (click)='scratchpad_unit.expand = !scratchpad_unit.expand'></i> -->
														</td>
														<td class="cell boldTitle">
															<strong>{{scratchpad_unit.name}}</strong>
														</td>
														<td class='cell' [ngClass]='{"col": valueProps[i].expand}' *ngFor='let values of scratchpad_unit.values; let i = index;'>
															<span class="editable" *ngIf="!scratchpadEditing" (click)="openModal(assumptionsModal, values, scratchpad_unit, scratchPadUnitIndex, i)">
																{{values.operand}}
															</span>
															<div *ngIf="scratchpadEditing && (values.operand !== undefined)" class='input-group' style='margin-bottom: 10px; display: block;'>
																<input class="form-control" (keyup)='values.updated = true; scratchpadEditing = true;' [(ngModel)]="values.operand" style='max-width: 100px;' />
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</section>
								</div>
								<!-- <div *ngIf="compareLoader" class="col-12 text-center m-t-lg">
							<img class="actionLoader" src="{{fullImagePath}}" />
						</div> -->
							</div>
						</div> <!-- .panel -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddCost' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-add-cost-modal *ngIf="toggleAddCost" (callback)='toggleAddCost = false; readMasterValueProp()' [cost]='cost' [costId]='costId.toString()' [edit]='edit' [data]='valuePropId'></app-add-cost-modal>
</p-sidebar>

<ng-template #assumptionsModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ 'compare.assumptions' | translate }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body clearfix">
		<div class='row'>
			<div class='col-xs-12'>
				<label>{{assumptionSelected.name}}</label>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<input type="number" class="form-control" name="assumptionValue" [(ngModel)]="assumptionValues.value" style='max-width: 150px;' />
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<img *ngIf="assumptionSaveLoader" class="actionLoader" src="{{fullImagePath}}" />
		<div *ngIf="!assumptionSaveLoader">
			<button type="button" class="btn btn-primary" (click)="saveAssumptions()" matTooltip="Save value now and refresh page" matTooltipPosition="below">{{ 'general.save' | translate }}</button>
			<button *ngIf="!assumptionValues.impact_fmt" type="button" class="btn btn-primary" (click)="saveAssumptions('all')" matTooltip="Save value for all ValueProp and refresh page" matTooltipPosition="below">{{ 'compare.save_all' | translate }}</button>
		</div>
	</div>
</ng-template>


<ng-template #deleteCostModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ 'compare.delete_cost' | translate }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{ 'compare.are_you_sure_that_you_want_to_delete_this_cost?' | translate }}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="deleteCost();c('Close click')">{{ 'general.Yes' | translate }}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ 'general.No' | translate }}</button>

	</div>
</ng-template>