import { ValueProp } from '@shared/models/value-prop.model';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SimpleImplementation } from '../../../../simple.implementation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ScenarioCombinedModelTranslation } from './sc_models.translation';
import { SmallModel, SolutionCategory } from './small-model.model';

@Component({
  selector: 'app-dashboard-sc-vpg-models-repv2',
  templateUrl: './models.component.html',
})
export class SCVPGModelsComponentRepv2 implements OnInit, OnDestroy {
  @Input() edit = false;
  @Input() valueProp: ValueProp;
  @Input() canEdit = false;
  @Output() callback = new EventEmitter();
  showModelsEditing = false;

  loading = false;

  ngUnsubscribe = new Subject();

  availableSolutions: SolutionCategory[] = [];
  solutionCategoryList: SolutionCategory[] = [];
  categoryType: string;
  hasModels = false;
  hasRadioButtonFeature = false;
  selectedModels: SmallModel[] = [];
  situationLength = 0;
  loadingSave = false;
  q2answer = '';
  showTranslate = false;

  feature61 = false;
  feature67: boolean;

  fontWeight: string = '600';
  fontSize: string = '18px';

  constructor(
    private notificationService: NotificationService,
    private commonService: CommonService,
    private RS: RepSimpleViewService,
    public sI: SimpleImplementation,
    private solutionService: SolutionService,
    private valuepositionService: ValuepositionService,
    private valuepropService: ValuepropService,
    private translationService: TranslationsV2Service,
    public trans: ScenarioCombinedModelTranslation
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.canEdit = this.valueProp.closed === '0' && this.valueProp.vp_can_edit;
    this.getAvailableSolutions();

    this.feature67 = this.commonService.checkFeature('67');
    this.feature61 = this.commonService.checkFeature('61');

    this.edit = !!this.valueProp.scenario_done && this.valueProp.scenario_done != '0' ? true : false;
    if (this.valueProp && this.valueProp.vmw_q_2) {
      this.q2answer = this.valueProp.vmw_q_2;
    }

    this.hasRadioButtonFeature = this.commonService.checkFeature(54);
    this.getSolutionCategoryList();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getSolution(categoryId): void {
    if (this.availableSolutions) {
      this.availableSolutions = this.availableSolutions.map((item) => {
        item['selectedCategory'] = false;
        if (item.solution_category_id != null && item.solution_category_id.indexOf(categoryId) >= 0) {
          item['selectedCategory'] = true;
        }
        return item;
      });
    }
  }

  getAvailableSolutions(): void {
    this.loading = true;

    this.solutionService
      .getAvailableSolutionsPlain(this.valueProp.id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((result) => {
        this.availableSolutions = result.result;

        if (this.availableSolutions) {
          this.availableSolutions = this.availableSolutions.map((item) => {
            item['quantity'] = 1;
            return item;
          });
        }
        if (this.availableSolutions.length == 1) {
          this.availableSolutions = this.availableSolutions.map((item) => {
            item['quantity'] = 1;
            return item;
          });
        }
        this.getSolutionCategoryList();
        this.getSolutionsUsedInValueProps();
      });
  }

  getSolutionsUsedInValueProps(): void {
    this.loading = true;
    this.hasModels = false;
    const id = this.valueProp.id;
    this.valuepositionService
      .getValuePropSolutions(id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        if (response.result) {
          const selectedSolutions = response.result;

          const solutionIds = selectedSolutions.map((solution) => solution['account_solution_id']);
          if (this.availableSolutions && this.availableSolutions.length) {
            this.availableSolutions.forEach((solution) => {
              const index = solutionIds.indexOf(solution['id']);
              if (index != -1) {
                this.selectedModels.push({
                  solutionId: solution['id'],
                  quantity: Number(selectedSolutions[index]['quantity']),
                });

                solution['isChecked'] = true;
                this.hasModels = true;
                solution['quantity'] = Number(selectedSolutions[index]['quantity']);
              }
            });
          }
        }
      });
  }

  getSolutionCategoryList(): void {
    this.solutionService
      .getSolutionCategoryListwithVPID()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.solutionCategoryList = result.result;
        this.categoryType = this.solutionCategoryList[0].id;
        this.getSolution(this.categoryType);
        if (this.solutionCategoryList && this.solutionCategoryList.length) {
          if (this.categoryType) {
            this.getSolution(this.solutionCategoryList[0]['id']);
          }
        }
      });
  }

  checkModels(params, quantity, event): void {
    if (this.hasRadioButtonFeature) {
      this.selectedModels = [];
    }
    if (event.target.checked === true) {
      const tempModel = {
        solutionId: params,
        quantity: quantity,
      };
      this.selectedModels.push(tempModel);
    } else if (event.target.checked === false) {
      this.situationLength = 0;

      this.selectedModels = this.selectedModels.filter((impact: any) => {
        return impact['solutionId'] !== params;
      });
    }
  }

  createSolution(): boolean {
    if (this.selectedModels) {
      const selectedModels = this.selectedModels.map((impact) => {
        return impact['solutionId'];
      });

      const selectedQuantity = this.selectedModels.map((impact) => {
        return impact['quantity'];
      });

      if ((!selectedModels && !selectedModels.length) || selectedModels.length == 0) {
        this.notificationService.error('Please select a Solution', false);
        return false;
      }

      this.loadingSave = true;

      const payload = {
        account_solution_id: selectedModels.join(','),
        value_prop_id: this.valueProp.id,
        quantity: selectedQuantity.join(','),
        delete_vp_solutions: 1,
      };

      this.valuepositionService
        .quickCreateSolution(this.valueProp.id, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          if (result.result) {
            const payload2 = { scenario_done: 1 };
            const payload3 = { scenario_done: 1, situation_done: 0, scalers_done: 0 };

            if (this.feature67) {
              if (this.valueProp.situation_done === '1' || this.valueProp.scalers_done === '1') {
                this.valueProp.situation_done = 'false';
                this.valueProp.scalers_done = 'false';
              }
            }
            const payload4 = this.feature67 ? payload3 : payload2;
            this.RS.putDone(this.valueProp.id, payload4)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((res) => {
                this.valueProp.scenario_done = 'true';
                if (!this.feature61) {
                  this.onFinish();
                }
              });
            this.edit = true;
            this.valuepropService.editSituations.next();
            this.showModelsEditing = false;
            this.loadingSave = false;
            this.getAvailableSolutions();
            this.notificationService.success('Solution assigned Successfully', false);
          }
        });
    }
  }

  onFinish(): void {
    this.valuepropService.reloadSituations.next();
    this.callback.emit(this.selectedModels);
  }

  funcCancel(): void {
    this.showModelsEditing = false;
    this.getAvailableSolutions();
  }
}
