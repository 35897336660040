import { Component, OnInit, Input } from '@angular/core';
import { PrimengChartData, PrimengData } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';

@Component({
	selector: 'app-bar-models',
	templateUrl: './bar-models.component.html',
	styleUrls: ['./bar-models.component.scss']
})
export class BarModelsComponent implements OnInit {
	@Input() chartData: any;
	id = "";
	chart: any;
	options: any;

	ngOnInit(): void {
		this.buildChart();
	}

	buildChart(): void {
		this.chart = {
			labels: this.chartData.data.labels,
			datasets: this.chartData.data.datasets
		}
		this.options = {
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true
					}
				}],
				xAxes: [{
					stacked: false,
					ticks: {
						autoSkip: false
					}
				}]
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						let label = data.datasets[tooltipItem.datasetIndex].label || '';
						if (label) {
							label += ': ' + data.datasets[0].data[tooltipItem.index];
						}
						return label;
					}
				}
			},
			legend: {
				display: false,
					position: "bottom",
						labels: {
					fontSize: 10
				}
			},

			animation: {
				duration: 100
			}
		}
	}
}

