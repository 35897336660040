<div class="models_page_header_container_small">
  <div class="keywest_header">
    <div class="row">
      <div class="col-xs-10">
        <div class="title">
          {{ trans.trans.OpportunityId.value }}
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="margin-bottom: 24px">
    <div class="col-xs-12">
      <div class="p-inputgroup" [ngClass]="{ 'has-error': opportunityIdControl?.touched && opportunityIdControl?.invalid }">
        <form [formGroup]="rootFormGroup.control">
          <input
            #inputElement
            formControlName="opportunityId"
            type="text"
            class="form-rounded form-control"
            style="width: 100% !important; caret-color: transparent"
            name="opportunityId"
            id="opportunityId"
            autocomplete="off"
            placeholder="Opportunity ID"
            (input)="setMaskedValue($event)"
          />
          <div style="color: #a94442; margin-top: 4px; margin-left: 6px" *ngIf="opportunityIdControl?.invalid">
            <span *ngIf="opportunityIdControl?.errors.required">{{ trans.trans.opportunityIdRequired.value }}</span>
          </div>
        </form>
        <div style="margin-left: 10px">
          <small *ngIf="isVMWare" style="font-size: 90%; font-style: italic"
            >{{ trans.trans.ch_oppurtunity_id.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.ch_oppurtunity_id"
              (callback)="onGetTranslations()"
            ></app-editTranslation>
          </small>
        </div>
      </div>
    </div>
  </div>
</div>
