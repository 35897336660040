import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { Subscription, Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitPhasingTranslations } from './phasing.translation';
import { take, finalize, takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { Metric, Phasing } from '@shared/models/value-prop.model';
import { FormArray, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'app/_utils/custom-validators';
import { ErrorMessagesService } from '@services/error-messages.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { AccrualTypeId } from '@data/services/valueprop/models/cost.model';
import { ConfirmationService } from 'primeng/api';
import { Metric as Metric2 } from '@data/services/valuerealization/models/value-tracker.model';
import { BenefitsImplementation } from '../../benefits.implementation';

@Component({
  selector: 'app-benefit-phasing',
  styleUrls: ['./phasing.component.scss'],
  templateUrl: './phasing.component.html',
})
export class BenefitPhasingComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  @Input() benefit: Metric | Metric2;
  @Input() mini = false;

  @Output() callBack = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  metricbenefits: { key: string; value: string }[];
  loading = false;

  showTranslate = false;
  ngUnsubscribe = new Subject();

  menuItems: any[] = [];
  getValuePropPhasingSubscription: Subscription;
  updateValuePropPhasingSubscription: Subscription;

  phasingTermGroup = this.fb.group({ phasingTermArray: new FormArray([]) });

  accrualTypes = ['', 'oneTime', 'annual', '', '', '', '', '', 'max100'];

  errorDict$ = this.errorMessagesService.formGroupObsFactoryCrossField(this.phasingTermGroup);

  get phasingTermArray() {
    return this.phasingTermGroup.get('phasingTermArray') as FormArray;
  }
  max100Map = new Map();

  constructor(
    private translationService: TranslationsV2Service,
    public trans: BenefitPhasingTranslations,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private errorMessagesService: ErrorMessagesService,
    private confirmationService: ConfirmationService,
    private bI: BenefitsImplementation
  ) {}

  ngOnInit(): void {
    this.max100Map.set(3, false);
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(take(1)).subscribe((res) => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(take(1)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.metricbenefits = [];
    this.loading = true;
    this.valuepropService
      .getValuePropPhasing(this.valueProp.id, this.benefit.id)
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
          // Needed to check loading change when parent uses OnPush
          this.cdref.markForCheck();
        })
      )
      .subscribe((response) => {
        if (response.result) {
          const phasing = response.result.metric_phasing;
          const isMax100 = +this.benefit.accrual_type_id === AccrualTypeId.Max100;
          this.metricbenefits = Object.keys(phasing).map((key) => {
            this.phasingTermArray.push(this.fb.group({ key: key, val: phasing[key] }));
            return {
              key: key,
              value: phasing[key],
            };
          });
          if (isMax100) {
            this.phasingTermGroup.setValidators([CustomValidators.maxTotalValueValidator(100)]);
          }
        }
      });
    this.bI.benefitsListByType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        res.forEach((benefitType) => {
          benefitType.vcs.forEach((vc) => {
            const foundMax100 = vc.metrics.some((metric) => {
              return +metric.accrual_type_id === AccrualTypeId.Max100;
            });
            if (foundMax100) {
              this.max100Map.set(3, true);
              this.max100Map.set(vc.id, true);
            }
          });
        });
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        const { accrual_type_id } = this.benefit;

        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.menuItems = [
          {
            label: this.trans.trans.applyThisBenefit.value,
            command: () => {
              this.showConfirmDialog(0);
            },
          },
          {
            label: this.trans.trans.applyBenefitInCategory.value,
            command: () => {
              this.showConfirmDialog(2);
            },
          },
          {
            label: this.trans.trans.applyBenefitInModel.value,
            command: () => {
              this.showConfirmDialog(3);
            },
          },
          {
            label: this.setAccrualTypeText(+accrual_type_id),
            command: () => {
              this.showConfirmDialog(4);
            },
          },
          {
            label: this.trans.trans.applyBenefitAll.value,
            command: () => {
              this.showConfirmDialog(1);
            },
          },
        ];
      });
  }

  showConfirmDialog(apply_all: 0 | 1 | 2 | 3 | 4) {
    let message = this.trans.trans.areYouSure.value;
    const totalValue = this.phasingTermArray.controls.reduce((acc, control) => acc + Number(control.value.val), 0);
    const modelHasMax100 = apply_all === 3 && this.max100Map.get(3) && +this.benefit.accrual_type_id !== AccrualTypeId.Max100;
    const categoryHasMax100 = apply_all === 2 && this.max100Map.get(this.benefit.value_category_id) && +this.benefit.accrual_type_id !== AccrualTypeId.Max100;

    if ((modelHasMax100 || categoryHasMax100 || apply_all === 1) && totalValue > 100) {
      message = this.trans.trans.max100Warning.value;
    }
    if (apply_all === 4) {
      message = `${this.trans.trans.allTypeWarning.value} ${this.trans.trans[this.accrualTypes[this.benefit.accrual_type_id]].value}, ${this.trans.trans.areYouSure.value}`;
    }

    this.confirmationService.confirm({
      message,
      acceptLabel: this.trans.trans.yes.value,
      rejectLabel: this.trans.trans.no.value,
      acceptButtonStyleClass: 'btn btn_customizable',
      rejectButtonStyleClass: 'btn btn_customizable_clear',
      header: 'Confirm Changes',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.updatePhasing(apply_all);
      },
      reject: () => {},
    });
  }

  setAccrualTypeText(accrualType: AccrualTypeId) {
    if (accrualType === AccrualTypeId.Annual) {
      return this.trans.trans.applyBenefitToAnnual.value;
    } else if (accrualType === AccrualTypeId.OneTime) {
      return this.trans.trans.applyBenefitToOneTime.value;
    } else if (accrualType === AccrualTypeId.Max100) {
      return this.trans.trans.applyBenefitToMax100.value;
    } else {
      return '';
    }
  }

  updatePhasing(apply_all: 0 | 1 | 2 | 3 | 4) {
    this.loading = true;

    const metricsPhasingModel: Phasing = {
      value_prop_id: +this.valueProp.id,
      value_prop_metric_id: +this.benefit.id,
      apply_all: apply_all,
    };

    if (apply_all === 4) {
      metricsPhasingModel.accrual_type_id = this.benefit.accrual_type_id;
    }

    for (let i = 0; i < this.metricbenefits.length; i++) {
      let key = this.metricbenefits[i]['key'];
      let value = this.phasingTermArray.at(i).value.val;
      metricsPhasingModel[key] = value;
    }
    this.valuepropService
      .updateValuePropPhasing(metricsPhasingModel, this.valueProp.id)
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
          this.cdref.markForCheck();
        })
      )
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          if (!this.mini) {
            this.valuepropService.refreshBenefitDetail.next();
            this.valuepropService.refreshDashboard.next('refreshDashboard');
            this.notificationService.success(this.trans.trans.phasing_updated_success.value, false);
          }
          this.reloadVP.emit(apply_all > 0 ? 'all' : '');
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false); // Params {message, islogout}
        }
      });
  }
}
