import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localeNumberMask'
})
export class LocaleNumberMaskPipe implements PipeTransform {

  transform(value: number | string, locale: string, minimumFractionDigits: number): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits
    }).format(Number(value));
  }

}

