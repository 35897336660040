import { Injectable } from '@angular/core';

@Injectable()
export class ValuePropCloneTranslation {
    public config: any = {
        component: 'vp-clone',
        display_name: 'Value Prop Clone',
        description: 'Sidebar for cloning a value prop',
    };

    public trans: any = {
        title: 'Clone',
        namePlaceholder: 'Enter a name for the new Clone',
        cloneOption: 'Clone Options',
        exactCopy: 'Clone (Exact Copy)',
        newAccount: 'For a New Account',
        newOpportunity: 'For a New Opportunity ID',
        cloneOwner: 'Owner of Clone',
        ownerMe: 'Me',
        ownerOriginal: 'Original Owner',
        cancel: 'Cancel',
        save: 'Save',
        newAccountLabel: 'Name',
        opportunityIDPlaceholder: 'Opportunity ID',
        areYouSure: 'Are you sure?',
        cloning: 'Cloning in progress...',
        nameRequired: 'Clone Name is required',
        companyRequired: 'Please select a company',
        opportunityIDRequired: 'Please enter in an Opportunity ID',
        back: 'Back'
    };
}
