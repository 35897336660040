import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tracker-case-studies',
  templateUrl: 'tracker-case-studies.component.html',
})
export class TrackerCaseStudiesComponent {
  @Input() vpRealization: any;
  @Input() can_edit: boolean = false;

  showDetail = false;
  toggleAddEditCaseStudy = false;
  createCaseStudy = false;

  caseStudy: any;
  caseStudyId: string;

  ngUnsubscribe = new Subject();

  closeCreateCaseStudy(): void {
    this.createCaseStudy = false;
  }

  funcOpenDetail(event) {
    this.showDetail = true;
    this.caseStudy = event;
    this.caseStudyId = event.id;
  }

  funcCloseDetail() {
    this.showDetail = false;
    this.caseStudy = undefined;
    this.caseStudyId = undefined;
  }
}
