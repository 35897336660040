import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class DefaultTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'applications-settings',
    display_name: 'Application Settings',
    description: 'Set application settings in profile',
  };

  public trans: any = {
    accountLogos: 'Account Logos',
    accountStage: 'Account Stage',
    accountStageName: 'Account Stage Name',
    accountStages: 'Account Stages',
    are_you_sure: 'Are you sure?',
    add: 'Add',
    add_new_status: 'Add new status',
    application_settings: 'Application Settings',
    availableColors: 'Available Colors',
    backToProfile: 'Back to Profile',
    changeAccountIcon: 'Change Account Icon',
    changeAccountLogo: 'Change Account Logo',
    change_logo: 'Change Logo',
    change_icon: 'Change Icon',
    chartColor: 'Chart Color',
    createNew: 'Create New',
    cancel_edit: 'Cancel Edit',
    deleteAccountStage: 'Delete Account Stage',
    deleteConfirmation: 'Are you sure that you want to remove this Account Stage type?',
    description: 'Description',
    delte_status: 'Delete Status',
    edit_status: 'Edit Status',
    waccFactor: 'WACC Factor',
    waccFactorDescription: 'Choose factor to associate with WACC',
    waccFactorRequired: 'Please select a factor',
    waccFactorSaved: 'WACC Factor successfully saved',
    headerColor: 'Header Color',
    imageWithTransparentBackgroundWorksBest: 'Image with transparent background works best.',
    import_system_statuses: 'Import system statuses',
    import_system: 'Import System Statuses',
    noAccountStagesFound: 'No Account Stages Found',
    no_entries_found: 'No Entries Found',
    requires_comment: 'Requires comment?',
    sequence: 'Sequence ',
    status: 'Status',
    save_changes: 'Save Changes',
    userPreference: 'User Preference',
    vp_status: 'Value Prop Status',
    branding: 'Branding',
    customize_ui: 'Customize UI',
    well_info:
      'Below, you can set various UI color elements. Click on the colored square to pick a color or enter in a web hex value into the input box and click the Update button.',
    header_color: 'Header Color',
    button_background_color: 'Button Background Color',
    button_text_color: 'Button Text Color',
    update: 'Update',
    well_info_2:
      "Below, you can choose what colors to use in various charts available around the application. Add as many as you'd like, but we recommend at least 10 colors for minimum value coverage.",
    add_color: 'Add Color',
    well_info_3:
      'These statuses appear in Value Prop Details for selection. Adding to this list switches from the built in status list. You can drag and drap rows to change their order.',
    sample_preview: 'Sample preview',
    header_sample: 'Header',
    button_sample: 'Button',
    settings: 'Settings',
    dashboard: 'Dashboard',
    setTermYears: 'Set Term Years',
    selectAvailableTerms: 'Select Available Term Years',
    termDisclaimer: 'IMPORTANT: Please submit a support ticket to request new Asset templates for new term years.',
    setDefaultTerm: 'Set Default Term',
    year1: '1 Year',
    year2: '2 Years',
    year3: '3 Years',
    year4: '4 Years',
    year5: '5 Years',
    year6: '6 Years',
    year7: '7 Years',
    year8: '8 Years',
    year9: '9 Years',
    year10: '10 Years',
    termsSuccess: 'Successfully saved account terms',
    manageAssetGenerator: 'Manage Asset Generator',
    legacyGenerator: 'Legacy Generator',
    v3Generator: 'V3 Generator',
    driveGenerator: 'Save to Google Drive',
    assetGeneratorSuccess: 'Successfully saved asset generator',
    serverProblem: 'There was a problem, please try again.',
    supportUrl: 'Support URL',
    supportEmail: 'Support Email',
    emailTooltip: 'This field is used to define an internal email address where users will send support issues.',
    urlTooltip: 'Use this field to specify a custom support url to which users will be redirected.',
    setFiscalYear: 'Set Fiscal Year',
    fiscalYearStart: 'What month does your fiscal year start?',
    fiscalYearSuccess: 'Successfully saved fiscal year start month',
    communityUrl: 'Community URL',
    lmsPath: 'Learn URL',
  };
}
