import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardImplementation } from '../../dashboard.implementation';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueRealizationDashboardTranslations } from './valuerealization.translation';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { StyleService } from 'app/style.service';

@Component({
  selector: 'app-dashboard-valuerealization',
  templateUrl: './valuerealization.component.html',
})
export class DashboardValueRealizationComponent implements OnInit, OnDestroy {
  retainList: { [klass: string]: any }[] = [];

  ngUnsubscribe = new Subject();
  showTranslate = false;
  loading = false;
  style2022$: Observable<boolean>;

  constructor(
    public dbI: DashboardImplementation,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: ValueRealizationDashboardTranslations,
    private valuerealizationService: ValuerealizationService,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getData(): void {
    this.loading = true;
    const searchLimit = '/limit/' + 5 + '/page/' + 1;
    this.valuerealizationService.getValueRealizationQuick(searchLimit).subscribe((response) => {
      if (response.result) {
        this.retainList = response.result.data;
      }
      this.loading = false;
    });
  }
}
