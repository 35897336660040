import { Component, OnInit, Input, OnChanges, HostListener, ViewChild } from '@angular/core';
import { PrimengChartData, PrimengData } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';

import { UIChart } from 'primeng/chart';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bar-line-chart',
  templateUrl: './bar-line-chart.component.html',
  styleUrls: ['./bar-line-chart.component.scss'],
})
export class BarLineChartComponent implements OnInit {
  @Input() set data(chartData) {
    this.chartData = chartData;
    this.chartData.data.datasets.forEach((dataset) => {
      if (dataset.label === 'ROI') {
        const currentData = dataset.data as (number | string)[];
        dataset.data = currentData.map((number) => (!(typeof number === 'number') ? Number(number.replace(',', '')) : number));
      }
    });
    this.buildChart(this.chartData);
  }
  @Input() axis2 = false;
  @Input() height = '300px';
  @Input() style2022 = false;
  id = '';
  chart: any;
  options: any;
  px_ratio: number;
  @ViewChild('pBar', { static: false }) pBar: UIChart;
  responsive = true;
  subscription: Subscription;
  chartData: any;

  ngOnInit(): void {
    this.buildChart(this.chartData);
    this.px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
  }

  @HostListener('window:resize', ['$event']) onResize(): void {
    this.isZooming();
  }

  isZooming(): boolean {
    const newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
    if (newPx_ratio != this.px_ratio) {
      this.px_ratio = newPx_ratio;
      return true;
    } else {
      return false;
    }
  }

  buildChart(chartData: any): void {
    this.chart = {
      labels: this.chartData.data.labels,
      datasets: this.chartData.data.datasets,
    };
    this.options = {
      scales: {
        yAxes: [
          {
            id: 'A',
            scaleLabel: {
              display: !!this.chartData.data.datasets.find((set) => set.yAxisID === 'A' && !!set.title),
              labelString: this.chartData.data.datasets.find((set) => set.yAxisID === 'A') ? this.chartData.data.datasets.find((set) => set.yAxisID === 'A').title : '',
            },
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                const number = !(typeof value === 'number') ? Number(value.replace(',', '')) : value;
                const numberLength = number.toString().length;
                let unit = '';

                if (numberLength >= 4 && numberLength <= 6) {
                  unit = 'K';
                } else if (numberLength >= 7 && numberLength <= 9) {
                  unit = 'M';
                } else if (numberLength >= 10 && numberLength <= 13) {
                  unit = 'B';
                } else if (numberLength >= 14 && numberLength <= 17) {
                  unit = 'T';
                }

                switch (unit) {
                  case 'K':
                    return number / 1e3 + unit;
                  case 'M':
                    return number / 1e6 + unit;
                  case 'B':
                    return number / 1e9 + unit;
                  case 'T':
                    return number / 1e12 + unit;
                  default:
                    return number;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }

            if (tooltipItem.datasetIndex == 0) {
              if (chartData['d0']) {
                label += chartData['d0'][tooltipItem.index];
              }
            }
            if (tooltipItem.datasetIndex == 1) {
              if (chartData['d1']) {
                label += chartData['d1'][tooltipItem.index];
              }
            }
            if (tooltipItem.datasetIndex == 2) {
              if (chartData['d2']) {
                label += chartData['d2'][tooltipItem.index];
              }
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          fontSize: 16,
        },
      },

      animation: {
        duration: 100,
      },
    };
    if (this.axis2) {
      this.options.scales.yAxes.push({
        id: 'B',
        position: 'right',
        scaleLabel: {
          display: !!this.chartData.data.datasets.find((set) => set.yAxisID === 'B' && !!set.title),
          labelString: this.chartData.data.datasets.find((set) => set.yAxisID === 'B') ? this.chartData.data.datasets.find((set) => set.yAxisID === 'B').title : '',
        },
        ticks: {
          callback: function (value) {
            const checkedValue = !(typeof value === 'number') ? Number(value.replace(',', '')) : value;
            return checkedValue.toFixed(0) + '%';
          },
        },
      });
    }
    if (this.style2022) {
      this.options = {
        ...this.options,
        scales: {
          ...this.options.scales,
          xAxes: [
            {
              barPercentage: 0.6,
              categoryPercentage: 0.4,
            },
          ],
        },
        legend: {
          position: 'bottom',
          labels: {
            fontSize: 16,
            padding: 20,
            boxWidth: 5,
            usePointStyle: true,
          },
        },
      };
    }
  }
}
