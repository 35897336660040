import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class CaseTypeResolver implements Resolve<any> {
  constructor(private valuePropService: ValuepropService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    return this.valuePropService
    .getValuePropDetail(route.paramMap.get('vpid'))
    .pipe(map((response) => {
      this.valuePropService.valueProp.next(response.result.value_prop);
      if (Number(response.result.value_prop.value_prop_status) <= 3) {
        return 'hypothesis';
      } else {
        return 'case';
      }
    }));
  }
}