import { NgModule } from '@angular/core';

import { SituationComponent } from './situation.component';
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TreeTableModule } from 'primeng/treetable';
import { SidebarModule } from 'primeng/sidebar';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { AEDetailSituationModule } from './ae_situations/ae_situations.module';
import { RDetailSituationModule } from './r_situations/r_situations.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SituationTranslations } from './situation.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TabViewModule } from 'primeng/tabview';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SituationComponent],
  imports: [
    AEDetailSituationModule,
    TreeTableModule,
    SidebarModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    MatTooltipModule,
    AreYouSureModule,
    RDetailSituationModule,
    EditTranslationsModule,
    TabViewModule,
  ],
  providers: [TranslationsV2Service, SituationTranslations],
  entryComponents: [SituationComponent],
  exports: [SituationComponent],
})
export class DetailSituationModule {}
