import { Injectable } from '@angular/core';

@Injectable()
export class AECollateralTranslations {

    public config = {
        component: 'addEditCollateral',
        display_name: 'Collateral Form',
		description: 'Form for creating and editing a collateral'
    };

    public trans: any = {
        addCollateral: 'Add Collateral',
        editCollateral: 'Edit Collateral',
        format: 'Format',
        chooseOne: 'Choose One',
        name: 'Name',
        url: 'Url',
        description: 'Description',
        save: 'Save',
        edit_translations: 'Edit Translations',
        back: 'Back',
        are_you_sure: 'Are you sure?'
    };
}
