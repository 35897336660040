<div *ngIf="!surveyLoading; else loader">
  <form #takeSurvey="ngForm">
    <div class="survey-padding" [class.panel]="!isValueRealized" [class.panel-white]="!isValueRealized">
      <div *ngIf="!isValueRealized" class="panel-heading">
        <div class="panel-title">
          <h4 class="survey-header">{{ trans.trans.surveys.value }}</h4>
        </div>
      </div>

      <div class="row margin-15-y" *ngIf="isValueRealized">
        <div class="col-md-6" [ngClass]="{ error: !respondent }">
          <label class="sidebar_label">Respondent</label>
          <input type="text" #respInput pInputText class="form-control" name="respondent" [(ngModel)]="respondent" [required]="true" />
        </div>
      </div>
      <div class="panel-body">
        <div class="dynamicQuestionare">
          <div class="row">
            <div class="rowBlock" *ngFor="let question of activeSurveyTemplate.questions_list; let i = index">
              <div class="clearfix" *ngIf="i === 0 || i % 2 === 0"></div>
              <div class="col-md-6">
                <div class="questionBlock" [ngClass]="'question' + question.id">
                  <div class="row">
                    <div class="col-md-10">
                      <p>{{ i + 1 }}. {{ question.label }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-offset-1 col-md-5 p-l-0">
                      <div class="form-group" *ngIf="+question.question_type_id === 1">
                        <input type="text" class="form-control" [placeholder]="trans.trans.answer.value" [(ngModel)]="question.answer" name="question{{ i }}" />
                      </div>
                      <div class="form-group" *ngIf="+question.question_type_id === 3">
                        <p-inputSwitch styleClass="inputswitch_header" name="question{{ i }}" [(ngModel)]="question.answer"></p-inputSwitch>
                        <label class="marg-h">
                          <span>{{ question.answer ? trans.trans.Yes.value : trans.trans.No.value }}</span>
                        </label>
                      </div>
                      <div class="form-group" *ngIf="+question.question_type_id === 4">
                        <textarea rows="1" class="form-control" [(ngModel)]="question.answer" name="question{{ i }}"></textarea>
                      </div>
                      <div class="form-group" *ngIf="+question.question_type_id === 2">
                        <div class="createOptions">
                          <div class="optionGroup" *ngFor="let option of question.question_options; let j = index">
                            <fieldset class="radio-control">
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    class="customradio"
                                    [name]="'question' + question.id"
                                    [checked]="option.checked"
                                    (click)="saveSingleSelectOption(question, option)"
                                  />
                                  <span>&nbsp; {{ option.name }}</span>
                                </label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <div class="form-group" *ngIf="+question.question_type_id === 5">
                        <div class="createOptions">
                          <div class="optionGroup" *ngFor="let option of question.question_options; let j = index">
                            <input type="checkbox" class="customcheckbox" name="question{{ i }}{{ j }}" [(ngModel)]="option.checked" />
                            <span>&nbsp; {{ option.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-white" *ngIf="!isTracker && !isValueRealized">
      <div class="panel-heading">
        <div class="panel-title clearfix">
          <h4 class="pull-left">{{ trans.trans.benefit_performance.value }}</h4>
          <div class="performanceOption pull-left">
            <label *ngFor="let option of benefitPerformanceType">
              <input type="radio" [value]="option.id" name="performanceOption" [(ngModel)]="benefitPerformanceTypeSel" />
              <span>{{ option.name }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="panel-body" *ngIf="!isTracker && !isValueRealized">
        <div class="benefitPerformance">
          <div class="benefits" *ngFor="let benefit of surveyDetails.value_survey_metrics">
            <div class="row">
              <div class="col-md-3">
                <p style="padding: 5px">{{ benefit.metric_name }}</p>
              </div>
              <div class="col-md-6" *ngIf="+benefitPerformanceTypeSel !== 2">
                <input type="range" min="0" max="200" name="PerformanceValue{{ benefit.id }}" [(ngModel)]="benefit.value_survey_improvement" />
              </div>
              <div class="col-md-1">
                <input type="text" name="PerformanceValue{{ benefit.id }}" [(ngModel)]="benefit.value_survey_improvement" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" *ngIf="!surveyDetails.value_survey_metrics">
          <div class="well">
            {{ trans.trans.no_benefit_performance_found.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="button-group pull-right marg-v">
      <img *ngIf="actionLoader" class="actionLoader" [src]="fullImagePath" />
      <app-are-you-sure
        tooltipPosition="top"
        [message]="trans.trans.are_you_sure.value"
        class="btn cancel-confirm marg-h"
        icon=""
        [text]="trans.trans.cancel.value"
        (callbackYes)="closeSurvey()"
      ></app-are-you-sure>
      <button class="btn btn_customizable" [disabled]="saveDisabled" (click)="saveSurvey()">{{ trans.trans.save.value }}</button>
    </div>
  </form>
</div>

<ng-template #loader>
  <div class="col-12 text-center">
    <img class="actionLoader" [src]="fullImagePath" />
  </div>
</ng-template>
