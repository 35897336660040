import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { cloneDeep } from 'lodash';

import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { TrackerGoalsTranslation } from './tracker-goals.translation';

import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { SimpleGoal, SimpleGoalReactiveForm, ValuePropAddGoal, ValuePropGoal, ValueRealizationGoal, ValueRealizationSetGoal } from '@shared/models/goal-group.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';

@Component({
  selector: 'app-tracker-goals',
  templateUrl: './tracker-goals.component.html',
  styleUrls: ['./tracker-goals.component.scss'],
})
export class TrackerGoalsComponent implements OnInit, OnDestroy {
  @Input() canEdit: boolean;
  @Input() valuePropId: string;

  loading = false;
  loadingSave = false;
  loadingChartSave = false;
  strSearch: string;
  toggleSidebar: boolean;
  toggleChart: boolean;

  ngUnsubscribe = new Subject();
  showTranslate: boolean;

  goalsList: ValueRealizationGoal[];
  goalsListIds: string[];
  selectedGoal: SimpleGoal;
  selectedGoalChart: ValueRealizationGoal;
  goalOnTheFlyPrivilege = this.commonService.checkPrivilege('17');

  constructor(
    public trans: TrackerGoalsTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private goalsService: GoalGroupsService,
    private notificationService: NotificationService,
    private valueRealizationService: ValuerealizationService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getData();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.valueRealizationService.readSurveyList.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getData();
    });
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getData(): void {
    this.loading = true;
    this.goalsService.getValueRealizationGoal(this.valuePropId).subscribe((goals) => {
      this.goalsList = goals;
      this.goalsListIds = this.goalsList.map((g) => g.account_factor_id.toString());
      this.loading = false;
    });
  }

  openGoal(goalData: ValueRealizationGoal = null): void {
    if (goalData) {
      this.selectedGoal = {
        name: goalData.factor_name,
        value: goalData.value.toString(),
        description: goalData.factor_description,
        account_factor_id: goalData.account_factor_id.toString(),
        unit_type_id: String(goalData.unit_type_id),
      };
    }
    this.toggleSidebar = true;
  }

  openGoalChart(goalData: ValueRealizationGoal = null): void {
    this.selectedGoalChart = cloneDeep(goalData);
    this.toggleChart = true;
  }

  onSaveClick(goalData: SimpleGoalReactiveForm): void {
    this.loadingSave = true;
    const payload: ValuePropAddGoal = {
      account_factor_id: goalData.accountFactorId,
      value: goalData.value,
      source_type_id: 1,
    };

    this.goalsService.addValuePropGoal(this.valuePropId, payload).subscribe(
      () => {
        this.loadingSave = false;
        this.toggleSidebar = false;
        this.notificationService.success(this.selectedGoal ? this.trans.trans.editSuccess.value : this.trans.trans.addSuccess.value, false);
        this.getData();
      },
      () => {
        this.loadingSave = false;
        this.notificationService.error(this.trans.trans.saveError.value, false);
      }
    );
  }

  onSaveChartClick(goalData: ValueRealizationGoal): void {
    this.loadingChartSave = true;
    const payload = goalData.survey_factors.map((surveyGoal) => ({
      account_factor_id: surveyGoal.account_factor_id.toString(),
      value_survey_id: surveyGoal.survey_id.toString(),
      source_type_id: surveyGoal.source_type_id,
      factor_value: surveyGoal.factor_value,
      value_survey_factor_id: surveyGoal.value_survey_factor_id,
    }));

    this.goalsService.setValueRealizationGoals(this.valuePropId, payload as unknown as ValueRealizationSetGoal[]).subscribe(
      () => {
        this.loadingChartSave = false;
        this.toggleChart = false;
        this.notificationService.success(this.trans.trans.editSuccess.value, false);
        this.getData();
      },
      () => {
        this.loadingChartSave = false;
        this.notificationService.error(this.trans.trans.saveError.value, false);
      }
    );
  }

  onCancelChartClick(): void {
    this.toggleChart = false;
  }

  removeGoal(goalData: ValuePropGoal): void {
    this.goalsService.deleteValuePropGoal(this.valuePropId, goalData.account_factor_id).subscribe(
      () => {
        this.getData();
        this.notificationService.success(this.trans.trans.deleteMessage.value, false);
      },
      () => {
        this.notificationService.error(this.trans.trans.saveError.value, false);
      }
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }
}
