import { Injectable } from '@angular/core';

@Injectable()
export class SupportTranslations {
  public config: any = {
    component: 'supportComponents',
    display_name: 'Support Page',
    description: `Submit issues and faq's`,
  };

  public trans: any = {
    faq: 'FAQ',
    submitCommentsAndConcerns: 'Submit Comments and Concerns',
    support: 'Support',
  };
}
