import { Injectable } from '@angular/core';

@Injectable()
export class UsersProfileTranslation {
  public config: any = {
    component: 'usersProfile',
    display_name: 'Users Section',
    description: 'List of users in profile section',
  };

  public trans: any = {
    backToProfile: 'Back to profile',
    email: 'Email',
    first_name: 'First Name',
    key: 'CRM Key?',
    last_name: 'Last Name',
    role: 'Role',
    username: 'User Name',
    manageusers: 'Manage Users',
    select_user: 'Select User',
    actions: 'Actions',
    name: 'Name',
    email_lowercase: 'Email(Lowercase)',
    manager_email: 'Manager Email',
    search: 'Search',
    rowLimit: 'Row limit:',
    edit: 'Edit',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    addUser: 'Add User',
    editUser: 'Edit User',
    userEmail: 'User Email',
    selectPrivilege: 'Select Privilege',
    save: 'Save',
    add: 'Add',
    FirstName: 'First Name',
    LastName: 'Last Name',
    UserName: 'User Name',
    selectGroup: 'Select User Group',
    settings: 'Settings',
    dashboard: 'Dashboard',
    back: 'Back',
    status: 'Status',
    assignmentType: 'Assignment Type',
    notAssigned: 'Not Assigned',
    manuallyAssign: 'Manually Assign',
    manuallyAssigned: 'Manually Assigned',
    manuallySuppress: 'Manually Suppress',
    manuallySuppressed: 'Manually Suppressed',
    userGroupAssigned: 'User Group Assigned',
    workflowEnabled: 'Workflow Enabled',
    workflowDisabled: 'Workflow Disabled',
    workflowAssignments: 'Workflow Assignments',
    reset: 'Reset',
    basic: 'Basic Information',
    wfAssignments: 'Workflow Assignments',
    close: 'Close',
    saveAndContinue: 'Save & Continue',
    saveAndClose: 'Save & Close',
    cancel: 'Cancel',
    noRecords: 'No Records Found',
    workflowAssigments: 'Workflow Assigments',
    disableDirectLogin: 'Disable Direct Login',
    sendEmail: 'Send Email',
    reassignWarning: 'This user has created Value Propositions. You may re-assign these to another user. Please choose from the list below.',
    reassignValueProps: 'Reassign Value Propositions',
  };
}
