import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';
import { SCVPGQ2Module } from '../q2/q2.module';
import { SCVPGModelsComponentRepv2 } from './models.component';
import { ScenarioCombinedModelTranslation } from './sc_models.translation';



@NgModule({
  imports: [
    SCVPGQ2Module,
    ProgressBarModule,
    AreYouSureModule,
    TreeModule,
    NgbModule,
    MatTooltipModule,
    SidebarModule,
    DropdownModule,
    FieldsetModule,
    TableChartModule,
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    EditTranslationsModule,
  ],
  declarations: [SCVPGModelsComponentRepv2],
  providers: [Translations, ScenarioCombinedModelTranslation],
  exports: [SCVPGModelsComponentRepv2],
})
export class SCVPGModelsModule {}
