import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { DiscoverQuestionsVpDashTranslations } from './question.translation';
import { takeUntil } from 'rxjs/operators';
import { DiscoveryQuestion } from '@data/services/valueprop/models/discovery-question.model';
import { ActivatedRoute } from '@angular/router';
import { StepperMenuItem } from '@shared/models/stepper-menu.modles';

@Component({
  selector: 'app-discovery-questions',
  templateUrl: './questions.component.html',
  providers: [ValuepositionService],
})
export class DiscoveryQuestionsComponent implements OnInit, OnDestroy {
  account_id: string;
  questionsRequired: DiscoveryQuestion[] = [];
  questionsOptional: DiscoveryQuestion[] = [];
  image_url: string;

  @Input() valueProp: ValueProp;
  @Input() valuePropId: string | number;
  @Input() showTranslate = false;
  @Input() locked = false;
  @Input() isSidebar = true;
  @Input() showAllQuestions = false;
  @Input() menu: StepperMenuItem[];
  @Input() step: string;
  @Output() getScalers = new EventEmitter();
  @Output() callback = new EventEmitter();

  fullImagePath: string;
  saveLoader = false;
  public questionLoader = false;

  private discoveryQuestionsDict = new Map();
  private discoveryQuestionsSectionToShow: string;
  public discoveryQuestions: DiscoveryQuestion[] = [];

  ngUnsubscribe = new Subject();
  crm: string;

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: DiscoverQuestionsVpDashTranslations,
    private route: ActivatedRoute
  ) {
    this.discoveryQuestionsDict.set('account', '31');
    this.discoveryQuestionsDict.set('benefits', '38');
    this.discoveryQuestionsDict.set('keyassumptions', '39');
    this.discoveryQuestionsDict.set('costs', '40');
    this.discoveryQuestionsDict.set('results', '37');

    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }
  ngOnInit() {
    this.crm = sessionStorage.getItem('crm');
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      if (params.step) {
        this.discoveryQuestionsSectionToShow = this.discoveryQuestionsDict.get(this.menu[params.step].viewTop);
      } else if (!!this.step) {
        this.discoveryQuestionsSectionToShow = this.discoveryQuestionsDict.get(this.menu[this.step].viewTop);
      } else {
        this.discoveryQuestionsSectionToShow = '37';
      }

      if (this.showAllQuestions) {
        this.discoveryQuestionsSectionToShow = '';
      }
    });
    this.account_id = sessionStorage.getItem('aid');
    this.funcGetDiscoveryQuestions();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  closeDiscovery(): void {
    this.callback.emit();
  }

  funcGetDiscoveryQuestions(): void {
    this.questionLoader = true;
    this.valuepropService
      .getDiscoveryQuestions(this.valueProp.id, this.discoveryQuestionsSectionToShow)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: { result: DiscoveryQuestion[] }) => {
        this.discoveryQuestions = response.result.sort((a, b) => +a.sequence - +b.sequence);
        this.discoveryQuestions.forEach((question) => {
          if (question.required === '1') {
            this.questionsRequired.push(question);
          } else {
            this.questionsOptional.push(question);
          }
        });
        this.questionLoader = false;
      });
  }

  funcSaveQuestions(): boolean {
    this.saveLoader = true;
    let payload: { discovery_questions: DiscoveryQuestion[] };
    const discovery_questions = [];
    if (this.questionsRequired && this.questionsRequired.length) {
      this.questionsRequired.forEach((questionRequired) => {
        if (+questionRequired.question_type_id !== 3 && questionRequired.answer.trim().length === 0) {
          this.saveLoader = false;
          this.notificationService.error(this.trans.trans.allRequiredValues.value, false);
          return false;
        }
        discovery_questions.push({
          account_discovery_question_id: questionRequired.account_discovery_question_id,
          answer: questionRequired.answer,
        });
      });
    }

    if (this.questionsOptional && this.questionsOptional.length) {
      this.questionsOptional.forEach((question) => {
        discovery_questions.push({
          account_discovery_question_id: question.account_discovery_question_id,
          answer: question.answer ? question.answer : '',
        });
      });
    }

    if (!discovery_questions.length) {
      this.getScalers.emit();
      this.saveLoader = false;
      return;
    }

    payload = { discovery_questions: discovery_questions };

    this.valuepropService
      .putDiscoveryQuestions(this.valuePropId, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.getScalers.emit();
          this.saveLoader = false;
          this.callback.emit();
          this.notificationService.success(this.trans.trans.updateSuccess.value, false);
          this.valuepropService.reloadSituations.next();
          this.valuepropService.refreshDiscoveryQuestions$.next(true);
        } else {
          this.saveLoader = false;
          this.callback.emit();
        }
      });
  }
}
