import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable ,  Subject ,  Subscription } from 'rxjs';
import { NgModule } from '@angular/core';

import { Alert, AlertType } from '../_models/alert';

@Injectable()
@NgModule()
export class AlertService implements OnDestroy {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;
    public redirectLogin: boolean = false;

	subscriptionRouterEvents: Subscription;

    constructor(private router: Router) {
        this.subscriptionRouterEvents = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                } else {
                    this.clear();
                }
            }
        });
    }
	
	private subjectLoggingOut = new Subject<any>();
	notifyLoggingOut() { this.subjectLoggingOut.next(); }
	subscribeLoggingOut(): Observable<any> { return this.subjectLoggingOut.asObservable(); }

	private subjectLoggedIn = new Subject<any>();
	notifyLoggedIn(data: any) { this.subjectLoggedIn.next(data); }
	subscribeLoggedIn(): Observable<any> { return this.subjectLoggedIn.asObservable(); }

	private subjectShowLogin = new Subject<any>();
	notifyShowLogin() { this.subjectShowLogin.next(); }
	subscribeShowLogin(): Observable<any> { return this.subjectShowLogin.asObservable(); }

	private subjectShowForgotPassword = new Subject<any>();
	notifyShowForgotPassword() { this.subjectShowForgotPassword.next(); }
	subscribeShowForgotPassword(): Observable<any> { return this.subjectShowForgotPassword.asObservable(); }

	ngOnDestroy() {
		if (this.subscriptionRouterEvents) { this.subscriptionRouterEvents.unsubscribe(); }
	}
	


    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Success, message, keepAfterRouteChange);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Error, message, keepAfterRouteChange);
    }

    info(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Info, message, keepAfterRouteChange);
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.alert(AlertType.Warning, message, keepAfterRouteChange);
    }

    alert(type: AlertType, message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(<Alert>{ type: type, message: message });
    }

    clear() {
        this.subject.next();
    }
}