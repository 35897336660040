<button type="button" class="btn btn-primary-outline pull-right">
	<i class="fa fa-plus"></i> Add New</button>

<div>
	<div class="table-responsive col-sm-12">
		<div class="searchTableContainer" *ngIf="projects.length > 0">
			<table class="table custom-table">
				<thead>
					<tr>
						<th>Name</th>
						<th>&nbsp;</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr class="grid-row company-row" *ngFor="let project of projects">
						<td>
							<span *ngIf="project.parent_name">{{project.parent_name}} - </span>
							{{project.name}}  <i class="icon-info tooltip-custom" [matTooltip]="project.description" matTooltipPosition="right"></i>
						</td>
						<td>
							<span class="on" *ngIf="project.active == '1'">Added to List</span>
						</td>
						<td>
							<div class="dropdown  pull-right">
								<span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
								<ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
									<li (click)="updateProject(project, 1)">
										<i class="icon-plus"></i> Add to List by Default</li>
									<li (click)="updateProject(project, 0)">
										<i class="fa fa-minus"></i> Remove from List</li>
									<li (click)="deleteProject(project)">
										<i class="icon-trash"></i> Delete</li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="projects.length == 0" class="m-t-md col-sm-10 comp_no_data">
			<div class="searchTableContainer text-center">
				<h4>No Projects Found</h4>
			</div>
		</div>
	</div>
</div> <!-- value category table -->
