import { Injectable } from '@angular/core';

@Injectable ()

export class FactorTranslations {

    public config: any = {
        component: 'factors',
        display_name: 'Factor Solution',
        description: 'All the factors for a solution'
    };

    public trans: any = {
        factors: 'Factors',
        saveAll: 'Save All',
        search: 'Search',
        rowLimit: 'Row Limit',
        includedInBenefits: 'Included in these benefits',
        benefitName: 'Benefit Name',
    };
}
