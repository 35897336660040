import { MenuItem } from 'primeng/api';
import { ValueProp } from '@shared/models/value-prop.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, HostListener, EventEmitter, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AccountService } from '@data/services/account/account.service';
import { Subject } from 'rxjs';
import { UserService } from '@data/services/user/user.service';
import { CustomerShareServices } from '@data/services/customer_share/share.service';
import { DefaultTranslations } from './share.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { Model } from '@data/services/account/models/model.interface';
import { Share } from '@shared/models/share.models';
import { Customer } from '@shared/models/customer.model';
import { FormBuilder, Validators } from '@angular/forms';
import { ErrorMessagesService } from '@services/error-messages.service';

@Component({
  selector: 'app-share-v2',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareComponentV2 implements OnInit, OnDestroy {
  @Input('data') valueProp: ValueProp;
  @Input() showHelp: boolean;
  @Input() showTranslate = false;
  @Input() embedded = false;
  @Input() fromRepSimple = false;
  @Input() set noExternal(value: boolean) {
    if (value) {
      this.manualExternalShareDeactivate = true;
    }
  }

  @Output() callbackBack = new EventEmitter();

  fullImagePath: string;
  sharesLoader = false;
  newCustomerLoader = false;
  existingCustomerLoader = false;
  shares: Share[] = [];
  addShare = false;
  users: User[];
  shareRoles: { id: string; name: string }[] = [];
  share: Model;
  currentJustify = 'center';
  selectedGuest: string;
  guestList: Array<Customer> = [];
  activeIdString = 'Existing_Customer';
  customer = {
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    account_name: '',
  };
  type = 'add';
  user_name: string;
  modalReference: NgbModalRef;
  hideValuePropActionLoader = true;

  view = '';

  ngUnsubscribe = new Subject();

  editing = false;
  user: User;
  user_id: string;

  arrReshare: MenuItem[] = [
    {
      label: 'Yes',
      command: () => {
        this.user.reshare = 'Yes';
      },
    },
    {
      label: 'No',
      command: () => {
        this.user.reshare = 'No';
      },
    },
  ];

  arrAccountView: MenuItem[] = [
    {
      label: 'Visible',
      command: () => {
        this.user.account.view = 'Visible';
      },
    },
    {
      label: 'Hidden',
      command: () => {
        this.user.account.view = 'Hidden';
      },
    },
  ];
  arrAccountEditable: MenuItem[] = [
    {
      label: 'View',
      command: () => {
        this.user.account.editable = 'View';
      },
    },
    {
      label: 'Edit',
      command: () => {
        this.user.account.editable = 'Edit';
      },
    },
  ];

  arrBenefitsView: MenuItem[] = [
    {
      label: 'Visible',
      command: () => {
        this.user.benefits.view = 'Visible';
      },
    },
    {
      label: 'Hidden',
      command: () => {
        this.user.benefits.view = 'Hidden';
      },
    },
  ];
  arrBenefitsEditable: MenuItem[] = [
    {
      label: 'View',
      command: () => {
        this.user.benefits.editable = 'View';
      },
    },
    {
      label: 'Edit',
      command: () => {
        this.user.benefits.editable = 'Edit';
      },
    },
  ];

  arrAssumptionsView: MenuItem[] = [
    {
      label: 'Visible',
      command: () => {
        this.user.assumptions.view = 'Visible';
      },
    },
    {
      label: 'Hidden',
      command: () => {
        this.user.assumptions.view = 'Hidden';
      },
    },
  ];
  arrAssumptionsEditable: MenuItem[] = [
    {
      label: 'View',
      command: () => {
        this.user.assumptions.editable = 'View';
      },
    },
    {
      label: 'Edit',
      command: () => {
        this.user.assumptions.editable = 'Edit';
      },
    },
  ];

  arrCostsView: MenuItem[] = [
    {
      label: 'Visible',
      command: () => {
        this.user.costs.view = 'Visible';
      },
    },
    {
      label: 'Hidden',
      command: () => {
        this.user.costs.view = 'Hidden';
      },
    },
  ];
  arrCostsEditable: MenuItem[] = [
    {
      label: 'View',
      command: () => {
        this.user.costs.editable = 'View';
      },
    },
    {
      label: 'Edit',
      command: () => {
        this.user.costs.editable = 'Edit';
      },
    },
  ];

  arrResultView: MenuItem[] = [
    {
      label: 'Visible',
      command: () => {
        this.user.results.view = 'Visible';
      },
    },
    {
      label: 'Hidden',
      command: () => {
        this.user.results.view = 'Hidden';
      },
    },
  ];

  arrResultsEditable: MenuItem[] = [
    {
      label: 'View',
      command: () => {
        this.user.results.editable = 'View';
      },
    },
    {
      label: 'Edit',
      command: () => {
        this.user.results.editable = 'Edit';
      },
    },
  ];

  userList: any;
  filteredUserList: any;
  email: string;
  name: string;
  foundUser = true;

  deleteUser = false;
  search = '';
  tot = 0;
  savingShare: boolean;
  report = false;
  reportList: any;
  addingInternalUser = false;
  header_color: string;
  loadingUsers = false;
  featureDisableExternalShare = false;
  manualExternalShareDeactivate = false;

  nameEmailForm = this.fb.group({
    name: [''],
    email: ['', [Validators.required, Validators.email]],
    expires: ['', Validators.required],
  });

  errorDict = new Map();
  emailMeFF = false;
  userEmail = '';
  customerCollaboration = this.commonService.checkFeature(149);

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private accountService: AccountService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private userService: UserService,
    private customerShareServices: CustomerShareServices,
    private fb: FormBuilder,
    private errorMessageService: ErrorMessagesService
  ) {
    this.fullImagePath = this.commonService.getLoaderImageUrl();
  }

  ngOnInit(): void {
    this.header_color = localStorage.getItem('header_color');
    this.user_id = sessionStorage.getItem('uid');
    this.userEmail = sessionStorage.getItem('email');
    this.view = 'share';

    this.errorDict = new Map(this.errorMessageService.getFormErrors(this.nameEmailForm.controls));

    this.nameEmailForm.valueChanges.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.nameEmailForm.controls));
    });

    this.nameEmailForm.controls['email'].valueChanges.pipe(debounceTime(600), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.nameEmailForm.controls['email'].markAsTouched();
    });

    this.sharesLoader = true;
    this.loadShares();
    this.getGuestList();
    this.funcGetUserList();

    this.valuepropService
      .getShareRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.shareRoles = response.result.filter((item) => {
            return item.id !== '5';
          });
        }
      });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.featureDisableExternalShare = this.commonService.checkFeature(102) || this.manualExternalShareDeactivate;
    this.emailMeFF = this.commonService.checkFeature(143);
  }
  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.callbackBack.emit();
  }

  funcYesNoConversion(val, v1, v2): string {
    if (val === '1') {
      return v1;
    } else {
      return v2;
    }
  }

  toggleEditShare(user): void {
    this.view = 'share';
    this.report = false;
    this.editing = true;
    this.user = { ...user, is_external: Number(user.is_external) };
    this.user.expires = user.expires ? new Date(user.expires) : '';
    this.user.reshare = this.funcYesNoConversion(user.re_share, 'Yes', 'No');
    this.user.account = {};
    this.user.account.editable = this.funcYesNoConversion(user.account_info_editable, 'Edit', 'View');
    this.user.account.view = this.funcYesNoConversion(user.account_info_view, 'Visible', 'Hidden');

    this.user.benefits = {};
    this.user.benefits.editable = this.funcYesNoConversion(user.benefits_editable, 'Edit', 'View');
    this.user.benefits.view = this.funcYesNoConversion(user.benefits_view, 'Visible', 'Hidden');

    this.user.assumptions = {};
    this.user.assumptions.editable = this.funcYesNoConversion(user.assumptions_editable, 'Edit', 'View');
    this.user.assumptions.view = this.funcYesNoConversion(user.assumptions_view, 'Visible', 'Hidden');

    this.user.costs = {};
    this.user.costs.view = this.funcYesNoConversion(user.costs_view, 'Visible', 'Hidden');
    this.user.costs.editable = this.funcYesNoConversion(user.costs_editable, 'Edit', 'View');

    this.user.results = {};
    this.user.results.view = this.funcYesNoConversion(user.result_view, 'Visible', 'Hidden');
    this.user.results.editable = this.funcYesNoConversion(user.result_editable, 'Edit', 'View');
  }

  funcSearch(event): void {
    const out = this.userList.filter((x) => x.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1);

    this.filteredUserList = out;
    if (this.filteredUserList.length) {
      this.foundUser = true;
    } else if (!this.featureDisableExternalShare && !this.customerCollaboration) {
      this.report = false;
      this.editing = true;
      this.user = {
        is_external: 1,
        email: '',
        user_name: this.email,
        account: { view: 'Visible', editable: 'Edit' },
        benefits: { view: 'Visible', editable: 'Edit' },
        assumptions: { view: 'Visible', editable: 'Edit' },
        costs: { view: 'Visible', editable: 'Edit' },
        results: { view: 'Visible', editable: 'Edit' },
      };
      this.nameEmailForm.controls['name'].setValue(this.email);
      this.foundUser = false;
    }
  }

  funcShowReport(user): void {
    this.user = { ...user, is_external: Number(user.is_external) };
    this.reportList = [];
    this.customerShareServices
      .getReport(this.valueProp.id, user.user_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.report = true;

        this.reportList = result.result.value_prop_log;
      });
  }

  funcGetUserList(): void {
    this.loadingUsers = true;
    this.userService
      .getAllUsersList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.userList = res.result.data;
        this.loadingUsers = false;
      });
  }

  funcAddExistingUser(): void {
    this.addingInternalUser = true;
    this.customerShareServices
      .updateExistingCustomer(this.valueProp.id, this.email as any)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.loadShares();
          this.email = null;
        }
        this.addingInternalUser = false;
      });
  }

  funcAddNewUser(): void {
    const customer = {
      email: this.email,
      first_name: this.name.split(' ')[0],
      last_name: this.name.split(' ')[1],
      share_role_type_id: '',
    };

    this.valuepropService
      .addNewCustomer(this.valueProp.id, customer)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success) {
          this.email = '';
          this.name = '';
          this.notificationService.success(this.trans.trans.customerSuccess.value, false);
          this.loadShares();
        }
      });
  }

  loadShares(): void {
    const valuePropId = this.valueProp.id;
    this.valuepropService
      .getValuePropShares(valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.shares = response.result.shares;
        }
      });
  }

  funcSaveShareSettings(emailMe = false ,bypassValidation = false): void {
    if (this.nameEmailForm.invalid && !bypassValidation) {
      this.nameEmailForm.markAllAsTouched();
      return;
    }
    if (this.user.is_external === 1) {
      this.savingShare = true;

      const payload = {
        email: this.nameEmailForm.value.email,
        customer_email: emailMe ? '0' : '1',
        first_name: this.nameEmailForm.value.name.split(' ')[0],
        last_name: this.nameEmailForm.value.name.split(' ')[1],
        account_info_view: this.user.account.view === 'Visible' ? '1' : '0',
        account_info_editable: this.user.account.editable === 'View' ? '0' : '1',
        benefits_view: this.user.benefits.view === 'Visible' ? '1' : '0',
        benefits_editable: this.user.benefits.editable === 'View' ? '0' : '1',
        assumptions_view: this.user.assumptions.view === 'Visible' ? '1' : '0',
        assumptions_editable: this.user.assumptions.editable === 'View' ? '0' : '1',
        costs_view: this.user.costs.view === 'Visible' ? '1' : '0',
        costs_editable: this.user.costs.editable === 'View' ? '0' : '1',
        result_view: this.user.results.view === 'Visible' ? '1' : '0',
        result_editable: this.user.results.editable === 'View' ? '0' : '1',
        re_share: '0',
        expires: this.nameEmailForm.value.expires,
        is_external: this.user.is_external === 1 ? 1 : 0,
        hash: this.user.hash ? this.user.hash : '',
      };

      if (this.user.hash) {
        this.customerShareServices
          .updateExternalShareSettings(this.valueProp.id, payload)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => (this.savingShare = false))
          )
          .subscribe((response) => {
            if (response.result.success) {
              this.foundUser = true;
              this.editing = false;
              this.loadShares();
              this.notificationService.success(this.trans.trans.vpSaveSuccess.value + this.user.user_name, false);
              this.user = new User();
              this.nameEmailForm.reset();
              this.email = '';
            } else {
              this.notificationService.error(this.trans.trans.vpSaveError.value + this.user.user_name, false);
            }
          });
      } else {
        this.customerShareServices
          .createExternalShareSettings(this.valueProp.id, payload)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => (this.savingShare = false))
          )
          .subscribe((response) => {
            if (response.result.success) {
              this.foundUser = true;
              this.editing = false;
              this.loadShares();
              this.notificationService.success(this.trans.trans.vpSaveSuccess.value + this.user.user_name, false);
              this.user = new User();
              this.nameEmailForm.reset();
              this.email = '';
            } else {
              this.notificationService.error(response.result.message, false);
            }
          });
      }
    } else {
      const payload = {
        share_id: this.user.id,
        share_role_type_id: this.user.share_role_type_id,
      };

      this.customerShareServices
        .updateShareSettings(this.valueProp.id, payload)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          finalize(() => (this.savingShare = false))
        )
        .subscribe(() => {
          this.foundUser = true;
          this.editing = false;
          this.loadShares();
          this.notificationService.success(this.trans.trans.vpSaveSuccess.value + this.user.user_name, false);
          this.user = new User();
          this.nameEmailForm.reset();
          this.email = '';
        });
    }
  }

  getGuestList(): void {
    const account_id = sessionStorage.getItem('aid');
    this.accountService
      .getExistingCustomers(account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result['guests']) {
          this.guestList = response.result['guests'];
        }
      });
  }

  deleteValuePropUser(share): void {
    this.hideValuePropActionLoader = true;

    if (share.is_external === '1') {
      this.customerShareServices
        .deleteExternalShare(this.valueProp.id, share.hash)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result && response.result.success !== false) {
            this.notificationService.success(response.result.message, false);
          } else if (response.result.success === false) {
            this.notificationService.error(response.result.message, false);
          }
          this.editing = false;
          this.foundUser = true;
          this.loadShares();

          share.deleteUser = false;
        });
    } else {
      this.valuepropService
        .deleteShareUser(this.valueProp.id, share.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result && response.result.success !== false) {
            this.notificationService.success(response.result.message, false);
          } else if (response.result.success === false) {
            this.notificationService.error(response.result.message, false);
          }
          this.editing = false;
          this.foundUser = true;
          this.loadShares();

          share.deleteUser = false;
        });
    }
    this.loadShares();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.id === 'startOver1') {
      return;
    }
    this.deleteUser = false;
  }

  onCancel(): void {
    this.editing = false;
    this.user = null;
    this.foundUser = true;
    this.nameEmailForm.reset();
  }
}

export class User {
  email?: string;
  expires?: Date | string;
  reshare?: string;
  first_name?: string;
  last_name?: string;
  account?: { view?: string; editable?: string };
  benefits?: { view?: string; editable?: string };
  assumptions?: { view?: string; editable?: string };
  costs?: { view?: string; editable?: string };
  results?: { view?: string; editable?: string };
  hash?: string;
  is_external: number;
  user_name?: string;
  id?: string;
  uid?: string;
  share_role_type_id?: string;
}
