import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ShareTranslations } from './value-tracker-share.translation';

@Component({
  selector: 'app-value-tracker-share',
  templateUrl: './value-tracker-share.component.html',
})
export class ValueTrackerShareComponent implements OnInit, OnDestroy {
  @Input() valuePropId: any;
  @Output() cb = new EventEmitter();
  @Output() closeCallback = new EventEmitter();

  ngUnsubscribe = new Subject();
  userList: any[] = [];
  selectedUser: any;
  shareList: any[] = [];
  roleList: any[] = [];
  selectedRole: any;

  pageLimit: number = 10;

  constructor(
    private userService: UserService,
    private valueTrackerService: ValuerealizationService,
    private notificationService: NotificationService,
    private valuePropService: ValuepropService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: ShareTranslations,
  ) {}

  ngOnInit():void {
    this.getUserList();
    this.getShareList();
    this.getRoleTypes();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy():void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  getUserList():void {
    this.userService
      .getAllUsersList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.userList = res.result.data;
      });
  }

  getRoleTypes():void {
    this.valuePropService
      .getShareRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.roleList = res.result;
      });
  }

  getShareList():void {
    this.valueTrackerService
      .getValueTrackerShareList(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.shareList = res.result.shares;
      });
  }

  getTranslations():void {
		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

		let payload = {
			account_id: sessionStorage.getItem("aid"),
			component: this.trans.config.component,
			lang: langAbbr
		}
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);;
		});
	}

  postShare():void {
    let payload = {
      share_id: this.selectedUser.id,
      share_role_type_id: this.selectedRole.id,
    };

    this.valueTrackerService
      .postValueTrackerShare(payload, this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getShareList();
        this.selectedUser = null;
        this.notificationService.success('Share Created Successfully', false);
      });
  }

  deleteShare(share_id):void {
    this.valueTrackerService
      .deleteValueTrackerShare(this.valuePropId, share_id.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getShareList();
        this.notificationService.success('Share Deleted Successfully', false);
      });
  }
}
