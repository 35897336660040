<div class='row' style='margin-bottom: 15px;' *ngIf="!addJustCase">
	<div class='col-md-3 col-xs-12'>
		<a routerLink='/dashboard' class='btn btn-danger btn-sm'><i class='fa fa-chevron-left'></i>{{trans.trans.back.value}}</a>
		<a *ngIf="!startOver" id='startOver1' (click)='startOver = !startOver' class='startOver btn btn-warning btn-sm'><i class='fa fa-refresh'></i>{{trans.trans.start_over.value}}</a>
		<a *ngIf="startOver" id='startOver2' (click)='funcStartOver()' class='startOver btn btn-success btn-sm'><i class='fa fa-refresh'></i>{{trans.trans.are_you_sure.value}}</a>
	</div>
	<div class='col-md-6 col-xs-12'>
	</div>
	<div class='col-md-3 col-xs-12'>

		<a (click)='startTour()' class='pull-right link clickable' style='margin-top: 7px;'>{{trans.trans.take_a_tour.value}}</a>
		<i class='fa fa-tasks pull-right' [ngbTooltip]="trans.trans.hide_progress_panel.value" container="body" placement="left" (click)='showProgress = !showProgress' *ngIf="showProgress" style='color: gold; font-size: 24px; float: left; margin-right: 10px; margin-top: 5px;'></i>
		<i class='fa fa-tasks pull-right' [ngbTooltip]="trans.trans.show_progress_panel.value" container="body" placement="left" (click)='showProgress = !showProgress' *ngIf="!showProgress" style='color: inherit; font-size: 24px; float: left; margin-right: 10px; margin-top: 5px;'></i>
		<i class='fa fa-lightbulb-o pull-right' [ngbTooltip]="trans.trans.click_to_hide_help.value" container="body" placement="bottom" (click)='funcShowHelp();' style='margin-right: 10px; margin-top: 2px; font-size: 24px; color: gold' *ngIf="showHelp"></i>
		<i class='fa fa-lightbulb-o pull-right' [ngbTooltip]="trans.trans.click_to_show_help.value" container="body" placement="bottom" (click)='funcShowHelp()' style='margin-right: 10px; margin-top: 2px; font-size: 24px;' *ngIf="!showHelp"></i>
	</div>
</div>

<div class='row'>
	<div class='col-xs-3' *ngIf="!addJustCase">
		<div class='vpNav'>
			<div *ngFor="let step of steps; let i = index; trackBy: trackByFn">
				<button (click)='GotoStep(i)' [disabled]='funcCheckRequired(step)' class='btn btn-default pull-right btn-block' [ngClass]="{'btn-selected': activeIndex == i}" [ngStyle]="{'width': step.step == 1 ? '90%' : step.step == 2?  '80%' : '100%'}">
					{{step.title}} 
					<span *ngIf="step.complete"><i class='fa fa-check'></i></span>
					<span *ngIf="step.badge" class='badge'>{{step.badge}}</span>
				</button>
			</div>
		</div>
	</div>
	<div [ngClass]="[addJustCase ? 'createContent col-xs-12' : showProgress ? 'createContent col-xs-6' : 'createContent col-xs-9']">
		<div #tabOutlet></div>
	</div>
	<div class='col-md-3 col-xs-12' *ngIf="showProgress && !addJustCase">
		<div class="panel panel-white createProgress">
			<div class='panel-heading' style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
				<h4>{{trans.trans.progress.value}} <app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.progress" (callback)="getTranslations()"></app-editTranslation>
				</h4>
			</div>
			<div class='panel-body'>
				<div [hidden]="progressHasData">{{trans.trans.waiting_for_data.value}}<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.waiting_for_data" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div *ngFor="let step of steps">
					<div class='row' style='margin-bottom: 5px;' *ngFor="let item of step.progress">
						<div class='col-xs-12' *ngIf="item.value == '-'">
							<hr>
						</div>
						<div class='col-xs-12' *ngIf="item.value && item.value !== '-'">
							<b>{{item.title}}</b> {{item.value}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ngx-guided-tour></ngx-guided-tour>