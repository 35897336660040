<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title">
            {{ trans.trans.kpiGroups.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.kpiGroups"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
      </div>
    </div>
    <div id="goals-search">
      <div class="col-xs-4 input-group keywest_search">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
        <input type="text" placeholder="Search" class="form-control" [formControl]="search" />
      </div>

      <div id="btn-container" *ngIf="canEdit">
        <a class="btn btn_customizable marg-h" (click)="onOpenGoalGroup()">
          <i class="fa fa-plus"></i>
          {{ trans.trans.addNewGroup.value }}
        </a>
        <a class="btn btn_customizable" (click)="onOpenGoal()"><i class="fa fa-plus"></i>{{ trans.trans.addNewGoal.value }}</a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <p-progressBar *ngIf="isLoading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

      <p-accordion [multiple]="true">
        <p-accordionTab *ngFor="let group of goalGroups$ | async; let i = index; trackBy: groupIdentity" [selected]="!!search.value" [header]="group.name">
          <div class="goal-wrapper">
            <div class="goal-group--actions">
              <app-ellipsis-menu *ngIf="group.id !== unassignedId">
                <ul class="list-group">
                  <li class="list-group-item" (click)="onOpenGoalGroup(group)">
                    {{ trans.trans.edit.value }}
                  </li>
                  <li class="list-group-item">
                    <app-are-you-sure
                      [text]="trans.trans.delete.value"
                      icon=""
                      [message]="trans.trans.areYouSure.value"
                      (callbackYes)="onDeleteGoalGroup(group)"
                    ></app-are-you-sure>
                  </li>
                </ul>
              </app-ellipsis-menu>
            </div>

            <p-table
              *ngIf="group.factors.length > 0; else noRecords"
              [value]="group.factors"
              [columns]="cols"
              styleClass="keywest_table"
              tableStyleClass="keywest_table"
              [globalFilterFields]="['name']"
              [dataKey]="'table-' + group.id"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th></th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="data-rows">
                    {{ rowData.name }}
                    <span [matTooltip]="rowData?.has_scratchpad === 1 ? trans.trans.edit_scratchpad.value : trans.trans.add_scratchpad.value">
                      <i (click)="openScratchpad(rowData)" [style.color]="rowData?.has_scratchpad === 1 ? '#06adad' : 'null'" class="fa fa-calculator clickable"></i>
                    </span>
                  </td>
                  <td>{{ rowData.connected_factors_seprated_by_commas }}</td>
                  <td class="data-rows">
                    {{ rowData.created }}
                  </td>
                  <td style="width: 5%">
                    <app-ellipsis-menu>
                      <ul class="list-group">
                        <li class="list-group-item" (click)="onOpenGoal(rowData)">
                          {{ trans.trans.edit.value }}
                        </li>
                        <li class="list-group-item">
                          <app-are-you-sure
                            [text]="trans.trans.delete.value"
                            icon=""
                            [message]="trans.trans.areYouSure.value"
                            (callbackYes)="onDeleteGoal(rowData)"
                          ></app-are-you-sure>
                        </li>
                      </ul>
                    </app-ellipsis-menu>
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <ng-template #noRecords>
              <app-no-records>{{ trans.trans.noGoals.value }}</app-no-records>
            </ng-template>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>

<p-sidebar styleClass="keywest_sidebar p-sidebar-lg" (onHide)="isSidebarOpen = false" [(visible)]="isSidebarOpen" position="right">
  <ng-container *ngIf="aeElement === 'goal' && isSidebarOpen">
    <app-ae-goals [goalSelected]="goalSelected"
      ><app-ae-goals-form
        (refresh)="getGroups()"
        (close)="isSidebarOpen = false"
        [goalGroups]="goalGroups"
        [goalSelected]="goalSelected"
        [linkedFactorsId]="linkedBenefitsId"
      ></app-ae-goals-form>
    </app-ae-goals>
  </ng-container>
  <ng-container *ngIf="aeElement === 'goal-group' && isSidebarOpen">
    <app-ae-goal-groups (refresh)="getGroups()" (close)="isSidebarOpen = false" [goalGroupSelected]="goalGroupSelected"></app-ae-goal-groups>
  </ng-container>
  <ng-container *ngIf="aeElement === 'scratchpad' && isSidebarOpen">
    <app-ae-scratchpads [factor]="goal" [templateSetting]="'goalFactor'" (callback)="getGroups(); isSidebarOpen = false"></app-ae-scratchpads>
  </ng-container>
</p-sidebar>

<!-- Close overlay from action menu -->
<span #closeOverlay></span>
