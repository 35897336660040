import { Component, OnInit, Input } from '@angular/core';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RCompetitorTranslation } from './r_competitors.translation';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
@Component({
	selector: 'app-r-competitors',
	templateUrl: './r_competitors.component.html'
})
export class RCompetitorsComponent implements OnInit {

	ngUnsubscribe = new Subject();

	sidebar_title: string = "View Competitor";
	showTranslate = false;

	@Input() competitorFormObj = {
		id: undefined,
		description: undefined,
		landmines: undefined,
		theyll_say: undefined,
		their_landmines: undefined,
		our_response: undefined,
		competitor_id: undefined,
		name: undefined,
		account_competitor_id: undefined
	};

	constructor(
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: RCompetitorTranslation
	) {}

	ngOnInit() {

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.sidebar_title = "View Competitor";
	}


	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}



}
