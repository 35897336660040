<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>
						{{ trans.trans.your_master_value_props.value }}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='master-value-prop-list.your_master_value_props'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.your_master_value_props">
						</app-editTranslation>

					</div>
				</div>
				<div class='col-xs-6'>

					<button class="btn btn_customizable pull-right" (click)="openAddValuePosition()" [matTooltip]="trans.trans.addNew.value" matTooltipPosition="below" *ngIf="showAddButtons">
						<i class="fa fa-plus"></i> {{trans.trans.add.value}}</button>
				</div>
			</div>
		</div>
		<div class='row marg-v'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tableValuePosition.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableValuePosition.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
			<div class='col-xs-8'>
				<span class="clickable pull-right" (click)='row.toggle($event)'>{{trans.trans.rowLimit.value}} {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
			</div>
		</div>
	</div>
	<div class='models_page_header_container_small'>
		<div class='row'>
			<div class='col-xs-12'>
				<p-progressBar *ngIf="loading" class='keywest_progressbar' mode="indeterminate"></p-progressBar>
				<p-table #tableValuePosition [columns]="columns" (sortFunction)="funcSort($event)" [customSort]="true" [value]="valueProps" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" [pSortableColumn]="col.field" style='white-space: nowrap;'>
								{{trans.trans[col.field].value}}
								<p-sortIcon [field]="col.field"></p-sortIcon>
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' [key]='col.field'
								[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans[col.field]">
								</app-editTranslation>
							</th>
							<th></th>
						</tr>
					</ng-template>

					<ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
						<tr>
							<td>
								<a routerLink="/mastervalueprop/{{ data.id }}" >{{data.name }}</a>
							</td>
							<td>{{data.total_benefits_fmt}}</td>
							<td>{{data.total_costs_fmt}}</td>
							<td>{{data.created}}</td>
							<td>
								<app-are-you-sure [message]="'Are you sure you want to delete ' + data.name + '?'" text='' icon='fa fa-trash' [class]='"btn btn-danger pull-right"' (callbackYes)='deleteValueProp(data.id)'></app-are-you-sure>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</div>