import { NgModule } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { ModelValueCategoryModule as ModelsV2ValueCategory } from 'app/value-map-maintenance/views/value-category/value-category.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ApplicationPipesModule } from '../pipe/pipe.module';
import { ShareFeatureModule } from '../share-feature/share-feature.module';
import { BreadCrumbModule } from '../shared/breadcrumb/breadcrumb.module';
import { SharedModule } from '../shared/shared.module';
import { MaintenanceSituationsModule } from '../value-map-maintenance/views/situations/situations.module';
import { MaintenanceSituationsModuleV1 } from './account-level-situations-v1/situations.module';
import { AddSolutionComponent } from './add-solution/add-solution.component';
import { AddBenefitModalModule } from './common/add-benefit-modal/add-benefit-modal.module';
import { AddProjectModalComponent } from './common/add-project-modal/add-project-modal.component';
import { AddValueCategoryModalComponent } from './common/add-value-category-modal/add-value-category-modal.component';
import { CreateMapComponent } from './create-map/create-map.component';
import { ModelSituationModule } from './situation/situation.module';
import { SolutionCategoryComponent } from './solution-category/solution-category.component';
import { ModelAssumptionsModule } from './solution-detail/assumptions/assumptions.module';
import { ModelBenefitsModule } from './solution-detail/benefits/benefits.module';
import { AddCaseStudyModule } from './solution-detail/case-studies/add-casestudy/add-case-study.module';
import { ModelCaseStudiesModule } from './solution-detail/case-studies/case-studies.module';
import { ModelCaseStudyDetailModule } from './solution-detail/case-studies/casestudy-detail/casestudy-detail.module';
import { ModelCostsModule } from './solution-detail/cost/cost.module';
import { ModelOverviewModule } from './solution-detail/overview/overview.module';
import { ModelScalersModule } from './solution-detail/scalers/scalers.module';
import { ModelSolutionDetailModule } from './solution-detail/solution-detail/solution-detail.module';
import { ReleaseToPartnersComponent } from './solution-list/release-to-partners/release-to-partners.component';
import { ShareComponent } from './solution-list/share/share.component';
import { SolutionListComponent } from './solution-list/solution-list.component';
import { ValueMapRoutingModule } from './value-map-routing.module';
import { ValuemapsComponent } from './valuemaps/valuemaps.component';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { TabViewModule } from 'primeng/tabview';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValidationModule } from './solution-detail/validation/validation.module';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    MultiSelectModule,
    DropdownModule,
    ModelsV2ValueCategory,
    MaintenanceSituationsModule,
    DLMenubarModule,
    MatTooltipModule,
    AddBenefitModalModule,
    DropdownModule,
    AutoCompleteModule,
    ModelOverviewModule,
    FieldsetModule,
    SidebarModule,
    SharedModule,
    ValueMapRoutingModule,
    ShareFeatureModule,
    BreadCrumbModule,
    AddCompanyModule,
    ApplicationPipesModule,
    ModelScalersModule,
    ModelSolutionDetailModule,
    ModelAssumptionsModule,
    ModelBenefitsModule,
    ModelCostsModule,
    ModelSituationModule,
    ModelCaseStudiesModule,
    ModelCaseStudyDetailModule,
    TableChartModule,
    TableModule,
    MaintenanceSituationsModuleV1,
    DropdownModule,
    MenuModule,
    InputSwitchModule,
    AddCaseStudyModule,
    TabViewModule,
    ValidationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    EllipsisMenuModule,
  ],
  declarations: [
    ValuemapsComponent,
    SolutionCategoryComponent,
    SolutionListComponent,
    AddSolutionComponent,
    CreateMapComponent,
    AddValueCategoryModalComponent,
    ReleaseToPartnersComponent,
    ShareComponent,
    AddProjectModalComponent,
  ],
  providers: [SolutionService, Translations],
  entryComponents: [AddValueCategoryModalComponent, ReleaseToPartnersComponent, AddProjectModalComponent],
  exports: [],
})
export class ValueMapModule {}
