<div class="col-md-6" style="margin-bottom: 10px">
  <div class="models_panel dashboard-card" style="height: 400px">
    <div class="models_page_header_container_small">
      <div class="keywest_header">
        <div class="row">
          <div class="col-xs-10">
            <div class="title">
              {{ trans.trans.valueRealizationHeading.value
              }}<app-editTranslation
                *ngIf="showTranslate"
                (callback)="getTranslations()"
                key="main_dashboard.valueRealizationsHeading"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.valueRealizationsHeading"
              ></app-editTranslation>
            </div>
          </div>
          <div class="col-xs-2" *ngIf="showAddButton">
            <button (click)="addNewSidebar = true" class="btn btn_customizable pull-right">
              <i class="fa fa-plus"></i> {{ trans.trans.addNew.value }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div style="overflow-y: hidden; max-height: 270px">
      <div [hidden]="!loading">
        <app-table-skeleton rowHeight="35px" [columns]="columns" [trans]="trans.trans" [rowLimit]="5"></app-table-skeleton>
      </div>
      <div *ngIf="loading">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>

      <p-table
        #tableSolutionList
        [value]="realizationsCompanies"
        [columns]="columns"
        [rows]="5"
        styleClass="keywest_table"
        tableStyleClass="keywest_table"
        [sortField]="'created'"
        [sortOrder]="-1"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns; index as i" [pSortableColumn]="col.field" [class.centered]="i !== 0">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tracker>
          <tr>
            <td>
              <span class="link" (click)="navToDetails(tracker)" class="clickable">{{ tracker.company_name }}</span>
            </td>
            <td class="centered">
              <span>${{ tracker.expected_fr }}</span>
            </td>
            <td class="centered">
              <span>${{ tracker.realized_fr }}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr *ngIf="!loading" class="not-hover">
            <td [attr.colspan]="columns.length" style="padding-top: 100px">
              <app-no-records width="200px" [records]="realizationsCompanies.length > 0">
                <ng-container *ngIf="realizationsCompanies.length === 0">
                  {{ trans.trans.noRecords.value }}
                </ng-container>
              </app-no-records>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <a routerLink="/valuerealized" class="keywest_seeMore">{{ trans.trans.seeMore.value }}</a>
    <app-editTranslation
      *ngIf="showTranslate"
      (callback)="getTranslations()"
      key="main_dashboard.vr_seeMore"
      [isTranslationV2]="true"
      [component]="trans.config.component"
      [transObj]="trans.trans.vr_seeMore"
    ></app-editTranslation>
  </div>
</div>
<app-value-realized-flyout (onHide)="addNewSidebar = $event" [addNewSidebar]="addNewSidebar"></app-value-realized-flyout>
