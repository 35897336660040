import { DLDragDropModule } from '@shared_components/dd_file_upload/upload.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SupportRoutingModule } from './support-routing.module';
import { SupportComponent } from './support.component';
import { FaqComponent } from './faq/faq.component';
import { IssuesComponent } from './issues/issues.component';
import { SupportService } from '@data/services/support/support.service';
import { SharedModule } from '../shared/shared.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SupportTranslations } from './support.translation';
import { FaqTranslations } from './faq/faq.translation';
import { IssuesTranslations } from './issues/issues.translation';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
@NgModule({
  imports: [CommonModule, SupportRoutingModule, NgbModule, SharedModule, ApplicationPipesModule, DLMenubarModule, DLDragDropModule],
  providers: [SupportService, TranslationsV2Service, SupportTranslations, FaqTranslations, IssuesTranslations],
  entryComponents: [SupportComponent],
  exports: [SupportComponent],
  declarations: [SupportComponent, FaqComponent, IssuesComponent],
})
export class SupportModule {}
