<div class='panel panel-results' style='border-radius: 10px;'>
	<div class='panel-body'>
		<h3 style='margin-top: 0px;'>{{trans.trans.Subtypesof.value}}: {{valueMetric.driver_factor}}</h3>
		<div class="clearfix">
			<table id="benefits" class="table custom-table benefits-table">
				<thead>
					<tr>
						<th>{{trans.trans.Subtype.value}}</th>
						<th>{{trans.trans.Value.value}}</th>
						<th>{{trans.trans.Improvement.value}} [{{valueMetric.abbr}}]</th>
						<th>{{trans.trans.FinancialFactor.value}}</th>
						<th>{{trans.trans.FinancialValue.value}}</th>
						<th></th>
					</tr>
				</thead>
				<tbody *ngIf="benefitsSubTypesList && benefitsSubTypesList!==null && benefitsSubTypesList.length>0">
					<tr *ngFor="let benefitSubType of benefitsSubTypesList">
						<td>
							<input type="text" class="form-control" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.name}}" [(ngModel)]="benefitSubType.name" />
						</td>
						<td [hidden]="true">
							<input type="text" class="form-control" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.driver_factor_id}}" [(ngModel)]="benefitSubType.driver_factor_id" />
						</td>
						<td class="inline">
							<input type="text" class="form-control inlineInput" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.driver_value}}" [(ngModel)]="benefitSubType.driver_value" />
							<span>X</span>
						</td>
						<td class="inline">
							<input type="text" class="form-control inlineInput" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.improvement_value}}" [(ngModel)]="benefitSubType.improvement_value" />
							<span>X</span>
						</td>
						<td class="text">{{benefitSubType.financial_factor}}</td>
						<td>
							<input type="text" class="form-control" [disabled]='!isSubTypeEnabled' [value]="benefitSubType.financial_value" [(ngModel)]="benefitSubType.financial_value" />
						</td>
						<td class="benefitSubTypesClose">
							<span (click)="textClose(benefitSubType.id)">
								<i class="fa fa-times subTypesClose"></i>
							</span>
						</td>
					</tr>
				</tbody>
			</table>
			<div class='row'>
				<div class='col-xs-6 btn-controls'>
					<button class="btn btn-success value-execution-add-a-new-value-proposition-results-benefit-save-button" (click)="saveSubType()" [disabled]='!isSubTypeEnabled' data-heap="value-execution-add-a-new-value-proposition-results-benefit-save-button">{{ trans.trans.save.value}}</button>
					<button class="btn btn-warning value-execution-add-a-new-value-proposition-results-benefit-enable-button" (click)="disableSubType(isSubTypeEnabled)"  data-heap="value-execution-add-a-new-value-proposition-results-benefit-enable-button">
						{{ !isSubTypeEnabled ? trans.trans.enable.value : trans.trans.disable.value }}
					</button>
					<button (click)="sendNotification()" class="btn btn-danger value-execution-add-a-new-value-proposition-results-benefit-cancel-button" data-heap="value-execution-add-a-new-value-proposition-results-benefit-cancel-button">
						{{ trans.trans.Cancel.value }}
					</button>
				</div>
				<div class='col-xs-6'>
					<button class="btn btn-primary pull-right value-execution-add-a-new-value-proposition-results-benefit-add-button" (click)="addSubTypes()" [disabled]='!isSubTypeEnabled' data-heap="value-execution-add-a-new-value-proposition-results-benefit-add-button">
						<span class="fa fa-plus"></span>&nbsp;{{ trans.trans.add.value}}</button>
				</div>
			</div>

		</div>
	</div>
</div>
