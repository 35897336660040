import { Injectable } from '@angular/core';

@Injectable ()

export class BottomPanelTranslations {

    public config: any = {
        component: 'vpDashBottomPanel',
        display_name: 'Value Prop Bottom Panel',
        description: 'Bottom panel in value prop dashboard'
    };

    public trans: any = {
        account: 'Account',
        add_benefit: 'Add Benefit',
        advanced: 'Advanced',
        all_assumptions: 'All Assumptions',
        assets: 'Assets',
        benefits: 'Benefits',
        case_studies: 'Case Studies',
        cashflow: 'Cashflow',
        collateral: 'Collateral',
        costs: 'Costs',
        view_costs: 'View Costs',
        deployment_schedule: 'Deployment Schedule',
        discovery: 'Discovery',
        discovery_questions: 'Discovery Questions',
        initiatives: 'Initiatives',
        key_assumptions: 'Key Assumptions',
        key_figures_and_roi: 'Key Figures & ROI',
        logs: 'Logs',
        mission_statement: 'Mission Statement',
        models: 'Models',
        notes: 'Notes',
        problem: 'Problem',
        questions: 'Questions',
        roi: 'ROI',
        roi_full: 'Key Figures & ROI',
        scalers: 'Scalers',
        scenario: 'Scenario',
        scorecard: 'Scorecard',
        share: 'Share',
        situations: 'Situations',
        solution: 'Solution',
        supporting_materials: 'Supporting Materials',
        tco: 'TCO',
        this_value_prop_is_locked: 'This Value Prop is Locked',
        view_benefits: 'View Benefits',
        edit_and_support: 'Edit & Support Your Value',
        charts_tables: 'Charts, Tables, and Info',
        switch_full_width: 'Switch to New, Full Width Dashboard',
        switch_old_width: 'Switch to Old, 2 Column Dashboard',
        full_width: 'Full Width',
        old_width: 'Old Dashboard',
        valuePrompters: 'Value Prompters',
        finalCustomerDeliverable: 'Final Customer Deliverable',
        goals: 'Goals',
        results: 'Results',
        dataAndAssumptions: 'Data & Assumptions',
        inputs: 'Inputs',
        allFactors: 'All Factors',
    };
}
