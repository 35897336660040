import { Injectable } from '@angular/core';

@Injectable()
export class FaqTranslations {
  public config: any = {
    component: 'faqComponent',
    display_name: 'Frequently Asked Questions',
    description: 'Frequently asked questions',
  };

  public trans: any = {
    search: 'Search',
  };
}
