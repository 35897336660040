import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorInputWrapperComponent } from './error-input-wrapper.component';

@NgModule({
  declarations: [ErrorInputWrapperComponent],
  imports: [CommonModule],
  exports: [ErrorInputWrapperComponent],
})
export class ErrorInputWrapperModule {}
