import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, of } from 'rxjs';
import { CreateSurveyRealizationTranslation } from './create-survey.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { finalize, switchMap, takeUntil, map } from 'rxjs/operators';
import { SurveyTemplateWithQuestions } from '@data/services/valueprop/models/survey-template';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';

@Component({
  selector: 'app-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.scss'],
})
export class CreateSurveyComponent implements OnInit, OnDestroy {
  @Input() surveyId: number;
  @Input() activeSurveyTemplate: SurveyTemplateWithQuestions;
  @Input() account_question_template_id: number;
  @Output() close = new EventEmitter();
  @Input() isTracker = false;
  @Input() isValueRealized = false;
  @Input() valuePropId: string = null;
  @Input() saveDisabled = false;

  surveyDetails: any;
  surveyLoading = false;
  actionLoader = false;

  mode: 'edit' | 'create' = 'create';
  fullImagePath: string;
  image_url: string;
  respondent: string;

  benefitPerformanceType: any;
  benefitPerformanceTypeSel: any;

  customDate;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    private factsService: FactsService,
    private valuerealizationService: ValuerealizationService,
    private notification: NotificationService,
    private activeRoute: ActivatedRoute,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: CreateSurveyRealizationTranslation
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.getSurveyDetail();
    this.getPerformanceTypes();
    this.benefitPerformanceTypeSel = '1';

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  closeSurvey(): void {
    this.close.emit();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getSurveyDetail() {
    this.surveyLoading = true;

    this.valuerealizationService
      .readValueRealizationSurvey(this.surveyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.surveyDetails = response.result;
          this.respondent = this.surveyDetails.respondent;
          this.getCurrentSurveyTemplate();

          this.mode = 'edit';
          if (this.surveyDetails['value_survey_metrics'] && this.surveyDetails['value_survey_metrics'][0]['value_performance_type_id']) {
            this.benefitPerformanceTypeSel = this.surveyDetails['value_survey_metrics'][0]['value_performance_type_id'];
          }

          if (this.surveyDetails['value_survey_response']) {
          } else {
            this.surveyLoading = false;
          }
        }
      });
  }

  getCurrentSurveyTemplate() {
    this.surveyLoading = true;
    let accountId = sessionStorage.getItem('aid');
    /// MG - change goes here!
    this.valuerealizationService
      .getSurveyQuestion(accountId, this.account_question_template_id)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.surveyLoading = false))
      )
      .subscribe((response) => {
        if (response.result) {
          let answerIndex: any[] = [];
          if (this.surveyDetails && this.surveyDetails['value_survey_response']) {
            answerIndex = this.surveyDetails['value_survey_response'].map((item) => {
              return item['account_survey_question_id'];
            });
          }

          let questionWithAnswers = response.result;
          if (answerIndex && answerIndex.length) {
            for (let i = 0; i < questionWithAnswers.length; i++) {
              let index = answerIndex.indexOf(questionWithAnswers[i]['id']);
              if (index != -1) {
                questionWithAnswers[i]['answer'] = this.surveyDetails['value_survey_response'][index]['survey_response'];
              }
              if (questionWithAnswers[i]['question_type_id'] == '2' || questionWithAnswers[i]['question_type_id'] == '5') {
                let options = questionWithAnswers[i]['question_options'];
                if (questionWithAnswers[i]['answer']) {
                  questionWithAnswers[i]['answer'] = questionWithAnswers[i]['answer'].split(',');
                  let answerOption = questionWithAnswers[i]['answer'];
                  for (let j = 0; j < options.length; j++) {
                    if (answerOption.indexOf(options[j]['id']) != -1) {
                      questionWithAnswers[i]['question_options'][j]['checked'] = true;
                    }
                  }
                }
              }
            }
          }

          this.activeSurveyTemplate['questions_list'] = questionWithAnswers;
        } else if (this.activeSurveyTemplate.questions_list && this.surveyDetails?.value_survey_response) {
          this.activeSurveyTemplate.questions_list.map((question) => {
            const answer = this.surveyDetails.value_survey_response.find((response) => response.account_survey_question_id === question.id);
            if (+question.question_type_id === 1 || +question.question_type_id === 3 || +question.question_type_id === 4 || +question.question_type_id === 2) {
              question.answer = answer.survey_response;
              if (+question.question_type_id === 2) {
                question.question_options.map((option) => {
                  if (option.id === question.answer) {
                    option.checked = true;
                  }
                });
              }
            }
          });
        }
      });
  }

  saveSingleSelectOption(question, option) {
    question.question_options.forEach((opt) => {
      opt.checked = option.id === opt.id;
    });
    question.answer = option.id;
  }

  saveSurvey() {
    if (this.isValueRealized && !this.respondent) {
      return;
    }
    this.actionLoader = true;

    let question = this.activeSurveyTemplate.questions_list;
    let value_survey_response = [];

    if (question) {
      for (let i = 0; i < question.length; i++) {
        if (question[i]['answer'] || question[i]['question_options']) {
          if (question[i]['question_options']) {
            let options = question[i]['question_options'];
            question[i]['answer'] = [];
            for (let j = 0; j < options.length; j++) {
              if (options[j].checked) {
                (question[i]['answer'] as any[]).push(options[j]['id']);
              }
            }
            question[i]['answer'] = (question[i]['answer'] as any[]).join(',');
          }

          value_survey_response.push({
            account_survey_question_id: question[i]['id'],
            survey_response: question[i]['answer'],
          });
        }
      }
    }

    let metrics = this.surveyDetails.value_survey_metrics;
    let value_survey_metrics = [];
    if (metrics) {
      for (let i = 0; i < metrics.length; i++) {
        value_survey_metrics.push({
          id: metrics[i]['id'],
          account_solution_metric_id: metrics[i].account_solution_metric_id,
          value_survey_improvement: metrics[i]['value_survey_improvement'],
          value_performance_type_id: this.benefitPerformanceTypeSel,
        });
      }
    }

    let value_prop_id;
    this.activeRoute.params.subscribe((params) => (value_prop_id = params['id']));

    let payload = {
      value_prop_id: this.valuePropId ?? value_prop_id,
      value_survey_id: this.surveyId,
      value_survey_response: value_survey_response,
      value_survey_metrics: value_survey_metrics,
    };

    this.valuerealizationService
      .createSurveyResponse(payload)
      .pipe(
        switchMap((response) => {
          if (this.respondent !== this.surveyDetails.respondent) {
            const respondentPayload = {
              value_survey_id: this.surveyDetails.id,
              name: this.surveyDetails.name,
              quarters_in: this.surveyDetails.quarters_in,
              value_prop_id: this.surveyDetails.value_prop_id,
              custom_survey_date: this.surveyDetails.custom_survey_date,
              respondent: this.respondent,
            };
            return this.valuerealizationService.updateSurveySettings(respondentPayload).pipe(map(() => response));
          } else {
            return of(response);
          }
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((response) => {
        if (response.result) {
          this.notification.success('Survey saved successfully', false);
          this.close.emit();
        }
        this.actionLoader = false;
      });
  }

  getPerformanceTypes() {
    this.factsService
      .getPerformanceTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result['fact_performance_type']) {
          this.benefitPerformanceType = response.result['fact_performance_type'];
          this.benefitPerformanceTypeSel = '1';
        }
      });
  }
}
