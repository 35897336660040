import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { TranslateLoader } from '@ngx-translate/core';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { SidebarModule } from 'primeng/sidebar';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KeyFiguresModule } from '../key_figures/key_figures.module';
import { ScoreCardComponent } from './score-card.component';
import { DefaultTranslations } from './score-card.translation';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [MatTooltipModule, TableChartModule, CommonModule, FormsModule, EditTranslationsModule, SidebarModule, ApplicationPipesModule, KeyFiguresModule],
  declarations: [ScoreCardComponent],
  providers: [DefaultTranslations, TranslationsV2Service],
  exports: [ScoreCardComponent],
})
export class ScoreCardModule {}
