<div class="sidebar_wrapper">
  <div class="sidebar_header">
    <div class="row">
      <div class="col-xs-6"></div>
      <div class="col-xs-6">
        <button class="btn btn_customizable pull-right" style="margin-right: 40px" (click)="openAddShare()"><i class="fa fa-plus"></i>{{ trans.trans.share.value }}</button>
      </div>
    </div>
  </div>
  <div class="sidebar_container">
    <div class="keywest_panel" id="addShares" *ngIf="addShare">
      <form name="shareModelForm" #shareModelForm="ngForm">
        <div class="row">
          <div class="col-xs-12 form-group">
            <div *ngIf="userShare" [ngClass]="{ 'has-error': shareModelForm.controls.share?.invalid && shareModelForm.controls.share?.touched }">
              <label class="labelsRequired">{{ trans.trans.userName.value }}</label>
              <p-dropdown
                appendTo="body"
                [autoDisplayFirst]="false"
                optionLabel="name"
                [options]="users"
                name="share"
                [(ngModel)]="share.share_to_user_id"
                [filter]="true"
                optionValue="id"
                filterBy="name"
                class="keywest_dropdown"
                [panelStyleClass]="'dropdown-item-font'"
                [required]="true"
              ></p-dropdown>
            </div>
            <div *ngIf="!userShare">{{ trans.trans.editing.value }}: {{ share.name }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 form-group" [ngClass]="{ 'has-error': shareModelForm.controls.shareRoleType?.invalid && shareModelForm.controls.shareRoleType?.touched }">
            <label class="labelsRequired">{{ trans.trans.shareRoleType.value }}</label>
            <p-dropdown
              appendTo="body"
              [autoDisplayFirst]="true"
              optionLabel="name"
              [options]="shareRoles"
              name="share"
              [(ngModel)]="share.share_role_type_id"
              optionValue="id"
              class="keywest_dropdown"
              [panelStyleClass]="'dropdown-item-font'"
              [required]="true"
            ></p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 marg-v">
            <button class="btn btn_customizable pull-right" (click)="saveShare()" [disabled]="shareModelForm.invalid">{{ trans.trans.save.value }}</button>
            <button class="btn pull-right cancel-confirm marg-h" (click)="closeAddShare()">{{ trans.trans.cancel.value }}</button>
            <img class="actionLoader pull-right" *ngIf="hideShareActionLoader" [src]="fullImagePath" />
          </div>
        </div>
      </form>
    </div>
    <div class="keywest_panel">
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input [(ngModel)]="strSearch" (keyup)="tableShares.filter($any($event).target.value, 'user_name', 'contains')" type="text" placeholder="Search" class="form-control" />
            <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableShares.filter('', 'user_name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>
      <p-table #tableShares [columns]="cols" [value]="shares" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th style="text-align: end">{{ trans.trans.actions.value }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-share>
          <tr>
            <td>{{ share.solution_name }}</td>
            <td>{{ share.user_name }}</td>
            <td>{{ share.share_role }}</td>
            <td>
              <app-ellipsis-menu>
                <ul class="list-group">
                  <li class="list-group-item" (click)="editPrivilege(share)">{{ trans.trans.edit.value }}</li>
                  <li class="list-group-item">
                    <app-are-you-sure (callbackYes)="deletePrivilege(share)" [text]="trans.trans.delete.value" icon="" [message]="trans.trans.areYouSure.value"></app-are-you-sure>
                  </li>
                </ul>
              </app-ellipsis-menu>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <ng-template #deleteModelPrivilegeModal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">{{ trans.trans.deleteModelPrivilege.value }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ trans.trans.areYouSurePriv.value }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="deletePrivilege(share)">{{ trans.trans.yes.value }}</button>
        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ trans.trans.no.value }}</button>
        <img class="actionLoader" *ngIf="hideDeleteActionLoader" [src]="fullImagePath" />
      </div>
    </ng-template>
  </div>
  <div class="btn_container" *ngIf="!hideShareActionLoader">
    <app-are-you-sure
      #scalerCancel
      matTooltipPosition="above"
      [message]="trans.trans.are_you_sure.value"
      class="btn cancel-confirm pull-right"
      icon=""
      [text]="trans.trans.back.value"
      (callbackYes)="closeShareFlyout()"
    ></app-are-you-sure>
  </div>
</div>
