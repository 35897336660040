import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'assetTagFormat'
})
export class AssetTagPipe implements PipeTransform {
    transform(value: string, suffix?: string): string {
        if (!value) {
            return '';
        }

        const s = suffix ? `_${suffix}` : '';
        return `[onshow.${value}${s}]`;
    }

}
