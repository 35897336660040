import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BenefitDetailComponent } from './benefit-detail.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SubTypesComponent } from '../sub-types/sub-types.component';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { BenefitsRatingModule } from '../rating/rating.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { BenefitsPhasingModule } from '../phasing/phasing.module';
import { FieldsetModule } from 'primeng/fieldset';
import { ScratchPadModule } from '../scratchpad/scratchpad.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { BenefitDetailTranslations } from './benefit-detail.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { SkeletonModule } from 'primeng/skeleton';
import { HeightCacheModule } from '@shared/height-cache/height-cache.module';
import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  imports: [
    DLProgressbarModule,
    DLMenubarModule,
    InputSwitchModule,
    FieldsetModule,
    MatTooltipModule,
    BenefitsPhasingModule,
    ProgressBarModule,
    MenubarModule,
    TableModule,
    SidebarModule,
    CommonModule,
    FormsModule,
    ScratchPadModule,
    AreYouSureModule,
    NgbModule,
    BenefitsRatingModule,
    ApplicationPipesModule,
    EditTranslationsModule,
    FunctionPipeModule,
    SkeletonModule,
    HeightCacheModule,
    TabViewModule,
    OverlayPanelModule,
  ],
  declarations: [SubTypesComponent, BenefitDetailComponent],
  providers: [TranslationsV2Service, BenefitDetailTranslations],
  exports: [BenefitDetailComponent],
})
export class BenefitDetailsModule {}
