import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-collapse-container',
  templateUrl: 'collapsible-container.component.html',
})
export class CollapseContainerComponent {
  @Input() isCollapsible = true;
  @Input() title: string;

  @Output() callback = new EventEmitter<string>();

  show = false;

  onOpen(): void {
    this.show = true;
    this.callback.emit('open');
  }

  onClose(): void {
    this.show = false;
    this.callback.emit('close');
  }
}
