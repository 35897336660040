import { NgModule } from '@angular/core';

import { RQuoteComponent } from "./r_quote.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabViewModule } from 'primeng/tabview';
 
import { MenuModule } from 'primeng/menu';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { AESectionsTranslations } from '../../sections/ae_sections/ae_sections.translation';
import { RQuoteTranslations } from './r_quote.transaltion';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';


@NgModule({
	declarations: [
		RQuoteComponent,
	],
	imports: [
		MenuModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		TabViewModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule
	],
	providers: [
		AESectionsTranslations,
		RQuoteTranslations
	],
	entryComponents: [
		RQuoteComponent,
	],
	exports: [RQuoteComponent]
})
export class RModelQuoteModule { }