import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AreYouSureComponent } from './areyousure.component';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [AreYouSureComponent],
  entryComponents: [AreYouSureComponent],
  imports: [MatTooltipModule, OverlayPanelModule, CommonModule, FormsModule],
  exports: [AreYouSureComponent],
})
export class AreYouSureModule {}
