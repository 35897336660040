import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CompetitorTranslation } from './competitors.translation';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-solutioncompetitors',
	templateUrl: './competitors.component.html',
	styleUrls: ['./competitors.component.scss']
})
export class SolutionCompetitorsComponent implements OnInit, OnDestroy {
	@Input() solutionID;
	@Input() can_edit: boolean = false;

	ngUnsubscribe = new Subject();


	solutionCompetitors: any = [];
	competitors: any = [];
	loadcompetitorTable: boolean = false;
	accountSolutionId: number;

	image_url: string;
	fullImagePath: string;
	competitorName: any;

	addEditThreshold: any;
	loadingSave: boolean = false;
	toggleAddCompetitor: boolean = false;
	dropdown: any[] = [];
	sidebar_title: string = "Add Competitor";
	mode: string = "add";
	strSearch: string = "";
	showTranslate = false;

	cols = [
		{ field: 'name', header: 'Name' }
	]

	@ViewChild('formAddCompetitor') formAddCompetitor: NgForm;

	competitorFormObj = {
		id: undefined,
		description: undefined,
		landmines: undefined,
		theyll_say: undefined,
		their_landmines: undefined,
		our_response: undefined,
		competitor_id: undefined,
		account_competitor_id: undefined,
		name: undefined,

	};
	style2022$: Observable<boolean>;

	constructor(
		private solutionService: SolutionService,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private notification: NotificationService,
		private translationService: TranslationsV2Service,
		public trans: CompetitorTranslation,
		private styleService: StyleService
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {

		this.style2022$ = this.styleService.style2022;
		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.route.params.subscribe(params => this.accountSolutionId = params['id']);

		if (!this.accountSolutionId) {
			this.accountSolutionId = this.solutionID;
		}
		this.getCompetitorList();
		this.getCompetitorsByAccount();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}


	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	funcBuildMenu(elem, menu, event) {
		this.dropdown = [
			{
				label: "Edit", icon: "fa fa-pencil", command: () => {
					this.showEditCompetitor(elem);
				}
			},
			{
				label: "Delete", icon: "fa fa-times", command: () => {
					this.deleteCompetitors(elem.id)
				}
			},
		]
		menu.toggle(event)
	}

	funcToggleView(elem) {
		this.toggleAddCompetitor = true;
		this.competitorFormObj = Object.assign({}, elem);
	}

	funcShowAddCompetitor() {
		this.toggleAddCompetitor = !this.toggleAddCompetitor;
		this.sidebar_title = "Add Competitor";
		for (var k in this.competitorFormObj) { this.competitorFormObj[k] = null; }
		this.mode = "add";
	}

	showEditCompetitor(elem) {
		this.sidebar_title = "Edit Competitor";
		this.toggleAddCompetitor = true;
		this.competitorFormObj = Object.assign({}, elem);
		this.mode = "edit";
	}

	getCompetitorsByAccount() {
		let aid = sessionStorage.getItem('aid')
		this.solutionService.getCompetitors(aid).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.competitors = result.result.data;
		});
	}

	getCompetitorList() {
		this.loadcompetitorTable = true;
		this.solutionService.getSolutionCompetitorList(this.accountSolutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.solutionCompetitors = response.result;
			} else {
				this.solutionCompetitors = [];
			}
			this.loadcompetitorTable = false;
		});
	}

	deleteCompetitors(id) {
		this.loadcompetitorTable = true;
		this.solutionService.deleteSolutionCompetitor(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.notification.success("Competitor deleted successfully", false); // Params {message, islogout}
			this.loadcompetitorTable = false;
			this.getCompetitorList();
		});
	}
}
