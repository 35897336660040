import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';

import { Notification, NotificationService } from '@services/notification.service';
import { Router } from '@angular/router';
import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SolutionService } from '@data/services/solution/solution.service';

@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  public showNotification: boolean = false;
  public subscription: Subscription;
  public timer: Observable<number>;
  public notification: Notification;
  public tokenExpired: boolean = false;

  subscriptionNotificationService: Subscription;

  xdl: string = '';
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private translationService: TranslationsV2Service,
    private solutionService: SolutionService
  ) {}

  ngOnInit() {
    this.subscriptionNotificationService = this.notificationService.getNotification().subscribe((data: Notification) => {
      this.notification = data;

      this.showNotification = true;

      this.timer = timer(3000);
      this.subscription = this.timer.subscribe(() => {
        this.showNotification = false;
        this.clearTimer();
        let url = localStorage.getItem('servicesURL') ? localStorage.getItem('servicesURL') : '';

        if (this.notification['logout'] === true) {
          if (sessionStorage.getItem('xdl_referer')) {
            this.xdl = sessionStorage.getItem('xdl_referer');
          }
          this.translationService.destroyTranslationCache();
          this.solutionService.destroySituationTreeMap();
          sessionStorage.clear();
          localStorage.clear();
          this.tokenExpired = true;
          this.notificationService.error('Your session has expired. Please login again', true);
          if (url) {
            localStorage.setItem('servicesURL', url);
          }

          heapReset();
          localStorage.removeItem('AnalyticsSession');
          // if (this.xdl !== null && this.xdl !== undefined) {
          if (this.xdl && this.xdl !== "") {
              window.location.href = this.xdl + '/mikey.html';
            } else {
              this.router.navigate(['/login']);
              window.location.reload();
            }
        }
      });
    });
  }

  private clearTimer() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionNotificationService) {
      this.subscriptionNotificationService.unsubscribe();
    }
  }
}
