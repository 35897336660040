<p-sidebar [(visible)]='toggleMaintenance' styleClass="thresholds_sidebar p-sidebar-xl" position='bottom'>
	<div class='row'>
		<div class='col-xs-12'>
			<h3>Models Support</h3>
		</div>
	</div>
	<div class='row'>
		<div class='col-xs-12'>
			<div class='well well-info'>
				Use the below sections to help build and support your model.
			</div>
		</div>
	</div>

	<dl-menubar styleClass='details_menu_bar' class='details_menu_bar' [model]="items"></dl-menubar>

	<div class='maintenance_container'>
		<div class='row'>
			<div class='col-xs-12'>
				<app-maintenance-situations [solutionId]='solutionId' *ngIf="view == 'situations'"></app-maintenance-situations>
				<app-value-map-pain-points *ngIf="view == 'pain_points'" [can_edit]='canEdit'></app-value-map-pain-points>
				<app-value-capabilities *ngIf="view == 'capabilities'" [can_edit]='canEdit'></app-value-capabilities>
				<app-value-map-op-objectives *ngIf="view == 'operational_objectives'" [can_edit]='canEdit'></app-value-map-op-objectives>
				<app-value-map-func-objectives *ngIf="view == 'functional_objectives'" [can_edit]='canEdit'></app-value-map-func-objectives>

			</div>
		</div>
	</div>
</p-sidebar>