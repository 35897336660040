import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2FuncObjectiveTranslations {
  public config: any = {
    component: 'func',
    display_name: 'Functional Objectives',
    description: 'Models Functional Objectives'
  };

  public trans: any = {
    func_objectives: 'Functional Objectives',
    add_new: 'Add New',
    ch_delete: 'Delete',
    ch_edit: 'Edit',
    ch_view: 'View',
    are_you_sure: 'Are you Sure?'
  };
}
