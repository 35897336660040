import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { AssetPrompterVpDashTranslations } from './asset-value-prompter.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-vpdashboard-assets-prompters',
	templateUrl: './assets-value-prompter.component.html'
})
export class AssetsValuePrompterComponent implements OnInit, OnDestroy {
	@Input() showHelp: boolean;
	@Input() contextualHelp: boolean;
	@Input() valuePropId: string | number;
	@Input() isDev: boolean;
	@Input() showTranslate = false;

	ngUnsubscribe: Subject<any> = new Subject();
	file: File;
	file_param = { name: '', description: '', location: '' };
	prompters: {[klass: string]: any}[] = [];
	prompter: {[klass: string]: any};
	uploadToggle = false;
	editToggle = false;
	assetUrl = "";

	constructor(
		private translationService: TranslationsV2Service,
		public trans: AssetPrompterVpDashTranslations,
		private commonService: CommonService,
		private valuePropService: ValuepropService,
	) {

	}

	ngOnInit() {

		  this.getTranslations();
		  this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		  });
		  this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		  });

		this.prompter = {};
		this.prompters = [];
		this.assetUrl = this.commonService.getAssetUrl();
		this.funcGetPrompters();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

	funcGetPrompters() {
		this.valuePropService.readValuePrompter(this.valuePropId).subscribe((response) => {
			this.prompters = response.result;
		});
	}

	handleInputChange(event) {
		this.file = event.target.files.item(0);
	}

	funcUploadPrompter() {
		let payload = new FormData();

		if (this.file) {
			payload.append('file', this.file);
			payload.append('name', this.file_param['name']);
			payload.append('description', this.file_param['description']);
		}
		this.valuePropService.uploadValuePrompter(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.file_param = { name: '', description: '', location: '' };
			this.funcGetPrompters();
			this.funcOpenAddPrompter();
			this.resetFileFileParam();
		});

	}

	funcUpdatePrompter() {
		let payload: FormData = new FormData();

		if (this.file) {
			payload.append('file', this.file);
		}

		payload.append('prompter_id', this.file_param['id']);
		payload.append('name', this.file_param['name']);
		payload.append('description', this.file_param['description'])

		this.valuePropService.updateValuePrompter(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.resetFileFileParam();
			this.funccloseEditPrompter();
			this.funcGetPrompters();
		});
	}

	funcDeletePrompter(id) {
		this.valuePropService.deleteValuePrompter(this.valuePropId, id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {

			this.funcGetPrompters();
		});

	}

	funcOpenAddPrompter() {
		this.uploadToggle = !this.uploadToggle;
	}

	funccloseEditPrompter() {
		this.editToggle = !this.editToggle;
		this.resetFileFileParam();
	}

	resetFileFileParam() {
		this.file = null;
		this.file_param = { name: '', description: '', location: ''};
	}

	refreshTranslation() {
		this.getTranslations();

	}

	funcOpenEditPrompter(prompter) {
		this.editToggle = true;
		this.file_param = prompter;
	}

}
