import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FactorsService } from '@data/services/factors/factors.service';
import { UsedBenefitFactor } from '@shared/models/factors.models';
import { DefaultTranslations } from '../ae_factors.translation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-factors-used-table',
  templateUrl: './factors-used-table.component.html',
  styleUrls: ['./factors-used-table.component.scss']
})
export class FactorsUsedTableComponent implements OnInit, OnDestroy {
  @Input() factorId: string;
  @Input() trans: DefaultTranslations;

  public usedBenefitFactors: UsedBenefitFactor[];

  private unsubscribe$ = new Subject<void>();

  constructor(private factorService: FactorsService) { }

  ngOnInit(): void {
    if(this.factorId) {
      this.factorService.getBenefitsUsingFactor(this.factorId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if(data && data.result) {
          this.usedBenefitFactors = data.result;
          if(this.usedBenefitFactors && this.usedBenefitFactors.length) {
            this.usedBenefitFactors.forEach(b => b.is_scratchpad = data.result.is_scratchpad_factor);
            this.usedBenefitFactors.sort((a,b)=> (a.model_name > b.model_name ? 1 : -1));
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}