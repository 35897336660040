import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateStringToDate',
})
export class DateStringToDatePipe implements PipeTransform {
  transform(dateString: string): Date {
    return new Date(dateString);
  }
}
