import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RealizedFilter, ValueRealizedCompany } from '@data/services/valuerealization/models/value-realized-company.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { ValueRealizedHomeTranslation } from 'app/value-realized/views/value-realized-home/value-realized-home.translation';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-value-realized',
  templateUrl: './value-realized.component.html',
  styleUrls: ['./value-realized.component.scss'],
})
export class ValueRealizedComponent implements OnInit, OnDestroy {
  public realizationsCompanies: ValueRealizedCompany[] = [];
  public loading = false;
  public showTranslate = false;
  public addNewSidebar = false;
  public tableRecords$: Observable<number>;
  public currentTableRecords$ = new Subject<number>();
  public sharedWith: { label: string; value: RealizedFilter }[];
  public columns = [
    { header: this.trans.trans.name, field: 'company_name' },
    { header: this.trans.trans.expected, field: 'expected' },
    { header: this.trans.trans.realized, field: 'realized' },
  ];
  public showAddButton: boolean = true;

  private unsubscribe = new Subject();
  private creator_filter: RealizedFilter = RealizedFilter.all;

  constructor(
    private valuerealizationService: ValuerealizationService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private router: Router,
    public trans: ValueRealizedHomeTranslation
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getValueRealizationWithCompany(this.creator_filter);

    if (this.commonService.checkFeature(41)) { 
      this.showAddButton = false; 
    }
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sharedWith = [
          { label: this.trans.trans.all.value, value: RealizedFilter.all },
          { label: this.trans.trans.mine.value, value: RealizedFilter.mine },
          { label: this.trans.trans.shared.value, value: RealizedFilter.sharedWithMe },
        ];
      });
  }

  private getValueRealizationWithCompany(filter: RealizedFilter): void {
    this.creator_filter = filter;
    this.loading = true;
    this.valuerealizationService
      .getValueRealizationsWithCompanies(this.creator_filter)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        this.realizationsCompanies = response.result;
        this.currentTableRecords$.next(this.realizationsCompanies.length);
      });
  }

  public navToDetails(tracker): void {
    this.router.navigate([`valuerealized/company/${tracker.id}/trackers`]);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
