import { Injectable } from '@angular/core';

@Injectable()
export class CollaborationTranslations {
  public config: any = {
    component: 'collaboration',
    display_name: 'Collaboration',
    description: 'Customer Collaboration',
  };
  public trans: any = {
    collaborateWithCustomer: 'Collaborate with Customer',
    addDetails: 'Add the details of the customer and choose what to share',
    shareWith: 'Share with',
    name: 'NAME',
    addName: 'Add name',
    email: 'EMAIL',
    addEmail: 'Add email',
    expirationDate: 'EXPIRATION DATE',
    expirationPlaceholder: 'dd/mm/yyyy',
    whatToShare: 'What to share',
    include: 'Include?',
    discoveryQuestions: 'Discovery Questions',
    outcomes: 'Outcomes / Benefits',
    keyAssumptions: 'Key Assumptions',
    assets: 'Assets',
    who: 'Who',
    noCollaborationsFound: 'No Customer Collaborations Found',
    search: 'Search',
    areYouSure: 'Are you sure?',
    addCollaboration: 'Add Collaboration',
    confirm: 'Confirm',
    saveSuccess: 'Customer Collaboration Share updated successfully for ',
    saveError: 'Customer Collaboration Share cannot be created for ',
    assetRequired: 'Asset is enabled but no asset has been chosen. Please choose an asset or disable Asset checkbox.',
    allRequiredValues: 'Please enter all required fields.',
    delete: 'Delete',
    back: 'Back to list',
  }

}