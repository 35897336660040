import { Pipe, PipeTransform } from '@angular/core';
import { AssetLog } from '@data/services/asset/asset-log.model';

@Pipe({
  name: 'filterEvents',
})
export class FilterEventsPipe implements PipeTransform {
  transform(events: AssetLog[], searchString: string, verbTranslations: { [actionId: string]: string }): AssetLog[] {
    if (!searchString) {
      return events;
    } else {
      const lowerCaseSearchStr = searchString.toLowerCase();
      return events.filter(
        (event) =>
          event.asset_template_name.toLowerCase().includes(lowerCaseSearchStr) ||
          event.user_name.toLowerCase().includes(lowerCaseSearchStr) ||
          verbTranslations[event.action_id].toLowerCase().includes(lowerCaseSearchStr)
      );
    }
  }
}
