import { Injectable } from '@angular/core';

@Injectable()
export class ValueRealizationDashboardTranslations {
  public config: any = {
    component: 'valueRealizationDashboard',
    display_name: 'Value Realization Dashboard',
    description: 'Value realization in main dashboard',
  };

  public trans: any = {
    vr_name: 'Name',
    vr_expected: 'Expected',
    vr_realized: 'Realized',
    vr_noValueRealizations: 'No Value Realizations Found',
    vr_notLicensedForValueRealizations: 'Please wait as your Value Realizations load.',
    valueRealizationsHeading: 'Recent Value Realizations (Different from VR from Insight)',
    valueRealizationHeading: 'Recent Value Realizations',
    add: 'Add',
    vr_seeMore: 'See More',
  };
}
