import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { SCVPGQ2Component } from './q2.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { Translations } from '@data/services/translation/translation.service';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeModule } from 'primeng/tree';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScenarioCombinedQuestionsTranslation } from './sc_q2.translation';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    ProgressBarModule,
    AreYouSureModule,
    TreeModule,
    NgbModule,
    MatTooltipModule,
    SidebarModule,
    DropdownModule,
    FieldsetModule,
    TableChartModule,
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    EditTranslationsModule,
  ],
  declarations: [SCVPGQ2Component],
  providers: [Translations, ScenarioCombinedQuestionsTranslation],
  exports: [SCVPGQ2Component],
})
export class SCVPGQ2Module {}
