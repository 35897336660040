import { Injectable } from '@angular/core';

@Injectable ()

export class CaseStudiesTranslations {
    
    public config: any = {
        component: 'casSudies',
        display_name: 'Case Studies',
        description: 'Case Studies for solution'
    };

    public trans: any = {
        caseStudies: 'Case Studies',
        name: 'Name',
        dateCreated: 'Date Created',
        testimonials: 'Testimonials',
        sections: 'Sections',
        benefits: 'Benefits',
        addNew: 'Add New',
        edit: 'Edit',
        download: 'Download',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
        backToCaseStudiesList: 'Back to Case Studies List',
        search: 'Search',
        addCaseStudy: 'Add New Case Study',
        editCaseStudy: 'Edit Case Study',
        edit_translations: 'Edit Translations'
    };
}
