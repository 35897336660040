import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { ChartData, ChartOptions } from 'chart.js';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompetitiveTCOTranslations } from './competitive-tco.translation';

@Component({
	selector: 'app-competitive-tco',
	templateUrl: './competitive-tco.component.html',
	styleUrls: ['competitive-tco.component.scss']
})
export class CompetitiveTcoComponent implements OnInit, OnDestroy {
	@Input() valueProp: ValueProp;
	@Input() embedded = false;
	@Input() showTranslate = false;

	@Input() menu: any;
  @Output() back = new EventEmitter();

	competitiveData = {labels: [], columns: []};
	feature76 = false;
	showAllMetrics = false;
	loading = false;
	chartData: ChartData;
	chartOptions: ChartOptions;
	ngUnsubscribe: Subject<any> = new Subject();

	constructor(
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		private translationService: TranslationsV2Service,
		public trans: CompetitiveTCOTranslations,
		public RS: RepSimpleViewService,
	) { }

	ngOnInit() {
		if (this.embedded) {

			let done = true;
			for (let i = 0; i < 4; i++) {
				if (!this.menu[i].done) { done = false; }
			}

			if (this.valueProp.done == '1') {
				done = true;
			}

			if (done) {
				let payload2 = { done: 1 }
				this.RS.putDone(this.valueProp.id, payload2).pipe(take(1)).subscribe(() => {
					this.valueProp.done = '1';
					for (let i = 0; i < 4; i++) {
						this.menu[i].done = true;
					}
				})
			}
		}


		this.getCompetitiveTco(this.valueProp.id);
		this.feature76 = this.commonService.checkFeature(76);

		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	getCompetitiveTco(id) {
		this.loading = true;
		setTimeout(() => {

			this.valuepropService.getCompetitiveTCO(id, this.showAllMetrics == true ? 1 : 0).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
				this.competitiveData['labels'] = result.result.rows[0];
				let tmp = [];
				for (let i = 1; i < result.result.rows.length; i++) {
					tmp.push(result.result.rows[i]);
				}
				this.competitiveData['columns'] = tmp;

				let dataset = [];
				let chartColors = this.commonService.getChartColors();
				for (let i = 0; i < result.result.chart_names.length; i++) {
					let elem = result.result.chart_names[i];
					let d = result.result.hard_costs_chart[i]
					let data = { label: elem, data: [d], backgroundColor: chartColors[i] }
					dataset.push(data);
				}
				this.chartData = {
					datasets: dataset
				}
				this.chartOptions = {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								callback: function (value, index, values) {
									let number = +value;
									let numberLength = number.toString().length;
									let unit = '';

									if (numberLength >= 4 && numberLength <= 6) {
										unit = 'K';
									}
									else if (numberLength >= 7 && numberLength <= 9) {
										unit = 'M';
									}
									else if (numberLength >= 10 && numberLength <= 13) {
										unit = 'B';
									}

									switch (unit) {
										case 'K':
											return number / 1e3 + unit;
										case 'M':
											return number / 1e6 + unit;
										case 'B':
											return number / 1e9 + unit;
										default:
											return number;
									}
								}
							}
						}]
					},
					tooltips: {
						callbacks: {
							label: function (tooltipItem, data) {
								var label = data.datasets[tooltipItem.datasetIndex].label || '';
								if (label) {
									label += ': ';
								}

								let number = data.datasets[tooltipItem.datasetIndex].data[0];
								let numberLength = number.toString().length;
								let out = "";
								let unit = '';

								if (numberLength >= 4 && numberLength <= 6) {
									unit = 'K';
									out = parseInt(number.toString()) / 1e3 + unit;
								}
								else if (numberLength >= 7 && numberLength <= 9) {
									unit = 'M';
									out = parseInt(number.toString()) / 1e6 + unit;
								}
								else if (numberLength >= 10 && numberLength <= 13) {
									unit = 'B';
									out = parseInt(number.toString()) / 1e9 + unit;
								}
								label += out;
								return label;
							}
						}
					},
				};
				this.loading = false;
			});
		}, 500);
	}
}
