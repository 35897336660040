import { Injectable } from '@angular/core';

@Injectable()
export class ValueTrackerDetailTranslations {
  public config: any = {
    component: 'trackerDetail',
    display_name: 'Value Realization Detail',
    description: 'Detail of a value realization',
  };

  public trans: any = {
    valueRealizationDetail: 'Value Realization Detail',
    metric_name: 'Benefit Name',
    active: 'Status',
    achieved: 'Achieved',
    expected: 'Expected',
    expectedToDate: 'Expected to Date',
    na: 'N/A',
    activeStatus: 'Active',
    all: 'All',
    survey: 'Survey',
    costs: 'Costs',
    caseStudies: 'Case Studies',
    createSurvey: 'Create Survey',
    achievedValue: 'Achieved Value',
    roi: 'ROI',
    name: 'Name',
    selectDate: 'Select a Date for the survey',
    addSurvey: 'Add Survey',
    benefit: 'Benefit',
    addBenefit: 'Add Benefit',
    payback: 'Payback',
    npv: 'NPV',
    vasluVsAchieved: 'Value vs Achieved',
    realizedPercent: 'Realized to date in (Percent) ',
    realizedDollars: 'Realized to date in (Currency)',
    rowLimit: 'Row Limit:',
    notifyMarketing: 'Notify Marketing',
    download: 'Download',
    owner: 'Realization Owner',
    linkToBusiness: 'Link to Business Case',
    months: 'Months',
    cancel: 'Cancel',
    back: 'Back',
    contractDate: 'Baseline Date:',
    shareNew: 'Share',
    selectTemplate: 'Select Template',
    selectADate: 'Select a Date',
    save: 'Save',
    are_you_sure: 'Are you sure?',
    shareTooltip: 'Share this Tracker with another Employee',
    surveyCreated: 'Survey created successfully',
  };
}
