import { throwError as observableThrowError, Observable, interval, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common/common.service';

import { NotificationService } from '@services/notification.service';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { Term } from '@data/services/account/models/term.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JsonObject } from '@angular-devkit/core';
import { APIResult } from '@shared/models/api-result.model';
import { CompetitorRequest, CompetitorsResponse } from '@shared/models/competitors-data.models';
import { CompanySearchPayload, CompanySearchResponse } from '@shared/models/company-data.models';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { Partner, PartnerGroup, PartnerPayload, PartnerQueryResult, PartnersFieldData, PartnerTypePayload } from '@shared/models/partner.model';
import { PrivilegePayload, Privileges } from '@shared/models/privileges.model';
import { Customer } from '@shared/models/customer.model';
import { BasicInfo } from '@shared/models/basic-info.model';
import { BenefitOnFly, BenefitRemoved, LowestRatedBenefit } from '@shared/models/benefits.models';
import { EscalerFactor, SolutionFactor } from '@shared/models/factors.models';
import { AccountStageResponse, AccountStageUpdate } from '@shared/models/account-stage.models';
import { AccountQuestionsResponse } from '@shared/models/account-question.model';
import { LMSPathResponse } from '@shared/models/lms-path.model';
import { DiscoveryQuestion, DiscoveryQuestionRequest, DiscoveryQuestionTag } from '@shared/models/discovery-question.model';
import { AdoptionReport, AdoptionReportRequest, ExcludeUsersPayload, ExcludeUsersResponse } from '@shared/models/adoption-report.models';
import { AccountColors, ChartColor, SetAccountColorsPayload, SetChartColorPayload } from '@shared/models/account-colors.models';
import { CurrencyRate, CurrencyRatesPayload, CurrencyRatesResponse } from '@shared/models/currency-rates.models';
import { Account } from '@data/services/account/models/account.interface';
import { ExcludedUser } from '@shared/models/user-info.model';
import { SequencePage, SequencePayload, SequenceResPayload } from './models/sequence.interface';
import { TranslationsV2Service } from '../translationsv2/translationsv2.service';
import { AssetGenerator } from './models/asset-generator.interface';
import { SolutionService } from '../solution/solution.service';
import { BenefitElement } from 'app/value-mapv2/solution-detail/benefits/benefits.model';
import { FactorWACC, WACCFactorPayload } from './models/factor-wacc.interface';
import { AggregatedServices } from './models/aggregated-services.interface';
import { BulkUpdateAPIResult } from '@shared/models/bulk-update.models';

@Injectable()
export class AccountService {
  serviceUrl: string;
  endpoint: string;
  unsubInterval: Subscription;
  supportUrl$ = new Subject<string>();
  communityUrl$ = new BehaviorSubject<string>(null);
  lmsPath$ = new BehaviorSubject<string>(null);
  xdl: string = '';

  constructor(
    private commonService: CommonService,
    private router: Router,
    private http: HttpClient,
    private notification: NotificationService,
    private translationService: TranslationsV2Service,
    private solutionService: SolutionService
  ) {
    this.serviceUrl = this.commonService.getServiceUrl();
    this.endpoint = this.serviceUrl + '/accounts/Account.Service_VC.php';
  }

  public jsonToUrlParam(data: JsonObject) {
    const params = new HttpParams();
    for (let key in data) {
      params.set(key, String(data[key]));
    }
    return params;
  }

  public getSigmaUrl( key_suffix?: string ): Observable<APIResult<any>> {
    let param = '';
    if ( key_suffix ) {
      param = '?key_suffix=' + key_suffix;
    }

    const endpoint    = 'account/analyticsUrl' + param;

    return this.commonService.getAPIService(endpoint);
    
  }

  public getBulkUpdateLogs(): Observable<APIResult<BulkUpdateAPIResult>> {
    const endpoint = 'account/bulkUpdateLogs';
    return this.commonService.getAPIService(endpoint);
  }

  public getCompetitors(queryString: string): Observable<APIResult<CompetitorsResponse>> {
    const accountId = sessionStorage.getItem('aid');
    const endpoint = 'account/competitor/' + accountId + queryString;
    return this.commonService.getAPIService(endpoint);
  }

  public createCompetitors(params: CompetitorRequest): Observable<APIResult<string>> {
    const endpoint = 'account/competitor/create';
    return this.commonService.postAPIService(endpoint, params);
  }

  public getSearchCompanies(params: CompanySearchPayload): Observable<APIResult<CompanySearchResponse>> {
    const endpoint = 'companies/search/';
    return this.commonService.postAPIService(endpoint, params);
  }

  public setSupportEmail(support_email: string): Observable<APIResult<SuccessFailResponse>> {
    const payload = { support_email };
    const endpoint = 'account/supportEmail';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getSupportEmail(): Observable<APIResult<SuccessFailResponse>> {
    const endpoint = 'account/supportEmail';
    return this.commonService.getAPIService(endpoint);
  }

  public getSupportUrl(): void {
    const endpoint = 'account/supportUrl';

    this.commonService.getAPIService(endpoint).subscribe((response) => {
      if (response.result.data) {
        this.supportUrl$.next(response.result.data);
      }
    });
  }
  getAccountDetails(accountId: string): Observable<Account> {
    const endpoint = 'account/accountDetails/' + accountId;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public setSupportUrl(support_url: string): Observable<APIResult<SuccessFailResponse>> {
    const payload = { support_url };
    const endpoint = 'account/supportUrl';
    return this.commonService.putAPIService(endpoint, payload).pipe(
      tap((response) => {
        if (response.result) {
          this.supportUrl$.next(support_url);
        }
      })
    );
  }

  public setCommunityUrl(community_url: string): Observable<APIResult<string>> {
    const payload = { community_url };
    const endpoint = 'account/communityUrl';
    return this.commonService.putAPIService(endpoint, payload).pipe(
      tap((response) => {
        if (response.result) {
          this.communityUrl$.next(community_url);
        }
      })
    );
  }

  public getCommunityUrl(): Observable<APIResult<string>> {
    const endpoint = 'account/communityUrl';
    return this.commonService.getAPIService(endpoint).pipe(
      tap((response) => {
        if (response.result) {
          this.communityUrl$.next(response.result);
        }
      })
    );
  }

  setAssetGenerator(assetGenerator: AssetGenerator): Observable<APIResult<unknown>> {
    const endpoint = 'account/assetGeneratorFile';
    return this.commonService.putAPIService(endpoint, { asset_gen_file: assetGenerator }).pipe(
      tap(() => {
        sessionStorage.setItem('asset_gen_file', assetGenerator);
      })
    );
  }

  public updateCompetitors(params: CompetitorRequest, competitorId: string): Observable<APIResult<string>> {
    const endpoint = 'account/competitor/' + competitorId;
    return this.commonService.putAPIService(endpoint, params);
  }

  public deleteCompetitors(competitorId: string): Observable<APIResult<string>> {
    const endpoint = 'account/competitor/' + competitorId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public deletePartners(partnerId: string): Observable<APIResult<string>> {
    const endpoint = 'account/partner/' + partnerId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getPartnersFieldData(accountId: string, type: string): Observable<APIResult<PartnersFieldData & Partner[]>> {
    let endpoint = '';
    if (type === 'owners') {
      endpoint = 'account/users/' + accountId;
    } else if (type === 'accoutnPartners') {
      endpoint = 'account/partner/' + accountId;
    } else if (type === 'partners') {
      endpoint = 'account/availablepartners/' + accountId;
    } else {
      endpoint = 'account/partnertype/' + accountId;
    }
    return this.commonService.getAPIService(endpoint);
  }

  public getPartnerGroups(accountId: string): Observable<APIResult<PartnerGroup[]>> {
    const endpoint = 'account/partnertype/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public createPartner(data: PartnerPayload): Observable<APIResult<string>> {
    const endpoint = 'account/partner/create';
    return this.commonService.postAPIService(endpoint, data);
  }

  public listPartners(queryString: string): Observable<APIResult<PartnerQueryResult>> {
    const accountId = sessionStorage.getItem('aid');
    const endpoint = 'account/partner/' + accountId + queryString;
    return this.commonService.getAPIService(endpoint);
  }

  public createNewPartnerType(data: PartnerTypePayload): Observable<APIResult<number>> {
    const endpoint = 'account/partnertype/create';
    return this.commonService.postAPIService(endpoint, data);
  }

  public startExpInterval(): void {
    this.unsubInterval = interval(600000).subscribe(() => {
      if (sessionStorage.getItem('vcu')) {
        this.checkTokenExp().subscribe(() => {
          return true;
        });
      }
    });
  }

  checkTokenExp(): Observable<APIResult<string>> {
    const endpoint = this.serviceUrl + 'users/tokenexpired/' + sessionStorage.getItem('uid');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    if (sessionStorage.getItem('uid')) {
      headers = headers.append('User-Id', sessionStorage.getItem('uid'));
    }
    if (sessionStorage.getItem('vcu')) {
      headers = headers.append('token', sessionStorage.getItem('vcu'));
    }
  if (sessionStorage.getItem('xdl_referer')) {
      this.xdl = sessionStorage.getItem('xdl_referer');
      console.log('this.xdl: ', this.xdl);
    }
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
    return this.http.get<APIResult<string>>(endpoint, { headers }).pipe(
      map((response) => {

          if (sessionStorage.getItem('xdl_referer')) {
            this.xdl = sessionStorage.getItem('xdl_referer');
            console.log('this.xdl: ', this.xdl);
          }
          console.log(response);
        return response;
      }),
      catchError((err) => {
        const isLogout = true;
        this.notification.error('Your Session has expired. Redirecting to login page', isLogout);

        setTimeout(() => {
          if (sessionStorage.getItem('xdl_referer')) {
            this.xdl = sessionStorage.getItem('xdl_referer');
          }
            this.translationService.destroyTranslationCache();
            this.solutionService.destroySituationTreeMap();
            sessionStorage.clear();
            localStorage.clear();
            this.unsubInterval.unsubscribe();
            heapReset();
            localStorage.removeItem('AnalyticsSession');

          // if (this.xdl !== null && this.xdl !== undefined) {
          if (this.xdl && this.xdl !== '') {
            window.location.href = this.xdl + '/mikey.html';
          } else {
            this.router.navigate(['/login']);
            window.location.reload();

          }
        }, 3000);

        return observableThrowError(err.message || 'Server error');
      })
    );
  }

  public updatePartner(payload: PartnerPayload, partnerId: string): Observable<APIResult<string>> {
    const endpoint = 'account/partner/' + partnerId;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public getPrivileges(): Observable<APIResult<Privileges[]>> {
    const endpoint = 'fact/privilegeTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public savePrivileges(payload: PrivilegePayload, userId: number): Observable<APIResult<string>> {
    const endpoint = 'users/requestChange/' + userId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getRecentSearchCompanies(queryString: string): Observable<APIResult<CompanySearchResponse>> {
    const endpoint = 'companies/recentlyViewed/' + queryString;
    return this.commonService.getAPIService(endpoint);
  }

  public getExistingCustomers(id: string): Observable<APIResult<{ guests: Customer[] }>> {
    const endpoint = 'account/guests/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getExistingCustomersVPGroup(id: string): Observable<APIResult<{ guests: Customer[] }>> {
    const endpoint = 'account/valuePropGroupGuests/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getExistingCustomersMVP(id: string): Observable<APIResult<{ guests: Customer[] }>> {
    const endpoint = 'account/masterValuePropGuests/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public createAccountScalers(accountId: string, payload: Partial<BasicInfo>): Observable<APIResult<SuccessFailResponse>> {
    const endpoint = 'account/scaleTypes/create/' + accountId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getTopOnFlyBenefits(): Observable<APIResult<BenefitOnFly[]>> {
    const endpoint = 'solutions/readBenefitsAddedOnFly';
    return this.commonService.getAPIService(endpoint);
  }

  public getBenefitsRemoved(): Observable<APIResult<BenefitRemoved[]>> {
    const endpoint = 'solutions/readNotPriorityBenefit';
    return this.commonService.getAPIService(endpoint);
  }

  public getDriverFactors(): Observable<APIResult<SolutionFactor[]>> {
    const endpoint = 'solutions/readDeltaDriverChangeEstimate';
    return this.commonService.getAPIService(endpoint);
  }

  public getFinancialFactors(): Observable<APIResult<SolutionFactor[]>> {
    const endpoint = 'solutions/readDeltaFinancialChangeEstimate';
    return this.commonService.getAPIService(endpoint);
  }

  public getImprovementFactors(): Observable<APIResult<SolutionFactor[]>> {
    const endpoint = 'solutions/readDeltaImprovementChangeProbable';
    return this.commonService.getAPIService(endpoint);
  }

  public getLowestRatedBenefits(): Observable<APIResult<LowestRatedBenefit[]>> {
    const endpoint = 'solutions/readLowRatedBenefits';
    return this.commonService.getAPIService(endpoint);
  }

  public getUserCommentsBenefits(): Observable<APIResult<{ [klass: string]: any }[]>> {
    const endpoint = 'solutions/readBenefitComments';
    return this.commonService.getAPIService(endpoint);
  }

  public getAccountStages(aid: string): Observable<APIResult<{ [klass: string]: any }[]>> {
    const endpoint = 'account/readAccountStageTypes/' + aid;
    return this.commonService.getAPIService(endpoint);
  }

  public saveAccountStages(aid: string, payload: { name: string }): Observable<APIResult<AccountStageResponse>> {
    const endpoint = 'account/accountStageTypes/create/' + aid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateAccountStages(aid: string, payload: AccountStageUpdate): Observable<APIResult<unknown>> {
    const endpoint = 'account/accountStageTypes/update/' + aid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteAccountStage(aid: string, id: string): Observable<APIResult<unknown>> {
    const endpoint = 'account/accountStageTypes/delete/' + aid + '/accountStageTypeId/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public readQuestions(aid: number): Observable<APIResult<AccountQuestionsResponse>> {
    const endpoint = 'account/accountQuestions/' + aid;
    return this.commonService.getAPIService(endpoint);
  }

  public readLMSPath(aid: string): Observable<APIResult<LMSPathResponse>> {
    const endpoint = 'account/readLMSpath/' + aid;
    return this.commonService.getAPIService(endpoint).pipe(
      tap((response) => {
        if (response?.result?.result?.lms_custom_path) {
          this.lmsPath$.next(response.result.result.lms_custom_path);
        }
      })
    );
  }

  public setLmsPath(lms_custom_path: string): Observable<APIResult<LMSPathResponse>> {
    const accountId = sessionStorage.getItem('aid');
    const payload = { lms_custom_path };
    const endpoint = 'account/lmsPath/update/' + accountId;
    return this.commonService.putAPIService(endpoint, payload).pipe(
      tap((response) => {
        if (response?.result === 'Account LMS path has been Updated successfully') {
          this.lmsPath$.next(lms_custom_path);
        }
      })
    );
  }

  public createDiscoveryQuestion(aid: string, payload: DiscoveryQuestionRequest): Observable<APIResult<number>> {
    const endpoint = 'account/discoveryQuestion/create/' + aid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getDiscoveryQuestions(aid: string): Observable<APIResult<DiscoveryQuestion[]>> {
    const endpoint = 'account/discoveryQuestions/' + aid;
    return this.commonService.getAPIService(endpoint);
  }

  public getDiscoveryQuestionsVP(vpid: string): Observable<APIResult<DiscoveryQuestion[]>> {
    const endpoint = 'valueProps/discoveryQuestions/' + vpid;
    return this.commonService.getAPIService(endpoint);
  }

  public getUniqueDiscoveryQuestionTags(aid: string): Observable<APIResult<DiscoveryQuestionTag[]>> {
    const endpoint = 'fact/tagTypes/accountId/' + aid;
    return this.commonService.getAPIService(endpoint);
  }

  public updateDiscoveryQuestion(aid: string, payload: DiscoveryQuestionRequest): Observable<APIResult<{ error: boolean; message: string }>> {
    const endpoint = 'account/discoveryQuestion/update/' + aid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteDiscoveryQuestion(aid: string, id: string): Observable<APIResult<boolean>> {
    const endpoint = 'account/discoveryQuestion/delete/' + aid + '/discoveryQuestionID/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public postGetAdoptionReport(accountId: string, payload: AdoptionReportRequest): Observable<APIResult<AdoptionReport>> {
    const endpoint = 'account/readUserAdoptionReport/' + accountId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public postExcludeUsers(payload: ExcludeUsersPayload): Observable<APIResult<ExcludeUsersResponse>> {
    const endpoint = 'account/excludedUsers';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getExcludedUsers(): Observable<APIResult<ExcludedUser[]>> {
    const endpoint = 'account/excludedUsers';
    return this.commonService.getAPIService(endpoint);
  }

  public setHeaderColor(accountId: string, payload: SetAccountColorsPayload): Observable<APIResult<string>> {
    const endpoint = 'account/setHeaderColor/' + accountId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public setChartColor(accountId: string, payload: SetChartColorPayload): Observable<APIResult<ChartColor[]>> {
    const endpoint = 'account/setChartColor/' + accountId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public deleteChartColor(accountId: string, chartColorId: string): Observable<APIResult<ChartColor[]>> {
    const endpoint = 'account/deleteChartColor/' + accountId + '/chartColorId/' + chartColorId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getAccountColors(accountId: string): Observable<APIResult<AccountColors>> {
    const endpoint = 'account/readColors/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public getCurrencyRates(): Observable<APIResult<CurrencyRate[]>> {
    const endpoint = 'account/conversionRates';
    return this.commonService.getAPIService(endpoint);
  }

  public postCurrencyRates(payload: CurrencyRatesPayload): Observable<APIResult<CurrencyRatesResponse>> {
    const endpoint = 'account/conversionRates';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getTerms(): Observable<Term[]> {
    const accountId = sessionStorage.getItem('aid');
    const endpoint = 'account/readAccountTerms/' + accountId;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  setTerms(terms: Term[], defaultTerm: Term): Observable<Term[]> {
    const endpoint = 'account/terms';

    return this.commonService
      .putAPIService(endpoint, {
        terms: terms.map((t) => ({ value: t.term })),
        default_term: defaultTerm.term,
      })
      .pipe(pluck('result'));
  }

  getFiscalMonth(): Observable<number> {
    const endpoint = 'account/readAccountDefault/3';

    return this.commonService.getAPIService(endpoint).pipe(
      pluck('result'),
      map((result) => {
        return result.value ? parseInt(result.value, 10) : null;
      })
    );
  }

  setFiscalMonth(value: number, isNew: boolean): Observable<number> {
    const endpoint = isNew ? 'account/accountDefault/3' : 'account/updateAccountDefault/3';
    const body = { value };

    const method = isNew ? this.commonService.postAPIService(endpoint, body) : this.commonService.putAPIService(endpoint, body);

    return method.pipe(
      pluck('result'),
      map((result) => {
        return result && result.value ? parseInt(result.value, 10) : null;
      })
    );
  }

  updateSequence(payload: SequencePayload, model: SequencePage): Observable<SequenceResPayload> {
    const endpoint = 'account/sequence/' + model;

    return this.commonService.putAPIService(endpoint, payload).pipe(
      pluck('result'),
      map((result) => {
        return result ?? null;
      })
    );
  }

  getSequence(model: SequencePage): Observable<any[]> {
    let endpoint = '';

    const accountId = sessionStorage.getItem('aid');

    switch (model) {
      case SequencePage.Benefits:
        endpoint = 'account/benefits/';
        break;
      case SequencePage.Assets:
        endpoint = 'asset/valuePropTemplates//limit/all/page/all';
        break;
      case SequencePage.PainPoints:
        endpoint = 'account/painPoints/' + accountId;
        break;
      case SequencePage.OperationalObjectives:
        endpoint = 'account/operationalObjectives/' + accountId;
        break;
      case SequencePage.FunctionalObjectives:
        endpoint = 'account/functionalObjectives/' + accountId;
        break;
      case SequencePage.Capabilities:
        endpoint = 'account/capabilities/' + accountId;
        break;
      case SequencePage.FactorGroups:
        endpoint = 'account/factorCategories/' + accountId;
        break;
      case SequencePage.ValueCategories:
        endpoint = 'account/valueCategories/' + accountId;
        break;
      case SequencePage.DiscoveryQuestions:
        endpoint = 'account/discoveryQuestions/' + accountId;
        break;
      case SequencePage.Costs:
        endpoint = 'account/costs/' + accountId;
        break;
      case SequencePage.Factors:
        endpoint = `account/factors/${accountId}/exclude/`;
        break;
      case SequencePage.Models:
        endpoint = 'solutions/availableSolutions/limit/all/page/1/search/all/sortby/id/order/desc?exclude_shared=true';
        break;
      case SequencePage.ModelCategories:
        endpoint = 'solutions/solutionCategories';
        break;
      case SequencePage.AccountWorkflows:
        endpoint = 'account/workflows/2/';
        break;
      case SequencePage.GoalGroups:
        endpoint = 'account/factor-categories?function_type_id=2';
        break;
      case SequencePage.Goals:
        endpoint = `account/factors/${accountId}/exclude/?function_type_id=2`;
        break;
      case SequencePage.Situations:
        endpoint = 'solutions/situation/onlyAccountSituationTypes';
        break;
    }

    return this.commonService.getAPIService(endpoint).pipe(
      pluck('result'),
      map((result) => {
        if (result.page && result.data) {
          return result.data ?? null;
        } else if (result.workflow) {
          return result.workflow ?? null;
        }
        return result ?? null;
      })
    );
  }

  getAccountBenefits(): Observable<APIResult<BenefitElement[]>> {
    const endpoint = 'account/benefits';
    return this.commonService.getAPIService(endpoint);
  }

  getAvailableAccountFactors(accountId: string): Observable<APIResult<EscalerFactor[]>> {
    const endpoint = `account/factors/${accountId}/exclude/0?function_type_id=1`;
    return this.commonService.getAPIService(endpoint);
  }

  getAccountFactorWACC(): Observable<FactorWACC> {
    const endpoint = 'account/factor-field-assignments';
    return this.commonService.getAPIService(endpoint).pipe(map(({result}: {result: FactorWACC[]}) => {
      if (result && result.length) {
        return result.find(f => f.field === 'wacc');
      } else {
        return null;
      }
    }));
  }

  getAccountFactorWACCPrecision(): Observable<number> {
    return this.getAccountFactorWACC().pipe(map(f => f ? Number.parseInt(f.factor_precision) : null));
  }

  saveAccountFactorWACC(payload: WACCFactorPayload): Observable<APIResult<{ data: boolean; success: boolean }>> {
    const endpoint = 'account/factor-field-assignments';
    return this.commonService.postAPIService(endpoint, payload);
  }

  getAccountAggregatedServices(accountId: string): Observable<APIResult<AggregatedServices>> {
    const endpoint = `account/benefitAggregateServices/${accountId}`;
    return this.commonService.postAPIService(endpoint, {});
  }
}
