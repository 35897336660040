import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';

import { AdoptionReportsComponent } from './adoption-reports.component';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { DLInputSwitchModule } from '@shared_components/primeng_toggle/toggle.module';
import { AutoCompleteModule } from 'primeng/autocomplete';


import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserAdoptionsReportTranslations } from './adoption-reports.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';




const routes: Routes = [
	{
		path: 'adoption-reports',
		component: AdoptionReportsComponent,
		data: { currentNav: 'settings' },
	}
];

@NgModule({
	imports: [
		DropdownModule,
		AutoCompleteModule,
		DLInputSwitchModule,
		GuidedTourModule,
		MultiSelectModule,
		TableChartModule,
		NgbModule,
		CommonModule,
		FormsModule,
		BreadcrumbsModule,
		CalendarModule,
		RouterModule.forChild(routes),
		EditTranslationsModule
	],
	declarations: [
		AdoptionReportsComponent
	],
	providers: [
		TranslationsV2Service,
		GuidedTourService,
		Translations,
		UserAdoptionsReportTranslations
	],
	entryComponents: [AdoptionReportsComponent],
	exports: [AdoptionReportsComponent]
})
export class AdoptionReportModule { }
