import { Injectable } from '@angular/core';
import { SvgIcon } from '../interfaces/svg-icon.interface';

@Injectable({
  providedIn: 'root'
})
export class SvgIconRegistryService {

  private registry = new Map<string, string>();

  registerIcons(icons: SvgIcon[]): void {
    icons.forEach(icon => this.registry.set(icon.name, icon.path));
  }

  getIcon(name: string): string {
    if(!this.registry.has(name)) {
      console.warn(`We could not find the Icon with the name ${name}, did you add it to the Icon registry?`);
    }
    
    return this.registry.get(name);
  }
}