import { NgModule } from '@angular/core';

import { SectionsComponent } from "./sections.component";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';

import {EditDeleteMenuRowModule} from '../../../../shared_components/edit-delete-menu-row/edit-delete-menu-row.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { RModelSectionsModule } from './r_sections/r_sections.module';
import { AEModelSectionsModule } from './ae_sections/ae_sections.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SectionsTranslations } from './sections.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [
		SectionsComponent,
	],
	imports: [
    MatTooltipModule,
		MenuModule,
		SidebarModule,
		NgbModule,
		FormsModule,
		CommonModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		OverlayPanelModule,
		AreYouSureModule,
		RModelSectionsModule,
		AEModelSectionsModule,
		EditDeleteMenuRowModule,
		EditTranslationsModule,
        ApplicationPipesModule
	],
	providers: [
		TranslationsV2Service,
		SectionsTranslations
	],
	entryComponents: [
		SectionsComponent,
	],
	exports: [SectionsComponent]
})
export class ModelSectionsModule { }