<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-12">
          <div class="title">
            {{ trans.trans.title.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.title"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <i *ngIf="!(style2022$ | async)" [matTooltip]="trans.trans.ch_assets.value" class="fa fa-info-circle"></i>
            <span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_assets.value">
              info
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <p-table [columns]="cols" [value]="assets" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ trans.trans[col.field].value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-asset>
            <tr class="table-row">
              <td>
                <p class="asset-heading">{{ asset.name }}</p>
                <a [href]="asset.url" target="_blank" role="button" matTooltip="Download Asset" matTooltipPosition="right">
                  <i [ngClass]="getFAIcon(asset.format)" [ngStyle]="getFAColor(asset.format)" style="margin-right: 5px"></i> Download
                </a>
                <a
                  *ngIf="asset.pdf == '1'"
                  [href]="asset.pdfUrl"
                  target="_blank"
                  role="button"
                  matTooltip="Download PDF Version"
                  matTooltipPosition="right"
                  style="margin-left: 20px">
                  <i class="fa fa-file-pdf-o" style="color: red; margin-right: 5px"></i> Download PDF
                </a>
                <p>{{ asset.description }}</p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
