import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateSurveyComponent } from './create-survey/create-survey.component';


const routes: Routes = [
	{
		path: 'trackers',
		loadChildren: () => import('./trackers_container/trackers-container.module').then(m => m.TrackersContainerModule),
		data: { page: 'trackers' }
	},
	{
		path: 'trackers/:id/survey/:surveyId',
		component: CreateSurveyComponent
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ValueTrackersRoutingModule { }
