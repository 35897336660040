<div class='keywest_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>Benefit Groups</div>
		</div>
		<div class='col-xs-6'>
			<button class="btn models_btn pull-right" (click)="openAddValueCategory()">
				<i class="fa fa-plus"></i> Add New</button>
		</div>
	</div>
</div>


<div class='row marg-v'>
	<div class='col-xs-4'>
		<div class="input-group models_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input (keyup)="tableSolutionList.filter($any($event).target.value, 'value_category', 'contains')" type='text' placeholder='Search' class='form-control'>
		</div>
	</div>
</div>

<p-menu #rowMenu appendTo='body' [popup]="true" [model]="dropdown" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='row'>
	<div class='col-xs-12'>
		<p-table #tableSolutionList [value]="valueCategories" [columns]="cols" [paginator]="true" [rows]="10" styleClass='models_table' tableStyleClass='models_table'>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [pSortableColumn]="col.field">
						{{col.header}}
						<p-sortIcon [field]="col.field"></p-sortIcon>
					</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-cat>
				<tr>
					<td>{{cat.value_category}}</td>
					<td (click)='funcBuildMenu(cat, rowMenu, $event)'>
						<i class='fa fa-ellipsis-v pull-right clickable' style='font-size: 18px;'></i>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddCategory' styleClass="thresholds_sidebar p-sidebar-lg" position='right'>

		<div class='sidebar_header_container'>
			<div class='sidebar_header'>
				{{sidebar_title}}
			</div>
		</div>
		<div class='sidebar_container'>
			<div class='thresholds_panel'>
				<div class="row">
					<div class="col-md-12">
						<label for="alterEgo" class="sidebar_label">Name</label>
						<input type='text' [(ngModel)]="valueCatName" class='form-control'>
					</div>
				</div>
			</div>
		</div>
		<div class='btn_container'>
			<div class='row'>
				<div class="col-md-12">
					<button *ngIf="!loadingSave && mode == 'add'" type="submit" class="btn models_btn pull-right" (click)="addNewValueCategory(valueCatName)">Save</button>
					<button *ngIf="!loadingSave && mode == 'edit'" type="submit" class="btn models_btn pull-right" (click)="updateValueCategory()">Update</button>
				</div>
			</div>
		</div>

</p-sidebar>

<ng-template #updateValueCategoryModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">
			{{ 'general.Update' | translate }} {{ 'create-map.value_category' | translate }}
		</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click');">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="updateValueCategory();">{{ 'general.save' | translate }}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ 'general.Cancel' | translate }}</button>
	</div>
</ng-template>