<div class="row">
  <div class="col-xs-12" *ngIf="can_edit">
    <a (click)="funcAddEditBenefitToggle()" class="a-link-color clickable"><i class="fa fa-plus"></i> {{ trans.trans.tie_to_benefits.value }}</a>
    <app-editTranslation
      *ngIf="showTranslate"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.tie_to_benefits"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>
</div>

<div class="row" *ngIf="!!caseStudy.benefits && caseStudy.benefits?.length > 0">
  <div class="col-xs-4" style="min-width: 300px" *ngFor="let benefit of caseStudy.benefits">
    <div class="quote_container">
      <div class="row">
        <div class="col-xs-9">
          <span class="case_study_benefit_title">{{ benefit.metric_name }}</span>
        </div>
        <div class="col-xs-3" *ngIf="can_edit" style="white-space: nowrap">
          <a (click)="deleteBenefit(benefit)" class="clickable pull-right"><i class="fa fa-times"></i></a>
          <a (click)="openEditBenefit(benefit)" class="clickable pull-right" style="margin-right: 10px"><i class="fa fa-pencil"></i></a>
        </div>
        <div class="col-xs-3" *ngIf="!can_edit" style="white-space: nowrap">
          <a (click)="funcOpenView(benefit)" class="clickable pull-right"><i class="fa fa-eye"></i></a>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <span class="case_study_benefit_metric">{{ benefit.improvement }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar
  [(visible)]="toggleAddEditBenefit"
  [modal]="isModal"
  styleClass="keywest_sidebar {{ can_edit && mode === 'add' ? 'p-sidebar-xl' : 'p-sidebar-lg' }}"
  position="right"
  (onHide)="resetValues()"
>
  <app-r-benefits-casestudy *ngIf="toggleAddEditBenefit && !can_edit" [benefitView]="selectedBenefit"></app-r-benefits-casestudy>
  <app-ae-benefits-casestudy
    *ngIf="toggleAddEditBenefit && can_edit"
    [caseStudy]="caseStudy"
    [type]="type"
    [selectedBenefit]="selectedBenefit"
    [mode]="mode"
    (valueChange)="funcCloseSidebar(true)"
    (closeCallback)="funcCloseSidebar(false)"
  ></app-ae-benefits-casestudy>
</p-sidebar>
