import { Injectable } from '@angular/core';

@Injectable ()

export class MissionVpDashTranslations {

    public config: any = {
        component: 'missionVpDash',
        display_name: 'Value Prop Mission',
        description: 'Mission tab in value prop dashboard'
    };

    public trans: any = {
        save: 'Save',
        mission_statement: 'Mission Statement'
    };
}
