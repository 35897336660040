import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { AccountService } from '@data/services/account/account.service';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';

@Component({
  selector: 'app-scalers',
  templateUrl: './scalers.component.html',
  styleUrls: ['./scalers.component.scss'],
})
export class ScalersComponent implements OnInit, OnDestroy {
  @Input() canEdit = false;

  imageUrl: string;
  fullImagePath: string;
  scalerLoader = false;
  solutionId: number;
  scalers: Array<BasicInfo | Factor> = [];
  factScalers: Array<BasicInfo> = [];
  selectedScalers: Array<{ [klass: string]: any }> = [];
  showEditScalersForm = false;
  showActionLoader = false;
  accountScalerShow = false;
  accountScaler: { name: string; description: string } = {
    name: '',
    description: '',
  };

  selectedItems: Array<{name: string, id: string} | Factor> = [];
  dropdownSettings: { [klass: string]: any } = {};

  subscriptiongetScaleTypes: Subscription;
  subscriptiongetAccountScaleTypes: Subscription;
  subscriptiongetAvailableScalers: Subscription;
  subscriptionupdateScalers: Subscription;
  subscriptiondeleteScaler: Subscription;
  subscriptioncreateAccountScalers: Subscription;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private factService: FactsService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => (this.solutionId = params['id']));

    this.getScalers();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Available Scalers',
      enableCheckAll: false,
      enableSearchFilter: false,
      labelKey: 'name',
      badgeShowLimit: 2,
      lazyLoading: false,
    };
  }

  ngOnDestroy() {
    if (this.subscriptiongetScaleTypes) {
      this.subscriptiongetScaleTypes.unsubscribe();
    }
    if (this.subscriptiongetAccountScaleTypes) {
      this.subscriptiongetAccountScaleTypes.unsubscribe();
    }
    if (this.subscriptiongetAvailableScalers) {
      this.subscriptiongetAvailableScalers.unsubscribe();
    }
    if (this.subscriptionupdateScalers) {
      this.subscriptionupdateScalers.unsubscribe();
    }
    if (this.subscriptiondeleteScaler) {
      this.subscriptiondeleteScaler.unsubscribe();
    }
    if (this.subscriptioncreateAccountScalers) {
      this.subscriptioncreateAccountScalers.unsubscribe();
    }
  }

  getScalers() {
    this.subscriptiongetScaleTypes = this.factService.getScaleTypes(1).subscribe((response) => {
      if (response.result) {
        this.factScalers = response.result;

        this.scalerLoader = true;
      }
      this.getAccountScalers();
    });
  }

  getAccountScalers() {
    this.subscriptiongetAccountScaleTypes = this.factService.getAccountScaleTypes().subscribe((response) => {
      this.scalers = this.factScalers;
      if (response.result) {
        this.scalers = this.scalers.concat(response.result);
      }
      this.scalerLoader = true;
      this.getAvailableScalers();
    });
  }

  getAvailableScalers() {
    this.subscriptiongetAvailableScalers = this.solutionService.getAvailableScalers(this.solutionId, 1).subscribe((response) => {
      if (response.result) {
        this.selectedScalers = response.result;

        const selectedScalersindexed = this.selectedScalers.reduce((acc, curr: any) => {
          return {
            [curr.scale_type_id]: true,
            ...acc,
          };
        }, {});
        this.selectedItems = this.scalers.filter((scal: any) => selectedScalersindexed[scal.id]);
        this.scalerLoader = true;
      }
    });
  }

  addScalersforEdit() {
    this.showEditScalersForm = true;

    const selectedScalersID = this.selectedScalers.reduce((acc, curr: any) => {
      return {
        [curr.scale_type_id]: true,
        ...acc,
      };
    }, {});

    this.selectedItems.forEach((item: any) => {
      if (!selectedScalersID[item.id]) {
        item['scale_type_id'] = item['id'];
        this.selectedScalers.push(item);
      }
    });
  }

  editScalers(values) {
    const accountSolutionScalers = [];
    this.showActionLoader = true;

    // tslint:disable-next-line: forin
    for (const key in values) {
      accountSolutionScalers.push({
        // tslint:disable-next-line: radix
        scale_type_id: parseInt(key.split('-')[1]),
        value: values[key],
      });
    }

    this.subscriptionupdateScalers = this.solutionService.updateScalers(this.solutionId, { account_solution_scalers: accountSolutionScalers }).subscribe((response) => {
      if (response.result) {
        this.getScalers();
        this.getAvailableScalers();
        this.notification.success('Scalers updated successfully', false);
        this.showActionLoader = false;
      }
    });
  }

  deleteScaler(id: string | number) {
    this.showActionLoader = true;
    this.subscriptiondeleteScaler = this.solutionService.deleteScaler(this.solutionId, id).subscribe((response) => {
      if (response.result) {
        for (let i = 0; i < this.selectedScalers.length; i++) {
          // tslint:disable-next-line: triple-equals
          if (this.selectedScalers[i]['id'] == id) {
            this.selectedScalers.splice(i, 1);
          }
        }
        this.notification.success(response.result, false);
        this.getScalers();
        this.getAvailableScalers();
        this.showActionLoader = false;
      }
    });
  }

  resetFormValues(form: NgForm) {
    // tslint:disable-next-line: forin
    for (const key in form.value) {
      const oldValue = this.getScalerResetValue(key.split('-')[1]);
      form.controls[key].setValue(oldValue);
    }
  }

  getScalerResetValue(id: string | number) {
    for (let i = 0; i < this.selectedScalers.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (this.selectedScalers[i]['scale_type_id'] == id) {
        return this.selectedScalers[i]['value'];
      }
    }
  }

  toggleScalersCreate() {
    this.accountScalerShow = !this.accountScalerShow;
    this.accountScaler = {
      name: '',
      description: '',
    };
  }

  createAccountScalers() {
    this.showActionLoader = true;
    const accountId = sessionStorage.getItem('aid');
    this.subscriptioncreateAccountScalers = this.accountService.createAccountScalers(accountId, this.accountScaler).subscribe((response) => {
      if (response.result) {
        this.notification.success('Account Scale Type created successfully', false);
        this.toggleScalersCreate();
        this.getAccountScalers();
        this.showActionLoader = false;
      }
    });
  }
}
