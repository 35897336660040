import { ElementRef, ComponentFactoryResolver, ApplicationRef, Injector } from '@angular/core';
import { DomPortalHost } from '@angular/cdk/portal';
import * as _ from 'lodash';

export function createDomPortalHost(elRef: ElementRef, injector: Injector) {
  return new DomPortalHost(elRef.nativeElement, injector.get(ComponentFactoryResolver), injector.get(ApplicationRef), injector);
}

export function arraysEqual(a: unknown[], b: unknown[], orderd = true): boolean {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  const aRef: unknown[] = orderd ? _.cloneDeep(a) : _.cloneDeep(a).sort(customComparer);
  const bRef: unknown[] = orderd ? _.cloneDeep(b) : _.cloneDeep(b).sort(customComparer);

  let pass = true;

  for (let i = 0; i < a.length; ++i) {
    const aElement = aRef[i];
    const bElement = bRef[i];
    if (pass) {
      checkPassConditions(aElement, bElement);
    }
  }

  function checkPassConditions(elementA: unknown, elementB: unknown): void {
    if (Array.isArray(elementA) && Array.isArray(elementB)) {
      pass = arraysEqual(elementA, elementB, orderd);
    } else if (checkObject(elementA) && checkObject(elementB)) {
      const aKeys = Object.keys(elementA);
      const bKeys = Object.keys(elementB);
      if (aKeys.length !== bKeys.length) {
        pass = false;
      } else {
        const objectA: { [klass: string]: any } = elementA;
        const objectB: { [klass: string]: any } = elementB;
        for (let i = 0; i < aKeys.length; ++i) {
          if (pass) {
            checkPassConditions(objectA[aKeys[i]], objectB[bKeys[i]]);
          }
        }
      }
    } else if (elementA !== elementB) {
      pass = false;
    }
  }

  return pass;
}

function valueExtractor(value: any): any {
  if (checkObject(value)) {
    return valueExtractor(value[Object.keys(value)[0]]);
  } else if (Array.isArray(value)) {
    return valueExtractor(value[0]);
  } else {
    return value;
  }
}

function customComparer(a: unknown, b: unknown) {
  let aValue = valueExtractor(a);
  let bValue = valueExtractor(b);
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }

  return 0;
}

function checkObject(value: unknown): boolean {
  return typeof value === 'object' && value !== null;
}

export const accountDefaultColors = {
  btnText: '#ffffff',
  header: '#4285f4',
  btnBg: '#4285f4',
};

export function extractInitials(name: string): string {
  const splitName = name.split(' ');

  name = '';
  splitName.forEach((word) => {
    word !== '' ? (name += word[0]) : name;
  });
  return name.substring(0, 2);
}

export const getSeparatorByLocale = (locale: string, type: 'group' | 'decimal'): string => {
  const separator: string = Intl.NumberFormat(locale)
    ['formatToParts'](1000.1)
    .find((part) => part.type === type)?.value;
  return separator;
};

export const escapeCharactersRegExp = /\\n|\\r|\\|\\r\\n/g;

export const defaultUnitTypes = [
  '',
  'currency',
  'currencyThousands',
  'currencyMillions',
  'currencyBillions',
  'thousands',
  'millions',
  'billions',
  'seconds',
  'minutes',
  'hours',
  'days',
  'months',
  'years',
  'percent',
  'basisPoints',
  'miles',
  'number',
  'weeks',
];

export function alphabeticSort(a: unknown, b: unknown, field: string, caseSensitive = false): number {
  const aField = caseSensitive ? a[field] : a[field].toLowerCase();
  const bField = caseSensitive ? b[field] : b[field].toLowerCase();
  if (aField < bField) {
    return -1;
  }
  if (aField > bField) {
    return 1;
  }
  return 0;
}
