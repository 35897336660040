import { Injectable } from '@angular/core';

@Injectable()
export class CasestudyDashboardTranslations {
  public config: any = {
    component: 'casestudyDashboard',
    display_name: 'Case Study Dashboard',
    description: 'Case studies list for main dashboard',
  };

  public trans: any = {
    caseStudiesHeading: 'Recent Case Studies',
    case_name: 'Name',
    noCaseStudies: 'No Case Studies Found',
    add: 'Add',
  };
}
