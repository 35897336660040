import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';

@Injectable()
export class ListService {

  service_url: string;
  constructor(private CommonService: CommonService) {
    this.service_url = this.CommonService.getServiceUrl();
  }

  public getHypotheses(payload) {
    let endpoint = "list/hypotheses";
    return this.CommonService.postAPIService(endpoint, payload);
  }
  
  public getValueProps(payload) {
    let endpoint = "list/valueProps";
    return this.CommonService.postAPIService(endpoint, payload);
  }

}