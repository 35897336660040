import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ValuePropCloneComponent } from './clone.component';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SearchCompanyCloneModule } from '../search-company-clone/search-companyv3.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ValuePropCloneTranslation } from './clone.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';

export class WebpackTranslateLoader implements TranslateLoader {
    getTranslation(lang: string) {
        return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
    }
}

@NgModule({
	imports: [
		AreYouSureModule,
		SearchCompanyCloneModule,
        EditTranslationsModule,
        CommonModule,
        FormsModule,
    ],
    declarations: [
        ValuePropCloneComponent
    ],
    providers: [ValuePropCloneTranslation, TranslationsV2Service],
    exports: [ValuePropCloneComponent]
})
export class ValuePropCloneModule { }
