<form #issueForm="ngForm" (ngSubmit)="submitQueries();">
  <div *ngIf="!(style2022$ | async)" class="row">
    <div [class]="(style2022$ | async) ? 'col-xs-9' : 'col-xs-12'">
      <label class="sidebar_label">{{ trans.trans.Email.value }}</label
      ><br />
      <input type="text" class="form-control" disabled="true" placeholder="{{ trans.trans.Email.value }}" name="email" [(ngModel)]="query.email" #email="ngModel" required />
      <span class="help-bpx" *ngIf="email.touched && !email.valid">{{ trans.trans.errorEmailValue.value }}</span>
    </div>
  </div>
  <div class="row">
    <div [class]="(style2022$ | async) ? 'col-xs-9' : 'col-xs-12'">
      <label [class.labelsRequired]="style2022$ | async" class="sidebar_label">{{ trans.trans.name.value }}</label>
      <select class="form-control" [(ngModel)]="query.section" name="section" #section="ngModel" required>
        <option selected value="">{{ trans.trans.choose_one.value }}</option>
        <option *ngFor="let section of sections" [value]="section">
          {{ section }}
        </option>
      </select>
      <span class="help-bpx red-color" *ngIf="section.touched && !section.valid">{{ trans.trans.errorChooseSection.value }}</span>
    </div>
  </div>
  <div class="row">
    <div [class]="(style2022$ | async) ? 'col-xs-9' : 'col-xs-12'">
      <label [class.labelsRequired]="style2022$ | async" class="sidebar_label">{{ trans.trans.description.value }}</label>
      <textarea class="form-control" [(ngModel)]="query.issues" name="issues" #issues="ngModel" required> </textarea>
      <span class="help-bpx red-color" *ngIf="issues.touched && !issues.valid">{{ trans.trans.errorIssueOrFeedback.value }}</span>
    </div>
  </div>
  <div class="row" style="margin-bottom: 10px">
    <div [class]="(style2022$ | async) ? 'col-xs-9' : 'col-xs-12'">
      <label class="sidebar_label"></label>
      <app-dl-dragdrop
        *ngIf="style2022$ | async"
        [canEdit]="true"
        [title]="trans.trans.dropClickUpload.value"
        [msg]="'(' + trans.trans.onlyImagesPDFsUploaded.value + ')'"
        [files]="[]"
        [reset]="refresh"
        (callbackInvalidSize)="showInvalidSize($event)"
        (callbackInvalidType)="showInvalidType($event)"
        [maxSizeKB]="33554.432"
        (callback)="files = $event"
      ></app-dl-dragdrop>
      <input type="file" *ngIf="!(style2022$ | async)" name="media" [(ngModel)]="query.media" multiple="multiple" accept="image/*" (change)="handleInputChange($any($event))" />
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <button class="btn btn_customizable" [disabled]="!issueForm.valid || IssuesLoader" type="submit">
        {{ (style2022$ | async) ? 'Submit' : trans.trans.save.value }}
      </button>
    </div>
  </div>
</form>
<div *ngIf="IssuesLoader" class="col-12 loader-container text-center">
  <img class="loader" src="{{ fullImagePath }}" />
</div>
