<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div *ngIf="showSurveysList">

	<div class='row marg-v'>
		<div class='col-xs-4'>
			<div class="input-group keywest_search">
				<span class="input-group-addon"><i class='fa fa-search'></i></span>
				<input [(ngModel)]="strSearch" (keyup)="tableRealization.filter($any($event.target).value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
				<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableRealization.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
			</div>
		</div>
		<div class='col-xs-8'>
			<span class="clickable pull-right row-limit" (click)='row.toggle($event)'>{{trans.trans.rowLimit.value}} {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
		</div>
	</div>

	<div class='row'>
		<div class='col-xs-12'>
			<p-table #tableRealization [value]="surveys" [columns]="columns" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
				<ng-template pTemplate="header" let-columns>
					<tr>

						<th *ngFor="let col of columns; " [pSortableColumn]="col.field">
							<ng-container *ngIf="col.field !== 'actions'">
								{{trans.trans[col.field].value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.yourValueRealization' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans[col.field]"></app-editTranslation>
								<p-sortIcon [field]="col.field"></p-sortIcon>
							</ng-container>
						</th>

					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-survey>
					<tr>
						<td>
							<a *ngIf="can_edit" (click)="openSurveyDetail(survey)" class='clickable link'>{{ survey.name }}</a>
							<span *ngIf="!can_edit">{{ survey.name }}</span>
						</td>

						<td>
							{{ survey.created | date:'MMM-yyyy' }}
						</td>
						<td *ngIf="realizaionIdInput">
							 {{ survey.custom_survey_date_fmt }}
						</td>
						<td>
							{{ survey.survey_name }}
						</td>
						<td style='white-space: nowrap; width: 100px;'>
							<div *ngIf="can_edit">
								<app-are-you-sure [matTooltip]="trans.trans.delete.value" [message]='trans.trans.are_you_sure.value' class="pull-right" icon='fa fa-trash' text='' (callbackYes)='delete(survey.id)'></app-are-you-sure>
								<!-- <i (click)="downAsset(survey)" [pTooltip]='trans.trans.downloadAsset.value' tooltipPosition='top' class="fa fa-cloud-download pull-right"></i> -->
								<i (click)="openSurveySettings(survey)" [matTooltip]='trans.trans.settings.value' matTooltipPosition='above' class="fa fa-cog pull-right"></i>
							</div>
						</td>
					</tr>
				</ng-template>
			</p-table>

		</div>
	</div>
</div>

<div class="clearfix"></div>
<div *ngIf="!showSurveysList">
	<div (click)="showSurveysList = !showSurveysList" role="button" class="m-b-xxl m-l-xxl a-link-color">
		<i class="fa fa-chevron-left"></i> {{trans.trans.back.value}}
	</div>
	<app-create-survey [surveyId]="+surveyId" [activeSurveyTemplate]="activeSurveyTemplate" [account_question_template_id]="account_question_template_id" (close)="closeCreateSurvey()" [isTracker]="!!realizaionIdInput"></app-create-survey>
</div>

<div *ngIf="surveyDetailLoader" class="col-12 text-center">
	<img class="actionLoader" [src]="fullImagePath" />
</div>

<p-sidebar [(visible)]='toggleSurveySettings' styleClass="keywest_sidebar p-sidebar-md" position='right'>
	<form name="SurveySettingsForm" #SurveySettingsForm="ngForm" class="full-height">
		<div class="sidebar_wrapper">
			<div class='sidebar_header'>
				{{trans.trans.survey_settings.value}}
			</div>

			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class="row">
						<div class="col-md-8">
							<div class="form-group" [ngClass]="{'has-error': (SurveySettingsForm.controls.surveyName?.invalid && SurveySettingsForm.controls.surveyName?.touched)}">
								<label class="labelsRequired">{{trans.trans.survey_name.value}}</label>
								<input type="text" class="form-control" name="surveyName" [(ngModel)]="surveyName" required />
							</div>
							<!-- add date for custom date -->
							<div class="row" *ngIf="realizaionIdInput">
								<div class="col-xs-12">
									<label class="sidebar_label">Survey Date
									</label>

								</div>
								<div class="col-xs-12">
									<p-calendar [(ngModel)]="customSurveyDate" name="customSurveyDate" view="month" dateFormat="mm/yy" placeholder='Select a Date' [readonlyInput]="true"></p-calendar>
								</div>
							</div>
							<div *ngIf="!realizaionIdInput">
								<div class="form-group" [ngClass]="{'has-error': (SurveySettingsForm.controls.surveyName?.invalid && SurveySettingsForm.controls.surveyName?.touched)}">
									<label class="labelsRequired">{{trans.trans.quarters_in.value}}</label>
									<select class="form-control" name="quaters_in" [(ngModel)]="quaters_in" required>
										<option *ngFor="let quarter of quatersList" [ngValue]="quarter.value">
											{{quarter.name}}
										</option>
									</select>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<div class="col-xs-12">
					<button type="button" class="btn btn_customizable pull-right" (click)="updateSurveySettings();" [disabled]="SurveySettingsForm.invalid">{{trans.trans.Update.value}}</button>
					<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='toggleSurveySettings = !toggleSurveySettings'></app-are-you-sure>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>
