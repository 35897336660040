<div class="row">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
  <div class="pull-right mr-5">
    <app-help-tooltip-value-realized [stepIndex]="stepIndex + 1"></app-help-tooltip-value-realized>
  </div>
</div>

<div class="row marg-v">
  <div class="col-md-3 mr-auto title no-wrap" *ngIf="stepIndex > 0">
    <div [hidden]="changeVPName">
      {{ valueProp?.name }}
      <a class="normal-t" [matTooltip]="trans.trans.renameTracker.value" (click)="changeVPName = !changeVPName"><i style="margin-left: 10px" class="fa fa-pencil"></i></a>
    </div>
    <app-edit-name
      *ngIf="changeVPName"
      [closeEdition]="!changeVPName"
      [name]="valueProp?.name"
      (cancel)="cancelChangeName()"
      (submit)="submitChangeName($event)"
      (hideValue)="hideVPName($event)"
      [editTip]="trans.trans.renameTracker.value"
    >
    </app-edit-name>
  </div>
  <div *ngIf="userName$ | async as userName" class="col-md-2 pull-right padding-15-top">{{ trans.trans.assignedBy.value }}: {{ userName }}</div>
</div>

<div class="row">
  <div class="col-md-12">
    <p-tabView styleClass="no-space" [(activeIndex)]="stepIndex">
      <p-tabPanel [header]="trans.trans.createTrackerBaseline.value">
        <app-tracker-baseline [companyId]="companyId" (nextCallback)="nextStep($event)" (cancelCallback)="cancel()"></app-tracker-baseline>
      </p-tabPanel>
      <p-tabPanel [header]="trans.trans.reviewBaseline.value" [disabled]="!!!valuePropId">
        <div [hidden]="stepIndex !== 1">
          <app-review-baseline (backCallback)="moveBack()" (finishCallback)="finish()" (cancelCallback)="cancel()" [valuePropId]="valuePropId"></app-review-baseline>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog [modal]="true" [closable]="false" [breakpoints]="{ '640px': '100vw' }" [style]="{ width: '475px' }" [(visible)]="showFinishModal">
  <div class="dialog-content">
    <div class="logo-vt">
      <span class="material-icons-outlined tooltip-custom check-circle-icon">check_circle</span>
    </div>
    <div class="title-vt">{{ trans.trans.valueTrackerSuccess.value }}</div>
    <div class="subtitle-vt">{{ trans.trans.startTracking.value }}</div>
  </div>
  <ng-template pTemplate="footer">
    <button class="btn btn_customizable reverse marg-h" (click)="navigateToAllTrackers()">{{ trans.trans.doItLater.value }}</button>
    <button [disabled]="isLoading" class="btn btn_customizable" (click)="navigateToThisTracker()">
      <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
      {{ trans.trans.continuteToTracker.value }}
    </button>
  </ng-template>
</p-dialog>
