import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterStatus',
    pure: false
})
export class filterStatusPipe implements PipeTransform {
    transform(arr: any[], filter: string): any {
        if (!arr || !filter) {
            return arr;
        }
        return arr.filter(item => item.active_type.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
    }
}
