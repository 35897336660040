import { Injectable } from '@angular/core';

@Injectable ()

export class ProfileDetailTranslation {

    public config: any = {
        component: 'profileDetail',
        display_name: 'Profile User  Full Detail',
        description: 'Full detail of a user profile'
    };

    public trans: any = {
        role: 'Role',
        name: 'Name',
		location: 'Location',
        Email: 'Email',
        changePassword: 'Change Password',
        confirmPassword: 'Confirm Password',
        currencyType: 'Currency Type',
        currentPassword: 'Current Password',
        editPwd: 'Edit',
        heading: 'Your Profile',
        manager: 'Manager',
        mobile: 'Mobile',
        newPassword: 'New Password',
        numberFormat: 'Number Format',
        phone: 'Phone',
        updateProfileIcon: 'Update Profile Icon',
        select: 'select',
        save: 'Save',
        Cancel: 'Cancel'
    };
}
