<div [ngClass]="fromRoute ? 'details_panel' : ''">
	<div class='row'>
		<div class='col-xs-12'>
			<div [ngClass]="fromRoute ? 'details_panel_container' : ''">

				<div class='row' *ngIf="fromRoute">
					<div class='col-xs-12'>
						<a routerLink='/case_studies' class='link clickable'><i class='fa fa-chevron-left'> Back</i></a>
					</div>
				</div>

				<div class='row sidebar_row'>
					<div class='col-xs-10'>
						<div class='title'>{{caseStudy?.name}} <a *ngIf="canEdit" (click)="funcToggleEditCaseStudy();" class='clickable'><i class='fa fa-pencil'></i></a></div>
					</div>
					<div class='col-xs-2'>
						<a class='btn btn_customizable pull-right' (click)="downloadAsset()">{{trans.trans.downloadAsset.value}}</a>
					</div>
				</div>

				<div class='row' *ngIf="companyDetails">
					<div class='col-xs-6'>
						<div class="row sidebar_row">
							<div class="col-xs-3">
								<img onerror="this.src='/assets/images/missing_logo.jpg'" src="//logo.clearbit.com/{{getHostnameFromRegex(companyDetails.url)}}" class='img-responsive'>
							</div>
							<div class="col-xs-9">
								<div class="row">
									<div class="col-md-4 account-label">{{trans.trans.name_label.value}}:
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name_label" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="col-md-8 account-info">{{companyDetails.name}}</div>
								</div>
								<div class="row" *ngIf="companyDetails.address1">
									<div class="col-md-4 account-label">{{trans.trans.address.value}}:
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.address" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="col-md-8 account-info">{{companyDetails.address1}}</div>
								</div>
								<div class="row" *ngIf="companyDetails.dl_sector_name">
									<div class="col-md-4 account-label">{{trans.trans.sector.value}}:
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.sector" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="col-md-8 account-info">{{companyDetails.dl_sector_name}}</div>
								</div>
								<div class="row" *ngIf="companyDetails.url">
									<div class="col-md-4 account-label">{{trans.trans.website.value}}:
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.website" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="col-md-8 account-info">{{companyDetails.url}}</div>
								</div>
								<div class="row" *ngIf="companyDetails.revenue">
									<div class="col-md-4 account-label">{{trans.trans.revenue.value}}:
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.revenue" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="col-md-8 account-info">{{companyDetails.revenue}} (M)</div>
								</div>
								<div class="row" *ngIf="companyDetails.employees">
									<div class="col-md-4 account-label">{{trans.trans.employees.value}}:
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>
										:
									</div>
									<div class="col-md-8 account-info">{{companyDetails.employees}}</div>
								</div>
							</div>
						</div>
					</div>
          <div class="col-xs-6">
            <h3 style="margin-top: 0px">Reference Url</h3>
  
            <app-error-input-wrapper [showOnTouched]="true" [errorMessage]="errorDict.get('referenceUrlControl')">
              <input placeholder="https://www.value-cloud.com" type="text" class="form-control" [formControl]="referenceUrlControl" />
            </app-error-input-wrapper>
          </div>
				</div>

				<div class='row' *ngIf="caseStudy">
					<div class='col-xs-12'>
						<p-fieldset styleClass='casestudy_legend' class='casestudy_legend' legend="{{trans.trans.testimonials.value}}" [toggleable]="true">
							<app-quote [canEdit]="canEdit" [caseStudyId]="caseStudyId" [quotes]="caseStudy.quotes" (valueChange)="getCaseStudyDetail()"></app-quote>
						</p-fieldset>

						<p-fieldset styleClass='casestudy_legend' class='casestudy_legend' legend="{{trans.trans.sections.value}}" [toggleable]="true">
							<app-sections [canEdit]="canEdit" [caseStudy]="caseStudy" [type]="type" (valueChange)="getCaseStudyDetail()"></app-sections>
						</p-fieldset>

						<p-fieldset styleClass='casestudy_legend' class='casestudy_legend' legend="{{trans.trans.benefits.value}}" [toggleable]="true">
							<app-benefits-casestudy [can_edit]="canEdit" [caseStudy]="caseStudy" [type]="type" (valueChange)="getCaseStudyDetail()"></app-benefits-casestudy>
						</p-fieldset>

						<p-fieldset styleClass='casestudy_legend' class='casestudy_legend' legend="Tags" [toggleable]="true">
							<app-case-study-tags [can_edit]="canEdit" [caseStudy]="caseStudy" (save)="populatePayloadFromEvent($event)"></app-case-study-tags>
						</p-fieldset>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleEditCaseStudy' *ngIf="caseStudy" styleClass="keywest_sidebar p-sidebar-lg" position='right'>

    <ng-container *ngIf="toggleEditCaseStudy">

        <form name="editCaseStudy" #editCaseStudy="ngForm">
            <div class='sidebar_wrapper'>
                <div class='sidebar_header'>
                    {{trans.trans.editCaseStudy.value}}
                    <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.editCaseStudy" (callback)="getTranslations()"></app-editTranslation>
                </div>
                <div class='sidebar_container'>
                    <div class='keywest_panel'>
                        <div class="row">
                            <div class="col-md-12" appNGError [elemControl]="editCaseStudy.controls.name">
                                <label class="sidebar_label">{{trans.trans.name.value}}
                                    <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
                                </label>
                                <input type='text' name="name" [(ngModel)]="caseStudyEdit.name" class='form-control' ret-data="Name" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 tag-column">
                                <label class="sidebar_label">{{trans.trans.tags.value}}</label>
                                <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.tags" (callback)="getTranslations()"></app-editTranslation>
                                <p-chips name="tags" ret-data="Tags" [(ngModel)]="caseStudyEdit.tags" [ngModelOptions]="{standalone: true}"></p-chips>
                            </div>
                        </div>
                    </div>
                </div>

                <div class='btn_container'>
									<div class="col-md-12">
											<app-btn-save [form]='editCaseStudy' (callback)='populatePayloadFromSidebar()' [text]='trans.trans.save.value' class='btn btn_customizable pull-right'></app-btn-save>
											<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
									</div>
                </div>

            </div>
        </form>
    </ng-container>
</p-sidebar>