import { NgModule } from '@angular/core';

import { FactsService } from '@data/services/facts/facts.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { SharedModule } from '../shared/shared.module';
import { ValueMapModule } from '../value-map/value-map.module';
import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { SurveyTemplateCreateComponent } from './survey-template-create/survey-template-create.component';
import { SurveyTemplateComponent } from './survey-template/survey-template.component';
import { SurveyComponent } from './survey/survey.component';
import { ValueTrackersRoutingModule } from './value-trackers-routing.module';
import { DashboardTrackersModule } from './dashboard/tracker.dashboard.module';
import { ModelCaseStudyDetailModule } from 'app/value-map/solution-detail/case-studies/casestudy-detail/casestudy-detail.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TrackersContainerModule } from './trackers_container/trackers-container.module';
import { TrackersContainerComponent } from './trackers_container/trackers-container.component';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { TableModule } from 'primeng/table';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		EditTranslationsModule,
		SharedModule,
		DashboardTrackersModule,
		TrackersContainerModule,
		ValueTrackersRoutingModule,
		ValueMapModule,
        TableModule,
		ModelCaseStudyDetailModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [
		SurveyComponent,
		CreateSurveyComponent,
		SurveyTemplateComponent,
		SurveyTemplateCreateComponent
	],
	providers: [
		TranslationsV2Service,
		ValuerealizationService,
		FactsService,
		Translations
	],
	entryComponents: [TrackersContainerComponent]
})
export class ValueTrackersModule { }
