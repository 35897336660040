<div *ngIf="dashboard == 'v1'">
	<div class='models_panel' style='margin-bottom: 20px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header_small'>
				<div class='row'>
					<div class='col-xs-12'>
						<div class='title'>
							{{ trans.trans.actions.value}}<app-editTranslation *ngIf="showTranslate"  [isTranslationV2]="true" (callback)='getTranslations()' [component]="trans.config.component" [transObj]="trans.trans.actions" key='vp_dashboard.actions.actions'></app-editTranslation>
						</div>
					</div>
				</div>
			</div>
			<div class='row'>
				<div *ngIf="!locked" class='col-xs-12 clickable' style='margin-bottom: 10px;'>
					<span (click)="lockValueProp('lock')"  matTooltip='{{trans.trans.lock.value}}' matTooltipPosition="right"><i class="fa fa-unlock"></i> {{ trans.trans.lock.value}}</span>
					<app-editTranslation *ngIf="showTranslate"  [isTranslationV2]="true" (callback)='getTranslations()' [component]="trans.config.component" [transObj]="trans.trans.lock" key='vp_dashboard.actions.lock'></app-editTranslation>
				</div>
				<div *ngIf="locked" class='col-xs-12 clickable' (click)="lockValueProp('unlock')" style='margin-bottom: 10px;' [ngClass]="{'locked': +valueProp.closed === 1}">
					<span (click)="lockValueProp('unlock')"  matTooltip='{{trans.trans.lock.value}}' matTooltipPosition="below"><i class="fa fa-lock"></i>{{ trans.trans.unlock.value}}</span>
					<app-editTranslation *ngIf="showTranslate"  [isTranslationV2]="true" (callback)='getTranslations()' [component]="trans.config.component" [transObj]="trans.trans.unlock" key='vp_dashboard.actions.unlock'></app-editTranslation>
				</div>

				<div class='col-xs-12 clickable' style='margin-bottom: 10px;'>
					<span (click)="funcShowShare()" matTooltip='{{trans.trans.share.value}}' matTooltipPosition="right"><i class="fa fa-share"></i> {{ trans.trans.share.value}}</span>
					<app-editTranslation *ngIf="showTranslate"  [isTranslationV2]="true" (callback)='getTranslations()' [component]="trans.config.component" [transObj]="trans.trans.share" key='vp_dashboard.actions.share'></app-editTranslation>
				</div>

				<div *ngIf="!fromMVP" class='col-xs-12 clickable' style='margin-bottom: 10px;'>
					<span (click)="showClone = !showClone" matTooltip='{{trans.trans.clone.value}}' container="body" matTooltipPosition="right"><i class="fa fa-file"></i> {{ trans.trans.clone.value}}</span>
					<app-editTranslation *ngIf="showTranslate"  [isTranslationV2]="true" (callback)='getTranslations()' [component]="trans.config.component" [transObj]="trans.trans.clone" key='vp_dashboard.actions.clone'></app-editTranslation>
				</div>

				<div *ngIf="fromMVP" class='col-xs-12 clickable' style='margin-bottom: 10px;'>
					<span (click)="showClone = !showClone" matTooltip='{{trans.trans.clone.value}}' matTooltipPosition="right"><i class="fa fa-file"></i> {{ trans.trans.clone.value}}</span>
					<app-editTranslation *ngIf="showTranslate"  [isTranslationV2]="true" (callback)='getTranslations()' [component]="trans.config.component" [transObj]="trans.trans.clone" key='vp_dashboard.actions.clone'></app-editTranslation>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div *ngIf="dashboard == 'v2'"> -->
<div *ngIf="dashboard == 'v2' && !locked" (click)="lockValueProp('lock')" class='pull-right clickable' matTooltip='{{trans.trans.lock.value}}' matTooltipPosition="below">
	<i class="prop-icon icon-lock-open" style='font-size: 18px;'></i>
</div>
<div *ngIf="dashboard == 'v2' && locked" (click)="lockValueProp('unlock')" class='pull-right clickable' [ngClass]="{'locked': +valueProp.closed === 1}" matTooltip='{{trans.trans.lock.value}}' matTooltipPosition="below">
	<i class="prop-icon icon-lock" style='font-size: 18px;'></i>
</div>

<!-- <div *ngIf="dashboard == 'v2'" (click)="openModal(valuePropShare)" class='pull-right clickable' style='margin-right: 10px;'> -->
<div *ngIf="dashboard == 'v2'" (click)="funcShowShare()" class='pull-right clickable' style='margin-right: 10px;'>
	<span matTooltip='{{trans.trans.share_this_value_prop.value}}' matTooltipPosition="below"><i class="prop-icon icon-share" style='font-size: 18px;'></i></span>
</div>


<p-sidebar [(visible)]="showClone" position='right' styleClass="keywest_sidebar p-sidebar-lg">
	<app-vp-clone [valueProp]='valueProp' (callbackCancel)='showClone = !showClone'></app-vp-clone>
</p-sidebar>

<div *ngIf="dashboard == 'v2' && !fromMVP && !showClone" (click)="showClone = !showClone" placement="bottom" class='pull-right clickable' style='margin-right: 10px;'>
	<span matTooltip='{{trans.trans.clone_this_value_prop.value}}' matTooltipPosition="below"><i class="prop-icon icon-docs" style='font-size: 18px;'></i></span>
</div>

<div *ngIf="dashboard == 'v2' && fromMVP && !showClone" (click)="showClone = !showClone" class='pull-right clickable' style='margin-right: 10px;'>
	<span matTooltip='{{trans.trans.clone_this_value_prop.value}}' matTooltipPosition="below"><i class="prop-icon icon-docs" style='font-size: 18px;'></i></span>
</div>



<ng-template #valuePropShare let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ trans.trans.valuePropShares.value }}<app-editTranslation *ngIf="showTranslate"  [isTranslationV2]="true" (callback)='getTranslations()' [component]="trans.config.component" [transObj]="trans.trans.valuePropShares" key='props.tabDetails.actions.valuePropShares'></app-editTranslation>
		</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
</ng-template>