import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'ae_list',
    display_name: 'Solution List Edit/Add',
    description: 'List of Solutions'
  };

  public trans: any = {
    model_name: 'Model name',
    categories: 'Categories',
    average_dep_time: 'Average Deployment Time (Months)',
    description: 'Description',
    add_model: 'Add Model',
    edit_model: 'Edit Model',
    save: 'Save',
    update: 'Update',
    back: 'Back',
    areYouSure: 'Are you sure?'
  };
}
