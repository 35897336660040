import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitValidation } from '@shared/models/benefits.models';
import { FactorValidation } from '@shared/models/factors.models';
import { take } from 'rxjs/operators';
import { ValidationTranslations } from './validation.translations';
interface ViewOption {
  label: string;
  value: string;
}
@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationComponent implements OnInit {
  @Input() solutionID = null;
  @Input() benefitValidations: BenefitValidation;
  @Input() factorValidations: FactorValidation;
  @Output() validationErrors = new EventEmitter<number>();
  @Output() onUpdate = new EventEmitter();

  public currentView: 'benefits' | 'factors' = 'benefits';
  public viewOptions: ViewOption[] = [];

  constructor(public trans: ValidationTranslations, private translationService: TranslationsV2Service, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  public getTranslations() {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
        this.viewOptions = [
          { label: this.trans.t.benefits, value: 'benefits' },
          { label: this.trans.t.factors, value: 'factors' },
        ];
        this.cd.markForCheck();
      });
  }

  public update() {
    this.onUpdate.emit();
  }
}
