import { Injectable } from '@angular/core';

@Injectable ()

export class NotifyMarketingTranslation {

    public config: any = {
        component: 'notifyMarketing',
        display_name: 'Notify Marketing',
        description: 'Notify marketing about a value realization'
    };

    public trans: any = {
        notify: 'Notify',
        name: 'Name',
        notifyMarketing: 'Notify Marketing'
    };
}
