import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ValuePropDashboardROIComponent } from './roi.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KeyFiguresModule } from '../key_figures/key_figures.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { BarLinechartModule } from 'app/common/chart/bar-line-chart/bar-line-chart.module';
import { Doughnut5ChartModule } from 'app/common/chart/doughnut5/doughnut5-chart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DefaultTranslations } from './roi.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { MatTooltipModule } from '@angular/material/tooltip';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
    MatTooltipModule,
		TableChartModule,
		CommonModule,
		FormsModule,
		ApplicationPipesModule,
		KeyFiguresModule,
		BarLinechartModule,
		Doughnut5ChartModule,
		EditTranslationsModule,
	],
	declarations: [
		ValuePropDashboardROIComponent
	],
	providers: [DefaultTranslations, TranslationsV2Service],
	exports: [ValuePropDashboardROIComponent]
})
export class ValuePropDashboardROIModule { }
