<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ trans.trans.addProofPoints.value }}
  </div>

  <div class="sidebar_container">
    <div class="keywest_panel">
      <div class="row">
        <div class="col-xs-4 redact-switch">
          <p-inputSwitch [(ngModel)]="isRedact"></p-inputSwitch>
          <span>{{ trans.trans.redact.value }}</span>
        </div>
      </div>
      <div class="row name-section" *ngIf="caseStudy">
        <div class="col-xs-8">
          <div class="title" style="padding-top: 0px" *ngIf="!editingName">
            {{ this.caseStudy.name }}
            <i *ngIf="canEdit" [matTooltip]="trans.trans.editName.value" (click)="editingName = true" class="fa fa-pencil clickable" style="margin-left: 10px"></i>
          </div>

          <div class="title" style="padding-top: 0px" *ngIf="editingName">
            <div class="input-group">
              <input type="text" [(ngModel)]="caseStudy.name" class="form-control" />
              <span class="input-group-addon" (click)="editingName = !editingName" [matTooltip]="this.trans.trans.save.value"><i class="fa fa-save"></i></span>
              <span class="input-group-addon" (click)="cancelNameEdit()" [matTooltip]="this.trans.trans.cancel.value"><i class="fa fa-times"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <label for="" class="control-label">{{ trans.trans.modelName.value }}:</label>
          <p-dropdown
            appendTo="body"
            [autoDisplayFirst]="false"
            panelStyleClass="dropdown-item-font"
            [options]="modelList"
            [(ngModel)]="model"
            optionLabel="name"
            class="keywest_dropdown dropdown-theme pull-right"
          >
          </p-dropdown>
        </div>
      </div>

      <div class="text-center actionLoad">
        <img class="actionLoader" *ngIf="showActionLoader" [src]="fullImagePath" />
      </div>
      <div class="row">
        <div class="col-xs-12">
          <h3 class="detailedDescriptionHeader">{{ trans.trans.accountDetails.value }}</h3>
        </div>
      </div>
      <div class="row">
        <div *ngIf="showCompanyDetails">
          <div class="col-md-10">
            <ul class="list-unstyled account-info-wrapper">
              <li class="row">
                <div class="col-md-2 account-label">{{ trans.trans.name.value }}:</div>
                <div class="col-md-4 account-info">{{ companyDetails.name }}</div>
                <div class="col-md-2 account-label">{{ trans.trans.Website.value }}:</div>
                <div class="col-md-4 account-info" *ngIf="companyDetails.url">{{ companyDetails.url }}</div>
              </li>
              <li class="row">
                <div class="col-md-2 account-label">{{ trans.trans.address.value }}:</div>
                <div class="col-md-4 account-info">{{ companyDetails.address1 }}</div>
                <div class="col-md-2 account-label">{{ trans.trans.revenue.value }}:</div>
                <div class="col-md-4 account-info">{{ companyDetails.revenue }}</div>
              </li>
              <li class="row">
                <div class="col-md-2 account-label">{{ trans.trans.sector.value }}:</div>
                <div class="col-md-4 account-info">{{ companyDetails.dl_sector_name }}</div>
                <div class="col-md-2 account-label">{{ trans.trans.employees.value }}:</div>
                <div class="col-md-4 account-info">{{ companyDetails.employees }}</div>
              </li>
            </ul>
          </div>
          <div class="col-md-7" *ngIf="companyDetails.synopsis">
            <div class="account-label description-label">{{ trans.trans.description.value }}</div>
            <div class="account-info">
              <p>{{ companyDetails.synopsis }}</p>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <label
            >{{ trans.trans.tags.value }}
            <small>({{ trans.trans.pressEnterToCreate.value }})</small>
          </label>
          <div class="input-group">
            <p-chips [(ngModel)]="tags" class="tag_menu"></p-chips>
          </div>
        </div>
        <div class="col-xs-12 sections" *ngIf="caseStudy">
          <p-fieldset styleClass="casestudy_legend" class="casestudy_legend" legend="Testimonials" [toggleable]="true">
            <app-quote [canEdit]="canEdit" [isModal]="false" [caseStudyId]="caseStudy.id" [quotes]="caseStudy.quotes" (valueChange)="refreshCaseStudy()"></app-quote>
          </p-fieldset>

          <p-fieldset styleClass="casestudy_legend" class="casestudy_legend" legend="Sections" [toggleable]="true">
            <app-sections [canEdit]="canEdit" [isModal]="false" [caseStudy]="caseStudy" type="solution" (valueChange)="refreshCaseStudy()"></app-sections>
          </p-fieldset>

          <p-fieldset styleClass="casestudy_legend" class="casestudy_legend" legend="Benefits" [toggleable]="true">
            <app-benefits-casestudy [can_edit]="canEdit" [isModal]="false" [caseStudy]="caseStudy" type="solution" (valueChange)="refreshCaseStudy()"></app-benefits-casestudy>
          </p-fieldset>
        </div>
      </div>
    </div>
  </div>
  <div class="btn_container">
    <div class="row">
      <div class="col-md-12">
        <button type="submit" class="btn btn_customizable pull-right" (click)="saveCaseStudy()" [disabled]="detailedDescription || model == null" pButton [loading]="isLoading">
          {{ trans.trans.save.value }}
        </button>
        <app-are-you-sure
          matTooltipPosition="above"
          [message]="trans.trans.are_you_sure.value"
          class="btn cancel-confirm pull-right marg-h"
          icon=""
          [text]="trans.trans.back.value"
          (callbackYes)="close()"
        ></app-are-you-sure>
      </div>
    </div>
  </div>
</div>
