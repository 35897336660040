import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PainPoint } from '@data/services/valueposition/models/painpoint.interface';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelV2OPObjectiveTranslations } from './op_objectives.translation';

@Component({
  selector: 'app-value-map-op-objectives',
  templateUrl: './op_objectives.component.html',
  styleUrls: ['./op_objectives.component.scss'],
})
export class ValueMapOpObjectivesComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;

  showAdd = false;
  painPoints: PainPoint[] = [];
  account_id: string;

  strSearch = '';
  loading = true;

  cols: Array<{field: string; header: string}> = [{ field: 'name', header: 'Operational Objective' }];
  mode = 'add';
  toggleAddEditOpObjective = false;
  ngUnsubscribe$ = new Subject();
  style2022$: Observable<boolean>;

  selectedElem: PainPoint = {
    name: '',
    description: '',
    id: '',
  };

  showTranslate = false;

  constructor(
    private painPointService: OperationalObjectivesService,
    private commonService: CommonService,
    public trans: ModelV2OPObjectiveTranslations,
    private translationService: TranslationsV2Service,
    private solutionService: SolutionService,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');
    this.funcGet();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  closeAddEditOpObjective(): void {
    this.toggleAddEditOpObjective = false;
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  openAddOpObjectives(): void {
    this.mode = 'add';
    // tslint:disable-next-line: forin
    for (const k in this.selectedElem) {
      this.selectedElem[k] = null;
    }
    this.toggleAddEditOpObjective = true;
  }

  funcToggleEdit(elem: PainPoint): void {
    this.mode = 'edit';
    this.selectedElem = Object.assign({}, elem);
    this.toggleAddEditOpObjective = true;
  }

  funcToggleView(elem: PainPoint): void {
    this.selectedElem = Object.assign({}, elem);
    this.toggleAddEditOpObjective = true;
  }

  funcGet(): void {
    this.loading = true;
    this.painPointService
      .getOperationalObjectives(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        if (res.result) {
          this.loading = false;
          this.painPoints = res && res.result && res.result.length ? res.result : [];

          this.solutionService.refreshModelsTotals.next();
        }
      });
  }

  funcDelete(elem: PainPoint): void {
    this.painPointService.deleteOperationalObjectives(this.account_id, elem.id).subscribe(() => {
      this.funcGet();
    });
  }
}
