<div class='sidebar_wrapper'>
    <div class='sidebar_header'>
        Notify Marketing
        <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
        [isTranslationV2]="true" [transObj]="trans.trans.notifyMarketing" (callback)="getTranslations()"></app-editTranslation>
    </div>

    <div class='sidebar_container'>
        <div class='keywest_panel'>
<!--
            <div class='row' >
                <div class="col-xs-12">
                    <label class="sidebar_label">Name
                    </label>
                    <input class="form-control" type="text" [(ngModel)]="realizationName">
                </div>
            </div> -->

            <div class='row' >
                <div class="col-xs-12">
                    <label class="sidebar_label">Select Marketing User
                    </label>
                    <p-dropdown appendTo="body" class="keywest_dropdown" [autoDisplayFirst]='false' optionLabel='name' [options]="users" name="scale_type_id" [(ngModel)]="user" [filter]="true" filterBy="name"></p-dropdown>
                </div>
            </div>

        </div>
    </div>
</div>

<div class='btn_container'>
    <div class='row'>
        <div class="col-md-12">
            <button  (click)='notifyMarketing()' class='btn btn_customizable pull-right'>Notify</button>
        </div>
    </div>
</div>