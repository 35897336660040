<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='keywest_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>{{ trans.trans.factors.value }}</div>
		</div>
		<div class='col-xs-6'>
			<a (click)='funcSaveAll()' *ngIf="updated && can_edit" class='btn btn_customizable pull-right'>{{ trans.trans.saveAll.value }}</a>
		</div>
	</div>
</div>

<div class='row'>
	<div class='col-xs-4'>
		<div class="input-group keywest_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input (keyup)="tableFactorsList.filter($any($event.target).value, 'name', 'contains')" [(ngModel)]="strSearch" type='text' [placeholder]='trans.trans.search.value' class='form-control'>
			<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableFactorsList.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
		</div>
	</div>
	<div class='col-xs-8'>
		<span class="clickable pull-right" (click)='row.toggle($event)'>{{ trans.trans.rowLimit.value }} {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
	</div>
</div>

<div *ngIf="loading">
	<p-progressBar mode="indeterminate"></p-progressBar>
</div>

<div class='row'>
	<div class='col-xs-12'>
		<p-table #tableFactorsList dataKey="factor_id" [value]="FactorList" [columns]="cols" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th style="width: 3em"></th>
					<th *ngFor="let col of columns" [pSortableColumn]="col.field">
						{{col.header}}
						<p-sortIcon [field]="col.field"></p-sortIcon>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-expanded="expanded" let-list>
				<tr>
					<td>
						<div href="#" #rowTrigger [pRowToggler]="list">
							<i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
						</div>
					</td>
					<td style='width: 100%;'>{{list.name}}
						<i *ngIf="list.description && !(style2022$ | async)" [matTooltip]='list.description' class='fa fa-info-circle'></i>
						<span *ngIf="list.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="list.description">
							info
						</span>
					</td>
					<td>
						<input type='text' *ngIf="can_edit" (keyup)='updated = true; list.updated = true;' [(ngModel)]="list.value" class='form-control pull-right' style='width: 150px !important;'>
						<span *ngIf="!can_edit" class='pull-right'>{{list.value_fmt}}</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="rowexpansion" let-rowData let-columns='columns'>
				<tr class="not-hover">
					<td [attr.colspan]="columns.length + 1">
						<h3 class="sub-title-weight">{{ trans.trans.includedInBenefits.value }}</h3>
						<table>
							<thead>
								<tr>
									<th class="sub-title-weight">{{ trans.trans.benefitName.value }}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of rowData.metrics">
									<td style='padding-left: 0px;'>{{item.metric_name}}</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
