import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { NotificationService } from '@services/notification.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { CommonService } from '@data/services/common/common.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './combined.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DashboardSimpleImplementation } from '../dashboard_simple/simple.implementation';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Solution, ValueProp } from '@shared/models/value-prop.model';
import { Permissions } from '../dashboard_customer/permissions';
import { Company } from '@shared/models/company.model';
import { ValuePropSituation } from '@data/services/valueprop/models/situation.model';
import { Currency, NumberFormat } from '@data/services/valueprop/models/situation-opportunity.model';
import { BaselineType } from '@data/services/valueprop/models/baselineType.model';
import { cloneDeep } from 'lodash';

interface CombinedSituation {
  number_format_types: NumberFormat[];
  currency_types: Currency[];
  customer_type: string;
  baseline_type: BaselineType;
  currency_type_id: string;
  term: string;
  baseline_type_id: string;
  deployment_time: number;
  customer_type_id: string;
  gross_profit: string;
  number_format: string;
  conversion_rate: string;
  wacc: string;
  opportunity_id: string;
}

@Component({
  selector: 'app-dashboard-scenario-combined',
  templateUrl: './combined.component.html',
})
export class ScenarioCombinedComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() props: any;
  @Input() view = 'simple';
  @Input() contextualHelp: any;
  @Input() valueProp: ValueProp;
  @Input() valuePropId: string | number;
  @Input() year1BreakDownChartData: string;
  @Input() costBenefitChartData: string;
  @Input() dashboard: string;
  @Input() isDev: boolean;
  @Input() locked = false;
  @Input() showTranslate = false;
  @Input() permissions: Permissions;
  @Output() callback = new EventEmitter();
  @Output() funcCallback = new EventEmitter();

  showSituationEditing = false;

  hasModels = false;
  situationLoader = false;
  headerColor = 'blue';
  showAccountEdit = false;
  showModelsEditing = false;
  showAdvancedEditing = false;

  loader = false;
  countryDetails: { id: string; name: string };
  industrySector: { id: string; name: string };
  industryId: { id: string; name: string };
  stateId: { name: string };
  company: Company & { gross_profit_fmt: string };
  supplementals: { [key: string]: any };
  showEdit: boolean;
  industrySectors: Array<{ [key: string]: any }>;
  countries: Array<{ [key: string]: any }>;
  statesLoader: boolean;
  states: Array<{ [key: string]: any }>;
  industriesLoader: boolean;
  industriesShow: boolean;
  industries: Array<{ [key: string]: any }>;
  showCategoryTypeLoader: boolean;
  selectedSolutionsLength: number;
  availableSolutions: Array<Solution & { solution_category_id?: string; isChecked?: boolean; selectedCategory?: boolean; showeditable?: boolean; tempQuantity?: string | number }>;
  solutionCategoryList: Array<{ id: string | number; category: string }>;
  categoryType: string | number;
  showModelsLoader: boolean;
  selectedModels: Array<{ solutionId: string; quantity: number }> = [];
  showCategoryLoader: boolean;
  situationLength: number;
  showActionLoaderModel: boolean;
  showSituation: boolean;
  user = 'system';
  grossProfitFeature: boolean;
  customerTypeFeature: boolean;
  opportunityIdFeature: boolean;
  aid: string;
  opportunityIdFeatureRequired: boolean;
  situation: CombinedSituation;
  term: string;
  currencyId: { id: number | string };
  customerTypes: Array<{ id: string; name: string }>;
  baselineTypes: Array<{ id: string; name: string }>;
  advancedTerms: Array<{ id: string; name: string; term: string }>;
  imageUrl: string;
  fullImagePath: string;
  loading = false;

  scalers: Array<{ name: string; value_fmt: string; value: number; greater_than_zero: any; id: string; description: string; source_type_id: string }> = [];
  scalersControl: Array<{ name: string; value_fmt: string; value: number; greater_than_zero: any; id: string; description: string; source_type_id: string }>;
  scalersLoader: boolean;
  masterScalerList: Array<{ value: number; name: string; master_scale_type_id: string; greater_than_zero: any; id: string }> = [];
  showSizeYourValue = false;

  availableSituations: ValuePropSituation[];
  showActionLoader: boolean;
  availableSituationsTemp: ValuePropSituation[];
  radioSituations: ValuePropSituation;

  subscriptionsaveChanges: Subscription;

  situationsSimple: Array<{ [key: string]: any }> = [];
  isDefault: string;
  currency: any;
  feature26: boolean;

  hasRadioButtonFeature = false;
  masterCalculateLoading = false;
  canEdit = false;
  ngUnsubscribe = new Subject();
  feature64 = false;

  selectedLanguage: { id: string; name: string };
  feature101 = false;
  isShared = false;
  crm: string;

  constructor(
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private valuepropService: ValuepropService,
    private factsService: FactsService,
    private notificationService: NotificationService,
    private solutionService: SolutionService,
    private RS: RepSimpleViewService,
    private valuepositionService: ValuepositionService,
    private commonService: CommonService,
    private masterValuePropService: MasterValuePropService,
    private sI: DashboardSimpleImplementation
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.aid = sessionStorage.getItem('aid');
    this.feature64 = this.commonService.checkFeature('64');
    this.feature101 = this.commonService.checkFeature('101');
    this.loading = true;
    this.headerColor = localStorage.getItem('header_color');

    if (this.commonService.checkFeature('13')) {
      this.grossProfitFeature = true;
    }

    if (this.commonService.checkFeature('20')) {
      this.customerTypeFeature = true;
    }

    if (this.commonService.checkFeature('22')) {
      this.opportunityIdFeature = true;
    }

    if (this.aid !== '20') {
      this.opportunityIdFeatureRequired = false;
    }
    this.funcReload();

    if (this.commonService.checkFeature('26')) {
      this.feature26 = true;
    }

    this.canEdit = this.valueProp.vp_can_edit ? this.valueProp.vp_can_edit : true;
    if (this.view === 'share') {
      this.canEdit = this.permissions.account_info_editable;
      this.isShared = true;
    }
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.valuepropService.reloadScalers$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.loadScalers();
      }
    });

    this.hasRadioButtonFeature = this.commonService.checkFeature(54);
    this.sI.childCombined = this;
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  refreshTranslation(): void {
    this.getTranslations();
  }

  funcShouldCallback(res): void {
    if (!this.hasModels) {
      this.funcCallback.emit(null);
      return;
    }
    this.funcCallback.emit(res);
  }

  funcReload(): void {
    this.funcGetSituationsSimple();
    this.getValuePropCompany(this.valueProp.id);
    this.getAvailableSolutions();
    this.getCountries();
    this.getCustomerTypes();
    this.getBaselineTypes();
    this.getValuePropSituation(this.valueProp.id);
    this.getTerms();
    this.getIndustrySectors();
    this.loadScalers();
    this.readAvailableSituations();
  }

  funcGetSituationsSimple(): void {
    this.RS.getSituationsSimple(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.situationsSimple = res.result;
      });
  }

  getValuePropCompany(id): void {
    this.loader = true;
    this.valuepropService
      .getValuePropCompany(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.countryDetails = { id: response.result.company.country_id, name: response.result.company.country };
          this.industrySector = { id: response.result.company.dl_sector_id, name: response.result.company.dl_sector_name };
          this.industryId = { id: response.result.company.industry_id, name: response.result.company.industry_name };
          this.stateId = { name: response.result.company.state };

          this.getStatesByCountries();
          this.getIndustryBySectors();

          this.loader = false;
          this.company = response.result.company;
          this.supplementals = response.result.supplementals;
        }
      });
  }

  funcUpdateCompany(): void {
    if (!this.company.name) {
      return;
    }

    if (!this.countryDetails.id) {
      return;
    }
    this.company.country_id = this.countryDetails.id;

    if (!this.industrySector.id) {
      return;
    }
    this.company.dl_sector_id = this.industrySector.id;

    if (!this.industryId.id) {
      return;
    }
    this.company.industry_id = this.industryId.id;

    if (!this.company.revenue_fmt) {
      return;
    }
    if (!this.company.employees) {
      return;
    }

    this.company.state = this.stateId.name;

    this.valuepropService
      .updateCompany(this.company.id, this.company)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          if (!response.result.success) {
            this.showEdit = false;
            this.notificationService.error(response.result.message, false);
          } else {
            this.showEdit = false;
            this.getValuePropCompany(this.company.id);
            this.funcReload();
            this.showAccountEdit = false;
            this.notificationService.success('Company info updated successfully', false);
          }
        }
      });
  }

  getIndustrySectors(): void {
    this.factsService
      .getSectors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.industrySectors = response.result;
      });
  }

  getCountries(): void {
    this.factsService
      .getCountries()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.countries = response.result.countries;
      });
  }

  getStatesByCountries(): void {
    let country_id = this.countryDetails.id;
    this.statesLoader = true;

    this.factsService
      .getStatesByCountries(country_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.statesLoader = false;
        this.states = response.result.states;
      });
  }

  getIndustryBySectors(): void {
    let sectorId = this.industrySector.id;

    this.industriesLoader = this.industriesShow = true;
    this.factsService
      .getIndustriesBySector(sectorId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.industriesLoader = this.industriesShow = false;
        this.industries = response.result.dl_industries;
      });
  }

  getSolution(categoryId): void {
    this.showCategoryTypeLoader = true;
    this.selectedSolutionsLength = 0;
    if (this.availableSolutions) {
      this.availableSolutions = this.availableSolutions.map((item) => {
        item['selectedCategory'] = false;
        if (item.solution_category_id != null && item.solution_category_id.indexOf(categoryId) >= 0) {
          this.selectedSolutionsLength++;
          item['selectedCategory'] = true;
        }
        return item;
      });

      this.showCategoryTypeLoader = false;
    }
  }

  getSolutionCategoryList(): void {
    this.solutionService
      .getSolutionCategoryListwithVPID()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.solutionCategoryList = result.result;
        if (this.solutionCategoryList && this.solutionCategoryList.length) {
          this.categoryType = this.solutionCategoryList[0]['id'];
          if (this.categoryType) {
            this.getSolution(this.solutionCategoryList[0]['id']);
          }
        }

        this.showModelsLoader = false;
      });
  }

  getAvailableSolutions(): void {
    this.hasModels = false;
    this.showModelsLoader = true;
    if (this.view == 'share') {
      this.solutionService
        .getAvailableSolutionsShare(this.valueProp.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          this.availableSolutions = result.result;
          if (this.availableSolutions) {
            this.availableSolutions = this.availableSolutions.map((item) => {
              item['quantity'] = 1;
              item['isChecked'] = true;
              return item;
            });
          }
          if (this.availableSolutions.length) {
            this.hasModels = true;
          }
          this.getSolutionCategoryList();
        });
    } else {
      this.solutionService
        .getAvailableSolutionsPlain(this.valueProp.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          this.availableSolutions = result.result;
          if (this.availableSolutions) {
            this.availableSolutions = this.availableSolutions.map((item) => {
              item['quantity'] = 1;
              return item;
            });
          }
          if (this.availableSolutions.length == 1) {
            this.availableSolutions = this.availableSolutions.map((item) => {
              item['quantity'] = 1;
              return item;
            });
          }

          this.getSolutionCategoryList();
          this.getSolutionsUsedInValueProps();
        });
    }
  }

  quantityAssign(id, quantityValue): void {
    let quantityCheck = this.selectedModels.filter((item) => {
      return item['solutionId'] == id;
    });

    if (quantityCheck.length > 0) {
      if (this.selectedModels && this.selectedModels.length) {
        for (let i = 0; i < this.selectedModels.length; i++) {
          if (this.selectedModels[i]['solutionId'] == id) {
            this.selectedModels[i]['quantity'] = quantityValue;
          }
        }
      }
    }
    this.createSolution();
  }

  checkModels(params, quantity, event): void {
    if (this.hasRadioButtonFeature) {
      this.selectedModels = [];
    }
    if (event.target.checked == true) {
      this.showCategoryLoader = true;
      const tempModel = {
        solutionId: params,
        quantity: quantity,
      };
      this.selectedModels.push(tempModel);
    } else if (event.target.checked == false) {
      this.situationLength = 0;

      this.selectedModels = this.selectedModels.filter((impact: any) => {
        return impact['solutionId'] !== params;
      });
    }
  }

  quantityEnabled(event): void {
    event.preventDefault();
  }

  closeSolution(elem): void {
    this.getAvailableSolutions();
    elem.funcCancel();
    this.showModelsEditing = false;
  }

  closeSituations(): void {
    this.situationLoader = false;
    this.readAvailableSituations();
    this.showSituationEditing = false;
  }

  createSolution(): void {
    if (this.selectedModels) {
      const selectedModels = this.selectedModels.map((impact) => {
        return impact['solutionId'];
      });

      const selectedQuantity = this.selectedModels.map((impact) => {
        return impact['quantity'];
      });

      this.showActionLoaderModel = true;

      const payload = {
        account_solution_id: selectedModels.join(','),
        value_prop_id: this.valueProp.id,
        quantity: selectedQuantity.join(','),
        delete_vp_solutions: 1,
      };

      this.valuepositionService
        .quickCreateSolution(this.valueProp.id, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          if (result.result) {
            this.showActionLoaderModel = false;
            this.showModelsEditing = false;
            this.funcReload();

            this.notificationService.success('Solution assigned Successfully', false);
          }
          this.showSituation = true;
        });
    }
  }

  getSolutionsUsedInValueProps(): void {
    this.hasModels = false;
    let id = this.valueProp.id ? this.valueProp.id : this.valuePropId;
    this.valuepositionService
      .getValuePropSolutions(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          let selectedSolutions = response.result;
          let solutionIds = selectedSolutions.map((solution) => solution['account_solution_id']);
          if (this.availableSolutions && this.availableSolutions.length) {
            this.availableSolutions.forEach((solution) => {
              let index = solutionIds.indexOf(solution['id']);
              if (index != -1) {
                this.selectedModels.push({
                  solutionId: solution['id'],
                  quantity: Number(selectedSolutions[index]['quantity']),
                });

                solution['isChecked'] = true;
                this.hasModels = true;
                solution['quantity'] = selectedSolutions[index]['quantity'];
              }
            });
          }
        }
      });
  }

  getOpportunityId(): void {
    this.valuepropService
      .getOpportunityId(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.situation.opportunity_id = response.result.opportunity_id;
        }
      });
  }

  getValuePropSituation(id): void {
    this.valuepropService
      .getSituation(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.term = response.result.term;

          this.currencyId = { id: response.result.currency_type_id };
          this.situation = response.result;
          this.currency = this.situation['currency_types'].filter((x) => x['id'] == this.situation['currency_type_id'])[0];
          if (this.opportunityIdFeature) {
            this.getOpportunityId();
          }
        }
      });
    this.loading = false;
  }

  getCustomerTypes(): void {
    this.valuepropService
      .getCustomerTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.customerTypes = response.result;
        }
      });
  }

  getBaselineTypes(): void {
    this.valuepropService
      .getBaselineTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.baselineTypes = response.result;
        }
      });
  }

  getTerms(): void {
    this.valuepropService
      .getTerms(sessionStorage.getItem('aid'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.advancedTerms = response.result;
        }
      });
  }

  readAvailableSituations(): void {
    this.showActionLoader = true;
    this.availableSituations = [];

    this.valuepropService
      .readAvailableSituations(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        let data = response.result;
        this.availableSituations = data;
        this.showActionLoader = false;
      });
  }

  masterScalerCalculate(): void {
    let payload = { scalers: [] };
    this.masterCalculateLoading = true;
    if (this.masterScalerList && this.masterScalerList.length) {
      for (let i = 0; i < this.masterScalerList.length; i++) {
        payload.scalers.push({
          master_scale_type_id: this.masterScalerList[i].master_scale_type_id,
          value: this.masterScalerList[i].value,
        });
      }
    }

    this.masterValuePropService
      .modifyMasterScaler(this.valueProp.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.masterCalculateLoading = false;
        this.loadScalers();
      });
  }

  loadScalers(): void {
    this.scalers = [];
    const searchLimit = this.valueProp.id;
    this.scalersLoader = true;
    this.valuepropService
      .getScalers(searchLimit, 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.scalers = response.result.scalers;

          this.isDefault = response.result.is_default;

          this.masterScalerList = response.result.master_scalers;
          this.masterScalerList = this.masterScalerList.map((item) => {
            if (item['greater_than_zero'] == '1') {
              item['greater_than_zero'] = true;
            } else {
              {
                item['greater_than_zero'] = false;
              }
            }
            return item;
          });

          this.scalers = this.scalers.map((item) => {
            if (item['name'] == 'includeDNS') {
              item['value'] = 0;
            }
            if (item['greater_than_zero'] == '1') {
              item['greater_than_zero'] = true;
            } else {
              {
                item['greater_than_zero'] = false;
              }
            }
            return item;
          });
          this.scalers = this.scalers.filter((item) => {
            return item['name'] != 'Does Not Scale';
          });
          this.scalersControl = cloneDeep(this.scalers);

          this.scalersLoader = false;
        }
      });
  }

  closeScaler(elem): void {
    this.loadScalers();
    elem.funcCancel();
    this.showSizeYourValue = false;
  }

  createScaler(): void {
    for (let s = 0; s < this.scalers.length; s++) {
      let scale = this.scalers[s];
      scale.source_type_id = this.checkScaler(scale.id) ? '4' : scale.source_type_id;
      if (scale.greater_than_zero && (scale.value == 0 || !scale.value)) {
        this.notificationService.error(scale.name + ' is required', false);
      }
    }

    const payload = {
      value_prop_scalers: this.scalers,
      angular_dev: 1,
    };

    const searchLimit = this.valueProp.id;
    this.scalersLoader = true;
    this.valuepropService
      .createScalers(searchLimit, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.funcReload();
        this.callback.emit();
        if (response.result && response.result.success !== false) {
          this.valuepropService.refreshBenefitDetail.next('');
          this.notificationService.success('Scalers updated', false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.showSizeYourValue = false;
        this.scalersLoader = false;
      });
  }

  funcCombinedModelsScalers(_res): Promise<boolean> {
    let arr = [];
    return new Promise((resolve) => {
      try {
        arr = this.availableSolutions.filter((x) => x.isChecked == true);
      } catch (e) {
        this.notificationService.error('Please select at least 1 model', false);
        resolve(false);
        return false;
      }
      if (!arr.length) {
        this.notificationService.error('Please select at least 1 model', false);
        resolve(false);
        return false;
      }

      if (this.isDefault == '1') {
        for (let s = 0; s < this.scalers.length; s++) {
          let scale = this.scalers[s];
          if (scale.greater_than_zero && (scale.value == 0 || !scale.value)) {
            this.notificationService.error(scale.name + ' is required', false);
            return false;
          }
        }

        const payload2 = {
          value_prop_scalers: this.scalers,
          angular_dev: 1,
        };
        const searchLimit = this.valueProp.id;
        this.scalersLoader = true;
        this.valuepropService
          .createScalers(searchLimit, payload2)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  private checkScaler(id: string): boolean {
    const scaler = this.scalers.find((scaler) => scaler.id === id);
    const controlScaler = this.scalersControl.find((scaler) => scaler.id === id);
    if (scaler && controlScaler) {
      return String(scaler.value) !== String(controlScaler.value);
    } else {
      return false;
    }
  }
}
export class Situation {
  baseline_type_id: number;
  customer_type_id: any;
  deployment_time: any;
  gross_profit: any;
  number_format: any;
  conversion_rate: any;
  wacc: any;
  opportunity_id: any;
}
