import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuePropFlowButtonsTranslations } from './value-prop-flow-buttons.translation';
import { ValuePropFlowButtonsComponent } from './value-prop-flow-buttons.component';

@NgModule({
  declarations: [
    ValuePropFlowButtonsComponent
  ],
  exports: [
    ValuePropFlowButtonsComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    TranslationsV2Service,
    ValuePropFlowButtonsTranslations
  ]
})
export class ValuePropFlowButtonsModule {}