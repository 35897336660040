import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BenefitsImplementation } from '../../benefits.implementation';
import { Translations } from '@data/services/translation/translation.service';
import { Observable } from 'rxjs';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-benefit-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class BenefitsSurveyComponent implements OnInit{
	@Input() benefitCategory: any;
	tot: number = 0;
	style2022$: Observable<boolean>;
	constructor(
		public trans: Translations,
		public bI: BenefitsImplementation,
		private styleService: StyleService
	) {
	
	}

	funcTot(c) {
		this.tot = c;
	}
	
	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;
	}
}