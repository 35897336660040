import { Injectable } from '@angular/core';

@Injectable()
export class BenefitsCasestudyTranslations {
  public config: any = {
    component: 'benefitsCasetudy',
    display_name: 'Case Study Benefits',
    description: 'Benefits of a case study',
  };

  public trans: any = {
    tieToBenefit: 'Tie to Benefit',
    save: 'Save',
    benefit: 'Benefit',
    description: 'Description',
    improvement: 'Improvement',
    name_label: 'Name',
    no_benefits_found: 'No Benefits Found',
    type: 'Type',
    update: 'Update',
    update_benefit: 'Update Benefit',
  };
}
