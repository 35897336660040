<p-progressBar *ngIf="dropdownValuesLoading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

<form name="addBenefitsForm" #addBenefitsForm="ngForm" *ngIf="!dropdownValuesLoading">
  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <div class="title">
          {{ sidebar_title }}
        </div>
      </div>
      <div class="col-xs-6">
        <i *ngIf="isDev" (click)="toggleDebug = !toggleDebug" class="fa fa-code pull-right"></i>
      </div>
    </div>
  </div>
  <div class="sidebar_container_full_page_noscroll">
    <div class="keywest_panel">
      <div class="row">
        <div class="col-xs-6 ben-padding" appNGError [elemControl]="addBenefitsForm.controls.metric_name">
          <label for="metric_name" class="sidebar_label"
            >{{ trans.trans.name.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.name"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_benefitName.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input
            type="text"
            ret-data="Name"
            class="form-control"
            placeholder="{{ trans.trans.enterName.value }}"
            name="metric_name"
            [(ngModel)]="selectedElem.metric_name"
            required
          />
        </div>

        <div *ngIf="featureRealizationName" class="col-xs-6 ben-padding" appNGError [elemControl]="addBenefitsForm.controls.value_realization_name">
          <label for="value_realization_name" class="sidebar_label">
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.name"
              (callback)="getTranslations()"
            ></app-editTranslation>
            {{ trans.trans.valueRealizationName.value }}
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_benefitName.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input
            type="text"
            ret-data="Name"
            class="form-control"
            placeholder="{{ trans.trans.enterName.value }}"
            name="value_realization_name"
            [(ngModel)]="selectedElem.value_realization_name"
          />
        </div>

        <div class="col-xs-6 ben-padding">
          <label class="sidebar_label"
            >{{ trans.trans.tcoName.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.tcoName"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_tcoName.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input type="text" name="tco_name" class="form-control" [(ngModel)]="selectedElem.tco_name" />
        </div>

        <div class="col-xs-6 ben-padding" appNGError [elemControl]="addBenefitsForm.controls.description">
          <label for="description" class="sidebar_label"
            >{{ trans.trans.description.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.description"
              (callback)="getTranslations()"
            ></app-editTranslation>

            <span
              style="margin-left: 5px"
              class="material-icons-outlined tooltip-custom info-icon"
              [matTooltip]="trans.trans.ch_benefitDescriptionExtended.value"
              matTooltipPosition="above"
            >
              info
            </span>
          </label>
          <textarea
            name="description"
            class="form-control"
            [placeholder]="trans.trans.description.value"
            [attr.ret-data]="trans.trans.description.value"
            [(ngModel)]="selectedElem.description"
            required
          ></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6" appNGError [elemControl]="addBenefitsForm.controls.accrual_type_id">
          <label class="sidebar_label"
            >{{ trans.trans.isAnnualBenefit.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.isAnnualBenefit"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_annualBenefit.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <div ret-data="{trans.trans.isAnnualBenefit.value}"></div>
          <p-dropdown
            appendTo="body"
            [attr.ret-data]="trans.trans.isAnnualBenefit.value"
            [autoDisplayFirst]="false"
            optionLabel="name"
            [options]="dropdownValues?.readAccrualTypes"
            name="accrual_type_id"
            [(ngModel)]="selectedElem.accrual_type_id"
            (onChange)="editPhasingTerms()"
            [filter]="true"
            filterBy="name"
            class="keywest_dropdown"
            [panelStyleClass]="'dropdown-item-font'"
            [required]="true"
          >
            <ng-template pTemplate="selectedItem">
              {{ selectedElem.accrual_type_id.id | functionPipe: translateAccrualType:{ trans, accrualTypes } }}
            </ng-template>
            <ng-template let-accrualType pTemplate="item">
              {{ accrualType.id | functionPipe: translateAccrualType:{ trans, accrualTypes } }}
            </ng-template>
          </p-dropdown>
        </div>

        <div class="col-xs-6" appNGError [elemControl]="addBenefitsForm.controls.value_category_id">
          <label for="value_category_id" class="sidebar_label"
            >{{ trans.trans.benefitGroupBelong.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.benefitGroupBelong"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_benefitGroup.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <p-dropdown
            [attr.ret-data]="trans.trans.benefitGroupBelong.value"
            appendTo="body"
            [autoDisplayFirst]="false"
            optionLabel="value_category"
            [options]="dropdownValues?.value_categories"
            name="value_category_id"
            [(ngModel)]="selectedElem.value_category_id"
            [filter]="true"
            filterBy="label,name"
            class="keywest_dropdown"
            [panelStyleClass]="'dropdown-item-font'"
            [required]="true"
          >
            <ng-template let-item pTemplate="selectedItem">
              <span *ngIf="item.description" class="dropdown-tooltip material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description" matTooltipPosition="above">
                info
              </span>
              <span style="vertical-align: middle">{{ item.name }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6" appNGError [elemControl]="addBenefitsForm.controls.benefit_type_id">
          <label class="sidebar_label">{{ trans.trans?.newBenefitType?.value ? trans.trans?.newBenefitType?.value : 'Benefit Type' }}</label>
          <div ret-data="'Benefit Type'"></div>
          <p-dropdown
            appendTo="body"
            ret-data="'Benefit Type'"
            [autoDisplayFirst]="false"
            optionLabel="name"
            [options]="dropdownValues?.benefitTypes"
            name="benefit_type_id"
            [(ngModel)]="selectedElem.benefit_type_id"
            [filter]="true"
            filterBy="label,name"
            class="keywest_dropdown"
            [panelStyleClass]="'dropdown-item-font'"
            [required]="true"
          ></p-dropdown>
        </div>

        <div class="col-xs-6" appNGError [elemControl]="addBenefitsForm.controls.impact_type_id">
          <label class="sidebar_label"
            >{{ trans.trans.howImpactYourCustomer.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.howImpactYourCustomer"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_benefitImpact.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <p-dropdown
            appendTo="body"
            [attr.ret-data]="trans.trans.howImpactYourCustomer.value"
            [autoDisplayFirst]="false"
            optionLabel="name"
            [options]="dropdownValues?.readImpactTypes"
            name="impact_type_id"
            [(ngModel)]="selectedElem.impact_type_id"
            [filter]="true"
            filterBy="label,name"
            class="keywest_dropdown"
            [panelStyleClass]="'dropdown-item-font'"
            [required]="true"
          ></p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6" *ngIf="!hasFeature46" appNGError [elemControl]="addBenefitsForm.controls.unique_type_id">
          <label class="sidebar_label"
            >{{ trans.trans.differentiated.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.differentiated"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_differentiated.value" matTooltipPosition="above">
              info
            </span> </label
          ><br />
          <p-inputSwitch
            name="unique_type_id"
            ret-data="Differentiated"
            (onChange)="switchChanged($event, selectedElem.unique_type_id)"
            [(ngModel)]="selectedElem.unique_type_id"
          ></p-inputSwitch>
          <div class="switch_inline">
            {{ selectedElem.unique_type_id ? trans.trans.yes.value : trans.trans.no.value }}
          </div>
        </div>

        <div class="col-xs-6" *ngIf="!hasFeature46" appNGError [elemControl]="addBenefitsForm.controls.default_na">
          <label class="sidebar_label" for="default_na"
            >{{ trans.trans.isDefault.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.isDefault"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_isDefault.value" matTooltipPosition="above">
              info
            </span> </label
          ><br />
          <p-inputSwitch
            name="default_na"
            [attr.ret-data]="trans.trans.isDefault.value"
            (onChange)="switchChanged($event, selectedElem.default_na)"
            [(ngModel)]="selectedElem.default_na"
          ></p-inputSwitch>
          <div class="switch_inline">
            {{ selectedElem.default_na ? trans.trans.yes.value : trans.trans.no.value }}
          </div>
        </div>

        <div class="col-xs-6" *ngIf="softFeature" appNGError [elemControl]="addBenefitsForm.controls.soft">
          <label class="sidebar_label" for="soft">{{ trans.trans.soft.value }}</label
          ><br />
          <p-inputSwitch name="soft" ret-data="soft" (onChange)="switchChanged($event, selectedElem.soft)" [(ngModel)]="selectedElem.soft"></p-inputSwitch>
          <div class="switch_inline">
            {{ selectedElem.soft ? trans.trans.yes.value : trans.trans.no.value }}
          </div>
        </div>

        <ng-container *ngIf="!isOnTheFlyBenefit">
          <div class="col-md-6">
            <label for="has_tag" class="sidebar_label">{{ trans.trans.createCustomAssetTag.value }}</label>
            <br />
            <p-inputSwitch [(ngModel)]="selectedElem.has_tag" (onChange)="generateCustomAsset($event)" name="has_tag"></p-inputSwitch>
          </div>

          <div class="col-md-6"></div>
          <!-- Factor Value Tag -->
          <div
            *ngIf="selectedElem.has_tag"
            class="col-md-6"
            appNGError
            [elemControl]="addBenefitsForm.controls.valueTag"
            [ngClass]="{ 'field-error': selectedElem.nameTag === selectedElem.valueTag }"
          >
            <label for="name" class="sidebar_label labelsRequired">{{ trans.trans.benefitValueTag.value }}</label>
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTagTooltip.value">info</span>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="selectedElem.valueTag"
              name="valueTag"
              ret-data="Value Tag"
              maxlength="255"
              appSpaceToUnderscore
              appSpecialCharacterValidator
              [required]="selectedElem.has_tag"
            />
            <span *ngIf="selectedElem.has_tag" class="hint light-h"
              >[{{ trans.trans.onShow.value }}{{ selectedElem.valueTag ? selectedElem.valueTag : trans.trans.value.value }}]</span
            >
            <span class="input-error-warn" *ngIf="addBenefitsForm.controls.valueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
          </div>
        </ng-container>

        <div class="col-xs-6" *ngIf="hasFeature46" appNGError [elemControl]="addBenefitsForm.controls.default_na">
          <label class="sidebar_label" for="default_na"
            >{{ trans.trans.soft.value }}
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.defaultNa.value" matTooltipPosition="above">
              info
            </span> </label
          ><br />
          <p-dropdown
            [options]="hardSoftDropdown"
            class="keywest_dropdown"
            [optionLabel]="'label'"
            appendTo="body"
            [autoDisplayFirst]="false"
            name="default_na"
            ret-data="Is this benefit N/A by default?"
            [panelStyleClass]="'dropdown-item-font'"
            [(ngModel)]="selectedElem.default_na"
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar_container_full_page_noscroll">
    <div class="keywest_page_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title clickable" (click)="selectedElem.driver_toggle = !selectedElem.driver_toggle">
            {{ trans.trans.driver.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.driver"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_driver.value" matTooltipPosition="above">
              info
            </span>
          </div>
          <p-inputSwitch
            styleClass="inputwitch_header"
            name="driver_toggle"
            [(ngModel)]="selectedElem.driver_toggle"
            (ngModelChange)="setFactorToggle('driver_factor_id', $event, factorFields.driver)"
          ></p-inputSwitch>
        </div>
        <div class="col-xs-6" *ngIf="selectedElem.driver_toggle">
          <a (click)="addDriver = !addDriver" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i> {{ trans.trans.addNew.value }}</a>
        </div>
      </div>
    </div>
    <div class="keywest_panel" [hidden]="!selectedElem.driver_toggle">
      <div class="row">
        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.driver_factor_id">
          <label class="sidebar_label"
            >{{ trans.trans.driverFactor.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.driverFactor"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_dirverFactor.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <div class="p-inputgroup">
            <div
              (click)="openEditFactor(selectedElem.driver_factor_id)"
              style="padding: 6px 15px"
              class="input-left-icon-addon clickable btn btn_customizable"
              *ngIf="can_edit && selectedElem.driver_factor_id && !(selectedElem.driver_factor_id.id === '1' || selectedElem.driver_factor_id.id === '2')"
            >
              <span class="fa fa-plus"></span>
            </div>
            <p-dropdown
              appendTo="body"
              [attr.ret-data]="trans.trans.driverFactor.value"
              [autoDisplayFirst]="false"
              optionLabel="name"
              [options]="dropdownValues?.readAccountFactorCategoriesType"
              name="driver_factor_id"
              [(ngModel)]="selectedElem.driver_factor_id"
              [filter]="true"
              filterBy="label,name"
              class="keywest_dropdown"
              [panelStyleClass]="'dropdown-item-font'"
              [required]="true"
              [group]="true"
              optionGroupChildren = "factors"
                              >
                        <ng-template let-group pTemplate="group">
                          <div class="p-d-flex p-ai-center">
                            <span>{{group.name}}</span>
                          </div>
                        <ng-template let-factor pTemplate="item">
                          <div>
                            <span>{{factor.name}}</span>
                          </div>
                        </ng-template>
                        </ng-template>
                      </p-dropdown>

            <div
              [matTooltip]="trans.trans.hasScratchpad.value"
              matTooltipPosition="above"
              (click)="openScratchpad(selectedElem.driver_factor_id, 'driver_baseline_answer')"
              *ngIf="selectedElem.driver_factor_id && !isOnTheFlyBenefit && !(selectedElem.driver_factor_id.id === '1' || selectedElem.driver_factor_id.id === '2')"
              class="clickable p-inputgroup-addon"
            >
              <i class="fa fa-calculator" [ngStyle]="{ color: selectedElem.driver_factor_id.has_scratchpad ? 'green' : '' }"></i>
            </div>
          </div>
        </div>
        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.driver_baseline_answer">
          <label class="sidebar_label"
            >{{ trans.trans.answer.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.answer"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span
              style="margin-left: 5px"
              class="material-icons-outlined tooltip-custom info-icon"
              [matTooltip]="trans.trans.ch_driverBaselineAnswer.value"
              matTooltipPosition="above"
            >
              info
            </span>
          </label>
          <input
            type="number"
            [attr.ret-data]="trans.trans.answer.value"
            name="driver_baseline_answer"
            class="form-control"
            [(ngModel)]="selectedElem.driver_baseline_answer"
            required
          />
          <span>{{ this.selectedElem?.driver_factor_id?.unit_type }}</span>
        </div>
      </div>

      <div *ngIf="!isOnTheFlyBenefit">
        <div class="row">
          <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.has_phasing">
            <label class="sidebar_label"
              >{{ trans.trans.seedGrowthDecline.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.seedGrowthDecline"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_seedGrowth.value" matTooltipPosition="above">
                info
              </span> </label
            ><br />
            <p-inputSwitch name="has_phasing" [(ngModel)]="selectedElem.has_phasing"></p-inputSwitch>
            <div class="switch_inline">
              {{ selectedElem.has_phasing ? trans.trans.yes.value : trans.trans.no.value }}
            </div>
          </div>
        </div>

        <div *ngIf="selectedElem.has_phasing">
          <div class="row" *ngFor="let dterm of driver_term; let i = index">
            <div class="col-xs-2">
              <label class="sidebar_label"
                >{{ trans.trans['year' + (i + 1)].value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans['year' + (i + 1)]"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
            </div>
            <div class="col-xs-8">
              <input type="range" [min]="-100" [max]="100" name="dterm{{ i }}" [(ngModel)]="dterm.val" />
            </div>
            <div class="col-xs-2">
              <input type="number" name="dterm_val{{ i }}" [(ngModel)]="dterm.val" class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar_container_full_page_noscroll">
    <div class="keywest_page_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title clickable" (click)="selectedElem.improvement_toggle = !selectedElem.improvement_toggle">
            {{ trans.trans.improvement.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.improvement"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
          <p-inputSwitch
            styleClass="inputwitch_header"
            name="improvement_toggle"
            [(ngModel)]="selectedElem.improvement_toggle"
            (ngModelChange)="setFactorToggle('improvement_factor_id', $event, factorFields.improvement)"
          ></p-inputSwitch>
        </div>
        <div class="col-xs-6" *ngIf="selectedElem.improvement_toggle">
          <a (click)="addImprovement = !addImprovement" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i> {{ trans.trans.addNew.value }}</a>
        </div>
      </div>
    </div>

    <div class="keywest_panel" [hidden]="!selectedElem.improvement_toggle">
      <div class="row">
        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.improvement_factor_id">
          <label class="sidebar_label"
            >{{ trans.trans.improvementFactor.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.improvementFactor"
              (callback)="getTranslations()"
            ></app-editTranslation>

            <span
              style="margin-left: 5px"
              class="material-icons-outlined tooltip-custom info-icon"
              [matTooltip]="trans.trans.ch_improvementFactor.value"
              matTooltipPosition="above"
            >
              info
            </span>
          </label>
          <div class="p-inputgroup">
            <!--aqui improvement-->
            <div
              (click)="openEditFactor(selectedElem.improvement_factor_id)"
              style="padding: 6px 15px"
              class="input-left-icon-addon clickable btn btn_customizable"
              *ngIf="can_edit && selectedElem.improvement_factor_id && !(selectedElem.improvement_factor_id.id === '1' || selectedElem.improvement_factor_id.id === '2')"
            >
              <span class="fa fa-plus"></span>
            </div>
            <p-dropdown
              appendTo="body"
              [attr.ret-data]="trans.trans.improvementFactor.value"
              [autoDisplayFirst]="false"
              optionLabel="name"
              [options]="dropdownValues?.readAccountFactorCategoriesType"
              name="improvement_factor_id"
              [(ngModel)]="selectedElem.improvement_factor_id"
              [filter]="true"
              filterBy="label,name"
              class="keywest_dropdown"
              [panelStyleClass]="'dropdown-item-font'"
              [required]="true"
              [group]="true"
              optionGroupChildren = "factors"
                              >
                        <ng-template let-group pTemplate="group">
                          <div class="p-d-flex p-ai-center">
                            <span>{{group.name}}</span>
                          </div>
                        <ng-template let-factor pTemplate="item">
                          <div>
                            <span>{{factor.name}}</span>
                          </div>
                        </ng-template>
                        </ng-template>
                      </p-dropdown>

            <div
              [matTooltip]="trans.trans.hasScratchpad.value"
              matTooltipPosition="above"
              (click)="openScratchpad(selectedElem.improvement_factor_id, 'probable')"
              *ngIf="selectedElem.improvement_factor_id && !isOnTheFlyBenefit"
              class="clickable p-inputgroup-addon"
            >
              <i class="fa fa-calculator" [ngStyle]="{ color: selectedElem.improvement_factor_id.has_scratchpad ? 'green' : '' }"></i>
            </div>
          </div>
        </div>

        <div
          class="col-xs-4"
          *ngIf="selectedElem.improvement_factor_id && +selectedElem.improvement_factor_id.id === 1"
          appNGError
          [elemControl]="addBenefitsForm.controls.unit_type_id"
        >
          <label class="sidebar_label"
            >{{ trans.trans.units.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.units"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_units.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <p-dropdown
            appendTo="body"
            ret-data="Units"
            [autoDisplayFirst]="false"
            optionLabel="name"
            [options]="dropdownValues?.readUnitTypes"
            name="unit_type_id"
            [(ngModel)]="selectedElem.unit_type_id"
            [filter]="true"
            filterBy="label,name"
            class="keywest_dropdown"
            [panelStyleClass]="'dropdown-item-font'"
            [required]="true"
          ></p-dropdown>
        </div>

        <div class="col-xs-4">
          <label class="sidebar_label"
            >{{ trans.trans.currentState.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.currentState"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_currentState.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input
            type="number"
            class="form-control"
            id="current_effort"
            [attr.ret-data]="trans.trans.currentState.value"
            placeholder="{{ trans.trans.currentEffortPlaceholder.value }}"
            name="current_effort"
            [(ngModel)]="selectedElem.current_effort"
          />
        </div>
      </div>
      <div class="row" style="margin-bottom: 8px">
        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.conservative">
          <label class="sidebar_label"
            >{{ trans.trans.conservative.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.conservative"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_conservative.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input type="number" name="conservative" [attr.ret-data]="trans.trans.conservative.value" class="form-control" [(ngModel)]="selectedElem.conservative" required />
        </div>
        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.probable">
          <label class="sidebar_label"
            >{{ trans.trans.probable.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.probable"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_probable.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input type="number" name="probable" [attr.ret-data]="trans.trans.probable.value" class="form-control" placeholder="" [(ngModel)]="selectedElem.probable" required />
          <span>{{ selectedElem?.improvement_factor_id?.unit_type }}</span>
        </div>
        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.aggressive">
          <label class="sidebar_label"
            >{{ trans.trans.aggressive.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.aggressive"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_aggressive.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input
            type="number"
            name="aggressive"
            [attr.ret-data]="trans.trans.aggressive.value"
            class="form-control"
            placeholder=""
            [(ngModel)]="selectedElem.aggressive"
            required
          />
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar_container_full_page_noscroll">
    <div class="keywest_page_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title clickable" (click)="selectedElem.financial_toggle = !selectedElem.financial_toggle">
            {{ trans.trans.financial.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.financial"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_financial.value" matTooltipPosition="above">
              info
            </span>
          </div>
          <p-inputSwitch
            styleClass="inputwitch_header"
            name="financial_toggle"
            [(ngModel)]="selectedElem.financial_toggle"
            (ngModelChange)="setFactorToggle('financial_factor_id', $event, factorFields.financial)"
          ></p-inputSwitch>
        </div>
        <div class="col-xs-6" *ngIf="selectedElem.financial_toggle">
          <a (click)="addFinancial = !addFinancial" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i> {{ trans.trans.addNew.value }}</a>
        </div>
      </div>
    </div>

    <div class="keywest_panel" [hidden]="!selectedElem.financial_toggle">
      <div class="row">
        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.financial_factor_id">
          <label class="sidebar_label"
            >{{ trans.trans.financialFactor.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.financialFactor"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_financialFactor.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <div class="p-inputgroup">
            <div
              (click)="openEditFactor(selectedElem.financial_factor_id)"
              style="padding: 6px 15px"
              class="input-left-icon-addon clickable btn btn_customizable"
              *ngIf="can_edit && selectedElem.financial_factor_id && !(selectedElem.financial_factor_id.id === '1' || selectedElem.financial_factor_id.id === '2')"
            >
              <span class="fa fa-plus"></span>
            </div>
            <p-dropdown
              appendTo="body"
              [autoDisplayFirst]="false"
              [attr.ret-data]="trans.trans.financialFactorType.value"
              optionLabel="name"
              [options]="dropdownValues?.readAccountFactorCategoriesType"
              name="financial_factor_id"
              [(ngModel)]="selectedElem.financial_factor_id"
              [filter]="true"
              filterBy="label,name"
              class="keywest_dropdown"
              [panelStyleClass]="'dropdown-item-font'"
              [required]="true"
              [group]="true"
              optionGroupChildren = "factors"
                              >
                        <ng-template let-group pTemplate="group">
                          <div class="p-d-flex p-ai-center">
                            <span>{{group.name}}</span>
                          </div>
                        <ng-template let-factor pTemplate="item">
                          <div>
                            <span>{{factor.name}}</span>
                          </div>
                        </ng-template>
                        </ng-template>
                      </p-dropdown>


            <div
              [matTooltip]="trans.trans.hasScratchpad.value"
              matTooltipPosition="above"
              (click)="openScratchpad(selectedElem.financial_factor_id, 'financial_baseline_answer')"
              *ngIf="selectedElem.financial_factor_id && !isOnTheFlyBenefit && !(selectedElem.financial_factor_id.id === '1' || selectedElem.financial_factor_id.id === '2')"
              class="clickable p-inputgroup-addon"
            >
              <i class="fa fa-calculator" [ngStyle]="{ color: selectedElem.financial_factor_id.has_scratchpad ? 'green' : '' }"></i>
            </div>
          </div>
        </div>

        <div class="col-xs-4" appNGError [elemControl]="addBenefitsForm.controls.financial_baseline_answer">
          <label class="sidebar_label"
            >{{ trans.trans.answer.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.answer"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_baselineAnswer.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <input type="number" name="financial_baseline_answer" class="form-control" id="financial_baseline_answer" [(ngModel)]="selectedElem.financial_baseline_answer" />
          <span>{{ selectedElem?.financial_factor_id?.unit_type }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar_container_full_page_noscroll">
    <div class="keywest_page_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title clickable" (click)="selectedElem.optional_toggle = !selectedElem.optional_toggle">
            {{ trans.trans.optional.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.optional"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
          <p-inputSwitch styleClass="inputwitch_header" name="optional_toggle" [(ngModel)]="selectedElem.optional_toggle"></p-inputSwitch>
        </div>
      </div>
    </div>

    <div class="keywest_panel" *ngIf="selectedElem.optional_toggle">
      <div class="row">
        <div class="col-xs-6">
          <div class="row">
            <div class="col-xs-12">
              <label class="sidebar_label"
                >{{ trans.trans.valueStatemenet.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.valueStatemenet"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <span
                  style="margin-left: 5px"
                  class="material-icons-outlined tooltip-custom info-icon"
                  [matTooltip]="trans.trans.ch_valueStatment.value"
                  matTooltipPosition="above"
                >
                  info
                </span>
              </label>
              <textarea name="value_statement" class="form-control" placeholder="{{ trans.trans.valueStatemenet.value }}" [(ngModel)]="selectedElem.value_statement"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label class="sidebar_label"
                >{{ trans.trans.featCapabitiltiesHeader.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.featCapabitiltiesHeader"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <span
                  style="margin-left: 5px"
                  class="material-icons-outlined tooltip-custom info-icon"
                  [matTooltip]="trans.trans.ch_featuresCapabilities.value"
                  matTooltipPosition="above"
                >
                  info
                </span>
              </label>
              <textarea name="features" class="form-control" placeholder="{{ trans.trans.featCapabitilties.value }}" [(ngModel)]="selectedElem.features"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label class="sidebar_label"
                >{{ trans.trans.course_correction.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.course_correction"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <span
                  style="margin-left: 5px"
                  class="material-icons-outlined tooltip-custom info-icon"
                  [matTooltip]="trans.trans.course_correction.value"
                  matTooltipPosition="above"
                >
                  info
                </span>
              </label>
              <textarea
                name="course_correction"
                class="form-control"
                placeholder="{{ trans.trans.course_correction.value }}"
                [(ngModel)]="selectedElem.course_correction"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label class="sidebar_label"
                >{{ trans.trans.funcObj.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.funcObj"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_funcObj.value" matTooltipPosition="above">
                  info
                </span>
              </label>
              <div [attr.ret-data]="trans.trans.funcObj.value"></div>
              <p-dropdown
                appendTo="body"
                [autoDisplayFirst]="false"
                [showClear]="true"
                optionLabel="name"
                [options]="dropdownValues?.readAccountFunctionalObjectives"
                name="functional_objectives"
                [(ngModel)]="selectedElem.functional_objectives"
                [filter]="true"
                filterBy="label,name"
                class="keywest_dropdown"
                [panelStyleClass]="'dropdown-item-font'"
              ></p-dropdown>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label class="sidebar_label"
                >{{ trans.trans.expenseType.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.expenseType"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_expenseType.value" matTooltipPosition="above">
                  info
                </span>
              </label>
              <div [attr.ret-data]="trans.trans.expenseType.value"></div>
              <p-dropdown
                appendTo="body"
                [autoDisplayFirst]="false"
                optionLabel="name"
                [options]="dropdownValues?.expense"
                name="expense_type_id"
                [(ngModel)]="selectedElem.expense_type_id"
                [filter]="true"
                filterBy="label,name"
                class="keywest_dropdown"
                [panelStyleClass]="'dropdown-item-font'"
              ></p-dropdown>
            </div>
          </div>

          <div *ngIf="hasFeature84">
            <div class="row" style="margin-bottom: 15px">
              <div class="col-xs-12">
                <label class="sidebar_label"
                  >{{ trans.trans.issues.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.issues"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.includeDefault.value"> info </span>
                </label>
                <textarea name="metric_note_2" rows="5" class="form-control" [(ngModel)]="selectedElem.metric_note_2"></textarea>
              </div>
            </div>

            <div class="row" style="margin-bottom: 15px">
              <div class="col-xs-12">
                <label class="sidebar_label"
                  >{{ trans.trans.resolutions.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.resolutions"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.includeDefault.value"> info </span>
                </label>
                <textarea name="metric_note_3" rows="5" class="form-control" [(ngModel)]="selectedElem.metric_note_3"></textarea>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="selectedBenefit && selectedBenefit.situations && selectedBenefit.situations.length">
            <div class="col-xs-6">
              <label class="sidebar_label"
                >{{ trans.trans.situations.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.situations"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <ul>
                <li *ngFor="let situation of selectedBenefit.situations">
                  {{ situation.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xs-6" *ngIf="!isOnTheFlyBenefit">
          <app-dl-dragdrop
            [canEdit]="can_edit"
            [title]="trans.trans.clickSelectImage.value"
            [msg]="trans.trans.acceptableFormat.value"
            [files]="files"
            (callbackDelete)="this.deleteBenefitFile($event)"
            (callback)="files = $event"
          ></app-dl-dragdrop>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <label (click)="impactAreaToggle = !impactAreaToggle" class="sidebar_label clickable">
            <i [ngClass]="impactAreaToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i>
            {{ trans.trans.impactBusinessArea.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.impactBusinessArea"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span *ngIf="selectedElem.areas && selectedElem.areas.length"> ({{ selectedElem.areas.length }} selected)</span>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_impactBusiness.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <app-dl-list-detail
            [canEdit]="can_edit"
            *ngIf="impactAreaToggle"
            key="id"
            title="{{ trans.trans.impactAreas.value }}"
            [list]="dropdownValues?.readAreaTypes"
            [selectedList]="selectedElem.areas"
            (callback)="selectedElem.areas = $event"
          ></app-dl-list-detail>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label (click)="painPointToggle = !painPointToggle" class="sidebar_label clickable">
            <i [ngClass]="painPointToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i>
            {{ trans.trans.painPoints.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.painPoints"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span *ngIf="selectedElem.pain_points && selectedElem.pain_points.length"> ({{ selectedElem.pain_points.length }} selected)</span>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_painPoints.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <app-dl-list-detail
            [canEdit]="can_edit"
            *ngIf="painPointToggle"
            title="{{ trans.trans.painPointsListTitle.value }}"
            key="account_painpoint_id"
            [list]="dropdownValues?.readPainPoints"
            [selectedList]="selectedElem.pain_points"
            (callback)="selectedElem.pain_points = $event"
          ></app-dl-list-detail>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label (click)="capabilitiesToggle = !capabilitiesToggle" class="sidebar_label clickable">
            <i [ngClass]="capabilitiesToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i>
            {{ trans.trans.capabilities.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.capabilities"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span *ngIf="selectedElem.capabilities && selectedElem.capabilities.length"> ({{ selectedElem.capabilities.length }} selected)</span>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_capabilities.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <app-dl-list-detail
            [canEdit]="can_edit"
            *ngIf="capabilitiesToggle"
            title="{{ trans.trans.capabilitiesListTitle.value }}"
            key="capabilities_id"
            [list]="dropdownValues?.readAccountCapabilities"
            [selectedList]="selectedElem.capabilities"
            (callback)="selectedElem.capabilities = $event"
          ></app-dl-list-detail>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label (click)="opObjectivesToggle = !opObjectivesToggle" class="sidebar_label clickable">
            <i [ngClass]="opObjectivesToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i>
            {{ trans.trans.opObjs.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.opObjs"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span *ngIf="selectedElem.operational_objectives && selectedElem.operational_objectives.length"> ({{ selectedElem.operational_objectives.length }} selected)</span>
            <span style="margin-left: 5px" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_opObjs.value" matTooltipPosition="above">
              info
            </span>
          </label>
          <app-dl-list-detail
            [canEdit]="can_edit"
            *ngIf="opObjectivesToggle"
            title="{{ trans.trans.opObjsListTitle.value }}"
            key="account_operational_objective_id"
            [list]="dropdownValues?.readAccountOperationalObjectives"
            [selectedList]="selectedElem.operational_objectives"
            (callback)="selectedElem.operational_objectives = $event"
          ></app-dl-list-detail>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label">
            Cumulative Benefit?
            <span
              style="margin-left: 5px"
              class="material-icons-outlined tooltip-custom info-icon"
              matTooltip="This would increase the benefit total to include the prior value"
              matTooltipPosition="above"
            >
              info
            </span> </label
          ><br />
          <p-inputSwitch
            name="cumulative_bens"
            ret-data="Cumulative"
            (onChange)="switchChanged($event, selectedElem.cumulative_bens)"
            [(ngModel)]="selectedElem.cumulative_bens"
          ></p-inputSwitch>
          <div class="switch_inline">
            {{ selectedElem.cumulative_bens ? trans.trans.yes.value : trans.trans.no.value }}
          </div>
        </div>
      </div>
      <div *ngIf="!isOnTheFlyBenefit">
        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label"
              >{{ trans.trans.benefitPhasing.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.benefitPhasing"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <span
                style="margin-left: 5px"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="trans.trans.ch_benefitsPhasing.value"
                matTooltipPosition="above"
              >
                info
              </span>
            </label>
          </div>
        </div>
        <div [formGroup]="phasingTermGroup">
          <div formArrayName="phasingTermArray">
            <app-error-input-wrapper [errorMessage]="(errorDict$ | async)?.get('crossField')">
              <div class="row" *ngFor="let term of phasingTermArray.controls; let i = index">
                <div [formGroupName]="i">
                  <div class="col-xs-2" *ngIf="i === 0; else yearLabel">
                    <label
                      >{{ trans.trans.currentState.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.currentState"
                        (callback)="getTranslations()"
                      ></app-editTranslation>
                    </label>
                  </div>
                  <ng-template #yearLabel>
                    <div class="col-xs-2">
                      <label
                        >{{ trans.trans['year' + i].value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans['year' + (i + 1)]"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                      </label>
                    </div>
                  </ng-template>
                  <div class="col-xs-8">
                    <div class="col-xs-6 minmax">0</div>
                    <div class="col-xs-6 minmax text-right">100</div>
                    <input type="range" [min]="0" [max]="100" formControlName="val" [value]="phasingTermArray.value[i].val" />
                  </div>
                  <div class="col-xs-2">
                    <input type="text" formControlName="val" class="form-control" [value]="phasingTermArray.value[i].val" />
                  </div>
                </div>
              </div>
            </app-error-input-wrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-are-you-sure
    matTooltipPosition="above"
    [message]="trans.trans.are_you_sure.value"
    class="btn cancel-confirm marg-h"
    icon=""
    [text]="trans.trans.cancel.value"
    (callbackYes)="cancel()"
  ></app-are-you-sure>
  <app-btn-save
    *ngIf="phasingTermGroup.valid; else disabledSave"
    #btnSave
    [form]="addBenefitsForm"
    class="btn btn_customizable"
    (callback)="saveBenefits(addBenefitsForm, btnSave)"
    [text]="trans.trans.save.value"
  ></app-btn-save>
  <ng-template #disabledSave
    ><button class="btn btn_customizable" disabled>{{ trans.trans.save.value }}</button></ng-template
  >
</form>

<p-sidebar [(visible)]="addDriver" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-ae-factors
    *ngIf="addDriver"
    [mode]="'add'"
    [isOnTheFlyBenefit]="isOnTheFlyBenefit"
    (callback)="getDropdownValues(); funcSetFactor('driver_factor_id', $event); addDriver = false"
    (callbackBack)="addDriver = false"
  ></app-ae-factors>
</p-sidebar>

<p-sidebar [(visible)]="addImprovement" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-ae-factors
    *ngIf="addImprovement"
    [mode]="'add'"
    [isOnTheFlyBenefit]="isOnTheFlyBenefit"
    (callback)="getDropdownValues(); funcSetFactor('improvement_factor_id', $event); addImprovement = false"
    (callbackBack)="addImprovement = false"
  ></app-ae-factors>
</p-sidebar>

<p-sidebar [(visible)]="addFinancial" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-ae-factors
    *ngIf="addFinancial"
    [mode]="'add'"
    [isOnTheFlyBenefit]="isOnTheFlyBenefit"
    (callback)="getDropdownValues(); funcSetFactor('financial_factor_id', $event); addFinancial = false"
    (callbackBack)="addFinancial = false"
  ></app-ae-factors>
</p-sidebar>

<p-sidebar [(visible)]="editFactor" styleClass="keywest_sidebar p-sidebar-xl" position="right" (onHide)="clearEditFactor()">
  <app-ae-factors
    *ngIf="editFactor"
    [mode]="'edit'"
    [isOnTheFlyBenefit]="isOnTheFlyBenefit"
    [formObjFactor]="selectedFactor"
    (callback)="getDropdownValues(); clearEditFactor()"
    (callbackBack)="editFactor = false"
  ></app-ae-factors>
</p-sidebar>

<p-sidebar [(visible)]="showFactor" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-ae-scratchpads *ngIf="showFactor && can_edit" (callback)="closeScratchpad($event)" [factor]="selectedFactor"></app-ae-scratchpads>
  <app-r-scratchpads *ngIf="showFactor && !can_edit" (callback)="showFactor = false" [factor]="selectedFactor"></app-r-scratchpads>
</p-sidebar>

<p-sidebar [(visible)]="toggleDebug" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.debug.value }}
    </div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <pre>
					{{ selectedElem | json }}
				</pre
        >
      </div>
    </div>
  </div>
</p-sidebar>
