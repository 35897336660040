import { Injectable } from '@angular/core';

@Injectable()
export class QuestionsAnswersGuidedDiscoveryTranslations {
  public config: any = {
    component: 'Guided Discovery step 2',
    display_name: 'Guided Discovery value rep step 2',
    description: '',
  };

  public trans: any = {
    cancel: 'Cancel',
    reviewRecomendations: 'Review Recommendations',
    sectionTitle: 'Select your Answers'
  };
}
