import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelAdminGuard implements CanActivate {
  

  constructor(
		private router: Router,
		private commonService: CommonService
	) { }

	canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
		let admin = this.commonService.checkPrivilege("2") || this.commonService.checkPrivilege("1");
		if (admin) { return true; }
		this.router.navigateByUrl('/dashboard');
		return false;
	}


}
