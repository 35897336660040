import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AccountService } from '@data/services/account/account.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { AdvancedVpDashboardTranslations } from './advanced.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { ValueProp } from '@shared/models/value-prop.model';
import { Situation } from '@data/services/valueprop/models/situation-opportunity.model';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss'],
})
export class AdvancedComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() contextualHelp: any;
  @Input() valueProp: ValueProp;
  @Input() showTranslate = false;
  vpCanEdit: boolean;
  situation: Partial<Situation> = {
    baseline_type_id: '',
    customer_type_id: '',
    deployment_time: 0,
    gross_profit: '',
    term: '',
    wacc: '',
  };

  ngUnsubscribe = new Subject();

  loader: boolean;
  fullImagePath: string;
  imageUrl: string;
  isVpReloadable = false;

  customerTypes: Array<{ id: string; name: string }> = [];
  baselineTypes: Array<{ id: string; name: string }> = [];
  advancedTerms: Array<{ id: string; name: string; term: string }> = [];
  term = '';
  originalTerm = '';
  grossProfitFeature = this.commonService.checkFeature('13');
  customerTypeFeature = this.commonService.checkFeature('20');
  opportunityIdFeature = this.commonService.checkFeature('22');
  opportunityIdFeatureRequired = false;
  aid: string;
  currencyId: { id: any };

  feature64 = this.commonService.checkFeature('64');
  locked = false;
  languages: Array<{ id: string }> = [];
  selectedLanguage: { id: string };
  waccPrecision: number = 0;

  constructor(
    private valuepropService: ValuepropService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    public trans: AdvancedVpDashboardTranslations,
    private translationService: TranslationsV2Service,
    private accountService: AccountService,
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.aid = sessionStorage.getItem('aid');

    this.getAssetLanguages();
    this.locked = +this.valueProp.closed === 1;

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.vpCanEdit = this.valueProp.vp_can_edit;
    this.getCustomerTypes();
    this.getBaselineTypes();
    this.getWACCPrecision();
    this.getValuePropSituation(this.valueProp.id);
    this.getTerms();

    if (this.aid !== '20') {
      this.opportunityIdFeatureRequired = false;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getOpportunityId(): void {
    this.loader = true;
    this.valuepropService
      .getOpportunityId(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.loader = false;

          this.situation.opportunity_id = response.result.opportunity_id;
        }
      });
  }

  getAssetLanguages(): void {
    this.valuepropService
      .readAssetLanguageTemplates(this.aid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.languages = res.result;
        this.selectedLanguage = this.languages.filter((x) => x.id == this.valueProp.language_type_id)[0];
      });
  }

  getValuePropSituation(id: number | string): void {
    this.loader = true;
    this.valuepropService
      .getSituation(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.term = response.result.term;
          this.originalTerm = this.term;
          this.loader = false;

          this.situation = response.result;
          this.currencyId = this.situation.currency_types.filter((currency) => currency.id == response.result.currency_type_id)[0];
          if (this.currencyId === undefined && !!response.result.currency_type_id) {
            this.currencyId = { id: response.result.currency_type_id };
          }

          if (this.opportunityIdFeature) {
            this.getOpportunityId();
          }
        }
      });
  }

  getCustomerTypes(): void {
    this.valuepropService
      .getCustomerTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.customerTypes = response.result;
        }
      });
  }

  getBaselineTypes(): void {
    this.valuepropService
      .getBaselineTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.baselineTypes = response.result;
        }
      });
  }

  getTerms(): void {
    this.valuepropService
      .getTerms(sessionStorage.getItem('aid'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.advancedTerms = response.result;
        }
      });
  }

  getWACCPrecision(): void {
    this.accountService.getAccountFactorWACCPrecision().subscribe((precision) => {
      this.waccPrecision = precision || 0;
    });
  }

  updateAdvanced(): void {
    const situation = {
      baseline_type_id: this.situation.baseline_type_id,
      customer_type_id: this.situation.customer_type_id,
      deployment_time: this.situation.deployment_time,
      gross_profit: this.situation.gross_profit,
      currency_type_id: this.currencyId.id ? this.currencyId.id : this.currencyId.id.id,
      number_format: this.situation.number_format ? this.situation.number_format : '1',
      conversion_rate: this.situation.conversion_rate,
      term: this.term,
      wacc: this.situation.wacc,
      language_type_id: this.selectedLanguage.id,
    };

    if (this.situation.opportunity_id) {
      situation['opportunity_id'] = this.situation.opportunity_id;
    }

    this.loader = true;
    this.valuepropService
      .updateAdvanced(this.valueProp.id, situation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success(this.trans.trans.updateSuccess.value, false);
          this.isVpReloadable = this.term !== this.originalTerm;
          this.originalTerm = this.term;
          this.valuepropService.refreshDashboard.next(true);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.loader = false;
        window.location.reload();
      });
  }

  setConversionRateField(event: { value: any }): void {
    this.isVpReloadable = true;
    this.situation = { ...this.situation, conversion_rate: event.value.rate };
  }
}
