import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BenefitsMiniComponent } from './mini.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTooltipModule } from '@angular/material/tooltip';


export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
    MatTooltipModule,
		NgbModule,
		ApplicationPipesModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [

		BenefitsMiniComponent
	],
	exports: [BenefitsMiniComponent]
})
export class BenefitsMiniModule { }
