import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, pluck, map, tap } from 'rxjs/operators';
import { APIResult } from '@shared/models/api-result.model';
import {
  RealizedFilter,
  TrackerBaselinePayload,
  TrackerNote,
  TrackerNoteEditPayload,
  TrackerNotePayload,
  TrackerNoteResponsePayload,
  ValueRealizedCompany,
  ValueRealizedCompanyWithTrackers,
  ValueRealizedListEntry,
} from './models/value-realized-company.model';
import { IncrementType } from '@shared/models/increment-type.model';
import { CompanyPromotedVP } from '@data/services/valuerealization/models/value-realized-company.model';
import { ValueTrackerBenefitType } from '@shared/models/benefit-type.model';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';
import { ValueRealizedSurveyTemplate } from './models/value-realized-survey.model';
import { ValueTrackerDetails } from './models/value-tracker.model';
import { TrackerGoalScratchpadPayload, ValuePropAddGoal } from '@shared/models/goal-group.model';
import { UpdateBrScratchpadPayload } from '@shared/models/update-br-scratchpad.model';
import { Template } from '@shared/models/asset.models';
import { ValueRealizedCaseStudies } from './models/value-realized-case-studies.model';

@Injectable()
export class ValuerealizationService {
  service_url;
  public readCaseStudy = new Subject<string>();
  public readSurveyList = new Subject<string>();
  public readSurveyTemplateList = new Subject<string>();
  private _trackerData$ = new BehaviorSubject<any>(null);
  public refreshDashboardData$ = new Subject<boolean>();
  public saveBenefitPerformance$ = new Subject<boolean>();

  get trackerData(): Observable<any> {
    return this._trackerData$ as Observable<any>;
  }

  set trackerDataInfo(tracker: any) {
    this._trackerData$.next(tracker);
  }

  refreshDashboardData() {
    this.refreshDashboardData$.next(true);
  }

  constructor(private commonService: CommonService) {}
  public getValueRealization(searchLimit) {
    let endpoint = 'valueRealization/lockedValueProps' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public getValueRealizationQuick(searchLimit) {
    let endpoint = 'valueRealization/lockedValuePropsQuick' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public getSurveys(searchLimit) {
    let endpoint = 'valueRealization/lockedValueProps' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public getValueRealizationSurveys(params) {
    let endpoint = 'valueRealization/readValueSurveys/' + params;
    return this.commonService.getAPIService(endpoint);
  }
  public getvalueRealizedTemplate() {
    let endpoint = 'asset/valueRealizedTemplate';
    return this.commonService.getAPIService(endpoint);
  }

  public readValueRealizationSurvey(survey_id) {
    let endpoint = 'valueRealization/read/valueSurvey/' + survey_id;
    return this.commonService.getAPIService(endpoint);
  }
  public readActiveSurveyTemplate(accountId) {
    let endpoint = 'account/readAccountSurveyQuestionTemplate/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }
  public createSurveys(params) {
    let endpoint = 'valueRealization/valueSurveys/';
    return this.commonService.postAPIService(endpoint, params);
  }
  public createSurveyResponse(payload) {
    let endpoint = 'valueRealization/createValueSurveyResponse';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public saveSurveyQuestion(payload) {
    let endpoint = 'account/surveyQuestion';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public getSurveyQuestion(account_id, template_id) {
    let endpoint = 'account/viewSurveyQuestionTemplate/' + account_id + '/templateID/' + template_id;
    return this.commonService.getAPIService(endpoint);
  }
  public getValueRealization_SurveyTemplates(accountid) {
    let endpoint = 'account/listSurveyQuestionTemplates/' + accountid;
    return this.commonService.getAPIService(endpoint);
  }
  public getCaseStudies(companyId: string, valuePropId: string, isValueprop: number, searchLimit: string): Observable<APIResult<ValueRealizedCaseStudies>> {
    let endpoint = 'valueRealization/readCaseStudies' + companyId + '/valuePropId/' + valuePropId + '/isAccountSolution/' + isValueprop + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public searchCaseStudies(payload) {
    let endpoint = '/valueRealization/searchCasestudy';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public getCaseStudyDetail(searchLimit) {
    let endpoint = 'valueRealization/read/caseStudy' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public saveCaseStudy(payload) {
    let endpoint = 'valueRealization/caseStudy';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public saveCaseStudyBenefits(payload) {
    let endpoint = 'valueRealization/addBenefits';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public deleteCaseStudyBenefit(payload) {
    let endpoint = 'valueRealization/benefits/delete';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public updateCaseStudyBenefit(payload) {
    let endpoint = 'valueRealization/benefits/update';
    return this.commonService.putAPIService(endpoint, payload);
  }
  public deleteCaseStudy(case_study_id) {
    let endpoint = 'valueRealization/delete/caseStudy/' + case_study_id;
    return this.commonService.deleteAPIService(endpoint);
  }
  public createSurveyTemplate(accId, payload) {
    let endpoint = 'account/surveyQuestionTemplate/' + accId;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public deleteSurvey(survey_id) {
    let endpoint = 'valueRealization/survey/delete/' + survey_id;
    return this.commonService.deleteAPIService(endpoint);
  }
  public updateSurveySettings(payload) {
    let endpoint = 'valueRealization/updateValueSurvey';
    return this.commonService.putAPIService(endpoint, payload);
  }
  public updateName(payload) {
    let endpoint = 'valueRealization/updateCaseStudyName';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateCaseStudyDetails(payload) {
    let endpoint = 'valueRealization/updateCaseStudy';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public fullCaseStudyUpdate(payload) {
    let endpoint = 'valueRealization/fullCaseStudyUpdate';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public createTemplate(accId, payload) {
    let endpoint = 'account/surveyQuestion/' + accId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateTemplate(aid, payload) {
    let endpoint = 'account/updateSurveyQuestion/' + aid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getValueRealizationDetail(value_prop_id): Observable<APIResult<ValueTrackerDetails & { original_value_prop_id: string }>> {
    return this.getAllValueRealizationsV2().pipe(
      pluck('result'),
      switchMap((res) => {
        let vr = res.find((real) => real.value_prop_id === value_prop_id);
        let endpoint = 'valueRealization/valueRealizedTracker/' + value_prop_id;
        let payload = {
          original_value_prop_id: vr?.original_value_prop_id,
        };
        return this.commonService.postAPIService(endpoint, payload).pipe(
          map((data) => ({
            result: {
              ...data.result,
              original_value_prop_id: vr?.original_value_prop_id,
            },
          }))
        );
      })
    );
  }

  public getValueRealizationDetailDashboard(value_prop_id): Observable<APIResult<ValueTrackerDetails>> {
    let endpoint = 'valueRealization/valueRealizedTracker/' + value_prop_id;
    let payload = {
      original_value_prop_id: value_prop_id,
    };
    return this.commonService.postAPIService(endpoint, payload).pipe(
      map((result) => {
        this.trackerDataInfo = result.result;
        return result;
      })
    );
  }

  public getAllValueRealizationsV2(): Observable<APIResult<ValueRealizedListEntry[]>> {
    let endpoint = 'valueRealization/readValueRealizedList';
    return this.commonService.getAPIService(endpoint);
  }

  public getValueRealizationsWithCompanies(filter: RealizedFilter): Observable<APIResult<ValueRealizedCompany[]>> {
    let endpoint = 'valueRealization/read/companiesWithTrackers?filter=' + filter;
    return this.commonService.getAPIService(endpoint);
  }

  public getValueTrackerAssets(value_prop_id): Observable<APIResult<Template[]>> {
    let endpoint = 'asset/allValueRealizedTemplates/' + value_prop_id;
    return this.commonService.getAPIService(endpoint);
  }

  public promoteVpToValueRealization(value_prop_id, payload) {
    let endpoint = 'valueProps/cloneValueProp/' + value_prop_id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateSurveyMetric(metric_id, payload) {
    let endpoint = 'valueRealization/update/valueSurveyMetric/' + metric_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public notifyMarketingTracker(payload) {
    let endpoint = 'valueRealization/valueRealizedTrackerNotification';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateValueSurveyMetricFresh(metric_id: number, payload) {
    let endpoint = 'valueRealization/update/valueSurveyMetricFresh/' + metric_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public addValueRealizationBenefitNote(payload) {
    let endpoint = 'valueRealization/updateValueSurveyNote';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public addValueRealizationBenefitTestimonial(payload) {
    let endpoint = 'valueRealization/updateSurveyMetricTestimonial';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public postValueTrackerShare(payload, vpid) {
    let endpoint = 'valueProps/valueTrackerShare/' + vpid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getValueTrackerShareList(vpid) {
    let endpoint = 'valueProps/readValueTrackerShares/' + vpid;
    return this.commonService.getAPIService(endpoint);
  }

  public deleteValueTrackerShare(vpid, share_id) {
    let endpoint = 'valueProps/deleteValueTrackerShare/' + vpid + '/shareID/' + share_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public postCalcScratchpadTotal(tid, payload): Observable<APIResult<{ total: string }>> {
    let endpoint = 'valueRealization/readScratchpadTotal/' + tid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public putBusinessReviewScratchpad(valuePropId: string, payload: UpdateBrScratchpadPayload): Observable<APIResult<any>> {
    const endpoint = 'valueRealization/updateBusinessReviewScratchpad/' + valuePropId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public postSaveScratchpadTotal(tid, payload) {
    let endpoint = 'valueRealization/updateSurveyScratchpad/' + tid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public postSaveFactorName(tid, payload) {
    let endpoint = 'valueRealization/createFactorOverride/' + tid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public overrideValueRealizationName(payload) {
    let endpoint = 'valueRealization/overrideRealizationMetricName/' + payload.value_prop_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getTrackers(companyId: number, filter: RealizedFilter): Observable<APIResult<ValueRealizedCompanyWithTrackers>> {
    let endpoint = `valueRealization/read/company/${companyId}/WithTrackers?filter=${filter}`;
    return this.commonService.getAPIService(endpoint);
  }

  public toggleIncludedInRollup(ids: number[], include: boolean) {
    const endpoint = `valueProps/vr_rollup_included`;
    const payload = {
      value_prop_ids: ids,
      rollup_included: include ? 1 : 0,
    }
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getPromotedVPSByCompany(company_id: string): Observable<CompanyPromotedVP[]> {
    const endpoint = `valueRealization/promotedValueProps?company_id=${company_id}`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public saveTrackerBaseline(vpId: string, payload: TrackerBaselinePayload) {
    const endpoint = `valueRealization/promoteAndSetToValueRealization/${vpId}`;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getValueRealizationBenefits(trackerId: string, businessReviewId?: number): Observable<ValueTrackerBenefitType[]> {
    const businessReviewQuery = businessReviewId ? `&business_review_id=${businessReviewId}` : '';
    const endpoint = `valueRealization/${trackerId}/benefits?show_all=1` + businessReviewQuery;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public getVRSurveysByBusinessReview(trackerId: string, businessReviewId: number): Observable<ValueRealizedSurveyTemplate[]> {
    const businessReviewQuery = businessReviewId ? `?business_review_id=${businessReviewId}` : '';
    const endpoint = `valueRealization/${trackerId}/surveys` + businessReviewQuery;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public getIncrementTypes(): Observable<IncrementType[]> {
    const endpoint = 'fact/incrementTypes';
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public getBusinessReviewData(valueTrackerId: string): Observable<BusinessReviewData[]> {
    const endpoint = `valueRealization/${valueTrackerId}/businessReviews`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public getBusinessReviewScratchpad(valuePropId: string, scratchpadId: number, overrideName = 1) {
    const endpoint = `valueRealization/surveyScratchpad/${valuePropId}/scratchpadId/${scratchpadId}?vr_override_name=${overrideName}`;
    return this.commonService.getAPIService(endpoint);
  }

  public updateBusinessReviewFactors(valuePropId: string, business_review_id: number, factors: TrackerGoalScratchpadPayload[]) {
    const endpoint = `valueRealization/updateBusinessReviewFactors/${valuePropId}`;
    return this.commonService.putAPIService(endpoint, { business_review_id, factors });
  }

  public saveTrackerNote(vpId: string, payload: TrackerNotePayload): Observable<TrackerNoteResponsePayload> {
    const endpoint = `valueRealization/${vpId}/note`;
    return this.commonService.postAPIService(endpoint, payload).pipe(pluck('result'));
  }

  public getTrackerNotes(vpId: string, brId: string, objectId: string, section: string): Observable<TrackerNote[]> {
    const endpoint = `valueRealization/${vpId}/note?section=${section}&object_id=${objectId}&business_review_id=${brId}`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public deleteTrackerNote(vpId: string, noteId: string): Observable<any> {
    const endpoint = `valueRealization/${vpId}/deleteNote/${noteId}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  public updateTrackerNote(vpId: string, payload: TrackerNoteEditPayload) {
    const endpoint = `valueRealization/${vpId}/note`;
    return this.commonService.putAPIService(endpoint, payload).pipe(pluck('result'));
  }
}
