<!-- eslint-disable prettier/prettier -->
<div class="row">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="models_panel models_panel style-2022" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <app-editTranslation [styleClass]="'title'" [transComp]="trans" [transObj]="trans.trans.branding"></app-editTranslation>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="models_panel" style="margin-bottom: 20px" *ngIf="isAccountAdmin">
          <div class="models_page_header_container_small">
            <div class="keywest_header">
              <div class="row">
                <div class="col-xs-4">
                  <app-editTranslation [transComp]="trans" [transObj]="trans.trans.accountLogos" [styleClass]="['title', 'normal-t']"></app-editTranslation>
                </div>
                <div class="col-xs-8">
                  <a (click)="uploadLogoInput.click()" class="btn btn_customizable pull-right marg-h">{{ trans?.trans?.change_logo?.value }}</a>
                  <a (click)="uploadIconInput.click()" class="btn btn_customizable pull-right">{{ trans?.trans?.change_icon?.value }}</a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6">
                <div class="profileImage">
                  <img class="account-logo-image img-responsive" [src]="userProfileImage" />
                  <input
                    style="display: none"
                    type="file"
                    class="form-control"
                    name="account_logo"
                    accept="image/*"
                    accept="image/*"
                    (change)="handleInputChange($event)"
                    #uploadLogoInput
                  />
                  <img *ngIf="actionLoaderLogo" class="actionLoader" [src]="fullImagePath" width="30px" />
                </div>
              </div>

              <div class="col-xs-6">
                <div class="profileImage">
                  <img class="account-icon-image img-responsive" [src]="userIconImage" />
                  <input style="display: none" type="file" class="form-control" name="account_icon" accept="image/*" (change)="handleIconChange($event)" #uploadIconInput />
                  <img *ngIf="actionLoaderIcon" class="actionLoader" [src]="fullImagePath" width="30px" />
                </div>
              </div>
            </div>

            <small>** {{ trans?.trans?.imageWithTransparentBackgroundWorksBest?.value }}</small>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.trans.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.imageWithTransparentBackgroundWorksBest"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>

        <div class="models_panel" style="margin-bottom: 20px">
          <div class="models_page_header_container_small">
            <div class="keywest_header">
              <div class="row">
                <div class="col-xs-10">
                  <div class="title normal-t">
                    {{ trans?.trans?.customize_ui?.value }}
                    <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.well_info?.value"> info </span>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.trans.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.customize_ui"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6">
                <div class="row">
                  <div class="col-xs-12">
                    <b>{{ trans?.trans?.header_color?.value }}</b>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.trans.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.header_color"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 20px">
                  <div class="col-sm-12">
                    <div style="float: left">
                      <p-colorPicker [(ngModel)]="currentColor"></p-colorPicker>
                    </div>
                    <div style="float: left">
                      <input type="text" name="currentColor" [(ngModel)]="currentColor" class="form-control" style="max-width: 200px" maxlength="7" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-12">
                    <b>{{ trans?.trans?.button_background_color?.value }}</b>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.trans.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.button_background_color"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 20px">
                  <div class="col-sm-12">
                    <div style="float: left">
                      <p-colorPicker [(ngModel)]="button_color"></p-colorPicker>
                    </div>
                    <div style="float: left">
                      <input type="text" name="button_color" [(ngModel)]="button_color" style="max-width: 200px" class="form-control" maxlength="7" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-12">
                    <b>{{ trans?.trans?.button_text_color?.value }}</b>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.trans.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.button_text_color"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 20px">
                  <div class="col-sm-12">
                    <div style="float: left">
                      <p-colorPicker [(ngModel)]="button_text_color"></p-colorPicker>
                    </div>
                    <div style="float: left">
                      <input type="text" name="button_text_color" [(ngModel)]="button_text_color" class="form-control" maxlength="7" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <a (click)="funcSetColors()" class="btn btn_customizable pull-right">{{ trans?.trans?.update?.value }}</a>
                  </div>
                </div>
              </div>
              <div class="col-xs-6">
                <b>{{ trans?.trans?.sample_preview?.value }}</b>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.trans.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.sample_preview"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <div class="mock-top-bar topmenu-outer" [ngStyle]="{ background: currentColor }">
                  {{ trans?.trans?.header_sample?.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.trans.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.header_sample"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
                <div class="mock-body">
                  <div class="content-container">
                    <div *ngFor="let items of [0, 1, 2, 3]" class="card m-1">
                      <div class="card-body p-2">
                        <div class="head align-items-center">
                          <div class="circle"></div>
                          <div class="top-mock ml-1">
                            <div class="content-mock"></div>
                            <div class="content-mock"></div>
                          </div>
                        </div>
                        <div class="content-mock"></div>
                      </div>
                    </div>
                  </div>
                  <button class="btn marg-v" [ngStyle]="{ 'background-color': button_color }">
                    <span appTranslations [ngStyle]="{ color: button_text_color }">
                      {{ trans?.trans?.button_sample?.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.trans.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.button_sample"
                        (callback)="getTranslations()"
                      ></app-editTranslation>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isAccountAdmin" class="models_panel" style="margin-bottom: 20px">
          <div class="models_page_header_container_small">
            <div class="keywest_header">
              <div class="row">
                <div class="col-xs-10">
                  <div class="title">{{ trans?.trans?.setTermYears?.value }}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <p>
                  <b>{{ trans?.trans?.selectAvailableTerms?.value }}</b>
                </p>

                <div class="checkbox custom-checkbox" *ngFor="let term of availableTerms" style="width: 20%; display: inline-block">
                  <label>
                    {{ term.label }}
                    <input type="checkbox" [(ngModel)]="term.selected" style="float: left" (ngModelChange)="setDefaultTermOptions()" />
                    <span class="check"></span>
                  </label>
                </div>

                <p>{{ trans?.trans?.termDisclaimer?.value }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <p>
                  <b>{{ trans?.trans?.setDefaultTerm?.value }}</b>
                </p>

                <p-dropdown
                  appendTo="body"
                  [autoDisplayFirst]="false"
                  [panelStyleClass]="'dropdown-item-font'"
                  class="keywest_dropdown dropdown-theme"
                  [options]="defaultTermOptions"
                  [(ngModel)]="defaultTerm"
                  optionLabel="name"
                  dataKey="term"
                ></p-dropdown>
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-xs-12">
                <a (click)="saveAccountTerms()" class="btn btn_customizable pull-right">{{ trans?.trans?.update?.value }}</a>
              </div>
            </div>

            <br />
          </div>
        </div>

        <div *ngIf="isAccountAdmin" class="models_panel" style="margin-bottom: 20px">
          <div class="models_page_header_container_small">
            <div class="keywest_header">
              <div class="row">
                <div class="col-xs-10">
                  <div class="title">{{ trans?.trans?.manageAssetGenerator?.value }}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-4 col-lg-3">
                <label>
                  <input type="radio" [(ngModel)]="assetGenerator" [value]="assetGeneratorType.legacy" />
                  {{ trans?.trans?.legacyGenerator?.value }}
                </label>
              </div>

              <div class="col-xs-4 col-lg-3">
                <label>
                  <input type="radio" [(ngModel)]="assetGenerator" [value]="assetGeneratorType.generatorV3" />
                  {{ trans?.trans?.v3Generator?.value }}
                </label>
              </div>

              <div class="col-xs-4 col-lg-3">
                <label>
                  <input type="radio" [(ngModel)]="assetGenerator" [value]="assetGeneratorType.saveToGdrive" />
                  {{ trans?.trans?.driveGenerator?.value }}
                </label>
              </div>

              <div class="col-xs-12 col-lg-3">
                <a (click)="saveAssetGenerator()" class="btn btn_customizable pull-right">{{ trans?.trans?.update?.value }}</a>
              </div>
            </div>

            <br />
          </div>
        </div>

        <div *ngIf="isAccountAdmin" class="models_panel" style="margin-bottom: 20px">
          <div class="models_page_header_container_small">
            <div class="keywest_header">
              <div class="row">
                <div class="col-xs-10">
                  <div class="title">{{ trans?.trans?.setFiscalYear?.value }}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <p>
                  <b>{{ trans?.trans?.fiscalYearStart?.value }}</b>
                </p>

                <p-dropdown
                  appendTo="body"
                  [autoDisplayFirst]="false"
                  [panelStyleClass]="'dropdown-item-font'"
                  class="keywest_dropdown dropdown-theme"
                  [options]="fiscalYearMonths"
                  [(ngModel)]="selectedMonth"
                  optionLabel="label"
                  dataKey="value"
                >
                  <ng-template let-item pTemplate="item">
                    {{ item.label | titlecase }}
                  </ng-template>
                </p-dropdown>
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-xs-12">
                <a (click)="saveFiscalMonth()" class="btn btn_customizable pull-right">{{ trans?.trans?.update?.value }}</a>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="models_panel" style="margin-bottom: 20px">
          <div class="models_page_header_container_small">
            <div class="keywest_header">
              <div class="row">
                <div class="col-xs-10">
                  <div class="title normal-t">
                    {{ trans?.trans?.chartColor?.value }}
                    <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.well_info_2?.value"> info </span>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.trans.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.chartColor"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>
                        {{ trans?.trans?.chartColor?.value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.trans.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.chartColor"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                      </th>
                      <th>Hex</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p-colorPicker [(ngModel)]="newColor"></p-colorPicker>
                      </td>
                      <td><input type="text" name="newColor" [(ngModel)]="newColor" class="form-control" maxlength="7" /></td>
                      <td>
                        <a (click)="funcSetChartColor()" class="btn btn-padding-sm btn_customizable pull-right">{{ trans?.trans?.add_color?.value }}</a>
                      </td>
                    </tr>
                  </tbody>

                  <tbody>
                    <tr *ngFor="let color of chartColors">
                      <td>
                        <p-colorPicker [(ngModel)]="color.color"></p-colorPicker>
                      </td>
                      <td>{{ color.color }}</td>
                      <td>
                        <app-are-you-sure [message]="trans?.trans?.are_you_sure?.value" text="" icon="fa fa-times" (callbackYes)="funcDeleteChartColor(color)"></app-are-you-sure>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="models_panel" style="margin-bottom: 20px" *ngIf="isAccountAdmin">
          <div class="models_page_header_container_small">
            <div class="keywest_header">
              <div class="row">
                <div class="col-xs-10">
                  <div class="title normal-t">
                    {{ trans?.trans?.waccFactor?.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.trans.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.waccFactor"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <p>
                  <b>{{ trans?.trans?.waccFactorDescription?.value }}</b>
                </p>

                <p-dropdown
                  appendTo="body"
                  *ngIf="WACCFactors"
                  [autoDisplayFirst]="false"
                  [panelStyleClass]="'dropdown-item-font'"
                  class="keywest_dropdown dropdown-theme"
                  [options]="WACCFactors"
                  [(ngModel)]="selectedWACCFactor"
                  [filter]="true"
                  [showClear]="true"
                  optionLabel="name"
                  dataKey="id"
                >
                </p-dropdown>
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-xs-12">
                <a (click)="saveAccountFactorWACC()" class="btn btn_customizable pull-right">{{ trans?.trans?.update?.value }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="models_panel" style="margin-bottom: 20px" *ngIf="isAccountAdmin">
  <div class="models_page_header_container_small">
    <div class="support-container">
      <ng-template
        [ngTemplateOutlet]="customInputs"
        [ngTemplateOutletContext]="{
          inputControl: supportEmail,
          transString: 'supportEmail',
          tooltipString: 'emailTooltip',
          cacheName: supportEmailCache,
          updateMessage: supportEmailUpdateMessage,
          errorObs: supportEmailErrors$
        }"
      ></ng-template>

      <ng-template
        [ngTemplateOutlet]="customInputs"
        [ngTemplateOutletContext]="{
          inputControl: supportUrl,
          transString: 'supportUrl',
          tooltipString: 'urlTooltip',
          cacheName: supportUrlCache,
          updateMessage: supportUrlUpdateMessage,
          errorObs: supportUrlErrors$
        }"
      ></ng-template>

      <ng-template
        [ngTemplateOutlet]="customInputs"
        [ngTemplateOutletContext]="{
          inputControl: lmsPath,
          transString: 'lmsPath',
          tooltipString: 'lmsPath',
          cacheName: lmsPathCache,
          updateMessage: lmsPathUpdateMessage,
          errorObs: lmsPathErrors$
        }"
      ></ng-template>
    </div>
  </div>
</div>

<div class="models_panel" style="margin-bottom: 20px" *ngIf="isAccountAdmin">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">
            {{ trans?.trans?.vp_status?.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.well_info_3?.value"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.trans.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.vp_status"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                {{ trans?.trans?.status?.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.trans.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.status"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans?.trans?.description?.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.trans.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.description"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans?.trans?.requires_comment?.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.trans.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.requires_comment"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <td style="min-width: 120px">
                <div>
                  <div class="btn-group pull-right" style="white-space: nowrap">
                    <!-- Disable for now -->
                    <!-- <button
                      matTooltip="{{ trans?.trans?.import_system?.value }}"
                      matTooltipPosition="below"
                      container="body"
                      placement="bottom"
                      (click)="importStatuses()"
                      class="btn btn-primary btn_customizable btn-sm"
                    >
                      <i class="fa fa-download"></i>
                    </button> -->
                    <button
                      matTooltip="{{ trans?.trans?.add_new_status?.value }}"
                      matTooltipPosition="below"
                      container="body"
                      placement="bottom"
                      (click)="showAdd()"
                      class="btn btn-primary btn_customizable btn-sm"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </thead>

          <tbody *ngIf="add_status">
            <tr>
              <td style="width: 45%"><input type="text" [(ngModel)]="status.name" name="status_name" class="form-control" /></td>
              <td style="width: 45%"><input type="text" name="desc" [(ngModel)]="status.description" class="form-control" /></td>
              <td style="width: 10%"><input type="checkbox" [(ngModel)]="status.comment" name="status_comment" /></td>
              <td>
                <button (click)="addStatus()" class="btn btn-success btn-sm">{{ trans?.trans?.add?.value }}</button>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!statuses.length">
            <tr>
              <td colspan="4">
                {{ trans?.trans?.no_entries_found?.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.trans.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans?.suno_entries_foundpportUrl"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="statuses.length" cdkDropList (cdkDropListDropped)="drop($event, statuses)" class="dragObjectsParent">
            <tr *ngFor="let status of statuses" cdkDrag class="dragObjects">
              <td>
                <input type="text" name="name" [(ngModel)]="status.name" class="form-control" *ngIf="status.edit" />
                <span *ngIf="!status.edit">{{ status.name }}</span>
              </td>
              <td>
                <input type="text" name="desc" *ngIf="status.edit" [(ngModel)]="status.description" class="form-control" />
                <span *ngIf="!status.edit">{{ status.description }}</span>
              </td>
              <td>
                <input type="checkbox" [(ngModel)]="status.comment" name="status_comment" *ngIf="status.edit" />
                <span *ngIf="!status.edit">{{ status.comment === 'checked' ? 'Yes' : 'No' }}</span>
              </td>
              <td>
                <div *ngIf="!status.edit">
                  <div class="btn-group pull-right">
                    <button
                      matTooltip="{{ trans?.trans?.edit_status?.value }}"
                      matTooltipPosition="below"
                      container="body"
                      (click)="status.edit = !status.edit"
                      class="btn btn-success btn-sm"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button
                      matTooltip="{{ trans?.trans?.delte_status?.value }}"
                      matTooltipPosition="below"
                      container="body"
                      (click)="deleteStatus(status)"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="status.edit">
                  <div class="btn-group pull-right">
                    <button
                      [matTooltip]="trans?.trans?.save_changes?.value"
                      matTooltipPosition="below"
                      container="body"
                      (click)="updateStatus(status); status.edit = !status.edit"
                      class="btn btn-success btn-sm"
                    >
                      <i class="fa fa-save"></i>
                    </button>
                    <button
                      [matTooltip]="trans?.trans?.cancel_edit?.value"
                      matTooltipPosition="below"
                      container="body"
                      (click)="status.edit = !status.edit"
                      class="btn btn-danger btn-sm"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template
  #customInputs
  let-inputControl="inputControl"
  let-transString="transString"
  let-tooltipString="tooltipString"
  let-cacheName="cacheName"
  let-updateMessage="updateMessage"
  let-errorObs="errorObs"
>
  <div class="input-container">
    <div class="keywest_header">
      <div class="title">
        {{ trans.trans[transString].value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.trans.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans[transString]"
          (callback)="getTranslations()"
        ></app-editTranslation>
        <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans[tooltipString].value"> info </span>
      </div>
    </div>
    <div class="support-input-container">
      <form (ngSubmit)="setInput(transString)">
        <app-error-input-wrapper [errorMessage]="$any(errorObs | async)?.get(transString)">
          <input name="inputName" type="text" class="form-control" [formControl]="inputControl" />
        </app-error-input-wrapper>
        <button class="btn btn-primary btn_customizable btn-sm xf-continue-button" type="submit" [disabled]="inputControl.invalid || cacheName === inputControl.value">
          {{ trans.trans.update.value }}
        </button>
      </form>
    </div>

    <p [style.color]="updateMessage.style.color" *ngIf="updateMessage">{{ updateMessage.message }}</p>
  </div>
</ng-template>
