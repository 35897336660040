<p-overlayPanel #op appendTo="body" styleClass="are-you-sure-overlay">
  <div class="panel panel-overlay panel-white">
    <div class="panel-heading" style="height: inherit !important; text-align: center">
      <div class="panel-title">
        {{ message }}
      </div>
    </div>
    <div class="panel-body panel-body-overlay" style="padding: 0px">
      <button (click)="funcYes()" class="btn btn-overlay btn-success" style="float: left; width: 50%">{{ yes }}</button>
      <button (click)="funcCancel()" class="btn btn-overlay btn-danger" style="float: left; width: 50%">{{ no }}</button>
    </div>
  </div>
</p-overlayPanel>

<div #actualTarget *ngIf="!loading" [matTooltip]="tooltip" container="body" matTooltipPosition="above" [ngClass]="{'icon-text': !!icon && text, 'prime-arrow-fix': !!icon}" class="pointer no-general" (click)="op.toggle($event, actualTarget)"
  ><i *ngIf="icon" class="{{ icon }} clickable mr " ngClass="iconColor" [ngStyle]="iconStyle"></i>
  <span [ngClass]="textColor">
    {{ text | titlecase }}
  </span>
</div>
<ng-container *ngIf="!noLoader">
  <img class="actionLoader pull-right" *ngIf="loading" [src]="fullImagePath" />
</ng-container>
