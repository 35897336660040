import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'top',
    display_name: 'Top Panel VP Dashboard',
    description: 'Top panel in vp dashboard top panel'
  };

  public trans: any = {
    key_figures_and_roi: 'Key Figures & ROI',
    cashflow: 'Cashflow',
    notes: 'Notes',
    logs: 'Logs',
    scorecard: 'Scorecard',
    share: 'Share',
    roi: 'ROI',
    tco: 'TCO',
    roi_full: 'Key Figures & ROI',
    competitiveTco: 'Competitive TCO'
  };
}
