import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class ReviewBaselineGoalGroupsTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'reviewBaseline',
    display_name: 'Review Baseline',
    description: 'Review baseline with tabs for goals, benefits, and success criteria',
  };

  public trans: any = {
    goals: 'Goals',
    addToTracker: 'Add to Tracker',
    goalName: 'Goal Name',
    baselineValue: 'Baseline Value',
    targetValue: 'Target Value',
    search: 'Search',
    units: 'Units',
    emptyTable: 'No data found',
    formula: 'Formula',
    currency: 'Currency',
    currencyThousands: 'Currency Thousands',
    currencyMillions: 'Currency Millions',
    currencyBillions: 'Currency Billions',
    thousands: 'Thousands',
    millions: 'Millions',
    billions: 'Billions',
    seconds: 'Seconds',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    months: 'Months',
    years: 'Years',
    percent: 'Percent',
    basisPoints: 'Basis Points',
    miles: 'Miles',
    number: 'Number',
    weeks: 'Weeks',
    saveError: 'There was a problem, please try again',
    areYouSure: 'Are you sure?',
    addSuccess: 'Goal added successfully',
    editSuccess: 'Goal edited successfully',
    description: 'Description',
    details: 'Details',
    precision: 'Precision',
    measurement: 'Measurement',
    unitType: 'Unit Type',
    back: 'Back',
    next: 'Next',
    cancel: 'Cancel',
    goalAdded: 'Goal added successfully',
    goalUpdated: 'Goal updated successfully',
    goalActiveStatusUpdated: 'Goal active status updated successfully',
    requestError: 'There was a problem, please try again',
    name: 'Name',
    save: 'Save',
    finish: 'Finish',
    goal: 'Goal',
    results: 'Results',
    newGoalTooltip: 'If a pre-defined Goal doesn’t exist which meets your needs, you can define a brand new goal on the fly.',
    linkedFactors: 'Linked Factors',
    goalSaved: 'Goal saved successfully',
    addGoal: 'Add Goal',
    editScratchpad: 'Edit Scratchpad',
    add: 'Add',
    delete: 'Delete',
    goalNameSaved: 'Goal name saved successfully',
    goalNameError: 'There was a problem, please try again',
    originalGoalName: 'Original Goal Name',
    allGoals: 'All Goals',
    onlyKeyGoals: 'Key Goals Only'
  };
}
