import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AccountComponent } from './account.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'primeng/sidebar';
import { FieldsetModule } from 'primeng/fieldset';
import { DropdownModule } from 'primeng/dropdown';
import { MatTooltipModule } from '@angular/material/tooltip';


export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
    MatTooltipModule,
		NgbModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		ApplicationPipesModule,
		SidebarModule,
		FieldsetModule,
		DropdownModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [

		AccountComponent
	],
	exports: [AccountComponent]
})
export class AccountModule { }
