import { Injectable } from '@angular/core';
import { DefaultTranslations } from 'app/value-prop/value-prop-dashboard/panel_top/top.translation';
import { getColor, getColorText } from 'app/repv2/helpers/colors';
import { funcFixVal } from 'app/repv2/helpers/fix-val';
import { RepSimpleResultsTranslaions } from 'app/repv2/views/results/results.translation';
import { DashboardSimpleImplementation } from 'app/value-prop/value-prop-dashboard/dashboard_simple/simple.implementation';
import { BottomPanelTranslations } from 'app/value-prop/value-prop-dashboard/panel_bottom/bottom.translation';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { RepSimpleViewService } from '../rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '../translationsv2/translationsv2.service';
import { ValuepropService } from '../valueprop/valueprop.service';
import { Permissions } from 'app/value-prop/value-prop-dashboard/dashboard_customer/permissions';
import { DataSet, OptionsVc } from './results-analysis.model';
import { ValueProp } from '@shared/models/value-prop.model';
import { VCData } from '@shared/models/vc-data.model';
import { APIResult } from '@shared/models/api-result.model';
import { ScoreCard } from '@shared/models/score-card.model';
import { ROIData } from '@shared/models/roi-data.model';
import { PaybackData } from '@shared/models/payback-data.model';
import { NPVData } from '@shared/models/npv-data.model';
import { InactionCostData } from '@shared/models/inaction-cost-data.model';
import { TCOData } from '@shared/models/tco-data.model';
import { ResultStatus } from '@shared/models/result-status.models';

@Injectable({
  providedIn: 'root',
})
export class ResultsAnalysisService {
  showAllMetrics = false;
  chartNPV: { [klass: string]: any } = null;
  chartPayback: { [klass: string]: any } = null;
  chartROI: { [klass: string]: any } = null;
  chartTCO: { [klass: string]: any } = null;
  chartVC: { [klass: string]: any } = null;
  chartVCLegend: string[] = [];
  chart3MO: { [klass: string]: any };
  colorROI = 'border-right: inherit';
  colorNPV = 'border-right: inherit';
  colorPBP = 'border-right: inherit';
  colorTCO = 'border-right: inherit';

  paybackText: string;
  paybackFormulaText: string;
  roiColor: string;
  roiStatus: ResultStatus;

  headerColor = '';
  inactionText: string;

  npvColor: string;
  npvFormulaText: string;
  npvStatus: ResultStatus;
  npvText: string;
  optionsVC: OptionsVc;
  pbpColor: string;
  pbpStatus: ResultStatus;
  permissions: Partial<Permissions>;
  roiText: string;
  selectedName = '';
  selectedVal = '';
  selectedCLR = '';
  selectedId: number;
  tcoText: string;
  threemoFormula: string;
  threemoText: string;
  VCchart: { labels: any[]; datasets: DataSet[] };
  years: number = 3;
  benefits = { metrics: [] };
  benefitTotal: string;
  colorVC = 'border-right: inherit';
  tcoFeature = false;
  toggleChart: boolean;
  valuePropStatusTypes: MenuItem[] = [];
  roiHasNonZeroCosts = false;
  translationsLoaded$ = new BehaviorSubject<boolean>(false);
  vcChartResCache: { vcData: APIResult<VCData>; valueProp: ValueProp; view: string };
  benefitTotalTrim: string;

  constructor(
    private commonService: CommonService,
    public trans: RepSimpleResultsTranslaions,
    public transTop: DefaultTranslations,
    public transBottom: BottomPanelTranslations,
    public translationService: TranslationsV2Service,
    private repSimpleService: RepSimpleViewService,
    public sI: DashboardSimpleImplementation,
    private valuePropService: ValuepropService
  ) {}

  getTranslations(valueProp, view, feature62) {
    if (valueProp) {
      const langId = sessionStorage.getItem('language_type_id');
      const langAbbr = this.translationService.getLanguageAbbr(langId);

      const accountId = sessionStorage.getItem('aid');

      const payload = {
        account_id: accountId,
        component: this.trans.config.component,
        lang: langAbbr,
      };

      const payloadTop = {
        account_id: accountId,
        component: this.transTop.config.component,
        lang: langAbbr,
      };

      const payloadBottom = {
        account_id: accountId,
        component: this.transBottom.config.component,
        lang: langAbbr,
      };

      forkJoin([
        this.translationService.getComponentTrans(payload),
        this.translationService.getComponentTrans(payloadTop),
        this.translationService.getComponentTrans(payloadBottom),
      ]).subscribe(([resultsTrans, topTrans, bottomTrans]) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, resultsTrans);
        this.transTop.trans = this.commonService.mergeObject(this.transTop.trans, topTrans);
        this.transBottom.trans = this.commonService.mergeObject(this.transBottom.trans, bottomTrans);

        this.translationsLoaded$.next(true);

        this.funcGetCharts(valueProp, view, feature62);
      });
    }
  }

  refreshROIChart(valueProp: ValueProp, feature62: boolean) {
    this.sI.valuePropLoader = true;
    this.repSimpleService.chartWithROI(valueProp.id).subscribe((res) => {
      this.funcGetROIChart(res, feature62);
    });
  }

  refreshVCChart(valueProp: ValueProp, view: string) {
    this.sI.valuePropLoader = true;
    this.repSimpleService.getVCChart(valueProp.id).subscribe((res) => {
      this.funcGetVCChart(res, valueProp, view);
    });
  }

  funcGetCharts(valueProp: ValueProp, view, feature62: boolean) {
    this.sI.valuePropLoader = true;
    let arr = [];
    arr.push(this.valuePropService.getScoreCard(valueProp.id));
    arr.push(this.repSimpleService.chartWithROI(valueProp.id));
    arr.push(this.repSimpleService.getPaybackChart(valueProp.id));
    arr.push(this.repSimpleService.getNPVChart(valueProp.id));
    arr.push(this.repSimpleService.getCostOfInactionChart(valueProp.id));
    arr.push(this.repSimpleService.getVCChart(valueProp.id));
    arr.push(this.repSimpleService.getTCOChart(valueProp.id, !!this.showAllMetrics ? 1 : 0));

    try {
      forkJoin(arr)
        .pipe(take(1))
        .subscribe(
          ([res1, res2, res3, res4, res5, res6, res7]: [
            APIResult<ScoreCard>,
            APIResult<ROIData>,
            APIResult<PaybackData>,
            APIResult<NPVData>,
            APIResult<InactionCostData>,
            APIResult<VCData>,
            APIResult<TCOData>
          ]) => {
            this.funcGetScoreCard(res1);
            this.funcGetROIChart(res2, feature62);
            this.funcGetPaybackChart(res3);
            this.funcGetNPVChart(res4);
            this.funcGet3MOChart(res5);
            this.funcGetVCChart(res6, valueProp, view);
            this.vcChartResCache = { vcData: res6, valueProp, view };
            this.funcGetTCOChart(res7);
            this.sI.valuePropLoader = false;
          }
        );
    } catch {}
  }

  funcGetScoreCard(response) {
    this.sI.valuePropLoader = true;
    if (response.result) {
      if (response && response.result && response.result.threshold_types) {
        for (let c = 0; c < response.result.threshold_types.length; c++) {
          let elem = response.result.threshold_types[c];
          switch (elem.keyword) {
            case 'ROI':
              this.colorROI = '2px solid ' + getColor(elem.status_id);
              this.roiStatus = getColorText(elem.status_id, elem.min_threshold, elem.max_threshold, elem.margin_threshold);
              this.roiColor = getColor(elem.status_id);
              break;
            case 'NPV':
              this.colorNPV = '2px solid ' + getColor(elem.status_id);
              this.npvStatus = getColorText(elem.status_id, elem.min_threshold, elem.max_threshold, elem.margin_threshold);
              this.npvColor = getColor(elem.status_id);

              break;
            case 'PBP':
              this.colorPBP = '2px solid ' + getColor(elem.status_id);
              this.pbpStatus = getColorText(elem.status_id, elem.min_threshold, elem.max_threshold, elem.margin_threshold);
              this.pbpColor = getColor(elem.status_id);

              break;
            case 'TCO':
              this.colorTCO = '2px solid ' + getColor(elem.status_id);
          }
        }
      }
    }
    this.sI.valuePropLoader = false;
  }

  funcGetPaybackChart(res) {
    let chartColors = this.commonService.getChartColors();

    this.chartPayback = {
      data: {
        datasets: [
          {
            label: this.trans.trans.netBenefits.value,
            data: res.result.net,
            type: 'line',
            borderColor: chartColors[2],
            fill: false,
          },
        ],
        labels: res.result.years,
      },
      d0: res.result.net_fmt,
    };
    this.paybackText = res.result.payback_formula;
    this.paybackFormulaText = res.result.payback_formula_text;
  }

  funcGetNPVChart(res) {
    let chartColors = this.commonService.getChartColors();
    this.chartNPV = {
      data: {
        datasets: [
          {
            label: this.trans.trans.npv.value,
            data: res.result.npv_yearly,
            type: 'line',
            borderColor: chartColors[2],
            fill: false,
          },
          {
            label: this.trans.trans.benefits.value,
            data: res.result.benefits,
            backgroundColor: chartColors[0],
          },
          {
            label: this.trans.trans.costs.value,
            data: res.result.costs,
            backgroundColor: chartColors[1],
          },
        ],
        labels: res.result.years,
      },
      d0: res.result.npv_yearly_fmt,
      d1: res.result.benefits_fmt,
      d2: res.result.costs_fmt,
    };
    this.npvText = res.result.npv_formula;
    this.npvFormulaText = res.result.npv_formula_text;
  }

  funcGetROIChart(res, feature62: boolean) {
    let chartColors = this.commonService.getChartColors();
    this.roiText = res.result.roi_formula;
    this.years = res.result.years.length;
    this.roiHasNonZeroCosts = res.result.costs.some((value) => value !== '0');

    const datasetTitle = this.roiHasNonZeroCosts ? this.trans.trans.benefitsAndCosts.value : this.trans.trans.benefits.value;

    this.chartROI = {
      data: {
        datasets: [
          {
            label: this.trans.trans.benefits.value,
            data: res.result.benefits,
            yAxisID: 'A',
            position: 'left',
            title: datasetTitle,
            backgroundColor: chartColors[0],
            order: 3,
          },
        ],
        labels: res.result.years.map((year) => {
          const yearTrans = this.trans.trans[year];
          if (yearTrans) {
            return yearTrans.value;
          } else {
            return year;
          }
        }),
      },
      d0: res.result.benefits_fmt,
      d1: res.result.costs_fmt,
      d2: res.result.roi_fmt,
    };

    if (this.roiHasNonZeroCosts) {
      this.chartROI.data.datasets.push({
        label: this.trans.trans.costs.value,
        data: res.result.costs,
        yAxisID: 'A',
        position: 'left',
        title: this.trans.trans.benefitsAndCosts.value,
        backgroundColor: chartColors[1],
        order: 2,
      });
    }

    if (!feature62 && this.roiHasNonZeroCosts) {
      this.chartROI.data.datasets.push({
        label: this.trans.trans.roi.value,
        data: res.result.roi,
        type: 'line',
        yAxisID: 'B',
        position: 'right',
        borderColor: chartColors[2],
        borderWidth: 1,
        pointBackgroundColor: chartColors[2],
        title: this.trans.trans.roi.value,
        fill: false,
        order: 1,
      });
    }
  }

  funcGetTCOChart(res) {
    let chartColors = this.commonService.getChartColors();

    this.chartTCO = {
      data: {
        datasets: [
          {
            label: this.trans.trans.currentCosts.value,
            data: res.result.current_state,
            backgroundColor: chartColors[0],
          },
          {
            label: this.trans.trans.futureCosts.value,
            data: res.result.future_state,
            backgroundColor: chartColors[1],
          },
        ],
        labels: res.result.years,
      },
      d0: res.result.current_state_fmt,
      d1: res.result.future_state_fmt,
      labels: ['current_state_fmt', 'future_state_fmt'],
    };
    this.tcoText = res.result.tco_formula;
  }

  funcGetVCChart(res: APIResult<VCData>, valueProp: ValueProp, view: string) {
    this.sI.valuePropLoader = true;
    this.chartVC = null;
    let chartColors = this.commonService.getChartColors();

    if (!res && !res.result && !res.result.names && !res.result.names.length) {
      this.chartVCLegend = [];
      return;
    } else {
      let labels = [];
      for (let i = 0; i < res.result.names.length; i++) {
        labels.push({
          name: res.result.names[i].name,
          description: res.result.names[i].description,
          color: chartColors[i],
          metrics: res.result.names[i].metrics,
          val: res.result.percents[i],
          fr: res.result.total_benefits_fr[i],
          fmt: res.result.total_benefits_fmt[i],
          id: res.result.names[i].id,
        });
      }

      this.chartVC = {
        data: {
          datasets: [
            {
              label: this.trans.trans.benefits.value,
              data: res.result.percents,
              backgroundColor: chartColors,
            },
          ],
          labels: labels,
        },
        d0: [res.result.total_benefits_fmt],
      };

      this.benefitTotal = res.result.total_annual_benefits;
      this.benefitTotalTrim = res.result.total_annual_benefits_fmt;

      this.chartVCLegend = res.result.names.length ? labels : [];
      this.inactionText = res.result.formula;
      this.buildChart(valueProp);
      const selectedCategory = this.selectedId || sessionStorage.getItem('benefit_index');
      if (selectedCategory && (view == 'benefits' || view == 'case')) {
        labels.map((category, index) => {
          if (category.id == selectedCategory) {
            category['value_category_id'] = category.id;
            this.clickBenefitClick(category, index, view);
          }
        });
      }
    }
    this.sI.valuePropLoader = false;
  }

  funcGet3MOChart(res) {
    let chartColors = this.commonService.getChartColors();

    this.threemoFormula = res.result.formula;
    this.threemoText = res.result.formula_text;
    this.chart3MO = {
      data: {
        datasets: [
          {
            label: this.trans.trans.benefits.value,
            data: [res.result.three_months_cost_of_inaction],
            backgroundColor: chartColors[0],
          },
        ],
        labels: ['Year 1'],
      },
      d0: [res.result.three_months_cost_of_inaction_fmt],
    };
    this.inactionText = res.result.formula;
  }

  buildChart(valueProp) {
    if (!this.chartVC) {
      return;
    }

    this.VCchart = {
      labels: this.chartVC.data.labels,
      datasets: this.chartVC.data.datasets,
    };

    this.optionsVC = {
      cutoutPercentage: 80,
      type: 'doughnut',
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.labels[tooltipItem.index].name + ': ' + data.datasets[0].data[tooltipItem.index] + '%';
            return label;
          },
        },
      },
      animation: {
        duration: 100,
      },
    };
    if (valueProp.benefits && sessionStorage.getItem('benefit_index') && sessionStorage.getItem('benefit_index') !== 'undefined') {
      let out = valueProp.benefits.filter((x) => x.value_category_id == sessionStorage.getItem('benefit_index'))[0];
      this.selectedCLR = out && out.color ? out.color : '#cdcdcd';
      this.selectedName = out && out.name ? out.name : '';
      this.selectedVal = out && out.vc_total_fmt ? out.vc_total_fmt : '';
      this.selectedId = out.value_category_id;
    } else {
      this.selectedCLR = this.VCchart.labels && this.VCchart.labels[0] ? this.VCchart.labels[0].color : '#cdcdcd';
      this.selectedName = this.VCchart.labels && this.VCchart.labels[0] ? this.VCchart.labels[0].name : '';
      this.selectedVal = this.VCchart.labels && this.VCchart.labels[0] ? this.VCchart.labels[0].fmt : '';
      this.selectedId = this.VCchart.labels && this.VCchart.labels[0] ? this.VCchart.labels[0].id : 0;
    }
    this.setSelectedCategory();
  }

  clickBenefitClick(item, i, view?, smallView?) {
    if (!smallView) {
      sessionStorage.setItem('benefit_index', item.value_category_id ?? item.id);
    }

    this.permissions = {
      benefits_editable: true,
      result_editable: true,
    };

    this.benefits.metrics = item.metrics;

    this.selectedCLR = item.color || this.selectedCLR;
    this.selectedName = item.name;
    this.selectedId = item.value_category_id ?? item.id;
    this.selectedVal = item.vc_total_fmt || item.fmt;
    item.metrics = funcFixVal(item.metrics);
    this.setSelectedCategory();
  }

  setSelectedCategory() {
    this.VCchart.labels.forEach((elem) => {
      elem.selected = elem.id === this.selectedId
    });
  }

  funcShowAllMetrics(valueProp, view, feature62) {
    this.toggleChart = true;

    setTimeout(() => {
      this.repSimpleService
        .getTCOChart(valueProp.id, this.showAllMetrics == true ? 1 : 0)
        .pipe(take(1))
        .subscribe((res) => {
          let chartColors = this.commonService.getChartColors();
          valueProp.tco_fmt = res.result.total_tco_cost_fmt;
          this.chartTCO = {
            data: {
              datasets: [
                {
                  label: this.trans.trans.currentCosts.value,
                  data: res.result.current_state,
                  backgroundColor: chartColors[0],
                },
                {
                  label: this.trans.trans.futureCosts.value,
                  data: res.result.future_state,
                  backgroundColor: chartColors[1],
                },
              ],
              labels: res.result.years,
            },
            d0: res.result.current_state_fmt,
            d1: res.result.future_state_fmt,
            labels: ['current_state_fmt', 'future_state_fmt'],
          };
          this.tcoText = res.result.tco_formula;
          this.toggleChart = false;
        });
      this.funcGetCharts(valueProp, view, feature62);
    }, 500);
  }

  clearServiceData(): void {
    this.selectedCLR = null;
    this.selectedVal = null;
    this.selectedName = '';
    this.VCchart = null;
    this.chartVC = null;
    this.chartROI = null;
    this.benefitTotal = '';
    this.vcChartResCache = null;
  }
}
