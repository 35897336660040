import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';
import { AddValueCategoryModalComponent } from '../../common/add-value-category-modal/add-value-category-modal.component';

@Component({
  selector: 'app-value-category',
  templateUrl: './value-category.component.html',
  styleUrls: ['./value-category.component.scss'],
})
export class ValueCategoryComponent implements OnInit, OnDestroy {
  @Input() solutionId: number;
  @Input() tabInit: number;
  @Input() can_edit = false;

  valueCategories: Array<{ [klass: string]: string }> = [];
  valueCategoryToEdit: { [klass: string]: string } = {};

  subscriptionreadAllValueCategory: Subscription;
  subscriptiongetValueCategories: Subscription;
  subscriptiondeleteSolutionValueCategory: Subscription;
  subscriptioneditSolutionValueCategory: Subscription;
  accountId: string;
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private notification: NotificationService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.accountId = sessionStorage.getItem('aid');

    this.getValueCategories();

    this.subscriptionreadAllValueCategory = this.solutionService.readAllValueCategory.subscribe(() => {
      this.getValueCategories();
    });
    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.subscriptionreadAllValueCategory) {
      this.subscriptionreadAllValueCategory.unsubscribe();
    }
    if (this.subscriptiongetValueCategories) {
      this.subscriptiongetValueCategories.unsubscribe();
    }
    if (this.subscriptiondeleteSolutionValueCategory) {
      this.subscriptiondeleteSolutionValueCategory.unsubscribe();
    }
    if (this.subscriptioneditSolutionValueCategory) {
      this.subscriptioneditSolutionValueCategory.unsubscribe();
    }
  }

  openAddValueCategory() {
    const modalReferenceValueCategory = this.modalService.open(AddValueCategoryModalComponent, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    modalReferenceValueCategory.componentInstance.solutionId = this.solutionId;
    modalReferenceValueCategory.componentInstance.isCreateAndEdit = true;
  }

  getValueCategories() {
    this.subscriptiongetValueCategories = this.solutionService.getAllValueCategories(this.accountId).subscribe((response) => {
      this.valueCategories = [];

      if (response.result && response.result) {
        this.valueCategories = response.result;
      }
    });
  }

  deleteSolutionValueCategory(valueCategoryId) {
    this.subscriptiondeleteSolutionValueCategory = this.solutionService.deleteSolutionValueCategoryNoSolutionID(valueCategoryId).subscribe((response) => {
      if (response.result) {
        this.notification.success(response.result, false);
        this.getValueCategories();
      }
    });
  }
  editSolutionValueCategory(valueCategory, modal) {
    this.modalService.open(modal, { windowClass: 'updateValueCategoryModal deleteModal', backdrop: 'static', keyboard: false });
    this.valueCategoryToEdit = valueCategory;
  }

  updateValueCategory(closeModal) {
    const payload = {
      value_category_id: this.valueCategoryToEdit['id'],
      name: this.valueCategoryToEdit['name'],
      description: this.valueCategoryToEdit['description'],
    };

    this.accountId = sessionStorage.getItem('aid');
    this.subscriptioneditSolutionValueCategory = this.solutionService.editSolutionValueCategory(payload, this.accountId).subscribe((response) => {
      if (response.result) {
        this.notification.success(response.result.message, false);
        this.solutionService.readAllValueCategory.next('readList');
        closeModal('Close click');
      }
    });
  }
}
