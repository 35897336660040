<div class="col-xs-12 card-wrapper">
    <!-- Title -->
    <div class="row">
        <div class="col-xs-8 col-md-10 mx-10">
            <span class="light-h">{{trans.trans.myTeam.value}}</span>
        </div>
        <div class="align-right">
            <a routerLink="/team">{{trans.trans.edit.value}}</a>
        </div>
    </div>

    <div class="row" class="team-content-wrapper">
        <div class="col-xs-12 col-md-12 col-lg-6" *ngFor="let member of teamMembers">
            <div class="member-wrapper">
                <div class="row">
                    <div class="col-xs-4 col-md-4 col-lg-3">
                        <img class="img-responsive img-circle image-sm"
                            [src]="member.profile_pic_filepath != '' ? member.profile_pic_filepath : profilepicdefaultpath">
                    </div>
                    <div class="col-xs-8 col-md-8 col-lg-9">
                        <div class="dark-t member-name" >{{member.user_name}}</div>
                        <div class="member-role light-h">{{member.role_name}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>