import { Injectable } from '@angular/core';
import {TranslationsBaseService} from '@shared/translations-base/translations-base.service';

@Injectable()
export class CompanyTrackersTranslation extends TranslationsBaseService {
  public config: any = {
    component: 'companyTrackers',
    display_name: 'Company Trackers List',
    description: 'A list of all trackers by company',
  };

  public trans = this.typeObjectKeys({
    add: 'Add New',
    achieved: 'Achieved',
    achievedToDate: 'Achieved to Date',
    all: 'All',
    areYouSure: 'Are you sure?',
    delete: 'Delete tracker',
    edit: 'Edit tracker settings',
    errorDelete: `We couldn't process the deletion of `,
    errorDetails: 'There was a problem while retrieving the information',
    expected: 'Expected',
    expectedtoDate: 'Expected to Date',
    varianceToDate: 'Variance to Date %',
    dashboard: 'Dashboard',
    measurement: 'Measurement',
    measurmentBegin: 'Measurement Begin',
    mine: 'Mine',
    owner: 'Owner',
    search: 'Search',
    successDelete: 'was successfully deleted',
    shared: 'Shared with Me',
    trackerName: 'Tracker Name',
    surveys: 'Surveys',
    measurementPeriods: 'Measurement Periods',
    valueRealized: 'Value Realized',
    valueTrackersFor: 'Value Trackers for ',
    valueTrackersTooltip: 'Click on a tracker name to Enter Measurement Milestone Data or to Review Actual vs Expected Data',
    lastMeasurement: 'Last Measurement',
    measurementType: 'Measurement Type',
    save: 'Save',
    reset: 'Reset',
    includeTrackers: 'Tracker(s) to include',
    excludeTrackers: 'Tracker(s) to exclude',
    saveSuccess: 'Selection saved successfully',
    saveError: 'Error saving selection',
  });
}
