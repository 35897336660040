import { Injectable } from '@angular/core';

@Injectable()

export class DefaultTranslations {

  public config: any = {
    component: 'add-value-position',
    display_name: 'Add Value Position',
    description: 'Add value position'
  };

  public trans: any = {
    opportunity_id: 'Opportunity ID',
    back: 'Back',
    next: 'Next',
    ch_text: 'Include the VMstar / SFDC opportunity ID to help with tracking the opportunity and reporting. If the opportunity does not have an ID yet, enter "99999999" as a place holder.',
    required: 'Required',
    question: 'What is the customer problem? Describe in detail.',
    how_would_you_like_to_determine_the_solutions_for_this_value_prop: 'How would you like to determine the solutions for this Business Case / Value Assessment',
    project_based: 'Project Based',
    product_based: 'Product Based',
    all_scalers: 'All Scalers',
    apply: 'Apply',
    based_on_your_project_selection_we_suggest_the_following_products: 'Based on your project selection, we suggest the following products:',
    choose_category_type: 'Choose Category Type:',
    continue_to_configure: 'Continue to Configure',
    discover_opportunities: 'Discover Opportunities',
    finish_and_review: 'Finish and Review',
    going_back_a_few_steps_this_may_reset_subsequent_steps_and_start_from_the_current_step: 'Going back a few steps? This may reset subsequent steps and start from the current step.',
    models: 'Models',
    no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_abov: 'No Models for this category, please try a different one by selecting from the dropdown above.',
    no_models_for_this_project: 'No Models for this project.',
    no_projects_found: 'No Projects Found',
    no_scalers_found: 'No Scalers Found.',
    pick_your_models: 'Pick your models',
    pick_your_projects: 'Pick your projects',
    pick_your_situations: 'Pick your situations',
    primary_scalers: 'Primary Scalers',
    scale_your_value_position: 'Scale Your Value Hypothesis',
    scale_your_value_prop: 'Scale Your Business Case / Value Assessment',
    select_your_project_below: 'Select Your Project Below',
    situations: 'Situations',
    calculate: 'Calculate',
    cancel: 'Cancel',
    yes: 'Yes',
    closed: 'Closed'
  };
}
