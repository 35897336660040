import { Injectable } from '@angular/core';

@Injectable()
export class ValuePropCashflowTranslations {
	public config: any = {
		component: 'cashflow',
		display_name: 'Cashflow VP Dashboard',
		description: 'Cashflow tab in vp dashboard top panel'
    }
    public trans: any = {
        ch_cashflow: "Cash flow is the net amount of cash and cash-equivalents being transferred into and out of a business. At the most fundamental level, a company's ability to create value for shareholders is determined by its ability to generate positive cash flows, or more specifically, maximize long-term free cash flow.",
        cashflow: 'Cashflow',
        name: "Name",
        year1: "Year 1",
        year2: "Year 2",
        year3: "Year 3",
        year4: "Year 4",
        year5: "Year 5",
        total: "Total",
        no_cashflow_found: "No cashflow found"
    }
}