<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{trans.trans.viewCompetitors.value}}
		<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
		[isTranslationV2]="true" [transObj]="trans.trans.viewCompetitors" (callback)="getTranslations()"></app-editTranslation>
	</div>

	<div class='sidebar_container'>
		<div class='keywest_panel'>

			<div class='row'>
				<div class="col-md-12">
					<label for="alterEgo" class="sidebar_label_readonly">{{trans.trans.name.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{competitorFormObj.name}}
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-6">
					<label for="description" class="sidebar_label_readonly">{{trans.trans.description.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{competitorFormObj.description}}
				</div>

				<div class="col-xs-6">
					<label for="landmines" class='sidebar_label_readonly'>{{trans.trans.landmines.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.landmines" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{competitorFormObj.landmines}}
				</div>
			</div>

			<div class='row'>
				<div class="col-xs-6">
					<label for="theyll_say" class='sidebar_label_readonly'> {{trans.trans.theyllSay.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.theyllSay" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{competitorFormObj.theyll_say}}
				</div>

				<div class="col-xs-6">
					<label for="their_landmines" class="sidebar_label_readonly">{{trans.trans.theirLandmines.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.theirLandmines" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{competitorFormObj.their_landmines}}
				</div>
			</div>

			<div class='row'>
				<div class="col-md-12">
					<label for="our_response" class='sidebar_label_readonly'>{{trans.trans.ourResponse.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.ourResponse" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{competitorFormObj.our_response}}
				</div>
			</div>

		</div>
	</div>
</div>