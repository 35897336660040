<ng-container *ngIf="createNewFactor">
  <app-ae-factors
    [back]="true"
    [embed]="false"
    [onScratchpad]="true"
    (callbackBack)="createNewFactor = !createNewFactor; getGroupedFactors(true)"
    *ngIf="createNewFactor && !isGoal"
    [mode]="'add'"
    (callback)="getGroupedFactors(false); createNewFactor = false"
  ></app-ae-factors>
  <app-ae-factors
    [back]="true"
    [embed]="false"
    [onScratchpad]="true"
    (callbackBack)="createNewFactor = !createNewFactor; getScaledBy(true)"
    *ngIf="createNewFactor && isGoal"
    [mode]="'add'"
    (callback)="getScaledBy(false); createNewFactor = false"
  ></app-ae-factors>
</ng-container>

<ng-container *ngIf="editFactor">
  <app-ae-factors
          [back]="true"
          [embed]="false"
          [onScratchpad]="true"
          (callbackBack)="clearEditFactor(false)"
          mode="edit"
          [formObjFactor]="selectedFactor"
          (callback)="clearEditFactor(true)"
  ></app-ae-factors>
</ng-container>

<form [hidden]="createNewFactor || editFactor" name="addDriverFactorForm" #addDriverFactorForm="ngForm">
  <div [ngClass]="{ sidebar_wrapper: !embed }">
    <div class="sidebar_header">
      {{ sidebar_title }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="'ae_scratchpads'"
        [isTranslationV2]="true"
        [transObj]="trans.trans.edit_scratchpad"
        (callback)="getTranslations()"
      ></app-editTranslation>
      <button *ngIf="!isGoal" class="btn btn_customizable pull-right mr-2" (click)="funcShowAddFactor()" matTooltipPosition="above" style="margin-right: 60px">
        <i class="fa fa-plus"></i>{{ trans.trans.addFactor.value }}
      </button>
    </div>

    <div class="sidebar_container" style="margin-bottom: 0px">
      <div class="keywest_panel" style="overflow-y: auto; max-height: 90vh">
        <div *ngIf="isGoal" class="name-wrapper">
          <span class="goal-name-title">{{ trans.trans.goalName.value }}</span>
          <span class="goal-name">
            {{ factor.name }}
          </span>
        </div>
        <div class="row row_nomargin">
          <div class="col-xs-12">
            <p *ngIf="!isGoal" class="well well-info" style="margin-bottom: 20px">
              {{ trans.trans.note.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'ae_scratchpads'"
                [isTranslationV2]="true"
                [transObj]="trans.trans.note"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <p-progressBar [style.padding]="'10px'" *ngIf="scratchpad_loading && factor?.has_scratchpad === 1" mode="indeterminate"></p-progressBar>
            <div [class.panel]="!isGoal" [class.panel-white]="!isGoal">
              <div [class.panel-heading]="!isGoal" [ngStyle]="!isGoal ? { 'height': 'inherit !important', 'background-color': '#f5f5f5', 'padding-bottom': '0px' } : {}">
                <span [class.goal-formula]="isGoal">
                  {{ trans.trans.formula.value }}
                </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="'ae_scratchpads'"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.formula"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <div class="keywest_icon_group pull-right">
                  <span *ngIf="!isGoal">
                    <i
                      class="fa fa-calculator"
                      [class.disabled]="addDriverFactorForm.form?.invalid"
                      [class.clickable]="addDriverFactorForm.form?.valid"
                      matTooltipPosition="above"
                      [matTooltip]="trans.trans.ch_calculate_scratchpad.value"
                      (click)="calculate()"
                    ></i>
                  </span>
                  <span>
                    <i
                      [class.disabled]="addDriverFactorForm.form?.invalid"
                      [class.clickable]="addDriverFactorForm.form?.valid"
                      class="fa fa-save"
                      matTooltipPosition="above"
                      [matTooltip]="trans.trans.ch_save_scratchpad.value"
                      (click)="saveDriverScratchpads(addDriverFactorForm)"
                    ></i>
                  </span>
                  <span>
                    <app-are-you-sure
                      [message]="trans.trans.are_you_sure.value"
                      text=""
                      [tooltip]="trans.trans.ch_delete_scratchpad.value"
                      icon="fa fa-trash"
                      (callbackYes)="deleteScratchpad()"
                    ></app-are-you-sure>
                  </span>
                  <span><i [matTooltip]="trans.trans.ch_add_row.value" matTooltipPosition="above" (click)="addDriverScratchpad()" class="fa fa-plus clickable"></i></span>
                </div>
              </div>
              <div class="panel-body" [class.goal-scratchpad-formula]="isGoal" [ngStyle]="!isGoal ? { 'margin': '0px', 'border-spacing': '0px' } : {}">
                <div class="scratchpad_formula" style="float: left">
                  <span [style.color]="!isGoal ? 'orange' : ''">{{ OpenParen_default }}</span>
                  <span [style.color]="!isGoal ? 'blue' : ''">{{ driverScratchpadFactor_default ? driverScratchpadFactor_default['name'] : '' }}</span>
                  <span *ngIf="!isGoal" [style.color]="!isGoal ? 'black' : ''">{{ driverScratchpadFactorValue_default }}</span>
                  <span [style.color]="!isGoal ? 'orange' : ''">{{ CloseParen_default }}</span>
                </div>
                <div class="scratchpad_formula" style="float: left" *ngFor="let driverScratchpadField of driverScratchpadFields; let i = index">
                  <span [style.color]="!isGoal ? 'green' : ''">{{ funcGetSign(driverScratchpadField.operator) }}</span>
                  <span [style.color]="!isGoal ? 'orange' : ''">{{ driverScratchpadField.openParen }}</span>
                  <span [style.color]="!isGoal ? 'blue' : ''">{{ driverScratchpadField.factor?.name }}</span>
                  <span *ngIf="!isGoal" [style.color]="!isGoal ? 'black' : ''">{{ driverScratchpadField.operand }}</span>
                  <span [style.color]="!isGoal ? 'orange' : ''">{{ driverScratchpadField.closeParen }}</span>
                </div>
                <div class="scratchpad_formula" style="float: left">
                  <ng-container *ngIf="scratchpadCalculation$ | async as scratchpadTotal">
                    <span [style.color]="!isGoal ? 'green' : ''">=</span>
                    <span [style.color]="!isGoal ? 'red' : ''">{{ scratchpadTotal }}</span>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="panel panel-white">
              <div class="panel-body" style="margin-top: 10px; border-spacing: 0px">
                <div class="row driver-factor-grid first-row">
                  <div>
                    <select class="form-control" [(ngModel)]="OpenParen_default" name="OpenParen_default" (change)="editingScratchpad()">
                      <option [ngValue]="undefined" selected>{{ trans.trans.select.value }}</option>
                      <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                        {{ paren.sign }}
                      </option>
                    </select>
                  </div>

                  <div [ngClass]="{ 'wide': !isGoal, 'very-wide': isGoal }">
                    <app-error-input-wrapper
                      [showOnTouched]="addDriverFactorForm.controls?.driverScratchpadFactor_default?.touched"
                      [errorMessage]="errorDict.get('driverScratchpadFactor_default')"
                    >
                      <div class="p-inputgroup-addon">
                        <div *ngIf="driverScratchpadFactor_default" class="btn btn_customizable input-left-icon-addon clickable" (click)="openEditFactor(driverScratchpadFactor_default)">
                          <span class="fa fa-plus"></span>
                        </div>
                        <p-dropdown
                                *ngIf="!isGoal"
                                appendTo="body"
                                [autoDisplayFirst]="false"
                                optionLabel="name"
                                [options]="groupedFactors"
                                name="driverScratchpadFactor_default"
                                [(ngModel)]="driverScratchpadFactor_default"
                                (ngModelChange)="editingScratchpad()"
                                [filter]="true"
                                filterBy="name"
                                class="keywest_dropdown"
                                [required]="true"
                                [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
                                [group]="true"
                                  optionGroupChildren = "factors"
                              >
                        <ng-template let-group pTemplate="group">
                          <div class="p-d-flex p-ai-center">
                            <span>{{group.name}}</span>
                          </div>
                        <ng-template let-factor pTemplate="item">
                          <div>
                            <span>{{factor.name}}</span>
                          </div>
                        </ng-template>
                        </ng-template>
                      </p-dropdown>
            <p-dropdown
                        *ngIf="isGoal"
                        appendTo="body"
                        [autoDisplayFirst]="false"
                        optionLabel="name"
                        [options]="scaledBy"
                        name="driverScratchpadFactor_default"
                        [(ngModel)]="driverScratchpadFactor_default"
                        (ngModelChange)="editingScratchpad()"
                        [filter]="true"
                        filterBy="name"
                        class="keywest_dropdown"
                        [required]="true"
                        [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
                    ></p-dropdown>  
                      </div>
                    </app-error-input-wrapper>
                  </div>

                  <div *ngIf="!isGoal">
                    <app-error-input-wrapper
                      [showOnTouched]="addDriverFactorForm.controls?.driverScratchpadFactorValue_default?.touched"
                      [errorMessage]="errorDict.get('driverScratchpadFactorValue_default')"
                    >
                      <input
                        class="form-control"
                        type="number"
                        name="driverScratchpadFactorValue_default"
                        [(ngModel)]="driverScratchpadFactorValue_default"
                        (ngModelChange)="editingScratchpad()"
                        [required]="!isGoal"
                      />
                      <span>{{ driverScratchpadFactor_default?.unit_type }}</span>
                    </app-error-input-wrapper>
                  </div>
                  <div>
                    <select class="form-control" [(ngModel)]="CloseParen_default" name="CloseParen_default">
                      <option [ngValue]="undefined" selected>{{ trans.trans.select.value }}</option>
                      <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                        {{ paren.sign }}
                      </option>
                    </select>
                  </div>
                  <div></div>
                </div>
                <div></div>
                <div *ngFor="let driverScratchpadField of driverScratchpadFields; let i = index" class="row driver-factor-grid">
                  <div>
                    <app-error-input-wrapper [showOnTouched]="addDriverFactorForm.controls['selectOperator_' + i]?.touched" [errorMessage]="errorDict.get('selectOperator_' + i)">
                      <select
                        class="form-control"
                        [(ngModel)]="driverScratchpadField.operator"
                        name="selectOperator_{{ i }}"
                        [required]="true"
                        (ngModelChange)="editingScratchpad()"
                      >
                        <option [ngValue]="undefined" hidden selected>{{ trans.trans.operator.value }}</option>
                        <option *ngFor="let operator of operatorTypesList" [ngValue]="operator.id">
                          {{ operator.sign }}
                        </option>
                      </select>
                    </app-error-input-wrapper>
                  </div>
                  <div>
                    <select class="form-control" [(ngModel)]="driverScratchpadField.openParen" name="scratchpadOpenParen_{{ i }}" (ngModelChange)="editingScratchpad()">
                      <option [ngValue]="undefined" selected>{{ trans.trans.select.value }}</option>
                      <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                        {{ paren.sign }}
                      </option>
                    </select>
                  </div>

                  <div [ngClass]="{ wide: isGoal }">
                    <app-error-input-wrapper
                      [showOnTouched]="addDriverFactorForm.controls['scratchpadDriverFactor_' + i]?.touched"
                      [errorMessage]="errorDict.get('scratchpadDriverFactor_' + i)"
                    >
                      <div class="p-inputgroup-addon">
                        <div *ngIf="driverScratchpadField?.factor" class="btn btn_customizable input-left-icon-addon clickable" (click)="openEditFactor(driverScratchpadField?.factor)">
                          <span class="fa fa-plus"></span>
                        </div>
                        <p-dropdown
                                *ngIf="!isGoal"
                                appendTo="body"
                                [autoDisplayFirst]="false"
                                optionLabel="name"
                                [options]="groupedFactors"
                                name="scratchpadDriverFactor_{{ i }}"
                                [(ngModel)]="driverScratchpadField.factor"
                                (ngModelChange)="editingScratchpad()"
                                [filter]="true"
                                filterBy="name"
                                class="keywest_dropdown"
                                [required]="true"
                                panelStyleClass="dropdown-item-font"
                                [group]="true"
                                optionGroupChildren = "factors"
                              >
                        <ng-template let-group pTemplate="group">
                          <div class="p-d-flex p-ai-center">
                            <span>{{group.name}}</span>
                          </div>
                        <ng-template let-factor pTemplate="item">
                          <div>
                            <span>{{factor.name}}</span>
                          </div>
                        </ng-template>
                        </ng-template>
                      </p-dropdown>
                      <p-dropdown
                                *ngIf="isGoal"
                                appendTo="body"
                                [autoDisplayFirst]="false"
                                optionLabel="name"
                                [options]="scaledBy"
                                name="scratchpadDriverFactor_{{ i }}"
                                [(ngModel)]="driverScratchpadField.factor"
                                (ngModelChange)="editingScratchpad()"
                                [filter]="true"
                                filterBy="name"
                                class="keywest_dropdown"
                                [required]="true"
                                panelStyleClass="dropdown-item-font"
                                ></p-dropdown>

                      </div>
                    </app-error-input-wrapper>
                  </div>
                  <div *ngIf="!isGoal">
                    <app-error-input-wrapper
                      [showOnTouched]="addDriverFactorForm.controls['scratchpadDriverValue_' + i]?.touched"
                      [errorMessage]="errorDict.get('scratchpadDriverValue_' + i)"
                    >
                      <input
                        class="form-control"
                        type="number"
                        name="scratchpadDriverValue_{{ i }}"
                        [(ngModel)]="driverScratchpadField.operand"
                        (ngModelChange)="editingScratchpad()"
                        [required]="!isGoal"
                      />
                      <span>{{ driverScratchpadField?.factor?.unit_type }}</span>
                    </app-error-input-wrapper>
                  </div>

                  <div>
                    <select class="form-control" [(ngModel)]="driverScratchpadField.closeParen" name="scratchpadCloseParen_{{ i }}" (ngModelChange)="editingScratchpad()">
                      <option [ngValue]="undefined" selected>{{ trans.trans.select.value }}</option>
                      <option *ngFor="let paren of parenthesis" [ngValue]="paren.sign">
                        {{ paren.sign }}
                      </option>
                    </select>
                  </div>
                  <div class="delete-icon">
                    <span style="display: flex">
                      <app-are-you-sure
                        matTooltip="{{ trans.trans.ch_delete_row.value }}"
                        matTooltipPosition="above"
                        message="{{ trans.trans.are_you_sure.value }}"
                        text=""
                        icon="fa fa-trash"
                        class="flex-omni-c"
                        (callbackYes)="driverScratchpadClose(driverScratchpadField.id)"
                      ></app-are-you-sure>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isGoal" class="buttons">
    <button
      (click)="saveDriverScratchpads(addDriverFactorForm)"
      class="btn btn_customizable pull-right"
      [disabled]="addDriverFactorForm.form.invalid || driverScratchpadFields.length === 0"
    >
      {{ trans.trans.save.value }}
    </button>
    <button (click)="callback.emit()" class="btn pull-right back-button">{{ trans.trans.back.value }}</button>
  </div>
</form>
