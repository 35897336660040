<div class="downloadList">
    <div class="table-responsive m-t-md">
        <table class="table custom-table custom-responsive-table">
            <thead>
                <tr>

                    <th>{{ trans.research.companyName | translate }}</th>
                    <th>{{ trans.research.dateOfDownload | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr  class="grid-row" *ngFor="let downloadDetail of downloadDetails" (click)="selectCompany(+downloadDetail.company_id)">
                    <td>{{ downloadDetail.name }}</td>
                    <td>{{ downloadDetail.created | date }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>