<p-menu #menuStatus appendTo="body" [popup]="true" [model]="valuePropStatusTypes" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div *ngIf="valueProp">
  <div class="row">
    <div class="tour_more_info col-lg-3 col-md-4 col-sm-12 col-xs-12">
      <div class="panel" style="margin-bottom: 10px; border-radius: 10px !important" [ngClass]="view == 'case' ? 'panel-shadow' : 'no-shadow'">
        <div class="panel-body clickable" (click)="funcChangeView('case')" style="margin-bottom: 10px">
          <h2
            *ngIf="!changeName && valueProp.vp_can_edit"
            class="nav-title clickable nav-title-name"
            style="font-weight: bold"
            (click)="funcChangeView('case')"
            [ngStyle]="view == 'case' && { color: headerColor }"
          >
            {{ valueProp.name }}
            <a *ngIf="!changeName && valueProp.closed !== '1'" matTooltip="Rename Business Case" (click)="changeName = !changeName"
              ><i style="margin-left: 10px" class="fa fa-pencil"></i
            ></a>
          </h2>

          <div class="input-group" *ngIf="changeName && !(style2022$ | async)">
            <input [(ngModel)]="vpName" class="form-control" />
            <div class="input-group-btn">
              <button (click)="changeValuePropName(vpName)" class="btn btn-success" [matTooltip]="trans.trans.save.value" matTooltipPosition="above" container="body">
                <i class="fa fa-save"></i>
              </button>
              <button (click)="cancelValuePropName()" class="btn btn-danger" [matTooltip]="trans.trans.cancel.value" matTooltipPosition="above" container="body">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
          <div class="nav-text clickable" (click)="funcChangeView('case')">
            {{ trans.trans.business_case.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.business_case"
            ></app-editTranslation>
          </div>
          <div class="nav-text" style="font-size: 10px; font-weight: bold">{{ trans.trans.createdBy.value }} {{ valueProp.creators_name }}</div>

          <div class="nav-text clickable">
            <div
              (click)="valueProp.closed == '0' && valueProp.vp_can_edit ? menuStatus.toggle($event) : ''"
              matTooltip="{{ trans.trans.edit_status.value }}"
              matTooltipPosition="below"
              style="float: left"
            >
              <i class="fa fa-tasks"></i> {{ valueProp.vp_status }}
            </div>

            <a
              *ngIf="valueProp.closed == '0'"
              class="nav-text clickable"
              style="margin-left: 10px; color: green"
              (click)="lockValueProp('lock')"
              [matTooltip]="trans.trans.lock.value"
              matTooltipPosition="below"
            >
              <i class="prop-icon icon-lock-open"></i>
            </a>
            <a
              *ngIf="valueProp.closed == '1'"
              class="nav-text clickable"
              style="margin-left: 10px; color: red"
              (click)="lockValueProp('unlock')"
              [ngClass]="{ locked: valueProp.closed === '1' }"
              [matTooltip]="trans.trans.unlock.value"
              matTooltipPosition="below"
            >
              <i class="prop-icon icon-lock"></i>
            </a>
          </div>

          <div class="arrow" style="top: 70px" *ngIf="view == 'case'"></div>
        </div>
      </div>

      <div class="panel panel-results" *ngIf="!feature62" style="border-radius: 10px; margin-bottom: 10px" [ngClass]="view == 'roi' ? 'panel-shadow' : 'panel-results'">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': colorROI }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('roi')">
          <h2 class="nav-title clickable" style="font-weight: bold" (click)="funcChangeView('roi')" [ngStyle]="view == 'roi' && { color: headerColor }">
            {{ valueProp.roi_decorated }}
          </h2>
          <div class="nav-text clickable" (click)="funcChangeView('roi')">
            {{ years }} {{ trans.trans.year_return_on_investment.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.year_return_on_investment"
            ></app-editTranslation>
          </div>
          <div class="arrow" style="top: 20px" *ngIf="view == 'roi'"></div>
        </div>
      </div>

      <div *ngIf="showTCO" class="panel panel-results" style="margin-bottom: 10px; border-radius: 10px" [ngClass]="view == 'tco' ? 'panel-shadow' : 'panel-results'">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': colorTCO }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('tco')">
          <h2 class="nav-title clickable" style="font-weight: bold" (click)="funcChangeView('tco')" [ngStyle]="view == 'tco' && { color: headerColor }">{{ valueProp.tco_fmt }}</h2>
          <div class="nav-text clickable" (click)="funcChangeView('tco')">
            {{ years }} {{ trans.trans.year_total_cost_of_ownership.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.year_total_cost_of_ownership"
            ></app-editTranslation>
          </div>
          <div class="arrow" style="top: 20px" *ngIf="view == 'tco'"></div>
        </div>
      </div>

      <div *ngIf="!hidePaybackFeature" class="panel panel-results" style="margin-bottom: 10px; border-radius: 10px" [ngClass]="{ 'panel-shadow': view == 'payback' }">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': colorPBP }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('payback')">
          <h2 class="nav-title clickable" style="font-weight: bold" (click)="funcChangeView('payback')" [ngStyle]="view == 'payback' && { color: headerColor }">
            {{ valueProp.payback_fmt }}
          </h2>
          <div class="nav-text clickable" (click)="funcChangeView('payback')">
            {{ trans.trans.payback_period_in_months.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.payback_period_in_months"
            ></app-editTranslation>
          </div>
          <div class="arrow" style="top: 20px" *ngIf="view == 'payback'"></div>
        </div>
      </div>

      <div *ngIf="!hideNPV" class="panel panel-results" style="margin-bottom: 10px; border-radius: 10px" [ngClass]="{ 'panel-shadow': view == 'npv' }">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': colorNPV }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('npv')">
          <h2 class="nav-title clickable" style="font-weight: bold" (click)="funcChangeView('npv')" [ngStyle]="view == 'npv' && { color: headerColor }">{{ valueProp.npv_fmt }}</h2>
          <div class="nav-text clickable" (click)="funcChangeView('npv')">
            {{ years }}{{ trans.trans.year_net_present_value.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.year_net_present_value"
            ></app-editTranslation>
          </div>
          <div class="arrow" style="top: 20px" *ngIf="view == 'npv'"></div>
        </div>
      </div>

      <div class="panel panel-results" style="margin-bottom: 10px; border-radius: 10px" [ngClass]="{ 'panel-shadow': view == '3mo' }">
        <div class="panel-body clickable" (click)="funcChangeView('3mo')">
          <h2 class="nav-title clickable" style="font-weight: bold" (click)="funcChangeView('3mo')" [ngStyle]="view == '3mo' && { color: headerColor }">
            {{ valueProp.three_month_cost_of_inaction_fmt }}
          </h2>
          <div class="nav-text clickable" (click)="funcChangeView('3mo')">
            {{ trans.trans.three_month_cost_of_delay.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.three_month_cost_of_delay"
            ></app-editTranslation>
          </div>
          <div class="arrow" style="top: 20px" *ngIf="view == '3mo'"></div>
        </div>
      </div>

      <div *ngIf="feature59" class="panel panel-results" style="margin-bottom: 10px; border-radius: 10px" [ngClass]="{ 'panel-shadow': view == 'benpertrans' }">
        <div class="panel-body clickable" (click)="funcChangeView('benpertrans')">
          <h2 class="nav-title clickable" style="font-weight: bold" (click)="funcChangeView('benpertrans')" [ngStyle]="view == '3mo' && { color: headerColor }">
            {{ valueProp.benefits_per_transaction_fmt }}
          </h2>
          <div class="nav-text clickable" (click)="funcChangeView('benpertrans')">{{ trans.trans.benefits_per_transaction.value }}</div>
          <div class="arrow" style="top: 20px" *ngIf="view == 'benpertrans'"></div>
        </div>
      </div>
    </div>

    <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12">
      <div *ngIf="(view == 'discovery' || view == 'case' || view == 'benefits' || view == 'notes') && chartROI && chartVC">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 chart_left">
          <div class="panel panel-info tour_benefits" style="margin-bottom: 0px">
            <div class="panel-body" *ngIf="!valueProp.benefits" style="height: 276px">
              <p>{{ trans.trans.no_benefits.value }}</p>
            </div>
            <div class="panel-body" *ngIf="valueProp.benefits">
              <div class="row" style="margin-bottom: 15px">
                <div class="col-xs-12">
                  <span class="total"
                    >{{ trans.trans.potential_total_benefit.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.potential_total_benefit"
                      (callback)="refreshTranslation()"
                    ></app-editTranslation> </span
                  >&nbsp; <span class="actual_total">+ {{ benefitTotal }}</span>
                </div>
              </div>
              <div class="chartcontainer" *ngIf="!valueProp.benefits_loading">
                <div class="row">
                  <div class="col-md-6 no-p">
                    <p-chart type="doughnut" height="180px" [options]="optionsVC" [data]="VCchart"></p-chart>
                    <div class="absolute-center text-center" [ngStyle]="{ color: selectedCLR }">
                      <span class="chart_val" style="padding-bottom: 12px">{{ selectedVal | numberTruncate }}</span
                      ><br />
                      <span class="chart_text">{{ selectedName }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 no-p" style="overflow-y: auto; height: 199px">
                    <div
                      class="row"
                      *ngFor="let elem of chartVC.data.labels; let i = index"
                      style="margin-left: 0px; margin-right: 15px; border-bottom: solid 1px #d8e1e6; margin-bottom: 10px"
                    >
                      <div class="col-xs-1" style="padding-left: 0px">
                        <p class="chart-icon clickable" (click)="clickBenefitClick(elem)" [ngStyle]="{ 'border-color': elem.color }"></p>
                      </div>
                      <div class="col-xs-10" style="padding-right: 0px">
                        <div class="val clickable" (click)="clickBenefitClick(elem)">{{ elem.val }}%</div>
                        <div (click)="clickBenefitClick(elem)" class="clickable label" style="width: 100%; display: block; text-align: left; white-space: pre-line">
                          {{ elem.name }}
                          <i *ngIf="elem.description && !(style2022$ | async)" [matTooltip]="elem.description" class="fa fa-info-circle"></i>
                          <span *ngIf="elem.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description">
                            info
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 chart_right">
          <div class="panel panel-info tour_roi" style="margin-bottom: 0px">
            <div class="panel-body">
              <div class="row" style="margin-bottom: 15px">
                <div class="col-xs-12">
                  <span class="total"
                    >{{ trans.trans.cost_vs_benefit.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.cost_vs_benefit"
                      (callback)="refreshTranslation()"
                    ></app-editTranslation> </span
                  >&nbsp; <span class="actual_total" style="font-size: 16px">= {{ trans.trans.return.value }}</span>
                </div>
              </div>

              <app-bar-line-chart *ngIf="valueProp && !sI.valuePropLoader && chartROI" [axis2]="true" height="200px" [data]="chartROI"></app-bar-line-chart>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="panel" style="margin-bottom: 0px; box-shadow: 0; margin-top: 10px; background-color: inherit; border-width: 0px">
              <div class="panel-heading" style="border-bottom: solid 1px #e2eff7 !important" *ngIf="view == 'discovery'">
                {{ trans.trans.discoveryQuestions.value }} <a (click)="view = 'case'" class="pull-right clickable"><i class="fa fa-times"></i></a>
              </div>
              <div class="panel-heading" style="border-bottom: solid 1px #e2eff7 !important" *ngIf="view == 'notes'">
                {{ trans.trans.Notes.value }} <a (click)="view = 'case'" class="pull-right clickable"><i class="fa fa-times"></i></a>
              </div>
              <div class="panel-heading" style="border-bottom: solid 1px #e2eff7 !important" *ngIf="view == 'benefits'">
                {{ trans.trans.benefits.value }} <a (click)="view = 'case'; closeBenefits()" class="pull-right clickable"><i class="fa fa-times"></i></a>
              </div>

              <app-notes
                [shareView]="view"
                [permissions]="permissions"
                (backCallback)="view = 'cashflow'"
                [showTranslate]="showTranslate"
                [showHelp]="showHelp"
                *ngIf="view == 'notes' && valueProp && valueProp['id'] && valueProp['id'] != null"
                [contextualHelp]="contextualHelp"
                [valueProp]="valueProp"
              ></app-notes>
            </div>
            <div *ngIf="!sI.valuePropLoader && view == 'case'" style="overflow-x: auto; width: 100%; display: block">
              <app-cashflow-simple class="tour_cashflow" [showHelp]="showHelp" *ngIf="view == 'case'" [valueProp]="valueProp"></app-cashflow-simple>
            </div>

            <div *ngIf="!sI.valuePropLoader && view == 'benefits' && benefits && benefits.metrics" style="overflow-x: auto; width: 100%; display: block">
              <app-benefits-mini-v2
                shareView="simple"
                [str]="selectedName + ' - ' + selectedVal"
                [desc]="selectedDesc"
                [categoryId]="selectedId"
                (reloadVP)="reload()"
                [permissions]="permissions"
                [locked]="valueProp.closed == '1' ? true : false"
                [valueProp]="valueProp"
                [benefits]="benefits.metrics"
                [repV2]="true"
              ></app-benefits-mini-v2>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="view == 'roi' && chartROI" class="panel panel-info">
        <div class="panel-body">
          <table class="panel-table">
            <tr>
              <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
              <td colspan="3">
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.return_on_investment.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.return_on_investment"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="3">
                <span class="info-text"
                  >{{ trans.trans.ratio_of_cumulative_net_benefit_investment_over_the_product_period.value }}
                  <span
                    >{{ trans.trans.your_roi_status_is.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.ratio_of_cumulative_net_benefit_investment_over_the_product_period"
                      (callback)="refreshTranslation()"
                    ></app-editTranslation>
                    &nbsp;<span [ngSwitch]="roiStatus.colorId">
                      <ng-container *ngSwitchCase="4">
                        <span style="color: green">{{ trans.trans.green.value }}</span> - {{ trans.trans.between.value }} {{ roiStatus.min }} {{ trans.trans.and.value }}
                        {{ roiStatus.max }}
                      </ng-container>
                      <ng-container *ngSwitchCase="3">
                        <span style="color: orange">{{ trans.trans.orange.value }}</span> - {{ trans.trans.not_between.value }} {{ roiStatus.min }} {{ trans.trans.and.value }}
                        {{ roiStatus.max }} {{ trans.trans.but_within_the_tolerance.value }} {{ roiStatus.margin }}%
                      </ng-container>
                      <ng-container *ngSwitchCase="2">
                        <span style="color: red">{{ trans.trans.red.value }}</span> - {{ trans.trans.not_between.value }} {{ roiStatus.min }} {{ trans.trans.and.value }}
                        {{ roiStatus.max }} {{ trans.trans.or_within_the_tolerance.value }} {{ roiStatus.margin }}%
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <span style="color: grey">{{ trans.trans.grey.value }}</span> - {{ trans.trans.grey_explanation.value }}
                      </ng-container>
                    </span>
                  </span></span
                >
              </td>
            </tr>
            <tr>
              <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.the_roi_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.the_roi_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text">
                  ({{ trans.trans.total_benefits.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_benefits"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  - {{ trans.trans.total_costs.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_costs"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  ) / {{ trans.trans.total_costs.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_costs"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
              <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.your_roi_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.your_roi_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ roiText }}</span>
              </td>
            </tr>
          </table>
          <app-bar-line-chart *ngIf="valueProp" [axis2]="true" [data]="chartROI"></app-bar-line-chart>
        </div>
      </div>

      <div *ngIf="view == 'tco' && chartTCO" class="panel panel-info">
        <div class="panel-body">
          <table class="panel-table">
            <tr>
              <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
              <td colspan="3">
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.total_cost_of_ownership.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_cost_of_ownership"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
                <div style="float: right; display: block" *ngIf="feature76">
                  <input type="checkbox" [(ngModel)]="showAllMetrics" (click)="funcShowAllMetrics()" /> Show More than Costs Impacts
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="3">
                <span class="info-text"
                  >{{ trans.trans.total_cost_of_ownership_estimate.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_cost_of_ownership_estimate"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
            </tr>
            <tr>
              <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.the_tco_formula.value }}

                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.the_tco_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text">
                  {{ trans.trans.total_current_costs.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_current_costs"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  – {{ trans.trans.total_future_costs.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_future_costs"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
              <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.your_tco_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.the_tco_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ tcoText }}</span>
              </td>
            </tr>
          </table>
          <div *ngIf="toggleChart">
            <p-progressBar *ngIf="toggleChart" mode="indeterminate"></p-progressBar>
          </div>
          <div *ngIf="!toggleChart">
            <app-bar *ngIf="valueProp" [data]="chartTCO"></app-bar>
            <div>
              <app-tco #tco [showTranslate]="false" [embedded]="true" [showAllMetrics]="showAllMetrics" [valueProp]="valueProp" (showMoreCosts)="allTcoMetrics(true)"></app-tco>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="view == 'payback'" class="panel panel-info">
        <div class="panel-body">
          <table class="panel-table">
            <tr>
              <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
              <td colspan="3">
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.payback_period.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.payback_period"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="3">
                <span class="info-text"
                  >{{ trans.trans.the_length_of_time_required_for_an_investment_to_recover_its_initial_outlay_in_terms_of_profits.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.the_length_of_time_required_for_an_investment_to_recover_its_initial_outlay_in_terms_of_profits"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  <span
                    >{{ trans.trans.your_payback_status_is.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.your_payback_status_is"
                      (callback)="refreshTranslation()"
                    ></app-editTranslation>
                    <span [ngSwitch]="pbpStatus.colorId">
                      <ng-container *ngSwitchCase="4">
                        <span style="color: green">{{ trans.trans.green.value }}</span> - {{ trans.trans.between.value }} {{ pbpStatus.min }} {{ trans.trans.and.value }}
                        {{ pbpStatus.max }}
                      </ng-container>
                      <ng-container *ngSwitchCase="3">
                        <span style="color: orange">{{ trans.trans.orange.value }}</span> - {{ trans.trans.not_between.value }} {{ pbpStatus.min }} {{ trans.trans.and.value }}
                        {{ pbpStatus.max }} {{ trans.trans.but_within_the_tolerance.value }} {{ pbpStatus.margin }}%
                      </ng-container>
                      <ng-container *ngSwitchCase="2">
                        <span style="color: red">{{ trans.trans.red.value }}</span> - {{ trans.trans.not_between.value }} {{ pbpStatus.min }} {{ trans.trans.and.value }}
                        {{ pbpStatus.max }} {{ trans.trans.or_within_the_tolerance.value }} {{ pbpStatus.margin }}%
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <span style="color: grey">{{ trans.trans.grey.value }}</span> - {{ trans.trans.grey_explanation.value }}
                      </ng-container>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
            <tr>
              <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.the_payback_period_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.the_payback_period_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ paybackFormulaText }}</span>
              </td>
              <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.your_payback_period_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.your_payback_period_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ paybackText }}</span>
              </td>
            </tr>
          </table>
          <app-line *ngIf="valueProp" [data]="chartPayback"></app-line>
        </div>
      </div>

      <div *ngIf="view == 'npv'" class="panel panel-info">
        <div class="panel-body">
          <table class="panel-table">
            <tr>
              <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
              <td colspan="3">
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.net_present_value.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.net_present_value"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="3">
                <span class="info-text"
                  >{{ trans.trans.is_the_difference_between_the_present_value_of_cash.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.is_the_difference_between_the_present_value_of_cash"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  <span>
                    {{ trans.trans.your_npv_status_is.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.your_npv_status_is"
                      (callback)="refreshTranslation()"
                    ></app-editTranslation>
                    <span [ngSwitch]="npvStatus.colorId">
                      <ng-container *ngSwitchCase="4">
                        <span style="color: green">{{ trans.trans.green.value }}</span> - {{ trans.trans.between.value }} {{ npvStatus.min }} {{ trans.trans.and.value }}
                        {{ npvStatus.max }}
                      </ng-container>
                      <ng-container *ngSwitchCase="3">
                        <span style="color: orange">{{ trans.trans.orange.value }}</span> - {{ trans.trans.not_between.value }} {{ npvStatus.min }} {{ trans.trans.and.value }}
                        {{ npvStatus.max }} {{ trans.trans.but_within_the_tolerance.value }} {{ npvStatus.margin }}%
                      </ng-container>
                      <ng-container *ngSwitchCase="2">
                        <span style="color: red">{{ trans.trans.red.value }}</span> - {{ trans.trans.not_between.value }} {{ npvStatus.min }} {{ trans.trans.and.value }}
                        {{ npvStatus.max }} {{ trans.trans.or_within_the_tolerance.value }} {{ npvStatus.margin }}%
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <span style="color: grey">{{ trans.trans.grey.value }}</span> - {{ trans.trans.grey_explanation.value }}
                      </ng-container>
                    </span>
                  </span>
                </span>
              </td>
            </tr>
            <tr>
              <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.the_npv_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.the_npv_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ npvFormulaText }}</span>
              </td>
              <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.your_npv_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.your_npv_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ npvText }}</span>
              </td>
            </tr>
          </table>
          <app-bar-line-chart *ngIf="valueProp" [axis2]="false" [data]="chartNPV"></app-bar-line-chart>
        </div>
      </div>

      <div *ngIf="view == '3mo'" class="panel panel-info">
        <div class="panel-body">
          <table class="panel-table">
            <tr>
              <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
              <td colspan="3">
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.three_month_cost_of_delay.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.three_month_cost_of_delay"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="3">
                <span class="info-text"
                  >{{ trans.trans.what_is_the_cost_of_the_customer_delaying_the_decision_to_buy.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.what_is_the_cost_of_the_customer_delaying_the_decision_to_buy"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                </span>
              </td>
            </tr>
            <tr>
              <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.the_three_months_cost_of_delay_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.the_three_months_cost_of_delay_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ threemoText }}</span>
              </td>
              <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
              <td>
                <span class="info-title" [ngStyle]="{ color: headerColor }"
                  >{{ trans.trans.your_three_months_cost_of_delay_formula.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.your_three_months_cost_of_delay_formula"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                ><br />
                <span class="info-text"> {{ threemoFormula }}</span>
              </td>
            </tr>
          </table>
          <app-horizontal-bar *ngIf="valueProp" [data]="chart3MO"></app-horizontal-bar>
        </div>
      </div>

      <div *ngIf="view == 'benpertrans'" class="panel panel-info">
        <div class="panel-body">
          <table class="panel-table">
            <tr>
              <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
              <td colspan="3">
                <span class="info-title" [ngStyle]="{ color: headerColor }">{{ trans.trans.benefits_per_transaction.value }} </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td colspan="3">
                <span class="info-text">{{ trans.trans.bpt_note.value }} </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<app-value-prop-flow-buttons [moveToBottom]="false" [next]="false" (goBack)="back.emit()"></app-value-prop-flow-buttons>

<p-sidebar *ngIf="changeName && (style2022$ | async)" (onHide)="cancelValuePropName()" [(visible)]="changeName" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-valueprop-name-changer [name]="valueProp.name" (cancel)="cancelValuePropName()" (nameSubmit)="changeValuePropName($event)"></app-valueprop-name-changer>
</p-sidebar>
