<div class='row'>
	<div class='col-md-4 col-sm-12'>
		<div class='panel panel-info'>
			<div class='panel-body'>
				<div class='row'>
					<div class='col-xs-12'>
						<p class='panel-title'>{{trans.menu.value_tracker.cases_closing_soon | translate}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='col-md-4 col-sm-12'>
		<div class='panel panel-info'>
			<div class='panel-body'>
				<div class='row'>
					<div class='col-xs-12'>
						<p class='panel-title'>{{trans.menu.value_tracker.active_tracker | translate}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='col-md-4 col-sm-12'>
		<div class='panel panel-info'>
			<div class='panel-body'>
				<div class='row'>
					<div class='col-xs-12'>
						<p class='panel-title'>{{trans.menu.value_tracker.surveys_received | translate}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class='row'>
	<div class='col-md-8 col-sm-12'>
		<div class='panel panel-info'>
			<div class='panel-body'>
				<div class='row'>
					<div class='col-xs-9'>
						<p class='panel-title'>{{trans.menu.value_tracker.recent_survey_response | translate}}</p>
					</div>
					<div class='col-xs-3'>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class='col-md-4 col-sm-12'>
		<div class='panel panel-info'>
			<div class='panel-body'>
				<div class='row'>
					<div class='col-xs-9'>
						<p class='panel-title'>{{trans.menu.value_tracker.recommended_case_study | translate}}</p>
					</div>
					<div class='col-xs-3'>
						<a class='btn btn-rounded pull-right'><i class='fa fa-plus'></i> {{trans.menu.value_tracker.add | translate}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class='row'>
	<div class='col-xs-12'>
		<div class='panel panel-info'>
			<div class='panel-body'>
				<div class='row'>
					<div class='col-xs-8'>
						<p class='panel-title'>{{trans.menu.value_tracker.upcoming_surveys | translate}}</p>
					</div>
					<div class='col-xs-4'>
						<a class='btn btn-rounded pull-right'><i class='fa fa-plus'></i>{{trans.menu.value_tracker.create_survey | translate}}</a>
					</div>
				</div>

				<table class="table custom-table survey-simple ">
					<thead>
						<tr>
							<th>{{trans.menu.value_tracker.name | translate}}</th>
							<th>{{trans.menu.value_tracker.company | translate}}</th>
							<th>{{trans.menu.value_tracker.last_survey | translate}}</th>
							<th>{{trans.menu.value_tracker.upcoming_survey | translate}}</th>
						</tr>
					</thead>
					<tbody>
						<tr class="grid-row">

						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<!-- <div class="valueRealizationDashboard">
	<div class="row soln-search-row">
		<div class="col-md-12">
			<div class="panel panel-white blue-head">
				<div class="panel-heading clearfix" style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
					<div class='row'>
						<div class='col-xs-6'>
							<h2>{{trans.value_realization.yourValueRealization | translate}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.yourValueRealization'></app-editTranslation>
							</h2>
						</div>
						<div class='col-xs-6'>
							<button class="btn btn-primary pull-right" routerLink="/valueprop" *ngIf="!isGuest"><i class="fa fa-plus"></i>{{trans.value_realization.addNewValueRealization | translate}}</button>
							<i class="fa fa-exchange pull-right" ngbTooltip="Edit Translations" container="body" placement="bottom" (click)='showTranslate = !showTranslate' style='margin-right: 10px; margin-top: 8px; font-size: 18px;' *ngIf="isAdmin && !showTranslate" aria-hidden="true"></i>
							<i class="fa fa-exchange pull-right" ngbTooltip="Edit Translations" container="body" placement="bottom" (click)='showTranslate = !showTranslate' style='margin-right: 10px; margin-top: 8px; font-size: 18px; color: gold;' *ngIf="isAdmin && showTranslate" aria-hidden="true"></i>

						</div>

					</div>
				</div>
				<div class="panel-body">
					<div class='row' style='margin-top: 20px; margin-bottom: 20px;'>
						<div class='col-xs-12'>
							<small class="panel-title-msg">{{trans.value_realization.youCanMeasureValueForAnyLockedValueProposition | translate}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.youCanMeasureValueForAnyLockedValueProposition'></app-editTranslation></small>
						</div>
					</div>
					<div class="soln_table_cont">
						<div class="table-responsive soln-table" style='padding-bottom: 0px;'>
							<div class="searchTableContainer">
								<table id="valueRealization" class="table custom-table custom-responsive-table">
									<thead>
										<tr>
											<th>{{trans.value_realization.name | translate}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.name'></app-editTranslation>
											</th>
											<th>{{trans.value_realization.company | translate}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.company'></app-editTranslation>
											</th>
											<th class="text-right">{{trans.value_realization.expected_to_date | translate}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.expected_to_date'></app-editTranslation>
											</th>
											<th class="text-right">{{trans.value_realization.realized_to_date | translate}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.realized_to_date'></app-editTranslation>
											</th>
											<th>{{trans.value_realization.action | translate }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.action'></app-editTranslation>
											</th>
										</tr>
									</thead>

									<tbody *ngIf="loadValueRealizationTable">

										<tr *ngFor="let value of valueRealization" class="grid-row">
											<td data-title="Name">
												<a routerLink="/trackers/{{value.id}}" class='link'>{{ value.name }}</a>
											</td>
											<td data-title="Company"> {{ value.companyname }}</td>
											<td data-title="'Expected To' Date" class="text-right">
												{{ value.total_benefits_fmt }}
											</td>
											<td data-title="'Realized To' Date" class="text-right">
												{{ value.improvementfmt }}
											</td>
											<td class="text-center"><span class="icon-trash" (click)="open(deleteVRModal,value.id)" *ngIf="!isGuest"></span></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div *ngIf="valueRealization.length==0 && loadValueRealizationTable" class="m-t-md col-sm-12">
							<div class="searchTableContainer text-center">
								<h4>{{ trans.value_realization.name | translate }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.name'></app-editTranslation>
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #deleteVRModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{trans.value_realization.delete_valueprop | translate}}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.name'></app-editTranslation>
		</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{trans.value_realization.are_you_sure_that_you_want_to_remove_this_locked_valueprop | translate}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="deleteValueRealization()">{{trans.value_realization.yes | translate}}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{trans.value_realization.no | translate}}</button>
		<img class="actionLoader" [hidden]="hideActionLoader" src="{{fullImagePath}}" />
	</div>
</ng-template> -->