<div class="row" style="padding: 4px 0 18px 0">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>
<div class="models_panel" style="margin-bottom: 24px" *ngIf="feature125 || feature126">
  <form [formGroup]="opportunityForm">
    <app-opportunity-form [showTranslate]="showTranslate" [isVMWare]="isVMWare" [feature68]="feature68" [trans]="this.trans" (getTranslations)="getTranslations()">
    </app-opportunity-form>
  </form>
</div>

<div *ngIf="!companyId" class="row main-container">
  <!-- Search Header -->
  <div class="search-header">
    <!-- Title -->
    <div class="row">
      <div class="col-xs-12 search-title">
        {{ trans.trans.letsCreateProp.value }}
      </div>
    </div>
    <!-- Sub Title -->
    <div class="row">
      <div class="col-xs-12 search-sub-title">
        {{ trans.trans.searchForCompany.value }}
      </div>
    </div>
    <!-- Search Box -->
    <div class="search-box-wrapper input-group keywest_search">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <p-autoComplete
        id="companyac"
        styleClass="search"
        [(ngModel)]="companyname"
        [suggestions]="companiesList"
        (completeMethod)="onSearchComplete()"
        (onSelect)="selectCompany($event.id)"
        field="name"
        [showEmptyMessage]="true"
        [emptyMessage]="trans.trans.noResults.value"
        [minLength]="1"
        [dropdown]="false"
        data-heap="value-execution-add-a-new-value-proposition-search-for-a-company-search-bar"
        class="value-execution-add-a-new-value-proposition-search-for-a-company-search-bar"
      >
        <ng-template let-company pTemplate="item" *ngIf="companiesList">
          <div class="search-dropdown-wrapper" style="width: 455px">
            <div class="search-list-item">
              <span class="search-company-logo">
                <img
                  *ngIf="company.logo_path"
                  onerror="this.onerror=null; this.remove();"
                  (error)="onImgError(company)"
                  [src]="company.name | functionPipe: getLogo:company.logo_path"
                />
                <i *ngIf="!company.logo_path" class="fa fa-building light-h company-default-icon"></i>
              </span>
              <span class="search-company-name">{{ company.name }}</span>
            </div>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>
  <div class="col-xs-12 col-md-4"></div>
  <!-- Table Selector -->
  <div class="col-xs-12 col-md-4 selection-box">
    <p-selectButton [options]="viewOptions" [(ngModel)]="currentView" styleClass="keywest_select_button"></p-selectButton>
  </div>
  <!-- Add Company Button -->
  <div class="col-xs-12 col-md-4 button-box">
    <button class="btn btn_customizable pull-right marg-h company-button value-execution-add-a-new-value-proposition-add-a-new-company-button" (click)="addCompany = true" tooltipPosition="bottom" data-heap="value-execution-add-a-new-value-proposition-add-a-new-company-button">
      <i class="fa fa-plus"></i>{{ trans.trans.addCompany.value }}
    </button>
  </div>
  <!-- Selected Table -->
  <div class="col-md-12 company-table-wrapper">
    <!-- Recent Companies -->
    <div *ngIf="currentView === 'recents'">
      <app-company-table
        [cols]="cols"
        (addToFavorites)="onAddToFavorites($event)"
        (removeFromFavorites)="onRemoveFavorite($event)"
        [trans]="trans"
        [favoritedCompanies]="favoriteCompanies"
        [isRecentTable]="true"
        [companiesList]="recentCompanies"
        [noResultsMessage]="trans.trans.noRecents.value"
        [featureCustomAccountTable]="featureCustomAccountTable"
        (selectCompany)="onTableSelection($event)"
      ></app-company-table>
    </div>
    <!-- Favorite Companies -->
    <div *ngIf="currentView === 'favorites'">
      <app-company-table
        [cols]="cols"
        [trans]="trans"
        (removeFromFavorites)="onRemoveFavorite($event)"
        [favoritedCompanies]="favoriteCompanies"
        [isRecentTable]="false"
        [companiesList]="favoriteCompanies"
        [noResultsMessage]="trans.trans.noFavorites.value"
        [featureCustomAccountTable]="featureCustomAccountTable"
        (selectCompany)="onTableSelection($event)"
      ></app-company-table>
    </div>
  </div>
</div>

<div class="company-container" *ngIf="companyId">
  <div class="company-row">
    <div class="logo-container col-lg-3">
      <div class="logo">
        <img
          *ngIf="companyDetails?.logo_path; else noLogo"
          onerror="this.onerror=null; this.remove();"
          (error)="onImgError(companyDetails)"
          [src]="companyDetails.name | functionPipe: getLogo:companyDetails.logo_path"
          alt="company-logo"
        />
        <ng-template #noLogo><i class="fa fa-building light-h company-default-icon company-icon"></i></ng-template>
      </div>
    </div>
    <div>
      <div class="company-name">
        <h3>{{ companyDetails?.name }}</h3>
        <i
          class="pi"
          [ngClass]="companyIsFavorite ? 'pi-heart-fill' : 'pi-heart'"
          [matTooltip]="companyIsFavorite ? trans.trans.removeFromFav.value : trans.trans.addToFav.value"
          (click)="toggleFavorite()"
        ></i>
      </div>
      <p class="company-description">{{ companyDetails?.description }}</p>
    </div>
    <div>
      <div class="company-button-ff101" *ngIf="feature101">
        <button
          pButton
          type="button"
          [loading]="loading"
          iconPos="right"
          (click)="funcContinue()"
          [disabled]="feature60 ? q1answer.length <= 0 : false || opportunityIdControl.invalid"
          class="btn btn_customizable value-execution-add-a-new-value-proposition-let's-get-started-button"
          [label]="trans.trans.letsGetStarted.value"
          data-heap="value-execution-add-a-new-value-proposition-let's-get-started-button"
        ></button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!feature101">
    <div class="company-details col-lg-4">
      <div class="company-text">
        <p>{{ trans.trans.company.value }}</p>
        <p class="text">{{ companyDetails?.name | functionPipe: defaultValue }}</p>
      </div>
      <div class="company-text">
        <p>{{ trans.trans.vcAccountId.value }}</p>
        <p class="text">{{ companyDetails?.id | functionPipe: defaultValue }}</p>
      </div>
      <div class="company-text">
        <p>{{ trans.trans.crmAccountId.value }}</p>
        <p class="text">{{ companyDetails?.account_defined_id | functionPipe: defaultValue }}</p>
      </div>
      <div class="company-text">
        <p>{{ trans.trans.city.value }}</p>
        <p class="text">{{ companyDetails?.city | functionPipe: defaultValue }}</p>
      </div>
      <div class="company-text">
        <p>{{ trans.trans.state.value }}</p>
        <p class="text">{{ companyDetails?.state | functionPipe: defaultValue }}</p>
      </div>
      <div class="company-text">
        <p>{{ trans.trans.country.value }}</p>
        <p class="text">{{ companyDetails?.country | functionPipe: defaultValue }}</p>
      </div>
    </div>
    <div class="company-stats-container">
      <div class="company-stats">
        <div class="company-stats-item">
          <p>{{ trans.trans.revenueM.value }}</p>
          <h2>{{ companyDetails?.revenue_fmt | functionPipe: defaultValue }}</h2>
        </div>
        <div class="company-stats-item">
          <p>{{ trans.trans.employees.value }}</p>
          <h2>{{ companyDetails?.employees_fmt | functionPipe: defaultValue }}</h2>
        </div>
        <div class="company-stats-item">
          <p>{{ trans.trans.sector.value }}</p>
          <h2>{{ companyDetails?.dl_sector_name | functionPipe: defaultValue }}</h2>
        </div>
      </div>
      <div class="company-button">
        <button
          pButton
          type="button"
          [loading]="loading"
          iconPos="right"
          (click)="funcContinue()"
          [disabled]="feature60 ? q1answer.length <= 0 : false || opportunityIdControl.invalid"
          class="btn btn_customizable value-execution-add-a-new-value-proposition-let's-get-started-button"
          [label]="trans.trans.letsGetStarted.value"
          data-heap="value-execution-add-a-new-value-proposition-let's-get-started-button"
        ></button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="companyId && feature60">
  <div class="models_panel" style="margin-bottom: 20px">
    <div class="models_page_header_container_small">
      <div class="keywest_header">
        <div class="row">
          <div class="col-xs-12">
            <div class="title">{{ trans.trans.whatIsTheCustomerProblem.value }}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <textarea required name="q1answer" maxlength="1000" [(ngModel)]="q1answer" class="form-control" rows="3"></textarea>
          <small style="font-size: 70%">{{ 1000 - q1answer.length }} of 1000 characters left <span style="color: red">Required</span></small
          ><br /><br />
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar position="right" styleClass="keywest_sidebar p-sidebar-md" [(visible)]="addCompany">
  <app-add-new-company *ngIf="addCompany" (cancel)="onCancel($event)"></app-add-new-company>
</p-sidebar>
