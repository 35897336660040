import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { Observable, Subject } from 'rxjs';
import { BenefitsImplementation } from '../../benefits.implementation';
import { BenefitsTableTranslations } from './benefits-table.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { take } from 'rxjs/operators';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-benefit-table',
	templateUrl: './benefits-table.component.html'

})
export class BenefitTableComponent implements OnInit, OnDestroy {

	ngUnsubscribe = new Subject();
	showTranslate = false;
	style2022$: Observable<boolean>;

	constructor(
		private translationService: TranslationsV2Service,
		public trans: BenefitsTableTranslations,
		public bI: BenefitsImplementation,
		private CommonService: CommonService,
		private styleService: StyleService
	) {

	}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();
		this.CommonService.notifyChangeLanguage.pipe(take(1)).subscribe(() => {
			this.getTranslations();
		});
		this.CommonService.notifyEditTranslation$.pipe(take(1)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(take(1)).subscribe(res => {
			this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);
		});
	}
}