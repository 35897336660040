import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import {
  ConfigurableWorkflow,
  WorkflowAssignment,
  WorkflowAssignmentResponse,
  WorkflowAssignmentUpdateRequest,
  WorkflowUserAssignment,
} from '@data/services/workflow/models/workflow.interface';
import { NotificationService } from '@services/notification.service';
import { APIResult } from '@shared/models/api-result.model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UsersProfileTranslation } from '../users.translation';

@Component({
  selector: 'app-workflow-config-table',
  templateUrl: './workflow-config-table.component.html',
  styleUrls: ['./workflow-config-table.component.scss'],
})
export class WorkflowConfigTableComponent implements OnInit, OnDestroy {
  @Input() trans: UsersProfileTranslation;
  @Input() userId: number;
  public strSearch = '';
  public allWorkflows: ConfigurableWorkflow[] = [];
  public workflowAssignments: WorkflowAssignment[] = [];
  public userWfAssignments: WorkflowUserAssignment[] = [];
  public cols: { field: string; header: string }[] = [];
  public loading = true;
  private unSubscribe$ = new Subject<void>();

  constructor(private configurableWorkflowService: ConfigurableWorkflowService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.loading = true
    this.configurableWorkflowService
      .getWorkflows()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((data: any) => {
        if (data.result) {
          this.configurableWorkflowService
            .getWorkflowAssignments(this.userId)
            .pipe(takeUntil(this.unSubscribe$))
            .subscribe((wfa: APIResult<WorkflowAssignmentResponse>) => {
              this.setCols();
              this.setWorkflowAssignmentMap();
              if (wfa.result) {
                this.allWorkflows = data.result.workflow;
                this.userWfAssignments = this.allWorkflows
                  .map((wf) => {
                    return {
                      name: wf.name,
                      active: wf.active === '1' ? this.trans.trans.workflowEnabled.value : this.trans.trans.workflowDisabled.value,
                      assignment_color: this.configurableWorkflowService.workflowColorMap.get('3'),
                      id: wf.id,
                    };
                  })
                  .sort((a, b) => {
                    // Sort by active descending
                    if (a.active > b.active) return -1;
                    if (a.active < b.active) return 1;
                    return 0;
                  });
                wfa.result.workflow_assignments.forEach((wfa) => {
                  const index = this.userWfAssignments.findIndex((wf) => wf.id === wfa.id);
                  if (index > -1) {
                    this.userWfAssignments[index].default_assignment = wfa.default_assignment
                      ? this.configurableWorkflowService.workflowAssignments.get(wfa.default_assignment)
                      : this.trans.trans.notAssigned.value;
                    this.userWfAssignments[index].assignment_color = wfa.default_assignment
                      ? this.configurableWorkflowService.workflowColorMap.get(wfa.default_assignment)
                      : this.configurableWorkflowService.workflowColorMap.get('3');
                  }
                });
              }
              this.loading = false;
            });
        }
      });
  }

  public onSearch(event: KeyboardEvent): void {
    const eventValue = (event.target as HTMLInputElement).value;
    if (eventValue.length > 0) {
      this.strSearch = eventValue;
    } else if (eventValue.length === 0) {
      this.strSearch = '';
    }
  }

  private setCols() {
    this.cols = [
      { field: 'name', header: this.trans.trans.name.value },
      { field: 'active', header: this.trans.trans.status.value },
      { field: 'default_assignment', header: this.trans.trans.assignmentType.value },
    ];
  }

  public clearSearch() {
    this.strSearch = '';
  }

  public setWorkflowAssignmentMap() {
    const { workflowAssignments } = this.configurableWorkflowService;
    const { manuallyAssigned, userGroupAssigned, manuallySuppressed } = this.trans.trans;
    workflowAssignments.set('0', manuallyAssigned.value);
    workflowAssignments.set('1', userGroupAssigned.value);
    workflowAssignments.set('2', manuallySuppressed.value);
  }

  public manuallyAssign(wf) {
    wf.default_assignment = this.trans.trans.manuallyAssigned.value;
    wf.assignment_color = this.configurableWorkflowService.workflowColorMap.get('0');
    const wfUpdate: WorkflowAssignmentUpdateRequest = {
      account_workflow_id: parseInt(wf.id),
      default_assignment: 0,
    };
    this.configurableWorkflowService
      .updateWorkflowAssigment(this.userId, wfUpdate)
      .pipe(take(1))
      .subscribe((res) => {
        this.notificationService.success(res.result, false);
      });
  }

  public unAssign(wf) {
    wf.default_assignment = this.trans.trans.manuallySuppressed.value;
    wf.assignment_color = this.configurableWorkflowService.workflowColorMap.get('2');
    const wfUpdate: WorkflowAssignmentUpdateRequest = {
      account_workflow_id: parseInt(wf.id),
      default_assignment: 2,
    };
    this.configurableWorkflowService
      .updateWorkflowAssigment(this.userId, wfUpdate)
      .pipe(take(1))
      .subscribe((res) => {
        this.notificationService.success(res.result, false);
      });
  }

  public reset(wf) {
    const wfReset: WorkflowAssignmentUpdateRequest = {
      account_workflow_id: parseInt(wf.id),
    };
    this.configurableWorkflowService
      .resetWorkflowAssignment(this.userId, wfReset)
      .pipe(take(1))
      .subscribe(() => {
        this.getData();
      });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
