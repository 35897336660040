import { Injectable } from '@angular/core';

@Injectable()
export class ValuePropGoalGroupTranslations {
  public config: any = {
    component: 'value-prop-goals',
    display_name: 'Value Prop Goals',
    description: '',
  };

  public trans: any = {
    goalAdded: 'Goal added to Value Prop successfully',
    goalUpdated: 'Goals updated successfully',
    goalDeleted: 'Goal removed from Value Prop successfully',
    requestError: 'Something went wrong, please try again',
    addGoal: 'Add Goal',
    edit: 'Edit',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    noGoals: 'No goals found',
    name: 'Name',
    targetValue: 'Target Value',
    units: 'Units',
    baselineValue: 'Baseline Value',
    saveChanges: 'Save Changes',
    noUpdate: 'Nothing to update',
    goalNameMax: 'Goal name cannot be more than 256 characters',
    goalNameSaved: 'Goal name saved',
    originalGoalName: 'Original Goal Name',
  };
}
