import { Injectable } from '@angular/core';

@Injectable()
export class SystemShareViewTranslations {
    public config: any = {
        component: 'system-share',
        display_name: 'Share main component',
        description: 'Parent component for share view'
    };

    public trans: any = {
        logout: 'Logout',
    };
}
