import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScratchpadComponentV2 } from '../scratchpad/scratchpad.component';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { BenefitsRatingModule } from '../rating/rating.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { BenefitsPhasingModule } from '../phasing/phasing.module';
import { FieldsetModule } from 'primeng/fieldset';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsScratchpadTranslations } from './scratchpad.translation';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { UnitTypeModule } from '@shared/unit-type/unit-type.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';

@NgModule({
  imports: [
    FieldsetModule,
    BenefitsPhasingModule,
    ProgressBarModule,
    MenubarModule,
    TableModule,
    SidebarModule,
    CommonModule,
    FormsModule,
    AreYouSureModule,
    NgbModule,
    BenefitsRatingModule,
    ApplicationPipesModule,
    CurrencyMaskModule,
    UnitTypeModule,
    MatTooltipModule,
  ],
  declarations: [ScratchpadComponentV2],
  providers: [TranslationsV2Service, BenefitsScratchpadTranslations, ValuerealizationService],
  exports: [ScratchpadComponentV2],
})
export class ScratchPadModule {}
