import { Component, OnInit } from '@angular/core';
import { EditDirectiveService } from '@shared_components/editTranslation/edit.service';
import { NotificationService } from '@services/notification.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './translations.translation';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin-translations',
  templateUrl: './translations.component.html',
})
export class AdminTranslationsComponent implements OnInit {
  edited = false;

  tableData: K[] = [];
  showTranslate = false;
  selectedColumns: { [klass: string]: any }[] = [];
  strSearch = '';

  allTranslationsv2Keys: unknown;
  allTranslationsv2Indexed: { [klass: string]: any };
  currentLang: {field: string; header: string};
  language_type_id: string;
  languages = [
    { field: '1', lang: 'en', header: 'English US' },
    { field: '2', lang: 'en-gb', header: 'English UK' },
    { field: '3', lang: 'es', header: 'Spanish' },
    { field: '4', lang: 'fr', header: 'French' },
    { field: '5', lang: 'zh', header: 'Chinese' },
    { field: '6', lang: 'pt', header: 'Portuguese' },
    { field: '7', lang: 'de', header: 'German' },
    { field: '8', lang: 'ja', header: 'Japanese' },
  ];

  columns = [
    { field: 'k', header: 'Key' },
    { field: '1', header: 'English US' },
    { field: '2', header: 'English UK' },
    { field: '3', header: 'Spanish' },
    { field: '4', header: 'French' },
    { field: '5', header: 'Chinese' },
    { field: '6', header: 'Portuguese' },
    { field: '7', header: 'German' },
    { field: '8', header: 'Japanese' },
  ];
  ngUnsubscribe = new Subject();
  constructor(
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private editService: EditDirectiveService,
    private notification: NotificationService
  ) {}
  ngOnInit() {
    const all_dictionaries = JSON.parse(sessionStorage.getItem('all_dictionaries'));
    const labels = JSON.parse(sessionStorage.getItem('labels'));
    const labelsKey = labels.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.k]: curr,
      };
    }, {});
    const dictionary = all_dictionaries.reduce((acc, curr) => {
      const hasOverride = labelsKey[curr.k] && labelsKey[curr.k].language_type_id === curr.language_type_id;

      if (acc[curr.k]) {
        acc[curr.k] = [
          ...acc[curr.k],
          {
            value: hasOverride ? labelsKey[curr.k].v : curr.v,
            updated: false,
            lang: curr.language_type_id,
          },
        ];
      } else {
        acc[curr.k] = [
          {
            value: hasOverride ? labelsKey[curr.k].v : curr.v,
            updated: false,
            lang: curr.language_type_id,
          },
        ];
      }
      return acc;
    }, {});
    let newTable = Object.keys(dictionary).map((dict) => {
      const languageIndexedObj = Object.values(dictionary[dict]).reduce<any>((acc: any, curr: any) => {
        return {
          ...acc,
          [curr.lang]: curr,
        };
      }, {});
      const newRow = {
        k: dict.toString(),
        ...languageIndexedObj,
      };
      const completeRow = this.addMissingRows(newRow);
      return this.validateTranslationRow(completeRow);
    });
    newTable = newTable.filter((tab) => Object.keys(tab).length > 7);
    this.tableData = newTable;
    this.getTranslations();

    const langId = sessionStorage.getItem('language_type_id');
    this.currentLang = this.columns.find((lang) => lang.field === langId);
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.currentLang = this.columns.find((lang) => lang.field === response);
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  funcSave() {
    let out = [];
    for (let i = 0; i < this.tableData.length; i++) {
      const elem: K = this.tableData[i];
      if (elem['1'].updated) {
        out.push({ key: elem.k, name: elem['1'].value, language_type_id: elem['1'].lang });
      }
      if (elem['2'].updated) {
        out.push({ key: elem.k, name: elem['2'].value, language_type_id: elem['2'].lang });
      }
      if (elem['3'].updated) {
        out.push({ key: elem.k, name: elem['3'].value, language_type_id: elem['3'].lang });
      }
      if (elem['4'].updated) {
        out.push({ key: elem.k, name: elem['4'].value, language_type_id: elem['4'].lang });
      }
      if (elem['5'].updated) {
        out.push({ key: elem.k, name: elem['5'].value, language_type_id: elem['5'].lang });
      }
      if (elem['6'].updated) {
        out.push({ key: elem.k, name: elem['6'].value, language_type_id: elem['6'].lang });
      }
      if (elem['7'].updated) {
        out.push({ key: elem.k, name: elem['7'].value, language_type_id: elem['7'].lang });
      }
      if (elem['8'].updated) {
        out.push({ key: elem.k, name: elem['8'].value, language_type_id: elem['8'].lang });
      }
    }
    const payload = { labels: out };
    this.editService.postUpdateKey(payload).subscribe((res) => {
      const labels = res.result.labels;
      out = [];
      for (let i = 0; i < labels.length; i++) {
        out.push({ k: labels[i].key, v: labels[i].name, language_type_id: labels[i].language_type_id });
      }
      this.edited = false;
      sessionStorage.setItem('labels', JSON.stringify(out));
      this.notification.success('Translations Updated Successfully', false);
    });
  }

  validateTranslationRow(row) {
    if (row.length === 9) {
      return row;
    } else if (!row['2']) {
      row['2'] = { value: undefined, updated: false, lang: 2 };
    } else if (row.length > 9) {
      row = this.removeDuplicateRows(row);
    }
    return row;
  }

  removeDuplicateRows(row) {
    const allLanguages = Object.values(row).reduce<any>((acc, curr: any) => {
      if (!curr.lang) {
        return acc;
      }
      acc[curr.lang] = curr;
      return acc;
    }, {});

    return {
      k: row.k,
      ...allLanguages,
    };
  }

  addMissingRows(row) {
    const allLanguage = [1, 2, 3, 4, 5, 6, 7, 8];

    allLanguage.forEach((lang) => {
      if (!row[lang]) {
        row[lang] = { value: undefined, updated: false, lang: lang };
      }
    });

    return row;
  }

  getAllTranslationsV2() {
    const allTranslationKeys = {};
    this.translationService
      .getAllTranslations({})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        const transByComponent = res.reduce((acc, curr, index) => {
          acc[curr.component].index = acc[curr.component].index < index ? acc[curr.component].index : index;
          acc[curr.component].values[curr.key][curr.lang] = curr;

          allTranslationKeys[curr.key][curr.lang] = curr;
          return acc;
        }, {});

        this.allTranslationsv2Indexed = transByComponent;

        this.allTranslationsv2Keys = Object.values(transByComponent).reduce((acc: any[], comp: any) => {
          const compKeys: any[] = Object.keys(comp.values).map((vals) => ({ [vals]: comp.values[vals] }));
          acc.concat(compKeys);
          return acc;
        }, []);
      });
  }

  onLangChange(event) {
    if (event.value.header !== 'Key') {
      this.setLanguage(event.value.field);
    }
  }

  setLanguage(event) {
    sessionStorage.setItem('language_type_id', event);
    this.commonService.notifyChangeLanguage.next();
    const user_id = sessionStorage.getItem('uid');
    const payload = {
      language_type_id: event,
    };
    this.language_type_id = event;
    this.editService.updateLanguage(user_id, payload).subscribe(() => {
      this.commonService.notifyChangeLanguage.next();
    });
  }

  updateTranslationV2(trans) {
    this.translationService.updateTranslation(trans).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }
}

export class K {
  k: string;
  1: { value: any; updated: any; lang: any };
  2: { value: any; updated: any; lang: any };
  3: { value: any; updated: any; lang: any };
  4: { value: any; updated: any; lang: any };
  5: { value: any; updated: any; lang: any };
  6: { value: any; updated: any; lang: any };
  7: { value: any; updated: any; lang: any };
  8: { value: any; updated: any; lang: any };
}
