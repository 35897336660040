<div class="models_panel account-wrapper">
  <div class="models_page_header_container_small no-margin">
    <div id="header" class="account_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title account-title">
            {{ trans.trans.account.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_account.value"> info </span>
          </div>
        </div>
        <div class="col-xs-2" *ngIf="company && company.can_edit">
          <a (click)="showEdit = !showEdit" class="btn btn_customizable pull-right">{{ trans.trans.edit.value }}</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div *ngIf="company" class="col-md-12">
        <div class="col-md-6 col-xs-12">
          <div class="row account-row">
            <!-- Name -->
            <div class="text-label">
              {{ trans.trans.name.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="refreshTranslation()"
                [isTranslationV2]="true"
                [transObj]="trans.trans.name"
                [component]="trans.config.component"
                key="vp_dashboard.account.name"
              ></app-editTranslation>
            </div>
            <div class="text-body">{{ company.name }}</div>
          </div>
          <!-- VC Account ID -->
          <div class="row account-row">
            <div class="text-label">
              {{ trans.trans.vcAccountId.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="refreshTranslation()"
                [isTranslationV2]="true"
                [transObj]="trans.trans.vcAccountId"
                [component]="trans.config.component"
                key="vp_dashboard.account.name"
              ></app-editTranslation>
            </div>
            <div class="text-body">{{ company.id }}</div>
          </div>
          <!-- CRM Account ID -->
          <div class="row account-row">
            <div class="text-label">
              {{ trans.trans.crmAccountID.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="refreshTranslation()"
                [isTranslationV2]="true"
                [transObj]="trans.trans.crmAccountID"
                [component]="trans.config.component"
                key="vp_dashboard.account.industry"
              ></app-editTranslation>
            </div>
            <div class="text-body">{{ company.account_defined_id }}</div>
          </div>
          <!-- CRM Account ID -->
          <div class="row account-row">
            <div class="text-label">
              {{ trans.trans.revenueM.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="refreshTranslation()"
                [isTranslationV2]="true"
                [transObj]="trans.trans.revenueM"
                [component]="trans.config.component"
                key="vp_dashboard.account.industry"
              ></app-editTranslation>
            </div>
            <div class="text-body">{{ company.revenue_fmt }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="showEdit" *ngIf="company" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.editing.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-xs-12">
            <p-fieldset [legend]="trans.trans.required.value">
              <div class="row">
                <div class="col-xs-12">
                  <label>
                    {{ trans.trans.company_name.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      (callback)="refreshTranslation()"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.company_name"
                      [component]="trans.config.component"
                      key="vp_dashboard.account.company_name"
                    ></app-editTranslation>
                  </label>
                  <input type="text" [(ngModel)]="company.name" class="form-control" required />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.country.value }}</label>
                  <p-dropdown
                    appendTo="body"
                    name="country"
                    [autoDisplayFirst]="false"
                    dataKey="id"
                    [options]="countries"
                    optionLabel="name"
                    (onChange)="getStatesByCountries()"
                    [(ngModel)]="countryDetails"
                    [filter]="true"
                    class="keywest_dropdown"
                  ></p-dropdown>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.revenue.value }}</label>
                  <input type="text" [(ngModel)]="company.revenue_fmt" class="form-control" />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.employees.value }}</label>
                  <input type="text" [(ngModel)]="company.employees" class="form-control" />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.industry_sector.value }}</label>
                  <p-dropdown
                    appendTo="body"
                    name="industryCategory"
                    [autoDisplayFirst]="false"
                    (onChange)="getIndustryBySectors()"
                    [options]="industrySectors"
                    dataKey="id"
                    optionLabel="name"
                    [(ngModel)]="industrySector"
                    [filter]="true"
                    class="keywest_dropdown"
                  ></p-dropdown>
                </div>
              </div>

              <div class="row" *ngIf="industrySector.id">
                <div class="col-xs-12">
                  <label>{{ trans.trans.industry.value }}</label>
                  <p-dropdown
                    appendTo="body"
                    name="industry"
                    [autoDisplayFirst]="false"
                    [options]="industries"
                    optionLabel="name"
                    [(ngModel)]="industryId"
                    [filter]="true"
                    dataKey="id"
                    class="keywest_dropdown"
                  ></p-dropdown>
                </div>
              </div>
            </p-fieldset>
            <p-fieldset [legend]="trans.trans.optional.value" [toggleable]="true" [collapsed]="true">
              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.id.value }}</label>
                  <input type="text" [(ngModel)]="company.account_defined_id" class="form-control" />
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="refreshTranslation()"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.id"
                    [component]="trans.config.component"
                    key="vp_dashboard.account.id"
                  ></app-editTranslation>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.addr1.value }}</label>
                  <input type="text" [(ngModel)]="company.address1" class="form-control" />
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="refreshTranslation()"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.addr1"
                    [component]="trans.config.component"
                    key="vp_dashboard.account.addr1"
                  ></app-editTranslation>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.addr2.value }}</label>
                  <input type="text" [(ngModel)]="company.address2" class="form-control" />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.city.value }}</label>
                  <input type="text" class="form-control" id="city" [(ngModel)]="company.city" name="city" placeholder="" />
                </div>
              </div>

              <div class="row" *ngIf="countryDetails.id">
                <div class="col-xs-12">
                  <label>{{ trans.trans.state.value }}</label>
                  <p-dropdown
                    appendTo="body"
                    name="state"
                    [autoDisplayFirst]="false"
                    [options]="states"
                    optionLabel="name"
                    [(ngModel)]="stateId"
                    [filter]="true"
                    class="keywest_dropdown"
                  ></p-dropdown>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.phone.value }}</label>
                  <input type="text" [(ngModel)]="company.phone" class="form-control" />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.website.value }}</label>
                  <input type="text" [(ngModel)]="company.url" class="form-control" />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.ticker.value }}</label>
                  <input type="text" [(ngModel)]="company.ticker" class="form-control" />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label>{{ trans.trans.gross.value }}</label>
                  <input type="text" [(ngModel)]="company.gross_profit_fmt" class="form-control" />
                </div>
              </div>
            </p-fieldset>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <div class="row">
        <div class="col-md-12">
          <button [disabled]="loadingUpdate" (click)="funcUpdateCompany()" class="btn btn_customizable pull-right">{{ trans.trans.save.value }}</button>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
