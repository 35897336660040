import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';

import { startWith, takeUntil, pairwise, pluck, take, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ModelFactorsService } from '@data/services/solution/model-factors.service';
import { NotificationService } from '@services/notification.service';
import { ModelGoalsTranslation } from './model-goals.translation';

import { Table } from 'primeng/table';
import { ConnectedObjectWithoutResult, Goal, GoalPayload, SimpleGoalReactiveForm } from '@shared/models/goal-group.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValueCategory } from '@data/services/valueposition/models/value-category.interface';
import { ActivatedRoute } from '@angular/router';
import { Benefit } from '@shared/models/value-prop.model';
import { UserService } from '@data/services/user/user.service';
import { UserInfo } from '@shared/models/user-info.model';
import { OperatorTypeList, ScratchpadCache, ScratchpadDefault, ScratchpadFields } from 'app/value-map-maintenance/views/factors/interfaces/factors.interface';
import { Items } from 'app/value-map-maintenance/views/factors/interfaces/items.interface';
import { AccountService } from '@data/services/account/account.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { ConfirmationService } from 'primeng/api';
export type TranslationStrings = keyof typeof ModelGoalsTranslation.prototype.trans;

@Component({
  selector: 'app-model-goals',
  templateUrl: './model-goals.component.html',
  styleUrls: ['./model-goals.component.scss'],
})
export class ModelGoalsComponent implements OnInit, OnDestroy {
  @Input() canEdit: boolean;
  @Input() modelId: string;
  @ViewChild('tableGoalsList', { static: false }) goalList: Table;

  loading = false;
  loadingSave = false;
  strSearch: string;
  toggleSidebar: boolean;
  aeElement: 'scratchpad' | 'addGoal' = 'addGoal';
  ngUnsubscribe = new Subject();
  showTranslate: boolean;

  goalsList: Goal[];
  goalsListIds: string[];
  selectedGoal: Goal;

  goalForm: FormGroup;

  valueCategories: ValueCategory[] = [];

  benefits: Benefit[] = [];

  solutionId: number;
  userData: UserInfo;

  numberLocales = ['en-US', 'en-US', 'de-DE', 'fr-FR'];

  openParen_default: string;
  closeParen_default: string;
  driverScratchpadFactorValue_default = '0';
  driverScratchpadFields: ScratchpadFields[] = [
    {
      id: 1,
      factor: [],
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: 0,
    },
  ];

  scaledBy: Items = [];
  accountId: string;
  driverScratchpadFactor_default: ScratchpadDefault = {};

  operatorTypesList: OperatorTypeList = [];

  linkedFactorsId: string[] = [];
  indexToUpdate: number = null;

  scratchpadCache: Map<string, ScratchpadCache> = new Map();

  scratchpadLoading: Map<string, boolean> = new Map();

  availableFactors: { label: string; value: string; linked: boolean }[];
  selectedFactors: { [klass: string]: string[] };

  get goalArray() {
    return this.goalForm.get('goals') as FormArray;
  }

  constructor(
    public trans: ModelGoalsTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private modelService: ModelFactorsService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private solutionService: SolutionService,
    private route: ActivatedRoute,
    private userService: UserService,
    private accountService: AccountService,
    private goalGroupsService: GoalGroupsService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.accountId = sessionStorage.getItem('aid');
    this.getGroups();
    this.getTranslations();

    this.goalForm = this.fb.group({
      goals: this.fb.array([]),
    });
    this.getData();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.goalForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe), startWith(this.goalForm.value), pairwise()).subscribe((valsPairwise) => {
      const [previousFormValues, currentFormValues] = valsPairwise;
      if (currentFormValues?.goals.length === 0) {
        return;
      }

      (previousFormValues as { goals: SimpleGoalReactiveForm[] }).goals.forEach((goal, index: number) => {
        if (goal.includeByDefault !== (currentFormValues as { goals: SimpleGoalReactiveForm[] }).goals[index].includeByDefault) {
          this.saveGoal((currentFormValues as { goals: SimpleGoalReactiveForm[] }).goals[index], index);
        }
      });
    });

    this.route.params.subscribe((params) => (this.solutionId = params['id']));

    this.getAllFactors();

    this.userService.user.subscribe((userInfo) => {
      this.userData = userInfo;
    });
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
      });
  }

  private getAllFactors(): void {
    const accountId = sessionStorage.getItem('aid');
    if (accountId) {
      this.accountService.getAvailableAccountFactors(accountId).subscribe((res) => {
        this.availableFactors = res.result
          .filter((factor) => +factor.id !== 0)
          .map((factor) => {
            return {
              label: factor.name,
              value: String(factor.id),
              linked: false,
            };
          });
      });
    }
  }

  getGroups(): void {
    this.goalGroupsService.getGoalGroups().subscribe((goalGroups) => {
      this.linkedFactorsId = [];
      goalGroups.forEach((group) => {
        group.factors.forEach((factor) => {
          if (factor.connected_object_id) {
            (factor.connected_object_id as ConnectedObjectWithoutResult[]).forEach((object) => this.linkedFactorsId.push(object.connected_object_id));
          }
        });
      });
    });
  }

  getData(): void {
    this.loading = true;
    this.goalArray.clear();
    this.modelService.getFactorsByFunctionType(this.modelId, 2).subscribe((data) => {
      this.goalsList = data as Goal[];
      this.selectedFactors = {};

      this.goalsList.forEach((goal) => {
        this.goalArray.push(this.createGoalFormGroup(goal));
      });

      this.goalsListIds = data.map((g) => g.account_factor_id);
      this.loading = false;
    });
  }

  createGoalFormGroup(goal: Goal): FormGroup {
    this.selectedFactors[goal.id] = (goal?.connected_object_id as ConnectedObjectWithoutResult[]).map((cObject) => cObject.connected_object_id);
    return this.fb.group({
      id: goal.id,
      accountSolutionId: goal.account_solution_id,
      accountFactorCategoryId: goal.account_factor_category_id,
      accountFactorId: goal.account_factor_id,
      name: goal.name,
      value: goal.value,
      baselineValue: +goal.baseline_value,
      precision: +goal.factor_precision,
      hasScratchpad: +goal.has_scratchpad,
      unitType: goal.unit_type_id,
      description: goal.description,
      icon: goal.icon,
      includeByDefault: goal.default_active === '1',
      captureBaseline: goal.capture_baseline,
      captureTarget: goal.capture_target,
    });
  }

  openGoal(id: number = null, index?: number, scratchpad = false): void {
    this.indexToUpdate = index ?? null;
    const goalFound = this.goalsList.find((g) => +g.id === +id);
    if (goalFound) {
      this.selectedGoal = { ...goalFound };
    }
    this.aeElement = scratchpad ? 'scratchpad' : 'addGoal';
    if (this.aeElement === 'scratchpad' && this.selectedGoal) {
      this.selectedGoal.id = this.selectedGoal.account_factor_id;
    }
    this.toggleSidebar = true;
  }

  saveGoal(goal: SimpleGoalReactiveForm | null, index: number = null): void {
    this.loadingSave = true;

    const includeByDefault = goal.includeByDefault ? '1' : '0';

    this.modelService
      .setFactor(this.modelId, goal.accountFactorId, 0, 0, includeByDefault)
      .pipe(
        take(1),
        finalize(() => (this.loadingSave = false))
      )
      .subscribe(
        () => {
          this.toggleSidebar = false;
          this.notify(this.selectedGoal ? 'addSuccess' : 'editSuccess');

          if (index !== null) {
          } else {
            this.getData();
          }
        },
        () => {
          this.notify('saveError', 'error');
        }
      );
  }

  updateGoal(goal: SimpleGoalReactiveForm, id: string) {
    const payload: GoalPayload = {
      name: goal.name,
      value_realization_name: '',
      description: goal.description,
      unit_type_id: goal.unitType,
      factor_precision: goal.precision,
      scales_by: 0,
      scales_how: 0,
      ratio: '',
      formula: '',
      is_key_factor: 0,
      literal_number: 0,
      greater_than_zero: 0,
      min: 1,
      max: 100,
      increment: 1,
      external_id: '',
      function_type_id: 2,
      icon: goal.icon,
      account_factor_category_id: goal.accountFactorCategoryId,
      account_factor_id: goal.accountFactorId,
      connected_object_id: this.selectedFactors[id].length
        ? this.availableFactors
            .filter((factor) => this.selectedFactors[id].some((id) => factor.value === id))
            .map((factor) => {
              return {
                connected_object_type_id: '2',
                connected_object_id: factor.value,
              };
            })
        : [],
      capture_baseline: goal.capture_baseline,
      capture_target: goal.capture_target,
    };

    this.goalGroupsService
      .updateGoal(this.accountId, payload)
      .pipe(finalize(() => (this.loadingSave = false)))
      .subscribe(
        (res) => {
          if (res.result && !res.result.success) {
            this.notify('saveError', 'error');
            return;
          }
          this.notify('editSuccess');
        },
        () => {
          this.notify('saveError', 'error');
        }
      );
  }

  getSolutionsScratchpad(factorId: string): void {
    const driverScratchpadFields = [];
    this.solutionService.getSolutionsScratchpads('3', factorId, 0).subscribe((response) => {
      if (response.result.units) {
        const factorGoals = response.result.units;
        this.openParen_default = factorGoals[0].open_paren === '1' ? '(' : undefined;
        this.closeParen_default = factorGoals[0].close_paren === '1' ? ')' : undefined;
        this.driverScratchpadFactorValue_default = factorGoals[0].operand;

        factorGoals.forEach((goal, index: number) => {
          let outFactor;
          this.scaledBy.forEach((scaledByfactor) => {
            if (scaledByfactor.id == goal.factor_id) {
              outFactor = scaledByfactor;
            }
          });
          if (index === 0) {
            this.driverScratchpadFactor_default = outFactor;
          } else {
            const scratchpadInput = {
              id: goal.id,
              factor: outFactor,
              operator: factorGoals[index - 1].operator_id,
              openParen: goal.open_paren === '1' ? '(' : undefined,
              closeParen: goal.close_paren === '1' ? ')' : undefined,
              operand: goal.operand,
            };
            driverScratchpadFields.push(scratchpadInput);
          }
        });

        const scratchpad = {
          driverScratchpadFactor_default: this.driverScratchpadFactor_default,
          openParen_default: this.openParen_default,
          closeParen_default: this.closeParen_default,
          driverScratchpadFactorValue_default: this.driverScratchpadFactorValue_default,
          driverScratchpadFields: driverScratchpadFields,
        };
        this.scratchpadCache.set(factorId, scratchpad);
        this.scratchpadLoading.set(factorId, false);
      }
    });
  }

  findFactor(availableFactors: { label: string; value: string; linked: boolean }[], id = ''): string {
    return availableFactors.find((factor) => factor.value === id).label;
  }

  removeGoal(goalData: SimpleGoalReactiveForm): void {
    this.modelService.deleteFactor(this.modelId, goalData.accountFactorId).subscribe(
      () => {
        this.getData();
        this.notify('deleteMessage');
      },
      () => {
        this.notify('saveError', 'error');
      }
    );
  }

  removeNegatives(value: number): number {
    return value < 0 ? 0 : value;
  }

  addToExistingVpsConfirm(): void {
    let message = this.trans.trans.goalWarning.value;
    this.confirmationService.confirm({
      message,
      acceptLabel: this.trans.trans.yes.value,
      rejectLabel: this.trans.trans.no.value,
      acceptButtonStyleClass: 'btn btn_customizable',
      rejectButtonStyleClass: 'btn btn_customizable_clear',
      header: this.trans.trans.confirm.value,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.addGoalToExistingVps();
      },
      reject: () => {},
    });
  }

  addGoalToExistingVps(): void {
    this.loading = true;
    const payload = {};
    this.solutionService
      .pushGoalsToValueProps(payload, this.modelId)
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (res) => {
          if (res.result && res.result === 'Value props already updated with these goals') {
            this.notify('goalsUpdated');
          }
        },
        () => {
          this.notify('saveError', 'error');
        }
      );
  }

  hideGoalSidebar(): void {
    this.selectedGoal = null;
    this.toggleSidebar = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  private notify(transString: TranslationStrings, type: 'error' | 'success' | 'warning' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
