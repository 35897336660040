import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { OverviewTranslations } from './overview.translation';
import {Model} from '@data/services/account/models/model.interface';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
	@Input() can_edit: boolean = false;
	image_url: string;
	fullImagePath: string;
	callbkSearch: any;
	pageNumber: number;
	pageLimit: number;
	searchText: string;
	shareRoleType: string = "1";
	overviewLoader: boolean;
	editSolution: boolean;
	solution: any = {};
	params: Object;
	solutionId: string;
	solutionCategories: any = [];
	selectedItems: Array<object> = [];
	dropdownSettings = {};
	solutionCategoryList: Array<object> = [];
	contextualHelp: object = {};


	loaderSave: boolean = false;
	editOverview: boolean = false;
	@Output() callbackGotoMenuItem = new EventEmitter();
	@Output() refreshSolutionDetails = new EventEmitter<Model>();

	ngUnsubscribe = new Subject();

	formEditModel = {
		name: "",
		description: "",
		avg_deployment_time: "",
		typical_deployment_type: "",
		quantity_enabled: "",
		avg_sale_price: "",
		avg_sale_cycle: "",
		factors: "",
		id: ""
	}
	style2022$: Observable<boolean>;
	showGoalCard: boolean;

	constructor(
		private solutionService: SolutionService,
		private commonService: CommonService,
		private notification: NotificationService,
		private route: ActivatedRoute,
		private translationService: TranslationsV2Service,
		public trans: OverviewTranslations,
		private styleService: StyleService
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getSolutionsOverview();

		this.showGoalCard = this.commonService.checkFeature(131);

		this.solutionService.readSolutionShareType.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

			this.shareRoleType = response;
		});

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	closeOverviewEdit(): void {
		this.editOverview = false;
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	getSolutionCategories() {
		this.solutionService.getSolutionCategoryList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			if (result.result) {
				this.solutionCategories = result.result;
				this.solutionCategories = this.solutionCategories.filter(item => {
					return item.category != "";
				})
				let tempsolution = this.solutionCategoryList;
				let selectedItems;
				if (tempsolution) {
					selectedItems = this.solutionCategories.filter(function (obj1) {
						return tempsolution.some(function (obj2) {
							return obj1.id == obj2;
						});
					});
				}
				this.selectedItems = selectedItems;
			}
		});
	}

	// callbackGotoMenuItem() {

	// }
	getSolutionsOverview() {
		let solutionId;
		this.route.params.subscribe(params => solutionId = params['id']);

		this.overviewLoader = true;
		this.solutionService.getSolutionDetail(solutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.formEditModel = response.result;
				this.shareRoleType = response.result.share_role_type_id;
				this.solution = response.result;
			}
			this.overviewLoader = false;
			this.solutionCategoryList = response.result.solution_category_id;
			this.getSolutionCategories();
			this.refreshSolutionDetails.emit(response.result);
			this.editOverview = false;
		});
	}

	openEditPage() {
		this.editSolution = true;
	}

	cancelEditPage() {
		this.editSolution = false;
	}

	updateSolution() {
		this.loaderSave = true;
		this.route.params.subscribe(params => this.solutionId = params['id']);

		// const solutionId = +this.route.snapshot.paramMap.get('id');
		this.overviewLoader = true;
		this.selectedItems = this.selectedItems.map(item => {
			return item['id']
		})
		this.params = {
			"name": this.solution.name,
			"description": this.solution.description,
			"solution_category_id": this.selectedItems
		}
		this.solutionService.updateSolutionDetail(this.solution.id, this.params).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.loaderSave = false;
				this.notification.success(response.result, false);
				this.getSolutionsOverview();
				this.editOverview = false;
			}
			this.overviewLoader = false;
		});
		this.editSolution = false;
	}
}
