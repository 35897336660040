<form name="formAddEditCategory" #formAddEditCategory="ngForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebar_title.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditCategory.controls.name">
            <label for="name" class="sidebar_label">{{ trans.trans.name.value }}</label>
            <input type="text" class="form-control" ret-data="Name" [(ngModel)]="selectedElem.name" name="name" [placeholder]="trans.trans.name.value" required />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditCategory.controls.tconame">
            <label for="tconame" class="sidebar_label">{{ trans.trans.tconame.value }}</label>
            <input type="text" class="form-control" ret-data="TCO Name" [(ngModel)]="selectedElem.tco_name" name="tconame" [placeholder]="trans.trans.tconame.value" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditCategory.controls.description">
            <label for="description" class="sidebar_label"
              >{{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'ae_value-category'"
                [isTranslationV2]="true"
                [transObj]="trans.trans.description"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <textarea name="description" ret-data="Description" [(ngModel)]="selectedElem.description" rows="4" class="form-control"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="has_tag" class="sidebar_label">{{ trans.trans.createCustomAssetTag.value }}</label>
            <br />
            <p-inputSwitch [(ngModel)]="selectedElem.has_tag" (onChange)="generateCustomAsset($event)" name="has_tag"></p-inputSwitch>
          </div>
          <div class="col-md-6"></div>
        </div>

        <div class="row" *ngIf="selectedElem.has_tag">
          <div class="col-md-6" appNGError [elemControl]="formAddEditCategory.controls.valueTag" [ngClass]="{ 'field-error': selectedElem.nameTag === selectedElem.valueTag }">
            <label for="name" class="sidebar_label labelsRequired">{{ trans.trans.benefitGroupValueTag.value }}</label>
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTagTooltip.value">info</span>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="selectedElem.tag"
              name="valueTag"
              ret-data="Value Tag"
              maxlength="255"
              appSpaceToUnderscore
              appSpecialCharacterValidator
              required
            />
            <span *ngIf="selectedElem.has_tag" class="hint light-h">{{ selectedElem.tag ? (selectedElem.tag | assetTagFormat) : (trans.trans.value.value | assetTagFormat) }}</span>
            <span class="input-error-warn" *ngIf="formAddEditCategory.controls.valueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6" *ngIf="feature52">
            <label for="description" class="sidebar_label">% {{ trans.trans.driven.value }}</label
            ><br />
            <p-inputSwitch
              name="percent_driven"
              (onChange)="selectedElem.percent_driven ? (selectedElem.fastfill_enabled = true) : null"
              [(ngModel)]="selectedElem.percent_driven"
            ></p-inputSwitch>
          </div>

          <div class="col-md-6" *ngIf="feature52">
            <label for="description" class="sidebar_label">{{ trans.trans.fastFill.value }}</label
            ><br />
            <p-inputSwitch name="fastfill_enabled" [disabled]="selectedElem.percent_driven" [(ngModel)]="selectedElem.fastfill_enabled"></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <div class="row">
        <div class="col-md-12">
          <input type="hidden" name="id" [(ngModel)]="selectedElem.id" />

          <button
            *ngIf="mode === 'edit'"
            pButton
            type="button"
            iconPos="right"
            [label]="trans.trans.update.value"
            [loading]="isLoading"
            [disabled]="!formAddEditCategory.valid"
            (click)="updateValueCategory(formAddEditCategory)"
            class="pull-right marg-h btn btn_customizable xf-continue-button"
          ></button>
          <button
            *ngIf="mode === 'add'"
            pButton
            type="button"
            iconPos="right"
            [label]="trans.trans.save.value"
            [disabled]="!formAddEditCategory.valid"
            [loading]="isLoading"
            class="pull-right marg-h btn btn_customizable xf-continue-button"
            (click)="insertValueCategory(formAddEditCategory)"
          ></button>
          <app-are-you-sure
            matTooltipPosition="above"
            [message]="trans.trans.areYouSure.value"
            class="btn cancel-confirm pull-right marg-h"
            icon=""
            [text]="trans.trans.back.value"
            (callbackYes)="close()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </div>
</form>
