<div *ngIf="mode == 'add' || mode == 'edit' || mode == 'view'">
  <div class="row">
    <div class="col-xs-12 top-action-details">
      <div (click)="funcAddEditBenefitDone()" class="clickable a-link-color"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToBenefits.value }}</div>
      <div *ngIf="benefitCloneAllowed && modelEditor && mode !== 'add'" class="action-item primary pull-right" (click)="cloneSpecific()">
        <i class="material-icons-outlined" style="font-size: 13px"> file_copy </i>
        <span>{{ trans.trans.clone.value }}</span>
      </div>
    </div>
  </div>
  <app-ae-benefits
    *ngIf="can_edit && (mode == 'add' || mode == 'edit')"
    [mode]="mode"
    [can_edit]="can_edit"
    [solutionId]="solutionId"
    [selectedElem]="selectedElem"
    [default_term]="default_term"
    (callback)="funcAddEditBenefitDone()"
    (cancelBenefitCallback)="funcAddEditBenefitDone()"
  ></app-ae-benefits>
  <app-r-benefits *ngIf="!can_edit && mode == 'view'" [can_edit]="can_edit" [default_term]="default_term" [selectedElem]="selectedElem"></app-r-benefits>
</div>

<p-menu #carouselMenu [popup]="true" appendTo="body" [model]="carousel" class="style-2022"></p-menu>
<p-progressBar *ngIf="!loaded" mode="indeterminate"></p-progressBar>

<div *ngIf="mode == 'list' && loaded">
  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <div class="title">
          {{ trans.trans.benefits.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.benefits"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </div>
      </div>

      <div class="col-xs-6">
        <button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="funcShowAddEditBenefit('add')" matTooltip="Add New" matTooltipPosition="above">
          <i class="fa fa-plus"></i>{{ trans.trans.addNew.value }}
        </button>
        <a class="btn btn_customizable pull-right" style="margin-right: 10px">
          <i (click)="toggleOptions = !toggleOptions" *ngIf="!toggleOptions" class="fa fa-ellipsis-h"></i>
          <div *ngIf="toggleOptions" style="float: right">
            <span (click)="toggleExpandCollapse()" *ngIf="expanded" style="margin-left: 10px">{{ trans.trans.expandAll.value }}</span>
            <span (click)="toggleExpandCollapse()" *ngIf="!expanded" style="margin-left: 10px">{{ trans.trans.collapseAll.value }}</span>
          </div>

          <div *ngIf="toggleOptions" style="float: right">
            <span (click)="setRows(10)">10 | </span>
            <span (click)="setRows(25)">25 | </span>
            <span (click)="setRows(50)">50 </span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <p-progressBar *ngIf="!loaded" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
  <div *ngIf="loaded">
    <dl-fieldset
      *ngFor="let category of valueCategories"
      styleClass="benefits_legend"
      class="benefits_legend"
      [legend]="category.name"
      [collapsed]="category.collapsed"
      [toggleable]="true"
      [info]="category.description"
    >
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="category.strSearch"
              (keyup)="tableSolutionList.filter($any($event).target.value, 'metric_name', 'contains')"
              type="text"
              [placeholder]="trans.trans.search.value"
              class="form-control"
            />
            <span *ngIf="category.strSearch !== ''" (click)="category.strSearch = ''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <p-table
            #tableSolutionList
            [value]="category.benefits"
            [columns]="selectedColumns"
            [paginator]="true"
            [rows]="rows"
            styleClass="keywest_table"
            tableStyleClass="keywest_table"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngIf="benefitCloneAllowed && modelEditor" class="checkbox-column">
                  <div class="custom-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        class="solutionCheckbox"
                        name="selectAll"
                        [checked]="category.allCheck"
                        [indeterminate]="category.partialCheck"
                        (change)="checkBenefitCategory(category)"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{ trans.trans[col.field].value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans[col.field]"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th>
                  {{ trans.trans.benefitAttributes.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.benefits.value"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </th>
                <th>
                  <i (click)="funcCarouselLeft()" *ngIf="carouselIndex > 0" class="clickable fa fa-chevron-left" style="width: 20px; margin-right: 10px"></i>
                  <span (click)="carouselMenu.toggle($event)" class="clickable"
                    >{{ trans.trans[carouselSelected.field].value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans[carouselSelected.field]"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </span>
                  <p-sortIcon [pSortableColumn]="carouselSelected.field" [field]="carouselSelected.field"></p-sortIcon>
                  <i (click)="funcCarouselRight()" *ngIf="carouselIndex < carousel.length - 1" class="clickable fa fa-chevron-right" style="width: 20px; margin-left: 10px"></i>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-benefit>
              <tr>
                <td *ngIf="benefitCloneAllowed && modelEditor" class="checkbox-column">
                  <div class="custom-checkbox">
                    <label>
                      <input type="checkbox" class="solutionCheckbox" name="selectAll" (change)="checkBenefits(category, benefit)" [checked]="benefit.checked" />
                      <span class="check"></span>
                    </label>
                  </div>
                </td>
                <td>
                  <a (click)="can_edit ? funcToggleEdit(benefit) : funcToggleView(benefit)" class="clickable">{{ benefit.metric_name }}</a>
                </td>
                <td>{{ benefit.driver_factor }}</td>
                <td>{{ benefit.driver_baseline_answer }}</td>

                <td>
                  <span *ngIf="can_edit" class="keywest_icon_group">
                    <span
                      ><i
                        [matTooltip]="benefit.default_na == 1 ? trans.trans.defaultOffToggle?.value : trans.trans.defaultOnToggle.value"
                        matTooltipPosition="above"
                        class="fa fa-power-off"
                        [ngStyle]="{ color: benefit.default_na == 1 ? 'red' : 'green' }"
                      ></i
                    ></span>
                    <span [matTooltip]="trans.trans.units.value + benefit.unit_type_id" matTooltipPosition="above"
                      ><i class="fa fa-{{ funcGetUnitTypeIcon(benefit.unit_type) }}"></i
                    ></span>
                    <span [matTooltip]="trans.trans.accrual.value + benefit.accrual_type" matTooltipPosition="above"
                      ><i class="fa fa-refresh" [ngStyle]="{ color: benefit.accrual_type_id == 1 ? 'red' : 'green' }"></i
                    ></span>
                    <span [matTooltip]="trans.trans.differentiated.value + benefit.unique_type" matTooltipPosition="above"
                      ><i class="fa fa-key" [ngStyle]="{ color: benefit.unique_type_id == 0 ? 'red' : benefit.unique_type_id == 1 ? 'orange' : 'green' }"></i
                    ></span>
                    <span (click)="addDriverSubTypes(benefit); toggleAddSubType = !toggleAddSubType" [matTooltip]="'Subtypes'" matTooltipPosition="above"
                      ><i class="fa fa-check" [ngStyle]="{ color: benefit.subtypes && !benefit.subtypes[0] ? 'red' : benefit.unique_type_id == 1 ? 'orange' : 'green' }"></i
                    ></span>
                  </span>
                </td>
                <td *ngIf="carouselSelected.field !== 'files'">{{ benefit[carouselSelected.field] }}</td>
                <td *ngIf="carouselSelected.field == 'files'" style="width: 200px">
                  <a *ngFor="let file of benefit.images" [href]="file.path" target="_blank"
                    ><img [src]="file.path" class="img-responsive" [matTooltip]="file.image_name" matTooltipPosition="above" style="margin: 3px; float: left; max-height: 30px"
                  /></a>
                </td>

                <td>
                  <span *ngIf="can_edit" class="keywest_icon_group">
                    <span><i (click)="funcToggleEdit(benefit)" [matTooltip]="trans.trans.edit.value" matTooltipPosition="above" class="clickable fa fa-pencil"></i></span>
                    <span>
                      <app-are-you-sure
                        #areYouSure
                        [matTooltip]="trans.trans.delete.value"
                        matTooltipPosition="above"
                        [message]="trans.trans.areYouSure.value"
                        text=""
                        icon="fieldset_icon fa fa-trash"
                        (callbackYes)="funcDeleteBenefit(benefit, areYouSure)"
                      ></app-are-you-sure>
                    </span>
                  </span>
                  <span *ngIf="!can_edit" class="keywest_icon_group">
                    <span><i (click)="funcToggleView(benefit)" class="fa fa-eye clickable pull-right" [matTooltip]="trans.trans.view.value" matTooltipPosition="above"></i></span>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </dl-fieldset>
  </div>
</div>

<p-sidebar [(visible)]="toggleAddSubType" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <form *ngIf="toggleAddSubType" name="addSubtypeForm" #addSubtypeForm="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.edit_benefit_subtypes.value }}
      </div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-md-9">
              <label for="dbAdminName" class="dbAdminName"
                >{{ trans.trans.enter_types.value }} <strong>{{ benefitSelectedList?.driver_factor }}</strong> {{ trans.trans.covers.value }}:
              </label>
            </div>

            <div class="col-md-3">
              <button type="button" class="btn btn_customizable pull-right" (click)="addSubTypes()"><i class="glyphicon glyphicon-plus"></i> {{ trans.trans.add.value }}</button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <table *ngIf="textInputFields && textInputFields.length" id="benefits" class="table custom-table benefitSubDriverTable">
                <thead>
                  <tr>
                    <th>{{ trans.trans.subtype.value }}</th>
                    <th>{{ trans.trans.value.value }}</th>
                    <th>{{ trans.trans.improvement.value }} [{{ benefitSelectedList.unit_type_abbr }}]</th>
                    <th *ngIf="checkIfNotApplicable(benefitSelectedList.financial_factor_id)">{{ benefitSelectedList.financial_factor }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let textInputField of textInputFields; let i = index" class="grid-row">
                    <td>
                      <input
                        type="text"
                        class="form-control m-t-xxs"
                        [(ngModel)]="textInputField.name"
                        [placeholder]="trans.trans.enter_name.value"
                        name="driverSubTypesName_{{ i }}"
                      />
                    </td>
                    <td>
                      <div style="display: inline-flex">
                        <input
                          type="text"
                          class="form-control m-t-xxs inlineInput"
                          [(ngModel)]="textInputField.driver_value"
                          [placeholder]="trans.trans.driver_value.value"
                          name="driverSubTypesDriverValue_{{ i }}"
                        />
                        <i style="padding-top: 15px; padding-left: 10px" class="fa fa-times"></i>
                      </div>
                    </td>
                    <td>
                      <div style="display: inline-flex">
                        <input
                          type="text"
                          class="form-control m-t-xxs inlineInput"
                          [(ngModel)]="textInputField.improvement_value"
                          [placeholder]="trans.trans.improvement_value.value"
                          name="driverSubTypesImpValue_{{ i }}"
                        />
                        <i *ngIf="checkIfNotApplicable(benefitSelectedList.financial_factor_id)" style="padding-top: 15px; padding-left: 10px" class="fa fa-times"></i>
                      </div>
                    </td>
                    <td>
                      <div style="display: inline-flex">
                        <input
                          type="text"
                          [ngStyle]="{ visibility: checkIfNotApplicable(benefitSelectedList.financial_factor_id) ? 'visible' : 'hidden' }"
                          class="form-control m-t-xxs"
                          [(ngModel)]="textInputField.financial_value"
                          [placeholder]="trans.trans.value.value"
                          name="driverSubTypesFinancialValue_{{ i }}"
                        />
                        <i (click)="textClose(textInputField.id)" style="padding-top: 15px; padding-left: 10px" class="fa fa-times text-close"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_container">
        <div class="col-xs-12">
          <button type="submit" class="btn btn_customizable pull-right" (click)="saveDriverSubTypes(addSubtypeForm)">{{ trans.trans.save.value }}</button>
          <app-are-you-sure
            class="btn delete-confirm pull-right marg-r"
            [message]="trans.trans.are_you_sure_delete.value"
            [text]="trans.trans.delete_all.value"
            icon=""
            (callbackYes)="deleteDriverSubType(driverFactorId)"
          ></app-are-you-sure>
          <app-are-you-sure
            class="btn cancel-confirm pull-right marg-h"
            [message]="trans.trans.areYouSure.value"
            [text]="trans.trans.back.value"
            icon=""
            (callbackYes)="cancelSubtypeChange()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>

<p-sidebar [(visible)]="toggleClone" *ngIf="toggleClone" (onHide)="closeCloneSidebar()" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <form *ngIf="toggleClone" name="addSubtypeForm" #addSubtypeForm="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.cloneBenefit.value }}
      </div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-md-12">
              {{ benefitsSelectedCheckbox.length > 1 ? trans.trans.benefitsName.value : trans.trans.benefitName.value }}
            </div>
            <div class="col-md-12" style="margin-top: 18px">
              <ng-container *ngFor="let benefit of benefitsSelectedCheckbox">
                <input class="form-control" [value]="benefit.metric_name" disabled />
              </ng-container>
            </div>
            <div class="col-md-12" style="margin-top: 18px">
              {{ trans.trans.cloneTo.value }}
            </div>
            <div class="col-md-12" style="margin-top: 18px">
              <p-dropdown
                appendTo="body"
                panelStyleClass="dropdown-item-font"
                name="asset"
                optionLabel="name"
                class="keywest_dropdown dropdown-theme"
                [options]="solutions"
                [filter]="true"
                filterBy="name"
                [autoDisplayFirst]="false"
                [(ngModel)]="solutionSelected"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_container">
        <div class="col-xs-12">
          <button type="submit" [disabled]="!solutionSelected || cloning" class="btn btn_customizable pull-right" (click)="triggerClone()">
            <ng-container *ngIf="!cloning; else loader">
              {{ trans.trans.save.value }}
            </ng-container>
            <ng-template #loader>
              <div class="ld ld-ring ld-spin">
                <i class="fa fa-spinner fa-spin fa-fw"></i>
              </div>
            </ng-template>
          </button>
          <app-are-you-sure
            class="btn cancel-confirm pull-right marg-h"
            [message]="trans.trans.areYouSure.value"
            [text]="trans.trans.back.value"
            icon=""
            (callbackYes)="cancelClone()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>

<p-sidebar [(visible)]="adminActionbar" styleClass="p-sidebar-actionbar" [dismissible]="false" [showCloseIcon]="false" position="bottom" [modal]="false">
  <div class="action-bar">
    <div class="count">
      <span>{{ benefitsCount }} {{ trans.trans.benefitSelected.value }}</span>
    </div>
    <div class="actions">
      <div class="action-item primary" (click)="cloneStart()">
        <i class="material-icons-outlined" style="font-size: 13px"> file_copy </i>
        <span>{{ trans.trans.clone.value }}</span>
      </div>
      <!-- <div class="action-item"> // will be added back whem BE is capable of handling movement.
        <i class="fa fa-arrows"></i>
        <span>{{ trans.trans.move.value }}</span>
      </div> -->
      <!-- <div class="action-item warning"> // removed until BE adds soft delete for benefits.
        <app-are-you-sure
          class="btn cancel-confirm pull-right marg-h"
          [message]="trans.trans.areYouSureBenefits.value"
          [text]="trans.trans.delete.value"
          icon="fa fa-trash"
          (callbackYes)="deleteBenefitsBundle()"
        ></app-are-you-sure>
      </div> -->
    </div>
    <div class="cancel" (click)="cancelBenefitSelection()">
      <i class="fa fa-times"></i>
      <span>{{ trans.trans.cancel.value }}</span>
    </div>
  </div>
</p-sidebar>
