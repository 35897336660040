import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription } from 'rxjs';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
	selector: 'app-dealdesk',
	templateUrl: './dealdesk.component.html',
	styleUrls: ['./dealdesk.component.scss']
})
export class DealdeskComponent implements OnInit, OnDestroy {
	showBenefits = false;
	showAssumption = false;
	showCosts = false;
	showDeployment = false;
	isvalueProp = false;
	valuePropId: string | number;
	valueProp: ValueProp;
	// Contextual Help
	contextualHelp = {};
	contextualHelpSub: Subscription;

	graduatedDeploymentFeature = false;
	refreshBenefits = false;
	subscription: Subscription;

	constructor(
		private router: Router,
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		private translateService: TranslateService,
		public trans: Translations
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit(): void {
		this.contextualHelpSub = this.commonService.contextualHelp.subscribe(response => {
			this.contextualHelp = response['Valueprop'];
		});

		if (this.commonService.checkFeature('12')) {
			this.graduatedDeploymentFeature = true;
		}

		// set VmWare's default status to be 'Customer Engaged', so if account id is 20, for vmware the dealdesk flow will create valueProp instead of valueposition.
		// Valueprop default status is 'Customer Engaged'
		const aid = sessionStorage.getItem('aid');
		if (this.router.url === '/valueprop/dealdesk' || aid === '20') {
			this.isvalueProp = true;
		}

		this.subscription = this.valuepropService.refreshDashboard.subscribe(response => {
			if (response === 'benefitChange') {
				this.refreshBenefits = !this.refreshBenefits;
			}
		});

	}

	ngOnDestroy(): void {
		if (this.subscription) { this.subscription.unsubscribe(); }
		if (this.contextualHelpSub) { this.contextualHelpSub.unsubscribe(); }
	}

	quickCreateCompleted(valueProp): void {
		this.valuePropId = valueProp['valuePropId'];
		this.valueProp = {
			'id': this.valuePropId.toString(),
			'vp_can_edit': true,
			'share_role_type_id': '5',
			'term': valueProp['ValuePropTerm']
		}

		if (this.graduatedDeploymentFeature) {
			this.showDeployment = true;
		} else {
			this.showCosts = true;
		}
	}

	initCosts(): void {
		this.showCosts = true;
	}

	showBenefit(): void {
		this.showBenefits = true;
	}

	showAssumptions(): void {
		this.showAssumption = true;
	}

	finishandReview(): void {
		this.router.navigate(['/valueprop', this.valuePropId]);
	}


}
