<div class="keywest_panel">
  <div class="row" *ngIf="canEdit">
    <div class="col-xs-6">
      <div class="input-group keywest_search">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
        <input (keyup)="filterByText()" name="search" [(ngModel)]="searchText" type="text" [placeholder]="trans.trans.search.value" class="form-control" />
        <span *ngIf="searchText !== ''" (click)="searchText = ''" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
      </div>
    </div>
    <div class="col-xs-6" *ngIf="type">
      <p-selectButton [options]="type" (onChange)="funcFilter($event)" [(ngModel)]="typeFilter" styleClass="keywest_select_button"></p-selectButton>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6" *ngIf="canEdit">
      <div class="case_study_benefits_list_container">
        <div class="row case_study_benefits_list" *ngIf="!filteredList.length">
          <div class="col-xs-12">{{ trans.trans.no_records.value | titlecase }}</div>
        </div>
        <div class="row case_study_benefits_list" *ngFor="let elem of filteredList">
          <div class="col-xs-9">{{ elem.name }}
            <i *ngIf="elem.description && !(style2022$ | async)" [matTooltip]="elem.description" matTooltipPosition="above" class="fa fa-info-circle"></i>
            <span *ngIf="elem.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description" matTooltipPosition="above">
              {{ trans.trans.info.value }}
            </span>
          </div>
          <div class="col-xs-3 clickable">
            <a (click)="addElemToSelected(elem)" *ngIf="!elem.disabled" class="pull-right case_study_btn_add">{{ trans.trans.add.value }} <i style="padding-top: 3px" class="fa fa-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <p-paginator [rows]="rowLimit" [totalRecords]="filteredList.length" (onPageChange)="paginate($event)"></p-paginator>
        </div>
      </div>
    </div>

    <div [ngClass]="canEdit ? 'col-xs-6' : 'col-xs-12'">
      <div class="case_study_benefits_list_container">
        <div class="row case_study_benefits_list">
          <div class="col-xs-9">
            <b>{{ title }} {{ trans.trans.selected.value }} ({{ currentSelectedList?.length ? currentSelectedList?.length : 0 }})</b>
          </div>
          <div *ngIf="canEdit" class="col-xs-3">
            <a (click)="clearSelected()" class="clickable pull-right">{{ trans.trans.clear_all.value | titlecase }} <i class="fa fa-times"></i></a>
          </div>
        </div>

        <div class="row case_study_benefits_list" *ngIf="!currentSelectedList?.length">
          <div class="col-xs-12">{{ trans.trans.click_action.value }}</div>
        </div>

        <div class="row case_study_benefits_list" *ngFor="let elem of currentSelectedList; let i = index">
          <div class="col-xs-9">{{ elem.name }}
            <i *ngIf="elem.description && !(style2022$ | async)" [matTooltip]="elem.description" matTooltipPosition="above" class="fa fa-info-circle"></i>
            <span *ngIf="elem.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="elem.description" matTooltipPosition="above">
              {{ trans.trans.info.value }}
            </span>
          </div>
          <div class="col-xs-3">
            <a *ngIf="canEdit" (click)="deleteSelectedElem(elem, i)"><i class="clickable pull-right fa fa-times"></i></a>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 20px" *ngIf="callbackSave">
        <div class="col-xs-12">
          <a (click)="funcSave()" class="btn btn_customizable pull-right">{{ trans.trans.save.value }}</a>
          <!-- TODO: Fix below -->
          <!-- <app-are-you-sure *ngIf="!cloneLoader" matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure> -->
        </div>
      </div>
    </div>
  </div>
</div>
