<div class="row">
  <div class="col-xs-12" *ngIf="canEdit">
    <a (click)="openAddQuoteModal()" class="clickable a-link-color"><i class="fa fa-plus"></i> {{ trans.trans.add_testimonial.value }}</a>
    <app-editTranslation
      *ngIf="showTranslate"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.add_testimonial"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>
</div>

<div class="row section_row" *ngFor="let quote of currentQuotes">
  <div class="row">
    <div class="col-xs-4">
      <i (click)="quote.expand = !quote.expand" *ngIf="!quote.expand" class="fa fa-chevron-right" style="margin-right: 10px"></i>
      <i (click)="quote.expand = !quote.expand" *ngIf="quote.expand" class="fa fa-chevron-down" style="margin-right: 10px"></i>

      <span (click)="quote.expand = !quote.expand" class="clickable section_row_text">{{ quote.first_name }} {{ quote.last_name }}</span>
    </div>
    <div class="col-xs-7">
      <span class="section_row_text">{{ quote.title }}</span>
    </div>
    <div class="col-xs-1">
      <span class="keywest_icon_group pull-right" *ngIf="canEdit">
        <span (click)="openAddQuoteModal(quote)" [matTooltip]="trans.trans.edit.value" tooltipPostiion="top">
          <i class="fa fa-pencil"></i>
        </span>
        <span>
          <app-are-you-sure
            [matTooltip]="trans.trans.delete.value"
            matTooltipPosition="above"
            [message]="trans.trans.areYousure.value"
            text=""
            icon="fieldset_icon fa fa-trash"
            (callbackYes)="deleteQuote(quote.id)"
          >
          </app-are-you-sure>
        </span>
      </span>
      <span class="keywest_icon_group pull-right" *ngIf="!canEdit">
        <span (click)="openView(quote)">
          <i class="fa fa-eye"></i>
        </span>
      </span>
    </div>
  </div>
  <div class="row" *ngIf="quote.expand">
    <div class="col-xs-4"></div>
    <div class="col-xs-7">
      <span class="quote_quote" [innerHTML]="quote.quote | filterUrlToLink"></span>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="toggleAddEditQuote" [modal]="isModal" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="resetValues()">
  <app-r-quote *ngIf="toggleAddEditQuote && !canEdit" [quoteView]="quoteFormObj"></app-r-quote>
  <app-ae-quote
    *ngIf="toggleAddEditQuote && canEdit"
    [quoteFormObj]="quoteFormObj"
    [mode]="mode"
    [caseStudyId]="caseStudyId"
    (valueChange)="closeSidebar(true)"
    (closeCallback)="closeSidebar(false)"
  ></app-ae-quote>
</p-sidebar>
