import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ShareComponent } from './share.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';


import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KeyFiguresModule } from '../key_figures/key_figures.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		TableChartModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		SidebarModule,
		ApplicationPipesModule,
		KeyFiguresModule,
		MenubarModule,
		MenuModule,
		DropdownModule,
		CalendarModule,
		AutoCompleteModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [
		ShareComponent
	],
	providers: [Translations],
	exports: [ShareComponent]
})
export class ShareV1Module { }
