<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ trans.add_benefits_model.add_your_benefits | translate }}
    <img class="actionLoader" *ngIf="selectedBenefit && driverFactorLoader" src="{{ fullImagePath }}" />
  </div>
  <div class="sidebar_container">
    <div class="keywest_panel">
      <div class="modal-body">
        <div *ngIf="!canEdit" class="row">
          <div class="col-xs-12">
            <p class="can_edit_message" style="color: #ffffff !important; padding: 20px; font-size: 14px">
              {{ trans.add_benefits_model.you_are_in_view_only_mode | translate }}
            </p>
          </div>
        </div>

        <form name="addBenefitsForm" #addBenefitsForm="ngForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.benefitsName?.invalid && addBenefitsForm.controls.benefitsName?.touched }">
                <label for="benefitsName" class="benefitsName labelsRequired">{{ trans.add_benefits_model.name | translate }}</label>
                <input type="text" class="form-control m-t-xxs" id="benefitsName" placeholder="Enter Name" name="benefitsName" [(ngModel)]="benefitsName" required />
              </div>
              <div class="form-group">
                <label for="benefitsDescription">{{ trans.add_benefits_model.description | translate }}</label>
                <textarea name="description" class="form-control m-t-xxs" id="benefitsDescription" placeholder="Description" [(ngModel)]="benefitsDescription"> </textarea>
              </div>
              <div class="form-group">
                <label for="benefitsFeatures">{{ trans.add_benefits_model.featurescapabilities | translate }}</label>
                <textarea name="features" class="form-control m-t-xxs" id="benefitsFeatures" placeholder="Features And Capabilities" [(ngModel)]="benefitsFeatures"> </textarea>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.annualBenefits?.invalid && addBenefitsForm.controls.annualBenefits?.touched }">
                <label for="" class="labelsRequired">{{ trans.add_benefits_model.is_this_an_annual_benefit | translate }}</label>
                <select *ngIf="annualBenefitsList && annualBenefitsList.length" class="form-control m-t-xxs" name="annualBenefits" [(ngModel)]="annualBenefits" required>
                  <option value="" selected="selected"></option>
                  <option *ngFor="let s of annualBenefitsList" [ngValue]="s.id">
                    {{ s.name }}
                  </option>
                </select>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.impact?.invalid && addBenefitsForm.controls.impact?.touched }">
                <label for="" class="labelsRequired">{{ trans.add_benefits_modal.impact | translate }}</label>
                <select *ngIf="impactList && impactList.length" class="form-control m-t-xxs" name="impact" [(ngModel)]="impact" required>
                  <option value="" selected="selected"></option>
                  <option *ngFor="let s of impactList" [ngValue]="s.id">
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="form-group"
                [ngClass]="{ 'has-error': addBenefitsForm.controls.valueCategoryBenefits?.invalid && addBenefitsForm.controls.valueCategoryBenefits?.touched }"
              >
                <label for="valueCategoryBenefits" class="labelsRequired">{{ trans.add_benefits_model.which_value_category_does_this_benefit_fall_under | translate }}</label>
                <select
                  *ngIf="valueCategoryBenefitsList && valueCategoryBenefitsList.length"
                  class="form-control m-t-xxs driverInline"
                  id="valueCategoryBenefits"
                  name="valueCategoryBenefits"
                  [(ngModel)]="valueCategoryBenefits"
                  required
                >
                  <option value="" selected="selected"></option>
                  <option *ngFor="let s of valueCategoryBenefitsList" [ngValue]="s.value_category_id">
                    {{ s.value_category }}
                  </option>
                </select>
                <div class="btnInline">
                  <a *ngIf="canEdit" class="driverFactor" (click)="openAddValueCategory()">
                    <span class="icon-plus"></span>
                  </a>
                </div>
              </div>
              <div>
                <label>{{ trans.add_benefits_model.functional_objectives | translate }}</label
                ><br />
                <select *ngIf="funcObjectives && funcObjectives.length" class="form-control m-t-xxs" [(ngModel)]="selectedFuncObjectives" name="selectedFuncObjectives">
                  <option value="" selected="selected"></option>
                  <option *ngFor="let ob of funcObjectives" [value]="ob.id">{{ ob.name }}</option>
                </select>
              </div>
              <div class="form-group benefits-scale">
                <label for="" class="labelsRequired">{{ trans.add_benefits_model.does_your_benefit_scale | translate }}</label>
                <select *ngIf="scaleList && scaleList.length" class="form-control m-t-xxs scaleType" name="scale" [(ngModel)]="scale" required>
                  <option value="" selected="selected"></option>
                  <option *ngFor="let s of scaleList" [ngValue]="s">
                    {{ s.name }}
                  </option>
                </select>
                <label class="scaleAnswer" *ngIf="scale">{{ scale.value }}</label>
              </div>
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.uniqueTypes?.invalid && addBenefitsForm.controls.uniqueTypes?.touched }">
                <label for="" class="labelsRequired">{{ trans.add_benefits_model.uniqueness | translate }}</label>
                <select *ngIf="uniqueList && uniqueList.length" class="form-control m-t-xxs" name="uniqueTypes" [(ngModel)]="uniqueTypes" required>
                  <option value="" selected="selected"></option>
                  <option *ngFor="let s of uniqueList" [ngValue]="s.id">
                    {{ s.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="project_id">{{ trans.add_benefits_model.project | translate }}</label>
                <select *ngIf="projects && projects.length" class="form-control m-t-xxs" name="project_id" [(ngModel)]="projectId">
                  <option value="" selected="selected"></option>
                  <option *ngFor="let p of projects" [ngValue]="p.id">
                    {{ p.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="expense_type_id">{{ trans.add_benefits_model.expense_type | translate }}</label>
                <select *ngIf="expenseTypes && expenseTypes.length" class="form-control m-t-xxs" name="expense_type_id" [(ngModel)]="expenseTypeId">
                  <option value="" selected="selected"></option>
                  <option *ngFor="let p of expenseTypes" [ngValue]="p.id">
                    {{ p.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="default_na">{{ trans.add_benefits_model.mark_this_benefit_as_nonapplicable_by_default | translate }}</label>
                <input type="checkbox" name="default_na" [(ngModel)]="defaultNA" />
              </div>

              <div class="form-group" *ngIf="selectedBenefit && selectedBenefit.situations && selectedBenefit.situations.length > 0">
                <label for="">{{ trans.add_benefits_model.situations | translate }}</label>
                <ul>
                  <li *ngFor="let situation of selectedBenefit.situations">
                    {{ situation.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group" *ngIf="hasFeature46" class="col-xs-6">
              <label for="soft">{{ trans.add_benefits_model.is_this_benefit_considered_soft | translate }}</label>
              <select class="form-control m-t-xxs" name="soft" [(ngModel)]="soft">
                <option value="" selected="selected"></option>
                <option *ngFor="let p of hardSoftDropdown" [ngValue]="p.value">
                  {{ p.label }}
                </option>
              </select>
            </div>

            <div class="col-xs-6" *ngIf="!hasFeature46">
              <label>{{ trans.add_benefits_model.should_this_benefit_be_quantified | translate }}</label
              ><br />
              <p-inputSwitch name="quant" id="quant" [(ngModel)]="quant"></p-inputSwitch> {{ quant ? 'Yes' : 'No' }}
            </div>
            <div class="col-xs-6" *ngIf="!hasFeature46">
              <label>{{ trans.add_benefits_model.is_this_benefit_considered_soft | translate }}</label
              ><br />
              <p-inputSwitch name="soft" [(ngModel)]="soft"></p-inputSwitch> {{ soft ? 'Yes' : 'No' }}
            </div>
            <div class="col-xs-6" *ngIf="!hasFeature46">
              <label>{{ trans.add_benefits_model.what_type_of_benefit_is_this | translate }}</label>
              <select *ngIf="benefitTypeList && benefitTypeList.length" class="form-control m-t-xxs" name="benefitType" [(ngModel)]="benefitType">
                <option value="" selected="selected"></option>
                <option *ngFor="let p of benefitTypeList" [ngValue]="p.id">
                  {{ p.name }}
                </option>
              </select>
            </div>
            <div class="col-xs-6">
              <label>{{ trans.add_benefits_model.would_you_like_to_use_a_different_name_for_tco | translate }}</label>
              <input type="text" class="form-control m-t-xxs" id="tcoName" placeholder="Enter Name" name="tcoName" [(ngModel)]="tcoName" />
            </div>
          </div>
          <div class="row" *ngIf="impactAreas && impactAreas.length">
            <div class="col-xs-12">
              <label>{{ trans.add_benefits_model.impacts_which_business_areas | translate }}</label
              ><br />
              <p-autoComplete
                *ngIf="impactAreas && impactAreas.length"
                [multiple]="true"
                name="impactAreas"
                placeholder="Type here"
                field="name"
                [(ngModel)]="selectedImpactAreas"
                [suggestions]="filteredImpactAreas"
                (completeMethod)="completeImpactAreaFilter($event)"
                [ngStyle]="{ width: '90% !important' }"
              >
              </p-autoComplete>
            </div>
          </div>

          <div class="row" *ngIf="painPoints && painPoints.length">
            <div class="col-xs-12">
              <label>{{ trans.add_benefits_model.pain_points | translate }}</label
              ><br />
              <p-autoComplete
                *ngIf="painPoints && painPoints.length"
                [multiple]="true"
                name="PainPoints"
                placeholder="Type here"
                field="name"
                [(ngModel)]="selectedPainPoints"
                [suggestions]="filteredPainPoints"
                (completeMethod)="completePainPointsFilter($event)"
                [ngStyle]="{ width: '90% !important' }"
              >
              </p-autoComplete>
            </div>
          </div>

          <div class="row" *ngIf="capabilities && capabilities.length">
            <div class="col-xs-12">
              <label>{{ trans.add_benefits_model.capabilities | translate }}</label
              ><br />
              <p-autoComplete
                *ngIf="capabilities && capabilities.length"
                [multiple]="true"
                name="Capabilities"
                placeholder="Type here"
                field="name"
                [(ngModel)]="selectedCapabilities"
                [suggestions]="filteredCapabilities"
                (completeMethod)="completeCapabilitiesFilter($event)"
                [ngStyle]="{ width: '90% !important' }"
              >
              </p-autoComplete>
            </div>
          </div>

          <div class="row" *ngIf="opObjectives && opObjectives.length">
            <div class="col-xs-12">
              <label>{{ trans.add_benefits_model.operational_objectives | translate }}</label
              ><br />
              <p-autoComplete
                *ngIf="opObjectives && opObjectives.length"
                [multiple]="true"
                name="OpObjectives"
                placeholder="Type here"
                field="name"
                [(ngModel)]="selectedOpObjectives"
                [suggestions]="filteredOpObjectives"
                (completeMethod)="completeOpObjectivesFilter($event)"
                [ngStyle]="{ width: '90% !important' }"
              >
              </p-autoComplete>
            </div>
          </div>

          <div class="row" *ngIf="driverFactorList && driverFactorList.length">
            <div class="col-xs-12">
              <h4>{{ trans.add_benefits_model.driver_factor | translate }}</h4>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.driverFactor?.invalid && addBenefitsForm.controls.driverFactor?.touched }">
                <label for="driverFactor" class="labelsRequired">{{ trans.add_benefits_model.driver_factor | translate }}</label>
                <div class="driverInline">
                  <p-dropdown appendTo="body"
                    class="keywest_dropdown"
                    name="driverFactor"
                    [autoDisplayFirst]="false"
                    [options]="driverFactorList"
                    [(ngModel)]="driverFactor"
                    [filter]="true"
                    optionLabel="name"
                    [required]="true"
                    class="keywest_dropdown"
                  ></p-dropdown>
                </div>
                <div class="btnInline">
                  <a *ngIf="canEdit" class="driverFactor" (click)="createFactor = !createFactor">
                    <span class="icon-plus"></span>
                  </a>
                </div>
              </div>
              <div class="form-group clearfix" id="createDriverFactor" *ngIf="createFactor">
                <label for="createDriverFactor"> {{ trans.add_benefits_model.create_driver_factor | translate }} </label>
                <input type="text" name="createDriverFactor" class="form-control m-t-xxs driverInline" id="createDriverFactor" [(ngModel)]="newDriverFactor" />
                <span class="icon-check btnInline" *ngIf="canEdit && !showActionLoader" (click)="createDriverFactor()"></span>
                <span class="btnInline">
                  <img class="actionLoader" *ngIf="showActionLoader" src="{{ fullImagePath }}" />
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.driverBaselineAnswer?.invalid && addBenefitsForm.controls.driverBaselineAnswer?.touched }">
                <label for="driverBaselineAnswer" class="labelsRequired">{{ trans.add_benefits_model.answer | translate }}</label>
                <input type="text" name="driverBaselineAnswer" class="form-control m-t-xxs" id="driverBaselineAnswer" [(ngModel)]="driverBaselineAnswer" required />
              </div>
            </div>
            <div class="col-md-4">
              <label>{{ trans.add_benefits_model.seed_growth_decline | translate }}</label
              ><br />
              <p-inputSwitch name="has_phasing" [(ngModel)]="hasPhasing"></p-inputSwitch>
              {{ hasPhasing ? 'Yes' : 'No' }}
            </div>
          </div>
          <div class="row" *ngIf="hasPhasing">
            <div class="col-xs-12">
              <div class="phase-sliders">
                <div class="col-sm-10 phase-slider-list" *ngFor="let driveTerm of driverTerm; let i = index">
                  <div class="col-sm-2">Year {{ i + 1 }}</div>
                  <div class="col-sm-8">
                    <input type="range" name="driveTerm{{ i }}" [min]="driveTerm.min" [max]="driveTerm.max" [(ngModel)]="driveTerm.val" [step]="driveTerm.increment" />
                  </div>
                  <div class="col-sm-2">
                    <input type="text" name="driveTerm{{ i }}" [(ngModel)]="driveTerm.val" class="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="improvementTypeList && improvementTypeList.length">
            <div class="col-xs-12">
              <h4>{{ trans.add_benefits_model.improvement_factor | translate }}</h4>
            </div>
            <div class="col-md-4">
              <div
                class="form-group"
                [ngClass]="{ 'has-error': addBenefitsForm.controls.selectedImprovementType?.invalid && addBenefitsForm.controls.selectedImprovementType?.touched }"
              >
                <label class="labelsRequired">Improvement</label>
                <div class="driverInline">
                  <p-dropdown appendTo="body"
                    class="keywest_dropdown"
                    name="selectedImprovementType"
                    [autoDisplayFirst]="false"
                    [options]="improvementTypeList"
                    [(ngModel)]="selectedImprovementType"
                    [filter]="true"
                    optionLabel="name"
                    class="keywest_dropdown"
                    [required]="true"
                  ></p-dropdown>
                </div>
                <div class="btnInline">
                  <a class="financialFactor" *ngIf="canEdit" (click)="createImprovementFlag = !createImprovementFlag">
                    <span class="icon-plus"></span>
                  </a>
                </div>
              </div>
              <div class="form-group clearfix" id="createImprovementFactor" *ngIf="createImprovementFlag">
                <label for="createImprovementFactor" class="labels">{{ trans.props.tabDetails.benefits.Add.createImprovementFactor | translate }}</label>
                <input type="text" name="createImprovementFactor" class="form-control m-t-xxs driverInline" id="createImprovementFactor" [(ngModel)]="newImprovementFactor" />
                <span class="icon-check btnInline" *ngIf="canEdit && !showImprovementActionLoader" (click)="createImprovementFactor()"></span>
                <span class="btnInline">
                  <img class="actionLoader" *ngIf="showImprovementActionLoader" src="{{ fullImagePath }}" />
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.unitType?.invalid && addBenefitsForm.controls.unitType?.touched }">
                <label for="improvementFactor" class="labelsRequired">{{ trans.props.tabDetails.benefits.Add.units | translate }}</label>
                <select *ngIf="unitTypeList && unitTypeList.length" class="form-control m-t-xxs" name="unitType" [(ngModel)]="unitType" (change)="checkCurrentEffort()" required>
                  <option value="" selected="selected"></option>
                  <option *ngFor="let s of unitTypeList" [ngValue]="s.id">
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.conservative?.invalid && addBenefitsForm.controls.conservative?.touched }">
                <label for="" class="labelsRequired">{{ trans.add_benefits_model.improvement_conservative | translate }} </label>
                <input type="text" name="conservative" class="form-control m-t-xxs" id="" [(ngModel)]="conservative" required />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.probable?.invalid && addBenefitsForm.controls.probable?.touched }">
                <label for="porbable" class="labelsRequired">{{ trans.add_benefits_model.probable | translate }}</label>
                <input type="text" name="porbable" class="form-control m-t-xxs" id="porbable" placeholder="" [(ngModel)]="porbable" required />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.aggressive?.invalid && addBenefitsForm.controls.aggressive?.touched }">
                <label for="aggressive" class="labelsRequired">{{ trans.add_benefits_model.aggressive | translate }}</label>
                <input type="text" name="aggressive" class="form-control m-t-xxs" id="aggressive" placeholder="" [(ngModel)]="aggressive" required />
              </div>
            </div>
            <div class="col-md-4">
              <div *ngIf="accountFeatures && showCurrentEffort" class="form-group">
                <label for="">{{ trans.add_benefits_model.current_effort | translate }}</label>
                <input type="text" class="form-control m-t-xxs" id="currentEffort" placeholder="Enter Current Effort" name="currentEffort" [(ngModel)]="currentEffort" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="financialDriverFactorList && financialDriverFactorList.length">
            <div class="col-xs-12">
              <h4>{{ trans.add_benefits_model.financial_factor | translate }}</h4>
            </div>
            <div class="col-md-4">
              <div
                class="form-group"
                [ngClass]="{ 'has-error': addBenefitsForm.controls.financialDriverFactor?.invalid && addBenefitsForm.controls.financialDriverFactor?.touched }"
              >
                <label for="financialFactorType" class="labelsRequired"> {{ trans.add_benefits_model.financial_factor_type | translate }}</label>
                <div class="driverInline">
                  <p-dropdown appendTo="body"
                    class="keywest_dropdown"
                    name="financialDriverFactor"
                    [autoDisplayFirst]="false"
                    [options]="financialDriverFactorList"
                    [(ngModel)]="financialDriverFactor"
                    [filter]="true"
                    optionLabel="name"
                    class="keywest_dropdown"
                    [required]="true"
                  ></p-dropdown>
                </div>
                <div class="btnInline">
                  <a class="financialFactor" *ngIf="canEdit" (click)="createDriverFlag = !createDriverFlag">
                    <span class="icon-plus"></span>
                  </a>
                </div>
              </div>
              <div class="form-group clearfix" id="createFinancialFactor" *ngIf="createDriverFlag">
                <label for="createFinancialFactor" class="labels">{{ trans.add_benefits_model.create_financial_factor | translate }} </label>
                <input type="text" name="createFinancialFactor" class="form-control m-t-xxs driverInline" id="createFinancialFactor" [(ngModel)]="FinancialFactor" />
                <span class="icon-check btnInline" *ngIf="canEdit && !showActionLoader" (click)="createFinancialFactor()"></span>
                <span class="btnInline">
                  <img class="actionLoader" *ngIf="showActionLoader" src="{{ fullImagePath }}" />
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" [ngClass]="{ 'has-error': addBenefitsForm.controls.financicalAnswer?.invalid && addBenefitsForm.controls.financicalAnswer?.touched }">
                <label for="financicalAnswer" class="labelsRequired">{{ trans.add_benefits_model.answer | translate }}</label>
                <input type="text" name="financicalAnswer" class="form-control m-t-xxs" id="financicalAnswer" [(ngModel)]="financicalAnswer" required />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="phase-sliders">
              <div class="phase-title m-l-xxs">{{ trans.props.tabDetails.benefits.benefits_phasing | translate }}</div>
              <div class="col-sm-10 phase-slider-list" *ngFor="let term of phasingTerm; let i = index">
                <div class="col-sm-2">Year {{ i + 1 }}</div>
                <div class="col-sm-8">
                  <input type="range" name="term{{ i }}" [min]="0" [max]="100" [(ngModel)]="term.val" [step]="term.increment" />
                </div>
                <div class="col-sm-2">
                  <input type="text" name="term{{ i }}" [(ngModel)]="term.val" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary m-t-xs m-b-xs"
            *ngIf="canEdit"
            (click)="activeModal.close('Cross click'); saveBenefits(addBenefitsForm)"
            [disabled]="addBenefitsForm.invalid"
          >
            {{ trans.add_benefits_model.save | translate }}
          </button>
          <div class="pull-right">
            <label class="labelsRequiredInfo">{{ trans.props.tabDetails.benefits.is_required | translate }}</label>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
