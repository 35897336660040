import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-add-master-value-prop',
  templateUrl: './add-master-value-prop.component.html',
  styleUrls: ['./add-master-value-prop.component.scss']
})
export class AddMasterValuePropComponent {

  @ViewChild('stepMoveBackConfirmation') stepMoveBackConfirmation: ElementRef;

}
