import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { ReviewBaselineTranslation } from '../review-baseline/review-baseline.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { BusinessReviewData, BusinessReviewDataWithNote } from '@data/services/valuerealization/models/business-review.model';
import { ErrorMessagesService } from '@services/error-messages.service';
import { TrackerNote } from '@data/services/valuerealization/models/value-realized-company.model';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
export type TranslationStrings = keyof typeof ReviewBaselineTranslation.prototype.trans;

@Component({
  selector: 'app-business-review-notes-sidebar',
  templateUrl: './business-review-notes-sidebar.component.html',
  styleUrls: ['./business-review-notes-sidebar.component.scss'],
})
export class BusinessReviewNotesSidebarComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() objectId: string | number;
  @Input() section: string;
  @Output() backClicked = new EventEmitter();

  noteControl = new FormControl('', [Validators.maxLength(3000)]);
  loadingNoteSave = false;

  selectedBr: BusinessReviewData;
  businessReviews: BusinessReviewData[];
  businessReviewsCache: BusinessReviewData[];

  notesErrorMessage$ = this.errorMessagesService.formContrlObsFactory(this.noteControl, 'noteControl');
  newNote: TrackerNote;
  editingBr: BusinessReviewDataWithNote;
  private unsubscribe$ = new Subject<void>();
  uid = sessionStorage.getItem('uid');

  constructor(
    public trans: ReviewBaselineTranslation,
    private translationService: TranslationsV2Service,
    private valueRealizationService: ValuerealizationService,
    public businessReviewService: TrackerBusinessReviewService,
    public errorMessagesService: ErrorMessagesService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.businessReviewService.businessReviews$.pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response) {
        this.businessReviews = response;
        this.businessReviewsCache = [...response];
        this.getBrWithNotes();
      }
    });
  }

  getBrWithNotes() {
    this.businessReviewService.brsWithNotes$.pipe(takeUntil(this.unsubscribe$)).subscribe((brWithNotes) => {
      if (brWithNotes) {
        if (brWithNotes.length === 0) {
          this.businessReviews = [...this.businessReviewsCache];
          return;
        }
        const newArray = this.businessReviewsCache.filter((br) => {
          return !brWithNotes.some((brN) => brN.id === br.id);
        });
        this.selectedBr = null;
        this.businessReviews = [...newArray];
      }
    });
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
      });
  }

  addNote() {
    const payload = {
      value_prop_id: this.valuePropId,
      business_review_id: this.selectedBr.id,
      customer_facing: 1,
      section: this.section,
      object_id: +this.objectId,
      note: this.noteControl.value,
    };
    this.valueRealizationService
      .saveTrackerNote(this.valuePropId, payload)
      .pipe(
        take(1),
        finalize(() => (this.loadingNoteSave = false))
      )
      .subscribe(
        (response) => {
          if (response) {
            this.createNewNote(response);
            this.notify('noteSaved');
          }
        },
        () => {
          this.notify('noteSaveError', 'error');
        }
      );
  }

  back() {
    this.backClicked.emit();
  }

  createNewNote(response) {
    this.newNote = {
      id: response.note_id,
      value_prop_id: +this.valuePropId,
      business_review_id: this.selectedBr.id,
      user_id: +this.uid,
      customer_facing: 1,
      section: this.section,
      object_id: +this.objectId,
      note: this.noteControl.value,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };
    this.noteControl.reset();
  }

  editNote() {
    const payload = {
      value_prop_id: this.valuePropId,
      business_review_id: this.editingBr.id,
      customer_facing: 1,
      section: this.section,
      object_id: +this.objectId,
      note: this.noteControl.value,
      note_id: +this.editingBr.noteId,
    };
    this.valueRealizationService
      .updateTrackerNote(this.valuePropId, payload)
      .pipe(
        take(1),
        finalize(() => (this.loadingNoteSave = false))
      )
      .subscribe(
        (response) => {
          if (response) {
            this.newNote = {
              id: payload.note_id,
              value_prop_id: +this.valuePropId,
              business_review_id: this.editingBr.id,
              user_id: +this.uid,
              customer_facing: 1,
              section: this.section,
              object_id: 10,
              note: this.noteControl.value,
              created: new Date().toISOString(),
              modified: new Date().toISOString(),
            };
            this.noteControl.reset();
            this.notify('noteSaved');
            this.editingBr = null;
          }
        },
        () => {
          this.notify('noteSaveError', 'error');
        }
      );
  }

  cancelEdit() {
    this.editingBr = null;
    this.noteControl.reset();
  }

  setEditNote(br: BusinessReviewDataWithNote) {
    this.editingBr = br;
    this.noteControl.setValue(br.note);
  }

  private notify(transString: TranslationStrings, type: 'error' | 'success' | 'warning' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
