<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div [class.keywest_header_small]="!(style2022$ | async)">
      <div class="row">
        <div *ngIf="!(style2022$ | async)" class="col-xs-10">
          <div class="title">
            {{ trans.trans.heading.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.heading"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-2 w-100">
          <span
            *ngIf="style2022$ | async"
            [class.link]="style2022$ | async"
            class="pull-right clickable"
            matTooltipPosition="below"
            container="body"
            routerLink="/full-profile"
            >{{trans.trans.edit.value}}</span
          >
          <i *ngIf="!(style2022$ | async)" (click)="showProfileFullView()" style="font-size: 18px" class="keywest_header_icon fa fa-pencil pull-right clickable"></i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12" *ngIf="user">
        <div class="row-maui-no-margin sub-profile-panel" [class.left_nav_user]="!(style2022$ | async)">
          <img [src]="user.profileImagePath !== '' ? user.profileImagePath : user.profilepicdefaultpath" class="img-responsive small-profile-image" />
          <span [class.title]="!(style2022$ | async)" class="settings-title-big margin-12-y" > {{ user.first_name }} {{ user.last_name }} </span>
          <span class="sub">{{ user.role_name }}</span>
        </div>
        <div *ngIf="style2022$ | async" class="row-maui flex-jcc marg-v">
          <div class="col-60-maui">
            <div class="col-xs-9 w-100 col-50-maui" *ngIf="user.mobile">
              <p>
                <span class="strong-text">Mobile:</span>
                <span class="ml-2 muted-t"> {{ user.mobile }}</span>
              </p>
            </div>
            <div class="col-xs-9 w-100 col-50-maui">
              <p>
                <span class="strong-text">E-Mail:</span>
                <span class="ml-2 muted-t"> {{ user.email }}</span>
              </p>
            </div>
            <div class="col-xs-9 w-100 col-50-maui" *ngIf="user.manager_email">
              <p class="text-nowrap">
                <span class="strong-text">Manager E-Mail:</span>
                <span class="ml-2 muted-t"> {{ user.manager_email }}</span>
              </p>
            </div>
          </div>
        </div>
        <ul *ngIf="!(style2022$ | async)" class="list-unstyled">
          <li *ngIf="user.city && user.city != 'false' && user.state && user.state != 'false'">
            <i class="icon-pointer m-r-xs"></i>
            <span *ngIf="user.city && user.city != 'false'">{{ user.city }}, </span>
            <span *ngIf="user.state && user.state != 'false'">{{ user.state }}</span>
          </li>
          <li>
            <i class="fa fa-envelope"></i><a href="mailto:{{ user.email }}">{{ user.email }}</a>
          </li>
          <li *ngIf="user.manager_email">
            <a href="mailto:{{ user.manager_email }}">{{ user.manager_email }}</a>
          </li>
          <li *ngIf="user.mobile">
            <i class="fa fa-mobile" aria-hidden="true"></i>
            <span class="blue-link-color">{{ user.mobile }}</span>
          </li>
          <li *ngIf="user.phone">
            <i class="fa fa-phone"></i>
            <span class="blue-link-color">{{ user.phone }}</span>
          </li>
        </ul>
        <hr *ngIf="!(style2022$ | async)" />
        <ul *ngIf="!(style2022$ | async)" class="list-unstyled">
          <li>
            <i class="fa fa-lock"></i>
            <span>**********</span>
          </li>
        </ul>
      </div>
      <div *ngIf="!user">
        <p class="text-center"><img class="loader" src="{{ fullImagePath }}" /></p>
      </div>
    </div>
  </div>
</div>
