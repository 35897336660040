export const smallLineChartConf = {
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
  },

  legend: {
    display: false,
  },

  animation: {
    duration: 500,
  },
  responsive: true
};