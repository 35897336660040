import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SolutionComponent } from './solution.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuModule } from 'primeng/menu';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	imports: [
		MenuModule,
    MatTooltipModule,
		NgbModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		ApplicationPipesModule,
		
	],
	declarations: [

		SolutionComponent
	],
	exports: [SolutionComponent]
})
export class SolutionModule { }
