import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AdminUserGroupsService } from '@data/services/account/user_groups.service';
import { UserGroupsProfileTranslations } from './groups.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { UserGroup } from '@shared/models/account-groups.models';
import { MenuItem } from 'primeng/api/menuitem';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessagesService } from '@services/error-messages.service';
import { NotificationService } from '@services/notification.service';
import { UserService } from '@data/services/user/user.service';
import { BasicUserData } from '@shared/models/user-info.model';
import { alphabeticSort } from '@shared/utils';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-admin-user-groups',
  styleUrls: ['./groups.component.scss'],
  templateUrl: './groups.component.html',
})
export class AdminUserGroupsComponent implements OnInit, OnDestroy {
  userGroups: UserGroup[] = [];
  account_id: string;

  menu: MenuItem[] = [];
  showTranslate = false;

  rowLimit: MenuItem[] = [];
  strSearch = '';
  pageLimit = 10;

  selectedGroup: Partial<UserGroup>;
  mode: 'add' | 'edit';
  showSidebar = false;
  sidebarTitle: { value: string };
  breadcrumbKeyValues: Breadcrumbs;

  ngUnsubscribe = new Subject();

  style2022$: Observable<boolean>;
  userGroupForm: FormGroup;
  errorDict = new Map();
  isLoading = false;
  userData: BasicUserData[];
  existingGroupMembers: BasicUserData[];
  selectedMembers: BasicUserData[] = [];
  availableUsers: BasicUserData[];
  selectedUsers: BasicUserData[] = [];
  usersLoading = false;
  fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';

  constructor(
    private adminUserGroups: AdminUserGroupsService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: UserGroupsProfileTranslations,
    private styleService: StyleService,
    private fb: FormBuilder,
    private errorMessageService: ErrorMessagesService,
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');
    this.getGroups();
    this.buildPageLimit();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.userGroupForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      id: [''],
    });
    this.userGroupForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.userGroupForm.controls));
    });

    this.errorMessageService.errorTranslationsComplete$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      if (value) {
        this.errorDict = new Map(this.errorMessageService.getFormErrors(this.userGroupForm.controls));
      }
    });

    this.getUserList();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: this.account_id,
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.manage_user_group.value]: { routerLink: '', active: true },
        };
      });
  }

  setRowLimit(num: number) {
    this.pageLimit = num;
    this.buildPageLimit();
  }

  addGroup() {
    this.showSidebar = true;
    this.mode = 'add';
    this.sidebarTitle = this.trans.trans.addUserGroup;
    this.availableUsers = cloneDeep(this.userData);
    this.existingGroupMembers = [];
  }

  toggleEdit(group: Partial<UserGroup>) {
    this.showSidebar = true;
    this.mode = 'edit';
    this.userGroupForm.patchValue(group);
    this.sidebarTitle = this.trans.trans.editUserGroup;
    this.getUsersByGroup(group.id);
  }

  buildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  getGroups() {
    this.adminUserGroups.getGroups(this.account_id).subscribe((res) => {
      if (res.result) {
        this.userGroups = res.result;
      }
    });
  }

  save() {
    this.isLoading = true;
    const payload = {
      name: this.userGroupForm.value.name,
      description: this.userGroupForm.value.description,
      users: this.existingGroupMembers,
    };

    this.adminUserGroups.postGroup(this.account_id, payload).subscribe((res) => {
      if (res.result) {
        this.notificationService.success(this.trans.trans.successfullyAdded.value, false);
      } else {
        this.notificationService.success(this.trans.trans.somethingWeentWrong.value, false);
      }

      this.getGroups();
      this.showSidebar = false;
      this.isLoading = false;
    });
  }

  deleteGroup(group: Partial<UserGroup>) {
    this.adminUserGroups.deleteGroup(this.account_id, group.id).subscribe((res) => {
      if (res.result) {
        this.notificationService.success(this.trans.trans.successfullyDeleted.value, false);
      } else {
        this.notificationService.success(this.trans.trans.somethingWeentWrong.value, false);
      }

      this.getGroups();
    });
  }

  saveEdit() {
    this.isLoading = true;
    const payload = {
      account_geo_id: this.userGroupForm.value.id,
      name: this.userGroupForm.value.name,
      description: this.userGroupForm.value.description,
      users: this.existingGroupMembers,
    };
    this.adminUserGroups.putGroup(this.account_id, payload).subscribe((res) => {
      if (res.result) {
        this.notificationService.success(this.trans.trans.successfullyEdited.value, false);
      } else {
        this.notificationService.success(this.trans.trans.somethingWeentWrong.value, false);
      }
      this.getGroups();
      this.showSidebar = false;
      this.isLoading = false;
    });
  }

  cancelEdit(): void {
    this.showSidebar = false;
    this.mode = null;
    this.userGroupForm.reset();
    this.existingGroupMembers = [];
    this.selectedMembers = [];
    this.selectedUsers = [];
  }

  removeAllUsers(): void {
    const newList = [...this.availableUsers, ...this.existingGroupMembers];
    newList.sort((a, b) => {
      return alphabeticSort(a, b, 'name');
    });
    this.availableUsers = newList;
    this.existingGroupMembers = [];
    this.selectedMembers = [];
    this.selectedUsers = [];
  }

  removeUsers(): void {
    const newList = [...this.availableUsers, ...this.selectedMembers];
    newList.sort((a, b) => {
      return alphabeticSort(a, b, 'name');
    });
    this.availableUsers = newList;
    this.existingGroupMembers = this.existingGroupMembers.filter((user) => !this.selectedMembers.some((member) => member.id === user.id));
    this.selectedMembers = [];
  }

  addAllUsers(): void {
    const newList = [...this.existingGroupMembers, ...this.availableUsers];
    newList.sort((a, b) => {
      return alphabeticSort(a, b, 'name');
    });
    this.existingGroupMembers = newList;
    this.availableUsers = [];
    this.selectedMembers = [];
    this.selectedUsers = [];
  }

  addUsers(): void {
    const newList = [...this.existingGroupMembers, ...this.selectedUsers];
    newList.sort((a, b) => {
      return alphabeticSort(a, b, 'name');
    });
    this.existingGroupMembers = newList;
    this.availableUsers = this.availableUsers.filter((user) => !this.selectedUsers.some((member) => member.id === user.id));
    this.selectedMembers = [];
  }

  private filterExistingUsers(): void {
    this.availableUsers = cloneDeep(this.userData);
    this.availableUsers = this.availableUsers.filter((user) => !this.existingGroupMembers.some((groupUser) => groupUser.id === +user.id));
  }

  private getUserList(): void {
    this.userService.getAllUsersList().subscribe(
      (response) =>
        (this.userData = response.result.data.sort((a, b) => {
          return alphabeticSort(a, b, 'name');
        }))
    );
  }

  private getUsersByGroup(groupId: string): void {
    this.usersLoading = true;
    this.adminUserGroups.readUsersByGroup(groupId).subscribe({
      next: (response) => {
        if (response.result.success) {
          this.existingGroupMembers = response.result.users.sort((a, b) => {
            return alphabeticSort(a, b, 'name');
          });
          this.filterExistingUsers();
        } else {
          this.notificationService.error(this.trans.trans.groupError.value, false);
        }
      },
      error: () => this.notificationService.error(this.trans.trans.groupError.value, false),
      complete: () => (this.usersLoading = false),
    });
  }
}
