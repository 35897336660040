<div class="row" *ngIf="style2022$ | async">
    <div class="col-md-4 mr-auto breadcrumb-container">
        <app-breadcrumbs *ngIf="breadcrumbKeyValues" [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
    </div>
</div>

<div class="row" *ngIf="!(style2022$ | async)">
    <div class="col-md-10">
        <a class="back-profile" [routerLink]="(style2022$ | async) ? ['/settings'] : ['/profile']"><i
                class="fa fa-chevron-left"></i> {{ (style2022$ | async) ? 'Back to Settings' :
            trans.trans.backToProfile.value }}</a>
    </div>
</div>
<!-- Page Wrapper -->
<div>
    <!-- Row 1 -->
    <div class="row card-wrapper mxy" *ngIf="manageSettingsLinks.length">
        <!-- Manage settings Card -->
        <div class="col-xs-12" >
            <div class="row title-row">
                <h1 class="settings-card-title">{{trans.trans.manageSettings.value}}</h1>
            </div>
            <!-- Content Row -->
            <div class="row" *ngIf="manageSettingsLinks.length">
                <app-settings-link-card [link]="link" *ngFor="let link of manageSettingsLinks"
                    class="col-xs-12 col-md-6 col-lg-3"></app-settings-link-card>
            </div>
        </div>
    </div>
    <!-- Row 2 -->
    <div class="row my">
        <!-- Column One -->
        <div class="col-xs-12 col-lg-6" *ngIf="reportLinks.length">
            <!-- Reports -->
            <div class="col-xs-12 card-wrapper my">
                <div class="row title-row">
                    <h1 class="settings-card-title">{{trans.trans.Reports.value}}</h1>
                </div>
                <!-- Content Row -->
                <div class="row">
                    <app-settings-link-card [link]="link" *ngFor="let link of reportLinks"
                    class="col-xs-12 col-md-6"></app-settings-link-card>
                </div>
            </div>
        </div>
        <!-- Column Two -->
        <div class="col-xs-12 col-lg-6" *ngIf="designCenterLinks.length">
            <!-- Design Center -->
            <div class="col-xs-12 card-wrapper my">
                <div class="row title-row">
                    <h1 class="settings-card-title">{{trans.trans.customerFacingElements.value}}</h1>
                </div>
                <!-- Content Row -->
                <div class="row">
                    <app-settings-link-card [link]="link" *ngFor="let link of designCenterLinks"
                    class="col-xs-12 col-md-6"></app-settings-link-card>
                </div>
            </div>
        </div>
    </div>
    <!-- Row 3 -->
    <div class="row my">
        <!-- Column One -->
        <div class="col-xs-12 col-lg-6 bottom-spacer" *ngIf="advancedFeaturesLinks.length">
            <!-- Advanced Features -->
            <div class="col-xs-12 card-wrapper">
                <div class="row title-row">
                    <h1 class="settings-card-title">{{trans.trans.advancedFeatures.value}}</h1>
                </div>
                <!-- Content Row -->
                <div class="row">
                    <app-settings-link-card [link]="link" *ngFor="let link of advancedFeaturesLinks"
                    class="col-xs-12 col-md-6"></app-settings-link-card>
                </div>
            </div>
        </div>
        <!-- Column Two -->
        <div class="col-xs-12 col-lg-6" *ngIf="localizationLinks.length">
            <!-- Localization -->
            <div class="col-xs-12 card-wrapper">
                <div class="row title-row">
                    <h1 class="settings-card-title">{{trans.trans.localizationCenter.value}}</h1>
                </div>
                <!-- Content Row -->
                <div class="row">
                    <app-settings-link-card [link]="link" *ngFor="let link of localizationLinks"
                    class="col-xs-12 col-md-6"></app-settings-link-card>
                </div>
            </div>
        </div>
    </div>
</div>