<div class="row">
  <div class="col-xs-6">
    <h3 class="title">{{ trans.models_v2.situations | translate }}</h3>
  </div>

  <div class="col-xs-6">
    <a (click)="funcAddSituation()" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i> Add</a>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <div class="well well-info">
      <p>{{ trans.models_v2.create_and_edit | translate }}</p>
    </div>
  </div>
</div>

<table class="table table-responsive">
  <thead>
    <tr>
      <th>Situation Type</th>
      <th>Order</th>
      <th></th>
    </tr>
  </thead>

  <tbody cdkDropList (cdkDropListDropped)="drop($event, situations)" class="dragObjectsParent">
    <tr *ngFor="let elem of situations" cdkDrag class="dragObjects">
      <td>{{ elem.name }}</td>
      <td>{{ elem.stack_order }}</td>
      <td>
        <div class="pull-right">
          <a (click)="funcToggleAddEdit(elem)" class="clickable" style="margin-right: 10px"><i class="fa fa-pencil"></i> Edit</a>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<p-sidebar [(visible)]="toggleSituationAddEdit" styleClass="thresholds_sidebar p-sidebar-lg" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ mode == 'edit' ? 'Edit Situation' : 'Add Situation' }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label">Name</label>
            <input type="text" name="name" [(ngModel)]="objSituation.name" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label">Description</label>
            <textarea name="description" [(ngModel)]="objSituation.description" rows="4" class="form-control"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label">Type</label><br />
            <fieldset class="radio-control marg-v">
              <div>
                <label>
                  <input type="radio" name="type" value="1" [(ngModel)]="objSituation.type" />
                  <span> Radio<br /> </span>
                </label>
              </div>
            </fieldset>

            <fieldset class="radio-control marg-v">
              <div>
                <label>
                  <input type="radio" name="type" value="2" [(ngModel)]="objSituation.type" />
                  <span> Checkbox<br /> </span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <a *ngIf="mode == 'edit'" (click)="funcUpdateSituation()" class="btn btn_customizable">Update</a>
            <a *ngIf="mode == 'add'" (click)="funcSaveSituation()" class="btn btn_customizable">Save</a>
          </div>
        </div>

        <div class="row" *ngIf="situationTree && situationTree.length">
          <div class="col-xs-12">
            <p-treeTable [value]="situationTree">
              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th style="width: 100px"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr [ttRow]="rowNode">
                  <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{ rowData.name }}
                  </td>
                  <td>
                    <span
                      *ngIf="!rowData.is_situation_type"
                      matTooltip="Delete Child Node"
                      matTooltipPosition="above"
                      (click)="funcDeleteChild(rowData)"
                      class="pull-right clickable"
                      style="margin-right: 10px"
                      ><i class="fa fa-times"></i
                    ></span>
                    <span
                      *ngIf="!rowData.is_situation_type"
                      matTooltip="Edit Child Node"
                      matTooltipPosition="above"
                      (click)="funcEditChild(rowData)"
                      class="pull-right clickable"
                      style="margin-right: 10px"
                      ><i class="fa fa-pencil"></i
                    ></span>
                    <span (click)="funcToggleAddEditChild(rowData)" matTooltip="Add Child Node" matTooltipPosition="above" class="pull-right clickable" style="margin-right: 10px"
                      ><i class="fa fa-plus"></i
                    ></span>
                  </td>
                </tr>
                <tr *ngIf="rowData.expanded">
                  <td colspan="2">
                    <h3>{{ modeChild == 'add' ? 'Add' : 'Edit' }} Child <i (click)="rowData.expanded = !rowData.expanded" class="fa fa-times clickable"></i></h3>
                    <div class="row">
                      <div class="col-xs-12">
                        <label>Name</label>
                        <input type="text" name="name" [(ngModel)]="rowData.childName" class="form-control" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12">
                        <label>Description</label>
                        <textarea name="description" [(ngModel)]="rowData.childDescription" rows="4" class="form-control"></textarea>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12">
                        <a *ngIf="modeChild == 'edit'" (click)="funcUpdateChild(rowData)" class="btn btn_customizable">Update</a>
                        <a *ngIf="modeChild == 'add'" (click)="funcAddChild(rowData)" class="btn btn_customizable">Add</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-treeTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
