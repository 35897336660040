import { NgModule } from '@angular/core';

import { AEBenefitsComponent } from './ae_benefits.component';
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';

import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';

import { PainPointsService } from '@data/services/pain_points/pain.service';
import { CapabilitiesService } from '@data/services/capabilities/capabilities.service';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { FunctionalObjectivesService } from '@data/services/func_objectives/func_objectives.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DropdownModule } from 'primeng/dropdown';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { DLListDetailModule } from '@shared_components/list_detail/list_detail.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { AEFactorsFormModule } from 'app/value-map-maintenance/views/factors/ae_factors/ae_factors.module';
import { DLDragDropModule } from '@shared_components/dd_file_upload/upload.module';
import { AEScratchpadsModule } from 'app/value-map-maintenance/views/factors/ae_scratchpads/ae_scratchpads.module';
import { RScratchpadsModule } from 'app/value-map-maintenance/views/factors/r_scratchpads/r_scratchpads.module';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AEBenefitsTranslations } from './ae_benefits.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { BenefitTypesService } from '@data/services/benefit-types/benefit-types.service';

import { SliderModule } from 'primeng/slider';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from 'app/_directives/directives.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';

@NgModule({
  declarations: [AEBenefitsComponent],
  imports: [
    CheckboxModule,
    RScratchpadsModule,
    AEScratchpadsModule,
    DLDragDropModule,
    MatTooltipModule,
    AEFactorsFormModule,
    ProgressBarModule,
    DLListDetailModule,
    DLNGErrorDirectiveModule,
    DropdownModule,
    NGFormSaveButtonModule,
    InputSwitchModule,
    AutoCompleteModule,
    MenuModule,
    SidebarModule,
    TableModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    EditTranslationsModule,
    SliderModule,
    AreYouSureModule,
    DirectivesModule,
    FunctionPipeModule,
    ReactiveFormsModule,
    ErrorInputWrapperModule,
  ],
  providers: [
    PainPointsService,
    CapabilitiesService,
    OperationalObjectivesService,
    FunctionalObjectivesService,
    TranslationsV2Service,
    AEBenefitsTranslations,
    BenefitTypesService,
  ],
  entryComponents: [AEBenefitsComponent],
  exports: [AEBenefitsComponent],
})
export class ModelAEBenefits {}
