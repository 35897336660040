import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { TeamMember } from '@data/services/valueprop/models/teamMember.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TeamTranslation } from './team.translation';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  providers: [UserService],
})
export class TeamComponent implements OnInit, OnDestroy {
  teamMembersList: TeamMember[];
  profilepicdefaultpath = 'https://media.value-cloud.com/images/default_user.png';

  style2022$: Observable<boolean>;

  @Output() toggle: EventEmitter<null> = new EventEmitter();

  subscriptiongetteamUsersList: Subscription;
  subscriptiongetContextualHelp: Subscription;
  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    private userService: UserService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: TeamTranslation,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;

    this.getTeamMemberList();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptiongetteamUsersList) {
      this.subscriptiongetteamUsersList.unsubscribe();
    }
    if (this.subscriptiongetContextualHelp) {
      this.subscriptiongetContextualHelp.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getTeamMemberList(): void {
    this.subscriptiongetteamUsersList = this.userService.getteamUsersList().subscribe((result) => {
      this.teamMembersList = result.result.team_members;
    });
  }
  showTeamFullView(): void {
    this.toggle.emit();
  }
}
