import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { CaseStudySection } from '@shared/models/case-study.model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RSectionsTranslations } from './r_sections.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { HttpClient } from '@angular/common/http';
import { VideoEmbedService } from '@services/video-embed.service';

@Component({
  selector: 'app-r-sections',
  templateUrl: './r_sections.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RSectionsComponent implements OnInit, OnDestroy {
  @Input() sectionEdit: CaseStudySection;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  overlayImgVideo = {
    type: undefined,
    path: undefined,
    description: undefined,
  };
  embeddedVideoURL: string;

  editOverlayImgVideo = {
    type: undefined,
    path: undefined,
  };
  editEmbeddedVideoIFrame: string;

  sidebar_title = 'View Section';
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    public trans: RSectionsTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
    private embedService: VideoEmbedService
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  setOverlayImageVideo(isEditList, sectionMedia, overlay: OverlayPanel, event) {
    if (isEditList) {
      this.editOverlayImgVideo = sectionMedia;
    }
    this.overlayImgVideo = sectionMedia;
    if (sectionMedia.type === 'link') {
      this.embeddedVideoURL = this.embedService.embed(sectionMedia.path);
    }
    overlay.toggle(event);
  }

  downAsset(imageUrl: string) {
    this.http
      .get(imageUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return {
            filename: imageUrl.substr(imageUrl.lastIndexOf('/') + 1),
            data: res,
          };
        })
      )
      .subscribe(
        (res) => {
          const url = window.URL.createObjectURL((res as any).data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        () => {},
        () => {}
      );
  }
}
