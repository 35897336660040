<div class="panel panel-white">
  <div class="panel-heading">
    <div class="panel-title">{{ trans.profile.trophies.heading | translate }}</div>
  </div>
  <div class="panel-body">
    <div class="trophies m-t-sm">
      <span class="m-r-xs" *ngFor="let trophy of trophiesList">
        <img src="https://media.value-cloud.com/images/jamaica/trophies/trophy_1.png" matTooltip="{{trophy.name}}"/>
      </span>
    </div>
  </div>
</div>