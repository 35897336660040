import { Injectable } from '@angular/core';


@Injectable ()

export class SituationTranslations {
    public config: any = {
        component: 'situation',
        display_name: 'Situation Solution',
        description: 'All the situations for a solution'
    };

    public trans: any = {
        situations: 'Situations',
        addNew: 'Add New',
        delete: 'Delete',
        view: 'View',
        edit: 'Edit',
        name: 'Name',
        back: 'Back',
        areYouSure: 'Are You Sure?',
        expandAll: 'Expand All',
        collapseAll: 'Collapse All',
    };
}
