import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueRealizedHomeComponent } from './views/value-realized-home/value-realized-home.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ValueRealizedRoutingModule } from './value-realized-routing.module';
import { ValueRealizedHomeTranslation } from './views/value-realized-home/value-realized-home.translation';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { CheckLogoPipeModule } from '@shared/pipes/check-logo.module';
import { TrackerBaselineComponent } from 'app/value-mapv2/solution-detail/tracker-baseline/tracker-baseline.component';
import { TrackerBaselineViewComponent } from './views/tracker-baselines/tracker-baseline-view.component';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ReviewBaselineComponent } from './views/review-baseline/review-baseline.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { EditNameModule } from '@shared_components/edit-name/edit-name.module';
import { ReviewBaselineBenefitsComponent } from './views/review-baseline-benefits/review-baseline-benefits.component';
import { ReviewBaselineGoalGroupsComponent } from './views/review-baseline-goal-groups/review-baseline-goal-groups.component';
import { TrackerBaselineViewTranslation } from './views/tracker-baselines/tracker-baseline-view.translation';
import { ReviewBaselineBenefitsTranslations } from './views/review-baseline-benefits/review-baseline-benefits.translation';
import { ReviewBaselineGoalGroupsTranslations } from './views/review-baseline-goal-groups/review-baseline-goal-groups.translation';
import { BaselineBenefitScratchpadComponent } from './views/baseline-benefit-scratchpad/baseline-benefit-scratchpad.component';
import { BaselineBenefitScratchpadTranslation } from './views/baseline-benefit-scratchpad/baseline-benefit-scratchpad.translation';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { SuccessCriteriaComponent } from './views/success-criteria/success-criteria.component';
import { SuccessCriteriaSidebarComponent } from './views/success-criteria-sidebar/success-criteria-sidebar.component';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ReviewBaselineTranslation } from './views/review-baseline/review-baseline.translation';
import { CompanyTrackersComponent } from './views/company-trackers/company-trackers.component';
import { CompanyTrackersTranslation } from './views/company-trackers/company-trackers.translation';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { CompanySearchComponent } from './views/company-search/company-search.component';
import { CompanySearchTranslation } from './views/company-search/company-search.translation';
import { TrackValueDashboardComponent } from './views/track-value-dashboard/track-value-dashboard.component';
import { TrackValueDashboardTranslation } from './views/track-value-dashboard/track-value-dashboard.translation';
import { RSV2ToolbarModule } from 'app/repv2/views/toolbar/toolbar.module';
import { BarLinechartModule } from 'app/common/chart/bar-line-chart/bar-line-chart.module';
import { DLChartModule } from '@shared_components/primeng_chart/chart.module';
import { ShortNumberPipe } from 'app/pipe/short-number.pipe';
import { TrackValueTabsComponent } from './views/track-value-tabs/track-value-tabs.component';
import { TrackValueGoalTabComponent } from './views/track-value-goal-tab/track-value-goal-tab.component';
import { TrackValueSuccessTabComponent } from './views/track-value-success-tab/track-value-success-tab.component';
import { TrackValueSurveyTabComponent } from './views/track-value-survey-tab/track-value-survey-tab.component';
import { TrackValueProofTabComponent } from './views/track-value-proof-tab/track-value-proof-tab.component';
import { TrackValueBenefitTabComponent } from './views/track-value-benefit-tab/track-value-benefit-tab.component';
import { TrackValueCostTabComponent } from './views/track-value-cost-tab/track-value-cost-tab.component';
import { TrackValueTabsTranslations } from './views/track-value-tabs/track-value-tabs.translation';
import { TrackValueGoalTabTranslations } from './views/track-value-goal-tab/track-value-goal-tab.translation';
import { TrackValueSuccessTabTranslations } from './views/track-value-success-tab/track-value-success-tab.translation';
import { TrackValueProofTabTranslations } from './views/track-value-proof-tab/track-value-proof-tab.translation';
import { TrackValueCostTabTranslations } from './views/track-value-cost-tab/track-value-cost-tab.translation';
import { TrackValueBenefitTabTranslations } from './views/track-value-benefit-tab/track-value-benefit-tab.translation';
import { GoalSidebarComponent } from './views/goal-sidebar/goal-sidebar.component';
import { TrackerBusinessReviewService } from './views/tracker-business-review.service';
import { NewSurveySidebarComponent } from './views/new-survey-sidebar/new-survey-sidebar.component';
import { NameInitialsModule } from '@shared/pipes/name-initials.module';
import { CreateSurveyCorrectModule } from 'app/value-realization/create-survey/create-survey-correct.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScratchPadModule } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/scratchpad/scratchpad.module';
import { UnitTypeModule } from '@shared/unit-type/unit-type.module';
import { BenefitFlyoutDetailComponent } from './views/benefit-flyout-detail/benefit-flyout-detail.component';
import { BenefitFlyoutInputTableComponent } from './views/benefit-flyout-input-table/benefit-flyout-input-table.component';
import { BenefitsPhasingModule } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/phasing/phasing.module';
import { BenefitFlyoutTrackerTranslation } from './views/benefit-flyout-detail/benefit-flyout-detail.translation';
import { BenefitFlyoutInputTableTranslation } from './views/benefit-flyout-input-table/benefit-flyout-input-table.translation';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HelpTooltipValueRealizedComponent } from './views/help-tooltip-value-realized/help-tooltip-value-realized.component';
import { HelpTooltipValueRealizedTranslations } from './views/help-tooltip-value-realized/help-tooltip-value-realized.translation';
import { ChooseAdventureInternalModule } from '@shared/choose-adventure-internal/choose-adventure-internal.module';
import { GoalGroupsModule } from 'app/value-map-maintenance/views/goal-groups/goal-groups.module';
import { RealizationCaseStudyModule } from 'app/value-realization/case-studies/case-studies.module';
import { BenefitDetailsModule } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/detail/benefit-detail.module';
import { BenefitsImplementation } from 'app/value-prop/value-prop-dashboard/benefits_v2/benefits.implementation';
import { AddBenefitsModule } from 'app/value-prop/value-prop-dashboard/add-benefits-component/add-benefits-component.module';
import { ModelAEBenefits } from 'app/value-mapv2/solution-detail/benefits/ae_benefits/ae_benefits.module';
import { AccordionModule } from 'primeng/accordion';
import { ArrayToFormControlModule } from 'app/pipe/array-to-form-control/array-to-form-control.module';
import { ValueRealizedFlyoutComponent } from './views/value-realized-home/value-realized-flyout/value-realized-flyout.component';
import { TickerModule } from '@shared/ticker/ticker.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DateStringToDateModule } from 'app/pipe/date-string-to-date/date-string-to-date.module';
import { GoalFormModule } from '@shared_components/goal-form/goal-form.module';
import { HeightCacheModule } from '@shared/height-cache/height-cache.module';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { BusinessReviewNotesTableComponent } from './views/business-review-notes-table/business-review-notes-table.component';
import { BusinessReviewNotesSidebarComponent } from './views/business-review-notes-sidebar/business-review-notes-sidebar.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  declarations: [
    ValueRealizedHomeComponent,
    TrackerBaselineComponent,
    TrackerBaselineViewComponent,
    ReviewBaselineComponent,
    ReviewBaselineBenefitsComponent,
    ReviewBaselineGoalGroupsComponent,
    BaselineBenefitScratchpadComponent,
    CompanyTrackersComponent,
    CompanySearchComponent,
    SuccessCriteriaComponent,
    SuccessCriteriaSidebarComponent,
    TrackValueDashboardComponent,
    TrackValueTabsComponent,
    TrackValueGoalTabComponent,
    TrackValueSuccessTabComponent,
    TrackValueSurveyTabComponent,
    TrackValueProofTabComponent,
    TrackValueBenefitTabComponent,
    TrackValueCostTabComponent,
    GoalSidebarComponent,
    NewSurveySidebarComponent,
    BenefitFlyoutDetailComponent,
    BenefitFlyoutInputTableComponent,
    HelpTooltipValueRealizedComponent,
    ValueRealizedFlyoutComponent,
    BusinessReviewNotesTableComponent,
    BusinessReviewNotesSidebarComponent,
  ],
  imports: [
    ValueRealizedRoutingModule,
    CommonModule,
    TableModule,
    FormsModule,
    MatTooltipModule,
    SelectButtonModule,
    BreadcrumbsModule,
    FunctionPipeModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    SidebarModule,
    AutoCompleteModule,
    TabViewModule,
    CalendarModule,
    DropdownModule,
    CheckboxModule,
    InputNumberModule,
    ApplicationPipesModule,
    ProgressBarModule,
    EditNameModule,
    InputTextareaModule,
    InputTextModule,
    ErrorInputWrapperModule,
    AreYouSureModule,
    EllipsisMenuModule,
    RSV2ToolbarModule,
    BarLinechartModule,
    DLChartModule,
    CheckLogoPipeModule,
    ApplicationPipesModule,
    NameInitialsModule,
    ProgressBarModule,
    CreateSurveyCorrectModule,
    FunctionPipeModule,
    NoRecordsModule,
    EditTranslationsModule,
    ProgressBarModule,
    ScratchPadModule,
    BarLinechartModule,
    UnitTypeModule,
    BenefitsPhasingModule,
    MenuModule,
    DialogModule,
    NgbTooltipModule,
    ChooseAdventureInternalModule,
    GoalGroupsModule,
    RealizationCaseStudyModule,
    BenefitDetailsModule,
    AddBenefitsModule,
    ModelAEBenefits,
    AccordionModule,
    GoalFormModule,
    AccordionModule,
    ArrayToFormControlModule,
    TickerModule,
    OverlayPanelModule,
    DateStringToDateModule,
    HeightCacheModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
  ],
  providers: [
    ValueRealizedHomeTranslation,
    CompanyTrackersTranslation,
    CompanySearchTranslation,
    TrackerBaselineViewTranslation,
    ReviewBaselineBenefitsTranslations,
    ReviewBaselineGoalGroupsTranslations,
    BaselineBenefitScratchpadTranslation,
    ReviewBaselineTranslation,
    TrackValueDashboardTranslation,
    ShortNumberPipe,
    TrackValueDashboardTranslation,
    TrackValueTabsTranslations,
    TrackValueGoalTabTranslations,
    TrackValueSuccessTabTranslations,
    TrackValueProofTabTranslations,
    TrackValueCostTabTranslations,
    TrackValueBenefitTabTranslations,
    TrackerBusinessReviewService,
    BenefitFlyoutTrackerTranslation,
    BenefitFlyoutInputTableTranslation,
    HelpTooltipValueRealizedTranslations,
    BenefitsImplementation,
    ConfirmationService,
  ],
  exports: [TrackerBaselineComponent, ValueRealizedFlyoutComponent],
})
export class ValueRealizedModule {}
