<div class="sidebar_wrapper">
  <div class="sidebar_header" *ngIf="!edit">
    {{ trans.trans.add_costs.value }}
    <app-editTranslation
      *ngIf="showTranslate"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.add_costs"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>
  <div class="sidebar_header" *ngIf="edit">
    <ng-container *ngIf="valueProp.vp_can_edit; else ViewCostTemplate">
      {{ trans.trans.edit_cost.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.edit_cost"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </ng-container>
    <ng-template #ViewCostTemplate>
      view_cost
      {{ trans.trans.view_cost.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.view_cost"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </ng-template>
  </div>

  <div class="sidebar_container">
    <div class="keywest_panel">
      <form name="AddCostsForm" #AddCostsForm="ngForm">
        <div class="row" *ngIf="featureCompetitorPickList">
          <div
            class="form-group col-xs-12"
            [ngClass]="{ 'has-error': AddCostsForm.controls.account_competitor_id?.invalid && AddCostsForm.controls.account_competitor_id?.touched }"
          >
            <label class="labelsRequired"
              >{{ trans.trans.competitor.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.competitor"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>

            <select
              [disabled]="!valueProp.vp_can_edit"
              [(ngModel)]="cost.account_competitor_id"
              class="form-control role-dropdown"
              name="account_competitor_id"
              #competitor
              (change)="setCompetitorCostTypeList(competitor.value)"
              required
            >
              <option selected value="">{{ trans.trans.choose_one.value }}</option>
              <option *ngFor="let competitor of competitorPickList" [value]="competitor.id">
                {{ competitor.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row" *ngIf="showCostTypeList">
          <div class="form-group col-xs-12" [ngClass]="{ 'has-error': AddCostsForm.controls.costType?.invalid && AddCostsForm.controls.costType?.touched }">
            <label class="labelsRequired"
              >{{ trans.trans.cost_type.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.cost_type"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>

            <p-dropdown
              [disabled]="!valueProp.vp_can_edit"
              appendTo="body"
              [autoDisplayFirst]="false"
              optionLabel="name"
              [options]="filteredCostTypeList"
              optionValue="id"
              name="accrual_type_id"
              [(ngModel)]="cost.cost_type_id"
              (onChange)="costTypeChanged(cost.cost_type_id)"
              [filter]="true"
              filterBy="name"
              name="costType"
              class="keywest_dropdown"
              [panelStyleClass]="'dropdown-item-font'"
              [required]="true"
            ></p-dropdown>
          </div>
        </div>

        <div *ngIf="cost.cost_type_id" class="form-flex-group row">
          <div class="form-group col-xs-12" *ngIf="!isSimplified">
            <label
              >{{ trans.trans.renameCost.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.renameCost"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input [disabled]="!valueProp.vp_can_edit" class="form-control" [(ngModel)]="cost.alt_cost_name" name="name" class="form-control" />
          </div>

          <div class="form-group col-xs-12">
            <label
              >{{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.description"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <textarea [disabled]="!valueProp.vp_can_edit" [(ngModel)]="cost.alt_cost_description" name="description" rows="5" class="form-control"></textarea>
          </div>

          <div class="form-group col-xs-12" [ngClass]="{ 'has-error': AddCostsForm.controls.accrualType?.invalid && AddCostsForm.controls.accrualType?.touched }">
            <label class="labelsRequired"
              >{{ trans.trans.accrual_type.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.accrual_type"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <select
              [disabled]="!valueProp.vp_can_edit"
              [(ngModel)]="cost.accrual_type_id"
              class="form-control role-dropdown"
              name="accrualType"
              (change)="accrualChange(cost.accrual_type_id)"
              required
            >
              <option selected value="">{{ trans.trans.choose_one.value }}</option>
              <option *ngFor="let accrualType of accrualTypes" [value]="accrualType.id">
                {{ accrualType.name }}
              </option>
            </select>
          </div>

          <!-- ACCRUAL INPUT PANEL -->
          <div class="adv-accrual-container" *ngIf="+cost.accrual_type_id">
            <form [formGroup]="advancedAccrualForm">
              <!-- One time -->
              <ng-container *ngIf="+cost.accrual_type_id === AccrualTypeId.OneTime">
                <app-error-input-wrapper [errorMessage]="errorDict.get('unitPrice')" [showOnTouched]="true">
                  <label class="labelsRequired"
                    >{{ trans.trans.unit_price.value }} ({{ currencySymbol }})
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.unit_price"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </label
                  ><br />
                  <input
                    currencyMask
                    [options]="{ prefix: currencySymbol, precision: 2, align: 'left', allowNegative: allowNegatives, thousands: valueProp.thousands, decimal: valueProp.decimal }"
                    type="text"
                    class="form-control"
                    formControlName="unitPrice"
                    [placeholder]="trans.trans.unit_price.value"
                /></app-error-input-wrapper>
              </ng-container>
              <!-- Variable -->
              <ng-container *ngIf="+cost.accrual_type_id === AccrualTypeId.Variable && +valueProp.term >= 2 && +valueProp.term <= 10">
                <ng-container *ngFor="let term of termIndexArray">
                  <app-error-input-wrapper [errorMessage]="errorDict.get('yr' + term + 'Costs')" [showOnTouched]="true">
                    <label
                      >{{ trans.trans['year' + term]?.value }} ({{ currencySymbol }})
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans['year' + term]"
                        (callback)="getTranslations()"
                      ></app-editTranslation>
                    </label>
                    <input
                      currencyMask
                      [options]="{ prefix: currencySymbol, precision: 2, align: 'left', allowNegative: allowNegatives, thousands: valueProp.thousands, decimal: valueProp.decimal }"
                      type="text"
                      class="form-control"
                      [formControlName]="'yr' + term + 'Costs'"
                      [placeholder]="trans.trans['placeholderYear' + term]?.value"
                  /></app-error-input-wrapper>
                </ng-container>
              </ng-container>
              <!-- Capital lease & Fair Market Value -->
              <ng-container *ngIf="+cost.accrual_type_id >= AccrualTypeId.CapitalLease">
                <div class="adv-accrual-row">
                  <div class="adv-accrual-col">
                    <app-error-input-wrapper [errorMessage]="errorDict.get('initialCost')" [showOnTouched]="true">
                      <label class="labelsRequired"
                        >{{ trans.trans.initialCost.value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.initialCost"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                        ({{ currencySymbol }}) </label
                      ><br />
                      <input
                        currencyMask
                        [options]="{ prefix: currencySymbol, precision: 2, align: 'left', allowNegative: allowNegatives, thousands: valueProp.thousands, decimal: valueProp.decimal }"
                        type="text"
                        class="form-control"
                        formControlName="initialCost"
                        [placeholder]="trans.trans.initialCost.value"
                    /></app-error-input-wrapper>

                    <app-error-input-wrapper [errorMessage]="errorDict.get('annualInterestRate')" [showOnTouched]="true">
                      <label class="labelsRequired"
                        >{{ trans.trans.annualInterestRate.value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.annualInterestRate"
                          (callback)="getTranslations()"
                        ></app-editTranslation> </label
                      ><br />
                      <input type="number" class="form-control" formControlName="annualInterestRate" [placeholder]="trans.trans.annualInterestRate.value" />
                    </app-error-input-wrapper>
                  </div>
                  <div></div>
                  <div class="unit-price">
                    {{ trans.trans.unit_price.value }}
                    <span class="annual-text">({{ trans.trans.annualPayment.value }})</span>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.unit_price"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.annualPayment"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                    <span class="unit-price-text"> {{ currencySymbol }} {{ +advancedAccrualForm.value.unitPriceCalculation * 12 | number: '1.0-0' }}</span>
                  </div>
                </div>

                <app-error-input-wrapper [errorMessage]="errorDict.get('leaseTerms')" [showOnTouched]="true">
                  <label class="labelsRequired"
                    >{{ trans.trans.leaseTerms.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.leaseTerms"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </label
                  ><br />
                  <input type="number" class="form-control" formControlName="leaseTerms" [placeholder]="trans.trans.leaseTerms.value" />
                </app-error-input-wrapper>
              </ng-container>
              <!-- Annual -->
              <ng-container *ngIf="+cost.accrual_type_id === AccrualTypeId.Annual">
                <app-error-input-wrapper [errorMessage]="errorDict.get('unitPrice')" [showOnTouched]="true">
                  <label class="labelsRequired"
                    >{{ trans.trans.unit_price.value }} ({{ currencySymbol }})
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.unit_price"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </label
                  ><br />
                  <input
                    currencyMask
                    [options]="{ prefix: currencySymbol, precision: 2, align: 'left', allowNegative: allowNegatives, thousands: valueProp.thousands, decimal: valueProp.decimal }"
                    type="text"
                    class="form-control"
                    formControlName="unitPrice"
                    [placeholder]="trans.trans.unit_price.value"
                  />
                </app-error-input-wrapper>
                <app-error-input-wrapper [errorMessage]="errorDict.get('yoyGrowth')" [showOnTouched]="true">
                  <label class="labelsRequired"
                    >{{ trans.trans.yoyGrowth.value }} (%)
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.yoyGrowth"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </label
                  ><br />
                  <input type="number" class="form-control" formControlName="yoyGrowth" [placeholder]="trans.trans.yoyGrowth.value" />
                </app-error-input-wrapper>
              </ng-container>
              <!-- Fair Market Value -->
              <ng-container *ngIf="+cost.accrual_type_id === AccrualTypeId.FairMarket">
                <!-- Residual Value Input -->
                <div class="adv-accrual-row">
                  <app-error-input-wrapper [errorMessage]="errorDict.get('residualValue')" [showOnTouched]="true">
                    <label class="labelsRequired"
                      >{{ trans.trans.residualValue.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.residualValue"
                        (callback)="getTranslations()"
                      ></app-editTranslation> </label
                    ><br />
                    <input type="number" class="form-control" formControlName="residualValue" [placeholder]="trans.trans.residualValue.value" />
                  </app-error-input-wrapper>

                  <div></div>

                  <div class="form-group">
                    <label
                      >{{ trans.trans.calcResult.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.calcResult"
                        (callback)="getTranslations()"
                      ></app-editTranslation> </label
                    ><br />
                    <input
                      class="form-control disabled"
                      value="{{ currencySymbol }} {{
                        +advancedAccrualForm.value.initialCost | residualBuyoutCalc: +advancedAccrualForm.value.quantity:+advancedAccrualForm.value.residualValue | number: '1.0-0'
                      }}"
                      disabled
                    />
                  </div>
                </div>

                <!-- Buyout Value Input -->
                <div class="adv-accrual-row">
                  <app-error-input-wrapper [errorMessage]="errorDict.get('buyoutValue')" [showOnTouched]="true">
                    <label class="labelsRequired"
                      >{{ trans.trans.buyoutValue.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.buyoutValue"
                        (callback)="getTranslations()"
                      ></app-editTranslation> </label
                    ><br />
                    <input type="number" class="form-control" formControlName="buyoutValue" [placeholder]="trans.trans.buyoutValue.value" />
                  </app-error-input-wrapper>

                  <div></div>
                  <div class="form-group">
                    <label
                      >{{ trans.trans.calcResult.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.calcResult"
                        (callback)="getTranslations()"
                      ></app-editTranslation> </label
                    ><br />
                    <input
                      class="form-control disabled"
                      value="{{ currencySymbol }} {{
                        +advancedAccrualForm.value.initialCost
                          | residualBuyoutCalc: +advancedAccrualForm.value.quantity:+advancedAccrualForm.value.residualValue:+advancedAccrualForm.value.buyoutValue
                          | number: '1.0-0'
                      }}"
                      disabled
                    />
                  </div>
                </div>
              </ng-container>
              <!-- Quantity and Discount -->

              <div class="adv-accrual-row" *ngIf="!isSimplified && +cost.accrual_type_id !== AccrualTypeId.Variable">
                <!-- Quantity Input -->
                <app-error-input-wrapper [errorMessage]="errorDict.get('quantity')" [showOnTouched]="true">
                  <label class="labelsRequired"
                    >{{ trans.trans.qty.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.qty"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </label
                  ><br />
                  <input type="number" class="form-control" formControlName="quantity" [placeholder]="trans.trans.quantity.value" />
                </app-error-input-wrapper>

                <div></div>
                <!-- Discount Input -->
                <app-error-input-wrapper [errorMessage]="errorDict.get('discount')" [showOnTouched]="true">
                  <label class="labelsRequired"
                    >{{ trans.trans.discountPercent.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.discountPercent"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </label
                  ><br />
                  <input type="number" class="form-control" formControlName="discount" [placeholder]="trans.trans.placeholderDiscount.value" />
                </app-error-input-wrapper>
              </div>
            </form>
          </div>
          <!-- END ACCRUAL INPUT PANEL -->

          <ng-container *ngIf="!isSimplified">
            <form class="expense-category-container" [formGroup]="advancedAccrualForm">
              <div class="form-group" *ngIf="costCategoryTypes && costCategoryTypes.length > 0">
                <label for="cost_category_type_id"
                  >{{ trans.trans.cost_category.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.cost_category"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <select formControlName="categoryTypeId" class="form-control role-dropdown" name="cost_category_type_id">
                  <option *ngFor="let costCategoryType of costCategoryTypes; trackBy: expenseCostTrackBy" [ngValue]="costCategoryType.id">
                    {{ costCategoryType.name }}
                  </option>
                </select>
              </div>
              <div class="form-group" [hidden]="!tco">
                <label for="expenseTypeId">
                  {{ trans.trans.expenseType.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.expenseType"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <select formControlName="expenseTypeId" class="form-control role-dropdown" name="alt_expense_type_id">
                  <option *ngFor="let expenseType of expenseTypes; trackBy: expenseCostTrackBy" [ngValue]="expenseType.id">
                    {{ expenseType.name }}
                  </option>
                </select>
              </div>
            </form>
          </ng-container>
        </div>
        <div class="row form-group">
          <div class="col-xs-6">
            <label class="labelsRequiredInfo">{{ trans.trans.required.value }}</label>
          </div>
        </div>
      </form>
    </div>
    <div [ngClass]="{ row: crm, btn_container: !crm }">
      <div class="col-xs-12">
        <img *ngIf="loading" class="loader" [src]="fullImagePath" />
        <button
          *ngIf="!loading && valueProp.vp_can_edit"
          class="btn btn_customizable pull-right xf-continue-button"
          [disabled]="AddCostsForm.invalid || advancedAccrualForm.invalid"
          (click)="saveCosts()"
        >
          {{ trans.trans.save.value }}
        </button>
        <app-are-you-sure
          *ngIf="!loading"
          matTooltipPosition="above"
          [message]="trans.trans.are_you_sure.value"
          class="btn cancel-confirm pull-right marg-h"
          icon=""
          [text]="trans.trans.back.value"
          (callbackYes)="closeSidebar()"
        ></app-are-you-sure>
      </div>
    </div>
  </div>
  
</div>
