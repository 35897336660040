import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SupportService } from '@data/services/support/support.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { DroppedFile } from '@shared/models/dropped-file.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { IssuesTranslations } from './issues.translation';
@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
})
export class IssuesComponent implements OnInit, OnDestroy {
  sections: string[] = [];
  query: { email: string; section: string; issues: string; media?: string };
  fullImagePath: string;
  imageUrl: string;
  IssuesLoader: boolean;
  files: DroppedFile[];
  aid: string;
  refresh = false;

  subscriptionpostQueries: Subscription;
  ngUnsubscribe = new Subject();
  showTranslate = false;

  style2022$: Observable<boolean>;
  @ViewChild('issueForm', { static: false }) issueForm: NgForm;

  constructor(
    private commonService: CommonService,
    private supportService: SupportService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
    public trans: IssuesTranslations
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.query = {
      email: sessionStorage.getItem('email'),
      section: '',
      issues: '',
    };

    this.aid = sessionStorage.getItem('aid');
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.funcBuildDropdownMenu();
      });
  }

  funcBuildDropdownMenu() {
    this.sections = [];
    this.sections.push(this.trans.trans.research.value);
    this.sections.push(this.trans.trans.reach.value);
    this.sections.push(this.trans.trans.revise.value);

    this.sections.push(this.trans.trans.retain.value);
    this.sections.push(this.trans.trans.model.value);
    this.sections.push(this.trans.trans.profile.value);

    if (this.aid === '25012') {
      this.sections = ['VMP Enhancement Suggestion', 'Issue with my Case', 'Issue with a Download', 'Issue with an External Share', 'Issue with VMP'];
    }
  }

  ngOnDestroy() {
    this.refresh = true;
    this.files = [];
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
    if (this.subscriptionpostQueries) {
      this.subscriptionpostQueries.unsubscribe();
    }
  }

  submitQueries() {
    this.IssuesLoader = true;
    const payload = new FormData();
    payload.append('email', sessionStorage.getItem('email'));
    payload.append('section', this.query['section']);
    payload.append('issues', this.query['issues']);

    if (this.files && this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        payload.append('issue_image' + '[' + i + ']', this.files[i]);
      }
    }

    this.subscriptionpostQueries = this.supportService.postQueries(payload)
    .pipe(
      finalize(() => this.IssuesLoader = false),
      catchError( error => {
        this.notificationService.error('An error have occurred, please try again', false);
        return throwError(error);
      })
      )
      .subscribe((response) => {
        if (response.result) {
          this.notificationService.success('Your feedback submitted succesfully', false);
          this.refresh = true;
          setTimeout(() => {
            this.files = [];
            this.refresh = false;
            this.issueForm.reset();
        }, 10);
      }
    });
  }

  showInvalidSize(file: string) {
    this.notificationService.error(file + ' is bigger than the 32mb max size ', false);
  }

  showInvalidType(file: string) {
    this.notificationService.error(file + ' has an invalid Type ', false);
  }

  handleInputChange(event: { target: { files: File[] } }) {
    this.files = event.target.files as DroppedFile[];
  }
}
