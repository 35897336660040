import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { AddProjectModalComponent } from '../../common/add-project-modal/add-project-modal.component';
import { Project } from './models/project.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  @Input() solutionId: number | string;
  @Input() tabInit: number;
  @Input() canEdit = false;

  projects: Array<Project> = [];

  subscriptionreadAllProjects: Subscription;
  subscriptiongetProjects: Subscription;
  subscriptiondeleteProject: Subscription;
  subscriptionupdateProject: Subscription;

  constructor(
    private solutionService: SolutionService,
    private modalService: NgbModal,
    private notification: NotificationService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.getProjects();

    this.subscriptionreadAllProjects = this.solutionService.readAllProjects.subscribe(() => {
      this.getProjects();
    });
  }

  ngOnDestroy() {
    if (this.subscriptionreadAllProjects) {
      this.subscriptionreadAllProjects.unsubscribe();
    }
    if (this.subscriptiongetProjects) {
      this.subscriptiongetProjects.unsubscribe();
    }
    if (this.subscriptiondeleteProject) {
      this.subscriptiondeleteProject.unsubscribe();
    }
    if (this.subscriptionupdateProject) {
      this.subscriptionupdateProject.unsubscribe();
    }
  }

  openAddProject() {
    const modalReferenceValueCategory = this.modalService.open(AddProjectModalComponent, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    modalReferenceValueCategory.componentInstance.solutionId = this.solutionId;
    modalReferenceValueCategory.componentInstance.isCreateAndEdit = true;
  }

  getProjects() {
    this.subscriptiongetProjects = this.solutionService.getProjects(this.solutionId).subscribe((response) => {
      this.projects = [];
      if (response.result) {
        this.projects = response.result;
      }
    });
  }

  deleteProject(project: Project) {
    this.subscriptiondeleteProject = this.solutionService.deleteProject(project.account_solution_id, project.account_project_type_id).subscribe((response) => {
      if (response.result) {
        this.getProjects();
        this.solutionService.triggerTabs.next('projects');
        this.notification.success(response.result, false);
      }
    });
  }

  updateProject(project: Project, active: number) {
    const payload = {
      account_solution_project_type_id: project['id'],
      account_project_type_id: project['account_project_type_id'],
      active: active,
    };

    this.subscriptionupdateProject = this.solutionService.updateProject(payload, project['account_solution_id']).subscribe((response) => {
      if (response.result && response.result.success) {
        this.getProjects();
        this.solutionService.triggerTabs.next('projects');
        this.notification.success(response.result.message, false);
      }
    });
  }
}
