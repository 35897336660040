import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { AccountService } from '@data/services/account/account.service';
import { DiscoverQuestionsVpDashTranslations } from './question.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';

@Component({
  selector: 'app-discovery-questions',
  templateUrl: './questions.component.html',
  providers: [ValuepositionService],
})
export class DiscoveryQuestionsComponent implements OnInit, OnDestroy {
  account_id: string;
  questionsRequired: any[] = [];
  questionsOptional: any[] = [];
  image_url: string;

  @Input() valueProp: any;
  @Input() valuePropId;
  @Input() showTranslate: boolean = false;
  @Input() locked: boolean = false;
  @Output() getScalers = new EventEmitter();
  fullImagePath: string;
  saveLoader: boolean = false;

  ngUnsubscribe = new Subject();
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: DiscoverQuestionsVpDashTranslations,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }
  ngOnInit() {
    // this.locked = this.valueProp.closed == 1;
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');

    this.funcGetDiscoveryQuestions();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcGetDiscoveryQuestions() {
    this.accountService
      .getDiscoveryQuestionsVP(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          let questions = response.result.sort((a, b) => +a.sequence - +b.sequence);
          for (let i = 0; i < questions.length; i++) {
            if (questions[i].required == '1') {
              this.questionsRequired.push(questions[i]);
            } else {
              this.questionsOptional.push(questions[i]);
            }
          }
        } else {
          this.questionsRequired = [];
          this.questionsOptional = [];
        }
      });
  }

  funcSaveQuestions() {
    this.saveLoader = true;
    let payload: any;
    let discovery_questions = [];
    if (this.questionsRequired && this.questionsRequired.length) {
      for (let i = 0; i < this.questionsRequired.length; i++) {
        if (!this.questionsRequired[i].answer) {
          this.saveLoader = false;
          this.notificationService.error('Please enter all required fields', false);
          return false;
        }
        discovery_questions.push({
          account_discovery_question_id: this.questionsRequired[i].account_discovery_question_id,
          answer: this.questionsRequired[i].answer,
        });
      }
    }

    if (this.questionsOptional && this.questionsOptional.length) {
      for (let i = 0; i < this.questionsOptional.length; i++) {
        discovery_questions.push({
          account_discovery_question_id: this.questionsOptional[i].account_discovery_question_id,
          answer: this.questionsOptional[i].answer ? this.questionsOptional[i].answer : '',
        });
      }
    }

    if (!discovery_questions.length) {
      this.getScalers.emit();
      this.saveLoader = false;
      return;
    }

    payload = { discovery_questions: discovery_questions };

    this.valuepropService
      .putDiscoveryQuestions(this.valuePropId, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.getScalers.emit();
          this.saveLoader = false;

          if (this.valueProp.discovery_questions.length) {
            this.notificationService.success('Values updated successfully', false); // Params {message, islogout}
            this.valuepropService.refreshDashboard.next('refreshDashboard');
          }
        } else {
          this.saveLoader = false;
        }
      });
  }
}
