import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueCategory } from '@data/services/valueposition/models/value-category.interface';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ModelV2ValueCategoryTranslations } from './value-category.translation';

@Component({
  selector: 'app-value-category',
  templateUrl: './value-category.component.html',
})
export class ValueCategoryComponent implements OnInit, OnDestroy {
  @Input() solutionId: string | number;
  @Input() tabInit: number;
  @Input() can_edit = false;
  loading = true;

  strSearch = '';
  toggleAddEditCategory = false;
  @ViewChild('formAddEditCategory') formAddEditCategory: NgForm;
  sidebar_title = 'Add Benefit Group';
  mode = 'add';
  dropdown: MenuItem[] = [];

  ngUnsubscribe$ = new Subject();

  @Input() selectedElem: ValueCategory = {
    name: '',
    description: '',
    id: '',
  };

  valueCategories: ValueCategory[] = [];
  valueCategoryToEdit: ValueCategory = {};

  account_id: string;

  cols: Array<{field: string, header: string}> = [{ field: 'name', header: 'Name' }];
  showTranslate = false;
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private notification: NotificationService,
    private commonService: CommonService,
    public trans: ModelV2ValueCategoryTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) { }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');
    this.getValueCategories();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  closeAddEditCategory(): void {
    this.toggleAddEditCategory = false;
  }

  funcToggleEdit(elem: ValueCategory): void {
    this.mode = 'edit';
    this.sidebar_title = 'Edit Benefit Group';
    this.selectedElem = Object.assign({}, elem);
    this.toggleAddEditCategory = true;
  }

  funcToggleView(elem: ValueCategory): void {
    this.toggleAddEditCategory = true;
    this.selectedElem = Object.assign({}, elem);
  }

  openAddValueCategory(): void {
    this.mode = 'add';
    // tslint:disable-next-line: forin
    for (const k in this.selectedElem) {
      this.selectedElem[k] = null;
    }
    this.toggleAddEditCategory = !this.toggleAddEditCategory;
  }

  getValueCategories(): void {
    this.loading = true;
    this.solutionService
      .getAllValueCategories(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.valueCategories = res && res.result && res.result.length ? res.result.map((cat) => ({ ...cat, isLoading: false })) : [];
        this.loading = false;
        this.solutionService.refreshModelsTotals.next();
      });
  }

  deleteSolutionValueCategory(valueCategoryId: string | number, row): void {
    row.isLoading = true;
    this.solutionService
      .deleteSolutionValueCategoryNoSolutionID(valueCategoryId)
      .pipe(
        finalize(() => (row.isLoading = false)),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(
        (response) => {
          if (response.result) {
            this.notification.success(response.result, false);
            this.getValueCategories();
          }
        },
        (error) => this.notification.error(error, false)
      );
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
