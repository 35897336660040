import { Injectable } from '@angular/core';

@Injectable ()
export class TcoTablesTranslations {

    public config: any = {
        component: 'tcoTables',
        display_name: 'TCO tables',
        description: 'TCO tables found in rep simple results'
    };

    public trans: any = {
            tco:'TCO',
            showMoreThanCostsImpacts:'Show More than Costs Impacts',
            current_state: 'Current State',
			future_state: 'Future State',
			total_cost_ownership: 'Total Cost of Ownership',
			year: 'Year',
			total: 'Total',
			total_current_state: 'Total Current State',
			total_future_state: 'Total Future State',
			tco_summary: 'TCO Summary',
            TCOSummaryPerUSer: 'TCO Summary Per User',
            Year1: 'Year 1',
            Year2: 'Year 2',
            Year3: 'Year 3',
            Year4: 'Year 4',
            Year5: 'Year 5',
            noTCOFound: 'No TCO Found',
            ch_tco: "Total cost of ownership (TCO) is a financial estimate intended to help buyers and owners determine the direct and indirect costs of a product or system. It is a management accounting concept that can be used in full cost accounting or even ecological economics where it includes social costs.",
    };
}