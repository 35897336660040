import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberTruncate',
})
export class NumberTruncatePipe implements PipeTransform {
  decorator: string;
  decoratorIndex: number;

  transform(value: string | number): string {
    if (typeof value === 'string') {
      const decimalAmount = value.match(/(\.|,)\d{1,2}(?!\d)/g);
      let numberWithoutSeparator: string;
      if (decimalAmount === null) {
        const includesDotsAsSeparators = value.includes('.');
        if (includesDotsAsSeparators) {
          numberWithoutSeparator = value.replace(/\./g, '');
        } else {
          numberWithoutSeparator = value.replace(/,/g, '');
        }
      } else {
        const isCommaDecimals = decimalAmount[0].includes(',');
        if (isCommaDecimals) {
          const withoutSeparatorBeforeFix = value.replace(/\./g, '');
          numberWithoutSeparator = withoutSeparatorBeforeFix.replace(/,/g, '.');
        } else {
          numberWithoutSeparator = value.replace(/,/g, '');
        }
      }
      const numberNoEmptySpace = numberWithoutSeparator.replace(/\s/g, '');
      return this.truncate(this.removeDecorator(numberNoEmptySpace));
    } else if (typeof value === 'number') {
      return this.truncate(value);
    } else {
      return '';
    }
  }

  private truncate(value: number): string {
    let numberWithCommas: string;
    if (value < 1000000000) {
      numberWithCommas = this.addCommas(String(value));
    } else if (1000000000 <= value && value < 1000000000000) {
      numberWithCommas = this.addCommas((value / 1000000000).toFixed(0)) + ' B';
    } else if (1000000000000 <= value) {
      numberWithCommas = this.addCommas((value / 1000000000000).toFixed(0)) + ' T';
    }
    if (numberWithCommas) {
      return this.decorator ? (this.decoratorIndex < 1 ? `${this.decorator}${numberWithCommas}` : `${numberWithCommas}${this.decorator}`) : numberWithCommas;
    } else {
      return '';
    }
  }

  private removeDecorator(value: string): number {
    const decoratorArray = value.split('').filter((character) => isNaN(Number(character)) && character != '' && character != '.');
    const decorator = decoratorArray.join('');
    if (decorator) {
      this.decorator = decorator;
      this.decoratorIndex = value.indexOf(decorator);
      return Number(value.replace(decorator, ''));
    } else {
      return Number(value);
    }
  }

  private addCommas(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
