<div class='keywest_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>{{trans.trans.competitors.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'competitor'" [isTranslationV2]="true"
				[transObj]="trans.trans.competitors" (callback)="getTranslations()"></app-editTranslation>
			</div>
		</div>
		<div class='col-xs-6'>
			<button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="funcShowAddCompetitor()" matTooltipPosition=“top”>
				<i class="fa fa-plus"></i>{{trans.trans.addNew.value}}</button>
		</div>
	</div>
</div>
<div class='row'>
	<div class='col-xs-4'>
		<div class="input-group keywest_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input [(ngModel)]="strSearch" (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
			<span *ngIf="strSearch !== ''" (click)="strSearch=''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-sm-12">
		<div class='row'>
			<div class='col-xs-12'>
				<p-table #tableSolutionList [value]="solutionCompetitors" [columns]="cols" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" [pSortableColumn]="col.field">
								{{trans.trans[col.field].value}}
								<app-editTranslation *ngIf="showTranslate" [component]="'competitors'" [isTranslationV2]="true"
								[transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
								<p-sortIcon [field]="col.field"></p-sortIcon>
							</th>
							<th></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-competitor>
						<tr>
							<td style='white-space: nowrap;'>
								<a class="clickable" (click)="can_edit ? showEditCompetitor(competitor) : funcToggleView(competitor)">{{competitor.name}}</a>
								<i *ngIf="competitor.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="competitor.description" matTooltipPosition="above"></i>
								<span *ngIf="competitor.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="competitor.description" matTooltipPosition="above">
									info
								</span>
							</td>
							<td>
								<span *ngIf="can_edit" class='keywest_icon_group pull-right'>
									<span><i (click)='showEditCompetitor(competitor)' [matTooltip]="trans.trans.edit.value" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
									<span>
										<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteCompetitors(competitor.id)'></app-are-you-sure>
									</span>
								</span>
								<span *ngIf="!can_edit" class='keywest_icon_group pull-right'>
									<span><i (click)='funcToggleView(competitor)' class='fa fa-eye clickable pull-right' [matTooltip]="trans.trans.view.value" matTooltipPosition="above"></i></span>
								</span>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddCompetitor' styleClass="keywest_sidebar p-sidebar-xl" position='right'>
	<app-ae-competitors *ngIf="toggleAddCompetitor && can_edit" [mode]='mode' [competitorFormObj]='competitorFormObj' (callback)='getCompetitorList(); toggleAddCompetitor = false'></app-ae-competitors>
	<app-r-competitors *ngIf="toggleAddCompetitor && !can_edit" [competitorFormObj]='competitorFormObj'></app-r-competitors>
</p-sidebar>