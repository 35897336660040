<fieldset
  [attr.id]="id"
  [ngClass]="{ 'p-fieldset p-component p-component-content p-corner-all': true, 'p-fieldset-toggleable': toggleable }"
  [ngStyle]="style"
  [class]="styleClass"
>
  <legend class="p-fieldset-legend p-corner-all p-default p-unselectable-text">
    <ng-container *ngIf="toggleable; else legendContent">
      <a tabindex="0" (keydown.enter)="toggle($event)" [attr.aria-controls]="id + '-content'" [attr.aria-expanded]="!collapsed">
        <ng-container *ngTemplateOutlet="legendContent"></ng-container>
      </a>
    </ng-container>
    <ng-template #legendContent>
      <span class="p-fieldset-toggler pi" *ngIf="toggleable" [ngClass]="{ 'pi-minus': !collapsed, 'pi-plus': collapsed }"></span>
      <span class="p-fieldset-legend-text" (click)="toggle($event)" style="font-size: 18px">{{ legend }}</span>
      <span *ngIf="info" class="p-fieldset-legend-text fieldset_icon">
        <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="info" matTooltipPosition="above"> info </span>
      </span>
      <span *ngIf="editCallbackVisible" (click)="funcEditCallback()" matTooltip="Edit" matTooltipPosition="above" class="p-fieldset-legend-text fieldset_icon"
        ><i class="fa fa-pencil"></i
      ></span>
      <app-are-you-sure
        message="Are you sure?"
        *ngIf="deleteCallbackVisible"
        matTooltip="Delete"
        matTooltipPosition="above"
        text=""
        icon="fieldset_icon fa fa-trash"
        (callbackYes)="funcDeleteCallback()"
      ></app-are-you-sure>
      <ng-content select="p-header"></ng-content>
    </ng-template>
  </legend>
  <div
    [attr.id]="id + '-content'"
    class="p-toggleable-content"
    [@fieldsetContent]="
      collapsed
        ? { value: 'hidden', params: { transitionParams: transitionOptions, height: '0' } }
        : { value: 'visible', params: { transitionParams: transitionOptions, height: '*' } }
    "
    [attr.aria-labelledby]="id"
    [attr.aria-hidden]="collapsed"
    (@fieldsetContent.done)="onToggleDone($any($event))"
    role="region"
  >
    <div class="p-fieldset-content">
      <ng-content></ng-content>
    </div>
  </div>
</fieldset>
