import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2PainPointsTranslations {
  public config: any = {
    component: 'pain',
    display_name: 'Pain Points',
    description: 'Pain Points'
  };

  public trans: any = {
    pain_points: 'Pain points',
    add_new: 'Add New',
    ch_delete: 'Delete',
    ch_edit: 'Edit',
    ch_view: 'View',
    are_you_sure: 'Are you sure?'
  };
}
