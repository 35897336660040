import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';

@Injectable()
export class UserAuthGuard implements CanActivate {
	constructor(
		private commonService: CommonService,
		private router: Router
	) {

	}
	canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
		let admin = this.commonService.checkPrivilege("5");
		if (admin) { return true; }
		this.router.navigateByUrl('/dashboard');
		return false;
	}
}