import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2RPainPointsTranslations {
  public config: any = {
    component: 'r_painpoints',
    display_name: 'Pain Points Read',
    description: 'Read Pain points'
  };

  public trans: any = {
    view_painpoint: 'View Pain Point',
    name: 'Name',
    description: 'Description'
  };
}
