
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { AssetsPanelTranslations } from './assetspanel.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
	selector: 'app-value-prop-dashboard-assets-panel',
	templateUrl: './assetspanel.component.html'
})
export class ValuePropDashboardAssetsPanelComponent implements OnInit, OnDestroy {

	@Input() showHelp: boolean;
	@Input() contextualHelp: boolean;
	@Input() valueProp: ValueProp;
	@Input() dashboard: string;
	@Input() header_color: string;
	@Input() showTranslate: boolean;
	enablePropSection: boolean;
	assetUrl: string;
	assetGenerator = "";
	userId = "";
	token = "";

	ngUnsubscribe: Subject<any> = new Subject();

	valuePropTemplates: ValueProp[] = [];
	subscriptionreadValuePropAssetTemplates: Subscription;
	subscriptionrefreshDashboard: Subscription;

	constructor(
		private valuepropService: ValuepropService,
		private commonService: CommonService,
		public trans: AssetsPanelTranslations,
		private translationService: TranslationsV2Service
	) {

	}

	ngOnInit() {
		this.userId = sessionStorage.getItem('uid');
		this.token = sessionStorage.getItem('vcu');

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.assetUrl = this.commonService.getAssetUrl();
		this.assetGenerator = sessionStorage.getItem('asset_gen_file');
		this.readValuePropAssetTemplates();

		this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.readValuePropAssetTemplates();
		});

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.readValuePropAssetTemplates();
		})
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();

	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	refreshTranslation() {

	}

	readValuePropAssetTemplates() {
		this.valuePropTemplates = [];
		this.valuepropService.readValuePropAssetTemplates(this.valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response) {

				this.valuePropTemplates = response.result;
			}
		});
	}

	getFAIcon(format) {
		switch (format) {
			case "MS Word":
				return "fa fa-file-word-o"

			case "MS Excel":
				return "fa fa-file-excel-o"

			case "MS PowerPoint":
				return "fa fa-file-powerpoint-o"

			case "MS PDF":
				return "fa fa-file-pdf-o"

			default:
				return "fa fa-cloud-download"

		}
	}

	getFAColor(format) {
		switch (format) {
			case "MS Word":
				return { "color": "#2e5b97" };

			case "MS Excel":
				return { "color": "#3a6e46" };

			case "MS PowerPoint":
				return { "color": "#c55737" };

			case "MS PDF":
				return { "color": "#c60a09" };

			default:
				return { "color": "black" }
		}
	}
}
