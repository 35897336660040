export function loadParlor(): void {
    if (!window.Parlor) {
        const isExternalShare = !!sessionStorage.getItem("hash_id");

        if (!isExternalShare) {
            const accountId = sessionStorage.getItem("aid");

            const parlorKeysMap = {
                '25012': "-MTkqKt6Z73ehPHwk4F1"
            };
            const parlorKey = parlorKeysMap[accountId];

            if (parlorKey) {
                (function (p) {
                    const f = document.getElementsByTagName('script')[0];
                    const j = document.createElement('script');
                    j.async = false;
                    j.id = "parlor-embedder";
                    j.src = "https://cdn.parlor.io/embedder/decisionlink.js.gz";
                    j.setAttribute("data-parlor-company", p);
                    j.addEventListener('load', function () {
                        const uid = sessionStorage.getItem("uid");
                        const email = sessionStorage.getItem("email");
                        window.Parlor.identify({ uid: uid, email: email });
                    });
                    f.parentNode.insertBefore(j, f);
                })(parlorKey);
            }
        }
    }
}
