import { Pipe, PipeTransform } from '@angular/core';
import { BenefitType, BenefitVCS } from '@shared/models/benefit-type.model';

@Pipe({
  name: 'filterBenefitTypeByStatus',
  pure: false,
})
export class FilterBenefitTypeByStatusPipe implements PipeTransform {
  transform(arr: BenefitType[], active: boolean, na: boolean, soft: boolean): BenefitType[] {
    if (!arr) {
      return arr;
    }

    const filterCallback = (benefitType: BenefitType) => {
      const categoryFilter = (category: BenefitVCS) => {
        const isActive = active && category.active_count;
        const isNa = na && category.na_count;
        const isSoft = soft && category.soft_count;

        return isActive || isNa || isSoft;
      };

      return benefitType.vcs.some(categoryFilter);
    };

    const filteredArray = arr.filter(filterCallback);
    return filteredArray;
  }
}
