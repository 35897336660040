<div class="models_panel reduced-padding">
  <div>
    <div class="keywest_header mr-0">
      <div class="row">
        <div class="col-xs-10">
          <div class="title padding-0-y">
            {{ trans.trans.advanced.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.advanced"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_advanced.value"> info </span>
          </div>
        </div>
        <div class="col-xs-2"></div>
      </div>
    </div>

    <form name="AddAdvancedForm" #AddAdvancedForm="ngForm" class="custom-advanced-form">
      <div *ngIf="customerTypeFeature" class="row" style="margin-bottom: 10px">
        <div class="col-xs-12">
          <label class="sidebar_label">
            {{ trans.trans.CustomerType.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.CustomerType"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.CustomerType"
            ></app-editTranslation>
          </label>
          <select [(ngModel)]="situation.customer_type_id" class="form-control role-dropdown" name="customerType">
            <option selected value="">{{ trans.trans.ChooseOne.value }}</option>
            <option selected="customerType.id == situation.customer_type" *ngFor="let customerType of customerTypes" [value]="customerType.id">
              {{ customerType.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px" *ngIf="!feature64">
        <div class="col-xs-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.baselineType?.invalid && AddAdvancedForm.controls.baselineType?.touched }">
          <label class="sidebar_label">
            {{ trans.trans.BaselineType.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_baseline_type.value" matTooltipPosition="right"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.BaselineType"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.BaselineType"
            ></app-editTranslation>
          </label>
          <select [(ngModel)]="situation.baseline_type_id" class="form-control role-dropdown" name="baselineType" required>
            <option selected value="0" disabled>{{ trans.trans.ChooseOne.value }}</option>
            <option selected="baselineType.id == situation.baseline_type" *ngFor="let baselineType of baselineTypes" [value]="baselineType.id">
              {{ baselineType.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div class="col-xs-12">
          <label class="sidebar_label">
            {{ trans.trans.CostofCapital.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_cost_of_capital.value" matTooltipPosition="right"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.CostofCapital"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.CostofCapital"
            ></app-editTranslation>
          </label>
          <p-inputNumber inputStyleClass="form-control"
                         [(ngModel)]="situation.wacc"
                         [minFractionDigits]="waccPrecision"
                         [maxFractionDigits]="waccPrecision"
                         name="costOfCapital"
                         placeholder="Cost of Capital"
          ></p-inputNumber>
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div class="col-xs-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.term?.invalid && AddAdvancedForm.controls.term?.touched }">
          <label class="sidebar_label">
            {{ trans.trans.Term.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_term.value" matTooltipPosition="right"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.Term"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.Term"
            ></app-editTranslation>
          </label>
          <select [(ngModel)]="term" class="form-control role-dropdown" name="term" required>
            <option selected value="">{{ trans.trans.ChooseOne.value }}</option>
            <option selected="advancedTerm.term == term ? 'selected' : ''" *ngFor="let advancedTerm of advancedTerms" [value]="advancedTerm.term">
              {{ advancedTerm.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div class="col-xs-12">
          <label class="sidebar_label">
            {{ trans.trans.deployment_time.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_deployment_time.value" matTooltipPosition="right"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.deployment_time"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.DeploymentTime"
            ></app-editTranslation>
          </label>
          <input type="number" class="form-control" id="" [(ngModel)]="situation.deployment_time" name="deploymentTime" placeholder="Deployment Time" />
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px" *ngIf="grossProfitFeature">
        <div class="col-xs-12">
          <label class="sidebar_label">
            {{ trans.trans.GrossProfitMargin.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_gross_profit_margin.value" matTooltipPosition="below"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.GrossProfitMargin"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.GrossProfitMargin"
            ></app-editTranslation>
          </label>
          <input type="number" class="form-control" id="" [(ngModel)]="situation.gross_profit" name="grossProgit" placeholder="Gross Profit Margin" />
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div
          class="col-xs-12"
          *ngIf="opportunityIdFeature"
          [ngClass]="{ 'has-error': AddAdvancedForm.controls.opportunity_id?.invalid && AddAdvancedForm.controls.opportunity_id?.touched }"
        >
          <label class="sidebar_label">
            {{ trans.trans.opportunity_id.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_opportunity_id.value" matTooltipPosition="right"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.opportunity_id"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.opportunity_id"
            ></app-editTranslation>
          </label>
          <input type="text" class="form-control" [(ngModel)]="situation.opportunity_id" name="opportunity_id" [attr.required]="opportunityIdFeatureRequired" />
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px" *ngIf="situation.number_format_types">
        <div class="col-xs-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.number_format?.invalid && AddAdvancedForm.controls.number_format?.touched }">
          <label class="sidebar_label">
            {{ trans.trans.currency_format.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.currency_format"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.currency_format"
            ></app-editTranslation>
          </label>
          <select class="form-control role-dropdown" name="role" type="text" [(ngModel)]="situation.number_format">
            <option [ngValue]="type.id" *ngFor="let type of situation.number_format_types">{{ type.name }}</option>
          </select>
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div class="col-xs-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.currency_type_id?.invalid && AddAdvancedForm.controls.currency_type_id?.touched }">
          <label class="sidebar_label">
            {{ trans.trans.currency_type_id.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_currency.value" matTooltipPosition="right"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.currency_type_id"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.currency_type_id"
            ></app-editTranslation>
          </label>
          <p-dropdown
            appendTo="body"
            name="currencyId"
            [autoDisplayFirst]="false"
            [options]="situation.currency_types"
            (onChange)="setConversionRateField($event)"
            dataKey="id"
            optionLabel="name"
            [(ngModel)]="currencyId"
            [filter]="true"
            class="keywest_dropdown"
          ></p-dropdown>
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div class="col-xs-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.conversion_rate?.invalid && AddAdvancedForm.controls.conversion_rate?.touched }">
          <label class="sidebar_label">
            {{ trans.trans.conversion_rate.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_conversion_rate.value" matTooltipPosition="right"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.conversion_rate"
              (callback)="getTranslations()"
              key="vp_dashboard.advanced.conversion_rate"
            ></app-editTranslation>
          </label>
          <input type="number" class="form-control" [(ngModel)]="situation.conversion_rate" name="conversion_rate" required />
        </div>
      </div>

      <div class="row" style="margin-bottom: 10px">
        <div class="col-xs-12">
          <label class="sidebar_label"> Asset Language </label>
          <p-dropdown
            appendTo="body"
            name="languages"
            [autoDisplayFirst]="false"
            [options]="languages"
            dataKey="id"
            optionLabel="name"
            [(ngModel)]="selectedLanguage"
            [filter]="true"
            class="keywest_dropdown"
          ></p-dropdown>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-xs-12">
        <button class="btn btn_customizable" *ngIf="!loader && vpCanEdit && !locked" [disabled]="AddAdvancedForm.invalid" (click)="updateAdvanced()">
          {{ trans.trans.save.value }}
        </button>
      </div>
    </div>
  </div>
</div>
