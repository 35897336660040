import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BarModelsComponent } from './bar-models.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';


import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ChartModule } from 'primeng/chart';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ChartModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [
		BarModelsComponent,
	],
	providers: [Translations],
	exports: [BarModelsComponent]
})
export class BarModelsModule { }
