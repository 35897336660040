import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { SCVPGScalersComponentRepv2 } from './scalers.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';



import { Translations } from '@data/services/translation/translation.service';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeModule } from 'primeng/tree';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScenarioCombinedScalersTranslations } from './sc_scalers.translation';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		ProgressBarModule,
		AreYouSureModule,
		TreeModule,
		NgbModule,
    MatTooltipModule,
		SidebarModule,
		DropdownModule,
		FieldsetModule,
		TableChartModule,
		CommonModule,
		FormsModule,
		ApplicationPipesModule,
		EditTranslationsModule,
		CurrencyMaskModule
	],
	declarations: [
		SCVPGScalersComponentRepv2
	],
	providers: [Translations, ScenarioCombinedScalersTranslations],
	exports: [SCVPGScalersComponentRepv2]
})
export class SCVPGScalersModule { }
