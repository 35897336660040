import { filterAssumptionsFinancialPipe, filterAssumptionsPipe, filterAssumptionsScratchpadsPipe, filterAssumptionsAllPipe } from './filter.assumptions';
import { filterBenefitsPipe, filterBenefitsStatusPipe } from './filter.benefits';
import { FilterPipe } from './filter.pipe';
import { RangePipe } from './range.pipe';
import { SortPipe } from './sort.pipe';
import { NgModule } from '@angular/core';
import { filterStatusPipe } from './filter.status';
import { filterValuePosPipe, filterValuePropPipe } from './filter.valueprop';
import { FormatNumberPipe } from './format-number.pipe';
import { TrustURLPipe } from './filter.trusturl';
import { filterUrlToLinkPipe } from './filter.urltolink';
import { AssetTagPipe } from './asset-tag.pipe';
import { ResidualBuyoutCalcPipe } from './residual-buyout-calculation.pipe';
import { FilterArrayByIndexPipe } from './filter-array-by-index.pipe';
import { FindOriginalIndexPipe } from './find-original-index.pipe';
import { GetScrachpadSignPipe } from './get-scrachpad-sign.pipe';
import { SafariDatePipe } from './normalize-date.pipe';
import { LocaleNumberMaskPipe } from './locale-number-mask.pipe';
import { FallbackValuePipe } from './fallback-value.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { FilterFactorsPipe } from './filter-factors.pipe';
import { VpTooltipPipe } from './vp-tooltip.pipe';

@NgModule({
  declarations: [
    filterUrlToLinkPipe,
    TrustURLPipe,
    filterValuePosPipe,
    filterAssumptionsAllPipe,
    filterAssumptionsFinancialPipe,
    filterAssumptionsPipe,
    filterAssumptionsScratchpadsPipe,
    FilterPipe,
    RangePipe,
    SortPipe,
    filterBenefitsPipe,
    filterValuePropPipe,
    filterStatusPipe,
    filterBenefitsStatusPipe,
    FormatNumberPipe,
    AssetTagPipe,
    ResidualBuyoutCalcPipe,
    GetScrachpadSignPipe,
    SafariDatePipe,
    LocaleNumberMaskPipe,
    ShortNumberPipe,
    FilterArrayByIndexPipe,
    FindOriginalIndexPipe,
    FallbackValuePipe,
    FilterFactorsPipe,
    VpTooltipPipe
  ],
  exports: [
    filterUrlToLinkPipe,
    TrustURLPipe,
    filterValuePosPipe,
    filterAssumptionsAllPipe,
    filterAssumptionsFinancialPipe,
    filterAssumptionsPipe,
    filterAssumptionsScratchpadsPipe,
    filterBenefitsPipe,
    FilterPipe,
    SortPipe,
    RangePipe,
    filterValuePropPipe,
    filterStatusPipe,
    filterBenefitsStatusPipe,
    FormatNumberPipe,
    AssetTagPipe,
    ResidualBuyoutCalcPipe,
    GetScrachpadSignPipe,
    SafariDatePipe,
    LocaleNumberMaskPipe,
    ShortNumberPipe,
    FilterArrayByIndexPipe,
    FindOriginalIndexPipe,
    FallbackValuePipe,
    FilterFactorsPipe,
  ],
})
export class ApplicationPipesModule {}
