import { Injectable } from '@angular/core';

@Injectable ()

export class SolutionDetailTranslations {

    public config: any = {
        component: 'solutionDetail',
        display_name: 'Solution Overview',
        description: 'Overview of a solution'
    };

    public trans: any = {
        back: 'Back',
        supporting_materials: 'Supporting Materials' ,
        ch_overview: 'Basic facts and figures of the model',
        ch_benefits: 'The Metrics used to quantify the improvement to the customer after successfully deploying your solution',
        ch_costs: 'The investment required to achieve the benefits in scope',
        ch_situations: `Forces that change your value
        . Items may include/exclude or change benefits, costs, collateral, or case study / supporting materials`,
        ch_competitors: 'Companies that have similiar solutions to yours that you need to differentiate from',
        ch_case_studies: 'Capture empirical improvements, customer sentiments, details, and media about customer success',
        ch_collateral: 'Standard materials used to help defend your value. Usually created by marketing',
        ch_thresholds: 'Tolerance measurements to help users understand if their value converstion could be challenged',
        required_inputs: `The below sections are required to build a model properly.
         Sections that have no entries are red with a zero (0) total.`,
        benefits: 'Benefits',
        overview: 'Overview',
        costs: 'Costs',
        situations: 'Situations',
        competitors: 'Competitors',
		case_studies: 'Case Studies',
		collateral: 'Collateral',
		thresholds: 'Thresholds',
        factors: 'Factors',
        goals: 'Goals',
        scalers: 'Set Scaler Defaults',
        validation: 'Validation',
        dashboard: 'Dashboard',
        models: 'Models',
        notRightAccess: 'You do not have the right access to modify models'
    };
}
