import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VPDashboardAssetsDeliverableComponent } from './deliverable.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { Translations } from '@data/services/translation/translation.service';
import { FileUploadModule } from 'primeng/fileupload';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DeliverableVpDashTranslations } from './deliverable.translation';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';


@NgModule({
	imports: [
		SidebarModule,
		FileUploadModule,
		EditTranslationsModule,
		CommonModule,
		FormsModule,
		ApplicationPipesModule,
		EllipsisMenuModule
	],
	declarations: [
		VPDashboardAssetsDeliverableComponent
	],
	providers: [
		Translations,
		TranslationsV2Service,
		DeliverableVpDashTranslations
		],
	exports: [VPDashboardAssetsDeliverableComponent]
})
export class VPDashboardAssetsDeliverableModule {}
