import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccountService } from '@data/services/account/account.service';
import { CompanyService } from '@data/services/company/company.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { AddCompanyCaseStudyModule } from '../add-company/add-company.module';
import { AddCasestudyComponent } from './add-casestudy.component';
import { AddCaseStudyDetailTranslations } from './add-casestudy.translation';

@NgModule({
  imports: [
    DropdownModule,
    ChipsModule,
    TableModule,
    ProgressBarModule,
    NgbModule,
    InputSwitchModule,
    AddCompanyCaseStudyModule,
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    EditTranslationsModule,
    AreYouSureModule
  ],
  declarations: [AddCasestudyComponent],
  providers: [Translations, CompanyService, AccountService, TranslationsV2Service, AddCaseStudyDetailTranslations],
  exports: [AddCasestudyComponent],
})
export class AddCaseStudyModule {}
