import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { NotificationService } from '@services/notification.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CreateVPService } from '@data/services/create_vp/create_vp.service';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './search-companyv2.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Company } from '@shared/models/company.model';
import { MasterValueProp } from '@data/services/valueposition/models/mast-value-prop.interface';
import { StyleService } from 'app/style.service';


@Component({
	selector: 'app-search-company-v2',
	templateUrl: './search-company.component.v2.html',
	styleUrls: ['./search-company.component.v2.scss']
})
// tslint:disable-next-line: component-class-suffix
export class SearchCompanyComponentV2 implements OnInit, OnDestroy {
	@Input() companyDetails: Company;
	@Input() index: number;
	@Input() value_prop_group: MasterValueProp;
	masterValuePropId: string | number;
	companiesList: Company[] = [];
	companyList = true;
	detailedDescription = true;
	companyId: number;
	payload: {[key: string]: any};
	showCompanyAdd = false;
	fullImagePath: string;
	quickCompanyDetailsDescription = true;

	pageNumber: number;
	pageNumberSearch = 1;

	pageLimit: number;
	pageLimitSearch = 'all';
	searchTypErr = false;
	order = 'asc';
	sortBy = 'name';
	companyname: string;
	companiesNoResult = false;
	showActionLoader = false;
	crm: {
    sfoide?: string;
    company_id?: number;
    opportunity_id?: string;
    account_defined_id?: string
  } = {};
	sfoide: string;

	contextualHelp = {};
	enableSaveBtn: boolean;

	subscriptioncontextualHelp: Subscription;
	subscriptiongetSearchCompanies: Subscription;
	subscriptiongetCompanySummary: Subscription;

	@Input() valuePropId: string | number;
	@Input() showTranslate: boolean;
	@Input() ValuePropTerm: any;
	@Input() isvalueProp: boolean;
	@Input() accountDefinedId: boolean;
	@Input() opportunityId: number | string;
	@Input() isOppurtunityEnabled: boolean;
	@Input() aid: string;
	@Input() masterValueProp: MasterValueProp;

	addCompany = false;
	ngUnsubscribe$ = new Subject();
	style2022$: Observable<boolean>;

	constructor(
		private accountService: AccountService,
		private companyService: CompanyService,
		private notificationService: NotificationService,
		private commonService: CommonService,
		private VPGroup: CreateVPService,
		public trans: DefaultTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {
		this.fullImagePath = this.commonService.getLoaderImageUrl();
		if (sessionStorage.getItem('crm')) {
			this.crm = JSON.parse(sessionStorage.getItem('crm'));
			this.sfoide = this.crm.sfoide;
			if (this.crm.company_id) {
				this.companyId = this.crm.company_id;
				this.selectCompany(this.companyId);
			}
    }
	}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;
		this.getRecentSearchComp();
		this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe(response => {
			this.contextualHelp = response['Model'];
		});

		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans.trans
		};

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	ngOnDestroy(): void {
		if (this.subscriptioncontextualHelp) { this.subscriptioncontextualHelp.unsubscribe(); }
		if (this.subscriptiongetSearchCompanies) { this.subscriptiongetSearchCompanies.unsubscribe(); }
		if (this.subscriptiongetCompanySummary) { this.subscriptiongetCompanySummary.unsubscribe(); }
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
	}

	selectCompany(params): void {
		this.companyList = true;
		this.companyId = params;
		this.showCompanyAdd = false;
		this.getCompanyDetail();
	}

	getCompanyDetail(): void {
		this.showActionLoader = true;
		this.subscriptiongetCompanySummary = this.companyService.getCompanySummary(this.companyId).subscribe((result) => {
			this.companyDetails = result.result.company;
			this.quickCompanyDetailsDescription = false;
			this.detailedDescription = false;
			this.showActionLoader = false;
			this.createVPGroup();
		});
	}

	createVPGroup(): void {
		if (this.crm.sfoide) {
			const payload = {
				'company_id': this.companyDetails.id,
				'sfoide': this.crm.sfoide,
				'group_type_id': '1'
			};

			this.VPGroup.createValuePropGroup(payload).subscribe((result) => {
				this.commonService.callbackVPCreateSave.next({
					index: this.index,
					badge: 'complete',
					progress: [
						{ title: 'Company Name', value: this.companyDetails.name },
						{ title: 'Revenue', value: this.companyDetails.revenue_fmt },
						{ title: 'Employees', value: this.companyDetails.employees_fmt }
					],
					companyDetails: this.companyDetails,
					value_prop_group: result.result.value_prop_group
				});
			});
		}else {
			const payload = {
				'company_id': this.companyDetails.id,
				'group_type_id': '1'
			};

			this.VPGroup.createValuePropGroup(payload).subscribe((result) => {
				this.commonService.callbackVPCreateSave.next({
					index: this.index,
					badge: 'complete',
					progress: [
						{ title: 'Company Name', value: this.companyDetails.name },
						{ title: 'Revenue', value: this.companyDetails.revenue_fmt },
						{ title: 'Employees', value: this.companyDetails.employees_fmt }
					],
					companyDetails: this.companyDetails,
					value_prop_group: result.result.value_prop_group
				});
			});
		}
	}

	openAddCompany(): void {
		this.addCompany = true;
	}

	getRecentSearchComp(): void {
		if (this.companyname && this.companyname !== null && this.companyname !== '') {
			this.companyList = true;
			this.companiesNoResult = false;
			this.showActionLoader = true;
			this.payload = {
				'searchTerm': this.companyname,
				'publicOnly': 0,
				'synopsisOnly': 1
			};
			this.payload['limit'] = this.pageLimitSearch;
			this.payload['page'] = this.pageNumberSearch;
			this.payload['sortBy'] = this.sortBy;
			this.payload['order'] = this.order;
			this.subscriptiongetSearchCompanies = this.accountService.getSearchCompanies(this.payload).subscribe(
				(response) => {
					this.showCompanyAdd = true;
					if (response.result && response.result.data && response.result.data.length > 0) {
						this.companiesList = response.result.data;
						this.companyList = false;
						this.detailedDescription = true;
					} else {
						this.companiesNoResult = true;
						this.quickCompanyDetailsDescription = true;
					}
					this.showActionLoader = false;
				}, error => {
					this.notificationService.error(error.result, false);
				});
		} else {
			this.companiesList = [];
			this.companiesNoResult = true;
			this.companyList = true;
		}
	}

	companyCreated($event): void {
		this.addCompany = false;
		this.companyname = $event.name;
		this.getRecentSearchComp();
	}

	enableSave($event): void {
		if ($event == 'true') {
			this.enableSaveBtn = true;
		} else {
			this.enableSaveBtn = false;
		}
	}


}

