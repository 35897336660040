<div class="keywest_panel">
  <form [formGroup]="goalForm">
    <div class="row">
      <div class="col-xs-12">
        <app-error-input-wrapper [errorMessage]="errorDict.get('name')" [showOnTouched]="true">
          <label class="sidebar_label labelsRequired">{{ trans.trans.nameLabel.value }}</label>
          <input class="form-control" type="text" [placeholder]="trans.trans.nameLabel.value" formControlName="name" />
        </app-error-input-wrapper>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 form-group">
        <label class="sidebar_label">{{ trans.trans.descriptionLabel.value }}</label>
        <textarea class="form-control" id="" cols="30" rows="5" [placeholder]="trans.trans.descriptionLabel.value" formControlName="description"></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 form-group">
        <label class="sidebar_label">{{ trans.trans.unitTypeLabel.value }}</label>
        <p-dropdown
          appendTo="body"
          panelStyleClass="dropdown-item-font"
          class="keywest_dropdown dropdown-theme"
          formControlName="unitType"
          optionLabel="name"
          [options]="unitTypes"
          [filter]="true"
          filterBy="name"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <app-error-input-wrapper [errorMessage]="errorDict.get('precision')" [showOnTouched]="true">
          <label class="sidebar_label">{{ trans.trans.precisionLabel.value }}</label>
          <input type="number" class="form-control" formControlName="precision" />
        </app-error-input-wrapper>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 form-group">
        <label class="sidebar_label">{{ trans.trans.goalGroupLabel.value }}</label>
        <p-dropdown
          appendTo="body"
          panelStyleClass="dropdown-item-font"
          class="keywest_dropdown dropdown-theme"
          formControlName="goalGroup"
          optionLabel="name"
          [options]="goalGroups"
          [filter]="true"
          filterBy="name"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label for="name" class="sidebar_label">{{ trans.trans.baseline.value }} </label><br />
        <p-inputSwitch formControlName="captureBaseline" name="capture_baseline"></p-inputSwitch>
        {{ +baselineController.value ? trans.trans.yes.value : trans.trans.no.value }}
      </div>
      <div class="col-md-4">
        <label for="name" class="sidebar_label">{{ trans.trans.target.value }} </label><br />
        <p-inputSwitch formControlName="captureTarget" name="capture_target"></p-inputSwitch>
        {{ +targetController.value ? trans.trans.yes.value : trans.trans.no.value }}
      </div>
      <div class="col-md-4">
        <label for="name" class="sidebar_label">{{ trans.trans.keyFactor.value }} </label><br />
        <p-inputSwitch formControlName="isKeyFactor" name="is_key_factor"></p-inputSwitch>
        {{ +keyFactorController.value ? trans.trans.yes.value : trans.trans.no.value }}
      </div>
    </div>
    <ng-container *ngIf="!vpId">
      <div class="row">
        <div class="col-xs-12 form-group">
          <label class="sidebar_label">{{ trans.trans.iconLabel.value }}</label>
          <p-dropdown
            appendTo="body"
            panelStyleClass="dropdown-item-font"
            class="keywest_dropdown dropdown-theme"
            formControlName="icon"
            optionLabel="label"
            dataKey="id"
            [options]="faIcons"
            [filter]="true"
            filterBy="label, value"
          >
            <ng-template let-item pTemplate="selectedItem">
              <i [class]="item.value.value" style="float: left; display: block; font-size: 18px; margin-right: 10px"></i>
              <span style="vertical-align: middle">{{ item.label }}</span>
            </ng-template>
            <ng-template let-icons pTemplate="item">
              <div class="p-helper-clearfix" style="position: relative; height: 25px">
                <i [class]="icons.value.value" style="float: left; display: block; font-size: 18px; margin-right: 10px"></i>
                <span style="vertical-align: middle">{{ icons.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 form-group">
          <label class="sidebar_label">{{ trans.trans.linkGoal.value }}</label>
          <p-multiSelect
            appendTo="body"
            panelStyleClass="multi-select-item-font"
            class="keywest_dropdown multi-select-theme"
            [options]="availableFactors"
            [(ngModel)]="selectedFactors"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="!availableFactors"
            [placeholder]="trans.trans.selectFactor.value"
            optionDisabled="linked"
          >
            <ng-template let-item pTemplate="item"> {{ item.value !== '0' && item.linked ? '(' + trans.trans.alreadyLinked.value + '): ' : '' }}{{ item.label }} </ng-template>
          </p-multiSelect>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 form-group">
          <label class="sidebar_label">{{ trans.trans.goalTypeLabel.value }}</label>
          <p-dropdown appendTo="body" panelStyleClass="dropdown-item-font" class="keywest_dropdown dropdown-theme" [options]="goalTypes"> </p-dropdown>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<div class="btn_container">
  <div class="row">
    <div class="col-xs-12">
      <button pButton class="btn btn_customizable pull-right mr-50" (click)="saveGoal()" [disabled]="goalForm.invalid" [loading]="isLoading">{{ trans.trans.save.value }}</button>
      <button class="btn btn_cancel pull-right" style="margin-right: 10px" (click)="goBack()" [disabled]="isLoading">{{ trans.trans.cancel.value }}</button>
    </div>
  </div>
</div>
