import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { SNCxOComponent } from './cxo.component';

import { SidebarModule } from 'primeng/sidebar';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { RouterModule, Routes } from '@angular/router';
import { SearchCompanyV3Module } from 'app/value-position/search-companyv3/search-companyv3.module';
import { RSV2AccountModule } from 'app/repv2/views/scenario_combined/views/account/account.module';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
	{
		path: 'cxo',
		component: SNCxOComponent,
		data: { view: "cxo", page: 'cxo' }
	},
	{
		path: 'cxo/:vpid',
		component: SNCxOComponent,
		data: { view: "cxo", page: 'cxo' }
	}
]

@NgModule({
	imports: [
		RSV2AccountModule,
		SearchCompanyV3Module,
		AreYouSureModule,
    MatTooltipModule,
		CommonModule,
		FormsModule,
		SidebarModule,
		RouterModule.forChild(routes),

	],
	declarations: [
		SNCxOComponent
	],
	exports: [SNCxOComponent],
	entryComponents: [SNCxOComponent]
})
export class SNCxOModule { }
