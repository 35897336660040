import { Benefit } from '@shared/models/value-prop.model';
import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Subject } from 'rxjs';
import { SolutionReportTranslations } from './solution-report.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { BenefitOnFly, BenefitRemoved, LowestRatedBenefit } from '@shared/models/benefits.models';
import { SolutionFactor } from '@shared/models/factors.models';

@Component({
  selector: 'app-solution-report',
  styleUrls: ['./solution-report.component.scss'],
  templateUrl: './solution-report.component.html',
})
export class SolutionReportComponent implements OnInit, OnDestroy {
  subscriptiongetTopOnFlyBenefits: Subscription;
  subscriptiongetBenefitsRemoved: Subscription;
  subscriptiongetDriverFactors: Subscription;
  subscriptiongetFinancialFactors: Subscription;
  subscriptiongetImprovementFactors: Subscription;
  subscriptiongetLowestRatedBenefits: Subscription;
  subscriptiongetUserCommentsBenefits: Subscription;

  factorExpand = true;
  benefitsOftenRemovedExpand = true;
  topOnTheFlyBenefitsExpand = true;
  lowestRatedBenefitsExpand = true;
  userCommentsOnBenefitsExpand = true;
  breadcrumbKeyValues: Breadcrumbs;

  ngUnsubscribe = new Subject();

  style2022$: Observable<boolean>;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: SolutionReportTranslations,
    private styleService: StyleService
  ) {}

  topOnFlyBenefitsList: Array<BenefitOnFly> = [];

  benefitsRemovedList: Array<BenefitRemoved> = [];
  driverFactorList: Array<SolutionFactor> = [];
  financialFactorList: Array<SolutionFactor> = [];
  improvementFactorList: Array<SolutionFactor> = [];
  LowestRatedBenefits: Array<LowestRatedBenefit> = [];
  UserCommentsBenefits: Array<{ [klass: string]: any }> = [];
  userBenefit: Benefit;
  modalReference: NgbModalRef;
  rating = 2;
  showTranslate = false;

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getTopOnFlyBenefits();
    this.getBenefitsRemoved();
    this.getDriverFactors();
    this.getFinancialFactors();
    this.getImprovementFactors();
    this.getLowestRatedBenefits();
    this.getUserCommentsBenefits();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.solution_reports.value]: { routerLink: '', active: true },
        };
      });
  }

  getTopOnFlyBenefits() {
    this.subscriptiongetTopOnFlyBenefits = this.accountService
      .getTopOnFlyBenefits()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.topOnFlyBenefitsList = response.result;
        }
      });
  }

  getBenefitsRemoved() {
    this.subscriptiongetBenefitsRemoved = this.accountService
      .getBenefitsRemoved()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.benefitsRemovedList = response.result;
        }
      });
  }

  getDriverFactors() {
    this.subscriptiongetDriverFactors = this.accountService
      .getDriverFactors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.driverFactorList = response.result;
        }
      });
  }

  getFinancialFactors() {
    this.subscriptiongetFinancialFactors = this.accountService
      .getFinancialFactors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.financialFactorList = response.result;
        }
      });
  }

  getImprovementFactors() {
    this.subscriptiongetImprovementFactors = this.accountService
      .getImprovementFactors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.improvementFactorList = response.result;
        }
      });
  }

  getLowestRatedBenefits() {
    this.subscriptiongetLowestRatedBenefits = this.accountService
      .getLowestRatedBenefits()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.LowestRatedBenefits = response.result;
        }
      });
  }
  getUserCommentsBenefits() {
    this.subscriptiongetUserCommentsBenefits = this.accountService
      .getUserCommentsBenefits()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.UserCommentsBenefits = this.generateArray(response.result);
        }
      });
  }
  generateArray(obj) {
    return Object.keys(obj).map((key) => {
      return obj[key];
    });
  }
  showComments(content, benefit) {
    this.userBenefit = benefit;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }
}
