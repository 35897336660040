import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Metric } from '@shared/models/value-prop.model';
import { ScratchpadDefault } from 'app/value-map-maintenance/views/factors/interfaces/factors.interface';

@Component({
	selector: 'app-scratchpad-modal',
	templateUrl: './scratchpad-modal.component.html',
	styleUrls: ['./scratchpad-modal.component.scss']
})
export class ScratchpadModalComponent implements OnInit, OnDestroy {
	@Input() factorTypeId: number;
	@Input() valuePropId: string;
	@Input() valueMetric: Metric;

	scratchPadName: string;
	scratchpadFactorName: string;
	scratchpadList: ScratchpadDefault;
	isScratchPad = false;
	fullImagePath: string;
	total: number = undefined;
	hideActionLoader = true;
	operatorList: Array<any>;

	ngUnsubscribe = new Subject();

	constructor(
		private CommonService: CommonService,
		private ValuepropService: ValuepropService,
		private NotificationService: NotificationService,
		public activeModal: NgbActiveModal,
		private translateService: TranslateService,
		public trans: Translations
	) {

		this.fullImagePath = this.CommonService.getLoaderImageUrl();
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit() {
		this.getScratchpadDetail();
		this.scratchpadOperatorTypes();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getScratchpadDetail() {

		let factor_id;
		if (this.factorTypeId.toString() == "1") {
			this.scratchPadName = "Driver Factor Scratchpad";
			factor_id = this.valueMetric.driver_factor_id;
		}
		else if (this.factorTypeId.toString() == "2") {
			this.scratchPadName = "Financial Factor Scratchpad";
			factor_id = this.valueMetric.financial_factor_id;
		}
		else if (this.factorTypeId.toString() == "3") {
			this.scratchPadName = "Improvement Factor Scratchpad";
			factor_id = this.valueMetric.improvement_factor_id;
		}

		let payload = this.valuePropId + "/valuePropMetricID/" + this.valueMetric['id'] + "/scratchpadFactorTypeID/" + this.factorTypeId + "/scratchpadFactorID/" + factor_id + "/testing123/0";

		this.ValuepropService.readDriverFactorScratchPad(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.isScratchPad = true;
				this.scratchpadList = response.result;

				this.scratchpadFactorName = response.result.scratchpad.factor_name;
			}
		});

	}

	scratchpadOperatorTypes() {
		this.ValuepropService.scratchpadOperatorTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.operatorList = response.result;
			}
		});
	}

	saveAndCalcScratchpad(mode) {
		this.hideActionLoader = false;
		let scratchpad_factor_id;
		let value_prop_metric_id = "";
		let factor = [];
		let operator = [];
		let openendValue = [];
		let openParen = [];
		let closeParen = [];

		if (this.factorTypeId == 2) {
			scratchpad_factor_id = this.valueMetric.financial_factor_id;
		}
		else if (this.factorTypeId == 1) {
			scratchpad_factor_id = this.valueMetric.driver_factor_id;
		}
		else if (this.factorTypeId == 3) {
			value_prop_metric_id = this.valueMetric.id;
			scratchpad_factor_id = this.valueMetric.improvement_factor_id;
		}
		else if (this.factorTypeId === 4) {

		}

		this.scratchpadList['units'].forEach(obj => {
			factor.push(obj.factor_id);
			operator.push(obj.operator_id);
			openendValue.push(obj.operand);
			openParen.push(obj.open_paren);
			closeParen.push(obj.close_paren);
		});

		let factor_id = factor.join(",");
		let operator_id = operator.join(",");
		let operand = openendValue.join(",");
		let open_paren = openParen.join(",");
		let close_paren = closeParen.join(",");


		let payload = {
			scratchpad_factor_type_id: this.factorTypeId,
			scratchpad_factor_id: scratchpad_factor_id,
			value_prop_metric_id: value_prop_metric_id,
			factor_id: factor_id,
			operator_id: operator_id,
			operand: operand,
			open_paren: open_paren,
			close_paren: close_paren
		}

		if (mode == "0") {
			this.ValuepropService.readScratchpadTotal(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result.total || response.result.total == '0') {
					// this.ValuepropService.refreshDashboard.next('benefitChange');
					this.total = response.result.total;
					this.hideActionLoader = true;
				}
			});
		}
		else if (mode === "1") {
			this.ValuepropService.updateScratchpad(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result && (response.result.success !== false)) {
					this.ValuepropService.refreshDashboard.next('benefitChange');
					this.NotificationService.success("Data saved Successfully.", false);
					this.hideActionLoader = true;
					this.activeModal.close();
					this.ValuepropService.readScratchpadTotal(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
						if (response.result.total) {
							this.total = response.result.total;
							this.hideActionLoader = true;
							this.activeModal.close();

						}
					});
				}
				else if (response.result.success === false) {
					this.hideActionLoader = true;
					this.NotificationService.error(response.result.message, false); // Params {message, islogout}
					this.hideActionLoader = true;
				}
			});
		}
	}

	clearAnswer() {
		this.scratchpadList['units'].forEach(obj => {
			obj.operand = 0;
			this.total = 0;
		});
	}

}
