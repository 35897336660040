import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
	public config: any = {
		component: 'notes',
		display_name: 'Notes VP Dashboard',
		description: 'Notes tab in vp dashboard top panel'
    }
    public trans: any = {
      notes: 'Notes',
      user_name: 'User',
      name     : 'Name',
      created  : 'Created',
      note     : 'Note',
      delete   : "Delete",
      save     : 'Save',
      edit     : "Edit",
      ch_notes: "Notes that you have captured during the process of iterating your value conversation.",
      no_notes_found: "No notes found",
      add_note: "Add Note",
      add_new: 'Add New',
      edit_note: 'Edit Note',
		  customer_facing: "Customer Facing",
      are_you_sure: 'Are you sure?',
      back: 'Back',
      add: 'Add'
    }
}
