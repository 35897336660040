import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TickerComponent } from './ticker.component';

@NgModule({
  declarations: [TickerComponent],
  imports: [CommonModule],
  exports: [TickerComponent],
})
export class TickerModule {}
