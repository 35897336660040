import { Component, Input, AfterViewInit } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';

declare global {
  interface Window {
    horizontalBarChart: Chart;
  }
}

@Component({
  selector: 'app-horizontal-bar',
  templateUrl: './horizontalbar.component.html',
  styleUrls: ['./horizontalbar.component.scss'],
})
export class HorizontalBarComponent implements AfterViewInit {
  @Input('data') chartData: ChartDataSets['data'];
  @Input('options') options: ChartOptions;
  @Input('legend') legend = true;
  @Input('labels') labels: string[];
  @Input('colors') colors: string[];
  @Input('fontColor') fontColor: string;

  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  dataMap = new Map();
  selectedIndexes: number[] = [];
  filteredColors: string[] = [];
  filteredLabels: string[] = [];
  filteredData: ChartDataSets['data'] = [];

  ngAfterViewInit(): void {
    this.enumerateObjects();
    this.buildChart();
  }

  enumerateObjects(): void {
    const fixedData: (string | number | number[] | Chart.ChartPoint)[][] = [];

    for (let i = 0; i < this.chartData.length; i++) {
      fixedData.push([this.labels[i], this.chartData[i], this.colors[i]]);
    }
    const dataSorted = fixedData.sort((a, b) => {
      const aElement = a[1];
      const bElement = b[1];
      if (typeof aElement === 'number' && typeof bElement === 'number') { 
        return bElement - aElement
      } else if (typeof aElement === 'string' && typeof bElement === 'string') {
        const anormalized = aElement.toLowerCase();
        const bnormalized = bElement.toLowerCase();
        if (anormalized < bnormalized){
          return -1
        }else if (anormalized > bnormalized){
          return 1
        } else {
          return 0
        }
      } else if (Array.isArray(aElement) && Array.isArray(bElement)){
        return bElement.reduce((partialSum, b) => partialSum + b, 0) - aElement.reduce((partialSum, a) => partialSum + a, 0);
      } else if(typeof aElement === 'object' && !Array.isArray(aElement) && typeof bElement === 'object' && !Array.isArray(bElement)){
        return Number(bElement.y) - Number(aElement.y)
      }
    });

    dataSorted.forEach((el, index) => {
      this.dataMap.set(index, el);
    });

    this.dataFilter();
  }

  dataFilter(): void {
    this.filteredLabels = [];
    this.filteredData = [];
    this.filteredColors = [];
    this.dataMap.forEach((el, index) => {
      if (!this.selectedIndexes.includes(index)) {
        this.filteredLabels.push(el[0]);
        this.filteredData.push(el[1]);
        this.filteredColors.push(el[2]);
      }
    });
  }

  insertDataIndex(event: MouseEvent): void {
    const index = +(event.target as Element ).getAttribute('data-index');
    if (!this.selectedIndexes.includes(index)) {
      this.selectedIndexes.push(index);
    } else {
      this.selectedIndexes = this.selectedIndexes.filter((el) => el != index);
    }
    this.dataFilter();
    this.buildChart();
  }

  buildChart(): void {
    if (document.getElementById('myChart')) {
      document.getElementById('myChart').remove();
    }

    const container = document.getElementById('canvas-container');
    const canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'myChart');
    this.canvas = canvas;
    container.appendChild(this.canvas);
    this.ctx = this.canvas.getContext('2d');
    const myChart = new Chart(this.ctx, {
      type: 'horizontalBar',
      data: {
        labels: this.filteredLabels,
        datasets: [
          {
            data: this.filteredData,
            backgroundColor: this.filteredColors,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: this.fontColor ? this.fontColor : '#5f5f5f',
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        legendCallback: () => {
          if (!this.legend) {
            return '';
          }
          const text: string[] = [];
          for (let [key, value] of this.dataMap) {
            text.push(`<div class="label-container" style="width: 100%; clear: both; margin-bottom: 10px; display: block; height: 15px;" data-index="${key}">`);
            text.push('<div style="background-color: ' + value[2] + `; height: 15px; width: 40px; display: block; float: left; clear: both;" data-index="${key}"></div>`);
            text.push(
              `<span style=" ${this.selectedIndexes.includes(key) ? 'text-decoration:line-through;' : ''} float: left; margin-left: 5px; font-size: 12px;" data-index="${key}">` +
                value[0] +
                '</span>'
            );
            text.push('</div>');
          }
          return text.join('');
        },
      },
    });

    window.horizontalBarChart = myChart;
    document.getElementById('chart-legends').innerHTML = myChart.generateLegend() as string;
    document
      .getElementById('chart-legends')
      .querySelectorAll('.label-container')
      .forEach((el) => {
        el.addEventListener('click', this.insertDataIndex.bind(this));
      });
  }
}
