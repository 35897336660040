import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardImplementation } from '../../dashboard.implementation';
import { Observable, Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CasestudyDashboardTranslations } from './casestudy.translation';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { AssetService } from '@data/services/asset/asset.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';

@Component({
  selector: 'app-dashboard-casestudy',
  templateUrl: './casestudy.component.html',
})
export class DashboardCaseStudyComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  showTranslate = false;
  caseStudies: { [klass: string]: any }[] = [];

  loading = false;
  seeMore = false;
  sidebarTitle = '';
  toggleAddCaseStudy = false;
  assetGenerator: string;
  assetURL: string;
  style2022$: Observable<boolean>;
  columns:Array<{field: string; header: string, sortField: string}> = [];

  constructor(
    public dbI: DashboardImplementation,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: CasestudyDashboardTranslations,
    private solutionService: SolutionService,
    private assetService: AssetService,
    private notificationService: NotificationService,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.assetURL = this.commonService.getAssetUrl();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
         this.columns = [
          {
            field: 'case_name',
            header: '',
            sortField: ''
          },
         ];
      });

  }

  getData(seemore?): void {
    this.loading = true;
    let searchLimit = '';
    if (seemore) {
      searchLimit = '/limit/all/page/' + 1;
    } else {
      searchLimit = '/limit/' + 5 + '/page/' + 1;
    }

    this.solutionService.getRecentCaseStudies(searchLimit).subscribe((response) => {
      if (response.result) {
        this.caseStudies = response.result.case_studies.data;
        this.loading = false;
      }
    });
  }

  funcGetModelsLink(caseStudy): string {
    return '/case_study/' + caseStudy.id + '/' + caseStudy.is_account_solution;
  }

  funcAddedCaseStudy(): void {
    this.toggleAddCaseStudy = false;
    this.getData();
  }

  downloadAsset(id): void {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.assetService.getCaseStudyAssetTemplate().subscribe((response) => {
      if (response && response !== null && typeof response !== 'string') {
        const downloadUrl =
          this.assetURL +
          'assets/' +
          this.assetGenerator +
          '?account_asset_template_id=' +
          response.result.id +
          '&user_id=' +
          userId +
          '&token=' +
          token +
          '&case_study_id=' +
          id +
          '&is_account_solution=' +
          1;
        window.location.assign(downloadUrl);
      } else {
        this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }
}
