import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { UserService } from '@data/services/user/user.service';
import { takeUntil, take } from 'rxjs/operators';
import { BottomPanelTranslations } from './bottom.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Benefit, Metric, ValueProp } from '@shared/models/value-prop.model';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-panel-bottom',
    templateUrl: './bottom.component.html',
})
export class DashboardBottomComponent implements OnInit, OnChanges, OnDestroy {
    @Input() benefitDetail: boolean;
    @Input() contextualHelp: string[] = [];
    @Input() dashboard: string;
    @Input() discoveryQuestions: boolean;
    @Input() explained:  any[];
    @Input() firstLoad = true;
    @Input() fromSimple = false;
    @Input() hasKeyAssumptions: boolean;
    @Input() isDev: boolean;
    @Input() loadBenefit: string;
    @Input() menuFilter: MenuItem;
    @Input() no_reload = false;
    @Input() permissions: { [klass: string]: any };
    @Input() selectedBenefit: Metric;
    @Input() showHelp: boolean;
    @Input() showTranslate = false;
    @Input() valueProp: ValueProp;
    @Input() valuePropId: string;
    @Input() valuePropLoader = false;
    @Input() viewBottom = 'account';
    @Input() showAllTcoMetrics = false;

    @Output() CallbackSelectedBenefit = new EventEmitter();
    @Output() callbackExplained = new EventEmitter();
    @Output() callbackSetTitleBottom = new EventEmitter();

    menuBottom: MenuItem[] = [];
    imageUrl: string;
    fullImagePath: string;
    benefitOnTheFlyPrivilege: boolean;
    graduatedDeploymentFeature: boolean;
    accountId: string;
    accountFeatures: string;
    privileges: string[];

    userId: string;
    assetsDeliverable = false;
    assetsPrompters = false;
    advancedTab = false;
    solutionId: number;

    feature52 = false;
    feature106 = false;

    modelsV2 = false;

    showEditValue = true;
    viewBottomTitle = 'Account';
    feature74 = false;

    loadingMenu = false;

    ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private valuePropService: ValuepropService,
        private translationService: TranslationsV2Service,
        public trans: BottomPanelTranslations,
        private commonService: CommonService,
        private userService: UserService
    ) {
        this.imageUrl = this.commonService.getImageUrl();
        this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    }

    ngOnInit() {
        this.feature74 = this.commonService.checkFeature(74);
        this.feature106 = this.commonService.checkFeature(106);

        this.valuePropService.getDiscoveryQuestions(this.valueProp.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response) => {
                if (response.result.length) {
                    this.discoveryQuestions = true;
                }
                this.funcBuildMenu();
            });

        if (this.valueProp.has_key_assumptions) {
            this.hasKeyAssumptions = true;
        }
        this.getTranslations();

        this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.getTranslations();
        });

        if (localStorage.getItem('showEditValue') == null) {
            localStorage.setItem('showEditValue', this.showEditValue.toString());
        }
        this.showEditValue = JSON.parse(localStorage.getItem('showEditValue'));

        if (this.fromSimple && this.firstLoad) {
            this.viewBottom = 'benefits';
        } else {
            const recentMenu = localStorage.getItem('vpdashboardMenuBottom');
            this.viewBottom = recentMenu ? recentMenu : 'benefits';
        }
        if (!this.valuePropId) {
            this.valuePropId = this.valueProp.id ? this.valueProp.id : this.valuePropId;
        }

        this.feature52 = this.commonService.checkFeature(52);

        if (this.firstLoad) {
            if (this.feature52 && !this.feature106) {
                this.viewBottom = 'fastfill';
            } else {
                this.viewBottom = 'benefits';
            }
        }
        this.firstLoad = false;

        this.userId = sessionStorage.getItem('uid');

        this.accountId = sessionStorage.getItem('aid');
        this.parseFeatures();

        this.getSolutionId();
        this.accountFeatures = sessionStorage.getItem('features');
        if (this.commonService.checkFeature('12')) {
            this.graduatedDeploymentFeature = true;
        }

        if (this.commonService.checkFeature('35')) {
            this.assetsDeliverable = true;
        }

        if (this.commonService.checkFeature('36')) {
            this.assetsPrompters = true;
        }

        if (!this.contextualHelp && !this.contextualHelp.length) {
            this.userService
                .getContextualHelpList()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((result) => {
                    if (result.result) {
                        this.commonService.contextualHelp.next(result.result);
                    }
                });
        }

        //feature to remove advanced tab
        if (this.commonService.checkFeature('40')) {
            this.advancedTab = true;
        }

        this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
            this.contextualHelp = response['Valueprop'];
        });

        const privs = sessionStorage.getItem('privileges');
        this.privileges = privs.split(',');
        if (this.privileges.length) {
            if (this.privileges.indexOf('6') >= 0) {
                this.benefitOnTheFlyPrivilege = true;
            }
        }

        this.valuePropService.gotoMenuItem.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            let menu = res.menu;
            let data = res.data;
            let panel = res.panel;

            localStorage.setItem(res.panel, menu);
            if (panel == 'vpdashboardMenuBottom') {
                this.viewBottom = menu;
            } else {
            }
            switch (menu) {
                case 'benefit_detail':
                    this.selectedBenefit = data;
                    break;
            }
        });

        this.valuePropService.rebuildMenu.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.getTranslations();
        });
    }

    ngOnChanges() {
        this.funcBuildMenu();
        console.clear();
    }

    getTranslations() {
        const langId = sessionStorage.getItem('language_type_id');
        const langAbbr = this.translationService.getLanguageAbbr(langId);

        const payload = {
            account_id: sessionStorage.getItem('aid'),
            component: this.trans.config.component,
            lang: langAbbr,
            localTranslations: this.trans.trans
        };
        this.translationService
            .getComponentTrans(payload)
            .pipe(take(1))
            .subscribe((res) => {
                this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
                this.funcBuildMenu();
            });
    }

    funcBuildMenu() {
        this.loadingMenu = true;
        this.menuBottom = [];
        let items = [];
        if (this.feature52) {
            this.menuBottom.push({
                label: 'Data & Assumptions',
                command: () => {
                    this.viewBottom = 'fastfill';
                    this.viewBottomTitle = 'Data & Assumptions';
                    localStorage.setItem('vpdashboardMenuBottom', 'fastfill');
                    localStorage.setItem('vpdashboardMenuBottomTitle', 'Data & Assumptions');
                },
            });
            // this.menuBottom.push({ separator: true });
        }
        if (!this.advancedTab) {
            items.push({
                label: this.trans.trans.account.value,
                styleClass: this.viewBottom == 'account' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'account';
                    this.viewBottomTitle = this.trans.trans.scenario.value + ' > ' + this.trans.trans.account.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'account');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.scenario.value + ' > ' + this.trans.trans.account.value
                    );
                    this.funcBuildMenu();
                },
            });
            items.push({
                label: this.trans.trans.models.value,
                styleClass: this.viewBottom == 'models' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'models';
                    this.viewBottomTitle = this.trans.trans.scenario.value + ' > ' + this.trans.trans.models.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'models');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.scenario.value + ' > ' + this.trans.trans.models.value
                    );
                    this.funcBuildMenu();
                },
            });
            items.push({
                label: this.trans.trans.situations.value,
                styleClass: this.viewBottom == 'situations' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'situations';
                    this.viewBottomTitle = this.trans.trans.scenario.value + ' > ' + this.trans.trans.situations.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'situations');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.scenario.value + ' > ' + this.trans.trans.situations.value
                    );
                    this.funcBuildMenu();
                },
            });
            items.push({
                label: this.trans.trans.advanced.value,
                styleClass: this.viewBottom == 'advanced' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'advanced';
                    this.viewBottomTitle = this.trans.trans.scenario.value + ' > ' + this.trans.trans.advanced.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'advanced');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.scenario.value + ' > ' + this.trans.trans.advanced.value
                    );
                    this.funcBuildMenu();
                },
            });

            if (this.feature74) {
                items.push({
                    label: 'Maturity',
                    styleClass: this.viewBottom == 'maturity' ? 'menuSelected' : '',
                    command: () => {
                        this.viewBottom = 'maturity';
                        this.viewBottomTitle = this.trans.trans.scenario.value + ' > ' + 'Maturity';
                        localStorage.setItem('vpdashboardMenuBottom', 'advanced');
                        localStorage.setItem(
                            'vpdashboardMenuBottomTitle',
                            this.trans.trans.scenario.value + ' > ' + 'Maturity'
                        );
                        this.funcBuildMenu();
                    },
                });
            }
            this.menuBottom.push({
                label: this.trans.trans.scenario.value,
                styleClass: ['account', 'models', 'situations', 'advanced'].includes(this.viewBottom)
                    ? 'menuSelected'
                    : '',
                command: () => {
                    this.viewBottom = 'account';
                    this.viewBottomTitle = this.trans.trans.scenario + ' > ' + this.trans.trans.account;
                    localStorage.setItem('vpdashboardMenuBottom', 'account');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.scenario.value + ' > ' + this.trans.trans.account.value
                    );
                    this.funcBuildMenu();
                },
                items: items,
            });
        } else {
            this.menuBottom.push({
                label: this.trans.trans.scenario.value,
                styleClass: ['account', 'models', 'situations'].includes(this.viewBottom) ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'account';
                    this.viewBottomTitle = this.trans.trans.scenario + ' > ' + this.trans.trans.account;
                    localStorage.setItem('vpdashboardMenuBottom', 'account');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.scenario + ' > ' + this.trans.trans.account
                    );
                    this.funcBuildMenu();
                },
                items: [
                    {
                        label: this.trans.trans.account.value,
                        styleClass: this.viewBottom == 'account' ? 'menuSelected' : '',
                        command: () => {
                            this.viewBottom = 'account';
                            this.viewBottomTitle =
                                this.trans.trans.scenario.value + ' > ' + this.trans.trans.account.value;
                            localStorage.setItem('vpdashboardMenuBottom', 'account');
                            localStorage.setItem(
                                'vpdashboardMenuBottomTitle',
                                this.trans.trans.scenario.value + ' > ' + this.trans.trans.account.value
                            );
                            this.funcBuildMenu();
                        },
                    },
                    {
                        label: this.trans.trans.models.value,
                        styleClass: this.viewBottom == 'models' ? 'menuSelected' : '',
                        command: () => {
                            this.viewBottom = 'models';
                            this.viewBottomTitle =
                                this.trans.trans.scenario.value + ' > ' + this.trans.trans.models.value;
                            localStorage.setItem('vpdashboardMenuBottom', 'models');
                            localStorage.setItem(
                                'vpdashboardMenuBottomTitle',
                                this.trans.trans.scenario.value + ' > ' + this.trans.trans.models.value
                            );
                            this.funcBuildMenu();
                        },
                    },
                    {
                        label: this.trans.trans.situations.value,
                        styleClass: this.viewBottom == 'situations' ? 'menuSelected' : '',
                        command: () => {
                            this.viewBottom = 'situations';
                            this.viewBottomTitle =
                                this.trans.trans.scenario.value + ' > ' + this.trans.trans.situations.value;
                            localStorage.setItem('vpdashboardMenuBottom', 'situations');
                            localStorage.setItem(
                                'vpdashboardMenuBottomTitle',
                                this.trans.trans.scenario.value + ' > ' + this.trans.trans.situations.value
                            );
                            this.funcBuildMenu();
                        },
                    },
                ],
            });
        }
        items = [];
        items.push({
            label: this.trans.trans.view_benefits.value,
            styleClass: this.viewBottom == 'benefits' ? 'menuSelected' : '',
            command: () => {
                this.viewBottom = 'benefits';
                this.viewBottomTitle = this.trans.trans.benefits.value + ' > ' + this.trans.trans.view_benefits.value;
                localStorage.setItem('vpdashboardMenuBottom', 'benefits');
                localStorage.setItem(
                    'vpdashboardMenuBottomTitle',
                    this.trans.trans.benefits.value + ' > ' + this.trans.trans.view_benefits.value
                );
                this.funcBuildMenu();
            },
        });
        if (this.benefitOnTheFlyPrivilege && this.valueProp.vp_can_edit) {
            items.push({
                label: this.trans.trans.add_benefit.value,
                styleClass: this.viewBottom == 'addbenefit' ? 'menuSelected' : '',
                command: () => {
                    this.selectedBenefit = null;
                    this.viewBottom = 'addbenefit';
                    this.viewBottomTitle = this.trans.trans.benefits.value + ' > ' + this.trans.trans.add_benefit.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'addbenefit');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.benefits.value + ' > ' + this.trans.trans.add_benefit.value
                    );
                    this.funcBuildMenu();
                },
            });
        }
        this.menuBottom.push({
            label: this.trans.trans.benefits.value,
            styleClass: this.viewBottom == 'benefits' ? 'menuSelected' : '',
            command: () => {
                this.viewBottom = 'benefits';
                this.viewBottomTitle = this.trans.trans.benefits.value + ' > ' + this.trans.trans.view_benefits.value;
                localStorage.setItem('vpdashboardMenuBottom', 'benefits');
                localStorage.setItem(
                    'vpdashboardMenuBottomTitle',
                    this.trans.trans.benefits.value + ' > ' + this.trans.trans.view_benefits.value
                );
                this.funcBuildMenu();
            },
            items: items,
        });

        if (this.graduatedDeploymentFeature) {
            this.menuBottom.push({
                label: this.trans.trans.costs.value,
                styleClass: ['costs', 'deploymentschedule'].includes(this.viewBottom) ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'costs';
                    this.viewBottomTitle = this.trans.trans.costs.value + ' > ' + this.trans.trans.view_costs.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'costs');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.costs.value + ' > ' + this.trans.trans.view_costs.value
                    );
                    this.funcBuildMenu();
                },
                items: [
                    {
                        label: this.trans.trans.view_costs.value,
                        styleClass: this.viewBottom == 'costs' ? 'menuSelected' : '',
                        command: () => {
                            this.viewBottom = 'costs';
                            this.viewBottomTitle =
                                this.trans.trans.costs.value + ' > ' + this.trans.trans.view_costs.value;
                            localStorage.setItem('vpdashboardMenuBottom', 'costs');
                            localStorage.setItem(
                                'vpdashboardMenuBottomTitle',
                                this.trans.trans.costs.value + ' > ' + this.trans.trans.view_costs.value
                            );
                            this.funcBuildMenu();
                        },
                    },
                    {
                        label: this.trans.trans.deployment_schedule.value,
                        styleClass: this.viewBottom == 'deploymentschedule' ? 'menuSelected' : '',
                        command: () => {
                            this.viewBottom = 'deploymentschedule';
                            this.viewBottomTitle =
                                this.trans.trans.costs.value + ' > ' + this.trans.trans.deployment_schedule;
                            localStorage.setItem('vpdashboardMenuBottom', 'deploymentschedule');
                            localStorage.setItem(
                                'vpdashboardMenuBottomTitle',
                                this.trans.trans.costs + ' > ' + this.trans.trans.deployment_schedule.value
                            );
                            this.funcBuildMenu();
                        },
                    },
                ],
            });
        } else {
            this.menuBottom.push({
                label: this.trans.trans.costs.value,
                styleClass: this.viewBottom == 'costs' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'costs';
                    this.viewBottomTitle = this.trans.trans.costs.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'costs');
                    localStorage.setItem('vpdashboardMenuBottomTitle', this.trans.trans.costs.value);
                    this.funcBuildMenu();
                },
            });
        }

        if (!this.feature52) {
            items = [];
            items.push({
                label: this.trans.trans.scalers.value,
                styleClass: this.viewBottom == 'scalers' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'scalers';
                    this.viewBottomTitle = this.trans.trans.discovery.value + ' > ' + this.trans.trans.scalers.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'scalers');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.discovery.value + ' > ' + this.trans.trans.scalers.value
                    );
                    this.funcBuildMenu();
                },
            });

            if (this.hasKeyAssumptions === true) {
                items.push({
                    label: this.trans.trans.key_assumptions.value,
                    styleClass: this.viewBottom == 'keyassumptions' ? 'menuSelected' : '',
                    command: () => {
                        this.viewBottom = 'keyassumptions';
                        this.viewBottomTitle =
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.key_assumptions.value;
                        localStorage.setItem('vpdashboardMenuBottom', 'keyassumptions');
                        localStorage.setItem(
                            'vpdashboardMenuBottomTitle',
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.key_assumptions.value
                        );
                        this.funcBuildMenu();
                    },
                });
            }

            items.push({
                label: this.trans.trans.all_assumptions.value,
                styleClass: this.viewBottom == 'allassumptions' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'allassumptions';
                    this.viewBottomTitle =
                        this.trans.trans.discovery.value + ' > ' + this.trans.trans.all_assumptions.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'allassumptions');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.discovery.value + ' > ' + this.trans.trans.all_assumptions.value
                    );
                    this.funcBuildMenu();
                },
            });

            if (this.valueProp.vmw_q_1) {
                items.push({
                    label: this.trans.trans.problem.value,
                    styleClass: this.viewBottom == 'problem' ? 'menuSelected' : '',
                    command: () => {
                        this.viewBottom = 'problem';
                        this.viewBottomTitle =
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.problem.value;
                        localStorage.setItem('vpdashboardMenuBottom', 'problem');
                        localStorage.setItem(
                            'vpdashboardMenuBottomTitle',
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.problem.value
                        );
                        this.funcBuildMenu();
                    },
                });
            }
            if (this.valueProp.vmw_q_2) {
                items.push({
                    label: this.trans.trans.solution.value,
                    styleClass: this.viewBottom == 'solution' ? 'menuSelected' : '',
                    command: () => {
                        this.viewBottom = 'solution';
                        this.viewBottomTitle =
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.solution.value;
                        localStorage.setItem('vpdashboardMenuBottom', 'solution');
                        localStorage.setItem(
                            'vpdashboardMenuBottomTitle',
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.solution.value
                        );
                        this.funcBuildMenu();
                    },
                });
            }

            if (this.discoveryQuestions) {
                items.push({
                    label: this.trans.trans.discovery_questions.value,
                    styleClass: this.viewBottom == 'questions' ? 'menuSelected' : '',
                    command: () => {
                        this.viewBottom = 'questions';
                        this.viewBottomTitle =
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.questions.value;
                        localStorage.setItem('vpdashboardMenuBottom', 'questions');
                        localStorage.setItem(
                            'vpdashboardMenuBottomTitle',
                            this.trans.trans.discovery.value + ' > ' + this.trans.trans.questions.value
                        );
                        this.funcBuildMenu();
                    },
                });
            }

            this.menuBottom.push({
                label: this.trans.trans.discovery.value,
                styleClass: this.viewBottom == 'scalers' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'scalers';
                    this.viewBottomTitle = this.trans.trans.discovery.value + ' > ' + this.trans.trans.scalers.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'scalers');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.discovery.value + ' > ' + this.trans.trans.scalers.value
                    );
                    this.funcBuildMenu();
                },
                items: items,
            });
        }

        items = [
            {
                label: this.trans.trans.assets.value,
                styleClass: this.viewBottom == 'assets' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'assets';
                    this.viewBottomTitle =
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.assets.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'assets');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.assets.value
                    );
                    this.funcBuildMenu();
                },
            },
            {
                label: this.trans.trans.case_studies.value,
                styleClass: this.viewBottom == 'casestudies' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'casestudies';
                    this.viewBottomTitle =
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.case_studies.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'casestudies');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.case_studies.value
                    );
                    this.funcBuildMenu();
                },
            },
            {
                label: this.trans.trans.collateral.value,
                styleClass: this.viewBottom == 'collateral' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'collateral';
                    this.viewBottomTitle =
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.collateral.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'collateral');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.collateral.value
                    );
                    this.funcBuildMenu();
                },
            },
        ];

        if (this.assetsPrompters) {
            items.push({
                label: this.trans.trans.valuePrompters.value,
                styleClass: this.viewBottom == 'prompter' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'prompter';
                    this.viewBottomTitle =
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.valuePrompters.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'prompter');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.valuePrompters.value
                    );
                    this.funcBuildMenu();
                },
            });
        }

        if (this.assetsDeliverable) {
            items.push({
                label: this.trans.trans.finalCustomerDeliverable.value,
                styleClass: this.viewBottom == 'deliverable' ? 'menuSelected' : '',
                command: () => {
                    this.viewBottom = 'deliverable';
                    this.viewBottomTitle =
                        this.trans.trans.supporting_materials.value +
                        ' > ' +
                        this.trans.trans.finalCustomerDeliverable.value;
                    localStorage.setItem('vpdashboardMenuBottom', 'deliverable');
                    localStorage.setItem(
                        'vpdashboardMenuBottomTitle',
                        this.trans.trans.supporting_materials.value +
                            ' > ' +
                            this.trans.trans.finalCustomerDeliverable.value
                    );
                    this.funcBuildMenu();
                },
            });
        }

        this.menuBottom.push({
            label: this.trans.trans.supporting_materials.value,
            styleClass: this.viewBottom == 'assets' ? 'menuSelected' : '',
            command: () => {
                this.viewBottom = 'assets';
                this.viewBottomTitle = this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.assets;
                localStorage.setItem('vpdashboardMenuBottom', 'assets');
                localStorage.setItem(
                    'vpdashboardMenuBottomTitle',
                    this.trans.trans.supporting_materials.value + ' > ' + this.trans.trans.assets.value
                );
                this.funcBuildMenu();
            },
            items: items,
        });
        this.loadingMenu = false;
    }

    funcShowEditValue() {
        this.showEditValue = !this.showEditValue;
        localStorage.setItem('showEditValue', this.showEditValue.toString());
    }

    funCcallbackSelectedBenefit(metric) {
        this.CallbackSelectedBenefit.emit(metric);
        // this.selectedBenefit = metric;
        // this.viewBottom = "benefits";
        // this.benefitDetail = true;
    }

    funcGotoExplain(event) {
        this.callbackExplained.emit(event);
        // this.explained = event;
        // this.viewBottom = "explained";
        // this.viewBottomTitle = "Explanation of value changes";
    }

    getSolutionId() {
        this.valuePropService.readSolutionList(this.valueProp.id)
            .pipe(take(1))
            .subscribe((response) => {
                if (response.result && response.result.length > 0) {
                    this.solutionId = Number(response.result[0]['account_solution_id']);
                }
            });
    }

    parseFeatures() {
        const features = sessionStorage.getItem('features');
        const accountFeatures = features.split(',');

        if (accountFeatures.indexOf('38') >= 0) {
            this.modelsV2 = true;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(false);
        this.ngUnsubscribe.complete();
    }
}
