<span [hidden]="changeName" [matTooltip]="editTip" (click)="editValue()"><i style="margin-left: 10px" class="fa fa-pencil"></i></span>

<div [hidden]="!changeName">
  <div class="input-group">
    <input [(ngModel)]="valueName" class="form-control" />
    <div class="input-group-btn">
      <button (click)="onSubmit()" class="btn btn-success" [matTooltip]="trans?.trans?.save?.value" matTooltipPosition="above" container="body">
        <i class="fa fa-save"></i>
      </button>
      <button (click)="onCancel()" class="btn btn-danger" [matTooltip]="trans.trans.cancel.value" matTooltipPosition="above" container="body">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>
