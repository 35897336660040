import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NotesComponent } from './notes.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KeyFiguresModule } from '../key_figures/key_figures.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { DefaultTranslations } from './notes.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { MenuModule } from 'primeng/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
	imports: [
		AreYouSureModule,
		TableModule,
    MatTooltipModule,
		TableChartModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		SidebarModule,
		ApplicationPipesModule,
		KeyFiguresModule,
		NoRecordsModule,
		MenuModule,
		ApplicationPipesModule
	],
	declarations: [
		NotesComponent
	],
	providers: [DefaultTranslations, TranslationsV2Service],
	exports: [NotesComponent]
})
export class NotesModule { }
