import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AdminAuthGuard } from '../admin-auth-guard/admin.guard.service';
import { TranslationsV2Component } from '../translationsv2/translationsv2.component';
import { TranslationsV2Module } from '../translationsv2/translationsv2.module';
import { AdminTranslationsComponent } from './translations.component';
import { DefaultTranslations } from './translations.translation';




export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  {
    path: 'translations',
    component: TranslationsV2Component,
    canActivate: [AdminAuthGuard],
    data: { currentNav: 'settings' }
  },
];

@NgModule({
  imports: [EditTranslationsModule, TableModule, NgbModule, CommonModule, FormsModule, DropdownModule, TranslationsV2Module, RouterModule.forChild(routes)],
  declarations: [AdminTranslationsComponent],
  providers: [DefaultTranslations, TranslationsV2Service],
  entryComponents: [AdminTranslationsComponent],
  exports: [AdminTranslationsComponent],
})
export class AdminTranslationsModule {}
