import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CXOService } from '@data/services/cxo/cxo.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
	selector: 'app-maturity',
	templateUrl: './maturity.component.html'
})
export class MaturityComponent implements OnInit, OnDestroy {
	@Input() valueProp: ValueProp;

	selectedModel: {[klass: string]: any}[];
	models: {[klass: string]: any}[];

	ngUnsubscribe: Subject<any> = new Subject();
	
	constructor(
		private CXOService: CXOService
	) {

	}

	ngOnInit() {
		this.getData();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getData() {
		this.CXOService.getValuePropMaturity(this.valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
		})
	}

	postData() {
		let payload = {
			
		}
		this.CXOService.postValuePropMaturity(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
		});
	}
}