import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { MaintenanceSituationsModule } from '../views/situations/situations.module';
import { ModelValueCategoryModule } from '../views/value-category/value-category.module';
import { ValueMapContainerComponent } from './container.component';
import { DefaultTranslations } from './container.translation';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [ValueMapContainerComponent],
  imports: [
    MaintenanceSituationsModule,
    DLMenubarModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    MenuModule,
    SidebarModule,
    TableModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    EditTranslationsModule,
    ModelValueCategoryModule
  ],
  providers: [DefaultTranslations, TranslationsV2Service],
  entryComponents: [ValueMapContainerComponent],
  exports: [ValueMapContainerComponent],
})
export class ValueMapContainerModule {}
