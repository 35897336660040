<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ trans.trans.viewSection.value }}
    <app-editTranslation
      *ngIf="showTranslate"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.viewSection"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>

  <div class="sidebar_container">
    <div class="keywest_panel">
      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label_readonly"
            >{{ trans.trans.section_type.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.section_type"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </label>
          <br />{{ sectionEdit.name }}
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label_readonly"
            >{{ trans.trans.description.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.description"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </label>
          <br />{{ sectionEdit.description }}
        </div>
      </div>
    </div>

    <div class="case_studies-media-list" *ngIf="sectionEdit.case_study_section_images.length > 0">
      <div class="row case_studies-media-list-row">
        <div class="col-xs-12">
          <div class="case_studies_media_list_header">
            {{ trans.trans.media.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.media"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
      </div>

      <!-- list of existing media -->
      <div class="row case_studies-media-list-row">
        <div class="col-xs-6">
          {{ trans.trans.media.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.media"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </div>
      </div>

      <div class="row section-row" *ngFor="let img of sectionEdit.case_study_section_images">
        <div #editListOverlayTarget></div>
        <div class="col-xs-9" *ngIf="img.type === 'media'">
          <div class="row case_studies-media-list-row">
            <span class="col-xs-1"><i (click)="setOverlayImageVideo(true, img, editListViewOverlay, $event)" class="fa fa-picture-o"></i></span>
            <span class="col-xs-11"
              >{{ img.image_name }}
              <i class="fa fa-info-circle" *ngIf="img.description && !(style2022$ | async)" [matTooltip]="img.description" matTooltipPosition="above"></i>
              <span
                *ngIf="img.description && (style2022$ | async)"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="img.description"
                matTooltipPosition="above"
              >
                info
              </span>
            </span>
          </div>
        </div>

        <div class="col-xs-9" *ngIf="img.type === 'link'">
          <div class="row case_studies-media-list-row">
            <span class="col-xs-1"><i (click)="setOverlayImageVideo(true, img, editListViewOverlay, $event)" class="fa fa-video-camera" aria-hidden="true"></i></span>
            <span class="col-xs-11"
              >{{ img.path }}
              <i class="fa fa-info-circle" *ngIf="img.description && !(style2022$ | async)" [matTooltip]="img.description" matTooltipPosition="above"></i>
              <span
                *ngIf="img.description && (style2022$ | async)"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="img.description"
                matTooltipPosition="above"
              >
                info
              </span>
            </span>
          </div>
        </div>
      </div>

      <p-overlayPanel #editListViewOverlay appendTo="body" styleClass="overlayStyle" [showCloseIcon]="true">
        <div class="overlayVideoImage">
          <div class="sidebar_header_container">
            <div class="case_studies_media_list_header">
              {{ overlayImgVideo.description }}
              <i *ngIf="overlayImgVideo.type === 'media'" (click)="downAsset(overlayImgVideo.path)" class="fa fa-cloud-download pull-right download_icon"></i>
            </div>
          </div>
          <span *ngIf="overlayImgVideo.type === 'media'">
            <img src="{{ overlayImgVideo.path }}" class="img-responsive" />
          </span>
          <span *ngIf="overlayImgVideo.type == 'link'">
            <div>
              <iframe [src]="embeddedVideoURL" width="600" height="400" webkitallowfullscreen mozallowfullscreen allowfullscreen frameborder="0" allowfullscreen></iframe>
            </div>
          </span>
        </div>
      </p-overlayPanel>
    </div>
  </div>
</div>
