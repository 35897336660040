import { Component, Input, EventEmitter, Output, OnDestroy, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AEQuoteTranslations } from './ae_quote.translation';
import { CommonService } from '@data/services/common/common.service';
import { Quote } from 'app/value-map/solution-detail/case-studies/quote/models/quote.model';
import { cloneDeep } from 'lodash';

interface QuoteForm {
  first_name: string;
  last_name: string;
  title: string;
  quote: string;
  account_case_study_quote_id: string;
}

@Component({
  selector: 'app-ae-quote',
  templateUrl: './ae_quote.component.html',
})
export class AEQuoteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() caseStudyId: string | number;
  @Input() mode: string;
  @Input() quoteFormObj: Quote = {
    first_name: undefined,
    last_name: undefined,
    title: undefined,
    quote: undefined,
    account_case_study_quote_id: undefined,
  };
  @Output() valueChange = new EventEmitter();
  @Output() closeCallback = new EventEmitter();

	ngUnsubscribe = new Subject();
	showTranslate = false;
  quoteDetails: QuoteForm;
	@ViewChild('addQuoteForm') addQuoteForm: NgForm;

  sidebar_title: { value: string } = { value: '' };
  constructor(
    private commonService: CommonService,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: AEQuoteTranslations
  ) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.quoteDetails = cloneDeep(this.quoteFormObj);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.mode === 'edit') {
        this.openAddQuoteModal(this.quoteFormObj);

        this.sidebar_title = { value: this.trans.trans.editQuote.value };
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.sidebar_title = this.mode === 'edit' ? this.trans.trans.editQuote : this.trans.trans.addQuote;
      });
  }

  openAddQuoteModal(quote?): void {
    this.sidebar_title = { value: this.trans.trans.addQuote.value };

    if (quote) {
      this.sidebar_title = { value: this.trans.trans.editQuote.value };

      this.addQuoteForm.controls['contact_last_name'].setValue(quote.last_name);
      this.addQuoteForm.controls['contact_first_name'].setValue(quote.first_name);
      this.addQuoteForm.controls['title'].setValue(quote.title);
      this.addQuoteForm.controls['quote'].setValue(quote.quote);
      this.addQuoteForm.controls['account_case_study_quote_id'].setValue(quote.id);
    }
  }

  processaddQuote(response) {
    if (response.result && response.result.success) {
      this.notification.success(response.result.message, false);
      this.valueChange.emit();
    }
  }

  addQuote(form: NgForm): void {
    const payload = {};

    payload['contact_first_name'] = form.controls.contact_first_name.value;
    payload['contact_last_name'] = form.controls.contact_last_name.value;
    payload['title'] = form.controls.title.value;
    payload['quote'] = form.controls.quote.value;
    payload['case_study_id'] = this.caseStudyId;

    if (this.mode === 'add') {
      this.solutionService
        .saveQuote(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.processaddQuote(response);
        });
    } else {
      payload['account_case_study_quote_id'] = form.controls.account_case_study_quote_id.value;
      this.solutionService
        .updateQuote(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.processaddQuote(response);
        });
    }
  }
}
