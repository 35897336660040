import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValuemapsComponent } from './valuemaps/valuemaps.component';
import { SolutionCategoryComponent } from './solution-category/solution-category.component';
import { SolutionDetailComponent } from './solution-detail/solution-detail/solution-detail.component';
import { AddSolutionComponent } from './add-solution/add-solution.component';
import { LibrarySolutionsComponent } from './library-solutions/library-solutions.component';
import { ModelsComponent } from './models_container/models.component';
const routes: Routes = [
	{
		path: 'valuemaps',
		component: ValuemapsComponent,
		children: [
			{
				path: 'solutionCategory',
				component: SolutionCategoryComponent,
				data: { page: 'solutionCategory' }
			}
		]
	},
	{
		path: 'modelsv2',
		component: ModelsComponent,
		data: { page: 'models', breadcrumb: "Models" }
	},

	{
		path: 'addmodel',
		component: AddSolutionComponent,
		data: { page: 'addmodel' }
	},
	{
		path: 'modelv2/:id',
		component: SolutionDetailComponent,
		data: { page: 'model', breadcrumb: "Model Details", currentNav: "models" }
	},
	{
		path: 'modelv2/:id/case/:caseid',
		component: SolutionDetailComponent,
		data: { page: 'model', breadcrumb: "Model Details", currentNav: "models" }
	},
	{
		path: 'librarySolutions',
		component: LibrarySolutionsComponent,
		data: { page: 'librarySolutions' }
	}

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ValueMapRoutingV2Module { }
