import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SituationElement } from '@data/services/valueprop/models/situation.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './r_situations.translation';

@Component({
  selector: 'app-r-maintenance-situations',
  templateUrl: './r_situations.component.html',
  styleUrls: ['./r_situations.component.scss'],
})
export class RMaintenanceSituationsComponent implements OnInit, OnDestroy {
  @Input() solutionId: number;

  situations: SituationElement[] = [];
  situation_tree: Array<{name?: string, desription?: string, expanded?: boolean}> = [];

  @Input() objSituation: Partial<SituationElement> = {
    name: '',
    description: '',
    type: '',
    id: '',
    situation_type_id: '',
  };

  cols: Array<{field: string, header: string}> = [
    { field: 'name', header: 'Situation Type' },
    { field: 'stack_order', header: 'Order' },
  ];

  sidebar_title = 'View Situation';
  ngUnsubscribe$ = new Subject();
  account_id: string;
  loading = true;
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) { }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');
    this.funcGetSituationTree(this.objSituation.id);
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.trans.trans.view_situation.value;
      });
  }

  funcGetSituationTree(id: number | string): void {
    this.situation_tree = [];

    this.solutionService.getSituationTree(id).subscribe((res) => {
      this.situation_tree = res.result.data;
    });
  }

  funcGetSituationList(): void {
    this.loading = true;

    this.solutionService.getAccountSituationTypes()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.situations = res.result;
        this.loading = false;
      });
  }
}
