import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ValueProp } from '@shared/models/value-prop.model';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuePropCloneTranslation } from './clone.translation';

@Component({
	selector: 'app-vp-clone',
	templateUrl: './clone.component.html',
	styleUrls: ['./clone.component.scss']
})
export class ValuePropCloneComponent implements OnInit, OnDestroy {
	@Input() valueProp: ValueProp;
	@Output() callbackCancel = new EventEmitter();

	imageUrl: string;
	fullImagePath: string;
	cloneOption = 0;
	clonedName = "";
	cloneCompanyId: string;
	cloneCompanyName: string;
	cloneLoader = false;
	opportunityId: string;
	ownerOfClone = 0;
	userId: string;
	feature22 = false;
	feature126 = false;
	showClone = false;
	feature80 = false;
	crm: string;

	ngUnsubscribe: Subject<any> = new Subject();

	constructor(
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: ValuePropCloneTranslation,
		private notificationService: NotificationService,
		private valuePropService: ValuepropService,
		private router: Router
	) {
		this.imageUrl = this.commonService.getImageUrl();
		this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
	}

	ngOnInit(): void {
		this.crm = sessionStorage.getItem('crm');
		this.feature80 = this.commonService.checkFeature(80);
		this.userId = sessionStorage.getItem('uid');
		this.feature22 = this.commonService.checkFeature("22");
		this.feature126 = this.commonService.checkFeature("126");

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
	}

	getTranslations(): void {
		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

		let payload = {
			account_id: sessionStorage.getItem("aid"),
			component: this.trans.config.component,
			lang: langAbbr,
			localTranslations: this.trans.trans,
		}
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	close(): void {
		this.showClone = false;
		this.callbackCancel.emit()
	}

	funcCloneCompany(event) {
		this.cloneCompanyId = event.id;
		this.cloneCompanyName = event.name;

	}

	cloneValueProp() {
		this.notificationService.info(this.trans.trans.cloning.value, false);
		this.cloneLoader = true;

		if (!this.clonedName) {
			this.notificationService.error(this.trans.trans.nameRequired.value, false);
			this.cloneLoader = false;
			return false;
		}

		if (this.cloneOption == 1) {
			if (!this.cloneCompanyId) {
				this.notificationService.error(this.trans.trans.companyRequired.value, false);
				this.cloneLoader = false;
				return false;
			}
			if (!this.opportunityId && this.feature22 && this.feature126) {
				this.notificationService.error(this.trans.trans.opportunityIDRequired.value, false);
				this.cloneLoader = false;
				return false;
			}
		}

		if (this.cloneOption == 2) {
			if (!this.opportunityId  && this.feature126) {
				this.notificationService.error(this.trans.trans.opportunityIDRequired.value, false);
				this.cloneLoader = false;
				return false;
			}
		}

		let payload = {
			name: this.clonedName,
			user_id: !this.ownerOfClone ? this.userId : null,
			opportunityId: this.opportunityId ? this.opportunityId : null,
			company_id: this.cloneCompanyId ? this.cloneCompanyId : null
		}

		this.valuePropService.cloneValueProp(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.cloneLoader = false;
				if (this.commonService.checkFeature("34")) {
					let route = this.feature80 ? "/4" : "/0";
					this.router.navigate(['repv2/' + response.result.value_prop.id + route]);
				} else {
					this.router.navigate(['valueprop/', response.result.value_prop.id]);
				}
			}
			else if (response.result.success === false) {
				this.cloneLoader = false;
				this.notificationService.error(response.result.message, false);
			}

			this.callbackCancel.emit();
		});
	}
}
