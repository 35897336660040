import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { CasestudiesValueRealizedTranslations } from './case-studies.translation';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-case-studies-realizations',
	templateUrl: './case-studies.component.html',
	styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesRealizationsComponent implements OnInit, OnDestroy {
	@Input() companyId;
	@Input() value_prop;
	@Input() isTracker;

	@Output() callback = new EventEmitter();
	pageNumber: number = 1;
	valuePropId: string;
	fullImagePath: string;
	image_url: string;
	caseStudies: any = [];
	caseStudiesLoader: boolean;
	searchType: any;
	searchTypeList: Array<Object>;
	caseStudiesName: string;

	subscriptionRouterParams: Subscription;
	subscriptionreadCaseStudy: Subscription;
	subscriptiongetCaseStudies: Subscription;
	subscriptionsearchCaseStudies: Subscription;
	subscriptiondeleteCaseStudy: Subscription;

	strSearch = '';
	pageLimit = 25;
	rowLimit = [];
	columns = [
		{field: 'name_label', header: 'Name'}
	];

	ngUnsubscribe = new Subject();
	showTranslate = false;
	style2022$: Observable<boolean>;

	constructor(
		private commonService: CommonService,
		private valueRealizationService: ValuerealizationService,
		private notification: NotificationService,
		private router: Router,
		private route: ActivatedRoute,
		private translationService: TranslationsV2Service,
		public trans: CasestudiesValueRealizedTranslations,
		private styleService: StyleService
	) {

		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

	}


	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: ParamMap) => {
			let id = params.get('id');
			this.changeUrl(id);
		});

		this.loadCaseStudies();

		  this.getTranslations();
		  this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		  });
		  this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		  });

		this.searchTypeList = [{
				name: 'Search By Name',
				value: 'name'
			},
			{
				name: 'Search By tags',
				value: 'tag'
			}
		];

		this.funcBuildPageLimit();
		this.searchType = this.searchTypeList[0]['value'];

		this.subscriptionreadCaseStudy = this.valueRealizationService.readCaseStudy.subscribe(() => {
			this.loadCaseStudies();
		});
	}

	ngOnDestroy() {
		if (this.subscriptionRouterParams) { this.subscriptionRouterParams.unsubscribe(); }
		if (this.subscriptionreadCaseStudy) { this.subscriptionreadCaseStudy.unsubscribe(); }
		if (this.subscriptiongetCaseStudies) { this.subscriptiongetCaseStudies.unsubscribe(); }
		if (this.subscriptionsearchCaseStudies) { this.subscriptionsearchCaseStudies.unsubscribe(); }
		if (this.subscriptiondeleteCaseStudy) { this.subscriptiondeleteCaseStudy.unsubscribe(); }

		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	changeUrl(id) {
		this.valuePropId = id;
		if (!this.isTracker) {
			this.router.navigate(['valuerealization', id, 'casestudies']);
		}
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

	   this.searchTypeList = [
		   {
				name: this.trans.trans.searchByName.value,
				value: 'name'
			},
			{
				name: this.trans.trans.searchByTag.value,
				value: 'tag'
			}
		];
	 });
	}

	setRowLimit(num) {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit() {
		this.rowLimit = [
			{ label: '10', icon: this.pageLimit == 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: '25', icon: this.pageLimit == 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: '50', icon: this.pageLimit == 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: '100', icon: this.pageLimit == 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		]
	}


	onTypeChange() {
		this.pageNumber = 1;
	}


	loadCaseStudies() {
		let searchLimit = "/limit/all" + "/page/" + this.pageNumber;
		let companyId = "/" + this.companyId;
		this.caseStudiesLoader = true;
		this.subscriptiongetCaseStudies = this.valueRealizationService.getCaseStudies(companyId, this.valuePropId, 0, searchLimit).subscribe((response) => {
			if (response.result) {
				this.caseStudies = response.result.case_studies.data;
				this.caseStudiesLoader = false;

			}
		});
	}
	searchCaseStudies() {
		if (this.caseStudiesName && this.caseStudiesName != null && this.caseStudiesName !== "") {
			this.caseStudiesLoader = true;
			let payload = {
				"searchType": this.searchType,
				"searchTerm": this.searchType == "tag" ? this.caseStudiesName.trim() : this.caseStudiesName,
				"page": this.pageNumber,
				"limit": this.pageLimit,
				"is_account_solution": 0,
				"value_prop_id": this.valuePropId,
				"company_id": this.companyId
			};

			this.subscriptionsearchCaseStudies = this.valueRealizationService.searchCaseStudies(payload).subscribe((response) => {
				if (response.result) {
					this.caseStudies = response.result.case_studies.data;
				}
				this.caseStudiesLoader = false;
			});
		} else {
			this.caseStudies = [];
			this.loadCaseStudies();
		}
	}


	deleteCaseStudy(case_study_id) {
		this.subscriptiondeleteCaseStudy = this.valueRealizationService.deleteCaseStudy(case_study_id).subscribe((response) => {
			if (response.result) {
				this.loadCaseStudies();
				this.notification.success(response.result, false); // Params {message, islogout}
			}
		});
	}
}
