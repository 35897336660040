<a *ngIf="!embedded" href="javascript:void(0)" (click)="funcGotoMenu()" class="back-btn"><span class="glyphicon glyphicon-chevron-left"></span>{{ trans2.trans.back.value}}</a>

<div class="table-responsive col-sm-12 benefitsDetailTable">
	<div *ngIf="benefit && benefit !== null" style='margin-bottom: 20px;'>
		<div *ngIf="!benefit.alt_metric_name || benefit.alt_metric_name == null">
			<div class='row'>
				<div class='col-xs-6'>
					<h3>
						<span (click)="benefit.showeditable_benefit = !benefit.showeditable_benefit" *ngIf="(!benefit.showeditable_benefit) && valueProp.vp_can_edit" class="editable tooltip-custom" [ngbTooltip]="trans2.trans.ch_rename.value" container="body" placement="right">{{benefit.metric_name}} ({{trans2.trans.edit.value}})</span>
						<span *ngIf="!valueProp.vp_can_edit">{{benefit.metric_name}}</span>
					</h3>
					<div *ngIf="benefit.showeditable_benefit" class="editableBoxName">
						<div class='input-group'>
							<input type="text" name="metric_name" [(ngModel)]="benefit.metric_name" class="form-control">
							<div class='input-group-btn'>
								<button (click)="updateAltMetricName();benefit.showeditable_benefit = !benefit.showeditable_benefit" class="btn btn-success">
									<span class="fa fa-check"></span>
								</button>
								<button (click)="closeBenefit(); benefit.showeditable_benefit = !benefit.showeditable_benefit" class="btn btn-danger">
									<span class="fa fa-times"></span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class='col-xs-6'>
					<form class="form-inline benefitStatusElement">
						<div class="editable-controls form-group benefitStatusElement pull-right">
							<select class="form-control active-type benefitStatusElement" name="benefit_active" [(ngModel)]="benefit.active">
								<option value="" class="benefitStatusElement" disabled>{{ trans2.trans.choose.value}}</option>
								<option *ngFor="let activeType of activeTypes" [value]="activeType.value" class="benefitStatusElement">
									{{activeType.name}}</option>
							</select>
						</div>
					</form>
				</div>
			</div>

		</div>
		<div *ngIf="benefit.alt_metric_name">
			<h3 (click)="benefit.showeditable_benefit = !benefit.showeditable_benefit" *ngIf="!benefit.showeditable_benefit" class="editable">{{benefit.alt_metric_name}}</h3>
			<div *ngIf="benefit.showeditable_benefit" class="editableBoxName">
				<div class='input-group' style='width: 450px !important;'>
					<input type="text" name="metric_name" [(ngModel)]="benefit.alt_metric_name" class="form-control" style='width: 350px !important;'>
					<div class='input-group-btn'>
						<button (click)="updateAltMetricName();benefit.showeditable_benefit = !benefit.showeditable_benefit" class="btn btn-success">
							<span class="fa fa-check"></span>
						</button>
						<button (click)="closeBenefit(); benefit.showeditable_benefit = !benefit.showeditable_benefit" class="btn btn-danger">
							<span class="fa fa-times"></span>
						</button>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
			<i>{{ trans2.trans.original_name.value }}: {{benefit.metric_name}}</i>
		</div>
		<div class="totalBenefits"><label><strong>{{ trans2.trans.total_benefit.value }}:</strong>&nbsp;</label>{{benefit.total_benefits_fmt}}</div>
		<div>
			<label>
				<strong>{{ trans2.trans.description.value }}:</strong>&nbsp;
			</label>
			<span [ngbTooltip]="contextualHelp['Position 30']" container="body" placement="top" [innerHTML]="benefit.description"></span>
		</div>
		<div *ngIf="benefit.features">
			<label>
				<strong>{{ trans2.trans.features.value }}:</strong>&nbsp;
			</label>
			<span [innerHTML]="benefit.features"></span>
		</div>
		<div *ngIf="benefit.vp_case_studies > 0">
			<label><strong>{{ trans2.trans.case_studies.value }}:</strong>&nbsp;</label>
			<a href="javascript:void(0)" (click)="showCaseStudies(showCaseStudiesModal)">
				{{benefit.vp_case_studies}}
			</a>
		</div>
		<div *ngIf="benefit.metric_note" class="editableNotes">
			<strong>{{ trans2.trans.note.value }}: </strong>
			<a href="javascript:void(0)" (click)="benefit.showeditable_notes = !benefit.showeditable_notes" *ngIf="!benefit.showeditable_notes">
				<span class="icon-pencil table-icon"></span>
			</a>
			<!-- <a href="javascript:void(0)" (click)="open(deleteNoteModal)">
				<span class="glyphicon glyphicon-trash glyphicon-table-icon"></span>
			</a> -->
			<p style='white-space: pre-line;'>{{benefit.metric_note}}
				<i [ngbTooltip]="contextualHelp['Position 28']" container="body" placement="right"></i>
			</p>
		</div>

		<div *ngIf="benefit.showeditable_notes" class="">
			<form name="benefitNotesForm" id="ngForm" #benefitNotesForm="ngForm">
				<textarea placeholder="Write your note here" rows="5" class="form-control" style='width: 100% !important;' [(ngModel)]="benefit.metric_note" name="metric_note"></textarea>
				<button class="btn btn-success" (click)="saveMetricNote()">
					{{ trans2.trans.save_note.value }}
				</button>
				<button (click)="benefit.showeditable_notes = !benefit.showeditable_notes" class="btn btn-danger">
					{{trans2.trans.cancel.value }}
				</button>
			</form>
		</div>
		<div *ngIf="showSubtypes">
			<app-sub-types [valueMetric]="valueMetric" [valueProp]="valueProp" (closeForm)="toggleSubTypesForm()" (refreshBenefit)="loadBenefits()"> </app-sub-types>
		</div>
	</div>
	<div id='width' style='width: 100%;'></div>
	<div style='overflow-x: scroll;' *ngIf="tableData && tableData.length" [ngStyle]="{'width': screenWidth}">
		<p-menubar [model]="menuDetails" *ngIf="!embedded">
			<div class='pull-right'>
				<a (click)='editing_scratch = !editing_scratch' *ngIf="!editing_scratch" class='btn btn-primary pull-right'><i class='fa fa-pencil'></i> {{trans2.trans.edit.value}}</a>
				<a (click)='editing_scratch = !editing_scratch; updateBenefit()' *ngIf="editing_scratch" class='btn btn-success pull-right'> {{trans2.trans.save_changes.value}}</a>
				<a (click)='editing_scratch = !editing_scratch' *ngIf="editing_scratch" class='btn btn-danger pull-right'><i class='fa fa-times'></i> {{trans2.trans.cancel.value}}</a>
			</div>
		</p-menubar>
		<!-- <p-table #dt2 dataKey='formula' [columns]="selectedColumns" [value]="tableData" [resizableColumns]="true">

			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngIf="!embedded" style="width: 3em"></th>
					<th *ngFor="let col of columns" [ngStyle]="{'white-space': 'nowrap', 'width': col.width ? col.width : 'inherit', 'min-width': col.minwidth ? col.minwidth : 'inherit'}">
						{{col.header}}
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns='columns'>
				<tr [ngClass]="{'total': rowData['type'] == 'total'}">
					<td *ngIf="!embedded">
						<a href="#" *ngIf="!editing_scratch && rowData['hasScratchpad']" [pRowToggler]="rowData">
							<i class='fa fa-calculator'></i>
						</a>
					</td>
					<td *ngFor="let col of columns">

						<span *ngIf="(!col.editable || !rowData.editable) && col.type == 'improvement'">{{rowData[col.field + "_fmt"]}}</span>
						<span *ngIf="(!col.editable || !rowData.editable) && col.type !== 'improvement'">{{rowData[col.field]}}</span>
						<span *ngIf="col.editable && !editing_scratch && rowData.editable">{{rowData[col.field + "_fmt"]}}</span>
						<div *ngIf='col.editable && editing_scratch && rowData.editable'>
							<input type='text' *ngIf="funcShowHideInput(rowData, col)" class='form-control' (change)="funcCalcCostWithYear(rowData, col.field); col.field == 'cost' ? rowData['cost_updated'] = true : col.field == 'future_effort' ? rowData['future_effort_updated'] = true : rowData['updated'] = true" [(ngModel)]="rowData[col.field]">
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="rowexpansion" let-rowData let-columns='columns'>
				<tr>
					<td [attr.colspan]="columns.length + 1">
						<app-scratchpad-component (SaveAndUpdateCallback)='dt2.toggleRow(rowData); loadBenefits()' [totalColumns]='totalColumns' [factorTypeId]='rowData["factorTypeId"]' [valuePropId]='valueProp.id' [valueMetric]='valueMetric'></app-scratchpad-component>
					</td>
				</tr>
			</ng-template>
		</p-table> -->
	</div>

</div>
<div *ngIf="benefitsLoader" class="col-sm-12 m-t-md text-center">
	<p class="text-center">
		<img class="loader" src="{{fullImagePath}}" />
	</p>
</div>
<div *ngIf="(benefitsDetail == null || benefitsDetail.length==0) && !benefitsLoader" class="m-t-md col-sm-12">
	<div class="searchTableContainer text-center">
		<h4>{{ trans2.trans.NoBenefitDetailFound.value }}</h4>
	</div>
</div>

<p-sidebar [(visible)]="showGrowthDecline" position='left' styleClass="p-sidebar-md">
	<h2>{{ trans2.trans.GrowthAfter1stYear.value }}</h2>
	<form name="addMetricPhasing" #addMetricPhasing="ngForm">
		<div *ngFor="let driver of metricbenefits; let index = index;" class='row'>
			<div class='col-xs-10'>
   				<!-- TODO?: Is this still used? -->
				<!-- <label>{{'general.Year'.value}}{{index+2}}</label> -->
				<input type='range' [min]='driver.min' [max]='driver.max' [(ngModel)]="driver.value" [step]="driver.increment">
			</div>
			<div class='col-xs-2'>
				<input type='number' min="-100" max="100" name='driveinput{{index}}' class='form-control' [(ngModel)]="driverPhasing[driver]">
			</div>
		</div>
		<button class="btn btn-success" *ngIf="!hideMetricLoader && valueProp.vp_can_edit" (click)="saveMetricPhase()">{{trans2.trans.save.value }}</button>
		<button class="btn btn-success" *ngIf="!hideMetricLoader && valueProp.vp_can_edit" (click)="saveMetricPhase('applyToAll')">{{ trans2.trans.applyToAll.value }}</button>
		<img class="actionLoader" [hidden]="!hideMetricLoader" [src]="fullImagePath" />
	</form>
</p-sidebar>


<p-sidebar [(visible)]="showDeleteNote" position='left' styleClass="p-sidebar-md">
	<h2>{{ trans2.trans.delete_note.value }}</h2>
	<p>{{ trans2.trans.are_you_sure_you_want_to_delete_this_note.value }}</p>
	<button type="button" class="btn btn-danger" (click)="deleteMetricNote()"><i class='fa fa-times'></i> {{trans2.trans.Yes.value }}</button>
</p-sidebar>


<ng-template #showCaseStudiesModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<!-- TODO?: Is this still used? -->
		<!-- <h4 class="modal-title">{{ 'benefit-detail.proof_points_for'.value }} -->
			<span *ngIf="benefit.alt_metric_name">{{benefit.alt_metric_name}}</span>
			<span *ngIf="!benefit.alt_metric_name || benefit.alt_metric_name == null">{{benefit.metric_name}}</span>
		<!-- </h4> -->
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body row">
		<div class="table-responsive col-sm-11 soln-table">
			<div class="searchTableContainer">
				<table class="table custom-table">
					<tbody *ngIf="benefitCaseStudies">
						<tr *ngFor="let caseStudy of benefitCaseStudies" class="grid-row">
							<td>
								<strong>{{caseStudy.company_name}}</strong> {{ trans2.trans['(name)_experience_an_improvement_of_(amount)_with_this_benefit.'].value }} <strong>{{caseStudy.improvement_fmt}}</strong>
								{{ trans2.trans.with_this_benefit.value }}
							</td>
						</tr>
					</tbody>
				</table>
				<div *ngIf="caseStudiesLoader" class="col-12 text-center">
					<img class="actionLoader" src="{{fullImagePath}}" />
				</div>
			</div>
		</div>
	</div>
</ng-template>