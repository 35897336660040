import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { SearchCompanyComponent } from './search-company.component';

import { TranslateLoader } from '@ngx-translate/core';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AddVPCompanyNoTransModule } from '../add-value-positionv2/dynamic_components/add-company_no_trans/vp-add-company.module';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './search-company.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
	imports: [
    MatTooltipModule,
		SidebarModule,
		EditTranslationsModule,
		AddVPCompanyNoTransModule,
		CommonModule,
		NgbModule,
		FormsModule,
		TableModule,
		AddCompanyModule
	],
	declarations: [
		SearchCompanyComponent
	],
	providers: [DefaultTranslations, TranslationsV2Service],
	exports: [SearchCompanyComponent]
})
export class SearchCompanyModule { }
