import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class BenefitTypeTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'benefitTypes',
    display_name: 'Benefit Types',
    description: 'Benefit Types found in models admin section ',
  };

  public trans = this.typeObjectKeys({
    benefitTypes: 'Benefit Types',
    add_new: 'Add new',
    delete: 'Delete',
    edit: 'Edit',
    search: 'Search',
    ch_view: 'View',
    are_you_sure: 'Are you sure?',
    name: 'Name',
    description: 'Description',
    view_benefit_type: 'View Benefit Type',
    add_benefit_type: 'Add Benefit Type',
    edit_benefit_type: 'Edit Benefit Type',
    save: 'Save',
    update: 'Update',
    read_only: 'Read only. The selected type is part of the system-level configuration.',
    back: 'Back',
    benefitDeleted: 'Benefit type deleted successfully',
    createCustomAssetTag: 'Create custom asset tag for this benefit type?',
    benefitValueTag: 'Benefit Value Tag',
    value: 'value',
    tagsSpecialCharactersError: 'Tags cannot contain special characters',
    valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    onShow: 'onshow.',
    tagRemoved: 'Tag removed',
    customBenefitTagWarning: 'Ensure that you have removed this custom functional objective tag from your assets to avoid disruption.',
    benefitTypeUpdatedSuccess: 'Benefit Type updated successfully',
    tagMustBeUnique: 'Tag must be unique',
    changesUpdatedSuccess: 'Benefit Type updated successfully',
    changesFailed: 'Failed to update Benefit Type.',
  });
}
