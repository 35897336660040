import { Injectable } from '@angular/core';

@Injectable ()
export class AECompetitorTranslation {
    
    public config: any = {
        component: 'addEditCompetitor',
        display_name: 'Competitor Form',
        description: 'Form for creating and editing a competitors'
    };

    public trans: any = {
        addcompetitor: 'Add Competitor',
        editcompetitor: 'Edit Competitor',
        name: 'Name',
        chooseOne: 'Choose One',
        placeholderCompetitors: 'Select Competitors',
        description: 'Description',
        placeholderDescription: 'Enter Description',
        lnadmine: 'Landmines',
        placeholderLandmines: 'Enter Landmines',
        theyllSay: 'They\'ll say',
        placeholderTheyllSay: 'Enter they\'ll Say',
        theirLandmines: 'Their Landmines',
        placeholderTheirLandmine: 'Enter Their Landmines',
        ourResponse: 'Our Response',
        placeholderOurResponse: 'Enter Our Response',
        save: 'Save',
        back: 'Back',
        are_you_sure: 'Are you sure?'
    };
}
