<div [ngClass]="!embedded ? 'models_panel' : ''" style='margin-bottom: 20px;'>
	<div *ngIf="!embedded" class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>
						{{trans.trans.competitive_tco.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.competitive_tco" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-6' *ngIf="!embedded">
					<div style='float: right; display: block;' *ngIf="feature76">
						<input type='checkbox' [(ngModel)]="showAllMetrics" (click)='getCompetitiveTco(valueProp.id)'>
						{{trans.trans.show_more.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.show_more" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class='row'>
		<div class='panel pane-white'>
			<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
			<div class='panel-heading' style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
				<h3 *ngIf="!embedded">
					{{trans.trans.tco_summary.value}} (Year {{valueProp.term}})
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.tco_summary" (callback)="getTranslations()"></app-editTranslation>
				</h3>
				<h3 *ngIf="embedded">
					{{trans.trans.competitive_tco.value}} (Year {{valueProp.term}})
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.competitive_tco" (callback)="getTranslations()"></app-editTranslation>

					<div style='float: right; display: block;' *ngIf="embedded && feature76">
						<input type='checkbox' [(ngModel)]="showAllMetrics" (click)='getCompetitiveTco(valueProp.id)'> {{trans.trans.show_more.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.show_more" (callback)="getTranslations()"></app-editTranslation>

					</div>
				</h3>

			</div>
			<div class='panel-body' *ngIf="competitiveData">
				<div class='row'>
					<div class='col-xs-6' style='overflow-x: auto;'>
						<div class='row tco_row'>
							<div *ngFor="let header of competitiveData.labels; let i = index;" style='width: 170px;'><b> {{header}}</b></div>
						</div>
						<div>
							<div *ngFor="let row of competitiveData.columns">
								<div class='row tco_row'>
									<div *ngFor="let tco of row;let i = index;" style='width: 170px;'>
										<strong *ngIf="i === 0">{{tco}}</strong>
										<div *ngIf="i !== 0">{{tco}}</div>
									</div>
								</div>
							</div>

						</div>
					</div>
					<div class='col-xs-6'>
						<p-chart type="bar" [options]='chartOptions' [data]="chartData"></p-chart>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<app-value-prop-flow-buttons [moveToBottom]="false" *ngIf="embedded" [next]="false" (goBack)="back.emit()"></app-value-prop-flow-buttons>