import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class BenefitFlyoutTrackerTranslation extends TranslationsBaseService {
  public config: any = {
    component: 'BenefitFlyoutTrackerRealized',
    display_name: 'Flyout with benefit details for trackers on value realized',
    description: 'Flyout used to check the details of a benefit inside trackers',
  };

  public trans = this.typeObjectKeys({
    formula: 'Formula',
    currentEffort: 'Current Effort',
    with: 'With',
    year: 'Year',
    benefitPerformance: 'Benefit Performance',
    courseCorrection: 'Course Correction',
    benefitDetails: 'Benefit Details',
    description: 'Description',
    notes: 'Notes',
    issues: 'Issues',
    resolutions: 'Resolutions',
    expectedValue: 'Expected Value',
    back: 'Back',
    areYouSure: 'Are you sure?',
    edit: 'Edit',
    saveChanges: 'Save Changes',
    cancelChanges: 'Cancel Changes',
    confirmCancel: 'There are unsaved changes, do you want to close and discard changes?',
    addNote: 'Add Note',
    addOrEditNotes: 'Add or Edit Notes',
    calculateSave: 'Calculate and Save',
    improvement: 'Improvement',
  });
}
