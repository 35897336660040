import { Injectable } from '@angular/core';

@Injectable()
export class AEBenefitsCasestudyTranslations {
	public config: any = {
		component: 'aeBenefitsCaseStudies',
		display_name: 'Case Study Benefits Form',
		description: 'Form for creating and editing benefits for a case study'
	};

	public trans: any = {
		addBenefit: 'Add Benefit',
		editBenefit: 'Edit Benefit',
		search: 'Search',
		add: 'Add',
		selected: 'Selected',
		improvement: 'Improvement',
		type: 'Type',
		save: 'Save',
		update: 'Update',
		edit_translations: 'Edit Translations',
		are_you_sure: 'Are you sure?',
		back: 'Back'
	};
}
