<form name="formAddThreshold" #formAddThreshold="ngForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebar_title.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="sidebar_title"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddThreshold.controls.threshold_type_id">
            <label for="alterEgo" class="sidebar_label"
              >{{ trans.trans.name.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.name"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <select
              class="form-control role-dropdown"
              ret-data="Name"
              [(ngModel)]="thresholdFormObj.threshold_type_id"
              placeholder="trans.trans.placeholderThreshold.value"
              name="threshold_type_id"
              type="text"
              required
            >
              <option [value]="null" disabled="disabled">{{ trans.trans.chooseOne.value }}</option>
              <option *ngFor="let threshold of getThresholdTypesNotExisting()" [value]="threshold.threshold_type_id">
                {{ threshold.name }}
              </option>
            </select>
            <span style="color: red" *ngIf="getThresholdTypesNotExisting().length === 0">
              {{ trans.trans.thresholdError.value }}
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4" appNGError [elemControl]="formAddThreshold.controls.min">
            <label for="name" class="sidebar_label">
              {{ trans.trans.min.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.min"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input type="number" class="form-control" ret-data="Min" id="min" [(ngModel)]="thresholdFormObj.min" name="min" placeholder=" Enter Min" minlength="ngModel" required />
          </div>

          <div class="col-md-4" appNGError [elemControl]="formAddThreshold.controls.max">
            <label for="max" class="sidebar_label">
              {{ trans.trans.max.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.max"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input type="number" class="form-control" ret-data="Max" id="max" [(ngModel)]="thresholdFormObj.max" name="max" placeholder=" Enter Max" maxlength="ngModel" required />
            <span style="color: red" *ngIf="formAddThreshold.controls.min?.value > formAddThreshold.controls.max?.value">
              {{ trans.trans.minMaxError.value }}
            </span>
          </div>

          <div class="col-md-4" appNGError [elemControl]="formAddThreshold.controls.margin">
            <label for="margin" class="sidebar_label">
              {{ trans.trans.margin.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.margin"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input type="number" class="form-control" ret-data="Margin" id="margin" [(ngModel)]="thresholdFormObj.margin" name="margin" placeholder=" Enter Margin" required />
          </div>
        </div>
      </div>
    </div>

    <div class="btn_container">
      <div class="col-md-12">
        <input type="hidden" name="id" [(ngModel)]="thresholdFormObj.id" />
        <app-btn-save [form]="formAddThreshold" (callback)="saveThreshold(formAddThreshold)" [text]="trans.trans.save.value" class="btn btn_customizable pull-right"></app-btn-save>
        <app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
      </div>
    </div>
  </div>
</form>
