import { ValueProp } from '@shared/models/value-prop.model';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Navstep$, SimpleImplementation } from './simple.implementation';
import { forkJoin, Subject, Observable, from } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { AssumptionsComponent } from 'app/value-prop/value-prop-dashboard/discovery/assumptions/assumptions.component';
import { AssumptionsFastFillComponent } from 'app/value-prop/value-prop-dashboard/discovery/fast_fill/assumptions.component';
import { ConfirmationService } from 'primeng/api';
import { RepSimpleTranslations } from './simple.translation';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { VpTooltipPipe } from 'app/pipe/vp-tooltip.pipe';

@Component({
  selector: 'app-dashboard-simple-v2',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RSV2Component implements OnInit, OnDestroy {
  ValuePropId: string;
  canEdit = false;
  dashboardTour: GuidedTour;
  detailsDefaultPage = 'account';

  feature49 = false;
  feature52 = false;
  feature67 = false;
  feature77 = false;

  isDev = false;
  isMobile = false;
  isValueReaiztionUser = false;
  menu: any[] = [];
  ngUnsubscribe = new Subject();
  permissions: any;
  sc_vpg = false;
  screenWidth: any;
  showTranslate = false;
  small = false;
  step = 0;
  default_wf_step;
  toggleDebug = false;

  valueProp: ValueProp;
  valuePropLoader = false;
  viewTop: 'account' | 'full_view' | 'keyassumptions' | 'costs' | 'results' | 'benefits' = 'account';
  viewTopMap = new Map<string, string>();

  changeVPName = false;
  crm: string;
  isRejected = false;
  public workFlow: ConversationWorkflow;
  public isHypothesis = false;
  public vpTooltip = '';

  @ViewChild('assumptions') assumptions: AssumptionsComponent;
  @ViewChild('assumptionsFastfill') assumptionsFastfill: AssumptionsFastFillComponent;

  constructor(
    public sI: SimpleImplementation,
    private commonService: CommonService,
    private guidedTourService: GuidedTourService,
    private translationService: TranslationsV2Service,
    public trans: RepSimpleTranslations,
    private route: ActivatedRoute,
    public valuePropService: ValuepropService,
    private userService: UserService,
    private RS: RepSimpleViewService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private configurableWorkflowService: ConfigurableWorkflowService,
    private vpTooltipPipe: VpTooltipPipe
  ) {}

  ngOnInit(): void {
    this.createViewTopMap();
    this.funcBuildNav();

    this.crm = sessionStorage.getItem('crm');
    sessionStorage.removeItem('benefit_index');

    if (sessionStorage.getItem('selectedConversationWorkflow') && sessionStorage.getItem('selectedConversationWorkflow') !== 'undefined') {
      this.workFlow = JSON.parse(sessionStorage.getItem('selectedConversationWorkflow'));
    }

    this.valuePropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res !== 'no_reload') {
        this.sI.collapseBenefitMini();
      }

      this.retrieveValuePropDetails();
    });
    [this.feature52, this.feature67, this.feature77] = this.commonService.checkFeatures(52, 67, 77);
    this.getTranslations(() => {
      this.setUpTour();
      
      const default_step = this.findDefaultStep();
      if ( default_step ) {
        this.switchRoute( default_step );
      }

      this.configurableWorkflowService.selectedConversationWorkflow$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((wf: ConversationWorkflow) => {
        // When workflow is switched look for the currently selected conversation. 
        //if found change change step to it. else move step to 0:
        const oldWF = { ...this.workFlow };
        this.workFlow = { ...wf };
        this.funcBuildNav();
        let customStep = this.step;

        // Set default wf back to undefined
        this.default_wf_step = undefined;

        const defaultStep = wf.conversations?.find((w) => w.is_default_member === true);
        const foundWF = wf.conversations?.find((w) => w.name === this.getViewTopKeyByValue(this.viewTop));

        if (defaultStep) {
          // First choice will be the default step from the workflow
          customStep = this.menu.findIndex((w) => this.viewTopMap.get(defaultStep.name) === w.viewTop);
          this.default_wf_step = customStep;
        } else if (foundWF) {
          // Second choice will be staying on the same step
          customStep = this.menu.findIndex((w) => this.viewTopMap.get(foundWF.name) === w.viewTop);
        } else {
          // Otherwise go to 0
          customStep = 0;
        }
        this.workFlow = { ...wf };
        this.switchRoute(customStep);
      });

      this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
        this.ValuePropId = params['vpid'];
        this.retrieveValuePropDetails();
        this.funcBuildNav();
        
        if (params['step'] && !this.default_wf_step) {
          const step = parseInt(params['step'], 10);
          this.step = step >= this.menu.length - 1 ? this.menu.length - 1 : step;
          for (let i = 0; i < this.step; i++) {
            this.menu[i].done = true;
          }
          this.gotoStep(this.step);
          Navstep$.next(this.step);
        }
      });
    });

    
    this.isDev = this.commonService.isDev$;

    this.commonService.notifyNavToggle.subscribe((res) => {
      this.isMobile = res;
    });

    if (this.feature67) {
      this.sc_vpg = true;
    }

    this.isValueReaiztionUser = this.commonService.checkProduct(4);
  }

  findDefaultStep() {
    const defaultStep = this.workFlow?.conversations?.find((w) => w.is_default_member === true);
    if (defaultStep) {
      this.default_wf_step = defaultStep;
      return this.menu.findIndex((w) => this.viewTopMap.get(defaultStep.name) === w.viewTop);
    }

    this.default_wf_step = undefined;

    return null;
  }

  showContinue() {
    return this.viewTop !== this.menu.slice(-1)[0].viewTop;
  }

  private switchRoute(customStep = -1) {
    this.route.params.pipe(take(1)).subscribe((params) => {
      this.ValuePropId = params['vpid'];
      this.retrieveValuePropDetails();
      this.funcBuildNav();

      if (params['step']) {
        const step = customStep === -1 ? parseInt(params['step'], 10) : customStep;
        this.step = step >= this.menu.length - 1 ? this.menu.length - 1 : step;
        for (let i = 0; i < this.step; i++) {
          this.menu[i].done = true;
        }
        this.gotoStep(this.step);
        Navstep$.next(this.step);

      } else if ( customStep != -1 && this.valueProp?.done === '1' ) {
        this.step = customStep >= this.menu.length - 1 ? this.menu.length - 1 : customStep;
        for (let i = 0; i < this.step; i++) {
          this.menu[i].done = true;
        }
        this.gotoStep(this.step);
        Navstep$.next(this.step);

      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.sI.collapseBenefitMini();
    this.sI.style2022ViewTop = null;
  }

  funcToggleTranslations(): void {
    this.commonService.notifyEditTranslations.next();
  }

  retrieveValuePropDetails(): void {
    if (!this.ValuePropId) {
      return;
    }
    this.sI.valuePropLoader = true;
    this.valuePropService
      .getValuePropDetail(this.ValuePropId)
      .pipe(
        take(1),
        finalize(() => (this.sI.valuePropLoader = false))
      )
      .subscribe((response) => {
        this.valuePropService.valueProp.next(response.result.value_prop);
        this.getValuePropDetails();
      });
  }

  getValuePropDetails(): void {
    this.valuePropService.valueProp.pipe(take(1)).subscribe((valueProp) => {
      if (valueProp) {
        this.valueProp = valueProp;
        this.isHypothesis = Number(this.valueProp.value_prop_status) <= 3;
        this.canEdit = this.valueProp.vp_can_edit;
        this.valueProp.benefits_loading = true;

        this.valuePropService
          .quickFillGetBenefits(this.ValuePropId)
          .pipe(take(1))
          .subscribe((response) => {
            this.valueProp.benefits = response.result.vcs;
            const chartColors = this.commonService.getChartColors();

            this.valueProp.benefits.forEach((elem, index: number) => {
              elem.color = chartColors[index];
            });

            this.valueProp.benefits_loading = false;
          });
        if (this.step !== 4) {
          this.sI.valuePropLoader = false;
        }
        this.permissions = {
          benefits_editable: true,
          result_editable: true,
        };
        const s = this.valueProp.step;
        for (let i = 0; i < parseInt(s, 10); i++) {
          this.menu[i].done = true;
        }
        this.sI.valuePropLoader = false;
      }
    });
  }

  funcToggleSupport(): void {
    this.userService.toggleSupportSidebar.next('');
  }

  funcBuildNav(): void {
    this.menu = [];
    this.menu.push({
      label: this.trans.trans.defineScenario.value,
      viewTop: 'account',
    });
    this.menu.push({
      label: this.trans.trans.reviewBenefits.value,
      viewTop: 'benefits',
    });
    this.menu.push({
      label: this.trans.trans.provideNumbers.value,
      viewTop: 'keyassumptions',
    });
    this.menu.push({
      label: this.trans.trans.addCosts.value,
      viewTop: 'costs',
    });
    this.menu.push({
      label: this.trans.trans.reviewResults.value,
      viewTop: 'results',
    });
    if (this.workFlow && this.workFlow.conversations && this.workFlow.conversations.length) {
      this.menu = this.menu.filter((m) => {
        return this.workFlow.conversations.some((w) => {
          return this.viewTopMap.get(w.name) === m.viewTop;
        });
      });
    }
  }

  gotoStep(step: number) {
    this.step = step;
    this.router.navigate(['repv2/', this.ValuePropId, step]);
    if (step !== 4 && sessionStorage.getItem('metricsExpandedMini')) {
      sessionStorage.removeItem('metricsExpandedMini');
    }
    this.viewTop = this.menu[this.step].viewTop;
  }

  callbackNext(routeEvent?): void {
    if (routeEvent) {
      this.directRouteToStep(routeEvent);
    } else {
      if (this.valueProp.done === '0') {
        const payload2 = { step: this.step };
        this.RS.putDone(this.valueProp.id, payload2)
          .pipe(take(1))
          .subscribe(() => {});
      }
      this.menu[this.step]['done'] = true;
      const tmp = Number(this.step) + 1;
      if (tmp >= this.menu.length) {
        return;
      }

      this.step++;
      this.router.navigate(['repv2/', this.valueProp.id, this.step]);

      this.viewTop = this.menu[this.step].viewTop;
    }
  }

  directRouteToStep(route: string): void {
    const stepRoute = this.menu.findIndex((step) => step.viewTop === route);
    if (this.valueProp.done === '0') {
      const stepEndpoints = this.menu
        .filter((index) => index <= stepRoute)
        .map((index) => {
          const payload = { step: index };
          return this.RS.putDone(this.valueProp.id, payload);
        });
      forkJoin(stepEndpoints).pipe(take(1)).subscribe();
    }
    this.menu = this.menu.map((step, index) => ({
      ...step,
      done: index <= stepRoute,
    }));

    this.step = stepRoute;
    this.router.navigate(['repv2/', this.valueProp.id, this.step]);

    this.viewTop = this.menu[this.step].viewTop;
  }

  confirmBackNavigation(): void {
    if (+this.step === 0) {
      return;
    }
    this.step--;
    this.router.navigate(['repv2/', this.valueProp.id, this.step]);
    this.viewTop = this.menu[this.step].viewTop;
    this.valuePropLoader = false;
  }

  funStartTour(): void {
    this.setUpTour();
    this.guidedTourService.startTour(this.dashboardTour);
  }

  getTranslations(callback): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        callback();
        this.vpTooltip = this.vpTooltipPipe.transform(this.valueProp, this.trans);
      });
  }

  setUpTour(): void {
    this.dashboardTour = {
      tourId: 'dashboard-tour',
      useOrb: false,
      steps: [
        {
          title: this.trans.trans.titleNavigation.value,
          content: this.trans.trans.contentNavigation.value,
          selector: '.tour_menu',
          orientation: Orientation.Bottom,
        },
        {
          title: this.trans.trans.titleAction.value,
          content: this.trans.trans.contentAction.value,
          selector: '.tour_icons',
          orientation: Orientation.Left,
        },
        {
          title: this.trans.trans.titleInfo.value,
          content: this.trans.trans.contentInfo.value,
          selector: '.tour_more_info',
          orientation: Orientation.Right,
        },
        {
          title: this.trans.trans.titleBenefitsChart.value,
          content: this.trans.trans.contentBenefitsChart.value,
          selector: '.tour_benefits',
          orientation: Orientation.Bottom,
        },
        {
          title: this.trans.trans.titleRoi.value,
          content: this.trans.trans.contentRoi.value,
          selector: '.tour_roi',
          orientation: Orientation.Bottom,
        },
        {
          title: this.trans.trans.titleCashflow.value,
          content: this.trans.trans.contentCashflow.value,
          selector: '.tour_cashflow',
          orientation: Orientation.Top,
        },
      ],
    };
  }

  openAllDetails(): void {
    this.viewTop = 'full_view';
  }

  cancelChangeName(): void {
    this.changeVPName = false;
  }

  submitChangeName(newName: string): void {
    this.valuePropService.updateValueProp(this.valueProp.id, { name: newName }).subscribe(() => {
      this.valueProp = { ...this.valueProp, name: newName };
      this.vpTooltip = this.vpTooltipPipe.transform(this.valueProp, this.trans);
      this.changeVPName = false;
      this.notificationService.success('Name updated successfully', false);
    });
  }

  hideVPName(event: boolean): void {
    this.changeVPName = event;
  }

  askSave(step?, navigation: 'back' | 'next' | 'any' = 'any'): void {
    const showPrompt =
      (+this.step === 2 && this.feature52 && this.assumptionsFastfill && this.assumptionsFastfill.isSomeUpdated) ||
      (+this.step === 2 && !this.feature52 && this.assumptions && this.assumptions.isSomeUpdated);
    if (showPrompt) {
      if (navigation === 'next') {
        this.updateAssumptions().subscribe(() => this.callNext(step, navigation));
      } else {
        this.confirmationService.confirm({
          accept: () => {
            this.updateAssumptions().subscribe(() => this.callNext(step, navigation));
          },
          reject: () => {
            if (this.isRejected) {
              this.callNext(step, navigation);
              this.isRejected = false;
            }
          },
        });
      }
    } else {
      this.callNext(step, navigation);
    }
  }

  callNext(step?, navigation: 'back' | 'next' | 'any' = 'any'): void {
    if (navigation === 'back') {
      this.confirmBackNavigation();
    } else {
      this.gotoStep(step);
    }
  }

  updateAssumptions(): Observable<unknown> {
    let $action: Observable<unknown>;
    if (this.feature52) {
      $action = from(this.assumptionsFastfill.updateAll('no emit'));
    } else {
      $action = from(this.assumptions.updateAll('no emit'));
    }
    return $action;
  }

  cancel(): void {
    this.router.navigate(['/valueposition']);
  }

  public getVPTooltip() {
    return `${this.valueProp.name}\n ${this.isHypothesis ? this.trans.trans.hypothesis.value : this.trans.trans.businessCase.value} ${this.trans.trans.vpCreatedBy.value} ${
      this.valueProp.creators_name
    } \n ${this.valueProp.last_modified_by_name && this.trans.trans.modifiedBy.value} ${this.valueProp.last_modified_by_name && this.valueProp.last_modified_by_name}`;
  }

  private createViewTopMap() {
    this.viewTopMap.set('Define Scenario', 'account');
    this.viewTopMap.set('Review Benefits', 'benefits');
    this.viewTopMap.set('Provide Numbers', 'keyassumptions');
    this.viewTopMap.set('Add Costs', 'costs');
    this.viewTopMap.set('Review Results', 'results');
  }

  private getViewTopKeyByValue( searchStr: string ) {
    for ( let [key, value] of this.viewTopMap.entries() ) {
      if ( value === searchStr ) {
        return key;
      }
    }
  }
}
