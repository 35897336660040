<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ selectedGoal?.name }} <span *ngIf="selectedGoalOrignal?.goalSurveyFactor">– {{ selectedGoalOrignal?.goalSurveyFactor.business_review_name }}</span>
  </div>
  <div class="sidebar_container">
    <div class="goal-grid">
      <div class="goal-title">{{ trans.trans.goalName.value }}</div>
      <div class="content-box">{{ selectedGoal?.name }}</div>
      <div class="spacer"></div>
      <div class="formula-title">{{ selectedGoal?.scratchpadString ? trans.trans.formula.value : '' }}</div>
      <div>
        <ng-container *ngIf="selectedGoal?.scratchpadString === null || selectedGoal?.scratchpadString === ''; else scratchpadContent">
          <div class="progressbar-container"></div>
        </ng-container>
      </div>

      <ng-container *ngIf="!inputView">
        <div>
          <ng-container *ngIf="!!selectedGoal?.linkedFactors">
            <div class="details-title">{{ trans.trans.linkedFactors.value }}</div>
            <div class="content-box">{{ selectedGoal?.linkedFactors }}</div>
          </ng-container>
        </div>
        <div>
          <ng-container *ngIf="!!selectedGoal?.description">
            <div class="description-title">{{ trans.trans.description.value }}</div>
            <div class="description-box">{{ selectedGoal?.description }}</div>
          </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="goalChartTemplate"> </ng-container>
      </ng-container>
    </div>
    <div class="flex-jbetween notes-header">
      <div class="description-title">
        <span *ngIf="brsWithNotes && brsWithNotes.length > 0">{{ trans.trans.notes.value }}</span>
      </div>
      <div>
        <button *ngIf="canEdit" class="btn btn_customizable pull-right" (click)="showNoteSidebar = true">{{ trans.trans.addOrEditNotes.value }}</button>
      </div>
    </div>

    <app-business-review-notes-table [valuePropId]="valuePropId" [disableEdit]="true" [objectId]="selectedGoal.accountFactorId" [section]="'goal'"></app-business-review-notes-table>
  </div>
</div>

<p-sidebar *ngIf="showNoteSidebar" [(visible)]="showNoteSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" [modal]="false">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.addNote.value }}
    </div>
    <div class="sidebar_container">
      <app-business-review-notes-sidebar
        [valuePropId]="valuePropId"
        [objectId]="selectedGoal.accountFactorId"
        [section]="'goal'"
        (backClicked)="closeNotesSidebar()"
      ></app-business-review-notes-sidebar>
    </div>
  </div>
</p-sidebar>

<ng-template #scratchpadContent>
  <div *ngIf="!inputView; else formula" class="content-box">
    {{ selectedGoal?.scratchpadString }}
  </div>
</ng-template>

<ng-template #formula>
  <div class="scratchpad">
    <app-scratchpad-component
      *ngIf="!!selectedGoalOrignal"
      (SaveAndUpdateCallback)="closeSidebarEventEmit($event)"
      factorTypeId="0"
      [valueMetricId]="selectedGoal.accountFactorId"
      [valuePropId]="valuePropId"
      [valueMetric]="selectedGoalOrignal"
      [goal]="true"
      [trackerDetail]="true"
      [sidebarStyle]="true"
      [surveyFactor]="selectedGoalOrignal.goalSurveyFactor"
      [year]="1"
    ></app-scratchpad-component>
  </div>
  <div></div>

  <ng-container *ngIf="!!goalChart">
    <ng-container *ngTemplateOutlet="goalChartTemplate"> </ng-container>
  </ng-container>
</ng-template>

<ng-template #goalChartTemplate>
  <div *ngIf="!!goalChart" class="chart-container-goals">
    <div class="title-goals">{{ selectedGoal?.name }}</div>
    <dl-chart idd="pBar1" type="line" [responsive]="true" height="208px" width="100%" [options]="chartOptions" [data]="goalChart"></dl-chart>
  </div>
</ng-template>
