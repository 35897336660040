<div [ngClass]="{ style2022: (style2022$ | async) }">
  <form #createSurvey="ngForm">
    <div class="panel-body">
      <div class="row" style="display: flex">
        <div class="col-xs-4">
          <label
            >{{ trans.trans.survey_template_name.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.survey_template_name"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </label>
          <input type="text" name="template_name" [(ngModel)]="template_name" class="form-control" autocomplete="off" />
        </div>
        <div class="col-xs-3" style="margin-left: 8px; margin-top: auto">
          <p-dropdown appendTo="body"
            [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
            class="keywest_dropdown dropdown-theme"
            [options]="options"
            [(ngModel)]="is_active"
            [ngModelOptions]="{ standalone: true }"
            optionLabel="name"
            dataKey="id"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="panel-body">
      <div class="row question-container" *ngFor="let question of questions; let i = index">
        <div class="col-xs-7">
          <div class="row">
            <div class="form-group">
              <strong class="question-label">Q {{ i + 1 }}</strong>
              <input type="text" name="question{{ question.id }}" [(ngModel)]="question.question" class="form-control" [placeholder]="trans.trans.question_num.value" />
            </div>
          </div>
        </div>

        <div class="col-xs-3" style="margin-left: 8px">
          <p-dropdown appendTo="body"
            [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
            class="keywest_dropdown dropdown-theme"
            [options]="questionTypes"
            [(ngModel)]="question.question_type_id"
            (ngModelChange)="questionTypeChange(question.question_type_id, 'question' + question.id)"
            [name]="'questionType' + question.id"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="trans.trans.answer_type.value"
          ></p-dropdown>
        </div>

        <div class="col-md-1">
          <i (click)="deleteQuestion(i)" style="float: left; margin-right: 10px" class="fa fa-trash-o clickable"></i>
        </div>

        <div class="answer-box-container">
          <div class="col-xs-7">
            <div style="pointer-events: none" class="form-group" *ngIf="question.question_type_id == '1'">
              <input type="text" class="form-control" placeholder="{{ trans.trans.short_text.value }}" />
            </div>

            <div class="form-group" *ngIf="question.question_type_id == '2'">
              <div class="create-options">
                <label (click)="addOptions(i)" class="add-option">+ Add another option</label>
                <div class="option-group" *ngFor="let option of question.options; let i = index">
                  <input type="radio" disabled />
                  <input
                    type="text"
                    name="question{{ question.id }}option{{ i }}"
                    placeholder="{{ trans.trans.option_label.value }}"
                    class="form-control"
                    [(ngModel)]="option.option ? option.option : option.name"
                    required
                  />
                  <i class="fa fa-times" aria-hidden="true" (click)="deleteOptions(question, option)"></i>
                </div>
              </div>
            </div>

            <div style="pointer-events: none" class="form-group" *ngIf="question.question_type_id == '4'">
              <textarea rows="1" class="form-control">{{ trans.trans.long_text.value }}</textarea>
            </div>

            <div class="form-group" *ngIf="question.question_type_id == '5'">
              <div class="create-options">
                <label (click)="addOptions(i)" class="add-option">+ Add another option</label>
                <div class="option-group" *ngFor="let option of question.options; let i = index">
                  <input type="checkbox" style="margin-right: 0.5em" disabled />
                  <input
                    type="text"
                    name="question{{ question.id }}option{{ i }}"
                    placeholder="{{ trans.trans.option_label.value }}"
                    class="form-control"
                    [(ngModel)]="option.option ? option.option : option.name"
                    required
                  />
                  <i class="fa fa-times" aria-hidden="true" (click)="deleteOptions(question, option)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-7">
          <div class="add-question">
            <span (click)="enableQuestion()">+ Add Question</span>
          </div>
        </div>
      </div>
    </div>

    <div class="button-group pull-right" style="margin-top: 1em">
      <img class="actionLoader" [src]="fullImagePath" *ngIf="actionLoader" />
      <button class="btn btn-secondary btn-transparent" (click)="hideForm()">Cancel</button>
      <button class="btn btn_customizable" *ngIf="surveyTemplateId" (click)="updateSurveyTemplate()">
        {{ trans.trans.update_template.value }}
      </button>
      <button class="btn btn_customizable" *ngIf="!surveyTemplateId" (click)="createSurveyTemplate()">
        {{ trans.trans.create_template.value }}
      </button>
    </div>
  </form>

  <div *ngIf="surveyTemplateLoader" class="col-12 text-center">
    <img class="actionLoader" [src]="fullImagePath" />
  </div>
</div>