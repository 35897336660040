import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { CostsComponent } from './costs.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { TranslateLoader } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SidebarModule } from 'primeng/sidebar';
import { AddCostModalModule } from 'app/value-prop/common/add-cost-modal/add-cost-modal.module';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CostVpDashboardTranslation } from './cost.translation';
import { ProgressBarModule } from 'primeng/progressbar';
import { ResultsAnalysisModule } from '@shared_components/results-analysis/results-analysis.module';
import { TextTruncateModule } from '@shared/pipes/text-truncate.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    ProgressBarModule,
    MenuModule,
    TableModule,
    MatTooltipModule,
    MenuModule,
    AddCostModalModule,
    ApplicationPipesModule,
    SidebarModule,
    CommonModule,
    EditTranslationsModule,
    NgbModule,
    FormsModule,
    ResultsAnalysisModule,
    TextTruncateModule,
    EllipsisMenuModule,
    AreYouSureModule,
  ],
  declarations: [CostsComponent],
  providers: [Translations, TranslationsV2Service, CostVpDashboardTranslation],
  exports: [CostsComponent],
})
export class CostsModule {}
