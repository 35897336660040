import { NgModule } from '@angular/core';

import { DLListDetailComponent } from './list_detail.component';

import { MenuModule } from 'primeng/menu';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PaginatorModule } from 'primeng/paginator';
import { ListDetailTranslations } from './list_detail.translation';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [PaginatorModule, SelectButtonModule, MatTooltipModule, MenuModule, FormsModule, CommonModule, ApplicationPipesModule, AreYouSureModule],
  exports: [DLListDetailComponent],
  declarations: [DLListDetailComponent],
  providers: [ListDetailTranslations],
})
export class DLListDetailModule {}
