<p-table [value]="benefits" styleClass="keywest_table" tableStyleClass="keywest_table"
  >>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="acknowledged" class="acknowledged-col">
        {{ t.acknowledged }}
        <p-sortIcon field="acknowledged"></p-sortIcon>
      </th>
      <th pSortableColumn="metric_name">
        {{ t.benefitName }}
        <p-sortIcon field="metric_name"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-benefit>
    <tr>
      <td class="acknowledged-col">
        <p-checkbox [binary]="true" [(ngModel)]="benefit.acknowledged" (onChange)="onAcknowledge(benefit)"></p-checkbox>
      </td>
      <td (click)="onSelected(benefit)" class="clickable">{{ benefit.metric_name }}</td>
    </tr>
  </ng-template>
</p-table>
