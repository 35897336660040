import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgModule } from '@angular/core';
import { EditTranslationDirective } from './edit.directive';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { FormsModule } from '@angular/forms';
import { EditDirectiveService } from './edit.service';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationTranslations } from './edit.translation';

@NgModule({
  declarations: [EditTranslationDirective],
  entryComponents: [EditTranslationDirective],
  imports: [ApplicationPipesModule, OverlayPanelModule, CommonModule, NgbModule, FormsModule],
  providers: [EditDirectiveService, TranslationsV2Service, EditTranslationTranslations],
  exports: [EditTranslationDirective],
})
export class EditTranslationsModule {}
