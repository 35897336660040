import { EventEmitter, HostListener, Injectable, Input, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@services/alert.service';
import { NotificationService } from '@services/notification.service';
import { FeedbackResponse } from '@shared/models/value-prop.model';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { SimpleImplementation as SimpleImplementation2 } from '../../../repv2/simple.implementation';
import { AddBenefitsModelComponent } from '../add-benefits-model/add-benefits-model.component';
import { DashboardSimpleImplementation } from '../dashboard_simple/simple.implementation';
import { BenefitsImplentationTranslations } from './benefits.translation';
import { BenefitType, BenefitVCS, BenefitVCSMetric } from '@shared/models/benefit-type.model';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';
import { VCData } from '@shared/models/vc-data.model';

@Injectable()
export class BenefitsImplementation {
  @Input() menuFilter = '';
  @Input() showHelp;
  @Input('data') valueProp: any;
  @Input() valuePropId: any;
  @Input('dealdesk') dealdesk: boolean;
  @Input() refreshBenefits: boolean;
  @Input() tabInit: number;
  @Input() contextualHelp: boolean;
  @Input() loadBenefit: boolean = false;
  @Input() dashboard: string = '';
  @Input() panel: string = '';
  @Input() permissions;
  @Input() shareView;
  @Input() benefitsView;
  @Input() locked: boolean = false;
  @Input() mini: boolean = false;

  toggleSeqSort: number = 0;
  ngUnsubscribe = new Subject();

  statusFilter: string = '';
  @Input() showTranslate: boolean = false;
  showTranslateSidebar: boolean = false;
  @Output() funcCallback = new EventEmitter();
  @Output() callbackNext = new EventEmitter();
  @Output() back = new EventEmitter();

  loadBenefits$ = new BehaviorSubject<string>(null);
  fullImagePath: string;
  modalReference: any;
  image_url: string;
  benefitsLoader: boolean = false;
  loadingInPlace: boolean = false;
  benefitsList: any[] = [];
  benefitsListByType: BenefitType[];

  metricsid: number;
  metricbenefits: any = [];
  metricbenefitsLoader: boolean = false;
  isTable: boolean = true;
  inputOpened: boolean = false;
  @Input() selectedBenefit: any;
  @Input() benefitDetail: boolean = false;
  showAllBenefitsCheck: boolean = true;
  feedback: any;
  feedbackList: FeedbackResponse;
  featuresOptions: any;
  featuresopt: any;
  has_funcobj: boolean;
  has_pain_point: boolean;
  has_capabilities: boolean;
  has_opobj: boolean;
  has_sequence: boolean;

  createFlow: boolean = false;

  activeTypes: any[] = [];

  showActive: boolean = true;
  showNA: boolean = false;
  showSoft: boolean = false;
  showDefinedSequence: boolean = false;
  showTotalBenefits: boolean = false;

  privileges: any;
  benefitOnTheFlyPrivilege: boolean = false;
  showBatchUpdateCheckboxes: boolean = false;
  batchActive: string = '1';
  benefitsPanel: boolean = false;
  @Input() benefitsSimple: boolean = false;

  expandCollapse: boolean = false;
  showDiscoveryQuestions = false;

  definedSequence: number = 0;
  totalBenefits: number = 1;

  modalLoading: boolean;
  showPhasing: boolean = false;
  openDashboardUpdateBenefitCallback = new EventEmitter();
  menuBenefitList: any[] = [];
  @Output() callbackUpdateCurrent = new EventEmitter();
  showAll: any;
  header_color: string;
  subscriptionsaveChanges: Subscription;

  @Input() addPhasingModal: any;
  searchText: string;
  expandAll: boolean = false;
  showDeleteCustomBenefit: boolean = false;
  @Output() deleteCustomBenefitCallback = new EventEmitter();
  deleteBenefit: any;

  screenHeight: number;
  screenWidth: number;
  termLimit: number = 3;
  termLength: number = 2;
  termShow: number;

  inlinestatus: any[] = [];

  group: any[] = [];
  group_selected: string = 'value_category';
  sort: any[];
  filters: any[];
  actions: any[] = [];
  putDragDropBenefitCategorySubscription: Subscription;

  feature39: boolean = false;
  feature48: boolean = false;
  feature46: boolean = false;
  featureDiscoveryQuestionsUpFront = false;

  modelsv2 = false;
  view: string = 'repv1';
  firstLoad = true;
  private _heightCacheTrigger$ = new Subject<boolean>();
  benefitsListByType$ = new BehaviorSubject<BenefitType[] | null>(null);
  refreshVCNames$ = new Subject<BenefitType[]>();
  refreshActionMenu$ = new Subject<boolean>();
  private _benefitsUpdated$ = new Subject<string>();
  forceGroupRefresh = false;

  constructor(
    private commonService: CommonService,
    public valuePropService: ValuepropService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private alertService: AlertService,
    public trans2: Translations,
    public sI: DashboardSimpleImplementation,
    public sI2: SimpleImplementation2,
    private translationService: TranslationsV2Service,
    public trans: BenefitsImplentationTranslations,
    private rAS: ResultsAnalysisService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    this.alertService
      .subscribeLoggingOut()
      .pipe(take(1))
      .subscribe(() => {
        this.ngOnDestroy();
      });

    this.modelsv2 = this.commonService.checkFeature(38);
    this.feature39 = this.commonService.checkFeature(39);
    this.feature48 = this.commonService.checkFeature(48);
    this.feature46 = this.commonService.checkFeature(46);
    this.featureDiscoveryQuestionsUpFront = this.commonService.checkFeature('96');

    this.loadInPlace();
  }

  ngOnInit() {
    this.header_color = sessionStorage.getItem('header_color');

    if (this.panel !== '') {
      return;
    }

    this.featuresOptions = sessionStorage.getItem('features');
    this.featuresopt = this.featuresOptions.split(',');

    this.feedback = {
      rating: 0,
      comment: '',
    };
    this.benefitOnTheFlyPrivilege = this.commonService.checkPrivilege(6);

    this.valuePropService.refreshBenefitDetail.pipe(take(1)).subscribe(() => {
      this.loadBenefits();
    });

    this.commonService.notifyChangeShowTranslate.pipe(take(1)).subscribe(() => {
      this.commonService.notifyChangeShowTranslate.next();
    });

    this.loadBenefits();
    this.setUpDropdownActions();
    this.funcAdjustVisibleYears();

    this.commonService.notifyChangeLanguage.pipe(take(1)).subscribe(() => {
      this.refreshTranslation();
    });

    if (this.shareView == 'share') {
      this.locked = !this.permissions.benefits_editable;
    }
    if (!this.permissions) {
      this.permissions.benefits_editable = true;
    }
  }

  ngOnDestroy(): void {
    this.group_selected = 'value_category';
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
    this.firstLoad = true;
  }

  loadInPlace(refresh: boolean = true) {
    this.loadBenefits$
      .pipe(
        debounceTime(500),
        switchMap((benefitId) => {
          this.benefitsList = [];
          if ( refresh ) {
            this.benefitsLoader = true;
          } else {
            this.loadingInPlace = true;
          }
          const val = this.showAllBenefitsCheck ? 1 : 3;
          const id = this.getValueProp();
          if (!id) {
            this.benefitsLoader = false;
            return of(null);
          }

          return this.valuePropService.getValuePropAllBenefitsTurboSummedByCategory(id, val, this.toggleSeqSort, this.group_selected).pipe(
            tap((response) => {
              try {
                this.setData(response, benefitId);
                this.rAS.refreshVCChart(this.valueProp, null);
              } catch (e) {
                this.benefitsLoader = false;
                this._heightCacheTrigger$.next(false);
              }
            })
          );
          })
        )
        .subscribe();
  }

  get heightCacheTrigger(): Observable<boolean> {
    return this._heightCacheTrigger$ as Observable<boolean>;
  }

  set triggerCache(status: boolean) {
    this._heightCacheTrigger$.next(status);
  }

  get benefitsUpdated$(): Observable<string> {
    return this._benefitsUpdated$ as Observable<string>;
  }

  get benefitFirstLoad(): boolean {
    return this.firstLoad;
  }

  set benefitFirstLoad(status: boolean) {
    this.firstLoad = status;
  }

  setUpDropdownActions() {
    // set initial actions dropdown menu
    this.actions = [];
    if (this.expandCollapse) {
      this.actions.push({
        label: this.trans.trans.expand_all.value,
        command: () => {
          this.funcExpand(true);
        },
      });
    }
    if (this.valueProp.vp_can_edit && !this.locked) {
      this.actions.push({
        label: this.trans.trans.set_bulk_status.value,
        command: () => {
          this.openSetActive();
        },
      });
    }
  }

  saveDropEvent(droppedArray: BenefitVCS[]): void {
    const value_categories = droppedArray.map((item, index) => ({
      value_category_id: item.value_category_id,
      sequence: index,
    }));

    const payload = { value_categories };

    this.valuePropService
      .putDragDropBenefitCategory(this.getValueProp(), payload)
      .pipe(take(1))
      .subscribe(() => {
        this.toggleSeqSort = 0;
        this.funcBuildSort();
        this.has_sequence = true;
        this.refreshActionMenu$.next(true);
        this.rebuildVCChart(droppedArray, true);
        this.notificationService.success(this.trans.trans.notification_success.value, false);
      });
  }

  reorderBenefitsForChart(benefits: BenefitVCS[]): VCData {
    const namesOut = [];
    const percentsOut = [];
    const totalBenefitsOut = [];
    const totalBenefitsFmtOut = [];
    const totalBenefitsFrOut = [];

    this.rAS.vcChartResCache.vcData.result.names.forEach((name, index) => {
      const found = benefits.findIndex((benefit) => benefit.id === name.id);
      if (found > -1) {
        namesOut[found] = benefits[found];
        percentsOut[found] = this.rAS.vcChartResCache.vcData.result.percents[index];
        totalBenefitsOut[found] = this.rAS.vcChartResCache.vcData.result.total_benefits[index];
        totalBenefitsFmtOut[found] = this.rAS.vcChartResCache.vcData.result.total_benefits_fmt[index];
        totalBenefitsFrOut[found] = this.rAS.vcChartResCache.vcData.result.total_benefits_fr[index];
      }
    });
    return {
      names: namesOut,
      total_benefits: totalBenefitsOut,
      total_benefits_fmt: totalBenefitsFmtOut,
      total_benefits_fr: totalBenefitsFrOut,
      percents: percentsOut,
      total_annual_benefits: this.rAS.vcChartResCache.vcData.result.total_annual_benefits,
    };
  }

  rebuildVCChart(benefits: BenefitVCS[], saveSequence = false): void {
    if (!this.rAS.vcChartResCache) {
      return;
    }
    const vcData = { result: this.reorderBenefitsForChart(benefits) };
    if (saveSequence) {
      this.rAS.vcChartResCache.vcData = vcData;
    }
    this.rAS.funcGetVCChart(vcData, this.rAS.vcChartResCache.valueProp, this.rAS.vcChartResCache.view);
  }

  sortBenefitsByTotals(): void {
    const findBenefits = this.benefitsListByType.find((benefitType) => {
      return +benefitType.benefit_type_id === 1;
    });
    const sorted = findBenefits.vcs.sort((a, b) => {
      return b.vc_total - a.vc_total;
    });
    this.rebuildVCChart(sorted);
  }

  sortBenefitsBySequence(): void {
    this.rAS.funcGetVCChart(this.rAS.vcChartResCache.vcData, this.rAS.vcChartResCache.valueProp, this.rAS.vcChartResCache.view);
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.funcSetupFilterMenu();
        this.funcBuildSort();
        this.funcBuildGroupMenu();
        this.funcExpand(false, true);

        this.activeTypes = [
          { name: this.trans.trans.active.value, value: '1' },
          { name: this.trans.trans.na.value, value: '0' },
        ];
        if (!this.feature39) {
          this.activeTypes.push({ name: this.trans.trans.soft.value, value: '3' });
        }
      });
  }

  refreshTranslation() {
    this.getTranslations();
  }

  funcShowTranslate() {
    this.showTranslate = !this.showTranslate;
    // this.funcBuild();
  }

  funcExpand(expand?, onLoad?) {
    // handles expanding and collapsing of panels
    if (expand) {
      this.expandAll = expand;
      this.actions = [];
      if (this.expandCollapse) {
        this.actions.push({
          label: this.trans.trans.collapse_all.value,
          command: () => {
            this.funcExpand(false);
          },
        });
      }
      if (this.valueProp.vp_can_edit && !this.locked) {
        this.actions.push({
          label: this.trans.trans.set_bulk_status.value,
          command: () => {
            this.openSetActive();
          },
        });
      }
    } else {
      this.expandAll = false;
      this.actions = [];
      if (this.expandCollapse) {
        this.actions.push({
          label: this.trans.trans.expand_all.value,
          command: () => {
            this.funcExpand(true);
          },
        });
      }
      if (this.valueProp.vp_can_edit && !this.locked) {
        this.actions.push({
          label: this.trans.trans.set_bulk_status.value,
          command: () => {
            this.openSetActive();
          },
        });
      }
    }
    this.benefitsList.forEach((elem) => {
      if (onLoad) {
        return;
      } else {
        elem.expand = expand;
      }
    });
    if (!onLoad) {
      this._benefitsUpdated$.next('allExpand');
    }
  }

  callbackUpdate() {
    // used in vp create flow
    this.callbackUpdateCurrent.emit();
    this.commonService.refreshVPGroupDashboard.next();
  }

  funcSaveBenefits(res?) {
    return new Promise((resolve) => {
      // used to save active / not active toggle button
      let out: any[] = [];
      for (let j = 0; j < this.benefitsList.length; j++) {
        for (let i = 0; i < this.benefitsList[j].metrics.length; i++) {
          let metric = this.benefitsList[j].metrics[i];
          if (metric.updated) {
            out.push(metric);
          }
        }
      }
      let payload = { metrics: out };

      let h2 = [];
      for (let j = 0; j < this.benefitsList.length; j++) {
        if (this.benefitsList[j].active_clean === true) {
          this.benefitsList[j].active = 1;
        } else {
          this.benefitsList[j].active = 0;
        }
        let metric = this.benefitsList[j];
        let payload2 = {
          active: this.benefitsList[j].active,
          value_category_id: metric.value_category_id,
        };
        h2.push(this.valuePropService.UpdateBenefitsActive(this.getValueProp(), payload2));
      }

      if (!out.length && !h2.length) {
        this.callbackUpdateCurrent.emit();
        resolve(true);
      } else {
        // this.callbackUpdateCurrent.emit();
        h2.push(this.valuePropService.UpdateBenefitsCurrentFuture(this.getValueProp(), payload));
      }

      forkJoin(h2)
        .pipe(take(1))
        .subscribe(() => {
          this.callbackUpdateCurrent.emit();
          resolve(true);
        });
      this.notificationService.success(this.trans.trans.notification_benefit_success.value, false);
    });
  }

  funcToggleBenefit(b) {
    // b.active = !b.active;
  }

  getBenefits(benefitStatus) {
    // get the color for an active status
    switch (benefitStatus) {
      case '1':
        return 'green';
      case '2':
        return 'violet';
      case '5':
      case '0':
      case '4':
        return 'red';
      case '3':
        return 'orange';
    }
  }

  @HostListener('document:click', ['$event']) clickout(event) {
    let className = event.target.className.split(' ');
    if (className.indexOf('benefitStatusElement') === -1) {
      if (this.inputOpened) {
        this.inputOpened = false;
        if (this.benefitsList && this.benefitsList.length) {
          this.benefitsList.forEach((benefitCategory) => {
            benefitCategory.metrics.forEach((benefit) => {
              benefit['changeActive'] = false;
            });
          });
        }
      }
    }
  }

  setTotals(response) {
    let benefitsList = response.result.vcs;

    if (benefitsList && benefitsList.length) {
      for (let j = 0; j < benefitsList.length; j++) {
        // benefitsList[j].start = 1;
        //

        for (let i = 0; i < benefitsList[j].metrics.length; i++) {
          if (this.benefitsList[j].metrics[i]) {
            switch (benefitsList[j].metrics[i].active) {
              case '0':
                this.benefitsList[j].metrics[i].active_type = this.trans.trans.na.value;
                break;
              case '1':
                this.benefitsList[j].metrics[i].active_type = this.trans.trans.active.value;
                break;
              case '2':
                this.benefitsList[j].metrics[i].active_type = this.trans.trans.not_priority.value;
                break;
              case '3':
                this.benefitsList[j].metrics[i].active_type = this.trans.trans.soft.value;
                break;
              case '4':
                this.benefitsList[j].metrics[i].active_type = this.trans.trans.na.value;
                break;
              case '5':
                this.benefitsList[j].metrics[i].active_type = this.trans.trans.na.value;
                break;
              default:
                this.benefitsList[j].metrics[i].active_type = '';
                break;
            }
          }
        }
      }
    }
  }

  setData(response, benefitId?: string) {
    // this.loadBenefits() calls this sets our data
    if (response.result) {
      this.has_funcobj = response.result.has_funcobj;
      this.has_pain_point = response.result.has_pain_point;
      this.has_capabilities = response.result.has_capabilities;
      this.has_opobj = response.result.has_opobj;
      this.has_sequence = response.result.has_sequence;

      this.benefitsListByType = response.result.benefit_types;
      this.benefitsList = [];
      this.benefitsListByType.forEach((type) => {
        this.benefitsList.push(...type.vcs);
      });

      if (this.benefitsList.length) {
        for (let m = 0; m < this.benefitsList.length; m++) {
          let list = JSON.parse(sessionStorage.getItem('benefitListExpanded'));
          if (list && !this.expandAll) {
            // let ids = list.split(",");
            if (list.indexOf(this.benefitsList[m].value_category_id) >= 0) {
              this.benefitsList[m].expand = true;
            } else {
              this.benefitsList[m].expand = false;
            }
          } else if (this.expandAll) {
            this.benefitsList[m].expand = true;
          }

          // if its create flow, we need to set all active to true
          if (this.createFlow) {
            this.benefitsList[m].active_clean = true;
          }

          this.benefitsList[m].start = 1;
          if (!this.benefitsList[m].metrics) {
            this.benefitsList[m].metrics = [];
          }

          for (let i = 0; i < this.benefitsList[m].metrics.length; i++) {
            let list = JSON.parse(sessionStorage.getItem('metricsExpanded'));
            if (list) {
              if (list.indexOf(this.benefitsList[m].metrics[i].value_prop_metric_id) >= 0) {
                this.benefitsList[m].metrics[i].expanded = true;
              } else {
                this.benefitsList[m].metrics[i].expanded = false;
              }
            }

            this.benefitsList[m].metrics[i].updateBatch = false;
            switch (this.benefitsList[m].metrics[i].active.toString()) {
              case '0':
                this.benefitsList[m].metrics[i].active_type = this.trans.trans.na.value;
                break;
              case '1':
                this.benefitsList[m].metrics[i].active_type = this.trans.trans.active.value;
                break;
              case '2':
                this.benefitsList[m].metrics[i].active_type = this.trans.trans.not_priority.value;
                break;
              case '3':
                this.benefitsList[m].metrics[i].active_type = this.trans.trans.soft.value;
                break;
              case '4':
                this.benefitsList[m].metrics[i].active_type = this.trans.trans.na.value;
                break;
              case '5':
                this.benefitsList[m].metrics[i].active_type = this.trans.trans.na.value;
                break;
              default:
                this.benefitsList[m].metrics[i].active_type = '';
                break;
            }
          }

          let out = [];
          for (let t = 1; t <= this.benefitsList[m].term; t++) {
            out.push({ label: 'Year ' + t, value: this.benefitsList[m]['vc_yr' + t + '_total_fmt'] });
            for (let mm = 0; mm < this.benefitsList[m].metrics.length; mm++) {
              let out2 = [];
              for (let tt = 1; tt <= this.benefitsList[m].term; tt++) {
                out2.push({ label: 'Year ' + tt, value: this.benefitsList[m].metrics[mm]['year' + tt + '_benefits_fmt'] });
              }
              this.benefitsList[m].metrics[mm].years = out2;
            }
          }
          this.benefitsList[m].years = out;
        }
      }
      if (this.firstLoad) {
        this.getTranslations();
        this.firstLoad = false;
        this.benefitsListByType$.next(this.benefitsListByType);
      } else {
        this.refreshVCNames$.next();
        this._benefitsUpdated$.next(benefitId);
      }
    }
    this.sortBenefits();
    this.benefitsLoader = false;
    this.loadingInPlace = false;
  }

  sortBenefits() {
    this.benefitsList = this.benefitsList.sort((a, b) => (a.is_financial > b.is_financial ? -1 : 1));
  }

  funcGetActiveType(active) {
    switch (active) {
      case '0':
        return this.trans.trans.na.value;
      case '1':
        return this.trans.trans.active.value;
      case '2':
        return this.trans.trans.not_priority.value;
      case '3':
        return this.trans.trans.soft.value;
      case '4':
        return this.trans.trans.na.value;
      case '5':
        return this.trans.trans.na.value;
    }
  }

  loadBenefits(benefitId?: string) {
    this.loadBenefits$.next(benefitId);
  }

  showAllBenefits(showAllBenefitsCheck) {
    this.benefitsLoader = true;
    this.showAllBenefitsCheck = !this.showAllBenefitsCheck;
    var self = this;
    this.loadBenefits();
  }

  funcSetBulkAuto(category, status) {
    category.loading = true;
    let payloadArray = [];
    this.benefitsLoader = true;

    let benefitGroup = category['metrics'];
    benefitGroup.forEach((benefit) => {
      let out = {
        value_prop_metric_id: benefit['id'],
        active: status,
      };

      payloadArray.push(out);
    });

    const payload = {
      value_prop_metrics: payloadArray,
    };

    this.valuePropService
      .updateMetricsActive(this.getValueProp(), payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.loadBenefits();
          category.loading = false;
          this.valuePropService.refreshDashboard.next('no_reload');
          this.notificationService.success(this.trans.trans.notification_status.value, false);
          this.benefitsLoader = false;
        } else {
          if (response.result && response.result.message) {
            this.notificationService.error(response.result.message, false);
          }
          this.benefitsLoader = false;
        }
        this.clearBatchUpdate();
      });
  }

  funcBuildSort() {
    this.sort = [];
    this.sort.push(
      {
        label: this.trans.trans.defined_sequence.value,
        icon: this.toggleSeqSort == 0 ? 'fa fa-check' : '',

        command: () => {
          this.showDefinedSequence = !this.showDefinedSequence;
          this.toggleSeqSort = 0;
          this.firstLoad = true;
          this.sortBenefitsBySequence();
          this.loadBenefits();
          this.funcBuildSort();
        },
      },
      {
        label: this.trans.trans.total_benefits_sequence.value,
        icon: this.toggleSeqSort == 1 ? 'fa fa-check' : '',

        command: () => {
          this.showTotalBenefits = !this.showTotalBenefits;
          this.sortBenefitsByTotals();
          this.toggleSeqSort = 1;
          this.firstLoad = true;
          this.loadBenefits();
          this.funcBuildSort();
        },
      }
    );
  }

  funcBuildGroupMenu() {
    this.group = [];
    this.group.push({
      label: this.trans.trans.value_category.value,
      icon: this.group_selected == 'value_category' ? 'fa fa-check' : '',

      command: () => {
        this.group_selected = 'value_category';
        this.firstLoad = true;
        this.loadBenefits();
        this.funcBuildGroupMenu();
      },
    });

    if (this.has_funcobj) {
      this.group.push({
        label: this.trans.trans.functional_objectives.value,
        icon: this.group_selected == 'functional_objectives' ? 'fa fa-check' : '',

        command: () => {
          this.group_selected = 'functional_objectives';
          this.firstLoad = true;
          this.loadBenefits();
          this.funcBuildGroupMenu();
        },
      });
    }
    if (this.has_pain_point) {
      this.group.push({
        label: this.trans.trans.pain_points.value,
        icon: this.group_selected == 'painpoints' ? 'fa fa-check' : '',

        command: () => {
          this.group_selected = 'painpoints';
          this.firstLoad = true;
          this.loadBenefits();
          this.funcBuildGroupMenu();
        },
      });
    }
    if (this.has_capabilities) {
      this.group.push({
        label: this.trans.trans.capabilities.value,
        icon: this.group_selected == 'capabilities' ? 'fa fa-check' : '',

        command: () => {
          this.group_selected = 'capabilities';
          this.firstLoad = true;
          this.loadBenefits();
          this.funcBuildGroupMenu();
        },
      });
    }
    if (this.has_opobj) {
      this.group.push({
        label: this.trans.trans.operational_objectives.value,
        icon: this.group_selected == 'operational_objectives' ? 'fa fa-check' : '',

        command: () => {
          this.group_selected = 'operational_objectives';
          this.firstLoad = true;
          this.loadBenefits();
          this.funcBuildGroupMenu();
        },
      });
    }
  }

  //filter dropdown
  funcSetupFilterMenu() {
    this.filters = [
      {
        label: this.trans.trans.active.value,
        icon: this.showActive ? 'fa fa-check' : '',
        styleClass: 'menu_green',
        active: this.showActive,
        command: () => {
          this.showActive = !this.showActive;
          this.forceGroupRefresh = !this.forceGroupRefresh;
          this.funcSetupFilterMenu();
        },
      },
      {
        label: this.trans.trans.na.value,
        icon: this.showNA ? 'fa fa-check' : '',
        styleClass: 'menu_red',
        active: this.showNA,
        command: () => {
          this.showNA = !this.showNA;
          this.forceGroupRefresh = !this.forceGroupRefresh;
          this.funcSetupFilterMenu();
        },
      },
    ];
    if (!this.feature39) {
      this.filters.push({
        label: this.trans.trans.soft.value,
        icon: this.showSoft ? 'fa fa-check' : '',
        styleClass: 'menu_orange',
        active: this.showSoft,
        command: () => {
          this.showSoft = !this.showSoft;
          this.forceGroupRefresh = !this.forceGroupRefresh;
          this.funcSetupFilterMenu();
        },
      });
    }
  }

  selectAllFilters(selected: boolean) {
    this.showActive = selected;
    this.showNA = selected;
    this.showSoft = selected;
    this.forceGroupRefresh = !this.forceGroupRefresh;
    this.funcSetupFilterMenu();
  }

  getAllSelected() {
    return this.showActive && this.showNA && (this.showSoft || this.feature39);
  }

  filterBenefit(benefit) {
    if (this.showActive) {
      if (benefit.active == '1') {
        return true;
      }
    }
    if (this.showNA) {
      if (benefit.active == '0' || benefit.active == '4' || benefit.active == '5') {
        return true;
      }
    }
    if (this.showSoft && !this.feature39) {
      if (benefit.active == '3') {
        return true;
      }
    }
    if (!this.showActive && !this.showNA) {
      return true;
    } else {
      return false;
    }
  }

  funcShowHideCategoriesPanel(benefitCategory) {
    let display = true;
    this.showActive ? (display = benefitCategory.active_count > 0) : true;
    this.showNA ? (display = benefitCategory.na_count > 0) : true;

    return display;
  }

  changeView(type) {
    // this.benefitsPanel = !this.benefitsPanel;
    if (type == 'table') {
      this.isTable = true;
    } else {
      this.isTable = false;
    }
  }

  openActiveTypes(benefit) {
    if (this.valueProp['vp_can_edit']) {
      this.inputOpened = true;
      this.selectedBenefit = benefit;
      benefit.changeActive = true;
    }
  }

  openActiveTypesPmenu(benefit, menu, event) {
    this.inlinestatus = [
      {
        label: this.trans.trans.active.value,
        name: this.trans.trans.active.value,
        value: '1',
        command: () => {
          this.statusChange(benefit, 1);
        },
      },
      {
        label: this.trans.trans.na.value,
        name: this.trans.trans.na.value,
        value: '0',
        command: () => {
          this.statusChange(benefit, 0);
        },
      },
    ];
    if (!this.feature39) {
      this.inlinestatus.push({
        label: this.trans.trans.soft.value,
        name: this.trans.trans.soft.value,
        value: '3',
        command: () => {
          this.statusChange(benefit, 3);
        },
      });
    }
    menu.toggle(event);
  }

  statusChange(benefit, val?) {
    localStorage.setItem('vpdashboardMenuBottom', 'benefits');
    let active = val !== undefined ? val.toString() : benefit.active;
    const payload = {
      value_prop_metric_id: benefit.id,
      active: active,
    };
    this.benefitsLoader = true;
    this.inputOpened = false;
    this.valuePropService
      .updateMetricActive(this.getValueProp(), payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          benefit.active_type = this.funcGetActiveType(active);
          // benefit.changeActive = false;
          this.valuePropService.refreshDashboard.next('refreshDashboard');
          this.valuePropService.refreshBenefitDetail.next();
          this.notificationService.success(this.trans.trans.notification_status.value, false);
          this.loadBenefits(benefit.id);
        } else if (response.result.success === false) {
          benefit.changeActive = false;
          this.notificationService.error(response.result.message, false); // Params {message, islogout}
        }
        this.benefitsLoader = false;
      });
    // }
  }

  updatePhasing(apply_all) {
    // This function updates our phasing

    if (apply_all > 0) {
      let res = confirm('Are you sure?');
      if (!res) {
        this.modalLoading = false;
        return;
      }
    }
    this.modalLoading = true;

    let MetricsPhasingModel = {
      value_prop_id: this.getValueProp(),
      value_prop_metric_id: this.metricsid,
      apply_all: apply_all,
    };

    for (let i = 0; i < this.metricbenefits.length; i++) {
      let key = this.metricbenefits[i]['key'];
      let value = this.metricbenefits[i]['value'];
      MetricsPhasingModel[key] = value;
    }

    this.valuePropService
      .updateValuePropPhasing(MetricsPhasingModel, this.getValueProp())
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.modalLoading = false;
          // this.modalReference.close();
          this.showPhasing = false;
          this.notificationService.success(this.trans.trans.phasing_status.value, false);
          this.ngOnInit();
        } else if (response.result.success === false) {
          this.modalLoading = false;
          this.showPhasing = false;
          this.notificationService.error(response.result.message, false);
        }
        this.valuePropService.refreshDashboard.next('no_reload');
      });
  }

  deleteCustomBenefit(benefit, vpId?) {
    const id = vpId ? vpId : this.getValueProp();
    this.valuePropService
      .deleteCustomBenefit(id, benefit.value_prop_metric_id)
      .pipe(take(1))
      .subscribe(
        (response) => {
          if (response.result && response.result.success !== false) {
            // this.ValuepropService.refreshDashboard.next('benefitChange');
            this.notificationService.success(this.trans.trans.success_delete.value, false);
            if (this.view === 'repv1') {
              this.loadBenefits();
              this.valuePropService.refreshDashboard.next('no_reload');
              this.valuePropService.refreshBenefitDetail.next();
            }
          } else if (response.result.success === false) {
            this.notificationService.error(response.result.message, false); // Params {message, islogout}
          } else if (response.result.errors) {
            this.notificationService.error(this.trans.trans.something_went_wrong.value + response.result.errors.reason, false);
          }
        },
        (error) => {
          this.notificationService.error(this.trans.trans.something_went_wrong.value + error.result, false);
        }
      );
  }

  showFeedback(content, benefitId) {
    // used in benefits grid view
    this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    this.valuePropService
      .readFeedback(benefitId)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.feedbackList = response.result;
        }
      });
  }

  editBenefit(selectedBenefit) {
    // used in benefits grid view
    const modalReference = this.modalService.open(AddBenefitsModelComponent, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    modalReference.componentInstance.selectedBenefit = selectedBenefit;
    modalReference.componentInstance.valuePropId = this.getValueProp();
    modalReference.result.then(
      () => {},
      () => {}
    );
  }

  openSetActive() {
    // shows our checkbox bulk edit
    this.showBatchUpdateCheckboxes = true;
    if (!this.showAllBenefitsCheck) {
      this.showAllBenefits(this.showAllBenefitsCheck);
    }
  }

  clearBatchUpdate(benefitsList?: BenefitType[]) {
    this.showBatchUpdateCheckboxes = false;
    const localBenefitsList = benefitsList ? benefitsList : this.benefitsListByType;
    localBenefitsList.forEach((benefitList) => {
      benefitList.vcs.forEach((vcs) => {
        vcs.allOn = false;
        let benefitGroup = vcs.metrics;
        benefitGroup.forEach((benefit) => {
          if (benefit.updateBatch) {
            benefit.updateBatch = false;
          }
        });
      });
    });
  }

  statusBatchUpdate(benefitsList?: BenefitType[]) {
    let payloadArray = [];
    this.benefitsLoader = true;
    const localBenefitsList = benefitsList ? benefitsList : this.benefitsListByType;

    localBenefitsList.forEach((benefitList) => {
      benefitList.vcs.forEach((vcs) => {
        vcs.allOn = false;
        let benefitGroup = vcs.metrics;
        benefitGroup.forEach((benefit) => {
          if (benefit.updateBatch) {
            let out = {
              value_prop_metric_id: benefit['id'],
              active: this.batchActive,
            };
            benefit.updateBatch = false;
            payloadArray.push(out);
          }
        });
      });
    });

    const payload = {
      value_prop_metrics: payloadArray,
    };

    this.valuePropService
      .updateMetricsActive(this.getValueProp(), payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.loadBenefits();

          this.notificationService.success(this.trans.trans.notification_status.value, false);
          this.valuePropService.refreshDashboard.next();
          this.benefitsLoader = false;
        } else {
          if (response.result && response.result.message) {
            this.notificationService.error(response.result.message, false);
          }
          this.benefitsLoader = false;
        }
        this.clearBatchUpdate();
      });
  }

  filterActive(arr: any[], id) {
    if (!arr) {
      return 0;
    }
    let tot = 0;
    // for (let i = 0; i < arr.length; i++) {
    for (let m = 0; m < arr['metrics'].length; m++) {
      if (arr['metrics'][m].active == id) {
        tot++;
      }
    }
    // }
    return tot;
  }

  rangeRightArrow(start, term) {
    if (!start) {
      return 1;
    } else {
      return start + 1;
    }
  }

  rangeLeftArrow(start, term) {
    if (!start) {
      return 1;
    } else {
      return start - 1;
    }
  }

  alignYear(i) {
    if (this.termShow == 1) {
      return 'one';
    }
    switch (i) {
      case 0:
        return 'zero';
      case 1:
        return 'one';
      case 2:
        return 'two';
    }
  }

  alignYearTbl(i) {
    if (this.termShow == 1) {
      return 'onetbl';
    }
    switch (i) {
      case 0:
        return 'zerotbl';
      case 1:
        return 'onetbl';
      case 2:
        return 'twotbl';
    }
  }

  checkAll(benefit) {
    if (!benefit.allOn) {
      benefit.allOn = true;
    } else {
      benefit.allOn = false;
    }
    for (let i = 0; i < benefit.metrics.length; i++) {
      benefit.metrics[i].updateBatch = benefit.allOn;
    }
  }

  funcAdjustVisibleYears() {
    // this function determines how many years we should show
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < 1200) {
      this.termLength = 1;
      this.termLimit = 0;
      this.termShow = 1;
    } else {
      this.termLength = 3;
      this.termLimit = 2;
      this.termShow = 3;
    }
  }

  showGroup(metric: BenefitVCSMetric[]): boolean {
    let found = false;
    if (this.showActive) {
      let res = metric.filter((x) => x.active == '1');
      if (res.length) {
        found = true;
      }
    }

    if (this.showNA) {
      let res = metric.filter((x) => x.active == '0' || x.active == '4' || x.active == '5');
      if (res.length) {
        found = true;
      }
    }

    if (this.showSoft) {
      let res = metric.filter((x) => x.active == '3');
      if (res.length) {
        found = true;
      }
    }
    return found;
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.funcAdjustVisibleYears();
  }

  expandCat(cat) {
    cat.expand = !cat.expand;
    this.setExpandCat();
  }

  expandBenefit(benefit) {
    benefit.expanded = !benefit.expanded;
    this.setExpandBenefit();
  }

  setExpandCat() {
    let out = [];
    for (let i = 0; i < this.benefitsList.length; i++) {
      let cat = this.benefitsList[i];
      if (cat.expand) {
        out.push(cat.value_category_id);
      }
    }

    sessionStorage.setItem('benefitListExpanded', JSON.stringify(out));
  }

  setExpandBenefit() {
    let out = [];
    for (let i = 0; i < this.benefitsList.length; i++) {
      let cat = this.benefitsList[i];
      for (let m = 0; m < cat.metrics.length; m++) {
        let metric = cat.metrics[m];
        if (metric.expanded) {
          out.push(metric.value_prop_metric_id);
        }
      }
    }

    sessionStorage.setItem('metricsExpanded', JSON.stringify(out));
  }

  getValueProp() {
    let id = this.valueProp ? this.valueProp.id : this.sI2.valueProp.id;
    if (!this.valueProp && this.sI2.valueProp) {
      this.valueProp = this.sI2.valueProp;
    } else if (this.sI2.ValuePropId) {
      this.valuePropId = this.sI2.ValuePropId;
      id = this.valuePropId;
    }
    return id;
  }

  clearServiceData(): void {
    this.valueProp = null;
  }
}
