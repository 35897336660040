import { Injectable } from '@angular/core';

@Injectable()
export class BenefitsMiniTranslations {
  public config: any = {
    component: 'benefitsMini',
    display_name: 'Mini Benefits component',
    description: 'Mini benefits component found in value prop dashboard scalers tab',
  };

  public trans: any = {
    included_benefits: 'Included Benefits',
    metric_name: 'Benefit Name',
    impact_type: 'Impact Type',
    impact: 'Improvement',
    year1_benefits_fmt: 'Year 1',
    total_benefits_fmt: 'Total',
    addedManually: 'Added Manually',
    addedManually2: 'This Benefit was added to this ValueProp manually.',
    update: 'Update',
    areYouSure: 'Are You Sure?',
    delete: 'Delete',
    benefit_type: 'Benefit Type',
    quantification_type: 'Benefit Unit',
    add_benefit: 'Add Benefit',
    close_add_benefit: 'Close Add Benefit',
    benefitDetails: 'Benefit Details',
    save: 'Save',
    closeConfirm: 'Are you sure you want to close without saving first?',
    ch_renameValueCategory: 'Rename the value category',
  };
}
