import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoRecordsComponent } from './no-records.component';


@NgModule({
  declarations: [NoRecordsComponent],
  imports: [CommonModule],
  exports: [NoRecordsComponent],
})
export class NoRecordsModule {}
