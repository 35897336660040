export enum AssetTagArray {
  Capabilities = 'cap_indexed_by_id',
  FunctionalObjectives = 'fo_indexed_by_id',
  BenefitGroups = 'vcs_indexed',
  Benefits = 'metrics_indexed_by_id',
  OperationalObjectives = 'oo_indexed_by_id',
  Factors = 'tmp_factor_indexed_by_id',
  PainPoints = 'pp_indexed_by_id',
  BenefitTypes = 'benefit_types_indexed',
  Costs = 'costs_indexed',
}
