import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ValueMapOpObjectivesComponent } from './op_objectives.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';


export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		TableModule,
		SidebarModule,
		MenuModule,
		NgbModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		ApplicationPipesModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [

		ValueMapOpObjectivesComponent
	],
	exports: [ValueMapOpObjectivesComponent],
	providers: [OperationalObjectivesService]
})
export class ValueMapOpObjectivesModule { }
