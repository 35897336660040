import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotifyMarketingTranslation } from './notify-marketing.translation';

@Component({
  selector: 'app-notify-marketing',
  templateUrl: './notify-marketing.component.html',
})
export class NotifyMarketingComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() benefit: any;

  @Output() callback = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate = false;
  users: any[];
  user: any;
  realizationName: string;

  constructor(
    public trans: NotifyMarketingTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private userService: UserService,
    private valueRealizationService: ValuerealizationService
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getUsers();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getUsers() {
    let query = '?role_type_id=9';

    this.userService
      .getUsersList(query)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.users = response.result.data;
      });
  }

  notifyMarketing() {
    const uid = sessionStorage.getItem('uid');
    let payload = {
      current_user_id: uid,
      marketing_user_id: this.user.id,
      value_prop_id: this.valuePropId,
      account_solution_metric_id: this.benefit.id,
    };
    this.valueRealizationService
      .notifyMarketingTracker(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.callback.emit();
      });
  }
}
