import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkFlowTranslation } from './choose-adventure-internal.translation';

@Component({
  selector: 'app-choose-adventure-internal',
  templateUrl: './choose-adventure-internal.component.html',
  styleUrls: ['./choose-adventure-internal.component.scss'],
})
export class ChooseAdventureInternalComponent implements OnInit, OnDestroy {
  @Input() ff134 = false;
  @Input() showStepSelection = false;
  @Input() navToRoute = '';
  @Input() workflows: ConversationWorkflow[] = [];

  @Output() onHidden = new EventEmitter<boolean>();

  public addFromDash = false;
  public existingVp = false;
  public navigationRoute = '';
  public newWindow = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public trans: WorkFlowTranslation,
    private commonService: CommonService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationsV2Service,
    private configurableWorkflowService: ConfigurableWorkflowService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  public onWorkflowSelected(wf: ConversationWorkflow, navToRoute: string) {
    this.configurableWorkflowService.selectedConversationWorkflow$.next(wf);
    if (this.existingVp) {
      this.newWindow ? this.openVpNewTab() : this.router.navigate([this.navigationRoute]);
    } else {
      this.addFromDash ? this.funcGetAddRouteFromDash(navToRoute) : this.funcGetAddRoute(navToRoute);
    }
    this.showStepSelection = false;
  }

  public onProcessSelected(process: 'express' | 'standard', navToRoute: string) {
    if (process === 'standard') {
      if (this.commonService.checkFeature('134')) {
        this.userService.replaceFF134$.next(true);
      }
      const features = sessionStorage.getItem('features').split(',');
      const newFeatures = features.filter((f) => f !== '134');
      if (newFeatures.length) {
        const featuresString = newFeatures.join(',');
        sessionStorage.setItem('features', featuresString);
      }
    } else if (process === 'express' && !this.ff134) {
      // add feature 134
      const features = sessionStorage.getItem('features').split(',');
      features.push('134');
      sessionStorage.setItem('features', features.join(','));
    }
    if (!this.existingVp) {
      if (this.addFromDash) {
        this.funcGetAddRouteFromDash(navToRoute);
      } else {
        this.funcGetAddRoute(navToRoute);
      }
    } else {
      this.newWindow ? this.openVpNewTab() : this.router.navigate([this.navigationRoute]);
    }
    this.showStepSelection = false;
  }

  funcGetAddRoute(incoming): void {
    this.router.navigate([this.commonService.getCreateVPRoute(incoming)], { relativeTo: this.route });
  }

  openVpNewTab() {
    const url = this.router.serializeUrl(this.router.createUrlTree([this.navigationRoute]));
    window.open(url, '_blank');
  }

  funcGetAddRouteFromDash(incoming): void {
    const feature71 = this.commonService.checkFeature('71');
    if (feature71) {
    } else {
      const route = this.commonService.getCreateVPRoute(incoming);
      const navRoute = route.includes('rep') ? [route, { type: incoming }] : [route];
      this.router.navigate(navRoute);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.next();
  }
}
