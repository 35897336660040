<div class="" *ngIf="!addShare">
  <div class="table-responsive soln-table">
    <div class="searchTableContainer">
      <div class="">
        <div class="clearfix">
          <button class="btn btn-primary pull-right" (click)="openAddShare()"><i class="fa fa-plus"></i>{{ trans.vp_dashboard.share.add | translate }}</button>
        </div>
        <table id="shares" class="table custom-table clearfix">
          <thead>
            <tr>
              <th>{{ trans.vp_dashboard.share.solution | translate }}</th>
              <th>{{ trans.vp_dashboard.share.user_name | translate }}</th>
              <th>{{ trans.vp_dashboard.share.share_role_type | translate }}</th>
              <th>{{ trans.vp_dashboard.share.actions | translate }}</th>
            </tr>
          </thead>

          <tbody *ngIf="shares && shares !== null && shares.length > 0">
            <tr *ngFor="let share of shares" class="grid-row">
              <td>{{ share.solution_name }}</td>
              <td>{{ share.user_name }}</td>
              <td>{{ share.share_role }}</td>
              <td>
                <i role="button" class="icon-pencil" (click)="editPrivilege(share)"></i>&nbsp;&nbsp;<i
                  role="button"
                  class="icon-trash"
                  (click)="open(deleteModelPrivilegeModal, share.id)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="sharesLoader" class="text-center">
    <img class="loader" src="{{ fullImagePath }}" />
  </div>
  <div *ngIf="(shares == null || shares.length == 0) && !sharesLoader" class="m-t-md">
    <div class="searchTableContainer text-center">
      <h4>{{ trans.vp_dashboard.share.no_shares_found | translate }}</h4>
    </div>
  </div>
</div>
<div class="" id="addShares" *ngIf="addShare">
  <form name="shareModelForm" #shareModelForm="ngForm">
    <div class="row clearfix">
      <div class="form-group col-md-8" *ngIf="UserShare" [ngClass]="{ 'has-error': shareModelForm.controls.share?.invalid && shareModelForm.controls.share?.touched }">
        <label class="labelsRequired">{{ trans.vp_dashboard.share.user_name | translate }}</label>
        <select [(ngModel)]="share.share_to_user_id" class="form-control role-dropdown" name="share" required>
          <option selected value="">{{ trans.vp_dashboard.share.choose_one | translate }}</option>
          <option *ngFor="let user of users" [value]="user.id">
            {{ user.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-8" [ngClass]="{ 'has-error': shareModelForm.controls.shareRoleType?.invalid && shareModelForm.controls.shareRoleType?.touched }">
        <label class="labelsRequired">{{ trans.vp_dashboard.share.share_role_type | translate }}</label>
        <select [(ngModel)]="share.share_role_type_id" class="form-control role-dropdown" name="shareRoleType" required>
          <option selected value="">{{ trans.vp_dashboard.share.choose_one | translate }}</option>
          <option *ngFor="let shareRole of shareRoles" [value]="shareRole.id">
            {{ shareRole.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="clearfix">
      <button class="btn btn-primary pull-right done-btn" (click)="closeAddShare()">
        {{ trans.General.back | translate }}
      </button>
      <button class="btn btn-primary pull-right done-btn" (click)="saveShare()" [disabled]="shareModelForm.invalid">
        {{ trans.General.save | translate }}
      </button>
      <img class="actionLoader pull-right" *ngIf="hideShareActionLoader" src="{{ fullImagePath }}" />
    </div>
  </form>
</div>
<ng-template #deleteModelPrivilegeModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.vp_dashboard.share.delete_model_priviledge | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ trans.vp_dashboard.share.are_you_sure_that_you_want_to_remove_this_priviledge | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="deletePrivilege()">{{ trans.General.Yes | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ trans.General.No | translate }}</button>
    <img class="actionLoader" *ngIf="hideDeleteActionLoader" src="{{ fullImagePath }}" />
  </div>
</ng-template>
