<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ trans.trans.addGoal.value }}
    <app-editTranslation
      *ngIf="showTranslate"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.addGoal"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>

  <p-progressBar *ngIf="loadingGoals; else formContainer" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

  <ng-template #formContainer>
    <div class="sidebar_container">
      <form *ngIf="accountGoals.length || !hideCreateNew; else noOptions" [formGroup]="goalForm">
        <app-error-input-wrapper [errorMessage]="!showNewGoal ? errorDict.get('factorId') : ''" [showOnTouched]="true">
          <label class="labelsRequired" *ngIf="accountGoals.length">
            {{ trans.trans.goalInput.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.goalInput"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </label>

          <p-dropdown
            appendTo="body"
            [placeholder]=""
            panelStyleClass="dropdown-item-font dropdown-items-fixed-width dropdown-items-w500"
            [options]="accountGoals"
            [disabled]="showNewGoal"
            formControlName="factorId"
            optionLabel="name"
            optionValue="id"
            [filter]="true"
            filterBy="name"
            class="keywest_dropdown"
            [autoDisplayFirst]="false"
            *ngIf="accountGoals.length"
          ></p-dropdown>
        </app-error-input-wrapper>

        <div class="new-goal" *ngIf="!hideCreateNew" [@openClose]="showNewGoal ? 'expanded' : 'collapsed'" [class.goal-disabled]="goalData">
          <div class="new-goal-title" (click)="toggleGoalForm()">
            <div></div>
            <div>{{ trans.trans.createNewGoal.value }}</div>

            <i class="pi pi-plus"></i>
          </div>

          <div>
            <app-ae-goals-form *ngIf="showNewGoal" (close)="cancel()" (refresh)="refreshGoals()" [goalGroups]="goalGroups" [vpId]="vpId"></app-ae-goals-form>
          </div>
        </div>

        <ng-container *ngIf="goalData">
          <div class="goal-title">{{ trans.trans.name.value }}</div>
          <div class="content-box">{{ goalData.name }}</div>
          <ng-container *ngIf="goalData.scratchpad_str && goalData.scratchpad_str !== ''">
            <div class="goal-title">{{ trans.trans.formula.value }}</div>
            <div class="content-box">
              {{ goalData.scratchpad_str }}
            </div>
          </ng-container>
          <ng-container *ngIf="goalData.description !== ''">
            <div class="goal-title">{{ trans.trans.description.value }}</div>
            <div class="description-text">
              {{ goalData.description }}
            </div>
          </ng-container>
          <ng-container *ngIf="goalData.connected_factors_seprated_by_commas !== ''">
            <div class="goal-title">{{ trans.trans.linkedFactors.value }}</div>
            <div class="content-box">
              {{ goalData.connected_factors_seprated_by_commas }}
            </div>
          </ng-container>
          <div class="goal-title">{{ trans.trans.unitType.value }}</div>
          <div class="content-box">
            <app-unit-type [unitType]="goalData.unit_type_id"></app-unit-type>
          </div>
        </ng-container>
      </form>
    </div>

    <div class="btn_container" *ngIf="!showNewGoal">
      <div class="row">
        <div class="col-xs-12">
          <button pButton class="btn btn_customizable pull-right save-button-margin" (click)="saveGoal()" [disabled]="goalForm.invalid" [loading]="loadingSave">
            {{ trans?.trans?.saveButton?.value }}
          </button>
          <button class="btn btn_cancel pull-right" style="margin-right: 10px" (click)="cancel()">{{ trans.trans.cancel.value }}</button>
        </div>
      </div>
    </div>

    <ng-template #noOptions>
      {{ trans.trans.noOptionGoals.value }}
    </ng-template>
  </ng-template>
</div>
