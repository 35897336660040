import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';


@Injectable()
export class MasterValuePropService {

	constructor(private CommonService: CommonService) { }


	public createMasterValueProp(payload) {
		let endpoint = "masterValueProps/create";
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public postCloneVPGroup(id, payload) {
		let endpoint = "valueProps/cloneValuePropGroup/" + id;
		return this.CommonService.postAPIService(endpoint, payload);

	}

	public cloneMasterValueProp(id, payload) {
		let endpoint = "masterValueProps/cloneMasterValueProp/" + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public readMasterValueProp(id) {
		let endpoint = "masterValueProps/masterValueProp/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public readValuePropGroup(group_id) {
		let endpoint = "valueProps/valuePropGroup/" + group_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public readValuePropGroupCompare(group_id, payload) {
		let endpoint = "valueProps/valuePropGroupCompare/" + group_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public readMasterValueProps(id) {
		let endpoint = "masterValueProps/masterValueProps/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public saveSolutions(id, payload) {
		let endpoint = "masterValueProps/solutions/" + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public readAvailableProjects(id) {
		let endpoint = "masterValueProps/availableProjects/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public createProjects(id, payload) {
		let endpoint = "masterValueProps/projects/" + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public readAvailableSituations(id) {
		let endpoint = "masterValueProps/availableSituations/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public createSituations(id, payload) {
		let endpoint = "masterValueProps/situations/" + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public readSituationsDiscovery(id) {
		let endpoint = "masterValueProps/readSituations/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public createSituationsDiscovery(id, payload) {
		let endpoint = "masterValueProps/situation/create/" + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public readProjects(id) {
		let endpoint = "masterValueProps/readProjects/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public readAvailableScalers(id) {
		let endpoint = "masterValueProps/availableScalers/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public modifyMasterScaler(id, payload) {
		let endpoint = "valueProps/masterScaler/create/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public createScalers(id, payload) {
		let endpoint = "masterValueProps/scalers/" + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public readAggregate(id, payload) {
		let endpoint = "masterValueProps/aggregate/" + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public deleteProject(master, project_id) {
		const endpoint = 'masterValueProps/project/' + master + '/projectID/' + project_id;
		return this.CommonService.deleteAPIService(endpoint);
	}

	public readRecent() {
		let endpoint = 'masterValueProps/readRecentMasterValueProps';
		return this.CommonService.getAPIService(endpoint);
	}

	public updateMasterValuePropName(id, payload) {
		let endpoint = "masterValueProps/name/update/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public updateVPGroupName(id, payload) {
		let endpoint = "valueProps/valuePropGroup/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public readAndSearchMasterValueProps(payload) {
		let endpoint = "masterValueProps/readAllMasterValueProps";
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public deleteMasterValueProp(id) {
		const endpoint = "masterValueProps/delete/" + id;
		return this.CommonService.deleteAPIService(endpoint);
	}

	public getVPGroupShares(value_prop_group_id) {
		let endpoint = "valueProps/groupShares/" + value_prop_group_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public readMasterValuePropShares(id) {
		let endpoint = "masterValueProps/shares/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public shareMasterValueProp(id, payload) {
		const endpoint = 'masterValueProps/share/' + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public shareVPGroup(id, payload){
		const endpoint = 'valueProps/groupShare/' + id;
		return this.CommonService.postAPIService(endpoint, payload);

	}

	
	public deleteVPGroupShare(id, shareId) {
		const endpoint = 'valueProps/deleteGroupShare/' + id + '/shareId/' + shareId;
		return this.CommonService.deleteAPIService(endpoint);
	}

	public deleteMasterValuePropShare(id, shareId) {
		const endpoint = 'masterValueProps/deleteShare/' + id + '/shareId/' + shareId;
		return this.CommonService.deleteAPIService(endpoint);
	}
	
	public updateVPGroupShare(id, payload) {
		let endpoint = "valueProps/updateGroupShare/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public updateMasterValuePropShare(id, payload) {
		let endpoint = "masterValueProps/updateShare/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public addNewCustomerVPGroup(id, payload) {
		const endpoint = 'valueProps/customerGroupShare/' + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public addNewCustomer(id, payload) {
		const endpoint = 'masterValueProps/customerShare/' + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public compareBulkUploadDriver(id, payload) {
		let endpoint = "masterValueProps/bulkUpdate/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public compareBulkUploadDriverGroup(id, payload) {
		let endpoint = "valueProps/groupBulkUpdate/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public compareBulkUploadScratchPad(id, payload) {
		let endpoint = "masterValueProps/scratchpadUnitsInBulk/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public compareBulkUploadScratchPadGroup(id, payload) {
		let endpoint = "valueProps/groupScratchpadUnitsInBulk/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}


	public updateDriverFactorMVP(id, type, payload) {
		let endpoint = "masterValueProps/drivers/" + id + "/valuePropId/" + type;
		return this.CommonService.putAPIService(endpoint, payload);
	}
	public updateDriverFactorGroup(id, type, payload) {
		let endpoint = "valueProps/groupDrivers/" + id + "/valuePropId/" + type;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public updateFinancialFactorMVP(id, type, payload) {
		let endpoint = "masterValueProps/financials/" + id + "/valuePropId/" + type;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public updateFinancialFactorGroup(id, type, payload) {
		let endpoint = "valueProps/groupFinancials/" + id + "/valuePropId/" + type;
		return this.CommonService.putAPIService(endpoint, payload);
	}


	public updateImprovementFactorMVP(id, payload) {
		let endpoint = "valueProps/updateImprovement/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public updateImprovementFactorGroup(id, payload) {
		let endpoint = "valueProps/updateImprovement/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public updateScratchPadUnitMVP(id, type, payload) {
		let endpoint = "masterValueProps/scratchpadUnit/update/" + id + "/valuePropId/" + type;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public updateScratchPadUnitMVPv2(id, type, payload) {
		let endpoint = "valueProps/groupScratchpadUnits/" + id + "/valuePropId/" + type;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public cloneValueProp(id, payload) {
		const endpoint = 'masterValueProps/cloneValueProp/' + id;
		return this.CommonService.postAPIService(endpoint, payload);
	}
}
