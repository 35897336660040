import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DashboardImplementation } from '../../dashboard.implementation';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuePositionDashboardTranslations } from './valueposition.translation';
import { Observable, Subject } from 'rxjs';
import { ListService } from '@data/services/list/list.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StyleService } from 'app/style.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { ChooseAdventureInternalComponent } from '@shared/choose-adventure-internal/choose-adventure-internal.component';
import { UserService } from '@data/services/user/user.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';

@Component({
  selector: 'app-dashboard-valueposition',
  templateUrl: './valueposition.component.html',
})
export class DashboardValuePositionComponent implements OnInit, OnDestroy {
  reachList= [];
  selectedProp: ValueProp;

  ngUnsubscribe = new Subject();
  showTranslate = false;
  loading = false;
  style2022$: Observable<boolean>;
  columns:Array<{field: string; header: string, sortField: string}> = [];
  public showStepSelection = false;
  public navigationRoute = '';
  public existingVp = false;
  public ff134 = false;
  private priv15 = false;
  private priv16 = false;
  public workflows: ConversationWorkflow[] = [];
  @ViewChild('chooseAdventure') chooseAdventureDialog: ChooseAdventureInternalComponent;

  constructor(
    public dbI: DashboardImplementation,
    public commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: ValuePositionDashboardTranslations,
    private listService: ListService,
    private valuepropService: ValuepropService,
    private router: Router,
    private styleService: StyleService,
    private userService: UserService,
    private route: ActivatedRoute,
    private configurableWorkflowService: ConfigurableWorkflowService
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.ff134 = this.commonService.checkFeature('134');
    this.priv15 = this.commonService.checkPrivilege('15');
    this.priv16 = this.commonService.checkPrivilege('16');
    if(sessionStorage.getItem('workflows') && sessionStorage.getItem('workflows') !== 'undefined') {
      this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
    }
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  funcGetAddRoute(incoming): void {
    this.router.navigate([this.commonService.getCreateVPRoute(incoming)], { relativeTo: this.route });
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
         this.columns = [
          {
            field: 'valuepos_name',
            header: 'valuepos',
            sortField: ''
          },
          {
            field: 'valuepos_totalbenefits',
            header: 'Total Benefits',
            sortField: ''
          }
         ];
      });
  }

  public chooseStep(route: string, id: string) {
    this.chooseAdventureDialog.navigationRoute = this.commonService.getLinkVPRoute(route, id, 4);
    this.chooseAdventureDialog.existingVp = true;
    this.existingVp = true;
    if(this.workflows.length <= 1) {
      if(this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      }
      else if(this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0])
      }
      this.router.navigate([this.commonService.getLinkVPRoute(route, id, 4)]);
      this.userService.replaceFF134$.next(false);
    }
    else {
      this.showStepSelection = true;
    }
  }

  public onAddHypoClicked(event: string) {
    this.existingVp = false;
    this.chooseAdventureDialog.existingVp = false;
    this.chooseAdventureDialog.addFromDash = true;
    if(this.workflows.length <= 1) {
      if(this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      }
      else if(this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0])
      }
      this.dbI.funcGetAddRoute(event);
      this.userService.replaceFF134$.next(false);
    }
    else {
      this.showStepSelection = true;
    }
  }

  public onHidden(hidden: boolean) {
    this.showStepSelection = hidden;
  }

  getData() {
    this.loading = true;
    const payload = {
      search: 'all',
      page: 1,
      limit: 5,
      sortby: 'modified',
      order: 'desc',
      skip_solutions: 1,
    };

    this.listService.getHypotheses(payload).subscribe((response) => {
      if (response.result) {
        if (response && response.result && response.result.data && response.result.data.length) {
          const resultItem = response.result.data.slice(0, 5);
          this.reachList = resultItem;
        }
      }
      this.loading = false;
    });
  }

  getSubValuePositionDetails(valueProp) {
    this.selectedProp = valueProp;
  }

  getValuePositionDetails(valuePropId, index) {
    if (!this.reachList[index]['solutionItems']) {
      this.valuepropService.getValuePropDetail(valuePropId).subscribe((response) => {
        if (response.result) {
          const valuePropsDetails = response.result.value_prop;
          let solutions = valuePropsDetails.solutions;
          let solutionStr;
          if (solutions) {
            let solutionStrArr = solutions.map((item) => {
              return item['name'];
            });
            solutionStr = solutionStrArr.join(',');
          }

          this.reachList[index]['solutionItems'] = solutionStr;
          this.reachList[index]['roi_decorated'] = valuePropsDetails.roi_decorated;
          this.reachList[index]['payback'] = valuePropsDetails.payback;
        }
      });
    }
  }
}
