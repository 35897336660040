
<section *ngIf="!isPreview; else preview">
  <ng-container *ngIf="questions?.length > 0; else noQuestions">
    <div class="question" *ngFor="let question of questions; index as i">

      <p>{{question.name}}</p>
      <br>

      <ng-container *ngIf="question?.members?.length > 0; else noMembers">
        <ng-container [ngSwitch]="question.element_type_id">
          <div class="member-field" *ngSwitchCase="elementTypes.singleSelect">
            <div class="input-radio single-select" *ngFor="let member of question.members">
              <p-radioButton [name]="i + 'radiogroup'" [value]="member.id" [(ngModel)]="question.singleSelectAnswer"></p-radioButton>
              <span>{{member.name}}</span>
            </div>
          </div>

          <div class="member-field" *ngSwitchCase="elementTypes.multiSelect">
            <div class="multi-select" *ngFor="let member of question.members">
              <p-checkbox [value]="member.id" [(ngModel)]="question.multiSelectAnswer"></p-checkbox>
              <span>{{member.name}}</span>
            </div>
          </div>

          <div class="member-field" class="dropdown-single" *ngSwitchCase="elementTypes.dropdownSingle">
            <p-dropdown appendTo="body"
              [options]="question.members"
              [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
              class='keywest_dropdown dropdown-theme'
              [placeholder]="trans.trans.selectOne.value"
              [(ngModel)]="question.dropdownSingleAnswer"
							[autoDisplayFirst]="false"
              optionLabel="name"
              dataKey="id"
            >
            </p-dropdown>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noMembers>
        <p>{{trans.trans.noMembersFound.value}}</p>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #noQuestions>
    <p>{{trans.trans.noQuestionsFound.value}}</p>
  </ng-template>
</section>

<ng-template #preview>
  <ng-container *ngIf="questions?.length > 0;">
    <div class="question" *ngFor="let question of questions; index as i">

      <p><b>Q{{i + 1}}</b> - {{question.name}}</p>
      <br>

      <ng-container *ngIf="question?.members?.length > 0">
        <ng-container [ngSwitch]="question.element_type_id">
          <div class="member-field" *ngSwitchCase="elementTypes.singleSelect">
            <div class="input-radio single-select" *ngFor="let member of question.members">
              <p-radioButton [value]="member" [(ngModel)]="noSelected" [disabled]="true"></p-radioButton>
              <span>{{member.name}}</span>
            </div>
          </div>

          <div class="member-field" *ngSwitchCase="elementTypes.multiSelect">
            <div class="multi-select" *ngFor="let member of question.members">
              <p-checkbox [disabled]="true"></p-checkbox>
              <span>{{member.name}}</span>
            </div>
          </div>

          <div class="member-field" class="dropdown-single" *ngSwitchCase="elementTypes.dropdownSingle">
            <p-dropdown appendTo="body"
              [options]="question.members | populateDropdownDisabled"
              [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
              class='keywest_dropdown dropdown-theme'
              [placeholder]="trans.trans.selectOne.value"
              [(ngModel)]="noSelected"
							[autoDisplayFirst]="false"
            >
              <ng-template let-item pTemplate="item">
                  <span>{{item.label}}</span>
              </ng-template>
            </p-dropdown>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
