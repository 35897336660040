import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuePropSituation, SituationElement, SituationPayload, SituationResponsesTop } from '@data/services/valueprop/models/situation.model';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SituationsSharedTranslations } from './situations-shared.translation';
import { Model } from '@data/services/account/models/model.interface';
import { ValueProp } from '@shared/models/value-prop.model';
import { SituationInputType } from '@shared/models/situation-input-type.enum';

export interface QuestionResponse {
  answer: string;
  open: boolean;
  openEditing: boolean;
  editing: boolean;
  editedAnswer: string;
  name: string;
  question_type_id: string;
  required: string;
  description: string;
}

@Component({
  selector: 'app-situations-shared',
  templateUrl: './situations-shared.component.html',
  styleUrls: ['./situations-shared.component.scss'],
  animations: [
    trigger('openClose', [
      state('collapsed, void', style({ height: '0px', visibility: 'hidden', opacity: '0' })),
      state('expanded', style({ height: '*', visibility: 'visible', opacity: '1' })),
      transition('expanded <=> collapsed, void => collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SituationsSharedComponent implements OnInit, OnDestroy {
  _modelsValue: Model[];
  _valuePropValue: ValueProp;

  @Input()
  set models(val: Model[]) {
    this._modelsValue = val;
  }
  get models() {
    return this._modelsValue;
  }
  @Input()
  set valueProp(val: ValueProp) {
    this._valuePropValue = val;
    this.locked = +this.valueProp.closed === 1 || this.valueProp.vp_can_edit === false;
  }
  get valueProp() {
    return this._valuePropValue;
  }

  @Input() hideSave = false;
  @Input() canEdit = false;
  @Input() discovery: boolean;
  @Input() isExternalShare: boolean;
  @Input() showTranslate = false;
  @Input() type = '';
  @Input() showEditSituations = false;
  @Output() callback = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  @Output() getScalers = new EventEmitter();
  @Output() getProjects = new EventEmitter();
  ngUnsubscribe = new Subject();
  SituationInputType = SituationInputType;
  loading = false;
  availableSituations: ValuePropSituation[] = [];
  saveLoading = false;
  crm: string;
  situationResponses: Map<string, SituationResponsesTop> = new Map();
  originalSituationResponses: Map<string, SituationResponsesTop>;
  questions: Map<string, QuestionResponse> = new Map();
  locked = false;
  situationsSaved = false;
  showSituationEditing = false;
  feature88 = false;
  feature67 = false;
  isQuickCreate = false;

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: SituationsSharedTranslations,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private RS: RepSimpleViewService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.feature88 = this.commonService.checkFeature(88);
    this.feature67 = this.commonService.checkFeature(67);
    this.crm = sessionStorage.getItem('crm');
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
      this.getTranslations();
    });

    this.readAvailableSituations();
    this.RS.refreshSimpleDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.readAvailableSituations();
      this.getDiscoveryQuestions();
    });

    this.situationsSaved = +this.valueProp.situation_done === 1 || !this.feature67;

    this.valuepropService.editSituations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.situationsSaved = false;
    });

    this.valuepropService.reloadSituations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.readAvailableSituations();
      this.getTranslations();
      this.getDiscoveryQuestions();
    });

    this.isQuickCreate = this.type === 'quickcreate';
    if (this.isExternalShare) {
      this.situationsSaved = true;
      this.feature88 = false;
    } else {
      this.getDiscoveryQuestions();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addSituation(situation: SituationElement | ValuePropSituation, ...id: string[]): void {
    if ((situation as ValuePropSituation).element_type_id) {
      this.situationResponses.set(situation.situation_type_id, {
        id: situation.situation_type_id,
        responses: situation.children.filter((el) => el.selected === true),
        element_type_id: (situation as ValuePropSituation).element_type_id + '',
      });
      situation.children.forEach((e) => {
        this.addSituation(e, situation.situation_type_id);
      });
    } else if (situation.children && id.length > 0) {
      const situationToInsertAt = this.recursivelyGetChildren(this.situationResponses, id);
      if (!situationToInsertAt.hasOwnProperty('children')) {
        situationToInsertAt.children = new Map();
      }
      situationToInsertAt.children.set((situation as SituationElement).id, {
        id: (situation as SituationElement).id,
        responses: situation.children.filter((el) => el.selected === true),
      });
      situation.children.forEach((e) => {
        this.addSituation(e, ...id, (situation as SituationElement).id);
      });
    }
  }

  recursivelyGetChildren(situationResponses: Map<string, SituationResponsesTop>, ids: string[]): SituationResponsesTop {
    if (ids.length === 1) {
      return situationResponses.get(ids[0]);
    } else {
      return this.recursivelyGetChildren(situationResponses.get(ids[0]).children, ids.slice(1));
    }
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.cdr.detectChanges();
      });
  }

  populateDefaultOptions(situationEl: SituationElement, situationTypeId: string) {
    if ((this.situationResponses.get(situationTypeId).responses.length > 0 && this.situationResponses.get(situationTypeId).responses[0].id === situationEl.id) || !this.feature88) {
      return;
    }

    this.situationResponses.get(situationTypeId).children.set(situationEl.id, {
      id: situationEl.id,
      responses: situationEl.children.filter((el) => el.guided_default_active === '1'),
    });
  }

  questionToggle(id: string, q: QuestionResponse, location = 'open') {
    this.questions.set(id, {
      ...q,
      [location]: !q[location],
      editing: false,
    });
  }

  editAnswer(id: string, q: QuestionResponse) {
    this.questions.set(id, {
      ...q,
      editing: !q.editing,
    });
  }

  resetQuestionState() {
    this.questions.forEach((value, key) => {
      this.questions.set(key, {
        ...value,
        openEditing: false,
        editing: false,
      });
    });
  }

  successQuestionState() {
    this.questions.forEach((value, key) => {
      this.questions.set(key, {
        ...value,
        answer: value.editedAnswer,
        openEditing: false,
        editing: false,
      });
    });
  }

  closeSituations() {
    this.resetQuestionState();
    this.showSituationEditing = false;
    this.readAvailableSituations();
  }

  readAvailableSituations() {
    this.loading = true;
    this.availableSituations = [];

    this.valuepropService
      .readAvailableSituations(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.availableSituations = response.result;
        if (this.availableSituations.length === 0) {
          const payload2 = { situation_done: 1 };
          this.RS.putDone(this.valueProp.id, payload2)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.valueProp.situation_done = '1';
              this.situationsSaved = true;
              this.loading = false;
              this.callback.emit();
              this.cdr.detectChanges();
            });
        } else {
          this.availableSituations = response.result.map(({ description, element_type_id, elements, name, situation_type_id }) => {
            return { description, element_type_id, name, situation_type_id, children: elements };
          });
          this.availableSituations.forEach((situation) => {
            this.addSituation(situation);
          });
          this.originalSituationResponses = new Map(JSON.parse(JSON.stringify(Array.from(this.situationResponses))));
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  getDiscoveryQuestions() {
    this.valuepropService
      .getDiscoveryQuestions(this.valueProp.id, '31')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        res.result
          .sort((a, b) => +a.sequence - +b.sequence)
          .forEach((q) => {
            this.questions.set(q.account_discovery_question_id, {
              answer: q.answer,
              open: false,
              openEditing: false,
              editing: false,
              editedAnswer: q.answer,
              name: q.name,
              question_type_id: q.question_type_id,
              required: q.required,
              description: q.description,
            });
          });
      });
  }

  asIsOrder(a, b) {
    return 1;
  }

  getChildrenRecursive(situation: SituationResponsesTop, elementType: string): SituationPayload[] {
    const payload = [];
    situation.children.forEach((child) => {
      if (child.responses.length > 0) {
        child.responses.forEach((response) => {
          payload.push(this.createPayloadObject(situation.element_type_id, response));
        });
      }
      if (child.children && child.children.size > 0) {
        const elementTypeId = situation.element_type_id ? situation.element_type_id : elementType;
        payload.push(...this.getChildrenRecursive(child, elementTypeId));
      }
    });

    return payload;
  }

  createPayloadObject(elementTypeId: string, response: SituationElement): SituationPayload {
    let payload: SituationPayload;
    if (elementTypeId === '2') {
      payload = {
        value_prop_id: this.valueProp.id,
        situation_type_id: response.situation_type_id,
        situation_element_id: response.situation_element_id,
        parent_id: response.parent_id,
      };
    } else {
      payload = {
        value_prop_id: this.valueProp.id,
        situation_type_id: response.situation_type_id,
        situation_element_id: response.situation_element_id,
        id: response.id,
        parent_id: response.parent_id,
      };
    }
    return payload;
  }

  identify(_index: number, item: any) {
    return item.id;
  }

  flattenResponseTree(): SituationPayload[] {
    const payload = [];
    this.situationResponses.forEach((situation) => {
      if (situation.responses.length > 0) {
        situation.responses.forEach((response) => {
          payload.push(this.createPayloadObject(situation.element_type_id, response));
        });
        if (situation.children && situation.children.size > 0) {
          payload.push(...this.getChildrenRecursive(situation, situation.element_type_id));
        }
      }
    });

    return payload;
  }

  removeParentIds(situations: SituationPayload[]): SituationPayload[] {
    const payloadWithoutParentIds = situations.map((p) => {
      const payload = { ...p };
      delete payload.parent_id;
      return payload;
    });
    return payloadWithoutParentIds;
  }

  removeOrphans(situations: SituationPayload[]) {
    const parentIds = situations.map((p) => p.situation_element_id);
    parentIds.push('0');
    const payloadWithoutOrphans = situations.filter((p) => parentIds.includes(p.parent_id.toString()));
    if (payloadWithoutOrphans.length < situations.length) {
      return this.removeOrphans(payloadWithoutOrphans);
    } else {
      // @ts-ignore
      const onlyUniqeValues = [...new Map(payloadWithoutOrphans.map((v) => [JSON.stringify([v.situation_element_id, v.parent_id]), v])).values()];
      return onlyUniqeValues;
    }
  }

  saveSituations() {
    this.saveLoading = true;
    const payloadFlat = this.flattenResponseTree();
    const payloadWithoutOrphans = this.removeOrphans(payloadFlat);
    const payloadWithoutParents = this.removeParentIds(payloadWithoutOrphans);

    const payloadJson = {
      situations: payloadWithoutParents,
    };

    this.valuepropService
      .saveSituations(this.valueProp.id, payloadJson)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          const payload2 = { situation_done: 1 };
          this.RS.putDone(this.valueProp.id, payload2)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.valueProp.situation_done = '1';
              this.situationsSaved = true;
              this.callback.emit();
              this.reloadVP.emit();
              this.valuepropService.reloadScalers$.next(true);

              if (this.isQuickCreate) {
                this.activateNextStep();
              }
            });

          this.notificationService.success(this.trans.trans.situationUpdated.value, false);
          this.saveLoading = false;
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }

        this.showSituationEditing = false;
        this.readAvailableSituations();
      });

    if (this.questions.size > 0) {
      this.saveQuestions();
    }
  }

  saveQuestions() {
    const payload = [];
    this.questions.forEach((question, key) => {
      payload.push({
        value_prop_id: this.valueProp.id,
        account_discovery_question_id: key,
        answer: question.editedAnswer,
        question_type_id: question.question_type_id,
      });
    });

    const discoveryQuestionPayload = {
      discovery_questions: payload,
    };

    this.valuepropService
      .putDiscoveryQuestions(this.valueProp.id, discoveryQuestionPayload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.result) {
          this.successQuestionState();
          this.notificationService.success(this.trans.trans.discoveryQuestionsUpdated.value, false);
        } else {
          this.resetQuestionState();
          this.notificationService.error(this.trans.trans.discoveryQuestionsUpdateError.value, false);
        }
      });
  }

  onToggleChange(id: string, q: QuestionResponse, event) {
    this.questions.set(id, {
      ...q,
      editedAnswer: event.checked ? '1' : '0',
    });
  }

  // Valueprop creation flow
  activateNextStep() {
    this.commonService.callbackVPCreateSave.next({});
    this.getScalers.emit();
  }
}
