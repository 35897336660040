<form name="formAddVPG" #formAddVPG="ngForm">
	<div class="sidebar_wrapper">
		<div class='sidebar_header'>
			Add to Group
		</div>
		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<div class='row'>
					<div class='col-xs-12' appNGError [elemControl]='formAddVPG.controls.vpg'>
						<label for="name" class="sidebar_label">
							Value Groups
						</label>
						<p-dropdown appendTo="body" ret-data='Value Group' name='vpg' [options]="vpgList" [filter]='true' [autoDisplayFirst]='false' [(ngModel)]="selectedVPG" [optionLabel]="'name'" class='keywest_dropdown pull-right' [required]='true'></p-dropdown>
					</div>
				</div>
			</div>

			<div class='keywest_panel' style='margin-top: 20px;'>
				<div class='row form-group'>
					<div class='col-xs-12'>
						<label for="name" class="sidebar_label">
							Create new Group
						</label>
						<div class="input-group keywest_search">
							<input name='vpg' [(ngModel)]="strVPG" type='text' placeholder='Group Name' class='form-control'>
							<span (click)="funcAddVPG()" class="btn_customizable input-group-addon clickable">Add</span>
						</div>
					</div>
				</div>
				<div [ngClass]="{'row': crm, 'btn_footer': !crm}">
					<div class="col-xs-12">
						<dl-progressbar *ngIf="loadingVPG" class='dl_progress' [showValue]='true' [value]='"Saving Selection"' mode="indeterminate"></dl-progressbar>
						<app-btn-save [form]='formAddVPG' *ngIf="!loadingVPG" (callback)='funcSaveVPG()' text='Save' class='btn btn_customizable pull-right'></app-btn-save>
						<app-are-you-sure #scalerCancel *ngIf="!loadingVPG" matTooltipPosition="above" message='Are you sure?' class="btn cancel-confirm pull-right marg-h" icon='' text='Back' (callbackYes)='closeVPG()'></app-are-you-sure>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>