<a (click)='callbackBack.emit()' class='clickable'>Back <i class='fa fa-times'></i></a><br><br>
<div class='row' *ngIf="showHelp">
	<div class='col-xs-12'>
		<div class='well well-info'>
			<b>{{ trans.vp_dashboard.menu.share | translate}}</b><br>
			{{'vpdashboard.share.desc' | translate}}
		</div>
	</div>
</div>
<p-menubar [model]="menuTop"></p-menubar>

<div class="" *ngIf="view == 'share'">
	<div class="table-responsive col-sm-12 soln-table">
		<div class="searchTableContainer">
			<table id="shares" class="table custom-table" style='width: 100%; max-height: 400px; overflow-y: scroll;'>
				<thead>
					<tr>
						<th>{{ trans.vp_dashboard.share.name | translate}}</th>
						<th>{{ trans.vp_dashboard.share.user_name | translate}}</th>
						<th>{{ trans.vp_dashboard.share.share_role_type | translate}}</th>
						<th style='text-align: right;'>{{ trans.vp_dashboard.share.actions | translate}}</th>
					</tr>
				</thead>

				<tbody *ngIf="shares && shares!==null && shares.length>0">
					<tr *ngFor="let share of shares" class="grid-row">
						<td>{{share.value_prop_name}}</td>
						<td>{{share.user_name}}</td>
						<td>{{share.share_role}}</td>
						<td>
							<div class='pull-right'>
								<a *ngIf="share.share_role_type_id!=='4'" (click)="editShareValueProp(share)" class='btn btn-success'><i role="button" class="fa fa-pencil"></i></a>
								<a (click)="open(deleteUserModal,share.id)" class='btn btn-danger'><i role="button" class="fa fa-times"></i></a>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div *ngIf="sharesLoader" class="col-sm-12 text-center">
		<img class="loader" src="{{fullImagePath}}" />
	</div>
	<div *ngIf="(shares ==null || shares.length==0) && !sharesLoader" class="m-t-md col-sm-12">
		<div class="searchTableContainer text-center">
			<h4>{{ trans.vp_dashboard.share.no_shares_found | translate}}</h4>
		</div>
	</div>
</div>

<div class="" id="addShares" *ngIf="view == 'add'">
	<form name="AddSharesForm" #AddSharesForm="ngForm" *ngIf="users.length">
		<h3 *ngIf="type =='edit'">{{ trans.vp_dashboard.share.edit_share | translate}}</h3>
		<div class="row">
			<div class="form-group col-md-4 col-xs-12" [ngClass]="{'has-error': (AddSharesForm.controls.share?.invalid && AddSharesForm.controls.share?.touched)}">
				<label class="labelsRequired">{{ trans.vp_dashboard.share.user_name | translate}}:</label>
				<p-dropdown appendTo="body" [autoDisplayFirst]='false' name="share" [options]="users" optionLabel='name' [(ngModel)]="share.share_id" [filter]="true"></p-dropdown>

				<label *ngIf="type =='edit'">&nbsp;&nbsp;{{user_name}}</label>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-md-4 col-xs-12" [ngClass]="{'has-error': (AddSharesForm.controls.shareRoleType?.invalid && AddSharesForm.controls.shareRoleType?.touched)}">
				<label class="labelsRequired">{{ trans.vp_dashboard.share.share_role_type | translate}}:</label>
				<select [(ngModel)]="share.share_role_type_id" class="form-control role-dropdown" name="shareRoleType" required>
					<option selected value="">{{ trans.vp_dashboard.share.choose_one | translate }}</option>
					<option *ngFor="let shareRole of shareRoles" [value]="shareRole.id">
						{{shareRole.name}}
					</option>
				</select>
			</div>
		</div>
		<button class="btn btn-danger" (click)="view = 'share'"><i class='fa fa-chevron-left'></i> {{ trans.button.back | translate }}</button>
		<button class="btn btn-success" [disabled]="AddSharesForm.invalid" (click)="saveShare('user')"><i class='fa fa-save'></i> {{ 'general.save' | translate }}</button>
	</form>
	<img *ngIf="!users.length" class="loader" src="{{fullImagePath}}" />

</div>

<div *ngIf="view == 'exitingcustomer'">
	<div *ngIf="guestList.length">
		<form name="addExistingCustomerForm" #addExistingCustomerForm="ngForm">
			<div class='row'>
				<div class='col-md-3 col-xs-6'>
					<label>{{ trans.vp_dashboard.share.existing_guest | translate}}</label>

					<p-dropdown appendTo="body" [autoDisplayFirst]='false' name="selectedGuest" [options]="guestList" optionLabel='name' [(ngModel)]="selectedGuest" [filter]="true"></p-dropdown>
				</div>

			</div>
		</form>
		<button *ngIf="!existingCustomerLoader" type="submit" class="btn btn-success" (click)="addExistingCustomer();" [disabled]="addExistingCustomerForm.invalid">
			{{ trans.button.save | translate }}
		</button>
		<img class="loader small" [src]="fullImagePath" *ngIf="existingCustomerLoader" />
	</div>
	<div *ngIf="!guestList.length">
		<img class="loader small" [src]="fullImagePath" *ngIf="existingCustomerLoader" />
	</div>
</div>
<div *ngIf="view == 'newcustomer'">

	<form name="addNewCustomerForm" #addNewCustomerForm="ngForm">
		<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
			<label class="labelsRequired">{{ trans.vp_dashboard.share.first_name | translate}}</label>
			<input type="text" class="form-control" name="first_name" [(ngModel)]="customer.first_name" required />
		</div>
		<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
			<label class="labelsRequired">{{ trans.vp_dashboard.share.last_name | translate}}</label>
			<input type="text" class="form-control" name="last_name" [(ngModel)]="customer.last_name" required />
		</div>
		<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
			<label class="labelsRequired">{{ trans.vp_dashboard.share.title | translate}}</label>
			<input type="text" class="form-control" name="title" [(ngModel)]="customer.title" required />
		</div>
		<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.email?.invalid && addNewCustomerForm.controls.email?.touched)}">
			<label class="labelsRequired">{{ trans.vp_dashboard.share.email | translate}}</label>
			<input type="email" email="true" class="form-control" name="email" [(ngModel)]="customer.email" required />
		</div>
		<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
			<label class="labelsRequired">{{ trans.vp_dashboard.share.company | translate}}</label>
			<input type="email" class="form-control" name="account_name" [(ngModel)]="customer.account_name" required />
		</div>
		<button *ngIf="!newCustomerLoader" type="submit" class="btn btn-success m-t-xs m-b-xs" (click)="addNewCustomer();" [disabled]="addNewCustomerForm.invalid">
			{{ trans.button.save | translate }}
		</button>
		<img class="loader small" [src]="fullImagePath" *ngIf="newCustomerLoader" />
	</form>
</div>

<ng-template #deleteUserModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ trans.vp_dashboard.share.delete_share_user | translate}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{'props.tabDetails.share.DeleteUserWarning' | translate}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="deleteValuePropUser()">{{ trans.button.Yes | translate }}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ trans.button.No | translate }}</button>
		<img class="actionLoader" [hidden]="hideValuePropActionLoader" src="{{fullImagePath}}" />
	</div>
</ng-template>