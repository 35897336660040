<p-tabView>
	<p-tabPanel header="{{'props.tabDetails.share.name' | translate}}" id="share_user">

			<div class="" *ngIf="!addShare">
				<div class="table-responsive col-sm-12 soln-table">
					<div class="searchTableContainer">
						<div class="">
							<button class="btn btn-primary pull-right marg-v" (click)="openAddShare()">
								<i class="fa fa-plus"></i>
                                {{ 'general.add' | translate }}
                            </button>
                                <p-table [value]="shares" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
                                    <ng-template pTemplate="header" let-columns>
                                        <tr>
                                            <th>{{'props.tabDetails.share.ValueProposition' | translate}}</th>
                                            <th>{{'props.tabDetails.share.UserName' | translate}}</th>
                                            <th>{{'props.tabDetails.share.ShareRoleType' | translate}}</th>
                                            <th>{{'props.tabDetails.share.Action' | translate}}</th>
									    </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-share>
                                     	<tr class="grid-row">
                                            <td>{{share.master_value_prop_name}}</td>
                                            <td>{{share.first_name}} {{share.last_name}}</td>
                                            <td>{{share.share_role}}</td>
                                            <td>
                                                <i role="button" class="icon-pencil" (click)="editShareValueProp(share)"></i>&nbsp;&nbsp;
                                                <i role="button" class="icon-trash" (click)="open(deleteUserModal,share.id)"></i>
                                            </td>
									    </tr>
                                    </ng-template>
                                </p-table>
						</div>
					</div>
				</div>
				<div *ngIf="sharesLoader" class="col-sm-12 text-center">
					<img class="loader" src="{{fullImagePath}}" />
				</div>
				<div *ngIf="(shares ==null || shares.length==0) && !sharesLoader" class="m-t-md col-sm-12">
					<div class="searchTableContainer text-center">
						<h4>{{'props.tabDetails.share.NoSharesFound' | translate}}</h4>
					</div>
				</div>
			</div>

			<div class="" id="addShares" *ngIf="addShare">
				<form name="AddSharesForm" #AddSharesForm="ngForm">
					<h3 *ngIf="type =='edit'">{{'props.tabDetails.share.EditShare' | translate}}</h3>
					<div class="row">
						<div class="form-group col-md-6" [ngClass]="{'has-error': (AddSharesForm.controls.share?.invalid && AddSharesForm.controls.share?.touched)}">
							<label class="labelsRequired">{{'props.tabDetails.share.UserName' | translate}}:</label>
							<p-dropdown appendTo="body" class="keywest_dropdown" [options]="users" optionLabel="name" [(ngModel)]="share"
							 name="share" [filter]="true" placeholder="Select a User"></p-dropdown>
							<!-- <angular2-multiselect [data]="users" [(ngModel)]="share.share_id" name="share" [settings]="dropdownSettings" required>
								<c-item>
									<ng-template let-item="item">
										<label for="{{item.name}}"> {{item.name}} </label> </ng-template>
								</c-item>
							</angular2-multiselect> -->

							<!-- <select *ngIf="type !='edit'" [(ngModel)]="share.share_id" class="form-control role-dropdown" name="share" required>
								<option selected value="">{{ 'general.ChooseOne' | translate }}</option>
								<option *ngFor="let user of users" [value]="user.id">
									{{user.name}}
								</option>
							</select> -->
							<label *ngIf="type =='edit'">&nbsp;&nbsp;{{user_name}}</label>
						</div>
					</div>
					<div class="row">
						<div class="form-group col-md-6" [ngClass]="{'has-error': (AddSharesForm.controls.shareRoleType?.invalid && AddSharesForm.controls.shareRoleType?.touched)}">
							<label class="labelsRequired">{{'props.tabDetails.share.ShareRoleType' | translate}}:</label>
							<select [(ngModel)]="share.share_role_type_id" class="form-control role-dropdown" name="shareRoleType" required>
								<option selected value="">{{ 'general.ChooseOne' | translate }}</option>
								<option *ngFor="let shareRole of shareRoles" [value]="shareRole.id">
									{{shareRole.name}}
								</option>
							</select>
						</div>
					</div>
					<button class="btn btn-primary pull-right done-btn" (click)="closeAddShare()">
						{{ 'general.back' | translate }}</button>
					<button class="btn btn-primary pull-right done-btn" [disabled]="AddSharesForm.invalid" (click)="saveShare('user')">
						{{ 'general.save' | translate }}</button>
				</form>
			</div>

	</p-tabPanel>
	<!-- <ngb-tab title="{{'props.tabDetails.share.SharewithCustomer' | translate}}" id="share_customer">
		<ng-template ngbTabContent>
			<ngb-tabset #tabsCustomer [justify]="currentJustify" [activeId]="activeIdString">
				<ngb-tab title="{{'props.tabDetails.share.ExistingCustomer' | translate}}" id="Existing_Customer">
					<ng-template ngbTabContent>
						<form name="addExistingCustomerForm" #addExistingCustomerForm="ngForm">
							<div class="form-group">
								<label>{{'props.tabDetails.share.ExistingGuest' | translate}}</label>
								<select class="form-control" name="selectedGuest" [(ngModel)]="selectedGuest">
									<option *ngFor="let guest of guestList" [ngValue]="guest">
										{{guest.name}}
									</option>
								</select>
							</div>
						</form>
						<button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="addExistingCustomer();" [disabled]="addExistingCustomerForm.invalid">
							{{ 'general.save' | translate }}
						</button>
						<img class="loader small" src="{{fullImagePath}}" *ngIf="existingCustomerLoader" />
					</ng-template>
				</ngb-tab>
				<ngb-tab title="{{'props.tabDetails.share.NewCustomer' | translate}}" id="New_Customer">
					<ng-template ngbTabContent>
						<form name="addNewCustomerForm" #addNewCustomerForm="ngForm">
							<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
								<label class="labelsRequired">{{'props.tabDetails.share.FirstName' | translate}}</label>
								<input type="text" class="form-control" name="first_name" [(ngModel)]="customer.first_name" required />
							</div>
							<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
								<label class="labelsRequired">{{'props.tabDetails.share.LastName' | translate}}</label>
								<input type="text" class="form-control" name="last_name" [(ngModel)]="customer.last_name" required />
							</div>
							<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
								<label class="labelsRequired">{{'props.tabDetails.share.Title' | translate}}</label>
								<input type="text" class="form-control" name="title" [(ngModel)]="customer.title" required />
							</div>
							<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.email?.invalid && addNewCustomerForm.controls.email?.touched)}">
								<label class="labelsRequired">{{'props.tabDetails.share.Email' | translate}}</label>
								<input type="email" email="true" class="form-control" name="email" [(ngModel)]="customer.email" required />
							</div>
							<div class="form-group" [ngClass]="{'has-error': (addNewCustomerForm.controls.share?.invalid && addNewCustomerForm.controls.share?.touched)}">
								<label class="labelsRequired">{{'props.tabDetails.share.Company' | translate}}</label>
								<input type="email" class="form-control" name="account_name" [(ngModel)]="customer.account_name" required />
							</div>
							<button *ngIf="!isVPGroup" [disabled]="addNewCustomerForm.invalid" type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="addNewCustomer();" [disabled]="addNewCustomerForm.invalid">{{ 'general.save' | translate }}</button>
							<button *ngIf="isVPGroup" [disabled]="addNewCustomerForm.invalid" type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="addNewCustomerVPGroup();" [disabled]="addNewCustomerForm.invalid">{{ 'general.save' | translate }}</button>
							<img class="loader small" src="{{fullImagePath}}" *ngIf="newCustomerLoader" />
						</form>
					</ng-template>
				</ngb-tab>
			</ngb-tabset>
		</ng-template>
	</ngb-tab> -->
</p-tabView>

<ng-template #deleteUserModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{'props.tabDetails.share.DeleteShareUser' | translate}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>{{'props.tabDetails.share.DeleteUserWarning' | translate}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" *ngIf="!isVPGroup" class="btn btn-primary" (click)="deleteMasterValuePropShare()">{{ 'general.Yes' | translate}}</button>
		<button type="button" *ngIf="isVPGroup" class="btn btn-primary" (click)="deleteVPGroupShare()">{{ 'general.Yes' | translate}}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ 'general.No' | translate }}</button>
		<img class="actionLoader" [hidden]="hideValuePropActionLoader" src="{{fullImagePath}}" />
	</div>
</ng-template>