import { Injectable } from '@angular/core';

@Injectable ()

export class CurrencyTranslation {

    public config: any = {
        component: 'currency',
        display_name: 'Exchange Rate',
        description: 'List of exchange rates'
    };

    public trans: any = {
        exchangeRates: 'Exchange Rates',
        settings: 'Settings',
        dashboard: 'Dashboard',
        backToProfile: 'Back to Profile',
        backToSettings: 'Back to Settings',
        save: 'Save',
        currency: 'Currency',
        enabled: 'Enabled',
        webCalcEnabled: 'Web Calculator Enabled?',
        
    };
}
