import { Component, OnInit, HostListener, Input, Output, EventEmitter, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BenefitsImplementation } from '../../benefits.implementation';
import { SolutionService } from '@data/services/solution/solution.service';
import { takeUntil, take, finalize, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BenefitDetailTranslations } from './benefit-detail.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Benefit, Metric, ValueProp, DriverPhasing } from '@shared/models/value-prop.model';
import { Permissions } from 'app/value-prop/value-prop-dashboard/dashboard_customer/permissions';
import { RowLimit } from 'app/_models/row-limit.model';
import { MetricCaseStudy } from '@shared/models/metric-case-study.model';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';
import { OverlayPanel } from 'primeng/overlaypanel';

interface BenefitDetailExtra {
  edit_tco_name?: boolean;
  description_expanded?: boolean;
  feature_expanded?: boolean;
  impact_fmt?: string;
  current_effort_fmt?: string;
  operation_objectives?: any;
  d_literal_number?: number | string;
  f_literal_number?: number | string;
  edit_name?: boolean;
  metric_note_edited?: string;
  metric_note_2_edited?: string;
  metric_note_3_edited?: string;
  [key: string]: any;
}

@Component({
  selector: 'app-benefit-detail',
  templateUrl: './benefit-detail.component.html',
  styleUrls: ['benefit-detail.component.scss'],
})
export class BenefitDetailComponent implements OnInit, OnDestroy {
  @Input('data') valueMetric: Metric;
  @Input() valueProp: ValueProp;
  @Input() permissions: Permissions;
  @Input() contextualHelp: any;
  @Output() closeBenefitDetail = new EventEmitter();
  @Output() refreshBenefit = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  @Input() selectedBenefit: Metric & { operation_objectives: any };
  @Input() embedded = false;
  @Input() shareView = 'simple';
  @Input() canEdit = true;
  @Input() locked = false;
  @Input() sidebar = false;
  @Input() vc_chart = false;

  loading = false;

  feature39 = false;

  showDetail = false;
  showGrowthDecline = false;
  showDeleteNote = false;

  showTranslate = false;
  screenWidth = '500px';
  editing_scratch = false;
  fullImagePath: string;
  image_url: string;
  benefitsLoader = false;
  benefitsDetail: Partial<Metric>[] = [];
  benefit: Metric & BenefitDetailExtra;
  benefitUpdates: Partial<Metric & BenefitDetailExtra>;
  benefitUpdateCopy: Partial<Metric & BenefitDetailExtra>;
  benefitOtherValues: Partial<Metric & BenefitDetailExtra>;
  modalReference: NgbModalRef;
  factorTypeId: number;
  hideMetricLoader = false;
  canAddBenefit = false;
  showCurrentEffort = false;

  caseStudiesLoader = false;
  benefitCaseStudies: Array<MetricCaseStudy> = [];
  driverPhasing: DriverPhasing;

  showRating = false;

  subscription: Subscription = new Subscription();

  cols: Array<{ field: string; style: string; header: string; width: string; minwidth?: string; editable: boolean; factorCol?: boolean }> = [];
  tableData: Partial<{ [key: string]: any }>[] = [];
  totalColumns = 1;
  selectedColumns: Array<{ field: string; style: string; header: string; width: string; minwidth?: string; editable: boolean }> = [];

  feedback: any[] = [];
  myRating = 0;
  menuDetails: RowLimit[] = [];
  hasSubtypes = false;
  showSubtypes = false;
  feature14 = false;
  hasFeature46 = false;

  company_name = '';

  ngUnsubscribe = new Subject();

  activeTypes: Array<{ name: string; value: string }> = [
    {
      name: 'Active',
      value: '1',
    },
    {
      name: 'N/A',
      value: '0',
    },
    {
      name: 'Considered Soft',
      value: '3',
    },
  ];

  hardSoftDropdown = [
    { value: 0, label: 'Hard' },
    { value: 1, label: 'Soft' },
    { value: 2, label: 'Strategic' },
  ];

  feedbackScore: number | string;
  myComment: string;
  subscriptionUpdateBenefit: Subscription;
  refreshBenefitDetailSubscription: Subscription;
  readFeedbackSubscription: Subscription;
  updateMetricActiveSubscription: Subscription;
  saveBenefitFeedbackSubscription: Subscription;

  account_id: string;
  impactList: any[] = [];
  annualBenefitsList: any[] = [];
  expense_types: any[] = [];
  benefitTypeList: any[] = [];

  impact: any;
  annualBenefits: any;

  soft: any;
  editSoft = false;

  expense_type_id: { name: string; id: number };

  benefitType: any;
  editBenefitType = false;

  quantified: boolean;
  editQuantified = false;

  tcoFeature = false;
  feature57 = false;
  strategicMask = false;
  hideGrowthDecline = false;
  disableCaseStudiesLink: boolean;

  tempBenefit: Partial<Benefit> = {};
  firstLoad = true;

  hasOverrides: Boolean = false;
  hasPhasingOverrides: Boolean = false;
  termYears: Array<{ label: string; value: number; }> = [];
  hasFeature145: boolean;
  disableEdits: boolean = false;

  @Input() mini = false;
  @ViewChild('op') op: OverlayPanel;

  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: BenefitDetailTranslations,
    public bI: BenefitsImplementation,
    private solutionService: SolutionService,
    private rAS: ResultsAnalysisService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  metricbenefits: any;
  phasingOverrides: any;
  scratchpadOpenParens: Array<{ abbr: string; value: string }> = [
    {
      abbr: '',
      value: '0',
    },
    {
      abbr: '(',
      value: '1',
    },
  ];

  scratchpadCloseParens: Array<{ abbr: string; value: string }> = [
    {
      abbr: '',
      value: '0',
    },
    {
      abbr: ')',
      value: '1',
    },
  ];

  items: any[] = [];
  view = 'description';

  feature84 = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event) {
      this.calcScreenWidth();
    }
  }

  ngOnInit() {
    const tmp = sessionStorage.getItem('benefit_mask');
    this.strategicMask = tmp === '1' ? true : false;
    this.feature84 = this.commonService.checkFeature(84);
    this.feature39 = this.commonService.checkFeature(39);
    this.hasFeature46 = this.commonService.checkFeature(46);
    this.feature14 = this.commonService.checkFeature(14);
    this.hideGrowthDecline = this.commonService.checkFeature(120);
    this.disableCaseStudiesLink = this.commonService.checkFeature(123);
    this.tcoFeature = this.commonService.checkFeature(10);
    this.hasFeature145 = this.commonService.checkFeature(145);
    this.disableEdits = this.commonService.checkFeature(151);

    this.bI.benefitsListByType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loading = false;
      this.loadBenefits(false);
    });

    this.bI.benefitsUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
      if (id === this.benefit?.id) {
        this.loadBenefits(true);
      } else {
        this.loadBenefits(false);
      }
      this.reloadVP.emit();
    });
    this.getValuePropDetails();

    const hasPermission = this.permissions && (this.mini ? this.permissions.result_editable : this.permissions.benefits_editable);

    this.canEdit = hasPermission && this.valueProp.vp_can_edit;
    if (this.canEdit === undefined) {
      this.canEdit = true;
    }

    if (this.shareView === 'share') {
      this.locked = false;
    }

    this.feature57 = this.commonService.checkFeature('57');
    if (this.feature57) {
      this.editing_scratch = true;
    }
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(take(1)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.showTranslate = !this.showTranslate;
    });

    if (!this.valueMetric) {
      this.valueMetric = this.selectedBenefit;
    }
    this.account_id = sessionStorage.getItem('aid');
    this.readImpactTypes();
    this.readAccrualTypes();
    this.getExpenseTypes();
    this.readBenefitTypes();

    this.company_name = sessionStorage.getItem('account_name');

    this.calcScreenWidth();
    const privs = sessionStorage.getItem('privileges');
    const privileges = privs.split(',');
    if (privileges.length) {
      if (privileges.indexOf('6') >= 0) {
        this.canAddBenefit = true;
      }
    }

    if (this.selectedBenefit && !this.valueMetric) {
      this.valueMetric = this.selectedBenefit;
    }
    this.loadBenefits(true);
    this.funcGetFeedback();

    this.valuepropService.refreshBenefitDetail.pipe(take(1)).subscribe(() => {
      this.loadBenefits(true);
      this.funcGetFeedback();
    });

    if (this.hasFeature46) {
      this.soft = this.hardSoftDropdown.find((hard) => hard.value === +this.valueMetric.soft).value;
    } else {
      this.soft = +this.valueMetric.soft === 1 ? true : false;
    }

    this.benefitType = this.valueMetric.benefit_type_id;
    this.quantified = +this.valueMetric.quantified === 1 ? true : false;

    this.showDetail = true;
    this.setupTermYears();
  }

  setupTermYears() {
    if (this.hasFeature145) {
      for (let i = 1; i <= this.valueProp['term']; i++) {
        const year = {
          label: 'Year ' + i,
          value: i,
        };
        this.termYears.push(year);
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  funcSetMask() {
    this.strategicMask = !this.strategicMask;
    sessionStorage.setItem('benefit_mask', this.strategicMask ? '1' : '0');
    this.loadBenefits(true);
  }

  getValuePropDetails() {
    if (this.valueProp.id && !this.valueProp.name) {
      const id = this.valueProp.id;
      this.valuepropService
        .getValuePropDetail(id)
        .pipe(take(1))
        .subscribe((response) => {
          if (response.result) {
            this.valueProp = response.result.value_prop;
          }
        });
    }
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.cols = [];
        this.selectedColumns = [];
        this.cols.push({ field: 'formula', style: 'left', header: this.trans.trans.formula.value, width: '450px', minwidth: '230px', editable: false });

        this.cols.push({ field: 'calc', style: 'center', header: '', width: '40px', editable: false });
        if (!this.embedded) {
          if (this.feature14) {
            this.cols.push({ field: 'cost', style: 'left', header: this.trans.trans.current_effort.value, width: '120px', editable: true, factorCol: true });
          }
          if (this.feature14) {
            this.cols.push({
              field: 'future_effort',
              style: 'left',
              header: this.trans.trans.with.value + ' ' + (this.company_name ? this.company_name : 'us'),
              width: '120px',
              editable: true,
              factorCol: true
            });
          }
          for (let i = 1; i <= this.valueProp['term']; i++) {
            const year = {
              field: 'year' + i,
              style: 'left',
              header: `${this.trans.trans.year.value}${i}`,
              width: '120px',
              editable: i === 1 ? true : this.hasFeature145,
              factorCol: true,
            };
            this.cols.push(year);
          }
        }
        this.selectedColumns = this.cols;

        this.activeTypes = [
          { name: this.trans.trans.active.value, value: '1' },
          { name: this.trans.trans.na.value, value: '0' },
        ];
        if (!this.feature39) {
          this.activeTypes.push({ name: this.trans.trans.soft.value, value: '3' });
        }
        this.loadBenefits(true);
      });
  }

  updateParamQuantified() {
    const payload = { quantified: this.quantified ? 1 : 0 };
    this.solutionService
      .updateSolutionBenefitsParam(this.selectedBenefit.value_prop_metric_id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.editQuantified = false;
        }
      });
  }

  public funcLiveSave(param, val) {
    if (!this.feature57) {
      return false;
    }
    this.updateBenefit();

    let payload;
    switch (param) {
      case 'tco_name':
        payload = { value_prop_metric_id: this.selectedBenefit.value_prop_metric_id, tco_name: val };
        break;
      case 'alt_metric_name':
        payload = { value_prop_metric_id: this.selectedBenefit.value_prop_metric_id, alt_metric_name: val };
        break;
      case 'active':
        payload = { value_prop_metric_id: this.selectedBenefit.value_prop_metric_id, active: val };
        break;
      case 'soft':
        payload = { value_prop_metric_id: this.selectedBenefit.value_prop_metric_id, soft: this.soft };
        break;

      case 'all':
        payload = {
          value_prop_metric_id: this.benefit.value_prop_metric_id,
          tco_name: this.benefit.tco_name,
          active: this.benefit.active,
          alt_metric_name: this.benefit.alt_metric_name ? this.benefit.alt_metric_name : this.benefit.metric_name,
        };
        if (this.hasFeature46) {
          payload['soft'] = this.soft;
        }
        break;
    }

    if (payload) {
      this.solutionService
        .updateSolutionBenefitsParamLiveSave(this.valueProp.id, payload)
        .pipe(take(1))
        .subscribe((response) => {
          if (response.result) {
            if (!this.mini) {
              this.notificationService.success(this.trans.trans.inlineSave.value, false);
            }
          }
          this.valuepropService.refreshBenefitDetail.next();
          this.valuepropService.refreshDashboard.next('no_reload');
          this.closeBenefitDetail.emit();
        });
    }
    return true;
  }

  updateParamSoft() {
    this.bI.triggerCache = true;
    let payload;
    if (this.hasFeature46) {
      payload = { soft: this.soft };
    } else {
      payload = { soft: this.soft ? 1 : 0 };
    }

    this.solutionService
      .updateSolutionBenefitsParam(this.selectedBenefit.value_prop_metric_id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.editSoft = false;
          this.valuepropService.refreshDashboard.next('no_reload');
          this.valuepropService.refreshBenefitDetail.next();
          this.bI.loadBenefits();
        }
      });
  }

  updateParamBenefitType() {
    const payload = { benefit_type_id: this.benefitType };
    this.solutionService
      .updateSolutionBenefitsParam(this.selectedBenefit.value_prop_metric_id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.benefitType = this.benefitTypeList.find((x) => x.id == this.benefitType);
          this.editBenefitType = false;
        }
      });
  }

  readImpactTypes() {
    this.solutionService
      .getImpactTypes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.impactList = response.result;
          this.impact = this.impactList.find((x) => x.id == this.valueMetric['impact_type_id']);
        }
      });
  }

  readAccrualTypes() {
    this.solutionService
      .getAccrualTypes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.annualBenefitsList = response.result;
          this.annualBenefits = this.annualBenefitsList.find((x) => x.id == this.valueMetric['accrual_type_id']);
        }
      });
  }

  getExpenseTypes() {
    this.solutionService
      .getExpenseTypes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.expense_types = response.result;
          this.expense_type_id = this.expense_types.find((x) => x.id == this.valueMetric['expense_type_id']);
        }
      });
  }

  readBenefitTypes() {
    this.solutionService
      .getBenefitTypes(this.account_id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.benefitTypeList = response.result;
          this.benefitType = this.benefitTypeList.find((x) => x.id == this.valueMetric['benefit_type_id']);
        }
      });
  }

  funcGetFeedback() {
    this.valuepropService
      .readFeedback(this.selectedBenefit.account_solution_metric_id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.feedback && response.result.feedback.length) {
          this.feedbackScore = response.result.average;
          this.feedback = response.result.feedback;
          this.loading = false;
        }
      });
  }

  saveBenefitRating(benefit) {
    const ratingObj = {
      account_solution_metric_id: benefit.account_solution_metric_id,
      score: this.myRating,
      comment: this.myComment,
    };

    this.valuepropService
      .saveBenefitFeedback(ratingObj, this.selectedBenefit.id)
      .pipe(take(1))
      .subscribe(() => {
        this.funcGetFeedback();
      });
  }

  funcGotoMenu() {
    this.valuepropService.gotoMenuItem.next({ panel: 'vpdashboardMenuBottom', menu: 'benefits', data: null });
  }
  statusChange() {
    const payload = {
      value_prop_metric_id: this.benefit.id,
      active: this.benefit.active,
    };
    this.benefitsLoader = true;
    this.updateMetricActiveSubscription = this.valuepropService
      .updateMetricActive(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.valuepropService.refreshDashboard.next('no_reload');
          this.bI.loadBenefits(this.benefit.id);

          this.notificationService.success(this.trans.trans.benefitStatusSaved.value, false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.benefitsLoader = false;
      });
  }

  funcSetupMenu() {
    this.menuDetails = [];
    this.menuDetails.push({
      label: this.trans.trans.growth.value,
      command: () => {
        this.updateDetailsPhasingModal();
        this.showGrowthDecline = !this.showGrowthDecline;
      },
    });
  }

  calcScreenWidth(): void {
    const tmp = document.getElementById('width');
    if (tmp && tmp.offsetWidth) {
      this.screenWidth = tmp.offsetWidth ? tmp.offsetWidth + 'px' : '100%';
    }
  }

  editFeatures() {
    this.benefit.edit_features = true; 
    this.benefit.features_edited = this.benefit.features;
    // Replacing Raw HTML with new line characters so textarea doesn't show raw <br/>
    this.benefit.features_edited = this.benefit.features_edited.replace(/<br\s*\/?>\s*/g, '\n');
    this.benefit.showFeatures = true;
  }

  editDescription() {
    this.benefit.edit_description = true; 
    this.benefit.description_edited = this.benefit.description; 
    this.benefit.description_edited = this.benefit.description_edited.replace(/<br\s*\/?>\s*/g, '\n');
    this.benefit.showDescription = true
  }

  loadBenefits(singleLoad: boolean) {
    if (!this.valueMetric) {
      return;
    }
    if (this.valueMetric && !this.valueMetric?.value_prop_metric_id) {
      this.valueMetric.value_prop_metric_id = this.valueMetric?.id;
    }
    this.valuepropService
      .readCaseStudyByValuePropMetricId(this.valueProp.id, this.valueMetric?.value_prop_metric_id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.benefitCaseStudies = response.result;

          this.benefitCaseStudies.forEach((data) => {
            if ( data.redacted === '1' ) {
              // Company should be redacted
              data.display_name = data.name;
            } else {
              data.display_name = data.company_name;
            }
          });
        }
      });
    if (singleLoad) {
      this.loading = true;
    }
    const searchLimit = this.valueProp.id;
    this.valuepropService
      .getValuePropBenefits(searchLimit, 1, +this.valueMetric?.id)
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false;
          this.firstLoad = false;
        })
      )
      .subscribe((response) => {
        if (response.result) {
          this.benefit = response.result.metrics[0];
          if (this.feature57) {
            this.benefit.edit_tco_name = true;
          }
          this.benefit.description_expanded = false;
          this.benefit.feature_expanded = false;
          if (this.benefit.has_subtypes) {
            this.hasSubtypes = true;
          }

          if (!this.benefit.metric_note) {
            this.benefit.metric_note = '';
          }
          if (!this.benefit.metric_note_2) {
            this.benefit.metric_note_2 = '';
          }
          if (!this.benefit.metric_note_3) {
            this.benefit.metric_note_3 = '';
          }

          this.checkCurrentEffort(this.benefit.unit_type_id, this.benefit.impact_type_id);
          this.benefitUpdates = {
            driver_value: this.benefit.driver_value,
            financial_value: this.benefit.financial_value,
            impact: this.benefit.impact,
            current_effort: this.benefit.current_effort,
          };
          this.benefitUpdateCopy = {
            driver_value: this.benefit.driver_value_fmt,
            financial_value: this.benefit.financial_value_fmt,
            impact: this.benefit.impact_fmt,
            current_effort: this.benefit.current_effort_fmt,
          };
          this.benefitOtherValues = {
            value_prop_metric_id: this.benefit.id,
            driver_factor_id: this.benefit.driver_factor_id,
            driver_source_type_id: this.benefit.driver_source_type_id,
            financial_factor_id: this.benefit.financial_factor_id,
            financial_source_type_id: this.benefit.financial_source_type_id,
            id: this.benefit.id,
            unit_type_id: this.benefit.unit_type_id,
          };

          this.benefitsLoader = false;

          this.totalColumns = +this.valueProp.term;

          this.tableData = [];

          if (this.selectedBenefit && !this.selectedBenefit.operation_objectives && this.benefit.operation_objectives) {
            this.selectedBenefit.operation_objectives = this.benefit.operation_objectives;
          }

          let r = {};

          r['factorTypeId'] = 1;
          r['hasScratchpad'] = this.benefit.has_driver_scratchpad === 1 ? true : false;
          r['formula'] = this.benefit.driver_factor;
          r['formula_edited'] = this.benefit.driver_factor;
          r['cost'] = '';
          r['editable'] = true;
          r['type'] = 'driver';
          r['factorRow'] = true;
          r['years_updated'] = [];
          r['calc'] = 'x';
          r['literal_number'] = this.benefit.d_literal_number;
          r['description'] = this.benefit.driver_description;
          r['driver_factor_id'] = this.benefit.driver_factor_id;
          r['factor_id'] = this.benefit.driver_factor_id;
          r['driver_source_type_id'] = this.benefit.driver_source_type_id;
          r['source_type_id'] = this.benefit.driver_source_type_id;
          r['driver_override'] = this.benefit['year' + 1 + '_driver'];
          r['driver_scratchpad_used'] = this.benefit['driver_scratchpad_used'];
          r['scratchpad_active'] = this.benefit['driver_scratchpad_used'] === 1 ? true : false;

          const driverAbbr = this.benefit.d_abbr && this.benefit.d_abbr === '%' ? this.benefit.d_abbr : '';
          for (let i = 1; i <= this.valueProp['term']; i++) {
            // Offset for table
            let j = i + 1;
            r['year' + i + '_fmt'] = this.benefit['year' + i + '_driver_fmt'] + ' ' + driverAbbr;
            r['year' + i] = this.benefit['year' + i + '_driver'];
            r['year' + j + '_phase_override'] = this.benefit['year' + i + '_driver_phase_override'];

            if (r['year' + j + '_phase_override']) {
              this.hasOverrides = true;
            }
          }

          const termArray = Array(Number(this.valueProp.term)).keys();
          const hasDriverData = Array.from(termArray).some((termKey) => {
            const term = termKey + 1;
            const driverNum = this.benefit['year' + term + '_driver_fmt'];
            return driverNum !== '' && !!driverNum;
          });

          if (!hasDriverData || !r['formula']) {
            r['hideRow'] = true;
          }
          this.tableData.push(r);

          let i_formula = this.benefit.improvement_factor;
          if ( this.benefit.improvement_factor_id === '1' ) {
            // Only add unit for old generic improvement factor
            i_formula += ' (' + this.benefit.unit_type + ')';
          }

          r = {
            formula: i_formula
          };
          r['formula_edited'] = this.benefit.improvement_factor;

          r['factorTypeId'] = 3;
          r['hasScratchpad'] = this.benefit.has_improvement_scratchpad === 1 ? true : false;
          r['editable'] = true;
          if (this.feature14) {
            r['cost'] = this.benefit.current_effort_fmt;
            r['cost_fmt'] = this.benefit.current_effort_fmt;
          }
          r['calc'] = 'x';
          r['literal_number'] = this.benefit.f_literal_number;
          r['type'] = 'improvement';
          r['factorRow'] = true;
          r['years_updated'] = [];
          r['future_effort'] = this.benefit.future_effort;
          r['future_effort_fmt'] = this.benefit.future_effort_fmt;
          r['description'] = this.benefit.improvement_description;
          r['improvement_factor_id'] = this.benefit.improvement_factor_id;
          r['factor_id'] = this.benefit.improvement_factor_id;
          r['impact'] = this.benefit.impact_fmt;
          r['impact_source_type_id'] = '1';
          r['source_type_id'] = '1';
          r['value_prop_metric_id'] = this.benefit.value_prop_metric_id;
          r['improvement_scratchpad_used'] = this.benefit['improvement_scratchpad_used'];
          r['scratchpad_active'] = this.benefit['improvement_scratchpad_used'] === 1 ? true : false;

          const improvementAbbr = this.benefit.i_abbr && this.benefit.i_abbr === '%' ? this.benefit.i_abbr : '';
          for (let i = 1; i <= this.valueProp['term']; i++) {
            // Offset for table
            let j = i + 1;
            r['year' + i + '_fmt'] = this.benefit['year' + i + '_improvement_fmt'] + ' ' + improvementAbbr;
            r['year' + i] = this.benefit['year' + i + '_improvement'];
            r['year' + j + '_phase_override'] = this.benefit['year' + i + '_improvement_phase_override'];
            
            if (r['year' + j + '_phase_override']) {
              this.hasOverrides = true;
            }
          }

          if (!this.benefit.impact_fmt || !r['formula']) {
            r['hideRow'] = true;
          }
          this.tableData.push(r);

          if (this.benefit.financial_factor) {
            r = {};
            r = { formula: this.benefit.financial_factor };
            r['formula_edited'] = this.benefit.financial_factor;
            r['factorTypeId'] = 2;
            r['hasScratchpad'] = this.benefit.has_financial_scratchpad === 1 ? true : false;
            r['editable'] = true;
            r['type'] = 'financial';
            r['factorRow'] = true;
            r['years_updated'] = [];
            r['calc'] = 'x';
            r['literal_number'] = this.benefit.f_literal_number;
            r['description'] = this.benefit.financial_description;

            r['financial_factor_id'] = this.benefit.financial_factor_id;
            r['factor_id'] = this.benefit.financial_factor_id;
            r['financial_override'] = this.benefit.financial_override;
            r['financial_source_type_id'] = this.benefit.financial_source_type_id;
            r['source_type_id'] = this.benefit.financial_source_type_id;
            r['financial_scratchpad_used'] = this.benefit['financial_scratchpad_used'];
            r['scratchpad_active'] = this.benefit['financial_scratchpad_used'] === 1 ? true : false;

            r['cost'] = '';
            const financialAbbr = this.benefit?.f_abbr && this.benefit.f_abbr === '%' ? this.benefit.f_abbr : '';
            for (let i = 1; i <= this.valueProp['term']; i++) {
              // Offset for table
              let j = i + 1;
              r['year' + i + '_fmt'] = this.benefit['year' + i + '_financial_fmt'] + ' ' + financialAbbr;
              r['year' + i] = this.benefit['year' + i + '_financial'];
              r['year' + j + '_phase_override'] = this.benefit['year' + i + '_financial_phase_override'];
              
              if (r['year' + j + '_phase_override']) {
                this.hasOverrides = true;
              }
            }

            this.tableData.push(r);
          }
          if (!this.embedded) {
            if (this.trans.trans.phasing.value) {
              r = {};
              r = { formula: this.trans.trans.phasing.value };
              r['calc'] = 'x';
              r['cost'] = '';
              r['editable'] = false;
              r['type'] = 'phasing';

              r['hasScratchpad'] = false;
              for (let i = 1; i <= this.valueProp['term']; i++) {
                r['year_' + i + '_fmt'] = +this.benefit['metric_phase_' + i];
                r['year' + i] = +this.benefit['metric_phase_' + i] - +this.benefit['metric_phase_0'] + '%';
              }
              this.tableData.push(r);
            }

            r = {};
            r = { formula: 'Total' };
            r['cost'] = '';
            r['calc'] = '=';
            r['type'] = 'total';
            r['hasScratchpad'] = false;
            r['editable'] = false;
            for (let i = 1; i <= this.valueProp['term']; i++) {
              if (this.soft == 0) {
                r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
                r['year' + i] = this.benefit['year' + i + '_benefits_fmt'];
              } else {
                if (!this.strategicMask) {
                  r['year_' + i + '_fmt'] = '----';
                  r['year' + i] = '----';
                } else {
                  r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
                  r['year' + i] = this.benefit['year' + i + '_benefits_fmt'];
                }
              }
            }
            this.tableData.push(r);
          }
          let hasGrowthDecline = false;
          this.tableData.forEach((data) => {
            if (!data.hideRow && !hasGrowthDecline) {
              data['showGrowthDecline'] = true;
              hasGrowthDecline = true;
            }
          });

          this.benefitsDetail = [];
          for (let i = 1; i <= this.valueProp['term']; i++) {
            const benefit: Partial<Metric> = {
              driver_value: this.benefit['year' + i + '_driver'],
              driver_value_fmt: this.benefit['year' + i + '_driver_fmt'],
              impact: this.benefit.impact_fmt,
              financial_value: this.benefit.financial_value_fmt,
              metric_phase_1: this.benefit['metric_phase_' + i],
              benefits: this.benefit['year' + i + '_benefits_fmt'],
              current_effort: this.benefit.current_effort_fmt,
              unit_type: this.benefit.unit_type_id,
              driver_factor: i === 1 ? this.benefit.driver_factor : '-',
              financial_factor: i === 1 ? this.benefit.financial_factor : '-',
              future_effort_fmt: this.benefit.future_effort_fmt,
            };
            this.benefitsDetail.push(benefit);
          }
          this.bI.triggerCache = false;
        }
      });


  }

  funcSaveFactorName(data) {
    let id = null;

    switch (data.type) {
      case 'driver':
        id = data.driver_factor_id;
        break;
      case 'improvement':
        id = data.improvement_factor_id;
        break;
      case 'financial':
        id = data.financial_factor_id;
        break;
    }

    const payload = {
      factor_id: id,
      override_name: data.formula_edited,
    };

    this.valuepropService
      .postEditFactorNameFromBenefits(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.factorNameSaved.value, false);
        data.editing = false;
        this.loadBenefits(true);
      });
  }

  funcCalcCostWithYear(row, changed) {
    if (changed === 'cost' || changed === 'future_effort') {
      row.year1 = parseFloat(row.cost) - parseFloat(row.future_effort);
    } else {
      row.future_effort = parseFloat(row.cost) - parseFloat(row.year1);
    }
  }

  useFmtField(row, col) {
    if ( row.factorRow === true && col.factorCol === true ) {
      // Factor row and factor column makes this a factor number cell
      // Check if we are editing
      if ( this.editing_scratch === false ) {
        // Just display
        return true;
      } else {
        // We are editing, only show the fmt field if we cannot edit
        if ( row.editable === true && col.editable === true ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      // This is a textfield, don't use fmt
      return false;
    }
  }

  useField(row, col) {
    if ( row.factorRow === true && col.factorCol === true ) {
      // Factor row and factor column makes this a factor number cell
      // We should never show field, always fmt or nothing
      return false;
    } else {
      // This is a textfield, always use field
      return true;
    }
  }

  funcShowHideInput(row, col) {
    if (row.type === 'improvement' && (col.field === 'cost' || col.field === 'future_effort')) {
      return true;
    }

    if ( row.type === 'improvement' && row.improvement_factor_id == '1' ) {
      // Generic improvement, only edit year 1
      if ( col.field === 'year1' ) {
        return true;
      } else {
        return false;
      }
    }

    if (col.field.includes('year')) {
      return true;
    }
    return false;
  }

  checkCurrentEffort(unit_type, impact_type) {
    const utype = parseInt(unit_type, 10);

    if (impact_type == 1 || utype === 8 || utype === 9 || utype === 10 || utype === 11 || utype === 12 || utype === 13) {
      this.showCurrentEffort = true;
    } else {
      this.showCurrentEffort = false;
    }
  }

  setData(response) {
    if (response.result && response.result.success !== false) {
      if (this.embedded) {
        this.refreshBenefit.emit();
        this.reloadVP.emit();
      }
    } else if (response.result.success === false) {
      this.notificationService.error(response.result.message, false); // Params {message, islogout}
    }
    if (this.embedded) {
      this.valuepropService.refreshBenefits.next('');
    }
    this.valuepropService.refreshDashboard.next('no_reload');
    this.loading = false;
  }

  public updateBenefit() {
    this.bI.triggerCache = true;
    if (!this.feature57) {
      this.loading = true;
    }
    let payload;
    const factors = this.tableData.filter((x) => x.updated === true || x.cost_updated === true || x.future_effort_updated === true);
    let payload_costs;

    factors.forEach((factor) => {
      switch (factor.type) {
        case 'driver':
          factor.driver_value = factor.year1;
          break;
        case 'financial':
          // Remove the currency symbol from the value, if present
          factor.year1 = String(factor.year1);
          const value = isNaN(+factor.year1[0]) ? factor.year1.slice(1, factor.year1.length) : factor.year1;
          factor.financial_value = value;
          break;
        case 'improvement':
          factor.impact = factor.year1;
          factor.improvement = factor.year1;
          factor.value_prop_metric_id = factor.value_prop_metric_id;
          if (factor.cost_updated === true || factor.future_effort_updated === true) {
            payload_costs = {
              value_prop_metric_id: factor.value_prop_metric_id,
              current_effort: factor.cost,
              future_effort: factor.future_effort,
            };
          }
          break;
      }
    });

    if (factors.length) {
      payload = {
        factors: factors,
        costs: payload_costs,
      };

      this.valuepropService
        .updateAllFactors(this.valueProp.id, payload)
        .pipe(take(1))
        .subscribe({
          next: () => {
            if (!this.feature57) {
              if (!this.mini) {
                this.notificationService.success(this.trans.trans.benefitUpdated.value, false);
              }
              this.valuepropService.refreshDashboard.next('no_reload');
              this.bI.loadBenefits(this.benefit.id);
            }
          },
          complete: () => {
            this.loadBenefits(true);
            this.loading = false;
          },
        });
    } else {
      if (!this.feature57) {
        this.loading = false;
      }
    }
  }

  closeBenefit() {
    this.benefitUpdates = this.benefitUpdateCopy;
  }

  improvementKeyPress(col, rowData) {
    this.funcCalcCostWithYear(rowData, col.field);

    if (col.field === 'cost') {
      rowData['cost_updated'] = true;
    } else {
      if (col.field === 'future_effort') {
        rowData['future_effort_updated'] = true;
      } else {
        rowData['updated'] = true;

        if ( rowData['years_updated'].indexOf(col.field) == -1 ) {
          rowData['years_updated'].push(col.field);
        }
      }
    }
  }

  open(content) {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  updateDetailsPhasingModal() {
    this.valuepropService
      .getDriverGrowth(this.valueProp.id, this.valueMetric.value_prop_metric_id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result.driver_phasing) {
          this.driverPhasing = response.result.driver_phasing;
          this.metricbenefits = Object.keys(response.result.driver_phasing);
          this.phasingOverrides = response.result.overrides;
          for (const key in this.phasingOverrides) {
            if (this.phasingOverrides.hasOwnProperty(key) && this.phasingOverrides[key] === true) {
              this.hasPhasingOverrides = true;
            }
          }
        }
      });
  }

  updateTCOName() {
    const params = {
      tco_name: this.benefit.tco_name === '' ? this.benefit.tco_name : this.benefit.tco_name,
      value_prop_metric_id: this.valueMetric.value_prop_metric_id,
    };

    this.valuepropService
      .updateTCOName(this.valueProp.id, params)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          if (this.embedded) {
            this.refreshBenefit.emit();
            this.reloadVP.emit();
          }
          this.valuepropService.refreshBenefitDetail.next();
          this.notificationService.success(this.trans.trans.TCONameSaved.value, false);
          this.loadBenefits(true);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        } else if (response.result.errors) {
          this.notificationService.error(this.trans.trans.somethingWrong.value + ': ' + response.result.errors.reason, false);
        }
      });
  }

  editBenefitName() {
    this.benefit.edit_name = !this.benefit.edit_name;
    this.tempBenefit = { ...this.benefit };
  }

  editBenefitDescription() {
    this.benefit.edit_description = !this.benefit.edit_description;
    this.tempBenefit = { ...this.benefit };
  }

  editBenefitFeatures() {
    this.benefit.edit_features = !this.benefit.edit_features;
    this.tempBenefit = { ...this.benefit };
  }

  updateAltMetricName() {
    this.bI.triggerCache = true;
    const params = {
      alt_metric_name: this.tempBenefit.alt_metric_name === '' ? this.tempBenefit.metric_name : this.tempBenefit.alt_metric_name,
      value_prop_metric_id: this.valueMetric.value_prop_metric_id,
    };

    this.valuepropService
      .updateMetricAltName(this.valueProp.id, params)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          if (this.embedded) {
            this.refreshBenefit.emit();
            this.reloadVP.emit();
          }
          this.valuepropService.refreshDashboard.next('no_reload');
          this.bI.loadBenefits(this.benefit.id);
          this.notificationService.success(this.trans.trans.metricNameSaved.value, false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        } else if (response.result.errors) {
          this.notificationService.error(this.trans.trans.somethingWrong.value, false);
        }
        this.loadBenefits(true);
      });
  }

  saveMetricDescription() {
    this.bI.triggerCache = true;
    const params = {
      alt_metric_description: this.benefit.description_edited,
      value_prop_metric_id: this.valueMetric.value_prop_metric_id,
    };

    this.valuepropService
      .updateMetricAltDescription(this.valueProp.id, params)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          if (this.embedded) {
            this.refreshBenefit.emit();
            this.reloadVP.emit();
          }
          this.valuepropService.refreshDashboard.next('no_reload');
          this.bI.loadBenefits(this.benefit.id);
          this.notificationService.success(this.trans.trans.metricDescriptionSaved.value, false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        } else if (response.result.errors) {
          this.notificationService.error(this.trans.trans.somethingWrong.value, false);
        }
        this.loadBenefits(true);
      });
  }

  saveMetricFeatures() {
    this.bI.triggerCache = true;
    const params = {
      features: this.benefit.features_edited,
      value_prop_metric_id: this.valueMetric.value_prop_metric_id,
    };

    this.valuepropService
      .updateMetricAltFeatures(this.valueProp.id, params)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          if (this.embedded) {
            this.refreshBenefit.emit();
            this.reloadVP.emit();
          }
          this.valuepropService.refreshDashboard.next('no_reload');
          this.bI.loadBenefits(this.benefit.id);
          this.notificationService.success(this.trans.trans.metricFeaturesSaved.value, false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        } else if (response.result.errors) {
          this.notificationService.error(this.trans.trans.somethingWrong.value, false);
        }
        this.loadBenefits(true);
      });
  }

  saveMetricNote(id: number): void {
    const payload = {
      value_prop_metric_id: this.valueMetric.value_prop_metric_id,
      metric_note: this.benefit.metric_note,
    };

    switch (id) {
      case 1:
        payload['metric_note'] = this.benefit.metric_note_edited;
        break;
      case 2:
        payload['metric_note_2'] = this.benefit.metric_note_2_edited;
        break;
      case 3:
        payload['metric_note_3'] = this.benefit.metric_note_3_edited;
        break;
    }
    this.valuepropService
      .updateMetricNote(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success(this.trans.trans.metricNameSaved.value, false);
          this.loadBenefits(true);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        } else if (response.result.errors) {
          this.notificationService.error(this.trans.trans.noteError.value, false);
        }
        this.funcSetupMenu();
      });
  }

  deleteMetricNote() {
    this.valuepropService
      .deleteMetricNote(this.valueProp.id, this.valueMetric.value_prop_metric_id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success(this.trans.trans.benefitNoteDelete.value, false);
          this.loadBenefits(true);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        } else if (response.result.errors) {
          this.notificationService.error(this.trans.trans.somethingWrong.value, false);
        }
        this.funcSetupMenu();
      });
  }

  saveMetricPhase(type?) {
    this.bI.triggerCache = true;
    this.hideMetricLoader = true;
    const payload = {
      value_prop_metric_id: this.valueMetric.value_prop_metric_id,
      apply_all: type === 'applyToAll' ? 1 : 0,
    };
    this.metricbenefits.forEach((element) => {
      payload[element] = this.driverPhasing[element];
    });

    this.valuepropService
      .updateDrivingPhase(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result.errors) {
          this.notificationService.error(this.trans.trans.somethingWrong.value, false);
        } else {
          this.notificationService.success(this.trans.trans.saveSuccess.value, false);
          this.valuepropService.refreshDashboard.next('no_reload');
          this.bI.loadBenefits(this.benefit.id);
        }
      });
    this.showGrowthDecline = false;
    this.hideMetricLoader = false;
    this.loadBenefits(true);
  }

  changePhase(value, element) {
    this.metricbenefits[value] = element.from;
  }

  opendeleteCustomBenefit(content) {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  showCaseStudies(content) {
    this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });

    this.caseStudiesLoader = true;
    this.valuepropService
      .readCaseStudyByValuePropMetricId(this.valueProp.id, this.valueMetric.value_prop_metric_id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.benefitCaseStudies = response.result;

          this.benefitCaseStudies.forEach((data) => {
            if ( data.redacted === '1' ) {
              // Company should be redacted
              data.display_name = data.name;
            } else {
              data.display_name = data.company_name;
            }
          });

          this.caseStudiesLoader = false;
        }
      });
  }

  toggleSubTypesForm() {
    this.showSubtypes = !this.showSubtypes;
  }

  onHide() {
    this.showDetail = false;
    this.closeBenefitDetail.emit();
  }

  funcGetCaseStudiesLink(elem) {
    return '/case_study/' + elem.id + '/' + elem.is_account_solution + '/'+ this.valueProp.id;
  }

  phasingChanged(info: string): void {
    this.bI.triggerCache = true;
    if (info === 'all') {
      this.bI.loadBenefits('all');
    } else {
      this.bI.loadBenefits(this.benefit.id);
    }
    this.loadBenefits(true);
    this.reloadVP.emit();
  }

  public onShowGrowthDecline() {
    this.showGrowthDecline = !this.showGrowthDecline;
    this.updateDetailsPhasingModal();
  }

  public colClamp(columnLength: number) {
    return Math.min(columnLength + 1, 9);
  }

  scratchpadCallback(): void {
    this.bI.triggerCache = true;
    this.bI.loadBenefits(this.benefit.id);
    this.valuepropService.refreshDashboard.next('no_reload');
  }

  clearOverrides(r): void {
    let value_prop_metric_id = r['value_prop_metric_id'];
    let payload = {
      value_prop_metric_id: value_prop_metric_id,
    }
    this.valuepropService
      .clearOverrides(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result.errors) {
          this.notificationService.error(this.trans.trans.somethingWrong.value, false);
        } else {
          this.op.hide();
          this.notificationService.success(this.trans.trans.saveSuccess.value, false);
          this.valuepropService.refreshDashboard.next('no_reload');
          this.hasOverrides = false;
          this.hasPhasingOverrides = false;
          this.bI.loadBenefits(this.benefit.id);
        }
      });
  }

  cancelClear(): void {
    this.op.hide();
  }

  useHrefLink(caseStudy): boolean {
    if ( caseStudy.reference_url !== '' ) {
      // If there is a reference url, show a link no matter what
      return true;
    } else {
      // Reference url is the only way to get href
      return false;
    }
  }

  useRouterLink(caseStudy): boolean {
    if ( caseStudy.reference_url !== '' ) {
      // Reference url is not empty, we will show href so return false
      return false;
    } else {
      // If there is no reference url, check the disableCaseStudiesLink feature
      if ( this.disableCaseStudiesLink === true ) {
        // Links are disabled, return false
        return false;
      } else {
        return true;
      }
    }
  }

}
