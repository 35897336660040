import { Injectable } from '@angular/core';

@Injectable()
export class CaseStudySectionsTranslations {
  public config: any = {
    component: 'sectionsCasestudy',
    display_name: 'Case Study Section',
    description: 'Section of a case study',
  };

  public trans: any = {
    action: 'Action',
    add_media: 'Add Media',
    add_section: 'Add Section',
    add_section_type: 'Add Section Type',
    choose_section_type: 'Choose Section Type',
    delete: 'Delete',
    description: 'Description',
    download: 'Download',
    edit: 'Edit',
    edit_section: 'Edit Section',
    image: 'Image',
    isRequired: 'Is Required',
    media: 'Media',
    media_description: 'Media Description',
    name: 'Name',
    no_media_found: 'No Media Found',
    no_sections_found: 'No Sections Found',
    preview: 'Preview',
    section: 'Section:',
    section_type: 'Section Type',
    section_type_description: 'Section type description',
    section_type_name: 'Section type name',
    size_in_bytes: 'Size (in Bytes)',
    type: 'Type',
    update_media: 'Update Media',
    name_label: 'Name',
    save: 'Save',
    Update: 'Update',
  };
}
