<div class='row'>
	<div class='col-xs-12'>
		<div class="sidebar_wrapper" style='height: auto !important; position: relative;'>
			<div class="sidebar_header">
				Create Your Executive Value Perspective or Business Case
			</div>

			<div *ngIf="activeStep == 0" class="sidebar_container" style='margin-bottom: 0px !important;'>
				<div class="keywest_panel">
					<div class="controls radio-control">
						<label>
							<input type="radio" name="controloption" [(ngModel)]="rtOption" value="1" id="rep" />&nbsp;&nbsp;
							<span>Generate Executive Platform Perspective</span>
						</label>
						<label>
							<input type="radio" name="controloption" [(ngModel)]="rtOption" value="0" id="traditional" />&nbsp;&nbsp;
							<span>Generate Value Perspective / Business Case</span>
						</label>
					</div>
					<div class='row'>
						<div class='col-xs-12'>
							<button class='btn btn_customizable' (click)="optionsNavigate()">Create</button>
						</div>
					</div>
				</div>
			</div>

			<div class="sidebar_container" style='margin-bottom: 0px !important;' *ngIf="activeStep == 1">
				<app-search-companyv3 [cxo]='rtOption' (funcCallback)='funcGotoVPID($event)'></app-search-companyv3>
			</div>

			<div class="sidebar_container" style='margin-bottom: 0px !important;' *ngIf="activeStep == 2 && valueProp">
				<div class='row'>
					<div class='col-xs-4'>
						<app-rsv2-sc-account [valueProp]='valueProp'></app-rsv2-sc-account>
					</div>
					
					<div class='col-xs-4'>
						<a *ngIf="valueProp.cxo_asset == 1" (click)='downloadAsset()' class='btn btn_customizable'>Download Asset</a>
						<div *ngIf="valueProp.cxo_asset == 0" class='well well-info'>{{valueProp.cxo_asset_message}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>