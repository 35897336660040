<div class='rsv2-sc-component panel'>
	<div class='rsv2-sc-heading panel-heading'>
		<span>
			{{trans.trans.advanced_options.value}} 
			<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.advanced_options" (callback)="getTranslations()"></app-editTranslation>
			<i [ngClass]="{ 'icon-primary': style2022$ | async }" *ngIf="valueProp.closed == '0' && canEdit" (click)='openAdvancedSettings()' class='fa fa-pencil pull-right clickable'></i></span>
	</div>
	<div class='rsv2-sc-body panel-body' *ngIf="situation && situation.customer_type">

		<table style='width: 100%;' class='table-spaced'>
			<tr *ngIf="!feature64">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.BaselineType" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.BaselineType.value}}
				</td>
				<td class='param'>{{situation.baseline_type}}</td>
			</tr>
			<tr>
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Term" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.Term.value}} (Years)
				</td>
				<td class='param'>{{situation.term}}</td>
			</tr>


			<tr>
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.currency" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.currency.value}}
				</td>
				<td class='param' *ngIf="currency">
					{{situation.currency_type_id == "0" ? "US Dollar" + "(" + situation.conversion_rate + " Exchange Rate)" : currency.abbreviation + " (" + situation.conversion_rate + ") "}}
				</td>
			</tr>
		</table>

	</div>
</div>

<p-sidebar [(visible)]="showAdvancedEditing" *ngIf="showAdvancedEditing" position='right' styleClass="keywest_sidebar p-sidebar-lg" (onHide)="closeAdvanced()">
	<form *ngIf="situation" name="AddAdvancedForm" #AddAdvancedForm="ngForm">
		<div class="sidebar_wrapper">
			<div class='sidebar_header'>
				{{trans.trans.advanced_options.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.advanced_options" (callback)="getTranslations()"></app-editTranslation>
			</div>
			<div class='sidebar_container'>
				<div [ngClass]="{ 'no-shadow no-border': style2022$ | async }" class='keywest_panel'>
					<div class='row'>
						<div class="col-sm-12" *ngIf="feature20">
							<label>
								{{trans.trans.customer_type.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.customer_type" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<select [(ngModel)]="situation.customer_type_id" class="form-control role-dropdown" name="customerType">
								<option selected value="">{{trans.trans.choose_one.value}}</option>
								<option [selected]="customerType.id == situation.customer_type" *ngFor="let customerType of customerTypes" [value]="customerType.id">
									{{customerType.name}}
								</option>
							</select>
						</div>
					</div>
					<div class='row' *ngIf="!feature64">
						<div class="col-sm-12" [ngClass]="{'has-error': (AddAdvancedForm.controls.baselineType?.invalid && AddAdvancedForm.controls.baselineType?.touched)}">
							<label class="labelsRequired">
								{{trans.trans.baseline_type.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_baseline_type.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_baseline_type.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.baseline_type" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<select *ngIf="situation.baseline_type_id" [(ngModel)]="situation.baseline_type_id" class="form-control role-dropdown" name="baselineType" required>
								<option selected value="0" disabled>{{trans.trans.choose_one.value}}</option>
								<option [selected]="baselineType.id == situation.baseline_type.id" *ngFor="let baselineType of baselineTypes" [value]="baselineType.id">
									{{baselineType.name}}
								</option>
							</select>
						</div>
					</div>
					<div class='row'>
						<div class="col-sm-12">
							<label>
								{{trans.trans.cost_of_cap.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_cost_of_cap.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_cost_of_cap.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.cost_of_cap" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type="number" class="form-control" [(ngModel)]="situation.wacc" id="" name="costOfCapital" placeholder="{{trans.trans.cost_of_cap.value}}">
						</div>
					</div>

					<div class='row'>
						<div class="col-sm-12" [ngClass]="{'has-error': (AddAdvancedForm.controls.term?.invalid && AddAdvancedForm.controls.term?.touched)}">
							<label class="labelsRequired">
								{{trans.trans.term.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_term.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_term.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.term" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<select [(ngModel)]="term" class="form-control role-dropdown" name="term" required>
								<option selected value="0">{{trans.trans.choose_one.value}}</option>
								<option [selected]="advancedTerm.term == term ? 'selected' : ''" *ngFor="let advancedTerm of advancedTerms" [value]="advancedTerm.term">
									{{advancedTerm.name}}
								</option>
							</select>
						</div>
					</div>

					<div class='row'>
						<div class="col-sm-12">
							<label>
								{{trans.trans.deployment_time.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_deployment_time.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_deployment_time.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.deployment_time" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type="number" class="form-control" id="" [(ngModel)]="situation.deployment_time" name="deploymentTime" placeholder="Deployment Time">
						</div>
					</div>

					<div class='row'>
						<div class="col-sm-12" *ngIf="grossProfitFeature">
							<label>
								{{trans.trans.GrossProfitMargin.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_gross_profit_margin.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_gross_profit_margin.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.GrossProfitMargin" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type="number" class="form-control" id="" [(ngModel)]="situation.gross_profit" name="grossProgit" placeholder="Gross Profit Margin">
						</div>
					</div>

					<div class='row'>
						<div class="col-sm-12" *ngIf="opportunityIdFeature" [ngClass]="{'has-error': (AddAdvancedForm.controls.opportunity_id?.invalid && AddAdvancedForm.controls.opportunity_id?.touched)}">
							<label>
								{{trans.trans.opportunity_id.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_opportunity_id.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_opportunity_id.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.opportunity_id" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input class="form-control" [(ngModel)]="situation.opportunity_id" name="opportunity_id" >
						</div>
					</div>

					<div class='row'>
						<div class="col-sm-12" [ngClass]="{'has-error': (AddAdvancedForm.controls.number_format?.invalid && AddAdvancedForm.controls.number_format?.touched)}">
							<label>
								{{trans.trans.currency_format.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.currency_format" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<select class="form-control role-dropdown" name="number_format" type="text" [(ngModel)]="situation.number_format">
								<option [value]="type.id" *ngFor="let type of situation.number_format_types">{{ type.name}}
								</option>
							</select>
						</div>
					</div>

					<div class='row'>
						<div class="col-sm-12" [ngClass]="{'has-error': (AddAdvancedForm.controls.currency_type_id?.invalid && AddAdvancedForm.controls.currency_type_id?.touched)}">
							<label>
								{{trans.trans.currency_type_id.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_currency.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_currency.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.currency_type_id" (callback)="getTranslations()"></app-editTranslation>
							</label><br>
							<p-dropdown appendTo="body" name="currency" [autoDisplayFirst]='false' [options]="situation.currency_types" dataKey='id' optionLabel='name' [(ngModel)]="currencyId" (onChange)="setConversionRateField($event)" [filter]="true" class='keywest_dropdown'>
							</p-dropdown>
						</div>
					</div>

					<div class='row form-group'>
						<div class="col-sm-12" [ngClass]="{'has-error': (AddAdvancedForm.controls.conversion_rate?.invalid && AddAdvancedForm.controls.conversion_rate?.touched)}">
							<label>
								{{trans.trans.conversion_rate.value}}
								<i *ngIf="!(style2022$ | async)" class='fa fa-info-circle' [matTooltip]='trans.trans.ch_conversion_rate.value' matTooltipPosition="right"></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_conversion_rate.value" matTooltipPosition="right">
									info
								</span>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.conversion_rate" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type="number" class="form-control" [(ngModel)]="situation.conversion_rate" name="conversion_rate" required>
						</div>
					</div>

				</div>
			</div>
			<div [ngClass]="{'row': crm, 'btn_container': !crm}">
				<div class='col-xs-12'>
					<dl-progressbar class='dl_progress' *ngIf="loader" [showValue]='true' [value]='trans.trans.saving_options.value' mode="indeterminate"></dl-progressbar>
					<button class="btn btn_customizable pull-right" *ngIf="!loader" [disabled]="AddAdvancedForm.invalid" (click)="updateAdvanced()">{{trans.trans.save.value}}</button>
					<app-are-you-sure [ngClass]="{
						'cancel-confirm': style2022$ | async,
						'btn-light':  !(style2022$ | async)
						}" *ngIf="!loader && showAdvancedEditing" matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn pull-right" icon='' [text]='trans.trans.back.value' (callbackYes)='closeAdvanced()'>
					</app-are-you-sure>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>
