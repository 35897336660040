import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactorsService } from '@data/services/factors/factors.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { NotificationService } from '@services/notification.service';
import { ScalingOption } from '@shared/models/factors.models';
import { Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil } from 'rxjs/operators';
import { FactorScaledByTranslations } from './factor-scaled.translation';

@Component({
  selector: 'app-factor-scaled',
  templateUrl: './factor-scaled.component.html',
  styleUrls: ['./factor-scaled.component.scss'],
})
export class FactorScaledComponent implements OnInit, OnDestroy {
  @Input() factor: Factor;
  @Input() canEdit = true;

  @Output() callback = new EventEmitter();
  ngUnsubscribe$ = new Subject();
  showTranslate = false;
  accountId: string;
  transReady = false;

  scalesHow: Observable<ScalingOption[]>;
  scaledBy: Observable<ScalingOption[]>;

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    private factorsService: FactorsService,
    public trans: FactorScaledByTranslations,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.accountId = sessionStorage.getItem('aid');
    this.scalesHow = this.factorsService.getScalesHow().pipe(
      pluck('result', 'scaling_options'),
      map((scalers) =>
        scalers.map((scaler) => ({
          ...scaler,
          label: scaler.name,
          value: scaler.id,
        }))
      )
    );
    this.scaledBy = this.factorsService.getScaledBy(this.accountId).pipe(
      pluck('result'),
      map((scalers) =>
        scalers.map((scaler) => ({
          ...scaler,
          label: scaler.name,
          value: scaler.id,
        }))
      )
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.transReady = true;
      });
  }

  updateFactor(form: NgForm): void {
    const formValue = form.value;
    const payload = {
      account_factor_category_id: this.factor.account_factor_category_id,
      account_factor_id: this.factor.id,
      company_field: this.factor.company_field,
      description: this.factor.description,
      factor_precision: this.factor.factor_precision,
      greater_than_zero: this.factor.greater_than_zero,
      icon: this.factor.icon,
      increment: this.factor.increment,
      is_key_factor: this.factor.is_key_factor,
      literal_number: this.factor.literal_number,
      max: this.factor.max,
      min: this.factor.min,
      name: this.factor.name,
      formula: formValue.formula,
      ratio: formValue.ratio,
      unit_type_id: this.factor.unit_type_id,
      value_realization_name: this.factor.value_realization_name,
      scales_by: formValue.scale_type_id,
      scales_how: formValue.straight_scaling,
      scalers_page: this.factor.scalers_page ? this.factor.scalers_page : 0
    };

    this.factorsService
      .putSaveFactor(this.accountId, payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.callback.emit();
        this.notificationService.success('Factor updated successfully', false);
      });
  }
}
