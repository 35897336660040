import { Injectable } from '@angular/core';

@Injectable()
export class SimpleGuidedDiscoveryTranslation {
  public config: any = {
    component: 'Guided Discovery step 1',
    display_name: 'Guided Discovery value rep',
    description: '',
  };

  public trans: any = {
    selectGuided: 'Select your Guided Discovery Workflow',
    selectOne: 'Select one workflow',
    save: 'Save',
    next: 'Next'
  };
}
