import { NgModule } from '@angular/core';

import { CollapseContainerComponent } from './collapsible-container.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [CollapseContainerComponent],
  declarations: [CollapseContainerComponent],
})
export class CollapseContainerModule {}
