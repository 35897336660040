import { NgModule } from '@angular/core';

import { FactorsListComponent } from "./factors.component";
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { FactorTranslations } from './factors.translation';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		FactorsListComponent,
	],
	imports: [
		ProgressBarModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		MenuModule,
		TableModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		FactorTranslations
	],
	entryComponents: [
		FactorsListComponent,
	],
	exports: [FactorsListComponent]
})
export class FactorsListModule { }