
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';

@Component({
	selector: 'app-feed',
	templateUrl: './feed.component.html',
	styleUrls: ['./feed.component.scss'],
	providers: [ValuepropService]
})
export class FeedComponent implements OnInit {
	id: string;
	feeds: any[];
	fullImagePath: string;
	feedsLoader = false;
	script = {
		loaded: false,
		src: '../../../assets/plugins/vertical-timeline/js/main.js',
		type: 'text/javascript'
	}

	constructor(
		private route: ActivatedRoute	) {
		this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
	}

	ngOnInit() {

		this.feeds = [];

		this.route.params.pipe(
			filter(params => params.id))
			.subscribe(params => {
				this.id = params.id;
			});
	}


	loadAnimation() {
		if (!this.script.loaded) {
			//load script
			let script = document.createElement('script');
			script.type = this.script.type;
			script.src = this.script.src;
			document.getElementsByTagName('body')[0].appendChild(script);
			this.script.loaded = true;
		}
	}

}
