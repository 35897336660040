import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ErrorMessagesTranslations } from './error-messages.translation';
import { genericErrors } from '../_utils/get-generic-errors';
import { customErrors } from '../_utils/get-custom-errors';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessagesService {
  errorConstructor = new Map();
  errorTranslationsComplete$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private commonService: CommonService, private translationService: TranslationsV2Service, public trans: ErrorMessagesTranslations) {
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService.getComponentTrans(payload).subscribe((res) => {
      this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      this.createGenericErrors();
      this.createCustomErrors();
      this.errorTranslationsComplete$.next(true);
    });
  }

  createGenericErrors(): void {
    const errors = genericErrors(this.trans.trans);
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        this.errorConstructor.set(`${key}`, errors[key]);
      }
    }
  }

  /**
   * Custom error messages
   */
  createCustomErrors(): void {
    const errors = customErrors(this.trans.trans);
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        this.errorConstructor.set(`${key}`, errors[key]);
      }
    }
  }

  /**
   * Returns an error message dictionary for given error codes
   * Do not edit, this method is intended to be generic for all error types
   */
  getFormErrors(formControls: { [key: string]: AbstractControl }): Map<string, string> {
    if (this.errorConstructor.size === 0) {
      return;
    }
    const tempErrorsDict = new Map();
    Object.entries(formControls).forEach(([control, value]) => {
      if (value.errors) {
        const errorType = Object.keys(value.errors)[0];
        const errorFunction = this.errorConstructor.get(errorType);
        if (errorType === 'maxlength' || errorType === 'minlength') {
          tempErrorsDict.set(control, errorFunction(value.errors[errorType].requiredLength));
        } else {
          tempErrorsDict.set(control, errorFunction(value.errors[errorType][errorType]));
        }
      }
    });
    return tempErrorsDict;
  }

  getCrossFieldErrors(formGroup: FormGroup): Map<string, string> {
    if (this.errorConstructor.size === 0 || !formGroup.errors) {
      return;
    }
    const tempErrorsDict = new Map();
    Object.entries(formGroup.errors).forEach(([control, value]) => {
      const errorFunction = this.errorConstructor.get(control);
      tempErrorsDict.set('crossField', errorFunction(value));
    });
    return tempErrorsDict;
  }

  /**
   * Returns an observable for a given form control
   * The obserable returns the new error map directly
   */
  formContrlObsFactory(inputControl: FormControl, inputName: string, debounce = 300) {
    return inputControl.valueChanges.pipe(
      debounceTime(debounce),
      map(() => {
        return new Map(this.getFormErrors({ [inputName]: inputControl }));
      })
    );
  }

  /**
   * Returns an observable for a given form control
   * The obserable returns the new error map directly
   */
  formGroupObsFactoryCrossField(inputGroup: FormGroup, debounce = 300) {
    return inputGroup.valueChanges.pipe(
      debounceTime(debounce),
      map(() => {
        return new Map(this.getCrossFieldErrors(inputGroup));
      })
    );
  }
}
