import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-value-tracker-assets',
  templateUrl: 'assets.component.html',
})
export class ValueTrackerAssetComponent implements OnInit, OnDestroy {
  @Input() valuePropId: any;
  @Input() value_survey_id: any;

  ngUnsubscribe = new Subject();
  assets: any[] = [];
  assetUrl: any;
  assetGenerator: any;
  userId: string;
  token: string;

  constructor(private valuerealizationService: ValuerealizationService, private commonService: CommonService) {}

  ngOnInit() {
    this.assetUrl = this.commonService.getAssetUrl();
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    this.userId = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');

    this.funcGetAssets();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  funcGetAssets() {
    this.valuerealizationService
      .getValueTrackerAssets(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        let assets = res.result;
        for (let i = 0; i < assets.length; i++) {
          this.assets.push({
            label: assets[i].name,
            icon: this.getFAIcon(assets[i].format),
            url: this.getAssetURL(assets[i], false),
            pdf: this.getAssetURL(assets[i], true),
            showPDF: assets[i].pdf == '1' ? true : false,
            target: '_blank',
            description: assets[i].description,
            format: assets[i].format,
          });
        }
      });
  }

  getAssetURL(asset, pdf) {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    this.userId = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');
    if (pdf) {
      return (
        this.commonService.getAssetUrl() +
        'assets/' +
        this.assetGenerator +
        '?account_asset_template_id=' +
        asset.id +
        '&user_id=' +
        this.userId +
        '&token=' +
        this.token +
        '&value_prop_id=' +
        this.valuePropId +
        '&value_survey_id=' +
        this.value_survey_id +
        '&pdf=1'
      );
    } else {
      return (
        this.commonService.getAssetUrl() +
        'assets/' +
        this.assetGenerator +
        '?account_asset_template_id=' +
        asset.id +
        '&user_id=' +
        this.userId +
        '&token=' +
        this.token +
        '&value_prop_id=' +
        this.valuePropId +
        '&value_survey_id=' +
        this.value_survey_id
      );
    }
  }

  getFAIcon(format) {
    switch (format) {
      case 'MS Word':
        return 'fa fa-file-word-o';

      case 'MS Excel':
        return 'fa fa-file-excel-o';

      case 'MS PowerPoint':
        return 'fa fa-file-powerpoint-o';

      case 'MS PDF':
        return 'fa fa-file-pdf-o';

      default:
        return 'fa fa-cloud-download';
    }
  }

  getFAColor(format) {
    switch (format) {
      case 'MS Word':
        return { color: '#2e5b97' };

      case 'MS Excel':
        return { color: '#3a6e46' };

      case 'MS PowerPoint':
        return { color: '#c55737' };

      case 'MS PDF':
        return { color: '#c60a09' };

      default:
        return { color: 'black' };
    }
  }
}
