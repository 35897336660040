<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>{{trans.trans.cashflow.value}}
						<i *ngIf="!(style2022$ | async)" [matTooltip]='trans.trans.ch_cashflow.value' class='fa fa-info-circle'></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_cashflow.value">
							info
						</span>
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.cashflow" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class='row'>
		<div class='col-xs-12'>
			<div class="table-responsive" >
				<table id="cashflow" [ngClass]="years == 3 ? 'table custom-table' : 'table table7 custom-table'">
					<thead>
						<tr>
							<th style='padding-left: 15px !important;'>{{ trans.trans.name.value}}</th>
							<th class='text-right' *ngFor="let item of cols; let i = index">{{item.col}}</th>
							<th class='text-right' style='padding-right: 15px !important;'>{{ trans.trans.total.value }}<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='trans.total'></app-editTranslation></th>
							<!-- <th [ngClass]="years == 3 ? 'col5' : 'col7'">{{trans.trans.name.value}}<app-editTranslation *ngIf="showTranslate" [component]="cashflow" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th [ngClass]="years == 3 ? 'text-right col5' : 'text-right col7'">{{ trans.trans.year1.value }}<app-editTranslation *ngIf="showTranslate" [component]="cashflow" [isTranslationV2]="true" [transObj]="trans.trans.year1" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th [ngClass]="years == 3 ? 'text-right col5' : 'text-right col7'">{{ trans.trans.year2.value}}<app-editTranslation *ngIf="showTranslate" [component]="cashflow" [isTranslationV2]="true" [transObj]="trans.trans.year2" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th [ngClass]="years == 3 ? 'text-right col5' : 'text-right col7'">{{ trans.trans.year3.value}}<app-editTranslation *ngIf="showTranslate" [component]="cashflow" [isTranslationV2]="true" [transObj]="trans.trans.year3" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th *ngIf="years == 5" class="text-right col7">{{ trans.trans.year4.value}}<app-editTranslation *ngIf="showTranslate" [component]="cashflow" [isTranslationV2]="true" [transObj]="trans.trans.year4" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th *ngIf="years == 5" class="text-right col7">{{ trans.trans.year5.value}}<app-editTranslation *ngIf="showTranslate" [component]="cashflow" [isTranslationV2]="true" [transObj]="trans.trans.year5" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th [ngClass]="years == 3 ? 'text-right col5' : 'text-right col7'">{{ trans.trans.total.value}}<app-editTranslation *ngIf="showTranslate" [component]="cashflow" [isTranslationV2]="true" [transObj]="trans.trans.total" (callback)="getTranslations()"></app-editTranslation>
							</th> -->

						</tr>
					</thead>
					<tbody *ngIf="cashflow && cashflow!==null && cashflow.length>0">
						<tr *ngFor="let cashflw of cashflow" class="grid-row">
							<td>{{cashflw.name}}</td>
							<td *ngFor="let item of cols; let idx = index" class='text-right'>{{cashflw['Year' + (idx + 1) + "_p2"]}}</td>
							<!-- <td [ngClass]="years == 3 ? 'text-right col5' : 'text-right col7'">{{cashflw.Year1_fmt}}</td>
							<td [ngClass]="years == 3 ? 'text-right col5' : 'text-right col7'">{{cashflw.Year2_fmt}}</td>
							<td [ngClass]="years == 3 ? 'text-right col5' : 'text-right col7'">{{cashflw.Year3_fmt}}</td>
							<td *ngIf="years == 5" class="text-right col7">{{cashflw.Year4_fmt}}</td>
							<td *ngIf="years == 5" class="text-right col7">{{cashflw.Year5_fmt}}</td> -->
							<td class="text-right">{{cashflw.Total_p2}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="cashflowLoader" class="col-sm-12 m-t-md text-center">
				<!-- <img class="loader" src="{{fullImagePath}}" /> -->
			</div>
			<div *ngIf="(cashflow==null || cashflow.length==0) && !cashflowLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4>{{ trans.trans.no_cashflow_found.value}}</h4>
				</div>
			</div>
		</div>
	</div>
</div>