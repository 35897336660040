import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-track-value-cost-tab',
  templateUrl: './track-value-cost-tab.component.html',
  styleUrls: ['./track-value-cost-tab.component.scss']
})
export class TrackValueCostTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
