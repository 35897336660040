import { Injectable } from '@angular/core';

@Injectable()
export class ScenarioCombinedVPGTranslations {
  public config: any = {
    component: 'scenarioCombinedVPG',
    display_name: 'Define Scenario Step',
    description: 'The first step of rep simple with steps for setting model, situations and scalers',
  };

  public trans: any = {
    loadingScenarioDetails: 'Loading Scenario Details',
    valueFor: 'Value For:',
    advancedOptions: 'Advanced Options',
    deploymentTime: 'Deployment Time',
    areYouSure: 'Are you sure?',
    next: 'Next',
    CustomerType: 'Customer Type',
    ChooseOne: 'Choose One',
    BaselineType: 'Baseline Type',
    CostofCapital: 'Cost of Capital (%)',
    Term: 'Term',
    DeploymentTime: 'Deployment Type',
    costOfCapital: 'Cost of Capital',
    grossProfitMargin: 'Gross Profit Margin',
    GrossProfitMargin: 'Gross Profit Margin (%)',
    opportunity_id: 'Opportunity ID',
    currency_format: 'Number Format',
    currency_type_id: 'Currency Type',
    conversion_rate: 'Conversion Rate',
    save: 'Save',
    ch_baseline_type: 'Set all of your projected improvements to conservative, probable, or aggressive.  This will not effect any improvements you have manually overwritten.',
    ch_term: 'The length in years of evaluating the value to a customer.',
    ch_cost_of_capital: 'The internal interest rate assigned for a company; this helps to define the future value of money calculations like NPV.',
    ch_deployment_time: 'Number of months it will take the customer after contract signing to start achieving any value.',
    ch_opportunity_id: 'Your CRM opportunity ID which will help administrators report on attach rates of your Value Propositions.',
    ch_currency: 'The currency you would like your Value Proposition displayed.',
    ch_conversion_rate: 'The current conversion rate between your desired currency and the US Dollar.',
    ch_gross_profit_margin: 'The revenue achieved by a company minus the cost of goods sold.',
    account: 'Account',
    addr1: 'Address 1',
    addr2: 'Address 2',
    city: 'City',
    company_name: 'Company Name',
    country: 'Country',
    zip: 'Zip Code',
    employees: 'Employees',
    gross: 'Gross Profits',
    id: 'Account ID',
    optional: 'Optional Information',
    phone: 'Phone #',
    required: 'Required',
    revenue: 'Revenue',
    state: 'State',
    ticker: 'Ticker',
    website: 'Website URL',
    industry_sector: 'Industry Sector',
    industry: 'Industry',
    cancel: 'Cancel',
    accountInfo: 'Account Info',
    guidedDiscovery: 'Guided Discovery',
    back: 'Back'
  };
}
