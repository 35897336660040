import { heapIdentify, heapUserProperties } from 'app/analytics-scripts/heap-scripts';
import { setWalkmeVariales } from 'app/analytics-scripts/walkme-scripts';
import { setHeap } from 'app/analytics-scripts/heap';
import { setWalkMe } from 'app/analytics-scripts/walkme';
import { userflowIdentify } from './userflow-scripts';
import { userflowInit } from './userflow';

export function setupAnalytics(): void {
  const win = window as Window & typeof globalThis & { analytics_started: any };

  if (typeof win.analytics_started === 'undefined') {
    win.analytics_started = true;
    localStorage.setItem('AnalyticsSession', 'on');
    setHeap();
    setWalkMe();
    userflowInit();
    startAnalyticsSession();
  }
}

export function startAnalyticsSession(): void {
  let uuid = sessionStorage.getItem('uuid');
  let user_name = sessionStorage.getItem('username');
  let email = sessionStorage.getItem('email');
  let account_name = sessionStorage.getItem('account_name');
  let manager_email = sessionStorage.getItem('manager_email');
  let role_type = sessionStorage.getItem('role');
  let activePrivileges = sessionStorage.getItem('active_privileges');

  const properties = {
    userName: user_name,
    userEmail: email,
    account: account_name,
    managerEmail: manager_email,
    userGroup: ['undefined'].join(),
    role: role_type,
    activePrivileges: activePrivileges
  };
  heapIdentify(uuid);
  heapUserProperties(properties);
  userflowIdentify(email, properties);
  setWalkmeVariales(uuid, properties);
}

