<form name="addBenefitForm" #addBenefitForm="ngForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebar_title.value }}
    </div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-xs-4" *ngIf="mode !== 'edit'">
            <div class="input-group keywest_search">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input name="search" [(ngModel)]="searchText" type="text" placeholder="{{ trans.trans.search.value }}" class="form-control" />
              <span *ngIf="searchText !== ''" (click)="searchText = ''" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-4" *ngIf="mode !== 'edit'">
            <div class="case_study_benefits_list_container">
              <div class="row case_study_benefits_list" *ngFor="let benefit of BenefitsList | filterBenefits: searchText:'metric_name'">
                <div class="col-xs-9">{{ benefit.metric_name }}</div>
                <div class="col-xs-3 clickable" *ngIf="!benefit.disabled">
                  <a (click)="addBenefitToSelected(benefit)" class="pull-right case_study_btn_add">{{ trans.trans.add.value }}</a>
                </div>
              </div>
            </div>
          </div>

          <div [ngClass]="{ 'col-xs-8': mode === 'add', 'col-xs-12': mode === 'edit' }">
            <div class="case_study_benefits_list_container table">
              <div class="row case_study_benefits_list">
                <div class="col-xs-7">
                  {{ trans.trans.selected.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.selected"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
                <div class="col-xs-2">
                  {{ trans.trans.type.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.type"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
                <div class="col-xs-2">
                  {{ trans.trans.improvement.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.improvement"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
                <div class="col-xs-1"></div>
              </div>

              <div class="row case_study_benefits_list" *ngFor="let benefit of selectedBenefits; let i = index">
                <!-- add -->

                <div *ngIf="mode === 'add'">
                  <form name="benefitsAdded" #benefitsAdded="ngForm">
                    <div class="col-xs-7" appNGError [elemControl]="addBenefitForm.controls.benefitsArray">
                      {{ benefit.metric_name }}
                    </div>
                    <div class="col-xs-2" appNGError [elemControl]="benefitsAdded.controls.benefitImpType">
                      <select [(ngModel)]="benefit.benefitImpType" class="form-control role-dropdown" name="benefitImpType" ret-data="Benefit Imp Type" required>
                        <option *ngFor="let impType of impTypesList" [value]="impType.id" [selected]="impType.id === benefit.benefitImpType">
                          {{ impType.abbr }}
                        </option>
                      </select>
                    </div>
                    <div class="col-xs-2" appNGError [elemControl]="benefitsAdded.controls.benefitImpValue">
                      <input name="benefitImpValue" type="text" [(ngModel)]="benefit.benefitImpValue" class="form-control" ret-data="Benefit Imp value" required />
                    </div>
                    <div class="col-xs-1">
                      <a (click)="selectBenefitToRemove(i, benefit)"><i class="fa fa-times"></i></a>
                    </div>
                  </form>
                </div>

                <!-- edit -->

                <div *ngIf="mode === 'edit'">
                  <div class="col-xs-7" appNGError [elemControl]="addBenefitForm.controls.benefitsArray">
                    {{ benefit.metric_name }}
                  </div>
                  <div class="col-xs-2" appNGError [elemControl]="addBenefitForm.controls.benefitImpType">
                    <select [(ngModel)]="benefit.benefitImpType" class="form-control role-dropdown" name="benefitImpType" ret-data="Benefit Type" required>
                      <option *ngFor="let impType of impTypesList" [value]="impType.id" [selected]="impType.id === benefit.benefitImpType">
                        {{ impType.abbr }}
                      </option>
                    </select>
                  </div>
                  <div class="col-xs-2" appNGError [elemControl]="addBenefitForm.controls.benefitImpValue">
                    <input name="benefitImpValue" type="text" [(ngModel)]="benefit.benefitImpValue" class="form-control" ret-data="Benefit Improvement value" required />
                  </div>
                </div>
              </div>
            </div>
            <input type="hidden" name="benefitsArray" ret-data="Add Benefits" [(ngModel)]="isBenifitsSelected" required />
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <div class="col-md-12">
        <input type="hidden" name="company_id" [(ngModel)]="benefitsForm.company_id" />
        <input type="hidden" name="case_study_id" [(ngModel)]="benefitsForm.case_study_id" />
        <input type="hidden" name="case_study_benefit_id" [(ngModel)]="benefitsForm.case_study_benefit_id" />
        <button *ngIf="mode == 'add'" class="btn btn_customizable pull-right" (click)="benefitsSave(addBenefitForm)">{{ trans.trans.save.value }}</button>
        <app-btn-save
          *ngIf="mode == 'edit'"
          class="btn btn_customizable pull-right"
          [form]="addBenefitForm"
          (callback)="editBenefit()"
          [text]="trans.trans.update.value"
        ></app-btn-save>
        <app-are-you-sure
          matTooltipPosition="above"
          [message]="trans.trans.are_you_sure.value"
          class="btn cancel-confirm pull-right marg-h"
          icon=""
          [text]="trans.trans.back.value"
          (callbackYes)="close()"
        ></app-are-you-sure>
      </div>
    </div>
  </div>
</form>
