<div class="row m-b-md">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs *ngIf="breadcrumbKeyValues" [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
  <div class="pull-right mr-5">
    <app-help-tooltip-value-realized [stepIndex]="0"></app-help-tooltip-value-realized>
  </div>
</div>
<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title">
            {{ trans.trans.valueTrackersByAccount.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.headerTooltip.value"> info </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-b-md">
    <div class="col-xs-12 col-md-3">
      <div id="case-search" class="input-group keywest_search">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
        <input
          [(ngModel)]="strSearch"
          (keyup)="tableSolutionList.filter($any($event).target.value, 'company_name', 'contains')"
          type="text"
          [placeholder]="trans.trans.search.value"
          class="form-control"
        />
        <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableSolutionList.filter('', 'company_name', 'contains')" class="input-group-addon clickable"
          ><i class="fa fa-times"></i
        ></span>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <p-selectButton
        [options]="sharedWith"
        [(ngModel)]="creator_filter"
        (onChange)="getValueRealizationWithCompany($event.value)"
        styleClass="keywest_select_button"
      ></p-selectButton>
    </div>
    <div class="col-xs-12 col-md-5" *ngIf="showAddButton">
      <button
        (click)="addNewSidebar = true"
        class="btn btn_customizable pull-right"
        [matTooltip]="trans.trans.addTooltip.value"
        matTooltipPosition="below"
        container="body"
        placement="bottom"
      >
        <i class="fa fa-plus"></i>{{ trans.trans.add.value }}
      </button>
    </div>
  </div>

  <div class="models_page_header_container_small" [ngClass]="{ 'full-height': (tableRecords$ | async) === 0 }">
    <div class="row">
      <div class="col-xs-12">
        <p-progressBar *ngIf="loading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

        <p-table
          #tableSolutionList
          [value]="realizationsCompanies"
          [columns]="columns"
          [paginator]="true"
          [rows]="10"
          styleClass="keywest_table"
          tableStyleClass="keywest_table"
          [sortField]="'created'"
          [sortOrder]="-1"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns; index as i" [pSortableColumn]="col.field" [class.centered]="i !== 0">
                {{ trans.trans[col.field].value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tracker>
            <tr>
              <td>
                <span class="link" (click)="navToDetails(tracker)" class="clickable">{{ tracker.company_name }}</span>
              </td>
              <td class="centered">
                <span>${{ tracker.expected_fr }}</span>
              </td>
              <td class="centered">
                <span>${{ tracker.realized_fr }}</span>
              </td>
              <td class="centered">
                <span [class]="tracker.percentage> 0 ? 'achieved' : tracker.percentage < 0 ? 'loss' : ''">
                  <i
                    class="fa"
                    [class]="
                      tracker.percentage > 0 ? 'fa-arrow-up' : tracker.percentage < 0 ? 'fa-arrow-down' : 'fa-minus'
                    "
                  ></i>
                  {{ tracker.percentage }} %</span
                >
              </td>
              <td class="number-trackers centered">
                <span>{{ tracker.number_of_trackers }}</span>
              </td>
              <td class="number-trackers centered">
                <span>{{ tracker.trackers_in_rollup }}</span>
              </td>
              <td class="centered">
                <span>{{ tracker.created === '0000-00-00 00:00:00' ? '' : (tracker.created | safariDate | date: 'mediumDate') }}</span>
              </td>
              <td class="centered">
                <span>{{ tracker.last_measurement_date | safariDate | date: 'LLL yy' }}</span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading" class="not-hover">
              <td [attr.colspan]="columns.length" style="padding-top: 100px">
                <app-no-records width="200px" [records]="realizationsCompanies.length > 0">
                  <ng-container *ngIf="realizationsCompanies.length === 0">
                    {{ trans.trans.noRecords.value }}
                  </ng-container>
                  <ng-container *ngIf="realizationsCompanies.length > 0"> {{ trans.trans.noResults.value }} "{{ strSearch }}" </ng-container>
                </app-no-records>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<app-value-realized-flyout (onHide)="addNewSidebar = $event" [addNewSidebar]="addNewSidebar"></app-value-realized-flyout>
