import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '@data/services/company/company.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { UserService } from '@data/services/user/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Translations } from '@data/services/translation/translation.service';
import { Company } from '@shared/models/company.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  providers: [CompanyService, FactsService],
})
export class CompanyComponent implements OnInit, OnDestroy {
  companyId: number;
  company: Company;
  feedback: Array<{ [klass: string]: any }>;
  showDetail = false;
  fullImagePath: string;
  currentRate = 0;
  modalReference: NgbModalRef;
  sectors: { [klass: string]: any }[];
  industries: { [klass: string]: any }[];
  rating = 0;
  profilepicdefaultpath: string;
  hideActionLoader = true;
  industry: string;
  sector: string;
  imageUrl: string;

  ratingsCount = 0;
  reviewsCount = 0;
  userCredits = 0;
  currentIndustryName: { [klass: string]: any }[];
  currentSectorName: { [klass: string]: any }[];
  downloads: { [klass: string]: any }[];
  showAddReview = false;
  hash = '';
  scbDownloading = true;

  subscriptiongetCompanyDetail: Subscription;
  subscriptiongetCompanyReview: Subscription;
  subscriptiongetSectors: Subscription;
  subscriptiongetIndustry: Subscription;
  subscriptionaddReview: Subscription;
  subscriptiongetUserCredits: Subscription;
  subscriptionSCBHashCreate: Subscription;
  subscriptionhasSCBHashAlready: Subscription;
  subscriptionspendCredit: Subscription;
  subscriptionSCBDownloadRead: Subscription;
  subscriptionselectSectors: Subscription;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private factsService: FactsService,
    private commonService: CommonService,
    private userService: UserService,
    private router: Router,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    this.companyId = this.route.params['value']['id'];

    this.imageUrl = this.commonService.getImageUrl();

    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';

    this.profilepicdefaultpath = this.imageUrl + 'images/default_user.png';
  }

  ngOnInit(): void {
    this.getCompanyDetail();
    this.getCompanyReview();
    this.showAddReviewLink();
    this.getUserCredits();
  }

  ngOnDestroy(): void {
    if (this.subscriptiongetCompanyDetail) {
      this.subscriptiongetCompanyDetail.unsubscribe();
    }
    if (this.subscriptiongetCompanyReview) {
      this.subscriptiongetCompanyReview.unsubscribe();
    }
    if (this.subscriptiongetSectors) {
      this.subscriptiongetSectors.unsubscribe();
    }
    if (this.subscriptiongetIndustry) {
      this.subscriptiongetIndustry.unsubscribe();
    }
    if (this.subscriptionaddReview) {
      this.subscriptionaddReview.unsubscribe();
    }
    if (this.subscriptiongetUserCredits) {
      this.subscriptiongetUserCredits.unsubscribe();
    }
    if (this.subscriptionSCBHashCreate) {
      this.subscriptionSCBHashCreate.unsubscribe();
    }
    if (this.subscriptionhasSCBHashAlready) {
      this.subscriptionhasSCBHashAlready.unsubscribe();
    }
    if (this.subscriptionspendCredit) {
      this.subscriptionspendCredit.unsubscribe();
    }
    if (this.subscriptionSCBDownloadRead) {
      this.subscriptionSCBDownloadRead.unsubscribe();
    }
    if (this.subscriptionselectSectors) {
      this.subscriptionselectSectors.unsubscribe();
    }
  }

  getCompanyDetail(): void {
    this.subscriptiongetCompanyDetail = this.companyService.getCompanyDetail(this.companyId).subscribe((result) => {
      this.company = result.result.company;

      this.industry = this.company['dl_industry_id'];
      this.sector = this.company['dl_sector_id'];
      this.hasSCBHashAlready();
      this.getSectors();
      this.getIndustries(this.industry);
      this.showDetail = true;
    });
  }

  getCompanyReview(): void {
    this.subscriptiongetCompanyReview = this.companyService.getCompanyReview(this.companyId).subscribe((result) => {
      this.currentRate = result.result.average;
      this.feedback = result.result.feedback;
      this.getReviewRating();
    });
  }

  open(content: any, industry: string, sector: string): void {
    this.industry = industry;
    this.sector = sector;
    this.getIndustries(this.industry);
    this.modalReference = this.modalService.open(content, { windowClass: 'comparisonModal', backdrop: 'static', keyboard: false });

    this.modalReference.result.then(
      () => {},
      () => {
        this.rating = 0;
      }
    );
  }

  getSectors(): void {
    this.subscriptiongetSectors = this.factsService.getSectors().subscribe((result) => {
      this.sectors = result.result;
    });
  }

  getIndustries(industry: string): void {
    this.subscriptiongetIndustry = this.factsService.getIndustry().subscribe((result) => {
      this.industries = result.result;
      this.getCurrentIndustry(industry);
    });
  }

  getCurrentIndustry(industry_id: string): void {
    this.currentIndustryName = this.industries.filter((val: { [klass: string]: any }) => {
      // tslint:disable-next-line: triple-equals
      return val.id == industry_id ? val : false;
    });
    if (this.currentIndustryName[0] !== undefined) {
      this.sector = this.currentIndustryName[0]['dl_sector_id'];
      this.getCurrentSector(this.sector);
    }
  }

  // Get current sector
  getCurrentSector(dl_sector_id: string): void {
    this.currentSectorName = this.sectors.filter((val) => {
      // tslint:disable-next-line: triple-equals
      return val.id == dl_sector_id ? val : false;
    });
  }

  addReview(comments: string): void {
    this.hideActionLoader = false;

    const payload = {
      score: this.rating,
      comment: comments,
    };

    this.subscriptionaddReview = this.companyService.addReview(this.companyId, payload).subscribe((result) => {
      this.rating = 0;
      this.currentRate = result.result.average;
      this.feedback = result.result.feedback;
      this.hideActionLoader = true;
      this.modalReference.close();
      this.getReviewRating();
    });
  }

  getReviewRating(): void {
    this.ratingsCount = this.feedback.length;
    if (this.feedback) {
      this.feedback.map((feed) => {
        if (feed['comment'] !== '') {
          ++this.reviewsCount;
        }
      });
    }
  }

  getUserCredits(): void {
    this.subscriptiongetUserCredits = this.companyService.getUserCredits().subscribe((result) => {
      this.userCredits = Number(result.result.credits);
    });
  }

  ngOnSubmit(form: NgForm): void {
    if (form.valid) {
      this.hideActionLoader = false;
      const payload = {
        industry_id: form.value.industry,
        company_id: this.companyId,
        sector_id: form.value.sector,
      };
      this.subscriptionSCBHashCreate = this.userService.SCBHashCreate(payload).subscribe((result) => {
        this.hash = result.result.hash;
        this.hideActionLoader = true;
        this.modalReference.close();
      });
    }
  }

  hasSCBHashAlready(): void {
    const uid = sessionStorage.getItem('uid');

    this.subscriptionhasSCBHashAlready = this.userService.hasSCBHashAlready(uid, this.companyId).subscribe((response) => {
      if (response.result && response.result.success) {
        this.hash = response.result.hash;
      } else {
      }
    });
  }

  createSCBHash(): void {
    const payload = {
      industry_id: 0,
      company_id: this.companyId,
      sector_id: 0,
    };

    if (this.sector != null) {
      payload.sector_id = Number(this.sector);
    }
    if (this.industry != null) {
      payload.industry_id = Number(this.industry);
    }

    this.subscriptionSCBHashCreate = this.userService.SCBHashCreate(payload).subscribe((result) => {
      this.hash = result.result.hash;

      this.downloadReport();
    });
  }

  redirectToPlans(): void {
    if (this.userCredits > 0) {
      if (this.hash !== '') {
        const service_url = this.commonService.getServiceUrl();

        this.scbDownloading = true;
        this.showAddReview = true;
        window.location.assign(service_url + 'smart_company_brief.php?hash=' + this.hash + '&user_id=' + sessionStorage.getItem('uid'));
      } else {
        this.createSCBHash();
      }
    } else {
      this.router.navigate(['/plans'], { queryParams: { id: this.companyId } });
    }
  }

  downloadReport(): void {
    this.scbDownloading = false;

    const payload = {
      company_id: this.companyId,
      credits: 1,
    };
    this.subscriptionspendCredit = this.userService.spendCredit(payload).subscribe((res) => {
      const service_url = this.commonService.getServiceUrl();
      if (res.result.credits) {
        this.userCredits = res.result.credits;
      }
      this.scbDownloading = true;
      this.showAddReview = true;
      window.location.assign(service_url + 'smart_company_brief.php?hash=' + this.hash + '&user_id=' + sessionStorage.getItem('uid'));
    });
  }

  showAddReviewLink(): void {
    this.subscriptionSCBDownloadRead = this.userService.SCBDownloadRead().subscribe((res) => {
      this.downloads = res.result.downloads;
      if (this.downloads) {
        this.downloads.forEach((item: { [klass: string]: any }) => {
          // tslint:disable-next-line: triple-equals
          if (item.company_id == this.companyId) {
            this.showAddReview = true;
          }
        });
      }
    });
  }

  selectSector(param: string): void {
    this.subscriptionselectSectors = this.factsService.selectSectors(param).subscribe((result) => {
      this.industries = result.result;
    });
  }
}
