import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription, Subject } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { BenefitsScratchpadTranslations } from './scratchpad.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { concatMap, finalize, take, tap } from 'rxjs/operators';
import { UserService } from '@data/services/user/user.service';
import { getSeparatorByLocale } from '@shared/utils';
import { Goal, ValuePropAddGoal, TrackerGoalScratchpadPayload } from '@shared/models/goal-group.model';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { CostResponse } from '@data/services/valueprop/models/cost.model';
import { Metric } from '@shared/models/value-prop.model';
import { BusinessFactor, SurveyFactor } from '@data/services/goal-groups/models/business-goals-response.interface';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { ReadScratchpadResult, ScratchpadUnit } from '@data/services/valueprop/models/scratchpad.model';
import { ScracthpadOperatorType } from '@shared/models/facts.models';

@Component({
  selector: 'app-scratchpad-component',
  templateUrl: './scratchpad.component.html',
  styleUrls: ['./scratchpad.component.scss'],
})
export class ScratchpadComponentV2 implements OnInit, OnDestroy {
  @Input() factorTypeId: string;
  @Input() valuePropId: string;
  @Input()
  set valueMetric(val: Goal | Metric | Partial<Metric> | BusinessFactor) {
    this._valueMetric = val;
    if (this.valuePropId) {
      this.getScratchpadDetail();
    }
  }
  get valueMetric() {
    return this._valueMetric;
  }

  _valueMetric;
  @Input() valueMetricId: string;
  @Input() Cost: CostResponse;
  @Input() mini = false;
  @Input() goal = false;
  @Input() sidebarStyle = false;
  @Input() trackerDetail = false;
  @Input() surveyFactor: SurveyFactor;
  @Input() manualUpdate = false;
  @Input() year = 0;
  @Output() SaveAndUpdateCallback = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  @Output() benefitUpdated = new EventEmitter();

  scratchPadName: string;
  scratchpadFactorName: string;
  scratchpadList: ReadScratchpadResult;
  isScratchPad: boolean = false;
  fullImagePath: string;
  total = '';
  hideActionLoader: boolean = true;
  operatorList: Array<ScracthpadOperatorType>;
  subscriptionreadDriverFactorScratchPad: Subscription;
  subscriptionscratchpadOperatorTypes: Subscription;
  subscriptionreadScratchpadTotal: Subscription;
  subscriptionupdateScratchpad: Subscription;

  showTranslate = false;
  ngUnsubscribe = new Subject();
  userLocale: string;
  decimalSeparator: string;
  groupSeparator: string;
  loading = false;

  @Input() totalColumns = 1;
  colWidth = '';
  @Input() locked = false;
  @Input() nameEditable = false;

  currentYear: Array<{ value: string; override: boolean; key: string; factor_id?: string; }> = [];
  yrOperand: Array<{ value: string; override: boolean; key: string }> = [];;
  hasScratchpadOverrides: Boolean = false;

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: BenefitsScratchpadTranslations,
    private userService: UserService,
    private goalGroupsService: GoalGroupsService,
    private valuerealizationService: ValuerealizationService
  ) {
    this.fullImagePath = this.commonService.getLoaderImageUrl();
  }

  ngOnInit() {
    this.getScratchpadDetail();
    this.scratchpadOperatorTypes();
    this.userLocale = this.userService.getUserLanguage();
    this.decimalSeparator = getSeparatorByLocale(this.userLocale, 'decimal');
    this.groupSeparator = getSeparatorByLocale(this.userLocale, 'group');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(take(1)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(take(1)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.colWidth = this.totalColumns * 120 + 'px';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getScratchpadDetail() {
    this.currentYear = [];
    let factor_id;
    if (+this.factorTypeId === 1) {
      this.scratchPadName = 'Driver Factor Scratchpad';
      factor_id = (this.valueMetric as Metric).driver_factor_id;
    } else if (+this.factorTypeId === 2) {
      this.scratchPadName = 'Financial Factor Scratchpad';
      factor_id = (this.valueMetric as Metric).financial_factor_id;
    } else if (+this.factorTypeId === 3) {
      this.scratchPadName = 'Improvement Factor Scratchpad';
      factor_id = (this.valueMetric as Metric).improvement_factor_id;
    } else if (+this.factorTypeId === 4) {
      this.scratchPadName = 'Costs Scratchpad';
      if (this.Cost) {
        factor_id = this.Cost.cost_type_id;
      }
      if (this.valueMetric) {
        factor_id = (this.valueMetric as Metric).improvement_factor_id;
      }
    } else if (this.goal) {
      factor_id = (this.valueMetric as Goal).account_factor_id;
    }

    if (!factor_id) {
      return;
    }
    this.loading = true;

    const payload =
      this.valuePropId +
      '/valuePropMetricID/' +
      (this.valueMetricId ? this.valueMetricId : this.valueMetric ? this.valueMetric['id'] : 0) +
      '/scratchpadFactorTypeID/' +
      this.factorTypeId +
      '/scratchpadFactorID/' +
      factor_id +
      '/testing123/0';
    if (this.trackerDetail && this.surveyFactor) {
      this.valuerealizationService
        .getBusinessReviewScratchpad(this.valuePropId, this.surveyFactor.scratchpad_id)
        .pipe(
          take(1),
          finalize(() => (this.loading = false))
        )
        .subscribe((response) => {
          if (response.result) {
            this.isScratchPad = true;
            this.scratchpadList = response.result;
            this.scratchpadList.units = response.result.units.map((u) => ({
              ...u,
              originalValue: u.operand,
            }));

            this.scratchpadFactorName = response.result.name;
            this.saveAndCalcScratchpad('0', true);
          }
        });
    } else {
      this.valuepropService
        .readDriverFactorScratchPad(payload)
        .pipe(
          take(1),
          finalize(() => (this.loading = false))
        )
        .subscribe((response) => {
          if (response.result) {
            this.isScratchPad = true;
            this.scratchpadList = response.result;
            this.scratchpadList.units = response.result.units.map((u) => ({
              ...u,
              operator_id: Number(u.operator_id),
              originalValue: u.operand,
              nameEdition: false,
            }));

            this.scratchpadFactorName = response.result.scratchpad.factor_name;
            this.saveAndCalcScratchpad('0', true);
            for (let i = 0; i < this.scratchpadList.units.length; i++) {
              const yrs = this.scratchpadList.units[i]['years']; 
              Object.keys(yrs).forEach((key) => {
                if (yrs[key]['key'] === 'year' + this.year) {
                  this.currentYear.push(yrs[key]);
                  if (yrs[key]['override']) {
                    this.hasScratchpadOverrides = true;
                  }
                }
              });

            }
            
           
          }
        });
    }
  }

  scratchpadOperatorTypes() {
    this.valuepropService
      .scratchpadOperatorTypes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.operatorList = response.result;
        }
      });
  }

  getYearlyScratchpadPayload() {
    let factors = [];

    if (this.scratchpadList && this.scratchpadList['units']) {
      const tempList = this.scratchpadList['units'];

      tempList.forEach((obj, i) => {
        const yearsUpdated = obj['years'].filter((y) => y.updated === true);

        yearsUpdated.forEach((year) => {
          const newFactor = {
            'account_factor_id': obj.factor_id,
            'value': this.currentYear[i].value,
            'year': year.key.replace('year', '')
          }

          factors.push(newFactor);
        });
      });

      const payload = {
        'factors': factors
      };

      return payload;
    }
  }

  getscratchpadPayload(mode: string) {
    let scratchpad_factor_id;
    let value_prop_metric_id = '';
    const factor = [];
    const operator = [];
    const openendValue = [];
    const openParen = [];
    const closeParen = [];


    if (+this.factorTypeId === 2) {
      scratchpad_factor_id = (this.valueMetric as Metric).financial_factor_id;
    } else if (+this.factorTypeId === 1) {
      scratchpad_factor_id = (this.valueMetric as Metric).driver_factor_id;
    } else if (+this.factorTypeId === 3) {
      value_prop_metric_id = this.valueMetric.id as string;
      scratchpad_factor_id = (this.valueMetric as Metric).improvement_factor_id;
    } else if (+this.factorTypeId === 4) {
      scratchpad_factor_id = this.Cost.cost_type_id;
      value_prop_metric_id = '0';
    } else if (+this.factorTypeId === 0) {
      scratchpad_factor_id = this.scratchpadList?.factor_id ?? this.scratchpadList.scratchpad.factor_id;
    }

    if (this.scratchpadList && this.scratchpadList['units']) {
      const tempList = mode === '1' ? this.scratchpadList['units'].filter((u) => u.operand !== u.originalValue) : this.scratchpadList['units'];

      if ( this.goal ) {
        // We are in a VR so we don't have yearly values 
        tempList.forEach((obj) => {
          this.currentYear.push({
            value: obj.operand, 
            override: false, 
            key: 'year1',
            factor_id: obj.factor_id
          });

          factor.push(obj.factor_id);
          operator.push(obj.operator_id);
          openendValue.push(obj.operand);
          openParen.push(obj.open_paren);
          closeParen.push(obj.close_paren);
        });

      } else {
        // We're not in VR so we can check yearly values
        tempList.forEach((obj, i) => {
          const yrs = this.scratchpadList.units[i]['years']; 
          Object.keys(yrs).forEach((key) => {
            if (yrs[key]['key'] === 'year' + this.year) {
                this.yrOperand.push(yrs[key]);
            }
          });

          factor.push(obj.factor_id);
          operator.push(obj.operator_id);
          openendValue.push(this.yrOperand[i]['value']);
          openParen.push(obj.open_paren);
          closeParen.push(obj.close_paren);
        });

      }

    }

    const payload = {
      scratchpad_factor_type_id: this.factorTypeId,
      value_prop_metric_id: value_prop_metric_id,
      factor_id: factor.join(','),
      operator_id: operator.join(','),
      operand: openendValue.join(','),
      open_paren: openParen.join(','),
      close_paren: closeParen.join(','),
      scratchpad_factor_id: scratchpad_factor_id,
    };

    return payload;
  }

  scratchpadYearUpdated(year, unit) {
    if ( this.goal ) {
      // We are in a VR, we need to update the scratchpadList
      const unit_id = unit.factor_id;
      const updated_value = year.value;

      for (let i = 0; i < this.scratchpadList.units.length; i++) {
        if ( this.scratchpadList.units[i]['factor_id'] == unit_id ) {
          // This is the unit we need to update
          this.scratchpadList.units[i]['operand'] = updated_value;
        }
      }

      // The ngModel is tied to currentYear array so we need to update that
      // to show the changes in the UI
      for (let i = 0; i < this.currentYear.length; i++) {
        if ( this.currentYear[i]['factor_id'] == unit_id ) {
          // This is the unit we need to update
          this.currentYear[i]['value'] = updated_value;
        }
      }

    } else {
      // Not in VR, we need to track years
      for (let i = 0; i < this.scratchpadList.units.length; i++) {
        const yrs = this.scratchpadList.units[i]['years']; 
        Object.keys(yrs).forEach((key) => {
          if (yrs[key]['key'] === 'year' + this.year) {
            if (yrs[key]['value'] !== year.value) {
              if ( this.scratchpadList.units[i].id === unit.id ) {
                yrs[key]['value'] = year.value;
                yrs[key].updated = true;
              
                this.scratchpadList.units[i].updated = true;
                this.scratchpadList.units[i]['years'][key] = yrs[key];
              }
               
            }
          }
        });
      }
    }
  }

  saveAndCalcScratchpad(mode: string, stay = false) {
    this.hideActionLoader = false;
    let payload = this.getscratchpadPayload('0');
    const readScratchpadTotal = this.valuepropService.readScratchpadTotal(this.valuePropId, payload).pipe(
      finalize(() => (this.hideActionLoader = true)),
      take(1),
      tap((response) => {
        if (response.result.total) {
          this.total = response.result.total;
          if (!stay) {
            this.SaveAndUpdateCallback.emit(payload.scratchpad_factor_id);
          }
        } else {
          this.total = response.result.total ?? response.result.message ?? '';
        }
      })
    );

    if (mode == '0') {
      readScratchpadTotal.subscribe();
    } else if (this.goal) {
      if (this.trackerDetail && this.surveyFactor) {
        const goalPayloads = this.buildTrackerScratchpadPayload();
        this.valuerealizationService
          .updateBusinessReviewFactors(this.valuePropId, this.surveyFactor.business_review_id, goalPayloads)
          .pipe(
            finalize(() => (this.hideActionLoader = true)),
            tap(() => {
              this.hideActionLoader = true;
              this.SaveAndUpdateCallback.emit(true);
            })
          )
          .subscribe();
      } else {
        const goalPayloads = this.buildGoalsPayload();
        const updateScratchpad = this.goalGroupsService.addValuePropGoals(this.valuePropId, goalPayloads).pipe(
          finalize(() => (this.hideActionLoader = true)),
          tap(() => {
            this.hideActionLoader = true;
          })
        );
        updateScratchpad.pipe(concatMap(() => readScratchpadTotal)).subscribe(() => {
          if (this.manualUpdate) {
            this.benefitUpdated.next();
          } else {
            this.valuepropService.refreshDashboard.next('no_reload');
          }
        });
      }
    } else {
      let payload = this.getYearlyScratchpadPayload();

      if (payload.factors.length === 0) {
        this.notificationService.warning(this.trans.trans.noChanges.value, false);
        this.hideActionLoader = true;
        return;
      }

      const updateScratchpad = this.valuepropService.quickFillPut(this.valuePropId, payload).pipe(
        finalize(() => (this.hideActionLoader = true)),
        tap((response) => {
          if (response.result && response.result.success !== false) {
            if (!this.mini && !this.manualUpdate) {
              this.valuepropService.refreshBenefitDetail.next('no_reload');
            }
            this.notificationService.success(this.trans.trans.dataSaved.value, false);
          } else {
            this.hideActionLoader = true;
            this.notificationService.error(response.result.message, false);
            this.hideActionLoader = true;
          }
        })
      );
      updateScratchpad.pipe(concatMap(() => readScratchpadTotal)).subscribe(() => {
        if (this.manualUpdate) {
          this.benefitUpdated.next();
        } else {
          this.valuepropService.refreshDashboard.next('no_reload');
        }
      });
    }
  }

  buildGoalsPayload(): ValuePropAddGoal[] {
    return this.scratchpadList.units.map((goal) => ({
      account_factor_id: goal.factor_id,
      source_type_id: 1,
      baseline_value: goal.operand.toString(),
    }));
  }

  buildTrackerScratchpadPayload(): TrackerGoalScratchpadPayload[] {
    return this.scratchpadList.units.map((goal) => ({
      factor_id: goal.factor_id,
      unit_type_id: goal.unit_type_id,
      factor_value: goal.operand,
    }));
  }

  cancel() {
    this.SaveAndUpdateCallback.emit();
  }

  clearAnswer() {
    this.scratchpadList['units'].forEach((obj) => {
      obj.operand = '0';
      this.total = '';
    });
  }

  toggleEdit(index: number): void {
    this.scratchpadList.units[index].nameEdition = !this.scratchpadList.units[index].nameEdition;
  }

  funcSaveFactorName(scratchpad: ScratchpadUnit): void {
    const payload = {
      factor_id: scratchpad.factor_id,
      override_name: scratchpad.name,
    };
    this.valuepropService.postEditFactorNameFromBenefits(this.valuePropId, payload).subscribe(
      () => {
        scratchpad.nameEdition = false;
        this.notificationService.success(this.trans.trans.nameUpdateSucess.value, false);
      },
      () => this.notificationService.error(this.trans.trans.nameUpdateFailure.value, false)
    );
  }
}
