import { Component, Input, OnInit } from '@angular/core';
import { TeamMember } from '@shared/models/team-member';
import { UserProfileTranslation } from '../user-profile.translation';

@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent implements OnInit {

  @Input() teamMembers: TeamMember[] = [];

  public profilepicdefaultpath = 'https://media.value-cloud.com/images/default_user.png';

  constructor(public trans: UserProfileTranslation,) { }

  ngOnInit() {}
}
