<div class='models_panel reduced-padding' style='margin-bottom: 20px;'>
	<div>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title padding-0-y'>{{trans.trans.models.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.models" (callback)="getTranslations()"></app-editTranslation>
						<i *ngIf="!(style2022$ | async)" [matTooltip]='trans.trans.ch_models.value' class='fa fa-info-circle'></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_models.value">
							info
						</span>
                    </div>
				</div>
				<div class='col-xs-2'>
				</div>
			</div>
		</div>

		<div *ngIf="loading">
			<p-progressBar mode="indeterminate"></p-progressBar>
		</div>

		<div *ngIf="!loading">
			<div class="row" *ngIf="user == 'system'">
				<div class="col-md-6">
					<label class="categoryType">
						{{ trans.trans.choose_category_type.value }}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='vp_dashboard.models.choose_category_type' [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.choose_category_type"></app-editTranslation>
					</label>
					<select *ngIf="solutionCategoryList.length>0" type="text" (change)="getSolution(categoryType)" class="form-control" name="categoryType" [(ngModel)]="categoryType">
						<option *ngFor="let solutionCategory of solutionCategoryList" [value]="solutionCategory.id">
							{{solutionCategory.category}} </option>
					</select>
					<div class="text-center">
						<img class="actionLoader" *ngIf="showCategoryTypeLoader" [src]="fullImagePath" />
					</div>
				</div>
			</div>
			<div class="row">
				<div *ngFor="let item of availableSolutions">
					<div class="col-md-3" *ngIf="user == 'system' && item.selectedCategory">
						<div [ngClass]="{'checkbox': !hasRadioButtonFeature, 'custom-checkbox': !hasRadioButtonFeature, 'keywest_radio_list': hasRadioButtonFeature}">
							<label>

								<span *ngIf="item.quantity_enabled == '1'">
									<span (click)="item.showeditable = !item.showeditable; item.tempQuantity = item.quantity" *ngIf="!item.showeditable">
										<span *ngIf="item.quantity" class="quantityCheck">{{item.quantity}}</span>
									</span>

									<div *ngIf="item.showeditable" class="input-group" style='max-width: 150px; float: left;'>
										<input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity">
										<div class='input-group-btn'>
											<button (click)="quantityAssign(item.id,item.quantity);item.showeditable = !item.showeditable;" class="btn btn-success">
												<i class="fa fa-check"></i>
											</button>
											<button (click)="item.quantity = item.tempQuantity; item.showeditable = !item.showeditable" class="btn btn-danger">
												<i class="fa fa-times"></i>
											</button>
										</div>
									</div>

									&nbsp;of
								</span>

								<input *ngIf="hasRadioButtonFeature" type="radio" class="solutionCheckbox" [disabled]="!valueProp.vp_can_edit" name="item.isChecked" (change)="item.isChecked = !item.isChecked" [checked]="item.isChecked" (click)="checkModels(item.id,item.quantity,$event)" />
								<span [ngClass]="{'keywest_radiobutton': hasRadioButtonFeature}">
									{{item.name}}
								</span>

								<input *ngIf="!hasRadioButtonFeature" type="checkbox" class="solutionCheckbox" [disabled]="!valueProp.vp_can_edit" name="item.isChecked" (change)="item.isChecked = !item.isChecked" [checked]="item.isChecked" (click)="checkModels(item.id,item.quantity,$event)" />

								<span class="check"></span>
							</label>
						</div>
					</div>
					<div class="col-md-3" *ngIf="user == 'customer' && item.isChecked">
						{{item.name}}
					</div>

				</div>
        <div class='col-xs-12' *ngIf="availableSolutions && selectedSolutionsLength == 0 && !showModelsLoader">
            <p class="well">{{ trans.trans.no_modelscategory_try_different_selecting_dropdown_above.value }}
            </p>
        </div>
			</div>
			<div class='row'>
				<div class='col-xs-12'>
					<app-are-you-sure *ngIf="!showActionLoaderModel && !locked && valueProp.vp_can_edit" [message]='trans.trans.areYouSureModels.value' [text]='trans.trans.save.value' icon='' class='btn btn_customizable' (callbackYes)='createSolution()'></app-are-you-sure>
				</div>
			</div>
		</div>
	</div>
</div>