<p-menu #inlinestatus appendTo='body' [popup]='true' [model]='bI.inlinestatus' [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<table id="benefits" class="table custom-table benefits-table" style='margin-bottom: 0px;' style='table-layout: fixed; width: 100%;'>
	<thead>
		<tr>
			<th width='70px' style='white-space: nowrap;'>{{ trans.vp_dashboard.benefits.phasing | translate }}</th>
			<th width='20px' *ngIf="bI.showBatchUpdateCheckboxes">
				<input type="checkbox" (click)='bI.checkAll(benefitCategory)' matTooltip="Apply to all in category" matTooltipPosition="above" />
			</th>
			<th width='30%' *ngIf="!bI.showBatchUpdateCheckboxes">{{ trans.vp_dashboard.benefits.status | translate }}</th>
			<th style="width: 100%">{{ trans.vp_dashboard.benefits.benefits | translate }}</th>
			<th width="200px" style='white-space: nowrap;'>{{ trans.vp_dashboard.benefits.impact | translate }}</th>
			<th width='20px'>
				<div style='float: left;'>
					<i class='fa fa-chevron-circle-left clickable' (click)='benefitCategory.start = bI.rangeLeftArrow(benefitCategory.start, benefitCategory.term)' *ngIf="benefitCategory.term > bI.termShow && (benefitCategory.start !== 1)" style='color: green; font-weight: bold; float: left; margin-top: 15px;'></i>
				</div>
			</th>
			<th width='100px' *ngFor="let year of benefitCategory.years | rangePipe: benefitCategory.start : bI.termLimit : bI.termShow; let i = index" style='padding-left: 0px !important; padding-right: 0px !important;' [ngClass]="bI.alignYearTbl(i)">
				{{year.label}}
			</th>
			<th width='20px'>
				<div style='float: right; text-align: right;'>
					<i class='fa fa-chevron-circle-right clickable' (click)='benefitCategory.start = bI.rangeRightArrow(benefitCategory.start, benefitCategory.term)' *ngIf="benefitCategory.term > bI.termShow && (benefitCategory.start !== benefitCategory.term - bI.termLimit)" style='color: green; font-weight: bold; margin-top: 15px;'></i>
				</div>
			</th>
			<th class='text-right' width='90px' style='padding-left: 0px !important;'>
				{{ trans.vp_dashboard.benefits.total | translate }}
			</th>
		</tr>
	</thead>
	<tbody>

		<ng-container *ngFor="let benefit of benefitCategory.metrics | filterBenefits : bI.menuFilter : 'metric_name' | filterStatus: bI.showActive : bI.showNA : bI.showSoft; let i = index;">
			<tr [ngClass]="{'row_selected': benefit.expanded || benefit.phasing}">
				<td>
					<div class="pull-left">
						<i class="fa fa-signal" [ngStyle]="{'color': benefit.phasing ? 'green' : 'inherit'}" (click)="benefit.phasing = !benefit.phasing" matTooltip="{{trans.contextual_help.vp_dashboard.position24 | translate}}" matTooltipPosition="above" role="button"></i>
					</div>
				</td>
				<td *ngIf="bI.showBatchUpdateCheckboxes">
					<p-checkbox name="checkbox{{i}}" [binary]='true' [(ngModel)]="benefit.updateBatch"></p-checkbox>
				</td>
				<td class="benefit-status benefitStatusElement" *ngIf="!bI.showBatchUpdateCheckboxes" matTooltip="{{trans.contextual_help.vp_dashboard.benefit_status | translate}}" matTooltipPosition="above">
					<a (click)="bI.openActiveTypesPmenu(benefit, inlinestatus, $event)"  class="dashedLink benefitStatusElement" data-toggle="tooltip" title="Change Benefits Status">
						<span class="activeStatusType benefitStatusElement" [ngStyle]="{'background-color': bI.getBenefits(benefit.active_type)}"></span>
					</a>
				</td>


				<td class="benefit-status" [matTooltip]="benefit.description" matTooltipPosition="above">
					<div style='display: inline-flex;'>
						<a (click)="bI.expandBenefit(benefit)" class='link clickable' style='display: inline;'>
							<i class='fa fa-chevron-right' *ngIf="!benefit.expanded"></i>
							<i class='fa fa-chevron-down' *ngIf="benefit.expanded"></i>
							{{benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name}}
						</a>
						<div class="keywest_icon_group" style="margin-left: 10px;">
							<span><i class="fa fa-bookmark" *ngIf="benefit.vp_specific == 1" aria-hidden="true" matTooltip="This Benefit was added to this ValueProp manually." matTooltipPosition="above"></i></span>
							<span *ngIf="bI.valueProp.vp_can_edit && benefit.vp_specific == 1 && bI.benefitOnTheFlyPrivilege" (click)="benefit.editing = !benefit.editing" matTooltip="Update" matTooltipPosition="above" class="clickable">
								<i style='color: green !important;' class="fa fa-pencil"></i>
							</span>
							<span>
								<app-are-you-sure message='Are you sure?' text='' iconColor="#ff5d5d" [tooltip]='"Delete"' icon='fa fa-times' [class]='"no_btn"'  *ngIf="bI.valueProp.vp_can_edit && benefit.vp_specific == 1 && bI.benefitOnTheFlyPrivilege" (callbackYes)='bI.deleteCustomBenefit(benefit)'></app-are-you-sure>
							</span>
						</div>
					</div>
				</td>
				<td style='white-space: nowrap;'>{{benefit.impact_type}}</td>
				<td></td>
				<td *ngFor="let year of benefit.years | rangePipe: benefitCategory.start : bI.termLimit : bI.termShow; let i = index" style='padding-left: 0px !important; padding-right: 0px !important;' [ngClass]="bI.alignYearTbl(i)">{{year.value}}</td>
				<td></td>
				<td class="preventWordBreak text-right" style='padding-left: 0px !important;'>{{benefit.total_benefits_fmt}}</td>
			</tr>

			<tr [ngClass]="{'row_selected': benefit.expanded || benefit.phasing || benefit.editing}" *ngIf="benefit.phasing">
				<td colspan='11'>
					<app-benefit-phasing (callBack)="benefit.phasing = false;" [valueProp]='bI.valueProp' [benefit]='benefit'></app-benefit-phasing>
				</td>
			</tr>

			<tr [ngClass]="{'row_selected': benefit.expanded || benefit.phasing || benefit.editing}" *ngIf="benefit.expanded">
				<td colspan='11'>
					<app-benefit-detail [contextualHelp]='bI.contextualHelp' [valueProp]='bI.valueProp' [selectedBenefit]='benefit' (closeBenefitDetail)="benefit.expanded = false"></app-benefit-detail>
				</td>
			</tr>

			<tr [ngClass]="{'row_selected': benefit.expanded || benefit.phasing || benefit.editing}" *ngIf="benefit.editing">
				<td colspan='11'>
				</td>
			</tr>
		</ng-container>
	</tbody>
</table>