import { DLSidebarComponent } from './sidebar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		AreYouSureModule,
    MatTooltipModule,
		CommonModule,
		FormsModule
	],
	declarations: [DLSidebarComponent],
	exports: [DLSidebarComponent],
	entryComponents: [DLSidebarComponent]
	})
export class DLSideBarModule { }