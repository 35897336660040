import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

export interface ReportType {
  description: string;
  id: string;
  name: string;
  params: string;
  thumbnail: string;
  url: string
}
export interface ReportTypeResponse {
  result: ReportType[];
}

@Injectable()
export class OtherReportsService {

  constructor(private CommonService: CommonService) { }

  public getReportTypes(): Observable<ReportTypeResponse> {
		let endpoint = "fact/report-types";
		return this.CommonService.getAPIService(endpoint);
	}
}
