import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { GuidedDiscoveryElementTypes, Workflow } from '@data/services/workflow/models/workflow.interface';
import { map, pluck } from 'rxjs/operators';
import { WorkflowModel, WorkflowQuestion, WorkflowQuestionAnswers, WorkflowSections } from '@data/services/workflow/models/workflow-question.interface';
import { WorkflowDataSourceMember } from '../facts/models/workflow-data-source.interface';
import { Solution, ValuePropSolutionPayload } from '@shared/models/value-prop.model';

@Injectable()
export class AccountWorkflowService {
  private workflowTypeId = 1;
  workflowDataSources = [];
  workflowDataSourcesMembers = new Map<string, WorkflowDataSourceMember[]>();
  workflowRefreshModel$ = new Subject<boolean>();

  constructor(private commonService: CommonService) { }

  getAccountWorkflows(active?: boolean): Observable<Workflow[]> {
    const activeParam = active !== undefined ? (active ? '1' : '0') : '';
    const endpoint = `account/workflows/${this.workflowTypeId}/${activeParam}`;

    return this.commonService.getAPIService(endpoint).pipe(
      pluck('result'),
      pluck('workflow'),
      map((workflows: Workflow[]) => workflows.map<Workflow>((workflow) => ({
        ...workflow,
        activeBool: workflow.active === '1'
      })))
    );
  }

  getAccountWorkflow(id: number | string): Observable<Workflow> {
    const endpoint = `account/workflow/${id}`;

    return forkJoin({
      workflow: this.commonService.getAPIService(endpoint).pipe(
        pluck('result'),
      ),
      questions: this.getAccountWorkflowQuestions(id)
    }).pipe(
      map(({ workflow, questions }) => ({
        ...workflow,
        activeBool: workflow.active === '1',
        questions: questions.sort((a: WorkflowQuestion, b: WorkflowQuestion) => +a.sequence - (+b.sequence))
      }))
    );
  }

  getAccountWorkflowQuestions(workflowId: number | string): Observable<WorkflowQuestion[]> {
    const endpoint = `account/workflows/${workflowId}/sections`;

    return this.commonService.getAPIService(endpoint).pipe(
      pluck('result'),
    );
  }

  addAccountWorkflowQuestions(workflowId: string, workflowSections: WorkflowSections): Observable<WorkflowQuestion[]> {
    const endpoint = `account/workflows/${workflowId}/sections`;

    return this.commonService.postAPIService(endpoint, workflowSections).pipe(pluck('result', 'workflow_sections'));
  }

  upateAccountWorkflowQuestions(workflowId: string, workflowSections: WorkflowSections): Observable<WorkflowQuestion[]> {
    const endpoint = `account/workflows/${workflowId}/sections`;

    return this.commonService.putAPIService(endpoint, workflowSections).pipe(pluck('result'));
  };

  deleteAccountWorkflowQuestion(workflowId: string, questionId: string | number): Observable<{ success: boolean }> {
    const endpoint = `account/workflows/${workflowId}/section/${questionId}`;

    return this.commonService.deleteAPIService(endpoint).pipe(pluck('result'));
  }

  getDataSourceMembers(dataSourceId: number | string): Observable<WorkflowDataSourceMember[]> {
    return this.commonService.getAPIService(`account/workflow-data/${dataSourceId}`).pipe(pluck('result', 'workflow_data'));
  }

  saveAccountWorkflow(workflow: Workflow): Observable<{ success: boolean, account_workflow_id?: number, message?: string }> {
    const endpoint = 'account/workflow';
    if (workflow.id) {
      return this.commonService.putAPIService(endpoint, workflow).pipe(pluck('result'));
    } else {
      return this.commonService.postAPIService(endpoint, workflow).pipe(pluck('result'));
    }
  }

  deleteAccountWorkflow(workflow: Workflow): Observable<{ success: boolean }> {
    const endpoint = 'account/workflow/' + workflow.id;
    return this.commonService.deleteAPIService(endpoint).pipe(pluck('result'));
  }

  getModelRecs(valuePropId: number | string): Observable<WorkflowModel[]> {
    return this.commonService.getAPIService(`valueProps/${valuePropId}/guided_solution_match`).pipe(pluck('result'));
  }

  getSelectedSolutions(valuePropId: string): Observable<Solution[]> {
    return this.commonService.getAPIService(`valueProps/solutions/${valuePropId}`).pipe(pluck('result'));
  }

  changeSolutions(valuePropId: string, payload: ValuePropSolutionPayload) {
    return this.commonService.postAPIService(`valueProps/solution/create/${valuePropId}`, payload).pipe(pluck('result'));
  }

  getQuestionsVP(valuePropId: string, accountWorkflowId: string | number): Observable<WorkflowQuestionAnswers[]> {
    const endpoint = `valueProps/${valuePropId}/workflow_sections/${accountWorkflowId}`;
  
    return this.commonService.getAPIService(endpoint).pipe(
      pluck('result', 'workflow_sections'),
      map((questions: WorkflowQuestionAnswers[]) => {
        if (!questions || questions.length === 0) {
          return [];
        }

        return questions.map(question => {
          if(+question.element_type_id === GuidedDiscoveryElementTypes.singleSelect){
            return {
              ...question,
              singleSelectAnswer: ''
            }
          }
          
          else if(+question.element_type_id === GuidedDiscoveryElementTypes.multiSelect){
            return {
              ...question,
              multiSelectAnswer: []
            }
          }
          
          else if(+question.element_type_id === GuidedDiscoveryElementTypes.dropdownSingle){
            return {
              ...question,
              dropdownSingleAnswer: null
            }
          }
        });
      })
    )
  }

  saveAnswers(valuePropId: string, questions: WorkflowQuestion[]) : Observable<{success: boolean, message: string}> {
    const endpoint = `valueProps/${valuePropId}/workflow_members`;
    return this.commonService.postAPIService(endpoint, {
      workflow_sections: questions
    }).pipe(pluck('result'));
  }
}
