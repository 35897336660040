import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { UserInfo } from '@shared/models/user-info.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ReviewBaselineGoalGroupsTranslations } from './review-baseline-goal-groups.translation';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';
import { ValueRealizationBaselineGoalGroup, ValueRealizationBaselineGoal, SimpleGoalReactiveForm, Goal, ValuePropAddGoal, SelectOption } from '@shared/models/goal-group.model';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { NotificationService } from '@services/notification.service';
import { FactorsService } from '@data/services/factors/factors.service';

@Component({
  selector: 'app-review-baseline-goal-groups',
  templateUrl: './review-baseline-goal-groups.component.html',
  styleUrls: ['./review-baseline-goal-groups.component.scss'],
})
export class ReviewBaselineGoalGroupsComponent implements OnInit, OnDestroy {
  _valuePropIdValue: string;
  selectedGoal: SimpleGoalReactiveForm;
  sidebarMode: string;
  isSidebarOpen: boolean;
  scratchpadGoalSelected: Goal;
  toggleScratchpadView: boolean;
  @Input()
  set valuePropId(val: string) {
    this._valuePropIdValue = val;
    if (val) {
      this.getGoalGroups();
    }
  }
  get valuePropId() {
    return this._valuePropIdValue;
  }

  @Input() valueProp: ValueProp;

  goalOnTheFlyPrivilege = this.commonService.checkPrivilege('17');
  ngUnsubscribe = new Subject();
  showTranslate = false;
  userData: UserInfo;
  numberLocales = ['en-US', 'en-US', 'de-DE', 'fr-FR'];
  unitTypes: ExpenseType[];
  goalGroupList: ValueRealizationBaselineGoalGroup[] = [];
  goalIdsList: string[] = [];
  selectOptions: SelectOption[] = [];
  selectedOption: string = 'key';

  loading = false;
  goalsLoaded: boolean;
  loadingSave = false;
  
  constructor(
    private commonService: CommonService,
    public trans: ReviewBaselineGoalGroupsTranslations,
    private translationService: TranslationsV2Service,
    private userService: UserService,
    public valuePropService: ValuepropService,
    private goalGroupsService: GoalGroupsService,
    private factorService: FactorsService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.factorService.getUnitTypes().subscribe((res) => {
      this.unitTypes = res.result;
    });

    if ( this.valuePropId ) {
      this.getGoalGroups();
    }

    this.userService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userInfo) => {
      this.userData = userInfo;
    });
    
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        
        this.selectOptions = [
          { label: this.trans.trans.allGoals.value, key: 'all'},
          { label: this.trans.trans.onlyKeyGoals.value, key: 'key'}
        ];
      });
  }
  
  getGoalGroups(): void {
    this.goalsLoaded = false;
    this.goalGroupsService
      .getValuePropBaselineGoals(this.valuePropId.toString(), 0)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((goalArr) => {
        let newGoalGroupList = [];
        let newGoalIdsList = [];

        let goalGroupResponse = goalArr as ValueRealizationBaselineGoalGroup[];

        goalGroupResponse.forEach((goalGroup) => {
          let factors = goalGroup.factors as ValueRealizationBaselineGoal[];

          factors.forEach(factor => {
            newGoalIdsList.push(factor.account_factor_id);

            factor.selected = factor.active === "1" ? true : false;
            factor.editing = false;
            factor.updated = false;
            factor.nameCache = factor.name;

            factor.unit_type = this.unitTypes.find((type) => type.id === factor.unit_type_id).name;

            if ( factor.is_key_factor === '1' ) {
              goalGroup.has_key_factors = true;
            }
          });

          newGoalGroupList.push(goalGroup);

        });

        this.goalGroupList = newGoalGroupList;
        this.goalIdsList = newGoalIdsList;

        this.setGoalListVisibleStatuses();

        this.goalsLoaded = true;
      });
  }

  setGoalListVisibleStatuses() {
    this.goalGroupList.forEach(goalGroup => {
      if ( this.selectedOption == 'all' ) {
        goalGroup.should_show = true;
      } else if ( this.selectedOption == 'key' && goalGroup.has_key_factors === true ) {
        goalGroup.should_show = true;
      } else {
        goalGroup.should_show = false;
      }

      goalGroup.factors.forEach(factor => {
        if ( this.selectedOption == 'all' ) {
          factor.should_show = true;
        } else if ( this.selectedOption == 'key' && factor.is_key_factor === '1' ) {
          factor.should_show = true;
        } else {
          factor.should_show = false;
        }
      });
    });
  }

  saveName(goalGroupIndex, rowIndex, account_factor_id) {
    const vr_override_name = this.goalGroupList[goalGroupIndex]['factors'][rowIndex]['nameCache'];
    const payload = {
      account_factor_id,
      vr_override_name,
    };

    this.valuePropService
      .postEditFactorNameFromBenefits(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.goalNameSaved.value, false);
        
        // Overwrite the name with the udpated name
        this.goalGroupList[goalGroupIndex]['factors'][rowIndex]['name'] = vr_override_name;
      });
  }

  editName(goalGroupIndex, rowIndex, nameCache) {
    this.goalGroupList[goalGroupIndex]['factors'][rowIndex]['editing'] = !this.goalGroupList[goalGroupIndex]['factors'][rowIndex]['editing'];
  }

  removeNegatives(value: number): number {
    return value < 0 ? 0 : Math.min(value, 9);
  }

  onAddGoalClick() {
    this.sidebarMode = 'add';
    this.isSidebarOpen = true;
  }

  addGoalToVp(goal: SimpleGoalReactiveForm | null, mode?: string): void {
    if (goal === null) {
      this.isSidebarOpen = false;
      this.getGoalGroups();
      return;
    }
    const payload: ValuePropAddGoal = {
      account_factor_id: goal.accountFactorId,
      source_type_id: 1,
      value: goal.value,
      active: '1',
    };

    this.loadingSave = true;

    this.goalGroupsService
      .addValuePropGoal(this.valuePropId, payload)
      .pipe(finalize(() => (this.loadingSave = false)))
      .subscribe(
        () => {
          if (mode === 'edit') {
            this.notificationService.success(this.trans.trans.goalUpdated.value, false);
          } else {
            this.notificationService.success(this.trans.trans.goalAdded.value, false);
          }

          this.isSidebarOpen = false;
          this.getGoalGroups();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  setFactorActive( goalGroupIndex, rowIndex, rowData ) {
    let activeStatus = rowData.selected === true ? '1' : '0';

    this.goalGroupList[goalGroupIndex]['factors'][rowIndex]['active'] = activeStatus;

    const payload = {
      account_factor_id: rowData.account_factor_id,
      active: activeStatus,
    };

    this.valuePropService
      .updateValuePropFactorActive(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(
        () => {
          this.notificationService.success(this.trans.trans.goalUpdated.value, false);
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  onGoalClick(goal: ValueRealizationBaselineGoal): void {
    this.selectedGoal = this.transformGoal(goal);
    this.sidebarMode = 'edit';
    this.isSidebarOpen = true;
  }

  toggleScratchpadSidebar(goal: Goal) {
    this.scratchpadGoalSelected = goal;
    this.toggleScratchpadView = true;
  }

  closeSidebars(): void {
    this.toggleScratchpadView = false;
    this.isSidebarOpen = false;
  }

  scratchpadSave(): void {
    this.closeSidebars();
    this.getGoalGroups();
  }

  transformGoal(goal: ValueRealizationBaselineGoal) {
    let simpleGoal: SimpleGoalReactiveForm = {
      accountFactorId: goal.account_factor_id,
      value: goal.value.toString(),
      name: goal.name,
      description: goal.description,
      baselineValue: goal.baseline_value.toString(),
      linkedFactors: goal.linked_factors,
      precision: goal.factor_precision,
      scratchpadString: goal.scratchpad_str,
      selected: goal.selected,
      editing: goal.editing,
      unitType: goal.unit_type,
    }

    return simpleGoal;
  }

  buildGoalsArray(): ValueRealizationBaselineGoal[] {
    let goals: ValueRealizationBaselineGoal[] = [];

    this.goalGroupList.forEach((group) => {
      let factors = group.factors;

      factors.forEach((factor: ValueRealizationBaselineGoal) => {
        if (factor.updated === true) {
          goals.push(factor);
        }
      })
    });

    return goals;
  }

  saveGoals(goals?: ValueRealizationBaselineGoal[], mode?: string): void {
    
    if ( goals === undefined ) {
      // Nothing passed, get goals from the object
      goals = this.buildGoalsArray();
    }

    const payload: ValuePropAddGoal[] = [];
    goals.forEach((goal) => {
      const goalPayload: ValuePropAddGoal = {
        account_factor_id: goal.account_factor_id,
        source_type_id: 1,
        value: goal.value.toString(),
        baseline_value: goal.baseline_value.toString(),
        active: goal.selected ? '1' : '0',
      };
      payload.push(goalPayload);
    });
    this.loadingSave = true;

    this.goalGroupsService
      .addValuePropGoals(this.valuePropId.toString(), payload)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingSave = false))
      )
      .subscribe(
        () => {
          if (mode === 'edit') {
            this.notificationService.success(this.trans.trans.goalUpdated.value, false);
          } else {
            this.notificationService.success(this.trans.trans.goalAdded.value, false);
          }
          this.getGoalGroups();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  goalUpdated(goalGroupIndex, rowIndex) {
    this.goalGroupList[goalGroupIndex]['factors'][rowIndex]['updated'] = true;
  }

  funcOptionChange(event) {
    this.selectedOption = event.value;

    this.setGoalListVisibleStatuses()
  }
  
}
