import { Component, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-modal-forgot-password',
	template: `<app-modal-container (closeLoginModal)='closeLogin()'><app-forgot-password (closeLogin)='closeLogin()'></app-forgot-password></app-modal-container>`
})
export class ModalForgotPasswordComponent {
	@Output() closeLoginModal: EventEmitter<null> = new EventEmitter();
	constructor() { }

	closeLogin() {
		this.closeLoginModal.emit();
	}
}
