import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { SearchCompanyTranslations } from './search-companyv3.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Company } from '@shared/models/company.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { CompanySearchPayload } from '@shared/models/company-data.models';

@Component({
  selector: 'app-search-companyv3',
  templateUrl: './search-companyv3.component.html',
  styleUrls: ['./search-companyv3.component.scss'],
})
export class SearchCompanyV3Component implements OnInit, OnChanges, OnDestroy {
  @Input() companyDetails: Company;
  @Input() cxo: any;
  @Input() is_value_prop = false;

  @Output() funcCallback = new EventEmitter();

  addCompany: boolean = false;
  imageNotFounderror = false;
  companiesList: Company[] = [];
  companyList = true;
  detailedDescription = true;
  companyId: number;
  payload = {};
  showCompanyAdd = false;
  fullImagePath: string;
  quickCompanyDetailsDescription = true;

  pageNumber: number;
  pageNumberSearch = 1;

  pageLimit: number;
  pageLimitSearch = 10;
  searchTypErr = false;
  order = 'asc';
  sortBy = 'name';
  modalReference: NgbModalRef;
  companyname = '';
  companiesNoResult = false;
  showActionLoader = false;
  crm: {
    sfoide?: string;
    company_id?: number;
    opportunity_id?: string;
    account_defined_id?: string;
  } = {};
  sfoide: string;
  aid = sessionStorage.getItem('aid');
  isVMWare = this.aid === '20';

  contextualHelp = {};
  enableSaveBtn: boolean;

  opportunityId: number | string;

  accountDefinedId: number | string;

  ngUnsubscribe$ = new Subject();
  showTranslate = false;
  firstCompanyItem: number;
  cols: Array<{ field?: string; header?: string; displayCondition?: boolean }>;
  loading: boolean;
  q1answer: string = '';
  q2answer: string = '';
  featureCustomAccountTable = this.commonService.checkFeature('95');
  feature22 = this.commonService.checkFeature('22'); // opportunity id
  feature60 = this.commonService.checkFeature('60'); // Question 1
  feature68 = this.commonService.checkFeature('68'); // Question 1
  feature89 = this.commonService.checkFeature('89');
  feature101 = this.commonService.checkFeature('101');
  feature125 = this.commonService.checkFeature('125');
  feature126 = this.commonService.checkFeature('126');
  breadcrumbKeyValues: Breadcrumbs;
  selectedCompany: Company;
  viewOptions: Array<{ label: string; value: string }> = [];
  currentView: string = 'recents';
  recentCompanies: Company[] = [];
  favoriteCompanies: Company[] = [];
  companyIsFavorite = false;
  opportunityForm = this.formBuilder.group({
    opportunityId: ['', this.feature126 ? Validators.required : Validators.nullValidator],
  });

  private searchInputComplete$ = new Subject<CompanySearchPayload>();

  constructor(
    private accountService: AccountService,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private translationService: TranslationsV2Service,
    public trans: SearchCompanyTranslations,
    private valuePositionService: ValuepositionService,
    private valuePropService: ValuepropService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.searchInputComplete$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap((payload) => {
          return this.accountService.getSearchCompanies(payload);
        })
      )
      .subscribe(
        (response: any) => {
          this.showActionLoader = false;
          this.showCompanyAdd = true;
          if (response.result && response.result.data && response.result.data.length > 0) {
            this.companiesList = response.result.data;
            this.companyList = false;
            this.detailedDescription = true;
            this.showActionLoader = false;
            this.firstCompanyItem = 0;
            this.normalizeCompanyLogoPath(this.companiesList);
          } else {
            this.companiesList = [];
            this.showActionLoader = false;
            this.companiesNoResult = true;
            this.quickCompanyDetailsDescription = true;
          }
          this.showActionLoader = false;
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );

    this.fullImagePath = this.commonService.getLoaderImageUrl();

    if (sessionStorage.getItem('crm')) {
      this.crm = JSON.parse(sessionStorage.getItem('crm'));
      this.sfoide = this.crm.sfoide;
      if (this.crm.company_id) {
        this.companyId = this.crm.company_id;
        this.selectCompany(this.companyId);
        this.getCompanyDetail();
      }
      if (this.crm.opportunity_id) {
        this.opportunityId = this.crm.opportunity_id;
      }
      if (this.crm.account_defined_id) {
        this.accountDefinedId = this.crm.account_defined_id;
      }
    }
  }

  ngOnInit() {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.onSearchComplete();

    if (sessionStorage.getItem('crm')) {
      this.crm = JSON.parse(sessionStorage.getItem('crm'));
      this.sfoide = this.crm.sfoide;
      if (this.crm.opportunity_id) {
        this.opportunityId = this.crm.opportunity_id;
      }
      if (this.crm.account_defined_id) {
        this.accountDefinedId = this.crm.account_defined_id;
      }
      if (this.crm.company_id) {
        this.companyId = this.crm.company_id;
        this.selectCompany(this.companyId);
        this.funcContinue();
      }
    }

    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.route.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response) => {
      this.is_value_prop = response.type === 'valueprop';
    });
    this.companyService.recentCompanies$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((companies) => {
      this.recentCompanies = companies;
    });
    this.companyService
      .getFavoriteCompanies()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((companies: any) => {
        if (companies.result) {
          this.favoriteCompanies = companies.result;
          this.normalizeCompanyLogoPath(this.favoriteCompanies);
        }
      });
  }

  get opportunityIdControl(): AbstractControl {
    if (this.opportunityForm) {
      return this.opportunityForm.get('opportunityId');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.hypotheses.value]: { routerLink: '/valueposition', active: false },
          [this.trans.trans.createNew.value]: { routerLink: '/valueposition/repv2', active: true },
        };
        this.cols = [
          { field: 'name', header: this.trans.trans.companyName.value, displayCondition: true },
          { field: 'account_defined_id', header: this.trans.trans.crmAccountId.value, displayCondition: true },
          { field: 'id', header: this.trans.trans.vcAccountId.value, displayCondition: true },
          { field: 'end_user_id', header: this.trans.trans.end_user_id.value, displayCondition: this.featureCustomAccountTable },
          { field: 'revenue_fmt', header: this.trans.trans.revenueM.value, displayCondition: true },
          { field: 'employees_fmt', header: this.trans.trans.employees.value, displayCondition: true },
          { field: 'city', header: this.trans.trans.city.value, displayCondition: true },
          { field: 'market_area', header: this.trans.trans.market_area.value, displayCondition: this.featureCustomAccountTable },
          { field: 'country', header: this.trans.trans.country.value, displayCondition: !this.featureCustomAccountTable },
          { field: 'action', header: ' ' },
        ];
        this.viewOptions = [
          { value: 'recents', label: this.trans.trans.recents.value },
          { value: 'favorites', label: this.trans.trans.favorites.value },
        ];
      });
  }

  saveOpportunityId(vpid): void {
    const payload = {
      opportunity_id: this.opportunityId,
    };

    this.valuePropService.updateValuePropOpportunityId(vpid, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  funcSaveQ1(vpid): void {
    if (!this.q1answer) {
      return;
    }
    const payload = { answer: this.q1answer };
    this.valuePropService.putVMWareQ1(vpid, payload).subscribe(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  funcSaveQ2(vpid): void {
    if (!this.q2answer) {
      return;
    }
    const payload = { answer: this.q2answer };
    this.valuePropService.putVMWareQ2(vpid, payload).subscribe(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
  }

  ngOnChanges(): void {
    if (this.companyDetails) {
      if (Object.keys(this.companyDetails).length !== 0 && this.companyDetails.constructor === Object) {
        this.quickCompanyDetailsDescription = false;
        this.detailedDescription = false;
      }
    }
  }

  openAddCompany(content): void {
    this.modalReference = this.modalService.open(content, { size: 'lg', windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  onSearchComplete() {
    if (this.companyname && this.companyname !== null && this.companyname !== '') {
      this.companyList = true;
      this.companiesNoResult = false;
      this.showActionLoader = true;
      const payload: CompanySearchPayload = {
        searchTerm: this.companyname,
        publicOnly: 0,
        synopsisOnly: 1,
        limit: 'all',
        page: this.pageNumberSearch,
        sortBy: this.sortBy,
        order: this.order,
      };
      this.searchInputComplete$.next(payload);
    } else {
      this.showActionLoader = false;
      this.companiesList = [];
      this.companiesNoResult = true;
      this.companyList = true;
    }
  }

  funcContinue(): void {
    let valueProp = {};
    this.loading = true;
    this.opportunityId = this.opportunityIdControl.value;

    if (this.aid === '25012') {
      if (this.crm.opportunity_id && !this.opportunityId) {
        valueProp = {
          company_id: this.companyId,
          opportunity_id: this.crm.account_defined_id,
          is_value_prop: this.is_value_prop,
        };
      } else if (this.accountDefinedId && !this.opportunityId) {
        valueProp = {
          company_id: this.companyId,
          account_defined_id: this.accountDefinedId,
          is_value_prop: this.is_value_prop,
        };
      } else if (this.opportunityId && this.accountDefinedId) {
        valueProp = {
          company_id: this.companyId,
          opportunity_id: this.opportunityId,
          account_defined_id: this.accountDefinedId,
          is_value_prop: this.is_value_prop,
        };
      } else {
        valueProp = {
          company_id: this.companyId,
          is_value_prop: this.is_value_prop,
        };
      }
    } else {
      valueProp = {
        company_id: this.companyId,
        is_value_prop: this.is_value_prop,
      };

      if (this.crm.sfoide) {
        valueProp['sfoide'] = this.crm.sfoide;
      }
    }

    valueProp['cxo_deck'] = this.cxo;

    this.valuePositionService
      .createValueProp(valueProp)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (result) => {
          if (result.result) {
            if (!this.sfoide) {
              this.saveOpportunityId(result.result.value_prop.id);
            }

            this.funcSaveQ1(result.result.value_prop.id);

            this.funcCallback.emit({ valuePropId: result.result.value_prop.id });
          }
        },
        (error) => {
          this.loading = false;
          this.notificationService.error(error.result, false);
        }
      );
  }

  selectCompany(params): void {
    this.companyname = null;
    this.companyList = true;
    this.companyId = params;
    this.showCompanyAdd = false;
    this.getCompanyDetail();
  }

  getCompanyDetail(): void {
    this.showActionLoader = true;
    this.companyService
      .getCompanySummary(this.companyId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((result) => {
        this.companyDetails = result.result.company;
        this.companyService.addRecentCompany(this.companyDetails);
        this.quickCompanyDetailsDescription = false;
        this.detailedDescription = false;
        this.showActionLoader = false;

        let companyList = [this.companyDetails];
        this.normalizeCompanyLogoPath(companyList);

        this.companyDetails = companyList[0];

        this.companyService
          .getCompanyFavorite(this.companyDetails.id)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe((res) => {
            this.companyIsFavorite = res.result;
          });
      });
  }

  onTableSelection(company: Company) {
    this.companyname = null;
    this.companyList = true;
    this.showCompanyAdd = false;
    this.selectedCompany = company;
    this.companyDetails = this.selectedCompany;
    this.companyId = parseInt(company.id);
    this.getCompanyDetail();
  }

  cancelCompany(): void {
    this.addCompany = false;
  }

  companyCreated($event): void {
    this.companyname = $event.name;
    this.onSearchComplete();
    this.addCompany = false;
  }

  enableSave($event): void {
    if ($event == 'true') {
      this.enableSaveBtn = true;
    } else {
      this.enableSaveBtn = false;
    }
  }
  onCancel(cancel) {
    this.addCompany = cancel;
  }

  public getLogo(companyName: string, logoPath = null): string {
    return logoPath ? logoPath : this.companyService.getLogo(companyName);
  }

  public onRemoveFavorite(company: Company) {
    this.companyService
      .removeCompanyFromFavorites(company.id)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(company.name + ' ' + this.trans.trans.isNoLongerAFavorite.value, false);
        this.companyService
          .getFavoriteCompanies()
          .pipe(take(1))
          .subscribe((companies: any) => {
            if (companies.result) {
              this.favoriteCompanies = companies.result;
              this.normalizeCompanyLogoPath(this.favoriteCompanies);
            }
          });
      });
  }

  public onAddToFavorites(company: Company) {
    this.companyService
      .addCompanyToFavorites(company.id)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(company.name + ' ' + this.trans.trans.isNowAFavorite.value, false);
        this.companyService
          .getFavoriteCompanies()
          .pipe(take(1))
          .subscribe((companies: any) => {
            if (companies.result) {
              this.favoriteCompanies = companies.result;
              this.normalizeCompanyLogoPath(this.favoriteCompanies);
            }
          });
      });
  }

  public toggleFavorite() {
    if (this.companyIsFavorite) {
      this.companyService
        .removeCompanyFromFavorites(this.companyDetails.id)
        .pipe(take(1))
        .subscribe(() => {
          this.companyIsFavorite = false;
          this.notificationService.success(this.companyDetails.name + ' ' + this.trans.trans.isNoLongerAFavorite.value, false);
        });
    } else {
      this.companyService
        .addCompanyToFavorites(this.companyDetails.id)
        .pipe(take(1))
        .subscribe(() => {
          this.companyIsFavorite = true;
          this.notificationService.success(this.companyDetails.name + ' ' + this.trans.trans.isNowAFavorite.value, false);
        });
    }
  }

  private normalizeCompanyLogoPath(companies: Company[]) {
    companies.forEach((company) => {
      if (!!company.logo_path) {
        return;
      }
      if (!company.logo_path && company.url && company.url !== '') {
        company.logo_path = this.companyService.getLogo(company.url);
      } else if (!company.url || (company.url === '' && !company.name.includes(' '))) {
        company.logo_path = this.companyService.getLogo(company.name, false);
      }
    });
  }

  public onImgError(company: Company) {
    company.logo_path = null;
  }

  public defaultValue(value: string): string {
    if (value === 'NULL') {
      return '-- --';
    }
    return value ? value : '-- --';
  }
}
