import { Injectable } from '@angular/core';

@Injectable ()

export class BenefitsSubtypesTranslations {

    public config: any = {
        component: 'subtype',
        display_name: 'Subtypes',
        description: 'Benefit subtype component'
    };

    public trans: any = {
        FinancialFactor: 'Financial Factor',
        FinancialValue: 'Financial Value',
        Improvement: 'Improvement',
        Subtype: 'Subtypes',
		Subtypesof: 'Subtypes of',
        Value: 'Value',
        Cancel: 'Cancel',
        add: 'Add',
        save: 'Save',
        enable: 'Enable',
        disable: 'Disable'
    };
}
