import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { ApiServiceService } from 'app/api-service.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { heapReset } from 'app/analytics-scripts/heap-scripts';

@Injectable()
export class RedirectGuard implements CanActivate {
  constructor(private apiService:ApiServiceService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.apiService.loggedIn$.pipe(
        map((result) => {
            if (!result) {
                heapReset();
                localStorage.removeItem('AnalyticsSession');
               this.router.navigate(['/login']);
            }
            const cachedUrl = sessionStorage.getItem('cachedUrl');
            if(cachedUrl) {
                this.router.navigate([`${cachedUrl}`]);
                sessionStorage.removeItem('cachedUrl');
            }
            return result;
        }
    ));
  }
}
