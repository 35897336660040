
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { ActionsVpDashboardTranslations } from './actions.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { ValueProp } from '@shared/models/value-prop.model';


@Component({
	selector: 'app-value-prop-dashboard-actions',
	templateUrl: './actions.component.html'
})
export class ValuePropDashboardActionsComponent implements OnInit, OnDestroy {
	@Input() valueProp: ValueProp;
	@Input() dashboard;
	@Input() showHelp: boolean;
	@Output() showShareCallback = new EventEmitter();
	@Input() header_color: string;
	@Input() showTranslate = false;

	ngUnsubscribe = new Subject();
	locked: boolean;
	subscriptionlockValueProp: Subscription;
	modalReference: NgbModalRef;
	cloneLoader: boolean;
	clonedName: string;
	subscriptioncloneValueProp: Subscription;
	fromMVP: boolean;

	clonedNameMVP: string;
	showClone = false;
	showShare = false;
	@Input() masterValuePropId: string;
	image_url: string;
	fullImagePath: string;

	constructor(
		private valuepropService: ValuepropService,
		private notificationService: NotificationService,
		private modalService: NgbModal,
		private masterValuePropService: MasterValuePropService,
		private router: Router,
		public trans: ActionsVpDashboardTranslations,
		private translationService: TranslationsV2Service,
		private commonService: CommonService

	) {

		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}
	ngOnInit() {

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.clonedName = this.valueProp.name;
		this.clonedNameMVP = this.valueProp.name;

		if (this.valueProp.closed == "1") {
			this.locked = true;
		}
		else if (this.valueProp.closed == "0") {
			this.locked = false;
		}
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	refreshTranslation() {


	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();

	}

	lockValueProp(type) {
		if (type == 'lock') {
			const payload = {
				"closed": "1"
			}
			this.valuepropService.lockValueProp(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

				if (response.result) {
					this.locked = true;
					this.valueProp.closed = '1';
					// this.getValuePropDetails(this.valuePropId);
					this.valuepropService.refreshDashboard.next('refreshDashboard');
					this.notificationService.success("Value Prop Locked Successfully", false);
				}
			});

		}
		if (type == 'unlock') {
			const payload = {
				"closed": "0"
			}
			this.valuepropService.lockValueProp(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

				if (response.result) {
					this.locked = false;
					this.valuepropService.refreshDashboard.next('refreshDashboard');
					this.notificationService.success("Value Prop Unlocked Successfully", false);
				}
			});
		}
	}


	valuePropId(_valuePropId: any, _statusTypeObj: { "value_prop_status_type_id": any; }) {
		throw new Error("Method not implemented.");
	}

	openModal(content) {
		this.modalReference = this.modalService.open(content, { size: 'lg', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}

	cloneValueProp() {


		this.notificationService.info('Cloning in progress...', false);
		this.cloneLoader = true;
		const payload = {
			"name": this.clonedName
		}

		this.valuepropService.cloneValueProp(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {

				this.cloneLoader = false;
				this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
					this.router.navigate(['valueprop/', response.result.value_prop.id]));
			}
			else if (response.result.success === false) {
				this.cloneLoader = false;
				this.notificationService.error(response.result.message, false);
			}
			this.showClone = !this.showClone;
		});
	}

	cloneValuePropFromMVP() {
		this.showClone = !this.showClone;

		this.notificationService.info('Cloning in progress...', false);
		this.cloneLoader = true;
		const payload = {
			"name": this.clonedNameMVP,
			value_prop_id: this.valuePropId
		}


		this.masterValuePropService.cloneValueProp((this.masterValuePropId ? this.masterValuePropId : this.valueProp.id), payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {


			if (response.result && (response.result.cloned_value_prop.success !== false)) {
				this.cloneLoader = false;
				this.router.navigate(['valueprop/', response.result.cloned_value_prop.value_prop.id]);
				this.loadInitialDetails();
				return;
				// this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
				// 	this.router.navigate(['valueprop/', response.result.value_prop.id]));
			}
			else if (response.result.cloned_value_prop.success === false) {
				this.cloneLoader = false;
				this.notificationService.error(response.result.message, false);
			}
		});
	}

	loadInitialDetails() {
		throw new Error("Method not implemented.");
	}

	funcShowShare() {
		this.showShareCallback.emit();
	}
}
