import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { ValueTrackerBenefit, ValueTrackerBenefitType, ValueTrackerBenefitTypeCategory } from '@shared/models/benefit-type.model';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { TrackValueBenefitTabTranslations } from './track-value-benefit-tab.translation';
import { ValueProp } from '@shared/models/value-prop.model';
import { Metric } from '@data/services/valuerealization/models/value-tracker.model';
import { MenuItem } from 'primeng/api';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Menu } from 'primeng/menu';
import { NotificationService } from '@services/notification.service';
@Component({
  selector: 'app-track-value-benefit-tab',
  templateUrl: './track-value-benefit-tab.component.html',
  styleUrls: ['./track-value-benefit-tab.component.scss'],
})
export class TrackValueBenefitTabComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() valueProp: ValueProp;

  benefitTypeList: ValueTrackerBenefitType[];
  searchedBenefitList: ValueTrackerBenefitType[];
  strSearch = '';
  ngUnsubscribe = new Subject();
  loading = false;

  showBenefitScratchpad = false;
  benefitScratchpad: Metric;
  columns: any[] = [
    { field: 'metric_name', transField: 'benefits' },
    { field: 'status', transField: 'status' },
    { field: 'expected_annual_value_fmt', transField: 'expectedTotalValue' },
    { field: 'expected_value_to_date_fmt', transField: 'expectedValueToDate' },
    { field: 'realized_value_to_date_fmt', transField: 'achievedValueToDate' },
    { field: 'variance_to_date_fmt', showIcon: true, transField: 'varianceToDate' },
  ];
  filters: {
    label: string;
    icon: string;
    styleClass: string;
    active: boolean;
    command: Function;
  }[];
  tableStatus: MenuItem[];
  feature39 = false;
  feature46 = false;

  showActive = true;
  showNA = false;
  showSoft = false;
  reviewId: number;
  cleanFlyout = false;

  constructor(
    public trans: TrackValueBenefitTabTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private valueRealizationService: ValuerealizationService,
    private businessReviewService: TrackerBusinessReviewService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  get allSelected(): boolean {
    return this.showActive && this.showNA && (this.showSoft || this.feature39);
  }

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.feature39 = this.commonService.checkFeature(39);
    this.feature46 = this.commonService.checkFeature(46);

    this.businessReviewService.businessReview
      .pipe(
        filter((businessReview) => !!businessReview),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((review) => {
        this.reviewId = review.id;
        this.getBenefitList(this.valuePropId, review.id);
      });
    this.businessReviewService.businessReviews$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getBenefitList(this.valuePropId, this.reviewId);
    });
    this.getBenefitList(this.valuePropId);

    this.businessReviewService.benefitRefresh$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((state) => state === true)
      )
      .subscribe(() => {
        this.updateValues(false);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  funcSetupFilterMenu() {
    this.filters = [
      {
        label: this.trans.trans.active.value,
        icon: this.showActive ? 'fa fa-check' : '',
        styleClass: 'menu_green',
        active: this.showActive,
        command: () => {
          this.showActive = !this.showActive;
          this.funcSetupFilterMenu();
          this.searchBenefits(this.strSearch, true);
        },
      },
      {
        label: this.trans.trans.na.value,
        icon: this.showNA ? 'fa fa-check' : '',
        styleClass: 'menu_red',
        active: this.showNA,
        command: () => {
          this.showNA = !this.showNA;
          this.funcSetupFilterMenu();
          this.searchBenefits(this.strSearch, true);
        },
      },
    ];
    if (!this.feature39) {
      this.filters.push({
        label: this.trans.trans.soft.value,
        icon: this.showSoft ? 'fa fa-check' : '',
        styleClass: 'menu_orange',
        active: this.showSoft,
        command: () => {
          this.showSoft = !this.showSoft;
          this.funcSetupFilterMenu();
          this.searchBenefits(this.strSearch, true);
        },
      });
    }
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    let payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.funcSetupFilterMenu();
      });
  }

  getBenefitsStatus(benefitStatus: string) {
    switch (benefitStatus) {
      case '1':
        return 'green';
      case '2':
        return 'violet';
      case '5':
      case '0':
      case '4':
        return 'red';
      case '3':
        return 'orange';
    }
  }

  openActiveTypesPmenu(benefit: ValueTrackerBenefit, menu: Menu, event: unknown) {
    this.tableStatus = [
      {
        label: this.trans.trans.active.value,
        command: () => {
          this.tableStatusChange(benefit, 1);
        },
      },
      {
        label: this.trans.trans.na.value,
        command: () => {
          this.tableStatusChange(benefit, 0);
        },
      },
    ];
    if (!this.feature39) {
      this.tableStatus.push({
        label: this.trans.trans.soft.value,
        command: () => {
          this.tableStatusChange(benefit, 3);
        },
      });
    }
    menu.toggle(event);
  }

  getBenefitList(valuePropId: string, businessReviewId?: number, silent: boolean = false) {
    if (!silent) {
      this.loading = true;
    }
    this.valueRealizationService
      .getValueRealizationBenefits(valuePropId, businessReviewId)
      .pipe(take(1))
      .subscribe((data) => {
        this.benefitTypeList = data.map((benefitType) => ({
          ...benefitType,
          id: benefitType.benefit_type_name === 'Financial' ? '1' : null,
          vcs: benefitType.value_categories.map((category) => ({
            ...category,
            activeCount: category.metrics.filter((benefit) => benefit.active === '1').length,
            naCount: category.metrics.filter((benefit) => benefit.active === '0' || benefit.active === '4').length,
            softCount: category.metrics.filter((benefit) => benefit.active === '2' || benefit.active === '3' || benefit.active === '5').length,
            metrics: category.metrics.map((benefit) => {
              const expect = Number(benefit.expected_value_to_date);
              const realized = Number(benefit.realized_value_to_date);
              const hasGainedValue = realized > expect;

              return {
                ...benefit,
                iconColor: hasGainedValue ? 'green' : 'red',
                icon: hasGainedValue ? 'arrow_upward' : 'arrow_downward',
              };
            }),
          })),
        }));
        this.searchBenefits(this.strSearch, silent);
        this.loading = false;
        if (this.businessReviewService.showBenefitSaved) {
          this.businessReviewService.showBenefitSaved = false;
          this.notificationService.success(this.trans.trans.benefitPerformance.value, false);
        }
        if (silent) {
          this.updateSelectedBenefit();
        }
      });
  }

  toggleBenefitTable(benefitCategory) {
    benefitCategory.expand = !benefitCategory.expand;
  }

  selectAllFilters(selected: boolean) {
    this.showActive = selected;
    this.showNA = selected;
    this.showSoft = selected;
    this.funcSetupFilterMenu();
    this.searchBenefits(this.strSearch, true);
  }

  searchBenefits(strSearch = '', statusUpdate = false) {
    this.strSearch = strSearch.toLowerCase();
    this.searchedBenefitList = this.benefitTypeList.map((type: any) => ({
      ...type,
      vcs: type.vcs.map((category) => ({
        ...category,
        expand: statusUpdate ? this.retrieveExpandedStatus(type.id, category.id) : !!strSearch,
        metrics: this.filterSearch(category, strSearch),
      })),
    }));
  }

  openBenefitScratchpad(benefit: Metric) {
    this.showBenefitScratchpad = true;
    this.benefitScratchpad = benefit;
  }

  closeBenefitScratchpad() {
    this.showBenefitScratchpad = false;
    this.benefitScratchpad = null;
  }

  noBenefitGroupToShow(
    vcs: {
      id: string;
      value_category_name: string;
      description?: string;
      metrics: ValueTrackerBenefit[];
      expand?: boolean;
    }[]
  ): boolean {
    return vcs.every((vcs) => vcs.metrics.length === 0);
  }

  updateValues(silent = true) {
    this.getBenefitList(this.valuePropId, null, silent);
    this.valueRealizationService.refreshDashboardData();
  }

  private updateSelectedBenefit() {
    this.cleanFlyout = true;
    this.cd.detectChanges();
    let valueCategory: ValueTrackerBenefitTypeCategory;
    let benefitFound: ValueTrackerBenefit;
    if (this.benefitScratchpad.benefit_type_id == '1') {
      valueCategory = this.searchedBenefitList[0].value_categories.find((category) => category.id == this.benefitScratchpad.value_category_id);
      benefitFound = valueCategory.metrics.find((metric) => metric.id === this.benefitScratchpad.id);
      if (valueCategory && benefitFound) {
        this.benefitScratchpad = valueCategory.metrics.find((metric) => metric.id == this.benefitScratchpad.id) as unknown as Metric;
      }
    } else {
      let found = false;
      for (let index = 0; index < this.searchedBenefitList.length && !found; index++) {
        const categoryInFlyout = this.searchedBenefitList[index].value_categories.find((category) => category.id == this.benefitScratchpad.value_category_id);
        benefitFound = categoryInFlyout.metrics.find((metric) => metric.id === this.benefitScratchpad.id);
        if (categoryInFlyout && benefitFound) {
          found = true;
          valueCategory = categoryInFlyout;
        }
      }
    }
    if (valueCategory && benefitFound) {
      this.benefitScratchpad = valueCategory.metrics.find((metric) => metric.id == this.benefitScratchpad.id) as unknown as Metric;
    }
    this.cleanFlyout = false;
    this.cd.detectChanges();
  }

  private filterSearch(
    category: {
      id: string;
      value_category_name: string;
      description?: string;
      metrics: ValueTrackerBenefit[];
      expand?: boolean;
    },
    searchParam: string
  ): ValueTrackerBenefit[] {
    if (this.allSelected) {
      return category.metrics.filter((benefit) => benefit.metric_name.toLowerCase().includes(searchParam));
    } else {
      return category.metrics.filter((benefit) => benefit.metric_name.toLowerCase().includes(searchParam) && this.checkStatus(benefit.active));
    }
  }

  private checkStatus(activeStatus: string): boolean {
    let correctStatus: boolean;
    switch (activeStatus) {
      case '0':
      case '4':
        correctStatus = this.showNA;
        break;
      case '1':
        correctStatus = this.showActive;
        break;
      case '2':
      case '3':
      case '5':
        correctStatus = this.showSoft;
        break;
    }
    return correctStatus;
  }

  private tableStatusChange(benefit: ValueTrackerBenefit, status: number): void {
    const payload = {
      value_prop_metric_id: benefit.value_prop_metric_id,
      active: status,
    };
    this.valuepropService.updateMetricActive(this.valuePropId, payload).subscribe((response) => {
      if (response.result && response.result.success !== false) {
        this.valueRealizationService.refreshDashboardData();
        this.getBenefitList(this.valuePropId, this.reviewId, true);
        this.notificationService.success(this.trans.trans.benefitUpdateSuccess.value, false);
      } else {
        this.notificationService.error(response.result.message, false);
      }
    });
  }

  private retrieveExpandedStatus(typeId: string, categoryId: string): boolean {
    return this.searchedBenefitList.find((type) => type.id === typeId)?.vcs.find((category) => category.id === categoryId)?.expand;
  }
}
