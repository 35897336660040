<div class="row">
	<div class="col-md-3 center">
		<div class="panel panel-white login-panel" id="js-alerts">
			<div class="panel-body">
				<div class="login-box">
					<div class="text-center"><img src="@assets/images/xDLIcon.png" alt="Value Execution by Xfactor.io" style="max-height: 40px;"></div>
					<div>
						<p class="text-center m-t-md">You have one more step before you can get in!</p>
						<form class="form-horizontal m-t-md" name="ChangePwdForm" #changePwdForm="ngForm" (ngSubmit)="ngOnSubmit(changePwdForm)">
							<div class="form-group">
								<input type="password" class="form-control" name="currentPassword" placeholder="Temp Password" required="" aria-invalid="true" ngModel>
							</div>
							<div class="form-group">
								<input type="password" class="form-control" name="newPassword" placeholder="New Password" required="" aria-invalid="true" ngModel>
							</div>
							<div class="form-group">
								<input type="password" class="form-control" name="confirmPassword" placeholder="Re-enter New Password" required="" aria-invalid="true" ngModel>
							</div>
							<div class="m-t-md login-btn-grp" *ngIf="!termsAccepted">
								<input type="checkbox" name="acceptTerms" value="" required="" aria-invalid="true" ngModel>
								<button class="text-center text-sm register-link-btn" (click)="open(termsOfUseContentModal)"> {{'intake.accept_terms_and_conditions' | translate}}</button>
							</div>
							<button type="submit" class="btn btn-success btn-block" id="loginBtn">Login</button>
							<div class="clearfix"></div>
						</form>
					</div>
					<p class="text-center m-t-xs text-sm">2018 © Decisionlink</p>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #termsOfUseContentModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{ trans.vp_dashboard.terms_and_conditions | translate }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="termsOfUse" [innerHTML]="termsOfUseContent">
		</div>
	</div>
</ng-template>