import { Injectable } from '@angular/core';

@Injectable()

export class CompetitiveTCOTranslations {

	public config: any = {
		component: 'CompetitiveTCO',
		display_name: 'Competitive TCO',
		description: 'Competitive TCO'
	};

	public trans: any = {
		competitive_tco: "Competitive TCO",
		show_more: "Show More than Costs Impacts",
		tco_summary: "TCO Summary"
	}
}