import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AddVPCompanyNoTransComponent } from './add-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldsetModule } from 'primeng/fieldset';
import { DropdownModule } from 'primeng/dropdown';
import { Translations } from '@data/services/translation/translation.service';
import { AddCompanyTransTranslations } from './add-company_no_trans.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	imports: [
		DropdownModule,
		FieldsetModule,
		NgbModule,
    MatTooltipModule,
		CommonModule,
		FormsModule,
		AreYouSureModule
	],
	providers: [
		Translations,
		AddCompanyTransTranslations,
		TranslationsV2Service,
	],
	declarations: [
		AddVPCompanyNoTransComponent
	],
	entryComponents: [AddVPCompanyNoTransComponent],
	exports: [AddVPCompanyNoTransComponent]
})
export class AddVPCompanyNoTransModule { }
