import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FactorsService } from '@data/services/factors/factors.service';
import { FactorRelationship } from '@shared/models/factors.models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from '../ae_factors.translation';

@Component({
  selector: 'app-factors-relation-table',
  templateUrl: './factors-relation-table.component.html',
  styleUrls: ['./factors-relation-table.component.scss'],
})
export class FactorsRelationTableComponent implements OnInit, OnDestroy {
  @Input() factorId: string;
  @Input() trans: DefaultTranslations;

  public relationships: FactorRelationship;

  private unsubscribe$ = new Subject<void>();

  constructor(private factorService: FactorsService) {}

  ngOnInit(): void {
    this.factorService
      .getFactorRelationships(this.factorId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data && data.result) {
          this.relationships = data.result;
          this.relationships.scratchpads.sort((a, b) => {
            if (a.type === 'Factor' && b.type !== 'Factor') {
              return -1;
            } else if (a.type !== 'Factor' && b.type === 'Factor') {
              return 1;
            } else {
              return a.factor_name > b.factor_name ? 1 : -1;
            }
          });
          this.relationships.factor_scales.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.relationships.connected_goals.sort((a, b) => (a.factor_name > b.factor_name ? 1 : -1));
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
