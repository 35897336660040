import { ChartOptions } from 'chart.js';

export const GoalChartOptions: ChartOptions = {
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
        },
        ticks: {
          fontColor: '#AFBBD1',
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#AFBBD1',
        },
      },
    ],
  },

  legend: {
    display: true,
    position: 'bottom',
    labels: {
      fontSize: 10,
      boxWidth: 5,
      usePointStyle: true,
    },
  },
  animation: {
    duration: 500,
  },
  responsive: true,
};
