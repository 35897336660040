<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>{{trans.trans.pain_points.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'pain'" [isTranslationV2]="true"
						[transObj]="trans.trans.pain_points" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div *ngIf="can_edit" class='col-xs-6'>
					<a (click)='openAddPainPoint()' class='btn btn_customizable pull-right'><i class='fa fa-plus'></i>{{trans.trans.add_new.value}}</a>
				</div>
			</div>
		</div>
		<div class='row marg-v'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tablePainPoints.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='Search' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tablePainPoints.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
		</div>
	</div>

	<div class='row'>
		<div class='col-xs-12'>
			<p-progressBar *ngIf="loading" class='keywest_progressbar' mode="indeterminate"></p-progressBar>
			<p-table #tablePainPoints [columns]="cols" [value]="painPoints" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns" [pSortableColumn]="col.field">
							{{trans.trans.pain_points.value}}
							<p-sortIcon [field]="col.field"></p-sortIcon>
							<app-editTranslation *ngIf="showTranslate" [component]="'pain'" [isTranslationV2]="true"
							[transObj]="trans.trans.pain_points" (callback)="getTranslations()"></app-editTranslation>
						</th>
						<th></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-category>
					<tr>
						<td>
							<span class="clickable" (click)="can_edit ? funcToggleEdit(category) : funcToggleView(category)">{{category.name}}</span>
							<i *ngIf="category.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="category.description" matTooltipPosition="above"></i>
							<span *ngIf="category.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="category.description" matTooltipPosition="above">
								info
							</span>
						</td>
						<td>
							<span *ngIf="can_edit" class='keywest_icon_group pull-right'>
								<span><i (click)='funcToggleEdit(category)' [matTooltip]="trans.trans.ch_edit.value" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
								<span>
									<app-are-you-sure [matTooltip]="trans.trans.ch_delete.value" matTooltipPosition="above" [message]="trans.trans.are_you_sure.value" text="" icon='fieldset_icon fa fa-trash' (callbackYes)='funcDelete(category)'></app-are-you-sure>
								</span>
							</span>
							<span *ngIf="!can_edit" class='keywest_icon_group pull-right'>
								<span><i (click)='funcToggleView(category)' class='fa fa-eye clickable' [matTooltip]="trans.trans.ch_view.value" matTooltipPosition="above"></i></span>
							</span>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddEditPainPoint' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-ae-painpoints *ngIf="toggleAddEditPainPoint && can_edit" [mode]='mode' [selectedElem]='selectedElem' (callback)='funcGet(); toggleAddEditPainPoint = false' (closeCallback)="closeAddEditPainPoint()"></app-ae-painpoints>
	<app-r-painpoints *ngIf="toggleAddEditPainPoint && !can_edit" [selectedElem]='selectedElem'></app-r-painpoints>
</p-sidebar>


<p-menu #rowMenu appendTo='body' [popup]="true" [model]="menu" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>