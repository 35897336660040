import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PainPoint } from '@data/services/valueposition/models/painpoint.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelV2ROPObjectiveTranslations } from './r_op_objectives.translation';

@Component({
  selector: 'app-r-op-objectives',
  templateUrl: './r_op_objectives.component.html',
})
export class ROpObjectivesComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() selectedElem: PainPoint = {
    name: '',
    description: '',
    id: '',
  };
  account_id: string;
  sidebar_title = 'Add Operational Objective';
  ngUnsubscribe$ = new Subject();

  showTranslate = false;

  constructor(
    private commonService: CommonService,
    public trans: ModelV2ROPObjectiveTranslations,
    private translationService: TranslationsV2Service
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.account_id = sessionStorage.getItem('aid');

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.trans.trans.view_op_ob.value;
      });
  }
}
