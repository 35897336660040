import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-model-assumptions',
  templateUrl: './assumptions.component.html',
  styleUrls: ['./assumptions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssumptionsModelComponent implements OnInit, OnDestroy {
  @Input() solutionID: number;
  @Input() tabInit: number;

  fullImagePath: string;
  image_url: string;
  driversLoader = false;
  drivers: { [klass: string]: any }[] = [];
  financialsLoader = false;
  financials: { [klass: string]: any }[] = [];
  scratchPadLoader = false;
  scratchPads: { [klass: string]: any }[] = [];
  sourceTypes: { [klass: string]: any }[] = [];

  subscriptiontriggerTabs: Subscription;
  subscriptiontoggleStaredKeyAssumption: Subscription;
  subscriptiongetDrivers: Subscription;
  subscriptiongetFinancials: Subscription;
  subscriptiongetScratchPadUnits: Subscription;
  subscriptioneditbaseLineAnswer: Subscription;
  subscriptioneditScratchpadUnit: Subscription;

  showDrivers = true;
  driversStarred = 0;
  searchTextDrivers = '';

  showFinancial = true;
  financialsStarred = 0;
  searchTextFinancial = '';

  showScratch = true;
  scratchStarred = 0;
  searchTextScratchpads = '';
  expandAll: boolean;

  constructor(private commonService: CommonService, private solutionService: SolutionService, private notification: NotificationService) {
    this.image_url = this.commonService.getImageUrl();

    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.loadDrivers();
    this.loadFinancials();
    this.loadScratchPadUnits();

    this.subscriptiontriggerTabs = this.solutionService.triggerTabs.subscribe((response) => {
      if (response === 'benefits') {
        this.loadDrivers();
        this.loadFinancials();
        this.loadScratchPadUnits();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptiontriggerTabs) {
      this.subscriptiontriggerTabs.unsubscribe();
    }
    if (this.subscriptiontoggleStaredKeyAssumption) {
      this.subscriptiontoggleStaredKeyAssumption.unsubscribe();
    }
    if (this.subscriptiongetDrivers) {
      this.subscriptiongetDrivers.unsubscribe();
    }
    if (this.subscriptiongetFinancials) {
      this.subscriptiongetFinancials.unsubscribe();
    }
    if (this.subscriptiongetScratchPadUnits) {
      this.subscriptiongetScratchPadUnits.unsubscribe();
    }
    if (this.subscriptioneditbaseLineAnswer) {
      this.subscriptioneditbaseLineAnswer.unsubscribe();
    }
    if (this.subscriptioneditScratchpadUnit) {
      this.subscriptioneditScratchpadUnit.unsubscribe();
    }
  }

  scroll(el) {
    const box = document.querySelector('.container_interior'),
      targetElm = document.querySelector('#' + el);
    targetElm.scrollIntoView({ behavior: 'smooth' });
  }

  funcExpand(expand?) {
    this.expandAll = !this.expandAll;
    this.showDrivers = expand ? expand : !this.showDrivers;
    this.showFinancial = expand ? expand : !this.showFinancial;
    this.showScratch = expand ? expand : !this.showScratch;
  }

  toggleStared(data, type_id) {
    let factor_id;
    let load;
    switch (type_id) {
      case 2:
        factor_id = data.driver_factor_id;
        load = this.loadDrivers();
        break;
      case 4:
        factor_id = data.financial_factor_id;
        load = this.loadFinancials();
        break;

      case 6:
        factor_id = data.factor_id;
        load = this.loadScratchPadUnits();
        break;
    }

    const payload = {
      factor_id: factor_id,
      factor_type_id: type_id,
      action: data.is_key === 1 ? 'Delete' : 'Create',
    };

    this.subscriptiontoggleStaredKeyAssumption = this.solutionService.toggleStaredKeyAssumption(this.solutionID, payload).subscribe(() => {
      load;
    });
  }

  loadDrivers() {
    this.driversLoader = true;
    this.subscriptiongetDrivers = this.solutionService.getDrivers(this.solutionID).subscribe(
      (response) => {
        if (response.result) {
          this.drivers = response.result.drivers;

          this.driversStarred = this.drivers.filter((x) => x.is_key === 1).length;
        }
        this.driversLoader = false;
      },
      () => {
        this.driversLoader = false;
      }
    );
  }

  loadFinancials() {
    this.financialsLoader = true;
    this.subscriptiongetFinancials = this.solutionService.getFinancials(this.solutionID).subscribe(
      (response) => {
        if (response.result) {
          this.financials = response.result.financials;
          this.financialsStarred = this.financials.filter((x) => x.is_key === 1).length;
        }
        this.financialsLoader = false;
      },
      () => {
        this.financialsLoader = false;
      }
    );
  }

  loadScratchPadUnits() {
    this.scratchPadLoader = true;
    this.subscriptiongetScratchPadUnits = this.solutionService.getScratchPadUnits(this.solutionID).subscribe(
      (response) => {
        if (response.result && response.result.units) {
          this.scratchPads = response.result.units;
          this.scratchStarred = this.scratchPads.filter((x) => x.is_key === 1).length;
        }
        this.scratchPadLoader = false;
      },
      () => {
        this.scratchPadLoader = false;
      }
    );
  }

  editDriverValue(driver) {
    const payload = {
      solution_id: this.solutionID,
      account_solution_metric_id: driver.account_solution_metric_id,
      key: 'driver_baseline_answer',
      value: driver.driver_value,
      factor: 'driver_factor_id',
      factor_id: driver.driver_factor_id,
      min: driver.min,
      max: driver.max,
      increment: driver.increment,
    };

    this.subscriptioneditbaseLineAnswer = this.solutionService.editbaseLineAnswer(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.loadDrivers();
        this.solutionService.triggerBenefit.next('reloadBenefits');
      }
    });
  }

  editFinancialValue(financial) {
    const payload = {
      solution_id: this.solutionID,
      account_solution_metric_id: financial.account_solution_metric_id,
      key: 'financial_baseline_answer',
      value: financial.financial_value,
      factor: 'financial_factor_id',
      factor_id: financial.financial_factor_id,
      min: financial.min,
      max: financial.max,
      increment: financial.increment,
    };

    this.subscriptioneditbaseLineAnswer = this.solutionService.editbaseLineAnswer(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.loadFinancials();
        this.solutionService.triggerBenefit.next('reloadBenefits');
      }
    });
  }

  editScratchpadUnit(unit) {
    const payload = {
      factor_id: unit['factor_id'],
      operand: unit['operand'],
      account_scratchpad_id: unit['scratchpad_id'],
      min: unit.min,
      max: unit.max,
      increment: unit.increment,
    };

    this.subscriptioneditScratchpadUnit = this.solutionService.editScratchpadUnit(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.loadScratchPadUnits();
        this.solutionService.triggerBenefit.next('reloadBenefits');
      }
    });
  }
}
