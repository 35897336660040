import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ValuePositionRoutingModule } from './value-position-routing.module';
import { ValuePositionComponent } from './value-position/value-position.component';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { ListService } from '@data/services/list/list.service';
import { AddValuePositionComponent } from './add-value-position/add-value-position.component';
import { FormsModule } from '@angular/forms';
import { DealdeskComponent } from './dealdesk/dealdesk.component';
import { DeploymentScheduleModule } from './deployment-schedule/deployment-schedule.module';
import { AddMasterValuePropComponent } from './add-master-value-prop/add-master-value-prop.component';
import { DocusignComponent } from './guided-discovery/docusign/docusign.component';
import { SearchCompanyModule } from './search-company/search-company.module';
import { FeatureOpportunityIdModule } from '@features/opportunity_id/opportunity_id.module';

import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { VMWareModule } from './guided-discovery/vmware/vmware.module';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { DiscoveryQuestionsModule } from 'app/value-prop/value-prop-dashboard/discovery/discovery_questions/questions.module';
import { CreateVPService } from '@data/services/create_vp/create_vp.service';
import { CostsModule } from 'app/value-prop/value-prop-dashboard/costs/costs.module';
import { BenefitsModule } from 'app/value-prop/value-prop-dashboard/benefits/benefits.module';
import { AssumptionsModule } from 'app/value-prop/value-prop-dashboard/discovery/assumptions/assumptions.module';
import { AddValuePositionModule } from './add-value-positionv2/add-value-position.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ApplicationPipesModule } from '../pipe/pipe.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { ShareModule } from '../value-prop/value-prop-dashboard/sharev2/share.module';
import { ValuePropCloneModule } from '../value-prop/value-prop-dashboard/clone/clone.module';
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './add-value-position/add-value-position.translation';
import { SituationsSharedModule } from '@shared_components/situations-shared/situations-shared.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ChooseAdventureComponent } from './choose-adventure/choose-adventure.component';
import { ChooseAdventureDialogComponent } from './choose-adventure-dialog/choose-adventure-dialog.component';
import { ChooseAdventureInternalModule } from '@shared/choose-adventure-internal/choose-adventure-internal.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';

@NgModule({
  imports: [
    SelectButtonModule,
    MenuModule,
    OverlayPanelModule,
    ProgressBarModule,
    AreYouSureModule,
    MatTooltipModule,
    ApplicationPipesModule,
    EditTranslationsModule,
    AddValuePositionModule,
    AssumptionsModule,
    BenefitsModule,
    CommonModule,
    SharedModule,
    ShareModule,
    SidebarModule,
    ValuePositionRoutingModule,
    FormsModule,
    FeatureOpportunityIdModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    InputMaskModule,
    SearchCompanyModule,
    VMWareModule,
    AddCompanyModule,
    DiscoveryQuestionsModule,
    DeploymentScheduleModule,
    CostsModule,
    DeploymentScheduleModule,
    ValuePropCloneModule,
    SituationsSharedModule,
    EllipsisMenuModule,
    BreadcrumbsModule,
    NoRecordsModule,
    DialogModule,
    ChooseAdventureInternalModule,
    FunctionPipeModule,
    CheckboxModule,
  ],
  declarations: [
    ValuePositionComponent,
    AddValuePositionComponent,
    DealdeskComponent,
    AddMasterValuePropComponent,
    DocusignComponent,
    ChooseAdventureComponent,
    ChooseAdventureDialogComponent,
  ],
  providers: [
    ValuepositionService,
    CreateVPService,
    DefaultTranslations,
    TranslationsV2Service,
    ListService,
  ],
  exports: [AddValuePositionComponent],
})
export class ValuePositionModule {}
