import { Injectable } from '@angular/core';

@Injectable()
export class GoalFormTranslation {
  public config: any = {
    component: 'goal-form',
    display_name: 'Add/Edit Goals',
    description: 'Form to add/edit a goal',
  };

  public trans: any = {
    addGoal: 'Add Goal',
    editGoal: 'Edit Goal',
    saveButton: 'Save',
    goalInput: 'Select Goal',
    valueInput: 'Set Target Value',
    baselineValue: 'Set Baseline Value',
    noOptionGoals: 'No goals available to add',
    units: 'Units',
    description: 'Description',
    createNewGoal: 'Create New Customer Specific Goal',
    unitType: 'Unit Type',
    name: 'Name',
    formula: 'Formula',
    linkedFactors: 'Linked Factors',
    cancel: 'Cancel',
  };
}
