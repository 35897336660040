import { ValueTracker } from './value-tracker.model';

export interface ValueRealizedCompany {
  company_name: string;
  created: Date;
  expected: number;
  expected_fr: string;
  id: number;
  number_of_trackers: number;
  trackers_in_rollup: number;
  percentage: number;
  realized: number;
  realized_fr: string;
  synopsis: string;
}

export enum RealizedFilter {
  all = '',
  mine = 'mine',
  sharedWithMe = 'shared_with_me',
}

export interface ValueRealized {
  value_prop_id: string;
  company_name: string;
  value_prop_name: string;
  created: string;
  original_user_id: string;
  original_value_prop_id: string;
}

export interface ValueRealizedListEntry extends ValueRealized {
  assigned_by: string;
}

export interface CompanyPromotedVP extends ValueRealized {
  total_benefits: string;
  total_benefits_fmt: string;
}

export interface ValueRealizedCompanyWithTrackers extends ValueRealizedCompany {
  expected_to_date_fmt: string;
  realized_to_date_fmt: string;
  trackers: ValueTracker[];
}

export interface TrackerBaselinePayload {
  user_id: string;
  custom_value_realized_date: string;
  increment_type_id: string;
  is_promote: number;
  name: string;
}

export interface TrackerNotePayload {
  value_prop_id: string;
  business_review_id: number;
  customer_facing: number;
  section: string;
  object_id: number;
  note: string;
}

export interface TrackerNoteEditPayload extends TrackerNotePayload {
  note_id: number;
}

export interface TrackerNoteResponsePayload {
  message: string;
  note_id: number;
}

export interface TrackerNote {
  id: number;
  value_prop_id: number;
  business_review_id: number;
  user_id: number;
  customer_facing: number;
  section: string;
  object_id: number;
  note: string;
  created: string;
  modified: string;
}
