<!--SOLUTION CATEGORIES-->
<div class="panel panel-default">
  <div class="panel-heading">
    <h3 class="panel-title">{{ trans.solution_category.model_categories | translate }}</h3>
  </div>
  <div class="panel-body">
    <div class="table-responsive">
      <div class="clearfix row">
        <div class="searchfilter clearfix col-4">
          <input type="text" class="form-control" [(ngModel)]="searchCategory" placeholder="Search Solution Categories" />
        </div>
        <div class="searchfilter clearfix col-8 text-right">
          <button class="btn btn-primary btn-sm" type="button" data-toggle="modal" data-target="#addCategory" data-backdrop="static" data-keyboard="false">Add Category</button>
        </div>
      </div>
      <table class="table table-responsive">
        <thead>
          <th width="100%">{{ trans.solution_category.category | translate }}</th>
          <th width="20%">{{ trans.solution_category.action | translate }}</th>
        </thead>

        <tbody>
          <tr *ngFor="let solution of solutionCategory">
            <td>{{ solution.category }}</td>
            <td class="text-center"><i class="fa fa-trash-o" aria-hidden="true" (click)="deleteCategory(solution)" role="button"></i></td>
          </tr>
          <tr [hidden]="hideLoder">
            <td class="text-center"><img class="loader" src="{{ fullImagePath }}" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- The Modal -->
    <div class="modal fade" id="addCategory">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">{{ trans.solution_category.add_new_category | translate }}</h4>
            <button type="button" class="close" data-dismiss="modal" #closePopup>&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <form action="/action_page.php">
              <div class="form-group">
                <label for="category">{{ trans.solution_category.category_name | translate }}</label>
                <input type="text" class="form-control" id="category" placeholder="Category Name" [(ngModel)]="catagoryName" [ngModelOptions]="{ standalone: true }" />
              </div>
              <button type="submit" class="btn btn-primary" (click)="addCategory(catagoryName)">{{ trans.solution_category.save | translate }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
