<div>
  <a class="a-link-color" routerLink="/trackers">
    <i class="fa fa-chevron-left"></i>
    {{ trans.trans.back.value }}
  </a>
</div>

<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-7">
          <div class="title">
            {{ trans.trans.valueRealizationDetail.value }}
          </div>
        </div>
        <div class="col-xs-5">
          <i *ngIf="commonService.isDev$" (click)="can_edit = !can_edit" matTooltip="Toggle can_edit" class="fa fa-code pull-right"></i>
          <a [matTooltip]="trans.trans.shareTooltip.value" *ngIf="can_edit" (click)="showTrackerShare = !showTrackerShare" class="btn btn_customizable pull-right">{{
            trans.trans.shareNew.value
          }}</a>
          <a (click)="toggleAssetDownload = !toggleAssetDownload" class="btn btn_customizable pull-right marg-h">{{ trans.trans.download.value }}</a>
        </div>
      </div>
    </div>

    <p-progressBar *ngIf="!vpRealization" mode="indeterminate"></p-progressBar>

    <div class="row">
      <div class="col-xs-3" *ngIf="vpRealization">
        <div class="panel panel-results panel-shadow" style="padding-top: 0px !important; padding-bottom: 0px !important; border-radius: 10px; margin-bottom: 10px">
          <div class="panel-body">
            <h2 class="keywest_card_title">{{ vpRealization.value_prop_name }}</h2>
            <div class="nav-text">{{ vpRealization.owner_name }}</div>
            <div class="nav-text">{{ trans.trans.contractDate.value }}: {{ vpRealization.valueprop_create_date }}</div>
            <div class="nav-text clickable" *ngIf="can_edit" (click)="navToVp(vpRealization.original_value_prop_id)">
              <a
                >{{ trans.trans.linkToBusiness.value }}
                <i class="fa fa-external-link"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="panel panel-results panel-shadow" style="padding-top: 0px !important; padding-bottom: 0px !important; border-radius: 10px; margin-bottom: 10px">
          <div class="panel-body">
            <h2
              class="keywest_card_title"
              [ngClass]="{
                keywest_title_underline_profit: determineGainLoss(vpRealization.benefits.unfmt_achieved_benefits, vpRealization.benefits.unfmt_expected_benefits),
                keywest_title_underline_loss: !determineGainLoss(vpRealization.benefits.unfmt_achieved_benefits, vpRealization.benefits.unfmt_expected_benefits)
              }"
            >
              {{ trans.trans.benefit.value }}: {{ vpRealization.benefits.achieved_benefits }}
            </h2>
            <div class="row">
              <div class="col-xs-5">
                <div class="nav-text">{{ vpRealization.benefits.expected_benefits }}</div>
                <div class="nav-text" style="margin-bottom: 13px">{{ trans.trans.expected.value }}</div>
              </div>
              <div class="col-xs-1 keywest_card_line_seperator"></div>
              <div
                class="col-xs-5"
                [ngClass]="{
                  text_danger: !determineGainLoss(vpRealization.benefits.unfmt_achieved_benefits, vpRealization.benefits.unfmt_expected_to_date_benefits),
                  text_success: determineGainLoss(vpRealization.benefits.unfmt_achieved_benefits, vpRealization.benefits.unfmt_expected_to_date_benefits)
                }"
              >
                <div class="nav-text">{{ vpRealization.benefits.expected_to_date_benefits }}</div>
                <div class="nav-text" style="margin-bottom: 13px">{{ trans.trans.expectedToDate.value }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel panel-results panel-shadow" style="padding-top: 0px !important; padding-bottom: 0px !important; border-radius: 10px; margin-bottom: 10px">
          <div class="panel-body">
            <h2
              class="keywest_card_title"
              [ngClass]="{
                keywest_title_underline_profit: determineGainLoss(vpRealization.costs.unfmt_original_expected_costs, vpRealization.costs.unfmt_expected_costs),
                keywest_title_underline_loss: !determineGainLoss(vpRealization.costs.unfmt_original_expected_costs, vpRealization.costs.unfmt_expected_costs)
              }"
            >
              {{ trans.trans.costs.value }}: {{ vpRealization.costs.expected_costs }}
            </h2>
            <div class="row">
              <div class="col-xs-5">
                <div class="nav-text">{{ vpRealization.costs.original_expected_costs }}</div>
                <div class="nav-text" style="margin-bottom: 13px">{{ trans.trans.expected.value }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel panel-results panel-shadow" style="padding-top: 0px !important; padding-bottom: 0px !important; border-radius: 10px; margin-bottom: 10px">
          <div class="panel-body">
            <h2
              class="keywest_card_title"
              [ngClass]="{
                keywest_title_underline_profit: determineGainLoss(vpRealization.payback.expected_payback, vpRealization.payback.achieved_payback),
                keywest_title_underline_loss: !determineGainLoss(vpRealization.payback.expected_payback, vpRealization.payback.achieved_payback)
              }"
            >
              {{ trans.trans.payback.value }}: {{ vpRealization.payback.achieved_payback | formatNumber }} {{ trans.trans.months.value }}
            </h2>
            <div class="row">
              <div class="col-xs-5">
                <div class="nav-text">{{ vpRealization.payback.expected_payback | formatNumber }} {{ trans.trans.months.value }}</div>
                <div class="nav-text" style="margin-bottom: 13px">{{ trans.trans.expected.value }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel panel-results panel-shadow" style="padding-top: 0px !important; padding-bottom: 0px !important; border-radius: 10px; margin-bottom: 10px">
          <div class="panel-body">
            <h2
              class="keywest_card_title"
              [ngClass]="{
                keywest_title_underline_profit: determineGainLoss(vpRealization.roi.achieved_roi, vpRealization.roi.expected_roi),
                keywest_title_underline_loss: !determineGainLoss(vpRealization.roi.achieved_roi, vpRealization.roi.expected_roi)
              }"
            >
              {{ trans.trans.roi.value }}: {{ vpRealization.roi.achieved_roi }}%
            </h2>
            <div
              [ngClass]="{
                text_danger: !determineGainLoss(vpRealization.roi.achieved_roi, vpRealization.roi.expected_roi),
                text_success: determineGainLoss(vpRealization.roi.achieved_roi, vpRealization.roi.expected_roi)
              }"
            >
              <div class="nav-text">{{ vpRealization.roi.expected_roi }}%</div>
              <div class="nav-text" style="margin-bottom: 13px">{{ trans.trans.expected.value }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-9" *ngIf="vpRealization">
        <div class="panel panel-results panel-shadow" style="padding: 20px 5px !important; border-radius: 10px; margin-bottom: 30px">
          <div class="panel-body clickable">
            <div class="row" style="margin: 10px 5px">
              <div class="col-xs-1"></div>
              <div class="col-xs-3">
                <fieldset class="radio-control marg-v">
                  <div>
                    <label>
                      <input type="radio" value="line chart" [(ngModel)]="chartView" />
                      <span (click)="chartView = 'line chart'">
                        {{ trans.trans.vasluVsAchieved.value }}
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>

              <div class="col-xs-3">
                <fieldset class="radio-control marg-v">
                  <div>
                    <label>
                      <input type="radio" value="realized percent" [(ngModel)]="chartView" />
                      <span (click)="chartView = 'realized percent'">
                        {{ trans.trans.realizedPercent.value }}
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>

              <div class="col-xs-3">
                <fieldset class="radio-control marg-v">
                  <div>
                    <label>
                      <input type="radio" value="realized dollars" [(ngModel)]="chartView" />
                      <span (click)="chartView = 'realized dollars'">
                        {{ trans.trans.realizedDollars.value }}
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>
              <div class="col-xs-1"></div>
            </div>

            <div *ngIf="chartView === 'line chart'" style="padding: 20px">
              <app-tracker-line [data]="lineChartData" [chartColors]="chartColors" [currency]="valueRealizationCurrency" (noteCallback)="saveNote($event)"></app-tracker-line>
            </div>

            <div *ngIf="chartView === 'realized percent'" style="padding: 20px">
              <app-tracker-stacked-bar [data]="barChartDataPercent" [chartColors]="chartColors"></app-tracker-stacked-bar>
            </div>

            <div *ngIf="chartView === 'realized dollars'" style="padding: 20px">
              <app-tracker-stacked-bar [data]="barChartDataDollars" [chartColors]="chartColors" [currency]="valueRealizationCurrency"></app-tracker-stacked-bar>
            </div>
          </div>
        </div>
        <div class="panel panel-results panel-shadow" style="padding: 20px !important; border-radius: 10px; margin-bottom: 30px">
          <app-collapse-container title="Survey">
            <div style="padding: 20px">
              <button *ngIf="can_edit && !surveyDatePicker" class="btn btn_customizable pull-right" (click)="pickDateForNewSurvey()">
                <i class="fa fa-plus"></i>{{ trans.trans.createSurvey.value }}
              </button>
            </div>

            <div style="padding: 10px" *ngIf="surveyDatePicker">
              <div class="row">
                <div class="col-xs-6">
                  <label class="sidebar_label" for="name">{{ trans.trans.name.value }}</label>
                  <input class="form-control" type="text" name="name" [(ngModel)]="surveyName" />
                </div>

                <div class="col-xs-6">
                  <label class="sidebar_label" for="name">{{ trans.trans.selectTemplate.value }}</label>
                  <p-dropdown
                    appendTo="body"
                    name="templates"
                    optionLabel="name"
                    appendTo="body"
                    [options]="templates"
                    [filter]="true"
                    filterBy="name"
                    [autoDisplayFirst]="false"
                    [(ngModel)]="surveyTemplate"
                    class="keywest_dropdown"
                    [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
                  ></p-dropdown>
                </div>
              </div>
              <div class="row" style="margin-top: 1em">
                <div class="col-xs-12">
                  <label class="sidebar_label">{{ trans.trans.selectDate.value }}</label>
                </div>
                <div class="col-xs-12">
                  <p-calendar [(ngModel)]="customSurveyDate" view="month" dateFormat="mm/yy" [placeholder]="trans.trans.selectADate.value" [readonlyInput]="true"></p-calendar>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <button
                    class="btn btn_customizable pull-right"
                    *ngIf="can_edit"
                    [matTooltip]="trans.trans.createSurvey.value"
                    matTooltipPosition="above"
                    (click)="getActiveSurveyTemplate(true)"
                  >
                    {{ trans.trans.save.value }}
                  </button>
                  <app-are-you-sure
                    matTooltipPosition="above"
                    [message]="trans.trans.are_you_sure.value"
                    class="btn cancel-confirm pull-right marg-h"
                    icon=""
                    [text]="trans.trans.cancel.value"
                    (callbackYes)="clearSurveyForm()"
                  ></app-are-you-sure>
                </div>
              </div>
            </div>

            <div style="padding: 15px 15px 30px 15px" *ngIf="vpRealization && !surveyDatePicker">
              <app-survey [can_edit]="can_edit" [realizaionIdInput]="vpRealization.value_prop_id" [activeSurveyTemplate]="activeSurveyTemplate"></app-survey>
            </div>
          </app-collapse-container>
        </div>

        <div class="panel panel-results panel-shadow" style="padding: 20px !important; border-radius: 10px; margin-bottom: 30px">
          <app-collapse-container title="Benefits">
            <p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

            <p-menu #status appendTo="body" [popup]="true" [model]="statusMenu" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

            <div *ngIf="!benefitView" style="padding: 20px !important; padding-bottom: 0px !important; border-radius: 10px; margin-bottom: 10px">
              <div style="margin: 15px 0">
                <button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="addBenefit()"><i class="fa fa-plus"></i>{{ trans.trans.addBenefit.value }}</button>
              </div>
              <div *ngIf="!benefitView">
                <div class="row">
                  <div class="col-xs-12">
                    <span class="clickable pull-right row-limit" (click)="row.toggle($event)"
                      >{{ trans.trans.rowLimit.value }} {{ pageLimit }} <i class="fa fa-chevron-down"></i
                    ></span>
                  </div>
                </div>

                <div class="row" style="margin-bottom: 40px">
                  <div *ngIf="benefits.length > 0" class="col-xs-12">
                    <p-table
                      #tableSolutionList
                      [value]="benefits"
                      [columns]="benefitTableCols"
                      [paginator]="true"
                      [rows]="pageLimit"
                      styleClass="keywest_table"
                      tableStyleClass="keywest_table"
                    >
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th *ngFor="let col of benefitTableCols" [pSortableColumn]="col.sortField">
                            <ng-container *ngIf="col.field === 'active'">
                              <select
                                *ngIf="can_edit"
                                class="form-control active-type benefitStatusElement"
                                style="width: 100px"
                                name="benefit_active"
                                onLoad="filterActive($event, tableSolutionList, col)"
                                [(ngModel)]="activeFilter"
                                (ngModelChange)="filterActive($event, tableSolutionList, col)"
                                (onChange)="filterActive($event, tableSolutionList, col)"
                              >
                                <option *ngFor="let activeType of activeTypes" [value]="activeType.value" class="benefitStatusElement">
                                  {{ activeType.label }}
                                </option>
                              </select>
                            </ng-container>

                            <ng-container *ngIf="col.field !== 'active'" style="white-space: nowrap">
                              {{ [col.header] }}
                              <p-sortIcon *ngIf="col.sort" [field]="col.sortField"></p-sortIcon>
                            </ng-container>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-ben>
                        <tr>
                          <td *ngFor="let col of benefitTableCols">
                            <div *ngIf="col.header !== 'Status'">
                              {{ ben[col.field] }}
                            </div>

                            <div *ngIf="col.header === 'Status' && can_edit" class="clickable" (click)="funcBuildStatusMenu(status, ben, $event)">
                              {{ getStatus(ben[col.field]) }}
                              <i class="fa fa-chevron-down"></i>
                            </div>
                            <div *ngIf="col.header === 'Status' && !can_edit">{{ getStatus(ben[col.field]) }}</div>
                          </td>
                          <td
                            class="clickable"
                            (click)="funcOpensidebar(ben)"
                            style="white-space: nowrap"
                            [ngStyle]="{ color: determineGainLoss(ben.realized_to_date, ben.expected_to_date) ? 'green' : 'red' }"
                          >
                            {{ ben.difference }}
                            <i
                              class="fa"
                              [ngClass]="{
                                'fa-arrow-up': determineGainLoss(ben.realized_to_date, ben.expected_to_date),
                                'fa-arrow-down': !determineGainLoss(ben.realized_to_date, ben.expected_to_date)
                              }"
                            ></i>
                          </td>
                          <td style="white-space: nowrap">
                            <div *ngIf="determineGainLoss(ben.realized_to_date, ben.expected_to_date) && can_edit" (click)="sendToMarketing(ben)" class="clickable">
                              <i class="fa fa-envelope-open-o"></i>
                              {{ trans.trans.notifyMarketing.value }}
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="benefitView">
              <div (click)="benefitView = false" role="button" class="m-b-xxl m-l-xxl a-link-color"><i class="fa fa-chevron-left"></i> {{ trans.trans.back.value }}</div>
              <app-add-benefits-component
                *ngIf="benefitView && !modelsv2"
                [showTranslate]="showTranslate"
                (cancelBenefitCallback)="benefitView = false"
                [surveyFields]="vpRealization.survey_ids"
                (saveBenefitCallback)="funcCloseBenefits(true)"
                [selectedBenefit]="selectedBenefit"
                [valueProp]="{ id: vpRealization.value_prop_id }"
              >
              </app-add-benefits-component>

              <div *ngIf="benefitView && solutionId && modelsv2" class="models_panel" style="margin-bottom: 20px">
                <app-ae-benefits
                  [solutionId]="solutionId"
                  [can_edit]="can_edit"
                  [isOnTheFlyBenefit]="true"
                  [surveyFields]="vpRealization.survey_ids"
                  [valuePropId]="vpRealization.value_prop_id"
                  (callback)="funcCloseBenefits(true)"
                  (cancelBenefitCallback)="benefitView = false"
                ></app-ae-benefits>
              </div>
            </div>
          </app-collapse-container>
        </div>

        <div *ngIf="isCustomerSuccessManager" class="panel panel-results panel-shadow" style="padding: 20px !important; border-radius: 10px; margin-bottom: 30px">
          <app-collapse-container title="Goals">
            <app-tracker-goals [valuePropId]="valuePropId" [canEdit]="can_edit"></app-tracker-goals>
          </app-collapse-container>
        </div>

        <div class="panel panel-results panel-shadow" style="padding: 20px !important; border-radius: 10px; margin-bottom: 30px">
          <app-collapse-container title="Costs">
            <app-tracker-cost [embedded]="true" [vpRealization]="vpRealization" [can_edit]="can_edit" (callback)="getValueRealization(valuePropId)"></app-tracker-cost>
          </app-collapse-container>
        </div>

        <div class="panel panel-results panel-shadow" style="padding: 20px !important; border-radius: 10px; margin-bottom: 30px">
          <app-collapse-container title="Case Studies">
            <app-tracker-case-studies [can_edit]="can_edit" [vpRealization]="vpRealization"></app-tracker-case-studies>
          </app-collapse-container>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="toggleAssetDownload" *ngIf="toggleAssetDownload" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-value-tracker-assets [valuePropId]="valuePropId" [value_survey_id]="lastSurveyId"></app-value-tracker-assets>
</p-sidebar>

<p-sidebar [(visible)]="showTrackerShare" *ngIf="showTrackerShare" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="funcCloseSidebar()">
  <app-value-tracker-share *ngIf="showTrackerShare" [valuePropId]="valuePropId" (closeCallback)="closeTrackerShare()"></app-value-tracker-share>
</p-sidebar>

<p-sidebar [(visible)]="showBenefitSidebar" styleClass="keywest_sidebar p-sidebar-xl" position="right" (onHide)="funcCloseSidebar()">
  <div class="h-100" *ngIf="selectedBenefit">
    <app-tracker-benefit
      [can_edit]="can_edit"
      [valuePropId]="valuePropId"
      [benefit]="selectedBenefit"
      [term]="vpRealization.term"
      (callback)="refreshBenefit($event)"
    ></app-tracker-benefit>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="showAssetSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="funcCloseSidebar()">
  <div class="h-100" *ngIf="showAssetSidebar">
    <app-asset-list [assetList]="valueProp?.assets?.message" [valuePropId]="valuePropId"></app-asset-list>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="showMarketingSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="funcCloseSidebar()">
  <div class="h-100" *ngIf="showMarketingSidebar">
    <app-notify-marketing [benefit]="selectedBenefit" [valuePropId]="valuePropId" (callback)="funcCloseSidebar()"></app-notify-marketing>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="showBenefitNotes" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="funcCloseSidebar()">
  <div class="h-100" *ngIf="showBenefitNotes">
    <app-tracker-benefit-notes [benefit]="benefitNotes" [can_edit]="can_edit" (callback)="saveNote($event)"></app-tracker-benefit-notes>
  </div>
</p-sidebar>
