import { Injectable } from '@angular/core';

@Injectable()
export class TranslationsBaseService {
  typeObjectKeys<T extends { [key: string]: any }>(object: T) {
    type propType = keyof typeof object;
    return object as { [originalPropType in propType]: any };
  }
  public t: any = {};
}
