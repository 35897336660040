import { Injectable } from '@angular/core';

@Injectable ()

export class ValueRealizedDetailTranslations {

    public config: any = {
        component: 'valueRealizedDetail',
        display_name: 'Value Realized Detail',
        description: 'Surveys and case studies of a value realization'
    };

    public trans: any = {
        addValueRealized: 'Add Value Realization',
        addCasestudy: 'Add Case Study',
        measurePerceptionTime: 'Measure the in time perception of value achieved by specific customers based on agreed value propositions',
        captureImprovements: 'Capture empirical improvements, customer sentiments, details and media about customer success',
        back: 'Back',
        noSurveyAvailable: 'No Survey Template Available',
        addCaseStudy: 'Add Case Study',
        addSurvey: 'Add Survey',
        caseStudies: 'Case Studies',
        createSurveyQuestions: 'Create Survey Questions',
        surveys: 'Surveys'
    };
}
