import { NgModule } from '@angular/core';

import { CaseStudiesComponent } from "./case-studies.component";

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelCaseStudyDetailModule } from './casestudy-detail/casestudy-detail.module';
 
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { AddCaseStudyModule } from './add-casestudy/add-casestudy.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CaseStudiesTranslations } from './case-studies.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { CaseStudyTagsModule } from './tags/tags.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		CaseStudiesComponent,
	],
	imports: [
		CaseStudyTagsModule,
		AddCaseStudyModule,
		TableModule,
		SidebarModule,
		ApplicationPipesModule,
		ModelCaseStudyDetailModule,
		NgbModule,
		FormsModule,
		CommonModule,
		AreYouSureModule,
    MatTooltipModule,
		EditTranslationsModule,
        MenuModule
	],
	providers: [
		TranslationsV2Service,
		CaseStudiesTranslations
	],
	entryComponents: [
		CaseStudiesComponent,
	],
	exports: [CaseStudiesComponent]
})
export class ModelCaseStudiesModule { }