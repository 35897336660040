import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrackValueDashboardTranslation } from '../track-value-dashboard/track-value-dashboard.translation';
import { ValueProp } from '@shared/models/value-prop.model';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';

@Component({
  selector: 'app-track-value-tabs',
  templateUrl: './track-value-tabs.component.html',
  styleUrls: ['./track-value-tabs.component.scss'],
})
export class TrackValueTabsComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() valueProp: ValueProp;
  businessReviewData: BusinessReviewData[] = [];
  ngUnsubscribe = new Subject();
  constructor(
    public trans: TrackValueDashboardTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public businessReviewService: TrackerBusinessReviewService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.getTranslations();
    });
    this.businessReviewService.businessReviews$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((businessReviews) => {
      this.businessReviewData = businessReviews;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    let payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
