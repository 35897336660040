import { Injectable } from '@angular/core';

@Injectable ()

export class RepSimpleTranslations {

    public config: any = {
        component: 'repSimple',
        display_name: 'Rep Simple',
        description: 'Rep simple section'
    };

    public trans: any = {
        download: 'Download',
        clone_this_value_prop: 'Clone this Value Prop',
        view_all_details: 'View All Details',
        actions: 'Actions',
        lock: 'Lock Value Prop',
        unlock: 'Unlock Value Prop',
        share: 'Share',
        next: 'Next',
        clone: 'Clone',
        back: 'Back',
        edit_translations: 'Edit Translations',
        download_assets: 'Download Assets',
        discovery_questions: 'Discovery Questions',
        edit_status: 'Click to edit status',
        tour: 'Tour',
        content1: 'Let\'s create a Value Prop.',
        content2: 'Start with the basics below and then we\'ll get into the details.',
        support: 'Support',
        titleNavigation: 'Navigation',
        contentNavigation: 'Click back to any previous step at any time.',
        titleAction: 'Action Items',
        contentAction: 'Here are your action items for sharing, status, downloads, and locking.',
        titleInfo: 'More Information',
        contentInfo: 'In this section you can click any of the labels for more information.',
        titleBenefitsChart: 'Benefits Chart',
        contentBenefitsChart: `This chart provides a breakdown of value by cagegory.
        You can click on each value in the legend to see the specific benefits.`,
        titleRoi: 'ROI',
        contentRoi: 'This chart provides a breakdown of your benefits vs. costs with ROI over the term of the evaluation.',
        titleCashflow: 'Cashflow',
        contentCashflow: 'This Cash Flow table provides context and basics for most calculations of value you will need.',
        addCosts: 'Add Costs',
        reviewCosts: 'Review Costs (Optional)',
        reviewCostsSimple: 'Review Costs',
        reviewResults: 'Review Results',
        vpLockedSucces: 'Value Prop Locked Successfully',
        vpUnlockedSucces: 'Value Prop Unlocked Successfully',
        statusUpdateSuccess: 'Status Updated successfully',
        logginError: 'Error logging in. Please try again',
        click_to_change_status: 'Click to change Status',
        cloneInProgress: 'Cloning in progress...',
        defineScenario: 'Define Scenario',
        aboutYou: 'About You',
        reviewBenefits: 'Review Benefits',
        prioritizeBenefits: 'Prioritize Benefits',
        provideNumbers: 'Provide Numbers',
        promoteToRealization: 'Promote To Realization'
    };
}
