import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AssetListComponent } from './assets.component';
import { AssetListTranslations } from './assets.translation';

@NgModule({
  imports: [CommonModule],
  exports: [AssetListComponent],
  declarations: [AssetListComponent],
  providers: [TranslationsV2Service, AssetListTranslations],
})
export class AssetListModule {}
