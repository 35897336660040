import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueRealizedSurvey, ValueRealizedSurveyTemplate } from '@data/services/valuerealization/models/value-realized-survey.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subject } from 'rxjs';
import { takeUntil, take, filter, finalize } from 'rxjs/operators';
import { TrackValueDashboardTranslation } from '../track-value-dashboard/track-value-dashboard.translation';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';

@Component({
  selector: 'app-track-value-survey-tab',
  templateUrl: './track-value-survey-tab.component.html',
  styleUrls: ['./track-value-survey-tab.component.scss'],
})
export class TrackValueSurveyTabComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;

  strSearch = '';
  loading = false;
  surveyTemplates: ValueRealizedSurveyTemplate[];
  selectedSurvey: ValueRealizedSurvey;
  addNewSurvey = false;
  columns = [
    { header: 'Respondent', field: 'respondent' },
    { header: 'Completed By', field: 'created_by' },
    { header: 'Completion Date', field: 'completed_date' },
    { header: 'Survey Period', field: 'business_review_name' },
  ];
  ngUnsubscribe = new Subject();
  businessReviewId: number;
  showAddNewSurvey = false;

  constructor(
    public trans: TrackValueDashboardTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private valueRealizationService: ValuerealizationService,
    public businessReviewService: TrackerBusinessReviewService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.businessReviewService.businessReview
      .pipe(
        filter((businessReview) => !!businessReview),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((review) => {
        this.businessReviewId = review.id;
        this.getSurveyList(review.id);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    let payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.columns = [
          { header: this.trans.trans.respondent.value, field: 'respondent' },
          { header: this.trans.trans.created_by.value, field: 'created_by' },
          { header: this.trans.trans.completed_date.value, field: 'completed_date' },
          { header: this.trans.trans.business_review_name.value, field: 'business_review_name' }
        ];
      });
  }

  getSurveyList(businessReviewId: number): void {
    this.valueRealizationService
      .getVRSurveysByBusinessReview(this.valuePropId, null)
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe((data) => {
        this.surveyTemplates = data;
      });
  }

  toggleSurveyTable(template: ValueRealizedSurveyTemplate): void {
    template.expand = !template.expand;
  }

  filterTemplates(templates: ValueRealizedSurveyTemplate[]): ValueRealizedSurveyTemplate[] {
    return templates?.filter((template) => template.surveys.length > 0);
  }

  closeSidebar(): void {
    this.showAddNewSurvey = false;
    this.selectedSurvey = null;
    this.getSurveyList(this.businessReviewId);
  }

  openSurvey(survey: ValueRealizedSurvey) {
    this.showAddNewSurvey = true;
    this.selectedSurvey = survey;
  }

  deleteSurvey(survey: ValueRealizedSurvey) {
    this.valueRealizationService
      .deleteSurvey(survey.value_survey_id)
      .pipe(take(1))
      .subscribe(() => {
        this.getSurveyList(this.businessReviewId);
      });
  }
}
