import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-detail-card',
  templateUrl: 'detail-card.component.html',
})
export class DetailCardComponent implements OnInit {
  @Input() isCollapsible = true;
  @Input() title: string;
  @Input() text: string;

  show = false;
  constructor() {}

  ngOnInit() {
    if (!this.isCollapsible) {
      this.show = true;
    }
  }
}
