import { Injectable } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import { AccountAssetCreatePayload, AccountAssetEditPayload, AccountAssetResponse, Asset, AssetElementPayload, AssetUploadResponse, ManualTemplateAssignPayload, Template, TemplateResponse } from '@shared/models/asset.models';
import { Observable } from 'rxjs/internal/Observable';
import { CommonService } from '../common/common.service';
import { catchError, pluck } from 'rxjs/operators';
import { AssetLog } from './asset-log.model';
import { Subject, throwError } from 'rxjs';
import { GenerateStandardAssetReq } from '../valueprop/models/asset.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AssetService {
  public refreshTemplate$ = new Subject<any>();

  constructor(private commonService: CommonService) {}

  assetLogId: string;
  public uploadTemplate(payload: FormData): Observable<APIResult<AssetUploadResponse>> {
    const endpoint = 'asset/template/upload';
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public editTemplate(templateId: string, payload: FormData): Observable<APIResult<TemplateResponse>> {
    const endpoint = 'asset/template/update/' + templateId;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public saveAccountAssets(payload: AccountAssetCreatePayload): Observable<APIResult<number>> {
    const endpoint = 'asset/';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public editAccountAssets(accountAssetId: string, payload: AccountAssetEditPayload): Observable<APIResult<AccountAssetResponse>> {
    const endpoint = 'asset/' + accountAssetId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteAsset(accountAssetId: string): Observable<APIResult<string>> {
    const endpoint = 'asset/' + accountAssetId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public deleteTemplateFile(templatePathId: string): Observable<APIResult<string>> {
    const endpoint = 'asset/deleteTemplateFile/' + templatePathId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public saveAssetElements(accountAssetId: string, payload: AssetElementPayload): Observable<APIResult<AccountAssetResponse>> {
    const endpoint = 'asset/elements/' + accountAssetId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public manuallyAssignTemplatePath(payload: ManualTemplateAssignPayload): Observable<APIResult<string>> {
    const endpoint = 'asset/manuallyAssignTemplatePath';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public editAssetElements(accountAssetId: string, payload: AssetElementPayload): Observable<APIResult<AccountAssetResponse>> {
    const endpoint = 'asset/elements/' + accountAssetId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getAsset(accountAssetId: string): Observable<APIResult<Asset>> {
    const endpoint = 'asset/' + accountAssetId;
    return this.commonService.getAPIService(endpoint);
  }

  public getAssetTemplate(template_id: string) {
    const endpoint = 'asset/template/upload/' + template_id;
    return this.commonService.getAPIService(endpoint);
  }

  public deleteAssetTemplate(template_id: string) {
    const endpoint = 'asset/template/delete/' + template_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getAssetTemplateLogs(template_id: string): Observable<AssetLog[]> {
    const endpoint = 'asset/template/' + template_id + '/logs';
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  public getCaseStudyAssetTemplate(): Observable<APIResult<Template> | string> {
    const endpoint = 'asset/caseStudyTemplate';
    return this.commonService.getAPIService(endpoint);
  }

  public readMasterValuePropTemplates(masterValuePropId: string | number): Observable<APIResult<Template[]>> {
    const endpoint = 'asset/masterValuePropTemplates/' + masterValuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public readValuePropGroupTemplates(valuePropGroupId: string): Observable<APIResult<Template[]>> {
    const endpoint = 'asset/valuePropGroupTemplates/' + valuePropGroupId;
    return this.commonService.getAPIService(endpoint);
  }
  public setLogId(value: string) {
   this.assetLogId = value;
  }

  public getLogId() {
    return this.assetLogId;
  }

  public deleteAssetLogId() {
    this.assetLogId = null;
  }

  public getAssetThemeTemplates() {
    const endpoint = 'asset/themeTemplate';
    return this.commonService.getAPIService(endpoint);
  }

  public uploadThemeTemplate(file: FormData) {
    const endpoint = 'asset/themeTemplate';
    return this.commonService.postAPIService(endpoint, file, 'multipart/form-data');
  }

  public generateStandardAssests(payload: GenerateStandardAssetReq) {
    const endpoint = '/asset/standardAssets';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public deleteThemeTemplate(themeId: string) {
    const endpoint = `asset/themeTemplate/delete/${themeId}`;
    return this.commonService.deleteAPIService(endpoint);
  }
}
