import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStudySidebarComponent } from './case-study-sidebar/case-study-sidebar.component';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { ChipsModule } from 'primeng/chips';
import { SidebarModule } from 'primeng/sidebar';
import { CaseStudySidebarTranslations } from './case-study-sidebar/case-study-sidebar.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';

@NgModule({
  declarations: [CaseStudySidebarComponent],
  exports: [CaseStudySidebarComponent],
  imports: [CommonModule, NGFormSaveButtonModule, DLNGErrorDirectiveModule, FormsModule, SidebarModule, ChipsModule, AreYouSureModule],
  providers: [CaseStudySidebarTranslations, TranslationsV2Service, CommonService]
})
export class CaseStudySidebarModule {}
