import { RequiredProperties } from './analytic.interface';
import userflow from 'userflow.js';


export function userflowIdentify(id: string, propertyObject: RequiredProperties): void {
  const {
    userEmail,
    account,
    managerEmail,
    userGroup,
    role,
    activePrivileges,
  } = propertyObject;

  userflow.identify(userEmail, {
    email: userEmail,
    account,
    managerEmail,
    userGroup,
    valueExecutionRole: role,
    valueExecutionId: id,
    activePrivileges,
  });
}

