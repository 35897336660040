<app-value-map-maintenance [solutionId]="solutionId" [canEdit]="canEdit" [toggleMaintenance]="toggleMaintenance"></app-value-map-maintenance>

<div *ngIf="loaded">
  <div class="row" style="margin-bottom: 20px">
    <div class="col-md-6 mr-auto breadcrumb-container">
      <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbValues"></app-breadcrumbs>
  </div>
  </div>
  

  <div *ngIf="!canEdit" class="row">
    <div class="col-xs-12">
      <p class="can_edit_message" style="color: #ffffff !important; padding: 20px; font-size: 24px">
        {{ trans.solution_detail.you_are_in_view_only_mode_please_contact_your_model_admin_if_you_need_to_make_changes | translate }}
      </p>
    </div>
  </div>

  <div class="panel panel-white">
    <div class="panel-body" style="max-height: inherit">
      <div class="solutionView">
        <h3 *ngIf="loadSolution" class="mainTitle"></h3>

        <!-- If tab order has to be changed, also change the variable 'tabOrder' in .ts file  -->
        <p-tabView [(activeIndex)]="activeIndex">
          <p-tabPanel header="{{ trans.solution_detail.over | translate }}" tooltip="{{ trans.models_contextual_help.help_overview | translate }}">

              <app-overview [canEdit]="canEdit"></app-overview>
          </p-tabPanel>

          <p-tabPanel *ngIf="masterValuePropFeature" header="{{ trans.solution_detail.projects | translate }}" tooltip="{{ trans.models_contextual_help.help_projects | translate }}">
              <div class="col-md-12">
                <app-projects [canEdit]="canEdit" [solutionId]="solutionId" [tabInit]="activeIndex"></app-projects>
              </div>
          </p-tabPanel>

          <p-tabPanel header="{{ trans.solution_detail.factors | translate }}" tooltip="{{ trans.models_contextual_help.help_assumptions | translate }}">
              <app-model-assumptions [canEdit]="canEdit" [solutionId]="solutionId" [tabInit]="activeIndex"></app-model-assumptions>
          </p-tabPanel>

          <p-tabPanel header="{{ trans.solution_detail.benefits | translate }}" tooltip="{{ trans.models_contextual_help.help_benefits | translate }}">
              <app-benefits [canEdit]="canEdit" [default_term]="defaultTerm" [tabInit]="activeIndex"></app-benefits>
          </p-tabPanel>
          <p-tabPanel header="{{ trans.solution_detail.costs | translate }}" tooltip="{{ trans.models_contextual_help.help_costs | translate }}">
              <app-costs [canEdit]="canEdit" [solutionId]="solutionId"></app-costs>
          </p-tabPanel>
          <p-tabPanel header="{{ trans.solution_detail.situations | translate }}" tooltip="{{ trans.models_contextual_help.help_situations | translate }}">
              <div style="padding: 0.5%">
                <app-situation [canEdit]="canEdit" [solutionId]="solutionId" [tabInit]="activeIndex"></app-situation>
              </div>
          </p-tabPanel>
          <p-tabPanel header="{{ trans.solution_detail.case_studies | translate }}" tooltip="{{ trans.models_contextual_help.help_case_studies | translate }}">
              <app-case-studies [canEdit]="canEdit" [solutionId]="solutionId" [tabInit]="activeIndex"></app-case-studies>
          </p-tabPanel>
          <p-tabPanel header="{{ trans.solution_detail.collateral | translate }}" tooltip="{{ trans.models_contextual_help.help_collateral | translate }}">
              <app-collateral
                [canEdit]="canEdit"
                [showHelp]="true"
                [contextualHelp]="contextualHelp"
                [solutionId]="solutionId"
                [tabInit]="activeIndex"
              ></app-collateral>
          </p-tabPanel>
          <p-tabPanel header="{{ trans.solution_detail.comp | translate }}" tooltip="{{ trans.models_contextual_help.help_competitors | translate }}">
              <app-solutioncompetitors [canEdit]="canEdit" [solutionId]="solutionId" [tabInit]="activeIndex"></app-solutioncompetitors>
          </p-tabPanel>
          <p-tabPanel header="{{ trans.solution_detail.threshold | translate }}" tooltip="{{ trans.models_contextual_help.help_thresholds | translate }}">
              <app-thresholds [canEdit]="canEdit" [solutionId]="solutionId" [tabInit]="activeIndex"></app-thresholds>
          </p-tabPanel>
          <p-tabPanel >
            <ng-template pTemplate="header" style="padding-top: 0;">
              <span *ngIf="validationCount !== '0'"  pBadge [value]="validationCount" severity="danger">{{ trans.solution_detail.validation | translate }}</span>
              <span  *ngIf="validationCount === '0'">{{ trans.solution_detail.validation | translate }}</span>
            </ng-template>
            <app-validation></app-validation>
        </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loadSolution" class="col-12 text-center">
  <img class="actionLoader" src="{{ fullImagePath }}" />
</div>

<ng-template #t let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill">
      <i class="fa fa-star"></i>
    </span>
    <i class="fa fa-star-o borderStar"></i>
  </span>
</ng-template>
