<form name="formAddEditPainPoint" #formAddEditPainPoint="ngForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebar_title }}
      <app-editTranslation
        *ngIf="showTranslate && mode === 'add'"
        [component]="'ae_painpoints'"
        [isTranslationV2]="true"
        [transObj]="trans.trans.add_painpoint"
        (callback)="getTranslations()"
      ></app-editTranslation>
      <app-editTranslation
        *ngIf="showTranslate && mode === 'edit'"
        [component]="'ae_painpoints'"
        [isTranslationV2]="true"
        [transObj]="trans.trans.add_painpoint"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditPainPoint.controls.name">
            <label for="name" class="sidebar_label labelsRequired"
              >{{ trans.trans.name.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'ae_painpoints'"
                [isTranslationV2]="true"
                [transObj]="trans.trans.name"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input type="text" class="form-control" ret-data="Name" [(ngModel)]="selectedElem.name" name="name" [placeholder]="trans.trans.painpoint_name.value" required />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditPainPoint.controls.description">
            <label for="description" class="sidebar_label"
              >{{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'ae_painpoints'"
                [isTranslationV2]="true"
                [transObj]="trans.trans.description"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <textarea name="description" ret-data="Description" [(ngModel)]="selectedElem.description" rows="4" class="form-control"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <label for="has_tag" class="sidebar_label">{{ trans.trans.createCustomAssetTag.value }}</label>
          <br />
          <p-inputSwitch [(ngModel)]="selectedElem.has_tag" (onChange)="generateCustomAsset($event)" name="has_tag"></p-inputSwitch>
        </div>
        <div class="col-md-6"></div>
        <!-- Custom Tag -->
        <div *ngIf="selectedElem.has_tag" class="col-md-6" appNGError [elemControl]="formAddEditPainPoint.controls.valueTag">
          <label for="name" class="sidebar_label labelsRequired">{{ trans.trans.ppTag.value }}</label>
          <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTagTooltip.value">info</span>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="selectedElem.tag"
            name="valueTag"
            ret-data="Value Tag"
            maxlength="255"
            appSpaceToUnderscore
            appSpecialCharacterValidator
            [required]="selectedElem.has_tag"
          />
          <span *ngIf="selectedElem.has_tag" class="hint light-h"> {{ selectedElem.tag ? (selectedElem.tag | assetTagFormat) : (trans.trans.value.value | assetTagFormat) }} </span>
          <span class="input-error-warn" *ngIf="formAddEditPainPoint.controls.valueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <div class="row">
        <div class="col-md-12">
          <input type="hidden" name="id" [(ngModel)]="selectedElem.id" />
          <button
            *ngIf="mode === 'edit'"
            pButton
            type="button"
            iconPos="right"
            [label]="trans.trans.update.value"
            [loading]="isLoading"
            [disabled]="!formAddEditPainPoint.valid"
            (click)="updatePainPoint(formAddEditPainPoint)"
            class="pull-right marg-h btn btn_customizable xf-continue-button"
          ></button>
          <button
            *ngIf="mode === 'add'"
            pButton
            type="button"
            iconPos="right"
            [label]="trans.trans.save.value"
            [disabled]="!formAddEditPainPoint.valid"
            [loading]="isLoading"
            class="pull-right marg-h btn btn_customizable xf-continue-button"
            (click)="insertPainPoint(formAddEditPainPoint)"
          ></button>
          <app-are-you-sure
            matTooltipPosition="above"
            [message]="trans.trans.are_you_sure.value"
            class="btn cancel-confirm pull-right marg-h"
            icon=""
            [text]="trans.trans.back.value"
            (callbackYes)="close()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </div>
</form>
