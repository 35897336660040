import { Component, OnInit, Input } from '@angular/core';
import { PrimengChartData, PrimengData } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';

@Component({
	selector: 'app-bar',
	templateUrl: './bar.component.html',
	styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
	@Input('data') chartData: any;
	id = "";
	chart: any;
	options: any;

	ngOnInit(): void {
		this.buildChart(this.chartData);
	}

	buildChart(chartData: any): void {
		this.chart = {
			labels: this.chartData.data.labels,
			datasets: this.chartData.data.datasets
		}
		this.options = {
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true,
						callback: function (value) {
							const number = !(typeof value === 'number') ? Number(value.replace(',', '')) : value;
							const numberLength = number.toString().length;
							let unit = '';

							if (numberLength >= 4 && numberLength <= 6) {
								unit = 'K';
							}
							else if (numberLength >= 7 && numberLength <= 9) {
								unit = 'M';
							}
							else if (numberLength >= 10 && numberLength <= 13) {
								unit = 'B';
							}

							else if (numberLength >= 14 && numberLength <= 17) {
								unit = 'T';
							}
							switch (unit) {
								case 'K':
									return Math.round((number / 1000)) + unit;
								case 'M':
									return Math.round((number / 1000000)) + unit;
								case 'B':
									return Math.round((number / 1000000000)) + unit;
								case 'T':
									return Math.round((number / 1000000000000)) + unit;
								default:
									return number;
							}
						}
					}
				}]
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						let label = data.datasets[tooltipItem.datasetIndex].label || '';
						if (label) {
							label += ': ';
						}

						if (tooltipItem.datasetIndex == 0) {
							if (chartData['d0']) { label += chartData['d0'][tooltipItem.index]; }
						}
						if (tooltipItem.datasetIndex == 1) {
							if (chartData['d1']) { label += chartData['d1'][tooltipItem.index]; }
						}
						if (tooltipItem.datasetIndex == 2) {
							if (chartData['d2']) { label += chartData['d2'][tooltipItem.index]; }
						}
						return label;
					}
				}
			},
			legend: {
				display: true,
				position: "right",
				labels: {
					fontSize: 10
				}
			},
			animation: {
				duration: 100
			}
		}
	}
}
