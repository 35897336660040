import { UserInfo } from '@shared/models/user-info.model';
import { Currency } from '@data/services/valueprop/models/situation-opportunity.model';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { AlertService } from '@services/alert.service';
import { NgForm } from '@angular/forms';
import { UserService } from '@data/services/user/user.service';
import { FactsService } from '@data/services/facts/facts.service';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { ApiServiceService } from '../../api-service.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { ProfileDetailTranslation } from './profile-detail.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { LanguageType } from '@shared/models/user-info.model';
import { FactColor, PalatteColor } from '@shared/models/account.models';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
  providers: [FactsService],
})
export class ProfileDetailComponent implements OnInit, OnDestroy {
  @Output() updateProfile = new EventEmitter();
  @Input() user: UserInfo;
  @Input() modelName: string;

  user_id: string;
  image_url: string;
  account_id: string;
  user_token: string;
  error_text: string;
  fullImagePath: any;
  errors: string;
  change_pwd = false;
  loaderImg = false;
  mobile: string;
  phone: string;
  manager_email: string;
  currencyType = 'Select';
  numberFormat: number;
  langType: Partial<LanguageType> | string;
  profileIconName = '';
  languageTypes: Partial<LanguageType>[];
  currencyTypes: Partial<Currency>[];
  selectedlang: Partial<LanguageType>;
  numberFormats: Array<{ id: string; name: string }> = [];
  selectedCurrency: Partial<Currency>;
  showColor = false;
  isAccountAdmin = true;
  actionLoader = false;
  userProfileImage: string;

  userColorPreference: Array<string> = ['#4285F4', '#003300', '#0000ff', '#00ccff', '#330033', '#663366', '#993366', '#ff9966'];
  currentColor = '';
  dropdownSettings: { [klass: string]: any } = {};
  selectedItems: Array<FactColor> = [];
  selectedColors: Array<PalatteColor> = [];
  chartSelectedcolor: { [klass: string]: any };
  userMultiColorPreference: Array<FactColor> = [
    { id: '1', color: '#003300', updated: false },
    { id: '2', color: '#0000ff', updated: false },
    { id: '3', color: '#00ccff', updated: false },
    { id: '4', color: '#330033', updated: false },
    { id: '5', color: '#663366', updated: false },
    { id: '6', color: '#993366', updated: false },
    { id: '7', color: '#ff9966', updated: false },
  ];
  files: File;
  isGuest = false;

  showLang = true;

  subscriptionupdateUserProfileIcon: Subscription;
  subscriptionresetPassword: Subscription;
  subscriptiongetLanguages: Subscription;
  subscriptiongetCurriences: Subscription;
  subscriptiongetNumberFormats: Subscription;
  subscriptionsaveUserColor: Subscription;
  subscriptionsaveAccountColor: Subscription;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  style2022$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private apiServiceService: ApiServiceService,
    private translationService: TranslationsV2Service,
    public trans: ProfileDetailTranslation,
    private styleService: StyleService
  ) {
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.user_id = sessionStorage.getItem('uid');
    this.account_id = sessionStorage.getItem('aid');
    this.user_token = sessionStorage.getItem('vcu');
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    this.isAccountAdmin = this.userService.getIsAccountAdmin();
    const chartSelectedcolor = localStorage.getItem('selectedColor');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    if (chartSelectedcolor !== 'undefined' && chartSelectedcolor !== null) {
      this.chartSelectedcolor = JSON.parse(chartSelectedcolor);
      const chartHeaderColor = JSON.parse(localStorage.getItem('selectedColor'));

      this.chartSelectedcolor = this.chartSelectedcolor.filter((item: any) => {
        return item.colorscheme_type_id === '3';
      });

      this.currentColor = chartHeaderColor.filter((item: any) => {
        return item.colorscheme_type_id === '2' && item['color'];
      });

      if (this.currentColor.length > 0) {
        this.setPalatteColor(this.currentColor[0]['color']);
      }

      const chartcolorArray = this.chartSelectedcolor.map((item: any) => {
        return item['color'];
      });

      for (let i = 0; i < this.userMultiColorPreference.length; i++) {
        const index = chartcolorArray.indexOf(this.userMultiColorPreference[i]['color']);
        if (index != -1) {
          this.userMultiColorPreference[i]['id'] = this.chartSelectedcolor[index]['id'];
          this.userMultiColorPreference[i]['updated'] = true;
          this.selectedItems.push(this.userMultiColorPreference[i]);
        }
      }
    }

    this.mobile = this.user['mobile'];
    this.phone = this.user['phone'];
    this.manager_email = this.user['manager_email'];
    this.numberFormat = parseInt(this.user['number_format'], 10);
    this.getLanguages();
    this.getCurrencies();
    this.getNumberFormats();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Available colors',
      enableCheckAll: false,
      enableSearchFilter: false,
      labelKey: 'name',
      badgeShowLimit: 'all',
    };

    this.setProfileImage();

    const rid = sessionStorage.getItem('rid');
    if (rid === '7') {
      this.isGuest = true;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionupdateUserProfileIcon) {
      this.subscriptionupdateUserProfileIcon.unsubscribe();
    }
    if (this.subscriptionresetPassword) {
      this.subscriptionresetPassword.unsubscribe();
    }
    if (this.subscriptiongetLanguages) {
      this.subscriptiongetLanguages.unsubscribe();
    }
    if (this.subscriptiongetCurriences) {
      this.subscriptiongetCurriences.unsubscribe();
    }
    if (this.subscriptiongetNumberFormats) {
      this.subscriptiongetNumberFormats.unsubscribe();
    }
    if (this.subscriptionsaveUserColor) {
      this.subscriptionsaveUserColor.unsubscribe();
    }
    if (this.subscriptionsaveAccountColor) {
      this.subscriptionsaveAccountColor.unsubscribe();
    }
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  setProfileImage(): void {
    this.userProfileImage = this.user['profileImagePath'] !== '' ? this.user['profileImagePath'] : this.user['profilepicdefaultpath'];
  }

  handleInputChange(event): void {
    this.files = event.target.files;

    const reader = new FileReader();
    reader.readAsDataURL(this.files[0]);
    reader.onload = () => {
      this.userProfileImage = reader.result.toString();
    };
    reader.onerror = function () {};

    this.profileIconName = this.files && this.files[0].name ? this.files[0].name : '';

    this.updateProfilePic();
  }

  updateProfilePic(): void {
    if (this.files && this.files[0]) {
      this.actionLoader = true;
      const payload = new FormData();
      payload.append('user_profile_icon', this.files[0]);

      this.subscriptionupdateUserProfileIcon = this.userService.updateUserProfileIcon(payload).subscribe(
        (result) => {
          this.notificationService.success('Profile Image updated successfully', false);
          this.user['profileImagePath'] = result['result']['profile_pic_filepath'];
          this.apiServiceService.profilePic.next(result['result']['profile_pic_filepath']);
          this.actionLoader = false;
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );
    }
  }

  updateUser(user): void {
    user['temp'] = { mobile: this.mobile, phone: this.phone };
    user.temp.currency = this.selectedCurrency ? { id: this.selectedCurrency['id'], name: this.currencyType } : '';
    user.temp.numberFormat = this.numberFormat ? this.numberFormat : '';
    user.temp.manager_email = this.manager_email;
    this.updateProfile.emit(user);
  }

  ngOnSubmit(form: NgForm): void {
    this.alertService.clear();
    this.loaderImg = true;

    const payload = {
      current_password: form.value.currentPassword,
      new_password: form.value.newPassword,
      confirm_password: form.value.confirmPassword,
    };

    this.subscriptionresetPassword = this.userService.resetPassword(payload).subscribe(
      (result) => {
        this.error_text = result.result;
        this.notificationService.success(this.error_text, false);
        this.change_pwd = false;
        this.loaderImg = false;
      },
      (error) => {
        this.errors = error;
        this.error_text = this.errors;
        this.notificationService.error(this.error_text, false);
        this.loaderImg = false;
      }
    );
  }
  showPwdForm(): void {
    this.alertService.clear();
    this.change_pwd = true;
  }
  cancelChangePwd(): void {
    this.alertService.clear();
    this.change_pwd = false;
  }
  getLanguages(): void {
    this.subscriptiongetLanguages = this.userService.getLanguages().subscribe((result) => {
      this.languageTypes = result.result;
      this.selectedlang = this.user['language_type_id'] !== '0' ? this.languageTypes.filter((lang) => lang.id === this.user['language_type_id'])[0] : {};
      this.langType = this.user['language_type_id'] !== '0' ? this.selectedlang : 'Select';
    });
  }
  getCurrencies(): void {
    this.subscriptiongetCurriences = this.userService.getCurriences().subscribe((result) => {
      this.currencyTypes = result.result;
      this.selectedCurrency = this.user['currency_type_id'] !== '0' ? this.currencyTypes.filter((currency) => currency.id === this.user['currency_type_id'])[0] : {};
      this.currencyType = this.user['currency_type_id'] !== '0' ? this.selectedCurrency['name'] : 'Select';
    });
  }
  getNumberFormats(): void {
    this.subscriptiongetNumberFormats = this.factsService.getNumberFormats().subscribe((response) => {
      if (response.result) {
        this.numberFormats = response.result;
      }
    });
  }

  onLangSelect(event): void {
    const val = (<HTMLSelectElement>event.srcElement).value;
    const index = val.split(':');
    this.selectedlang = this.languageTypes[Number(index[0]) - 1];
  }

  onCurrencySelect(event): void {
    const val = (<HTMLSelectElement>event.srcElement).value;
    const index = val.split(':');
    this.selectedCurrency = this.currencyTypes[Number(index[0]) - 1];
  }

  saveAccountColor(account_id, payload): void {
    this.subscriptionsaveAccountColor = this.userService.saveAccountColor(account_id, payload).subscribe((result) => {
      if (result.result) {
        this.notificationService.success(result.result, false);

        sessionStorage.setItem('header_color', this.currentColor);
        this.apiServiceService.changeHeaderColor.next(this.currentColor);
      } else {
        this.notificationService.error(result.result, false);
      }
    });
    this.actionLoader = false;
  }

  refresh(): void {
    location.reload();
  }

  toggleColors(): void {
    this.showColor = !this.showColor;
  }

  setColor(color): void {
    this.toggleColors();
    this.setPalatteColor(color);
  }

  setPalatteColor(color): void {
    this.currentColor = color;
    const payload: Array<PalatteColor> = [
      {
        color_scheme_type_id: '2',
        color: color,
      },
    ];
    this.selectedColors = payload;
  }
}
