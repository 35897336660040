<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{benefit?.metric_name}}
	</div>
	<form *ngIf="can_edit" name="noteForm" #noteForm="ngForm">
		<div class='sidebar_container'>
			<div class='keywest_panel'>

				<div class='row'>
					<div class="col-xs-12" appNGError [elemControl]="noteForm.controls.title">
						<label class="sidebar_label">Title
						</label>
						<input class="form-control" name="title" ret-data="Title" type="text" [(ngModel)]="note.title" required>
					</div>
				</div>

				<div class='row'>
					<div class="col-xs-12" appNGError [elemControl]="noteForm.controls.name">
						<label class="sidebar_label">Name
						</label>
						<input class="form-control" name="name" ret-data="Name" type="text" [(ngModel)]="note.name">
					</div>
				</div>

				<div class='row'>
					<div class="col-xs-12" appNGError [elemControl]="noteForm.controls.note" required>
						<label class="sidebar_label">Note
						</label>
						<textarea class="form-control" name="note" ret-data="Note" type="text" [(ngModel)]="note.note" required></textarea>
					</div>
				</div>

				<div class='row'>
					<div class="col-md-12">
						<app-btn-save [form]='noteForm' (callback)='saveNote()' text='Save' class='btn btn_customizable pull-right'></app-btn-save>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="sidebar_container" *ngIf="benefit.testimonials && benefit.testimonials.length > 0">
		<div class="keywest_panel">
			<div class='row section_row' *ngFor="let quote of benefit.testimonials">
				<div class='row'>
					<div class='col-xs-4'>
						<i (click)='quote.expand = !quote.expand' *ngIf="!quote.expand" class='fa fa-chevron-right' style='margin-right: 10px;'></i>
						<i (click)='quote.expand = !quote.expand' *ngIf="quote.expand" class='fa fa-chevron-down' style='margin-right: 10px;'></i>

						<span (click)='quote.expand = !quote.expand' class='clickable section_row_text'>{{quote.name}}</span>

					</div>
					<div class='col-xs-7'>
						<span class='section_row_text'>{{quote.title}}</span>
					</div>
					<div class="col-xs-1">

					</div>
				</div>
				<div class='row' *ngIf="quote.expand">
					<div class="col-xs-4"></div>
					<div class='col-xs-7'>
						<span class='quote_quote'>{{quote.quote}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>

<div>

</div>