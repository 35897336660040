import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BenefitPhasingComponent } from './phasing.component';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressBarModule } from 'primeng/progressbar';
import { MenuModule } from 'primeng/menu';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitPhasingTranslations } from './phasing.translation';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  imports: [MenuModule, ProgressBarModule, CommonModule, FormsModule, NgbModule, EditTranslationsModule, ReactiveFormsModule, ErrorInputWrapperModule, ConfirmDialogModule],
  declarations: [BenefitPhasingComponent],
  providers: [TranslationsV2Service, BenefitPhasingTranslations, ConfirmationService],
  exports: [BenefitPhasingComponent],
})
export class BenefitsPhasingModule {}
