import { NgModule } from '@angular/core';

import { TranslationsV2Component } from './translationsv2.component';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { TranslationsV2Translation } from './translationsv2.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectLanguageModule } from '@shared/select-language/select-language.module';

@NgModule({
    imports: [
        EditTranslationsModule,
        DropdownModule,
        CommonModule,
        RouterModule,
        TableModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BreadcrumbsModule,
        ProgressBarModule,
        SelectLanguageModule
    ],
    exports: [TranslationsV2Component],
    declarations: [TranslationsV2Component],
    providers: [
        TranslationsV2Service,
        TranslationsV2Translation
    ],
})
export class TranslationsV2Module { }
