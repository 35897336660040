import { Injectable } from '@angular/core';
import { MaterialIcon } from '@shared/models/material-icons';
import { SettingsTranslation } from 'app/account/settings/settings.translation';

export interface Linkprivileges {
   accountAdmin: boolean;
   modelAdmin: boolean;
   assetAdmin: boolean;
   creditAdmin: boolean;
   userAdmin: boolean;
   webCalcAdmin: boolean;
   valuePropAdmin: boolean;
   valuePropAdminReadOnly: boolean;
}

export enum MateralIconClass {
  OUTLINEED = 'material-icons-outlined',
  FILLED = 'material-icons'
}

export enum MateralIconColor {
  BLUE = '#3185fc',
  GREEN = '#81c14b',
  TEAL = '#06adad',
  PURPLE = '#7b61ff',
  RED = '#fa6063'
}

export interface SettingsLink {
  routerLink?: string;
  title: string;
  iconName: MaterialIcon;
  iconClass: MateralIconClass;
  iconColor: MateralIconColor;
  canShow: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public userLinkprivileges: Linkprivileges;
  public feature74 = false;
  public feature87 = false;
  public bulkUpdateEnabled = false;
  public guidedDiscoveryEnabled = false;

  constructor(public trans: SettingsTranslation) { }

  public getManageSettingsLinks = (): SettingsLink[] => {
    const {accountAdmin, userAdmin} = this.userLinkprivileges;
    const links: SettingsLink[] = [
      {
        routerLink: '/applications-settings',
        title: this.trans.trans.manage_application_settings.value,
        iconName: MaterialIcon.SETTINGS,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.BLUE,
        canShow: accountAdmin
      },
      {
        routerLink: '/users',
        title: this.trans.trans.manage_users.value,
        iconName: MaterialIcon.PERSON,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.BLUE,
        canShow: userAdmin
      },
      {
        routerLink: '/user_groups',
        title: this.trans.trans.manage_user_groups.value,
        iconName: MaterialIcon.PEOPLE,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.BLUE,
        canShow: userAdmin
      },
      {
        routerLink: '/competitors',
        title: this.trans.trans.manage_competitors.value,
        iconName: MaterialIcon.WORK_OUTLINE,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.BLUE,
        canShow: accountAdmin
      },
      {
        routerLink: '/partners',
        title: this.trans.trans.manage_partners.value,
        iconName: MaterialIcon.WIDGETS,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.BLUE,
        canShow: accountAdmin
      },
      {
        routerLink: '/edit-sequences',
        title: 'Manage Sequence',
        iconName: MaterialIcon.LIST_ALT,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.BLUE,
        canShow: accountAdmin
      },
      {
        routerLink: '/configurable-workflows',
        title: 'Configurable Workflows',
        iconName: MaterialIcon.ROUTE,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.BLUE,
        canShow: accountAdmin
      },
    ];
    return links.filter(link => link.canShow);
  }

  public getReportLinks = (): SettingsLink[] => {
    const {accountAdmin, modelAdmin} = this.userLinkprivileges;
    const links: SettingsLink[] = [
      {
        routerLink: '/solution-adoption',
        title: this.trans.trans.solution_reports.value,
        iconName: MaterialIcon.QUERY_STATS,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.GREEN,
        canShow: accountAdmin || modelAdmin
      },
      {
        routerLink: '/user-adoption',
        title: this.trans.trans.user_adoption_reports.value,
        iconName: MaterialIcon.ADD_CHART,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.GREEN,
        canShow: accountAdmin
      },
      {
        routerLink: '/other-reports',
        title: this.trans.trans.other_reports.value,
        iconName: MaterialIcon.INSIGHTS,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.GREEN,
        canShow: accountAdmin
      },
      {
        routerLink: '/analytics',
        title: this.trans.trans.analytics.value,
        iconName: MaterialIcon.INSIGHTS,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.GREEN,
        canShow: accountAdmin
      },
    ];
    return links.filter(link => link.canShow);
  }

  public getDesignCenterLinks = (): SettingsLink[] => {
    const {accountAdmin, assetAdmin, webCalcAdmin} = this.userLinkprivileges;
    const links: SettingsLink[] = [
      {
        routerLink: '/asset-management',
        title: this.trans.trans.manage_asset_templates.value,
        iconName: MaterialIcon.LIST_ALT,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.TEAL,
        canShow: accountAdmin || assetAdmin
      },
      {
        routerLink: '/calc',
        title: this.trans.trans.webCalculator.value,
        iconName: MaterialIcon.CALCULATE,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.TEAL,
        canShow: accountAdmin || webCalcAdmin,
      },
    ];
    return links.filter(link => link.canShow);
  }

  public getAdvancedFeaturesLinks = (): SettingsLink[] => {
    const {accountAdmin} = this.userLinkprivileges;
    const links: SettingsLink[] = [
      {
        routerLink: '/features',
        title: this.trans.trans.features.value,
        iconName: MaterialIcon.TUNE,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.PURPLE,
        canShow: accountAdmin
      },
      {
        routerLink: '/discovery-questions',
        title: this.trans.trans.discoveryQuestions.value,
        iconName: MaterialIcon.HELP_OUTLINE,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.PURPLE,
        canShow: accountAdmin
      },
      {
        routerLink: '/guided-discovery',
        title: this.trans.trans.guidedDiscovery.value,
        iconName: MaterialIcon.GPS_NOT_FIXED,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.PURPLE,
        canShow: accountAdmin && this.guidedDiscoveryEnabled
      },
      {
        routerLink: '/survey-templates',
        title: this.trans.trans.surveyTemplates.value,
        iconName: MaterialIcon.ASSIGNMENT,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.PURPLE,
        canShow: accountAdmin
      },
      {
        routerLink: '/custom-asset-tags',
        title: this.trans.trans.customAssetTags.value,
        iconName: MaterialIcon.TUNE,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.PURPLE,
        canShow: accountAdmin
      },
      {
        routerLink: '/bulk-update',
        title: this.trans.trans.bulkUpdate.value,
        iconName: MaterialIcon.TUNE,
        iconClass: MateralIconClass.OUTLINEED,
        iconColor: MateralIconColor.PURPLE,
        canShow: accountAdmin && this.bulkUpdateEnabled
      },
    ];
    return links.filter(link => link.canShow);
  }

  public getLocalizationLinks = (): SettingsLink[] => {
    const {accountAdmin, userAdmin, assetAdmin, webCalcAdmin, valuePropAdmin, valuePropAdminReadOnly} = this.userLinkprivileges;
    const links: SettingsLink[] = [
      {
        routerLink: '/translations',
        title: this.trans.trans.translationTitle.value,
        iconName: MaterialIcon.TRASLATE,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.RED,
        canShow: accountAdmin
      },
      {
        routerLink: '/currency',
        title: this.trans.trans.exchange_rates.value,
        iconName: MaterialIcon.CURRENCY_EXCHANGE,
        iconClass: MateralIconClass.FILLED,
        iconColor: MateralIconColor.RED,
        canShow: this.feature87 && (accountAdmin || userAdmin || assetAdmin || webCalcAdmin || valuePropAdmin || valuePropAdminReadOnly)
      },
    ];
    return links.filter(link => link.canShow);
  }

  public getUserLinkPrilages = (): Linkprivileges => {
    const privilegeOption = sessionStorage.getItem('privileges');
    const options = privilegeOption.split(',');
    const linkprivileges: Linkprivileges = {
      accountAdmin: false,
      modelAdmin: false,
      assetAdmin: false,
      creditAdmin: false,
      userAdmin: false,
      webCalcAdmin: false,
      valuePropAdminReadOnly: false,
      valuePropAdmin: false
    }
    if (options.indexOf('1') >= 0) {
      linkprivileges.accountAdmin = true;
    }
    if (options.indexOf('2') >= 0) {
      linkprivileges.modelAdmin = true;
    }
    if (options.indexOf('3') >= 0) {
      linkprivileges.assetAdmin = true;
    }
    if (options.indexOf('4') >= 0) {
      linkprivileges.creditAdmin = true;
    }
    if (options.indexOf('5') >= 0) {
      linkprivileges.userAdmin = true;
    };
    if (options.indexOf('10') >= 0) {
      linkprivileges.webCalcAdmin = true;
    }
    if (options.indexOf('11') >= 0) {
      linkprivileges.valuePropAdminReadOnly = true;
    }
    if (options.indexOf('12') >= 0) {
      linkprivileges.valuePropAdmin = true;
    }
    return linkprivileges;
  }

  public hasSettingsLinks() {
    let linkCount = 0;
    this.userLinkprivileges = this.getUserLinkPrilages();
    linkCount += this.getManageSettingsLinks().length;
    linkCount += this.getReportLinks().length;
    linkCount += this.getDesignCenterLinks().length;
    linkCount += this.getAdvancedFeaturesLinks().length;
    linkCount += this.getLocalizationLinks().length;
    return linkCount > 0;
  }
}
