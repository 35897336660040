import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { AddNewCompanyTranslations } from './add-company.translation';
import { pluck, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DroppedFile } from '@shared/models/dropped-file.model';
import { FactsService } from '@data/services/facts/facts.service';
import { Countries, Industries, IndustrySectors, States } from 'app/value-position/add-value-positionv2/dynamic_components/interfaces/company.interface';
import { CompanyService } from '@data/services/company/company.service';
import { Company } from '@shared/models/company.model';
import { NotificationService } from '@services/notification.service';
import { CustomValidators } from 'app/_utils/custom-validators';

interface tabOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-add-new-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
})
export class AddCompanyComponent implements OnInit, OnDestroy {
  @Output() cancel = new EventEmitter<boolean>();

  public pageOptions: tabOption[];
  public currentPage = '1';
  public files: DroppedFile[] = [];
  public states: States;
  public industries: Industries;

  public basicForm: FormGroup;
  public addtionalInfoForm: FormGroup;

  private unsubscribe = new Subject<void>();

  public countries$ = this.factsService.getCountries().pipe(pluck('result', 'countries'));
  public industrySectors$ = this.factsService.getSectors().pipe(pluck('result'));
  public feature115 = false;

  constructor(
    public trans: AddNewCompanyTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private factsService: FactsService,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private fb: FormBuilder
  ) {}

  get name() {
    return this.basicForm.get('name');
  }
  get industrySector() {
    return this.basicForm.get('industrySector');
  }
  get industry() {
    return this.basicForm.get('industry');
  }
  get country() {
    return this.basicForm.get('country');
  }
  get revenueInMillions() {
    return this.basicForm.get('revenueInMillions');
  }
  get url() {
    return this.addtionalInfoForm.get('url');
  }
  get description() {
    return this.addtionalInfoForm.get('description');
  }
  get employees() {
    return this.addtionalInfoForm.get('employees');
  }
  get address() {
    return this.addtionalInfoForm.get('address');
  }
  get city() {
    return this.addtionalInfoForm.get('city');
  }
  get state() {
    return this.addtionalInfoForm.get('state');
  }
  get postalCode() {
    return this.addtionalInfoForm.get('postalCode');
  }

  ngOnInit(): void {
    this.getTranslations();
    this.feature115 = this.commonService.checkFeature(115);
    this.createForms();
    this.listenToForms();
    if (this.industrySector.value === '') {
      this.industry.disable();
    }
    if (this.country.value === '') {
      this.state.disable();
    }
  }

  private getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.createPageOptions();
      });
  }

  private createPageOptions() {
    this.pageOptions = [
      { value: '1', label: this.trans.trans.basic.value },
      { value: '2', label: this.trans.trans.addtionalInfo.value },
      { value: '3', label: this.trans.trans.finish.value },
    ];
  }

  private createForms() {
    this.basicForm = this.fb.group({
      name: ['', [Validators.required]],
      industrySector: ['', [this.feature115 ? Validators.nullValidator : Validators.required]],
      industry: ['', [this.feature115 ? Validators.nullValidator : Validators.required]],
      country: ['', [this.feature115 ? Validators.nullValidator : Validators.required]],
      revenueInMillions: ['', [this.feature115 ? Validators.nullValidator : Validators.required]],
    });
    this.addtionalInfoForm = this.fb.group({
      url: ['', [CustomValidators.urlValidator()]],
      description: '',
      employees: ['', [CustomValidators.noDecimalValidator()]],
      address: '',
      city: '',
      state: '',
      postalCode: ['', [CustomValidators.noDecimalValidator()]],
    });
  }

  public getStatesByCountries(countryId): void {
    this.factsService
      .getStatesByCountries(countryId)
      .pipe(take(1))
      .subscribe((response) => (this.states = response.result.states));
  }

  private getIndustryBySectors(sectorId): void {
    this.factsService
      .getIndustriesBySector(sectorId)
      .pipe(take(1))
      .subscribe((response) => (this.industries = response.result.dl_industries));
  }

  private listenToForms() {
    this.industrySector.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      if (value && value.id !== '') {
        this.getIndustryBySectors(value.id);
        this.industry.enable();
      } else {
        this.industry.disable();
      }
    });
    this.country.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      if (value && value.id !== '') {
        this.getStatesByCountries(value.id);
        this.state.enable();
      } else {
        this.state.disable();
      }
    });
  }

  public goNext() {
    this.currentPage = (parseInt(this.currentPage) + 1).toString();
  }

  public goBack() {
    this.currentPage = (parseInt(this.currentPage) - 1).toString();
  }

  public onCancel() {
    this.cancel.emit(false);
  }

  public onSubmit() {
    const company: Partial<Company> = {
      address1: this.address.value,
      city: this.city.value,
      country: this.country.value.name,
      country_id: this.country.value.id,
      url: this.url.value,
      employees: this.employees.value,
      industry_id: this.industry.value.id,
      industry_sector_id: this.industrySector.value.id,
      name: this.name.value,
      net_sales: this.revenueInMillions.value,
      state: this.state.value.abbreviation,
      zip: this.postalCode.value,
      description: this.description.value,
    };

    this.companyService
      .addCompany(company)
      .pipe(take(1))
      .subscribe((res: any) => {
        const { message, success } = res.result;
        if (!success && message) {
          this.notificationService.error(message, false);
        } else {
          if (this.files.length > 0) {
            // Save company logo here
            const formData = new FormData();
            formData.append('company_logo', this.files[0]);
            formData.append('id', res.result.id);
            formData.append('url', this.url.value);
            this.companyService
              .addCompanyLogo(formData)
              .pipe(take(1))
              .subscribe((res) => {
                if (res.result && res.result.success) {
                  this.notificationService.success(this.trans.trans.companyCreated.value, false);
                } else if (res.result && !res.result.success) {
                  this.notificationService.error(res.result.message, false);
                } else {
                  this.notificationService.error(this.trans.trans.fileNotUploaded.value, false);
                }
              });
          } else {
            this.notificationService.success(this.trans.trans.companyCreated.value, false);
          }
          this.cancel.emit(false);
        }
      });
  }

  public showInvalidSize(file: string) {
    this.notificationService.error(file + ' is bigger than the 32mb max size ', false);
  }

  public showInvalidType(file: string) {
    this.notificationService.error(file + ' has an invalid Type ', false);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
