import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { ColumnMeta } from '@shared/models/common.models';
import { Goal, GoalGroup, SimpleGoalReactiveForm, ValuePropAddGoal, ValuePropGoal } from '@shared/models/goal-group.model';
import { Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ValuePropGoalGroupTranslations } from './value-prop-goals.translation';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';

@Component({
  selector: 'app-value-prop-goals',
  templateUrl: './value-prop-goals.component.html',
  styleUrls: ['./value-prop-goals.component.scss'],
})
export class ValuePropGoalsComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() canEdit: boolean;
  @ViewChild('closeOverlay') closeOverlay: ElementRef;
  showTranslate = false;
  isLoading = false;
  isLoadingAdd = false;
  cols: ColumnMeta[];
  goalGroups: GoalGroup[] = [];
  accountId: string;
  goalSelected: ValuePropGoal;
  goalIdsList: string[] = [];
  ngUnsubscribe$ = new Subject();
  sidebarOpen = false;
  toggleScratchpadView = false;
  fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  goalGroupArrays: FormArray[] = [];
  goalOnTheFlyPrivilege = this.commonService.checkPrivilege('17');
  constructor(
    private goalGroupsService: GoalGroupsService,
    private translationsService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: ValuePropGoalGroupTranslations,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private valuepropService: ValuepropService
  ) {}

  ngOnInit(): void {
    this.accountId = sessionStorage.getItem('aid');
    this.getGroups();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationsService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationsService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.cols = [
          { field: 'name', header: this.trans.trans.name.value },
          { field: 'baselineValue', header: this.trans.trans.baselineValue.value },
          { field: 'value', header: this.trans.trans.targetValue.value },
          { field: 'units', header: this.trans.trans.units.value },
        ];
      });
  }

  getGroups(): void {
    this.isLoading = true;
    this.goalIdsList = [];
    this.goalGroupsService
      .getValuePropGoalsWithVPSpecific(this.valuePropId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((goalGroups) => {
        this.goalGroups = goalGroups;
        goalGroups.forEach((group) => {
          this.goalGroupArrays.push(this.createFormStructure(group));
          this.goalIdsList.push(...group.factors.map((factor) => factor.account_factor_id));
        });
      });
  }

  onRemoveGoalFromVp(goal: ValuePropGoal): void {
    this.isLoading = true;

    this.goalGroupsService
      .deleteValuePropGoal(this.valuePropId, goal.account_factor_id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.notificationService.success(this.trans.trans.goalDeleted.value, false);
          this.getGroups();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  createFormStructure(goalGroup: GoalGroup): FormArray {
    const factorsFormArray = this.fb.array([]);

    goalGroup.factors.forEach((factor: any) => {
      const factorFormArray = this.fb.group({
        name: new FormControl(factor.name, [Validators.maxLength(255), Validators.required]),
        editing: new FormControl(false),
      });
      factorsFormArray.push(factorFormArray);
    });

    return factorsFormArray;
  }

  editName(index: number, rowIndex: number, goal: Goal | null): void {
    this.goalGroupArrays[index].at(rowIndex).setValue({
      name: goal ? goal.name : '',
      editing: !this.goalGroupArrays[index].at(rowIndex).get('editing').value,
    });
  }

  saveName(index: number, rowIndex: number, factor_id: string): void {
    if (this.goalGroupArrays[index].at(rowIndex).get('name').invalid) {
      return;
    }
    const override_name = this.goalGroupArrays[index].at(rowIndex).get('name').value;
    const payload = {
      factor_id,
      override_name,
    };

    this.valuepropService
      .postEditFactorNameFromBenefits(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(
        () => {
          this.notificationService.success(this.trans.trans.goalNameSaved.value, false);
          this.editName(index, rowIndex, null);
          this.updateNameInGroup(index, rowIndex, override_name);
        },
        () => {
          this.notificationService.success(this.trans.trans.requestError.value, false);
        }
      );
  }

  updateNameInGroup(index: number, rowIndex: number, goalName: string): void {
    this.goalGroups[index].factors[rowIndex].name = goalName;
  }

  addGoalToVp(goal: SimpleGoalReactiveForm | null | 'cancel', mode?: string): void {
    if (goal === 'cancel') {
      this.sidebarOpen = false;
      return;
    }
    if (goal === null) {
      this.sidebarOpen = false;
      this.getGroups();
      return;
    }
    const payload: ValuePropAddGoal = {
      account_factor_id: goal.accountFactorId,
      source_type_id: 1,
      value: goal.value,
      active: '1',
    };

    this.isLoadingAdd = true;

    this.goalGroupsService
      .addValuePropGoal(this.valuePropId, payload)
      .pipe(finalize(() => (this.isLoadingAdd = false)))
      .subscribe(
        () => {
          if (mode === 'edit') {
            this.notificationService.success(this.trans.trans.goalUpdated.value, false);
          } else {
            this.notificationService.success(this.trans.trans.goalAdded.value, false);
          }

          this.sidebarOpen = false;
          this.getGroups();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  onOpenGoal(goal?: ValuePropGoal): void {
    this.closeOverlay.nativeElement.click();
    this.goalSelected = goal;
    this.sidebarOpen = true;
  }

  groupIdentity(_idx: number, group: GoalGroup): string {
    return group.id;
  }

  closeSidebars(): void {
    this.toggleScratchpadView = false;
    this.sidebarOpen = false;
  }

  scratchpadSave(): void {
    this.closeSidebars();
    this.getGroups();
  }

  toggleScratchpadSidebar(goal: ValuePropGoal): void {
    this.goalSelected = goal;
    this.toggleScratchpadView = true;
  }

  improvementKeyPress(rowData: ValuePropGoal): void {
    rowData.updated = true;
  }

  saveChanges(): void {
    if (this.isLoading) {
      return;
    }
    const batchPayload: ValuePropAddGoal[] = this.goalGroups.reduce((factorArrays, group) => {
      const newFactors = group.factors
        .filter((factor) => factor.updated)
        .map((factor) => {
          return {
            account_factor_id: factor.account_factor_id,
            source_type_id: 1,
            value: factor.value_fmt,
            baseline_value: factor.baseline_value_fmt,
            active: factor.active,
          };
        });
      return [...factorArrays, ...newFactors];
    }, []);
    if (batchPayload.length) {
      this.isLoading = true;
      this.goalGroupsService
        .addValuePropGoals(this.valuePropId, batchPayload)
        .pipe(finalize(() => (this.isLoadingAdd = false)))
        .subscribe(
          () => {
            this.notificationService.success(this.trans.trans.goalUpdated.value, false);
            this.getGroups();
          },
          () => {
            this.notificationService.error(this.trans.trans.requestError.value, false);
          }
        );
    } else {
      this.notificationService.warning(this.trans.trans.noUpdate.value, false);
    }
  }
}
