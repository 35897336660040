import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AESCrypto } from '../data/services/crypto/crypto.service';
import { BridgeComponent } from './bridge.component';



const routes: Routes = [
  {
    path: 'bridge/:hash',
    component: BridgeComponent,
  },
];
@NgModule({
  imports: [CommonModule, FormsModule, RouterModule.forChild(routes)],
  declarations: [BridgeComponent],
  providers: [AESCrypto],
  exports: [BridgeComponent],
  entryComponents: [BridgeComponent],
})
export class BridgeModule {}
