
<div *ngIf="scratchpadFactorName">
	<table class='table table-responsive table-striped'>
		<tr *ngFor="let scratchpad of scratchpadList.units">
			<td style="width: 3em"></td>
			<td style='width: 100%;'>
				<span *ngIf="scratchpad.open_paren==1"> ( </span>
				<label>{{scratchpad.name}}</label>
				<i style='color: blue;'>{{operatorList[scratchpad.operator_id - 1].sign}}</i>
				<span *ngIf="scratchpad.close_paren==1"> ) </span>
			</td>
			<td style='width: 120px;'></td>
			<td [ngStyle]="{'width': colWidth}">
				<input currencyMask [options]="{ 
					prefix: '', 
					decimal: decimalSeparator, 
					thousands: groupSeparator, 
					precision: scratchpad.factor_precision >= 0 ? 
					  scratchpad.factor_precision : 2
					}"
				  title="Answer" 
				  type="text" 
				  size="1" 
				  name=operand{{scratchpad.operand}} 
				  [(ngModel)]="scratchpad.operand" 
				  placeholder="Answer" 
				  class="form-control" style='max-width: 120px;'>
			</td>
		</tr>
		<tr>
			<td colspan='2'>&nbsp;</td>
			<td style='text-align: right;'>{{ 'general.Total' | translate }}</td>
			<td>
				<span class="total-value" *ngIf="total">{{total | number: '1.0-5': userLocale }}</span>
			</td>
		</tr>
		<tr>
			<td style="width: 3em"></td>
			<td colspan='3'>
				<div *ngIf="hideActionLoader">
					<button type="button" class="btn btn-primary" (click)="saveAndCalcScratchpad('1')">{{ 'general.save' | translate }}</button>
					<button type="button" class="btn btn-primary" (click)="saveAndCalcScratchpad('0')">{{'general.Calculate' | translate }}</button>
					<button type="button" class="btn btn-primary" (click)="clearAnswer();">{{ 'general.Clear' | translate}}</button>
				</div>
				<div *ngIf="!hideActionLoader">
					<div class='row'>
						<div class='col-xs-12'>
							<img class="actionLoader" [hidden]="hideActionLoader" src="{{fullImagePath}}" />
						</div>
					</div>
				</div>
			</td>
		</tr>
	</table>
	
	<!-- <div class='row' style='margin-bottom: 20px;'>
		<div class='col-xs-12'>
			<small>A {{scratchPadName}} for</small><br>
			<strong>{{scratchpadFactorName}}</strong>
		</div>
	</div>
	<div *ngFor="let scratchpad of scratchpadList.units">
		<div class="row">
			<div class="col-xs-4">
				<label>{{scratchpad.name}}</label>
				<span *ngIf="scratchpad.open_paren==1"> ( </span>
				<input title="Answer" type="text" size="1" name=operand{{scratchpad.operand}} value="{{scratchpad.operand}}" [(ngModel)]="scratchpad.operand" placeholder="Answer" class="form-control" style='max-width: 200px;'>
				<span *ngIf="scratchpad.close_paren==1"> ) </span>
			</div>
		</div>
		<div class="row" *ngIf="scratchpad.operator_id !='5'">
			<div class="col-xs-4">
				<i style='color: blue;'>{{getOperatorText(operatorList[scratchpad.operator_id - 1].sign)}}</i>
			</div>
		</div>
	</div> -->
	<!-- <div class="form-group">
		<div *ngIf="hideActionLoader">
			<button type="button" class="btn btn-primary" (click)="saveAndCalcScratchpad('1')">{{ 'general.save' | translate }}</button>
			<button type="button" class="btn btn-primary" (click)="saveAndCalcScratchpad('0')">{{'general.Calculate' | translate }}</button>
			<button type="button" class="btn btn-primary" (click)="clearAnswer();">{{ 'general.Clear' | translate}}</button>
			<span class="total-value" *ngIf="total != undefined">{{ 'general.Total' | translate }} : {{total}}</span>
		</div>
		<div *ngIf="!hideActionLoader">
			<div class='row'>
				<div class='col-xs-12'>
					<img class="actionLoader" [hidden]="hideActionLoader" src="{{fullImagePath}}" />
				</div>
			</div>
		</div>
	</div> -->
</div>
