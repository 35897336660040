import { NgModule } from '@angular/core';

import { SolutionCompetitorsComponent } from "./competitors.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';
 
import { ModelScalersModule } from '../scalers/scalers.module';
import { RouterModule } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { AECompetitorsModule } from './ae_competitors/ae_competitors.module';
import { RCompetitorsModule } from './r_competitors/r_competitors.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CompetitorTranslation } from './competitors.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		SolutionCompetitorsComponent
	],
	imports: [
		AreYouSureModule,
		RCompetitorsModule,
		AECompetitorsModule,
		MenuModule,
		TableModule,
		SidebarModule,
		RouterModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		CompetitorTranslation
	],
	entryComponents: [
		SolutionCompetitorsComponent,
	],
	exports: [SolutionCompetitorsComponent]
})
export class ModelSolutionCompetitorsModule { }