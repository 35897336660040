import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsSubtypesTranslations } from './sub-types.translation';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-sub-types',
	templateUrl: './sub-types.component.html',
	styleUrls: ['./sub-types.component.scss']
})
export class SubTypesComponent implements OnInit, OnDestroy {
	@Input() valueMetric: any;
	@Input() valueProp: any;
	@Input() selectedSubTypeFactor: any;
	@Output() closeForm: EventEmitter<any> = new EventEmitter();
	@Output() refreshBenefit: EventEmitter<any> = new EventEmitter();

	showTranslate = false;
	ngUnsubscribe = new Subject();

	constructor(
		private translationService: TranslationsV2Service,
		private CommonService: CommonService, private modalService: NgbModal,
		private ValuepropService: ValuepropService,
		private NotificationService: NotificationService,
		public activeModal: NgbActiveModal,
		public trans: BenefitsSubtypesTranslations)
		{

		}

	modalReference: any;
	benefitsSubTypesList: any[] = [];
	names: any[] = [];
	improvementValues: any[] = [];
	financialValues: any[] = [];
	driverValues: any[] = [];
	benefitSubTypeObj: any[] = [];
	isSubTypeEnabled: any = 0;
	SubTypeEnabled: boolean;

	ngOnInit() {
		this.isSubTypeEnabled = parseInt(this.valueMetric.subtypes_enabled);

		this.getTranslations();
		this.CommonService.notifyChangeLanguage.pipe(take(1)).subscribe(() => {
			this.getTranslations();
		 });
		this.CommonService.notifyEditTranslation$.pipe(take(1)).subscribe((res) => {
			this.showTranslate = res;
		  });

		this.readSubTypes();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(take(1)).subscribe(res => {
		this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);
	 });
	}

	 sendNotification() {
        this.closeForm.emit('cancel');
    }

	open(content) {
		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}

	readSubTypes() {
		this.ValuepropService.getValuePropMetric_DriverFactorSubTypes(this.valueProp.id, this.valueMetric.value_prop_metric_id).pipe(take(1)).subscribe((response) => {
			if (response.result.subtypes) {
				this.benefitsSubTypesList = response.result.subtypes;
				//this.subTypeFactorName = response.result.scratchpad.factor_name;
			}
			else {
				this.benefitsSubTypesList.push({
					id: 0,
					name: "Driver Factor",
					driver_factor_id: "1",
					driver_value: "0",
					improvement_value: "0",
					financial_factor: this.valueMetric.financial_factor,
					financial_value: "0",
					value_prop_metric_id: this.valueMetric.value_prop_metric_id,
					value_prop_id: this.valueProp.id
				})
			}
		});
	}

	addSubTypes() {
		//		let financialName = this.selectedSubTypeFactor.financial_factor;
		let financialName = this.valueMetric.financial_factor;
		let idRandom = Math.floor(Math.random() * 1000)
		this.benefitsSubTypesList.push({
			id: idRandom,
			name: "Driver Factor",
			driver_factor_id: "1",
			driver_value: "0",
			improvement_value: "0",
			financial_factor: financialName,
			financial_value: "0",
			value_prop_metric_id: this.valueMetric.value_prop_metric_id,
			value_prop_id: this.valueProp.id
		})
	}

	textClose(params) {
		this.benefitsSubTypesList = this.benefitsSubTypesList.filter((textField: any) => {
			return textField.id !== params;
		});
	}

	disableSubType(isSubTypeEnabled) {

		this.isSubTypeEnabled = parseInt(isSubTypeEnabled) ? 0 : 1;
		var payload = {
			"driver_factor_id": this.valueMetric.driver_factor_id,
			"subtypes_enabled": this.isSubTypeEnabled
		}

		this.ValuepropService.updateValuePropMetric_DriverFactorSubTypes(payload, this.valueProp.id).pipe(take(1)).subscribe(() => {
			this.valueMetric.subtypes_enabled = parseInt(this.isSubTypeEnabled);
		});
	}

	saveSubType() {
		this.benefitsSubTypesList.forEach(item => {
			this.names.push(item["name"]);
			this.financialValues.push(item["financial_value"]);
			this.improvementValues.push(item["improvement_value"]);
			this.driverValues.push(item["driver_value"]);
			return item;
		});
		this.benefitSubTypeObj = [];
		this.benefitSubTypeObj.push({
			"value_prop_id": this.valueProp.id,
			"value_prop_metric_id": this.valueMetric.value_prop_metric_id,
			"driver_factor_id": this.valueMetric.driver_factor_id,
			"name": this.names.join(','),
			"driver_value": this.driverValues.join(','),
			"improvement_value": this.improvementValues.join(','),
			"financial_value": this.financialValues.join(',')
		});

		var payload = this.benefitSubTypeObj[0];
		this.ValuepropService.createValuePropMetric_DriverFactorSubTypes(this.valueProp.id, payload).pipe(take(1)).subscribe((response) => {

			if (response.result && (response.result.success !== false)) {
				this.ValuepropService.refreshDashboard.next('no_reload');
				this.NotificationService.success("Value Prop Subtypes saved successfully", false); // Params {message, islogout}
				this.refreshBenefit.emit('true');

			}
			else if (response.result.success === false) {
				this.NotificationService.error(response.result.message, false); // Params {message, islogout}
			}

			this.activeModal.close();
		});
	}
}
