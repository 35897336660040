import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BenefitsComponent } from './benefits.component';
import { BenefitPanelComponent } from './benefits-panel/benefits-panel.component';
import { BenefitTableComponent } from './benefits-table/benefits-table.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { ApplicationPipesModule } from '../../../pipe/pipe.module';
import {TableModule } from 'primeng/table';
import { BenefitRecursiveComponent } from './benefits-recursive/benefits-recursive.component';
import { BenefitPanelCreateFlowComponent } from './benefits-panel-create-flow/benefits-panel-create-flow.component';
import { DLInputSwitchModule } from '@shared_components/primeng_toggle/toggle.module';

import {CheckboxModule} from 'primeng/checkbox';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { EditTranslationsModule} from '@shared_components/editTranslation/edit.directive.module';
import { BenefitPanelSimpleComponent } from './benefits-panel-simple/benefits-panel-simple.component';
import { BenefitDetailsModule } from './benefit-detail/benefit-detail.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
	imports: [
    MatTooltipModule,
		CheckboxModule,
		DragDropModule,
		EditTranslationsModule,
		DLInputSwitchModule,
		TableModule,
		MenubarModule,
		ApplicationPipesModule,
		SidebarModule,
		CommonModule,
		NgbModule,
		FormsModule,
		BenefitDetailsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})

	],
	declarations: [
		BenefitPanelCreateFlowComponent,
		BenefitPanelComponent,
		BenefitPanelSimpleComponent,
		BenefitTableComponent,
		BenefitsComponent,
		BenefitRecursiveComponent
	],
	entryComponents: [
		BenefitPanelSimpleComponent,
		BenefitPanelComponent,
		BenefitTableComponent,
		BenefitsComponent,
		BenefitRecursiveComponent
	],
	providers: [Translations],
	exports: [BenefitsComponent]
})
export class BenefitsModule { }
