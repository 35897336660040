import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { SearchCompanyComponentV2 } from './search-company.component.v2';

import { TranslateLoader } from '@ngx-translate/core';
import { AddVPCompanyModule } from '../add-company/vp-add-company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SidebarModule } from 'primeng/sidebar';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './search-companyv2.translation';
import { TableModule } from 'primeng/table';
import { MatTooltipModule } from '@angular/material/tooltip';
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    AddVPCompanyModule,
    SidebarModule,
    CommonModule,
    MatTooltipModule,
    NgbModule,
    FormsModule,
    EditTranslationsModule,
    TableModule
  ],
  declarations: [
    SearchCompanyComponentV2
  ],
  providers: [DefaultTranslations, TranslationsV2Service],
  exports: [SearchCompanyComponentV2]
})
export class SearchCompanyModuleV2 { }
