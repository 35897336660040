import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Notification {
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
  logout: boolean;
}

@Injectable()
export class NotificationService {
  private subject = new Subject<Notification>();

  getNotification(): Observable<any> {
    return this.subject.asObservable();
  }

  error(message: string, logout: boolean) {
    this.subject.next(<Notification>{ type: 'error', message: message, logout: logout });
  }

  success(message: string, logout: boolean) {
    this.subject.next(<Notification>{ type: 'success', message: message, logout: logout });
  }

  info(message: string, logout: boolean) {
    this.subject.next(<Notification>{ type: 'info', message: message, logout: logout });
  }

  warning(message: string, logout: boolean) {
    this.subject.next(<Notification>{ type: 'warning', message: message, logout: logout });
  }

  serverError(message: string, logout: boolean) {
    this.subject.next(<Notification>{ type: 'error', message: message, logout: logout });
  }
}
