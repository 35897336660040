<div class="keywest_droparea" style="margin-bottom: 20px" *ngIf="canEdit">
  <input
    id="fileUploader"
    class="hidden_droparea clickable"
    type="file"
    [size]="maxSizeKB"
    multiple
    accept=".jpg,.png,.pdf"
    #fileInput
    [disabled]="!multiple && files.length > 0"
    (change)="uploadFile($any($event).target.files)"
  />
  <div class="info_droparea">
    <i class="fa fa-cloud-upload upload-cloud"></i><br />
    <span *ngIf="useDark" (click)="fileInput.click()" class="dark-text"> {{ title }}<br /></span>
    <span *ngIf="!useDark"(click)="fileInput.click()" class="text drop-area-text"> {{ title }}<br /></span>
    <span class="small drop-area-text">{{ msg }}</span>
  </div>
</div>

<ng-container *ngFor="let file of files; let i = index">
  <div class="picture-list-item-wrapper row">
    <div class="col-xs-3">
      <img class="file-image" [src]="file.path" />
    </div>
    <div class="col-xs-9">
      <span class="list-item">
        <span class="light-h bold-header-2022">
          {{ file.image_name }}
        </span>
        <br />
        <span class="light-l bold-header-2022">
          {{ file.image_size }}
        </span>
      </span>
    </div>
    <span class="col-xs-1 pull-right close-icon">
      <i (click)="funcDeleteFileServer(i, file)" class="fa fa-close fa-lg list-item-delete light-h"></i>
    </span>
  </div>
</ng-container>
