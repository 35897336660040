<div class="sidebar_wrapper">
  <div class="sidebar_header" *ngIf="sidebar_title && sidebar_title.value">
    {{ sidebar_title.value }}
  </div>

  <div class="sidebar_container">
    <div class="keywest_panel">
      <div *ngIf="!toggleEditSectionMediaForm">
        <form name="addSectionForm" #addSectionForm="ngForm">
          <div class="row">
            <div class="col-xs-12">
              <label class="sidebar_label"
                >{{ trans.trans.section_type.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.section_type"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <div class="input-group keywest_search" appNGError [elemControl]="addSectionForm.controls.sectionType">
                <div class="input-group-addon btn_customizable left-select-addon">
                  <i
                    [matTooltip]="trans.trans.add_section_type.value"
                    matTooltipPosition="above"
                    (click)="isCollapsed = !isCollapsed"
                    class="i-white"
                    [ngClass]="isCollapsed ? 'clickable fa fa-plus' : 'clickable fa fa-minus'"
                  ></i>
                </div>
                <select class="form-control" name="sectionType" ret-data="Section Type" [(ngModel)]="sectionEdit.sectionType" required>
                  <option value="" disabled>
                    {{ trans.trans.choose_section_type.value }}
                  </option>
                  <option *ngFor="let sectionType of sectionTypes" [ngValue]="sectionType">
                    {{ sectionType.name }}
                  </option>
                </select>
              </div>
              <span style="color: red" *ngIf="sectionTypes.length === 0">
                {{ trans.trans.deleteExisting.value }}
              </span>
            </div>
          </div>
          <div class="keywest_subpanel" style="margin-bottom: 30px" *ngIf="!isCollapsed">
            <div class="row">
              <div class="col-xs-12" id="collapseExample">
                <div class="sidebar_header">
                  {{ trans.trans.add_section_type.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.add_section_type"
                    (callback)="getTranslations()"
                  ></app-editTranslation
                  ><i (click)="isCollapsed = !isCollapsed" class="fa fa-times pull-right clickable"></i>
                </div>
              </div>
            </div>
            <form class="add-section-container" name="addSectionTypeForm" #addSectionTypeForm="ngForm" class="keywest_form">
              <div class="row">
                <div class="col-xs-12" appNGError [elemControl]="addSectionTypeForm.controls.name">
                  <label class="sidebar_label"
                    >{{ trans.trans.section_type_name.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.section_type_name"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </label>
                  <input type="text" class="form-control" name="name" ret-data="Name" [(ngModel)]="sectionEdit.name" required [placeholder]="trans.trans.section_type_name.value" />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12" appNGError [elemControl]="addSectionTypeForm.controls.description">
                  <label class="sidebar_label"
                    >{{ trans.trans.section_type_description.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.section_type_description"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </label>
                  <textarea
                    class="form-control"
                    name="description"
                    [(ngModel)]="sectionEdit.description"
                    ret-data="Description"
                    required
                    [placeholder]="trans.trans.section_type_description.value"
                  ></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <app-btn-save
                    *ngIf="!isCollapsed"
                    [form]="addSectionTypeForm"
                    (callback)="addSectionType(addSectionTypeForm)"
                    [text]="trans.trans.save.value"
                    class="btn btn_customizable pull-right"
                  ></app-btn-save>
                </div>
              </div>
            </form>
          </div>
          <div class="row">
            <div class="col-xs-12" appNGError [elemControl]="addSectionForm.controls.description">
              <label class="sidebar_label"
                >{{ trans.trans.section_description.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.section_description"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <textarea class="form-control" name="description" [(ngModel)]="sectionFormObj.description" ret-data="Description" required></textarea>
            </div>
          </div>

          <div class="row" style="margin-bottom: 0">
            <div class="col-md-12">
              <input type="hidden" name="account_case_study_id" [(ngModel)]="sectionEdit.account_case_study_id" />
              <input type="hidden" name="case_study_section_id" [(ngModel)]="sectionEdit.id" />
              <app-btn-save
                *ngIf="mode == 'add'"
                [form]="addSectionForm"
                (callback)="addSection(addSectionForm)"
                text="{{ trans.trans.save.value }}"
                class="btn btn_customizable pull-right marg-v"
              ></app-btn-save>
              <app-btn-save
                *ngIf="mode == 'edit'"
                [form]="addSectionForm"
                (callback)="updateSection(addSectionForm)"
                [text]="trans.trans.update.value"
                class="btn btn_customizable pull-right marg-v"
              ></app-btn-save>
            </div>
          </div>

          <div class="keywest_subpanel">
            <div class="row">
              <div class="col-xs-12">
                <div class="sidebar_header">
                  {{ trans.trans.addMedia.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.addMedia"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
              </div>
            </div>

            <form name="addMediaForm" #addMediaForm="ngForm" class="keywest_form">
              <div class="row">
                <div class="col-xs-12" appNGError [elemControl]="addMediaForm.controls.mediaType">
                  <label class="sidebar_label"
                    >{{ trans.trans.mediaType.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.mediaType"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </label
                  ><br />

                  <span style="font-size: 70%"
                    >{{ trans.trans.selectVideoImage.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.selectVideoImage"
                      (callback)="getTranslations()"
                    ></app-editTranslation> </span
                  ><br />

                  <div class="old-input" *ngIf="!(style2022$ | async)">
                    <input type="radio" name="typeLink" [(ngModel)]="mediatype" value="link" />{{ trans.trans.videoUrl.value }}
                  </div>
                  <fieldset class="radio-control" *ngIf="style2022$ | async">
                    <div>
                      <label>
                        <input type="radio" name="typeLink" [(ngModel)]="mediatype" value="link" />
                        <span>{{ trans.trans.videoUrl.value }}</span>
                      </label>
                    </div>
                  </fieldset>
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.videoUrl"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <div class="old-input" *ngIf="!(style2022$ | async)">
                    <input type="radio" name="typeMedia" [(ngModel)]="mediatype" value="media" style="margin-left: 20px" />{{ trans.trans.image.value }}
                  </div>
                  <fieldset class="radio-control" *ngIf="style2022$ | async">
                    <div>
                      <label>
                        <input type="radio" name="typeMedia" [(ngModel)]="mediatype" value="media" style="margin-left: 20px" />
                        <span>{{ trans.trans.image.value }}</span>
                      </label>
                    </div>
                  </fieldset>
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.image"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <input type="hidden" class="form-control" ret-data="Media Type" name="mediaType" [(ngModel)]="mediatype" required />
                </div>
              </div>

              <div class="row" *ngIf="mediatype == 'link'">
                <div class="col-xs-12" appNGError [elemControl]="addMediaForm.controls.url">
                  <label class="sidebar_label"
                    >{{ trans.trans.videoUrl.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.videoUrl"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="url"
                    ret-data="Url"
                    [(ngModel)]="mediaFormObj.url"
                    class="form-control"
                    placeholder="Please provide the complete url to the video"
                  />
                </div>
              </div>

              <div class="row" *ngIf="mediatype == 'media'">
                <div class="col-xs-12" appNGError [elemControl]="addMediaForm.controls.media">
                  <label class="sidebar_label"
                    >{{ trans.trans.media.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.media"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </label>
                  <input
                    type="file"
                    class="form-control media-input"
                    ret-data="Image"
                    name="media"
                    [(ngModel)]="mediaFormObj.media"
                    accept="image/*"
                    (change)="handleInputChange($event)"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label class="sidebar_label"
                    >{{ trans.trans.media_description.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.media_description"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </label>
                  <textarea class="form-control" ret-data="Description" name="mediaEditDescription" [(ngModel)]="mediaFormObj.mediaDescription"></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <app-btn-save
                    [form]="addMediaForm"
                    (callback)="addMedia(addMediaForm)"
                    [text]="trans.trans.addMedia.value"
                    class="btn btn_customizable pull-right"
                  ></app-btn-save>
                </div>
              </div>
            </form>
          </div>

          <div class="case_studies-media-list" *ngIf="sectionEditMediaList.length > 0">
            <div class="row case_studies-media-list-row">
              <div class="col-xs-12">
                <div class="case_studies_media_list_header">
                  {{ trans.trans.media.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.media"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
              </div>
            </div>

            <div class="row section-row" *ngFor="let img of sectionEditMediaList">
              <div #editListOverlayTarget></div>
              <div class="col-xs-9" *ngIf="img.type === 'media'">
                <div class="row case_studies-media-list-row">
                  <span class="col-xs-1"><i (click)="setOverlayImageVideo(true, img, editListViewOverlay, $event)" class="fa fa-picture-o"></i></span>
                  <span class="col-xs-8"
                    >{{ img.image_name }}
                    <i class="fa fa-info-circle" *ngIf="img.description && !(style2022$ | async)" [matTooltip]="img.description" matTooltipPosition="above"></i>
                    <span
                      *ngIf="img.description && (style2022$ | async)"
                      class="material-icons-outlined tooltip-custom info-icon"
                      [matTooltip]="img.description"
                      matTooltipPosition="above"
                    >
                      info
                    </span>
                  </span>
                </div>
              </div>

              <div class="col-xs-9" *ngIf="img.type === 'link'">
                <div class="row case_studies-media-list-row">
                  <span class="col-xs-1"><i (click)="setOverlayImageVideo(true, img, editListViewOverlay, $event)" class="fa fa-video-camera" aria-hidden="true"></i></span>
                  <span class="col-xs-8"
                    >{{ img.path }}
                    <i class="fa fa-info-circle" *ngIf="img.description && !(style2022$ | async)" [matTooltip]="img.description" matTooltipPosition="above"></i>
                    <span
                      *ngIf="img.description && (style2022$ | async)"
                      class="material-icons-outlined tooltip-custom info-icon"
                      [matTooltip]="img.description"
                      matTooltipPosition="above"
                    >
                      info
                    </span>
                  </span>
                </div>
              </div>

              <div class="col-xs-3">
                <span class="keywest_icon_group pull-right">
                  <span (click)="openEditMedia(img)" [matTooltip]="trans.trans.edit.value" matTooltipPosition="above">
                    <i class="fa fa-pencil"></i>
                  </span>
                  <span>
                    <app-are-you-sure
                      [matTooltip]="trans.trans.delete.value"
                      matTooltipPosition="above"
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="deleteMedia(img.id)"
                    >
                    </app-are-you-sure>
                  </span>
                </span>
              </div>
            </div>

            <p-overlayPanel #editListViewOverlay appendTo="body" styleClass="overlayStyle" [showCloseIcon]="true">
              <div class="overlayVideoImage">
                <div class="sidebar_header_container">
                  <div class="case_studies_media_list_header">
                    {{ overlayImgVideo.description }}
                    <i *ngIf="overlayImgVideo.type === 'media'" (click)="downAsset(overlayImgVideo.path)" class="fa fa-cloud-download pull-right download_icon"></i>
                  </div>
                </div>
                <span *ngIf="overlayImgVideo.type === 'media'">
                  <img [src]="overlayImgVideo.path" class="img-responsive" />
                </span>
                <span *ngIf="overlayImgVideo.type == 'link'">
                  <div>
                    <iframe [src]="embeddedVideoURL" width="600" height="400" frameborder="0" allowfullscreen></iframe>
                  </div>
                </span>
              </div>
            </p-overlayPanel>
          </div>
        </form>
      </div>
      <ng-container *ngIf="toggleEditSectionMediaForm">
        <form name="editMediaForm" #editMediaForm="ngForm">
          <div class="sidebar_header">
            {{ trans.trans.editMedia.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.editMedia"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
          <!-- edit existing media -->

          <div class="sidebar_container">
            <div class="keywest_panel">
              <div class="col-xs-12">
                <label class="sidebar_label"
                  >{{ trans.trans.mediaType.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.mediaType"
                    (callback)="getTranslations()"
                  ></app-editTranslation> </label
                ><br />
                <span style="font-size: 70%"
                  >{{ trans.trans.selectVideoImage.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.selectVideoImage"
                    (callback)="getTranslations()"
                  ></app-editTranslation></span
                ><br />
                <input type="radio" name="mediaEditType" [(ngModel)]="mediaEditType" value="link" />{{ trans.trans.videoUrl.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.videoUrl"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <input type="radio" name="mediaEditType" [(ngModel)]="mediaEditType" value="media" style="margin-left: 20px" />{{ trans.trans.image.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.image"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <input class="form-control" ret-data="Media Type" type="hidden" name="editMediaType" [(ngModel)]="mediaEditType" required />
              </div>

              <div *ngIf="mediaEditType == 'link'">
                <div class="row">
                  <div class="col-xs-12" appNGError [elemControl]="editMediaForm.controls.editMediaUrl">
                    <label class="sidebar_label"
                      >{{ trans.trans.videoUrl.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.videoUrl"
                        (callback)="getTranslations()"
                      ></app-editTranslation>
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      name="editMediaUrl"
                      ret-data="Url"
                      [(ngModel)]="mediaFormObj.url"
                      placeholder="Please provide the complete url to the video"
                      required
                    />
                  </div>
                </div>
              </div>

              <div *ngIf="mediaEditType === 'media'">
                <div class="row">
                  <div class="col-xs-12" appNGError [elemControl]="editMediaForm.controls.editMediaImage">
                    <div *ngIf="!editFiles">
                      <img [src]="mediaFormObj.media" class="img-repsonsive" style="max-height: 38px" />
                    </div>
                    <label class="sidebar_label"
                      >{{ trans.trans.media.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.media"
                        (callback)="getTranslations()"
                      ></app-editTranslation>
                    </label>
                    <input name="editMediaImage" class="form-control" ret-data="Media" type="file" accept="image/*" (change)="handleInputChangeEdit($event)" required />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <label class="sidebar_label"
                    >{{ trans.trans.media_description.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.media_description"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </label>
                  <textarea class="form-control" ret-data="Description" name="mediaEditDescription" [(ngModel)]="mediaFormObj.mediaDescription"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_container">
            <div class="col-md-12" style="padding-right: 4em">
              <input type="hidden" name="mediaId" [(ngModel)]="mediaFormObj.id" />
              <input type="hidden" name="account_case_study_section_id" [(ngModel)]="mediaFormObj.account_case_study_section_id" />
              <app-btn-save
                *ngIf="toggleEditSectionMediaForm"
                [form]="editMediaForm"
                (callback)="editMedia(editMediaForm)"
                [text]="trans.trans.updateMedia.value"
                class="btn btn_customizable pull-right"
              ></app-btn-save>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>
