import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BenefitDetailComponent } from './benefit-detail.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SubTypesComponent } from '../sub-types/sub-types.component';
import { ScratchpadComponentComponent } from 'app/value-prop/common/scratchpad-component/scratchpad.component';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { BenefitDetailV1 } from './benefit-detail.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatTooltipModule } from '@angular/material/tooltip';


export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		MenubarModule,
		TableModule,
		SidebarModule,
    MatTooltipModule,
		CommonModule,
		FormsModule,
		NgbModule,
		ApplicationPipesModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		CurrencyMaskModule,
	],
	declarations: [
		ScratchpadComponentComponent,
		SubTypesComponent,
		BenefitDetailComponent
	],
	providers: [
		BenefitDetailV1,
		TranslationsV2Service
	],
	exports: [BenefitDetailComponent]
})
export class BenefitDetailsModule { }
