import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { SvgIconModule } from '@shared_components/svg-icon/svg-icon.module';
import { ValuePropCloneModule } from 'app/value-prop/value-prop-dashboard/clone/clone.module';
import { PromoteRealizationModule } from 'app/value-prop/value-prop-dashboard/promote-realization/promote-realization.module';
import { ShareModule } from 'app/value-prop/value-prop-dashboard/sharev2/share.module';
import { CollaborationModule } from 'app/value-prop/value-prop-dashboard/collaboration/collaboration.module';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { RSV2DiscoveryQuestionsModule } from '../discovery_questions/questions.module';
import { RSV2AddToVPGComponent } from '../promoteVPG/vpg.component';
import { ToolbarAssetsComponent } from './toolbar-assets/toolbar-assets.component';
import { RSV2ToolbarComponent } from './toolbar.component';
import { RSV2ToolbarTranslations } from './toolbar.translation';
import { MatButtonModule } from '@angular/material/button';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { LinkyModule } from 'ngx-linky';
import { LogModule } from 'app/value-prop/value-prop-dashboard/log/log.module';
import { VPDashboardAssetsDeliverableModule } from 'app/value-prop/value-prop-dashboard/assets_customer_deliverable/deliverable.module';
import { ModelCollateralModule } from 'app/value-mapv2/solution-detail/collateral/collateral.module';
import { DashboardCollateralModule } from 'app/value-prop/value-prop-dashboard/collateral/collateral.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';

@NgModule({
  declarations: [RSV2ToolbarComponent, RSV2AddToVPGComponent, ToolbarAssetsComponent],
  imports: [
    DLProgressbarModule,
    AreYouSureModule,
    NGFormSaveButtonModule,
    DropdownModule,
    DLNGErrorDirectiveModule,
    RSV2DiscoveryQuestionsModule,
    ShareModule,
    CollaborationModule,
    RouterModule,
    MatTooltipModule,
    ValuePropCloneModule,
    PromoteRealizationModule,
    SidebarModule,
    EditTranslationsModule,
    CommonModule,
    FormsModule,
    MenuModule,
    SvgIconModule,
    MatButtonModule,
    LinkyModule,
    NoRecordsModule,
    LogModule,
    VPDashboardAssetsDeliverableModule,
    ModelCollateralModule,
    DashboardCollateralModule,
    FunctionPipeModule,
  ],
  providers: [RSV2ToolbarTranslations],
  exports: [RSV2ToolbarComponent],
  entryComponents: [RSV2ToolbarComponent],
})
export class RSV2ToolbarModule {}
