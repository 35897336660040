import { Injectable } from '@angular/core';

@Injectable ()

export class AssetListTranslations {

    public config: any = {
        component: 'assetList',
        display_name: 'Asset List',
        description: 'List of assets for downloading'
    };

    public trans: any = {
        download: 'Download'
    };
}
