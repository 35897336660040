import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BenefitTypesService } from '@data/services/benefit-types/benefit-types.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SelectedElement } from '@data/services/valueposition/models/selected-element.interface';
import { NotificationService } from '@services/notification.service';
import { BenefitType } from '@shared/models/benefits.models';
import { of, Subject } from 'rxjs';
import { finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { BenefitTypeTranslations } from '../benefit-type.translation';
import { snakeCase } from 'lodash';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';

export type TranslationStrings = keyof typeof BenefitTypeTranslations.prototype.trans;

@Component({
  selector: 'app-ae-benefit-types',
  templateUrl: './ae-benefit-type.component.html',
  styleUrls: ['./ae-benefit-type.component.scss'],
})
export class AeBenefitTypeComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() mode = 'add';
  @Input() selectedElem: Partial<BenefitType> = {
    name: '',
    description: '',
    id: '',
  };

  @Output() callback = new EventEmitter();
  @Output() closeCallback = new EventEmitter();
  @ViewChild('formAddEditBenefitType') formAddEditBenefitType: NgForm;

  name = '';
  description = '';
  showAdd = false;
  account_id: string;
  sidebar_title: { value?: string } = {};
  toggleAddEditBenefitType: boolean;
  ngUnsubscribe$ = new Subject();
  showTranslate = false;
  isLoading = false;

  constructor(
    private benefitTypeService: BenefitTypesService,
    private commonService: CommonService,
    public trans: BenefitTypeTranslations,
    private translationService: TranslationsV2Service,
    private notificationService: NotificationService,
    private customAssetTagService: CustomAssetTagService
  ) {}

  ngOnInit(): void {
    this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_benefit_type : this.trans.trans.edit_benefit_type;
    this.account_id = sessionStorage.getItem('aid');

    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  insertBenefitType(form: NgForm): void {
    this.isLoading = true;

    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
    };

    this.benefitTypeService
      .postBenefitType(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((benefitTypeRes) => {
          if (this.selectedElem.has_tag) {
            const payload = this.customAssetTagService.createTagPayload(
              benefitTypeRes.result.benefit_type_id,
              this.selectedElem.name,
              this.selectedElem.tag,
              AssetTagArray.BenefitTypes,
              true
            );
            return this.customAssetTagService.createAssetTag(payload);
          } else {
            return of(benefitTypeRes);
          }
        }),
        take(1)
      )
      .subscribe(
        (response) => {
          if (response?.result && response.result === 'The tags must be unique.') {
            this.notify('tagMustBeUnique', 'error');
            return;
          }
          this.notify('benefitTypeUpdatedSuccess');
          this.callback.emit('');
          this.toggleAddEditBenefitType = false;
        },
        () => {
          this.notify('changesFailed', 'error');
        }
      );
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
        this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_benefit_type : this.trans.trans.add_benefit_type;
      });
  }

  updateBenefitType(form: NgForm): void {
    this.isLoading = true;

    const payload = {
      benefit_type_id: form.controls.id.value,
      name: form.controls.name.value,
      description: form.controls.description.value,
    };
    this.benefitTypeService
      .putBenefitType(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((benefitTypeRes) => {
          if (this.selectedElem.has_tag) {
            const assetId = this.selectedElem.tag_data?.id;
            const payload = this.customAssetTagService.createTagPayload(
              this.selectedElem.id,
              this.selectedElem.name,
              this.selectedElem.tag,
              AssetTagArray.BenefitTypes,
              true,
              assetId
            );
            return this.customAssetTagService.editAssetTag(payload);
          } else if (this.selectedElem.tag_data) {
            return this.customAssetTagService.deleteAssetTag(this.selectedElem.tag_data?.id);
          } else {
            return of(benefitTypeRes);
          }
        }),
        take(1)
      )
      .subscribe(
        (response) => {
          if (response?.result && response.result === 'The tags must be unique.') {
            this.notify('tagMustBeUnique', 'error');
            return;
          }
          this.notify('changesUpdatedSuccess');
          this.callback.emit('');
          this.toggleAddEditBenefitType = false;
        },
        () => {
          this.notify('changesFailed', 'error');
        }
      );
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.selectedElem = {
        ...this.selectedElem,
        tag: snakeCase(this.selectedElem.name),
      };
    } else if (this.selectedElem.tag_data?.id) {
      this.notify('customBenefitTagWarning', 'warning');
    }
  }

  private notify(transString: TranslationStrings, type: 'error' | 'warning' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
