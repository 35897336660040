<div class="models_panel" style="border-top-right-radius: 0px !important; display: inline-table; width: 100%; margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header" style="padding-top: 0px">
      <div class="row">
        <div class="col-xs-10">
          <div class="title" style="padding-top: 0px">
            <h2>
              {{ str }}
              <span *ngIf="desc" [matTooltip]="desc" class="material-icons-outlined clickable"> info </span>
              <i (click)="editValueCategory()" *ngIf="!bI.locked && canEdit && !disableEdits" class="fa fa-pencil clickable" style="margin-left: 10px"></i>
              <div *ngIf="editingVC" class="input-group" style="width: 100%" [matTooltip]="trans.trans.ch_renameValueCategory.value" matTooltipPosition="above">
                <input type="text" name="value_category" [(ngModel)]="alt_name" class="form-control" />
                <div class="input-group-btn">
                  <span
                    (click)="closeValueCategoryEdit()"
                    class="clickable input-group-addon action-button"
                  >
                    <i class="fa fa-times"></i>
                  </span>
                  <span
                    (click)="updateValueCategoryName()"
                    class="clickable input-group-addon action-button"
                  >
                    <i class="fa fa-save"></i>
                  </span>
                </div>
              </div>
            </h2>
            <small>{{ benefits.length }} {{ trans.trans.included_benefits.value }}</small>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.included_benefits"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>

        <div class="col-xs-2" *ngIf="benefitFlyPrivilege">
          <div style="padding: 15px" *ngIf="!benfitFlyOpen">
            <button class="btn btn-next btn_customizable pull-right clickable" (click)="benfitFlyOpen = true">
              {{ trans.trans.add_benefit.value }}
            </button>
          </div>
          <div style="padding: 15px" *ngIf="benfitFlyOpen">
            <button class="btn btn-next btn_customizable pull-right clickable" (click)="benfitFlyOpen = false">
              {{ trans.trans.close_add_benefit.value }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="benfitFlyOpen">
      <app-add-benefits-component
        *ngIf="!bI.modelsv2"
        [mini]="true"
        (reloadVP)="reload()"
        (cancelBenefitCallback)="benfitFlyOpen = !benfitFlyOpen"
        (saveBenefitCallback)="benfitFlyOpen = false"
        [categoryId]="categoryId"
        [showHelp]="bI.showHelp"
        [contextualHelp]="bI.contextualHelp"
        [valuePropId]="valueProp.id"
        [valueProp]="valueProp"
      ></app-add-benefits-component>
      <app-ae-benefits
        *ngIf="bI.modelsv2"
        (reloadVP)="reload()"
        [solutionId]="solutionId.toString()"
        [mode]="'add'"
        [can_edit]="true"
        [isOnTheFlyBenefit]="true"
        [categoryFlyId]="categoryId"
        [valuePropId]="valueProp.id"
        (callback)="benfitFlyOpen = false"
      ></app-ae-benefits>
    </div>

    <div class="row" *ngIf="!benfitFlyOpen">
      <div class="col-xs-12">
        <p-table [value]="benefits" [columns]="cols" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr *ngIf="cols">
              <ng-container *ngFor="let col of columns">
                <th *ngIf="col.conditionalField" [pSortableColumn]="col.sort" style="white-space: nowrap">
                  {{ trans.trans[col.field].value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans[col.field]"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <p-sortIcon [field]="col.sort"></p-sortIcon>
                </th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-benefit>
            <tr>
              <td class="benefit-status">
                <span (click)="expandBenefitMini(benefit)">
                  <span class="material-icons-outlined accordion-child">
                    {{ benefit.expanded ? 'arrow_drop_down' : 'arrow_right' }}
                  </span>
                  <span role="button" class="link">{{ benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name }}</span>

                  <span *ngIf="benefit.description" [matTooltip]="benefit.description" class="material-icons-outlined clickable marg-left"> info </span>
                </span>

                <div class="keywest_icon_group" style="margin-left: 10px" *ngIf="benefit.vp_specific == 1">
                  <span><i class="fa fa-bookmark" aria-hidden="true" [matTooltip]="trans.trans.addedManually2.value" matTooltipPosition="above"></i></span>
                  <span *ngIf="canEdit" (click)="benefit.editingFly = !benefit.editingFly" [matTooltip]="trans.trans.update.value" matTooltipPosition="above" class="clickable">
                    <i style="color: green !important" class="fa fa-pencil"></i>
                  </span>
                  <span>
                    <app-are-you-sure
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      [tooltip]="trans.trans.delete.value"
                      icon="fa fa-times"
                      [class]="'no_btn'"
                      *ngIf="canEdit"
                      iconColor="#ff5d5d"
                      (callbackYes)="bI.deleteCustomBenefit(benefit, valueProp.id); reload()"
                    ></app-are-you-sure>
                  </span>
                </div>
              </td>
              <td *ngIf="featureNonFinancial">{{ benefit.benefit_type }}</td>
              <td *ngIf="!feature70">{{ benefit.impact_type }}</td>
              <td *ngIf="feature70">{{ getBenefitType(benefit.soft) }}</td>
              <td>{{ benefit.impact }} {{ benefit.abbr }}</td>
              <td class="preventWordBreak">{{ feature70 && benefit.soft == '2' ? '----' : benefit.year1_benefits_fmt }}</td>
              <td class="preventWordBreak">{{ feature70 && benefit.soft == '2' ? '----' : benefit.total_benefits_fmt }}</td>
            </tr>
            <div [ngClass]="{ row_selected: benefit.expanded || benefit.phasing || benefit.editing }" *ngIf="benefit.expanded">
              <dl-sidebar
                *ngIf="feature57"
                [message]="trans.trans.closeConfirm.value"
                (callback)="benefit.expanded = false; expandBenefitMini(null)"
                [visible]="feature57 && !!benefit"
                styleClass="keywest_sidebar p-sidebar-xl"
                position="right"
                [dismissible]="false"
              >
                <div class="sidebar_wrapper">
                  <div class="sidebar_header">
                    {{ trans.trans.benefitDetails.value }}
                    <a (click)="benefitDetail.funcLiveSave('all', '')" class="btn btn_customizable pull-right" style="margin-top: -4px; margin-right: 30px">{{
                      trans.trans.save.value
                    }}</a>
                  </div>

                  <div class="sidebar_container">
                    <div class="keywest_panel" appHeightCache [trigger]="bI.heightCacheTrigger">
                      <app-benefit-detail
                        #benefitDetail
                        [mini]="true"
                        [shareView]="shareView"
                        (reloadVP)="reload()"
                        [permissions]="permissions"
                        [locked]="locked"
                        [canEdit]="canEdit"
                        [contextualHelp]="bI.contextualHelp"
                        [valueProp]="valueProp"
                        [selectedBenefit]="benefit"
                        (closeBenefitDetail)="benefit.expanded = false"
                        [vc_chart]="true"
                      ></app-benefit-detail>
                    </div>
                  </div>
                </div>
              </dl-sidebar>
            </div>

            <tr class="not-hover" [ngClass]="{ row_selected: benefit.expanded }" *ngIf="benefit.expanded">
              <td colspan="5">
                <app-benefit-detail
                  *ngIf="!feature57"
                  [mini]="true"
                  (reloadVP)="reload()"
                  [shareView]="shareView"
                  [permissions]="permissions"
                  [locked]="locked"
                  [canEdit]="canEdit"
                  [valueProp]="valueProp"
                  [selectedBenefit]="benefit"
                  (closeBenefitDetail)="benefit.expanded = false"
                  [vc_chart]="true"
                ></app-benefit-detail>
              </td>
            </tr>
            <tr class="not-hover" [ngClass]="{ row_selected: benefit.expanded || benefit.phasing || benefit.editing }" *ngIf="benefit.editingFly">
              <td colspan="11">
                <app-add-benefits-component
                  *ngIf="!bI.modelsv2"
                  [mini]="true"
                  (reloadVP)="reload()"
                  (cancelBenefitCallback)="benefit.editingFly = !benefit.editingFly"
                  (saveBenefitCallback)="benefit.editingFly = false"
                  [selectedBenefit]="benefit"
                  [showHelp]="bI.showHelp"
                  [contextualHelp]="bI.contextualHelp"
                  [valuePropId]="valueProp.id"
                  [valueProp]="valueProp"
                ></app-add-benefits-component>
                <app-ae-benefits
                  *ngIf="benefit.editingFly && bI.modelsv2"
                  (reloadVP)="reload()"
                  [solutionId]="benefit.account_solution_id"
                  [mode]="'edit'"
                  [selectedElem]="benefit"
                  [can_edit]="true"
                  [isOnTheFlyBenefit]="true"
                  [valuePropId]="valueProp.id"
                  (callback)="benefit.editingFly = false"
                ></app-ae-benefits>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
