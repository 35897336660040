import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-skeleton',
  templateUrl: './table-skeleton.component.html',
  styleUrls: ['./table-skeleton.component.scss']
})
export class TableSkeletonComponent implements OnInit {
  @Input() rowLimit = 10;
  @Input() columns:Array<{field: string; header: string, sortField?: string}> = [];
  @Input() trans;
  @Input() rowHeight = '65px';
  @Input() useTanslations = true;
  @Input() useHeaderValues = false;

  public items = [];

  constructor() { }

  ngOnInit(): void {
    this.items = new Array(10);
  }
}