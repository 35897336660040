import { ValueGroup } from '@shared/models/group.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VGPService } from '@data/services/value-prop-group/vgp.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rsv2-promote-vpg',
  templateUrl: './vpg.component.html',
})
export class RSV2AddToVPGComponent implements OnInit, OnDestroy {
  @Input() valueProp: any;
  @Output() callback = new EventEmitter();

  ngUnsubscribe = new Subject();

  vpg = false;
  toggleAddVPG = false;
  selectedVPG: ValueGroup;
  vpgList:  ValueGroup[] = [];
  uid: string = null;
  loadingVPG = false;
  saveLoader = false;
  strVPG = '';
  crm: string;

  constructor(private notificationService: NotificationService, private VPGService: VGPService) {}
  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.getVPGData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getVPGData(): void {
    this.VPGService.getVPGGroupListArray(this.uid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.vpgList = res.result.value_prop_groups;
      });
  }

  funcSaveVPG(): void {
    this.loadingVPG = true;
    const arr = [];
    arr.push(this.valueProp.id);

    const payload = {
      members: arr,
    };
    this.VPGService.addSingleVPtoVPG(this.selectedVPG.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toggleAddVPG = false;
        this.loadingVPG = false;
        this.notificationService.success('Business Case added to Value Group Successfully', false);
        this.callback.emit();
      });
  }

  closeVPG(): void {
    this.callback.emit();
  }

  funcAddVPG(): boolean {
    if (!this.strVPG) {
      return false;
    }
    const payload = {
      group_type_id: '1',
      name: this.strVPG,
    };

    this.VPGService.postCreateVPG(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getVPGData();
        this.strVPG = '';
        this.notificationService.success('Group Created Successfully', false);
      });
  }
}
