<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='row'>
	<div class='col-xs-4'>
		<div class="input-group keywest_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input [(ngModel)]="strSearch" (keyup)="tableRealization.filter($any($event.target).value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
			<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableRealization.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
		</div>
	</div>
	<div class='col-xs-8'>
		<span class="clickable pull-right row-limit" (click)='row.toggle($event)'>{{trans.trans.rowLimit.value}} {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
	</div>
</div>

<div class='row'>
	<div class='col-xs-12'>
		<p-table #tableRealization [value]="caseStudies" [columns] = "columns" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th [pSortableColumn]="'name'">
						{{trans.trans.name_label.value}}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='value_realization.yourValueRealization'
						[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
						<p-sortIcon [field]="'name'"></p-sortIcon>
					</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-caseStudy>
				<tr>
					<td *ngIf="!isTracker">
						<a routerLink="/valuerealization/{{valuePropId}}/casestudy/{{caseStudy.id}}" class='link'>{{caseStudy.name}}</a>
					</td>
					<td class="clickable" *ngIf="isTracker">
						<a (click)="callback.emit(caseStudy)" class='link'>{{caseStudy.name}}</a>
					</td>
					<td class="">
						<app-are-you-sure [tooltip]="trans.trans.delete.value" [message]='trans.trans.areYouSure.value' class="pull-right" icon='fa fa-trash' text='' (callbackYes)='deleteCaseStudy(caseStudy.id)'></app-are-you-sure>
					</td>
				</tr>
			</ng-template>
		</p-table>

	</div>
</div>
