export default {
  'contextual_help': {
    vp_dashboard: {
      rename_value_prop: 'Ability to rename the value Proposition',
      assets: 'Sales-ready Assets usable in different sales stages.',
      lock: 'Value Prop status and the ability to lock to prevent change.',
      position4: 'Common hurdles with status indicators.',
      position5: 'Action icons let you edit,share or clone the value prop.',
      position6: 'Charts and Reports panel enable the details >>>',
      positiion7: 'Back To Overview',
      share: 'Click to share this ValueProp.',
      clone: 'Click to clone this ValueProp',
      account: 'The definition of your sales opportunity.  Who are you selling, what are you selling, and what special situations are you aware of.',
      benefits: 'The metrics used to quantify the improvement to the customer after successfully deploying your solution.',
      key_assumptions: 'Specific factors which driver your value formulas',
      tco: 'Total cost of ownership (TCO) is a financial estimate intended to help buyers and owners determine the direct and indirect costs of a product or system. It is a management accounting concept that can be used in full cost accounting or even ecological economics where it includes social costs.',
      roi: 'Ratio of cumulative net benefits to cumulative investment over the project period.An ROI of 200% means that every dollar spent on project nets two dollars in return.You want to align your ROI to be compelling enough for action by the buyer but also not too large to be unbelievable.Formula: ROI = (benefits – investments) / investments',
      notes: 'Notes that you have captured during the process of iterating your value conversation.',
      position16: 'Who',
      position17: 'what',
      position18: 'Against Who',
      position19: 'How much',
      collateral:
        'Collateral is already composed content (PDF, PowerPoint, etc.) that you want to use to support your value proposition to a customer. Common types of collateral are formal case studies, data sheets, analyst reports, and anything that can defend your thinking on the value you can provide.',
      advanced: 'Fine tune your value even further with advanced options',
      position22: 'Toggle showing all the benefits in the list below',
      search_benefits: 'Search all benefits based on name.',
      position24: 'Phase benefits over time.',
      benefit_status: 'Change benefit status',
      position26: 'Drill down into benefit details',
      position27: 'Rename the benefit',
      position28: 'Description of the benefit and how your features contribute',
      position29: 'Add a customer note',
      models: 'The catalog of your value usually at the solution level',
      position31: 'Formula for measuring the benefit',
      add_value_prop: 'Add Value Prop',
      search_value_prop: 'Search for a Value Prop',
      costs: 'The investment required to achieve the benefits in scope',
      position35: 'The detailed assumptions that drive your benefits and costs',
      cashflow:
        "Cash flow is the net amount of cash and cash-equivalents being transferred into and out of a business. At the most fundamental level, a company's ability to create value for shareholders is determined by its ability to generate positive cash flows, or more specifically, maximize long-term free cash flow.",
      log: 'Log of events that have taken place including who made what changes and when',
      scorecard:
        'Quick check of your completion that can be used to evaluate whether your value is close to sharing with a customer or close to being fully iterated.  This also includes whether your value metrics are within reasonable tolerance.',
      position39: 'Zoom In',
      position40: 'Zoom Out',
      scalers: 'The determining factors of sizing your customer.  Often these will influence how large or small your value and costs are for a given account.',
      position42: 'Projects and priorities of the account to which you can align and drive impact.',
      position43: 'The company you are selling',
      situation: 'Forces that change your value.  Items may include/exclude or change benefits, costs, collateral, or case study / supporting materials.',
      position45: 'The timeframe and quantity of your rollout to a customer.',
      case_studies:
        'Case Studies are a structured capture of Testimonial, objectives, and specific benefit improvements achieved by a particular customer and value proposition after they have implemented your solution.',
      baseline_type: 'Set all of your projected improvements to conservative, probable, or aggressive.  This will not effect any improvements you have manually overwritten.',
      term: 'The length in years of evaluating the value to a customer.',
      cost_of_capital: 'The internal interest rate assigned for a company; this helps to define the future value of money calculations like NPV.',
      deployment_time: 'Number of months it will take the customer after contract signing to start achieving any value.',
      opportunity_id: 'Your CRM opportunity ID which will help administrators report on attach rates of your Value Propositions.',
      currency: 'The currency you would like your Value Proposition displayed.',
      conversion_rate: 'The current conversion rate between your desired currency and the US Dollar.',
      gross_profit_margin: 'The revenue achieved by a company minus the cost of goods sold.',

      edit_translations: 'Edit Translations',
      refresh_data: 'Refresh Data',
      hide_help: 'Hide Help',
      show_help: 'Show Help',
      download_assets: 'Download Assets',
      download: 'Download',
      quantity: 'Quantity',
      discount: 'Discount',
      edit: 'Edit',
      delete_this_vp: 'Delete this Value Prop',
      roi_range: 'ROI range',
      add_cost: ' Add Cost',
      click_to_change_status: 'Click to change Status',
    },
    value_position: {
      add_value_position: 'Add value position',
      add_new: "Can't find in our database? Add a company here",
    },
    value_realization: {
      add_new_value_realization: 'Add new value realization',
    },
    models_v2: {
      overview: 'Basic facts and figures of the model',
      benefits: 'The Metrics used to quantify the improvement to the customer after successfully deploying your solution',
      costs: 'The investment required to achieve the benefits in scope',
      situations: 'Forces that change your value. Items may include/exclude or change benefits, costs, collateral, or case study / supporting materials',
      competitors: 'Companies that have similiar solutions to yours that you need to differentiate from',
      case_studies: 'Capture empirical improvements, customer sentiments, details, and media about customer success',
      collateral: 'Standard materials used to help defend your value. Usually created by marketing',
      thresholds: 'Tolerance measurements to help users understand if their value converstion could be challenged',
      required_inputs: 'The below sections are required to build a model properly. Sections that have no entries are red with a zero (0) total.',
    },
  },
  'vpcreate': {
    account: 'Select your account',
  },
  'admin_panel': {
    import_system: 'Import System Statuses',
    add_new_status: 'Add New Status',
    edit_status: 'Edit Status',
    delete_status: 'Delete Status',
    save_changes: 'Save Changes',
    cancel_edit: 'Cancel',
    your_team: 'Your Team \n Mange your team here',
    translations: {
      title: 'Translations',
    },
    group: {
      manage_user_group: 'Manage User Groups',
      group: 'Group',
      description: 'Description',
      add: 'Add',
      save: 'Save',
      no_entries_found: 'No Entries Found',
    },
  },
  'models_v2': {
    actions: 'Actions',
    models: 'Models',
    models_list: 'Models List',
    model_groups: 'Model Groups',
    benefits_and_groups: 'Benefit Fields and Groups',
    benefits: 'Benefits',
    overview: 'Overview',
    costs: 'Costs',
    situations: 'Situations',
    create_and_edit: 'Create and edit situations below. You can drag and drop situations to re-order them.',
    situation_groups: 'Situation Groups',
    supporting_materials: 'Supporting Materials',
    competitors: 'Competitors',
    case_studies: 'Case Studies',
    collateral: 'Collateral',
    thresholds: 'Thresholds',
    add_new_model: 'Add New Model',
    add_factors: 'Add factors',
    add_situations: 'Add Situations',
    benefit_groups: 'Benefit Groups',
    factor_groups: 'Factor Groups',
    optional_inputs: 'Optional Inputs',
    pain_points: 'Pain Points',
    capabilities: 'Capabilities',
    operational_objectives: 'Operational Objectives',
    functional_objectives: 'Functional Objectives',
    required_inputs: 'Required Inputs',
    add_new_situations: {
      add_new: 'Add New',
      edit_situation_type: 'Edit Situation Type',
      name: 'Name',
      situation_type: 'Situation type',
      no_situations: 'No Situations Yet',
      situation_type_elements: 'Situation Type Elements',
      select_your_option: 'Select Your Option',
      select_situation_type_dropdown: 'Select a situation type from the dropdown above to proceed',
      description: 'Description',
      active: 'Active by Default?',
      available_for_guided_discovery: 'Available for Guided Discovery',
      save: 'Save',
      update: 'Update',
    },
    create_models: {
      model_name: 'Model Name',
      categories: 'Categories',
      average_deployment_time: 'Average Deployment Time',
      average_sales_price: 'Average Sales Price',
      average_sales_cycle: 'Average Sales Cycle',
      description: 'Description',
    },
  },
  'bottom': {
    what_is_the_customer_problem_describe_in_detail: "What is the customer's problem? Describe in details",
    how_does_your_selection_solve_the_customer_problem: 'How does your selection solve the customer problem',
  },
  'asset_prompter': {
    value_prompters: 'Value Prompter',
    file: 'File',
    name: 'Name',
    description: 'Description',
    no_prompters_available: 'No Prompters Available',
    upload: 'Upload',
  },
  'menu': {
    dashboard: 'Dashboard',
    dl_only: 'DL Only',
    learn: 'Learn',
    model: 'Models',
    profile: 'Profile',
    reach: 'Hypotheses',
    research: 'Insights',
    retain: 'Realizations',
    revise: 'Cases',
    support: 'Support',
    logout: 'Logout',
    switch_languages: 'Switch Languages',
    value_tracker: {
      cases_closing_soon: 'Cases Closing Soon',
      active_tracker: 'Active Tracker',
      surveys_received: 'Surveys Received',
      recent_survey_response: ' Recent Survey Response',
      recommended_case_study: 'Recommended Case Study',
      add: 'Add',
      upcoming_surveys: 'Upcoming Surveys',
      create_survey: 'Create Survey',
      name: 'Name',
      company: 'Company',
      last_survey: 'Last Survey',
      upcoming_survey: 'Upcoming Survey',
    },
    value_trackers: 'Value Trackers',
    edit_translations: 'Edit Translations',
  },
  'dashboard_simple': {
    lets_create_a_value_prop: "Let's Create a Value Prop",
    start_with_the_basics_below_and_then_well_get_into_the_details: "Start witht he basics below and then we'll get into thedetails",
    back: 'Back',
    tour: 'Tour',
    download: 'Download',
    discovery_questions: 'Discovery Questions',
    view_all_details: 'View All Details',
    next: 'Next',
  },
  'dashboard_customer': {
    please_login_to_view_this_value_prop: 'Please Login to View this Value Prop',
    login: 'Log in',
    prepared_by: 'Prepared by',
    your_company_information: 'Your Company Information',
    models: 'Models',
    next: 'Next',
    password: 'Password',
  },
  'vp_dashboard': {
    discovery_question: {
      question: 'Discovery Questions',
      next: 'Next',
      no_question: 'No discovery questions found',
      optional: 'Optional',
      required: 'Required',
      save: 'Save',
    },
    explained: {
      name: 'Name',
      value: 'Value',
      factor_type: 'Factor Type',
    },
    roi: {
      chart_year1: 'Year 1 Benefits',
      chart_cost: 'Cost Benefit',
    },
    year1BreakDownChartData: {
      name: 'Name',
      total: 'Total',
    },
    costBenefitChartData: {
      name: 'Name',
      benefits: 'Benefits',
      costs: 'Costs',
    },
    assets: {
      title: 'Assets',
      name: 'Name',
      no_assets_found: 'No assets available for this language, please contact your system administrator to request assets created for this language.',
    },
    actions: {
      actions: 'Actions',
      lock: 'Lock Value Prop',
      unlock: 'Unlock Value Prop',
      share: 'Share',
      share_this_value_prop: 'Click to share this ValueProp',
      clone: 'Clone',
      clone_this_value_prop: 'Clone this Value Prop',
      refresh: 'Refresh Data',
      hide_help: 'Hide Help',
      show_help: 'Show Help',
      edit_translations: 'Edit Translations',
      download_assets: 'Download Assets',
      download: 'Download',
      take_a_tour: 'Take a tour',
      discovery_questions: 'Discovery Questions',
      edit_status: 'Click to edit status',
    },
    combined_scenario: {
      scenario_text: 'Here is a brief summary of the Account. Please choose the Model(s) you want to use and make updates to any other fields as required.',
    },
    cashflow: {
      name: 'Name',
      year1: 'Year 1',
      year2: 'Year 2',
      year3: 'Year 3',
      year4: 'Year 4',
      year5: 'Year 5',
      total: 'Total',
      no_cashflow_found: 'No cashflow found',
    },
    notes: {
      user: 'User',
      name: 'Name',
      time: 'Time',
      note: 'Note',
      no_notes_found: 'No notes found',
      add_notes: 'Add Note',
      customer_facing: 'Customer Facing',
    },
    scorecard: {
      Status: 'Status',
      PhasingActivated: 'Phasing Activated',
      DriverComplete: 'Driver Complete %',
      FinancialComplete: 'Financial Complete %',
      ImprovementComplete: 'Improvement Complete %',
      total_benefits: 'Total benefits $',
    },
    share: {
      add: 'Add',
      are_you_sure_that_you_want_to_remove_this_priviledge: ' Are you sure that you want to remove this priviledge?',
      name: 'Business Case / Value Assessment',
      user_name: 'User Name',
      share_role_type: 'Share Role Type',
      actions: 'Actions',
      no_shares_found: 'No shares found',
      edit_share: 'Edit Share',
      choose_one: 'Choose One',
      existing_guest: 'Existing Guest',
      first_name: 'First Name',
      last_name: 'Last Name',
      title: 'Title',
      email: 'Email',
      company: 'Company',
      delete_share_user: 'Delete Share User',
      delete_model_priviledge: 'Delete Model Priviledge',
      view: 'View Current Shares',
      share_with: 'Share with',
      solution: 'Solution',
      existing_customer: 'Existing Customer',
      new_customer: 'New Customer',
    },
    add_benefit: {
      required: 'Required',
      name: 'Name',
      Impact: 'Impact',
      IsAnnualBenefits: 'Is this an annual benefit?',
      Solutions: 'Solutions',
      WhichValueCategory: 'Which value category does this benefit fall under?',
      driver: 'Driver Factor',
      DriverFactor: 'Driver Factor',
      CreateDriverFactor: 'Create Driver Factor',
      Answer: 'Answer',
      improvement: 'Improvement',
      improvement_required: 'Required',
      units: 'Units',
      CurrentEffort: 'Current Effort',
      financial: 'Financial Factor',
      FinancialFactorType: 'Financial Factor Type',
      CreateFinancialFactor: 'Create Financial Factor',
      CreateFinancialFactorAnswer: 'Answer',
      additional: 'Additional Information',
      description: 'Description',
      features: 'Features/Capabilities',
      Uniqueness: 'Uniqueness',
      impacts: 'Impacts Which Business Areas',
      impacts_desc: 'Impact Description',
      area: 'Area',
      NoAreasSelected: 'No Areas Selected',
      choose: 'Choose',
      save: 'Save',
      cancel: 'Cancel',
    },
    account: {
      account: 'Account',
      addr1: 'Address 1',
      addr2: 'Address 2',
      city: 'City',
      company_name: 'Company Name',
      country: 'Country',
      zip: 'Zip Code',
      edit: 'Edit',
      editing: 'Editing Company Info',
      employees: 'Employees',
      gross: 'Gross Profits',
      id: 'Account ID',
      optional: 'Optional Information',
      phone: 'Phone #',
      required: 'Required',
      revenue: 'Revenue',
      save: 'Save',
      state: 'State',
      ticker: 'Ticker',
      website: 'Website URL',
      industry_sector: 'Industry Sector',
      industry: 'Industry',
      key_figures: 'Key Figures',
      name: 'Name',
    },
    models: {
      choose_category_type: 'Choose Category Type:',
      save: 'Save',
    },
    situations: {
      notAvailable: 'No Situations are available.',
      view_less: 'View less',
      view_more: 'View more',
    },
    advanced: {
      CustomerType: 'Customer Type',
      ChooseOne: 'Choose One',
      BaselineType: 'Baseline Type',
      CostofCapital: 'Cost of Capital (%)',
      Term: 'Term',
      DeploymentTime: 'Deployment Type',
      GrossProfitMargin: 'Gross Profit Margin (%)',
      opportunity_id: 'Opportunity ID',
      currency_format: 'Number Format',
      currency_type_id: 'Currency Type',
      conversion_rate: 'Conversion Rate',
      roi_range: 'Roi Range',
    },
    benefits: {
      change_benefits_status: 'Change Benefits Status',
      formula: 'Formula',
      current_effort: 'Current Effort',
      with: 'With',
      original_name: 'Original Name',
      total_benefit: 'Total Benefit',
      description: 'Description',
      features: 'Features',
      case_studies: 'Case Studies',
      note: 'Note',
      add_note: 'Add Note',
      save_note: 'Save Note',
      NoBenefitDetailFound: 'No Benefit Details Found',
      GrowthAfter1stYear: 'Growth after 1st Year',
      delete_note: 'Delete Note',
      are_you_sure_you_want_to_delete_this_note: 'Are you sure you want to delete this note?',
      applyToAll: 'Apply to all',
      sub_types: 'SubTypes',
      edit_note: 'Edit Note',
      growth: 'Growth / Decline',
      search: 'Search',
      show_all: 'Show All',
      show_active: 'Show Active Benefits',
      active: 'Active',
      na: 'N/A',
      not_priority: 'Not a Priority',
      soft: 'Considered Soft',
      clear: 'Clear Filter',
      setbulk: 'Set bulk status',
      set_na: 'Set N/A',
      set_active: 'Set Active',
      set_soft: 'Set Soft',
      expand: 'Expand All',
      collapse: 'Collapse All',
      year: 'Year',
      year1: 'Year 1',
      year2: 'Year 2',
      year3: 'Year 3',
      year4: 'Year 4',
      year5: 'Year 5',
      year6: 'Year 6',
      year7: 'Year 7',
      total: 'Total',
      phasing: 'Phasing',
      status: 'Status',
      benefits: 'Benefits',
      impact: 'Impact Type',
      improvement: 'Improvement',
      impact_over_time: 'Your impact over time',
      current_state: 'Current state',
      apply: 'Apply',
      apply_metrics: 'Apply to all metrics',
      apply_metrics_category: 'Apply to all benefits in category',
      apply_metrics_model: 'Apply to all benefits in model',
      choose: 'Choose',
      no_benefits_found: 'No benefits found',
      defined_sequence: 'Defined Sequence',
      total_benefits: 'Total Benefits',
      delete: 'Are you sure you want to delete this benefit?',
      notification_status: 'Benefit status updated Successfully',
      total_benefits_sequence: 'Total Benefits Sequence',
      phasing_status: 'Phasing updated Successfully',
      success_delete: 'Successfully deleted your benefit',
      value_category: 'Value Category',
      functional_objectives: 'Functional Objectives',
      pain_points: 'Pain Points',
      capabilities: 'Capabilities',
      operational_objectives: 'Operational Objectives',
      something_went_wrong: 'Something went wrong: ',
      benefits_text:
        'The benefit calculations are aligned to the Value Messaging framework and this step lists the benefits at two levels: Functional Outcomes and Operational Outcomes. Use the toggle buttons to the right to enable and disable Functional Outcomes. Use the + buttons to expand Functional Outcomes and the Status buttons to disable specific Operational Outcomes (Benefits). Click on an Operational Outcome name to get more information.',
    },
    benefits_dropdown: {
      considered_soft: 'Considered Softtt',
      defined_sequence: 'Defined Sequence',
    },

    costs: {
      Type: 'Type',
      Accrues: 'Accrues',
      UnitPrice: 'Unit Price',
      qty: 'QTY',
      GrandTotal: 'Grand Total',
      DISC: 'DISC',
      Subtotal: 'Subtotal',
      NoCostsFound: 'No Costs Found',
      rename: 'Rename',
      cost_text: 'Use the + button to add the Subscription, Implementation, Training or any other associated costs. Note that the Unit Price is Annual.',
    },
    deliverable: {
      final_customer_deliverable: 'Final Customer Deliverable',
      file: 'File',
      filename: 'FileName',
      description: 'Description',
      upload: 'Upload',
      no_deliverables_available: 'No Deliverables available',
    },
    add_cost: {
      add_costs: 'Add Costs',
      cost_type: 'Cost Type',
      choose_one: 'Choose One',
      unit_price: 'Unit Price',
      skip: "Skip if you're using Variable Costs",
      accrual_type: 'Accrual Type',
      qty: 'Quantity',
      discount: 'Discount %',
      year1: 'Year 1 Cost',
      year2: 'Year 2 Cost',
      year3: 'Year 3 Cost',
      year4: 'Year 4 Cost',
      year5: 'Year 5 Cost',
      year6: 'Year 6 Cost',
      year7: 'Year 7 Cost',
      year8: 'Year 8 Cost',
      year9: 'Year 9 Cost',
      cost_category: 'Cost Category',
      required: 'Required',
      cancel: 'Cancel',
      save: 'Save',
    },
    scalers: {
      primary_scalers: 'Primary Scalers',
      all_scalers: 'All Scalers',
    },
    assumptions: {
      scroll_to_drivers: 'Scroll to Drivers',
      scroll_to_financials: 'Scroll to Financials',
      scroll_to_scratchpads: 'Scroll to Scratchpad',
      click_to_view_benefits: 'Click to view included benefits',
      search: 'Search',
      keyassumptions: 'Key Assumptions',
      assumptions: 'Assumptions',
      edit_all_values: 'Edit all values',
      cancel_changes: 'Cancel changes',
      save_changes: 'Save changes',
      drivers: 'Drivers',
      financials: 'Financials',
      scratchpads: 'Scratchpads',
      collapse: 'Collapse all',
      expand: 'Expand all',
      DriverFactors: 'Driver Factors',
      DriverFactor: 'Driver Factor',
      DriverValue: 'Driver Value',
      DriverSourceType: 'Driver Source Type',
      DriverEstimate: 'Driver Estimate',
      DriverOverride: 'Driver Override',
      NoDriversFound: 'No drivers found',
      no_key_assumptions_found: 'No Key Assumptions Found',
      financial: 'Financial Factors',
      FinancialFactor: 'Financial Factor',
      FinancialValue: 'Financial Value',
      FinancialSourceType: 'Financial Source Type',
      NoFinancialsFound: 'No financial factors found',
      Includedbenefits: 'Included in the following benefits',
      FinancialEstimate: 'Financial Estimate',
      FinancialOverride: 'Financial Override',
      scratchpad: 'Scratchpad Units',
      records_drivers: 'records',
      records_financial: 'records',
      records_scratch: 'records',
      ScratchpadUnits: 'Scratchpad Units',
      value: 'Value',
      NoScratchpadUnitsFound: 'No scratchpad units found',
      assumptions_text: ' Use the slider or input the numbers in the text box to make any changes to the key data points used in the calculations',
    },
    initiatives: {
      ImpactArea: 'Impact Area',
      name: 'Name',
      NoInitiavesFound: 'No initiatives found',
      DeleteImpact: 'Delete impact',
      RemoveImpactWarning: 'Remove impact',
      AddInitiatives: 'Add Initiatives',
      Selectimpact: 'Select Impact',
      Initiativeisrequired: 'Initiative is required',
      UpdateInitiatives: 'Update Initiative',
    },

    case_studies: {
      name: 'Name',
      noCaseStudies: 'No case studies found',
    },
    collateral: {
      collateral: 'Colleteral',
      format: 'Format',
      name: 'Name',
      url: 'URL',
      noCollateralsFound: 'No collateral found',
      ChooseOne: 'Choose One',
      description: 'Description',
      isRequired: 'Is Required?',
      deleteCollateral: 'Delete Collateral',
      deleteConfirmation: 'Are you sure you want to delete this?',
    },
    menu: {
      account: 'Account',
      add_benefit: 'Add Benefit',
      advanced: 'Advanced',
      all_assumptions: 'All Assumptions',
      assets: 'Assets',
      benefits: 'Benefits',
      case_studies: 'Case Studies',
      cashflow: 'Cashflow',
      collateral: 'Collateral',
      costs: 'Costs',
      view_costs: 'View Costs',
      deployment_schedule: 'Deployment Schedule',
      discovery: 'Discovery',
      discovery_questions: 'Discovery Questions',
      initiatives: 'Initiatives',
      key_assumptions: 'Key Assumptions',
      key_figures_and_roi: 'Key Figures & ROI',
      logs: 'Logs',
      mission_statement: 'Mission Statement',
      models: 'Models',
      notes: 'Notes',
      problem: 'Problem',
      questions: 'Questions',
      roi: 'ROI',
      roi_full: 'Key Figures & ROI',
      scalers: 'Scalers',
      scenario: 'Scenario',
      scorecard: 'Scorecard',
      share: 'Share',
      situations: 'Situations',
      solution: 'Solution',
      supporting_materials: 'Supporting Materials',
      tco: 'TCO',
      this_value_prop_is_locked: 'This Value Prop is Locked',
      view_benefits: 'View Benefits',
      edit_and_support: 'Edit & Support Your Value',
      charts_tables: 'Charts, Tables, and Info',
      switch_full_width: 'Switch to New, Full Width Dashboard',
      switch_old_width: 'Switch to Old, 2 Column Dashboard',
      full_width: 'Full Width',
      old_width: 'Old Dashboard',
    },
    sidebar_menu: {
      translate_menus: 'Translate Menus',
      sidebar_account: 'Account',
      sidebar_add_benefit: 'Add Benefit',
      sidebar_advanced: 'Advanced',
      sidebar_all_assumptions: 'All Assumptions',
      sidebar_assets: 'Assets',
      sidebar_benefits: 'Benefits',
      sidebar_case_studies: 'Case Studies',
      sidebar_cashflow: 'Cashflow',
      sidebar_collateral: 'Collateral',
      sidebar_costs: 'Costs',
      view_costs: 'View Costs',
      deployment_schedule: 'Deployment Schedule',
      sidebar_discovery: 'Discovery',
      discovery_questions: 'Discovery Questions',
      sidebar_initiatives: 'Initiatives',
      sidebar_key_assumptions: 'Key Assumptions',
      key_figures_and_roi: 'Key Figures & ROI',
      sidebar_logs: 'Logs',
      sidebar_mission_statement: 'Mission Statement',
      sidebar_models: 'Models',
      sidebar_notes: 'Notes',
      problem: 'Problem',
      questions: 'Questions',
      sidebar_roi: 'ROI',
      roi_full: 'Key Figures & ROI',
      sidebar_scalers: 'Scalers',
      sidebar_scenario: 'Scenario',
      sidebar_scorecard: 'Scorecard',
      sidebar_share: 'Share',
      sidebar_situations: 'Situations',
      solution: 'Solution',
      sidebar_supporting_materials: 'Supporting Materials',
      tco: 'TCO',
      this_value_prop_is_locked: 'This Value Prop is Locked',
      sidebar_view_benefits: 'View Benefits',
      edit_and_support: 'Edit & Support Your Value',
      charts_tables: 'Charts, Tables, and Info',
      switch_full_width: 'Switch to New, Full Width Dashboard',
      switch_old_width: 'Switch to Old, 2 Column Dashboard',
      full_width: 'Full Width',
      old_width: 'Old Dashboard',
    },
    tco: {
      current_state: 'Current State',
      future_state: 'Future State',
      total_cost_ownership: 'Total Cost of Ownership',
      year: 'Year',
      total: 'Total',
      total_current_state: 'Total Current State',
      total_future_state: 'Total Future State',
      tco_summary: 'TCO Summary',
    },
    tour: {
      tour: 'Tour',
      step1: {
        title: 'Welcome',
        text: "Let's take a quick walk through of the new changes of Dashboard V2",
      },
      step2: {
        title: 'Switching Dashboards',
        text: 'Here, we can switch between the Old Dashboard and the New Dashboard',
      },
      step3: {
        title: 'Expanding / Collapsing Panels',
        text: 'We can expand and collapse each of these panels to get more screen realisatate',
      },
      step4: {
        title: 'Breadcrumb',
        text: "The title of the panel is also a breadcrumb, so we know which menu item we're on",
      },
      step5: {
        title: 'Menu Bar',
        text: 'All of the tabs from the old dashboard have been consolidated into a new menu bar. Mouse over a menu with a down arrow, or simply click the menu item to go to that page',
      },
      step6: {
        title: 'Finished',
        text: 'Well that is about it, thank you for taking the tour!',
      },
    },
    no_vp: {
      content1: "Let's create a Value Prop.",
      content2: "Start with the basics below and then we'll get into the details.",
    },
    download: 'Download',
    discovery_questions: 'Discovery Questions',
    support: 'Support',
    view_all_details: 'View All Details',
    next: 'Next',
    back: 'Back',
    note: 'Notes',
    login: 'Login',
    password: 'Password',
    panel_title: 'Please login to view this Value Prop',
    add_group: 'Add this Value Prop to a Group',
    select_option: 'Please select an option',
    cashfloww: 'Cashflow',
    add_quotes_costs: 'Add Quotes / Costs',
    translate_benefits: 'Translate Benefits',
    additional_details: 'Additional Details',
    impact: 'Impact',
    accrual: 'Accrual',
    is_this_benefit_considered_soft: 'Is this benefit considered soft?',
    expense_type: 'Expense Type',
    what_type_of_benefit_is_this: 'What type of benefit is this?',
    should_this_benefit_be_quantified: 'Should this benefit be quantified?',
    model: 'Model',
    experience_an_improvement_of: 'experience an improvement of',
    with_this_benefit: 'with this benefit.',
    subtypes: 'Subtypes',
    my_comments: 'My Comments',
    tco_name: 'TCO Name:',
    included_benefits: 'Included Benefits',
    action: 'Actions',
    filter: 'Filter',
    sort: 'Sort',
    benefits_panel_content:
      'Use the toggle buttons to the right to enable and disable Value Categories. Use the + buttons to expand Value Categories and the Status buttons to disable specific Benefits. Click on a Benefit Name to get more information.',
    edit_cost: 'Edit Cost',
    compare: 'Compare',
    improvements: 'Improvements',
    save: 'Save',
    add_a_company: 'Add A Company',
    name: 'Name',
    industry_sector: 'Industry Sector',
    select_industry_sector: 'Select Industry Sector',
    industry: 'Industry',
    select_industry: 'Select Industry',
    country: 'Country',
    revenue_in_millions: 'Revenue in Millions',
    employees: 'Employees',
    address: 'Address',
    city: 'City',
    state: 'State',
    select_state: 'Select State',
    postal_code: 'Postal Code',
    opportunity_id: 'Opportunity ID',
    terms_and_conditions: 'Terms and Conditions',
    decisionlink: 'Xfactor.io',
    dealdesk_heading: 'Dealdesk Heading',
    data: 'Data',
    explain_changes: 'Explain changes',
    notification_info: 'Please close all panels before re-ordering value categories',
    notification_success: 'Benefit order updated successfully',
    expand_all: 'Expand All',
    set_bulk_status: 'Set Bulk Status',
    collapse_all: 'Collapse All',
    notification_benefit_success: 'Benefits Updated Successfully',
    group: 'Group',
  },
  'models_contextual_help': {
    help_overview: 'Basic facts and figures of the model',
    help_projects: 'Projects',
    help_benefits: 'The metrics used to quantify the improvement to the customer after successfully deploying your solution',
    help_assumptions: 'Cost Categories are a grouping of costs in a category you have defined',
    help_costs: 'The investment required to achieve the benefits in scope',
    help_situations: 'Forces that change your value. Items may include/exclude or change benefits, costs, collateral, or case study / supporting materials.',
    help_competitors: 'Companies that have similar solutions to yours that you need to differentiate from.',
    help_case_studies: 'Capture empirical improvements, customer sentiments, details, and media about customer success.',
    help_collateral: 'Standard materials used to help defend your value. Usually created by marketing.',
    help_thresholds: 'Tolerance measurements to help users understand if their value conversion could be challenged.',
  },
  'table': {
    first: 'First',
    last: 'Last',
    next: 'Next',
    previous: 'Previous',
  },
  'button': {
    save: 'Save',
    next: 'Next',
    delete: 'Delete',
    back: 'Back',
    add: 'Add',
    edit: 'Edit',
    filter: 'Filter',
    cancel: 'Cancel',
    Yes: 'Yes',
    No: 'No',
    Update: 'Update',
    search: 'Search',
    addNew: 'Add New',
    view: 'View',
  },
  'vpgroup': {
    business_case_for: 'Business case for ',
    compare_all: 'Compare all',
    details: 'Details',
    totals: 'Totals',
    totalBenefits: 'Total Benefits',
    totalCosts: 'Total Costs',
    ROI: 'ROI',
    NPV: 'NPV',
    paybackPeriod: 'Payback Period (Months)',
    tco: 'TCO Improvements',
    mi: '3 Month Cost of Delay',
    projects: 'Projects',
    actions: 'Actions',
    clone: 'Clone',
    share: 'Share',
    assets: 'Assets',
    benefits_per_transaction: 'Benefit per Transaction',
    status: 'Status',
    vpgroup_scalers: 'Scalers',
    vp_assets: 'Assets',
    add_projects: 'Add project',
    pick_your_projects: 'Pick your projects',
    no_projects_found: 'No projects found',
    share_your_master_value_prop: 'Share Your Master Business Case / Value Assessment',
    clonevpgroup: 'Clone this Value Prop Group',
    add_another: 'Add Another',
  },
  'addvp': {
    back: 'Back',
    account: {
      desc: "Who are you building this value prop for? Search for your company below. If you don't find what your looking for, click the Add New button to create a new company.",
      pick_your_account: 'Pick Your Company',
    },
    are_you_sure: 'Are you sure?',
    assumptions: {
      key_assumptions: 'Key Assumptions',
      next: 'Next',
    },
    click_to_hide_help: 'Click to hide help',
    click_to_show_help: 'Click to show help',
    company: {
      pick_your_company: 'Pick Your Company',
      additional_info: 'Additional Information',
      required: 'Required',
    },
    finish: {
      done: "You're done! We have the basics of what we need to create your Value Prop.",
      finishreview: 'Finish and Review',
      next: 'Next Steps',
    },
    hide_progress_panel: 'Hide Progress Panel',
    opportunity: {
      next: 'Next',
      opportunityid: 'Opportunity ID',
      optional: 'Optional - enter in an account id or opportunity id here.',
    },
    progress: 'Progress',
    refine: {
      done: "You're done! We have the basics of what we need to create your Value Prop.",
      finish: 'Finish',
      next_steps: 'Next Steps',
      graduated_deployment: 'Graduated Deployment',
      benefits: 'Benefits',
      costs: 'Costs',
      link1: 'Click Continue to Business Case to view your Business Case.',
      link2: 'Click Continue to Refine to refine your value prop.',
      link3: 'Click Add Use Case to add another Use Case.',
      btn1: 'Continue to Business Case',
      to_view_your_value_prop: 'to view your value prop',
      btn2: 'Continue to Refine',
      to_refine_your_value_prop: 'to refine your value prop',
      btn3: 'Add Use Case',
      to_add_another_value_prop: 'to add another value prop',
    },
    models: {
      pick_your_models: 'Pick your model(s)',
      based_on_your_project_selection_we_suggest_the_following_products: 'Based on your project selection, we suggest the following products:',
      choose_category_type: 'Choose Category Type:',
      no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_above:
        'No Models for this category, please try a different one by selecting from the dropdown above.',
    },
    scalers: {
      scale: 'Scale your use case',
      calculate: 'Calculate',
      next: 'Next',
      primary_scalers: 'Primary Scalers',
      all_scalers: 'All Scalers',
      no_scalers_found: 'No scalers found',
    },
    show_progress_panel: 'Show Progress Panel',
    situations: {
      pick: 'Pick Your Situations',
      notAvailable: 'No situations available',
    },
    start_over: 'Start Over',
    take_a_tour: 'Take a tour',
    usecase: {
      addvp: 'Add Use Case',
      are_you_sure: 'Are you sure?',
      create: 'Create Business Case',
      delete: 'Delete',
      desc: "Let's create a Use Case! You may optionally provide a name below for your use case. If you do not, a name will be automatically generated for you. When your done, click the Create Business Case Button.",
      edit: 'Editing Value Prop',
      name: 'Use Case',
      optional: '(Optional)',
      update: 'Update',
    },
    waiting_for_data: 'Waiting for data...',
  },
  'add_company': {
    add_a_company: 'Add a Company',
    address: 'Address',
    city: 'City',
    country: 'Country',
    employees: 'Employees',
    hello: 'Hello, ',
    industry: 'Industry',
    industry_sector: 'Industry Sector',
    is_required: 'Is Required',
    name: 'Nameee',
    postal_code: 'Postal Code',
    revenue_millions: 'Revenue (Millions)',
    save: 'Save',
    select_country: 'Select Country',
    select_industry: 'Select Industry',
    select_industry_sector: 'Select Industry Sector',
    select_state: 'Select State',
    state: 'State',
    fancy: 'Fancy',
    content: 'Content',
  },
  'deployment_schedule': {
    deployment_cumulative: 'Deployment (Cumulative)',
    deployment_net: 'Deployment (Net)',
    graduated_deployment: 'Graduated Deployment',
    will_this_have_a_graduated_deployment: 'Will this have a graduated deployment?',
  },
  'keyFigures': {
    title: 'Key Figures',
    mi: '3 Month Cost of Delay',
    NPV: 'NPV',
    ROI: 'ROI',
    paybackPeriod: 'Payback Period (Months)',
    tco: 'TCO Improvements',
    totalBenefits: 'Total Benefits',
    totalCosts: 'Total Costs',
  },
  'key_figures': {
    total_benefits: 'Total Benefits',
  },
  'main_dashboard': {
    dash_board: 'Dashboard',
    SCBHeading: 'Recent Account Insights',
    scb_name: 'Name',
    scb_date: 'Date',
    scb_seemore: 'See More',
    valuepos_name: 'Name',
    valuepos_totalbenefits: 'Total Benefits',
    valuepos_seemore: 'See More',
    valuepos_noValuePositions: 'No Value Hypotheses Found',
    valueprop_name: 'Name',
    valueprop_totalbenefits: 'Total Benefits',
    valueprop_valuepropname: 'Business Case / Value Assessment Name',
    valueprop_models: 'Model(s)',
    valueprop_year1benfits: 'Year 1 Benefits',
    valueprop_totalBenefits: 'Total Benefits',
    valueprop_ROI: 'ROI',
    valueprop_paybackPeriod: 'Payback Period',
    valueprop_months: 'Months',
    valueprop_creatorName: 'Creator Name',
    valueprop_noValueProps: 'No Business Cases / Value Assessments Found',
    valueprop_notLicensedForValueProps: 'Please wait as your Business Cases / Value Assessments load.',
    valueprop_seeMore: 'See More',
    masterValuePropsHeading: 'Recent Master Business Cases / Value Assessments',
    masterValueProp: 'Master Value Prop',
    mvp_name: 'Name',
    mvp_totalBenefits: 'Total Benefits',
    mvp_noValueProps: 'No Business Cases / Value Assessments Found',
    mvp_notLicensedForValueProps: 'Please wait as your Business Cases / Value Assessments load.',
    mvp_seeMore: 'See More',
    vr_name: 'Name',
    vr_expected: 'Expected',
    vr_realized: 'Realized',
    vr_noValueRealizations: 'No Value Realizations Found',
    vr_notLicensedForValueRealizations: 'Please wait as your Value Realizations load.',
    vr_seeMore: 'See More',
    models_modelsHeading: 'Recent Value Models',
    models_name: 'Name',
    models_timeused: 'Times Used',
    models_seemore: 'See More',
    model_noValueModels: 'No Models Found',
    caseStudiesHeading: 'Recent Case Studies',
    case_name: 'Name',
    noCaseStudies: 'No Case Studies Found',
    noScbFound: 'No Data Found',
    notLicensedForValueInsights: 'Please wait as your Value Insights load.',
    notLicensedForValueModels: 'Please wait as your Models load.',
    valuepos_notLicensedForValuePositions: 'Please wait as your Value Hypotheses load.',
    valuePositionsHeading: 'Recent Value Hypotheses',
    valuePropsHeading: 'Recent Business Cases / Value Assessments',
    valueRealizationsHeading: 'Recent Value Realizations (Different from VR from Insight)',
    start: 'Start',
    dealdesk: 'Dealdesk',
    custom: 'Custom',
    guidedDiscovery: 'Guided Discovery',
    chooseOptionToContinue: 'Choose an option to continue',
    quickCreate: 'Quick Create',
  },
  'value_realization': {
    survey_tip: 'Measure the in time perception of value achieved by specific customers based on agreed value propositions',
    case_study_tip: 'Capture empirical improvements, customer sentiments, details and media about customer success',
    yourValueRealization: 'Your Value Realizations (Different from VR from Insight)',
    addNewValueRealization: 'Add New Value Realization',
    youCanMeasureValueForAnyLockedValueProposition: '(You can measure value for any locked Business Case / Value Assessment)',
    expected_to_date: 'Expected To Date',
    realized_to_date: 'Realized To Date',
    name: 'Name',
    company: 'Company',
    action: 'Action',
    delete_valueprop: 'Delete Business Case / Value Assessment?',
    are_you_sure_that_you_want_to_remove_this_locked_valueprop: 'Are you sure that you want to remove this locked Business Case / Value Assessment?',
    yes: 'Yes',
    no: 'No',
  },
  'value_position': {
    addValuePosition: 'Add Value Position',
    name: 'Name',
    company: 'Company',
    totalBenefits: 'Total Benefits',
    totalCosts: 'Total Costs',
    created: 'Created',
    creatorName: 'Creator Name',
    status: 'Status',
    yourValuePositions: 'Your Value Positions',
    vp_name: 'Value Prop Name',
    models: 'Model(s)',
    year1: 'Year 1 Benefits',
    total_benefits: 'Total Benefits',
    roi: 'ROI',
    payback: 'Payback Period',
    show: 'Show',
    entries: 'entries',
    my_value_positions: 'My Value Positions',
    shared_with_me: 'My Value Positions & Shared with Me',
    months: 'months',
    no_value_position_found: 'No Value Position Found',
  },
  'value_prop': {
    yourValueProps: 'Your Value Props',
    name: 'Name',
    company: 'Company',
    totalBenefits: 'Total Benefits',
    totalCosts: 'Total Costs',
    created: 'Created',
    creatorName: 'Creator',
    status: 'Status',
    no_value_props_found: 'No value props found',
    vp_name: 'Value Prop Name',
    models: 'Model(s)',
    year1: 'Year 1 Benefits',
    total_benefits: 'Total Benefits',
    roi: 'ROI',
    payback: 'Payback Period',
    my_value_positions: 'My Value Props',
    shared_with_me: 'My Value Props & Shared with Me',
    months: 'Months',
  },
  'payment': {
    payment_works: 'Payment works!!',
  },
  'add_benefits_model': {
    add_your_benefits: 'Add Your Benefits',
    aggressive: 'Aggressive',
    answer: 'Answer',
    benefit_phasing: 'Benefit Phasing',
    create_driver_factor: 'Create Driver Factor',
    create_financial_factor: 'Create Financial Factor',
    current_effort: 'Current Effort',
    capabilities: 'Capabilities',
    description: 'Description',
    does_your_benefit_scale: 'Does your benefit scale?',
    driver_factor: 'Driver Factor',
    expense_type: 'Expense Type',
    featurescapabilities: 'Features/Capabilities',
    financial_factor: 'Financial Factor',
    financial_factor_type: 'Financial Factor Type',
    functional_objectives: 'Functional Objectives?',
    impact: 'Impact',
    is_this_benefit_considered_soft: 'Is this benefit considered soft?',
    impacts_which_business_areas: 'Impacts Which Business Areas?',
    improvement_conservative: 'Improvement Conservative',
    improvement_factor: 'Improvement Factor',
    is_required: 'Is Required',
    is_this_an_annual_benefit: 'Is this an annual benefit?',
    mark_this_benefit_as_nonapplicable_by_default: 'Mark this benefit as Non-Applicable by Default?',
    name: 'Name',
    no_areas_selected: 'No areas selected',
    operational_objectives: 'Operational Objectives?',
    probable: 'Probable',
    project: 'Project',
    pain_points: 'Pain Points?',
    save: 'Save',
    situations: 'Situations',
    should_this_benefit_be_quantified: 'Should this benefit be quantified?',
    seed_growth_decline: 'Seed Growth / Decline ?',
    uniqueness: 'Uniqueness',
    which_value_category_does_this_benefit_fall_under: 'Which value category does this benefit fall under?',
    what_type_of_benefit_is_this: 'What type of benefit is this?',
    would_you_like_to_use_a_different_name_for_tco: 'Would you like to use a different name for TCO?',
    year_1: 'Year 1: ',
    year_2: 'Year 2:',
    year_3: 'Year 3:',
    year_4: 'Year 4:',
    year_5: 'Year 5:',
    you_are_in_view_only_mode: 'You are in view only mode. Please contact your Models Admin if you need to make changes.',
    app_ng_error: 'To what Benefit Group does this benefit belong?',
    how_does: 'How does this benefit impact your customer?',
    differentiated: 'Differentiated',
    default_na: 'Is this benefit N/A by default?',
    driver: 'Driver',
    add_new: 'Add New',
    improvement: 'Improvement',
    conservative: 'Conservative',
    financial: 'Financial',
    optional: 'Optional',
    tco_name: 'TCO Name',
    value_statement: 'Value Statement',
    what_type_benefit: 'What benefit type is this?',
    debug: 'Debug',
  },
  'add_casestudy': {
    add_case_study: 'Add Caseeee Study',
    add_company: 'Add Company',
    add_new: 'Add New',
    address: 'Address',
    cancel: 'Cancel',
    city: 'City',
    country: 'Country',
    description: 'Description',
    employees: 'Employees',
    enter_comma_separated_keywords_for_tags: 'Enter comma separated keywords for tags',
    heres_what_we_know_about_the_account_you_have_selected: "Here's what we know about the account you have selected:",
    models: 'Models',
    name: 'Name',
    name_label: 'Name Label',
    no_companies_found: 'No Companies Found',
    revenue: 'Revenue',
    save: 'Save',
    sector: 'Sector',
    step_1_redact: 'Step #1: Redact',
    step_2_pick_your_account: 'Step #2: Pick your account',
    tags: 'Tags',
    ticker: 'Ticker',
    website: 'Website',
  },
  'add_project_modal': {
    add_child: 'Add Child',
    add_new: 'Add New',
    add_project: 'Add Project',
    add_to_existing_parent: 'Add to Existing Parent',
    add_to_list_by_default: 'Add to List by Default',
    cancel: 'Cancel',
    children: 'Children',
    description: 'Description',
    parent: 'Parent',
    project_name: 'Project name',
    save: 'Save',
  },
  'add_value_position': {
    OpportunityID: 'Opportunity ID',
    all_scalers: 'All Scalers',
    apply: 'Apply',
    based_on_your_project_selection_we_suggest_the_following_products: 'Based on your project selection, we suggest the following products:',
    choose_category_type: 'Choose Category Type:',
    calculate: 'Calculate',
    continue_to_configure: 'Continue to Configure',
    discover_opportunities: 'Discover Opportunities',
    finish_and_review: 'Finish and Review',
    going_back_a_few_steps_this_may_reset_subsequent_steps_and_start_from_the_current_step:
      'Going back a few steps? This may reset subsequent steps and start from the current step.',
    how_would_you_like_to_determine_the_solutions_for_this_value_prop: 'How would you like to determine the solutions for this Business Case / Value Assessment',
    how_does_your_selection_solve_the_customer_problem: 'How does your selection solve the customers problem?',
    models: 'Models',
    next: 'Next',
    no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_above:
      'No Models for this category, please try a different one by selecting from the dropdown above.',
    no_models_for_this_project: 'No Models for this project.',
    no_projects_found: 'No Projects Found',
    no_scalers_found: 'No Scalers Found.',
    of_1000_characters_left: 'Of 1000 charcters left',
    of_1200_characters_left: 'Of 1200 characters left',
    pick_your_models: 'Pick your models',
    pick_your_projects: 'Pick your projects',
    pick_your_situations: 'Pick your situations',
    primary_scalers: 'Primary Scalers',
    product_based: 'Product Based',
    project_based: 'Project Based',
    what_is_the_customer_problem_describe_in_detail: 'What is the customer problem? Describe in detail.',
    scale_your_value_position: 'Scale Your Value Hypothesis',
    scale_your_value_prop: 'Scale Your Business Case / Value Assessment',
    select_your_project_below: 'Select Your Project Below',
    situations: 'Situations',
  },
  'add_value_category_model': {
    add: 'Add',
    add_new: 'Add New',
    add_value_category: 'Add Value Category',
    cancel: 'Cancel',
    category_name: 'Category name',
    import: 'Import',
    save: 'Save',
    what_category_did_you_influence: 'what Category Did You Influence?',
  },
  'benefit_from_library_model': {
    add_value_category: 'Add Value Category',
    benefit_name: 'Benefit Name',
    description: 'Description',
    library_benefits: 'Library Benefits',
    no_library_benefits_found: 'No Library Benefits Found',
    import: 'Import',
  },
  'benefits_casestudy': {
    benefit: 'Benefit',
    description: 'Description',
    improvement: 'Improvement',
    name: 'Name',
    no_benefits_found: 'No Benefits Found',
    tie_to_benefits: 'Tie to benefits',
    type: 'Type',
    update: 'Update',
    update_benefit: 'Update Benefit',
  },
  'capabilities': {
    description: 'Description',
    name: 'Name',
    no_entries_found: 'No Entries Found',
  },
  'casestudy_detail': {
    benefits: 'Benefits',
    cancel: 'Cancel',
    change: 'Change',
    download_asset: 'Download Asset',
    download: 'Download',
    model: 'Model:',
    name: 'Name',
    save: 'Save',
    sections: 'Sections',
    tags: 'Tags:',
    testimonials: 'Testimonials',
  },
  'Competitors': {
    add_new: 'Add New',
    choose_one: 'Choose One',
    delete: 'Delete',
    description: 'Description',
    edit: 'Edit',
    is_required: 'Is Required',
    landmines: 'Landmines',
    name: 'Name',
    no_competitors_found: 'No Competitors Found',
    our_response: 'Our Response',
    save: 'Save',
    their_landmines: 'Their Landmines',
    they_ll_say: "They'll say",
  },
  'Cost': {
    add: 'Add',
    add_cost_category: 'Add Cost Category',
    add_new: 'Add New',
    are_you_sure_you_want_to_remove_this_solution_cost: 'Are you sure you want to remove this solution cost?',
    cost_type_costs: '(cost type) Costs',
    cancel: 'Cancel',
    category_name: 'Category Name',
    choose_one: 'Choose One',
    cost_answer_baseline: 'Cost Answer Baseline',
    cost_category_optional: 'Cost Category (Optional)',
    cost_type: 'Cost Type',
    costs: 'Costs',
    delete: 'Delete',
    delete_solution_cost: 'Delete Solution Cost?',
    driver_factor_optional: 'Driver Factor (Optional)',
    edit: 'Edit',
    expense_type: 'Expense Type',
    how_does_this_accrue: 'How does this accrue?',
    include_this_cost_by_default_in_valueprops: 'Include this cost by default in Business Cases / Value Assessments?',
    is_required: 'Is Required',
    line_item: 'Line Item',
    no_model_costs_found: 'No Model Costs Found',
    scale_factor: 'Scale Factor',
    situations: 'Situations',
    unit_price: 'Unit Price',
  },
  'create_map': {
    add_new: 'Add New',
    average_deployment_time: 'Average Deployment Time',
    average_sales_price: 'Average Sales Price',
    average_sales_cycle: 'Average Sales Cycle',
    back: 'Back',
    benefits: 'Benefits',
    case_studies: 'Case Studies',
    choose_from_library: 'Choose from Library',
    collateral: 'Collateral',
    costs: 'Costs',
    description: 'Description',
    driver_factor: 'Driver Factor',
    enter_name: 'Enter Name',
    model_category: 'Model Category',
    model_name: 'Model Name',
    name: 'Name',
    no_benefits_found: 'No Benefits Found',
    quantities: 'Quantities',
    save: 'Save',
    situations: 'Situations',
    typical_customers: 'Typical Customers',
    value_category: 'Value Category',
    your_model: 'Your Model',
  },
  'Dashboard': {
    SCBHeading: 'Recent Account Insights',
    caseStudiesHeading: 'Recent Case Studies',
    masterValuePropsHeading: 'Recent Master Business Cases / Value Assessments',
    modelsHeading: 'Recent Value Models',
    noCaseStudies: 'No Case Studies Found',
    noScbFound: 'No Data Found',
    noValueModels: 'No Models Found',
    noValuePositions: 'No Value Hypotheses Found',
    noValueProps: 'No Business Cases / Value Assessments Found',
    noValueRealizations: 'No Value Realizations Found',
    notLicensedForValueInsights: 'Please wait as your Value Insights load.',
    notLicensedForValueModels: 'Please wait as your Models load.',
    notLicensedForValuePositions: 'Please wait as your Value Hypotheses load.',
    notLicensedForValueProps: 'Please wait as your Business Cases / Value Assessments load.',
    notLicensedForValueRealizations: 'Please wait as your Value Realizations load.',
    valuePositionsHeading: 'Recent Value Hypotheses',
    valuePropsHeading: 'Recent Business Cases / Value Assessments',
    valueRealizationsHeading: 'Recent Value Realizations (Different from VR from Insight)',
  },
  'FAQ': {
    no_faqs_found: "No Faq's Found",
  },
  'General': {
    Calculate: 'Calculate',
    Cancel: 'Cancel',
    Choose: 'Choose',
    ChooseOne: 'Choose One',
    Clear: 'Clear',
    Comment: 'Comment',
    Create: 'Create',
    Delete: 'Delete',
    Edit: 'Edit',
    Feedback: 'Feedback',
    No: 'No',
    ROI: 'ROI',
    Rate: 'Rate',
    Term: 'Term',
    Total: 'Total',
    Update: 'Update',
    View: 'View',
    Year: 'Year',
    Yes: 'Yes',
    actions: 'Actions',
    add: 'Add',
    addNew: 'Add New',
    back: 'Back',
    chooseOptionToContinue: 'Choose Option to Continue',
    clone: 'Clone',
    custom: 'Custom',
    datatable: {
      first: 'First',
      last: 'Last',
      next: 'Next',
      previous: 'Previous',
      rows: 'rows',
      show: 'Show',
      showing: 'Showing',
    },
    date: 'Date',
    dealdesk: 'Deal Desk',
    description_label: 'Description',
    download: 'Download',
    email_address: 'Email Address',
    employees: 'Employees',
    expected: 'Expected',
    guidedDiscovery: 'Guided Discovery',
    isRequired: 'Required',
    logout: 'Log Out',
    masterValueProp: 'Master Business Case / Value Assessment',
    models: 'Model(s)',
    months: 'Months',
    name_label: 'Name',
    no_value_categories_found: 'No Value Categories Found',
    next: 'Next',
    password: 'Password',
    paybackPeriod: 'Payback Period',
    quickCreate: 'Quick Create',
    realized: 'Realized',
    revenue: 'Revenue',
    save: 'Save',
    search: 'Search',
    searchResults: 'Search Results',
    seeMore: 'See More',
    select: 'Select',
    start: 'Start',
    test_item_query: 'Is this a test item?',
    timesUsed: 'Times Used',
    totalBenefits: 'Total Benefits',
    typical: '',
    valuePropName: 'Business Case / Value Assessment Name',
    year1Benefits: 'Year 1 Benefits',
  },
  'library_solutions': {
    back: 'Back',
    import: 'Import',
    library_solutions: 'Library Solutions',
    model_name: 'Model Name',
    no_library_models_found: 'No Library Models Found',
    typical_customer_revenue: 'typical_customer_revenue',
  },
  'Model': {
    listing: {
      Category: 'Category',
      Group: 'Group',
      Models: 'Models',
      Specific: 'Specific',
      assignModelAdmin: 'Assign Model Admin',
      addNew: 'Add New',
      categories: 'Categories',
      categoryName: 'Category Name',
      modelAdministrators: 'Model Administrators',
      modelCategories: 'Model Categories',
      modelName: 'Model Name',
      modelsCategoryOverview: 'Models Category Overview',
      noModelCategoriesFound: 'No Model Categories Found',
      releaseToAllUsers: 'Release to all users',
      releasedToPartners: 'Released to partners',
      releasedToUsers: 'Released to Users',
      removeFromPartners: 'Remove from partners',
      removeReleaseFromPartnersConfirmation: 'Are you sure that you want to remove this release from your partners?',
      removeThisRelease: 'Remove this release?',
      removecategoryConfirmation: 'Are you sure that you want to remove this Model Category?',
      top5Models: 'Top 5 Models',
    },
  },
  'Overview': {
    average_sales_price: 'Average Sales Price',
    averages_sales_cycle: 'Averages Sales Cycle',
    cancel: 'Cancel',
    deployment: 'Deployment',
    description: 'Description',
    model_category: 'Model Category',
    model_name: 'Model Name',
    name: 'Name',
    no_model_found: 'No Model Found',
    typical_customers: 'Typical Customers',
    update: 'Update',
    edit_model: 'Edit Model',
    editing: 'Editing',
  },
  'prof': {
    support: {
      answer: 'Answer',
      errorChooseSection: 'Please choose a section',
      errorEmailValue: 'Please enter the Email Value',
      errorIssueOrFeedback: 'Please enter your issue / feedback',
      faq: 'FAQ',
      issuesOrFeedback: 'Issues/ Feedback',
      question: 'Question',
      screenshots: 'Screenshots',
      section: 'Section',
      submitCommentsAndConcerns: 'Submit Comments and Concerns',
      support: 'Support',
      changelog: 'Change Log',
    },
  },
  'projects': {
    add_new: 'Add New',
    add_to_list: 'Add to List',
    add_to_list_by_default: 'Add to List by Default',
    delete: 'Delete',
    name: 'Name',
    no_projects_found: 'No Projects Found',
    remove_from_list: 'Remove from list',
  },
  'model_quote': {
    add_testimonial: 'Add Testimonial',
    are_you_sure_that_you_want_to_remove_this_testimonial: 'Are you sure that you want to remove this testimonial?',
    delete: 'Delete',
    delete_case_study_testimonial: 'Delete Case Study Testimonial?',
    first_name: 'First Name',
    is_required: 'Is Required',
    last_name: 'Last Name',
    no_testimonials_found: 'No Testimonials Found',
    testimonial: 'Testimonial',
    title: 'Title',
    update: 'Update',
  },
  'release_to_partners': {
    no_partners_found: 'No Partners Found',
    partners: 'Partners',
    release_to_partners: 'Release To partners',
  },
  'Research': {
    city: 'City',
    companyName: 'Company Name',
    company_description: 'Company Description',
    country: 'Country',
    dateOfDownload: 'Date of Download',
    employees: 'Employees',
    noCompaniesFound: 'No Companies Founds',
    popularSearches: 'Popular Searches',
    recentSearches: 'Recent Searches',
    recentlyDownloaded: 'Recently Downloaded',
    recentlyViewed: 'Recently Viewed',
    revenue: 'Revenue',
    searchByName: 'Search By Name',
    searchCompany: 'Search Company',
    searchForACompany: 'Search for a Company',
    search_for_a_company: 'Search for a Company',
    ticker: 'Ticker',
  },
  'results': {
    your_return_on_investment: 'ROI',
    your_total_cost_of_ownership: 'TCO',

    loading_results: 'Loading Results',
    business_case: 'Business Case',
    cost_vs_benefit: 'Cost vs. Benefit',
    year_return_on_investment: 'year return on investment (ROI)',
    year_total_cost_of_ownership: 'year Total Cost of Ownership (TCO)',
    year_net_present_value: 'Year Net Present value (NPV)',
    your_payback_period_formula: 'Your Payback period_formula',
    your_payback_status_is: 'Your Payback Status is',
    your_roi_status_is: 'Your ROI status is',
    your_npv_status_is: 'Your NPV status is',
    the_roi_formula: 'The ROI Formula',
    your_roi_formula: 'Your ROI Formula',
    your_npv_formula: ' Your NPV Formula',
    your_three_months_cost_of_delay_formula: ' Your 3 Months Cost of Delay Formula',
    potential_total_benefit: 'Potential Total Benefit',
    payback_period_in_months: 'Payback Period in Months',
    payback_period: 'Payback Period',
    return_on_investment: 'Return On Investment (ROI)',
    ratio_of_cumulative_net_benefit_investment_over_the_product_period:
      'Ratio of cumulative net benefits to cumulative investment over the product period. An ROI of 200% means that every dollar spent on a project nets two dollars in return. You want to align your ROI to be compelling enough for action by the buyer but also not too large to be unbelievable.',
    net_present_value: 'Net Present Value (NPV)',
    total_benefits: 'Total Benefits',
    total_costs: 'Total Costs',
    total_cost_of_ownership: 'Total Cost of Ownership (TCO)',
    total_cost_of_ownership_estimate:
      'Total cost of ownership (an estimate of all the direct and indirect costs involved in acquiring and operating a product or system over its lifetime)',
    the_tco_formula: 'The TCO formula',
    your_tco_formula: 'Your TCO formula',
    the_payback_period_formula: ' The Payback Period Formula',
    the_npv_formula: ' The NPV Formula',
    total_current_costs: 'Total Current Costs',
    total_future_costs: ' Total Future Costs',
    three_month_cost_of_delay: '3 Month Cost of Delay',
    three_months_cost_of_delay: ' 3 Month Cost of Delay',
    the_three_months_cost_of_delay_formula: 'The 3 Months Cost of Delay Formula',
    the_length_of_time_required_for_an_investment_to_recover_its_initial_outlay_in_terms_of_profits:
      'The length of time required for an investment to recover its initial outlay in terms of profits or savings. If insulation costs $110 and saves $55 a year, its payback period would be two years ',
    is_the_difference_between_the_present_value_of_cash:
      'Is the difference between the present value of cash inflows and the present value of cash outflows over a period of time. NPV is used in capital budgeting and investment planning to analyze the profitability of a projected investment or project',
    what_is_the_cost_of_the_customer_delaying_the_decision_to_buy:
      'What is the cost of the customer delaying the decision to buy by 1 full quarter (3 months) due to lost opportunity of growth, productivity, cost removal or risk? ',
    results_text:
      'This step gives a summary of the results. Click on the percentage breakdown to the right of the donut to view the benefit calculation in detail. Note that you may need to scroll down depending on your screen resolution.',
  },
  'Scalers': {
    add: 'Add',
    cancel: 'Cancel',
    create: 'Create',
    create_account_scalers: 'Create Account Scalers',
    delete: 'Delete',
    reset: 'Reset',
    save: 'Save',
    scaler_description: 'Scaler Description',
    scaler_name: 'Scaler Name',
    typical_customers: 'Typical Customers',
    value: 'Value',
  },
  'Sections': {
    action: 'Action',
    add_media: 'Add Media',
    add_section: 'Add Section',
    add_section_type: 'Add Section Type',
    choose_section_type: 'Choose Section Type',
    delete: 'Delete',
    description: 'Description',
    download: 'Download',
    edit: 'Edit',
    edit_section: 'Edit Section',
    image: 'Image',
    is_required: 'Is Required',
    media: 'Media',
    media_description: 'Media Description',
    name: 'Name',
    no_media_found: 'No Media Found',
    no_sections_found: 'No Sections Found',
    preview: 'Preview',
    section: 'Section:',
    section_type: 'Section Type',
    section_type_description: 'Section type description',
    section_type_name: 'Section type name',
    size_in_bytes: 'Size (in Bytes)',
    type: 'Type',
    update_media: 'Update Media',
  },
  'SITUATION': {
    active_by_default: 'Active by default?',
    add_new: 'Add New',
    add_situation: 'Add Situation',
    add_to_list: 'Add to list',
    add_to_list_and_recommend: 'Add to list and Recommend',
    add_to_the_available_costs: 'Add to the available costs',
    added_to_list: 'Added to List',
    aggressive: 'Aggressive',
    all_costs: 'All Costs',
    available_for_guided_discovery: 'Available for Guided Discovery?',
    add_new_situation_type: 'Add New Situation Type',
    autoload_in_valueprop: 'Autoload in ValueProp',
    back: 'Back',
    change_name: 'Change (name)',
    change: 'Change',
    change_it: 'Change it',
    children_of_parent_name: 'Children of (parent name)',
    choose_one: 'Choose One',
    conservative: 'Conservative',
    cost_added_to_autoload: 'Cost Added to Autoload',
    cost_added_to_available_cost: 'Cost Added to available cost',
    description: 'Description',
    driver_baseline: 'Driver Baseline',
    driver: 'Driver:',
    edit: 'Edit',
    edit_situation_type: 'Edit Situation Type',
    element_type: 'Element Type',
    exclude_from_available_costs: ' Exclude from available costs',
    financial_baseline: 'Financial Baseline',
    financial_factor: 'Financial Factor:',
    from_available_costs: 'from available costs',
    how_does_this_accrue: 'How does this accrue?',
    in_value_prop: 'in Business Case / Value Assessment',
    included_by_default: 'Included by Default',
    name: 'Name',
    new_element: 'New Element',
    new_situation: 'New Situation',
    new_situation_type: 'New Situation Type',
    no_assets_yet: 'No Assets Yet!',
    no_benefits_yet: 'No Benefits Yet!',
    no_case_studies_yet: 'No Case Studies Yet!',
    no_costs_yet: 'No Costs Yet!',
    no_projects_yet: 'No Projects Yet!',
    no_situation_yet: 'No Situation Yet!',
    no_collaterals_yet: 'No Collaterals Yet!',
    non_applicable_by_default: 'Non-Applicable By Default',
    off: 'Off',
    on: 'On',
    projects: 'Projects',
    probable: 'Probable',
    recommended: 'Recommended',
    remove_from_list: 'Remove from list',
    remove_from_valueprop: 'Revove from Valueprop',
    removed_from_autoload: 'Removed from Autoload',
    removed_from_list: 'Removed from List',
    reset: 'Reset',
    reset_cost: 'Reset Cost',
    reset_asset: 'Reset Asset',
    reset_benefit: 'Reset Benefit',
    reset_case_study: 'Reset Case Study',
    reset_collateral: 'Reset Collateral',
    reset_all_costs: 'Reset All Costs',
    save: 'Save',
    scale_factor: 'Scale Factor',
    select_a_situation_type_from_the_dropdown_above_to_proceed: 'Select a situation type from the dropdown above to proceed.',
    select_your_option: 'Select Your Option',
    situation_type: 'Situation Type',
    situation_name: 'Situation Name',
    situation_type_elements: 'Situation Type Elements',
    status: 'Status',
    stack_order: 'Stack Order',
    to_change_situation_type_stack_order_drag_and_drop_situation_types_from_below_and_save:
      'To change Situation Type stack order, drag and drop situation types from below and save',
    turn_it_off: 'Turn it off',
    turn_it_on: 'Turn it on',
    unit_price: 'Unit Price',
    update: 'Update',
    value: 'Value:',
    values_changed: 'Values Changed',
    view_all: 'View All',
    what_do_you_want_to_happen_to_this_benefit_when_this_sitation_occurs: 'What do you want to happen to this benefit when this sitation occurs?',
    will_this_be_a_nested_situation_type: 'Will this be a nested situation type?',
  },
  'solution_detail': {
    back: 'Back',
    benefits: 'Benefits',
    costs: 'Costs',
    collateral: 'Collateral',
    comp: 'Competitors',
    case_studies: 'Case Studies',
    factors: 'Factors',
    functional_objectives: 'Functional Objectives',
    over: 'Overview',
    operational_objectives: 'Operational Objectives',
    projects: 'Projects',
    pain_points: 'Pain Points',
    capabilities: 'Capabilities',
    situations: 'Situations',
    threshold: 'Threshold',
    take_a_tour: 'Take a Tour',
    you_are_in_view_only_mode_please_contact_your_model_admin_if_you_need_to_make_changes:
      'You are in view only mode. Please contact your Models Admin if you need to make changes',
    value_categories: 'Value Categories',
    validation: 'Validation',
    Dashboard: 'Dashboard',
    Models: 'Models',
  },
  'solution_category': {
    action: 'Action',
    add_category: 'Add Category',
    add_new_category: 'Add New Category',
    category: 'Category',
    category_name: 'Category Name:',
    model_categories: 'Model Categories',
    save: 'Save',
  },
  'Thresholds': {
    add_new: 'Add New',
    choose_one: 'Choose One',
    delete: 'Delete',
    edit: 'Edit',
    is_required: 'Is Required',
    manage_threshold: 'Manage Threshold',
    margin: 'Margin',
    margin_percent: 'Margin (%)',
    max: 'Max',
    min: 'Min',
    name: 'Name',
    no_thresholds_found: 'No Thresholds Found',
    save: 'Save',
    you_cannot_enter_minimum_value_greater_than_maximum_value: 'You cannot enter minimum value greater than maximum value.',
  },
  'Valuemaps': {
    maps_by_category: 'Maps By Category',
    solution_categories: 'Solution Categories',
    solutions: 'Solutions',
    your_solution_overview: 'Your Solution Overview',
  },
  'Vmware': {
    based_on_your_initial_input_we_recommend_the_following_projects: 'Based on your initial input, we recommend the following project(s).',
    continue: 'Continue',
    reset: 'Reset',
    you_can_override_the_suggestion_and_add_subtract_project_as_appropriate: 'You can override the suggestion and add/subtract project as appropriate',
  },
  'vpDashBoard': {
    Assumptions: {
      assumptions: 'All Assumptions',
      collapse: 'Collapse All',
      driver: 'Driver Factors',
      drivers: 'Drivers',
      expand: 'Expand All',
      financial: 'Financial Factors',
      financials: 'Financials',
      keyassumptions: 'Key Assumptions',
      scratchpad_units: 'Scratchpad Units',
      scratchpads: 'Scratchpads',
      starred: 'Starred',
      value: 'Value',
    },
    Benefits: {
      add: 'Add',
      additional: 'Additional Information',
      area: 'Area',
      all_value_categories: 'All Value Categories',
      add_new: 'Add New',
      action: 'Action',
      are_you_sure_that_you_want_to_remove_these_benefits: 'Are you sure that you want to remove these benefits?',
      are_you_sure_that_you_want_to_remove_these_subtypes_it_will_remove_these_subtypes_for_all_instances_of_this_driver_factor:
        'Are you sure that you want to remove these subtypes? It will remove these subtypes for all intsances of this driver factor',
      are_you_sure_you_want_to_remove_these_scratchpads_it_will_remove_these_subtypes_for_all_instances_of_this_scratchpads:
        'Are you sure that you want to remove these scratchpads? It will remove these subtypes for all intsances of this scratchpads',
      benefits: 'Benefits',
      benefit: 'Benefit: ',
      back: 'Back',
      comment: 'Comment',
      cancel: 'Cancel',
      collapse: 'Collapse All',
      current_effort: 'Current Effort',
      current_state: 'Current State',
      delete: 'Delete',
      delete_this_benefit: 'Delete this Benefit?',
      delete_this_scratchpad: 'Delete this Scratchpad?',
      delete_this_subtype: 'Delete this Subtype?',
      driver_factor: 'Driver Factor',
      driver_subtypes: 'Driver Subtypes',
      driver_scratchpads: 'Driver Scratchpads',
      edit: 'Edit',
      editing: 'Editing Benefit',
      expand_all: 'Expand All',
      enter_the_different_types_of: 'Enter the different types of ',
      feedback: 'Feedback',
      financial: 'Financial Factor',
      financial_factor: 'Financial Factor',
      financial_scratchpads: 'Financial Scratchpads',
      impacts: 'Impacts Which Business Areas',
      impacts_desc: 'Which areas of the business does this benefit impact? This may drive items like situations and assets for particular audience personas.',
      improve: 'Improvement',
      improvement: 'Improvement Factor',
      improvement_scratchpads: 'Improvement Scratchpads',
      include: 'Include?',
      name: 'Name',
      no_records_found: 'No records found',
      no_driver_subtype: 'No Driver Subtype',
      no_benefits_for_this_value_category: 'No Benefits for this Vlaue Category',
      no_benefits_found: 'No Benefits Found',
      no: 'No',
      new_factor: 'New Factor',
      operator: 'Operator',
      projects: 'Projects',
      required: 'Required',
      rate: 'Rate',
      rate_this_benefit: 'Rate This Benefit',
      value_category: 'Value Category',
      select: 'Select',
      sort_by: 'Sort By',
      save: 'Save',
      save_changes: 'Save Changes',
      situations: 'Situations',
      status: 'Status',
      subtypes: 'Subtypes',
      scratchpads: 'Scratchpads',
      this_covers: 'this covers:',
      show_feedback: 'Show Feedback',
      with_us: 'With Us',
      yes: 'Yes',
    },
  },
  'supporting_materials': {
    case_studies: {
      back_to_case_studies_list: 'Back to case studies list',
      case_studies: 'Case Studies',
      add_new: 'Add New',
    },
  },
  'account_profile': {
    account_partners: {
      clean_up: 'Clean Up',
      question: 'Discovery Questions',
      element_name: 'Element Name',
      heading: 'Administrator Options',
      manage_application_features: 'Manage Application Features',
      manage_application_settings: 'Manage Application Settings',
      manage_asset_templates: 'Manage Asset Templates',
      manage_competitors: 'Manage Competitors',
      manage_partners: 'Manage Partners',
      manage_users: 'Manage Users',
      solution_reports: 'Solutions and Value Props',
      stack_order: 'Stack Order',
      user_adoption_reports: 'User Adoption',
      name: 'Name',
      type: 'Typre',
      actions: 'Actions',
      owner: 'Owner',
    },
    adoptionReports: {
      ActivityOrValuePositionsOrPropsOverTime: 'Activity / Value Hypotheses / Cases Over Time',
      PropsOrPositionsByStatus: 'Business Cases / Hypotheses By Status',
      adoptionReports: 'Adoption Reports',
      application_settings: '',
      count: 'Count',
      master_value_prop_report: 'Master Business Case / Value Assessment Report',
      owner: 'Owner',
      topCreators: 'Top Creators',
      topLogins: 'Top Logins',
      topValuePositionsOrProps: 'Top Value Hypotheses / Business Cases',
      totalBenefits: 'Total Benefits',
      value_prop_report: 'Business Case / Value Assessment Report',
    },
    applicationSettings: {
      accountLogos: 'Account Logos',
      accountStage: 'Account Stage',
      accountStageName: 'Account Stage Name',
      accountStages: 'Account Stages',
      add: 'Add',
      add_new_status: 'Add new status',
      application_settings: 'Application Settings',
      availableColors: 'Available Colors',
      changeAccountIcon: 'Change Account Icon',
      changeAccountLogo: 'Change Account Logo',
      chartColor: 'Chart Color',
      createNew: 'Create New',
      deleteAccountStage: 'Delete Account Stage',
      deleteConfirmation: 'Are you sure that you want to remove this Account Stage type?',
      description: 'Description',
      headerColor: 'Header Color',
      imageWithTranSpeicherntBackgroundWorksBest: '',
      imageWithTransparentBackgroundWorksBest: 'Image with transparent background works best.',
      import_system_statuses: 'Import system statuses',
      noAccountStagesFound: 'No Account Stages Found',
      no_entries_found: 'No Entries Found',
      requires_comment: 'Requires comment?',
      sequence: 'Sequence ',
      status: 'Status',
      userPreference: 'User Preference',
      vp_status: 'Value Prop Status',
    },
    assetManagement: {
      addAssetTemplate: 'Add Asset Template',
      addNewTemplate: 'Add New Template',
      addTemplate: 'Add Template',
      artifactName: 'Asset Name',
      blocks: 'Blocks',
      chartsAndGraphs: 'Charts and Graphs',
      defaultActive: 'Active by default?',
      description: 'Description',
      element_name: '',
      format: 'Format',
      language: 'Language',
      manageAssetTemplates: 'Manage Asset Templates',
      next: 'Next',
      no_asset_templates_found: 'No Asset Templates Found',
      page: 'page',
      pickAssestElement: 'Pick Asset Element',
      slideNumber: 'Slide Number',
      stack_order: 'Stack Order',
      templateName: 'Template Name',
      whatKindOfAssetAreYouUploading: 'What kind of asset are you uploading?',
      willCustomersBeDownloadingThisAssetDirectly: 'Will customers be downloading this asset directly?',
      yourAssetTemplateFile: 'Your Asset Template File',
    },
    change: 'change',
    account_competitors: {
      company_optional: 'Company (Optional)',
      manage_competitors: 'Manage Competitors',
      competitor: 'Competitor',
      delete_competitor: 'Delete Competitor?',
      remove_this: 'Are you sure that you want to remove this competitor?',
      select_company: 'Select Company',
      name_label: 'Name',
      actions: 'Actions',
      description: 'Description',
      is_this_a_test_item: 'Is this a test Item',
    },
    credits: {
      account_credits: 'Account Credits',
      assignCreditslabel: 'Assign Credits',
      heading: 'Credits',
      my_credits: 'My Credits',
      selectUser: 'Select User',
    },
    details: {
      Email: 'Email',
      backToProfile: 'Back to Profile',
      changePassword: 'Change Password',
      confirmPassword: 'Confirm Password',
      currencyType: 'Currency Type',
      currentPassword: 'Current Password',
      editPwd: 'Edit',
      heading: 'Your Profile',
      languageType: 'Language Type',
      manager: 'Manager',
      mobile: 'Mobile',
      newPassword: 'New Password',
      numberFormat: 'Number Format',
      phone: 'Phone',
      updateProfileIcon: 'Update Profile Icon',
      pick_asset_element: 'Pick Asset Element',
    },
    edit: 'edit',
    partners: {
      'addPartnerType': 'Add Partner Type',
      'available-partners': 'Available Partners',
      'delete': 'Delete Partner',
      'delete-modal-title': '',
      'description': 'Description (Optional)',
      'newPartnerType': 'New Partner Type',
      'none-found': 'No Partners Found',
      'owner': 'Owner',
      'partnerType': 'Partner Type (Optional)',
      'remove-prompt': 'Are you sure that you want to remove this Partner?',
    },
    privileges: {
      changePrivilege: 'Change Privilege',
      heading: 'Privileges',
      menu: {
        'account-administrator': 'Account Administrator',
        'asset-administrator': 'Asset Administrator',
        'solution-administrator': 'Solution Administrator',
        'user-administrator': 'User Administrator',
      },
      selectPrivilege: 'Select Privilege',
    },
    requestChange: 'request change',
    solutionReport: {
      baseline: 'Baseline',
      benefitsOftenRemoved: 'Benefits Often Removed',
      changed: 'Changed',
      createImprovementFactor: '',
      driverFactor: 'Driver Factor',
      factorsWithBiggestVariations: 'Factors with Biggest Variations',
      financialFactor: 'Financial Factor',
      frequency: 'Frequency',
      improvementFactor: 'Improvement Factor',
      lowestRatedBenefits: 'Lowest Rated Benefits',
      noDataFound: 'No Data Found',
      rating: 'Rating',
      standardDeviation: 'Standard Deviation',
      topOnTheFlyBenefits: 'Top On The Fly Benefits',
      userCommentsOnBenefits: 'User Comments on Benefits',
    },
    support: {
      'answer': 'Answer',
      'errorChooseSection': 'Please choose a section',
      'errorEmailValue': 'Please enter the Email Value',
      'errorIssueOrFeedback': 'Please enter your issue / feedback',
      'faq': 'FAQ',
      'issuesOrFeedback': 'Issues/ Feedback',
      'question': 'Question',
      'screenshots': 'Screenshots',
      'section': 'Section',
      'submit Comments and Concerns': '',
      'submitCommentsAndConcerns': 'Submit Comments and Concerns',
      'support': 'Support',
      'changelog': 'Change Log',
    },
    team: {
      addTeamMember: 'Add Team Member',
      deleteMember: 'Delete Member?',
      deleteTeamMemberConfirm: 'Are you sure that you want to remove this team member?',
      heading: 'Your Team',
      selectPrivileges: 'Select Privileges',
      selectUser: 'Select User',
    },
    trophies: {
      heading: 'Trophies',
    },
    manage_users: {
      delete_user: 'Delete User?',
      email: 'Email',
      first_name: 'First Name',
      key: 'CRM Key?',
      last_name: 'Last Name',
      manage_email: 'Manager Email',
      role: 'Role',
      username: 'User Name',
      delete_user_confirm: 'Are you sure that you want to remove this user?',
      has: 'has',
      manageusers: 'Manage Users',
      no_users_found: 'No Users Found',
      re_assign_and_delete: 'Re-assign and Delete',
      re_assign_this_users_valueprops: "Re-assign this user's Business Cases / Value Assessments",
      select_user: 'Select User',
      value_props: 'Value Props',
      user_group: 'User Group',
      actions: 'Actions',
      name: 'Name',
      email_lowercase: 'Email(Lowercase)',
      manager_email: 'Manager Email',
    },
  },

  'add_benefits_modal': {
    'add_your_benefits': 'Add Your Benefits',
    'aggressive': 'Aggressive',
    'answer': 'Answer',
    'benefit_phasing': 'Benefit Phasing',
    'create_driver_factor': 'Create Driver Factor',
    'create_financial_factor': 'Create Financial Factor',
    'current_effort': 'Current Effort',
    'capabilities': 'Capabilities',
    'description': 'Description',
    'does_your_benefit_scale': 'Does your benefit scale?',
    'driver_factor': 'Driver Factor',
    'expense_type': 'Expense Type',
    'featurescapabilities': 'Features/Capabilities',
    'financial_factor_type': 'Financial Factor Type',
    'functional_objectives': 'Functional Objectives?',
    'impact': 'Impact',
    'impacts_which_business_areas': 'Impacts Which Business Areas?',
    'improvement_conservative': 'Improvement Conservative',
    'improvement_factor': 'Improvement Factor',
    'is_required': 'Is Required',
    'is_this_an_annual_benefit': 'Is this an annual benefit?',
    'mark_this_benefit_as_nonapplicable_by_default': 'Mark this benefit as Non-Applicable by Default?',
    'name': 'Name',
    'no_areas_selected': 'No areas selected',
    'operational_objectives': 'Operational Objectives?',
    'probable': 'Probable',
    'project': 'Project',
    'painpoint': 'Pain Points?',
    'save': 'Save',
    'situations': 'Situations',
    'uniqueness': 'Uniqueness',
    'which_value_category_does_this_benefit_fall_under': 'Which value category does this benefit fall under?',
    'year_1': 'Year 1: ',
    'year_2': 'Year 2:',
    'year_3': 'Year 3:',
    'year_4': 'Year 4:',
    'year_5:': 'Year 5:',
  },

  'add-casestudy': {
    'add_case_study': 'Add Case Study',
    'add_company': 'Add Company',
    'add_new': 'Add New',
    'address': 'Address',
    'cancel': 'Cancel',
    'city': 'City',
    'country': 'Country',
    'description': 'Description',
    'employees': 'Employees',
    'enter_comma_separated_keywords_for_tags': 'Enter comma separated keywords for tags',
    'heres_what_we_know_about_the_account_you_have_selected': "Here's what we know about the account you have selected:",
    'models': 'Models',
    'name': 'Name',
    'no_companies_found': 'No Companies Found',
    'revenue': 'Revenue',
    'save': 'Save',
    'sector': 'Sector',
    'step_#1:_redact': 'Step #1: Redact',
    'step_#2:_pick_your_account': 'Step #2: Pick your account',
    'tags': 'Tags',
    'ticker': 'Ticker',
    'website': 'Website',
  },

  'add_project_model': {
    add_child: 'Add Child',
    add_new: 'Add New',
    add_project: 'Add Project',
    add_to_existing_parent: 'Add to Existing Parent',
    add_to_list_by_default: 'Add to List by Default',
    cancel: 'Cancel',
    children: 'Children',
    description: 'Description',
    parent: 'Parent',
    project_name: 'Project name',
    save: 'Save',
  },
  'add_solution': {
    back: 'Back',
    build_from: 'Build from',
    pick_a_model: 'Pick a Model',
    scratch: 'Scratch',
  },

  'add_value_category_modal': {
    'add': 'Add',
    'add_new': 'Add New',
    'add_value_category': 'Add Value Category',
    'cancel': 'Cancel',
    'category_name': 'Category name',
    'import': 'Import',
    'save': 'Save',
    'what_category_did_you_influence?': 'What Category Did You Influence?',
    'what_category_did_you_influence': 'what Category Did You Influence?',
  },

  'add-value-position': {
    'OpportunityID': 'Opportunity ID',
    'all_scalers': 'All Scalers',
    'apply': 'Apply',
    'based_on_your_project_selection_we_suggest_the_following_products': 'Based on your project selection, we suggest the following products:',
    'choose_category_type': 'Choose Category Type:',
    'continue_to_configure': 'Continue to Configure',
    'discover_opportunities': 'Discover Opportunities',
    'finish_and_review': 'Finish and Review',
    'going_back_a_few_steps_this_may_reset_subsequent_steps_and_start_from_the_current_step':
      'Going back a few steps? This may reset subsequent steps and start from the current step.',
    'how_would_you_like_to_determine_the_solutions_for_this_value_prop': 'How would you like to determine the solutions for this Business Case / Value Assessment',
    'models': 'Models',
    'next': 'Next',
    'no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_above':
      'No Models for this category, please try a different one by selecting from the dropdown above.',
    'no_models_for_this_project.': 'No Models for this project.',
    'no_projects_found': 'No Projects Found',
    'no_scalers_found': 'No Scalers Found.',
    'pick_your_models': 'Pick your models',
    'pick_your_projects': 'Pick your projects',
    'pick_your_situations': 'Pick your situations',
    'primary_scalers': 'Primary Scalers',
    'product_based': 'Product Based',
    'project_based': 'Project Based',
    'scale_your_value_position': 'Scale Your Value Hypothesis',
    'scale_your_value_prop': 'Scale Your Business Case / Value Assessment',
    'select_your_project_below': 'Select Your Project Below',
    'situations': 'Situations',
  },
  'adoption_reports': {
    chart: 'Chart',
    excluded_user_list: 'Excluded User List',
    filter: 'Filter',
    get_all_data: 'Get All Data',
    logins_by_month: 'Logins By Month',
    no_logins: 'No Logins',
    no_value_props: 'No Value Props',
    save: 'Save',
    take_a_tour: 'Take a tour',
    top_account_value: 'Top Account Value',
    top_creators: 'Top Creators',
    top_customer_value: 'Top Customer Value',
    top_delivered: 'Top Delivered',
    vps_created_by_month: "VP's Created By Month",
    vps_modified_by_month: "VP's Modified By Month",
  },
  'assets': {
    download: 'Download',
    name: 'Name',
    no_assets_found: 'No Assets Found',
  },
  'benefit_detail': {
    '(name)_experience_an_improvement_of_(amount)_with_this_benefit.': '(name) experience an improvement of (amount) with this benefit.',
    'are_you_sure_you_want_to_delete_this_benefit?': 'Are you sure you want to delete this Benefit?',
    'are_you_sure_you_want_to_delete_this_note?': 'Are you sure you want to delete this note?',
    'delete_benefit?': 'Delete Benefit?',
    'delete_note?': 'Delete Note?',
    'no': 'No',
    'proof_points_for': 'Proof Points for',
    'yes': 'Yes',
  },

  'benefit-from-library-modal': {
    add_value_category: 'Add Value Category',
    benefit_name: 'Benefit Name',
    description: 'Description',
    library_benefits: 'Library Benefits',
    no_library_benefits_found: 'No Library Benefits Found',
  },
  'benefits': {
    'action': 'Action',
    'add': 'Add',
    'add_driver_subtype': 'Add Driver Subtype',
    'add_new': 'Add New',
    'all_value_categories': 'All Value Categories',
    'are_you_sure_that_you_want_to_remove_these_benefits?': 'Are you sure that you want to remove these benefits?',
    'are_you_sure_that_you_want_to_remove_these_subtypes?_it_will_remove_these_subtypes_for_all_instances_of_this_driver_factor':
      'Are you sure that you want to remove these subtypes? It will remove these subtypes for all instances of this driver factor',
    'benefit:': 'Benefit:',
    'comment': 'Comment',
    'create': 'Create',
    'create_factor': 'Create Factor',
    'delete': 'Delete',
    'delete_this_benefits?': 'Delete This Benefits?',
    'delete_this_scratchpad?': 'Delete this Scratchpad?',
    'delete_this_subtype?': 'Delete this Subtype?',
    'driver_factor': 'Driver Factor',
    'driver_factor_scratchpad': 'Driver Factor Scratchpad',
    'driver_scratchpads': 'Driver Scratchpads',
    'driver_subtypes': 'Driver Subtypes',
    'enter_the_different_types_of_(factor)_this_covers:': 'Enter the different types of (factor) this covers:',
    'feedback': 'Feedback',
    'financial_factor_scratchpad': 'Financial Factor Scratchpad',
    'improvement_factor_scratchpad': 'Improvement Factor Scratchpad',
    'improvement_scratchpads': 'Improvement Scratchpads',
    'name': 'Name',
    'new_factor': 'New Factor',
    'no': 'No',
    'no_benefits_for_this_value_category': 'No Benefits for this Value Category',
    'no_benefits_found': 'No Benefits Found',
    'operator': 'Operator',
    'projects': 'Projects',
    'rate_this_benefit': 'Rate This Benefit',
    'save': 'Save',
    'scratchpads': 'Scratchpads',
    'select': 'Select',
    'set_bulk_status': 'Set Bulk Status',
    'show_feedback': 'Show Feedback',
    'situations': 'Situations',
    'sort_by': 'Sort By',
    'subtypes': 'Subtypes',
    'value_category': 'Value Category',
    'yes': 'Yes',
  },

  'benefits-casestudy': {
    benefit: 'Benefit',
    description: 'Description',
    improvement: 'Improvement',
    name: 'Name',
    no_benefits_found: 'No Benefits Found',
    tie_to_benefits: 'Tie to Benefits',
    type: 'Type',
    update: 'Update',
    update_benefit: 'Update Benefit',
  },

  'case-studies-benefits': {
    improvement_currency: 'Improvement Currency',
  },

  'casestudy-detail': {
    'benefits': 'Benefits',
    'cancel': 'Cancel',
    'change': 'Change',
    'download_asset': 'Download Asset',
    'model:': 'Model:',
    'name': 'Name',
    'save': 'Save',
    'sections': 'Sections',
    'tags:': 'Tags:',
    'testimonials': 'Testimonials',
  },
  'cashflow': {
    no_cashflow_found: 'No Cashflow Found',
  },
  'company': {
    add_review: 'Add Review',
    apply_changes: 'Apply Changes',
    back: 'Back',
    comments: 'Comments',
    comparison: 'Comparison',
    credit_per_report_you_have_remaining_credits: '1 Credit Per Report, You have remaining {{value}} Credits',
    download_full_report: 'Download Full Report',
    employees: 'Employees',
    industries: 'Industries',
    rating: 'Rating',
    ratings: 'Ratings &',
    recent_review: 'Recent Review',
    revenue: 'Revenue',
    reviews: 'Reviews',
    sectors: 'Sectors',
    select_industry: 'Select Industry',
    select_sector: 'Select Sector',
    ticker: 'Ticker',
    view_sample_report: 'View Sample Report',
  },
  'company-search': {
    enter_only_id: 'Enter only id',
  },
  'compare': {
    'are_you_sure_that_you_want_to_delete_this_cost?': 'Are you sure that you want to delete this cost?',
    'assumptions': 'Assumptions',
    'back_to_mater_value_prop': 'Back to Master Business Case / Value Assessment',
    'back_to_vp_group': 'Back to Value Prop Group',
    'benefits': 'Benefits',
    'cancel': 'Cancel',
    'cancel_changes': 'Cancel Changes',
    'comparison': 'Comparison',
    'costs': 'Costs',
    'delete_cost': 'Delete Cost',
    'edit_all_values': 'Edit All Values',
    'key_figures': 'Key Figures',
    'master_valueprop_for': 'Master ValueProp for',
    'save': 'Save',
    'save_all': 'Save All',
    'save_changes': 'Save Changes',
    'totals': 'Totals',
  },
  'competitor': {
    name: 'Name',
    no_competitor_found: 'No Competitor Found',
  },

  'competitors': {
    'add_new': 'Add New',
    'choose_one': 'Choose One',
    'delete': 'Delete',
    'description': 'Description',
    'edit': 'Edit',
    'is_required': 'Is Required',
    'landmines': 'Landmines',
    'name': 'Name',
    'no_competitors_found': 'No Competitors Found',
    'our_response': 'Our Response',
    'save': 'Save',
    'their_landmines': 'Their Landmines',
    "they'll_say": "They'll say",
  },

  'cost': {
    '(cost_type)_costs': '(cost type) Costs',
    'add': 'Add',
    'add_cost_category': 'Add Cost Category',
    'add_new': 'Add New',
    'are_you_sure_you_want_to_remove_this_solution_cost?': 'Are you sure you want to remove this solution cost?',
    'cancel': 'Cancel',
    'category_name': 'Category Name',
    'choose_one': 'Choose One',
    'cost_answer_baseline': 'Cost Answer Baseline',
    'cost_category_(optional)': 'Cost Category (Optional)',
    'cost_type': 'Cost Type',
    'delete': 'Delete',
    'delete_solution_cost?': 'Delete Solution Cost?',
    'driver_factor_(optional)': 'Driver Factor (Optional)',
    'edit': 'Edit',
    'expense_type': 'Expense Type',
    'how_does_this_accrue?': 'How does this accrue?',
    'include_this_cost_by_default_in_valueprops?': 'Include this cost by default in Business Cases / Value Assessments?',
    'is_required': 'Is Required',
    'line_item': 'Line Item',
    'no_model_costs_found': 'No Model Costs Found',
    'scale_factor': 'Scale Factor',
    'situations': 'Situations',
    'unit_price': 'Unit Price',
  },

  'create-map': {
    add_new: 'Add New',
    average_deployment_time: 'Average Deployment Time',
    back: 'Back',
    benefits: 'Benefits',
    case_studies: 'Case Studies',
    choose_from_library: 'Choose from Library',
    collateral: 'Collateral',
    costs: 'Costs',
    description: 'Description',
    driver_factor: 'Driver Factor',
    enter_name: 'Enter Name',
    model_category: 'Model Category',
    model_name: 'Model Name',
    name: 'Name',
    no_benefits_found: 'No Benefits Found',
    quantities: 'Quantities',
    save: 'Save',
    situations: 'Situations',
    typical_customers: 'Typical Customers',
    value_category: 'Value Category',
    your_model: 'Your Model',
  },
  'create-survey': {
    benefit_performance: 'Benefit Performance',
    no_benefit_performance_found: 'No Benefit Performance Found',
  },
  'customer-stories': {
    close: 'Close',
    launch_demo_modal: 'Launch demo modal',
    modal_title: 'Modal title',
    one_fine_body: 'One fine body',
  },

  'dashboard': {
    SCBHeading: 'Recent Account Insights',
    caseStudiesHeading: 'Recent Case Studies',
    masterValuePropsHeading: 'Recent Master Business Cases / Value Assessments',
    modelsHeading: 'Recent Value Models',
    noCaseStudies: 'No Case Studies Found',
    noScbFound: 'No Data Found',
    noValueModels: 'No Models Found',
    noValuePositions: 'No Value Hypotheses Found',
    noValueProps: 'No Business Cases / Value Assessments Found',
    noValueRealizations: 'No Value Realizations Found',
    notLicensedForValueInsights: 'Please wait as your Value Insights load.',
    notLicensedForValueModels: 'Please wait as your Models load.',
    notLicensedForValuePositions: 'Please wait as your Value Hypotheses load.',
    notLicensedForValueProps: 'Please wait as your Business Cases / Value Assessments load.',
    notLicensedForValueRealizations: 'Please wait as your Value Realizations load.',
    valuePositionsHeading: 'Recent Value Hypotheses',
    valuePropsHeading: 'Recent Business Cases / Value Assessments',
    valueRealizationsHeading: 'Recent Value Realizations (Different from VR from Insight)',
  },
  'deployment-schedule': {
    'deployment_(cumulative)': 'Deployment (Cumulative)',
    'deployment_(net)': 'Deployment (Net)',
    'graduated_deployment': 'Graduated Deployment',
    'will_this_have_a_graduated_deployment?': 'Will this have a graduated deployment?',
  },
  'discovery_questions': {
    add_a_new_question: 'Add a new question',
    description: 'Description',
    description_desc: 'This is the description of the field and will also be the text information provided in the contextual help for the user when reviewing the field',
    manage_discover_questions: 'Manage Discovery Questions',
    name: 'Name',
    name_desc: 'This is the question your user will be presented to provide the answer in the form field.',
    nothing: 'No questions found',
    required: 'Required',
    required_desc: 'If the field is required, it will prevent a user from updating or creating a Value Proposition or Position until this field is completed',
    save: 'Save',
    tag: 'Tag',
    tag_desc: 'This is the field name you want to use for including this field into any asset for downloading',
    type: 'Type',
    type_desc: 'This is the question your user will be presented to provide the answer in the form field.',
    type_fld1: 'Text Field',
    type_fld2: 'Long Text Field',
    type_fld3: 'Toggle / Yes | No',
  },
  'dl_admin': {
    create_a_new_account: 'Create A New Account',
    impersonate_a_user: 'Impersonate A User',
    create_new_faq: 'Create New FAQ',
    features: 'Features',
    features_well_info: 'Features extend or modify system functionality. Below, find a list of features available and toggle them on or off as needed.',
  },
  'docusign': {
    'choose_category_type': 'Choose Category Type',
    'finish_and_review': 'Finish and Review',
    'models': 'Models',
    'next': 'Next',
    'no_models_for_this_category,_please_try_a_different_one_by_selecting_from_the_dropdown_above.':
      'No Models for this category, please try a different one by selecting from the dropdown above.',
    'no_projects_found': 'No Projects Found',
    'no_scalers_found': 'No Scalers Found',
    'pick_your_models': 'Pick your models',
    'pick_your_projects': 'Pick your projects',
    'pick_your_situations': 'Pick your situations',
    'scale_your_value_position': 'Scale Your Value Hypothesis',
    'scale_your_value_prop': 'Scale Your Business Case / Value Assessment',
  },
  'faq': {
    no_faqs_found: "No Faq's Found",
  },
  'forgot-password': {
    forgot_your_password_enter_your_email_address: 'Forgot Your Password? Enter your email address',
    login: 'Login',
    register: 'Register',
    reset_password: 'Reset Password',
  },
  'general': {
    Calculate: 'Calculate',
    Cancel: 'Cancel',
    Choose: 'Choose',
    ChooseOne: 'Choose One',
    Clear: 'Clear',
    Comment: 'Comment',
    Create: 'Create',
    Delete: 'Delete',
    Edit: 'Edit',
    Feedback: 'Feedback',
    No: 'No',
    ROI: 'ROI',
    Rate: 'Rate',
    Term: 'Term',
    Total: 'Total',
    Update: 'Update',
    View: 'View',
    Year: 'Year',
    Yes: 'Yes',
    actions: 'Actions',
    add: 'Add',
    addNew: 'Add New',
    back: 'Back',
    chooseOptionToContinue: 'Choose Option to Continue',
    clone: 'Clone',
    custom: 'Custom',
    datatable: {
      first: 'First',
      last: 'Last',
      next: 'Next',
      previous: 'Previous',
      rows: 'rows',
      show: 'Show',
      showing: 'Showing',
    },
    date: 'Date',
    dealdesk: 'Deal Desk',
    description_label: 'Description',
    download: 'Download',
    email_address: 'Email Address',
    employees: 'Employees',
    expected: 'Expected',
    guidedDiscovery: 'Guided Discovery',
    isRequired: 'Required',
    logout: 'Log Out',
    masterValueProp: 'Master Business Case / Value Assessment',
    models: 'Model(s)',
    months: 'Months',
    name_label: 'Name',
    next: 'Next',
    password: 'Password',
    paybackPeriod: 'Payback Period',
    quickCreate: 'Quick Create',
    realized: 'Realized',
    revenue: 'Revenue',
    save: 'Save',
    search: 'Search',
    searchResults: 'Search Results',
    seeMore: 'See More',
    select: 'Select',
    start: 'Start',
    test_item_query: 'Is this a test item?',
    timesUsed: 'Times Used',
    totalBenefits: 'Total Benefits',
    typical: '',
    valuePropName: 'Business Case / Value Assessment Name',
    year1Benefits: 'Year 1 Benefits',
  },
  'guided-discovery': {
    'add_company': 'Add Company',
    'address': 'Address',
    'based_on_your_selections_the_best_fits_for_this_account_are:': 'Based on Your Selections the best fits for this account are:',
    'cancel': 'Cancel',
    'city': 'City',
    'country': 'Country',
    'dashboard': 'Dashboard',
    'description': 'Description',
    'employees': 'Employees',
    'finish_&_review': 'Finish & Review',
    "here's_what_we_know_about_the_account_you_have_selected:": '',
    'heres_what_we_know_about_the_account_you_have_selected': "Here's what we know about the account you have selected:",
    'initiative_is_required': 'Initiative is required',
    'name': 'Name',
    'next': 'Next',
    'no_companies_found': 'No Companies Found',
    'reach': 'Reach',
    'revenue': 'Revenue',
    'revise': 'Revise',
    'sector': 'Sector',
    'select_which_impact_area_you_would_like_to_focus_your_pursuit': 'Select which impact area you would like to focus your pursuit',
    'select_which_line_of_business_you_would_like_to_go_after': 'Select which line of business you would like to go after',
    'select_your_business_benefits': 'Select Your Business Benefits',
    'select_your_business_outcomes': 'Select Your Business Outcomes',
    'situations': 'Situations',
    'step_#1:_pick_your_account': 'Step #1: Pick your account',
    'step_#2:_impact_&_line_of_business': 'Step #2: Impact & Line of Business',
    'step_#3:_business_outcomes_and_value_categories': 'Step #3: Business Outcomes and Value Categories',
    'step_#4:_benefits_and_situations': 'Step #4: Benefits and Situations',
    'step_#5:_models': 'Step #5: Models',
    'ticker': 'Ticker',
    'value_positions_guided_discovery': 'Value Hypotheses - Guided Discovery',
    'value_prop_guided_discovery': 'Business Case / Value Assessment - Guided Discovery',
    'website': 'Website',
  },
  'intake': {
    'accept_terms_and_conditions': 'Accept Terms and Conditions',
    'login': 'LOGIN',
    'you_have_one_more_step_before_you_can_get_in!': 'You have one more step before you can get in!',
  },
  'issues': {
    choose_one: 'Choose One',
  },

  'library-solutions': {
    back: 'Back',
    import: 'Import',
    library_solutions: 'Library Solutions',
    model_name: 'Model Name',
    no_library_models_found: 'No Library Models Found',
    typical_customer_revenue: 'typical_customer_revenue',
  },
  'log': {
    no_logs_found: 'No Logs Found',
  },
  'login': {
    'LOGIN': 'LOGIN',
    'forgot_password': 'Forgot Password',
    'forgot_your_password': 'Forgot your password?',
    'forgot_your_password?_enter_your_email_address': 'Forgot Your Password? Enter your email address',
    'login': 'Login',
    'please_login_into_your_account': 'Please login into your account.',
    'please_enter_valid_address': 'Please enter a valid email address',
    'enter_your_email': 'Enter your email address and password to access Value Execution',
    'register': 'Register',
    'enter_email': 'Enter your email',
    'enter_pass': 'Enter your password',
    'reset_password': 'Reset Password',
    'decisionLink_corporation': 'Xfactor.io',
    'incorrect_email_or_password': 'Incorrect email or password, verify the information',
  },
  'master-value-prop': {
    'COMPARE': 'Compare',
    'actions': 'Actions',
    'add': 'Add',
    'add_projects': 'Add Projects',
    'assets': 'Assets',
    'benefit_per_transaction': 'Benefit per Transaction',
    'cancel': 'Cancel',
    'clone_this_master_value_prop': 'Clone this Master Business Case / Value Assessment',
    'compare': 'COMPARE',
    'compare_all': 'Compare All',
    'details': 'Details',
    'master_valueprop_for_(name)': '',
    'master_valueprop_for_name': 'Master Business Case / Value Assessment for ',
    'no_projects_found': 'No Projects Found',
    'pick_your_projects': 'Pick your projects',
    'projects': 'Projects',
    'save': 'Save',
    'scalers': '',
    'share_your_master_value_prop': 'Share Your Master Business Case / Value Assessment',
    'status': 'Status',
    'totals': 'Totals',
  },
  'master-value-prop-list': {
    created_date: 'Created Date',
    name: 'Name',
    no_value_props_found: 'No Business Cases / Value Assessments Found',
    total_benefits: 'Total Benefits',
    total_costs: 'Total Costs',
    valueprop_name: 'Business Case / Value Assessment name',
    your_master_value_props: 'Your Master Business Cases / Value Assessments',
  },

  'model': {
    listing: {
      Category: 'Category',
      Group: 'Group',
      Models: 'Models',
      Specific: 'Specific',
      assignModelAdmin: 'Assign Model Admin',
      categories: 'Categories',
      categoryName: 'Category Name',
      modelAdministrators: 'Model Administrators',
      modelCategories: 'Model Categories',
      modelName: 'Model Name',
      modelsCategoryOverview: 'Models Category Overview',
      noModelCategoriesFound: 'No Model Categories Found',
      releaseToAllUsers: 'Release to all users',
      releaseToPartners: 'Release to partners',
      releasedToPartners: 'Released to partners',
      releasedToUsers: 'Released to Users',
      removeFromPartners: 'Remove from partners',
      removeReleaseFromPartnersConfirmation: 'Are you sure that you want to remove this release from your partners?',
      removeThisRelease: 'Remove this release?',
      removecategoryConfirmation: 'Are you sure that you want to remove this Model Category?',
      top5Models: 'Top 5 Models',
      more: 'More',
    },
  },
  'modelDetail': {
    benefits: 'Benefits',
    case_studies: 'Case Studies',
    categories: 'Value Categories',
    collateral: 'Collateral',
    competitors: 'Competitors',
    costs: 'Costs',
    create: 'Create Scaler',
    factors: 'Factors',
    overview: 'Overview',
    projects: 'Projects',
    save: 'Save',
    scalers: 'Scalers',
    situations: 'Situations',
    thresholds: 'Thresholds',
  },
  'notes': {
    add_notes: 'Add Notes',
    customer_facing: 'Customer facing',
    delete: 'Delete',
    edit: 'Edit',
    name: 'Name',
    no_notes_found: 'No Notes Found',
    note: 'Note',
    save: 'Save',
  },
  'overview': {
    average_sales_price: 'Average Sales Price',
    averages_sales_cycle: 'Averages Sales Cycle',
    cancel: 'Cancel',
    deployment: 'Deployment',
    description: 'Description',
    model_category: 'Model Category',
    name: 'Name',
    no_model_found: 'No Model Found',
    typical_customers: 'Typical Customers',
  },
  'plans': {
    '25_or_more:_$6_each': '25 or more: $6 each',
    '5_or_more:_$10_each': '5 or more: $10 each',
    'back': 'Back',
    'buy_credits': 'Buy Credits',
    'buy_more_credits_and_save': 'Buy More Credits and Save',
    'choose_a_plan': 'Choose a Plan',
    'for_1_report/credit': 'for 1 Report/Credit',
    'for_number_of_reports': 'for {{value}} Reports',
    'pay_with_card': 'Pay with card',
    'save_currency_value': 'Save {{value}}',
    'select': 'Select',
  },
  'position': {
    listing: {
      addValuePosition: 'Add Value Hypothesis',
      search: 'Search',
      searchByName: 'Search By Name',
      searchValuePositions: 'Search Value Hypotheses',
      valuePositions: 'Value Hypotheses',
      yourValuePositions: 'Your Value Hypothesis',
    },
  },
  'profile': {
    administrator_options: {
      clean_up: 'Clean Up',
      dq: 'Discovery Questions',
      element_name: 'Element Name',
      heading: 'Administrator Options',
      manage_application_features: 'Manage Application Features',
      manage_application_settings: 'Manage Application Settings',
      manage_asset_templates: 'Manage Asset Templates',
      manage_competitors: 'Manage Competitors',
      manage_partners: 'Manage Partners',
      manage_users: 'Manage Users',
      solution_reports: 'Solution and Value Props',
      stack_order: 'Stack Order',
      user_adoption_reports: 'User Adoption',
      manage_user_groups: 'Manage User Groups',
    },
    adoptionReports: {
      ActivityOrValuePositionsOrPropsOverTime: 'Activity / Value Hypotheses / Cases Over Time',
      PropsOrPositionsByStatus: 'Business Cases / Hypotheses By Status',
      adoptionReports: 'Adoption Reports',
      application_settings: '',
      count: 'Count',
      master_value_prop_report: 'Master Business Case / Value Assessment Report',
      owner: 'Owner',
      topCreators: 'Top Creators',
      topLogins: 'Top Logins',
      topValuePositionsOrProps: 'Top Value Hypotheses / Business Cases',
      totalBenefits: 'Total Benefits',
      value_prop_report: 'Business Case / Value Assessment Report',
      download_report: 'Download report',
    },
    applicationSettings: {
      accountLogos: 'Account Logos',
      accountStage: 'Account Stage',
      accountStageName: 'Account Stage Name',
      accountStages: 'Account Stages',
      add: 'Add',
      add_new_status: 'Add new status',
      application_settings: 'Application Settings',
      availableColors: 'Available Colors',
      changeAccountIcon: 'Change Account Icon',
      changeAccountLogo: 'Change Account Logo',
      chartColor: 'Chart Color',
      createNew: 'Create New',
      deleteAccountStage: 'Delete Account Stage',
      deleteConfirmation: 'Are you sure that you want to remove this Account Stage type?',
      description: 'Description',
      headerColor: 'Header Color',
      imageWithTranSpeicherntBackgroundWorksBest: '',
      imageWithTransparentBackgroundWorksBest: 'Image with transparent background works best.',
      import_system_statuses: 'Import system statuses',
      noAccountStagesFound: 'No Account Stages Found',
      no_entries_found: 'No Entries Found',
      requires_comment: 'Requires comment?',
      sequence: 'Sequence ',
      status: 'Status',
      userPreference: 'User Preference',
      vp_status: 'Value Prop Status',
      branding: 'Branding',
      customize_ui: 'Customize UI',
      well_info:
        'Below, you can set various UI color elements. Click on the colored square to pick a color or enter in a web hex value into the input box and click the Update button.',
      header_color: 'Header Color',
      button_background_color: 'Button Background Color',
      button_text_color: 'Button Text Color',
      update: 'Update',
      well_info_2:
        "Below, you can choose what colors to use in various charts available around the application. Add as many as you'd like, but we recommend at least 10 colors for minimum value coverage.",
      add_color: 'Add Color',
      well_info_3:
        'These statuses appear in Value Prop Details for selection. Adding to this list switches from the built in status list. You can drag and drap rows to change their order.',
    },
    assetManagement: {
      addAssetTemplate: 'Add Asset Template',
      addNewTemplate: 'Add New Template',
      addTemplate: 'Add Template',
      artifactName: 'Asset Name',
      blocks: 'Blocks',
      chartsAndGraphs: 'Charts and Graphs',
      defaultActive: 'Active by default?',
      description: 'Description',
      element_name: '',
      format: 'Format',
      language: 'Language',
      manageAssetTemplates: 'Manage Asset Templates',
      next: 'Next',
      no_asset_templates_found: 'No Asset Templates Found',
      page: 'page',
      pickAssestElement: 'Pick Asset Element',
      slideNumber: 'Slide Number',
      stack_order: 'Stack Order',
      templateName: 'Template Name',
      whatKindOfAssetAreYouUploading: 'What kind of asset are you uploading?',
      willCustomersBeDownloadingThisAssetDirectly: 'Will customers be downloading this asset directly?',
      yourAssetTemplateFile: 'Your Asset Template File',
    },
    change: 'change',
    competitors: {
      company_optional: 'Company (Optional)',
      competitor: 'Competitor',
      delete_competitor: 'Delete Competitor?',
      remove_this: 'Are you sure that you want to remove this competitor?',
      select_company: 'Select Company',
    },
    credits: {
      account_credits: 'Account Credits',
      assignCreditslabel: 'Assign Credits',
      heading: 'Credits',
      my_credits: 'My Credits',
      selectUser: 'Select User',
    },
    details: {
      Email: 'Email',
      backToProfile: 'Back to Profile',
      changePassword: 'Change Password',
      confirmPassword: 'Confirm Password',
      currencyType: 'Currency Type',
      currentPassword: 'Current Password',
      editPwd: 'Edit',
      heading: 'Your Profile',
      languageType: 'Language Type',
      manager: 'Manager',
      mobile: 'Mobile',
      newPassword: 'New Password',
      numberFormat: 'Number Format',
      phone: 'Phone',
      updateProfileIcon: 'Update Profile Icon',
      pick_asset_element: 'Pick Asset Element',
      refresh_content_1: 'If you have changed your language preference, please click',
      refresh_content_2: 'to reload the application and apply your language preference.',
      here: 'Here',
      location: 'Location',
    },
    edit: 'edit',
    partners: {
      'addPartnerType': 'Add Partner Type',
      'available-partners': 'Available Partners',
      'delete': 'Delete Partner',
      'delete-modal-title': '',
      'description': 'Description (Optional)',
      'newPartnerType': 'New Partner Type',
      'none-found': 'No Partners Found',
      'owner': 'Owner',
      'partnerType': 'Partner Type (Optional)',
      'remove-prompt': 'Are you sure that you want to remove this Partner?',
    },
    privileges: {
      changePrivilege: 'Change Privilege',
      heading: 'Privileges',
      menu: {
        'account-administrator': 'Account Administrator',
        'asset-administrator': 'Asset Administrator',
        'solution-administrator': 'Solution Administrator',
        'user-administrator': 'User Administrator',
      },
      selectPrivilege: 'Select Privilege',
    },
    requestChange: 'request change',
    solutionReport: {
      baseline: 'Baseline',
      benefitsOftenRemoved: 'Benefits Often Removed',
      changed: 'Changed',
      createImprovementFactor: '',
      driverFactor: 'Driver Factor',
      factorsWithBiggestVariations: 'Factors with Biggest Variations',
      financialFactor: 'Financial Factor',
      frequency: 'Frequency',
      improvementFactor: 'Improvement Factor',
      lowestRatedBenefits: 'Lowest Rated Benefits',
      noDataFound: 'No Data Found',
      rating: 'Rating',
      standardDeviation: 'Standard Deviation',
      topOnTheFlyBenefits: 'Top On The Fly Benefits',
      userCommentsOnBenefits: 'User Comments on Benefits',
      business_case: 'Business Case',
      name: 'Name',
      modified: 'Modified',
    },
    support: {
      'answer': 'Answer',
      'errorChooseSection': 'Please choose a section',
      'errorEmailValue': 'Please enter the Email Value',
      'errorIssueOrFeedback': 'Please enter your issue / feedback',
      'faq': 'FAQ',
      'issuesOrFeedback': 'Issues/ Feedback',
      'question': 'Question',
      'screenshots': 'Screenshots',
      'section': 'Section',
      'submit Comments and Concerns': '',
      'submitCommentsAndConcerns': 'Submit Comments and Concerns',
      'support': 'Support',
      'changelog': 'Change Log',
    },
    team: {
      addTeamMember: 'Add Team Member',
      deleteMember: 'Delete Member?',
      deleteTeamMemberConfirm: 'Are you sure that you want to remove this team member?',
      heading: 'Your Team',
      selectPrivileges: 'Select Privileges',
      selectUser: 'Select User',
    },
    trophies: {
      heading: 'Trophies',
    },
    users: {
      DeleteUser: 'Delete User?',
      Email: 'Email',
      FirstName: 'First Name',
      Key: 'CRM Key?',
      LastName: 'Last Name',
      ManagerEmail: 'Manager Email',
      Role: 'Role',
      UserName: 'User Name',
      deleteUserConfirm: 'Are you sure that you want to remove this user?',
      has: 'has',
      manageUsers: 'Manage Users',
      no_users_found: 'No Users Found',
      re_assign_and_delete: 'Re-assign and Delete',
      re_assign_this_users_valueprops: "Re-assign this user's Business Cases / Value Assessments",
      select_user: '',
      value_props: 'Value Props',
    },
  },
  'props': {
    clone_this_value_prop: 'Clone this Value Prop',
    listing: {
      searchValueProposition: 'Search Business Cases / Value Assessments',
      yourValueProps: 'Your Business Cases / Value Assessments',
    },
    tabDetails: {
      actions: {
        clone: 'Clone',
        lock: 'Lock',
        share: 'Share',
        unlock: 'Unlock',
        valuePropClone: 'Business Case / Value Assessment Clone',
        valuePropShares: 'Business Case / Value Assessment Shares',
      },
      benefits: {
        ActiveStatus: 'Active Status',
        Add: {
          AddYourBenefits: 'Add Your Benefits',
          Aggressive: 'Aggressive',
          Answer: 'Answer',
          create_driver_factor: 'Create Driver Factor',
          create_financial_factor: 'Create Financial Factor',
          CurrentEffort: 'Current Effort',
          create_improvement_factor: 'Create Improvement Factor',
          DoesBenefitScale: 'Does your benefit scale?',
          driver_factor: 'Driver Factor',
          Features: 'Features/Capabilities',
          FinancialFactorType: 'Financial Factor Type',
          Impact: 'Impact',
          ImpactAreas: 'Impacts Which Business Areas?',
          improvement_conservative: 'Improvement Conservative',
          improvement_factor_optional: 'Improvement Factor (Optional)',
          IsAnnualBenefits: 'Is this an annual benefits?',
          NoAreasSelected: 'No Areas Selected',
          Probable: 'Probable',
          Solutions: 'Solutions',
          Uniqueness: 'Uniqueness',
          WhichValueCategory: 'Which value category does this benefit fall under?',
          improvementTypeList: 'Improvement Type',
          units: 'Units',
          createImprovementFactor: 'Create Improvement Factor',
        },
        AddNote: 'Add Note',
        ApplyToAllMetrics: 'Apply To All Metrics',
        Benefit: 'Benefit',
        Benefits: 'Benefits',
        benefits_phasing: 'Benefits Phasing',
        Description: 'Description',
        Driver: 'Driver',
        DriverScratchpad: 'Driver Scratchpad',
        Features: 'Features',
        Financial: 'Financial',
        FinancialFactor: 'Financial Factor',
        FinancialScratchpad: 'Financial Scratchpad',
        FinancialValue: 'Financial Value',
        GrowthAfter1stYear: 'Growth After 1st Year % (Not Compound)',
        ImpactType: 'Impact Type',
        Improvement: 'Improvement',
        Improvement$: 'Improvement[$]',
        ImprovementScratchpad: 'Improvement Scratchpad',
        is_required: 'is Required',
        NoBenefitDetailFound: 'No Benefit Detail Found',
        NoBenefitsFound: 'No Benefits Found',
        Note: 'Note',
        Originalname: 'Original name',
        Phase: 'Phase',
        Phasing: 'Phasing',
        RateThisBenefit: 'Rate This Benefit',
        SaveNote: 'Save Note',
        ScratchPadfor: 'ScratchPad for',
        SearchBenefits: 'Search Benefits',
        ShowAllBenefits: 'Show All Benefits',
        ShowFeedback: 'Show Feedback',
        SubType: 'SubType',
        Subtypes: 'Subtypes',
        Subtypesof: 'Subtypes of',
        Total: 'Total',
        TotalBenefit: 'Total Benefit',
        Value: 'Value',
        Year1: 'Year 1 :',
        Year10: 'Year 10 :',
        Year2: 'Year 2 :',
        Year3: 'Year 3 :',
        Year4: 'Year 4 :',
        Year5: 'Year 5 :',
        Year6: 'Year 6 :',
        Year7: 'Year 7 :',
        Year8: 'Year 8 :',
        Year9: 'Year 9 :',
        YourImpactOverTime: 'Your Impact Over Time',
        all_metric_in_model: 'Apply To All Benefits In Model',
        all_metrics_in_category: 'Apply To All Benefits in Value Category',
        apply: 'Apply',
        applyToAll: 'Apply to All',
        status: {
          Active: 'Active',
          ConsideredSoft: 'Considered Soft',
          NA: 'N/A',
          NotaPriority: 'Not a Priority',
        },
      },
      cashFlow: {
        Name: 'Name',
        Total: 'Total',
        Year1: 'Year 1',
        Year2: 'Year 2',
        Year3: 'Year 3',
        Year4: 'Year 4',
        Year5: 'Year 5',
      },
      collateral: {
        URL: 'URL',
        deleteCollateral: 'Delete Collateral?',
        deleteConfirmation: 'Are you sure that you want to remove this Collateral?',
        format: 'Format',
        noCollateralsFound: 'No Collaterals Found',
      },
      cost: {
        AccrualType: 'Accrual Type',
        Accrues: 'Accrues',
        AddCosts: 'Add Costs',
        CostCategory: 'Cost Category',
        CostType: 'Cost Type',
        Discount: 'Discount (%)',
        GrandTotal: 'Grand Total',
        NoCostsFound: 'No Costs Found',
        Quantity: 'Quantity',
        Subtotal: 'Subtotal',
        Type: 'Type',
        UnitPrice: 'Unit Price',
        Year1Cost: 'Year 1 Cost',
        Year2Cost: 'Year 2 Cost',
        Year3Cost: 'Year 3 Cost',
        Year4Cost: 'Year 4 Cost',
        Year5Cost: 'Year 5 Cost',
      },
      discovery: {
        assumptions: {
          DriverEstimate: 'Driver Estimate',
          DriverFactor: 'Driver Factor',
          DriverOverride: 'Driver Override',
          DriverSourceType: 'Driver Source Type',
          DriverValue: 'Driver Value',
          FinancialEstimate: 'Financial Estimate',
          FinancialFactor: 'Financial Factor',
          FinancialOverride: 'Financial Override',
          FinancialSourceType: 'Financial Source Type',
          FinancialValue: 'Financial Value',
          Includedbenefits: 'Included in the following benefits',
          NoDriversFound: 'No Drivers Found',
          NoFinancialsFound: 'No Financials Found',
          NoScratchpadUnitsFound: 'No Scratchpad Units Found',
          ScratchpadUnits: 'Scratchpad Units',
        },
        initiatives: {
          AddInitiatives: 'Add Initiatives',
          DeleteImpact: 'Delete Impact?',
          ImpactArea: 'Impact Area',
          Initiativeisrequired: 'Initiative is required',
          NoInitiavesFound: 'No Initiatives Found',
          RemoveImpactWarning: 'Are you sure that you want to remove this impact?',
          Selectimpact: 'Select which impact area you would like to focus your pursuit',
          UpdateInitiatives: 'Update Initiatives',
          name: 'Initiatives',
        },
      },
      keyFigures: {
        mi: '3 Month Cost of Delay',
        NPV: 'NPV',
        ROI: 'ROI',
        paybackPeriod: 'Payback Period (Months)',
        tco: 'TCO Improvements',
        totalBenefits: 'Total Benefits',
        totalCosts: 'Total Costs',
      },
      notes: {
        Name: 'Name',
        Note: 'Note',
        Time: 'Time',
        User: 'User',
        addBtn: 'Add',
      },
      scenario: {
        account: {
          Address1: 'Address1',
          Address2: 'Address2',
          Employees: 'Employees',
          EnhancedData: 'Enhanced Data',
          GrossProfit: 'Gross Profit',
          Industry: 'Industry',
          PhoneNo: 'Phone No',
          Revenue: 'Revenue',
          Sector: 'Sector',
          Website: 'Website',
          account_defined_id: 'Account ID',
          city: 'City',
          country: 'Country',
          name: 'Account',
          state: 'State',
          ticker: 'Ticker',
          zip: 'Zip',
        },
        advanced: {
          BaselineType: 'Baseline Type',
          ChooseOne: 'Choose One',
          CostofCapital: 'Cost of Capital (%)',
          CustomerType: 'Customer Type',
          DeploymentTime: 'Deployment Time (Months)',
          GrossProfitMargin: 'Gross Profit Margin (%)',
          Term: 'Term',
          conversion_rate: 'Conversion Rate',
          currency_format: 'Number Format',
          currency_type_id: 'Currency Type',
          name: 'Advanced',
          opportunity_id: 'Opportunity ID',
        },
        contacts: {
          name: 'Contacts',
        },
        models: {
          ChooseModels: 'Choose Models',
          SelectedModels: 'Selected Models',
          name: 'Models',
        },
        situations: {
          name: 'Situations',
          notAvailable: 'No Situations are available.',
          view_less: 'View less',
          view_more: 'View more',
        },
      },
      scoreCard: {
        'DriverComplete%': 'Driver Complete %',
        'FinancialComplete%': 'Financial Complete %',
        'ImprovementComplete%': 'Improvement Complete %',
        'PhasingActivated': 'Phasing Activated',
        'Status': 'Status',
      },
      share: {
        Action: 'Action',
        Company: 'Company',
        DeleteShareUser: 'Delete Share User?',
        DeleteUserWarning: 'Are you sure that you want to remove this user?',
        EditShare: 'Edit Share',
        Email: 'Email',
        ExistingCustomer: 'Existing Customer',
        ExistingGuest: 'Existing Guest',
        FirstName: 'First Name',
        LastName: 'Last Name',
        NewCustomer: 'New Customer',
        NoSharesFound: 'No Shares Found',
        ShareRoleType: 'Share Role Type',
        SharewithCustomer: 'Share with Customer',
        Title: 'Title',
        UserName: 'User Name',
        ValueProposition: 'Business Case / Value Assessment',
        name: 'Share',
      },
      tco: {
        CurrentState: 'Current State',
        FutureState: 'Future State',
        TCOSummary: 'TCO Summary',
        TCOSummaryPerUSer: 'TCO Summary Per User',
        Total: 'Total',
        TotalCostofOwnership: 'Total Cost of Ownership',
        TotalCurrentState: 'Total Current State',
        TotalFutureState: 'Total Future State',
        Year1: 'Year 1',
        Year2: 'Year 2',
        Year3: 'Year 3',
        Year4: 'Year 4',
        Year5: 'Year 5',
        noTCOFound: 'No TCO Found',
      },
    },
    table: {
      company: 'Company',
      created: 'Created',
      creatorName: 'Creator',
      name: 'Name',
      status: 'Status',
      totalBenefits: 'Total Benefits',
      totalCosts: 'Total Costs',
    },
    tabs: {
      Assets: 'Assets',
      Assumptions: 'All Assumptions',
      Benefits: 'Benefits',
      CaseStudies: 'Case Studies',
      Cashflow: 'Cashflow',
      Collateral: 'Collateral',
      Cost: 'Cost',
      DeploymentSchedule: 'Deployment Schedule',
      Discovery: 'Discovery',
      Initiatives: 'Initiatives',
      Log: 'Log',
      Notes: 'Notes',
      ROI: 'ROI',
      Scalers: 'Scalers',
      Scenario: 'Scenario',
      Scorecard: 'Scorecard',
      TCO: 'TCO',
      actions: 'Actions',
      assets: 'Assets',
      keyFigures: 'Key Figures',
      key_assumptions: 'Key Assumptions',
      mission: 'Mission Statement',
    },
  },

  'quote': {
    'add_testimonial': 'Add Testimonial',
    'are_you_sure_that_you_want_to_remove_this_testimonial?': 'Are you sure that you want to remove this testimonial?',
    'delete': 'Delete',
    'delete_case_study_testimonial?': 'Delete Case Study Testimonial?',
    'first_name': 'First Name',
    'is_required': 'Is Required',
    'last_name': 'Last Name',
    'no_testimonials_found': 'No Testimonials Found',
    'testimonial': 'Testimonial',
    'title': 'Title',
    'update': 'Update',
  },
  'realization': {
    Year1Cost: '',
    Year2Cost: '',
    Year3Cost: '',
    Year4Cost: '',
    Year5Cost: '',
    addACaseStudy: 'Add a Case Study',
    addCaseStudy: 'Add Case Study',
    addNewValueRealization: 'Add New Value Realization',
    addSection: 'Add Section',
    addSectionType: 'Add Section Type',
    addSurvey: 'Add Survey',
    addTestimonial: 'Add Testimonial',
    address: 'Address',
    benefits: 'Benefits',
    caseStudies: 'Case Studies',
    change: 'Change',
    createSurveyQuestions: 'Create Survey Questions',
    description: 'Description',
    done: 'Done',
    downloadAsset: 'Download Asset',
    employees: 'Employees',
    hereIsWhatWeKnowAboutTheAccountYouHaveSelected: 'Here’s what we know about the account you have selected: ',
    improvement: 'Improvement ',
    media: 'Media',
    mediaDescription: 'Media Description',
    model: 'Model',
    noSectionsFound: 'No Sections Found',
    revenue: 'Revenue',
    searchCaseStudies: 'Search Case Studies',
    sectionType: 'Section Type',
    sections: 'Sections',
    sector: 'Sector',
    status: 'Status',
    step1Redact: 'Step #1 Redact',
    surveyCreatedDate: 'Survey Created date',
    surveyTemplates: 'Survey Templates',
    surveys: 'Surveys',
    tagsEnterCommaSeparatedKeywordsForTags: 'Tags (Enter comma separated keywords for tags)',
    templateCreatedDate: 'Template Created Date',
    testimonials: 'Testimonials',
    tieToBenefits: 'Tie to Benefits',
    type: 'Type',
    valueRealizationDescription: '',
    website: 'Website',
    yesOrNo: 'Yes / No',
    youCanMeasureValueForAnyLockedValueProposition: '(You can measure value for any locked Business Case / Value Assessment)',
    yourValueRealization: 'Your Value Realizations (Different from VR from Insight)',
  },
  'registration': {
    'login': 'Login',
    'submit': 'Submit',
    'which_of_these_best_represents_your_role?*': 'Which of these best represents your role?*',
  },
  'release-to-partners': {
    no_partners_found: 'No Partners Found',
    partners: 'Partners',
    save: 'Save',
  },

  'research': {
    city: 'City',
    companyName: 'Company Name',
    company_description: 'Company Description',
    country: 'Country',
    dateOfDownload: 'Date of Download',
    employees: 'Employees',
    noCompaniesFound: 'No Companies Foundsss',
    popularSearches: 'Popular Searches',
    recentSearches: 'Recent Searches',
    recentlyDownloaded: 'Recently Downloaded',
    recentlyViewed: 'Recently Viewed',
    revenue: 'Revenue',
    searchByName: 'Search By Name',
    searchCompany: 'Search Company',
    searchForACompany: 'Search for a Company',
    search_for_a_company: 'Search for a Company',
    ticker: 'Ticker',
  },

  'scalers': {
    add: 'Add',
    cancel: 'Cancel',
    create: 'Create',
    create_account_scalers: 'Create Account Scalers',
    reset: 'Reset',
    save: 'Save',
    scaler_description: 'Scaler Description',
    scaler_name: 'Scaler Name',
  },
  'scb': {
    company: 'Company',
  },
  'search_company': {
    description: 'Description',
    add_company: 'Add Company',
    address: 'Address',
    city: 'City',
    country: 'Country',
    employees: 'Employees',
    heres_what_we_know_about_the_account_you_have_selected: "Here's what we know about the account you have selected",
    no_companies_found: 'No Companies Found',
    pick_your_account: 'Pick your account',
    revenue: 'Revenue',
    search_for_a_company: 'Search for a Company',
    sector: 'Sector',
    ticker: 'Ticker',
    website: 'Website',
  },

  'sections': {
    'action': 'Action',
    'add_media': 'Add Media',
    'add_section': 'Add Section',
    'add_section_type': 'Add Section Type',
    'choose_section_type': 'Choose Section Type',
    'delete': 'Delete',
    'description': 'Description',
    'description:': 'Description:',
    'download': 'Download',
    'edit': 'Edit',
    'edit_section': 'Edit Section',
    'image': 'Image',
    'is_required': 'Is Required',
    'media': 'Media',
    'media_description': 'Media Description',
    'name': 'Name',
    'no_media_found': 'No Media Found',
    'no_sections_found': 'No Sections Found',
    'preview': 'Preview',
    'section:': 'Section:',
    'section_type': 'Section Type',
    'section_type_description': 'Section type description',
    'section_type_name': 'Section type name',
    'size_(in_bytes)': 'Size (in Bytes)',
    'type': 'Type',
    'update_media': 'Update Media',
  },
  'share': {
    'actions': 'Actions',
    'add': 'Add',
    'are_you_sure_that_you_want_to_remove_this_privilege?': 'Are you sure that you want to remove this privilege?',
    'choose_one': 'Choose One',
    'delete_model_privilege?': 'Delete Model Privilege?',
    'no_shares_found': 'No Shares Found',
    'share_role_type': 'Share Role Type',
    'solution': 'Solution',
    'user_name': 'User Name',
  },

  'situation': {
    'active_by_default?': 'Active by default?',
    'add_new': 'Add New',
    'add_to_list': 'Add to list',
    'add_to_list_and_recommend': 'Add to list and Recommend',
    'add_to_the_available_costs': 'Add to the available costs',
    'added_to_list': 'Added to List',
    'aggressive': 'Aggressive',
    'all_costs': 'All Costs',
    'available_for_guided_discovery?': 'Available for Guided Discovery?',
    'back': 'Back',
    'change_(name)': 'Change (name)',
    'change_it': 'Change it',
    'children_of_(parent_name)': 'Children of (parent name)',
    'choose_one': 'Choose One',
    'conservative': 'Conservative',
    'cost_added_to_autoload': 'Cost Added to Autoload',
    'cost_added_to_available_cost': 'Cost Added to available cost',
    'description': 'Description',
    'driver_baseline': 'Driver Baseline',
    'edit': 'Edit',
    'edit_situation_type': 'Edit Situation Type',
    'element_type': 'Element Type',
    'financial_baseline': 'Financial Baseline',
    'from_available_costs': 'from available costs',
    'how_does_this_accrue?': 'How does this accrue?',
    'in_value_prop': 'in Business Case / Value Assessment',
    'included_by_default': 'Included by Default',
    'name': 'Name',
    'new_element': 'New Element',
    'new_situation': 'New Situation',
    'new_situation_type': 'New Situation Type',
    'no_assets_yet!': 'No Assets Yet!',
    'no_benefits_yet!': 'No Benefits Yet!',
    'no_case_studies_yet!': 'No Case Studies Yet!',
    'no_costs_yet!': 'No Costs Yet!',
    'no_projects_yet!': 'No Projects Yet!',
    'no_situation_yet!': 'No Situation Yet!',
    'non-applicable_by_default': 'Non-Applicable By Default',
    'off': 'Off',
    'on': 'On',
    'projects': 'Projects',
    'recommended': 'Recommended',
    'remove_from_list': 'Remove from list',
    'removed_from_autoload': 'Removed from Autoload',
    'removed_from_list': 'Removed from List',
    'reset': 'Reset',
    'reset_benefit': 'Reset Benefit',
    'reset_case_study': 'Reset Case Study',
    'reset_collateral': 'Reset Collateral',
    'reset_cost': 'Reset Cost',
    'save': 'Save',
    'scale_factor': 'Scale Factor',
    'select_a_situation_type_from_the_dropdown_above_to_proceed.': 'Select a situation type from the dropdown above to proceed.',
    'select_your_option': 'Select your option',
    'situation_type': 'Situation Type',
    'situation_type_elements': 'Situation Type Elements',
    'stack_order': 'Stack Order',
    'to_change_situation_type_stack_order,_drag_and_drop_situation_types_from_below_and_save':
      'To change Situation Type stack order, drag and drop situation types from below and save',
    'turn_it_off': 'Turn it off',
    'turn_it_on': 'Turn it on',
    'unit_price': 'Unit Price',
    'update': 'Update',
    'values_changed': 'Values Changed',
    'what_do_you_want_to_happen_to_this_benefit_when_this_sitation_occurs?': 'What do you want to happen to this benefit when this sitation occurs?',
    'will_this_be_a_nested_situation_type?': 'Will this be a nested situation type?',
  },

  'solution-category': {
    'action': 'Action',
    'add_category': 'Add Category',
    'add_new_category': 'Add New Category',
    'category': 'Category',
    'category_name:': 'Category Name:',
    'model_categories': 'Model Categories',
    'save': 'Save',
  },
  'solution-list': {
    'count)_more': 'count) More',
  },
  'survey': {
    no_surveys_found: 'No Surveys Found',
    quarters_in: 'Quarters In',
    settings: 'Settings',
    survey_name: 'Survey name',
    survey_settings: 'Survey Settings',
  },
  'survey-template': {
    active: 'Active',
    in_active: 'In Active',
    no_surveys_found: 'No Surveys Found',
  },
  'survey-template-create': {
    'add_options': 'Add Options',
    'answer_type': 'Answer Type',
    'example': 'Example',
    'general_questions': 'General Questions',
    'long_text': 'Long Text',
    'options': 'Options',
    'question_#': 'Question #',
    'questions': 'Questions',
  },

  'thresholds': {
    'add_new': 'Add New',
    'choose_one': 'Choose One',
    'delete': 'Delete',
    'edit': 'Edit',
    'is_required': 'Is Required',
    'manage_threshold': 'Manage Threshold',
    'margin': 'Margin',
    'margin_(%)': 'Margin (%)',
    'max': 'Max',
    'min': 'Min',
    'name': 'Name',
    'no_thresholds_found': 'No Thresholds Found',
    'save': 'Save',
    'you_cannot_enter_minimum_value_greater_than_maximum_value.': 'You cannot enter minimum value greater than maximum value.',
  },
  'value-position': {
    'delete': 'Delete',
    'model(s)': 'Model(s)',
    'months': 'months',
    'no_value_position_found': 'No Value Hypothesis Found',
    'payback_period': 'Payback Period',
    'roi': 'ROI',
    'total_benefits': 'Total Benefits',
    'typical': 'Typical',
    'valueprop_name': 'Business Case / Value Assessment name',
    'year_1_benefits': 'Year 1 Benefits',
  },
  'value-prop-dashboard': {
    'back_to_master_value_prop': 'Back to Master Business Case / Value Assessment',
    'cancel': 'Cancel',
    'change': 'change',
    'part_of_the_master_value_prop:': 'Part of the Master Business Case / Value Assessment:',
    'save': 'Save',
    'share_your_master_value_prop': 'Share Your Master Business Case / Value Assessment',
  },
  'value-realization': {
    'action': 'Action',
    'are_you_sure_that_you_want_to_remove_this_locked_valueprop?': 'Are you sure that you want to remove this locked Business Case / Value Assessment?',
    'company': 'Company',
    'delete_valueprop?': 'Delete Business Case / Value Assessment?',
    'expected_to_date': 'Expected To Date',
    'no_value_realization_found': 'No Value Realization Found',
    'realized_to_date': 'Realized To Date',
  },
  'valuemap': {
    'advanced': 'Advanced',
    'assets': 'Assets',
    'benefits': 'Benefits',
    'competitors': 'Competitors',
    'costs': 'Costs',
    'customer_stories': 'Customer Stories',
    'description': 'Description',
    'done': 'Done',
    'driver_factor': 'Driver Factor',
    'driver_scratchpads': 'Driver Scratchpads',
    'driver_subtypes': 'Driver Subtypes',
    'financial_scratchpads': 'Financial Scratchpads',
    'format': 'Format',
    'improvement_scratchpads': 'Improvement Scratchpads',
    'landmines': 'Landmines',
    'list_item': 'List Item',
    'name': 'Name',
    'our_response': 'Our Response',
    'scale': 'Scale',
    'their_landmines': 'Their Landmines',
    "they'll_say": "They'll say",
    'unit_price': 'Unit Price',
    'url': 'URL',
    'value_categories': 'Value Categories',
  },
  'valuemaps': {
    maps_by_category: 'Maps By Category',
    solution_categories: 'Solution Categories',
    solutions: 'Solutions',
    your_solution_overview: 'Your Solution Overview',
  },
  'valueprop': {
    custom: 'Custom',
    typical: 'Typical',
  },
  'vmware': {
    based_on_your_initial_input_we_recommend_the_following_projects: 'Based on your initial input, we recommend the following project(s).',
    continue: 'Continue',
    reset: 'Reset',
    you_can_override_the_suggestion_and_add_subtract_project_as_appropriate: 'You can override the suggestion and add/subtract project as appropriate',
  },

  'vpdashboard': {
    account: {
      account: 'Account',
      addr1: 'Address 1',
      addr2: 'Address 2',
      city: 'City',
      company_name: 'Company Name',
      country: 'Country',
      edit: 'Edit',
      editing: 'Editing Company Info',
      employees: 'Employees',
      gross: 'Gross Profits',
      id: 'Account ID',
      optional: 'Optional Information',
      phone: 'Phone #',
      required: 'Required',
      revenue: 'Revenue',
      save: 'Save',
      state: 'State',
      ticker: 'Ticker',
      website: 'Website URL',
    },
    actions: {
      actions: 'Actions',
      clone: 'Clone',
      lock: 'Lock Value Prop',
      share: 'Share',
      unlock: 'Unlock Value Prop',
    },
    advanced: {
      advanced: 'Advanced',
    },
    assets: {
      assets: 'Assets',
    },
    assumptions: {
      assumptions: 'All Assumptions',
      collapse: 'Collapse All',
      driver: 'Driver Factors',
      drivers: 'Drivers',
      expand: 'Expand All',
      financial: 'Financial Factors',
      financials: 'Financials',
      keyassumptions: 'Key Assumptions',
      scratchpad: 'Scratchpad Units',
      scratchpads: 'Scratchpads',
      value: 'Value',
    },
    benefits: {
      additional: 'Additional Information',
      area: 'Area',
      benefits: 'Benefits',
      cancel: 'Cancel',
      collapse: 'Collapse All',
      current_effort: 'Current Effort',
      current_state: 'Current State',
      driver: 'Driver Factor',
      edit: 'Edit',
      editing: 'Editing Benefit',
      expand_all: 'Expand All',
      financial: 'Financial Factor',
      financial_factor: 'Financial Factor',
      impacts: 'Impacts Which Business Areas',
      impacts_desc: 'Which areas of the business does this benefit impact? This may drive items like situations and assets for particular audience personas.',
      improve: 'Improvement',
      improvement: 'Improvement Factor',
      include: 'Include?',
      no_records_found: 'No records found',
      required: 'Required',
      save: 'Save',
      save_changes: 'Save Changes',
      status: 'Status',
      with_us: 'With Us',
      active: 'Active',
      soft: 'Soft',
      n_a: 'N/A',
      next: 'Next',
    },
    case_studies: {
      case_studies: 'Case Studies',
    },
    cashflow: {
      cashflow: 'Cash flow',
    },
    costs: {
      costs: 'Costs',
    },
    download: 'Asset Download',
    dq: {
      dq: 'Discovery Questions',
      next: 'Next',
      nothing: 'No discovery questions found',
      optional: 'Optional',
      required: 'Required',
      save: 'Save',
    },
    figures: {
      change_status: 'Click to change status',
      comment: 'Comment:',
      comments: 'Comments',
      figures: 'Key Figures',
      required: 'Required',
    },
    hide_help: 'Click to hide help',
    initiatives: {
      delete: 'Delete',
      initiatives: 'Initiatives',
    },
    log: {
      log: 'Activity Log',
    },
    /* 		"menu": {
					"account": "Account",
					"add_benefit": "Add Benefit",
					"advanced": "Advanced",
					"all_assumptions": "All Assumptions",
					"assets": "Assets",
					"benefits": "Benefits",
					"case_studies": "Case Studies",
					"cashflow": "Cashflow",
					"collateral": "Collateral",
					"costs": "Costs",
					"deployment_schedule": "Deployment Schedule",
					"discovery": "Discovery",
					"discovery_questions": "Discovery Questions",
					"initiatives": "Initiatives",
					"key_assumptions": "Key Assumptions",
					"key_figures_and_roi": "Key Figures & ROI",
					"logs": "Logs",
					"mission_statement": "Mission Statement",
					"models": "Models",
					"notes": "Notes",
					"problem": "Problem",
					"questions": "Questions",
					"roi": "ROI",
					"scalers": "Scalers",
					"scenario": "Scenario",
					"scorecard": "Scorecard",
					"share": "Share",
					"situations": "Situations",
					"solution": "Solution",
					"supporting_materials": "Supporting Materials",
					"tco": "TCO",
					"view_benefits": "View Benefits",
					"view_costs": "View Costs"
				}, */
    mission: {
      mission: 'Mission Statement',
    },
    notes: {
      notes: 'Notes',
    },
    refresh: 'Refresh Data',
    roi: {
      roi: 'ROI',
    },
    scorecard: {
      scorecard: 'Scorecard',
    },
    share: {
      desc: 'Share this Value Prop with a new or existing customer',
      share: 'Share',
    },
    show_help: 'Click to show help',
    tco: {
      tco: 'TCO',
    },
  },
};
