import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
	public config: any = {
		component: 'log',
		display_name: 'Log VP Dashboard',
		description: 'Log tab in vp dashboard top panel'
    }
    public trans: any = {
        logs: 'Logs',
        no_logs: 'No Logs Found',
        ch_logs: "Log of events that have taken place including who made what changes and when",
    }
}