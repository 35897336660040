import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { OtherReportsService, ReportType } from '@data/services/other-reports/other-reports.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OtherReportsTranslations } from './other-reports.translation';

@Component({
  selector: 'app-other-reports',
  templateUrl: './other-reports.component.html',
  styleUrls: ['./other-reports.component.scss']
})
export class OtherReportsComponent implements OnInit, OnDestroy {
  public reports: ReportType[] = [];
  public unsubscribe = new Subject<void>();
  public breadcrumbKeyValues: Breadcrumbs;
  public style2022$: Observable<boolean>;
  public strSearch = '';
  private searchTimeOutTIme = 300;
  private searchTimeOut: NodeJS.Timer;

  constructor(
    private otherReportsService: OtherReportsService,
    public trans: OtherReportsTranslations,
    private commonservice: CommonService,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
    ) { }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.otherReportsService.getReportTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(reportResult => this.reports = reportResult.result);
      this.getTranslations();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem("aid"),
      component: this.trans.config.component,
      lang: langAbbr,
			localTranslations: this.trans.trans

    };
    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.trans.trans = this.commonservice.mergeObject(this.trans.trans, res);

      this.breadcrumbKeyValues = {
        [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
        [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
        [this.trans.trans.other_reports.value]: { routerLink: '', active: true },
      };
    });
  }

  generateLink(reportType: ReportType) {
    const {url, params} = reportType;
    const token = sessionStorage.getItem('vcu');
    const userId = sessionStorage.getItem('uid');
    const serviceUrl = this.commonservice.getServiceUrl();
    return `${serviceUrl}${url}?user_id=${userId}&token=${token}${params ? '&' + params : ''}`;
  }

  searchReports(event: KeyboardEvent){
    clearTimeout(this.searchTimeOut);
    this.searchTimeOut = setTimeout(() =>{
    if(this.strSearch === '' || this.reports.length === 0) {
      this.otherReportsService.getReportTypes()
      .pipe(take(1))
      .subscribe(reportResult => this.reports = reportResult.result);
    } else {
     this.reports = this.reports.filter(report => report.name.toLowerCase().trim().includes(this.strSearch.toLowerCase().trim()))
    }
    }, this.searchTimeOutTIme)
  }

  clearSearch() {
    this.strSearch = '';
    this.otherReportsService.getReportTypes()
    .pipe(take(1))
    .subscribe(reportResult => this.reports = reportResult.result);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}