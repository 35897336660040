import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArrayByIndex',
})
export class FilterArrayByIndexPipe implements PipeTransform {
  transform(inputArr: any[], index: number): any[] {
    const outputArr = [
      ...inputArr.map((item) => {
        const outputName = item.name ?? item.metric_name;
        return { ...item, name: outputName };
      }),
    ];
    outputArr.splice(index, 1);
    return outputArr;
  }
}
