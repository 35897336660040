<div
	[ngClass]="{'p-inputswitch p-component': true, 'p-inputswitch-checked': checked, 'p-disabled': disabled, 'p-inputswitch-readonly': readonly, 'p-inputswitch-focus': focused}"
	[ngStyle]="style"
	[class]="styleClass"
	(click)="onClick($event, cb)"
	role="checkbox" [attr.aria-checked]="checked" style='width: 100px' [ngStyle]="{'background-color': checked ? onColor : offColor}">
	<div class="p-helper-hidden-accessible">
		<input #cb type="checkbox"
		[attr.id]="inputId"
		[attr.name]="name"
		[attr.tabindex]="tabindex"
		[checked]="checked"
		(change)="onInputChange($event)"
		(focus)="onFocus($event)"
		(blur)="onBlur($event)"
		[disabled]="disabled" />
	</div>
	<span class="p-inputswitch-slider">
		<div *ngIf="!checked" style='float: left; padding-top: 3px; padding-left: 25px; color: #fff;'>{{offLabel}}</div>
		<div *ngIf="checked" style='padding-top: 3px; padding-right: 30px; color: #fff; float: right;'>{{onLabel}}</div>
	</span>
</div>