import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TcoTablesTranslations } from './tco.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

interface TermTableItem {
  year: number;
  show: boolean;
}

interface TableCarousel {
  end: number;
  start: number;
}

interface Tco {
  current_state?: any;
  future_state?: any;
  total_un_fmt?: string;
  is_vmware?: boolean;
  name?: string;
  total?: string;
  expenses?: any[];
}

@Component({
  selector: 'app-tco',
  templateUrl: './tco.component.html',
  styleUrls: ['./tco.component.scss'],
})
export class TcoComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() contextualHelp: any[];
  @Input() valueProp: ValueProp;
  @Input() tabInit: number;
  @Input() showTranslate = false;
  @Input() showAllMetrics = false;
  @Input() embedded = false;

  @Output() showMoreCosts = new EventEmitter();

  fullImagePath: string;
  image_url: string;
  tcoLoader = false;
  tcoObj: Tco;
  tcoObjCurrent: Tco;
  tcoObjFuture: Tco;
  tcoObjTotal: Tco;
  isVmware = false;
  tco: Tco[] = [];
  terms: Array<number>;
  termItems: TermTableItem[];
  carouselData: TableCarousel = {
    start: 0,
    end: 4,
  };

  ngUnsubscribe = new Subject();
  feature76 = false;
  show_all_metrics = false;
  loading = false;

  constructor(private CommonService: CommonService, private ValuepropService: ValuepropService, private translationService: TranslationsV2Service, public trans: TcoTablesTranslations) {

    this.image_url = this.CommonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }
  ngOnInit() {
    this.feature76 = this.CommonService.checkFeature(76);

      this.getTranslations();
      this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
        this.getTranslations();
      });
      this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
        this.showTranslate = res;
      });
    this.loadTCO();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

getTranslations() {

 let langId = sessionStorage.getItem('language_type_id');
 let langAbbr = this.translationService.getLanguageAbbr(langId);

 let payload = {
   'account_id': sessionStorage.getItem('aid'),
   'component': this.trans.config.component,
   'lang': langAbbr
 };

 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
   this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);
 });
}

  loadTCO(): void {
    this.tcoLoader = true;
    setTimeout(() => {
      this.terms = Array(parseInt(this.valueProp['term'].toString(), 10))
        .fill(0)
        .map((x, i) => i);
      this.termItems = this.terms.map((num) => ({
        year: num,
        show: num < 5,
      }));

      const searchLimit = this.valueProp.id;
      this.ValuepropService.getTCO(searchLimit, this.showAllMetrics == true ? 1 : 0)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.tcoObj = response.result;
            this.tcoObjCurrent = {
              name: 'Annual TCO Current',
              total: this.tcoObj.current_state.total_un_fmt,
              expenses: this.tcoObj.current_state.expenses,
            };
            this.tcoObjCurrent = this.formObjWithTerms(this.tcoObjCurrent, this.tcoObj.current_state);

            this.tcoObjFuture = {
              name: 'Annual TCO Future',
              total: this.tcoObj.future_state.total_un_fmt,
              expenses: this.tcoObj.future_state.expenses,
            };
            this.tcoObjFuture = this.formObjWithTerms(this.tcoObjFuture, this.tcoObj.future_state);

            this.tcoObjTotal = {
              name: 'TCO Reduction/Saving',
              total: this.tcoObj.total_un_fmt,
            };
            this.tcoObjTotal = this.formObjWithTerms(this.tcoObjTotal, this.tcoObj);

            this.isVmware = this.tcoObj.is_vmware;
            this.tco = [this.tcoObjCurrent, this.tcoObjFuture, this.tcoObjTotal];
          }
          this.tcoLoader = false;
        });
    }, 500);
  }

  formObjWithTerms(tco, tcoObj): Tco {
    for (let i = 0, length = this.valueProp['term']; i < length; i++) {
      tco['yr' + (i + 1)] = tcoObj['yr' + (i + 1) + '_un_fmt'];
      tco['sec_yr' + (i + 1)] = tcoObj['yr' + (i + 1) + '_secondary_un_fmt'];
    }

    return tco;
  }

  funcGetWidth(len: number): { 'float': string; 'max- width': string; 'width': string } {
    const obj = { 'float': 'left', 'max- width': 100 / len + '%', 'width': '100%;' };
    return obj;
  }

  loadAllCosts(): void {
    this.showMoreCosts.emit(true);
    this.loadTCO();
  }

  moveCarousel(side: 'right' | 'left'): void {
    const startIndex = this.termItems.find((term) => term.show);
    const start = side === 'left' ? startIndex.year - 1 : startIndex.year + 1;
    const end = start + 5;
    this.carouselData = {
      end,
      start,
    };
    this.termItems = this.termItems.map(({ show, year }) => ({
      year,
      show: year >= start && year <= end,
    }));
  }

  refreshTranslation(): void {
    this.getTranslations();
  }
}
