import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { BenefitType, BenefitVCS, BenefitVCSMetric } from '@shared/models/benefit-type.model';
import { ValueProp } from '@shared/models/value-prop.model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BenefitsImplementation } from '../../benefits.implementation';
import { BenefitsImplentationTranslations } from '../../benefits.translation';

@Component({
  selector: 'app-benefit-panel-2022',
  templateUrl: './benefit-panel-2022.component.html',
  styleUrls: ['benefit-panel-2022.component.scss'],
})
export class BenefitPanel2022Component implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  showAddBenefit = false;
  solutionId: number;
  financialCount = 0;
  ngUnsubscribe$ = new Subject();
  showEditTranslate = false;
  canEdit = true;
  termLimit = null;
  termShow = 10;
  showGroup = (metrics: BenefitVCSMetric[], _forceGroupRefresh: boolean) => this.bI.showGroup(metrics);
  benefitsListByType: BenefitType[];
  benefitOnTheFlyPrivilege = this.commonService.checkPrivilege('6');
  disableEdits = this.commonService.checkFeature('151');

  constructor(
    private translationService: TranslationsV2Service,
    public trans: BenefitsImplentationTranslations,
    public bI: BenefitsImplementation,
    private valuePropService: ValuepropService,
    private commonService: CommonService,
    private rAS: ResultsAnalysisService
  ) {}

  ngOnInit(): void {
    this.getSolutionId();
    this.canEdit = this.valueProp.vp_can_edit;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showEditTranslate = res;
    });

    this.syncScreenWidth();
    this.bI.benefitsListByType$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.benefitsListByType = res;
    });

    this.bI.refreshVCNames$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.benefitsListByType.forEach((benefitType) => {
        benefitType.vcs.forEach((vc) => {
          vc.name = this.bI.benefitsList.find((item) => item.id === vc.id).name;
        })
      });
    });

    this.bI.benefitsUpdated$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((id) => {
      if (id === 'allExpand') {
        // setting this as default for now since there is an issue of factors being shared in multiple benefits.
        this.manualUpdateAll(true);
      } else {
        this.manualUpdateAll();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.bI.benefitFirstLoad = true;
    this.bI.expandAll = false;
  }

  @HostListener('window:resize', ['$event'])
  syncScreenWidth() {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 1440 && screenWidth > 1280) {
      this.termLimit = 7;
      this.termShow = 8;
    } else if (screenWidth <= 1280 && screenWidth >= 1100) {
      this.termLimit = 5;
      this.termShow = 6;
    } else if (screenWidth < 1100 && screenWidth >= 1000) {
      this.termLimit = 4;
      this.termShow = 5;
    } else if (screenWidth < 1000) {
      this.termLimit = 2;
      this.termShow = 3;
    } else {
      this.termLimit = null;
      this.termShow = 10;
    }
  }

  getTranslations() {
    const languageId = sessionStorage.getItem('language_type_id');
    const languageAbbr = this.translationService.getLanguageAbbr(languageId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: languageAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  toggleAddBenefit() {
    this.showAddBenefit = !this.showAddBenefit;
    this.rAS.refreshVCChart(this.valueProp, null);
  }

  getSolutionId() {
    this.valuePropService
      .readSolutionList(this.bI.valueProp.id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.length > 0) {
          this.solutionId = Number(response.result[0]['account_solution_id']);
        }
      });
  }

  checkFinancialBenefit(isFinancial: string, index: number, showBenefit: boolean) {
    if (index === 0) {
      this.financialCount = 0;
    }
    if (isFinancial === '1' && this.financialCount === 0 && showBenefit) {
      this.financialCount++;
      return true;
    } else {
      return false;
    }
  }

  savedBenefit() {
    this.toggleAddBenefit();
  }

  editValueCategory( benefitCategory ) {
    benefitCategory.alt_name = benefitCategory.alt_name ?? benefitCategory.name;
    benefitCategory.edit_value_category = !benefitCategory.edit_value_category;
  }

  closeValueCategoryEdit( benefitCategory ) {
    benefitCategory.edit_value_category = !benefitCategory.edit_value_category;
  }

  updateValueCategoryName( benefitCategory ) {
    benefitCategory.edit_value_category = !benefitCategory.edit_value_category;

    const params = {
      name: benefitCategory.alt_name,
      value_category_id: benefitCategory.value_category_id,
    };

    this.valuePropService
      .updateValueCategoryOverride(this.valueProp.id, params)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.valuePropService.refreshDashboard.next();
          this.bI.loadBenefits();
        }
        this.manualUpdateAll();
      });
  }

  drop(dropEvent: CdkDragDrop<BenefitVCS[]>, dropArray: BenefitVCS[]) {
    if (dropEvent.isPointerOverContainer === false) {
      return;
    }
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
    this.bI.saveDropEvent(dropArray);
  }

  private manualUpdate(id: string, expandAll: boolean) {
    let categoryId: string;
    let typeId: string;
    this.benefitsListByType?.forEach((type) => {
      type.vcs.forEach((category) => {
        const foundBenefit = category.metrics.find((metric) => metric.id === id);
        if (foundBenefit) {
          categoryId = category.id;
          typeId = type.id;
          this.updateValues(typeId, categoryId, expandAll);
        }
      });
    });
  }

  private updateValues(typeId: string, categoryId: string, expandAll: boolean): void {
    const newTypeInfo = this.bI.benefitsListByType.find((type) => type.id === typeId);
    if (newTypeInfo) {
      const typeToUpdate = this.benefitsListByType.find((currentType) => currentType.id === typeId);
      if (typeToUpdate) {
        const currentVCS = typeToUpdate.vcs.find((category) => category.id === categoryId);
        const updatedVCS = newTypeInfo.vcs.find((category) => category.id === categoryId);
        let out = [];
        for (let t = 1; t <= +updatedVCS.term; t++) {
          out.push({ label: 'Year ' + t, value: updatedVCS['vc_yr' + t + '_total_fmt'] });
          for (let mm = 0; mm < updatedVCS.metrics.length; mm++) {
            let out2 = [];
            for (let tt = 1; tt <= +updatedVCS.term; tt++) {
              out2.push({ label: 'Year ' + tt, value: updatedVCS.metrics[mm]['year' + tt + '_benefits_fmt'] });
            }
            const currentMetric = currentVCS.metrics.find((metric) => metric.id === updatedVCS.metrics[mm].id);
            currentMetric['years'] = out2;
          }
        }
        currentVCS.years = out;
        currentVCS.na_count = updatedVCS.na_count;
        currentVCS.active_count = updatedVCS.active_count;
        currentVCS.soft_count = updatedVCS.soft_count;
        currentVCS.vc_total_fmt = updatedVCS.vc_total_fmt;
        currentVCS.expand = expandAll ? updatedVCS.expand : currentVCS.expand;
      }
    }
  }

  private manualUpdateAll(expandAll = false): void {
    this.benefitsListByType?.forEach((type) => {
      type.vcs.forEach((category) => {
        category.metrics.forEach((metric) => this.manualUpdate(metric.id, expandAll));
      });
    });
  }
}
