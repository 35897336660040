<div class="accordion-wrapper">
  <p-accordion id="validation-accordion" [multiple]="true">
    <!-- Inconsistant Values (Factor Missmatch) -->
    <p-accordionTab [selected]="true" *ngIf="validations?.factor_mismatch.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ trans.trans.factorsWithInconsistentValues.value }}</span>
        <span [matTooltip]="trans.trans.inconsistentValuesTT.value" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-factor-validation-table [cancelTriggered$]="cancelTriggered$" (factorValueUpdate)="onValueUpdate($event)" [trans]="trans" [factors]="validations" [tableType]="tableTypes.INCONSISTENT_VALUES"></app-factor-validation-table>
      </ng-template>
    </p-accordionTab>
    <!-- VALUE OF 0 (Zero Factors) -->
    <p-accordionTab [selected]="true" *ngIf="validations?.zero_factors.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ trans.trans.factorValueOf0.value }}</span>
        <span [matTooltip]="trans.trans.valueOf0TT.value" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-factor-validation-table [cancelTriggered$]="cancelTriggered$" (factorValueUpdate)="onValueUpdate($event)" [trans]="trans" [factors]="validations" [tableType]="tableTypes.VALUE_OF_0"></app-factor-validation-table>
      </ng-template>
    </p-accordionTab>
    <!-- No Default Value (No Default Scalers) -->
    <p-accordionTab [selected]="true" *ngIf="validations?.no_default_scalers.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ trans.trans.scaleFactorNoDefault.value }}</span>
        <span [matTooltip]="trans.trans.noDefaultValueTT.value" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-factor-validation-table [cancelTriggered$]="cancelTriggered$" (factorValueUpdate)="onValueUpdate($event)" [trans]="trans" [factors]="validations" [tableType]="tableTypes.NO_DEFAULT_VALUE"></app-factor-validation-table>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
<div class="button-wrapper">
  <button class="btn btn_customizable pull-right" (click)="save()">{{trans.trans.save.value}}</button>
  <button class="btn cancel-confirm pull-right" (click)="cancel()">{{trans.trans.cancel.value}}</button>
</div>