<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
<ng-template #loadedContent>
  <div class="col-xs-4">
    <div class="input-group keywest_search search-container" *ngIf="!loading">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input type="text" class="form-control" [formControl]="searchField" />
      <span *ngIf="searchField.value !== ''" (click)="resetSearchField()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <div class="col-xs-8">
    <button class="btn btn_customizable pull-right" (click)="addProofPoint()"><i class="fa fa-plus"></i> {{ trans.trans.add.value }}</button>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <p-table #tableSuccessCriteria [value]="caseStudies" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              {{ trans.trans.proofPointName.value }}
              <p-sortIcon field="name"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr>
            <td class="col-name-success clickable" (click)="editCaseStudy(rowData)">
              {{ rowData.name }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2" class="text-center">{{ trans.trans.emptyTable.value }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-template>

<p-sidebar *ngIf="caseStudySidebar" [(visible)]="caseStudySidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-add-casestudy-realization
    [companyID]="companyId"
    [valuepropId]="valuePropId"
    [solutions]="modelList"
    [caseStudy]="selectedProofpoint"
    [canEdit]="canEdit"
    (callback)="closeProofPointSidebar($event)"
    (refreshSolutions)="refreshProofpoints()"
  ></app-add-casestudy-realization>
</p-sidebar>
