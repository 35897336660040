<div class="row" *ngIf="style2022$ | async">
    <div class="col-md-4 mr-auto breadcrumb-container">
      <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
    </div>
  </div>
  
  <div class="row" *ngIf="!(style2022$ | async)">
    <div class="col-md-10 old-breadcrumb">
      <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.back_to_profile.value }}</a>
    </div>
  </div>
  <div class="models_panel" style="margin-bottom: 20px; height: 4000px" id="solution-adoption-iframe-container">
    <div class="models_page_header_container_small" style="height: 100%">
      <div class="keywest_header_small">
        <div class="tab-container">
          <p-tabView style="height: 100%; margin-bottom: 4vh;" (onChange)="tabSwitch($event);">
            <p-tabPanel [header]="trans.trans.valueProps.value" style="display: flex; height: 100%; flex-direction: column;">
              <div style="height: 100%">
                  <iframe id="sigmaValuePropsiFrame" width="100%" height="100%" [src]="sigmaValuePropsUrl"
                  style="border: none; height: 3900px;"></iframe>
              </div>
            </p-tabPanel>
            <p-tabPanel [header]="trans.trans.modelDocumentation.value" style="display: flex; height: 100%; flex-direction: column;">
              <div style="height: 100%">
                  <iframe id="sigmaModelDocumentationiFrame" width="100%" height="100%" [src]="sigmaModelDocumentationUrl"
                  style="border: none; height: 2750px;"></iframe>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </div>
  </div>