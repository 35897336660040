import { NgModule } from '@angular/core';

import { AECollateralComponent } from "./ae_collateral.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 
import { RouterModule } from '@angular/router';

import { Translations } from '@data/services/translation/translation.service';
import { EditTranslationsModule} from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AECollateralTranslations } from './ae_collateral.translation';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		AECollateralComponent,
	],
	imports: [
		EditTranslationsModule,
		RouterModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule,
		AreYouSureModule
	],
	providers: [
		TranslationsV2Service,
		AECollateralTranslations,
		Translations
	],
	entryComponents: [
		AECollateralComponent,
	],
	exports: [AECollateralComponent]
})
export class AECollateralModule { }