import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterBenefits',
	pure: false
})
export class filterBenefitsPipe implements PipeTransform {
	transform(arr: any[], search: string, filter: string): any {
		if (!arr || !filter || !search) {
			return arr;
		}
		return arr.filter(item => item[filter].toLowerCase().indexOf(search.toLowerCase()) !== -1);
	}
}

@Pipe({
	name: 'filterStatus',
	pure: false
})
export class filterBenefitsStatusPipe implements PipeTransform {
	transform(arr: any[], active: boolean, na: boolean, soft: boolean): any {
		let out = [];
		for (let i = 0; i < arr.length; i++) {
			let elem = arr[i];
			if (active) {
				if (elem.active == "1") { out.push(elem); }
			}
			if (na) {
				if (elem.active == "0") { out.push(elem); }
				if (elem.active == "4") { out.push(elem); }
				if (elem.active == "5") { out.push(elem); }
			}
			if (soft) {
				if (elem.active == "3") { out.push(elem); }
			}
		}
		return out;
	}
}
