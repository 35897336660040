<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">
            {{ trans.scb.company | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <app-company-search [page]="page"></app-company-search>
      </div>
    </div>
  </div>
</div>
