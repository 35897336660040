import { Injectable } from '@angular/core';

@Injectable ()

export class UserProfileTranslation {

    public config: any = {
        component: 'userProfileComponent',
        display_name: 'Profile User Detail',
        description: 'Detail of a user in profile section'
    };

    public trans: any = {
        heading: 'Your Profile',
        edit: 'Edit',
        dashboard: 'Dashboard',
        profile: 'Profile',
        myTeam: 'My Team',
        myPrivileges: 'My Privileges',
        phone: 'Phone',
        email: 'Email',
        managerEmail: 'Manager Email',
        systemLanguage: 'System Language',
        currenyType: 'Currency Type',
        numberFormat: 'Number Format',
        editProfileInformation: 'Edit Profile Information',
        changePassword: 'Change Password',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        save: 'Save',
        cancel: 'Cancel',
        manager: 'Manager',
        editInfo: 'Edit Profile',
        langchangedSuccessful: 'Language changed successfully',
        currentPasswordRequired: 'Current password is required.',
        newPasswordRequired: 'New password is required',
        passwordNonMatch: 'Passwords do not match',
        team: 'Team',
        backToProfile: 'Back To Profile'
    };
}