<div class="keywest_header">
  <div class="row">
    <div class="col-xs-6">
      <div class="title">
        {{ trans.trans.scalers.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.scalers"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
    </div>
  </div>
</div>

<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

<ng-template #loadedContent>
  <div class="col-md-12">
    <div class="row m-b-xxl" *ngIf="canEdit">
      <div class="col-md-5">
        <p-multiSelect class="keywest_dropdown" [options]="scalers" [(ngModel)]="selectedItems" optionLabel="name"></p-multiSelect>
      </div>

      <div class="col-md-5">
        <button class="btn btn_customizable marg-h" [disabled]="selectedItems.length == 0" (click)="addScalersForEdit()">{{trans.trans.setDefault.value}}</button>
      </div>
    </div>

    <div class="row m-b-xxl">
      <div class="col-md-6">
        <form class="form-horizontal" name="editScalers" #editScalersForm="ngForm">
          <div class="form-group" *ngFor="let item of selectedScalers">
            <label class="col-sm-6 control-label">{{item.name}} <i *ngIf="item.description" class="icon-info tooltip-custom" [matTooltip]="item.description" matTooltipPosition="right"></i></label>
            <div class="col-sm-5">
              <p-inputNumber inputStyleClass="form-control" [minFractionDigits]="item.factor_precision || 0" [maxFractionDigits]="item.factor_precision || 0" placeholder="{{item.name}}" [name]="'scaler-' + item.scale_type_id" [ngModel]="item.value" [disabled]="!canEdit" [required]="true"></p-inputNumber>
            </div>
            <div class="col-md-1 m-t-xs">
              <app-are-you-sure *ngIf="canEdit" [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fa fa-trash' (callbackYes)='deleteScaler(item.scale_type_id)'></app-are-you-sure>
            </div>
          </div>
          <div class="form-group" *ngIf="selectedScalers.length > 0">
            <div class="col-sm-offset-3 col-sm-9">
              <button type="button" class="btn btn_customizable" *ngIf="canEdit" [disabled]="loadingSave" (click)="editScalers(editScalersForm)">{{trans.trans.save.value}}</button>
              <button type="button" class="btn btn-light marg-h" *ngIf="canEdit" [disabled]="loadingSave" (click)="resetFormValues(editScalersForm)">{{trans.trans.reset.value}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
