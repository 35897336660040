import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserAdoptionTranslation } from './user-adoption.translation';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-user-adoption',
  styleUrls: ['./user-adoption.component.scss'],
  templateUrl: './user-adoption.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UserAdoptionComponent implements OnInit, OnDestroy {

  toggleEdit = false;
  togglePreview = false;
  breadcrumbKeyValues: Breadcrumbs;

  account_id = '';

  showTranslate = false;
  ngUnsubscribe = new Subject<boolean>();

  loading = false;
  trans_toggle = false;
  toggleInactive = false;
  style99 = false;
  style2022$: Observable<Boolean>;
  originalTrans: { [klass: string]: string };

  sigmaUserAdoptionUrl: SafeUrl;
  sigmaModelDocumentationUrl: SafeUrl;
  sigmaValuePropsUrl: SafeUrl;

  constructor(
    private commonService: CommonService,
    public trans: UserAdoptionTranslation,
    private translationService: TranslationsV2Service,
    private accountService: AccountService,
    private router: Router,
    private styleService: StyleService,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.originalTrans = { ...this.trans.trans };
    this.account_id = sessionStorage.getItem('aid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getSigmaUserAdoptionUrl();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.analytics.value]: { routerLink: '', active: true },
        };
      });
  }

  getSigmaUserAdoptionUrl(): void {
    this.accountService.getSigmaUrl().subscribe((result) => {
        this.sigmaUserAdoptionUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.result.analytics_url);
    });
  }

}
