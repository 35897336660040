import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompanyLogoPipe } from './check-logo.pipe';
import { CompanyService } from '@data/services/company/company.service';

@NgModule({
  declarations: [CompanyLogoPipe],
  imports: [CommonModule],
  exports: [CompanyLogoPipe],
  providers: [CompanyService]
})
export class CheckLogoPipeModule {}
