<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>{{trans.trans.discoveryQuestions.value}}</div>
				</div>
			</div>
		</div>

		<p-fieldset id='required' [legend]="trans.trans.required.value" *ngIf="questionsRequired && questionsRequired.length">
			<div class='row' *ngFor="let question of questionsRequired">
				<div class='col-xs-12'>
					<label class="marg-v">{{question.name}} 
						<i *ngIf="question.description && !(style2022$ | async)" [matTooltip]="question.description" container="body" placement="top" class='fa fa-info-circle'></i>
						<span *ngIf="question.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="question.description" container="body" placement="top">
							info
						</span>
					</label>
					<span [ngSwitch]="question.question_type_id">
						<div *ngSwitchCase="1">
							<input type='text' [disabled]='locked' name='type1' [(ngModel)]="question.answer" class='form-control' />
						</div>
						<div *ngSwitchCase="2">
							<textarea name='type2' [disabled]='locked' [(ngModel)]="question.answer" rows='3' class='form-control'></textarea>
						</div>
						<div *ngSwitchCase="3">
							<p-inputSwitch name='type3' [disabled]='locked' [(ngModel)]="question.answer"></p-inputSwitch>
						</div>
						<p *ngSwitchDefault>

						</p>
					</span>
				</div>
			</div>
		</p-fieldset>

		<p-fieldset [legend]="trans.trans.dq.value" [toggleable]="true" [collapsed]='true' *ngIf="questionsOptional && questionsOptional.length">
			<div class='row' *ngFor="let question of questionsOptional">
				<div class='col-xs-12'>
					<label class="marg-v">{{question.name}} 
						<i *ngIf="question.description && !(style2022$ | async)" [matTooltip]="question.description" container="body" placement="top" class='fa fa-info-circle'></i>
						<span *ngIf="question.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="question.description" container="body" placement="top">
							info
						</span>
					</label>
					<span [ngSwitch]="question.question_type_id">
						<div *ngSwitchCase="1">
							<input type='text' [disabled]='locked' name='type1' [(ngModel)]="question.answer" class='form-control' />
						</div>
						<div *ngSwitchCase="2">
							<textarea name='type2' [disabled]='locked' [(ngModel)]="question.answer" rows='3' class='form-control'></textarea>
						</div>
						<div *ngSwitchCase="3">
							<p-inputSwitch name='type3' [disabled]='locked' [(ngModel)]="question.answer"></p-inputSwitch>
						</div>
						<p *ngSwitchDefault>

						</p>
					</span>
				</div>
			</div>
		</p-fieldset>

		<div *ngIf="!questionsRequired.length && !questionsOptional.length" class='row'>
			<div class='col-xs-12'><b>{{trans.trans.nothing.value}}</b></div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<img *ngIf="saveLoader" class="loader" src="{{fullImagePath}}" />
				<a *ngIf="!saveLoader && (questionsRequired.length || questionsOptional.length) && !locked" (click)='funcSaveQuestions()' class='btn btn_customizable'>{{trans.trans.save.value}}</a>
				<a *ngIf="!saveLoader && !questionsRequired.length && !questionsOptional.length && !locked" (click)='funcSaveQuestions()' class='btn btn_customizable'>{{trans.trans.next.value}}</a>
			</div>
		</div>
	</div>
</div>