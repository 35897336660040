import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { SituationsSharedModule } from '@shared_components/situations-shared/situations-shared.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KeyFiguresModule } from '../../../value-prop/value-prop-dashboard/key_figures/key_figures.module';
import { GuidedDiscoveryModule } from '../guided_discovery/guided-discovery.module';
import { AccountFormModule } from '../scenario_combined/views/account-form/account-form.module';
import { SCVPGComponent } from './sc_vpg.component';
import { ScenarioCombinedVPGTranslations } from './sc_vpg.translation';
import { SCVPGModelsModule } from './views/models/models.module';
import { SCVPGScalersModule } from './views/scalers/scalers.module';
import { InputNumberModule } from 'primeng/inputnumber';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    AccountFormModule,
    DLProgressbarModule,
    SCVPGModelsModule,
    SCVPGScalersModule,
    GuidedDiscoveryModule,
    ProgressBarModule,
    AreYouSureModule,
    TreeModule,
    NgbModule,
    MatTooltipModule,
    SidebarModule,
    DropdownModule,
    FieldsetModule,
    TableChartModule,
    InputNumberModule,
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    KeyFiguresModule,
    EditTranslationsModule,
    SituationsSharedModule,
    ValuePropFlowButtonsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  declarations: [SCVPGComponent],
  providers: [Translations, ScenarioCombinedVPGTranslations],
  exports: [SCVPGComponent],
})
export class SCVPGModule {}
