import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'cost',
    display_name: 'Rep Simple Costs',
    description: 'Costs tab in rep simple',
  };
  public trans: any = {
    costs: 'Costs',
    add_cost: 'Add Cost',
    ch_cost: 'The investment required to achieve the benefits in scope',
    Type: 'Type',
    Accrues: 'Accrues',
    UnitPrice: 'Unit Price',
    unitPrice: 'Unit Price',
    qty: 'QTY',
    GrandTotal: 'Grand Total',
    DISC: 'DISC',
    Subtotal: 'Subtotal',
    NoCostsFound: 'No Costs Found',
    rename: 'Rename',
    cost_text: 'Use the + button to add the Subscription, Implementation, Training or any other associated costs. Note that the Unit Price is Annual.',
    quantity: 'Quantity',
    discount: 'Discount',
    edit: 'Edit',
    delete: 'Delete',
    areYouSure: 'Are you sure?'
  };
}
