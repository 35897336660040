import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class AeGoalsTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'ae-goals',
    display_name: 'Add or Edit Goal',
    description: '',
  };

  public trans = this.typeObjectKeys({
    requestError: 'Something went wrong, please try again',
    goalUpdated: 'Goal updated successfully',
    goalSaved: 'Goal saved successfully',
    addGoal: 'Add Goal',
    editGoal: 'Edit Goal',
    nameLabel: 'Name',
    descriptionLabel: 'Description',
    goalGroupLabel: 'Goal Group',
    unitTypeLabel: 'Unit Type',
    precisionLabel: 'Precision',
    linkGoal: 'Link Goal to Factor',
    selectFactor: 'Select Factor',
    goalTypeLabel: 'Goal Type',
    iconLabel: 'Icon',
    save: 'Save',
    back: 'Back',
    alreadyLinked: 'Factor already linked to a Goal',
    cancel: 'Cancel',
    baseline: 'Baseline',
    target: 'Target',
    yes: 'Yes',
    no: 'No',
    keyFactor: 'Key Goal?'
  });
}
