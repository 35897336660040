import { Component, OnInit, HostListener, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddBenefitsModelComponent } from '../../add-benefits-model/add-benefits-model.component';
import { ScratchpadModalComponent } from '../../../common/scratchpad-modal/scratchpad-modal.component';
import { Subscription, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { BenefitDetailV1 } from './benefit-detail.translation';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DriverPhasing } from '@shared/models/value-prop.model';

@Component({
	selector: 'app-benefit-detail',
	templateUrl: './benefit-detail.component.html',
	styleUrls: ['./benefit-detail.component.scss']
})
export class BenefitDetailComponent implements OnInit, OnDestroy {
	@Input('data') valueMetric: any;
	@Input() valueProp: any;
	@Input() contextualHelp: object;
	@Output() closeBenefitDetail = new EventEmitter();
	@Output() refreshBenefit = new EventEmitter();
	@Input() selectedBenefit: any;
	@Input() embedded: boolean = false;

	showTranslate = false;
	ngUnsubscribe = new Subject();

	showGrowthDecline: boolean = false;
	showDeleteNote: boolean = false;

	screenWidth: string = "500px";
	editing_scratch: boolean = false;
	fullImagePath: string;
	image_url: string;
	benefitsLoader: boolean = false;
	benefitsDetail: any = [];
	benefit: any;
	benefitUpdates: any;
	benefitUpdateCopy: any;
	benefitOtherValues: any;
	modalReference: any;
	operand: any;
	units: any;
	selectedFactor: any;
	benefitsSubTypesList: Array<Object>;
	factorTypeId: any;
	subTypeFactorName: any;
	phasingDetails = {};
	metricPhase2: any;
	metricPhase3: any;
	hideMetricLoader: boolean = false;
	accountFeatures: boolean = false;
	canAddBenefit: boolean = false;
	showCurrentEffort: boolean = false;

	// Case Studies
	caseStudiesLoader: boolean = false;
	benefitCaseStudies: Array<any> = [];
	driverPhasing: DriverPhasing;

	privileges: any;


	subscription: Subscription = new Subscription();
	utype: any;

	subscriptiongetValuePropBenefits: Subscription;
	subscriptionupdateBenefitDriver: Subscription;
	subscriptionupdateBenefitFinancial: Subscription;
	subscriptionupdateBenefitImp: Subscription;
	subscriptionupdateBenefitCurrentEffort: Subscription;
	subscriptiongetValuePropMetric_DriverFactorSubTypes: Subscription;
	subscriptiongetDriverGrowth: Subscription;
	subscriptionupdateMetricAltName: Subscription;
	subscriptionupdateMetricNote: Subscription;
	subscriptiondeleteMetricNote: Subscription;
	subscriptionupdateDrivingPhase: Subscription;
	subscriptiondeleteCustomBenefit: Subscription;
	subscriptionreadCaseStudyByValuePropMetricId: Subscription;
	cols: any[] = [];
	tableData: any[] = [];
	totalColumns: number = 1;
	selectedColumns: any[] = [];

	menuDetails: any[] = [];
	hasSubtypes: boolean = false;
	showSubtypes: boolean = false;
	feature14: boolean = false;

	company_name: string = "";

	// feature14: boolean = false;

	activeTypes: any = [
		{
			'name': 'Active',
			'value': '1',
		},
		{
			'name': 'N/A',
			'value': '0',

		},
		{
			'name': 'Considered Soft',
			'value': '3',

		}
	];

	constructor(
		private commonService: CommonService, private modalService: NgbModal,
		private ValuepropService: ValuepropService,
		private NotificationService: NotificationService,
		private translateService: TranslateService,
		public trans: Translations,
		public trans2: BenefitDetailV1,
		private translationService: TranslationsV2Service
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);

		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	metricbenefits: any;

	scratchpadOpenParens: Array<Object> = [
		{
			abbr: '',
			value: '0'
		},
		{
			abbr: '(',
			value: '1'
		}
	];

	scratchpadCloseParens: Array<Object> = [
		{
			abbr: '',
			value: '0'
		},
		{
			abbr: ')',
			value: '1'
		}
	];

	@HostListener('window:resize', ['$event']) onResize(event) {
		if (event) {
			this.calcScreenWidth();
		}
	}

	ngOnInit() {

		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
		  this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
		  this.showTranslate = res;
		});
		localStorage.setItem("vpdashboardMenuBottom", "benefit_detail");

		let featuresOptions = sessionStorage.getItem('features');
		let featuresopt = featuresOptions.split(',');
		if (featuresopt.indexOf('14') >= 0) {
			this.feature14 = true;
		}

		this.company_name = sessionStorage.getItem("account_name");



		this.calcScreenWidth();
		const privs = sessionStorage.getItem('privileges');
		let privileges = privs.split(",");
		if (privileges.length) {
			if (privileges.indexOf('6') >= 0) {
				this.canAddBenefit = true;
			}
		}

		this.accountFeatures = this.checkFeatures();

		// let featuresOptions = sessionStorage.getItem('features');
		// let featuresopt = featuresOptions.split(',');
		// if (featuresopt.indexOf('14') >= 0) {
		// 	this.feature14 = true;
		// 	}
		if (this.selectedBenefit && !this.valueMetric) { this.valueMetric = this.selectedBenefit; }
		this.loadBenefits();



		// this.selectedBenefit = null;
	}

	ngOnDestroy() {

		if (this.subscription) { this.subscription.unsubscribe(); }

		if (this.subscriptiongetValuePropBenefits) { this.subscriptiongetValuePropBenefits.unsubscribe(); }
		if (this.subscriptionupdateBenefitDriver) { this.subscriptionupdateBenefitDriver.unsubscribe(); }
		if (this.subscriptionupdateBenefitFinancial) { this.subscriptionupdateBenefitFinancial.unsubscribe(); }
		if (this.subscriptionupdateBenefitImp) { this.subscriptionupdateBenefitImp.unsubscribe(); }
		if (this.subscriptionupdateBenefitCurrentEffort) { this.subscriptionupdateBenefitCurrentEffort.unsubscribe(); }
		if (this.subscriptiongetValuePropMetric_DriverFactorSubTypes) { this.subscriptiongetValuePropMetric_DriverFactorSubTypes.unsubscribe(); }
		if (this.subscriptiongetDriverGrowth) { this.subscriptiongetDriverGrowth.unsubscribe(); }
		if (this.subscriptionupdateMetricAltName) { this.subscriptionupdateMetricAltName.unsubscribe(); }
		if (this.subscriptionupdateMetricNote) { this.subscriptionupdateMetricNote.unsubscribe(); }
		if (this.subscriptiondeleteMetricNote) { this.subscriptiondeleteMetricNote.unsubscribe(); }
		if (this.subscriptionupdateDrivingPhase) { this.subscriptionupdateDrivingPhase.unsubscribe(); }
		if (this.subscriptiondeleteCustomBenefit) { this.subscriptiondeleteCustomBenefit.unsubscribe(); }
		if (this.subscriptionreadCaseStudyByValuePropMetricId) { this.subscriptionreadCaseStudyByValuePropMetricId.unsubscribe(); }
		// this.ValuepropService.refreshDashboard.next('benefitChange');

	}

	getTranslations() {

		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans2.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans2.trans
		};

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	   this.trans2.trans = this.commonService.mergeObject(this.trans2.trans, res);
	 });
	}
	funcGotoMenu() {
		this.ValuepropService.gotoMenuItem.next({ panel: "vpdashboardMenuBottom", menu: "benefits", data: null })

	}
	statusChange() {
		const payload = {
			"value_prop_metric_id": this.benefit.id,
			"active": this.benefit.active
		}
		this.benefitsLoader = true;
		this.ValuepropService.updateMetricActive(this.valueProp.id, payload).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// if (this.dealdesk) {
				// }
				this.ValuepropService.refreshDashboard.next('no_reload');
				// this.ValuepropService.refreshBenefits.next();
				this.NotificationService.success("Benefit status updated Successfully", false);
			}
			else if (response.result.success === false) {
				this.NotificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.benefitsLoader = false;
		});
	}


	funcSetupMenu() {
		this.menuDetails = [];
		if (this.hasSubtypes && this.valueProp.vp_can_edit) {
			this.menuDetails.push({ label: this.trans.vp_dashboard.benefits.sub_types, command: () => { this.showSubtypes = !this.showSubtypes } });
		}

		if (!this.benefit.metric_note && this.valueProp.vp_can_edit) {
			this.menuDetails.push({ label: this.trans.vp_dashboard.benefits.add_note, command: () => { this.benefit.showeditable_notes = !this.benefit.showeditable_notes } });
		}
		if (this.benefit.metric_note) {
			this.menuDetails.push({ label: this.trans.vp_dashboard.benefits.edit_note, command: () => { this.benefit.showeditable_notes = !this.benefit.showeditable_notes } });
			this.menuDetails.push({ label: this.trans.vp_dashboard.benefits.delete_note, command: () => { this.showDeleteNote = !this.showDeleteNote } });
		}
		this.menuDetails.push({ label: this.trans.vp_dashboard.benefits.growth, command: () => { this.updateDetailsPhasingModal(); this.showGrowthDecline = !this.showGrowthDecline } });
	}

	calcScreenWidth() {
		// fix table horizontal scroll
		let tmp = document.getElementById("width");
		this.screenWidth = tmp.offsetWidth + "px";
	}
	checkFeatures() {
		let features = sessionStorage.getItem('features');
		let options = features.split(',');
		if (options.indexOf('14') >= 0) {
			return true;
		}
		return false;
	}

	loadBenefits() {
		this.tableData = [];
		let searchLimit = this.valueProp.id;
		this.benefitsLoader = true;
		this.subscriptiongetValuePropBenefits = this.ValuepropService.getValuePropBenefits(searchLimit, 1, this.valueMetric.id).subscribe((response) => {
			if (response.result) {
				this.benefit = response.result;

				if (this.benefit.has_subtypes) {
					this.hasSubtypes = true;
				}

				if (!this.benefit.metric_note) { this.benefit.metric_note = ""; }
				this.funcSetupMenu();




				this.checkCurrentEffort(this.benefit.unit_type_id, this.benefit.impact_type_id);
				this.benefitUpdates = {
					"driver_value": this.benefit.driver_value,
					"financial_value": this.benefit.financial_value,
					"impact": this.benefit.impact,
					"current_effort": this.benefit.current_effort
				}
				this.benefitUpdateCopy = {
					"driver_value": this.benefit.driver_value_fmt,
					"financial_value": this.benefit.financial_value_fmt,
					"impact": this.benefit.impact_fmt,
					"current_effort": this.benefit.current_effort_fmt
				}
				this.benefitOtherValues = {
					"value_prop_metric_id": this.benefit.id,
					"driver_factor_id": this.benefit.driver_factor_id,
					"driver_source_type_id": this.benefit.driver_source_type_id,
					"financial_factor_id": this.benefit.financial_factor_id,
					"financial_source_type_id": this.benefit.financial_source_type_id,
					"id": this.benefit.id,
					"unit_type_id": this.benefit.unit_type_id
				}

				this.benefitsLoader = false;
				this.cols = [];
				this.selectedColumns = [];
				this.cols.push({ field: "formula", header: this.trans.vp_dashboard.benefits.formula, width: "450px", editable: false });

				this.cols.push({ field: "calc", header: "+ / -", width: "40px", editable: false });
				if (!this.embedded) {
					if (this.showCurrentEffort && this.feature14) { this.cols.push({ field: "cost", header: this.trans.vp_dashboard.benefits.current_effort, width: "120px", editable: true }); }
					if (this.showCurrentEffort && this.feature14) { this.cols.push({ field: "future_effort", header: "With " + (this.company_name ? this.company_name : "us"), width: "120px", editable: true }); }
					for (let i = 1; i <= this.valueProp['term']; i++) {
						// let year = { field: "year" + i, header: "Year " + i, width: "120px", editable: (i == 1 ? true : false) }
						// this.cols.push(year);
						let year = { field: "year" + i, header: "Year " + i, width: "120px", editable: (i == 1 ? true : false) }
						this.cols.push(year);
					}
				}
				this.selectedColumns = this.cols;


				this.totalColumns = this.valueProp['term'];

				this.tableData = [];

				// driver
				let r = {};
				r['factorTypeId'] = 1;
				r['hasScratchpad'] = this.benefit.has_driver_scratchpad == 1 ? true : false;
				r['formula'] = this.benefit.driver_factor;
				// r['cost'] = this.benefit.current_effort_fmt;
				r['cost'] = "";
				r['editable'] = true;
				r['type'] = "driver";

				r['driver_factor_id'] = this.benefit.driver_factor_id;
				r['driver_source_type_id'] = this.benefit.driver_source_type_id;
				r['driver_override'] = this.benefit['year' + 1 + '_driver'];
				for (let i = 1; i <= this.valueProp['term']; i++) {
					r['year' + i + '_fmt'] = this.benefit['year' + i + '_driver_fmt'];
					r['year' + i] = i == 1 ? this.benefit['year' + i + '_driver'] : this.benefit['year' + i + '_driver_fmt'];
				}
				this.tableData.push(r);

				this.tableData.push({ formula: "", cost: "", calc: "x", editable: false });
				// improvement
				r = {};
				r = { formula: "Improvement" + " (" + this.benefit.unit_type + ")" };
				r['factorTypeId'] = 3;
				r['hasScratchpad'] = this.benefit.has_improvement_scratchpad == 1 ? true : false;
				r['editable'] = true;
				if (this.feature14) {
					r['cost'] = this.benefit.current_effort_fmt;
					r['cost_fmt'] = this.benefit.current_effort_fmt;
				}
				r['type'] = "improvement";
				r['future_effort'] = this.benefit.future_effort;
				r['future_effort_fmt'] = this.benefit.future_effort_fmt;
				r['improvement_factor_id'] = this.benefit.improvement_factor_id;
				r['impact'] = this.benefit.impact_fmt;
				r['impact_source_type_id'] = "1";
				r['value_prop_metric_id'] = this.benefit.value_prop_metric_id;

				for (let i = 1; i <= this.valueProp['term']; i++) {
					r['year' + i + '_fmt'] = this.benefit.impact_fmt + this.benefit.abbr;
					r['year' + i] = i == 1 ? this.benefit.impact_fmt : this.benefit.impact_fmt + this.benefit.abbr;
					// + this.valueMetric.abbr;
				}
				this.tableData.push(r);


				if (this.benefit.financial_factor) {
					this.tableData.push({ formula: "", cost: "", calc: "x", editable: false });
					r = {};
					r = { formula: this.benefit.financial_factor };
					r['factorTypeId'] = 2;
					r['hasScratchpad'] = this.benefit.has_financial_scratchpad == 1 ? true : false;
					r['editable'] = true;
					r['type'] = "financial";

					r['financial_factor_id'] = this.benefit.financial_factor_id;
					r['financial_override'] = this.benefit.financial_override;
					r['financial_source_type_id'] = this.benefit.financial_source_type_id;
					// r['cost'] = this.benefit.current_effort_fmt;
					r['cost'] = "";
					for (let i = 1; i <= this.valueProp['term']; i++) {
						r['year' + i + '_fmt'] = this.benefit.financial_value;
						// r['year' + i + '_fmt'] = this.benefit.financial_value_fmt;
						r['year' + i] = this.benefit.financial_value;
					}
					this.tableData.push(r);
				}
				if (!this.embedded) {
					this.tableData.push({ formula: "", cost: "", calc: "x", editable: false });
					r = {};
					r = { formula: "Phasing" };
					// r['cost'] = this.benefit.current_effort_fmt;
					r['cost'] = "";
					r['editable'] = false;
					r['hasScratchpad'] = false;
					for (let i = 1; i <= this.valueProp['term']; i++) {
						r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
						r['year' + i] = this.benefit['metric_phase_' + i] + "%";
					}
					this.tableData.push(r);

					this.tableData.push({ formula: "", cost: "", calc: "=", editable: false });

					r = {};
					r = { formula: "Total" };
					// r['cost'] = this.benefit.current_effort_fmt;
					r['cost'] = "";
					r['type'] = 'total';
					r['hasScratchpad'] = false;
					r['editable'] = false;
					for (let i = 1; i <= this.valueProp['term']; i++) {
						r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
						r['year' + i] = this.benefit['year' + i + '_benefits_fmt'];
					}
					this.tableData.push(r);
				}

				this.benefitsDetail = [];
				for (let i = 1; i <= this.valueProp['term']; i++) {
					let benefit = {
						driver_value: this.benefit['year' + i + '_driver'],
						driver_value_fmt: this.benefit['year' + i + '_driver_fmt'],
						impact: this.benefit.impact_fmt,
						financial_value: this.benefit.financial_value_fmt,
						metric_phase_1: this.benefit['metric_phase_' + i],
						benefits: this.benefit['year' + i + '_benefits_fmt'],
						current_effort: this.benefit.current_effort_fmt,
						unit_type: this.benefit.unit_type_id,
						driver_factor: i == 1 ? this.benefit.driver_factor : '-',
						financial_factor: i == 1 ? this.benefit.financial_factor : '-',
						future_effort_fmt: this.benefit.future_effort_fmt
					};
					this.benefitsDetail.push(benefit);
				}
			}
		});
	}

	funcCalcCostWithYear(row, changed) {
		if ((changed == "cost") || (changed == "future_effort")) {
			row.year1 = parseFloat(row.cost) - parseFloat(row.future_effort);
		} else {
			row.future_effort = parseFloat(row.cost) - parseFloat(row.year1);
		}
	}

	funcShowHideInput(row, col) {
		//
		//
		if ((row.type == "improvement") && ((col.field == "cost") || (col.field == "future_effort"))) { return true; }
		if (col.field == "year1") { return true; }
		return false;
	}

	checkCurrentEffort(unit_type, impact_type) {
		const utype = parseInt(unit_type);


		if ((impact_type == 1) || (utype === 8) || (utype === 9) || (utype === 10) || (utype === 11) || (utype === 12) || (utype === 13)) {
			this.showCurrentEffort = true;
		} else {
			this.showCurrentEffort = false;
		}
	}

	setData(response) {
		// this.selectedBenefit = null;
		if (response.result && (response.result.success !== false)) {
			// this.ValuepropService.refreshDashboard.next({ update: 'benefitChange', benefit: this.valueMetric });
			if (this.embedded) {
				this.refreshBenefit.emit();
			}
			// this.loadBenefits();
		}
		else if (response.result.success === false) {
			this.NotificationService.error(response.result.message, false); // Params {message, islogout}
		}
		if (this.embedded) { this.ValuepropService.refreshBenefits.next(''); }
		this.ValuepropService.setSelectedBenefit.next(this.valueMetric);
		localStorage.setItem("vpdashboardMenuBottom", "benefit_detail");
		this.ValuepropService.refreshDashboard.next('no_reload');

	}

	updateBenefit() {
		let payload;
		let factors = this.tableData.filter(x => (x.updated == true) || (x.cost_updated == true) || (x.future_effort_updated == true));
		let payload_costs;

		for (let f = 0; f < factors.length; f++) {
			switch (factors[f].type) {
				case "driver":
					factors[f].driver_value = factors[f].year1;
					break;
				case "financial":
					factors[f].financial_value = factors[f].year1;
					break;
				case "improvement":
					factors[f].impact = factors[f].year1;
					factors[f].improvement = factors[f].year1;
					factors[f].value_prop_metric_id = factors[f].value_prop_metric_id;
					if ((factors[f].cost_updated == true) || (factors[f].future_effort_updated == true)) {
						payload_costs = {
							"value_prop_metric_id": factors[f].value_prop_metric_id,
							"current_effort": factors[f].cost,
							"future_effort": factors[f].future_effort

						};
					}
					break;
			}
		}

		if (factors.length) {
			payload = {
				factors: factors,
				costs: payload_costs
			};
			this.ValuepropService.updateAllFactors(this.valueProp.id, payload).subscribe((res) => {
				this.setData(res);
			});
		}
	}

	closeBenefit() {
		this.benefitUpdates = this.benefitUpdateCopy;
	}

	open(content) {
		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}

	//	openSubTypesModal(benefits) {
	//		const modalReference = this.modalService.open(SubTypesComponent, { windowClass: 'deleteModal', size: 'lg', backdrop: "static", keyboard: false });
	//		modalReference.componentInstance.valueMetric = this.valueMetric;
	//		modalReference.componentInstance.valueProp = this.valueProp;
	//		modalReference.componentInstance.selectedSubTypeFactor = benefits;
	//		modalReference.result.then((result) => {
	//		}, (reason) => {
	//		});
	//	}

	updateScratchpadModal(obj, factor_type_id) {
		this.selectedFactor = obj;

		const modalReference = this.modalService.open(ScratchpadModalComponent, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		modalReference.componentInstance.factorTypeId = factor_type_id;
		modalReference.componentInstance.valuePropId = this.valueProp['id'];
		modalReference.componentInstance.valueMetric = this.valueMetric;
		// this.open(content);
	}

	updateSubTypeModal(benefits, content) {
		this.open(content);
		this.valueMetric;
		this.valueProp;
		this.selectedFactor = benefits;
		this.subscriptiongetValuePropMetric_DriverFactorSubTypes = this.ValuepropService.getValuePropMetric_DriverFactorSubTypes(this.valueProp.id, this.valueMetric.value_prop_metric_id).subscribe((response) => {
			if (response.result) {
				this.benefitsSubTypesList = response.result;
				//this.subTypeFactorName = response.result.scratchpad.factor_name;
			}
		});
	}

	// removed param for linting perposes because the open function is commented below. However, this is to open growthDeclineModal if need agian.
	updateDetailsPhasingModal() {
		this.subscriptiongetDriverGrowth = this.ValuepropService.getDriverGrowth(this.valueProp.id, this.valueMetric.value_prop_metric_id).subscribe((response) => {
			if (response.result.driver_phasing) {
				// this.open(content);
				this.driverPhasing = response.result.driver_phasing;
				this.metricbenefits = Object.keys(response.result.driver_phasing);
				// this.phasingDetails;
			}
		});
	}

	updateAltMetricName() {
		var params = {
			"alt_metric_name": (this.benefit.alt_metric_name == "") ? this.benefit.metric_name : this.benefit.alt_metric_name,
			"value_prop_metric_id": this.valueMetric.value_prop_metric_id,
		};

		this.subscriptionupdateMetricAltName = this.ValuepropService.updateMetricAltName(this.valueProp.id, params).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshBenefits.next();
				if (this.embedded) {
					this.refreshBenefit.emit();
				}
				this.NotificationService.success("Successfully saved your metric name", false);
				this.loadBenefits();
			}
			else if (response.result.success === false) {
				this.NotificationService.error(response.result.message, false); // Params {message, islogout}
			}
			else if (response.result.errors) {
				this.NotificationService.error("Something went wrong: " + response.result.errors.reason, false);
			}
		});
	}

	saveMetricNote() {
		let payload = {
			"value_prop_metric_id": this.valueMetric.value_prop_metric_id,
			"metric_note": this.benefit.metric_note
		}
		this.subscriptionupdateMetricNote = this.ValuepropService.updateMetricNote(this.valueProp.id, payload).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshDashboard.next('benefitChange');
				this.NotificationService.success("Successfully saved your metric name", false);
				this.loadBenefits()
			}
			else if (response.result.success === false) {
				this.NotificationService.error(response.result.message, false); // Params {message, islogout}
			}
			else if (response.result.errors) {
				this.NotificationService.error("Your Note did not save because of: " + response.result.errors.reason, false);
			}
			this.funcSetupMenu();
		});
	}

	deleteMetricNote() {
		this.subscriptiondeleteMetricNote = this.ValuepropService.deleteMetricNote(this.valueProp.id, this.valueMetric.value_prop_metric_id).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshDashboard.next('benefitChange');
				this.NotificationService.success("Successfully deleted your benefit note", false);
				this.loadBenefits();
			}
			else if (response.result.success === false) {
				this.NotificationService.error(response.result.message, false); // Params {message, islogout}
			}
			else if (response.result.errors) {
				this.NotificationService.error("Something went wrong: " + response.result.errors.reason, false);
			}
			this.showDeleteNote = !this.showDeleteNote;
			// this.modalReference.close();
			this.funcSetupMenu();

		});
	}

	saveMetricPhase(type?) {
		this.hideMetricLoader = true;
		let payload = {
			"value_prop_metric_id": this.valueMetric.value_prop_metric_id,
			"apply_all": (type === 'applyToAll') ? 1 : 0
		}
		this.metricbenefits.forEach(element => {
			payload[element] = this.driverPhasing[element];
		});

		this.subscriptionupdateDrivingPhase = this.ValuepropService.updateDrivingPhase(this.valueProp.id, payload).subscribe((response) => {
			if (response.result.errors) {
				this.NotificationService.error("Something went wrong: ", false);
			}
			else {
				this.ValuepropService.refreshDashboard.next('no_reload');
				this.NotificationService.success("Successfully saved", false);
				this.hideMetricLoader = false;
				// this.modalReference.close();
				this.loadBenefits();
			}
			this.showGrowthDecline = !this.showGrowthDecline;
		});

	}
	changePhase(value, element) {
		this.metricbenefits[value] = element.from;
	}

	openUpdateBenefit() {
		const modalReference = this.modalService.open(AddBenefitsModelComponent, { windowClass: 'deleteModal', size: 'lg', backdrop: "static", keyboard: false });
		modalReference.componentInstance.valuePropId = this.valueProp.id;
		modalReference.componentInstance.selectedBenefit = this.valueMetric;
	}

	opendeleteCustomBenefit(content) {
		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
	}



	showCaseStudies(content) {
		this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: "static", keyboard: false });

		this.caseStudiesLoader = true;
		this.subscriptionreadCaseStudyByValuePropMetricId = this.ValuepropService.readCaseStudyByValuePropMetricId(this.valueProp.id, this.valueMetric.value_prop_metric_id).subscribe((response) => {
			if (response.result) {
				this.benefitCaseStudies = response.result;
				this.caseStudiesLoader = false;
			}
		});
	}

	toggleSubTypesForm() {
		this.showSubtypes = !this.showSubtypes
	}
}
