<div style="padding: 0px 15px">
  <label>{{ trans.trans.sectionTitle.value }}</label>
  <app-editTranslation
    *ngIf="showTranslate"
    [isTranslationV2]="true"
    [component]="trans.config.component"
    [transObj]="trans.trans.sectionTitle"
    (callback)="getTranslations()"
  ></app-editTranslation>
  
  <app-workflow-questions [questions]="questions" [isPreview]="false"></app-workflow-questions>

  <div class="btn_container">
    <div class="row">
      <div class="col pull-right">
        <button class="btn btn_customizable" (click)="goBack()">
          {{trans.trans.cancel.value}}
        </button>
        <button class="btn btn_customizable" [disabled]="!questions || !canSave" (click)="onSaveAnswers()">
          {{trans.trans.reviewRecomendations.value}}
        </button>
      </div>
    </div>
  </div>
</div>
