import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { UserService } from '@data/services/user/user.service';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';

@Component({
  selector: 'app-choose-adventure-dialog',
  templateUrl: './choose-adventure-dialog.component.html',
  styleUrls: ['./choose-adventure-dialog.component.scss']
})
export class ChooseAdventureDialogComponent implements OnInit {
  @Input() navigationRoute: string;
  @Input() showStepSelection = true;
  @Input() trans: any
  @Input() existingVp = false;
  @Input() workflows: ConversationWorkflow[] = [];
  
  constructor(
    private commonService: CommonService, 
    private userService: UserService, 
    private router: Router,
    private configurableWorkflowService: ConfigurableWorkflowService
    ) { }

  ngOnInit(): void {}

  public onWorkflowSelected(wf: ConversationWorkflow) {
    this.configurableWorkflowService.selectedConversationWorkflow$.next(wf);
    this.showStepSelection = false;
    if(!this.existingVp) {
      this.funcGetAddRoute('valueposition');
    } else {
      this.router.navigate([this.navigationRoute]);
    }
  }

  public onProcessSelected(process: 'express' | 'standard', valueposition: string,) {
    const ff134 = this.commonService.checkFeature('134');
    if(process === 'standard') {
      if(ff134) {
        this.userService.replaceFF134$.next(true);
      } 
      const features = sessionStorage.getItem('features').split(',');
      const newFeatures = features.filter(f => f !== '134');
      if(newFeatures.length) {
        const featuresString = newFeatures.join(',');
        sessionStorage.setItem('features', featuresString);
      }
    } else {
      if(!ff134) {
        const features = sessionStorage.getItem('features').split(',');
        features.push('134');
        sessionStorage.setItem('features', features.join(','));
      }
    }
    this.showStepSelection = false;
    if(!this.existingVp) {
      this.funcGetAddRoute('valueposition');
    } else {
      this.router.navigate([this.navigationRoute]);
    }
  }

  private funcGetAddRoute (incoming): void {
    this.router.navigate([this.commonService.getCreateVPRoute(incoming)]);
  }
}