import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Owner } from '@shared/models/owner.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-release-to-partners',
  templateUrl: './release-to-partners.component.html',
  styleUrls: ['./release-to-partners.component.scss'],
  providers: [AccountService],
})
export class ReleaseToPartnersComponent implements OnInit, OnDestroy {
  @Input() solutionId: number;

  releaseTypes: Array<{ id: string | number; name: string }> = [];
  releaseTypeId: string | number;
  partnerTypeList: Owner[] = [];
  searchText: string;
  selectedPartnerList: Array<string> = [];
  selectedPartnerTypeList: Array<string> = [];

  subscriptiongetReleaseTypes: Subscription;
  subscriptiongetPartnersFieldData: Subscription;
  subscriptionrealeaseToPartners: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private factsService: FactsService,
    private accountService: AccountService,
    private notification: NotificationService,
    private solutionService: SolutionService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.getReleaseTypes();
    this.getPartnerTypes();
  }

  ngOnDestroy() {
    if (this.subscriptiongetReleaseTypes) {
      this.subscriptiongetReleaseTypes.unsubscribe();
    }
    if (this.subscriptiongetPartnersFieldData) {
      this.subscriptiongetPartnersFieldData.unsubscribe();
    }
    if (this.subscriptionrealeaseToPartners) {
      this.subscriptionrealeaseToPartners.unsubscribe();
    }
  }

  getReleaseTypes() {
    this.subscriptiongetReleaseTypes = this.factsService.getReleaseTypes().subscribe((response) => {
      if (response.result) {
        this.releaseTypes = response.result.releaseTypes;
      }
    });
  }

  getPartnerTypes() {
    const accId = sessionStorage.getItem('aid');
    this.subscriptiongetPartnersFieldData = this.accountService.getPartnersFieldData(accId, 'accoutnPartners').subscribe(
      (result) => {
        if (result['result']['data'] != null) {
          this.partnerTypeList = result['result']['data'];
        }
      },
      (error) => this.notification.error(error['result'], false)
    );
  }

  selectPartnerTypes(releaseTypeId: string | number) {
    this.releaseTypeId = releaseTypeId;
    // tslint:disable-next-line: triple-equals
    if (releaseTypeId == '3' || releaseTypeId == '4') {
      this.resetPartners();
    }
  }

  resetPartners() {
    if (this.partnerTypeList) {
      this.partnerTypeList.forEach((item) => {
        item['selectedPartner'] = false;
      });
    }
  }

  realeaseToPartners() {
    this.selectedPartnerList = [];
    if (this.partnerTypeList) {
      this.partnerTypeList.forEach((item) => {
        if (item['selectedPartner']) {
          this.selectedPartnerList.push(String(item['id']));
          this.selectedPartnerTypeList.push(item['PartnerTypeId']);
        }
      });
      const payload = {
        account_solution_id: this.solutionId,
        release_type_id: this.releaseTypeId,
        account_partner_id: this.selectedPartnerList.join(','),
        partner_type_id: this.selectedPartnerTypeList.join(','),
      };

      this.subscriptionrealeaseToPartners = this.solutionService.realeaseToPartners(payload).subscribe((response) => {
        if (response.result) {
          this.notification.success('Partner Release Successfully', false);
          this.solutionService.readSolution.next('updateSolution');
          this.activeModal.close();
        }
      });
    }
  }
}
