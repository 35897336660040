import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountService } from '@data/services/account/account.service';
import { FeaturesService } from '@data/services/features/features.service';
import { SalesManagerDashboardService } from '@data/services/sales_manager/sales_manager.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { VGPService } from '@data/services/value-prop-group/vgp.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ChooseAdventureInternalModule } from '@shared/choose-adventure-internal/choose-adventure-internal.module';
import { NameInitialsModule } from '@shared/pipes/name-initials.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLChartModule } from '@shared_components/primeng_chart/chart.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { HorizontalBarSalesManagerModule } from 'app/common/chart/horizontalbar_salesmanager/horizontalbar.module';
import { RedirectGuard } from 'app/guards/redirect.guard';
import { AddCaseStudyModule } from 'app/value-mapv2/solution-detail/case-studies/add-casestudy/add-casestudy.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ScbModule } from '../scb/scb.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefaultTranslations } from './dashboard/dashboard.translation';
import { DashboardCaseStudyComponent } from './dashboard/views/casestudy/casestudy.component';
import { CasestudyDashboardTranslations } from './dashboard/views/casestudy/casestudy.translation';
import { DashboardMVPComponent } from './dashboard/views/mvp/mvp.component';
import { MVPDashboardTranslations } from './dashboard/views/mvp/mvp.translation';
import { DashboardSalesManagerOrgViewComponent } from './dashboard/views/sales_manager/org/org.component';
import { DashboardSalesManagerComponent } from './dashboard/views/sales_manager/sales_manager.component';
import { SalesManagerFunctions } from './dashboard/views/sales_manager/sales_manager.functions';
import { SalesManagerDashboardTranslations } from './dashboard/views/sales_manager/sales_manager.translation';
import { DashboardSCBComponent } from './dashboard/views/scb/scb.component';
import { ScbDashboardTranslations } from './dashboard/views/scb/scb.translation';
import { DashboardSolutionComponent } from './dashboard/views/solution/solution.component';
import { SolutionDashboardTranslation } from './dashboard/views/solution/solution.translation';
import { DashboardValuePositionComponent } from './dashboard/views/valueposition/valueposition.component';
import { ValuePositionDashboardTranslations } from './dashboard/views/valueposition/valueposition.translation';
import { DashboardValuePropComponent } from './dashboard/views/valueprop/valueprop.component';
import { ValuepropDashboardTranslations } from './dashboard/views/valueprop/valueprop.translation';
import { DashboardValueRealizationComponent } from './dashboard/views/valuerealization/valuerealization.component';
import { ValueRealizationDashboardTranslations } from './dashboard/views/valuerealization/valuerealization.translation';
import { DashboardValuePropGroupComponent } from './dashboard/views/vpg/vpg.component';
import { ValuePropGroupDashboardTranslations } from './dashboard/views/vpg/vpg.translation';
import { DealdeskComponent } from './dealdesk/dealdesk.component';
import { MainDashboardRoutingModule } from './main-dashboard-routing.module';
import { ValueRealizedComponent } from './dashboard/views/value-realized/value-realized.component';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { ValueRealizedHomeTranslation } from 'app/value-realized/views/value-realized-home/value-realized-home.translation';
import { FunctionPipeModule } from "../shared/pipes/function-pipe.module";
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { ValueRealizedModule } from 'app/value-realized/value-realized.module';
import { TrackersComponent } from './dashboard/views/trackers/trackers.component';
import { ValueTrackerTableTranslation } from 'app/value-trackers/trackers_container/trackers-container.translation';
import { ListService } from '@data/services/list/list.service';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}
@NgModule({
    providers: [
        Translations,
        TranslationsV2Service,
        DefaultTranslations,
        ValueRealizationDashboardTranslations,
        ValuepropDashboardTranslations,
        ValuePositionDashboardTranslations,
        SolutionDashboardTranslation,
        ScbDashboardTranslations,
        MVPDashboardTranslations,
        SalesManagerDashboardTranslations,
        CasestudyDashboardTranslations,
        Translations,
        VGPService,
        ValuePropGroupDashboardTranslations,
        SalesManagerFunctions,
        RedirectGuard,
        FeaturesService,
        SalesManagerDashboardService,
        AccountService,
        ValuerealizationService,
        ValueRealizedHomeTranslation, 
        ValueTrackerTableTranslation,
        ListService,
    ],
    declarations: [
        DashboardSalesManagerOrgViewComponent,
        DashboardValuePropGroupComponent,
        DashboardComponent,
        DealdeskComponent,
        DashboardCaseStudyComponent,
        DashboardMVPComponent,
        DashboardSCBComponent,
        DashboardSolutionComponent,
        DashboardValuePositionComponent,
        DashboardValuePropComponent,
        DashboardValueRealizationComponent,
        DashboardSalesManagerComponent,
        DashboardComponent,
        DealdeskComponent,
        ValueRealizedComponent,
        TrackersComponent,
    ],
    imports: [
        AddCaseStudyModule,
        DLProgressbarModule,
        HorizontalBarSalesManagerModule,
        DLNGErrorDirectiveModule,
        NameInitialsModule,
        NGFormSaveButtonModule,
        SidebarModule,
        CalendarModule,
        MenuModule,
        TableModule,
        ChartModule,
        TreeTableModule,
        SidebarModule,
        NgxJsonViewerModule,
        DLChartModule,
        FormsModule,
        SelectButtonModule,
        NgxDaterangepickerMd.forRoot(),
        OverlayPanelModule,
        ProgressBarModule,
        SharedModule,
        MainDashboardRoutingModule,
        ScbModule,
        EditTranslationsModule,
        MatTooltipModule,
        ChooseAdventureInternalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
        }),
        FunctionPipeModule,
        NoRecordsModule,
        ValueRealizedModule,
    ]
})
export class MainDashboardModule {}
