import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';

@Component({
	selector: 'app-library-solutions',
	templateUrl: './library-solutions.component.html',
	styleUrls: ['./library-solutions.component.scss']
})
export class LibrarySolutionsComponent implements OnInit {
	pageNumber: number;
	pageLimit = 'all';
	librarySolutionsLoader: boolean;
	librarySolutions: Array<{typical_customer_revenue: string; name: string; id: string}> = [];
	fullImagePath: string;
	image_url: string;

	constructor(
		private commonService: CommonService,
		private location: Location,
		private solutionService: SolutionService,
		private router: Router
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit(): void {
    this.loadLibrarySolutions();
	}

	loadLibrarySolutions(): void {
		const searchLimit = '/limit/' + this.pageLimit + '/page/' + this.pageNumber + '/sortby/id/order/desc';
		this.librarySolutionsLoader = true;
		this.solutionService.getLibrarySolutions(searchLimit).subscribe((response) => {
			if (response.result) {
				this.librarySolutions = response.result.data;
			}
			this.librarySolutionsLoader = false;
		});
	}
	importLibraryToAccount(selectedSolutionId): void {
		const libSolutionId = {
			'lib_solution_id': selectedSolutionId
		};

		this.solutionService.importLibrarySolutionsToAccount(libSolutionId).subscribe(response => {
			this.router.navigate(['model/update/', response.result]);
		});
	}

	backClicked(): void {
		this.location.back();
	}
}

