<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>
						{{trans.trans.value_prompters.value}}
						<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();'
						[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.value_prompters" key='vp_dashboard.asset_prompter.value_prompter'></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-2'>
					<button class="btn btn_customizable pull-right" (click)="funcOpenAddPrompter()">
						<i class="fa fa-plus"></i>{{ trans.trans.add.value}}
					</button>
				</div>
			</div>
		</div>

		<div class='row'>
			<div *ngIf="uploadToggle || editToggle" class='col-xs-12'>

			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<table id="deliverables" class="table custom-table designer-table">
					<thead>
						<tr>
							<th>{{trans.trans.name.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();'
									[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" key='vp_dashboard.asset_prompter.name'></app-editTranslation>
							</th>
							<th style='width: 120px; white-space: nowrap;'>

							</th>
						</tr>
					</thead>
					<tbody *ngIf="prompters && prompters.length">
						<tr *ngFor='let p of prompters'>
							<td><a href="{{assetUrl}}{{p.location}}">{{p.name}}</a></td>
							<td class="">
								<div class="dropdown  pull-right">
									<span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
									<ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
										<li (click)="funcOpenEditPrompter(p)">
											<i class="icon-pencil"></i> {{ trans.trans.edit.value }}
										</li>
										<li (click)="funcDeletePrompter(p.id)">
											<i class="icon-trash"></i> {{ trans.trans.delete.value }}
										</li>
									</ul>
								</div>
							</td>

						</tr>
					</tbody>
					<tbody *ngIf="!prompters.length">
						<tr>
							<td>{{trans.trans.no_prompters_available.value}}.</td>
						</tr>

					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]="uploadToggle" position='right' styleClass="keywest_sidebar p-sidebar-lg">
	<form name="ValuePrompterForm" #ValuePrompterForm="ngForm">
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				Add Asset
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class='row'>
						<div class='col-xs-12'>
							<label class='sidebar_label'>{{trans.trans.file.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();'
								[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.file" key='vp_dashboard.asset_prompter.file'></app-editTranslation></label>
							<div *ngIf="editToggle && !file">
								<a href="{{assetUrl}}{{file_param.location}}">{{file_param.name}}</a>
							</div>
							<input type="file" name="file" id="fileUploaded" accept=".xls, .xlsx, .ppt, .doc, .docs, .docx, .pptx" (change)="handleInputChange($event)" required>
						</div>
					</div>
					<div class='row'>
						<div class='col-xs-12'>
							<label class='sidebar_label'>{{trans.trans.name.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();'
									[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" key='vp_dashboard.asset_prompter.name'></app-editTranslation></label>
							<input type='text' name='filename' [(ngModel)]="file_param.name" class='form-control'>
						</div>
					</div>
					<div class='row'>
						<div class='col-xs-12'>
							<label class='sidebar_label'>{{trans.trans.description.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();'
									[component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.description" key='vp_dashboard.asset_prompter.description'></app-editTranslation></label>
							<textarea name='description' class='form-control' [(ngModel)]="file_param.description" rows='1'>{{file_param.description}}</textarea>
						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<a (click)='editToggle ? funcUpdatePrompter() : funcUploadPrompter()' class='btn btn_customizable pull-right'><i class='fa fa-cloud-upload'></i>
					{{trans.trans.upload.value}}</a>
			</div>
		</div>
	</form>
</p-sidebar>