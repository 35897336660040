import { NgModule } from '@angular/core';

import { AESituationComponent } from './ae_situations.component';
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AESituationTranslations } from './ae_sitations.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BenefitsPhasingModule } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/phasing/phasing.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';

@NgModule({
  declarations: [AESituationComponent],
  imports: [
    SidebarModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    MatTooltipModule,
    DropdownModule,
    TableModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    ToggleButtonModule,
    InputSwitchModule,
    EditTranslationsModule,
    ProgressBarModule,
    AreYouSureModule,
    BenefitsPhasingModule,
    ReactiveFormsModule,
    ErrorInputWrapperModule,
  ],
  providers: [TranslationsV2Service, AESituationTranslations],
  entryComponents: [AESituationComponent],
  exports: [AESituationComponent],
})
export class AEDetailSituationModule {}
