import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TableModule } from 'primeng/table';

import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AccountService } from '@data/services/account/account.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { MenuModule } from 'primeng/menu';
import { ClipboardModule} from '@angular/cdk/clipboard';
import { TabViewModule } from 'primeng/tabview';
import { AnalyticsComponent } from '../analytics/analytics.component';
import { AnalyticsTranslation } from '../analytics/analytics.translation';
import { UserAdoptionComponent } from '../analytics/user-adoption/user-adoption.component';
import { UserAdoptionTranslation } from '../analytics/user-adoption/user-adoption.translation';
import { SolutionAdoptionComponent } from '../analytics/solution-adoption/solution-adoption.component';
import { SolutionAdoptionTranslation } from '../analytics/solution-adoption/solution-adoption.translation';
import { SelectLanguageModule } from '@shared/select-language/select-language.module';
import { MatTooltipModule } from '@angular/material/tooltip';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  {
    path: '',
    component: AnalyticsComponent,
  },
  {
    path: 'user-adoption',
    component: UserAdoptionComponent,
  },{
    path: 'solution-adoption',
    component: SolutionAdoptionComponent,
  },
];

@NgModule({
  imports: [
    ProgressBarModule,
    MatTooltipModule,
    MenuModule,
    EllipsisMenuModule,
    EditTranslationsModule,
    NgbModule,
    BreadcrumbsModule,
    CommonModule,
    FormsModule,
    ClipboardModule,
    SelectLanguageModule,
    RouterModule.forChild(routes),
    TableModule,
    TabViewModule
  ],
  declarations: [
    AnalyticsComponent,
    UserAdoptionComponent,
    SolutionAdoptionComponent
  ],
  providers: [
    AccountService,
    AnalyticsTranslation,
    UserAdoptionTranslation,
    SolutionAdoptionTranslation,
    TranslationsV2Service
  ],
  entryComponents: [
    AnalyticsComponent,
    UserAdoptionComponent,
    SolutionAdoptionComponent
  ],
  exports: [
    AnalyticsComponent,
    UserAdoptionComponent,
    SolutionAdoptionComponent
  ],
})
export class AnalyticsModule {}
