import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { CompanyService } from '@data/services/company/company.service';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from '@data/services/asset/asset.service';
import { StyleService } from 'app/style.service';
import { CompanySearchPayload } from '@shared/models/company-data.models';
import { Model } from '@data/services/account/models/model.interface';
import { Company } from '@shared/models/company.model';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'app/_utils/custom-validators';
import { ErrorMessagesService } from '@services/error-messages.service';
import { MenuItem } from 'primeng/api';
import { CaseStudyTranslations } from './ae_case_studies.translations';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

@Component({
  selector: 'app-case-studies-ae',
  templateUrl: './ae_case_studies.component.html',
  styleUrls: ['./ae_case_studies.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AECaseStudiesComponent implements OnInit, OnDestroy {
  @Input() solutionID: string;
  @Input() contextualHelp: string;
  @Input() mode: string;
  @Input() caseStudyEdit: { [klass: string]: any };
  @Output() callback = new EventEmitter();

  ngUnsubscribe = new Subject();
  companyName: string;
  showActionLoader = false;
  companyList = true;
  companiesNoResult = false;
  detailedDescription = false;
  companyDetails: Company = {};
  companiesList: Company[] = [];
  companyId: string;
  showCompanyDetails = false;
  isRedact = false;
  openAddCompany: boolean;
  fullImagePath: string;
  enableSaveBtn: boolean;
  payload: { [klass: string]: any } | CompanySearchPayload;
  showTranslate = false;

  pageNumber: number;
  pageNumberSearch = 1;

  pageLimit: number;
  pageLimitSearch = 'all';
  searchTypErr = false;
  order = 'asc';
  sortBy = 'name';

  strSearch = '';

  selectedModel: Model;
  modelList: Model[] = [];
  sidebar_title = '';

  case_study_id: string;
  caseStudy: any;
  referenceUrlControl = new FormControl('', [CustomValidators.urlValidator(true)]);
  title = 'Add Case Study';
  editingName = false;
  solutionAdmin = false;
  can_edit = false;
  tags: string[] = [];
  redacted = false;
  tagList: MenuItem[] = [];
  is_account_solution: number;
  assetGenerator: string;
  assetURL: string;
  toggleEditCaseStudy = false;
  style2022$: Observable<boolean>;
  errorDict = new Map<string, string>();
  accessFromVP: boolean;
  previousVPId: string;

  constructor(
    private commonService: CommonService,
    private accountService: AccountService,
    private companyService: CompanyService,
    private solutionService: SolutionService,
    private notificationService: NotificationService,
    private valueRealizationService: ValuerealizationService,
    private router: Router,
    private route: ActivatedRoute,
    private assetService: AssetService,
    private styleService: StyleService,
    private errorMessagesService: ErrorMessagesService,
    public trans: CaseStudyTranslations,
    private translationsService: TranslationsV2Service,
  ) {
    const image_url = this.commonService.getImageUrl();
    this.fullImagePath = image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.errorMessagesService.getTranslations();
    this.assetURL = this.commonService.getAssetUrl();
    this.style2022$ = this.styleService.style2022;

    this.getModels();
    this.getCaseStudyListForTags();
    this.getTranslations();

    this.caseStudy = {
      account_id: '',
      account_solution_id: '',
      account_solution_name: '',
      benefits: false,
      company_id: '',
      company_name: '',
      created: '',
      dep_casestudy_id: '',
      is_account_solution: '',
      modified: '',
      name: '',
      name_edited: '',
      quotes: false,
      redacted: '0',
      sections: false,
      tag: '',
      value_prop_id: '0',
      referenceUrl: '',
    };

    const modelAdmin = this.commonService.checkPrivilege(2);
    const caseStudyAdmin = this.commonService.checkPrivilege(9);
    if (modelAdmin || caseStudyAdmin) {
      this.can_edit = true;
    }

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      if (params['case_study_id']) {
        this.case_study_id = params['case_study_id'];
        this.is_account_solution = params['is_account_solution'];
      }

      if (params['fromVP']) {
        this.accessFromVP = true;
        this.previousVPId = params['fromVP'];
      } else {
        this.accessFromVP = false;
      }
    });

    if (this.mode === 'edit') {
      this.selectCompany(this.caseStudyEdit.company_id);

      this.isRedact = this.caseStudyEdit.redacted === '1';

      this.tags = this.caseStudyEdit.tag && this.caseStudyEdit.tag.length ? this.caseStudyEdit.tag.split(',') : [];
    }

    this.setRefUrlErrorDict();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationsService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationsService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  setRefUrlErrorDict() {
    this.referenceUrlControl.valueChanges.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.errorDict = new Map(this.errorMessagesService.getFormErrors({ referenceUrlControl: this.referenceUrlControl }));
    });
  }

  getModels() {
    const searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc';
    this.solutionService
      .getAvailableSolutionsFull(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.modelList = response.result.data;
          if (this.case_study_id) {
            this.getCaseStudyDetail();
          }
        }
      });
  }

  getRecentSearchComp() {
    if (this.companyName && this.companyName !== null && this.companyName !== '') {
      this.companyList = true;
      this.companiesNoResult = false;
      this.showActionLoader = true;
      this.payload = {
        searchTerm: this.companyName,
        publicOnly: 0,
        synopsisOnly: 1,
      };
      this.payload['limit'] = this.pageLimitSearch;
      this.payload['page'] = this.pageNumberSearch;
      this.payload['sortBy'] = this.sortBy;
      this.payload['order'] = this.order;
      this.accountService
        .getSearchCompanies(this.payload as CompanySearchPayload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (response) => {
            if (response.result && response.result.data && response.result.data.length > 0) {
              this.companiesList = response.result.data;
              this.companyList = false;
              this.showActionLoader = false;
            } else {
              this.companiesNoResult = true;
              this.detailedDescription = false;
            }
            this.showActionLoader = false;
          },
          (error) => {
            this.notificationService.error(error.result, false);
          }
        );
    } else {
      this.companiesList = [];
      this.companiesNoResult = true;
      this.companyList = true;
      this.companyId = '';
      this.showActionLoader = false;
    }
  }

  selectCompany(params) {
    this.detailedDescription = true;
    this.companyId = params;
    this.getCompanyDetail(this.companyId);
  }

  clearCompanyList(): void {
    this.companyName = '';
    this.companiesList = [];
    this.companyList = false;
    this.companyId = '';
    this.showActionLoader = false;
    this.companiesNoResult = false;
  }

  getHostnameFromRegex(url) {
    if (url === undefined) {
      return false;
    }
    if (!url) {
      url = this.companyDetails['name'] + '.com';
    }
    const sourceString = url.replace('http://', '').replace('https://', '').replace('www.', '').replace('.com.com', '.com').split(/[/?#]/)[0];
    return sourceString;
  }

  getCompanyDetail(id) {
    this.showActionLoader = true;
    this.companyService
      .getCompanySummary(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.companyDetails = result.result.company;
        }
        this.showCompanyDetails = true;
        this.showActionLoader = false;
        this.detailedDescription = true;
      });
  }

  getCaseStudyDetail() {
    this.solutionService.getCaseStudyDetail(this.case_study_id, this.is_account_solution).subscribe((response) => {
      if (response.result && response.result.case_study && response.result.case_study.length > 0) {
        this.caseStudy = {
          ...response.result.case_study[0],
          benefits: !Array.isArray(response.result.case_study[0].benefits) ? [] : response.result.case_study[0].benefits,
        };
        this.referenceUrlControl.setValue(this.caseStudy.reference_url);
        this.title = this.caseStudy.name;

        this.selectedModel = this.modelList.filter((x) => x.id == this.caseStudy.account_solution_id)[0];
        if (this.caseStudy.redacted == 1) {
          this.redacted = true;
        } else {
          this.redacted = false;
        }

        this.tags = [];
        if (this.caseStudy.tag.length) {
          this.tags = this.caseStudy.tag.split(',');
        }

        this.companyId = this.caseStudy.company_id;
        this.getCompanyDetail(this.caseStudy.company_id);
      }
    });
  }

  downloadAsset(id) {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.assetService.getCaseStudyAssetTemplate().subscribe((response) => {
      if (response && response !== null && typeof response !== 'string') {
        const downloadUrl =
          this.assetURL +
          'assets/' +
          this.assetGenerator +
          '?account_asset_template_id=' +
          response.result.id +
          '&user_id=' +
          userId +
          '&token=' +
          token +
          '&case_study_id=' +
          id +
          '&is_account_solution=' +
          1;
        window.location.assign(downloadUrl);
      } else {
        this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }

  getCaseStudyListForTags() {
    const searchLimit = '/limit/all/page/' + 1;

    this.solutionService
      .getRecentCaseStudies(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          let caseStudyList = [];
          caseStudyList = response.result.case_studies.data;

          caseStudyList.forEach((elem) => {
            const tags = elem && elem.tag ? elem.tag.split(',') : [];
            if (tags && tags.length) {
              tags.forEach((tag) => {
                this.tagList.push(tag);
              });
            }
          });

          const uniqueTags = [];
          this.tagList.forEach((el) => {
            if (this.tagList.indexOf(el) !== -1) {
              uniqueTags.push({
                label: el,
                command: () => {
                  this.appendTag(el);
                },
              });
            }
          });
          this.tagList = uniqueTags;
        }
      });
  }

  appendTag(tag) {
    this.tags.push(tag);
    this.tags = [...this.tags];
  }

  UpdateCaseStudy() {
    let name = '';
    if (this.caseStudy) {
      name = this.caseStudy.name_edited;
      if (!name) {
        name = this.caseStudy.name;
      }
      if (!name) {
        name = '';
      }
    }

    if (this.referenceUrlControl.invalid) {
      return;
    }

    const payload = {
      case_study_id: this.case_study_id,
      account_solution_id: this.selectedModel.id,
      name: name,
      tag: this.tags && this.tags.length ? this.tags.join() : '',
      redacted: this.redacted == true ? 1 : 0,
      company_id: this.caseStudy && this.caseStudy.company_id ? this.caseStudy.company_id : this.companyId,
      reference_url: this.referenceUrlControl.value,
    };

    if (payload.case_study_id) {
      this.valueRealizationService.fullCaseStudyUpdate(payload).subscribe((response) => {
        if (response.result.success) {
          this.editingName = false;
          this.notificationService.success('Case Study Updated Successfully', false);
          this.getCaseStudyDetail();
        } else {
          this.notificationService.error('Case Study Update Failed!', false);
        }
      });
    } else {
      this.solutionService
        .saveCaseStudy(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.case_study_id = response.result.id;
            this.notificationService.success('Case Study created successfully', false);
            this.is_account_solution = 1;
            this.router.navigate(['/case_study/' + this.case_study_id + '/' + this.is_account_solution]);
            this.getCaseStudyDetail();
          }
        });
    }
  }

  addCompany() {
    this.openAddCompany = true;
  }

  companyCreated($event) {
    this.openAddCompany = false;
    this.companyName = $event.name;
    this.getRecentSearchComp();
  }

  enableSave($event) {
    if ($event == 'true') {
      this.enableSaveBtn = true;
    } else {
      this.enableSaveBtn = false;
    }
  }

  funcToggleEditCaseStudy() {
    this.toggleEditCaseStudy = !this.toggleEditCaseStudy;
    this.caseStudyEdit = Object.assign({}, this.caseStudy);
  }

  closeSidebar() {
    this.toggleEditCaseStudy = false;
    this.getCaseStudyDetail();
  }

  removeTag(i) {
    this.tags.splice(i, 1);
    this.UpdateCaseStudy();
  }

  changeEditStatus() {
    this.editingName = !this.editingName;
    if (this.caseStudy) {
      this.caseStudy.name_edited = this.caseStudy.name ? this.caseStudy.name : '';
    }
  }

  showTopSection(): boolean {
    if ( this.accessFromVP ) {
      return false;
    } else {
      if ( !this.case_study_id || !this.redacted || this.can_edit ) {
        return true;
      }
    }
  }
}
