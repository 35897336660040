import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { UserService } from '@data/services/user/user.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-collateral',
  templateUrl: './collateral.component.html',
  styleUrls: ['./collateral.component.scss'],
  providers: [FactsService],
})
export class CollateralComponent implements OnInit, OnDestroy {
  @Input() canEdit = false;

  @Input() showHelp: boolean;
  @Input() contextualHelp: { [klass: string]: any };
  @Input() solutionId: number | string;
  @Input() valuePropId: number;
  @Input() tabInit: number;
  @Input() showTranslate = false;

  loadcollateralTable = false;
  collateral: { [klass: string]: any }[] = [];
  imageUrl: string;
  fullImagePath: string;
  fileFormatTypes: { [klass: string]: any }[] = [];
  modalReference: NgbModalRef;
  CollateralModel: { [klass: string]: any };
  accountSolutionId: number | string;
  asset_id: number;
  format: number;
  name: string;
  description: string;
  url: string;
  id: number;
  collateralId: unknown;
  typeOfCollateral: string;
  hideActionLoader = true;
  style2022$: Observable<boolean>;

  subscriptioncontextualHelp: Subscription;
  subscriptiongetCollateralList: Subscription;
  subscriptionreadCollateral: Subscription;
  subscriptiongetFileFormatTypes: Subscription;
  subscriptioneditCollateral: Subscription;
  subscriptiondeleteCollateral: Subscription;
  subscriptioncreateCollateralList: Subscription;
  subscriptionupdateCollateral: Subscription;

  constructor(
    private solutionService: SolutionService,
    private valuepropService: ValuepropService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private factsService: FactsService,
    private commonService: CommonService,
    private notification: NotificationService,
    private userService: UserService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.userService.getContextualHelpList().subscribe((result) => {
      if (result.result) {
        this.commonService.contextualHelp.next(result.result);
      }
    });

    this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.typeOfCollateral = 'Add';

    this.route.params.subscribe((params) => (this.accountSolutionId = params['id']));

    if (!this.accountSolutionId) {
      this.accountSolutionId = this.solutionId;
    }
    this.getCollateralList();
    this.getFileFormats();

    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.subscriptiongetCollateralList) {
      this.subscriptiongetCollateralList.unsubscribe();
    }
    if (this.subscriptionreadCollateral) {
      this.subscriptionreadCollateral.unsubscribe();
    }
    if (this.subscriptiongetFileFormatTypes) {
      this.subscriptiongetFileFormatTypes.unsubscribe();
    }
    if (this.subscriptioneditCollateral) {
      this.subscriptioneditCollateral.unsubscribe();
    }
    if (this.subscriptiondeleteCollateral) {
      this.subscriptiondeleteCollateral.unsubscribe();
    }
    if (this.subscriptioncreateCollateralList) {
      this.subscriptioncreateCollateralList.unsubscribe();
    }
    if (this.subscriptionupdateCollateral) {
      this.subscriptionupdateCollateral.unsubscribe();
    }
  }

  refreshTranslation() {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  processgetCollateralList(response: any) {
    if (response.result) {
      this.collateral = response.result;
    } else {
      this.collateral = [];
    }
    this.loadcollateralTable = false;
  }

  getCollateralList() {
    this.loadcollateralTable = true;

    if (this.solutionId) {
      this.subscriptiongetCollateralList = this.solutionService.getCollateralList(this.solutionId).subscribe((response) => {
        this.processgetCollateralList(response);
      });
    } else {
      this.subscriptionreadCollateral = this.valuepropService.readCollateral(this.valuePropId).subscribe((response) => {
        this.processgetCollateralList(response);
      });
    }
  }

  getFileFormats() {
    this.subscriptiongetFileFormatTypes = this.factsService.getFileFormatTypes().subscribe((result) => {
      this.fileFormatTypes = result.result;
    });
  }

  openWindow(url: string) {
    window.open(url, '_blank');
  }

  openAddCollateral(content: any) {
    this.typeOfCollateral = 'Add';
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.finally(
      () => {
        this.resetValues();
      }
    );
  }

  editCollateral(content: any, id: number) {
    this.typeOfCollateral = 'Edit';
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.finally(
      () => {
        this.resetValues();
      }
    );
    this.subscriptioneditCollateral = this.solutionService.editCollateral(id).subscribe((response) => {
      if (response.result) {
        this.id = response.result.id;
        this.asset_id = response.result.id;
        this.name = response.result.name;
        this.format = response.result.format_type_id;
        this.description = response.result.description;
        this.url = response.result.url;

        if (this.valuePropId) {
          this.accountSolutionId = response.result.account_solution_id;
        }
      }
    });
  }

  deleteCollateral() {
    this.hideActionLoader = false;
    this.subscriptiondeleteCollateral = this.solutionService.deleteCollateral(this.collateralId).subscribe(() => {
      this.hideActionLoader = true;
      this.modalReference.close();
      this.getCollateralList();
      this.notification.success('Collaterals deleted successfully', false);
    });
  }

  resetValues() {
    this.id = 0;
    this.asset_id = 0;
    this.name = '';
    this.format = 0;
    this.description = '';
    this.url = '';
  }

  SaveCollateral(form: NgForm) {
    this.CollateralModel = {
      asset_id: this.id,
      format_type_id: form.value.format,
      name: form.value.name,
      description: form.value.description,
      url: form.value.url,
    };

    if (this.typeOfCollateral === 'Add') {
      this.CollateralModel['account_solution_id'] = this.accountSolutionId;
      this.subscriptioncreateCollateralList = this.solutionService.createCollateralList(this.CollateralModel).subscribe(() => {
        this.notification.success('Collateral created successfully', false);
        this.getCollateralList();
        this.solutionService.triggerTabs.next('collateral');
      });
    } else {
      if (this.valuePropId) {
        this.CollateralModel['account_solution_id'] = this.accountSolutionId;
      } else {
        this.CollateralModel['account_solution_id'] = this.accountSolutionId;
      }

      this.subscriptionupdateCollateral = this.solutionService.updateCollateral(this.CollateralModel).subscribe(() => {
        this.notification.success('Collateral updated successfully', false);
        this.getCollateralList();
        this.solutionService.triggerTabs.next('collateral');
      });
    }

    this.modalReference.close();
  }

  open(content: any, id: number) {
    this.collateralId = id;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }
}
