<form name="formAddCompetitor" #formAddCompetitor="ngForm">
	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			{{sidebar_title?.value}}
			<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
			[isTranslationV2]="true" [transObj]="sidebar_title" (callback)="getTranslations()"></app-editTranslation>
		</div>

		<div class='sidebar_container'>
			<div class='keywest_panel'>

				<div class='row'>
					<div class="col-md-12" appNGError [elemControl]="formAddCompetitor.controls.competitor_id">
						<label for="alterEgo" class="sidebar_label">{{trans.trans.name.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<select class="form-control" ret-data='Name' [(ngModel)]="competitorFormObj.account_competitor_id" placeholder="Select Competitors" name="competitor_id" type="text" required>
							<option disabled="disabled">{{trans.trans.chooseOne.value}}</option>
							<option *ngFor="let competitor of competitors" [value]="competitor.id">
								{{competitor.name}}
							</option>
						</select>
					</div>
				</div>

				<div class='row'>
					<div class="col-xs-6" appNGError [elemControl]="formAddCompetitor.controls.description">
						<label for="description" class="sidebar_label">{{trans.trans.description.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea class="form-control keywest_textarea" ret-data='Description' rows='5' id="Enter Description" [(ngModel)]="competitorFormObj.description" name="description" placeholder="{{trans.trans.placeholderDescription.value}}" required></textarea>
					</div>

					<div class="col-xs-6" appNGError [elemControl]="formAddCompetitor.controls.landmines">
						<label for="landmines" class='sidebar_label'>{{trans.trans.lnadmine.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.lnadmine" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea class="form-control keywest_textarea" rows='5' ret-data='Landmines' id="landmines" [(ngModel)]="competitorFormObj.landmines" name="landmines" placeholder="{{trans.trans.placeholderLandmines.value}}" required></textarea>
					</div>
				</div>

				<div class='row'>
					<div class="col-xs-6" appNGError [elemControl]="formAddCompetitor.controls.theyll_say">
						<label for="theyll_say" class='sidebar_label'>{{trans.trans.theyllSay.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.theyllSay" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea class="form-control keywest_textarea" rows='5' ret-data="They'll Say" id="theyll_say" [(ngModel)]="competitorFormObj.theyll_say" name="theyll_say" placeholder="{{trans.trans.placeholderTheyllSay.value}}" required></textarea>
					</div>

					<div class="col-xs-6" appNGError [elemControl]="formAddCompetitor.controls.their_landmines">
						<label for="their_landmines" class="sidebar_label">{{trans.trans.theirLandmines.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.theirLandmines" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea class="form-control keywest_textarea" rows='5' ret-data='Their Landmines' id="their_landmines" [(ngModel)]="competitorFormObj.their_landmines" name="their_landmines" placeholder="{{trans.trans.placeholderTheirLandmine.value}}" required></textarea>
					</div>
				</div>

				<div class='row'>
					<div class="form-group col-md-12" appNGError [elemControl]="formAddCompetitor.controls.our_response">
						<label for="our_response" class='sidebar_label'>{{trans.trans.ourResponse.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
							[isTranslationV2]="true" [transObj]="trans.trans.ourResponse" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea class="form-control keywest_textarea" row='10' ret-data='Our Response' id="our_response" [(ngModel)]="competitorFormObj.our_response" name="our_response" placeholder="{{trans.trans.placeholderOurResponse.value}}" required></textarea>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div class='btn_container'>
		<div class="col-md-12">
			<input type='hidden' name='id' [(ngModel)]="competitorFormObj.id">
			<app-btn-save [form]='formAddCompetitor' (callback)='saveCompetitor(formAddCompetitor)' [text]='trans.trans.save.value' class='btn btn_customizable pull-right'></app-btn-save>
			<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
		</div>
	</div>
</form>