import { Injectable } from '@angular/core';

@Injectable ()

export class AddCostVpDashTranslations {

    public config: any = {
        component: 'addCostVpDash',
        display_name: 'Add Cost Form',
        description: 'Form to add a cost'
    };

    public trans: any = {
        add_costs: 'Add Costs',
        competitor: 'Competitors',
        cost_type: 'Cost Type',
        choose_one: 'Choose One',
        unit_price: 'Unit Price',
        skip: 'Skip if you\'re using Variable Costs',
        accrual_type: 'Accrual Type',
        qty: 'Quantity',
        discount: 'Discount (%)',
        year1: 'Year 1 Cost',
        year2: 'Year 2 Cost',
        year3: 'Year 3 Cost',
        year4: 'Year 4 Cost',
        year5: 'Year 5 Cost',
        year6: 'Year 6 Cost',
        year7: 'Year 7 Cost',
        year8: 'Year 8 Cost',
        year9: 'Year 9 Cost',
        year10: 'Year 10 Cost',
        cost_category: 'Cost Category',
        required: 'Required',
        cancel: 'Cancel',
        save: 'Save',
        edit_cost: 'Edit Cost',
        view_cost:'View Cost',
        placeholderYear1: 'yr1 costs',
        placeholderYear2: 'yr2 costs',
        placeholderYear3: 'yr3 costs',
        placeholderYear4: 'yr4 costs',
        placeholderYear5: 'yr5 costs',
        placeholderYear6: 'yr6 costs',
        placeholderYear7: 'yr7 costs',
        placeholderYear8: 'yr8 costs',
        placeholderYear9: 'yr9 costs',
        placeholderYear10: 'yr10 costs',
        quantity: 'Quantity',
        placeholderDiscount: 'Discount',
        renameCost: 'Rename Cost (Optional)',
        description: 'Description',
        annualPayment: 'Annual Payment',
        initialCost: 'Initial Cost',
        annualInterestRate: 'Annual Interest Rate (%)',
        leaseTerms: 'Lease Terms (Months)',
        residualValue: 'Residual Value (% of Present Value)',
        buyoutValue: 'Buyout Value (% of Residual Value)',
        calcResult: 'Calculation Result',
        yoyGrowth: 'YoY Growth Rate',
        discountPercent: 'Discount (%)',
        expenseType: 'Expense Type',
        are_you_sure: 'Are you sure?',
        back: 'Back',
        costUpdated: 'Cost updated successfully',
        costAdded: 'Cost added successfully'
    };
}
