

	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			{{trans.trans.viewBenefits.value}}
			<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true" 
			[transObj]="trans.trans.viewBenefits" (callback)="getTranslations()"></app-editTranslation>
		</div>

	

		<div class='sidebar_container'>
			<div class='keywest_panel'>

						<div class='case_study_benefits_list_container'>
							<div class='row case_study_benefits_list'>
								<div class='col-xs-7 sidebar_label_readonly'>{{trans.trans.selected.value}}
										<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true" 
										[transObj]="trans.trans.selected" (callback)="getTranslations()"></app-editTranslation>
								</div>
								<div class='col-xs-2 sidebar_label_readonly'>{{trans.trans.improvement.value}}
										<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true" 
										[transObj]="trans.trans.improvement" (callback)="getTranslations()"></app-editTranslation>
								</div>
								<div class='col-xs-2 sidebar_label_readonly'>{{trans.trans.type.value}}
										<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true" 
										[transObj]="trans.trans.type" (callback)="getTranslations()"></app-editTranslation>
								</div>
							</div>
				
							 
							<div class="row case_study_benefits_list">
								<div class='col-xs-7'>
									{{benefitView.metric_name}}
								</div>
								<div class='col-xs-2'>
									{{benefitView.improvement}}
								</div>
								<div class='col-xs-2'>
                  					{{benefitView.improvement_type_name.abbr}}
								</div>
							</div>

							
						</div>
					</div>
				</div>
				
			</div>