import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Template } from '@data/services/valueprop/models/asset.model';
import { LanguageType } from '@shared/models/user-info.model';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil} from 'rxjs/operators';
import { AssetPreviewTranslations } from './asset-preview.translation';
import { GlobalSearchService } from '@data/services/search/search.service';
import { ColumnMeta } from '@shared/models/common.models';

@Component({
  selector: 'app-asset-preview',
  templateUrl: './asset-preview.component.html',
  styleUrls: ['./asset-preview.component.scss']
})
export class AssetPreviewComponent implements OnInit, OnDestroy {

  showTranslate = false;
  ngUnsubscribe = new Subject();
  loading = false;
  languages: LanguageType[];
	asset_gen_file: string;
	user_id: string;
	token: string;

  @Input() template: Template;

	strSearch = '';
  res: { [klass: string]: any }[] = [];

	rollup: { 
		type: string; 
		url: string; 
		name: string;
		term: string;
		id: string;
	}[] = [];

	cols: ColumnMeta[]= [
		{ field: 'name', header: 'Name', width: '' },
		{ field: 'term', header: 'Term', width: '85px' },
	];

  constructor(
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: AssetPreviewTranslations,
    private globalSearch: GlobalSearchService,
		private CommonService: CommonService,
  ) { }

  ngOnInit() {
		this.asset_gen_file = sessionStorage.getItem('asset_gen_file');
		this.user_id = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');

    this.getTranslations();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

				this.rollup = [{ type: 'hypothesis', url: '', term: '', id: '', name: this.trans.trans.emptyTable.value }];
      });
  }

	funcSearch(): void {
		this.loading = true;
    if (this.strSearch) {
      const payload = {
        searchTerm: this.strSearch,
        publicOnly: 0,
        synopsisOnly: 1,
        limit: 'all',
        page: 1,
        sortBy: 'name',
        order: 'asc',
      };

      this.globalSearch
        .getGlobalSearch(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(debounceTime(1000))
        .subscribe((res) => {
          this.res = res;
          this.rollup = res.result.rollup
						.filter((val) => val.type == 'hypothesis' || val.type == 'proposition')
						.map(searchResult => this.funcBuildDownloadUrl(searchResult));

						this.loading = false;
        });
    }
  }

	funcBuildDownloadUrl(searchResult) {
		searchResult['download_link'] = this.getAssetURL(searchResult.id);

		return searchResult;
	}

	getAssetURL(value_prop_id) {
    return (
      this.CommonService.getAssetUrl() +
      'assets/' +
      this.asset_gen_file +
      '?account_asset_template_id=' +
      this.template.id +
      '&user_id=' +
      this.user_id +
      '&token=' +
      this.token +
      '&value_prop_id=' +
      value_prop_id
    );
  }

}