import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SelectedElement } from '@data/services/valueposition/models/selected-element.interface';
import { Capability } from '@shared/models/capabilities.models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelsV2RCapabilitiesTranslations } from './r_capabilities.translation';

@Component({
  selector: 'app-r-capabilities',
  templateUrl: './r_capabilities.component.html',
})
export class RCapabilitiesComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() selectedElem: SelectedElement;
  @ViewChild('formAddEditCapabilities') formAddEditCapabilities: NgForm;

  name = '';
  description = '';
  showAdd = false;
  capabilities: Capability[] = [];
  account_id: string;
  mode = 'add';
  sidebar_title = 'View Capability';
  toggleAddEditCapability: boolean;
  cols: Array<{field: string, header: string}> = [{ field: 'name', header: 'Capabilities' }];
  strSearch = '';
  showTranslate = false;
  ngUnsubscribe$ = new Subject();

  constructor(
    private commonService: CommonService,
    public trans: ModelsV2RCapabilitiesTranslations,
    private translationService: TranslationsV2Service
  ) { }

  ngOnInit() {
    this.account_id = sessionStorage.getItem('aid');
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.trans.trans.view_capability.value;
      });
  }

}
