import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { Subscription, Subject } from 'rxjs';
import { InitiativeVpDashTranslations } from './initiatives.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-initiatives',
	templateUrl: './initiatives.component.html',
	providers: [ValuepositionService]
})
export class InitiativesComponent implements OnInit, OnDestroy {
	@Input() valueProp: any;
	@Input() changeDetect: any;
	@Input() showHelp;
	@Input() contextualHelp;
	@Input() showTranslate: boolean = false;

	ngUnsubscribe = new Subject();

	image_url: string;
	fullImagePath: string;
	InitiativeLoader: boolean = false;
	Initiatives: any[] = [];
	ImpactAreaList: Array<any> = [];
	modalReference: any;
	selectedImpactInitiative: Array<any> = [];
	selectedImpactTypes: Array<any> = [];
	saveBtnDisabled: boolean = true;
	deleteImpactId: any;
	subscription: Subscription = new Subscription();

	showAddInitiatives: boolean = false;
	showUpdateInitiatives: boolean = false;

	constructor(
		private valuepropService: ValuepropService,
		private commonService: CommonService,
		private valuePositionService: ValuepositionService,
		private notificationService: NotificationService,
		private modalService: NgbModal,
		public trans: InitiativeVpDashTranslations,
		private translationService: TranslationsV2Service

	) {


		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {

		  this.getTranslations();
		  this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		  });
		  this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		  });


		this.getInitiatives();

	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

	refreshTranslation() {
		this.getTranslations();

	}

	reloadComponent(valueProp) {
		// this.valueProp = valueProp;
		this.getInitiatives();
	}

	getInitiatives() {

		this.InitiativeLoader = true;
		this.Initiatives = [];
		this.valuepropService.getInitiatives(this.valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result) {
				this.Initiatives = response.result;
				this.getImpactTypes("add");
			}
			this.InitiativeLoader = false;
		});
	}

	getImpactTypes(type) {
		this.valuePositionService.getImpactTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
			this.ImpactAreaList = result.result;
			if (type === "add") {
				this.addImpactChanges();
			} else if (type === "update") {
				this.updateImpactChanges();
			}
		});
	}

	changeImpactArea(item, event) {
		this.saveBtnDisabled = true;
		if (event.target.checked == true) {
			this.selectedImpactTypes.push(item.id);
		}
		else if (event.target.checked == false) {
			this.selectedImpactTypes = this.selectedImpactTypes.filter((impact: any) => {
				return impact !== item.id;
			});
		}

		if (this.selectedImpactTypes.length > 0) {
			this.saveBtnDisabled = false;
		}
	}
	//delete impact popup
	open(content, id) {
		this.deleteImpactId = id;
		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}
	deleteImpact(id) {
		this.valuepropService.deleteImpact(this.valueProp.id, id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.notificationService.success(response.result.message, false); // Params {message, islogout}
				// this.ValuepropService.refreshDashboard.next('refreshDashboard');
				this.getInitiatives();
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.getInitiatives();

			this.modalReference.close();
		});

	}
	openAddUpdateInitiatives(type) {
		this.getImpactTypes(type);
		if (type == "add") {
			this.showAddInitiatives = !this.showAddInitiatives;
		} else {
			this.showUpdateInitiatives = !this.showUpdateInitiatives;
		}
		// this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		// this.modalReference.result.then((result) => {
		// }, (reason) => {
		// });
	}
	addImpactChanges() {
		var ids = [];
		if (this.ImpactAreaList) {
			for (var i = 0; i < this.ImpactAreaList.length; i++) {
				if (this.Initiatives) {
					for (var j = 0; j < this.Initiatives.length; j++) {
						if (this.Initiatives[j]["impact_type_id"] === this.ImpactAreaList[i]["id"]) {
							ids.push(this.ImpactAreaList[i]["id"]);
						}
					}
				}
			}
		}
		this.ImpactAreaList = this.ImpactAreaList.filter((impact: any) => {
			return ids.indexOf(impact.id) === -1;
		});
	}
	updateImpactChanges() {
		var ids = [];
		if (this.ImpactAreaList && this.ImpactAreaList.length) {
			for (var i = 0; i < this.ImpactAreaList.length; i++) {
				for (var j = 0; j < this.Initiatives.length; j++) {
					if (this.Initiatives[j]["impact_type_id"] === this.ImpactAreaList[i]["id"]) {
						this.ImpactAreaList[i]["impact_type_id"] = this.ImpactAreaList[i]["id"];
						this.ImpactAreaList[i]["id"] = this.Initiatives[j]["id"];
						this.ImpactAreaList[i]["ischecked"] = true;
						this.ImpactAreaList[i]["initiative"] = this.Initiatives[j]["initiative"];
						this.ImpactAreaList[i]["ischeckedValue"] = true;
						ids.push(this.ImpactAreaList[i]["id"]);
						break;
					}
				}
			}
		}
		this.ImpactAreaList = this.ImpactAreaList.filter((impact: any) => {
			return ids.indexOf(impact.id) !== -1;
		});
	}
	saveImpactType() {
		this.selectedImpactInitiative = this.ImpactAreaList.filter((impact: any) => {
			return impact.ischecked;
		});
		if (this.selectedImpactInitiative && this.selectedImpactInitiative.length) {
			for (let i = 0; i < this.selectedImpactInitiative.length; i++) {
				this.selectedImpactInitiative[i]["impact_type_id"] = this.selectedImpactInitiative[i]["id"];
				delete this.selectedImpactInitiative[i]["id"];
				delete this.selectedImpactInitiative[i]["description"];
				delete this.selectedImpactInitiative[i]["ischecked"];
				delete this.selectedImpactInitiative[i]["name"];
			}
		}
		let payload = { "value_prop_id": this.valueProp.id, "company_id": this.valueProp.company_id, "impact": this.selectedImpactInitiative };
		this.valuepropService.saveImpactTypes(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshDashboard.next('refreshDashboard');
				this.notificationService.success(response.result.message, false); // Params {message, islogout}
				this.getInitiatives();
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.showAddInitiatives = !this.showAddInitiatives;
		});
	}
	updateImpactType() {
		this.selectedImpactInitiative = this.ImpactAreaList.filter((impact: any) => {
			return impact.ischecked;
		});
		let payload = { "impact": this.selectedImpactInitiative };
		if (payload.impact && payload.impact.length) {
			for (let i = 0; i < payload.impact.length; i++) {
				delete payload.impact[i]["description"];
				delete payload.impact[i]["ischecked"];
				delete payload.impact[i]["ischeckedValue"];
				delete payload.impact[i]["name"];
			}
		}
		this.valuepropService.updateImpactTypes(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshDashboard.next('refreshDashboard');
				this.notificationService.success(response.result.message, false); // Params {message, islogout}
				this.getInitiatives();
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.showUpdateInitiatives = !this.showUpdateInitiatives;
		});
	}
}
