import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { AlertService } from '../../_services/alert.service';

@Component({
  selector: 'app-trackers-list',
  templateUrl: './tracker.dashboard.component.html',
  styleUrls: ['./tracker.dashboard.component.scss'],
})
export class DashboardTrackersComponent implements OnInit, OnDestroy {
  pageNumber: number;
  pageLimit: number;
  deleteValueRealizationId: any;
  order: string = 'desc';
  sortBy: string = 'modified';

  valueRealization: any = [];
  modalReference: any;

  loadValueRealizationTable: boolean = false;

  fullImagePath: string;
  image_url: string;
  searchTypeList: Array<Object> = [
    {
      name: 'Search By Name',
      value: 'name',
    },
    {
      name: 'Search By Account',
      value: 'account',
    },
    {
      name: 'Search By Creator',
      value: 'creator',
    },
  ];
  searchType;
  showActionLoader: boolean = false;
  valuerealizationname: string;
  hideActionLoader: boolean = true;

  isGuest: boolean = false;
  isAdmin: boolean = false;

  subscriptiongetValueRealization: Subscription;
  subscriptiondeleteValueProp: Subscription;
  subscriptionSubscribeLoggedIn: Subscription;
  showTranslate: boolean = false;
  header_color: string;

  constructor(
    private CommonService: CommonService,
    private ValuerealizationService: ValuerealizationService,
    private NotificationService: NotificationService,
    private modalService: NgbModal,
    private ValuepropService: ValuepropService,
    private AlertService: AlertService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    this.image_url = this.CommonService.getImageUrl();

    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true' ? true : false;

    this.header_color = sessionStorage.getItem('header_color');

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'customercolors';

    let entry = '.btn-rounded { background-color: ' + this.header_color + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    this.loadValueRealization();

    const rid = sessionStorage.getItem('rid');
    if (rid == '7') {
      this.isGuest = true;
    }

    this.subscriptionSubscribeLoggedIn = this.AlertService.subscribeLoggedIn().subscribe(() => {
      this.loadValueRealization();
    });
  }

  ngOnDestroy() {
    if (this.subscriptiongetValueRealization) {
      this.subscriptiongetValueRealization.unsubscribe();
    }
    if (this.subscriptiondeleteValueProp) {
      this.subscriptiondeleteValueProp.unsubscribe();
    }
    if (this.subscriptionSubscribeLoggedIn) {
      this.subscriptionSubscribeLoggedIn.unsubscribe();
    }
  }

  refreshTranslation() {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  loadValueRealization() {
    let searchLimit = '/limit/' + this.pageLimit + '/page/' + this.pageNumber + '/sortby/' + this.sortBy + '/order/' + this.order;
    this.subscriptiongetValueRealization = this.ValuerealizationService.getValueRealization(searchLimit).subscribe((response) => {
      if (response.result) {
        this.valueRealization = response.result.data;
        this.loadValueRealizationTable = true;
      }
    });
  }

  open(content, id) {
    this.deleteValueRealizationId = id;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  deleteValueRealization() {
    this.hideActionLoader = false;
    this.subscriptiondeleteValueProp = this.ValuepropService.deleteValueProp(this.deleteValueRealizationId).subscribe((response) => {
      if (response.result) {
        this.NotificationService.success(response.result, false);
        this.loadValueRealization();
      }
      this.hideActionLoader = true;
      this.modalReference.close();
    });
  }
}
