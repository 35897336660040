import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { NotificationService } from '@services/notification.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './search-company.translation';
import { Company } from '@shared/models/company.model';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-search-company',
	templateUrl: './search-company.component.html'
})
export class SearchCompanyComponent implements OnInit, OnChanges, OnDestroy {
	@Input() companyDetails;
	@Output() afterCompanyDetail = new EventEmitter();

	addCompany = false;

	companiesList: Company[] = [];
	companyList = true;
	detailedDescription = true;
	companyId: number;
	payload: any = {};
	showCompanyAdd = false;
	fullImagePath: string;
	quickCompanyDetailsDescription = true;

	pageNumber: number;
	pageNumberSearch = 1;

	pageLimit = 10;
	pageLimitSearch = 10;
	searchTypErr = false;
	order = 'asc';
	sortBy = 'name';
	modalReference: NgbModalRef;
	companyname = '';
	companiesNoResult = false;
	showActionLoader = false;
	crm: {
    sfoide?: string;
    company_id?: number;
    opportunity_id?: string;
    account_defined_id?: string
  } = {};

	sfoide: string;
	contextualHelp: any = {};
	enableSaveBtn: boolean;

	cols: any[] = [
		{ field: 'name', header: 'Name' },
		{ field: 'account_id', header: 'Account ID' },
		{ field: 'revenue', header: 'Revenue' },
		{ field: 'employees', header: 'Employees' },
		{ field: 'ticker', header: 'Ticker' },
		{ field: 'city', header: 'City' },
		{ field: 'country', header: 'Country' }
	];

	ngUnsubscribe$ = new Subject();
	showTranslate = false;
	style2022$: Observable<boolean>;

	constructor(
		private accountService: AccountService,
		private companyService: CompanyService,
		private notificationService: NotificationService,
		private commonService: CommonService,
		private modalService: NgbModal,
		public trans: DefaultTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {
		this.fullImagePath = this.commonService.getLoaderImageUrl();

		if (sessionStorage.getItem('crm')) {
			this.crm = JSON.parse(sessionStorage.getItem('crm'));
			this.sfoide = this.crm.sfoide;
			if (this.crm.company_id) {
				this.companyId = this.crm.company_id;
				this.selectCompany(this.companyId);
			}
		}

	}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;
		this.getRecentSearchComp();

		this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
			this.contextualHelp = response['Model'];
		});

		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans.trans
		};

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
	}

	ngOnChanges(): void {
		if (Object.keys(this.companyDetails).length !== 0 && this.companyDetails.constructor === Object) {
			this.quickCompanyDetailsDescription = false;
			this.detailedDescription = false;
		}
	}

	openAddCompany(content): void {
		this.modalReference = this.modalService.open(content, { size: 'lg', windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
	}

	getRecentSearchComp(): void {
		if (this.companyname && this.companyname !== null && this.companyname !== '') {
			this.companyList = true;
			this.companiesNoResult = false;
			this.showActionLoader = true;
			this.payload = {
				'searchTerm': this.companyname,
				'publicOnly': 0,
				'synopsisOnly': 1
			};
			this.payload['limit'] = 'all';
			this.payload['page'] = this.pageNumberSearch;
			this.payload['sortBy'] = this.sortBy;
			this.payload['order'] = this.order;
			this.accountService.getSearchCompanies(this.payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
				(response) => {
					this.showCompanyAdd = true;
					if (response.result && response.result.data && response.result.data.length > 0) {
						this.companiesList = response.result.data;
						this.companyList = false;
						this.detailedDescription = true;
					} else {
						this.companiesNoResult = true;
						this.quickCompanyDetailsDescription = true;
					}
					this.showActionLoader = false;
				}, error => {
					this.notificationService.error(error.result, false);
				});
		} else {
			this.companiesList = [];
			this.companiesNoResult = true;
			this.companyList = true;
		}
	}

	selectCompany(params): void {
		this.companyList = true;
		this.companyId = params;
		this.showCompanyAdd = false;
		this.getCompanyDetail();
	}

	getCompanyDetail(): void {
		this.showActionLoader = true;
		this.companyService.getCompanySummary(this.companyId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {

			this.companyDetails = result.result.company;

			this.quickCompanyDetailsDescription = false;
			this.detailedDescription = false;
			this.showActionLoader = false;

			this.afterCompanyDetail.emit(this.companyId);
		});
	}

	cancelCompany(): void {
		this.addCompany = false;
	}

	companyCreated($event): void {
		this.companyname = $event.name;
		this.getRecentSearchComp();
		this.addCompany = false;
	}

	enableSave($event): void {
		if ($event == 'true') {
			this.enableSaveBtn = true;
		} else {
			this.enableSaveBtn = false;
		}
	}

}
