import {NgModule} from '@angular/core';
import {RangeInputDirective} from './range-input.directive';

@NgModule({
    declarations: [
        RangeInputDirective,
    ],
    exports: [
        RangeInputDirective
    ]
})
export class RangeInputModule { }
