<div [ngClass]="{ btn_crm_container: !moveToBottom, btn_footer: moveToBottom }">
    <button *ngIf="cancel" class="btn btn_customizable_grey value-execution-add-a-new-value-proposition-cancel-button" (click)="goBack.emit()" data-heap="value-execution-add-a-new-value-proposition-cancel-button">{{ trans.trans.cancel.value }}</button>
    <button *ngIf="back" class="btn btn_customizable_grey value-execution-add-a-new-value-proposition-back-button" (click)="goBack.emit()" data-heap="value-execution-add-a-new-value-proposition-back-button">{{ trans.trans.back.value }}</button>
    <button
        *ngIf="jumpToResultsFeature && jumpToResults && workflowReviewResultsCheck"
        style="margin-left: 10px"
        class="btn btn_customizable_grey value-execution-add-a-new-value-proposition-review-results-button"
        [disabled]="disabled"
        (click)="jumpResults.emit('results')"
        data-heap="value-execution-add-a-new-value-proposition-review-results-button"
    >
        {{ trans.trans.reviewResults.value }}
    </button>
    <button *ngIf="next && workflowContinueCheck" [disabled]="disabled" style="margin-left: 10px" class="btn btn_customizable xf-continue-button value-execution-add-a-new-value-proposition-continue-button" (click)="goNext.emit()" data-heap="value-execution-add-a-new-value-proposition-continue-button">
        {{ trans.trans.continue.value }}
    </button>
</div>
