import { DLMenubarComponent } from './menubar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DLMenubarSubModule } from '@shared_components/primeng_menubarsub/menubarsub.module';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgbModule,
		DLMenubarSubModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [DLMenubarComponent],
	exports: [DLMenubarComponent],
	entryComponents: [DLMenubarComponent],
	providers: [
		Translations,
	]
})
export class DLMenubarModule { }