import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiServiceService } from '../api-service.service';
import { LeftNaviTranslations } from './leftnavi.translation';
import { accountDefaultColors } from '@shared/utils';

@Component({
  selector: 'app-leftnavi',
  templateUrl: './leftnavi.component.html',
  styleUrls: ['./leftnavi.component.scss'],
})
export class LeftnaviComponent implements OnInit, OnDestroy {
  isLoggedIn$: boolean;
  isIntake$: boolean;
  dlAdmin = false;
  isValuePropUser = false;
  isValuePositionUser = false;
  isValueRealizationUser = false;
  isValueInsightUser = false;
  isSolutionAdmin = false;
  isValueRealized2 = false;
  masterValuePropFeature = false;
  accountId: string;
  lmsUrl: string;
  isGuest = false;
  @Input() isMobile = false;
  isDev = false;

  @Input() menuToggle = true;
  @Output() toggleMenu = new EventEmitter();

  subscriptionloggedIn: Subscription;
  subscriptionchangeLanguage: Subscription;
  subscriptionprofiledetails: Subscription;
  subscriptionreadLMSPath: Subscription;

  showTranslate = false;
  isAdmin = false;
  @Input() hash_id = false;

  modelsV2 = false;
  ngUnsubscribe = new Subject();
  feature31 = false;

  constructor(
    private router: Router,
    private apiServiceService: ApiServiceService,
    public accountService: AccountService,
    public commonService: CommonService,
    public trans: LeftNaviTranslations,
    private translationService: TranslationsV2Service,
    private userService: UserService,
    private solutionService: SolutionService,
  ) {}

  ngOnInit(): void {
    this.isDev = this.commonService.isDev$;
    this.feature31 = this.commonService.checkFeature('31');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.isAdmin = sessionStorage.getItem('isAdmin') === 'true' ? true : false;

    this.accountId = sessionStorage.getItem('aid');

    this.subscriptionloggedIn = this.apiServiceService.loggedIn$.subscribe((res) => {
      if (res) {
        this.isLoggedIn$ = true;
        this.isAdmin = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
      } else {
        this.isLoggedIn$ = false;
      }
    });

    const exp = '@decisionlink.com';
    const userEmail = sessionStorage.getItem('email');
    if (userEmail.indexOf(exp) !== -1) {
      this.dlAdmin = true;
    }

    this.subscriptionprofiledetails = this.apiServiceService.profiledetails.subscribe(() => {
      this.setModuleLicense();
      this.parseFeatures();
    });

    this.setModuleLicense();
    this.parseFeatures();

    this.readLMSPath();
    const rid = sessionStorage.getItem('rid');
    if (rid === '7') {
      this.isGuest = true;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionloggedIn) {
      this.subscriptionloggedIn.unsubscribe();
    }
    if (this.subscriptionchangeLanguage) {
      this.subscriptionchangeLanguage.unsubscribe();
    }
    if (this.subscriptionprofiledetails) {
      this.subscriptionprofiledetails.unsubscribe();
    }
    if (this.subscriptionreadLMSPath) {
      this.subscriptionreadLMSPath.unsubscribe();
    }
  }

  funcToggleSupport(): void {
    this.userService.toggleSupportSidebar.next('');
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

  funcToggleMenu(): void {
    if (this.isMobile) {
      if (this.menuToggle) {
        this.toggleMenu.emit();
      }
    }
  }

	logout() {
		this.translationService.destroyTranslationCache();
    this.solutionService.destroySituationTreeMap();
		sessionStorage.clear();
		localStorage.clear();
		heapReset();
		localStorage.removeItem('AnalyticsSession');
		this.router.navigate(['/login']);
		window.location.reload();
        this.commonService.setAccountColors(accountDefaultColors.header, accountDefaultColors.btnBg, accountDefaultColors.btnText);
	}

  setModuleLicense(): void {
    if (sessionStorage.getItem('products')) {
      const products = JSON.parse(sessionStorage.getItem('products'));

      this.isValuePropUser = false;
      this.isValuePositionUser = false;
      this.isValueRealizationUser = false;
      this.isValueInsightUser = false;
      if (products && products.length) {
        products.forEach((item) => {
          if (item.name === 'Value Positions') {
            this.isValuePositionUser = true;
          } else if (item.name === ' Value Propositions') {
            this.isValuePropUser = true;
          } else if (item.name === 'Value Realizations') {
            this.isValueRealizationUser = true;
          } else if (item.name === 'Value Insights') {
            this.isValueInsightUser = true;
          } else if (item.name === 'VR 2.0') {
            this.isValueRealized2 = true
          }
        });
      }
    }

    if (sessionStorage.getItem('privileges')) {
      const privs = sessionStorage.getItem('privileges');
      const model_admin = privs.indexOf('2');
      if (model_admin !== -1) {
        this.isSolutionAdmin = true;
      }
    }
  }


	parseFeatures() {
		this.masterValuePropFeature = false;
		const features = sessionStorage.getItem('features');
		const accountFeatures = features.split(',');

		if (accountFeatures.indexOf('15') >= 0) {
			this.masterValuePropFeature = true;
		}

		if (accountFeatures.indexOf('38') >= 0) {
			this.modelsV2 = true;
		}
	}

	readLMSPath() {
		this.subscriptionreadLMSPath = this.accountService.readLMSPath(this.accountId).subscribe(response => {
			if (response.result.result && typeof response.result.result === 'object' && response.result.result.lms_custom_path) {
				this.lmsUrl = response.result.result.lms_custom_path;
			} else {
				this.lmsUrl = 'https://decisionlink.talentlms.com'
			}
		});
	}
}
