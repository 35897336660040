import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
	public config: any = {
		component: 'score-card',
		display_name: 'Score Card VP Dashboard',
		description: 'Score card tab in vp dashboard top panel'
    }
    public trans: any = {
        Status: "Status",
        scorecard: 'Scorecard',
		PhasingActivated: "Phasing Activated",
		DriverComplete: "Driver Complete %",
		FinancialComplete: "Financial Complete %",
        ImprovementComplete: "Improvement Complete %",
        total_benefits: "Total benefits $",
        ch_scorecard: "Quick check of your completion that can be used to evaluate whether your value is close to sharing with a customer or close to being fully iterated.  This also includes whether your value metrics are within reasonable tolerance.",
    }
}