import { NgModule } from '@angular/core';

import { PromoteRealizationComponent } from './promote-realization.component';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PromoteRealizationTranslations } from './promote-realization.translation';
import { UserService } from '@data/services/user/user.service';
import { DropdownModule } from 'primeng/dropdown';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { ProgressBarModule } from 'primeng/progressbar';

@NgModule({
  imports: [
    DropdownModule,
    NGFormSaveButtonModule,
    EditTranslationsModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    DLNGErrorDirectiveModule,
    FormsModule,
    AreYouSureModule,
    ReactiveFormsModule,
    ErrorInputWrapperModule,
    FunctionPipeModule,
    ProgressBarModule,
  ],
  exports: [PromoteRealizationComponent],
  declarations: [PromoteRealizationComponent],
  providers: [
    TranslationsV2Service,
    PromoteRealizationTranslations,
    UserService
  ],
})
export class PromoteRealizationModule { }
