import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fallbackValue',
})
export class FallbackValuePipe implements PipeTransform {
  transform(...values: Array<string | number>): number {
    return values.find((value) => value !== '' && !isNaN(+value)) as number;
  }
}
