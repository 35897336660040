import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from '../../combined.translation';
import { Scalers } from 'app/repv2/views/scenario_combined_vpg/views/scalers/scalers.interface';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-rsv2-sc-scalers',
  templateUrl: './scalers.component.html',
})
export class RSV2SCScalersComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  @Output() reloadVP = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate: boolean = false;

  feature26: boolean = false;
  edit: boolean = false;
  loading: boolean = false;
  scalers: Scalers[] = [];
  scalersControl: Scalers[];
  is_default: boolean = false;
  saveLoading: boolean = false;
  showSizeYourValue: boolean = false;
  MasterScalerList: { [klass: string]: any }[] = [];
  masterCalculateLoading: boolean = false;
  ValuePropId: any;
  crm: string;
  canEdit: boolean;

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: DefaultTranslations,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private RS: RepSimpleViewService,
    private masterValuePropService: MasterValuePropService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.crm = sessionStorage.getItem('crm');
    this.canEdit = this.valueProp.vp_can_edit;
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.ValuePropId = params['vpid'];
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.feature26 = this.commonService.checkFeature('26');
    this.edit = this.valueProp.scalers_done === '1' ? true : false;

    this.RS.refreshSimpleDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadScalers();
    });
    this.valuepropService.reloadScalers$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.loadScalers();
      }
    });
    this.loadScalers();
  }

  ngOnDestroy() {
    if (this.valueProp.done !== '1') {
      this.createScalerNext();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  closeScaler() {
    this.showSizeYourValue = false;
    this.loadScalers();
  }

  MasterScalerCalculate() {
    const payload = { scalers: [] };
    this.masterCalculateLoading = true;
    if (this.MasterScalerList !== undefined && this.MasterScalerList.length) {
      for (let i = 0; i < this.MasterScalerList.length; i++) {
        payload.scalers.push({
          master_scale_type_id: this.MasterScalerList[i].master_scale_type_id,
          value: this.MasterScalerList[i].value,
        });
      }
    }

    this.masterValuePropService
      .modifyMasterScaler(this.ValuePropId, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.masterCalculateLoading = false;
        this.loadScalers();
      });
  }

  loadScalers() {
    this.loading = true;
    this.scalers = [];
    const searchLimit = this.ValuePropId;
    this.valuepropService
      .getScalers(searchLimit, 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.scalers = response.result.scalers;

          if (this.scalers.length === 0 || !this.scalers) {
            this.loading = false;
          }
          this.is_default = response.result.is_default == 1 ? true : false;

          this.MasterScalerList = response.result.master_scalers;

          if ( this.MasterScalerList !== undefined ) {
            this.MasterScalerList = this.MasterScalerList?.map((item) => {
              if (item['greater_than_zero'] === '1') {
                item['greater_than_zero'] = true;
              } else {
                {
                  item['greater_than_zero'] = false;
                }
              }
              return item;
            });
          } else {
            this.MasterScalerList = [];
          }

          this.scalers = this.scalers.map((item) => {
            if (item['name'] === 'includeDNS') {
              item['value'] = 0;
            }
            if (item['greater_than_zero'] === '1') {
              item['greater_than_zero'] = true;
            } else {
              {
                item['greater_than_zero'] = false;
              }
            }
            return item;
          });
          this.scalers = this.scalers.filter((item) => {
            return item['name'] != 'Does Not Scale';
          });
          this.scalersControl = cloneDeep(this.scalers);
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
  }

  createScaler() {
    this.saveLoading = true;
    for (let s = 0; s < this.scalers.length; s++) {
      const scale = this.scalers[s];
      scale.source_type_id = this.checkScaler(scale.id) ? '4' : scale.source_type_id;
      if (scale.greater_than_zero && (scale.value === 0 || !scale.value)) {
        this.saveLoading = false;
        this.notificationService.error(scale.name + ' is required', false);
        return false;
      }
    }

    const payload = {
      value_prop_scalers: this.scalers,
      angular_dev: 1,
    };
    const searchLimit = this.ValuePropId;
    this.valuepropService
      .createScalers(searchLimit, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        const payload2 = { scalers_done: 1 };
        this.RS.putDone(this.ValuePropId, payload2)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.valueProp.scalers_done = '1';
            this.edit = true;
            this.loadScalers();
          });

        if (response.result && response.result.success !== false) {
          this.notificationService.success('Scalers updated', false);
          this.reloadVP.emit();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.showSizeYourValue = false;
        this.saveLoading = false;
      });
  }

  createScalerNext() {
    this.saveLoading = true;
    for (let s = 0; s < this.scalers.length; s++) {
      const scale = this.scalers[s];
      scale.source_type_id = this.checkScaler(scale.id) ? '4' : scale.source_type_id;
      if (scale.greater_than_zero && (scale.value == 0 || !scale.value)) {
        this.saveLoading = false;
        this.notificationService.error(scale.name + ' is required', false);
        return false;
      }
    }

    if (!this.is_default) {
      return false;
    }

    const payload = {
      value_prop_scalers: this.scalers,
      angular_dev: 1,
    };
    const searchLimit = this.ValuePropId;

    this.valuepropService
      .createScalers(searchLimit, payload)
      .pipe(take(1))
      .subscribe(() => {});
  }

  createScalerNext$(): Observable<any> {
    this.saveLoading = true;
    for (let s = 0; s < this.scalers.length; s++) {
      const scale = this.scalers[s];
      scale.source_type_id = this.checkScaler(scale.id) ? '4' : scale.source_type_id;
      if (scale.greater_than_zero && (scale.value == 0 || !scale.value)) {
        this.saveLoading = false;
        this.notificationService.error(scale.name + ' is required', false);
        return of(false);
      }
    }

    if (!this.is_default) {
      return of(false);
    }

    const payload = {
      value_prop_scalers: this.scalers,
      angular_dev: 1,
    };
    const searchLimit = this.ValuePropId;

    return this.valuepropService.createScalers(searchLimit, payload);
  }

  editScalers(): void {
    this.edit = false;
  }

  private checkScaler(id: string): boolean {
    const scaler = this.scalers.find((scaler) => scaler.id === id);
    const controlScaler = this.scalersControl.find((scaler) => scaler.id === id);
    if (scaler && controlScaler) {
      return String(scaler.value) !== String(controlScaler.value);
    } else {
      return false;
    }
  }
}
