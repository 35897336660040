import { Injectable } from '@angular/core';

@Injectable()
export class KeywestTranslations {
  public config: any = {
    component: 'keywestNavBar',
    display_name: 'Navigation Menu',
    description: 'Navigation menu on the left side and found on the whole application',
  };

  public trans: any = {
    dashboard: 'Dashboard',
    dl_only: 'DL Only',
    learn: 'Learn',
    model: 'Models',
    profile: 'Profile',
    reach: 'Hypotheses',
    research: 'Insights',
    retain: 'Realizations',
    revise: 'Cases',
    support: 'Support',
    logout: 'Logout',
    switch_languages: 'Switch Languages',
    value_trackers: 'Value Trackers',
    edit_translations: 'Edit Translations',
    caseStudies: 'Case Studies',
    valueGroups: 'Value Groups',
    settings: 'Settings',
    value_realized: 'Value Realized',
    translations: 'Edit Translations',
    community: 'Community',
  };
}
