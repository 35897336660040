import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { ResultsAnalysisModule } from '@shared_components/results-analysis/results-analysis.module';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ApplicationPipesModule } from '../../../../pipe/pipe.module';
import { BenefitsMiniModule } from '../../benefits/benefits-mini/mini.module';
import { BenefitsMiniV2Module } from '../../benefits_v2/views/mini/mini.module';
import { ScratchPadModule } from '../../benefits_v2/views/scratchpad/scratchpad.module';
import { AssumptionsComponent } from './assumptions.component';

@NgModule({
	imports: [
		DLProgressbarModule,
		MenuModule,
		CurrencyMaskModule,
		ScratchPadModule,
		BenefitsMiniV2Module,
		SliderModule,
    MatTooltipModule,
		BenefitsMiniModule,
		OverlayPanelModule,
		TableModule,
		MenubarModule,
		ApplicationPipesModule,
		SidebarModule,
		CommonModule,
		NgbModule,
		EditTranslationsModule,
		FormsModule,
		AreYouSureModule,
		ResultsAnalysisModule,
        ValuePropFlowButtonsModule,
	],
	declarations: [
		AssumptionsComponent,

	],
	providers: [Translations],
	exports: [AssumptionsComponent]
})
export class AssumptionsModule { }
