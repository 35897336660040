import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';
import { AccountFormModule } from '../account-form/account-form.module';
import { RSV2SCAccountComponent } from './account.component';

@NgModule({
  imports: [
    DLProgressbarModule,
    AreYouSureModule,
    TreeModule,
    NgbModule,
    MatTooltipModule,
    SidebarModule,
    DropdownModule,
    FieldsetModule,
    TableChartModule,
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    EditTranslationsModule,
    AccountFormModule,
  ],
  declarations: [RSV2SCAccountComponent],
  providers: [],
  exports: [RSV2SCAccountComponent],
  entryComponents: [RSV2SCAccountComponent],
})
export class RSV2AccountModule {}
