import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyService } from '@data/services/company/company.service';
import { UserService } from '@data/services/user/user.service';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { Subscription, Subject } from 'rxjs';
import { CreditsTranslation } from './credits.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-credits',
	templateUrl: './credits.component.html',
	styleUrls: ['./credits.component.scss'],
	providers: [CompanyService]
})
export class CreditsComponent implements OnInit, OnDestroy {

	subscriptiongetUserCredits: Subscription;
	subscriptiongetAccountCredits: Subscription;
	subscriptionassignUserCredits: Subscription;
	subscriptiongetUsersList: Subscription;

	ngUnsubscribe = new Subject();
	showTranslate = false;

	constructor(
		private companyService: CompanyService,
		private commonService: CommonService,
		private userService: UserService,
		private notification: NotificationService,
		private translationService: TranslationsV2Service,
		public trans: CreditsTranslation,
	) {

	}
	userCredits: number;
	accountCredits: number;
	accountUsers: any = [];
	AssignedCredit: any;
	selectedUser: any;
	assignCredits: any;

	ngOnInit() {
		this.getUserCredits();
		this.getAccountCredits();
		this.getAccountUsers();

		  this.getTranslations();
		  this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		  });
		  this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		  });
	}

	ngOnDestroy() {
		if (this.subscriptiongetUserCredits) { this.subscriptiongetUserCredits.unsubscribe(); }
		if (this.subscriptiongetAccountCredits) { this.subscriptiongetAccountCredits.unsubscribe(); }
		if (this.subscriptionassignUserCredits) { this.subscriptionassignUserCredits.unsubscribe(); }
		if (this.subscriptiongetUsersList) { this.subscriptiongetUsersList.unsubscribe(); }

		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

	getUserCredits() {
		this.subscriptiongetUserCredits = this.companyService.getUserCredits().subscribe(result => {
			this.userCredits = result.result.credits;
		});
	}

	getAccountCredits() {
		this.subscriptiongetAccountCredits = this.companyService.getAccountCredits().subscribe(result => {
			this.accountCredits = result.result.credits;
		});
	}

	assignUserCredits() {
		var assignUserCredits = {
			"creditsToAssign": this.AssignedCredit,
			"toUserID": this.selectedUser
		};
		this.subscriptionassignUserCredits = this.companyService.assignUserCredits(assignUserCredits).subscribe(result => {
			this.notification.success(result.result.message, false);
			this.getAccountCredits();
			this.ClearValues();
		});
	}

	ngOnSubmit() {
		var assignUserCredits = {
			"creditsToAssign": this.AssignedCredit,
			"toUserID": this.selectedUser
		};
		this.subscriptionassignUserCredits = this.companyService.assignUserCredits(assignUserCredits).subscribe(result => {
			this.notification.success(result.result.message, false);
			this.getAccountCredits();
			this.ClearValues();
		});
	}

	getAccountUsers() {
		this.subscriptiongetUsersList = this.userService.getUsersList("").subscribe(result => {
			this.accountUsers = result.result.data;
		});
	}

	ClearValues() {
		this.AssignedCredit = "";
		this.selectedUser = "";
		this.assignCredits = false;
	}
}
