import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './r_list.translation';
import { takeUntil } from 'rxjs/operators';
import { Model } from '@data/services/account/models/model.interface';

@Component({
	selector: 'app-r-solution-list',
	templateUrl: './r_list.component.html'
})
export class RSolutionListComponent implements OnInit, OnDestroy {
	@Input() can_edit = false;

	@Input() frmSolutionObj: Partial<Model> = {
		name: '',
		description: '',
		avg_deployment_time: '',
		typical_deployment_type: '',
		quantity_enabled: '',
		avg_sale_price: '',
		avg_sale_cycle: '',
		id: '',
		solution_category_id: [],
		category: undefined,
		category_names: undefined
	};

	sidebar_title = 'View Model';
	ngUnsubscribe = new Subject();
	showTranslate = false;

	constructor(
		private commonService: CommonService,
		public trans: DefaultTranslations,
		private translationService: TranslationsV2Service
	) { }

	ngOnInit(): void {
		this.frmSolutionObj.category = this.frmSolutionObj.category_names.split(',');
		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
			account_id: sessionStorage.getItem('aid'),
			component: this.trans.config.component,
			lang: langAbbr,
			localTranslations: this.trans.trans
		};
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}
}
