import { NgModule } from "@angular/core";
import { BreadcrumbComponent } from "./breadcrumb.component";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from "@angular/router";

@NgModule({
	imports: [
		BrowserModule,
		RouterModule
	],
	declarations: [
		BreadcrumbComponent
	],
	exports: [
		BreadcrumbComponent
	]
})

export class BreadCrumbModule { }
