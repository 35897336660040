import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { ErrorMessagesService } from '@services/error-messages.service';
import { NotificationService } from '@services/notification.service';
import { CaseStudy } from '@shared/models/case-study.model';
import { Company } from '@shared/models/company.model';
import { CustomValidators } from 'app/_utils/custom-validators';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { CaseStudyPayload } from '../interfaces/case-study-payload.interface';
import { CaseStudyDetailTranslations } from './casestudy-detail.translation';

@Component({
  selector: 'app-casestudy-detail2',
  templateUrl: './casestudy-detail.component.html',
  styleUrls: ['./casestudy-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CasestudyDetailComponent implements OnInit, OnDestroy {
  @Input() caseStudyId:  string | number;
  @Input() type: string;
  @Input() myCaseStudy: CaseStudy;
  @Input() canEdit = false;

  ngUnsubscribe = new Subject();

  caseStudy: CaseStudy;
  showCaseStudy = false;
  isAccountSolution: number;
  changeName = false;
  assetUrl = '';
  assetGenerator = '';
  showTranslate = false;
  errorDict = new Map<string, string>();
  referenceUrlControl = new FormControl('', [CustomValidators.urlValidator(true)]);

  companyDetails: Company;
  toggleEditCaseStudy = false;
  loadingSave = false;
  fromRoute = false;
  isSolutionAdmin = false;
  caseStudyEdit: { [klass: string]: any };

  constructor(
    private solutionService: SolutionService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private assetService: AssetService,
    private ValueRealizationService: ValuerealizationService,
    public trans: CaseStudyDetailTranslations,
    private companyService: CompanyService,
    private translationService: TranslationsV2Service,
    private route: ActivatedRoute,
    private errorMessagesService: ErrorMessagesService
  ) {}

  ngOnInit() {
    this.errorMessagesService.getTranslations();

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      if (params['case_study_id']) {
        this.canEdit = true;
        this.fromRoute = true;
        this.caseStudyId = params['case_study_id'];
        this.getCaseStudyDetail();
      }
    });
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    if (!this.fromRoute) {
      this.getCaseStudyDetail();
    }
    this.assetUrl = this.commonService.getAssetUrl();
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');

    this.canEdit = this.commonService.checkPrivilege(2);

    this.setRefUrlErrorDict();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setRefUrlErrorDict() {
    this.referenceUrlControl.valueChanges.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.errorDict = new Map(this.errorMessagesService.getFormErrors({ referenceUrlControl: this.referenceUrlControl }));
    });
  }

  close(): void {
    this.toggleEditCaseStudy = false;
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getCaseStudyDetail() {
    this.isAccountSolution = this.type == 'solution' ? 1 : 0;
    if (this.isAccountSolution) {
      if (+this.myCaseStudy.value_prop_id !== 0 && +this.myCaseStudy.is_account_solution === 0) {
        this.isAccountSolution = 0;
      }
    }

    if (this.fromRoute) {
      this.isAccountSolution = 1;
    }

    this.solutionService.getCaseStudyDetail(this.caseStudyId, this.isAccountSolution).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response.result && response.result.case_study) {
        this.caseStudy = response.result.case_study[0];
        if (!this.caseStudy) {
          this.caseStudy = new CaseStudy();
          this.caseStudy.tags = [];
        }
        if (this.caseStudy.tag) {
          this.caseStudy.tags = this.caseStudy.tag && this.caseStudy.tag.length ? this.caseStudy.tag.split(',') : [];
        }
        this.referenceUrlControl.setValue(this.caseStudy.reference_url);

        this.getCompanyDetail(this.caseStudy.company_id);
        this.showCaseStudy = true;
      }
    });
  }

  getCompanyDetail(id) {
    this.companyService.getCompanySummary(id).subscribe((result) => {
      if (result.result) {
        this.companyDetails = result.result.company;
      }
    });
  }

  getHostnameFromRegex(url) {
    if (url === undefined) {
      return false;
    }
    if (!url) {
      url = this.companyDetails['name'] + '.com';
    }
    const sourceString = url.replace('http://', '').replace('https://', '').replace('www.', '').replace('.com.com', '.com').split(/[/?#]/)[0];
    return sourceString;
  }

  testImage(URL) {
    const tester = new Image();
    tester.src = URL;
  }

  funcToggleEditCaseStudy() {
    this.toggleEditCaseStudy = !this.toggleEditCaseStudy;
    this.caseStudyEdit = Object.assign({}, this.caseStudy);
  }

  downloadAsset() {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.assetService.getCaseStudyAssetTemplate().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      if (response && response !== null && typeof response !== 'string') {
        const downloadUrl =
          this.assetUrl +
          'assets/' +
          this.assetGenerator +
          '?account_asset_template_id=' +
          response.result.id +
          '&user_id=' +
          userId +
          '&token=' +
          token +
          '&case_study_id=' +
          this.caseStudyId +
          '&is_account_solution=' +
          this.isAccountSolution;

        window.location.assign(downloadUrl);
      } else {
        this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }

  populatePayloadFromEvent(payload: CaseStudyPayload): void {
    if (!payload.reference_url) {
      payload.reference_url = this.referenceUrlControl.value;
    }

    this.updateCaseStudyDetails(payload);
  }

  populatePayloadFromSidebar(): void {
    const payload: CaseStudyPayload = {
      case_study_id: this.caseStudy.id,
      name: this.caseStudyEdit.name,
      tag: this.caseStudyEdit.tags ? this.caseStudyEdit.tags.join() : '',
      reference_url: this.referenceUrlControl.value,
    };

    this.updateCaseStudyDetails(payload);
  }

  updateCaseStudyDetails(payload: CaseStudyPayload): void {
    if (this.referenceUrlControl.invalid) {
      return;
    }

    this.ValueRealizationService.updateCaseStudyDetails(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result.success) {
          this.toggleEditCaseStudy = false;
          this.getCaseStudyDetail();
          this.notificationService.success('Updated successfully!', false);
        } else {
          this.notificationService.error('Something went wrong.', false);
        }
      });
  }

  cancelNameChange() {
    this.changeName = false;
  }
}
