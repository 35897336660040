import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TableModule } from 'primeng/table';

import { HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AccountService } from '@data/services/account/account.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule } from 'primeng/progressbar';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeTableModule } from 'primeng/treetable';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { MenuModule } from 'primeng/menu';
import { ClipboardModule} from '@angular/cdk/clipboard';
import { TabViewModule } from 'primeng/tabview';
import { BulkUpdateComponent } from '../bulk-update/bulk-update.component';
import { BulkUpdateTranslation } from '../bulk-update/bulk-update.translation';
import { SelectLanguageModule } from '@shared/select-language/select-language.module';
import { MatTooltipModule } from '@angular/material/tooltip';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  {
    path: '',
    component: BulkUpdateComponent,
  },
];

@NgModule({
  imports: [
    AreYouSureModule,
    ProgressBarModule,
    SidebarModule,
    InputSwitchModule,
    TreeTableModule,
    MultiSelectModule,
    MatTooltipModule,
    MenuModule,
    EllipsisMenuModule,
    EditTranslationsModule,
    TableModule,
    NgbModule,
    BreadcrumbsModule,
    CommonModule,
    FormsModule,
    ClipboardModule,
    TabViewModule,
    SelectLanguageModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    BulkUpdateComponent,
  ],
  providers: [
    AccountService,
    ValuepropService,
    BulkUpdateTranslation,
    TranslationsV2Service
  ],
  entryComponents: [BulkUpdateComponent],
  exports: [BulkUpdateComponent],
})
export class BulkUpdateModule {}
