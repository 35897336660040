<!--LIST-->
<div *ngIf="toggles.showList">
  <div class="col-md-12">
    <button type="button" *ngIf="canEdit" class="btn btn_customizable pull-right" (click)="toggleAddSituation(false)" matTooltip="Add New" matTooltipPosition="above">
      <i class="fa fa-plus"></i> {{ trans.SITUATION.add_new | translate }}
    </button>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <p-treeTable [value]="situations">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ trans.SITUATION.name | translate }}</th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode">
            <td>
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              {{ rowData.name }}
            </td>
            <td>
              <span *ngIf="canEdit && rowData.is_leaf" (click)="deleteSituation(rowData.id)" class="pull-right clickable" style="margin-right: 10px"
                ><i class="fa fa-times"></i
              ></span>
              <span *ngIf="canEdit && rowData.is_leaf" (click)="showEditView(rowData)" class="pull-right clickable" style="margin-right: 10px"><i class="fa fa-pencil"></i></span>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="showAddView" styleClass="p-sidebar-md" position="right">
  <div class="row">
    <div class="col-xs-12">
      <h3>{{ trans.SITUATION.add_situation | translate }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <label>{{ trans.SITUATION.situation_name | translate }}</label>
      <input type="text" name="name" [(ngModel)]="addNewObj.name" class="form-control" />
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <label>Situation Description</label>
      <textarea name="description" [(ngModel)]="addNewObj.description" class="form-control" rows="4"></textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <a (click)="funcAddNestedSituation()" class="btn btn_customizable">{{ trans.SITUATION.save | translate }}</a>
    </div>
  </div>
</p-sidebar>
<!--SITUATION FORM-->
<div class="col-md-12" *ngIf="toggles.showSituationForm">
  <div class="back-btn">
    <span class="a-link-color" (click)="clearSituation(); toggleShowList()">
      <i class="fa fa-angle-left fa-2x marg-h" title="Back to the list " aria-hidden="true "></i>{{ trans.SITUATION.back | translate }}
    </span>
  </div>

  <form name="SituationForm" #SituationForm="ngForm" (ngSubmit)="saveSituation()">
    <div *ngIf="mode == 'add'">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label for="situation_type" class="labelsRequired">{{ trans.SITUATION.situation_type | translate }}</label>
            <p-dropdown appendTo="body"
              class="keywest_dropdown"
              [options]="situationTypes"
              name="situation_type_id"
              [(ngModel)]="situationTypeIdObj"
              placeholder="Select a Stiuation Type"
              (onChange)="assignSituationTypeId()"
              [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
              [required]="true"
            ></p-dropdown>
          </div>
        </div>
        <div class="col-md-4 situationBtn" *ngIf="!edit"></div>
      </div>
    </div>

    <div class="situation-form clearfix">
      <div style="margin-bottom: 20px" *ngIf="mode == 'edit'">
        <p-treeTable [value]="tree">
          <ng-template pTemplate="header">
            <tr>
              <th>{{ trans.SITUATION.name | translate }}</th>
              <th>{{ trans.SITUATION.description | translate }}</th>
              <th>{{ trans.SITUATION.status | translate }}</th>
              <th style="width: 100px"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttRow]="rowNode">
              <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{ rowData.name }}
              </td>
              <td>{{ rowData.description }}</td>
              <td></td>
              <td>
                <span *ngIf="canEdit" (click)="funcShowAddView(rowData.situation_element_id, rowData.situation_type_id)" class="pull-right clickable" style="margin-right: 10px"
                  ><i class="fa fa-plus"></i
                ></span>
              </td>
            </tr>
          </ng-template>
        </p-treeTable>
      </div>
      <form name="SituationForm " (ngSubmit)="saveSituation()">
        <div *ngIf="mode == 'add'">
          <div class="form-group">
            <label for="situation_element " class="labelsRequired"> {{ trans.SITUATION.situation_type_elements | translate }} </label>
            <select
              autocomplete="off "
              class="form-control situationControl"
              name="situation_element "
              [(ngModel)]="situation.situation_element_id"
              required
              *ngIf="situationTypeElements.length > 0"
              (change)="checkForChildren1(situation.situation_element_id)"
            >
              <option value="" disabled selected>{{ trans.SITUATION.select_your_option | translate }}</option>
              <option *ngFor="let situationTypeElement of situationTypeElements" [value]="situationTypeElement.id">
                {{ situationTypeElement.name }}
              </option>
            </select>
            <div *ngIf="situationTypeElementsLoader">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </div>
            <div class="well" *ngIf="situationTypeElements.length == 0 && !situationTypeElementsLoader">
              {{ trans.SITUATION.select_a_situation_type_from_the_dropdown_above_to_proceed | translate }}
            </div>
          </div>
          <div class="form-group" *ngIf="elementChildren1 && elementChildren1.length > 0">
            <label> {{ trans.SITUATION.children_of_parent_name | translate }} {{ parent1Name }} </label>
            <select
              autocomplete="off "
              class="form-control situationControl"
              name="situation_element "
              [(ngModel)]="situation.situation_element_id"
              required
              *ngIf="elementChildren1.length > 0"
              (change)="checkForChildren2($any($event).target.value)"
              (blur)="checkForChildren2($any($event).target.value)"
            >
              <option value="" disabled selected>{{ trans.SITUATION.select_your_option | translate }}</option>
              <option *ngFor="let childElement1 of elementChildren1" [value]="childElement1.id">
                {{ childElement1.name }}
              </option>
            </select>
            <div *ngIf="situationTypeElementsLoader">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </div>
            <div class="well" *ngIf="situationTypeElements.length == 0 && !situationTypeElementsLoader">
              {{ trans.SITUATION.select_a_situation_type_from_the_dropdown_above_to_proceed | translate }}
            </div>
          </div>
          <div class="form-group" *ngIf="elementChildren2 && elementChildren2.length > 0">
            <label> {{ trans.SITUATION.children_of_parent_name | translate }} {{ parent2Name }} </label>
            <select
              autocomplete="off "
              class="form-control situationControl"
              name="situation_element "
              [(ngModel)]="situation.situation_element_id"
              required
              *ngIf="elementChildren2.length > 0"
              (change)="checkForChildren3($any($event).target.value)"
              (blur)="checkForChildren3($any($event).target.value)"
            >
              <option value="" disabled selected>{{ trans.SITUATION.select_your_option | translate }}</option>
              <option *ngFor="let childElement2 of elementChildren2" [value]="childElement2.id">
                {{ childElement2.name }}
              </option>
            </select>
            <div *ngIf="situationTypeElementsLoader">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </div>
            <div class="well" *ngIf="situationTypeElements.length == 0 && !situationTypeElementsLoader">
              {{ trans.SITUATION.select_a_situation_type_from_the_dropdown_above_to_proceed | translate }}.
            </div>
          </div>
          <div class="form-group" *ngIf="elementChildren3 && elementChildren3 && elementChildren3.length > 0">
            <label> {{ trans.SITUATION.children_of_parent_name | translate }} {{ parent3Name }} </label>
            <select
              autocomplete="off "
              class="form-control situationControl"
              name="situation_element "
              [(ngModel)]="situation.situation_element_id"
              required
              *ngIf="elementChildren3.length > 0"
              (change)="checkForChildren4($any($event).target.value)"
              (blur)="checkForChildren4($any($event).target.value)"
            >
              <option value="" disabled selected>{{ trans.SITUATION.select_your_option | translate }}</option>
              <option *ngFor="let childElement3 of elementChildren3" [value]="childElement3.id">
                {{ childElement3.name }}
              </option>
            </select>
            <div *ngIf="situationTypeElementsLoader">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </div>
            <div class="well" *ngIf="situationTypeElements.length == 0 && !situationTypeElementsLoader">
              {{ trans.SITUATION.select_a_situation_type_from_the_dropdown_above_to_proceed | translate }}
            </div>
          </div>
          <div class="form-group" *ngIf="elementChildren4 && elementChildren4.length > 0">
            <label> {{ trans.SITUATION.children_of_parent_name | translate }} {{ parent4Name }} </label>
            <select
              autocomplete="off "
              class="form-control situationControl"
              name="situation_element "
              [(ngModel)]="situation.situation_element_id"
              required
              *ngIf="elementChildren4.length > 0"
              (change)="checkForChildren5($any($event).target.value)"
              (blur)="checkForChildren5($any($event).target.value)"
            >
              <option value="" disabled selected>{{ trans.SITUATION.select_your_option | translate }}</option>
              <option *ngFor="let childElement4 of elementChildren4" [value]="childElement4.id">
                {{ childElement4.name }}
              </option>
            </select>
            <div *ngIf="situationTypeElementsLoader">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </div>
            <div class="well" *ngIf="situationTypeElements.length == 0 && !situationTypeElementsLoader">
              {{ trans.SITUATION.select_a_situation_type_from_the_dropdown_above_to_proceed | translate }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description">{{ trans.SITUATION.description | translate }}</label>
          <textarea class="form-control" rows="3" name="description" [(ngModel)]="situation.description"></textarea>
        </div>
        <div class="form-group">
          <label class="marg-h">{{ trans.SITUATION.active_by_default | translate }}</label>
          <input type="checkbox" name="default_nactive" class="customcheckbox" [(ngModel)]="situation.default_active" />
        </div>
        <div class="form-group" *ngIf="masterValuePropFeature">
          <label>{{ trans.SITUATION.available_for_guided_discovery | translate }}</label>
          <input type="checkbox" name="guided_discovery" class="customcheckbox" [(ngModel)]="situation.guided_discovery" />
        </div>
        <div class="form-group m-b-xxl clearfix">
          <button type="submit" class="btn btn_customizable pull-right done-btn" [disabled]="SituationForm.invalid">
            <span *ngIf="!edit">{{ trans.SITUATION.save | translate }}</span>
            <span *ngIf="edit">{{ trans.SITUATION.update | translate }}</span>
          </button>
        </div>
        <div class="form-group" *ngIf="toggles.showBenefitsCosts">
          <p-tabView>
            <p-tabPanel header="Benefits">

                <!--BENEFITS-->
                <div class="table-responsive col-sm-12" style="padding: 0px">
                  <div class="searchTableContainer">
                    <div class="clearfix"></div>
                    <div class="panel panel-white" style="border: 1px solid #f5f5f5">
                      <div class="panel-heading" style="width: 100%; height: 110px !important; background-color: #f5f5f5; padding-bottom: 0px">
                        <div class="dropdown" style="position: absolute">
                          <h3 data-toggle="dropdown" data-hover="dropdown" class="panel-title clickable">
                            {{ selectedProject ? selectedProject.name : 'Filter' }} <i class="fa fa-chevron-down"></i>
                          </h3>

                          <ul class="dropdown-menu dropdown-menu" role="menu" aria-labelledby="dLabel" style="width: 250px">
                            <li (click)="selectedProject = null">
                              {{ trans.SITUATION.view_all | translate }}
                            </li>
                            <li *ngFor="let p of projectsList" (click)="selectedProject = p">
                              {{ p.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="panel-body">
                        <table class="table custom-table">
                          <thead>
                            <tr>
                              <th>{{ trans.SITUATION.name | translate }}</th>
                              <th></th>
                              <!-- Non-Applicable By Default column -->
                              <th></th>
                              <!-- Benefit Activated column -->
                              <th></th>
                              <!-- Benefit values changed column -->
                              <th></th>
                              <!-- Benefit Actions -->
                            </tr>
                          </thead>
                          <tbody *ngIf="benefits && benefits.length == 0">
                            <td colspan="5">
                              <img class="actionLoader" src="{{ fullImagePath }}" />
                            </td>
                          </tbody>
                          <tbody *ngIf="situations == null && situations.length == 0">
                            <td colspan="5">
                              <h4>{{ trans.SITUATION.no_benefits_yet | translate }}</h4>
                            </td>
                          </tbody>
                          <tbody>
                            <ng-container *ngFor="let benefit of benefits">
                              <ng-container *ngIf="!selectedProject || selectedProject.id == benefit.project_id">
                                <tr class="grid-row company-row">
                                  <td>{{ benefit.metric_name }}</td>
                                  <td>
                                    <span class="off" *ngIf="benefit.default_na === '1'">
                                      {{ trans.SITUATION.non_applicable_by_default | translate }}
                                    </span>
                                  </td>
                                  <td>
                                    <div *ngIf="benefit.changeToggle">
                                      <span class="off" *ngIf="benefit.changedActive == 0"><strong>Off</strong></span>
                                      <span class="on" *ngIf="benefit.changedActive == 1"><strong>On</strong></span>
                                    </div>
                                  </td>
                                  <td>
                                    <div *ngIf="benefit.valueChangeToggle">
                                      <span class="on">{{ trans.SITUATION.values_changed | translate }}</span>
                                    </div>
                                  </td>
                                  <td class="">
                                    <div *ngIf="canEdit" class="dropdown pull-right">
                                      <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                                      <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                                        <li>{{ trans.SITUATION.what_do_you_want_to_happen_to_this_benefit_when_this_sitation_occurs | translate }}</li>
                                        <li (click)="saveSituationBenefitActive(1, benefit.id)">
                                          <i class="fa fa-square"></i>
                                          {{ trans.SITUATION.turn_it_on | translate }}
                                        </li>
                                        <li (click)="saveSituationBenefitActive(0, benefit.id)">
                                          <i class="fa fa-square-o"></i>
                                          {{ trans.SITUATION.turn_it_off | translate }}
                                        </li>
                                        <li (click)="showBenefitControls(changeBenefitWindow, benefit.id)">
                                          <i class="fa fa-sliders"></i>
                                          {{ trans.SITUATION.change_it | translate }}
                                        </li>
                                        <li (click)="saveSituationBenefitActive('NULL', benefit.id)">
                                          <i class="fa fa-undo"></i>
                                          {{ trans.SITUATION.reset_benefit | translate }}
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </p-tabPanel>
            <p-tabPanel header="Costs">

                <button type="button" class="btn btn-light pull-right" (click)="resetAllCost()" *ngIf="canEdit && costs && costs.length > 0">
                  {{ trans.SITUATION.reset_all_costs | translate }}
                </button>

                <div class="table-responsive col-sm-12">
                  <div class="searchTableContainer">
                    <table class="table custom-table">
                      <thead>
                        <tr>
                          <th>{{ trans.SITUATION.name | translate }}</th>
                          <th></th>
                          <!-- Cost Added to available cost -->
                          <th></th>
                          <!-- Cost Added to Autoload cost -->
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="grid-row company-row" *ngFor="let cost of costs">
                          <td>{{ cost.name }}</td>
                          <td>
                            <div *ngIf="cost.changeToggleAvailable == 1">
                              <span class="on">{{ trans.SITUATION.cost_added_to_available_cost | translate }}</span>
                            </div>
                          </td>
                          <td>
                            <div *ngIf="cost.changeToggleAutoload == 1">
                              <span class="on">{{ trans.SITUATION.cost_added_to_autoload | translate }}</span>
                            </div>
                            <div *ngIf="cost.changeToggleAutoload === '0'">
                              <span class="off">{{ trans.SITUATION.removed_from_autoload | translate }}</span>
                            </div>
                          </td>
                          <td class="">
                            <div *ngIf="canEdit" class="dropdown pull-right">
                              <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                              <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                                <li (click)="saveSituationCosts(cost, 'available')">
                                  <i class="fa fa-plus"></i>
                                  {{ trans.SITUATION.add_to_the_available_costs | translate }}
                                </li>
                                <li (click)="saveSituationCosts(cost, 'exclude')"><i class="fa fa-minus"></i>{{ trans.SITUATION.exclude_from_available_costs | translate }}</li>
                                <li (click)="saveSituationCosts(cost, 'autoload')"><i class="fa fa-sliders"></i>{{ trans.SITUATION.autoload_in_valueprop | translate }}</li>
                                <li (click)="saveSituationCosts(cost, 'removeauto')">
                                  <i class="fa fa-minus"></i>
                                  {{ trans.SITUATION.remove_from_valueprop | translate }}
                                </li>
                                <li (click)="saveSituationCosts(cost, 'reset')">
                                  <i class="fa fa-undo"></i>
                                  {{ trans.SITUATION.reset_cost | translate }}
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="situations.length == 0" class="m-t-md col-sm-10 comp_no_data">
                    <div class="searchTableContainer text-center">
                      <h4>{{ trans.SITUATION.no_costs_yet | translate }}</h4>
                    </div>
                  </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Case Studies">

                <div class="table-responsive col-sm-12">
                  <div class="searchTableContainer">
                    <table class="table custom-table" style="width: 100%">
                      <thead>
                        <tr>
                          <th>{{ trans.SITUATION.name | translate }}</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="grid-row company-row" *ngFor="let cs of caseStudies">
                          <td>{{ cs.name }}</td>
                          <td>
                            <div *ngIf="cs.changedActive !== null && cs.changedActive !== false">
                              <span class="off" *ngIf="cs.changedActive == '0'"
                                ><strong>{{ trans.SITUATION.off | translate }}</strong></span
                              >
                              <span class="on" *ngIf="cs.changedActive == '1'"
                                ><strong>{{ trans.SITUATION.on | translate }}</strong></span
                              >
                            </div>
                          </td>
                          <td class="">
                            <div *ngIf="canEdit" class="dropdown pull-right">
                              <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                              <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                                <li (click)="saveSituationCaseStudyActive(1, cs.id)">
                                  <i class="fa fa-square"></i>
                                  {{ trans.SITUATION.turn_it_on | translate }}
                                </li>
                                <li (click)="saveSituationCaseStudyActive(0, cs.id)">
                                  <i class="fa fa-square-o"></i>
                                  {{ trans.SITUATION.turn_it_off | translate }}
                                </li>
                                <li (click)="saveSituationCaseStudyActive('NULL', cs.id)">
                                  <i class="fa fa-undo"></i>
                                  {{ trans.SITUATION.reset_case_study | translate }}
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="situations.length == 0" class="m-t-md col-sm-10 comp_no_data">
                    <div class="searchTableContainer text-center">
                      <h4>{{ trans.SITUATION.no_case_studies_yet | translate }}</h4>
                    </div>
                  </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Assets">

                <div class="table-responsive col-sm-12">
                  <div class="searchTableContainer">
                    <table class="table custom-table">
                      <thead>
                        <tr>
                          <th>{{ trans.SITUATION.name | translate }}</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="!assets">
                        <tr>
                          <td colspan="3">
                            <h4>{{ trans.SITUATION.no_assets_yet | translate }}</h4>
                          </td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="assets && assets.length > 0">
                        <tr class="grid-row company-row" *ngFor="let asset of assets">
                          <td>{{ asset.name }}</td>
                          <td>
                            <div *ngIf="asset.changedActive !== null && asset.changedActive !== false">
                              <span class="off" *ngIf="asset.changedActive == '0'"
                                ><strong>{{ trans.SITUATION.off | translate }}</strong></span
                              >
                              <span class="on" *ngIf="asset.changedActive == '1'"
                                ><strong>{{ trans.SITUATION.on | translate }}</strong></span
                              >
                            </div>
                          </td>
                          <td class="">
                            <div *ngIf="canEdit" class="dropdown pull-right">
                              <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                              <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                                <li (click)="saveSituationAssetsActive(1, asset.id)">
                                  <i class="fa fa-square"></i>
                                  {{ trans.SITUATION.turn_it_on | translate }}
                                </li>
                                <li (click)="saveSituationAssetsActive(0, asset.id)">
                                  <i class="fa fa-square-o"></i>
                                  {{ trans.SITUATION.turn_it_off | translate }}
                                </li>
                                <li (click)="saveSituationAssetsActive('NULL', asset.id)">
                                  <i class="fa fa-undo"></i>
                                  {{ trans.SITUATION.reset_asset | translate }}
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Collateral">

                <div class="table-responsive col-sm-12">
                  <div class="searchTableContainer">
                    <table class="table custom-table">
                      <thead>
                        <tr>
                          <th>{{ trans.SITUATION.name | translate }}</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="!collateral">
                        <tr>
                          <td colspan="3">
                            <h4>{{ trans.SITUATION.no_collaterals_yet | translate }}</h4>
                          </td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="collateral">
                        <tr class="grid-row company-row" *ngFor="let coll of collateral">
                          <td>{{ coll.name }}</td>
                          <td>
                            <div *ngIf="coll.changedActive !== null && coll.changedActive !== false">
                              <span class="off" *ngIf="coll.changedActive == '0'"
                                ><strong>{{ trans.SITUATION.off | translate }}</strong></span
                              >
                              <span class="on" *ngIf="coll.changedActive == '1'"
                                ><strong>{{ trans.SITUATION.on | translate }}</strong></span
                              >
                            </div>
                          </td>
                          <td class="">
                            <div *ngIf="canEdit" class="dropdown pull-right">
                              <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                              <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                                <li (click)="saveSituationCollateralActive(1, coll.id)">
                                  <i class="fa fa-square"></i>
                                  {{ trans.SITUATION.turn_it_on | translate }}
                                </li>
                                <li (click)="saveSituationCollateralActive(0, coll.id)">
                                  <i class="fa fa-square-o"></i>
                                  {{ trans.SITUATION.turn_it_off | translate }}
                                </li>
                                <li (click)="saveSituationCollateralActive('NULL', coll.id)">
                                  <i class="fa fa-undo"></i>
                                  {{ trans.SITUATION.reset_collateral | translate }}
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

            </p-tabPanel>
            <p-tabPanel header="Projects" *ngIf="masterValuePropFeature">

                <div class="table-responsive col-sm-12">
                  <div class="searchTableContainer">
                    <table class="table custom-table">
                      <thead>
                        <tr>
                          <th>{{ trans.SITUATION.name | translate }}</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="grid-row company-row" *ngFor="let project of projectsList">
                          <td>{{ project.parent_name }} - {{ project.name }}</td>
                          <td>
                            <div *ngIf="project.active == '1'">
                              <span class="on">{{ trans.SITUATION.included_by_default | translate }}</span>
                            </div>
                          </td>
                          <td>
                            <div *ngIf="project.changedRecommend == '1'">
                              <span class="on">{{ trans.SITUATION.recommended | translate }}</span>
                            </div>
                          </td>
                          <td>
                            <div *ngIf="project.changedActive == '1'">
                              <span class="on">{{ trans.SITUATION.add_to_list | translate }}</span>
                            </div>
                            <div *ngIf="project.changedActive === '0'">
                              <span class="off">{{ trans.SITUATION.removed_from_list | translate }}</span>
                            </div>
                          </td>
                          <td class="">
                            <div class="dropdown pull-right">
                              <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                              <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                                <li (click)="saveSituationProjectsActive('addToList', project)"><i class="fa fa-plus"></i>{{ trans.SITUATION.add_to_list | translate }}</li>
                                <li (click)="saveSituationProjectsActive('removeFromList', project)">
                                  <i class="fa fa-minus"></i>{{ trans.SITUATION.remove_from_list | translate }}
                                </li>
                                <li (click)="saveSituationProjectsActive('addAndRecommend', project)">
                                  <i class="fa fa-plus"></i>{{ trans.SITUATION.add_to_list_and_recommend | translate }}
                                </li>
                                <li (click)="saveSituationProjectsActive('reset', project)"><i class="fa fa-undo"></i>{{ trans.SITUATION.reset | translate }}</li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="projectsList.length == 0" class="m-t-md col-sm-10 comp_no_data">
                    <div class="searchTableContainer text-center">
                      <h4>{{ trans.SITUATION.no_projects_yet | translate }}</h4>
                    </div>
                  </div>
                </div>

            </p-tabPanel>
          </p-tabView>
        </div>
      </form>
    </div>
  </form>
</div>
<!--END SITUATION FORM-->

<!--SITUATION TYPE-->
<div *ngIf="toggles.addType" class="col-md-12">
  <div class="back-btn">
    <span class="a-link-color" (click)="clearSituationType()">
      <i class="fa fa-angle-left fa-2x marg-h" title="Back to the list" aria-hidden="true"></i> {{ trans.SITUATION.back | translate }}
    </span>
  </div>

  <h3>{{ trans.SITUATION.new_situation_type | translate }}</h3>
  <form name="SituationTypeForm" #SituationTypeForm="ngForm" (ngSubmit)="saveSituationType()">
    <div
      class="form-group"
      [ngClass]="{ 'has-error': SituationTypeForm.controls.name?.errors && SituationTypeForm.controls.name?.invalid && SituationTypeForm.controls.name?.touched }"
    >
      <label for="name" class="labelsRequired">{{ trans.SITUATION.name | translate }}</label>
      <input type="text" class="form-control" name="name" id="name" placeholder="Name" [(ngModel)]="situationType.name" required />
    </div>
    <div class="form-group">
      <label for="description">{{ trans.SITUATION.description | translate }}</label>
      <textarea class="form-control" rows="3" name="description" [(ngModel)]="situationType.description"></textarea>
    </div>
    <div class="form-group">
      <label for="nested">{{ trans.SITUATION.will_this_be_a_nested_situation_type | translate }}</label>
      <input type="checkbox" id="nested" name="nested" />
    </div>
    <div>
      <fieldset>
        <legend class="labelsRequired">Element Type</legend>
        <div *ngFor="let elementType of situationElementTypes">
          <label class="radio-inline" for="element_type_id">
            <input type="radio" name="element_type_id" value="{{ elementType.id }}" [(ngModel)]="situationType.element_type_id" required />
            {{ elementType.name }}
          </label>
        </div>
      </fieldset>
    </div>

    <div class="form-group">
      <button type="submit" class="btn btn_customizable pull-right done-btn" [disabled]="SituationTypeForm.invalid">
        {{ trans.SITUATION.save | translate }}
      </button>
    </div>
  </form>
</div>
<!--ADD ELEMENT-->
<div *ngIf="toggles.addElement">
  <h3>{{ trans.SITUATION.new_element | translate }}</h3>
  <form name="ElementForm" #ElementForm="ngForm" class="well" (ngSubmit)="saveSituationTypeElement()">
    <div class="form-group" [ngClass]="{ 'has-error': ElementForm.controls.name?.errors && ElementForm.controls.name?.invalid && ElementForm.controls.name?.touched }">
      <label for="name" class="labelsRequired">{{ trans.SITUATION.name | translate }}</label>
      <input type="text" class="form-control" name="name" id="name" placeholder="Name" [(ngModel)]="situationElement.name" required />
    </div>
    <div class="form-group">
      <label for="description">{{ trans.SITUATION.description | translate }}</label>
      <textarea class="form-control" rows="3" name="description" [(ngModel)]="situationElement.description"></textarea>
    </div>

    <div class="form-group clearfix">
      <button type="submit" class="btn btn_customizable done-btn pull-right" [disabled]="ElementForm.invalid">
        {{ trans.SITUATION.save | translate }}
      </button>
    </div>
  </form>
</div>

<!-- SITUATION TYPE STACK ORDER -->
<div *ngIf="toggles.situation_type_stack_order">
  <div class="back-btn">
    <span class="a-link-color" (click)="toggleSituationTypeStackOrder()">
      <i class="fa fa-angle-left fa-2x marg-h" title="Back to the list " aria-hidden="true "></i> {{ trans.SITUATION.back | translate }}
    </span>
  </div>
  <div>
    <p class="small m-l-xxl m-b-xxl">{{ trans.SITUATION.to_change_situation_type_stack_order_drag_and_drop_situation_types_from_below_and_save | translate }}</p>
  </div>
  <div class="wrapper col-md-12">
    <div class="row">
      <div class="col-md-8">
        <p (click)="editSituationType(null)">
          {{ trans.SITUATION.situation_type | translate }}
        </p>
      </div>
      <div class="col-md-3 text-center">
        <strong>{{ trans.SITUATION.stack_order | translate }}</strong>
      </div>
      <div class="col-md-1 text-center">
        <p></p>
      </div>
    </div>
    <ul cdkDropList (cdkDropListDropped)="drop($event, accountSituationType)" class="dragObjectsParent">
      <li *ngFor="let situationType of accountSituationType; let i = index" cdkDrag [cdkDragData]="situationType" class="dragObjects">
        <div class="row">
          <div class="col-md-8">
            <p>{{ situationType.name }}</p>
          </div>
          <div class="col-md-3 text-center">
            <strong>{{ i }}</strong>
          </div>
          <div class="col-md-1 text-center">
            <p (click)="editSituationType(situationType)"><i class="icon-pencil"></i> {{ trans.SITUATION.edit | translate }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <button type="button" class="btn btn_customizable done-btn pull-right" (click)="updateSituationTypeStackOrder()">
    {{ trans.SITUATION.save | translate }}
  </button>
</div>

<!--MODALS-->
<ng-template #changeBenefitWindow let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h3>{{ trans.SITUATION.change | translate }} {{ benefit.metric_name }}</h3>
      <button type="button" class="close" aria-label="close" (click)="d('cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="ChangeBenefitForm" (ngSubmit)="saveSolutionSituationBenefit()">
        <div class="form-group">
          <label for="driver_baseline"
            >{{ trans.SITUATION.driver_baseline | translate }} ({{ trans.SITUATION.driver | translate }} {{ benefit.driver_factor }}, {{ trans.SITUATION.value | translate }}
            {{ benefit.driver_baseline_answer }})</label
          >
          <input
            type="number"
            step="0.01"
            class="form-control"
            name="driver_baseline_answer"
            id="driver_baseline_answer"
            placeholder="Driver Baseline"
            [(ngModel)]="benefitConf.driver_baseline_answer"
          />
        </div>
        <div class="form-group">
          <label for="financial_baseline"
            >{{ trans.SITUATION.financial_baseline | translate }} ({{ trans.SITUATION.financial_factor | translate }} {{ benefit.financial_factor }},
            {{ trans.SITUATION.value | translate }} {{ benefit.financial_baseline_answer }})
          </label>
          <input
            type="number"
            step="0.01"
            class="form-control"
            name="financial_baseline_answer"
            id="financial_baseline_answer"
            placeholder="Financial Baseline"
            [(ngModel)]="benefitConf.financial_baseline_answer"
          />
        </div>
        <div class="form-group">
          <label for="conservative">{{ trans.SITUATION.conservative | translate }} ({{ benefit.conservative }})</label>
          <input type="number" step="0.01" class="form-control" name="conservative" id="conservative" placeholder="Conservative" [(ngModel)]="benefitConf.conservative" />
        </div>
        <div class="form-group">
          <label for="probable">{{ trans.SITUATION.probable | translate }} ({{ benefit.probable }}) </label>
          <input type="number" step="0.01" class="form-control" name="probable" id="probable" placeholder="Probable" [(ngModel)]="benefitConf.probable" />
        </div>
        <div class="form-group">
          <label for="aggressive">{{ trans.SITUATION.aggressive | translate }} ({{ benefit.aggressive }})</label>
          <input type="number" step="0.01" class="form-control" name="aggressive" id="aggressive" placeholder="Aggressive" [(ngModel)]="benefitConf.aggressive" />
        </div>
        <div class="form-group">
          <label for="current_effort">Current Effort</label>
          <input type="text" class="form-control" name="current_effort" id="current_effort" placeholder="Current Effort" [(ngModel)]="benefitConf.current_effort" />
        </div>
        <div class="form-group clearfix">
          <button type="submit" class="btn btn_customizable pull-right done-btn">{{ trans.SITUATION.save | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #changeCostWindow let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3>{{ trans.SITUATION.change | translate }}{{ cost.name }}</h3>
    <button type="button" class="close" aria-label="close" (click)="d('cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="ChangeCostForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group col-md-12">
            <label for="alterEgo">{{ trans.SITUATION.how_does_this_accrue | translate }}</label>
            <select class="form-control role-dropdown" [(ngModel)]="cost.accrual_type_id" name="cost.accrualType" type="text">
              <option disabled="disabled">{{ trans.SITUATION.choose_one | translate }}</option>
              <option *ngFor="let accrualType of accrualTypes" [value]="accrualType.id">
                {{ accrualType.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-12">
            <label for="name">{{ trans.SITUATION.unit_price | translate }}</label>
            <input type="number" class="form-control" id="cost" name="cost.cost" [(ngModel)]="cost.cost" placeholder="Enter Unit Price" required />
          </div>
          <div class="form-group col-md-12">
            <label for="alterEgo">{{ trans.SITUATION.scale_factor | translate }}</label>
            <select class="form-control role-dropdown" [(ngModel)]="cost.scale_type_id" name="scaleFactor" type="text">
              <option disabled="disabled">{{ trans.SITUATION.choose_one | translate }}</option>
              <option *ngFor="let scaleType of scaleTypes" [value]="scaleType.id">
                {{ scaleType.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <div class="col-sm-9">
              <button type="submit" class="btn btn_customizable">{{ trans.SITUATION.save | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
