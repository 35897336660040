import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { AlertService } from '@services/alert.service';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserProfileTranslation } from '../user-profile.translation';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  @Output() change_pwd = new EventEmitter<boolean>();

  public style2022$: Observable<boolean>;
  public showTranslate = false;
  private unsubscribe = new Subject<void>();
  public passwordForm: FormGroup;

  constructor(
    public trans: UserProfileTranslation,
    private translationService: TranslationsV2Service,
    private userService: UserService,
    private alertService: AlertService,
    private NotificationService: NotificationService,
    private commonService: CommonService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.getTranslations();
    this.passwordForm = this.createPasswordForm();
  }
  private createPasswordForm() {
    const form = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['']
    }, { validator: this.matchPassword });
    return form;
  }

  matchPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get("newPassword").value;
    const confirm = control.get("confirmPassword").value;
    if (password != confirm) { return { 'notMatching': true } }
    return null;
  }


  public getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);
    let payload = {
      account_id: sessionStorage.getItem("aid"),
      component: this.trans.config.component,
      lang: langAbbr,
			localTranslations: this.trans.trans
    };
    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
    });
  }

  public CancelChangePwd() {
    this.alertService.clear();
    this.change_pwd.emit(false);
  }

  public onSubmit(e) {
    e.preventDefault();
    this.alertService.clear();
    let payload = {
      "current_password": this.passwordForm.get('currentPassword').value,
      "new_password": this.passwordForm.get('newPassword').value,
      "confirm_password": this.passwordForm.get('confirmPassword').value
    };
    this.userService.resetPassword(payload).subscribe(
      (result) => {
        this.NotificationService.success(result.result, false);
        this.resetForm();
        this.change_pwd.emit(false);
      })
  }

  public resetForm() {
    this.passwordForm.reset({currentPassword: '', newPassword: '', confirmPassword: ''});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.resetForm();
  }
}
