import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ExplainedComponent } from './explained.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {SidebarModule} from 'primeng/sidebar';
import { AddCostModalModule } from 'app/value-prop/common/add-cost-modal/add-cost-modal.module';
import { EditTranslationsModule} from '@shared_components/editTranslation/edit.directive.module';
import {TreeTableModule} from 'primeng/treetable';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ExplainedVpDashTranslations } from './explained.translation';


@NgModule({
	imports: [
		TreeTableModule,

		AddCostModalModule,
		ApplicationPipesModule,
		SidebarModule,
		CommonModule,
		EditTranslationsModule,
		NgbModule,
		FormsModule,
	],
	declarations: [

		ExplainedComponent
	],
	providers: [
		TranslationsV2Service,
		ExplainedVpDashTranslations
	],
	exports: [ExplainedComponent]
})
export class ExplainedModule { }
