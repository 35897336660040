import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JsonObject } from '@angular-devkit/core';
import { NgLocalization } from '@angular/common';

export interface Test {
  user_id: any;
  token: any;
  success: string;
  body: string;
  categories: any;
  solutions: any;
  account_id: any;
  features: any;
  role_type_id: any;
  user_type_id: any;
  logo_filepath: any;
  user_name: string;
}

const API_URL = 'https://devservice.value-cloud.com/accounts/Account.Service_VC.php?';
const api_endpoit = 'https://devservice.value-cloud.com/users/User.Service_VC.php';
const API_ENDPOINT = "https://devservice.value-cloud.com";
const defaultHeaders = new HttpHeaders();
defaultHeaders.append('User-Id', sessionStorage.getItem('uid'));
defaultHeaders.append('token', sessionStorage.getItem('vcu'));

@Injectable()
export class ApiServiceService {
  constructor(private http: HttpClient, private router: Router) {}

  // Observable string sources
  private useData = new Subject<string>();
  public changeLanguage = new Subject<string>();
  public changeHeaderColor = new Subject<string>();
  public profilePic = new Subject<string>();
  public companyLogo = new Subject<string>();
  public companyIcon = new Subject<string>();
  public profiledetails = new Subject<string>();

  private loggedIn = new BehaviorSubject<boolean>(false);
  // private loggedIn1 = new BehaviorSubject<boolean>(false);
  private intake = new BehaviorSubject<boolean>(false);

  // Observable string streams
  getUser$ = this.useData.asObservable();
  loggedIn$ = this.loggedIn.asObservable();
  // loggedIn1$ = this.loggedIn1.asObservable();
  isIntake$ = this.intake.asObservable();

  apiUrl = API_ENDPOINT;

  // Service message commands
  setUserData(details: any) {
    this.useData.next(details);
  }

  changeLoggedInVal(loggedInVal: boolean) {
    this.loggedIn.next(loggedInVal);
  }

	changeIntakeVal(intakeVal: boolean) {
		this.intake.next(intakeVal)
	}


	// Convert Json to url parameters
	public jsonToUrlParam(data: JsonObject) {
		let params = new HttpParams();
		for (let key in data) {
			params.set(key, String(data[key]))
		}
		return params;
	}

	// login check API
	public validateUser(logindata: any): Observable<Test> {
		let params = this.jsonToUrlParam(logindata);

		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		return this.http.post<Test>(api_endpoit, { headers, params });
	}

	// To get SolutionCategoryChart
	public getSolutionCategoryChart(paramsObject: any): Observable<Test> {
		let params = this.jsonToUrlParam(paramsObject);
		return this.http.get<Test>(API_URL, { headers: defaultHeaders, params });
	}

	// Get Solution Category

	public readSolutionCategories(paramsObject: any): Observable<Test> {
		let params = this.jsonToUrlParam(paramsObject);
		return this.http.get<Test>(API_URL, { headers: defaultHeaders, params });
	}

	// Get Solution List

	public readSolutions(paramsObject: any): Observable<Test> {
		let params = this.jsonToUrlParam(paramsObject);
		return this.http.get<Test>(API_URL, { headers: defaultHeaders, params });
	}


	// Create Solution Category

	public createSolutionCategories(paramsObject: any): Observable<Test> {
		let params = this.jsonToUrlParam(paramsObject);
		return this.http.get<Test>(API_URL, { headers: defaultHeaders, params });
	}

	// Delete Solution Category

	public deleteSolutionCategories(paramsObject: any): Observable<Test> {
		let params = this.jsonToUrlParam(paramsObject);
		return this.http.get<Test>(API_URL, { headers: defaultHeaders, params });
	}

	//Check if user is logged in
	public isLoggedIn() {
		let path = location.pathname.split("/")[1];
		let path_list = ["register", "forgotpassword", "vp", "calculators", "authorize", "saml", "bridge"];
		if (path_list.includes(path)) {
			return false;
		} else if(path === 'login'){
				return false;
		} else if (!sessionStorage.getItem('vcu')) {
			heapReset();

			if (path) {
				sessionStorage.setItem('cachedUrl', location.pathname);
			}

			this.router.navigate(['/login']);
		} else {
			return true;
		}
	}

  //Check if user is logged in for the first time
  public isNewUser() {
    if (sessionStorage.getItem('intake') == 'true') {
      return true;
    } else {
      return false;
    }
  }
}
