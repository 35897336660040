import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { AccountScratchpad } from './factors.models';
import { SurveyFactor } from '@data/services/goal-groups/models/business-goals-response.interface';

export interface SimpleGoal {
  account_factor_id?: string;
  value?: string;
  name?: string;
  description?: string;
  unit_type_id?: string;
  capture_baseline?: '1' | '0';
  capture_target?: '1' | '0';
}

export interface SimpleGoalReactiveForm {
  accountFactorId?: string;
  value?: string;
  name?: string;
  description?: string;
  baselineValue?: string;
  linkedFactors?: string[];
  precision?: string;
  unitType?: string;
  accountSolutionId?: string;
  accountFactorCategoryId?: string;
  icon?: string;
  scratchpadString?: string;
  selected?: boolean;
  includeByDefault?: boolean;
  capture_baseline?: '1' | '0';
  capture_target?: '1' | '0';
  editing?: boolean;
}

export interface Goal extends SimpleGoal {
  id?: string;
  account_id?: string;
  unit_type_id?: string;
  literal_number?: string | number;
  scalers_page?: string;
  baseline_answer?: string;
  greater_than_zero?: string | number;
  is_key_factor?: string | number;
  min?: string | number;
  max?: string | number;
  increment?: string | number;
  factor_precision?: string | number;
  company_field?: string;
  scale_type_id?: string;
  straight_scaling?: string;
  ratio?: string;
  formula?: string;
  web_included_factor?: string;
  sequence?: string;
  primary_scaler?: string;
  icon?: string;
  is_telemetry_data?: string;
  created?: string;
  modified?: string;
  dep_factor_type_id?: string;
  dep_factor_id?: string;
  value_realization_name?: string;
  function_type_id?: string | number;
  external_id?: string;
  scale_type_name?: string;
  unit_type?: string | number;
  account_factor_category_id?: string;
  has_scratchpad?: number;
  account_scratchpad?: AccountScratchpad;
  has_tag?: boolean;
  scales_how?: string | number;
  scales_by?: string | number;
  vr_normalized?: string;
  account_solution_id?: string;
  baseline_value?: string;
  scratchpad_str?: string;
  account_factor_id?: string;
  connected_objects?: ConnectedObject | ConnectedObjectWithoutResult[];
  connected_object_id?: string | ConnectedObjectWithoutResult[];
  value_fmt?: string;
  default_active?: '0' | '1';
  capture_baseline?: '0' | '1';
  capture_target?: '0' | '1';
  connected_factors_seprated_by_commas?: string;
}

export interface GoalPayload {
  active?: '0' | '1';
  name: string;
  value_realization_name: string;
  description: string;
  unit_type_id: string;
  factor_precision: string;
  scales_by: number;
  scales_how: number;
  ratio: string;
  formula: string;
  is_key_factor: number;
  literal_number: number;
  greater_than_zero: number;
  min: number;
  max: number;
  increment: number;
  external_id: string;
  function_type_id: number;
  icon: string;
  account_factor_category_id?: string;
  account_factor_id?: string;
  connected_object_id: {
    connected_object_type_id: string;
    connected_object_id: string;
  }[];
  capture_baseline: '0' | '1';
  capture_target: '0' | '1';
}

export interface GoalPayloadOneTime {
  active?: '0' | '1';
  name: string;
  description: string;
  unit_type_id: string;
  factor_precision: string;
  scales_how: number;
  ratio: string;
  formula: string;
  is_key_factor: number;
  literal_number: number;
  greater_than_zero: number;
  min: number;
  max: number;
  increment: number;
  icon: string;
  account_factor_category_id?: string;
  function_type_id?: number;
  target_value: number;
  connected_object_id:
    | []
    | [
        {
          connected_object_type_id: string;
          connected_object_id: string;
        }
      ];
  capture_baseline: '0' | '1';
  capture_target: '0' | '1';
}

export interface ConnectedObject {
  result?: Factor[];
}

export interface SelectOption {
  label: string;
  key: string;
}

export interface ConnectedObjectWithoutResult {
  connected_object_type_id: string;
  connected_object_id: string;
  account_factor_id?: string;
  created?: string;
  id?: string;
  metric_name?: string;
  modified?: string;
}

export interface GoalGroup {
  id?: string;
  name: string;
  factors: Array<ValuePropGoal>;
  account_id?: string;
  sequence?: string;
  description?: string;
  is_financial: string;
  fastfill_enabled: string;
  created: string;
  modified: string;
  function_type_id: number;
  account_factor_category_id?: string;
  account_factor_id?: string;
  goalList?: Array<ValuePropGoal>;
}

export interface ValueRealizationGoalGroup {
  id?: string;
  name: string;
  factors: Array<ValueRealizationGoal>;
  account_id?: string;
  sequence?: string;
  description?: string;
  is_financial: string;
  fastfill_enabled: string;
  created: string;
  modified: string;
  function_type_id: number;
  account_factor_category_id?: string;
  account_factor_id?: string;
  goalList?: Array<ValueRealizationGoal>;
}

export interface ValuePropGoal extends Goal {
  value_prop_id?: string;
  factor_estimate?: string;
  factor_estimate_fmt?: string;
  factor_override?: string;
  factor_override_fmt?: string;
  situation_override?: string;
  value_fmt?: string;
  baseline_value_fmt?: string;
  source_type_id?: string;
  updated?: boolean;
  active?: '0' | '1';
  scratchpad_used?: 0 | 1;
  currentValue?: number;
  survey_factors?: SurveyFactor[];
  factor_name?: string;
}

export interface ValuePropAddGoal {
  value?: string;
  source_type_id: number;
  account_factor_id: string;
  baseline_value?: string;
  active?: '0' | '1';
}

export interface TrackerGoalScratchpadPayload {
  factor_id: string;
  unit_type_id: string;
  factor_value: string;
}

export interface ValueRealizationGoal extends Goal {
  id: string;
  account_factor_id: string;
  factor_name: string;
  factor_description: string;
  value_fmt?: string;
  value: string;
  source_type_id?: string;
  survey_factors: SurveyFactor[];
  unit_type_id?: string;
  currentValue?: number;
  linkedFactors?: string[];
  goalSurveyFactor?: SurveyFactor;
}

export interface ValueRealizationSetGoal {
  account_factor_id: string;
  factor_value: string;
  source_type_id: number;
  value_survey_id: string;
  value_survey_factor_id: number;
}

export interface ValueRealizationBaselineGoalGroup {
  account_factor_id: string;
  account_id: string;
  description: string;
  factors: ValueRealizationBaselineGoal[];
  fastfill_enabled: string;
  function_type_id: string;
  name: string;
  has_key_factors?: boolean;
  should_show?: boolean;
}

export interface ValueRealizationBaselineGoal {
  account_factor_id: string;
  active: string;
  selected?: boolean;
  editing?: boolean;
  updated?: boolean;
  baseline_value: number;
  baseline_value_fmt: string;
  capture_baseline: string;
  capture_target: string;
  connected_factors_seprated_by_commas: string;
  description: string;
  factor_estimate: number;
  factor_estimate_fmt: string;
  factor_override: number;
  factor_override_fmt: string;
  factor_precision: string;
  linked_factors?: string[];
  name: string;
  nameCache: string;
  original_factor_name: string;
  scratchpad_str: string;
  scratchpad_used: number;
  unit_type_id: string;
  unit_type?: string;
  value: number;
  value_fmt: string;
  value_prop_id: string;
  is_key_factor?: string;
  should_show?: boolean;
}
