<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title">
            {{ trans.trans.scalers.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.scalers"
              (callback)="getTranslations()"
            ></app-editTranslation>
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_scalers.value"> info </span>
          </div>
        </div>
        <div class="col-xs-6" *ngIf="canEdit">
          <button class="btn btn_customizable pull-right" *ngIf="valueProp.vp_can_edit && !scalersLoader && !locked" (click)="createScaler()">
            {{ trans.trans.save.value }}
          </button>
          <!-- <a (click)="funcShowExplain()" *ngIf="explained.length" class="btn btn_customizable pull-right">{{ trans.trans.explain_changes.value }}</a> -->
        </div>
      </div>
    </div>

    <div *ngIf="scalers && scalers !== null && scalers.length > 0">
      <div class="row" style="margin-top: 10px">
        <div class="col-xs-12">
          <h3>
            {{ trans.trans.all_scalers.value
            }}<app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.all_scalers"
              key="vp_dashboard.scalers.all_scalers"
            ></app-editTranslation>
          </h3>
        </div>
      </div>
      <div *ngFor="let scaler of scalers; let index = index" style="margin-bottom: 10px">
        <div class="row">
          <div class="col-xs-12" *ngIf="scaler.name !== 'Does Not Scale'">
            <label class="sidebar_label">
              <i
                class="fa fa-chevron-right clickable"
                *ngIf="!scaler.showBenefits"
                placement="bottom"
                [matTooltip]="trans.trans.viewIncluded.value"
                container="body"
                (click)="scaler.showBenefits = !scaler.showBenefits"
              ></i>
              <i
                class="fa fa-chevron-down clickable"
                *ngIf="scaler.showBenefits"
                placement="bottom"
                [matTooltip]="trans.trans.viewIncluded.value"
                container="body"
                (click)="scaler.showBenefits = !scaler.showBenefits"
              ></i>
              {{ scaler.name }}
              <span *ngIf="scaler.description !== scaler.name" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="scaler.description"> info </span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <input
              type="text"
              [readOnly]="!canEdit"
              class="form-control"
              [min]="0"
              id=""
              [(ngModel)]="scaler.value"
              [required]="scaler.greater_than_zero"
              name="scaler_{{ scaler.id }}"
              currencyMask
              [options]="{ prefix: '', thousands: valueProp.thousands, precision: scaler.factor_precision >= 0 ? scaler.factor_precision : 2, decimal: valueProp.decimal }"
            />
          </div>
        </div>
        <div class="row" *ngIf="scaler.showBenefits">
          <div class="col-xs-12" style="max-height: 400px; overflow-y: scroll">
            <app-benefits-mini-v2 [valueProp]="valueProp" [benefits]="scaler.metrics" [clean]="true" [permissions]="permissions"></app-benefits-mini-v2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
