<div class="row" *ngIf="showHelp">
  <div class="col-xs-12">
    <div class="well well-info">
      <b>{{ trans.vp_dashboard.menu.collateral | translate }}</b
      ><br />
      {{ trans.contextual_help.vp_dashboard.collateral | translate }}
    </div>
  </div>
</div>
<div class="col-sm-12 collateraltab">
  <div class="pull-right m-b-xxl" *ngIf="solutionId">
    <button class="btn btn_customizable" *ngIf="canEdit" (click)="openAddCollateral(addCollateralModal)" matTooltip="Add new" matTooltipPosition="above">
      <i class="fa fa-plus"></i> {{ trans.button.add | translate }}
    </button>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <table class="table table-responsive custom-table">
        <thead>
          <tr>
            <th>
              {{ trans.vp_dashboard.collateral.format | translate
              }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.format"></app-editTranslation>
            </th>
            <th>
              {{ trans.vp_dashboard.collateral.name | translate
              }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.name"></app-editTranslation>
            </th>
            <th>
              {{ trans.vp_dashboard.collateral.url | translate
              }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.url"></app-editTranslation>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngIf="loadcollateralTable">
          <tr>
            <td colspan="4">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="(collateral == null || collateral.length == 0) && !loadcollateralTable">
          <tr>
            <td colspan="4">
              {{ trans.vp_dashboard.collateral.noCollateralsFound | translate
              }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.noCollateralsFound"></app-editTranslation>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr *ngFor="let value of collateral" class="grid-row company-row">
            <td>
              {{ value.format }}
            </td>
            <td>
              {{ value.name }}
            </td>
            <td>
              <a (click)="openWindow(value.url)">{{ value.url }}</a>
            </td>
            <td class="">
              <div class="dropdown pull-right">
                <span *ngIf="canEdit" class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                  <li (click)="editCollateral(addCollateralModal, value.id)"><i class="icon-pencil"></i> {{ trans.button.edit | translate }}</li>
                  <li (click)="open(deleteCollateralModal, value.id)"><i class="icon-trash"></i> {{ trans.button.delete | translate }}</li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #addCollateralModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ typeOfCollateral }} {{ trans.vp_dashboard.menu.collateral | translate
        }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.menu.collateral"></app-editTranslation>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="AddCollateralForm" #AddCollateralForm="ngForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group col-md-12" [ngClass]="{ 'has-error': AddCollateralForm.controls.format?.invalid && AddCollateralForm.controls.format?.touched }">
              <label class="labelsRequired" for="alterEgo"
                >{{ trans.vp_dashboard.collateral.format | translate
                }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.format"></app-editTranslation
              ></label>
              <select class="form-control role-dropdown" [(ngModel)]="format" name="format" type="text" required>
                <option disabled="disabled">{{ trans.vp_dashboard.collateral.ChooseOne | translate }}</option>
                <option *ngFor="let formatTypes of fileFormatTypes" [value]="formatTypes.id">
                  {{ formatTypes.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-12" [ngClass]="{ 'has-error': AddCollateralForm.controls.name?.invalid && AddCollateralForm.controls.name?.touched }">
              <label for="name" class="labelsRequired">
                {{ trans.vp_dashboard.collateral.name | translate
                }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.name"></app-editTranslation
              ></label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="name" placeholder="{{ trans.general.name_label | translate }}" required />
            </div>
            <div class="form-group col-md-12">
              <label for="alterEgo"
                >{{ trans.vp_dashboard.collateral.description | translate
                }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.description"></app-editTranslation
              ></label>
              <textarea class="form-control" id="Description" [(ngModel)]="description" name="description" placeholder="{{ 'general.description-label' | translate }}"></textarea>
            </div>
            <div
              class="form-group col-md-12"
              [ngClass]="{ 'has-error': AddCollateralForm.controls.url?.errors && AddCollateralForm.controls.url?.invalid && AddCollateralForm.controls.url?.touched }"
            >
              <label for="name" class="labelsRequired">
                {{ trans.vp_dashboard.collateral.url | translate
                }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.url"></app-editTranslation
              ></label>
              <input type="text" class="form-control" id="url" required [(ngModel)]="url" name="url" ngModel placeholder="{{ 'props.tabDetails.collateral.URL' | translate }}" />

              <!-- <div [hidden]="!AddCollateralForm.controls.url?.errors.pattern">
                                Must be a valid URL! // include in url text - pattern="https?://.+"
                            </div> -->
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-sm-9">
                <button type="submit" class="btn btn_customizable" (click)="SaveCollateral(AddCollateralForm)" [disabled]="AddCollateralForm.invalid">
                  {{ trans.button.save | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="pull-right">
            <label class="labelsRequiredInfo"
              >{{ trans.vp_dashboard.collateral.isRequired | translate
              }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.isRequired"></app-editTranslation
            ></label>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #deleteCollateralModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ trans.vp_dashboard.collateral.deleteCollateral | translate
        }}<app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.collateral.deleteCollateral"></app-editTranslation>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ trans.vp_dashboard.collateral.deleteConfirmation | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn_customizable" (click)="deleteCollateral()">{{ trans.button.Yes | translate }}</button>
      <button type="button" class="btn btn-light marg-h" (click)="c('Close click')">{{ trans.button.No | translate }}</button>
      <img class="actionLoader" [hidden]="hideActionLoader" src="{{ fullImagePath }}" />
    </div>
  </div>
</ng-template>
