<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>
						{{ trans.trans.heading.value }}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.heading" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-2'>
					<a (click)="addTeamMember()" class="btn btn_customizable pull-right">{{trans.trans.add.value}}</a>
				</div>
			</div>
		</div>

		<div class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tableUsers.filter($any($event).target.value, 'user_name', 'contains')" type='text' placeholder='Search' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableUsers.filter('', 'user_name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
			<div class='col-xs-8'>
				<span class="clickable pull-right" (click)='row.toggle($event)'>Row Limit {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
			</div>
		</div>


		<div class="row">
			<div class='col-xs-12'>
				<p-table #tableUsers [value]="teamMembersList" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th></th>
							<th>{{trans.trans.name.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
									[transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-member>
						<tr>
							<td><img style='max-width: 40px;' class="img-responsive img-circle" [src]="member.profile_pic_filepath != '' ? member.profile_pic_filepath:profilepicdefaultpath" /></td>
							<td>{{member.user_name}}</td>
							<td>
								<span class='keywest_icon_group pull-right'>
									<span>
										<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteMember(member.team_member_id)'></app-are-you-sure>
									</span>
								</span>

							</td>
						</tr>
					</ng-template>
				</p-table>

			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='showAddmember' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<form name="AddMember" #AddMember="ngForm" (ngSubmit)="ngOnSubmit(AddMember)">
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				{{ trans.trans.addTeamMember.value }}
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class='row'>
						<div class='col-xs-12'>
							<label for="partner" class="sidebar_label">{{ trans.trans.selectUser.value }}</label>
							<p-dropdown appendTo="body" name='username' [filter]="true" optionLabel="name" [options]="usersList" [(ngModel)]="selectedItems" class='keywest_dropdown'></p-dropdown>
						</div>
					</div>
					<div class='row'>
						<div class='col-xs-12'><label for="partner" class="sidebar_label">{{ trans.trans.selectPrivileges.value }}</label>

							<select type="text" class="form-control privileges-dropdown" name="privileges" [(ngModel)]="privilegeId" required>
								<option value="" selected disabled="disabled">{{ trans.trans.selectPrivileges.value }}</option>
								<option *ngFor="let privileges of privilegesList" id={{privileges.privilegeId}} [ngValue]="privileges.privilegeId">
									{{ privileges.name }}
								</option>
							</select>

						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<div class='row'>
					<div class="col-md-12">
						<button type="submit" class="btn btn_customizable pull-right" [disabled]="AddMember.invalid">{{ trans.trans.add.value }}</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>