import { Injectable, NgModule } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
@NgModule()
export class SpinnerService {
	private subject = new Subject<boolean>();

  	getSpinner(): Observable<any> {
        return this.subject.asObservable();
    }

    loadSpinner(loading: boolean) {
        this.subject.next(<boolean>loading);
    }
}