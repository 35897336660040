<div class="dlAdmin">
    <div class="col-md-12 dlAdminLinks">
        <div class="row">
            <div class="col-md-4 col-md-offset-1">
                <div class="panel panel-white">
                	<a>{{ trans.trans.create_a_new_account.value}}
                	<span class="icon-globe" aria-hidden="true"></span></a>
                </div>
            </div>
            <div class="col-md-4 col-md-offset-2">
                <div class="panel panel-white">
                	<a>{{ trans.trans.impersonate_a_user.value}}
                	<span class="icon-user-following" aria-hidden="true"></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 dlAdminLinks">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <div class="panel panel-white">
                	<a>{{ trans.trans.create_new_faq.value}}
                	<span class="icon-question" aria-hidden="true"></span></a>
                </div>
            </div>
        </div>
    </div>
</div>