import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class DiscoveryQuestionsDefaultTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'discovery-questions',
    display_name: 'Discovery Questions Profile',
    description: 'Discovery questions in profile',
  };
  public trans = this.typeObjectKeys({
    back_to_profile: 'Back to Profile',
    add: 'Add',
    manage_discovery_questions: 'Manage Discovery Questions',
    name: 'Name',
    type: 'Type',
    tag: 'Tag',
    required: 'Required',
    add_a_new_question: 'Add a new question',
    description: 'Description',
    ch_description: 'This is the description of the field and will also be the text information provided in the contextual help for the user when reviewing the field',
    ch_name: 'This is the question your user will be presented to provide the answer in the form field.',
    nothing: 'No questions found',
    ch_required: 'If the field is required, it will prevent a user from updating or creating a Value Proposition or Position until this field is completed',
    save: 'Save',
    ch_tag: 'This is the field name you want to use for including this field into any asset for downloading',
    ch_type: 'This is the question your user will be presented to provide the answer in the form field.',
    type_fld1: 'Text Field',
    type_fld2: 'Long Text Field',
    type_fld3: 'Toggle / Yes | No',
    are_you_sure: 'Are you sure?',
    delete: 'Delete',
    edit_question: 'Edit question',
    default_answer: 'Default Answer',
    tag_question: 'Question Tag',
    tag_answer: 'Answer Tag',
    tag_custom: 'Custom Tag',
    model: 'Model',
    placement: 'Placement',
    yes: 'Yes',
    no: 'No',
    none: 'None',
    all: 'All',
    question_updated_success: 'Question updated successfully.',
    question_created_success: 'Question created successfully.',
    question_updated_failure: 'Question update failed. Please try again.',
    question_created_failure: 'Question create failed. Please try again.',
    delete_question_confirm: 'Are you sure you want to delete this question?',
    settings: 'Settings',
    dashboard: 'Dashboard',
    choosePlacement: 'Choose Placement',
    chooseModel: 'Choose Model',
    chooseType: 'Choose Type',
    back: 'Back',
  });
}
