import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrayToFormControlPipe, ReturnErrorObservable } from './array-to-form-control.pipe';

@NgModule({
  declarations: [ArrayToFormControlPipe, ReturnErrorObservable],
  imports: [CommonModule],
  exports: [ArrayToFormControlPipe, ReturnErrorObservable],
})
export class ArrayToFormControlModule {}
