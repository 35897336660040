import { DLInputSwitchComponent } from './toggle.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [CommonModule, FormsModule],
	declarations: [DLInputSwitchComponent],
	exports: [DLInputSwitchComponent],
	entryComponents: [DLInputSwitchComponent]
	})
export class DLInputSwitchModule { }