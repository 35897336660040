import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BenefitsFullComponent } from './full.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { BenefitDetailsModule } from '../detail/benefit-detail.module';
import { BenefitsPhasingModule } from '../phasing/phasing.module';
import { CheckboxModule } from 'primeng/checkbox';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { AddBenefitsModule } from 'app/value-prop/value-prop-dashboard/add-benefits-component/add-benefits-component.module';
import { MenuModule } from 'primeng/menu';
import { ModelAEBenefits } from 'app/value-mapv2/solution-detail/benefits/ae_benefits/ae_benefits.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsFullTranslations } from './full.translation';
import { DLSideBarModule } from '@shared_components/primeng_sidebar/sidebar.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    DLSideBarModule,
    MenuModule,
    MatTooltipModule,
    AddBenefitsModule,
    AreYouSureModule,
    ProgressBarModule,
    MenubarModule,
    TableModule,
    CommonModule,
    FormsModule,
    NgbModule,
    BenefitDetailsModule,
    BenefitsPhasingModule,
    CheckboxModule,
    ApplicationPipesModule,
    ModelAEBenefits,
    EditTranslationsModule,
    SidebarModule,
  ],
  declarations: [BenefitsFullComponent],
  providers: [TranslationsV2Service, BenefitsFullTranslations],
  exports: [BenefitsFullComponent],
})
export class BenefitFullModule {}
