<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header_small'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>
						Download Assets
					</div>
				</div>
			</div>
		</div>

		<div class='row' *ngFor="let asset of assets" style='margin-bottom: 20px;'>
			<div class="col-md-12">
				<b>{{asset.label}}</b><br>
						<a [href]='asset.url' target='_blank'><i [class]='asset.icon' [ngStyle]="getFAColor(asset.format)" style='margin-right: 10px;'></i>Download</a>
						<a *ngIf="asset.showPDF" [href]='asset.pdf' target='_blank'><i class='fa fa-file-pdf-o' style='color: red; margin-left: 20px; margin-right: 5px;'></i>Download PDF</a>
						<p>{{asset.description}}</p>
			</div>
		</div>
	</div>
</div>