
<!-- <p-sidebar [(visible)]='toggleAddEditQuote' styleClass="keywest_sidebar p-sidebar-lg" position='right' (onHide)="resetValues()"> -->
		<div class="sidebar_wrapper">
			<div class='sidebar_header'>
				{{trans.trans.viewQuote.value}}
				<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
				[transObj]="trans.trans.viewQuote" (callback)="getTranslations()"></app-editTranslation>
			</div>

			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class="row">
						<div class='col-xs-6'>
							<label class="sidebar_label_readonly">{{ trans.trans.first_name.value}}
									<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
									[transObj]="trans.trans.first_name" (callback)="getTranslations()"></app-editTranslation>
							</label>

              <br> {{quoteView.first_name}}
						</div>

						<div class='col-xs-6'>
							<label class="sidebar_label_readonly">{{ trans.trans.last_name.value}}
									<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
									[transObj]="trans.trans.last_name" (callback)="getTranslations()"></app-editTranslation>
							</label>

              <br> {{quoteView.last_name}}
						</div>
					</div>
					<div class='row'>
						<div class='col-xs-12'>
							<label class="sidebar_label_readonly">{{ trans.trans.title.value}}
									<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
									[transObj]="trans.trans.title" (callback)="getTranslations()"></app-editTranslation>
							</label>

              <br> {{quoteView.title}}
						</div>
					</div>
					<div class="row">
						<div class='col-xs-12'>
							<label class="sidebar_label_readonly">{{ trans.trans.testimonial.value}}
									<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
									[transObj]="trans.trans.testimonial" (callback)="getTranslations()"></app-editTranslation>
							</label>

              <br> {{quoteView.quote}}
						</div>
					</div>

				</div>
			</div>



		</div>