import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { Quote } from 'app/value-map/solution-detail/case-studies/quote/models/quote.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuoteTranslations } from './quote.translation';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements OnInit, OnDestroy {
  @Input() caseStudyId: string | number;
  @Input() set quotes(values: Quote[] | boolean) {
    if (typeof values === 'boolean') {
      this.currentQuotes = [];
    } else {
      this.currentQuotes = values;
    }
  }
  @Input() canEdit: boolean;
  @Input() isModal = true;
  @Output() valueChange = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate = false;

  quoteFormObj: Quote = {
    contact_first_name: undefined,
    contact_last_name: undefined,
    title: undefined,
    quote: undefined,
    account_case_study_quote_id: undefined,
  };
  mode: string;
  toggleAddEditQuote: boolean;
  sidebar_title = 'Add Quote';
  currentQuotes: Quote[] = [];

  constructor(
    private solutionService: SolutionService,
    private notification: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: QuoteTranslations,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  openAddQuoteModal(quote?): void {
    this.sidebar_title = 'Add Testimonial';
    this.toggleAddEditQuote = true;
    this.mode = 'add';

    if (quote) {
      this.sidebar_title = 'Edit Testimonial';
      this.mode = 'edit';
      this.quoteFormObj = quote;
    }
  }

  processaddQuote(response): void {
    if (response.result && response.result.success) {
      this.notification.success(response.result.message, false);
      this.valueChange.emit();
    }
  }

  openView(quote): void {
    this.toggleAddEditQuote = true;
    this.quoteFormObj = quote;
  }

  deleteQuote(id): void {
    this.solutionService
      .deleteQuote(this.caseStudyId, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success) {
          this.notification.success(response.result.message, false);
          this.valueChange.emit();
        }
      });
  }

  resetValues(): void {
    this.mode = undefined;
    this.quoteFormObj = {
      contact_first_name: undefined,
      contact_last_name: undefined,
      title: undefined,
      quote: undefined,
      account_case_study_quote_id: undefined,
    };
  }

  closeSidebar(change: boolean) {
    this.toggleAddEditQuote = false;
    if (change) {
      this.valueChange.emit();
    }
  }
}
