import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CaseStudySidebarTranslations } from './case-study-sidebar.translation';

@Component({
  selector: 'app-case-study-sidebar',
  templateUrl: './case-study-sidebar.component.html',
  styleUrls: ['./case-study-sidebar.component.scss'],
})
export class CaseStudySidebarComponent implements OnInit, OnDestroy {
  @Input() toggleEditCaseStudy: boolean;
  @Input() tags: string[] = [];
  @Input() caseStudyEdit: { [klass: string]: any };
  @Input() id: number;
  @Output() refreshCaseDetails = new EventEmitter();

  ngUnsubscribe$ = new Subject();

  constructor(
    private notificationService: NotificationService,
    private ValueRealizationService: ValuerealizationService,
    public trans: CaseStudySidebarTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.refreshCaseDetails.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  updateCaseDetails(): void {
    const payload = {
      case_study_id: this.id,
      name: this.caseStudyEdit.name,
      tag: this.tags ? this.tags.join() : '',
    };
    this.ValueRealizationService.updateCaseStudyDetails(payload).subscribe((response) => {
      if (response.result.success) {
        this.notificationService.success('Case Study Updated successfully', false);
        this.toggleEditCaseStudy = !this.toggleEditCaseStudy;
        this.refreshCaseDetails.emit();
      } else {
        this.notificationService.error('Case Study change failed!', false);
        this.refreshCaseDetails.emit();
      }
    });
  }
}
