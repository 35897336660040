import { Injectable } from '@angular/core';

@Injectable()
export class AssetsManageProfileTranslations {
  public config: any = {
    component: 'manageAssets',
    display_name: 'Manage Assets',
    description: 'Manage assets in profile',
  };

  public trans: any = {
    backToProfile: 'Back to Profile',
    addAssetTemplate: 'Add Asset Template',
    addTemplate: 'Add Template',
    artifactName: 'Asset Name',
    defaultActive: 'Active by default?',
    description: 'Description',
    language: 'Language',
    manageAssetTemplates: 'Manage Asset Templates',
    page: 'page',
    stack_order: 'Stack Order',
    templateName: 'Template Name',
    whatKindOfAssetAreYouUploading: 'What kind of asset are you uploading?',
    willCustomersBeDownloadingThisAssetDirectly: 'Will customers be downloading this asset directly?',
    yourAssetTemplateFile: 'Your Asset Template File',
    here: 'Here',
    add: 'Add',
    addTheme: 'Add Theme',
    addThemes: 'Add Themes',
    selectLanguage: 'Select Language',
    ChooseOne: 'Choose One',
    orderSelected: 'Order Selected',
    save: 'Save',
    pick_asset_element: 'Pick Asset Element',
    next: 'Next',
    edit: 'Edit',
    cancel: 'Cancel',
    element_name: 'Element Name',
    addAsset: 'Add Asset',
    editAsset: 'Edit Asset',
    pageNumberError: 'Please select a page number',
    blockNumberError: 'Please select a block number',
    formSaveError: 'Please select the page numbers for your selected Slide Numbers and Blocks before continuing.',
    loadingElements: 'Loading Asset Element Types',
    loadingDetails: 'Loading Asset Details',
    loadingLanguage: 'Loading Languages',
    loadingAssetTypes: 'Loading Asset Types',
    addPdf: 'Add PDF option?',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    settings: 'Settings',
    dashboard: 'Dashboard',
    noModelsSelected: 'No models selected',
    noSaveWarning: 'If you leave without continuing your changes will not save.',
    slideOutWarning: 'Your changes did not save. In order to save your changes in the future ensure you click Next or Save.',
    added: 'Added',
    edited: 'Edited',
    deleted: 'Deleted',
    newTemplate: 'New Template Added',
    changeLog: 'Change Log',
    search: 'Search',
    viewLog: 'View Log',
    noSlidesSelected: 'No slides selected',
    noChartsSelected: 'No chart or graph selected',
    noBlockSelected: 'No block selected',
    existingFiles: 'Existing files for this template',
    noExistingFiles: 'There are currently no files for this template',
    deleteTemplate: 'Delete Template',
    existingFilesTooltip: 'These files have been directly connected to this template.',
    potentialFilesShow: 'Show Potential File Matches',
    potentialFilesHide: 'Hide Potential File Matches',
    potentialFilesTooltip: 'These files have NOT been directly connected to this template but their format suggests they may be connected.',
    deleteFile: 'Delete File?',
    deleteFileConfirmation: 'Are you sure you want to delete this file? This cannot be undone.',
    connectTooltip: 'Connect file to this template',
    uploadFile: 'Upload File',
    standardTemplates: 'Standard Templates',
    uploadThemeFile: 'Upload your theme file',
    chooseFile: 'Choose File',
    generateStandardAssets: 'Generate Standard Assets',
    OverrideExistingStandardTemplates: 'Override existing standard templates?',
    standardAssetsGenerated: 'Standard assets Generated Successfully',
    powerPointLandscape: "Powerpoint Landscape",
    powerPointPortrait: 'Powerpoint Portrait',
    word: 'Word', 
    deleteSuccessfull: 'Delete Successfull!',
  };
}
