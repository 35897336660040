import { Component, Input, OnDestroy, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { FeedbackResponse } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BenefitsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tabInit: number;
  @Input() default_term = '3';
  @Input() canEdit = false;

  solutionId: number;
  driverFactorId: number;
  scratchpadsInfo: { [klass: string]: any };
  valueCategories: { [klass: string]: any }[] = [];
  initialValueCategories: { [klass: string]: any }[] = [];
  valueCategory: { [klass: string]: any };
  currentValueCategory: { [klass: string]: any };
  currentValueCategoryName: string;
  currentBenefit: { [klass: string]: any };
  benefitLoader = false;
  valueCategoryBenefitLoader = false;
  isSolutionAdmin = false;
  isAccountAdmin = false;
  imageUrl: string;
  profilepicdefaultpath: string;
  fullImagePath: string;
  selectedCategory: { [klass: string]: any };
  tableViewBenefits = true;
  cardViewBenefits: boolean;
  modalReference: NgbModalRef;
  deleteModalReference: any;
  selectedBenefit: { [klass: string]: any };
  feedback: { [klass: string]: any };
  feedbackList: FeedbackResponse[];
  driverScratchpadFactor_default: { [klass: string]: any };
  driverScratchpadFactorValue_default = 0;
  OpenParenDefault: string;
  CloseParenDefault: string;
  templateSetting: string;
  benefitSelectedList: { [klass: string]: any };
  benefitDeleteLoader: boolean;
  dropdownSettings: { [klass: string]: any } = {};
  parenthesis: Array<{ [klass: string]: any }> = [
    {
      name: 'openParen',
      sign: '(',
    },
    {
      name: 'closeParen',
      sign: ')',
    },
  ];

  textInputFields: Array<{ [klass: string]: any }> = [
    {
      id: 1,
      name: '',
    },
  ];
  driverScratchpadFields: Array<any> = [
    {
      id: 1,
      factor: [],
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: 0,
    },
  ];
  scratchpadDriverFactorList: Array<{ [klass: string]: any }>;
  operatorTypesList: Array<{ [klass: string]: any }>;
  scratchpadImprovementList: Array<{ [klass: string]: any }>;

  subDriverFactorList: Array<{ [klass: string]: any }> = [
    {
      id: 1,
      factortype1: '',
      operatortype: '+',
      factortype2: '',
      close: '',
    },
  ];

  contextualHelp: { [klass: string]: any } = {};

  accountFeatures: string;
  masterValuePropFeature: boolean;
  projectsList: { [klass: string]: any }[] = [];
  selectedProject: { [klass: string]: any };
  newFactor: string;
  toggleScratchpadFactor = true;
  factorCreateLoader = false;

  addScratchPadError = '';

  subscriptioncontextualHelp: Subscription;
  subscriptionreadAllValueCategory: Subscription;
  subscriptiontriggerBenefit: Subscription;
  subscriptiongetScratchpadFactorTypes: Subscription;
  subscriptiongetScatchpadOperatorTypes: Subscription;
  subscriptiongetScratchpadImprovementTypes: Subscription;
  subscriptionreadBenefitsByValueCategory: Subscription;
  subscriptiongetDriverFactorSubTypes: Subscription;
  subscriptioncreateDriverFactorSubTypes: Subscription;
  subscriptiondeleteDriverFactorSubTypes: Subscription;
  subscriptiongetSolutionsScratchpads: Subscription;
  subscriptiondeleteSolutionsScratchpads: Subscription;
  subscriptioncreateSolutionScratchpad: Subscription;
  subscriptionsaveBenefitFeedback: Subscription;
  subscriptionreadFeedback: Subscription;
  subscriptiondeleteSolutionMetric: Subscription;
  subscriptionsaveBenefitSequence: Subscription;
  subscriptionreadBenefitsByProject: Subscription;
  subscriptiongetProjects: Subscription;
  subscriptionreadBenefitsByValueCategoryAndProject: Subscription;
  subscriptioncreateDriverFactor: Subscription;
  subscriptioncreateFinancialFactor: Subscription;
  subscriptioncreateImprovementFactor: Subscription;

  toggleAddBenefit = false;
  style2022$: Observable<boolean>;
  readOnly: boolean;

  constructor(
    private solutionService: SolutionService,
    private factsService: FactsService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private modalService: NgbModal,
    private notification: NotificationService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    this.profilepicdefaultpath = this.imageUrl + 'images/default_user.png';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.getAggregatedFactors();
    this.route.params.subscribe((params) => (this.solutionId = params['id']));

    this.feedback = {
      rating: 0,
      comment: '',
    };

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select DriverFactors',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
    };

    this.subscriptionreadAllValueCategory = this.solutionService.readAllValueCategory.subscribe(() => {
      this.readBenefitsByValueCategory('all', this.solutionId);
    });

    this.subscriptiontriggerBenefit = this.solutionService.triggerBenefit.subscribe(() => {
      this.readBenefitsByValueCategory('all', this.solutionId);
    });
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.masterValuePropFeature = false;
    this.accountFeatures = sessionStorage.getItem('features');
    if (this.commonService.checkFeature('15')) {
      this.masterValuePropFeature = true;
      this.getProjects();
      this.readBenefitsByProject('all');
    }

    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptionreadAllValueCategory) {
      this.subscriptionreadAllValueCategory.unsubscribe();
    }
    if (this.subscriptiontriggerBenefit) {
      this.subscriptiontriggerBenefit.unsubscribe();
    }
    if (this.subscriptiongetScratchpadFactorTypes) {
      this.subscriptiongetScratchpadFactorTypes.unsubscribe();
    }
    if (this.subscriptiongetScatchpadOperatorTypes) {
      this.subscriptiongetScatchpadOperatorTypes.unsubscribe();
    }
    if (this.subscriptiongetScratchpadImprovementTypes) {
      this.subscriptiongetScratchpadImprovementTypes.unsubscribe();
    }
    if (this.subscriptionreadBenefitsByValueCategory) {
      this.subscriptionreadBenefitsByValueCategory.unsubscribe();
    }
    if (this.subscriptiongetDriverFactorSubTypes) {
      this.subscriptiongetDriverFactorSubTypes.unsubscribe();
    }
    if (this.subscriptioncreateDriverFactorSubTypes) {
      this.subscriptioncreateDriverFactorSubTypes.unsubscribe();
    }
    if (this.subscriptiondeleteDriverFactorSubTypes) {
      this.subscriptiondeleteDriverFactorSubTypes.unsubscribe();
    }
    if (this.subscriptiongetSolutionsScratchpads) {
      this.subscriptiongetSolutionsScratchpads.unsubscribe();
    }
    if (this.subscriptiondeleteSolutionsScratchpads) {
      this.subscriptiondeleteSolutionsScratchpads.unsubscribe();
    }
    if (this.subscriptioncreateSolutionScratchpad) {
      this.subscriptioncreateSolutionScratchpad.unsubscribe();
    }
    if (this.subscriptionsaveBenefitFeedback) {
      this.subscriptionsaveBenefitFeedback.unsubscribe();
    }
    if (this.subscriptionreadFeedback) {
      this.subscriptionreadFeedback.unsubscribe();
    }
    if (this.subscriptiondeleteSolutionMetric) {
      this.subscriptiondeleteSolutionMetric.unsubscribe();
    }
    if (this.subscriptionsaveBenefitSequence) {
      this.subscriptionsaveBenefitSequence.unsubscribe();
    }
    if (this.subscriptionreadBenefitsByProject) {
      this.subscriptionreadBenefitsByProject.unsubscribe();
    }
    if (this.subscriptiongetProjects) {
      this.subscriptiongetProjects.unsubscribe();
    }
    if (this.subscriptionreadBenefitsByValueCategoryAndProject) {
      this.subscriptionreadBenefitsByValueCategoryAndProject.unsubscribe();
    }
    if (this.subscriptioncreateDriverFactor) {
      this.subscriptioncreateDriverFactor.unsubscribe();
    }
    if (this.subscriptioncreateFinancialFactor) {
      this.subscriptioncreateFinancialFactor.unsubscribe();
    }
    if (this.subscriptioncreateImprovementFactor) {
      this.subscriptioncreateImprovementFactor.unsubscribe();
    }
  }

  ngOnChanges() {
    if (this.tabInit === 3) {
      this.readBenefitsByValueCategory('all', this.solutionId);
      this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();
      this.isAccountAdmin = this.getIsAccountAdmin();

      this.getScratchpadOperatorTypes();
      this.getImprovementTypes();
    }
  }

  getIsAccountAdmin() {
    const privilegeOption = sessionStorage.getItem('privileges');
    const options = privilegeOption.split(',');
    if (options.indexOf('1') >= 0) {
      return true;
    }
    return false;
  }
  getAggregatedFactors() {
    this.scratchpadDriverFactorList = [];

    this.benefitLoader = true;
    this.subscriptiongetScratchpadFactorTypes = this.factsService.getScratchpadFactorTypes().subscribe((response) => {
      this.scratchpadDriverFactorList = response.result.map((scratch) => ({
        label: scratch.name,
        value: scratch,
      }));
      this.benefitLoader = false;
    });
  }
  getScratchpadOperatorTypes() {
    this.subscriptiongetScatchpadOperatorTypes = this.factsService.getScatchpadOperatorTypes().subscribe((response) => {
      this.operatorTypesList = response.result.filter((operator: any) => {
        return operator.id !== '5';
      });
    });
  }

  funcGetSign(id) {
    // tslint:disable-next-line: triple-equals
    const out = this.operatorTypesList.filter((x) => x['id'] == id);

    return out.length ? out[0]['name'] : '';
  }

  getImprovementTypes() {
    this.benefitLoader = true;
    this.subscriptiongetScratchpadImprovementTypes = this.factsService.getScratchpadImprovementTypes().subscribe((response) => {
      this.scratchpadImprovementList = response.result;
      this.benefitLoader = false;
    });
  }
  loadSelectedValueCategoryBenefits(selectedCategory) {
    if (selectedCategory) {
      this.valueCategories = [selectedCategory];
    } else {
      this.valueCategories = this.initialValueCategories;
    }
  }
  readBenefitsByValueCategory(value_category_id, solution_id) {
    this.valueCategoryBenefitLoader = true;
    this.subscriptionreadBenefitsByValueCategory = this.solutionService.readBenefitsByValueCategory(value_category_id, solution_id).subscribe((response) => {
      this.valueCategoryBenefitLoader = false;
      this.initialValueCategories = response.result;

      this.valueCategories = response.result;
    });
  }

  tableView(view: string) {
    if (view === 'Table_View') {
      this.cardViewBenefits = true;
      this.tableViewBenefits = false;
    } else if (view === 'Grid_View') {
      this.cardViewBenefits = false;
      this.tableViewBenefits = true;
    }
  }

  openSolution() {
    this.selectedBenefit = null;

    this.toggleAddBenefit = !this.toggleAddBenefit;
  }

  textClose(params: { [klass: string]: any }) {
    this.textInputFields = this.textInputFields.map((textField: any) => {
      if (textField.id === params) {
        textField.name = '';
      }
      return textField;
    });
    this.textInputFields = this.textInputFields.filter((textField: any) => {
      return textField.id !== params;
    });
  }

  addSubTypes() {
    const idRandom = 'textfield-' + 1 + Math.floor(Math.random() * 1000) + '-inputEl';
    const subTypes = { id: idRandom };
    this.textInputFields.push(subTypes);
  }

  addDriverFactor() {
    const idRandom = Math.floor(Math.random() * 1000);
    this.subDriverFactorList.push({
      id: idRandom,
      factortype1: '',
      operatortype: '+',
      factortype2: '',
      close: '',
    });
  }

  driverFactorClose(params) {
    this.subDriverFactorList = this.subDriverFactorList.filter((textField: any) => {
      return textField.id !== params;
    });
  }

  editBenefit(selectedBenefit) {
    this.selectedBenefit = selectedBenefit;
    this.solutionId = this.solutionId;
    this.toggleAddBenefit = !this.toggleAddBenefit;
  }

  openScratchpad(content) {
    const modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    modalReference.componentInstance.solutionId = this.solutionId;
    modalReference.result.then(
      () => {},
      () => {}
    );
  }
  addDriverSubTypes(content, benefit) {
    this.textInputFields = [
      {
        id: 1,
        name: '',
        driver_value: 0,
        improvement_value: 0,
        financial_value: 0,
      },
    ];
    this.getDriverSubTypes(benefit.driver_factor_id);
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
    this.driverFactorId = benefit.driver_factor_id;
    this.benefitSelectedList = benefit;
  }
  getDriverSubTypes(id: any) {
    const updatedTextFields = [];
    this.benefitLoader = true;
    this.subscriptiongetDriverFactorSubTypes = this.solutionService.getDriverFactorSubTypes(id).subscribe((response) => {
      if (response.result) {
        for (let i = 0; i < response.result.length; i++) {
          const textInput = {
            id: response.result[i].id,
            name: response.result[i].name,
            driver_value: response.result[i].driver_value,
            improvement_value: response.result[i].improvement_value,
            financial_value: response.result[i].financial_value,
          };
          updatedTextFields.push(textInput);
        }
        this.textInputFields = updatedTextFields;
      }
      this.benefitLoader = false;
    });
  }
  saveDriverSubTypes(form: NgForm) {
    this.benefitLoader = true;
    let name = '';
    let driver_value = '';
    let improvement_value = '';
    let financial_value = '';
    for (const i in form.value) {
      if (form.value[i] && i.includes('driverSubTypesName')) {
        name += form.value[i] + ',';
      } else if (form.value[i] && i.includes('driverSubTypesDriverValue')) {
        driver_value += form.value[i] + ',';
      } else if (form.value[i] && i.includes('driverSubTypesImpValue')) {
        improvement_value += form.value[i] + ',';
      } else if (form.value[i] && i.includes('driverSubTypesFinancialValue')) {
        financial_value += form.value[i] + ',';
      }
    }
    name = name.slice(0, -1);
    const payload = {
      driver_factor_id: this.driverFactorId,
      name: name,
      driver_value: driver_value,
      improvement_value: improvement_value,
      financial_value: financial_value,
    };
    this.subscriptioncreateDriverFactorSubTypes = this.solutionService.createDriverFactorSubTypes(payload).subscribe(() => {
      this.benefitLoader = false;
      this.modalReference.close();
      this.readBenefitsByValueCategory('all', this.solutionId);
      this.notification.success('Driver SubTypes added successfully', false);
    });
  }

  open(content: any) {
    this.deleteModalReference = this.modalService.open(content, {
      windowClass: 'deleteModal secondLayerModalWindow',
      backdropClass: 'secondLayerModalBackdrop',
      backdrop: 'static',
      keyboard: false,
    });
    this.deleteModalReference.result.then(
      () => {},
      () => {}
    );
  }
  deleteDriverSubType(id) {
    this.benefitLoader = true;
    this.subscriptiondeleteDriverFactorSubTypes = this.solutionService.deleteDriverFactorSubTypes(id).subscribe((result) => {
      this.notification.success(result.result, false);
      this.benefitLoader = false;
      this.modalReference.close();
      this.readBenefitsByValueCategory('all', this.solutionId);
      this.deleteModalReference.close();
    });
  }
  addDriverScratchpad() {
    const idRandom = Math.floor(Math.random() * 1000);
    const driverFactor = {
      id: idRandom,
      factor: [],
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: 0,
    };
    this.driverScratchpadFields.push(driverFactor);
  }

  addScratchpads(content, benefit, type) {
    this.driverScratchpadFields = [
      {
        id: 1,
        factor: [],
        operator: undefined,
        openParen: undefined,
        closeParen: undefined,
        operand: 0,
      },
    ];
    if (type === 'driverFactor') {
      this.templateSetting = type;
    } else if (type === 'financialFactor') {
      this.templateSetting = type;
    } else if (type === 'improvementFactor') {
      this.templateSetting = type;
    }
    this.driverScratchpadFactor_default = {};
    this.driverScratchpadFactorValue_default = 0;
    this.OpenParenDefault = undefined;
    this.CloseParenDefault = undefined;
    this.getSolutionsScratchpad(benefit);
    this.toggleScratchpadFactor = true;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    this.scratchpadsInfo = benefit;
  }
  driverScratchpadClose(params) {
    this.driverScratchpadFields = this.driverScratchpadFields.map((driverField: any) => {
      if (driverField.id === params) {
        driverField.factor = [];
        driverField.operator = undefined;
        driverField.openParen = undefined;
        driverField.closeParen = undefined;
        driverField.operand = 0;
      }
      return driverField;
    });
    this.driverScratchpadFields = this.driverScratchpadFields.filter((driverField: any) => {
      return driverField.id !== params;
    });
  }
  getSolutionsScratchpad(benefit) {
    const driverScratchpadFields = [];
    this.benefitLoader = true;
    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    const factorId =
      this.templateSetting === 'driverFactor' ? benefit.driver_factor_id : this.templateSetting === 'financialFactor' ? benefit.financial_factor_id : benefit.improvement_factor_id;
    this.subscriptiongetSolutionsScratchpads = this.solutionService.getSolutionsScratchpads(typeId, factorId, benefit.id).subscribe((response) => {
      if (response.result.units) {
        const firstScratchValue: any = this.scratchpadDriverFactorList.find((fact: any) => {
          return response.result.units[0].factor_id === fact.value.id;
        });
        this.driverScratchpadFactor_default = firstScratchValue.value;

        this.OpenParenDefault = response.result.units[0].open_paren === '1' ? '(' : undefined;
        this.CloseParenDefault = response.result.units[0].close_paren === '1' ? ')' : undefined;
        this.driverScratchpadFactorValue_default = response.result.units[0].operand;
        for (let i = 1; i < response.result.units.length; i++) {
          const j = i - 1;
          const nextScratchValue: any = this.scratchpadDriverFactorList.find((fact: any) => {
            return response.result.units[i].factor_id === fact.value.id;
          });
          const scratchpadInput = {
            id: response.result.units[i].id,
            factor: nextScratchValue.value,
            operator: response.result.units[j].operator_id,
            openParen: response.result.units[i].open_paren === '1' ? '(' : undefined,
            closeParen: response.result.units[i].close_paren === '1' ? ')' : undefined,
            operand: response.result.units[i].operand,
          };
          driverScratchpadFields.push(scratchpadInput);
        }
        this.benefitLoader = false;
        this.driverScratchpadFields = driverScratchpadFields;
      } else {
        this.benefitLoader = false;
      }
    });
  }
  deleteScratchpad() {
    this.benefitLoader = true;
    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    const factorId =
      this.templateSetting === 'driverFactor'
        ? this.scratchpadsInfo.driver_factor_id
        : this.templateSetting === 'financialFactor'
        ? this.scratchpadsInfo.financial_factor_id
        : this.scratchpadsInfo.improvement_factor_id;
    this.subscriptiondeleteSolutionsScratchpads = this.solutionService.deleteSolutionsScratchpads(typeId, factorId, this.scratchpadsInfo.id).subscribe((result) => {
      this.notification.success(result.result, false);
      this.benefitLoader = false;
      this.modalReference.close();
      this.readBenefitsByValueCategory('all', this.solutionId);
      this.deleteModalReference.close();
    });
  }
  saveDriverScratchpads(form: NgForm) {
    if (!form.valid) {
      this.addScratchPadError = 'An Operator, Driver Factor and a Value are required.';
      return false;
    }
    this.addScratchPadError = '';
    this.benefitLoader = true;
    let factorValue = '';

    let openParenValue = (this.OpenParenDefault ? '1' : '0') + (this.OpenParenDefault ? ',' : ',');
    let closeParenValue = (this.CloseParenDefault ? '1' : '0') + (this.CloseParenDefault ? ',' : ',');

    let operand = this.driverScratchpadFactorValue_default + ',';
    let operatorValue = '';
    for (const i in form.value) {
      if (i.includes('scratchpadDriverFactor')) {
        factorValue += form.value[i]['id'] + ',';
      } else if (i.includes('scratchpadDriverValue')) {
        operand += form.value[i] + ',';
      } else if (form.value[i] && i.includes('selectOperator')) {
        operatorValue += form.value[i] + ',';
      } else if (i.includes('scratchpadOpenParen')) {
        if (form.value[i]) {
          openParenValue += (form.value[i] === '(' ? '1' : '0') + ',';
        } else {
          openParenValue += '0,';
        }
      } else if (i.includes('scratchpadCloseParen')) {
        if (form.value[i]) {
          closeParenValue += (form.value[i] === ')' ? '1' : '0') + ',';
        } else {
          closeParenValue += '0,';
        }
      }
    }
    factorValue = factorValue.slice(0, -1);
    operatorValue = operatorValue.slice(0, -1);
    openParenValue = openParenValue.slice(0, -1);
    closeParenValue = closeParenValue.slice(0, -1);
    operand = operand.slice(0, -1);
    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    const payload = {
      scratchpad_factor_id:
        this.templateSetting === 'driverFactor'
          ? this.scratchpadsInfo.driver_factor_id
          : this.templateSetting === 'financialFactor'
          ? this.scratchpadsInfo.financial_factor_id
          : this.scratchpadsInfo.improvement_factor_id,
      scratchpad_factor_type_id: typeId,
      account_solution_metric_id: this.scratchpadsInfo.id,
      factor_id: factorValue,
      operator_id: operatorValue + (operatorValue ? ',5' : ''),
      open_paren: openParenValue,
      close_paren: closeParenValue,
      operand: operand,
    };

    this.subscriptioncreateSolutionScratchpad = this.solutionService.createSolutionScratchpad(payload).subscribe((result) => {
      this.benefitLoader = false;
      this.modalReference.close();
      if (result.result.message) {
        this.notification.success(result.result.message, false);
      } else {
        this.notification.success('Scratchpad added successfully', false);
      }
      this.readBenefitsByValueCategory('all', this.solutionId);
      this.solutionService.triggerTabs.next('benefits');
    });
  }
  openBenefitRating(content: any) {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }
  saveBenefitRating() {
    const ratingObj = {
      account_solution_metric_id: this.selectedBenefit.id,
      score: this.feedback.rating,
      comment: this.feedback.comment,
    };

    this.subscriptionsaveBenefitFeedback = this.solutionService.saveBenefitFeedback(ratingObj, this.selectedBenefit.id).subscribe(() => {
      this.modalReference.close();
      this.readBenefitsByValueCategory('all', this.solutionId);
    });
  }

  showFeedback(content: any, benefitId: any) {
    this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.subscriptionreadFeedback = this.solutionService.readFeedback(benefitId).subscribe((response) => {
      if (response.result) {
        this.feedbackList = response.result;
      }
    });
  }

  deleteVPBenefit(content: any, data: { [klass: string]: any }) {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
    this.benefitSelectedList = data;
  }

  deleteBenefits() {
    this.benefitDeleteLoader = true;
    const payload = this.benefitSelectedList.account_solution_id + '/accountSolutionMetricID/' + this.benefitSelectedList.id;
    this.subscriptiondeleteSolutionMetric = this.solutionService.deleteSolutionMetric(payload).subscribe((response) => {
      // tslint:disable-next-line: triple-equals
      if (response.result.success == false) {
        this.benefitDeleteLoader = false;
        this.notification.error(response.result.message, false);
      } else {
        this.benefitDeleteLoader = false;
        this.notification.success(response.result.message, false);
      }
      this.modalReference.close();
    });
  }

  openScratchpadTypes(content: any, data: { [klass: string]: any }) {
    this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.benefitSelectedList = data;
  }

  showSituations(content: any, data: { [klass: string]: any }) {
    this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.benefitSelectedList = data;
  }

  saveBenefitSequence(valueCategoryId: any) {
    let valueCategories = this.valueCategories.find((valueCategory) => valueCategory['value_category_id'] == valueCategoryId);
    valueCategories = valueCategories['benefits'];

    const payload = {
      value_category_id: valueCategoryId,
      benefit_sequence: [],
    };

    payload['benefit_sequence'] = valueCategories.map((benefit, index) => {
      return {
        account_solution_metric_id: benefit['id'],
        sequence: index,
      };
    });

    this.subscriptionsaveBenefitSequence = this.solutionService.saveBenefitSequence(payload, this.solutionId).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
      }
    });
  }

  drop(dropEvent: CdkDragDrop<unknown[]>, dropArray: any[]) {
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
  }

  readBenefitsByProject(projectId: any) {
    this.subscriptionreadBenefitsByProject = this.solutionService.readBenefitsByProject(projectId, this.solutionId).subscribe(() => {});
  }

  getProjects() {
    this.subscriptiongetProjects = this.solutionService.getProjects(this.solutionId).subscribe((response) => {
      this.projectsList = [];
      if (response.result) {
        this.projectsList = response.result;
      }
    });
  }

  loadSelectedProjectBenefits(selectedProject: { [klass: string]: any }) {
    this.selectedProject = selectedProject.id;
    this.valueCategoryBenefitLoader = true;
    this.subscriptionreadBenefitsByValueCategoryAndProject = this.solutionService
      .readBenefitsByValueCategoryAndProject('all', this.selectedProject, this.solutionId)
      .subscribe((response) => {
        this.valueCategoryBenefitLoader = false;
        this.initialValueCategories = response.result;
        this.valueCategories = response.result;
      });
  }

  processData() {
    this.newFactor = '';
    this.factorCreateLoader = false;
    this.toggleScratchpadFactor = true;
    this.notification.success('Factor created successfully', false);
    this.getAggregatedFactors();
  }

  createScratchpadFactor() {
    this.factorCreateLoader = true;
    const FactorName = {
      name: this.newFactor,
      account_id: sessionStorage.getItem('aid'),
    };

    if (this.templateSetting === 'driverFactor') {
      this.subscriptioncreateDriverFactor = this.factsService.createDriverFactor(FactorName).subscribe(() => {
        this.processData();
      });
    } else if (this.templateSetting === 'financialFactor') {
      this.subscriptioncreateFinancialFactor = this.factsService.createFinancialFactor(FactorName).subscribe(() => {
        this.processData();
      });
    } else if (this.templateSetting === 'improvementFactor') {
      this.subscriptioncreateImprovementFactor = this.factsService.createImprovementFactor(FactorName).subscribe(() => {
        this.processData();
      });
    }
  }
}
