import { Injectable } from "@angular/core";
@Injectable()
export class ScbHelperService {
    valid: boolean = true;
    public validate(form, formModel, errors){
        this.valid = true;
        for(let field of formModel){    
          for(let valid of field.valid){
            if(valid === 'required')
              this.chkRequired(form, field, errors);
            else if(valid === 'email')
              this.chkEmail(form, field, errors);
          }
        }
        return this.valid;
    }
    public chkEmail(form, field, errors){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(form.value[field.name]).toLowerCase())){
          errors[field.name] = '';
        } else {
          this.valid = (this.valid) ? false : true;
          errors[field.name] = 'Enter valid email';
        }
    }
    public chkRequired(form, field, errors){
        if(form.value[field.name] === ''){
            errors[field.name] = 'This field is required';
        } else{
            this.valid = (this.valid) ? false : true;
            errors[field.name] = '';
        }
    }
} 