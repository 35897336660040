import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2EAValueCategoryTranslations {
  public config: any = {
    component: 'ae_value_category',
    display_name: 'Models Value Category Form',
    description: 'Form for creating a value category',
  };

  public trans: any = {
    name: 'Name',
    description: 'Description',
    add_value_category: 'Add Value Category',
    edit_value_category: 'Edit Value Category',
    save: 'Save',
    update: 'Update',
    value_category_name: 'Value Category Name',
    back: 'Back',
    areYouSure: 'Are you sure?',
    tconame: 'TCO Name',
    createCustomAssetTag: 'Create custom asset tag for this benefit group?',
    removeTag: 'Ensure that you have removed this custom factor group tag from your assets to avoid disruption',
    driven: 'Driven',
    fastFill: 'Fast Fill',
    addSuccessful: 'Value Category added successfully',
    updateSuccessful: 'Value Category updated successfully',
    benefitGroupValueTag: 'Benefit Group Value Tag',
    valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    onShow: 'onshow.',
    value: 'value',
    tagsSpecialCharactersError: 'Tags cannot contain special characters',
    tagMustBeUnique: 'Tags must be unique',
  };
}
