import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'add-company',
    display_name: 'Add Company Form',
    description: 'Form for a adding a company'
  };

  public trans: any = {
    add_a_company: 'Add a Company',
    address: 'Address',
    city: 'City',
    country: 'Country',
    employees: 'Employees',
    hello: 'Hello, ',
    industry: 'Industry',
    industry_sector: 'Industry Sector',
    is_required: 'Is Required',
    required: 'Required',
    name: 'Name',
    postal_code: 'Postal Code',
    revenue_millions: 'Revenue (Millions)',
    save: 'Save',
    select_country: 'Select Country',
    select_industry: 'Select Industry',
    select_industry_sector: 'Select Industry Sector',
    select_state: 'Select State',
    state: 'State',
    content: 'Content',
    additional_info: 'Additional Informations',
    add_new: 'Add New'
  };
}
