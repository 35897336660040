<form name="situationForm" #situationForm="ngForm">
  <div class="sidebar_container_full_page">
    <div class="keywest_page_header" *ngIf="mode === 'edit'">
      <div class="row">
        <div class="col-xs-12">
          <div class="title">
            {{ breadcrumb }}
          </div>
        </div>
      </div>
    </div>

    <p-progressBar *ngIf="!loaded" mode="indeterminate"></p-progressBar>

    <div *ngIf="mode !== 'edit'">
      <div class="row">
        <div class="col-md-12" appNGError [elemControl]="situationForm.controls.situation_type_id">
          <div class="form-group">
            <label for="situation_type" class="sidebar_label"
              >{{ trans.trans.situationType.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.situationType"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input class="form-control" type="hidden" name="situation_type_id" [(ngModel)]="situationObj.situation_type_id" />
            <p-dropdown
              appendTo="body"
              class="keywest_dropdown"
              placeholder="Select a Situation"
              ret-data="Situation Type"
              [options]="situationTypes"
              [filter]="true"
              filterBy="label"
              name="situation_type_id"
              [(ngModel)]="situationObj.situation_type_id"
              (onChange)="assignSituationTypeId()"
              [panelStyleClass]="'dropdown-item-font'"
              [required]="true"
            >
            </p-dropdown>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12" appNGError [elemControl]="situationForm.controls.situation_element">
          <label class="sidebar_label"
            >{{ trans.trans.selectSituationElement.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.selectSituationElement"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </label>
          <div class="well" *ngIf="!situationObj.situation_type_id">
            {{ trans.trans.selectSituationToProceed.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.selectSituationToProceed"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
          <select
            *ngIf="situationObj.situation_type_id"
            class="form-control"
            name="situation_element1"
            [(ngModel)]="parent1"
            ret-data="Situation Element Type"
            required
            (change)="checkForChildren1($any($event).target.value)"
          >
            <option [value]="null" disabled>{{ trans.trans.select_your_option.value }}</option>
            <option *ngFor="let situationTypeElement of situationTypeElements" [value]="situationTypeElement.id">
              {{ situationTypeElement.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- nested elements -->

      <div class="row" *ngIf="elementChildren1 && elementChildren1.length > 0">
        <div class="col-md-12" appNGError [elemControl]="situationForm.controls.situation_element">
          <label class="sidebar_label">
            {{ trans.trans.children_of_parent_name.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.chilldren_of_parent_name"
              (callback)="getTranslations()"
            ></app-editTranslation>
            {{ parent1Name }}
          </label>
          <select
            autocomplete="off "
            class="form-control"
            name="situation_element2"
            [(ngModel)]="parent2"
            required
            *ngIf="elementChildren1.length > 0"
            ret-data="Situation Element"
            (change)="checkForChildren2($any($event).target.value)"
            (blur)="checkForChildren2($any($event).target.value)"
          >
            <option [value]="null" disabled selected>{{ trans.trans.select_your_option.value }}</option>
            <option *ngFor="let childElement1 of elementChildren1" [value]="childElement1.id">
              {{ childElement1.name }}
            </option>
          </select>
          <div *ngIf="situationTypeElementsLoader">
            <img class="actionLoader" src="{{ fullImagePath }}" />
          </div>
          <div class="well" *ngIf="situationTypeElements.length === 0 && !situationTypeElementsLoader">
            {{ trans.trans.selectSituationToProceed.value }}
          </div>
        </div>
      </div>

      <div class="row" *ngIf="elementChildren2 && elementChildren2.length > 0">
        <div class="col-md-12" appNGError [elemControl]="situationForm.controls.situation_element">
          <label class="sidebar_label">
            {{ trans.trans.children_of_parent_name.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.chilldren_of_parent_name"
              (callback)="getTranslations()"
            ></app-editTranslation>
            {{ parent2Name }}
          </label>
          <select
            autocomplete="off "
            class="form-control"
            name="situation_element3"
            [(ngModel)]="parent3"
            required
            *ngIf="elementChildren2.length > 0"
            ret-data="Situation Element"
            (change)="checkForChildren3($any($event).target.value)"
            (blur)="checkForChildren3($any($event).target.value)"
          >
            <option [value]="null" disabled selected>{{ trans.trans.select_your_option.value }}</option>
            <option *ngFor="let childElement2 of elementChildren2" [value]="childElement2.id">
              {{ childElement2.name }}
            </option>
          </select>
          <div *ngIf="situationTypeElementsLoader">
            <img class="actionLoader" src="{{ fullImagePath }}" />
          </div>
          <div class="well" *ngIf="situationTypeElements.length === 0 && !situationTypeElementsLoader">{{ trans.trans.selectSituationToProceed.value }}.</div>
        </div>
      </div>

      <div class="row" *ngIf="elementChildren3 && elementChildren3 && elementChildren3.length > 0">
        <div class="col-md-12" appNGError [elemControl]="situationForm.controls.situation_element">
          <label class="sidebar_label">
            {{ trans.trans.selectSituationToProceed.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.chilldren_of_parent_name"
              (callback)="getTranslations()"
            ></app-editTranslation>
            {{ parent3Name }}
          </label>
          <select
            autocomplete="off "
            class="form-control"
            name="situation_element4"
            [(ngModel)]="parent4"
            required
            *ngIf="elementChildren3.length > 0"
            ret-data="Situation Element"
            (change)="checkForChildren4($any($event).target.value)"
            (blur)="checkForChildren4($any($event).target.value)"
          >
            <option [value]="null" disabled selected>{{ trans.trans.select_your_option.value }}</option>
            <option *ngFor="let childElement3 of elementChildren3" [value]="childElement3.id">
              {{ childElement3.name }}
            </option>
          </select>
          <div *ngIf="situationTypeElementsLoader">
            <img class="actionLoader" src="{{ fullImagePath }}" />
          </div>
          <div class="well" *ngIf="situationTypeElements.length === 0 && !situationTypeElementsLoader">
            {{ trans.trans.selectSituationToProceed.value }}
          </div>
        </div>
      </div>

      <div class="row" *ngIf="elementChildren4 && elementChildren4.length > 0">
        <div class="col-md-12" appNGError [elemControl]="situationForm.controls.situation_element">
          <label class="sidebar_label">
            {{ trans.trans.selectSituationToProceed.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.chilldren_of_parent_name"
              (callback)="getTranslations()"
            ></app-editTranslation>
            {{ parent4Name }}
          </label>
          <select
            autocomplete="off "
            class="form-control"
            name="situation_element5"
            [(ngModel)]="parent5"
            required
            *ngIf="elementChildren4.length > 0"
            ret-data="Situation Element"
            (change)="checkForChildren5($any($event).target.value)"
            (blur)="checkForChildren5($any($event).target.value)"
          >
            <option [value]="null" disabled selected>{{ trans.trans.select_your_option.value }}</option>
            <option *ngFor="let childElement4 of elementChildren4" [value]="childElement4.id">
              {{ childElement4.name }}
            </option>
          </select>
          <div *ngIf="situationTypeElementsLoader">
            <img class="actionLoader" src="{{ fullImagePath }}" />
          </div>
          <div class="well" *ngIf="situationTypeElements.length === 0 && !situationTypeElementsLoader">
            {{ trans.trans.selectSituationToProceed.value }}
          </div>
        </div>
      </div>

      <!-- end nested elements -->
    </div>

    <div class="row">
      <div class="col-md-12" appNGError [elemControl]="situationForm.controls.description">
        <label class="sidebar_label"
          >{{ trans.trans.description.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.description"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </label>
        <textarea name="description" ret-data="Description" class="form-control keywest_textarea" [(ngModel)]="situationObj.description"></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label for="defaultActive" class="sidebar_label"
          >{{ trans.trans.activeDefault.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.activeDefault"
            (callback)="getTranslations()"
          ></app-editTranslation> </label
        ><br />
        <p-inputSwitch [(ngModel)]="situationObj.defaultActive" name="defaultActive"></p-inputSwitch> {{ situationObj.defaultActive ? 'Yes' : 'No' }}
      </div>
    </div>

    <div class="row" *ngIf="feature88">
      <div class="col-md-12">
        <label for="guided_default_active" class="sidebar_label">Child active by default only when parent selected </label><br />
        <p-inputSwitch [(ngModel)]="situationObj.guidedDefaultActive" name="guidedDefaultActive"></p-inputSwitch> {{ situationObj.guidedDefaultActive ? 'Yes' : 'No' }}
      </div>
    </div>

    <!-- Hide for now, does not need to have a function -->
    <!-- <div class="row" *ngIf="masterValuePropFeature">
			<div class="col-md-12">
				<label for="guided_discovery" class="sidebar_label">{{trans.trans.availableGuidedDiscovery.value}}
					<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
					[transObj]="trans.trans.availableGuidedDiscovery" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				<p-inputSwitch [(ngModel)]="situationObj.guided_discovery" name="guided_discovery"></p-inputSwitch> {{situationObj.guided_discovery ? "Yes" : "No"}}
			</div>
		</div> -->
  </div>

  <div class="btn_container">
    <div class="row">
      <div class="col-xs-12">
        <app-btn-save
          #saveBtn
          [form]="situationForm"
          (callback)="saveSituation(situationForm, saveBtn)"
          [text]="trans.trans.save.value"
          class="btn btn_customizable pull-right"
        ></app-btn-save>
        <app-are-you-sure
          matTooltipPosition="above"
          [message]="trans.trans.are_you_sure.value"
          class="btn cancel-confirm pull-right marg-h"
          icon=""
          [text]="trans.trans.cancel.value"
          (callbackYes)="back()"
        ></app-are-you-sure>
      </div>
    </div>
  </div>
</form>

<div class="sidebar_container_full_page" *ngIf="situationsItemsMenuView">
  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <span><i (click)="toggleBenefits = !toggleBenefits" *ngIf="!toggleBenefits" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
        <span><i (click)="toggleBenefits = !toggleBenefits" *ngIf="toggleBenefits" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
        <span (click)="toggleBenefits = !toggleBenefits" class="clickable title_no_float"
          >{{ trans.trans.benefits.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.benefits"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </span>
      </div>
      <div class="col-xs-4 pull-right">
        <a class="btn btn_customizable pull-right" style="margin-right: 10px">
          <i (click)="toggleOptions = !toggleOptions" *ngIf="!toggleOptions" class="fa fa-ellipsis-h"></i>
          <div *ngIf="toggleOptions" style="float: right">
            <span (click)="toggleExpandCollapse()" *ngIf="!expanded" style="margin-left: 10px">{{ trans.trans.expandAll.value }}</span>
            <span (click)="toggleExpandCollapse()" *ngIf="expanded" style="margin-left: 10px">{{ trans.trans.collapseAll.value }}</span>
          </div>

          <div *ngIf="toggleOptions" style="float: right">
            <span (click)="setRows(10)">10 | </span>
            <span (click)="setRows(25)">25 | </span>
            <span (click)="setRows(50)">50 </span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="toggleBenefits">
    <div>
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="strSearchBenefits"
              (keyup)="benefitsTable.filter($any($event).target.value, 'metric_name', 'contains')"
              type="text"
              placeholder="{{ trans.trans.search.value }}"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
            />
            <span *ngIf="strSearchBenefits !== ''" (click)="strSearchBenefits = ''; benefitsTable.filter('', 'metric_name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>

      <p-table #benefitsTable [columns]="benefitsCols" [value]="benefits" [paginator]="true" [rows]="rows" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-situationBenefit>
          <tr style="width: 100%">
            <td>
              {{ situationBenefit.metric_name }}
              <span
                *ngIf="situationBenefit.description"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="situationBenefit.description"
                matTooltipPosition="above"
              >
                info
              </span>
            </td>
            <td>
              <span>
                {{ situationBenefit.sit_conservative !== 0 ? situationBenefit.sit_conservative : '' }}
              </span>
            </td>
            <td>
              <span>
                {{ situationBenefit.sit_probable !== 0 ? situationBenefit.sit_probable : '' }}
              </span>
            </td>
            <td>
              <span>
                {{ situationBenefit.sit_aggressive !== 0 ? situationBenefit.sit_aggressive : '' }}
              </span>
            </td>
            <td>
              <span>
                {{ +situationBenefit.sit_current_effort !== 0 ? situationBenefit.sit_current_effort : '' }}
              </span>
            </td>
            <td>
              <span *ngIf="situationBenefit.default_na === '1'" class="off">
                {{ trans.trans.non_applicable_by_default.value }}
              </span>
            </td>
            <td>
              <div *ngIf="situationBenefit.active">
                <span class="off" *ngIf="+situationBenefit.active === 0"
                  ><strong>{{ trans.trans.off.value }}</strong></span
                >
                <span class="on" *ngIf="+situationBenefit.active === 1"
                  ><strong>{{ trans.trans.on.value }}</strong></span
                >
              </div>
            </td>
            <td>
              <div *ngIf="situationBenefit.valueChangeToggle">
                <span class="on">{{ trans.trans.values_changed.value }}</span>
              </div>
            </td>

            <td class="pull-right">
              <span class="keywest_icon_group pull-right">
                <span (click)="saveSituationBenefitActive(1, situationBenefit.id)" [matTooltip]="trans.trans.turn_it_on.value" matTooltipPosition="above">
                  <i class="fa fa-square"></i>
                </span>

                <span (click)="saveSituationBenefitActive(0, situationBenefit.id)" [matTooltip]="trans.trans.turn_it_off.value" matTooltipPosition="above">
                  <i class="fa fa-square-o"></i>
                </span>
                <span (click)="showBenefitControls(situationBenefit)" [matTooltip]="trans.trans.change_it.value" matTooltipPosition="above">
                  <i class="fa fa-sliders"></i>
                </span>
                <span (click)="saveSituationBenefitActive('NULL', situationBenefit.id)" [matTooltip]="trans.trans.reset_benefit.value" matTooltipPosition="above">
                  <i class="fa fa-undo"></i>
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <span><i (click)="toggleFactors = !toggleFactors" *ngIf="!toggleFactors" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
        <span><i (click)="toggleFactors = !toggleFactors" *ngIf="toggleFactors" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
        <span (click)="toggleFactors = !toggleFactors" class="clickable title_no_float"
          >{{ trans.trans.factors.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.factors"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="toggleFactors">
    <div>
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="strSearchFactors"
              (keyup)="factorsTable.filter($any($event).target.value, 'name', 'contains')"
              type="text"
              placeholder="{{ trans.trans.search.value }}"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
            />
            <span *ngIf="strSearchFactors !== ''" (click)="strSearchFactors = ''; factorsTable.filter('', 'name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>

      <p-table #factorsTable [columns]="factorCols" [value]="factors" [paginator]="true" [rows]="rows" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-factor>
          <tr>
            <td>
              <span>{{ factor.name }}</span>
            </td>
            <td>
              <span>{{ factor.solution_factor_baseline_answer }}</span>
            </td>
            <td>
              <span *ngIf="!factor.edit">{{ factor.factor_baseline_answer }} </span>
            </td>
            <td>
              <i
                [hidden]="factor.edit"
                (click)="factor.edit = !factor.edit"
                style="margin-left: 10px"
                [matTooltip]="trans.trans.editFactorBaseline.value"
                matTooltipPosition="above"
                class="fa fa-pencil"
              ></i>
              <div *ngIf="factor.edit" class="input-group pull-right">
                <input type="number" [(ngModel)]="factor.factor_old_baseline_answer" class="form-control" />
                <span class="input-group-addon"><i (click)="postFactorBaseline(factor)" class="fa fa-save"></i></span>
                <span class="input-group-addon"><i (click)="cancelOverride(factor)" class="fa fa-times"></i></span>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <span><i (click)="toggleCosts = !toggleCosts" *ngIf="!toggleCosts" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
        <span><i (click)="toggleCosts = !toggleCosts" *ngIf="toggleCosts" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
        <span (click)="toggleCosts = !toggleCosts" class="clickable title_no_float"
          >{{ trans.trans.costs.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.costs"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="toggleCosts">
    <div>
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="strSearchCosts"
              (keyup)="costsTable.filter($any($event).target.value, 'name', 'contains')"
              type="text"
              placeholder="{{ trans.trans.search.value }}"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
            />
            <span *ngIf="strSearchCosts !== ''" (click)="strSearchCosts = ''; costsTable.filter('', 'name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>

      <p-table #costsTable [columns]="costsCols" [value]="costs" [paginator]="true" [rows]="rows" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-situationCost>
          <tr>
            <td>
              {{ situationCost.name }}
              <span *ngIf="situationCost.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situationCost.description" matTooltipPosition="above">
                info
              </span>
            </td>

            <td>
              <div *ngIf="situationCost.available === '0'">
                <span class="off">{{ trans.trans.cost_excluded_to_available_cost.value }}</span>
              </div>
              <div *ngIf="situationCost.available === '1'">
                <span class="on">{{ trans.trans.cost_added_to_available_cost.value }}</span>
              </div>
            </td>
            <td>
              <div *ngIf="situationCost.active === '1'">
                <span class="on">{{ trans.trans.cost_added_to_autoload.value }}</span>
              </div>
              <div *ngIf="situationCost.active === '0'">
                <span class="off">{{ trans.trans.removed_from_autoload.value }}</span>
              </div>
            </td>
            <td>
              <span class="keywest_icon_group pull-right">
                <span (click)="saveSituationCosts(situationCost, 'available')" [matTooltip]="trans.trans.add_to_the_available_costs.value" matTooltipPosition="above">
                  <i class="fa fa-plus"></i>
                </span>
                <span (click)="saveSituationCosts(situationCost, 'exclude')" [matTooltip]="trans.trans.exclude_from_available_costs.value" matTooltipPosition="above">
                  <i class="fa fa-minus"></i>
                </span>
                <span (click)="saveSituationCosts(situationCost, 'autoload')" [matTooltip]="trans.trans.autoload_in_valueprop.value" matTooltipPosition="above">
                  <i class="fa fa-sliders"></i>
                </span>
                <span (click)="saveSituationCosts(situationCost, 'removeauto')" [matTooltip]="trans.trans.remove_from_valueprop.value" matTooltipPosition="above">
                  <i class="fa fa-minus"></i>
                </span>

                <span (click)="saveSituationCosts(situationCost, 'reset')" [matTooltip]="trans.trans.reset_cost.value" matTooltipPosition="above">
                  <i class="fa fa-undo"></i>
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <span><i (click)="toggleCaseStudies = !toggleCaseStudies" *ngIf="!toggleCaseStudies" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
        <span><i (click)="toggleCaseStudies = !toggleCaseStudies" *ngIf="toggleCaseStudies" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
        <span (click)="toggleCaseStudies = !toggleCaseStudies" class="clickable title_no_float"
          >{{ trans.trans.caseStudies.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.caseStudies"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="toggleCaseStudies">
    <div>
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="strSearchCaseStudies"
              (keyup)="caseStudiesTable.filter($any($event).target.value, 'name', 'contains')"
              type="text"
              placeholder="{{ trans.trans.search.value }}"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
            />
            <span *ngIf="strSearchCaseStudies !== ''" (click)="strSearchCaseStudies = ''; caseStudiesTable.filter('', 'name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>

      <p-table #caseStudiesTable [columns]="caseStudiesCols" [value]="caseStudies" [paginator]="true" [rows]="rows" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-situationCaseStudies>
          <tr>
            <td>
              {{ situationCaseStudies.name }}
              <span
                *ngIf="situationCaseStudies.description"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="situationCaseStudies.description"
                matTooltipPosition="above"
              >
                info
              </span>
            </td>

            <td>
              <div *ngIf="situationCaseStudies.active">
                <span class="off" *ngIf="+situationCaseStudies.active === 0"
                  ><strong>{{ trans.trans.off.value }}</strong></span
                >
                <span class="on" *ngIf="+situationCaseStudies.active === 1"
                  ><strong>{{ trans.trans.on.value }}</strong></span
                >
              </div>
            </td>

            <td>
              <span class="keywest_icon_group pull-right">
                <span (click)="saveSituationCaseStudyActive(1, situationCaseStudies.id)" [matTooltip]="trans.trans.turn_it_on.value" matTooltipPosition="above">
                  <i class="fa fa-square"></i>
                </span>
                <span (click)="saveSituationCaseStudyActive('0', situationCaseStudies.id)" [matTooltip]="trans.trans.turn_it_off.value" matTooltipPosition="above">
                  <i class="fa fa-square-o"></i>
                </span>
                <span (click)="saveSituationCaseStudyActive('NULL', situationCaseStudies.id)" [matTooltip]="trans.trans.reset_case_study.value" matTooltipPosition="above">
                  <i class="fa fa-undo"></i>
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div *ngIf="situations.length === 0" class="m-t-md col-sm-10 comp_no_data">
      <div class="searchTableContainer text-center">
        <h4>{{ trans.trans.no_case_studies_yet.value }}</h4>
      </div>
    </div>
  </div>

  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <span><i (click)="toggleAssets = !toggleAssets" *ngIf="!toggleAssets" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
        <span><i (click)="toggleAssets = !toggleAssets" *ngIf="toggleAssets" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
        <span (click)="toggleAssets = !toggleAssets" class="clickable title_no_float"
          >{{ trans.trans.assets.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.assets"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="toggleAssets">
    <div>
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="strSearchAssets"
              (keyup)="assetsTable.filter($any($event).target.value, 'name', 'contains')"
              type="text"
              placeholder="{{ trans.trans.search.value }}"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
            />
            <span *ngIf="strSearchAssets !== ''" (click)="strSearchAssets = ''; assetsTable.filter('', 'name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>

      <p-table #assetsTable [columns]="assetsCols" [value]="assets" [paginator]="true" [rows]="rows" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-situationAssets>
          <tr>
            <td>
              <i class="{{ situationAssets.icon }}" [ngStyle]="getFAColor(situationAssets.format)"></i>
              {{ situationAssets.name }}
              <span
                *ngIf="situationAssets.description"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="situationAssets.description"
                matTooltipPosition="above"
              >
                info
              </span>
            </td>

            <td>
              <div *ngIf="situationAssets.active">
                <span class="off" *ngIf="+situationAssets.active === 0"
                  ><strong>{{ trans.trans.off.value }}</strong></span
                >
                <span class="on" *ngIf="+situationAssets.active === 1"
                  ><strong>{{ trans.trans.on.value }}</strong></span
                >
              </div>
            </td>

            <td>
              <!-- {{situationAssets | json}} -->
              <span class="keywest_icon_group pull-right">
                <span (click)="saveSituationAssetsActive(1, situationAssets.id)" [matTooltip]="trans.trans.turn_it_on.value" matTooltipPosition="above">
                  <i class="fa fa-square"></i>
                </span>
                <span (click)="saveSituationAssetsActive(0, situationAssets.id)" [matTooltip]="trans.trans.turn_it_off.value" matTooltipPosition="above">
                  <i class="fa fa-square-o"></i>
                </span>
                <span (click)="saveSituationAssetsActive('NULL', situationAssets.id)" [matTooltip]="trans.trans.reset_asset.value" matTooltipPosition="above">
                  <i class="fa fa-undo"></i>
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="keywest_page_header">
    <div class="row">
      <div class="col-xs-6">
        <span><i (click)="toggleCollateral = !toggleCollateral" *ngIf="!toggleCollateral" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
        <span><i (click)="toggleCollateral = !toggleCollateral" *ngIf="toggleCollateral" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
        <span (click)="toggleCollateral = !toggleCollateral" class="clickable title_no_float"
          >{{ trans.trans.collateral.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.collateral"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="toggleCollateral">
    <div>
      <div class="row">
        <div class="col-xs-4">
          <div class="input-group keywest_search">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input
              [(ngModel)]="strSearchCollateral"
              (keyup)="collateralTable.filter($any($event).target.value, 'name', 'contains')"
              type="text"
              placeholder="{{ trans.trans.search.value }}"
              class="form-control"
              [ngModelOptions]="{ standalone: true }"
            />
            <span *ngIf="strSearchCollateral !== ''" (click)="strSearchCollateral = ''; collateralTable.filter('', 'name', 'contains')" class="input-group-addon clickable"
              ><i class="fa fa-times"></i
            ></span>
          </div>
        </div>
      </div>

      <p-table #collateralTable [columns]="collateralCols" [value]="collateral" [paginator]="true" [rows]="rows" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-situationCollateral>
          <tr>
            <td>
              {{ situationCollateral.name }}
              <span
                *ngIf="situationCollateral.description"
                class="material-icons-outlined tooltip-custom info-icon"
                [matTooltip]="situationCollateral.description"
                matTooltipPosition="above"
              >
                info
              </span>
            </td>

            <td>
              <div *ngIf="situationCollateral.active">
                <span class="off" *ngIf="+situationCollateral.active === 0"
                  ><strong>{{ trans.trans.off.value }}</strong></span
                >
                <span class="on" *ngIf="+situationCollateral.active === 1"
                  ><strong>{{ trans.trans.on.value }}</strong></span
                >
              </div>
            </td>

            <td>
              <span class="keywest_icon_group pull-right">
                <span (click)="saveSituationCollateralActive(1, situationCollateral.id)" [matTooltip]="trans.trans.turn_it_on.value" matTooltipPosition="above">
                  <i class="fa fa-square"></i>
                </span>
                <span (click)="saveSituationCollateralActive(0, situationCollateral.id)" [matTooltip]="trans.trans.turn_it_off.value" matTooltipPosition="above">
                  <i class="fa fa-square-o"></i>
                </span>
                <span (click)="saveSituationCollateralActive('NULL', situationCollateral.id)" [matTooltip]="trans.trans.reset_collateral.value" matTooltipPosition="above">
                  <i class="fa fa-undo"></i>
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div *ngIf="masterValuePropFeature">
    <div class="keywest_page_header">
      <div class="row">
        <div class="col-xs-6">
          <span><i (click)="toggleProjects = !toggleProjects" *ngIf="!toggleProjects" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
          <span><i (click)="toggleProjects = !toggleProjects" *ngIf="toggleProjects" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
          <span (click)="toggleProjects = !toggleProjects" class="clickable title_no_float"
            >{{ trans.trans.project.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.project"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="toggleProjects">
      <div>
        <div class="row">
          <div class="col-xs-4">
            <div class="input-group keywest_search">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input
                [(ngModel)]="strSearchProjects"
                (keyup)="projectsTable.filter($any($event).target.value, 'name', 'contains')"
                type="text"
                placeholder="{{ trans.trans.search.value }}"
                class="form-control"
                [ngModelOptions]="{ standalone: true }"
              />
              <span *ngIf="strSearchProjects !== ''" (click)="strSearchProjects = ''; projectsTable.filter('', 'name', 'contains')" class="input-group-addon clickable"
                ><i class="fa fa-times"></i
              ></span>
            </div>
          </div>
        </div>

        <p-table #projectsTable [columns]="projectCols" [value]="projectsList" [paginator]="true" [rows]="rows" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ trans.trans[col.field].value }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-situationProject>
            <tr>
              <td>
                {{ situationProject.parent_name }} - {{ situationProject.name }}
                <span
                  *ngIf="situationProject.description"
                  class="material-icons-outlined tooltip-custom info-icon"
                  [matTooltip]="situationProject.description"
                  matTooltipPosition="above"
                >
                  info
                </span>
              </td>

              <td>
                <div *ngIf="+situationProject.active === 1">
                  <span class="on">{{ trans.trans.included_by_default.value }}</span>
                </div>
              </td>
              <td>
                <div *ngIf="+situationProject.changedRecommend === 1">
                  <span class="on">{{ trans.trans.recommended.value }}</span>
                </div>
              </td>
              <td>
                <div *ngIf="+situationProject.changedActive === 1">
                  <span class="on">{{ trans.trans.add_to_list.value }}</span>
                </div>
                <div *ngIf="+situationProject.changedActive === 0">
                  <span class="off">{{ trans.trans.removed_from_list.value }}</span>
                </div>
              </td>

              <td>
                <span class="keywest_icon_group pull-right">
                  <span (click)="saveSituationProjectsActive('addToList', situationProject)">
                    <i class="fa fa-plus" [matTooltip]="trans.trans.add_to_list.value" matTooltipPosition="above"></i>
                  </span>
                  <span (click)="saveSituationProjectsActive('removeFromList', situationProject)">
                    <i class="fa fa-minus" [matTooltip]="trans.trans.remove_from_list.value" matTooltipPosition="above"></i>
                  </span>
                  <span (click)="saveSituationProjectsActive('addAndRecommend', situationProject)">
                    <i class="fa fa-plus" [matTooltip]="trans.trans.add_to_list_and_recommend.value" matTooltipPosition="above"></i>
                  </span>
                  <span (click)="saveSituationProjectsActive('reset', situationProject)">
                    <i class="fa fa-undo" [matTooltip]="trans.trans.reset.value" matTooltipPosition="above"></i>
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<!-- benefit sidebar -->
<p-sidebar [(visible)]="showBenefitSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <form name="changeBenefitForm" #changeBenefitForm="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.change.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.change"
          (callback)="getTranslations()"
        ></app-editTranslation>
        {{ benefitConf.metric_name }}
      </div>

      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-xs-12" appNGError [elemControl]="changeBenefitForm.controls.conservative">
              <label for="conservative" class="sidebar_label"
                >{{ trans.trans.conservative.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.conservative"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                ({{ benefitConf.conservative }})</label
              >
              <input
                type="number"
                class="form-control"
                id="conservative"
                step="0.01"
                name="Conservative"
                placeholder="conservative"
                [(ngModel)]="benefitConf.sit_conservative"
                ret-data="Driver Baseline"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12" appNGError [elemControl]="changeBenefitForm.controls.probable">
              <label for="probable" class="sidebar_label"
                >{{ trans.trans.probable.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.probable"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                ({{ benefitConf.probable }})</label
              >
              <input
                type="number"
                class="form-control"
                id="probable"
                step="0.01"
                name="probable"
                placeholder="Probable"
                [(ngModel)]="benefitConf.sit_probable"
                ret-data="Driver Baseline"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12" appNGError [elemControl]="changeBenefitForm.controls.aggressive">
              <label for="aggressive" class="sidebar_label"
                >{{ trans.trans.aggressive.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.aggressive"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                ({{ benefitConf.aggressive }})</label
              >
              <input
                type="number"
                class="form-control"
                id="aggressive"
                step="0.01"
                name="aggressive"
                placeholder="Aggressive"
                [(ngModel)]="benefitConf.sit_aggressive"
                ret-data="Driver Baseline"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12" appNGError [elemControl]="changeBenefitForm.controls.current_effort">
              <label for="current_effort" class="sidebar_label"
                >{{ trans.trans.currentEffort.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.currentEffort"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="current_effort"
                step="0.01"
                name="current_effort"
                placeholder="Current Effort"
                [(ngModel)]="benefitConf.sit_current_effort"
                ret-data="Driver Baseline"
              />
            </div>
          </div>
        </div>

        <!-- <div [formGroup]="phasingTermGroup">
          <div formArrayName="phasingTermArray">
            <app-error-input-wrapper [errorMessage]="(errorDict$ | async)?.get('crossField')">
              <div class="row" *ngFor="let term of phasingTermArray.controls; let i = index">
                <div [formGroupName]="i">
                  <div class="col-xs-2" *ngIf="i === 0; else yearLabel">
                    <label class="sidebar_label">
                      {{ trans.trans.current_state.value }}
                      <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.current_state"
                        (callback)="getTranslations()"
                      ></app-editTranslation>
                    </label>
                  </div>
                  <ng-template #yearLabel>
                    <div class="col-sm-2" *ngIf="i > 0">
                      <label class="sidebar_label">
                        {{ trans.trans.year.value }} {{ i }}:
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.year"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                      </label>
                    </div>
                  </ng-template>
                  <div class="col-xs-8">
                    <input type="range" [min]="0" [max]="100" formControlName="val" [value]="phasingTermArray.value[i].val" />
                  </div>
                  <div class="col-xs-2">
                    <input type="text" formControlName="val" class="form-control" [value]="phasingTermArray.value[i].val" />
                  </div>
                </div>
              </div>
            </app-error-input-wrapper>
          </div>
        </div> -->
      </div>

      <div class="btn_container">
        <div class="row">
          <div class="col-xs-12">
            <app-btn-save
              *ngIf="showBenefitSidebar"
              [form]="changeBenefitForm"
              (callback)="saveSolutionSituationBenefit()"
              text="Save"
              class="btn btn_customizable pull-right"
            ></app-btn-save>
            <app-are-you-sure
              matTooltipPosition="above"
              [message]="trans.trans.are_you_sure.value"
              class="btn cancel-confirm pull-right marg-h"
              icon=""
              [text]="trans.trans.cancel.value"
              (callbackYes)="back()"
            ></app-are-you-sure>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>

<!-- costs sidebar -->

<p-sidebar [(visible)]="showCostsSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <form name="changeCostForm" #changeCostForm="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.change.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.change"
          (callback)="getTranslations()"
        ></app-editTranslation>
        {{ cost.name }}
      </div>

      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-md-12" appNGError [elemControl]="changeCostForm.controls.accrualType">
              <label class="sidebar_label"
                >{{ trans.trans.how_does_this_accrue.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.how_does_this_accrue"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <select class="form-control" [(ngModel)]="cost.accrual_type_id" name="accrualType">
                <option disabled>{{ trans.trans.choose_one.value }}</option>
                <option *ngFor="let accrualType of accrualTypes" [value]="accrualType.id">
                  {{ accrualType.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12" appNGError [elemControl]="changeBenefitForm.controls.cost">
              <label for="cost" class="sidebar_label"
                >{{ trans.trans.unit_price.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.unit_price"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input type="number" class="form-control" id="cost" name="cost" placeholder="Enter Unit Price" [(ngModel)]="cost.cost" ret-data="Cost" />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" appNGError [elemControl]="changeCostForm.controls.scaleFactor">
              <label class="sidebar_label"
                >{{ trans.trans.scale_factor.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.scale_factor"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <select class="form-control" [(ngModel)]="cost.accrual_type_id" name="scaleFactor">
                <option disabled>{{ trans.trans.choose_one.value }}</option>
                <option *ngFor="let scaleType of scaleTypes" [value]="scaleType.id">
                  {{ scaleType.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="btn_container">
        <div class="row">
          <div class="col-xs-12">
            <app-btn-save
              *ngIf="showCostsSidebar"
              [form]="changeCostForm"
              (callback)="saveSituationCosts(cost, undefined)"
              [text]="trans.trans.save.value"
              class="btn btn_customizable pull-right"
            ></app-btn-save>
            <app-are-you-sure
              matTooltipPosition="above"
              [message]="trans.trans.are_you_sure.value"
              class="btn cancel-confirm pull-right marg-h"
              icon=""
              [text]="trans.trans.cancel.value"
              (callbackYes)="back()"
            ></app-are-you-sure>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>
