import { Injectable } from '@angular/core';

@Injectable()

export class DeploymentScheduleVpDashTranslations {

  public config: any = {
    component: 'deploymentScheduleVpDash',
    display_name: 'Value Prop Deplyment Schedule',
    description: 'Deployment schedule tab in value prop dashboard'
  };

  public trans: any = {
    deploymentScehdule: 'Deployment Schedule',
    No: 'No',
    Year: 'Year',
    Yes: 'Yes',
    next: 'Next',
    save: 'Save',
    deployment_cumulative: 'Deployment (Cumulative)',
    deployment_net: 'Deployment (Net)',
    will_this_have_a_graduated_deployment: 'Will this have a graduated deployment?'
  };
}
