import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safariDate'
})
export class SafariDatePipe implements PipeTransform {

  transform(value: string): string {
    return value?.replace(' ', 'T');
  }

}
