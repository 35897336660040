import { Component, HostListener, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { BenefitsComponent } from '../benefits.component';

@Component({
	selector: 'app-benefit-panel',
	templateUrl: './benefits-panel.component.html',
	styleUrls: ['./benefits-panel.component.scss']
})
export class BenefitPanelComponent extends BenefitsComponent implements OnChanges {
	@Input() menuFilter;
	@Input() benefitsList: any;
	@Input() benefitsListExpanded: any;
	@Input() valueProp: any;
	@Input() contextualHelp: object;
	@Input() dealdesk: boolean;
	@Input() benefitOnTheFlyPrivilege: boolean;
	@Input() statusFilter: string = "";

	@Output() openAddPhasingCallback = new EventEmitter();
	@Output() openBenefitCallback = new EventEmitter();
	@Output() calcStatusCallback = new EventEmitter();
	@Output() openUpdateBenefitCallback = new EventEmitter();

	@Input() addPhasingModal: any;
	@Input() showBatchUpdateCheckboxes: boolean = false;
	searchText: string;
	@Input() expandAll: boolean = false;
	showDeleteCustomBenefit: boolean = false;
	@Output() deleteCustomBenefitCallback = new EventEmitter();
	deleteBenefit: any;

	@Input() showActive: boolean = false;
	@Input() showNA: boolean = false;
	@Input() showSoft: boolean = false;

	screenHeight: number;
	screenWidth: number;
	termLimit: number = 3;
	termLength: number = 2;
	termShow: number;

	filterActive(arr: any[], id) {
		if (!arr) { return 0; }
		let tot = 0;
		// for (let i = 0; i < arr.length; i++) {
		for (let m = 0; m < arr['metrics'].length; m++) {

			if (arr['metrics'][m].active == id) { tot++; }
		}
		// }
		return tot;
	}

	ngOnInit() {
		this.funcAdjustVisibleYears();
	}

	ngOnChanges() {
		if (this.showBatchUpdateCheckboxes) { this.funcExpand(true); }
		if (this.menuFilter) { this.funcExpand(true); }
	}

	openBenefitDetail(benefit) {
		this.openBenefitCallback.emit(benefit);
	}

	openAddPhasing(id) {
		this.openAddPhasingCallback.emit({ content: this.addPhasingModal, id: id });
	}

	opendeleteCustomBenefit(benefit) {
		this.deleteBenefit = benefit;
		this.showDeleteCustomBenefit = !this.showDeleteCustomBenefit;
	}

	deleteCustomBenefit() {
		this.deleteCustomBenefitCallback.emit(this.deleteBenefit);
		this.deleteBenefit = null;
		this.showDeleteCustomBenefit = !this.showDeleteCustomBenefit;
	}

	openUpdateBenefit(benefit) {
		
		this.openUpdateBenefitCallback.emit({ menuItem: "addbenefit", data: benefit });
		// this.valueProp.loadMenuItem.next();
	}

	funcExpand(expand?) {
		if (expand) {
			this.expandAll = expand;
		} else {
			this.expandAll = !this.expandAll;
		}
		this.benefitsListExpanded.forEach(elem => {
			elem.expand = (expand ? expand : this.expandAll);
		})
	}

	calcStatus() {
		// this.calcStatusCallback.emit();
	}
	rangeRightArrow(start) {
		if (!start) {
			return 1;
		}
		else {
			return start + 1;
		}
	}

	rangeLeftArrow(start) {
		if (!start) {
			return 1;
		}
		else {
			return start - 1;
		}
	}

	alignYear(i) {
		if (this.termShow == 1) { return "one"; }
		switch (i) {
			case 0: return "zero";
			case 1: return "one";
			case 2: return "two";
		}
	}

	alignYearTbl(i) {
		if (this.termShow == 1) { return "onetbl"; }
		switch (i) {
			case 0: return "zerotbl";
			case 1: return "onetbl";
			case 2: return "twotbl";
		}
	}

	checkAll(benefit) {
		if (!benefit.allOn) { benefit.allOn = true; } else { benefit.allOn = false; }
		for (let i = 0; i < benefit.metrics.length; i++) {
			benefit.metrics[i].updateBatch = benefit.allOn;
		}
	}

	funcAdjustVisibleYears() {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;

		if (this.screenWidth < 1200) {
			this.termLength = 1;
			this.termLimit = 0;
			this.termShow = 1;
		} else {
			this.termLength = 3;
			this.termLimit = 2;
			this.termShow = 3;
		}

		// 
	}

	showGroup(metric) {
		// 
		let found = false;
		if (this.showActive) {
			let res = metric.filter(x => x.active_type == "Active");
			// 
			if (res.length) { found = true; }
		}

		if (this.showNA) {
			let res = metric.filter(x => x.active_type == "N/A");
			if (res.length) { found = true; }
		}

		if (this.showSoft) {
			let res = metric.filter(x => x.active_type == "Considered Soft");
			if (res.length) { found = true; }
		}
		return found;
	}
	
	@HostListener('window:resize', ['$event']) onResize() {
		this.funcAdjustVisibleYears();
	}
}