import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CapabilitiesService } from '@data/services/capabilities/capabilities.service';
import { FunctionalObjectivesService } from '@data/services/func_objectives/func_objectives.service';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { PainPointsService } from '@data/services/pain_points/pain.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLFieldsetModule } from '@shared_components/primeng_fieldset/fieldset.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';

import { ModelAEBenefits } from './ae_benefits/ae_benefits.module';
import { BenefitsComponent } from './benefits.component';
import { BenefitsTranslations } from './benefits.translation';
import { RBenefitsModule } from './r_benefits/r_benefits.module';

@NgModule({
  declarations: [BenefitsComponent],
  imports: [
    MultiSelectModule,
    MatTooltipModule,
    AreYouSureModule,
    ProgressBarModule,
    ModelAEBenefits,
    RBenefitsModule,
    DLFieldsetModule,
    ApplicationPipesModule,
    TableModule,
    MenuModule,
    SidebarModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    EditTranslationsModule,
    DropdownModule,
  ],
  providers: [PainPointsService, CapabilitiesService, OperationalObjectivesService, FunctionalObjectivesService, TranslationsV2Service, BenefitsTranslations],
  entryComponents: [BenefitsComponent],
  exports: [BenefitsComponent],
})
export class ModelBenefitsModule {}
