import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { BusinessFactor } from '@data/services/goal-groups/models/business-goals-response.interface';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SimpleGoalReactiveForm } from '@shared/models/goal-group.model';
import { ChartData, ChartOptions } from 'chart.js';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ReviewBaselineTranslation } from '../review-baseline/review-baseline.translation';
import { GoalChartOptions } from './chart-options';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { BusinessReviewDataWithNote } from '@data/services/valuerealization/models/business-review.model';

@Component({
  selector: 'app-goal-sidebar',
  templateUrl: './goal-sidebar.component.html',
  styleUrls: ['./goal-sidebar.component.scss'],
})
export class GoalSidebarComponent implements OnInit, OnDestroy {
  @Input() selectedGoal: SimpleGoalReactiveForm;
  @Input() selectedGoalOrignal: BusinessFactor;
  @Input() valuePropId: string;
  @Input() inputView = false;
  @Output() closeSidebar = new EventEmitter();
  ngUnsubscribe = new Subject();
  showTranslate = false;
  goalChart: ChartData;
  showNoteSidebar = false;
  canEdit = false;
  chartOptions: ChartOptions = GoalChartOptions;
  brsWithNotes: BusinessReviewDataWithNote[] = [];
  constructor(
    public trans: ReviewBaselineTranslation,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public businessReviewService: TrackerBusinessReviewService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.businessReviewService.canEdit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.canEdit = response;
    });

    this.businessReviewService.brsWithNotes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((brWithNotes) => {
      this.brsWithNotes = brWithNotes;
    });
  }

  ngOnDestroy(): void {
    this.businessReviewService.brsWithNotes$.next(null);
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  closeSidebarEventEmit(refresh = false): void {
    this.selectedGoalOrignal = { ...this.selectedGoalOrignal };
    const payload = { refresh, goalId: this.selectedGoalOrignal.account_factor_id };
    this.closeSidebar.emit(payload);
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.createChart();
      });
  }
  createChart() {
    if (!this.selectedGoalOrignal) {
      return;
    }
    const chartColors = this.commonService.getChartColors();
    const labels = [];
    this.selectedGoalOrignal.survey_factors.forEach((surveyFactor) => {
      labels.push(surveyFactor.business_review_name);
    });
    this.goalChart = {
      datasets: [],
      labels,
    };
    if (this.selectedGoalOrignal.capture_target) {
      this.goalChart.datasets.push({
        label: this.trans.trans.goal.value,
        type: 'line',
        borderWidth: 1,
        pointBackgroundColor: chartColors[2],
        fill: false,
        order: 1,
        data: this.selectedGoalOrignal.survey_factors.map((survey) => (survey.target_value ? survey.target_value : 0)),
        borderColor: chartColors[2],
      });
    }
    this.goalChart.datasets.push({
      label: this.trans.trans.results.value,
      type: 'bar',
      data: this.selectedGoalOrignal.survey_factors.map((survey) => (survey.factor_value ? survey.factor_value : 0)),
      backgroundColor: chartColors[1],
      order: 2,
    });
  }
  closeNotesSidebar() {
    this.showNoteSidebar = false;
  }
}
