import { Injectable } from '@angular/core';

@Injectable()
export class CaseStudyTranslations {
  public config: any = {
    component: 'ae_case_studies',
    display_name: 'Case Studies',
    description: 'Case Studies',
  };
  public trans: any = {
    backToCaseStudy: 'Back to Case Study List',
    backToValueProp: 'Back to Value Proposition',
    downloadAsset: 'Download Asset',
    selectModel: 'Select Model',
    selectAccount: 'Select an Account',
    companyName: 'Name',
    companyRevenue: 'Revenue',
    companyNumEmployees: '# Employees',
    companyTicker: 'Ticker',
    companyCity: 'City',
    companyCountry: 'Country',
    noCompaniesFound: 'No Companies Found',
    companyAddress: 'Address',
    companySector: 'Sector',
    companyWebsite: 'Website',
    tags: 'Tags',
    companyEmployees: 'Employees',
    referenceUrl: 'Reference URL',
    cancel: 'Cancel',
    editCaseStudyName: 'Edit Case Study Name',
    save: 'Save'
  };
}