import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Translations } from '@data/services/translation/translation.service';
import { UserService } from '@data/services/user/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StyleService } from 'app/style.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html',
  styleUrls: ['./saml.component.scss'],
})
export class SamlComponent implements OnInit {
  hash: string;
  userId: string;
  relay: string;
  token: string;
  showYea = false;
  showNea = false;
  subscriptionlogin: Subscription;
  @Output() closeLogin: EventEmitter<null> = new EventEmitter();

  constructor(
    private userService: UserService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
    public trans: Translations,
    private route: ActivatedRoute,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.hash = this.route.snapshot.paramMap.get('hash');
    const hash_decoded = atob(this.hash);
    if (hash_decoded.includes(':')) {
      const bits = hash_decoded.split(':');
      this.userId = bits[0];
      this.token = bits[1];
      this.relay = bits[2];

      const payload = {
        user_id: this.userId,
        token: this.token,
      };

      this.userService.verifySAML(payload).subscribe((response) => {
        if (response.result.success) {
          this.showYea = true;
          this.showNea = false;
          const user_data = response.result.userInfo;
          this.store(user_data);
        } else {
          this.showNea = true;
          this.showYea = false;
        }
      });
    }
  }

  store(data): void {
    const user_id = this.userId;
    const account_id = data.account_id;
    const role_type_id = data.role_type_id;
    const features = data.features;
    const privs = data.privileges;
    const vcu = this.token;
    const account_name = data.account_name;
    const asset_gen_file = data.asset_gen_file;
    const header_color = data.header_color;
    const language_type_id = data.language_type_id;
    const button_color = data.button_color;
    const button_text_color = data.button_text_color;
    const email = data.email;
    const username = data.username;
    const role_type = data.role_type;
    const chart_colors = data.chart_colors.account_colors;

    const ccolors = [];
    chart_colors.forEach(function (item) {
      ccolors.push(item.color);
    });

    const enc_chart_colors = JSON.stringify(ccolors);

    const crm_obj = {
      type: 'saml',
    };

    const crm = JSON.stringify(crm_obj);

    sessionStorage.setItem('header_color', header_color);
    sessionStorage.setItem('chart_colors', enc_chart_colors);
    sessionStorage.setItem('button_color', button_color);
    sessionStorage.setItem('button_text_color', button_text_color);
    sessionStorage.setItem('asset_gen_file', asset_gen_file);
    sessionStorage.setItem('language_type_id', language_type_id);
    sessionStorage.setItem('account_name', account_name);
    sessionStorage.setItem('vcu', vcu);
    sessionStorage.setItem('uid', user_id);
    sessionStorage.setItem('aid', account_id);

    sessionStorage.setItem('rid', role_type_id);
    sessionStorage.setItem('features', features);
    this.styleService.updateStyle2022();
    sessionStorage.setItem('privileges', privs);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('username', username);
    sessionStorage.setItem('role', role_type);
    sessionStorage.setItem('crm', crm);

    if (this.relay) {
      window.location.href = '/' + this.relay;
    }
  }
}
