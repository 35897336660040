<div class="sidebar_wrapper">
    <div class="sidebar_header">
        {{ trans.trans.editGoal.value }}
        <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.editGoal"
                (callback)="getTranslations()"
        ></app-editTranslation>
    </div>

    <p-progressBar *ngIf="loadingSurveys; else contentContainer" class='keywest_progressbar' mode="indeterminate"></p-progressBar>

    <ng-template #contentContainer>
        <ng-container *ngIf="goalData.survey_factors.length; else noSurveys">
            <div class="sidebar_container">
                <label>
                    {{ trans.trans.name.value }}
                </label><br/>
                {{goalData.factor_name}}

                <div *ngIf="chartData" class="tracker-chart-container">
                    <p-chart type="bar" [data]="chartData.data" [options]="chartOptions"></p-chart>
                </div>

                <ng-container *ngIf="goalData.factor_description">
                    <label>
                        {{ trans.trans.description.value }}
                    </label><br/>
                    {{goalData.factor_description}}

                    <br><br>
                </ng-container>

                <p-table [value]="goalData.survey_factors" styleClass='keywest_table' tableStyleClass='keywest_table'>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                {{trans.trans.surveyCol.value}}
                            </th>
                            <th>
                                {{trans.trans.dateCol.value}}
                            </th>
                            <th>
                                {{trans.trans.valueCol.value}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>{{rowData.survey_name}}</td>
                            <td>{{rowData.survey_effective}}</td>
                            <td>
                                <input class='form-control' type='text' [(ngModel)]="rowData.factor_value" (ngModelChange)="buildChart()">
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class='btn_container'>
                <div class='row'>
                    <div class="col-md-12">
                        <button class="btn btn_customizable pull-right" (click)="save()">
                            <ng-container *ngIf="!loadingSave; else spinner">
                                {{ trans?.trans?.saveButton?.value }}
                            </ng-container>
                            <ng-template #spinner>
                                <i class="fa fa-spinner fa-spin fa-fw"></i>
                            </ng-template>
                        </button>
                        <button class="btn pull-right" (click)="cancel()">
                            {{ trans?.trans?.backButton?.value }}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #noSurveys>
            <div class="sidebar_container">
                {{trans.trans.noSurveys.value}}
            </div>
        </ng-template>
    </ng-template>

</div>

