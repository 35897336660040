import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-costs',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss'],
})
export class CostComponent implements OnInit, OnDestroy, OnChanges {
  @Input() canEdit = false;

  @Input() solutionId: number | string;
  @Input() tabInit: number;

  loadSolutionCosts = false;
  costs: { [klass: string]: any }[] = [];
  costCategoryTypes: { [klass: string]: any }[] = [];
  factCostCategoryTypes: { [klass: string]: any }[] = [];
  expenseTypes: { id: string | number; name: string }[];
  accrualTypes: { id: string | number; name: string }[];
  scaleTypes: { [klass: string]: any }[];
  costsModel: { [klass: string]: any };
  modalReference: NgbModalRef;
  imageUrl: string;
  fullImagePath: string;
  accountSolutionId: number;

  costId: string | number = null;
  name: string;
  costaccrualType: number;
  cost: number;
  scaleFactor: { [klass: string]: any };
  readOnly: boolean;
  costCategoryTypeId: number;
  driverFactorId: number;
  expenseTypeId: number;
  situationsTied: Array<{ [klass: string]: any }> = [];
  accountId: string | number;
  costInValueprop = 0;
  costBaselineAnswer = 0;

  revenueToUs: boolean;

  hasOurCostFeature = false;

  contextualHelp: { [klass: string]: any } = {};
  hideActionLoader = true;
  costType = 'Add';
  newCostCategory: Array<{ [klass: string]: any }> = [];
  drivers: { [klass: string]: any }[] = [];

  subscriptioncontextualHelp: Subscription;
  subscriptiontriggerCost: Subscription;
  subscriptiongetSolutionCosts: Subscription;
  subscriptioneditSolutionCosts: Subscription;
  subscriptiongetAccrualTypes: Subscription;
  subscriptiongetAvailableScalers: Subscription;
  subscriptiongetAccountCostCategoryTypes: Subscription;
  subscriptiongetExpenseTypes: Subscription;
  subscriptiondeleteSolutionCosts: Subscription;
  subscriptioncreateSolutionCosts: Subscription;
  subscriptionupdateSolutionCosts: Subscription;
  subscriptiongetCostCategoryTypes: Subscription;
  subscriptionaddNewCostCategory: Subscription;
  subscriptiongetDrivers: Subscription;
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private factsService: FactsService,
    private commonService: CommonService,
    private notification: NotificationService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.subscriptiontriggerCost = this.solutionService.triggerCost.subscribe(() => {
      this.getSolutionCostList();
    });
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.hasOurCostFeature = this.commonService.checkFeature(55);

    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptiontriggerCost) {
      this.subscriptiontriggerCost.unsubscribe();
    }
    if (this.subscriptiongetSolutionCosts) {
      this.subscriptiongetSolutionCosts.unsubscribe();
    }
    if (this.subscriptioneditSolutionCosts) {
      this.subscriptioneditSolutionCosts.unsubscribe();
    }
    if (this.subscriptiongetAccrualTypes) {
      this.subscriptiongetAccrualTypes.unsubscribe();
    }
    if (this.subscriptiongetAvailableScalers) {
      this.subscriptiongetAvailableScalers.unsubscribe();
    }
    if (this.subscriptiongetAccountCostCategoryTypes) {
      this.subscriptiongetAccountCostCategoryTypes.unsubscribe();
    }
    if (this.subscriptiongetExpenseTypes) {
      this.subscriptiongetExpenseTypes.unsubscribe();
    }
    if (this.subscriptiondeleteSolutionCosts) {
      this.subscriptiondeleteSolutionCosts.unsubscribe();
    }
    if (this.subscriptioncreateSolutionCosts) {
      this.subscriptioncreateSolutionCosts.unsubscribe();
    }
    if (this.subscriptionupdateSolutionCosts) {
      this.subscriptionupdateSolutionCosts.unsubscribe();
    }
    if (this.subscriptiongetCostCategoryTypes) {
      this.subscriptiongetCostCategoryTypes.unsubscribe();
    }
    if (this.subscriptionaddNewCostCategory) {
      this.subscriptionaddNewCostCategory.unsubscribe();
    }
    if (this.subscriptiongetDrivers) {
      this.subscriptiongetDrivers.unsubscribe();
    }
  }

  ngOnChanges() {
    if (this.tabInit === 1) {
      this.route.params.subscribe((params) => (this.accountSolutionId = params['id']));

      this.getSolutionCostList();
      this.getAccrualTypes();
      this.getScaleTypes();
      this.getCostCategoryTypes();
      this.getFactCostCategoryTypes();
      this.getExpenseTypes();
      this.loadDrivers();
    }
  }

  getSolutionCostList() {
    this.loadSolutionCosts = true;
    this.subscriptiongetSolutionCosts = this.solutionService
      .getSolutionCosts(this.solutionId)
      .pipe(finalize(() => (this.loadSolutionCosts = false)))
      .subscribe((response) => {
        if (response.result) {
          this.costs = response.result;
        } else {
          this.costs = [];
        }
      });
  }
  openAddSolutionCost(content: any) {
    this.costType = 'Add';
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {
        this.resetValues();
      },
      () => {
        this.resetValues();
      }
    );
  }
  editSolutionCosts(content: any, id: string | number, index: number) {
    this.costType = 'Edit';
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.finally(() => {
      this.resetValues();
    });

    this.subscriptioneditSolutionCosts = this.solutionService.editSolutionCosts(id).subscribe((response) => {
      if (response.result) {
        this.costId = response.result.id;
        this.accountSolutionId = response.result.account_solution_id;
        this.name = response.result.name;
        this.costaccrualType = response.result.accrual_type_id;
        this.cost = response.result.cost;

        if (this.scaleTypes.length > 0) {
          // tslint:disable-next-line: triple-equals
          const scaleFactor = this.scaleTypes.filter((scale) => scale['scale_type_id'] == response.result.scale_type_id);
          this.scaleFactor = scaleFactor[0];
        }
        this.costCategoryTypeId = response.result.cost_category_type_id;
        this.driverFactorId = response.result.driver_factor_id;
        this.expenseTypeId = response.result.expense_type_id;
        this.solutionService.triggerTabs.next('costs');
        // tslint:disable-next-line: radix
        this.costInValueprop = parseInt(response.result.default_include);
        // tslint:disable-next-line: radix
        this.costBaselineAnswer = parseInt(response.result.cost_baseline_answer);

        if (this.hasOurCostFeature) {
          this.revenueToUs = response.result.cost_to_us === '1';
        }
      }
    });

    this.situationsTied = this.costs[index]['situation'];
  }
  getAccrualTypes() {
    this.subscriptiongetAccrualTypes = this.factsService.getAccrualTypes().subscribe((result) => {
      this.accrualTypes = result.result.filter((item: { id: string | number; name: string }) => {
        // tslint:disable-next-line: triple-equals
        return item.id != 3;
      });
    });
  }
  getScaleTypes() {
    this.subscriptiongetAvailableScalers = this.solutionService.getAvailableScalers(this.solutionId, 0).subscribe((result) => {
      this.scaleTypes = result.result;
    });
  }

  getCostCategoryTypes() {
    this.accountId = sessionStorage.getItem('aid');
    this.costCategoryTypes = [];
    this.subscriptiongetAccountCostCategoryTypes = this.factsService.getAccountCostCategoryTypes(this.accountId).subscribe((result) => {
      if (result.result) {
        this.costCategoryTypes = result.result;
      }
    });
  }

  getExpenseTypes() {
    this.subscriptiongetExpenseTypes = this.factsService.getExpenseTypes().subscribe((result) => {
      this.expenseTypes = result.result;
    });
  }

  deleteSolutionCost() {
    this.hideActionLoader = false;
    this.subscriptiondeleteSolutionCosts = this.solutionService.deleteSolutionCosts(this.costId).subscribe(() => {
      this.getSolutionCostList();
      this.hideActionLoader = true;
      this.modalReference.close();
      this.notification.success('Solution Cost deleted successfully', false);
    });
  }

  SaveCosts(form: NgForm) {
    this.costsModel = {
      account_solution_id: this.solutionId,
      name: form.value.name,
      accrual_type_id: form.value.costaccrualType,
      cost: form.value.cost,
      scale_type_id: form.value.scaleFactor['scale_type_id'],
      cost_category_type_id: form.value.cost_category_type_id,
      driver_factor_id: form.value.driver_factor_id,
      expense_type_id: form.value.expense_type_id,
      default_include: form.value.costInValueprop,
      cost_baseline_answer: form.value.cost_baseline_answer,
    };

    if (this.hasOurCostFeature) {
      this.costsModel['cost_to_us'] = form.value.revenueToUs ? 1 : 0;
    }

    if (this.costId == null) {
      this.subscriptioncreateSolutionCosts = this.solutionService.createSolutionCosts(this.costsModel).subscribe(() => {
        this.notification.success('Costs created successfully', false);
        this.getSolutionCostList();
        this.solutionService.triggerTabs.next('costs');
        this.costId = null;
      });
    } else if (this.costId != null) {
      this.costsModel['account_solution_cost_id'] = this.costId;
      this.subscriptionupdateSolutionCosts = this.solutionService.updateSolutionCosts(this.costsModel).subscribe(() => {
        this.notification.success('Costs updated successfully', false);
        this.getSolutionCostList();
        this.solutionService.triggerTabs.next('costs');
        this.costId = null;
      });
    }

    this.modalReference.close();
  }
  resetValues() {
    this.costId = undefined;
    this.name = '';
    this.costaccrualType = 0;
    this.cost = 0;
    this.scaleFactor = undefined;
    this.costCategoryTypeId = 0;
    this.driverFactorId = 0;
    this.costInValueprop = 0;
    this.costBaselineAnswer = 0;
    this.revenueToUs = false;
  }

  open(content: any, id: string | number) {
    this.costId = '';
    this.costId = id;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  getFactCostCategoryTypes() {
    this.factCostCategoryTypes = [];
    this.subscriptiongetCostCategoryTypes = this.factsService.getCostCategoryTypes().subscribe((result) => {
      if (result.result) {
        this.factCostCategoryTypes = result.result.map((cat: { [klass: string]: any }) => ({
          label: cat.name,
          value: cat,
        }));
      }
    });
  }

  openAddCostCategory(content: any) {
    this.modalReference = this.modalService.open(content, {
      windowClass: 'deleteModal secondLayerModalWindow',
      backdropClass: 'secondLayerModalBackdrop',
      backdrop: 'static',
      keyboard: false,
    });
  }

  addNewCostCategory() {
    const params = {
      account_id: sessionStorage.getItem('aid'),
      cost_category_type_id: this.newCostCategory['id'],
    };

    this.subscriptionaddNewCostCategory = this.solutionService.addNewCostCategory(params).subscribe((result) => {
      if (result.result && result.result.success) {
        this.getCostCategoryTypes();
        this.notification.success('Costs category added successfully', false);
      }
    });
  }

  loadDrivers() {
    this.subscriptiongetDrivers = this.solutionService.getDrivers(this.solutionId).subscribe((response) => {
      if (response.result) {
        this.drivers = response.result.drivers;
      }
    });
  }
}
