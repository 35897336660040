import { Injectable } from '@angular/core';

@Injectable ()

export class TranslationsV2Translation {

    public config: any = {
        component: 'translationv2Component',
        display_name: 'Translations List',
        description: 'List of all translations to edit'
    };

    public trans: any = {
        back_to_profile: 'Back to Profile',
        translations: 'Translations',
        k: 'Key',
        1: 'English US',
        2: 'English UK',
        3: 'Spanish',
        4: 'French',
        5: 'Chinese',
        6: 'Portuguese',
        7: 'German',
        8: 'Japanese',
        9: 'Korean',
        selectedLanguage: 'Selected Language:',
        expand_all: 'Expand All',
        collapse_all: 'Collapse All',
        save: 'Save',
        settings: 'Settings',
        dashboard: 'Dashboard',
        translationsUpdated: 'Translations successfully updated'
    };
}
