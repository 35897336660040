import { Injectable } from '@angular/core';

@Injectable ()
export class RCompetitorTranslation {
    
    public config: any = {
        component: 'readCompetitor',
        display_name: 'Competitor View',
        description: 'View of an individual competitor'
    };

    public trans: any = {
        viewCompetitors: 'View Competitors',
        name: 'Name',
        description: 'Description',
        landmines: 'Landmines',
        theyllSay: 'They\'ll say',
        theirLandmines: 'Their Landmines',
        ourResponse: 'Our Response'
    };
}
