<div class="col-md-6" style='margin-bottom: 10px;'>
	<div class='models_panel'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>{{trans.trans.SCBHeading.value}}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.mvp_seeMore'
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.SCBHeading"></app-editTranslation>
						</div>
					</div>
					<div class='col-xs-2'>
						<button *ngIf="dbI.isValueInsightUser && !dbI.isGuest" class="btn btn_customizable pull-right" routerLink="/scb"><i class='fa fa-plus'></i> {{ trans.trans.add.value }} </button>
					</div>
				</div>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<div class="" style='overflow-y: auto; max-height: 440px;'>
					<table *ngIf="dbI.isValueInsightUser" class="table custom-table dashboard-table">
						<thead>
							<tr>
								<th>{{ trans.trans.scb_name.value }}
									<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.scb_name'
									[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.scb_name"></app-editTranslation>
								</th>
								<th width="100">{{ trans.trans.scb_date.value }}
									<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.scb_date'
									[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.scb_date"></app-editTranslation>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr class="grid-row company-row" *ngFor="let research of dbI.researchList">
								<td (click)="dbI.selectCompany(research.company_id)">{{research.name}}</td>
								<td>{{research.modified | date:"MM/dd/yy"}}</td>
								<td class="text-center">
									<span *ngIf="!research.expired" class="icon-cloud-download" (click)="dbI.downloadSCB(research.hash)"></span>
									<span *ngIf="research.expired" class="icon-cloud-download expired"></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div *ngIf="dbI.researchList.length==0" class="m-t-md col-sm-12">
					<div class=" text-center">
						<h4>{{ trans.trans.noScbFound.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.noScbFound'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.noScbFound"></app-editTranslation>
						</h4>
					</div>
				</div>

				<div *ngIf="!dbI.isValueInsightUser">
					<p>{{ trans.trans.notLicensedForValueInsights.value }}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.scb_seemore'
						[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.notLicensedForValueInsights"></app-editTranslation></p>
				</div>

				<div *ngIf="dbI.isValueInsightUser">
					<a routerLink="/scb" class="keywest_seeMore">{{ trans.trans.scb_seemore.value }}</a>
					<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.scb_seemore'
					[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.scb_seemore"></app-editTranslation>
				</div>
			</div>
		</div>

	</div>
</div>