<div class='row' *ngIf="showHelp">
	<div class='col-xs-12'>
		<div class='well well-info'>
			<b>{{trans.vp_dashboard.menu.benefits | translate}}</b><br>
			<!--{{contextualHelp['Position 11']}} -->
			{{trans.contextual_help.vp_dashboard.benefits | translate}}
		</div>
	</div>
</div>
<!-- {{benefitDetail}} -->

<p-sidebar [(visible)]="showTranslateSidebar" position='right'>
	<h2 style='margin-bottom: 10px;'>Translate Benefits</h2>
	<div style='overflow-y: scroll; height: 100%; display: block; padding-bottom: 80px;'>
		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.search | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.search'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.show_all | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.show_all'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.active | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.active'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.na | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.na'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.soft | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.soft'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.clear | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.clear'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.setbulk | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.setbulk'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.expand | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.expand'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.collapse | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.collapse'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year1 | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year1'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year2 | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year2'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year3 | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year3'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year4 | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year4'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year5 | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year5'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year6 | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year6'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.year7 | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.year7'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.total | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.total'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.phasing | translate}} PHASING
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.phasing'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.status | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.status'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.benefits | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.benefits'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.impact | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.impact'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.impact_over_time | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.impact_over_time'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.current_state | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.current_state'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.apply | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.apply'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.apply_metrics | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.apply_metrics'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.apply_metrics_category | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.apply_metrics_category'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.apply_metrics_model | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.apply_metrics_model'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.choose | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.choose'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.no_benefits_found | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.no_benefits_found'></app-editTranslation>
			</div>
		</div>

		<div class='row trans_row'>
			<div class='col-xs-12'>
				{{trans.vp_dashboard.benefits.delete | translate}}
				<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.delete'></app-editTranslation>
			</div>
		</div>
	</div>
</p-sidebar>
<!-- <div *ngIf="benefitDetail">
	<a href="javascript:void(0)" (click)="closeBenefitDetail()" class="back-btn"><span class="glyphicon glyphicon-chevron-left"></span>{{ trans.button.back | translate}}</a>
	<app-benefit-detail [data]="selectedBenefit" [valueProp]="valueProp" [contextualHelp]="contextualHelp" (closeBenefitDetail)="closeBenefitDetail()"></app-benefit-detail>
</div> -->
<!-- <div *ngIf="!benefitDetail"> -->
<p-menubar #benefitsMenu [model]="menuBenefitList" [hidden]='benefitsSimple' *ngIf="!dealdesk">

	<div *ngIf="!showBatchUpdateCheckboxes && benefitsPanel" class='pull-right'>
		<input id='menuBarSearch' type="text" [(ngModel)]="menuFilter" pInputText placeholder="Search" style='height: 36px;'>
	</div>
	<div *ngIf="!showBatchUpdateCheckboxes && benefitsPanel" class='pull-right'>
		<button *ngIf="expandAll" (click)='funcExpand()' class='btn btn-primary pull-right'><i class='fa fa-minus'></i> {{trans.vp_dashboard.benefits.collapse | translate}}</button>
		<button *ngIf="!expandAll" (click)='funcExpand()' class='btn btn-primary pull-right'><i class='fa fa-plus'></i> {{trans.vp_dashboard.benefits.expand | translate}}</button>
	</div>
	<div class="pull-right">
		<button class="btn btn-primary" *ngIf="valueProp.vp_can_edit && !showBatchUpdateCheckboxes" (click)="openSetActive()">{{trans.vp_dashboard.benefits.setbulk | translate }}</button>
	</div>
	<div *ngIf="showBatchUpdateCheckboxes" class="pull-right">
		<div class="input-group" style='max-width: 300px;'>
			<select class="form-control active-type" name="benefit_active_batch" [(ngModel)]="batchActive">
				<option *ngFor="let activeType of activeTypes" [value]="activeType.value">
					{{activeType.name}}</option>
			</select>
			<div class='input-group-btn'>
				<button type="button" class="btn btn-success" style='height: 34px;' (click)="statusBatchUpdate();">{{ trans.button.save | translate}}</button>
				<button type="button" class="btn btn-danger" style='height: 34px;' (click)="closeSetActive()">{{ trans.button.cancel | translate}}</button>
			</div>
		</div>
	</div>
	<i *ngIf="showTranslate" class='fa fa-exchange clickable' style='margin-top: 10px; margin-right: 10px;' (click)='showTranslateSidebar = !showTranslateSidebar'></i>
</p-menubar>

<div *ngIf="benefitsLoader" class="col-sm-12 m-t-md text-center">
	<img class="loader" src="{{fullImagePath}}" /><br>
	<p class="text-center">
		Loading Benefits
	</p>
</div>

<div *ngIf="!benefitsList" class="col-sm-12 m-t-md text-center">
	<p class="text-center">
		{{trans.vp_dashboard.benefits.no_benefits_found | translate}}
	</p>
</div>

<!-- TODO?: Is this component still being used? -->
<!-- <app-benefit-panel-create-flow [showActive]='showActive' [showNA]='showNA' [showSoft]='showSoft' [benefitsLoader]='benefitsLoader' [expandAll]='expandAll' [statusFilter]='statusFilter' dashboard='interior' panel='panel' (callbackUpdateCurrent)='callbackUpdate()' [benefitOnTheFlyPrivilege]='benefitOnTheFlyPrivilege' [menuFilter]='menuFilter' *ngIf="dealdesk" [showBatchUpdateCheckboxes]='showBatchUpdateCheckboxes'  (calcStatusCallback)='countStatus()' (openAddPhasingCallback)='openAddPhasingFunc($event)' (openBenefitCallback)='openBenefitDetail($event)' [benefitsListExpanded]='benefitsListExpanded' [(benefitsList)]='benefitsList' [dealdesk]='dealdesk' [valueProp]='valueProp' (openUpdateBenefitCallback)='funcOpenUpdateBenefitCallback($event)' (deleteCustomBenefitCallback)='deleteCustomBenefit($event)' [contextualHelp]='contextualHelp'></app-benefit-panel-create-flow> -->

<app-benefit-panel [showBatchUpdateCheckboxes]='showBatchUpdateCheckboxes'
[showActive]='showActive' [showNA]='showNA' [showSoft]='showSoft'
[expandAll]='expandAll' [statusFilter]='statusFilter' dashboard='interior'
panel='panel' [benefitOnTheFlyPrivilege]='benefitOnTheFlyPrivilege' [menuFilter]='menuFilter'
*ngIf="benefitsPanel && !dealdesk" [showBatchUpdateCheckboxes]='showBatchUpdateCheckboxes'
 [showActive]='showActive' [showNA]='showNA' [showSoft]='showSoft'
(calcStatusCallback)='countStatus()' (openAddPhasingCallback)='openAddPhasingFunc($event)'
(openBenefitCallback)='openBenefitDetail($event)' [benefitsListExpanded]='benefitsListExpanded'
[benefitsList]='benefitsList' [dealdesk]='dealdesk' [valueProp]='valueProp' (openUpdateBenefitCallback)='funcOpenUpdateBenefitCallback($event)'
(deleteCustomBenefitCallback)='deleteCustomBenefit($event)' [contextualHelp]='contextualHelp'></app-benefit-panel>

<app-benefit-panel-simple [showContinue]="showContinue" [benefitsLoader]='benefitsLoader' [showBatchUpdateCheckboxes]='showBatchUpdateCheckboxes' [showActive]='true' [showNA]='true' [showSoft]='true' [expandAll]='expandAll' [statusFilter]='statusFilter' dashboard='interior' panel='panel' [benefitOnTheFlyPrivilege]='benefitOnTheFlyPrivilege' [menuFilter]='menuFilter' *ngIf="benefitsSimple && !benefitsPanel && !dealdesk" [showBatchUpdateCheckboxes]='showBatchUpdateCheckboxes' [showActive]='showActive' [showNA]='showNA' [showSoft]='showSoft' (calcStatusCallback)='countStatus()' (openAddPhasingCallback)='openAddPhasingFunc($event)' (openBenefitCallback)='openBenefitDetail($event)' [benefitsListExpanded]='benefitsListExpanded' [benefitsList]='benefitsList' [dealdesk]='dealdesk' [valueProp]='valueProp' (openUpdateBenefitCallback)='funcOpenUpdateBenefitCallback($event)' (deleteCustomBenefitCallback)='deleteCustomBenefit($event)' [contextualHelp]='contextualHelp'></app-benefit-panel-simple>

<app-benefit-table [showBatchUpdateCheckboxes]='showBatchUpdateCheckboxes' *ngIf="isTable && !benefitsSimple && !benefitsPanel && !dealdesk" panel='panel' (callbackChangeView)='changeView($event)' [benefitOnTheFlyPrivilege]='benefitOnTheFlyPrivilege' [showBatchUpdateCheckboxes]='showBatchUpdateCheckboxes' [showActive]='showActive' [showNA]='showNA' [showSoft]='showSoft' (openAddPhasingCallback)='openAddPhasingFunc($event)' (openBenefitCallback)='openBenefitDetail($event)' (openUpdateBenefitCallback)='funcOpenUpdateBenefitCallback($event)' (deleteCustomBenefitCallback)='deleteCustomBenefit($event)' [benefitsList]='benefitsList' [dealdesk]='dealdesk' [valueProp]='valueProp' [contextualHelp]='contextualHelp'></app-benefit-table>

<p-sidebar [(visible)]="showPhasing" position='left' styleClass="p-sidebar-md">
	<h2>{{trans.vp_dashboard.benefits.impact_over_time}}</h2>
	<form name="AddPhasingForm" #AddPhasingForm="ngForm">
		<div class="row">
			<div class="phase-sliders">
				<div class="col-sm-10 phase-label">{{ trans.vp_dashboard.benefits.phasing | translate }}</div>
				<div *ngIf="metricbenefitsLoader" class="col-sm-12 m-t-md text-center">
					<p class="text-center">
						<img class="loader" [src]="fullImagePath" />
					</p>
				</div>

				<div *ngIf="metricbenefits && metricbenefits.length">
					<div *ngFor="let metricbenefit of metricbenefits; let i = index" class="form-group col-sm-11 phase-slider-list">
						<div class="col-sm-3" *ngIf="i == 0"> {{trans.vp_dashboard.benefits.current_state | translate}} </div>
						<div class="col-sm-3" *ngIf="i > 0"> {{ trans.vp_dashboard.benefits.year | translate}} {{i}}: </div>
						<div class="col-sm-7">
							<input type='range'  min="0" max="100" [(ngModel)]="metricbenefit.value" step="1">
						</div>
						<div class="col-sm-2">
							<input type='text' name='metricbenefit{{i}}' [(ngModel)]="metricbenefit.value" class='form-control'>
						</div>
					</div>
				</div>
				<br>
				<div class="col-md-11">
					<div class="form-group">
						<div class='col-sm-12' *ngIf="modalLoading">
							<img class="loader" [src]="fullImagePath" />
						</div>
						<div class="col-sm-12" *ngIf="!modalLoading">
							<div class="dropdown pull-right">
								<a class='btn btn-success pull-right' data-toggle="dropdown" data-hover="dropdown">{{trans.button.save | translate}} <i class='fa fa-chevron-down'></i></a>
								<ul *ngIf="valueProp.vp_can_edit" class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
									<li (click)="updatePhasing(0)">{{ trans.vp_dashboard.benefits.apply | translate}}</li>
									<li (click)="updatePhasing(1)">{{ trans.vp_dashboard.benefits.apply_metrics | translate}}</li>
									<li (click)="updatePhasing(2)">{{ trans.vp_dashboard.benefits.apply_metrics_category | translate}}</li>
									<li (click)="updatePhasing(3)">{{ trans.vp_dashboard.benefits.apply_metrics_model | translate}}</li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>

<div class="col-sm-12 benefits-wrapper-tile" *ngIf="!isTable">
	<div class='row'>
		<div class='col-xs-12'>
			<span class="changeView pull-right">
				<i class="fa fa-th-large" *ngIf="isTable" (click)="changeView('tile')"></i>
				<i class="fa fa-list-ul" *ngIf="!isTable" (click)="changeView('table')"></i>
			</span>
		</div>
	</div>
	<div>
		<div *ngFor="let benefitCategory of benefitsList" class="row">
			<div class="clearfix text-center customer_relations col-md-12">
				<div class="col-sm-12 title_line">
					<h4>
						<span>{{benefitCategory.name}} ({{benefitCategory.metrics.length}})</span>
					</h4>
				</div>
			</div>
			<div class="row value_cat_benefits" *ngIf="benefitCategory.metrics && benefitCategory.metrics.length > 0 && benefitCategory.metrics[0] !== null">
				<!-- TODO?: Is this component still being used? -->
				<div class="col-sm-6 boxWrapper" *ngFor="let benefit of benefitCategory.metrics | filter : null : 'metric_name'">
					<div class="benefit-card">
						<!-- <div class="icon-pencil" (click)="editBenefit(benefit)"></div> -->
						<div class="icon-row">
							<code data-type="unit_type" *ngIf="benefit.unit_type" role="button">
									<span class="iconsSpan" matTooltipPosition="below" matTooltip="{{benefit.unit_type}}">
										<i class="icon-hourglass" *ngIf="benefit.unit_type == 'Seconds' ||
                      benefit.unit_type == 'Minutes' ||
                      benefit.unit_type == 'Hours' ||
                      benefit.unit_type == 'Days' ||
                      benefit.unit_type == 'Months' ||
                      benefit.unit_type == 'Years'"></i>

										<i class="letter" *ngIf="benefit.unit_type == 'Dollars' ||
                      benefit.unit_type == 'Dollars - Thousands' ||
                      benefit.unit_type == 'Dollars - Millions' ||
                      benefit.unit_type == 'Dollars - Billions' ||
                      benefit.unit_type == 'Thousands' ||
                      benefit.unit_type == 'Millions' ||
                      benefit.unit_type == 'Billions'">$</i>

										<i class="letter" *ngIf="benefit.unit_type == 'Percent'">{{benefit.unit_type_abbr}}</i>

										<i class="letter" *ngIf="benefit.unit_type == 'Basis Points' ||
                      benefit.unit_type == 'Miles' ||
                      benefit.unit_type == 'Number'">1</i>
									</span>
								</code>

							<code data-type="impact_type" *ngIf="benefit.imapct_type" role="button">
									<span class="iconsSpan" matTooltipPosition="below" matTooltip="{{benefit.imapct_type}}">
										<i [ngClass]="benefit.imapct_type == 'Revenue' ? 'icon-bar-chart' :
                                    benefit.imapct_type == 'Costs' ? 'letter' :
                                    benefit.imapct_type == 'Productivity' ? ' icon-settings' :
                                    benefit.imapct_type == 'Risk' ? ' icon-shield' : ''">
											<span *ngIf="benefit.imapct_type == 'Costs'">$</span>
										</i>
									</span>
								</code>

							<code data-type="impact_type" *ngIf="benefit.accrual_type" role="button">
									<span class="iconsSpan" placement="bottom" ngbTooltip="{{benefit.accrual_type}}">
										<i [ngClass]="benefit.accrual_type == 'One Time' ? 'icon-clock' :
                                    'icon-refresh'">
										</i>
									</span>
								</code>

							<code data-type="impact_type" *ngIf="benefit.unique_type" role="button">
									<span class="iconsSpan" placement="bottom" ngbTooltip="{{benefit.unique_type}}">
										<i [ngClass]="benefit.unique_type == 'Unique' ? 'letter' :
                                    'icon-doc'">
											<span *ngIf="benefit.unique_type == 'Unique'">D</span>
										</i>
									</span>
								</code>
						</div>
						<div class="solution-types">
							<span>
								<button class="green_button" *ngIf="benefit.has_driver_scratchpad">
									<i class="fa fa-check"></i>{{ 'props.tabDetails.benefits.DriverScratchpad' | translate }}</button>
								<button class="orange_button" *ngIf="!benefit.has_driver_scratchpad">{{ 'props.tabDetails.benefits.DriverScratchpad' | translate }}</button>
							</span>

							<span>
								<button class="green_button" *ngIf="benefit.has_financial_scratchpad">
									<i class="fa fa-check"></i>{{ 'props.tabDetails.benefits.FinancialScratchpad' | translate }}</button>
								<button class="orange_button" *ngIf="!benefit.has_financial_scratchpad">{{ 'props.tabDetails.benefits.FinancialScratchpad' | translate }}</button>
							</span>

							<span>
								<button class="green_button" *ngIf="benefit.has_improvement_scratchpad">
									<i class="fa fa-check"></i>{{ 'props.tabDetails.benefits.ImprovementScratchpad' | translate }}</button>
								<button class="orange_button" *ngIf="!benefit.has_improvement_scratchpad">{{ 'props.tabDetails.benefits.ImprovementScratchpad' | translate }}</button>
							</span>

							<span>
								<button class="green_button" *ngIf="benefit.has_subtypes">
									<i class="fa fa-check"></i>{{ 'props.tabDetails.benefits.Subtypes' | translate }}</button>
								<button class="orange_button" *ngIf="!benefit.has_subtypes">{{ 'props.tabDetails.benefits.Subtypes' | translate }}</button>
							</span>
						</div>
						<div class="benefit" (click)="openBenefitDetail(benefit)">
							<p class="title">{{ 'props.tabDetails.benefits.Benefit' | translate }}:</p>
							<p class="editable" [ngbTooltip]="benefit.description" container="body">{{benefit.metric_name}}</p>
						</div>
						<div class="formula">
							<p>{{benefit.formula}}</p>
						</div>
						<div class="rating">
							<ng-template #t let-fill="fill">
								<span class="star" [class.full]="fill === 100">
									&#9733;
								</span>
							</ng-template>
							<ngb-rating [(rate)]="benefit.feedbackAvg" [starTemplate]="t" [readonly]="true" [max]="5">
							</ngb-rating>
							<div class="row">
								<div *ngIf="valueProp.vp_can_edit" class="col-md-6">
									<button type="button" class="btn btn-primary-outline" (click)="selectedBenefit=benefit;openBenefitRating(rateBenefitModal)">
										<i class="fa fa-star" aria-hidden="true"></i>
										{{ 'props.tabDetails.benefits.RateThisBenefit' | translate }}
									</button>
								</div>
								<div class="col-md-6">
									<div class="feedback" *ngIf="benefit.feedbackAvg">
										<button type="button" class="btn btn-primary-outline" (click)="showFeedback(showFeedbackModal, benefit.account_solution_metric_id)">
											{{ 'props.tabDetails.benefits.ShowFeedback' | translate }}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="(benefits == null || benefits.length==0) && !benefitsLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4> {{ 'props.tabDetails.benefits.NoBenefitsFound' | translate }}</h4>
				</div>
			</div>
		</div>
	</div>

	<ng-template #rateBenefitModal let-c="close" let-d="dismiss">
		<div class="modal-header">
			<h4 class="modal-title">{{ 'general.Rate' | translate}} {{selectedBenefit.metric_name}}</h4>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<form name="rateBenefitForm" #rateBenefitForm="ngForm" (ngSubmit)="saveBenefitRating()">
				<div class="form-group rateBenefit">
					<ng-template #t let-fill="fill">
						<span class="star" [class.full]="fill === 100">
							&#9733;
						</span>
					</ng-template>
					<ngb-rating [(rate)]="feedback.rating" [starTemplate]="t"  [max]="5">
					</ngb-rating>
				</div>
				<div class="form-group clearfix">
					<label for="comment">
						{{ 'general.Comment' | translate}}
					</label>
					<textarea class="form-control" name="comment" id="comment" [(ngModel)]="feedback.comment" required="required">
               </textarea>
				</div>
				<div class="form-group">
					<button type="submit" class="btn btn-primary" [disabled]="feedback.rating == 0 || rateBenefitForm.invalid">
						<i class="fa fa-floppy-o" aria-hidden="true"></i>
						{{ 'general.save' | translate}}
					</button>
				</div>
			</form>
		</div>
	</ng-template>
	<ng-template #showFeedbackModal let-c="close" let-d="dismiss" class="modal-dialog modal-sm">
		<div class="modal-header">
			<h4 class="modal-title">{{ 'general.Feedback' | translate}}</h4>
			<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="reviewList">
				<div class="review" *ngFor="let feedbk of feedbackList.feedback">
					<div class="row">
						<div class="col-md-2 col-xs-4 text-center">
							<p><img class="profileImage" [src]="feedbk.profile_pic_filepath != '' ? feedbk.profile_pic_filepath : null" />
							</p>
							<h5>{{feedbk.user_name}}</h5>
						</div>
						<div class="col-md-10 col-xs-8">

							<p class="dateContainer"><i class="icon-calendar"></i>&nbsp; {{feedbk.modified | date:"dd MMM yyyy"}}</p>
							<p>{{feedbk.comment}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>
<!-- </div> -->