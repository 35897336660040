import { NgModule } from '@angular/core';

import { RSituationComponent } from './r_situations.component';
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RSituationTranslations } from './r_situations.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		RSituationComponent,
	],
	imports: [
		SidebarModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		TabViewModule,
    MatTooltipModule,
		DLMenubarModule,
		InputSwitchModule,
		TableModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		RSituationTranslations
	],
	entryComponents: [
		RSituationComponent,
	],
	exports: [RSituationComponent]
})
export class RDetailSituationModule { }