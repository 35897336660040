import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { ValueRealizationDetailComponent } from './value-realization-detail/value-realization-detail.component';
import { ValueRealizationComponent } from './value-realization/value-realization.component';


const routes: Routes = [
	{
		path: 'valuerealization',
	    component: ValueRealizationComponent
	},
	{
		path: 'valuerealization/:id/survey/:surveyId',
	    component: CreateSurveyComponent
	},
	// {
	// 	path: 'valuerealization/:id/surveyTemplate',
	//     component: ValueRealizationDetailComponent
	// },
	// {
	// 	path: 'valuerealization/:id/surveyTemplate/:surveyId',
	//     component: ValueRealizationDetailComponent
	// },
	{
		path: 'valuerealization/:id/:type/:typeId',
	    component: ValueRealizationDetailComponent
	},
	{
		path: 'valuerealization/:id',
		component: ValueRealizationDetailComponent
	},
	// {
	// 	path: 'valuerealization/:id/:type',
	//     component: ValueRealizationDetailComponent
	// }
	
	
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ValueRealizationRoutingModule { }
