import { Component, OnInit, Input } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FactsService } from '@data/services/facts/facts.service';
import { AccountService } from '@data/services/account/account.service';
import { Owner } from '@shared/models/owner.model';


@Component({
	selector: 'app-release-to-partners',
	templateUrl: './release-to-partners.component.html',
	styleUrls: ['./release-to-partners.component.scss'],
	providers: [AccountService]
})
export class ReleaseToPartnersComponent implements OnInit {
	@Input() solutionId: string;

	releaseTypes: Array<{id: string; name: string}> = [];
	releaseTypeId = '';
	partnerTypeList: Array<Owner> = [];
	searchText: string;
	selectedPartnerList: Array<string> = [];

	constructor(
		public activeModal: NgbActiveModal,
		private factsService: FactsService,
		private accountService: AccountService,
		private notification: NotificationService,
		private solutionService: SolutionService
	) { }

	ngOnInit(): void {
		this.getReleaseTypes();
		this.getPartnerTypes();
	}

	getReleaseTypes(): void {
	  this.factsService.getReleaseTypes().subscribe((response) => {
			if (response.result) {
				this.releaseTypes = response.result.releaseTypes;
			}
		});
	}

	getPartnerTypes(): void {
		const accId = sessionStorage.getItem('aid')
		this.accountService.getPartnersFieldData(accId, 'accoutnPartners').subscribe(
			(result) => {
				if (result['result']['data'] != null) {
					this.partnerTypeList = result['result']['data'];
				}
			},
			error => this.notification.error(error['result'], false)
		);
	}

	selectPartnerTypes(releaseTypeId): void {
		this.releaseTypeId = releaseTypeId;
		if (releaseTypeId == '3' || releaseTypeId == '4') {
			this.resetPartners();
		}
	}

	resetPartners(): void {
		if (this.partnerTypeList) {
			this.partnerTypeList.forEach(item => {
				item['selectedPartner'] = false;
			});
		}
	}

	realeaseToPartners(): void {
		this.selectedPartnerList = [];
		if (this.partnerTypeList) {
			this.partnerTypeList.forEach(item => {
				if (item['selectedPartner']) {
					this.selectedPartnerList.push(item['PartnerTypeId']);
				}
			});
			const payload = {
				'account_solution_id': this.solutionId,
				'release_type_id': this.releaseTypeId,
				'partner_type_id': this.selectedPartnerList.join(',')
			}

			this.solutionService.realeaseToPartners(payload).subscribe((response) => {
				if (response.result) {
					this.notification.success('Partner Release Successfully', false);
					this.solutionService.readSolution.next('updateSolution');
					this.activeModal.close();
				}
			});
		}
	}

}
