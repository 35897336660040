<div (click)="backClicked()" class="createBack"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span> Back</div>
&nbsp;&nbsp;

<div class="librarySolutionsDashboard">
  <div class="row soln-search-row">
    <div class="col-md-12">
      <div class="panel panel-white blue-head">
        <div class="panel-heading clearfix">
          <h4 class="panel-title">Library Solutions</h4>
        </div>
        <div class="panel-body">
          <div class="soln_table_cont">
            <div class="table-responsive col-sm-12 soln-table">
              <div class="searchTableContainer">
                <div class="">
                  <table id="librarySolutions" class="table custom-table">
                    <thead>
                      <tr>
                        <th>Model Name</th>
                        <th>typical_customer_revenue</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody *ngIf="librarySolutions.length > 0 && !librarySolutionsLoader">
                      <tr *ngFor="let solution of librarySolutions" class="grid-row">
                        <td>
                          {{ solution.name }}
                        </td>
                        <td>{{ solution.typical_customer_revenue }}</td>
                        <td>
                          <div class="soln-table-dropdown dropdown pull-right" id="soln-table-dropdown">
                            <span class="glyphicon glyphicon-option-horizontal soln-dot-icon dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                            <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                              <li (click)="importLibraryToAccount(solution.id)"><i class="icon-login"></i> Import</li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div *ngIf="librarySolutionsLoader" class="col-12 text-center">
              <img class="actionLoader" [src]="fullImagePath" />
            </div>
            <div *ngIf="librarySolutions.length == 0 && !librarySolutionsLoader" class="m-t-md col-sm-12">
              <div class="searchTableContainer text-center">
                <h4>No Library Models Found</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
