import { Injectable } from '@angular/core';

@Injectable()
export class AESituationTranslations {
  public config: any = {
    component: 'addEditSituation',
    display_name: 'Situation Form',
    description: 'Form for creating and editing situations',
  };

  public trans: any = {
    back: 'Back',
    search: 'Search',
    situationType: 'Situation Type',
    selectSituation: 'Select a Situation',
    selectSituationElement: 'Situation Type Elements',
    selectSituationToProceed: 'Select a situation type from the dropdown above to proceed.',
    description: 'Description',
    select_your_option: 'Select Your Option',
    activeDefault: 'Active by default?',
    no: 'No',
    availableGuidedDiscovery: 'Available for Guided Discovery?',
    yes: 'Yes',
    save: 'Save',
    benefits: 'Benefits',
    factors: 'Factors',
    costs: 'Costs',
    caseStudies: 'Case Studies',
    children_of_parent_name: 'Children of (parent name)',
    assets: 'Assets',
    collateral: 'Collateral',
    project: 'Project',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    add_to_the_available_costs: 'Add to the available costs',
    autoload_in_valueprop: 'Autoload in ValueProp',
    cost_added_to_autoload: 'Cost Added to Autoload',
    cost_added_to_available_cost: 'Cost Added to available cost',
    cost_excluded_to_available_cost: 'Cost excluded from available cost',
    exclude_from_available_costs: ' Exclude from available costs',
    remove_from_valueprop: 'Revove from Valueprop',
    removed_from_autoload: 'Removed from Autoload',
    non_applicable_by_default: 'Non-Applicable By Default',
    values_changed: 'Values Changed',
    reset_cost: 'Reset Cost',
    reset_asset: 'Reset Asset',
    reset_benefit: 'Reset Benefit',
    turn_it_off: 'Turn it off',
    turn_it_on: 'Turn it on',
    metric_name: 'Name',
    name: 'Name',
    conservative: 'Conservative',
    probable: 'Probable',
    aggressive: 'Aggressive',
    currentEffort: 'Current Effort',
    current_effort: 'Current Effort',
    reset: 'Reset',
    choose_one: 'Choose One',
    change: 'Change',
    change_it: 'Change It',
    modelBaseline: 'Model Baseline',
    driver_value: 'Model Baseline',
    factor_baseline_answer: 'Situation Override',
    editFactorBaseline: 'Edit Factor Baseline',
    situationOverride: 'Situation Override ',
    reset_case_study: 'Reset Case Study',
    reset_collateral: 'Reset Collateral',
    reset_all_costs: 'Reset All Costs',
    no_assets_yet: 'No Assets Yet!',
    no_benefits_yet: 'No Benefits Yet!',
    no_case_studies_yet: 'No Case Studies Yet!',
    no_costs_yet: 'No Costs Yet!',
    no_projects_yet: 'No Projects Yet!',
    no_situation_yet: 'No Situation Yet!',
    no_collaterals_yet: 'No Collaterals Yet!',
    add_to_list: 'Add to list',
    included_by_default: 'Included by Default',
    recommended: 'Recommended',
    removed_from_list: 'Removed from List',
    how_does_this_accrue: 'How does this accrue?',
    scale_factor: 'Scale Factor',
    unit_price: 'Unit Price',
    add_to_list_and_recommend: 'Add to list and Recommend',
    off: 'Off',
    on: 'On',
    situation_created_success: 'Situation created successfully',
    situation_updated_success: 'Situation updated successfully',
    change_benefits_status_success: 'Benefit status changed successfully',
    change_benefits_status_fail: 'Benefit status could not be changed',
    something_went_wrong: 'Something went wrong',
    situation_deleted: 'Situation deleted successfully',
    element_created_add_new: 'You created a new element! You should add another.',
    cost_added_success: 'Cost added successfully',
    cost_excluded_success: 'Cost excluded successfully',
    cost_autoload_success: 'Cost added to autoload successfully',
    stack_order_updated_success: 'Stack order updated successfully',
    case_study_changed: 'Case Study has been changed',
    asset_status_changed: 'Asset status has been changed',
    collateral_status_changed: 'Collateral status has been changed',
    project_status_changed: 'Project status has been changed',
    benefit_reset_success: 'Benefit reset successfully',
    no_negative: 'Negative Values are not allowed',
    are_you_sure: 'Are you sure?',
    cancel: 'Cancel',
    year: 'Year',
    current_state: 'Current State',
  };
}
