import { Injectable } from '@angular/core';

@Injectable ()

export class TrackValueTabsTranslations {

    public config: any = {
        component: 'trackValueTabs',
        display_name: 'Track Value Tabs',
        description: 'The tabs found in the track value detail dashboard'
    };

    public trans: any = {};
}