import { Injectable } from '@angular/core';

@Injectable ()

export class RealizationSurveysTranslations {

    public config: any = {
        component: 'valueRealizedSurvey',
        display_name: 'Value Realizaion Survey',
        description: 'Survey page in value realized'
    };

    public trans: any = {
        name: 'Name',
        actions: 'Actions',
        delete: 'Delete',
        downloadAsset: 'Download Asset',
        back: 'Back',
        Update: 'Update',
        Cancel: 'Cancel',
        no_surveys_found: 'No Surveys Found',
        quarters_in: 'Quarters In',
        settings: 'Settings',
        survey_name: 'Survey name',
        survey_settings: 'Survey Settings',
        search: 'Search',
        rowLimit: 'Row Limit:',
        created: 'Survey Created Date',
        custom_survey_date_fmt: 'Survey Effective Date',
        are_you_sure: 'Are you sure?'
    };
}
