<div class='row'>
		<div class='col-xs-12'>
			<div class='well well-info'>
				<b>{{trans.trans.mission_statement.value}}</b><br>
			</div>
		</div>
	</div>
<div class='row'>
	<div class='col-xs-12'>
		<label for='mission'>{{trans.trans.mission_statement.value}}</label>
		<textarea class='form-control' [(ngModel)]="data.mission_statement" rows='4'></textarea>
	</div>
</div>

<div class='row'>
	<div class='col-xs-12'>
		<button (click)='btnUpdateMissionStatement()' class='btn btn-success'>{{trans.trans.save.value}}</button>
	</div>
</div>