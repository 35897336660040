import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { NotificationService } from '@services/notification.service';
import { ConnectedObjectWithoutResult, Goal, SimpleGoalReactiveForm, ValuePropAddGoal, ValueRealizationBaselineGoal, ValueRealizationGoalGroup } from '@shared/models/goal-group.model';
import { UserInfo } from '@shared/models/user-info.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { ReviewBaselineTranslation } from './review-baseline.translation';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { Items } from 'app/value-map-maintenance/views/factors/interfaces/items.interface';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { BenefitType } from '@shared/models/benefit-type.model';
import { ValueProp } from '@shared/models/value-prop.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';
import { FactorsService } from '@data/services/factors/factors.service';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { ErrorMessagesService } from '@services/error-messages.service';
import { GoalGroupsComponent } from 'app/value-map-maintenance/views/goal-groups/goal-groups.component';
import { ReviewBaselineGoalGroupsComponent } from '../review-baseline-goal-groups/review-baseline-goal-groups.component';

export enum BaselineOptions {
  goals = 1,
  benefits = 2,
  successCriteria = 3,
}

@Component({
  selector: 'app-review-baseline',
  templateUrl: './review-baseline.component.html',
  styleUrls: ['./review-baseline.component.scss'],
})
export class ReviewBaselineComponent implements OnInit, OnDestroy {
  _valuePropIdValue: string;
  @Input()
  set valuePropId(val: string) {
    this._valuePropIdValue = val;
    if (val) {
      //this.getFactors();
    }
  }
  get valuePropId() {
    return this._valuePropIdValue;
  }

  @ViewChild('goalGroups') goalGroups: ReviewBaselineGoalGroupsComponent;

  @Output() cancelCallback = new EventEmitter();
  @Output() finishCallback = new EventEmitter();
  @Output() backCallback = new EventEmitter();
  BaselineOptions = BaselineOptions;
  ngUnsubscribe = new Subject();
  showTranslate = false;
  selectionOptions: { label: string; value: BaselineOptions; inactive: boolean }[];
  selectedOption: BaselineOptions = BaselineOptions.goals;
  userData: UserInfo;
  numberLocales = ['en-US', 'en-US', 'de-DE', 'fr-FR'];
  unitTypes: ExpenseType[];
  goalForm: FormGroup;
  loading = false;

  loadingSave = false;
  availableBenefits: { label: string; value: string; linked: boolean }[];
  linkedBenefitsId: string[] = [];
  goalIdsList: string[] = [];
  isSidebarOpen = false;
  accountId: string;
  scaledBy: Items = [];
  toggleSeqSort: number = 0;
  group_selected: string = 'value_category';
  benefitsLoader: boolean = false;
  benefitsList: any[] = [];
  benefitsListByType: BenefitType[];
  benefits: any = {};
  createFlow: boolean = false;
  valueProp: ValueProp;
  sidebarMode: 'add' | 'edit' = 'add';
  toggleScratchpadView: boolean;

  constructor(
    private commonService: CommonService,
    public trans: ReviewBaselineTranslation,
    private translationService: TranslationsV2Service,
    private accountService: AccountService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private userService: UserService,
    private goalGroupsService: GoalGroupsService,
    public valuePropService: ValuepropService,
    private router: Router,
    private route: ActivatedRoute,
    private factorService: FactorsService,
    private errorMessagesService: ErrorMessagesService
  ) {}

  ngOnInit(): void {
    this.accountId = sessionStorage.getItem('aid');

    this.factorService.getUnitTypes().subscribe((res) => {
      this.unitTypes = res.result;
    });
    this.getAllBenefits();

    if ( this.valuePropId ) {
      this.getValuePropDetails();
    }

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.userService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userInfo) => {
      this.userData = userInfo;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.selectionOptions = [
          { label: this.trans.trans.goals.value, value: BaselineOptions.goals, inactive: false },
          { label: this.trans.trans.benefits.value, value: BaselineOptions.benefits, inactive: true },
          { label: this.trans.trans.successCriteria.value, value: BaselineOptions.successCriteria, inactive: true },
        ];
      });
  }

  private getAllBenefits(): void {
    this.accountService.getAccountBenefits().subscribe((res) => {
      this.availableBenefits = res.result.map((benefit) => {
        return {
          label: benefit.metric_name,
          value: String(benefit.id),
          linked: this.linkedBenefitsId.includes(String(benefit.id)),
        };
      });
    });
  }

  cancel() {
    this.cancelCallback.emit();
  }

  nextStep() {
    if (this.selectedOption === BaselineOptions.goals) {
      this.getValuePropDetails();
      this.saveGoals();

    } else if (this.selectedOption === BaselineOptions.successCriteria) {
      this.finishCallback.emit();
      return;

    }

    this.selectionOptions[this.selectedOption].inactive = false;
    ++this.selectedOption;
    this.selectionOptions = [...this.selectionOptions];
  }

  previousStep() {
    if (this.selectedOption === BaselineOptions.goals) {
      this.callbackBack();
    } else {
      --this.selectedOption;
    }
  }

  jumpFinish(): void {
    if (this.selectedOption === BaselineOptions.goals) {
      this.saveGoals();
    }
    this.finishCallback.emit();
  }

  callbackBack() {
    this.backCallback.emit();
  }

  getValuePropDetails() {
    this.valuePropService
      .getValuePropDetail(this.valuePropId.toString())
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.valueProp = response.result.value_prop;
          if (this.valueProp.vp_can_edit === false) {
            this.router.navigate(['..'], { relativeTo: this.route });
          }
        }
      });
  }

  removeNegatives(value: number): number {
    return value < 0 ? 0 : Math.min(value, 9);
  }

  closeSidebars(): void {
    this.toggleScratchpadView = false;
    this.isSidebarOpen = false;
  }

  saveGoals(goals?: ValueRealizationBaselineGoal[], mode?: string): void {
    
    if ( goals === undefined ) {
      // Nothing passed, get goals from the object
      goals = this.goalGroups?.buildGoalsArray();
    }

    const payload: ValuePropAddGoal[] = [];
    goals.forEach((goal) => {
      const goalPayload: ValuePropAddGoal = {
        account_factor_id: goal.account_factor_id,
        source_type_id: 1,
        value: goal.value.toString(),
        baseline_value: goal.baseline_value.toString(),
        active: goal.selected ? '1' : '0',
      };
      payload.push(goalPayload);
    });
    this.loadingSave = true;

    this.goalGroupsService
      .addValuePropGoals(this.valuePropId.toString(), payload)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loadingSave = false))
      )
      .subscribe(
        () => {
          if (mode === 'edit') {
            this.notificationService.success(this.trans.trans.goalUpdated.value, false);
          } else {
            this.notificationService.success(this.trans.trans.goalAdded.value, false);
          }
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }
}
