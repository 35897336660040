import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueCategory } from '@data/services/valueposition/models/value-category.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelsV2RValueCategoryTranslations } from './r_value_category.translation';

@Component({
  selector: 'app-r-value-category',
  templateUrl: './r_value_category.component.html',
})
export class RValueCategoryComponent implements OnInit, OnDestroy {
  @Input() solutionId: number;
  @Input() tabInit: number;
  @Input() can_edit = false;
  @Input() mode = 'add';

  sidebar_title = 'Add Benefit Group';
  ngUnsubscribe$ = new Subject();

  @Input() selectedElem: ValueCategory = {
    name: '',
    description: '',
    id: '',
  };

  account_id: string;
  showTranslate = false;

  constructor(
    private commonService: CommonService,
    public trans: ModelsV2RValueCategoryTranslations,
    private translationService: TranslationsV2Service
  ) { }

  ngOnInit() {
    this.account_id = sessionStorage.getItem('aid');
    this.sidebar_title = 'Add Value Category';
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
