import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'dashboard',
    display_name: 'Dashboard',
    description: 'Main dashboard for whole application',
  };

  public trans: any = {
    dash_board: 'Dashboard',
    new: 'NEW!',
    new_look_title: 'Has A Brand New Look!',
    new_look_description: 'We’ve had some work done ... our new platform offers our valued users a better experience. Want to learn more?',
    sneak_peak: 'Sneak Peak New Look',
    take_back: 'Take me back',
  };
}
