import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { NotificationService } from '../../../_services/notification.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ScbService {
  private service_url: string;
  private headers = new HttpHeaders({
    'User-Id': sessionStorage.getItem('uid'),
    'token': sessionStorage.getItem('vcu'),
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
  });
  private options = { headers: this.headers };
  constructor(private http: HttpClient, private commonService: CommonService, private notificationService: NotificationService) {
    this.service_url = this.commonService.getServiceUrl();
  }

  public registerUser(data) {
    const endpoint = this.service_url + 'account/partnertype/create';

    return this.http.post(endpoint, data, this.options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        if (+err.status === 403) {
          const isLogout = true;
          this.notificationService.error(err.json().result, isLogout); // Params {message, islogout}
        }
        return observableThrowError(err.message || 'Server error');
      })
    );
  }
  public getRoles() {
    const endpoint = this.service_url + 'fact/roleTypes';

    return this.http.get(endpoint, this.options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        if (+err.status === 403) {
          const isLogout = true;
          this.notificationService.error(err.json().result, isLogout); // Params {message, islogout}
        }
        return observableThrowError(err.message || { result: 'Server error' });
      })
    );
  }
  public register(data) {
    const endpoint = this.service_url + 'scb/users/create';

    return this.http.post(endpoint, data, this.options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        const error = err.json();
        return observableThrowError(error);
      })
    );
  }

  public buyCredits(postData) {
    const endpoint = this.service_url + 'users/credits/buy';

    const result = null;
    return this.http.post(endpoint, postData, this.options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        if (+err.status === 403) {
          const isLogout = true;
          this.notificationService.error(err.json().result, isLogout); // Params {message, islogout}
        }
        return observableThrowError(err.message || { result: 'Server error' });
      })
    );
  }

  public createSubcription(postData) {
    const endpoint = this.service_url + 'users/credits/buy';

    return this.http.post(endpoint, postData, this.options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        if (+err.status === 403) {
          const isLogout = true;
          this.notificationService.error(err.json().result, isLogout); // Params {message, islogout}
        }
        return observableThrowError(err.message || 'Server error');
      })
    );
  }

  public scbDownloadList() {
    const userid = sessionStorage.getItem('uid');
    const endpoint = this.service_url + 'scb/downloads/' + userid;

    return this.http.get(endpoint, this.options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        if (+err.status === 403) {
          const isLogout = true;
          this.notificationService.error(err.json().result, isLogout); // Params {message, islogout}
        }
        return observableThrowError(err.message || 'Server error');
      })
    );
  }
}
