import { Location } from '@angular/common';
import { HostListener, Injectable, Input, Directive, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { CustomerShareServices } from '@data/services/customer_share/share.service';
import { FactsService } from '@data/services/facts/facts.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { VGPService } from '@data/services/value-prop-group/vgp.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AlertService } from '@services/alert.service';
import { NotificationService } from '@services/notification.service';
import { ValuePropStatus } from '@shared/models/account.models';
import { ValueProp } from '@shared/models/value-prop.model';
import { ApiServiceService } from 'app/api-service.service';
import { StyleService } from 'app/style.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RepSimpleTranslations } from './simple.translation';

export type ViewType = 'full_view' | 'account' | 'benefits' | 'keyassumptions' | 'costs' | 'results' | 'share' | 'simple';

@Directive()
@Injectable()
export class DashboardSimpleImplementation implements OnDestroy {
  @Input() childCombined: { [klass: string]: any };
  @Input() childBenefits: { [klass: string]: any };
  @Input() childAssumptions: { [klass: string]: any };
  @Input() view: ViewType = 'simple';
  @Input() lowRes: boolean;
  @Input() valueProp: ValueProp;
  @Input() valuePropId: string;
  @Input() permissions: { [klass: string]: any };
  @Input() showTranslate = false;
  @Input() panelH: string;

  showLogin = true;
  valuePropStatusEditable = false;

  userId: string;
  token: string;
  contextualHelp: string[] = [];
  menuTop: any[] = [];
  viewTop: any = 'account';

  valuePropLoader = false;
  AssetsMenu = false;

  waitingButton = false;

  h: number;

  step = 0;
  headerColor = 'blue';
  menuAssets: any[] = [];
  assetGenerator = '';
  locked = false;
  valuePropStatusTypes: ValuePropStatus[] = [];
  valuePropStatusComment: string;
  done = false;
  image_url: string;
  fullImagePath: string;
  assetUrl: string;
  hasKeyAssumptions = false;
  discoveryQuestions = false;

  showClone = false;
  clonedName = '';
  cloneLoader: boolean;
  firstLoad = true;

  clone_option = 0;
  clone_userid: string;
  opportunity_id: string;
  owner_of_clone = false;
  clone_company_id: string;
  clone_company_name = '';

  resultsCanEdit = true;

  promoteToRealization = false;
  feature58 = false;
  isValueReaiztionUser = false;

  sc_vpg = false;
  first_run = true;
  showFooter = false;
  direct = false;
  feature52 = false;
  showBack = false;
  showBackLink = '';
  scenario_done = false;
  situation_done = false;
  scalers_done = false;
  q2_done = false;
  activeIndex = 0;
  feature67 = false;
  vpg = false;
  toggleAddVPG = false;
  selectedVPG: any;
  vpgList: any[] = [];
  uid: string = null;
  loadingVPG = false;

  sharedLoginSuccess$: Subject<any> = new Subject();
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private commonService: CommonService,
    public RS: RepSimpleViewService,
    private userService: UserService,
    private valuepropService: ValuepropService,
    public trans: RepSimpleTranslations,
    private translationService: TranslationsV2Service,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private location: Location,
    private customerShare: CustomerShareServices,
    private apiServiceService: ApiServiceService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private VPGService: VGPService,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    this.assetUrl = this.commonService.getAssetUrl();
  }

  OnInit() {
    this.initTranslations();

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    const feature31 = this.commonService.checkFeature('31');
    const feature34 = this.commonService.checkFeature('34');
    this.feature52 = this.commonService.checkFeature('52');
    const feature56 = this.commonService.checkFeature('56');
    this.feature58 = this.commonService.checkFeature('58');
    this.feature67 = this.commonService.checkFeature('67');

    this.showFooter = false;
    if (this.feature67 && feature34) {
      this.sc_vpg = true;
      this.showFooter = false;
    }

    if (!feature31 && !feature56 && feature34) {
      this.showFooter = true;
    }

    const products = JSON.parse(sessionStorage.getItem('products'));
    let hasVR;
    if (products && products.length) {
      hasVR = products.find((prod) => prod.product_id == 4);
    }

    this.isValueReaiztionUser = !!hasVR;

    if (this.valuePropId) {
      this.getValuePropDetails();
      this.getValuePropStatusTypes();
      this.funcBuild();
    } else {
      if (!this.direct) {
        this.step = 0;
      }
      this.funcBuild();
    }

    this.userId = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');

    this.headerColor = localStorage.getItem('headerColor');

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'customercolors';

    let entry = '.btn-next { background-color: ' + this.headerColor + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    entry = '.btn-back { color: ' + this.headerColor + ' !important; border-color: ' + this.headerColor + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    entry = '.badge_current { color: ' + this.headerColor + ' !important; border-color: ' + this.headerColor + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    entry = '.arrow-steps .step.current { color: ' + this.headerColor + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    entry = '.badge_done { background-color: ' + this.headerColor + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    entry = 'body .p-slider .p-slider-range { background-color: ' + this.headerColor + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    entry = '.toolbar_icons { color: ' + this.headerColor + ' !important;  }';
    style.appendChild(document.createTextNode(entry));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    this.h = window.innerHeight;

    this.RS.refreshSimpleDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getValuePropDetails();
      this.funcGetAssets();
    });

    this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.firstLoad = false;

      this.getValuePropDetails();
      this.getValuePropStatusTypes();

      this.funcGetAssets();
    });

    this.valuepropService.refreshBenefitDetail.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getValuePropDetails();
      this.getValuePropStatusTypes();
      this.funcGetAssets();
    });


    this.uid = sessionStorage.getItem('uid');
    const priv7 = this.commonService.checkPrivilege('7');
    if (feature56 && priv7) {
      this.vpg = true;
      this.getVPGData();
    }
  }

  initTranslations(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.funcBuild();
      });
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event) {
      this.h = window.innerHeight;
      this.panelH = this.h - 45 + 'px';
    }
  }

  toggleShowTranslate() {}

  getVPGData() {
    this.VPGService.getVPGGroupListArray(this.uid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.vpgList = res.result.value_prop_groups;
      });
  }

  funcSaveVPG(form: NgForm) {
    this.loadingVPG = true;
    const arr = [];
    arr.push(this.valueProp.id);

    const payload = {
      members: arr,
    };
    this.VPGService.addSingleVPtoVPG(this.selectedVPG.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toggleAddVPG = false;
        this.loadingVPG = false;
        this.notificationService.success('Business Case added to Value Group Successfully', false);
      });
  }

  funcCallback(res) {
    if (!res) {
      this.waitingButton = false;
      return false;
    }
    this.waitingButton = false;

    switch (res.res) {
      case 'gotoStep':
        this.gotoStepCallback(res.id);
        break;
      case 'back':
        this.backCallback();
        break;
      case 'next':
        this.nextCallback();
        break;
    }

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.refreshTranslation();
    });
  }

  refreshTranslation() {}

  ngOnDestroy() {
    sessionStorage.removeItem('repsimple_results_view');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  funcSendStepToServer() {
    const payload = {
      step: this.step,
    };
    this.RS.updateVPStep(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(() => {
        if (this.step == 4) {
          const payload2 = { done: 1 };
          this.RS.putDone(this.valuePropId, payload2)
            .pipe(take(1))
            .subscribe(() => {
              this.done = true;
            });
        }
      });
  }

  funcCloneCompany(event) {
    this.clone_company_id = event.id;
    this.clone_company_name = event.name;
  }

  cloneValueProp() {
    this.notificationService.info('Cloning in progress...', false);
    this.cloneLoader = true;

    if (!this.clonedName) {
      this.notificationService.error('Value Prop Name is required', false);
      this.cloneLoader = false;
      return false;
    }

    if (this.clone_option == 1) {
      if (!this.clone_company_id) {
        this.notificationService.error('Please select a company', false);
        this.cloneLoader = false;
        return false;
      }
      if (!this.opportunity_id) {
        this.notificationService.error('Please enter in an Opportunity ID', false);
        this.cloneLoader = false;
        return false;
      }
    }

    if (this.clone_option == 2) {
      if (!this.opportunity_id) {
        this.notificationService.error('Please enter in an Opportunity ID', false);
        this.cloneLoader = false;
        return false;
      }
    }

    const payload = {
      name: this.clonedName,
      user_id: !this.owner_of_clone ? this.userId : null,
      opportunity_id: this.opportunity_id ? this.opportunity_id : null,
      company_id: this.clone_company_id ? this.clone_company_id : null,
    };

    this.valuepropService
      .cloneValueProp(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.cloneLoader = false;
          this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() => this.router.navigate(['rep/', response.result.value_prop.id]));
        } else if (response.result.success === false) {
          this.cloneLoader = false;
          this.notificationService.error(response.result.message, false);
        }
        this.showClone = !this.showClone;
      });
  }

  funcGotoStepDirect(id) {
    switch (id) {
      case '0':
        this.viewTop = 'account';
        break;
      case '1':
        this.viewTop = 'benefits';
        break;
      case '2':
        this.viewTop = 'keyassumptions';
        break;
      case '3':
        this.viewTop = 'costs';
        break;
      case '4':
        this.viewTop = 'results';
        break;
      default:
        this.viewTop = 'results';
        break;
    }
    this.step = id;
  }

  funcBuild() {
    this.assetUrl = this.commonService.getAssetUrl();

    if (!this.contextualHelp.length) {
      this.userService
        .getContextualHelpList()
        .pipe(take(1))
        .subscribe((result) => {
          if (result.result) {
            this.commonService.contextualHelp.next(result.result);
          }
        });
    }

    this.commonService.contextualHelp.pipe(take(1)).subscribe((response) => {
      this.contextualHelp = response['Valueprop'];
    });

    this.menuTop = [];
    if (this.permissions.account_info_view) {
      this.menuTop.push({
        label: this.view == 'simple' ? this.trans.trans.defineScenario.value : this.trans.trans.aboutYou.value,
        viewTop: 'account',
        done: this.step > 0 ? true : false,
        command: () => {
          this.viewTop = 'account';
          this.step = 0;
          this.funcSwitchStep();
        },
        funcBack: () => {
          if (this.permissions.account_info_editable && this.view == 'simple') {
            this.childCombined.funcCombinedModelsScalers('callback').then((res) => {
              if (res) {
                this.menuTop[this.step].done = true;
                this.nextCallback();
              } else {
                this.waitingButton = false;
              }
            });
          } else {
            this.menuTop[this.step].done = true;
            this.nextCallback();
          }
        },
        funcNext: (step?: number) => {
          if (this.permissions.account_info_editable && this.view == 'simple') {
            this.childCombined.funcCombinedModelsScalers('callback').then((res) => {
              if (res) {
                this.menuTop[this.step].done = true;
                this.nextCallback();
              } else {
                this.waitingButton = false;
              }
            });
          } else {
            this.menuTop[this.step].done = true;
            this.nextCallback();
          }
        },
      });
    }
    if (this.permissions.benefits_view) {
      this.menuTop.push({
        label: this.view == 'simple' ? this.trans.trans.reviewBenefits.value : this.trans.trans.prioritizeBenefits.value,
        viewTop: 'benefits',
        done: this.step > 1 ? true : false,
        command: () => {
          this.viewTop = 'benefits';
          this.step = 1;
          this.funcSwitchStep();
        },
        funcBack: () => {
          this.backCallback();
        },
        funcNext: (step?: number) => {
          this.menuTop[this.step].done = true;
          this.nextCallback();
        },
      });
    }

    let show_assumptions = false;

    if (this.view == 'simple') {
      show_assumptions = true;
    } else {
      if (this.permissions.assumptions_view && !!this.valueProp) {
        if (this.valueProp.has_key_assumptions.toString() == '1') {
          show_assumptions = true;
        } else {
          show_assumptions = false;
        }
      } else {
        show_assumptions = false;
      }
    }

    if (show_assumptions) {
      this.menuTop.push({
        label: this.trans.trans.provideNumbers.value,
        viewTop: 'keyassumptions',
        done: this.step > 2 ? true : false,
        command: () => {
          this.viewTop = 'keyassumptions';
          this.step = 2;
          this.funcSwitchStep();
        },
        funcBack: () => {
          this.backCallback();
        },
        funcNext: (step?: number) => {
          if (this.permissions.assumptions_editable) {
            this.waitingButton = true;
            this.childAssumptions.updateAll('callback').then(() => {
              this.waitingButton = false;
              this.menuTop[this.step].done = true;
              if (typeof step !== 'undefined') {
                this.gotoStep(step);
              } else {
                this.nextCallback();
              }
            });
          } else {
            this.menuTop[this.step].done = true;
            this.nextCallback();
          }
        },
      });
    }

    if (this.permissions.costs_view) {
      this.menuTop.push({
        label: this.view == 'simple' ? this.trans.trans.addCosts.value : this.trans.trans.reviewCostsSimple.value,
        viewTop: 'costs',
        done: this.step > 3 ? true : false,
        command: () => {
          this.viewTop = 'costs';
          this.step = 3;
          this.funcSwitchStep();
        },
        funcBack: () => {
          this.backCallback();
        },
        funcNext: (step?: number) => {
          this.menuTop[this.step].done = true;
          this.nextCallback();
        },
      });
    }
    if (this.permissions.result_view) {
      this.menuTop.push({
        label: this.trans.trans.reviewResults.value,
        viewTop: 'results',
        done: this.step > 4 ? true : false,
        command: () => {
          this.viewTop = 'results';
          this.step = 4;
          this.funcSwitchStep();
        },
        funcBack: () => {
          this.backCallback();
        },
        funcNext: (step?: number) => {
          this.menuTop[this.step].done = true;
          this.nextCallback();
        },
      });
    }
  }

  gotoStep(id) {
    if (this.view == 'simple') {
      if (!this.done) {
        this.waitingButton = false;
        return false;
      }
    }
    this.step = id;
    this.showFooter = true;
    this.funcSwitchStep();
  }
  gotoStepCallback(id) {
    this.step = id;
    this.funcSendStepToServer();

    this.menuTop[this.step].done = false;
    this.funcSwitchStep();
  }

  funcToggleSupport() {
    this.userService.toggleSupportSidebar.next('');
  }

  back() {
    if (this.step < 0) {
      this.step = 0;
    }
    this.waitingButton = true;
    if (!this.menuTop[this.step]) {
      this.step = 0;
    }
    this.menuTop[this.step].funcBack();
  }

  backCallback() {
    this.step = this.step - 1;
    this.funcSendStepToServer();
    this.waitingButton = false;
    this.funcSwitchStep();
  }

  next(step?: number) {
    if (this.step > this.menuTop.length) {
      this.step = this.menuTop.length - 1;
    }

    this.menuTop[this.step].funcNext(step);

    this.showFooter = true;
  }

  nextCallback() {
    this.waitingButton = false;
    this.menuTop[this.step].done = true;
    this.step = this.step + 1;
    this.funcSendStepToServer();

    this.funcSwitchStep();
  }

  funcGotoVPID(id) {
    this.showFooter = true;
    this.step = 0;
    this.viewTop = 'account';
    this.valueProp.id = id.valuePropId;
    this.valuePropId = id.valuePropId;
    this.location.go('/rep/' + id.valuePropId);
    this.getValuePropDetails();
    this.funcGetAssets();
  }

  resetMenuColors() {
    for (let i = 0; i < this.menuTop.length; i++) {}
  }

  funcSwitchStep() {
    if (this.step > this.menuTop.length) {
      this.step = this.menuTop.length - 1;
    }
    if (this.step < 0) {
      this.step = 0;
    }
    this.viewTop = this.menuTop[this.step] && this.menuTop[this.step].viewTop ? this.menuTop[this.step].viewTop : 'account';
  }

  getValuePropDetails() {
    if (!this.valuePropId) {
      return false;
    }

    let id = this.valuePropId ? this.valuePropId : this.valueProp.id;
    if (id === undefined) {
      this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => (id = params['vpid']));
    }
    this.valuePropId = id;

    this.valuePropLoader = true;
    this.valuepropService
      .getValuePropDetail(id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.valueProp = response.result.value_prop;
          if (this.view !== 'share') {
            this.locked = +this.valueProp.closed === 1 ? true : false;
          }

          this.resultsCanEdit = this.valueProp.vp_can_edit;

          this.funcGetAssets();
          if (response.result.value_prop.has_key_assumptions == 1) {
            this.hasKeyAssumptions = true;
            this.valuepropService.rebuildMenu.next();
          }

          this.valuepropService
            .getDiscoveryQuestions(this.valueProp.id)
            .pipe(take(1))
            .subscribe((response) => {
              if (response.result.length) {
                this.discoveryQuestions = true;
              }
            });

          this.scenario_done = +this.valueProp.scenario_done === 1 ? true : false;
          this.situation_done = +this.valueProp.situation_done === 1 ? true : false;
          this.scalers_done = +this.valueProp.scalers_done === 1 ? true : false;
          this.q2_done = +this.valueProp.q2_done === 1 ? true : false;

          this.sharedLoginSuccess$.next();
          this.sharedLoginSuccess$.complete();

          if (+this.valueProp.done === 0) {
            this.step = +this.valueProp.step;
            if (this.step > this.menuTop.length) {
              this.step = this.menuTop.length - 1;
            }
            if (this.step < 0) {
              this.step = 0;
            }
            this.funcBuild();

            this.funcSwitchStep();
          } else {
            this.funcBuild();
            this.done = true;
            if (this.viewTop !== 'full_view') {
              this.funcSwitchStep();
            }
          }
          this.valuePropLoader = false;
        }
      });
  }

  funcGetAssets() {
    this.valuepropService
      .readValuePropAssetTemplates(this.valueProp.id)
      .pipe(take(1))
      .subscribe((result) => {
        if (result.result) {
          this.menuAssets = [];
          const assets = result.result;

          for (let i = 0; i < assets.length; i++) {
            this.menuAssets.push({
              label: assets[i].name,
              icon: this.getFAIcon(assets[i].format),
              url: this.getAssetURL(assets[i]),
              target: '_blank',
              description: assets[i].description,
              format: assets[i].format,
            });
          }
        }
      });
  }

  getAssetURL(asset) {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    this.userId = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');
    return (
      this.commonService.getAssetUrl() +
      'assets/' +
      this.assetGenerator +
      '?account_asset_template_id=' +
      asset.account_asset_template_id +
      '&user_id=' +
      this.userId +
      '&token=' +
      this.token +
      '&value_prop_id=' +
      this.valuePropId
    );
  }

  getFAIcon(format) {
    switch (format) {
      case 'MS Word':
        return 'fa fa-file-word-o';

      case 'MS Excel':
        return 'fa fa-file-excel-o';

      case 'MS PowerPoint':
        return 'fa fa-file-powerpoint-o';

      case 'MS PDF':
        return 'fa fa-file-pdf-o';

      default:
        return 'fa fa-cloud-download';
    }
  }

  getFAColor(format) {
    switch (format) {
      case 'MS Word':
        return { 'color': '#2e5b97', 'margin-right': '5px' };

      case 'MS Excel':
        return { 'color': '#3a6e46', 'margin-right': '5px' };

      case 'MS PowerPoint':
        return { 'color': '#c55737', 'margin-right': '5px' };

      case 'MS PDF':
        return { 'color': '#c60a09', 'margin-right': '5px' };

      default:
        return { 'color': 'black', 'margin-right': '5px' };
    }
  }

  lockValueProp(type) {
    let canLock = true;
    if (this.view === 'share') {
      canLock = this.permissions.result_editable;
    }
    if (this.valueProp.vp_can_edit && canLock) {
      if (type == 'lock') {
        const payload = {
          closed: '1',
        };
        this.valuepropService
          .lockValueProp(this.valueProp.id, payload)
          .pipe(take(1))
          .subscribe((response) => {
            if (response.result) {
              this.locked = true;
              this.valueProp.closed = 1;

              this.valuepropService.refreshDashboard.next('refreshDashboard');
              this.notificationService.success(this.trans.trans.vpLockedSucces.value, false);
            }
          });
      }
      if (type == 'unlock') {
        const payload = {
          closed: '0',
        };
        this.valuepropService
          .lockValueProp(this.valueProp.id, payload)
          .pipe(take(1))
          .subscribe((response) => {
            if (response.result) {
              this.locked = false;
              this.valueProp.closed = 0;
              this.valuepropService.refreshDashboard.next('refreshDashboard');
              this.notificationService.success(this.trans.trans.vpUnlockedSucces.value, false);
            }
          });
      }
    }
  }

  getValuePropStatusTypes() {
    this.factsService
      .getValuePropStatusTypes()
      .pipe(take(1))
      .subscribe((response) => {
        this.valuePropStatusTypes = [];
        for (let s = 0; s < response.result.length; s++) {
          const elem = response.result[s];
          this.valuePropStatusTypes.push({ label: elem.name, command: () => this.updateValuePropStatus(elem.id) });
        }
      });
  }

  updateValuePropStatus(statusTypeid) {
    if (!statusTypeid) {
      return;
    }

    const statusTypeObj = { value_prop_status_type_id: statusTypeid };

    this.valuepropService
      .updateValuePropStatus(this.valueProp.id, statusTypeObj)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success(this.trans.trans.statusUpdateSuccess.value, false);
          this.getValuePropStatusTypes();
          this.getValuePropDetails();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  funcNavDiscoveryQuestions() {
    this.RS.navDiscoveryQuestions.next('');
  }

  funcProcessOneZero(input) {
    if (input == '1') {
      return true;
    } else {
      return false;
    }
  }
  loading = false;
  hash_id = '';
  password = '';
  login_data: any;
  creator_name: string;
  creator_email: string;
  creator_phone: string;

  funcLogin() {
    if (!this.password) {
      return false;
    }
    this.loading = true;
    const payload = {
      hash: this.hash_id,
      password: this.password,
    };
    this.customerShare
      .ShareLogin(payload)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.result.success == false) {
          this.notificationService.error(res.result.message, false);
          return;
        }

        if (res.result.value_prop_id) {
          this.showLogin = false;
          if (this.view == 'share') {
            this.permissions = {
              account_info_editable: this.funcProcessOneZero(res.result.account_info_editable),
              account_info_view: this.funcProcessOneZero(res.result.account_info_view),
              assumptions_editable: this.funcProcessOneZero(res.result.assumptions_editable),
              assumptions_view: this.funcProcessOneZero(res.result.assumptions_view),
              benefits_editable: this.funcProcessOneZero(res.result.benefits_editable),
              benefits_view: this.funcProcessOneZero(res.result.benefits_view),
              costs_view: this.funcProcessOneZero(res.result.costs_view),
              costs_editable: this.funcProcessOneZero(res.result.costs_editable),
              re_share: this.funcProcessOneZero(res.result.re_share),
              result_view: this.funcProcessOneZero(res.result.result_view),
              result_editable: this.funcProcessOneZero(res.result.result_editable),
              expires: res.result.expires,
            };
            this.resultsCanEdit = this.permissions.result_editable;
          }

          this.login_data = res;
          this.valuePropId = this.login_data.result.value_prop_id;

          if (!res.result.hasOwnProperty('errors')) {
            sessionStorage.setItem('hash_id', this.hash_id);

            sessionStorage.setItem('uid', this.login_data.result.user_id);
            sessionStorage.setItem('vcu', this.login_data.result.token);
            sessionStorage.setItem('aid', this.login_data.result.account_id);
            sessionStorage.setItem('account_name', this.login_data.result.account_name);
            sessionStorage.setItem('features', this.login_data.result.features);
            this.styleService.updateStyle2022();
            sessionStorage.setItem('privileges', this.login_data.result.privileges);
            sessionStorage.setItem('trophies', this.login_data.result.trophies);
            sessionStorage.setItem('rid', this.login_data.result.role_type_id);

            localStorage.setItem('creator_name', this.login_data.result.creator_name);
            localStorage.setItem('creator_email', this.login_data.result.creator_email);
            localStorage.setItem('creator_phone', this.login_data.result.creator_phone);
            this.creator_name = this.login_data.result.creator_name;
            this.creator_email = this.login_data.result.creator_email;
            this.creator_phone = this.login_data.result.creator_phone;

            localStorage.setItem('headerColor', this.login_data.result.headerColor);
            sessionStorage.setItem('headerColor', this.login_data.result.headerColor);
            this.apiServiceService.changeHeaderColor.next(this.login_data.result.headerColor);
            const out = [];

            for (let c = 0; c < this.login_data.result.chart_colors.length; c++) {
              const elem = this.login_data.result.chart_colors[c];
              out.push(elem.color);
            }

            localStorage.setItem('chart_colors', JSON.stringify(out));
            sessionStorage.setItem('chart_colors', JSON.stringify(out));
            this.headerColor = this.login_data.result.header_color;

            sessionStorage.setItem('new_asset_gen', this.login_data.result.new_asset_gen);
            sessionStorage.setItem('asset_gen_file', this.login_data.result.asset_gen_file);
            sessionStorage.setItem('language_type_id', this.login_data.result.language_type_id);
            this.commonService.notifyChangeLanguage.next(this.login_data.result.language_type_id);

            sessionStorage.setItem('logo', this.login_data.result.logo_filepath);
            this.apiServiceService.companyLogo.next(this.login_data.result.logo_filepath);
            sessionStorage.setItem('username', this.login_data.result.user_name);
            sessionStorage.setItem('email', this.login_data.result.email);

            if (this.login_data.result.fact_labels) {
              const out = [];
              const label_list = this.login_data.result.fact_labels;

              for (let i = 0; i < label_list.length; i++) {
                out.push({ k: label_list[i].name, v: label_list[i].description, language_type_id: label_list[i].language_type_id });
              }

              sessionStorage.setItem('all_dictionaries', JSON.stringify(out));
            }

            if (this.login_data.result.labels) {
              const out = [];
              const label_list = this.login_data.result.labels;
              const labels = label_list.filter((x) => (x.language_type_id = this.login_data.result.language_type_id));

              for (let i = 0; i < labels.length; i++) {
                out.push({ k: labels[i].key, v: labels[i].value, language_type_id: labels[i].language_type_id });
              }

              sessionStorage.setItem('labels', JSON.stringify(out));
            }

            this.apiServiceService.changeLoggedInVal(true);

            this.alertService.notifyLoggedIn(this.login_data.result);
          }
          this.getValuePropDetails();
        } else {
          this.loading = false;
          this.alertService.error(this.trans.trans.logginError.value, true);
          return;
        }
      });
  }
}
