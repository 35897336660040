<h3 class="breadcrumb-header">Valueprop Feed</h3>

<div [hidden]="feedsLoader">
    <p class="text-center"><img class="loader" src="{{fullImagePath}}" /></p>
</div>

<div class="cssanimations">
    <div class="row">
        <div class="col-md-12">
            <section id="cd-timeline" class="cd-container">
                <div *ngFor="let feed of feeds; let last = last" class="cd-timeline-block">
                    <div class="cd-timeline-img cd-success">
                        <i class="fa fa-pencil-square-o"></i>
                    </div> <!-- cd-timeline-img -->
                    <div class="cd-timeline-content">
                        <h4>{{feed.section}}</h4>
                        <p>{{feed.log_text}}</p>
                        <span class="cd-date">{{feed.modified}}</span>
                    </div> <!-- cd-timeline-content -->
                    <span *ngIf="last">{{loadAnimation()}}</span>
                </div> <!-- cd-timeline-block -->
            </section> <!-- cd-timeline -->
        </div>
    </div><!-- Row -->
 </div>

 