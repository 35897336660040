import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';

@Component({
  selector: 'app-scb',
  templateUrl: './scb.component.html'
})
export class ScbComponent {
  page = 'scb';
  constructor(
    private translateService: TranslateService,
    public trans: Translations) {
      this.translateService.setDefaultLang('defaultLang');
      this.trans.funcLoadOverrides(this.trans);
  }
}
