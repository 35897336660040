import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { APIResult } from '@shared/models/api-result.model';
import { Capability, CapabilityPayload } from '@shared/models/capabilities.models';
import { Observable } from 'rxjs';

@Injectable()
export class CapabilitiesService {
	serviceUrl: string;

	constructor(private commonService: CommonService) {
		this.serviceUrl = this.commonService.getServiceUrl();
	}

	public postCapability(accountId: string, payload: CapabilityPayload): Observable<APIResult<number>> {
		let endpoint = "account/capability/" + accountId;
		return this.commonService.postAPIService(endpoint, payload);
	}

	public getCapabilities(accountId: string): Observable<APIResult<Capability[]>> {
		let endpoint = "account/capabilities/" + accountId;
		return this.commonService.getAPIService(endpoint);
	}

	public putCapability(accountId: string, payload: CapabilityPayload): Observable<APIResult<string>> {
		let endpoint = "account/capability/" + accountId;
		return this.commonService.putAPIService(endpoint, payload);
	}

	public deleteCapability(accountId: string, accountCapabilityId: string): Observable<APIResult<string>> {
		let endpoint = "account/capability/" + accountId + "/delete/" + accountCapabilityId;
		return this.commonService.deleteAPIService(endpoint);
	}
}