import { Pipe, PipeTransform } from '@angular/core';
import { CompanyService } from '@data/services/company/company.service';
import { Company } from '@shared/models/company.model';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators'; 


@Pipe({
    name: 'companyUrlPipe'
  })
  export class CompanyLogoPipe implements PipeTransform {
  
    constructor(private companyService: CompanyService) {

    }
    transform(company: Company ): Observable<any> {
        if (!company || !company.name) {
            return of('');
          } else if (company?.logo_path) {
            return of(company.logo_path);
          } else {
            const value = company?.name?.toLowerCase();
            const path = `${value.replace(/ /g, '')}.com/`;
            return this.companyService.checkCompanyLogo(`https://logo.clearbit.com/${path}`).pipe(
                map((res) => !res ? null : `https://logo.clearbit.com/${path}`)
            );
          }
      return ;
    }
  
  }