import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.scss'],
})
export class CreateSurveyComponent implements OnInit, OnDestroy {
  @Input() surveyId: number;
  @Input() activeSurveyTemplate: any;
  @Input() account_question_template_id: number;
  @Output() close = new EventEmitter();
  surveyDetails: any;
  SurveyLoader: boolean = false;
  actionLoader: boolean = false;

  mode: string = 'create';
  fullImagePath: string;
  image_url: string;

  benefitPerformanceType: any;
  benefitPerformanceTypeSel: any;

  subscriptionreadValueRealizationSurvey: Subscription;
  subscriptiongetSurveyQuestion: Subscription;
  subscriptioncreateSurveyResponse: Subscription;
  subscriptiongetPerformanceTypes: Subscription;

  constructor(
    private factsService: FactsService,
    private valuerealizationService: ValuerealizationService,
    private notification: NotificationService,
    private activeRoute: ActivatedRoute,
    private CommonService: CommonService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.image_url = this.CommonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.getSurveyDetail();
    this.getPerformanceTypes();
    this.benefitPerformanceTypeSel = '1';
  }

  ngOnDestroy() {
    if (this.subscriptionreadValueRealizationSurvey) {
      this.subscriptionreadValueRealizationSurvey.unsubscribe();
    }
    if (this.subscriptiongetSurveyQuestion) {
      this.subscriptiongetSurveyQuestion.unsubscribe();
    }
    if (this.subscriptioncreateSurveyResponse) {
      this.subscriptioncreateSurveyResponse.unsubscribe();
    }
    if (this.subscriptiongetPerformanceTypes) {
      this.subscriptiongetPerformanceTypes.unsubscribe();
    }
  }

  getSurveyDetail() {
    this.SurveyLoader = false;
    this.subscriptionreadValueRealizationSurvey = this.valuerealizationService.readValueRealizationSurvey(this.surveyId).subscribe((response) => {
      if (response.result) {
        this.surveyDetails = response.result;
        this.mode = 'edit';
        if (this.surveyDetails['value_survey_metrics'] && this.surveyDetails['value_survey_metrics'][0]['value_performance_type_id']) {
          this.benefitPerformanceTypeSel = this.surveyDetails['value_survey_metrics'][0]['value_performance_type_id'];
        }

        if (this.surveyDetails['value_survey_response']) {
          this.getCurrentSurveyTemplate();
        } else {
          this.SurveyLoader = true;
        }
      }
    });
  }

  getCurrentSurveyTemplate() {
    let accountId = sessionStorage.getItem('aid');
    this.subscriptiongetSurveyQuestion = this.valuerealizationService.getSurveyQuestion(accountId, this.account_question_template_id).subscribe((response) => {
      if (response.result) {
        let answerIndex = this.surveyDetails['value_survey_response'].map((item) => {
          return item['account_survey_question_id'];
        });

        let QuestionWithanswers = response.result;

        for (let i = 0; i < QuestionWithanswers.length; i++) {
          let index = answerIndex.indexOf(QuestionWithanswers[i]['id']);
          if (index != -1) {
            QuestionWithanswers[i]['answer'] = this.surveyDetails['value_survey_response'][index]['survey_response'];
          }
          if (QuestionWithanswers[i]['question_type_id'] == '2') {
            let options = QuestionWithanswers[i]['question_options'];
            if (QuestionWithanswers[i]['answer']) {
              QuestionWithanswers[i]['answer'] = QuestionWithanswers[i]['answer'].split(',');
              let answerOption = QuestionWithanswers[i]['answer'];
              for (let j = 0; j < options.length; j++) {
                if (answerOption.indexOf(options[j]['id']) != -1) {
                  QuestionWithanswers[i]['question_options'][j]['checked'] = true;
                }
              }
            }
          }
        }

        this.activeSurveyTemplate['questions_list'] = QuestionWithanswers;
      }

      this.SurveyLoader = true;
    });
  }

  saveSurvey() {
    this.actionLoader = true;

    let question = this.activeSurveyTemplate['questions_list'];
    let value_survey_response = [];

    if (question) {
      for (let i = 0; i < question.length; i++) {
        if (question[i]['answer'] || question[i]['question_options']) {
          if (question[i]['question_options']) {
            let options = question[i]['question_options'];
            question[i]['answer'] = [];
            for (let j = 0; j < options.length; j++) {
              if (options[j].checked) {
                question[i]['answer'].push(options[j]['id']);
              }
            }
            question[i]['answer'] = question[i]['answer'].join(',');
          }

          value_survey_response.push({
            account_survey_question_id: question[i]['id'],
            survey_response: question[i]['answer'],
          });
        }
      }
    }

    let metrics = this.surveyDetails['value_survey_metrics'];
    let value_survey_metrics = [];
    if (metrics) {
      for (let i = 0; i < metrics.length; i++) {
        value_survey_metrics.push({
          id: metrics[i]['id'],
          value_survey_improvement: metrics[i]['value_survey_improvement'],
          value_performance_type_id: this.benefitPerformanceTypeSel,
        });
      }
    }

    let value_prop_id;
    this.activeRoute.params.subscribe((params) => (value_prop_id = params['id']));

    let payload = {
      value_prop_id: value_prop_id,
      value_survey_id: this.surveyId,
      value_survey_response: value_survey_response,
      value_survey_metrics: value_survey_metrics,
    };

    this.subscriptioncreateSurveyResponse = this.valuerealizationService.createSurveyResponse(payload).subscribe((response) => {
      if (response.result) {
        this.notification.success('Survey saved successfully', false);
        this.close.emit();
      }
      this.actionLoader = false;
    });
  }

  getPerformanceTypes() {
    this.subscriptiongetPerformanceTypes = this.factsService.getPerformanceTypes().subscribe((response) => {
      if (response.result && response.result['fact_performance_type']) {
        this.benefitPerformanceType = response.result['fact_performance_type'];

        this.benefitPerformanceTypeSel = '1';
      }
    });
  }
}
