import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class ModelGoalsTranslation extends TranslationsBaseService {
  public config: any = {
    component: 'model-goals',
    display_name: 'Model Goals',
    description: 'All the goals in a solution',
  };

  public trans = this.typeObjectKeys({
    goals: 'Goals',
    nameCol: 'Goal Name',
    valueCol: 'Value',
    addGoal: 'Add Goal',
    editGoal: 'Edit',
    deleteGoal: 'Delete',
    defaultValueUpdated: 'Goal values updated',
    deleteMessage: 'Goal deleted successfully',
    saveError: 'There was a problem, please try again',
    areYouSure: 'Are you sure?',
    emptyTable: 'No data found',
    addSuccess: 'Goal added successfully',
    editSuccess: 'Goal edited successfully',
    linkedBenefit: 'Linked Benefit',
    linkedFactor: 'Linked Factor(s)',
    formula: 'Formula',
    currency: 'Currency',
    currencyThousands: 'Currency Thousands',
    currencyMillions: 'Currency Millions',
    currencyBillions: 'Currency Billions',
    thousands: 'Thousands',
    millions: 'Millions',
    billions: 'Billions',
    seconds: 'Seconds',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    months: 'Months',
    years: 'Years',
    percent: 'Percent',
    basisPoints: 'Basis Points',
    miles: 'Miles',
    number: 'Number',
    weeks: 'Weeks',
    units: 'Units',
    alreadyLinked: 'Factor already linked to a Goal',
    selectFactor: 'Select Factor',
    includeByDefault: 'Include by in Value Prop by default',
    addGoalToExisting: 'Add Goals to Existing Value Props',
    goalWarning: 'This will automatically associate goals with any value proposition that uses this model.  Are You Sure?',
    goalsUpdated: 'Value props updated successfully',
    yes: 'Yes',
    no: 'No',
    confirm: 'Confirm',
  });
}
