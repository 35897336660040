import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { CaseStudy, CaseStudyMediaObject, CaseStudySection } from '@shared/models/case-study.model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SectionsTranslations } from './sections.translation';
import { NotificationService } from '@services/notification.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { VideoEmbedService } from '@services/video-embed.service';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SectionsComponent implements OnInit, OnDestroy {
  @Input() caseStudy: CaseStudy;
  @Input() type: string;
  @Input() canEdit: boolean;
  @Input() isModal = true;
  @Output() valueChange = new EventEmitter();

  ngUnsubscribe = new Subject();
  validationRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(?:https?:\/\/)?(?:www\.)?dailymotion.com\/(video|hub)+(\/([^_]+))?[^#]*(‪#‎video‬=([^_&]+))?$|^(?:https?:\/\/)?(?:www\.)?vimeo.com\/([0-9]+)$/;
  validVideo = false;
  caseStudyLoader: boolean;
  files: Blob[] = [];
  sectionTypes: { [klass: string]: any }[] = [];
  sectionType: { [klass: string]: any } = {};
  addSectionLoader = false;
  addSectionTypeLoader = false;
  isCollapsed = true;

  showTranslate = false;
  overlayImgVideo: CaseStudyMediaObject = {
    type: undefined,
    path: undefined,
    description: undefined,
  };
  embeddedVideoURL: string;

  editOverlayImgVideo: CaseStudyMediaObject = {
    type: undefined,
    path: undefined,
  };
  editEmbeddedVideoIFrame: string;

  mediaFormObj = {
    mediaType: undefined,
    media: undefined,
    url: undefined,
    mediaDescription: undefined,
    account_case_study_section_id: undefined,
    id: undefined,
  };

  toggleAddEditSection = false;
  sidebar_title = 'Add Section';
  mode = 'add';

  sectionEdit: CaseStudySection = {
    id: undefined,
    account_case_study_id: undefined,
    section_type_id: undefined,
    name: undefined,
    description: undefined,
    sectionType: undefined,
  };

  constructor(
    private commonService: CommonService,
    private solutionService: SolutionService,
    private http: HttpClient,
    private embedService: VideoEmbedService,
    private notification: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: SectionsTranslations
  ) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  closeAddEditSection(): void {
    this.toggleAddEditSection = false;
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  setOverlayImageVideo(isEditList, sectionMedia, overlay: OverlayPanel, event): void {
    this.validVideo = this.validateEmbedVideoUrl(sectionMedia.path);

    if (isEditList) {
      this.editOverlayImgVideo = sectionMedia;
    }
    this.overlayImgVideo = sectionMedia;
    if (sectionMedia.type === 'link') {
      this.embeddedVideoURL = this.embedService.embed(sectionMedia.path);
    }
    overlay.toggle(event);
  }

  openAddEditSection(content: CaseStudySection): void {
    this.sidebar_title = 'Add Section';
    this.mode = 'add';

    if (content) {
      this.mode = 'edit';
      this.sectionEdit = content;
    }
    this.toggleAddEditSection = !this.toggleAddEditSection;
  }

  resetSection(): void {
    this.sectionEdit = {
      id: undefined,
      account_case_study_id: undefined,
      section_type_id: undefined,
      name: undefined,
      description: undefined,
      sectionType: undefined,
    };
  }

  downAsset(imageUrl) {
    this.http
      .get(imageUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return {
            filename: imageUrl.substr(imageUrl.lastIndexOf('/') + 1),
            data: res,
          };
        })
      )
      .subscribe(
        (res) => {
          const url = window.URL.createObjectURL((res as any).data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        () => {},
        () => {}
      );
  }

  deleteSection(section_id: string): void {
    this.solutionService
      .deleteCaeStudySection(section_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notification.success(response.result, false);
          this.valueChange.emit();
        }
      });
  }

  openViewSection(section: CaseStudySection): void {
    this.toggleAddEditSection = true;
    this.sectionEdit = section;
  }

  validateEmbedVideoUrl(url: string): boolean {
    return !!url.match(this.validationRegex);
  }
}
