import { Injectable } from '@angular/core';

@Injectable()

export class PromoteRealizationTranslations {

  public config: any = {
    component: 'promoteRealization',
    display_name: 'Promote to Realization',
    description: 'Promote value prop to realization'
  };

  public trans: any = {
    promoteRealization: 'Promote to Realization',
    save: 'Save',
    selectCustomerSuccess: 'Who is the customer success user you want to assign this to?',
    promote: 'Promote',
    name: 'Name',
    selectDate: 'Select a Date',
    contractDate: 'Baseline Date',
    are_you_sure: 'Are you sure?',
    back: 'Back',
    success: 'Succesfully promoted to realization',
    error: 'Could not be promoted to realization',
  };
}
