<div *ngIf="text" class="benefit-detail-card clickable">
  <div *ngIf="isCollapsible" (click)="show = !show" style="padding: 20px; padding-bottom: 10px">
    <i class="fa fa-chevron-right" *ngIf="!show"></i>
    <i class="fa fa-chevron-down" *ngIf="show"></i>&nbsp;
    <b>{{ title }}</b>
  </div>
  <div *ngIf="show" style="padding: 20px; padding-top: 10px">
    <span [innerHtml]="text"></span>
  </div>
</div>
