<!---  valuemaps -->
<div class="valuemaps">
  <div active="active" class="ng-isolate-scope">
    <ul class="nav nav-tabs clearfix">
      <li class="uib-tab nav-item ng-isolate-scope active" index="0" heading="Your Solution Overview">
        <a href="" class="nav-link ng-binding">{{ trans.valuemaps.your_solution_overview | translate }}</a>
      </li>
    </ul>
    <div class="tab-content clearfix">
      <div class="tab-pane active clearfix">
        <div class="pull-left dash-container-nav ng-scope">
          <div
            role="button"
            tabindex="0"
            class="panel panel-default"
            [ngClass]="{ 'panel-info': isChartActive }"
            (click)="isChartActive = true; isCategoryActive = false; isListActive = false"
            routerLink="/valuemaps/chart"
          >
            <div class="panel-heading">
              <h3 class="panel-title">{{ trans.valuemaps.maps_by_category | translate }}</h3>
            </div>
            <div class="panel-body"></div>
          </div>
          <div
            role="button"
            tabindex="0"
            class="panel panel-default"
            (click)="isChartActive = false; isCategoryActive = true; isListActive = false"
            [ngClass]="{ 'panel-info': isCategoryActive }"
            routerLink="/valuemaps/solutionCategory"
          >
            <div class="panel-heading">
              <h3 class="panel-title">{{ trans.valuemaps.solution_categories | translate }}</h3>
            </div>
            <div class="panel-body"></div>
          </div>
          <div
            role="button"
            tabindex="0"
            class="panel panel-default"
            (click)="isChartActive = false; isCategoryActive = false; isListActive = true"
            [ngClass]="{ 'panel-info': isListActive }"
            routerLink="/valuemaps/solutionList"
          >
            <div class="panel-heading">
              <h3 class="panel-title">{{ trans.valuemaps.solutions | translate }}</h3>
            </div>
            <div class="panel-body"></div>
          </div>
        </div>
        <div class="pull-left dash-container-content ng-scope">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<!---  valuemaps -->
