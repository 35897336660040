import { Injectable } from '@angular/core';

@Injectable ()

export class ProblemVpDashTranslations {

    public config: any = {
        component: 'problemVpDash',
        display_name: 'Value Prop Problem',
        description: 'Problem component in value prop dashboard'
    };

    public trans: any = {
        whatProblemDescribe: 'What is the customers problem? Describe in detail',
        numCharachterLeft: 'of 1000 characters left',
        save: 'Save'
    };
}
