<div class="row" style="margin: 1rem 3.5rem 0 1rem;">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs *ngIf="breadcrumbKeyValues" [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="workflow-wrapper">
  <div class="row" *ngIf="columns">
    <app-workflow-table 
    [trans]="trans" 
    [columns]="columns" 
    [workflowList]="configurableWorkflows" 
    (onStatusChange)="onStatusChange($event)"
    (onDelete)="onDelete($event)"
    (onClone)="onClone($event)"
    (onEdit)="onEdit($event)"
    (onAdd)="onAdd()"
    ></app-workflow-table>
  </div>
</div>

<app-create-workflow 
  *ngIf="isOpen" 
  [workflowTitle]="workflowTitle"
  (sidebarClose)="onHide($event)" 
  (onSaveEmitter)="getWorkflows()"
  [sidebarOpen]="isOpen" 
  [workflowSelected]="workflowSelected" 
  [trans]="trans"
  ></app-create-workflow>