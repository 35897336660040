import { Injectable } from '@angular/core';
import { FeatureGroup } from 'app/account/features/features.interface';
import { Subject } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable()
export class FeaturesService {
  private updateStatus$ = new Subject<FeatureGroup[]>();

  constructor(
		private commonService: CommonService
	) { }

  set setUpdateStatus(featureGroups: FeatureGroup[]) {
    this.updateStatus$.next(featureGroups);
  }

  get updateStatus() {
    return this.updateStatus$.asObservable();
  }

	public getFeaturesList() {
		let endpoint = "account/features";
		return this.commonService.getAPIService(endpoint);
	}

	public getFeatureGroups() {
		let endpoint = "account/featureGroups";
		return this.commonService.getAPIService(endpoint);
	}

	public postFeature(feature_type_id) {
		let payload = {};
		let endpoint = "account/feature/" + feature_type_id;
		return this.commonService.postAPIService(endpoint, payload);
	}

	public deleteFeature(feature_type_id) {
		let endpoint = "account/feature/" + feature_type_id;
		return this.commonService.deleteAPIService(endpoint);
	}
}