import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { CaseStudiesVpDashTranslations } from './case-studies.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';
import { CaseStudy } from '@shared/models/case-study.model';

@Component({
  selector: 'app-case-studies-prop',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss'],
})
export class CaseStudiesComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() valueProp: ValueProp;
  @Input() showTranslate = false;

  ngUnsubscribe: Subject<any> = new Subject();

  caseStudies: CaseStudy[] = [];
  caseStudiesLoader = false;
  fullImagePath: string;
  showCaseStudiesList = false;
  caseStudyId: number;
  caseStudy: CaseStudy;
  subscriptionreadCaseStudies: Subscription;
  locked = false;
  disableCaseStudiesLink: boolean;
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private translationService: TranslationsV2Service,
    public trans: CaseStudiesVpDashTranslations,
    private styleService: StyleService
  ) {
    this.fullImagePath = this.commonService.getImageUrl() + '/images/jamaica/reload.gif';
  }

  @Input() valuePropId: string | number;
  @Input() tabInit: number;

  ngOnInit() {
    this.locked = +this.valueProp.closed === 1;
    this.style2022$ = this.styleService.style2022;
    this.disableCaseStudiesLink = this.commonService.checkFeature(123);

    this.readCaseStudies();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  refreshTranslation() {
    this.getTranslations();
  }

  readCaseStudies() {
    this.caseStudiesLoader = true;
    this.valuepropService
      .readCaseStudies(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.caseStudies = [];
        if (result.result) {
          this.caseStudies = result.result;
        }
        this.caseStudiesLoader = false;
      });
  }

  navigateCaseStudy(caseStudy) {
    this.showCaseStudiesList = true;
    this.caseStudyId = caseStudy['id'];
    this.caseStudy = caseStudy;
  }
}
