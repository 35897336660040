import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';

import { SectionsComponent } from './sections.component';
import { CaseStudySectionsTranslations } from './sections.translation';

@NgModule({
  declarations: [SectionsComponent],
  imports: [NgbModule, InputMaskModule, FormsModule, CommonModule, MatTooltipModule, TabViewModule, HttpClientModule],
  providers: [TranslationsV2Service, CaseStudySectionsTranslations],
  entryComponents: [SectionsComponent],
  exports: [SectionsComponent],
})
export class ModelSectionsModule {}
