import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RCostTranslations } from './r_cost.translation';
import { ModelCost } from '@data/services/solution/models/model-cost';

@Component({
	selector: 'app-r-costs',
	templateUrl: './r_cost.component.html'
})
export class RCostComponent implements OnInit, OnDestroy {
	@Input() solutionID: number;
	@Input() can_edit: boolean = false;

	ngUnsubscribe = new Subject();

	loadSolutionCosts: boolean = false;
	costs: any = [];
	costCategoryTypes: any = [];
	factCostCategoryTypes: any = [];
	expenseTypes: any[];
	accrualTypes: any[];
	scaleTypes: any[];
	costsModel: object;
	modalReference: any;
	image_url: string;
	fullImagePath: string;
	accountSolutionId: number;
	showTranslate = false;

	situationsTied: any[] = [];
	account_id: any;

	// Contextual Help
	contextualHelp: object = {};
	costId: any;
	hideActionLoader: boolean = true;
	costType: any = "Add";
	dropdownSettings: any = {};
	newCostCategory: any;
	newCategoryLoader: boolean = false;
	drivers: any = [];

	@ViewChild('formAddEditCost') formAddEditCost: NgForm;

	@ViewChild('addNewCostCategoryForm') addNewCostCategoryForm: NgForm;

	@Input() costFormObj: Partial<ModelCost> = {
		id: undefined,
		account_solution_id: undefined,
		cost_category_type_id: undefined,
		expense_type_id: undefined,
		driver_factor_id: undefined,
		name: undefined,
		description: undefined,
		accrual_type_id: undefined,
		cost: undefined,
		situation_id: undefined,
		investment_situation_id: undefined,
		cost_baseline_answer: undefined,
		scale_type_id: undefined,
		default_include: undefined,
		created: undefined,
		modified: undefined,
		dep_cost_id: undefined,
		scale_type: undefined,
		scale_type_name: undefined,
		straight_scaling: undefined,
		company_field: undefined,
		ratio: undefined,
		company_field_name: undefined,
		situation: undefined,
		costInValueprop: undefined,
		scales_how: undefined,
		scales_how_name: undefined,
		formula: undefined

	};

	strSearch = '';

	// new
	addEditCost: any;
	loadingSave: boolean = false;
	toggleAddCost: boolean = false;
	dropdown: any[] = [];
	sidebar_title: string = "Add New Cost";
	openAddCostCategory: boolean = false;
	cols = [
		{ field: 'name', header: 'Line Item' },
		{ field: 'cost', header: 'Unit Price' }
	]

	constructor(
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: RCostTranslations
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.sidebar_title = "View Cost";
		if (this.costFormObj.situation) {
			this.situationsTied = this.costFormObj.situation;
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}


	}
