import { Directive, ElementRef, AfterViewInit, Renderer2, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appHeightCache]',
})
export class HeightCacheDirective implements AfterViewInit, OnDestroy {
  @Input() trigger: Observable<boolean>;
  ngUnsubscribe = new Subject<boolean>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.trigger) {
      this.trigger.pipe(takeUntil(this.ngUnsubscribe)).subscribe((check) => {
        if (check) {
          this.setMinHeight();
        } else {
          this.removeMinHeight();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private setMinHeight() {
    const cachedHeight: number = this.elementRef.nativeElement.offsetHeight;
    this.renderer.setStyle(this.elementRef.nativeElement, 'min-height', `${cachedHeight + 100}px`);
  }

  private removeMinHeight() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'min-height', `auto`);
  }
}
