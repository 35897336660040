<div (click)="backClicked()" class="createBack" *ngIf="!crm">
	<span class="fa fa-chevron-left" aria-hidden="true"></span> {{trans.trans.back.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.back" (callback)="getTranslations()"></app-editTranslation>
</div>
<form class="add-value-position">
	<div class='models_panel' style='margin-bottom: 20px;' *ngIf="isOppurtunityEnabled || feature68">
		<div class='models_page_header_container_small' *ngIf="feature68">
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>{{trans.trans.opportunity_id.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.opportunity_id" (callback)="getTranslations()"></app-editTranslation></div>
					</div>
				</div>
			</div>

			<div class="row" style='margin-bottom: 20px;' *ngIf="feature68">
				<div class="col-xs-6">
					<div class='p-inputgroup' [ngClass]="{'has-error': !opportunityId}">
						<p-inputMask mask="99999999" *ngIf="isVMWare" id="opportunityId" placeholder="{{trans.trans.opportunity_id.value}}" name="opportunityId" [(ngModel)]="opportunityId" [disabled]="!!valuePropId"></p-inputMask>
						<input type="text" *ngIf="!isVMWare" class="form-control" id="opportunityId" placeholder="Opportunity ID" name="opportunityId" [(ngModel)]="opportunityId" [disabled]="!!valuePropId" />
						<button class='btn btn_customizable' (click)='vmWareNext()'>{{trans.trans.next.value}}</button>
					</div>
					<small *ngIf="isVMWare" style='font-size: 90%; font-style: italic;'>{{trans.trans.ch_text.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ch_text" (callback)="getTranslations()"></app-editTranslation></small>
				</div>
			</div>
		</div>
	</div>

	<app-search-company *ngIf="!VMWareprojectInit" (afterCompanyDetail)="afterCompanyDetail($event)" [companyDetails]="companyDetails">
	</app-search-company>

	<div *ngIf="isVMWare && q1">
		<div class='models_panel' style='margin-bottom: 20px;'>
			<div class='models_page_header_container_small'>
				<div class='keywest_header_small'>
					<div class='row'>
						<div class='col-xs-12'>
							<div class='title'>
								{{trans.trans.question.value}}
							</div>
						</div>
					</div>
				</div>
				<div class='row'>
					<div class='col-xs-12'>
						<textarea name='q1answer' maxlength="1000" [(ngModel)]="q1answer" class='form-control' rows='3'></textarea>
						<small style='font-size: 70%;'>{{1000 - q1answer.length}} of 1000 characters left <span style='color: red;'>{{trans.trans.required.value}}</span></small><br><br>
						<a (click)='funcSaveQ1()' class='btn btn_customizable'>{{trans.trans.next.value}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="q1Finished && (valuePropId || masterValuePropId)">
		<div class='models_panel' style='margin-bottom: 20px;' *ngIf="projectFeature">
			<div class='models_page_header_container_small'>
				<div class='keywest_header'>
					<div class='row'>
						<div class='col-xs-10'>
							<div class='title'>{{trans.trans.how_would_you_like_to_determine_the_solutions_for_this_value_prop.value}}</div>
						</div>
					</div>
				</div>
				<div class='row'>
					<div class='col-xs-12'>
						<a (click)='toggleProject(1)' class='link clickable'>{{trans.trans.project_based.value}}</a> &nbsp;
						<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle" [ngbTooltip]="contextualHelpVP['Position 48']" placement="bottom" container="body"></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="contextualHelpVP['Position 48']" placement="bottom" container="body">
							info
						</span>
						 <br><br>
						<a (click)='toggleProject(2)' class='link clickable'>{{trans.trans.product_based.value}}</a> &nbsp;
						<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle" [ngbTooltip]="contextualHelpVP['Position 49']" placement="bottom" container="body"></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="contextualHelpVP['Position 49']" placement="bottom" container="body">
							info
						</span>
						<br><br>
						<a (click)='toggleProject(3, openDiscoveryModal)' class='link clickable'>{{trans.trans.discover_opportunities.value}}</a> &nbsp;
						<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle" [ngbTooltip]="contextualHelpVP['Position 50']" placement="bottom" container="body"></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="contextualHelpVP['Position 50']" placement="bottom" container="body">
							info
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class='models_panel' style='margin-bottom: 20px;' *ngIf="projectToggle">
			<div class='models_page_header_container_small'>
				<div class='keywest_header'>
					<div class='row'>
						<div class='col-xs-10'>
							<div class='title'>{{trans.trans.select_your_project_below.value}}</div>
						</div>
					</div>
				</div>
				<div class='row'>
					<div class='col-xs-12'>
						<form name="projectForm" id="projectForm" class="custom-select" #projectForm="ngForm">
							<div class="form-group" *ngFor="let project of availableProjects">
								<input type="checkbox" [name]="project.name" [value]="project.id" [id]="project.name" (change)="project.isChecked = !project.isChecked" [checked]="project.isChecked" (click)="checkProjects(project.id,$event)">
								<label [for]="project.name" class="project-display project-label" style='margin-left: 10px;'> {{project.name}}</label>
							</div>
							<button type="submit" class="btn btn_customizable" (click)="saveProjects()">{{trans.trans.apply.value}}</button>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class='models_panel' style='margin-bottom: 20px;' *ngIf="productToggle">
			<div class='models_page_header_container_small'>
				<div class='keywest_header'>
					<div class='row'>
						<div class='col-xs-10'>
							<div class='title' *ngIf="!cameFromProjects">{{trans.trans.pick_your_models.value}}</div>
							<div class='title' *ngIf="cameFromProjects">{{trans.trans.based_on_your_project_selection_we_suggest_the_following_products.value}}</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<label class="categoryType">{{trans.trans.choose_category_type.value}}</label>
						<select *ngIf="solutionCategoryList.length>0" type="text" (change)="getSolution(categoryType)" class="form-control" name="categoryType" [(ngModel)]="categoryType" style='max-width: 300px;'>
							<option *ngFor="let solutionCategory of solutionCategoryList" [value]="solutionCategory.id">
								{{solutionCategory.category}} </option>
						</select>
						<div class="text-center">
							<img class="actionLoader" *ngIf="showCategoryTypeLoader" [src]="fullImagePath" />
						</div>
					</div>
				</div>

				<div *ngFor="let item of availableSolutions">
					<div class="checkbox custom-checkbox" *ngIf="item.selectedCategory">
						<label>
							<span *ngIf="item.quantity_enabled == '1'">
								<span (click)="quantityEnabled($event);item.showeditable = !item.showeditable; item.tempQuantity = item.quantity" *ngIf="!item.showeditable">
									<span *ngIf="item.quantity" class="quantityCheck">{{item.quantity}}</span>
								</span>
								&nbsp;of</span> {{item.name}}
							<span *ngIf="item.showeditable" class="editableBox">
								<input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity">
								<button (click)="quantityAssign(item.id,item.quantity);item.showeditable = !item.showeditable;" class="btn btn_customizable">
									<i class="fa fa-check"></i>
								</button>
								<button (click)="item.quantity = item.tempQuantity;
										item.showeditable = !item.showeditable" class="btn btn-default">
									<i class="fa fa-times"></i>
								</button>
							</span>

							<input type="checkbox" name="item.isChecked" (change)="item.isChecked = !item.isChecked" [checked]="item.isChecked" (click)="checkModels(item.id,item.quantity,$event)" />

							<span class="check"></span>
						</label>
					</div>
				</div>
				<div *ngIf="selectedSolutionsLength == 0 && !showModelsLoader">
					<p class="well">
						{{trans.trans.no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_abov.value}}
					</p>
				</div>
				<div class="text-center m-t-xxl">
					<img class="actionLoader" *ngIf="showModelsLoader" [src]="fullImagePath" />
				</div>

				<div class='row'>
					<div class='col-xs-12'>
						<button type="button" class="btn btn_customizable" *ngIf="selectedModels.length > 0" style="margin-top: 0.5%" (click)="createSolution()">
							{{trans.trans.next.value}}
						</button>
						<img class="actionLoader" *ngIf="showActionLoaderModel" [src]="fullImagePath" />
					</div>
				</div>
			</div>
		</div>

		<div class='models_panel' style='margin-bottom: 20px;' *ngIf="projModToggle">
			<div class='models_page_header_container_small'>
				<div class='keywest_header'>
					<div class='row'>
						<div class='col-xs-10'>
							<div class='title'>{{trans.trans.based_on_your_project_selection_we_suggest_the_following_products.value}}</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4" *ngFor="let item of availableSolutions">
						<div class="checkbox custom-checkbox">
							<label>
								<span *ngIf="item.quantity_enabled == '1'">
									<span (click)="quantityEnabled($event);item.showeditable = !item.showeditable; item.tempQuantity = item.quantity" *ngIf="!item.showeditable">
										<span *ngIf="item.quantity" class="quantityCheck">{{item.quantity}}</span>
									</span>
									&nbsp;of</span> {{item.name}}
								<span *ngIf="item.showeditable" class="editableBox">
									<input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity">
									<button (click)="item.showeditable = !item.showeditable;" class="btn btn_customizable">
										<i class="fa fa-check"></i>
									</button>
									<button (click)="item.quantity = item.tempQuantity;
										item.showeditable = !item.showeditable" class="btn btn-default">
										<i class="fa fa-times"></i>
									</button>
								</span>

								<input type="checkbox" name="item.isChecked" (change)="item.isChecked = !item.isChecked" [checked]="item.isChecked" (click)="checkModels(item.id,item.quantity,$event)" />

								<span class="check"></span>
							</label>
						</div>
					</div>
					<div *ngIf="availableSolutions.length == 0 && !showModelsLoader">
						<p class="well">{{trans.trans.no_models_for_this_project.value}}
						</p>
					</div>
					<div class="text-center m-t-xxl">
						<img class="actionLoader" *ngIf="showModelsLoader" src="fullImagePath" />
					</div>
				</div>
			</div>
			<div class='row'>
				<div class='col-xs-12'>
					<button type="button" class="btn btn_customizable" *ngIf="selectedModels.length > 0" style="margin-top: 0.5%" (click)="createSolution()">
						{{trans.trans.next.value}}
					</button>
					<img class="actionLoader" *ngIf="showActionLoaderModel" src="fullImagePath" />
				</div>
			</div>
		</div>
	</div>

	<div class='models_panel' style='margin-bottom: 20px;' *ngIf="showMasterValuePropProjects">
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>{{trans.trans.pick_your_projects.value}}</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-11" *ngIf="availableMasterValuePropProjects.length > 0">
					<div class="panel-body col-md-5 projects-list">
						<div *ngFor="let projects of availableMasterValuePropProjects">
							<p>
								<i class="fa fa-plus" (click)="projects.isCollapsed = !projects.isCollapsed" [attr.aria-expanded]="projects.isCollapsed"></i>
								&nbsp;
								<span class="tooltip-custom" [ngbTooltip]="projects.description" container="body" placement="right">{{projects.name}}</span>
							</p>
							<ul [ngbCollapse]="!projects.isCollapsed">
								<li *ngFor="let children of projects.children" class="checkbox custom-checkbox">
									<label [ngbTooltip]="children.description" container="body" placement="right">
										{{children.name}}
										<input type="checkbox" name="project{{children.id}}" [(ngModel)]="children.selected">
										<span class="check"></span>
									</label>
								</li>
							</ul>
						</div>
					</div>
					<div class="d-flex panel-body col-md-1 action-icons-wrapper">
						<div class="fa fa-chevron-right" (click)="moveSelectedProject()"></div>
					</div>
					<div class="panel-body col-md-5 projects-list">
						<div *ngFor="let projects of selectedProjects">
							<p class="col-md-10">
								<span class="tooltip-custom" [ngbTooltip]="projects.description" container="body" placement="right">{{projects.parent}}
									- {{projects.name}}</span>
							</p>
							<p class="col-md-2" role="button" (click)="removeSelectedProject(projects.id)">
								<i class="fa fa-times"></i>
							</p>
						</div>
					</div>
				</div>
				<div class="col-md-11" *ngIf="(availableMasterValuePropProjects.length == 0) && showActionLoaderProjects">
					<img class="actionLoader" [src]="fullImagePath" />
				</div>
				<div class="col-md-11" *ngIf="(availableMasterValuePropProjects.length == 0) && !showActionLoaderProjects">
					<p>{{trans.trans.no_projects_found.value}}</p>
				</div>
				<div class="col-md-11">
					<button type="button" class="btn btn_customizable" (click)="createProjects()" [disabled]="(availableMasterValuePropProjects.length > 0) && (selectedProjects.length == 0)">
						{{trans.trans.next.value}}
					</button>
					<img class="actionLoader" [src]="fullImagePath" *ngIf="(availableMasterValuePropProjects.length > 0) && showActionLoaderProjects" />
				</div>
			</div>
		</div>
	</div>

	<div class='models_panel' style='margin-bottom: 20px;' *ngIf="isVMWare && q2">
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>How does your selection solve the customer problem?</div>
					</div>
				</div>
			</div>
			<div class='row'>
				<div class='col-xs-12'>
					<textarea name='q2answer' maxlength="1200" [(ngModel)]="q2answer" class='form-control' rows='3'></textarea>
					<small style='font-size: 70%;'>{{1200 - q2answer.length}} of 1200 characters left <span style='color: red;'>{{trans.trans.required.value}}</span></small><br><br>
					<a (click)='funcSaveQ2()' class='btn btn_customizable'>{{trans.trans.next.value}}</a>
				</div>
			</div>
		</div>
	</div>

	<div class='row' *ngIf="showSituation && q2Finished" style='margin-bottom: 20px;'>
		<div class='col-xs-12'>
			<div *ngIf="!masterValueProp">
				<app-situations-shared [valueProp]="valueProp" [canEdit]="valueProp.vp_can_edit" [type]="'quickcreate'" (getScalers)="getScalers()" (resetStepsBelow)="resetStepsBelowConfirmation(3)"></app-situations-shared>

			</div>
			<!-- <div *ngIf="masterValueProp">
				<app-situation-valueprop masterValueProp="true" [masterValuePropId]="masterValuePropId" [type]="'quickcreate'" [(showScalers)]="showScalers" (getScalers)="getScalers()" (resetStepsBelow)="resetStepsBelowConfirmation(3)"></app-situation-valueprop>
			</div> -->
		</div>
	</div>

	<div class='models_panel' style='margin-bottom: 20px;' *ngIf="showScalers">
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title' *ngIf="isvalueProp">{{trans.trans.scale_your_value_prop.value}}</div>
						<div class='title' *ngIf="!isvalueProp">{{trans.trans.scale_your_value_position.value}}</div>
					</div>
				</div>
			</div>


			<div *ngIf="feature26 && MasterScalerList?.length">
				<div class='row'>
					<div class='col-xs-12'>
						<h3>
							{{trans.trans.primary_scalers.value}}
						</h3>
					</div>
				</div>
				<div class='row'>
					<div class='col-xs-3' *ngFor="let item of MasterScalerList; let i = index">
						<b>{{item.name}}</b> <input type='text' name='masterscaler{{item.id}}' class='form-control' [(ngModel)]="item.value">
					</div>
				</div>
				<div class='row'>
					<div class='col-xs-6'>
						<img class="actionLoader" *ngIf="masterCalculateLoading" [src]="fullImagePath" />
						<span *ngIf="!masterCalculateLoading" class='btn btn_customizable clickable' (click)='masterScalerCalculate()'>{{trans.trans.calculate.value}}</span>
					</div>
				</div>

			</div>

			<h3>{{trans.trans.all_scalers.value}}</h3>
			<div class="row">
				<div class="col-xs-3" style='height: 100px;' *ngFor="let allScaler of allScalersList">
					<label [for]="allScaler.scale_type_id" class="scalerName">{{allScaler.name}}
						<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle" [ngbTooltip]="allScaler.description" placement="right" container="body"></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="allScaler.description" placement="right" container="body">
							info
						</span>
					</label>
					<input type="number" class="form-control m-t-xxs" [id]="allScaler.scale_type_id" [placeholder]="allScaler.name" name="scalerValue_{{allScaler.id}}" [(ngModel)]="allScaler.value" min="0" autocomplete="off" style='max-width: 150px;'>
				</div>
				<div [hidden]="!showActionLoader" class="m-l-xxs">
					<img class="actionLoader" [src]="fullImagePath" />
				</div>
			</div>
			<div *ngIf="!showActionLoader && allScalersList == null || allScalersList.length == 0">
				<p class="well">{{trans.trans.no_scalers_found.value}}</p>
			</div>
			<div class='row'>
				<div class='col-xs-12'>
					<div class="form-group">
						<button type="button" class="btn btn_customizable scaleValuePropBtn" (click)="createScaler('finishandreview')">
							<span>{{trans.trans.finish_and_review.value}}</span>
						</button>
						<button *ngIf="dealdesk" type="button" class="btn btn_customizable scaleValuePropBtn" (click)="createScaler()">
							<span>{{trans.trans.continue_to_configure.value}}</span>
						</button>
						<img class="actionLoader" *ngIf="showActionLoaderScaler" [src]="fullImagePath" />
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<ng-template #stepMoveBackConfirmation let-c="close" let-d="dismiss">
	<div class="modal-body">
		<p>{{trans.trans.going_back_a_few_steps_this_may_reset_subsequent_steps_and_start_from_the_current_step.value}}
		</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn_customizable" (click)="c('Close click'); resetStepsBelow()">{{trans.trans.yes.value}}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{trans.trans.cancel.value}}</button>
	</div>
</ng-template>

<ng-template #openDiscoveryModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{discoveryHeader}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<app-vmware (changeHeading)="changeDiscoveryHeader($event)" [valuePropId]="valuePropId.toString()"></app-vmware>
	</div>
</ng-template>
