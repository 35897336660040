<ul  style="margin: 0 20px;" [ngClass]="{'ui-menubar-root-list':root, 'ui-widget-content ui-corner-all ui-submenu-list ui-shadow':!root}" (click)="listClick($event)">
	<ng-container *ngFor="let child of options; let i=index">
		<li *ngIf="child && child.separator" class="ui-menu-separator ui-widget-content" [ngClass]="{'ui-helper-hidden': child.visible === false}" style="margin: 0 25px;"></li>
		<li *ngIf="!child.separator" #listItem [ngClass]="{'ui-menuitem ui-corner-all':true, 'ui-menu-parent':child.items,'ui-menuitem-active':listItem==activeItem,'ui-helper-hidden': child.visible === false}" (mouseenter)="onItemMouseEnter($event,listItem,child)" (mouseleave)="onItemMouseLeave($event)" (click)="onItemMenuClick($event, listItem, child)">
      <a *ngIf="!child.routerLink" style='float: left; display: block; width: 100%;' [matTooltip]='child.tooltip' matTooltipPosition="above" [href]="child.url||'#'" [attr.data-automationid]="child.automationId" [attr.target]="child.target" [attr.title]="child.title" [attr.id]="child.id" (click)="itemClick($event, child, listItem)" [ngClass]="{'ui-menuitem-link ui-corner-all':true,'ui-state-disabled':child.disabled, 'value-execution-add-a-new-value-proposition-results-menu-header':true}" [ngStyle]="child.style" [class]="child.styleClass " [attr.tabindex]="child.tabindex ? child.tabindex : '0'" [attr.data-heap]="'value-execution-add-a-new-value-proposition-results-menu-' + child.label.toLowerCase().split(' ').join('-')">
				<span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
				<span class="ui-menuitem-text" style='float: left; display: block;'>{{child.label}}</span>
				<span class="{{child.badgeStyleClass}}" style='float: left; display: block;'>{{child.badge}}</span>
				<span class="ui-submenu-icon pi pi-fw" *ngIf="child.items" class="fa" [ngClass]="{'fa-caret-down':root,'fa-caret-right':!root}" style='float: left; display: block; margin-left: 7px;'></span>
			</a>
			<a *ngIf="child.routerLink" [routerLink]="child.routerLink" [attr.data-automationid]="child.automationId" [queryParams]="child.queryParams" [routerLinkActive]="'ui-state-active'" [routerLinkActiveOptions]="child.routerLinkActiveOptions||{exact:false}" [attr.target]="child.target" [attr.title]="child.title" [attr.id]="child.id" [attr.tabindex]="child.tabindex ? child.tabindex : '0'" (click)="itemClick($event, child, listItem)" [ngClass]="{'ui-menuitem-link ui-corner-all':true,'ui-state-disabled':child.disabled}" [ngStyle]="child.style" [class]="child.styleClass">
				<span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
				<span class="ui-menuitem-text">{{child.label}}</span>
				<span class="ui-submenu-icon pi pi-fw" *ngIf="child.items" class="fa" [ngClass]="{'fa-caret-down':root,'fa-caret-right':!root}"></span>
			</a>
			<dl-menubarSub class="ui-submenu" [item]="child" *ngIf="child.items" [autoDisplay]="true"></dl-menubarSub>
		</li>
	</ng-container>
</ul>
