
import { Injectable } from '@angular/core';
import defaultLang from './defaultLang';
import { CommonService } from '../common/common.service';
import { forkJoin, Subject } from 'rxjs';


export function GenericClass<Props>(): new () => Props {
	return class { } as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
	return path ? `${path}.${suffix}` : suffix;
}

function transformObjectToPath<T extends object | string>(
	suffix: string,
	objectToTransformOrEndOfPath: T,
	path = ''
): T {
	return typeof objectToTransformOrEndOfPath === 'object'
		? Object.entries(objectToTransformOrEndOfPath).reduce(
			(objectToTransform, [key, value]) => {
				objectToTransform[key] = transformObjectToPath(
					key,
					value,
					concatIfExistsPath(path, suffix)
				);

				return objectToTransform;
			},
			{} as T
		)
		: (concatIfExistsPath(path, suffix) as T);
}

@Injectable()
export class Translations extends GenericClass<typeof defaultLang>() {
	public toggleShowTranslate = new Subject<any>();

	constructor(
		private CommonService: CommonService
	) {
		super();
		Object.assign(this, transformObjectToPath('', defaultLang));
	}

	funcLoadOverrideFromServer() {

		this.funcLoadOverrides();
	}

	funcLoadOverrides(trans?) {
		let language_type_id = sessionStorage.getItem("language_type_id");
		if (!language_type_id) { language_type_id = "1"; }

		if (sessionStorage.getItem("uid") && (!sessionStorage.getItem("labels") || !sessionStorage.getItem("all_dictionaries"))) {

			let h1 = this.getAllDictionaries();
			let h2 = this.getOverrides();
			forkJoin([h1, h2]).subscribe(res => {
				let out = [];
				let label_list = res[0].result.all_dictionaries;

				for (let i = 0; i < label_list.length; i++) {
					out.push({ k: label_list[i].name, v: label_list[i].description, language_type_id: label_list[i].language_type_id })
				}

				sessionStorage.setItem("all_dictionaries", JSON.stringify(out));

				let out2 = [];
				let label_list2 = res[1].result;
				let labels = label_list2.filter(x => x.language_type_id == language_type_id);

				for (let i = 0; i < labels.length; i++) {
					out2.push({ k: labels[i].key, v: labels[i].value, language_type_id: labels[i].language_type_id })
				}

				sessionStorage.setItem("labels", JSON.stringify(out2));

				label_list = (JSON.parse(sessionStorage.getItem("all_dictionaries")) ? JSON.parse(sessionStorage.getItem("all_dictionaries")) : []);

				labels = [];
				labels = label_list.filter(x => x.language_type_id == language_type_id);

				this.processLabels(labels, trans);

				label_list = (JSON.parse(sessionStorage.getItem("labels")) ? JSON.parse(sessionStorage.getItem("labels")) : []);

				labels = [];
				labels = label_list.filter(x => x.language_type_id == language_type_id);

				this.processLabels(labels, trans);
			})

		} else {

			let label_list = (JSON.parse(sessionStorage.getItem("all_dictionaries")) ? JSON.parse(sessionStorage.getItem("all_dictionaries")) : []);

			let labels = [];
			labels = label_list.filter(x => x.language_type_id == language_type_id);

			this.processLabels(labels, trans);

			label_list = (JSON.parse(sessionStorage.getItem("labels")) ? JSON.parse(sessionStorage.getItem("labels")) : []);

			labels = [];
			labels = label_list.filter(x => x.language_type_id == language_type_id);

			this.processLabels(labels, trans);
		}
	}

	reloadLabels(trans, language_type_id) {
		let label_list = (JSON.parse(sessionStorage.getItem("all_dictionaries")) ? JSON.parse(sessionStorage.getItem("all_dictionaries")) : []);

		let labels = [];
		labels = label_list.filter(x => x.language_type_id == language_type_id);

		this.processLabels(labels, trans);

		label_list = (JSON.parse(sessionStorage.getItem("labels")) ? JSON.parse(sessionStorage.getItem("labels")) : []);

		labels = [];
		labels = label_list.filter(x => x.language_type_id == language_type_id);

		this.processLabels(labels, trans);

	}
	getAllDictionaries() {
		let endpoint = "fact/factLabels";
		return this.CommonService.getAPIService(endpoint);
	}

	getOverrides() {
		let endpoint = "account/accountLabelTypes";
		return this.CommonService.getAPIService(endpoint);
	}

	processLabels(labels, trans) {
		if (labels && labels.length) {
			for (let i = 0; i < labels.length; i++) {

				let l = (labels[i] && labels[i].k && labels[i].v ? labels[i].k.split(".") : null);
				if (l && l.length) {
					switch (l.length) {
						case 1:
							if (trans[l[0]]) { trans[l[0]] = labels[i].v; }
							break;

						case 2:

							if (trans[l[0]][l[1]] && l) { trans[l[0]][l[1]] = labels[i].v; }
							break;

						case 3:
							if (trans[l[0]][l[1]][l[2]]) { trans[l[0]][l[1]][l[2]] = labels[i].v; }
							break;

						case 4:
							if (trans[l[0]][l[1]][l[2]][l[3]]) { trans[l[0]][l[1]][l[2]][l[3]] = labels[i].v; }
							break;
					}
				}
			}
		}
	}
}