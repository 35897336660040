import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Quote } from './models/quote.model';
import { CasstudyQuoteTranslations } from './quote.translation';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements OnInit, OnDestroy {
  @Input() caseStudyId: string | number;
  @Input() quotes: Quote[];
  @Output() valueChange = new EventEmitter();

  quote: Partial<Quote> = {
    title: '',
    quote: '',
    contact_first_name: '',
    contact_last_name: '',
  };
  edit = false;
  selectedQuote: number | string;
  subscriptionsaveQuote: Subscription;
  subscriptionupdateQuote: Subscription;
  subscriptiondeleteQuote: Subscription;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    private modalService: NgbModal,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: CasstudyQuoteTranslations
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    if (this.subscriptionsaveQuote) {
      this.subscriptionsaveQuote.unsubscribe();
    }
    if (this.subscriptionupdateQuote) {
      this.subscriptionupdateQuote.unsubscribe();
    }
    if (this.subscriptiondeleteQuote) {
      this.subscriptiondeleteQuote.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  resetModal() {
    this.quote = {
      title: '',
      quote: '',
      contact_first_name: '',
      contact_last_name: '',
    };
  }

  openAddQuoteModal(content: any, quote?: Partial<Quote>) {
    this.resetModal();
    this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });

    if (quote) {
      this.quote = {
        title: quote['title'],
        quote: quote['quote'],
        contact_first_name: quote['first_name'],
        contact_last_name: quote['last_name'],
        account_case_study_quote_id: quote['id'],
      };

      this.edit = true;
    }
  }

  processaddQuote(response: any) {
    if (response.result && response.result.success) {
      this.notification.success(response.result.message, false);
      this.valueChange.emit();
    }
  }
  addQuote() {
    const payload = this.quote;

    payload['case_study_id'] = this.caseStudyId.toString();

    if (!this.edit) {
      this.subscriptionsaveQuote = this.solutionService.saveQuote(payload).subscribe((response) => {
        this.processaddQuote(response);
      });
    } else {
      this.subscriptionupdateQuote = this.solutionService.updateQuote(payload).subscribe((response) => {
        this.processaddQuote(response);
      });
    }
  }

  openDeleteQuote(content: any, quoteId: string | number) {
    this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });

    this.selectedQuote = quoteId;
  }

  deleteQuote() {
    this.subscriptiondeleteQuote = this.solutionService.deleteQuote(this.caseStudyId, this.selectedQuote).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.valueChange.emit();
      }
    });
  }
}
