import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { AccountService } from '@data/services/account/account.service';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject } from 'rxjs';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { AllScaler } from '@data/services/valueposition/models/all-scaler.interface';
import { StyleService } from 'app/style.service';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';

@Component({
	selector: 'app-typical-customers',
	templateUrl: './typical_customers.component.html'
})
export class TypicalCustomersComponent implements OnInit, OnDestroy {
	@Input() can_edit: boolean = false;

	image_url: string;
	fullImagePath: string;
	scalerLoader: boolean = false;
	@Input() solutionId: string;
	scalers: Array<BasicInfo | Factor> = [];
	factScalers: Array<BasicInfo> = [];
	selectedScalers: AllScaler[] = [];
	showEditScalersForm: boolean = false;
	showActionLoader: boolean = false;
	accountScalerShow: boolean = false;
	accountScaler: any = {name: '', description: ''};

	//dropdown
	selectedItems: Array<object> = [];
	dropdownSettings = {};

	strSearch: string = '';
	ngUnsubscribe = new Subject();
	style2022$: Observable<boolean>;
	@ViewChild('editScalersForm') editScalersForm: NgForm;
	constructor(
		private solutionService: SolutionService,
		private commonService: CommonService,
		private factService: FactsService,
		private notification: NotificationService,
		private accountService: AccountService,
		private translateService: TranslateService,
		public trans: Translations,
		private styleService: StyleService
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getScalers();

		this.dropdownSettings = {
			singleSelection: false,
			text: 'Available Scalers',
			enableCheckAll: false,
			enableSearchFilter: false,
			labelKey: 'name',
			badgeShowLimit: 2,
			lazyLoading: false
		};
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getScalers() {
		this.factService.getScaleTypes(1).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.factScalers = response.result;

				this.scalerLoader = true;
			}
			this.getAccountScalers();
		});
	}

	getAccountScalers() {
		this.factService.getAccountScaleTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			this.scalers = this.factScalers;
			if (response.result) {
				this.scalers = this.scalers.concat(response.result);
			}
			this.scalerLoader = true;
			this.getAvailableScalers();
		});
	}

	getAvailableScalers() {
		this.solutionService.getAvailableScalers(this.solutionId, 1).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.selectedScalers = response.result;
				this.scalerLoader = true;
			}
		});
	}

	addScalersforEdit() {
		this.showEditScalersForm = true;

		let selectedScalersID = this.selectedScalers.reduce((acc, curr: any) => {
			return {
				[curr.scale_type_id]: true,
				...acc
			};
		}, {});

		this.selectedItems.forEach((item: any) => {
			if (!selectedScalersID[item.id]) {
				item['scale_type_id'] = item['id'];
				this.selectedScalers.push(item);
			}

		});
	}

	editScalers() {
		let account_solution_scalers = [];
		let tmp = this.selectedScalers.filter(x => x.updated == true);
		tmp.forEach(elem => {
			account_solution_scalers.push({
				'scale_type_id': elem.scale_type_id,
				'value': elem.value
			});
		});

		this.solutionService.updateScalers(this.solutionId, { account_solution_scalers }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.getScalers();
				this.getAvailableScalers();
				this.notification.success('Scalers updated successfully', false);
				this.showActionLoader = false;
			}
		});

	}

	deleteScaler(id) {
		this.showActionLoader = true;
		this.solutionService.deleteScaler(this.solutionId, id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				for (let i = 0; i < this.selectedScalers.length; i++) {
					if (this.selectedScalers[i]['id'] == id) {
						this.selectedScalers.splice(i, 1);
					}
				}
				this.notification.success(response.result, false);
				this.getScalers();
				this.getAvailableScalers();
				this.showActionLoader = false;
			}
		});
	}

	resetFormValues(form) {
		for (let key in form.value) {
			let oldValue = this.getScalerResetValue(key.split('-')[1]);
			form.controls[key].setValue(oldValue);
		}
	}

	getScalerResetValue(id) {
		for (let i = 0; i < this.selectedScalers.length; i++) {
			if (this.selectedScalers[i]['scale_type_id'] == id) {
				return this.selectedScalers[i]['value'];
			}
		}
	}

	toggleScalersCreate() {
		this.accountScalerShow = !this.accountScalerShow;
		this.accountScaler = {
			name: '',
			description: ''
		}
	}

	createAccountScalers() {
		this.showActionLoader = true;
		let account_id = sessionStorage.getItem('aid');
		this.accountService.createAccountScalers(account_id, this.accountScaler).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			if (response.result) {
				this.notification.success('Account Scale Type created successfully', false);
				this.toggleScalersCreate();
				this.getAccountScalers();
				this.showActionLoader = false;
			}
		});
	}
}
