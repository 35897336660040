import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
	selector: 'app-key-discovery',
	templateUrl: './key_discovery.component.html'
})
export class KeyDiscoveryComponent implements OnInit, OnDestroy {
	@Input() solutionID: number;
	@Input() tabInit: number;
	@Input() data;

	@Output() updateAssumptions = new EventEmitter();
	// @Output() 

	fullImagePath: string;
	image_url: string;
	driversLoader: boolean = false;
	drivers: any = [];
	financialsLoader: boolean = false;
	financials: any = [];
	scratchPadLoader: boolean = false;
	scratchPads: any = [];
	sourceTypes: any = [];

	isCustomerShareType: boolean = false;

	ngUnsubscribe = new Subject();

	constructor(
		private CommonService: CommonService,
		private solutionService: SolutionService,
		private valuePropService: ValuepropService,
		private notification: NotificationService
	) {
		this.image_url = this.CommonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {

		this.loadKeyAssumptions();

		this.valuePropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.loadKeyAssumptions();
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	loadKeyAssumptions() {
		this.driversLoader = true;

		this.valuePropService.getKeyAssumptions(this.data.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.drivers = response.result.drivers;
				this.financials = response.result.financials;
				this.scratchPads = response.result.units;

			}
			this.driversLoader = false;
		},
			() => {
				this.driversLoader = false;
			});
	}

	editDriverValue(driver) {
		let payload = {
			driver_factor_id: driver.driver_factor_id,
			driver_override: driver.driver_value,
			driver_source_type_id: driver.driver_source_type_id,
			value_prop_metric_id: driver.value_prop_metric_id,
			// "solution_id": this.data.id,
			// "account_solution_metric_id": driver.account_solution_metric_id,
			// "key": "driver_baseline_answer",
			// value: driver.driver_value,
			// "factor": "driver_factor_id",
			// "driver_factor_id": driver.driver_factor_id
		}


		this.valuePropService.updateBenefitDriver(this.data.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {


			if (response.result && response.result.success) {
				this.notification.success("Update Successful", false);
				this.loadKeyAssumptions();
				this.valuePropService.refreshDashboard.next('refreshDashboard');

				this.solutionService.triggerBenefit.next('reloadBenefits');

			}
		});
	}

	editFinancialValue(financial) {
		let payload = {
			financial_factor_id: financial.financial_factor_id,
			financial_source_type_id: financial.financial_source_type_id,
			financial_override: financial.financial_value,
			value_prop_metric_id: financial.value_prop_metric_id
			// "solution_id": this.data.id,
			// "account_solution_metric_id": financial.account_solution_metric_id,
			// "key": "financial_baseline_answer",
			// "value": financial.financial_value,
			// "factor": "financial_factor_id",
			// "factor_id": financial.financial_factor_id
		}

		this.valuePropService.updateBenefitFinancial(this.data.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result && response.result.success) {
				this.notification.success("Update Successful", false);
				// this.notification.success(response.result.message, false);
				this.loadKeyAssumptions();
				this.valuePropService.refreshDashboard.next('refreshDashboard');
				this.solutionService.triggerBenefit.next('reloadBenefits');
			}
		});
	}
}
