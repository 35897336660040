import { Injectable } from '@angular/core';

@Injectable()
export class AddCasestudyRealizationTranslations {
  public config: any = {
    component: 'addCasestudyRealization',
    display_name: 'Add Case Study Realization',
    description: 'Add a case study for a value realization',
  };

  public trans: any = {
    Yes: 'Yes',
    No: 'No',
    Website: 'Website',
    address: 'Address',
    description: 'Description',
    employees: 'Employees',
    pressEnterToCreate: 'Use the return keyboard after typing to create the tag',
    accountDetails: 'Account Details',
    modelName: 'Model Name',
    name: 'Name',
    revenue: 'Revenue',
    save: 'Save',
    sector: 'Sector',
    step_1_redact: 'Step #1: Redact',
    tags: 'Tags',
    are_you_sure: 'Are you sure?',
    back: 'Back',
    addProofPoints: 'Add Proof Points',
    redact: 'Redact',
    caseStudySuccess: 'Proof / Case Study created successfully',
    caseStudyfailure: 'There was a problem creating the proof / case study',
    caseStudyUpdate: 'Proof / Case study updated successfully',
    caseStudyUpdateFailure: 'There was a problem updating the proof / case study',
    cancel: 'Cancel',
    editName: 'Edit Case Study Name',
  };
}
