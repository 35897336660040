import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { AEModelQuoteModule } from './ae_quote/ae_quote.module';
import { QuoteComponent } from './quote.component';
import { QuoteTranslations } from './quote.translation';
import { RModelQuoteModule } from './r_quote/r_quote.module';
@NgModule({
  declarations: [QuoteComponent],
  imports: [
    MenuModule,
    SidebarModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    AreYouSureModule,
    RModelQuoteModule,
    AEModelQuoteModule,
    MatTooltipModule,
    EditTranslationsModule,
    ApplicationPipesModule,
  ],
  providers: [TranslationsV2Service, QuoteTranslations],
  entryComponents: [QuoteComponent],
  exports: [QuoteComponent],
})
export class ModelQuoteModule {}
