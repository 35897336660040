<div class="col-md-6 " style='margin-bottom: 10px;'>
	<div class='models_panel' style='height: 400px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-6'>
						<div class='title'>{{ trans.trans.masterValuePropsHeading.value}}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.mvp_name'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.masterValuePropsHeading"></app-editTranslation></div>
					</div>
					<div class='col-xs-6'>

					</div>
				</div>
			</div>

			<div style='overflow-y: auto; max-height: 270px;'>
				<div *ngIf="dbI.isValuePropUser">
					<table class="table custom-table dashboard-table fixedTable">
						<thead>
							<tr>
								<th width="70%">{{ trans.trans.mvp_name.value }}
									<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.mvp_name'
									[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.mvp_name"></app-editTranslation>
								</th>
								<th width="30%" class="text-right">
									{{ trans.trans.mvp_totalBenefits.value }}
									<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.mvp_totalBenefits'
									[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.mvp_totalBenefits">
									</app-editTranslation>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr class="grid-row company-row" *ngFor="let m of dbI.masterValueProps; let index = index">
								<td>
									<a *ngIf="m.is_group == 1" routerLink="/mastervalueprop/{{m.id}}/group" class='link'>{{m.name}}</a>
									<a *ngIf="!m.is_group" routerLink="/mastervalueprop/{{m.id}}" class='link'>{{m.name}}</a>
									<i class="fa fa-plus" *ngIf="m.value_props" (click)="m.isCollapsed = !m.isCollapsed" [attr.aria-expanded]="m.isCollapsed"></i>

									<table [ngbCollapse]="!m.isCollapsed">
										<tr *ngFor="let v of m.value_props">
											<td width="80%">
												<a routerLink="/valueposition/{{v.id}}" class='link'>{{v.name}}</a>
											</td>

											<td>
												<!-- <label>
													<strong>Total Benefits: </strong>
												</label> -->
												{{v.total_benefits_fmt}}
											</td>
										</tr>
									</table>
								</td>
								<td class="text-right">{{m.total_benefits_fmt}}</td>
							</tr>
						</tbody>
					</table>
					<div *ngIf="dbI.masterValueProps.length==0" class="m-t-md col-sm-12">
						<div class="text-center">
							<h4>{{ trans.trans.mvp_noValueProps.value }}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.mvp_noValueProps'
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.mvp_noValueProps"></app-editTranslation>
							</h4>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="!dbI.isValuePropUser">
				<p>{{ trans.trans.mvp_notLicensedForValueProps.value }}
					<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.mvp_notLicensedForValueProps'
					[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.mvp_notLicensedForValueProps"></app-editTranslation>
				</p>
			</div>
			<a routerLink="/mastervalueprop" class="keywest_seeMore">{{ trans.trans.mvp_seeMore.value }}</a>
			<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.mvp_seeMore'
			[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.mvp_seeMore"></app-editTranslation>

		</div>

	</div>
</div>