import { Injectable } from '@angular/core';

@Injectable()
export class RBenefitsCasestudyTranslations {
	public config: any = {
		component: 'readBenefitsCaseStudies',
		display_name: 'Case Study Benefits View',
		description: 'View for a case study benefit'
	};

	public trans: any = {
		viewBenefits: 'View Benefits',
		add: 'Add',
		selected: 'Selected',
		improvement: 'Improvement',
		type: 'Type',
		save: 'Save',
		update: 'Update',
        edit_translations: 'Edit Translations'
	};
}
