import { Injectable } from '@angular/core';

@Injectable()
export class OverviewTranslations {
	public config: any = {
		component: 'modelsOverview',
		display_name: 'Solution Overview',
		description: 'Overview of a model'
	};

	public trans: any = {
		benefits: 'Benefits',
        costs: 'Costs',
        situations: 'Situations',
        case_studies: 'Case Studies',
        competitors: 'Competitors',
        thresholds: 'Thresholds',
        deployment_time: 'Deployment Time',
        months: '(Months)',
        factors: 'Factors',
        goals: 'Goals',
        scalers: 'Default Scaler values',
    };
}
