import { Injectable } from '@angular/core';

@Injectable()
export class AssetPreviewTranslations {
  public config: any = {
    component: 'assetPreview',
    display_name: 'Asset Preview',
    description: 'Asset Download Preview',
  };

  public trans: any = {
    emptyTable: 'Search for Value Props to test against'
  };
}
