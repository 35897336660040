import { Injectable } from '@angular/core';

@Injectable()
export class ThresholdTranslations {

    public config = {
        component: 'threshold',
        display_name: 'Thresholds',
		description: 'Thresholds for solutions'
    };

    public trans: any = {
        thresholds: 'Thresholds',
        name: 'Name 	',
        min: 'Min 	',
        max: 'Max 	',
        margin: 'Margin',
        addNew: 'Add New',
        edit_translations: 'Edit Translations',
        edit: 'Edit',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
        view: 'View',
        search: 'Search',
        threshold_delete_success: 'Solution Threshold deleted successfully',
        threshold_add_success: 'Solution Threshold added successfully',
        sidebar_title_view: "View Threshold",
        sidebar_title_add: "Add New Threshold",
        sidebar_title_edit: "Edit Threshold",

    };
}
