<div class="row">
  <div class="col-md-12">
    <p-tabView styleClass="no-space">
      <p-tabPanel [header]="trans.trans.goal.value">
        <app-track-value-goal-tab [valuePropId]="valuePropId"></app-track-value-goal-tab>
      </p-tabPanel>
      <p-tabPanel *ngIf="businessReviewData" [disabled]="businessReviewData?.length === 0" [header]="trans.trans.benefit.value">
        <app-track-value-benefit-tab [valuePropId]="valuePropId" [valueProp]="valueProp"></app-track-value-benefit-tab>
      </p-tabPanel>
      <p-tabPanel [header]="trans.trans.successCriteria.value">
        <app-track-value-success-tab [valuePropId]="valuePropId"></app-track-value-success-tab>
      </p-tabPanel>
      <p-tabPanel [header]="trans.trans.survey.value">
        <app-track-value-survey-tab [valuePropId]="valuePropId"></app-track-value-survey-tab>
      </p-tabPanel>
      <!-- <p-tabPanel [header]="trans.trans.cost.value">
            <app-track-value-cost-tab></app-track-value-cost-tab>
          </p-tabPanel> -->
      <p-tabPanel [header]="trans.trans.proofPoints.value">
        <app-track-value-proof-tab [valuePropId]="valuePropId" *ngIf="valuePropId"></app-track-value-proof-tab>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
