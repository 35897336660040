import { take } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, ViewChild, Input, Output, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { takeUntil } from 'rxjs/operators';

import { Subscription, Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AssumptionsVpDashTranslations } from './assumptions.translation';
import { Table } from 'primeng/table';
import { getSeparatorByLocale } from '@shared/utils';
import { UserService } from '@data/services/user/user.service';
import { RepSimpleTranslations } from 'app/repv2/simple.translation';
import { isEmpty } from 'lodash';
import { ValueProp } from '@shared/models/value-prop.model';
import { AllAssumptionType, AssumptionDriver, AssumptionFinancial, AssumptionScratchpad } from '@shared/models/assumptions.model';
import { PTableColumn } from '@shared/models/p-table.model';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Factor, FactorGroup } from '@data/services/valueposition/models/factor-group.interface';
import { MenuItem } from 'primeng/api';
import { DashboardSimpleImplementation } from '../../dashboard_simple/simple.implementation';

@Component({
  selector: 'app-assumptions',
  templateUrl: './assumptions.component.html',
})
export class AssumptionsComponent implements OnInit, OnDestroy {
  @Input() showHelp;
  @Input() valueProp: ValueProp;
  @Input() keyAssumptions: boolean = false;
  @Input() locked: boolean = false;
  @Input() view: string = 'full';
  @Input() viewSimple: boolean = false;
  @Input() showTranslate: boolean = false;
  @Input() showContinue: boolean =  true;
  @Input() permissions: { [klass: string]: any } = {
    account_info_editable: true,
    account_info_view: true,
    assumptions_editable: true,
    assumptions_view: true,
    benefits_editable: true,
    benefits_view: true,
    costs_view: true,
    re_share: true,
    result_view: true,
    expires: null,
  };
  @Input() showCharts = true;

  @Output() valueChange = new EventEmitter();
  @Output() callbackSelectedBenefit = new EventEmitter();
  @Output() callbackNext = new EventEmitter();
  @Output() funcCallback = new EventEmitter();
  @Output() back = new EventEmitter();

  @ViewChild('tableAssumptionsDrivers') tableAssumptionsDrivers: Table;
  @ViewChild('tableAssumptionsFinancial') tableAssumptionsFinancial: Table;
  @ViewChild('tableAssumptionsScratchpads') tableAssumptionsScratchpads: Table;

  globalSearch: string = '';
  globalSearchDrivers: string = '';
  globalSearchFinancial: string = '';
  globalSearchScratchpads: string = '';

  fullImagePath: string;
  image_url: string;
  driversLoader: boolean = false;
  drivers: AssumptionDriver[] = [];
  financialsLoader: boolean = false;
  scratchpadUnitsLoader: boolean = false;
  financials: AssumptionFinancial[] = [];
  improvements: any[] = [];
  scratchpadUnits: AssumptionScratchpad[] = [];
  sourceTypes: BasicInfo[] = [];
  isCustomerShareType: boolean = true;

  // Batch Edit
  assumptionsEditing: boolean = false;
  isAssumptionUpdated: boolean = false;
  assumptionSaveLoader: boolean = false;
  ogdrivers: AssumptionDriver[] = [];
  ogfinancials: AssumptionFinancial[] = [];
  ogScratchpadUnits: AssumptionScratchpad[] = [];
  assumption: AssumptionDriver | AssumptionFinancial | AssumptionScratchpad;
  type: string;
  subjectKeyUp: Subject<any> = new Subject();
  canEdit = true;
  scratchpadUnitFeature = this.commonService.checkFeature('23');

  expandDrivers: boolean = true;
  expandFinancial: boolean = true;
  expandScratchpads: boolean = true;
  expandAll: boolean = false;

  subscriptiongetKeyAssumptions: Subscription;

  searchText: string = '';

  unfilteredAllKeyAssumptions: AllAssumptionType[];
  allKeyAssumptions: AllAssumptionType[] = [];

  headerColor: string = 'blue';
  loading: boolean = false;
  selectedFactor: any = { id: '' };
  showFactor: boolean = false;
  templateSetting: string = '';
  valueMetricId: string;
  valueMetric: any = { driver_factor_id: '', financial_factor_id: '' };

  modelsV2 = this.commonService.checkFeature(38);
  feature44 = this.commonService.checkFeature(44);
  factorGroups: FactorGroup[] = [];
  factorGroupsCache: FactorGroup[] = [];
  chartColors: string[] = [];

  pageLimit: number = 100;
  rowLimit: MenuItem[];

  col_driver: PTableColumn[] = [
    { field: 'driver_factor', transField: 'driver_factor', header: 'Driver Factor' },
    { field: 'driver_value_un_fmt', transField: 'driver_value_un_fmt', header: 'Driver Value' },
    { field: 'driver_source', transField: 'driver_source_type', header: 'Driver Source Type' },
  ];

  colFinancial: PTableColumn[] = [
    { field: 'financial_factor', header: 'Financial Factor' },
    { field: 'financial_value_un_fmt', header: 'Financial Value' },
    { field: 'financial_source', header: 'Financial Source Type' },
  ];

  colScratchpads: PTableColumn[] = [
    { field: 'name', header: 'Scratchpad Units' },
    { field: 'unit_type', header: 'Type' },
    { field: 'unit_abbr', header: 'Unit' },
    { field: 'operand_value', header: 'Value' },
  ];

  factorFilter: any;
  unfilteredSelectedFactorGroups: FactorGroup[];
  selected_factor_groups: FactorGroup[] = [];
  userLocale: string;
  decimalSeparator: string;
  groupSeparator: string;
  crm: string;

  allFactorGroups = false;
  ngUnsubscribe = new Subject();

  constructor(
    private commonService: CommonService,
    private ValuepropService: ValuepropService,
    private NotificationService: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: AssumptionsVpDashTranslations,
    private userService: UserService,
    public transRepSimple: RepSimpleTranslations,
    public sI: DashboardSimpleImplementation
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.crm = sessionStorage.getItem('crm');
    this.userLocale = this.userService.getUserLanguage();
    this.decimalSeparator = getSeparatorByLocale(this.userLocale, 'decimal');
    this.groupSeparator = getSeparatorByLocale(this.userLocale, 'group');
    if (!this.permissions) {
      this.permissions = {
        account_info_editable: true,
        account_info_view: true,
        assumptions_editable: true,
        assumptions_view: true,
        benefits_editable: true,
        benefits_view: true,
        costs_view: true,
        re_share: true,
        result_view: true,
        expires: null,
      };
    }
    this.funcBuildPageLimit();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.chartColors = this.commonService.getChartColors();
    if (this.modelsV2) {
      this.loadFactorGroups(true);
    }

    this.headerColor = sessionStorage.getItem('header_color');
    if (!this.valueProp) {
      return;
    }

    this.getSourceTypes();

    if (this.keyAssumptions) {
      this.loadKeyAssumptions();
    } else {
      this.initialize();
    }

    // this.subjectKeyUp
    //   .pipe(debounceTime(400))
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((data) => {
    //     this.updateAssumptionInline(data.event, data.assumption, data.type);
    //   });

    this.ValuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.keyAssumptions) {
        this.loadKeyAssumptions();
      } else {
        this.initialize();
      }
    });

    if (this.view == 'share') {
      this.locked = this.valueProp.closed == '1';
      this.canEdit = this.permissions.assumptions_editable;
    }

    this.canEdit = this.permissions && this.permissions.assumptions_editable && this.valueProp.vp_can_edit;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  get isSomeUpdated() {
    if (this.modelsV2) {
      return this.factorGroups.some((fg) => fg.factors.some((f) => f.updated));
    } else {
      return this.allKeyAssumptions.some((ka) => ka.updated);
    }
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  filterTable(row: any, str: string, fld: string) {
    if (this.globalSearch && row[fld]) {
      return row[fld].toLowerCase().includes(str);
    } else {
      return true;
    }
  }

  filterAssumptions(searchTerm: string): void {
    if (this.modelsV2) {
      this.selected_factor_groups = this.selected_factor_groups.map((group) => {
        const fullGroup = this.factorGroups.find((fGroup) => fGroup.id === group.id);
        return {
          ...group,
          factors: fullGroup.factors.filter((factor) => factor.name.toLowerCase().includes(searchTerm.toLowerCase())),
        };
      });
    } else {
      this.allKeyAssumptions = this.unfilteredAllKeyAssumptions.filter((assumption) => {
        const assumptionName = assumption['driver_factor'] || assumption['financial_factor'] || assumption['improvement_factor'] || assumption['name'];
        return assumptionName.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }
  }

  closeScratchpad(): void {
    this.showFactor = false;
  }

  setRowLimit(num: number): void {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit(): void {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit == 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit == 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit == 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit == 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  getBenefits(benefitStatus: string): string {
    switch (benefitStatus) {
      case '1':
        return 'green';
      case 'Not a Priority':
        return 'violet';
      case '0':
        return 'red';
      case '2':
        return 'orange';
      case '3':
        return 'orange';
    }
  }

  gotoBenefit(metric): void {
    this.callbackSelectedBenefit.emit(metric);
  }

  refreshTranslation(): void {
    this.getTranslations();
  }

  initialize(): void {
    if (this.keyAssumptions) {
      this.loadKeyAssumptions();
    } else {
      this.loadDrivers();
      this.loadFinancials();
      this.loadScratchpadUnits();
    }
  }

  ngOnChanges() {
    if (this.valueProp && +this.valueProp['share_role_type_id'] != 4) {
      this.isCustomerShareType = false;
    }
  }

  funcFilterGroups(arr: FactorGroup) {
    this.selected_factor_groups = [];
    if (!arr) {
      this.selected_factor_groups = this.factorGroups;
      this.allFactorGroups = true;
      return true;
    }
    this.selected_factor_groups.push(arr);
    this.allFactorGroups = false;
  }

  findGroupById(groupObj: FactorGroup[], updatedFactorId: string): Factor {
    return groupObj.find((group) => group.factors.some((factor) => factor.id === updatedFactorId))?.factors.find((factor) => factor.id === updatedFactorId);
  }

  replaceFactor(factorGroups: FactorGroup[], id: string, updatedFactor: Factor): FactorGroup[] {
    factorGroups.forEach((group) => {
      const factorIndex = group.factors.findIndex((factor) => factor.id === id);
      if (factorIndex !== -1) {
        group.factors[factorIndex] = updatedFactor;
      }
    });
    return factorGroups;
  }

  loadFactorGroups(removeFilter = false, updatedFactorId?: string): void {
    this.ValuepropService.valuePropGetFactorGroups(this.valueProp.id, 1, 0)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.result.has_factor_groups) {
          if (removeFilter) {
            this.selected_factor_groups = res.result.factor_groups;
            this.allFactorGroups = true;
            this.factorGroups = res.result.factor_groups;
            this.factorGroupsCache = res.result.factor_groups;
          } else if (updatedFactorId) {
            const updatedFactorIds = this.checkNonUserInputUpdate(res.result.factor_groups);
            updatedFactorIds.forEach((id) => {
              const foundResFactor = this.findGroupById(res.result.factor_groups, id);
              if (foundResFactor) {
                this.selected_factor_groups = this.replaceFactor(this.selected_factor_groups, id, foundResFactor);
                this.factorGroups = this.replaceFactor(this.factorGroups, id, foundResFactor);
                this.factorGroupsCache = this.factorGroups;
              }
            });
          } else {
            this.selected_factor_groups = res.result.factor_groups.filter((resGroup) => this.selected_factor_groups.some((group) => group.id === resGroup.id));
            this.factorGroups = res.result.factor_groups;
            this.factorGroupsCache = this.factorGroups;
          }
          let count = 0;
          this.factorGroups.forEach((elem) => {
            elem.color = this.chartColors[count];
            count++;
            elem.factors = this.markFactorData(elem.factors);
            if (count > this.chartColors.length) {
              count = 0;
            }
          });
        } else {
          this.modelsV2 = false;
        }
        if (!isEmpty(this.globalSearch)) {
          this.filterAssumptions(this.globalSearch);
        }
      });
  }

  loadKeyAssumptions(): void {
    this.loading = true;
    this.ValuepropService.getKeyAssumptions(this.valueProp.id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.drivers = response.result.drivers.map((elem) => {
            elem.type = 'driver';
            elem.updated = false;
            elem.isTdData = elem.is_telemetry_data === '1';
            return elem;
          });
          this.financials = response.result.financials.map((elem) => {
            elem.type = 'financial';
            elem.updated = false;
            elem.isTdData = elem.is_telemetry_data === '1';
            return elem;
          });

          this.improvements = response.result.improvements.map((elem) => {
            elem.type = 'improvement';
            elem.updated = false;
            elem.isTdData = elem.is_telemetry_data === '1';
            return elem;
          });

          this.scratchpadUnits = response.result.units.map((unit) => {
            unit['operand_value'] = Number(unit['operand']).toLocaleString();
            unit.type = 'scratch';
            unit.updated = false;
            unit.isTdData = unit.is_telemetry_data === '1';
            return unit;
          });
          this.ogScratchpadUnits = response.result.units.map((unit) => {
            unit['operand_value'] = Number(unit['operand']).toLocaleString();
            unit.isTdData = unit.is_telemetry_data === '1';
            return unit;
          });
          this.allKeyAssumptions = [...this.drivers, ...this.financials, ...this.scratchpadUnits, ...this.improvements];
          this.unfilteredAllKeyAssumptions = this.allKeyAssumptions;
        }
        this.loading = false;
      });
  }

  loadDrivers(): void {
    let searchLimit = this.valueProp.id;
    this.driversLoader = true;
    this.drivers = [];
    this.ValuepropService.getDrivers(searchLimit)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.drivers = this.markFactorData(response.result.drivers);
          this.ogdrivers = response.result.drivers;
        }
        this.driversLoader = false;
      });
  }

  loadFinancials(): void {
    let searchLimit = this.valueProp.id;
    this.financialsLoader = true;
    this.financials = [];
    this.ValuepropService.getFinancials(searchLimit)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.financials = this.markFactorData(response.result.financials);
          this.ogfinancials = response.result.financials;
        }
        this.financialsLoader = false;
      });
  }

  loadScratchpadUnits(): void {
    let searchLimit = this.valueProp.id;
    this.scratchpadUnitsLoader = true;
    this.scratchpadUnits = [];
    this.ValuepropService.getScartchpadUnits(searchLimit)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.units) {
          this.scratchpadUnits = this.markFactorData(response.result.units).map((unit) => {
            unit['operand_value'] = Number(unit['operand']).toLocaleString();
            return unit;
          });
          this.ogScratchpadUnits = response.result.units.map((unit) => {
            unit['operand_value'] = Number(unit['operand']).toLocaleString();
            return unit;
          });
        }

        this.scratchpadUnitsLoader = false;
      });
  }

  editDriverValue(driver: AssumptionDriver): void {
    let payload = {
      driver_factor_id: driver.driver_factor_id,
      driver_override: driver.driver_value,
      driver_source_type_id: driver.driver_source_type_id,
      value_prop_metric_id: driver.value_prop_metric_id,
    };

    this.ValuepropService.updateDriverFactor(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.drivers = response.result.value_prop_drivers;
          if (this.keyAssumptions) {
            this.loadKeyAssumptions();
          } else {
            this.initialize();
          }
          this.NotificationService.success(this.trans.trans.driver_update_success.value, false);
          this.ValuepropService.refreshDashboard.next();
        } else if (response.result.success === false) {
          this.NotificationService.error(response.result.message, false);
        }
      });
  }

  editFinancialValue(financial: AssumptionFinancial) {
    let payload = {
      financial_factor_id: financial.financial_factor_id,
      financial_source_type_id: financial.financial_source_type_id,
      financial_override: financial.financial_value,
    };

    this.ValuepropService.updateFinancialFactor(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.financials = response.result.financials;
          if (this.keyAssumptions) {
            this.loadKeyAssumptions();
          } else {
            this.initialize();
          }
          this.NotificationService.success(this.trans.trans.financial_update_success.value, false);
        } else if (response.result.success === false) {
          this.NotificationService.error(response.result.message, false);
        }
      });
  }

  editScratchpadUnit(unit: AssumptionScratchpad, rowIndex: number): void {
    let payload = {
      factor_id: unit.factor_id,
      operand: unit.operand,
      operator_id: unit.operator_id,
    };

    unit.showeditable = !unit.showeditable;
    this.ValuepropService.updateScratchpadUnit(this.valueProp.id, payload)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.scratchpadUnits[rowIndex] = {
            ...this.scratchpadUnits[rowIndex],
            operand_value: unit.operand,
            operand: unit.operand,
            operand_override: unit.operand,
          };
          this.NotificationService.success(this.trans.trans.scratchpad_units_update_success.value, false);
        } else if (response.result.success === false) {
          this.NotificationService.error(response.result.message, false);
        }
      });
  }

  getSourceTypes(): void {
    this.ValuepropService.getSourceTypes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.sourceTypes = response.result;
        }
      });
  }

  assumptionsBatchEditMode(): void {
    this.assumptionsEditing = !this.assumptionsEditing;
  }

  assumptionsCancelEdit(): void {
    this.assumptionsEditing = false;
    this.isAssumptionUpdated = false;
    this.assumptionSaveLoader = false;
  }

  funcExpand(expand?: boolean) {
    this.expandAll = !this.expandAll;
    this.expandDrivers = expand ? expand : !this.expandDrivers;
    this.expandFinancial = expand ? expand : !this.expandFinancial;
    this.expandScratchpads = expand ? expand : !this.expandScratchpads;
  }

  scroll(el: string) {
    var box = document.querySelector('.container_interior'),
      targetElm = document.querySelector('#' + el);
    targetElm.scrollIntoView({ behavior: 'smooth' });
  }

  updateAll(resstep?: 'no emit'): Promise<boolean> {
    this.assumptionSaveLoader = true;
    const noEmit = resstep === 'no emit';
    let factors = [];
    this.factorGroups.forEach((elem) => {
      elem.factors.forEach((factor) => {
        if (factor.updated) {
          factors.push({
            account_factor_id: factor.account_factor_id,
            source_type_id: factor.source_type_id,
            value: factor.value,
          });
        }
      });
    });
    if (this.modelsV2 && (this.view == 'simple' || this.view === 'share')) {
      let keyAssumptionsUpdated = this.allKeyAssumptions.reduce((acc, curr) => {
        let field = curr.type === 'financial' ? 'financial_' : curr.type === 'driver' ? 'driver_' : 'operand_';

        if (curr.updated) {
          acc.push({
            account_factor_id: curr[(curr.type === 'scratch' ? '' : field) + 'factor_id'],
            source_type_id: curr[field + 'source_type_id'],
            value: curr[curr.type === 'scratch' ? 'operand' : field + 'value'],
          });
        }
        return acc;
      }, []);
      factors = factors.concat(keyAssumptionsUpdated);
      let payload = {
        factors: factors,
      };

      if (this.view === 'share') {
        return new Promise((resolve) => {
          if (factors.length) {
            this.ValuepropService.putRepSimpleFactorGroups(this.valueProp.id, payload)
              .pipe(take(1))
              .subscribe(() => {
                this.resetUpdatedStatus();
                this.assumptionSaveLoader = false;
                this.sI.getValuePropDetails();
                this.NotificationService.success(this.trans.trans.scratchpad_units_update_success.value, false);
                if (!noEmit) {
                  this.callbackNext.emit();
                } else {
                  this.loadFactorGroups();
                }
                resolve(true);
              });
          } else {
            this.assumptionSaveLoader = false;
            if (!noEmit) {
              this.callbackNext.emit();
            }
            resolve(true);
          }
        });
      }

      if (factors.length) {
        return new Promise((resolve) => {
          this.ValuepropService.putRepSimpleFactorGroups(this.valueProp.id, payload)
            .pipe(take(1))
            .subscribe(() => {
              this.resetUpdatedStatus();
              this.assumptionSaveLoader = false;
              this.NotificationService.success(this.trans.trans.values_update_success.value, false);
              this.loadFactorGroups();
              if (!noEmit) {
                this.callbackNext.emit();
              }
              resolve(true);
            });
        });
      } else {
        this.assumptionSaveLoader = false;
        if (!noEmit) {
          this.callbackNext.emit();
        }
      }
    } else {
      return new Promise((resolve) => {
        this.assumptionSaveLoader = true;
        let payload;

        let drivers = this.drivers.filter((x) => x.updated == true);
        factors.push(...drivers);

        let financials = this.financials.filter((x) => x.updated == true);
        factors.push(...financials);

        let improvements = this.improvements.filter((x) => x.updated == true);
        factors.push(...improvements);

        let scratchpads = this.scratchpadUnits.filter((x) => x.updated == true);
        factors.push(...scratchpads);

        if (factors.length) {
          payload = { factors: factors };
          this.ValuepropService.updateAllFactors(this.valueProp.id, payload)
            .pipe(take(1))
            .subscribe((res) => {
              this.assumptionSaveLoader = false;

              this.ValuepropService.refreshDashboard.next('no_reload');
              if (res.result.success) {
                this.assumptionsEditing = false;
                this.isAssumptionUpdated = false;
                this.assumptionSaveLoader = false;
                if (!noEmit) {
                  this.callbackNext.emit();
                }
                this.NotificationService.success(this.trans.trans.values_update_success.value, false);
                resolve(true);
              } else {
                this.assumptionsEditing = false;
                this.isAssumptionUpdated = false;
                this.assumptionSaveLoader = false;
                if (!noEmit) {
                  this.callbackNext.emit();
                }
                this.NotificationService.error(res.result.message, false);
                resolve(true);
              }
            });
        } else {
          this.assumptionSaveLoader = false;
          if (!noEmit) {
            this.callbackNext.emit();
          }
          resolve(true);
        }
      });
    }
  }

  filterAllTables(event) {
    this.tableAssumptionsDrivers.filter(event, 'driver_factor', 'contains');
    this.tableAssumptionsFinancial.filter(event, 'financial_factor', 'contains');
    this.tableAssumptionsScratchpads.filter(event, 'name', 'contains');
  }

  markFactorData(factors: any[]) {
    return factors.map((factor) => ({
      ...factor,
      isTdData: factor.is_telemetry_data === '1',
      allowNegative: factor.greater_than_zero !== '1',
    }));
  }

  resetUpdatedStatus() {
    if (this.modelsV2) {
      return this.factorGroups.map((fg) =>
        fg.factors.forEach((f) => {
          if (f.updated) {
            f.updated = false;
          }
        })
      );
    } else {
      return this.allKeyAssumptions.forEach((ka) => {
        if (ka.updated) {
          ka.updated = false;
        }
      });
    }
  }

  onFactorClick(elem: any): void {
    this.templateSetting = '1';
    this.valueMetricId = '0';
    this.valueMetric.driver_factor_id = elem.id;
    this.showFactor = true;
    this.selectedFactor.id = elem.id;
  }

  onDriverFactorCalcClick(elem: any): void {
    this.templateSetting = '1';
    this.valueMetricId = '0';
    this.valueMetric.driver_factor_id = elem.driver_factor_id;
    this.showFactor = true;
    this.selectedFactor.id = elem.driver_factor_id;
  }

  onFinancialFactorCalcClick(elem: any): void {
    this.templateSetting = '2';
    this.valueMetricId = '0';
    this.valueMetric.financial_factor_id = elem.financial_factor_id;
    this.showFactor = true;
    this.selectedFactor.id = elem.financial_factor_id;
  }

  private checkNonUserInputUpdate(newValues: FactorGroup[]): string[] {
    const ids: string[] = [];
    this.factorGroupsCache.forEach((group) => {
      const resGroup = newValues.find((rGroup) => rGroup.id === group.id);
      group.factors.forEach((factor) => {
        const resFactor = resGroup?.factors.find((rFactor) => rFactor.id === factor.id);
        if (resFactor && factor.value !== resFactor.value) {
          ids.push(String(factor.id));
        }
      });
    });
    return ids;
  }

  sourceChanged(elem: AssumptionDriver | AssumptionFinancial | AssumptionScratchpad) {
    elem.updated = true; 
    this.isAssumptionUpdated = true

    // Since we are now keying off the display source field, we need to set the actual source
    let source_field = '';
    let display_source_field = '';
		switch (elem.type) {
			case 'driver':
				source_field = 'driver_source_type_id';
        display_source_field = 'driver_display_source_type_id';
				break;
      case 'financial':
        source_field = 'financial_source_type_id';
        display_source_field = 'financial_display_source_type_id';
        break;
      case 'scratch':
        source_field = 'operand_source_type_id';
        display_source_field = 'operand_display_source_type_id';
        break;
			default:
				break;
		}

    let newDisplaySource = elem[display_source_field];
    let currentSource = elem[source_field];

    if ( currentSource === '5' && newDisplaySource === '1' ) {
      // Current source is situation projected, new source is projected
      // We need to keep the underlying source as situation projected
      elem[source_field] = '5';
    } else {
      elem[source_field] = newDisplaySource;
    }
	}
}
