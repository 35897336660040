import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DefaultTranslations } from 'app/main-dashboard/dashboard/dashboard.translation';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TreeTableModule } from 'primeng/treetable';
import { AEMaintenanceSituationsComponent } from './ae_situations.component';
import { InputSwitchModule } from 'primeng/inputswitch';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AEMaintenanceSituationsComponent],
  imports: [
    ProgressBarModule,
    MatTooltipModule,
    NgbModule,
    TreeTableModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    MenuModule,
    SidebarModule,
    TableModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    AreYouSureModule,
    EditTranslationsModule,
    InputSwitchModule,
  ],
  providers: [DefaultTranslations, TranslationsV2Service],
  entryComponents: [AEMaintenanceSituationsComponent],
  exports: [AEMaintenanceSituationsComponent],
})
export class AEMaintenanceSituationsModule {}
