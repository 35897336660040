import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Customer } from '@shared/models/customer.model';
import { Share } from '@shared/models/share.models';
import { ValueProp } from '@shared/models/value-prop.model';
import { Shares } from 'app/value-map/solution-list/share/models/share.model';
import { User } from 'app/value-prop/value-prop-dashboard/sharev2/share.component';
import { MenuItem } from 'primeng/api/menuitem';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareComponent implements OnInit, OnDestroy {
  @Input('data') valueProp: ValueProp;
  @Input() showHelp: boolean;
  @Input() embedded = false;

  @Input() showTranslate = false;
  @Output() funcCallback = new EventEmitter();
  @Output() callbackBack = new EventEmitter();

  fullImagePath: string;
  sharesLoader = false;
  newCustomerLoader = false;
  existingCustomerLoader = false;
  shares: Share[] = [];
  addShare = false;
  users: User[];
  shareRoles: { id: string; name: string }[] = [];
  share: Partial<Shares>;
  currentJustify = 'center';
  selectedGuest: string;
  guestList: Array<Customer> = [];
  activeIdString = 'Existing_Customer';
  customer = {
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    account_name: '',
  };
  type = 'add';
  user_name: string;
  modalReference: NgbModalRef;
  hideValuePropActionLoader = true;

  menuTop: MenuItem[] = [];
  view = '';

  dropdownSettings: {
    singleSelection: boolean;
    text: string;
    enableCheckAll: boolean;
    enableSearchFilter: boolean;
    labelKey: string;
    searchBy: string[];
    primaryKey: string;
    badgeShowLimit: number;
    lazyLoading: boolean;
  };

  ngUnsubscribe = new Subject();

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public trans: Translations,
    private RepSimpleService: RepSimpleViewService
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    this.fullImagePath = this.commonService.getLoaderImageUrl();
  }

  ngOnInit(): void {
    this.view = 'share';
    this.menuTop.push({
      label: this.trans.vp_dashboard.share.view,
      command: () => {
        this.view = 'share';
      },
    });
    this.menuTop.push({ separator: true });
    this.menuTop.push({
      label: this.trans.vp_dashboard.share.share_with,
      command: () => {
        this.view = 'exitingcustomer';
      },
      items: [
        {
          label: this.trans.vp_dashboard.share.existing_customer,
          command: () => {
            this.view = 'exitingcustomer';
          },
        },
        {
          label: this.trans.vp_dashboard.share.new_customer,
          command: () => {
            this.view = 'newcustomer';
          },
        },
      ],
    });

    this.menuTop.push({ separator: true });
    this.menuTop.push({
      label: 'Add internal share',
      command: () => {
        this.view = 'add';
        this.openAddShare();
      },
    });

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select User',
      enableCheckAll: false,
      enableSearchFilter: true,
      labelKey: 'name',
      searchBy: ['name'],
      primaryKey: 'id',
      badgeShowLimit: 3,
      lazyLoading: false,
    };

    this.sharesLoader = true;
    this.loadShares();
    this.getGuestList();

    this.RepSimpleService.saveChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.funcCallback.emit(res);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadShares(): void {
    const searchLimit = this.valueProp.id;
    this.sharesLoader = true;
    this.valuepropService
      .getValuePropShares(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.shares = response.result.shares.filter((item) => {
            return item.share_role_type_id !== '5';
          });
          this.sharesLoader = false;
        }
      });
  }
  openAddShare(): void {
    this.view = 'add';
    this.addShare = true;
    this.type = 'add';
    this.share = {
      share_id: '',
      share_role_type_id: '',
    };
    const account_id = sessionStorage.getItem('aid');
    this.valuepropService
      .getUsers(account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.users = response.result.data;
        }
      });
    this.valuepropService
      .getShareRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.shareRoles = response.result.filter((item) => {
            return item.id !== '5';
          });
        }
      });
  }
  closeAddShare(): void {
    this.addShare = false;
  }

  saveShare(type): void {
    if (this.type === 'add') {
      if (type === 'customer') {
        this.existingCustomerLoader = true;
        this.valuepropService
          .createCustomerShare(this.valueProp.id, this.selectedGuest)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            if (response.result && response.result.success !== false) {
              this.existingCustomerLoader = false;
              this.loadShares();
              this.view = 'share';
            }
          });
      } else {
        this.valuepropService
          .saveValuePropShare(this.valueProp.id, this.share)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            if (response.result && response.result.success !== false) {
              this.closeAddShare();
              if (type === 'customer') {
                this.existingCustomerLoader = false;
                this.view = 'share';
              }
              if (response.result.success) {
                this.notificationService.success('Shared Successfully', false);
              }

              if (response.result.value_prop_share.share_role_type_id == '4') {
                this.valuepropService.refreshDashboard.next('refreshDashboard');
              }
            } else if (response.result.success === false) {
              this.existingCustomerLoader = false;
              this.notificationService.error(response.result.message, false);
            }
            this.loadShares();
          });
      }
    } else if (this.type === 'edit') {
      this.valuepropService
        .updateShare(this.valueProp.id, this.share)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result && response.result.success !== false) {
            if (response.result && response.result.value_prop_share && response.result.value_prop_share.share_role_type_id == '4') {
              this.valuepropService.refreshDashboard.next('refreshDashboard');
            }
            this.view = 'share';
            this.notificationService.success('Updated Successfully', false);
          } else if (response.result.success === false) {
            this.existingCustomerLoader = false;
            this.notificationService.error(response.result.message, false);
          }
          this.loadShares();
        });
    }
  }

  onTabChange(event): void {
    if (event.nextId === 'Existing_Customer') {
      this.getGuestList();
    }
  }

  getGuestList() {
    const account_id = sessionStorage.getItem('aid');
    this.accountService
      .getExistingCustomers(account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result['guests']) {
          this.guestList = response.result['guests'];
        }
      });
  }

  addExistingCustomer(): void {
    this.share = {
      share_id: this.selectedGuest['share_id'],
      share_role_type_id: '4',
    };

    this.saveShare('customer');
  }

  addNewCustomer(): void {
    this.newCustomerLoader = true;

    this.valuepropService
      .addNewCustomer(this.valueProp.id, this.customer)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success) {
          this.notificationService.success('Customer Created Successfully', false);

          this.getGuestList();
          this.newCustomerLoader = false;
          this.view = 'share';
        } else if (response.result && !response.result.success && response.result.errors) {
          this.newCustomerLoader = false;
          this.notificationService.error(response.result.errors.reason, false);
          this.view = 'share';
        } else if (response.result.success === false) {
          this.newCustomerLoader = false;
          this.notificationService.error(response.result.message, false);
          this.view = 'share';
        }
        this.getGuestList();
        this.loadShares();
      });
  }

  editShareValueProp(share): void {
    this.openAddShare();
    this.type = 'edit';
    this.user_name = share.user_name;
    this.share = {
      share_id: share.id,
      share_role_type_id: share.share_role_type_id,
    };
  }

  open(content, id): void {
    this.share = {
      share_id: '',
      share_role_type_id: '',
    };
    this.share['share_id'] = id;
    this.modalReference = this.modalService.open(content, {
      windowClass: 'deleteModal secondLayerModalWindow',
      backdropClass: 'secondLayerModalBackdrop',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }

  deleteValuePropUser(): void {
    this.hideValuePropActionLoader = true;
    this.valuepropService
      .deleteShareUser(this.valueProp.id, this.share.share_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success(response.result.message, false);
          this.loadShares();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.modalReference.close();
      });
  }
}
