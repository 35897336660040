import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader } from '@ngx-translate/core';
import { CaseStudyTagsModule } from 'app/value-mapv2/solution-detail/case-studies/tags/tags.module';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ModelBenefitsCaseStudyModule } from '../benefits-casestudy/benefits-casestudy.module';
import { ModelQuoteModule } from '../quote/quote.module';
import { ModelSectionsModule } from '../sections/sections.module';
import { CasestudyDetailComponent } from './casestudy-detail.component';
import { CasestudyDetailTranslations } from './casestudy-detail.translation';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  declarations: [CasestudyDetailComponent],
  imports: [
    CaseStudyTagsModule,
    ModelQuoteModule,
    ModelBenefitsCaseStudyModule,
    ModelSectionsModule,
    ModelQuoteModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
  ],
  providers: [TranslationsV2Service, CasestudyDetailTranslations],
  entryComponents: [CasestudyDetailComponent],
  exports: [CasestudyDetailComponent],
})
export class ModelCaseStudyDetailModule {}
