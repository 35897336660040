import { Injectable } from '@angular/core';

@Injectable ()

export class TeamTranslation {

    public config: any = {
        component: 'teamComponent',
        display_name: 'Team Component',
        description: 'Team section in profile'
    };

    public trans: any = {
        heading: 'Your Team',
        your_team: 'Your Team \n Mange your team here',
        edit: 'Edit'
    };
}
