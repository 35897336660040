<p-menu #menuStatus appendTo="body" [popup]="true" [model]="valuePropStatusTypes" styleClass="style-2022"></p-menu>

<ng-container *ngIf="!showLocked; else lockedTemplate">
  <div class="row flex-jend created-margin">
    <div class="col-md-3 col-sm-6 col-xs-12">
      <h1 class="mr-0 padding-10-y">
        <ng-container *ngIf="isNotSalesForce">
          <span class="breadcrumb-title clickable" routerLink="/dashboard">
            {{ trans.trans.dashboard.value }}
          </span>
          <span class="breadcrumb-title marg-h">></span>
        </ng-container>
        <span class="breadcrumb-title-active clickable">
          {{ isHypothesis ? trans.trans.hypothesis.value : trans.trans.businessCase.value }}
        </span>
      </h1>
    </div>
  </div>
</ng-container>

<ng-template #lockedTemplate>
  <div class="flex-jbetween mr-2">
    <div class="status-container">
      <h1 class="breadcrumb-title-active mr-0 clickable padding-10-y" (click)="menuStatus.toggle($event)" [matTooltip]="valueProp.vp_status" matTooltipPosition="below">
        {{ valueProp.vp_status }}
      </h1>
      <span class="fa fa-chevron-down marg-h padding-10-y clickable" (click)="menuStatus.toggle($event)"></span>
    </div>
    <span class="lock-background">
      <h1 class="breadcrumb-title mr-0 clickable" [matTooltip]="locked ? trans.trans.unlock.value : trans.trans.lock.value" (click)="lockValueProp()">
        <i class="lock-icon" [ngClass]="{ 'icon-lock red-color': locked, 'icon-lock-open green-color': !locked }"></i>
      </h1>
    </span>
  </div>
</ng-template>
