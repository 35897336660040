<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header_small'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>
						{{ trans.trans.changePrivilege.value }}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.changePrivilege" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
			</div>
		</div>
		<form name="PrivilegeUpdateForm" #PrivilegeUpdateForm="ngForm" (ngSubmit)="updatePrivilege()">
			<div class='row'>
				<div class="col-xs-12">
					<div class="form-group" [ngClass]="{'has-error': (PrivilegeUpdateForm.controls.privilegeList?.invalid && PrivilegeUpdateForm.controls.privilegeList?.touched)}">
						<label for="privilege" class="col-sm-3 control-label labelsRequired">{{ trans.trans.selectPrivilege.value }}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
							[transObj]="trans.trans.selectPrivilege" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<p-multiSelect class="keywest_dropdown" [options]="privilegeList" [(ngModel)]="selectedPrivil" name="privilegeList"></p-multiSelect>
					</div>
				</div>
			</div>
			<div class='row' style='margin-bottom: 20px;'>
				<div class="col-xs-12">
					<button *ngIf="userAdmin.length === 0" type="submit" class="btn btn_customizable pull-left" [disabled]="PrivilegeUpdateForm.invalid">{{ trans.trans.requestChange.value }}</button>
					<button *ngIf="userAdmin.length > 0" type="submit" class="btn btn_customizable pull-left" [disabled]="PrivilegeUpdateForm.invalid">{{ trans.trans.save.value }}</button>
					<img class="loader" *ngIf="showActionLoader" src="{{fullImagePath}}" />
				</div>
			</div>
		</form>
	</div>
</div>