import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class ValidationTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'Validation',
    display_name: 'Validation',
    description: 'Validation for benefits and factors',
  };

  public trans = this.typeObjectKeys({
    benefits: 'Benefits',
    factors: 'Factors',
    checkMissing: 'Check For Benefits With Missing:',
    fuctionalObjectives: 'Functional Objectives',
    operationalObjectives: 'Operational Objectives',
    capabilities: 'Capabilities',
    painPoints: 'Pain Points',
    featuresCapabilities: 'Features',
    benefitName: 'Factor Name',
    benefitsWithDupeNames: 'Benefits With Duplicated Names',
    factorName: 'Factor Name',
    value: 'Value',
    issue: 'Issue',
    scratchpadValue: 'Scratchpad Value',
    modelDefaultValue: 'Model Default Value',
    factorsWithInconsistentValues: 'Factors With Inconsistent Values',
    effectedBenefits: 'Effected Benefits:',
    factorDefaultMismatchScratchpad: 'Factors Where Default Value Does Not Match Scratchpad Value',
    factorsWithBrokenScratchpads: 'Factors With Broken Scratchpads',
    factorValueOf0: 'Factors With A Value Of 0',
    scaleFactorNoDefault: 'Scale Factors With No Default Value',
    enterValue: 'Enter Value',
    save: 'Save',
    cancel: 'Cancel',
    benefitsWithDupeNamesTT: 'You have two or more benefits with the same name. Ensure that this is correct to ensure the most accurate Value Proposition.',
    benefitMissingFOs: 'Benefits With Missing Functional Objectives',
    benefitMissingFOsTT:
      'This benefit is missing functional objectives, filling this out will allow you to sort your benefits by this category and provide more robust detail to your Value Proposition.',
    benefitMissingOOs: 'Benefits With Missing Operational Objectives',
    benefitMissingOOsTT:
      'This benefit is missing operational objectives, filling this out will allow you to sort your benefits by this category and provide more robust detail to your Value Proposition.',
    benefitMissingCapabilities: 'Benefits With Missing Capabilities',
    benefitMissingCapabilitiesTT:
      'This benefit is missing capabilities, filling this out will allow you to sort your benefits by this category and provide more robust detail to your Value Proposition.',
    benefitMissingPainPoints: 'Benefits With Missing Pain Points',
    benefitMissingPainPointsTT:
      'This benefit is missing pain points, filling this out will allow you to sort your benefits by this category and provide more robust detail to your Value Proposition.',
    benefitMissingFeatures: 'Benefits With Missing Features',
    benefitMissingFeaturesTT:
      'This benefit is missing features, filling this out will allow you to sort your benefits by this category and provide more robust detail to your Value Proposition.',
    inconsistentValuesTT:
      'Factors listed have different values across different benefits. You may set a single value for this factor across all benefits in this model. Ensure that you save.',
    valueOf0TT: 'The factors listed have a value of 0. Enter a value and save to set a new default value.',
    noDefaultValueTT: 'The factors listed are scale factors without a default value. Enter a value and save to set a new default value.',
    updatesMadeSuccessful: 'Updates made successfully',
    acknowledged: 'Acknowledged',
  });
}
