import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';

import { AdminMaturityComponent } from './maturity.component';

import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeTableModule } from 'primeng/treetable';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule } from 'primeng/progressbar';
import { CXOService } from '@data/services/cxo/cxo.service';
import { AEMaturityModule } from './ae_maturity/ae_maturity.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
	{
		path: 'maturity',
		component: AdminMaturityComponent
	}
];

@NgModule({
	imports: [
		AreYouSureModule,
		AEMaturityModule,
		ProgressBarModule,
		SidebarModule,
		InputSwitchModule,
		TreeTableModule,
		MultiSelectModule,
    MatTooltipModule,
		EditTranslationsModule,
		TableModule,
		NgbModule,
		CommonModule,
		FormsModule,
		RouterModule.forChild(routes),
	],
	declarations: [
		AdminMaturityComponent
	],
	providers: [
		CXOService,
		TranslationsV2Service
	],
	entryComponents: [AdminMaturityComponent],
	exports: [AdminMaturityComponent]
})
export class AdminMautiryModule { }
