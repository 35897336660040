import { Injectable } from '@angular/core';

@Injectable ()

export class BenefitsMenuTranslations {

    public config: any = {
        component: 'benefitsMenu',
        display_name: 'Benefits Menu',
        description: 'Menu for list of benefits'
    };

    public trans: any = {
        search: 'Search',
        action: 'Actions',
        filter: 'Filter',
        sort: 'Sort',
        group: 'Group',
        discovery_questions: 'Discovery Questions',
        all: 'All',
        addNew: 'Add New',
        actions: 'Actions'
    };
}
