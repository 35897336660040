<div class='models_panel' *ngIf="loaded">
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-9'>
					<div class='title'>
						{{valueRealization.value_prop.name}}
					</div>
				</div>
				<div class='col-xs-3'>
					<button *ngIf="location == 'Templates' && isAccountAdmin" class="btn btn_customizable pull-right" (click)="createSurveyTemplate()"><i class="fa fa-plus"></i>{{trans.trans.createSurveyQuestions.value}}</button>
					<button *ngIf="menuView === 'survey' && locationTab !== 'casestudy'" class="btn btn_customizable pull-right" [matTooltip]="trans.trans.addValueRealized.value" matTooltipPosition="above" (click)="createSurvey()"><i class="fa fa-plus"></i>{{trans.trans.addSurvey.value}}</button>
					<button *ngIf="menuView === 'case studies' && locationTab !== 'casestudy'" class="btn btn_customizable pull-right" [matTooltip]="trans.trans.addCasestudy.value" matTooltipPosition="above" (click)="openAddCaseStudy()"><i class="fa fa-plus"></i>{{trans.trans.addCaseStudy.value}}</button>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>

				<dl-menubar styleClass='details_menu_bar' class='details_menu_bar' [model]="menuItems"></dl-menubar>

				<div *ngIf="menuView === 'survey' && locationTab !== 'casestudy'">
					<app-survey [activeSurveyTemplate]="activeSurveyTemplate"></app-survey>
				</div>

				<div *ngIf="menuView === 'case studies' && locationTab !== 'casestudy'">
					<div class="case-studies-wrapper" *ngIf="valueRealization['value_prop']">
						<app-case-studies-realizations [companyId]="valueRealization['value_prop']['company_id']"></app-case-studies-realizations>
					</div>
				</div>

				<div *ngIf="locationTab == 'casestudy'" id="casestudy">
					<div class="case-studies-wrapper">
						<div (click)="casestudiesList()" role="button" class="m-b-xxl m-l-xxl">
							<i class="fa fa-chevron-left"></i> {{trans.trans.back.value}}
						</div>
						<app-casestudy-detail [caseStudyId]="caseStudyId" [type]="'valueprop'" [caseStudy]="caseStudy"></app-casestudy-detail>
					</div>
				</div>

				<!-- <ngb-tabset #t="ngbTabset" [activeId]="locationTab">
					<ngb-tab id="survey">
						<ng-template ngbTabTitle>
							<span [matTooltip]="trans.trans.measurePerceptionTime.value" matTooltipPosition="above">{{trans.trans.surveys.value}}</span>
						</ng-template>
						<ng-template ngbTabContent>
							<div class="app-survey-wrapper">
								<app-survey [activeSurveyTemplate]="activeSurveyTemplate"></app-survey>
							</div>
						</ng-template>
					</ngb-tab>
					<ngb-tab *ngIf="locationTab != 'casestudy'" id="casestudies">
						<ng-template ngbTabTitle>
							<span [matTooltip]="trans.trans.captureImprovements.value" matTooltipPosition="above">{{trans.trans.caseStudies.value}}</span>
						</ng-template>
						<ng-template ngbTabContent>
							<div class="case-studies-wrapper" *ngIf="valueRealization['value_prop']">
								<app-case-studies [companyId]="valueRealization['value_prop']['company_id']"></app-case-studies>

							</div>
						</ng-template>
					</ngb-tab>
					<ngb-tab *ngIf="locationTab == 'casestudy'" id="casestudy">
						<ng-template ngbTabTitle>
							<span [matTooltip]="trans.trans.captureImprovements.value" matTooltipPosition="above">{{trans.trans.caseStudies.value}}</span>
						</ng-template>
						<ng-template ngbTabContent>
							<div class="case-studies-wrapper">
								<div (click)="casestudiesList()" role="button" class="m-b-xxl m-l-xxl">
									<i class="fa fa-chevron-left"></i> {{trans.trans.back.value}}
								</div>
								<app-casestudy-detail [caseStudyId]="caseStudyId" [type]="'valueprop'" [caseStudy]="null"></app-casestudy-detail>
							</div>
						</ng-template>
					</ngb-tab>
				</ngb-tabset> -->
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddCaseStudy' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-add-casestudy-realization *ngIf="toggleAddCaseStudy && valuepropId" (callback)='closeAddCaseStudy()' [companyID]='companyID' [valuepropId]='valuepropId' [solutions]='solutions'></app-add-casestudy-realization>
</p-sidebar>
<div *ngIf="!loaded" class="col-md-12 text-center m-t-xl">
	<img class="loader" [src]="fullImagePath" />
</div>