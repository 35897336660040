import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactorsService } from '@data/services/factors/factors.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OperatorTypeList, Parenthesis, ScratchpadDefault, ScratchpadFields } from '../interfaces/factors.interface';
import { Items } from '../interfaces/items.interface';
import { DefaultTranslations } from './r_scratchpads.translation';

@Component({
  selector: 'app-r-scratchpads',
  templateUrl: './r_scratchpads.component.html',
})
export class RScratchpadsComponent implements OnInit, OnDestroy {
  @Output() callback = new EventEmitter();
  @Input() factor: Factor;
  sidebar_title = 'View Scratchpad';
  operatorTypesList: OperatorTypeList = [];
  has_scratchpad = false;
  newFactor = '';
  driverScratchpadFactor_default: ScratchpadDefault = {};
  mode = 'add';
  ngUnsubscribe$ = new Subject();
  parenthesis: Parenthesis[] = [
    { name: 'openParen', sign: '(' },
    { name: 'closeParen', sign: ')' },
  ];

  driverScratchpadFields: ScratchpadFields[] = [
    {
      id: 1,
      factor: [],
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: 0,
    },
  ];

  addScratchPadError: string;
  benefitLoader: boolean;

  OpenParen_default: string;
  CloseParen_default: string;
  driverScratchpadFactorValue_default: string;
  templateSetting: string;
  account_id: string;
  scaledBy: Items = [];
  showTranslate = false;

  constructor(
    private factsService: FactsService,
    private factorsService: FactorsService,
    private solutionService: SolutionService,
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service
  ) {}

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.has_scratchpad = this.factor.has_scratchpad == 1 ? true : false;

    this.getScratchpadOperatorTypes();
    this.getSolutionsScratchpad();
    this.getScaledBy();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }
  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getScratchpadOperatorTypes(): void {
    this.factsService.getScatchpadOperatorTypes().subscribe((response) => {
      this.operatorTypesList = response.result.filter((operator: any) => {
        return operator.id !== '5';
      });
    });
  }

  getScaledBy(): void {
    this.factorsService
      .getScaledBy(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.scaledBy = res && res.result && res.result.length ? res.result : [];
      });
  }

  driverScratchpadClose(params): void {
    this.driverScratchpadFields = this.driverScratchpadFields.map((driverField: any) => {
      if (driverField.id === params) {
        driverField.factor = [];
        driverField.operator = undefined;
        driverField.openParen = undefined;
        driverField.closeParen = undefined;
        driverField.operand = 0;
      }
      return driverField;
    });
    this.driverScratchpadFields = this.driverScratchpadFields.filter((driverField: any) => {
      return driverField.id !== params;
    });
  }

  addDriverScratchpad(): void {
    const idRandom = Math.floor(Math.random() * 1000);
    const driverFactor = {
      id: idRandom,
      factor: [],
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: 0,
    };
    this.driverScratchpadFields.push(driverFactor);
  }

  getSolutionsScratchpad(): void {
    const driverScratchpadFields = [];
    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    this.solutionService.getSolutionsScratchpads(typeId, this.factor.id, 0).subscribe((response) => {
      if (response.result.units) {
        for (let f = 0; f < this.scaledBy.length; f++) {
          const factor = this.scaledBy[f];
          if (factor.id == response.result.units[0].factor_id) {
            this.driverScratchpadFactor_default = factor;
          }
        }

        this.OpenParen_default = response.result.units[0].open_paren === '1' ? '(' : undefined;
        this.CloseParen_default = response.result.units[0].close_paren === '1' ? ')' : undefined;
        this.driverScratchpadFactorValue_default = response.result.units[0].operand;
        for (let i = 1; i < response.result.units.length; i++) {
          const j = i - 1;
          let out_factor;
          for (let f = 0; f < this.scaledBy.length; f++) {
            const factor = this.scaledBy[f];
            if (factor.id == response.result.units[i].factor_id) {
              out_factor = factor;
            }
          }
          const scratchpadInput = {
            id: response.result.units[i].id,
            factor: out_factor,

            operator: response.result.units[j].operator_id,
            openParen: response.result.units[i].open_paren === '1' ? '(' : undefined,
            closeParen: response.result.units[i].close_paren === '1' ? ')' : undefined,
            operand: response.result.units[i].operand,
          };
          driverScratchpadFields.push(scratchpadInput);
        }
        this.benefitLoader = false;
        this.driverScratchpadFields = driverScratchpadFields;
      } else {
        this.benefitLoader = false;
      }
    });
  }

  funcGetSign(id: string | number): string {
    const out = this.operatorTypesList.filter((x) => x['id'] == id);

    return out.length ? out[0]['name'] : '';
  }
}
