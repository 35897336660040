<form name="nameForm" [formGroup]="nameForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">{{ trans.trans.rename.value }}</div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-sm-12">
            <label> {{ trans.trans.name.value }} </label>
            <input class="form-control" formControlName="name" />
          </div>
        </div>
      </div>
    </div>
    <div class='btn_container'>
      <div class='row'>
        <div class='col-xs-12'>
          <button [disabled]="!nameForm.valid" class='btn btn_customizable pull-right' (click)="submitName()">{{ trans.trans.save.value }}</button>
          <app-are-you-sure matTooltipPosition="above" [message]='trans.trans.areYouSure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='cancel.emit()'></app-are-you-sure>
        </div>
      </div>
    </div>
  </div>
</form>
