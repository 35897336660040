
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ProblemVpDashTranslations } from './problem.translation';

@Component({
	selector: 'app-problem',
	templateUrl: './problem.component.html'
})
export class ProblemComponent implements OnInit, OnDestroy {
	@Input() valueProp: any;
	ngUnsubscribe = new Subject();
	showTranslate = false;

	constructor(
		private valuepropService: ValuepropService,
		private notificationService: NotificationService,
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: ProblemVpDashTranslations
	) {

	}

	ngOnInit() {

		  this.getTranslations();
		  this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		  });
		  this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		  });
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

	funcSaveQ1() {
		if (!this.valueProp.vmw_q_1) { return false; }
		let payload = { answer: this.valueProp.vmw_q_1 };
		this.valuepropService.putVMWareQ1(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.notificationService.success("Question Updated Successfully", false);
		});

	}
}