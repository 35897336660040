<label *ngIf="showLabel" class="sidebar_label" >{{ trans.trans.selectedLanguage.value }} {{ currentLang?.name }}</label>

<p-dropdown 
  [options]="languages" 
  optionLabel="name" 
  dataKey="id" 
  panelStyleClass="dropdown-item-font"
  appendTo="body"
  class="keywest_dropdown dropdown-theme" 
  (onChange)="onLangChange($event)" 
  [(ngModel)]="currentLang"
>
  <ng-template let-item pTemplate="selectedItem">
    <div class="country-flag">
      <span  *ngIf="flagsMap?.size > 0"> {{item?.id | countryFlag : flagsMap}} {{item.name}} </span>
    </div>
  </ng-template>

  <ng-template let-item pTemplate="item" >
    <div class="country-flag">
      <span  *ngIf="flagsMap?.size > 0"> {{item?.id | countryFlag : flagsMap}} {{item.name}} </span>
    </div>
  </ng-template>
</p-dropdown>