import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AddBenefitsComponentComponent } from './add-benefits-component.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { TranslateLoader } from '@ngx-translate/core';

import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { FieldsetModule } from 'primeng/fieldset';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DLListDetailModule } from '@shared_components/list_detail/list_detail.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AddBenefitVpDashboardTranslations } from './add-benefits.translation';
import { MatTooltipModule } from '@angular/material/tooltip';


export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		InputSwitchModule,
		EditTranslationsModule,
		FieldsetModule,
		ProgressBarModule,
		MenubarModule,
		TableModule,
		SidebarModule,
		CommonModule,
		FormsModule,
		AreYouSureModule,
		NgbModule,
		DropdownModule,
		MultiSelectModule,
		ApplicationPipesModule,
		DLListDetailModule,
    MatTooltipModule,
	],
	declarations: [
		AddBenefitsComponentComponent
	],
	providers: [
		TranslationsV2Service,
		AddBenefitVpDashboardTranslations
	],
	exports: [AddBenefitsComponentComponent]
})
export class AddBenefitsModule { }
