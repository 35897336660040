import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-add-value-category-modal',
  templateUrl: './add-value-category-modal.component.html',
  styleUrls: ['./add-value-category-modal.component.scss'],
})
export class AddValueCategoryModalComponent implements OnInit, OnDestroy {
  @Input() solutionId: number;

  categoryInfluences: Array<{ [klass: string]: any }> = [];
  factValueCategorys: Array<{ [klass: string]: any }> = [];
  factValueCategoryDropdown: Array<{ [klass: string]: any }> = [];
  factCategory: string;
  imageUrl: string;
  fullImagePath: string;
  categoryInfluence: { [klass: string]: any } = {};
  newCategory: string;
  newCategoryLoader = false;
  influenceLoader = false;
  toggleAddView = false;
  toggleFactView = false;
  dropdownSettings: { [klass: string]: any } = {};
  currentJustify = 'center';
  editFact = false;

  style2022$: Observable<boolean>;

  subscriptiongetFactValueCategories: Subscription;
  subscriptiongetAccountCategotires: Subscription;
  subscriptionsaveValueCategory: Subscription;
  subscriptionaddNewValueCategory: Subscription;

  constructor(
    private solutionService: SolutionService,
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private factsService: FactsService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select Value Category',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
    };

    this.getFactCategotires();
    this.getAccountCategotires();
    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.subscriptiongetFactValueCategories) {
      this.subscriptiongetFactValueCategories.unsubscribe();
    }
    if (this.subscriptiongetAccountCategotires) {
      this.subscriptiongetAccountCategotires.unsubscribe();
    }
    if (this.subscriptionsaveValueCategory) {
      this.subscriptionsaveValueCategory.unsubscribe();
    }
    if (this.subscriptionaddNewValueCategory) {
      this.subscriptionaddNewValueCategory.unsubscribe();
    }
  }

  getFactCategotires() {
    this.subscriptiongetFactValueCategories = this.factsService.getFactValueCategories().subscribe((response) => {
      this.factValueCategorys = [];
      if (response.result) {
        this.factValueCategorys = response.result.map((category: { [klass: string]: any }) => {
          return {
            label: category.name,
            value: category,
          };
        });
      }
    });
  }

  getAccountCategotires() {
    const aid = sessionStorage.getItem('aid');
    this.subscriptiongetAccountCategotires = this.solutionService.getAccountCategotires(aid).subscribe((response) => {
      this.categoryInfluences = [];
      if (response.result) {
        this.categoryInfluences = response.result.map((influence: { [klass: string]: any }) => {
          return {
            label: influence.name,
            value: influence,
          };
        });
      }
    });
  }

  importFactcategory() {
    this.editFact = false;
    this.toggleFactView = true;

    this.factCategory = this.factValueCategoryDropdown['name'];
  }

  saveValueCategory() {
    const valueCatObj = {
      account_solution_id: this.solutionId,
      value_category_id: this.categoryInfluence['id'],
    };

    this.subscriptionsaveValueCategory = this.solutionService.saveValueCategory(valueCatObj).subscribe((response) => {
      if (response.result) {
        this.notificationService.success('Value Category Added successfully', false);
        this.solutionService.readAllValueCategory.next('readList');
        this.activeModal.close();
      }
    });
  }

  toggleAddViews() {
    this.toggleAddView = !this.toggleAddView;
  }

  addNewValueCategory(newCategory: string) {
    const params = {
      name: newCategory,
    };

    const aid = sessionStorage.getItem('aid');

    this.newCategoryLoader = true;
    this.subscriptionaddNewValueCategory = this.solutionService.addNewValueCategory(params, aid).subscribe((response) => {
      if (response.result && response.result.success) {
        this.categoryInfluence = {
          id: response.result.value_category_id,
        };

        this.saveValueCategory();

        this.newCategoryLoader = false;
      }
    });
  }
}
