import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { FactorTranslations } from './factors.translation';
import { SolutionValidationService } from '@data/services/validation/solution-validation.service';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';


@Component({
	selector: 'app-factors-list',
	templateUrl: './factors.component.html',
})
export class FactorsListComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() can_edit: boolean = false;
	@Output() onSaveAll = new EventEmitter();
	strSearch: string = "";
	FactorList: any[] = [];
	ngUnsubscribe = new Subject();
	updated: boolean = false;
	accountSolutionId: number;
	loading: boolean = false;
	rowLimit: { label: string; icon: string; command: () => void; }[];
	pageLimit: number = 10;
	@ViewChild('tableFactorsList') factorListTable: Table;
	@ViewChild('p-paginator') paginator: Paginator
	@ViewChild('rowTrigger') rowTrigger: ElementRef

	cols = [
		{ field: 'name', header: 'Name' },
		{ field: 'value', header: 'Value' }
	];
	style2022$: Observable<boolean>;

	constructor(
		private solutionService: SolutionService,
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		private styleService: StyleService,
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: FactorTranslations,
		private solutionValidationService: SolutionValidationService
	) {

	}
	ngAfterViewInit(): void {
		this.getData();
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.accountSolutionId = parseInt(this.route.snapshot.paramMap.get('id'));
		this.funcBuildPageLimit();
		this.getData();
	}


	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getData() {
		this.loading = true;
		this.solutionService.getFactorList(this.accountSolutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.loading = false;
			this.FactorList = res.result.factors;
			this.factorListTable.first = 0;
			this.solutionValidationService.selectedValidationFacotor$
			.pipe(take(1))
			.subscribe((factor) => {
				const index = this.FactorList.findIndex(f => f.factor_id === factor.id );
				// Change page and select factor:  
				this.factorListTable.first = index;
				this.rowTrigger?.nativeElement.click();
			});
		});
	}

	getTranslations(): void {

		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);

		const payload = {
			account_id: sessionStorage.getItem('aid'),
			component: this.trans.config.component,
			lang: langAbbr,
			localTranslations: this.trans.trans
		}
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	funcSaveAll() {
		this.updated = false;
		let out = [];
		this.FactorList.forEach(elem => {
			if (elem.updated) {
				out.push({ factor_id: elem.id, value: elem.value, unit_type_id: elem.unit_type_id });
			}
		})

		let payload = { factors: out }
		this.solutionService.updateFactorList(this.accountSolutionId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.notificationService.success("Factors Updated Successfully", false);
			this.getData();
			this.onSaveAll.emit();
		})
	}

	setRowLimit(num) {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit() {
		this.rowLimit = [
			{ label: "10", icon: this.pageLimit == 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: "25", icon: this.pageLimit == 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: "50", icon: this.pageLimit == 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: "100", icon: this.pageLimit == 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		]
	}
}