import { Injectable } from '@angular/core';

@Injectable()
export class CashflowRepSimpleTranslations {
  public config: any = {
    component: 'cashflowSimple',
    display_name: 'Cashflow Rep Simple',
    description: 'Cashflow in rep simple',
  };

  public trans: any = {
    ch_cashflow: `Cash flow is the net amount of cash and cash-equivalents
        being transferred into and out of a business. At the most fundamental level,
        a company's ability to create value for shareholders is determined by its
        ability to generate positive cash flows, or more specifically, maximize long-term free cash flow.`,
    cashfloww: 'Cashflow',
    name: 'Name',
    year1: 'Year 1',
    year2: 'Year 2',
    year3: 'Year 3',
    year4: 'Year 4',
    year5: 'Year 5',
    total: 'Total',
    cashflow_benefits: 'Benefits',
    cashflow_costs: 'Costs',
    cashflow_net_cash_flow: 'Net Cash Flow',
    cashflow_dis_cash_flow: 'Disc. Cash Flow',
    cashflow_npv: 'Net Present Value',
    no_cashflow_found: 'No cashflow found',
  };
}
