import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { CountryFlag, CountryFlagService } from '@data/services/country-flag/country-flag.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { LanguageType } from '@shared/models/user-info.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectLanguageTranslations } from './select-language.translation';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit, OnDestroy {

  languages: LanguageType[] = [];
  flag = '';
  currentLang: LanguageType;
  ngUnsubscribe = new Subject<boolean>();
  flagsMap: Map<string, CountryFlag>;

  @Input() showLabel = true;
  @Output() languageChange = new EventEmitter<string>();

  constructor(
    private userService: UserService,
    private translationService: TranslationsV2Service,
    public trans: SelectLanguageTranslations,
    private commonService: CommonService,
    private countryFlagService: CountryFlagService
  ) { }

  ngOnInit(): void {
    this.getLanguages();

    this.countryFlagService.countryFlagMap$.subscribe(flags => {
      this.flagsMap = flags;
    });

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.flag = this.translationService.getLanguageFlagIcon(res);
      this.getTranslations();
    });
  }

  getLanguages(): void {
    this.userService.getLanguages(true).subscribe((result) => {
      this.languages = result.result.map((l) => {
        l.lang = this.translationService.getLanguageAbbr(l.id);
        return l;
      });
      const langId = sessionStorage.getItem('language_type_id');
      this.flag = this.translationService.getLanguageFlagIcon(langId);
      this.currentLang = this.languages.find((lang) => lang.id === langId);
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  onLangChange(event): void {
    if (event.value.header !== 'Key') {
      this.languageChange.emit(event.value.id);
    }
  }

}
