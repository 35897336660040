import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-mikeynav',
  templateUrl: './mikeynav.component.html',
  styleUrls: ['./mikeynav.component.scss']
})
export class MikeynavComponent implements OnInit, OnDestroy{
  @ViewChild('productOverlay') productOverlay: ElementRef;
  @ViewChild('productButton') productButton: ElementRef;
  toggleProductList:boolean = false;
  xredirect:string = '';
  constructor() { }

  ngOnInit(): void {
    if(sessionStorage.getItem('xdl_referer')){
      this.xredirect = sessionStorage.getItem('xdl_referer');
    }
  }

  ngOnDestroy(){
    this.toggleProductList = false;
  }

  ngAfterViewInit() {
    this.positionProductOverlay();
  }
  @HostListener('window:resize')
  onWindowResize() {
    this.positionProductOverlay();
  }

  showProductList(){
    this.toggleProductList = !this.toggleProductList;
    this.positionProductOverlay();
  }


  positionProductOverlay() {
    if (this.productButton && this.productOverlay) {
      const buttonRect = this.productButton.nativeElement.getBoundingClientRect();
      const overlayElement = this.productOverlay.nativeElement;
      const overlayHeight = overlayElement.offsetHeight;

      // Calculate the translateY value to position the overlay above the fold
      const translateYValue = buttonRect.top - overlayHeight - 30; // 30px buffer

      overlayElement.style.transform = `translateY(${translateYValue}px)`;
    }
  }

}
