import { NgModule } from '@angular/core';

import { EditDeleteMenuRowComponent } from './edit-delete-menu-row.component';

import { MenuModule } from 'primeng/menu';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, MenuModule],
  exports: [EditDeleteMenuRowComponent],
  declarations: [EditDeleteMenuRowComponent],
  providers: [],
})
export class EditDeleteMenuRowModule {}
