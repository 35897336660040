import { Pipe, PipeTransform } from "@angular/core";
import { truncate } from 'lodash';

@Pipe({
  name: 'textTruncate'
})
export class TextTruncatePipe implements PipeTransform {
  transform(text: string, length: number): string {
    if (text.length > length) {
      return truncate(text, {
        length: length,
      });
    } else {
      return text;
    }
  }
}