import { Component, OnInit, OnDestroy } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { AlertService } from '../../../_services/alert.service';
import { SolutionDetailTranslations } from './solution-detail.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Model } from '@data/services/account/models/model.interface';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { SolutionValidationService } from '@data/services/validation/solution-validation.service';
import { BenefitValidation, BenefitValidationItem } from '@shared/models/benefits.models';
import { FactorValidation } from '@shared/models/factors.models';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-solution-detail',
  templateUrl: './solution-detail.component.html',
  styleUrls: ['./solution-detail.component.scss'],
})
export class SolutionDetailComponent implements OnInit, OnDestroy {
  rating = 4;
  solutionID: string;
  solutionDetail: any;
  valueCategory: any;
  currentValueCategory: any;
  currentValueCategoryName: string;
  currentBenefit: any;
  benefitLoader: boolean = false;
  loadSolution: boolean = false;
  image_url: string;
  fullImagePath: string;
  isSolutionAdmin: boolean = false;
  // Contextual Help
  contextualHelp: object = {};

  masterValuePropFeature: boolean = false;

  ngUnsubscribe = new Subject();

  loaded: boolean = false;

  subscriptioncontextualHelp: Subscription;
  subscriptiongetSolutionDetail: Subscription;
  subscriptionreadBenefitsByValueCategory: Subscription;
  subscriptionSubscribeLoggedIn: Subscription;

  isDev: boolean = false;

  // new
  items: any[] = [];
  view: string = 'overview';
  header_color: string;
  default_term: any;
  caseStudyID: any;
  breadcrumbValues: Breadcrumbs;
  modelEditor = false;
  public benefitValidations: BenefitValidation;
  public factorValidations: FactorValidation;
  public validationErrorCount = 0;

  constructor(
    private solutionService: SolutionService,
    private location: Location,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private alertService: AlertService,
    public trans: SolutionDetailTranslations,
    private translationService: TranslationsV2Service,
    private solutionValidationService: SolutionValidationService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }
  currentJustify = 'center';

  ngOnInit() {
    this.solutionValidationService.selectedValidationBenefit$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((benefit: BenefitValidationItem) => {
      this.view = 'benefits';
      this.funcDrawMenu();
    });
    this.solutionValidationService.selectedValidationFacotor$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.view = 'factors';
      this.funcDrawMenu();
    });
    this.view = 'overview';
    this.getTranslations();
    this.isDev = this.commonService.isDev$;

    let features = sessionStorage.getItem('features');

    if (features.indexOf('15') >= 0) {
      this.masterValuePropFeature = true;
    }

    this.getSolutionDetail();
    this.funcDrawMenu();

    this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.subscriptionSubscribeLoggedIn = this.alertService.subscribeLoggedIn().subscribe(() => {
      this.getSolutionDetail();
    });

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.route.params.subscribe((params) => (this.caseStudyID = params['caseid']));
    if (this.caseStudyID) {
      this.view = 'casestudies';
    }
    this.solutionValidationService.validationErrorCount$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((count) => {
      this.validationErrorCount += count;
      this.funcDrawMenu();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptiongetSolutionDetail) {
      this.subscriptiongetSolutionDetail.unsubscribe();
    }
    if (this.subscriptionreadBenefitsByValueCategory) {
      this.subscriptionreadBenefitsByValueCategory.unsubscribe();
    }
    if (this.subscriptionSubscribeLoggedIn) {
      this.subscriptionSubscribeLoggedIn.unsubscribe();
    }
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.funcDrawMenu();
      });
  }
  private buildBreadcrumbs() {
    if (this.solutionID !== undefined && this.solutionDetail?.name !== undefined) {
      this.breadcrumbValues = {
        [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
        [this.trans.trans.models.value]: { routerLink: '/models', active: false },
        [this.solutionDetail?.name]: { routerLink: `/model/${this.solutionID}`, active: true },
      };
    }
  }

  funcDrawMenu() {
    this.items = [
      {
        label: this.trans.trans.overview.value,
        tooltip: this.trans.trans.ch_overview.value,
        styleClass: this.view == 'overview' ? 'menuSelected' : '',
        command: () => {
          this.view = 'overview';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.benefits.value,
        tooltip: this.trans.trans.ch_benefits.value,
        styleClass: this.view == 'benefits' ? 'menuSelected' : '',
        command: () => {
          this.view = 'benefits';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.factors.value,
        tooltip: this.trans.trans.factors.value,
        styleClass: this.view == 'factors' ? 'menuSelected' : '',
        command: () => {
          this.view = 'factors';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.scalers.value,
        styleClass: this.view == 'scalers' ? 'menuSelected' : '',
        command: () => {
          this.view = 'scalers';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.costs.value,
        tooltip: this.trans.trans.ch_costs.value,
        styleClass: this.view == 'costs' ? 'menuSelected' : '',
        command: () => {
          this.view = 'costs';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.situations.value,
        tooltip: this.trans.trans.ch_situations.value,
        styleClass: this.view == 'situations' ? 'menuSelected' : '',
        command: () => {
          this.view = 'situations';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.supporting_materials.value,
        styleClass: ['casestudies', 'collateral', 'competitors'].includes(this.view) ? 'menuSelected' : '',
        items: [
          {
            label: this.trans.trans.competitors.value,
            tooltip: this.trans.trans.ch_competitors.value,
            placement: 'right',
            command: () => {
              this.view = 'competitors';
              this.funcDrawMenu();
            },
          },
          {
            label: this.trans.trans.case_studies.value,
            tooltip: this.trans.trans.ch_case_studies.value,
            placement: 'right',
            command: () => {
              this.view = 'casestudies';
              this.funcDrawMenu();
            },
          },
          {
            label: this.trans.trans.collateral.value,
            tooltip: this.trans.trans.ch_overview.value,
            placement: 'right',
            command: () => {
              this.view = 'collateral';
              this.funcDrawMenu();
            },
          },
        ],
      },
      {
        label: this.trans.trans.thresholds.value,
        tooltip: this.trans.trans.ch_thresholds.value,
        styleClass: this.view == 'thresholds' ? 'menuSelected' : '',
        command: () => {
          this.view = 'thresholds';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.validation.value,
        tooltip: this.trans.trans.validation.value,
        styleClass: this.view == 'validation' ? 'menuSelected' : '',
        badge: this.validationErrorCount > 9 ? '9+' : this.validationErrorCount,
        badgeStyleClass: 'validationBadge',
        command: () => {
          this.view = 'validation';
          this.funcDrawMenu();
        },
      },
    ];

    if (this.commonService.checkFeature(131)) {
      this.items.splice(4, 0, {
        label: this.trans.trans.goals.value,
        styleClass: this.view == 'goals' ? 'menuSelected' : '',
        command: () => {
          this.view = 'goals';
          this.funcDrawMenu();
        },
      });
    }
    this.buildBreadcrumbs();
  }

  getSolutionDetail() {
    this.route.params.subscribe((params) => (this.solutionID = params['id']));
    combineLatest([this.solutionValidationService.getBenefitValidation(this.solutionID), this.solutionValidationService.getFactorValidation(this.solutionID)])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([benfitResponse, factorResponse]) => {
        if (benfitResponse.result && factorResponse.result) {
          this.benefitValidations = benfitResponse.result;
          this.factorValidations = factorResponse.result;
          const errorCount = this.calculateValidationErrors();
          this.validationErrorCount = errorCount;
          this.funcDrawMenu();
        }
      });

    this.subscriptiongetSolutionDetail = this.solutionService.getSolutionDetail(this.solutionID).subscribe((response) => {
      this.default_term = response.result.term || response.result.default_term;

      if (!response.result['share_role_type_id']) {
        this.location.back();
        this.loaded = false;
        return false;
      }

      this.solutionDetail = response.result;
      this.valueCategory = this.solutionDetail.value_categories;
      const shareType = this.solutionDetail.share_role_type_id;
      this.modelEditor = shareType === '2' || shareType === '3' || shareType === '5';
      if (!shareType || shareType.length === 0) {
        this.router.navigate(['dashboard']);
        this.notificationService.warning(this.trans.trans.notRightAccess.value, false);
      }
      this.currentValueCategory = 'all';
      this.loadSolution = true;

      this.solutionService.readSolutionShareType.next(this.solutionDetail['share_role_type_id']);
      this.loaded = true;
    });
  }

  setSolutionDetail(model: Model): void {
    this.default_term = model.term || model.default_term;
    this.solutionDetail = model;
  }

  getCurrentBenefit() {
    this.benefitLoader = false;
    for (let i = 0; i < this.valueCategory.length; i++) {
      if (this.valueCategory[i].id == this.currentValueCategory) {
        this.currentValueCategoryName = this.valueCategory[i].name;
      }
    }
    this.readBenefitsByValueCategory(this.currentValueCategory, this.solutionID);
  }

  readBenefitsByValueCategory(value_category_id, solution_id) {
    this.subscriptionreadBenefitsByValueCategory = this.solutionService.readBenefitsByValueCategory(value_category_id, solution_id).subscribe((response) => {
      this.currentBenefit = response.result;
      this.benefitLoader = true;
    });
  }

  public onValidationErrors(event: number) {
    this.items[this.items.length - 1].badge = event.toString();
  }

  private calculateValidationErrors(): number {
    let sum = 0;
    // Benefits
    if (this.benefitValidations) {
      sum += this.totalUnacknowledged(this.benefitValidations);
    }
    // Factors
    if (this.factorValidations) {
      sum += this.totalUnacknowledged(this.factorValidations);
    }
    return sum;
  }

  private totalUnacknowledged(validations: BenefitValidation | FactorValidation): number {
    let sum = 0;
    Object.values(validations).forEach((items: BenefitValidationItem[]) => {
      sum += this.unacknowledgedCategory(items);
    });
    return sum;
  }

  private unacknowledgedCategory(items: BenefitValidationItem[]): number {
    let sum = 0;
    for (const item of items) {
      if (!item.acknowledged) {
        sum++;
      }
    }
    return sum;
  }

  backClicked() {
    this.location.back();
  }
}
