export function setHeap() {
  const win = window as Window & typeof globalThis & { heap: any };
  if (win.heap) {
    win.heap = win.heap;
  } else {
    win.heap = [];
  }
  const heap = win.heap;

  heap.load = function(e, t) {
    (win.heap.appid = e), (win.heap.config = t = t || {});
    var r = document.createElement("script");
    (r.type = "text/javascript"),
      (r.async = !0),
      (r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js");
    var a = document.getElementsByTagName("script")[0];
    a.parentNode.insertBefore(r, a);
    for (
      var n = function(e) {
        return function() {
          heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      },
      p = [
        "addEventProperties",
        "addUserProperties",
        "clearEventProperties",
        "identify",
        "resetIdentity",
        "removeEventProperty",
        "setEventProperties",
        "track",
        "unsetEventProperty",
      ],
      o = 0;
      o < p.length;
      o++
    )
      heap[p[o]] = n(p[o]);
  };

  let hostname = window.location.hostname;
  switch (hostname) {
    case "localhost":
      // no Heap for localhost
      break;

    case "develop.ui.value-cloud.com":
    case "stage.value-cloud.com":
      heap.load("3244238738");
      break;

    default:
      heap.load("2662141689");
      break;
  }
}

