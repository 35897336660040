import { Injectable } from '@angular/core';

@Injectable()
export class BenefitsCasestudyTranslations {
	public config: any = {
		component: 'benefitsCaseStudies',
		display_name: 'Case Study Benefits v2',
		description: 'All benefits for a case study'
	};

	public trans: any = {
		tie_to_benefits: 'Tie to Benefits',
		edit_translations: 'Edit Translations'
	};
}
