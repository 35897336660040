<div class='competitors_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>Factors</div>
		</div>
		<div class='col-xs-6'>
			<button type="button" class="btn btn_customizable pull-right" *ngIf="can_edit" (click)="editScalers()">{{trans.Scalers.save | translate}}</button>
			<button class="btn btn_customizable pull-right" *ngIf="can_edit" (click)="toggleScalersCreate()">{{trans.Scalers.create_account_scalers | translate}}</button>
		</div>
	</div>
</div>

<div class='row' style='margin-bottom: 20px;'>
	<div class='col-xs-4'>
		<div class="input-group keywest_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input [(ngModel)]="strSearch" type='text' placeholder='Search' class='form-control'>
			<span *ngIf="strSearch !== ''" (click)="strSearch='';" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
		</div>
	</div>
	<div class='col-xs-4'></div>
	<div class="col-xs-3">
		<p-multiSelect class="keywest_dropdown" [options]="scalers" [(ngModel)]="selectedItems" optionLabel="name"></p-multiSelect>
	</div>
	<div class="col-xs-1">
		<button class="btn btn_customizable" *ngIf="can_edit" [disabled]="selectedItems.length == 0" (click)="addScalersforEdit()">{{trans.Scalers.add | translate}}</button>
	</div>

</div>

<div class="row" *ngIf="!accountScalerShow" style='margin-bottom: 20px;'>
</div>

<div class="row" style='margin-bottom: 20px;' *ngFor="let item of selectedScalers | filter: strSearch : 'name'">
	<div class="col-xs-4">
		<label class='sidebar_label'>{{item.name}} 
			<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle" [matTooltip]="item.description" matTooltipPosition="right"></i>
			<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description" matTooltipPosition="above">
				info
			</span>
		</label>
		<div class="input-group keywest_search">
			<input type="text" class="form-control" [placeholder]="item.name" (keyup)='item.updated = true' [name]="'scaler-' + item.id" [(ngModel)]="item.value" />
			<span class='input-group-addon'>
				<app-are-you-sure matTooltip="Delete" matTooltipPosition="above" message='Are you sure?' text='' icon='fa fa-trash' (callbackYes)='deleteScaler(item.id)'></app-are-you-sure>
			</span>
		</div>
	</div>
</div>


<p-sidebar [(visible)]='accountScalerShow' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<form class="form-horizontal" name="createAccountScalersForm" #createAccountScalersForm="ngForm">
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				Add Scaler
			</div>

			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class='row'>
						<div vlass='col-xs-12'>
							<label class='sidebar_label'>{{trans.Scalers.scaler_name | translate}}</label>
							<input type="text" class="form-control" placeholder="Scaler Name" name="scalerName" [(ngModel)]="accountScaler.name" required />
						</div>
					</div>
					<div class='row'>
						<div vlass='col-xs-12'>
							<label class='sidebar_label'>{{trans.Scalers.scaler_description | translate}}</label>
							<input type="text" class="form-control" placeholder="Scaler Description" name="scalerdesc" [(ngModel)]="accountScaler.description" required />
						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<button type="button" class="btn btn_customizable pull-right" (click)="createAccountScalers()" [disabled]="createAccountScalersForm.invalid">{{trans.Scalers.create | translate}}</button>
				<button type="button" class="btn cancel-confirm pull-right" (click)="toggleScalersCreate()">{{trans.Scalers.cancel | translate}}</button>
			</div>
		</div>
	</form>
</p-sidebar>