<div class="keywest_header">
  <div class="row">
    <div class="col-xs-12">
      <button *ngIf="canEdit" (click)="openGoal()" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i>{{ trans.trans.addGoal.value }}</button>
    </div>
  </div>
</div>

<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

<ng-template #loadedContent>
  <div class="row">
    <div class="col-xs-12">
      <p-table [value]="goalsList" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{ trans.trans.nameCol.value }}
            </th>
            <th>
              {{ trans.trans.valueCol.value }}
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>
              <a *ngIf="canEdit" class="clickable" (click)="openGoalChart(rowData)">{{ rowData.factor_name }}</a>
              <span *ngIf="!canEdit">{{ rowData.factor_name }}</span>
            </td>
            <td [style.width]="'150px'">{{ rowData.value }}</td>
            <td>
              <app-ellipsis-menu *ngIf="canEdit">
                <ul class="list-group">
                  <li class="list-group-item" (click)="openGoal(rowData)">
                    {{ trans.trans.editGoal.value }}
                  </li>
                  <li class="list-group-item">
                    <app-are-you-sure (callbackYes)="removeGoal(rowData)" [text]="trans.trans.deleteGoal.value" icon="" [message]="trans.trans.areYouSure.value"></app-are-you-sure>
                  </li>
                </ul>
              </app-ellipsis-menu>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" class="text-center">{{ trans.trans.emptyTable.value }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-template>

<p-sidebar [(visible)]="toggleSidebar" styleClass="keywest_sidebar p-sidebar-md" position="right">
  <app-goal-form
    *ngIf="toggleSidebar"
    [goalData]="selectedGoal"
    [modelGoalIds]="goalsListIds"
    [loadingSave]="loadingSave"
    [hideCreateNew]="!goalOnTheFlyPrivilege"
    (saveClick)="onSaveClick($event)"
  ></app-goal-form>
</p-sidebar>

<p-sidebar [(visible)]="toggleChart" styleClass="keywest_sidebar p-sidebar-md" position="right">
  <app-tracker-goal-chart
    *ngIf="toggleChart"
    [goalData]="selectedGoalChart"
    [loadingSave]="loadingChartSave"
    (saveClick)="onSaveChartClick($event)"
    (cancelClick)="onCancelChartClick()"
  ></app-tracker-goal-chart>
</p-sidebar>
