import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { FactorsService } from '@data/services/factors/factors.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';
import { ErrorMessagesService } from '@services/error-messages.service';
import { NotificationService } from '@services/notification.service';
import { FaIcon } from '@shared/models/fa-icon.model';
import { ConnectedObjectWithoutResult, Goal, GoalGroup, GoalPayload, GoalPayloadOneTime } from '@shared/models/goal-group.model';
import { forkJoin, Subject } from 'rxjs';
import { finalize, pluck, takeUntil, take } from 'rxjs/operators';
import { AeGoalsTranslations } from '../ae-goals.translation';

@Component({
  selector: 'app-ae-goals-form',
  templateUrl: './ae-goals-form.component.html',
  styleUrls: ['./ae-goals-form.component.scss'],
})
export class AeGoalsFormComponent implements OnInit, OnDestroy {
  @Input() goalSelected: Goal;
  @Input() goalGroups: GoalGroup[] = [];
  @Input() linkedFactorsId: string[];
  @Input() vpId: string;
  @Output() refresh = new EventEmitter();
  @Output() close = new EventEmitter();

  goalForm: FormGroup;
  errorDict = new Map();
  accountId: string;
  isLoading = false;
  availableFactors: { label: string; value: string; linked: boolean }[];
  selectedFactors: string[] = [];
  goalTypes = [
    {
      label: 'Static',
    },
  ];

  faIcons: FaIcon[] = [];
  unitTypes: ExpenseType[];

  showTranslate = false;
  ngUnsubscribe$ = new Subject();

  constructor(
    private errorMessageService: ErrorMessagesService,
    private goalGroupService: GoalGroupsService,
    private notificationService: NotificationService,
    private factorsService: FactorsService,
    public trans: AeGoalsTranslations,
    private translationsService: TranslationsV2Service,
    private commonService: CommonService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.accountId = sessionStorage.getItem('aid');
    if (!this.vpId) {
      this.getAllBenefits();
    }

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getFieldsValues();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  get baselineController(): AbstractControl {
    return this.goalForm.get('captureBaseline');
  }

  get targetController(): AbstractControl {
    return this.goalForm.get('captureTarget');
  }

  get keyFactorController(): AbstractControl {
    return this.goalForm.get('isKeyFactor');
  }

  private getFieldsValues() {
    forkJoin({
      unitTypes: this.factorsService.getUnitTypes().pipe(pluck('result')),
      icons: this.commonService.getFAIconList(),
    })
      .pipe(take(1))
      .subscribe((res) => {
        this.unitTypes = res.unitTypes;
        this.faIcons = res.icons.icons;
        const noIcon = {
          id: null,
          label: 'No Icon',
          value: '',
        };

        this.faIcons.unshift(noIcon);
        this.setFormValues();
      });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationsService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationsService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  buildForm() {
    this.goalForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      goalGroup: new FormControl(),
      unitType: new FormControl(),
      icon: new FormControl(),
      precision: new FormControl(0, [Validators.max(9), Validators.min(0)]),
      captureBaseline: new FormControl(1),
      captureTarget: new FormControl(1),
      isKeyFactor: new FormControl(0)
    });

    this.goalForm.markAllAsTouched();

    this.goalForm.valueChanges.subscribe(() => {
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.goalForm.controls));
    });
  }

  setFormValues() {
    if (this.goalSelected) {
      this.goalForm.patchValue({
        name: this.goalSelected.name,
        description: this.goalSelected.description,
        goalGroup: this.goalGroups.find((group) => group.id == this.goalSelected.account_factor_category_id),
        unitType: this.unitTypes.find((unitType) => unitType.id == this.goalSelected.unit_type_id),
        icon: this.goalSelected.icon ? this.faIcons.find((icon) => icon.label == this.goalSelected.icon) : this.faIcons[0],
        precision: this.goalSelected.factor_precision,
        captureBaseline: +this.goalSelected.capture_baseline,
        captureTarget: +this.goalSelected.capture_target,
        isKeyFactor: +this.goalSelected.is_key_factor
      });

      this.selectedFactors =
        this.goalSelected.connected_object_id && this.goalSelected.connected_object_id.length
          ? (this.goalSelected.connected_object_id as ConnectedObjectWithoutResult[]).map((cObject) => cObject.connected_object_id)
          : [];
      return;
    }

    this.goalForm.patchValue({
      name: '',
      description: '',
      goalGroup: this.goalGroups[0],
      unitType: this.unitTypes[13],
      icon: this.faIcons[0],
      precision: '0',
      captureBaseline: '1',
      captureTarget: '1',
      isKeyFactor: '0'
    });
  }

  saveGoal() {
    this.isLoading = true;
    if (this.vpId) {
      this.saveGoalForVP();
      return;
    }

    const payload: GoalPayload = {
      name: this.goalForm.get('name').value,
      value_realization_name: '',
      description: this.goalForm.get('description').value,
      unit_type_id: this.goalForm.get('unitType').value.id,
      factor_precision: this.goalForm.get('precision').value,
      scales_by: 0,
      scales_how: 0,
      ratio: '',
      formula: '',
      is_key_factor: this.goalForm.get('isKeyFactor').value ? 1 : 0,
      literal_number: 0,
      greater_than_zero: 0,
      min: 1,
      max: 100,
      increment: 1,
      account_factor_category_id: this.goalForm.get('goalGroup').value.id,
      icon: this.goalForm.get('icon').value.label,
      external_id: '',
      function_type_id: 2,
      connected_object_id: this.selectedFactors.length
        ? this.availableFactors
            .filter((factor) => this.selectedFactors.some((id) => factor.value === id))
            .map((factor) => {
              return {
                connected_object_type_id: '2',
                connected_object_id: factor.value,
              };
            })
        : [],
      capture_baseline: this.goalForm.get('captureBaseline').value ? '1' : '0',
      capture_target: this.goalForm.get('captureTarget').value ? '1' : '0',
    };

    if (this.goalSelected) {
      payload.account_factor_id = this.goalSelected.id;

      this.goalGroupService
        .updateGoal(this.accountId, payload)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          (res) => {
            if (res.result && !res.result.success) {
              this.notificationService.error(this.trans.trans.requestError.value, false);
              return;
            }

            this.notificationService.success(this.trans.trans.goalUpdated.value, false);
            this.refresh.emit();
            this.goBack();
          },
          () => {
            this.notificationService.error(this.trans.trans.requestError.value, false);
          }
        );

      return;
    }

    this.goalGroupService
      .createGoal(this.accountId, payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res) => {
          if (res.result && !res.result.success) {
            this.notificationService.error(this.trans.trans.requestError.value, false);
            return;
          }

          this.notificationService.success(this.trans.trans.goalSaved.value, false);
          this.refresh.emit();
          this.goBack();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  saveGoalForVP() {
    const payload: GoalPayloadOneTime = {
      active: '1',
      name: this.goalForm.get('name').value,
      description: this.goalForm.get('description').value,
      unit_type_id: this.goalForm.get('unitType').value.id,
      factor_precision: this.goalForm.get('precision').value,
      scales_how: 0,
      ratio: '',
      formula: '',
      is_key_factor: this.goalForm.get('captureBaseline').value ? 1 : 0,
      literal_number: 0,
      greater_than_zero: 0,
      min: 1,
      max: 100,
      increment: 1,
      account_factor_category_id: this.goalForm.get('goalGroup').value.id,
      icon: this.goalForm.get('icon').value.label,
      function_type_id: 2,
      target_value: 0,
      connected_object_id: [],
      capture_baseline: this.goalForm.get('captureBaseline').value ? '1' : '0',
      capture_target: this.goalForm.get('captureTarget').value ? '1' : '0',
    };
    this.goalGroupService
      .createOneTimeGoal(this.vpId, payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res) => {
          if (res.result && !res.result.success) {
            this.notificationService.error(this.trans.trans.requestError.value, false);
            return;
          }

          this.notificationService.success(this.trans.trans.goalSaved.value, false);
          this.refresh.emit();
          this.goBack();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  goBack() {
    this.goalSelected = null;
    this.close.emit();
  }

  private getAllBenefits(): void {
    const accountId = sessionStorage.getItem('aid');
    if (accountId) {
      this.accountService.getAvailableAccountFactors(accountId).subscribe((res) => {
        this.availableFactors = res.result
          .filter((factor) => factor.id != '0')
          .map((factor) => {
            const idString = String(factor.id);
            return {
              label: factor.name,
              value: idString,
              linked: this.linkedFactorsId.includes(idString) && !this.selectedFactors.includes(idString),
            };
          });
      });
    }
  }
}
