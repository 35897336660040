import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalSearchService } from '@data/services/search/search.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './search.component.html',
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  term = '';
  loading = false;
  hypotheses: { [klass: string]: any } & { expanded?: boolean }[] = [];
  propositions: { [klass: string]: any } & { expanded?: boolean }[] = [];
  realizations: { [klass: string]: any } & { expanded?: boolean }[] = [];
  companies: { [klass: string]: any } & { expanded?: boolean }[] = [];
  case_studies: { [klass: string]: any } & { expanded?: boolean }[] = [];

  firstLoad = true;

  pageLimit = 10;

  cols = [{ field: 'name', header: 'Name' }];

  ngUnsubscribe = new Subject();
  constructor(
    private SearchService: GlobalSearchService,
    private route: ActivatedRoute,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.loading = true;

    if (!this.term) {
      this.loading = false;
    }
    this.route.params.subscribe((routeParams) => {
      this.term = routeParams.term;
      if (this.term) {
        this.loading = true;

        const payload = {
          searchTerm: this.term,
          publicOnly: 0,
          synopsisOnly: 1,
          limit: 'all',
          page: 1,
          sortBy: 'name',
          order: 'asc',
        };
        this.SearchService.getGlobalSearch(payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .pipe(debounceTime(1000))
          .subscribe((res) => {
            this.hypotheses = res.result.hypotheses;
            if (this.hypotheses.length) {
              this.hypotheses['expanded'] = true;
            }

            this.propositions = res.result.propositions;
            if (this.propositions.length) {
              this.propositions['expanded'] = true;
            }

            this.realizations = res.result.realizations;
            if (this.realizations.length) {
              this.realizations['expanded'] = true;
            }

            this.companies = res.result.companies;
            if (this.companies.length) {
              this.companies['expanded'] = true;
            }

            this.case_studies = res.result.case_studies;
            if (this.case_studies.length) {
              this.case_studies['expanded'] = true;
            }

            this.loading = false;
          });
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  funcGetCaseStudiesLink(elem: { [klass: string]: string }) {
    const modelsv2 = this.commonService.checkFeature(38);
    if (modelsv2) {
      return '/modelv2/' + elem.account_solution_id + '/case/' + elem.case_study_id;
    } else {
      return '/model/' + elem.account_solution_id + '/case/' + elem.case_study_id;
    }
  }
}
