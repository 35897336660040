import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'solution-list',
    display_name: 'Models List',
    description: 'View of all available Models',
  };

  public trans: any = {
    models: 'Models',
    model_categories: 'Model Categories',
    value_categories: 'Value Categories',
    situations: 'Situations',
    painPoints: 'Pain Points',
    capabilities: 'Capabilities',
    operational_objectives: 'Operational Objectives',
    functional_objectives: 'Functional Objectives',
    add_new: 'Add New',
    released_to_users: 'Released to Users',
    released_to_partners: 'Released to Partners',
    specific: 'Specific',
    group: 'Group',
    clone: 'Clone',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    cloning_in_progress: 'Cloning in Progress',
    name: 'Model Name',
    releasedToUsers: 'Released To Users',
    releasedToPartners: 'Released To Partners',
    areYouSure: 'Are you sure that you want to delete?',
    modelAdmins: 'Model Admins',
    back: 'Back',
    are_you_sure: 'Are you sure?',
    save: 'Save',
    cancel: 'Cancel',
    release_to_partners: 'Release To Partners',
    partners: 'Partners',
    remove_filter: 'Remove Filter',
    share: 'Share',
    editing: 'Editing',
    shareRoleType: 'Share Role Type',
    actions: 'Actions',
    deleteModelPrivilege: 'Delete Model Privilege?',
    areYouSurePriv: 'Are you sure that you want to remove this privilege?',
    yes: 'Yes',
    no: 'No',
    userName: 'User Name',
  };
}
