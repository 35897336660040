<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div *ngIf="!hideHeader" class="title">
            {{ trans.trans.collateral.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_collateral.value"> info </span>
          </div>
        </div>
        <div class="col-xs-2">
          <button
            *ngIf="!locked && can_edit"
            class="btn btn_customizable pull-right"
            (click)="openAddCollateral()"
            [matTooltip]="trans.trans.addNew.value"
            matTooltipPosition="above"
          >
            <i class="fa fa-plus"></i> {{ trans.trans.add.value }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <table class="table table-responsive custom-table">
          <thead>
            <tr>
              <th>
                {{ trans.trans.format.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.format"
                  key="vp_dashboard.collateral.format"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.name.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.name"
                  key="vp_dashboard.collateral.name"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.url.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.url"
                  key="vp_dashboard.collateral.url"
                ></app-editTranslation>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="loadcollateralTable">
            <tr>
              <td colspan="4">
                <img class="actionLoader" src="{{ fullImagePath }}" />
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="(collateral === null || collateral.length === 0) && !loadcollateralTable">
            <tr>
              <td colspan="4">
                {{ trans.trans.noCollateralsFound.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.noCollateralsFound"
                  key="vp_dashboard.collateral.noCollateralsFound"
                ></app-editTranslation>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr *ngFor="let value of collateral" class="grid-row company-row">
              <td>
                {{ value.format }}
              </td>
              <td>
                {{ value.name }}
              </td>
              <td>
                <a [href]="value.url | functionPipe: prependHttp" target="_blank">{{ value.url }}</a>
              </td>
              <td class="">
                <app-ellipsis-menu *ngIf="can_edit && value.value_prop_id == valuePropId" class="pull-right">
                  <ul class="list-group">
                    <li class="list-group-item" (click)="editCollateral(value)"><i class="icon-pencil"></i> {{ trans.trans.edit.value }}</li>
                    <li class="list-group-item" (click)="open(deleteCollateralModal, value.id)"><i class="icon-trash"></i> {{ trans.trans.delete.value }}</li>
                  </ul>
                </app-ellipsis-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<p-sidebar *ngIf="toggleAdd" [(visible)]="toggleAdd" [modal]="false" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <form name="AddCollateralForm" #AddCollateralForm="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">{{ typeOfCollateral }} {{ trans.trans.collateral.value }}</div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-xs-12" [ngClass]="{ 'has-error': AddCollateralForm.controls.format?.invalid && AddCollateralForm.controls.format?.touched }">
              <label class="sidebar_label" for="alterEgo"
                >{{ trans.trans.format.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.format"
                  key="vp_dashboard.collateral.format"
                ></app-editTranslation
              ></label>
              <select class="form-control" [(ngModel)]="format" name="format" type="text" required>
                <option disabled="disabled">{{ trans.trans.ChooseOne.value }}</option>
                <option *ngFor="let formatTypes of fileFormatTypes" [value]="formatTypes.id">
                  {{ formatTypes.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12" [ngClass]="{ 'has-error': AddCollateralForm.controls.name?.invalid && AddCollateralForm.controls.name?.touched }">
              <label for="name" class="sidebar_label">
                {{ trans.trans.name.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.name"
                  key="vp_dashboard.collateral.name"
                ></app-editTranslation
              ></label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="name" placeholder="{{ trans.trans.name_label.value }}" required />
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <label for="alterEgo" class="sidebar_label"
                >{{ trans.trans.description.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.description"
                  key="vp_dashboard.collateral.description"
                ></app-editTranslation
              ></label>
              <textarea class="form-control" id="Description" [(ngModel)]="description" name="description" placeholder="{{ trans.trans.descriptionLabel.value }}"></textarea>
            </div>
          </div>
          <div class="row">
            <div
              class="col-xs-12"
              [ngClass]="{ 'has-error': AddCollateralForm.controls.url?.errors && AddCollateralForm.controls.url?.invalid && AddCollateralForm.controls.url?.touched }"
            >
              <label for="name" class="sidebar_label">
                {{ trans.trans.url.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.url"
                  key="vp_dashboard.collateral.url"
                ></app-editTranslation
              ></label>
              <input type="text" class="form-control" id="url" required [(ngModel)]="url" name="url" ngModel placeholder="{{ trans.trans.url.value }}" />
            </div>
          </div>
        </div>

        <div class="pull-right">
          <label class="labelsRequiredInfo"
            >{{ trans.trans.isRequired.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.isRequired"
              key="vp_dashboard.collateral.isRequired"
            ></app-editTranslation
          ></label>
        </div>
      </div>
      <div class="btn_container">
        <div class="col-xs-12">
          <button type="submit" class="btn btn_customizable pull-right clickable" (click)="SaveCollateral(AddCollateralForm)" [disabled]="AddCollateralForm.invalid">
            {{ trans.trans.save.value }}
          </button>
          <app-are-you-sure
            matTooltipPosition="above"
            [message]="trans.trans.are_you_sure.value"
            class="btn cancel-confirm pull-right marg-h"
            icon=""
            [text]="trans.trans.back.value"
            (callbackYes)="toggleAdd = !toggleAdd"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>

<ng-template #deleteCollateralModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ trans.trans.deleteCollateral.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        (callback)="getTranslations()"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.deleteCollateral"
        key="vp_dashboard.collateral.deleteCollateral"
      ></app-editTranslation>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ trans.trans.deleteConfirmation.value }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="deleteCollateral()">{{ trans.trans.Yes.value }}</button>
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ trans.trans.No.value }}</button>
    <img class="actionLoader" [hidden]="hideActionLoader" src="{{ fullImagePath }}" />
  </div>
</ng-template>
