import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CasestudyDetailTranslations } from './casestudy-detail.translation';

@Component({
  selector: 'app-casestudy-detail',
  templateUrl: './casestudy-detail.component.html',
  styleUrls: ['./casestudy-detail.component.scss'],
})
export class CasestudyDetailComponent implements OnInit, OnDestroy {
  @Input() caseStudyId: string | number;
  @Input() type: string;
  @Input() caseStudy: { [klass: string]: any };

  caseStudyItem: { [klass: string]: any };
  showCaseStudy = false;
  isAccountSolution: number;
  changeName = false;
  assetUrl = '';
  assetGenerator = '';

  subscriptiongetCaseStudyDetail: Subscription;
  subscriptiongetCaseStudyAssetTemplate: Subscription;
  subscriptionupdateName: Subscription;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    private solutionService: SolutionService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private assetService: AssetService,
    private ValueRealizationService: ValuerealizationService,
    private translationService: TranslationsV2Service,
    public trans: CasestudyDetailTranslations
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getCaseStudyDetail();
    this.assetUrl = this.commonService.getAssetUrl();
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
  }

  ngOnDestroy() {
    if (this.subscriptiongetCaseStudyDetail) {
      this.subscriptiongetCaseStudyDetail.unsubscribe();
    }
    if (this.subscriptiongetCaseStudyAssetTemplate) {
      this.subscriptiongetCaseStudyAssetTemplate.unsubscribe();
    }
    if (this.subscriptionupdateName) {
      this.subscriptionupdateName.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getCaseStudyDetail() {
    this.isAccountSolution = this.type === 'solution' ? 1 : 0;
    if (this.isAccountSolution) {
      if (this.caseStudy && this.caseStudy['value_prop_id'] != 0 && this.caseStudy['is_account_solution'] == 0) {
        this.isAccountSolution = 0;
      }
    }
    this.subscriptiongetCaseStudyDetail = this.solutionService.getCaseStudyDetail(this.caseStudyId, this.isAccountSolution).subscribe((response) => {
      if (response.result && response.result.case_study) {
        this.caseStudyItem = response.result.case_study[0];
        this.showCaseStudy = true;
      }
    });
  }

  downloadAsset() {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.subscriptiongetCaseStudyAssetTemplate = this.assetService.getCaseStudyAssetTemplate().subscribe((response) => {
      if (response && response !== null && typeof response !== 'string') {
        const downloadUrl =
          this.assetUrl +
          'assets/' +
          this.assetGenerator +
          '?account_asset_template_id=' +
          response.result.id +
          '&user_id=' +
          userId +
          '&token=' +
          token +
          '&case_study_id=' +
          this.caseStudyId +
          '&is_account_solution=' +
          this.isAccountSolution;

        window.location.assign(downloadUrl);
      } else {
        this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }

  changeCaseStudyName() {
    const payload = {
      case_study_id: this.caseStudyItem.id,
      name: this.caseStudyItem.name,
    };

    this.subscriptionupdateName = this.ValueRealizationService.updateName(payload).subscribe((response) => {
      if (response.result.success) {
        this.notificationService.success('Name Updated successfully', false);
        this.changeName = false;
      } else {
        this.notificationService.error('Name change failed!', false);
      }
    });
  }

  cancelNameChange() {
    this.changeName = false;
  }
}
