import { ValueProp, Benefit } from '@shared/models/value-prop.model';
import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { forkJoin, of, Subject, Observable } from 'rxjs';
import { take, takeUntil, tap, catchError } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { SimpleImplementation } from 'app/repv2/simple.implementation';
import { funcFixVal } from 'app/repv2/helpers/fix-val';
import { getColor } from 'app/repv2/helpers/colors';
import { StyleService } from 'app/style.service';
import { RepSimpleResultsTranslaions } from './results.translation';
import { ChartResult, SubChart } from './chart-result.interface';
import { ResultStatus } from '@shared/models/result-status.models';

@Component({
  selector: 'app-rsv2-dashboard-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Resultscomponent implements OnInit, OnDestroy {
  @Input() shareView = 'simple';
  @Input() menu: { done: boolean }[] = [];
  @Input() showHelp: boolean;
  @Input() contextualHelp: any[];
  @Input() showTranslate = false;
  @Input() locked = false;

  @Input() view = 'case';
  @Input() valueProp: ValueProp;

  @Output() reloadVP = new EventEmitter();
  @Output() back = new EventEmitter();

  ngUnsubscribe = new Subject();

  feature59 = false;
  feature62 = false;

  headerColor = '';
  showTCO = false;
  hideNPV = false;
  clonedValuePropName = '';
  hidePaybackFeature = false;

  chartROI: ChartResult = null;
  chartTCO: ChartResult = null;
  chartPayback: ChartResult = null;
  chartNPV: ChartResult = null;
  chart3MO: ChartResult = null;
  chartVC: ChartResult = null;
  chartVCLegend = [];
  benefits: Partial<Benefit> = { metrics: [] };

  colorROI = 'border-right: inherit';
  colorNPV = 'border-right: inherit';
  colorPBP = 'border-right: inherit';
  colorTCO = 'border-right: inherit';
  colorVC = 'border-right: inherit';

  roiStatus: ResultStatus;
  npvStatus: ResultStatus;
  pbpStatus: ResultStatus;

  roiText: string;
  tcoText: string;
  paybackText: string;
  paybackFormulaText: string;
  npvText: string;
  npvFormulaText: string;
  inactionText: string;
  vcText: string;
  benefitTotal: string;
  roiColor: string;
  npvColor: string;
  pbpColor: string;

  threemoText: string;
  threemoFormula: string;

  years = 3;

  changeName = false;

  optionsVC: any;
  VCchart: SubChart;
  selectedName = '';
  selectedVal = '';
  selectedCLR = '';
  selectedId: number | string;
  selectedDesc: string;
  valuePropStatusTypes: any[] = [];
  permissions: any;
  vpName = '';

  showAllMetrics = false;
  feature76 = false;
  toggleChart = false;

  style2022$: Observable<boolean>;

  constructor(
    public RS: RepSimpleViewService,
    private commonService: CommonService,
    private ValuePropService: ValuepropService,
    private RepSimpleService: RepSimpleViewService,
    private notificationService: NotificationService,
    public trans: RepSimpleResultsTranslaions,
    private translationService: TranslationsV2Service,
    private factsService: FactsService,
    public sI: SimpleImplementation,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.feature76 = this.commonService.checkFeature(76);

    this.vpName = this.valueProp.name;
    let done = true;
    for (let i = 0; i < 4; i++) {
      if (!this.menu[i].done) {
        done = false;
      }
    }

    if (this.valueProp.done === '1') {
      done = true;
    }

    if (done) {
      const payload2 = { done: 1 };
      this.RS.putDone(this.valueProp.id, payload2)
        .pipe(take(1))
        .subscribe(() => {
          this.valueProp.done = '1';
          for (let i = 0; i < 4; i++) {
            this.menu[i].done = true;
          }
        });
    }

    this.getValuePropStatusTypes();

    this.RS.navDiscoveryQuestions.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.view = 'discovery';
    });

    this.RS.navNotes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.view = 'notes';
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    sessionStorage.removeItem('repsimple_results_view');

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    sessionStorage.removeItem('repsimple_results_view');
    sessionStorage.removeItem('metricsExpandedMini');

    this.RepSimpleService.navDiscoveryQuestions.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.view = 'discovery';
      const divElem = document.getElementById('panel_wrapper');
      const chElem = document.getElementById('panel_container');
      const topPos = divElem.offsetTop;
      divElem.scrollTop = topPos - chElem.offsetTop;
    });

    this.headerColor = sessionStorage.getItem('header_color');

    this.feature62 = this.commonService.checkFeature('62');
    this.feature59 = this.commonService.checkFeature('59');
    this.showTCO = this.commonService.checkFeature(10);
    this.hideNPV = this.commonService.checkFeature(37);
    this.hidePaybackFeature = this.commonService.checkFeature(121);

    this.ValuePropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.ValuePropService.refreshBenefitDetail.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.view = 'benefits';
      this.getTranslations();
    });

    if (sessionStorage.getItem('repsimple_results_view')) {
      this.view = sessionStorage.getItem('repsimple_results_view');
    }

    this.RepSimpleService.navNotes.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.view = 'notes';
    });

    this.clonedValuePropName = this.valueProp.name;

    const benefitIndex = sessionStorage.getItem('benefit_index');
    if (benefitIndex) {
      const out = this.valueProp.benefits.filter((x) => x.value_category_id === benefitIndex)[0];
      this.benefits.metrics = out.metrics;
      this.view = 'benefits';
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  funcShowAllMetrics(): void {
    this.toggleChart = true;

    setTimeout(() => {
      this.sI.showAllTcoMetrics = this.showAllMetrics;
      this.RepSimpleService.getTCOChart(this.valueProp.id, this.showAllMetrics === true ? 1 : 0)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          const chartColors = this.commonService.getChartColors();
          this.valueProp.tco_fmt = parseInt(res.result.total_tco_cost_fmt, 10);
          this.chartTCO = {
            data: {
              datasets: [
                {
                  label: this.trans.trans.currentCosts.value,
                  data: res.result.current_state,
                  backgroundColor: chartColors[0],
                },
                {
                  label: this.trans.trans.futureCosts.value,
                  data: res.result.future_state,
                  backgroundColor: chartColors[1],
                },
              ],
              labels: res.result.years,
            },
            d0: res.result.current_state_fmt,
            d1: res.result.future_state_fmt,
            labels: ['current_state_fmt', 'future_state_fmt'],
          };

          this.tcoText = res.result.tco_formula;
          this.toggleChart = false;
        });
      this.funcGetCharts();
    }, 500);
  }

  allTcoMetrics(show: boolean): void {
    this.showAllMetrics = show;
    this.sI.showAllTcoMetrics = show;
  }

  closeBenefits(): void {
    sessionStorage.removeItem('benefit_index');
  }

  reload(): void {
    this.funcGetCharts();

    this.ValuePropService.quickFillGetBenefits(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.valueProp.benefits = response.result.vcs;
        this.reloadVP.emit();
      });
  }

  getColorText(status: number, min: number, max: number, margin: number): ResultStatus {
    return {
      colorId: status,
      min,
      max,
      margin,
    };
  }

  reloadv2(): void {
    this.ValuePropService.quickFillGetBenefits(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.valueProp.benefits = response.result.vcs;
      });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.funcGetCharts();
      });
  }

  changeValuePropName(name: string): void {
    this.changeName = false;
    const payload = {
      name: name || this.vpName.trim(),
    };
    this.ValuePropService.updateValueProp(this.valueProp.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.valueProp.name = response.result.value_prop.name;
          this.reloadVP.emit();
          this.notificationService.success('Name updated successfully', false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  funcGetCharts(): void {
    this.sI.valuePropLoader = true;
    const $scoreCards = this.ValuePropService.getScoreCard(this.valueProp.id).pipe(
      tap((res) => this.funcGetScoreCard(res)),
      catchError(() => of('error'))
    );
    const $chartROI = this.RepSimpleService.chartWithROI(this.valueProp.id).pipe(
      tap((res) => this.funcGetROIChart(res)),
      catchError(() => of('error'))
    );
    const $chartpayback = this.RepSimpleService.getPaybackChart(this.valueProp.id).pipe(
      tap((res) => this.funcGetPaybackChart(res)),
      catchError(() => of('error'))
    );
    const $chartNPV = this.RepSimpleService.getNPVChart(this.valueProp.id).pipe(
      tap((res) => this.funcGetNPVChart(res)),
      catchError(() => of('error'))
    );
    const $chartCostOfInaction = this.RepSimpleService.getCostOfInactionChart(this.valueProp.id).pipe(
      tap((res) => this.funcGet3MOChart(res)),
      catchError(() => of('error'))
    );
    const $chartVC = this.RepSimpleService.getVCChart(this.valueProp.id).pipe(
      tap((res) => this.funcGetVCChart(res)),
      catchError(() => of('error'))
    );
    const $chartTCO = this.RepSimpleService.getTCOChart(this.valueProp.id, !!this.showAllMetrics ? 1 : 0).pipe(
      tap((res) => this.funcGetTCOChart(res)),
      catchError(() => of('error'))
    );

    const arr = [$scoreCards, $chartROI, $chartpayback, $chartNPV, $chartCostOfInaction, $chartVC, $chartTCO];
    forkJoin(arr)
      .pipe(take(1))
      .subscribe(() => (this.sI.valuePropLoader = false));
  }

  refreshTranslation(): void {
    this.getTranslations();
  }

  funcGetScoreCard(response): void {
    this.sI.valuePropLoader = true;

    if (response.result) {
      if (response && response.result && response.result.threshold_types) {
        for (let c = 0; c < response.result.threshold_types.length; c++) {
          const elem = response.result.threshold_types[c];
          switch (elem.keyword) {
            case 'ROI':
              this.colorROI = '4px solid ' + getColor(elem.status_id);
              this.roiStatus = this.getColorText(elem.status_id, elem.min_threshold, elem.max_threshold, elem.margin_threshold);
              this.roiColor = getColor(elem.status_id);
              break;
            case 'NPV':
              this.colorNPV = '4px solid ' + getColor(elem.status_id);
              this.npvStatus = this.getColorText(elem.status_id, elem.min_threshold, elem.max_threshold, elem.margin_threshold);
              this.npvColor = getColor(elem.status_id);

              break;
            case 'PBP':
              this.colorPBP = '4px solid ' + getColor(elem.status_id);
              this.pbpStatus = this.getColorText(elem.status_id, elem.min_threshold, elem.max_threshold, elem.margin_threshold);
              this.pbpColor = getColor(elem.status_id);

              break;
            case 'TCO':
              this.colorTCO = '4px solid ' + getColor(elem.status_id);
          }
        }
      }
    }
    this.sI.valuePropLoader = false;
  }

  funcGetROIChart(res): void {
    const chartColors = this.commonService.getChartColors();

    this.roiText = res.result.roi_formula;
    this.years = res.result.years.length;
    this.chartROI = {
      data: {
        datasets: [
          {
            label: this.trans.trans.benefits.value,
            data: res.result.benefits,
            yAxisID: 'A',
            position: 'left',
            title: this.trans.trans.benefitsAndCosts.value,
            backgroundColor: chartColors[0],
            order: 3,
          },
          {
            label: this.trans.trans.costs.value,
            data: res.result.costs,
            yAxisID: 'A',
            position: 'left',
            title: this.trans.trans.benefitsAndCosts.value,
            backgroundColor: chartColors[1],
            order: 2,
          },
        ],
        labels: res.result.years.map((year) => {
          const yearTrans = this.trans.trans[year];
          if (yearTrans) {
            return yearTrans.value;
          } else {
            return year;
          }
        }),
      },
      d0: res.result.benefits_fmt,
      d1: res.result.costs_fmt,
      d2: res.result.roi_fmt,
    };

    if (!this.feature62) {
      this.chartROI.data.datasets.push({
        label: this.trans.trans.roi.value,
        data: res.result.roi,
        type: 'line',
        yAxisID: 'B',
        position: 'right',
        borderColor: chartColors[2],
        title: this.trans.trans.roi.value,
        fill: false,
        order: 1,
      });
    }
  }

  funcGetTCOChart(res): void {
    const chartColors = this.commonService.getChartColors();

    this.chartTCO = {
      data: {
        datasets: [
          {
            label: this.trans.trans.currentCosts.value,
            data: res.result.current_state,
            backgroundColor: chartColors[0],
          },
          {
            label: this.trans.trans.futureCosts.value,
            data: res.result.future_state,
            backgroundColor: chartColors[1],
          },
        ],
        labels: res.result.years,
      },
      d0: res.result.current_state_fmt,
      d1: res.result.future_state_fmt,
      labels: ['current_state_fmt', 'future_state_fmt'],
    };
    this.tcoText = res.result.tco_formula;
  }

  funcGetPaybackChart(res): void {
    const chartColors = this.commonService.getChartColors();

    this.chartPayback = {
      data: {
        datasets: [
          {
            label: this.trans.trans.netBenefits.value,
            data: res.result.net,
            type: 'line',
            borderColor: chartColors[2],
            fill: false,
          },
        ],
        labels: res.result.years,
      },
      d0: res.result.net_fmt,
    };
    this.paybackText = res.result.payback_formula;
    this.paybackFormulaText = res.result.payback_formula_text;
  }

  funcGetNPVChart(res): void {
    const chartColors = this.commonService.getChartColors();

    this.chartNPV = {
      data: {
        datasets: [
          {
            label: this.trans.trans.npv.value,
            data: res.result.npv_yearly,
            type: 'line',
            borderColor: chartColors[2],
            fill: false,
          },
          {
            label: this.trans.trans.benefits.value,
            data: res.result.benefits,
            backgroundColor: chartColors[0],
          },
          {
            label: this.trans.trans.costs.value,
            data: res.result.costs,
            backgroundColor: chartColors[1],
          },
        ],
        labels: res.result.years,
      },
      d0: res.result.npv_yearly_fmt,
      d1: res.result.benefits_fmt,
      d2: res.result.costs_fmt,
    };
    this.npvText = res.result.npv_formula;
    this.npvFormulaText = res.result.npv_formula_text;
  }

  funcGet3MOChart(res): void {
    const chartColors = this.commonService.getChartColors();

    this.threemoFormula = res.result.formula;
    this.threemoText = res.result.formula_text;
    this.chart3MO = {
      data: {
        datasets: [
          {
            label: this.trans.trans.benefits.value,
            data: [res.result.three_months_cost_of_inaction],
            backgroundColor: chartColors[0],
          },
        ],
        labels: ['Year 1'],
      },
      d0: [res.result.three_months_cost_of_inaction_fmt],
    };
    this.inactionText = res.result.formula;
  }

  funcGetVCChart(res): void {
    this.sI.valuePropLoader = true;
    this.chartVC = null;
    const chartColors = this.commonService.getChartColors();

    if (!res && !res.result && !res.result.names && !res.result.names.length) {
      this.chartVCLegend = [];
      return;
    } else {
      const labels = [];
      for (let i = 0; i < res.result.names.length; i++) {
        labels.push({
          name: res.result.names[i].name,
          color: chartColors[i],
          metrics: res.result.names[i].metrics,
          val: res.result.percents[i],
          fr: res.result.total_benefits_fr[i],
          id: res.result.names[i].id,
          description: res.result.names[i].description,
        });
      }

      this.chartVC = {
        data: {
          datasets: [
            {
              label: this.trans.trans.benefits.value,
              data: res.result.percents,
              backgroundColor: chartColors,
            },
          ],
          labels: labels,
        },
        d0: [res.result.total_benefits_fmt],
      };

      this.benefitTotal = res.result.total_annual_benefits;

      this.chartVCLegend = res.result.names.length ? labels : [];
      this.inactionText = res.result.formula;
      this.buildChart();
      const selectedCategory = this.selectedId || sessionStorage.getItem('benefit_index');
      if (selectedCategory && this.view === 'benefits') {
        res.result.names.map((category) => {
          if (category.id === selectedCategory) {
            category['value_category_id'] = category.id;
            this.clickBenefitClick(category);
          }
        });
      }
    }
    this.sI.valuePropLoader = false;
  }

  clickBenefitClick(item): void {
    const benefit = this.valueProp.benefits.find((benefit) => benefit.value_category_id === item.id);
    sessionStorage.setItem('benefit_index', benefit.value_category_id);

    this.permissions = {
      benefits_editable: true,
      result_editable: true,
    };

    this.benefits.metrics = item.metrics;

    this.selectedCLR = item.color || this.selectedCLR;
    this.selectedName = item.name;
    this.selectedId = benefit.value_category_id;
    this.selectedDesc = item.description;
    this.selectedVal = benefit.vc_total_p2 || this.selectedVal;
    item.metrics = funcFixVal(item.metrics);
    this.view = 'benefits';
  }

  buildChart(): void {
    this.VCchart = {
      labels: this.chartVC.data.labels,
      datasets: this.chartVC.data.datasets,
    };

    this.optionsVC = {
      cutoutPercentage: 80,
      type: 'doughnut',
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const label = data.labels[tooltipItem.index].name + ': ' + data.datasets[0].data[tooltipItem.index] + '%';
            return label;
          },
        },
      },
      animation: {
        duration: 100,
      },
    };

    if (this.valueProp.benefits && sessionStorage.getItem('benefit_index')) {
      const out = this.valueProp.benefits.filter((x) => x.value_category_id === sessionStorage.getItem('benefit_index'))[0];
      this.selectedCLR = out && out.color ? out.color : '#cdcdcd';
      this.selectedName = out && out.name ? out.name : '';
      this.selectedDesc = out && out.description ? out.description : '';
      this.selectedVal = out && out.vc_total_p2 ? out.vc_total_p2 : '';
    } else {
      const vc = this.valueProp.benefits.find((benefitGroup) => (this.VCchart.labels[0] ? benefitGroup.name === this.VCchart.labels[0].name : false));
      this.selectedCLR = this.VCchart.labels && this.VCchart.labels[0] ? this.VCchart.labels[0].color : '#cdcdcd';
      this.selectedName = this.VCchart.labels && this.VCchart.labels[0] ? this.VCchart.labels[0].name : '';
      this.selectedDesc = vc ? vc.description : null;
      this.selectedVal = this.VCchart.labels && this.VCchart.labels[0] ? this.VCchart.labels[0].fr : '';
    }
  }

  lockValueProp(type: string): void {
    if (type === 'lock' && this.valueProp.vp_can_edit) {
      const payload = {
        closed: '1',
      };
      this.ValuePropService.lockValueProp(this.valueProp.id, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.reloadVP.emit();
            this.notificationService.success(this.trans.trans.vpLockedSucces.value, false);
          }
        });
    }
    if (type === 'unlock' && this.valueProp.vp_can_edit) {
      const payload = {
        closed: '0',
      };
      this.ValuePropService.lockValueProp(this.valueProp.id, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.reloadVP.emit();
            this.notificationService.success(this.trans.trans.vpUnlockedSucces.value, false);
          }
        });
    }
  }

  getValuePropStatusTypes(): void {
    this.factsService
      .getValuePropStatusTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.valuePropStatusTypes = [];
        for (let s = 0; s < response.result.length; s++) {
          const elem = response.result[s];
          this.valuePropStatusTypes.push({ label: elem.name, command: () => this.updateValuePropStatus(elem.id) });
        }
      });
  }

  updateValuePropStatus(statusTypeid): void {
    if (!statusTypeid) {
      return;
    }
    const statusTypeObj = { value_prop_status_type_id: statusTypeid };

    this.ValuePropService.updateValuePropStatus(this.valueProp.id, statusTypeObj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success(this.trans.trans.statusUpdateSuccess.value, false);

          this.reloadVP.emit();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  cancelValuePropName(): void {
    this.vpName = this.valueProp.name;
    this.changeName = false;
    this.valueProp.name = this.clonedValuePropName;
  }

  funcChangeView(view: string): void {
    this.view = view;
  }

  funcCloseView(): void {
    this.view = 'case';
    sessionStorage.setItem('repsimple_results_view', this.view);
  }
}
