<p-overlayPanel #op appendTo="body" styleClass="save-overlay">
  <div class="panel panel-overlay">
    <div class="panel-heading" style="background-color: #f5f5f5; height: inherit !important; text-align: center">
      <div class="panel-title">The following are required fields</div>
    </div>
    <div class="panel-body panel-body-overlay" style="max-height: 200px; overflow-y: auto; padding: 20px">
      <div class="row" style="border-bottom: 1px solid #f5f5f5; margin-bottom: 10px" *ngFor="let elem of controlList">
        <div class="col-xs-12">
          <a (click)="funcScrollTo(elem.key)" class="clickable" style="color: red !important">{{ elem.name }}</a>
        </div>
      </div>
    </div>
  </div>
</p-overlayPanel>
<a #actualTarget (click)="funcVerifyForm($event)" [ngClass]="{ 'running': running }" class="ngform_save_btn ld-ext-right {{ class }} xf-continue-button">
  <i *ngIf="icon && !running" class="{{ icon }}"></i> {{ running ? '' : text }}
  <div class="ld ld-ring ld-spin">
    <i class="fa fa-spinner fa-spin fa-fw"></i>
  </div>
</a>
