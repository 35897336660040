
<div class='keywest_page_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>{{trans.trans.viewBenefit.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.viewBenefit" (callback)="getTranslations()"></app-editTranslation>
			</div>
		</div>
		<div class='col-xs-6'>
			<i *ngIf="isDev" (click)='toggleDebug = !toggleDebug' class='fa fa-code pull-right'></i>
		</div>
	</div>
</div>
<div class='sidebar_container_full_page_noscroll'>
	<div class='keywest_panel'>
		<div class="row">
			<div class="col-xs-6">
				<label for="metric_name" class="sidebar_label_readonly">{{trans.trans.name.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.metric_name}}
			</div>
			<div class="col-xs-6">
				<label for="value_category_id" class="sidebar_label_readonly">{{trans.trans.benefitGroupBelong.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.benefitGroupBelong" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.value_category_name}}
			</div>
		</div>

		<div class="row">
			<div class="col-xs-6">
				<label for="description" class='sidebar_label_readonly'>{{trans.trans.description.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.description}}
			</div>
			<div class="col-xs-6">
				<label class="sidebar_label_readonly">{{trans.trans.howImpactYourCustomer.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.howImpactYourCustomer" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.impact_type_name}}
			</div>
		</div>

		<div class="row">
			<div class='col-xs-6'>
				<label class="sidebar_label_readonly">{{trans.trans.isAnnualBenefit.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.isAnnualBenefit" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.accrual_type_name}}
			</div>
		</div>

		<div class="row">
			<div class="col-xs-6">
				<label class="sidebar_label_readonly">{{trans.trans.differentiated.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.differentiated" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				<div class="switch_inline">{{selectedElem.unique_type_id ? trans.trans.yes.value : trans.trans.no.value}}</div>
			</div>

			<div class="col-xs-6">
				<label class="sidebar_label_readonly" for="default_na">{{trans.trans.isDefault.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.isDefault" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				<div class="switch_inline">{{selectedElem.default_na ? trans.trans.yes.value : trans.trans.no.value}}</div>
			</div>
		</div>
	</div>
</div>
<div class='sidebar_container_full_page_noscroll'>
	<div class='keywest_page_header'>
		<div class='row'>
			<div class='col-xs-6'>
				<div class='title clickable'>{{trans.trans.driver.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.driver" (callback)="getTranslations()"></app-editTranslation>
				</div>
			</div>
		</div>
	</div>
	<div class='keywest_panel'>
		<div class='row'>
			<div class='col-xs-4'>
				<label class="sidebar_label_readonly">{{trans.trans.driverFactor.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.driverFactor" (callback)="getTranslations()"></app-editTranslation><span matTooltip='This factor has a scratchpad. Click to view or edit it.' matTooltipPosition="above" (click)='showFactor = true; selectedFactor = selectedElem.driver_factor_id' *ngIf="selectedElem.driver_factor_id && selectedElem.driver_factor_id.has_scratchpad" class="clickable"><i class='fa fa-calculator' style='color: green;'></i></span>
				</label><br>
				{{selectedElem.driver_factor_name}}
			</div>
			<div class='col-xs-4'>
				<label class="sidebar_label_readonly">{{trans.trans.answer.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.answer" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.driver_baseline_answer}}
			</div>
		</div>
		<div class='row'>
			<div class="col-xs-4">
				<label class='sidebar_label_readonly'>{{trans.trans.seedGrowthDecline.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.seedGrowthDecline" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				<div class="switch_inline">{{selectedElem.has_phasing ? trans.trans.yes.value : trans.trans.no.value}}</div>
			</div>
		</div>

		<div *ngIf="selectedElem.has_phasing">
			<div class='row' *ngFor="let term of driver_term; let i = index">
				<div class="col-xs-12">
					<label class='sidebar_label_readonly'>{{trans.trans['year' + (i + 1)].value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans['year' + (i + 1)]" (callback)="getTranslations()"></app-editTranslation>
					</label>
					{{term.val}}
				</div>
			</div>
		</div>
	</div>
</div>

<div class='sidebar_container_full_page_noscroll'>
	<div class='keywest_page_header'>

		<div class='row'>
			<div class='col-xs-6'>
				<div class='title clickable'>{{trans.trans.improvement.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.improvement" (callback)="getTranslations()"></app-editTranslation>
				</div>
			</div>
		</div>
	</div>

	<div class='keywest_panel'>
		<div class='row'>
			<div class='col-xs-4'>
				<label class='sidebar_label_readonly'>{{trans.trans.improvementFactor.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.improvementFactor" (callback)="getTranslations()"></app-editTranslation><span [matTooltip]='trans.trans.hasScratchpad.value' matTooltipPosition="above" (click)='showFactor = true; selectedFactor = selectedElem.improvement_factor_id' *ngIf="selectedElem.improvement_factor_id && selectedElem.improvement_factor_id.has_scratchpad" class="clickable"><i class='fa fa-calculator' style='color: green;'></i></span>
				</label><br>
				{{selectedElem.improvement_factor_name}}
			</div>

			<div class='col-xs-4'>
				<label class="sidebar_label_readonly">{{trans.trans.units.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.units" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.unit_type_name}}
			</div>

			<div class='col-xs-4'>
				<label for="" class='sidebar_label_readonly'>{{trans.trans.currentState.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.currentState" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.current_effort}}
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-4'>
				<label class="sidebar_label_readonly">{{trans.trans.conservative.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.conservative" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.conservative}}
			</div>
			<div class='col-xs-4'>
				<label class="sidebar_label_readonly">{{trans.trans.probable.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.probable" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.probable}}
			</div>
			<div class='col-xs-4'>
				<label class="sidebar_label_readonly">{{trans.trans.aggressive.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.aggressive" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.aggressive}}
			</div>
		</div>
	</div>
</div>

<div class='sidebar_container_full_page_noscroll'>
	<div class='keywest_page_header'>
		<div class='row'>
			<div class='col-xs-6'>
				<div class='title clickable'>{{trans.trans.financial.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.financial" (callback)="getTranslations()"></app-editTranslation>
				</div>
			</div>
		</div>
	</div>

	<div class='keywest_panel'>
		<div class='row'>
			<div class='col-xs-4'>
				<label class='sidebar_label_readonly'>{{trans.trans.financialFactor.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.financialFactor" (callback)="getTranslations()"></app-editTranslation>
					<span matTooltip='This factor has a scratchpad. Click to view or edit it.' matTooltipPosition="above" (click)='showFactor = true; selectedFactor = selectedElem.financial_factor_id' *ngIf="selectedElem.financial_factor_id && selectedElem.financial_factor_id.has_scratchpad" class="clickable"><i class='fa fa-calculator' style='color: green;'></i></span>
				</label><br>
				{{selectedElem.financial_factor_name}}
			</div>

			<div class='col-xs-4'>
				<label class='sidebar_label_readonly'>{{trans.trans.answer.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.answer" (callback)="getTranslations()"></app-editTranslation>
				</label><br>
				{{selectedElem.financial_baseline_answer}}
			</div>
		</div>
	</div>

</div>

<div class='sidebar_container_full_page_noscroll'>
	<div class='keywest_page_header'>
		<div class='row'>
			<div class='col-xs-6'>
				<div class='title clickable'>{{trans.trans.optional.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.optional" (callback)="getTranslations()"></app-editTranslation>
				</div>
			</div>
		</div>
	</div>

	<div class='keywest_panel'>
		<div class='row'>
			<div class='col-xs-6'>
				<div class="row">
					<div class="col-xs-12">
						<label class="sidebar_label_readonly">{{trans.trans.tcoName.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.tcoName" (callback)="getTranslations()"></app-editTranslation>
						</label><br>
						{{selectedElem.tco_name}}
					</div>
				</div>

				<div class="row">
					<div class="col-xs-12">
						<label class="sidebar_label_readonly">{{trans.trans.valueStatemenet.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.valueStatemenet" (callback)="getTranslations()"></app-editTranslation>
						</label><br>
						{{selectedElem.value_statement}}
					</div>
				</div>

				<div class="row">
					<div class="col-xs-12">
						<label class="sidebar_label_readonly">{{trans.trans.featCapabitiltiesHeader.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.featCapabitiltiesHeader" (callback)="getTranslations()"></app-editTranslation>
						</label><br>
						{{selectedElem.features}}
					</div>
				</div>

				<div class="row">
					<div class="col-xs-12">
						<label class='sidebar_label_readonly'>{{trans.trans.funcObj.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.funcObj" (callback)="getTranslations()"></app-editTranslation>
						</label><br>
						{{selectedElem.functional_objectives.name}}
					</div>
				</div>

				<div class="row">
					<div class="col-xs-12">
						<label class='sidebar_label_readonly'>{{trans.trans.expenseType.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.expenseType" (callback)="getTranslations()"></app-editTranslation>
						</label><br>
						{{selectedElem.expense_type_id?.name}}
					</div>
				</div>

				<div class="row">
					<div class="col-xs-12">
						<label class='sidebar_label_readonly'>{{trans.trans.benefitType.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.benefitType" (callback)="getTranslations()"></app-editTranslation>
						</label><br>
						{{selectedElem.benefit_type_id.name}}
					</div>
				</div>

				<div *ngIf="hasFeature84">
					<div class="row" style='margin-bottom: 15px;'>
						<div class="col-xs-12">
							<label class="sidebar_label">{{trans.trans.issues.value}}
								<i *ngIf="!(style2022$ | async)" matTooltip='Include default information for the business case creator to leverage as a starting point' class='fa fa-info-circle'></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="Include default information for the business case creator to leverage as a starting point">
									info
								</span>
							</label>
							{{selectedElem.metric_note_2}}
						</div>
					</div>

					<div class="row" style='margin-bottom: 15px;'>
						<div class="col-xs-12">
							<label class="sidebar_label">{{trans.trans.resolutions.value}}
								<i *ngIf="!(style2022$ | async)" matTooltip='Include default information for the business case creator to leverage as a starting point' class='fa fa-info-circle'></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="Include default information for the business case creator to leverage as a starting point">
									info
								</span>
							</label>
							{{selectedElem.metric_note_3}}
						</div>
					</div>
				</div>
			</div>
			<div class='col-xs-6'>
				<app-dl-dragdrop [canEdit]='can_edit' title='{{trans.trans.dragDropImage.value}}' msg='{{trans.trans.acceptableFormat.value}}' [files]='files' (callback)='files = $event'></app-dl-dragdrop>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<label (click)='impactAreaToggle = !impactAreaToggle' class='sidebar_label_readonly clickable'>
					<i [ngClass]="impactAreaToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i> {{trans.trans.impactBusinessArea.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.impactBusinessArea" (callback)="getTranslations()"></app-editTranslation>
					<span *ngIf="selectedElem.areas && selectedElem.areas.length"> ({{selectedElem.areas.length}} selected)</span>
				</label>
				<app-dl-list-detail [canEdit]='can_edit' title='Impact Areas' *ngIf="impactAreaToggle" key='id' [list]='dropdownValues.readAreaTypes' [selectedList]='selectedElem.areas' (callback)='selectedElem.areas = $event'></app-dl-list-detail>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<label (click)='painPointToggle = !painPointToggle' class='sidebar_label_readonly clickable'>
					<i [ngClass]="painPointToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i> {{trans.trans.painPoints.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.painPoints" (callback)="getTranslations()"></app-editTranslation>
					<span *ngIf="selectedElem.pain_points && selectedElem.pain_points.length"> ({{selectedElem.pain_points.length}} selected)</span>
				</label>
				<app-dl-list-detail [canEdit]='can_edit' title='{{trans.trans.painPointTitle.value}}' *ngIf="painPointToggle" key='account_painpoint_id' [list]='dropdownValues.readPainPoints' [selectedList]='selectedElem.pain_points' (callback)='selectedElem.pain_points = $event'></app-dl-list-detail>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<label (click)='capabilitiesToggle = !capabilitiesToggle' class='sidebar_label_readonly clickable'>
					<i [ngClass]="capabilitiesToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i> {{trans.trans.capabilities.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.capabilities" (callback)="getTranslations()"></app-editTranslation>
					<span *ngIf="selectedElem.capabilities && selectedElem.capabilities.length"> ({{selectedElem.capabilities.length}} selected)</span>
				</label>
				<app-dl-list-detail [canEdit]='can_edit' title='{{trans.trans.capabilitiesTitle.value}}' *ngIf="capabilitiesToggle" key='capabilities_id' [list]='dropdownValues.readAccountCapabilities' [selectedList]='selectedElem.capabilities' (callback)='selectedElem.capabilities = $event'></app-dl-list-detail>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<label (click)='opObjectivesToggle = !opObjectivesToggle' class='sidebar_label_readonly clickable'>
					<i [ngClass]="opObjectivesToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i> {{trans.trans.opObjs.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.opObjs" (callback)="getTranslations()"></app-editTranslation>
					<span *ngIf="selectedElem.operational_objectives && selectedElem.operational_objectives.length"> ({{selectedElem.operational_objectives.length}} selected)</span>
				</label>
				<app-dl-list-detail [canEdit]='can_edit' title='{{trans.trans.opObjTitle.value}}' *ngIf="opObjectivesToggle" key='account_operational_objective_id' [list]='dropdownValues.readAccountOperationalObjectives' [selectedList]='selectedElem.operational_objectives' (callback)='selectedElem.operational_objectives = $event'></app-dl-list-detail>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12">
				<label class='sidebar_label_readonly'>{{trans.trans.benefitPhasing.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.benefitPhasing" (callback)="getTranslations()"></app-editTranslation>
				</label>
			</div>
		</div>
		<div class='row' *ngFor="let term of phasing_term; let i = index">
			<div class="col-xs-12">
				<label class='sidebar_label_readonly'>{{trans.trans['year' + (i + 1)].value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans['year' + (i + 1)]" (callback)="getTranslations()"></app-editTranslation>: {{term.val}}
				</label>
			</div>
		</div>
	</div>

</div>

<p-sidebar [(visible)]='showFactor' styleClass="keywest_sidebar p-sidebar-xl" position='right'>
	<app-ae-scratchpads *ngIf="showFactor && can_edit" (callback)='showFactor = false;' [factor]='selectedFactor'></app-ae-scratchpads>
	<app-r-scratchpads *ngIf="showFactor && !can_edit" (callback)='showFactor = false;' [factor]='selectedFactor'></app-r-scratchpads>
</p-sidebar>

<p-sidebar [(visible)]='toggleDebug' styleClass="keywest_sidebar p-sidebar-xl" position='right'>
	<div class="sidebar_wrapper">
		<div class='sidebar_header'>
			{{trans.trans.debug.value}}
		</div>

		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<pre>
					{{selectedElem | json}}
				</pre>
			</div>
		</div>
	</div>
</p-sidebar>
