<div class='keywest_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>Scalers</div>
		</div>
		<div class='col-xs-6'>
				<button class="btn btn_customizable pull-right" (click)="addScalers()" [matTooltip]="'Add New'" matTooltipPosition="above">
				<i class="fa fa-plus"></i> Add New</button>

			<button class="btn btn_customizable pull-right" (click)="toggleScalersCreate()" [matTooltip]="'Add New'" matTooltipPosition="above" style='margin-right: 10px;'>
			<i class="fa fa-plus"></i> Add New Scaler</button>
	</div>
	</div>
</div>

<div class='row'>
	<div class='col-xs-4'>
		<div class="input-group keywest_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input [(ngModel)]="strSearch" (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='Search' class='form-control'>
			<span *ngIf="strSearch !== ''" (click)="strSearch=''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
		</div>
	</div>
</div>

<p-menu #rowMenu appendTo='body' [popup]="true" [model]="dropdown" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='row'>
	<div class='col-xs-12'>
		<p-table #tableSolutionList [value]="selectedScalers" [columns]="cols" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [pSortableColumn]="col.field">
						{{col.header}}
						<p-sortIcon [field]="col.field"></p-sortIcon>
					</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-scaler>
				<tr>
					<td>
						{{scaler.name}}
						<i *ngIf="scaler.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="scaler.description" matTooltipPosition="above"></i>
						<span *ngIf="scaler.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="scaler.description" matTooltipPosition="above">
							info
						</span>
					</td>
					<td>{{scaler.value}}</td>
					<td (click)='funcBuildMenu(scaler, rowMenu, $event)'>
						<i class='fa fa-ellipsis-v clickable' style='font-size: 18px;'></i>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-sidebar [(visible)]="toggleAddEditNewScaler" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="resetValues()">
<form name="addEditScalerForm" #addEditScalerForm="ngForm">

	<div class="sidebar_wrapper">
		<div class="sidebar_header">
			{{sidebar_title}}
		</div>


	<div class="sidebar_container">
		<div class="keywest_panel">

			<div class="row">
				<div class="col-xs-12">
					<label for="alterEgo" class="sidebar_label">Scalers</label>
					<div class="row">
						<div class="col-xs-12" appNGError [elemControl]="addEditScalerForm.controls.scaler">
							<div *ngIf="mode !== 'edit'">
								<input type="hidden" name="scaler" [(ngModel)]="addScalerObj.scaler" ret-data="Scaler" required>
								<p-dropdown appendTo="body" class="form-control" [options]="scalers" dataKey="value" name="scaler" [(ngModel)]="addScalerObj.scaler" placeholder="Scaler" [filter]="true" filterBy="label"></p-dropdown>
						</div>

						<div *ngIf="mode === 'edit'">
							<input type="hidden" name="scaler" class="form-control" name="scaler" [(ngModel)]="addScalerObj.scaler">
							<span *ngIf="addScalerObj.scaler" class="form-control">{{addScalerObj.scaler.name}}</span>
						</div>

						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-xs-12" appNGError [elemControl]="addEditScalerForm.controls.value">
					<label for="value" class="sidebar_label">Value</label>
					<input type="number" class="form-control" name="value" [(ngModel)]="addScalerObj.value" ret-data="Value" placeholder="Value" required>
				</div>
			</div>

		</div>

		<div class='btn_container'>
			<div class='row'>
				<div class="col-md-12">
					<app-btn-save *ngIf="toggleAddEditNewScaler" [form]='addEditScalerForm' (callback)='editScalers(addEditScalerForm)' text='Save' class='btn btn_customizable pull-right'></app-btn-save>
				</div>
			</div>
		</div>

	</div>
	</div>
</form>
</p-sidebar>

<p-sidebar [(visible)]="toggleCreateNewScaler" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="resetValues()">
	<form name="createNewScalerForm" #createNewScalerForm="ngForm">
		<div class="sidebar_wrapper">
			<div class="sidebar_header">
				{{sidebar_title}}
			</div>

			<div class="sidebar_container">
				<div class="keywest_panel">

					<div class="row">
						<div class="col-xs-12" appNGError [elemControl]="createNewScalerForm.controls.name">
							<label for="name" class="sidebar_label">Name</label>
							<input type="text" name="name" class="form-control" ret-data="Name" [(ngModel)]="accountScaler.name" required>
						</div>
					</div>

					<div class="row">
						<div class="col-xs-12" appNGError [elemControl]="createNewScalerForm.controls.description">
							<label for="description" class="sidebar_label">Description</label>
							<textarea class="form-control" name="description" [(ngModel)]="accountScaler.description" ret-data="Description" ></textarea>
						</div>
					</div>

				</div>
			</div>

			<div class="btn_container">
				<div class="row">
					<div class="col-xs-12">
						<app-btn-save *ngIf="toggleCreateNewScaler" [form]="createNewScalerForm" (callback)="createAccountScalers(createNewScalerForm)" class='btn btn_customizable pull-right' text="Save"></app-btn-save>
					</div>
				</div>
			</div>

		</div>
	</form>
</p-sidebar>