import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pluck, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { CustomAssetTagsTranslation } from './custom-asset-tags.translation';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { AssetTags, Tag } from '@shared/models/asset-tag.models';
import { NotificationService } from '@services/notification.service';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';
import { FormControl } from '@angular/forms';

export type TranslationStrings = keyof typeof CustomAssetTagsTranslation.prototype.trans;

@Component({
  selector: 'app-custom-asset-tags',
  templateUrl: './custom-asset-tags.component.html',
  styleUrls: ['./custom-asset-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAssetTagsComponent implements OnInit, OnDestroy {
  public t: { [key in TranslationStrings]: string };
  public tagSelected: Tag;
  public breadcrumbKeyValues: Breadcrumbs;
  public showTranslate = false;
  public isOpen = false;
  private unsubscribe$ = new Subject<void>();

  /**
   * @description Creates an observable that retrieves an array of tags from a service, filters out any tags that do not have a "result" property, extracts the "result" property, and then maps over the array of tags to add a new "active" property based on the value of the original "active" property of each tag. The observable updates when the "refreshProperty" BehaviorSubject emits a new value.
   * @param refreshProperty BehaviorSubject that emits a new value when the observable should update
   * @param arrayName string that is used to retrieve the array of tags from the service
   * @returns an observable that emits an array of tags
   **/

  private observableFactory = (refreshProperty: BehaviorSubject<boolean>, arrayName: string): Observable<Tag[]> => {
    return refreshProperty.pipe(
      switchMap((_) =>
        this.customAssetTagService.getAssetTags(arrayName).pipe(
          filter((tags) => tags && tags.result),
          pluck('result'),
          map((tags) => {
            let out = [];
            Object.keys(tags).forEach((key) => {
              const tag = tags[key];
              out.push({ ...tag, active: tag.active === '1' ? true : false });
            });
            return out;
          })
        )
      )
    );
  };

  private factorAssetTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private benefitAssetTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private benefitAssetGroupTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private benefitAssetTypeTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private functionalObjectiveTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private capabilityTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private ooTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private ppTagsRefresh$ = new BehaviorSubject<boolean>(false);
  private costTagsRefresh$ = new BehaviorSubject<boolean>(false);

  public years = Array.from({ length: 10 }, (_v, k) => k + 1);
  public phasingYears = Array.from({ length: 11 }, (_v, k) => k);

  public factorAssetTags$ = this.observableFactory(this.factorAssetTagsRefresh$, AssetTagArray.Factors);
  public benefitAssetTags$ = this.observableFactory(this.benefitAssetTagsRefresh$, AssetTagArray.Benefits);
  public benefitGroupAssetTags$ = this.observableFactory(this.benefitAssetGroupTagsRefresh$, AssetTagArray.BenefitGroups);
  public benefitTypeAssetTags$ = this.observableFactory(this.benefitAssetTypeTagsRefresh$, AssetTagArray.BenefitTypes);
  public functionalObjectiveTags$ = this.observableFactory(this.functionalObjectiveTagsRefresh$, AssetTagArray.FunctionalObjectives);
  public capabilityTags$ = this.observableFactory(this.capabilityTagsRefresh$, AssetTagArray.Capabilities);
  public ooTags$ = this.observableFactory(this.ooTagsRefresh$, AssetTagArray.OperationalObjectives);
  public ppTags$ = this.observableFactory(this.ppTagsRefresh$, AssetTagArray.PainPoints);
  public costTags$ = this.observableFactory(this.costTagsRefresh$, AssetTagArray.Costs);
  public searchInput = new FormControl('');
  public searchString = '';

  constructor(
    private customAssetTagService: CustomAssetTagService,
    public trans: CustomAssetTagsTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getTranslations();

    this.searchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), startWith(''), takeUntil(this.unsubscribe$)).subscribe((searchTerm: string) => {
      this.searchString = searchTerm;
      this.cd.markForCheck();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  resetSearch() {
    this.searchInput.reset();
    this.cd.detectChanges();
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
        this.t = this.trans.t;
        this.breadcrumbKeyValues = {
          [this.t.dashboard]: { routerLink: '/dashboard', active: false },
          [this.t.settings]: { routerLink: '/settings', active: false },
          [this.t.customAssetTags]: { routerLink: '/custom-asset-tags', active: true },
        };
        this.cd.markForCheck();
      });
  }

  public editAssetTag(tag: Tag): void {
    this.tagSelected = tag;
    this.isOpen = true;
  }

  public deleteTag(id: string): void {
    this.customAssetTagService
      .deleteAssetTag(id)
      .pipe(take(1))
      .subscribe(
        () => {
          this.notify('deleteSuccess');
          this.getTags();
        },
        () => {
          this.notify('deleteError', 'error');
        }
      );
  }

  public onHide(event: boolean): void {
    this.isOpen = event;
  }

  private formatPayload(tag: Tag): AssetTags {
    let tags = [];
    tags.push({
      ...tag,
      active: tag.active ? '1' : '0',
    });
    return {
      tags,
    };
  }

  public getTags(arrayName = null): void {
    if (!arrayName) {
      this.factorAssetTagsRefresh$.next(true);
      this.benefitAssetTagsRefresh$.next(true);
      this.benefitAssetGroupTagsRefresh$.next(true);
      this.benefitAssetTypeTagsRefresh$.next(true);
      this.functionalObjectiveTagsRefresh$.next(true);
      this.capabilityTagsRefresh$.next(true);
      this.ooTagsRefresh$.next(true);
      this.ppTagsRefresh$.next(true);
      this.costTagsRefresh$.next(true);
      return;
    }
    switch (arrayName) {
      case AssetTagArray.Factors:
        this.factorAssetTagsRefresh$.next(true);
        break;
      case AssetTagArray.Benefits:
        this.benefitAssetTagsRefresh$.next(true);
        break;
      case AssetTagArray.BenefitGroups:
        this.benefitAssetGroupTagsRefresh$.next(true);
        break;
      case AssetTagArray.BenefitTypes:
        this.benefitAssetTypeTagsRefresh$.next(true);
        break;
      case AssetTagArray.FunctionalObjectives:
        this.functionalObjectiveTagsRefresh$.next(true);
        break;
      case AssetTagArray.Capabilities:
        this.capabilityTagsRefresh$.next(true);
        break;
      case AssetTagArray.OperationalObjectives:
        this.ooTagsRefresh$.next(true);
        break;
      case AssetTagArray.PainPoints:
        this.ppTagsRefresh$.next(true);
        break;
      case AssetTagArray.Costs:
        this.costTagsRefresh$.next(true);
        break;
      default:
        break;
    }
  }

  public onUpdateActive(tag: Tag) {
    const payload = this.formatPayload(tag);
    this.customAssetTagService
      .editAssetTag(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (!tag.active) {
          this.notify('statusOff', 'warning');
          setTimeout(() => {
            this.notify('saveSuccess');
          }, 3000);
        } else {
          this.notify('saveSuccess');
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private notify(transString: TranslationStrings, type: 'error' | 'success' | 'warning' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
