import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueCategory } from '@data/services/valueposition/models/value-category.interface';
import { NotificationService } from '@services/notification.service';
import { of, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { ModelV2EAValueCategoryTranslations } from './ae_value_category.translation';
import { snakeCase } from 'lodash';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';
@Component({
  selector: 'app-ae-value-category',
  templateUrl: './ae_value_category.component.html',
})
export class AEValueCategoryComponent implements OnInit, OnDestroy {
  @Input() solutionId: number | string;
  @Input() tabInit: number;
  @Input() can_edit = false;
  @Input() mode = 'add';
  @Output() callback = new EventEmitter();
  @Output() closeCallback = new EventEmitter();

  toggleAddEditCategory = false;
  @ViewChild('formAddEditCategory') formAddEditCategory: NgForm;
  sidebar_title: { value?: string } = {};

  ngUnsubscribe$ = new Subject();
  feature52 = false;

  @Input() selectedElem: ValueCategory = {
    name: '',
    description: '',
    percent_driven: null,
    fastfill_enabled: null,
    id: '',
  };

  account_id: string;
  showTranslate = false;

  isLoading = false;

  constructor(
    private solutionService: SolutionService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    public trans: ModelV2EAValueCategoryTranslations,
    private translationService: TranslationsV2Service,
    private customAssetTagService: CustomAssetTagService
  ) {}

  ngOnInit(): void {
    this.feature52 = this.commonService.checkFeature(52);
    if (this.selectedElem.percent_driven == '1') {
      this.selectedElem.percent_driven = true;
    } else {
      this.selectedElem.percent_driven = false;
    }
    if (this.selectedElem.fastfill_enabled == '1') {
      this.selectedElem.fastfill_enabled = true;
    } else {
      this.selectedElem.fastfill_enabled = false;
    }

    this.account_id = sessionStorage.getItem('aid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_value_category : this.trans.trans.edit_value_category;
      });
  }

  insertValueCategory(form: NgForm): void {
    this.isLoading = true;
    const payload = {
      name: form.controls.name.value,
      tco_name: form.controls.tconame.value,
      description: form.controls.description.value,
      percent_driven: form.controls.percent_driven && form.controls.percent_driven.value ? 1 : 0,
      fastfill_enabled: form.controls.fastfill_enabled && form.controls.fastfill_enabled.value ? 1 : 0,
    };

    this.solutionService
      .addNewValueCategory(payload, this.account_id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((benefitRes) => {
          if (this.selectedElem.has_tag) {
            const payload = this.customAssetTagService.createTagPayload(
              benefitRes.result.value_category_id,
              this.selectedElem.tag_data?.name ?? this.selectedElem.nameTag,
              this.selectedElem.tag,
              AssetTagArray.BenefitGroups,
              true
            );

            return this.customAssetTagService.createAssetTag(payload);
          } else {
            return of(benefitRes);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((response) => {
        if (response?.result && response.result === 'The tags must be unique.') {
          this.notify('tagMustBeUnique', 'error');
          return;
        }
        this.notificationService.success(this.trans.trans.addSuccessful.value, false);
        if (this.formAddEditCategory) {
          this.formAddEditCategory.reset();
        }
        this.toggleAddEditCategory = false;
        this.callback.emit('');
      });
  }

  updateValueCategory(form: NgForm): void {
    this.isLoading = true;
    const payload = {
      value_category_id: form.controls.id.value,
      name: form.controls.name.value,
      tco_name: form.controls.tconame.value,
      description: form.controls.description.value,
      percent_driven: form.controls.percent_driven && form.controls.percent_driven.value ? 1 : 0,
      fastfill_enabled: form.controls.fastfill_enabled && form.controls.fastfill_enabled.value ? 1 : 0,
    };

    this.solutionService
      .editSolutionValueCategory(payload, this.account_id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        switchMap((response) => {
          if (this.selectedElem.has_tag) {
            const assetId = this.selectedElem.tag_data?.id;
            const payload = this.customAssetTagService.createTagPayload(
              this.selectedElem.id,
              this.selectedElem.tag_data?.name ?? this.selectedElem.nameTag,
              this.selectedElem.tag,
              AssetTagArray.BenefitGroups,
              true,
              assetId
            );
            return this.customAssetTagService.editAssetTag(payload);
          } else {
            return of(response);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((response) => {
        if (response.result) {
          if (response?.result && response.result === 'The tags must be unique.') {
            this.notify('tagMustBeUnique', 'error');
            return;
          }
          this.notify('updateSuccessful');
          this.callback.emit('');
          this.toggleAddEditCategory = false;
        }
      });
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.selectedElem = {
        ...this.selectedElem,
        nameTag: this.selectedElem.name,
        tag: snakeCase(this.selectedElem.name),
      };
    } else if (this.selectedElem.tag_data?.id) {
      this.notificationService.success(this.trans.trans.removeTag.value, false);
    }
  }
  private notify(transString: string, type: 'error' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
