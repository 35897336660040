import { Component, Input, OnDestroy } from '@angular/core';
import { BenefitValidation } from '@shared/models/benefits.models';
import { Subject } from 'rxjs';
import { ValidationTranslations } from '../validation.translations';
import { TranslationStrings } from './benefit-validation-table/benefit-validation-table.component';

interface ValidationOption {
  key: string;
  value: string;
}
@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnDestroy {
  public t: { [key in TranslationStrings]: string };
  _trans: ValidationTranslations;
  @Input() set trans(val: ValidationTranslations) {
    if (val) {
      this._trans = val;
      this.t = val.t;
      this.setTranslations();
    }
  }
  get trans() {
    return this._trans;
  }

  @Input() validations: BenefitValidation;

  public validationOptions: ValidationOption[] = [];
  public selectedValidationOptions: string[] = [];
  public accordions = {
    duplicateNames: true,
    fuctionalObjectives: false,
    operationalObjectives: false,
    capabilities: false,
    painPoints: false,
    features: false,
  };
  public benefits1 = ['Benefit-One'];
  public benefits2 = ['Benefit-Two'];

  private unsubscribe$ = new Subject<void>();

  private setTranslations() {
    this.validationOptions = [
      { value: this.t.fuctionalObjectives, key: 'fuctionalObjectives' },
      { value: this.t.operationalObjectives, key: 'operationalObjectives' },
      { value: this.t.capabilities, key: 'capabilities' },
      { value: this.t.painPoints, key: 'painPoints' },
      { value: this.t.featuresCapabilities, key: 'features' },
    ];
    this.selectedValidationOptions = this.validationOptions.map((option) => option.key);
    this.onSelectionChange();
  }

  public onSelectionChange() {
    this.resetAccordians();
    this.selectedValidationOptions.forEach((option: string) => (this.accordions[option] = true));
  }

  private resetAccordians() {
    this.accordions = {
      duplicateNames: true,
      fuctionalObjectives: false,
      operationalObjectives: false,
      capabilities: false,
      painPoints: false,
      features: false,
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
