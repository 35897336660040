import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';

import { NotificationService } from '@services/notification.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '@data/services/common/common.service';
import { PartnersProfileTranslations } from './partners.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { Owner } from '@shared/models/owner.model';
import {Partner, PartnerQueryInfo, PartnersFieldData} from '@shared/models/partner.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { RowLimit } from 'app/_models/row-limit.model';
import { MenuItem } from 'primeng/api';
interface ApplicationDropdownSettings {
  singleSelection: boolean;
  text: string;
  enableCheckAll: boolean;
  enableSearchFilter: boolean;
  labelKey: string;
  lazyLoading: boolean;
}
@Component({
  selector: 'app-partners',
  styleUrls: ['./partners.component.scss'],
  templateUrl: './partners.component.html',
  providers: [AccountService],
})
export class PartnersComponent implements OnInit, OnDestroy {
  hidePartnersList = true;
  partnerNoData = false;
  partnersTableLoader = false;
  ownerList: Owner[] = [];
  fullImagePath: string;
  availPartnerList: Partner[] = [];
  partnerTypeList: (PartnersFieldData & Partial<Partner>)[] = [];
  partner: { [klass: string]: any };
  availPartner: Partner;
  partnerTypes: string[] = [];
  selectedOwner: Owner;
  selectedPartner: Partner;
  showActionLoader = false;
  showNewPartner = false;
  hideModalActionLoader = true;
  partnersListdata: PartnerQueryInfo[];
  loadPartnersTable = false;
  newPartnerType = '';
  partnerTypeDes = '';
  showEditPartner = false;
  isAddingPartner = false;
  deletePartnerId: string;
  notificationMessage = '';
  modalReference: NgbModalRef;
  dropdownList: { [klass: string]: any }[] = [];
  selectedItems: { id: string }[] = [];
  dropdownSettings: ApplicationDropdownSettings;
  items: { [klass: string]: any }[];
  breadcrumbKeyValues: Breadcrumbs;

  page: number;
  pageLimit = 10;
  order = 'desc';
  sortBy = 'created';
  showTranslate = false;

  subscriptionlistPartners: Subscription;
  subscriptiongetPartnersFieldData: Subscription;
  subscriptiondeletePartners: Subscription;
  subscriptioncreatePartner: Subscription;
  subscriptioncreateNewPartnerType: Subscription;
  subscriptionupdatePartner: Subscription;

  ngUnsubscribe = new Subject();

  strSearch = '';
  rowLimit: MenuItem[] = [];

  toggleEditPartner = false;

  style2022$: Observable<boolean>;

  constructor(
    private AccountService: AccountService,
    private notification: NotificationService,
    private modalService: NgbModal,
    private CommonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: PartnersProfileTranslations,
    private styleService: StyleService
  ) {
    this.fullImagePath = this.CommonService.getLoaderImageUrl();
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;

    this.getTranslations();
    this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.listPartnersForm();
    this.loadDataTable();
    this.funcBuildPageLimit();
    this.items = [];
  }

  ngOnDestroy() {
    if (this.subscriptionlistPartners) {
      this.subscriptionlistPartners.unsubscribe();
    }
    if (this.subscriptiongetPartnersFieldData) {
      this.subscriptiongetPartnersFieldData.unsubscribe();
    }
    if (this.subscriptiondeletePartners) {
      this.subscriptiondeletePartners.unsubscribe();
    }
    if (this.subscriptioncreatePartner) {
      this.subscriptioncreatePartner.unsubscribe();
    }
    if (this.subscriptioncreateNewPartnerType) {
      this.subscriptioncreateNewPartnerType.unsubscribe();
    }
    if (this.subscriptionupdatePartner) {
      this.subscriptionupdatePartner.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.manage_partners.value]: { routerLink: '', active: true },
        };
      });
  }

  setRowLimit(num) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit == 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit == 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit == 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit == 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  loadDataTable() {
    this.partnersTableLoader = true;
    const queryString = '/limit/all';

    this.subscriptionlistPartners = this.AccountService.listPartners(queryString).subscribe((result) => {
      this.partnersTableLoader = false;
      if (result.result.data) {
        this.partnerNoData = false;

        this.partnersListdata = result.result.data;
        this.partnersListdata.forEach((item) => {
          item.partnerTypeList = item.PartnerType.split(',');
          item.showtruncate = true;
          if (item.partnerTypeList.length > 4) {
            item.showtruncateinitial = true;
          } else {
            item.showtruncateinitial = false;
          }
          item['truncatedpartnertype'] = item.partnerTypeList.slice(0, 4);
        });
        this.loadPartnersTable = true;
      } else {
        this.partnersListdata = [];
        this.partnerNoData = true;
        this.loadPartnersTable = true;
      }
    });
  }

  expandlist(index) {
    for (let i = 0; i < this.partnersListdata.length; i++) {
      if (i == index) {
        if (this.partnersListdata[i].showtruncate) {
          this.partnersListdata[i]['truncatedpartnertype'] = this.partnersListdata[i].partnerTypeList;
          this.partnersListdata[i].showtruncate = false;
        } else {
          this.partnersListdata[i]['truncatedpartnertype'] = this.partnersListdata[i].partnerTypeList.slice(0, 4);
          this.partnersListdata[i].showtruncate = true;
        }
      }
    }
  }

  getPartnersList(order) {
    this.loadPartnersTable = false;

    if (order == 'changeOrder') {
      this.sortBy = 'created';
      this.order = 'desc';
    } else {
      this.loadDataTable();
    }
  }

  addPartner() {
    this.isAddingPartner = true;
    this.toggleEditPartner = !this.toggleEditPartner;
  }

  cancelPartner() {
    this.hidePartnersList = true;
    this.resetFormFields();
  }

  listPartnersForm() {
    const accId = sessionStorage.getItem('aid');
    this.getOwners(accId);
    this.getPartners(accId);
    this.subscriptiongetPartnersFieldData = this.AccountService.getPartnersFieldData(accId, 'partnerTypes').subscribe(
      (result) => {
        if (result.result) {
          this.partnerTypeList = result.result;
          this.items = this.partnerTypeList.map((type) => {
            return {
              label: type.name,
              value: type,
            };
          });
        }
      },
      (error) => this.notification.error(error['result'], false)
    );
  }
  getOwners(accId) {
    this.subscriptiongetPartnersFieldData = this.AccountService.getPartnersFieldData(accId, 'owners').subscribe(
      (result) => {
        this.ownerList = result['result']['data'];
        if (result['result']['data'].length > 0) {
          this.selectedOwner = this.ownerList[0];
        }
      },
      (error) => this.notification.error(error['result'], false)
    );
  }
  getPartners(accId) {
    this.subscriptiongetPartnersFieldData = this.AccountService.getPartnersFieldData(accId, 'partners').subscribe(
      (result) => {
        this.availPartnerList = result['result'];
        if (result['result'].length > 0) {
          this.selectedPartner = this.availPartnerList[0];
        }
      },
      (error) => this.notification.error(error['result'], false)
    );
  }
  deletePartner(id) {
    this.hideModalActionLoader = false;
    this.subscriptiondeletePartners = this.AccountService.deletePartners(id).subscribe((result) => {
      this.notification.success(result.result, false);
      this.getPartnersList('noChangeOrder');

      this.modalReference.close();
    });
  }

  open(content, id) {
    this.deletePartnerId = '';
    this.deletePartnerId = id;
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {},
      () => {}
    );
  }
  ngOnSubmit(form: NgForm) {
    this.showActionLoader = true;
    if (form.valid) {
      const formdata = {
        owner_id: this.selectedOwner.id,
        partner_id: this.selectedPartner.account_id,
        partner_type_id: this.partnerTypes.toString(),
      };
      if (this.showEditPartner) this.updatePartner(formdata);
      else this.createPartner(formdata);
    }
  }
  createPartner(formdata) {
    this.subscriptioncreatePartner = this.AccountService.createPartner(formdata).subscribe(
      (result) => {
        this.actionAftSave(result);
      },
      (error) => {
        this.notification.error(error['result'], false);
        this.showActionLoader = false;
        this.resetFormFields();
      }
    );
  }
  resetFormFields() {
    this.selectedOwner = this.ownerList[0];
    this.selectedPartner = this.availPartnerList[0];
    this.partnerTypes = [];
    this.selectedItems = [];
  }

  onOwnerSelect(event: { originalEvent: PointerEvent; value: Owner }) {
    this.selectedOwner = event.value;
  }

  onPartnerSelect(event: { originalEvent: PointerEvent; value: Partner }) {
    this.selectedPartner = event.value;
  }

  onCheck(event) {
    const id = event.id;
    this.partnerTypes.push(id);
  }

  onUncheck(event) {
    const id = event.id;

    const index = this.partnerTypes.indexOf(id);
    if (index > -1) this.partnerTypes.splice(index, 1);
  }

  onPartnerTypeChange() {
    this.partnerTypes = this.selectedItems.map((item) => item.id);
  }

  addPartnerType() {
    this.resetFormFields();
    this.showNewPartner = true;
    this.hidePartnersList = true;
  }

  hidePartnerType() {
    this.showNewPartner = false;
    this.hidePartnersList = false;
    this.newPartnerType = '';
    this.partnerTypeDes = '';
  }

  savePartnerType(form: NgForm) {
    if (form.valid) {
      this.showActionLoader = true;
      const formdata = {
        name: form.value.newPartnerType,
        description: form.value.partnerTypeDes,
      };
      this.subscriptioncreateNewPartnerType = this.AccountService.createNewPartnerType(formdata).subscribe(
        (result) => {
          this.notification.success('Partner Type created successfully', false);
          const newpart = { id: result.result, name: formdata.name, description: formdata.description };
          this.partnerTypeList.push(newpart);
          this.hidePartnerType();
          form.reset();
          this.showActionLoader = false;
        },
        (error) => {
          this.notification.error(error.result, false);
          this.showActionLoader = false;
        }
      );
    }
  }

  editPartner(partner) {
    this.isAddingPartner = false;
    this.toggleEditPartner = !this.toggleEditPartner;
    this.hidePartnersList = false;
    this.selectedOwner = this.ownerList.filter((element) => element.id === partner.owner_id)[0];

    this.selectedPartner = this.availPartnerList.filter((element) => element.account_id === partner.partner_id)[0];

    const part_types = [];
    partner.partner_type_ids = partner.PartnerTypeId.split(',');
    this.partnerTypes = partner.partner_type_ids;
    partner.partner_type_ids.forEach((id) => {
      this.partnerTypeList.filter((part) => {
        if (id === part.id) part_types.push(part);
      });
    });
    this.selectedItems = part_types;
  }

  updatePartner(formdata) {
    this.subscriptionupdatePartner = this.AccountService.updatePartner(formdata, this.selectedPartner.account_id).subscribe(
      (result) => {
        this.actionAftSave(result);
        this.toggleEditPartner = false;
      },
      (error) => {
        this.notification.error(error['result'], false);
        this.showActionLoader = false;
      }
    );
  }

  actionAftSave(result) {
    this.notification.success(result.result, false);
    this.showActionLoader = false;
    this.hidePartnersList = true;
    this.getPartnersList('changeOrder');
    this.resetFormFields();
  }
}
