

	<form name="addQuoteForm" #addQuoteForm="ngForm">
		<div class="sidebar_wrapper">
			<div class='sidebar_header' *ngIf="sidebar_title && sidebar_title.value">
				{{sidebar_title.value}}
			</div>

			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class="row">
						<div class='col-xs-6' appNGError [elemControl]="addQuoteForm.controls.contact_first_name">
							<label class="sidebar_label">{{ trans.trans.first_name.value }}
								<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
								[transObj]="trans.trans.first_name" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type="text" class="form-control" name="contact_first_name" ret-data="First Name" [(ngModel)]="quoteDetails.first_name" required>
						</div>

						<div class='col-xs-6' appNGError [elemControl]="addQuoteForm.controls.contact_last_name">
							<label class="sidebar_label">{{ trans.trans.last_name.value }}
								<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
								[transObj]="trans.trans.last_name" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type="text" class="form-control" ret-data="Last Name" name="contact_last_name" [(ngModel)]="quoteDetails.last_name" required>
						</div>
					</div>
					<div class='row'>
						<div class='col-xs-12' appNGError [elemControl]="addQuoteForm.controls.title">
							<label class="sidebar_label">{{ trans.trans.title.value }}
								<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
								[transObj]="trans.trans.title" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type="text" class="form-control" ret-data="Title" name="title" [(ngModel)]="quoteDetails.title" required>
						</div>
					</div>
					<div class="row">
						<div class='col-xs-12' appNGError [elemControl]="addQuoteForm.controls.quote">
							<label class="sidebar_label">{{ trans.trans.testimonial.value }}
								<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
								[transObj]="trans.trans.testimonial" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<input type='hidden' name='account_case_study_quote_id' [(ngModel)]="quoteDetails.account_case_study_quote_id">
							<textarea class="form-control keywest_textarea" name="quote" ret-data="Quote" [(ngModel)]="quoteDetails.quote" required></textarea>
						</div>
					</div>

				</div>
			</div>

			<div class='btn_container'>
				<div class="col-md-12">
					<app-btn-save [form]='addQuoteForm' (callback)='addQuote(addQuoteForm)' [text]='trans.trans.save.value' class='btn btn_customizable pull-right'></app-btn-save>
					<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
				</div>
			</div>

		</div>
	</form>