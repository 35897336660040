import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '@data/services/company/company.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Location } from '@angular/common';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserService } from '@data/services/user/user.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './add-value-position.translation';
import { CompanyDetails } from '@data/services/valueposition/models/company-details.interface';
import { AllScaler } from '@data/services/valueposition/models/all-scaler.interface';
import { MasterScaler } from '@data/services/valueposition/models/master-scaler.interface';
import { AvailableSolution } from '@data/services/valueposition/models/available-solutions.interface';
import { VpProject, VpProjectChildren } from '@data/services/valueposition/models/master-vp-projects.interface';
import { ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';


@Component({
  selector: 'app-add-value-position',
  templateUrl: './add-value-position.component.html',
  styleUrls: ['./add-value-position.component.scss'],
  providers: [CompanyService, ValuepositionService, MasterValuePropService],
})
export class AddValuePositionComponent implements OnInit, OnDestroy {

  @Input() dealdesk: boolean;
  @Input() masterValueProp: boolean;
  @Input() isvalueProp: boolean;
  @Output() quickCreate = new EventEmitter();
  @Output() masterValuePropCreate = new EventEmitter();

  isVMWare = false;

  q1 = false;  // show the question
  q1answer = '';  // the questions answer
  q1Finished = false;  // we're done with the question - show the next panel

  q2 = false;
  q2answer = '';
  q2Finished = false;
  detailedDescription = true;
  companyId: number;
  companyDetails: CompanyDetails = {};
  showActionLoader = false;
  showActionLoaderSituation = false;
  showActionLoaderModel = false;
  showActionLoaderScaler = false;
  showModelsLoader = false;
  finishandReviewButton = false;
  showSituation = false;
  showCategoryLoader = false;
  selectedScalersList: AllScaler[] = [];
  valuePropId: string | number;
  fullImagePath: string;
  quickCompanyDetailsDescription = true;
  image_url: string;
  addvaluePositionLoader = false;
  scalersLoader = false;
  showScalers = false;
  addValueForm = true;
  quickFinish = true;
  allScalersList: AllScaler[] = [];
  MasterScalerList: MasterScaler[] = [];
  selectedModels: Array<{solutionId: string | number; quantity: string | number}> = [];
  step2Wrapper = true;
  step3Wrapper = true;
  categoryType: string | number;
  availableSolutions: AvailableSolution[] = [];
  scalersCreated: boolean;
  solutionCategoryList: Array<{id: string | number, category: string | number}> = [];
  showCategoryTypeLoader = false;
  selectedQuantity: Array<number | string> = [];
  selectedSolutionsLength = 0;
  modalReference: NgbModalRef;
  crm: {
    sfoide?: string;
    company_id?: number;
    opportunity_id?: string;
    account_defined_id?: string
  } = {};
  sfoide: string;
  ValuePropTerm: number;

  masterCalculateLoading = false;

  // contextualHelp = {};
  situationLength = 0;

  projectFeature = false;
  feature26 = false;

  projects: Array<string | number>;
  availableProjects: Array<{name?: string; id?: string | number; isChecked?: boolean}>;
  projectToggle = false;
  projModToggle = false;
  productToggle = false;
  cameFromProjects = false;
  VMWareprojectInit = false;
  projectInit = false;
  solutionMode: number;


  stepNumber: number;
  @ViewChild('stepMoveBackConfirmation', { static: true }) stepMoveBackConfirmation: ElementRef;

  // Master Value Props
  masterValuePropId: string | number;
  showMasterValuePropProjects = false;
  availableMasterValuePropProjects: VpProject[];
  selectedProjects: VpProjectChildren[] = [];
  showActionLoaderProjects = false;

  // Discovery
  guidedDiscoveryModal: NgbModalRef;
  discoveryHeader = 'Mandatory Discovery Questions';

  // Oppurtunity Feature
  isOppurtunityEnabled = false;
  aid: string;
  opportunityId: string;
  accountDefinedId: string;

  contextualHelpVP: Array<{[key: string]: any}> = [];
  ngUnsubscribe$ = new Subject();
  showTranslate = false;
  feature68 = false;

  valueProp: ValueProp;
  style2022$: Observable<boolean>;

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private valuepositionService: ValuepositionService,
    private commonService: CommonService,
    private solutionService: SolutionService,
    private modalService: NgbModal,
    private valuepropService: ValuepropService,
    private factsService: FactsService,
    private location: Location,
    private masterValuePropService: MasterValuePropService,
    private userService: UserService,
    private translationService: TranslationsV2Service,
    public trans: DefaultTranslations,
    private styleService: StyleService

  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    if (sessionStorage.getItem('crm')) {
      this.crm = JSON.parse(sessionStorage.getItem('crm'));
      this.sfoide = this.crm.sfoide;
      if (this.crm.company_id) {
        this.companyId = this.crm.company_id;
        this.getCompanyDetail();
      }

      if (this.crm.opportunity_id) {
        this.opportunityId = this.crm.opportunity_id;
      }
      if (this.crm.account_defined_id) {
        this.accountDefinedId = this.crm.account_defined_id;
      }
    }
  }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.feature68 = this.commonService.checkFeature('68');
    this.scalersCreated = false;

    this.userService.getContextualHelpList().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if (result.result) {
        this.commonService.contextualHelp.next(result.result);
      }
    });

    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      // this.contextualHelp = response['Model'];
      this.contextualHelpVP = response['Valueposition'];

    });

    this.aid = sessionStorage.getItem('aid');


    if (this.commonService.checkFeature('13')) {
      this.projectFeature = true;
    }

    if (this.commonService.checkFeature('22')) {
      this.isOppurtunityEnabled = true;
      this.VMWareprojectInit = true;
      this.projectInit = false;
    }
    if (this.aid === '20') {
      this.isVMWare = true;
    } else {
      this.isVMWare = false;
      this.q1Finished = true;
      this.q2Finished = true;
    }

    if (this.commonService.checkFeature('26')) {
      this.feature26 = true;
    }

    if (this.projectFeature) {
      this.productToggle = false;
      if (!this.VMWareprojectInit) {
        this.projectInit = true;
      }
      this.solutionMode = 1;
      this.projects = [];
      this.getProjects();
    } else {
      this.productToggle = true;
    }

    this.valuepropService.guidedDiscoverySave.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      this.discoveryFlow(response);
    });

    if (this.aid !== '20') {
      this.VMWareprojectInit = false;
      //I do not like that this variable is named this. Company search is not a vmware-specific feature -- Lewis 2019.06.04
    }

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
  getTranslations(): void {

    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
      this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
    });
  }

  funcSaveQ1(): void {
    if (!this.q1answer) { return; }
    const payload = { answer: this.q1answer };
    this.valuepropService.putVMWareQ1(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
    this.q1Finished = true;
  }

  funcSaveQ2(): void {
    if (!this.q2answer) { return; }
    const payload = { answer: this.q2answer };
    this.valuepropService.putVMWareQ2(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      window.scrollTo(0, document.body.scrollHeight);
    });
    this.q2Finished = true;
  }

  createMasterScaler(): void {
    this.valuepropService.createMasterScaler(this.masterValuePropId, {}).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  vmWareNext(): void {
    if (this.aid === '20') {
      if (!this.opportunityId) {
        this.notificationService.warning('An Opportunity ID is required', false);
        return;
      }
      if (this.opportunityId.length < 8) {
        this.notificationService.warning('Opportunity ID must be 8 numbers long', false);
        return;
      }
      this.VMWareprojectInit = false;
      this.projectInit = true;
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  toggleProject(mode, content?): void {
    // tslint:disable-next-line: radix
    this.solutionMode = parseInt(mode);

    if (this.solutionMode === 1) {
      this.projectToggle = true;
      this.productToggle = false;
      this.projectInit = false;
    }

    if (this.solutionMode === 2) {
      this.projectToggle = false;
      this.productToggle = true;
      this.projectInit = false;
    }

    if (this.solutionMode === 3) {
      this.guidedDiscoveryModal = this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    }
  }

  discoveryFlow(selectedProjects): void {
    this.guidedDiscoveryModal.close();
    this.projectToggle = true;
    this.productToggle = false;
    this.projectInit = false;

    this.availableProjects.forEach(project => {
      if (selectedProjects.indexOf(project['id']) !== -1) {
        // tslint:disable-next-line: radix
        this.projects.push(+project['id']);
        project['isChecked'] = true;
      }
    });
  }


  getProjects(): void {
    this.factsService.getProjects().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      this.availableProjects = result.result;
    });
  }

  checkProjects(params, event): void {
    if (event.target.checked === true) {
      this.projects.push(params);
    } else if (event.target.checked === false) {
      const i = this.projects.indexOf(event.target.value);
      this.projects.splice(i, 1);
    }
  }

  saveProjects(): void {
    const params = {
      projects: this.projects
    };

    this.valuepropService.createProjects(this.valuePropId, params).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if (result) {
        this.availableSolutions = [];
        this.solutionService.getAvailableSolutionsPlain(this.valuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {

          if (result.result) {
            this.showModelsLoader = true;
            this.availableSolutions = result.result;

            if (this.availableSolutions) {
              this.availableSolutions = this.availableSolutions.map(item => {
                item['quantity'] = 1;
                return item;
              });
            }
            this.showModelsLoader = false;
            this.getSolutionCategoryList();
            this.projModToggle = true;
          }
        });
      }
    });
  }

  getSolutionCategoryList(): void {
    this.solutionService.getSolutionCategoryListwithVPID().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      this.solutionCategoryList = result.result;
      this.categoryType = this.solutionCategoryList[0]['id'];
      if (this.categoryType) {
        this.getSolution(this.solutionCategoryList[0]['id']);
      }
    });
  }

  getAvailableSolutions(): void {
    this.showModelsLoader = true;

    this.solutionService.getAvailableSolutionsPlain(this.valuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
      this.availableSolutions = result.result;
      if (this.availableSolutions) {
        this.availableSolutions = this.availableSolutions.map(item => {
          item['quantity'] = 1;
          return item;
        });
      }
      this.showModelsLoader = false;
      this.getSolutionCategoryList();
    });
  }

  getSolution(categoryId): void {
    this.showCategoryTypeLoader = true;
    this.selectedSolutionsLength = 0;
    if (this.availableSolutions) {
      this.availableSolutions = this.availableSolutions.map(item => {
        item['selectedCategory'] = false;
        if (item.solution_category_id != null && (item.solution_category_id.indexOf(categoryId) >= 0)) {
          this.selectedSolutionsLength++;
          item['selectedCategory'] = true;
        }
        return item;
      });

      this.showCategoryTypeLoader = false;
    }
  }

  getCompanyDetail(): void {
    this.showActionLoader = true;
    this.companyService.getCompanySummary(this.companyId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
      this.companyDetails = result.result.company;
      this.showActionLoader = false;
    });
  }

  afterCompanyDetail(companyId): void {
    this.companyId = companyId;
    if (this.masterValueProp) {

      if (this.crm.sfoide) {

        const params = {
          'company_id': this.companyId,
          'sfoide': this.crm.sfoide
        };
        this.valuePropId = 0;
        this.createMasterValueProp(params);
      } else {
        const params = {
          'company_id': this.companyId
        };
        this.valuePropId = 0;
        this.createMasterValueProp(params);
      }

      this.getAvailableSolutions();
    } else {
      let valueProp = {};
      const cxo = sessionStorage.getItem('cxo_deck');

      if (this.crm.sfoide) {
        valueProp = {
          'company_id': this.companyId,
          'sfoide': this.crm.sfoide,
          'is_value_prop': this.isvalueProp ? true : false,
          cxo_deck: cxo
        };

        if (this.companyId !== undefined) {
          this.valuepositionService.createValueProp(valueProp).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
            if (result.result) {
              this.valuePropId = result.result.value_prop.id;
              this.valueProp = result.result.value_prop;
              this.ValuePropTerm = result.result.value_prop['term'];
              this.getAvailableSolutions();
              if (this.isOppurtunityEnabled) {
                this.saveOppurtunityId();
              }
            }
          });
        }
      } else {
        if (this.aid === '25012') {
          if (this.crm.opportunity_id && !this.opportunityId) {
            valueProp = {
              'company_id': this.companyId,
              'opportunity_id': this.crm.account_defined_id,
              'is_value_prop': this.isvalueProp ? true : false
            };
          } else if (this.accountDefinedId && !this.opportunityId) {
            valueProp = {
              'company_id': this.companyId,
              'account_defined_id': this.accountDefinedId,
              'is_value_prop': this.isvalueProp ? true : false
            };
          } else if (this.opportunityId && this.accountDefinedId) {
            valueProp = {
              'company_id': this.companyId,
              'opportunity_id': this.opportunityId,
              'account_defined_id': this.accountDefinedId,
              'is_value_prop': this.isvalueProp ? true : false
            };
          } else {
            valueProp = {
              'company_id': this.companyId,
              'is_value_prop': this.isvalueProp ? true : false
            };
          }
        } else {

          valueProp = {
            'company_id': this.companyId,
            'is_value_prop': this.isvalueProp ? true : false
          };
        }


        if (this.companyId !== undefined) {
          this.valuepositionService.createValueProp(valueProp).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
            if (result.result) {
              this.valuePropId = result.result.value_prop.id;
              this.valueProp = result.result.value_prop;
              this.ValuePropTerm = result.result.value_prop['term'];
              this.getAvailableSolutions();
              if (this.isOppurtunityEnabled) {
                this.saveOppurtunityId();
              }
            }
          });
        }
      }
    }

    this.q1 = true;
  }

  checkModels(params, quantity, event): void {
    if (event.target.checked === true) {
      this.showCategoryLoader = true;
      const tempModel = {
        'solutionId': params,
        'quantity': quantity
      };

      this.selectedModels.push(tempModel);
    } else if (event.target.checked === false) {
      this.situationLength = 0;

      this.selectedModels = this.selectedModels.filter((impact: any) => {
        return impact['solutionId'] !== params;
      });

    }
  }

  quantityAssign(id, quantityValue): void {
    const quantityCheck = this.selectedModels.filter(item => {
      return item['solutionId'] == id;
    });

    if (quantityCheck.length > 0) {
      for (let i = 0; i < this.selectedModels.length; i++) {
        if (this.selectedModels[i]['solutionId'] == id) {
          this.selectedModels[i]['quantity'] = quantityValue;
        }
      }
    }
  }

  createSolution(): void {
    if (this.showSituation) {
      this.resetStepsBelowConfirmation(2);
    }

    if (this.selectedModels) {
      const selectedModels = this.selectedModels.map((impact) => {
        return impact['solutionId'];
      });

      const selectedQuantity = this.selectedModels.map((impact) => {
        return impact['quantity'];
      });

      this.showActionLoaderModel = true;

      if (this.masterValueProp) {
        const payload = {
          'account_solution_id': selectedModels.join(',')
        };

        this.masterValuePropService.saveSolutions(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
          if (result.result) {
            this.showActionLoaderModel = false;
            this.readAvailableMasterValuePropProjects();
            this.showMasterValuePropProjects = true;
          }
        });
      } else {
        const payload = {
          'account_solution_id': selectedModels.join(','),
          'value_prop_id': this.valuePropId,
          'quantity': selectedQuantity.join(','),
          'delete_vp_solutions': 1
        };
        this.valuepositionService.quickCreateSolution(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
          if (result.result) {
            this.showActionLoaderModel = false;
          }
          this.showSituation = true;
        });
      }

    }
    this.q2 = true;
  }

  masterScalerCalculate(): void {
    const payload = { scalers: [] };
    this.masterCalculateLoading = true;

    for (let i = 0; i < this.MasterScalerList.length; i++) {
      payload.scalers.push({
        master_scale_type_id: this.MasterScalerList[i].master_scale_type_id,
        value: this.MasterScalerList[i].value
      });
    }


    this.masterValuePropService.modifyMasterScaler(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {

      this.masterCalculateLoading = false;
      this.getScalers();
    });
  }

  getScalers(): void {
    this.showActionLoader = true;
    if (this.masterValueProp) {
      this.masterValuePropService.readAvailableScalers(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
        this.allScalersList = result.result.scalers;

        this.showActionLoader = false;
      });

    } else {
      this.valuepositionService.getAvailableScalers(this.valuePropId + '/angularDev/1/includeDns/0/testing/0').pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
        if (result.result && result.result.all_scalers && result.result.all_scalers.length > 0) {
          this.allScalersList = result.result.all_scalers;
          this.MasterScalerList = result.result.master_scalers;
          this.addValueForm = true;

        }
        this.showActionLoader = false;
      });

    }
    this.showScalers = true;

  }

  createScaler(type?): void {
    if (this.allScalersList && this.allScalersList != null && this.allScalersList.length > 0) {
      this.selectedScalersList = this.allScalersList.filter((scaler: any) => {
        return scaler.value;
      });

      const scalerPayload = {
        'value_prop_scalers': this.allScalersList,
        'angular_dev': 1,
        'from_dealdesk': 1
      };
      this.showActionLoaderScaler = true;

      if (this.masterValueProp) {
        this.masterValuePropService.createScalers(this.masterValuePropId, scalerPayload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
          if (result.result) {
            this.scalersCreated = true;
          }
          this.showActionLoaderScaler = false;
          this.afterScalerSave();
        });

      } else {
        this.valuepositionService.quickCreateScaler(this.valuePropId, scalerPayload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
          if (result.result) {
            this.scalersCreated = true;
          }
          this.showActionLoaderScaler = false;

          this.afterScalerSave(type);
        });

      }

    } else {
      this.afterScalerSave();
    }
  }

  afterScalerSave(type?): void {
    if (this.masterValueProp) {
      this.router.navigate(['/mastervalueprop', this.masterValuePropId]);
      return;
    }

    if (!this.dealdesk || type === 'finishandreview') {
      this.router.navigate(['/valueprop', this.valuePropId]);
    }

    if (this.dealdesk) {
      this.quickCreate.emit({
        'valuePropId': this.valuePropId,
        'ValuePropTerm': this.ValuePropTerm
      });
    }
  }

  quantityEnabled(event): void {
    event.preventDefault();
  }

  backClicked(): void {
    this.location.back();
  }

  resetStepsBelow(): void {
    // Update Step 2 : Models
    if (this.stepNumber === 2) {
      this.showSituation = false;
      this.createSolution();
    }

    // Update Step 3 : Situation, next step getScalers will be emitted from situation component itself
    if (this.stepNumber === 3) {
      this.showScalers = false;
    }
  }

  resetStepsBelowConfirmation(stepNumber): void {
    this.stepNumber = stepNumber;
    this.modalReference = this.modalService.open(this.stepMoveBackConfirmation, { size: 'lg', windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  createMasterValueProp(params): void {
    this.masterValuePropService.createMasterValueProp(params).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      this.masterValuePropId = result.result.master_value_prop.id;
    });
  }

  readAvailableMasterValuePropProjects(): void {
    this.showActionLoaderProjects = true;
    this.availableMasterValuePropProjects = [];
    this.masterValuePropService.readAvailableProjects(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      if (response.result && response.result.projects) {
        this.availableMasterValuePropProjects = response.result.projects;
      }
      this.showActionLoaderProjects = false;
    });
  }

  moveSelectedProject(): void {
    this.selectedProjects = [];

    this.availableMasterValuePropProjects.forEach(projects => {
      if (projects['children'] && projects['children'].length > 0) {
        projects['children'].forEach(children => {
          if (children['selected']) {
            children['parent'] = projects['name'];
            this.selectedProjects.push(children);
          }
        });
      }
    });
  }

  removeSelectedProject(project_id): void {
    const index = this.selectedProjects.findIndex(project => project['id'] == project_id);

    if (index !== -1) {
      this.selectedProjects.splice(index, 1);
    }
  }

  createProjects(): void {
    const payload = {
      'account_project_id': this.selectedProjects.map(project => project['id']).join(',')
    };

    this.showActionLoaderProjects = true;
    this.masterValuePropService.createProjects(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {

      this.showActionLoaderProjects = false;
      this.getScalers();
    });
    this.q2 = true;
  }

  changeDiscoveryHeader(txt): void {
    this.discoveryHeader = txt;
  }

  saveOppurtunityId() {
    const payload = {
      'opportunity_id': this.opportunityId
    };

    this.valuepropService.updateValuePropOpportunityId(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {

    });
  }
}
