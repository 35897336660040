import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class StyleService {
  accountPrivileges = sessionStorage.getItem('privileges');
  accountFeatures = sessionStorage.getItem('features');

  private style2022$ = new BehaviorSubject<boolean>(true);

  private dashboard$ = new BehaviorSubject<boolean>(this.checkDashboard());

  get style2022(): Observable<boolean> {
    return this.style2022$ as Observable<boolean>;
  }

  get dashboard(): Observable<boolean> {
    return this.dashboard$ as Observable<boolean>;
  }

  updateStyle2022() {
    this.style2022$.next(true);
  }

  updateDashboard() {
    this.dashboard$.next(this.checkDashboard());
  }

  private checkDashboard(): boolean {
    const feature53 = this.checkFeature53();
    const salesManager = this.checkManagerRole();
    return feature53 && salesManager ? true : false;
  }

  private checkFeature53(): boolean {
    const list = sessionStorage.getItem('features');
    const listArr = list ? list.split(',') : [];
    return listArr.includes('53');
  }

  public checkManagerRole(): boolean {
    const list = sessionStorage.getItem('rid');
    return list === '3' ? true : false;
  }
}
