<div class="rsv2-sc-component panel" style="margin-bottom: 20px">
  <div class="rsv2-sc-heading panel-heading">
    <span>
      {{ trans.trans.models.value }}
      <i *ngIf="!hasModels" [matTooltip]="trans.trans.tooltipRequire.value" container="body" placement="right" class="fa fa-exclamation-triangle" style="color: red"></i>
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.models"
        (callback)="getTranslations()"
      ></app-editTranslation>
      <i
        [ngClass]="{ 'icon-primary': style2022$ | async }"
        *ngIf="valueProp.closed == '0' && canEdit"
        (click)="showModelsEditing = !showModelsEditing"
        class="fa fa-pencil pull-right clickable"
      ></i>
    </span>
  </div>
  <div class="rsv2-sc-body panel-body">
    <dl-progressbar class="dl_progress" *ngIf="showCategoryTypeLoader" [showValue]="true" [value]="trans.trans.loadingModels.value" mode="indeterminate"></dl-progressbar>

    <div *ngIf="!hasModels">
      {{ trans.trans.no_models.value }}
    </div>

    <div *ngFor="let item of availableSolutions">
      <div *ngIf="item.isChecked" class="models">
        {{ item.name }}
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="showModelsEditing" [dismissible]="true" [showCloseIcon]="true" *ngIf="showModelsEditing" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.models.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.models"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel grid">
        <!-- Select Category -->
        <div class="row">
          <div class="col-xs-12">
            <label>
              {{ trans.trans.choose_category_type.value }}
            </label>
            <select
              *ngIf="solutionCategoryList && solutionCategoryList.length"
              type="text"
              (change)="getSolution(selectedSolutionCategoryId)"
              class="form-control"
              name="categoryType"
              [(ngModel)]="selectedSolutionCategoryId"
            >
              <option *ngFor="let solutionCategory of solutionCategoryList" [value]="solutionCategory.id">
                {{ solutionCategory.category }}
              </option>
            </select>

            <div class="text-center">
              <img class="actionLoader" *ngIf="showCategoryTypeLoader" [src]="fullImagePath" />
            </div>

            <div class="col-xs-12" *ngIf="availableSolutions && selectedSolutionsLength == 0 && !showModelsLoader">
              <p class="well">
                {{ trans.trans.no_models_category.value }}
              </p>
            </div>
          </div>
        </div>

        <!-- Solutions table -->
        <div class="row">
          <div class="col-xs-12">
            <div class="input-group keywest_search">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input #search [(ngModel)]="strSearch" type="text" [placeholder]="trans.trans.placeholderSearch.value" class="form-control" />
            </div>

            <p-table
              #solutionsTable
              [globalFilterFields]="['name']"
              [value]="availableSolutions | functionPipe: selectedCategorySolutions | functionPipe: filterSolutions:strSearch"
              [columns]="cols"
              styleClass="keywest_table"
              tableStyleClass="keywest_table"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr>
                  <td>
                    <!-- Is editable -->
                    <ng-container *ngIf="item.quantity_enabled == '1'">
                      <ng-container (click)="item.showeditable = !item.showeditable; item.tempQuantity = item.quantity" *ngIf="!item.showeditable">
                        <span *ngIf="item.quantity" class="quantityCheck">{{ item.quantity }}</span>
                      </ng-container>

                      <div *ngIf="item.showeditable" class="input-group" style="max-width: 150px; float: left">
                        <input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity" />
                        <div class="input-group-btn">
                          <button (click)="quantityAssign(item.id, item.quantity); item.showeditable = !item.showeditable" class="btn btn-success">
                            <i class="fa fa-check"></i>
                          </button>
                          <button (click)="item.quantity = item.tempQuantity; item.showeditable = !item.showeditable" class="btn btn-danger">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>

                      &nbsp;of
                    </ng-container>

                    <!-- Feature 54 On - Single-->
                    <p-radioButton
                      class="radio-item"
                      name="radio-item"
                      *ngIf="hasRadioButtonFeature"
                      [value]="true"
                      [(ngModel)]="item.isChecked"
                      (onClick)="checkModels(item.id, item.quantity, item.isChecked)"
                    >
                    </p-radioButton>

                    <!-- Feature 54 off - Multiple-->
                    <p-checkbox *ngIf="!hasRadioButtonFeature" [binary]="true" [(ngModel)]="item.isChecked" (onChange)="checkModels(item.id, item.quantity, item.isChecked)">
                    </p-checkbox>

                    <p class="solution-name" [ngClass]="{ keywest_radiobutton: hasRadioButtonFeature }">
                      {{ item.name }}
                      <i class="fa fa-info-circle" *ngIf="item.description && !(style2022$ | async)" [matTooltip]="item.description"></i>
                      <span *ngIf="item.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description"> info </span>
                    </p>
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <app-no-records *ngIf="!availableSolutions || availableSolutions.length === 0">
              {{ trans.trans.noRecords.value }}
            </app-no-records>
          </div>
        </div>
      </div>

      <div [ngClass]="{ row: crm, btn_container: !crm }">
        <div class="col-xs-12">
          <button class="btn btn_customizable pull-right" [disabled]="showActionLoaderModel" (click)="createSolution()">
            <ng-container *ngIf="!showActionLoaderModel; else loadingSpinner">
              {{ trans.trans.save.value }}
            </ng-container>
            <ng-template #loadingSpinner>
              <i class="fa fa-spinner fa-spin"></i>
            </ng-template>
          </button>

          <app-are-you-sure
            class="btn cancel-confirm pull-right marg-h"
            *ngIf="!showActionLoaderModel"
            matTooltipPosition="above"
            [message]="trans.trans.are_you_sure.value"
            icon=""
            [text]="trans.trans.back.value"
            (callbackYes)="closeSolution()"
          >
          </app-are-you-sure>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
