import { Injectable } from '@angular/core';

@Injectable()

export class RSV2CreateTranslations {

	public config: any = {
		component: 'RSV2Nav',
		display_name: 'Rep Simple Navigation',
		description: 'Rep simple section'
	};

	public trans: any = {
		content1: "Let's create a Value Prop.",
		content2: "Start with the basics below and then we'll get into the details."
	};
}
