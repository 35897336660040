import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Observable, of, Subject } from 'rxjs';
import { ModelCategory } from '@data/services/account/models/model-category.interface';
import { APIResult, PagedAPIResult } from '@shared/models/result-response.model';
import { CaseStudy } from '@shared/models/case-study.model';
import { ValuePropCollateral } from '@shared/models/collateral.model';
import { FeedbackResponse, Solution } from '@shared/models/value-prop.model';
import { BasicInfo } from '@shared/models/basic-info.model';
import { ExpenseType } from '../valueprop/models/cost.model';
import { CreateCostTagData, TagData } from '@shared/models/asset-tag.models';
import { ChartSolutionCategoryResponse, ModelsOverviewTotals } from '@shared/models/solution.models';
import { BenefitType } from '@shared/models/benefits.models';
import { TreeNode } from 'primeng/api';
import { map, pluck } from 'rxjs/operators';
import { ValueCategory } from '../valueposition/models/value-category.interface';
import { CloneBenefitsResponse } from './models/vp-solution.interface';
import { SituationItem, SituationTreeBySolution, SolutionData } from '@shared/models/situation.model';

@Injectable()
export class SolutionService {
  constructor(private commonService: CommonService) {}
  public readAllValueCategory = new Subject<string>();
  public readSolution = new Subject<string>();
  public readCaseStudy = new Subject<string>();
  public triggerTabs = new Subject<string>();
  public triggerCost = new Subject<string>();
  public triggerBenefit = new Subject<string>();
  public readSolutionShareType = new Subject<string>();
  public readAllProjects = new Subject<string>();

  public refreshModelsTotals = new Subject<any>();

  public gotoAddModel = new Subject<any>();
  public gotoAddSituation = new Subject<any>();
  public gotoAddFactor = new Subject<any>();

  private situationTrees = new Map<string, SolutionData[]>();

  public setSituationTreeMap(id: string, situations: SolutionData[]): void {
    this.situationTrees.set(id, situations);
  }

  public removeSituationTreeMap(id: string): void {
    this.situationTrees.delete(id);
  }

  public destroySituationTreeMap() {
    this.situationTrees = new Map();
  }

  public getExpenseTypes(): Observable<APIResult<ExpenseType[]>> {
    let endpoint = 'fact/expenseTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getFactorList(account_solution_id) {
    let endpoint = 'solutions/distinctFactors/' + account_solution_id;
    return this.commonService.getAPIService(endpoint);
  }

  public updateFactorList(account_solution_id, payload) {
    let endpoint = 'solutions/updateFactorsAcrossSolution/' + account_solution_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getBenefitsDropdownAggregate(account_id, payload) {
    let endpoint = 'account/benefitAggregateServices/' + account_id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getSolutions(searchLimit) {
    let endpoint = 'solutions/solutionList' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public getAvailableSolutions(searchLimit) {
    let endpoint = 'solutions/availableSolutionsShort' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }

  public getAvailableSolutionsFull(searchLimit) {
    let endpoint = 'solutions/availableSolutions' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }

  public getModelsOverviewTotals(account_id: string): Observable<APIResult<ModelsOverviewTotals>> {
    let endpoint = 'solutions/readModelOverviewTotals/' + { account_id };
    return this.commonService.getAPIService(endpoint);
  }

  public getShares(model_id) {
    let endpoint = 'account/modelAdmins/' + model_id;
    return this.commonService.getAPIService(endpoint);
  }

  public cloneModel(account_solution_id, payload) {
    let endpoint = 'solutions/cloneSolution/' + account_solution_id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public postCreateShare(payload) {
    let endpoint = 'solutions/createAccountSolutionShare';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public chartSolutionCategoryChart(): Observable<APIResult<ChartSolutionCategoryResponse>> {
    let endpoint = 'solutions/solutionCategorychartV2';
    return this.commonService.getAPIService(endpoint);
  }
  public chartTop5Models() {
    let endpoint = 'solutions/topFiveSolutionsV2';
    return this.commonService.getAPIService(endpoint);
  }

  public deleteSolution(id) {
    let endpoint = 'solution/delete/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getSolutionCategoryList(): Observable<{ result: ModelCategory[] }> {
    let endpoint = 'solutions/solutionCategories';
    return this.commonService.getAPIService(endpoint);
  }

  public getSolutionCategoryListwithVPID() {
    let endpoint = 'solutions/vpSolutionCategoriesList';
    return this.commonService.getAPIService(endpoint);
  }
  public getSolutionCategoryChart() {
    let endpoint = 'solutions/solutionCategorychart';
    return this.commonService.getAPIService(endpoint);
  }
  public createSolutionCategory(params) {
    let endpoint = 'solutions/solutionCategories/add';
    return this.commonService.postAPIService(endpoint, params);
  }
  public createSolutionModel(params) {
    let endpoint = 'solution/create';
    return this.commonService.postAPIService(endpoint, params);
  }

  public updateSolutionCategory(params) {
    let endpoint = 'solutions/solutionCategories/update';
    return this.commonService.putAPIService(endpoint, params);
  }

  public deleteSolutionCategory(params) {
    let endpoint = 'solutions/solutionCategory/delete/' + params;
    return this.commonService.deleteAPIService(endpoint);
  }
  public getTopUsedSolutions() {
    let endpoint = 'valueProps/solutionsUsedInValueProps/' + sessionStorage.getItem('uid');
    return this.commonService.getAPIService(endpoint);
  }
  public getSolutionDetail(id) {
    let endpoint = 'solutions/details/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public updateSolutionDetail(id, params) {
    let endpoint = '/solutions/accountSolutionDetails/update/' + id;
    return this.commonService.putAPIService(endpoint, params);
  }

  public readBenefitsByValueCategory(value_category_id: string, solution_id: number): Observable<APIResult<ValueCategory[]>> {
    let endpoint = 'solutions/benefitsByValueCategory/valueCategoryId/' + value_category_id + '/id/' + solution_id;
    return this.commonService.getAPIService(endpoint);
  }
  public readBenefitsByValueCategoryAndProject(value_category_id, project_id, solution_id) {
    // tslint:disable-next-line:max-line-length
    let endpoint = 'solutions/benefitsByValueCategory/valueCategoryId/' + value_category_id + '/projectId/' + project_id + '/id/' + solution_id;
    return this.commonService.getAPIService(endpoint);
  }
  public createDriverFactorSubTypes(params) {
    let endpoint = 'solutions/createDriverFactorSubtypes/';
    return this.commonService.postAPIService(endpoint, params);
  }
  public getDriverFactorSubTypes(id) {
    let endpoint = 'solutions/driverFactorSubtypes/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public deleteDriverFactorSubTypes(id) {
    let endpoint = 'solutions/deleteDriverFactorSubtypes/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }
  public createSolutionScratchpad(params) {
    let endpoint = 'solutions/scratchpad/create/';
    return this.commonService.postAPIService(endpoint, params);
  }

  public getScratchpadCaclulation(scratchpad) {
    const endpoint = '/solutions/readScratchpadTotal';
    return this.commonService.postAPIService(endpoint, scratchpad);
  }

  public getSolutionsScratchpads(factorTypeID, factorID, metricID) {
    let endpoint = 'solutions/readAccountScratchpad/factorTypeID/' + factorTypeID + '/factorID/' + factorID + '/metricID/' + metricID;
    return this.commonService.getAPIService(endpoint);
  }

  public deleteSolutionsScratchpads(factorTypeID, factorID, metricID) {
    let endpoint = 'solutions/deleteScratchpad/factorTypeID/' + factorTypeID + '/factorID/' + factorID + '/metricID/' + metricID;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getCollateralList(id): Observable<APIResult<ValuePropCollateral[]>> {
    let endpoint = 'solutions/accountSolutionAssets/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public createCollateralList(params) {
    let endpoint = 'solutions/accountSolutionAsset/create/';
    return this.commonService.postAPIService(endpoint, params);
  }
  public editCollateral(id) {
    let endpoint = 'solutions/accountSolutionAsset/assetID/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public updateCollateral(params) {
    let endpoint = 'solutions/accountSolutionAsset/update/';
    return this.commonService.putAPIService(endpoint, params);
  }
  public deleteCollateral(id) {
    let endpoint = 'solutions/accountSolutionAsset/delete/assetID/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getSolutionCompetitorList(id) {
    let endpoint = 'solutions/solutionCompetitors/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public createCompetitor(params) {
    let endpoint = 'solutions/solutionCompetitor/create/';
    return this.commonService.postAPIService(endpoint, params);
  }
  public editSolutionCompetitor(id) {
    let endpoint = 'solutions/competitor/read/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public updateCompetitor(params) {
    let endpoint = 'solutions/solutionCompetitor/update';
    return this.commonService.putAPIService(endpoint, params);
  }
  public deleteSolutionCompetitor(competitorId) {
    let endpoint = 'solutions/solutionCompetitor/delete/' + competitorId;
    return this.commonService.deleteAPIService(endpoint);
  }
  public getCompetitors(id) {
    let endpoint = 'account/competitor/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public getAvailableSolutionsPlain(valuePropId) {
    let endpoint = 'solutions/availableSolutionsPlain/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  public getAvailableSolutionsShare(valuePropId: string): Observable<APIResult<Solution[]>> {
    let endpoint = 'valueProps/solutions/' + valuePropId;
    return this.commonService.getAPIService(endpoint);
  }

  //threshold
  public getThresholds(accountid, account_solution_id) {
    let endpoint = 'account/solutionThresholds/' + accountid + '/account_solution_id/' + account_solution_id;
    return this.commonService.getAPIService(endpoint);
  }
  public SaveThreshold(params) {
    let endpoint = 'account/solutionThreshold/create';
    return this.commonService.postAPIService(endpoint, params);
  }
  public deleteThreshold(accountSolutionid, thresholdTypeId) {
    let endpoint = 'account/deleteSolutionThreshold/' + accountSolutionid + '/accountSolutionThresholdID/' + thresholdTypeId;
    return this.commonService.deleteAPIService(endpoint);
  }
  //account asset solutions

  public getAvailableScalers(id, excludeDns) {
    let endpoint = 'solutions/availableScalers/' + id + '/excludeDns/' + excludeDns;
    return this.commonService.getAPIService(endpoint);
  }

  public getAllScaleTypes() {
    let endpoint = 'solutions/readAllScaleTypes';
    return this.commonService.getAPIService(endpoint);
  }
  public updateScalers(id, params) {
    let endpoint = 'solutions/accountSolutionScaler/create/' + id;
    return this.commonService.postAPIService(endpoint, params);
  }
  public deleteScaler(solutionId, scalerId) {
    let endpoint = 'solutions/accountSolutionScaler/delete/' + solutionId + '/scaleTypeId/' + scalerId;
    return this.commonService.deleteAPIService(endpoint);
  }
  public getSolutionCosts(id) {
    let endpoint = 'solutions/solutionCosts/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public createSolutionCosts(params) {
    let endpoint = 'solutions/cost/add/';
    return this.commonService.postAPIService(endpoint, params);
  }
  public updateSolutionCosts(params) {
    let endpoint = 'solutions/cost/update/';
    return this.commonService.putAPIService(endpoint, params);
  }
  public deleteSolutionCosts(id) {
    let endpoint = 'solutions/cost/delete/1/accountSolutionCostID/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }
  public editSolutionCosts(id) {
    let endpoint = 'solutions/solutionCost/read/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public getCostCompetitors(account_id) {
    let endpoint = `account/competitor/${account_id}/limit/all/page/all/search/all/sortby/created/order/desc`;
    return this.commonService.getAPIService(endpoint);
  }
  public addNewCostCategory(params) {
    let endpoint = 'account/CostCategory/create';
    return this.commonService.postAPIService(endpoint, params);
  }
  public createSolution(params) {
    let endpoint = 'solution/create';
    return this.commonService.postAPIService(endpoint, params);
  }
  public getSolutionSituations(id) {
    let endpoint = 'solutions/readAccountSolutionSituations/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public getIsSolutionAdmin() {
    let privilegeOption = sessionStorage.getItem('privileges');
    let options = privilegeOption.split(',');
    if (options.indexOf('2') >= 0) {
      return true;
    }
    return false;
  }
  public getLibrarySolutions(searchLimit) {
    let endpoint = 'solutions/librarySolutions/' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public importLibrarySolutionsToAccount(params) {
    let endpoint = 'solutions/importLibSolutionToAccountSolutions';
    return this.commonService.postAPIService(endpoint, params);
  }

  public getUnitTypes(): Observable<APIResult<ExpenseType[]>> {
    let endpoint = 'fact/unitTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getBenefitTypes(account_id: string): Observable<APIResult<BenefitType[]>> {
    let endpoint = 'account/benefitTypes/' + account_id;
    return this.commonService.getAPIService(endpoint);
  }

  public getAreaTypes() {
    let endpoint = 'fact/areaTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getAccrualTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/accrualTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getFinancialTypes() {
    let endpoint = 'fact/financialFactors';
    return this.commonService.getAPIService(endpoint);
  }

  public getImpactTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/impactTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getScaleTypes() {
    let endpoint = 'fact/scaleTypes/excludeDns/0';
    return this.commonService.getAPIService(endpoint);
  }

  public getDriverFactorTypes() {
    let endpoint = 'fact/driverFactorTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getDriverFactor(params, aid) {
    let endpoint = 'fact/driverFactors/driverFactorTypeId/' + params + '/accountId/' + aid;
    return this.commonService.getAPIService(endpoint);
  }

  public getUniqueTypes() {
    let endpoint = 'fact/uniqueTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getValueCategories(params) {
    let endpoint = 'solutions/solutionValueCategories/' + params;
    return this.commonService.getAPIService(endpoint);
  }

  public getValueCategoriesOneMetric(vp_id, account_solution_id?) {
    let endpoint = 'valueProps/readValueCategories/' + vp_id + (account_solution_id ? '?account_solution_id=' + account_solution_id : '');
    return this.commonService.getAPIService(endpoint);
  }

  public getFunctionalObjectivesOneMetric(vp_id, account_solution_id?) {
    let endpoint = 'valueProps/readFunctionalObjectives/' + vp_id + (account_solution_id ? '?account_solution_id=' + account_solution_id : '');
    return this.commonService.getAPIService(endpoint);
  }

  public getAllValueCategories(params) {
    let endpoint = 'account/valueCategories/' + params;
    return this.commonService.getAPIService(endpoint);
  }

  public readAvailableValueCategories() {
    let endpoint = 'solution/availableValueCategories';
    return this.commonService.getAPIService(endpoint);
  }

  public saveSolutionBenefits(payload) {
    let endpoint = 'solutions/benefit/add';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateSolutionBenefitsParam(value_prop_metric_id, payload) {
    let endpoint = 'valueProps/updateValuePropBenefitSpecificValues/' + value_prop_metric_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateSolutionBenefitsParamLiveSave(vpid, payload) {
    let endpoint = 'valueProps/updateAllMetricDetails/' + vpid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateSolutionBenefits(id, payload) {
    let endpoint = 'solutions/updateSolutionMetric/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public saveBenefitSequence(payload, id) {
    let endpoint = 'solutions/updateBenefitsSequence/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public releaseToAllUsers(payload) {
    let endpoint = 'solution/releaseToUsers';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getFactors(account_solution_id, account_solution_situation_id) {
    // solutions/distinctFactors/{account_solution_id}
    let endpoint = 'solutions/distinctFactors/' + account_solution_id + '/accountSolutionSituationId/' + account_solution_situation_id;
    return this.commonService.getAPIService(endpoint);
  }

  public postFactorBaseline(account_solution_situation_id, payload) {
    // solutions/solutionSituationFactor/{account_solution_situation_id}
    let endpoint = 'solutions/solutionSituationFactor/' + account_solution_situation_id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public getSituationTypes(): Observable<APIResult<SituationItem[]>> {
    let endpoint = 'solutions/situation/accountSituationTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getAccountSituationTypes(): Observable<APIResult<SituationItem[]>> {
    let endpoint = 'solutions/situation/onlyAccountSituationTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getSituationTree(situation_type_id) {
    // situation/{situation_type_id}/all/elements/solutionId/{account_solution_id}
    let endpoint = 'situation/' + situation_type_id + '/all/elements';
    return this.commonService.getAPIService(endpoint);
  }

  public getSituation(params) {
    let endpoint = 'solutions/solutionSituation/' + params;
    return this.commonService.getAPIService(endpoint);
  }

  public getcategoryInfluence() {
    let endpoint = 'solutions/categoryInfluence/';
    return this.commonService.getAPIService(endpoint);
  }

  public getAccountCategotires(accountid) {
    let endpoint = 'account/valueCategories/' + accountid;
    return this.commonService.getAPIService(endpoint);
  }

  public saveValueCategory(payload) {
    let endpoint = 'solutions/valueCategory/create';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public addNewValueCategory(payload, aid) {
    let endpoint = 'account/valueCategory/add/' + aid;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getlibraryBenefits(searchLimit) {
    let endpoint = 'solutions/libraryBenefits/' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }

  public saveSituationType(payload) {
    let endpoint = 'solutions/situationType';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateSituationType(payload) {
    let endpoint = 'solutions/updateSituationType';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public updateSituationTypeStackOrder(payload) {
    let endpoint = 'solutions/updateSituationType/StackOrder';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public;

  public saveSituation(payload) {
    let endpoint = 'solutions/solutionSituation';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateSituation(payload) {
    let endpoint = 'solutions/updatemultipleSolutionSituations';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getSingleSituationTree(account_solution_situation_id, account_solution_id) {
    // /solutions/{account_solution_situation_id}/singleSituationTree/bySolution/id/{account_solution_id}
    let endpoint = 'solutions/' + account_solution_situation_id + '/singleSituationTree/bySolution/id/' + account_solution_id;
    return this.commonService.getAPIService(endpoint);
  }
  public realeaseToPartners(payload) {
    let endpoint = 'solution/releaseToPartners';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getSituationsBySolution(id) {
    let endpoint = 'solutions/situation/bySolution/id/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  // Add expanded property to situation options with children
  // This is needed to determine if all situations are expanded or not
  private expandChildren(node: TreeNode, expandChildren: boolean) {
    if (node.children) {
      node.expanded = expandChildren;
      for (let childNode of node.children) {
        this.expandChildren(childNode, expandChildren);
      }
    }
  }

  public getSituationsBySolutionTree(id, pluckResult = false): Observable<SolutionData[] | APIResult<SituationTreeBySolution>> {
    const foundSituationTree = this.situationTrees.get(id);
    if (foundSituationTree) {
      return of(foundSituationTree);
    } else {
      let endpoint = 'solutions/situationTree/bySolution/id/' + id;

      if (pluckResult) {
        return this.commonService.getAPIService(endpoint).pipe(
          pluck('result', 'data'),
          map((situations) => {
            situations.forEach((node) => {
              this.expandChildren(node, false);
            });
            return situations;
          })
        );
      } else {
        return this.commonService.getAPIService(endpoint);
      }
    }
  }

  public getSituationsBySolutionTreeV2(situation_type_id, account_solution_id) {
    // situation/{situation_type_id}/elements/solutionId/{account_solution_id}
    let endpoint = 'situation/' + situation_type_id + '/elements/solutionId/' + account_solution_id;
    return this.commonService.getAPIService(endpoint);
  }

  public getSituationsBySolutionTreeV3(situation_type_id, account_solution_id) {
    // solutions/{account_solution_situation_id}/singleSituationTree/bySolution/id/{account_solution_id}
    let endpoint = 'solutions/' + situation_type_id + '/singleSituationTree/bySolution/id/' + account_solution_id;
    return this.commonService.getAPIService(endpoint);
  }

  public saveSituationElement(payload) {
    let endpoint = 'solutions/situation/element';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateSituationElement(situation_element_id, payload) {
    let endpoint = 'solutions/situation/updateElement/' + situation_element_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteSituationElement(situation_element_id) {
    let endpoint = 'solutions/situation/deleteElement/' + situation_element_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public deleteSituationType(situation_type_id: number) {
    let endpoint = `solutions/situationType/${situation_type_id}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getSituationTypeElements(id) {
    let endpoint = 'situation/' + id + '/elements';
    return this.commonService.getAPIService(endpoint);
  }

  public getSolutionSituation(situation_id) {
    let endpoint = 'solutions/solutionSituation/' + situation_id;
    return this.commonService.getAPIService(endpoint);
  }

  public readSolutionBenefits(id) {
    let endpoint = 'solutions/solutionMetrics/' + id + '/vpSpecificOnly/0';
    return this.commonService.getAPIService(endpoint);
  }

  public saveCaseStudy(payload) {
    let endpoint = 'solutions/caseStudy';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updatecaseStudy(payload, solutionId) {
    let endpoint = 'solutions/updateCaseStudies/' + solutionId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getCaseStudies(id, searchLimit): Observable<APIResult<{ case_studies: PagedAPIResult<CaseStudy> }>> {
    let endpoint = 'solutions/readCaseStudiesBySolution/' + id + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }

  public getCaseStudiesPlain(id) {
    let endpoint = 'solutions/caseStudiesPlain/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getRecentCaseStudies(searchLimit) {
    let endpoint = 'valueRealization/recentCasestudiesShort' + searchLimit;
    return this.commonService.getAPIService(endpoint);
  }
  public searchCaseStudies(payload) {
    let endpoint = 'solutions/searchCasestudy';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getCaseStudyDetail(id, isAccountSolution) {
    let endpoint = 'solutions/read/caseStudy/' + id + '/isAccountSolution/' + isAccountSolution;
    return this.commonService.getAPIService(endpoint);
  }

  public deleteCaseStudy(case_study_id) {
    let endpoint = 'solutions/delete/caseStudy/' + case_study_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public cloneModelCaseStudy(payload) {
    let endpoint = 'valueRealization/cloneCaseStudy';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveQuote(payload) {
    let endpoint = 'solutions/addQuote';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateQuote(payload) {
    let endpoint = 'solutions/updateQuote';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteQuote(case_study_id, account_case_study_quote_id) {
    let endpoint = 'solutions/deleteQuote/' + case_study_id + '/accountCaseStudyQuoteId/' + account_case_study_quote_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public saveSituationBenefitActive(payload) {
    let endpoint = 'solutions/solutionSituationBenefitActive';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public saveSituationCosts(payload) {
    let endpoint = 'solutions/solutionSituationCosts';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public saveSituationCost(payload, situation_id, cost_id) {
    let endpoint = 'solutions/solutionSituationCost/' + situation_id + '/costID/' + cost_id;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public saveSolutionSituationBenefit(payload) {
    let endpoint = 'solutions/solutionSituationBenefit';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public deleteSituation(id) {
    let endpoint = 'solutions/solutionSituation/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public saveSituationTypeElement(payload) {
    let endpoint = 'solutions/situation/element';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveSection(payload) {
    let endpoint = 'solutions/addSections';
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public updateSection(payload) {
    let endpoint = 'solutions/update/sections';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public getBenefitsList(params) {
    let endpoint = 'solutions/solutionMetrics/' + params + '/vpSpecificOnly/0';
    return this.commonService.getAPIService(endpoint);
  }
  public getUnitType(): Observable<APIResult<ExpenseType>> {
    let endpoint = 'fact/unitTypes';
    return this.commonService.getAPIService(endpoint);
  }
  public getImpTypes() {
    let endpoint = 'fact/CaseStudyBenefitImprovementTypes';
    return this.commonService.getAPIService(endpoint);
  }
  public saveCaseStudyBenefits(payload) {
    let endpoint = 'solutions/addBenefits';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public deleteCaseStudyBenefit(payload) {
    let endpoint = 'solutions/benefits/delete';
    return this.commonService.postAPIService(endpoint, payload);
  }
  public updateCaseStudyBenefit(payload) {
    let endpoint = 'solutions/benefits/update';
    return this.commonService.putAPIService(endpoint, payload);
  }
  public addMediaToSection(payload) {
    let endpoint = 'solutions/addSectionImages';
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public deleteCaeStudySection(section_id) {
    let endpoint = 'solutions/sections/delete/' + section_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public deleteSectionMedia(media_id) {
    let endpoint = 'solutions/sectionImages/delete/' + media_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public updateSectionMedia(payload) {
    let endpoint = 'solutions/update/sectionImages';
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public updateStackOrder(situationid, payload) {
    let endpoint = 'solutions/updateSolutionSituation/' + situationid;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public saveBenefitFeedback(payload, id) {
    let endpoint = 'solutions/benefits/feedback/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }
  public readFeedback(id: string): Observable<APIResult<FeedbackResponse[]>> {
    let endpoint = 'solutions/benefits/readFeedback/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getAvailableSolutionByCategory(param) {
    let endpoint = 'solutions/readAvailableSolutionsByCategory/' + param;
    return this.commonService.getAPIService(endpoint);
  }

  public getTopFiveSolutions() {
    let endpoint = 'solutions/topFiveSolutions';
    return this.commonService.getAPIService(endpoint);
  }

  public deleteSolutionMetric(payload) {
    let endpoint = 'solutions/deleteSolutionMetric/' + payload;
    return this.commonService.deleteAPIService(endpoint);
  }
  /*Share in models */
  public getmodelsShares(id) {
    let endpoint = 'solutions/readAccountSolutionShares/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public saveModelsPrivilege(payload) {
    let endpoint = 'solutions/createAccountSolutionShare';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public updateModelsPrivilege(id, payload) {
    let endpoint = 'solutions/updateAccountSolutionShare/' + id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteModelsPrivilege(id) {
    let endpoint = 'solutions/deleteAccountSolutionShare/' + id;
    return this.commonService.deleteAPIService(endpoint);
  }
  /*Nested Situations */
  public getSituationElementChildren(element_id) {
    let endpoint = 'situation/elementChildren/' + element_id;
    return this.commonService.getAPIService(endpoint);
  }

  public toggleStaredKeyAssumption(id, payload) {
    // solutions/KeyAssumption/1074
    let endpoint = 'solutions/KeyAssumption/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  // Assumptions
  public getDrivers(id) {
    let endpoint = 'solutions/drivers/' + id + '/scratchpadDetails/0';
    return this.commonService.getAPIService(endpoint);
  }
  public getFinancials(id) {
    let endpoint = 'solutions/financials/' + id + '/scratchpadDetails/0';
    return this.commonService.getAPIService(endpoint);
  }
  public editbaseLineAnswer(payload) {
    let endpoint = 'solutions/baselineAnswer/update';
    return this.commonService.putAPIService(endpoint, payload);
  }
  public getScratchPadUnits(id) {
    let endpoint = 'solutions/readScratchpads/' + id;
    return this.commonService.getAPIService(endpoint);
  }
  public editScratchpadUnit(payload) {
    let endpoint = 'solutions/scratchpadUnits/update';
    return this.commonService.putAPIService(endpoint, payload);
  }
  public saveSolutionProject(id, payload) {
    let endpoint = 'solutions/solutionProject/create/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getSolutionProjects(id) {
    let endpoint = 'solutions/solutionProjects/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getProjects(id) {
    let endpoint = 'solutions/solutionProjects/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public getAccountProjects(id) {
    let endpoint = 'account/projectTypes/' + id;
    return this.commonService.getAPIService(endpoint);
  }

  public addNewProject(id, payload) {
    let endpoint = 'account/newProjectType/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public projectCreateChildren(id, payload) {
    let endpoint = 'account/updateAccountProjectType/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public saveProject(id, payload) {
    let endpoint = 'solutions/solutionProject/create/' + id;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public deleteProject(id, accountProjectTypeId) {
    let endpoint = 'solutions/solutionProject/delete/' + id + '/accountProjectTypeId/' + accountProjectTypeId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public updateProject(payload, solution_id) {
    let endpoint = 'solutions/solutionProject/update/' + solution_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public saveSituationCaseStudyActive(payload, situation_id, cs_id) {
    let endpoint = 'solutions/solutionSituationCaseStudy/' + situation_id + '/caseStudyID/' + cs_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public saveSituationAssetActive(payload, situation_id, as_id) {
    let endpoint = 'solutions/solutionSituationAsset/' + situation_id + '/assetID/' + as_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public saveSituationCollateralActive(payload, situation_id, cl_id) {
    let endpoint = 'solutions/solutionSituationCollateral/' + situation_id + '/collateralID/' + cl_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public saveSituationProjectsActive(payload) {
    let endpoint = 'solutions/solutionSituationProject';
    return this.commonService.putAPIService(endpoint, payload);
  }

  public readBenefitsByProject(project_id, solution_id) {
    let endpoint = 'solutions/benefitsByProject/projectId/' + project_id + '/id/' + solution_id;
    return this.commonService.getAPIService(endpoint);
  }

  public deleteSolutionValueCategory(valueCategoryId, solutionId) {
    let endpoint = 'solutions/solutionValueCategory/delete/' + solutionId + '/valueCategoryId/' + valueCategoryId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public deleteSolutionValueCategoryNoSolutionID(valueCategoryId) {
    //account/valueCategory/delete/{value_category_id}
    let endpoint = 'account/valueCategory/delete/' + valueCategoryId;
    return this.commonService.deleteAPIService(endpoint);
  }
  public editSolutionValueCategory(payload, accountId) {
    let endpoint = 'account/valueCategory/update/' + accountId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public postFiles(payload, account_solution_metric_id) {
    // solutions/addMetricImages
    let endpoint = 'solutions/addMetricImages/' + account_solution_metric_id;
    return this.commonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

  public putFiles(payload, account_solution_metric_id) {
    // solutions/update/metricImages
    let endpoint = 'solutions/update/metricImages/' + account_solution_metric_id;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteFiles(metric_image_id) {
    //solutions/metricImage/delete/{metric_image_id}
    let endpoint = 'solutions/metricImage/delete/' + metric_image_id;
    return this.commonService.deleteAPIService(endpoint);
  }

  public getOperationalObjectives(accountId) {
    let endpoint = 'account/operationalObjectives/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }
  public getPainPoints(accountId) {
    let endpoint = 'account/painPoints/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }
  public getCapabilities(accountId) {
    let endpoint = 'account/capabilities/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }
  public getFunctionalObjectives(accountId) {
    let endpoint = 'account/functionalObjectives/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public cloneBenefits(account_solution_id: number, account_solution_id_destiny: number, benefitsIds: { id: number }[]): Observable<APIResult<CloneBenefitsResponse>> {
    const endpoint = 'solutions/benefits/clone';
    const payload = {
      account_solution_id,
      account_solution_id_destiny,
      benefits: benefitsIds,
    };
    return this.commonService.postAPIService(endpoint, payload);
  }

  public pushGoalsToValueProps(payload, account_solution_id: string): Observable<APIResult<string>> {
    let endpoint = `solutions/${account_solution_id}/push-goals`;
    return this.commonService.postAPIService(endpoint, payload);
  }

  saveCostTags(data: CreateCostTagData, createAssetTag = false): Observable<APIResult<TagData[]>> {
    let additional_tags = [
      { field: `cost_type` }, 
      { field: `description` }, 
      { field: `quantity`, format_type: 'number' },
      { field: `present_value`, format_type: 'currency' },
      { field: `lease_term` },
      { field: `residual_value`, format_type: 'currency' },
      { field: `buyout_value`, format_type: 'currency' },
      { field: `accrual_type` },
      { field: `solution_name` },
      { field: `cost`, format_type: 'currency' },
      { field: `subtotal`, format_type: 'currency' },
      { field: `discount`, format_type: 'number' },
      { field: `grand_total`, format_type: 'currency' },
    ];

    for ( let i = 1; i <= 10; i++ ) {
      additional_tags.push({
        field: 'yr' + i + '_costs',
        format_type: 'currency'
      });

      additional_tags.push({
        field: 'yr' + i + '_quantity',
        format_type: 'number'
      });
    }

    const tags = {
      id: data.tagId, // Send if updating, if not sent, new tag is created
      name: data.name,
      description: 'new tag',
      tag: data.value,
      metadata: {
        array_name: 'costs_indexed',
        id: data.costId, // account_solution_cost_id
        field: 'cost',
        format_type: 'currency',
        additional_tags: additional_tags,
      },
    };
    const payload = { tags: [tags] };
    const endpoint = 'account/asset-tags';
    return this.commonService.postAPIService(endpoint, payload);
  }
}
