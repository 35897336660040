import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class TrackValueGoalTabTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'trackValueGoalTab',
    display_name: 'Track Value Goal Tab',
    description: 'The goal tab found in the track value detail dashboard',
  };

  public trans = this.typeObjectKeys({
    reviewSuccess: 'Business review created successfully',
    reviewFailure: 'We were not able to create your business review',
    reviewUpdateSuccess: 'Business review successfully updated',
    reviewUpdateFailure: 'We were not able to update your review',
    notEnoughData: 'Not enough data',
    forPeriod: 'For Period',
    editDataForPeriod: 'Edit Data for Period',
    goalNameSaved: 'Goal name saved',
    originalGoalName: 'Original Goal Name',
    na: 'N/A',
  });
}
