import { Injectable } from '@angular/core';

@Injectable()
export class MasterValuePropTranslations {
	public config: any = {
		component: 'master-vp',
		display_name: 'Master Value Prop Details',
		description: 'master value prop detials and usecases'
    }
    public trans: any = {
        business_case_for: "Business case for ",
		compare_all: "Compare all",
		details: "Details",
		totals: "Totals",
		totalBenefits: "Total Benefits",
		totalCosts: "Total Costs",
		ROI: "ROI",
		NPV: "NPV",
		paybackPeriod: "Payback Period (Months)",
		tco: "TCO Improvements",
		cod: "3 Month Cost of Delay",
		projects: "Projects",
		actions: "Actions",
		clone: "Clone",
		share: "Share",
		assets: "Assets",
		benefits_per_transaction: "Benefit per Transaction",
		status: "Status",
		vpgroup_scalers: "Scalers",
		vp_assets: "Assets",
		add_projects: "Add project",
		pick_your_projects: "Pick your projects",
		no_projects_found: "No projects found",
		share_your_master_value_prop: "Share Your Master Business Case / Value Assessment",
		clonevpgroup: "Clone this Value Prop Group",
        add_another: "Add Another",
        ch_vp: 'Ability to rename a Value Proposition',
        ch_add_value_prop: 'Add Value Prop',
        add: 'Add',
        compare: 'Compare',
		clone_this_master_value_prop: "Clone this Master Business Case / Value Assessment",
		ch_delete: 'Delete this value prop',
		ch_edit: 'Edit',
		ch_assets: 'Sales-ready Assets usable in different sales stages.'
    }
}