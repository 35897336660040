import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RepSimpleTranslations } from './nav.translation';

@Component({
  selector: 'app-rsv2-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RSV2NavComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  showTranslate = false;
  @Input() menu: { label: string; viewTop: string; desc: string; done: boolean }[] = [];
  @Input() valueProp: ValueProp;
  @Input() step = 0;
  @Output() callbackMenuClick = new EventEmitter();
  style2022 = false;

  constructor(
    private commonService: CommonService, 
    private translationService: TranslationsV2Service, 
    public trans: RepSimpleTranslations, 
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.styleService.style2022.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isStyle2022) => (this.style2022 = isStyle2022));
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    sessionStorage.setItem('workflowFallback', String(this.menu.length));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  gotoStep(step: number): void {
    if (this.valueProp.done === '1') {
      this.callbackMenuClick.emit(step);
    } else {
      return;
    }
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
