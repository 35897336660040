import { Injectable } from '@angular/core';

@Injectable()
export class SectionsTranslations {
	public config: any = {
		component: 'sections',
		display_name: 'Case Study Sections',
		description: 'Sections for case studies'
	};

	public trans: any = {
		add_section: 'Add Section',
		delete: 'Delete',
		areYouSure: 'Are you sure?',
		edit: 'Edit',
        edit_translations: 'Edit Translations'
	};
}
