import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';

@Injectable({
	providedIn: 'root'
})
export class NoPrivilegesGuard implements CanActivate {

	constructor(
		private router: Router,
		private commonService: CommonService
	) { }

	canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
		let hasPrivileges = this.commonService.hasPrivileges();
		if(hasPrivileges){
			return true;
		}
		this.router.navigateByUrl('/dashboard');
		return false;
	}

}
