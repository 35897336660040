import { Injectable } from '@angular/core';

@Injectable()
export class GoalGroupTranslations {
  public config: any = {
    component: 'goal-groups',
    display_name: 'Goal Groups',
    description: ''
  };

  public trans: any = {
    deleteGoal: 'Goal deleted successfully',
    requestError: 'Something went wrong, please try again',
    deleteGoalGroup: 'Goal Group deleted successfully',
    kpiGroups: 'KPI Groups',
    addNewGroup: 'Add New Group',
    addNewGoal: 'Add New Goal',
    edit: 'Edit',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    noGoals: 'No goals found',
    add_scratchpad: 'Add Scratchpad',
    edit_scratchpad: 'Edit Scratchpad',
    name: 'Name',
    connectedFactors: 'Connected Factor(s)',
    createdOn: 'Created On',
  };
}
