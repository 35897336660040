import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { AlertService } from '@services/alert.service';
import { CompanyService } from '@data/services/company/company.service';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { MasterValuePropTranslations } from './master-value-prop.translation';
import { MasterValueProp, ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';
import { Company } from '@shared/models/company.model';

export interface Prop {
	name: string,
	id: number,
	children?: Prop[]
}


@Component({
	selector: 'app-master-value-prop',
	templateUrl: './master-value-prop.component.html',
})
export class MasterValuePropComponent implements OnInit, OnDestroy {
	changeName = false;

	masterValuePropId = "";
	masterValueProp: MasterValueProp = { name: '', id: null};
	projects = [];
	valueProps: ValueProp[] = [];
	totalValueProps: number;
	fullImagePath: string;

	toCompare = [];
	numToCompare: number;
	showCompare = false;
	valuePropLoader = false;
	cloneLoader = false;

	availableMasterValuePropProjects: any[];
	selectedProjects = [];
	showActionLoaderProjects = false;
	// Contextual Help
	contextualHelp = {};
	subscription: Subscription;

	modalReference: NgbModalRef;

	valueProp: Prop = {
		name: 'Master Value Prop for ACME Mortage',
		id: 0
	};

	scenarios: Array<Prop> = [{
		name: 'HR Offer Letters',
		id: 0
	},
	{
		name: 'HR - On- Boarding',
		id: 1
	},
	{
		name: 'Retail Consumer Finance',
		id: 2,
		children: [{
			name: 'Retail Consumer Finance',
			id: 3
		}]
	}
	];
	masterValuePropTemplates: any = [];
	userId: string;
	token: string;

	valuePropStatusTypes: any = [];
	valuePropStatus: number;
	valuePropStatusName: string;
	valuePropStatus_editable = false;


	allScalersList: any = [];
	selectedScalersList: Array<object> = [];
	showActionLoaderScaler = false;
	showActionLoader = false;
	showScalers = false;

	cloneName = "";
	assetUrl = "";
	assetGenerator = "";
	header_color = "#f5f5f5";
	isVPGroup = false;
	VPGroup = 0;
	term = "";
	showAddVP = false;
	value_prop_group_id: string;
	companyDetails: Company;
	showTranslate = false;
	isAdmin = false;
	showShare = false;
	showClone = false;

	ngUnsubscribe = new Subject();



	style2022$: Observable<boolean>;

	constructor(
		private modalService: NgbModal,
		private masterValuePropService: MasterValuePropService,
		private route: ActivatedRoute,
		private notificationService: NotificationService,
		private router: Router,
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		private factsService: FactsService,
		private assetService: AssetService,
		private notification: NotificationService,
		private alertService: AlertService,
		private companyService: CompanyService,
		public trans: MasterValuePropTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {
		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.isAdmin = (sessionStorage.getItem("isAdmin") == "true" ? true : false);
		this.funcLoad();

		this.alertService.subscribeLoggedIn().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.funcLoad();
		})
		this.header_color = localStorage.getItem("header_color");

		this.valuepropService.callbackFinishAddJustCase.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.funcLoad();
			this.showAddVP = false;
		})

		this.getTranslations();
		this.commonService.notifyChangeLanguage.subscribe(() => {
			this.getTranslations();
		})

		this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		})
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	CallbackDone() {
		this.funcLoad();
	}

	funcDeleteVP(valueProp) {
		valueProp.deleteLoading = true;
		this.valuepropService.deleteValueProp(valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.funcLoad();
		});
	}

	funcLoad(urlid?) {
		let id;
		if (urlid) { id = urlid; } else {
			id = this.route.snapshot.paramMap.get('id');
		}
		this.value_prop_group_id = id;
		if (this.route.snapshot.paramMap.get('group')) { this.isVPGroup = true; }
		// this.route.params.subscribe(params => id = params['id']);
		// this.route.params.subscribe(params => params['group'] ? this.isVPGroup = true : this.isVPGroup = false);

		// const id = (urlid ? urlid : this.route.snapshot.paramMap.get('id'));
		if (this.isVPGroup) {
			this.masterValuePropId = this.value_prop_group_id;
		} else {
			this.masterValuePropId = id;
		}

		this.masterValueProp = { name: '', id: null};
		this.readMasterValueProp();

		if (this.isVPGroup) {
			this.getVPGroupAssets();
		} else {
			this.readTemplates();
		}

		this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			this.contextualHelp = response['Valueprop'];
		});
		// this.subscription = this.ValuepropService.refreshDashboard.subscribe(response => {
		// });

		this.userId = sessionStorage.getItem('uid');
		this.token = sessionStorage.getItem('vcu');

		this.assetUrl = this.commonService.getAssetUrl();
		this.assetGenerator = sessionStorage.getItem('asset_gen_file');
	}

	funcCloneVPGroup() {
		let payload = { name: this.cloneName };
		this.cloneLoader = true;
		this.masterValuePropService.postCloneVPGroup(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

			this.notificationService.success("Value Prop Group cloned successfully", false); // Params {message, islogout}
			this.cloneName = "";
			this.showClone = false;

			let id = result.result;
			let newmasterValuePropId = id.master_value_prop.id;
			this.cloneLoader = false;

			this.router.navigate(["mastervalueprop/" + newmasterValuePropId + "/group"]);
			// window.location.reload();
			this.funcLoad(newmasterValuePropId);

		})

	}
	funcCloneValueProp() {
		let payload = { name: this.cloneName };
		this.cloneLoader = true;
		this.masterValuePropService.cloneMasterValueProp(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

			this.notificationService.success("Master Value Prop cloned successfully", false); // Params {message, islogout}
			this.cloneName = "";
			this.showClone = false;
			let id = result.result;
			let newmasterValuePropId = id.master_value_prop.id;
			this.cloneLoader = false;

			this.router.navigate(["mastervalueprop/" + newmasterValuePropId]);
			// window.location.reload();
			this.funcLoad(newmasterValuePropId);

		})
	}

	funcProcessVP(master_value_prop) {

		this.companyService.getCompanySummary(master_value_prop.master_value_prop.company_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {

			this.companyDetails = result.result.company;
		});


		this.VPGroup = master_value_prop.master_value_prop.id;
		this.masterValueProp = master_value_prop.master_value_prop;
		this.masterValueProp['originalName'] = this.masterValueProp['name'];

		this.projects = master_value_prop.projects;
		if (master_value_prop && master_value_prop.value_props) {
			this.valueProps = master_value_prop.value_props.map(valueprop => {

				valueprop['id'] = (valueprop['id'] ? valueprop['id'] : valueprop['value_prop_id']);
				valueprop['originalName'] = valueprop['name'];
				this.term = valueprop.term;
				valueprop.showScalers = true;
				return valueprop;
			});
		}
		this.valueProps = this.valueProps.filter(x => x.id !== "");

		this.valuePropLoader = false;

		this.getValuepropScalers();
	}

	readMasterValueProp() {
		this.valuePropLoader = true;
		if (this.isVPGroup) {
			this.masterValuePropService.readValuePropGroup(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
				this.funcProcessVP(result.result);
			});
		} else {
			this.masterValuePropService.readMasterValueProp(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

				this.funcProcessVP(result.result);
			});
		}
	}

	getValuepropScalers() {

		this.valueProps.forEach(valueprop => {
			if (!valueprop.id) { return; }
			if (this.isVPGroup) {
				this.valuepropService.readAvailableScalersNoTrans(valueprop['id']).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
					valueprop['scalers'] = [];
					if (response.result && response.result.scalers) {
						valueprop['scalers'] = response.result.scalers;
					} else {
						valueprop['scalers'] = [];
					}
				});

			} else {
				this.valuepropService.readAvailableScalers(valueprop['id']).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
					valueprop['scalers'] = [];
					if (response.result && response.result.scalers) {
						valueprop['scalers'] = response.result.scalers;
					} else {
						valueprop['scalers'] = [];
					}
				});
			}
		});
	}

	deleteProject(id) {
		this.masterValuePropService.deleteProject(this.masterValuePropId, id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			if (result.result == "Project has been deleted successfully") {

				this.notificationService.success(result.result, false); // Params {message, islogout}
				this.readMasterValueProp();
			} else {

			}
		});
	}

	btnCompareAll() {
		this.toCompare = [...this.valueProps];

		for (let i = 0; i < this.valueProps.length; i++) {
			this.valueProps[i].checkbox = true;
		}

		this.numToCompare = this.toCompare.length;

		if (this.numToCompare > this.toCompare.length) {
			this.numToCompare = this.toCompare.length;
		}

		this.totalValueProps = this.valueProps.length;

		if (this.numToCompare) {
			this.showCompare = true;
		} else {
			this.showCompare = false;
		}

	}

	addToCompare(valueProp) {
		valueProp.compare = !valueProp.compare;
		// const checked = evt.target.checked;
		const checked = valueProp.compare;
		if (checked) {
			const index = this.toCompare.findIndex((x) => x.id == valueProp.id);

			if (index == -1) {
				this.toCompare.push(valueProp);
			}

		} else {
			if (this.toCompare.length) {
				const index = this.toCompare.findIndex((x) => x.id == valueProp.id);

				if (index > -1) {
					this.toCompare.splice(index, 1);
				}
			}
		}

		this.numToCompare = this.toCompare.length;

		if (this.numToCompare > this.toCompare.length) {
			this.numToCompare = this.toCompare.length;
		}

		this.totalValueProps = this.valueProps.length;

		if (this.numToCompare) {
			this.showCompare = true;
		} else {
			this.showCompare = false;
		}
	}

	saveCompare() {
		if (this.numToCompare) {
			const mastervalueprop = {
				name: this.masterValueProp['name'],
				id: this.masterValueProp['id']
			};

			localStorage.setItem("mastervalueprop", JSON.stringify(mastervalueprop));

			const tmp = this.toCompare.map(a => a.id);
			const compareString = tmp.join(",");
			localStorage.setItem("mvpIds", compareString);
			if (this.isVPGroup) {
				this.router.navigate(["mastervalueprop/" + this.masterValuePropId + "/compare/" + this.VPGroup]);
			} else {
				this.router.navigate(["mastervalueprop/" + this.masterValuePropId + "/compare"]);
			}

		} else {
			this.notificationService.error("Please choose a collection of Value Props to compare.", false);
		}
	}

	goToValuePropDash(id) {
		const mvp = {
			"fromMVP": true,
			"mvp_id": this.masterValuePropId
		};

		sessionStorage.setItem('mvp', JSON.stringify(mvp));
		if (this.VPGroup) {
			this.router.navigate(["valueprop/" + id + "/group/" + this.value_prop_group_id]);
		} else {

			this.router.navigate(["valueprop/" + id]);
		}
	}

	changeMasterValuePropName() {
		this.changeName = false;

		let payload = {

		}

		if (this.VPGroup) {
			payload = {
				value_prop_group_id: this.VPGroup,
				group_type_id: "1",
				name: this.masterValueProp.name
			}
			this.masterValuePropService.updateVPGroupName(this.VPGroup, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result) {
					// this.masterValueProp['name'] = response.result.value_prop['name'];
					this.notificationService.success("Value Prop Group name changed successfully", false); // Params {message, islogout}
				}
			});
		} else {
			payload = {
				"name": this.masterValueProp['name']
			}
			this.masterValuePropService.updateMasterValuePropName(this.masterValueProp['id'], payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result) {
					this.masterValueProp['name'] = response.result.value_prop['name'];
					this.notificationService.success("Master Value Prop name changed successfully", false); // Params {message, islogout}
				}
			});
		}
	}

	cancelMasterValuePropName() {
		this.changeName = false;
		this.masterValueProp['name'] = this.masterValueProp['originalName'];
	}

	openAddScenarios(content) {
		this.modalReference = this.modalService.open(content, {
			size: 'lg',
			windowClass: 'deleteModal', backdrop: "static", keyboard: false
		});

		this.readAvailableMasterValuePropProjects();
		this.getScalers();
	}

	readAvailableMasterValuePropProjects() {
		this.showActionLoaderProjects = true;
		this.availableMasterValuePropProjects = [];
		this.masterValuePropService.readAvailableProjects(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			if (response.result && response.result.projects) {
				this.availableMasterValuePropProjects = response.result.projects;
			}
			this.showActionLoaderProjects = false;
			this.setSelectedProjects();
		});
	}

	setSelectedProjects() {
		this.selectedProjects = [];

		let projectsSaved = this.projects.map(project => project['id']);

		this.availableMasterValuePropProjects.forEach(projects => {
			if (projects['children'] && projects['children'].length > 0) {
				projects['children'].forEach(children => {
					if (projectsSaved.indexOf(children['id']) != -1) {
						children['selected'] = true;
						children['parent'] = projects['name'];
						this.selectedProjects.push(children);
					}
				});
			}
		});
	}

	moveSelectedProject() {
		let projectsSelected = this.selectedProjects.map(project => project['id']);

		this.availableMasterValuePropProjects.forEach(projects => {
			if (projects['children'] && projects['children'].length > 0) {
				projects['children'].forEach(children => {
					if (children['selected'] && projectsSelected.indexOf(children['id']) == -1) {
						children['parent'] = projects['name'];
						this.selectedProjects.push(children);
					}
				});
			}
		});
	}

	removeSelectedProject(project_id) {
		let index = this.selectedProjects.findIndex(project => project['id'] == project_id);

		if (index != -1) {
			this.selectedProjects.splice(index, 1);
		}
	}

	createProjects() {
		let payload = {
			'account_project_id': this.selectedProjects.map(project => project['id']).join(',')
		};

		this.showActionLoaderProjects = true;
		this.masterValuePropService.createProjects(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {

			this.modalReference.close();
			this.getScalers();
			this.notificationService.success("You have successfully added a project to your Master Value Prop.", false); // Params {message, islogout}
			this.readMasterValueProp();
			this.showActionLoaderProjects = false;
		});
	}

	changeValuePropName(valueProp, index) {
		this.valueProps[index]['changeName'] = false;

		const payload = {
			"name": this.valueProps[index]['name']
		}
		this.valuepropService.updateValueProp(valueProp['id'], payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.valueProps[index]['name'] = response.result.value_prop.name;
				this.notificationService.success("Value Prop name changed successfully", false); // Params {message, islogout}
			}
		});
	}

	cancelValuePropName(index) {
		this.valueProps[index]['changeName'] = false;
		this.valueProps[index]['name'] = this.valueProps[index]['originalName'];
	}

	openModal(content) {
		this.modalReference = this.modalService.open(content, { size: 'lg', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}


	getVPGroupAssets() {
		this.valuepropService.getVPGroupAssets(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

			this.masterValuePropTemplates = response.result;
		})
	}

	readTemplates() {

		if (this.isVPGroup) {
			this.assetService.readValuePropGroupTemplates(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

				this.masterValuePropTemplates = response.result;
			});

		} else {
			this.assetService.readMasterValuePropTemplates(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

				this.masterValuePropTemplates = response.result;
			});

		}

	}

	getValuePropStatusTypes() {
		this.factsService.getValuePropStatusTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			this.valuePropStatusTypes = response.result;
			// this.valuePropStatusTypes = this.valuePropStatusTypes.map(item => {
			// 	if (item['id'] == this.valuePropStatus) {
			// 		this.valuePropStatusName = item['name'];
			// 	}
			// 	return item;

			// });
		});
	}

	updateValuePropStatusVPGroup(statusTypeid, value_prop_group_id) {
		let payload = {
			"value_prop_group_id": value_prop_group_id,
			"status_type_id": statusTypeid
		};
		this.valuepropService.updateValuePropGroupStatus(value_prop_group_id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshDashboard.next('refreshDashboard');
				this.readMasterValueProp(); //would be nice if we could update one of these in place...
				this.notificationService.success("Status Updated successfully", false); // Params {message, islogout}
				this.getValuePropStatusTypes();
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
		});
	}

	updateValuePropStatus(statusTypeid, valuePropId) {
		let statusTypeObj = {
			"value_prop_status_type_id": statusTypeid
		};
		this.valuepropService.updateValuePropStatus(valuePropId, statusTypeObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshDashboard.next('refreshDashboard');
				this.readMasterValueProp(); //would be nice if we could update one of these in place...
				this.notificationService.success("Status Updated successfully", false); // Params {message, islogout}
				this.getValuePropStatusTypes();
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
		});
	}
	setStatusEditable(valueProp) {
		valueProp.valuePropStatus_editable = !valueProp.valuePropStatus_editable;
		if (valueProp.valuePropStatus_editable) {
			this.getValuePropStatusTypes();
		}
	}

	getScalers() {
		this.showActionLoader = true;
		this.masterValuePropService.readAvailableScalers(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
			this.allScalersList = result.result.scalers;

			this.showActionLoader = false;
		});

		this.showScalers = true;

	}

	createScaler() {
		// Scaler create
		if (this.allScalersList && this.allScalersList != null && this.allScalersList.length > 0) {
			this.selectedScalersList = this.allScalersList.filter((scaler: any) => {
				return scaler.value;
			});

			let scalerPayload = {
				'value_prop_scalers': this.allScalersList,
				'angular_dev': 1
			};
			this.showActionLoaderScaler = true;

			this.masterValuePropService.createScalers(this.masterValuePropId, scalerPayload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
				this.readMasterValueProp();
				this.showActionLoaderScaler = false;
			});



		}

	}

	getFAIcon(format) {
		switch (format) {
			case "MS Word":
				return "fa fa-file-word-o"

			case "MS Excel":
				return "fa fa-file-excel-o"

			case "MS PowerPoint":
				return "fa fa-file-powerpoint-o"

			case "MS PDF":
				return "fa fa-file-pdf-o"

			default:
				return "fa fa-cloud-download"

		}
	}

	getFAColor(format) {
		switch (format) {
			case "MS Word":
				return { "color": "#2e5b97" };

			case "MS Excel":
				return { "color": "#3a6e46" };

			case "MS PowerPoint":
				return { "color": "#c55737" };

			case "MS PDF":
				return { "color": "#c60a09" };

			default:
				return { "color": "black" }
		}
	}

	updateScalerValue(valueprop, scaler) {


		let payload;

		if (scaler.driver_factor_id == "2372" && scaler.driver_source_type_id) {
			payload = {
				'driver_factor_id': scaler.driver_factor_id,
				'driver_override': scaler.value,
				'driver_source_type_id': scaler.driver_source_type_id,
				'value_prop_metric_id': scaler.value_prop_metric_id
			};
			this.valuepropService.updateDriverFactor(valueprop.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

				if (response.result) {
					this.readMasterValueProp();
					this.getValuepropScalers();
					if (this.isVPGroup) {
						this.getVPGroupAssets();
					} else {
						this.readTemplates();
					}
					this.notification.success("Scalers updated successfully", false);
				}
			}
			);
		} else {
			payload = {
				"value_prop_scalers": valueprop.scalers,
				"angular_dev": 1
			}
			this.valuepropService.createScalers(valueprop.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

				if (response.result) {
					this.readMasterValueProp();
					this.getValuepropScalers();
					if (this.isVPGroup) {
						this.getVPGroupAssets();
					} else {
						this.readTemplates();
					}
					this.notification.success("Scalers updated successfully", false);
					// this.showActionLoader = false;
				}
			}
			);
		}
	}
}