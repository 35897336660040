import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SituationInputType } from '@shared/models/situation-input-type.enum';
import { StyleService } from 'app/style.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-situation-input',
  templateUrl: './situation-input.component.html',
  styleUrls: ['./situation-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SituationInputComponent {
  modelValue = [];
  situationValue;
  elementTypeIdValue: number;
  style2022$: Observable<boolean>;

  SituationInputType = SituationInputType;

  @Input() feature88 = false;
  @Input() canEdit = true;

  @Input()
  set situation(val) {
    this.situationValue = val;
  }

  get situation() {
    return this.situationValue;
  }

  @Input()
  set elementTypeId(val) {
    this.elementTypeIdValue = +val;
  }

  get elementTypeId(): number {
    return this.elementTypeIdValue;
  }

  @Input()
  get model() {
    return this.modelValue;
  }

  @Output()
  modelChange = new EventEmitter();

  constructor(private styleService: StyleService) {
    this.style2022$ = this.styleService.style2022;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  onChange(_$event) {
    this.modelChange.emit(this.modelValue);
  }
}
