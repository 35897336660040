import { NgModule } from '@angular/core';

import { SolutionDetailComponent } from './solution-detail.component';
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelOverviewModule } from '../overview/overview.module';
import { ModelProjectsModule } from '../projects/projects.module';
import { ModelValueCategoryModule } from '../value-category/value-category.module';
import { ModelAssumptionsModule } from '../assumptions/assumptions.module';
import { ModelBenefitsModule } from '../benefits/benefits.module';
import { ModelCostsModule } from '../cost/cost.module';
import { ModelCaseStudiesModule } from '../case-studies/case-studies.module';
import { ModelCollateralModule } from '../collateral/collateral.module';
import { ModelSolutionCompetitorsModule } from '../competitors/competitors.module';
import { ModelThresholdsModule } from '../thresholds/thresholds.module';
import { ValueMapPainPointsModule } from '../views/pain_points/pain.module';
import { ValueMapCapabilitiesModule } from '../views/capabilities/capabilities.module';
import { ValueMapFuncObjectivesModule } from '../views/func_objectives/func.module';
import { ValueMapOpObjectivesModule } from '../views/op_objectives/op_objectives.module';
import { DetailSituationModule } from '../situation/situation.module';

import { MenubarModule } from 'primeng/menubar';
import { ModelScalersModule } from '../scalers/scalers.module';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';

import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SolutionDetailTranslations } from './solution-detail.translation';
import { TypicalCustomersModule } from '../typical_customers/scalers.module';
import { FactorsListModule } from '../factors/factors.module';
import { CaseStudyTagsModule } from '../case-studies/tags/tags.module';
import { ModelGoalsModule } from '../goals/model-goals.module';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { TabView, TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { TrackerBaselineTranslation } from '../tracker-baseline/tracker-baseline.translation';
import { ValueRealizedModule } from 'app/value-realized/value-realized.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModelScalerDefaultsModule } from '../../scaler-defaults/model-scaler-defaults.module';
import { ValidationModule } from 'app/value-map/solution-detail/validation/validation.module';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  declarations: [SolutionDetailComponent],
  imports: [
    CaseStudyTagsModule,
    FactorsListModule,
    TypicalCustomersModule,
    MatTooltipModule,
    DLMenubarModule,
    ModelScalersModule,
    MenubarModule,
    ValueMapCapabilitiesModule,
    ValueMapFuncObjectivesModule,
    ValueMapOpObjectivesModule,
    ValueMapPainPointsModule,
    ModelThresholdsModule,
    ModelThresholdsModule,
    ModelSolutionCompetitorsModule,
    ModelCollateralModule,
    ModelCaseStudiesModule,
    ModelCostsModule,
    ModelProjectsModule,
    ModelValueCategoryModule,
    ModelOverviewModule,
    ModelAssumptionsModule,
    ModelBenefitsModule,
    ModelGoalsModule,
    ModelScalerDefaultsModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    DetailSituationModule,
    TableModule,
    CalendarModule,
    TabViewModule,
    DropdownModule,
    ValueRealizedModule,
    ValidationModule,
    BreadcrumbsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  providers: [Translations, TranslationsV2Service, SolutionDetailTranslations, TrackerBaselineTranslation],
  entryComponents: [SolutionDetailComponent],
})
export class ModelSolutionDetailModule {}
