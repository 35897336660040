<div class="row">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">
            {{ trans.trans.manage_discovery_questions.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.manage_discovery_questions"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-2">
          <button class="btn btn_customizable pull-right" (click)="funcAddQuestion()"><i class="fa fa-plus"></i>{{ trans.trans.add.value }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <table class="table table-responsive table-striped">
          <thead>
            <tr>
              <th>
                {{ trans.trans.name.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.name"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.type.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.type"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.tag_question.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.tag_question"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.tag_answer.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.tag_answer"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.required.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.required"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.model.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.model"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.placement.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.placement"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="!questions.length">
            <tr>
              <td colspan="7">{{ trans.trans.nothing.value }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="questions.length">
            <tr *ngFor="let q of questions">
              <td>{{ q.name }}</td>
              <td>{{ funcGetQuestionTypeText(q.question_type_id) }}</td>
              <td>{{ q.question_tag | assetTagFormat: 'Question' }}</td>
              <td>{{ q.question_tag | assetTagFormat: 'Answer' }}</td>
              <td>{{ q.required === '1' ? trans.trans.yes.value : trans.trans.no.value }}</td>
              <td>{{ q.default_active === '1' ? trans.trans.all.value : q.default_active === '0' ? trans.trans.none.value : q.selected_models?.length }}</td>
              <td>
                <ng-container *ngIf="q.selected_sections">
                  <p class="question-section" *ngFor="let section of q.selected_sections">{{ section.name }}</p>
                </ng-container>
              </td>
              <td>
                <span class="keywest_icon_group pull-right">
                  <span><i (click)="funcEditQuestion(q)" class="fa fa-pencil clickable"></i></span>
                  <span class="clickable">
                    <app-are-you-sure
                      [matTooltip]="trans.trans.delete.value"
                      matTooltipPosition="above"
                      [message]="trans.trans.are_you_sure.value"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="funcDeleteQuestion(q)"
                    ></app-are-you-sure>
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="showAdd" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <form name="AddDiscoveryQuestion" #AddDiscoveryQuestion="ngForm" (ngSubmit)="funcUpdateQuestion(AddDiscoveryQuestion)">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ question.id ? trans.trans.edit_question.value : trans.trans.add_a_new_question.value }}
        <app-editTranslation
          *ngIf="showTranslate && question.id"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.edit_question"
          (callback)="getTranslations()"
        ></app-editTranslation>
        <app-editTranslation
          *ngIf="showTranslate && !question.id"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.add_a_new_question"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label labelsRequired"
                >{{ trans.trans.name.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_name.value"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.name"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input id="name" type="text" name="name" [(ngModel)]="question.name" class="form-control" required autocomplete="off" />
              <span class="tag-display">{{ question.question_tag | assetTagFormat: 'Question' }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="type" class="sidebar_label labelsRequired"
                >{{ trans.trans.type.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_type.value"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.type"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <p-dropdown
                appendTo="body"
                [panelStyleClass]="'dropdown-item-font'"
                id="type"
                name="type"
                class="keywest_dropdown dropdown-theme"
                [placeholder]="trans.trans.chooseType.value"
                [autoDisplayFirst]="false"
                [showClear]="true"
                [options]="typesList"
                [(ngModel)]="selectedType"
                [required]="true"
                optionLabel="name"
                dataKey="id"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="row" *ngIf="selectedType?.id !== 3">
            <div class="col-xs-12">
              <label for="answer" class="sidebar_label"
                >{{ trans.trans.default_answer.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.default_answer"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <textarea rows="3" id="answer" name="answer" [(ngModel)]="question.placeholder_text" class="form-control"></textarea>
              <span class="tag-display">{{ question.question_tag | assetTagFormat: 'Answer' }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="tag" class="sidebar_label labelsRequired"
                >{{ trans.trans.tag_custom.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.tag_custom"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_tag.value"> info </span>
              </label>
              <input type="text" id="tag" name="tag" [(ngModel)]="question.question_tag" class="form-control" required />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="description" class="sidebar_label labelsRequired"
                >{{ trans.trans.description.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_description.value"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.description"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <textarea rows="3" id="description" name="description" [(ngModel)]="question.description" class="form-control" required></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="model" class="sidebar_label"
                >{{ trans.trans.model.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.model"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <p-multiSelect
                [panelStyleClass]="'dropdown-item-font'"
                [options]="availableModels"
                [placeholder]="trans.trans.chooseModel.value"
                [(ngModel)]="selectedModels"
                class="keywest_dropdown dropdown-theme"
                optionLabel="name"
                id="model"
                name="model"
                appendTo="body"
                dataKey="id"
              ></p-multiSelect>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="model" class="sidebar_label"
                >{{ trans.trans.placement.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.placement"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <p-multiSelect
                [panelStyleClass]="'multi-select-item-font'"
                appendTo="body"
                id="placement"
                name="placement"
                [placeholder]="trans.trans.choosePlacement.value"
                class="keywest_dropdown multi-select-theme"
                [options]="discoveryQuestionSections"
                [(ngModel)]="question.selected_sections"
                optionLabel="label"
              ></p-multiSelect>
            </div>
          </div>

          <div class="row text-right">
            <div class="col-xs-12 required-container">
              <p-checkbox class="marg-h" id="required" [binary]="true" name="required" [(ngModel)]="question.requiredBool" [value]="question.requiredBool"></p-checkbox>
              <label for="required" class="sidebar_label"
                >{{ trans.trans.required.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_required.value"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.required"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_container">
        <div class="row">
          <div class="col-md-12">
            <button type="submit" *ngIf="!showActionLoader" class="btn btn_customizable pull-right xf-continue-button" [disabled]="AddDiscoveryQuestion.invalid">
              <ng-container *ngIf="!showActionLoader; else loadingSpinner">
                {{ trans.trans.save.value }}
              </ng-container>
              <ng-template #loadingSpinner>
                <i class="fa fa-spinner fa-spin fa-fw"></i>
              </ng-template>
            </button>
            <app-are-you-sure
              matTooltipPosition="above"
              [message]="trans.trans.are_you_sure.value"
              class="btn cancel-confirm pull-right marg-h"
              icon=""
              [text]="trans.trans.back.value"
              (callbackYes)="cancelAdd()"
            ></app-are-you-sure>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>
