import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { PrimengChartDataCustom, PrimengDataCustomLabels, PrimengLabels } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';
import { ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
	selector: 'app-doughnut5-chart',
	templateUrl: './doughnut5-chart.component.html',
	styleUrls: ['./doughnut5-chart.component.scss']
})
export class Doughnut5ChartComponent implements OnInit, OnChanges, OnDestroy {
	@Input('data') chartData: any;
	chart: any;
	options: any;
	@Input() legend: any;
	@Input() height = "300px";
	@Input() valueProp: ValueProp;
	selectedName = "";
	selectedVal = "";
	selectedCLR = "";

	ngUnsubscribe = new Subject();
	style2022$: Observable<boolean>;

	@Output() benefitClick = new EventEmitter();
	constructor(
		private RS: RepSimpleViewService,
		private valuePropService: ValuepropService,
		private styleService: StyleService
	) {

	}
	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.buildChart(this.chartData);

		const benId = sessionStorage.getItem("repsimple_results_benefit_id");
		if (benId) {
			const cat = this.chartData.data.labels.find((category) => category.id === benId);
			this.setSelected(cat ? cat : this.chartData.data.labels[0]);
		} else {
			this.setSelected(this.chartData.data.labels[0]);
		}
		if (benId &&
			sessionStorage.getItem('repsimple_results_view') &&
			this.valueProp && this.valueProp.id) {
			this.RS.getBenefitsByValueCategoryID(this.valueProp.id, sessionStorage.getItem("repsimple_results_benefit_id"))
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(res => {
					this.benefitClick.emit(res.result);
				});
		}

		this.valuePropService.refreshBenefitDetail.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.RS.getBenefitsByValueCategoryID(this.valueProp.id, sessionStorage.getItem("repsimple_results_benefit_id"))
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(res => {
					this.benefitClick.emit(res.result);
				});
		})
	}

	ngOnChanges(): void {
		this.selectedCLR = (this.chartData.data.labels && this.chartData.data.labels[0] && this.chartData.data.labels[0].color ? this.chartData.data.labels[0].color : "");
		this.selectedName = (this.chartData.data.labels && this.chartData.data.labels[0] && this.chartData.data.labels[0].name ? this.chartData.data.labels[0].name : "");
		this.selectedVal = (this.chartData.data.labels && this.chartData.data.labels[0] && this.chartData.data.labels[0].fr ? this.chartData.data.labels[0].fr : "");
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	clickBenefitClick(item: PrimengLabels): void {
		this.selectedCLR = item.color;
		this.selectedName = item.name;
		this.selectedVal = item.fr;
		item.metrics = this.funcFixVal(item.metrics);
		sessionStorage.setItem("repsimple_results_view", "benefits");
		sessionStorage.setItem("repsimple_results_benefit_id", item.id);
		this.benefitClick.emit(item);
	}

	funcFixVal(benefits: PrimengLabels['metrics']): PrimengLabels['metrics'] {
		for (let i = 0; i < benefits.length; i++) {
			const yearBenefit = benefits[i].year1_benefits;
			benefits[i].year1_benefits = typeof yearBenefit === 'string' ? parseInt(yearBenefit) : yearBenefit;
			const totalBenefit = benefits[i].total_benefits;
			benefits[i].total_benefits = typeof totalBenefit === 'string' ? parseInt(totalBenefit) : totalBenefit;
		}
		return benefits;
	}

	buildChart(chartData: PrimengChartDataCustom): void {
		this.chart = {
			labels: this.chartData.data.labels,
			datasets: this.chartData.data.datasets
		}
		this.options = {
			cutoutPercentage: 80,
			type: "doughnut",
			layout: {
				padding: {
					left: 0,
					right: 0,
					top: 0,
					bottom: 0
				}
			},
			legend: {
				display: false
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						if (chartData['d0']) {
							const customLabel = data.labels as unknown[] as PrimengDataCustomLabels['labels']; // casting because callback use regular interface
							const label = customLabel[tooltipItem.index].name + ": " + data.datasets[0].data[tooltipItem.index] + "%";
							return label;
						}
					}
				}
			},
			animation: {
				duration: 100
			}
		}
	}

	setSelected(category: PrimengLabels): void {
		this.selectedCLR = category.color;
		this.selectedName = category.name;
		this.selectedVal = category.fr;
	}
}
