<div class="models_panel">
  <div class="models_page_header_container_small">
    <div id="btn-container">
      <a class="btn btn_customizable mr-2" (click)="saveChanges()">
        <ng-container *ngIf="!isLoading; else loader">
          {{ trans.trans.saveChanges.value }}
        </ng-container>
        <ng-template #loader>
          <img class="actionLoader pull-right" [src]="fullImagePath" />
        </ng-template>
      </a>
      <a class="btn btn_customizable" (click)="onOpenGoal()"><i class="fa fa-plus"></i>{{ trans.trans.addGoal.value }}</a>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <p-progressBar *ngIf="isLoading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

      <p-accordion [multiple]="true">
        <p-accordionTab *ngFor="let group of goalGroups; let i = index; trackBy: groupIdentity" [header]="group.name">
          <div class="goal-wrapper">
            <p-table *ngIf="group.factors?.length > 0; else noRecords" [value]="group.factors" [columns]="cols" styleClass="keywest_table" tableStyleClass="keywest_table">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    *ngFor="let col of columns"
                    [pSortableColumn]="col.field"
                    [ngClass]="{ 'fixed-width': col.field === 'baselineValue' || col.field === 'value', 'centered': col.field === 'units' }"
                  >
                    {{ col.header }}
                  </th>
                  <th class="fixed-width-small"></th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="data-rows">
                    <ng-container *ngIf="!(goalGroupArrays[i] | arrayToFormControl: rowIndex:'editing')?.value">
                      {{ rowData.name }}
                      <i (click)="editName(i, rowIndex, rowData)" class="clickable fa fa-pencil" style="margin-left: 10px"></i>

                      <i
                        *ngIf="!!rowData.scratchpad_str && rowData.scratchpad_used === 1"
                        (click)="toggleScratchpadSidebar(rowData)"
                        class="clickable fa fa-calculator"
                        [ngStyle]="{ color: 'green' }"
                        style="margin-left: 10px"
                      ></i>
                      <img
                        *ngIf="!!rowData.scratchpad_str && rowData.scratchpad_used === 0"
                        class="clickable"
                        style="width: 18px; margin-left: 10px; margin-bottom: 6px"
                        src="assets/images/scratchpad/calculator-inactive.png"
                        (click)="toggleScratchpadSidebar(rowData)"
                      />
                      <ng-container *ngIf="rowData.original_factor_name !== rowData.name">
                        <br />
                        <span class="small italic"> {{ trans.trans.originalGoalName.value }}: {{ rowData.original_factor_name }}</span>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(goalGroupArrays[i] | arrayToFormControl: rowIndex:'editing')?.value">
                      <app-error-input-wrapper [errorMessage]="(goalGroupArrays[i] | returnErrorObservable: rowIndex | async)?.get('name')" [showFormGroup]="false">
                        <div class="input-group">
                          <input
                            [formControl]="goalGroupArrays[i] | arrayToFormControl: rowIndex:'name'"
                            type="text"
                            class="form-control"
                            (keydown.Enter)="saveName(i, rowIndex, rowData.account_factor_id)"
                          />
                          <span class="clickable input-group-addon" (click)="editName(i, rowIndex, rowData)"><i class="fa fa-times"></i></span>
                          <span class="clickable input-group-addon" (click)="saveName(i, rowIndex, rowData.account_factor_id)"><i class="fa fa-save"></i></span>
                        </div>
                      </app-error-input-wrapper>
                    </ng-container>
                  </td>

                  <td class="data-rows fixed-width">
                    <ng-container *ngIf="rowData.capture_baseline === '1'">
                      <ng-container *ngIf="!canEdit; else baselineInput">{{ rowData.baseline_value_fmt }}</ng-container>
                      <ng-template #baselineInput>
                        <input type="text" class="form-control" (keyup)="improvementKeyPress(rowData)" [(ngModel)]="rowData.baseline_value_fmt" [disabled]="isLoading" />
                      </ng-template>
                    </ng-container>
                  </td>

                  <td class="data-rows fixed-width">
                    <ng-container *ngIf="rowData.capture_target === '1'">
                      <ng-container *ngIf="!canEdit; else targetInput">{{ rowData.value_fmt }}</ng-container>
                      <ng-template #targetInput>
                        <input type="text" class="form-control" (keyup)="improvementKeyPress(rowData)" [(ngModel)]="rowData.value_fmt" [disabled]="isLoading" />
                      </ng-template>
                    </ng-container>
                  </td>
                  <td class="data-rows centered">
                    <app-unit-type [unitType]="rowData?.unit_type_id"></app-unit-type>
                  </td>
                  <td class="fixed-width-small">
                    <app-ellipsis-menu>
                      <ul class="list-group">
                        <li class="list-group-item">
                          <app-are-you-sure
                            [text]="trans.trans.delete.value"
                            icon=""
                            [message]="trans.trans.areYouSure.value"
                            (callbackYes)="onRemoveGoalFromVp(rowData)"
                          ></app-are-you-sure>
                        </li>
                      </ul>
                    </app-ellipsis-menu>
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <ng-template #noRecords>
              <app-no-records>{{ trans.trans.noGoals.value }}</app-no-records>
            </ng-template>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <p-sidebar styleClass="keywest_sidebar p-sidebar-lg" (onHide)="closeSidebars()" [(visible)]="sidebarOpen" position="right">
    <app-goal-form
      *ngIf="sidebarOpen"
      [goalData]="goalSelected"
      [modelGoalIds]="goalIdsList"
      [vpId]="valuePropId"
      [hideCreateNew]="!goalOnTheFlyPrivilege"
      [loadingSave]="isLoadingAdd"
      (saveClick)="addGoalToVp($event)"
      (cancelClick)="closeSidebars()"
    >
    </app-goal-form>
  </p-sidebar>

  <p-sidebar styleClass="keywest_sidebar p-sidebar-lg" (onHide)="closeSidebars()" [(visible)]="toggleScratchpadView" position="right">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">Edit Scratchpad</div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <app-scratchpad-component
            *ngIf="toggleScratchpadView"
            (SaveAndUpdateCallback)="scratchpadSave()"
            factorTypeId="0"
            [valueMetricId]="goalSelected.account_factor_id"
            [valuePropId]="valuePropId"
            [valueMetric]="goalSelected"
            [goal]="true"
            [year]="1"
          ></app-scratchpad-component>
        </div>
      </div>
    </div>
  </p-sidebar>
</div>

<!-- Close overlay from action menu -->
<span #closeOverlay></span>
