import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AccountService } from '@data/services/account/account.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CurrencyTranslation } from './currency.translation';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { CommonService } from '@data/services/common/common.service';

@Component({
  selector: 'app-admin-currency',
  styleUrls: ['./currency.component.scss'],
  templateUrl: './currency.component.html',
})
export class AdminCurrencyComponent implements OnInit, OnDestroy {
  account_id: string = '';
  ngUnsubscribe = new Subject();
  strSearch: string = '';
  loading: boolean = false;
  style2022$: Observable<boolean>;
  breadcrumbKeyValues: Breadcrumbs;

  currencies: any[] = [];

  constructor(
    private accountService: AccountService,
    private notificationService: NotificationService,
    private styleService: StyleService,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: CurrencyTranslation
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
    this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getData() {
    this.loading = true;
    this.accountService
      .getCurrencyRates()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.currencies = res.result.map((elem) => this.setElement(elem));
        this.loading = false;
      });
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

			this.breadcrumbKeyValues = {
				[this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
				[this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
				[this.trans.trans.currency.value]: { routerLink: '', active: true },
			  };
		});
  }

  funcSave() {
    let out = [];
    this.currencies.forEach((elem) => {
      if (elem.checked && elem.value && elem.value >= 0) {
        out.push({ currency_type_id: elem.id, rate: elem.value, web_calc_enabled: +elem.web_calc_enabled });
      }
    });

    let payload = {
      rates: out,
    };

    this.accountService
      .postCurrencyRates(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.notificationService.success('Exchange Rates Updated', false);
        this.currencies = res.result.rates.map((elem) => this.setElement(elem));
      });
  }

  setElement(elem: any) {
    return { ...elem, web_calc_enabled: elem.web_calc_enabled === undefined ? 0 : elem.web_calc_enabled };
  }
}
