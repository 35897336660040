import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SalesManagerFunctions {
  public toggleOrgTerm = new Subject<any>();

  calcQuick(v): { start_end: string; end_date: string } {
    const s = new Date();
    let m = s.getMonth() + 1;
    let y = 0;
    let mm: string;
    if (v > m) {
      y = 1;
      const t = s.getMonth() - v;

      if (t.toString().substr(0, 1) === '-') {
        mm = (12 - (v - m)).toString();
      }
    } else {
      m = m - v;
      if (m === 0) {
        m = 12;
        y = 1;
      }
      mm = m.toString().length === 2 ? m.toString() : '0' + m;
    }

    const ss = s.getFullYear() - y + '-' + mm + '-' + s.getDate();

    const e = new Date();
    const ee = e.getFullYear() + '-' + ('0' + (e.getMonth() + 1)).slice(-2) + '-' + e.getDate();
    return { start_end: ss, end_date: ee };
  }

  hexToRgb(hex: string): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const out = result ? 'rgba(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', ' + 0.6 + ')' : null;
    return out;
  }
}
