import { SurveyTemplate } from '@data/services/valueprop/models/survey-template';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SurveyTemplateDefaultTranslations } from './survey-template.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { Menu } from 'primeng/menu';
import { StyleService } from 'app/style.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { RowLimit } from 'app/_models/row-limit.model';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-survey-template',
  templateUrl: './survey-template.component.html',
  styleUrls: ['./survey-template.component.scss'],
})
export class SurveyTemplateComponent implements OnInit, OnDestroy {
  @Input() showSurveyQuestionsList = true;
  @Output() getActiveSurveyTemplateList = new EventEmitter();
  @ViewChild('menu') menu: Menu;
  surveyDetails: SurveyTemplate;
  accountId: string;
  sliderStartValue = 2;
  items: MenuItem[] = [];

  loadSurveysTable = false;
  surveys: SurveyTemplate[] = [];
  valuePropId: string;
  surveyTemplateId: string;
  breadcrumbKeyValues: Breadcrumbs;

  subscriptionRouterParams: Subscription;
  subscriptionreadSurveyTemplateList: Subscription;
  subscriptiongetValueRealization_SurveyTemplates: Subscription;
  surveyTemplate: SurveyTemplate;
  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private valuerealizationService: ValuerealizationService,
    private route: ActivatedRoute,
    public trans: SurveyTemplateDefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.accountId = sessionStorage.getItem('aid');
    this.getSurveyList();
    this.subscriptionRouterParams = this.route.params.subscribe((params) => this.changeUrl(params.id));

    this.subscriptionreadSurveyTemplateList = this.valuerealizationService.readSurveyTemplateList.subscribe((response) => {
      this.getSurveyList();
      this.openSurveyTemplateDetail(response);
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.style2022$ = this.styleService.style2022;
  }
  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.survey_templates.value]: { routerLink: '', active: true },
        };
      });
  }
  ngOnDestroy(): void {
    if (this.subscriptionRouterParams) {
      this.subscriptionRouterParams.unsubscribe();
    }
    if (this.subscriptionreadSurveyTemplateList) {
      this.subscriptionreadSurveyTemplateList.unsubscribe();
    }
    if (this.subscriptiongetValueRealization_SurveyTemplates) {
      this.subscriptiongetValueRealization_SurveyTemplates.unsubscribe();
    }
  }

  changeUrl(id: string): void {
    this.valuePropId = id;
  }

  getSurveyList() : void{
    this.loadSurveysTable = false;
    this.subscriptiongetValueRealization_SurveyTemplates = this.valuerealizationService.getValueRealization_SurveyTemplates(this.accountId).subscribe((response) => {
      if (response.result && response.result) {
        this.surveys = response.result;
      }
      this.loadSurveysTable = true;
    });
  }

  openSurveyTemplateDetail(survey): void {
    this.surveyTemplate = survey;
    this.surveyTemplateId = survey.id;
    this.showSurveyQuestionsList = false;
  }

  gettemplateList(): void {
    this.showSurveyQuestionsList = true;
    this.getSurveyList();
    this.getActiveSurveyTemplateList.emit();
  }

  formVisible() {
    this.showSurveyQuestionsList = !this.showSurveyQuestionsList;
  }

  funcBuildSubMenu(survey, event): void {
    this.items = [
      {
        label: 'Edit',
        command: () => {
          this.openSurveyTemplateDetail(survey);
        },
      },
    ];
    this.menu.toggle(event);
  }

  refreshTranslation(): void {
    this.getTranslations();
  }
}
