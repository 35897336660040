<p-table
  *ngIf="companiesList && companiesList.length > 0"
  #tableCompanies
  [columns]="cols"
  [value]="companiesList"
  [paginator]="true"
  [rows]="10"
  styleClass="keywest_table"
  tableStyleClass="keywest_table"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.displayCondition" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </ng-container>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-company let-index="rowIndex">
    <tr>
      <td>
        <img
          *ngIf="company.logo_path"
          onerror="this.onerror=null; this.remove();"
          (error)="onImgError(company)"
          style="width: 20px; margin-right: 10px"
          [src]="company.name | functionPipe: getLogo:company.logo_path"
        />
        <i *ngIf="!company.logo_path" class="fa fa-building light-h company-default-icon"></i>
        <a (click)="onSelectCompany(company)" class="clickable" [attr.data-heap]="'value-execution-add-a-new-value-proposition-table-company-select-' + index">{{ company.name }}</a>

        <span class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="tipContent" placement="right" container="body"> info </span>
        <ng-template #tipContent class="toolTipContent">
          <ng-container *ngIf="!featureCustomAccountTable">
            <p *ngIf="company?.end_user_id && company.end_user_id.length">ECCID<br /></p>
            <div *ngFor="let ecid of company.end_user_id">{{ ecid }}<br /></div>
          </ng-container>
          <p>
            {{ company.name }}
          </p>
          <div *ngIf="company.account_defined_id">{{ company.account_defined_id }}</div>
          <div *ngIf="company.location_type">{{ company.location_type }}</div>
          <div *ngIf="company.country">{{ company.country }}</div>
          <div>
            <div *ngIf="company.address1">{{ company.address1 }}</div>
            <div *ngIf="company.city && company.state && company.zip">
              {{ company.city }},
              {{ company.state }}
              {{ company.zip }}
            </div>
          </div>
        </ng-template>
      </td>
      <td data-title="Account ID">{{ company.account_defined_id }}</td>
      <td data-title="Company ID">{{ company.id }}</td>
      <td data-title="ECCID" *ngIf="featureCustomAccountTable">
        <div *ngFor="let ecid of company.end_user_id">{{ ecid }}<br /></div>
      </td>
      <td data-title="Revenue">{{ company.revenue_fmt }}</td>
      <td data-title="Employees">{{ company.employees_fmt }}</td>
      <td data-title="City">{{ company.city }}</td>
      <td data-title="Market Area" *ngIf="featureCustomAccountTable">{{ company.market_area }}</td>
      <td data-title="Country" *ngIf="!featureCustomAccountTable">{{ company.country }}</td>
      <td>
        <app-ellipsis-menu>
          <ul class="list-group">
            <!-- Edit -->
            <li class="list-group-item" (click)="onSelectCompany(company)">
              {{ this.trans.trans.edit.value }}
            </li>
            <!-- Remove from Faves -->
            <li *ngIf="isCompanyFavorited(company)" class="list-group-item" (click)="onRemoveFromFavorites(company)">
              {{ this.trans.trans.removeFromFavorites.value }}
            </li>
            <!-- Add To Faves -->
            <li *ngIf="isRecentTable && !isCompanyFavorited(company)" class="list-group-item" (click)="onAddToFavorites(company)">
              {{ this.trans.trans.addToFav.value }}
            </li>
          </ul>
        </app-ellipsis-menu>
      </td>
    </tr>
  </ng-template>
</p-table>
<app-no-records *ngIf="!companiesList || companiesList.length === 0">
  {{ noResultsMessage }}
</app-no-records>
