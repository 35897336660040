import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class BulkUpdateTranslation extends TranslationsBaseService {

  public config: any = {
    component: 'bulkUpdate',
    display_name: 'Bulk Update Salesforce',
    description: 'Bulk Update Salesforce',
  };

  public trans: any = {
    status: 'Status',
    edit: 'Edit',
    delete: 'Delete',
    new_update: 'Start Update',
    initDate: 'Initialized',
    startDate: 'CRM Start',
    endDate: 'CRM End',
    recordsCount: 'Processed',
    recordsFailedCount: 'Failed',
    settings: 'Settings',
    dashboard: 'Dashboard',
    bulkUpdate: 'Bulk Update',
    complete: 'Complete',
    pending: 'Pending',
    saveSuccess: 'Bulk Update Request Sent',
    username: 'Initiating User',
    search: 'Search',
    refresh: 'Refresh',
    back_to_profile: 'Back to Profile'
  };
}
