import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '@data/services/user/user.service';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { NotificationService } from '@services/notification.service';
import { ApiServiceService } from '../../api-service.service';
import { Subject } from 'rxjs';
import { DefaultTranslations } from './applications-settings.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { filter, pluck, take, takeUntil } from 'rxjs/operators';
import { accountDefaultColors } from '@shared/utils';
import { AppImplementation } from 'app/app/app.implementation';
import { Term } from '@data/services/account/models/term.interface';
import { User } from 'app/value-prop/value-prop-dashboard/sharev2/share.component';
import { ChartColor, EmailUpdateMessage, FactColor, PalatteColor, ValuePropStatus } from '@shared/models/account.models';
import { FormControl, Validators } from '@angular/forms';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { CustomValidators } from 'app/_utils/custom-validators';
import { ErrorMessagesService } from '@services/error-messages.service';
import { SelectItem } from 'primeng/api';
import { AssetGenerator } from '@data/services/account/models/asset-generator.interface';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { WACCFactorPayload } from '@data/services/account/models/factor-wacc.interface';
import { AccountAggregatedFactor } from '@data/services/account/models/aggregated-services.interface';

type ApplicationDropdownSettings = {
  singleSelection: boolean;
  text: string;
  enableCheckAll: boolean;
  enableSearchFilter: boolean;
  labelKey: string;
  lazyLoading: boolean;
};

@Component({
  selector: 'app-applications-settings',
  templateUrl: './applications-settings.component.html',
  styleUrls: ['./applications-settings.component.scss'],
})
export class ApplicationsSettingsComponent implements OnInit, OnDestroy {
  currentColor = '';
  dropdownSettings: ApplicationDropdownSettings;
  selectedItems: Array<FactColor> = [];
  selectedColors: Array<PalatteColor> = [];
  chartSelectedcolor: FactColor[];
  fullImagePath: string;
  isAccountAdmin = true;
  actionLoader = false;
  user_id: string;
  image_url: string;
  account_id: string;
  selectedheaderColor: string;
  showColor = false;
  user_token: string;
  userColorPreference: Array<string>;
  userProfileImage: string;
  userIconImage: string;
  mobile: string;
  phone: string;
  user: User;
  accountLogoFiles: File;
  accountIconFiles: File;
  profileIconName: string;
  logo_filepathdefaultpath: string;
  icon_filepathdefaultpath: string;
  actionLoaderLogo = false;
  actionLoaderIcon = false;
  userMultiColorPreference: Array<FactColor> = [];
  supportEmailCache: string;
  supportUrlCache: string;
  communityUrlCache: string;
  lmsPathCache: string;
  supportEmail = new FormControl('', [Validators.email]);
  supportUrl = new FormControl('', [CustomValidators.urlValidator()]);
  communityUrl = new FormControl('', [CustomValidators.urlValidator()]);
  lmsPath = new FormControl('', [CustomValidators.urlValidator()]);
  supportEmailUpdateMessage: EmailUpdateMessage;
  supportUrlUpdateMessage: EmailUpdateMessage;
  communityUrlUpdateMessage: EmailUpdateMessage;
  lmsPathUpdateMessage: EmailUpdateMessage;
  add_status = false;
  statuses: ValuePropStatus[] = [];
  new_status: ValuePropStatus = {
    name: '',
    description: '',
    comment: '0',
  };
  status: ValuePropStatus = {};

  chartColors: ChartColor[] = [];
  newColor = '';
  button_color: string;
  button_text_color: string;
  ngUnsubscribe$ = new Subject<boolean>();
  showTranslate = false;

  availableTerms = [
    {
      id: '1',
      label: '1 Year',
      selected: false,
    },
    {
      id: '2',
      label: '2 Years',
      selected: false,
    },
    {
      id: '3',
      label: '3 Years',
      selected: false,
    },
    {
      id: '4',
      label: '4 Years',
      selected: false,
    },
    {
      id: '5',
      label: '5 Years',
      selected: false,
    },
    {
      id: '6',
      label: '6 Years',
      selected: false,
    },
    {
      id: '7',
      label: '7 Years',
      selected: false,
    },
    {
      id: '8',
      label: '8 Years',
      selected: false,
    },
    {
      id: '9',
      label: '9 Years',
      selected: false,
    },
    {
      id: '10',
      label: '10 Years',
      selected: false,
    },
  ];

  defaultTermOptions: Term[];
  defaultTerm: Term;

  breadcrumbKeyValues: Breadcrumbs;

  assetGenerator: AssetGenerator;
  assetGeneratorType = AssetGenerator;

  errorDict = new Map<string, string>();

  fiscalYearMonths: SelectItem[] = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];
  selectedMonth: SelectItem;
  isFiscalMonthEmpty = true;

  supportUrlErrors$ = this.errorMessagesService.formContrlObsFactory(this.supportUrl, 'supportUrl');
  lmsPathErrors$ = this.errorMessagesService.formContrlObsFactory(this.lmsPath, 'lmsPath');
  communityUrlErrors$ = this.errorMessagesService.formContrlObsFactory(this.communityUrl, 'communityUrl');
  supportEmailErrors$ = this.errorMessagesService.formContrlObsFactory(this.supportEmail, 'supportEmail');

  WACCFactors: AccountAggregatedFactor[];
  selectedWACCFactor: AccountAggregatedFactor;
  isFactorSelected: boolean;

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private apiServiceService: ApiServiceService,
    private accountService: AccountService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    public aI: AppImplementation,
    private errorMessagesService: ErrorMessagesService
  ) {
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.errorMessagesService.getTranslations();
    this.status = this.new_status;
    this.fact_colors();
    this.getAccountTerms();
    this.getFiscalYearMonth();
    this.user_id = sessionStorage.getItem('uid');
    this.account_id = sessionStorage.getItem('aid');
    this.user_token = sessionStorage.getItem('vcu');
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
    this.logo_filepathdefaultpath = this.commonService.getAccountLogoUrl();
    this.icon_filepathdefaultpath = this.commonService.getAccountIconUrl();
    this.isAccountAdmin = this.userService.getIsAccountAdmin();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Available colors',
      enableCheckAll: false,
      enableSearchFilter: false,
      labelKey: 'name',
      lazyLoading: false,
    };
    this.getAccountFactorsWACC();

    this.accountService
      .readLMSPath(this.account_id)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter((response) => !!response.result),
        pluck('result', 'result', 'lms_custom_path')
      )
      .subscribe((url: string) => {
        this.lmsPathCache = url;
        this.lmsPath.patchValue(url, { emitEvent: false, onlySelf: true });
      });

    this.accountService
      .getSupportEmail()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result.data) {
          this.supportEmailCache = response.result.data;
          this.supportEmail.patchValue(response.result.data, { emitEvent: false, onlySelf: true });
        }
      });

    this.accountService.getSupportUrl();

    this.accountService
      .getCommunityUrl()
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter((response) => !!response.result),
        pluck('result')
      )
      .subscribe((url) => {
        this.communityUrlCache = url;
        this.communityUrl.patchValue(url, { emitEvent: false, onlySelf: true });
      });

    this.accountService.supportUrl$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response) => {
      this.supportUrlCache = response;
      this.supportUrl.patchValue(response, { emitEvent: false, onlySelf: true });
    });

    this.accountService.getAccountDetails(this.account_id).subscribe((account) => {
      if (account) {
        this.assetGenerator = account.asset_gen_file;
      }
    });

    this.user = JSON.parse(sessionStorage.getItem('user_Info'));
    this.mobile = this.user['mobile'];
    this.phone = this.user['phone'];
    this.setProfileImage();
    this.getStatus();

    this.funcGetAccountColors();

    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  getTranslations() {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.application_settings.value]: { routerLink: '', active: true },
        };

        this.availableTerms.forEach((term) => {
          if (this.trans.trans['year' + term.id]) {
            term.label = this.trans.trans['year' + term.id].value;
          }
        });
        this.defaultTermOptions.forEach((term) => {
          if (this.trans.trans['year' + term.term]) {
            term.name = this.trans.trans['year' + term.term].value;
          }
        });
        const langId = sessionStorage.getItem('language_type_id');
        const langAbbr = this.translationService.getLanguageAbbr(langId);
        const formatter = new Intl.DateTimeFormat(langAbbr, { month: 'long' });
        this.fiscalYearMonths.forEach((item) => {
          item.label = formatter.format(new Date(2000, item.value - 1, 15));
        });
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  setProfileImage() {
    this.userProfileImage = this.user['logo_filepath'] != '' ? this.user['logo_filepath'] : this.logo_filepathdefaultpath;
    this.userIconImage = this.user['icon_filepath'] != '' ? this.user['icon_filepath'] : this.icon_filepathdefaultpath;
  }

  setInput(inputName: string) {
    const methodName = 'set' + inputName.charAt(0).toUpperCase() + inputName.slice(1);
    const cacheName = inputName + 'Cache';
    const updateMessage = inputName + 'UpdateMessage';

    this[updateMessage] = null;
    this.accountService[methodName](this[inputName].value)
      .pipe(take(1))
      .subscribe(
        (response) => {
          if (response.result && response.result.success) {
            this[updateMessage] = { style: { color: 'green' }, message: response.result.message };
            this[cacheName] = this[inputName].value;
          } else if (response.result && !response.result.success) {
            this[updateMessage] = { style: { color: 'green' }, message: response.result };
            this[cacheName] = this[inputName].value;
          }
        },
        () => {
          this[updateMessage] = { style: { color: 'red' }, message: this.trans.trans.serverProblem.value };
        }
      );
  }

  fact_colors() {
    this.userService.getFactColorList().subscribe((result) => {
      if (result.result) {
        this.userColorPreference = result.result.colors.map((item) => {
          return item['color'];
        });
        this.userMultiColorPreference = result.result.colors.map((item) => {
          item['updated'] = false;
          return item;
        });
        this.setFactColors();
      }
    });
  }

  setFactColors() {
    const chartSelectedcolor = localStorage.getItem('selectedColor');
    if (chartSelectedcolor !== 'undefined' && chartSelectedcolor !== null) {
      this.chartSelectedcolor = JSON.parse(chartSelectedcolor);
      const chartHeaderColor = JSON.parse(localStorage.getItem('selectedColor'));

      this.chartSelectedcolor = this.chartSelectedcolor.filter((item: any) => {
        return item.colorscheme_type_id == '3';
      });

      this.currentColor = chartHeaderColor.filter((item: any) => {
        return item.colorscheme_type_id == '2' && item['color'];
      });
      if (this.currentColor.length > 0) {
        this.setPalatteColor(this.currentColor[0]['color']);
      }

      const chartcolorArray = this.chartSelectedcolor.map((item: any) => {
        return item['color'];
      });

      for (let i = 0; i < this.userMultiColorPreference.length; i++) {
        const index = chartcolorArray.indexOf(this.userMultiColorPreference[i]['color']);
        if (index != -1) {
          this.userMultiColorPreference[i]['id'] = this.chartSelectedcolor[index]['id'];
          this.userMultiColorPreference[i]['updated'] = true;
          this.selectedItems.push(this.userMultiColorPreference[i]);
        }
      }
    }
  }

  handleInputChange(event) {
    this.accountLogoFiles = event.target.files;
    let account_logo;
    const reader = new FileReader();
    reader.readAsDataURL(this.accountLogoFiles[0]);
    reader.onload = () => {
      this.userProfileImage = reader.result as string;
    };
    reader.onerror = function () {};
    if (this.accountLogoFiles && this.accountLogoFiles[0]) {
      this.actionLoaderLogo = true;
      const payload = new FormData();
      payload.append('account_logo', this.accountLogoFiles[0]);
      account_logo = payload;
      if (account_logo) {
        this.userService
          .saveAccountImage(account_logo, this.account_id)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe((result) => {
            if (result.result) {
              this.notificationService.success('Account Logo is updated successfully', false);
              this.user['logo_filepath'] = result['result']['logo_filepath'];
              this.apiServiceService.companyLogo.next(result['result']['logo_filepath']);
            } else {
              this.notificationService.error('Account Logo is not updated successfully', false);
            }
            this.actionLoaderLogo = false;
          });
      }
    }
  }

  handleIconChange(event) {
    this.accountIconFiles = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(this.accountIconFiles[0]);
    reader.onload = () => {
      this.userIconImage = reader.result as string;
    };
    reader.onerror = function () {};
    if (this.accountIconFiles && this.accountIconFiles[0]) {
      this.actionLoaderIcon = true;
      let account_icon;
      const payload = new FormData();
      payload.append('account_icon', this.accountIconFiles[0]);
      account_icon = payload;
      if (account_icon) {
        this.userService
          .saveAccountImage(account_icon, this.account_id)
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe((result) => {
            if (result.result) {
              this.notificationService.success('Account Icon is updated successfully', false);
              this.user['icon_filepath'] = result['result']['icon_filepath'];
              this.apiServiceService.companyIcon.next(result['result']['icon_filepath']);
            } else {
              this.notificationService.error('Account Icon is not updated successfully', false);
            }
            this.actionLoaderIcon = false;
          });
      }
    }
  }

  toggleColors() {
    this.showColor = !this.showColor;
  }

  drop(dropEvent: CdkDragDrop<any[]>, dropArray: any[]) {
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
    this.sequenceStatus();
  }

  setColor(color) {
    this.toggleColors();
    this.setPalatteColor(color);
  }

  setPalatteColor(color) {
    this.currentColor = color;
    const payload: PalatteColor[] = [
      {
        color_scheme_type_id: '2',
        color: color,
      },
    ];
    this.selectedColors = payload;
  }

  showAdd() {
    this.add_status = !this.add_status;
    this.status = this.new_status;
  }
  getStatus() {
    this.statuses = [];
    this.userService
      .GetValuePropStatus(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((result) => {
        if (result.result) {
          result.result.forEach((element) => {
            if (element.comment == 1) {
              element.comment = 'checked';
            } else {
              element.comment = '';
            }
            this.statuses.push(element);
          });
        }
      });
  }

  addStatus(status?) {
    let payload;
    if (status) {
      payload = status;
    } else {
      payload = this.status;
      if (payload['comment'] == 'checked') {
        payload['comment'] = 1;
      } else {
        payload['comment'] = 0;
      }
      this.add_status = !this.add_status;
    }
    this.userService
      .CreateValuePropStatus(payload, this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((result) => {
        if (result.result) {
          if (!status) {
            this.getStatus();
            this.notificationService.success('Successfully added status ' + this.status.name, false);
          }
          this.status = this.new_status;
        }
      });
  }

  updateStatus(status) {
    if (!status.name) {
      return;
    }
    const payload = {
      value_prop_status_id: status.id,
      id: status.id,
      name: status.name,
      description: status.description,
      comment: status.comment ? 1 : 0,
    };

    this.userService
      .UpdateValuePropStatus(payload, this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((result) => {
        if (result.result) {
          this.getStatus();
          this.notificationService.success('Successfully updated status', false);
        }
      });
  }

  deleteStatus(status) {
    if (confirm('Are you sure to delete status' + status.name)) {
      this.userService
        .DeleteValuePropStatus(this.account_id, status.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((result) => {
          if (result.result) {
            this.getStatus();
            this.notificationService.success('Successfully deleted status', false);
          }
        });
    }
  }

  sequenceStatus() {
    const payload = [];

    for (let i = 0; i < this.statuses.length; i++) {
      payload.push({ id: this.statuses[i].id, value_prop_status_id: this.statuses[i].id, sequence: i });
    }

    this.userService
      .SequenceValuePropStatus(payload, this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((result) => {
        if (result.result) {
          this.notificationService.success('Successfully updated order', false);
          this.getStatus();
        }
      });
  }

  importStatuses() {
    this.factsService
      .getValuePropStatusTypes()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result) {
          response.result.forEach((element) => {
            this.addStatus(element);
          });
        }
        this.getStatus();
        this.notificationService.success('Successfully added status ' + this.status.name, false);
      });
  }

  funcGetAccountColors() {
    this.accountService.getAccountColors(this.account_id).subscribe((res) => {
      this.currentColor = res.result.header_color ? res.result.header_color : accountDefaultColors.header;
      this.button_color = res.result.button_color ? res.result.button_color : accountDefaultColors.btnBg;
      this.button_text_color = res.result.button_text_color ? res.result.button_text_color : accountDefaultColors.btnText;
      this.chartColors = res.result.chart_colors;
      let out = [];
      for (let c = 0; c < res.result.chart_colors.length; c++) {
        let elem = res.result.chart_colors[c];
        out.push(elem.color);
      }
      sessionStorage.setItem('chart_colors', JSON.stringify(out));
    });
  }

  funcSetColors() {
    const payload = {
      color: this.currentColor ? this.currentColor : accountDefaultColors.header,
      button_color: this.button_color ? this.button_color : accountDefaultColors.btnBg,
      button_text_color: this.button_text_color ? this.button_text_color : accountDefaultColors.btnText,
    };
    this.accountService.setHeaderColor(this.account_id, payload).subscribe(() => {
      this.notificationService.success('Colors updated successfully', false);
      const headerColor = this.currentColor ? this.currentColor : accountDefaultColors.header;
      const buttonColor = this.button_color ? this.button_color : accountDefaultColors.btnBg;
      const buttonTextColor = this.button_text_color ? this.button_text_color : accountDefaultColors.btnText;
      sessionStorage.setItem('header_color', headerColor);
      sessionStorage.setItem('button_color', buttonColor);
      sessionStorage.setItem('button_text_color', buttonTextColor);
      this.apiServiceService.changeHeaderColor.next(this.currentColor);
      this.aI.updateButtonColors(headerColor, buttonColor, buttonTextColor);
    });
  }

  funcSetChartColor() {
    const payload = { color: this.newColor };
    this.accountService
      .setChartColor(this.account_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.funcGetAccountColors();
        this.newColor = '';
      });
  }

  funcDeleteChartColor(elem) {
    this.accountService
      .deleteChartColor(this.account_id, elem.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.funcGetAccountColors();
      });
  }

  getAccountTerms(): void {
    this.accountService
      .getTerms()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((terms) => {
        this.defaultTerm = terms.find((t) => t.is_default);
        this.defaultTermOptions = terms;

        terms.forEach((t) => {
          const availableTerm = this.availableTerms.find((at) => at.id == t.term);

          if (availableTerm) {
            availableTerm.selected = true;
          }
        });
        this.getTranslations();
      });
  }

  setDefaultTermOptions(): void {
    this.defaultTermOptions = this.availableTerms
      .filter((t) => t.selected)
      .map((t) => ({
        name: t.label,
        term: t.id,
      }));

    if (this.defaultTerm && !this.defaultTermOptions.find((t) => t.term === this.defaultTerm.term)) {
      this.defaultTerm = null;
    }
  }

  saveAccountTerms(): void {
    if (this.defaultTerm) {
      this.accountService
        .setTerms(this.defaultTermOptions, this.defaultTerm)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(() => {
          this.notificationService.success(this.trans.trans.termsSuccess.value, false);
        });
    }
  }

  saveAssetGenerator(): void {
    if (this.assetGenerator) {
      this.accountService.setAssetGenerator(this.assetGenerator).subscribe(() => {
        this.notificationService.success(this.trans.trans.assetGeneratorSuccess.value, false);
      });
    }
  }

  getFiscalYearMonth(): void {
    this.accountService.getFiscalMonth().subscribe((value) => {
      if (value) {
        this.isFiscalMonthEmpty = false;
        this.selectedMonth = this.fiscalYearMonths.find((m) => m.value === value);
      }
    });
  }

  saveFiscalMonth(): void {
    if (this.selectedMonth) {
      this.accountService.setFiscalMonth(this.selectedMonth.value, this.isFiscalMonthEmpty).subscribe((value) => {
        if (value) {
          this.isFiscalMonthEmpty = false;
          this.notificationService.success(this.trans.trans.fiscalYearSuccess.value, false);
        } else {
          this.notificationService.error(this.trans.trans.serverProblem.value, false);
        }
      });
    }
  }

  saveAccountFactorWACC(): void {
    if (!this.isFactorSelected && !this.selectedWACCFactor) {
      this.notificationService.error(this.trans.trans.waccFactorRequired.value, false);
      return;
    }
    const payload: WACCFactorPayload = {
      account_factor_id: this.selectedWACCFactor ? this.selectedWACCFactor.id : '0',
      field: 'wacc',
    };
    this.accountService.saveAccountFactorWACC(payload).subscribe(() => {
      this.notificationService.success(this.trans.trans.waccFactorSaved.value, false);
      this.isFactorSelected = !!this.selectedWACCFactor;
    });
  }

  private getSelectedAccountFactorWACC(): void {
    this.accountService.getAccountFactorWACC().subscribe((factorWACC) => {
      if (factorWACC) {
        this.selectedWACCFactor = this.WACCFactors.find((factor) => factorWACC.account_factor_id === factor.id);
        this.isFactorSelected = true;
      }
    });
  }

  private getAccountFactorsWACC(): void {
    this.accountService.getAccountAggregatedServices(this.account_id).subscribe((response) => {
      this.WACCFactors = response.result.readAccountAggregatedFactors;
      this.getSelectedAccountFactorWACC();
    });
  }
}
