<ng-container *ngIf="!showAbbrivations; else abbr">
  {{ trans.trans[unitTypes[+unitType]]?.value }}
  <app-editTranslation
    *ngIf="showTranslate"
    [component]="trans.config.component"
    [isTranslationV2]="true"
    [transObj]="trans.trans[unitTypes[+unitType]]"
    (callback)="getTranslations()"
  ></app-editTranslation>
</ng-container>
<ng-template #abbr>
  {{ trans.trans[unitTypesAbbreviated[+unitType]]?.value }}
  <app-editTranslation
    *ngIf="showTranslate"
    [component]="trans.config.component"
    [isTranslationV2]="true"
    [transObj]="trans.trans[unitTypesAbbreviated[+unitType]]"
    (callback)="getTranslations()"
  ></app-editTranslation>
</ng-template>
