import { NgModule } from '@angular/core';
import { RSectionsComponent } from "./r_sections.component";
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {EditDeleteMenuRowModule} from '../../../../../shared_components/edit-delete-menu-row/edit-delete-menu-row.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RSectionsTranslations } from './r_sections.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		RSectionsComponent,
	],
	imports: [
    MatTooltipModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		OverlayPanelModule,
		AreYouSureModule,
		EditDeleteMenuRowModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		RSectionsTranslations
	],
	entryComponents: [
		RSectionsComponent,
	],
	exports: [RSectionsComponent]
})
export class RModelSectionsModule { }