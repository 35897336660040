import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { take } from 'rxjs/operators';
import { HelpTooltipValueRealizedTranslations } from './help-tooltip-value-realized.translation';

@Component({
  selector: 'app-help-tooltip-value-realized',
  templateUrl: './help-tooltip-value-realized.component.html',
  styleUrls: ['./help-tooltip-value-realized.component.scss'],
})
export class HelpTooltipValueRealizedComponent implements OnInit {
  _stepIndex = 0;
  @Input() set stepIndex(stepIndex: number) {
    this._stepIndex = stepIndex;
  }

  get stepIndex(): number {
    return this._stepIndex;
  }

  helpItems = ['tooltipActions1', 'tooltipActions2', 'tooltipActions3', 'tooltipActions4', 'tooltipActions5', 'tooltipActions6'];

  constructor(public trans: HelpTooltipValueRealizedTranslations, private translationService: TranslationsV2Service, private commonService: CommonService) {}

  ngOnInit(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
