import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SalesManagerTerm } from '@data/services/sales_manager/models/term.model';
import { StatsLoginStats, StatsLoginsTotals, StatsUserStats, UserDashboardStats } from '@data/services/sales_manager/models/user-dashboard-stats.model';
import { SalesManagerDashboardService } from '@data/services/sales_manager/sales_manager.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { AppImplementation } from 'app/app/app.implementation';
import { StyleService } from 'app/style.service';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SalesManagerFunctions } from './sales_manager.functions';
import { SalesManagerDashboardTranslations } from './sales_manager.translation';
@Component({
  selector: 'app-dashboard-sales-manager',
  templateUrl: './sales_manager.component.html',
  styleUrls: ['./sales_manager.component.scss'],
})
export class DashboardSalesManagerComponent implements OnInit, OnDestroy {
  chart: ChartData;
  chartCreated: ChartData;

  ngUnsubscribe = new Subject();

  loading = false;
  toggleDebug = false;
  customRange: { start_date: string; end_date: string };
  columnsCreated: { field: string; header: string }[] = [];
  columnsModified: { field: string; header: string }[] = [];
  columnsLogin: { field: string; header: string }[] = [];
  account_id = '';
  // TODO?: SalesManagerTerm and MenuItem aren't compatible types. It looks like a MenuItem is being extended by SalesManagerTerm, that causes issues with the typing on the PrimeNG menu.
  term: any[] = [];
  term_filter: SalesManagerTerm;
  term_filter_display = '';

  viewQuick: any[] = [];
  view: 'dashboard' | 'org' = 'dashboard';
  view_filter = { name: 'Dashboard', view: 'dashboard' };
  quick_filter: any;

  isDev = false;
  chartTableView = false;
  opportunityChartTableView = false;

  naughtyLogin: StatsUserStats[] = [];
  naughtyCreated: StatsUserStats[] = [];
  naughtyEdited: StatsUserStats[] = [];

  naughtyLoginTotal = 0;
  naughtyCreatedTotal = 0;
  naughtyEditedTotal = 0;

  menuTableOptions = [
    { field: 'logins_by_vp_created', header: 'Value Propositions Created' },
    { field: 'logins_by_vp_modified', header: 'Value Propositions Edited' },
    { field: 'logins_by_month', header: 'Logins' },
  ];

  menuOpportunityTableOptions = [
    { field: 'logins_by_vp_created', header: 'Value Propositions Created' },
    { field: 'logins_by_vp_modified', header: 'Value Propositions Edited' },
    { field: 'logins_by_month', header: 'Logins' },
  ];

  tableDataDefault = { field: 'logins_by_vp_created', header: 'Value Propositions Created' };
  tableOpportunityDataDefault = { field: 'logins_by_vp_created', header: 'Value Propositions Created' };

  logins: StatsLoginsTotals;

  modified_vps: any;
  total_modified_vps: any;

  total_created_vps: any;
  colsTable: { field: string; header: string }[] = [];
  maxDate: Date;

  tableData: any[] = [];

  logins_by_month: StatsLoginStats[];
  options: ChartOptions;

  optionsCreated: ChartOptions;
  chartData: ChartData;
  logins_by_vp_created: any;
  logins_by_vp_modified: any;
  gradient: CanvasGradient;
  gradientStroke1: CanvasGradient;
  chartLoading: boolean;
  gradientStroke2: CanvasGradient;
  chartModified: ChartData;
  optionsModified: ChartOptions;
  optionsLogins: ChartOptions;
  chartLogins: ChartData;

  chartGeoLogins: ChartData;
  chartGeoCreated: ChartData;
  chartGeoEdited: ChartData;

  chartGeoLoginOptions: ChartOptions;
  chartGeoCreatedOptions: ChartOptions;
  chartGeoModifiedOptions: ChartOptions;

  toggleDetails = false;
  sidebar_title = '';

  loadingGeo = false;
  chartColors: string[] = [];
  chartColorCount = 0;
  strSearch = '';
  rowLimit: MenuItem[] = [];
  pageLimit = 10;

  dteStart: string;
  dteEnd: string;
  toggleCustomeDateRange = false;
  selectedTreeUser: string;
  chartOpportunityData = false;

  colsValueProps: { field: string; header: string }[] = [
    { field: 'company_name', header: 'Customer Name' },
    { field: 'opportunity_id', header: 'Opportunity ID' },
    { field: 'totalbenefits_fmt', header: 'Projected Benefits' },
    { field: 'totalcosts_fmt', header: 'Project Costs' },
    { field: 'created', header: 'Created' },
  ];

  showTranslate = false;
  chart_loading = false;
  maxYear: string;
  rid = 0;
  @ViewChild('pBar1') pBar1;
  orgView = 'revenue';
  range = false;
  primary_color = '';
  sm_expanded: { [klass:string]: unknown }[] = [];

  opportunityBarChartData: ChartDataSets['data'];
  opportunityDoughnutChartData: ChartData;
  opportunityDoughnutChartOptions: ChartOptions;
  opportunityBarChartLabels: string[];
  opportunityBarChartFontColor = '#AFBBD1';

  opportunityBarChartColors: string[];

  cache: UserDashboardStats[] = [];
  cacheProgress = 0;
  cacheIndex = 0;
  cacheFirstLoad = true;
  loadingNaughtyList: boolean;
  chartType = 'bar';
  dateLabel = 'Select Your Range';

  datesToggle: { label: string; command }[] = [];

  chartToggle: { label: string; icon: string; command }[] = [
    {
      label: 'Chart View',
      icon: 'fa fa-bar-chart',
      command: () => {
        this.funcToggleChartView();
      },
    },
    {
      label: 'Table View',
      icon: 'fa fa-table',
      command: () => {
        this.funcToggleTableView();
        this.toggleTableData('logins_by_vp_created');
      },
    },
  ];

  chartOpportunityToggle: { label: string; icon: string; command }[] = [
    {
      label: 'Chart View',
      icon: 'fa fa-bar-chart',
      command: () => {
        this.funcToggleOpportunityChartView();
      },
    },
    {
      label: 'Table View',
      icon: 'fa fa-table',
      command: () => {
        this.funcToggleOpportunityTableView();
        this.toggleOpportunityTableData('logins_by_vp_created', this.cacheIndex);
      },
    },
  ];
  loadingDataGeo: boolean;
  term_label: string;
  cachePercent: number;
  loadingCustom: boolean;
  geoIndex: number;
  termGeo = [];

  custom_start_end: string;
  custom_end_date: string;
  termsLoaded: boolean;
  selectedIndex: number;
  loadingTime = 25;
  loadingTimeValue = 100;
  waitToLoadOrgDetails = true;
  fullyLoaded = false;
  dataGeoLoaded = false;
  ranges: any = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  quarters: any = {
    q1: [],
    q2: [],
    q3: [],
    q4: [],
  };

  style2022 = true;

  constructor(
    private salesManagerDashboardService: SalesManagerDashboardService,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: SalesManagerDashboardTranslations,
    public aI: AppImplementation,
    private smFunctions: SalesManagerFunctions,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.styleService.style2022.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.style2022 = res;
    });
    this.sm_expanded = JSON.parse(sessionStorage.getItem('sm_expanded')) ? JSON.parse(sessionStorage.getItem('sm_expanded')) : [];
    this.selectedTreeUser = sessionStorage.getItem('sm_selected');

    this.orgView = sessionStorage.getItem('orgView') ? sessionStorage.getItem('orgView') : 'revenue';
    this.maxDate = new Date();
    const s = new Date(this.dteStart);
    this.maxYear = s.getFullYear().toString();

    this.primary_color = sessionStorage.getItem('button_color');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.columnsCreated = [
      { field: 'name', header: 'Name' },
      { field: 'modified', header: 'Last Created' },
    ];

    this.columnsModified = [
      { field: 'name', header: 'Name' },
      { field: 'modified', header: 'Last Edited' },
    ];

    this.columnsLogin = [
      { field: 'name', header: 'Name' },
      { field: 'modified', header: 'Last Login' },
    ];
    this.account_id = sessionStorage.getItem('aid');

    this.getTerms();
    this.funcBuildPageLimit();

    this.isDev = this.commonService.isDev$;
    this.chartColors = this.commonService.getChartColors();
    this.rid = parseInt(sessionStorage.getItem('rid'), 10);
  }

  calcQuick(v) {
    const s = new Date();
    let m = s.getMonth() + 1;
    let y = 0;
    let mm: string;
    if (v > m) {
      y = 1;
      const t = s.getMonth() - v;
      if (t.toString().substring(0, 1) === '-') {
        mm = (12 - (v - m)).toString();
      }
      if (mm.length === 1) {
        mm = '0' + mm;
      }
    } else {
      m = m - v;
      if (m === 0) {
        m = 12;
        y = 1;
      }
      mm = m.toString().length === 2 ? m.toString() : '0' + m;
    }

    const ss = s.getFullYear() - y + '-' + mm + '-' + s.getDate();
    this.dteStart = ss;

    const e = new Date();
    const ee = e.getFullYear() + '-' + ('0' + (e.getMonth() + 1)).slice(-2) + '-' + e.getDate();
    this.dteEnd = ee;
    const out = { start_end: ss, end_date: ee };
    return out;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  setRowLimit(num: number) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  funcToggleChartView() {
    this.chartTableView = false;
  }

  funcToggleTableView() {
    this.chartTableView = true;
  }
  toggleTableData(event) {
    this.tableData = [];
    this.tableDataDefault = this.menuTableOptions[0];
    const fld = event.value && event.value.field ? event.value.field : event;
    const out = [];
    this.cache[this.cacheIndex][fld].forEach((el) => {
      out.push({ val: el.count });
    });
    this.tableData.push(out);
  }

  filterNaughtyList() {
    const res = this.cache[this.cacheIndex].list_chart;
    this.naughtyCreated = res.vps_created_list.result.filter((record) => {
      if (moment(record.modified).isSameOrAfter(this.custom_start_end) && moment(record.modified).isSameOrBefore(this.custom_end_date)) {
        return record;
      }
    });
    this.naughtyCreatedTotal = this.naughtyCreated.length;

    this.naughtyEdited = res.vps_modified_list.result.filter((record) => {
      if (moment(record.modified).isSameOrAfter(this.custom_start_end) && moment(record.modified).isSameOrBefore(this.custom_end_date)) {
        return record;
      }
    });
    this.naughtyEditedTotal = this.naughtyEdited.length;

    this.naughtyLogin = res.login_user_list.result.filter((record) => {
      if (moment(record.modified).isSameOrAfter(this.custom_start_end) && moment(record.modified).isSameOrBefore(this.custom_end_date)) {
        return record;
      }
    });
    this.naughtyLoginTotal = this.naughtyLogin.length;
  }

  getCustomDates(dates) {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }

    const startDate = moment(dates.startDate?.$d);
    const endDate = moment(dates.endDate?.$d);

    this.custom_start_end = startDate.isValid() ? startDate.format('MM/DD/YYYY') : null;
    this.custom_end_date = endDate.isValid() ? endDate.format('MM/DD/YYYY') : null;

    this.btnToggleCustom();
  }

  buildTerms(res: any) {
    res.result.forEach((quarter) => {
      const quarterLogic = {
        label:
          quarter.name +
          ' (' +
          new Intl.DateTimeFormat('en-US', { month: 'short' }).format(moment(quarter.start_date).toDate()) +
          '-' +
          new Intl.DateTimeFormat('en-US', { month: 'short' }).format(moment(quarter.end_date).toDate()) +
          ')',
        command: () => {
          this.setDateLabel(quarter);

          this.btnToggleCustom(0, quarter);
        },
      };
      this.datesToggle.push(quarterLogic);
    });
    const customLogic = {
      label: 'Custom Range',
      command: () => {
        this.dateLabel = 'Custom Range';
      },
    };
    this.datesToggle.push(customLogic);
  }

  setDateLabel(quarter: any) {
    this.dateLabel =
      quarter.name +
      ' (' +
      new Intl.DateTimeFormat('en-US', { month: 'short' }).format(moment(quarter.start_date).toDate()) +
      '-' +
      new Intl.DateTimeFormat('en-US', { month: 'short' }).format(moment(quarter.end_date).toDate()) +
      ')';

    this.custom_start_end = quarter.start_date;
    this.custom_end_date = quarter.end_date;
  }

  getTerms() {
    this.loading = true;
    this.termsLoaded = false;
    this.salesManagerDashboardService
      .getTerms(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.buildTerms(res);
        this.term = [];

        res.result.forEach((element, i) => {
          element.label = element.name;
          element.icon = 'fa fa-spinner fa-spin';
          element.quick = false;
          element.custom = true;
          element.loading = true;
          element.geoLoading = true;
          element.index = i;
          this.term.push(element);
        });

        this.term.push({
          label: 'Custom Date Range',
          start_end: '',
          start_date: '',
          end_date: '',
          quick: true,
          index: res.result.length - 1,
          custom: true,
          geoLoading: false,
          loading: false,
        });

        const indexQuarterToLoad = 0;

        this.setDateLabel(this.term[indexQuarterToLoad]);

        this.termGeo = this.term;
        this.range = false;
        this.termsLoaded = true;
        this.funcLoadFirst(indexQuarterToLoad);
      });
  }

  funcLoadFirst(index) {
    this.loading = true;
    const interval = setInterval(() => {
      this.loadingTime--;

      this.loadingTimeValue = this.loadingTime * 6;
      if (this.loadingTime === 0) {
        this.waitToLoadOrgDetails = false;
        clearInterval(interval);
      }
    }, 1000);

    const elem = this.term[index];
    const payload = {
      start_date: elem.start_date,
      end_date: elem.end_date,
      selected_dates: elem.quick ? 1 : '',
    };
    this.salesManagerDashboardService
      .getData(this.account_id, payload)
      .pipe(take(1))
      .subscribe((res) => {
        elem.loading = false;
        this.cache[elem.index] = res.result;

        this.dteStart = elem.start_end;
        this.dteEnd = elem.end_date;
        this.term_filter = this.term[index];
        this.term_filter_display = this.term[index].name;
        this.toggleCustomeDateRange = false;
        this.funcDashOrg(elem.start_date, elem.end_date, 1, index);
        this.buildChart();
        elem.icon = null;
      });
  }

  funcDashOrg(start, end, quick, index) {
    this.customRange = {
      start_date: start,
      end_date: end,
    };
    if (this.view === 'dashboard') {
      this.selectedIndex = index;
      this.processData(start, end, quick, index);
      if (this.dataGeoLoaded) {
        this.smFunctions.toggleOrgTerm.next(index);
      }
    } else {
      this.term_filter = this.term[index];
      this.term_filter_display = this.term[index].label;
      this.term_label = this.term[index].label;
      this.geoIndex = index;
      this.smFunctions.toggleOrgTerm.next(index);
      this.processData(start, end, quick, index);
    }
  }

  updateDataGeoStatus() {
    this.dataGeoLoaded = true;
  }

  processData(start, end, quick, index) {
    this.cacheIndex = index;
    this.colsTable = [];

    this.dteStart = start;
    this.dteEnd = end;

    this.term_filter = this.term[index];
    this.term_filter_display = this.term[index].label;
    this.term_label = this.term[index].label;
    this.getNaughtyList();

    const payload = {
      start_date: this.dteStart,
      end_date: this.dteEnd,
      selected_dates: quick ? 1 : '',
    };

    if (this.dateLabel === 'Custom Range') {
        payload.start_date = this.custom_start_end;
        payload.end_date = this.custom_end_date;
    }

    if (this.cache && this.cache[index] && this.cache[index].logins_by_month) {
      this.cache[index].logins_by_month.forEach((el) => {
        this.colsTable.push({ field: '', header: el.abbr + ' ' + el.year });
      });
    }

    if (this.term_filter && this.term_filter.custom) {
      this.logins = this.cache[index].total_logins[0];
      this.modified_vps = this.cache[index].total_modified_vps[0];

      this.total_created_vps = this.cache[index].total_created_vps[0];
      this.total_modified_vps = this.cache[index].total_modified_vps[0];

      this.logins_by_month = this.cache[index].logins_by_month;
      this.logins_by_vp_created = this.cache[index].logins_by_vp_created;
      this.logins_by_vp_modified = this.cache[index].logins_by_vp_modified;
    } else {
      this.logins = this.cache[index].total_logins.filter((x) => x.year == this.term_filter.year && x.quarter == this.term_filter.quarter)[0];
      this.modified_vps = this.cache[index].total_modified_vps.filter((x) => x.year == this.term_filter.year && x.quarter == this.term_filter.quarter)[0];

      this.total_created_vps = this.cache[index].total_created_vps.filter((x) => x.year == this.term_filter.year && x.quarter == this.term_filter.quarter)[0];
      this.total_modified_vps = this.cache[index].total_modified_vps.filter((x) => x.year == this.term_filter.year && x.quarter == this.term_filter.quarter)[0];

      this.logins_by_month = this.cache[index].logins_by_month;
      this.logins_by_vp_created = this.cache[index].logins_by_vp_created;
      this.logins_by_vp_modified = this.cache[index].logins_by_vp_modified;
    }

    if (!this.total_created_vps) {
      this.total_created_vps = {
        percent_change: 0,
        user_adoption_share_percentage: 0,
        average_vp_created_per_user: 0,
        count: 0,
      };
    }

    if (!this.modified_vps) {
      this.modified_vps = {
        percent_change: 0,
        average_vp_modified_per_user: 0,
        count: 0,
      };
    }

    if (!this.logins) {
      this.logins = {
        percent_change: '0',
        average_logins_per_user: 0,
        count: 0,
        change: null,
      };
    }

    if (!this.total_modified_vps) {
      this.total_modified_vps = {
        average_vp_modified_per_user: 0,
        count: 0,
      };
    }

    this.salesManagerDashboardService
      .getDataOrgChart(this.account_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (!res.result) {
          this.chartOpportunityData = false;
        } else {
          let dataset = [];
          let labels = [];
          const tooltips = [];
          const colors = [];

          const { chartColors } = this;
          if (res && res.result && res.result.bar_chart) {
            let count = 0;
            res.result.bar_chart.forEach((element) => {
              dataset.push(Number(element.total));
              colors.push(chartColors[count]);
              labels.push(element.valuePropStatus);
              tooltips.push({ label: element.valuePropStatus + ': ' + Number(element.total) });
              count++;
            });
          }
          this.opportunityBarChartLabels = labels;
          this.opportunityBarChartColors = colors;
          this.opportunityBarChartData = dataset;

          dataset = [];
          labels = [];
          if (res && res.result && res.result.pie_chart) {
            res.result.pie_chart.forEach((element) => {
              dataset.push(element.total_percent);
              labels.push(element.valuePropStatus);
            });
          }

          this.opportunityDoughnutChartData = {
            datasets: [
              {
                backgroundColor: chartColors,
                data: dataset,
              },
            ],
            labels: labels,
          };
          this.opportunityDoughnutChartOptions = {
            cutoutPercentage: 70,
            legend: { position: 'right' },

            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const label = data.datasets[0].data[tooltipItem.index] + '%';
                  return label;
                },
              },
            },
          };
        }
        this.loading = false;
      });
    this.processGeo();
    this.cd.detectChanges();
  }

  quarterCalClick() {
    this.toggleCustomeDateRange = !this.toggleCustomeDateRange;
    this.range = false;
    this.quick_filter = this.viewQuick[0];
  }

  processGeo() {
    this.buildChartGeoLogin();
    this.buildChartGeoCreate();
    this.buildChartGeoModified();
  }

  getChartColors() {
    this.chartColorCount++;
    if (this.chartColorCount > this.chartColors.length) {
      this.chartColorCount = 0;
    }
    return this.chartColors[this.chartColorCount];
  }

  btnToggleCustom(state: number = 1, quarter?: any) {
    this.loadingCustom = true;

    this.dteStart = quarter ? this.custom_start_end : this.funcCleanupPChartDate(this.custom_start_end);
    this.dteEnd = quarter ? this.custom_end_date : this.funcCleanupPChartDate(this.custom_end_date);

    const index = quarter ? quarter.index : this.term.length - 1;

    const payload = {
      start_date: this.dteStart,
      end_date: this.dteEnd,
      selected_dates: state === 1 ? 1 : '',
    };

    this.customRange = {
      start_date: this.dteStart,
      end_date: this.dteEnd,
    };

    this.salesManagerDashboardService
      .getData(this.account_id, payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.cache[index] = res.result;
        this.loadingCustom = false;
        this.funcDashOrg(this.dteStart, this.dteEnd, 1, index);
        this.buildChart();
        this.cd.detectChanges();
      });
  }

  funcCleanupPChartDate(dte) {
    const s = new Date(dte);
    const ss = s.getFullYear() + '-' + ('0' + (s.getMonth() + 1)).slice(-2) + '-' + s.getDate();
    return ss;
  }

  funcCountLoaded() {
    let count = 0;
    this.term.forEach((elem) => {
      if (elem.label) {
        if (!elem.loading) {
          count++;
        }
      }
    });
    return count - 1;
  }

  toggleOpportunityTableData(event, index) {
    this.tableData = [];

    const fld = event.value && event.value.field ? event.value.field : event;
    const out = [];
    this.cache[index][fld].forEach((el) => {
      out.push({ val: el.count });
    });
    this.tableData.push(out);
  }

  getNaughtyList() {
    const res = this.cache[this.cacheIndex].list_chart;

    this.naughtyCreated = res.vps_created_list.result;
    this.naughtyCreatedTotal = res.vps_created_list.rows;

    this.naughtyEdited = res.vps_modified_list.result;
    this.naughtyEditedTotal = res.vps_modified_list.rows;

    this.naughtyLogin = res.login_user_list.result;
    this.naughtyLoginTotal = res.login_user_list.rows;
  }

  funcToggleOpportunityTableView() {
    this.opportunityChartTableView = true;
  }

  funcToggleOpportunityChartView() {
    this.opportunityChartTableView = false;
  }

  buildChart() {
    this.chartLoading = true;
    const { chartColors } = this;

    const labels = [];
    const logins = [];
    const created = [];
    const modified = [];
    if (this.cache[this.selectedIndex].logins_by_month) {
      this.cache[this.selectedIndex].logins_by_month.forEach((el) => {
        labels.push(el.abbr + ' ' + el.year);
        logins.push(el.count);
      });

      this.cache[this.selectedIndex].logins_by_vp_created.forEach((el) => {
        created.push(el.count);
      });

      const created_max = Math.max.apply(null, created);

      this.cache[this.selectedIndex].logins_by_vp_modified.forEach((el) => {
        modified.push(el.count);
      });
      const modified_max = Math.max.apply(null, modified);

      this.buildChartCreated();
      this.buildChartModified();
      this.buildChartLogins();

      const canvas = <HTMLCanvasElement>document.getElementById('pBar1');
      if (canvas) {
        const ctx = canvas.getContext('2d');

        this.gradientStroke1 = ctx.createLinearGradient(0, 0, 0, created_max);
        this.gradientStroke1.addColorStop(0, chartColors[0]);
        this.gradientStroke1.addColorStop(1, chartColors[0]);

        this.gradientStroke2 = ctx.createLinearGradient(0, 0, 0, modified_max);
        this.gradientStroke2.addColorStop(0, chartColors[2]);
        this.gradientStroke2.addColorStop(1, chartColors[2]);

        this.chart = {
          datasets: [
            {
              label: 'Value Propositions Created',
              data: created,
              type: 'line',
              order: 2,
              borderColor: this.gradientStroke1,
              pointBorderColor: this.gradientStroke1,
              pointBackgroundColor: this.gradientStroke1,
              pointHoverBackgroundColor: this.gradientStroke1,
              pointHoverBorderColor: this.gradientStroke1,
              pointBorderWidth: 5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 1,
              pointRadius: 1,
              fill: true,
              backgroundColor: this.smFunctions.hexToRgb(chartColors[0]),
              borderWidth: 3,
            },
            {
              label: 'Value Propositions Edited',
              data: modified,
              type: 'line',
              order: 1,
              borderColor: this.gradientStroke2,
              pointBorderColor: this.gradientStroke2,
              pointBackgroundColor: this.gradientStroke2,
              pointHoverBackgroundColor: this.gradientStroke2,
              pointHoverBorderColor: this.gradientStroke2,
              pointBorderWidth: 5,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 1,
              pointRadius: 1,
              fill: false,
              borderWidth: 2,
            },

            {
              label: 'Users Who Have Logged In',
              data: logins,
              type: 'bar',
              order: 3,
              borderColor: chartColors[chartColors.length -1],
              backgroundColor: this.smFunctions.hexToRgb(chartColors[chartColors.length -1]),
              fill: true,
              maxBarThickness: 50,
            },
          ],
          labels: labels,
        };

        this.options = {
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: true,
                },
                ticks: {
                  fontColor: '#AFBBD1',
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontColor: '#AFBBD1',
                },
              },
            ],
          },
          tooltips: {
            mode: 'index',
            enabled: false,

            custom: function (tooltipModel) {
              let tooltipEl = document.getElementById('chartjs-tooltip');

              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<div id="tooltip-inner" style="border-collapse: collapse;	border-radius: 5px;	width: 100%;"></div>';
                document.body.appendChild(tooltipEl);
              }

              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);
                const bodyLinesSorted = bodyLines.sort(function (a, b) {
                  return a === b ? 0 : a < b ? -1 : 1;
                });

                let innerHtml = '<div style="background-color: #DDE5EB; border-radius: 5px 5px 0 0;">';

                titleLines.forEach(function (title) {
                  innerHtml += '<div><div style="padding: 6px 0 4px 12px; font-weight: normal">' + title + '</div></div>';
                });
                innerHtml += '</div><div style="padding: 4px 16px 12px 0;">';

                bodyLinesSorted.forEach((body, i) => {
                  const labelArr = body[0].split(': ');
                  let style = 'background: ' + this._chart.config.options.legend.labels.fontColor[i];
                  style += '; width:7px; height: 7px; margin-top: 4px; margin-left: 5px; border-radius: 50%';
                  const div = '<div style="' + style + '"></div>';
                  innerHtml +=
                    '<div><div style="display: grid; grid-template-columns: 18px 162px 7% 3%; padding: 3px 0px 0px 9px; color: #7E899E;">' +
                    div +
                    labelArr[0] +
                    '<div style="display: flex; justify-content: flex-end; color: #3C4351;">' +
                    labelArr[1] +
                    '</div><div></div></div></div>';
                });
                innerHtml += '</div>';

                const tableRoot = tooltipEl.querySelector('#tooltip-inner');
                tableRoot.innerHTML = innerHtml;
              }

              const position = this._chart.canvas.getBoundingClientRect();

              tooltipEl.style.opacity = '1';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.backgroundColor = '#FFFFFF';
              tooltipEl.style.borderRadius = '5px';
              tooltipEl.style.boxShadow = '0px 0px 20px rgba(0,0,0,0.10),0px 10px 20px rgba(0,0,0,0.05), 0px 20px 20px rgba(0,0,0,0.05),0px 30px 20px rgba(0,0,0,0.05)';
            },
          },

          legend: {
            display: false,
            labels: {
              fontColor: [chartColors[0], chartColors[2], chartColors[chartColors.length -1]],
              fontSize: 18,
            },
          },
          animation: {
            duration: 500,
          },
        };
      }
    }
    this.cacheFirstLoad = false;
  }

  buildChartCreated() {
    const { chartColors } = this;

    const created = [];
    const labels = [];

    const filteredLogins = this.cache[this.selectedIndex].logins_by_month.filter(login =>{
      const loginDate = `${login.year}-${login.month_name}-${this.dteStart.split('-')[2]}`;
      return this.dateCheck(this.dteStart, this.dteEnd, loginDate);
    });
    const modifiedLoginFilter =  this.cache[this.selectedIndex].logins_by_vp_created.filter(login =>{
      const loginDate = `${login.year}-${login.month_name}-${this.dteStart.split('-')[2]}`;
      return this.dateCheck(this.dteStart, this.dteEnd, loginDate);
    });

    filteredLogins.forEach((el) => {
      labels.push(el.abbr + ' ' + el.year);
    });
    modifiedLoginFilter.forEach((el) => {
      created.push(el.count);
    });
    this.total_created_vps.count = created.reduce((acc, cur) => +acc + +cur, 0);

    this.chartCreated = {
      datasets: [
        {
          label: '',
          data: created,
          borderColor: chartColors[2],
          backgroundColor: chartColors[2],
          fill: false,
          maxBarThickness: 8,
        },
      ],
      labels: labels,
    };

    this.optionsCreated = {
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
              beginAtZero: true
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
      },

      legend: {
        display: false,
      },

      animation: {
        duration: 500,
      },
      tooltips: {
        mode: 'index',
        enabled: false,

        custom: function (tooltipModel) {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div id="tooltip-inner" style="border-collapse: collapse;	border-radius: 5px;	width: 100%;"></div>';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const bodyLinesSorted = bodyLines.sort(function (a, b) {
              return a === b ? 0 : a < b ? -1 : 1;
            });

            let innerHtml = '<div style="background-color: #DDE5EB; border-radius: 5px 5px 0 0;">';

            titleLines.forEach(function (title) {
              innerHtml += '<div><div style="padding: 6px 12px 4px 12px; font-weight: normal">' + title + '</div></div>';
            });
            innerHtml += '</div><div style="padding: 4px 16px 12px 0;">';

            bodyLinesSorted.forEach((body, i) => {
              const labelArr = body[0].split(': ');
              let style = 'background: ' + chartColors[2];
              style += '; width:7px; height: 7px; margin-top: 4px; margin-left: 5px; border-radius: 50%';
              const div = '<div style="' + style + '"></div>';
              innerHtml +=
                '<div><div style="display: grid; grid-template-columns: 18px 7% 3%; padding: 3px 0px 0px 9px; color: #7E899E;">' +
                div +
                labelArr[0] +
                '<div></div></div></div>';
            });
            innerHtml += '</div>';

            const tableRoot = tooltipEl.querySelector('#tooltip-inner');
            tableRoot.innerHTML = innerHtml;
          }

          const position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 80 + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.backgroundColor = '#FFFFFF';
          tooltipEl.style.borderRadius = '5px';
          tooltipEl.style.boxShadow = '0px 0px 20px rgba(0,0,0,0.10),0px 10px 20px rgba(0,0,0,0.05), 0px 20px 20px rgba(0,0,0,0.05),0px 30px 20px rgba(0,0,0,0.05)';
        },
      },
    };
  }

  buildChartModified() {
    const { chartColors } = this;
    const created = [];
    const labels = [];
    const filteredLogins = this.cache[this.selectedIndex].logins_by_month.filter(login =>{
      const loginDate = `${login.year}-${login.month_name}-${this.dteStart.split('-')[2]}`;
      return this.dateCheck(this.dteStart, this.dteEnd, loginDate);
    });
    const modifiedLoginFilter =  this.cache[this.selectedIndex].logins_by_vp_modified.filter(login =>{
      const loginDate = `${login.year}-${login.month_name}-${this.dteStart.split('-')[2]}`;
      return this.dateCheck(this.dteStart, this.dteEnd, loginDate);
    });

    filteredLogins.forEach((el) => {
      labels.push(el.abbr + ' ' + el.year);
    });
    modifiedLoginFilter.forEach((el) => {
      created.push(el.count);
    });
    this.total_modified_vps.count = created.reduce((acc, cur) => +acc + +cur, 0);
    this.chartModified = {
      datasets: [
        {
          label: '',
          data: created,
          borderColor: chartColors[2],
          backgroundColor: chartColors[2],
          fill: false,
          maxBarThickness: 8,
        },
      ],
      labels: labels,
    };

    this.optionsModified = {
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
              beginAtZero: true
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
      },

      legend: {
        display: false,
      },

      animation: {
        duration: 500,
      },

      tooltips: {
        mode: 'index',
        enabled: false,

        custom: function (tooltipModel) {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div id="tooltip-inner" style="border-collapse: collapse;	border-radius: 5px;	width: 100%;"></div>';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const bodyLinesSorted = bodyLines.sort(function (a, b) {
              return a === b ? 0 : a < b ? -1 : 1;
            });

            let innerHtml = '<div style="background-color: #DDE5EB; border-radius: 5px 5px 0 0;">';

            titleLines.forEach(function (title) {
              innerHtml += '<div><div style="padding: 6px 12px 4px 12px; font-weight: normal">' + title + '</div></div>';
            });
            innerHtml += '</div><div style="padding: 4px 16px 12px 0;">';

            bodyLinesSorted.forEach((body, i) => {
              const labelArr = body[0].split(': ');
              let style = 'background: ' + chartColors[2];
              style += '; width:7px; height: 7px; margin-top: 4px; margin-left: 5px; border-radius: 50%';
              const div = '<div style="' + style + '"></div>';
              innerHtml +=
                '<div><div style="display: grid; grid-template-columns: 18px 7% 3%; padding: 3px 0px 0px 9px; color: #7E899E;">' +
                div +
                labelArr[0] +
                '<div></div></div></div>';
            });
            innerHtml += '</div>';

            const tableRoot = tooltipEl.querySelector('#tooltip-inner');
            tableRoot.innerHTML = innerHtml;
          }

          const position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 80 + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.backgroundColor = '#FFFFFF';
          tooltipEl.style.borderRadius = '5px';
          tooltipEl.style.boxShadow = '0px 0px 20px rgba(0,0,0,0.10),0px 10px 20px rgba(0,0,0,0.05), 0px 20px 20px rgba(0,0,0,0.05),0px 30px 20px rgba(0,0,0,0.05)';
        },
      },
    };
  }

  buildChartLogins() {
    const { chartColors } = this;

    const created = [];
    const labels = [];

    const filteredLogins = this.cache[this.selectedIndex].logins_by_month.filter(login =>{
      const loginDate = `${login.year}-${login.month_name}-${this.dteStart.split('-')[2]}`;
      return this.dateCheck(this.dteStart, this.dteEnd, loginDate);
    });

    filteredLogins.forEach((el) => {
      labels.push(el.abbr + ' ' + el.year);
      created.push(el.count);
    });
    this.logins.count = created.reduce((acc, cur) => +acc + +cur, 0);
    this.chartLogins = {
      datasets: [
        {
          label: '',
          data: created,
          borderColor: chartColors[2],
          backgroundColor: chartColors[2],
          fill: false,
        },
      ],
      labels: labels,
    };

    this.optionsLogins = {
      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
              beginAtZero: true
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
      },

      legend: {
        display: false,
      },

      animation: {
        duration: 500,
      },
      tooltips: {
        mode: 'index',
        enabled: false,

        custom: function (tooltipModel) {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div id="tooltip-inner" style="border-collapse: collapse;	border-radius: 5px;	width: 100%;"></div>';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const bodyLinesSorted = bodyLines.sort(function (a, b) {
              return a === b ? 0 : a < b ? -1 : 1;
            });

            let innerHtml = '<div style="background-color: #DDE5EB; border-radius: 5px 5px 0 0;">';

            titleLines.forEach(function (title) {
              innerHtml += '<div><div style="padding: 6px 12px 4px 12px; font-weight: normal">' + title + '</div></div>';
            });
            innerHtml += '</div><div style="padding: 4px 16px 12px 0;">';

            bodyLinesSorted.forEach((body, i) => {
              const labelArr = body[0].split(': ');
              let style = 'background: ' + chartColors[2];
              style += '; width:7px; height: 7px; margin-top: 4px; margin-left: 5px; border-radius: 50%';
              const div = '<div style="' + style + '"></div>';
              innerHtml +=
                '<div><div style="display: grid; grid-template-columns: 18px 7% 3%; padding: 3px 0px 0px 9px; color: #7E899E;">' +
                div +
                labelArr[0] +
                '<div></div></div></div>';
            });
            innerHtml += '</div>';

            const tableRoot = tooltipEl.querySelector('#tooltip-inner');
            tableRoot.innerHTML = innerHtml;
          }

          const position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 80 + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.backgroundColor = '#FFFFFF';
          tooltipEl.style.borderRadius = '5px';
          tooltipEl.style.boxShadow = '0px 0px 20px rgba(0,0,0,0.10),0px 10px 20px rgba(0,0,0,0.05), 0px 20px 20px rgba(0,0,0,0.05),0px 30px 20px rgba(0,0,0,0.05)';
        },
      },
    };
  }

  buildChartGeoLogin() {
    const labels = [];
    const data = [];
    const color = [];

    const { chartColors } = this;
    let countColor = 0;
    if (this.cache[this.cacheIndex].direct_report_chart) {
      this.cache[this.cacheIndex].direct_report_chart['login'].forEach((el) => {
        labels.push(el.name);
        data.push(el.percent);
        if (countColor > chartColors.length) {
          countColor = 0;
        }
        color.push(chartColors[countColor]);
        countColor++;
      });

      this.chartGeoLogins = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: color,
          },
        ],
      };

      this.chartGeoLoginOptions = {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 10,
            usePointStyle: true,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const pointPercent = data.datasets[0].data[tooltipItem['index']];
              return pointPercent + '%';
            },
          },
        },
        onClick: () => {
          this.sidebar_title = 'Value Realizer Logins by Level 1 (Geo) Org';
          this.toggleDetails = true;
        },
      };
    }
  }

  buildChartGeoCreate() {
    const labels = [];
    const data = [];
    const color = [];

    const { chartColors } = this;
    let countColor = 0;

    if (this.cache[this.cacheIndex].direct_report_chart) {
      this.cache[this.cacheIndex].direct_report_chart['vp_create'].forEach((el) => {
        labels.push(el.name);
        data.push(el.percent);
        if (countColor > chartColors.length) {
          countColor = 0;
        }
        color.push(chartColors[countColor]);
        countColor++;
      });

      this.chartGeoCreated = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: color,
          },
        ],
      };

      this.chartGeoCreatedOptions = {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 10,
            usePointStyle: true,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const pointPercent = data.datasets[0].data[tooltipItem['index']];
              return pointPercent + '%';
            },
          },
        },
        onClick: () => {
          this.sidebar_title = 'Value Propositions Created by Geo';
          this.toggleDetails = true;
        },
      };
    }
  }

  buildChartGeoModified() {
    const labels = [];
    const data = [];
    const color = [];

    const { chartColors } = this;
    let countColor = 0;
    if (this.cache[this.cacheIndex].direct_report_chart) {
      this.cache[this.cacheIndex].direct_report_chart['vp_modified'].forEach((el) => {
        labels.push(el.name);
        data.push(el.percent);
        if (countColor > chartColors.length) {
          countColor = 0;
        }
        color.push(chartColors[countColor]);
        countColor++;
      });

      this.chartGeoEdited = {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: color,
          },
        ],
      };

      this.chartGeoModifiedOptions = {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontSize: 10,
            usePointStyle: true,
          },
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const pointPercent = data.datasets[0].data[tooltipItem['index']];
              return pointPercent + '%';
            },
          },
        },
        onClick: () => {
          this.sidebar_title = 'Value Propositions Edited by Level (Geo)';
          this.toggleDetails = true;
        },
      };
    }
  }

  getGeos() {
    this.loadingGeo = true;
    this.loadingGeo = false;
    this.buildChartGeoLogin();
    this.buildChartGeoCreate();
    this.buildChartGeoModified();
  }

  toggleView(view) {
    if (!this.fullyLoaded) {
      this.notificationService.info('Please wait until all data is loaded.', false);
      return false;
    }
    this.view = view;
  }

  private dateCheck(from: string, to: string, check: string) {

    let fDate,lDate,cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if((cDate <= lDate && cDate >= fDate)) {
        return true;
    }
    return false;
}
}
