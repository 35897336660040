import { Injectable } from '@angular/core';

@Injectable ()

export class BenefitDetailV1 {

    public config: any = {
        component: 'benefitDetailV1',
        display_name: 'Benefit Detail Version 1',
        description: 'version 1 of benefit detail'
    };

    public trans: any = {
        ch_rename: 'Rename the benefit',
        back: 'Back',
        edit: 'Edit',
        choose: 'Choose',
        original_name: 'Original Name',
        total_benefit: 'Total Benefit',
        description: 'Description',
        features: 'Features',
        case_studies: 'Case Studies',
        note: 'Note',
        save_note: 'Save Note',
        cancel: 'Cancel',
        save_changes: 'Save Changes',
        NoBenefitDetailFound: 'No Benefit Detail Found',
        Year: 'Year',
        save: 'Save',
        applyToAll: 'Apply to All',
        Yes: 'Yes',
        proof_points_for: 'Proof Points for',
        with_this_benefit: 'with this benefit',
        '(name)_experience_an_improvement_of_(amount)_with_this_benefit.':
        '(name) experience an improvement of (amount) with this benefit.',
    };
}
