import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class BenefitPhasingTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'benefitsPhasing',
    display_name: 'Benefits Phasing',
    description: 'Phasing for a benefit',
  };

  public trans = this.typeObjectKeys({
    applyThisBenefit: 'Apply to this Benefit',
    applyBenefitInCategory: 'Apply to All Benefits in Category',
    applyBenefitInModel: 'Apply to All Benefits in Model',
    applyBenefitAll: 'Apply to All Benefits',
    applyBenefitToAnnual: 'Apply to All Annual Benefits',
    applyBenefitToOneTime: 'Apply to All One Time Benefits',
    applyBenefitToMax100: 'Apply to All Max 100 Benefits',
    save: 'Save',
    impact_over_time: 'Your impact over time',
    current_state: 'Current state',
    year: 'Year',
    phasing_updated_success: 'Phasing updated Successfully',
    yes: 'Yes',
    no: 'No',
    max100Warning: 'Changes will NOT be applied to benefits that have a Max100 Accrual Type, are you sure you want to apply changes to other benefits?',
    areYouSure: 'Are you sure?',
    allTypeWarning: 'You are about to apply changes to benefits that have have an accrual type of:',
    annual: 'Annual',
    oneTime: 'One Time',
    max100: 'Max 100',
  });
}
