<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title' *ngIf="selectedBenefit">{{trans.trans.editBenefit.value}}</div>
					<div class='title' *ngIf="!selectedBenefit">{{trans.trans.addBenefit.value}}</div>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<form name="addBenefitsForm" #addBenefitsForm="ngForm" (ngSubmit)="saveBenefits(addBenefitsForm)">
					<p-fieldset id='required' [legend]="trans.trans.required.value">
						<div class='row'>
							<div class="col-md-3 col-sm-6 col-xs-12" style='height: 80px;'>
								<div [ngClass]="{'has-error': (addBenefitsForm.controls.benefitsName?.invalid && addBenefitsForm.controls.benefitsName?.touched)}">
									<label>
										{{ trans.trans.name.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.name'></app-editTranslation>
									</label>
									<input type="text" class="form-control m-t-xxs" id="benefitsName" [placeholder]="trans.trans.name.value" name="benefitsName" [(ngModel)]="benefitsName" required>
								</div>
							</div>

							<div class='col-md-3 col-sm-6 col-xs-12' style='height: 80px;'>
								<div [ngClass]="{'has-error': (addBenefitsForm.controls.impact?.invalid && addBenefitsForm.controls.impact?.touched)}">
									<label>
										{{ trans.trans.Impact.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.Impact'></app-editTranslation>
									</label>
									<select class="form-control m-t-xxs" name="impact" [(ngModel)]="impact" required>
										<option *ngFor="let s of impactList" [ngValue]="s.id">
											{{s.name}}
										</option>
									</select>
								</div>
							</div>

							<div class='col-md-3 col-sm-6 col-xs-12' style='height: 80px;'>
								<div [ngClass]="{'has-error': (addBenefitsForm.controls.annualBenefits?.invalid && addBenefitsForm.controls.annualBenefits?.touched)}">
									<label>
										{{ trans.trans.IsAnnualBenefits.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.IsAnnualBenefits'></app-editTranslation>
									</label>
									<select class="form-control m-t-xxs" name="annualBenefits" [(ngModel)]="annualBenefits" required>
										<option *ngFor="let s of annualBenefitsList" [ngValue]="s.id">
											{{s.name}}
										</option>
									</select>
								</div>
							</div>
						</div>
						<div class='row'>
							<div class='col-md-3 col-sm-6 col-xs-12' style='height: 80px;'>
								<div [ngClass]="{'has-error': (addBenefitsForm.controls.solutionType?.invalid && addBenefitsForm.controls.solutionType?.touched)}">
									<label>
										{{ trans.trans.Solutions.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.Solutions'></app-editTranslation>
									</label>
									<select class="form-control m-t-xxs" name="solutionType" [(ngModel)]="solutionType" #solution (change)="readValueCategories(solutionType); readFunctionalObjectives(solutionType)" required>
										<option *ngFor="let s of solutionsList" [ngValue]="s.account_solution_id">
											{{s.name}}
										</option>
									</select>
								</div>
							</div>

							<div class='col-md-3 col-sm-6 col-xs-12' style='height: 80px;'>
								<div [ngClass]="{'has-error': (addBenefitsForm.controls.valueCategoryBenefits?.invalid && addBenefitsForm.controls.valueCategoryBenefits?.touched)}">
									<label>
										{{ trans.trans.WhichValueCategory.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.WhichValueCategory'></app-editTranslation>
									</label>
									<p-progressBar *ngIf="loadingVC" mode="indeterminate"></p-progressBar>
									<p-dropdown appendTo="body" *ngIf="!loadingVC && valueCategoryBenefitsList.length" [autoDisplayFirst]='false' [required]='true' [virtualScroll]="true" [itemSize]="30" name="valueCategoryBenefits" [options]="valueCategoryBenefitsList" optionLabel='value_category' [(ngModel)]="valueCategoryBenefits" [filter]="valueCategoryBenefitsList.length > 5" class='keywest_dropdown'></p-dropdown>
									<!-- <select class="form-control m-t-xxs" id="valueCategoryBenefits" name="valueCategoryBenefits" [(ngModel)]="valueCategoryBenefits" required>
										<option value=''>{{ trans.trans.select_option.value }}</option>
										<option *ngFor="let s of valueCategoryBenefitsList" [ngValue]="s.value_category_id">
											{{s.value_category}}
										</option>
									</select> -->
								</div>
							</div>
						</div>
						<p-fieldset [legend]="trans.trans.driver.value">
							<div class='row'>
								<div class='col-xs-4'>
									<div [ngClass]="{'has-error': (addBenefitsForm.controls.driverFactor?.invalid && addBenefitsForm.controls.driverFactor?.touched)}">
										<label>
											{{ trans.trans.DriverFactor.value }}
											<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.DriverFactor'></app-editTranslation>
										</label>
										<div class="driverInline">
											<p-dropdown appendTo="body" [autoDisplayFirst]='false' [virtualScroll]="true" [itemSize]="30" name="driverFactor" *ngIf="driverFactorList" [options]="driverFactorList" optionLabel='name' [(ngModel)]="driverFactor" [filter]="true" class='keywest_dropdown'></p-dropdown>
										</div>
										<div class="btnInline">
											<a href="javascript:;" class="driverFactor" (click)="createFactor = !createFactor">
												<span class="icon-plus"></span>
											</a>
										</div>
									</div>
								</div>

								<div class='col-xs-2' *ngIf=" createFactor">
									<div class="form-group clearfix" id="createDriverFactor">
										<label>
											{{ trans.trans.CreateDriverFactor.value }}
											<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.CreateDriverFactor'></app-editTranslation>
										</label>
										<input type="text" name="createDriverFactor" class="form-control m-t-xxs driverInline" id="createDriverFactor" [(ngModel)]="newDriverFactor">
										<span class="icon-check btnInline" *ngIf="!showActionLoader" (click)="createDriverFactor()"></span>
										<span class="btnInline">
											<img class="actionLoader" *ngIf="showActionLoader" src="{{fullImagePath}}" />
										</span>
									</div>
								</div>
								<div class='col-xs-2'>
									<div [ngClass]="{'has-error': (addBenefitsForm.controls.driverBaselineAnswer?.invalid && addBenefitsForm.controls.driverBaselineAnswer?.touched)}">
										<label>
											{{ trans.trans.Answer.value }}
											<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.Answer'></app-editTranslation>
										</label>

										<input type="text" name="driverBaselineAnswer" class="form-control m-t-xxs" id="driverBaselineAnswer" name="driverBaselineAnswer" [(ngModel)]="driverBaselineAnswer" required>
									</div>
								</div>
							</div>
						</p-fieldset>
						<p-fieldset [legend]="trans.trans.improvement.value">
							<div class='row'>
								<div class="col-xs-2" [ngClass]="{'has-error': (addBenefitsForm.controls.probable?.invalid && addBenefitsForm.controls.probable?.touched)}">
									<label>
										{{ trans.trans.improvement_required.value}}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.improvement_required'></app-editTranslation>
									</label>
									<input type="text" name="probable" class="form-control m-t-xxs" id="probable" placeholder="" [(ngModel)]="probable" required>
								</div>
								<div class="col-xs-2" [ngClass]="{'has-error': (addBenefitsForm.controls.unitType?.invalid && addBenefitsForm.controls.unitType?.touched)}">
									<label>
										{{ trans.trans.units.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.units'></app-editTranslation>
									</label>
									<select class="form-control m-t-xxs" name="unitType" [(ngModel)]="unitType" (change)="checkCurrentEffort()" required>
										<option *ngFor="let s of unitTypeList" [ngValue]="s.id">
											{{s.name}}
										</option>
									</select>
								</div>

								<div *ngIf="accountFeatures && showCurrentEffort" class="col-xs-2">
									<label>
										{{ trans.trans.CurrentEffort.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.CurrentEffort'></app-editTranslation>
									</label>
									<input type="text" class="form-control m-t-xxs" id="currentEffort" placeholder="Enter Current Effort" name="currentEffort" [(ngModel)]="currentEffort">
								</div>
							</div>
						</p-fieldset>

						<p-fieldset [legend]="trans.trans.financial.value">
							<div class='row'>
								<div class="col-xs-4" [ngClass]="{'has-error': (addBenefitsForm.controls.financialDriverFactor?.invalid && addBenefitsForm.controls.financialDriverFactor?.touched)}">
									<label>
										{{ trans.trans.FinancialFactorType.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.FinancialFactorType'></app-editTranslation>
									</label>
									<div class="driverInline">
										<p-dropdown appendTo="body" [autoDisplayFirst]='false' [virtualScroll]="true" [itemSize]="30" name="financialDriverFactor" *ngIf="financialDriverFactorList" [options]="financialDriverFactorList" optionLabel='name' [(ngModel)]="financialDriverFactor" [filter]="true" class='keywest_dropdown'></p-dropdown>
									</div>
									<div class="btnInline">
										<a class="financialFactor" href="javascript:;" (click)="createDriverFlag = !createDriverFlag">
											<span class="icon-plus"></span>
										</a>
									</div>
								</div>
								<div class="col-xs-2" id="createFinancialFactor" *ngIf="createDriverFlag">
									<label>
										{{ trans.trans.CreateFinancialFactor.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.CreateFinancialFactor'></app-editTranslation>
									</label>
									<input type="text" name="createFinancialFactor" class="form-control m-t-xxs driverInline" id="createFinancialFactor" [(ngModel)]="FinancialFactor">
									<span class="icon-check btnInline" *ngIf="!showActionLoader" (click)="createFinancialFactor()"></span>
									<span class="btnInline">
										<img class="actionLoader" *ngIf="showActionLoader" src="{{fullImagePath}}" />
									</span>
								</div>
								<div class="col-xs-2" [ngClass]="{'has-error': (addBenefitsForm.controls.financicalAnswer?.invalid && addBenefitsForm.controls.financicalAnswer?.touched)}">
									<label>
										{{ trans.trans.CreateFinancialFactorAnswer.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.CreateFinancialFactorAnswer'></app-editTranslation>
									</label>
									<input type="text" name="financicalAnswer" class="form-control m-t-xxs" id="financicalAnswer" [(ngModel)]="financicalAnswer" required>
								</div>
							</div>
						</p-fieldset>
					</p-fieldset>

					<p-fieldset [toggleable]="true" [collapsed]='true' [legend]="trans.trans.additional.value">
						<div class='row'>
							<div class='col-xs-12'>
								<div>
									<label>
										{{ trans.trans.description.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.description'></app-editTranslation>
									</label>
									<textarea name="description" class="form-control m-t-xxs" id="benefitsDescription" [placeholder]="trans.trans.description.value" [(ngModel)]="benefitsDescription"></textarea>
								</div>
							</div>
						</div>
						<div class='row'>
							<div class='col-xs-12'>
								<label>
									{{ trans.trans.features.value }}
									<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.features'></app-editTranslation>
								</label>
								<textarea name="features" class="form-control m-t-xxs" id="benefitsFeatures" [placeholder]="trans.trans.features.value" [(ngModel)]="benefitsFeatures"></textarea>
							</div>
						</div>
						<div class='row'>

							<div class='col-xs-3'>
								<label>{{trans.trans.would_you_like_to_use_a_different_name_for_tco.value}}</label>
								<input type="text" class="form-control m-t-xxs" id="tcoName" placeholder="Enter Name" name="tcoName" [(ngModel)]="tcoName">
							</div>

							<div class='col-xs-3'>
								<div [ngClass]="{'has-error': (addBenefitsForm.controls.uniqueTypes?.invalid && addBenefitsForm.controls.uniqueTypes?.touched)}">
									<label>
										{{ trans.trans.Uniqueness.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.Uniqueness'></app-editTranslation>
									</label>
									<select class="form-control m-t-xxs" name="uniqueTypes" [(ngModel)]="uniqueTypes">
										<option *ngFor="let s of uniqueList" [ngValue]="s.id">
											{{s.name}}
										</option>
									</select>
								</div>
							</div>

							<div class='col-xs-3'>
								<label for="expense_type_id">{{trans.trans.expense_type.value}}</label>
								<select *ngIf="expense_types && expense_types.length" class="form-control m-t-xxs" name="expense_type_id" [(ngModel)]="expense_type_id">
									<option value="" selected="selected"></option>
									<option *ngFor="let p of expense_types" [ngValue]="p.id">
										{{p.name}}
									</option>
								</select>
							</div>
						</div>
						<div class='row'>

							<div class='col-xs-3'>
								<label>{{trans.trans.functional_objectives.value}}</label>
								<p-dropdown appendTo="body" *ngIf="functionalObjectives && functionalObjectives.length" [autoDisplayFirst]='false' [virtualScroll]="true" [itemSize]="30" name="functionalObjectiveId" [options]="functionalObjectives" optionLabel='name' [(ngModel)]="functionalObjectiveId" [filter]="functionalObjectives.length > 5 ? true : false" class='keywest_dropdown'></p-dropdown>

								<!-- <select class="form-control m-t-xxs" name="functionalObjectiveId" [(ngModel)]="functionalObjectiveId">
									<option value="" selected="selected"></option>
									<option *ngFor="let p of functionalObjectives" [ngValue]="p.id">
										{{p.name}}
									</option>
								</select> -->
							</div>

							<div class='col-xs-3'>
								<label>{{trans.trans.what_type_of_benefit_is_this.value}}</label>
								<select *ngIf="benefitTypeList && benefitTypeList.length" class="form-control m-t-xxs" name="benefitType" [(ngModel)]="benefitType">
									<option value="" selected="selected"></option>
									<option *ngFor="let p of benefitTypeList" [ngValue]="p.id">
										{{p.name}}
									</option>
								</select>
							</div>


						</div>

						<div class="row">
							<div class='col-xs-3' *ngIf="hasFeature46">
								<label>{{trans.trans.is_this_benefit_considered_soft.value}}</label><br>
								<p-dropdown appendTo="body" [options]="hardSoftDropdown" class="keywest_dropdown" [optionLabel]="'label'" appendTo="body" [autoDisplayFirst]='false' name='soft' [(ngModel)]="soft"></p-dropdown>
							</div>

							<div class='col-xs-3' *ngIf="!hasFeature46">
								<label>{{trans.trans.is_this_benefit_considered_soft.value}}</label><br>
								<p-inputSwitch name='soft' [(ngModel)]="soft"></p-inputSwitch> {{soft ? "Yes" : "No"}}
							</div>

							<div class='col-xs-3' *ngIf="!hasFeature46">
								<label>{{trans.trans.should_this_benefit_be_quantified.value}}</label><br>
								<p-inputSwitch name='quantified' [(ngModel)]="quantified"></p-inputSwitch> {{quantified ? "Yes" : "No"}}
							</div>
						</div>

						<div class='row fly_padding'>
							<div class='col-xs-12'>
								<label (click)='painPointToggle = !painPointToggle' class='sidebar_label clickable'>
									<i [ngClass]="painPointToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i> {{trans.trans.pain_points.value}}<span *ngIf="painPointsSelected && painPointsSelected.length"> ({{painPointsSelected.length}} selected)</span>
									<i *ngIf="!(style2022$ | async)" matTooltip='What pain points does this benefit solve?' matTooltipPosition="above" style='margin-left: 5px;' class='fa fa-info-circle'></i>
									<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="What pain points does this benefit solve?" matTooltipPosition="above" style="margin-left: 5px;">
										info
									</span>
								</label>
								<app-dl-list-detail [canEdit]='true' *ngIf="painPointToggle" title='Pain Points' key='account_painpoint_id' [list]='painPoints' [selectedList]='painPointsSelected' (callback)='painPointsSelected = $event'></app-dl-list-detail>
							</div>
						</div>

						<div class='row fly_padding'>
							<div class='col-xs-12'>
								<label (click)='capabilitiesToggle = !capabilitiesToggle' class='sidebar_label clickable'>
									<i [ngClass]="capabilitiesToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i> {{trans.trans.capabilities.value}} <span *ngIf="capabilitiesSelected && capabilitiesSelected.length"> ({{capabilitiesSelected.length}} selected)</span>
									<i *ngIf="!(style2022$ | async)" matTooltip='What capabilities (bullet points) are provided by the solution to achieve this benefit?' matTooltipPosition="above" style='margin-left: 5px;' class='fa fa-info-circle'></i>
									<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="What capabilities (bullet points) are provided by the solution to achieve this benefit?" matTooltipPosition="above" style="margin-left: 5px;">
										info
									</span>
								</label>
								<app-dl-list-detail [canEdit]='true' *ngIf="capabilitiesToggle" title='Capabilities' key='capabilities_id' [list]='capabilities' [selectedList]='capabilitiesSelected' (callback)='capabilitiesSelected = $event'></app-dl-list-detail>
							</div>
						</div>

						<div class='row fly_padding'>
							<div class='col-xs-12'>
								<label (click)='opObjectivesToggle = !opObjectivesToggle' class='sidebar_label clickable'>
									<i [ngClass]="opObjectivesToggle ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"></i> {{trans.trans.operational_objectives.value}}<span *ngIf="operationalObjectivesSelected && operationalObjectivesSelected.length"> ({{operationalObjectivesSelected.length}} selected)</span>
									<i *ngIf="!(style2022$ | async)" matTooltip='What objectives or initiatives is this benefit going to help the customer achieve?' matTooltipPosition="above" style='margin-left: 5px;' class='fa fa-info-circle'></i>
									<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip="What objectives or initiatives is this benefit going to help the customer achieve?" matTooltipPosition="above" style="margin-left: 5px;">
										info
									</span>
								</label>
								<app-dl-list-detail [canEdit]='true' *ngIf="opObjectivesToggle" title='Operational Objectives' key='account_operational_objective_id' [list]='operationalObjectives' [selectedList]='operationalObjectivesSelected' (callback)='operationalObjectivesSelected = $event'></app-dl-list-detail>
							</div>
						</div>
					</p-fieldset>

					<p-fieldset [legend]="trans.trans.impacts.value" [toggleable]='true' [collapsed]='true'>
						<div class='row' *ngIf="showHelp">
							<div class='col-xs-12'>
								<div class='well well-info'>
									{{trans.trans.impacts_desc.value}}
									<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.impacts_desc'></app-editTranslation>
								</div>
							</div>
						</div>
						<table class="table table-responsive custom-table" style='padding-bottom: 0px;'>
							<thead>
								<tr>
									<th>
										{{trans.trans.area.value}}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.area'></app-editTranslation>
									</th>
									<th class="multiselect-end">
										<div class="multiselect-container">
											<p-multiSelect name='areaAdd' [options]="impactAreas" [resetFilterOnHide]="true" [(ngModel)]="impactAreasList" appendTo='body' optionLabel='name' [filter]="true"></p-multiSelect>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr class="grid-row" *ngFor="let impactArea of impactAreasList">
									<td name="impactArea">{{impactArea.name}}</td>
									<td>
										<div class="clickable btn btn-danger btn-sm pull-right" (click)="impactAreaClose(impactArea)"><i class='fa fa-times'></i></div>
									</td>
								</tr>
								<tr class="grid-row" *ngIf="!impactAreasList.length">
									<td colspan='2'>
										{{ trans.trans.NoAreasSelected.value }}
										<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.add_benefit.NoAreasSelected'></app-editTranslation>
									</td>
								</tr>
							</tbody>
						</table>
					</p-fieldset>
					<div class='row'>
						<div class='col-xs-12'>
							<img class="actionLoader" *ngIf="showSaveLoader" [src]="fullImagePath" />
							<button type="submit" *ngIf="!showSaveLoader" class="btn btn_customizable" (click)="saveBenefits(addBenefitsForm)" [disabled]="addBenefitsForm.invalid">{{ trans.trans.save.value }}</button>
							<app-are-you-sure matTooltipPosition="above" message='Are you sure?' class="btn btn_customizable" icon='' text='Cancel' (callbackYes)='funcCancelBenefit()'></app-are-you-sure>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>