import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LogComponent } from './log.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { KeyFiguresModule } from '../key_figures/key_figures.module';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { DefaultTranslations } from './log.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { MatTooltipModule } from '@angular/material/tooltip';


export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
	imports: [
    MatTooltipModule,
		TableChartModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		SidebarModule,
		ApplicationPipesModule,
		KeyFiguresModule,		
	],
	declarations: [
		LogComponent
	],
	providers: [DefaultTranslations, TranslationsV2Service],
	exports: [LogComponent]
})
export class LogModule { }
