import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-no-records',
  templateUrl: './no-records.component.html',
  styleUrls: ['./no-records.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoRecordsComponent {
  @Input() width = 'auto';
  @Input() records = false;
}
