import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AddCompanyCaseStudyComponent } from './add-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}


const routes: Routes = [
	{
		path: 'company/add',
		component: AddCompanyCaseStudyComponent
	}
];
@NgModule({
	imports: [
		RouterModule.forChild(routes),
		NgbModule,
		CommonModule,
    MatTooltipModule,
		FormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	],
	declarations: [
		AddCompanyCaseStudyComponent
	],
	providers: [],
	entryComponents: [AddCompanyCaseStudyComponent],
	exports: [AddCompanyCaseStudyComponent, RouterModule]
})
export class AddCompanyCaseStudyModule { }
