import { Component, ElementRef, Input, forwardRef, ChangeDetectorRef, ViewChild, ViewEncapsulation, OnInit } from '@angular/core';

import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { InputSwitch } from 'primeng/inputswitch';
@Component({
	selector: 'dl-inputswitch',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DLInputSwitchComponent)
		}
	]
})

export class DLInputSwitchComponent extends InputSwitch implements OnInit {
	@Input() name: any;
	@Input() inputId: any;
	@Input() innerValue: any;
	@Input() ngModel: any;
	@Input() ngClass: any;
	@Input() group: any;
	@Input() style: any;
	@Input() container: any;
	@Input() onContainer: any;
	@Input() offContainer: any;
	@Input() onLabelChild: any;
	@Input() offLabelChild: any;

	@Input() offColor: string = "red";
	@Input() onColor: string = "green";
	@Input() offLabel: string = "False";
	@Input() onLabel: string = "True";
	val: string = '';
	switch_container: ElementRef;
	initialized: any;
	@Input() c: FormControl = new FormControl();
	@ViewChild('input') inputRef: ElementRef;
	@Input() checked: boolean;
	errors: Array<any> = ['This field is required'];

	propagateChange: any = () => { };

	constructor(cd: ChangeDetectorRef) {
		super(cd);
	}

	ngOnInit() {
		let elem = document.getElementById("customcolors");
		if (elem) { elem.remove(); }

		const style = document.createElement('style');
		style.type = 'text/css';
		style.id = "customcolors";

		let containerInterior;
		if (this.offColor) {
			containerInterior = '.p-inputswitch .p-inputswitch-slider { color: #fff; background-color: ' + this.offColor + ' !important; }';
			style.appendChild(document.createTextNode(containerInterior));

			containerInterior = '.p-inputswitch.p-inputswitch-focus .p-inputswitch-slider { color: #fff; background-color: ' + this.offColor + ' !important; }';
			style.appendChild(document.createTextNode(containerInterior));

			containerInterior = '.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider { color: #fff; background-color: ' + this.offColor + ' !important; }';
			style.appendChild(document.createTextNode(containerInterior));
		}

		if (this.onColor) {
			containerInterior = '.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider { color: #fff; background-color: ' + this.onColor + ' !important; }';
			style.appendChild(document.createTextNode(containerInterior));

			containerInterior = '.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider { color: #fff; background-color: ' + this.onColor + ' !important; }';
			style.appendChild(document.createTextNode(containerInterior));

			containerInterior = '.p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider { color: #fff; background-color: ' + this.onColor + ' !important; }';
			style.appendChild(document.createTextNode(containerInterior));
		}

		const head = document.getElementsByTagName('head')[0];
		head.appendChild(style);
	}

	get value(): any {
		return this.innerValue;
	};

	@Input('value')
	set value(v: any) {
		this.checked = v;
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.propagateChange();

		}
	}

	writeValue(value: any) {
		this.innerValue = value;
		this.propagateChange();
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(_fn: any) {
	}
}