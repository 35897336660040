<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-12'>
					<div *ngIf="!feature62" class='title'>{{trans.trans.roi_key.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.roi_key" (callback)="getTranslations()"></app-editTranslation>
					</div>
					<div *ngIf="feature62" class='title'>Key Figures
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.keyfigures_key" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class='row' *ngIf="!loading">
		<div [class]="cols" *ngIf="valueProp && dashboard == 'v2'">
			<app-value-prop-dashboard-key-figures *ngIf="valueProp" [showTranslate]='showTranslate' [dashboard]='dashboard' [showHelp]='showHelp' [contextualHelp]='contextualHelp' [valueProp]='valueProp'></app-value-prop-dashboard-key-figures>
		</div>

		<div *ngIf="chartVC" [class]="cols" style='padding-left: 30px; padding-right: 30px;'>
			<div class='row' style='margin-bottom: 10px;'>
				<div class='col-xs-12'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.potential_total_benefit" (callback)="getTranslations()"></app-editTranslation><span class='total'>{{trans.trans.potential_total_benefit.value}}</span>&nbsp; <span class='actual_total'>+ {{benefitTotal}}</span>
				</div>
			</div>
			<app-doughnut5-chart [valueProp]='valueProp' [legend]='chartVCLegend' [data]='chartVC'></app-doughnut5-chart>
		</div>
		<div *ngIf="chartROI" [class]="cols" style='padding-left: 30px; padding-right: 30px;'>
			<app-bar-line-chart [axis2]='true' height='300px' [data]='chartROI'></app-bar-line-chart>
		</div>
	</div>
</div>