import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FunctionalObjectivesService } from '@data/services/func_objectives/func_objectives.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PainPoint } from '@data/services/valueposition/models/painpoint.interface';
import { SelectedElement } from '@data/services/valueposition/models/selected-element.interface';
import { StyleService } from 'app/style.service';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelV2FuncObjectiveTranslations } from './func.translation';

@Component({
  selector: 'app-value-map-func-objectives',
  templateUrl: './func.component.html',
})
export class ValueMapFuncObjectivesComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;

  showAdd = false;
  painPoints: PainPoint[] = [];
  account_id: string;
  menu: MenuItem[] = [];
  strSearch = '';
  loading = true;

  @ViewChild('formFuncObjectives') formFuncObjectives: NgForm;
  cols: Array<{field: string; header: string}> = [{ field: 'name', header: 'Functional Objective' }];
  sidebar_title = 'Add Functional Objective';
  mode = 'add';
  toggleAddEditFuncObjective = false;
  ngUnsubscribe$ = new Subject();

  selectedElem: PainPoint = {
    name: '',
    description: '',
    id: '',
  };

  showTranslate = false;
  style2022$: Observable<boolean>;

  constructor(
    private painPoint: FunctionalObjectivesService,
    private commonService: CommonService,
    public trans: ModelV2FuncObjectiveTranslations,
    private translationService: TranslationsV2Service,
    private solutionService: SolutionService,
    private styleService: StyleService
  ) { }

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.style2022$ = this.styleService.style2022;
    this.funcGet();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  closeAddEditFuncObjective(): void {
    this.toggleAddEditFuncObjective = false;
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  openAddEditFuncObjective(): void {
    // tslint:disable-next-line: forin
    for (const k in this.selectedElem) {
      this.selectedElem[k] = null;
    }
    this.mode = 'add';
    this.toggleAddEditFuncObjective = true;
  }

  funcToggleView(elem: PainPoint): void {
    this.toggleAddEditFuncObjective = true;
    this.selectedElem = Object.assign({}, elem);
  }

  funcToggleEdit(elem: PainPoint): void {
    this.mode = 'edit';

    this.selectedElem = Object.assign({}, elem);

    this.toggleAddEditFuncObjective = true;
  }

  funcGet(): void {
    this.loading = true;
    this.painPoint
      .getFunctionalObjectives(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        if (res.result) {
          this.painPoints = res && res.result && res.result.length ? res.result : [];
          this.loading = false;
          this.solutionService.refreshModelsTotals.next();
        }
      });
  }

  funcDelete(elem: PainPoint): void {
    this.painPoint
      .deleteFunctionalObjectives(this.account_id, elem.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.funcGet();
      });
  }
}
