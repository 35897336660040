import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';

import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { GoalFormModule } from '@shared_components/goal-form/goal-form.module';

import { TrackerGoalsComponent } from './tracker-goals.component';
import { TrackerGoalsTranslation } from './tracker-goals.translation';
import { TrackerGoalChartTranslation } from './tracker-goal-chart/tracker-goal-chart.translation';
import { TrackerGoalChartComponent } from './tracker-goal-chart/tracker-goal-chart.component';
import {ChartModule} from 'primeng/chart';

@NgModule({
	declarations: [
		TrackerGoalsComponent,
        TrackerGoalChartComponent,
	],
    imports: [
        CommonModule,
        EditTranslationsModule,
        ProgressBarModule,
        FormsModule,
        SidebarModule,
        TableModule,
        EllipsisMenuModule,
        AreYouSureModule,
        GoalFormModule,
        ChartModule,
    ],
	providers: [
		TranslationsV2Service,
		TrackerGoalsTranslation,
        TrackerGoalChartTranslation,
	],
	exports: [TrackerGoalsComponent]
})
export class TrackerGoalsModule { }
