<div class="row m-b-md">
  <div class="col-md-6 mr-auto breadcrumb-container">
    <app-breadcrumbs *ngIf="breadcrumbKeyValues" [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
  <div class="pull-right mr-5">
    <app-help-tooltip-value-realized [stepIndex]="3"></app-help-tooltip-value-realized>
  </div>
</div>
<div class="models_panel">
  <div *ngIf="!companyDetails; else content">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
  <ng-template #content>
    <div class="models_page_header_container_small">
      <div class="keywest_header">
        <div class="row">
          <div class="col-xs-6">
            <div class="clickable title" (click)="goBack()">
              <i class="fa fa-chevron-left"></i> {{ trans.trans.valueTrackersFor.value }} {{ companyDetails?.company_name }}
              <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTrackersTooltip.value"> info </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row marg-v">
      <div class="col-xs-12 col-md-3">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input
            [(ngModel)]="querySearch"
            (keyup)="tableTrackersList.filter($any($event).target.value, 'name', 'contains')"
            type="text"
            [placeholder]="trans.trans.search.value"
            class="form-control"
          />
          <span *ngIf="querySearch !== ''" (click)="querySearch = ''; tableTrackersList.filter('', 'name', 'contains')" class="input-group-addon clickable"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <p-selectButton
          [options]="sharedWith"
          [(ngModel)]="creatorFilter"
          (onChange)="getCompanyDetails(companyDetails.id, $event.value)"
          styleClass="keywest_select_button"
        ></p-selectButton>
      </div>
      <div class="col-xs-12 col-md-5" *ngIf="showAddButton">
        <button
          (click)="selectCompany()"
          class="btn btn_customizable pull-right"
          [matTooltip]="trans.trans.add.value"
          matTooltipPosition="below"
          container="body"
          placement="bottom"
        >
          <i class="fa fa-plus"></i>{{ trans.trans.add.value }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <p-table
          #tableTrackersList
          [value]="companyTrackers"
          [(selection)]="companyTrackersSelected"
          [columns]="columns"
          [paginator]="true"
          [rows]="10"
          styleClass="keywest_table"
          tableStyleClass="keywest_table"
          sortField="last_measurement_date"
          [sortOrder]="-1"
          (onRowSelect)="trackerSelected($event.data)"
          (onRowUnselect)="trackerUnselected($event.data)"
          (onHeaderCheckboxToggle)="tableCheckboxToggle()"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th>
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th *ngFor="let col of columns; index as i" [pSortableColumn]="col.sortName" [class.centered]="i !== 0">
                {{ trans.trans[col.field]?.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <p-sortIcon *ngIf="col.field !== 'percentage'" [field]="col.sortName"></p-sortIcon>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tracker>
            <tr>
              <td>
                <p-tableCheckbox [value]="tracker"></p-tableCheckbox>
              </td>
              <td>
                <span class="link" (click)="navToDetails(tracker)" class="clickable">{{ tracker.name }}</span>
              </td>
              <td class="centered">
                <span>{{ tracker.first_name }} {{ tracker.last_name }}</span>
              </td>
              <td class="centered">
                <span>{{ tracker.measurement }}</span>
              </td>
              <td class="centered">
                <span>${{ tracker.expected_to_date_fr }}</span>
              </td>
              <td class="centered">
                <span>${{ tracker.realized_to_date_fr }}</span>
              </td>
              <td class="centered">
                <span [class]="tracker.percentage > 0 ? 'achieved' : tracker.percentage < 0 ? 'loss' : ''">
                  <i
                    class="fa"
                    [class]="
                      tracker.percentage > 0 ? 'fa-arrow-up' : tracker.percentage < 0 ? 'fa-arrow-down' : 'fa-minus'
                    "
                  ></i>
                  {{ tracker.percentage }} %</span
                >
              </td>
              <td class="number-surveys centered">
                <span>{{ tracker.survey_count }}</span>
              </td>
              <td class="centered">
                <span>{{ tracker.custom_value_realized_date === '0000-00-00 00:00:00' ? '' : (tracker.custom_value_realized_date | safariDate | date: 'mediumDate') }}</span>
              </td>
              <td class="centered">
                <span>{{ tracker.last_measurement_date | safariDate | date: 'mediumDate' }}</span>
              </td>
              <td>
                <app-ellipsis-menu *ngIf="+tracker.share_role_type_id !== 1 || +tracker.share_role_type_id === 4">
                  <ul class="list-group">
                    <li class="list-group-item" (click)="triggerEdit(tracker)">
                      <span style="font-size: 13px" class="material-icons-outlined"> edit </span>
                      {{ trans.trans.edit.value | titlecase }}
                    </li>
                    <li
                      *ngIf="(+tracker.share_role_type_id === 5 || +tracker.share_role_type_id === 5) && tracker | functionPipe: checkDeletePermission:{ priv1, priv12 }"
                      class="list-group-item"
                    >
                      <app-are-you-sure
                        (callbackYes)="deleteTracker(tracker)"
                        [text]="trans.trans.delete.value | titlecase"
                        icon="fa fa-trash"
                        [message]="trans.trans.areYouSure.value"
                      ></app-are-you-sure>
                    </li>
                  </ul>
                </app-ellipsis-menu>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </ng-template>
</div>

<p-sidebar *ngIf="addNewSidebar" [(visible)]="addNewSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="onSidebarClose()">
  <app-company-search [companyId]="companyDetails.id.toString()" (companySelected)="defineTracker($event)"></app-company-search>
</p-sidebar>

<p-sidebar [visible]="!loading && (companyTrackersToInclude.length > 0 || companyTrackersToExclude.length > 0)" styleClass="p-sidebar-actionbar" [dismissible]="false" [showCloseIcon]="false" position="bottom" [modal]="false">
  <div class="action-bar">
    <span>{{ companyTrackersToInclude.length }} {{ trans.trans.includeTrackers.value }}</span>
    <span>{{ companyTrackersToExclude.length }} {{ trans.trans.excludeTrackers.value }}</span>
    <div class="actions">
      <div class="action-item primary" (click)="saveSelection()">
        <i class="material-icons-outlined" style="font-size: 13px"> save </i>
        <span>{{ trans.trans.save.value }}</span>
      </div>
      <div class="action-item" (click)="restartSelection()">
        <i class="material-icons-outlined" style="font-size: 13px"> restart_alt </i>
        <span>{{ trans.trans.reset.value }}</span>
      </div>
    </div>
  </div>
</p-sidebar>
