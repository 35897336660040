import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { CXOService } from '@data/services/cxo/cxo.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-sn-cxo',
	templateUrl: './cxo.component.html'
})
export class SNCxOComponent implements OnInit {
	rtOption: number;

	steps = { step1: false, step2: false };
	activeStep = 0;
	vpid = "";
	ngUnsubscribe = new Subject();
	valueProp: ValueProp;
	assetUrl = "";
	assetGenerator = "";
	step = 1;

	constructor(
		private commonService: CommonService,
		private router: Router,
		private valuepropService: ValuepropService,
		private CXOService: CXOService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.assetUrl = this.commonService.getAssetUrl();
		this.assetGenerator = sessionStorage.getItem('asset_gen_file');
	}

	optionsNavigate(): void {
		if (this.rtOption == 0) {
			let rte: string;
			let role5 = this.commonService.checkRoleID("5");
			if (role5) {
				this.router.navigate(['addValuePosition'])	
			} else {
				rte = this.commonService.getCreateVPRoute('cxo');
			}
			this.router.navigate([rte])
		} else {
			this.vpid = null;
			this.valueProp = null;
			this.activeStep = 1;
		}
	}

	funcGotoVPID(event: { valuePropId: string }): void {
		this.vpid = event.valuePropId;
		this.activeStep = 2;

		this.valuepropService.getValuePropDetail(this.vpid).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.step = 2;
				this.valueProp = response.result.value_prop;
			}
		});
	}

	downloadAsset(): void {
		const userId = sessionStorage.getItem('uid');
		const token = sessionStorage.getItem('vcu');
		this.CXOService.downloadAsset(this.valueProp.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response && response !== null) {
				const downloadUrl = this.assetUrl + 'assets/' + this.assetGenerator
					+ '?account_asset_template_id=' + response.result.id + '&user_id=' + userId + '&token=' + token + '&value_prop_id=' + this.valueProp.id;

				window.location.assign(downloadUrl);
			} else {
				this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
			}
		});
	}

	continue(): void {
		this.router.navigate([this.commonService.getCreateVPRoute("valueprop")]);
	}
}