<div [ngSwitch]="tableType">
  <!-- INCONSISTENT_VALUES -->
  <p-table *ngSwitchCase="tableTypes.INCONSISTENT_VALUES" [value]="factors?.factor_mismatch" responsiveLayout="scroll" styleClass="keywest_table" tableStyleClass="keywest_table">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="acknowledged" class="acknowledged-col">
          {{ t?.acknowledged }}
          <p-sortIcon field="acknowledged"></p-sortIcon>
        </th>
        <th width="75%">{{ t.benefitName }}</th>
        <th>{{ t.value }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-factor>
      <tr>
        <td class="acknowledged-col">
          <p-checkbox [binary]="true" [(ngModel)]="factor.acknowledged" (onChange)="onAcknowledge(factor, 'factor_mismatch')"></p-checkbox>
        </td>
        <td class="clickable" (click)="goToFactor(factor)">{{ factor.name }}</td>
        <td>
          <input #input type="number" class="form-control" placeholder="{{ t.enterValue }}" (change)="onValueChange($event, factor, tableTypes.INCONSISTENT_VALUES)" />
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <span class="indented">{{ t.effectedBenefits }}</span>
        </td>
        <td></td>
      </tr>
      <tr *ngFor="let benefit of factor.effected_benefits">
        <td></td>

        <td class="clickable" (click)="goToBenefit(benefit)">
          <span class="indented">{{ benefit.name }}</span>
        </td>
        <td>
          <input type="text" class="form-control" placeholder="{{ t.enterValue }}" disabled [value]="benefit.value" />
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- DEFUALT_VALUE_MISMATCH_SCRATCHPAD -->
  <p-table
    *ngSwitchCase="tableTypes.DEFUALT_VALUE_MISMATCH_SCRATCHPAD"
    [value]="factors?.factor_mismatch"
    responsiveLayout="scroll"
    styleClass="keywest_table"
    tableStyleClass="keywest_table"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="acknowledged" class="acknowledged-col">
          {{ t?.acknowledged }}
          <p-sortIcon field="acknowledged"></p-sortIcon>
        </th>
        <th>{{ t.benefitName }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-benefit>
      <tr>
        <td class="acknowledged-col">
          <p-checkbox [binary]="true" [(ngModel)]="benefit.acknowledged" (onChange)="onAcknowledge(benefit, 'factor_mismatch')"></p-checkbox>
        </td>
        <td class="clickable">{{ benefit }}</td>
      </tr>
    </ng-template>
  </p-table>
  <!-- BROKEN_SCRATCHPADS -->
  <p-table *ngSwitchCase="tableTypes.BROKEN_SCRATCHPADS" [value]="factors?.factor_mismatch" responsiveLayout="scroll" styleClass="keywest_table" tableStyleClass="keywest_table">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="acknowledged" class="acknowledged-col">
          {{ t?.acknowledged }}
          <p-sortIcon field="acknowledged"></p-sortIcon>
        </th>
        <th>{{ t.benefitName }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-benefit>
      <tr>
        <td class="acknowledged-col">
          <p-checkbox [binary]="true" [(ngModel)]="benefit.acknowledged" (onChange)="onAcknowledge(benefit, 'factor_mismatch')"></p-checkbox>
        </td>
        <td class="clickable">{{ benefit }}</td>
      </tr>
    </ng-template>
  </p-table>
  <!-- VALUE_OF_0 -->
  <p-table *ngSwitchCase="tableTypes.VALUE_OF_0" [value]="factors?.zero_factors" responsiveLayout="scroll" styleClass="keywest_table" tableStyleClass="keywest_table">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="acknowledged" class="acknowledged-col">
          {{ t?.acknowledged }}
          <p-sortIcon field="acknowledged"></p-sortIcon>
        </th>
        <th width="75%">{{ t.benefitName }}</th>
        <th>{{ t.value }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-factor>
      <tr>
        <td class="acknowledged-col">
          <p-checkbox [binary]="true" [(ngModel)]="factor.acknowledged" (onChange)="onAcknowledge(factor, 'zero_factors')"></p-checkbox>
        </td>
        <td class="clickable" (click)="goToFactor(factor)">{{ factor.name }}</td>
        <td>
          <input #input type="number" class="form-control" placeholder="{{ t.enterValue }}" (change)="onValueChange($event, factor, tableTypes.VALUE_OF_0)" />
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- NO_DEFAULT_VALUE -->
  <p-table *ngSwitchCase="tableTypes.NO_DEFAULT_VALUE" [value]="factors?.no_default_scalers" responsiveLayout="scroll" styleClass="keywest_table" tableStyleClass="keywest_table">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="acknowledged" class="acknowledged-col">
          {{ t?.acknowledged }}
          <p-sortIcon field="acknowledged"></p-sortIcon>
        </th>
        <th width="75%">{{ t.benefitName }}</th>
        <th>{{ t.value }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-factor>
      <tr>
        <td class="acknowledged-col">
          <p-checkbox [binary]="true" [(ngModel)]="factor.acknowledged" (onChange)="onAcknowledge(factor, 'no_default_scalers')"></p-checkbox>
        </td>
        <td class="clickable" (click)="goToFactor(factor)">{{ factor.name }}</td>
        <td>
          <input #input type="number" class="form-control" placeholder="{{ t.enterValue }}" (change)="onValueChange($event, factor, tableTypes.NO_DEFAULT_VALUE)" />
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
