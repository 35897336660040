<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>{{trans.trans.scorecard.value}}
						<i *ngIf="!(style2022$ | async)" [matTooltip]='trans.trans.ch_scorecard.value' class='fa fa-info-circle'></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_scorecard.value">
							info
						</span>
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.scorecard" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
			</div>
		</div>

		<div class='row'>
			<div class="col-sm-4">
				<div class="score-card-list">
					<div class="score-label">{{ trans.trans.Status.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Status" (callback)="getTranslations()"></app-editTranslation>
					</div>
					<div class="score" [ngClass]="[checkStatus(scoreCard.value_prop_status) ? 'green' : 'red']">
						<i class="fa" [ngClass]="[checkStatus(scoreCard.value_prop_status) ? 'fa-check' : 'fa-times']"></i>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="score-card-list">
					<div class="score-label">{{ trans.trans.PhasingActivated.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.PhasingActivated" (callback)="getTranslations()"></app-editTranslation>
					</div>
					<div class="score" [ngClass]="[scoreCard.phasing_activated ? 'green' : 'red']">
						<i class="fa" [ngClass]="[scoreCard.phasing_activated ? 'fa-check' : 'fa-times']"></i>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="score-card-list">
					<div class="score-label">{{ trans.trans.DriverComplete.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.DriverComplete" (callback)="getTranslations()"></app-editTranslation>
					</div>
					<div class="score" [ngClass]="[(scoreCard.driver_override >= 80) ? 'green' : (scoreCard.driver_override >= 60) ? 'orange' : 'red']">
						<i class="fa" [ngClass]="[(scoreCard.driver_override >= 80) ? 'fa-check' : (scoreCard.driver_override >= 60) ? 'fa-minus' : 'fa-times']"></i>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="score-card-list">
					<div class="score-label">{{ trans.trans.FinancialComplete.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.FinancialComplete" (callback)="getTranslations()"></app-editTranslation>
					</div>
					<div class="score" [ngClass]="[(scoreCard.financial_override >= 80) ? 'green' : (scoreCard.financial_override >= 60) ? 'orange' : 'red']">
						<i class="fa" [ngClass]="[(scoreCard.financial_override >= 80) ? 'fa-check' : (scoreCard.financial_override >= 60) ? 'fa-minus' : 'fa-times']"></i>
					</div>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="score-card-list">
					<div class="score-label">{{ trans.trans.ImprovementComplete.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ImprovementComplete" (callback)="getTranslations()"></app-editTranslation>
					</div>
					<div class="score" [ngClass]="[(scoreCard.impact_override >= 80) ? 'green' : (scoreCard.impact_override >= 60) ? 'orange' : 'red']">
						<i class="fa" [ngClass]="[(scoreCard.impact_override >= 80) ? 'fa-check' : (scoreCard.impact_override >= 60) ? 'fa-minus' : 'fa-times']"></i>
					</div>
				</div>
			</div>
			<ng-container *ngFor="let scorecard of scoreCard.threshold_types; let i = index">
				<div class="col-sm-4">
					<div class="score-card-list">
						<div class="score-label">{{scorecard.name}}</div>
						<div class="score" [ngClass]="[(scorecard.status_id == 4) ? 'green' : (scorecard.status_id == 3) ? 'orange' :  (scorecard.status_id == 2) ? 'red' : '']">
							<i *ngIf="scorecard.status_id != 1" class="fa" [ngClass]="[(scorecard.status_id == 4) ? 'fa-check' : (scorecard.status_id == 3) ? 'fa-minus' :  (scorecard.status_id == 2) ? 'fa-times' : '']"></i>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>