<div class="row" *ngIf="caseStudy">
	<div class='col-xs-12'>
		<div (click)="funcBack(); getCaseStudies()" class='clickable a-link-color'>
			<i class="fa fa-chevron-left"></i> {{trans.trans.backToCaseStudiesList.value}}
		</div>
		<app-casestudy-detail2 [canEdit]="can_edit" [caseStudyId]="caseStudyId" [type]="'solution'" [myCaseStudy]="caseStudy"></app-casestudy-detail2>
	</div>
</div>

<div *ngIf="!caseStudy">
	<div class='keywest_header' *ngIf="!caseStudy">
		<div class='row'>
			<div class='col-xs-6'>
				<div class='title'> {{trans.trans.caseStudies.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="'casSudies'" [isTranslationV2]="true" [transObj]="trans.trans.caseStudies" (callback)="getTranslations()"></app-editTranslation>
				</div>
			</div>
			<div class='col-xs-6' *ngIf="can_edit">
				<button class="btn btn_customizable pull-right" (click)="funcShowAddCaseStudies()" matTooltip="Add New" matTooltipPosition="above">
					<i class="fa fa-plus"></i> {{trans.trans.addNew.value}}</button>
			</div>
		</div>
	</div>


	<div class='row marg-v'>
		<div class='col-xs-4'>
			<div class="input-group keywest_search">
				<span class="input-group-addon"><i class='fa fa-search'></i></span>
				<input [(ngModel)]="caseStudiesSearch" (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control' [ngModelOptions]="{standalone: true}">
				<span *ngIf="caseStudiesSearch !== ''" (click)="caseStudiesSearch = ''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
			</div>
		</div>
	</div>

	<div class='row'>
		<div class='col-xs-12'>
			<p-table #tableSolutionList [value]="caseStudiesList" [columns]="cols" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns" [pSortableColumn]="col.field">
							{{trans.trans[col.field].value}}
							<app-editTranslation *ngIf="showTranslate" [component]="'casSudies'" [isTranslationV2]="true" [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
							<p-sortIcon [field]="col.field"></p-sortIcon>
						</th>
						<th></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-caseStudy>
					<tr>
						<td>
							<a (click)="navigateCaseStudy(caseStudy)" class='clickable'>{{caseStudy.name}}</a>
						</td>
						<td>{{caseStudy.created}}</td>
						<td>{{caseStudy.testimonials_total}}</td>
						<td>{{caseStudy.sections_total}}</td>
						<td>{{caseStudy.benefits_total}}</td>
						<td *ngIf="can_edit">
							<span class="keywest_icon_group pull-right">
								<span (click)="funcEditCaseStudy(caseStudy)" [matTooltip]="trans.trans.edit.value" matTooltipPosition="above">
									<i class="fa fa-pencil"></i>
								</span>
								<span (click)="downloadAsset(caseStudy)" [matTooltip]="trans.trans.download.value" matTooltipPosition="above">
									<i class="fa fa-download"></i>
								</span>
								<span>
									<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteCaseStudy(caseStudy.id)'>
									</app-are-you-sure>
								</span>
							</span>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>

<p-menu #rowMenu appendTo='body' [popup]="true" [model]="dropdown" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<p-sidebar [(visible)]='toggleAddCaseStudies' styleClass="keywest_sidebar p-sidebar-lg" position='right' (onHide)="onCloseSidebar()">
	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			{{sidebar_title}}
		</div>
		<app-add-casestudy *ngIf="toggleAddCaseStudies" (callback)='funcAddedCaseStudy()' [solutionID]='solutionID' [mode]='mode' [caseStudyEdit]="caseStudyEdit"></app-add-casestudy>
	</div>
</p-sidebar>