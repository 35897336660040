import { Pipe, PipeTransform } from '@angular/core';
import { CountryFlag } from '@data/services/country-flag/country-flag.service';

@Pipe({
  name: 'countryFlag',
})
export class CountryFlagPipe implements PipeTransform {

  transform(langId: string, flagsMap: Map<string, CountryFlag>): string {
    const flag = flagsMap.get(langId);

    if (!flag) {
      return '';
    }

    return flag.unicodeIcon;
  }

}
