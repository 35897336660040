import { Injectable } from '@angular/core';

@Injectable()
export class CasestudyDetailTranslations {
  public config: any = {
    component: 'caseStudyDetail',
    display_name: 'Case Study Detail',
    description: 'Details of a case study',
  };

  public trans: any = {
    name_label: 'Name',
    benefits: 'Benefits',
    Cancel: 'Cancel',
    change: 'Change',
    download_asset: 'Download Asset',
    model: 'Model:',
    name: 'Name',
    save: 'Save',
    sections: 'Sections',
    tags: 'Tags:',
    testimonials: 'Testimonials',
  };
}
