export class Permissions {
	account_info_editable?: boolean;
	account_info_view?: boolean;
	assumptions_editable?: boolean;
	assumptions_view?: boolean;
	benefits_editable?: boolean;
	benefits_view?: boolean;
	costs_view?: boolean;
	costs_editable?: boolean;
	re_share?: boolean;
	result_view?: boolean;
	result_editable?: boolean;
	expires?: string;
}