import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2RModelCategoryTranslations {
  public config: any = {
    component: 'r_category',
    display_name: 'Solutions Category View',
    description: 'Read only for solution categories'
  };

  public trans: any = {
    name: 'Name',
    description: 'Description',
    view_model_group: 'View Model Group'
  };
}
