<div *ngIf="!loading" class='rsv2-sc-component panel'>
	<div class='rsv2-sc-heading panel-heading'>
		<span>
			{{trans.trans.account.value}}
			<i [ngClass]="{ 'icon-primary': style2022$ | async }" *ngIf="company.can_edit && valueProp.closed == '0'" (click)='showAccountEdit = !showAccountEdit' class='fa fa-pencil pull-right clickable'></i>
			<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account" (callback)="getTranslations()"></app-editTranslation>
		</span>
	</div>
	<div class='rsv2-sc-body panel-body' *ngIf="company && company.name">
		<h3>{{company.name}}</h3>
		<div class='panel panel-sub' *ngIf="!featureHideRevenueEmployees && !simpleAccountNameFeature">
			<div class='row'>
				<div class='col-xs-6'>
					<span class='key'>{{trans.trans.Revenue.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.Revenue" (callback)="getTranslations()"></app-editTranslation>
					</span><br>
					<span class='param' style='padding-left: 0px;'>{{company.revenue_fmt}}</span>
				</div>
				<div class='col-xs-6'>
					<span class='key'>{{trans.trans.employees.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>
					</span><br>
					<span class='param' style='padding-left: 0px;'>{{company.employees === '0' ? "N/A" : company.employees}}</span>
				</div>
			</div>
		</div>
		<table style='width: 100%;' class='table-spaced' [ngStyle]="{'margin-top': featureHideRevenueEmployees ? '25px' : ''}" *ngIf='!simpleAccountNameFeature'>
			<tr *ngIf="company.name">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.account_id.value}}:
				</td>
				<td class='param'>{{company.account_defined_id}}</td>
			</tr>
			<tr *ngIf="company.address1 && company.address1 != 'NULL' && company.address1 != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.addr1.value}}:
				</td>
				<td class='param'>{{company.address1}}</td>
			</tr>
			<tr *ngIf="company.address2 && company.address2 != 'NULL' && company.address2 != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.addr2.value}}:
				</td>
				<td class='param'>{{company.address2}}</td>
			</tr>
			<tr *ngIf="company.city && company.city != 'NULL' && company.city != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.city.value}}:
				</td>
				<td class='param'>{{company.city}}</td>
			</tr>
			<tr *ngIf="company.state && company.state != 'NULL' && company.state != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.state.value}}:
				</td>
				<td class='param'>{{company.state}}</td>
			</tr>
			<tr *ngIf="company.zip && company.zip != 'NULL' && company.zip != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.zip.value}}:
				</td>
				<td class='param'>{{company.zip}}</td>
			</tr>
			<tr *ngIf="company.country && company.country != 'NULL' && company.country != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.country.value}}:
				</td>
				<td class='param'>{{company.country}}</td>
			</tr>
			<tr *ngIf="company.phone && company.phone != 'NULL' && company.phone != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.phone.value}}:
				</td>
				<td class='param'>{{company.phone}}</td>
			</tr>
			<tr *ngIf="company.url && company.url != 'NULL' && company.url != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.website.value}}:
				</td>
				<td class='param'>{{company.url}}</td>
			</tr>
			<tr *ngIf="company.ticker && company.ticker != 'NULL' && company.ticker != ''">
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.ticker.value}}:
				</td>
				<td class='param'>{{company.ticker}}</td>
			</tr>

			<tr>
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.industry.value}}:
				</td>
				<td class='param'>{{company.industry_name}}</td>
			</tr>
			<tr>
				<td class='key'>
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>{{trans.trans.industry_sector.value}}:
				</td>
				<td class='param'>{{company.dl_sector_name}}</td>
			</tr>


		</table>
	</div>
</div>

<p-sidebar *ngIf="showAccountEdit" [(visible)]="showAccountEdit" position='right' styleClass="keywest_sidebar p-sidebar-lg">
	<div class="sidebar_wrapper">
		<div class='sidebar_header'>
			{{trans.trans.account_info.value}}
		</div>		
		<app-account-form
			[company]="company"
			(onSubmit)="funcUpdateCompany($event)"
			(onCancel)="closeAccount()">
		</app-account-form>
	</div>
</p-sidebar>
