import { Injectable } from '@angular/core';

@Injectable ()
export class CaseStudyDetailTranslations {

    public config: any = {
        component: 'casestudyDetail',
		display_name: 'Case Study Detail',
		description: 'Details for a case study including quotes, sections and benefits'
    };

    public trans: any = {
        name_label: 'Name',
        revenue: 'Revenue',
        name: 'Name',
        employees: 'Employees',
        ticker: 'Ticker',
        city: 'City',
        country: 'Country',
        sector: 'Sector',
        address: 'Address',
        website: 'Website',
        tags: 'Tags',
        save: 'Save',
        downloadAsset: 'Download Asset',
        testimonials: 'Testimonials',
        sections: 'Sections',
        benefits: 'Benefits',
        editCaseStudy: 'Edit Case Study',
        are_you_sure: 'Are you sure?',
        back: 'Back'
    };
}
