import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterValuePos',
	pure: false
})
export class filterValuePosPipe implements PipeTransform {
	transform(arr: any[], filter: string): any {
		if (filter == "0") {
			return arr.filter(x => x.user_id == sessionStorage.getItem("uid"));
		}
		return arr;
	}
}


@Pipe({
	name: 'filterValueProp',
	pure: false
})
export class filterValuePropPipe implements PipeTransform {
	transform(arr: any[], filter: string): any {
		if (filter == "0") {
			return arr.filter(x => x.user_id == sessionStorage.getItem("uid"));
		}
		return arr;
	}
}
