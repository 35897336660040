import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from '@data/services/company/company.service';
import { CommonService } from '@data/services/common/common.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { takeUntil } from 'rxjs/operators';
import { Company } from '@shared/models/company.model';
import { AvailableSolution } from '@data/services/valueposition/models/available-solutions.interface';
import { MasterValueProp } from '@data/services/valueposition/models/mast-value-prop.interface';
import { AllScaler } from '@data/services/valueposition/models/all-scaler.interface';
import { VpProject } from '@data/services/valueposition/models/master-vp-projects.interface';

@Component({
	selector: 'app-docusign',
	templateUrl: './docusign.component.html',
	styleUrls: ['./docusign.component.scss'],
	providers: [CompanyService]
})
export class DocusignComponent implements OnInit, OnDestroy {

	masterValuePropFeature = true;
	fullImagePath: string;
	image_url: string;
	company: Company;
	companyId: string | number;
	showActionLoader = false;
	showActionLoaderSituation = false;
	showActionLoaderModel = false;
	showActionLoaderScaler = false;
	showModelsLoader = false;

	availableSolutions: AvailableSolution[];
	masterValuePropId: string | number;
	showMasterValuePropProjects = false;
	availableMasterValuePropProjects: VpProject[];
	selectedProjects = [];
	showActionLoaderProjects = false;
	scalersLoader = false;
	showScalers = false;
	allScalersList: AllScaler[] = [];
	selectedModels: Array<{solutionId: string | number; quantity: string | number}> = [];
	solutionCategoryList: Array<{id: string | number; category: string | number}> = [];
	showCategoryTypeLoader = false;
	categoryType: string | number;
	selectedSolutionsLength = 0;
	showSituation = false;
	showProjects = false;
	selectedScalersList = [];
	scalersCreated = false;
  isvalueProp = false;

	crm: {sfoide?: string} = {};

	modelToggle = false;

  ngUnsubscribe$ = new Subject();


	constructor(
		private router: Router,
		private companyService: CompanyService,
		private commonService: CommonService,
		private masterValuePropService: MasterValuePropService,
		private solutionService: SolutionService,
		private translateService: TranslateService,
		public trans: Translations,
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit(): void {
		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
	}

	afterCompanyDetail(companyId): void {
		this.companyService.getCompanySummary(companyId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
			this.company = result.result.company;
			this.companyId = companyId;

			if (this.crm.sfoide) {
				const params = {
					'company_id': this.companyId,
					'sfoide': this.crm.sfoide
				};
				this.masterValuePropService.createMasterValueProp(params).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
					this.masterValuePropId = result.result.master_value_prop.id;
					this.getAvailableSolutions();
				});
			} else {
				const params = {
					'company_id': this.companyId
				};
				this.masterValuePropService.createMasterValueProp(params).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
					this.masterValuePropId = result.result.master_value_prop.id;
					this.getAvailableSolutions();
				});
			}

		});
	}

	getAvailableSolutions(): void {
		this.showModelsLoader = true;
		this.solutionService.getAvailableSolutionsPlain(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
			this.availableSolutions = result.result;
			if (this.availableSolutions) {
				this.availableSolutions = this.availableSolutions.map(item => {
					item['quantity'] = 1;
					return item;
				});
			}
			this.showModelsLoader = false;
			this.modelToggle = true;
			this.getSolutionCategoryList();
		});
	}

	getSolutionCategoryList(): void {
		this.solutionService.getSolutionCategoryList().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
			this.solutionCategoryList = result.result;
			this.categoryType = this.solutionCategoryList[0]['id'];
			if (this.categoryType) {
				this.getSolution(this.solutionCategoryList[0]['id']);
			}
		});
	}

	getSolution(categoryId): void {
		this.showCategoryTypeLoader = true;
		this.selectedSolutionsLength = 0;
		if (this.availableSolutions) {
			this.availableSolutions = this.availableSolutions.map(item => {
				item['selectedCategory'] = false;
				if (item.solution_category_id != null && (item.solution_category_id.indexOf(categoryId) >= 0)) {
					this.selectedSolutionsLength++;
					item['selectedCategory'] = true;
				}
				return item;
			});

			this.showCategoryTypeLoader = false;
		}
	}

	readAvailableMasterValuePropProjects(): void {
		this.showActionLoaderProjects = true;
		this.availableMasterValuePropProjects = [];
		this.masterValuePropService.readProjects(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
			if (response.result && response.result.projects) {
				const projects = response.result.projects;

				for (let i = 0; i < projects.length; i++) {
					const children = projects[i]['children'];
					for (let j = 0; j < children.length; j++) {
						if (children[j]['recommend'] == '1') {
							projects[i]['children'][j]['selected'] = true;
						}
					}
				}

				this.availableMasterValuePropProjects = projects;

				this.moveSelectedProject();
			}
			this.showActionLoaderProjects = false;
		});
	}

	moveSelectedProject(): void {
		this.selectedProjects = [];

		this.availableMasterValuePropProjects.forEach(projects => {
			if (projects['children'] && projects['children'].length > 0) {
				projects['children'].forEach(children => {
					if (children['selected']) {
						children['parent'] = projects['name'];
						this.selectedProjects.push(children);
					}
				});
			}
		});
	}

	removeSelectedProject(project_id): void {
		const index = this.selectedProjects.findIndex(project => project['id'] == project_id);

		if (index !== -1) {
			this.selectedProjects.splice(index, 1);
		}
	}

	createProjects(): void {
		const payload = {
			'account_project_id': this.selectedProjects.map(project => project['id']).join(',')
		};

		this.showActionLoaderProjects = true;
		this.masterValuePropService.createProjects(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
			this.showActionLoaderProjects = false;
			this.getScalers();
		});
	}

	checkModels(params, quantity, event): void {
		if (event.target.checked) {
			const tempModel = {
				'solutionId': params,
				'quantity': quantity
			};
			this.selectedModels.push(tempModel);
		} else {
			this.selectedModels = this.selectedModels.filter((impact: any) => {
				return impact['solutionId'] !== params;
			});
		}
	}

	quantityAssign(id, quantityValue): void {
		const quantityCheck = this.selectedModels.filter(item => {
			return item['solutionId'] == id;
		});

		if (quantityCheck.length > 0) {
			for (let i = 0; i < this.selectedModels.length; i++) {
				if (this.selectedModels[i]['solutionId'] == id) {
					this.selectedModels[i]['quantity'] = quantityValue;
				}
			}
		}
	}

	createSolution(): void {
		if (this.selectedModels) {

			const selectedModels = this.selectedModels.map((impact) => {
				return impact['solutionId'];
			});


			this.showActionLoaderModel = true;

			const payload = {
				'account_solution_id': selectedModels.join(',')
			};

			this.masterValuePropService.saveSolutions(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
				if (result.result) {
					this.showActionLoaderModel = false;
					this.showSituation = true;
				}
			});
		}
	}

	getProjects(): void {
		this.showProjects = true;
		this.readAvailableMasterValuePropProjects();
	}

	getScalers(): void {
		this.showActionLoader = true;
		this.masterValuePropService.readAvailableScalers(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
			this.allScalersList = result.result.scalers;
			this.showActionLoader = false;
		});

		this.showScalers = true;
	}

	createScaler(): void {
		if (this.allScalersList && this.allScalersList != null && this.allScalersList.length > 0) {
			this.selectedScalersList = this.allScalersList.filter((scaler: any) => {
				return scaler.value;
			});

			const scalerPayload = {
				'value_prop_scalers': this.allScalersList,
				'angular_dev': 1
			};
			this.showActionLoaderScaler = true;

			this.masterValuePropService.createScalers(this.masterValuePropId, scalerPayload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result) => {
				if (result.result) {
					this.scalersCreated = true;
				}
				this.showActionLoaderScaler = false;
				this.afterScalerSave();
			});

		} else {
			this.afterScalerSave();
		}

	}

	afterScalerSave(): boolean {
		this.router.navigate(['/mastervalueprop', this.masterValuePropId]);
		return true;
	}

}
