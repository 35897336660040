import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { SolutionThresholdType } from '@shared/models/facts.models';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';
import { SolutionThreshold } from './models/solution-threshold.model';

@Component({
  selector: 'app-thresholds',
  templateUrl: './thresholds.component.html',
  styleUrls: ['./thresholds.component.scss'],
})
export class ThresholdsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() canEdit = false;

  @Input() solutionId: number | string;
  @Input() tabInit: number;

  solutionThreshold: SolutionThreshold[] = [];
  modalReference: NgbModalRef;
  accountSolutionId: number;
  accountId: number;
  loadthresholdTable: boolean;
  thresholdTypes: SolutionThresholdType[] = [];
  ThresholdModel: SolutionThreshold;
  id: number;
  min: number;
  max: number;
  margin: number;
  thresholdTypeId: number;

  contextualHelp: { [klass: string]: any } = {};
  fullImagePath = '';

  subscriptioncontextualHelp: Subscription;
  subscriptiongetThresholds: Subscription;
  subscriptiongetSolutionThresholdTypes: Subscription;
  subscriptionSaveThreshold: Subscription;
  subscriptiondeleteThreshold: Subscription;

  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private factsService: FactsService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.fullImagePath = commonService.getLoaderImageUrl();
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    // tslint:disable-next-line: radix
    this.accountSolutionId = parseInt(this.route.snapshot.paramMap.get('id'));
    // tslint:disable-next-line: radix
    this.accountId = parseInt(sessionStorage.getItem('aid'));
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });
    this.style2022$ = this.styleService.style2022;
    this.getThresholdList();
  }

  ngOnDestroy() {
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptiongetThresholds) {
      this.subscriptiongetThresholds.unsubscribe();
    }
    if (this.subscriptiongetSolutionThresholdTypes) {
      this.subscriptiongetSolutionThresholdTypes.unsubscribe();
    }
    if (this.subscriptionSaveThreshold) {
      this.subscriptionSaveThreshold.unsubscribe();
    }
    if (this.subscriptiondeleteThreshold) {
      this.subscriptiondeleteThreshold.unsubscribe();
    }
  }

  ngOnChanges() {
    if (this.tabInit === 9) {
      this.getThresholdTypeList();
      this.getThresholdList();
    }
  }

  getThresholdList() {
    this.loadthresholdTable = true;
    this.subscriptiongetThresholds = this.solutionService.getThresholds(this.accountId, this.accountSolutionId).subscribe((response) => {
      if (response.result) {
        this.solutionThreshold = response.result;
      }
      this.loadthresholdTable = false;
    });
  }

  getThresholdTypeList() {
    this.subscriptiongetSolutionThresholdTypes = this.factsService.getSolutionThresholdTypes().subscribe((response) => {
      if (response.result) {
        this.thresholdTypes = response.result.thresholdTypes.map((item) => {
          item['threshold_type_id'] = item['id'];
          return item;
        });
      }
    });
  }

  saveThreshold(form: NgForm) {
    this.ThresholdModel = {
      id: 0,
      account_solution_id: this.accountSolutionId,
      threshold_type_id: form.value.threshold_type_id,
      min: form.value.min,
      max: form.value.max,
      margin: form.value.margin,
    };
    this.ThresholdModel['foo'] = 2
    if (Number(form.value.id) === 0 || form.value.id == null) {
      this.subscriptionSaveThreshold = this.solutionService.SaveThreshold(this.ThresholdModel).subscribe((result) => {
        this.notification.success('Threshold created successfully', false);
        this.solutionThreshold = result['result']['account_solution_thresholds'];
      });
    } else {
      this.subscriptionSaveThreshold = this.solutionService.SaveThreshold(this.ThresholdModel).subscribe((result) => {
        this.notification.success('Threshold updated successfully', false);
        this.solutionThreshold = result['result']['account_solution_thresholds'];
      });
    }
    this.modalReference.close();
  }

  deleteThreshold(thresholdtypeid: number) {
    this.subscriptiondeleteThreshold = this.solutionService.deleteThreshold(this.accountSolutionId, thresholdtypeid).subscribe(() => {
      this.getThresholdList();
      this.notification.success('Solution Threshold deleted successfully', false);
    });
  }

  openAddThreshold(content: TemplateRef<HTMLDivElement>) {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {
        this.resetValues();
      },
      () => {
        this.resetValues();
      }
    );
  }

  resetValues() {
    this.id = 0;
    this.thresholdTypeId = null;
    this.min = null;
    this.max = null;
    this.margin = null;
  }

  editAddThreshold(content: TemplateRef<HTMLDivElement>, index: number) {
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.then(
      () => {
        this.resetValues();
      },
      () => {
        this.resetValues();
      }
    );
    const indexrow = this.solutionThreshold[index];

    if (indexrow) {
      this.id = indexrow.id;
      this.thresholdTypeId = indexrow.threshold_type_id;
      this.min = Number(indexrow.min);
      this.max = Number(indexrow.max);
      this.margin = indexrow.margin;
    }
    this.loadthresholdTable = false;
  }
}
