<div class="modal-header">
	<h4 class="modal-title">{{scratchPadName}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<form class="clearfix" name="scratchpadTemplateForm" id="ngForm" #scratchpadTemplateForm="ngForm" *ngIf="isScratchPad">
		<div class="subHeader">{{ 'props.tabDetails.benefits.ScratchPadfor' | translate }}:&nbsp;
			<strong>{{scratchpadFactorName}}</strong>
		</div>
		<div class="form-group" *ngFor="let scratchpad of scratchpadList.units">
			<div class="row">
				<div class="col-md-4 col-md-offset-1">
					{{scratchpad.name}}
				</div>
				<div class="col-md-7">
					<div class="row">
						<div class="col-md-1 scratchpadParens">
							<span *ngIf="scratchpad.open_paren==1"> ( </span>
						</div>
						<div class="col-md-6">
							<input title="Answer" type="text" size="1" name=operand{{scratchpad.operand}} value="{{scratchpad.operand}}" [(ngModel)]="scratchpad.operand" placeholder="Answer" class="form-control">
						</div>
						<div class="col-md-1 scratchpadParens">
							<span *ngIf="scratchpad.close_paren==1"> ) </span>
						</div>
					</div>
				</div>
			</div>
			<div class="row operandScratchPad">
				<div class="form-group" *ngIf="scratchpad.operator_id !='5'">
					<div class="col-md-offset-6 col-md-2">
						{{operatorList[scratchpad.operator_id - 1].sign}}
					</div>
				</div>
			</div>
		</div>
		<div class="form-group">
			<div *ngIf="hideActionLoader">
				<button type="button" class="btn btn-primary" (click)="saveAndCalcScratchpad('1')">{{ 'general.save' | translate }}</button>
				<button type="button" class="btn btn-primary" (click)="saveAndCalcScratchpad('0')">{{'general.Calculate' | translate }}</button>
				<button type="button" class="btn btn-primary" (click)="clearAnswer();">{{ 'general.Clear' | translate}}</button>
				<span class="total-value" *ngIf="total != undefined">{{ 'general.Total' | translate }} : {{total}}</span>
			</div>
			<div *ngIf="!hideActionLoader">
				<div class='row'>
					<div class='col-xs-12'>
						<img class="actionLoader" [hidden]="hideActionLoader" src="{{fullImagePath}}" />
						<!-- <ngb-progressbar [showValue]="true" type="info" [value]="25"></ngb-progressbar> -->
					</div>
				</div>
			</div>
		</div>
	</form>
</div>