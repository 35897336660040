import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { APIResult } from '@shared/models/api-result.model';
import { ComponentTranslation, ComponentTranslationPayload, TranslationModel } from '@shared/models/translation.models';
import { of } from 'rxjs';

export interface TranslationError {
  [x: string]: string;
}

export interface TranslationClass {
  typeObjectKeys<T extends { [key: string]: any }>(object: T): { [originalPropType in keyof typeof object]: any };
  config: {
    component: string;
    display_name: string;
    description: string;
  };
  trans: any;
  t: any;
}

@Injectable()
export class TranslationsV2Service {
  translationCacheMap = new Map<string, TranslationModel>();
  translationErrors: TranslationError[] = [];

  constructor(private commonService: CommonService) {
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.translationCacheMap.clear();
    });
  }

  public getAllTranslations(accountId) {
    const endpoint = 'fact/readTranslationsComponents/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public getAllComponents() {
    const endpoint = 'fact/readAllComponents';
    return this.commonService.getAPIService(endpoint);
  }

  public getAllOverrides(accountId) {
    const endpoint = 'fact/readTranslationOverride/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public getComponentTrans(payload: ComponentTranslationPayload, cache = true): Observable<any> {
    const foundTranslations = this.getTranslationCache(payload.component);
    if (foundTranslations && cache) {
      return of(foundTranslations);
    } else {
      const localTranslations = payload.localTranslations;
      delete payload.localTranslations;

      const endpoint = 'fact/getTranslationsV2';
      return this.commonService.postAPIService(endpoint, payload).pipe(
        map((trans: APIResult<ComponentTranslation[]>) => {
          let output: TranslationModel;
          // The fallback if the local translations haven't been sent, for backwards compatibility
          if (!localTranslations) {
            output = trans.result.reduce<TranslationModel>((acc, curr) => {
              return {
                [curr.key]: curr,
                ...acc,
              };
            }, {});
          } else {
            // Iterate over the local translations, and if the translation is not found, construct a fallback add it to the output
            output = Object.keys(localTranslations).reduce<TranslationModel>((acc: TranslationModel, curr: string) => {
              const foundTranslation = trans.result.find((translation: ComponentTranslation) => translation.key === curr);
              if (!foundTranslation) {
                this.translationErrors.push({ [payload.component]: curr });

                let transValue: string;
                if (typeof localTranslations[curr] !== 'string') {
                  transValue = (localTranslations[curr] as ComponentTranslation).value;
                } else {
                  transValue = localTranslations[curr] as string;
                }
                return {
                  [curr]: { value: transValue, key: curr, is_override: null, translation_id: null },
                  ...acc,
                };
              } else {
                // Otherwise, add the translation normally
                return {
                  [curr]: foundTranslation,
                  ...acc,
                };
              }
            }, {});
          }

          // Log the errors so that translations can be run
          if (this.translationErrors.length > 0) {
            console.warn('translationErrors', this.translationErrors);
          }

          this.setTranslationCache(payload.component, output);
          return output;
        })
      );
    }
  }

  trans(trans: TranslationClass): Observable<TranslationClass> {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: trans.config.component,
      lang: langAbbr,
      localTranslations: trans.trans,
    };

    return this.getComponentTrans(payload).pipe(
      map((output) => ({
        ...trans,
        trans: output,
        t: Object.keys(output).reduce((tAcc: any, tCurr: string) => {
          tAcc[tCurr] = output[tCurr].value;
          return tAcc;
        }, {}),
      }))
    );
  }

  public saveTranslation(payload) {
    const endpoint = 'fact/createTranslationsV2';
    return this.commonService.postAPIService(endpoint, payload).pipe(
      map((output) => {
        this.translationCacheMap.delete(payload.component);
        return output;
      })
    );
  }

  public overrideTranslation(payload) {
    const endpoint = 'fact/createOverrideTranslationsV2';
    return this.commonService.postAPIService(endpoint, payload).pipe(
      map((output) => {
        this.translationCacheMap.delete(payload.component);
        return output;
      })
    );
  }

  public updateTranslation(payload) {
    const endpoint = 'fact/updateTranslationsV2';
    return this.commonService.putAPIService(endpoint, payload).pipe(
      map((output) => {
        this.translationCacheMap.delete(payload.component);
        return output;
      })
    );
  }

  public updateOverrideTranslation(payload) {
    const endpoint = 'fact/updateOverrideTranslationsV2';
    return this.commonService.putAPIService(endpoint, payload).pipe(
      map((output) => {
        this.translationCacheMap.delete(payload.component);
        return output;
      })
    );
  }

  public getLanguageAbbr(num: string | number): string {
    let lang: string;

    switch (+num) {
      case 1: {
        lang = 'en';
        break;
      }
      case 2: {
        lang = 'gb';
        break;
      }
      case 3: {
        lang = 'es';
        break;
      }
      case 4: {
        lang = 'fr';
        break;
      }
      case 5: {
        lang = 'zh';
        break;
      }
      case 6: {
        lang = 'pt';
        break;
      }
      case 7: {
        lang = 'de';
        break;
      }
      case 8: {
        lang = 'ja';
        break;
      }
      case 9: {
        lang = 'ko';
        break;
      }
      case 10: {
        lang = 'it';
        break;
      }
      case 11: {
        lang = 'nl';
        break;
      }
      case 12: {
        lang = 'id';
        break;
      }
      case 13: {
        lang = 'th';
        break;
      }
      case 14: {
        lang = 'ms';
        break;
      }
      default: {
        lang = 'en';
        break;
      }
    }
    return lang;
  }

  public getLanguageFlagIcon(langId: string): string {
    let flag;
    switch (langId) {
      case '1': {
        flag = 'flag-icon flag-icon-um';
        break;
      }
      case '2': {
        flag = 'flag-icon flag-icon-gb';
        break;
      }
      case '3': {
        flag = 'flag-icon flag-icon-es';
        break;
      }
      case '4': {
        flag = 'flag-icon flag-icon-fr';
        break;
      }
      case '5': {
        flag = 'flag-icon flag-icon-cn';
        break;
      }
      case '6': {
        flag = 'flag-icon flag-icon-pt';
        break;
      }
      case '7': {
        flag = 'flag-icon flag-icon-de';
        break;
      }
      case '8': {
        flag = 'flag-icon flag-icon-jp';
        break;
      }
      case '9': {
        flag = 'flag-icon flag-icon-kr';
        break;
      }
      case '10': {
        flag = 'flag-icon flag-icon-it';
        break;
      }
      case '11': {
        flag = 'flag-icon flag-icon-nl';
        break;
      }
      case '12': {
        flag = 'flag-icon flag-icon-id';
        break;
      }
      case '13': {
        flag = 'flag-icon flag-icon-th';
        break;
      }
      case '14': {
        flag = 'flag-icon flag-icon-my';
        break;
      }
    }
    return flag;
  }

  getTranslationCache(componentName: string): TranslationModel | undefined {
    return this.translationCacheMap.get(componentName);
  }

  setTranslationCache(componentName: string, translations): void {
    this.translationCacheMap.set(componentName, translations);
  }

  destroyTranslationCache() {
    this.translationCacheMap.clear();
  }
}
