import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';

import { AdminCurrencyComponent } from './currency.component';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeTableModule } from 'primeng/treetable';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule } from 'primeng/progressbar';
import { CXOService } from '@data/services/cxo/cxo.service';

import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { CheckboxModule } from 'primeng/checkbox';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { Translations } from '@data/services/translation/translation.service';
import { CurrencyTranslation } from './currency.translation';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
	{
		path: 'currency',
		component: AdminCurrencyComponent
	}
];

@NgModule({
	imports: [
		AreYouSureModule,
		ProgressBarModule,
		SidebarModule,
		InputSwitchModule,
		TreeTableModule,
		MultiSelectModule,
    MatTooltipModule,
		EditTranslationsModule,
		TableModule,
		NgbModule,
		CommonModule,
		BreadcrumbsModule,
		FormsModule,
		CheckboxModule,
		RouterModule.forChild(routes),
	],
	declarations: [
		AdminCurrencyComponent
	],
	providers: [
		CXOService,
		TranslationsV2Service, 
		Translations,
		CurrencyTranslation
	],
	entryComponents: [AdminCurrencyComponent],
	exports: [AdminCurrencyComponent]
})
export class AdminCurrencyModule { }
