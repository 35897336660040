<div>
	<div class="row">
		<div class="col-md-6">
			<input type="text" class="form-control" placeholder="{{ trans.vp_dashboard.benefits.search | translate }}" [(ngModel)]="searchText" matTooltip= '{{trans.contextual_help.vp_dashboard.search_benefits | translate}}' matTooltipPosition="left" />
		</div>
		<div class='col-xs-6'>
			<span class="changeView pull-right">
				<i class="fa fa-th-large" *ngIf="!benefitsPanel" (click)="changeViewCallback('tile')"></i>
				<i class="fa fa-list-ul" *ngIf="benefitsPanel" (click)="changeViewCallback('table')"></i>
			</span>
		</div>
	</div>

	<div class="row">
		<div class="col-md-3 col-sm-12" *ngIf="!dealdesk">
			<div>

			</div>
		</div>
	</div>
	<div class="table-responsive col-sm-12 soln-table">
		<div *ngFor="let benefitCategory of benefitsList; let b = index">
			<div *ngIf="showGroup(benefitCategory.metrics);">
				<h3 style='margin-bottom: 0px;'>
					{{benefitCategory.name}}<br>
					<small style='font-size: 70%; color: green; margin: 5px;'>{{filterActive(benefitCategory, 1)}} {{trans.vp_dashboard.benefits.active | translate }} hii</small>
					<small style='font-size: 70%; color: red; margin: 5px;'>{{filterActive(benefitCategory, 0)}} {{trans.vp_dashboard.benefits.na | translate }}</small>
					<small style='font-size: 70%; color: orange; margin: 5px;'>{{filterActive(benefitCategory, 3)}} {{trans.vp_dashboard.benefits.soft | translate }}</small>
				</h3>

				<table id="benefits" class="table custom-table" style='border-top: 0px;'>
					<thead>
						<tr>
							<th style='border: 0px; padding-bottom: 0px !important;' width="5%">{{ trans.vp_dashboard.benefits.phasing | translate }} hi</th>
							<th style='border: 0px; padding-bottom: 0px !important;' *ngIf="showBatchUpdateCheckboxes" width="5%">&nbsp;</th>
							<th style='border: 0px; padding-bottom: 0px !important;' width="10%">{{ trans.vp_dashboard.benefits.status | translate}} hiii<app-editTranslation  *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.phasing'></app-editTranslation></th>
							<th style='border: 0px; padding-bottom: 0px !important;' width="35%">{{ trans.vp_dashboard.benefits.benefits | translate }}</th>
							<th width="10%" style='border: 0px; padding-bottom: 0px !important; white-space: nowrap;'>{{ trans.vp_dashboard.benefits.impact | translate }}</th><app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='vp_dashboard.benefits.phasing'></app-editTranslation>
							<th style='border: 0px; padding-bottom: 0px !important;' width="10%">{{ trans.vp_dashboard.benefits.improvement | translate}}</th>
							<th style='border: 0px; padding-bottom: 0px !important;' width="17%" class="text-right">{{ trans.vp_dashboard.benefits.year1 | translate }}</th>
							<th width="16%" style='border: 0px; padding-bottom: 0px !important; text-align: right;'>{{ trans.vp_dashboard.benefits.total | translate }}</th>
						</tr>
					</thead>


					<tbody>
						<tr *ngFor="let benefit of benefitCategory.metrics | filter : menuFilter : 'metric_name' | filterStatus: showActive : showNA : showSoft; let i = index" class="grid-row">
							<ng-container>
								<td>
									<div class="pull-left">
										<i class="glyphicon glyphicon-equalizer" (click)="openAddPhasing(addPhasingModal, benefit.id)" [ngbTooltip]="contextualHelp['Position 24']" container="body" placement="top" role="button"></i>
									</div>
								</td>
								<td *ngIf="showBatchUpdateCheckboxes">
										<p-checkbox name="checkbox{{b}}{{i}}" [binary]='true' [(ngModel)]="benefit.updateBatch"></p-checkbox>

									<!-- <label>
										<input type="checkbox" [(ngModel)]="benefit.updateBatch" (click)='benefit.updateBatch = !benefit.updateBatch' />
										<span class="check"></span>
									</label> -->
								</td>
								<td class="benefit-status benefitStatusElement" *ngIf="(!inputOpened || !benefit.changeActive)" (click)="openActiveTypes(benefit)" matTooltip= '{{trans.contextual_help.vp_dashboard.benefit_status | translate}}' matTooltipPosition="above">
									<!-- {{benefit.active_type}} -->
									<a href="javascript:;" class="dashedLink benefitStatusElement" data-toggle="tooltip" [title]='trans.vp_dashboard.benefits.change_benefits_status | translate'>
										<span class="activeStatusType benefitStatusElement" [ngStyle]="{'background-color':getBenefits(benefit.active_type)}"></span>
									</a>
								</td>
								<td *ngIf="inputOpened && benefit.changeActive" class="benefitStatusElement">
									<form class="form-inline benefitStatusElement">
										<div class="editable-controls form-group benefitStatusElement">
											<select class="form-control active-type benefitStatusElement" name="benefit_active_{{benefit.id}}" [(ngModel)]="benefit.active" (change)="!showBatchUpdateCheckboxes ? statusChange(benefit) : ''">
												<option value="" class="benefitStatusElement" disabled>{{ trans.vp_dashboard.benefits.choose | translate}}</option>
												<option *ngFor="let activeType of activeTypes" [value]="activeType.value" class="benefitStatusElement">
													{{activeType.name}}</option>
											</select>
										</div>
									</form>
								</td>
								<td class="benefit-status" [ngbTooltip]="benefit.description" container="body" placement="top">
									<span role="button" (click)="openBenefitDetail(benefit)" class='link'>{{benefit.alt_metric_name?benefit.alt_metric_name:benefit.metric_name}}&nbsp;
										<i class="fa fa-bookmark" *ngIf="benefit.vp_specific == 1" aria-hidden="true" title="This Benefit was added to this ValueProp manually."></i>
									</span>



									<a *ngIf="valueProp.vp_can_edit && benefit.vp_specific == 1 && benefitOnTheFlyPrivilege" (click)="openUpdateBenefit(benefit)" ngbTooltip="Update" container="body" placement="top">
										<span style='color: green !important; margin-left: 10px;' class="fa fa-pencil"></span>
									</a>
									<a *ngIf="valueProp.vp_can_edit && benefit.vp_specific == 1 && benefitOnTheFlyPrivilege" (click)="opendeleteCustomBenefit(benefit)" ngbTooltip="Delete" container="body" placement="top">
										<span style='color: red !important; margin-left: 10px;' class="fa fa-times"></span>
									</a>
								</td>
								<td>{{benefit.impact_type}}</td>
								<td>{{benefit.impact}} {{benefit.abbr}}</td>
								<td class="preventWordBreak text-right">{{benefit.year1_benefits_fmt}}</td>
								<td class="preventWordBreak text-right">{{benefit.total_benefits_fmt}}</td>
							</ng-container>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div *ngIf="benefitsLoader" class="col-sm-12 m-t-md text-center">
			<p class="text-center">
				<img class="loader" src="{{fullImagePath}}" />
			</p>
		</div>
		<div *ngIf="(benefitsList == null || benefitsList.length==0) && !benefitsLoader" class="m-t-md col-sm-12">
			<div class="searchTableContainer text-center">
				<h4>{{ trans.vp_dashboard.benefits.no_benefits_found | translate }}</h4>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]="showDeleteCustomBenefit" position='left' styleClass="p-sidebar-md">
	<h2>{{ trans.vp_dashboard.benefits.delete | translate }}</h2>

	<p>{{ trans.vp_dashboard.benefits.delete | translate }}</p>

	<button type="button" class="btn btn-primary" (click)="deleteCustomBenefit()">{{ trans.button.Yes | translate }}</button>
	<button type="button" class="btn btn-secondary" (click)="showDeleteCustomBenefit = !showDeleteCustomBenefit">{{ trans.button.No | translate }}</button>
</p-sidebar>