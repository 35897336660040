import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';
import { snakeCase } from 'lodash';

@Directive({
  selector: '[appSpaceToUnderscore]',
})
export class SpaceToUnderscoreDirective {
  constructor(private renderer: Renderer2, private elementRef: ElementRef, private ngModel: NgModel) {}

  @HostListener('blur', ['$event'])
  onKeyDown() {
    const val = this.ngModel.model;
    let exception = false;

    let checkedVal: string;

    if (val.includes('_p2')) {
      checkedVal = val.replace('_p2', '');
      exception = true;
    } else {
      checkedVal = val;
    }

    const snaked = snakeCase(checkedVal) + (exception ? '_p2' : '');

    this.renderer.setProperty(this.elementRef.nativeElement, 'value', snaked);
    this.ngModel.update.emit(snaked);
  }
}
