import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'sharev2',
    display_name: 'Share VP Dashboard',
    description: 'Share tab in vp dashboard top panel',
  };
  public trans: any = {
    find: 'Find and add a user',
    back: 'Back',
    share: 'Share',
    ch_share: 'Share this Value Prop with a new or existing customer',
    desc: "Search for a user below and if no user is found, you'll be able to create a new customer share.",
    add: 'Add',
    who: 'Who',
    are_you_sure: 'Are you sure?',
    no_shares_found: 'No Shares Found',
    activity_log_for: 'Activity log for ',
    no_activity_found: 'No Activity Found',
    create_a_new_share_for: 'Create a new share for ',
    choose_one: 'Choose One',
    name: 'Name',
    email: 'Email',
    expires: 'Expires',
    feature: 'Feature',
    visible: 'Visible?',
    editable: 'Editable?',
    account_info: 'Account Info',
    benefits: 'Benefits',
    assumptions: 'Assumptions',
    costs: 'Costs',
    results: 'Results',
    cancel: 'Cancel',
    save: 'Save',
    edit_share: 'Edit Share',
    share_role_type: 'Share Role Type',
    existing_guest: 'Existing Guest',
    user_name: 'User Name',
    first_name: 'First Name',
    last_name: 'Last Name',
    company: 'Company',
    delete_share_user: 'Delete Share User',
    DeleteUserWarning: 'Are you sure that you want to remove this user?',
    search_for_users_by_name: 'Search For Users by name',
    search: 'Search',
    ch_assumtpions: 'Please ensure a model associated with this Value Prop has at least 1 Key Assumption',
    title: 'Title',
    descShort: 'Search for a user below to share',
    externalDisabled: 'Sharing with external users is disable at this moment',
    vpSaveSuccess: 'Value Prop Share updated successfully for ',
    vpSaveError: 'Value Prop Share cannot be created for ',
    customerSuccess: 'Customer Created Successfully',
    internalUser: 'Internal User',
    externalUser: 'External User',
    emailMe: 'Email Me',
    emailCustomer: 'Email Customer'
  };
}
