import { Directive, ElementRef, HostListener, Input, Renderer2, AfterViewChecked } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appNGError]',
})
export class DLNGErrorDirective implements AfterViewChecked {
  @Input() elemControl: AbstractControl;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.funcValidate();
  }

  @HostListener('change') onChange(): void {
    this.funcValidate();
  }

  funcValidate(): void {
    if (this.elemControl && this.elemControl.invalid) {
      this.renderer.addClass(this.el.nativeElement, 'ngform_has_error');
      return;
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'ngform_has_error');
    }
  }
}
