<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{ trans.trans.title.value }}
	</div>
	<div class='sidebar_container'>
		<div class='keywest_panel'>
			<div class='row'>
				<div class='col-xs-12'>
					<input type='text' [(ngModel)]="clonedName" [placeholder]="trans.trans.namePlaceholder.value" class='form-control'>
				</div>
			</div>

			<div class='row'>
				<div class='col-xs-12'>
					<h3>{{ trans.trans.cloneOption.value }}</h3>
				</div>
			</div>
			<div class='row'>
				<div class='col-xs-12'>
					<input type='radio' id='clone-options-exact' name='clone_options' [value]='0' [(ngModel)]="cloneOption">
					<label for='clone-options-exact'> {{ trans.trans.exactCopy.value }}</label>
				</div>
			</div>

			<div class='row'>
				<div class='col-xs-12'>
					<input type='radio' id='clone-options-account' name='clone_options' [value]='1' [(ngModel)]="cloneOption">
					<label for='clone-options-account'> {{trans.trans.newAccount.value }}</label>
				</div>
			</div>

			<div class='row' *ngIf="cloneOption == 1">
				<div class='col-xs-12'>
					<app-search-company-clone *ngIf="!cloneCompanyId" (funcCallback)='funcCloneCompany($event)'></app-search-company-clone>
					<div *ngIf="cloneCompanyId">
						<div class='row'>
							<div class='col-xs-12'>
								<b>{{trans.trans.newAccountLabel.value}}</b>: {{cloneCompanyName}}
							</div>
						</div>

						<div class='row' *ngIf="feature22">
							<div class='col-xs-12'>
								<input type='text' [(ngModel)]="opportunityId" class='form-control' [placeholder]='trans.trans.opportunityIDPlaceholder.value'>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class='row' *ngIf="feature22">
				<div class='col-xs-12'>
					<input type='radio' id='clone-options-opportunity' name='clone_options' [value]='2' [(ngModel)]="cloneOption">
					<label for='clone-options-opportunity'> {{trans.trans.newOpportunity.value}}</label>
				</div>
			</div>

			<div class='row' *ngIf="cloneOption == 2">
				<div class='col-xs-12'>
					<input type='text' [(ngModel)]="opportunityId" class='form-control' [placeholder]='trans.trans.opportunityIDPlaceholder.value'>
				</div>
			</div>

			<br>

			<div class='row'>
				<div class='col-xs-12'>
					<h3>{{trans.trans.cloneOwner.value}}</h3>

					<input type='radio' id='clone-owner-me' name='clone_owner' [value]='0' [(ngModel)]="ownerOfClone">
					<label for='clone-owner-me'> {{trans.trans.ownerMe.value}}</label><br>

					<input type='radio' id='clone-owner-original' name='clone_owner' [value]='1' [(ngModel)]="ownerOfClone">
					<label for='clone-owner-original'> {{trans.trans.ownerOriginal.value}}</label>
				</div>
			</div>

			<div [ngClass]="{'row': crm, 'btn_container': !crm}">
				<div class='col-xs-12'>
					<button class='btn btn_customizable pull-right' (click)='cloneValueProp()' [disabled]="cloneLoader">
						<ng-container *ngIf="!cloneLoader; else loadingSpinner">
							{{trans.trans.save.value}}
						</ng-container>
						<ng-template #loadingSpinner>
							<i class="fa fa-spinner fa-spin"></i>
						</ng-template>
					</button>
					<app-are-you-sure *ngIf="!cloneLoader" [message]='trans.trans.areYouSure.value' class="btn btn-secondary pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
				</div>
			</div>
		</div>
	</div>
	
</div>
