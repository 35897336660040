import { Injectable } from '@angular/core';

@Injectable()
export class DiscoverQuestionsVpDashTranslations {
  public config: any = {
    component: 'discoveryQuestionsVpDash',
    display_name: 'Value Prop Discovery Questions',
    description: 'Discovery questions tab in value prop dashboard',
  };

  public trans: any = {
    discoveryQuestions: 'Discovery Questions',
    question: 'Discovery Questions',
    next: 'Next',
    required: 'Required',
    save: 'Save',
    dq: 'Discovery Questions',
    nothing: 'No discovery questions found',
    areYouSure: 'Are you sure?',
    back: 'Back',
    allRequiredValues: 'Please enter all required fields.',
    updateSuccess: 'Discovery questions updated successfully.',
  };
}
