<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ sidebar_title }}
    <app-editTranslation
      *ngIf="showTranslate && mode === 'add'"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.add_situation"
      (callback)="getTranslations()"
    ></app-editTranslation>
    <app-editTranslation
      *ngIf="showTranslate && mode === 'edit'"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.edit_situation"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>
  <div class="sidebar_container">
    <div class="keywest_panel">
      <form name="formAddEditSituation" #formAddEditSituation="ngForm">
        <div class="row">
          <div class="col-xs-12" appNGError [elemControl]="formAddEditSituation.controls.name">
            <label class="sidebar_label"
              >{{ trans.trans.name.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'ae_situations'"
                [isTranslationV2]="true"
                [transObj]="trans.trans.name"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input type="text" name="name" ret-data="Name" [(ngModel)]="situation.name" class="form-control" required />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label"
              >{{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'ae_situations'"
                [isTranslationV2]="true"
                [transObj]="trans.trans.description"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <textarea name="description" [(ngModel)]="situation.description" rows="4" class="form-control"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6" appNGError [elemControl]="formAddEditSituation.controls.type">
            <label class="sidebar_label"
              >{{ trans.trans.type.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="'ae_situations'"
                [isTranslationV2]="true"
                [transObj]="trans.trans.type"
                (callback)="getTranslations()"
              ></app-editTranslation> </label
            ><br />
            <fieldset class="radio-control marg-v">
              <div>
                <label>
                  <input type="radio" name="type" value="1" ret-data="Type" [(ngModel)]="situation.type" required />
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="'ae_situations'"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.singleSelectList"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <span class="marg-h">{{ trans.trans.singleSelectList.value }}</span>
                </label>
              </div>
            </fieldset>

            <fieldset class="radio-control marg-v">
              <div>
                <label>
                  <input type="radio" name="type" value="2" [(ngModel)]="situation.type" required />
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="'ae_situations'"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.multiSelectList"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <span class="marg-h">{{ trans.trans.multiSelectList.value }}</span>
                </label>
              </div>
            </fieldset>
            <fieldset class="radio-control marg-v">
              <div>
                <label>
                  <input type="radio" name="type" value="3" [(ngModel)]="situation.type" required />
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="'ae_situations'"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.singleSelectDropdown"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <span class="marg-h">{{ trans.trans.singleSelectDropdown.value }}</span>
                </label>
              </div>
            </fieldset>
            <fieldset class="radio-control marg-v">
              <div>
                <label>
                  <input type="radio" name="type" value="4" [(ngModel)]="situation.type" required />
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="'ae_situations'"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.multiSelectDropdown"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                  <span class="marg-h">{{ trans.trans.multiSelectDropdown.value }}</span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="row tags-container">
          <div class="col-xs-12">
            <div class="col-xs-12 onshow-container">
              <div class="tag-input-container">
                <label class="sidebar_label">{{ trans.trans.createCustomAssetTag.value }}</label>
                <br />
                <p-inputSwitch 
                  ngDefaultControl 
                  appNGError 
                  [elemControl]="formAddEditSituation.controls.hasTag" 
                  [(ngModel)]="situation.has_tag" 
                  (onChange)="generateOnshowTag($event)" 
                  name="hasTag"
                  class="tag-switch"
                ></p-inputSwitch>
              </div>
              <!-- Situation Value Tag -->
              <div
                *ngIf="situation.has_tag"
                appNGError
                [elemControl]="formAddEditSituation.controls.valueTag"
                [ngClass]="{ 'field-error': situation.name_tag === situation.value_tag }"
              >
                <label class="sidebar_label labelsRequired">{{ trans.trans.situationValueTag.value }}</label>
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTagTooltip.value">info</span>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="situation.value_tag"
                  name="valueTag"
                  ret-data="Value Tag"
                  maxlength="255"
                  appSpaceToUnderscore
                  appSpecialCharacterValidator
                  [required]="situation.has_tag"
                />
                <div *ngIf="situation.has_tag" class="hint light-h tag-div"
                  >[{{ trans.trans.onShow.value }}{{ situation.value_tag ? situation.value_tag : trans.trans.value.value }}]
                </div>
                <span class="input-error-warn" *ngIf="formAddEditSituation.controls.valueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
              </div>
              <div appNGError [elemControl]="formAddEditSituation.controls.nameTag" *ngIf="situation.has_tag">
                <div [hidden]="true" class="col-md-4" [ngClass]="{ 'has-error': situation.name_tag === situation.value_tag }">
                  <label class="sidebar_label">{{ trans.trans.nameTag.value }} </label>
                  <input type="text" class="form-control" [(ngModel)]="situation.name_tag" name="nameTag" ret-data="Name Tag" [required]="situation.has_tag" />
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="tag-input-container">
                <label class="sidebar_label">{{ trans.trans.createCustomBlockTag.value }}</label>
                <br />
                <p-inputSwitch 
                  ngDefaultControl 
                  appNGError 
                  [elemControl]="formAddEditSituation.controls.hasBlockTag" 
                  [(ngModel)]="situation.has_block_tag" 
                  (onChange)="generateBlockTag($event)" 
                  name="hasBlockTag"
                  class="tag-switch"
                ></p-inputSwitch>
              </div>
              <!-- Situation Value Block Tag -->
              <div
                *ngIf="situation.has_block_tag"
                appNGError
                [elemControl]="formAddEditSituation.controls.blockValueTag"
                [ngClass]="{ 'field-error': situation.block_name_tag === situation.block_value_tag }"
              >
                <label class="sidebar_label labelsRequired">{{ trans.trans.situationValueTag.value }}</label>
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.customBlockTooltip.value">info</span>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="situation.block_value_tag"
                  name="blockValueTag"
                  ret-data="Block Value Tag"
                  maxlength="255"
                  appSpaceToUnderscore
                  appSpecialCharacterValidator
                  [required]="situation.has_block_tag"
                />
                <div *ngIf="situation.has_block_tag" class="hint light-h tag-div"
                  >[{{ situation.block_value_tag }}{{ trans.trans.beginBlock.value }}]
                    [{{ situation.block_value_tag }}_sub1;block=begin]
                    [{{ situation.block_value_tag }}_sub1.name]
                    [{{ situation.block_value_tag }}_sub1.description]
                    [{{ situation.block_value_tag }}_sub1;block=end]
                    [{{ situation.block_value_tag }};block=end]
                </div>
                <span class="input-error-warn" *ngIf="formAddEditSituation.controls.blockValueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
              </div>
              <div class="row" appNGError [elemControl]="formAddEditSituation.controls.blockNameTag" *ngIf="situation.has_block_tag">
                <div [hidden]="true" class="col-md-4" [ngClass]="{ 'has-error': situation.block_name_tag === situation.block_value_tag }">
                  <label class="sidebar_label">{{ trans.trans.blockNameTag.value }} </label>
                  <input type="text" class="form-control" [(ngModel)]="situation.block_name_tag" name="blockNameTag" ret-data="Block Name Tag" [required]="situation.has_block_tag" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="situation_tree && situation_tree.length">
          <div class="col-xs-12">
            <p-treeTable [value]="situation_tree" styleClass="keywest_table">
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ trans.trans.name.value }}</th>
                  <th style="width: 100px"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr [ttRow]="rowNode">
                  <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{ rowData.name }}

                    <span *ngIf="rowData.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.description" matTooltipPosition="above">
                      info
                    </span>
                  </td>
                  <td>
                    <span class="keywest_icon_group">
                      <span><i (click)="funcToggleAddEditChild(rowData)" [matTooltip]="trans.trans.ch_add.value" matTooltipPosition="above" class="clickable fa fa-plus"></i></span>
                      <span><i (click)="funcEditChild(rowData)" [matTooltip]="trans.trans.ch_edit.value" matTooltipPosition="above" class="clickable fa fa-pencil"></i></span>
                      <span>
                        <app-are-you-sure
                          style="cursor: pointer"
                          [matTooltip]="trans.trans.ch_delete.value"
                          matTooltipPosition="above"
                          [message]="trans.trans.are_you_sure.value"
                          text=""
                          icon="fieldset_icon fa fa-trash"
                          (callbackYes)="funcDeleteChild(rowData)"
                        ></app-are-you-sure>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr *ngIf="rowData.expanded">
                  <td colspan="2">
                    <form name="formAddEditChild" #formAddEditChild="ngForm">
                      <div class="keywest_subpanel">
                        <div class="row row_nomargin">
                          <div class="col-xs-12">
                            <div class="sidebar_header">
                              {{ modeChild === 'add' ? 'Add' : 'Edit' }} Child <i (click)="rowData.expanded = !rowData.expanded" class="fa fa-times clickable"></i>
                            </div>
                          </div>
                        </div>

                        <div class="row row_nomargin">
                          <div class="col-xs-12" appNGError [elemControl]="formAddEditChild.controls.name">
                            <label class="sidebar_label"
                              >{{ trans.trans.name.value }}
                              <app-editTranslation
                                *ngIf="showTranslate"
                                [component]="'ae_situations'"
                                [isTranslationV2]="true"
                                [transObj]="trans.trans.name"
                                (callback)="getTranslations()"
                              ></app-editTranslation>
                            </label>
                            <input type="text" name="name" [(ngModel)]="rowData.childName" class="form-control" required />
                          </div>
                        </div>

                        <div class="row row_nomargin">
                          <div class="col-xs-12" appNGError [elemControl]="formAddEditChild.controls.descrition">
                            <label class="sidebar_label"
                              >{{ trans.trans.description.value }}
                              <app-editTranslation
                                *ngIf="showTranslate"
                                [component]="'ae_situations'"
                                [isTranslationV2]="true"
                                [transObj]="trans.trans.description"
                                (callback)="getTranslations()"
                              ></app-editTranslation>
                            </label>
                            <textarea name="description" [(ngModel)]="rowData.childDescription" rows="4" class="form-control keywest_textarea"></textarea>
                          </div>
                        </div>

                        <div class="row row_nomargin" *ngIf="rowData.can_set_child_element">
                          <div class="col-xs-12">
                            <label>Type</label><br />
                            <input type="radio" name="type" value="0" [(ngModel)]="rowData.child_element_type_id" /> Same as main situation<br />
                            <input type="radio" name="type" value="1" [(ngModel)]="rowData.child_element_type_id" /> {{ trans.trans.singleSelectList.value }}<br />
                            <input type="radio" name="type" value="2" [(ngModel)]="rowData.child_element_type_id" /> {{ trans.trans.multiSelectList.value }}<br />
                            <input type="radio" name="type" value="3" [(ngModel)]="rowData.child_element_type_id" /> {{ trans.trans.singleSelectDropdown.value }}<br />
                            <input type="radio" name="type" value="4" [(ngModel)]="rowData.child_element_type_id" /> {{ trans.trans.multiSelectDropdown.value }}
                          </div>
                        </div>

                        <div class="subpanel_btn_container">
                          <div class="row row_nomargin">
                            <div class="col-xs-12">
                              <app-btn-save
                                *ngIf="rowData.expanded && modeChild === 'add'"
                                [form]="formAddEditChild"
                                (callback)="funcAddChild(rowData)"
                                [text]="trans.trans.add.value"
                                class="btn btn_customizable pull-right"
                              ></app-btn-save>
                              <app-btn-save
                                *ngIf="rowData.expanded && modeChild === 'edit'"
                                [form]="formAddEditChild"
                                (callback)="funcUpdateChild(rowData)"
                                [text]="trans.trans.update.value"
                                class="btn btn_customizable pull-right"
                              ></app-btn-save>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              </ng-template>
            </p-treeTable>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="btn_container">
    <div class="col-xs-12">
      <app-btn-save
        *ngIf="mode === 'add'"
        [form]="formAddEditSituation"
        (callback)="funcSaveSituation()"
        [text]="trans.trans.save.value"
        class="btn btn_customizable pull-right"
      ></app-btn-save>
      <app-btn-save
        *ngIf="mode === 'edit'"
        [form]="formAddEditSituation"
        (callback)="funcUpdateSituation()"
        [text]="trans.trans.update.value"
        class="btn btn_customizable pull-right"
      ></app-btn-save>
      <app-are-you-sure
        matTooltipPosition="above"
        [message]="trans.trans.are_you_sure.value"
        class="btn cancel-confirm pull-right marg-h"
        icon=""
        [text]="trans.trans.back.value"
        (callbackYes)="close()"
      ></app-are-you-sure>
    </div>
  </div>
</div>
