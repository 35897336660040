import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { WorkflowQuestionAnswers } from '@data/services/workflow/models/workflow-question.interface';
import { GuidedDiscoveryElementTypes } from '@data/services/workflow/models/workflow.interface';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkflowQuestionsTranslation } from './workflow-questions.translation';

@Component({
  selector: 'app-workflow-questions',
  templateUrl: 'workflow-questions.component.html',
  styleUrls: ['workflow-questions.component.scss'],
})

export class WorkflowQuestionsComponent implements OnInit {
  @Input() isPreview = false;
  @Input() questions: WorkflowQuestionAnswers[];
  elementTypes = GuidedDiscoveryElementTypes;
  accountId: string;
  showTranslate = false;
  ngUnsubscribe = new Subject();
  style2022$: Observable<boolean>;
  noSelected = {};

  constructor(
    public trans: WorkflowQuestionsTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private styleService: StyleService,
  ) { }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.accountId = sessionStorage.getItem('aid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }
}
