<p-table styleClass="keywest_table" tableStyleClass="keywest_table" [value]="relationships?.scratchpads" responsiveLayout="scroll">
  <ng-template pTemplate="header">
    <tr>
      <th>{{ trans.trans.isAScratchPadFactorFor.value }}</th>
      <th>{{ trans.trans.type.value }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-scratchpad>
    <tr>
      <td>{{ scratchpad.factor_name }}</td>
      <td>{{ scratchpad.type }}</td>
    </tr>
  </ng-template>
</p-table>
<app-no-records class="no-records" *ngIf="!relationships || !relationships.scratchpads || relationships.scratchpads.length === 0">{{ trans.trans.noRecords.value }}</app-no-records>
<p-table styleClass="keywest_table" tableStyleClass="keywest_table" [value]="relationships?.factor_scales" responsiveLayout="scroll">
  <ng-template class="margin" pTemplate="header">
    <tr>
      <th>{{ trans.trans.scales.value }}</th>
      <th>{{ trans.trans.scalesHow.value }}</th>
      <th>{{ trans.trans.value.value | titlecase }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-factorScale>
    <tr>
      <td>{{ factorScale.name }}</td>
      <td>{{ factorScale.scales_how }}</td>
      <td>{{ factorScale.ratio }}</td>
    </tr>
  </ng-template>
</p-table>
<app-no-records class="no-records margin" *ngIf="!relationships || !relationships.factor_scales || relationships.factor_scales.length === 0">{{
  trans.trans.noRecords.value
}}</app-no-records>
<p-table styleClass="keywest_table" tableStyleClass="keywest_table" [value]="relationships?.connected_goals" responsiveLayout="scroll">
  <ng-template class="margin" pTemplate="header">
    <tr>
      <th>{{ trans.trans.linkedToGoal.value }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-linkedGoal>
    <tr>
      <td>{{ linkedGoal.factor_name }}</td>
    </tr>
  </ng-template>
</p-table>
<app-no-records class="no-records margin" *ngIf="!relationships || !relationships.connected_goals || relationships.connected_goals.length === 0">{{
  trans.trans.noRecords.value
}}</app-no-records>
