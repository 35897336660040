import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-input-wrapper',
  templateUrl: './error-input-wrapper.component.html',
  styleUrls: ['./error-input-wrapper.component.scss'],
})
export class ErrorInputWrapperComponent {
  @Input() errorMessage: string;
  @Input() showOnTouched: boolean = true;
  @Input() showFormGroup: boolean = true;
}
