import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiServiceService } from '../../api-service.service';

@Component({
  selector: 'app-solution-category',
  templateUrl: './solution-category.component.html',
  styleUrls: ['./solution-category.component.scss'],
})
export class SolutionCategoryComponent implements OnInit, OnDestroy {
  fullImagePath: string;
  hideLoder: boolean;
  catagoryName: string;
  searchCategory: string;

  @ViewChild('closePopup', { static: true }) closePopup: ElementRef;

  subscriptionreadSolutionCategories: Subscription;
  subscriptiondeleteSolutionCategories: Subscription;
  subscriptioncreateSolutionCategories: Subscription;

  constructor(private apiServiceService: ApiServiceService, private translateService: TranslateService, public trans: Translations) {
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }
  solutionCategory: any;
  ngOnInit() {
    const params = {
      noun: 'SolutionCategories',
      action: 'Read',
      user_id: sessionStorage.getItem('uid'),
    };
    this.hideLoder = false;
    this.subscriptionreadSolutionCategories = this.apiServiceService.readSolutionCategories(params).subscribe((result) => {
      this.solutionCategory = result.categories;
      this.hideLoder = true;
    });
  }

  ngOnDestroy() {
    if (this.subscriptionreadSolutionCategories) {
      this.subscriptionreadSolutionCategories.unsubscribe();
    }
    if (this.subscriptiondeleteSolutionCategories) {
      this.subscriptiondeleteSolutionCategories.unsubscribe();
    }
    if (this.subscriptioncreateSolutionCategories) {
      this.subscriptioncreateSolutionCategories.unsubscribe();
    }
  }

  deleteCategory(data: { [klass: string]: any }) {
    const params = {
      noun: 'SolutionCategory',
      action: 'Delete',
      user_id: sessionStorage.getItem('uid'),
      account_id: data.account_id,
      id: data.id,
    };
    if (confirm('Are you sure to delete ' + data.category + ' category ?')) {
      this.subscriptiondeleteSolutionCategories = this.apiServiceService.deleteSolutionCategories(params).subscribe(() => {
        this.ngOnInit();
      });
    }
  }
  addCategory(catName: string) {
    this.closePopup.nativeElement.click();
    const params = {
      noun: 'SolutionCategory',
      action: 'Create',
      user_id: sessionStorage.getItem('uid'),
      category: catName,
    };

    this.subscriptioncreateSolutionCategories = this.apiServiceService.createSolutionCategories(params).subscribe(() => {
      this.catagoryName = '';
      this.ngOnInit();
    });
  }
}
