<div id="wrapper" class="app_wrapper" style="background-color: #f5f5f5">
  <p-menu #menuDropdown appendTo="body" [popup]="true" [model]="profileMenu" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

  <div id="center" [ngClass]="aI.funcSetMainCenterClass()" class="center_no_padding bg" style="background-color: #f5f5f5">
    <div *ngIf="aI.isLoggedIn$" class="navbar">
      <div class="navbar-inner">
        <div class="topmenu-outer" [ngStyle]="{ background: aI.currentColor }" style="margin-left: 0px !important">
          <div class="top-menu">
            <ul class="nav navbar-nav navbar-left header-left">
              <span class="logo-text desktop">
                <i class="logo">
                  <img [src]="aI.companyLogo" [alt]="aI.companyLogo" style="display: block; height: 60px; width: auto; border: 1px solid transparent" />
                </i>
              </span>

              <span class="logo-text small">
                <i class="small-logo"><img [src]="aI.companyIcon" [alt]="aI.companyIcon" style="display: block; height: 60px; width: auto; border: 1px solid transparent" /></i>
              </span>
            </ul>
            <ul class="nav navbar-nav navbar-right header-right" #navRight *ngIf="aI.isLoggedIn$">
              <li class="dropdown">
                <a href="javascript:void(0)" class="dropdown-toggle" (click)="menuDropdown.toggle($event)">
                  <span class="user-name user">{{ aI.user }}<i class="fa fa-angle-down"></i></span>
                  <img
                    class="img-circle avatar"
                    *ngIf="aI.isLoggedIn$ && (aI.profileImagePath || aI.profilepicdefaultpath)"
                    [src]="aI.profileImagePath !== '' ? aI.profileImagePath : aI.profilepicdefaultpath"
                    width="40"
                    height="40"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container_interior" style="background-color: #f5f5f5">
      <div id="main-wrapper" class="route-container" style="padding: 0px !important; margin-bottom: 90px; margin-top: 0px">
        <alert *ngIf="aI.isLoggedIn$ || aI.tokenExpired || aI.redirectLogin"></alert>
        <notification></notification>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
