import { Injectable } from '@angular/core';

@Injectable()
export class ScbDashboardTranslations {
  public config: any = {
    component: 'scbDash',
    display_name: 'Company Brief Dashboard',
    description: "Company brief's in dashboard",
  };

  public trans: any = {
    SCBHeading: 'Recent Account Insights',
    scb_name: 'Name',
    scb_date: 'Date',
    scb_seemore: 'See More',
    noScbFound: 'No Data Found',
    notLicensedForValueInsights: 'Please wait as your Value Insights load.',
    add: 'Add',
  };
}
