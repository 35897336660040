import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { RSV2NavComponent } from './nav.component';
import { RepSimpleTranslations } from './nav.translation';

@NgModule({
  declarations: [RSV2NavComponent],
  imports: [MatTooltipModule, EditTranslationsModule, CommonModule, FormsModule],
  exports: [RSV2NavComponent],
  entryComponents: [RSV2NavComponent],
  providers: [RepSimpleTranslations],
})
export class RSV2NavModule {}
