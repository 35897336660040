import { Injectable } from '@angular/core';

@Injectable ()

export class BenefitsImplentationTranslations {

    public config: any = {
        component: 'benefitsComponents',
        display_name: 'Benefits',
        description: 'Benefits of a value prop section'
    };

    public trans: any = {
        notification_info: 'Please close all panels before re-ordering value categories',
        notification_success: 'Benefit order updated successfully',
        expand_all: 'Expand All',
        set_bulk_status: 'Set Bulk Status',
        collapse_all: 'Collapse All',
        active: 'Active',
        na: 'N/A',
        soft: 'Considered Soft',
        defined_sequence: 'Defined Sequence',
        notification_status: 'Benefit status updated Successfully',
        total_benefits_sequence: 'Total Benefits Sequence',
        success_delete: 'Successfully deleted your benefit',
        value_category: 'Value Category',
        functional_objectives: 'Functional Objectives',
        pain_points: 'Pain Points',
        capabilities: 'Capabilities',
        operational_objectives: 'Operational Objectives',
        something_went_wrong: 'Something went wrong: ',
        not_priority: 'Not a Priority',
        phasing_status: 'Phasing updated Successfully',
        notification_benefit_success: 'Benefits Updated Successfully',
        benefits_panel_title: "Benefits",
        back_to_benefits_list: "Back to benefits list",
        total: "Total",
        benefits_financial: " Benefits - Financial",
        benefits_non_financial: " Benefits - Non Financial",
        ch_renameValueCategory: 'Rename the value category',
    };
}