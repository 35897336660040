import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { RFactorsFormComponent } from './r_factors.component';
import { DefaultTranslations } from './r_factors.translation';

@NgModule({
  imports: [
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    CommonModule,
    FormsModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    EditTranslationsModule
  ],
  declarations: [RFactorsFormComponent],
  exports: [RFactorsFormComponent],
  providers: [DefaultTranslations, TranslationsV2Service],
})
export class RFactorsFormModule { }
