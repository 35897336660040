<div class="row" style="margin-bottom: 20px">
  <div [ngClass]="{ 'col-xs-6 padding-0-x': (style2022$ | async), 'col-xs-4': (style2022$ | async) }">
    <div class="input-group keywest_search">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input [(ngModel)]="txtSearch" type="text" placeholder="{{ trans.trans.search.value }}" class="form-control" />
      <span *ngIf="txtSearch !== ''" (click)="txtSearch = ''" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
    </div>
  </div>
</div>

<div class="row section_row" *ngFor="let faq of faQuestions | filter: txtSearch:'question'">
  <div class="row">
    <div class="col-xs-12">
      <i (click)="faq.expand = !faq.expand" *ngIf="!faq.expand" class="fa fa-chevron-right" style="margin-right: 10px"></i>
      <i (click)="faq.expand = !faq.expand" *ngIf="faq.expand" class="fa fa-chevron-down" style="margin-right: 10px"></i>
      <span (click)="faq.expand = !faq.expand" class="clickable section_row_text faq-title">{{ faq.question }}</span>
    </div>
  </div>

  <div class="row" *ngIf="faq.expand">
    <div class="col-xs-12" style="padding-left: 38px">
      <span class="quote_quote" style="font-style: normal" [innerHtml]="faq.answer"></span>
    </div>
  </div>
</div>

<div *ngIf="faqLoader" class="col-12 text-center">
  <img class="loader" src="{{ fullImagePath }}" />
</div>
