import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { MissionVpDashTranslations } from './mission.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-mission-statement',
	templateUrl: './mission.component.html'
})
export class MissionStatementComponent implements OnInit, OnDestroy {
	@Input() data: any;
	@Input() showHelp;
	@Input() showTranslate: boolean = false;
	ngUnsubscribe = new Subject();

	constructor(
		private valuePropService: ValuepropService,
		private notification: NotificationService,
		public trans: MissionVpDashTranslations,
		private translationService: TranslationsV2Service,
		private commonService: CommonService
	) {


	}

	ngOnInit() {
		  this.getTranslations();
		  this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		  });
		  this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		  });
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
	 });
	}

	refreshTranslation() {
		this.getTranslations();

	}

	btnUpdateMissionStatement() {
		let payload = {
			"mission_statement": this.data.mission_statement
		}
		this.valuePropService.updateMissionStatement(this.data.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
			if (response) {
				if (response.result && response.result.success) {
					if (response.result.message) {
						this.notification.success(response.result.message, false);
					}

				}
			}
			// this.valuePropService.refreshDashboard.next('refreshDashboard');
		})
	}
}