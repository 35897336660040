import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CommonService } from '@data/services/common/common.service';
import { Subject } from 'rxjs';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { AccountQuestion } from '@shared/models/account-question.model';

@Component({
	selector: 'app-vmware',
	templateUrl: './vmware.component.html',
	styleUrls: ['./vmware.component.scss']
})
export class VmwareComponent implements OnInit, OnDestroy {

	@Input() valuePropId: string;
	@Output() changeHeading = new EventEmitter();

	aid: number;
	questions: AccountQuestion[] = [];
	fullImagePath: string;
	questionsLoader = false;
	questionsSaveLoader = false;
	toggleDiscovery = false;

	suggestedProjects: Array<{id: string | number; name: string; select_status: number; checked: any}> = [];
	suggestedProjectsLoader = false;

  ngUnsubscribe$ = new Subject();

	constructor(
		private accountService: AccountService,
		private valuepropService: ValuepropService,
		private commonService: CommonService,
		private translateService: TranslateService,
		public trans: Translations
	) {

		this.fullImagePath = this.commonService.getLoaderImageUrl();
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit(): void {
		this.aid = parseInt(sessionStorage.getItem('aid'));
		this.readQuestions();
	}

	ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
	}

	readQuestions(): void {
		this.questionsLoader = true;
		this.accountService.readQuestions(this.aid).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
			this.questions = result.result.account_questions;
			this.questionsLoader = false;
		});
	}

	ngOnSubmit(): void {
		const payload = {
			'answers': []
		};

		this.questions.forEach(question => {
			payload['answers'].push({
				'account_question_id': question['id'],
				'val': question['value']
			});
		});

		this.questionsSaveLoader = true;
		this.valuepropService.saveAnswers(this.valuePropId, payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
			if (response.result) {
				this.toggleDiscovery = true;
				this.changeHeading.emit('Project Recommendation');
				this.getSuggestedProjects();
			}
			this.questionsSaveLoader = false;
		});
	}

	getStatusClass(status): string {
		if (status == 1) {
			return 'fa-circle rotate green';
		}
		else if (status == 2) {
			return 'fa-stop rotate yellow';
		}
		else if (status == 3) {
			return 'fa-square red';
		}
		else if (status == 4) {
			return 'fa-square red';
		}
	}

	getSuggestedProjects(): void {
		this.suggestedProjectsLoader = true;

		this.valuepropService.readProjectSuggestions(this.valuePropId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
			if (response.result && response.result.projects) {
				this.suggestedProjectsLoader = false;
				this.suggestedProjects = response.result.projects.map(project => {
					if (project['select_status'] == 1) {
						project['checked'] = true;
					}
					return project;
				});
			}
		});
	}

	suggestedProjectsSave(): void {
		const selectedProjects = this.suggestedProjects.filter(project => project['checked']);
		const selectedProjectsID = [];

		for (let i = 0; i < selectedProjects.length; i++) {
			const selectIds = selectedProjects[i]['select_ids'].split(',');
			for (let j = 0; j < selectIds.length; j++) {
				selectedProjectsID.push(selectIds[j]);
			}
		}

		this.valuepropService.guidedDiscoverySave.next(selectedProjectsID);
	}

	reset(): void {
		this.readQuestions();
		this.changeHeading.emit('Mandatory Discovery Questions');
		this.toggleDiscovery = false;
	}

}
