<div class="keywest_page_header">
  <div class="row">
    <div class="col-xs-6">
      <span><i (click)="onOpen()" *ngIf="!show" class="fa fa-chevron-right" style="margin-right: 10px"></i></span>
      <span><i (click)="onClose()" *ngIf="show" class="fa fa-chevron-down" style="margin-right: 10px"></i></span>
      <span (click)="!show ? onOpen() : onClose()" class="clickable title_no_float">{{ title }} </span>
    </div>
  </div>
</div>

<div *ngIf="show">
  <ng-content></ng-content>
</div>
