import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { AddCaseStudyModule } from 'app/value-mapv2/solution-detail/case-studies/add-casestudy/add-casestudy.module';
import { ModelCaseStudyDetailModule } from 'app/value-mapv2/solution-detail/case-studies/casestudy-detail/casestudy-detail.module';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { AECaseStudiesModule } from './ae_case_studies/ae_case_studies.module';
import { CaseStudiesComponent } from './case_studies.component';

const routes: Routes = [
  {
    path: 'case_study',
    component: CaseStudiesComponent,
  },
  {
    path: 'case_study/new',
    loadChildren: () => import('./ae_case_studies/ae_case_studies.module').then((m) => m.AECaseStudiesModule),
  },
  {
    path: 'case_study/:case_study_id/:is_account_solution',
    loadChildren: () => import('./ae_case_studies/ae_case_studies.module').then((m) => m.AECaseStudiesModule),
  },
];
@NgModule({
  imports: [
    AECaseStudiesModule,
    ModelCaseStudyDetailModule,
    AddCaseStudyModule,
    SidebarModule,
    MenuModule,
    ProgressBarModule,
    TableModule,
    MultiSelectModule,
    EditTranslationsModule,
    AreYouSureModule,
    MatTooltipModule,
    SelectButtonModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
  ],
  declarations: [CaseStudiesComponent],
  providers: [],
  exports: [CaseStudiesComponent],
  entryComponents: [CaseStudiesComponent],
})
export class CaseStudiesModule {}
