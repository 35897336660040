import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-solution',
  templateUrl: './add-solution.component.html',
  styleUrls: ['./add-solution.component.scss'],
})
export class AddSolutionComponent {
  constructor(private location: Location, public translate: TranslateService, private translateService: TranslateService, public trans: Translations) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  backClicked() {
    this.location.back();
  }
}
