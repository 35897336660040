<!-- Checkbox header -->
<div class="row validation-header-wrapper">
  <span class="validation-header dark-t">{{ t.checkMissing }}</span>
</div>
<!-- Checkbox Group -->
<div class="row check-group-wrapper">
  <div *ngFor="let validationOption of validationOptions" class="p-field-checkbox">
    <p-checkbox name="validationGroup" [value]="validationOption.key" [inputId]="validationOption.key" [(ngModel)]="selectedValidationOptions" (onChange)="onSelectionChange()">
    </p-checkbox>
    <label class="check-option-label" [for]="validationOption.key">{{ validationOption.value }}</label>
  </div>
</div>
<div class="accordion-wrapper">
  <p-accordion id="validation-accordion" [multiple]="true">
    <!-- Benefits With Duplicated Names -->
    <p-accordionTab [selected]="true" *ngIf="accordions?.duplicateNames && validations?.duplicate_names?.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ t.benefitsWithDupeNames }}</span>
        <span [matTooltip]="t.benefitsWithDupeNamesTT" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-benefit-validation-table [trans]="trans" [benefits]="validations?.duplicate_names" [validationCategory]="'duplicate_names'"></app-benefit-validation-table>
      </ng-template>
    </p-accordionTab>
    <!-- Benefits With Functional Objectives -->
    <p-accordionTab [selected]="true" *ngIf="accordions?.fuctionalObjectives && validations?.missing_functional_objectives.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ t.benefitMissingFOs }}</span>
        <span [matTooltip]="t.benefitMissingFOsTT" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-benefit-validation-table
          [trans]="trans"
          [benefits]="validations?.missing_functional_objectives"
          [validationCategory]="'missing_functional_objectives'"
        ></app-benefit-validation-table>
      </ng-template>
    </p-accordionTab>
    <!-- Benefits With Operational Objectives -->
    <p-accordionTab [selected]="true" *ngIf="accordions?.operationalObjectives && validations?.missing_operational_objectives.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ t.benefitMissingOOs }}</span>
        <span [matTooltip]="t.benefitMissingOOsTT" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-benefit-validation-table
          [trans]="trans"
          [benefits]="validations?.missing_operational_objectives"
          [validationCategory]="'missing_operational_objectives'"
        ></app-benefit-validation-table>
      </ng-template>
    </p-accordionTab>
    <!-- Benefits With Capabilities-->
    <p-accordionTab [selected]="true" *ngIf="accordions?.capabilities && validations?.missing_capabilities.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ t.benefitMissingCapabilities }}</span>
        <span [matTooltip]="t.benefitMissingCapabilitiesTT" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-benefit-validation-table [trans]="trans" [benefits]="validations?.missing_capabilities" [validationCategory]="'missing_capabilities'"></app-benefit-validation-table>
      </ng-template>
    </p-accordionTab>
    <!-- Benefits With Pain Points -->
    <p-accordionTab [selected]="true" *ngIf="accordions?.painPoints && validations?.missing_painpoints.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ t.benefitMissingPainPoints }}</span>
        <span [matTooltip]="t.benefitMissingPainPointsTT" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-benefit-validation-table [trans]="trans" [benefits]="validations?.missing_painpoints" [validationCategory]="'missing_painpoints'"></app-benefit-validation-table>
      </ng-template>
    </p-accordionTab>
    <!-- Benefits With Features -->
    <p-accordionTab [selected]="true" *ngIf="accordions?.features && validations?.missing_features.length">
      <ng-template pTemplate="header">
        <span class="validation-header dark-t">{{ t.benefitMissingFeatures }}</span>
        <span [matTooltip]="t.benefitMissingFeaturesTT" class="material-icons-outlined tooltip-custom info-icon light-tooltip"> info </span>
      </ng-template>
      <ng-template pTemplate="content">
        <!-- content -->
        <app-benefit-validation-table [trans]="trans" [benefits]="validations?.missing_features" [validationCategory]="'missing_features'"></app-benefit-validation-table>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
