import { WorkflowModel } from './../../../../data/services/workflow/models/workflow-question.interface';
import { EventEmitter, OnDestroy, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { takeUntil } from 'rxjs/operators';
import { GuidedModelTranslation } from './guided-model.translation';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-guided-model',
  templateUrl: './guided-model.component.html',
  styleUrls: ['./guided-model.component.scss'],
})
export class GuidedModelComponent implements OnInit, OnDestroy {
  @Input() valuePropId: number | string;
  @Output() finishedGuide = new EventEmitter();

  ngUnsubscribe$ = new Subject();
  showTranslate: boolean;
  modelSuggestions: WorkflowModel[] = [];
  previousModels: string[] = [];
  chosenModels: string[] = [];
  loadingSave = false;

  constructor(
    private workflowService: AccountWorkflowService,
    private commonService: CommonService,
    public trans: GuidedModelTranslation,
    private translationService: TranslationsV2Service
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getSolutions();
    this.getSelectedSolutions();
  }

  getSolutions(): void {
    this.workflowService
      .getModelRecs(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.modelSuggestions = [...res];
      });
  }

  getSelectedSolutions(): void {
    this.previousModels = [];
    this.workflowService
      .getSelectedSolutions(this.valuePropId.toString())
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.previousModels = res.map((solution) => solution.account_solution_id);
      });
  }

  addSolutions(replace: boolean = false): void {
    this.loadingSave = true;
    const solutionList = replace ? this.chosenModels : this.previousModels.concat(this.chosenModels);
    const payload = {
      account_solution_id: solutionList.join(','),
      value_prop_id: this.valuePropId,
      quantity: this.getQuantity(solutionList.length),
      delete_vp_solutions: 1,
    };
    this.workflowService
      .changeSolutions(this.valuePropId.toString(), payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.closeGuide();
      });
  }

  closeGuide(): void {
    this.loadingSave = false;
    this.finishedGuide.emit();
  }

  getQuantity(length: number): string {
    const quantity = [];
    for (let index = 0; index < length; index++) {
      quantity.push(1);
    }
    return quantity.join(',');
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
