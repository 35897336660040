import { Injectable } from '@angular/core';

@Injectable()
export class SurveyTemplateCreateDefaultTranslations {
	public config: any = {
		component: 'survey-template-create',
		display_name: 'Create Survey Template',
		description: 'Create survey template in profile'
    }
    public trans: any = {
        general_question: 'General Quesitons',
        survey_template_name: 'Survey Template Name',
        question_num: 'Enter your question',
        question_title: 'Question Title',
        what_kind_of_q: 'What Kind of Question is this?',
        example: 'Example',
        short_text: 'Short Text',
        long_text: 'Long Text',
        answer_type: 'Answer Type',
        no: 'No',
        yes: 'Yes',
        add_options: 'Add Options',
        option_label: 'Option Label',
        update_template: 'Update Template',
        create_template: 'Create Template',
    }
}