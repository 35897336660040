<div style="margin: 2.4rem;">
  <div class="row search-wrapper">
    <div class="col-xs-12 col-md-3">
      <div id="hypo-search" class="input-group keywest_search">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
        <input
          [(ngModel)]="strSearch"
          (keyup)="workflowTable.filter($any($event).target.value, 'name', 'contains')"
          type="text"
          placeholder="{{ trans.trans.search.value }}"
          class="form-control"
        />
        <span *ngIf="strSearch !== ''" (click)="strSearch = ''; workflowTable.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
      </div>
    </div>
    <div class="col-xs-12 col-md-9">
      <button
      class="btn btn_customizable pull-right marg-h"
      (click)="add()"
      [matTooltip]="trans.trans.addNew.value"
      matTooltipPosition="below"
    >
      <i class="fa fa-plus"></i>{{ trans.trans.addNew.value }}
    </button>
    </div>
    
  </div>
  
  <div class="row table-wrapper">
    <p-table
      #workflowTable
      #tableValuePosition
      [columns]="columns"
      [value]="workflowList"
      [paginator]="true"
      responsiveLayout="scroll"
      [rows]="pageLimit"
      styleClass="keywest_table"
      tableStyleClass="keywest_table"
      [rowsPerPageOptions]="[10, 25, 50, 100]"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" style="white-space: nowrap" [pSortableColumn]="col.field">
            {{ col.header }}
            <p-sortIcon></p-sortIcon>
          </th>
          <th>
            <!-- Toggle buttons -->
          </th>
          <th>
            <!-- actions -->
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-workflow let-rowIndex="rowIndex">
        <tr>
          <td><a class="link" (click)="edit(workflow)">{{ workflow.name }}</a></td>
          <td>{{ workflow.system_defined === '1' ? trans.trans.systemDefined.value : trans.trans.accountSpecific.value }}</td>
          <td *ngIf="workflow.all_users === '0'"><span *ngIf="workflow && workflow.user_groups && workflow.user_groups.length">{{ workflow.user_groups[0]?.name }} <span *ngIf="workflow.user_groups.length > 1">+ {{workflow.user_groups?.length - 1}}</span> </span></td>
          <td *ngIf="workflow.all_users === '1'">{{trans.trans.all.value}}</td>
          <td>{{ workflow.creator }}</td>
          <td>{{ workflow.created | date }}</td>
          <td>{{ workflow.active ? trans.trans.enabled.value : trans.trans.disabled.value }}</td>
          <td>
            <p-inputSwitch [(ngModel)]="workflow.active" (onChange)="statusChange(workflow)"></p-inputSwitch>
          </td>
          <td>
            <app-ellipsis-menu>
              <ul class="list-group">
                <!-- Edit -->
                <li class="list-group-item" (click)="edit(workflow)">
                  <span style="font-size: 13px; margin-right: 2px" class="material-icons"> edit </span>
                  {{ this.trans.trans.edit.value }}
                </li>
                <!-- Clone -->
                <li [hidden]="true" class="list-group-item" (click)="clone(workflow)">
                  <span style="font-size: 13px; margin-right: 2px" class="material-icons-outlined"> file_copy </span>{{ this.trans.trans.clone.value }}
                </li>
                <!-- Delete -->
                <li class="list-group-item" *ngIf="workflow.system_defined !== '1'">
                  <app-are-you-sure
                    style="color: red"
                    (callbackYes)="delete(workflow)"
                    [text]="trans.trans.delete.value"
                    icon="fa fa-trash"
                    [message]="trans.trans.areYouSure.value"
                  ></app-are-you-sure>
                </li>
              </ul>
            </app-ellipsis-menu>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

