import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2RValueCategoryTranslations {
  public config: any = {
    component: 'r_value_category',
    display_name: 'Models Value Category View',
    description: 'View for creating a value category'
  };

  public trans: any = {
    name: 'Name',
    description: 'Description'
  };
}
