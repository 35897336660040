import { Component, Input, forwardRef } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIChart } from 'primeng/chart';
@Component({
	selector: 'dl-chart',
	templateUrl: './chart.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DLChartComponent)
		}
	]
})

export class DLChartComponent extends UIChart {
	@Input() idd: any;
}