import { Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { DefaultTranslations } from './notes.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { Menu } from 'primeng/menu';
import { UserActionItem } from '@shared/models/user-action-item.model';
import { ValueProp } from '@shared/models/value-prop.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RowLimit } from 'app/_models/row-limit.model';
import { ColumnMeta } from '@shared/models/common.models';

interface Note {
	name: string;
	first_name?: string;
	last_name?: string;
	customer_facing: string | number | boolean;
	note: string;
	id?: string;
}

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
	@Input() showHelp: boolean;
	@Input() permissions: {[klass: string]: any};
	@Input() contextualHelp: any[];
	@Input() showTranslate = false;
	@Input() valueProp: ValueProp;
	@Input() tabInit: number;
	@Input() shareView: string;
	@Output() backCallback = new EventEmitter();
	@ViewChild('menu') menu: Menu;

	fullImagePath: string;
	image_url: string;
	notesLoader = false;
	notes: Note[] = [];
	note: Note = { name: "", customer_facing: "", note: "" }
	modalReference: NgbModalRef;
	pageLimit = 10;
	rowLimit: RowLimit[] = [];
	cols: ColumnMeta[] = [];
	showAddNote = false;
	locked = false;
	style2022 = false;
	colors: string[] = [];
	strSearch = '';
	isNewNote = false;
	searchTimeout;
	searchDebounceTime = 750;
	menuItems: UserActionItem[] = [];

	ngUnsubscribe: Subject<any> = new Subject();

	constructor(
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		private notificationService: NotificationService,
		public trans: DefaultTranslations,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {
		this.styleService.style2022.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isStyle2022 => this.style2022 = isStyle2022);
		this.colors = this.commonService.getChartColors().filter(color => color && color !== '');
		if (this.shareView == "share") {
			this.locked = !this.permissions.benefits_editable;
		}

		this.loadNotes();
		this.funcBuildPageLimit();
		this.cols = [
			{ field: "name", header: "Name" },
			{ field: "user_name", header: "User" },
			{ field: "customer_facing", header: "Customer Facing" },
			{ field: "created", header: "Created" },
			{ field: "notes", header: "Note" }
		]

		this.getTranslations();
		this.commonService.notifyChangeLanguage.subscribe(() => {
			this.getTranslations();
		})

		this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	abbreviateName(name: string) {
		let abb = '';
		if (name && name.length > 1 && name.includes(' ')) {
			const nameArray = name.split(' ');
			abb = nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase();
		}
		return abb.trim();
	}

	getTranslations() {
		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		})
	}

	setRowLimit(num) {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit() {
		this.rowLimit = [
			{ label: "10", icon: this.pageLimit == 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: "25", icon: this.pageLimit == 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: "50", icon: this.pageLimit == 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: "100", icon: this.pageLimit == 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		]
	}

	loadNotes() {
		let searchLimit = this.valueProp.id;
		this.notesLoader = true;
		this.valuepropService.getNotes(searchLimit).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			this.notes = [];
			if (response.result && response.result.notes) {
				if (parseInt(this.valueProp['share_role_type_id'], 2) == 4) {
					this.notes = response.result.notes.filter(note => note['customer_facing'] == '1');
				}
				else {
					this.notes = response.result.notes;
				}
			}
			this.notesLoader = false;
		});
	}

	openAddNotes() {
		this.isNewNote = true;
		this.note = {
			"name": "",
			"customer_facing": true,
			"note": ""
		};
		this.showAddNote = true;
	}

	processResponse(response) {
		if (response.result && (response.result.success !== false)) {
			this.loadNotes();
		}
		else if (response.result.success === false) {
			this.notificationService.error(response.result.message, false); // Params {message, islogout}
		}
		this.showAddNote = false;
		this.notesLoader = false;
	}

	funcBackCallback() {
		this.backCallback.emit("");
	}

	saveNote() {
		this.notesLoader = true;
		if (this.note.customer_facing == false) {
			this.note.customer_facing = 0;
		} else {
			this.note.customer_facing = 1;
		}

		// this.note.customer_facing = (this.shareView == 'share' ? 1 : 0);
    if (this.shareView === 'notes' || this.shareView === 'share') {
			this.note.customer_facing = 1;
		}

		if (this.note.id) {
			this.valuepropService.updateNote(this.valueProp.id, this.note).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				this.processResponse(response);
			})
		} else {
			this.valuepropService.saveNote(this.valueProp.id, this.note).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				this.processResponse(response);
			})
		}
	}

	openEditNote(note) {
		this.isNewNote = false;
		this.showAddNote = true;
		this.note = {
			"name": note.name,
			"customer_facing": note.customer_facing == "1",
			"note": note.note,
			"id": note.id
		};
	}

	deleteNote(noteId) {
		this.notesLoader = true;
		this.valuepropService.deleteNote(this.valueProp.id, noteId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.loadNotes();
				// this.ValuepropService.refreshDashboard.next('refreshDashboard');
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.notesLoader = false;
		});
	}

	searchNotes(event) {
		if (this.strSearch === '') {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => this.loadNotes, this.searchDebounceTime);
		} else if (event.key === 'Backspace') {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.loadNotes();
				this.notes = this.filterNotes();
			}, this.searchDebounceTime);
		} else {
			this.notes = this.filterNotes();
		}
	}

	private filterNotes() {
		const normSearch = this.strSearch.toLowerCase().trim();
		return this.notes.filter(note => note.name.toLowerCase().trim().includes(normSearch) ||
			note.first_name.toLowerCase().trim().includes(normSearch) ||
			note.last_name.toLowerCase().trim().includes(normSearch)
		);
	}
	funcBuildSubMenu(note, event) {
		this.menuItems = [
		  {
			label: this.trans.trans.delete.value,
			command: () => {
			  this.deleteNote(note.id);
			},
		  },
		];
		this.menu.toggle(event);
	  }
}