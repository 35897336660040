import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';

@Injectable()
export class FunctionalObjectivesService {
	service_url: string;

	constructor(private CommonService: CommonService) {
		this.service_url = this.CommonService.getServiceUrl();
	}

	public postFunctionalObjective(account_id, payload) {
		let endpoint = "account/functionalObjective/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getFunctionalObjectives(account_id) {
		let endpoint = "account/functionalObjectives/" + account_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public getFunctionalObjectivesSolution(account_solution_id) {
		let endpoint = "solutions/functionalObjectives/" + account_solution_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public putFunctionalObjective(account_id, payload) {
		let endpoint = "account/functionalObjective/" + account_id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public deleteFunctionalObjectives(account_id, account_functional_objective_id) {
		let endpoint = "account/functionalObjective/" + account_id + "/delete/" + account_functional_objective_id;
		return this.CommonService.deleteAPIService(endpoint);
	}
}