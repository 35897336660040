import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueTrackerBenefit } from '@shared/models/benefit-type.model';
import { Metric } from '@shared/models/value-prop.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaselineBenefitScratchpadTranslation } from './baseline-benefit-scratchpad.translation';

@Component({
  selector: 'app-baseline-benefit-scratchpad',
  templateUrl: './baseline-benefit-scratchpad.component.html',
  styleUrls: ['./baseline-benefit-scratchpad.component.scss'],
})
export class BaselineBenefitScratchpadComponent implements OnInit, OnDestroy {
  @Input() benefit: Metric;
  scratchpadData: any[] = [];
  loading = true;

  ngUnsubscribe = new Subject();
  constructor(public trans: BaselineBenefitScratchpadTranslation, private translationService: TranslationsV2Service, private commonService: CommonService) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.getFactorData(this.benefit);
      });
  }

  getFactorData(benefit: Metric | ValueTrackerBenefit) {
    let goalData;
    if ((benefit as Metric).connected_object_id?.result?.length) {
        goalData = benefit.connected_object_id.result[0];
        (benefit as Metric).calculated_value_fmt = goalData.calculated_value_fmt;
    } else {
        goalData = (benefit as ValueTrackerBenefit).connected_objects?.result;
    }
    if (goalData) {
        this.scratchpadData = [
            { name: benefit.driver_factor, value: benefit.driver_value_fmt },
            { name: goalData?.goal_factor_name, value: goalData?.goal_factor_value_fmt },
            { name: benefit.financial_factor, value: benefit.financial_value_fmt },
            { name: this.trans.trans.calculatedValue.value, value: benefit?.calculated_value_fmt, style: 'pull-right margin-15-x', valueStyle: 'bold-header-2022' },
        ];
    }
    this.loading = false;
  }
}
