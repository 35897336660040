<div>
  <span class="material-icons-outlined tooltip-custom help-icon" [ngbTooltip]="helpTooltip" placement="bottom-right auto" container="body">help</span>
  <span class="need-help">{{ trans.trans.needHelp.value }}</span>
</div>

<ng-template #helpTooltip>
  <div class="help-title">{{ trans.trans.tooltipActions.value }}</div>
  <div class="help-item-list">
    <div [class.selected]="i === stepIndex || (stepIndex === 9 && (i === 4 || i === 5))" *ngFor="let item of helpItems; index as i">{{ i + 1 }}) {{ trans.trans[item].value }}</div>
  </div>
</ng-template>
