import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { GoalFormModule } from '@shared_components/goal-form/goal-form.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ValuePropGoalsComponent } from './value-prop-goals.component';
import { ValuePropGoalGroupTranslations } from './value-prop-goals.translation';
import { UnitTypeModule } from '@shared/unit-type/unit-type.module';
import { ScratchPadModule } from '../benefits_v2/views/scratchpad/scratchpad.module';
import { ArrayToFormControlModule } from 'app/pipe/array-to-form-control/array-to-form-control.module';

@NgModule({
  declarations: [ValuePropGoalsComponent],
  imports: [
    EditTranslationsModule,
    CommonModule,
    FormsModule,
    ProgressBarModule,
    AccordionModule,
    TableModule,
    EllipsisMenuModule,
    AreYouSureModule,
    ReactiveFormsModule,
    SidebarModule,
    ErrorInputWrapperModule,
    NoRecordsModule,
    DropdownModule,
    GoalFormModule,
    ScratchPadModule,
    UnitTypeModule,
    ArrayToFormControlModule,
    ErrorInputWrapperModule,
  ],
  exports: [ValuePropGoalsComponent],
  providers: [GoalGroupsService, ValuePropGoalGroupTranslations],
})
export class ValuePropGoalsModule {}
