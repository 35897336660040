import { Injectable } from '@angular/core';

@Injectable()
export class RQuoteTranslations {
	public config: any = {
		component: 'readQuote',
		display_name: 'Case Study Quote View',
		description: 'View of a quote from a case study'
	};

	public trans: any = {
		viewQuote: 'View Testimonial',
		first_name: 'First Name',
		last_name: 'Last Name',
		testimonial: 'Testimonial',
		title: 'Title',
        edit_translations: 'Edit Translations'
	};
}
