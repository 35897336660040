import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Benefit } from '@shared/models/value-prop.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BenefitsCasestudyTranslations } from './benefits-casestudy.translation';

@Component({
  selector: 'app-benefits-casestudy',
  templateUrl: './benefits-casestudy.component.html',
  styleUrls: ['./benefits-casestudy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BenefitsCasestudyComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Output() valueChange = new EventEmitter();
  @Input() caseStudy: { [klass: string]: any };
  @Input() can_edit: boolean;
  @Input() isModal = true;

  ngUnsubscribe = new Subject();

  selectedBenefit: Benefit;
  valuePropId: number;
  showTranslate = false;
  sidebar_title = 'Tie to benefit';
  toggleAddEditBenefit = false;
  searchText = '';
  mode: 'add' | 'edit';
  loadingSave = false;

  constructor(
    private commonService: CommonService,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private valuerealizationService: ValuerealizationService,
    private translationService: TranslationsV2Service,
    public trans: BenefitsCasestudyTranslations
  ) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcAddEditBenefitToggle(): void {
    this.toggleAddEditBenefit = !this.toggleAddEditBenefit;
    this.mode = 'add';
    this.sidebar_title = 'Tie to benefit';
  }

  funcOpenView(benefit): void {
    this.toggleAddEditBenefit = true;

    this.selectedBenefit = benefit;
  }

  processdeleteBenefit(response): void {
    if (response.result && response.result.success) {
      this.notification.success('Benefit deleted successfully', false);
      this.valueChange.emit();
      this.resetValues();
    }
  }

  deleteBenefit(benefit): void {
    const payload = {
      company_id: benefit['company_id'],
      case_study_id: benefit['case_study_id'],
      benefits: [
        {
          case_study_benefit_id: benefit['id'],
        },
      ],
    };

    if (this.type === 'solution') {
      this.solutionService
        .deleteCaseStudyBenefit(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.processdeleteBenefit(response);
        });
    } else {
      this.valuerealizationService
        .deleteCaseStudyBenefit(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.processdeleteBenefit(response);
        });
    }
  }

  openEditBenefit(benefit): void {
    this.mode = 'edit';
    this.sidebar_title = 'Edit benefit';
    this.toggleAddEditBenefit = !this.toggleAddEditBenefit;
    this.selectedBenefit = benefit;
  }

  resetValues(): void {
    this.selectedBenefit = undefined;
  }

  funcCloseSidebar(change: boolean): void {
    this.toggleAddEditBenefit = false;
    if (change) {
      this.valueChange.emit();
    }
  }

  checkSelectedBenefitsTypeAndValue(selectedBenfitsList: QueryList<NgForm>): boolean {
    return selectedBenfitsList.reduce((acc, curr) => {
      if (curr.invalid) {
        (acc as boolean) = false;
      }
      return acc;
    }, true);
  }
}
