<p-dialog 
  position="top" 
  [header]="trans.trans.chooseWorkFlow.value" 
  [style]="{width: '31vw', maxHeight: '37vh'}" 
  [(visible)]="showStepSelection"
  [closable]="false"
  [modal]="true"
  [draggable]="false"
>
  <div class="process-wrapper">
    <div class="selection-wrapper" (click)="onWorkflowSelected(wf)" *ngFor="let wf of workflows; let i=index" [attr.data-heap]="'value-execution-add-a-new-value-proposition-choose-conversation-workflow-to-use-menu-' + wf.name.toLowerCase().split(' ').join('-')" [ngClass]="'value-execution-add-a-new-value-proposition-choose-conversation-workflow-to-use-menu-' + i">
      <div class="row selection-row">
        <div class="icon-wrapper vp-select-button-wrapper">
          <span class="material-icons-outlined selection-icon vp-select-icon">
            {{ wf.icon ? wf.icon : 'checklist_rtl' }}
          </span>
        </div>
        <div class="select-text-wrapper">
          <div class="title-text">
            {{ wf.name }}
          </div>
          <div class="note-text">
            {{ wf.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
