<div class="review-period-grid" *ngIf="!editingBr; else editHeader">
  {{ trans.trans.reviewPeriod.value }}

  <p-dropdown
    [panelStyleClass]="'dropdown-item-font'"
    name="asset"
    optionLabel="name"
    class="keywest_dropdown dropdown-theme"
    appendTo="body"
    [options]="businessReviews"
    [filter]="true"
    filterBy="name"
    [autoDisplayFirst]="false"
    [(ngModel)]="selectedBr"
    [resetFilterOnHide]="true"
    [placeholder]="trans.trans.chooseAReviewPeriod.value"
  ></p-dropdown>
</div>

<ng-template #editHeader>
  <div class="review-period-grid edit-mode">
    <span>{{ trans.trans.editingReviewPeriod.value }}:</span>
    <span>{{ editingBr.name }}</span>
  </div>
</ng-template>

<div class="notes-inner">
  <div *ngIf="businessReviewService.canEdit | async" class="note-textarea">
    <app-error-input-wrapper [errorMessage]="(notesErrorMessage$ | async)?.get('noteControl')">
      <textarea rows="5" cols="30" [formControl]="noteControl" pInputTextarea [autoResize]="true" [placeholder]="trans.trans.addNewNote.value"></textarea>
    </app-error-input-wrapper>
    <div class="flex-jend">
      <ng-container *ngIf="!editingBr; else editControls">
        <button class="btn cancel-confirm marg-h" (click)="back()">
          {{ trans.trans.back.value }}
        </button>
        <button
          class="btn btn_customizable"
          pButton
          [loading]="loadingNoteSave"
          [disabled]="noteControl.invalid || !selectedBr || !noteControl.value || noteControl.value.length === 0"
          (click)="addNote()"
        >
          {{ trans.trans.addNote.value }}
        </button>
      </ng-container>
      <ng-template #editControls>
        <button class="btn btn_customizable cancel marg-h" (click)="cancelEdit()">
          {{ trans.trans.cancel.value }}
        </button>
        <button
          class="btn btn_customizable"
          pButton
          [loading]="loadingNoteSave"
          [disabled]="noteControl.invalid || !noteControl.value || noteControl.value.length === 0"
          (click)="editNote()"
        >
          {{ trans.trans.save.value }}
        </button>
      </ng-template>
    </div>
  </div>
</div>

<app-business-review-notes-table [valuePropId]="valuePropId" [newNote]="newNote" (editNote)="setEditNote($event)"></app-business-review-notes-table>
