import { NgModule } from '@angular/core';

import { BenefitsCasestudyComponent } from "./benefits-casestudy.component";
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';
 
import { SidebarModule } from 'primeng/sidebar';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { AEModelBenefitsCaseStudyModule } from './ae_benefits-casestudy/ae_benefits-casestudy.module';
import { RModelBenefitsCaseStudyModule } from './r_benefits-casestudy/r_benefits-casestudy.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsCasestudyTranslations } from './benefits-casestudy.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';


@NgModule({
	declarations: [
		BenefitsCasestudyComponent,
	],
	imports: [
		ApplicationPipesModule,
		SidebarModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		TabViewModule,
		DLNGErrorDirectiveModule,
		AEModelBenefitsCaseStudyModule,
		RModelBenefitsCaseStudyModule,
		NGFormSaveButtonModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		BenefitsCasestudyTranslations
	],
	entryComponents: [
		BenefitsCasestudyComponent,
	],
	exports: [BenefitsCasestudyComponent]
})
export class ModelBenefitsCaseStudyModule { }