<div *ngIf="view === 'interstitial'">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>

<div *ngIf="view !== 'interstitial'">
  <app-value-prop-goals *ngIf="view === 'goals' && valueProp?.id" [valuePropId]="valueProp.id" [canEdit]="valueProp.vp_can_edit"> </app-value-prop-goals>

  <app-competitive-tco *ngIf="view === 'competitive-tco' && valueProp && valueProp.id && valueProp.id !== null" [valueProp]="valueProp"></app-competitive-tco>
  <app-notes
    [showTranslate]="showTranslate"
    shareView="simple"
    [showHelp]="showHelp"
    *ngIf="view === 'notes' && valueProp && valueProp.id && valueProp.id !== null"
    [contextualHelp]="contextualHelp"
    [valueProp]="valueProp"
  ></app-notes>
  <app-log
    [showTranslate]="showTranslate"
    [showHelp]="showHelp"
    *ngIf="view === 'log' && valueProp && valueProp.id && valueProp.id !== null"
    [contextualHelp]="contextualHelp"
    [valueProp]="valueProp"
  ></app-log>
  <app-score-card
    [showTranslate]="showTranslate"
    [showHelp]="showHelp"
    *ngIf="view === 'scorecard' && valueProp && valueProp.id && valueProp.id !== null"
    [contextualHelp]="contextualHelp"
    [valueProp]="valueProp"
  ></app-score-card>
  <app-share-v2 [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="view === 'share' && valueProp && valueProp.id && valueProp.id !== null" [data]="valueProp">
  </app-share-v2>
  <app-assumptions-fastfill
    [showTranslate]="showTranslate"
    [showHelp]="showHelp"
    [contextualHelp]="contextualHelp"
    *ngIf="view === 'fastfill'"
    (callbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
    [valueProp]="valueProp"
  ></app-assumptions-fastfill>
  <app-explained (callbackNoData)="view = 'account'" *ngIf="view === 'explained'"></app-explained>
  <div style="margin-top: 20px">
    <app-account *ngIf="view === 'account' && valueProp" [companyId]="valueProp.id" [valueProp]="valueProp" [showTranslate]="showTranslate" [showHelp]="showHelp"></app-account>

    <app-models [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="view === 'models' && valueProp" [valueProp]="valueProp"></app-models>
    <app-situations-shared
      [showTranslate]="showTranslate"
      [showEditSituations]="true"
      [canEdit]="canEdit"
      *ngIf="view === 'situations' && valueProp"
      [valueProp]="valueProp"
      (reloadVP)="reloadDashboard()"
    ></app-situations-shared>
    <app-advanced [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="view === 'advanced' && valueProp" [valueProp]="valueProp"></app-advanced>
  </div>
  <app-maturity *ngIf="view === 'maturity' && valueProp" [valueProp]="valueProp"></app-maturity>

  <app-benefits-v2 *ngIf="view === 'benefits'" [mini]="false" [showTranslate]="showTranslate" [valueProp]="valueProp" [showHelp]="showHelp"></app-benefits-v2>
  <app-benefit-detail
    *ngIf="view === 'benefit_detail'"
    [selectedBenefit]="selectedBenefit"
    [data]="selectedBenefit"
    [data]="selectedBenefit"
    [valueProp]="valueProp"
    (closeBenefitDetail)="view = 'benefits'"
  >
  </app-benefit-detail>

  <div *ngIf="view === 'addbenefit' && solutionId && modelsV2" class="models_panel" style="margin-bottom: 20px">
    <app-ae-benefits [solutionId]="solutionId" [can_edit]="true" [isOnTheFlyBenefit]="true" [valuePropId]="valueProp.id" (callback)="view = 'benefits'"></app-ae-benefits>
  </div>
  <app-costs-valueprop [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="view === 'costs' && valueProp" [valueProp]="valueProp"></app-costs-valueprop>
  <app-deployment-schedule [showHelp]="showHelp" *ngIf="view === 'deploymentschedule' && valueProp" [data]="valueProp"></app-deployment-schedule>

  <app-scalers
    [showTranslate]="showTranslate"
    [showHelp]="showHelp"
    (callbackExplained)="funcGotoExplain($event)"
    (callbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
    *ngIf="view === 'scalers' && valueProp"
    [data]="valueProp"
  ></app-scalers>

  <app-assumptions
    [showTranslate]="showTranslate"
    [showHelp]="showHelp"
    *ngIf="view === 'allassumptions'"
    (callbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
    [valueProp]="valueProp"
  ></app-assumptions>
  <app-mission-statement [showHelp]="showHelp" *ngIf="view === 'mission' && valueProp" [data]="valueProp"></app-mission-statement>
  <app-initiatives [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="view === 'initiatives' && valueProp" [valueProp]="valueProp"></app-initiatives>

  <app-assumptions [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="view === 'keyassumptions'" [valueProp]="valueProp" [keyAssumptions]="true"></app-assumptions>

  <app-discovery-questions
    [menu]="menu"
    [showTranslate]="showTranslate"
    *ngIf="view === 'questions' && valueProp"
    [valuePropId]="valuePropId"
    [valueProp]="valueProp"
    [isSidebar]="false"
    [showAllQuestions]="true"
  ></app-discovery-questions>

  <app-problem [valueProp]="valueProp" *ngIf="view === 'problem' && valueProp"></app-problem>
  <app-solution [valueProp]="valueProp" *ngIf="view === 'solution' && valueProp"></app-solution>

  <app-vpdashboard-assets-prompters [valuePropId]="valuePropId" [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="view === 'prompter' && valueProp">
  </app-vpdashboard-assets-prompters>
</div>
