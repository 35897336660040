<div>
	<div class="row preview-header" >{{trans.trans.emptyTable.value}}</div>
	<div class="row" style="padding: 10px;">
		<div class="col-xs-6">
			<div class="input-group keywest_search">
				<span class="input-group-addon"><i class="fa fa-search"></i></span>
				<input 
					[(ngModel)]="strSearch" 
					type="text" 
					placeholder="Search" 
					(keyup)="funcSearch()" 
					style="padding-top: 6px;" 
					(keydown.enter)="$event.preventDefault()"
				/>
				<span *ngIf="strSearch !== ''" (click)="strSearch = ''" class="input-group-addon clickable">
					<i class="fa fa-times"></i>
				</span>
			</div>
		</div>
	</div>
	<div class="row" style="padding: 10px;">
		<div *ngIf="loading">
			<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
		</div>
		<p-table [value]="rollup" [columns]="cols">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th 
						*ngFor="let col of columns" 
						[pSortableColumn]="col.field"
						[width]="col.width"
					>
						{{ col.header }}
						<p-sortIcon [field]="col.field"></p-sortIcon>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td *ngIf="rowData.download_link !== undefined"><a [href]="rowData.download_link" class="link" target="_blank">{{ rowData.name }}</a></td>
					<td *ngIf="rowData.download_link == undefined">{{ rowData.name }}</td>
					<td style="text-align: center;">{{ rowData.term }}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>