<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{ trans.trans.share_tracker.value }}
	</div>
	<div class='sidebar_container'>
		<div class='keywest_panel'>
			<div class='row'>
				<div class="col-xs-6">
					<label class="sidebar_label">{{ trans.trans.employee.value }}</label>
					<p-dropdown appendTo="body" [options]="userList" [autoDisplayFirst]='false' [filter]="true" optionLabel="name" [(ngModel)]="selectedUser" class='keywest_dropdown pull-right'></p-dropdown>
				</div>

				<div class="col-xs-6">
					<label class="sidebar_label">{{ trans.trans.role.value }}</label>
					<p-dropdown appendTo="body" [options]="roleList" [autoDisplayFirst]='false' [filter]="true" optionLabel="name" [(ngModel)]="selectedRole" class='keywest_dropdown pull-right'></p-dropdown>
				</div>
			</div>
			<div class='row'>
				<div class='col-xs-12'>
					<a (click)='postShare()' class='btn btn_customizable pull-right marg-v'>{{ trans.trans.share.value }}</a>
				</div>
			</div>
		</div>

		<div class='keywest_panel' style='margin-top: 20px;'>
			<div class='row'>
				<div class="col-xs-12">
					<label class="sidebar_label">{{ trans.trans.currently_shared_with.value }}</label>
					<p-table [value]="shareList" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th>{{ trans.trans.name.value }}</th>
								<th>{{ trans.trans.role.value }}</th>
								<th></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-rowData>
							<tr>
								<td>{{ rowData.user_name }}</td>
								<td>{{ rowData.share_role }}</td>
								<td>
									<span class='keywest_icon_group pull-right'>
										<span>
											<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteShare(rowData)'></app-are-you-sure>
										</span>
									</span>
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
		</div>
	</div>
	<div class='btn_container'>
		<div class="col-md-12">
			<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
		</div>
	</div>
</div>