import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartJSMetaData, PrimengChartData } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';
import { ChartPoint } from 'chart.js';

@Component({
  selector: 'app-tracker-line',
  templateUrl: 'tracker-line.component.html',
})
export class TrackerLineComponent implements OnInit {
  @Input() data: PrimengChartData;
  @Input() chartColors: string[];
  @Input() currency: string;

  @Output() noteCallback = new EventEmitter();

  options: CustomChartOptions;
  dataLogPoint = '';
  toggleLogPoint = false;
  logPointData: {
    label: string;
    data: number | null | undefined | number[] | ChartPoint | undefined;
  };

  ngOnInit(): void {
    this.buildData();
  }

  buildData(): void {
    const that = this;
    this.options = {
      responsive: true,
      onClick: function (_evnt, arr: ChartJSMetaData[]) {
        if (arr.length > 0) {
          const chart = arr[0]._chart;
          const elem = chart.getElementAtEvent(event) as unknown as ChartJSMetaData; // casting due to chartjs being not specific enough, returning only [{}]
          const dataSetIndex = elem[0]._datasetIndex;
          const index = elem[0]._index;
          const data = elem[0]._chart.data;
          const clickedData = data.datasets[dataSetIndex].data[index];
          const clickedDataLabel = data.labels[index];
          that.logPointData = {
            label: clickedDataLabel,
            data: clickedData,
          };
        }
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Months',
            },
            gridLines: {
              drawOnChartArea: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (label) => {
                const num = Number(label).toLocaleString('en');
                return this.currency ? this.currency + ' ' + num : num;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltip) {
            const num = Number(tooltip.yLabel).toLocaleString('en');
            return that.currency ? that.currency + ' ' + num : num;
          },
        },
      },
    };
  }

  saveNote(elem: string) {
    this.toggleLogPoint = false;
    this.noteCallback.emit({ data: this.logPointData, note: elem });
  }
}
