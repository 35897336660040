<div class="row" *ngIf="benefitSurveyTableColumns.length">
  <div class="col-xs-12">
    <p-table
      #dt2
      dataKey="formula"
      [columns]="benefitSurveyTableColumns"
      [value]="benefitSurveyTable"
      [resizableColumns]="true"
      [styleClass]="'keywest_table'"
      [tableStyleClass]="'keywest_table'"
      rowExpandMode="single"
    >
      <ng-template pTemplate="header" let-columns>
        <tr class="not-hover">
          <th
            *ngFor="let col of columns"
            [ngStyle]="{
              'white-space': 'nowrap',
              'text-align': col.field === 'formula' ? 'left' : 'centered',
              'width': col.width ? col.width : 'inherit',
              'min-width': col.minwidth ? col.minwidth : 'inherit',
              'font-weight': col.field === 'formula' ? '700' : 'normal',
              'font-size': col.field === 'formula' ? '16px' : '13px'
            }"
          >
            <div class="flex-aic" *ngIf="col.field !== 'formula'; else regularHeader">
              <button
                *ngIf="col.type === 'carouselPrev' && businessReviews.length > 3"
                [disabled]="businessReviewIndex === 0 || scratchPadEditIndex"
                (click)="prevReview()"
                pButton
                pRipple
                type="button"
                icon="fa fa-caret-left"
                class="p-button-rounded p-button-text"
              ></button>
              <div [ngStyle]="{ 'position': !col.type ? 'relative' : 'static', 'bottom': !col.type ? '-2px' : 'inherit', 'max-width': !col.type ? '120px' : '79px' }">
                <div ticker>{{ col.header }}</div>
              </div>
              <button
                *ngIf="col.type === 'carouselNext' && businessReviews.length > 3"
                [disabled]="businessReviewIndex >= businessReviews.length - 3 || scratchPadEditIndex"
                (click)="nextReview()"
                pButton
                pRipple
                type="button"
                icon="fa fa-caret-right"
                class="p-button-rounded p-button-text"
              ></button>
            </div>
            <ng-template #regularHeader>
              {{ col.header }}
            </ng-template>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
        <tr
          *ngIf="rowData.formula !== 'varianceToDate'; else variance"
          [ngClass]="{ 'final-total': rowData.type === 'finalTotal', 'second-header': rowData.type === 'secondHeader' }"
          class="not-hover"
        >
          <td
            *ngFor="let col of columns; let index = index"
            [ngStyle]="{
              'text-align': col.style,
              'max-width': col.field === 'formula' ? '260px' : col.field === 'calc' ? '40px' : 'none',
              'min-width': col.field === 'formula' ? '260px' : col.field === 'calc' ? '40px' : 'none',
              'white-space': col.field === 'formula' ? 'normal' : 'nowrap',
              'font-size': rowData.type === 'secondHeader' ? '16px' : '13px',
              'font-weight': rowData.type === 'secondHeader' ? '700' : '400'
            }"
          >
            <span
              *ngIf="(!col.editable || !rowData.editable) && col.type !== 'improvement' && index > 0"
              [innerHTML]="trans.trans[rowData[col.field]]?.value ?? (benefitUpdated !== null && benefitUpdated <= index && rowIndex > 3 && index > 1 ? '---' : rowData[col.field])"
            ></span>
            <ng-container *ngIf="index === 0 && trans.trans[rowData[col.field]]?.value">
              <ng-container *ngIf="rowData[col.field] === 'valueAchievedToDate'; else nobold">
                <span>{{ trans.trans[rowData[col.field]]?.value }}</span>
              </ng-container>
              <ng-template #nobold>
                {{ trans.trans[rowData[col.field]]?.value }}
              </ng-template>
            </ng-container>
            <ng-container *ngIf="index === 0 && !trans.trans[rowData[col.field]]?.value && !(factorNameFormArray | arrayToFormControl: rowIndex - 1:'editing')?.value"
              >{{ rowData[col.field] }}
              <ng-container *ngIf="rowData?.type === 'improvement'">{{ ' (' + defaultUnitTypes[this.benefit.unit_type_id] + ')' }}</ng-container>
              <i
                *ngIf="canEdit && (rowData?.type === 'driver' || rowData?.type === 'improvement' || rowData?.type === 'financial')"
                (click)="editFactorName(rowIndex - 1)"
                class="clickable fa fa-pencil"
                style="margin-left: 10px"
                matTooltip="t.editName"
              ></i
            ></ng-container>
            <ng-container *ngIf="(factorNameFormArray | arrayToFormControl: rowIndex - 1:'editing')?.value && index === 0">
              <app-error-input-wrapper [errorMessage]="(factorNameFormArray | returnErrorObservable: rowIndex - 1 | async)?.get('name')" [showFormGroup]="false">
                <div class="input-group header-input">
                  <input
                    [formControl]="factorNameFormArray | arrayToFormControl: rowIndex - 1:'name'"
                    type="text"
                    (keydown.Enter)="saveFactorName(rowIndex - 1, col.businessReviewId)"
                    class="form-control"
                  />
                  <span class="clickable input-group-addon" (click)="editFactorName(rowIndex - 1)"><i class="fa fa-times"></i></span>
                  <span class="clickable input-group-addon" (click)="saveFactorName(rowIndex - 1, rowData.factor_id)"><i class="fa fa-save"></i></span>
                </div>
              </app-error-input-wrapper>
            </ng-container>
            <span *ngIf="col.editable && !editExpected && rowData.editable"
              >{{ rowData[col.field + '_fmt'] }}
              <ng-container *ngIf="rowData[col.field + '_has_scratchpad']">
                <ng-container *ngIf="(scratchPadEditIndex === index && toggledRow == rowData) || scratchPadEditIndex === null ; else disabledCalc">
                  <i
                    *ngIf="rowData[col.field + '_scratchpad_used']; else inactive"
                    [matTooltip]="trans.trans.editScratchpad.value"
                    (click)="funcGetScratchpadPadding(index, rowData)"
                    style="color: green"
                    [pRowToggler]="rowData"
                    class="fa fa-calculator clickable"
                  ></i>
                  <ng-template #inactive>
                    <img
                      class="inactive-calc"
                      [matTooltip]="trans.trans.editScratchpad.value"
                      (click)="funcGetScratchpadPadding(index, rowData)"
                      [pRowToggler]="rowData"
                      src="assets/images/scratchpad/calculator-inactive.png"
                      [alt]="trans.trans.scratchpadNotInUse.value"
                    />
                  </ng-template>
                </ng-container>
                <ng-template #disabledCalc>
                  <i style="color: gray" class="fa fa-calculator"></i>
                </ng-template>
              </ng-container>
            </span>
            <div *ngIf="col.editable && editExpected && rowData.editable">
              <p-inputNumber
                mode="decimal"
                [(ngModel)]="businessReviews[col.index][rowData.benefitField]"
                (keyup)="inputUpdated(index)"
                [disabled]="isLoading"
                [locale]="numberLocales[+userData?.number_format] ?? 'en-US'"
                [minFractionDigits]="rowData.precision | functionPipe: removeNegatives"
                [maxFractionDigits]="rowData.precision | functionPipe: removeNegatives"
              >
              </p-inputNumber>
            </div>
          </td>
        </tr>
        <ng-template #variance>
          <tr class="not-hover">
            <td *ngFor="let col of columns; let i = index" [ngStyle]="{ 'text-align': col.style }">
              <span *ngIf="i === 0; else varianceData">{{ trans.trans[rowData[col.field]]?.value }}</span>
              <ng-template #varianceData>{{ benefitUpdated !== null && benefitUpdated <= i && rowIndex > 3 && i > 1 ? '---' : rowData[col.field] }}</ng-template>
            </td>
          </tr>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <tr class="not-hover">
          <td colspan="3" style="text-align: right">
            <h3>{{ trans.trans.scratchpadEdit.value }}</h3>
          </td>
          <td colspan="2"></td>
        </tr>
        <tr *ngFor="let item of selectedScratchpad.units" class="not-hover">
          <td colspan="3" id="scratchpad-factor-text">
            <span *ngIf="+item.open_paren === 1"> ( </span>
            {{ item.name }}
            <i style="color: blue" *ngIf="operatorList[+item.operator_id - 1]">{{ operatorList[+item.operator_id - 1].sign }}</i>
            <span *ngIf="+item.close_paren === 1"> ) </span>
          </td>
          <td><input [disabled]="canEdit !== true" type="text" (keyup)="markScratchpadAsTouched()" [(ngModel)]="item.operand" class="form-control" /></td>
          <td *ngIf="columns.length !== selectedColLabelPadding" [attr.colspan]="columns.length === +selectedColLabelPadding ? 0 : columns.length - selectedColLabelPadding"></td>
        </tr>
        <tr class="not-hover">
          <td colspan="3" style="text-align: right">
            <b>{{ trans.trans.total.value }}: </b>
          </td>
          <td>{{ scratchpadCalcTotal }}</td>
          <td *ngIf="columns.length !== selectedColLabelPadding" [attr.colspan]="columns.length === +selectedColLabelPadding ? 0 : columns.length - selectedColLabelPadding"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
