<div class="docusignGuided add-guided-discovery">
	<div class="companySearch">
		<app-search-company (afterCompanyDetail)="afterCompanyDetail($event)"></app-search-company>
	</div>


	<div class="panel panel-white add-guided-discovery" *ngIf="modelToggle">
		<div class="panel-body addForm">
			<div class="form-group">
				<label for="" class="control-label stepsheader">{{trans.add_value_position.pick_your_models | translate }}</label>
				<div class="row">
					<div class="col-md-6">
						<h4>
							<div class="row">
								<div class="col-md-3">{{trans.add_value_position.pick_your_models | translate }} </div>
								<div class="col-md-9">
									<label class="categoryType">{{trans.add_value_position.choose_category_type | translate }}</label>
									<select *ngIf="solutionCategoryList.length>0" type="text" (change)="getSolution(categoryType)" class="form-control"
										name="categoryType" [(ngModel)]="categoryType">
										<option *ngFor="let solutionCategory of solutionCategoryList" [value]="solutionCategory?.id">
										{{solutionCategory?.category}} </option>
									</select>
									<div class="text-center">
										<img class="actionLoader" *ngIf="showCategoryTypeLoader" [src]="fullImagePath" />
									</div>
								</div>
							</div>
						</h4>

						<div *ngFor="let item of availableSolutions">
							<div class="checkbox custom-checkbox" *ngIf="item.selectedCategory">
								<label>
									<span *ngIf="item.quantity_enabled == '1'">
										<span (click)="item.showeditable = !item.showeditable; item.tempQuantity = item.quantity"
										*ngIf="!item.showeditable">
										<span *ngIf="item.quantity" class="quantityCheck">{{item.quantity}}</span>
											</span>
										&nbsp;of</span> {{item.name}}
										<span *ngIf="item.showeditable" class="editableBox">
											<input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity">
											<button (click)="quantityAssign(item.id,item.quantity);item.showeditable = !item.showeditable;" class="btn btn-primary">
												<i class="fa fa-check"></i>
											</button>
											<button (click)="item.quantity = item.tempQuantity;
											item.showeditable = !item.showeditable" class="btn btn-default">
											<i class="fa fa-times"></i>
										</button>
									</span>

									<input type="checkbox" name="item.isChecked" (change)="item.isChecked = !item.isChecked" [checked]="item.isChecked"
									(click)="checkModels(item.id,item.quantity,$event)" />

									<span class="check"></span>
								</label>
							</div>
						</div>
						<div *ngIf="selectedSolutionsLength == 0 && !showModelsLoader">
							<p class="well">{{trans.add_value_position.no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_above | translate }}
							</p>
						</div>
						<div class="text-center m-t-xxl">
							<img class="actionLoader" *ngIf="showModelsLoader" [src]="fullImagePath" />
						</div>
					</div>
				</div>
			</div>

			<button type="button" class="btn btn-primary-outline" *ngIf="selectedModels.length > 0" style="margin-top: 0.5%"
			 (click)="createSolution()">
				<i class="fa fa-plus"></i>{{trans.add_value_position.next | translate }}
			</button>
			<img class="actionLoader" *ngIf="showActionLoaderModel" [src]="fullImagePath" />
		</div>
	</div>

	<div class="panel panel-white" *ngIf="showSituation">
		<div class="panel-body addForm">
			<div class="form-group">
				<label for="" class="control-label stepsheader">{{trans.add_value_position.pick_your_situations | translate }}</label>
				<div class="row">
					<div class="col-md-6">
						<!-- <app-situation-valueprop masterValueProp="true" discovery="true" [masterValuePropId]="masterValuePropId" [type]="'quickcreate'" [(showScalers)]="showScalers" [company]="company" (getProjects)="getProjects()"></app-situation-valueprop> -->

					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="panel panel-white" *ngIf="showProjects">
		<div class="panel-body addForm">
			<div class="form-group">
				<label for="" class="control-label stepsheader">{{trans.add_value_position.pick_your_projects | translate }}</label>
				<div class="row">
					<div class="col-md-11" *ngIf="availableMasterValuePropProjects.length > 0">
						<div class="panel-body col-md-5 projects-list">
							<div *ngFor="let projects of availableMasterValuePropProjects">
								<p>
									<i class="icon-plus" (click)="projects.isCollapsed = !projects.isCollapsed" [attr.aria-expanded]="projects.isCollapsed"></i>
									&nbsp;
									<span class="tooltip-custom" [matTooltip]="projects.description" matTooltipPosition="right">{{projects.name}}</span>
								</p>
								<ul [ngbCollapse]="!projects.isCollapsed">
									<li *ngFor="let children of projects.children" class="checkbox custom-checkbox">
										<label [matTooltip]="children.description" matTooltipPosition="right">
											{{children.name}}
											<input type="checkbox" name="project{{children.id}}" [(ngModel)]="children.selected">
											<span class="check"></span>
										</label>
									</li>
								</ul>
							</div>
						</div>
						<div class="d-flex panel-body col-md-1 action-icons-wrapper">
							<div class="action-icons icon-arrow-right" (click)="moveSelectedProject()"></div>
						</div>
						<div class="panel-body col-md-5 projects-list">
							<div *ngFor="let projects of selectedProjects">
								<p class="col-md-10">
									<span class="tooltip-custom" [matTooltip]="projects.description" matTooltipPosition="right">{{projects.parent}}
										- {{projects.name}}</span>
								</p>
								<p class="col-md-2" role="button" (click)="removeSelectedProject(projects.id)">
									<i class="fa fa-times"></i>
								</p>
							</div>
						</div>
					</div>
					<div class="col-md-11" *ngIf="(availableMasterValuePropProjects.length == 0) && showActionLoaderProjects">
						<img class="actionLoader" [src]="fullImagePath" />
					</div>
					<div class="col-md-11" *ngIf="(availableMasterValuePropProjects.length == 0) && !showActionLoaderProjects">
						<p>{{trans.add_value_position.no_projects_found | translate }}</p>
					</div>
					<div class="col-md-11">
						<button type="button" class="btn btn-primary-outline" (click)="createProjects()" [disabled]="(availableMasterValuePropProjects.length > 0) && (selectedProjects.length == 0)">
							<i class="fa fa-plus"></i>{{trans.add_value_position.next | translate }}
						</button>
						<img class="actionLoader" [src]="fullImagePath" *ngIf="(availableMasterValuePropProjects.length > 0) && showActionLoaderProjects" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="panel panel-white" *ngIf="showScalers">
		<div class="panel-body addForm">
			<div class="form-group">
				<label for="" class="control-label stepsheader">
					<span *ngIf="isvalueProp">{{trans.add_value_position.scale_your_value_prop | translate }}</span>
					<span *ngIf="!isvalueProp">{{trans.add_value_position.scale_your_value_position | translate }}</span>
				</label>
				<div class="row">
					<div class="col-md-4" *ngFor="let allScaler of allScalersList">
						<label for="{{allScaler.scale_type_id}}" class="scalerName">{{allScaler.name}}
							<i class="icon-info tooltip-custom m-l-xs" [matTooltip]="allScaler.description" matTooltipPosition="right"></i>
						</label>
						<input type="number" class="form-control m-t-xxs" id={{allScaler.scale_type_id}} placeholder={{allScaler.name}}
						 name="scalerValue_{{allScaler.id}}" [(ngModel)]="allScaler.value" min="0" autocomplete="off">
					</div>
					<div [hidden]="!showActionLoader" class="m-l-xxs">
						<img class="actionLoader" [src]="fullImagePath" />
					</div>
				</div>
				<div *ngIf="(!showActionLoader && allScalersList == null) || (!showActionLoader && allScalersList.length == 0)">
					<p class="well">{{trans.add_value_position.no_scalers_found | translate }}</p>
				</div>
				<div class="form-group">
					<button type="button" class="btn btn-primary scaleValuePropBtn" (click)="createScaler()">
						<span>{{trans.add_value_position?.finish_and_review | translate }}</span>
					</button>
					<img class="actionLoader" *ngIf="showActionLoaderScaler" [src]="fullImagePath" />
				</div>
			</div>
		</div>
	</div>
</div>