<div>
	<div class="panel panel-white">
        <div class="panel-heading clearfix">
            <h4 class="panel-title">{{ trans.vp_dashboard.dealdesk_heading | translate }}</h4>
        </div>
        <div class="panel-body">
        	{{ trans.vp_dashboard.data | translate }}
        </div>
    </div>
</div>
