<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
<p-confirmDialog #cd [message]="trans.trans.wantSaveChanges.value">
  <p-footer>
    <button type="button" class="btn btn_customizable" (click)="cd.accept()">{{ trans.trans.yes.value }}</button>
    <button [ngClass]="'btn-light'" type="button" class="btn btn_customizable_clear marg-h" (click)="cd.reject()">
      {{ trans.trans.no.value }}
    </button>
  </p-footer>
</p-confirmDialog>

<ng-template #loadedContent>
  <div class="benefits-tab" *ngIf="!showAddBenefit">
    <div class="row marg-v">
      <div class="col-xs-3">
        <div class="input-group keywest_search no-background">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input [(ngModel)]="strSearch" (ngModelChange)="searchBenefits($event)" type="text" [placeholder]="trans.trans.search.value" class="form-control" />
          <span *ngIf="strSearch !== ''" (click)="searchBenefits()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
      <div class="col-xs-5"></div>
      <div class="col-xs-4 text-right">
        <button *ngIf="benefitOnTheFlyPrivilege" class="btn btn_customizable marg-h" (click)="toggleAddBenefit()">
          <span class="material-icons-outlined plus"> add </span>
          {{ trans.trans.addNew.value }}
        </button>
      </div>
    </div>

    <div class="row" style="margin-bottom: 24px">
      <div class="col-md-6 col-xs-12">
        <span
          class="full-badge full-badge-clear clickable"
          style="margin-left: 0px"
          (click)="displayAllFilter()"
          [ngClass]="{ 'full-badge-clear-selected': currentFilter.length === 3 }"
          >{{ trans.trans.all.value }}</span
        >
        <ng-container *ngFor="let filtr of filterOptions">
          <span (click)="filtr.command()" [ngClass]="{ 'full-badge-clear-selected': filtr.icon === 'active' }" class="full-badge full-badge-clear clickable">{{
            filtr.label
          }}</span>
        </ng-container>
      </div>
    </div>

    <ng-container *ngFor="let benefitType of searchedBenefitList">
      <h3>
        {{ trans.trans.benefits.value }} <span *ngIf="benefitType.id !== '1'">{{ trans.trans.non.value }} </span> {{ trans.trans.financial.value
        }}<span *ngIf="benefitType.id !== '1'">: {{ benefitType.name }} </span>
      </h3>

      <ng-container *ngFor="let benefitCategory of benefitType.vcs">
        <div class="bordered-panel-tile" *ngIf="benefitCategory.metrics.length">
          <span class="material-icons-outlined accordion" (click)="toggleBenefitTable(benefitCategory)">
            {{ benefitCategory.expand ? 'arrow_drop_down' : 'arrow_right' }}
          </span>

          <span class="clickable benefit-text" (click)="toggleBenefitTable(benefitCategory)">
            {{ benefitCategory.name }}

            <span
              *ngIf="benefitCategory.description"
              class="material-icons-outlined tooltip-custom info-icon"
              [matTooltip]="benefitCategory.description"
              matTooltipPosition="above"
              container="body"
            >
              info
            </span> </span
          ><br />

          <div *ngIf="benefitCategory.expand" class="benefits-table padding-15-top">
            <p-table [value]="benefitCategory.metrics" #baselineBenefitsTable responsiveLayout="scroll" styleClass="keywest_table" tableStyleClass="keywest_table">
              <ng-template pTemplate="header">
                <tr class="clear">
                  <th class="status-dropdown">{{ trans.trans.setStatus.value }}</th>
                  <th pSortableColumn="metric_name">{{ trans.trans.benefit.value }}<p-sortIcon field="metric_name"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-benefit>
                <tr>
                  <td class="status-dropdown">
                    <div>
                      <p-dropdown
                        panelStyleClass="dropdown-item-font"
                        name="status"
                        [(ngModel)]="benefit.active"
                        (ngModelChange)="statusChange(benefit.id, $event)"
                        optionLabel="name"
                        optionValue="id"
                        class="keywest_dropdown dropdown-theme"
                        appendTo="body"
                        [options]="statusTypes"
                        filterBy="name"
                      ></p-dropdown>
                    </div>
                  </td>
                  <td class="clickable" (click)="openBenefitScratchpad(benefit)">{{ benefit.metric_name }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-container>
      <div *ngIf="benefitType.vcs | functionPipe: noBenefitGroupToShow">
        {{ trans.trans.noMatches.value }}
      </div>
    </ng-container>
  </div>
  <div *ngIf="showAddBenefit" class="add-benefit-container">
    <div class="row">
      <div class="col-xs-3" *ngIf="showAddBenefit">
        <div (click)="toggleAddBenefit()" class="clickable a-link-color">
          <i class="fa fa-chevron-left"></i>
          <span class="marg-h">
            {{ trans.trans.backToBenefitsList.value }}
          </span>
        </div>
      </div>
    </div>
    <app-add-benefits-component *ngIf="!modelsv2" (cancelBenefitCallback)="toggleAddBenefit()" (saveBenefitCallback)="savedBenefit()" [valueProp]="valueProp">
    </app-add-benefits-component>

    <app-ae-benefits
      *ngIf="modelsv2"
      [solutionId]="solutionId"
      [can_edit]="true"
      [isOnTheFlyBenefit]="true"
      [valuePropId]="valuePropId"
      (callback)="savedBenefit()"
      (cancelBenefitCallback)="toggleAddBenefit()"
    ></app-ae-benefits>
  </div>
</ng-template>

<p-sidebar [(visible)]="showBenefitScratchpad" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="closeBenefitScratchpad()">
  <app-baseline-benefit-scratchpad *ngIf="showBenefitScratchpad" [benefit]="benefitScratchpad">
    <app-benefit-detail
      [embedded]="false"
      (reloadVP)="onBenefitDetailChanges()"
      [sidebar]="true"
      [mini]="true"
      [locked]="bI.locked"
      [valueProp]="valueProp"
      [selectedBenefit]="benefitScratchpad"
      [canEdit]="false"
      (closeBenefitDetail)="(closeBenefitScratchpad)"
      [permissions]="bI.permissions"
    ></app-benefit-detail>
  </app-baseline-benefit-scratchpad>
</p-sidebar>
