import { Component, OnDestroy, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Company } from '@shared/models/company.model';
import { finalize, take, takeUntil, map } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import { AccountService } from '@data/services/account/account.service';
import { CompanyService } from '@data/services/company/company.service';
import { NotificationService } from '@services/notification.service';
import { CompanySearchPayload } from '@shared/models/company-data.models';
import { CompanySearchTranslation } from './company-search.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss'],
})
export class CompanySearchComponent implements OnInit, OnDestroy {
  @Input() companyId: string;
  @Output() companySelected = new EventEmitter<string>();
  companyDetails: Company;
  companiesList: Company[];
  companiesListSuggested: Company[];
  isLoadingResults = false;
  ngUnsubscribe$ = new Subject();
  selectedCompanyForm: FormGroup;
  feature101 = this.commonService.checkFeature('101');

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    public trans: CompanySearchTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.selectedCompanyForm = this.fb.group({
      selectedCompany: new FormControl(''),
    });

    this.selectedCompanyForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
      if (!value.selectedCompany) {
        this.getRecentSearches();
      }
    });
    this.getRecentSearches();
    this.getTranslations();
    if (this.companyId) {
      this.getCompanyDetails(this.companyId);
    }
  }

  ngOnDestroy(): void {
    this.companyDetails = null;
    this.companiesList = [];
    this.selectedCompanyForm.controls.selectedCompany.reset();
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  defaultValue(value: string): string {
    if (value === 'NULL') {
      return '-- --';
    }
    return value ? value : '-- --';
  }

  defineTracker(companyId: string): void {
    this.companySelected.emit(companyId);
  }

  getRecentSearches(): void {
    this.isLoadingResults = true;
    const queryString = '/limit/10/page/1/sortby/created/order/desc?trackers=true';
    this.accountService
      .getRecentSearchCompanies(queryString)
      .pipe(
        take(1),
        finalize(() => (this.isLoadingResults = false))
      )
      .subscribe(
        (result) => {
          if (result.result && result.result.data.length > 0) {
            const sortedData = result.result.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
            const convertedData = sortedData.map((item) => {
              return {
                ...item,
                business_case_count: +item.business_case_count,
                promoted_case_count: +item.promoted_case_count,
              };
            })
            this.companiesListSuggested = convertedData;
          }
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );
  }

  getCompanyDetails(id: string): void {
    this.isLoadingResults = true;
    this.companyService
      .getCompanySummary(+id)
      .pipe(take(1))
      .subscribe(
        (result) => {
          if (result.result) {
            this.companyDetails = result.result.company;
          }
          this.isLoadingResults = false;
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );
  }

  normalizeCompanyLogoPath(company: Company): string {
    if (!company || !company.name) {
      return '';
    } else if (company?.logo_path) {
      return company.logo_path;
    } else {
      const value = company?.name?.toLowerCase();
      const path = `${value}.com/`;
      return `https://logo.clearbit.com/${path}`;
    }
  }

  searchCompanies(query: string): void {
    const payload = {
      searchTerm: query ?? '',
      publicOnly: 0,
      synopsisOnly: 1,
      vr_case_counts: true,
    };

    this.accountService
      .getSearchCompanies(payload as CompanySearchPayload)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.companiesList = response.result.data;
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );
  }

  private getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
