<div class="soln_table_cont overview">
  <div class="searchTableContainer">
    <div class="col-sm-12" *ngIf="solution && !overviewLoader">
      <div class="edit-solution" *ngIf="editSolution">
        <div class="panel panel-white" style="padding-bottom: 0px">
          <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5">
            <h3 class="panel-title">{{ trans.Overview.editing | translate }}{{ solution.name }}</h3>
          </div>
          <div class="panel-body">
            <form name="overViewForm" #overViewForm="ngForm">
              <div class="edit-info" [ngClass]="{ 'has-error': overViewForm.controls.name?.invalid && overViewForm.controls.name?.touched }">
                <div>
                  <label class="labelsRequired">{{ trans.Overview.name | translate }}:</label>
                </div>
                <input class="form-control" name="name" [(ngModel)]="solution.name" required />
              </div>
              <div class="edit-info">
                <div>
                  <label>{{ trans.Overview.description | translate }}: </label>
                </div>
                <textarea class="form-control" name="description" [(ngModel)]="solution.description"></textarea>
              </div>
              <div
                class="edit-info clearfix"
                [ngClass]="{ 'has-error': overViewForm.controls.selectedSolutionItems?.invalid && overViewForm.controls.selectedSolutionItems?.touched }"
              >
                <div>
                  <label class="labelsRequired">{{ trans.Overview.model_category | translate }}:</label>
                </div>
                <div class="valuecategory" *ngIf="solutionCategories && solutionCategories.length">
                  <p-multiSelect
                    class="keywest_dropdown"
                    [options]="solutionCategories"
                    name="selectedSolutionItems"
                    [(ngModel)]="selectedItems"
                    optionLabel="category"
                  ></p-multiSelect>
                </div>
              </div>
              <div class="col-sm-12 update-solution" *ngIf="editSolution">
                <button
                  class="btn"
                  [ngClass]="{
                    'btn_customizable': !(style2022$ | async),
                    'btn-light marg-h': style2022$ | async
                  }"
                  (click)="cancelEditPage()"
                  type="submit"
                >
                  {{ trans.Overview.cancel | translate }}
                </button>
                <button class="btn btn_customizable" *ngIf="canEdit" (click)="updateSolution()" type="submit" [disabled]="overViewForm.invalid || selectedItems.length == 0">
                  {{ trans.Overview.update | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="panel panel-white" style="padding-bottom: 0px" *ngIf="!editSolution">
        <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5">
          <h3 class="panel-title">
            {{ solution.name }}
            <i *ngIf="canEdit" class="fa fa-pencil" matTooltip="{{ trans.Overview.edit_model | translate }}" matTooltipPosition="above" (click)="openEditPage()"></i>
          </h3>
        </div>

        <div class="panel-body">
          <div *ngIf="!editSolution" class="info description">{{ solution.description }}</div>
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 33%">{{ trans.Overview.average_sales_price | translate }}</th>
                <th style="width: 33%">{{ trans.Overview.averages_sales_cycle | translate }}</th>
                <th style="width: 33%">{{ trans.Overview.deployment | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ solution.avg_sale_price_fmt }}</td>
                <td>{{ solution.avg_sale_cycle }}</td>
                <td>{{ solution.avg_deployment_time }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <app-scalers [canEdit]="canEdit"></app-scalers>

    </div>
  </div>
  <div [hidden]="!overviewLoader" class="col-12 text-center">
    <img class="actionLoader" src="{{ fullImagePath }}" />
  </div>
  <div *ngIf="!solution && !overviewLoader" class="m-t-md col-sm-12">
    <div class="searchTableContainer text-center">
      <h4>{{ trans.Overview.no_model_found | translate }}</h4>
    </div>
  </div>
</div>
