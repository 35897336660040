import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';

import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { GoalFormModule } from '@shared_components/goal-form/goal-form.module';

import { ModelFactorsService } from '@data/services/solution/model-factors.service';

import { ModelGoalsComponent } from './model-goals.component';
import { ModelGoalsTranslation } from './model-goals.translation';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { UserService } from '@data/services/user/user.service';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { AEScratchpadsModule } from 'app/value-map-maintenance/views/factors/ae_scratchpads/ae_scratchpads.module';
import { UnitTypeModule } from '@shared/unit-type/unit-type.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  declarations: [ModelGoalsComponent],
  imports: [
    CommonModule,
    EditTranslationsModule,
    ProgressBarModule,
    FormsModule,
    SidebarModule,
    TableModule,
    EllipsisMenuModule,
    AreYouSureModule,
    GoalFormModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputNumberModule,
    ApplicationPipesModule,
    MatTooltipModule,
    FunctionPipeModule,
    MultiSelectModule,
    CheckboxModule,
    AEScratchpadsModule,
    UnitTypeModule,
    ConfirmDialogModule,
  ],
  providers: [TranslationsV2Service, ModelGoalsTranslation, ModelFactorsService, UserService, ConfirmationService],
  exports: [ModelGoalsComponent],
})
export class ModelGoalsModule {}
