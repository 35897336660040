import { DLProgressbarComponent } from './progress.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [CommonModule, FormsModule],
	declarations: [DLProgressbarComponent],
	exports: [DLProgressbarComponent],
	entryComponents: [DLProgressbarComponent]
})
export class DLProgressbarModule { }