<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title">{{ trans.trans.tco.value }}</div>
        </div>
        <div class="col-xs-6">
          <div *ngIf="!embedded" style="float: right; display: block">
            <input *ngIf="feature76" type="checkbox" [(ngModel)]="showAllMetrics" (click)="loadAllCosts()" /> {{ trans.trans.showMoreThanCostsImpacts.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="showHelp">
    <div class="col-xs-12">
      <div class="well well-info">
        <b>{{ trans.trans.tco.value }}</b
        ><br />
        {{ trans.trans.ch_tco.value }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div *ngIf="tcoLoader" class="col-12 text-center">
        <p-progressBar *ngIf="tcoLoader" mode="indeterminate"></p-progressBar>
      </div>

      <div *ngIf="!tcoLoader && tcoObjCurrent && tcoObjCurrent !== null">
        <div class="panel pane-white">
          <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
            <h3>
              {{ trans.trans.current_state.value
              }}<app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.current_state"
                (callback)="refreshTranslation()"
                key="vp_dashboard.tco.current_state"
              ></app-editTranslation>
            </h3>
          </div>
          <div class="panel-body">
            <div class="row row-spacing">
              <div class="col-md-2">
                <b
                  >{{ trans.trans.total_cost_ownership.value
                  }}<app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_cost_ownership"
                    (callback)="refreshTranslation()"
                    key="vp_dashboard.tco.total_cost_ownership"
                  ></app-editTranslation
                ></b>
              </div>
              <div class="col-md-8">
                <div class="row row-between">
                  <ng-container *ngFor="let term of termItems; let i = index">
                    <div *ngIf="term.show" class="col-md-2">
                      <i *ngIf="i === carouselData.start && termItems.length > 5" class="fa fa-chevron-circle-left clickable" (click)="moveCarousel('left')"></i>
                      <b>
                        {{ trans.trans.year.value }} {{ i + 1 }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.year"
                          (callback)="refreshTranslation()"
                          key="vp_dashboard.tco.year"
                        ></app-editTranslation>
                      </b>
                      <i *ngIf="i === carouselData.end && termItems.length > 5" class="fa fa-chevron-circle-right clickable" (click)="moveCarousel('right')"></i>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-2">
                <b
                  >{{ trans.trans.total.value
                  }}<app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total"
                    (callback)="refreshTranslation()"
                    key="vp_dashboard.tco.total"
                  ></app-editTranslation
                ></b>
              </div>
            </div>
            <div *ngIf="tcoObjCurrent.expenses && tcoObjCurrent.expenses.length">
              <div *ngFor="let expense of tcoObjCurrent.expenses">
                <div class="row">
                  <div class="col-xs-12">
                    <strong>{{ expense.name }}</strong>
                  </div>
                </div>

                <div *ngIf="expense.categories && expense.categories.length">
                  <div *ngFor="let category of expense.categories">
                    <div class="row row-spacing" (click)="category.expand = !category.expand">
                      <div class="col-md-2">
                        <a class="clickable">{{ category.category }}</a>
                      </div>
                      <div class="col-md-8">
                        <div class="row row-between">
                          <ng-container *ngFor="let term of termItems; let i = index">
                            <div *ngIf="term.show" class="col-md-2">
                              {{ category['yr' + (i + 1) + '_un_fmt'] }}
                            </div>
                          </ng-container>
                        </div>
                      </div>

                      <div class="col-md-2">{{ category.total_un_fmt }}</div>
                    </div>
                    <div *ngIf="(category && category.expand ? true : false) && category.items && category.items.length">
                      <div class="row grid-row grid-row-margin row-spacing" *ngFor="let item of category.items">
                        <div class="col-md-2">{{ item.name }}</div>
                        <div class="col-md-8">
                          <div class="row row-between">
                            <ng-container *ngFor="let term of termItems; let i = index">
                              <div *ngIf="term.show" class="col-md-2">
                                {{ item['yr' + (i + 1) + '_un_fmt'] }}
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="col-md-2">{{ item.total_un_fmt }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row grid-row table-total">
                  <div class="col-md-2">
                    <strong>{{ trans.trans.total.value }} {{ expense.abbr }}</strong>
                  </div>
                  <div class="col-md-8">
                    <div class="row row-between term-total">
                      <ng-container *ngFor="let term of termItems; let i = index">
                        <div *ngIf="term.show" class="col-md-2 total-padding">
                          <strong>
                            {{ expense['yr' + (i + 1) + '_un_fmt'] }}
                          </strong>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col-md-2 total-padding">
                    <strong>{{ expense.total_un_fmt }}</strong>
                  </div>
                </div>
              </div>
              <div class="row grid-row row-spacing">
                <div class="col-md-2">
                  <strong
                    >{{ trans.trans.total_current_state.value
                    }}<app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.total_current_state"
                      (callback)="refreshTranslation()"
                      key="vp_dashboard.tco.total_current_state"
                    ></app-editTranslation
                  ></strong>
                </div>
                <div class="col-md-8">
                  <div class="row row-between term-total">
                    <ng-container *ngFor="let term of termItems; let i = index">
                      <div *ngIf="term.show" class="col-md-2 total-padding">
                        <strong>
                          {{ tcoObjCurrent['yr' + (i + 1)] }}
                        </strong>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="col-md-2">
                  <strong>{{ tcoObjCurrent.total }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!tcoLoader && tcoObjFuture && tcoObjFuture !== null">
        <div class="panel pane-white">
          <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
            <h3>
              {{ trans.trans.total_future_state.value
              }}<app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.total_future_state"
                (callback)="refreshTranslation()"
                key="vp_dashboard.tco.total_future_state"
              ></app-editTranslation>
            </h3>
          </div>
          <div class="panel-body">
            <div class="row row-spacing">
              <div class="col-md-2">
                <b
                  >{{ trans.trans.future_state.value
                  }}<app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.future_state"
                    (callback)="refreshTranslation()"
                    key="vp_dashboard.tco.future_state"
                  ></app-editTranslation
                ></b>
              </div>
              <div class="col-md-8">
                <div class="row row-between">
                  <ng-container *ngFor="let term of termItems; let i = index">
                    <div *ngIf="term.show" class="col-md-2">
                      <i *ngIf="i === carouselData.start && termItems.length > 5" class="fa fa-chevron-circle-left clickable" (click)="moveCarousel('left')"></i>
                      <b>
                        {{ trans.trans.year.value }} {{ i + 1 }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.year"
                          (callback)="refreshTranslation()"
                          key="vp_dashboard.tco.year"
                        ></app-editTranslation>
                      </b>
                      <i *ngIf="i === carouselData.end && termItems.length > 5" class="fa fa-chevron-circle-right clickable" (click)="moveCarousel('right')"></i>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-2">
                <b
                  >{{ trans.trans.total.value
                  }}<app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total"
                    (callback)="refreshTranslation()"
                    key="vp_dashboard.tco.total"
                  ></app-editTranslation
                ></b>
              </div>
            </div>
            <div *ngIf="tcoObjFuture.expenses && tcoObjFuture.expenses.length">
              <div *ngFor="let expense of tcoObjFuture.expenses">
                <div class="row">
                  <div class="col-xs-12">
                    <strong>{{ expense.name }}</strong>
                  </div>
                </div>

                <div *ngIf="expense.categories && expense.categories.length">
                  <div *ngFor="let category of expense.categories">
                    <div class="row row-spacing" (click)="category.expand = !category.expand">
                      <div class="col-md-2">
                        <a class="clickable">{{ category.category }}</a>
                      </div>
                      <div class="col-md-8 row row-between">
                        <ng-container *ngFor="let term of termItems; let i = index">
                          <div *ngIf="term.show" class="col-md-2">
                            {{ category['yr' + (i + 1) + '_un_fmt'] }}
                          </div>
                        </ng-container>
                      </div>
                      <div class="col-md-2 total-margin">{{ category.total_un_fmt }}</div>
                    </div>
                    <div *ngIf="(category && category.expand ? true : false) && category.items && category.items.length" class="grid-row">
                      <div class="row row-spacing" *ngFor="let item of category.items">
                        <div class="col-md-2">{{ item.name }}</div>
                        <div class="col-md-8">
                          <div class="row row-between">
                            <ng-container *ngFor="let term of termItems; let i = index">
                              <div *ngIf="term.show" class="col-md-2">
                                {{ item['yr' + (i + 1) + '_un_fmt'] }}
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-2">{{ item.total_un_fmt }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row grid-row table-total row-spacing">
                  <div class="col-md-2">
                    <strong>{{ trans.trans.total.value }} {{ expense.abbr }}</strong>
                  </div>
                  <div class="col-md-8">
                    <div class="row row-between term-total">
                      <ng-container *ngFor="let term of termItems; let i = index">
                        <div *ngIf="term.show" class="col-md-2 total-padding">
                          <strong>
                            {{ expense['yr' + (i + 1) + '_un_fmt'] }}
                          </strong>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <strong>{{ expense.total_un_fmt }}</strong>
                  </div>
                </div>
              </div>
              <div class="row grid-row row-spacing">
                <div class="col-md-2">
                  <strong
                    >{{ trans.trans.total_future_state.value
                    }}<app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.total_future_state"
                      (callback)="refreshTranslation()"
                      key="vp_dashboard.tco.total_future_state"
                    ></app-editTranslation
                  ></strong>
                </div>
                <div class="col-md-8">
                  <div class="row row-between term-total">
                    <ng-container *ngFor="let term of termItems; let i = index">
                      <div *ngIf="term.show" class="col-md-2 total-padding">
                        <strong>
                          {{ tcoObjFuture['yr' + (i + 1)] }}
                        </strong>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-2">
                  <strong>{{ tcoObjFuture.total }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!tcoLoader && tco && tco !== null">
        <div class="panel pane-white">
          <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
            <h3>
              {{ trans.trans.tco_summary.value
              }}<app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.tco_summary"
                (callback)="refreshTranslation()"
                key="vp_dashboard.tco.tco_summary"
              ></app-editTranslation>
            </h3>
          </div>
          <div class="panel-body">
            <div class="row row-spacing">
              <div class="col-md-2">
                <b
                  >{{ trans.trans.total_cost_ownership.value
                  }}<app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total_cost_ownership"
                    (callback)="refreshTranslation()"
                    key="vp_dashboard.tco.total_cost_ownership"
                  ></app-editTranslation
                ></b>
              </div>
              <div class="col-md-8">
                <div class="row row-between">
                  <ng-container *ngFor="let term of termItems; let i = index">
                    <div *ngIf="term.show" class="col-md-2">
                      <i *ngIf="i === carouselData.start && termItems.length > 5" class="fa fa-chevron-circle-left clickable" (click)="moveCarousel('left')"></i>
                      <b>
                        {{ trans.trans.year.value }} {{ i + 1 }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.year"
                          (callback)="refreshTranslation()"
                          key="vp_dashboard.tco.year"
                        ></app-editTranslation>
                      </b>
                      <i *ngIf="i === carouselData.end && termItems.length > 5" class="fa fa-chevron-circle-right clickable" (click)="moveCarousel('right')"></i>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-2">
                <b
                  >{{ trans.trans.total.value
                  }}<app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.total"
                    (callback)="refreshTranslation()"
                    key="vp_dashboard.tco.total"
                  ></app-editTranslation
                ></b>
              </div>
            </div>

            <div class="row row-spacing" *ngFor="let tcos of tco">
              <div class="col-md-2">{{ tcos.name }}</div>
              <div class="col-md-8">
                <div class="row row-between">
                  <ng-container *ngFor="let term of termItems; let i = index">
                    <div *ngIf="term.show" class="col-md-2">
                      {{ tcos['yr' + (i + 1)] }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-2">{{ tcos.total }}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!tcoLoader && isVmware && tco && tco !== null">
        <div class="panel pane-white">
          <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
            <h3>{{ trans.trans.TCOSummaryPerUSer.value }}</h3>
          </div>
          <div class="panel-body">
            <div class="row row-spacing">
              <div class="col-md-2">
                <b>{{ trans.trans.TotalCostofOwnership.value }}</b>
              </div>
              <div class="col-md-8">
                <div class="row row-between">
                  <ng-container *ngFor="let term of termItems; let i = index">
                    <div *ngIf="term.show" class="col-md-2">
                      <i *ngIf="i === carouselData.start && termItems.length > 5" class="fa fa-chevron-circle-left clickable" (click)="moveCarousel('left')"></i>
                      <b>{{ trans.trans['Year' + (i + 1)].value }}</b>
                      <i *ngIf="i === carouselData.end && termItems.length > 5" class="fa fa-chevron-circle-right clickable" (click)="moveCarousel('right')"></i>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-2">
                <b>{{ trans.trans.total.value }}</b>
              </div>
            </div>

            <div class="row row-spacing" *ngFor="let tcos of tco">
              <div class="col-md-2">{{ tcos.name }}</div>
              <div class="col-md-8">
                <div class="row row-between">
                  <ng-container *ngFor="let term of termItems; let i = index">
                    <div *ngIf="term.show" class="col-md-2">
                      {{ tcos['yr' + (i + 1)] }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-2 total-margin">{{ tcos.total }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
