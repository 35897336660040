<!-- <p-progressBar *ngIf="!dbI.loaded" mode="indeterminate"></p-progressBar> -->

<div class="row flex-jend margin-15-y" *ngIf="(style2022$ | async) && dbI.view !== 'sales_manager' ">
	<div class="col-md-4 mr-auto">
		<h1 class="mr-0">
			<span class="breadcrumb-title clickable" routerLink="/dashboard">
					{{trans.trans.dash_board.value}}
					<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.dash_board" (callback)="getTranslations()"></app-editTranslation>
			</span>
		</h1>
	</div>
</div>

<div class='models_panel' *ngIf="dbI.loaded && dbI.showDashboard && dbI.view !== 'sales_manager'">
	<div class='models_page_header_container_small'>
		<div class='keywest_header' *ngIf="dbI.view !== 'sales_manager' && !(style2022$ | async)">
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>{{ trans.trans.dash_board.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.dash_board" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-6'>
					<i matTooltip='Toggle all panels for testing' *ngIf="dbI.isDev" (click)='dbI.funcToggleAll()' class='fa fa-code pull-right'></i>
				</div>
			</div>
		</div>

		<div [ngSwitch]="dbI.view">
			<div *ngSwitchCase="'main_dashboard'">
				<div class="row dashboard-tiles">
					<app-dashboard-scb *ngIf="dbI.isValueInsightUser"></app-dashboard-scb>
					<app-dashboard-valueposition *ngIf="dbI.isValuePositionUser || dbI.isGuest"></app-dashboard-valueposition>
					<app-dashboard-valueprop *ngIf="dbI.isValuePropUser || dbI.isGuest"></app-dashboard-valueprop>
					<app-dashboard-mvp *ngIf="dbI.masterValuePropFeature"></app-dashboard-mvp>
					<app-value-realized *ngIf="(product5 && customerSuccessPriv)"></app-value-realized>
					<app-trackers *ngIf="(product4 && feature58 && !product5)"></app-trackers>
					<app-dashboard-solution *ngIf="dbI.isSolutionAdmin"></app-dashboard-solution>
					<app-dashboard-casestudy *ngIf="dbI.caseStudyFeature"></app-dashboard-casestudy>
					<app-dashboard-vpg *ngIf="dbI.vpg"></app-dashboard-vpg>
				</div>
			</div>

			<div *ngSwitchDefault>

			</div>
		</div>
	</div>
</div>

<div *ngIf="dbI.view == 'sales_manager'">
	<app-dashboard-sales-manager></app-dashboard-sales-manager>
</div>