import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CompanyService } from '@data/services/company/company.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { DashboardImplementation } from './dashboard.implementation';
import { AlertService } from '@services/alert.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { DefaultTranslations } from './dashboard.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { StyleService } from 'app/style.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ValuepropService, CompanyService, ValuerealizationService, SolutionService, DashboardImplementation],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  accountAdmin = false;
  loaded = false;
  valueProps: ValueProp[];
  userID: string;
  accountID: string;
  currentJustify = 'center';
  loadDashboardTable = false;
  fullImagePath: string;
  isSCBUser = false;
  isValuePropUser = false;
  isValuePositionUser = false;
  isValueRealizationUser = false;
  isValueInsightUser = false;
  hasRepSimpleFeature = false;
  isSalesRep = false;
  dashboardLoading = true;
  userCredits: number;
  valuePropsDetails: ValueProp;
  showTooltipActionLoader = false;
  dtTrigger: Subject<any> = new Subject();
  page: string;
  masterValuePropFeature = false;
  dealdeskFeature = false;
  guidedDiscoveryFeature = false;
  caseStudyFeature = false;
  selectedOption: string;
  isGuest = false;
  valuePropGroupsFeature = false;

  subscriptionscbDownloadList: Subscription;
  subscriptiongetValuePositionList: Subscription;
  subscriptiongetValueRealizationQuick: Subscription;
  subscriptiongetUser: Subscription;
  subscriptiongetValueProp: Subscription;
  subscriptiongetValuePropDetail: Subscription;
  subscriptiongetAvailableSolutions: Subscription;
  subscriptiongetUserCredits: Subscription;
  subscriptiongetRecentCaseStudies: Subscription;
  subscriptionreadAndSearchMasterValueProps: Subscription;
  subscriptionSubscribeLoggedIn: Subscription;

  header_color: string;
  showTranslate = false;
  isAdmin = false;
  feature41: boolean;
  showAddButtons = false;
  style2022$: Observable<boolean>;
  showNavigation = true;
  ngUnsubscribe = new Subject();
  view = '';
  modelsV2 = false;
  feature31 = false;
  feature58 = false;
  product4 = false;
  product5 = false;
  customerSuccessPriv = false;

  get user(): string {
    return sessionStorage.getItem('username');
  }

  constructor(
    public dbI: DashboardImplementation,
    private alertService: AlertService,
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private style: StyleService
  ) {}

  ngOnInit(): void {
    this.product4 = this.commonService.checkProduct(4);
    this.product5 = this.commonService.checkProduct(5);
    if (sessionStorage.getItem('privileges')) {
      const privsString = sessionStorage.getItem('privileges');
      const privs = privsString.split(',');
      this.accountAdmin = privs.includes('1');
      this.customerSuccessPriv = privs.includes('14');
    }
    this.feature58 = this.commonService.checkFeature(58);
    this.style2022$ = this.style.style2022;

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.style.updateDashboard();
    this.style.dashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dashboardManager) => {
      this.checkDashboard(dashboardManager);
    });
  }

  ngAfterViewInit(): void {
    this.dbI.ngOnInit();
  }

  checkDashboard(salesManager: boolean): void {
    const feature53 = this.commonService.checkFeature('53');
    this.feature31 = this.commonService.checkFeature('31');
    this.modelsV2 = this.commonService.checkFeature('38');
    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true' ? true : false;
    if (salesManager) {
      this.dbI.view = 'sales_manager';
    } else if (this.isAdmin && feature53) {
      this.dbI.view = 'sales_manager';
    } else {
      this.dbI.view = 'main_dashboard';

      this.dbI.isAdmin = sessionStorage.getItem('isAdmin') == 'true' ? true : false;

      this.dbI.header_color = localStorage.getItem('header_color');

      if (sessionStorage.getItem('uid')) {
        this.dbI.userID = sessionStorage.getItem('uid');
      }
      if (sessionStorage.getItem('aid')) {
        this.dbI.accountID = sessionStorage.getItem('aid');
      }

      this.alertService
        .subscribeLoggedIn()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data) => {
          this.dbI.isAdmin = sessionStorage.getItem('isAdmin') == 'true' ? true : false;
          this.dbI.userID = data.user_id;
          this.dbI.accountID = data.account_id;

          this.dbI.funcReloadData(data.user_id);
        });
    }
  }

  ngOnDestroy(): void {
    this.dbI.ngOnDestroy();
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
