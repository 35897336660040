<form class="" name="CompanySearchForm" #CompanySearchForm="ngForm" (ngSubmit)="getRecentSearchComp()">
	<div class="sidebar_container">
		<div class="keywest_panel" style="overflow-y: scroll; height: 75vh;">
			<div class="row ">
				<div class="col-xs-12">
					<label for="" class="sidebar_label">{{trans.trans.redact.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.redact" (callback)="getTranslations()"></app-editTranslation>
					</label>
				</div>
				<div class="col-xs-12">
					<p-inputSwitch name='redact' [(ngModel)]="isRedact"></p-inputSwitch>
					<span class='switch_inline'>{{ isRedact ? trans.trans.yes.value : trans.trans.no.value}}</span>
				</div>
			</div>

			<div class="row " style='margin-top: 30px;' *ngIf="!detailedDescription">
				<div class="col-xs-12">
					<label for="" class="sidebar_label">{{trans.trans.pickAccount.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.pickAccount" (callback)="getTranslations()"></app-editTranslation>
					</label>
					<div class="input-group keywest_search">
						<span class="input-group-addon"><i class='fa fa-search'></i></span>
						<input [(ngModel)]="companyName" (key.enter)='getRecentSearchComp()' type='text' name="companyName" placeholder="{{trans.trans.searchCompany.value}}" class='form-control'>
						<span *ngIf="companiesNoResult && !companiesList.length" (click)="toggleAddCompany = !toggleAddCompany" class="input-group-addon clickable">Add Company</span>
						<span *ngIf="companyName !== ''" (click)="clearCompanyList()" class="input-group-addon clickable"
						><i class="fa fa-times"></i
					  ></span>
					</div>
				</div>
			</div>

			<div class='row ' *ngIf="showActionLoader">
				<div class='col-xs-12'>
					<p-progressBar mode="indeterminate"></p-progressBar>
				</div>
			</div>
			<div class='row ' *ngIf="toggleAddCompany">
				<div class='col-xs-12'>
					<app-add-company-case-study [modalCreate]='true' (companyCreated)='toggleAddCompany = false; detailedDescription = true; selectCompany($event.id)'></app-add-company-case-study>
				</div>
			</div>
			<div class='row ' *ngIf="companiesList.length && !detailedDescription">
				<div class='col-xs-12'>
					<p-table #tableSolutionList [value]="companiesList" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
						<ng-template pTemplate="header">
							<tr>
								<th>{{ trans.trans.name_label.value }}
									<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name_label" (callback)="getTranslations()"></app-editTranslation>
								</th>
								<th class="text-right">{{ trans.trans.revenue.value }}
									<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.revenue" (callback)="getTranslations()"></app-editTranslation>
								</th>
								<th class="text-right">{{ trans.trans.employees.value }}
									<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>
								</th>
								<th>{{ trans.trans.ticker.value }}
									<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ticker" (callback)="getTranslations()"></app-editTranslation>
								</th>
								<th>{{ trans.trans.city.value }}
									<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.city" (callback)="getTranslations()"></app-editTranslation>
								</th>
								<th>{{ trans.trans.country.value }}
									<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.country" (callback)="getTranslations()"></app-editTranslation>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-company>
							<tr (click)="selectCompany(company.id)" class="clickable">
								<td>{{company.name}}</td>
								<td>{{company.net_sales}}</td>
								<td>{{company.employees}}</td>
								<td>{{company.ticker}}</td>
								<td>{{company.city}}</td>
								<td>{{company.country}}</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>

			<div class='row ' *ngIf="companiesNoResult">
				<div class="m-t-md col-sm-12">
					<div class="searchTableContainer text-center">
						<h4>{{ trans.trans.noCompaniesFound.value }}</h4>
					</div>
				</div>
			</div>

			<div *ngIf="detailedDescription">
				<div class="row ">
					<div class="col-xs-3">
						<img onerror="this.src='/assets/images/missing_logo.jpg'" src="//logo.clearbit.com/{{getHostnameFromRegex(companyDetails.url)}}" class='img-responsive'>
					</div>
					<div class="col-xs-9">
						<div class="row">
							<div class="col-md-4 account-label">{{trans.trans.name_label.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name_label" (callback)="getTranslations()"></app-editTranslation>:
							</div>
							<div class="col-md-8 account-info">{{companyDetails.name}}</div>
						</div>
						<div class="row">
							<div class="col-md-4 account-label">{{trans.trans.address.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.address" (callback)="getTranslations()"></app-editTranslation>:
							</div>
							<div class="col-md-8 account-info">{{companyDetails.address1}}</div>
						</div>
						<div class="row">
							<div class="col-md-4 account-label">{{trans.trans.sector.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.sector" (callback)="getTranslations()"></app-editTranslation>:
							</div>
							<div class="col-md-8 account-info">{{companyDetails.dl_sector_name}}</div>
						</div>
						<div class="row">
							<div class="col-md-4 account-label">{{trans.trans.website.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.website" (callback)="getTranslations()"></app-editTranslation>:
							</div>
							<div class="col-md-8 account-info">{{companyDetails.url}}</div>
						</div>
						<div class="row">
							<div class="col-md-4 account-label">{{trans.trans.revenue.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.revenue" (callback)="getTranslations()"></app-editTranslation>:
							</div>
							<div class="col-md-8 account-info">{{companyDetails.revenue}}</div>
						</div>
						<div class="row">
							<div class="col-md-4 account-label">{{trans.trans.employees.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>:
							</div>
							<div class="col-md-8 account-info">{{companyDetails.employees}}</div>
						</div>
					</div>
				</div>

				<div class="row " style='margin-top: 30px; margin-bottom: 30px;'>
					<div class="col-xs-12" *ngIf="companyDetails.synopsis">
						<p>{{companyDetails.synopsis}}</p>
					</div>
				</div>
        
				<div class="row ">
					<div class="col-md-12 d-flex f-column">
						<label for="" class="sidebar_label">{{trans.trans.tags.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.tags" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<p-chips name='tags' [(ngModel)]="tags"></p-chips>
						<small class="margin-12-y">Please press enter when entering in tags before hitting save below</small>
					</div>

				</div>

			</div>


		</div>
	</div>

	<div class='btn_container'>
		<div class='row'>
			<div class="col-md-12">
				<app-btn-save [form]='CompanySearchForm' (callback)='saveCaseStudy()' [text]='trans.trans.save.value' class='btn btn_customizable pull-right'></app-btn-save>
				<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
			</div>
		</div>
	</div>
</form>
