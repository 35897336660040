<p-menu #actionsDropdown appendTo="body" [popup]="true" [model]="propActions" [styleClass]="'style-2022'"></p-menu>

<p-menu #workflowDropdown appendTo="body" [popup]="true" [model]="workflows" [styleClass]="'style-2022'"></p-menu>

<div class="row" *ngIf="valueProp && valueProp.solutions && valueProp.solutions?.length > 0" style="margin-top: 0px; margin-bottom: 0px">
  <div class="col-xs-12">
    <div class="tour_icons" *ngIf="valueProp.solutions && valueProp.solutions.length > 0">
      <div *ngIf="!isValueTracker" class="pull-right toolbar-text toolbar-text-border" >        
        <span class="icon-background value-execution-add-a-new-value-proposition-value-prop-actions-menu" (click)="actionsDropdown.toggle($event)" data-heap="value-execution-add-a-new-value-proposition-value-prop-actions-menu">
          <h1 class="breadcrumb-title mr-0 clickable vp-actions-label">{{trans.trans.hypothesisActions.value}}
            <img id="vp-chevron-down" src="assets/images/icons/chevron-down.svg"/>
          </h1>
        </span>
      </div>
      <div *ngIf="menu && repSimpleStep && showDiscoveryQuestionsToggle" class="pull-right toolbar_icons toolbar-text toolbar-text-border" [matTooltip]="trans.trans.discovery.value" matTooltipPosition="below">
        <span (click)="toggleDiscoveryQuestions = !toggleDiscoveryQuestions">
          <h1 class="breadcrumb-title mr-0 clickable vp-actions-label">
            <i class="material-icons-outlined info-icon" [matTooltip]="trans.trans.emailTooltip?.value"> explore </i>
            {{ trans.trans.discovery.value }}
            <i *ngIf="showDiscoveryAlert" [matTooltip]="trans.trans.discoveryAlert.value" class="fa fa-exclamation-circle alert-circle"></i>
          </h1>
        </span>
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.discovery"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div *ngIf="!isValueTracker && workflows?.length > 1" class="pull-right spacer-right toolbar-text" >
        <span class="icon-background value-execution-add-a-new-value-proposition-current-workflow-menu" (click)="workflowDropdown.toggle($event)" data-heap="value-execution-add-a-new-value-proposition-current-workflow-menu">
          <h1 class="breadcrumb-title mr-0 clickable vp-actions-label">{{selectedWorkflow.name}}
            <img id="wf-chevron-down" src="assets/images/icons/chevron-down-full.svg"/>
          </h1>
        </span>
      </div>
      <button *ngIf="isValueTracker && valueProp.vp_can_edit" id="settings-icon" (click)="triggerEdit()" mat-icon-button [matTooltip]="trans.trans.editTracker.value">
        <span class="material-icons-outlined">settings</span>
      </button>

      <div 
        *ngIf="isValueTracker" 
        class="pull-right toolbar_icons" 
        [matTooltip]="trans.trans.download.value" 
        matTooltipPosition="below"
      >
        <span (click)="AssetsMenu = !AssetsMenu">
          <i class="fa fa-cloud-download"></i>
          {{ trans.trans.download.value }}
        </span>
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.download"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div 
        *ngIf="isValueTracker && valueProp.vp_can_edit" 
        class="pull-right toolbar_icons" 
        [matTooltip]="trans.trans.share.value" 
        matTooltipPosition="below"
      >
        <span (click)="toggleShare = !toggleShare">
          <i class="prop-icon icon-share"></i>
          {{ trans.trans.share.value }}
        </span>
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.share"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
    </div>
  </div>
</div>

<p-sidebar *ngIf="toggleAddVPG" [(visible)]="toggleAddVPG" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-rsv2-promote-vpg (callback)="toggleAddVPG = !toggleAddVPG" [valueProp]="valueProp"></app-rsv2-promote-vpg>
</p-sidebar>

<p-sidebar *ngIf="toggleDiscoveryQuestions" [(visible)]="toggleDiscoveryQuestions" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-discovery-questions
    [menu]="menu"
    [step]="repSimpleStep"
    [locked]="!valueProp.vp_can_edit"
    (callback)="toggleDiscoveryQuestions = !toggleDiscoveryQuestions"
    [showTranslate]="showTranslate"
    [valuePropId]="valueProp.id"
    [valueProp]="valueProp"
  ></app-discovery-questions>
</p-sidebar>

<p-sidebar *ngIf="toggleShare" [(visible)]="toggleShare" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-share-v2
    [fromRepSimple]="true"
    [embedded]="true"
    [noExternal]="noExternalShare"
    (callbackBack)="toggleShare = !toggleShare"
    [showTranslate]="showTranslate"
    [data]="valueProp"
  ></app-share-v2>
</p-sidebar>

<p-sidebar *ngIf="toggleCollaboration" [(visible)]="toggleCollaboration" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-collaboration
    (callbackBack)="toggleCollaboration = !toggleCollaboration"
    [showTranslate]="showTranslate"
    [data]="valueProp"
  ></app-collaboration>
</p-sidebar>

<p-sidebar *ngIf="AssetsMenu" [(visible)]="AssetsMenu" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-toolbar-assets
    *ngIf="AssetsMenu"
    [valuePropId]="+valueProp.id"
    [showAllTcoMetrics]="showAllTcoMetrics"
    [calculatorPublicKey]="valueProp.calculator_public_key"
    [isValueTracker]="isValueTracker"
    [businessReview]="businessReview"
    [businessReviewRequired]="businessReviewRequired"
  ></app-toolbar-assets>
</p-sidebar>

<p-sidebar *ngIf="showClone" [(visible)]="showClone" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <app-vp-clone [valueProp]="valueProp" (callbackCancel)="showClone = !showClone"></app-vp-clone>
</p-sidebar>

<p-sidebar *ngIf="promoteToRealization" [(visible)]="promoteToRealization" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-promote-realization [valueProp]="valueProp" (callback)="promoteToRealization = false"></app-promote-realization>
</p-sidebar>

<p-sidebar *ngIf="showLogs && valueProp && valueProp.id && valueProp.id !== null" [(visible)]="showLogs" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <div class="sidebar_header">
    {{ trans.trans.logs.value }}
  </div>
  <app-log [showTranslate]="showTranslate" [showHelp]="false" [contextualHelp]="[]" [valueProp]="valueProp"></app-log>
</p-sidebar>

<p-sidebar *ngIf="showAssetDeliverables && valueProp" [(visible)]="showAssetDeliverables" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.finalCustomerDeliverables.value }}
    </div>
    <app-vpdashboard-assets-deliverables [valuePropId]="valueProp.id" [showTranslate]="showTranslate" [showHelp]="false"> </app-vpdashboard-assets-deliverables>
  </div>
</p-sidebar>

<p-sidebar *ngIf="showCollateral" [(visible)]="showCollateral" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.addCollateralHeading.value }}
    </div>
    <app-dashboard-collateral
      [hideHeader]="true"
      [can_edit]="valueProp.vp_can_edit"
      [valueProp]="valueProp"
      [showTranslate]="showTranslate"
      [showHelp]="false"
      [valuePropId]="valueProp.id"
    ></app-dashboard-collateral>
  </div>
</p-sidebar>
