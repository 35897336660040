import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class TrackValueDashboardTranslation extends TranslationsBaseService {
  public config: any = {
    component: 'TrackValueDashboardComponent',
    display_name: 'Track Value Dashboard',
    description: 'View the dashboard of a value tracker',
  };

  public trans = this.typeObjectKeys({
    valueRealized: 'Value Realized',
    addNew: 'Add new',
    dashboard: 'Dashboard',
    renameTracker: 'Rename Tracker',
    assignedBy: 'Assigned by',
    threeYearValue: 'Expected 3 year value',
    expected: 'Expected',
    yearValue: 'year value',
    expectedValueToDate: 'Expected Value to Date',
    achievedValueToDate: 'Achieved Value to Date',
    trendOverTime: 'Trend over time',
    editDataForPeriod: 'Edit Data for Period',
    enterMeasurementData: 'Enter Measurement Data',
    trackValue: 'Track Value',
    thousands: 'K',
    millions: 'M',
    billions: 'B',
    trillions: 'T',
    quintillions: 'Q',
    quarter: 'Q',
    achieved: 'Achieved',
    currency: 'Currency',
    currencyThousands: 'Currency Thousands',
    currencyMillions: 'Currency Millions',
    currencyBillions: 'Currency Billions',
    seconds: 'Seconds',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    months: 'Months',
    years: 'Years',
    percent: 'Percent',
    basisPoints: 'Basis Points',
    miles: 'Miles',
    number: 'Number',
    weeks: 'Weeks',
    goalName: 'Goal Name',
    linkedFactors: 'Linked Factors',
    baselineValue: 'Baseline Value',
    targetValue: 'Target Value',
    trend: 'Trend',
    enterValue: 'Enter Value',
    unitType: 'Unit Type',
    goal: 'Goal',
    goals: 'Goals',
    benefit: 'Benefit',
    benefits: 'Benefits',
    successCriteria: 'Success Criteria',
    survey: 'Survey',
    cost: 'Cost',
    proofPoints: 'Proof Points',
    keyGoals: 'Key Goals',
    allGoals: 'All Goals',
    name: 'Name',
    answer: 'Answer',
    save: 'Save',
    cancel: 'Cancel',
    search: 'Search',
    selectBusinessReviewPeriod: 'Select Business Review Period',
    selectSurveyTemplate: 'Select your Survey Template',
    chooseOne: 'Choose One',
    valueRealizedTracking: 'Value Realized Tracking',
    customerCheckIn: 'Customer Check-In',
    revenueOptimization: 'Revenue Optimization',
    newSurvey: 'New Survey',
    selectPeriod: 'Select Period',
    respondent: 'Respondent',
    created_by: 'Completed By',
    completed_date: 'Completion Date',
    business_review_name: 'Survey Period',
    edit: 'Edit',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    editBusinessReview: 'Edit business review',
    editSurvey: 'Edit Survey',
    businessReviewError: 'Please select a business review before selecting a template',
    expectedVsAchieved: 'Expected vs. Achieved Value to Date',
    expectedVsByGoal: 'Expected vs. Achieved Value by Goal',
    expectedVsByBenefit: 'Expected vs. Achieved Value by Benefit',
    completed_by: 'Completed By',
    trackValueLongTitle: 'Enter and Track Actual Value Achieved against original Value Proposition: ',
    trackerTitleLong: 'View Performance across Measurement Periods for',
    longHelperText:
      'Click the Enter Measurement Data button to enter data for the next measurement milestone. After entering measurement data, the charts and values will update and show data for the most recent measurement period. To edit previous periods, select the period you want to edit from the Data for Period dropdown and then click the pencil to edit . You can share this tracker with other internal users or you can download outputs to facilitate customer conversations. Additionally, you can click the gear icon to edit tracker settings including baseline and target values if needed.',
    addNewData: 'Add New Data',
    goalNameSaved: 'Goal name saved',
    nameUpdateSuccess: 'Name updated successfully',
    originalGoalName: 'Original Goal Name',
    businessReviewNameSaved: 'Business review name saved',
    periodOfPerformance: 'Period of Performance',
    editDate: 'Edit End Date',
    editName: 'Edit Period Name',
    businessReviewDateSaved: 'End date updated successfully',
    editExistingData: 'Edit existing data',
    saveChanges: 'Save Changes',
    deleteLatest: 'Delete Latest Review',
    confirmMessageBegin: 'Are you sure you want to delete',
    deleteMessage: 'Delete Business Review',
  });
}
