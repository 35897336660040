<div class="row" *ngIf="loadingAssetTypes">
  <div class="col-xs-12">
    {{ trans.trans.loadingAssetTypes.value }}<br />
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
</div>

<form name="AccountAssetsForm" id="ngForm" #AccountAssetsForm="ngForm">
  <div class="row">
    <div class="col-xs-12">
      <label class="sidebar_label">{{ trans.trans.whatKindOfAssetAreYouUploading.value }}</label>
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.whatKindOfAssetAreYouUploading"
        (callback)="getTranslations()"
      ></app-editTranslation>

      <select
        class="form-control role-dropdown"
        [disabled]="mode == 'edit'"
        [(ngModel)]="selectedAsset.asset_type_id"
        placeholder="Select Asset Types"
        name="asset_type_id"
        type="text"
        (change)="notifyChanges()"
        required
      >
        <option disabled="disabled">{{ trans.trans.ChooseOne.value }}</option>
        <option *ngFor="let assetType of assetTypes" [ngValue]="assetType.id">{{ assetType.name }}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <fieldset class="checkbox custom-checkbox" (click)="selectedAsset.customer_facing = !selectedAsset.customer_facing; notifyChanges()">
        <input type="checkbox" class="custom-checkbox" [(ngModel)]="selectedAsset.customer_facing" name="customer_facing" style="margin-right: 10px" />
        <label class="sidebar_label" style="margin: auto">{{ trans.trans.willCustomersBeDownloadingThisAssetDirectly.value }}</label>
        <span class="check"></span>
      </fieldset>
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.willCustomersBeDownloadingThisAssetDirectly"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
  </div>

  <div class="row standard-paths-container" *ngIf="standardPaths?.length > 0">
    <div class="col-xs-12">
      <div class="col-xs-12 standard-path-title">
        {{ trans.trans.standardTemplates.value }}
      </div>
      <div *ngFor="let path of standardPaths" class="path-row col-xs-12">
        <div *ngIf="path.files?.length > 0" class="standard-path-name">
          <ng-container 
            *ngTemplateOutlet="expandCollapse; context:{ path: path }"
          >
          </ng-container>
          {{ path.name }}
          <i *ngIf="path.description !== ''" [matTooltip]="path.description" class="fa fa-info-circle"></i>
          
        </div>
        <div *ngIf="path.expanded && path.files?.length > 0">
          <div *ngFor="let file of path.files" class="col-xs-12 standard-file-row">
            <a class="link" target="_blank" [href]="file.link" download
              >{{ file.label }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #expandCollapse let-path="path">
  <td width="40px" style="width: 40px !important;" *ngIf="lightTheme">
    <div class="arrows" (click)="path.expanded = !path.expanded" *ngIf="!path.expand">
      <img class="vc-row-expand" src="assets/images/icons/expand-xf.svg"/>
    </div>
    <div class="arrows" (click)="path.expanded = !path.expanded" *ngIf="path.expand">
      <img class="vc-row-collapse" src="assets/images/icons/collapse-xf.svg"/>
    </div>
  </td>
  <!-- Non-light theme -->
  <td width="40px" style="width: 40px !important;" *ngIf="!lightTheme">
    <div class="arrows" (click)="path.expanded = !path.expanded" *ngIf="!path.expand">
      <img class="vc-row-expand" src="assets/images/icons/expand.svg"/>
    </div>
    <div class="arrows" (click)="path.expanded = !path.expanded" *ngIf="path.expand">
      <img class="vc-row-collapse" src="assets/images/icons/collapse.svg"/>
    </div>
  </td>
</ng-template>