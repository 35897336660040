import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { CaseStudy, CaseStudyImage, CaseStudyMediaObject, CaseStudySection, CaseStudySectionType } from '@shared/models/case-study.model';
import { TranslationObject } from '@shared/models/translation.model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AESectionsTranslations } from './ae_sections.translation';
import { StyleService } from 'app/style.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@services/notification.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { VideoEmbedService } from '@services/video-embed.service';

@Component({
  selector: 'app-ae-sections',
  templateUrl: './ae_sections.component.html',
  styleUrls: ['./ae_sections.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AESectionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() caseStudy: CaseStudy;
  @Input() sectionEdit: CaseStudySection = {
    id: undefined,
    account_case_study_id: undefined,
    section_type_id: undefined,
    name: undefined,
    description: undefined,
    sectionType: undefined,
  };
  @Input() type: string;
  @Input() mode: string;
  @Output() valueChange = new EventEmitter();
  @Output() closeCallback = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate = false;

  files: Blob[] = [];
  sectionTypes: CaseStudySectionType[] = [];
  sectionType: CaseStudySectionType = null;
  addSectionLoader = false;
  addSectionTypeLoader = false;
  isCollapsed = true;
  section: CaseStudySection;
  overlayImgVideo: CaseStudyMediaObject = {
    type: undefined,
    path: undefined,
    description: undefined,
  };
  embeddedVideoURL: string;

  editOverlayImgVideo = {
    type: undefined,
    path: undefined,
  };
  editEmbeddedVideoIFrame: string;

  @ViewChild('addSectionForm') addSectionForm: NgForm;

  @ViewChild('addSectionTypeForm') addSectionTypeForm: NgForm;

  @ViewChild('addMediaForm') addMediaForm: NgForm;

  @ViewChild('editMediaForm') editMediaForm: NgForm;

  sectionFormObj: Partial<CaseStudySection> = {
    sectionType: undefined,
    description: undefined,
    case_study_section_id: undefined,
    account_case_study_id: undefined,
  };

  newSectionType = {
    name: undefined,
    description: undefined,
  };

  mediaFormObj = {
    mediaType: undefined,
    media: undefined,
    url: undefined,
    mediaDescription: '',
    account_case_study_section_id: undefined,
    id: undefined,
  };

  sidebar_title: TranslationObject = { value: '' };
  sectionEditMediaList: CaseStudyImage[] = [];
  mediadescription = '';
  toggleEditSectionMediaForm = false;
  editFiles: { [klass: string]: any };
  mediaEditType = '';
  mediaEditDescription = '';

  mediatype = '';
  style2022$: Observable<boolean>;

  constructor(
    private factsService: FactsService,
    private solutionService: SolutionService,
    private http: HttpClient,
    private notification: NotificationService,
    public trans: AESectionsTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private styleService: StyleService,
    private embedService: VideoEmbedService
  ) {}

  ngOnInit() {
    this.getSections();

    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.style2022$ = this.styleService.style2022;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.openAddEditSection(this.mode === 'edit' ? this.sectionEdit : undefined);
    }, 0);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.sidebar_title = this.mode === 'add' ? this.trans.trans.addSection : this.trans.trans.editSection;
      });
  }

  setOverlayImageVideo(isEditList: boolean, sectionMedia: CaseStudyMediaObject, overlay: OverlayPanel, event) {
    if (isEditList) {
      this.editOverlayImgVideo = sectionMedia;
    }
    this.overlayImgVideo = sectionMedia;
    if (sectionMedia.type === 'link') {
      this.embeddedVideoURL = this.embedService.embed(sectionMedia.path);
    }
    overlay.toggle(event);
  }

  openAddEditSection(content: CaseStudySection) {
    if (this.sidebar_title) {
      this.sidebar_title = { value: this.trans.trans.addSection.value };
    }

    this.type = '';

    this.sectionEditMediaList = [];

    if (content) {
      this.sectionType = this.sectionTypes.find((type: any) => type.id === content.section_type_id);
      this.sidebar_title.value = this.trans.trans.editSection.value;
      this.section = content;

      this.addSectionForm.controls['description'].setValue(content.description);
      this.addSectionForm.controls['sectionType'].setValue(this.sectionType);
      this.addSectionForm.controls['case_study_section_id'].setValue(content.id);
      this.addSectionForm.controls['account_case_study_id'].setValue(content.account_case_study_id);

      this.sectionEditMediaList = content.case_study_section_images ? content.case_study_section_images : [];
    }
  }

  handleInputChange(event) {
    this.files = event.target.files;
  }

  addSection(form: NgForm) {
    this.addSectionLoader = true;

    const payload = new FormData();
    payload.append('company_id', this.caseStudy['company_id']);
    payload.append('case_study_id', this.caseStudy['id']);

    payload.append('sections[0][section]', form.controls.sectionType.value['name']);
    payload.append('sections[0][description]', form.controls.description.value);
    payload.append('sections[0][section_type_id]', form.controls.sectionType.value['id']);

    for (let i = 0; i < this.files.length; i++) {
      payload.append(this.sectionType['name'] + '[' + i + ']', this.files[i]);
      payload.append('description[' + this.sectionType['name'] + '][' + i + ']', this.section['mediadescription']);
    }

    this.solutionService
      .saveSection(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.addSectionLoader = false;

        this.addSectionForm.reset();
        this.valueChange.emit();
      });
  }

  updateSection(form: NgForm) {
    this.addSectionLoader = true;

    const payload = {
      type: form.controls.sectionType.value,
      description: form.controls.description.value,
      case_study_section_id: form.controls.case_study_section_id.value,
      account_case_study_id: this.caseStudy.id,
    };

    this.solutionService
      .updateSection(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.addSectionLoader = false;

        this.addSectionForm.reset();
        this.valueChange.emit();

        if (response.result) {
          this.notification.success(response.result, false);
        }
      });
  }

  addSectionType(form: NgForm) {
    this.addSectionTypeLoader = true;

    const payload = {
      name: form.controls.name.value,
      description: form.controls.description.value,
    };
    this.factsService
      .createSectionType(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.addSectionTypeLoader = false;
        this.isCollapsed = true;
        this.addSectionTypeForm.reset();
        this.getSections();
      });
  }

  closeAddSectionType() {
    this.isCollapsed = true;
    this.addSectionTypeForm.reset();
  }

  getSections() {
    this.factsService
      .CaseStudySectionTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if ((response as any).result && (response as any).result['sectionTypes']) {
          if (this.mode === 'edit') {
            this.sectionTypes = (response as any).result['sectionTypes'].filter((type: any) => type.id === this.sectionEdit.section_type_id);
            this.sectionEdit.sectionType = this.sectionTypes[0];
          } else {
            const existingSectionType = this.caseStudy.sections
              ? this.caseStudy.sections.reduce((acc, curr) => {
                  acc[curr.section_type_id] = true;
                  return acc;
                }, {})
              : {};
            this.sectionTypes = (response as any).result['sectionTypes'].filter((type) => !existingSectionType[type.id]);
          }
        }
      });
  }

  addMedia(form: NgForm) {
    const payload = new FormData();

    const typeMedia = form.controls.mediaType.value;
    payload.append('section_id', this.addSectionForm.controls.case_study_section_id.value);
    payload.append('type', typeMedia);
    payload.append('description', form.controls.mediaEditDescription.value);

    if (typeMedia == 'link') {
      payload.append('url', form.controls.url.value);
    } else {
      payload.append('images[0]', this.files[0]);
    }

    this.solutionService
      .addMediaToSection(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result) {
          this.notification.success(response.result.message, false);

          this.addMediaForm.reset();
          this.valueChange.emit();
        }
      });
  }

  downAsset(imageUrl: string) {
    this.http
      .get(imageUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return {
            filename: imageUrl.substr(imageUrl.lastIndexOf('/') + 1),
            data: res,
          };
        })
      )
      .subscribe(
        (res) => {
          const url = window.URL.createObjectURL((res as any).data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (error) => {},
        () => {}
      );
  }

  deleteSection(section_id: string) {
    this.solutionService
      .deleteCaeStudySection(section_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notification.success(response.result, false);
          this.valueChange.emit();
        }
      });
  }

  deleteMedia(media_id: string) {
    this.solutionService
      .deleteSectionMedia(media_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notification.success(response.result, false);
          this.valueChange.emit();
        }
      });
  }

  openEditMedia(media: CaseStudyImage) {
    this.mediaEditType = media.type;
    this.toggleEditSectionMediaForm = !this.toggleEditSectionMediaForm;
    setTimeout(() => {
      this.editMediaForm.controls.mediaEditDescription.setValue(media.description);
      this.editMediaForm.controls.editMediaType.setValue(media.type);
      this.editMediaForm.controls.mediaId.setValue(media.id);
      this.editMediaForm.controls.account_case_study_section_id.setValue(media.account_case_study_section_id);

      if (media.type === 'link') {
        this.mediaFormObj.url = media.path;
      } else {
        this.mediaFormObj.media = media.path;
      }
    }, 0);
  }

  editMedia(form: NgForm) {
    const payload = new FormData();

    const type = form.controls.editMediaType.value;
    if (form.controls.editMediaUrl || this.editFiles) {
      if (type === 'link') {
        payload.append('url', form.controls.editMediaUrl.value);
      } else if (type === 'media') {
        payload.append('images[0]', this.editFiles[0]);
      }
    }
    payload.append('description', form.controls.mediaEditDescription.value);
    payload.append('account_case_study_section_id', form.controls.account_case_study_section_id.value);
    payload.append('type', type);
    payload.append('id', form.controls.mediaId.value);

    this.solutionService
      .updateSectionMedia(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if ((response as any).result) {
          this.notification.success((response as any).result.message, false);
          this.toggleEditSectionMediaForm = false;
          this.valueChange.emit();
        }
      });
  }

  handleInputChangeEdit(event) {
    this.editFiles = event.target.files;
  }

  resetEditMedia() {
    this.editMediaForm.reset();
    this.editFiles = undefined;

    this.mediaFormObj = {
      mediaType: undefined,
      media: undefined,
      url: undefined,
      mediaDescription: '',
      account_case_study_section_id: undefined,
      id: undefined,
    };
  }
}
