import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findOriginalIndex'
})
export class FindOriginalIndexPipe implements PipeTransform {

  transform(id: string, allCurrentItems: any[], _forceRefresh: boolean): number {
    const returnIndex = allCurrentItems.findIndex(element => +element.id === +id)
    return returnIndex;
  }

}
