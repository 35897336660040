<div class="form-group col-md-12">
  <label>{{ trans.trans.bestMatch.value }}</label>
  <app-editTranslation
    *ngIf="showTranslate"
    [isTranslationV2]="true"
    [component]="trans.config.component"
    [transObj]="trans.trans.bestMatch"
    (callback)="getTranslations()"
  ></app-editTranslation>

  <div class="member-field">
    <div class="multi-select" *ngFor="let model of modelSuggestions; index as i">
      <div *ngIf="i === 1" class="other-options">  <label>{{ trans.trans.otherOptions.value }}</label>
      </div>
      <p-checkbox [value]="model.account_solution_id" [(ngModel)]="chosenModels"></p-checkbox>
      <span>{{ model.solution_name }}</span>
    </div>
  </div>
</div>

<div class="btn_container">
  <div class='row' *ngIf="loadingSave">
    <div class='col-xs-12'>
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
  </div>
  <div class="row" *ngIf="!loadingSave">
    <div class="col pull-right">
      <button class="btn btn_customizable" (click)="addSolutions(true)" [disabled]="chosenModels.length === 0">
        {{ trans.trans.replaceOptions.value }}
      </button>
      <button class="btn btn_customizable" (click)="addSolutions()" [disabled]="chosenModels.length === 0">
        {{ trans.trans.addSelected.value }}
      </button>
    </div>
  </div>
</div>
