import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BaselineType } from '@data/services/valueprop/models/baselineType.model';
import { CustomerType } from '@data/services/valueprop/models/customerType.model';
import { Currency, Situation } from '@data/services/valueprop/models/situation-opportunity.model';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { AccountService } from '@data/services/account/account.service';
import { Workflow } from '@data/services/workflow/models/workflow.interface';
import { NotificationService } from '@services/notification.service';
import { Company } from '@shared/models/company.model';
import { Solution, ValueProp } from '@shared/models/value-prop.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScenarioCombinedVPGTranslations } from './sc_vpg.translation';
import { cloneDeep } from 'lodash';
import { Model } from '@data/services/account/models/model.interface';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';

@Component({
  selector: 'app-dashboard-sc-vpg',
  styleUrls: ['./sc_vpg.component.scss'],
  templateUrl: './sc_vpg.component.html',
})
export class SCVPGComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() view = 'simple';
  @Input() valueProp: ValueProp;
  @Input() showTranslate = false;
  @Input() permissions: { [klass: string]: any };
  @Input() activeIndex = 0;
  @Input() showContinue: boolean = true;

  @Output() callback = new EventEmitter();
  @Output() callbackNext = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  @Output() funcCallback = new EventEmitter();
  @Output() back = new EventEmitter();

  showSituationEditing = false;

  hasModels = false;
  situationLoader = false;
  header_color = 'blue';
  showAccountEdit = false;
  showGuidedDiscovery = false;
  showModelsEditing = false;
  showAdvancedEditing = false;

  loader = false;
  company: Company;
  showEdit: boolean;
  showCategoryTypeLoader: boolean;
  selectedSolutionsLength: number;
  availableSolutions: any[] = [];
  showModelsLoader: boolean;
  selectedModels: Model[] = [];
  showCategoryLoader: boolean;
  situationLength: number;
  showActionLoaderModel: boolean;
  showSituation: boolean;
  user = 'system';
  grossProfitFeature: boolean;
  customerTypeFeature: boolean;
  opportunityIdFeature: boolean;
  aid: string;
  opportunityIdFeatureRequired: boolean;
  situation: Situation;
  originalSituation: Situation;
  term: number;
  originalTerm: number;
  currencyId: { id: string };
  originalCurrencyId: string;
  customerTypes: CustomerType[];
  showModels = true;

  baselineTypes: BaselineType[];
  advancedTerms: { name: string; term: string }[];
  image_url: string;
  fullImagePath: string;
  loading = false;

  scalers: any[] = [];
  scalersLoader: boolean;
  showSizeYourValue = false;

  showActionLoader: boolean;

  subscriptionsaveChanges: Subscription;

  is_default: string;
  currency: Currency;
  feature26: boolean;

  masterCalculateLoading = false;
  canEdit = false;
  ngUnsubscribe = new Subject();

  step = 0;
  steps = [{ done: false }, { done: false }, { done: false }];

  availableSituationsLoading = false;
  scalersLoading = false;

  q2answer = '';
  feature61 = false;
  showNext = false;

  step2_edit = false;
  feature87 = false;
  hideAdvancedOptions: boolean;
  crm: string;

  workflows: Workflow[];
  guidedDiscoveryEnabled: boolean;
  jumpToResultsFeature = false;
  waccPrecision: number = 0;

  constructor(
    public trans: ScenarioCombinedVPGTranslations,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    private workflowService: AccountWorkflowService,
    private accountService: AccountService,
    private configurableWorkflowService: ConfigurableWorkflowService,
    private cdr: ChangeDetectorRef,
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.configurableWorkflowService.selectedConversationWorkflow$.subscribe(() => {
      this.loader = true;
      this.cdr.detectChanges();
      this.loader = false;
    });

    this.feature61 = this.commonService.checkFeature('61');
    if (this.valueProp && this.valueProp.vmw_q_2) {
      this.q2answer = this.valueProp.vmw_q_2;
    }
    this.loading = true;
    this.header_color = sessionStorage.getItem('header_color');

    this.grossProfitFeature = this.commonService.checkFeature(13);
    this.customerTypeFeature = this.commonService.checkFeature(20);
    this.opportunityIdFeature = this.commonService.checkFeature(22);
    this.feature87 = this.commonService.checkFeature(87);
    this.guidedDiscoveryEnabled = this.commonService.checkFeature(124);
    this.hideAdvancedOptions = this.commonService.checkFeature(40);
    this.jumpToResultsFeature = this.commonService.checkFeature(109);

    this.aid = sessionStorage.getItem('aid');
    if (this.aid !== '20') {
      this.opportunityIdFeatureRequired = false;
    }
    this.funcReload();
    if (this.commonService.checkFeature('26')) {
      this.feature26 = true;
    }

    this.canEdit = this.valueProp.vp_can_edit;
    (this.selectedModels as unknown as Solution[]) = this.valueProp.solutions;
    this.vpgContinue();

    if (this.guidedDiscoveryEnabled) {
      this.getWorkflows();
    }

    if (!this.hideAdvancedOptions) {
      this.getWACCPrecision();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleAdvanced() {
    this.showAdvancedEditing = !this.showAdvancedEditing;
    this.getValuePropSituation(this.valueProp.id);
  }

  closeAdvanced(): void {
    this.showAdvancedEditing = false;
    this.situation = cloneDeep(this.originalSituation);
    this.term = this.originalTerm;
    this.currencyId.id = this.originalCurrencyId;
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  vpgContinue(data?) {
    if (this.valueProp.scenario_done && this.valueProp.scenario_done != '0') {
      this.activeIndex = 1;
      this.steps[0].done = true;
      if (data) {
        this.selectedModels = Array.isArray(data)
          ? data.map((model) => ({
              ...model,
              account_solution_id: model.solutionId,
            }))
          : [];
      }
    }

    if (this.valueProp.situation_done == '1') {
      this.valuepropService.refreshDiscoveryQuestions$.next(true);
      this.activeIndex = 2;
      this.steps[1].done = true;
    }

    if (this.valueProp.scalers_done == '1') {
      this.activeIndex = 2;
      this.steps[2].done = true;
    }
    if (this.activeIndex == 2 && this.steps[2].done) {
      this.funcReload();
      this.showNext = true;
    }
    this.loading = false;
  }

  resetSteps() {
    this.steps = [{ done: false }, { done: false }, { done: false }];
  }

  closeGuided() {
    this.showModels = false;
    this.showGuidedDiscovery = false;
    this.valuepropService.refreshDashboard.next('');
    setTimeout(() => {
      this.showModels = true;
    }, 1000);
  }

  funcReload() {
    this.getValuePropCompany(this.valueProp.id);
    this.getCustomerTypes();
    this.getBaselineTypes();
    this.getValuePropSituation(this.valueProp.id);
    this.getTerms();
  }

  getValuePropCompany(id) {
    this.loader = true;
    this.valuepropService
      .getValuePropCompany(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.loader = false;
          this.company = response.result.company;
        }
      });
  }

  funcUpdateCompany(company) {
    this.valuepropService
      .updateCompany(this.company.id, company)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          if (!response.result.success) {
            this.showEdit = false;
            this.notificationService.error(response.result.message, false);
          } else {
            this.showEdit = false;
            this.company = { ...company };
            this.showAccountEdit = false;
            this.notificationService.success('Company info updated successfully', false);
          }
        }
      });
  }

  /**************************************************
   * ADVANCED
   */

  getOpportunityId() {
    this.valuepropService
      .getOpportunityId(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.situation.opportunity_id = response.result.opportunity_id;
        }
      });
  }

  getValuePropSituation(id) {
    this.valuepropService
      .getSituation(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.term = Number(response.result.term);
          this.originalTerm = this.term;
          this.currencyId = { id: response.result.currency_type_id };
          this.originalCurrencyId = this.currencyId.id;
          this.situation = response.result;
          this.originalSituation = cloneDeep(this.situation);
          this.currency = this.situation['currency_types'].filter((x) => x['id'] === this.situation['currency_type_id'])[0];
          if (this.opportunityIdFeature) {
            this.getOpportunityId();
          }
        }
      });
    this.loading = false;
  }

  getCustomerTypes() {
    this.valuepropService
      .getCustomerTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.customerTypes = response.result;
        }
      });
  }

  getBaselineTypes() {
    this.valuepropService
      .getBaselineTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.baselineTypes = response.result;
        }
      });
  }

  getTerms() {
    this.valuepropService
      .getTerms(sessionStorage.getItem('aid'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.advancedTerms = response.result;
        }
      });
  }

  getWACCPrecision(): void {
    this.accountService.getAccountFactorWACCPrecision().subscribe((precision) => {
      this.waccPrecision = precision || 0;
    });
  }

  updateAdvanced() {
    const situation = {
      baseline_type_id: this.situation.baseline_type_id,
      customer_type_id: this.situation.customer_type_id,
      deployment_time: this.situation.deployment_time,
      gross_profit: this.situation.gross_profit,
      currency_type_id: this.currencyId.id,
      number_format: this.situation.number_format,
      conversion_rate: this.situation.conversion_rate,
      term: this.term,
      wacc: this.situation.wacc,
    };

    if (this.commonService.checkFeature('22')) {
      this.opportunityIdFeature = true;
    }

    if (this.situation.opportunity_id) {
      situation['opportunity_id'] = this.situation.opportunity_id;
    }

    this.loader = true;
    this.valuepropService
      .updateAdvanced(this.valueProp.id, situation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.showAdvancedEditing = false;
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.loader = false;
        this.valuepropService.refreshDashboard.next('');
        this.showAdvancedEditing = false;
        this.originalSituation = cloneDeep(this.situation);
        this.originalTerm = this.term;
        this.originalCurrencyId = this.currencyId.id;
      });
  }

  funcCombinedModelsScalers() {
    let arr = [];
    return new Promise((resolve) => {
      if (this.commonService.checkFeature('56')) {
        resolve(true);
        return true;
      } else {
        try {
          arr = this.availableSolutions.filter((x) => x.isChecked == true);
        } catch (e) {
          this.notificationService.error('Please select at least 1 model', false);
          resolve(false);
          return false;
        }
        if (!arr.length) {
          this.notificationService.error('Please select at least 1 model', false);
          resolve(false);
          return false;
        }
      }

      if (this.is_default == '1') {
        for (let s = 0; s < this.scalers.length; s++) {
          const scale = this.scalers[s];
          if (scale.greater_than_zero && (scale.value == 0 || !scale.value)) {
            this.notificationService.error(scale.name + ' is required', false);
            return false;
          }
        }
        const payload2 = {
          value_prop_scalers: this.scalers,
          angular_dev: 1,
        };
        const searchLimit = this.valueProp.id;
        this.scalersLoader = true;
        this.valuepropService
          .createScalers(searchLimit, payload2)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }

  closeAccount() {
    this.showAccountEdit = false;
  }

  closeGuidedDiscovery() {
    this.showGuidedDiscovery = false;
  }

  setConversionRateField(event: { value: any }) {
    this.situation = { ...this.situation, conversion_rate: event.value.rate };
  }

  getWorkflows(): void {
    this.workflowService.getAccountWorkflows(true).subscribe((workflows) => {
      this.workflows = workflows;
    });
  }
}
