import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from '../value-position/value-position.translation';

@Component({
  selector: 'app-choose-adventure',
  templateUrl: './choose-adventure.component.html',
  styleUrls: ['./choose-adventure.component.scss']
})
export class ChooseAdventureComponent implements OnInit, OnDestroy {
  public navigationRoute = '';
  public existingVp = false
  private baseRoute = 'valueposition';
  private id = null;
  private unsubscribe$ = new Subject<void>();
  public workflows: ConversationWorkflow[] = [];

  constructor(
    private commonService: CommonService, 
    public trans: DefaultTranslations, 
    private route: ActivatedRoute,
    private translationService: TranslationsV2Service
    ) { 
      this.getTranslations();
      this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.getTranslations();
        if(params && params.value_prop_id) {
          this.existingVp = true;
          this.id = params.value_prop_id;
        } 
        this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
        this.navigationRoute = this.commonService.getLinkVPRoute(this.baseRoute, this.id, 4);
      })
    }

  ngOnInit(): void {}

  private  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
