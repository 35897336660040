import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Company } from '@shared/models/company.model';
import { Paginator } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-company-clone',
  templateUrl: './search-companyv3.component.html',
  styleUrls: ['./search-companyv3.component.scss'],
})
export class SearchCompanyCloneComponent implements OnInit, OnDestroy, OnChanges {
  @Input() companyDetails: Company;
  @Output() funcCallback = new EventEmitter();

  addCompany = false;

  companiesList: Array<Company> = [];
  companiesPaginatedList: Array<Company> = [];
  companyList = true;
  detailedDescription = true;
  companyId: number;
  payload: object;
  showCompanyAdd = false;
  fullImagePath: string;
  callbkSearch: any;
  quickCompanyDetailsDescription = true;

  pageNumber: number;
  pageNumberSearch = 1;

  pageLimit: number;
  pageLimitSearch: number | string = 10;
  searchTypErr = false;
  order = 'asc';
  sortBy = 'name';
  modalReference: NgbModalRef;
  companyname: string;
  companiesNoResult = false;
  showActionLoader = false;
  crm: Company;
  sfoide: string;
  aid: string;
  rowsPerPage = 10;

  contextualHelp: string;
  enableSaveBtn: boolean;

  opportunityId: string;
  accountDefinedId: string;

  loading = false;

  ngUnsubscribe = new Subject();
  feature89 = false;

  @ViewChild('p') paginator: Paginator;

  constructor(
    private accountService: AccountService,
    private companyService: CompanyService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public trans: Translations,
    private valuepositionService: ValuepositionService
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    this.fullImagePath = this.commonService.getLoaderImageUrl();

    const crmTemp = sessionStorage.getItem('crm');

    if (this.crm) {
      this.crm = JSON.parse(crmTemp);
      this.sfoide = this.crm.sfoide;
      if (this.crm.company_id) {
        this.companyId = parseInt(this.crm.company_id, 10);

        this.getCompanyDetail();
      }
      if (this.crm.opportunity_id) {
        this.opportunityId = this.crm.opportunity_id;
      }
      if (this.crm.account_defined_id) {
        this.accountDefinedId = this.crm.account_defined_id.toString();
      }
    } else {
      this.crm = null;
    }
  }

  ngOnInit() {
    this.feature89 = this.commonService.checkFeature(89);

    const crmTemp = sessionStorage.getItem('crm');
    this.aid = sessionStorage.getItem('aid');

    if (this.crm) {
      this.crm = JSON.parse(crmTemp);
      this.sfoide = this.crm.sfoide;
      if (this.crm.company_id) {
        this.companyId = parseInt(this.crm.company_id, 10);

        this.getCompanyDetail();
      }
      if (this.crm.opportunity_id) {
        this.opportunityId = this.crm.opportunity_id;
      }
      if (this.crm.account_defined_id) {
        this.accountDefinedId = this.crm.account_defined_id.toString();
      }
    } else {
      this.crm = null;
    }

    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.contextualHelp = response['Model'];
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  refreshTranslation(): void {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnChanges(): void {
    if (Object.keys(this.companyDetails).length !== 0 && this.companyDetails.constructor === Object) {
      this.quickCompanyDetailsDescription = false;
      this.detailedDescription = false;
    }
  }

  openAddCompany(content): void {
    this.modalReference = this.modalService.open(content, {
      size: 'lg',
      windowClass: 'deleteModal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  getRecentSearchComp(): void {
    if (this.companyname && this.companyname !== null && this.companyname !== '') {
      this.companyList = true;
      this.companiesNoResult = false;
      this.showActionLoader = true;
      this.payload = {
        searchTerm: this.companyname,
        publicOnly: 0,
        synopsisOnly: 1,
      };
      this.pageLimitSearch = 'all';
      this.payload['limit'] = this.pageLimitSearch;
      this.payload['page'] = this.pageNumberSearch;
      this.payload['sortBy'] = this.sortBy;
      this.payload['order'] = this.order;
      this.accountService
        .getSearchCompanies(this.payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (response) => {
            this.showActionLoader = false;
            this.showCompanyAdd = true;
            if (response.result && response.result.data && response.result.data.length > 0) {
              this.companiesList = response.result.data;
              this.companiesPaginatedList = this.companiesList.slice(0, this.rowsPerPage);
              this.companyList = false;
              this.detailedDescription = true;
              const out = {
                recordsTotal: response.result.total,
                recordsFiltered: response.result.total,
                data: [],
              };

              this.callbkSearch = out;
              this.showActionLoader = false;
              this.resetPagination();
            } else {
              this.showActionLoader = false;
              this.companiesNoResult = true;
              this.quickCompanyDetailsDescription = true;
            }
            this.showActionLoader = false;
          },
          (error) => {
            this.notificationService.error(error.result, false);
          }
        );
    } else {
      this.showActionLoader = false;
      this.companiesList = [];
      this.companiesNoResult = true;
      this.companyList = true;
    }
  }

  funcContinue(): void {
    this.loading = true;
    let valueProp = {};

    if (this.aid === '25012') {
      if (this.crm.opportunity_id && !this.opportunityId) {
        valueProp = {
          company_id: this.companyId,
          opportunity_id: this.crm.account_defined_id,
          is_value_prop: false,
        };
      } else if (this.accountDefinedId && !this.opportunityId) {
        valueProp = {
          company_id: this.companyId,
          account_defined_id: this.accountDefinedId,
          is_value_prop: false,
        };
      } else if (this.opportunityId && this.accountDefinedId) {
        valueProp = {
          company_id: this.companyId,
          opportunity_id: this.opportunityId,
          account_defined_id: this.accountDefinedId,
          is_value_prop: false,
        };
      } else {
        valueProp = {
          company_id: this.companyId,
          is_value_prop: false,
        };
      }
    } else {
      valueProp = {
        company_id: this.companyId,
        is_value_prop: false,
      };

      if (this.crm.sfoide) {
        valueProp['sfoide'] = this.crm.sfoide;
      }
    }
    const cxo = sessionStorage.getItem('cxo_deck');
    valueProp['cxo_deck'] = cxo;

    this.valuepositionService
      .createValueProp(valueProp)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.loading = false;
          this.funcCallback.emit({ valuePropId: result.result.value_prop.id });
        }
      });
  }

  selectCompany(id, name): void {
    this.companyList = false;
    this.companyId = id;
    this.showCompanyAdd = false;
    this.funcCallback.emit({ id: this.companyId, name: name });
  }

  getCompanyDetail(): void {
    this.showActionLoader = true;
    this.companyService
      .getCompanySummary(this.companyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.companyDetails = result.result.company;

        this.quickCompanyDetailsDescription = false;
        this.detailedDescription = false;
        this.showActionLoader = false;
      });
  }

  cancelCompany(): void {
    this.addCompany = false;
  }

  companyCreated($event): void {
    this.companyname = $event.name;
    this.getRecentSearchComp();
    this.addCompany = false;
  }

  enableSave($event): void {
    if ($event === 'true') {
      this.enableSaveBtn = true;
    } else {
      this.enableSaveBtn = false;
    }
  }

  paginate(event) {
    this.companiesPaginatedList = this.companiesList.slice(event.first, event.first + event.rows);
  }

  resetPagination() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }
}
