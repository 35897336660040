import { Injectable } from '@angular/core';

@Injectable ()

export class CostTranslations {

    public config: any = {
        component: 'cost',
        display_name: 'Cost Solution',
        description: 'All the costs for a solution'
    };

    public trans: any = {
        costLiteral: 'Costs',
        cost: 'Unit Price',

        addNew: 'Add New',
        search: 'Search',
        lineItem: 'Line Item',
        unitPrice: 'Unit Price',
        name: 'Line Item',
        edit: 'Edit',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
        view: 'View',
        edit_translations: 'Edit Translations'
    };
}
