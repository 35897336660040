import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { Subject } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { ExplainedVpDashTranslations } from './explained.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-explained',
	templateUrl: './explained.component.html',
})
export class ExplainedComponent implements OnInit, OnDestroy {
	@Input() explained: {[klass: string]: any}[] = [];
	@Output() callbackNoData = new EventEmitter();
	list: TreeNode[];

	ngUnsubscribe: Subject<any> = new Subject();

	constructor(
		private translationService: TranslationsV2Service,
		public trans: ExplainedVpDashTranslations,
		private commonService: CommonService

	) {
		// this.translateService.setDefaultLang('defaultLang');
		// this.trans.funcLoadOverrides(this.trans);

	}

	ngOnInit() {

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		if (!this.explained) { this.callbackNoData.emit(""); }
		let tmp = [];

		for (let e = 0; e < this.explained.length; e++) {
			let elem = this.explained[e];
			if (elem && elem.length) {
				for (let ee = 0; ee < elem.length; ee++) {
					let elem_sub = elem[ee];
					let children = [];
					if (elem_sub.children && elem_sub.children.length) {
						for (let c = 0; c < elem_sub.children.length; c++) {
							let child = elem_sub.children[c];

							let children2 = [];
							if (child.children && child.children.length) {
								for (let cc = 0; cc < child.children.length; cc++) {
									let child2 = child.children[cc];

									let children3 = [];
									if (child2.children && child2.children.length) {
										for (let ccc = 0; ccc < child2.children.length; ccc++) {
											let child3 = child2.children[ccc];

											// insert here
											let children4 = [];
											if (child3.children && child3.children.length) {
												for (let cccc = 0; cccc < child3.children.length; cccc++) {
													let child4 = child3.children[cccc];

													// insert here
													let children5 = [];
													if (child4.children && child4.children.length) {
														for (let ccccc = 0; ccccc < child4.children.length; ccccc++) {
															let child5 = child4.children[ccccc];

															// insert here
															let children6 = [];
															if (child5.children && child5.children.length) {
																for (let cccccc = 0; cccccc < child5.children.length; cccccc++) {
																	let child6 = child5.children[cccccc];

																	// insert here
																	let children7 = [];
																	if (child6.children && child6.children.length) {
																		for (let ccccccc = 0; ccccccc < child6.children.length; ccccccc++) {
																			let child7 = child6.children[ccccccc];

																			// insert here
																			let children8 = [];
																			if (child7.children && child7.children.length) {
																				for (let cccccccc = 0; cccccccc < child7.children.length; cccccccc++) {
																					let child8 = child7.children[cccccccc];
																					// insert here
																					let children9 = [];
																					if (child8.children && child8.children.length) {
																						for (let ccccccccc = 0; ccccccccc < child8.children.length; ccccccccc++) {
																							let child9 = child8.children[ccccccccc];
																							// insert here
																							children9.push({ data: { id: ccccccccc, name: child9.name, value: child9.value, factor_type: child9.factor_type } })

																						}
																					}
																					children8.push({ children: children9, data: { id: cccccccc, name: child8.name, value: child8.value, factor_type: child8.factor_type } })

																				}
																			}
																			children7.push({ children: children8, data: { id: ccccccc, name: child7.name, value: child7.value, factor_type: child7.factor_type } })

																		}
																	}
																	children6.push({ children: children7, data: { id: cccccc, name: child6.name, value: child6.value, factor_type: child6.factor_type } })

																}
															}
															children5.push({ children: children6, data: { id: ccccc, name: child5.name, value: child5.value, factor_type: child5.factor_type } })

														}
													}

													children4.push({ children: children5, data: { id: cccc, name: child4.name, value: child4.value, factor_type: child4.factor_type } })

												}
											}

											children3.push({ children: children4, data: { id: ccc, name: child3.name, value: child3.value, factor_type: child3.factor_type } })
										}
									}
									children2.push({ children: children3, data: { id: cc, name: child2.name, value: child2.value, factor_type: child2.factor_type } })
									//*************************************************** */

								}
							}
							children.push({ children: children2, data: { id: c, name: child.name, value: child.value, factor_type: child.factor_type } })
						}


					}
					let out = { children: children, data: { rowNode: ee, id: ee, name: elem_sub.name, value: elem_sub.value, factor_type: elem_sub.factor_type, expanded: false, expandedIcon: "fa fa-chevron-down", collapsedIcon: "fa fa-chevron-right" } }

					tmp.push(out);
				}
			}
		}
		this.list = tmp;
		// this.explained = list;
	}

	ngOnDestroy() {

	}


	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}
}