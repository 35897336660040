import { Component, OnInit, OnDestroy, Input, Inject, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { SalesManagerDashboardService } from "@data/services/sales_manager/sales_manager.service";
import { NotificationService } from "@services/notification.service";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { DOCUMENT } from '@angular/common';
import { CommonService } from "@data/services/common/common.service";
import { SalesManagerDashboardTranslations } from "../sales_manager.translation";
import { SalesManagerFunctions } from "../sales_manager.functions";
import { TreeNode } from "primeng/api";
import { StyleService } from "app/style.service";
@Component({
	selector: 'app-dashboard-sales-manager-org-view',
	templateUrl: './org.component.html'
})
export class DashboardSalesManagerOrgViewComponent implements OnInit, OnDestroy {
	@Input() term: any[] = [];
	@Input() customRange:{start_date:string,end_date:string};
	@Output() dataGeoLoaded = new EventEmitter<boolean>();

	cachFirstLoad: boolean = true;
	loadingDataGeo: boolean = false;
	account_id: string = "";
	cache: any[] = [];
	ngUnsubscribe = new Subject();
	geoIndex: number = 0;
	selectedGeoData: any;
	details: any;
	selectedTreeUser: any;
	chartData: any;
	chart_loading: boolean = false;
	colsValueProps: any[] = [];
	orgView: string = "revenue";
	sm_expanded: any[] = [];
	asset_template_id: any;
	first = 0;
	treeTableData:TreeNode[];

	primary_color: string = "";

	dteStart: string;
	dteEnd: string;
	term_filter: any;
	cachePercent: number = 0;
	cacheProgress: number = 0;
	pageLimit: number = 10;
	rowLimit: any[] = [];
	isDev: boolean = false;
	style2022$: Observable<boolean>;

	constructor(
		private salesManagerDashboardService: SalesManagerDashboardService,
		private notificationService: NotificationService,
		@Inject(DOCUMENT) private document: Document,
		private CommonService: CommonService,
		public trans: SalesManagerDashboardTranslations,
		private cd: ChangeDetectorRef,
		private smFunctions: SalesManagerFunctions,
		private styleService: StyleService
	) {

	}

	ngOnInit() {
		this.isDev = this.CommonService.isDev$;
		this.style2022$ = this.styleService.style2022;
		this.funcBuildPageLimit();
		this.primary_color = localStorage.getItem("button_color");
		this.account_id = sessionStorage.getItem("aid");
		this.getAssetId();
		this.term_filter = this.term[5];


		this.term.forEach(elem => {
			elem.geoLoading = true;
		})

		this.funcFirstLoad(5);

		this.smFunctions.toggleOrgTerm.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.geoIndex = res;
			this.term_filter = this.term[this.geoIndex];
			if(res===8){
				let payload = {
					"start_date": this.customRange.start_date,
					"end_date": this.customRange.end_date
				}

				this.salesManagerDashboardService.getDataGeo(this.account_id,payload).pipe(take(1)).subscribe(res=>{
					this.cache[this.geoIndex] = res.result.data;
					this.findExpanded(this.cache[this.geoIndex], (elem) => {
						this.getDataGeo(elem);
	
					});
				});
			}else{
				this.findExpanded(this.cache[this.geoIndex], (elem) => {
					this.getDataGeo(elem);

				});
			}
		})
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}


	funcFirstLoad(index) {
		let elem = this.term[index];
		let payload = {
			"start_date": elem.start_end,
			"end_date": elem.end_date
		}

		this.salesManagerDashboardService.getDataGeo(this.account_id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			elem.geoLoading = false;
			this.cache[elem.index] = res.result.data;
			this.cd.detectChanges();

			this.geoIndex = elem.index;
			this.selectedGeoData = this.cache[elem.index][0].data;
			this.term_filter = this.term[elem.index];
			this.dteStart = this.term[elem.index].start_end;
			this.dteEnd = this.term[elem.index].end_date;

			this.loadDrilldownDetails(this.selectedGeoData);
			this.getDataGeo(null);
		})
	}

	getDataGeo(elem) {
		if (this.cachFirstLoad) {
			this.loadingDataGeo = true;

			this.term.forEach(elem => {
				let payload = {
					"start_date": elem.start_end,
					"end_date": elem.end_date
				}

				this.salesManagerDashboardService.getDataGeo(this.account_id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
					if (elem.index !== 5) {
						this.cache[elem.index] = res.result.data;
					}

					elem.geoLoading = false;
					if (elem.index == 5) {
						this.cd.detectChanges();
						this.geoIndex = elem.index;
						this.selectedGeoData = this.cache[elem.index][0].data;
						this.term_filter = this.term[elem.index];
						this.dteStart = this.term[elem.index].start_end;
						this.dteEnd = this.term[elem.index].end_date;

						this.loadDrilldownDetails(this.selectedGeoData);
					}
				})

				let interval = setInterval(() => {
					let loaded = false;
					this.term.forEach(elem => {
						if (elem.geoLoading) {
							loaded = true;
						} else {
							this.cachePercent = this.funcCountLoaded() / (this.term.length - 1) * 100;
							this.cacheProgress = this.funcCountLoaded();
						}
						if (elem.geoLoading) {
							loaded = true;
						}
					})
					if (!loaded) {
						this.loadingDataGeo = false;
						this.dataGeoLoaded.emit(true);
						this.cd.detectChanges();
						this.cachFirstLoad = false;
						this.notificationService.success("Organization Details loaded and cached successfully", false);
						clearInterval(interval);
					}
				}, 500)
			})


		} else {
			if (elem) {
				this.selectedGeoData = elem;
				this.term_filter = this.term[this.geoIndex];
				this.dteStart = this.term[this.geoIndex].start_end;
				this.dteEnd = this.term[this.geoIndex].end_date;
				this.loadDrilldownDetails(this.selectedGeoData);
			}
		}
	}

	loadDrilldownDetails(elem) {

		let container = this.document.querySelector('.container_interior');
		container.scrollTop = 0;

		if (elem && elem.id) {
			sessionStorage.setItem("sm_selected", elem.id);
		}

		this.chartData = null;
		this.selectedTreeUser = elem.id;
		this.details = elem;
		this.details.profile_color = elem.profile_color;
		this.details.chart = elem.chart;
		this.details.name = elem.first_name + " " + elem.last_name;
		this.details.email = elem.email;
		this.details.profile_path = elem.profile_pic_filepath;
		this.details.chart_data = elem.data && elem.data.chart_data ? elem.data.chart_data : elem.chart_data;
		this.details.total_revenue = elem.fmt_total_revenue;

		this.details.created = elem.total_vps_created ? elem.total_vps_created : 0;
		this.details.created_change = elem.table_data && elem.table_data.change ? elem.table_data.change : "none";
		this.details.created_percent_change = elem.table_data && elem.table_data.percent_change ? elem.table_data.percent_change : "0";

		this.details.modified = elem && elem.total_vps_modified ? elem.total_vps_modified : 0;
		this.details.modified_change = elem.total_vps_modified && elem.total_vps_modified.change ? elem.total_vps_modified.change : "none";
		this.details.modified_percent_change = elem.total_vps_modified && elem.total_vps_modified.percent_change ? elem.total_vps_modified.percent_change : "0";

		this.details.login = elem && elem.total_login ? elem.total_login : 0;
		this.details.login_change = elem.total_login && elem.total_login.change ? elem.total_login.change : "none";
		this.details.login_percent_change = elem.total_login && elem.total_login.percent_change ? elem.total_login.percent_change : "0";


		this.details.value_props_created = elem.table_data && elem.table_data.value_prop_created.data.length ? elem.table_data.value_prop_created.data : [];
		this.details.value_props_edited = elem.table_data && elem.table_data.value_prop_modified.data.length ? elem.table_data.value_prop_modified.data : [];
		this.details.logins = elem.table_data && elem.table_data.logins && elem.table_data.logins.data && elem.table_data.logins.data.length ? elem.table_data.logins.data : [];
		this.details.revenue = elem.table_data && elem.table_data.revenue && elem.table_data.revenue.data && elem.table_data.revenue.data.length ? elem.table_data.revenue.data : [];
		this.funcToggleGeoData(this.orgView);
		this.cd.detectChanges();
	}

	funcToggleGeoData(val) {
		this.chartData = null;
		let elem: Details = this.details;
		let labels = [];
		let data = [];
		let colors = this.CommonService.getChartColors();
		let color = [];
		let colorCount = 0;
		this.first = 0;
		switch (val) {
			case "revenue":
				colorCount = 0;
				this.colsValueProps = [
					{ field: "company_name", header: "Customer Name" },
					{ field: "opportunity_id", header: "Opportunity ID" },
					{ field: "created", header: "Date Created" },
					{ field: "modified", header: "Date Last Edited" },
					{ field: "revenue_fmt", header: "Value to VMWare" }
				]
				this.details.value_props = this.details.revenue;

				this.orgView = "revenue";
				sessionStorage.setItem("orgView", this.orgView);
				if (elem.chart_data && elem.chart_data['revenue']) {

					elem.chart_data['revenue'].forEach(el => {
						labels.push(el.first_name + " " + el.last_name);
						data.push(el.count);
						color.push(colors[colorCount]);
						colorCount++;
						if (colorCount > colors.length) { colorCount = 0; }
					});
					this.chartData = {
						labels: labels,
						datasets: [{
							label: "Value to VMWare",
							data: data,
							backgroundColor: color
						}]
					}

					this.details.chart_options = {
						legend: {
							display: false,
							position: "bottom",
							labels: {
								fontSize: 10
							},
							usePointStyle: true
						},
						scales: {
							yAxes: [{
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true,
								}
							}],
							xAxes: [{
								scaleLabel: {
									display: true,
									labelString: 'Value to VMWare'
								  },
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true,
									callback: function (value) {
										let number = value;
										let numberLength = number.toString().length;
										let unit = '';

										if (numberLength >= 4 && numberLength <= 6) {
											unit = 'K';
										}
										else if (numberLength >= 7 && numberLength <= 9) {
											unit = 'M';
										}
										else if (numberLength >= 10 && numberLength <= 13) {
											unit = 'B';
										}

										else if (numberLength >= 14 && numberLength <= 17) {
											unit = 'T';
										}

										switch (unit) {
											case 'K':
												return number / 1e3 + unit;
											case 'M':
												return number / 1e6 + unit;
											case 'B':
												return number / 1e9 + unit;
											case 'T':
												return number / 1e12 + unit;
											default:
												return number;
										}
									}
								}
							}]
						},
						tooltips: {
							callbacks: {
								label: function (tooltipItem, data) {
									var total = data.datasets[tooltipItem.datasetIndex];
									let number = total.data[tooltipItem.index];
									let numberLength = number.toFixed(0).toString().length;
									let unit = '';

									if (numberLength >= 4 && numberLength <= 6) {
										unit = 'K';
									}
									else if (numberLength >= 7 && numberLength <= 9) {
										unit = 'M';
									}
									else if (numberLength >= 10 && numberLength <= 13) {
										unit = 'B';
									}

									else if (numberLength >= 14 && numberLength <= 17) {
										unit = 'T';
									}

									switch (unit) {
										case 'K':
											return (number / 1e3).toFixed(2) + unit;
										case 'M':
											return (number / 1e6).toFixed(2) + unit;
										case 'B':
											return (number / 1e9).toFixed(2) + unit;
										case 'T':
											return (number / 1e12).toFixed(2) + unit;
										default:
											return number;
									}
								}
							}
						}
					}
				}
				this.chart_loading = false;
				break;

			case "created":
				colorCount = 0;
				this.colsValueProps = [
					{ field: "company_name", header: "Customer Name" },
					{ field: "opportunity_id", header: "Opportunity ID" },
					{ field: "value_to_us_fmt", header: "Value to VMWare" },
					{ field: "totalbenefits_fmt", header: "Total Benefits" },
					{ field: "created", header: "Date Created" }
				]
				this.details.value_props = this.details.value_props_created;

				this.orgView = "created";
				sessionStorage.setItem("orgView", this.orgView);
				if (elem.chart_data && elem.chart_data['vps_created']) {

					elem.chart_data['vps_created'].forEach(el => {
						labels.push(el.first_name + " " + el.last_name);
						data.push(el.count);
						color.push(colors[colorCount]);
						colorCount++;
						if (colorCount > colors.length) { colorCount = 0; }
					});
					this.chartData = {
						labels: labels,
						datasets: [{
							label: "Business Cases Created",
							data: data,
							backgroundColor: color
						}]
					}

					this.details.chart_options = {
						legend: {
							display: false,
							position: "bottom",
							labels: {
								fontSize: 10
							},
							usePointStyle: true
						},
						scales: {
							yAxes: [{
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true
								}
							}],
							xAxes: [{
								scaleLabel: {
									display: true,
									labelString: 'Business Cases Created'
								  },
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true
								}
							}]
						}
					}
				}
				this.chart_loading = false;
				break;

			case "modified":
				colorCount = 0;
				this.colsValueProps = [
					{ field: "company_name", header: "Customer Name" },
					{ field: "opportunity_id", header: "Opportunity ID" },
					{ field: "value_to_us_fmt", header: "Value to VMWare" },
					{ field: "totalbenefits_fmt", header: "Total Benefits" },
					{ field: "created", header: "Date Created" },
					{ field: "modified", header: "Date Last Edited" }
				]
				this.details.value_props = this.details.value_props_edited;
				this.orgView = "modified";
				sessionStorage.setItem("orgView", this.orgView);

				if (elem.chart_data && elem.chart_data['vps_modified']) {

					elem.chart_data['vps_modified'].forEach(el => {
						labels.push(el.first_name + " " + el.last_name);
						data.push(el.count);
						color.push(colors[colorCount]);
						colorCount++;
						if (colorCount > colors.length) { colorCount = 0; }
					});

					this.chartData = {
						labels: labels,
						datasets: [{
							label: "Business Cases Edited",
							data: data,
							backgroundColor: color
						}]
					}

					this.details.chart_options = {
						legend: {
							display: false,
							position: "bottom",
							labels: {
								fontSize: 10
							},
							usePointStyle: true
						},
						scales: {
							yAxes: [{
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true
								}
							}],
							xAxes: [{
								scaleLabel: {
								display: true,
								labelString: 'Business Cases Edited'
							  },
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true
								}
							}]
						}
					}
				}
				this.chart_loading = false;
				break;

			case "login":
				colorCount = 0;
				this.colsValueProps = [
					{ field: "login_date", header: "Login Date", align: "left" }
				]
				this.orgView = "login";
				sessionStorage.setItem("orgView", this.orgView);
				this.details.value_props = this.details.logins;
				if (elem.chart_data && elem.chart_data['logins']) {
					elem.chart_data['logins'].forEach(el => {
						labels.push(el.first_name + " " + el.last_name);
						data.push(el.count);
						color.push(colors[colorCount]);
						colorCount++;
						if (colorCount > colors.length) { colorCount = 0; }
					});

					this.chartData = {
						labels: labels,
						datasets: [{
							label: "Logins",
							data: data,
							backgroundColor: color
						}]
					}

					this.details.chart_options = {
						legend: {
							display: false,
							position: "bottom",
							labels: {
								fontSize: 10
							},
							usePointStyle: true
						},
						scales: {
							yAxes: [{
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true
								}
							}],
							xAxes: [{
								scaleLabel: {
									display: true,
									labelString: 'Logins'
								  },
								gridLines: {
									display: true
								},
								ticks: {
									beginAtZero: true
								}
							}]
						}
					}
				}
				this.chart_loading = false;
				break;

		}
		this.details.chart_data = elem.chart_data;

	}

	findExpanded(data: any[], cb) {

		let user_id = sessionStorage.getItem("sm_selected");

		let arr = JSON.parse(sessionStorage.getItem("sm_expanded"));
		if (!arr || !arr.length) {
		  arr = [user_id];
		}

		if (arr && arr.length) {
			data.forEach(elem => {
				if (elem.data.id == user_id) {
					this.selectedGeoData = elem.data;
					if (cb) { cb(elem.data); }
				}
				let id = elem.data.id;
				if (arr.includes(id)) {
					elem.expanded = true;
				} else if (!!elem.expanded && !arr.includes(id)) {
					elem.expanded = false;
				}
				if (elem.children && elem.children.length) {
					this.findExpanded(elem.children, cb);
				}
			});

		}
	}

	onNodeExpand(event) {
		const setExpand = () => {
			this.sm_expanded.push(event.node.data.id);
			let out = JSON.stringify(this.sm_expanded);
			sessionStorage.setItem("sm_expanded", out);
		};
		if (event.node.parent) {
			const parentIdOpened = this.sm_expanded.indexOf(event.node.parent.data.id);
			if (parentIdOpened !== this.sm_expanded.length - 1) {
				this.sm_expanded.splice(parentIdOpened + 1);
				setExpand();
				this.findExpanded(this.cache[this.geoIndex], () => {
					if (this.selectedGeoData && this.selectedGeoData.id) {
						this.loadDrilldownDetails(this.selectedGeoData);
					}
				});
			} else {
				setExpand();
			}
		} else {
			setExpand();
		}
	}

	onNodeCollapse(event) {
		this.sm_expanded.splice(this.sm_expanded.indexOf(event.node.data.id), 1);
		let out = JSON.stringify(this.sm_expanded);
		sessionStorage.setItem("sm_expanded", out);
	}

	downloadReport() {
		const userId = sessionStorage.getItem('uid');
		const token = sessionStorage.getItem('vcu');
		let media_url = this.CommonService.getMediaServerName();
		let url = media_url + "assets/report_generator.php?user_id=" + userId + "&token=" + token + "&account_asset_template_id=" + this.asset_template_id + "&start_date=" + this.customRange.start_date + "&end_date=" + this.customRange.end_date + "&organization_user_id=" + this.selectedTreeUser + "&quarter_name=" + (this.term_filter.custom ? "" : this.term_filter.name);
		window.open(url, "_blank");
	}

	highlightUserColor(elem) {
		if (elem.id == this.selectedTreeUser) { return true; }
		return false;
	}

	getAssetId() {
		this.salesManagerDashboardService.downloadAsset("23").pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.asset_template_id = res.result.id;
		});
	}

	funcCleanupPChartDate(dte) {
		let s = new Date(dte);
		let ss = s.getFullYear() + "-" + ("0" + (s.getMonth() + 1)).slice(-2) + "-" + s.getDate();
		return ss;
	}

	funcCountLoaded() {
		let count = 0;
		this.term.forEach(elem => {
			if (elem.label) {
				if (!elem.geoLoading) {
					count++;
				}			}
		})
		return count - 1;
	}

	setRowLimit(num) {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit() {
		this.rowLimit = [
			{ label: "10", icon: this.pageLimit == 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: "25", icon: this.pageLimit == 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: "50", icon: this.pageLimit == 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: "100", icon: this.pageLimit == 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		]
	}
}


class Details {
	name: string = "";
	email: string = "";
	profile_path: string = "";
	profile_color: string = "";
	chart: number = 0;
	created: number = 0;
	modified: number = 0;
	login: number = 0;
	value_props: any;
	value_props_created: any;
	value_props_edited: any;
	logins: any;
	total_revenue: any;
	revenue: any[];

	chart_data: {};
	chart_options: {};
	created_change: string = "none";
	created_percent_change: string = "0";

	modified_change: string = "none";
	modified_percent_change: string = "0";

	login_change: string = "";
	login_percent_change: string = "0";
}