import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { filter, finalize, pluck, take, takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitFlyoutTrackerTranslation } from './benefit-flyout-detail.translation';
import { defaultUnitTypes } from '@shared/utils';
import { Metric } from '@data/services/valuerealization/models/value-tracker.model';
import { ConfirmationService } from 'primeng/api';
import { BusinessReviewDataWithNote } from '@data/services/valuerealization/models/business-review.model';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';

@Component({
  selector: 'app-benefit-flyout-detail',
  templateUrl: './benefit-flyout-detail.component.html',
  styleUrls: ['./benefit-flyout-detail.component.scss'],
})
export class BenefitFlyoutDetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input() set benefitData(ben: Metric) {
    this.benefit = { ...ben };
    this._benefitData = ben;
    this.loading = false;
  }
  _benefitData: Metric;
  @Input() valuePropId: string;
  @Input() term: number = 3;
  @Input() can_edit: boolean = false;

  @Output() callback = new EventEmitter();
  @Output() closeSidebar = new EventEmitter();

  ngUnsubscribe = new Subject();

  benefit: Metric;
  benefitTableData = [];
  benefitTableColumns = [];
  valuePropBenefit: Metric;

  feature14 = false;
  showCurrentEffort = false;
  loading = false;
  loadingUpdate = false;
  editBenefitName = true;
  latestVariance: number;
  benefitUpdated: number | null = null;
  scratchpadOpen: number | null = null;
  showNoteSidebar = false;
  brsWithNotes: BusinessReviewDataWithNote[] = [];
  canEdit = false;
  editExpected = false;
  resetChanges = 0;

  constructor(
    private commonService: CommonService,
    private valuePropService: ValuepropService,
    private valuerealizationService: ValuerealizationService,
    private notificationService: NotificationService,
    public trans: BenefitFlyoutTrackerTranslation,
    private confirmationService: ConfirmationService,
    private translationService: TranslationsV2Service,
    private businessReviewService: TrackerBusinessReviewService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.businessReviewService.brsWithNotes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((brWithNotes) => {
      this.brsWithNotes = brWithNotes;
      this.cd.detectChanges();
    });
    this.businessReviewService.canEdit.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.canEdit = response;
    });
  }

  ngOnChanges() {
    this.feature14 = this.commonService.checkFeature(14);

    this.loadBenefitData(this.valuePropId);
  }

  ngOnDestroy() {
    this.businessReviewService.brsWithNotes$.next(null);
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
      });
  }

  loadBenefitData(vpId: string) {
    this.loading = true;
    this.valuePropService
      .getValuePropBenefits(vpId, 1, null)
      .pipe(
        pluck('result'),
        filter((ben) => !!ben),
        finalize(() => (this.loading = false)),
        take(1)
      )
      .subscribe((data) => {
        const benefit: unknown = data?.metrics ? data.metrics.find((ben) => +ben.account_solution_metric_id === +this.benefit.account_solution_metric_id) : null;
        this.valuePropBenefit = benefit as Metric;
        this.benefit = {
          ...this.valuePropBenefit,
          ...this._benefitData,
        };

        const lastBr = this.benefit.business_review_column[this.benefit.business_review_column.length - 1];

        this.latestVariance = +lastBr.realized_benefits_to_date - +lastBr.expected_benefits_to_date;

        this.funcBuildTableColumns();

        this.extractScratchpadData(this.benefit);
        this.editExpected = false;
        this.benefitUpdated = null;
      });
  }

  confirm(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: this.trans.trans.confirmCancel.value,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelChanges();
      },
      reject: () => {},
    });
  }

  funcSaveFactorName(data) {
    let id = '';
    switch (data.type) {
      case 'driver':
        id = data.driver_factor_id;
        break;
      case 'improvement':
        id = data.improvement_factor_id;
        break;
      case 'financial':
        id = data.financial_factor_id;
        break;
    }

    let payload = {
      factor_id: id,
      override_name: data.formula,
    };

    this.valuerealizationService
      .postSaveFactorName(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success('Factor name saved successfully', false);
        this.loadBenefitData(this.valuePropId);
      });
  }

  funcBuildTableColumns() {
    let cols = [];

    const term = Number(this.term);
    this.benefitTableColumns = [];
    cols.push({ field: 'formula', style: 'left', header: this.trans.trans.expectedValue.value, width: '400px', editable: false });

    cols.push({ field: 'calc', style: 'center', header: '+ / -', width: '40px', editable: false });
    if (this.showCurrentEffort && this.feature14) {
      cols.push({ field: 'cost', style: 'left', header: this.trans.trans.currentEffort.value, width: '120px', editable: true });
    }
    if (this.showCurrentEffort && this.feature14) {
      cols.push({ field: 'future_effort', style: 'left', header: this.trans.trans.with.value + ' ', width: '120px', editable: true });
    }
    for (let i = 1; i <= term; i++) {
      let year = { field: 'year' + i, style: 'left', header: `${this.trans.trans.year.value}${i}`, width: '120px', editable: i == 1 ? true : false };
      cols.push(year);
    }
    this.benefitTableColumns = cols;
  }

  extractScratchpadData(ben) {
    this.checkCurrentEffort(ben.unit_type_id, ben.impact_type_id);
    const term = Number(this.term);
    this.benefitTableData = [];
    let r = {};

    if (this.benefit.driver_factor) {
      r = {};
      r['factorTypeId'] = 1;
      r['hasScratchpad'] = this.benefit.has_driver_scratchpad == 1 ? true : false;
      r['formula'] = this.benefit.driver_factor_baseline_name;
      r['cost'] = '';
      r['editable'] = true;
      r['type'] = 'driver';
      r['calc'] = 'x';
      r['description'] = this.benefit.driver_description;
      r['driver_factor_id'] = this.benefit.driver_factor_id;
      r['driver_source_type_id'] = this.benefit.driver_source_type_id;
      r['driver_override'] = this.benefit['year' + 1 + '_driver'];
      for (let i = 1; i <= term; i++) {
        r['year' + i + '_fmt'] = this.benefit['year' + i + '_driver_fmt'];
        r['year' + i] = i == 1 ? this.benefit['year' + i + '_driver'] : this.benefit['year' + i + '_driver_fmt'];
      }
      this.benefitTableData.push(r);
    }

    if (this.benefit.improvement_factor) {
      r = {};
      r = {
        formula:
          (this.benefit.improvement_factor ? this.benefit.improvement_factor_baseline_name : this.trans.trans.improvement.value) +
          ' (' +
          defaultUnitTypes[this.benefit.unit_type_id] +
          ')',
      };

      r['factorTypeId'] = 3;
      r['hasScratchpad'] = this.benefit.has_improvement_scratchpad == 1 ? true : false;
      r['editable'] = true;
      if (this.feature14) {
        r['cost'] = this.benefit.current_effort_fmt;
        r['cost_fmt'] = this.benefit.current_effort_fmt;
      }
      r['calc'] = 'x';
      r['type'] = 'improvement';
      r['future_effort'] = this.benefit.future_effort;
      r['future_effort_fmt'] = this.benefit.future_effort_fmt;
      r['description'] = this.benefit.improvement_description;
      r['improvement_factor_id'] = this.benefit.improvement_factor_id;
      r['impact'] = this.benefit.impact_fmt;
      r['impact_source_type_id'] = '1';
      r['value_prop_metric_id'] = this.benefit.value_prop_metric_id;

      for (let i = 1; i <= term; i++) {
        r['year' + i + '_fmt'] = this.benefit.abbr == '%' ? this.benefit.impact_fmt + this.benefit.abbr : this.benefit.abbr + this.benefit.impact_fmt;
        r['year' + i] = i == 1 ? this.benefit.impact_fmt : this.benefit.abbr == '%' ? this.benefit.impact_fmt + this.benefit.abbr : this.benefit.abbr + this.benefit.impact_fmt;
      }
      this.benefitTableData.push(r);
    }

    if (this.benefit.financial_factor) {
      r = {};
      r = { formula: this.benefit.financial_factor_baseline_name };
      r['factorTypeId'] = 2;
      r['hasScratchpad'] = this.benefit.has_financial_scratchpad == 1 ? true : false;
      r['editable'] = true;
      r['type'] = 'financial';
      r['calc'] = 'x';
      r['description'] = this.benefit.financial_description;

      r['financial_factor_id'] = this.benefit.financial_factor_id;
      r['financial_override'] = this.benefit.financial_override;
      r['financial_source_type_id'] = this.benefit.financial_source_type_id;

      r['cost'] = '';
      for (let i = 1; i <= term; i++) {
        r['year' + i + '_fmt'] = this.benefit.financial_value_fr;
        r['year' + i] = i == 1 ? this.benefit.financial_value : this.benefit.financial_value_fr;
      }
      this.benefitTableData.push(r);
    }
    r = {};
    r = { formula: 'Phasing' };

    r['calc'] = '=';
    r['cost'] = '';
    r['editable'] = false;
    r['hasScratchpad'] = false;
    for (let i = 1; i <= term; i++) {
      r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
      r['year' + i] = +this.benefit['metric_phase_' + i] - +this.benefit['metric_phase_0'] + '%';
    }
    this.benefitTableData.push(r);

    r = {};
    r = { formula: 'Total' };

    r['cost'] = '';
    r['type'] = 'total';
    r['hasScratchpad'] = false;
    r['editable'] = false;
    for (let i = 1; i <= term; i++) {
      r['year_' + i + '_fmt'] = this.benefit['metric_phase_' + i];
      r['year' + i] = this.benefit['year' + i + '_benefits_fmt'];
    }
    this.benefitTableData.push(r);
    this.benefitTableData = this.benefitTableData.filter((x) => +x?.improvement_factor_id !== 2 && +x?.financial_factor_id !== 2 && +x?.driver_factor_id !== 2);
  }

  checkCurrentEffort(unit_type, impact_type) {
    const utype = parseInt(unit_type);

    if (impact_type == 1 || utype === 8 || utype === 9 || utype === 10 || utype === 11 || utype === 12 || utype === 13) {
      this.showCurrentEffort = true;
    } else {
      this.showCurrentEffort = false;
    }
  }

  triggerUpdate() {
    this.callback.emit();
    this.loading = true;
  }

  closeSidebarCallback() {
    this.closeSidebar.emit();
  }

  save(): void {
    this.loadingUpdate = true;
    this.valuerealizationService.saveBenefitPerformance$.next(true);
  }

  toggleEdit() {
    this.editExpected = !this.editExpected;
  }

  cancelChanges() {
    this.editExpected = false;
    this.benefitUpdated = null;
    this.resetChanges = ++this.resetChanges;
  }

  updateBenefitStatus(i: number) {
    this.benefitUpdated = i;
  }

  updateScratchpadOpen(i: number) {
    this.scratchpadOpen = i;
  }

  closeNotesSidebar() {
    this.showNoteSidebar = false;
  }
}
