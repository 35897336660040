import { Injectable } from '@angular/core';

@Injectable()
export class RSV2ToolbarTranslations {
  public config: any = {
    component: 'RSV2Toolbar',
    display_name: 'Rep Simple toolbar',
    description: 'Rep simple toolbar options including the dropdown menu',
  };

  public trans: any = {
    download: 'Download',
    clone_this_value_prop: 'Clone this Value Prop',
    view_all_details: 'View All Details',
    actions: 'Actions',
    lock: 'Lock Value Prop',
    unlock: 'Unlock Value Prop',
    share: 'Share',
    next: 'Next',
    clone: 'Clone',
    back: 'Back',
    results_text:
      'This step gives a summary of the results. Click on the percentage breakdown to the right of the donut to view the benefit calculation in detail.Note that you may need to scroll down depending on your screen resolution.',
    edit_translations: 'Edit Translations',
    download_assets: 'Download Assets',
    discovery_questions: 'Discovery Questions',
    discovery: 'Discovery',
    edit_status: 'Click to edit status',
    tour: 'Tour',
    content1: "Let's create a Value Prop.",
    content2: "Start with the basics below and then we'll get into the details.",
    support: 'Support',
    titleNavigation: 'Navigation',
    contentNavigation: 'Click back to any previous step at any time.',
    titleAction: 'Action Items',
    contentAction: 'Here are your action items for sharing, status, downloads, and locking.',
    titleInfo: 'More Information',
    contentInfo: 'In this section you can click any of the labels for more information.',
    titleBenefitsChart: 'Benefits Chart',
    contentBenefitsChart: `This chart provides a breakdown of value by cagegory.
        You can click on each value in the legend to see the specific benefits.`,
    titleRoi: 'ROI',
    contentRoi: 'This chart provides a breakdown of your benefits vs. costs with ROI over the term of the evaluation.',
    titleCashflow: 'Cashflow',
    contentCashflow: 'This Cash Flow table provides context and basics for most calculations of value you will need.',
    addCosts: 'Add Costs',
    vpg: 'Add to Group',
    reviewCosts: 'Review Costs (Optional)',
    reviewResults: 'Review Results',
    vpLockedSucces: 'Value Prop Locked Successfully',
    vpUnlockedSucces: 'Value Prop Unlocked Successfully',
    statusUpdateSuccess: 'Status Updated successfully',
    logginError: 'Error logging in. Please try again',
    click_to_change_status: 'Click to change Status',
    cloneInProgress: 'Cloning in progress...',
    defineScenario: 'Define Scenario',
    aboutYou: 'About You',
    reviewBenefits: 'Review Benefits',
    prioritizeBenefits: 'Prioritize Benefits',
    provideNumbers: 'Provide Numbers',
    promoteToRealization: 'Promote To Realization',
    discoverValuePrompter: 'Discovery Value Prompter',
    downloadPdf: 'Download PDF',
    editTracker: 'Edit Tracker Settings',
    addVPToGroup: 'Add this Value Prop to a Value Group',
    noAssets: 'No Assets Found',
    valueCloudAssets: 'Value Execution Assets',
    supportContent: 'Supporting Content',
    proofPoints: 'Proof Points',
    openLink: 'Open link in a new window',
    logs: 'Logs',
    finalCustomerDeliverables: 'Final Customer Deliverables',
    addCollateral: 'Add Collateral',
    addCollateralHeading: 'Add / Modify Value Prop Specific Collateral',
    addBusinessReview: 'No review data to generate Value Execution Assets, add new data to display the assets',
    hypothesisActions: 'Value Prop Actions',
    discoveryAlert: 'You have unanswered discovery questions',
    collaborateInternally: 'Collaborate Internally',
    collaborateWithCustomer: 'Collaborate with Customer',
  };
}
