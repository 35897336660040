import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {
    
    transform(num: number): string | number {

        const typeNumber = Number(num);

        if (isNaN(typeNumber)) {
            return num;
        }

        const withDecimal = typeNumber % 1 !== 0 ? typeNumber.toFixed(2) : typeNumber;

        return withDecimal;
    }

}
