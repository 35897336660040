<div [hidden]="!isLoggedIn$ || hash_id" style="overflow: hidden">
  <ul *ngIf="isAdmin" class="menu accordion-menu" style="z-index: 1; overflow-x: hidden">
    <li>
      <a (click)="showTranslate = !showTranslate" style="height: 60px"
        ><i class="menu-icon fa fa-exchange" style="float: left" aria-hidden="true"></i>
        <p class="clickable" style="float: left; max-width: 90px; display: block">{{ trans.trans.edit_translations | translate }}</p>
      </a>
    </li>
  </ul>
  <ul *ngIf="trans" class="menu accordion-menu" style="z-index: 1; overflow-x: hidden">
    <li [routerLinkActive]="['active']">
      <a routerLink="/dashboard" (click)="funcToggleMenu()"
        ><span class="menu-icon fa fa-home" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.dashboard }}</p>
        <span class="active-page"></span>
        <app-editTranslation *ngIf="showTranslate" key="menu.dashboard"></app-editTranslation>
      </a>
    </li>

    <li *ngIf="isValueInsightUser" [routerLinkActive]="['active']">
      <a routerLink="/scb" (click)="funcToggleMenu()"
        ><span class="fa fa-sticky-note" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.research }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.research"></app-editTranslation>
      </a>
    </li>

    <li *ngIf="isValuePositionUser" [routerLinkActive]="['active']">
      <a routerLink="/valueposition" (click)="funcToggleMenu()"
        ><span class="menu-icon fa fa-bullhorn" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.reach }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.reach"></app-editTranslation>
      </a>
    </li>
    <li *ngIf="isValuePropUser" [routerLinkActive]="['active']">
      <a [routerLink]="masterValuePropFeature ? ['/mastervalueprop'] : ['/valueprop']" (click)="funcToggleMenu()"
        ><span class="menu-icon fa fa-pie-chart" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.revise }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.revise"></app-editTranslation>
      </a>
    </li>
    <li *ngIf="isValueRealizationUser" [routerLinkActive]="['active']">
      <a routerLink="/valuerealization" (click)="funcToggleMenu()"
        ><span class="menu-icon icon-loop" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.retain | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.retain"></app-editTranslation>
      </a>
    </li>
    <li *ngIf="isValueRealizationUser && isDev" [routerLinkActive]="['active']">
      <a routerLink="/trackers" (click)="funcToggleMenu()"
        ><span class="menu-icon icon-loop" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.value_trackers | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.value_trackers"></app-editTranslation>
      </a>
    </li>
    <li *ngIf="isValueRealized2" [routerLinkActive]="['active']">
      <a routerLink="/valuerealized" (click)="funcToggleMenu()"
        ><span class="menu-icon icon-bar-chart" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.value_realized | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.value_realized"></app-editTranslation>
      </a>
    </li>

    <li *ngIf="feature31" [routerLinkActive]="['active']">
      <a routerLink="/vpg" (click)="funcToggleMenu()"
        ><span class="menu-icon fa fa-users" aria-hidden="true"></span>
        <p>Value Groups</p>
      </a>
    </li>
    <li *ngIf="isSolutionAdmin && !modelsV2" [routerLinkActive]="['active']">
      <a routerLink="/models" (click)="funcToggleMenu()"
        ><span class="menu-icon fa fa-puzzle-piece" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.model | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.model"></app-editTranslation>
      </a>
    </li>

    <li [routerLinkActive]="['active']">
      <a routerLink="/case_study" (click)="funcToggleMenu()"
        ><span class="menu-icon fa fa-users" aria-hidden="true"></span>
        <p>Case Studies</p>
      </a>
    </li>

    <li *ngIf="isSolutionAdmin && modelsV2" [routerLinkActive]="['active']">
      <a routerLink="/modelsv2" (click)="funcToggleMenu()"
        ><span class="menu-icon fa fa-puzzle-piece" style="width: 56px !important" aria-hidden="true"></span>
        <p>{{ trans.trans.model | translate }} V2</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.model"></app-editTranslation>
      </a>
    </li>
  </ul>

  <ul class="menu accordion-menu bottom-menu" style="z-index: 999; overflow-x: hidden" [ngStyle]="{ width: menuToggle ? '180px' : '60px' }">
    <li [routerLinkActive]="['active']" *ngIf="!isGuest">
      <a href="{{ lmsUrl }}" target="_blank"
        ><span class="menu-icon fa fa-graduation-cap" style="width: 56px !important" aria-hidden="true"></span>
        <p *ngIf="menuToggle">{{ trans.trans.learn | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.learn"></app-editTranslation>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a routerLink="/profile"
        ><span class="menu-icon fa fa-user-o" style="width: 56px !important" aria-hidden="true"></span>
        <p *ngIf="menuToggle">{{ trans.trans.profile | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.profile"></app-editTranslation>
      </a>
    </li>
    <li [routerLinkActive]="['active']" *ngIf="!isGuest">
      <a (click)="funcToggleSupport()" class="clickable"
        ><span class="menu-icon fa fa-life-ring" style="width: 56px !important" aria-hidden="true"></span>
        <p *ngIf="menuToggle">{{ trans.trans.support | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.support"></app-editTranslation>
      </a>
    </li>
    <li [routerLinkActive]="['active']" *ngIf="dlAdmin">
      <a routerLink="/dlAdmin"
        ><span class="menu-icon icon-support" style="width: 56px !important" aria-hidden="true"></span>
        <p *ngIf="menuToggle">{{ trans.trans.dl_only | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="menu.dl-only"></app-editTranslation>
      </a>
    </li>
    <li class="visible-xs">
      <a role="button" (click)="logout()"
        ><span class="menu-icon icon-key" style="width: 56px !important" aria-hidden="true"></span>
        <p *ngIf="menuToggle">{{ trans.trans.logout | translate }}</p>
        <app-editTranslation *ngIf="showTranslate" key="general.logout"></app-editTranslation>
      </a>
    </li>
  </ul>
</div>
