<div class="drop-list" cdkDropList [class.item-dragging]="dragging" (cdkDropListDropped)="droppedIntoList($event)">
  <div
    *ngFor="let item of items; let index = index"
    class="item"
    [class.selected]="isSelected(index)"
    cdkDrag
    [cdkDragDisabled]="disabled"
    (cdkDragStarted)="dragStarted($event, index)"
    (cdkDragEnded)="dragEnded()"
    (cdkDragDropped)="dropped($event)"
    (click)="select($event, index)"
  >
    <div *ngIf="!dragging || !isSelected(index)">
      <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: item, item: item, index: index, selected: isSelected(index) }"> </ng-container>
    </div>

    <div *cdkDragPreview>
      <div *ngFor="let sel of selections">
        <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: items[sel], item: sel }"> </ng-container>
      </div>
    </div>
  </div>
</div>
