import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Subscription, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { takeUntil } from 'rxjs/operators';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';
import * as $ from 'jquery';
import { Benefit, Benefits, Driver, Impact, Metric, Phase, Scale, Solution } from '@shared/models/value-prop.model';
import { UniqueType } from '@shared/models/account.models';
import { ValueCategory } from '@data/services/valueposition/models/value-category.interface';
import { Dropdown } from '@shared/models/dropdown.models';
import { BasicInfo } from '@shared/models/basic-info.model';


@Component({
	selector: 'app-add-benefits-model',
	templateUrl: './add-benefits-model.component.html',
	styleUrls: ['./add-benefits-model.component.scss']
})
export class AddBenefitsModelComponent implements OnInit, OnDestroy {
	@Input() valuePropId: string;
	@Input() selectedBenefit: Metric;

	unitTypeList: ExpenseType[] = [];
	unitType: string;
	solutionType: any;
	impactAreas: Array<Impact> = [];
	annualBenefitsList: Array<BasicInfo> = [];
	annualBenefits = "2";
	financialDriverFactorList: Array<Driver> = [];
	improvementTypeList: Array<BasicInfo> = [];
	financialDriverFactor: Driver;
	financialDriverFactorId: string;
	selectedImprovementType: BasicInfo[] = [];
	impactList: Array<Impact> = [];
	impact: string;
	scaleList: Array<Scale> = [];
	scale: string;
	driverFactorTypeList: Array<string> = [];
	driverFactorType: string;
	driverFactorList: Array<Driver> = [];
	driverFactor: Array<Driver> = [];
	driverFactorId: string;
	uniqueList: Array<UniqueType> = [];
	uniqueTypes: string;
	valueCategoryBenefits: ValueCategory | string;
	valueCategoryBenefitsList: Array<ValueCategory> = [];
	impactAreasList: Array<Impact> = [];
	noAreaSelect = true;
	impactAreaIdList: Array<string> = [];
	solutionsList: Array<Solution> = [];
	benefit: Phase = {
		"phase_0": 0,
		"phase_1": 0,
		"phase_2": 0,
		"phase_3": 0,
		"phase_4": 0,
	}
	currentEffort: string | number;
	benefitsName: string;
	benefitsDescription: string;
	searchText: string;
	driverBaselineAnswer: string;
	conservative: string;
	aggressive: string;
	financicalAnswer: string;
	probable: string;
	modalReference: NgbModalRef;
	solutionId: number;
	account_solution_id: string | number;
	newDriverFactor: Driver;
	FinancialFactor: string;
	newImprovementFactor: string;
	fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
	benefitsFeatures: string;
	dropdownSettings: Dropdown;
	dropdownSettings1: Dropdown;
	improvementTypeSettings: Dropdown;

	showActionLoader = false;
	showImprovementActionLoader = false;
	createDriverFlag = false;
	createImprovementFlag = false;
	createFactor = false;
	accountFeatures = false;
	showeditLoader = false;
	showCurrentEffort = false;

	subscriptionreadSolutionList: Subscription;
	subscriptiongetUnitTypes: Subscription;
	subscriptiongetAccrualTypes: Subscription;
	subscriptiongetFinancialTypes: Subscription;
	subscriptiongetScratchpadImprovementTypes: Subscription;
	subscriptiongetImpactTypes: Subscription;
	subscriptiongetScaleTypes: Subscription;
	subscriptiongetDriverFactorTypes: Subscription;
	subscriptiongetDriverFactor: Subscription;
	subscriptiongetUniqueTypes: Subscription;
	subscriptiongetValueCategories: Subscription;
	subscriptionsaveValuePropsBenefits: Subscription;
	subscriptionupdateValuePropsBenefits: Subscription;
	subscriptioncreateDriverFactor: Subscription;
	subscriptioncreateFinancialFactor: Subscription;
	subscriptioncreateImprovementFactor: Subscription;
	subscriptiongetAreaTypes: Subscription;

	ngUnsubscribe: Subject<any> = new Subject();

	constructor(
		private solutionService: SolutionService,
		private ValuepropService: ValuepropService,
		public activeModal: NgbActiveModal,
		private notificationService: NotificationService,
		private factsService: FactsService,
		private translateService: TranslateService,
		public trans: Translations

	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);

	}

	ngOnInit() {
		this.readUnitTypes();
		this.readAreaTypes();
		this.readAccrualTypes();
		this.readFinancialTypes(null);
		this.readImprovementTypes();
		this.readImpactTypes();
		this.readScaleTypes();
		this.readDriverFactorTypes();
		//this.readDriverFactor();
		this.readUniqueTypes();
		this.readSolutionList();

		this.dropdownSettings = {
			singleSelection: true,
			text: "Select DriverFactors",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			badgeShowLimit: "all",
			noDataLabel: "No Data Found...",
			searchBy: ['name'],
			labelKey: 'name',
			primaryKey: 'id',
			lazyLoading: false
		};

		this.dropdownSettings1 = {
			singleSelection: true,
			text: "Select Financial Factors",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			badgeShowLimit: "all",
			noDataLabel: "No Data Found...",
			searchBy: ['name'],
			labelKey: 'name',
			primaryKey: '',
			lazyLoading: false,

		};

		this.improvementTypeSettings = {
			singleSelection: true,
			text: "Select Improvement Type",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			badgeShowLimit: "all",
			noDataLabel: "No Data Found...",
			searchBy: ['name'],
			labelKey: 'name',
			primaryKey: '',
			lazyLoading: false,
		};

		this.accountFeatures = this.checkFeatures();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	checkFeatures() {
		let features = sessionStorage.getItem('features');
		let options = features.split(',');
		if (options.indexOf('14') >= 0) {
			return true;
		}
		return false;
	}

	checkCurrentEffort() {
		const utype = parseInt(this.unitType);
		
		if ((utype == 8) || (utype == 9) || (utype == 10) || (utype == 11) || (utype == 12) || (utype == 13)) {
			this.showCurrentEffort = true;
		} else {
			this.showCurrentEffort = false;
		}
	}
	readSolutionList() {
		this.ValuepropService.readSolutionList(this.valuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.solutionsList = response.result;
			}
			if (!this.solutionType) {
				this.solutionType = this.solutionsList[0]['account_solution_id'];
				this.account_solution_id = this.solutionsList[0]['account_solution_id'];
				this.readValueCategories(this.account_solution_id);
			}
		});
	}

	readUnitTypes() {
		this.solutionService.getUnitTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.unitTypeList = response.result;
			}
			if (!this.unitType) {
				this.unitType = this.unitTypeList[0]['id'];
			}
		});
	}

	readAreaTypes() {
		this.solutionService.getAreaTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.impactAreas = response.result;
			}

			if (this.selectedBenefit && this.selectedBenefit['areas'] && this.selectedBenefit['areas'].length > 0) {
				let areaIds = this.selectedBenefit['areas'].map(item => {
					return item['area_type_id'];
				});
				this.impactAreaIdList = areaIds;

				this.impactAreas = this.impactAreas.map(item => {
					if (this.impactAreaIdList.indexOf(item['id']) != -1) {
						this.impactAreasList.push(item);
						item['checked'] = true;
					}
					return item;
				});

				this.noAreaSelect = false;
			}
		});
	}

	readAccrualTypes() {
		this.solutionService.getAccrualTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.annualBenefitsList = response.result;
				
			}
			if (!this.annualBenefits) {
				this.annualBenefits = this.annualBenefitsList[1]['id'];
			}
		});
	}

	readFinancialTypes(params) {
		this.solutionService.getFinancialTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				
				this.financialDriverFactorList = response.result;
				if (params != null) {
					this.financialDriverFactor = this.financialDriverFactorList.find(item => {
						return item['id'] == params
					});
				}
			}
			if (!this.financialDriverFactor) {
				this.financialDriverFactorId = this.financialDriverFactorList[0]['id'];
			}
		});
	}

	readImprovementTypes(params?) {
		this.factsService.getScratchpadImprovementTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.improvementTypeList = response.result;
				this.selectedImprovementType.push(this.improvementTypeList[0]);
				if (params) {
					this.selectedImprovementType = this.improvementTypeList.filter(item => {
						return item['id'] == params;
					});
				}
			}
			if (!this.financialDriverFactor) {
				this.selectedImprovementType = [this.improvementTypeList[0]];
			}
		});
	};

	readImpactTypes() {
		this.solutionService.getImpactTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.impactList = response.result;
			}
			if (!this.impact) {
				this.impact = this.impactList[0]['id'];
			}
		});
	}

	readScaleTypes() {
		this.solutionService.getScaleTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.scaleList = response.result;
			}
			if (!this.scale) {
				this.scale = this.scaleList[0]['id'];
			}
		});
	}

	readDriverFactorTypes() {
		this.solutionService.getDriverFactorTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.driverFactorTypeList = response.result;
			}
			if (!this.driverFactorType) {
				this.driverFactorType = this.driverFactorTypeList[0]['id'];
				this.readDriverFactor(null);
			}
		});
	}

	readDriverFactor(params) {
		this.showeditLoader = true;
		let factorType = 'all';
		let aid = sessionStorage.getItem('aid');
		this.solutionService.getDriverFactor(factorType, aid).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.driverFactorList = response.result;

				if (params != null) {
					this.driverFactor = this.driverFactorList.filter(item => {
						return item['id'] == params
					});
				}

				if (this.selectedBenefit) {
					this.editBenefit();
				}
			}
			if (!this.driverFactor) {
				this.driverFactorId = this.driverFactorList[0]['id'];
			}

			this.showeditLoader = false;
		});
	}

	readUniqueTypes() {
		this.solutionService.getUniqueTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.uniqueList = response.result;
			}
			if (!this.uniqueTypes) {
				this.uniqueTypes = this.uniqueList[0]['id'];
			}
		});
	}

	readValueCategories(params) {
		this.solutionService.getValueCategories(params).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.valueCategoryBenefitsList = response.result.value_categories;
			}
			if (!this.valueCategoryBenefits && this.valueCategoryBenefitsList) {
				this.valueCategoryBenefits = this.valueCategoryBenefitsList[0]['value_category_id'];
			}
		});
	}

	saveBenefits(form: NgForm) {
		
		let benfitsObj = {
			"account_solution_id": parseInt(form.value.solutionType),
			"value_category_id": parseInt(form.value.valueCategoryBenefits),
			"impact_type_id": parseInt(form.value.impact),
			"metric_name": form.value.benefitsName,
			"current_effort": form.value.currentEffort,
			"scale_type_id": parseInt(form.value.scale),
			"unique_type_id": parseInt(form.value.uniqueTypes),
			"accrual_type_id": parseInt(form.value.annualBenefits),
			"description": form.value.description,
			"features": form.value.features,
			"area_type_id": this.impactAreaIdList.join(","),
			"driver_factor_id": parseInt(form.value.driverFactor[0].id),
			// "driver_factor_type_id": parseInt(form.value.driverFactorType),
			"driver_baseline_answer": form.value.driverBaselineAnswer,
			"conservative": form.value.conservative,
			"probable": form.value.probable,
			"aggressive": form.value.aggressive,
			"unit_type_id": parseInt(form.value.unitType),
			"financial_factor_id": parseInt(form.value.financialDriverFactor[0].id),
			"financial_baseline_answer": form.value.financicalAnswer,
			"value_prop_id": this.valuePropId,
			"improvement_factor_id": form.value.selectedImprovementType[0].id
		}

		if (!this.selectedBenefit) {
			this.saveBenefit(benfitsObj);
		}
		else {
			benfitsObj['account_solution_metric_id'] = this.selectedBenefit['account_solution_metric_id'];
			benfitsObj['value_prop_metric_id'] = this.selectedBenefit['id'];
			benfitsObj['vp_specific'] = 1;
			this.updateBenefit(benfitsObj);
		}
	}

	saveBenefit(benfitsObj) {
		this.ValuepropService.saveValuePropsBenefits(this.valuePropId, benfitsObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.ValuepropService.refreshDashboard.next('refreshDashboard');
				this.notificationService.success("Benefit Added successfully", false); // Params {message, islogout}
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
		});
	}

	updateBenefit(benfitsObj) {
		this.ValuepropService.updateValuePropsBenefits(this.valuePropId, benfitsObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.ValuepropService.refreshDashboard.next('refreshDashboard');
				this.notificationService.success("Benefit Updated successfully", false); // Params {message, islogout}
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
		});
	}

	checkArea(content, event) {
		$('div.dropdown.impactList').addClass('open');
		if (event.target.checked == true) {
			if (this.impactAreaIdList.indexOf(content.id) == -1) {
				this.impactAreasList.push(content);
				this.impactAreaIdList.push(content.id);
			}
		}
		else if (event.target.checked == false) {
			this.impactAreasList = this.impactAreasList.filter((impact: any) => {
				return impact.id !== content.id;
			});
			this.impactAreaIdList = this.impactAreaIdList.filter((impact: any) => {
				return impact !== content.id;
			});
		}
		if (this.impactAreasList.length > 0) {
			this.noAreaSelect = false;
		}
		else if (this.impactAreasList.length == 0) {
			this.noAreaSelect = true;
		}
	}

	impactAreaClose(content) {
		this.impactAreasList = this.impactAreasList.filter((impact: any) => {
			return impact.id !== content.id;
		});

		//$('.custom-checkbox input').prop('checked', false);

		if (this.impactAreasList.length == 0) {
			$('.custom-checkbox input').prop('checked', false);
			this.noAreaSelect = true;
		}
		else if (this.impactAreasList.length > 0) {
			this.noAreaSelect = false;
		}

		this.impactAreaIdList = this.impactAreaIdList.filter((impact: any) => {
			return impact !== content.id;
		});
	}

	changePhase(value, element) {
		this.benefit[value] = element.from;
	}

	editBenefit() {
		this.benefitsName = this.selectedBenefit['alt_metric_name'] != "" ? this.selectedBenefit['alt_metric_name'] : this.selectedBenefit['metric_name'];
		this.benefitsDescription = this.selectedBenefit['description'];
		this.annualBenefits = this.selectedBenefit['accrual_type_id'];
		this.impact = this.selectedBenefit['impact_type_id'];
		this.valueCategoryBenefits = this.selectedBenefit['value_category_id'];
		this.scale = this.selectedBenefit['scale_type_id'];
		this.currentEffort = this.selectedBenefit['current_effort'];
		this.uniqueTypes = this.selectedBenefit['unique_type_id'];

		this.driverFactor = this.driverFactorList.filter(item => {
			return item['id'] == this.selectedBenefit['driver_factor_id'];
		});

		this.driverBaselineAnswer = this.selectedBenefit['driver_baseline_answer'];
		this.unitType = this.selectedBenefit['unit_type_id'];
		this.conservative = this.selectedBenefit['conservative'];
		this.probable = this.selectedBenefit['probable'];
		this.aggressive = this.selectedBenefit['aggressive'];

		this.financialDriverFactor = this.financialDriverFactorList.find(item => {
			return item['id'] == this.selectedBenefit['financial_factor_id'];
		});
		this.selectedImprovementType = this.improvementTypeList.filter(item => {
			return item['id'] == this.selectedBenefit.improvement_factor_id;
		});

		this.financicalAnswer = this.selectedBenefit['financial_baseline_answer'];
		this.benefitsFeatures = this.selectedBenefit['features'];
		this.solutionType = this.selectedBenefit['account_solution_id'];

		this.readValueCategories(this.solutionType);
		if (this.unitType) {
			this.checkCurrentEffort();
		}
	}

	createDriverFactor() {
		this.showActionLoader = true;
		let driverFactorName = {
			"name": this.newDriverFactor.name,
			"account_id": sessionStorage.getItem('aid')
		};
		if (driverFactorName) {
			this.factsService.createDriverFactor(driverFactorName).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result && response.result.success) {
					this.readDriverFactor(response.result.driverFactor['id']);
					this.notificationService.success('Driver Factor created successfully', false); // Params {message, islogout}
				}
				else if (response.result && !response.result.success) {
					this.notificationService.error(response.result.message, false); // Params {message, islogout}
				}
				this.newDriverFactor = null;

				//this.driverFactor = response.result.driverFactor['id'];
				this.showActionLoader = false;
				this.createFactor = false;
			});
		}
	}

	createFinancialFactor() {
		this.showActionLoader = true;
		let driverFactorName = { "name": this.FinancialFactor };
		if (driverFactorName) {
			this.factsService.createFinancialFactor(driverFactorName).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result && response.result.success) {
					this.readFinancialTypes(response.result.financialFactor['id']);
					this.notificationService.success('Financial Factor created successfully', false); // Params {message, islogout}
				}
				else if (response.result && !response.result.success) {
					this.notificationService.error(response.result.message, false); // Params {message, islogout}
				}

				//this.financialDriverFactor = response.result.financialFactor['id'];
				this.FinancialFactor = "";
				this.createDriverFlag = false;
				this.showActionLoader = false;
			});
		}
	};

	createImprovementFactor() {
		this.showImprovementActionLoader = true;
		let improvementFactorName = { "name": this.newImprovementFactor };
		if (improvementFactorName) {
			this.factsService.createImprovementFactor(improvementFactorName).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result && response.result.success) {
					this.readImprovementTypes(response.result.improvementFactor['id']);
					this.notificationService.success('Improvement Factor created successfully', false); // Params {message, islogout}
				}
				else if (response.result && !response.result.success) {
					this.notificationService.error(response.result.message, false); // Params {message, islogout}
				}

				//this.financialDriverFactor = response.result.financialFactor['id'];
				this.newImprovementFactor = "";
				this.createImprovementFlag = false;
				this.showImprovementActionLoader = false;
			});
		}
	};
}