<div *ngIf="loading">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>

<app-simple-guided-discovery
  *ngIf="currentStep === this.steps.selectWorkflow"
  [workflows]="workflows$ | async"
  (workflowSelected)="changeStepToStepTwo($event)"
></app-simple-guided-discovery>

<app-questions-answers-guided-discovery
  *ngIf="currentStep === this.steps.answerQuestions"
  [questions]="questions$ | async"
  (saveAnswers)="saveAnswers($event)"
  (back)="setStep(this.steps.selectWorkflow)"
></app-questions-answers-guided-discovery>

<app-guided-model (finishedGuide)="closeGuidedDiscovery()" [valuePropId]="valuePropId" *ngIf="currentStep === this.steps.modelResults"></app-guided-model>
