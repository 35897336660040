import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomAssetTagsComponent } from './custom-asset-tags.component';
import { ManageTagsComponent } from './components/manage-tags/manage-tags.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { CustomAssetTagsTranslation } from './custom-asset-tags.translation';
import { TableModule } from 'primeng/table';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { SidebarModule } from 'primeng/sidebar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { ApplicationPipesModule } from '../../pipe/pipe.module';
import { ButtonModule } from 'primeng/button';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { FilterTagsPipe } from './filter-tags.pipe';

@NgModule({
  declarations: [CustomAssetTagsComponent, ManageTagsComponent, FilterTagsPipe],
  imports: [
    CommonModule,
    InputSwitchModule,
    InputTextModule,
    BreadcrumbsModule,
    SidebarModule,
    TableModule,
    MatTooltipModule,
    AreYouSureModule,
    NoRecordsModule,
    FormsModule,
    ReactiveFormsModule,
    EllipsisMenuModule,
    EditTranslationsModule,
    TabViewModule,
    ErrorInputWrapperModule,
    ApplicationPipesModule,
    ButtonModule,
    FunctionPipeModule,
  ],
  providers: [CustomAssetTagService, CustomAssetTagsTranslation],
})
export class CustomAssetTagsModule {}
