<div *ngIf="loading">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>

<div *ngIf="!loading">
  <div *ngIf="!edit">
    <div class="rsv2-sc-component panel">
      <div class="rsv2-sc-heading panel-heading value-execution-add-a-new-value-proposition-header-0" [attr.data-heap]="'value-execution-add-a-new-value-proposition-header-' + trans.trans.selectModels.value.toLowerCase().split(' ').join('-')">
        {{ trans.trans.selectModels.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.selectModels"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div [class]="'rsv2-sc-body panel-body form-group'">
        <div class="row">
          <div class="col-xs-12">
            <label class="categoryType">
              {{ trans.trans.choosCategory.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.choosCategory"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <p-dropdown
              appendTo="body"
              [autoDisplayFirst]="false"
              [panelStyleClass]="'dropdown-item-font'"
              class="keywest_dropdown dropdown-theme"
              name="categoryType"
              (onChange)="getSolution(categoryType)"
              [options]="solutionCategoryList"
              [(ngModel)]="categoryType"
              optionLabel="category"
              optionValue="id"
            >
              <ng-template let-item pTemplate="selectedItem">
                {{ item.category }} <span *ngIf="item.description !== ''" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description"> info </span>
              </ng-template>
              <ng-template let-item pTemplate="item">
                {{ item.category }} <span *ngIf="item.description !== ''" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description"> info </span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-xs-12" *ngFor="let item of availableSolutions">
              <div
                *ngIf="item.selectedCategory"
                [ngClass]="{ 'checkbox': !hasRadioButtonFeature, 'custom-checkbox': !hasRadioButtonFeature, 'keywest_radio_list': hasRadioButtonFeature }"
              >
                <label>
                  <span *ngIf="+item.quantity_enabled === 1">
                    <span (click)="item.showeditable = !item.showeditable; item.tempQuantity = item.quantity" *ngIf="!item.showeditable">
                      <span *ngIf="item.quantity" class="quantityCheck">{{ item.quantity }}</span>
                    </span>

                    <div *ngIf="item.showeditable" class="input-group" style="max-width: 150px; float: left">
                      <input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity" />
                      <div class="input-group-btn">
                        <button (click)="item.showeditable = !item.showeditable" class="btn btn-success">
                          <i class="fa fa-check"></i>
                        </button>
                        <button (click)="item.quantity = item.tempQuantity; item.showeditable = !item.showeditable" class="btn btn-danger">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>

                    &nbsp;of
                  </span>

                  <input
                    *ngIf="hasRadioButtonFeature"
                    type="radio"
                    class="solutionCheckbox"
                    name="item.isChecked"
                    (change)="item.isChecked = !item.isChecked"
                    [checked]="item.isChecked"
                    [disabled]="!canEdit"
                    (click)="checkModels(item.id, item.quantity, $event)"
                  />
                  <span [ngClass]="{ keywest_radiobutton: hasRadioButtonFeature }">
                    {{ item.name }}
                  </span>
                  <span *ngIf="item.description !== ''" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description"> info </span>
                  <input
                    *ngIf="!hasRadioButtonFeature"
                    type="checkbox"
                    class="solutionCheckbox"
                    name="item.isChecked"
                    (change)="item.isChecked = !item.isChecked"
                    [checked]="item.isChecked"
                    [disabled]="!canEdit"
                    (click)="checkModels(item.id, item.quantity, $event)"
                  />

                  <span class="check"></span>
                </label>
              </div>
            </div>

            <div class="col-xs-12" *ngIf="availableSolutions && availableSolutions.length === 0 && !showModelsEditing">
              <p class="well">
                {{ trans.trans.noModels.value }}
              </p>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="loadingSave">
          <div class="col-xs-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
          </div>
        </div>
        <div class="row" *ngIf="!loadingSave">
          <div class="col-xs-12">
            <button type="button" class="btn btn_customizable pull-right value-execution-add-a-new-value-proposition-select-your-solutions-continue-button" (click)="createSolution()" data-heap="value-execution-add-a-new-value-proposition-select-your-solutions-continue-button">{{ trans.trans.continue.value }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="edit">
    <div class="rsv2-sc-component panel" *ngIf="+valueProp.q2_done === 1 || !feature61" style="background-color: #f4f8f9; border-radius: 10px">
      <div class="rsv2-sc-heading panel-heading">
        <span>
          <app-editTranslation [transObj]="trans.trans.selectModels" [transComp]="trans" [style.fontSize]="fontSize" [style.fontWeight] = "fontWeight"></app-editTranslation>
          <i *ngIf="!hasModels" [ngbTooltip]="trans.trans.modelsRequired.value" container="body" placement="right" class="fa fa-exclamation-triangle" style="color: red"></i>
          <i *ngIf="canEdit" (click)="showModelsEditing = !showModelsEditing" class="fa fa-pencil pull-right clickable icon-primary"></i>
        </span>
      </div>
      <div class="rsv2-sc-body panel-body">
        <div *ngFor="let item of availableSolutions">
          <div *ngIf="item.isChecked" class="models">
            {{ item.name }}
            <span *ngIf="item.description !== ''" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description"> info </span>
          </div>
        </div>
      </div>
    </div>
    <app-dashboard-sc-vpg-q2 *ngIf="feature61" [valueProp]="valueProp" (callback)="onFinish()"></app-dashboard-sc-vpg-q2>
  </div>
</div>

<p-sidebar *ngIf="showModelsEditing" [(visible)]="showModelsEditing" [dismissible]="true" [showCloseIcon]="true" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.models.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.models"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <label class="categoryType">
                {{ trans.trans.choosCategory.value }}
              </label>
              <select
                *ngIf="solutionCategoryList && solutionCategoryList.length"
                type="text"
                (change)="getSolution(categoryType)"
                class="form-control"
                name="categoryType"
                [(ngModel)]="categoryType"
              >
                <option *ngFor="let solutionCategory of solutionCategoryList" [value]="solutionCategory.id">
                  {{ solutionCategory.category }}
                </option>
              </select>
            </div>
          </div>
          <div *ngFor="let item of availableSolutions">
            <div class="row" *ngIf="item.selectedCategory">
              <div class="col-md-12">
                <div [ngClass]="{ 'checkbox': !hasRadioButtonFeature, 'custom-checkbox': !hasRadioButtonFeature, 'keywest_radio_list': hasRadioButtonFeature }">
                  <label>
                    <span *ngIf="+item.quantity_enabled === 1">
                      <span (click)="item.showeditable = !item.showeditable; item.tempQuantity = item.quantity" *ngIf="!item.showeditable">
                        <span *ngIf="item.quantity" class="quantityCheck">{{ item.quantity }}</span>
                      </span>

                      <div *ngIf="item.showeditable" class="input-group" style="max-width: 150px; float: left">
                        <input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity" />
                        <div class="input-group-btn">
                          <button (click)="item.showeditable = !item.showeditable" class="btn btn-success">
                            <i class="fa fa-check"></i>
                          </button>
                          <button (click)="item.quantity = item.tempQuantity; item.showeditable = !item.showeditable" class="btn btn-danger">
                            <i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>

                      &nbsp;of
                    </span>

                    <input
                      *ngIf="hasRadioButtonFeature"
                      type="radio"
                      class="solutionCheckbox"
                      name="item.isChecked"
                      (change)="item.isChecked = !item.isChecked"
                      [checked]="item.isChecked"
                      [disabled]="!canEdit"
                      (click)="checkModels(item.id, item.quantity, $event)"
                    />
                    <span [ngClass]="{ keywest_radiobutton: hasRadioButtonFeature }">
                      {{ item.name }}
                    </span>
                    <input
                      *ngIf="!hasRadioButtonFeature"
                      type="checkbox"
                      class="solutionCheckbox"
                      name="item.isChecked"
                      (change)="item.isChecked = !item.isChecked"
                      [checked]="item.isChecked"
                      [disabled]="!canEdit"
                      (click)="checkModels(item.id, item.quantity, $event)"
                    />

                    <span class="check"></span>
                  </label>
                  <span *ngIf="item.description !== ''" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="item.description"> info </span>
                </div>
              </div>

              <div class="col-xs-12" *ngIf="availableSolutions && availableSolutions.length === 0 && !showModelsEditing">
                <p class="well">
                  {{ trans.trans.noModels.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <div class="row" *ngIf="loadingSave">
        <div class="col-xs-12">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </div>

      <div class="form-group text-right" *ngIf="!loadingSave">
        <button type="button" class="btn btn_customizable pull-right" (click)="createSolution()">{{ trans.trans.save.value }}</button>
        <app-are-you-sure
          #solutionCancel
          matTooltipPosition="above"
          [message]="trans.trans.areYouSure.value"
          class="btn cancel-confirm pull-right"
          icon=""
          [text]="trans.trans.back.value"
          (callbackYes)="funcCancel()"
        ></app-are-you-sure>
      </div>
    </div>
  </div>
</p-sidebar>
