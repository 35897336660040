<div class='models_panel' style='margin-top: 20px;' *ngIf="valueProp.q2_done == '0'">
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title' style='font-size: 15px;'>
						{{trans.trans.howSolveProblem.value}}
						<i *ngIf='+valueProp.q2_done === 1' (click)='toggleEdit = !toggleEdit' class='fa fa-pencil'></i>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="!toggleEdit" class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<textarea name='q2answer' maxlength="1200" [(ngModel)]="q2answer" class='form-control' rows='3'>{{valueProp.q2}}</textarea>
				<small style='font-size: 70%;'>{{1200 - q2answer.length}} {{trans.trans.charactersLeft.value}} <span style='color: red;'>{{trans.trans.required.value}}</span></small><br><br>
			</div>
		</div>

		<div *ngIf="toggleEdit" class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				{{valueProp.vmw_q_2}}

			</div>
		</div>
		<div *ngIf="!toggleEdit" class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<p-progressBar *ngIf="saveLoading" mode="indeterminate"></p-progressBar>
				<button *ngIf="!saveLoading" type="button" class="btn btn_customizable pull-right" (click)="funcSaveQ2()">{{trans.trans.save.value}}</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="valueProp.q2_done == '1'" class='panel' style='background-color: #f4f8f9; border-radius: 10px;'>
	<div class='panel-heading' style='border-bottom: solid 1px #e2eff7 !important;'>
		<span style='font-size: 13px;'>
			{{trans.trans.howSolveProblem.value}}
			<i (click)='toggleEdit = !toggleEdit' class='fa fa-pencil'></i>
		</span>
	</div>
	<div class='panel-body'>
		<div *ngIf="!toggleEdit" class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<textarea name='q2answer' maxlength="1200" [(ngModel)]="q2answer" class='form-control' rows='3'>{{valueProp.vmw_q_2}}</textarea>
				<small style='font-size: 70%;'>{{1200 - q2answer.length}} of 1200 characters left <span style='color: red;'>{{trans.trans.required.value}}</span></small><br><br>
			</div>
		</div>

		<div *ngIf="toggleEdit" class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<p>{{valueProp.vmw_q_2}}</p>
			</div>
		</div>

		<div *ngIf="!toggleEdit" class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-12'>
				<p-progressBar *ngIf="saveLoading" mode="indeterminate"></p-progressBar>
				<button *ngIf="!saveLoading" type="button" class="btn btn_customizable pull-right" (click)="funcSaveQ2()">{{trans.trans.save.value}}</button>
			</div>
		</div>
	</div>
</div>