import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FunctionPipe } from './function-pipe.pipe';
import { CompanyService } from '@data/services/company/company.service';

@NgModule({
  declarations: [FunctionPipe],
  imports: [CommonModule],
  exports: [FunctionPipe],
})
export class FunctionPipeModule {}
