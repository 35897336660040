import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { BenefitsComponent } from '../benefits.component';

@Component({
	selector: 'app-benefit-panel-create-flow',
	templateUrl: './benefits-panel-create-flow.component.html',
	styleUrls: ['./benefits-panel-create-flow.component.scss']
})
export class BenefitPanelCreateFlowComponent extends BenefitsComponent implements OnChanges {
	@Input() menuFilter;
	@Input() benefitsList: any;
	@Input() benefitsListExpanded: any;
	@Input() valueProp: any;
	@Input() contextualHelp: object;
	@Input() dealdesk: boolean;
	@Input() benefitOnTheFlyPrivilege: boolean;
	@Input() statusFilter: string = "";
	@Input() benefitsLoader;
	@Output() openAddPhasingCallback = new EventEmitter();
	@Output() openBenefitCallback = new EventEmitter();
	@Output() calcStatusCallback = new EventEmitter();
	@Output() openUpdateBenefitCallback = new EventEmitter();

	@Input() addPhasingModal: any;
	@Input() showBatchUpdateCheckboxes: boolean = false;
	searchText: string;
	@Input() expandAll: boolean = false;
	showDeleteCustomBenefit: boolean = false;
	@Output() deleteCustomBenefitCallback = new EventEmitter();
	deleteBenefit: any;

	@Input() showActive: boolean = false;
	@Input() showNA: boolean = false;
	@Input() showSoft: boolean = false;
	
	filterActive(arr: any[], id) {
		if (!arr) { return 0; }
		let tot = 0;
		// for (let i = 0; i < arr.length; i++) {
		for (let m = 0; m < arr['metrics'].length; m++) {

			if (arr['metrics'][m].active == id) { tot++; }
		}
		// }
		return tot;
	}

	ngOnChanges() {
		if (this.showBatchUpdateCheckboxes) { this.funcExpand(true); }
		if (this.menuFilter) { this.funcExpand(true); }
	}
	openBenefitDetail(benefit) {
		this.openBenefitCallback.emit(benefit);
	}

	funcIsActive(metric) {
		let found = false;
		for (let m = 0; m < metric.length; m++) {
			if (metric[m].active_type == "Active") { found = true;}
		}
		return found;
	}

	opendeleteCustomBenefit(benefit) {
		this.deleteBenefit = benefit;
		this.showDeleteCustomBenefit = !this.showDeleteCustomBenefit;
	}

	deleteCustomBenefit() {
		this.deleteCustomBenefitCallback.emit(this.deleteBenefit);
		this.deleteBenefit = null;
		this.showDeleteCustomBenefit = !this.showDeleteCustomBenefit;
	}

	openUpdateBenefit(benefit) {
		
		this.openUpdateBenefitCallback.emit({ menuItem: "addbenefit", data: benefit });
		// this.valueProp.loadMenuItem.next();
	}

	funcExpand(expand?) {
		if (expand) {
			this.expandAll = expand;
		} else {
			this.expandAll = !this.expandAll;
		}
		this.benefitsListExpanded.forEach(elem => {
			elem.expand = (expand ? expand : this.expandAll);
		})
	}

	calcStatus() {
		// this.calcStatusCallback.emit();
	}

	alignYear(i) {
		switch (i) {
			case 0: return "zero";
			case 1: return "one";
			case 2: return "two";
		}
	}

	alignYearTbl(i) {
		switch (i) {
			case 0: return "zerotbl";
			case 1: return "onetbl";
			case 2: return "twotbl";
		}
	}

	checkAll(benefit) {
		if (!benefit.allOn) { benefit.allOn = true; } else { benefit.allOn = false; }
		for (let i = 0; i < benefit.metrics.length; i++) {
			benefit.metrics[i].updateBatch = benefit.allOn;
		}
	}

}