import { Component, ElementRef, forwardRef, Input, Renderer2, ViewEncapsulation } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MenuItem } from 'primeng/api/public_api';
@Component({
	selector: 'dl-menubar',
	templateUrl: './menubar.component.html',
	styleUrls: ['./menubar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => DLMenubarComponent)
		}
	]
})

export class DLMenubarComponent {

    @Input() model: MenuItem[];

    @Input() style: any;

    @Input() styleClass: string;

    @Input() autoZIndex: boolean = true;

    @Input() baseZIndex: number = 0;

    private _autoDisplay: boolean;

    @Input() get autoDisplay(): boolean {
        return this._autoDisplay;
    }
    set utc(_utc: boolean) {
        console.log("AutoDisplay property is deprecated and functionality is not available.");
    }

    constructor(public el: ElementRef, public renderer: Renderer2) { }
}