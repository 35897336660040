
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { FactsService } from '@data/services/facts/facts.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { CommonService } from '@data/services/common/common.service';
import { KeyFigureTranslations } from './key_figures.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { MenuItem } from 'primeng/api';

interface Prev {
	total_benefits_fmt: string;
	total_costs_fmt: string;
	roi_decorated: string;
	npv_fmt: string;
	payback: string;
	tco_fmt: string;
	three_month_cost_of_inaction_fmt: string;
}

@Component({
	selector: 'app-value-prop-dashboard-key-figures',
	templateUrl: './key_figures.component.html'
})
export class ValuePropDashboardKeyFiguresComponent implements OnInit, OnDestroy {
	@Input() showHelp: boolean;
	@Input() contextualHelp: any[];
	@Input() valueProp: ValueProp;
	@Input() dashboard: string;
	@Input() header_color: string;
	@Input() showTranslate = false;
	@Input() isDev = false;

	prev: Prev = {
		total_benefits_fmt: "",
		total_costs_fmt: "",
		roi_decorated: "",
		npv_fmt: "",
		payback: "",
		tco_fmt: "",
		three_month_cost_of_inaction_fmt: ""
	}
	accountFeatures: string;
	showBody = true;
	masterValuePropName = "";
	clonedValuePropName = "";
	valuePropStatusTypes: MenuItem[];
	valuePropStatusComment: string;
	valuePropStatusEditable: boolean;
	tcoFeature: boolean;
	valuePropLoader = false;
	feature37 = true;
	feature62 = false; // turn off ROI
	feature77 = false;
	canEdit = false;
	changeName: boolean;
	vp_name: string;
	style2022$: Observable<boolean>;

	ngUnsubscribe: Subject<any> = new Subject();

	constructor(
		private notificationService: NotificationService,
		private factsService: FactsService,
		private valuepropService: ValuepropService,
		public trans: KeyFigureTranslations,
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		private styleService: StyleService
	) {

	}

	refreshTranslation() {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();
	}

	ngOnInit() {
		this.clonedValuePropName = this.valueProp.name;
		this.vp_name = this.clonedValuePropName;
		this.canEdit = this.valueProp.vp_can_edit;

		this.feature62 = this.commonService.checkFeature("62");
		this.feature77 = this.commonService.checkFeature("77");
		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		if (this.commonService.checkFeature('10')) {
			this.tcoFeature = true;
		}
		if (localStorage.getItem("total_benefits_fmt")) { this.prev.total_benefits_fmt = "Previous Value: " + localStorage.getItem("total_benefits_fmt") };
		if (localStorage.getItem("total_costs_fmt")) { this.prev.total_costs_fmt = "Previous Value: " + localStorage.getItem("total_costs_fmt") };
		if (localStorage.getItem("roi_decorated")) { this.prev.roi_decorated = "Previous Value: " + localStorage.getItem("roi_decorated") };
		if (localStorage.getItem("npv_fmt")) { this.prev.npv_fmt = "Previous Value: " + localStorage.getItem("npv_fmt") };
		if (localStorage.getItem("payback")) { this.prev.payback = "Previous Value: " + localStorage.getItem("payback") };
		if (localStorage.getItem("tco_fmt")) { this.prev.tco_fmt = "Previous Value: " + localStorage.getItem("tco_fmt") };
		if (localStorage.getItem("three_month_cost_of_inaction_fmt")) { this.prev.three_month_cost_of_inaction_fmt = "Previous Value: " + localStorage.getItem("three_month_cost_of_inaction_fmt") };
		this.getValuePropStatusTypes();
		this.feature37 = this.commonService.checkFeature(37) ? false : true;
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	changeValuePropName(id) {
		this.changeName = false;
		const payload = {
			"name": this.vp_name.trim()
		}
		this.valuepropService.updateValueProp(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.valueProp.name = response.result.value_prop.name;
				// this.reloadVP.emit();
				this.notificationService.success("Name updated successfully", false);

			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}

		});
	}
	cancelValuePropName() {
		this.vp_name = this.valueProp.name;
		this.changeName = false;
		this.valueProp.name = this.clonedValuePropName;
	}
	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}


	getValuePropStatusTypes() {
		this.factsService.getValuePropStatusTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			this.valuePropStatusTypes = response.result.map(elem => {
				return {
					id: elem.id,
					label: elem.name,
					command: () => {
						this.updateValuePropStatus(elem.id);
					}
				};
			})
		});
	}

	updateValuePropStatus(statusTypeid) {
		let statusTypeObj = {
			"value_prop_status_type_id": statusTypeid
		};
		if (statusTypeid.comment == "1") {
			if (!this.valuePropStatusComment) {
				this.notificationService.error("A comment is required for this status", false); // Params {message, islogout}
				return;
			}
			statusTypeObj['comment'] = this.valuePropStatusComment;
		} else {
			statusTypeObj['comment'] = "";
		}

		this.valuePropStatusEditable = !this.valuePropStatusEditable;

		this.valuepropService.updateValuePropStatus(this.valueProp.id, statusTypeObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.valuepropService.refreshDashboard.next('refreshDashboard');
				this.notificationService.success("Status Updated successfully", false); // Params {message, islogout}
				this.getValuePropStatusTypes();
				// this.valuePropStatusComment = "";
			}
			else if (response.result.success === false) {
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
		});
	}
}
