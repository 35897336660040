import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CollateralComponent } from './collateral.component';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  declarations: [CollateralComponent],
  imports: [
    EditTranslationsModule,
    RouterModule,
    NgbModule,
    MatTooltipModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  providers: [Translations],
  entryComponents: [CollateralComponent],
  exports: [CollateralComponent],
})
export class ModelCollateralModule {}
