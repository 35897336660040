import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ValueMapContainerModule } from 'app/value-map-maintenance/container/container.module';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { ModelSituationModule } from '../../situation/situation.module';
import { ModelThresholdsModule } from '../../thresholds/thresholds.module';
import { ModelAssumptionsModule } from '../assumptions/assumptions.module';
import { ModelBenefitsModule } from '../benefits/benefits.module';
import { ModelCaseStudiesModule } from '../case-studies/case-studies.module';
import { ModelCollateralModule } from '../collateral/collateral.module';
import { ModelSolutionCompetitorsModule } from '../competitors/competitors.module';
import { ModelCostsModule } from '../cost/cost.module';
import { ModelOverviewModule } from '../overview/overview.module';
import { ModelProjectsModule } from '../projects/projects.module';
import { ModelValueCategoryModule } from '../value-category/value-category.module';
import { SolutionDetailComponent } from './solution-detail.component';
import {BadgeModule} from 'primeng/badge';
import { ValidationModule } from '../validation/validation.module';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [SolutionDetailComponent],
  imports: [
    ValueMapContainerModule,
    MatTooltipModule,
    ModelThresholdsModule,
    ModelSolutionCompetitorsModule,
    ModelCollateralModule,
    ModelCaseStudiesModule,
    ModelSituationModule,
    ModelCostsModule,
    ModelProjectsModule,
    ModelValueCategoryModule,
    ModelOverviewModule,
    ModelAssumptionsModule,
    ModelBenefitsModule,
    NgbModule,
    ValidationModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    BadgeModule,
    BreadcrumbsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  entryComponents: [SolutionDetailComponent],
})
export class ModelSolutionDetailModule {}
