import { NgModule } from '@angular/core';

import { RBenefitsComponent } from "./r_benefits.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';

import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';

import { PainPointsService } from '@data/services/pain_points/pain.service';
import { CapabilitiesService } from '@data/services/capabilities/capabilities.service';
import { OperationalObjectivesService } from '@data/services/op_objectives/op_objectives.service';
import { FunctionalObjectivesService } from '@data/services/func_objectives/func_objectives.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { DLListDetailModule } from '@shared_components/list_detail/list_detail.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { AEFactorsFormModule } from 'app/value-map-maintenance/views/factors/ae_factors/ae_factors.module';
import { CheckboxModule } from 'primeng/checkbox';
import { RScratchpadsModule } from 'app/value-map-maintenance/views/factors/r_scratchpads/r_scratchpads.module';
import { AEScratchpadsModule } from 'app/value-map-maintenance/views/factors/ae_scratchpads/ae_scratchpads.module';
import { DLDragDropModule } from '@shared_components/dd_file_upload/upload.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RBenefitsTranslations } from './r_benefits.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		RBenefitsComponent,
	],
	imports: [
		CheckboxModule,
		RScratchpadsModule,
		AEScratchpadsModule,
		DLDragDropModule,
    MatTooltipModule,
		AEFactorsFormModule,
		ProgressBarModule,
		DLListDetailModule,
		DLNGErrorDirectiveModule,
		SliderModule,
		DropdownModule,
		NGFormSaveButtonModule,
		InputSwitchModule,
		AutoCompleteModule,
		MenuModule,
		SidebarModule,
		TableModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		TabViewModule,
		EditTranslationsModule
	],
	providers: [
		PainPointsService,
		CapabilitiesService,
		OperationalObjectivesService,
		FunctionalObjectivesService,
		TranslationsV2Service,
		RBenefitsTranslations
	],
	entryComponents: [
		RBenefitsComponent,
	],
	exports: [RBenefitsComponent]
})
export class RBenefitsModule { }