import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from '../../combined.translation';
import { Currency, Situation } from '@data/services/valueprop/models/situation-opportunity.model';
import { BaselineType } from '@data/services/valueprop/models/baselineType.model';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-rsv2-sc-advanced',
  templateUrl: './advanced.component.html',
})
export class RSV2SCAdvancedComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  @Output() reloadVP = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate = false;

  showAdvancedEditing = false;
  situation: Situation;
  originalSituation: Situation;
  originalCurrencyId: string;
  currencyId: {id: string };
  currency: Currency;
  opportunityIdFeature = false;
  loader = false;
  originalTerm: string;
  term: string;
  loading = false;
  customerTypes: { [klass: string]: any }[] = [];
  baselineTypes: BaselineType[] = [];
  advancedTerms: { [klass: string]: any }[] = [];

  feature20 = false;
  feature64 = false;
  feature87 = false;
  grossProfitFeature = false;
  crm: string;
  canEdit: boolean;

  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: DefaultTranslations,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private RS: RepSimpleViewService,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.style2022$ = this.styleService.style2022;
    this.canEdit = this.valueProp.vp_can_edit;
    this.opportunityIdFeature = this.commonService.checkFeature(22);
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getValuePropSituation();
    this.getCustomerTypes();
    this.getBaselineTypes();
    this.getTerms();

    this.feature20 = this.commonService.checkFeature(20);
    this.feature64 = this.commonService.checkFeature(64);
    this.feature87 = this.commonService.checkFeature(87);
    this.grossProfitFeature = this.commonService.checkFeature(13);
    this.opportunityIdFeature = this.commonService.checkFeature(22);
    this.RS.refreshSimpleDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.getValuePropSituation());
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  closeAdvanced(): void {
    this.showAdvancedEditing = false;
    this.situation = cloneDeep(this.originalSituation);
    this.term = this.originalTerm;
    this.currencyId = { id: this.originalCurrencyId }
  }

  getOpportunityId(): void {
    this.valuepropService
      .getOpportunityId(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.situation.opportunity_id = response.result.opportunity_id;
        }
      });
  }

  getValuePropSituation(): void {
    this.valuepropService
      .getSituation(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.originalTerm = response.result.term;
          this.term = response.result.term;

          this.originalCurrencyId = response.result.currency_type_id;
          this.currencyId = { id: response.result.currency_type_id };
          this.situation = response.result;
          this.situation.number_format = this.situation.number_format;
          const currency = this.situation['currency_types'].filter((x) => x['id'] === this.situation['currency_type_id']);
          this.currency = currency[0];
          if (this.opportunityIdFeature) {
            this.getOpportunityId();
          }
        }
      });
    this.loading = false;
  }

  getCustomerTypes(): void {
    this.valuepropService
      .getCustomerTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.customerTypes = response.result;
        }
      });
  }

  getBaselineTypes(): void {
    this.valuepropService
      .getBaselineTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.baselineTypes = response.result;
        }
      });
  }

  getTerms(): void {
    this.valuepropService
      .getTerms(sessionStorage.getItem('aid'))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.advancedTerms = response.result;
        }
      });
  }

  updateAdvanced(): void {
    const situation = {
      baseline_type_id: this.situation.baseline_type_id,
      customer_type_id: this.situation.customer_type_id,
      deployment_time: this.situation.deployment_time,
      gross_profit: this.situation.gross_profit,
      currency_type_id: this.currencyId.id,
      number_format: this.situation.number_format,
      conversion_rate: this.situation.conversion_rate,
      term: this.term,
      wacc: this.situation.wacc,
    };
    this.opportunityIdFeature = true;

    if (this.situation.opportunity_id) {
      situation['opportunity_id'] = this.situation.opportunity_id;
    }

    this.loader = true;
    this.valuepropService
      .updateAdvanced(this.valueProp.id, situation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.getValuePropSituation();
          this.showAdvancedEditing = false;
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.reloadVP.emit();
        this.loader = false;
        this.showAdvancedEditing = false;
        this.originalSituation = cloneDeep(this.situation);
        this.originalCurrencyId = this.currencyId.id;
        this.originalTerm = this.term;
      });
  }

  setConversionRateField(event: { value: any }): void {
    this.situation = { ...this.situation, conversion_rate: event.value.rate };
  }

  openAdvancedSettings(): void {
    this.showAdvancedEditing = true;
    this.originalSituation = cloneDeep(this.situation);
  }
}
