import { Injectable } from '@angular/core';

@Injectable ()

export class SettingsTranslation {

    public config: any = {
        component: 'Settings',
        display_name: 'Settings',
        description: 'Settings Page'
    };

    public trans: any = {
        manageSettings: 'Manage Settings',
        manage_application_settings: 'Manage Application Settings',
        manage_competitors: 'Manage Competitors',
        manage_partners: 'Manage Partners',
        manage_users: 'Manage Users',
        manage_user_groups: 'Manage User Groups',
        Reports: 'Advanced Analytics',
        solution_reports: 'Solutions and Value Props',
        user_adoption_reports: 'User Adoption',
        other_reports: 'SFDC Troubleshooting',
        customerFacingElements: 'Customer Facing Elements',
        manage_asset_templates: 'Manage Asset Templates',
        webCalculator: 'Web Calculator',
        advancedFeatures: 'Advanced Features',
        features: 'Manage Features',
        surveyTemplates: 'Survey Templates',
        guidedDiscovery: 'Guided Discovery',
        discoveryQuestions: 'Discovery Questions',
        localizationCenter: 'Localization',
        translationTitle: 'Translations',
        exchange_rates: 'Exchange Rates',
        dashboard: 'Dashboard',
        settings: 'Settings',
        backToProfile: 'Back To Profile',
        customAssetTags: 'Custom Asset Tags',
        bulkUpdate: 'Bulk Update Salesforce',
        analytics: 'Custom Analytics'
    };
}