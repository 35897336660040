import { NgModule } from '@angular/core';
import { RCompetitorsComponent } from "./r_competitors.component";
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RCompetitorTranslation } from './r_competitors.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
	declarations: [
		RCompetitorsComponent
	],
	imports: [
		NgbModule,
		CommonModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		RCompetitorTranslation
	],
	entryComponents: [
		RCompetitorsComponent,
	],
	exports: [RCompetitorsComponent]
})
export class RCompetitorsModule { }