import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-value-realized-flyout',
  templateUrl: './value-realized-flyout.component.html',
  styleUrls: ['./value-realized-flyout.component.scss']
})
export class ValueRealizedFlyoutComponent implements OnInit {
@Input() addNewSidebar = false;
@Output() onHide = new EventEmitter<boolean>();
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public onSidebarClose(): void {
    this.addNewSidebar = false;
    this.onHide.emit(this.addNewSidebar);
  }

  public defineTracker(id:string): void {
    this.router.navigate(['valuerealized/company/' + id]);
  }
}