import { Injectable } from '@angular/core';

@Injectable()
export class CollateralTranslations {

    public config = {
        component: 'collateral',
        display_name: 'Collateral',
		description: 'Collateral for Solution'
    };

    public trans: any = {
        addNew: 'Add New',
        collateral: 'Collateral',
        search: 'Search',
        name: 'Name',
        format: 'Format',
        view: 'View',
        url: 'Url',
        edit: 'Edit',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
        edit_translations: 'Edit Translations',
        ch_addNew: 'Add New Collateral'
    };
}
