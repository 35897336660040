import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class UserGroupsProfileTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'userGroupsProfile',
    display_name: 'User Groups',
    description: 'User group list in profile',
  };

  public trans = this.typeObjectKeys({
    backToProfile: 'Back to Profile',
    search: 'Search',
    rowLimit: 'Row limit:',
    addUserGroup: 'Add User Group',
    editUserGroup: 'Edit User Group',
    name: 'Name',
    description: 'Description',
    update: 'Update',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    manage_user_group: 'Manage User Groups',
    group: 'Group',
    add: 'Add',
    valueCategoryName: 'Value Category Name',
    userGroupName: 'User Group Name',
    settings: 'Settings',
    dashboard: 'Dashboard',
    back: 'Back',
    successfullyAdded: 'Group successfully added',
    successfullyEdited: 'Group successfully edited',
    successfullyDeleted: 'Group successfully deleted',
    somethingWeentWrong: 'Something went wrong',
    availableUsers: 'Available Users',
    selectedUsers: 'Selected Users',
    groupError: `We couldn't retrieve the list of users from this group`,
  });
}
