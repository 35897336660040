import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './combined.translation';
import { RSV2SCScalersComponent } from './views/scalers/scalers.component';
import { Workflow } from '@data/services/workflow/models/workflow.interface';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';

@Component({
  selector: 'app-dashboard-scenario-combined-repv2',
  templateUrl: './combined.component.html',
})
export class ScenarioCombinedComponentRepv2 implements OnInit, OnDestroy {
  @Input() view = 'simple';
  @Input() valueProp: ValueProp;
  @Input() isDev: boolean;
  @Input() showTranslate = false;
  @Input() showContinue: boolean = true;

  @Output() callback = new EventEmitter();
  @Output() callbackNext = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  @Output() back = new EventEmitter();

  imageUrl: string;
  fullImagePath: string;
  ngUnsubscribe = new Subject();

  showNext = true;

  feature20 = false;
  feature88 = false;
  feature40 = this.commonService.checkFeature(40);
  guidedDiscoveryEnabled = false;

  jumpToResultsFeature = false;
  crm: string;

  @ViewChild('scalers') scalers: RSV2SCScalersComponent;

  workflows: Workflow[];
  guidedDiscoveryVisible = false;
  advancedOptionsVisible = true;

  constructor(
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private RS: RepSimpleViewService,
    private workflowService: AccountWorkflowService
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }
  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.feature20 = this.commonService.checkFeature(20);
    this.feature88 = this.commonService.checkFeature(88);
    this.guidedDiscoveryEnabled = this.commonService.checkFeature(124);
    this.jumpToResultsFeature = this.commonService.checkFeature(109);
    this.advancedOptionsVisible = !this.commonService.checkFeature(40);

    if (this.guidedDiscoveryEnabled) {
      this.workflowService.getAccountWorkflows(true).subscribe((workflows) => {
        this.workflows = workflows;
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  reload(): void {
    this.RS.refreshSimpleDashboard.next();
    this.reloadVP.emit();
  }

  closeGuided(): void {
    this.guidedDiscoveryVisible = false;
    this.workflowService.workflowRefreshModel$.next(true);
    this.reload();
  }

  nextStep(route?) {
    this.scalers
      .createScalerNext$()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.callbackNext.emit(route);
      });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  showGuidedDiscovery(): void {
    this.guidedDiscoveryVisible = true;
  }
}
