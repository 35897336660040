import { NgModule } from '@angular/core';

import { AESolutionListComponent } from "./ae_list.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import {MultiSelectModule} from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './ae_list.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AESolutionListComponent,
	],
	imports: [
		AreYouSureModule,
		DropdownModule,
    MatTooltipModule,
		MultiSelectModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		EditTranslationsModule
	],
	providers: [DefaultTranslations, TranslationsV2Service
	],
	entryComponents: [
		AESolutionListComponent,
	],
	exports: [AESolutionListComponent]
})
export class AESolutionListModule { }