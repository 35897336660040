import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FactsService } from '@data/services/facts/facts.service';
import { NotificationService } from '@services/notification.service';
import { AccountVpDashTranslations } from './account.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValueProp } from '@shared/models/value-prop.model';
import { Company } from '@shared/models/company.model';
import { StyleService } from 'app/style.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() contextualHelp: any;
  @Input() showTranslate = false;
  @Input() companyId: string | number;
  @Input() valueProp: ValueProp;

  company: Company & { creators_name: string; gross_profit_fmt: string };
  supplementals: { [key: string]: any };
  loader: boolean;
  fullImagePath: string;
  imageUrl: string;
  subscriptiongetValuePropCompany: Subscription;
  subscriptiongetSectors: Subscription;
  industrySectors: Array<{ [key: string]: any }>;
  subscriptiongetCountries: Subscription;
  countries: Array<{ [key: string]: any }>;
  statesLoader: boolean;
  subscriptiongetStatesByCountries: Subscription;
  states: Array<{ [key: string]: any }>;
  industriesLoader: boolean;
  industriesShow: boolean;
  subscriptiongetIndustriesBySector: Subscription;
  industries: Array<{ [key: string]: any }>;
  showEdit = false;
  countryDetails: { id: string; name: string };
  industrySector: { id: string; name: string };
  industryId: { id: string; name: string };
  stateId: { name: string };
  loadingUpdate = false;
  ngUnsubscribe$ = new Subject();

  constructor(
    private valuepropService: ValuepropService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private factsService: FactsService,
    private notificationService: NotificationService,
    public trans: AccountVpDashTranslations,
    private translationService: TranslationsV2Service
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    if (this.companyId) {
      this.getValuePropCompany(this.companyId);
      this.getCountries();
      this.getIndustrySectors();
    } else {
      this.route.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => (this.companyId = params['id']));
      this.getValuePropCompany(this.companyId);
    }
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('uid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  refreshTranslation() {
    this.getTranslations();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();

    if (this.subscriptiongetValuePropCompany) {
      this.subscriptiongetValuePropCompany.unsubscribe();
    }
  }

  getValuePropCompany(id: string | number): void {
    this.loader = true;
    this.valuepropService
      .getValuePropCompany(id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result) {
          this.countryDetails = { id: response.result.company.country_id, name: response.result.company.country };
          this.industrySector = { id: response.result.company.dl_sector_id, name: response.result.company.dl_sector_name };
          this.industryId = { id: response.result.company.dl_industry_id, name: response.result.company.industry_name };
          this.stateId = { name: response.result.company.state };

          this.getStatesByCountries();
          this.getIndustryBySectors();

          this.loader = false;
          this.company = response.result.company;
          this.supplementals = response.result.supplementals;
        }
      });
  }

  funcUpdateCompany(): void {
    if (!this.company.name) {
      return;
    }

    if (!this.countryDetails.id) {
      return;
    }
    this.company.country_id = this.countryDetails.id;
    this.company.country = this.countryDetails.name;

    if (!this.industrySector.id) {
      return;
    }
    this.company.dl_sector_id = this.industrySector.id;

    if (!this.industryId.id) {
      return;
    }
    this.company.industry_id = this.industryId.id;

    if (!this.company.revenue_fmt) {
      return;
    }
    if (!this.company.employees) {
      return;
    }

    this.company.state = this.stateId.name;

    if (this.company.revenue_fmt) {
      this.company.revenue = this.company.revenue_fmt;
    }

    this.loadingUpdate = true;

    this.valuepropService
      .updateCompany(this.company.id, this.company)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.loadingUpdate = false;
        if (response.result) {
          if (!response.result.success) {
            this.showEdit = false;
            this.notificationService.error(response.result.message, false);
          } else {
            this.showEdit = false;
            this.getValuePropCompany(this.companyId);
            this.notificationService.success('Company info updated successfully', false);
          }
        }
      });
  }

  getIndustrySectors(): void {
    this.factsService
      .getSectors()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.industrySectors = response.result;
      });
  }
  getCountries(): void {
    this.factsService
      .getCountries()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.countries = response.result.countries;
      });
  }
  getStatesByCountries(): void {
    const country_id = this.countryDetails.id;
    this.statesLoader = true;
    this.factsService
      .getStatesByCountries(country_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.statesLoader = false;
        this.states = response.result.states;
      });
  }

  getIndustryBySectors(): void {
    const sectorId = this.industrySector.id;

    this.industriesLoader = this.industriesShow = true;
    this.factsService
      .getIndustriesBySector(sectorId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.industriesLoader = this.industriesShow = false;
        this.industries = response.result.dl_industries;
      });
  }
}
