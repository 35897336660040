import { Injectable } from '@angular/core';

@Injectable()
export class EditSequencesDefaultTranslations {
  public config: any = {
    component: 'edit-sequences',
    display_name: 'Edit Sequences in settings',
    description: 'Edit Sequences in settings',
  };
  public trans: any = {
    settings: 'Settings',
    dashboard: 'Dashboard',
    benefitsOnly: 'Benefits',
    valueCategories: 'Value Categories',
    factors: 'Factor Name',
    factorOnly: 'Factors',
    situationOnly: 'Situations',
    situation_types: 'Situation Name',

    operationalObjectives: 'Operational Objectives',
    functionalObjectives: 'Functional Objectives',
    painPoints: 'Pain Points',
    capabilitiesOnly: 'Capabilities',
    assetsOnly: 'Assets',
    discoveryQuestionsOnly: 'Discovery Questions',
    benefits: 'Benefit Name',
    value_categories: 'Value Category Name',
    factor_groups: 'Factor Group Name',
    operational_objectives: 'Operational Objective Name',
    functional_objectives: 'Functional Objective Name',
    goals: 'Goal Name',
    goalOnly: 'Goals',
    goal_groups: 'Goal Group Name',
    goalGroups: 'Goal Groups',
    pain_points: 'Pain Point Name',
    capabilities: 'Capability Name',
    account_asset_templates: 'Asset Name',
    discovery_question: 'Discovery Question Name',
    description: 'Description',
    search: 'Search',
    sequencing: 'Sequencing',
    save: 'Save',
    cancel: 'Cancel',
    customSquence: 'Custom Sequence',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    areYouSure: 'Are you sure?',
    saveError: 'Your sequence did not save, please try again',
    move: 'Move',
    tooltip: 'To select multiple rows, hold down shift as you click',
    noItemsFound: 'No items found',
    factorGroups: 'Factor Groups',
    name: 'Name',
    unsavedChanges: 'You have unsaved changes. Please save before exiting to avoid losing your new sequence.',
    unsavedShort: 'Unsaved Changes',
    success: 'Sequence updated successfully',
    saved: 'Saved',
    fail: 'Save Failure',
    costs: 'Cost Name',
    costsOnly: 'Costs',
    models: 'Model Name',
    modelsOnly: 'Models',
    modelCategories: 'Model Categories',
    model_categories: 'Model Category Name',
    account_workflow: 'Workflows',
    solution_name: "Solution Name",
    reset: 'Reset',
    areYouSureReset: 'This will reset all of the sequences for SequenceObject, are you sure?',
    resetSuccess: 'Sequence reset successfully',
    resetFail: 'Reset Failure',
  };
}
