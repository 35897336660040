import { UserInfo } from '@shared/models/user-info.model';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { ScbService } from '@data/services/scb/scb.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MasterValueProp, ValueProp } from '@shared/models/value-prop.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../../_services/alert.service';
import { DefaultTranslations } from './dashboard.translation';

@Injectable()
export class DashboardImplementation implements OnInit, OnDestroy {
  loaded = false;

  valueProps: ValueProp[];
  userID: string;
  accountID: string;
  currentJustify = 'center';
  loadDashboardTable = false;
  fullImagePath: string;
  isSCBUser = false;
  isValuePropUser = false;
  isValuePositionUser = false;
  isValueRealizationUser = false;
  isValueRealized2 = false;
  isValueInsightUser = false;
  hasRepSimpleFeature = false;
  isSalesRep = false;
  dashboardLoading = true;
  userDetails: UserInfo;
  downloadDetails: { [klass: string]: any };
  userCredits: number;
  showTooltipActionLoader = false;

  researchList: { [klass: string]: any }[] = [];
  modalReference;
  page: string;
  isSolutionAdmin = false;
  masterValuePropFeature = false;
  dealdeskFeature = false;
  guidedDiscoveryFeature = false;
  caseStudyFeature = false;
  masterValueProps: MasterValueProp[] = [];

  selectedOption: string;
  accountFeatures: string[] = [];
  valuePropCreateFeatureCount: number;

  isGuest = false;

  valuePropGroupsFeature = false;

  subscriptionscbDownloadList: Subscription;
  subscriptiongetValuePositionList: Subscription;
  subscriptiongetValueRealizationQuick: Subscription;
  subscriptiongetUser: Subscription;
  subscriptiongetValueProp: Subscription;
  subscriptiongetValuePropDetail: Subscription;
  subscriptiongetAvailableSolutions: Subscription;
  subscriptiongetUserCredits: Subscription;
  subscriptiongetRecentCaseStudies: Subscription;
  subscriptionreadAndSearchMasterValueProps: Subscription;
  subscriptionSubscribeLoggedIn: Subscription;
  header_color: string;
  showTranslate = false;
  isAdmin = false;

  selectedProp: ValueProp;
  feature41: boolean;
  feature58 = false;
  showAddButtons = false;
  toggleAddValueProp = false;

  get user(): string {
    return sessionStorage.getItem('username');
  }

  showNavigation = true;
  isDev = false;
  toggleAll = false;
  view = 'main_dashboard';

  ngUnsubscribe = new Subject();
  vpg = false;
  showDashboard = false;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private ScbService: ScbService,
    private commonService: CommonService,
    private companyService: CompanyService,
    private masterValuePropService: MasterValuePropService,
    private translateService: TranslateService,
    private translationService: TranslationsV2Service,
    public trans2: DefaultTranslations,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
    this.masterValueProps = [];
    this.isDev = this.commonService.isDev$;
  }

  filter: any = { name: '' };

  ngOnInit(): void {
    this.showDashboard = sessionStorage.getItem('hash_id') ? false : true;

    this.hasRepSimpleFeature = this.commonService.checkFeature('34');
    this.feature58 = this.commonService.checkFeature('58');
    const feature56 = this.commonService.checkFeature('56');
    const priv7 = this.commonService.checkPrivilege('7');
    if (feature56 && priv7) {
      this.vpg = true;
    }

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.isAdmin = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
    this.header_color = localStorage.getItem('header_color');

    if (sessionStorage.getItem('uid')) {
      this.userID = sessionStorage.getItem('uid');
    }
    if (sessionStorage.getItem('aid')) {
      this.accountID = sessionStorage.getItem('aid');
    }

    this.funcReloadData();

    this.subscriptionSubscribeLoggedIn = this.alertService.subscribeLoggedIn().subscribe((data) => {
      this.isAdmin = sessionStorage.getItem('isAdmin') === 'true' ? true : false;
      this.userID = data.user_id;
      this.accountID = data.account_id;

      this.funcReloadData(data.user_id);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans2.config.component,
      lang: langAbbr,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans2.trans = res;
      });
  }

  funcGetAddRoute(incoming): void {
    const feature71 = this.commonService.checkFeature('71');
    if (feature71) {
      this.toggleAddValueProp = true;
    } else {
      const route = this.commonService.getCreateVPRoute(incoming);
      const navRoute = route.includes('rep') ? [route, { type: incoming }] : [route];
      this.router.navigate(navRoute);
    }
  }

  funcToggleAll(): void {
    this.toggleAll = !this.toggleAll;
    this.isValueInsightUser = this.toggleAll;
    this.isValuePositionUser = this.toggleAll;
    this.isValuePropUser = this.toggleAll;
    this.masterValuePropFeature = this.toggleAll;
    this.isValueRealizationUser = this.toggleAll;
    this.isSolutionAdmin = this.toggleAll;
    this.caseStudyFeature = this.toggleAll;
  }

  toggleNavigation(): void {
    this.showNavigation = !this.showNavigation;
    this.commonService.notifyNavigationToggle.next(this.showNavigation);
  }

  refreshTranslation(): void {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  funcReloadData(userid?): void {
    if (!this.userID || !this.accountID) {
      return;
    }
    this.getUserData(userid);
    this.parseFeatures();
    const rid = sessionStorage.getItem('rid');
    if (rid === '7') {
      this.isGuest = true;
    }
  }

  getUserData(user_id?): void {
    const uid = this.userID ? this.userID : user_id;
    if (!uid) {
      return;
    }
    this.subscriptiongetUser = this.userService.getUser(parseInt(uid, 10)).subscribe((result) => {
      this.userDetails = result['result'];
      this.userService.userInfo = this.userDetails;
      sessionStorage.setItem('products', JSON.stringify(this.userDetails['products']));
      if (sessionStorage.getItem('products')) {
        const products = JSON.parse(sessionStorage.getItem('products'));
        if (products.length) {
          products.forEach((item) => {
            if (item.name === 'Value Positions') {
              this.isValuePositionUser = true;
            } else if (item.name === ' Value Propositions') {
              this.isValuePropUser = true;
            } else if (item.name === 'Value Realizations') {
              this.isValueRealizationUser = true;
            } else if (item.name === 'Value Insights') {
              this.isValueInsightUser = true;
            } else if (item.name === 'VR 2.0') {
              this.isValueRealized2 = true
            }
          });
        }
      } else {
        this.isSCBUser = false;
      }

      if (sessionStorage.getItem('privileges')) {
        const privs = sessionStorage.getItem('privileges');
        const model_admin = privs.indexOf('2');
        if (model_admin !== -1) {
          this.isSolutionAdmin = true;
        }
      }
      this.dashboardLoading = false;
      if (!this.isSCBUser) {
      } else {
        this.getUserCredits();
      }
      this.loaded = true;
    });
  }

  downloadSCB(hash): void {
    const service_url = this.commonService.getServiceUrl();
    window.location.assign(service_url + 'smart_company_brief.php?hash=' + hash + '&user_id=' + sessionStorage.getItem('uid'));
  }

  public getDownloadData(): void {
    this.subscriptionscbDownloadList = this.ScbService.scbDownloadList().subscribe((result) => {
      this.downloadDetails = result['result']['downloads'];
    });
  }

  selectCompany(id): void {
    this.router.navigate(['/company/' + id]);
  }

  getUserCredits(): void {
    this.subscriptiongetUserCredits = this.companyService.getUserCredits().subscribe((result) => {
      this.userCredits = result.result.credits;
    });
  }

  openAddValuePosition(page): void {
    this.page = page;
    let optionCount = 1;

    if (this.guidedDiscoveryFeature) {
      optionCount++;
    }

    if (optionCount === 1) {
      if (this.dealdeskFeature && this.accountID === '20') {
        if (!this.isValuePositionUser) {
          this.page = 'valuePosition';
        }

        if (this.valuePropGroupsFeature) {
          this.selectOptions('quickcreate');
        } else {
          this.selectOptions('dealdesk');
        }
      } else if (this.dealdeskFeature) {
        this.toggleAddValueProp = !this.toggleAddValueProp;
      } else if (this.isSalesRep && this.hasRepSimpleFeature) {
        this.selectOptions('repsimple');
      } else {
        this.selectOptions('quickcreate');
      }
    } else {
      this.toggleAddValueProp = !this.toggleAddValueProp;
    }
  }

  selectOptions(selectedOpt?): void {
    const aid = sessionStorage.getItem('aid');

    if (!selectedOpt) {
        selectedOpt = this.selectedOption;
    }

    if (selectedOpt === 'quickcreate') {
      if (this.page === 'valuePosition') {
        if (this.valuePropGroupsFeature) {
          this.router.navigate(['addValuePositionV2']);
        } else if (this.isSalesRep && this.hasRepSimpleFeature) {
          this.router.navigate(['repv2']);
        } else {
          this.router.navigate(['addValuePosition']);
        }
      } else {
        if (aid === '464') {
          this.router.navigate(['addValuePositionV2']);
        } else if (this.isSalesRep && this.hasRepSimpleFeature) {
          this.router.navigate(['repv2']);
        } else {
          this.router.navigate(['addvalueprop']);
        }
      }
    } else if (selectedOpt === 'guideddiscovery') {
      if (this.page === 'valuePosition') {
        if (aid === '50009') {
          this.router.navigate(['masterValueProps/discovery']);
        } else {
          this.router.navigate(['guidedDiscovery']);
        }
      } else if (aid === '464' || aid === '50009') {
        this.router.navigate(['addValuePositionV2']);
      } else {
        this.router.navigate(['valueprop/guidedDiscovery']);
      }
    } else if (selectedOpt === 'dealdesk') {
      if (this.page === 'valuePosition') {
        this.router.navigate(['dealdesk']);
      } else {
        this.router.navigate(['valueprop/dealdesk']);
      }
    } else if (selectedOpt === 'masterValueProp') {
      if (this.page === 'valuePosition') {
        this.router.navigate(['addMasterValueProp']);
      } else {
        this.router.navigate(['addMasterValueProp']);
      }
    } else if (selectedOpt === 'repsimple') {
      this.router.navigate(['repv2']);
    }

    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  parseFeatures(): void {
    const features = sessionStorage.getItem('features');
    const aid = sessionStorage.getItem('aid');
    const rid = sessionStorage.getItem('rid');

    if (rid === '1' || rid === '2') {
      this.isSalesRep = true;
    }

    this.valuePropCreateFeatureCount = 0;
    this.accountFeatures = features.split(',');

    if (this.accountFeatures.indexOf('15') >= 0) {
      this.masterValuePropFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
      this.readRecentMasterValueProps();
    } else {
      this.masterValuePropFeature = false;
    }

    if (this.accountFeatures.indexOf('31') >= 0) {
      this.valuePropGroupsFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.masterValuePropFeature = false;
    }

    if (this.accountFeatures.indexOf('16') >= 0) {
      this.dealdeskFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.dealdeskFeature = false;
    }

    if (this.accountFeatures.indexOf('17') >= 0) {
      this.guidedDiscoveryFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.guidedDiscoveryFeature = false;
    }

    if (this.accountFeatures.indexOf('18') >= 0) {
      this.caseStudyFeature = true;
    } else {
      this.caseStudyFeature = false;
    }

    if (this.accountFeatures.indexOf('34') >= 0) {
      this.hasRepSimpleFeature = true;
    }

    if (aid === '50000') {
      this.isGuest = true;
      this.masterValuePropFeature = true;
      this.isValuePropUser = true;
    }

    if ((this.feature41 = this.commonService.checkFeature(41))) {
      if (this.isGuest) {
        this.showAddButtons = false;
      } else if (this.isValuePropUser) {
        this.showAddButtons = false;
      }
    } else {
      if (this.isGuest) {
        this.showAddButtons = false;
      } else {
        this.showAddButtons = true;
      }
    }
  }

  readRecentMasterValueProps(): void {
    const payload = {
      search: 'all',
      page: 1,
      limit: 5,
      sortby: 'modified',
      order: 'desc',
    };

    this.subscriptionreadAndSearchMasterValueProps = this.masterValuePropService.readAndSearchMasterValueProps(payload).subscribe((response) => {
      if (response.result.master_value_props) {
        this.masterValueProps = response.result.master_value_props.data;
      }
    });
  }
}
