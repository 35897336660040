<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>
						{{trans.trans.yourValueRealization.value}}
						<i *ngIf="!(style2022$ | async)" [matTooltip]='trans.trans.youCanMeasureValueForAnyLockedValueProposition.value' class='fa fa-info-circle clickable'></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.youCanMeasureValueForAnyLockedValueProposition">
							info
						</span>
						<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.yourValueRealization'
						[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.yourValueRealization"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-6'>
					<button class="btn btn_customizable pull-right" [matTooltip]='trans.trans.add_new_value_realization.value' matTooltipPosition='below' container="body" placement="bottom" routerLink="/valueprop" *ngIf="showAddButton"><i class="fa fa-plus"></i>{{trans.trans.addNewValueRealization.value}}</button>
				</div>
			</div>
		</div>

		<div class='row marg-v'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tableRealization.filter($any($event.target).value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableRealization.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
			<div class='col-xs-8'>
				<span class="clickable pull-right" (click)='row.toggle($event)'>{{trans.trans.rowLimit.value}} {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<p-table #tableRealization [value]="valueRealization" [columns] = "columns" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns; " [pSortableColumn]="col.field">
								{{trans.trans[col.field].value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='refreshTranslation();' key='value_realization.yourValueRealization'
								[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans[col.field]"></app-editTranslation>
								<p-sortIcon [field]="col.field"></p-sortIcon>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-value>
						<tr>
							<td data-title="{{trans.trans.name.value}}">
								<a routerLink="/valuerealization/{{value.id}}" class='link'>{{ value.name }}</a>
								<a [matTooltip]='trans.trans.viewVp.value' matTooltipPosition='above' routerLink="{{vpRouterLink + value.id}}" target="_blank" style='margin-left: 10px;'><i class='fa fa-arrow-right'></i></a>
							</td>
							<td data-title="{{trans.trans.company.value}}"> {{ value.companyname }}</td>
							<td data-title="{{trans.trans.realizedTo.value}} Date" class="text-right">
								{{ value.total_benefits_fmt }}
							</td>
							<td data-title="{{trans.trans.expectedTo.value}} Date" class="text-right">
								{{ value.improvementfmt }}
							</td>
							<td>
								<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteValueRealization(value.id)'></app-are-you-sure>
							</td>
						</tr>
					</ng-template>
				</p-table>

			</div>
		</div>

	</div>
</div>