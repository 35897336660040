import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';

@Component({
  selector: 'app-dealdesk',
  templateUrl: './dealdesk.component.html',
  styleUrls: ['./dealdesk.component.scss'],
})
export class DealdeskComponent {
  constructor(private translateService: TranslateService, public trans: Translations) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }
}
