import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil, pairwise, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { GoalFormTranslation } from './goal-form.translation';
import { ErrorMessagesService } from '@services/error-messages.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { Goal, GoalGroup, SimpleGoalReactiveForm } from '@shared/models/goal-group.model';
import { ModelGoalsTranslation } from 'app/value-mapv2/solution-detail/goals/model-goals.translation';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-goal-form',
  templateUrl: './goal-form.component.html',
  styleUrls: ['./goal-form.component.scss'],
  animations: [
    trigger('openClose', [
      state('collapsed, void', style({ height: '34px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed, void => collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class GoalFormComponent implements OnInit, OnDestroy {
  @Input() modelGoalIds: string[];
  @Input() loadingSave: boolean;
  @Input() vpId: string;
  @Input() numberLocale: string;
  @Input() hideCreateNew: boolean;
  @Input() goalData: Goal;
  @Output() saveClick = new EventEmitter<SimpleGoalReactiveForm | null>();
  @Output() cancelClick = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate: boolean;
  goalForm: FormGroup;
  accountGoals: Goal[];
  loadingGoals = true;
  isEdit: boolean;
  errorDict = new Map();
  showNewGoal = false;
  goalGroups: GoalGroup[];

  constructor(
    public trans: GoalFormTranslation,
    public parentTrans: ModelGoalsTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private fb: FormBuilder,
    private errorMessageService: ErrorMessagesService,
    private accountGoalsService: GoalGroupsService
  ) {}

  ngOnInit(): void {
    this.isEdit = !!this.goalData;

    this.getTranslations();
    this.buildForm();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.goalForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe), debounceTime(100), startWith(this.goalForm.value), pairwise()).subscribe((formValuesPairwise) => {
      const [previousFormValues, currentFormValues] = formValuesPairwise;
      if (previousFormValues.factorId !== currentFormValues.factorId) {
        this.goalData = this.accountGoals.find((goal) => goal.id === currentFormValues.factorId);
      }

      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.goalForm.controls));
    });

    if (this.isEdit) {
      this.accountGoals = [
        {
          id: this.goalData.account_factor_id,
          name: this.goalData.name,
          description: this.goalData.description,
        },
      ];
      this.loadingGoals = false;
    } else {
      this.accountGoalsService.getGoalGroups().subscribe((groups) => {
        this.goalGroups = groups;
        this.accountGoals = [];
        groups.forEach((group) => {
          this.accountGoals.push(...group.factors.filter((factor) => !this.modelGoalIds.includes(factor.id)));
        });
        this.loadingGoals = false;
      });
    }
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  buildForm(): void {
    this.goalForm = this.fb.group({
      factorId: [
        {
          value: this.isEdit ? this.goalData.account_factor_id : null,
          disabled: this.isEdit,
        },
        [Validators.required],
      ],
    });
  }

  saveGoal(): void {
    if (this.goalForm.valid) {
      const goalFormData = this.goalForm.value;

      this.saveClick.emit({
        ...this.goalData,
        accountFactorId: this.isEdit ? this.goalData.account_factor_id : goalFormData.factorId,
        value: '0',
        baselineValue: '0',
        capture_baseline: this.goalData.capture_baseline,
        capture_target: this.goalData.capture_target,
      });
    }
  }

  refreshGoals(): void {
    this.saveClick.emit(null);
  }

  cancel(): void {
    this.cancelClick.emit();
  }

  toggleGoalForm(): void {
    this.showNewGoal = !this.showNewGoal;
    this.goalForm.reset();
    this.errorDict = new Map();
  }

  removeNegatives(value: number): number {
    return value < 0 ? 0 : value;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }
}
