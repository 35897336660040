import { Component } from '@angular/core';
import { BenefitsImplementation } from '../../benefits.implementation';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';

@Component({
	selector: 'app-benefit-panel-create-flow',
	templateUrl: './benefits-panel-create-flow.component.html',
	styleUrls: ['./benefits-panel-create-flow.component.scss']
})
export class BenefitPanelCreateFlowComponent {
	
	constructor(
		private translateService: TranslateService,
		public trans: Translations,		
		public bI: BenefitsImplementation
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);

	}
}