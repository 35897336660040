import { Injectable } from '@angular/core';

@Injectable ()

export class OtherReportsTranslations {
    public config: any = {
        component: 'otherRepots',
        display_name: 'SFDC Troubleshooting',
        description: 'SFDC Troubleshooting Reports'
    };
    public trans: any = {
        settings: 'Settings',
        dashboard: 'Dashboard',
        other_reports: 'SFDC Troubleshooting',
        search: 'Search Reports',
        backToProfile: 'Back To Profile',
        downloadTooltip: 'Download report'
    }
}