import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ScalersVpDashTranslations } from './scalers.translation';
import { takeUntil } from 'rxjs/operators';
import { Scalers } from 'app/repv2/views/scenario_combined_vpg/views/scalers/scalers.interface';
import { cloneDeep } from 'lodash';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-scalers',
  templateUrl: './scalers.component.html',
})
export class ScalersComponent implements OnInit, OnDestroy {
  @Input() showHelp;
  @Input('data') valueProp: ValueProp;
  @Input() changeDetect: any;
  @Output() valueChange = new EventEmitter();
  @Output() callbackExplained = new EventEmitter();
  @Output() callbackSelectedBenefit = new EventEmitter();
  @Input() showTranslate: boolean = false;
  @Input() canEdit = true;
  @Input() permissions: { [klass: string]: any };
  ngUnsubscribe = new Subject();

  fullImagePath: string;
  image_url: string;
  scalersLoader: boolean = false;
  scalers: Scalers[] = [];
  scalersControl: Scalers[];
  feature26 = this.commonService.checkFeature('26');

  explained: any[] = [];
  locked: boolean = false;

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private notificationService: NotificationService,
    private masterValuePropService: MasterValuePropService,
    public trans: ScalersVpDashTranslations,
    private translationService: TranslationsV2Service,
    private rAS: ResultsAnalysisService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }
  ngOnInit() {
    this.locked = this.valueProp.closed == 1;

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.explained = [];

    this.loadScalers();

    this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadScalers();
    });
    this.valuepropService.reloadScalers$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.loadScalers();
      }
    });

    this.canEdit = this.valueProp.vp_can_edit;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  reloadComponent(_valueProp) {
    this.loadScalers();
  }

  loadScalers() {
    this.scalersLoader = true;
    this.scalers = [];
    let searchLimit = this.valueProp.id;
    this.scalersLoader = true;
    this.valuepropService
      .getScalers(searchLimit, 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.scalers = response.result.scalers;

          this.scalers = this.scalers.map((item) => {
            if (item['name'] == 'includeDNS') {
              item['value'] = 0;
            }
            if (item['greater_than_zero'] == '1') {
              item['greater_than_zero'] = true;
            } else {
              {
                item['greater_than_zero'] = false;
              }
            }
            return item;
          });
          this.scalers = this.scalers.filter((item) => {
            return item['name'] != 'Does Not Scale';
          });
          this.scalersControl = cloneDeep(this.scalers);

          this.scalersLoader = false;
        }
      });
  }

  funcShowExplain() {
    this.callbackExplained.emit(this.explained);
  }

  gotoBenefit(metric) {
    this.callbackSelectedBenefit.emit(metric);
  }

  createScaler() {
    for (let s = 0; s < this.scalers.length; s++) {
      let scale = this.scalers[s];
      scale.source_type_id = this.checkScaler(scale.id) ? '4' : scale.source_type_id;
      if (scale.greater_than_zero && (scale.value == 0 || !scale.value)) {
        this.notificationService.error(scale.name + this.trans.trans.isRequired.value, false);
        return false;
      }
    }

    const payload = {
      value_prop_scalers: this.scalers,
      angular_dev: 1,
    };
    let searchLimit = this.valueProp.id;
    this.scalersLoader = true;
    this.valuepropService
      .createScalers(searchLimit, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result.explained) {
          this.explained = response.result.explained;
        }

        if (response.result && response.result.success !== false) {
          this.rAS.refreshVCChart(this.valueProp, null);
          this.valuepropService.refreshDashboard.next('no_reload');
          this.notificationService.success(this.trans.trans.scalersUpdated.value, false);
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
        this.scalersLoader = false;
      });
  }

  getBenefits(benefitStatus) {
    switch (benefitStatus) {
      case '1':
        return 'green';
      case 'Not a Priority':
        return 'violet';
      case '0':
        return 'red';
      case '2':
        return 'orange';
      case '3':
        return 'orange';
    }
  }

  private checkScaler(id: string): boolean {
    const scaler = this.scalers.find((scaler) => scaler.id === id);
    const controlScaler = this.scalersControl.find((scaler) => scaler.id === id);
    if (scaler && controlScaler) {
      return String(scaler.value) !== String(controlScaler.value);
    } else {
      return false;
    }
  }
}
