<div (click)="backClicked()" class="createBack"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span> {{ trans.add_solution.back | translate }}</div>
&nbsp;&nbsp;
<div class="addSolution">
  <div class="row">
    <div routerLink="/librarySolutions" class="col-md-4">
      <div class="panel panel-white">
        <div class="panel-body text-center" role="button">
          <h4>{{ trans.add_solution.pick_a_model | translate }}</h4>
          <h4>(Recommended)</h4>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="panel panel-white">
        <div class="panel-body text-center" role="button" routerLink="/model/create">
          <h4>{{ trans.add_solution.build_from | translate }}</h4>
          <h4>{{ trans.add_solution.scratch | translate }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
