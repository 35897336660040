<div class="row">
  <div class="col-xs-12" *ngIf="canEdit">
    <a (click)="openAddEditSection(null)" class="a-link-color clickable"><i class="fa fa-plus"></i> {{ trans.trans.add_section.value }}</a>
    <app-editTranslation
      *ngIf="showTranslate"
      [component]="trans.config.component"
      [isTranslationV2]="true"
      [transObj]="trans.trans.add_section"
      (callback)="getTranslations()"
    ></app-editTranslation>
  </div>
</div>

<div *ngIf="caseStudy.sections">
  <div class="row section_row" *ngFor="let section of caseStudy.sections">
    <div class="row">
      <div class="col-xs-4">
        <i (click)="section.expand = !section.expand" *ngIf="!section.expand" class="fa fa-chevron-right" style="margin-right: 10px"></i>
        <i (click)="section.expand = !section.expand" *ngIf="section.expand" class="fa fa-chevron-down" style="margin-right: 10px"></i>
        <span (click)="section.expand = !section.expand" class="clickable section_row_text">{{ section.name }}</span>
      </div>

      <div class="col-xs-7">
        <div class="row" *ngIf="section.case_study_section_images">
          <div class="col-xs-1" *ngFor="let img of section.case_study_section_images">
            <div #listOverlayTarget></div>
            <span *ngIf="img.type === 'media'">
              <i (click)="setOverlayImageVideo(false, img, listViewOverlay, $event)" class="fa fa-picture-o"></i>
            </span>
            <span *ngIf="img.type == 'link'">
              <i (click)="setOverlayImageVideo(false, img, listViewOverlay, $event)" class="fa fa-video-camera" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="col-xs-1">
        <span class="keywest_icon_group pull-right" *ngIf="canEdit">
          <span (click)="openAddEditSection(section)">
            <i class="fa fa-pencil" [matTooltip]="trans.trans.edit.value"></i>
          </span>
          <span>
            <app-are-you-sure
              [matTooltip]="trans.trans.delete.value"
              matTooltipPosition="above"
              [message]="trans.trans.areYouSure.value"
              text=""
              icon="fieldset_icon fa fa-trash"
              (callbackYes)="deleteSection(section.id.toString())"
            >
            </app-are-you-sure>
          </span>
        </span>
        <span class="keywest_icon_group pull-right" *ngIf="!canEdit">
          <span (click)="openViewSection(section)">
            <i class="fa fa-eye clickable"></i>
          </span>
        </span>
      </div>
    </div>

    <div class="row" *ngIf="section.expand">
      <div class="col-xs-4"></div>
      <div class="col-xs-7">
        <span class="quote_quote" [innerHTML]="section.description | filterUrlToLink"></span>
      </div>
    </div>
  </div>
</div>

<p-overlayPanel #listViewOverlay appendTo="body" styleClass="overlayStyle" [showCloseIcon]="true">
  <div class="overlayVideoImage">
    <div class="sidebar_header_container">
      <div class="case_studies_media_list_header">
        {{ overlayImgVideo.description }}
        <i *ngIf="overlayImgVideo.type == 'media'" (click)="downAsset(overlayImgVideo.path)" class="fa fa-cloud-download pull-right download_icon"></i>
      </div>
    </div>
    <span *ngIf="overlayImgVideo.type === 'media'">
      <img src="{{ overlayImgVideo.path }}" class="img-responsive" />
    </span>
    <span *ngIf="overlayImgVideo.type == 'link' && validVideo">
      <div>
        <iframe [src]="embeddedVideoURL" width="600" height="400" frameborder="0" allowfullscreen></iframe>
      </div>
    </span>
    <span style="word-wrap: break-word" *ngIf="overlayImgVideo.type == 'link' && !validVideo"
      >Click here to watch: <a [href]="overlayImgVideo.path" target="_blank">{{ overlayImgVideo.path }}</a></span
    >
  </div>
</p-overlayPanel>

<p-sidebar [(visible)]="toggleAddEditSection" [modal]="isModal" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="resetSection()">
  <app-r-sections *ngIf="toggleAddEditSection && !canEdit" [sectionEdit]="sectionEdit"></app-r-sections>
  <app-ae-sections
    [sectionEdit]="sectionEdit"
    [mode]="mode"
    [caseStudy]="caseStudy"
    *ngIf="toggleAddEditSection && canEdit"
    (valueChange)="toggleAddEditSection = false; valueChange.emit()"
    (closeCallback)="closeAddEditSection()"
  ></app-ae-sections>
</p-sidebar>
