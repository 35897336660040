import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CXOService } from '@data/services/cxo/cxo.service';
import { SimpleType } from 'app/repv2/views/scenario_combined_vpg/views/models/small-model.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ae-maturity',
  templateUrl: './ae_maturity.component.html',
})
export class AEMaturityComponent implements OnInit, OnDestroy {
  @Input() mode = 'add';
  @Input() elem: SimpleType = { id: '' };
  @Output() callback = new EventEmitter();

  account_id = '';
  ngUnsubscribe = new Subject();

  constructor(private CXOService: CXOService) {}

  ngOnInit() {
    this.account_id = sessionStorage.getItem('aid');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  postMaturity(): void {
    const payload = {
      name: this.elem.name,
      description: this.elem.description,
    };
    this.CXOService.adminPostCXO(this.account_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.callback.emit();
      });
  }

  updateMaturity(): void {
    const payload = {
      maturity_model_id: this.elem.id,
      name: this.elem.name,
      description: this.elem.description,
    };
    this.CXOService.adminPutCXO(this.account_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.callback.emit();
      });
  }
}
