<div *ngIf="loaded">
  <div class="row" style="margin-bottom: 20px"></div>
  <dl-menubar styleClass="details_menu_bar" class="details_menu_bar" [model]="items">
    <span *ngIf="isDev" (click)="solutionDetail.can_edit = !solutionDetail.can_edit" class="clickable pull-right">{{
      solutionDetail.can_edit ? 'Switch to View Only Mode' : 'Switch to Edit Mode'
    }}</span>
  </dl-menubar>

  <div class="details_panel">
    <div class="row">
      <div class="col-xs-12">
        <div class="details_panel_container">
          <app-overview
            [can_edit]="solutionDetail.can_edit"
            (callbackGotoMenuItem)="view = $event; funcDrawMenu()"
            *ngIf="view === 'overview'"
            (refreshSolutionDetails)="setSolutionDetail($event)"
          ></app-overview>
          <app-model-scaler-defaults
            (onUpdate)="getSolutionDetail()"
            [canEdit]="solutionDetail.can_edit"
            [modelId]="solutionID"
            *ngIf="view == 'scalers'"
          ></app-model-scaler-defaults>
          <app-benefits [can_edit]="solutionDetail.can_edit" [default_term]="default_term" *ngIf="view === 'benefits'" [modelEditor]="modelEditor"></app-benefits>
          <app-factors-list (onSaveAll)="getSolutionDetail()" [can_edit]="solutionDetail.can_edit" *ngIf="view == 'factors'"></app-factors-list>
          <app-model-goals *ngIf="view === 'goals'" [modelId]="solutionID" [canEdit]="solutionDetail.can_edit"></app-model-goals>
          <app-detail-situation
            [default_term]="default_term"
            [can_edit]="solutionDetail.can_edit"
            [tabInit]="1"
            [solutionId]="solutionID"
            *ngIf="view === 'situations'"
          ></app-detail-situation>
          <app-solutioncompetitors [can_edit]="solutionDetail.can_edit" [solutionID]="solutionID" *ngIf="view === 'competitors'"></app-solutioncompetitors>
          <app-costs [can_edit]="solutionDetail.can_edit" [solutionID]="solutionID" [term]="default_term" *ngIf="view === 'costs'"></app-costs>

          <app-collateral
            [can_edit]="solutionDetail.can_edit"
            [showHelp]="true"
            [contextualHelp]="contextualHelp"
            [solutionID]="solutionID"
            *ngIf="view === 'collateral'"
          ></app-collateral>
          <app-case-studies [can_edit]="solutionDetail.can_edit" [solutionID]="solutionID" *ngIf="view === 'casestudies'"></app-case-studies>
          <app-thresholds [can_edit]="solutionDetail.can_edit" [solutionID]="solutionID" *ngIf="view === 'thresholds'"></app-thresholds>
          <app-validation
            (onUpdate)="getSolutionDetail()"
            [benefitValidations]="benefitValidations"
            [factorValidations]="factorValidations"
            style="margin-top: 24px"
            *ngIf="view === 'validation'"
            [solutionID]="solutionID"
            (validationErrors)="onValidationErrors($event)"
          ></app-validation>
        </div>
      </div>
    </div>
  </div>
</div>
