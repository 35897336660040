import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';

@Injectable()
export class OperationalObjectivesService {
	service_url: string;

	constructor(private CommonService: CommonService) {
		this.service_url = this.CommonService.getServiceUrl();
	}

	public postOperationalObjective(account_id, payload) {
		let endpoint = "account/operationalObjective/" + account_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getOperationalObjectives(account_id) {
		let endpoint = "account/operationalObjectives/" + account_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public getOperationalObjectivesSolution(account_solution_id) {
		let endpoint = "solutions/operationalObjectives/" + account_solution_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public putOperationalObjective(account_id, payload) {
		let endpoint = "account/operationalObjective/" + account_id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

	public deleteOperationalObjectives(account_id, account_operational_objective_id) {
		let endpoint = "account/operationalObjective/" + account_id + "/delete/" + account_operational_objective_id;
		return this.CommonService.deleteAPIService(endpoint);
	}
}