import { ChangeDetectionStrategy, Component, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: '[ticker]',
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: text-indent;
      }
    `,
    `
      :host:hover {
        text-indent: var(--indent);
        text-overflow: clip;
        user-select: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TickerComponent {
  @HostBinding('style.--indent.px')
  indent = 0;

  @HostBinding('style.transitionDuration.ms')
  get duration(): number {
    return -10 * this.indent;
  }

  @HostListener('mouseenter', ['$event.target'])
  onMouseEnter({ scrollWidth, clientWidth }: HTMLElement) {
    this.indent = clientWidth - scrollWidth;
  }
}
