<div class="keywest_header">
  <div class="row">
    <div class="col-xs-6">
      <div class="title">
        {{ trans.trans.goals.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.goals"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
    </div>

    <div class="col-xs-6">
      <button *ngIf="canEdit" (click)="openGoal()" class="btn btn_customizable pull-right marg-h"><i class="fa fa-plus"></i>{{ trans.trans.addGoal.value }}</button>
      <button *ngIf="canEdit" (click)="addToExistingVpsConfirm()" class="btn btn_customizable pull-right">
        <i class="fa fa-plus"></i>{{ trans.trans.addGoalToExisting.value }}
      </button>
    </div>
  </div>
</div>
<p-confirmDialog #cd [style]="{ width: '410px' }">
  <p-footer>
    <button type="button" class="btn btn_customizable" (click)="cd.accept()">{{ trans.trans.yes.value }}</button>
    <button [ngClass]="'btn-light'" type="button" class="btn btn_customizable_clear marg-h" (click)="cd.reject()">
      {{ trans.trans.no.value }}
    </button>
  </p-footer>
</p-confirmDialog>
<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

<ng-template #loadedContent>
  <div class="row">
    <div class="col-xs-12">
      <form [formGroup]="goalForm">
        <div formArrayName="goals">
          <p-table #tableGoalsList [value]="goalArray.controls" styleClass="keywest_table" tableStyleClass="keywest_table" dataKey="value.id">
            <ng-template pTemplate="header">
              <tr>
                <th>
                  {{ trans.trans.includeByDefault.value }}
                </th>
                <th pSortableColumn="value.name">
                  {{ trans.trans.nameCol.value }}
                  <p-sortIcon field="value.name"></p-sortIcon>
                </th>
                <th>
                  {{ trans.trans.linkedFactor.value }}
                </th>
                <th>{{ trans.trans.units.value }}</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
              <tr class="not-hover" [formGroupName]="rowIndex">
                <td class="col-default-active">
                  <p-checkbox [binary]="true" [formControl]="$any(goalArray.controls[rowIndex]).controls['includeByDefault']"></p-checkbox>
                </td>
                <td class="col-name">
                  <span>
                    {{ rowData.value.name }}
                  </span>
                  <span *ngIf="rowData.value.description" class="material-icons-outlined tooltip-custom info-icon icon" [matTooltip]="rowData.value.description"> info </span>
                  <i
                    *ngIf="rowData.value.hasScratchpad === 1"
                    (click)="openGoal(rowData.value.id, null, true)"
                    class="clickable fa fa-calculator icon"
                    [ngStyle]="{ color: 'green' }"
                    style="margin-left: 10px"
                  ></i>
                </td>

                <td class="col-benefit">
                  <ng-container *ngIf="availableFactors">
                    <p-multiSelect
                      appendTo="body"
                      *ngIf="canEdit"
                      panelStyleClass="multi-select-item-font"
                      class="keywest_dropdown multi-select-theme"
                      [options]="availableFactors | filterFactors: rowData.value.id:linkedFactorsId:selectedFactors"
                      [(ngModel)]="selectedFactors[rowData.value.id]"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="!availableFactors"
                      [placeholder]="trans.trans.selectFactor.value"
                      (onChange)="updateGoal(rowData.value, rowData.value.id)"
                      optionDisabled="linked"
                      [showToggleAll]="false"
                    >
                      <ng-template let-item pTemplate="item">
                        {{ item.value !== '0' && item.linked ? '(' + trans.trans.alreadyLinked.value + '): ' : '' }}{{ item.label }}
                      </ng-template>
                    </p-multiSelect>
                    <span *ngIf="!canEdit">{{ availableFactors | functionPipe: findFactor:rowData.value.linkedFactor }}</span>
                  </ng-container>
                </td>
                <td class="col-units">
                  <app-unit-type [unitType]="rowData.value.unitType"></app-unit-type>
                </td>
                <td class="col-trash">
                  <div *ngIf="canEdit" matTooltipPosition="above" [matTooltip]="trans.trans.deleteGoal.value">
                    <app-are-you-sure
                      class="flex-omni-c"
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="removeGoal(rowData.value)"
                    ></app-are-you-sure>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6" class="text-center">{{ trans.trans.emptyTable.value }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<p-sidebar [(visible)]="toggleSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="hideGoalSidebar()">
  <ng-container *ngIf="aeElement === 'addGoal' && toggleSidebar">
    <app-goal-form
      *ngIf="toggleSidebar"
      [goalData]="selectedGoal"
      [modelGoalIds]="goalsListIds"
      [loadingSave]="loadingSave"
      [numberLocale]="numberLocales[+userData.number_format] ?? 'en-US'"
      [hideCreateNew]="true"
      (saveClick)="saveGoal($event)"
      (cancelClick)="toggleSidebar = false"
    ></app-goal-form>
  </ng-container>
  <ng-container *ngIf="aeElement === 'scratchpad' && toggleSidebar">
    <app-ae-scratchpads [factor]="selectedGoal" [templateSetting]="'goalFactor'" (callback)="getGroups(); toggleSidebar = false"></app-ae-scratchpads>
  </ng-container>
</p-sidebar>
