import { Observable, Subject } from 'rxjs';
import { StyleService } from 'app/style.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CXOService } from '@data/services/cxo/cxo.service';
import { SimpleType } from 'app/repv2/views/scenario_combined_vpg/views/models/small-model.model';
import { takeUntil } from 'rxjs/operators';
import { CXOMaturity } from '@shared/models/cxo.models';

@Component({
  selector: 'app-admin-maturity',
  templateUrl: './maturity.component.html',
})
export class AdminMaturityComponent implements OnInit, OnDestroy {
  mode = 'add';
  elem: SimpleType = {
    id: '',
  };

  account_id = '';
  ngUnsubscribe = new Subject();
  maturity: CXOMaturity[] = [];
  toggleAdd = false;
  strSearch = '';
  loading = false;
	style2022$: Observable<boolean>;

  constructor(private CXOService: CXOService, private styleService: StyleService) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.account_id = sessionStorage.getItem('aid');
    this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  funcAddMaturity(): void {
    this.elem = {};
    this.mode = 'add';
    this.toggleAdd = true;
  }

  funcEditMaturity(elem): void {
    this.elem = elem;
    this.mode = 'edit';
    this.toggleAdd = true;
  }

  getData(): void {
    this.loading = true;
    this.CXOService.adminGetCXO(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.maturity = res.result;

        this.loading = false;
      });
  }

  funcDelete(elem): void {
    this.CXOService.adminDeleteCXO(this.account_id, elem.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getData();
      });
  }
}
