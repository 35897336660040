import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValuepropComponent } from './valueprop/valueprop.component';
import { ValuePropDashboardComponent } from './value-prop-dashboard/dashboard_system/value-prop-dashboard.component';
import { AddValuePropComponent } from './add-value-prop/add-value-prop.component';
import { DealdeskComponent } from '../value-position/dealdesk/dealdesk.component'; 
import { MasterValuePropComponent } from './master-value-prop/master-value-prop.component';
import { CompareComponent } from './master-value-prop/compare/compare.component';
import { MasterValuePropListComponent } from './master-value-prop/master-value-prop-list/master-value-prop-list.component';
import { RSV2CreateComponent } from 'app/repv2/views/create/create.component';

const routes: Routes = [
	{
		path: 'valueprop/dealdesk',
		component: DealdeskComponent,
	},
	{
		path: 'valueprop/repv2',
		component: RSV2CreateComponent,
	},
	{
		path: 'valueprop/:id',
		component: ValuePropDashboardComponent,
		data: { page: 'valuePropDashboard' }
	},
	{
		path: 'valueprop/:id/group/:group_id',
		component: ValuePropDashboardComponent,
		data: { page: 'valuePropDashboard' }
	},
	{
		path: 'valueprop',
	    component: ValuepropComponent,
	},
	{
		path: 'addvalueprop',
		component: AddValuePropComponent,
		data: { page: 'addvalueprop' }
	},
	{
		path: 'mastervalueprop',
		component: MasterValuePropListComponent,
		data: { page: 'addvalueprop' }
	},
	{
		path: 'mastervalueprop/:id',
		component: MasterValuePropComponent,
		data: { page: 'addvalueprop' }
	},
	{
		path: 'mastervalueprop/:id/:group',
		component: MasterValuePropComponent,
		data: { page: 'addvalueprop' }
	},
	{
		path: 'mastervalueprop/:id/compare',
		component: CompareComponent,
		data: { page: 'mastervalueprop' }
	},
	{
		path: 'mastervalueprop/:id/compare/:group',
		component: CompareComponent,
		data: { page: 'mastervalueprop' }
	}
	
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ValuePropRoutingModule { }
