<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>{{trans.trans.deploymentScehdule.value}}</div>
				</div>
			</div>
		</div>
		<div class='row' *ngIf="!showDeployment && dealdesk">
			<div class='col-xs-12'>
				<div class="form-group tbl-container">
					<label class="graduated_label">
						{{trans.trans.will_this_have_a_graduated_deployment.value }}
					</label><br>
					<input type="radio" name="showDeployment" (click)="promptForDeployment(1)" /> {{ trans.trans.Yes.value }}
					<input type="radio" name="showDeployment" (click)="promptForDeployment(0)" /> {{ trans.trans.No.value }}
				</div>
			</div>
		</div>
		<p-progressBar *ngIf="graduatedDeploymentLoader" mode="indeterminate"></p-progressBar>
		<form *ngIf="!graduatedDeploymentLoader && showDeployment && deploymentSchedule" name="GraduatedDeploymentForm" #GraduatedDeploymentForm="ngForm">
			<div *ngIf="term == 1">
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 1 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr1' [(ngModel)]="deploymentSchedule.yr1_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr1val' [(ngModel)]="deploymentSchedule.yr1_percent"></div>
					</div>
				</div>
			</div>

			<div *ngIf="term == 2">
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 1 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr1' [(ngModel)]="deploymentSchedule.yr1_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr1val' [(ngModel)]="deploymentSchedule.yr1_percent"></div>
					</div>
				</div>
				<div class="row" style='margin-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 2 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr2' [(ngModel)]="deploymentSchedule.yr2_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr2val' [(ngModel)]="deploymentSchedule.yr2_percent"></div>
					</div>
				</div>
			</div>

			<div *ngIf="term == 3">
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 1 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr1' [(ngModel)]="deploymentSchedule.yr1_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr1val' [(ngModel)]="deploymentSchedule.yr1_percent"></div>
					</div>
				</div>
				<div class="row" style='margin-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 2 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr2' [(ngModel)]="deploymentSchedule.yr2_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr2val' [(ngModel)]="deploymentSchedule.yr2_percent"></div>
					</div>
				</div>
				<div class="row" style='margin-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 3 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr3' [(ngModel)]="deploymentSchedule.yr3_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr3val' [(ngModel)]="deploymentSchedule.yr3_percent"></div>
					</div>
				</div>
			</div>

			<div *ngIf="term == 4">
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 1 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr1' [(ngModel)]="deploymentSchedule.yr1_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr1val' [(ngModel)]="deploymentSchedule.yr1_percent"></div>
					</div>
				</div>
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 2 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr2' [(ngModel)]="deploymentSchedule.yr2_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr2val' [(ngModel)]="deploymentSchedule.yr2_percent"></div>
					</div>
				</div>
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 3 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr3' [(ngModel)]="deploymentSchedule.yr3_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr3val' [(ngModel)]="deploymentSchedule.yr3_percent"></div>
					</div>
				</div>
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 4 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr4' [(ngModel)]="deploymentSchedule.yr4_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr4val' [(ngModel)]="deploymentSchedule.yr4_percent"></div>
					</div>
				</div>
			</div>

			<div *ngIf="term == 5">
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 1 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr1' [(ngModel)]="deploymentSchedule.yr1_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr1val' [(ngModel)]="deploymentSchedule.yr1_percent"></div>
					</div>
				</div>
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 2 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr2' [(ngModel)]="deploymentSchedule.yr2_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr2val' [(ngModel)]="deploymentSchedule.yr2_percent"></div>
					</div>
				</div>
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 3 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr3' [(ngModel)]="deploymentSchedule.yr3_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr3val' [(ngModel)]="deploymentSchedule.yr3_percent"></div>
					</div>
				</div>
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 4 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr4' [(ngModel)]="deploymentSchedule.yr4_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr4val' [(ngModel)]="deploymentSchedule.yr4_percent"></div>
					</div>
				</div>
				<div class="row" style='padding-bottom: 20px;'>
					<div class="col-sm-12">
						<div class="col-sm-2">{{trans.trans.Year.value}} 5 :</div>
						<div class="col-sm-8">
							<input type='range' name='yr5' [(ngModel)]="deploymentSchedule.yr5_percent">
						</div>
						<div class="col-sm-2"><input type='text' name='yr5val' [(ngModel)]="deploymentSchedule.yr5_percent"></div>
					</div>
				</div>
			</div>
		</form>
		<div *ngIf="!graduatedDeploymentLoader && showDeployment && deploymentSchedule && netPercents">
			<table class="table table-striped" *ngIf="term == 1">
				<thead>
					<th></th>
					<th></th>
				</thead>
				<tbody>
					<tr>
						<td>{{ trans.trans.deployment_cumulative.value }}</td>
						<td>{{deploymentSchedule.yr1_percent}}%</td>
					</tr>
					<tr>
						<td>{{trans.trans.deployment_net.value }}</td>
						<td>{{netPercents.net_percents.yr1_percent}}%</td>
					</tr>
					<tr *ngIf="cumulative_values">
						<td>{{netPercents.cumulative_values.name}}</td>
						<td>{{netPercents.cumulative_values.yr1_percent}}</td>
					</tr>
					<tr *ngIf="net_values">
						<td>{{netPercents.net_values.name}}</td>
						<td>{{netPercents.net_values.yr1_percent}}</td>
					</tr>
				</tbody>
			</table>

			<table class="table table-striped" *ngIf="term == 2">
				<thead>
					<th></th>
					<th></th>
					<th></th>
				</thead>
				<tbody>
					<tr>
						<td>{{ trans.trans.deployment_cumulative.value }}</td>
						<td>{{deploymentSchedule.yr1_percent}}%</td>
						<td>{{deploymentSchedule.yr2_percent}}%</td>

					</tr>
					<tr>
						<td>{{trans.trans.deployment_net.value }}</td>
						<td>{{netPercents.net_percents.yr1_percent}}%</td>
						<td>{{netPercents.net_percents.yr2_percent}}%</td>
					</tr>
					<tr *ngIf="cumulative_values">
						<td>{{netPercents.cumulative_values.name}}</td>
						<td>{{netPercents.cumulative_values.yr1_percent}}</td>
						<td>{{netPercents.cumulative_values.yr2_percent}}</td>
					</tr>
					<tr *ngIf="net_values">
						<td>{{netPercents.net_values.name}}</td>
						<td>{{netPercents.net_values.yr1_percent}}</td>
						<td>{{netPercents.net_values.yr2_percent}}</td>
					</tr>
				</tbody>
			</table>


			<table class="table table-striped" *ngIf="term == 3">
				<thead>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
				</thead>
				<tbody>
					<tr>
						<td>{{ trans.trans.deployment_cumulative.value }}</td>
						<td>{{deploymentSchedule.yr1_percent}}%</td>
						<td>{{deploymentSchedule.yr2_percent}}%</td>
						<td>{{deploymentSchedule.yr3_percent}}%</td>

					</tr>
					<tr>
						<td>{{trans.trans.deployment_net.value }}</td>
						<td>{{netPercents.net_percents.yr1_percent}}%</td>
						<td>{{netPercents.net_percents.yr2_percent}}%</td>
						<td>{{netPercents.net_percents.yr3_percent}}%</td>
					</tr>
					<tr *ngIf="cumulative_values">
						<td>{{netPercents.cumulative_values.name}}</td>
						<td>{{netPercents.cumulative_values.yr1_percent}}</td>
						<td>{{netPercents.cumulative_values.yr2_percent}}</td>
						<td>{{netPercents.cumulative_values.yr3_percent}}</td>
					</tr>
					<tr *ngIf="net_values">
						<td>{{netPercents.net_values.name}}</td>
						<td>{{netPercents.net_values.yr1_percent}}</td>
						<td>{{netPercents.net_values.yr2_percent}}</td>
						<td>{{netPercents.net_values.yr3_percent}}</td>
					</tr>
				</tbody>
			</table>

			<table class="table table-striped" *ngIf="term == 4">
				<thead>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
				</thead>
				<tbody>
					<tr>
						<td>{{trans.trans.deployment_cumulative.value }}</td>
						<td>{{deploymentSchedule.yr1_percent}}%</td>
						<td>{{deploymentSchedule.yr2_percent}}%</td>
						<td>{{deploymentSchedule.yr3_percent}}%</td>
						<td>{{deploymentSchedule.yr4_percent}}%</td>

					</tr>
					<tr>
						<td>{{trans.trans.deployment_net.value }}</td>
						<td>{{netPercents.yr1_percent}}%</td>
						<td>{{netPercents.yr2_percent}}%</td>
						<td>{{netPercents.yr3_percent}}%</td>
						<td>{{netPercents.yr4_percent}}%</td>
					</tr>

					<tr *ngIf="cumulative_values">
						<td>{{netPercents.cumulative_values.name}}</td>
						<td>{{netPercents.cumulative_values.yr1_percent}}</td>
						<td>{{netPercents.cumulative_values.yr2_percent}}</td>
						<td>{{netPercents.cumulative_values.yr3_percent}}</td>
						<td>{{netPercents.cumulative_values.yr4_percent}}</td>
					</tr>
					<tr *ngIf="net_values">
						<td>{{netPercents.net_values.name}}</td>
						<td>{{netPercents.net_values.yr1_percent}}</td>
						<td>{{netPercents.net_values.yr2_percent}}</td>
						<td>{{netPercents.net_values.yr3_percent}}</td>
						<td>{{netPercents.net_values.yr4_percent}}</td>
					</tr>
				</tbody>
			</table>

			<table class="table table-striped" *ngIf="term == 5">
				<thead>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
				</thead>
				<tbody>
					<tr>
						<td>{{trans.trans.deployment_cumulative.value }}</td>
						<td>{{deploymentSchedule.yr1_percent}}%</td>
						<td>{{deploymentSchedule.yr2_percent}}%</td>
						<td>{{deploymentSchedule.yr3_percent}}%</td>
						<td>{{deploymentSchedule.yr4_percent}}%</td>
						<td>{{deploymentSchedule.yr5_percent}}%</td>

					</tr>
					<tr>
						<td>{{trans.trans.deployment_net.value }}</td>
						<td>{{netPercents.yr1_percent}}%</td>
						<td>{{netPercents.yr2_percent}}%</td>
						<td>{{netPercents.yr3_percent}}%</td>
						<td>{{netPercents.yr4_percent}}%</td>
						<td>{{netPercents.yr5_percent}}%</td>
					</tr>

					<tr *ngIf="cumulative_values">
						<td>{{netPercents.cumulative_values.name}}</td>
						<td>{{netPercents.cumulative_values.yr1_percent}}</td>
						<td>{{netPercents.cumulative_values.yr2_percent}}</td>
						<td>{{netPercents.cumulative_values.yr3_percent}}</td>
						<td>{{netPercents.cumulative_values.yr4_percent}}</td>
						<td>{{netPercents.cumulative_values.yr5_percent}}</td>
					</tr>
					<tr *ngIf="net_values">
						<td>{{netPercents.net_values.name}}</td>
						<td>{{netPercents.net_values.yr1_percent}}</td>
						<td>{{netPercents.net_values.yr2_percent}}</td>
						<td>{{netPercents.net_values.yr3_percent}}</td>
						<td>{{netPercents.net_values.yr4_percent}}</td>
						<td>{{netPercents.net_values.yr5_percent}}</td>
					</tr>
				</tbody>
			</table>

		</div>

		<div class='row'>
			<div class="col-md-12">
				<div class="form-group">
					<button *ngIf="!dealdesk && valueProp.vp_can_edit" type="submit" class="btn btn_customizable" (click)="updateGraduatuedDeplyment()">{{trans.trans.save.value}}</button>
					<button *ngIf="dealdesk && graduatedRadio" type="submit" class="btn btn_customizable" (click)="updateGraduatuedDeplyment()">{{trans.trans.next.value}} <i class="fa fa-chevron-right"></i></button>
				</div>
			</div>
		</div>
	</div>
</div>