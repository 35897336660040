import { Component, Input, OnInit } from '@angular/core';
import { UserPrivilege } from '@shared/models/user-info.model';
import { UserProfileTranslation } from '../user-profile.translation';

@Component({
  selector: 'app-privileges-card',
  templateUrl: './privileges-card.component.html',
  styleUrls: ['./privileges-card.component.scss']
})
export class PrivilegesCardComponent implements OnInit {

  @Input() privileges: UserPrivilege[] = [];

  constructor(public trans: UserProfileTranslation,) { }

  ngOnInit() {}
}
