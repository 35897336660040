import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DiscoverQuestionsVpDashTranslations } from './question.translation';
import { DiscoveryQuestionsComponent } from './questions.component';

@NgModule({
  imports: [MatTooltipModule, AreYouSureModule, CommonModule, FormsModule, EditTranslationsModule, AddCompanyModule, InputSwitchModule, FieldsetModule],
  declarations: [DiscoveryQuestionsComponent],
  providers: [Translations, TranslationsV2Service, DiscoverQuestionsVpDashTranslations],
  exports: [DiscoveryQuestionsComponent],
})
export class RSV2DiscoveryQuestionsModule {}
