import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { APIResult } from '@shared/models/api-result.model';
import {
  Goal,
  GoalGroup,
  GoalPayload,
  GoalPayloadOneTime,
  ValuePropAddGoal,
  ValuePropGoal,
  ValueRealizationGoal,
  ValueRealizationGoalGroup,
  ValueRealizationSetGoal,
} from '@shared/models/goal-group.model';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { BusinessReviewGoalsElement } from './models/business-goals-response.interface';
import { BusinessReviewFactorsUpdate, BusinessReviewUpdate } from './models/business-goals-update.interface';

@Injectable()
export class GoalGroupsService {
  constructor(private commonService: CommonService) {}

  getGoalGroups(functionTypeId = 2): Observable<GoalGroup[]> {
    const endpoint = `account/factor-categories?function_type_id=${functionTypeId}`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  createGoalGroup(accountId: string, goalGroup: GoalGroup) {
    const endpoint = `account/factorCategory/${accountId}`;
    return this.commonService.postAPIService(endpoint, goalGroup);
  }

  updateGoalGroup(accountId: string, goalGroup: GoalGroup) {
    const endpoint = `account/factorCategory/${accountId}`;
    return this.commonService.putAPIService(endpoint, goalGroup);
  }

  deleteGoalGroup(accountId: string, factorCategoryId: string) {
    const endpoint = `account/factorCategory/${accountId}/delete/${factorCategoryId}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  createGoal(accountId: string, goal: GoalPayload) {
    const endpoint = `account/factor/${accountId}`;
    return this.commonService.postAPIService(endpoint, goal);
  }

  createOneTimeGoal(valuePropId: string, goal: GoalPayloadOneTime) {
    const endpoint = `valueProps/oneTimeGoal/${valuePropId}`;
    return this.commonService.postAPIService(endpoint, goal);
  }

  updateGoal(accountId: string, goal: GoalPayload) {
    const endpoint = `account/factor/${accountId}`;
    return this.commonService.putAPIService(endpoint, goal);
  }

  deleteGoal(goalId: string) {
    const endpoint = `account/deleteAccountfactor/${goalId}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  getValuePropGoals(valuePropId: string): Observable<GoalGroup[]> {
    const endpoint = `valueProps/${valuePropId}/factors?function_type_id=2`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  getValuePropBaselineGoals(valuePropId: string, overrideName = 1): Observable<Goal[]> {
    const endpoint = `valueProps/${valuePropId}/factors?function_type_id=2&grouped=true&include_inactive=1&vr_override_name=${overrideName}`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  getValuePropGoalsWithVPSpecific(valuePropId: string): Observable<GoalGroup[]> {
    const endpoint = `valueProps/${valuePropId}/factors?function_type_id=2`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  getValueRealizationsGoals(valuePropId: string, businessReviewId: number | null = null): Observable<BusinessReviewGoalsElement[]> {
    const endpoint = `valueRealization/${valuePropId}/read/businessReview/goals?business_review_id=${businessReviewId}&grouped=false`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }
  getValueRealizationsGoalsGrouped(valuePropId: string, businessReviewId: number | null = null): Observable<ValueRealizationGoalGroup[]> {
    const endpoint = `valueRealization/${valuePropId}/read/businessReview/goals?business_review_id=${businessReviewId}&grouped=true`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  addValuePropGoal(valuePropId: string, payload: ValuePropAddGoal): Observable<ValuePropGoal[]> {
    const endpoint = `valueProps/${valuePropId}/factors`;
    return this.commonService.postAPIService(endpoint, { factors: [payload] }).pipe(pluck('result', 'message'));
  }

  addValuePropGoals(valuePropId: string, payload: ValuePropAddGoal[]): Observable<ValuePropGoal[]> {
    const endpoint = `valueProps/${valuePropId}/factors`;
    return this.commonService.postAPIService(endpoint, { factors: payload }).pipe(pluck('result', 'message'));
  }

  deleteValuePropGoal(valuePropId: string, goalId: string): Observable<any> {
    const endpoint = `valueProps/${valuePropId}/factor/${goalId}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  getValueRealizationGoal(valuePropId: string): Observable<ValueRealizationGoal[]> {
    const endpoint = `valueRealization/${valuePropId}/goals`;
    return this.commonService.getAPIService(endpoint).pipe(pluck('result', 'factors'));
  }

  setValueRealizationGoals(valuePropId: string, payload: ValueRealizationSetGoal[]): Observable<void> {
    const endpoint = `valueRealization/${valuePropId}/factors`;
    return this.commonService.putAPIService(endpoint, { factors: payload });
  }

  saveBusinessReview(valuePropId: string, payload: { name: string | null }): Observable<APIResult<{ business_review_id: number; message: string }>> {
    const endpoint = `valueRealization/${valuePropId}/businessReview`;
    return this.commonService.postAPIService(endpoint, payload);
  }

  updateBusinessReview(valuePropId: number, payload: BusinessReviewFactorsUpdate): Observable<APIResult<SuccessFailResponse>> {
    const endpoint = `valueRealization/updateBusinessReviewFactors/${valuePropId}`;
    return this.commonService.putAPIService(endpoint, payload);
  }

  deleteBusinessReview(businessReviewId: number): Observable<any> {
    const endpoint = `valueRealization/businessReview/${businessReviewId}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  updateBusinessReviewNameDate(businessReviewId: number, payload: BusinessReviewUpdate): Observable<APIResult<SuccessFailResponse>> {
    const endpoint = `valueRealization/businessReview/${businessReviewId}`;
    return this.commonService.putAPIService(endpoint, payload);
  }
}
