import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, column_name : string): any {
    if (!items || !searchText) {
        return items;
    }
    return items.filter(item => {
      if(column_name) {
        return item[column_name].toLowerCase().indexOf(searchText) !== -1
      }
      else {
        return item.name.toLowerCase().indexOf(searchText) !== -1
      }
    });
}
  
}
