<div class="row marg-v">
  <div class="col-md-8">
    <h3 class="title">Value Categories</h3>
  </div>
  <div class="col-md-4">
    <button type="button" *ngIf="can_edit" class="btn btn_customizable pull-right marg-h" (click)="openAddValueCategory()">
      <i class="fa fa-plus"></i> {{ trans.General.addNew | translate }}
    </button>
  </div>
</div>

<div class="table-responsive col-sm-12">
  <div class="searchTableContainer">
    <table class="table custom-table">
      <thead>
        <tr>
          <th style="border: 0px">{{ trans.General.name_label | translate }}</th>
          <th style="border: 0px"></th>
        </tr>
      </thead>

      <tbody *ngIf="valueCategories.length == 0">
        <tr>
          <td colspan="2">{{ trans.General.no_value_categories_found | translate }}</td>
        </tr>
      </tbody>

      <tbody>
        <tr class="grid-row company-row" *ngFor="let valueCategory of valueCategories">
          <td>{{ valueCategory.name }}</td>
          <td>
            <div class="dropdown pull-right">
              <span *ngIf="can_edit" class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
              <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                <li (click)="editSolutionValueCategory(valueCategory, updateValueCategoryModal)"><i class="icon-pencil"></i> {{ 'general.Edit' | translate }}</li>

                <li (click)="deleteSolutionValueCategory(valueCategory.id)"><i class="icon-trash"></i> {{ trans.General.Delete | translate }}</li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #updateValueCategoryModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.General.Update | translate }} {{ trans.create_map.value_category | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-xs-12">
          <label>Value Category Name</label>
          <input type="text" class="form-control" [(ngModel)]="valueCategoryToEdit.name" />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <label>Description</label>
          <textarea name="description" [(ngModel)]="valueCategoryToEdit.description" class="form-control" rows="4"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn_customizable" (click)="updateValueCategory(c)">{{ trans.General.save | translate }}</button>
      <button type="button" class="btn btn-light marg-h" (click)="c('Close click')">{{ trans.General.Cancel | translate }}</button>
    </div>
  </div>
</ng-template>
