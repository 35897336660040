import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SelectedElement } from '@data/services/valueposition/models/selected-element.interface';
import { BenefitType } from '@shared/models/benefits.models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BenefitTypeTranslations } from '../benefit-type.translation';

@Component({
  selector: 'app-r-benefit-types',
  templateUrl: './r-benefit-type.component.html',
  styleUrls: ['./r-benefit-type.component.scss'],
})
export class RBenefitTypeComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() selectedElem: Partial<BenefitType>;

  name = '';
  description = '';
  account_id: string;
  ngUnsubscribe$ = new Subject();
  showTranslate = false;

  constructor(
    private commonService: CommonService,
    public trans: BenefitTypeTranslations,
    private translationService: TranslationsV2Service
  ) { }

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

}
