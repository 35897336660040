import { Component, Input, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TagsTranslations } from './tags.translation';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CaseStudy } from '@shared/models/case-study.model';
import { CaseStudyPayload } from '../interfaces/case-study-payload.interface';

@Component({
  selector: 'app-case-study-tags',
  templateUrl: './tags.component.html',
})
export class CaseStudyTagsComponent implements OnInit, OnDestroy {
  @Input() caseStudyId: string;
  @Input() caseStudy: CaseStudy;
  @Input() can_edit = false;
  @Output() save = new EventEmitter<CaseStudyPayload>();

  ngUnsubscribe = new Subject();
  showTranslate = false;

  quoteFormObj = {
    contact_first_name: undefined,
    contact_last_name: undefined,
    title: undefined,
    quote: undefined,
    account_case_study_quote_id: undefined,
  };
  mode: string;
  toggleAddEditQuote: boolean;
  sidebar_title = 'Add Quote';
  tags: string[] = [];

  constructor(
    public trans: TagsTranslations,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    ) {}

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.sidebar_title = this.mode === 'edit' ? this.trans.trans.editQuote : this.trans.trans.addQuote;
      });
  }

  removeTag(index): void {
    this.caseStudy.tags.splice(index, 1);
    this.saveCaseStudy();
  }

  saveCaseStudy(): void {
    if (this.tags && this.tags.length) {
      this.tags.forEach((elem) => {
        if (this.caseStudy.tags) {
          this.caseStudy.tags.push(elem);
        } else {
          this.caseStudy.tags = [elem];
        }
      });
    }

    const payload: CaseStudyPayload = {
      case_study_id: this.caseStudy.id,
      name: this.caseStudy.name,
      tag: this.caseStudy.tags ? this.caseStudy.tags.join() : '',
    };

    this.save.emit(payload);
  }
}
