import { Injectable } from '@angular/core';

@Injectable ()

export class UnitTypeTranslations {

    public config: any = {
        component: 'UnitTypeComponent',
        display_name: 'Unit Type Component',
        description: 'This translate the unit types',
    };

    public trans: any = {
        currency: 'Currency',
        currencyThousands: 'Currency Thousands',
        currencyMillions: 'Currency Millions',
        currencyBillions: 'Currency Billions',
        thousands: 'Thousands',
        millions: 'Millions',
        billions: 'Billions',
        seconds: 'Seconds',
        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days',
        months: 'Months',
        years: 'Years',
        percent: 'Percent',
        basisPoints: 'Basis Points',
        miles: 'Miles',
        number: 'Number',
        weeks: 'Weeks',
        currencyAbbr: '$',
        currencyThousandsAbbr: '$K',
        currencyMillionsAbbr: '$M',
        currencyBillionsAbbr: '$B',
        thousandsAbbr: 'K',
        millionsAbbr: 'M',
        billionsAbbr: 'B',
        secondsAbbr: 'Sec',
        minutesAbbr: 'Min',
        hoursAbbr: 'H',
        daysAbbr: 'D',
        monthsAbbr: 'Mos',
        yearsAbbr: 'Yrs',
        percentAbbr: '%',
        basisPointsAbbr: 'BPs',
        milesAbbr: 'MI',
        numberAbbr: '',
        weeksAbbr: 'Wk',
    };
}
