import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../_services/alert.service';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-welcome',
	template: ``
	// templateUrl: './modal.component.html',
})
export class WelcomeComponent implements OnInit, OnDestroy {
	subscriptionSubscribeLoggedIn: Subscription;

	constructor(
		private router: Router,
		private AlertService: AlertService,

	) {

	}
	ngOnInit() {
		let vcu = sessionStorage.getItem('vcu');
		if (vcu) {
			this.router.navigate(['/dashboard']);

		}

		this.subscriptionSubscribeLoggedIn = this.AlertService.subscribeLoggedIn().subscribe(() => {
			this.router.navigate(['/dashboard']);			
		})
	}

	ngOnDestroy() {
		if (this.subscriptionSubscribeLoggedIn) { this.subscriptionSubscribeLoggedIn.unsubscribe(); }
	}
}
