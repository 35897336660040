<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>
<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title">
            {{ trans.trans.factor_groups.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="'factors'"
              [isTranslationV2]="true"
              [transObj]="trans.trans.factor_groups"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-6">
          <span class="clickable pull-right" (click)="row.toggle($event)" style="margin-top: 5px"> Row Limit: {{ rows }} <i class="fa fa-chevron-down"></i> </span>

          <a *ngIf="can_edit" (click)="openAddFactor()" class="btn btn_customizable pull-right marg-h"><i class="fa fa-plus"></i>{{ trans.trans.add_new.value }}</a>
          <a *ngIf="can_edit" (click)="openAddFactorGroup()" class="btn btn_customizable pull-right marg-h"><i class="fa fa-plus"></i>{{ trans.trans.add_new_group.value }}</a>

          <a class="btn btn_customizable pull-right marg-h" (click)="toggleExpandCollapse()" *ngIf="expanded">{{ trans.trans.expand_all.value }}</a>
          <a class="btn btn_customizable pull-right marg-h" (click)="toggleExpandCollapse()" *ngIf="!expanded"> {{ trans.trans.collapse_all.value }}</a>
        </div>
      </div>
    </div>

    <div class="row marg-v">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input [formControl]="strSearch" type="text" placeholder="Search" class="form-control" />
          <span *ngIf="strSearch.value !== ''" (click)="cleanFilter()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
    </div>
  </div>
  <p-progressBar *ngIf="loading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

  <div *ngIf="!loading">
    <ng-container *ngFor="let category of filteredFactorGroups">
      <dl-fieldset
        *ngIf="category.factors.length"
        [scratchpad]="true"
        [info]="category.description"
        [editCallbackVisible]="+category.id === 0 ? false : can_edit ? true : false"
        (editCallback)="toggleEditGroup(category)"
        [deleteCallbackVisible]="+category.id === 0 ? false : can_edit ? true : false"
        (deleteCallback)="deleteFactorGroup(category)"
        styleClass="benefits_legend"
        class="benefits_legend"
        [legend]="category.name"
        [collapsed]="category.collapsed"
        [toggleable]="true"
        [transitionOptions]="'225ms cubic-bezier(0.4,0.0,0.2,1)'"
      >
        <div class="row">
          <div class="col-xs-12">
            <p-table
              *ngIf="!loadingPages; else loader"
              #pTable
              dataKey="id"
              [columns]="cols"
              [value]="category.factors"
              [paginator]="true"
              [rows]="strSearch.value.length ? 10000 : rows"
              styleClass="keywest_table"
              tableStyleClass="keywest_table"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th style="width: 40px"></th>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{ trans.trans[col.field].value }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="'factors'"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans[col.field]"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowIndex="rowIndex" let-expanded="expanded" let-factor>
                <tr>
                  <td style="width: 40px">
                    <i style="color: gold; margin-left: 10px; font-size: 20px" [ngClass]="factor.is_key_factor == 1 ? 'fa fa-star' : 'fa fa-star-o'"></i>
                  </td>
                  <td>
                    <span class="clickable" (click)="can_edit ? funcToggleEdit(factor) : funcToggleView(factor)">{{ factor.name }}</span>
                    <i
                      *ngIf="factor.description && !(style2022$ | async)"
                      class="fa fa-info-circle"
                      [matTooltip]="factor.description"
                      matTooltipPosition="above"
                      style="margin-left: 10px"
                    ></i>
                    <span
                      *ngIf="factor.description && (style2022$ | async)"
                      class="material-icons-outlined tooltip-custom info-icon"
                      [matTooltip]="factor.description"
                      matTooltipPosition="above"
                    >
                      info
                    </span>
                    <i
                      [pRowToggler]="factor"
                      (click)="toggleRowExtension(factor, 'scratchpad')"
                      [matTooltip]="trans.trans.ch_edit_scratchpad.value"
                      matTooltipPosition="above"
                      class="clickable fa fa-calculator"
                      [ngStyle]="{ color: factor.has_scratchpad == 1 ? 'green' : 'inherit' }"
                      style="margin-left: 10px"
                    ></i>
                    <i
                      [pRowToggler]="factor"
                      (click)="toggleRowExtension(factor, 'scaledBy')"
                      *ngIf="factor.scale_type_id !== '0'"
                      [matTooltip]="trans.trans.ch_edit_scalers.value"
                      matTooltipPosition="above"
                      class="clickable fa fa-level-up"
                      style="margin-left: 10px"
                    ></i>
                  </td>
                  <td>{{ factor.created }}</td>
                  <td>
                    <span *ngIf="can_edit" class="keywest_icon_group pull-right">
                      <span>
                        <i (click)="funcToggleEdit(factor)" matTooltip="Edit Factor" matTooltipPosition="above" class="clickable fa fa-pencil"></i>
                        <app-are-you-sure
                          matTooltip="Delete"
                          matTooltipPosition="above"
                          message="Are you sure?"
                          text=""
                          icon="fieldset_icon fa fa-trash"
                          (callbackYes)="deleteFactor(factor)"
                        ></app-are-you-sure>
                      </span>
                    </span>
                    <span *ngIf="!can_edit" class="keywest_icon_group pull-right">
                      <span
                        ><i (click)="funcToggleView(factor)" class="fa fa-eye clickable pull-right" [matTooltip]="trans.trans.ch_view.value" matTooltipPosition="above"></i
                      ></span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-factor>
                <tr class="not-hover" *ngIf="factor.rowView === 'scratchpad'">
                  <td colspan="4">
                    <div>
                      <app-ae-scratchpads *ngIf="can_edit" [embed]="true" (callback)="getFactorCategories()" [factor]="factor" [calledFrom]="'factor_groups'"></app-ae-scratchpads>
                      <app-r-scratchpads *ngIf="!can_edit" (callback)="getFactorCategories()" [factor]="factor"></app-r-scratchpads>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="factor.rowView === 'scaledBy'">
                  <td colspan="4">
                    <app-factor-scaled [factor]="factor" [canEdit]="can_edit" (callback)="getFactorCategories()"></app-factor-scaled>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <ng-template #loader>
              <p-progressBar mode="indeterminate"></p-progressBar>
            </ng-template>
          </div>
        </div>
      </dl-fieldset>
    </ng-container>
  </div>
</div>

<p-sidebar [(visible)]="toggleAddEditFactor" styleClass="keywest_sidebar p-sidebar-xl" position="right" (onHide)="onCloseSideBar()">
  <app-ae-factors
    *ngIf="toggleAddEditFactor && can_edit"
    [mode]="mode_factor"
    (callbackBack)="onCloseSideBar($event)"
    [formObjFactor]="formObjFactor"
    (callback)="getFactorCategories()"
  ></app-ae-factors>
  <app-r-factors *ngIf="toggleAddEditFactor && !can_edit" [formObjFactor]="formObjFactor"></app-r-factors>
</p-sidebar>

<p-sidebar [(visible)]="toggleAddEditFactorGroup" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <form name="formAddEditFactorGroup" #formAddEditFactorGroup="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ sidebar_title_factor_group }}
        <app-editTranslation
          *ngIf="showTranslate && mode_group == 'add'"
          [component]="'factors'"
          [isTranslationV2]="true"
          [transObj]="trans.trans.add_factor_group"
          (callback)="getTranslations()"
        ></app-editTranslation>
        <app-editTranslation
          *ngIf="showTranslate && mode_group == 'edit'"
          [component]="'factors'"
          [isTranslationV2]="true"
          [transObj]="trans.trans.edit_group"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-md-12" appNGError [elemControl]="formAddEditFactorGroup.controls.name">
              <label for="name" class="sidebar_label">{{ trans.trans.name.value }}</label>
              <input type="text" class="form-control" ret-data="Name" [(ngModel)]="formObj.name" name="name" placeholder="{{ trans.trans.name.value }}" required />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" appNGError [elemControl]="formAddEditFactorGroup.controls.description">
              <label for="description" class="sidebar_label"
                >{{ trans.trans.description.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="'factors'"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.description"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <textarea name="description" ret-data="Description" [(ngModel)]="formObj.description" rows="4" class="form-control"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_container">
        <div class="row">
          <div class="col-md-12">
            <input type="hidden" name="id" [(ngModel)]="formObj.id" />
            <app-btn-save
              *ngIf="toggleAddEditFactorGroup && mode_group == 'add'"
              [form]="formAddEditFactorGroup"
              (callback)="insertFactorGroup(formAddEditFactorGroup)"
              [text]="trans.trans.save.value"
              class="btn btn_customizable pull-right"
            ></app-btn-save>
            <app-btn-save
              *ngIf="toggleAddEditFactorGroup && mode_group == 'edit'"
              [form]="formAddEditFactorGroup"
              (callback)="updateFactorGroup(formAddEditFactorGroup)"
              [text]="trans.trans.update.value"
              class="btn btn_customizable pull-right"
            ></app-btn-save>
            <app-are-you-sure
              matTooltipPosition="above"
              [message]="trans.trans.are_you_sure.value"
              class="btn cancel-confirm pull-right marg-h"
              icon=""
              [text]="trans.trans.back.value"
              (callbackYes)="closeAddEditFactorGroup()"
            ></app-are-you-sure>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>

<p-menu #rowMenu appendTo="body" [popup]="true" [model]="menu" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>
