  <div class="col-sm-12">
    <div class="pull-right m-b-xxl">
      <button (click)="openAddAccountStage(createAccountStage, 'new')" class="btn btn-primary pull-right btn-right">
      	<i class="fa fa-plus"></i> {{ trans.general.add | translate }}
      </button>
    </div>
  </div>
  <div class="soln_table_cont">
    <div class="table-responsive col-sm-12 soln-table">
        <div class="">
          <table class="table custom-table">
            <thead>
              <tr>
                <th>{{ trans.general.name_label | translate }}</th>
                <th>{{ trans.profile.applicationSettings.sequence | translate }}</th>
                <th>{{ trans.general.actions | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let accountStage of accountStages" class="grid-row">
                <td>{{accountStage.name}}</td>
                <td>{{accountStage.sequence}}</td>
                <td class="user_icons">
		            <i class="icon-pencil m-r-md" (click)="openAddAccountStage(createAccountStage, 'edit', accountStage)"></i>
		            <i class="icon-trash"  (click)="openDeleteAccountStage(deleteAccountStageModal, accountStage)"></i>
		        </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
    <div *ngIf="accountStages.length == 0 && !accountStagesLoader" class="m-t-md col-sm-12">
      <div class="text-center">
        <h4>{{ trans.profile.applicationSettings.noAccountStagesFound | translate }}</h4>
      </div>
    </div>
    <div *ngIf="accountStagesLoader">
        <img class="actionLoader" src="{{fullImagePath}}" />
    </div>
  </div>

<div class="clearfix"></div>

<ng-template #createAccountStage let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
       <span *ngIf="!edit">{{ trans.profile.applicationSettings.createNew | translate }}</span>
       <span *ngIf="edit">{{ trans.general.Update | translate }}</span>
       {{ trans.profile.applicationSettings.accountStage | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click');">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="createAccountStageForm" #createAccountStageForm="ngForm">
        <div class="form-group" [ngClass]="{'has-error': (createAccountStageForm.controls.accountStageName?.invalid && createAccountStageForm.controls.accountStageName?.touched)}">
            <label for="partner" class="col-sm-4 control-label labelsRequired">{{ trans.profile.applicationSettings.accountStageName | translate }}</label>
            <div class="col-sm-7">
                <div class="input-group m-b-sm">
                    <div class="input-group-btn">
                        <input type="text" class="form-control" name="accountStageName" placeholder="{{ trans.profile.applicationSettings.accountStageName | translate }}" [(ngModel)]="accountStageName" required>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-sm-offset-4 col-sm-7">
                <button type="submit" class="btn btn-primary" [disabled]="createAccountStageForm.invalid" (click)="ngOnSubmit(createAccountStageForm.value); c('Close click');">{{ trans.general.save | translate }}</button>
                <button type="button" class="btn btn-secondary btn-cancel" (click)=" createAccountStageForm.reset(); c('Close click');">{{ trans.general.Cancel | translate }}</button>
            </div>
        </div>
         <div class="pull-right">
            <label class="labelsRequiredInfo">{{ trans.general.isRequired | translate }}</label>
        </div>
        <div class="clearfix"></div>
    </form>
  </div>
</ng-template>


<ng-template #deleteAccountStageModal let-c="close" let-d="dismiss">
	<div class="modal-header">
	<h4 class="modal-title">
	   {{ trans.profile.applicationSettings.deleteAccountStage | translate }}
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="d('Cross click');">
	  <span aria-hidden="true">&times;</span>
	</button>
	</div>
	<div class="modal-body">
		<p>{{ trans.profile.applicationSettings.deleteConfirmation | translate }}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="deleteAccountStage();c('Close click')">{{ trans.general.Yes | translate }}</button>
		<button type="button" class="btn btn-secondary" (click)="c('Close click')">{{ trans.general.No | translate }}</button>
	</div>
</ng-template>