<ng-container *ngIf="!!transComp">
  <span [ngClass]="styleClass">{{ transObj.value }}</span>
</ng-container>

<ng-container *ngIf="currentLanguageObj && languages && showTranslateLocal">
  <span [ngClass]="styleClass"><i class="fa fa-exchange clickable" (click)="op.show($event)" style="margin-left: 10px; color: green; font-size: 14px !important"></i></span>

  <p-overlayPanel #op appendTo="body" styleClass="keywest_edit_trans">
    <div *ngIf="!isTranslationV2">
      <small style="font-size: 11px">
        <i *ngIf="!toggleAll" (click)="toggleAll = !toggleAll" class="fa fa-chevron-right"></i>
        <i *ngIf="toggleAll" (click)="toggleAll = !toggleAll" class="fa fa-chevron-down"></i>
        {{ trans.trans.currentLanguage.value }} {{ trans.trans[languages[+currentLanguage - 1].lang].value }}</small
      ><br />
      <small style="font-size: 11px">{{ trans.trans.original.value }} {{ languages[+currentLanguage - 1].original }}</small>
      <div style="width: 400px">
        <div class="input-group" style="width: 100%">
          <input
            type="text"
            (keyup)="languages[+currentLanguage - 1].updated = true"
            [(ngModel)]="languages[+currentLanguage - 1].value"
            class="form-control"
            style="width: 400px"
          />
          <div *ngIf="toggleAll" class="input-group-btn">
            <a (click)="translateAll()" ngbTooltip="{{ trans.trans.translate.value }}" container="body" placement="bottom" class="btn btn-success"
              ><i class="fa fa-chevron-down"></i
            ></a>
          </div>
        </div>
      </div>
      <div *ngIf="toggleAll" style="overflow-y: scroll; width: 450px; max-height: 300px">
        <div *ngFor="let item of languages" style="margin-top: 10px">
          <div [hidden]="item.id === currentLanguage">
            <small style="font-size: 11px">{{ item.lang }}</small>
            <br />
            <small *ngIf="item.original" style="font-size: 11px">{{ trans.trans.original.value }} {{ item.original }}</small>
            <input type="text" (keyup)="item.updated = true" [(ngModel)]="item.value" class="form-control" />
          </div>
        </div>
      </div>
      <div style="margin-top: 10px; margin-bottom: 10px; width: 450px; display: block">
        <a (click)="funcSave()" class="btn btn-success pull-right">{{ trans.trans.translate.value }}</a>
      </div>
    </div>

    <div *ngIf="isTranslationV2" style="padding: 10px">
      <small style="font-size: 11px"> {{ trans.trans.currentLanguage.value }} {{ trans.trans[currentLanguageObj.lang]?.value }}</small>
      <span [class]="currentLanguageObj['flag']" style="margin-left: 10px"></span>
      <br />
      <small style="font-size: 11px">{{ trans.trans.original?.value }} {{ transObj?.value }}</small>
      <div style="width: 420px">
        <div class="input-group" style="width: 100%">
          <input type="text" (keyup)="languages[+currentLanguage - 1].updated = true" [(ngModel)]="newTranslationText" class="form-control" style="width: 420px" />
        </div>
      </div>

      <div style="margin-top: 10px; width: 420px; display: block">
        <a (click)="funcSaveV2()" style="margin: 10px 0" class="btn btn_customizable pull-right">{{ trans.trans.translate.value }}</a>
      </div>
    </div>
  </p-overlayPanel>
</ng-container>
