<div class="models_panel">
  <div class="models_page_header_container_small page_header">
    <div class="row">
      <div class="col-sm-12 log-wrapper">
        <section *ngIf="changeLog && changeLog !== null && changeLog.length > 0" id="cd-timeline" class="cd-container log-container">
          <div class="cd-timeline-block" *ngFor="let log of changeLog">
            <div class="cd-timeline-img cd-info">
              <div class="timeline-circle flex-omni-c">
                <div class="timeline-circle-border flex-omni-c">
                  <div class="timeline-inner-circle"></div>
                </div>
              </div>
            </div>
            <!-- cd-timeline-img -->
            <div class="cd-timeline-content">
              <h4 class="timeline-title">{{ log.section }}</h4>
              <span class="timeline-medium-date">{{ log.modified | date: 'mediumDate' }}</span>
              <p>{{ log.log_text }}</p>
              <div class="timeline-speech-container"><div class="timeline-speech-box"></div></div>
            </div>
            <!-- cd-timeline-content -->
            <div class="cd-date">
              <span class="timeline-short-date">{{ log.modified | date: 'short' }}</span>
            </div>
          </div>
          <!-- cd-timeline-block -->
        </section>
        <!-- cd-timeline -->
        <div *ngIf="changeLogLoader" class="col-sm-12 m-t-md text-center">
          <!-- <img class="loader" src="{{fullImagePath}}" /> -->
        </div>
        <div *ngIf="(changeLog === null || changeLog.length === 0) && !changeLogLoader" class="flex-omni-c f-column">
          <img src="assets/images/no-data-placeholder.svg" alt="No Records" />
          <span class="muted-t">{{ trans.trans.no_logs.value }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
