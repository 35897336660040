
import { Component, Input } from '@angular/core';
import { AvailableSolution } from '@data/services/valueposition/models/available-solutions.interface';
import { CompanyDetails } from '@data/services/valueposition/models/company-details.interface';
import { MasterValueProp } from '@data/services/valueposition/models/mast-value-prop.interface';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
  template: ``,
  selector: 'app-addvp-account',
})
export class AddVPGenericComponent {
  @Input() contextualHelp;
  @Input() valuePropId: number | string;
  @Input() masterValueProp: MasterValueProp;
  @Input() masterValuePropId: number | string;
  @Input() ValuePropTerm: any;
  @Input() isvalueProp: boolean;
  @Input() accountDefinedId: number | string;
  @Input() opportunityId: number | string;
  @Input() isOppurtunityEnabled: boolean;
  @Input() aid: string;
  @Input() isVMWare: boolean;
  @Input() valueProp: ValueProp;
  @Input() availableSolutions: AvailableSolution[];
  @Input() index: string;
  @Input() progress: any[] = [];
  @Input() value_prop_group: MasterValueProp;
  @Input() companyDetails: CompanyDetails;
  @Input() showHelp: boolean;
  @Input() addJustCase: any;
  @Input() showTranslate: boolean;
}
