import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { FilterArrayByIndexPipe } from 'app/pipe/filter-array-by-index.pipe';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TreeTableModule } from 'primeng/treetable';
import { AEMaintenanceSituationsModule } from './ae_situations/ae_situations.module';
import { AEMaintenanceSituationsTranslations } from './ae_situations/ae_situations.translation';
import { RMaintenanceSituationsModule } from './r_situations/r_situations.module';
import { MaintenanceSituationsComponent } from './situations.component';
import { DefaultTranslations } from './situations.translation';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [MaintenanceSituationsComponent],
  imports: [
    ProgressBarModule,
    MatTooltipModule,
    NgbModule,
    TreeTableModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    MenuModule,
    SidebarModule,
    TableModule,
    NgbModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    AreYouSureModule,
    RMaintenanceSituationsModule,
    AEMaintenanceSituationsModule,
    EditTranslationsModule,
    EllipsisMenuModule,
    DropdownModule,
    ApplicationPipesModule,
    RadioButtonModule,
  ],
  providers: [DefaultTranslations, TranslationsV2Service, AEMaintenanceSituationsTranslations],
  entryComponents: [MaintenanceSituationsComponent],
  exports: [MaintenanceSituationsComponent],
})
export class MaintenanceSituationsModule {}
