import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'search-company',
    display_name: 'VP Search Company',
    description: 'Search for a company for a new vp'
  };

  public trans: any = {
    description: 'Description',
    add_company: 'Add Company',
    address: 'Address',
    city: 'City',
    country: 'Country',
    employees: 'Employees',
    heres_what_we_know_about_the_account_you_have_selected: `Here's what we know about the account you have selected`,
    no_companies_found: 'No Companies Found',
    pick_your_account: 'Pick your account',
    revenue: 'Revenue',
    search_for_a_company: 'Search for a Company',
    sector: 'Sector',
    ticker: 'Ticker',
    website: 'Website',
    add_new: 'Add New',
    account_id: 'Account ID',
    name: 'Name',
  };
}
