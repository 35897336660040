export interface SurveyTemplate {
  account_id: string;
  created: string;
  id: string;
  is_active: string;
  modified: string;
  name: string;
}

export interface SurveyTemplateWithQuestions extends SurveyTemplate {
  questions_list: SurveyQuestion[];
}

export interface SurveyQuestion {
  account_survey_questions_template_id: string;
  created: string;
  id: string;
  label: string;
  modified: string;
  question_options: any;
  question_type_id: string;
  template_name: string;
  answer?: string | any[];
  checked?: boolean;
}