import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ModelCollateralModule } from '../value-map/solution-detail/collateral/collateral.module';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ModelCollateralModule
	],
	declarations: [
	],
	exports: [
	]
})
export class ShareFeatureModule { }
