import { Observable } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { Subject } from 'rxjs';
import { AssetsManageProfileTranslations } from './assets.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AssetService } from '@data/services/asset/asset.service';
import { StyleService } from 'app/style.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { Template } from '@data/services/valueprop/models/asset.model';
import { Solution } from '@data/services/valueprop/models/solution.model';
import { NotificationService } from '@services/notification.service';
import { ColumnMeta } from '@shared/models/common.models';
import { RowLimit } from 'app/_models/row-limit.model';
import { Router } from '@angular/router';
import { AssetTemplate } from '@shared/models/asset.models';
import { MenuItem } from 'primeng/api';


@Component({
	selector: 'app-assets',
	styleUrls: ['./assets.component.scss'],
	templateUrl: './assets.component.html'
})
export class AssetsComponent implements OnInit, OnDestroy {
	@ViewChild('addAssetTemplateModal', { static: true }) addAssetTemplateModal;
	fullImagePath: string;

	template: Template;
	step1Wrapper: boolean;
	step2Wrapper: boolean;
	step3Wrapper: boolean;
	templates: Template[] = [];
	enableSaveInElements: boolean;
	editPage: 'add' | 'edit' = 'add';
	actionLoader: boolean;
  showNoSaveWarning = false;

	ngUnsubscribe = new Subject<boolean>();
	showTranslate = false;
	toggleAddAsset = false;
	toogleAddTheme = false;
	// theme flag
	assetDefaultThemesFeatureFlag = false;
	breadcrumbKeyValues: Breadcrumbs;

	assets: AssetTemplate[] = [];
	cols: ColumnMeta[]= [
		{ field: 'name', header: 'Name' },
		{ field: 'format', header: 'Format' },
	];
	pageLimit = 10;
	rowLimit: MenuItem[];
	strSearch = '';
  solutions: Solution[] = [];

	obj: {asset_type_id: string; customer_facing: string; templates: Template[]} = {
		asset_type_id: '',
		customer_facing: '',
		templates: [],
	}

	loading = false;
	selectedElem: Partial<AssetTemplate> = {};
	style2022$: Observable<Boolean>;


	constructor(
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: AssetsManageProfileTranslations,
		private valuepropService: ValuepropService,
		private assetService: AssetService,
		private styleService: StyleService,
    private solutionsService: SolutionService,
    private notificationService: NotificationService,
	private router: Router
	) {
		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit(): void {
		this.assetDefaultThemesFeatureFlag = this.commonService.checkFeature('153');
		this.style2022$ = this.styleService.style2022;
		this.getData();
		this.funcBuildPageLimit();
		this.templates = [];
		this.templates.push({
			language_type_id: '',
			name: '',
			description: '',
			artifact_name: '',
			default_active: '0'
		});

    this.getSolutions();
		this.step1Wrapper = false;

		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

  getSolutions(): void {
    this.solutionsService.getAvailableSolutionsFull('/limit/all/page/1/search/all/sortby/id/order/desc').subscribe(res => {
      this.solutions = res.result.data;
    });
  }

	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);

		const payload = {
			account_id: sessionStorage.getItem('aid'),
			component: this.trans.config.component,
			lang: langAbbr,
			localTranslations: this.trans.trans
		};

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

			this.breadcrumbKeyValues = {
				[this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
				[this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
				[this.trans.trans.manageAssetTemplates.value]: { routerLink: '', active: true },
			  };
		});
	}

	setRowLimit(num): void {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit(): void {
		this.rowLimit = [
			{ label: '10', icon: this.pageLimit === 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: '25', icon: this.pageLimit === 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: '50', icon: this.pageLimit === 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: '100', icon: this.pageLimit === 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		];
	}

	getData(): void {
		this.selectedElem = null;
		this.assets = [];
		this.toggleAddAsset = false;
		this.loading = true;
		const searchLimit = '/limit/all/page/all';
		this.valuepropService.readValuePropAssetTemplates(searchLimit).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
			if (result.result) {
				this.assets = result.result;
			}
			this.loading = false;
		});
	}

	deleteAsset(rowData): void {
		this.assetService.deleteAsset(rowData.account_asset_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getData();
		});
	}

	funcToggleAdd(): void {
		this.toggleAddAsset = true;
		this.editPage = 'add';
		this.selectedElem = null;
	}

	funcToggleEdit(asset): void {
		this.selectedElem = asset;
		this.toggleAddAsset = true;
		this.editPage = 'edit';
	}

  hideSidebar(): void {
    if (this.showNoSaveWarning) {
      this.notificationService.error(this.trans.trans.slideOutWarning.value, false);
      this.showNoSaveWarning = false;
    }
  }

  viewLog(rowData){
    this.assetService.setLogId(rowData.id);
	this.router.navigate(['asset-log']);
  }

  public onStandardAssetsGenerated() {
	this.getData();
  }
}
