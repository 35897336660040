import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SituationTranslations } from './situation.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { TreeNode } from 'primeng/api';
import { SituationItem, SituationToggle, SolutionData } from '@shared/models/situation.model';
import { BasicInfo } from '@shared/models/basic-info.model';

@Component({
  selector: 'app-detail-situation',
  templateUrl: './situation.component.html',
  styleUrls: ['./situation.component.scss'],
})
export class SituationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() solutionId: string;
  @Input() tabInit: number;
  @Input() can_edit = false;
  @Input() default_term = 3;

  ngUnsubscribe = new Subject();

  customcheckboxlabel: string;
  toggles: SituationToggle;
  account_solution_id: number | string;
  account_situation_id: number;
  situation_type_id: number;
  situation_type_id_obj: Array<SituationItem> = [];
  situationTypes: SituationItem[];
  accountSituationType: Array<SituationItem> = [];
  situationElementTypes: BasicInfo[];
  situation: Partial<SituationItem>;
  situations: SolutionData[];
  allExpanded = false;

  // dropdown
  edit = false;
  situationLoader = false;

  // Loader Images
  fullImagePath: string;

  masterValuePropFeature: boolean;

  showTranslate = false;

  mode = 'add';

  showAddView = false;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private factsService: FactsService,
    private notificationService: NotificationService,
    public trans: SituationTranslations,
    private translationService: TranslationsV2Service
  ) {
    const image_url = this.commonService.getImageUrl();
    this.fullImagePath = image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.account_solution_id = this.solutionId;

    this.situation = {
      account_solution_id: this.account_solution_id,
    };

    this.customcheckboxlabel = 'No';

    this.reset();

    this.masterValuePropFeature = false;
    if (this.commonService.checkFeature('15')) {
      this.masterValuePropFeature = true;
    }
  }

  ngOnDestroy() {
    // Cache the current situation tree and its expanded nodes when the component is destroyed
    this.solutionService.setSituationTreeMap(this.account_solution_id.toString(), this.situations);
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  // Fix to call API's on tab first time load alone and skip next loads
  ngOnChanges() {
    this.reset();
    if (this.tabInit === 1) {
      this.account_solution_id = this.solutionId;

      this.getSituationsBySolution();
      this.getSituationTypes();
      this.getAccountSituationTypes();
      this.getElementTypes();
    }
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  reset() {
    this.toggles = {
      addType: false,
      showSituationForm: false,
      addElement: false,
      defineBenefits: false,
      defineCosts: false,
      showList: true,
      showBenefitsCosts: false,
      situation_type_stack_order: false,
      situation_type_edit: false,
      showView: false,
    };

    this.edit = false;
  }

  toggleAddSituation(id) {
    this.mode = null;
    if (!id) {
      setTimeout(() => {
        this.mode = 'add';
      }, 0);
      this.situation = {};
      this.situation_type_id = null;
      this.situation_type_id_obj = [];
    } else {
      this.mode = 'edit';
    }

    this.toggles.showList = false;
    this.toggles.addType = false;
    this.toggles.showSituationForm = true;
    this.toggles.showBenefitsCosts = false;

    this.edit = false;
  }

  toggleShowList() {
    this.toggles.addType = false;
    this.toggles.showSituationForm = false;
    this.toggles.addElement = false;
    this.toggles.showList = true;
    this.toggles.showView = false;

    this.getSituationsBySolution();
  }

  getSituationTypes() {
    this.solutionService
      .getSituationTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.situationTypes = result.result;
      });
  }

  getAccountSituationTypes() {
    this.accountSituationType = [];
    this.solutionService
      .getAccountSituationTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.accountSituationType = result.result;
          this.getSituationsBySolution();
        }
      });
  }

  getElementTypes() {
    this.factsService
      .getSituationElementTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.situationElementTypes = result.result;
      });
  }

  getSituationsBySolution() {
    this.situationLoader = true;

    this.solutionService
      .getSituationsBySolutionTree(this.account_solution_id, true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.situationLoader = false;
        this.solutionService.setSituationTreeMap(this.account_solution_id.toString(), Array.isArray(result) ? result : result.result.data);
        if (!Array.isArray(result)) {
          this.situations = [
            ...result.result.data
              .map((situation) => ({
                ...situation,
                data: {
                  ...situation.data,
                  sequence: this.accountSituationType.find((situationType) => situationType.id === situation.data.id)?.sequence,
                },
              }))
              .sort(function (a, b) {
                return +a.data.sequence - +b.data.sequence;
              }),
          ];
        } else {
          this.situations = [
            ...result
              .map((situation) => ({
                ...situation,
                data: {
                  ...situation.data,
                  sequence: this.accountSituationType.find((situationType) => situationType.id === situation.data.id)?.sequence,
                },
              }))
              .sort(function (a, b) {
                return +a.data.sequence - +b.data.sequence;
              }),
          ];
        }
        this.onNodeChange();
      });
  }

  expandOrCollaseAll(expandChildren: boolean): void {
    this.situations.forEach((node) => {
      this.expandChildren(node, expandChildren);
    });
    this.allExpanded = expandChildren;
    this.situations = [...this.situations];
  }

  onNodeChange() {
    const extractChildren = (x) => x.children;
    let flat = [];
    this.situations.forEach((situation) => {
      flat = [...flat, { expanded: situation.expanded, level: 0, name: situation.data.name }];
      flat = [
        ...flat,
        ...this.flatten(extractChildren(situation), extractChildren, null, null)
          .map((x) => delete x.children && x)
          .filter((x) => x.expanded !== undefined),
      ];
    });
    this.allExpanded = flat.every((x) => x.expanded === true);
  }

  flatten = (children, extractChildren, level, parent) =>
    Array.prototype.concat.apply(
      children.map((x) => ({ ...x, level: level || 1, parent: parent || null })),
      children.map((x) => this.flatten(extractChildren(x) || [], extractChildren, (level || 1) + 1, x.id))
    );

  expandChildren(node: TreeNode, expandChildren: boolean) {
    if (node.children) {
      node.expanded = expandChildren;
      for (let childNode of node.children) {
        this.expandChildren(childNode, expandChildren);
      }
    }
  }

  deleteSituation(id) {
    this.situationLoader = true;
    this.solutionService
      .deleteSituation(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.situationLoader = false;
        this.notificationService.success('Situation deleted', false); // Params {message, islogout}
        this.getSituationsBySolution();
      });
  }

  showEditView(situation: SituationItem) {
    this.mode = 'edit';
    this.situation = situation;
    this.situation['default_active'] = parseInt(situation['default_active']);
    this.situation['guided_default_active'] = parseInt(situation['guided_default_active']);
    this.situation_type_id = +this.situation['situation_type_id'];
    let situation_type_id_obj = this.situationTypes.find((situation) => situation['id'] == this.situation['situation_type_id']);
    this.situation_type_id_obj = [situation_type_id_obj];
    this.account_situation_id = +this.situation['id'];
    this.toggles.showList = false;
    this.toggles.addType = false;
    this.toggles.showSituationForm = true;
    this.toggles.showBenefitsCosts = true;

    this.edit = true;
  }

  showSituationView(situation) {
    this.situation = situation;
    this.toggles.showView = true;
  }
}
