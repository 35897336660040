import { Component, Input, OnInit, Output, EventEmitter, OnChanges, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EditDirectiveService } from './edit.service';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { EditTranslationTranslations } from './edit.translation';
import { takeUntil } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { UserService } from '@data/services/user/user.service';
import { Dictionary } from '@shared/models/dictionary.model';
import { LanguageType } from '@shared/models/user-info.model';

export interface TransObj {
  key: string;
  value: string;
  translation_id: string;
  is_override: number;
}

type EditTranslation = LanguageType & { value: any; original: any; updated: boolean; abbr: string; flag: string };

@Component({
  selector: 'app-editTranslation',
  templateUrl: './edit.directive.html',
})
export class EditTranslationDirective implements OnInit, OnChanges, OnDestroy {
  @Input() component: string;
  @Input() isTranslationV2 = false;
  @Input() key = 'default';
  @Input() transComp: any;
  @Input() transObj: TransObj;
  @Input() styleClass: string | string[];
  @Output() callback = new EventEmitter();
  description = '';
  value = '';
  id = 0;
  toggleAll = false;

  accountId: string | number;
  newTranslationText: string;
  currentLanguage: string;
  currentLanguageObj: Partial<EditTranslation>;

  isDev = false;

  languages: Partial<EditTranslation>[];
  langKey: Dictionary[] = [];

  showTranslateLocal = false;
  ngUnsubscribe = new Subject();

  constructor(
    private editService: EditDirectiveService,
    private notification: NotificationService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private translationServiceV2: TranslationsV2Service,
    public trans: EditTranslationTranslations,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnInit() {
    if (!!this.transComp) {
      this.isTranslationV2 = true;
    }
    this.isDev = this.commonService.isDev$;

    this.userService.getLanguages().subscribe((result) => {
      this.languages = result.result.map((l) => {
        l.abbr = this.translationServiceV2.getLanguageAbbr(l.id);
        l.lang = l.name === 'English US' ? 'English' : l.name;
        l.flag = this.translationServiceV2.getLanguageFlagIcon(l.id);
        return l;
      });
      this.setLanguages();

      const keyList: Dictionary[] = JSON.parse(sessionStorage.getItem('all_dictionaries'));
      this.langKey = keyList.filter((x) => x.k === this.key);

      const overridenList: any[] = JSON.parse(sessionStorage.getItem('labels'));

      for (let i = 1; i <= this.languages.length; i++) {
        let tmp = this.langKey.filter((x) => +x.language_type_id === i);
        if (!tmp[0]) {
          const out = this.translateService.instant(this.key);
          tmp[0] = { language_type_id: i.toString(), k: this.key, v: out };
        }

        this.languages[i - 1].original = tmp[0].v;
        this.languages[i - 1].value = tmp[0].v;

        tmp = overridenList.filter((x) => +x.language_type_id === i && x.k === this.key);
        if (tmp[0] && tmp[0].v) {
          this.languages[i - 1].value = tmp[0].v;
        }
      }
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslateLocal = res;
      this.cd.detectChanges();
    });
  }

  ngOnChanges() {
    if (this.languages) {
      this.setLanguages();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationServiceV2.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationServiceV2
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  translateAll() {
    for (let l = 0; l < this.languages.length; l++) {
      const currAbbr = this.languages[l].abbr;
      this.commonService.googleTranslate(this.languages[+this.currentLanguage - 1].value, currAbbr).subscribe((res) => {
        if (res) {
          const out = res.data.translations[0];
          this.languages[l].value = out.translatedText;
        }
      });
    }
  }

  funcSave() {
    let out = [];
    for (let i = 0; i < this.languages.length; i++) {
      const elem = this.languages[i];
      if (elem.updated === true) {
        out.push({ key: this.key, name: isEmpty(elem.value) ? ' ' : elem.value, language_type_id: elem.id });
      }
    }
    const payload = { labels: out };
    this.editService.postUpdateKey(payload).subscribe((res) => {
      const labels = res.result.labels;
      out = [];
      for (let i = 0; i < labels.length; i++) {
        out.push({ k: labels[i].key, v: labels[i].name, language_type_id: labels[i].language_type_id });
      }

      sessionStorage.setItem('labels', JSON.stringify(out));

      this.notification.success('Translation Updated Successfully', false);
      this.callback.emit();
    });
  }

  funcSaveV2() {
    const parsedTransText = isEmpty(this.newTranslationText) ? ' ' : this.newTranslationText;
    const payload = {
      component: this.component ?? this.transComp.config.component,
      key: this.transObj.key,
      lang: this.currentLanguageObj.abbr,
      value: parsedTransText,
      account_id: this.accountId,
      translation_id: this.transObj.translation_id,
    };
    if (this.transObj.is_override === 1) {
      this.translationServiceV2.updateOverrideTranslation(payload).subscribe((response) => {
        if (response.result.success) {
          if (this.transComp) {
            this.transObj.value = parsedTransText;
          }
          this.notification.success('Translation Updated Successfully', false);
          this.callback.emit();
        }
      });
    } else {
      this.translationServiceV2.overrideTranslation(payload).subscribe((response) => {
        if (response.result.success) {
          if (this.transComp) {
            this.transObj.value = parsedTransText;
          }
          this.notification.success('Translation Updated Successfully', false);
          this.callback.emit();
        }
      });
    }
  }

  setLanguages() {
    this.currentLanguage = sessionStorage.getItem('language_type_id');
    this.currentLanguageObj = this.languages.find((lang) => lang.id === this.currentLanguage);
    this.accountId = sessionStorage.getItem('aid');
    this.newTranslationText = this.transObj ? this.transObj.value : '';
  }
}
