import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class ReviewBaselineBenefitsTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'reviewBaselineBenefits',
    display_name: 'Review Baseline Benefits',
    description: 'Benefits tab of the review baseline screen',
  };

  public trans = this.typeObjectKeys({
    active: 'Active',
    na: 'N/A',
    soft: 'Considered Soft',
    search: 'Search',
    benefits: 'Benefits',
    non: 'Non',
    financial: 'Financial',
    setStatus: 'Set Status',
    benefit: 'Benefit',
    changeStatusSuccess: 'Status changed successfully',
    filter: 'Filter',
    noMatches: 'No benefit matches the current filter',
    all: 'All',
    addNew: 'Add new',
    backToBenefitsList: 'Back to benefits list',
    wantSaveChanges: 'Do you want to save your changes?',
    yes: 'Yes',
    no: 'No',
  });
}
