
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DefaultTranslations } from './roi.translation';
import { ValueProp } from '@shared/models/value-prop.model';
import { VPChart } from '../results/interfaces/chart-data.interface';


export class ReportData {
	year1_cols: any;
	year1_data: any;
	year1_chartdata: any;

	cost_cols: any;
	cost_data: any;
	cost_chartdata: any;
}

@Component({
	selector: 'app-value-prop-dashboard-roi',
	templateUrl: './roi.component.html'
})
export class ValuePropDashboardROIComponent implements OnInit, OnDestroy {
	@Input() showHelp: boolean;
	@Input() contextualHelp: any[];
	@Input() valueProp: ValueProp;
	@Input() year1BreakDownChartData: {[klass: string]: any};;
	@Input() costBenefitChartData: {[klass: string]: any};;
	@Input() dashboard: string;
	@Input() isDev: boolean;
	@Input() showTranslate = false;

	data = new ReportData;

	cols = 'col-lg-4 col-md-6 col-sm-12';

	chartVC: VPChart = null;
	chartVCLegend: string[] = [];
	benefitTotal: string;
	inaction_text: string;
	years: number;
	chartROI: any = null;
	getVCChartSubscription: Subscription;
	chartWithROISubscription: Subscription;
	ngUnsubscribe = new Subject();
	loading = false;

	feature62 = false;
	constructor(
		public trans: DefaultTranslations,
		private translationService: TranslationsV2Service,
		private commonService: CommonService,
		private RepSimpleService: RepSimpleViewService,
		private valuePropService: ValuepropService
	) {

	}
	ngOnInit(): void {
		this.feature62 = this.commonService.checkFeature('62');

		if (this.dashboard === 'v1') {
			this.cols = 'col-lg-6 col-md-6 col-sm-12';
		} else {
			this.cols = 'col-lg-4 col-md-6 col-sm-12';
		}

		this.valuePropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.funcGetVCChart();
			this.funcGetROIChart();
		});

		this.funcGetVCChart();
		this.funcGetROIChart();

		this.getTranslations();
		this.commonService.notifyChangeLanguage.subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
	getTranslations(): void {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
		  account_id: sessionStorage.getItem('aid'),
		  component: this.trans.config.component,
		  lang: langAbbr,
		  localTranslations: this.trans.trans
		};
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		})
	}

	funcGetVCChart(): void {
		this.loading = true;
		const chartColors = this.commonService.getChartColors();
		this.getVCChartSubscription = this.RepSimpleService.getVCChart(`${this.valueProp.id}`)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.loading = false)
      )
      .subscribe(res => {
        if (!res.result && !res.result.names && !res.result.names.length) {
          this.chartVCLegend = [];
        } else {
          const labels = [];
          for (let i = 0; i < res.result.names.length; i++) {

            const name = res.result.names[i].name;
            const vc = this.valueProp.benefits ? this.valueProp.benefits.find((benefit) => benefit.name === name) : null;
            labels.push({
              name,
              description: vc ? vc.description : null,
              color: chartColors[i],
              metrics: res.result.names[i].metrics,
              val: res.result.percents[i],
              fr: res.result.total_benefits_fr[i]
            });
          }
          this.chartVC = {
            data: {
              datasets: [
                {
                  label: 'Benefits',
                  data: res.result.percents,
                  backgroundColor: chartColors
                }
              ],
              labels: labels
            },
            d0: [res.result.total_benefits_fmt]
          };

          this.benefitTotal = res.result.total_annual_benefits;

          this.chartVCLegend = res.result.names.length ? labels : [];
          this.inaction_text = res.result.formula;
        }
      });
  }

	funcGetROIChart(): void {
		const chartColors = this.commonService.getChartColors();
		this.chartWithROISubscription = this.RepSimpleService.chartWithROI(`${this.valueProp.id}`).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.years = res.result.years.length;
        this.chartROI = {
          data: {
            datasets: [
              {
                label: 'Benefits',
                data: res.result.benefits,
                yAxisID: 'A',
                position: 'left',
                backgroundColor: chartColors[0],
                order: 3
              },
              {
                label: 'Costs',
                data: res.result.costs,
                yAxisID: 'A',
                position: 'left',
                backgroundColor: chartColors[1],
                order: 2
              }
            ],
            labels: res.result.years
          },
          d0: res.result.benefits_fmt,
          d1: res.result.costs_fmt,
          d2: res.result.roi_fmt
        };

        if (!this.feature62) {
          this.chartROI.data.datasets.push({
            label: 'ROI',
            data: res.result.roi,
            type: 'line',
            yAxisID: 'B',
            position: 'right',
            borderColor: chartColors[2],
            fill: false,
            order: 1
          });
        }
      });
  }
}

