import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './container.translation';

@Component({
  selector: 'app-value-map-maintenance',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ValueMapContainerComponent implements OnInit, OnDestroy {
  @Input() toggleMaintenance = false;
  @Input() canEdit = false;
  @Input() solutionId: string | number;

  items: MenuItem[] = [];
  view = 'situations';
  showTranslate = false;
  ngUnsubscribe$ = new Subject();

  constructor(
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service
  ) { }

  ngOnInit(): void {
    this.funcDrawMenu();
    this.getTranslations();
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcDrawMenu(): void {
    this.items = [
      {
        label: 'Situations',
        styleClass: this.view === 'situations' ? 'menuSelected' : '',
        command: () => {
          this.view = 'situations';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Pain Points',
        styleClass: this.view === 'pain_points' ? 'menuSelected' : '',
        command: () => {
          this.view = 'pain_points';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Capabilities',
        styleClass: this.view === 'capabilities' ? 'menuSelected' : '',
        command: () => {
          this.view = 'capabilities';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Operational Objectives',
        styleClass: this.view === 'operational_objectives' ? 'menuSelected' : '',
        command: () => {
          this.view = 'operational_objectives';
          this.funcDrawMenu();
        },
      },
      {
        label: 'Functional Objectives',
        styleClass: this.view === 'functional_objectives' ? 'menuSelected' : '',
        command: () => {
          this.view = 'functional_objectives';
          this.funcDrawMenu();
        },
      },
    ];
  }
}
