<div class="sidebar_wrapper">
  <div class="sidebar_header">
    <div *ngIf="mode == 'add'" class="title flyout-title">
      {{ trans.trans.addAsset.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.addAsset"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <div *ngIf="mode == 'edit'" class="title flyout-title">
      {{ trans.trans.editAsset.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.editAsset"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
  </div>
  <div class="sidebar_container">
    <div class="keywest_panel">
      <div class="row">
        <div class="col-xs-12">
          <p-steps [(activeIndex)]="activeIndex" styleClass="keywest_asset_steps" [model]="steps"></p-steps>
        </div>
      </div>


      <div class="row" *ngIf="loadingLoadEditDetails">
        <div class="col-xs-12">
          {{ trans.trans.loadingDetails.value }}<br />
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </div>


      <div *ngIf="!loadingLoadEditDetails">
        <div [ngStyle]="{'display': activeIndex == 0 ? 'block' : 'none'}">
          <app-asset-basics
            [selectedAsset]="selectedAsset"
            [mode]="mode"
            (changes)="emitFlagShowWarning()"
          ></app-asset-basics>
        </div>

        <div [ngStyle]="{'display': activeIndex == 1 ? 'block' : 'none'}">
          <app-asset-templates
            [selectedAsset]="selectedAsset"
            [templates]="templates"
            [solutions]="solutions"
            (changes)="emitFlagShowWarning()"
            (deleteTemplate)="deleteTemplate($any($event))"
            (saveInPlace)="saveAssetTemplate(false)"
          ></app-asset-templates>
        </div>

        <div [ngStyle]="{'display': activeIndex == 2 ? 'block' : 'none'}">
          <app-asset-settings
            [accountAssetId]="accountAssetId"
            [selectedElem]="selectedElem"
            (changes)="emitFlagShowWarning()"
            (reload)="callback.emit()"
            (save)="saveAssetElements($event)"
          ></app-asset-settings>
        </div>
      </div>

    </div>
    <div class="btn_container">
      <div class="row" style="padding-top: 10px;">
        <div class="col-xs-12">
          <div *ngIf="loadingSave">
            <p-progressBar mode="indeterminate"></p-progressBar>
          </div>
          <button type="submit" *ngIf="activeIndex !== 0 && !loadingSave" class="btn btn_customizable" (click)="goBack()">Back</button>
          <button type="submit" [disabled]="!selectedAsset.asset_type_id" *ngIf="activeIndex == 0 && mode == 'add'" class="btn btn_customizable pull-right mr-50" (click)="saveAccountAssets()">
            {{ trans.trans.next.value }}
          </button>
          <button type="submit" *ngIf="activeIndex == 0 && mode == 'edit'" class="btn btn_customizable pull-right mr-50" (click)="updateAccountAssets()">
            {{ trans.trans.next.value }}
          </button>
          <button [disabled]="templates.length < 1 || loadingSave" type="submit" *ngIf="activeIndex == 1 && !loadingSave" class="btn btn_customizable pull-right mr-50" (click)="saveAssetTemplate()">
            {{ trans.trans.next.value }}
          </button>
          <button [disabled]="loadingSave" type="submit" *ngIf="activeIndex == 2 && !loadingSave" class="btn btn_customizable pull-right mr-50" (click)="populateSaveSettings()">
            {{ trans.trans.save.value }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
