import { Injectable } from '@angular/core';

@Injectable()
export class SelectLanguageTranslations {

  public config: any = {
    component: 'select-language',
    display_name: 'Select Language component',
    description: ''
  };

  public trans: any = {
    selectedLanguage: 'Selected Language:',
  };
}
