import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddBenefitVpDashboardTranslations } from './add-benefits.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { DashboardSimpleImplementation } from '../dashboard_simple/simple.implementation';
import { Driver, Impact, Metric, MetricCapability, Phase, Scale, Solution, ValueProp } from '@shared/models/value-prop.model';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';
import { StyleService } from 'app/style.service';
import { UniqueType } from '@shared/models/account.models';
import { ValueCategory } from '@data/services/valueposition/models/value-category.interface';
import { Dropdown, SoftDropdown } from '@shared/models/dropdown.models';
import { BasicInfo } from '@shared/models/basic-info.model';
import { BenefitType } from '@shared/models/benefits.models';

@Component({
	selector: 'app-add-benefits-component',
	templateUrl: './add-benefits-component.component.html',
	styleUrls: ['./add-benefits-component.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AddBenefitsComponentComponent implements OnInit, OnDestroy {
	@Input() valueProp: ValueProp;
	@Input() valuePropId: string;
	@Input() showHelp: boolean;
	@Input() contextualHelp: boolean;
	@Input() selectedBenefit: Metric;
	@Input() showTranslate = false;
	@Input() mini = false;
	@Input() surveyFields: number[];
    @Input() categoryId: number | string;

	@Output() reloadVP = new EventEmitter();
	@Output() saveBenefitCallback = new EventEmitter();
	@Output() cancelBenefitCallback = new EventEmitter();

	unitTypeList: Array<ExpenseType> = [];
	unitType: string;
	solutionType: string;
	impactAreas: Array<Impact> = [];
	annualBenefitsList: Array<BasicInfo> = [];
	annualBenefits = "2";
	financialDriverFactorList: Array<Driver> = [];
	improvementTypeList: Array<BasicInfo> = [];
	financialDriverFactor: Driver;
	financialDriverFactorId: string;
	selectedImprovementType: BasicInfo[] = [];
	impactList: Array<Impact> = [];
	impact: string;
	scaleList: Array<Scale> = [];
	scale: string;
	driverFactorTypeList: Array<string> = [];
	driverFactorType: string;
	driverFactorList: Array<Driver> = [];
	driverFactor: Array<Driver> = [];
	driverFactorId: string;
	uniqueList: Array<UniqueType> = [];
	uniqueTypes: string;
	valueCategoryBenefits: ValueCategory | string;
	valueCategoryBenefitsList: ValueCategory[] = [];
	impactAreasList: Array<Impact> = [];
	noAreaSelect = true;
	impactAreaIdList: Array<string> = [];
	solutionsList: Array<Solution> = [];
	benefit: Phase = {
		"phase_0": 0,
		"phase_1": 0,
		"phase_2": 0,
		"phase_3": 0,
		"phase_4": 0,
	}
	currentEffort: string | number;
	benefitsName: string;
	benefitsDescription: string;
	searchText: string;
	driverBaselineAnswer: string;
	conservative: string;
	aggressive: string;
	financicalAnswer: string;
	probable: string | number;
	modalReference: NgbModalRef;
	solutionId: number;
	account_solution_id: string;
	newDriverFactor: Driver;
	FinancialFactor: string;
	newImprovementFactor: string;
	fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
	benefitsFeatures: string;
	dropdownSettings: Dropdown;
	dropdownSettings1: Dropdown;
	improvementTypeSettings: Dropdown;
	hardSoftDropdown: SoftDropdown[];

	subscriptionreadSolutionList: Subscription;
	subscriptiongetUnitTypes: Subscription;
	subscriptiongetAccrualTypes: Subscription;
	subscriptiongetFinancialTypes: Subscription;
	subscriptiongetScratchpadImprovementTypes: Subscription;
	subscriptiongetImpactTypes: Subscription;
	subscriptiongetScaleTypes: Subscription;
	subscriptiongetDriverFactorTypes: Subscription;
	subscriptiongetDriverFactor: Subscription;
	subscriptiongetUniqueTypes: Subscription;
	subscriptiongetValueCategories: Subscription;
	subscriptionsaveValuePropsBenefits: Subscription;
	subscriptionupdateValuePropsBenefits: Subscription;
	subscriptioncreateDriverFactor: Subscription;
	subscriptioncreateFinancialFactor: Subscription;
	subscriptioncreateImprovementFactor: Subscription;
	subscriptiongetAreaTypes: Subscription;

	expense_types: ExpenseType[] = [];
	benefitTypeList: BenefitType[] = [];
	soft: number | boolean;
	quantified: boolean;
	benefitType: string | number;
	tcoName: string;
	account_id: string;
	expense_type_id: string;

	painPoints: MetricCapability[];
	capabilities: MetricCapability[];
	functionalObjectives: MetricCapability[];
	operationalObjectives: MetricCapability[];

	functionalObjectiveId: string;

	painPointsSelected: {[klass: string]: any}[];
	capabilitiesSelected: { [klass: string]: any }[];
	operationalObjectivesSelected: {[klass: string]: any}[];

	showActionLoader = false;
	showImprovementActionLoader = false;
	createDriverFlag = false;
	createImprovementFlag = false;
	createFactor = false;
	accountFeatures = false;
	showeditLoader = false;
	showCurrentEffort = false;
	showSaveLoader = false;
	painPointToggle = false;
	capabilitiesToggle = false;
	opObjectivesToggle = false;
	hasFeature46 = false;
	loadingVC = false;

	ngUnsubscribe: Subject<any> = new Subject();

	style2022$: Observable<boolean>;

	constructor(
		private solutionService: SolutionService,
		private valuepropService: ValuepropService,
		public activeModal: NgbActiveModal,
		private notificationService: NotificationService,
		private factsService: FactsService,
		private translationService: TranslationsV2Service,
		public trans: AddBenefitVpDashboardTranslations,
		private commonService: CommonService,
		private sI: DashboardSimpleImplementation,
		private styleService: StyleService
	) {

	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		if (!this.valuePropId && !this.valueProp) {
			this.valuePropId = this.sI.valuePropId;
		}
		// if (!this.valueProp) { this.valueProp['id'] = this.valuePropId;}

		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.account_id = sessionStorage.getItem("aid");


		this.readUnitTypes();
		this.readAreaTypes();
		this.readAccrualTypes();
		this.readFinancialTypes(null);
		this.readImprovementTypes();
		this.readImpactTypes();
		this.readScaleTypes();
		this.readDriverFactorTypes();
		//this.readDriverFactor();
		this.readUniqueTypes();
		this.readSolutionList();
		this.getExpenseTypes();
		this.readBenefitTypes();
		// this.readPainPoints();
		// this.readCapabilities();
		// this.readOerationalObjectives();
		this.readFunctionalObjectives(null);

		this.dropdownSettings = {
			singleSelection: true,
			text: "Select DriverFactors",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			badgeShowLimit: "all",
			noDataLabel: "No Data Found...",
			searchBy: ['name'],
			labelKey: 'name',
			primaryKey: 'id',
			lazyLoading: false
		};

		this.dropdownSettings1 = {
			singleSelection: true,
			text: "Select Financial Factors",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			badgeShowLimit: "all",
			noDataLabel: "No Data Found...",
			searchBy: ['name'],
			labelKey: 'name',
			primaryKey: '',
			lazyLoading: false,
		};

		this.improvementTypeSettings = {
			singleSelection: true,
			text: "Select Improvement Type",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			badgeShowLimit: "all",
			noDataLabel: "No Data Found...",
			searchBy: ['name'],
			labelKey: 'name',
			primaryKey: '',
			lazyLoading: false,
		};


		this.hardSoftDropdown = [
			{ value: 0, label: 'Hard' },
			{ value: 1, label: 'Soft' },
			{ value: 2, label: 'Strategic' }
		];

		this.accountFeatures = this.checkFeatures();
		this.quantified = (this.selectedBenefit && this.selectedBenefit.quantified !== 1 ? false : true);
		this.soft = (this.selectedBenefit && this.selectedBenefit.soft == 1 ? true : false);
		this.benefitType = (this.selectedBenefit && this.selectedBenefit.benefit_type_id ? this.selectedBenefit.benefit_type_id : 1);
		if (this.selectedBenefit) {
			this.painPointsSelected = this.mapAccountIdToId(this.selectedBenefit.pain_points, 'account_painpoint_id');
			this.capabilitiesSelected = this.mapAccountIdToId(this.selectedBenefit.capabilities, 'account_capability_id');
			this.operationalObjectivesSelected = this.mapAccountIdToId(this.selectedBenefit.operational_objectives, 'account_operational_objective_id');
			this.functionalObjectiveId = this.selectedBenefit.functional_objectives.length > 0 ? this.selectedBenefit.functional_objectives[0].account_functional_objective_id : undefined;

			if (this.hasFeature46) {
				this.soft = this.hardSoftDropdown.find((val) => val.value == this.selectedBenefit.soft).value;
			}
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {
		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

	getExpenseTypes() {
		// /fact/expenseTypes
		this.solutionService.getExpenseTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.expense_types = response.result;
			}
		});
	}

	readBenefitTypes() {
		// GET account/benefitTypes/{account_id}
		this.solutionService.getBenefitTypes(this.account_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.benefitTypeList = response.result;
			}
		});
	}

	funcCancelBenefit() {
		this.cancelBenefitCallback.emit();
	}

	refreshTranslation() {
		this.getTranslations();
	}

	checkFeatures() {
		let features = sessionStorage.getItem('features');
		let options = features.split(',');
		if (options.indexOf('46')) {
			this.hasFeature46 = true;
		}
		if (options.indexOf('14') >= 0) {
			return true;
		}
		return false;
	}

	checkCurrentEffort() {
		const utype = parseInt(this.unitType);

		if ((utype == 8) || (utype == 9) || (utype == 10) || (utype == 11) || (utype == 12) || (utype == 13)) {
			this.showCurrentEffort = true;
		} else {
			this.showCurrentEffort = false;
		}
	}

	readSolutionList() {
		let id = this.valueProp ? this.valueProp.id : this.valuePropId;

		this.valuepropService.readSolutionList(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.solutionsList = response.result;
			}
			if (!this.solutionType) {
				this.solutionType = this.solutionsList[0]['account_solution_id'];
				this.account_solution_id = this.solutionsList[0]['account_solution_id'];
				this.readValueCategories(this.account_solution_id);
			}
		});
	}

	readUnitTypes() {
		this.solutionService.getUnitTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.unitTypeList = response.result;
			}
			if (!this.unitType) {
				this.unitType = this.unitTypeList[0]['id'];
			}
		});
	}

	readAreaTypes() {
		this.solutionService.getAreaTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.impactAreas = response.result;
			}

			if (this.selectedBenefit && this.selectedBenefit['areas'] && this.selectedBenefit['areas'].length > 0) {
				let areaIds = this.selectedBenefit['areas'].map(item => {
					return item['area_type_id'];
				});
				this.impactAreaIdList = areaIds;

				this.impactAreas = this.impactAreas.map(item => {
					if (this.impactAreaIdList.indexOf(item.id) != -1) {
						this.impactAreasList.push(item);
						item['checked'] = true;
					}
					return item;
				});

				this.noAreaSelect = false;
			}
		});
	}

	readAccrualTypes() {
		this.solutionService.getAccrualTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.annualBenefitsList = response.result;

			}
			if (!this.annualBenefits) {
				this.annualBenefits = this.annualBenefitsList[1]['id'];
			}
		});
	}

	readFinancialTypes(params: string) {
		this.solutionService.getFinancialTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.financialDriverFactorList = response.result;
				if (params != null) {
					this.financialDriverFactor = this.financialDriverFactorList.find((item: Driver) => {
						return item.id == params
					});
				}
			}
			if (!this.financialDriverFactor) {
				this.financialDriverFactorId = this.financialDriverFactorList[0]['id'];
			}
		});
	}

	readImprovementTypes(params?) {
		this.factsService.getScratchpadImprovementTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.improvementTypeList = response.result;
				this.selectedImprovementType.push(this.improvementTypeList[0]);
				if (params) {
					this.selectedImprovementType = this.improvementTypeList.filter(item => {
						return item['id'] == params;
					});
				}
			}
			if (!this.financialDriverFactor) {
				this.selectedImprovementType = [this.improvementTypeList[0]];
			}
		});
	};

	readImpactTypes() {
		this.solutionService.getImpactTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.impactList = response.result;
			}
			if (!this.impact) {
				this.impact = this.impactList[0]['id'];
			}
		});
	}

	readScaleTypes() {
		this.solutionService.getScaleTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.scaleList = response.result;
			}
			if (!this.scale) {
				this.scale = this.scaleList[0]['id'];
			}
		});
	}

	readDriverFactorTypes() {
		this.solutionService.getDriverFactorTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.driverFactorTypeList = response.result;
			}
			if (!this.driverFactorType) {
				this.driverFactorType = this.driverFactorTypeList[0]['id'];
				this.readDriverFactor(null);
			}
		});
	}

	readDriverFactor(params) {
		this.showeditLoader = true;
		let factorType = 'all';
		let aid = sessionStorage.getItem('aid');
		this.solutionService.getDriverFactor(factorType, aid).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.driverFactorList = response.result;

				if (params != null) {
					this.driverFactor = this.driverFactorList.filter(item => {
						return item['id'] == params
					});
				}

				if (this.selectedBenefit) {
					this.editBenefit();
				}
			}
			if (!this.driverFactor) {
				this.driverFactorId = this.driverFactorList[0]['id'];
			}

			this.showeditLoader = false;
		});
	}

	readUniqueTypes() {
		this.solutionService.getUniqueTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.uniqueList = response.result;
			}
			if (!this.uniqueTypes) {
				this.uniqueTypes = this.uniqueList[0]['id'];
			}
		});
	}

	readValueCategories(params) {
		this.loadingVC = true;
		let account_solution_id = params ? params : null;
		let id = this.valueProp ? this.valueProp.id : this.valuePropId;
		this.solutionService.getValueCategoriesOneMetric(id, account_solution_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.valueCategoryBenefitsList = response.result.value_categories;
			}

			if (this.valueCategoryBenefitsList && this.valueCategoryBenefitsList.length && this.selectedBenefit && this.selectedBenefit.value_category_id) {
				this.valueCategoryBenefits = this.valueCategoryBenefitsList.find(x => x.value_category_id == this.selectedBenefit.value_category_id);
			}
            if (this.categoryId) {
				this.valueCategoryBenefits = this.valueCategoryBenefitsList.find(x => parseInt(x.value_category_id) == this.categoryId);
			}
			// if (!this.valueCategoryBenefits && this.valueCategoryBenefitsList) {
			// 	this.valueCategoryBenefits = this.valueCategoryBenefitsList[0]['value_category_id'];
			// }
			this.loadingVC = false;
		});
	}

	// readPainPoints() {
	// 	this.solutionService.getPainPoints(this.account_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
	// 		this.painPoints = response.result;
	// 	});
	// }

	// readCapabilities() {
	// 	this.solutionService.getCapabilities(this.account_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
	// 		this.capabilities = response.result;
	// 	});
	// }

	// readOerationalObjectives() {
	// 	this.solutionService.getOperationalObjectives(this.account_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
	// 		this.operationalObjectives = response.result;
	// 	});
	// }

	readFunctionalObjectives(params?) {
		let id = this.valueProp ? this.valueProp.id : this.valuePropId;
		let account_solution_id = params ? params : null;
		this.solutionService.getFunctionalObjectivesOneMetric(id, account_solution_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			console.table(response.result.functional_objectives);
			this.painPoints = response.result.painpoints;
			this.capabilities = response.result.capabilities;
			this.operationalObjectives = response.result.operational_objectives;
			this.functionalObjectives = response.result.functional_objectives;
			if (this.selectedBenefit && this.selectedBenefit.functional_objectives && this.selectedBenefit.functional_objectives.length > 0) {
				this.functionalObjectiveId = this.functionalObjectives.filter(x => x.id == this.selectedBenefit.functional_objectives[0].account_functional_objective_id)[0].account_functional_objective_id;
			}
		});
	}

	saveBenefits(form: NgForm) {

		this.showSaveLoader = true;
		// return;
		let benfitsObj = {
			"account_solution_id": parseInt(form.value.solutionType),
			"value_category_id": parseInt(form.value.valueCategoryBenefits.value_category_id),
			"impact_type_id": parseInt(form.value.impact),
			"metric_name": form.value.benefitsName,
			"current_effort": form.value.currentEffort,
			// "scale_type_id": 1,
			"unique_type_id": parseInt(form.value.uniqueTypes),
			"accrual_type_id": parseInt(form.value.annualBenefits),
			"description": form.value.description,
			"features": form.value.features,
			"area_type_id": this.impactAreaIdList.join(","),
			"driver_factor_id": parseInt(form.value.driverFactor.id),
			"driver_baseline_answer": form.value.driverBaselineAnswer,
			"conservative": "",
			"probable": form.value.probable,
			"aggressive": "",
			"unit_type_id": parseInt(form.value.unitType),
			"financial_factor_id": parseInt(form.value.financialDriverFactor.id),
			"financial_baseline_answer": form.value.financicalAnswer,
			"value_prop_id": this.valueProp.id,
			tco_name: form.value.tcoName,
			"expense_type_id": form.value.expense_type_id,


			"account_functional_objective_id": form.controls.functionalObjectiveId && form.controls.functionalObjectiveId.value ? form.controls.functionalObjectiveId.value.id : "",
			"account_painpoint_id": this.funcParseList(this.painPointsSelected, "id"),
			"account_capability_id": this.funcParseList(this.capabilitiesSelected, "id"),
			"account_operational_objective_id": this.funcParseList(this.operationalObjectivesSelected, "id"),
		}
		if (this.surveyFields) {
			benfitsObj['value_survey_id'] = this.surveyFields;
			benfitsObj['value_survey_benefit'] = 1;
		}

		if (this.hasFeature46) {
			benfitsObj["soft"] = form.value.soft.value;
		} else {
			benfitsObj['benefit_type_id'] = form.value.benefitType;
			benfitsObj["soft"] = (form.value.soft == true ? 1 : 0);
			benfitsObj["quantified"] = (form.value.quantified == true ? 1 : 0);
		}

		if(this.surveyFields) {
			benfitsObj['value_survey_id'] = this.surveyFields;
			benfitsObj['value_survey_benefit'] = 1;
		}

		if (!this.selectedBenefit) {
			this.saveBenefit(benfitsObj);
		} else {
			benfitsObj['account_solution_metric_id'] = this.selectedBenefit['account_solution_metric_id'];
			benfitsObj['value_prop_metric_id'] = this.selectedBenefit['id'];
			benfitsObj['vp_specific'] = 1;
			this.updateBenefit(benfitsObj);
		}
	}

	saveBenefit(benefitsObj) {
		this.valuepropService.saveValuePropsBenefits(this.valueProp.id, benefitsObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result && (response.result.success !== false)) {
				this.showSaveLoader = false;
				this.saveBenefitCallback.emit();
				if (!this.mini) {
					this.valuepropService.gotoMenuItem.next({ menu: "benefits", panel: "vpdashboardMenuBottom" });

					this.valuepropService.refreshDashboard.next('no_reload');
					this.valuepropService.refreshBenefitDetail.next();

					this.notificationService.success("Benefit Added successfully", false); // Params {message, islogout}
				}
				this.valuepropService.gotoMenuItem.next({ menu: "benefits", panel: "vpdashboardMenuBottom" });

				this.reloadVP.emit();
			}
			else if (response.result.success === false) {
				this.showSaveLoader = false;
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.showSaveLoader = false;
		});
	}

	updateBenefit(benfitsObj) {
		this.valuepropService.updateValuePropsBenefits(this.valueProp.id, benfitsObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.showSaveLoader = false;
				if (!this.mini) {
					this.saveBenefitCallback.emit();
					this.valuepropService.refreshDashboard.next('no_reload');
					this.valuepropService.refreshBenefitDetail.next();
				}
				// this.ValuepropService.refreshDashboard.next('ReturnToBenefits');
				this.notificationService.success("Benefit Updated successfully", false); // Params {message, islogout}
				this.reloadVP.emit();
			}
			else if (response.result.success === false) {
				this.showSaveLoader = false;
				this.notificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.showSaveLoader = false;
		});
	}

	checkArea(content, event) {
		$('div.dropdown.impactList').addClass('open');
		if (event.target.checked == true) {
			if (this.impactAreaIdList.indexOf(content.id) == -1) {
				this.impactAreasList.push(content);
				this.impactAreaIdList.push(content.id);
			}
		}
		else if (event.target.checked == false) {
			this.impactAreasList = this.impactAreasList.filter((impact: any) => {
				return impact.id !== content.id;
			});
			this.impactAreaIdList = this.impactAreaIdList.filter((impact: any) => {
				return impact !== content.id;
			});
		}
		if (this.impactAreasList.length > 0) {
			this.noAreaSelect = false;
		}
		else if (this.impactAreasList.length == 0) {
			this.noAreaSelect = true;
		}
	}

	impactAreaClose(content) {
		this.impactAreasList = this.impactAreasList.filter((impact: any) => {
			return impact.id !== content.id;
		});

		//$('.custom-checkbox input').prop('checked', false);

		if (this.impactAreasList.length == 0) {
			$('.custom-checkbox input').prop('checked', false);
			this.noAreaSelect = true;
		}
		else if (this.impactAreasList.length > 0) {
			this.noAreaSelect = false;
		}

		this.impactAreaIdList = this.impactAreaIdList.filter((impact: any) => {
			return impact !== content.id;
		});
	}

	changePhase(value, element) {
		this.benefit[value] = element.from;
	}

	editBenefit() {
		this.benefitsName = (this.selectedBenefit['alt_metric_name'] ? this.selectedBenefit['alt_metric_name'] : this.selectedBenefit['metric_name']);
		this.benefitsDescription = this.selectedBenefit['description'];
		this.annualBenefits = this.selectedBenefit['accrual_type_id'];
		this.impact = this.selectedBenefit['impact_type_id'];
		this.valueCategoryBenefits = this.selectedBenefit['value_category_id'];
		this.scale = this.selectedBenefit['scale_type_id'];
		this.currentEffort = this.selectedBenefit['current_effort'];
		this.uniqueTypes = this.selectedBenefit['unique_type_id'];

		this.tcoName = this.selectedBenefit.tco_name;
		this.expense_type_id = this.selectedBenefit['expense_type_id'];
		if (this.selectedBenefit.quantified == "1") { this.selectedBenefit.quantified = true; } else { this.selectedBenefit.quantified = false; }
		if (this.selectedBenefit.soft == "1") { this.selectedBenefit.soft = true; } else { this.selectedBenefit.soft = false; }

		this.driverFactorId = this.driverFactorList.find(item => {
			return item['id'] == this.selectedBenefit['driver_factor_id'];
		}).id;

		this.driverBaselineAnswer = this.selectedBenefit['driver_baseline_answer'];
		this.unitType = this.selectedBenefit['unit_type_id'];
		this.conservative = this.selectedBenefit['conservative'];
		this.probable = this.selectedBenefit['impact'];
		this.aggressive = this.selectedBenefit['aggressive'];

		this.financialDriverFactor = this.financialDriverFactorList.find(item => {
			return item['id'] == this.selectedBenefit['financial_factor_id'];
		});
		this.selectedImprovementType = this.improvementTypeList.filter(item => {
			return item['id'] == this.selectedBenefit.improvement_factor_id;
		});

		this.financicalAnswer = this.selectedBenefit['financial_baseline_answer'];
		this.benefitsFeatures = this.selectedBenefit['features'];
		this.solutionType = this.selectedBenefit['account_solution_id'];

		this.readValueCategories(this.solutionType);
		if (this.unitType) {
			this.checkCurrentEffort();
		}
	}

	createDriverFactor() {
		this.showActionLoader = true;
		let driverFactorName = {
			"name": this.newDriverFactor.name,
			"account_id": sessionStorage.getItem('aid')
		};
		if (driverFactorName) {
			this.factsService.createDriverFactor(driverFactorName).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result && response.result.success) {
					this.readDriverFactor(response.result.driverFactor['id']);
					this.notificationService.success('Driver Factor created successfully', false); // Params {message, islogout}
				}
				else if (response.result && !response.result.success) {
					this.notificationService.error(response.result.message, false); // Params {message, islogout}
				}
				this.newDriverFactor = null;

				//this.driverFactor = response.result.driverFactor['id'];
				this.showActionLoader = false;
				this.createFactor = false;
			});
		}
	}

	createFinancialFactor() {
		this.showActionLoader = true;
		let driverFactorName = { "name": this.FinancialFactor };
		if (driverFactorName) {
			this.factsService.createFinancialFactor(driverFactorName).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result && response.result.success) {
					this.readFinancialTypes(response.result.financialFactor['id']);
					this.notificationService.success('Financial Factor created successfully', false); // Params {message, islogout}
				}
				else if (response.result && !response.result.success) {
					this.notificationService.error(response.result.message, false); // Params {message, islogout}
				}

				//this.financialDriverFactor = response.result.financialFactor['id'];
				this.FinancialFactor = "";
				this.createDriverFlag = false;
				this.showActionLoader = false;
			});
		}
	};

	createImprovementFactor() {
		this.showImprovementActionLoader = true;
		let improvementFactorName = { "name": this.newImprovementFactor };
		if (improvementFactorName) {
			this.factsService.createImprovementFactor(improvementFactorName).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				if (response.result && response.result.success) {
					this.readImprovementTypes(response.result.improvementFactor['id']);
					this.notificationService.success('Improvement Factor created successfully', false); // Params {message, islogout}
				}
				else if (response.result && !response.result.success) {
					this.notificationService.error(response.result.message, false); // Params {message, islogout}
				}
				//this.financialDriverFactor = response.result.financialFactor['id'];
				this.newImprovementFactor = "";
				this.createImprovementFlag = false;
				this.showImprovementActionLoader = false;
			});
		}
	};

	funcParseList(val, field): string {
		let arr = [];
		if (val) {
			for (let i = 0; i < val.length; i++) {
				arr.push(val[i][field]);
			}
			return arr.join(',');
		} else {
			return "";
		}

	}

	mapAccountIdToId(array: MetricCapability[], field) {
		if (!array) {
			return [];
		}
		return array.map((item) => ({
			...item,
			id: item[field]
		}));
	}
}
