<div *ngIf="valueProp">
  <div class="col-md-12" style="margin-bottom: 15px">
    <div class="grid-container-horizontal">
      <div class="panel panel-results" [ngClass]="view === 'case' ? 'panel-shadow' : 'no-shadow'">
        <div class="panel-body clickable" (click)="funcChangeView('case')" style="margin-bottom: 10px">
          <div class="nav-text clickable" (click)="funcChangeView('case')">
            {{ trans.trans.business_case.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.business_case"
            ></app-editTranslation>
          </div>
          <div class="nav-title">{{ trans.trans.overview.value }}</div>
        </div>
      </div>
      <div class="panel panel-results" *ngIf="!feature62 && !noCostSuperSimpleRep" [ngClass]="view == 'roi' ? 'panel-shadow' : 'panel-results'">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': rAS.colorROI }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('roi')">
          <div class="nav-text clickable" (click)="funcChangeView('roi')">
            {{ rAS.years }} {{ trans.trans.year_return_on_investment.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.year_return_on_investment"
            ></app-editTranslation>
          </div>
          <h2 class="nav-title clickable" (click)="funcChangeView('roi')" [ngStyle]="view == 'roi' && { color: rAS.headerColor }">
            {{ valueProp.roi_decorated }}
          </h2>
        </div>
      </div>

      <div *ngIf="showTCO && !noCostSuperSimpleRep" class="panel panel-results" [ngClass]="view == 'tco' ? 'panel-shadow' : 'panel-results'">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': rAS.colorTCO }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('tco')">
          <div class="nav-text clickable" (click)="funcChangeView('tco')">
            {{ rAS.years }} {{ trans.trans.year_total_cost_of_ownership.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.year_total_cost_of_ownership"
            ></app-editTranslation>
          </div>
          <h2 class="nav-title clickable" (click)="funcChangeView('tco')" [ngStyle]="view == 'tco' && { color: rAS.headerColor }">
            {{ valueProp.tco_fmt }}
          </h2>
        </div>
      </div>

      <div *ngIf="!hidePaybackFeature && !noCostSuperSimpleRep" class="panel panel-results" [ngClass]="{ 'panel-shadow': view == 'payback' }">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': rAS.colorPBP }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('payback')">
          <div class="nav-text clickable" (click)="funcChangeView('payback')">
            {{ trans.trans.payback_period_in_months.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.payback_period_in_months"
            ></app-editTranslation>
          </div>
          <h2 class="nav-title clickable" (click)="funcChangeView('payback')" [ngStyle]="view === 'payback' && { color: rAS.headerColor }">
            {{ valueProp.payback_fmt }}
          </h2>
        </div>
      </div>

      <div *ngIf="!hideNPV && !noCostSuperSimpleRep" class="panel panel-results" [ngClass]="{ 'panel-shadow': view == 'npv' }">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': rAS.colorNPV }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('npv')">
          <div class="nav-text clickable" (click)="funcChangeView('npv')">
            {{ rAS.years }}{{ trans.trans.year_net_present_value.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.year_net_present_value"
            ></app-editTranslation>
          </div>
          <h2 class="nav-title clickable" (click)="funcChangeView('npv')" [ngStyle]="view == 'npv' && { color: rAS.headerColor }">
            {{ valueProp.npv_fmt }}
          </h2>
        </div>
      </div>

      <div class="panel panel-results" [ngClass]="{ 'panel-shadow': view == '3mo' }">
        <span class="color-decoration" [ngStyle]="{ 'border-bottom': rAS.colorVC }"></span>
        <div class="panel-body clickable" (click)="funcChangeView('3mo')">
          <div class="nav-text clickable" (click)="funcChangeView('3mo')">
            {{ trans.trans.three_month_cost_of_delay.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.three_month_cost_of_delay"
            ></app-editTranslation>
          </div>
          <h2 class="nav-title clickable" (click)="funcChangeView('3mo')" [ngStyle]="view === '3mo' && { color: rAS.headerColor }">
            {{ valueProp.three_month_cost_of_inaction_fmt }}
          </h2>
        </div>
      </div>

      <div *ngIf="!hideCashFlowFF" class="panel panel-results" [ngClass]="{ 'panel-shadow': view == 'cashFlow' }">
        <div class="panel-body clickable" (click)="funcChangeView('cashFlow')">
          <div class="nav-text clickable" (click)="funcChangeView('cashFlow')">
            {{ trans.trans.netCashFlow.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="refreshTranslation()"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.three_month_cost_of_delay"
            >
            </app-editTranslation>
          </div>
          <h2 class="nav-title clickable" (click)="funcChangeView('cashFlow')" [ngStyle]="view == 'cashFlow' && { color: rAS.headerColor }">
            {{ valueProp?.net_cashflow_fmt }}
          </h2>
        </div>
      </div>

      <div *ngIf="feature59" class="panel panel-results" [ngClass]="{ 'panel-shadow': view == 'benpertrans' }">
        <div class="panel-body clickable" (click)="funcChangeView('benpertrans')">
          <h2 class="nav-title clickable" (click)="funcChangeView('benpertrans')" [ngStyle]="view === '3mo' && { color: rAS.headerColor }">
            {{ valueProp.benefits_per_transaction_fmt }}
          </h2>
          <div class="nav-text clickable" (click)="funcChangeView('benpertrans')">{{ trans.trans.benefits_per_transaction.value }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="chart-container col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div *ngIf="(view === 'discovery' || view === 'case' || view === 'benefits' || view === 'notes') && rAS.chartROI && rAS.chartVC">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 chart_left">
        <div class="border-card tour_benefits" style="margin-bottom: 0px">
          <div class="panel-body" *ngIf="!valueProp.benefits" style="height: 276px">
            <p>No benefits found. Please return to step 2 and activate a few benefits</p>
          </div>
          <div class="panel-body" *ngIf="valueProp.benefits">
            <div class="chartcontainer" *ngIf="!valueProp.benefits_loading">
              <div class="row">
                <div class="col-xs-12">
                  <div class="row">
                    <div class="col-xs-12">
                      <span class="total"
                        >{{ trans.trans.potential_total_financial_benefit.value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.potential_total_benefit"
                          (callback)="refreshTranslation()"
                        ></app-editTranslation> </span
                      >&nbsp; <span class="actual-total">+ {{ rAS.benefitTotal }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 doughnut-container">
                  <p-chart type="doughnut" height="180px" [options]="rAS.optionsVC" [data]="rAS.VCchart"></p-chart>
                  <div class="absolute-center-offset-10 text-center" [ngStyle]="{ color: rAS.selectedCLR }">
                    <span class="chart_val" style="padding-bottom: 12px" *ngIf="rAS.VCchart?.labels.length">{{ rAS.selectedVal | numberTruncate }}</span
                    ><br />
                    <span class="chart_text">{{ rAS.selectedName }}</span>
                  </div>
                </div>
                <div class="col-md-8 doughnut-chart-list-wrapper">
                  <div class="doughnut-chart-list">
                    <div class="row" *ngFor="let elem of rAS.VCchart?.labels; let i = index" style="margin-left: 0px; margin-right: 15px; margin-bottom: 10px">
                      <div class="col-xs-1 chart-icon-container">
                        <p class="chart-icon clickable" (click)="onBenefitClick(elem, i)" [ngStyle]="{ 'background-color': elem.color }"></p>
                      </div>
                      <div class="col-xs-10" style="padding-right: 0px">
                        <div (click)="onBenefitClick(elem, i)" class="clickable label" style="width: 100%; display: block; text-align: left; white-space: pre-line">
                          <span class="val clickable">{{ elem.val }}%</span>
                          <span class="text">{{ elem.name }}</span> <i matTooltip="Click to see the benefits" class="fa fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 chart_right">
        <div class="border-card tour_roi" style="margin-bottom: 0px">
          <div class="panel-body">
            <div class="row" style="margin-bottom: 15px">
              <div class="col-xs-12">
                <span class="total"
                  >{{ trans.trans.cost_vs_benefit.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.cost_vs_benefit"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation> </span
                >&nbsp; <span class="actual-total">= {{ trans.trans.return.value }}</span>
              </div>
            </div>

            <app-bar-line-chart
              *ngIf="valueProp && !sI.valuePropLoader && rAS.chartROI"
              [axis2]="true"
              height="200px"
              [data]="rAS.chartROI"
              [style2022]="true"
            ></app-bar-line-chart>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="view === 'roi' && rAS.chartROI" class="panel panel-info">
      <div class="panel-body">
        <table class="panel-table">
          <tr>
            <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
            <td colspan="3">
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.return_on_investment.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.return_on_investment"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <span class="info-text"
                >{{ trans.trans.ratio_of_cumulative_net_benefit_investment_over_the_product_period.value }}
                <span
                  >{{ trans.trans.your_roi_status_is.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.ratio_of_cumulative_net_benefit_investment_over_the_product_period"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  &nbsp;<span [ngSwitch]="rAS.roiStatus.colorId">
                    <ng-container *ngSwitchCase="4">
                      <span style="color: green">{{ trans.trans.green.value }}</span> - {{ trans.trans.between.value }} {{ rAS.roiStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.roiStatus.max }}
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                      <span style="color: orange">{{ trans.trans.orange.value }}</span> - {{ trans.trans.not_between.value }} {{ rAS.roiStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.roiStatus.max }} {{ trans.trans.but_within_the_tolerance.value }} {{ rAS.roiStatus.margin }}%
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      <span style="color: red">{{ trans.trans.red.value }}</span> - {{ trans.trans.not_between.value }} {{ rAS.roiStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.roiStatus.max }} {{ trans.trans.or_within_the_tolerance.value }} {{ rAS.roiStatus.margin }}%
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <span style="color: grey">{{ trans.trans.grey.value }}</span> - {{ trans.trans.grey_explanation.value }}
                    </ng-container>
                  </span>
                </span></span
              >
            </td>
          </tr>
          <tr>
            <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.the_roi_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.the_roi_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text">
                ({{ trans.trans.total_benefits.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.total_benefits"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
                - {{ trans.trans.total_costs.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.total_costs"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
                ) / {{ trans.trans.total_costs.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.total_costs"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
            <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.your_roi_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.your_roi_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.roiText }}</span>
            </td>
          </tr>
        </table>
        <app-bar-line-chart *ngIf="valueProp" [axis2]="true" [data]="rAS.chartROI" [style2022]="true"> </app-bar-line-chart>
      </div>
    </div>

    <div *ngIf="view === 'tco' && rAS.chartTCO" class="panel panel-info">
      <div class="panel-body">
        <table class="panel-table">
          <tr>
            <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
            <td colspan="3">
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.total_cost_of_ownership.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.total_cost_of_ownership"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
              <div style="float: right; display: block" *ngIf="feature76">
                <input type="checkbox" [(ngModel)]="rAS.showAllMetrics" (click)="rAS.funcShowAllMetrics(valueProp, view, feature62)" /> Show More than Costs Impacts
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <span class="info-text"
                >{{ trans.trans.total_cost_of_ownership_estimate.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.total_cost_of_ownership_estimate"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
          </tr>
          <tr>
            <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.the_tco_formula.value }}

                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.the_tco_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text">
                {{ trans.trans.total_current_costs.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.total_current_costs"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
                – {{ trans.trans.total_future_costs.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.total_future_costs"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
            <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.your_tco_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.your_tco_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.tcoText }}</span>
            </td>
          </tr>
        </table>
        <div *ngIf="rAS.toggleChart">
          <p-progressBar *ngIf="rAS.toggleChart" mode="indeterminate"></p-progressBar>
        </div>
        <div *ngIf="!rAS.toggleChart">
          <app-bar *ngIf="valueProp" [data]="rAS.chartTCO"></app-bar>
          <div>
            <app-tco #tco [showTranslate]="false" [embedded]="true" [showAllMetrics]="rAS.showAllMetrics" [valueProp]="valueProp"></app-tco>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="view === 'payback'" class="panel panel-info">
      <div class="panel-body">
        <table class="panel-table">
          <tr>
            <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
            <td colspan="3">
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.payback_period.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.payback_period"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <span class="info-text"
                >{{ trans.trans.the_length_of_time_required_for_an_investment_to_recover_its_initial_outlay_in_terms_of_profits.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.the_length_of_time_required_for_an_investment_to_recover_its_initial_outlay_in_terms_of_profits"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
                <span
                  >{{ trans.trans.your_payback_status_is.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.your_payback_status_is"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  <span [ngSwitch]="rAS.pbpStatus.colorId">
                    <ng-container *ngSwitchCase="4">
                      <span style="color: green">{{ trans.trans.green.value }}</span> - {{ trans.trans.between.value }} {{ rAS.pbpStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.pbpStatus.max }}
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                      <span style="color: orange">{{ trans.trans.orange.value }}</span> - {{ trans.trans.not_between.value }} {{ rAS.pbpStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.pbpStatus.max }} {{ trans.trans.but_within_the_tolerance.value }} {{ rAS.pbpStatus.margin }}%
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      <span style="color: red">{{ trans.trans.red.value }}</span> - {{ trans.trans.not_between.value }} {{ rAS.pbpStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.pbpStatus.max }} {{ trans.trans.or_within_the_tolerance.value }} {{ rAS.pbpStatus.margin }}%
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <span style="color: grey">{{ trans.trans.grey.value }}</span> - {{ trans.trans.grey_explanation.value }}
                    </ng-container>
                  </span>
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.the_payback_period_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.the_payback_period_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.paybackFormulaText }}</span>
            </td>
            <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.your_payback_period_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.your_payback_period_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.paybackText }}</span>
            </td>
          </tr>
        </table>
        <app-line *ngIf="valueProp" [data]="rAS.chartPayback" [style2022]="true"></app-line>
      </div>
    </div>

    <div *ngIf="view === 'npv'" class="panel panel-info">
      <div class="panel-body">
        <table class="panel-table">
          <tr>
            <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
            <td colspan="3">
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.net_present_value.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.net_present_value"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <span class="info-text"
                >{{ trans.trans.is_the_difference_between_the_present_value_of_cash.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.is_the_difference_between_the_present_value_of_cash"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
                <span>
                  {{ trans.trans.your_npv_status_is.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.your_npv_status_is"
                    (callback)="refreshTranslation()"
                  ></app-editTranslation>
                  <span [ngSwitch]="rAS.npvStatus.colorId">
                    <ng-container *ngSwitchCase="4">
                      <span style="color: green">{{ trans.trans.green.value }}</span> - {{ trans.trans.between.value }} {{ rAS.npvStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.npvStatus.max }}
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                      <span style="color: orange">{{ trans.trans.orange.value }}</span> - {{ trans.trans.not_between.value }} {{ rAS.npvStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.npvStatus.max }} {{ trans.trans.but_within_the_tolerance.value }} {{ rAS.npvStatus.margin }}%
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      <span style="color: red">{{ trans.trans.red.value }}</span> - {{ trans.trans.not_between.value }} {{ rAS.npvStatus.min }} {{ trans.trans.and.value }}
                      {{ rAS.npvStatus.max }} {{ trans.trans.or_within_the_tolerance.value }} {{ rAS.npvStatus.margin }}%
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <span style="color: grey">{{ trans.trans.grey.value }}</span> - {{ trans.trans.grey_explanation.value }}
                    </ng-container>
                  </span>
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.the_npv_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.the_npv_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.npvFormulaText }}</span>
            </td>
            <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.your_npv_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.your_npv_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.npvText }}</span>
            </td>
          </tr>
        </table>
        <app-bar-line-chart *ngIf="valueProp" [axis2]="false" [data]="rAS.chartNPV" [style2022]="true"> </app-bar-line-chart>
      </div>
    </div>

    <div *ngIf="view === '3mo'" class="panel panel-info">
      <div class="panel-body">
        <table class="panel-table">
          <tr>
            <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
            <td colspan="3">
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.three_month_cost_of_delay.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.three_month_cost_of_delay"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <span class="info-text"
                >{{ trans.trans.what_is_the_cost_of_the_customer_delaying_the_decision_to_buy.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.what_is_the_cost_of_the_customer_delaying_the_decision_to_buy"
                  (callback)="refreshTranslation()"
                ></app-editTranslation>
              </span>
            </td>
          </tr>
          <tr>
            <td width="44px"><img src="/assets/images/icon2.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.the_three_months_cost_of_delay_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.the_three_months_cost_of_delay_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.threemoText }}</span>
            </td>
            <td width="44px"><img src="/assets/images/icon3.png" style="margin-right: 10px" /></td>
            <td>
              <span class="info-title" [ngStyle]="{ color: rAS.headerColor }"
                >{{ trans.trans.your_three_months_cost_of_delay_formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.your_three_months_cost_of_delay_formula"
                  (callback)="refreshTranslation()"
                ></app-editTranslation> </span
              ><br />
              <span class="info-text"> {{ rAS.threemoFormula }}</span>
            </td>
          </tr>
        </table>
        <app-horizontal-bar *ngIf="valueProp" [data]="rAS.chart3MO" [style2022]="true"></app-horizontal-bar>
      </div>
    </div>

    <div *ngIf="view == 'cashFlow'" class="panel panel-info">
      <div style="overflow-x: auto; width: 100%; display: block">
        <app-cashflow-simple class="tour_cashflow" [valueProp]="valueProp"></app-cashflow-simple>
      </div>
    </div>

    <div *ngIf="view === 'benpertrans'" class="panel panel-info">
      <div class="panel-body">
        <table class="panel-table">
          <tr>
            <td><img src="/assets/images/icon1.png" style="margin-right: 10px" /></td>
            <td colspan="3"><span class="info-title" [ngStyle]="{ color: rAS.headerColor }">Benefit per Transaction </span></td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <span class="info-text">{{ trans.trans.bpt_note.value }} </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
