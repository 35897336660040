import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterUrlToLink',
    pure: false
})
export class filterUrlToLinkPipe implements PipeTransform {
    transform(filter: string): any {

        var replacedText, replacePattern1, replacePattern3;

        replacedText = filter.replace(/(?:\r\n|\r|\n)/g, '<br>');

        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = filter.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    }
}