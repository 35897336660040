import { Injectable } from '@angular/core';

@Injectable()
export class MVPDashboardTranslations {
  public config: any = {
    component: 'mvpDash',
    display_name: 'Master Value Prop Dashboard',
    description: 'Master value prop for main dashboard',
  };

  public trans: any = {
    masterValuePropsHeading: 'Recent Master Business Cases / Value Assessments',
    masterValueProp: 'Master Value Prop',
    mvp_name: 'Name',
    mvp_totalBenefits: 'Total Benefits',
    mvp_noValueProps: 'No Business Cases / Value Assessments Found',
    mvp_notLicensedForValueProps: 'Please wait as your Business Cases / Value Assessments load.',
    mvp_seeMore: 'See More',
    add: 'Add',
  };
}
