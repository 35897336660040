import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2ListTranslations {
  public config: any = {
    component: 'models',
    display_name: 'ModelsV2 Container',
    description: 'ModelsV2 Container '
  };

  public trans: any = {
    models_category_overview: 'Models Category Overview',
    models: 'Models',
    models_list: 'Models List',
    actions: 'Actions',
    ch_required_input: 'The below sections are required to build a model properly. Sections that have no entries are red with a zero (0) total.',
    required_input: 'Required Input',
    take_a_tour: 'Take a Tour',
    add_new_model: 'Add New Model',
    add_factors: 'Add Factors',
    add_situations: 'Add Situations',
    top5_models: 'Top 5 Models',
    model_groups: 'Model Groups',
    groups: 'Benefits and Groups',
    benefit_groups: 'Benefit Groups',
    benefit_types: 'Benefit Types',
    factor_groups: 'Factor Groups',
    optional_inputs: 'Optional Inputs',
    pain_points: 'Pain Points',
    capabilities: 'Capabilities',
    operational_objectives: 'Operational Objectives',
    functional_objectives: 'Functional Objectives',
    situations: 'Situations',
    situation_groups: 'SItuation Groups',
    benefitTypes: 'Benefit Types',
    goalGroups: 'Goal Groups',
  };
}
