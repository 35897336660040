<form name="formEditFactorScale" #formEditFactorScale="ngForm" *ngIf="transReady">
  <div>
    <div class="sidebar_header">
      Scales By
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.scaled_by"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <div class="sidebar_container" style="margin-bottom: 0px">
      <div class="keywest_panel" style="overflow-y: auto; max-height: 90vh">
        <div class="row">
          <ng-container *ngIf="scaledBy | async as scalers">
            <div class="col-md-4" *ngIf="scalers.length">
              <label for="name" class="sidebar_label">
                {{ trans.trans.scaled_by.value }}
              </label>
              <p-dropdown
                name="scale_type_id"
                [options]="scalers"
                [filter]="true"
                filterBy="name"
                [(ngModel)]="factor.scale_type_id"
                class="keywest_dropdown"
                appendTo="body"
              ></p-dropdown>
            </div>
          </ng-container>

          <ng-container *ngIf="scalesHow | async as scale">
            <div class="col-md-4" appNGError [elemControl]="formEditFactorScale.controls.straight_scaling" *ngIf="scale.length">
              <label for="name" class="sidebar_label">{{ trans.trans.scales_how.value }}</label>
              <p-dropdown
                name="straight_scaling"
                [options]="scale"
                [filter]="true"
                filterBy="name"
                [(ngModel)]="factor.straight_scaling"
                class="keywest_dropdown"
                appendTo="body"
              ></p-dropdown>
            </div>
          </ng-container>

          <div class="col-xs-4" appNGError [elemControl]="formEditFactorScale.controls.ratio" *ngIf="factor.straight_scaling == 2">
            <label for="name" class="sidebar_label"
              >{{ trans.trans.ratio.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.ratio"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input type="text" ret-data="Ratio" class="form-control" [(ngModel)]="factor.ratio" name="ratio" [placeholder]="trans.trans.ratio.value" required />
          </div>

          <div class="col-xs-4" appNGError [elemControl]="formEditFactorScale.controls.formula" *ngIf="factor.straight_scaling == 3">
            <label for="name" class="sidebar_label"
              >{{ trans.trans.formula.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.formula"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <input type="text" ret-data="Formula" class="form-control" [(ngModel)]="factor.formula" name="formula" [placeholder]="trans.trans.formula.value" required />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <app-btn-save [form]="formEditFactorScale" (callback)="updateFactor(formEditFactorScale)" text="Save" class="btn btn_customizable pull-right"></app-btn-save>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
