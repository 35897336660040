import { AccountScratchpad, FactorCategoriesResponse } from '@shared/models/factors.models';
import { FactorTagData } from '@shared/models/factors.models';
import { FaIcon } from '@shared/models/icons.models';

export interface FactorGroup extends FactorCategoriesResponse {
  strSearch?: string;
  collapsed?: boolean;
  color?: string;
}

export interface Factor {
  is_key_factor?: string | number;
  name?: string;
  description?: string;
  scale_type_id?: number | string;
  has_scratchpad?: string | number;
  created?: Date;
  id?: string | number;
  unit_type_id?: Array<string | number>;
  factor_precision?: string;
  vr_normalized?: string;
  scales_by?: any;
  scales_how?: any;
  literal_number?: string;
  greater_than_zero?: string;
  min?: string;
  max?: string;
  increment?: string;
  company_field?: any[];
  account_factor_category_id?: any[];
  rowView?: string;
  value_realization_name?: string;
  ratio?: string;
  formula?: string;
  icon?: FaIcon;
  straight_scaling?: number | string;
  account_scratchpad?: AccountScratchpad;
  account_factor_id?: string;
  value?: string;
  source_type_id?: string;
  display_source_type_id?: string;
  account_factor_category_type_id?: string;
  account_id?: string;
  scalers_page?: string;
  baseline_answer?: string;
  web_included_factor?: string;
  sequence?: string;
  primary_scaler?: string;
  is_telemetry_data?: string;
  modified?: Date;
  dep_factor_type_id?: string;
  dep_factor_id?: string;
  unit_type?: string;
  abbr?: string;
  value_fmt?: string;
  value_fr?: string;
  max_fmt?: string;
  max_fr?: string;
  min_fmt?: string;
  min_fr?: string;
  scratchpad_id?: string;
  scratchpad_used?: number;
  scratchpads?: string;
  units?: Unit[];
  has_tag?: boolean;
  nameTag?: string;
  valueTag?: string;
  tag_data?: FactorTagData;
  tag?: string;
  updated?: boolean;
  error?: string;
  valid_error?: string;
  isTdData?: boolean;
  allowNegative?: boolean;
  value_prop_metric_id?: string;
  capture_target?: string;
  capture_baseline?: string;
  validation?: CustomerValidation;
}

export interface CustomerValidation {
  id?: number;
  value_prop_id: number;
  account_factor_id?: number;
  user_id?: number;
  validating_user_name: string,
  previous_val?: number;
  new_val?: number;
  date?: string;
  year: number;
}

export const defaultFactor: Factor = {
  id: '',
  name: '',
  value_realization_name: '',
  description: '',
  unit_type_id: [],
  factor_precision: '0',
  scales_by: [],
  scales_how: '',
  scalers_page: '',
  ratio: '',
  formula: '',
  is_key_factor: '',
  literal_number: '',
  vr_normalized: '',
  greater_than_zero: '',
  min: '',
  max: '',
  increment: '',
  company_field: [],
  account_factor_category_id: [],
  has_tag: false,
  valueTag: null,
  nameTag: null,
  icon: {
    id: null,
    label: 'No Icon',
    value: '',
  },
};

export interface Unit {
  id: string;
  value_prop_scratchpad_id: string;
  account_scratchpad_unit_id: string;
  factor_id: string;
  operand: string;
  operand_estimate: string;
  operand_override: string;
  operand_source_type_id: string;
  operator_id: string;
  open_paren: string;
  close_paren: string;
  created: Date;
  modified: Date;
  name: string;
  factor_precision: string;
  abbreviation: string;
  unit_type_id: string;
  literal_number: string;
  unit_type: string;
  is_telemetry_data: string;
  type?: string;
  updated?: boolean;
  isTdData?: boolean;
}
