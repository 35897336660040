import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PrimengChartDataCustom, PrimengDataCustomLabels, PrimengLabels } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';

@Component({
	selector: 'app-doughnut-models-chart',
	templateUrl: './doughnut-models-chart.component.html',
	styleUrls: ['./doughnut-models-chart.component.scss']
})
export class DoughnutModelsChartComponent implements OnInit, OnChanges {
	@Input('data') chartData: any;
	chart: any;
	options: any;
	@Input() legend: any;
	selectedName = "";
	selectedVal = "";
	selectedCLR = "";

	@Output() modelClick = new EventEmitter();

	ngOnInit(): void {
		if (this.chartData) {
			this.selectedName = this.chartData.data.labels[0].name;
			this.selectedVal = this.chartData.data.labels[0].total;
		}

	}

	ngOnChanges(): void {
		if (this.chartData) {
			this.selectedName = (this.chartData.data.labels[0].name ? this.chartData.data.labels[0].name : "");
			this.selectedVal = (this.chartData.data.labels[0].total ? this.chartData.data.labels[0].total : "");
			this.buildChart();
		}
	}

	clickBenefitClick(item: any): void {
		this.selectedCLR = item.color;
		this.selectedName = item.name;
		this.selectedVal = item.total;
		this.modelClick.emit(item.name);
	}

	buildChart(): void {
		this.chart = {
			labels: this.chartData.data.labels,
			datasets: this.chartData.data.datasets
		}
		this.options = {
			cutoutPercentage: 80,
			type: "doughnut",
			layout: {
				padding: {
					left: 0,
					right: 0,
					top: 0,
					bottom: 0
				}
			},
			legend: {
				display: false
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						const customLabels = data.labels as unknown[] as PrimengDataCustomLabels['labels']; // Casting since callback use regular interface
						const label = customLabels[tooltipItem.index].name + ": " + data.datasets[0].data[tooltipItem.index];
						return label;
					}
				}
			},

			animation: {
				duration: 100
			}
		}
	}
}
