import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolutionValidationService } from '@data/services/validation/solution-validation.service';
import { BenefitValidationItem } from '@shared/models/benefits.models';
import { FactorValidation, FactorValidationUpdate, FactorValidationValueChange, ValidationFactorItem } from '@shared/models/factors.models';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslationStrings } from '../../benefits/benefit-validation-table/benefit-validation-table.component';
import { ValidationTranslations } from '../../validation.translations';

export enum FactorValidationTableTypes {
  INCONSISTENT_VALUES = 'inconsistentValues',
  DEFUALT_VALUE_MISMATCH_SCRATCHPAD = 'defaultValueMismatchScratchpad',
  BROKEN_SCRATCHPADS = 'brokenScratchpads',
  VALUE_OF_0 = 'valueOf0',
  NO_DEFAULT_VALUE = 'noDefaultValue',
}

@Component({
  selector: 'app-factor-validation-table',
  templateUrl: './factor-validation-table.component.html',
  styleUrls: ['./factor-validation-table.component.scss'],
})
export class FactorValidationTableComponent implements OnInit, AfterViewInit, OnDestroy {
  public t: { [key in TranslationStrings]: string };
  private solutionId: string;

  @Input() set trans(val: ValidationTranslations) {
    if (val) {
      this.t = val.t;
    }
  }
  @Input() factors: FactorValidation;
  @Input() tableType: FactorValidationTableTypes;
  @Input() cancelTriggered$: BehaviorSubject<boolean>;
  @Output() factorValueUpdate = new EventEmitter<FactorValidationValueChange>();

  public tableTypes = FactorValidationTableTypes;
  private unsubscribe$ = new Subject<void>();

  @ViewChildren('input') inputs: QueryList<ElementRef>;

  constructor(private solutionValidationService: SolutionValidationService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => (this.solutionId = params['id']));
  }

  ngAfterViewInit(): void {
    this.cancelTriggered$
      .pipe(
        filter((triggered) => triggered),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.inputs.forEach((input: ElementRef) => (input.nativeElement.value = undefined)));
  }

  public onValueChange(event: Event, factor: ValidationFactorItem, tableType: FactorValidationTableTypes) {
    const value = (event.target as HTMLInputElement).value;
    const update: FactorValidationUpdate = {
      factor_id: factor.id,
      unit_type_id: factor.unit_type_id,
      value,
    };
    const changeEvent: FactorValidationValueChange = {
      update,
      tableType,
    };
    this.factorValueUpdate.emit(changeEvent);
  }

  public goToFactor(factor: ValidationFactorItem) {
    this.solutionValidationService.selectedValidationFacotor$.next(factor);
  }

  public goToBenefit(benefit: BenefitValidationItem) {
    this.solutionValidationService.selectedValidationBenefit$.next(benefit);
  }

  public onAcknowledge(item: BenefitValidationItem, errorType: 'no_default_scalers' | 'zero_factors' | 'factor_mismatch') {
    const payload = {
      factors: [
        {
          factor_id: +item.id,
          [errorType]: item.acknowledged ? 1 : 0,
        },
      ],
    };
    this.solutionValidationService.acknowledgeValidationFactor(this.solutionId, payload).subscribe((res) => {
      if (res.result.status_code === 200) {
        this.solutionValidationService.validationErrorCount$.next(item.acknowledged ? -1 : 1);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
