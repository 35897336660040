import { NgModule } from '@angular/core';

import { TrackerLineComponent } from './tracker-line.component';
import { ChartModule } from 'primeng/chart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        ChartModule,
        OverlayPanelModule,
        CommonModule,
        FormsModule,
        MatTooltipModule,
    ],
    exports: [TrackerLineComponent],
    declarations: [TrackerLineComponent],
    providers: [],
})
export class TrackerLineModule { }
