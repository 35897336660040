<div [ngClass]="{ 'style-2022': style2022$ | async }">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.add_value_category_model.add_value_category | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p-tabView>
      <p-tabPanel header="Import">

          <div class="row">
            <div class="col-md-8">
              <div *ngIf="!toggleFactView">
                <div class="form-group">
                  <label class="labelsRequired">{{ trans.add_value_category_model.what_category_did_you_influence | translate }}</label>
                  <p-dropdown appendTo="body"
                    class="keywest_dropdown"
                    name="factValueCategoryDropdown"
                    [options]="factValueCategorys"
                    [(ngModel)]="factValueCategoryDropdown"
                    [filter]="true"
                    placeholder="Select a Value Category"
                    [required]="true"
                    [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
                  ></p-dropdown>
                </div>
                <div class="form-group">
                  <button type="button" class="btn btn_customizable m-t-xs m-b-xs" (click)="importFactcategory()" [disabled]="factValueCategoryDropdown.length == 0">
                    {{ trans.add_value_category_model.import | translate }}
                  </button>
                </div>
              </div>
              <div *ngIf="toggleFactView">
                <div class="form-group">
                  <label class="labelsRequired">{{ trans.add_value_category_model.what_category_did_you_influence | translate }}</label>

                  <div *ngIf="!editFact" class="m-t-md">
                    <label>
                      <strong class="m-r-md">{{ factCategory }}</strong>
                      <i class="icon-pencil tooltip-custom" (click)="editFact = !editFact" matTooltip="Edit" matTooltipPosition="right"></i>
                    </label>
                  </div>

                  <input *ngIf="editFact" class="form-control" type="text" name="factCategory" [(ngModel)]="factCategory" required />
                </div>
                <div class="form-group">
                  <button type="button" class="btn btn_customizable m-t-xs m-b-xs" (click)="addNewValueCategory(factCategory)" [disabled]="!factCategory">Save</button>
                  <button type="button" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="toggleFactView = !toggleFactView">
                    {{ trans.add_value_category_model.cancel | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>

      </p-tabPanel>
      <p-tabPanel header="Create New">

          <div *ngIf="toggleAddView">
            <form name="addNewValueCategoryForm" #addNewValueCategoryForm="ngForm">
              <div class="row">
                <div class="col-md-8">
                  <div
                    class="form-group"
                    [ngClass]="{ 'has-error': addNewValueCategoryForm.controls.newCategory?.invalid && addNewValueCategoryForm.controls.newCategory?.touched }"
                  >
                    <label for="benefitsName" class="benefitsName labelsRequired">{{ trans.add_value_category_model.category_name | translate }}</label>
                    <input type="text" name="newCategory" [(ngModel)]="newCategory" class="form-control" placeholder="Category name" required />
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn_customizable m-t-xs m-b-xs" (click)="addNewValueCategory(newCategory)" [disabled]="addNewValueCategoryForm.invalid">
                {{ trans.add_value_category_model.add | translate }}
              </button>

              <button type="submit" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="toggleAddViews()">{{ trans.add_value_category_model.cancel | translate }}</button>

              <img *ngIf="newCategoryLoader" class="actionLoader m-l-xxs" src="{{ fullImagePath }}" />
            </form>
          </div>

          <div *ngIf="!toggleAddView">
            <form name="addValueCategoryForm" #addValueCategoryForm="ngForm" (ngSubmit)="saveValueCategory()">
              <div class="row">
                <div class="col-md-8">
                  <div
                    class="form-group"
                    [ngClass]="{ 'has-error': addValueCategoryForm.controls.categoryInfluence?.invalid && addValueCategoryForm.controls.categoryInfluence?.touched }"
                  >
                    <label for="benefitsName" class="benefitsName labelsRequired">{{ trans.add_value_category_model.what_category_did_you_influence | translate }}</label>
                    <p-dropdown appendTo="body"
                      *ngIf="categoryInfluences"
                      class="keywest_dropdown"
                      [options]="categoryInfluences"
                      [(ngModel)]="categoryInfluence"
                      [filter]="true"
                      placeholder="Select a Value Category"
                      name="categoryInfluence"
                      [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
                      [required]="true"
                    ></p-dropdown>
                    <img *ngIf="influenceLoader" class="actionLoader m-l-xxs" src="{{ fullImagePath }}" />
                  </div>
                </div>

                <div class="col-md-4">
                  <button type="submit" class="btn btn_customizable m-t-md m-b-xs" (click)="toggleAddViews()">{{ trans.add_value_category_model.add_new | translate }}</button>
                </div>
              </div>

              <button type="submit" class="btn btn_customizable m-t-xs m-b-xs" (click)="saveValueCategory()" [disabled]="addValueCategoryForm.invalid">
                {{ trans.add_value_category_model.save | translate }}
              </button>
            </form>
          </div>

      </p-tabPanel>
    </p-tabView>
  </div>
</div>
