<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row' style='margin-bottom: 20px;'>
				<div class='col-xs-8'>
					<div class='title'>
						<span *ngIf="!changeName && !valuePropLoader">{{trans.trans.business_case_for.value}}
							{{masterValueProp.name}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.business_case_for" (callback)="getTranslations()"></app-editTranslation>
							<div *ngIf="valuePropLoader" class="col-12 text-center m-t-lg">
								<img class="actionLoader" src="{{fullImagePath}}" />
							</div>
							<i class="icon-pencil" role="button" *ngIf="!changeName && !valuePropLoader" matTooltip='{{trans.trans.ch_vp.value}}' container="body" matTooltipPosition="right" (click)="changeName=true" style='margin-left: 10px;'></i>
						</span>
						<div class='input-group' style='margin-bottom: 10px;'>
							<input class="form-control" *ngIf="changeName" [(ngModel)]="masterValueProp.name" />
							<div class="input-group-btn">
								<a role="button" *ngIf="changeName" (click)="changeMasterValuePropName()" class='btn btn-success'><i class='fa fa-save'></i></a>
								<a role="button" *ngIf="changeName" (click)="cancelMasterValuePropName()" class='btn btn-danger'><i class='fa fa-times'></i></a>
							</div>
						</div>
					</div>
				</div>
				<div class='col-xs-4'>
					<button class='btn btn_customizable pull-right' *ngIf="valueProps.length > 1" (click)='btnCompareAll()'>{{trans.trans.compare_all.value}}</button>
					<button class='btn btn_customizable pull-right' *ngIf="isVPGroup" matTooltip='{{trans.trans.ch_add_value_prop.value}}' container="body" matTooltipPosition="below" (click)='showAddVP = !showAddVP'>{{trans.trans.add_another.value}}</button>
					<!-- <i class="fa fa-exchange pull-right" matTooltip='{{trans.contextual_help.vp_dashboard.edit_translations | translate}}' container="body" matTooltipPosition="below" (click)='showTranslate = !showTranslate' style='margin-right: 10px; margin-top: 8px; font-size: 18px;' *ngIf="isAdmin && !showTranslate" aria-hidden="true"></i>
					<i class="fa fa-exchange pull-right" matTooltip='{{trans.contextual_help.vp_dashboard.rename_value_prop | translate}}' container="body" matTooltipPosition="right" (click)='showTranslate = !showTranslate' style='margin-right: 10px; font-size: 18px; margin-top: 8px; color: gold;' *ngIf="isAdmin && showTranslate" aria-hidden="true"></i> -->

				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class='models_panel' style='margin-bottom: 20px;' *ngIf="valueProp && !valuePropLoader">
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-12'>
									<div class='title'>{{trans.trans.totals.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.totals" (callback)="getTranslations()"></app-editTranslation>
									</div>
								</div>
							</div>
						</div>

						<div class="key-features-list">
							<div class="key-features-label">{{trans.trans.totalBenefits.value}} ({{term}}
								years) <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.totalBenefits" (callback)="getTranslations()"></app-editTranslation>
							</div>
							<div class="key-features-value">{{masterValueProp.total_benefits_fmt}}</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{trans.trans.totalCosts.value}} ({{term}} years)
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.totalCosts" (callback)="getTranslations()"></app-editTranslation>
							</div>
							<div class="key-features-value">{{masterValueProp.total_costs_fmt}}</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{trans.trans.ROI.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ROI" (callback)="getTranslations()"></app-editTranslation>

							</div>
							<div class="key-features-value">{{masterValueProp.roi}}</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{trans.trans.NPV.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.NPV" (callback)="getTranslations()"></app-editTranslation>

							</div>
							<div class="key-features-value">{{masterValueProp.npv}}</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{trans.trans.paybackPeriod.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.paybackPeriod" (callback)="getTranslations()"></app-editTranslation>
							</div>
							<div class="key-features-value">{{masterValueProp.payback}}</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{trans.trans.tco.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.tco" (callback)="getTranslations()"></app-editTranslation>

							</div>
							<div class="key-features-value">{{masterValueProp.tco}}</div>
						</div>
						<div class="key-features-list">
							<div class="key-features-label">{{trans.trans.cod.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.cod" (callback)="getTranslations()"></app-editTranslation>

							</div>
							<div class="key-features-value">{{masterValueProp.cost_of_delay}}</div>
						</div>

						<div class="key-features-list">
							<div class="key-features-label clickable" (click)='showClone = !showClone'><i class="prop-icon icon-docs"></i> Clone</div>
						</div>

						<div class="key-features-list">
							<div class="key-features-label clickable" (click)='showShare = !showShare'><i class="prop-icon icon-share"></i> Share</div>
						</div>
					</div>
					<div *ngIf="valuePropLoader" class="col-12 text-center m-t-lg">
						<img class="actionLoader" src="{{fullImagePath}}" />
					</div>
				</div>

				<div class='models_panel' style='margin-bottom: 20px;' *ngIf="projects && projects.length">
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row' style='margin-bottom: 20px;'>
								<div class='col-xs-8'>
									<div class='title'>{{trans.trans.projects.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.projects" (callback)="getTranslations()"></app-editTranslation>
									</div>
								</div>
								<div class='col-xs-4'>
									<button type="button" class="btn btn_customizable pull-right" (click)="openAddScenarios(addScenarioModal)">{{trans.trans.add.value}}</button>
								</div>
							</div>
						</div>

						<div *ngFor="let project of projects" class="row" style='margin-bottom: 20px;'>
							<div class='col-xs-12'>
								<span class="tooltip-custom" [ngbTooltip]="project.description" container="body" placement="right">{{project.name}}</span>
								<div class="pull-right" style='margin: 0px;'><i class="fa fa-times clickable" role="button" (click)="deleteProject(project.id)"></i></div>
							</div>
						</div>
					</div>
				</div>

				<div class='models_panel' style='margin-bottom: 20px;' *ngIf="projects && projects.length">
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-12'>
									<div class='title'>{{trans.trans.actions.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.actions" (callback)="getTranslations()"></app-editTranslation>
									</div>
								</div>

							</div>
						</div>


					</div>
				</div>

				<div class='models_panel' style='margin-bottom: 20px;' *ngIf="masterValuePropTemplates && masterValuePropTemplates.length">
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-12'>
									<div class='title'>{{trans.trans.assets.value}}
										<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle" [ngbTooltip]="trans.trans.ch_assets.value" container="body" placement="right"></i>
										<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="trans.trans.ch_assets.value" placement="right" container="body">
                      info
                    </span>
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.assets" (callback)="getTranslations()"></app-editTranslation>
									</div>
								</div>

							</div>
						</div>

						<div class='row' *ngFor="let template of masterValuePropTemplates" style='margin-bottom: 20px;'>
							<div class="col-md-12">
								<div>
									<i [ngClass]="getFAIcon(template.format)" [ngStyle]="getFAColor(template.format)"></i>
									<a *ngIf="!isVPGroup" href="{{assetUrl}}/assets/{{assetGenerator}}?account_asset_template_id={{template.account_asset_template_id}}&user_id={{userId}}&token={{token}}&master_value_prop_id={{masterValuePropId}}" target="_blank" [matTooltip]="template.description" matTooltipPosition="right" style='padding-left: 5px;'>{{template.artifact_name}}</a>
									<a *ngIf="isVPGroup" href="{{assetUrl}}/assets/{{assetGenerator}}?account_asset_template_id={{template.account_asset_template_id}}&user_id={{userId}}&token={{token}}&value_prop_group_id={{masterValuePropId}}" target="_blank" [matTooltip]="template.description" matTooltipPosition="right" style='padding-left: 5px;'>{{template.artifact_name}}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-8">
				<div class='models_panel' style='margin-bottom: 20px;' *ngFor="let valueProp of valueProps; let i = index;">
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-8'>
									<div class='title'><span *ngIf="!valueProp.changeName">{{valueProp.name}} &nbsp;</span>
										<i class="fa fa-pencil clickable" style='margin-left: 10px;' *ngIf="!valueProp.changeName && !valuePropLoader" (click)="valueProp.changeName=true" matTooltip='{{trans.trans.ch_edit.value}}' container="body" matTooltipPosition="below"></i>
										<i *ngIf="!valueProp.changeName && !valueProp.deleteLoading" class='fa fa-times clickable' style='margin-left: 10px;' (click)='funcDeleteVP(valueProp)' matTooltip='{{trans.trans.ch_delete.value}}' container="body" matTooltipPosition="below"></i>
										<img *ngIf="valueProp.deleteLoading" class="actionLoader" src="{{fullImagePath}}" />
										<!-- <i class='fa fa-plus clickable' *ngIf="!valueProp.compare" style='margin-left: 10px;' (click)='addToCompare($event,valueProp)' ngbTooltip="Compare" container="body" placement="bottom"></i>
										<i class='fa fa-minus clickable' *ngIf="valueProp.compare" style='margin-left: 10px;' (click)='addToCompare($event,valueProp)' ngbTooltip="Compare" container="body" placement="bottom"></i> -->
										<div *ngIf="valueProp.changeName" class='input-group'>
											<input class='form-control' [(ngModel)]="valueProp.name" />
											<div class='input-group-btn'>
												<a role="button" (click)="changeValuePropName(valueProp, i)" class='btn btn-success'><i class='fa fa-save'></i></a>
												<a role="button" (click)="cancelValuePropName(i)" class='btn btn-danger'><i class='fa fa-times'></i></a>
											</div>
										</div>
									</div>
								</div>
								<div class='col-xs-4'>
									<div class='pull-right'>
										<button type="button" class="btn btn_customizable" (click)="goToValuePropDash(valueProp.id)">{{trans.trans.details.value}}</button>
\										<!-- Don't do: This component is depricated -->
										<!-- <p-toggleButton *ngIf="valueProps.length > 1" styleClass="btn btn_customizable" onLabel="Compare" offLabel="Compare" onIcon="fa fa-check" offIcon="fa fa-times" iconPos="right" (onChange)='addToCompare(valueProp)'>
										</p-toggleButton> -->
									</div>
								</div>

							</div>
						</div>

						<div class='row'>
							<div class="key-features col-md-6">
								<div class="key-features-list">
									<div class="key-features-label">{{trans.trans.totalBenefits.value}}
										({{valueProp.term}} years) <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.totalBenefits" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.total_benefits_fmt}}</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label">{{trans.trans.totalCosts.value}}
										({{valueProp.term}} years) <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.totalCosts" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.total_costs_fmt}}</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label">{{trans.trans.ROI.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ROI" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.roi_decorated}}</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label">{{trans.trans.NPV.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.NPV" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.npv_fmt}}</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label">{{trans.trans.paybackPeriod.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.paybackPeriod" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.payback}} Months</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label">{{trans.trans.tco.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.tco" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.tco_fmt}}</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label">{{trans.trans.cod.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.cod" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.cost_of_delay_fmt}}</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label">
										{{trans.trans.benefits_per_transaction.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.benefits_per_transaction" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value">{{valueProp.benefits_per_transaction_fmt}}</div>
								</div>
								<div class="key-features-list">
									<div class="key-features-label" style='width: 40%;'>
										{{trans.trans.status.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.status" (callback)="getTranslations()"></app-editTranslation>
									</div>
									<div class="key-features-value" style='width: 60%;'><span (click)="setStatusEditable(valueProp)" *ngIf="!valueProp.valuePropStatus_editable" class="editable">
											{{valueProp.vp_status}}
										</span>
										<div *ngIf="valueProp.valuePropStatus_editable" class="editableBox">
											<div class='input-group'>
												<select class="form-control" name="valuePropStatus" [(ngModel)]="valuePropStatus">
													<option *ngFor="let propstatus of valuePropStatusTypes" [ngValue]="propstatus.id">
														{{propstatus.name}}
													</option>
												</select>
												<div class='input-group-btn' style='width: 35%;'>
													<button (click)="updateValuePropStatus(valuePropStatus, valueProp.id); valueProp.valuePropStatus_editable = !valueProp.valuePropStatus_editable;" class="btn btn-success" style='height: 35px;'>
														<i class="fa fa-check"></i>
													</button>
													<button (click)="valueProp.valuePropStatus_editable = !valueProp.valuePropStatus_editable" class="btn btn-danger" style='height: 35px;'>
														<i class="fa fa-times"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class='models_panel' style='margin-bottom: 20px;'>
									<div class='models_page_header_container_small'>
										<div class='keywest_header'>
											<div class='row'>
												<div class='col-xs-10'>
													<div class='title title_nopadding' (click)='valueProp.showScalers = !valueProp.showScalers'>
														{{trans.trans.vpgroup_scalers.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.vpgroup_scalers" (callback)="getTranslations()"></app-editTranslation>
													</div>
												</div>
												<div class='col-xs-2'>
													<i class='pull-right fa fa-plus link clickable' style='color: #000;' *ngIf="!valueProp.showScalers" (click)='valueProp.showScalers = !valueProp.showScalers'></i>
													<i class='pull-right fa fa-minus link clickable' style='color: #000;' *ngIf="valueProp.showScalers" (click)='valueProp.showScalers = !valueProp.showScalers'></i>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="valueProp.showScalers">
										<div class="row" style='margin-bottom: 10px;' *ngFor="let scaler of valueProp.scalers">
											<div class="col-xs-6">{{scaler.name}}</div>
											<div class="col-xs-6 editable link clickable text-right" (click)='scaler.edit = !scaler.edit' *ngIf="!scaler.value_fmt && !scaler.edit">{{scaler.value | number}}
											</div>
											<div class="col-xs-6 editable link clickable text-right" (click)='scaler.edit = !scaler.edit' *ngIf="scaler.value_fmt && !scaler.edit">{{scaler.value_fmt}}</div>
											<div class="col-xs-6" *ngIf="scaler.edit">
												<div class='input-group' style='margin-bottom: 10px;'>
													<input type="number" name="scaler_value" [(ngModel)]="scaler.value" class='form-control'>
													<div class='input-group-btn'>
														<button (click)="updateScalerValue(valueProp, scaler); scaler.edit = !scaler.edit;" class="btn btn-success" style='height: 35px;'><i class="fa fa-check"></i></button>
														<button (click)="scaler.edit = !scaler.edit" class="btn btn-danger" style='height: 35px;'><i class="fa fa-times"></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class='models_panel' style='margin-bottom: 20px;'>
									<div class='models_page_header_container_small'>
										<div class='keywest_header'>
											<div class='row'>
												<div class='col-xs-10'>
													<div class='title title_nopadding' (click)='valueProp.showScalers = !valueProp.showScalers'>
														{{trans.trans.vp_assets.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.vp_assets" (callback)="getTranslations()"></app-editTranslation>
													</div>
												</div>
												<div class='col-xs-2'>
													<i class='fa fa-plus pull-right link clickable' style='color: #000;' *ngIf="!valueProp.showAssets" (click)='valueProp.showAssets = !valueProp.showAssets'></i>
													<i class='fa fa-minus pull-right link clickable' style='color: #000;' *ngIf="valueProp.showAssets" (click)='valueProp.showAssets = !valueProp.showAssets'></i>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="valueProp.showAssets">
										<div class='row'>
											<div class='col-xs-12'>
												<div class="key-features-list" *ngFor="let asset of valueProp.assets.message">
													<i [ngClass]="getFAIcon(asset.format)" [ngStyle]="getFAColor(asset.format)"></i>
													<a href="{{assetUrl}}/assets/{{assetGenerator}}?account_asset_template_id={{asset.account_asset_template_id}}&user_id={{userId}}&token={{token}}&value_prop_id={{valueProp.id}}" target="_blank" [matTooltip]="asset.description" matTooltipPosition="left" style='padding-left: 5px;'>{{asset.name}}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-sidebar *ngIf="showAddVP" [(visible)]="showAddVP" styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<div style='height: 100%; overflow-y: scroll;'>
		<app-add-value-position-v2 [value_prop_group]='masterValueProp' [companyDetails]='companyDetails' [addJustCase]='true' (CallbackDone)='CallbackDone()'></app-add-value-position-v2>
	</div>
</p-sidebar>

<p-sidebar [(visible)]="showCompare" styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<div class='row'>
		<div class='col-xs-12'>
			<a (click)='saveCompare()' class='btn btn_customizable pull-right'>{{trans.trans.compare.value}}<i class='fa fa-chevron-right'></i></a>
		</div>
	</div>
	<div class='row'>
		<div class='col-xs-3' *ngFor="let item of toCompare">
			<b>{{item.name}}</b>

			<div class="key-features-list">
				<div class="key-features-label">{{trans.trans.totalBenefits.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.totalBenefits" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div class="key-features-value">{{item.total_benefits_fmt}}</div>
			</div>
			<div class="key-features-list">
				<div class="key-features-label">{{trans.trans.totalCosts.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.totalCosts" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div class="key-features-value">{{item.total_costs_fmt}}</div>
			</div>
			<div class="key-features-list">
				<div class="key-features-label">{{trans.trans.ROI.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.ROI" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div class="key-features-value">{{item.roi_decorated}}</div>
			</div>

			<div class="key-features-list">
				<div class="key-features-label">{{trans.trans.NPV.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.NPV" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div class="key-features-value">{{item.npv_fmt}}</div>
			</div>
			<div class="key-features-list">
				<div class="key-features-label">{{trans.trans.paybackPeriod.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.paybackPeriod" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div class="key-features-value">{{item.payback}} Months</div>
			</div>
			<div class="key-features-list">
				<div class="key-features-label">{{trans.trans.tco.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.tco" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div class="key-features-value">{{item.tco_fmt}}</div>
			</div>
			<div class="key-features-list">
				<div class="key-features-label">{{trans.trans.cod.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.cod" (callback)="getTranslations()"></app-editTranslation>
				</div>
				<div class="key-features-value">{{item.cost_of_delay_fmt}}</div>
			</div>
		</div>
	</div>
</p-sidebar>

<ng-template #addScenarioModal let-c="close" let-d="dismiss" class="modal-dialog modal-sm">
	<div class="modal-header">
		<h4 class="modal-title">{{trans.trans.add_projects.value}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d(' Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body addForm">
		<div class="form-group">
			<label for="" class="control-label stepsheader">{{trans.trans.pick_your_projects.value}}</label>
			<div class="row" style="margin-bottom:10px">
				<div class="col-md-11" *ngIf="availableMasterValuePropProjects.length > 0">
					<div class="panel-body col-md-5 projects-list">

						<div *ngFor="let projects of availableMasterValuePropProjects">
							<p style='margin: 0px !important;'>
								<i class="icon-plus" (click)="projects.isCollapsed = !projects.isCollapsed" [attr.aria-expanded]="projects.isCollapsed"></i> &nbsp;
								<span class="tooltip-custom" [matTooltip]="projects.description" matTooltipPosition="right">{{projects.name}}</span>
							</p>
							<ul [ngbCollapse]="!projects.isCollapsed">
								<li *ngFor="let children of projects.children" class="checkbox custom-checkbox">
									<label [matTooltip]="children.description" matTooltipPosition="right">
										{{children.name}}
										<input type="checkbox" name="project{{children.id}}" [(ngModel)]="children.selected">
										<span class="check"></span>
									</label>
								</li>
							</ul>
						</div>
					</div>
					<div class="d-flex panel-body col-md-1 action-icons-wrapper">
						<div class="action-icons icon-arrow-right large-arrow" (click)="moveSelectedProject()"></div>
					</div>
					<div class="panel-body col-md-5 projects-list">
						<div *ngFor="let projects of selectedProjects">
							<p class="col-md-10" style='margin: 0px !important;'>
								<span class="tooltip-custom" [ngbTooltip]="projects.description" container="body" placement="right">{{projects.parent}} - {{projects.name}}</span>
							</p>
							<p class="col-md-2" style='margin: 0px !important;' role="button" (click)="removeSelectedProject(projects.id)">
								<i class="fa fa-times"></i>
							</p>
						</div>
					</div>
				</div>
				<div class="col-md-11" *ngIf="(availableMasterValuePropProjects.length == 0) && showActionLoaderProjects">
					<img class="actionLoader" src="{{fullImagePath}}" />
				</div>
				<div class="col-md-11" *ngIf="(availableMasterValuePropProjects.length == 0) && !showActionLoaderProjects">
					<p>{{trans.trans.no_projects_found.value}}</p>
				</div>
				<div class="col-md-11">
					<img class="actionLoader" src="{{fullImagePath}}" *ngIf="(availableMasterValuePropProjects.length > 0) && showActionLoaderProjects" />
				</div>
				<div class="col-md-11" style="margin-top:10px">

				</div>
			</div>

		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="createProjects()" [disabled]="(availableMasterValuePropProjects.length > 0) && (selectedProjects.length == 0)">{{trans.trans.add.value}}</button>
	</div>
</ng-template>

<p-sidebar [(visible)]="showShare" styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			{{trans.trans.share_your_master_value_prop.value}}
		</div>
		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<app-master-share [masterValuePropId]="masterValuePropId" [isVPGroup]='isVPGroup'></app-master-share>
			</div>
		</div>
	</div>
</p-sidebar>

<p-sidebar [(visible)]="showClone" styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			<div *ngIf="isVPGroup">{{trans.trans.clonevpgroup.value}}</div>
			<div *ngIf="!isVPGroup">{{trans.trans.clone_this_master_value_prop.value}}</div>
		</div>
		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<input type='text' class='form-control' [(ngModel)]="cloneName" placeholder='New Name'>
			</div>
			<div class='btn_container'>
				<img *ngIf="cloneLoader" class="actionLoader" [src]="fullImagePath" />
				<button (click)='funcCloneVPGroup()' *ngIf="!cloneLoader && cloneName !== '' && isVPGroup" class='pull-right btn btn_customizable'>{{trans.trans.clone.value}}</button>
				<button (click)='funcCloneValueProp()' *ngIf="!cloneLoader && cloneName !== '' && !isVPGroup" class='pull-right btn btn_customizable'>{{trans.trans.clone.value}}</button>
			</div>
		</div>
	</div>
</p-sidebar>