import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateStringToDatePipe } from './date-string-to-date.pipe';

@NgModule({
  declarations: [DateStringToDatePipe],
  imports: [CommonModule],
  exports: [DateStringToDatePipe],
})
export class DateStringToDateModule {}
