import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ValueTrackerShareComponent } from './value-tracker-share.component';
import { ShareTranslations } from './value-tracker-share.translation';
@NgModule({
  declarations: [ValueTrackerShareComponent],
  entryComponents: [ValueTrackerShareComponent],
  imports: [MatTooltipModule, CommonModule, FormsModule, TableModule, DropdownModule, AreYouSureModule],
  providers: [ShareTranslations],
  exports: [ValueTrackerShareComponent],
})
export class ValueTrackerShareModule {}
