import { Injectable } from '@angular/core';

@Injectable()
export class ValueRealizedHomeTranslation {
  public config: any = {
    component: 'valueRealizedHome',
    display_name: 'Value Realized List',
    description: 'A list of all value realizations by company',
  };

  public trans: any = {
    add: 'Add New',
    all: 'All',
    company_name: 'Account',
    created: 'Date Created',
    dashboard: 'Dashboard',
    expected: 'Expected to Date',
    mine: 'Mine',
    name: 'Name',
    number_of_trackers: 'Trackers Created',
    search: 'Search',
    shared: 'Shared with Me',
    valueRealized: 'Value Realized',
    realized: 'Achieved to Date',
    percentage: 'Variance to Date %',
    last_measurement_date: 'Last Measurement',
    noRecords: 'No records found',
    noResults: 'No results for ',
    valueTrackersByAccount: 'Value Trackers by Account',
    headerTooltip: 'To access existing value trackers, click on the account name. To create a new value tracker, click Add New Tracker.',
    addTooltip: 'Click Add New Trackers to promote a Value Proposition into a Tracker and to configure the Tracker Baseline in preparation for measuring actual performance.',
    valueRealizationHeading: 'Recent Value Realizations',
    addNew: 'Add',
    seeMore: 'See More',
    trackers_in_rollup: 'Trackers Included',
  };
}
