import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { TranslateLoader } from '@ngx-translate/core';
import { AddValuePositionComponentV2 } from './add-value-position.component.v2';
import { Routes, RouterModule } from '@angular/router';
import { SearchCompanyModule } from '../search-company/search-company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VMWareModule } from '../guided-discovery/vmware/vmware.module';
import { SearchCompanyModuleV2 } from './dynamic_components/search-companyv2/search-company.module';
import { DynamicComponentLoaderModule, DynamicComponentManifest } from './dynamic-component-loader/dynamic-component-loader.module';
import { AddVPGenericComponent } from './dynamic_components/generic.component';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DefaultTranslations } from './add-value-positionv2.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

const manifests: DynamicComponentManifest[] = [
  {
    componentId: 'opportunityid',
    path: 'opportunity_id',
    loadChildren: () => import('./dynamic_components/opportunity/opportunity.module').then(m => m.AddVPOpportunityModule)
  },
  {
    componentId: 'addyouraccount',
    path: 'add_your_account',
    loadChildren: () => import('./dynamic_components/account/vpaccount.module').then(m => m.VPAccountModule)
  },
  {
    componentId: 'models',
    path: 'models',
    loadChildren: () => import('./dynamic_components/models/vpsolutions.module').then(m => m.AddVPSolutionsModule)
  },
  {
    componentId: 'situations',
    path: 'situations',
    loadChildren: () => import('./dynamic_components/situations/vpsituations.module').then(m => m.AddVPSituationsModule)
  },
  {
    componentId: 'scalers',
    path: 'scalers',
    loadChildren: () => import('./dynamic_components/scalers/vpscalers.module').then(m => m.AddVPScalersModule)
  },
  {
    componentId: 'assumptions',
    path: 'assumptions',
    loadChildren: () => import('./dynamic_components/key_assumptions/create_key_assumptions.module').then(m => m.AddVPKeyAssumptions)
  },
  {
    componentId: 'usecase',
    path: 'usecase',
    loadChildren: () => import('./dynamic_components/use_case/usecase.module').then(m => m.VPUsecaseModule)
  },
  {
    componentId: 'refine',
    path: 'refine',
    loadChildren: () => import('./dynamic_components/refine/refine.module').then(m => m.AddVPRefineModule)
  },
  {
    componentId: 'finish',
    path: 'finish',
    loadChildren: () => import('./dynamic_components/finish/finish.module').then(m => m.AddVPFinishModule)
  }
];

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

const routes: Routes = [
  {
    path: 'addValuePositionV2',
    component: AddValuePositionComponentV2
  }
];

@NgModule({
  imports: [
    GuidedTourModule,
    DynamicComponentLoaderModule.forRoot(manifests),
    NgbModule,
    RouterModule.forChild(routes),
    CommonModule,
    TableModule,
    MultiSelectModule,
    DropdownModule,
    InputMaskModule,
    TabViewModule,
    EditTranslationsModule,
    FormsModule,
    SearchCompanyModule,
    SearchCompanyModuleV2,
    VMWareModule,
  ],
  declarations: [
    AddVPGenericComponent,
    AddValuePositionComponentV2
  ],
  providers: [GuidedTourService, DefaultTranslations, TranslationsV2Service],
  entryComponents: [AddValuePositionComponentV2],
  exports: [AddValuePositionComponentV2, RouterModule]
})
export class AddValuePositionModule { }
