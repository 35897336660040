import { Injectable } from '@angular/core';

@Injectable()
export class ConfigurableWorkFlowsTranslations {
  public config: any = {
    component: 'ConfigurableWorkFlows',
    display_name: 'Configurable WorkFlows',
    description: 'Configurable WorkFlows main'
  };

  public trans: any = { 
    dashboard: 'Dashboard',
    settings: 'Settings',
    configurableWorkflow: 'Configurable Workflows',
    search: 'Search',
    name: 'Name',
    systemStatus: 'System Status',
    userGroups: 'User Groups',
    createdBy: 'Created By',
    createdDate: 'Created Date',
    status: 'Status',
    enabled: 'Enabled',
    disabled: 'Disabled',
    systemDefined: 'System Defined',
    userDefined: 'User Defined',
    accountSpecific: 'Account Specific',
    areYouSure: 'Are you sure you want to delete this workflow?',
    edit: 'Edit',
    clone: 'Clone',
    delete: 'Delete',
    changeSuccessful: 'Changes were made successfully',
    addNew: 'Add New', 
    addNewWorkflow: 'Add New Workflow',
    editWorkflow: 'Edit Workflow',
    save: 'Save',
    cancel: 'Cancel',
    assets: 'Available Assets',
    description: 'Description',
    userGroupsAvailable: 'Available For',
    workflowIcon: 'Add Workflow Icon',
    workflowName: 'Workflow Name',
    position: 'Position',
    standardStep: 'Standard Step',
    stepName: 'Workflow Step Name',
    stepDescription: 'Workflow Step Description',
    allTemplates: 'All Templates',
    specificTemplates: 'Specific Templates',
    allUsers: 'All Users',
    all: 'All',
    defaultStep: 'Default?',
  };
}