import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'add-company',
    display_name: 'Add Company v2',
    description: 'Add Company inside create VP'
  };

  public trans: any = {
    back: 'Back',
    are_you_sure: 'Are you sure?',
    start_over: 'Start Over',
    take_a_tour: 'Take a tour',
    waiting_for_data: 'Waiting for data...',
    progress: 'Progress',
    hide_progress_panel: 'Hide Progress Panel',
    show_progress_panel: 'Show Progress Panel',
    click_to_hide_help: 'Click to hide help',
    click_to_show_help: 'Click to show help',
    pick_your_company: 'Pick your Company',
    add_usecase: 'Add Use Case',
    pick_your_models: 'Pick Your Model(s)',
    pick_your_situations: 'Pick Your Situations',
    scale_use_case: 'Scale your use case',
    finish: 'Finish',
  };
}
