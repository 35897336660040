import {Injectable} from '@angular/core';

@Injectable()
export class WorkflowQuestionsTranslation {
    public config: any = {
        component: 'workflowQuestions',
        display_name: 'Guided Discovery Workflow Questions',
        description: 'Guided Discovery workflow questions'
    };

    public trans: any = {
      selectOne: 'Select one',
      noMembersFound: 'No members were found',
      noQuestionsFound: 'No questions were found'
    };
}
