import { Injectable } from '@angular/core';
import { FormatType } from '@shared/models/format-type.model';
import { APIResult } from '@shared/models/api-result.model';
import { AssetElementTypesResponse, AssetType } from '@shared/models/asset.models';
import { BasicInfo } from '@shared/models/basic-info.model';
import { CaseStudySectionType, FactValueCategory, Industry, ProjectBasicInfo, ScracthpadOperatorType, ScratchpadFactorType, SolutionThresholdType, State, ValuePropStatusType } from '@shared/models/facts.models';
import { ScaleType } from 'chart.js';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';
import { CostCategoryType, ExpenseType } from '../valueprop/models/cost.model';
import { NumberFormat } from '../valueprop/models/situation-opportunity.model';
import { WorkflowDataSource } from './models/workflow-data-source.interface';
import { Factor } from '../valueposition/models/factor-group.interface';
@Injectable()
export class FactsService {
  serviceUrl: string;
  endpoint: string;

  constructor(private commonService: CommonService) {
    this.serviceUrl = this.commonService.getServiceUrl();
    this.endpoint = this.serviceUrl + '/facts/Fact.Service_VC.php';
  }

  public getSectors(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/sectors';
    return this.commonService.getAPIService(endpoint);
  }

  public getIndustry(): Observable<APIResult<Industry[]>> {
    let endpoint = 'fact/industriesBySectors';
    return this.commonService.getAPIService(endpoint);
  }

  public selectSectors(param: string): Observable<APIResult<Industry[]>> {
    let endpoint = 'fact/industriesBySectors/' + param;
    return this.commonService.getAPIService(endpoint);
  }

  public getFileFormatTypes(): Observable<APIResult<FormatType[]>> {
    let endpoint = 'fact/formatTypes/';
    return this.commonService.getAPIService(endpoint);
  }

  public getAccrualTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/accrualTypes/';
    return this.commonService.getAPIService(endpoint);
  }

  public getScaleTypes(dns: number): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/scaleTypes/excludeDns/' + dns;
    return this.commonService.getAPIService(endpoint);
  }

  public getAccountScaleTypes(): Observable<APIResult<Factor[]>> {
    let endpoint = 'solutions/readAccountScaleTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getQuestionTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/surveyQuestionTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getSituationElementTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/situationElementTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getSolutionThresholdTypes(): Observable<APIResult<{ thresholdTypes: SolutionThresholdType[] }>> {
    let endpoint = 'fact/solutionThresholdTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getValuePropStatusTypes(): Observable<APIResult<ValuePropStatusType[]>> {
    let endpoint = 'fact/valuePropStatusTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getScratchpadImprovementTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/improvementTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getScratchpadFactorTypes(): Observable<APIResult<ScratchpadFactorType[]>> {
    let endpoint = 'fact/aggregatedFactors';
    return this.commonService.getAPIService(endpoint);
  }

  public getScatchpadOperatorTypes(): Observable<APIResult<ScracthpadOperatorType[]>> {
    let endpoint = 'fact/scratchpadOperatorTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getReleaseTypes(): Observable<APIResult<{ releaseTypes: BasicInfo[] }>> {
    let endpoint = 'fact/releaseTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public CaseStudySectionTypes(): Observable<APIResult<{ sectionTypes: CaseStudySectionType[] }>> {
    let endpoint = 'fact/CaseStudySectionTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getAssetTypes(): Observable<APIResult<AssetType[]>> {
    let endpoint = 'fact/assetTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getAssetElementTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/assetElementTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getAssetElementTypesCombined(assetId: string): Observable<APIResult<AssetElementTypesResponse>> {
    let endpoint = 'asset/readAssetTypesAndElements/' + assetId;
    return this.commonService.getAPIService(endpoint);
  }

  public getCostCategoryTypes(): Observable<APIResult<BasicInfo[]>> {
    let endpoint = 'fact/costCategoryTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getExpenseTypes(): Observable<APIResult<ExpenseType[]>> {
    let endpoint = 'fact/expenseTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getPerformanceTypes(): Observable<APIResult<{ fact_performance_type: BasicInfo[] }>> {
    let endpoint = 'fact/factPerformanceTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getNumberFormats(): Observable<APIResult<NumberFormat[]>> {
    let endpoint = 'fact/numberFormatTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public createDriverFactor(payload: { name: string; account_id: string }): Observable<APIResult<{ driverFactor: BasicInfo; success: boolean; message?: string }>> {
    let endpoint = 'fact/createDriverFactor';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public createFinancialFactor(payload: { name: string }): Observable<APIResult<{ financialFactor: BasicInfo; success: boolean; message: string }>> {
    let endpoint = 'fact/createFinancialFactor';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public createImprovementFactor(payload: { name: string }): Observable<APIResult<{ improvementFactor: BasicInfo; success: boolean; message: string }>> {
    let endpoint = 'fact/createImprovementFactor';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getAccountCostCategoryTypes(accountid: string): Observable<APIResult<CostCategoryType[]>> {
    let endpoint = 'account/readCostCategoryTypes/' + accountid;
    return this.commonService.getAPIService(endpoint);
  }

  public createSectionType(payload: { name: string; description: string }): Observable<APIResult<{ section_id: number }>> {
    let endpoint = 'fact/createCaseStudySectionTypes';
    return this.commonService.postAPIService(endpoint, payload);
  }

  public getIndustriesBySector(sectorId: string | number): Observable<APIResult<{ dl_industries: Industry[]  }>> {
    let endpoint = 'fact/dlIndustriesByDLSectorID/' + sectorId;
    return this.commonService.getAPIService(endpoint);
  }

  public getCountries(): Observable<APIResult<{ countries: BasicInfo[] }>> {
    let endpoint = 'fact/readCountries';
    return this.commonService.getAPIService(endpoint);
  }

  public getStatesByCountries(countryId: string): Observable<APIResult<{ states: State[] }>> {
    let endpoint = 'fact/readStatesByCountry/' + countryId;
    return this.commonService.getAPIService(endpoint);
  }

  public getProjects(): Observable<APIResult<ProjectBasicInfo[]>> {
    let endpoint = 'fact/projects';
    return this.commonService.getAPIService(endpoint);
  }

	public getFactValueCategories(): Observable<APIResult<FactValueCategory[]>> {
		let endpoint = 'fact/readValueCategories';
		return this.commonService.getAPIService(endpoint);
	}

  getWorkflowDataSources(): Observable<{result: {workflow_data_sources: WorkflowDataSource[]}}> {
    const endpoint = 'fact/workflow-data-sources';
    return this.commonService.getAPIService(endpoint);
  }
}
