import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2ROPObjectiveTranslations {
  public config: any = {
    component: 'r_op_objectives',
    display_name: 'Operational Objectives Read',
    description: 'Read Solutions Operational Objectives '
  };

  public trans: any = {
    view_op_ob: 'View Operational Objectives',
    name: 'Name',
    description: 'Description'
  };
}
