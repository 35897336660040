import { Injectable } from '@angular/core';

import { CommonService } from '@data/services/common/common.service';

import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ModelFactor } from '@shared/models/model-factor.model';
import { Goal } from '@shared/models/goal-group.model';
import { APIResult } from '@shared/models/api-result.model';
import { FactorResponse } from './models/factor-response.interface';

@Injectable()
export class ModelFactorsService {
  constructor(private commonService: CommonService) {}
  setFactor(modelId: number | string, factorId: number | string, value: number, baseline_value: number, default_active: '0' | '1'): Observable<APIResult<FactorResponse>> {
    const endpoint = 'solutions/factor';
    const payload = {
      account_solution_id: `${modelId}`,
      account_factor_id: `${factorId}`,
      value,
      baseline_value,
      default_active,
    };

    return this.commonService.postAPIService(endpoint, payload);
  }

  getFactorsByFunctionType(modelId: number | string, functionTypeId = 1): Observable<ModelFactor[] | Goal[]> {
    const endpoint = `solutions/${modelId}/factors?function_type_id=${functionTypeId}`;

    return this.commonService.getAPIService(endpoint).pipe(pluck('result'));
  }

  deleteFactor(modelId: number | string, factorId: number | string): Observable<void> {
    const endpoint = `solutions/${modelId}/factor/${factorId}`;

    return this.commonService.deleteAPIService(endpoint);
  }
}
