<div *ngIf="!loading || !hideActionLoader; else progressBar" class="formula-container">
  <div class="formula-title">
    <ng-container *ngFor="let scratchpad of scratchpadList?.units; index as i">
      <span *ngIf="+scratchpad?.open_paren === 1" class="paren"> ( </span>
      <span class="formula-name">{{ scratchpad.name }}</span>
      <span class="operand">{{ scratchpad.operand }}</span>
      <span *ngIf="+scratchpad?.close_paren === 1" class="paren"> ) </span>
      <span *ngIf="operatorList && operatorList[+scratchpadList?.units[i].operator_id - 1] && +scratchpadList?.units[i].operator_id !== 5" class="operator">{{
        operatorList[+scratchpadList?.units[i].operator_id - 1]?.sign
      }}</span>
    </ng-container>
  </div>
  <div class="formula-box">
    <div *ngFor="let scratchpad of scratchpadList?.units; index as i" class="formula-row">
      <div class="formula-symbol">
        <span *ngIf="+scratchpad?.open_paren === 1 && i === 0"> ( </span>
        <span style="color: #ff0000" *ngIf="i > 0 && operatorList && operatorList[+scratchpadList?.units[i - 1].operator_id - 1]">{{
          operatorList[+scratchpadList?.units[i - 1].operator_id - 1]?.sign
        }}</span>
        <span *ngIf="+scratchpad?.open_paren === 1 && i !== 0"> ( </span>
      </div>
      <div [ngClass]="currentYear[i]?.override ? 'formula-factor has_override' : 'formula-factor'">
        <span *ngIf="!scratchpad.nameEdition">
          {{ scratchpad.name }} 
          <span *ngIf="scratchpad.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="scratchpad.description"> info </span>
          <i *ngIf="nameEditable" (click)="toggleEdit(i)" style="margin-left: 10px" class="clickable fa fa-pencil"></i>
        </span>
        <div *ngIf="scratchpad.nameEdition" class="input-group">
          <input [(ngModel)]="scratchpad.name" type="text" class="form-control" />
          <span class="clickable input-group-addon" (click)="toggleEdit(i)"><i class="fa fa-times"></i></span>
          <span class="clickable input-group-addon" (click)="funcSaveFactorName(scratchpad)"><i class="fa fa-save"></i></span>
        </div>
      </div>
      <div class="spacer"></div>
      <!-- <div class="formula-input" *ngFor="let year of scratchpad.years"> -->
      <div class="formula-input">
        <input 
          currencyMask
          [options]="{
            prefix: '',
            decimal: decimalSeparator,
            thousands: groupSeparator,
            precision: +scratchpad?.factor_precision >= 0 ? scratchpad.factor_precision : 2
          }"
          title="Answer"
          *ngIf="!locked"
          [disabled]="+scratchpad?.literal_number === 1 || (+scratchpad?.function_type_id === 1 && !!goal)"
          type="text"
          size="1"
          name="operand{{ currentYear[i]?.value }}"
          [(ngModel)]="currentYear[i]['value']"
          (ngModelChange)="scratchpadYearUpdated(currentYear[i], scratchpad)"
          placeholder="Answer"
          [ngClass]="currentYear[i]?.override === true ? 'form-control has_override' : 'form-control'"
        />
      </div>
      <div class="formula-symbol">
        <span *ngIf="+scratchpad?.close_paren === 1"> ) </span>
      </div>
    </div>

    <div class="formula-row">
      <div class="formula-symbol"></div>
      <div class="formula-factor">
        {{ trans.trans.calculatedValue.value }}
      </div>
      <div class="spacer"></div>
      <div class="formula-total">
        <span class="total-value" *ngIf="total">{{ total }}</span>
      </div>
      <div class="formula-symbol"></div>
    </div>
    <div *ngIf="hasScratchpadOverrides" class="has_override_txt">* Contains manually overridden value</div>
  </div>
</div>

<ng-container *ngIf="!sidebarStyle; else goalSidebar">
  <div *ngIf="!locked">
    <div *ngIf="hideActionLoader; else actionLoader">
      <button type="button" class="btn btn-success" (click)="saveAndCalcScratchpad('1')"><i class="fa fa-save"></i> {{ trans.trans.save.value }}</button>
      <button type="button" class="btn btn-primary" (click)="saveAndCalcScratchpad('0', true)"><i class="fa fa-calculator"></i> {{ trans.trans.Calculate.value }}</button>
      <button type="button" class="btn btn-danger" (click)="clearAnswer()"><i class="fa fa-times"></i> {{ trans.trans.Clear.value }}</button>
    </div>
    <ng-template #actionLoader>
      <div class="row">
        <div class="col-xs-12">
          <img class="actionLoader" [hidden]="hideActionLoader" [src]="fullImagePath" />
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-template #progressBar>
  <p-progressBar mode="indeterminate"></p-progressBar>
</ng-template>
<ng-template #goalSidebar>
  <div class="goal-button-bar flex-jend">
    <button class="btn btn_customizable cancel marg-r" (click)="cancel()">{{ trans.trans.cancel.value }}</button>
    <button type="button" class="btn btn_customizable marg-h" (click)="saveAndCalcScratchpad('0', true)">{{ trans.trans.Calculate.value }}</button>
    <button class="btn btn_customizable marg-h" [disabled]="!hideActionLoader" (click)="saveAndCalcScratchpad('1')">{{ trans.trans.save.value }}</button>
  </div>
</ng-template>
