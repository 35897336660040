import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyService } from '@data/services/company/company.service';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { Subject } from 'rxjs';
import { AccountService } from '@data/services/account/account.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DiscoveryQuestionsDefaultTranslations } from './discovery-questions.translation';
import { takeUntil } from 'rxjs/operators';
import { Model } from '@data/services/account/models/model.interface';
import { SolutionService } from '@data/services/solution/solution.service';
import { cloneDeep } from 'lodash';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { DiscoveryQuestion, Section } from '@shared/models/discovery-question.model';
import { DiscoveryAccountSolution } from '@shared/models/discovery-account-solution.model';

type DiscoveryType = {
  id: number;
  name: string;
  description: string;
};

export class Question implements DiscoveryQuestion {
  name: string;
  type?: string;
  tag?: string;
  description: string;
  required: string;
  account_id: string;
  created: Date;
  id: string;
  modified: Date;
  question_type_id: string;
  tag_type_id: string;
  question_tag: string;
  placeholder_text: string;
  sections: Section[];
  requiredBool?: boolean;
  selected_models?: Model[];
  selected_sections?: QuestionSection[];
  default_active?: string;
  account_solutions?: DiscoveryAccountSolution[];
}

export class QuestionSection {
  id: string;
  name: string;
  label?: string;
}

@Component({
  selector: 'app-admin-discovery-questions',
  templateUrl: './discovery-questions.component.html',
  styleUrls: ['discovery-questions.component.scss'],
  providers: [CompanyService],
})
export class AdminDiscoveryQuestionsComponent implements OnInit, OnDestroy {
  questions: Question[] = [];
  showAdd: boolean = false;
  question: Question = new Question();
  showActionLoader: boolean = false;
  account_id: string;
  showTranslate = false;
  ngUnsubscribe = new Subject<boolean>();
  typesList: DiscoveryType[];
  selectedType: DiscoveryType;
  breadcrumbKeyValues: Breadcrumbs;
  discoveryQuestionDefaultSections: QuestionSection[] = [
    {
      id: '37',
      name: 'Review Results',
      label: 'Include in Review Results (Step 5)',
    },
  ];

  discoveryQuestionSections: QuestionSection[] = [
    {
      id: '31',
      name: 'Define Scenario',
      label: 'Include in Define Scenario (Step 1)',
    },
    {
      id: '38',
      name: 'Review Benefits',
      label: 'Include in Review Benefits (Step 2)',
    },
    {
      id: '39',
      name: 'Provide Numbers',
      label: 'Include in Provide numbers (Step 3)',
    },
    {
      id: '40',
      name: 'Add Costs',
      label: 'Include in Add Costs (Step 4)',
    },
    {
      id: '37',
      name: 'Review Results',
      label: 'Include in Review Results (Step 5)',
    },
    {
      id: '42',
      name: 'Value Realization',
      label: 'Include in Value Realization',
    },
  ];
  availableModels: Model[];
  selectedModels: Model[];
  customerCollaboration = this.CommonService.checkFeature(149);

  constructor(
    private CommonService: CommonService,
    private AccountService: AccountService,
    private NotificationService: NotificationService,
    public trans: DiscoveryQuestionsDefaultTranslations,
    private translationService: TranslationsV2Service,
    private solutionService: SolutionService
  ) {}

  ngOnInit() {
    this.account_id = sessionStorage.getItem('aid');
    this.funcGetDiscoveryQuestions();

    this.getTranslations();
    this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.CommonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    if ( this.customerCollaboration ) {
      this.discoveryQuestionSections.push({
        id: '46',
        name: 'Customer Collaboration',
        label: 'Include in Customer Collaboration',
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);
          this.breadcrumbKeyValues = {
            [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
            [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
            [this.trans.trans.manage_discovery_questions.value]: { routerLink: '', active: true },
          };
        },
        () => {
          this.typesList = [];
        }
      );
  }

  funcGetDiscoveryQuestions() {
    this.AccountService.getDiscoveryQuestions(this.account_id).subscribe((response) => {
      if (response.result) {
        this.questions = response.result;
      } else {
        this.questions = [];
      }
      this.getModelsList();
    });
  }

  getModelsList(): void {
    let searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc';
    this.solutionService.getAvailableSolutions(searchLimit).subscribe((response) => {
      if (response.result) {
        this.availableModels = response.result.data;
        if (this.question) {
          switch (this.question.default_active) {
            case '0':
              this.selectedModels = [];
              break;
            case '1':
              this.selectedModels = this.availableModels;
              break;
            default:
              this.selectedModels = this.question.account_solutions
                ? this.availableModels.filter((model) => this.question.account_solutions.map((solution) => solution.account_solution_id).includes(model.id))
                : [];
              break;
          }
        }
        this.setModelAndSections();
      }
    });
  }

  setModelAndSections(): void {
    if (this.questions.length && this.availableModels) {
      this.questions.forEach((q) => {
        q.requiredBool = q.required === '1';
        q.selected_models = q.account_solutions ? this.availableModels.filter((m) => q.account_solutions.map((solution) => solution.account_solution_id).includes(m.id)) : [];

        if (q.sections) {
          q.selected_sections = [];
          q.sections.forEach((section) => {
            const s = this.discoveryQuestionSections.find((s) => s.id === section.section_type_id);
            if (s) {
              q.selected_sections.push(s);
            }
          });
          q.selected_sections.sort((a, b) => this.discoveryQuestionSections.indexOf(a) - this.discoveryQuestionSections.indexOf(b));
        }
      });
    }
  }

  funcGetQuestionTypeText(id) {
    switch (id) {
      case '1':
        return this.trans.trans.type_fld1.value;
      case '2':
        return this.trans.trans.type_fld2.value;
      case '3':
        return this.trans.trans.type_fld3.value;
    }
    return '';
  }

  funcProcessResponse(result: { error: boolean; message?: string } | number) {
    if ((result && typeof result === 'object' && !result.error) || typeof result !== 'object') {
      const message = this.question.id ? this.trans.trans.question_updated_success : this.trans.trans.question_created_success;
      this.NotificationService.success(message.value, false);

      this.question = new Question();
      this.showAdd = !this.showAdd;
      this.funcGetDiscoveryQuestions();
    } else {
      const errorMessage = this.question.id ? this.trans.trans.question_updated_failure : this.trans.trans.question_created_failure;
      this.NotificationService.error(result ? result.message || errorMessage : errorMessage, false);
    }

    this.showActionLoader = false;
  }

  funcUpdateQuestion(question) {
    this.showActionLoader = true;
    let q = question.controls;

    let payload = {
      name: q.name.value,
      description: q.description.value,
      required: q.required.value == true ? 1 : '0',
      tag_type_id: this.question.tag_type_id,
      question_tag: q.tag.value,
      question_type_id: String(this.selectedType.id),
      placeholder_text: String(this.selectedType.id) === '3' ? '' : q.answer.value,
      default_active: this.availableModels.every((model) => this.selectedModels.map((model) => model.id).includes(model.id))
        ? 1
        : this.availableModels.some((model) => this.selectedModels.map((model) => model.id).includes(model.id))
        ? null
        : 0,
      account_solutions:
        !this.availableModels.every((model) => this.selectedModels.map((model) => model.id).includes(model.id)) &&
        this.availableModels.some((model) => this.selectedModels.map((model) => model.id).includes(model.id))
          ? this.selectedModels
          : [],
      sections: q.placement.value,
    };

    if (this.question.id) {
      payload['account_discovery_question_id'] = this.question.id;
      this.AccountService.updateDiscoveryQuestion(this.account_id, payload).subscribe((response) => {
        this.funcProcessResponse(response.result);
      });
    } else {
      this.AccountService.createDiscoveryQuestion(this.account_id, payload).subscribe((response) => {
        this.funcProcessResponse(response.result);
      });
    }
  }

  funcEditQuestion(question) {
    this.question = cloneDeep(question);
    this.getModelsList();
    this.loadQuestionType();
    this.showAdd = !this.showAdd;
  }

  funcAddQuestion() {
    this.question = new Question();
    this.question.selected_sections = this.discoveryQuestionDefaultSections;
    this.getModelsList();
    this.loadQuestionType();
    this.showAdd = !this.showAdd;
  }

  funcDeleteQuestion(question: Question) {
    this.AccountService.deleteDiscoveryQuestion(this.account_id, question.id).subscribe((res) => {
      this.funcGetDiscoveryQuestions();
      if (res.result) {
        this.NotificationService.success('Question deleted successfully', false);
      }
    });
  }

  private loadQuestionType() {
    this.typesList = [
      {
        id: 1,
        name: String(this.trans.trans.type_fld1.value),
        description: '',
      },
      {
        id: 2,
        name: this.trans.trans.type_fld2.value,
        description: '',
      },
      {
        id: 3,
        name: this.trans.trans.type_fld3.value,
        description: '',
      },
    ];
    if (this.question.question_type_id) {
      this.selectedType = this.typesList.find((type) => String(type.id) === this.question.question_type_id);
    } else {
      this.selectedType = this.typesList[0];
    }
  }

  cancelAdd(): void {
    this.showAdd = false;
  }
}
