<form [formGroup]="promoteForm">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.promoteRealization.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.promoteRealization"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>

    <div class="sidebar_container">
      <div class="keywest_panel">

          <div class="row">
            <div class="col-xs-12">
              <app-error-input-wrapper [showOnTouched]="true" [errorMessage]="errorDict.get('realizationName')">
                <label class="sidebar_label labelsRequired">{{ trans.trans.name.value }}</label>
                <input name="realizationName" type="text" class="form-control" formControlName="realizationName" />
              </app-error-input-wrapper>
            </div>
          </div>
          <div class="row" *ngIf="this.userData | functionPipe: userHasPermission">
            <div class="col-xs-12">
              <p-calendar formControlName="customDate" name="customDate" [showIcon]="true" [placeholder]="trans.trans.contractDate.value" dateFormat="yy-mm-dd"></p-calendar>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-xs-12">
              <app-error-input-wrapper [showOnTouched]="true" [errorMessage]="errorDict.get('user')">
                <label class="sidebar_label labelsRequired">{{ trans.trans.selectCustomerSuccess.value }} </label>
                <p-dropdown
                appendTo="body"
                class="keywest_dropdown"
                ret-data="User"
                [autoDisplayFirst]="false"
                optionLabel="name"
                [options]="users"
                name="scale_type_id"
                formControlName="user"
                [filter]="true"
                filterBy="name"
              ></p-dropdown>
              </app-error-input-wrapper>

            </div>
          </div>

      </div>
    </div>
    <div [ngClass]="{ row: crm, btn_container: !crm }">
      <div class="col-md-12">
        <button (click)="saveToRealization()" [disabled]="promoteForm.invalid || isLoading" class="btn btn_customizable pull-right">{{trans.trans.save.value}}</button>

        <app-are-you-sure
          matTooltipPosition="above"
          [message]="trans.trans.are_you_sure.value"
          class="btn cancel-confirm pull-right marg-h"
          icon=""
          [text]="trans.trans.back.value"
          (callbackYes)="close()"
        ></app-are-you-sure>
      </div>

    </div>
    <p-progressBar *ngIf="isLoading" mode="indeterminate"></p-progressBar>
  </div>
</form>
