import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { Subject, Observable, Subscription } from 'rxjs';

import { GuidedTour, Orientation } from 'ngx-guided-tour';
import { GuidedTourService } from 'ngx-guided-tour';

import { UserService } from '@data/services/user/user.service';
import { UserAdoptionsReportTranslations } from './adoption-reports.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { StyleService } from 'app/style.service';
import { UserData, ExcludedUser } from '@shared/models/user-info.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';

@Component({
  selector: 'app-adoption-reports',
  styleUrls: ['./adoption-reports.component.scss'],
  templateUrl: './adoption-reports.component.html',
  providers: [FactsService],
})
export class AdoptionReportsComponent implements OnInit, OnDestroy {
  account_id: string;
  excludedUsersList: ExcludedUser[] = [];
  userList: UserData[] = [];
  selectedExcludedList: ExcludedUser[];
  showExclude: boolean = false;
  filteredUserList: UserData[] = [];
  privilegeOption: string;
  options: string[];
  featuresOptions: string;
  featuresopt: string[];
  showDownloads: boolean = false;
  btn1Title = '';
  btn1URL = '';
  dteStart = '';
  dteEnd = '';
  data: reportData = new reportData();
  chart_colors: string[] = [];
  dark_chart_colors = [
    '#e53935',
    '#d81b60',
    '#8e24aa',
    '#5e35b1',
    '#3949ab',
    '#1e88e5',
    '#039be5',
    '#00acc1',
    '#00897b',
    '#43a047',
    '#7cb342',
    '#c0ca33',
    '#fdd835',
    '#ffb300',
    '#fb8c00',
    '#f4511e',
    '#6d4c41',
    '#757575',
    '#546e7a',
  ];
  light_chart_colors = [
    '#ffcdd2',
    '#f8bbd0',
    '#e1bee7',
    '#d1c4e9',
    '#c5cae9',
    '#bbdefb',
    '#b3e5fc',
    '#b2ebf2',
    '#b2dfdb',
    '#c8e6c9',
    '#dcedc8',
    '#f0f4c3',
    '#fff9c4',
    '#ffecb3',
    '#ffe0b2',
    '#ffccbc',
    '#d7ccc8',
    '#f5f5f5',
    '#cfd8dc',
  ];
  medium_chart_colors = [
    '#e57373',
    '#f06292',
    '#ba68c8',
    '#9575cd',
    '#7986cb',
    '#64b5f6',
    '#4fc3f7',
    '#4dd0e1',
    '#4db6ac',
    '#81c784',
    '#aed581',
    '#dce775',
    '#fff176',
    '#ffd54f',
    '#ffb74d',
    '#ff8a65',
    '#a1887f',
    '#e0e0e0',
    '#90a4ae',
  ];
  system_chart_colors: string[] = [];
  panels: ChartColorOption[] = [];
  selectedPanels: string[] = [];
  selectedChartColor = '';
  subscription: Subscription;
  loaded: boolean = false;
  ChartColorOptions: ChartColorOption[] = [];
  ngUnsubscribe = new Subject<boolean>();
  showTranslate = false;
  style2022$: Observable<boolean>;
  breadcrumbKeyValues: Breadcrumbs;

  constructor(
    private guidedTourService: GuidedTourService,
    private AccountService: AccountService,
    private CommonService: CommonService,
    private UserService: UserService,
    private translationService: TranslationsV2Service,
    public trans: UserAdoptionsReportTranslations,
    public styleService: StyleService
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.funcGetExcludedUsers();
    this.funcGetUserList();

    this.getTranslations();
    this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    if (localStorage.getItem('AdoptionReports')) {
      this.selectedPanels = JSON.parse(localStorage.getItem('AdoptionReports'));
    } else {
      this.selectedPanels = [
        'noLogin',
        'topCustomerValue',
        'top_acct_value',
        'top_delivered',
        'noVPs',
        'top_creators',
        'login_by_month',
        'vp_modified_by_month',
        'vp_created_by_month',
      ];
    }

    this.system_chart_colors = JSON.parse(sessionStorage.getItem('chart_colors'));
    if (localStorage.getItem('AdoptionReportsChartColor')) {
      this.selectedChartColor = localStorage.getItem('AdoptionReportsChartColor');
    } else {
      this.selectedChartColor = 'system';
    }

    this.funcSetChartColors();

    this.privilegeOption = sessionStorage.getItem('privileges');
    this.options = this.privilegeOption.split(',');

    if (this.options.indexOf('1') >= 0) {
      this.showDownloads = true;
      let uid = sessionStorage.getItem('uid');
      let vcu = sessionStorage.getItem('vcu');

      let url = this.CommonService.getHostName();
      this.btn1URL = url + '/valueprops/reportRedirect.php?user_id=' + uid + '&token=' + vcu;

      this.btn1Title = 'value_prop';
      this.featuresOptions = sessionStorage.getItem('features');
      this.featuresopt = this.featuresOptions.split(',');
    }
    this.account_id = sessionStorage.getItem('aid');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.adoptionReort.value]: { routerLink: '', active: true },
        };

        this.panels = [
          { label: this.trans.trans.no_logins.value, value: 'noLogin' },
          { label: this.trans.trans.top_customer_value.value, value: 'topCustomerValue' },
          { label: this.trans.trans.top_account_value.value, value: 'top_acct_value' },
          { label: this.trans.trans.top_delivered.value, value: 'top_delivered' },
          { label: this.trans.trans.no_value_props.value, value: 'noVPs' },
          { label: this.trans.trans.top_creators.value, value: 'top_creators' },
          { label: this.trans.trans.logins_by_month.value, value: 'login_by_month' },
          { label: this.trans.trans.vps_modified_by_month.value, value: 'vp_modified_by_month' },
          { label: this.trans.trans.vps_created_by_month.value, value: 'vp_created_by_month' },
        ];

        this.ChartColorOptions = [
          { label: this.trans.trans.systemColors.value, value: 'system' },
          { label: this.trans.trans.lightColors.value, value: 'light' },
          { label: this.trans.trans.mediumColors.value, value: 'medium' },
          { label: this.trans.trans.darkColors.value, value: 'dark' },
        ];
      });
  }

  funcExclude(event) {
    let out = this.userList.filter((x) => x.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1);

    this.filteredUserList = out;
  }

  updateExcludeList() {
    let out = [];
    for (let i = 0; i < this.selectedExcludedList.length; i++) {
      let user = this.selectedExcludedList[i].id;
      out.push(user);
    }

    let payload = { users: out };
    this.AccountService.postExcludeUsers(payload).subscribe(() => {
      this.funcGetAdoptionReport();
      this.showExclude = false;
    });
  }

  funcSetChartColors() {
    this.loaded = false;
    if (this.selectedChartColor) {
      switch (this.selectedChartColor) {
        case 'system':
          this.chart_colors = this.system_chart_colors;
          break;
        case 'light':
          this.chart_colors = this.light_chart_colors;
          break;
        case 'medium':
          this.chart_colors = this.medium_chart_colors;
          break;
        case 'dark':
          this.chart_colors = this.dark_chart_colors;
          break;
      }
    }
    localStorage.setItem('AdoptionReportsChartColor', this.selectedChartColor);
    this.funcGetAdoptionReport();
  }

  public dashboardTour: GuidedTour = {
    tourId: 'dashboard-tour',
    useOrb: false,
    steps: [
      {
        title: this.trans.trans.tourTitleWelcome.value,
        content: this.trans.trans.tourContentWelcome.value,
        orientation: Orientation.Center,
      },
      {
        title: this.trans.trans.tourTitleShowHide.value,
        content: this.trans.trans.tourContentShowHide.value,
        selector: '#tourShowHidePanels',
        orientation: Orientation.Bottom,
      },
      {
        title: this.trans.trans.tourTitleFilter.value,
        content: this.trans.trans.tourContentCalendar.value,
        selector: '#tourDates',
        orientation: Orientation.Bottom,
      },
      {
        title: this.trans.trans.tourTitleExpandCollapse.value,
        content: this.trans.trans.tourContentExpandCollapse.value,
        selector: '.tourExpandCollapse',
        orientation: Orientation.Bottom,
      },
      {
        title: this.trans.trans.tourTitleChangeChart.value,
        content: this.trans.trans.tourContentChangeChart.value,
        selector: '#tourChartType',
        orientation: Orientation.Bottom,
      },
      {
        title: this.trans.trans.tourTitleData.value,
        content: this.trans.trans.tourContentDataToggle.value,
        selector: '#tourChartToggle',
        orientation: Orientation.Bottom,
      },
      {
        title: this.trans.trans.tourTitleExport.value,
        content: this.trans.trans.tourContentExport.value,
        selector: '#tourExportTable',
        orientation: Orientation.Bottom,
      },
      {
        title: this.trans.trans.tourTitleExportSelection.value,
        content: this.trans.trans.tourContentSelectRows.value,
        selector: '#tourExportSelection',
        orientation: Orientation.Bottom,
      },
      {
        title: this.trans.trans.tourTitleFinished.value,
        content: this.trans.trans.tourContentFinished.value,
        orientation: Orientation.Center,
      },
    ],
  };

  startTour() {
    this.guidedTourService.startTour(this.dashboardTour);
  }

  multiSelectChanged() {
    localStorage.setItem('AdoptionReports', JSON.stringify(this.selectedPanels));
  }

  getMonth(monthStr) {
    let out = (new Date(monthStr + ' 1, 2012').getMonth() + 1).toString();
    if (out.length == 1) {
      out = 0 + out;
    }
    return out;
  }

  funcGetAdoptionReport() {
    this.account_id = sessionStorage.getItem('aid');
    this.loaded = false;
    let out: string[] = [];
    let dteStartOut;
    if (this.dteStart) {
      out = this.dteStart.toString().split(' ');
      dteStartOut = out[3] + '-' + this.getMonth(out[1]) + '-' + out[2] + ' ' + out[4];
    } else {
      dteStartOut = '';
    }

    out = [];
    let dteEndOut;
    if (this.dteEnd) {
      out = this.dteEnd.toString().split(' ');
      dteEndOut = out[3] + '-' + this.getMonth(out[1]) + '-' + out[2] + ' ' + out[4];
    } else {
      dteEndOut = '';
    }

    let payload = {
      start_date: dteStartOut ? dteStartOut : '',
      end_date: dteEndOut ? dteEndOut : '',
    };

    this.AccountService.postGetAdoptionReport(this.account_id, payload).subscribe((res) => {
      if (res) {
        this.funcBuildData(res);
      }
    });
  }

  funcBuildData(res) {
    this.data = new reportData();
    if (res.result && res.result.no_logins) {
      this.processNoLogins(res.result.no_logins);
    }
    if (res.result && res.result.top_cust_value) {
      this.processTopCustomerValue(res.result.top_cust_value);
    }
    if (res.result && res.result.top_acct_value) {
      this.processTopAccountValue(res.result.top_acct_value);
    }
    if (res.result && res.result.top_delivered) {
      this.processTopDelivered(res.result.top_delivered);
    }
    if (res.result && res.result.no_vps) {
      this.processNoVPs(res.result.no_vps);
    }
    if (res.result && res.result.top_creators) {
      this.processTopCreators(res.result.top_creators);
    }
    if (res.result && res.result.logins_by_month) {
      this.processLoginsByMonth(res.result.logins_by_month);
    }
    if (res.result && res.result.vp_created_by_month) {
      this.processVPCreatedByMonth(res.result.vp_created_by_month);
    }
    if (res.result && res.result.vp_modified_by_month) {
      this.processVPModifiedByMonth(res.result.vp_modified_by_month);
    }
    this.loaded = true;
  }

  processNoLogins(data) {
    this.data.no_logins_cols = [
      { field: 'email', header: this.trans.trans.email.value },
      { field: 'first_name', header: this.trans.trans.first_name.value },
      { field: 'last_name', header: this.trans.trans.last_name.value },
      { field: 'username', header: this.trans.trans.username.value },
    ];
    this.data.no_logins_data = data;
  }

  processNoVPs(data) {
    this.data.no_vps_cols = [{ field: 'email', header: this.trans.trans.email.value }];
    this.data.no_vps_data = data;
  }

  processTopCustomerValue(data) {
    this.data.top_cust_value_cols = [
      { field: 'email', header: this.trans.trans.email.value },
      { field: 'first_name', header: this.trans.trans.first_name.value },
      { field: 'last_name', header: this.trans.trans.last_name.value },
      { field: 'total_benefits', header: this.trans.trans.total_benefits.value },
    ];
    this.data.top_cust_value_data = data;

    let labels = [];
    let dataset = [];
    let out_fmt = [];
    let out = [];
    let colors = [];
    let colorcount = 0;
    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].first_name + ' ' + data[i].last_name);
      out.push(data[i].total_benefits);
      out_fmt.push(this.funcCalcUnit(data[i].total_benefits));

      colors.push(this.chart_colors[colorcount]);
      colorcount++;
      if (colorcount > this.chart_colors.length) {
        colorcount = 0;
      }
    }

    dataset.push({ data: out, data_fmt: out_fmt, label: this.trans.trans.total_benefits.value, backgroundColor: colors });
    this.data.top_cust_value_chartdata = { labels: labels, datasets: dataset };
  }

  processTopDelivered(data) {
    this.data.top_delivered_cols = [
      { field: 'email', header: this.trans.trans.email.value },
      { field: 'first_name', header: this.trans.trans.first_name.value },
      { field: 'last_name', header: this.trans.trans.last_name.value },
      { field: 'count', header: this.trans.trans.total.value },
    ];
    this.data.top_delivered_data = data;

    let labels = [];
    let dataset = [];
    let out = [];
    let colors = [];
    let colorcount = 0;
    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].first_name + ' ' + data[i].last_name);
      out.push(data[i].count);

      colors.push(this.chart_colors[colorcount]);
      colorcount++;
      if (colorcount > this.chart_colors.length) {
        colorcount = 0;
      }
    }

    dataset.push({ data: out, backgroundColor: colors });
    this.data.top_delivered_chartdata = { labels: labels, datasets: dataset };
  }

  processTopAccountValue(data) {
    this.data.top_acct_value_cols = [
      { field: 'email', header: this.trans.trans.email.value },
      { field: 'first_name', header: this.trans.trans.first_name.value },
      { field: 'last_name', header: this.trans.trans.last_name.value },
      { field: 'name', header: this.trans.trans.name.value },
      { field: 'total_costs', header: this.trans.trans.total_costs.value },
    ];
    this.data.top_acct_value_data = data;

    let labels = [];
    let dataset = [];
    let out = [];
    let out_fmt = [];
    let colors = [];
    let colorcount = 0;
    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].name);

      out.push(data[i].total_costs);
      out_fmt.push(this.funcCalcUnit(data[i].total_costs));
      colors.push(this.chart_colors[colorcount]);
      colorcount++;
      if (colorcount > this.chart_colors.length) {
        colorcount = 0;
      }
    }

    dataset.push({ data: out, data_fmt: out_fmt, label: this.trans.trans.total_costs.value, backgroundColor: colors });
    this.data.top_acct_value_chartdata = { labels: labels, datasets: dataset };
  }

  processTopCreators(data) {
    if (!data) {
      return;
    }
    this.data.top_creators_cols = [
      { field: 'email', header: this.trans.trans.email.value },
      { field: 'first_name', header: this.trans.trans.first_name.value },
      { field: 'last_name', header: this.trans.trans.last_name.value },
      { field: 'username', header: this.trans.trans.username.value },
      { field: 'count', header: this.trans.trans.total.value },
    ];
    this.data.top_creators_data = data;

    let labels = [];
    let dataset = [];
    let out = [];
    let colors = [];
    let colorcount = 0;

    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].first_name + ' ' + data[i].last_name);
      out.push(data[i].count);
      colors.push(this.chart_colors[colorcount]);
      colorcount++;
      if (colorcount > this.chart_colors.length) {
        colorcount = 0;
      }
    }

    dataset.push({ data: out, label: this.trans.trans.total.value, backgroundColor: colors });
    this.data.top_creators_chartdata = { labels: labels, datasets: dataset };
  }

  processLoginsByMonth(data) {
    if (!data) {
      return;
    }
    this.data.login_by_month_cols = [
      { field: 'date', header: this.trans.trans.date.value },
      { field: 'count', header: this.trans.trans.count.value },
    ];
    let d = [];
    for (let i = 0; i < data.length; i++) {
      d.push({ date: data[i]['Month(a.date)'] + '-' + data[i]['Year(a.date)'], count: data[i].count });
    }
    data = d;
    this.data.login_by_month_data = d;

    let labels = [];
    let dataset = [];
    let out = [];
    let colors = [];
    let colorcount = 0;

    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].date);
      out.push(data[i].count);
      colors.push(this.chart_colors[colorcount]);
      colorcount++;
      if (colorcount > this.chart_colors.length) {
        colorcount = 0;
      }
    }

    dataset.push({ data: out, label: this.trans.trans.total.value, backgroundColor: colors });
    this.data.login_by_month_chartdata = { labels: labels, datasets: dataset };
  }

  processVPCreatedByMonth(data) {
    if (!data) {
      return;
    }
    this.data.vp_created_by_month_cols = [
      { field: 'date', header: this.trans.trans.date.value },
      { field: 'count', header: this.trans.trans.count.value },
    ];
    let d = [];
    for (let i = 0; i < data.length; i++) {
      d.push({ date: data[i]['Month(a.created)'] + '-' + data[i]['Year(a.created)'], count: data[i].count });
    }
    data = d;
    this.data.vp_created_by_month_data = d;

    let labels = [];
    let dataset = [];
    let out = [];
    let colors = [];
    let colorcount = 0;

    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].date);
      out.push(data[i].count);
      colors.push(this.chart_colors[colorcount]);
      colorcount++;
      if (colorcount > this.chart_colors.length) {
        colorcount = 0;
      }
    }

    dataset.push({ data: out, label: this.trans.trans.total.value, backgroundColor: colors });
    this.data.vp_created_by_month_chartdata = { labels: labels, datasets: dataset };
  }

  processVPModifiedByMonth(data) {
    if (!data) {
      return;
    }
    this.data.vp_modified_by_month_cols = [
      { field: 'date', header: this.trans.trans.date.value },
      { field: 'count', header: this.trans.trans.count.value },
    ];
    let d = [];
    for (let i = 0; i < data.length; i++) {
      d.push({ date: data[i]['Month(c.modified)'] + '-' + data[i]['Year(c.modified)'], count: data[i].count });
    }
    data = d;
    this.data.vp_modified_by_month_data = d;

    let labels = [];
    let dataset = [];
    let out = [];
    let out_fmt = [];

    let colors = [];
    let colorcount = 0;

    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].date);
      out.push(data[i].count);
      out_fmt.push(this.funcCalcUnit(data[i].count));
      colors.push(this.chart_colors[colorcount]);
      colorcount++;
      if (colorcount > this.chart_colors.length) {
        colorcount = 0;
      }
    }

    dataset.push({ data: out, data_fmt: out_fmt, label: this.trans.trans.total.value, backgroundColor: colors });
    this.data.vp_modified_by_month_chartdata = { labels: labels, datasets: dataset };
  }

  funcGetExcludedUsers() {
    this.AccountService.getExcludedUsers().subscribe((res) => {
      if (res.result) {
        this.excludedUsersList = res.result;
        for (let i = 0; i < this.excludedUsersList.length; i++) {
          this.excludedUsersList[i].name = this.excludedUsersList[i].first_name + ' ' + this.excludedUsersList[i].last_name;
        }
        this.selectedExcludedList = this.excludedUsersList;
      } else {
        this.excludedUsersList = [];
        this.selectedExcludedList = [];
      }
    });
  }

  funcGetUserList() {
    this.UserService.getAllUsersList().subscribe((res) => {
      this.userList = res.result.data;
    });
  }

  funcCalcUnit(value): string {
    let number = value;
    let numberLength = number.toString().length;
    let unit = '';

    if (numberLength >= 4 && numberLength <= 6) {
      unit = 'K';
    } else if (numberLength >= 7 && numberLength <= 9) {
      unit = 'M';
    } else if (numberLength >= 10 && numberLength <= 13) {
      unit = 'B';
    } else if (numberLength >= 14 && numberLength <= 17) {
      unit = 'T';
    } else if (numberLength >= 18 && numberLength <= 21) {
      unit = 'Quad';
    } else if (numberLength >= 22 && numberLength <= 25) {
      unit = 'Quin';
    }

    switch (unit) {
      case 'K':
        return Math.round(number / 1000) + unit;
      case 'M':
        return Math.round(number / 1000000) + unit;
      case 'B':
        return Math.round(number / 1000000000) + unit;
      case 'T':
        return Math.round(number / 1000000000000) + unit;
      case 'Quan':
        return Math.round(number / 1000000000000000) + unit;
      case 'Quin':
        return Math.round(number / 1000000000000000000) + unit;
      default:
        return number;
    }
  }
}
export interface ColHeaderGroup {
  field: string;
  header: string;
}

export interface ChartColorOption {
  label: string;
  value: string;
}

export class reportData {
  no_logins_cols: ColHeaderGroup[];
  no_logins_data: ColHeaderGroup[];

  no_vps_cols: ColHeaderGroup[];
  no_vps_data: ColHeaderGroup[];

  top_cust_value_data: ColHeaderGroup[];
  top_cust_value_cols: ColHeaderGroup[];
  top_cust_value_chartdata: any;

  top_acct_value_data: ColHeaderGroup[];
  top_acct_value_cols: ColHeaderGroup[];
  top_acct_value_chartdata: any;

  top_delivered_data: ColHeaderGroup[];
  top_delivered_cols: ColHeaderGroup[];
  top_delivered_chartdata: any;

  top_creators_data: ColHeaderGroup[];
  top_creators_cols: ColHeaderGroup[];
  top_creators_chartdata: any;

  login_by_month_data: ColHeaderGroup[];
  login_by_month_cols: ColHeaderGroup[];
  login_by_month_chartdata: any;

  vp_created_by_month_data: ColHeaderGroup[];
  vp_created_by_month_cols: ColHeaderGroup[];
  vp_created_by_month_chartdata: any;

  vp_modified_by_month_data: ColHeaderGroup[];
  vp_modified_by_month_cols: ColHeaderGroup[];
  vp_modified_by_month_chartdata: any;
}
