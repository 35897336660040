<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <app-rsv2-sc-account [valueProp]="valueProp"></app-rsv2-sc-account>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <a *ngIf="guidedDiscoveryEnabled && workflows && workflows.length" class="link clickable" (click)="showGuidedDiscovery()"
      ><i class="fa fa-crosshairs"></i> {{ trans.trans.guidedDiscovery.value }}</a
    >
    <app-rsv2-sc-models (showNext)="showNext = $event" (reloadVP)="reload()" [valueProp]="valueProp"></app-rsv2-sc-models>
    <app-situations-shared [canEdit]="valueProp.vp_can_edit" (reloadVP)="reload()" [valueProp]="valueProp"></app-situations-shared>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <app-rsv2-sc-scalers #scalers (reloadVP)="reloadVP.emit()" [valueProp]="valueProp"></app-rsv2-sc-scalers>
    <app-rsv2-sc-advanced *ngIf="!feature40" (reloadVP)="reloadVP.emit()" [valueProp]="valueProp"></app-rsv2-sc-advanced>
  </div>
</div>

<app-value-prop-flow-buttons
  [cancel]="true"
  [back]="false"
  [disabled]="!showNext"
  [moveToBottom]="!crm"
  [next]="showContinue"
  [jumpToResults]="jumpToResultsFeature"
  (goNext)="nextStep()"
  (goBack)="back.emit()"
  (jumpResults)="nextStep($event)"
></app-value-prop-flow-buttons>

<p-sidebar styleClass="keywest_sidebar p-sidebar-md" position="right" *ngIf="guidedDiscoveryVisible" [(visible)]="guidedDiscoveryVisible">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.guidedDiscovery.value }}
    </div>

    <div class="sidebar_container">
      <app-guided-discovery (updateWorkflow)="closeGuided()" [valuePropId]="valueProp.id"></app-guided-discovery>
    </div>
  </div>
</p-sidebar>
