<h1 class="mr-0 breadcrumb-container">
  <ng-container *ngFor="let navigationItem of breadcrumbKeyValues | keyvalue: keepOrder">
      <ng-container  *ngIf="!navigationItem.value.active; else active">
          <span class="breadcrumb-title clickable" [routerLink]="navigationItem.value.routerLink">
            {{navigationItem.key}}
          </span>
          <span class="breadcrumb-title marg-h pi pi-chevron-right chevron"></span>
      </ng-container>
      <ng-template #active>
          <span class="breadcrumb-title-active">
              {{navigationItem.key}}
          </span>
      </ng-template>
  </ng-container>
</h1>