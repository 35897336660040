import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditNameComponent } from './edit-name/edit-name.component';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [EditNameComponent],
  exports: [EditNameComponent],
  imports: [CommonModule, FormsModule, MatTooltipModule],
})
export class EditNameModule {}
