import { Injectable } from '@angular/core';

@Injectable()
export class AESectionsTranslations {
	public config: any = {
		component: 'addEditSections',
		display_name: 'Sections Form',
		description: 'Form for creating and editing a section'
	};

	public trans: any = {
		addSection: 'Add Section',
		editSection: 'Edit Section',
		section_type: 'Section Type',
		section_description: 'Description',
		choose_section_type: 'Choose Section Type',
		deleteExisting: 'You must delete an existing section before creating a new one or edit a current',
		add_section_type: 'Add Section Type',
		section_type_name: 'Section Type Name',
		section_type_description: 'Section Type Description',
		save: 'Save',
		update: 'Update',
		mediaType: 'Media Type',
		selectVideoImage: 'Please select Video URL or Image to add an asset to this section',
		image: 'Image',
		videoUrl: 'Video URL',
		media: 'Media',
		media_description: 'Media Description',
		addMedia: 'Add Media',
		addMediaButton: 'Add Media',
		editMedia: 'Edit Media',
		updateMedia: 'Update Media',
		provideComplete: 'Please provide the complete url to the video',
		edit_translations: 'Edit Translations',
		edit: 'Edit',
		delete: 'Delete',
		areYouSure: 'Are You Sure?',
		back: 'Back'
	};
}
