import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RCollateralTranslations } from './r_collateral.translation';
import { ValuePropCollateral } from '@shared/models/collateral.model';

@Component({
	selector: 'app-r-collateral',
	templateUrl: './r_collateral.component.html',
	providers: [FactsService]
})
export class RCollateralComponent implements OnInit, OnDestroy {
	@Input() showHelp;
	@Input() can_edit: boolean = false;
	@Input() contextualHelp;
	@Input() solutionID: number;
	@Input() valuePropId: number;
	@Input() tabInit: number;
	@Input() showTranslate: boolean = false;
	@Input() collateralFormObj: Partial<ValuePropCollateral> = {
		id: null,
		format_type_id: null,
		name: null,
		description: null,
		url: null
	};

	ngUnsubscribe = new Subject();
	selectedItem: any[] = [];

	collateral: any = [];
	image_url: string;
	fullImagePath: string;
	fileFormatTypes: any = [];
	accountSolutionId: number;
	collateralId: any;
	// Contextual Help
	// contextualHelp: object = {};
	typeOfCollateral: any;
	hideActionLoader: boolean = true;

	@ViewChild('formAddEditCollateral') formAddEditCollateral: NgForm;



	// new
	addEditCollateral: any;
	loadingSave: boolean = false;
	toggleAddCollateral: boolean = false;
	dropdown: any[] = [];
	@Input() sidebar_title: string = "Add New Collateral";

	strSearch = '';

	cols = [
		{ field: 'format', header: 'Format' },
		{ field: 'name', header: 'Name' },
		{ field: 'url', header: 'URL' },
		{ field: 'url', header: 'Default Active' }
	]

	constructor(
		private route: ActivatedRoute,
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: RCollateralTranslations

	) {


		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
		// this.sidebar_title = "View Collateral";
		this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => this.accountSolutionId = params['id']);

		if (!this.accountSolutionId) {
			this.accountSolutionId = this.solutionID;
		}

	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}


	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

}
