
<div *ngIf="SurveyLoader">
	<form #takeSurvey="ngForm">
		<div class="panel panel-white">
			<div class="panel-heading">
				<div class="panel-title">
					<h4>{{'realization.surveys' | translate}}</h4>
				</div>
			</div>
			<div class="panel-body">
				<div class="dynamicQuestionare">
					<div class="row">
						<div class="rowBlock" *ngFor="let question of activeSurveyTemplate.questions_list; let i = index">
							<div class="clearfix" *ngIf="(i == 0 || i % 2 == 0)"></div>
							<div class="col-md-6">
								<div class="questionBlock" [ngClass]="'question' + question.id">
									<div class="row">
										<div class="col-md-10">
											<p>{{i+1}}. {{question.label}}</p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-offset-1 col-md-5 p-l-0">
											<div class="form-group" *ngIf="question.question_type_id == '1'">
												<input type="text" class="form-control" placeholder="Answer" [(ngModel)]="question.answer" name="question{{i}}" />
											</div>
											<div class="form-group" *ngIf="question.question_type_id == '3'">
												<label class="switch">
													<div class="checker">
														<input type="checkbox" class="customcheckbox" [(ngModel)]="question.answer" name="question{{i}}" />
													</div>
													<span>{{question.answer ? ('general.Yes' | translate) : ('general.No' | translate)}}</span>
												</label>
											</div>
											<div class="form-group" *ngIf="question.question_type_id == '4'">
												<textarea rows="1" class="form-control" [(ngModel)]="question.answer" name="question{{i}}"></textarea>
											</div>
											<div class="form-group" *ngIf="question.question_type_id == '2'">
												<div class="createOptions">
													<div class="optionGroup" *ngFor="let option of question.question_options; let j = index">
														<input type="checkbox" class="customcheckbox" name="question{{i}}{{j}}"
														 [(ngModel)]="option.checked" />
														<span>&nbsp; {{option.name}}</span>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="panel panel-white">
			<div class="panel-heading">
				<div class="panel-title clearfix">
					<h4 class="pull-left">{{'create-survey.benefit_performance' | translate}}</h4>
					<div class="performanceOption pull-left">
						<label *ngFor="let option of benefitPerformanceType">
							<input type="radio" [value]="option.id" name="performanceOption" [(ngModel)]="benefitPerformanceTypeSel" />
							<span>{{option.name}}</span>
						</label>
					</div>
				</div>
			</div>
			<div class="panel-body">
				<div class="benefitPerformance">
					<div class="benefits" *ngFor="let benefit of surveyDetails.value_survey_metrics">
						<div class="row">
							<div class="col-md-3">
								<p>{{benefit.metric_name}}</p>
							</div>
							<div class="col-md-6" *ngIf="benefitPerformanceTypeSel != 2">
								<input type="range"  min="0" max="200" name="PerformanceValue{{benefit.id}}"name="PerformanceValue{{benefit.id}}" [(ngModel)]="benefit.value_survey_improvement">
							</div>
							<div class="col-md-1">
								<input type="text" name="PerformanceValue{{benefit.id}}" [(ngModel)]="benefit.value_survey_improvement" />
							</div>
						</div>
					</div>
				</div>
				<div class="text-center" *ngIf="!surveyDetails.value_survey_metrics">
					<div class="well">
						{{'create-survey.no_benefit_performance_found' | translate}}
					</div>
				</div>
			</div>
		</div>
		<div class="button-group pull-right">
			<img *ngIf="actionLoader" class="actionLoader" src="{{fullImagePath}}" />
			<button class="btn btn-primary" (click)="saveSurvey()">{{'general.save' | translate}}</button>
		</div>
	</form>
</div>

<div *ngIf="!SurveyLoader" class="col-12 text-center">
	<img class="actionLoader" src="{{fullImagePath}}" />
</div>