import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { StarRatingComponent } from './rating.component';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressBarModule } from 'primeng/progressbar';


export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		ProgressBarModule,
		CommonModule,
		FormsModule,
		NgbModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	],
	declarations: [
		StarRatingComponent
	],
	exports: [StarRatingComponent]
})
export class BenefitsRatingModule { }
