<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>Benefits</div>
				</div>
			</div>
		</div>

		<app-benefit-menu *ngIf="!bI.benefitsLoader"></app-benefit-menu>

		<div *ngIf="bI.benefitsLoader" style='margin-top: 10px;'>
			<p-progressBar mode="indeterminate"></p-progressBar>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<div *ngIf="!bI.benefitsLoader"
                id="drag-container"
                cdkDropList
                (cdkDropListDropped)="drop($event, bI.benefitsList)"
                [cdkDropListDisabled]="bI.locked || !canEdit || bI.group_selected !== 'value_category'" 
                style='margin-top: 10px;'>
					<div *ngFor="let benefitCategory of bI.benefitsList; let b = index" cdkDrag [cdkDragData]="benefitCategory">
						<!-- {{benefitCategory | json}} -->
						<div class='panel panel-white dragObjects' *ngIf="bI.showGroup(benefitCategory.metrics);">
							<div class="panel-heading vwad-benefit-header simple-benefit-line">
								<div class='row'>
									<div class='col-xs-12 clickable' style='padding-right: 30px;'>
										<table style='table-layout:fixed;width:100%'>
											<tr>
												<td width='20px' class="vwad-benefit-tile" (click)='bI.expandCat(benefitCategory);'>
													<i class='fa fa-plus clickable' *ngIf="!benefitCategory.expand" style='margin-right: 5px;'></i>
													<i class='fa fa-minus clickable' *ngIf="benefitCategory.expand" style='margin-right: 5px;'></i>
												</td>
												<td width='100%' class="vwad-benefit-tile" (click)='bI.expandCat(benefitCategory);'>
													<span class='clickable'>
														{{benefitCategory.name}}

														<i class="fa fa-info-circle" *ngIf="benefitCategory.description && !(style2022$ | async)"  [matTooltip]="benefitCategory.description" matTooltipPosition="above" container="body"></i>
														<span *ngIf="benefitCategory.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="benefitCategory.description" matTooltipPosition="above" container="body">
															info
													</span>
													</span><br>
													<small class="vwad-benefit-bold vwad-benefit-size" style='color: green; margin: 5px;'>{{benefitCategory.active_count}} {{trans.vp_dashboard.benefits.active | translate }}</small>
													<app-editTranslation *ngIf="bI.showTranslate" (callback)='bI.refreshTranslation();' key='vp_dashboard.benefits.active'></app-editTranslation>
													<small class="vwad-benefit-bold vwad-benefit-size" style='color: red; margin: 5px;'>{{benefitCategory.na_count}} {{trans.vp_dashboard.benefits.na | translate }}</small>
													<app-editTranslation *ngIf="bI.showTranslate" (callback)='bI.refreshTranslation();' key='vp_dashboard.benefits.na'></app-editTranslation>
													<ng-container *ngIf="!bI.feature39 && !bI.feature46">
														<small class="vwad-benefit-bold vwad-benefit-size" style='color: orange; margin: 5px;'>{{benefitCategory.soft_count}} {{trans.vp_dashboard.benefits.soft | translate }}</small>
														<app-editTranslation *ngIf="bI.showTranslate" (callback)='bI.refreshTranslation();' key='vp_dashboard.benefits.soft'></app-editTranslation>
													</ng-container>
												</td>
												<td width='20px'>
													<div style='float: left;'>
														<i class='fa fa-chevron-circle-left clickable' (click)='benefitCategory.start = bI.rangeLeftArrow(benefitCategory.start, benefitCategory.term)' *ngIf="benefitCategory.term > bI.termShow && (benefitCategory.start !== 1)" style='color: green; font-weight: bold; float: left; margin-top: 15px;'></i>
													</div>
												</td>
												<td *ngFor="let year of benefitCategory.years | rangePipe: benefitCategory.start : bI.termLimit : bI.termShow; let i = index" width='100px'>
													<div class="vwad-benefit-bold vwad-benefit-size" [ngClass]="bI.alignYear(i)">
														{{year.value}}<br>
														<small class="vwad-benefit-bold vwad-benefit-size" style='color: #a5a5a5;'>{{year.label}}</small>
													</div>
												</td>
												<td width='20px'>
													<div style='float: right; text-align: right;'>
														<i class='fa fa-chevron-circle-right clickable' (click)='benefitCategory.start = bI.rangeRightArrow(benefitCategory.start, benefitCategory.term)' *ngIf="benefitCategory.term > bI.termShow && (benefitCategory.start !== benefitCategory.term - bI.termLimit)" style='color: green; font-weight: bold; margin-top: 15px;'></i>
													</div>
												</td>
												<td class="vwad-benefit-bold vwad-benefit-size" style='text-align: right;' width='90px'>
													{{benefitCategory.vc_total_fmt}}<br>
													<small style='color: #a5a5a5;'>{{trans.vp_dashboard.benefits.total | translate }}</small>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
							<div class="panel-body" *ngIf="benefitCategory.expand">
								<app-benefit-full [mini]='bI.mini' [locked]='bI.locked' [valueProp]='bI.valueProp' [index]='b' [benefitCategory]='benefitCategory'></app-benefit-full>
								<div *ngIf="bI.benefitsLoader" class="col-sm-12 m-t-md text-center">
									<p class="text-center">
										<img class="loader" src="{{bI.fullImagePath}}" />
									</p>
								</div>
								<!-- <div *ngIf="(bI.benefitsList == null || (bI.benefitsList && bI.benefitsList.length==0)) && !bI.benefitsLoader" class="m-t-md col-sm-12">
									<div class="searchTableContainer text-center">
										<h4>{{ 'props.tabDetails.benefits.NoBenefitsFound' | translate }}</h4>
									</div>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
