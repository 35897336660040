import { ValueProp } from '@shared/models/value-prop.model';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SimpleImplementation } from '../../../../simple.implementation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ScenarioCombinedQuestionsTranslation } from './sc_q2.translation';

@Component({
  selector: 'app-dashboard-sc-vpg-q2',
  templateUrl: './q2.component.html',
})
export class SCVPGQ2Component implements OnInit, OnDestroy {
  @Input() edit = false;
  @Input() valueProp: ValueProp;
  @Input() canEdit = false;
  @Output() callback = new EventEmitter();

  q2answer = '';
  toggleEdit = false;
  ngUnsubscribe = new Subject();
  saveLoading = false;
  showTranslate = false;

  constructor(
    private notificationService: NotificationService,
    private commonService: CommonService,
    private RS: RepSimpleViewService,
    public sI: SimpleImplementation,
    private valuepropService: ValuepropService,
    private translationService: TranslationsV2Service,
    public trans: ScenarioCombinedQuestionsTranslation
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    if (this.valueProp.q2_done === '1') {
      this.toggleEdit = true;
    }
    this.q2answer = this.valueProp.vmw_q_2;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcSaveQ2(): boolean {
    if (!this.q2answer) {
      return false;
    }

    this.saveLoading = true;
    const payload = { answer: this.q2answer };
    this.valuepropService.putVMWareQ2(this.valueProp.id ? this.valueProp.id : this.valueProp.id, payload).subscribe(() => {
      const payload2 = { q2_done: 1 };
      this.RS.putDone(this.valueProp.id, payload2)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.toggleEdit = !this.toggleEdit;
          this.valueProp.vmw_q_2 = this.q2answer;
          this.valueProp.q2_done = '1';
          this.saveLoading = false;
          this.callback.emit();
        });
      this.notificationService.success('Question saved Successfully', false);
    });
  }
}
