import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ValueTrackerAssetComponent } from './assets.component';

@NgModule({
  imports: [SidebarModule, CommonModule, TableModule, MatTooltipModule],
  exports: [ValueTrackerAssetComponent],
  declarations: [ValueTrackerAssetComponent],
  providers: [],
})
export class ValueTrackerAssetsModule {}
