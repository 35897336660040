import { Injectable } from '@angular/core';

@Injectable()
export class TrackerGoalChartTranslation {
    public config: any = {
        component: 'tracker-goal-chart',
        display_name: 'Tracker Goal Chart',
        description: 'Chart for Tracker Goals',
    };

    public trans: any = {
        editGoal: 'Edit Goal',
        saveButton: 'Save',
        backButton: 'Back',
        name: 'Name',
        description: 'Description',
        noSurveys: 'No surveys to show, please create a new survey.',
        surveyCol: 'Survey',
        dateCol: 'Effective Date',
        valueCol: 'Value',
        goalChart: 'Goal',
        surveyChart: 'Survey values',
    };
}
