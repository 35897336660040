<div class="row" style="padding-top: 25vh">
  <div class="center valueCloud-login">
    <div class="panel panel-white" id="js-alerts">
      <div>
        <!--<div class="text-center login-bg">
          <span>Welcome to</span> <img src="assets/images/xvp-white-transparent.png" alt="Value Execution by Xfactor.io" style="max-height: 42px" /></div>-->
        <div class="login-box">
          <div *ngIf="!isForgotPassword">
            <div class="text-center">
              <img src="assets/images/XF-Vector.png" alt="Value Execution by Xfactor.io" style="max-height: 45px; margin-top: 20px" />
            </div>
            <!--<div>
              <p class="login-title">Sign In</p>
              <p class="text-center login-sub-header">{{ trans.login.enter_your_email | translate }}</p>
            </div>-->
            <form class="m-t-md" name="LoginForm" [formGroup]="loginForm">
              <div *ngIf="invalidCredentials" style="margin-top: -25px" class="login-error-label login-error-label-active">
                {{ trans.login.incorrect_email_or_password | translate }}
              </div>
              <!-- <div class="login-label">{{ trans.general.email_address | translate }}</div> -->
              <div class="form-group">
                <input
                  type="email"
                  class="form-control login-input"
                  name="loginUsername"
                  [class.login-invalid-input]="loginForm.touched && loginForm.get('email').errors"
                  id="loginUsername"
                  formControlName="email"
                  placeholder="{{ trans.general.email_address | translate }}"
                  required="true"
                  [attr.aria-invalid]="loginForm.touched && loginForm.get('email').hasError('email')"
                />
              </div>
              <div [class.login-error-label-active]="loginForm.touched && loginForm.get('email').hasError('email')" style="margin-top: -12px" class="login-error-label">
                {{ trans.login.please_enter_valid_address | translate }}
              </div>
              <div class="form-group">
                <div class="login-password-container">
                  <!-- <div class="login-label">{{ trans.general.password | translate }}</div> -->
                  <a *ngIf="!loading" class="login-link" routerLink="/forgotpassword">{{ trans.login.forgot_your_password | translate }} </a>
                </div>
                <input
                  type="password"
                  class="form-control password login-input"
                  name="loginPassword"
                  id="passwordField"
                  [class.login-invalid-input]="loginForm.touched && loginForm.get('password').errors"
                  formControlName="password"
                  placeholder="{{ trans.general.password | translate  }}"
                  required="true"
                  [attr.aria-invalid]="loginForm.touched && loginForm.get('password').errors"
                />
              </div>
              <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
              <div class="login-btn-container">
                <button (click)="submitUserCredentials()" [disabled]="loginForm.invalid" *ngIf="!loading" type="submit" class="btn btn-success btn-block login-submit">
                  {{ trans.login.login | translate }}
                </button>
              </div>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
