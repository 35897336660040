import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebCalcGuard implements CanActivate {
  constructor(private commonService: CommonService, private router: Router) {}
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAllowed = this.commonService.checkPrivilege('1') || this.commonService.checkPrivilege('10');
    if (isAllowed) {
      return true;
    }
    this.router.navigateByUrl('/dashboard');
    return false;
  }
}
