import { Injectable } from '@angular/core';

@Injectable ()

export class BaselineBenefitScratchpadTranslation {

    public config: any = {
        component: 'baselineBenefitScratchpad',
        display_name: 'Baseline Benefit Scratchpad',
        description: 'Scratchpad view fore baseline benefits'
    };

    public trans: any = {
        calculatedValue: 'Calculated Value:',
        formulaHeader: '[Driver factor x Goal factor x Financial factor = value]',
        formula: 'Formula',
        missingGoalMessage: 'This benefit is not connected to a goal. Please contact your Model administrator to link a goal to this benefit to finish calculating the value realized here'
    };
}