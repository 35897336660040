import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'roiVpTop',
    display_name: 'ROI VP Dashboard',
    description: 'ROI tab in vp dashboard top panel'
  };

  public trans: any = {
    roi_key: 'Key Figures & ROI',
    keyfigures_key: 'Key Figures',
    potential_total_benefit: 'Potential Total Benefit',
    roi: 'ROI',
  };
}
