import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class AnalyticsTranslation extends TranslationsBaseService {

  public config: any = {
    component: 'analytics',
    display_name: 'Custom Analytics',
    description: 'Custom Analytics',
  };

  public trans: any = {
    settings: 'Settings',
    dashboard: 'Dashboard',
    analytics: 'Custom Analytics',
    back_to_profile: 'Back to Profile',
    contact_admin: 'Please reach out to your CSM for more information on custom reporting',
  };
}
