<div class="row" style="margin-bottom: 20px">
  <div class="col-xs-8">
    <div (click)="goToMasterValueProp()" class="createBack" *ngIf="masterValuePropName"><span class="fa fa-chevron-left" aria-hidden="true"></span> {{ masterValuePropName }}</div>
  </div>
  <div class="col-xs-4">
    <span *ngIf="isDev" (click)="navRepSimple()" class="link clickable pull-right" style="margin-left: 10px" (click)="funcSwitchDashboardView('v2')">{{
      trans.trans.switchToRepSimple.value
    }}</span>

    <span
      class="tourSwitchDashboard link clickable pull-right"
      *ngIf="dashboard == 'v1'"
      [ngbTooltip]="trans.trans.switch_full_width.value"
      container="body"
      placement="left"
      (click)="funcSwitchDashboardView('v2')"
      >{{ trans.trans.full_width.value }}</span
    >
    <span
      class="tourSwitchDashboard link clickable pull-right"
      *ngIf="dashboard == 'v2'"
      [ngbTooltip]="trans.trans.switch_old_width.value"
      container="body"
      placement="left"
      (click)="funcSwitchDashboardView('v1')"
      >{{ trans.trans.old_width.value }}</span
    >
  </div>
</div>

<p-sidebar [(visible)]="AssetsMenu" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.download.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row" *ngFor="let asset of menuAssets">
          <div class="col-xs-12">
            <b>{{ asset.label }}</b
            ><br />
            <a [href]="asset.url" target="_blank"><i [class]="asset.icon" [ngStyle]="getFAColor(asset.format)" style="margin-right: 10px"></i>{{ trans.trans.download.value }}</a>
            <a *ngIf="asset.showPDF" [href]="asset.pdf" target="_blank"
              ><i class="fa fa-file-pdf-o" style="color: red; margin-left: 20px; margin-right: 5px"></i>{{ trans.trans.download_pdf.value }}</a
            >
            <p>{{ asset.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <h2 style='margin-bottom: 10px;'>{{trans.trans.download.value}}</h2>
	<div style='height: 100%; display: block; padding-bottom: 50px;'>
		<div style=' overflow-y: scroll; height: 100%;'>
			<div style=' margin-bottom: 100px;' *ngFor="let asset of menuAssets">
				<div class='col-xs-12'>
					<a href='{{asset.url}}' target='_blank'><i class='{{asset.icon}}' [ngStyle]="getFAColor(asset.format)"></i> {{asset.label}}</a><br>
					<small style='font-size: 70%;'>{{asset.description}}</small>
				</div>
			</div>
		</div>
	</div> -->
</p-sidebar>

<p-sidebar [(visible)]="promoteToRealization" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-promote-realization [valueProp]="valueProp" (callback)="promoteToRealization = false"></app-promote-realization>
</p-sidebar>

<p-sidebar [(visible)]="showTranslateSidebar" position="right">
  <h2 style="margin-bottom: 10px">{{ trans.trans.translate_menus.value }}</h2>
  <div style="overflow-y: scroll; height: 100%; display: block; padding-bottom: 80px">
    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_roi.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_roi"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_cashflow.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_cashflow"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_notes.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_notes"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_logs.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_logs"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_scorecard.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_scorecard"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_share.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_share"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_scenario.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_scenario"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_account.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_account"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_models.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_models"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_situations.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_situations"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_advanced.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_advanced"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_benefits.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_benefits"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_view_benefits.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_view_benefits"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_add_benefit.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_add_benefit"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_costs.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_costs"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_discovery.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_discovery"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        <!-- Scalers -->
        {{ trans.trans.sidebar_scalers.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_scalers"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_all_assumptions.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_all_assumptions"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_key_assumptions.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_key_assumptions"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_initiatives.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_initiatives"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_mission_statement.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_mission_statement"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12">
        {{ trans.trans.sidebar_supporting_materials.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_supporting_materials"></app-editTranslation>
      </div>
    </div>

    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_assets.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_assets"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_case_studies.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_case_studies"></app-editTranslation>
      </div>
    </div>
    <div class="row trans_row">
      <div class="col-xs-12" style="margin-left: 40px">
        {{ trans.trans.sidebar_collateral.value }}
        <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="trans.sidebar_collateral"></app-editTranslation>
      </div>
    </div>
  </div>
</p-sidebar>

<div *ngIf="dashboard == 'v1' && valueProp" class="row">
  <div class="col-xs-6">
    <h2 *ngIf="!changeName" style="margin-top: 0px; font-size: 18px">
      {{ valueProp.name }}
      <a
        *ngIf="!changeName && valueProp.vp_can_edit && valueProp.closed !== 1"
        (click)="changeName = true"
        [ngbTooltip]="contextualHelp['Position 1']"
        container="body"
        placement="right"
        ><i style="margin-left: 10px" class="fa fa-pencil"></i
      ></a>
      <small *ngIf="valueProp.closed == 1"><br />{{ trans.trans.this_value_prop_is_locked.value }}</small>
    </h2>
    <div class="input-group">
      <input *ngIf="changeName" [(ngModel)]="valueProp.name" class="form-control" />
      <div class="input-group-btn">
        <button *ngIf="changeName" (click)="changeValuePropName(valueProp.id)" class="btn btn-success" ngToolTip="Save" container="body"><i class="fa fa-save"></i></button>
        <button *ngIf="changeName" (click)="cancelValuePropName()" class="btn btn-danger" ngToolTip="Cancel" container="body"><i class="fa fa-times"></i></button>
      </div>
    </div>
  </div>
  <div class="col-xs-6">
    <div class="pull-right">
      <i
        class="fa fa-refresh pull-right"
        [ngbTooltip]="trans.trans.refresh.value"
        container="body"
        placement="bottom"
        (click)="funcGlobalRefresh()"
        style="margin-right: 10px; font-size: 18px"
      ></i>
      <i
        class="fa fa-lightbulb-o pull-right"
        [ngbTooltip]="trans.trans.hide_help.value"
        container="body"
        placement="bottom"
        (click)="funcShowHelp()"
        style="margin-right: 10px; font-size: 18px; color: gold"
        *ngIf="showHelp"
      ></i>
      <i
        class="fa fa-lightbulb-o pull-right"
        [ngbTooltip]="trans.trans.show_help.value"
        container="body"
        placement="bottom"
        (click)="funcShowHelp()"
        style="margin-right: 10px; font-size: 18px"
        *ngIf="!showHelp"
      ></i>
      <!-- <i class="fa fa-exchange pull-right" [ngbTooltip]="trans.trans.edit_translations.value" container="body" placement="bottom" (click)='funcShowTranslate()' style='margin-right: 10px; font-size: 18px;' *ngIf="isAdmin && !showTranslate" aria-hidden="true"></i>
			<i class="fa fa-exchange pull-right" [ngbTooltip]="trans.trans.edit_translations.value" container="body" placement="bottom" (click)='funcShowTranslate()' style='margin-right: 10px; font-size: 18px; color: gold;' *ngIf="isAdmin && showTranslate" aria-hidden="true"></i> -->
      <i
        class="fa fa-cloud-download"
        [ngbTooltip]="trans.trans.download.value"
        container="body"
        placement="bottom"
        (click)="AssetsMenu = !AssetsMenu"
        style="font-size: 18px; margin-right: 10px"
      ></i>
      <i
        class="fa fa-check-circle-o"
        *ngIf="isRealizationUser"
        [ngbTooltip]="'Promote to Realization'"
        container="body"
        placement="bottom"
        (click)="promoteToRealization = true"
        style="font-size: 18px; margin-right: 10px"
      ></i>
      <app-value-prop-dashboard-actions (showShareCallback)="viewTop = 'share'" [valueProp]="valueProp"></app-value-prop-dashboard-actions>
    </div>
  </div>
</div>
<div class="row" *ngIf="dashboard == 'v1' && valueProp">
  <div class="col-xs-3">
    <p-progressBar *ngIf="valuePropLoader" mode="indeterminate"></p-progressBar>
    <app-value-prop-dashboard-key-figures
      [isDev]="isDev"
      [showTranslate]="showTranslate"
      [header_color]="header_color"
      *ngIf="valueProp"
      [dashboard]="dashboard"
      [showHelp]="showHelp"
      [contextualHelp]="contextualHelp"
      [valueProp]="valueProp"
    ></app-value-prop-dashboard-key-figures>
    <app-value-prop-dashboard-actions
      [showTranslate]="showTranslate"
      [header_color]="header_color"
      (showShareCallback)="viewTop = 'share'"
      *ngIf="valueProp"
      [showHelp]="showHelp"
      [valueProp]="valueProp"
      [dashboard]="dashboard"
    ></app-value-prop-dashboard-actions>
    <app-value-prop-dashboard-assets-panel
      [showTranslate]="showTranslate"
      [header_color]="header_color"
      *ngIf="valueProp"
      [dashboard]="dashboard"
      [showHelp]="showHelp"
      [valueProp]="valueProp"
    ></app-value-prop-dashboard-assets-panel>
  </div>
  <div class="col-xs-9">
    <app-panel-top
      [showTranslate]="showTranslate"
      [valueProp]="valueProp"
      [isDev]="isDev"
      [dashboard]="dashboard"
      [showHelp]="showHelp"
      [viewTop]="viewTop"
      [year1BreakDownChartData]="year1BreakDownChartData"
      [costBenefitChartData]="costBenefitChartData"
    ></app-panel-top>
    <app-panel-bottom
      *ngIf="loaded && viewBottom !== 'interstitial' && valueProp"
      [discoveryQuestions]="discoveryQuestions"
      [contextualHelp]="contextualHelp"
      [showTranslate]="showTranslate"
      [valueProp]="valueProp"
      [valuePropId]="valuePropId"
      [isDev]="isDev"
      [dashboard]="dashboard"
      [showHelp]="showHelp"
      [selectedBenefit]="selectedBenefit"
      [benefitDetail]="benefitDetail"
      (callbackExplained)="funcGotoExplain($event)"
      [explained]="explained"
      [hasKeyAssumptions]="hasKeyAssumptions"
      (CallbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
      [viewBottom]="viewBottom"
      [firstLoad]="firstLoad"
    >
    </app-panel-bottom>
  </div>
</div>

<p-sidebar [(visible)]="showVPGroupAdd" position="right" styleClass="p-sidebar-md">
  <h2 style="margin-bottom: 10px">{{ trans.trans.add_group.value }}</h2>
  <div class="input-group">
    <p-dropdown
      appendTo="body"
      *ngIf="showVPGroupAdd"
      [autoDisplayFirst]="false"
      optionLabel="name"
      [options]="VPGroupList"
      [filter]="true"
      [(ngModel)]="selectedGroup"
    ></p-dropdown>
    <div class="input-group-btn">
      <a *ngIf="showVPGroupAdd" (click)="addVpToGroup()" class="btn btn-success"><i class="fa fa-save"></i></a>
      <a *ngIf="showVPGroupAdd" (click)="showVPGroupAdd = !showVPGroupAdd" class="btn btn-danger"><i class="fa fa-times"></i></a>
    </div>
  </div>
</p-sidebar>

<div *ngIf="dashboard == 'v2' && valueProp" class="row">
  <div class="col-xs-6">
    <h2 *ngIf="!changeName" style="font-size: 18px; margin-top: 0px">
      {{ valueProp.name }}
      <a
        *ngIf="!changeName && valueProp.vp_can_edit && valueProp.closed !== 1"
        (click)="changeName = true"
        [matTooltip]="trans.trans.ch_rename_value_prop.value"
        matTooltipPosition="right"
        ><i class="fa fa-pencil"></i
      ></a>
      <small *ngIf="valueProp.closed == 1"><br />{{ trans.trans.this_value_prop_is_locked.value }}</small>
    </h2>
    <div class="input-group">
      <input *ngIf="changeName" [(ngModel)]="valueProp.name" class="form-control" />
      <div class="input-group-btn">
        <button *ngIf="changeName" (click)="changeValuePropName(valueProp.id)" class="btn btn-success" [matTooltip]="trans.trans.save.value" container="body">
          <i class="fa fa-save"></i>
        </button>
        <button *ngIf="changeName" (click)="cancelValuePropName()" class="btn btn-danger" [matTooltip]="trans.trans.cancel.value" container="body">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="col-xs-6">
    <div class="pull-right">
      <a (click)="startTour()" class="pull-right link clickable">{{ trans.trans.take_a_tour.value }}</a>
      <i
        class="fa fa-refresh pull-right"
        matTooltip="{{ trans.trans.ch_refresh_data.value }}"
        matTooltipPosition="below"
        (click)="funcGlobalRefresh()"
        style="margin-right: 10px; font-size: 18px"
      ></i>
      <i
        class="fa fa-lightbulb-o pull-right"
        matTooltip="{{ trans.trans.ch_hide_help.value }}"
        matTooltipPosition="below"
        (click)="funcShowHelp()"
        style="font-size: 18px; margin-right: 10px; color: gold"
        *ngIf="showHelp"
      ></i>
      <i
        class="fa fa-lightbulb-o pull-right"
        matTooltip="{{ trans.trans.ch_show_help.value }}"
        matTooltipPosition="below"
        (click)="funcShowHelp()"
        style="font-size: 18px; margin-right: 10px"
        *ngIf="!showHelp"
      ></i>
      <!-- <i class="fa fa-exchange pull-right" matTooltip="{{trans.trans.ch_edit_translations.value}}" matTooltipPosition="below" (click)='funcShowTranslate()' style='margin-right: 10px; font-size: 18px;' *ngIf="isAdmin && !showTranslate" aria-hidden="true"></i>
			<i class="fa fa-exchange pull-right" matTooltip="{{trans.trans.ch_edit_translations.value}}" matTooltipPosition="below" (click)='funcShowTranslate()' style='margin-right: 10px; font-size: 18px; color: gold;' *ngIf="isAdmin && showTranslate" aria-hidden="true"></i> -->
      <i
        class="fa fa-cloud-download"
        matTooltip="{{ trans.trans.ch_download.value }}"
        matTooltipPosition="below"
        (click)="AssetsMenu = !AssetsMenu"
        style="float: left; font-size: 18px; margin-right: 20px"
      ></i>
      <i
        class="fa fa-check-circle-o"
        *ngIf="isRealizationUser"
        [matTooltip]="'Promote to Realization'"
        container="body"
        matTooltipPosition="below"
        (click)="promoteToRealization = true"
        style="font-size: 18px; margin: 0 10px"
      ></i>

      <app-value-prop-dashboard-actions
        (showShareCallback)="viewTop = 'share'"
        [dashboard]="dashboard"
        [masterValuePropId]="masterValuePropId"
        [valueProp]="valueProp"
        style="float: left"
      ></app-value-prop-dashboard-actions>
    </div>
  </div>
</div>

<div class="row" *ngIf="dashboard == 'v2' && valueProp">
  <div class="col-xs-12">
    <p-progressBar *ngIf="valuePropLoader" mode="indeterminate"></p-progressBar>
  </div>
</div>

<app-panel-top
  *ngIf="dashboard == 'v2' && valueProp"
  [showTranslate]="showTranslate"
  [valueProp]="valueProp"
  [isDev]="isDev"
  [dashboard]="dashboard"
  [showHelp]="showHelp"
  [viewTop]="viewTop"
  [year1BreakDownChartData]="year1BreakDownChartData"
  [costBenefitChartData]="costBenefitChartData"
></app-panel-top>
<app-panel-bottom
  [valuePropLoader]="valuePropLoader"
  *ngIf="dashboard == 'v2' && valueProp"
  (callbackSetTitleBottom)="viewBottomTitle = $event"
  [discoveryQuestions]="discoveryQuestions"
  [contextualHelp]="contextualHelp"
  [showTranslate]="showTranslate"
  [valueProp]="valueProp"
  [valuePropId]="valuePropId"
  [isDev]="isDev"
  [dashboard]="dashboard"
  [showHelp]="showHelp"
  [selectedBenefit]="selectedBenefit"
  [benefitDetail]="benefitDetail"
  (callbackExplained)="funcGotoExplain($event)"
  [explained]="explained"
  [hasKeyAssumptions]="hasKeyAssumptions"
  (CallbackSelectedBenefit)="funCcallbackSelectedBenefit($event)"
  [viewBottom]="viewBottom"
  [firstLoad]="firstLoad"
>
</app-panel-bottom>
<ngx-guided-tour></ngx-guided-tour>
