import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ListService } from '@data/services/list/list.service';
import { CommonService } from '@data/services/common/common.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Observable, Subject, merge } from 'rxjs';
import { AlertService } from '../../_services/alert.service';
import { DefaultTranslations } from './value-position.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil, map } from 'rxjs/operators';
import { extractInitials } from '@shared/utils';
import { ValueProp, ValuePropListItem } from '@shared/models/value-prop.model';
import { FactsService } from '@data/services/facts/facts.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { Table } from 'primeng/table';
import { UserService } from '@data/services/user/user.service';
import { ChooseAdventureInternalComponent } from '@shared/choose-adventure-internal/choose-adventure-internal.component';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';

@Component({
  selector: 'app-value-position',
  templateUrl: './value-position.component.html',
  styleUrls: ['./value-position.component.scss'],
})
export class ValuePositionComponent implements OnInit, OnDestroy {
  pageNumber: number;
  pageLimit = 10;
  order = 'desc';
  sortBy = 'created';
  searchText = 'all';

  valuePositionList: ValueProp[] = [];
  image_url: string;
  fullImagePath: string;
  valuePositionLoader: boolean;
  modalReference: NgbModalRef;
  valuePropsDetails: { solutions?: any; roi_decorated?: any; payback?: any; solutionItems?: any };
  solutions: any;
  showTooltipActionLoader = false;
  includeCalcs: boolean = false;
  hasUnfinishedVps: boolean = false;

  contextualHelp = {};
  searchTypeList: Array<{ name: string; value: string }> = [];
  searchType: string;
  accountID = sessionStorage.getItem('aid');
  dealdeskFeature = false;
  masterValuePropFeature = false;
  guidedDiscoveryFeature = false;

  accountFeatures = [];
  feature41 = false;
  showAddButtons = false;
  valuePropCreateFeatureCount: number;
  isGuest = false;
  valuePropGroupsFeature = false;
  isValuePropUser = false;
  caseStudyFeature = false;
  page: string;
  isValuePositionUser = false;
  showSearch = false;
  repSimpleFeature = false;
  selectedOption: string;

  creator_filter = '';

  showTranslate = false;
  isAdmin = false;
  strSearch = '';
  toggleShare = false;
  showClone = false;
  valueposition: ValuePropListItem;
  selectedCase: ValueProp;
  viewAll: boolean = false;

  columns: Array<{ field: string; header: string; sortField: string }> = [];
  rowLimit: Array<{ label: string; icon: string; command: () => void }> = [];

  loading = false;
  loadingVPDetails = false;
  sharedWith: Array<{ label: string; value: string }>;
  ngUnsubscribe$ = new Subject();
  valuePropStatusTypes = [];
  @ViewChild('tableValuePosition', { static: true }) table: Table;
  tableRecords$: Observable<number>;
  currentTableRecords$ = new Subject<number>();

  public breadcrumbKeyValues: Breadcrumbs;
  private unsortedValuePositionList = [];
  public sorted = false;
  public showStepSelection = false;
  public navigationRoute = '';
  public existingVp = false;
  public ff134 = this.commonService.checkFeature('134');
  public priv15 = this.commonService.checkPrivilege('15');
  public priv16 = this.commonService.checkPrivilege('16');
  public priv1 = this.commonService.checkPrivilege('1');
  public priv12 = this.commonService.checkPrivilege('12');
  public workflows: ConversationWorkflow[] = [];

  @ViewChild('chooseAdventure') chooseAdventureDialog: ChooseAdventureInternalComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private listService: ListService,
    private modalService: NgbModal,
    public commonService: CommonService,
    private notification: NotificationService,
    private valuepropService: ValuepropService,
    private alertService: AlertService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private factsService: FactsService,
    private notificationService: NotificationService,
    private userService: UserService,
    private configurableWorkflowService: ConfigurableWorkflowService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    this.searchTypeList.push({
      name: this.trans.trans.value_position.value,
      value: 'name',
    });
    this.searchType = this.searchTypeList[0]['value'];

    this.viewAll = this.commonService.checkPrivilege("11") || this.commonService.checkPrivilege("12");
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('workflows') && sessionStorage.getItem('workflows') !== 'undefined') {
      this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
    }
    this.funcBuildPageLimit();
    this.userService.replaceFF134$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((replaceFF134) => {
      if (replaceFF134) {
        let features = sessionStorage.getItem('features').split(',');
        if (!features.includes('134')) {
          features.push('134');
          sessionStorage.setItem('features', features.join(','));
          this.ff134 = this.commonService.checkFeature('134');
        } else {
          const newFeatures = features.filter((f) => f !== '134');
          if (newFeatures.length) {
            const featuresString = newFeatures.join(',');
            sessionStorage.setItem('features', featuresString);
          }
        }
      }
    });
    this.columns = [
      { field: 'name', sortField: 'name', header: 'Name' },
      { field: 'company_name', sortField: 'company_name', header: 'Company' },
      { field: 'models', sortField: 'solutions_display', header: 'Model' },
      { field: 'benefits_total', sortField: 'total_benefits', header: 'Benefits' },
      { field: 'costs_total', sortField: 'total_costs', header: 'Costs' },
      { field: 'creator', sortField: 'vp_creator_name', header: 'Creator' },
      { field: 'created_on', sortField: 'created', header: 'Created On' },
      { field: 'status', sortField: 'status', header: 'Status' },
    ];

    this.getValuePositionList();

    this.isAdmin = sessionStorage.getItem('isAdmin') == 'true' ? true : false;

    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response) => {
      this.contextualHelp = response['Valueposition'];
    });
    this.parseFeatures();

    const rid = sessionStorage.getItem('rid');
    if (rid === '7') {
      this.isGuest = true;
    } else {
      this.isGuest = false;
    }
    this.alertService
      .subscribeLoggedIn()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.getValuePositionList();
      });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.subscribeToTableRecords();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sharedWith = [
          { label: this.trans.trans.all.value, value: '' },
          { label: this.trans.trans.mine.value, value: '0' },
          { label: this.trans.trans.sharedWithMe.value, value: '1' },
        ];
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.hypotheses.value]: { routerLink: '/valueposition', active: true },
        };
      });
  }

  public chooseStep(route: string, id: string) {
    this.chooseAdventureDialog.navigationRoute = this.commonService.getLinkVPRoute(route, id, 4);
    this.chooseAdventureDialog.existingVp = true;
    if (this.workflows.length <= 1) {
      if (this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      } else if (this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0]);
      }
      this.router.navigate([this.commonService.getLinkVPRoute(route, id, 4)]);
      this.userService.replaceFF134$.next(false);
    } else {
      this.showStepSelection = true;
    }
  }

  public onAddHypoClicked(event: string) {
    this.chooseAdventureDialog.existingVp = false;
    if (this.workflows.length <= 1) {
      if (this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      } else if (this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0]);
      }
      this.funcGetAddRoute(event);
      this.userService.replaceFF134$.next(false);
    } else {
      this.showStepSelection = true;
    }
  }

  funcGetAddRoute(incoming): void {
    this.router.navigate([this.commonService.getCreateVPRoute(incoming)], { relativeTo: this.route });
  }

  public onHidden(hidden: boolean) {
    this.showStepSelection = hidden;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  setRowLimit(num): void {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit(): void {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  funcSort(event): void {
    event.data.sort((data1, data2) => {
      let field = '';
      switch (event.field) {
        case 'total_benefits_fmt':
          field = 'totalbenefits';
          break;
        case 'total_costs_fmt':
          field = 'totalcosts';
          break;
        default:
          field = event.field;
          break;
      }

      const value1 = field !== event.field ? Math.trunc(Number(data1[field])) : data1[field];
      const value2 = field !== event.field ? Math.trunc(Number(data2[field])) : data2[field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }
      this.sorted = true;
      return event.order * result;
    });
  }

  resetSort() {
    this.valuePositionList = [...this.unsortedValuePositionList];
    this.sorted = false;
    this.table.reset();
  }

  funcShowTranslate(): void {
    this.showTranslate = !this.showTranslate;
  }

  onTypeChange(): void {
    this.pageNumber = 1;
  }

  getValuePositionList() {
    this.loading = true;
    this.valuePositionList = [];
    const payload = {
      search: 'all',
      page: 1,
      limit: 'all',
      sortby: 'modified',
      order: 'desc',
      include_calcs: this.includeCalcs
    };

    this.listService
      .getHypotheses(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result) {
          let list = [];
          switch (this.creator_filter) {
            case '':
              list = response.result.data;
              break;
            case '0':
              list = response.result.data.filter((x) => x.user_id == sessionStorage.getItem('uid'));
              break;
            case '1':
              list = response.result.data.filter((x) => x.share_role_type_id == '1' || x.share_role_type_id == '2');
              break;
          }
          this.valuePositionList = list;
          this.unsortedValuePositionList = response.result.data;
          this.hasUnfinishedVps = response.result.has_unfinished_vps;
        }

        this.loading = false;
        this.currentTableRecords$.next(this.valuePositionList.length);
      });
  }

  subscribeToTableRecords(): void {
    this.tableRecords$ = merge(this.table.onFilter.pipe(map((source) => source.filteredValue.length)), this.currentTableRecords$);
  }

  getColor(prop_code): string {
    switch (prop_code) {
      case '1':
        return '#ea1d25';
      case '2':
        return 'orange';
      case '3':
        return 'green';
      case '4':
        return 'green';
      case '5':
        return 'green';
    }
  }

  openAddValuePosition(content, page): void {
    this.page = page;

    let optionCount = 1;

    if (this.guidedDiscoveryFeature) {
      optionCount++;
    }

    if (optionCount === 1) {
      if (this.dealdeskFeature && this.accountID === '20') {
        if (!this.isValuePositionUser) {
          this.page = 'valuePosition';
        }

        if (this.valuePropGroupsFeature) {
          this.selectOptions('quickcreate');
        } else {
          this.selectOptions('dealdesk');
        }
      } else if (this.dealdeskFeature) {
        this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
      } else {
        const navRoute = this.repSimpleFeature ? 'rep' : 'quickcreate';
        this.selectOptions(navRoute);
      }
    } else {
      const navRoute = this.repSimpleFeature ? 'rep' : 'quickcreate';
      this.selectOptions(navRoute);
    }
  }

  selectOptions(selectedOpt?): void {
    const aid = sessionStorage.getItem('aid');

    if (!selectedOpt) {
      selectedOpt = this.selectedOption;
    }

    if (selectedOpt === 'quickcreate') {
      if (this.page === 'valuePosition') {
        if (this.valuePropGroupsFeature) {
          this.router.navigate(['addValuePositionV2']);
        } else {
          this.router.navigate(['addValuePosition']);
        }
      } else {
        if (aid === '464') {
          this.router.navigate(['addValuePositionV2']);
        } else {
          this.router.navigate(['addvalueprop']);
        }
      }
    } else if (selectedOpt == 'guideddiscovery') {
      if (this.page === 'valuePosition') {
        if (aid === '50009') {
          this.router.navigate(['masterValueProps/discovery']);
        } else {
          this.router.navigate(['guidedDiscovery']);
        }
      } else if (aid === '464' || aid === '50009') {
        this.router.navigate(['addValuePositionV2']);
      } else {
        this.router.navigate(['valueprop/guidedDiscovery']);
      }
    } else if (selectedOpt === 'dealdesk') {
      if (this.page === 'valuePosition') {
        this.router.navigate(['dealdesk']);
      } else {
        this.router.navigate(['valueprop/dealdesk']);
      }
    } else if (selectedOpt === 'masterValueProp') {
      if (this.page === 'valuePosition') {
        this.router.navigate(['addMasterValueProp']);
      } else {
        this.router.navigate(['addMasterValueProp']);
      }
    } else if (selectedOpt === 'rep') {
      this.router.navigate(['rep']);
    }

    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  getValuePropDetails(data, op, event, valuePropId): void {
    op.hide();
    op.show(event);
    this.loadingVPDetails = true;

    this.valueposition = data;

    this.valuepropService
      .getValuePropDetail(valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result) {
          this.valuePropsDetails = response.result.value_prop;
          this.valueposition.solutionItems = this.valuePropsDetails.solutions;
          if (this.valueposition.solutionItems) {
            this.valueposition.solutionItems = this.valueposition.solutionItems.map((item) => {
              return { name: item['name'] };
            });
          }
          this.loadingVPDetails = false;
        }
      });
  }

  getValuePropGroup(data, op, event): void {
    op.hide();
    this.valueposition = data;
    op.show(event);
  }

  deleteValueProp(valueprop: ValueProp): void {
    const valuepropId = valueprop.id;
    this.valuePositionLoader = true;
    if (valueprop.is_grouped === '1') {
      this.valuepropService
        .deleteValuePropGroup(valuepropId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((response) => {
          if (response.result) {
            this.valuePositionLoader = false;
            this.notification.success(response.result.message, false);
            this.valuePositionList = this.valuePositionList.filter((valuePosition) => valuePosition.id !== valueprop.id);
            this.currentTableRecords$.next(this.valuePositionList.length);
          }
        });
    } else {
      this.valuepropService
        .deleteValueProp(valuepropId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((response) => {
          if (response.result) {
            this.valuePositionLoader = false;
            this.notification.success(response.result, false);
            this.valuePositionList = this.valuePositionList.filter((valuePosition) => valuePosition.id !== valueprop.id);
            this.currentTableRecords$.next(this.valuePositionList.length);
          }
        });
    }
  }

  parseFeatures(): void {
    const features = sessionStorage.getItem('features');
    const aid = sessionStorage.getItem('aid');
    const rid = sessionStorage.getItem('rid');

    this.valuePropCreateFeatureCount = 0;
    this.accountFeatures = features.split(',');

    if (this.accountFeatures.indexOf('15') >= 0) {
      this.masterValuePropFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.masterValuePropFeature = false;
    }

    if (this.accountFeatures.indexOf('31') >= 0) {
      this.valuePropGroupsFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.masterValuePropFeature = false;
    }

    if (this.accountFeatures.indexOf('16') >= 0) {
      this.dealdeskFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.dealdeskFeature = false;
    }

    if (this.accountFeatures.indexOf('17') >= 0) {
      this.guidedDiscoveryFeature = true;
      this.valuePropCreateFeatureCount = this.valuePropCreateFeatureCount + 1;
    } else {
      this.guidedDiscoveryFeature = false;
    }

    if (this.accountFeatures.indexOf('18') >= 0) {
      this.caseStudyFeature = true;
    } else {
      this.caseStudyFeature = false;
    }

    if ((this.feature41 = this.commonService.checkFeature(41))) {
      if (this.isGuest) {
        this.showAddButtons = false;
      }
    } else {
      if (this.isGuest) {
        this.showAddButtons = false;
      } else {
        this.showAddButtons = true;
      }
    }

    if (aid === '50000') {
      this.isGuest = true;
      this.masterValuePropFeature = true;
      this.isValuePropUser = true;
    }

    if (this.accountFeatures.indexOf('34') >= 0) {
      if (rid === '1' || rid === '2') {
        this.repSimpleFeature = true;
      }
    }
  }

  getInitials(name: string): string {
    return extractInitials(name);
  }

  updateValuePropStatus(statusTypeid, valueProp): void {
    if (!statusTypeid) {
      return;
    }
    const statusTypeObj = { value_prop_status_type_id: statusTypeid };
    this.valuepropService
      .updateValuePropStatus(valueProp.id, statusTypeObj)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          valueProp.status = response.result.value_prop_status.name;
          this.notificationService.success('Status updated successfully', false);
        } else {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  public onChange(event, data) {
    const vpt = this.valuePropStatusTypes.find((vpt) => vpt.name === event.target.value);
    if (vpt) {
      this.updateValuePropStatus(vpt.id, data);
    }
  }

  getValuePropStatusTypes(vp): void {
    this.factsService
      .getValuePropStatusTypes()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        this.valuePropStatusTypes = [];
        for (let s = 0; s < response.result.length; s++) {
          const elem = response.result[s];
          this.valuePropStatusTypes.push({
            label: elem.name,
            command: () => this.updateValuePropStatus(elem.id, vp),
          });
        }
      });
  }

  checkDeletePermission(vp: ValueProp, privs: { priv1: boolean; priv12: boolean }): boolean {
    if (sessionStorage.getItem('uid') === vp.user_id) {
      return true;
    }
    if (+vp.share_role_type_id === 4) {
      return false;
    }
    if (privs.priv1 || privs.priv12) {
      return true;
    }
    if (+vp.share_role_type_id === 3 && +vp.share_role_type_id === 5) {
      return true;
    }
  }
}
