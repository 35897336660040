import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ScratchpadModalComponent } from '../../common/scratchpad-modal/scratchpad-modal.component';
import { AssetService } from '@data/services/asset/asset.service';
import { forkJoin, Observable, Subject } from "rxjs";
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@data/services/user/user.service';
import { Translations } from '@data/services/translation/translation.service';
import { takeUntil } from 'rxjs/operators';
import { Benefits, MasterValueProp, ValueProp } from '@shared/models/value-prop.model';
import { CostType } from '@data/services/valueprop/models/cost.model';
import { ScratchpadDefault } from 'app/value-map-maintenance/views/factors/interfaces/factors.interface';
import { StyleService } from 'app/style.service';

interface ValuePropId {
	id: string;
}

@Component({
	selector: 'app-compare',
	templateUrl: './compare.component.html',
	styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit, OnDestroy {
	changeName = false;
	changeNameMVP = false;
	fullImagePath: string;

	isCollapsedKeyFigure = false;
	isCollapsedBenefits = false;
	isCollapsedCosts = false;
	isCollapsedAssumptions = false;
	isCollapsedImprovements = false;
	compareLoader = true;
	currentTab = 'KeyFigure';

	masterValuePropId: number;
	masterValueProp: MasterValueProp = {};
	ogMasterValueProp: MasterValueProp = {};
	valueProps: ValueProp[] = [];
	ogValueProps: ValueProp[] = [];

	keyFigures = [];
	benefits: Benefits[] = [];
	costs: CostType[] = [];
	assumptions = [];
	ogAssumptions = [];
	scratchpads: ScratchpadDefault[] = [];
	scratchpad_units = [];
	improvements = [];
	ogScratchpads: ScratchpadDefault[] = [];
	ogScratchpad_units: ScratchpadDefault[] = [];

	modalReference: NgbModalRef;
	assumptionSelected: any;
	assumptionValues: any;
	assumptionValue: number;
	assumptionSaveLoader = false;
	improvementSaveLoader = false;
	compareKeyFigures: any = {};
	contextualHelp : any= {};
	masterValuePropTemplates: MasterValueProp[] = [];
	compareIds = [];
	userId: string;
	token: string;
	isAssumptionUpdated = false;
	isImprovementUpdated = false;

	costObj: object;

	eKeyFigures = false;
	e3mi: boolean;
	etco: boolean;
	eNPV: boolean;
	epaybackPeriod: boolean;
	eROI: boolean;
	etotalCosts: boolean;
	eTotalBenefits: boolean;
	eBenefits: boolean;
	eCosts: boolean;
	eAssumption: boolean;
	eImprovements: boolean;

	assumptionsEditing = false;
	scratchpadEditing = false;
	improvementsEditing = false;
	assetUrl = "";
	assetGenerator = ""

	header_color: string;

	VPGroup = 0;
	toggleAddCost: boolean;
	cost: CostType;
	valuePropId: ValuePropId;
	costId: number;
	edit: boolean;

	ngUnsubscribe = new Subject();
	style2022$: Observable<boolean>;

	constructor(
		private modalService: NgbModal,
		private masterValuePropService: MasterValuePropService,
		private route: ActivatedRoute,
		private notification: NotificationService,
		private router: Router,
		private commonService: CommonService,
		private valuepropService: ValuepropService,
		private assetService: AssetService,
		private cd: ChangeDetectorRef,
		public translate: TranslateService,
		private userService: UserService,
		private translateService: TranslateService,
		public trans: Translations,
		private styleService: StyleService
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
		// this.translate.setDefaultLang('en_US');
		// if (sessionStorage.getItem("lang_file")) {
		// 	this.translate.use(sessionStorage.getItem("lang_file"));
		// } else {
		// 	if (sessionStorage.getItem("user_lang") !== 'false' && sessionStorage.getItem("user_lang") !== null) {
		// 		this.translate.use(sessionStorage.getItem("user_lang"));
		// 	}
		// }

		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.header_color = localStorage.getItem("header_color");

		this.funceImprovements(true);
		this.funceAssumption(true);

		this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
			this.masterValuePropId = params.id;
			this.VPGroup = params.group;
		});

		this.masterValueProp = JSON.parse(localStorage.getItem("mastervalueprop"));

		this.readAggregate();
		this.readMasterValueProp();

		this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.readAggregate();
		});

		if (!this.contextualHelp) {
			this.userService.getContextualHelpList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {

				if (result.result) {
					this.commonService.contextualHelp.next(result.result)
				}
			});
		}

		this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

			this.contextualHelp = response['Valueprop'];
		})

		if (this.VPGroup) {
			this.getVPGroupAssets();
		} else {
			this.readTemplates();
		}

		this.compareIds = [localStorage.getItem("mvpIds")];
		this.userId = sessionStorage.getItem('uid');
		this.token = sessionStorage.getItem('vcu');

		this.assetUrl = this.commonService.getAssetUrl();
		this.assetGenerator = sessionStorage.getItem('asset_gen_file');
	}

	ngOnDestroy() {
		localStorage.removeItem("mastervalueprop");
		localStorage.removeItem("mvpIds");
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	getFAIcon(format) {
		switch (format) {
			case "MS Word":
				return "fa fa-file-word-o"

			case "MS Excel":
				return "fa fa-file-excel-o"

			case "MS PowerPoint":
				return "fa fa-file-powerpoint-o"

			case "MS PDF":
				return "fa fa-file-pdf-o"

			default:
				return "fa fa-cloud-download"

		}
	}

	getFAColor(format) {
		switch (format) {
			case "MS Word":
				return { "color": "#2e5b97" };

			case "MS Excel":
				return { "color": "#3a6e46" };

			case "MS PowerPoint":
				return { "color": "#c55737" };

			case "MS PDF":
				return { "color": "#c60a09" };

			default:
				return { "color": "black" }
		}
	}


	moveTo(section) {
		this.currentTab = section;

		this['isCollapsed' + section] = false;


		// const ele = document.getElementById(section);
		// const parentEle = document.getElementById('compareSections');
		// parentEle.scrollTo(0, (ele.offsetTop - 50));
	}

	funcProcessReadAggregate(result) {
		console.log('result: ', result)
		this.valueProps = result.result.value_props;
		this.ogValueProps = result.result.value_props;
		this.benefits = result.result.metrics;
		this.keyFigures = result.result.key_figures;
		this.costs = result.result.costs;
		this.assumptions = result.result.assumptions;
		this.ogAssumptions = this.assumptions;
		this.improvements = result.result.improvements;
		this.compareKeyFigures = result.result.key_figures_aggregate;
		this.scratchpad_units = result.result.scratchpad_units;
		this.ogScratchpad_units = result.result.scratchpad_units;
		this.compareLoader = false;
		this.isAssumptionUpdated = false;
		let tot = this.valueProps.length;
		let imptot = this.improvements[0].values.length;
		if (imptot < tot) {
			for (let i = 0; i < this.improvements.length; i++) {
				if (this.improvements[i].values.length < tot) {
					let a = tot - this.improvements[i].values.length;
					for (let b = 0; b < a; b++) {
						this.improvements[i].values.push({ expand: false });
					}
				}
			}
		}

		let obj = {
			close_paren: "",
			created: "",
			factor_id: "",
			id: "",
			modified: "",
			name: "",
			open_paren: "",
			operand: "",
			operator_id: "",
			value_prop_id: "",
			value_prop_scratchpad_id: ""
		};
		if (this.scratchpad_units && this.scratchpad_units.length) {
			imptot = this.scratchpad_units[0].values.length;
			// if (imptot < tot) {
			for (let i = 0; i < this.scratchpad_units.length; i++) {
				for (let c = 0; c < this.scratchpad_units[i].values.length; c++) {
					if (this.scratchpad_units[i].values[c] == "0") {
						this.scratchpad_units[i].values[c] = obj;
					}
				}
				if (this.scratchpad_units[i].values.length < tot) {
					let a = tot - this.scratchpad_units[i].values.length;
					for (let b = 0; b < a; b++) {
						this.scratchpad_units[i].values.push({ expand: false });
					}
				}
			}

		}
		// }
		// this.funceImprovements(true);
		// this.funceAssumption(true);
	}

	readAggregate() {

		const compareString = localStorage.getItem("mvpIds");

		const params = {
			"value_prop_id": compareString
		};

		this.compareLoader = true;
		if (this.VPGroup) {
			this.masterValuePropService.readValuePropGroupCompare(this.masterValuePropId, params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
				this.funcProcessReadAggregate(result);
			});
		} else {
			this.masterValuePropService.readAggregate(this.masterValuePropId, params).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
				this.funcProcessReadAggregate(result);
			});
		}
	}

	funcProcessVP(result) {
		console.log(result)
		this.masterValueProp = result.result.master_value_prop;
		this.masterValueProp['originalName'] = this.masterValueProp['name'];
		this.ogMasterValueProp = this.masterValueProp;

	}

	readMasterValueProp() {
		if (this.VPGroup) {
			this.masterValuePropService.readValuePropGroup(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
				this.funcProcessVP(result);
			});
		} else {
			this.masterValuePropService.readMasterValueProp(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
				this.funcProcessVP(result);
			});
		}
	}

	goToMasterValueProp() {
		this.router.navigate(["mastervalueprop/" + this.masterValuePropId + (this.VPGroup ? "/group" : "")]);
	}

	goToValuePropDash(id) {
		const mvp = {
			"fromMVP": true,
			"mvp_id": this.masterValuePropId
		};

		sessionStorage.setItem('mvp', JSON.stringify(mvp));
		this.router.navigate(["valueprop/" + id]);
	}

	changeValuePropName() {
		this.changeName = false;
	}

	cancelValuePropName() {
		this.changeName = false;
	}

	assumptionIndex: number;
	improvementIndex: number;
	assumptionFactorIndex: number;

	openModal(content, assumptionValues, assumption, assumptionIndex, assumptionFactorIndex) {

		this.assumptionValues = assumptionValues;
		this.assumptionSelected = assumption;
		this.assumptionIndex = assumptionIndex;
		this.assumptionFactorIndex = assumptionFactorIndex;

		if (this.assumptionSelected['value_prop_scratchpad_id']) {
			this.assumptionValues['value'] = this.assumptionValues['operand'];
			this.assumptionValues['value_prop_id'] = this.valueProps[this.assumptionFactorIndex]['id'];
		}

		if (this.assumptionValues.hasOwnProperty('impact_fmt')) {
			this.assumptionValues['value'] = this.assumptionValues['impact'];
		}

		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
	}

	processSaveAssumptions(response) {

		if (response.result && response.result.success) {
			this.readAggregate();
			this.notification.success("Value updated successfully", false);
		}
		this.assumptionSaveLoader = false;
		this.modalReference.close();

	}

	saveAssumptions(type?) {
		this.assumptionSaveLoader = true;
		// type = this.assumptionValues['value_prop_id'] ? this.assumptionValues['value_prop_id'] : this.assumptionValues['id'];
		type = (type == 'all') ? 'all' : this.assumptionValues['value_prop_id'] ? this.assumptionValues['value_prop_id'] : this.assumptionValues['id'];

		if (this.assumptionSelected['driver_factor_id']) {
			let payload = {
				'driver_factor_id': this.assumptionSelected['driver_factor_id'],
				'driver_override': this.assumptionValues['value'],
				'driver_source_type_id': this.assumptionValues['source_type_id']
			};
			if (this.VPGroup) {
				this.masterValuePropService.updateDriverFactorGroup(this.masterValuePropId, type, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					this.processSaveAssumptions(response);
				});
			} else {
				this.masterValuePropService.updateDriverFactorMVP(this.masterValuePropId, type, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					this.processSaveAssumptions(response);
				});
			}
		}
		else if (this.assumptionSelected['financial_factor_id']) {
			let payload = {
				'financial_factor_id': this.assumptionSelected['financial_factor_id'],
				'financial_override': this.assumptionValues['value'],
				'financial_source_type_id': this.assumptionValues['source_type_id']
			};

			if (this.VPGroup) {
				this.masterValuePropService.updateFinancialFactorGroup(this.masterValuePropId, type, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					this.processSaveAssumptions(response);
				});
			} else {
				this.masterValuePropService.updateFinancialFactorMVP(this.masterValuePropId, type, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					this.processSaveAssumptions(response);
				});
			}
		}
		else if (this.assumptionValues.hasOwnProperty("impact_fmt")) {
			let payload = {
				"metric_id": this.assumptionValues['value_prop_metric_id'],
				"unit_type_id": this.assumptionValues['unit_type_id'],
				"improvement": this.assumptionValues['value']
				// 'improvement_factor_id': this.assumptionSelected['improvement_factor_id'],
				// 'improvement': this.assumptionValues['impact']
				// 'financial_source_type_id': this.assumptionValues['source_type_id']
			};

			if (this.VPGroup) {
				this.masterValuePropService.updateImprovementFactorGroup(this.assumptionValues['value_prop_id'], payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					this.processSaveAssumptions(response);
				});
			} else {
				this.masterValuePropService.updateImprovementFactorMVP(this.assumptionValues['value_prop_id'], payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
					this.processSaveAssumptions(response);
				});
			}

		}
		else if (this.assumptionSelected['value_prop_scratchpad_id']) {
			// if case for scratchpad_units
			// this.assumptionValues['value_prop_id'] ? this.assumptionValues['value_prop_id'] : this.assumptionValues['id']
			let payload = {
				'factor_id': this.assumptionValues['factor_id'],
				'operand': this.assumptionValues['value'],
				'operator_id': this.assumptionValues['operator_id']
			};
			this.masterValuePropService.updateScratchPadUnitMVPv2(this.masterValuePropId, type, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
				this.processSaveAssumptions(response);
			});
		}
	}

	scratchpadCancelEdit() {
		this.scratchpad_units = [];
		this.scratchpad_units = this.ogScratchpad_units;
		this.scratchpads = this.ogScratchpads;

		this.scratchpadEditing = false;
	}

	assumptionsCancelEdit() {
		this.valueProps = this.ogValueProps;
		this.masterValueProp = this.ogMasterValueProp;
		this.assumptions = [];
		this.assumptions = this.ogAssumptions;
		this.assumptionsEditing = false;
		this.isAssumptionUpdated = false;
		this.cd.detectChanges();
	}

	payloadBulkSave = [];
	setAssumptionsForBulkSave(modal: boolean) {
		this.isAssumptionUpdated = true;


		if (this.assumptionSelected['value_prop_scratchpad_id']) {
			// if case for scratchpad_units
			this.scratchpad_units[this.assumptionIndex]['values'][this.assumptionFactorIndex]['operand'] = this.assumptionValues['value'];
		}
		else {
			this.assumptions[this.assumptionIndex]['values'][this.assumptionFactorIndex]['value'] = this.assumptionValues['value'];
			this.assumptions[this.assumptionIndex]['values'][this.assumptionFactorIndex]['value_fmt'] = this.assumptionValues['value'];
		}

		if (this.assumptionSelected['driver_factor_id']) {

			let payload = {
				'driver_factor_id': this.assumptionSelected['driver_factor_id'],
				'driver_override': this.assumptionValues['value'],
				'driver_source_type_id': this.assumptionValues['source_type_id']
			};

			this.payloadBulkSave.push(this.masterValuePropService.updateDriverFactorMVP(this.masterValuePropId, this.assumptionValues['value_prop_id'], payload));
		}
		else if (this.assumptionSelected['financial_factor_id']) {
			let payload = {
				'financial_factor_id': this.assumptionSelected['financial_factor_id'],
				'financial_override': this.assumptionValues['value'],
				'financial_source_type_id': this.assumptionValues['source_type_id'],
			};


			this.payloadBulkSave.push(this.masterValuePropService.updateFinancialFactorMVP(this.masterValuePropId, this.assumptionValues['value_prop_id'], payload));
		}
		else if (this.assumptionSelected['value_prop_scratchpad_id']) {
			// if case for scratchpad_units
			let payload = {
				'factor_id': this.assumptionValues['factor_id'],
				'operand': this.assumptionValues['operand'],
				'operator_id': this.assumptionValues['operator_id']
			};


			this.payloadBulkSave.push(this.masterValuePropService.updateScratchPadUnitMVP(this.masterValuePropId, this.assumptionValues['value_prop_id'], payload));
		}

		if (modal) {
			this.modalReference.close();
		}
	}

	assumptionBulkSave() {
		this.assumptionSaveLoader = true;

		forkJoin(this.payloadBulkSave).pipe(takeUntil(this.ngUnsubscribe)).subscribe(results => {
			if (results) {
				this.assumptionSaveLoader = false;
				this.notification.success("Values updated successfully", false); // Params {message, islogout}
				this.readAggregate();
			}
		});
	}

	compareBulkUpdateImprovements() {

		this.improvementSaveLoader = true;
		let factors = [];
		for (let a = 0; a < this.improvements.length; a++) {
			for (let f = 0; f < this.improvements[a].values.length; f++) {
				if (this.improvements[a]['values'][f]['updated']) {
					let found = false;

					if (this.improvements[a]['improvement_factor_id']) {
						found = true;
						this.improvements[a]['values'][f]['improvement_factor_id'] = this.improvements[a]['improvement_factor_id'];
					}

					if (!found) { continue; }
					factors.push(this.improvements[a]['values'][f]);
				}
			}
		}

		let payload = { factors: factors };

		if (this.VPGroup) {
			this.masterValuePropService.compareBulkUploadDriverGroup(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {

				this.improvementsEditing = false;
				this.improvementSaveLoader = false;
				if (res.result.success) {
					this.notification.success("Values updated successfully", false);
					this.readAggregate();
				}
			})
		} else {
			this.masterValuePropService.compareBulkUploadDriver(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {

				this.improvementsEditing = false;
				this.improvementSaveLoader = false;
				if (res.result.success) {
					this.notification.success("Values updated successfully", false);
					this.readAggregate();
				}
			})
		}

	}

	compareBulkUpdate() {
		this.assumptionSaveLoader = true;

		let factors = [];
		for (let a = 0; a < this.assumptions.length; a++) {
			for (let f = 0; f < this.assumptions[a].values.length; f++) {
				if (this.assumptions[a]['values'][f]['updated']) {
					let found = false;

					if (this.assumptions[a]['driver_factor_id']) {
						found = true;
						this.assumptions[a]['values'][f]['driver_factor_id'] = this.assumptions[a]['driver_factor_id'];
					}

					if (this.assumptions[a]['financial_factor_id']) {
						found = true;
						this.assumptions[a]['values'][f]['financial_factor_id'] = this.assumptions[a]['financial_factor_id'];
					}

					if (!found) { continue; }
					factors.push(this.assumptions[a]['values'][f]);
				}
			}
		}


		let payload = { factors: factors };
		this.masterValuePropService.compareBulkUploadDriver(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {

			this.assumptionsEditing = false;
			this.assumptionSaveLoader = false;
			if (res.result.success) {
				this.notification.success("Values updated successfully", false);
				this.readAggregate();
			}
		})

	}

	compareBulkUpdateScratchpad() {
		this.assumptionSaveLoader = true;

		let factors = [];
		for (let a = 0; a < this.scratchpad_units.length; a++) {
			for (let f = 0; f < this.scratchpad_units[a].values.length; f++) {
				if (this.scratchpad_units[a]['values'][f]['updated']) {

					// let found = false;
					// if (this.scratchpad_units[a]['value_prop_scratchpad_id']) {
					// 	found = true;
					// 	this.scratchpad_units[a]['values'][f]['value_prop_id'] = this.scratchpad_units[a]['id'];
					// 	// this.scratchpad_units[a]['values'][f]['value_prop_scratchpad_id'] = this.scratchpad_units[a]['value_prop_scratchpad_id'];
					// }

					// if (!found) { continue; }
					factors.push(this.scratchpad_units[a]['values'][f]);
				}
			}
		}


		let payload = { units: factors };

		if (this.VPGroup) {
			this.masterValuePropService.compareBulkUploadScratchPadGroup(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {

				this.assumptionsEditing = false;
				this.assumptionSaveLoader = false;
				if (res.result.success) {
					this.notification.success("Values updated successfully", false);
					this.readAggregate();
				}
			})

		} else {
			this.masterValuePropService.compareBulkUploadScratchPad(this.masterValuePropId, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {

				this.assumptionsEditing = false;
				this.assumptionSaveLoader = false;
				if (res.result.success) {
					this.notification.success("Values updated successfully", false);
					this.readAggregate();
				}
			})
		}

	}

	// assumptionBulkSaveInline() {
	// 	// this.setAssumptionsForBulkSave(false);

	// 	this.assumptionSaveLoader = true;
	// 	forkJoin(this.payloadBulkSave).subscribe(results => {
	// 		if (results) {
	// 			this.assumptionsEditing = false;
	// 			this.assumptionSaveLoader = false;
	// 			this.notification.success("Values updated successfully", false); // Params {message, islogout}
	// 			this.readAggregate();
	// 		}
	// 	});
	// }

	showScratchPad(value) {
		if (value['has_driver_scratchpad'] == 1 || value['has_improvement_scratchpad'] == 1 || value['has_financial_scratchpad'] == 1) {
			return true;
		}
	}

	showScratchPadModal(value, assumption) {
		const modalReference = this.modalService.open(ScratchpadModalComponent, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		modalReference.componentInstance.valuePropId = value['value_prop_id'];

		let valueMetric = {};
		let factor_type_id;
		if (assumption['driver_factor_id']) {
			valueMetric['driver_factor_id'] = assumption['driver_factor_id'];
			factor_type_id = 1;
		}
		else if (assumption['financial_factor_id']) {
			valueMetric['financial_factor_id'] = assumption['financial_factor_id'];
			factor_type_id = 2;
		}
		else if (assumption['improvement_factor_id']) {
			valueMetric['improvement_factor_id'] = assumption['improvement_factor_id'];
			factor_type_id = 3;
		}

		valueMetric['id'] = 0;

		modalReference.componentInstance.factorTypeId = factor_type_id;
		modalReference.componentInstance.valueMetric = valueMetric;
	}

	changeMasterValuePropName() {
		this.changeNameMVP = false;

		const payload = {
			"name": this.masterValueProp['name']
		}
		this.masterValuePropService.updateMasterValuePropName(this.masterValueProp['id'], payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.masterValueProp['name'] = response.result.value_prop['name'];
				this.notification.success("Master Value Prop name changed successfully", false); // Params {message, islogout}
			}
		});
	}

	cancelMasterValuePropName() {
		this.changeNameMVP = false;
		this.masterValueProp['name'] = this.masterValueProp['originalName'];
	}

	getVPGroupAssets() {
		this.valuepropService.getVPGroupAssets(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

			this.masterValuePropTemplates = response.result;
		})
	}

	readTemplates() {
		this.assetService.readMasterValuePropTemplates(this.masterValuePropId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {

			this.masterValuePropTemplates = response.result;
		});
	}

	openDeleteCost(content, costId, valuePropId) {
		this.costObj = {
			'costId': costId,
			'valuePropId': valuePropId
		}
		this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
	}

	deleteCost() {
		this.valuepropService.deleteValuePropCosts(this.costObj['valuePropId'], this.costObj['costId']).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.notification.success(response.result.message, false); // Params {message, islogout}
				this.readAggregate();
			}
			else if (response.result.success === false) {
				this.notification.error(response.result.message, false); // Params {message, islogout}
			}
		});
	}

	editCost(costId, valuePropId) {

		this.costId = costId;
		this.valuePropId = { 'id': valuePropId };
		this.edit = true;
		this.toggleAddCost = true;
		// const modalReference = this.modalService.open(AddCostModalComponent, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		// modalReference.componentInstance.edit = true;
		// modalReference.componentInstance.costId = costId;
		// modalReference.componentInstance.valueProp = { 'id': valuePropId };
	}

	addCost(valuePropId) {
		this.costId = null;
		this.edit = false;

		this.valuePropId = { 'id': valuePropId };
		this.cost = {
			"value_prop_id": valuePropId,
			"has_scratchpad": null,
			"account_competitor_id": "",
			"account_solution_id": "",
			"accrual_type_id": null,
			"already_added": false,
			"buyout_value": "",
			"company_field": "",
			"cost": "",
			expand: false,
			"alt_cost_name": "",
			"alt_cost_description": "",
			"value_prop_cost_id": "",
			"discount": null,
			"alt_expense_type_id": "",
			"cost_baseline_answer": "",
			"cost_category_type_id": "",
			"cost_type_id": "",
			"cost_to_us": "",
			"created": "",
			"default_include": "",
			"dep_cost_id": "",
			"description": "",
			"driver_factor_id": "",
			"expense_type_id": "",
			"formula": "",
			"id": "",
			"investment_situation_id": "",
			"lease_term": "",
			"modified": "",
			"name": "",
			"present_value": "",
			"quantity": null,
			"rate": "",
			"ratio": "",
			"residual_value": "",
			"scale_type": "",
			"scale_type_id": "",
			"sequence": "",
			"situation_id": "",
			"straight_scaling": "",
			"yr1_costs": "",
			"yr2_costs": "",
			"yr3_costs": "",
			"yr4_costs": "",
			"yr5_costs": "",
			"yr6_costs": "",
			"yr7_costs": "",
			"yr8_costs": "",
			"yr9_costs": "",
			"yr10_costs": "",
			cost_id: ''
		}

		this.toggleAddCost = true;
		// const modalReference = this.modalService.open(AddCostModalComponent, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		// modalReference.componentInstance.edit = false;
		// modalReference.componentInstance.cost = this.cost;
		// modalReference.componentInstance.valueProp = { 'id': valuePropId };
	}

	funceKeyFigures(expand: boolean) {
		// expand or collapse all key figures
		this.eKeyFigures = expand;
		this.eTotalBenefits = expand;
		this.etotalCosts = expand;
		this.eROI = expand;
		this.epaybackPeriod = expand;
		this.eNPV = expand;
		this.etco = expand;
		this.e3mi = expand;
	}

	funcEBenefits(expand: boolean) {
		this.eBenefits = expand;
		for (let i = 0; i < this.benefits.length; i++) {
			this.benefits[i].expand = expand;
		}
	}

	funceCosts(expand: boolean) {
		this.eCosts = expand;

		for (let i = 0; i < this.costs.length; i++) {
			this.costs[i].expand = expand;
		}
	}

	funceAssumption(expand: boolean) {
		this.eAssumption = expand;

		for (let i = 0; i < this.assumptions.length; i++) {
			this.assumptions[i].expand = expand;
		}

		for (let i = 0; i < this.improvements.length; i++) {
			this.improvements[i].expand = expand;
		}

		for (let i = 0; i < this.scratchpad_units.length; i++) {
			this.scratchpad_units[i].expand = expand;
		}
	}

	funceImprovements(expand: boolean) {
		this.eImprovements = expand;

		for (let i = 0; i < this.improvements.length; i++) {
			this.improvements[i].expand = expand;
		}
	}



}
