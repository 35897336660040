import { Injectable } from '@angular/core';

@Injectable ()
export class CompetitorTranslation {
    
    public config: any = {
        component: 'competitors',
        display_name: 'Competitors Solution',
        description: 'All the Competitors in a solution'
    };

    public trans: any = {
        competitors: 'Competitors',
        addNew: 'Add New',
        search: 'Search',
        name: 'Name',
        edit: 'Edit',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
        view: 'View',
        edit_translations: 'Edit Translations'
    };
}
