import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FactorsService } from '@data/services/factors/factors.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { AeGoalGroupsComponent } from './ae-goal-groups/ae-goal-groups.component';
import { AeGoalGroupsTranslations } from './ae-goal-groups/ae-goal-groups.translation';
import { AeGoalsComponent } from './ae-goals/ae-goals.component';
import { AeGoalsTranslations } from './ae-goals/ae-goals.translation';
import { GoalGroupsComponent } from './goal-groups.component';
import { GoalGroupTranslations } from './goal-groups.translation';
import { AEScratchpadsModule } from '../factors/ae_scratchpads/ae_scratchpads.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AeGoalsFormComponent } from './ae-goals/ae-goals-form/ae-goals-form.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [GoalGroupsComponent, AeGoalGroupsComponent, AeGoalsComponent, AeGoalsFormComponent],
  imports: [
    EditTranslationsModule,
    CommonModule,
    FormsModule,
    ProgressBarModule,
    AccordionModule,
    TableModule,
    EllipsisMenuModule,
    AreYouSureModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    ErrorInputWrapperModule,
    NoRecordsModule,
    DropdownModule,
    MatTooltipModule,
    AEScratchpadsModule,
    MultiSelectModule,
    InputSwitchModule,
    ButtonModule,
  ],
  exports: [GoalGroupsComponent, AeGoalsComponent, AeGoalsFormComponent],
  providers: [GoalGroupsService, FactorsService, GoalGroupTranslations, AeGoalGroupsTranslations, AeGoalsTranslations],
})
export class GoalGroupsModule {}
