import { ValueProp, Solution } from '@shared/models/value-prop.model';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DashboardImplementation } from '../../dashboard.implementation';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropDashboardTranslations } from './valueprop.translation';
import { Subject } from 'rxjs';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ChooseAdventureInternalComponent } from '@shared/choose-adventure-internal/choose-adventure-internal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@data/services/user/user.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ListService } from '@data/services/list/list.service';

interface ValuePropSol extends Omit<ValueProp, 'solutions'> {
  solutions?: Partial<Solution>[];
}
@Component({
  selector: 'app-dashboard-valueprop',
  templateUrl: './valueprop.component.html',
})
export class DashboardValuePropComponent implements OnInit, OnDestroy {
  reviseList: { [klass: string]: any }[] = [];
  valuePropsDetails: ValueProp;

  ngUnsubscribe = new Subject();
  showTranslate = false;
  loading = false;
  loadingVPDetails = false;
  valueposition: ValuePropSol;
  columns: Array<{ field: string; header: string; sortField: string }> = [];
  public showStepSelection = false;
  public navigationRoute = '';
  public existingVp = false;
  public ff134 = false;
  public workflows: ConversationWorkflow[] = [];
  @ViewChild('chooseAdventure') chooseAdventureDialog: ChooseAdventureInternalComponent;

  constructor(
    public dbI: DashboardImplementation,
    public commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: ValuepropDashboardTranslations,
    private valuepropService: ValuepropService,
    private listService: ListService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private configurableWorkflowService: ConfigurableWorkflowService
  ) {}

  ngOnInit(): void {
    this.ff134 = this.commonService.checkFeature('134');
    if (sessionStorage.getItem('workflows') && sessionStorage.getItem('workflows') !== 'undefined') {
      this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
    }
    this.userService.replaceFF134$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((replaceFF134) => {
      if (replaceFF134) {
        let features = sessionStorage.getItem('features').split(',');
        if (!features.includes('134')) {
          features.push('134');
          sessionStorage.setItem('features', features.join(','));
          this.ff134 = this.commonService.checkFeature('134');
        } else {
          const newFeatures = features.filter((f) => f !== '134');
          if (newFeatures.length) {
            const featuresString = newFeatures.join(',');
            sessionStorage.setItem('features', featuresString);
          }
        }
      }
    });
    this.dbI.parseFeatures();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getData();
  }
  public chooseStep(route: string, id: string) {
    this.chooseAdventureDialog.navigationRoute = this.commonService.getLinkVPRoute(route, id, 4);
    this.chooseAdventureDialog.existingVp = true;
    this.existingVp = true;
    if (this.workflows.length <= 1) {
      if (this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      } else if (this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0]);
      }
      this.router.navigate([this.commonService.getLinkVPRoute(route, id, 4)]);
      this.userService.replaceFF134$.next(false);
    } else {
      this.showStepSelection = true;
    }
  }

  public onAddHypoClicked(event: string) {
    this.existingVp = false;
    this.chooseAdventureDialog.existingVp = false;
    this.chooseAdventureDialog.addFromDash = true;
    if (this.workflows.length <= 1) {
      if (this.workflows.length === 0) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
      } else if (this.workflows.length === 1) {
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0]);
      }
      this.dbI.funcGetAddRoute(event);
      this.userService.replaceFF134$.next(false);
    } else {
      this.showStepSelection = true;
    }
  }

  funcGetAddRoute(incoming): void {
    this.router.navigate([this.commonService.getCreateVPRoute(incoming)], { relativeTo: this.route });
  }

  public onHidden(hidden: boolean) {
    this.showStepSelection = hidden;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.columns = [
          {
            field: 'valueprop_name',
            header: '',
            sortField: '',
          },
          {
            field: 'valueprop_totalbenefits',
            header: '',
            sortField: '',
          },
        ];
      });
  }

  getData(): void {
    const payload = {
      search: 'all',
      page: 1,
      limit: 5,
      sortby: 'modified',
      order: 'desc',
      skip_solutions: 1,
    };
    this.loading = true;
    this.listService.getValueProps(payload).subscribe((response) => {
      if (response.result) {
        this.reviseList = response.result.data;
      }
      this.loading = false;
    });
  }

  getValuePropDetails(valuePropId: string, op, event): void {
    op.hide();
    op.show(event);
    this.loadingVPDetails = true;
    this.valuepropService.getValuePropDetail(valuePropId).subscribe((response) => {
      if (response.result) {
        this.valueposition = response.result.value_prop;
        this.valueposition.solutions = this.valueposition.solutions;
        if (this.valueposition.solutions) {
          this.valueposition.solutions = this.valueposition.solutions.map((item) => {
            return { name: item['name'] };
          });
        }
        this.loadingVPDetails = false;
      }
    });
  }
}
