import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';
import { Observable, Subject } from 'rxjs';
import { AlertService } from '../../_services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { GuidedTourService } from './guided-tour/guided-tour.service';
import { GuidedTour, Orientation } from './guided-tour/tour-component/guided-tour.constants';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ModelsV2ListTranslations } from './models.translation';
import { Solution } from '@shared/models/value-prop.model';
import { ChartConfiguration, ChartData } from 'chart.js';
import { MenuItem } from 'primeng/api';
import { ColumnMeta } from '@shared/models/common.models';
import { StyleService } from 'app/style.service';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModelsComponent implements OnInit, OnDestroy {
  companiesNoResult = false;
  image_url: string;
  fullImagePath: string;
  solutions: Solution[] = [];
  solutionsLoader: boolean;
  solutionsCategoryLoader: boolean;
  solutionChartLoader: boolean;
  solutionId: string;
  solutionName: string;
  isSolutionAdmin = false;
  hidecategoryName: boolean;
  featureNonFinancial = false;

  pageNumber: number;
  pageLimit: number;
  searchText: string;

  order = 'desc';
  sortBy = 'id';

  chartVC: ChartConfiguration & { d0: unknown };
  chartVCLegend: Array<{ name: string; total: string; color: string }>;

  view = 'solution';
  items: MenuItem[] = [];

  filterList = '';
  totals: { [key: string]: any };

  cols: ColumnMeta[] = [
    { field: 'name', header: 'Model Name' },
    { field: 'category', header: 'Category' },
    { field: 'releasedToUsers', header: 'Released to Users' },
    { field: 'releasedToPartners', header: 'Released to Partners' }
  ];

  barChartData: { [key: string]: any };
  header_color: string;
  isDev = false;
  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;

  tourScrollReference = document.querySelector<HTMLElement>('.container_interior');

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private alertService: AlertService,
    public trans: ModelsV2ListTranslations,
    private translationService: TranslationsV2Service,
    private guidedTourService: GuidedTourService,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.isDev = this.commonService.isDev$;

    this.getTotals();

    this.getSolutions();
    this.chartModelCategoryOverview();
    this.chartTop5Models();

    this.hidecategoryName = false;

    this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();
    this.featureNonFinancial = this.commonService.checkFeature('94');

    this.solutionService.readSolution.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getSolutions();
    });

    this.alertService.subscribeLoggedIn().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getSolutions();
      this.hidecategoryName = false;
      this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();
    });

    this.solutionService.refreshModelsTotals.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTotals();
      this.chartModelCategoryOverview();
    });

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.funcDrawMenu();
      });
  }

  funcDrawMenu() {
    this.items = [
      {
        label: this.trans.trans.models.value,
        styleClass: ['solution', 'model_categories'].includes(this.view) ? 'menuSelected' : '',
        command: () => {
          this.view = 'solution';
          this.funcDrawMenu();
        },
        items: [
          {
            label: this.trans.trans.models_list.value,
            styleClass: this.view == 'solution' ? 'menuSelected' : '',
            command: () => {
              this.view = 'solution';
              this.funcDrawMenu();
            },
          },
          {
            label: 'Model Groups',
            badge: this.totals && this.totals.modelsGroupTotal ? this.totals.modelsGroupTotal : '',
            badgeStyleClass: this.totals && this.totals.modelsGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
            styleClass: this.view == 'model_categories' ? 'menuSelected' : '',
            command: () => {
              this.view = 'model_categories';
              this.funcDrawMenu();
            },
          },
        ],
      },
      {
        label: this.trans.trans.groups.value,
        styleClass: ['value_category', 'pain_points', 'capabilities', 'operational_objectives', 'functional_objectives'].includes(this.view) ? 'menuSelected' : '',
        command: () => {
          this.view = 'value_category';
          this.funcDrawMenu();
        },
        items: [
          {
            label: this.trans.trans.benefit_groups.value,
            badge: this.totals.benefitGroupTotal,
            badgeStyleClass: this.totals.benefitGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
            styleClass: this.view == 'value_category' ? 'menuSelected' : '',
            command: () => {
              this.view = 'value_category';
              this.funcDrawMenu();
            },
          },
          {
            label: this.trans.trans.pain_points.value,
            badge: this.totals.painPointGroupTotal,
            badgeStyleClass: this.totals.painPointGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
            styleClass: this.view == 'pain_points' ? 'menuSelected' : '',
            command: () => {
              this.view = 'pain_points';
              this.funcDrawMenu();
            },
          },
          {
            label: this.trans.trans.capabilities.value,
            badge: this.totals.capabilitiesGroupTotal,
            badgeStyleClass: this.totals.capabilitiesGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
            styleClass: this.view == 'capabilities' ? 'menuSelected' : '',
            command: () => {
              this.view = 'capabilities';
              this.funcDrawMenu();
            },
          },
          {
            label: this.trans.trans.operational_objectives.value,
            badge: this.totals.operationalObjectivesGroupTotal,
            badgeStyleClass: this.totals.operationalObjectivesGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
            styleClass: this.view == 'operational_objectives' ? 'menuSelected' : '',
            command: () => {
              this.view = 'operational_objectives';
              this.funcDrawMenu();
            },
          },
          {
            label: this.trans.trans.functional_objectives.value,
            badge: this.totals.functionalObjectivesGroupTotal,
            badgeStyleClass: this.totals.functionalObjectivesGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
            styleClass: this.view == 'functional_objectives' ? 'menuSelected' : '',
            command: () => {
              this.view = 'functional_objectives';
              this.funcDrawMenu();
            },
          },
        ],
      },
      {
        label: this.trans.trans.situation_groups.value,
        badge: this.totals.situationGroupTotal,
        badgeStyleClass: this.totals.situationGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
        styleClass: this.view == 'situations' ? 'menuSelected' : '',
        command: () => {
          this.view = 'situations';
          this.funcDrawMenu();
        },
      },
      {
        label: this.trans.trans.factor_groups.value,
        badge: this.totals.factorGroupTotal,
        badgeStyleClass: this.totals.factorGroupTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
        styleClass: this.view == 'factors' ? 'menuSelected' : '',
        command: () => {
          this.view = 'factors';
          this.funcDrawMenu();
        },
      },
      
    ];

    const goalGroupsFeature = this.commonService.checkFeature('131');

    if(goalGroupsFeature) {
      this.items.push(
        {
          label: this.trans.trans.goalGroups.value,
          badge: '',
          badgeStyleClass: '',
          styleClass: this.view == 'goal_groups' ? 'menuSelected' : '',
          command: () => {
            this.view = 'goal_groups';
            this.funcDrawMenu();
          }
        }
      );
    }

    if (this.featureNonFinancial) {
      const benefitTypeSection = {
        label: this.trans.trans.benefitTypes.value,
        badge: this.totals.benefitTypesTotal,
        badgeStyleClass: this.totals.benefitTypesTotal > 0 ? 'keywest_badge_green' : 'keywest_badge_red',
        styleClass: this.view == 'benefit_types' ? 'menuSelected' : '',
        command: () => {
          this.view = 'benefit_types';
          this.funcDrawMenu();
        },
      };
      this.items[1].items.splice(1, 0, benefitTypeSection);
    }
  }

  funcAddModel(div: HTMLElement): void {
    (async () => {
      this.view = 'solution';
      div.scrollIntoView();

      await this.delay(2000);

      this.solutionService.gotoAddModel.next();
    })();
  }

  funcAddFactors(div: HTMLElement): void {
    (async () => {
      this.view = 'factors';
      div.scrollIntoView();

      await this.delay(5000);

      this.solutionService.gotoAddFactor.next();
    })();
  }

  funcAddSituations(div: HTMLElement): void {
    (async () => {
      this.view = 'situations';
      div.scrollIntoView();

      await this.delay(1000);

      this.solutionService.gotoAddSituation.next();
    })();
  }

  delay(ms: number): Promise<NodeJS.Timer> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  funcLoadView(view): void {
    this.view = view;
    this.funcDrawMenu();
  }

  getSolutions(): void {
    this.pageLimit = 10;
    this.pageNumber = 1;

    const searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc';
    this.solutionsLoader = true;
    this.solutionService.getAvailableSolutions(searchLimit).subscribe((response) => {
      if (response.result) {
        this.solutions = response.result.data;
      }
      this.solutionsLoader = false;
    });
  }

  getTotals() {
    this.solutionService.getModelsOverviewTotals(this.solutionId).subscribe((res) => {
      this.totals = res.result;
      this.getTranslations();
      this.funcDrawMenu();
    });
  }

  chartModelCategoryOverview(): void {
    const chartColors = this.commonService.getChartColors();

    this.solutionService.chartSolutionCategoryChart().subscribe(res => {

      if (!res.result && !res.result.category && !res.result.category.length) {
        this.chartVCLegend = [];
      } else {
        const labels = [];
        for (let i = 0; i < res.result.category.length; i++) {
          labels.push({
            name: res.result.category[i],
            total: res.result.count[i],
            color: chartColors[i],
          });
        }

        this.chartVC = {
          data: {
            datasets: [
              {
                label: 'Categories',
                data: res.result.count,
                backgroundColor: chartColors
              }
            ],
            labels: labels
          },
          d0: [res.result.total_benefits_fmt]
        };
        this.chartVCLegend = res.result.category.length ? labels : [];
      }
    });
  }

  chartTop5Models() {
    const chartColors = this.commonService.getChartColors();

    this.solutionService.chartTop5Models().subscribe(res => {
      this.barChartData = {
        data: {
          datasets: [
            {
              label: 'Models',
              data: res.result.solutionCount,
              backgroundColor: chartColors
            }
          ],
          labels: res.result.solutionName
        },
        labels: res.result.solutionName

      };
    });
  }

  startTour(): void {
    this.guidedTourService.startTour(this.dashboardTour);
  }

  // tslint:disable-next-line: member-ordering
  public dashboardTour: GuidedTour = {
    tourId: 'dashboard-tour',
    useOrb: false,
    steps: [
      {
        title: 'Actions',
        content: "Here you'll find quick links to common Model creation tasks",
        selector: '.tour_actions',
        orientation: Orientation.Left,
      },
      {
        title: 'Category Overview',
        content: 'This chart displays how many models belong to a given category. Clicking on a category will filter the models list below',
        selector: '.tour_overview',
        orientation: Orientation.Right,
      },
      {
        title: 'Top 5 Models',
        content: 'These are the most commonly used models and how many times their used in Value Props',
        selector: '.tour_top5',
        orientation: Orientation.Right,
      },
      {
        title: 'Required Inputs',
        content:
          'To build a model successfully, some inputs are required. Click on a requirement to be taken to that section. Requirements that have not been met are displayed in red',
        selector: '.tour_required',
        orientation: Orientation.Left,
      },

      {
        title: 'Optional Inputs',
        content:
          'Optional inputs provide tertiary information that assist in the creation of Value Props. While they are optional, we do recommend they be filled out for the best possible experience',
        selector: '.tour_optional',
        orientation: Orientation.Top,
      },
      {
        title: 'Main Work Area',
        content:
          "Our main work area is where you'll input all of you're data. Use the tab bar to navigate through the various sections. Searching, sorting, and additional information icons are all provided to help your with the creation of data",
        selector: '.tour_content',
        orientation: Orientation.Left,
      },
    ],
  };
}
