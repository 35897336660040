import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiDragDropComponent } from './multi-drag-drop.component';
import { DragDropModule } from '@angular/cdk/drag-drop';



@NgModule({
  declarations: [MultiDragDropComponent],
  imports: [
    CommonModule,
    DragDropModule
  ],
  exports: [MultiDragDropComponent]
})
export class MultiDragDropModule { }
