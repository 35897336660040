<div class="col-sm-12 caseStudiestab">
  <div class="pull-right m-b-md m-t-xxl">
    <button class="btn btn-primary" (click)="openAddSection(addSectionModal)"><i class="fa fa-plus"></i> {{ trans.trans.add_section.value }}</button>
  </div>
  <div class="clearfix"></div>
  <ng-container *ngIf="caseStudy && caseStudy.sections">
    <div class="" *ngFor="let caseStudySection of caseStudy.sections" id="case-study">
      <div class="panel-heading clearfix">
        <h4 class="panel-title">
          {{ trans.trans.section.value }} {{ caseStudySection.name }}
          <i class="icon-pencil m-l-xxl" (click)="openEditSection(editSectionModal, caseStudySection)"></i>
          <i class="icon-trash m-l-xs" (click)="deleteSection(caseStudySection.id)"></i>
        </h4>
      </div>
      <div class="panel-body">
        <div class="description">
          <div class="col-md-2 name">
            <p>
              <strong>{{ trans.trans.description.value }} </strong>
            </p>
          </div>
          <div class="col-md-10">
            <p>{{ caseStudySection.description }}</p>
          </div>
        </div>
        <div class="description">
          <div class="col-md-1 name">
            <strong>{{ trans.trans.media.value }} </strong>
          </div>
          <div class="col-md-11">
            <table id="case-studies-media" class="table searchSolution">
              <thead>
                <tr>
                  <th>{{ trans.trans.name_label.value }}</th>
                  <th>{{ trans.trans.type.value }}</th>
                  <th>{{ trans.trans.size_in_bytes.value }}</th>
                  <th>{{ trans.trans.preview.value }}</th>
                  <th>{{ trans.trans.action.value }}</th>
                </tr>
              </thead>

              <tbody>
                <tr class="soln_row" *ngFor="let image of caseStudySection.case_study_section_images">
                  <td>
                    {{ image.image_name ? image.image_name : image.description }}
                    <i *ngIf="image.description != ''" class="icon-info tooltip-custom m-t-md" [matTooltip]="image.description" matTooltipPosition="right"></i>
                  </td>
                  <td>{{ image.type ? image.type : 'media' }}</td>
                  <td>
                    {{ image.image_size ? image.image_size : 'N/A' }}
                  </td>
                  <td>
                    <a target="_blank" href="{{ image.path }}">
                      <img *ngIf="!image.type" [src]="image.path" class="previewImage" />
                    </a>
                    <i *ngIf="image.type" class="fa fa-video-camera" (click)="openMedia(image.path)"></i>
                  </td>
                  <td class="">
                    <div class="dropdown pull-right">
                      <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                      <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                        <li class="dropdown-menu-list" (click)="downAsset(image.path)"><i class="icon-cloud-download"></i> {{ trans.trans.download.value }}</li>
                        <li class="dropdown-menu-list" (click)="deleteMedia(image.id)"><i class="icon-trash"></i> {{ trans.trans.delete.value }}</li>
                        <li class="dropdown-menu-list" (click)="openEditMedia(editMediaModal, image)"><i class="icon-pencil"></i> {{ trans.trans.edit.value }}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="!caseStudySection.case_study_section_images">
              <p class="m-l-xxl">{{ trans.trans.no_media_found.value }}</p>
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <button class="btn btn-primary btn-addon m-b-sm m-t-sm btn-xs" (click)="openAddMedia(addMediaModal, caseStudySection)">
              <i class="fa fa-plus"></i>{{ trans.trans.add_media.value }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="caseStudyLoader" class="col-12 text-center">
    <img class="actionLoader" src="{{ fullImagePath }}" />
  </div>
  <div *ngIf="caseStudy && caseStudy.sections == false && !caseStudyLoader" class="m-t-md col-sm-12">
    <div class="searchTableContainer text-center">
      <h4>{{ trans.trans.no_sections_found.value }}</h4>
    </div>
  </div>
</div>

<ng-template #addSectionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.add_section.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="addSectionForm" #addSectionForm="ngForm">
      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.section_type.value }}</label>
        <select
          class="form-control"
          name="type"
          [(ngModel)]="sectionType"
          [ngClass]="{ 'has-error': addSectionForm.controls.sectionType?.invalid && addSectionForm.controls.sectionType?.touched }"
          required
        >
          <option value="">
            {{ trans.trans.choose_section_type.value }}
          </option>
          <option *ngFor="let sectionType of sectionTypes" [ngValue]="sectionType">
            {{ sectionType.name }}
          </option>
        </select>
        <a class="add-section" (click)="isCollapsed = !isCollapsed">+ {{ trans.trans.add_section_type.value }}</a>
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
          <div class="card">
            <div class="card-body">
              <form class="add-section-container" name="addSectionType" #addSectionTypeForm="ngForm">
                <h4>{{ trans.trans.add_section_type.value }}</h4>
                <button type="button" class="close" aria-label="Close" (click)="closeAddSectionType()">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="add-section-form">
                  <div class="form-group">
                    <label class="labelsRequired">{{ trans.trans.section_type_name.value }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name="sectionName"
                      [(ngModel)]="newSectionType.name"
                      required
                      placeholder="{{ trans.trans.section_type_name.value }}"
                      [ngClass]="{ 'has-error': addSectionTypeForm.controls.sectionName?.invalid && addSectionTypeForm.controls.sectionName?.touched }"
                    />
                  </div>
                  <div class="form-group">
                    <label class="labelsRequired">{{ trans.trans.section_type_description.value }}</label>
                    <textarea
                      class="form-control"
                      name="sectionDescription"
                      [(ngModel)]="newSectionType.description"
                      required
                      placeholder="{{ trans.trans.section_type_description.value }}"
                      [ngClass]="{ 'has-error': addSectionTypeForm.controls.sectionDescription?.invalid && addSectionTypeForm.controls.sectionDescription?.touched }"
                    >
                    </textarea>
                  </div>
                  <div class="form-group">
                    <button type="button" class="btn btn-primary m-t-xs m-b-xs" (click)="addSectionType()" [disabled]="addSectionTypeForm.invalid">
                      {{ trans.trans.save.value }}
                    </button>
                    <img class="actionLoader" src="{{ fullImagePath }}" *ngIf="addSectionTypeLoader" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.description.value }}</label>
        <textarea
          class="form-control"
          name="description"
          [(ngModel)]="section.description"
          [ngClass]="{ 'has-error': addSectionForm.controls.description?.invalid && addSectionForm.controls.description?.touched }"
          required
        >
        </textarea>
      </div>
      <div class="form-group">
        <label>{{ trans.trans.media.value }}</label>
        <input type="file" class="form-control" name="media" [(ngModel)]="section.media" accept="image/*" (change)="handleInputChange($event)" />
      </div>
      <div class="form-group">
        <label>{{ trans.trans.media_description.value }}</label>
        <textarea class="form-control" name="description" [(ngModel)]="section.mediadescription"> </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div>
      <button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="addSection()" [disabled]="addSectionForm.invalid">{{ trans.trans.save.value }}</button>
      <img class="actionLoader" src="{{ fullImagePath }}" *ngIf="addSectionLoader" />
    </div>
    <div class="text-right m-t-xs">
      <label class="labelsRequiredInfo">{{ trans.trans.isRequired.value }}</label>
    </div>
  </div>
</ng-template>

<ng-template #editSectionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.edit_section.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="editSectionForm" #editSectionForm="ngForm">
      <div class="form-group">
        <label for="name">{{ trans.trans.section_type.value }}</label>
        <input class="form-control" name="type" [(ngModel)]="currentSection.type" disabled />
      </div>

      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.description.value }}</label>
        <textarea
          class="form-control"
          name="description"
          [(ngModel)]="currentSection.description"
          required
          [ngClass]="{ 'has-error': editSectionForm.controls.description?.invalid && editSectionForm.controls.description?.touched }"
        >
        </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div>
      <button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="updateSection()" [disabled]="editSectionForm.invalid">{{ trans.trans.save.value }}</button>
      <img class="actionLoader" src="{{ fullImagePath }}" *ngIf="addSectionLoader" />
    </div>
    <div class="text-right m-t-xs">
      <label class="labelsRequiredInfo">{{ trans.trans.isRequired.value }}</label>
    </div>
  </div>
</ng-template>

<ng-template #addMediaModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.add_media.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="addMediaForm" #addMediaForm="ngForm">
      <div class="form-group">
        <label class="labelsRequired">{{ trans.trans.media.value }}</label>
        <input type="file" class="form-control" name="media" [(ngModel)]="section.media" accept="image/*" (change)="handleInputChange($event)" required />
      </div>
      <div class="form-group">
        <label>{{ trans.trans.media_description.value }}</label>
        <textarea class="form-control" name="description" [(ngModel)]="section.mediadescription"> </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div>
      <button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="addMedia()" [disabled]="addMediaForm.invalid">{{ trans.trans.save.value }}</button>
      <img class="actionLoader" src="{{ fullImagePath }}" *ngIf="addSectionLoader" />
    </div>
    <div class="text-right m-t-xs">
      <label class="labelsRequiredInfo">{{ trans.trans.isRequired.value }}</label>
    </div>
  </div>
</ng-template>

<ng-template #editMediaModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.update_media.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="editMediaForm" #editMediaForm="ngForm">
      <div class="form-group"></div>
      <div class="form-group">
        <label for="name">{{ trans.trans.media_description.value }}</label>
        <textarea class="form-control" name="description" [(ngModel)]="mediadescription"> </textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="editMedia()" [disabled]="editMediaForm.invalid">{{ trans.trans.Update.value }}</button>
    <img class="actionLoader" src="{{ fullImagePath }}" *ngIf="addSectionLoader" />
  </div>
</ng-template>
