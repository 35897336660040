import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccountService } from '@data/services/account/account.service';
import { Model } from '@data/services/account/models/model.interface';
import { SequencePage, SequencePayload } from '@data/services/account/models/sequence.interface';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { PainPoint } from '@data/services/valueposition/models/painpoint.interface';
import { ValueCategory } from '@data/services/valueposition/models/value-category.interface';
import { CostResponse } from '@data/services/valueprop/models/cost.model';
import { SituationElement } from '@data/services/valueprop/models/situation.model';
import { FunctionalObjective } from '@data/services/valuerealization/models/value-tracker.model';
import { ConfigurableWorkflow } from '@data/services/workflow/models/workflow.interface';
import { Factor } from '@shared/models/account.models';
import { Asset } from '@shared/models/asset.models';
import { Capability } from '@shared/models/capabilities.models';
import { DiscoveryQuestion } from '@shared/models/discovery-question.model';
import { FactorCategoriesResponse } from '@shared/models/factors.models';
import { Goal, GoalGroup } from '@shared/models/goal-group.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { BenefitElement } from 'app/value-mapv2/solution-detail/benefits/benefits.model';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { EditSequencesDefaultTranslations } from './edit-sequences.translation';

export class SequenceItemCache {
  constructor(
    public benefits: BenefitElement[] = [],
    public value_categories: ValueCategory[] = [],
    public factors: Factor[] = [],
    public factor_groups: FactorCategoriesResponse[] = [],
    public models: Model[] = [],
    public model_categories: any[] = [],
    public operational_objectives: any[] = [],
    public functional_objectives: FunctionalObjective[] = [],
    public pain_points: PainPoint[] = [],
    public capabilities: Capability[] = [],
    public discovery_question: DiscoveryQuestion[] = [],
    public account_asset_templates: Asset[] = [],
    public costs: CostResponse[] = [],
    public account_workflow: ConfigurableWorkflow[] = [],
    public situation_types: SituationElement[] = [],
    public goal_groups: GoalGroup[] = [],
    public goals: Goal[] = []
  ) {}
}

@Component({
  selector: 'app-edit-sequences',
  templateUrl: './edit-sequences.component.html',
  styleUrls: ['./edit-sequences.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSequencesComponent implements OnInit {
  breadcrumbKeyValues: Breadcrumbs;
  menuItems: MenuItem[];

  // Has to be any because it can hold items with dissimilar types
  allCurrentItems = [];
  selectedMove: BenefitElement | ValueCategory | FactorCategoriesResponse | PainPoint | Capability | DiscoveryQuestion | Asset;
  moveDirection: 'up' | 'down' = 'up';
  indexOfSelection: number;
  indexToMove = 0;
  showSidebar = false;
  sequenceItemCache = new SequenceItemCache();
  searchInput = new FormControl('');
  filteredOptions: Observable<string[]>;
  ngUnsubscribe = new Subject();
  currentPage = SequencePage.Benefits;
  previousPage = SequencePage.Benefits;
  SequencePage = SequencePage;
  isLoading = true;
  hideMenu: Observable<boolean>;
  currentSequenceEdited = false;
  showConfirmDialog = false;

  forceRefresh = false;

  resetPrompt: string;

  private hideMenu$ = new Subject<boolean>();

  constructor(
    private translationService: TranslationsV2Service,
    public trans: EditSequencesDefaultTranslations,
    private commonService: CommonService,
    private accountService: AccountService,
    private messageService: MessageService,
    private solutionService: SolutionService,
    private cd: ChangeDetectorRef
  ) {
    this.hideMenu = this.hideMenu$.asObservable();
  }

  ngOnInit(): void {
    this.getTranslations();
    this.filteredOptions = this.searchInput.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
    this.accountService.getSequence(SequencePage.Benefits).subscribe((res) => {
      this.allCurrentItems = [...res];
      this.searchInput.reset();
      this.sequenceItemCache.benefits = res;
      this.isLoading = false;
      this.cd.markForCheck();
    });
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    let propteryToSearch = 'name';
    if (this.currentPage === SequencePage.Benefits) {
      propteryToSearch = 'metric_name';
    } else if (this.currentPage === SequencePage.ModelCategories) {
      propteryToSearch = 'category';
    }
    return (this.allCurrentItems as any).filter((option) => option[propteryToSearch].toLowerCase().includes(filterValue));
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.buildMenu();
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.sequencing.value]: { routerLink: '', active: true },
        };
        this.cd.markForCheck();
      });
  }

  switchPage(page: SequencePage, reload = false): void {
    if (this.currentSequenceEdited) {
      this.showSaveWarning();
      this.currentPage = this.previousPage;
      return;
    }
    if (!reload && this.sequenceItemCache[page].length > 0) {
      this.allCurrentItems = [...this.sequenceItemCache[page]];
      this.searchInput.reset();
      return;
    }
    this.isLoading = true;
    this.cd.markForCheck();
    this.accountService.getSequence(page).subscribe((res) => {
      if (!res || res.length === 0) {
        this.allCurrentItems = [];
        this.searchInput.reset();
        this.isLoading = false;
        this.cd.markForCheck();
        return;
      }
      const result = res.filter((model) => model.id !== 0);
      const sortedItems = result.sort((a, b) => +a.sequence - +b.sequence);
      this.allCurrentItems = [...sortedItems];
      this.searchInput.reset();
      this.sequenceItemCache[page] = result;
      this.isLoading = false;
      this.cd.markForCheck();
    });
  }

  buildMenu(): void {
    this.menuItems = [
      {
        label: this.trans.trans.benefitsOnly.value,
        styleClass: this.currentPage === SequencePage.Benefits ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Benefits;
          this.switchPage(SequencePage.Benefits);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.valueCategories.value,
        styleClass: this.currentPage === SequencePage.ValueCategories ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.ValueCategories;
          this.switchPage(SequencePage.ValueCategories);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.factorGroups.value,
        styleClass: this.currentPage === SequencePage.FactorGroups ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.FactorGroups;
          this.switchPage(SequencePage.FactorGroups);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.factorOnly.value,
        styleClass: this.currentPage === SequencePage.Factors ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Factors;
          this.switchPage(SequencePage.Factors);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.modelsOnly.value,
        styleClass: this.currentPage === SequencePage.Models ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Models;
          this.switchPage(SequencePage.Models);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.modelCategories.value,
        styleClass: this.currentPage === SequencePage.ModelCategories ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.ModelCategories;
          this.switchPage(SequencePage.ModelCategories);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.operationalObjectives.value,
        styleClass: this.currentPage === SequencePage.OperationalObjectives ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.OperationalObjectives;
          this.switchPage(SequencePage.OperationalObjectives);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.functionalObjectives.value,
        styleClass: this.currentPage === SequencePage.FunctionalObjectives ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.FunctionalObjectives;
          this.switchPage(SequencePage.FunctionalObjectives);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.painPoints.value,
        styleClass: this.currentPage === SequencePage.PainPoints ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.PainPoints;
          this.switchPage(SequencePage.PainPoints);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.capabilitiesOnly.value,
        styleClass: this.currentPage === SequencePage.Capabilities ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Capabilities;
          this.switchPage(SequencePage.Capabilities);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.assetsOnly.value,
        styleClass: this.currentPage === SequencePage.Assets ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Assets;
          this.switchPage(SequencePage.Assets);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.discoveryQuestionsOnly.value,
        styleClass: this.currentPage === SequencePage.DiscoveryQuestions ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.DiscoveryQuestions;
          this.switchPage(SequencePage.DiscoveryQuestions);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.costsOnly.value,
        styleClass: this.currentPage === SequencePage.Costs ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Costs;
          this.switchPage(SequencePage.Costs);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.account_workflow.value,
        styleClass: this.currentPage === SequencePage.AccountWorkflows ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.AccountWorkflows;
          this.switchPage(SequencePage.AccountWorkflows);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.situationOnly.value,
        styleClass: this.currentPage === SequencePage.Situations ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Situations;
          this.switchPage(SequencePage.Situations);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.goalGroups.value,
        styleClass: this.currentPage === SequencePage.GoalGroups ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.GoalGroups;
          this.switchPage(SequencePage.GoalGroups);
          this.buildMenu();
        },
      },
      {
        label: this.trans.trans.goalOnly.value,
        styleClass: this.currentPage === SequencePage.Goals ? 'menuSelected' : '',
        command: () => {
          this.previousPage = this.currentPage;
          this.currentPage = SequencePage.Goals;
          this.switchPage(SequencePage.Goals);
          this.buildMenu();
        },
      },
    ];

    const selectedTitle = this.menuItems.find((item) => !!item.styleClass)?.label;
    this.resetPrompt = (this.trans.trans.areYouSureReset?.value as string)?.replace('SequenceObject', selectedTitle);
  }

  handleDropdownChange(_event): void {
    this.indexToMove = this.allCurrentItems.findIndex((x) => x.id === this.selectedMove.id);
  }

  saveMoveForm(): void {
    const index = this.indexOfSelection;

    if (this.moveDirection === 'up') {
      moveItemInArray(this.allCurrentItems, index, this.indexToMove - 1);
    } else {
      moveItemInArray(this.allCurrentItems, index, this.indexToMove);
    }
    this.currentSequenceEdited = true;
    this.forceRefresh = !this.forceRefresh;
    this.searchInput.reset();
    this.indexOfSelection = null;
    this.selectedMove = null;
    this.closeSidebar();
    this.hideMenu$.next(true);
  }

  moveUp(id: string): void {
    this.hideMenu$.next(true);
    const foundIndex = this.findOriginalIndex(id);
    moveItemInArray(this.allCurrentItems, foundIndex, foundIndex - 1);
    this.currentSequenceEdited = true;
    this.forceRefresh = !this.forceRefresh;
    this.searchInput.reset();
  }

  moveDown(id: string): void {
    this.hideMenu$.next(true);
    const foundIndex = this.findOriginalIndex(id);
    moveItemInArray(this.allCurrentItems, foundIndex, foundIndex + 1);
    this.currentSequenceEdited = true;
    this.forceRefresh = !this.forceRefresh;
    this.searchInput.reset();
  }

  handleCustomSequenceClick(id: string): void {
    this.showSidebar = true;
    this.indexOfSelection = this.findOriginalIndex(id);
  }

  closeSidebar(): void {
    this.showSidebar = false;
  }

  findOriginalIndex(id: string): number {
    return this.allCurrentItems.findIndex((element) => +element.id === +id);
  }

  onDrop(reorderedList): void {
    this.allCurrentItems = [...reorderedList];
    this.currentSequenceEdited = true;
    this.searchInput.reset();
  }

  onSave(): void {
    this.itemsUpdated(this.allCurrentItems);
  }

  onCancel(): void {
    this.currentSequenceEdited = false;
    this.switchPage(this.currentPage);
  }

  onReset(): void {
    const payload: SequencePayload = { elements: this.allCurrentItems.map((item) => ({ id: item.id, sequence: '0' })) };

    this.accountService.updateSequence(payload, this.currentPage).subscribe((res) => {
      if (res.status_code === 200) {
        this.currentSequenceEdited = false;
        this.messageService.add({ severity: 'success', summary: this.trans.trans.resetSuccess.value });
        this.switchPage(this.currentPage, true);
      } else {
        this.messageService.add({ severity: 'error', summary: this.trans.trans.resetFail.value, detail: res.message as string });
        this.allCurrentItems = [...this.sequenceItemCache[this.currentPage]];
        this.currentSequenceEdited = false;
      }
      this.searchInput.reset();
      ++this.indexOfSelection;
    });
  }

  itemsUpdated(reorderedList): void {
    const payload: SequencePayload = { elements: [] };
    reorderedList.forEach((item, index: number) => {
      payload.elements.push({
        id: item.id,
        sequence: index.toString(),
      });
    });

    this.accountService.updateSequence(payload, this.currentPage).subscribe((res) => {
      if (res.status_code === 200) {
        this.sequenceItemCache[this.currentPage] = reorderedList;
        this.allCurrentItems = [...reorderedList];
        this.messageService.add({ severity: 'success', summary: this.trans.trans.saved.value, detail: this.trans.trans.success.value });
        this.currentSequenceEdited = false;
        this.switchPage(this.currentPage, true);
      } else {
        this.messageService.add({ severity: 'error', summary: this.trans.trans.fail.value, detail: res.message as string });
        this.allCurrentItems = [...this.sequenceItemCache[this.currentPage]];
        this.cd.detectChanges();
      }
      this.searchInput.reset();
      ++this.indexOfSelection;
      this.currentSequenceEdited = false;
    });
  }

  showSaveWarning(): void {
    this.showConfirmDialog = true;
  }
}
