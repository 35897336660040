import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ErrorMessagesService } from '@services/error-messages.service';
import { NotificationService } from '@services/notification.service';
import { Goal } from '@shared/models/goal-group.model';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AeGoalGroupsTranslations } from './ae-goal-groups.translation';

@Component({
  selector: 'app-ae-goal-groups',
  templateUrl: './ae-goal-groups.component.html',
  styles: [`
    textarea {
      max-height: 300px;
      min-height: 100px;
      min-width: 100%;
      max-width: 100%;
    }
  `]
})
export class AeGoalGroupsComponent implements OnInit, OnDestroy{
  @Input() goalGroupSelected: Goal;
  @Output() refresh = new EventEmitter();
  @Output() close = new EventEmitter();
  goalGroupForm: FormGroup;
  errorDict = new Map();
  accountId: string;
  isLoading = false;
  showTranslate = false;
  ngUnsubscribe$ = new Subject();

  constructor(
    private errorMessageService: ErrorMessagesService,
    private goalGroupService: GoalGroupsService,
    private notificationService: NotificationService,
    public trans: AeGoalGroupsTranslations,
    private translationsService: TranslationsV2Service,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.accountId = sessionStorage.getItem('aid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  buildForm() {
    this.goalGroupForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('')
    });

    this.setFormValues();

    this.goalGroupForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.goalGroupForm.controls));
    });
  }

  setFormValues() {
    if (this.goalGroupSelected) {
      this.goalGroupForm.patchValue({
        name: this.goalGroupSelected.name,
        description: this.goalGroupSelected.description
      });
    }
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationsService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationsService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }


  saveGoalGroup() {
    const payload = {
      ...this.goalGroupForm.getRawValue(),
      function_type_id: 2
    };

    this.isLoading = true;

    // Edit
    if (this.goalGroupSelected) {
      payload.account_factor_category_id = this.goalGroupSelected.id;

      this.goalGroupService.updateGoalGroup(this.accountId, payload)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe(
          () => {
            this.notificationService.success(this.trans.trans.goalGroupUpdated.value, false);
            this.refresh.emit();
            this.goBack();
          },
          () => {
            this.notificationService.error(this.trans.trans.requestError.value, false);
          }
        );

      return;
    }

    // Create
    this.goalGroupService.createGoalGroup(this.accountId, payload)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        () => {
          this.notificationService.success(this.trans.trans.goalGroupSaved.value, false);
          this.refresh.emit();
          this.goBack();
        },
        () => {
          this.notificationService.error(this.trans.trans.requestError.value, false);
        }
      );
  }

  goBack() {
    this.goalGroupSelected = null;
    this.close.emit();
  }

}
