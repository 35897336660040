import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, OnDestroy {
  @Input() activeSurveyTemplate;
  @Input() can_edit: boolean = false;
  showSurveysList = true;
  surveyId: string;

  surveys: any = [];
  survey: any[];
  valueRealizationId: number;
  loadSurveysTable = false;
  surveyDetailLoader = false;
  surveyName: string;
  quaters_in: number;
  quatersList: Array<any> = [];
  fullImagePath: string;
  image_url: string;
  showActionLoader = false;
  account_question_template_id: number;
  assetUrl: string = '';
  assetGenerator: string = '';

  subscriptionRouterParams: Subscription;
  subscriptionreadSurveyList: Subscription;
  subscriptiongetValueRealizationSurveys: Subscription;
  subscriptiongetvalueRealizedTemplate: Subscription;
  subscriptiondeleteSurvey: Subscription;
  subscriptionupdateSurveySettings: Subscription;

  constructor(
    private CommonService: CommonService,
    private ValuerealizationService: ValuerealizationService,
    private NotificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    this.image_url = this.CommonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    this.subscriptionRouterParams = this.route.params.subscribe((params) => this.changeUrl(params.id));
  }

  ngOnInit() {
    this.loadSurveys();

    this.assetUrl = this.CommonService.getAssetUrl();

    this.subscriptionreadSurveyList = this.ValuerealizationService.readSurveyList.subscribe((response) => {
      this.openSurveyDetail(response);
      this.loadSurveys();
    });

    this.setQuatersList();
  }

  ngOnDestroy() {
    if (this.subscriptionRouterParams) {
      this.subscriptionRouterParams.unsubscribe();
    }
    if (this.subscriptionreadSurveyList) {
      this.subscriptionreadSurveyList.unsubscribe();
    }
    if (this.subscriptiongetValueRealizationSurveys) {
      this.subscriptiongetValueRealizationSurveys.unsubscribe();
    }
    if (this.subscriptiongetvalueRealizedTemplate) {
      this.subscriptiongetvalueRealizedTemplate.unsubscribe();
    }
    if (this.subscriptiondeleteSurvey) {
      this.subscriptiondeleteSurvey.unsubscribe();
    }
    if (this.subscriptionupdateSurveySettings) {
      this.subscriptionupdateSurveySettings.unsubscribe();
    }
  }

  refreshTranslation() {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  changeUrl(id) {
    this.valueRealizationId = id;
    this.router.navigate(['valuerealization', id, 'survey']);
  }

  setQuatersList() {
    for (let i = 1; i <= 20; i++) {
      this.quatersList.push({
        name: i + ' Quarter(s)',
        value: i,
      });
    }
  }

  loadSurveys() {
    this.loadSurveysTable = false;

    this.subscriptiongetValueRealizationSurveys = this.ValuerealizationService.getValueRealizationSurveys(this.valueRealizationId).subscribe((response) => {
      if (response.result && response.result.value_surveys) {
        this.surveys = response.result.value_surveys;
      } else {
        this.surveys = [];
      }

      this.loadSurveysTable = true;
    });
  }

  openSurveyDetail(survey) {
    this.surveyId = survey['id'];
    this.showSurveysList = false;
    this.account_question_template_id = survey['account_question_template_id'];
  }
  downAsset(survey) {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.subscriptiongetvalueRealizedTemplate = this.ValuerealizationService.getvalueRealizedTemplate().subscribe((response) => {
      if (response && response !== null && response !== '') {
        const imageUrl =
          this.assetUrl +
          'assets/' +
          this.assetGenerator +
          '?account_asset_template_id=' +
          response.result.id +
          '&user_id=' +
          userId +
          '&token=' +
          token +
          '&value_prop_id=' +
          this.valueRealizationId +
          '&value_survey_id=' +
          survey.id;
        window.location.assign(imageUrl);
      } else {
        this.NotificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }

  delete(survey_id) {
    this.subscriptiondeleteSurvey = this.ValuerealizationService.deleteSurvey(survey_id).subscribe((response) => {
      if (response.result) {
        this.NotificationService.success(response.result, false);
        this.loadSurveys();
      }
    });
  }

  openSurveySettings(content, survey) {
    this.surveyId = survey['id'];
    this.surveyName = survey['name'];
    this.quaters_in = parseInt(survey['quarters_in']);

    this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
  }

  updateSurveySettings() {
    let params = {
      value_prop_id: this.valueRealizationId,
      name: this.surveyName,
      quarters_in: this.quaters_in,
      value_survey_id: this.surveyId,
    };

    this.subscriptionupdateSurveySettings = this.ValuerealizationService.updateSurveySettings(params).subscribe((response) => {
      if (response.result) {
        this.NotificationService.success('Survey Updated Successfully', false);
        this.loadSurveys();
      }
    });
  }

  closeCreateSurvey() {
    this.showSurveysList = true;
  }
}
