import { Injectable } from '@angular/core';

@Injectable()
export class ScenarioCombinedQuestionsTranslation {
  public config: any = {
    component: 'scenarioCombinedQuestions',
    display_name: 'Defined Scenario Question',
    description: 'Question component for the define scenario step process',
  };

  public trans: any = {
    howSolveProblem: 'How does your selection solve the customer problem?',
    required: 'Required',
    save: 'Save',
    charactersLeft: 'of 1200 characters left',
  };
}
