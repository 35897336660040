import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { BulkUpdateLogEntry } from '@shared/models/bulk-update.models';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BulkUpdateTranslation } from './bulk-update.translation';

@Component({
  selector: 'app-bulk-update',
  styleUrls: ['./bulk-update.component.scss'],
  templateUrl: './bulk-update.component.html',
})
export class BulkUpdateComponent implements OnInit, OnDestroy {
  rowLimit: Array<{ label: string; icon: string; command: () => void }> = [];
  pageLimit = 25;

  currentType = 0;

  strSearch = '';
  toggleEdit = false;
  togglePreview = false;
  breadcrumbKeyValues: Breadcrumbs;

  account_id = '';

  showTranslate = false;
  ngUnsubscribe = new Subject<boolean>();

  loading = false;
  trans_toggle = false;
  complete: BulkUpdateLogEntry[];
  pending: BulkUpdateLogEntry[];
  toggleInactive = false;
  style99 = false;
  style2022$: Observable<Boolean>;
  originalTrans: { [klass: string]: string };

  constructor(
    private commonService: CommonService,
    public trans: BulkUpdateTranslation,
    private translationService: TranslationsV2Service,
    private accountService: AccountService,
    private valuepropService: ValuepropService,
    private router: Router,
    private styleService: StyleService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.funcGetBulkUpdates();
    this.style2022$ = this.styleService.style2022;
    this.originalTrans = { ...this.trans.trans };
    this.account_id = sessionStorage.getItem('aid');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.bulkUpdate.value]: { routerLink: '', active: true },
        };
      });
  }

  funcGetBulkUpdates() {
    this.loading = true;
    this.accountService
      .getBulkUpdateLogs()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.complete = res.result.logs.complete;
        this.pending = res.result.logs.pending;
        this.funcBuildPageLimit(false);
        this.loading = false;
      });
  }

  swapState(value: number) {
    this.currentType = value;
    this.setRowLimit(25);
  }

  funcInitBulkUpdate() {
    this.loading = true;
    this.valuepropService
      .bulkUpdateSalesforceValueProps()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loading = false;
        this.funcGetBulkUpdates();
      });
  }

  setRowLimit(num): void {
    if (num != 100) {
      this.pageLimit = num;
      this.funcBuildPageLimit(false);
    } else if (this.currentType === 0) {
      this.pageLimit = this.complete.length;
      this.funcBuildPageLimit(true);
    } else {
      this.pageLimit = this.pending.length;
      this.funcBuildPageLimit(true);
    }
  }

  funcBuildPageLimit(all: boolean): void {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 && all === false ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 && all === false ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 && all === false ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: 'All',
        icon: all === true ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

}
