import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GlobalSearchService } from '@data/services/search/search.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { GlobalSearchComponent } from './search.component';
import { SearchTranslations } from './search.translation';

@NgModule({
  declarations: [GlobalSearchComponent],
  entryComponents: [GlobalSearchComponent],
  imports: [MatTooltipModule, OverlayPanelModule, CommonModule, FormsModule, RouterModule],
  providers: [GlobalSearchService, SearchTranslations],
  exports: [GlobalSearchComponent],
})
export class GlobalSearchHeaderModule {}
