import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RealizedFilter, ValueRealizedCompany } from '@data/services/valuerealization/models/value-realized-company.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { extractInitials } from '@shared/utils';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { Table } from 'primeng/table';
import { Subject, Observable, merge } from 'rxjs';
import { takeUntil, finalize, map } from 'rxjs/operators';
import { ValueRealizedHomeTranslation } from './value-realized-home.translation';

@Component({
  selector: 'app-value-realized-home',
  templateUrl: './value-realized-home.component.html',
  styleUrls: ['./value-realized-home.component.scss'],
})
export class ValueRealizedHomeComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  showTranslate = false;
  realizationsCompanies: ValueRealizedCompany[] = [];
  strSearch = '';
  sharedWith: { label: string; value: RealizedFilter }[];
  breadcrumbKeyValues: Breadcrumbs;
  loading = false;
  showAddButton: boolean = true;

  columns = [
    { header: this.trans.trans.name, field: 'company_name' },
    { header: this.trans.trans.expected, field: 'expected' },
    { header: this.trans.trans.realized, field: 'realized' },
    { header: this.trans.trans.percentage, field: 'percentage' },
    { header: this.trans.trans.number_of_trakers, field: 'number_of_trackers' },
    { header: this.trans.trans.trackers_in_rollup, field: 'trackers_in_rollup' },
    { header: this.trans.trans.created, field: 'created' },
    { header: this.trans.trans.last_measurement_date, field: 'last_measurement_date' },

  ];
  creator_filter: RealizedFilter = RealizedFilter.all;

  addNewSidebar = false;

  @ViewChild('tableSolutionList', { static: true }) table: Table;
  tableRecords$: Observable<number>;
  currentTableRecords$ = new Subject<number>();

  constructor(
    private commonService: CommonService,
    private valuerealizationService: ValuerealizationService,
    public trans: ValueRealizedHomeTranslation,
    private translationService: TranslationsV2Service,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getValueRealizationWithCompany(this.creator_filter);

    this.subscribeToTableRecords();

    if (this.commonService.checkFeature(41)) { 
      this.showAddButton = false; 
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  subscribeToTableRecords(): void {
    this.tableRecords$ = merge(this.table.onFilter.pipe(map((source) => source.filteredValue.length)), this.currentTableRecords$);
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.valueRealized.value]: { routerLink: '/valuerealized', active: true },
        };
        this.sharedWith = [
          { label: this.trans.trans.all.value, value: RealizedFilter.all },
          { label: this.trans.trans.mine.value, value: RealizedFilter.mine },
          { label: this.trans.trans.shared.value, value: RealizedFilter.sharedWithMe },
        ];
      });
  }

  getValueRealizationWithCompany(filter: RealizedFilter): void {
    this.creator_filter = filter;
    this.loading = true;
    this.valuerealizationService
      .getValueRealizationsWithCompanies(this.creator_filter)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.loading = false))
      )
      .subscribe((response) => {
        this.realizationsCompanies = response.result;
        this.currentTableRecords$.next(this.realizationsCompanies.length);
      });
  }

  getInitials(name: string): string {
    return extractInitials(name);
  }

  navToDetails(tracker): void {
    this.router.navigate([`company/${tracker.id}/trackers`], { relativeTo: this.route });
  }

  defineTracker(id: string) {
    this.router.navigate(['valuerealized/company/' + id]);
  }

  onSidebarClose(): void {
    this.addNewSidebar = false;
  }
}
