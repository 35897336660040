import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2ValueCategoryTranslations {
  public config: any = {
    component: 'value-category',
    display_name: 'ModelsV2',
    description: 'ModelsV2 view'
  };

  public trans: any = {
    benefit_groups: 'Benefit Groups',
    add_new: 'Add New',
    ch_edit: 'Edit',
    ch_delete: 'Delete',
    are_you_sure: 'Are you sure?',
    view: 'View',
    search: 'Search',
    name: 'Name'
  };
}
