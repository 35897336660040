import { ChartDataSets } from 'chart.js';

export interface PrimengChartData {
  data: PrimengData;
}

export interface PrimengChartDataCustom {
  data: PrimengDataCustomLabels;
}

export interface PrimengData {
  labels: string[];
  datasets: PrimengDatasets[];
}

export interface PrimengDataCustomLabels {
  labels: PrimengLabels[];
  datasets: PrimengDatasets[];
}

export interface PrimengDatasets extends ChartDataSets {
  title?: string;
}

export interface PrimengLabels {
  color?: string;
  fr?: string;
  name?: string;
  metrics?: {
    year1_benefits: number | string;
    total_benefits: number | string;
  }[];
  description?: string;
  val?: string;
  total?: string;
  id?: string;
}

export interface ChartJSMetaData {
  _chart: Chart;
  _datasetIndex: number;
  _index: number;
  _model: Model;
  _start?: any;
  _view: Model;
  _xScale: Chart.ChartScales;
  _yScale: Chart.ChartScales;
  hidden?: boolean | undefined;
}

interface Model {
  backgroundColor: string;
  borderAlign?: Chart.BorderAlignment | undefined;
  borderColor: string;
  borderWidth?: number | undefined;
  circumference?: number | undefined;
  controlPointNextX: number;
  controlPointNextY: number;
  controlPointPreviousX: number;
  controlPointPreviousY: number;
  endAngle?: number | undefined;
  hitRadius: number;
  innerRadius?: number | undefined;
  outerRadius?: number | undefined;
  pointStyle: string;
  radius: string;
  skip?: boolean | undefined;
  startAngle?: number | undefined;
  steppedLine?: undefined;
  tension: number;
  x: number;
  y: number;
  base: number;
  head: number;
}
