import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { SearchCompanyTranslations } from '../search-companyv3.translation';

@Component({
  selector: 'app-opportunity-form',
  templateUrl: './opportunity-form.component.html',
  styleUrls: ['./opportunity-form.component.scss'],
})
export class OpportunityFormComponent {
  @Input() feature68 = false;
  @Input() showTranslate = false;
  @Input() isVMWare = false;
  @Input() trans: SearchCompanyTranslations;

  @Output() getTranslations = new EventEmitter<void>();

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  @HostListener('keydown.backspace', ['$event'])
  backSpace(evt: KeyboardEvent) {
    if (evt.target === this.inputElement.nativeElement && this.feature68) {
      let value = this.unMaskValue(this.opportunityIdControl.value);
      value = value.substring(0, value.length - 1);
      value = this.maskValue(value);
      this.opportunityIdControl.setValue(value);
      evt.preventDefault();
    }
  }

  @HostListener('focusout', ['$event'])
  onBlur() {
    if (this.opportunityIdControl.value === '_________') {
      this.opportunityIdControl.setValue('');
    }
  }

  constructor(public rootFormGroup: FormGroupDirective) {}

  get opportunityIdControl(): AbstractControl {
    return this.rootFormGroup.control.get('opportunityId');
  }

  public onGetTranslations() {
    this.getTranslations.emit();
  }

  public setMaskedValue(event): void {
    if (this.feature68) {
      let value = this.unMaskValue(this.opportunityIdControl.value);
      let allowedCharacters = /^[0-9]\d*$/;

      if (!allowedCharacters.test(event.data)) {
        value = value.replace(/\D/g, '');
      }

      if (value.length < 10) {
        this.opportunityIdControl.markAsTouched();
        value = this.maskValue(value);
        this.opportunityIdControl.setValue(value);
      } else {
        this.opportunityIdControl.setValue(value.substring(0, value.length - 1));
      }
    }
  }

  private unMaskValue(value: string): string {
    return value.replace(/_/g, '');
  }

  private maskValue(value: string): string {
    for (let i = value.length; i < 9; i++) {
      value += '_';
    }
    return value;
  }
}
