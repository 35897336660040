import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchCompanyCloneComponent } from './search-companyv3.component';
import {PaginatorModule} from 'primeng/paginator';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { SidebarModule } from 'primeng/sidebar';
import { AddVPCompanyNoTransModule } from 'app/value-position/add-value-positionv2/dynamic_components/add-company_no_trans/vp-add-company.module';
import { MatTooltipModule } from '@angular/material/tooltip';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		AddVPCompanyNoTransModule,
		SidebarModule,
    MatTooltipModule,
		CommonModule,
		NgbModule,
		FormsModule,
		PaginatorModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		AddCompanyModule
	],
	declarations: [
		SearchCompanyCloneComponent
	],
	providers: [],
	exports: [SearchCompanyCloneComponent]
})
export class SearchCompanyCloneModule { }
