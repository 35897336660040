import { Injectable } from '@angular/core';

@Injectable ()

export class AssetsPanelTranslations {
    public config: any = {
        component: 'assetsPanelVpDashbord',
        display_name: 'Asset Panel',
        description: 'Asset Panel for Value Prop dashboard'
    };

    public trans: any = {
        title: 'Assets',
        ch_assetsPanel: 'Sales-ready Assets usable in different sales stages.'
    };
}
