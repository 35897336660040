import { Model } from '@data/services/account/models/model.interface';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { CompanyService } from '@data/services/company/company.service';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { AddCaseStudyDetailTranslations } from './add-casestudy.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Company } from '@shared/models/company.model';

@Component({
  selector: 'app-add-casestudy',
  templateUrl: './add-casestudy.component.html',
  styleUrls: ['./add-casestudy.component.scss'],
})
export class AddCasestudyComponent implements OnInit, OnDestroy {
  @Input() solutionID: string;
  @Input() contextualHelp: { [klass: string]: any };
  @Input() mode: string;
  @Input() caseStudyEdit: { [klass: string]: any };
  @Output() callback = new EventEmitter();

  ngUnsubscribe = new Subject();
  companyName: string;
  showActionLoader = false;
  companyList = false;
  companiesNoResult = false;
  detailedDescription = false;
  companyDetails: Company = {};
  companiesList: Company[] = [];
  companyId: string;
  showCompanyDetails = false;
  isRedact = false;
  tags: string[];
  openAddCompany: boolean;
  fullImagePath: string;
  enableSaveBtn: boolean;
  showTranslate = false;

  pageNumber: number;
  pageNumberSearch = 1;

  pageLimit: number;
  pageLimitSearch = 'all';
  searchTypErr = false;
  order = 'asc';
  sortBy = 'name';

  strSearch = '';
  toggleAddCompany = false;

  selectedModel: Model;
  modelList: Model[] = [];
  sidebar_title = '';

  constructor(
    private commonService: CommonService,
    private accountService: AccountService,
    private companyService: CompanyService,
    private solutionService: SolutionService,
    private notificationService: NotificationService,
    private valueRealizationService: ValuerealizationService,
    public trans: AddCaseStudyDetailTranslations,
    private translationService: TranslationsV2Service
  ) {
    const image_url = this.commonService.getImageUrl();
    this.fullImagePath = image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    if (this.mode === 'add') {
      this.sidebar_title = 'Add Case Study';
    } else {
      this.sidebar_title = 'Edit Case Study';
    }

    this.getModels();
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    if (this.mode === 'edit') {
      this.selectCompany(this.caseStudyEdit.company_id);

      this.isRedact = this.caseStudyEdit.redacted === '1';

      this.tags = this.caseStudyEdit.tag && this.caseStudyEdit.tag.length ? this.caseStudyEdit.tag.split(',') : [];
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.callback.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getModels(): void {
    const searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc';
    this.solutionService
      .getAvailableSolutionsFull(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.modelList = response.result.data;
        }
      });
  }

  getRecentSearchComp(): void {
    this.companiesList = [];
    if (this.companyName && this.companyName !== null && this.companyName !== '') {
      this.companyList = true;
      this.companiesNoResult = false;
      this.showActionLoader = true;
      const payload = {
        searchTerm: this.companyName,
        publicOnly: 0,
        synopsisOnly: 1,
      };
      payload['limit'] = this.pageLimitSearch;
      payload['page'] = this.pageNumberSearch;
      payload['sortBy'] = this.sortBy;
      payload['order'] = this.order;
      this.accountService
        .getSearchCompanies(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (response) => {
            if (response.result && response.result.data && response.result.data.length > 0) {
              this.companiesList = response.result.data;
              this.companyList = false;
              this.showActionLoader = false;
            } else {
              this.companiesNoResult = true;
              this.detailedDescription = false;
            }
            this.showActionLoader = false;
          },
          (error) => {
            this.notificationService.error(error.result, false);
          }
        );
    } else {
      this.companiesList = [];
      this.companiesNoResult = true;
      this.companyList = true;

      this.companyId = '';
      this.showActionLoader = false;
    }
  }

  selectCompany(params): void {
    this.detailedDescription = true;
    this.companiesNoResult = false;

    this.companyId = params;
    this.getCompanyDetail();
  }

  getHostnameFromRegex(url) {
    if (url === undefined) {
      return false;
    }
    if (!url) {
      url = this.companyDetails['name'] + '.com';
    }
    const sourceString = url.replace('http://', '').replace('https://', '').replace('www.', '').replace('.com.com', '.com').split(/[/?#]/)[0];
    return sourceString;
  }

  getCompanyDetail(): void {
    this.showActionLoader = true;
    this.companyService
      .getCompanySummary(+this.companyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.companyDetails = result.result.company;
        }
        this.showCompanyDetails = true;
        this.showActionLoader = false;
        this.detailedDescription = true;
      });
  }

  saveCaseStudy(): void {
    if (!this.solutionID) {
      this.solutionID = this.selectedModel.id;
    }

    if (this.mode === 'edit') {
      const payload = {
        case_study_id: this.caseStudyEdit.id,
        name: this.caseStudyEdit.name,
        tag: this.tags ? this.tags.join() : [],
        reference_url: this.caseStudyEdit.reference_url,
      };

      this.valueRealizationService
        .updateCaseStudyDetails(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result.success) {
            this.notificationService.success('Updated successfully', false);
            this.callback.emit();
          } else {
            this.notificationService.error('Name change failed!', false);
          }
        });
    } else {
      const payload = {
        company_id: this.companyId,
        account_solution_id: this.solutionID,
        redacted: this.isRedact ? 1 : 0,
        tag: this.tags && this.tags.length ? this.tags.join() : '',
      };

      this.solutionService
        .saveCaseStudy(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.notificationService.success('Case Study created successfully', false);
            this.callback.emit();
          }
        });
    }
  }

  addCompany(): void {
    this.openAddCompany = true;
  }
  companyCreated($event): void {
    this.openAddCompany = false;
    this.companyName = $event.name;
    this.getRecentSearchComp();
  }
  enableSave($event): void {
    if ($event === 'true') {
      this.enableSaveBtn = true;
    } else {
      this.enableSaveBtn = false;
    }
  }

  clearCompanyList(): void {
    this.companyName = ''
    this.companiesList = [];
    this.companyList = false;
    this.companyId = '';
    this.showActionLoader = false;
    this.companiesNoResult = false;
  }

}
