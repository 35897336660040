import { Location } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { Subject, Subscription } from 'rxjs';
import { AlertService } from '../../_services/alert.service';
import { AddBenefitModalComponent } from '../common/add-benefit-modal/add-benefit-modal.component';
import * as $ from 'jquery';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CreateMapTranslations } from './create-map.translation';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-map',
  templateUrl: './create-map.component.html',
  styleUrls: ['./create-map.component.scss'],
  providers: [FactsService],
  encapsulation: ViewEncapsulation.None,
})
export class CreateMapComponent implements OnInit, OnDestroy {
  @Output() callbackSaveModel = EventEmitter;
  customcheckboxlabel: string;
  saveQuantity = false;

  solutionCategories: { [klass: string]: any }[] = [];
  fullImagePath: string;
  imageUrl: string;
  pageType: { [klass: string]: any };
  solution: { [klass: string]: any };
  loader: boolean;
  haveId: boolean;
  solutionId: number;
  valueCategoriesBenefits: Array<{ [klass: string]: any }> = [];
  valueCategories: Array<{ [klass: string]: any }> = [];
  showBenefits = false;
  showSituations = false;
  dropdownSettings: { [klass: string]: any } = {};
  selectedItems: Array<{ [klass: string]: any }> = [];
  solutionCategoryList: Array<{ [klass: string]: any }> = [];
  tabInit = 1;

  loaded = false;

  subscription: Subscription;
  subscriptionRouter: Subscription;
  subscriptiongetSolutionDetail: Subscription;
  subscriptiongetSolutionCategoryList: Subscription;
  subscriptioncreateSolutionModel: Subscription;
  subscriptionupdateSolutionDetail: Subscription;
  subscriptionreadBenefitsByValueCategory: Subscription;
  subscriptionSubscribeLoggedIn: Subscription;
  ngUnsubscribe = new Subject();

  constructor(
    private location: Location,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private translationService: TranslationsV2Service,
    public trans: CreateMapTranslations
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.haveId = false;
    this.customcheckboxlabel = 'No';
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.subscriptionRouter = this.route.data.subscribe((value) => (this.pageType = value));
    this.loadPage(this.pageType);
    this.solution = {
      name: '',
      description: '',
      avg_deployment_time: '',
      typical_deployment_type: '0',
      quantity_enabled: false,
      avg_sale_price: '',
      avg_sale_cycle: '',
      solution_category_id: '',
    };
    (<any>$('input:checkbox')).uniform();
    if (this.solutionId) {
      this.readBenefitsByValueCategory('all', this.solutionId);
    }

    this.subscription = this.solutionService.readAllValueCategory.subscribe(() => {
      this.readBenefitsByValueCategory('all', this.solutionId);
    });

    this.subscriptionSubscribeLoggedIn = this.alertService.subscribeLoggedIn().subscribe(() => {
      this.loadPage(this.pageType);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionRouter) {
      this.subscriptionRouter.unsubscribe();
    }
    if (this.subscriptiongetSolutionDetail) {
      this.subscriptiongetSolutionDetail.unsubscribe();
    }
    if (this.subscriptiongetSolutionCategoryList) {
      this.subscriptiongetSolutionCategoryList.unsubscribe();
    }
    if (this.subscriptioncreateSolutionModel) {
      this.subscriptioncreateSolutionModel.unsubscribe();
    }
    if (this.subscriptionupdateSolutionDetail) {
      this.subscriptionupdateSolutionDetail.unsubscribe();
    }
    if (this.subscriptionreadBenefitsByValueCategory) {
      this.subscriptionreadBenefitsByValueCategory.unsubscribe();
    }
    if (this.subscriptionSubscribeLoggedIn) {
      this.subscriptionSubscribeLoggedIn.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadPage(pageType: { [klass: string]: any }) {
    if (pageType.page === 'Create') {
    } else if (pageType.page === 'Update') {
      this.getSolutionsOverview();
    }
    this.getSolutionCategories();
  }

  saveQuantites() {
    if (this.solution.quantity_enabled === true) {
      this.solution.quantity_enabled = false;
      this.customcheckboxlabel = 'No';
    } else {
      this.solution.quantity_enabled = true;
      this.customcheckboxlabel = 'Yes';
    }
  }

  private getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getSolutionsOverview() {
    this.solutionId = +this.route.snapshot.paramMap.get('id');
    this.haveId = true;
    this.loader = true;
    this.subscriptiongetSolutionDetail = this.solutionService.getSolutionDetail(this.solutionId).subscribe((response) => {
      if (response.result) {
        if (!response.result['share_role_type_id']) {
          this.location.back();
          this.loaded = false;
          return false;
        }

        this.solution = response.result;
        // tslint:disable-next-line: triple-equals
        if (this.solution.quantity_enabled == '0') {
          this.solution.quantity_enabled = false;
          this.customcheckboxlabel = 'No';
        } else {
          this.solution.quantity_enabled = true;
          this.customcheckboxlabel = 'Yes';
          document.querySelector('.checker span').className = 'checked';
        }
        this.solutionCategoryList = response.result.solution_category_id;
      }
      this.loader = false;
      this.loaded = true;
    });
  }
  getSolutionCategories() {
    this.subscriptiongetSolutionCategoryList = this.solutionService.getSolutionCategoryList().subscribe((result) => {
      this.solutionCategories = result.result.map((cat) => ({
        label: cat.category,
        value: cat,
      }));
      this.solutionCategories = this.solutionCategories.filter((item) => {
        return item.value.category !== '';
      });
      if (this.solutionCategories) {
        if (this.pageType.page === 'Update') {
          const tempsolution = this.solutionCategoryList;
          if (tempsolution.length > 0) {
            const selectedItems = this.solutionCategories.filter((obj1) => {
              return tempsolution.some((obj2) => {
                return obj1.value.id === obj2;
              });
            });
            this.selectedItems = selectedItems;
          } else {
            this.getSolutionCategories();
            const selectedItems = this.solutionCategories.filter((obj1) => {
              return tempsolution.some((obj2) => {
                return obj1.value.id === obj2;
              });
            });
            this.selectedItems = selectedItems;
          }
        }
      }
    });
  }

  saveSolution() {
    if (this.solution.quantity_enabled === false) {
      this.solution.quantity_enabled = '0';
    } else {
      this.solution.quantity_enabled = '1';
    }

    this.createSolution();
  }
  createSolution() {
    const selectedItems = this.selectedItems.map((item) => {
      return item['id'];
    });
    this.solution['solution_category_id'] = selectedItems;

    this.subscriptioncreateSolutionModel = this.solutionService.createSolutionModel(this.solution).subscribe((response) => {
      if (response.result) {
        this.haveId = true;
        this.solutionId = response.result.id;
        this.notification.success('Solution created successfully', false);

        this.router.navigate(['model/' + this.solutionId]);
      }
    });
  }
  updateSolution() {
    this.solutionId = +this.route.snapshot.paramMap.get('id');
    this.haveId = true;
    this.loader = true;
    const selectedItems = this.selectedItems.map((item) => {
      return item['id'];
    });

    const params = {
      name: this.solution.name,
      description: this.solution.description,
      avg_deployment_time: this.solution.avg_deployment_time,
      typical_deployment_type: this.solution.typical_deployment_type,
      quantity_enabled: this.solution.quantity_enabled,
      avg_sale_price: this.solution.avg_sale_price,
      avg_sale_cycle: this.solution.avg_sale_cycle,
      solution_category_id: selectedItems,
    };
    this.subscriptionupdateSolutionDetail = this.solutionService.updateSolutionDetail(this.solutionId, params).subscribe((response) => {
      if (response.result) {
        this.router.navigate(['models']);
        this.notification.success('Solution updated successfully', false);
      }
      this.loader = false;
    });
  }

  backClicked() {
    this.location.back();
  }

  openAddBenefit(benefit: { [klass: string]: any }) {
    const modalReference = this.modalService.open(AddBenefitModalComponent, { windowClass: 'deleteModal', size: 'lg', backdrop: 'static', keyboard: false });
    modalReference.componentInstance.solutionId = this.solutionId;
    if (benefit) {
      modalReference.componentInstance.selectedBenefit = benefit;
    }
    modalReference.result.then(
      () => {},
      () => {}
    );
  }

  readBenefitsByValueCategory(valueCategoryId: number | string, solutionId: number | string) {
    this.subscriptionreadBenefitsByValueCategory = this.solutionService.readBenefitsByValueCategory(String(valueCategoryId), +solutionId).subscribe((response) => {
      const valueCategories = response.result;
      this.valueCategories = valueCategories;
      this.valueCategoriesBenefits = [];
      valueCategories.forEach((value: { [klass: string]: any }) => {
        if (value.benefits && value.benefits[0] != null) {
          this.valueCategoriesBenefits = this.valueCategoriesBenefits.concat(value.benefits);
        }
      });

      this.showBenefits = this.valueCategories.length > 0 ? true : false;
      this.showSituations = this.valueCategoriesBenefits.length > 0 ? true : false;
    });
  }
}
