import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactorsService } from '@data/services/factors/factors.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { defaultFactor, Factor } from '@data/services/valueposition/models/factor-group.interface';
import { NotificationService } from '@services/notification.service';
import { FaIcon } from '@shared/models/fa-icon.model';
import { CreateTagData, FactorCategoriesResponse } from '@shared/models/factors.models';
import { forkJoin, of, Subject } from 'rxjs';
import { takeUntil, switchMap, map, take } from 'rxjs/operators';
import { Items } from '../interfaces/items.interface';
import { DefaultTranslations } from './ae_factors.translation';
import { snakeCase, cloneDeep } from 'lodash';

interface TableOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-ae-factors',
  templateUrl: './ae_factors.component.html',
  styleUrls: ['./ae_factors.component.scss'],
})
export class AEFactorsFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() mode: string;
  @Input() back: boolean;
  @Input() embed = false;
  @Input() title_factor = '';
  @Input() onScratchpad = false;
  @Input() isOnTheFlyBenefit = false;

  @Output() callback = new EventEmitter<number>();
  @Output() callbackBack = new EventEmitter<boolean>();

  hasFeature103 = this.commonService.checkFeature(103);
  ngUnsubscribe$ = new Subject();

  unitTypes: Items = [];
  scaledBy: Items = [];
  scalesHow: Items = [];
  companyField: Items = [];
  account_id: string;
  factorGroups: FactorCategoriesResponse[] = [];
  loading = false;
  initialized = false;
  tableOptions: TableOption[] = [];
  selectedTable: 'used' | 'relates' | 'situations' = 'used';
  public accountFactorCategoryId: string;

  @ViewChild('formAddEditFactor') formAddEditFactor: NgForm;

  @Input() formObjFactor: Factor = cloneDeep(defaultFactor);

  showTranslate = false;

  faIcons: FaIcon[] = [];

  constructor(
    private factorsService: FactorsService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service
  ) {}

  ngOnInit(): void {
    this.commonService.getFAIconList().subscribe((res) => {
      if (res) {
        const noIcon = {
          id: null,
          label: 'No Icon',
          value: '',
        };
        if (!this.formObjFactor.icon || !this.formObjFactor.icon.value) {
          this.formObjFactor.icon = noIcon;
        }
        this.faIcons = res.icons;
        this.faIcons.unshift(noIcon);
        const tmp = res.icons.filter((x) => x.value == this.formObjFactor.icon.value);
        this.formObjFactor.icon = tmp[0];
      }
    });

    this.account_id = sessionStorage.getItem('aid');
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngAfterViewInit(): void {
    forkJoin({
      factorCategories: this.factorsService.getFactorCategories(this.account_id),
      scalesHow: this.factorsService.getScalesHow(),
      scalesBy: this.factorsService.getScaledBy(this.account_id),
      companyField: this.factorsService.getCompanyField(),
      unitTypes: this.factorsService.getUnitTypes(),
    }).subscribe((response) => {
      this.unitTypes = response && response.unitTypes.result && response.unitTypes.result.length ? response.unitTypes.result : [];
      this.companyField = response && response.companyField.result && response.companyField.result.length ? response.companyField.result : [];
      this.scaledBy = response && response.scalesBy.result && response.scalesBy.result.length ? response.scalesBy.result : [];
      this.scalesHow =
        response && response.scalesHow.result && response.scalesHow.result.scaling_options && response.scalesHow.result.scaling_options.length
          ? response.scalesHow.result.scaling_options
          : [];

      this.scalesHow = this.scalesHow.filter(elem => elem.id !== 0);

      const selectedScalesBy = this.scaledBy.filter((item) => {
        return item.id == this.formObjFactor.scale_type_id;
      });

      this.formObjFactor.scales_by = selectedScalesBy[0];

      this.factorGroups = response.factorCategories && response.factorCategories.result && response.factorCategories.result.length ? response.factorCategories.result : [];

      this.factorGroups.map((elem) => {
        elem['strSearch'] = '';
        elem['collapsed'] = false;
        return elem;
      });

      setTimeout(() => {
        this.setFormFields(this.formObjFactor);
        this.onTableSelectionChange();

        this.formAddEditFactor.form.valueChanges.subscribe(elem => {
          // If scaler is removed, reset scales_how to force selection
          if ( +elem.scales_by?.id === 0 || elem.scales_by === undefined ) {
            this.formObjFactor.scales_how = undefined;
          }
        });
      }, 0);
    });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.title_factor = this.onScratchpad
          ? this.trans.trans.back_to_scratchpad.value
          : this.mode === 'add'
          ? this.trans.trans.add_factor.value
          : this.trans.trans.edit_factor.value;
        this.tableOptions = [
          { label: this.trans.trans.whereFactorIsUsed.value, value: 'used' },
          { label: this.trans.trans.factorRelatesTo.value, value: 'relates' },
          { label: this.trans.trans.situationsOnThisFactor.value, value: 'situations' },
        ];
        this.selectedTable = 'used';
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  close(refresh = false): void {
    this.callbackBack.emit(refresh);
    this.formAddEditFactor.reset();
  }

  insertFactor(form: NgForm, btn?): void {
    let categoryList = [];
    let categoryListOut = '';

    if (form.controls.account_factor_category_id.value && form.controls.account_factor_category_id.value.length) {
      for (let i = 0; i < form.controls.account_factor_category_id.value.length; i++) {
        categoryList.push(form.controls.account_factor_category_id.value[i].id);
      }
    } else {
      categoryList = [];
    }

    categoryListOut = categoryList.join(',');
    const payload = {
      name: form.controls.name.value,
      value_realization_name: form.controls.value_realization_name ? form.controls.value_realization_name.value : '',
      description: form.controls.description ? form.controls.description.value : '',
      unit_type_id: form.controls.unit_type_id.value ? form.controls.unit_type_id.value.id : '',
      factor_precision: form.controls.factor_precision ? form.controls.factor_precision.value : '',
      scales_by: form.controls.scales_by.value ? form.controls.scales_by.value.id : 0,
      scales_how: form.controls.scales_how?.value ? form.controls.scales_how.value.id : 0,
      ratio: form.controls.ratio && +form.controls.scales_how?.value?.id === 2 ? form.controls.ratio.value : 0,
      formula: form.controls.formula && +form.controls.scales_how?.value?.id === 3 ? form.controls.formula.value : '',
      is_key_factor: form.controls.is_key_factor.value ? 1 : 0,
      scalers_page: String(form.controls.scalers_page.value),
      literal_number: form.controls.literal_number.value ? 1 : 0,
      greater_than_zero: form.controls.greater_than_zero.value ? 1 : 0,
      vr_normalized: form.controls.vr_normalized.value ? 1 : 0,

      min: form.controls.min.value ? form.controls.min.value : 1,
      max: form.controls.max.value ? form.controls.max.value : 100,
      increment: form.controls.increment.value ? form.controls.increment.value : 1,
      company_field: form.controls.company_field.value ? form.controls.company_field.value.id : '',
      account_factor_category_id: categoryListOut,
      icon: form.controls.icon.value ? form.controls.icon.value.value : '',
    };

    if (payload.scales_how === 2) {
      payload.ratio = form.controls.ratio.value;
    } else if (payload.scales_how === 3) {
      payload.formula = form.controls.formula.value;
    }

    this.factorsService
      .postSaveFactor(this.account_id, payload)
      .pipe(
        switchMap((factorResponse) => {
          if (this.formObjFactor.has_tag) {
            const payload = this.createFactorAssetPayload(factorResponse.result.account_factor_id);
            return this.factorsService.saveFactorTags(payload, true).pipe(map(() => factorResponse));
          } else {
            return of(factorResponse);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((res) => {
        if (res.result.success) {
          form.resetForm();
          this.callback.emit(res.result.account_factor_id);
          this.notificationService.success(this.trans.trans.changesSavedSuccess.value, false);
          this.close(true);
        } else {
          this.loading = false;
          this.notificationService.error(this.trans.trans.changesFailed.value, false);
        }
      });

    if (!this.formObjFactor.has_tag && this.formObjFactor.tag_data?.id) {
      this.factorsService.deleteAssetTag(this.formObjFactor.tag_data?.id).pipe(take(1)).subscribe();
    }
  }

  updateFactor(form: NgForm, prop: string = null): void {
    let categoryList = [];
    let categoryListOut = '';
    if (!form.controls.id.value) {
      return null;
    }
    if (form.controls.account_factor_category_id.value && form.controls.account_factor_category_id.value.length) {
      for (let i = 0; i < form.controls.account_factor_category_id.value.length; i++) {
        categoryList.push(form.controls.account_factor_category_id.value[i].id);
      }
    } else {
      categoryList = [];
    }

    categoryListOut = categoryList.join(',');
    let payload = {
      account_factor_id: form.controls.id.value,
      name: form.controls.name ? form.controls.name.value : '',
      value_realization_name: form.controls.value_realization_name ? form.controls.value_realization_name.value : '',
      description: form.controls.description ? form.controls.description.value : '',
      unit_type_id: form.controls.unit_type_id && form.controls.unit_type_id.value ? form.controls.unit_type_id.value.id : '',
      factor_precision: form.controls.factor_precision ? form.controls.factor_precision.value : '',
      scales_by: form.controls.scales_by && form.controls.scales_by.value ? form.controls.scales_by.value.id : 0,
      scales_how: form.controls.scales_how && form.controls.scales_how?.value ? form.controls.scales_how.value.id : 0,
      ratio: form.controls.ratio && +form.controls.scales_how?.value?.id === 2 ? form.controls.ratio.value : 0,
      formula: form.controls.formula && +form.controls.scales_how?.value?.id === 3 ? form.controls.formula.value : '',
      is_key_factor: form.controls.is_key_factor.value ? 1 : 0,
      scalers_page: form.controls.scalers_page.value ? 1 : 0,
      literal_number: form.controls.literal_number.value ? 1 : 0,
      greater_than_zero: form.controls.greater_than_zero.value ? 1 : 0,
      vr_normalized: form.controls.vr_normalized.value ? 1 : 0,
      min: form.controls.min.value ? form.controls.min.value : 1,
      max: form.controls.max.value ? form.controls.max.value : 100,
      increment: form.controls.increment.value ? form.controls.increment.value : 1,
      company_field: form.controls.company_field && form.controls.company_field.value ? form.controls.company_field.value.id : '',
      account_factor_category_id: categoryListOut,
      icon: form.controls.icon.value ? form.controls.icon.value.value : '',
      tag: null,
    };

    if (payload.scales_how === 2) {
      payload.ratio = form.controls.ratio.value;
    } else if (payload.scales_how === 3) {
      payload.formula = form.controls.formula.value;
    }

    this.factorsService
      .putSaveFactor(this.account_id, payload)
      .pipe(
        switchMap((res) => {
          if (this.formObjFactor.has_tag) {
            const assetId = this.formObjFactor.tag_data?.id;
            const payload = this.createFactorAssetPayload(this.formObjFactor.id, assetId);
            return this.factorsService.saveFactorTags(payload).pipe(map(() => res.result.account_factor_id));
          } else {
            return of(res.result.account_factor_id);
          }
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe({
        next: (factorId) => {
          if (form.controls && prop && !form.controls[prop].value) {
            this.notificationService.warning(this.trans.trans.customFactorTagWarning.value, false);
          }
          this.callback.emit(factorId);
          this.notify('changesSavedSuccess');
          this.close(true);
        },
        error: () => {
          this.loading = false;
          this.notify('changesFailed', 'error');
        },
      });

    if (!this.formObjFactor.has_tag && this.formObjFactor.tag_data?.id) {
      this.factorsService.deleteAssetTag(this.formObjFactor.tag_data?.id).pipe(take(1)).subscribe();
    }
  }

  setFormFields(factorElement): void {
    if (this.mode === 'edit' && this.formAddEditFactor.controls.id) {
      this.formAddEditFactor.controls.id.setValue(factorElement.id);
      this.formAddEditFactor.controls.name.setValue(factorElement.name);
      this.formAddEditFactor.controls.description.setValue(factorElement.description);
      if (this.hasFeature103) {
        this.formAddEditFactor.controls.value_realization_name.setValue(factorElement.value_realization_name);
      }

      const selectedUnitType = this.unitTypes.filter((item) => {
        return item.id == factorElement.unit_type_id || item.name === factorElement.unit_type;
      });
      this.formAddEditFactor.controls.unit_type_id.setValue(selectedUnitType ? selectedUnitType[0] : []);

      this.formAddEditFactor.controls.factor_precision.setValue(factorElement.factor_precision ? factorElement.factor_precision : '');

      const selectedScalesBy = this.scaledBy.filter((item) => {
        return item.id == factorElement.scale_type_id;
      });

      this.formAddEditFactor.controls.scales_by.setValue(selectedScalesBy ? selectedScalesBy[0] : []);

      const selectedScalesHow = this.scalesHow.filter((item) => {
        return item.id == factorElement.straight_scaling;
      });

      if ( this.formAddEditFactor.controls.scales_how && selectedScalesBy[0].id !== 0 ) {
        this.formAddEditFactor.controls.scales_how.setValue(selectedScalesHow ? selectedScalesHow[0] : []);
      }

      this.formAddEditFactor.controls.is_key_factor.setValue(factorElement.is_key_factor == 1 ? true : false);
      this.formAddEditFactor.controls.scalers_page.setValue(+factorElement.scalers_page ? 1 : 0);
      this.formAddEditFactor.controls.literal_number.setValue(factorElement.literal_number == 1 ? true : false);
      this.formAddEditFactor.controls.greater_than_zero.setValue(factorElement.greater_than_zero == 1 ? true : false);
      this.formAddEditFactor.controls.vr_normalized.setValue(factorElement.vr_normalized == 1 ? true : false);

      this.formAddEditFactor.controls.min.setValue(factorElement.min);
      this.formAddEditFactor.controls.max.setValue(factorElement.max);
      this.formAddEditFactor.controls.increment.setValue(factorElement.increment);

      if (factorElement.has_tag) {
        this.formAddEditFactor.controls.nameTag.setValue(factorElement.tag_data.name);
        this.formAddEditFactor.controls.valueTag.setValue(factorElement.tag);
      }

      const selectedCompanyField = this.companyField.filter((item) => {
        return item.id == factorElement.company_field;
      });
      this.formAddEditFactor.controls.company_field.setValue(selectedCompanyField ? selectedCompanyField[0] : []);

      if (factorElement.account_factor_category_id) {
        if (typeof factorElement.account_factor_category_id === 'number' || typeof factorElement.account_factor_category_id === 'string') {
          const list = Array.isArray(factorElement.account_factor_category_id) ? factorElement.account_factor_category_id : factorElement.account_factor_category_id.split(',');
          const selectedCategories = this.factorGroups.filter(function (array_el) {
            return (
              list.filter(function (anotherOne_el) {
                return anotherOne_el == array_el.id;
              }).length == 1
            );
          });
          this.formAddEditFactor.controls.account_factor_category_id.setValue(selectedCategories);
        } else {
          this.formAddEditFactor.controls.account_factor_category_id.setValue(factorElement.account_factor_category_id);
        }
      } else {
        const categories = [];
        this.factorGroups.map((fg) => {
          if (fg.factors.some((f) => f.id == this.formObjFactor.id)) {
            categories.push(fg);
          }
        });
        this.formObjFactor.account_factor_category_id = categories;
        this.formAddEditFactor.controls.account_factor_category_id.setValue(categories);
      }

      this.title_factor = this.onScratchpad ? this.trans.trans.back_to_scratchpad.value : this.trans.trans.edit_factor.value;
    } else {
      this.title_factor = this.onScratchpad ? this.trans.trans.back_to_scratchpad.value : this.trans.trans.add_factor.value;
    }

    this.initialized = true;
  }

  getFactorCategories(): void {
    this.factorsService
      .getFactorCategories(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.factorGroups = res && res.result && res.result.length ? res.result : [];

        this.factorGroups.map((elem) => {
          elem['strSearch'] = '';
          elem['collapsed'] = false;
          return elem;
        });
      });
  }

  getUnitTypes(): void {
    this.factorsService
      .getUnitTypes()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.unitTypes = res && res.result && res.result.length ? res.result : [];
      });
  }

  getCompanyField(): void {
    this.factorsService
      .getCompanyField()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.companyField = res && res.result && res.result.length ? res.result : [];
      });
  }

  getScaledBy(): void {
    this.factorsService
      .getScaledBy(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.scaledBy = res && res.result && res.result.length ? res.result : [];
      });
  }

  getScalesHow(): void {
    this.factorsService
      .getScalesHow()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.scalesHow = res && res.result && res.result.scaling_options && res.result.scaling_options.length ? res.result.scaling_options : [];
      });
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.formObjFactor = {
        ...this.formObjFactor,
        nameTag: this.formObjFactor.name,
        valueTag: snakeCase(this.formObjFactor.name),
      };
    } else if (this.formObjFactor.tag_data?.id) {
      this.notify('customFactorTagWarning', 'warning');
    }
  }

  createFactorAssetPayload(factorId: number | string, assetId?: string): CreateTagData {
    return {
      factorId: factorId.toString(),
      assetId,
      name: this.formObjFactor.nameTag,
      value: this.formObjFactor.valueTag,
    };
  }

  public onTableSelectionChange() {
    this.accountFactorCategoryId = this.formObjFactor?.account_factor_category_id[0]?.id;
  }

  private notify(transString: string, type: 'error' | 'warning' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
