import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { NotificationService } from '@services/notification.service';
import { UserService } from '@data/services/user/user.service';
import { Subscription, Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { takeUntil } from 'rxjs/operators';
import { PrivilegesChangeTranslation } from './previleges-change.translation';
import { UserPrivilege } from '@shared/models/user-info.model';

@Component({
  selector: 'app-previleges-change',
  templateUrl: './previleges-change.component.html',
  styleUrls: ['./previleges-change.component.scss'],
})
export class PrevilegesChangeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() privilegesUserData: UserPrivilege[];
  @Input() showAdminView: boolean;

  privilegeList = [];
  privilegeId: string[] = [];
  userAdmin: UserPrivilege[] = [];
  showActionLoader = false;
  fullImagePath = '';
  selectedPrivil = [];
  dropdownSettings: { [klass: string]: any };

  subscriptiongetPrivileges: Subscription;
  subscriptionsavePrivileges: Subscription;
  subscriptionupdateUser: Subscription;

  ngUnsubscribe = new Subject();
  showTranslate = false;

  constructor(
    private accountService: AccountService,
    private notificationService: NotificationService,
    private userService: UserService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: PrivilegesChangeTranslation
  ) {
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Privilege',
      enableCheckAll: false,
      enableSearchFilter: false,
      labelKey: 'name',
      badgeShowLimit: 3,
      lazyLoading: false,
    };
  }

  ngAfterViewInit() {
    this.getPrivileges();
  }

  ngOnDestroy() {
    if (this.subscriptiongetPrivileges) {
      this.subscriptiongetPrivileges.unsubscribe();
    }
    if (this.subscriptionsavePrivileges) {
      this.subscriptionsavePrivileges.unsubscribe();
    }
    if (this.subscriptionupdateUser) {
      this.subscriptionupdateUser.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getPrivileges() {
    this.subscriptiongetPrivileges = this.accountService.getPrivileges().subscribe((result) => {
      this.privilegeList = result.result.map((priv) => {
        return {
          label: priv.name,
          value: priv,
        };
      });
      if (this.privilegesUserData) this.getUserPrivileges();
    });
  }

  updatePrivilege() {
    this.showActionLoader = true;
    this.privilegeId = [];
    if (this.selectedPrivil) this.selectedPrivil.filter((priv) => this.privilegeId.push(priv.id));
    const priv = this.privilegeId.join(',');
    const requestData = { privilege: priv };
    const userId = Number(sessionStorage.getItem('uid'));

    if (!this.showAdminView) {
      this.subscriptionsavePrivileges = this.accountService.savePrivileges(requestData, userId).subscribe(
        () => {
          this.notificationService.success('Your account administrator has been notified', false);
          this.showActionLoader = false;
        },
        (error) => {
          this.notificationService.error(error.result, false);
          this.showActionLoader = false;
        }
      );
    } else {
      this.subscriptionupdateUser = this.userService.updateUser(userId, requestData).subscribe(
        () => {
          this.notificationService.success('Privilege updated successfully', false);
          sessionStorage.setItem('privileges', priv);
          this.showActionLoader = false;
        },
        (error) => {
          this.notificationService.error(error.result, false);
          this.showActionLoader = false;
        }
      );
    }
  }

  getUserPrivileges() {
    const privilegesSelectedTemp = [];
    this.userAdmin = this.privilegesUserData.filter((priv) => priv.privilege_id === '5');
    this.privilegesUserData.forEach((upriv) => {
      this.privilegeList.filter((priv) => {
        if (upriv.privilege_id === priv.value.id) privilegesSelectedTemp.push(priv.value);
      });
    });
    this.selectedPrivil = privilegesSelectedTemp;
  }
}
