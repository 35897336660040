<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class='keywest_header'>
      <div class='row'>
        <div class='col-xs-6'>
          <div class='title'>{{trans.trans.valueRealization.value}}
            <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.valueRealization"
            (callback)="getTranslations()"></app-editTranslation>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class='row marg-v'>
      <div class='col-xs-4'>
          <div class="input-group keywest_search">
              <span class="input-group-addon"><i class='fa fa-search'></i></span>
              <input [(ngModel)]="strSearch" (keyup)="tableSolutionList.filter($any($event).target.value, 'value_prop_name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
              <span *ngIf="strSearch !== ''" (click)="strSearch=''; tableSolutionList.filter('', 'value_prop_name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
          </div>
      </div>
  </div>

  <div class="models_page_header_container_small" [ngClass]="{'full-height': (tableRecords$ | async) === 0}">
    <div class='row'>
      <div class='col-xs-12'>
        <p-progressBar *ngIf="loading" class='keywest_progressbar' mode="indeterminate"></p-progressBar>
        <p-table 
          #tableSolutionList
          [value]="realizations"
          [columns]="columns"
          [paginator]="true"
          [rows]="10"
          styleClass='keywest_table'
          tableStyleClass='keywest_table'>

          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{trans.trans[col.field].value}}
                              <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans[col.field]"
                              (callback)="getTranslations()"></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tracker>
            <tr>
              <td *ngFor="let col of columns">
                <span *ngIf="col.field !== 'assigned_by'; else assignedBy" (click)="navToDetail(tracker)">
                    <span [ngClass]="{ 'clickable link': col.field === 'value_prop_name'}">
                        {{tracker[col.field]}}
                    </span>
                </span>
                <ng-template #assignedBy>
                    <span class="'field-with-avatar'">
                        <div class="avatar-circle">
                            {{tracker[col.field] | functionPipe : getInitials}}
                        </div>
                    </span>
                </ng-template>
              </td>
              <td> 
                <span class='keywest_icon_group pull-right'>
                  <span>
                    <app-are-you-sure matTooltip="Delete" matTooltipPosition="above" message='Are you sure?' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteValueRealization(tracker.value_prop_id)'></app-are-you-sure>
                  </span>
                </span>
              </td> 
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading" class="not-hover">
              <td [attr.colspan]="columns.length + 1" style="padding-top: 100px">
                <app-no-records width="200px" [records]="realizations.length > 0">
                  <ng-container *ngIf="realizations.length === 0">
                    {{trans.trans.noRecords.value}}
                  </ng-container>
                  <ng-container *ngIf="realizations.length > 0">
                    {{trans.trans.noResults.value}} "{{strSearch}}"
                  </ng-container>
                </app-no-records>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>


</div>