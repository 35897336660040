import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssetLog } from '@data/services/asset/asset-log.model';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { PrimeIcons } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetsManageProfileTranslations } from '../assets.translation';

@Component({
  selector: 'app-asset-log',
  templateUrl: './asset-log.component.html',
  styleUrls: ['./asset-log.component.scss'],
})
export class AssetLogComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<boolean>();
  breadcrumbKeyValues: Breadcrumbs;
  style2022$: Observable<Boolean>;

  events: AssetLog[];

  search: string;
  strSearch: string;

  actionStyles = {
    1: {
      icon: 'fa fa-plus',
      color: '#e5f0ff',
      iconColor: '#418ffc',
    },
    2: { icon: 'fa fa-pencil', color: '#e6f7f7', iconColor: '#06ADAD' },
    3: { icon: PrimeIcons.TRASH, color: '#fee7e8', iconColor: '#fa6063' },
  };

  verbTranslations = {
    1: 'New Template Added',
    2: 'Edited',
    3: 'Deleted',
  };

	showTranslate = false;

  constructor(
    private translationService: TranslationsV2Service,
    public trans: AssetsManageProfileTranslations,
    private commonService: CommonService,
    private styleService: StyleService,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.getAssetLogs(this.assetService.getLogId());
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.manageAssetTemplates.value]: { routerLink: '/asset-management', active: false },
          [this.trans.trans.changeLog.value]: { routerLink: '', active: true },
        };

        this.verbTranslations[1] = this.trans.trans.newTemplate.value + ' ' + this.trans.trans.added.value;
        this.verbTranslations[2] = this.trans.trans.edited.value;
        this.verbTranslations[3] = this.trans.trans.deleted.value;
      });
  }

  getAssetLogs(id: string): void {
    this.assetService.getAssetTemplateLogs(id).subscribe((res) => {
      const withDates = res.map((obj: AssetLog) => {
        return { ...obj, created: new Date(obj.created) };
      });

      const sortedAsc = withDates.sort((objA, objB) => objB.created.getTime() - objA.created.getTime());
      this.events = [...sortedAsc];
    });
  }

  onSearch(event: KeyboardEvent): void {
    const eventValue = (event.target as HTMLInputElement).value;
    if (eventValue.length > 0) {
      this.strSearch = eventValue;
    } else if (eventValue.length === 0) {
      this.strSearch = '';
    }
  }

  clearSearch() {
    this.strSearch = '';
  }

  ngOnDestroy(): void {
    this.assetService.deleteAssetLogId();
  }
}
