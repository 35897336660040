import { Injectable } from '@angular/core';

@Injectable()
export class AEMaintenanceSituationsTranslations {
  public config: any = {
    component: 'ae_situations',
    display_name: 'Situations Add/Edit',
    description: 'Situations Add/Edit',
  };

  public trans: any = {
    name: 'Name',
    description: 'Description',
    add_situation: 'Add Situation',
    edit_situation: 'Edit Situation',
    ch_add: 'Add',
    ch_edit: 'Edit',
    ch_delete: 'Delete',
    save: 'Save',
    update: 'Update',
    single_select: 'Single Select',
    multi_select: 'Multi-Select',
    type: 'Type',
    are_you_sure: 'Are you sure?',
    add: 'Add',
    singleSelectList: 'Single-Select List',
    multiSelectList: 'Multi-Select List',
    singleSelectDropdown: 'Single-Select Dropdown',
    multiSelectDropdown: 'Multi-Select Dropdown',
    back: 'Back',
    cannotBeDeleted: 'This element is currently being used in a situation and cannot be deleted at this time',
    orderUpdated: 'Situation order updated successfully',
    childNodeSuccess: 'Child node added successfully',
    situationSuccess: 'Situation created successfully',
    situationUpdateSuccess: 'Situation updated successfully',
    createCustomAssetTag: 'Create custom onshow tag for this situation?',
    createCustomBlockTag: 'Create custom block for this situation?',
    customBlockTooltip: 'This block tag will allow for a custom block based on the situation. The name and description of the situation will be accessible. The selected elements will be availabe in a subblock called selected_elements and their name and description will also be available',
    nameTag: 'Name Tag',
    blockNameTag: 'Block Name Tag',
    tagSameValueError: 'Tags cannot have the same values',
    tagsSpecialCharactersError: 'Tags cannot contain special characters',
    valueTag: 'Value Tag',
    cancel: 'Cancel',
    situationValueTag: 'Situation Value Tag',
    valueTagTooltip: 'This onshow tag will show the names of the selected elements for this situation in the given Value Proposition',
    changesSavedSuccess: 'Changes saved successfully.',
    customSituationTagWarning: 'Ensure that you have removed this custom situation tag from your assets to avoid disruption.',
    onShow: 'onshow.',
    _name: '_name',
    _description: '_description',
    value: 'value',
    beginBlock: ';block=begin;sub1=selected_elements',
    customFactorTagWarning: 'Ensure that you have removed this custom factor tag from your assets to avoid disruption.',
  };
}
