import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    
    transform(array: Array<any>, field: string, order: boolean): Array<any> {

        if(array !== undefined && field !== undefined && order !== undefined) {

            var orderValue = order ? 1 : -1;

            array.sort((a: any, b: any) => {
                let x = a[field].toLowerCase();
                let y = b[field].toLowerCase();

                if (x < y) {
                    return -(orderValue);
                } else if (x > y) {
                    return (orderValue);
                } else {
                    return 0;
                }

            });
        }

        return array;
    }

}
