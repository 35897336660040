import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2AEOPObjectiveTranslations {
  public config: any = {
    component: 'ae_op_objectives',
    display_name: 'Operational Objectives Add/Edit',
    description: 'Add/Edit solutions operational Objectives',
  };

  public trans: any = {
    name: 'Name',
    description: 'Description',
    add_op_ob: 'Add Operational Objective',
    edit_op_ob: 'Edit Operational Objective',
    save: 'Save',
    update: 'Update',
    value_category_name: 'Value Category Name',
    are_you_sure: 'Are you sure?',
    back: 'Back',
    successUpdate: 'Operational Objective updated successfully.',
    successCreate: 'Operational Objective created successfully.',
    tagMustBeUnique: 'Tags must be unique.',
    createCustomAssetTag: 'Create custom asset tag for this Operational Objective?',
    ooTag: 'Operational Objective Tag',
    valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    ooTagWarning: 'Ensure that you have removed this operational objective tag from your assets to avoid disruption.',
  };
}
