import { MenuModule } from 'primeng/menu';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { AssetService } from '@data/services/asset/asset.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CaseStudiesTranslations } from './case-studies.translation';
import { StyleService } from 'app/style.service';
import { CaseStudy } from '@shared/models/case-study.model';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss'],
})
export class CaseStudiesComponent implements OnInit, OnDestroy {
  @Input() solutionID: string;
  @Input() tabInit: number;
  @Input() can_edit: boolean;

  ngUnsubscribe = new Subject();

  pageNumber: number;
  pageLimit: number;
  caseStudiesList: CaseStudy[] = [];
  caseStudiesListLoader = false;
  showCaseStudiesList = false;
  caseStudyId: string;
  caseStudy: CaseStudy;

  searchType: string;
  caseStudiesName: string;
  selectedCaseStudy: CaseStudy;
  searchText: string;
  newCaseStudyName: string;
  cloneRename = false;

  valuePropId: number;

  assetUrl = '';
  assetGenerator = '';

  loadingSave = false;
  toggleAddCaseStudies = false;
  dropdown: { [klass: string]: any }[] = [];
  sidebar_title = 'Add New Case Study';

  showTranslate = false;

  cols = [
    { field: 'name', header: 'Name' },
    { field: 'dateCreated', header: 'Date Created' },
    { field: 'testimonials', header: 'Testimonials' },
    { field: 'sections', header: 'Sections' },
    { field: 'benefits', header: 'Benefits' },
  ];

  caseStudiesSearch = '';

  mode: string;
  caseStudyEdit: { [klass: string]: any } = {};
  toggleShowTags = false;
  tagList: string[] = [];
  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private solutionService: SolutionService,
    private router: Router,
    private notification: NotificationService,
    private assetService: AssetService,
    private route: ActivatedRoute,
    private translationService: TranslationsV2Service,
    public trans: CaseStudiesTranslations,
    private styleService: StyleService
  ) { }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.solutionService.readCaseStudy.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getCaseStudies();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.assetUrl = this.commonService.getAssetUrl();
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');

    this.getCaseStudies();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcEditCaseStudy(elem: CaseStudy): void {
    this.mode = 'edit';
    this.caseStudyEdit = elem;
    this.toggleAddCaseStudies = true;
    this.sidebar_title = 'Edit Case Study';
  }

  funcShowAddCaseStudies(): void {
    this.toggleAddCaseStudies = !this.toggleAddCaseStudies;
    this.sidebar_title = 'Add New Case Study';
  }

  getCaseStudies(): void {
    const searchLimit = '/limit/all/page/' + this.pageNumber;
    this.caseStudiesListLoader = false;
    this.solutionService
      .getCaseStudies(this.solutionID, searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.case_studies) {
			this.caseStudiesList = response.result.case_studies.data.map((caseS) => ({ // added to fix issue reported in SUP-1097 TODO remove when that is fixed
                ...caseS,
                section: caseS.sections ? caseS.sections.map((section) => ({
                    ...section,
                    case_study_section_images: section.case_study_section_images ? section.case_study_section_images.map((image) => ({
                        ...image,
                        type: image.type === 'link' ? image.type : 'media'
                    })) : null
                })) : null
            }));
            
            this.caseStudiesListLoader = true;

          this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => (this.caseStudyId = params['caseid']));
          if (this.caseStudyId) {
            this.caseStudy = this.caseStudiesList.filter((x) => x.id === this.caseStudyId)[0];
          }
        }
      });
  }

  searchCaseStudies(): void {
    if (this.caseStudiesName && this.caseStudiesName != null && this.caseStudiesName !== '') {
      this.caseStudiesListLoader = true;
      const payload = {
        searchType: this.searchType,
        searchTerm: this.searchType === 'tag' ? this.caseStudiesName.trim() : this.caseStudiesName,
        page: this.pageNumber,
        limit: this.pageLimit,
        solution_id: this.solutionID,
      };

      this.solutionService
        .searchCaseStudies(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.caseStudiesList = response.result.case_studies.data;
          }
          this.caseStudiesListLoader = false;
        });
    } else {
      this.caseStudiesList = [];
      this.getCaseStudies();
    }
  }

  navigateCaseStudy(caseStudy: CaseStudy): void {
    this.caseStudyId = caseStudy['id'];
    this.caseStudy = caseStudy;
  }

  deleteCaseStudy(case_study_id: number): void {
    this.solutionService
      .deleteCaseStudy(case_study_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.getCaseStudies();
          this.notification.success(response.result, false);
        }
      });
  }

  funcBack(): void {
    this.router.navigate(['/modelv2/' + this.caseStudy.account_solution_id]);
    this.caseStudy = null;
    this.caseStudyId = null;
  }

  downloadAsset(caseStudy: CaseStudy): void {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');

    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.assetService
      .getCaseStudyAssetTemplate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response && response !== null && typeof response !== 'string') {
          const downloadUrl =
            this.assetUrl +
            'assets/' +
            this.assetGenerator +
            '?account_asset_template_id=' +
            response.result.id +
            '&user_id=' +
            userId +
            '&token=' +
            token +
            '&case_study_id=' +
            caseStudy.id;

          window.location.assign(downloadUrl);
        } else {
          this.notification.error('You need a template file in order to download this asset. Contact your administrator.', false);
        }
      });
  }



  updatecaseStudy(): void {
    const payload = {
      case_study_id: this.selectedCaseStudy['id'],
      value_prop_id: this.valuePropId,
    };

    this.solutionService
      .updatecaseStudy(payload, this.solutionID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success) {
          this.notification.success('Value prop assigned successfully', false);
        }
      });
  }

  funcAddedCaseStudy(): void {
    this.toggleAddCaseStudies = false;
    this.getCaseStudies();
  }

  onCloseSidebar(): void {
    this.mode = null;
    this.toggleAddCaseStudies = false;
    this.caseStudyEdit = null;
  }
}
