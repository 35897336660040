import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@services/alert.service';
import { UserService } from '@data/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '@data/services/common/common.service';
import { Translations } from '@data/services/translation/translation.service';
import { UserInfo } from '@shared/models/user-info.model';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss'],
})
export class AuthorizeComponent implements OnInit, OnDestroy {
  isForgotPassword: boolean;
  currYear = new Date().getFullYear();

  subscriptionlogin: Subscription;
  @Output() closeLogin: EventEmitter<null> = new EventEmitter();
  profileImagePath: string;
  companyLogo: string;
  companyIcon: string;
  flagUserLanguage: boolean;
  currentColor: string;
  systemColor: boolean;
  header_color = '#90a4ae';
  medium_chart_colors: string[] = [
    '#e57373',
    '#f06292',
    '#ba68c8',
    '#9575cd',
    '#7986cb',
    '#64b5f6',
    '#4fc3f7',
    '#4dd0e1',
    '#4db6ac',
    '#81c784',
    '#aed581',
    '#dce775',
    '#fff176',
    '#ffd54f',
    '#ffb74d',
    '#ff8a65',
    '#a1887f',
    '#e0e0e0',
    '#90a4ae',
  ];
  service_url: string;
  client_id = '';
  org_url = '';
  user_email = '';
  user_details: UserInfo;
  no_bueno = false;
  paramsObj: any;

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private userService: UserService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
    private commonservice: CommonService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
    this.service_url = this.commonservice.getServiceUrl();

    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.paramsObj = params;
    });

    if (this.paramsObj) {
      this.user_email = this.paramsObj.params.email;
      this.client_id = this.paramsObj.params.client_id;

      const payload = {
        email: this.user_email,
      };

      this.userService.verifyUser(payload).subscribe((res) => {
        if (res.result.id && res.result.email) {
          this.user_details = res.result;
        } else {
          this.no_bueno = true;
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptionlogin) {
      this.subscriptionlogin.unsubscribe();
    }
  }

  receiveMessage: any = (event: any) => {
    if (event.data) {
      this.user_email = event.data.email;
      this.client_id = event.data.client_id;
      this.org_url = event.data.org_url;

      const payload = {
        email: this.user_email,
      };

      this.userService.verifyUser(payload).subscribe((res) => {
        if (res.result.id && res.result.email) {
          this.user_details = res.result;
        } else {
          this.no_bueno = true;
        }
      });
    }
  }

  ngOnSubmit(): void {
    this.alertService.clear();

    const responseType = 'code';
    const state = 'xyz';
    const authorized = 'yes';

    window.location.href =
      this.service_url +
      'auth/authorize.php?app=dynamics&response_type=' +
      responseType +
      '&client_id=' +
      this.client_id +
      '&state=' +
      state +
      '&authorized=' +
      authorized +
      '&uid=' +
      this.user_details.id +
      '&org_url=' +
      this.org_url;
  }
}
