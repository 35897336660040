import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyService } from '@data/services/company/company.service';
import { Company } from '@shared/models/company.model';
import { SearchCompanyTranslations } from '../search-companyv3.translation';

@Component({
  selector: 'app-company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.scss'],
})
export class CompanyTableComponent {
  @Input() cols: Array<{ field?: string; header?: string; displayCondition?: boolean }>;
  @Input() companiesList: Company[] = [];
  @Input() featureCustomAccountTable = false;
  @Input() noResultsMessage = '';
  @Input() trans: SearchCompanyTranslations;
  @Input() isRecentTable = true;
  @Input() favoritedCompanies: Company[] = [];

  @Output() selectCompany = new EventEmitter<Company>();
  @Output() removeFromFavorites = new EventEmitter<Company>();
  @Output() addToFavorites = new EventEmitter<Company>();

  constructor(private companyService: CompanyService) {}

  public getLogo(companyName: string, logoPath = null): string {
    return logoPath ? logoPath : this.companyService.getLogo(companyName);
  }

  public onImgError(company: Company) {
    company.logo_path = null;
  }

  public onSelectCompany(company: Company) {
    this.selectCompany.emit(company);
  }

  public onAddToFavorites(company: Company) {
    this.addToFavorites.emit(company);
  }

  public onRemoveFromFavorites(company: Company) {
    this.removeFromFavorites.emit(company);
  }

  public isCompanyFavorited(company: Company): boolean {
    const found = this.favoritedCompanies.find((comp) => comp.id === company.id);
    return found ? true : false;
  }
}
