<form name="formAddEditCategory" #formAddEditCategory="ngForm">
	<div class='sidebar_wrapper'>
		<div class='sidebar_header' *ngIf="mode == 'add' ">
			{{trans.trans.add_model_group.value}} 
			<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
			[transObj]="trans.trans.add_model_group" (callback)="getTranslations()"></app-editTranslation>
		</div>
		<div class='sidebar_header' *ngIf="mode == 'edit' ">
			{{trans.trans.edit_model_group.value}} 
			<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
			[transObj]="trans.trans.edit_model_group" (callback)="getTranslations()"></app-editTranslation>
		</div>

		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<div class="row">
					<div class="col-md-12" appNGError [elemControl]='formAddEditCategory.controls.category'>
						<label for="category" class="sidebar_label">{{trans.trans.name.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
						[transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<input type="text" class="form-control" ret-data='Model Category Name' [(ngModel)]="selectedElem.category" name="category" [placeholder]="trans.trans.model_category_name.value" required>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12" >
						<label for="description" class="sidebar_label">{{trans.trans.description.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" 
						[transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
						</label>
						<textarea name='description' ret-data='Description' [(ngModel)]="selectedElem.description" rows='4' class='form-control'></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class='btn_container'>
			<div class='row'>
				<div class="col-md-12">
					<input type='hidden' name='id' [(ngModel)]="selectedElem.id">
					<app-btn-save *ngIf="mode == 'add'" [form]='formAddEditCategory' (callback)='insertModelCategory(formAddEditCategory)' [text]='trans.trans.save.value' class='btn btn_customizable pull-right'></app-btn-save>
					<app-btn-save *ngIf="mode == 'edit'" [form]='formAddEditCategory' (callback)='updateModelCategory(formAddEditCategory)' [text]='trans.trans.update.value' class='btn btn_customizable pull-right'></app-btn-save>
					<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.areYouSure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
				</div>
			</div>
		</div>
	</div>
</form>