<div class="row flex-jend margin-15-y">
  <div class="col-md-4 mr-auto" *ngIf="style2022">
    <h1 class="mr-0">
      <span class="breadcrumb-title clickable" routerLink="/dashboard"> Dashboard </span>
      <span class="breadcrumb-title marg-h">></span>
      <span class="breadcrumb-title-active">
        {{ trans.trans.support.value }}
      </span>
    </h1>
  </div>
</div>
<div class="models_panel" style="margin-bottom: 20px">
  <dl-menubar *ngIf="style2022" styleClass="details_menu_bar" class="details_menu_bar" [model]="menuItems"></dl-menubar>

  <div class="models_page_header_container_small">
    <div class="keywest_header" *ngIf="!style2022">
      <div class="row">
        <div class="col-xs-10">
          {{ trans.trans.support.value }}
        </div>
      </div>
    </div>
    <div style="flex: 1; height: 100vh; padding: 20px; padding-bottom: 60px; margin-bottom: 160px; overflow-y: auto; overflow-x: hidden">
      <div class="row">
        <div class="col-xs-12">
          <dl-menubar *ngIf="!style2022" styleClass="details_menu_bar" class="details_menu_bar" [model]="menuItems"></dl-menubar>

          <div *ngIf="menuView === 'faq'">
            <app-faq></app-faq>
          </div>

          <div *ngIf="menuView === 'issues'">
            <app-issues></app-issues>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
