import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class DefaultTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'situations',
    display_name: 'Situations',
    description: 'Situations',
  };

  public trans = this.typeObjectKeys({
    situations: 'Situations',
    name: 'Situation Type',
    sequence: 'Display Order',
    add_new: 'Add New',
    ch_edit: 'Edit',
    ch_view: 'View',
    customSquence: 'Custom Sequence',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    situationName: 'Situation Name',
    move: 'Move',
    cancel: 'Cancel',
    orderUpdate: 'Situation order updated successfully',
    editTitle: 'Edit Situation',
    addTitle: 'Add Situation',
    processingOrder: 'Processing Order',
    saveProcessing: 'Save Processing Order',
    editSituation: 'Click a situation name to edit the situation',
    setProcessingOrder:
      'Set Processing Order (higher numbers are processed first and lower numbers are processed last) by dragging and dropping situations or by utilizing controls in kebab menu.',
    displayOrder: 'Display order is set in the Admin Settings / Sequencing Screen.',
  });
}
