<form name="formAEMaturity" #formAEMaturity="ngForm">
	<div class='sidebar_wrapper'>
		<div class='sidebar_header'>
			<div *ngIf="mode == 'add'">Add Maturity</div>
			<div *ngIf="mode == 'edit'">Edit Maturity</div>
		</div>
		<div class='sidebar_container'>
			<div class='keywest_panel'>
				<div class='row'>
					<div class='col-xs-12'>
						<label>Name</label>
						<input type='text' name='name' [(ngModel)]="elem.name" class='form-control'>
					</div>
				</div>
				<div class='row'>
					<div class='col-xs-12'>
						<label>Description</label>
						<textarea name='description' [(ngModel)]="elem.description" rows='5' class='form-control'></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class='btn_container'>
			<div class='row'>
				<div class="col-md-12">
					<app-btn-save *ngIf="mode == 'add'" [form]='formAEMaturity' (callback)='postMaturity()' text='Save' class='btn btn_customizable pull-right'></app-btn-save>
					<app-btn-save *ngIf="mode == 'edit'" [form]='formAEMaturity' (callback)='updateMaturity()' text='Update' class='btn btn_customizable pull-right'></app-btn-save>
				</div>
			</div>
		</div>
	</div>
</form>