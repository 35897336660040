import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { AccountService } from '@data/services/account/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';

@Component({
	selector: 'app-account-stage-types',
	templateUrl: './account-stage-types.component.html',
	styleUrls: ['./account-stage-types.component.scss']
})
export class AccountStageTypesComponent implements OnInit, OnDestroy {

	fullImagePath: string;
	accountStages: Array<any> = [];
	accountStage: any = {};
	accountStageName: string;
	accountStagesLoader: boolean = false;
	edit: boolean = false;

	subscriptiongetAccountStages: Subscription;
	subscriptionupdateAccountStages: Subscription;
	subscriptionsaveAccountStages: Subscription;

	constructor(
		private commonService: CommonService,
		private accountService: AccountService,
		private modalService: NgbModal,
		private notification: NotificationService,
		private translateService: TranslateService,
		public trans: Translations
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit() {
		this.getAccountStages();
	}

	ngOnDestroy() {
		if (this.subscriptiongetAccountStages) { this.subscriptiongetAccountStages.unsubscribe(); }
		if (this.subscriptionupdateAccountStages) { this.subscriptionupdateAccountStages.unsubscribe(); }
		if (this.subscriptionsaveAccountStages) { this.subscriptionsaveAccountStages.unsubscribe(); }
	}

	getAccountStages() {
		let aid = sessionStorage.getItem('aid');
		this.accountStagesLoader = true;

		this.subscriptiongetAccountStages = this.accountService.getAccountStages(aid).subscribe(response => {
			this.accountStages = [];
			if (response.result) {
				this.accountStages = response.result;
			}
			this.accountStagesLoader = false;
		});
	}

	openAddAccountStage(content, mode, accountStage?) {
		this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		this.accountStageName = '';

		if (mode == 'edit') {
			this.edit = true;
			this.accountStage = accountStage;
			this.accountStageName = accountStage['name'];
		}
		else {
			this.edit = false;
		}
	}

	ngOnSubmit(formValue) {
		if (this.edit) {
			let aid = sessionStorage.getItem('aid');
			let payload = {
				"name": formValue['accountStageName'],
				"account_stage_type_id": this.accountStage['id']
			};

			this.subscriptionupdateAccountStages = this.accountService.updateAccountStages(aid, payload).subscribe(response => {
				if (response.result) {
					this.notification.success('Account Stage type updated successfully', false);
					this.getAccountStages();
				}
			});
		}
		else {
			let aid = sessionStorage.getItem('aid');
			let payload = {
				"name": formValue['accountStageName']
			};

			this.subscriptionsaveAccountStages = this.accountService.saveAccountStages(aid, payload).subscribe(response => {
				if (response.result && response.result.success) {
					this.notification.success('Account Stage type added successfully', false);
					this.getAccountStages();
				}
			});
		}
	}

	openDeleteAccountStage(content, accountStage) {
		this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		this.accountStage = accountStage;
	}

	deleteAccountStage() {
		let aid = sessionStorage.getItem('aid');

		this.accountService.deleteAccountStage(aid, this.accountStage['id']).subscribe(response => {
			if (response.result) {
				this.notification.success('Account Stage type deleted successfully', false);
				this.getAccountStages();
			}
		});
	}

}
