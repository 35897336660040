import { Benefit } from '@shared/models/value-prop.model';
import { Component, OnInit, ViewChild, ViewChildren, QueryList, Input, EventEmitter, Output, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ActivatedRoute } from '@angular/router';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AEBenefitsCasestudyTranslations } from './ae_benefits-casestudy.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ExpenseType } from '@data/services/valueprop/models/cost.model';

@Component({
  selector: 'app-ae-benefits-casestudy',
  templateUrl: './ae_benefits-casestudy.component.html',
  styleUrls: ['./ae_benefits-casestudy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AEBenefitsCasestudyComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() type: string;
  @Input() caseStudy: { [klass: string]: any };
  @Input() selectedBenefit: Benefit;
  @Input() mode: 'add' | 'edit';

  @Output() valueChange = new EventEmitter();
  @Output() closeCallback = new EventEmitter();
  ngUnsubscribe = new Subject();
  showTranslate = false;
  @ViewChild('addBenefitForm') addBenefitForm: NgForm;

  @ViewChildren('benefitsAdded') benefitsAdded: QueryList<NgForm>;

  benefitsForm = {
    benefits: undefined,
    company_id: undefined,
    case_study_id: undefined,
    case_study_benefit_id: undefined,
  };

  isBenifitsSelected: boolean;

  availableBenefits: Array<Benefit> = [];
  selectedBenefits: Benefit[] = [];

  selectedBenefitRemove: Benefit;
  selectedBenefitMove: Benefit;
  image_url: string;
  fullImagePath: string;
  actionLoader = false;
  BenefitsList: Array<Benefit> = [];
  unitTypeList: ExpenseType;
  valuePropId: number;
  benefitImpValue: string;
  benefitImpType: string;
  impTypesList: { [klass: string]: any }[] = [];

  sidebar_title: { value: string } = { value: '' };
  toggleAddEditBenefit = false;
  searchText = '';
  loadingSave = false;

  constructor(
    private commonService: CommonService,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private valuepropService: ValuepropService,
    private valuerealizationService: ValuerealizationService,
    public trans: AEBenefitsCasestudyTranslations,
    private translationService: TranslationsV2Service
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getUnitType();

    if (this.type == 'valueprop') {
      this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => (this.valuePropId = params['id']));
    }
    this.getBenefitsList();
    this.getImpTypes();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.mode === 'edit') {
        this.openEditBenefit(this.selectedBenefit);
      } else if (this.mode === 'add') {
        this.funcAddEditBenefitToggle();
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.sidebar_title = this.mode === 'add' ? this.trans.trans.addBenefit : this.trans.trans.editBenefit;
      });
  }

  addBenefitToSelected(benefit: Benefit): void {
    benefit.disabled = true;
    this.isBenifitsSelected = true;
    this.selectedBenefits.push(benefit);
  }

  funcAddEditBenefitToggle(): void {
    this.mode = 'add';

    this.addBenefitForm.controls.company_id.setValue(this.caseStudy.company_id);
    this.addBenefitForm.controls.case_study_id.setValue(this.caseStudy.id);
  }

  selectBenefitToMove(selectedBenefit: Benefit): void {
    this.getImpTypes();
    this.benefitImpValue = '';
    this.selectedBenefitMove = selectedBenefit;
    this.selectedBenefitRemove = null;
  }

  selectBenefitToRemove(i, benefit: Benefit): void {
    benefit.disabled = false;
    this.selectedBenefits.splice(i, 1);
    this.isBenifitsSelected = this.selectedBenefits.length > 0 ? true : undefined;
  }

  moveSelectedBenefit() {
    if (this.selectedBenefits.indexOf(this.selectedBenefitMove) == -1 && this.selectedBenefitMove !== null && this.benefitImpValue) {
      for (let i in this.impTypesList) {
        if (this.impTypesList[i]['id'] === this.benefitImpType) {
          this.selectedBenefitMove['improvement'] = {
            value: this.benefitImpValue,
            type: this.benefitImpType,
            typeAbbr: this.impTypesList[i]['abbr'],
          };
        }
      }
      this.selectedBenefits.push(this.selectedBenefitMove);
    }
  }
  removeSelectedBenefit(): void {
    if (this.selectedBenefits.length > 0 && this.selectedBenefits.indexOf(this.selectedBenefitRemove) !== -1 && this.selectedBenefitRemove !== null) {
      this.selectedBenefits.splice(this.selectedBenefits.indexOf(this.selectedBenefitRemove), 1);
    }
  }

  getBenefitsList(): void {
    this.actionLoader = true;
    if (this.type == 'solution') {
      this.solutionService
        .getBenefitsList(this.caseStudy['account_solution_id'])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.removeSavedBenefits(response.result.solution_metrics);
          }
        });
    } else {
      const searchLimit = this.valuePropId;
      this.valuepropService.getValuePropBenefits(searchLimit, 0, 0).subscribe((response) => {
        if (response.result) {
          this.removeSavedBenefits(response.result.metrics);
        }
      });
    }
    this.actionLoader = false;
  }

  removeSavedBenefits(benefits): void {
    if (this.caseStudy['benefits'] && Array.isArray(this.caseStudy['benefits'])) {
      const savedBenefits = this.caseStudy['benefits'].map((benefit) => benefit['account_solution_metric_id']);
      for (let i = 0; i < benefits.length; i++) {
        if (savedBenefits.indexOf(benefits[i]['account_solution_metric_id']) != -1) {
          benefits.splice(i, 1);
        }
      }
    }
    this.BenefitsList = benefits;
  }

  getUnitType(): void {
    this.solutionService
      .getUnitType()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.unitTypeList = response.result;
        }
      });
  }
  getImpTypes(): void {
    this.solutionService.getImpTypes().subscribe((response) => {
      if (response.result) {
        this.impTypesList = response.result.improvementTypes.map((type) => {
          if (type.name === 'Improvement') {
            return {
              ...type,
              abbr: 'Text',
            };
          } else {
            return type;
          }
        });
        this.benefitImpType = this.impTypesList[0]['id'];
      }
    });
  }

  processBenefitsSave(response): void {
    if (response.result) {
      this.valueChange.emit();
      this.notification.success('Benefit Tied successfully', false);
    }
  }

  benefitsSave(form: NgForm): void {
    const allBenefitsValid = this.checkSelectedBenefitsTypeAndValue(this.benefitsAdded);

    if (form.invalid) {
      this.notification.error('Make sure you have added a benefit', false);
      return;
    } else if (!allBenefitsValid) {
      this.notification.error('Please make sure all your benefits have Improvements and Types', false);
      return;
    }
    let benefitsList = [];
    for (const i in this.selectedBenefits) {
      const benefit = {
        benefit_id: this.selectedBenefits[i]['id'],
        improvement: this.selectedBenefits[i]['benefitImpValue'],
        improvement_type_id: this.selectedBenefits[i]['benefitImpType'],
      };

      if (this.type != 'solution') {
        benefit['account_solution_metric_id'] = this.selectedBenefits[i]['account_solution_metric_id'];
      }

      benefitsList.push(benefit);
    }

    const payload = {
      company_id: form.controls.company_id.value,
      case_study_id: form.controls.case_study_id.value,
      benefits: benefitsList,
    };

    if (this.type == 'solution') {
      this.solutionService
        .saveCaseStudyBenefits(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.processBenefitsSave(response);
        });
    } else {
      this.valuerealizationService
        .saveCaseStudyBenefits(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.processBenefitsSave(response);
        });
    }
  }

  openEditBenefit(benefit): void {
    this.mode = 'edit';
    this.sidebar_title.value = 'Edit benefit';
    this.toggleAddEditBenefit = !this.toggleAddEditBenefit;
    benefit.benefitImpValue = benefit.improvement;
    benefit.benefitImpType = benefit.improvement_type_id;
    this.selectedBenefits = [benefit];

    this.isBenifitsSelected = true;

    this.addBenefitForm.controls.company_id.setValue(benefit.company_id);
    this.addBenefitForm.controls.case_study_id.setValue(benefit.case_study_id);
    this.addBenefitForm.controls.case_study_benefit_id.setValue(benefit.id);
  }

  processeditBenefit(response): void {
    if (response.result && response.result.success) {
      this.notification.success('Benefit Updated successfully', false);
      this.valueChange.emit();
      this.resetValues();
    }
  }

  editBenefit(): void {
    const allBenefitsValid = this.checkSelectedBenefitsTypeAndValue(this.benefitsAdded);

    if (!allBenefitsValid) {
      this.notification.error('Please make sure all your benefits have Improvements and Types', false);
      return;
    }

    for (const i in this.selectedBenefits) {
      const payload = {
        company_id: this.selectedBenefits[i]['company_id'],
        case_study_id: this.selectedBenefits[i]['case_study_id'],
        benefits: [
          {
            case_study_benefit_id: this.selectedBenefits[i]['id'],
            improvement: this.selectedBenefits[i]['benefitImpValue'],
            improvement_type_id: this.selectedBenefits[i]['benefitImpType'],
          },
        ],
      };

      if (this.type == 'solution') {
        this.solutionService
          .updateCaseStudyBenefit(payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            this.processeditBenefit(response);
            this.valueChange.emit();
            this.toggleAddEditBenefit = !this.toggleAddEditBenefit;
          });
      } else {
        this.valuerealizationService
          .updateCaseStudyBenefit(payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response) => {
            this.processeditBenefit(response);
            this.valueChange.emit();
            this.toggleAddEditBenefit = !this.toggleAddEditBenefit;
          });
      }
    }
  }

  resetValues(): void {
    this.selectedBenefits = [];
    this.isBenifitsSelected = undefined;
    this.addBenefitForm.reset();
  }

  checkSelectedBenefitsTypeAndValue(selectedBenfitsList: QueryList<NgForm>): boolean {
    return selectedBenfitsList.reduce((acc, curr) => {
      if (curr.invalid) {
        (acc as boolean) = false;
      }
      return acc;
    }, true);
  }
}
