import { NgModule } from '@angular/core';
import { WorkflowQuestionsComponent } from '@shared_components/workflow-questions/workflow-questions.component';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { WorkflowQuestionsTranslation } from './workflow-questions.translation';
import { DropdownModule } from 'primeng/dropdown'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopulateDropdownDisabledPipe } from 'app/pipe/populate-dropdown-disabled.pipe';

@NgModule({
  declarations: [
    WorkflowQuestionsComponent,
    PopulateDropdownDisabledPipe,
  ],
  imports: [
    CommonModule,
    RadioButtonModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [WorkflowQuestionsComponent],
  providers: [WorkflowQuestionsTranslation]
})
export class WorkflowQuestionsModule { }
