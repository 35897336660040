<div class="companyDetailView">
  <div class="row">
    <div class="col-md-11">
      <div routerLink="/dashboard" role="button" class="m-b-xxl"><i class="fa fa-chevron-left"></i> {{ trans.company.back | translate }}</div>

      <div *ngIf="showDetail">
        <div class="row">
          <div class="col-md-6">
            <div class="video">
              <iframe
                width="100%"
                height="270px"
                src="https://www.youtube.com/embed/pSTCrphCsr4?&amp;autoplay=1&amp;rel=0&amp;fs=0&amp;theme=light&amp;showinfo=0&amp;controls=0&amp;autohide=1&amp;color=white"
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen=""
              ></iframe>
            </div>
          </div>
          <div class="col-md-6">
            <div class="companyDetails">
              <h4>{{ company.name }}</h4>
              <h5>{{ company.address1 }}, {{ company.address2 }}</h5>

              <div class="ratingSection">
                <p>
                  <span class="avgRating">{{ ratingsCount }} <i class="fa fa-star"></i></span>
                  {{ trans.company.ratings | translate }} {{ reviewsCount }} {{ trans.company.reviews | translate }}
                </p>
              </div>

              <div class="sampleReport">
                <p>
                  <u
                    ><a target="_blank" href="https://media.value-cloud.com/images/smart_company_brief_pg.pdf" download>{{ trans.company.view_sample_report | translate }}</a></u
                  >
                </p>
              </div>

              <div class="comparison">
                <h5>
                  {{ trans.company.comparison | translate }} <a><i class="icon-note" (click)="open(comparisonModal, company['dl_industry_id'], company['dl_sector_id'])"></i></a>
                </h5>
                <p class="infoText" *ngIf="currentIndustryName && currentIndustryName[0]">{{ currentIndustryName[0].name }}</p>
              </div>

              <div>
                <p>
                  <button class="btn btn-primary" (click)="redirectToPlans()"><i class="fa fa-download"></i> {{ trans.company.download_full_report | translate }}</button>
                </p>
                <p class="infoText">{{ trans.company.credit_per_report_you_have_remaining_credits | translate: { value: userCredits } }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="companyNumbers">
              <div class="numbers">
                <p class="infoText">{{ trans.company.ticker | translate }}</p>
                <p class="values">{{ company.ticker }}</p>
              </div>
              <div class="numbers">
                <p class="infoText">{{ trans.company.revenue | translate }}</p>
                <p class="values">{{ company.revenue_fmt }}</p>
              </div>
              <div class="numbers">
                <p class="infoText">{{ trans.company.employees | translate }}</p>
                <p class="values">{{ company.employees_fmt }}</p>
              </div>
            </div>
            <div class="addReviewLink" [hidden]="!showAddReview">
              <u
                ><a (click)="open(addReviewModal, null, null)">{{ trans.company.add_review | translate }}</a></u
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="companyDesc">
              <p>{{ company.synopsis }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-11">
            <div class="reviews" *ngIf="feedback.length > 0">
              <h4>{{ trans.company.recent_review | translate }}</h4>

              <div class="reviewList">
                <div class="review" *ngFor="let feedbk of feedback">
                  <div class="row">
                    <div class="col-md-2 col-xs-4 text-center">
                      <p><img class="profileImage" [src]="feedbk.profile_pic_filepath != '' ? feedbk.profile_pic_filepath : profilepicdefaultpath" /></p>
                      <h5>{{ feedbk.user_name }}</h5>
                    </div>
                    <div class="col-md-10 col-xs-8">
                      <div class="starRating">
                        <ngb-rating [(rate)]="feedbk.score" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                      </div>
                      <p class="dateContainer"><i class="icon-calendar"></i>&nbsp; {{ feedbk.modified | date: 'dd MMM yyyy' }}</p>
                      <p>{{ feedbk.comment }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="showDetail" class="text-center">
        <img class="loader" src="{{ fullImagePath }}" />
      </div>
    </div>
  </div>
</div>

<ng-template #t let-fill="fill">
  <span class="star" [class.full]="fill === 100">
    <span class="half" [style.width.%]="fill"><i class="fa fa-star"></i></span>
    <i class="fa fa-star-o borderStar"></i>
  </span>
</ng-template>

<ng-template #comparisonModal let-c="close" let-d="dismiss">
  <div class="appModal comparisonModal">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.company.comparison | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form-horizontal" name="comparisonForm" #comparisonForm="ngForm" (ngSubmit)="ngOnSubmit(comparisonForm)">
        <div class="form-group">
          <div class="col-sm-6">
            <label for="sector" class="control-label"><i class="icon-settings"></i>{{ trans.company.sectors | translate }}</label>
            <div class="m-t-xs">
              <select (change)="selectSector(sector)" class="form-control" name="sector" id="sector" required [(ngModel)]="sector">
                <option value="" disabled="disabled">{{ trans.company.select_sector | translate }}</option>
                <option *ngFor="let c of sectors" [ngValue]="c.id">{{ c.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <label for="industry" class="control-label"><i class="icon-briefcase"></i>{{ trans.company.industries | translate }}</label>
            <div class="m-t-xs">
              <select class="form-control" name="industry" id="industry" required [(ngModel)]="industry">
                <option value=" " disabled="disabled">{{ trans.company.select_industry | translate }}</option>
                <option *ngFor="let c of industries" [ngValue]="c.id">{{ c.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="pull-right">
          <button type="submit" class="btn btn-primary">{{ trans.company.apply_changes | translate }}</button>
          <img class="actionLoader" [hidden]="hideActionLoader" src="{{ fullImagePath }}" />
        </div>
      </form>
    </div>
    <div class="modal-footer">
    </div>
  </div>
</ng-template>

<ng-template #addReviewModal let-c="close" let-d="dismiss">
  <div class="appModal addReviewModal">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.company.add_review | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form-horizontal" name="addReviewForm">
        <div class="form-group">
          <label class="col-sm-2 control-label">{{ trans.company.rating | translate }}</label>
          <div class="col-sm-10 ratingInput">
            <ngb-rating [(rate)]="rating" [starTemplate]="t" [max]="5"></ngb-rating>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">{{ trans.company.comments | translate }}</label>
          <div class="col-sm-10">
            <textarea name="comments" class="form-control" placeholder="Enter Your Feedback" ngModel #comments="ngModel"></textarea>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="addReview(comments.viewModel)" [disabled]="rating == 0">{{ trans.company.add_review | translate }}</button>
      <img class="actionLoader" [hidden]="hideActionLoader" src="{{ fullImagePath }}" />
    </div>
  </div>
</ng-template>

<div class="loaderFull" [hidden]="scbDownloading">
  <img class="actionLoader" src="{{ fullImagePath }}" />
</div>
