import { Injectable } from '@angular/core';

@Injectable()
export class ScenarioCombinedModelTranslation {
  public config: any = {
    component: 'ScenarioCombinedModels',
    display_name: 'Define Scenario Models',
    description: 'Models component for the define scenario step process',
  };

  public trans: any = {
    selectModels: '1. Select Models',
    choosCategory: 'Choose Category Type',
    noModels: 'No Models Found',
    continue: 'Continue',
    modelsRequired: 'Models are required to continue',
    models: 'Models',
    save: 'Save',
    areYouSure: 'Are you sure?',
    cancel: 'Cancel',
    required: 'Required',
    back: 'Back'
  };
}
