import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subscription } from 'rxjs';
import * as $ from 'jquery';

@Component({
  selector: 'app-survey-template-create-trackers',
  templateUrl: './survey-template-create.component.html',
  styleUrls: ['./survey-template-create.component.scss'],
})
export class SurveyTemplateCreateComponent implements OnInit, OnDestroy {
  @Input() surveyTemplateId;
  @Output() valueChange = new EventEmitter();

  surveyDetails: any;
  accountId: any;
  sliderStartValue: number = 2;

  questionTypes: Array<any> = [];
  loadSurveysTable: boolean = false;
  surveys: any = [];
  valuePropId: string;
  fullImagePath: string;
  image_url: string;

  savedQuestions: Array<any> = [];
  surveyTemplateLoader: boolean = true;
  actionLoader: boolean = false;

  questions: Array<any> = [
    {
      id: 1,
      question: '',
      questionType: '',
      questionOverlay: false,
      options: [
        {
          option: '',
        },
      ],
    },
    {
      id: 2,
      question: '',
      questionType: '',
      questionOverlay: true,
      options: [
        {
          option: '',
        },
      ],
    },
  ];

  questionTrack: number = 1;

  subscriptiongetSurveyQuestion: Subscription;
  subscriptiongetQuestionTypes: Subscription;
  subscriptionsaveSurveyQuestion: Subscription;
  style2022$: Observable<boolean>;

  constructor(
    private factsService: FactsService,
    private valuerealizationService: ValuerealizationService,
    private notification: NotificationService,
    private CommonService: CommonService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.image_url = this.CommonService.getImageUrl();
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.accountId = sessionStorage.getItem('aid');
    this.savedQuestions = [];
    this.getSurveyDetail();
    this.getQuestionTypes();
  }

  ngOnDestroy() {
    if (this.subscriptiongetSurveyQuestion) {
      this.subscriptiongetSurveyQuestion.unsubscribe();
    }
    if (this.subscriptiongetQuestionTypes) {
      this.subscriptiongetQuestionTypes.unsubscribe();
    }
    if (this.subscriptionsaveSurveyQuestion) {
      this.subscriptionsaveSurveyQuestion.unsubscribe();
    }
  }

  getSurveyDetail() {
    this.subscriptiongetSurveyQuestion = this.valuerealizationService.getSurveyQuestion(this.accountId, this.surveyTemplateId).subscribe((response) => {
      if (response.result) {
        this.savedQuestions = response.result;
      }
      this.surveyTemplateLoader = false;
    });
  }

  questionTypeChange(questionType, id) {
    if (questionType == '3') {
      setTimeout(function () {
        (<any>$('.' + id + ' input:checkbox')).uniform();
      }, 10);
    } else if (questionType == '2') {
    }
  }

  enableQuestion() {
    let index = this.questions.length - 1;
    if (this.questions[index - 1]['question'] != '' && this.questions[index - 1]['questionType']) {
      this.questions[index]['questionOverlay'] = false;
      this.questions.push({
        id: index + 2,
        question: '',
        questionType: '',
        questionOverlay: true,
        options: [
          {
            option: '',
          },
        ],
      });
    }
  }

  getQuestionTypes() {
    this.subscriptiongetQuestionTypes = this.factsService.getQuestionTypes().subscribe((response) => {
      if (response.result) {
        this.questionTypes = response.result;
      }
    });
  }

  addOptions(id) {
    this.questions[id - 1]['options'].push({
      option: '',
    });
  }

  saveSurvey() {
    this.actionLoader = true;

    let questions = [];
    this.questions.forEach((data) => {
      let question = {};
      if (data.question != '' && data.questionType != '') {
        if (data.questionType == 2 && data.options.length > 1 && data.options[0]['option'] != '') {
          question['question_type_id'] = data.questionType;
          question['label'] = data.question;
          let options = [];
          data.options.forEach((option) => {
            if (option.option != '') {
              options.push(option.option);
            }
          });
          question['options'] = options;
          questions.push(question);
        } else {
          question['question_type_id'] = data.questionType;
          question['label'] = data.question;

          questions.push(question);
        }
      }
    });

    let params = {
      account_survey_questions_template_id: this.surveyTemplateId,
      questions: questions,
    };

    this.subscriptionsaveSurveyQuestion = this.valuerealizationService.saveSurveyQuestion(params).subscribe((response) => {
      if (response.result) {
        this.notification.success(response.result['message'], false);
        this.valueChange.emit();
      }
      this.actionLoader = true;
    });
  }
}
