import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SituationElement } from '@data/services/valueprop/models/situation.model';
import { NotificationService } from '@services/notification.service';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { AEMaintenanceSituationsTranslations } from './ae_situations.translation';
import { AssetTags, Tag } from '@shared/models/asset-tag.models';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { snakeCase } from 'lodash';
import { combineLatest } from 'rxjs';

interface SituationTree {
  name?: string;
  description?: string;
  expanded?: boolean;
  childName?: string;
  childDescription?: string;
  child_element_type_id?: number | string;
  can_set_child_element?: any;
  situation_element_id?: number | string;
  situation_type_id?: number | string;
  is_situation_type?: boolean;
}

@Component({
  selector: 'app-ae-maintenance-situations',
  templateUrl: './ae_situations.component.html',
  styleUrls: ['./ae_situations.component.scss'],
})
export class AEMaintenanceSituationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() solutionId: number | string;
  @Input() mode = 'add';

  @ViewChild('formAddEditSituation') formAddEditSituation;

  @ViewChild('formAddEditChild') formAddEditChild: NgForm;
  fullImagePath: string;

  situations: SituationElement[] = [];
  situation_tree: SituationTree[] = [];
  situation: Partial<SituationElement>;

  @Input() set objSituation(model: Partial<SituationElement>) {
    this.situation = { ...model };
  }

  @Output() valueChange = new EventEmitter();
  @Output() closeCallback = new EventEmitter();

  modeChild = 'add';
  cols: Array<{ field: string; header: string }> = [
    { field: 'name', header: 'Situation Type' },
    { field: 'stack_order', header: 'Order' },
  ];

  sidebar_title = 'Add Situation';
  currentSituationId = '';
  toggleAddEditSituation = false;
  ngUnsubscribe$ = new Subject();
  loading = true;

  account_id: string;
  showTranslate = false;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    public trans: AEMaintenanceSituationsTranslations,
    private translationService: TranslationsV2Service,
    private customAssetTagService: CustomAssetTagService
  ) {}

  ngOnInit() {
    this.account_id = sessionStorage.getItem('aid');

    this.solutionService.gotoAddSituation.subscribe(() => {
      this.funcAddSituation();
    });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngAfterViewInit(): void {
    if (this.mode === 'add') {
      this.funcAddSituation();
    } else if (this.mode === 'edit') {
      setTimeout(() => {
        this.funcEditSituation(this.situation);
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  funcEditSituation(elem: Partial<SituationElement>): void {
    this.funcToggleAddEdit(elem);

    this.toggleAddEditSituation = true;
  }

  funcAddSituation(): void {
    this.situation = {
      name: '',
      description: '',
      type: '',
      id: '',
      situation_type_id: '',
      has_tag: false,
      has_block_tag: false,
    };

    this.toggleAddEditSituation = true;
  }

  funcToggleAddEdit(elem: Partial<SituationElement>): void {
    this.mode = 'edit';

    this.formAddEditSituation.controls.name.setValue(elem.name);
    this.formAddEditSituation.controls.description.setValue(elem.description);
    this.formAddEditSituation.controls.type.setValue(elem.element_type_id);
    this.currentSituationId = elem.id;
    this.formAddEditSituation.controls.hasTag.setValue(elem.has_tag);
    this.formAddEditSituation.controls.hasBlockTag.setValue(elem.has_block_tag);

    if (elem.has_tag) {
      this.formAddEditSituation.controls.nameTag.setValue(elem.tag_data.name);
      this.formAddEditSituation.controls.valueTag.setValue(elem.tag);
    }

    if (elem.has_block_tag) {
      this.formAddEditSituation.controls.blockNameTag.setValue(elem.block_tag_data.name);
      this.formAddEditSituation.controls.blockValueTag.setValue(elem.block_tag);
    }

    this.funcGetSituationTree(elem.id);
    this.toggleAddEditSituation = true;
  }

  funcToggleAddEditChild(rowData: SituationTree) {
    rowData.childName = '';
    rowData.childDescription = '';
    this.modeChild = 'add';
    rowData.expanded = !rowData.expanded;
  }

  funcGetSituationTree(id: string | number): void {
    this.situation_tree = [];

    this.solutionService.getSituationTree(id).subscribe((res) => {
      this.situation_tree = res.result.data;
    });
  }

  funcSaveSituation(): void {
    const payload = {
      account_id: this.account_id,
      element_type_id: this.situation.type,
      name: this.situation.name,
      description: this.situation.description,
    };

    this.solutionService
      .saveSituationType(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.notificationService.success(this.trans.trans.situationSuccess.value, false);

        let situation_type_id = res.result.toString();
        if (this.situation.has_tag) {
          // Save the onshow tag
          this.customAssetTagService.createAssetTag(this.funcOnshowTagPayload(situation_type_id)).pipe(take(1)).subscribe();
        }

        if (this.situation.has_block_tag) {
          // Save the block tag
          this.customAssetTagService.createAssetTag(this.funcBlockTagPayload(situation_type_id)).pipe(take(1)).subscribe();
        }

        this.valueChange.emit();
      });
  }

  generateOnshowTag(tagChange) {
    if (tagChange.checked) {
      this.situation = {
        ...this.situation,
        name_tag: this.situation.name,
        value_tag: snakeCase(this.situation.name),
      };
    } else if (this.situation.tag_data?.id) {
      this.notify('customFactorTagWarning', 'warning');
    }
  }

  generateBlockTag(tagChange) {
    if (tagChange.checked) {
      this.situation = {
        ...this.situation,
        block_name_tag: this.situation.name + ' Block',
        block_value_tag: snakeCase(this.situation.name) + '_block',
      };
    } else if (this.situation.block_tag_data?.id) {
      this.notify('customFactorTagWarning', 'warning');
    }
  }

  funcOnshowTagPayload(situation_type_id?: string | null) {
    let tag_payload: Tag = {
      active: '1',
      name: this.situation.name,
      description: this.situation.description,
      tag: this.situation.value_tag,
      metadata: {
        type: 'onshow',
        id: situation_type_id ? situation_type_id : this.situation.id,
        array_name: 'selected_situations_by_type',
        field: 'name',
        format_type: 'string',
      }
    }

    if ( this.situation.tag_data?.id ) {
      // Send id if this is an update
      tag_payload['id'] = this.situation.tag_data?.id;
    }

    const final_tag_payload: AssetTags = {
      tags: [tag_payload]
    };

    return final_tag_payload;
  }

  funcBlockTagPayload(situation_type_id?: string | null) {
    let tag_payload: Tag = {
      active: '1',
      name: this.situation.name,
      description: this.situation.description,
      tag: this.situation.block_value_tag,
      metadata: {
        type: 'block',
        id: situation_type_id ? situation_type_id : this.situation.id,
        array_name: 'situations_indexed_by_id',
      }
    }

    if ( this.situation.tag_data?.id ) {
      // Send id if this is an update
      tag_payload['id'] = this.situation.tag_data?.id;
    }

    const final_tag_payload: AssetTags = {
      tags: [tag_payload]
    };

    return final_tag_payload;
  }

  funcUpdateSituation(): void {
    const payload = {
      id: this.situation.id,
      account_id: this.account_id,
      situation_type_id: this.situation.id,
      element_type_id: this.situation.type,
      name: this.situation.name,
      description: this.situation.description,
    };

    this.solutionService
      .updateSituationType(payload)
      .pipe( switchMap((res) => {
        let tag_response;
        if (!this.situation.has_tag && this.situation.tag_data?.id) {
          tag_response = this.customAssetTagService.deleteAssetTag(this.situation.tag_data?.id);
        } else if (this.situation.has_tag) {
          // Save the onshow tag
          tag_response = this.customAssetTagService.createAssetTag(this.funcOnshowTagPayload());
        } else {
          tag_response = of(Observable);
        }

        let block_tag_response;
        if (!this.situation.has_block_tag && this.situation.block_tag_data?.id) {
          block_tag_response = this.customAssetTagService.deleteAssetTag(this.situation.block_tag_data?.id);
        } else if (this.situation.has_block_tag) {
          // Save the onshow tag
          block_tag_response = this.customAssetTagService.createAssetTag(this.funcBlockTagPayload());
        } else {
          block_tag_response = of(Observable);
        }

        return forkJoin([tag_response, block_tag_response]);
      }), 
      takeUntil(this.ngUnsubscribe$) )
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.situationUpdateSuccess.value, false);
        this.valueChange.emit();
      });
  }

  funcAddChild(row: SituationTree): void {
    if (!row.childName) {
      return;
    }
    const payload = {
      name: row.childName,
      description: row.childDescription,
      parent_id: row.situation_element_id,
      account_situation_type_id: row.situation_type_id,
      child_element_type_id: row.child_element_type_id,
    };

    this.solutionService
      .saveSituationElement(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.childNodeSuccess.value, false);
        row.expanded = false;
        this.funcGetSituationTree(row.situation_type_id);
      });
  }

  funcEditChild(row: SituationTree): void {
    this.modeChild = 'edit';
    row.childName = row.name;
    row.childDescription = row.description;
    row.expanded = true;
  }

  funcDeleteChild(row: SituationTree): void {
    if (row.is_situation_type) {
      this.deleteSituationType(row.situation_type_id as number);
    } else {
      this.solutionService
        .deleteSituationElement(row.situation_element_id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          (res) => {
            if (res.result.success === false) {
              this.notificationService.error(this.trans.trans.cannotBeDeleted.value, false);
              return;
            }
            this.notificationService.success(res.result, false);
            this.funcGetSituationTree(this.currentSituationId);
          },
          () => {
            this.notificationService.error(this.trans.trans.cannotBeDeleted.value, false);
          }
        );
    }
  }

  deleteSituationType(situation_type_id: number) {
    this.solutionService
      .deleteSituationType(situation_type_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          this.notificationService.success(res.result, false);
          this.funcGetSituationTree(this.currentSituationId);
          if (this.situation_tree.length === 0) {
            this.valueChange.emit();
          }
        },
        (error) => {
          this.notificationService.error(error, false);
        }
      );
  }

  funcUpdateChild(row: SituationTree): void {
    if (!row.childName) {
      return;
    }

    const payload = {
      name: row.childName,
      description: row.childDescription,
      child_element_type_id: row.child_element_type_id ? row.child_element_type_id : 0,
    };

    this.solutionService
      .updateSituationElement(row.situation_element_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        row.expanded = false;
        this.funcGetSituationTree(this.currentSituationId);
      });
  }

  drop(): void {
    this.funcUpdateSituationOrder();
  }

  funcUpdateSituationOrder(): void {
    const situationStackOrder = this.situations.map((item, index) => {
      const situation = {};
      situation['stack_order'] = index;
      situation['situation_type_id'] = item['id'];
      return situation;
    });

    const payload = {
      situation_types: situationStackOrder,
    };

    this.solutionService
      .updateSituationTypeStackOrder(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result && response.result['success']) {
          this.notificationService.success(this.trans.trans.orderUpdated.value, false);
        }
      });
  }

  resetValues(): void {
    this.formAddEditSituation.reset();
    this.situation_tree = [];
    if (this.formAddEditChild) {
      this.formAddEditChild.reset();
    }
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.mode === 'add' ? this.trans.trans.add_situation.value : this.trans.trans.edit_situation.value;
      });
  }

  private notify(transString: string, type: 'error' | 'warning' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
