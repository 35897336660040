import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ellipsis-menu',
  templateUrl: 'ellipsis-menu.component.html',
  styleUrls: ['ellipsis-menu.component.scss'],
})
export class EllipsisMenuComponent implements OnInit, OnDestroy{
  @ViewChild('menu') menu;

  @Input() horizontal = false;
  @Input() hide: Observable<boolean>;
  @Input() narrow = false;

  ngUnsubscribe$ = new Subject<boolean>();


  ngOnInit() {
    // Manualy hide the menu using the hide observable
    if (this.hide) {
      this.hide.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
        if (val === true) {
          this.menu.hide();
        }
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
