import { Injectable } from '@angular/core';

@Injectable()
export class SituationsSharedTranslations {
  public config: any = {
    component: 'situations-shared',
    display_name: 'Situations Shared',
    description: 'Generic situations component',
  };
  public trans: any = {
    special_situations: 'Special Situations',
    no_special_situations: 'No special situations selected, click edit to select any that apply',
    situations: 'Situations',
    situation_type: 'Situation Type',
    ch_scenario: 'Here is a brief summary of the Account. Please choose the Model(s) you want to use and make updates to any other fields as required.',
    situationsTitle2: '2. Select Special Situations',
    continue: 'Continue',
    contact: 'Please Contact your Model Administrator',
    save: 'Save',
    next: 'Next',
    qualificationQuestionInfo2: 'Use qualification questions to capture customer notes during discovery.',
    qualificationQuestions: 'Qualification Questions',
    areYouSure: 'Are you sure?',
    back: 'Back',
    descriptionLabel: 'Description',
    answerLabel: 'Answer',
    discoveryQuestionsUpdated: 'Discovery questions updated successfully',
    discoveryQuestionsUpdateError: 'Error updating discovery questions',
    situationUpdated: 'Situation updated successfully',
  };
}
