import { Component, Input, Output, EventEmitter} from '@angular/core';
import { BenefitsComponent } from '../benefits.component';

@Component({
	selector: 'app-benefit-recursive',
	templateUrl: './benefits-recursive.component.html',
})
export class BenefitRecursiveComponent extends BenefitsComponent {
	@Input() benefitsList: any;
	@Input() valueProp: any;
	@Input() contextualHelp: object;
	@Input() dealdesk: boolean;
	@Output() openBenefitCallback = new EventEmitter();
}