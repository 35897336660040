<div class="link">
  <div class="flex-jcc w-100 select-buttons">
    <p-selectButton
      [options]="selectionOptions"
      [(ngModel)]="selectedOption"
      [optionDisabled]="valuePropId ? '' : 'inactive'"
      styleClass="keywest_select_button select-button-tabs"
    ></p-selectButton>
  </div>

  <ng-container *ngIf="selectedOption === BaselineOptions.goals && valuePropId">
    <app-review-baseline-goal-groups #goalGroups 
      [valueProp]="valueProp" 
      [valuePropId]="valuePropId"
    ></app-review-baseline-goal-groups>
  </ng-container>

  <ng-container *ngIf="selectedOption === BaselineOptions.benefits">
    <app-review-baseline-benefits [valueProp]="valueProp" [valuePropId]="valuePropId" [trackerId]="valuePropId"></app-review-baseline-benefits>
  </ng-container>

  <ng-container *ngIf="selectedOption === BaselineOptions.successCriteria">
    <app-success-criteria [valuePropId]="valuePropId"></app-success-criteria>
  </ng-container>

  <div class="nav-bar flex-jend">
    <button class="btn btn_customizable cancel marg-h" (click)="cancel()">{{ trans.trans.cancel.value }}</button>
    <button class="btn btn_customizable reverse marg-r" (click)="previousStep()">{{ trans.trans.back.value }}</button>
    <button class="btn btn_customizable" [disabled]="false" (click)="nextStep()">
      <ng-container *ngIf="selectedOption !== BaselineOptions.successCriteria; else finish">
        {{ trans.trans.next.value }}
      </ng-container>
      <ng-template #finish>{{ trans.trans.finish.value }}</ng-template>
    </button>
    <button class="btn btn_customizable marg-l" [disabled]="false" (click)="jumpFinish()" *ngIf="selectedOption !== BaselineOptions.successCriteria">
      {{ trans.trans.finish.value }}
    </button>
  </div>
</div>
