import { Injectable } from '@angular/core';

@Injectable ()
export class AddCaseStudyDetailTranslations {

    public config: any = {
        component: 'addCasestudyDetail',
		display_name: 'Case Study Form',
		description: 'Form to add a new case study'
    };

    public trans: any = {
        redact: 'Redact',
        yes: 'Yes',
        no: 'No',
        pickAccount: 'Pick your account',
        searchCompany: 'Search for a Company',
        name_label: 'Name',
        revenue: 'Revenue',
        name: 'Name',
        employees: 'Employees',
        ticker: 'Ticker',
        city: 'City',
        country: 'Country',
        sector: 'Sector',
        address: 'Address',
        website: 'Website',
        tags: 'Tags',
        save: 'Save',
        noCompaniesFound: 'No Companies Found',
        back: 'Back',
        are_you_sure: 'Are you sure?'
    };
}
