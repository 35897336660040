import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { CaseStudy } from '@shared/models/case-study.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss'],
})
export class CaseStudiesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() canEdit = false;

  @Input() solutionId: number | string;
  @Input() tabInit: number;

  columns = [{ header: 'Name', field: 'name' }];
  pageNumber = 1;
  pageLimit = 1000;
  fullImagePath: string;
  caseStudiesList: CaseStudy[] = [];
  caseStudiesListLoader = false;
  showCaseStudiesList = false;
  caseStudyId: number;
  caseStudy: CaseStudy;

  contextualHelp: { [klass: string]: any } = {};
  searchType: string;
  searchTypeList: Array<{ [klass: string]: any }>;
  caseStudiesName: string;
  solutions: Array<{ [klass: string]: any }> = [];
  solutionSelected: { [klass: string]: any };
  selectedCaseStudy: { [klass: string]: any };
  searchText: string;
  newCaseStudyName: string;
  cloneRename = false;

  valueProps: { [klass: string]: any }[] = [];
  valuePropId: number;

  assetUrl = '';
  assetGenerator = '';

  subscriptionreadCaseStudy: Subscription;
  subscriptioncontextualHelp: Subscription;
  subscriptiongetCaseStudies: Subscription;
  subscriptionsearchCaseStudies: Subscription;
  subscriptiondeleteCaseStudy: Subscription;
  subscriptiongetCaseStudyAssetTemplate: Subscription;
  subscriptiongetAvailableSolutions: Subscription;
  subscriptionreadUserValuePropsPlain: Subscription;
  subscriptioncloneModelCaseStudy: Subscription;
  subscriptionupdatecaseStudy: Subscription;
  ngUnsubscribe = new Subject();

  accountSolutionId: any;
  showAddCase = false;

  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private solutionService: SolutionService,
    private valuepropService: ValuepropService,
    private router: Router,
    private notification: NotificationService,
    private assetService: AssetService,
    private translateService: TranslateService,
    public trans: Translations,
    private route: ActivatedRoute
  ) {
    const imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.searchTypeList = [
      {
        name: 'Search By Name',
        value: 'name',
      },
      {
        name: 'Search By tags',
        value: 'tag',
      },
    ];
    this.searchType = this.searchTypeList[0]['value'];

    this.getCaseStudies();

    this.subscriptionreadCaseStudy = this.solutionService.readCaseStudy.subscribe(() => {
      this.getCaseStudies();
    });
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.getModalsList();
    this.getValuePropList();

    this.assetUrl = this.commonService.getAssetUrl();
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
  }

  ngOnDestroy() {
    if (this.subscriptionreadCaseStudy) {
      this.subscriptionreadCaseStudy.unsubscribe();
    }
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }

    if (this.subscriptiongetCaseStudies) {
      this.subscriptiongetCaseStudies.unsubscribe();
    }
    if (this.subscriptionsearchCaseStudies) {
      this.subscriptionsearchCaseStudies.unsubscribe();
    }
    if (this.subscriptiondeleteCaseStudy) {
      this.subscriptiondeleteCaseStudy.unsubscribe();
    }
    if (this.subscriptiongetCaseStudyAssetTemplate) {
      this.subscriptiongetCaseStudyAssetTemplate.unsubscribe();
    }
    if (this.subscriptiongetAvailableSolutions) {
      this.subscriptiongetAvailableSolutions.unsubscribe();
    }
    if (this.subscriptionreadUserValuePropsPlain) {
      this.subscriptionreadUserValuePropsPlain.unsubscribe();
    }
    if (this.subscriptioncloneModelCaseStudy) {
      this.subscriptioncloneModelCaseStudy.unsubscribe();
    }
    if (this.subscriptionupdatecaseStudy) {
      this.subscriptionupdatecaseStudy.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  onTypeChange() {
    this.pageNumber = 1;
  }

  ngOnChanges() {
    if (this.tabInit === 1) {
      this.getCaseStudies();
    }
  }

  funcNavBack() {
    this.router.navigate(['/model/' + this.accountSolutionId]);
    this.showCaseStudiesList = false;
  }

  openAddCaseStudy() {
    this.showAddCase = true;
  }

  getCaseStudies() {
    const searchLimit = '/limit/' + this.pageLimit + '/page/' + this.pageNumber;
    this.caseStudiesListLoader = false;
    this.subscriptiongetCaseStudies = this.solutionService.getCaseStudies(this.solutionId, searchLimit).subscribe((response) => {
      if (response.result && response.result.case_studies) {
        this.caseStudiesList = response.result.case_studies.data;
        this.accountSolutionId = this.caseStudiesList[0] ? this.caseStudiesList[0].account_solution_id : null;

        this.caseStudiesListLoader = true;

        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => (this.caseStudyId = params['caseid']));
        if (this.caseStudyId) {
          this.caseStudy = this.caseStudiesList.filter((x) => +x.id === +this.caseStudyId)[0];
          this.showCaseStudiesList = true;
        }
      }
    });
  }

  searchCaseStudies() {
    if (this.caseStudiesName && this.caseStudiesName != null && this.caseStudiesName !== '') {
      this.caseStudiesListLoader = true;
      const payload = {
        searchType: this.searchType,
        searchTerm: this.searchType === 'tag' ? this.caseStudiesName.trim() : this.caseStudiesName,
        page: this.pageNumber,
        limit: this.pageLimit,
        solution_id: this.solutionId,
      };

      this.subscriptionsearchCaseStudies = this.solutionService.searchCaseStudies(payload).subscribe((response) => {
        if (response.result) {
          this.caseStudiesList = response.result.case_studies.data;
        }
        this.caseStudiesListLoader = false;
      });
    } else {
      this.caseStudiesList = [];
      this.getCaseStudies();
    }
  }
  navigateCaseStudy(caseStudy: CaseStudy) {
    this.showCaseStudiesList = true;
    this.caseStudyId = +caseStudy['id'];
    this.caseStudy = caseStudy;
  }

  deleteCaseStudy(caseStudyId: any) {
    this.subscriptiondeleteCaseStudy = this.solutionService.deleteCaseStudy(caseStudyId).subscribe((response) => {
      if (response.result) {
        this.getCaseStudies();
        this.notification.success(response.result, false);
      }
    });
  }

  downloadAsset(caseStudy: CaseStudy) {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');

    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.subscriptiongetCaseStudyAssetTemplate = this.assetService.getCaseStudyAssetTemplate().subscribe((response) => {
      if (response && response !== null && typeof response !== 'string') {
        const downloadUrl =
          this.assetUrl +
          'assets/' +
          this.assetGenerator +
          '?account_asset_template_id=' +
          response.result.id +
          '&user_id=' +
          userId +
          '&token=' +
          token +
          '&case_study_id=' +
          caseStudy.id;

        window.location.assign(downloadUrl);
      } else {
        this.notification.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }

  getModalsList() {
    const searchLimit = '/limit/all/page/1/search/all/sortby/id/order/desc';
    this.subscriptiongetAvailableSolutions = this.solutionService.getAvailableSolutions(searchLimit).subscribe((response) => {
      if (response.result) {
        const data = response.result.data;
        this.solutions = data.filter((solution) => solution['id'] != this.solutionId);
      }
    });
  }

  getValuePropList() {
    this.subscriptionreadUserValuePropsPlain = this.valuepropService.readUserValuePropsPlain().subscribe((response) => {
      if (response.result) {
        this.valueProps = response.result;
      }
    });
  }

  openModal(content: any, caseStudy: { [klass: string]: any }) {
    this.modalService.open(content, {
      windowClass: 'deleteModal',
      backdrop: 'static',
      keyboard: false,
    });

    this.selectedCaseStudy = caseStudy;
    this.valuePropId = undefined;
  }

  cloneModel() {
    this.cloneRename = !this.cloneRename;
    this.newCaseStudyName = 'Copy of ' + this.selectedCaseStudy['name'];
  }

  saveModel() {
    const payload = {
      case_study_id: this.selectedCaseStudy['id'],
      account_solution_id: this.solutionSelected['id'],
      new_case_study_name: this.newCaseStudyName,
    };

    this.subscriptioncloneModelCaseStudy = this.solutionService.cloneModelCaseStudy(payload).subscribe((response) => {
      if (response.result) {
        this.notification.success('Case Study cloned successfully', false);
        this.cloneRename = !this.cloneRename;
      }
    });
  }

  updatecaseStudy() {
    const payload = {
      case_study_id: this.selectedCaseStudy['id'],
      value_prop_id: this.valuePropId,
    };

    this.subscriptionupdatecaseStudy = this.solutionService.updatecaseStudy(payload, this.solutionId).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success('Value prop assigned successfully', false);
      }
    });
  }

  closeSidebar() {
    this.showAddCase = false;
  }
}
