import { Injectable } from '@angular/core';

@Injectable ()

export class AddBenefitVpDashboardTranslations {

    public config: any = {
        component: 'addBenefitsOnFly',
        display_name: 'Add Benefits',
        description: 'Add benefits on the fly in value prop dashboard'
    };

    public trans: any = {
        addBenefit: 'Add Benefit',
        editBenefit: 'Edit Benefit',
        required: 'Required',
        name: 'Name',
        Impact: 'Impact',
        IsAnnualBenefits: 'Is this an annual benefit?',
        Solutions: 'Solutions',
        WhichValueCategory: 'Which value category does this benefit fall under?',
        driver: 'Driver Factor',
        DriverFactor: 'Driver Factor',
        CreateDriverFactor: 'Create Driver Factor',
        Answer: 'Answer',
        improvement: 'Improvement',
        improvement_required: 'Required',
        units: 'Units',
        CurrentEffort: 'Current Effort',
        financial: 'Financial Factor',
        FinancialFactorType: 'Financial Factor Type',
        CreateFinancialFactor: 'Create Financial Factor',
        CreateFinancialFactorAnswer: 'Answer',
        additional: 'Additional Information',
        description: 'Description',
        features: 'Features/Capabilities',
        Uniqueness: 'Uniqueness',
        impacts: 'Impacts Which Business Areas',
        impacts_desc: 'Impact Description',
        area: 'Area',
        NoAreasSelected: 'No Areas Selected',
        choose: 'Choose',
        save: 'Save',
        cancel: 'Cancel',
        add_your_benefits: 'Add Your Benefits',
		aggressive: 'Aggressive',
		answer: 'Answer',
		benefit_phasing: 'Benefit Phasing',
		create_driver_factor: 'Create Driver Factor',
		create_financial_factor: 'Create Financial Factor',
		current_effort: 'Current Effort',
		capabilities: 'Capabilities',
		does_your_benefit_scale: 'Does your benefit scale?',
		driver_factor: 'Driver Factor',
		expense_type: 'Expense Type',
		featurescapabilities: 'Features/Capabilities',
		financial_factor: 'Financial Factor',
		financial_factor_type: 'Financial Factor Type',
		functional_objectives: 'Functional Objectives?',
		impact: 'Impact',
		is_this_benefit_considered_soft: 'Is this benefit considered soft?',
		impacts_which_business_areas: 'Impacts Which Business Areas?',
		improvement_conservative: 'Improvement Conservative',
		improvement_factor: 'Improvement Factor',
		is_required: 'Is Required',
		is_this_an_annual_benefit: 'Is this an annual benefit?',
		mark_this_benefit_as_nonapplicable_by_default: 'Mark this benefit as Non-Applicable by Default?',
		no_areas_selected: 'No areas selected',
		operational_objectives: 'Operational Objectives?',
		probable: 'Probable',
		project: 'Project',
		pain_points: 'Pain Points?',
		situations: 'Situations',
		should_this_benefit_be_quantified: 'Should this benefit be quantified?',
		seed_growth_decline: 'Seed Growth / Decline ?',
		uniqueness: 'Uniqueness',
		which_value_category_does_this_benefit_fall_under: 'Which value category does this benefit fall under?',
		what_type_of_benefit_is_this: 'What type of benefit is this?',
		would_you_like_to_use_a_different_name_for_tco: 'Would you like to use a different name for TCO?',
		year_1: 'Year 1: ',
		year_2: 'Year 2:',
		year_3: 'Year 3:',
		year_4: 'Year 4:',
		year_5: 'Year 5:',
		you_are_in_view_only_mode: 'You are in view only mode. Please contact your Models Admin if you need to make changes.',
		app_ng_error: 'To what Benefit Group does this benefit belong?',
		how_does: 'How does this benefit impact your customer?',
		differentiated: 'Differentiated',
		default_na: 'Is this benefit N/A by default?',
		add_new: 'Add New',
		conservative: 'Conservative',
		optional: 'Optional',
		tco_name: 'TCO Name',
		value_statement: 'Value Statement',
		what_type_benefit: 'What benefit type is this?',
		debug: 'Debug',
		select_option: 'Please select an option'
    };
}