<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>{{trans.trans.benefit_groups.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'value-category'" [isTranslationV2]="true"
						[transObj]="trans.trans.benefit_groups" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-6'>
					<a *ngIf="can_edit" (click)='openAddValueCategory()' class='btn btn_customizable pull-right'><i class='fa fa-plus'></i>{{trans.trans.add_new.value}}</a>
				</div>
			</div>
		</div>
		<div class='row marg-v'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tableValueCategoryList.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableValueCategoryList.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<p-progressBar *ngIf="loading" class='keywest_progressbar' mode="indeterminate"></p-progressBar>
				<p-table #tableValueCategoryList [columns]="cols" [value]="valueCategories" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" [pSortableColumn]="col.field">
								{{trans.trans.name.value}}
								<p-sortIcon [field]="col.field"></p-sortIcon>
								<app-editTranslation *ngIf="showTranslate" [component]="'value-category'" [isTranslationV2]="true"
								[transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
							</th>
							<th></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-category let-rowData>
						<tr>
							<td>
								<span class="clickable" (click)="can_edit ? funcToggleEdit(category) : funcToggleView(category)">{{category.name}}</span>
								<i *ngIf="category.description && !(style2022$ | async)" class='fa fa-info-circle' [matTooltip]="category.description" matTooltipPosition="above"></i>
								<span *ngIf="category.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="category.description" matTooltipPosition="above">
									info
								</span>
							</td>
							<td>
								<span *ngIf="can_edit" class='keywest_icon_group pull-right'>
									<span><i (click)='funcToggleEdit(category)' [matTooltip]="trans.trans.ch_edit.value" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
									<span>
										<app-are-you-sure [isLoading]="rowData.isLoading" [matTooltip]="trans.trans.ch_delete.value" matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteSolutionValueCategory(category.id, rowData)'></app-are-you-sure>
									</span>
								</span>
								<span *ngIf="!can_edit" class='keywest_icon_group pull-right'>
									<span><i (click)='funcToggleView(category)' class='fa fa-eye clickable' [matTooltip]="trans.trans.view.value" matTooltipPosition="above"></i></span>
								</span>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</div>

<p-menu #rowMenu appendTo='body' [popup]="true" [model]="dropdown" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<p-sidebar [(visible)]='toggleAddEditCategory' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-ae-value-category [solutionId]="solutionId" *ngIf="toggleAddEditCategory && can_edit" [mode]='mode' [selectedElem]='selectedElem' (callback)='getValueCategories(); toggleAddEditCategory = false' (closeCallback)="closeAddEditCategory()"></app-ae-value-category>
	<app-r-value-category *ngIf="toggleAddEditCategory && !can_edit" [selectedElem]='selectedElem'></app-r-value-category>
</p-sidebar>