import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurableWorkflowsComponent } from './configurable-workflows.component';
import { ConfigurableWorkFlowsTranslations } from './configurable-workflows.translation';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { WorkflowTableComponent } from './workflow-table/workflow-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreateWorkflowComponent } from './create-workflow/create-workflow.component';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    ConfigurableWorkflowsComponent,
    WorkflowTableComponent,
    CreateWorkflowComponent
  ],
  imports: [
    CommonModule,
    BreadcrumbsModule,
    FormsModule,
    TableModule,
    EditTranslationsModule,
    EllipsisMenuModule,
    AreYouSureModule,
    InputSwitchModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    InputTextModule,
    MultiSelectModule,
    InputTextareaModule,
    CheckboxModule,
    DragDropModule
  ],
  exports: [ConfigurableWorkflowsComponent],
  providers: [ConfigurableWorkFlowsTranslations, ConfigurableWorkflowService]
})
export class ConfigurableWorkflowsModule { }
