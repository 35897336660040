<app-choose-adventure-internal
  #chooseAdventure
  (onHidden)="onHidden($event)"
  [navToRoute]="'valueprop'"
  [ff134]="ff134"
  [showStepSelection]="showStepSelection"
  [workflows]="workflows"
></app-choose-adventure-internal>
<div class="col-md-6" style="margin-bottom: 10px">
  <div class="models_panel dashboard-card" style="height: 400px">
    <div class="models_page_header_container_small">
      <div class="keywest_header">
        <div class="row">
          <div class="col-xs-10">
            <div class="title">
              {{ trans.trans.valuePropsHeading.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="getTranslations()"
                key="main_dashboard.valuePropsHeading"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.valuePropsHeading"
              ></app-editTranslation>
            </div>
          </div>
          <div class="col-xs-2">
            <button *ngIf="dbI.showAddButtons" (click)="onAddHypoClicked('valueprop')" class="btn btn_customizable pull-right value-execution-dashboard-add-a-new-value-proposition-button" data-heap="value-execution-dashboard-add-a-new-value-proposition-button">
              <i class="fa fa-plus"></i> {{ trans.trans.add.value }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div style="overflow-y: auto; max-height: 270px">
      <div [hidden]="!loading">
        <app-table-skeleton rowHeight="35px" [columns]="columns" [trans]="trans.trans" [rowLimit]="5"></app-table-skeleton>
      </div>
      <table *ngIf="!loading" class="table custom-table dashboard-table fixedTable">
        <thead>
          <tr>
            <th width="70%">
              {{ trans.trans.valueprop_name.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="getTranslations()"
                key="main_dashboard.valueprop_name"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.valueprop_name"
              ></app-editTranslation>
            </th>
            <th width="30%" class="text-right">
              {{ trans.trans.valueprop_totalbenefits.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="getTranslations()"
                key="main_dashboard.valueprop_totalbenefits"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.valueprop_totalbenefits"
              >
              </app-editTranslation>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="grid-row company-row" *ngFor="let revise of reviseList; let index = index">
            <td>
              <a *ngIf="revise.is_group" (click)="chooseStep('groupv1', revise.id)" class="link">{{ revise.name }}</a>
              <a *ngIf="!revise.is_group" (click)="chooseStep('valueprop', revise.id)" class="link">{{ revise.name }}</a>

              <i
                class="fa fa-plus"
                style="margin-left: 10px"
                *ngIf="revise.value_props"
                (click)="revise.isCollapsed = !revise.isCollapsed"
                [attr.aria-expanded]="revise.isCollapsed"
              ></i>
              <span class="material-icons-outlined tooltip-custom info-icon" (mouseout)="op.toggle($event)" (mouseover)="getValuePropDetails(revise.id, op, $event)">info</span>

              <table [ngbCollapse]="!revise.isCollapsed">
                <tr *ngFor="let v of revise.value_props">
                  <td width="80%">
                    <a routerLink="/valueposition/{{ v.id }}" class="link">{{ v.name }}</a>
                  </td>
                  <td>{{ v.total_benefits_fmt }}</td>
                </tr>
              </table>

              <ng-template #tipContent class="toolTipContent">
                <div *ngIf="revise.solutions">
                  <p>
                    <label>{{ trans.trans.valueprop_valuepropname.value }}</label
                    >:&nbsp;
                    <span>{{ revise.name }}</span>
                  </p>
                  <p *ngIf="revise.solutions && revise.solutions.length">
                    <label>{{ trans.trans.valueprop_models.value }}</label>
                    <span *ngFor="let elem of revise.solutions">{{ elem.name }}</span>
                  </p>
                  <p>
                    <label>{{ trans.trans.valueprop_year1benfits.value }}</label
                    >:&nbsp;
                    <span>{{ revise.year1_benefits_fmt }}</span>
                  </p>
                  <p>
                    <label>{{ trans.trans.valueprop_totalBenefits.value }}</label
                    >:&nbsp;
                    <span>{{ revise.total_benefits_fmt }}</span>
                  </p>
                  <p *ngIf="revise.roi_decorated">
                    <label>{{ trans.trans.valueprop_ROI.value }}</label
                    >:&nbsp;
                    <span>{{ revise.roi_decorated }}</span>
                  </p>
                  <p *ngIf="revise.payback">
                    <label>{{ trans.trans.valueprop_paybackPeriod.value }}</label
                    >:&nbsp;
                    <span>{{ revise.payback }}</span>
                  </p>
                  <p *ngIf="revise.vp_creator_name">
                    <label>{{ trans.trans.valueprop_creatorName.value }}</label
                    >:&nbsp;
                    <span>{{ revise.vp_creator_name }}</span>
                  </p>
                </div>
              </ng-template>
            </td>
            <td class="text-right">{{ revise.total_benefits_fmt }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="reviseList.length == 0" class="m-t-md col-sm-12">
        <div class="text-center">
          <h4>
            {{ trans.trans.valueprop_noValueProps.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="main_dashboard.valueprop_noValueProps"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.valueprop_noValueProps"
            >
            </app-editTranslation>
          </h4>
        </div>
      </div>
    </div>
    <a routerLink="/valueprop" class="keywest_seeMore">{{ trans.trans.valueprop_seeMore.value }}</a>
  </div>
</div>

<p-sidebar [(visible)]="dbI.toggleAddValueProp" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.chooseOptionToContinue.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-xs-12">
            <input [(ngModel)]="dbI.selectedOption" type="radio" name="controloption" value="quickcreate" id="quickcreate" checked />&nbsp;&nbsp;
            <span *ngIf="dbI.accountID != '20' && dbI.accountID != '464'">{{ trans.trans.quickCreate.value }}</span>
            <span *ngIf="dbI.accountID == '20'">Typical</span>
            <span *ngIf="dbI.valuePropGroupsFeature">{{ trans.trans.vpGroup.value }}</span>
          </div>
        </div>
        <div class="row" *ngIf="dbI.guidedDiscoveryFeature">
          <div class="col-xs-12">
            <input [(ngModel)]="dbI.selectedOption" type="radio" name="controloption" value="guideddiscovery" id="guideddiscovery" />&nbsp;&nbsp;
            <span>{{ trans.trans.guidedDiscovery.value }}</span>
          </div>
        </div>
        <div class="row" *ngIf="dbI.dealdeskFeature">
          <div class="col-xs-12">
            <input [(ngModel)]="dbI.selectedOption" type="radio" name="controloption" value="dealdesk" id="dealdesk" />&nbsp;&nbsp;
            <span *ngIf="dbI.accountID != '20'">{{ trans.trans.dealdesk.value }}</span>
            <span *ngIf="dbI.accountID == '20'">{{ trans.trans.custom.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn_customizable pull-right" (click)="dbI.selectOptions()">{{ trans.trans.start.value }}</button>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<p-overlayPanel #op appendTo="body" styleClass="keywest_tooltip_overlay_wide">
  <p-progressBar *ngIf="loadingVPDetails" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
  <div *ngIf="valueposition && !loadingVPDetails">
    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Account:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.company_name }}</span>
      </div>
    </div>
    <div class="row" *ngIf="valueposition.solutions && valueposition.solutions.length" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Models:</label>
      </div>
      <div class="col-xs-7">
        <div *ngFor="let elem of valueposition.solutions">{{ elem.name }}<br /></div>
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Status:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.status }}</span>
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Benefits:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.total_benefits_fmt }}</span>
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Total Cost:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.total_costs_fmt }}</span>
      </div>
    </div>

    <div class="row" *ngIf="valueposition.roi_decorated" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">ROI:</label>
      </div>
      <div class="col-xs-7">
        {{ valueposition.roi_decorated }}
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Last Modified:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.modified }}</span>
      </div>
    </div>

    <div class="row" *ngIf="valueposition.payback" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Payback Period:</label>
      </div>
      <div class="col-xs-7">{{ valueposition.payback }}<span>months</span></div>
    </div>
  </div>
</p-overlayPanel>
