<app-choose-adventure-internal
  #chooseAdventure
  (onHidden)="onHidden($event)"
  [ff134]="ff134"
  [workflows]="workflows"
  [showStepSelection]="showStepSelection"
></app-choose-adventure-internal>

<div class="card style-2022">
  <ng-container *ngIf="(companyVpList$ | async)?.length > 0 && !loading; else loadingTemplate">
    <div class="row marg-v">
      <div class="col-md-6">
        {{ trans.trans.selectValuePosition.value }}
        <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.explaination.value"> info </span>
      </div>
    </div>
    <p-table
      [value]="companyVpList$ | async"
      #baselineTable
      [(selection)]="selectedCompanyBaseline"
      dataKey="value_prop_id"
      responsiveLayout="scroll"
      styleClass="keywest_table"
      tableStyleClass="keywest_table"
      [paginator]="true"
      responsiveLayout="scroll"
      [rows]="15"
      [sortField]="'last_modified_date'"
      [sortOrder]="-1"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem"></th>
          <th pSortableColumn="value_prop_name">{{ trans.trans.vpName.value }}<p-sortIcon field="value_prop_name"></p-sortIcon></th>
          <th pSortableColumn="solutions">{{ trans.trans.products.value }}<p-sortIcon field="solutions"></p-sortIcon></th>
          <th pSortableColumn="created">{{ trans.trans.createdDate.value }}<p-sortIcon field="created"></p-sortIcon></th>
          <th pSortableColumn="total_benefits">{{ trans.trans.totalBenefits.value }}<p-sortIcon field="total_benefits"></p-sortIcon></th>
          <th pSortableColumn="total_costs">{{ trans.trans.totalCosts.value }}<p-sortIcon field="total_costs"></p-sortIcon></th>
          <th pSortableColumn="status">{{ trans.trans.status.value }}<p-sortIcon field="status"></p-sortIcon></th>
          <th pSortableColumn="last_modified_by">{{ trans.trans.lastModifiedBy.value }}<p-sortIcon field="last_modified_by"></p-sortIcon></th>
          <th pSortableColumn="last_modified_date">{{ trans.trans.lastModifiedDate.value }}<p-sortIcon field="last_modified_date"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-companyVp>
        <tr class="not-hover" *ngIf="companyVp">
          <td>
            <p-tableRadioButton [value]="companyVp"></p-tableRadioButton>
          </td>
          <td style="max-width: 300px" class="name">
            <div class="link" (click)="chooseStep('valueposition', companyVp.value_prop_id)">{{ companyVp.value_prop_name }}</div>
          </td>
          <td>
            {{ companyVp.solutions ? (companyVp.solutions === 'MULTIPLE' ? trans.trans.multiple.value : companyVp.solutions.name ? companyVp.solutions.name : '') : '' }}
          </td>
          <td>
            {{ companyVp.created | safariDate | date }}
          </td>
          <td>{{ companyVp.total_benefits_fmt }}</td>
          <td>{{ companyVp.total_costs_fmt }}</td>
          <td>{{ companyVp.status }}</td>
          <td>{{ companyVp.last_modified_by }}</td>
          <td>{{ companyVp.last_modified_date | safariDate | date }}</td>
        </tr>
        <app-no-records *ngIf="!companyVp"></app-no-records>
      </ng-template>
    </p-table>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div class="progress-spinner-container" *ngIf="loading; else noRecords">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
</ng-template>

<ng-template #noRecords>
  <app-no-records>{{ trans.trans.noRecords.value }}</app-no-records>
</ng-template>

<div class="nav-bar-footer flex-jend">
  <button class="btn btn_customizable cancel marg-h" (click)="cancel()">{{ trans.trans.cancel.value }}</button>
  <button class="btn btn_customizable reverse marg-r" disabled="true">{{ trans.trans.back.value }}</button>
  <button class="btn btn_customizable" (click)="showTrackerModal = true" [disabled]="!selectedCompanyBaseline">
    {{ trans.trans.next.value }}
  </button>
</div>

<p-dialog [modal]="true" [breakpoints]="{ '640px': '100vw' }" [style]="{ width: '300px' }" [(visible)]="showTrackerModal">
  <ng-template pTemplate="header"> {{ trans.trans.createTrackerBaseline.value }} </ng-template>

  <div class="dialog-content">
    <div></div>
    <label class="labelsRequired" for="icon">{{ trans.trans.dateToBegin.value }}</label>
    <p-calendar
      class="pull-right"
      [(ngModel)]="beginDate"
      autofocus
      [showIcon]="true"
      inputId="icon"
      view="month"
      dateFormat="mm/yy"
      [yearNavigator]="true"
      yearRange="2020:2030"
    ></p-calendar>
    <div></div>
    <label class="labelsRequired" for="datePicker">{{ trans.trans.incrementType.value }}</label>
    <div class="calendar-dropdown">
      <p-dropdown
        [panelStyleClass]="'dropdown-item-font'"
        name="incrementType"
        [(ngModel)]="incrementType"
        optionLabel="name"
        class="keywest_dropdown dropdown-theme"
        appendTo="body"
        [options]="incrementTypes"
        filterBy="name"
        inputId="datePicker"
        [placeholder]="trans.trans.incrementType.value"
      ></p-dropdown>
    </div>
    <div></div>
  </div>
  <ng-template pTemplate="footer">
    <button class="btn btn_customizable cancel marg-h" (click)="showTrackerModal = false" [disabled]="loading">{{ trans.trans.cancel.value }}</button>
    <button
      class="btn btn_customizable"
      pButton
      iconPos="right"
      [loading]="loading"
      [disabled]="!selectedCompanyBaseline || !beginDate || !incrementType"
      (click)="selectBaselineValues()"
    >
      {{ trans.trans.createTracker.value }}
    </button>
  </ng-template>
</p-dialog>
