<div class="sidebar_container">
  <div>
    <div [ngClass]="{ 'no-shadow no-border': style2022$ | async }" class="keywest_panel">
      <p-fieldset [legend]="trans.trans.required.value">
        <div class="row">
          <div class="col-xs-12">
            <label>
              {{ trans.trans.company_name.value }}
              <app-editTranslation *ngIf="showTranslate"></app-editTranslation>
            </label>
            <input type="text" [(ngModel)]="companyAccount.name" class="form-control" required />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.country.value }}</label>
            <p-dropdown 
              appendTo="body"
              name="country"
              [autoDisplayFirst]="false"
              dataKey="id"
              [options]="countries"
              optionLabel="name"
              (onChange)="getStatesByCountries()"
              [(ngModel)]="countryDetails"
              [filter]="true"
              class="keywest_dropdown"
            ></p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.Revenue.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.revenue_fmt" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.employees.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.employees" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.industry.value }}</label>
            <p-dropdown 
              appendTo="body"
              name="industryCategory"
              [autoDisplayFirst]="false"
              (onChange)="getIndustryBySectors()"
              [options]="industrySectors"
              dataKey="id"
              optionLabel="name"
              [(ngModel)]="industrySector"
              [filter]="true"
              class="keywest_dropdown"
            ></p-dropdown>
          </div>
        </div>

        <div class="row" *ngIf="industrySector && industrySector.id">
          <div class="col-xs-12">
            <label>{{ trans.trans.industry_sector.value }}</label>
            <p-dropdown 
              appendTo="body"
              name="industry"
              [autoDisplayFirst]="false"
              [options]="industries"
              dataKey="id"
              optionLabel="name"
              [(ngModel)]="industryId"
              [filter]="true"
              class="keywest_dropdown"
            ></p-dropdown>
          </div>
        </div>
      </p-fieldset>
      <p-fieldset [legend]="trans.trans.optional.value" [toggleable]="true" [collapsed]="true">
        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.account_id.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.account_defined_id" class="form-control" />
            <app-editTranslation *ngIf="showTranslate"></app-editTranslation>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.addr1.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.address1" class="form-control" />
            <app-editTranslation *ngIf="showTranslate"></app-editTranslation>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.addr2.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.address2" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.city.value }}</label>
            <input type="text" class="form-control" id="city" [(ngModel)]="companyAccount.city" name="city" placeholder="" />
          </div>
        </div>

        <div class="row" *ngIf="countryDetails && countryDetails.id">
          <div class="col-xs-12">
            <label>{{ trans.trans.state.value }}</label>
            <p-dropdown appendTo="body" name="state" [autoDisplayFirst]="false" [options]="states" optionLabel="name" [(ngModel)]="stateId" [filter]="true" class="keywest_dropdown"></p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.phone.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.phone" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.website.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.url" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.ticker.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.ticker" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <label>{{ trans.trans.GrossProfitMargin.value }}</label>
            <input type="text" [(ngModel)]="companyAccount.gross_profit_fmt" class="form-control" />
          </div>
        </div>
      </p-fieldset>
    </div>
  </div>
</div>
<div [ngClass]="{ row: crm, btn_container: !crm }">
  <div class="col-xs-12">
    <dl-progressbar class="dl_progress" *ngIf="loading" [showValue]="true" [value]="trans.trans.saving_account.value" mode="indeterminate"></dl-progressbar>
    <a (click)="submit()" *ngIf="!loading" class="btn btn_customizable pull-right">{{ trans.trans.save.value }}</a>
    <app-are-you-sure
      [ngClass]="{
        'btn cancel-confirm': style2022$ | async,
        'btn-light': !(style2022$ | async)
      }"
      matTooltipPosition="above"
      *ngIf="!loading"
      [message]="trans.trans.are_you_sure.value"
      class="btn pull-right"
      icon=""
      [text]="trans.trans.back.value"
      (callbackYes)="close()"
    ></app-are-you-sure>
  </div>
</div>