import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { RealizedFilter, ValueRealizedCompanyWithTrackers } from '@data/services/valuerealization/models/value-realized-company.model';
import { ValueTracker } from '@data/services/valuerealization/models/value-tracker.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { concat, EMPTY, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CompanyTrackersTranslation } from './company-trackers.translation';

@Component({
  selector: 'app-company-trackers',
  templateUrl: './company-trackers.component.html',
  styleUrls: ['./company-trackers.component.scss'],
})
export class CompanyTrackersComponent implements OnInit {
  addNewSidebar = false;
  breadcrumbKeyValues: Breadcrumbs;
  columns = [
    { header: this.trans.trans.trackerName, field: 'trackerName', sortName: 'name' },
    { header: this.trans.trans.owner, field: 'owner', sortName: 'last_name' },
    { header: this.trans.trans.measurementType, field: 'measurementType', sortName: 'measurement' },
    { header: this.trans.trans.expectedtoDate, field: 'expectedtoDate', sortName: 'total_expected' },
    { header: this.trans.trans.achievedToDate, field: 'achievedToDate', sortName: 'total_realized' },
    { header: this.trans.trans.varianceToDate, field: 'varianceToDate', sortName: 'percentage' },
    { header: this.trans.trans.measurementPeriods, field: 'measurementPeriods', sortName: 'survey_count' },
    { header: this.trans.trans.measurmentBegin, field: 'measurmentBegin', sortName: 'custom_value_realized_date' },
    { header: this.trans.trans.lastMeasurement, field: 'lastMeasurement', sortName: 'last_measurement_date' },
  ];
  companyDetails: ValueRealizedCompanyWithTrackers;
  creatorFilter: RealizedFilter = RealizedFilter.all;
  loading = true;
  ngUnsubscribe$ = new Subject();
  querySearch = '';
  sharedWith: { label: string; value: RealizedFilter }[];
  companyTrackers: ValueTracker[];
  companyTrackersSelected: ValueTracker[];
  companyTrackersToInclude: number[] = [];
  companyTrackersToExclude: number[] = [];
  showTranslate: boolean;
  comanyId: string;
  public priv1 = this.commonService.checkPrivilege('1');
  public priv12 = this.commonService.checkPrivilege('12');
  public showAddButton = !this.commonService.checkFeature(41);

  constructor(
    private commonService: CommonService,
    private router: Router,
    private notificationService: NotificationService,
    public trans: CompanyTrackersTranslation,
    private translationService: TranslationsV2Service,
    private route: ActivatedRoute,
    private valuerealizationService: ValuerealizationService,
    private valuePropService: ValuepropService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.comanyId = param.companyId;
      this.getCompanyDetails(Number(this.comanyId));
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  deleteTracker(tracker: ValueTracker): void {
    this.loading = true;
    this.valuePropService.deleteValueProp(tracker.id).subscribe(
      () => {
        this.notificationService.success(`${tracker.name} ${this.trans.trans.successDelete.value}`, false);
        this.getCompanyDetails(this.companyDetails.id, this.creatorFilter);
      },
      () => {
        this.notificationService.error(`${this.trans.trans.errorDelete.value} ${tracker.name}`, false);
      },
      () => (this.loading = false)
    );
  }

  goBack(): void {
    this.router.navigate(['valuerealized']);
  }

  getCompanyDetails(id: number, filter = RealizedFilter.all): void {
    this.creatorFilter = filter;
    this.loading = true;
    this.valuerealizationService.getTrackers(id, filter).subscribe(
      (response) => {
        this.companyDetails = this.companyDetails ? this.companyDetails : response.result;
        this.companyTrackers = response.result.trackers ?? [];
        this.restartSelection();
        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.valueRealized.value]: { routerLink: '/valuerealized', active: false },
          [response.result.company_name]: { routerLink: '', active: true },
        };
      },
      () => {
        this.notificationService.error(this.trans.trans.errorDetails.value, false);
      },
      () => (this.loading = false)
    );
  }

  saveSelection(): void {
    this.loading = true;
    concat(this.saveIncludedTrackers(), this.saveExcludedTrackers()).subscribe({
      error: () => {
        this.notificationService.error(this.trans.trans.saveError.value, false);
        this.loading = false;
      },
      complete: () => {
        this.notificationService.success(this.trans.trans.saveSuccess.value, false);
        this.restartSelection();
        this.loading = false;
      }
    });
  }

  restartSelection(): void {
    this.companyTrackersSelected = this.companyTrackers.filter(t => t.vr_rollup_included === 1);
    this.companyTrackersToInclude = [];
    this.companyTrackersToExclude = [];
  }

  trackerSelected(tracker: ValueTracker): void {
    if (tracker.vr_rollup_included === 0) {
      this.companyTrackersToInclude = [...this.companyTrackersToInclude, tracker.id];
    } else {
      this.companyTrackersToExclude = this.companyTrackersToExclude.filter(t => t !== tracker.id);
    }
  }

  trackerUnselected(tracker: ValueTracker): void {
    if (tracker.vr_rollup_included === 1) {
      this.companyTrackersToExclude = [...this.companyTrackersToExclude, tracker.id];
    } else {
      this.companyTrackersToInclude = this.companyTrackersToInclude.filter(t => t !== tracker.id);
    }
  }

  tableCheckboxToggle() {
    this.companyTrackers.forEach(t => {
      if (this.companyTrackersSelected.includes(t)) {
        this.trackerSelected(t);
      } else {
        this.trackerUnselected(t);
      }
    });
  }

  defineTracker(id: string) {
    this.router.navigate(['valuerealized/company/' + id]);
  }

  onSidebarClose(): void {
    this.addNewSidebar = false;
  }

  triggerEdit(tracker: ValueTracker): void {
    this.router.navigate([tracker.id + '/edit'], { relativeTo: this.activatedRoute });
  }

  navToDetails(tracker): void {
    this.router.navigate([tracker.id], { relativeTo: this.activatedRoute });
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sharedWith = [
          { label: this.trans.trans.all.value, value: RealizedFilter.all },
          { label: this.trans.trans.mine.value, value: RealizedFilter.mine },
          { label: this.trans.trans.shared.value, value: RealizedFilter.sharedWithMe },
        ];
      });
  }

  selectCompany(): void {
    this.router.navigate(['/valuerealized/company/' + this.comanyId]);
  }

  checkDeletePermission(vp: ValueTracker, privs: { priv1: boolean; priv12: boolean }): boolean {
    if (+sessionStorage.getItem('uid') === +vp.user_id) {
      return true;
    }
    if (+vp.share_role_type_id === 4) {
      return false;
    }
    if (privs.priv1 || privs.priv12) {
      return true;
    }
    if (+vp.share_role_type_id === 3 && +vp.share_role_type_id === 5) {
      return true;
    }
  }

  private saveIncludedTrackers(): Observable<void> {
    if (this.companyTrackersToInclude.length > 0) {
      return this.valuerealizationService.toggleIncludedInRollup(this.companyTrackersToInclude, true).pipe(
          tap(() => {
            this.companyTrackersToInclude.forEach(id => this.companyTrackers.find(t => t.id === id).vr_rollup_included = 1);
          })
      );
    } else {
      return EMPTY;
    }
  }

  private saveExcludedTrackers(): Observable<void> {
    if (this.companyTrackersToExclude.length > 0) {
      return this.valuerealizationService.toggleIncludedInRollup(this.companyTrackersToExclude, false).pipe(
          tap(() => {
            this.companyTrackersToExclude.forEach(id => this.companyTrackers.find(t => t.id === id).vr_rollup_included = 0);
          })
      );
    } else {
      return EMPTY;
    }
  }
}
