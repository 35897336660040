import { Injectable } from '@angular/core';

@Injectable()
export class GuidedDiscoveryTranslation {
  public config: any = {
    component: 'Guided Discovery Business Case',
    display_name: 'Guided Discovery Business Case',
    description: '',
  };

  public trans: any = {
    modelsUpdated: 'Models have been successfully updated',
    saveAnswersSuccess: 'Answers saved successfully',
    saveAnswersError: 'Error saving answers, please try again'
  };
}
