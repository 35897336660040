import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2ModelCategoryTranslations {
  public config: any = {
    component: 'category',
    display_name: 'Solution Category',
    description: 'View of the solutions category'
  };

  public trans: any = {
    model_groups: 'Model Groups',
    add_new: 'Add new',
    delete: 'Delete',
    edit: 'Edit',
    are_you_sure: 'Are you sure? ',
    category: 'Category',
    ch_view: 'View',
    search: 'Search',
    categoryDeleted: 'Solution Category deleted successfully'
  };
}
