<div *ngIf="loading" class="text-center">
  <img [src]="fullImagePath" class="loader" /><br />
  <p>{{ trans.trans.loadingScenarioDetails.value }}</p>
</div>
<div class="row" style="margin-bottom: 20px">
  <div class="col-xs-6">
    <div class="header-row">
      <div class="guided-container clickable" *ngIf="guidedDiscoveryEnabled && !!workflows && workflows.length">
        <div (click)="showGuidedDiscovery = !showGuidedDiscovery">
          <i class="fa fa-crosshairs"> </i>
          <span>
            {{ trans.trans.guidedDiscovery.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.guidedDiscovery"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <a *ngIf="activeIndex > 0 && canEdit && !hideAdvancedOptions" (click)="toggleAdvanced()" class="clickable pull-right"
      ><i class="fa fa-cog"></i> {{ trans.trans.advancedOptions.value }}</a
    >
  </div>
</div>

<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <app-dashboard-sc-vpg-models-repv2
      *ngIf="(activeIndex == 0 || activeIndex == 1 || activeIndex == 2) && showModels"
      (callback)="vpgContinue($event)"
      [canEdit]="canEdit"
      [valueProp]="valueProp"
    ></app-dashboard-sc-vpg-models-repv2>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <app-situations-shared
      *ngIf="activeIndex === 1 || activeIndex === 2"
      (callback)="vpgContinue()"
      [canEdit]="canEdit"
      [hideSave]="true"
      (reloadVP)="reloadVP.emit()"
      [valueProp]="valueProp"
      [models]="selectedModels"
    ></app-situations-shared>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <app-dashboard-sc-vpg-scalers-repv2
      *ngIf="activeIndex == 2"
      (reloadVP)="reloadVP.emit()"
      (callback)="vpgContinue()"
      [canEdit]="canEdit"
      [valueProp]="valueProp"
    ></app-dashboard-sc-vpg-scalers-repv2>
  </div>
</div>

<app-value-prop-flow-buttons
  *ngIf="!loader"
  [disabled]="!showNext"
  [cancel]="true"
  [back]="false"
  [moveToBottom]="!crm"
  [jumpToResults]="jumpToResultsFeature"
  (goNext)="callbackNext.emit()"
  (goBack)="back.emit()"
  (jumpResults)="callbackNext.emit($event)"
  [next]="showContinue"
></app-value-prop-flow-buttons>

<p-sidebar [(visible)]="showAdvancedEditing" *ngIf="showAdvancedEditing && company" position="right" styleClass="keywest_sidebar p-sidebar-lg" (onHide)="closeAdvanced()">
  <form name="AddAdvancedForm" #AddAdvancedForm="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.advancedOptions.value }}
      </div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-sm-12" *ngIf="customerTypeFeature">
              <label>
                {{ trans.trans.CustomerType.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.CustomerType"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <select [(ngModel)]="situation.customer_type_id" class="form-control role-dropdown" name="customerType">
                <option selected value="">{{ trans.trans.ChooseOne.value }}</option>
                <option selected="customerType.id == situation.customer_type" *ngFor="let customerType of customerTypes" [value]="customerType.id">
                  {{ customerType.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.baselineType?.invalid && AddAdvancedForm.controls.baselineType?.touched }">
              <label class="labelsRequired">
                {{ trans.trans.BaselineType.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_baseline_type.value" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.BaselineType"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <select *ngIf="situation.baseline_type_id" [(ngModel)]="situation.baseline_type_id" class="form-control role-dropdown" name="baselineType" required>
                <option selected value="0">{{ trans.trans.ChooseOne.value }}</option>
                <option selected="baselineType.id == situation.baseline_type" *ngFor="let baselineType of baselineTypes" [value]="baselineType.id">
                  {{ baselineType.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label>
                {{ trans.trans.CostofCapital.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_cost_of_capital.value" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.CostofCapital"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <p-inputNumber inputStyleClass="form-control"
                             id="costOfCapital"
                             [(ngModel)]="situation.wacc"
                             [minFractionDigits]="waccPrecision"
                             [maxFractionDigits]="waccPrecision"
                             name="costOfCapital"
                             [placeholder]="trans.trans.costOfCapital.value"
              ></p-inputNumber>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.term?.invalid && AddAdvancedForm.controls.term?.touched }">
              <label class="labelsRequired">
                {{ trans.trans.Term.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_term.value" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.Term"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <select [(ngModel)]="term" class="form-control role-dropdown" name="term" required>
                <option selected value="">{{ trans.trans.ChooseOne.value }}</option>
                <option [selected]="+advancedTerm.term === +term ? 'selected' : ''" *ngFor="let advancedTerm of advancedTerms" [value]="advancedTerm.term">
                  {{ advancedTerm.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <label>
                {{ trans.trans.DeploymentTime.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_deployment_time.value" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.DeploymentTime"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="deploymentTime"
                [(ngModel)]="situation.deployment_time"
                name="deploymentTime"
                [placeholder]="trans.trans.deploymentTime.value"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12" *ngIf="grossProfitFeature">
              <label>
                {{ trans.trans.GrossProfitMargin.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_gorss_profit_margin" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.GrossProfitMargin"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input
                type="number"
                class="form-control"
                id="grossProgit"
                [(ngModel)]="situation.gross_profit"
                name="grossProgit"
                [placeholder]="trans.trans.grossProfitMargin.value"
              />
            </div>
          </div>

          <div class="row">
            <div
              class="col-sm-12"
              *ngIf="opportunityIdFeature"
              [ngClass]="{ 'has-error': AddAdvancedForm.controls.opportunity_id?.invalid && AddAdvancedForm.controls.opportunity_id?.touched }"
            >
              <label>
                {{ trans.trans.opportunity_id.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_opportunity_id.value" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.opportunity_id"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input class="form-control" [(ngModel)]="situation.opportunity_id" name="opportunity_id" [attr.required]="opportunityIdFeatureRequired" />
            </div>
          </div>

          <div class="row" *ngIf="situation.number_format_types">
            <div class="col-sm-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.number_format?.invalid && AddAdvancedForm.controls.number_format?.touched }">
              <label>
                {{ trans.trans.currency_format.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.currency_format"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <select class="form-control role-dropdown" name="format" type="text" [(ngModel)]="situation.number_format">
                <option [ngValue]="type.id" *ngFor="let type of situation.number_format_types">{{ type.name }}</option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.currency_type_id?.invalid && AddAdvancedForm.controls.currency_type_id?.touched }">
              <label>
                {{ trans.trans.currency_type_id.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_currency.value" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.currency_type_id"
                  (callback)="getTranslations()"
                ></app-editTranslation> </label
              ><br />
              <p-dropdown
                appendTo="body"
                (onChange)="setConversionRateField($event)"
                name="currency"
                [autoDisplayFirst]="false"
                [options]="situation.currency_types"
                dataKey="id"
                optionLabel="name"
                [(ngModel)]="currencyId"
                [filter]="true"
                class="keywest_dropdown"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-sm-12" [ngClass]="{ 'has-error': AddAdvancedForm.controls.conversion_rate?.invalid && AddAdvancedForm.controls.conversion_rate?.touched }">
              <label>
                {{ trans.trans.conversion_rate.value }}
                <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_conversion_rate.value" matTooltipPosition="right"> info </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.conversion_rate"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input type="number" class="form-control" [(ngModel)]="situation.conversion_rate" name="conversion_rate" required />
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ row: crm, btn_container: !crm }">
        <div class="col-xs-12">
          <dl-progressbar class="dl_progress" *ngIf="loader" [showValue]="true" [value]="'Saving Options'" mode="indeterminate"></dl-progressbar>
          <button style="margin-left: 8px" class="btn btn_customizable pull-right" *ngIf="!loader" [disabled]="AddAdvancedForm.invalid" (click)="updateAdvanced()">
            {{ trans.trans.save.value }}
          </button>
          <app-are-you-sure
            matTooltipPosition="above"
            *ngIf="!loader"
            [message]="trans.trans.areYouSure.value"
            class="btn cancel-confirm pull-right"
            icon=""
            [text]="trans.trans.back.value"
            (callbackYes)="closeAdvanced()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>

<p-sidebar [(visible)]="showAccountEdit" *ngIf="showAccountEdit" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.accountInfo.value }}
    </div>

    <div class="sidebar_container">
      <app-account-form [company]="company" (onSubmit)="funcUpdateCompany($event)" (onCancel)="closeAccount()"> </app-account-form>
    </div>
  </div>
</p-sidebar>
<p-sidebar [(visible)]="showGuidedDiscovery" *ngIf="showGuidedDiscovery" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.guidedDiscovery.value }}
    </div>

    <div class="sidebar_container">
      <app-guided-discovery (updateWorkflow)="closeGuided()" [valuePropId]="valueProp.id"></app-guided-discovery>
    </div>
  </div>
</p-sidebar>
