<div class="change-password center" style="overflow-x: hidden;">
    <form class="form-horizontal" [formGroup]="passwordForm" (submit)="onSubmit($event)">
        <div class="form-group">
            <label for="currentPassword" class="col-sm-4 labelsRequired form-label w-100">{{
                trans.trans.currentPassword.value }}
                <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
                    [transObj]="trans.trans.currentPassword" (callback)="getTranslations()"></app-editTranslation>
            </label>
            <div class="col-sm-4 w-100 mb-15">
                <input type="password" class="form-control" formControlName="currentPassword" />
                <!--  -->
                <small class="text-danger" *ngIf="passwordForm.get('currentPassword').hasError('required') && passwordForm.get('currentPassword').touched">
                    {{trans.trans.currentPasswordRequired.value}}
                </small>
            </div>
        </div>
        <div class="form-group">
            <label for="newPassword" class="col-sm-4 labelsRequired w-100 form-label">
                {{trans.trans.newPassword.value}}
                <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
                    [transObj]="trans.trans.newPassword" (callback)="getTranslations()"></app-editTranslation>
            </label>
            <div class="col-sm-8 w-100 mb-15">
                <input type="password" class="form-control" formControlName="newPassword" />
                <!--  -->
                <small class="text-danger" *ngIf="passwordForm.get('newPassword').hasError('required') && passwordForm.get('newPassword').touched">
                    {{trans.trans.newPasswordRequired.value}}
                </small>
            </div>
        </div>
        <div class="form-group">
            <label for="confirmPassword" class="col-sm-4 w-100 labelsRequired form-label">{{
                trans.trans.confirmPassword.value }}
                <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
                    [transObj]="trans.trans.confirmPassword" (callback)="getTranslations()"></app-editTranslation>
            </label>
            <div class="col-sm-8 w-100 mb-15">
                <input type="password" class="form-control" formControlName="confirmPassword" />
                <!--  -->
                <small class="text-danger" *ngIf="passwordForm.errors?.notMatching && passwordForm.get('confirmPassword').touched">
                    {{trans.trans.passwordNonMatch.value}}
                </small>
            </div>
        </div>

        <div class="form-group">
            <div class="reverse-button-layout">
                <button class="btn btn_customizable ml-15" type="submit" [disabled]="passwordForm.invalid">{{
                    trans.trans.save.value }}</button>
                <button type="button" class="btn btn-secondary btn-cancel" (click)="CancelChangePwd()">
                    {{trans.trans.cancel.value}}
                </button>
            </div>
        </div>
    </form>
</div>