<div [ngClass]="{'style2022': (style2022$ | async)}">
	<div *ngIf="savedQuestions.length == 0 && !surveyTemplateLoader">
		<form #createSurvey="ngForm">
			<div class="panel panel-white">
				<div class="panel-heading">
					<div class="panel-title">
						<h4>{{'survey-template-create.general_questions' | translate}}</h4>
					</div>
				</div>
				<div class="panel-body">
					<div class="dynamicQuestionare">
						<div class="row">
							<div class="rowBlock" *ngFor="let question of questions; let i = index">
								<div class="clearfix" *ngIf="(i == 0 || i % 2 == 0)"></div>
								<div class="col-md-6">
									<div class="questionBlock" [ngClass]="'question' + question.id">
										<div class="row">
											<div class="col-md-3">
												<p>{{'survey-template-create.question_#' | translate}}{{question.id}}</p>
											</div>
											<div class="col-md-6">
												<div class="row">
													<div class="form-group">
														<input type="text" name="question{{question.id}}" [(ngModel)]="question.question" class="form-control" placeholder="Question #{{question.id}}" [required]="!question.questionOverlay" [disabled]="question.questionOverlay" />
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-offset-1 col-md-5 p-l-0">
												<div style="pointer-events: none;">
													<label *ngIf="(question.questionType != '' && question.questionType != 2)"><strong>{{'survey-template-create.example' | translate}}</strong></label>
													<div class="form-group" *ngIf="question.questionType == '1'">
														<input type="text" class="form-control" placeholder="Short Text" />
													</div>
													<div class="form-group" *ngIf="question.questionType == '3'">
														<label class="switch">
															<span>{{'general.No' | translate}}</span>
															<input type="checkbox" class="customcheckbox" />
															<span>{{'general.Yes' | translate}}</span>
														</label>
													</div>
													<div class="form-group" *ngIf="question.questionType == '4'">
														<textarea rows="1" class="form-control">{{'survey-template-create.long_text' | translate}}</textarea>
													</div>
												</div>
												<div class="form-group" *ngIf="question.questionType == '2'">
													<div class="createOptions">
														<label><strong>{{'survey-template-create.add_options' | translate}}</strong></label>

														<div class="optionGroup" *ngFor="let option of question.options; let i = index">
															<span class="radioCircle"></span>
															<input type="text" name="question{{question.id}}option{{i}}" class="form-control" [(ngModel)]="option.option" required>
															<button *ngIf="question.options.length == (i+1)" (click)="addOptions(question.id)" [disabled]="option.option == ''" class="btn btn_customizable">
																<i class="fa fa-plus"></i>
															</button>
														</div>
													</div>

												</div>
											</div>

											<div class="col-md-3 p-r-0 p-l-0">
												<select class="form-control" [(ngModel)]="question.questionType" (ngModelChange)="questionTypeChange(question.questionType, 'question' + question.id)" name="questionType{{question.id}}" [required]="!question.questionOverlay" [disabled]="question.questionOverlay">
													<option value="" disabled="true" [selected]="!question.questionType">{{'survey-template-create.answer_type' | translate}}</option>
													<option *ngFor="let questionType of questionTypes" [value]="questionType.id">
														{{questionType.name}}
													</option>
												</select>
											</div>
										</div>

										<div class="questionOverlay" (click)="enableQuestion()" *ngIf="question.questionOverlay"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="button-group pull-right">
				<img class="actionLoader" [src]="fullImagePath" *ngIf="actionLoader" />
				<button class="btn btn_customizable" (click)="saveSurvey()" [disabled]="createSurvey.invalid">{{'general.save' | translate}}</button>
			</div>
		</form>
	</div>

	<div *ngIf="savedQuestions.length > 0">
		<div class="panel panel-white">
			<div class="panel-heading">
				<div class="panel-title">
					<h4>{{'survey-template-create.questions' | translate}}</h4>
				</div>
			</div>
			<div class="panel-body">
				<div class="dynamicQuestionare">
					<div class="row">
						<div class="rowBlock" *ngFor="let question of savedQuestions; let i = index">
							<div class="clearfix" *ngIf="(i == 0 || i % 2 == 0)"></div>
							<div class="col-md-6">
								<div class="questionBlock" [ngClass]="'question' + question.id">
									<div class="row">
										<div class="col-md-10">
											<p>{{i+1}}. {{question.label}}</p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-offset-1 col-md-5 p-l-0">
											<div style="pointer-events: none;">
												<label *ngIf="(question.question_type_id != 2)"><strong>{{'survey-template-create.example' | translate}}</strong></label>
												<div class="form-group" *ngIf="question.question_type_id == '1'">
													<input type="text" class="form-control" placeholder="Short Text" />
												</div>
												<div class="form-group" *ngIf="question.question_type_id == '3'">
													<label class="switch">
														<div class="checker">
															<input type="checkbox" class="customcheckbox" />
														</div>
														<span>{{'general.Yes' | translate}}</span>
													</label>
												</div>
												<div class="form-group" *ngIf="question.question_type_id == '4'">
													<textarea rows="1" class="form-control">{{'survey-template-create.long_text' | translate}}</textarea>
												</div>
											</div>
											<div class="form-group" *ngIf="question.question_type_id == '2'">
												<div class="createOptions">
													<label><strong>{{'survey-template-create.options' | translate}}</strong></label>

													<ul class="optionGroup" *ngFor="let option of question.question_options; let i = index">
														<li>
															<span class="radioCircle"></span>
															<span>{{option.name}}</span>
														</li>
													</ul>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="surveyTemplateLoader" class="col-12 text-center">
		<img class="actionLoader" [src]="fullImagePath" />
	</div>
</div>