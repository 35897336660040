import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ProgressBarModule } from 'primeng/progressbar';

import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';

import { GoalFormTranslation } from './goal-form.translation';
import { GoalFormComponent } from './goal-form.component';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { ModelGoalsTranslation } from 'app/value-mapv2/solution-detail/goals/model-goals.translation';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { UnitTypeModule } from '@shared/unit-type/unit-type.module';
import { GoalGroupsModule } from 'app/value-map-maintenance/views/goal-groups/goal-groups.module';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [GoalFormComponent],
  imports: [
    CommonModule,
    EditTranslationsModule,
    ProgressBarModule,
    FormsModule,
    ErrorInputWrapperModule,
    ReactiveFormsModule,
    DropdownModule,
    InputNumberModule,
    FunctionPipeModule,
    UnitTypeModule,
    GoalGroupsModule,
    ButtonModule,
  ],
  providers: [TranslationsV2Service, GoalFormTranslation, GoalGroupsService, ModelGoalsTranslation],
  exports: [GoalFormComponent],
})
export class GoalFormModule {}
