import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Company } from '@shared/models/company.model';
import { SimpleType } from 'app/repv2/views/scenario_combined_vpg/views/models/small-model.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Countries } from './../../../../value-position/add-value-positionv2/dynamic_components/interfaces/company.interface';

@Component({
  selector: 'app-add-company-case-study',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
  providers: [CompanyService],
})
export class AddCompanyCaseStudyComponent implements OnInit, OnDestroy {
  @Input() modalCreate = false;

  @Output() companyCreated = new EventEmitter();
  @Output() enableSave = new EventEmitter();
  @ViewChild('addCompanyForm', { static: true }) addCompanyForm;

  company: Company;
  industrySectors: BasicInfo[];
  countries: Countries;
  industries: BasicInfo[];
  industriesShow = true;
  industriesLoader = false;
  statesLoader = true;
  states: { [klass: string]: any }[];
  addCompanyLoader: boolean;
  fullImagePath: string;
  countryDetails: string;
  contextualHelp: { [klass: string]: any } = {};
  contextualHelpSub: any;
  scraperCompany: { [klass: string]: any };

  ngUnsubscribe = new Subject<boolean>();

  constructor(
    private factsService: FactsService,
    private companyService: CompanyService,
    private location: Location,
    private router: Router,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    const image_url = this.commonService.getImageUrl();
    this.fullImagePath = image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.countryDetails = '';
    this.company = {
      name: '',
      industry_sector_id: '',
      industry_id: '',
      address1: '',
      city: '',
      state: '',
      country_id: '',
      employees: '',
      net_sales: 0,
      zip: '',
      country: '',
    };
    this.addCompanyForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (data === 'VALID') {
        this.enableSave.emit('true');
      } else {
        this.enableSave.emit('false');
      }
    });
    this.getIndustrySectors();
    this.getCountries();
    this.getContextualHelp();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getContextualHelp(): void {
    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.contextualHelp = response['Valueprop'];
    });
  }
  getIndustrySectors(): void {
    this.factsService
      .getSectors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.industrySectors = response.result;
      });
  }
  getCountries(): void {
    this.factsService
      .getCountries()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.countries = response.result.countries;
      });
  }
  getStatesByCountries(country): void {
    this.company.country_id = country.id;
    this.company.country = country.name;
    this.statesLoader = true;
    this.factsService
      .getStatesByCountries(country.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.statesLoader = false;
        this.states = response.result.states;
      });
  }
  getIndustryBySectors(sectorId): void {
    this.industriesLoader = this.industriesShow = true;
    this.factsService
      .getIndustriesBySector(sectorId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.industriesLoader = this.industriesShow = false;
        this.industries = response.result.dl_industries;
      });
  }

  addCompany(): void {
    this.addCompanyLoader = true;
    this.companyService
      .addCompany(this.company)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.addCompanyLoader = false;
        if (response.result && response.result.id && !this.modalCreate) {
          this.router.navigate(['company/' + response.result.id]);
        } else if (response.result && response.result.id && this.modalCreate) {
          this.companyCreated.emit(response.result);
        } else if (response.result && 'success' in response.result && response.result.success == false) {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  backClicked(): void {
    this.location.back();
  }

  searchWithScraper(): void {
    const payload = {
      companyName: this.company.name,
    };

    this.companyService
      .searchWithScraper(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.scraperCompany = response.result;
      });
  }
}
