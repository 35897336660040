import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { TrackerBaselineTranslation } from './tracker-baseline.translation';
import { catchError, finalize, map, pluck, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { UserService } from '@data/services/user/user.service';
import { IncrementType } from '@shared/models/increment-type.model';
import { CompanyPromotedVP, TrackerBaselinePayload } from '@data/services/valuerealization/models/value-realized-company.model';
import { ValueTracker } from '@data/services/valuerealization/models/value-tracker.model';
import { Router } from '@angular/router';
import { ChooseAdventureInternalComponent } from '@shared/choose-adventure-internal/choose-adventure-internal.component';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';

@Component({
  selector: 'app-tracker-baseline',
  templateUrl: './tracker-baseline.component.html',
  styleUrls: ['./tracker-baseline.component.scss'],
})
export class TrackerBaselineComponent implements OnInit {
  @Input() companyId: string;
  @Input() set trackerInfo(info: ValueTracker) {
    this.trackerDetails = info;
    this.setTrackerInfo();
  }
  @Output() nextCallback = new EventEmitter();
  @Output() cancelCallback = new EventEmitter();
  companyVpList$: Observable<CompanyPromotedVP[]>;
  selectedCompanyBaseline: CompanyPromotedVP;
  trackerDetails: ValueTracker;

  beginDate = new Date();
  incrementType: IncrementType;
  incrementTypes: IncrementType[];
  loading = false;
  showTrackerModal = false;

  ngUnsubscribe = new Subject();
  pageLimit = 25;
  showStepSelection = false;
  public ff134 = this.commonService.checkFeature('134');
  public workflows: ConversationWorkflow[] = [];
  @ViewChild('chooseAdventure') chooseAdventureDialog: ChooseAdventureInternalComponent;

  constructor(
    public trans: TrackerBaselineTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private valueRealizedService: ValuerealizationService,
    private userService: UserService,
    private router: Router,
    private configurableWorkflowService: ConfigurableWorkflowService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    if (sessionStorage.getItem('workflows') && sessionStorage.getItem('workflows') !== 'undefined') {
      this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
    }
    this.getTranslations();
    this.getIncrementTypes();
    this.companyVpList$ = this.valueRealizedService.getPromotedVPSByCompany(this.companyId).pipe(
      catchError((err) => of(err)),
      take(1),
      finalize(() => (this.loading = false)),
      map((res) => {
        return res.map((vp) => {
          return { ...vp, total_benefits: +vp.total_benefits, total_costs: +vp.total_costs };
        });
      })
    );
    this.userService.replaceFF134$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((replaceFF134) => {
      if (replaceFF134) {
        let features = sessionStorage.getItem('features').split(',');
        if (!features.includes('134')) {
          features.push('134');
          sessionStorage.setItem('features', features.join(','));
          this.ff134 = this.commonService.checkFeature('134');
        } else {
          const newFeatures = features.filter((f) => f !== '134');
          if (newFeatures.length) {
            const featuresString = newFeatures.join(',');
            sessionStorage.setItem('features', featuresString);
          }
        }
      }
    });
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  openVp(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([this.commonService.getLinkVPRoute('valueposition', id, 4)]));

    window.open(url, '_blank');
  }

  getIncrementTypes() {
    this.valueRealizedService
      .getIncrementTypes()
      .pipe(take(1))
      .subscribe((types) => {
        this.incrementTypes = types;
      });
  }

  isInvalid() {
    return !this.selectedCompanyBaseline || !this.beginDate || !this.incrementType;
  }

  selectBaselineValues() {
    if (this.isInvalid()) {
      return;
    }
    this.loading = true;
    this.userService.user
      .pipe(
        switchMap(({ id: user_id }) => {
          // Find the timezone difference and create a new time that lines up with UTC
          const tzDifference = this.beginDate.getTimezoneOffset();
          const offsetTime = new Date(this.beginDate.getTime() - tzDifference * 60 * 1000);

          const custom_value_realized_date = offsetTime.toISOString().replace(/[a-z]/gi, ' ');
          const payload: TrackerBaselinePayload = {
            user_id,
            custom_value_realized_date,
            increment_type_id: this.incrementType.id,
            is_promote: 1,
            name: this.selectedCompanyBaseline.value_prop_name,
          };

          const crm = sessionStorage.getItem('crm');

          if (crm) {
            const crmJson: { [klass: string]: string } = JSON.parse(crm);
            const sfaide = crmJson.sfaide;
            if (sfaide) {
              payload['sfaide'] = sfaide;
            }
          }

          return this.valueRealizedService.saveTrackerBaseline(this.selectedCompanyBaseline.value_prop_id, payload).pipe(
            finalize(() => this.finalize()),
            take(1),
            pluck('result')
          );
        })
      )
      .subscribe((result) => {
        if (result && result.success) {
          this.nextCallback.emit(result.value_prop.id);
        }
      });
  }

  private finalize() {
    this.loading = false;
    this.showTrackerModal = false;
    this.beginDate = new Date();
    this.incrementType = null;
  }

  cancel() {
    this.cancelCallback.emit();
  }

  private setTrackerInfo(): void {
    if (this.trackerDetails) {
      this.incrementType = this.incrementTypes.find((type) => Number(type.id) === Number(this.trackerDetails.increment_type_id));
      if (String(this.trackerDetails.custom_value_realized_date) !== '0000-00-00 00:00:00') {
        this.beginDate = new Date(String(this.trackerDetails.custom_value_realized_date).replace(' ', 'T'));
      }
    }
  }

  public chooseStep(route: string, id: string) {
    this.chooseAdventureDialog.newWindow = true;
    this.chooseAdventureDialog.navigationRoute = this.commonService.getLinkVPRoute(route, id, 4);
    this.chooseAdventureDialog.existingVp = true;
    switch (this.workflows.length) {
      case 0:
        this.configurableWorkflowService.selectedConversationWorkflow$.next(null);
        this.openVp(id);
        break;
      case 1:
        this.configurableWorkflowService.selectedConversationWorkflow$.next(this.workflows[0]);
        this.userService.replaceFF134$.next(false);
        this.openVp(id);
        break;
      default:
        this.showStepSelection = true;
        break;
    }
  }

  public onHidden(hidden: boolean) {
    this.showStepSelection = hidden;
  }
}
