import { Component, OnInit, Input, OnDestroy, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { ActivatedRoute } from "@angular/router";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AEThresholdTranslations } from './ae_thresholds.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

@Component({
	selector: 'app-ae-thresholds',
	templateUrl: './ae_thresholds.component.html',
})
export class AEThresholdsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() solutionID: number;
	@Input() mode: string = "add";
	@Output() callback = new EventEmitter();
	ngUnsubscribe = new Subject();

	solutionThreshold: any[] = [];
	accountSolutionId: number;
	accountId: number;
	loadthresholdTable: boolean;
	thresholdTypes: any = [];
	// Contextual Help
	contextualHelp: object = {};
	fullImagePath: string = "";
	strSearch: string = "";

	@Input() can_edit: boolean = false; // can the record be edited or not

	@ViewChild('formAddThreshold') formAddThreshold: NgForm;

	@Input() thresholdFormObj = {
		threshold_type_id: null,
		name: "",
		min: null,
		max: null,
		margin: null,
		id: 0
	};

	// new
	addEditThreshold: any;
	loadingSave: boolean = false;
	toggleAddThreshold: boolean = false;
	dropdown: any[] = [];
	showTranslate = false;
	@Input() sidebar_title: any;

	cols = [
		{ field: 'name', header: 'Name' },
		{ field: 'min', header: 'Min' },
		{ field: 'max', header: 'Max' },
		{ field: 'margin', header: 'Margin' }
	]

	constructor(
		private solutionService: SolutionService,
		private commonService: CommonService,
		private factsService: FactsService,
		private notification: NotificationService,
		private route: ActivatedRoute,
		public trans: AEThresholdTranslations,
		private translationService: TranslationsV2Service
	) {
		this.fullImagePath = commonService.getLoaderImageUrl();

	}

	ngOnInit() {

		this.getTranslations();

		this.accountSolutionId = parseInt(this.route.snapshot.paramMap.get('id'));
		this.accountId = parseInt(sessionStorage.getItem('aid'));

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.getThresholdList();
		this.getThresholdTypeList();


		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
	}

	ngAfterViewInit() {
		if (this.mode === 'edit') {
			setTimeout(() => {
				this.formAddThreshold.controls.threshold_type_id.setValue(this.thresholdFormObj.threshold_type_id);
			}, 0);
		}
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	close(): void {
		this.callback.emit();
	}
	
	getTranslations() {

		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
			this.sidebar_title = this.mode === 'add' ? this.trans.trans.addTitle : this.trans.trans.editTitle;
		});
	}


	getThresholdList() {
		this.loadthresholdTable = true;
		this.solutionService.getThresholds(this.accountId, this.accountSolutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {

			if (response.result) {
				this.solutionThreshold = response.result;
			}
			this.loadthresholdTable = false;
		});
	}

	getThresholdTypeList() {
		this.factsService.getSolutionThresholdTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.thresholdTypes = response.result.thresholdTypes.map(item => {
					item['threshold_type_id'] = item['id'];
					return item;
				});
			}
		});
	}


	getThresholdTypesNotExisting() {
		if (this.thresholdTypes.length < 1) {
			return [];
		} else if (this.mode === 'edit') {
			let typeId = this.formAddThreshold.controls.threshold_type_id.value;
			this.thresholdTypes = this.thresholdTypes.filter((type) => type.threshold_type_id === typeId);
			return this.thresholdTypes.filter((type) => type.threshold_type_id === typeId);
		} else {
			let existingTypes = this.solutionThreshold.reduce((acc, solution) => ({
				...acc,
				[solution.threshold_type_id]: solution.threshold_type_id
			}), {});
			return this.thresholdTypes.reduce((acc, curr) => {
				if (existingTypes[curr.threshold_type_id]) {
					return acc;
				} else {
					acc.push(curr);
				}
				return acc;
			}, []);
		}
	}

	saveThreshold(form: NgForm) {
		this.loadingSave = true;
		const isEdit = this.mode === 'edit';
		const payload = {
			"id": isEdit ? form.controls.id.value : 0,
			"account_solution_id": this.accountSolutionId,
			"threshold_type_id": form.controls.threshold_type_id.value,
			"min": form.controls.min.value,
			"max": form.controls.max.value,
			"margin": form.controls.margin.value
		};

		const succesMessage = isEdit ? this.trans.trans.successThresholdUpdate.value :
		 this.trans.trans.successThreshold.value;

		this.solutionService.SaveThreshold(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.loadingSave = false;
			this.notification.success(succesMessage, false);
			this.toggleAddThreshold = false;
			this.callback.emit("");
		});

	}

	compareMinMax(min, max) {
		return Number(min) > Number(max);
	}
}


