import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgModule } from '@angular/core';
import { TableChartComponent } from './tablechart.component';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
	declarations: [
		TableChartComponent
	],
	entryComponents: [TableChartComponent],
	imports: [
		DropdownModule,
		CommonModule,
		NgbModule,
		TableModule,
		FormsModule,
		ToggleButtonModule,
		TableModule,
		CalendarModule,
		ChartModule,
    MatTooltipModule,
	],
	exports: [TableChartComponent]
})

export class TableChartModule {}