import { Injectable } from '@angular/core';

@Injectable()
export class AEQuoteTranslations {
	public config: any = {
		component: 'addEditQuote',
		display_name: 'Case Studies Quote Form',
		description: 'Form for creating and editing a quote for a case study'
	};

	public trans: any = {
		editQuote: 'Edit Testimonial',
		addQuote: 'Add Testimonial',
		save: 'Save',
		first_name: 'First Name',
		last_name: 'Last Name',
		testimonial: 'Testimonial',
		title: 'Title',
    edit_translations: 'Edit Translations',
		are_you_sure: 'Are you sure?',
		back: 'Back'
	};
}
