import { StyleService } from 'app/style.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EditDirectiveService } from '@shared_components/editTranslation/edit.service';
import { NotificationService } from '@services/notification.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { TranslationsV2Translation } from './translationsv2.translation';
import { CommonService } from '@data/services/common/common.service';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { UserService } from '@data/services/user/user.service';
import { FormControl } from '@angular/forms';
import { LanguageType } from '@shared/models/user-info.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';

export interface TranslationComponent {
  component: string;
  description: string;
  display_name: string;
  expand: boolean;
  id: string;
}

@Component({
  selector: 'app-translationsv2',
  styleUrls: ['./translationsv2.component.scss'],
  templateUrl: 'translationsv2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationsV2Component implements OnInit, OnDestroy {
  uid: string;
  aid: string;
  expanded = false;
  flag: string;
  allTranslationsv2Keys: {
    [k: string]: {
      values: { [lang: string]: any }[];
    };
  };
  allTranslationsv2Indexed: { [klass: string]: any };
  allComponents: TranslationComponent[];
  filteredComponents: TranslationComponent[];
  currentLang: LanguageType;
  language_type_id: string;
  languages: LanguageType[];
  breadcrumbKeyValues: Breadcrumbs;

  searchField = new FormControl('');
  ngUnsubscribe = new Subject();
  showTranslate = false;
  areFiltersLocked = false;
  style2022$: Observable<boolean>;
  fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  isLoading = false;

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    public trans: TranslationsV2Translation,
    private translationService: TranslationsV2Service,
    private editService: EditDirectiveService,
    private notification: NotificationService,
    private cdr: ChangeDetectorRef,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;

    this.allTranslationsv2Keys = undefined;
    this.uid = sessionStorage.getItem('uid');
    this.aid = sessionStorage.getItem('aid');

    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.flag = this.translationService.getLanguageFlagIcon(res);
      this.getTranslations();
    });

    this.userService.getLanguages(true).subscribe((result) => {
        this.languages = result.result.map((l) => {
          l.lang = this.translationService.getLanguageAbbr(l.id);
          return l;
        });
        const langId = sessionStorage.getItem('language_type_id');
        this.currentLang = this.languages.find((lang) => lang.id === langId);
        this.getTranslations();
      });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getAllTranslationsV2();

    this.getAllComponents();

    this.searchField.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), startWith(''), takeUntil(this.ngUnsubscribe)).subscribe((searchTerm: string) => {
      if (this.allComponents) {
        this.filterComponents(searchTerm);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  returnLanguageIcon(langId: string): string {
    return this.translationService.getLanguageFlagIcon(langId);
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: this.aid,
      component: this.trans.config.component,
      lang: langAbbr,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.translations.value]: { routerLink: '', active: true },
        };
      });
  }

  getAllTranslationsV2(): void {
    this.isLoading = true;
    this.translationService
      .getAllTranslations(this.aid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        const transByComponent = res.result.reduce((acc, curr) => {
          if (!acc[curr.component]) {
            acc[curr.component] = {
              values: {},
            };
          }
          if (!acc[curr.component].values[curr.key]) {
            acc[curr.component].values[curr.key] = {};
          }
          acc[curr.component].values[curr.key][curr.lang] = curr;
          return acc;
        }, {});

        this.allTranslationsv2Keys = Object.keys(transByComponent).reduce((acc, curr: string) => {
          acc[curr] = {
            values: Object.values(transByComponent[curr].values).map((el: any) => ({
              ...el,
              org: { ...el.en },
            })),
          };
          return acc;
        }, {});

        this.getAllOverrides(transByComponent);

        this.allTranslationsv2Indexed = Object.keys(transByComponent).map((compKey: any) => ({
          [compKey]: transByComponent[compKey],
          component: compKey,
          description: compKey.description,
          vals: Object.values(transByComponent[compKey].values),
        }));
        this.isLoading = false;
        this.cdr.reattach();
        this.cdr.detectChanges();
      });
  }

  getAllComponents(): void {
    this.translationService
      .getAllComponents()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.allComponents = res.result.sort((a, b) => a.display_name.localeCompare(b.display_name));
        this.filteredComponents = [...this.allComponents];
        this.cdr.detectChanges();
      });
  }

  getAllOverrides(allTranslations): void {
    this.translationService
      .getAllOverrides(this.aid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ result }) => {
        result.forEach((override) => {
          if (override.component && allTranslations[override.component] && override.key) {
            const original = allTranslations[override.component].values[override.key][override.lang];
            if (original.id === override.translation_id) {
              this.allTranslationsv2Keys[override.component].values.forEach((val, index) => {
                if (val[override.lang] && val[override.lang]['key'] === override.key) {
                  this.allTranslationsv2Keys[override.component].values[index][override.lang] = {
                    ...override,
                    oldValue: override.val,
                  };
                }
              });
            }
          }
        });
        this.cdr.detectChanges();
      });
  }

  setLanguage(langId: string): void {
    sessionStorage.setItem('language_type_id', langId);
    this.commonService.notifyChangeLanguage.next(langId);
    const user_id = sessionStorage.getItem('uid');
    const payload = {
      language_type_id: langId,
    };
    this.language_type_id = langId;
    this.editService.updateLanguage(user_id, payload).subscribe(() => {
      this.commonService.notifyChangeLanguage.next(langId);
      this.cdr.detectChanges();
    });
  }

  updateTranslationV2(trans): void {
    if (trans.oldValue === trans.val) {
      return;
    }
    let payload;

    if (trans.translation_id) {
      payload = {
        ...trans,
        value: trans.val,
        account_id: this.aid,
        translation_id: trans.translation_id,
        translations_override_id: trans.id,
      };
      this.translationService
        .updateOverrideTranslation(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.notification.success(this.trans.trans.translationsUpdated.value, false);
          this.areFiltersLocked = false;
          this.refreshData();
        });
    } else {
      payload = {
        ...trans,
        value: trans.val,
        account_id: this.aid,
        translation_id: trans.id,
      };
      this.translationService
        .overrideTranslation(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.notification.success(this.trans.trans.translationsUpdated.value, false);
          this.refreshData();
        });
    }
  }

  refreshData(): void {
    this.getAllTranslationsV2();
  }

  toggleExpandCollapse(): void {
    this.expanded = !this.expanded;
    this.allComponents.forEach((comp) => (comp.expand = this.expanded));
  }

  filterTable(comp: TranslationComponent): { [lang: string]: any }[] {
    if (this.searchField.value) {
      return this.allTranslationsv2Keys[comp.component].values.filter((vals) => {
        const termOrg = vals['org'] && vals['org'].val ? vals['org'].val.toLowerCase() : '';
        const termEn = vals['en'] && vals['en'].val ? vals['en'].val.toLowerCase() : '';
        return termOrg.includes(this.searchField.value.toLowerCase()) || termEn.includes(this.searchField.value.toLowerCase());
      });
    } else {
      return this.allTranslationsv2Keys && this.allTranslationsv2Keys[comp.component] ? this.allTranslationsv2Keys[comp.component].values : [];
    }
  }

  filterComponents(str: string) {
    if (this.isEmptyInputValue(str)) {
      this.filteredComponents = [...this.allComponents];
      this.cdr.detectChanges();
      return;
    }

    this.filteredComponents = this.allComponents.filter((comp) => {
      if (this.allTranslationsv2Keys && this.allTranslationsv2Keys[comp.component]) {
        const hasTrans = this.allTranslationsv2Keys[comp.component].values.filter((vals) => {
          let termOrg: string, termEn: string;
          if (vals.org && vals.org.val) {
            termOrg = vals.org.val.toLowerCase();
          }
          if (vals.en && vals.en.val) {
            termEn = vals.en.val.toLowerCase();
          }

          if (termOrg || termEn) {
            return termOrg.includes(str.toLowerCase()) || termEn.includes(str.toLowerCase());
          } else {
            return '';
          }
        });
        return hasTrans.length > 0;
      } else {
        return false;
      }
    });
    this.cdr.detectChanges();
  }

  isEmptyInputValue(value: any): boolean {
    return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
  }

  activateCDR(): void {
    this.cdr.reattach();
  }

  deactivateCDR(): void {
    this.cdr.detach();
  }

  saveEnter(event: KeyboardEvent, row): void {
    this.updateTranslationV2(row);
    const input = event.target as HTMLInputElement;
    input.blur();
  }

  escapeKeyEvent(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.blur();
  }

  clearSearch(): void {
    this.resetSearchField();
    this.cdr.detectChanges();
  }

  private resetSearchField(): void {
    this.searchField.reset();
  }
}
