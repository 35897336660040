import userflow from 'userflow.js';

export const userflowInit = () => {
  let hostname = window.location.hostname;
  switch (hostname) {
    case "localhost":
      // no userflow for localhost
      break;

    case "develop.ui.value-cloud.com":
    case "stage.value-cloud.com":
      userflow.init("ct_67gwsawya5bypidjp364f4b6oi")
      break;

    default:
      userflow.init("ct_n57kkog53rbabkeijhnrrg2m5a");
      break;
  }
}

