import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Category } from '@data/services/valueposition/models/category.interface';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelsV2ModelCategoryTranslations } from './category.translation';

@Component({
  selector: 'app-model-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class ModelCategoryComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  modelCategoryList: Category[] = [];
  toggleAddEditCategory = false;
  dropdown: MenuItem[] = [];

  @ViewChild('formAddEditCategory') formAddEditCategory: NgForm;
  loading = true;

  cols: Array<{field: string, header: string}> = [{ field: 'category', header: 'Category' }];
  strSearch = '';

  sidebar_title = 'Add Model Category';
  mode = 'add';

  selectedElem: Category = {
    category: '',
    description: '',
    id: '',
  };

  private ngUnsubscribe$ = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private notification: NotificationService,
    private commonService: CommonService,
    public trans: ModelsV2ModelCategoryTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getSolutionCategoryList();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  closeAddEditCategory(): void {
    this.toggleAddEditCategory = false;
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  openAddModel(): void {
    this.toggleAddEditCategory = true;
    this.mode = 'add';
    // tslint:disable-next-line: forin
    for (const k in this.selectedElem) {
      this.selectedElem[k] = null;
    }
  }

  funcToggleEdit(elem: Category): void {
    this.mode = 'edit';
    this.sidebar_title = 'Edit Model Category';
    this.selectedElem = Object.assign({}, elem);
    this.toggleAddEditCategory = true;
  }

  funcToggleView(elem: Category): void {
    this.toggleAddEditCategory = true;
    this.selectedElem = Object.assign({}, elem);
  }

  getSolutionCategoryList(): void {
    this.loading = true;
    this.solutionService
      .getSolutionCategoryList()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.loading = false;
        this.modelCategoryList = res && res.result && res.result.length ? res.result.sort((catA, catB) => +catA.sequence - +catB.sequence) : [];
      });
  }

  deleteModelCategory(id: string | number): void {
    this.solutionService
      .deleteSolutionCategory(id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        () => {
          this.toggleAddEditCategory = false;
          this.getSolutionCategoryList();
          this.solutionService.refreshModelsTotals.next();

          this.notification.success(this.trans.trans.categoryDeleted.value, false);
        },
        (error) => {
          this.toggleAddEditCategory = false;
          this.getSolutionCategoryList();
          this.solutionService.refreshModelsTotals.next();
          this.notification.error(error, false);
        }
      );
  }
}
