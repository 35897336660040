<!--LIST-->
<div>
  <div class="keywest_header">
    <div class="row">
      <div class="col-xs-6">
        <div class="title">
          {{ trans.trans.situations.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.situations"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </div>
      </div>
      <div class="flex-col-aie">
        <button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="toggleAddSituation(false)" [matTooltip]="trans.trans.addNew.value" matTooltipPosition="above">
          <i class="fa fa-plus"></i> {{ trans.trans.addNew.value }}
        </button>
        <div *ngIf="!toggles.showSituationForm && !toggles.showView" class="expand-collapse-text">
          <span *ngIf="!allExpanded" class="clickable" (click)="expandOrCollaseAll(true)">+ {{ trans.trans.expandAll.value }}</span>
          <span *ngIf="allExpanded" class="clickable" (click)="expandOrCollaseAll(false)">- {{ trans.trans.collapseAll.value }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!toggles.showSituationForm && !toggles.showView">
    <div class="col-sm-12">
      <p-treeTable [value]="situations" (onNodeExpand)="onNodeChange()" (onNodeCollapse)="onNodeChange()">
        <ng-template pTemplate="header">
          <tr>
            <th>
              {{ trans.trans.name.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.name"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode">
            <td>
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <span class="treetable-text">
                {{ rowData.name }}
                <span *ngIf="rowData.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.description" matTooltipPosition="above">
                  info
                </span>
              </span>
            </td>
            <td *ngIf="can_edit">
              <span *ngIf="rowData.is_leaf" class="pull-right clickable" style="margin-right: 10px">
                <app-are-you-sure
                  [matTooltip]="trans.trans.delete.value"
                  matTooltipPosition="above"
                  [message]="trans.trans.areYouSure.value"
                  text=""
                  icon="fieldset_icon fa fa-trash"
                  (callbackYes)="deleteSituation(rowData.id)"
                ></app-are-you-sure>
              </span>
              <span *ngIf="rowData.is_leaf" [matTooltip]="trans.trans.edit.value" (click)="showEditView(rowData)" class="pull-right clickable" style="margin-right: 10px"
                ><i class="fa fa-pencil"></i
              ></span>
            </td>
            <td *ngIf="!can_edit && rowData.is_leaf">
              <span><i (click)="showSituationView(rowData)" class="fa fa-eye clickable pull-right" matTooltip="View" matTooltipPosition="above"></i></span>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
</div>

<div class="col-md-12" *ngIf="toggles.showSituationForm || toggles.showView">
  <div class="clickable a-link-color">
    <span (click)="toggleShowList()"> <i class="fa fa-angle-left marg-h" title="Back to the list " aria-hidden="true "></i>{{ trans.trans.back.value }} </span>
  </div>

  <app-r-detail-situation *ngIf="!can_edit && toggles.showView" [situationView]="situation" [solutionId]="solutionId"></app-r-detail-situation>
  <app-ae-detail-situation
    [default_term]="default_term"
    [can_edit]="can_edit"
    *ngIf="can_edit && toggles.showSituationForm"
    [modeType]="mode"
    [situationEdit]="situation"
    [solutionId]="solutionId"
    (backCallback)="toggleShowList()"
  ></app-ae-detail-situation>
</div>

<!-- <button (click)="refresh()">Refresh</button> -->
