import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';

import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';

import { MatTooltipModule } from '@angular/material/tooltip';

import { ModelScalerDefaultsComponent } from './model-scaler-defaults.component';
import { ModelScalerDefaultsTranslation } from './model-scaler-defaults.translation';

@NgModule({
	declarations: [
        ModelScalerDefaultsComponent,
	],
    imports: [
        CommonModule,
        FormsModule,
        EditTranslationsModule,
        ProgressBarModule,
        AreYouSureModule,
        MatTooltipModule,
        MultiSelectModule,
        InputNumberModule,
    ],
	providers: [
		TranslationsV2Service,
        ModelScalerDefaultsTranslation,
	],
	exports: [ModelScalerDefaultsComponent]
})
export class ModelScalerDefaultsModule { }
