import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: {[x: string]: string} = {
    component: 'container',
    display_name: 'Models Section V2',
    description: 'Container for all model details'
  };

  public trans: any = {
    model_support: 'Models Support',
    note: 'Use the below sections to help build and support your model.',
    situations: 'Situations',
    pain_point: 'Pain Points',
    capabilities: 'Capabilities',
    operational_obs: 'Operational Objectives',
    func_obs: 'Functional Objectives',
  };
}
