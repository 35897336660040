<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ trans.trans.addACompany.value }}
  </div>
  <ng-container *ngIf="!feature115; else simplified">
    <div class="selection-wrapper">
      <p-selectButton
        [disabled]="(currentPage === '1' && basicForm.invalid) || (currentPage === '2' && addtionalInfoForm.invalid)"
        [(ngModel)]="currentPage"
        [options]="pageOptions"
        styleClass="keywest_select_button"
      >
        <ng-template let-item>
          <!-- number -->
          <span class="circle light-l">
            {{ item.value }}
          </span>
          <!-- label -->
          <span class="label light-h">
            {{ item.label }}
            <span *ngIf="item.label === trans.trans.addtionalInfo.value" class="light-l">({{ trans.trans.optional.value }})</span>
          </span>
        </ng-template>
      </p-selectButton>
      
    </div>
    <!-- Page 1 -->
    <div *ngIf="currentPage === '1'" class="page">
      <form [formGroup]="basicForm">
        <!-- Name -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.name.value }}</label>
          <input class="form-input value-execution-add-a-new-value-proposition-add-a-new-company-name-input" type="text" pInputText formControlName="name" data-heap="value-execution-add-a-new-value-proposition-add-a-new-company-name-input"/>
          <small class="form-error" *ngIf="name.invalid && name.touched">{{ trans.trans.nameRequired.value }}</small>
        </div>
        <!-- Industry Sector -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.industrySector.value }}</label>
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [placeholder]="trans.trans.selectOne.value"
            formControlName="industrySector"
            [options]="industrySectors$ | async"
            name="sector"
            optionLabel="name"
            class="keywest_dropdown"
          ></p-dropdown>
          <small class="form-error" *ngIf="industrySector.invalid && industrySector.touched">{{ trans.trans.industrySectorRequired.value }}</small>
        </div>
        <!-- Industry -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.industry.value }}</label>
          <p-dropdown
            appendTo="body"
            [placeholder]="trans.trans.selectOne.value"
            formControlName="industry"
            [options]="industries"
            name="industry"
            optionLabel="name"
            class="keywest_dropdown"
          ></p-dropdown>
          <small class="form-error" *ngIf="industry.invalid && industry.touched">{{ trans.trans.industryRequired.value }}</small>
        </div>
        <!-- Country -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.country.value }}</label>
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [placeholder]="trans.trans.selectOne.value"
            formControlName="country"
            [options]="countries$ | async"
            name="country"
            optionLabel="name"
            class="keywest_dropdown"
          >
          </p-dropdown>
          <small class="form-error" *ngIf="country.invalid && country.touched">{{ trans.trans.countryRequired.value }}</small>
        </div>
        <!-- Revenue in Millions -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.revenueInMillions.value }}</label>
          <input class="form-input" type="number" pInputText formControlName="revenueInMillions" />
          <small class="form-error" *ngIf="revenueInMillions.invalid && revenueInMillions.touched">{{ trans.trans.revenueRequired.value }}</small>
        </div>
        <!-- Add Logo -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.addLogo.value }}</label>
          <app-dl-dragdrop
            [canEdit]="true"
            [title]="trans.trans.clickToDrag.value"
            [maxSizeKB]="33554.432"
            (callback)="files = $event"
            [useDark]="true"
            [multiple]="false"
            [files]="files.length > 0 ? files : []"
            (callbackInvalidSize)="showInvalidSize($event)"
            (callbackInvalidType)="showInvalidType($event)"
          >
          </app-dl-dragdrop>
        </div>
        <!-- Buttons -->
        <div class="button-wrapper">
          <button class="btn btn_customizable pull-right" (click)="goNext()" [disabled]="basicForm.invalid">
            {{ trans.trans.next.value }}
          </button>
          <button class="btn btn_secondary pull-right cancel-button" (click)="onCancel()">{{ trans.trans.cancel.value }}</button>
        </div>
      </form>
    </div>
    <!-- Page 2 -->
    <div *ngIf="currentPage === '2'" class="page">
      <form [formGroup]="addtionalInfoForm">
        <!-- Description -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.description.value }}</label>
          <textarea rows="5" class="form-text-area" pInputTextarea formControlName="description"></textarea>
        </div>
        <!-- Url -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.url.value }}</label>
          <input class="form-input" type="text" pInputText formControlName="url" />
          <small class="form-error" *ngIf="url.invalid && url.touched">{{ trans.trans.wrongUrlFormat.value }} </small>
        </div>
        <!-- Employees -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.employees.value }}</label>
          <input class="form-input" type="number" step="1" pInputText formControlName="employees" />
          <small class="form-error" *ngIf="employees.invalid && employees.touched">{{ trans.trans.noDecimal.value }} </small>
        </div>
        <!-- Industry -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.address.value }}</label>
          <input class="form-input" type="text" pInputText formControlName="address" />
        </div>
        <!-- Address -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.city.value }}</label>
          <input class="form-input" type="text" pInputText formControlName="city" />
        </div>
        <!-- State -->
        <div *ngIf="states" class="form-group">
          <label class="form-label">{{ trans.trans.state.value }}</label>
          <p-dropdown
            appendTo="body"
            [filter]="true"
            [placeholder]="trans.trans.selectOne.value"
            formControlName="state"
            [options]="states"
            name="state"
            optionLabel="name"
            class="keywest_dropdown"
          >
          </p-dropdown>
        </div>
        <!-- postalCode -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.postalCode.value }}</label>
          <input class="form-input" type="number" step="1" pInputText formControlName="postalCode" />
          <small class="form-error" *ngIf="postalCode.invalid && postalCode.touched">{{ trans.trans.noDecimal.value }} </small>
        </div>
        <!-- Buttons -->
        <div class="button-wrapper">
          <button class="btn btn_customizable pull-right" (click)="goNext()" [disabled]="addtionalInfoForm.invalid">
            {{ trans.trans.next.value }}
          </button>
          <button class="btn btn_secondary pull-right cancel-button" (click)="goBack()" [disabled]="addtionalInfoForm.invalid">{{ trans.trans.back.value }}</button>
        </div>
      </form>
    </div>
    <!-- Page 3 -->
    <div *ngIf="currentPage === '3'" class="page">
      <!-- image and name -->
      <div class="row top-row" *ngIf="files.length">
        <div class="col-xs-3 image-col">
          <img class="company-logo" [src]="files[0].path" />
        </div>
        <div class="col-xs-8 desc-col">
          <div class="row title-row">
            <span class="name-title">
              {{ name.value }}
            </span>
          </div>
          <div class="row-wrap">
            <span class="wrap">{{ description.value }}</span>
          </div>
        </div>
      </div>
      <div class="row no-image-title" *ngIf="!files.length">
        <span class="name-title">
          {{ name.value }}
        </span>
      </div>
      <div class="row no-image-description" *ngIf="!files.length">
        <span>{{ description.value }}</span>
      </div>
      <!-- company info wrapper -->
      <div class="company-info-wrapper">
        <div class="company-info-container">
          <div class="row data-row">
            <div class="col-xs-4 company-info-label">
              {{ trans.trans.company.value }}
            </div>
            <div class="col-xs-8 company-info-value">
              {{ name.value }}
            </div>
          </div>
          <div class="row data-row">
            <div class="col-xs-4 company-info-label">
              {{ trans.trans.url.value }}
            </div>
            <div class="col-xs-8 company-info-value">
              {{ url.value && url.value !== '' ? url.value : '-- --' }}
            </div>
          </div>
          <div class="row data-row">
            <div class="col-xs-4 company-info-label">
              {{ trans.trans.accountId.value }}
            </div>
            <div class="col-xs-8 company-info-value">-- --</div>
          </div>
          <div class="row data-row">
            <div class="col-xs-4 company-info-label">
              {{ trans.trans.city.value }}
            </div>
            <div class="col-xs-8 company-info-value">
              {{ city.value && city.value !== '' ? city.value : '-- --' }}
            </div>
          </div>
          <div class="row data-row">
            <div class="col-xs-4 company-info-label">
              {{ trans.trans.state.value }}
            </div>
            <div class="col-xs-8 company-info-value">
              {{ state.value.name && state.value.name !== '' ? state.value.name : '-- --' }}
            </div>
          </div>
          <div class="row">
            <div class="col-xs-4 company-info-label">
              {{ trans.trans.country.value }}
            </div>
            <div class="col-xs-8 company-info-value">
              {{ country.value.name && country.value.name !== '' ? country.value.name : '-- --' }}
            </div>
          </div>
        </div>
      </div>
      <!-- Sector -->
      <div class="sector-card-wrapper">
        <div class="sector-title">
          {{ trans.trans.sector.value }}
        </div>
        <div class="sector-value">
          {{ industry.value.name }}
        </div>
      </div>
      <!-- revenue and employees -->
      <div class="row sector-card-wrapper no-ml">
        <div class="col-xs-4 no-padding">
          <div class="sector-title">
            {{ trans.trans.revenue.value }}
          </div>
          <div class="sector-value lg-text">${{ revenueInMillions.value }}M</div>
        </div>
        <div class="col-xs-4 no-padding card-left-border">
          <div class="sector-title">
            {{ trans.trans.employees.value }}
          </div>
          <div class="sector-value lg-text">
            {{ employees.value ? employees.value : '-- --' }}
          </div>
        </div>
      </div>
      <!-- buttons -->
      <div class="button-wrapper">
        <button class="btn btn_customizable pull-right value-execution-add-a-new-value-proposition-add-a-new-company-name-input-add-company-button" (click)="onSubmit()" [disabled]="basicForm.invalid || addtionalInfoForm.invalid" data-heap="value-execution-add-a-new-value-proposition-add-a-new-company-name-input-add-company-button">
          {{ trans.trans.addCompany.value }}
        </button>
        <button class="btn btn_secondary pull-right cancel-button" (click)="goBack()">{{ trans.trans.back.value }}</button>
      </div>
    </div>
  </ng-container>
  <ng-template #simplified>
    <div class="simplified-form">
      <form [formGroup]="basicForm">
        <!-- Name -->
        <div class="form-group">
          <label class="form-label">{{ trans.trans.name.value }}</label>
          <input class="form-input" type="text" pInputText formControlName="name" />
          <small class="form-error" *ngIf="name.invalid && name.touched">{{ trans.trans.nameRequired.value }}</small>
        </div>
      </form>
        <button class="btn btn_customizable pull-right" (click)="onSubmit()" [disabled]="basicForm.invalid || addtionalInfoForm.invalid">
          {{ trans.trans.addCompany.value }}
        </button>
    </div>
  </ng-template>
</div>
