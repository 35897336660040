<div class="add_company">
  <div class="panel panel-white">
    <div [hidden]="modalCreate" class="panel-heading">
      <h3 class="panel-title">{{ trans.add_company.add_a_company | translate }}</h3>
    </div>
    <div class="panel-body">
      <form name="addCompanyForm" class="col-md-8" #addCompanyForm="ngForm" (ngSubmit)="addCompany()">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{ 'has-error': addCompanyForm.controls.name?.invalid && addCompanyForm.controls.name?.touched }">
              <label class="labelsRequired">{{ trans.add_company.name | translate }}</label>
              <input type="text" class="form-control" id="name" [(ngModel)]="company.name" name="name" required />
              <i
                class="fa fa-search name-search"
                matTooltip="{{ trans.contextual_help.vp_dashboard.rename_value_prop | translate }}"
                matTooltipPosition="left"
                [ngbPopover]="popContent"
                [popoverTitle]="popTitle"
                (click)="searchWithScraper()"
              ></i>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': addCompanyForm.controls.industryCategory?.invalid && addCompanyForm.controls.industryCategory?.touched }">
              <label class="labelsRequired">{{ trans.add_company.industry_sector | translate }}</label>
              <select (change)="getIndustryBySectors(company.industry_sector_id)" class="form-control" name="industryCategory" required [(ngModel)]="company.industry_sector_id">
                <option value="" disabled="disabled">{{ trans.add_company.select_industry_sector | translate }}</option>
                <option *ngFor="let sector of industrySectors" [id]="sector.id" [ngValue]="sector.id">
                  {{ sector.name }}
                </option>
              </select>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': addCompanyForm.controls.industry?.invalid && addCompanyForm.controls.industry?.touched }">
              <label class="labelsRequired">
                {{ trans.add_company.industry | translate }}
              </label>
              <img class="industryloader loader" src="{{ fullImagePath }}" *ngIf="industriesLoader" />

              <select [disabled]="industriesShow" class="form-control" name="industry" required [(ngModel)]="company.industry_id">
                <option value="" disabled="disabled">{{ trans.add_company.select_industry | translate }}</option>
                <option *ngFor="let industry of industries" [id]="industry.id" [ngValue]="industry.id">
                  {{ industry.name }}
                </option>
              </select>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': addCompanyForm.controls.revenue?.invalid && addCompanyForm.controls.revenue?.touched }">
              <label class="labelsRequired">{{ trans.add_company.revenue_millions | translate }}</label>
              <input type="number" class="form-control" id="revenue" [(ngModel)]="company.net_sales" name="revenue" required />
            </div>
            <div class="form-group">
              <label>{{ trans.add_company.employees | translate }}</label>
              <input type="number" class="form-control" id="employees" [(ngModel)]="company.employees" name="employees"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{ 'has-error': addCompanyForm.controls.country?.invalid && addCompanyForm.controls.country?.touched }">
              <label class="labelsRequired">{{ trans.add_company.country | translate }}</label>
              <select class="form-control" name="country" required [(ngModel)]="countryDetails" (change)="getStatesByCountries(countryDetails)">
                <option value="" disabled="disabled">{{ trans.add_company.select_country | translate }}</option>
                <option *ngFor="let country of countries" [id]="country.id" [ngValue]="country">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ trans.add_company.address | translate }}</label>
              <input type="text" class="form-control" id="address" [(ngModel)]="company.address1" name="address"/>
            </div>
            <div class="form-group">
              <label>{{ trans.add_company.city | translate }}</label>
              <input type="text" class="form-control" id="city" [(ngModel)]="company.city" name="city"/>
            </div>
            <div class="form-group">
              <label for="name">{{ trans.add_company.state | translate }}</label>
              <select [disabled]="statesLoader" class="form-control" name="state" [(ngModel)]="company.state">
                <option value="" disabled="disabled">{{ trans.add_company.select_state | translate }}</option>
                <option *ngFor="let state of states" [id]="state.id" [ngValue]="state.abbreviation">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ trans.add_company.postal_code | translate }}</label>
              <input type="text" class="form-control" id="zipcode" [(ngModel)]="company.zip" name="zipcode"/>
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="addCompanyLoader" class="text-center company-loader">
        <img class="loader" [src]="fullImagePath" />
      </div>
      <div class="clearfix"></div>
      <div class="pull-right">
        <label class="labelsRequiredInfo">{{ trans.add_company.is_required | translate }}</label>
      </div>
    </div>
  </div>
  <div [hidden]="modalCreate" class="row">
    <div class="pull-right">
      <button type="submit" [disabled]="addCompanyForm.invalid" class="btn btn-success" (click)="addCompany()">
        <span class="glyphicon glyphicon-floppy-disk" aria-hidden="true"> {{ trans.add_company.save | translate }}</span></button
      >&nbsp;&nbsp;
    </div>
  </div>
</div>
<ng-template #popContent
  >{{ trans.add_company.hello | translate }} <b>{{ name }}</b
  >!</ng-template
>
<ng-template #popTitle
  >{{ trans.add_company.fancy | translate }} <b>{{ content!! }}</b></ng-template
>
