<div class="modal-header">
  <h4 class="modal-title">{{ trans.add_project_modal.add_project | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="toggleAddView">
    <form name="addNewProjectForm" #addNewProjectForm="ngForm">
      <div class="clearfix">
        <h4>{{ trans.add_project_modal.parent | translate }}</h4>
        <div class="parent col-md-8">
          <div class="form-group" *ngIf="existingParent">
            <label>{{ trans.add_project_modal.parent | translate }}</label>
            <p-dropdown appendTo="body"
              class="keywest_dropdown"
              [options]="accountProjects"
              optionLabel="name"
              name="accountProjectParent"
              [(ngModel)]="accountProjectParent"
              placeholder="Select a Project"
              [filter]="true"
            ></p-dropdown>
            <img *ngIf="accountProjectsLoader" class="actionLoader m-l-xxs" src="{{ fullImagePath }}" />
          </div>

          <div class="form-group" *ngIf="!existingParent">
            <div class="form-group clearfix">
              <label class="labelsRequired col-md-4 no-p">{{ trans.add_project_modal.project_name | translate }}</label>
              <div class="col-md-8">
                <input type="text" name="parentName" [(ngModel)]="parentName" class="form-control" placeholder="Project name" required />
              </div>
            </div>
            <div class="form-group clearfix">
              <label class="col-md-4 no-p">{{ trans.add_project_modal.description | translate }}</label>
              <div class="col-md-8">
                <input type="text" name="parentDescription" [(ngModel)]="parentDescription" class="form-control" placeholder="Description" />
              </div>
            </div>
          </div>
        </div>

        <div class="centerVertically addChild-btn col-md-3">
          <button type="button" class="btn btn-primary" (click)="addChild()">
            {{ trans.add_project_modal.add_child | translate }}
          </button>
        </div>

        <div class="col-md-12" *ngIf="childrens.length > 0">
          <h4>{{ trans.add_project_modal.children | translate }}</h4>
          <div class="m-b-md clearfix" *ngFor="let children of childrens; let i = index">
            <div class="children col-md-10">
              <div class="form-group clearfix">
                <label class="labelsRequired col-md-4 no-p">{{ trans.add_project_modal.project_name | translate }}</label>
                <div class="col-md-8">
                  <input type="text" name="childrenName_{{ i }}" [(ngModel)]="children.name" class="form-control" placeholder="Project name" required />
                </div>
              </div>
              <div class="form-group clearfix">
                <label class="col-md-4 no-p">{{ trans.add_project_modal.description | translate }}</label>
                <div class="col-md-8">
                  <input type="text" name="childrenDescription_{{ i }}" [(ngModel)]="children.description" class="form-control" placeholder="Description" />
                </div>
              </div>
            </div>
            <div class="centerVertically actionContainer col-md-2">
              <span class="icon-close" (click)="removeChild(i)"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="m-t-md">
        <button type="button" class="btn btn-primary m-t-xs m-b-xs" (click)="addNewProject()" [disabled]="addNewProjectForm.invalid">
          {{ trans.General.add | translate }}
        </button>

        <button type="button" class="btn btn-default m-t-xs m-b-xs" (click)="toggleAddViews()">Cancel</button>

        <img *ngIf="newProjectLoader" class="actionLoader m-l-xxs" src="{{ fullImagePath }}" />
      </div>
    </form>
  </div>

  <div *ngIf="!toggleAddView">
    <form name="addProjectForm" #addProjectForm="ngForm">
      <div class="row">
        <div class="col-md-7">
          <div class="form-group" *ngIf="accountProject && accountProject.parent_id > 0">
            <label class="labelsRequired">{{ trans.add_project_modal.project_name | translate }}</label>
          </div>

          <div class="form-group" [ngClass]="{ 'has-error': addProjectForm.controls.accountProject?.invalid && addProjectForm.controls.accountProject?.touched }">
            <label class="labelsRequired">{{ trans.add_project_modal.project_name | translate }}</label>
            <p-dropdown appendTo="body"
              class="keywest_dropdown"
              [options]="accountProjectsAllLevel"
              optionLabel="name"
              name="accountProject"
              [(ngModel)]="accountProject"
              [filter]="true"
              placeholder="Select a Project"
            ></p-dropdown>
            <img *ngIf="accountProjectsLoader" class="actionLoader m-l-xxs" src="{{ fullImagePath }}" />
          </div>

          <div class="form-group clearfix">
            <label for="isActive">
              <input type="checkbox" id="isActive" name="isActive" [(ngModel)]="isActive" />
              {{ trans.add_project_modal.add_to_list_by_default | translate }}
            </label>
          </div>
        </div>

        <div class="col-md-4">
          <button type="button" class="btn btn-primary m-t-md m-b-xs" (click)="toggleAddViews()">{{ trans.add_project_modal.add_new | translate }}</button>
          <button type="button" class="btn btn-primary m-t-md m-b-xs" (click)="toggleAddViews('parent')">{{ trans.add_project_modal.add_to_existing_parent | translate }}</button>
        </div>
      </div>

      <button type="button" class="btn btn-primary m-t-xs m-b-xs" (click)="saveProject()" [disabled]="addProjectForm.invalid">
        {{ trans.add_project_modal.save | translate }}
      </button>
    </form>
  </div>
</div>
