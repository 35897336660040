import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BenefitsMiniComponent } from './mini.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BenefitDetailsModule } from '../detail/benefit-detail.module';
import { TableModule } from 'primeng/table';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsMiniTranslations } from './mini.translation';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressBarModule } from 'primeng/progressbar';
import { DLSideBarModule } from '@shared_components/primeng_sidebar/sidebar.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ModelAEBenefits } from 'app/value-mapv2/solution-detail/benefits/ae_benefits/ae_benefits.module';
import { AddBenefitsModule } from 'app/value-prop/value-prop-dashboard/add-benefits-component/add-benefits-component.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HeightCacheModule } from '@shared/height-cache/height-cache.module';

@NgModule({
  imports: [
    ProgressBarModule,
    SidebarModule,
    TableModule,
    BenefitDetailsModule,
    CommonModule,
    MatTooltipModule,
    FormsModule,
    NgbModule,
    EditTranslationsModule,
    ApplicationPipesModule,
    DLSideBarModule,
    AreYouSureModule,
    ModelAEBenefits,
    AddBenefitsModule,
    HeightCacheModule,
  ],
  declarations: [BenefitsMiniComponent],
  providers: [TranslationsV2Service, BenefitsMiniTranslations],
  exports: [BenefitsMiniComponent],
})
export class BenefitsMiniV2Module {}
