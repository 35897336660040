import { Injectable } from '@angular/core';

@Injectable()
export class IssuesTranslations {
  public config: any = {
    component: 'issuesComponent',
    display_name: 'Issues',
    description: 'Submit an issue or concern',
  };

  public trans: any = {
    errorChooseSection: 'Please choose a section',
    errorEmailValue: 'Please enter the Email Value',
    errorIssueOrFeedback: 'Please enter your issue / feedback',
    issuesOrFeedback: 'Issues/ Feedback',
    screenshots: 'Screenshots',
    section: 'Section',
    Email: 'Details',
    email: 'Email',
    choose_one: 'Choose One',
    save: 'Save',
    model: 'Models',
    profile: 'Profile',
    reach: 'Hypotheses',
    research: 'Insights',
    retain: 'Realizations',
    revise: 'Cases',
    description: 'Description',
    name: 'Name',
    dropClickUpload: 'Drop files here or click to upload',
    onlyImagesPDFsUploaded: 'Only images and PDFs can be uploaded',
  };
}
