import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FeatureOpportunityIdComponent),
  multi: true,
};

@Component({
  selector: 'app-feature-opportunity-id',
  templateUrl: './opportunity_id.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FeatureOpportunityIdComponent implements ControlValueAccessor, OnInit {
  @Input() model: any;

  featuresOptions: string;
  featuresopt: string[];
  showField = false;
  required = false;
  private innerValue = '';

  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  constructor(private translateService: TranslateService, public trans: Translations) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit(): void {
    this.featuresOptions = sessionStorage.getItem('features');
    this.featuresopt = this.featuresOptions.split(',');
    if (this.featuresopt.indexOf('22') >= 0) {
      this.showField = true;
      this.required = true;
    } else {
      this.showField = false;
    }
  }

  get value(): string {
    return this.innerValue;
  }

  set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
