<div class="row" *ngIf="style2022$ | async">
    <div class="col-md-4 mr-auto breadcrumb-container">
      <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
    </div>
  </div>
  
  <div class="row" *ngIf="!(style2022$ | async)">
    <div class="col-md-10 old-breadcrumb">
      <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.back_to_profile.value }}</a>
    </div>
  </div>
  <div class="models_panel" style="margin-bottom: 20px">
    <div class="models_page_header_container_small">
      <div class="keywest_header_small">
        <div class="row">
          <p-tabView>
              <p-tabPanel [header]="trans.trans.bulkUpdate.value">
                <p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

            <!-- Complete / Pending -->
            <ul class="keywest_breadcrumb flex-jcc">
            <li (click)="swapState(0)" [ngClass]="{ current: currentType == 0 }">
                <a [ngClass]="{ current: currentType == 0 }">
                <span [ngClass]="{ text_current: currentType == 0 }">{{ trans.trans.complete.value }} ({{ complete?.length }})</span>
                </a>
            </li>
            <li (click)="swapState(1)" [ngClass]="{ current: currentType == 1 }">
                <a [ngClass]="{ current: currentType == 1 }">
                <span [ngClass]="{ text_current: currentType == 1 }">{{ trans.trans.pending.value }} ({{ pending?.length }})</span>
                </a>
            </li>
            </ul>

            <div class="row search-container">
            <div class="col-xs-4">
                <div *ngIf="currentType === 0 || currentType === 1" class="input-group keywest_search">
                <span class="input-group-addon"><i class="fa fa-search"></i></span>
                <input
                    [(ngModel)]="strSearch"
                    (keyup)="tableBulkUpdates.filter($any($event).target.value, 'full_name', 'contains')"
                    type="text"
                    [placeholder]="trans.trans.search.value"
                    class="form-control"
                />
                <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableBulkUpdates.filter('', 'full_name', 'contains')" class="input-group-addon clickable"
                    ><i class="fa fa-times"></i
                ></span>
                </div>
            </div>
            <div class="col-xs-8">
                <a (click)="funcInitBulkUpdate()" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i>{{ trans.trans.new_update.value }}</a>
                <a (click)="funcGetBulkUpdates()" class="pull-right"><i class="fa fa-refresh refresh-icon"></i></a>
            </div>
            </div>

            <!-- Bulk update data table -->
            <div class="row">
            <div class="col-xs-12">
                <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
                <p-table
                #tableBulkUpdates
                [value]="currentType === 0 ? complete : pending"
                [paginator]="true"
                [rows]="pageLimit"
                [rowsPerPageOptions]="[10,25,50]"
                styleClass="keywest_table"
                tableStyleClass="keywest_table"
                >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                    <th [pSortableColumn]="'full_name'">
                        {{ trans.trans.username.value }}
                        <p-sortIcon [field]="'full_name'"></p-sortIcon>
                        <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.username"
                        (callback)="getTranslations()"
                        ></app-editTranslation>
                    </th>
                    <th>
                        {{ trans.trans.initDate.value }}
                        <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.initDate"
                        (callback)="getTranslations()"
                        ></app-editTranslation>
                    </th>
                    <th>
                        {{ trans.trans.startDate.value }}
                        <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.startDate"
                        (callback)="getTranslations()"
                        ></app-editTranslation>
                    </th>
                    <th>
                        {{ trans.trans.endDate.value }}
                        <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.endDate"
                        (callback)="getTranslations()"
                        ></app-editTranslation>
                    </th>
                    <th>
                        {{ trans.trans.recordsCount.value }}
                        <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.recordsCount"
                        (callback)="getTranslations()"
                        ></app-editTranslation>
                    </th>
                    <th>
                        {{ trans.trans.recordsFailedCount.value }}
                        <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.recordsFailedCount"
                        (callback)="getTranslations()"
                        ></app-editTranslation>
                    </th>
                    <th *ngIf="currentType === 0">
                        {{ trans.trans.status.value }}
                        <app-editTranslation
                        *ngIf="showTranslate"
                        [component]="trans.config.component"
                        [isTranslationV2]="true"
                        [transObj]="trans.trans.status"
                        (callback)="getTranslations()"
                        ></app-editTranslation>
                    </th>
                    <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-elem>
                    <tr>
                    <td>
                        <a>{{ elem.full_name }}</a>
                    </td>

                    <td>{{ elem.date }}</td>
                    <td>
                        {{ elem.response.bulk_start_time }}
                    </td>
                    <td>{{ elem.response.bulk_finish_time }}</td>
                    <td>{{ elem.response.recordsCount }}</td>
                    <td>{{ elem.response.recordsFailedCount }}</td>
                    <td>{{ elem.response.status }}</td>
                    <td>
                    </td>
                    </tr>
                </ng-template>
                </p-table>
                <div class="col-xs-12">
                </div>
            </div>
            </div>
        </p-tabPanel>
    </p-tabView>
  </div>
</div>
</div>
</div>