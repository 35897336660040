import { Injectable } from '@angular/core';

@Injectable()
export class ValuePrpDashboardTranslations {
  public config: any = {
    component: 'vpDash',
    display_name: 'Value Prop Dashboard',
    description: 'Dashboard for value prop',
  };

  public trans: any = {
    actions: 'Actions',
    lock: 'Lock Value Prop',
    unlock: 'Unlock Value Prop',
    share: 'Share',
    share_this_value_prop: 'Click to share this Value Prop',
    clone: 'Clone',
    clone_this_value_prop: 'Clone this Value Prop',
    refresh: 'Refresh Data',
    hide_help: 'Hide Help',
    show_help: 'Show Help',
    edit_translations: 'Edit Translations',
    download_assets: 'Download Assets',
    take_a_tour: 'Take a tour',
    discovery_questions: 'Discovery Questions',
    edit_status: 'Click to edit status',
    translate_menus: 'Translate Menus',
    sidebar_account: 'Account',
    sidebar_add_benefit: 'Add Benefit',
    sidebar_advanced: 'Advanced',
    sidebar_all_assumptions: 'All Assumptions',
    sidebar_assets: 'Assets',
    sidebar_benefits: 'Benefits',
    sidebar_case_studies: 'Case Studies',
    sidebar_cashflow: 'Cashflow',
    sidebar_collateral: 'Collateral',
    sidebar_costs: 'Costs',
    view_costs: 'View Costs',
    deployment_schedule: 'Deployment Schedule',
    sidebar_discovery: 'Discovery',
    sidebar_initiatives: 'Initiatives',
    sidebar_key_assumptions: 'Key Assumptions',
    key_figures_and_roi: 'Key Figures & ROI',
    sidebar_logs: 'Logs',
    sidebar_mission_statement: 'Mission Statement',
    sidebar_models: 'Models',
    sidebar_notes: 'Notes',
    problem: 'Problem',
    questions: 'Questions',
    sidebar_roi: 'ROI',
    roi_full: 'Key Figures & ROI',
    sidebar_scalers: 'Scalers',
    sidebar_scenario: 'Scenario',
    sidebar_scorecard: 'Scorecard',
    sidebar_share: 'Share',
    sidebar_situations: 'Situations',
    solution: 'Solution',
    sidebar_supporting_materials: 'Supporting Materials',
    tco: 'TCO',
    this_value_prop_is_locked: 'This Value Prop is Locked',
    sidebar_view_benefits: 'View Benefits',
    edit_and_support: 'Edit & Support Your Value',
    charts_tables: 'Charts, Tables, and Info',
    switch_full_width: 'Switch to New, Full Width Dashboard',
    switch_old_width: 'Switch to Old, 2 Column Dashboard',
    full_width: 'Full Width',
    old_width: 'Old Dashboard',
    save: 'Save',
    cancel: 'Cancel',
    ch_edit_translations: 'Edit Translations',
    ch_refresh_data: 'Refresh Data',
    ch_hide_help: 'Hide Help',
    ch_show_help: 'Show Help',
    ch_download_assets: 'Download Assets',
    ch_download: 'Download',
    account: 'Account',
    add_benefit: 'Add Benefit',
    advanced: 'Advanced',
    all_assumptions: 'All Assumptions',
    assets: 'Assets',
    benefits: 'Benefits',
    case_studies: 'Case Studies',
    cashflow: 'Cashflow',
    collateral: 'Collateral',
    costs: 'Costs',
    discovery: 'Discovery',
    initiatives: 'Initiatives',
    key_assumptions: 'Key Assumptions',
    logs: 'Logs',
    mission_statement: 'Mission Statement',
    models: 'Models',
    notes: 'Notes',
    roi: 'ROI',
    scalers: 'Scalers',
    scenario: 'Scenario',
    scorecard: 'Scorecard',
    situations: 'Situations',
    supporting_materials: 'Supporting Materials',
    view_benefits: 'View Benefits',
    download: 'Download',
    switchToRepSimple: 'Switch to Rep Simple',
    step1title: 'Welcome',
    step1text: "Let's take a quick walk through of the new changes of Dashboard V2",
    step2title: 'Switching Dashboards',
    step2text: 'Here, we can switch between the Old Dashboard and the New Dashboard',
    step3title: 'Expanding / Collapsing Panels',
    step3text: 'We can expand and collapse each of these panels to get more screen realisatate',
    step4title: 'Breadcrumb',
    step4text: "The title of the panel is also a breadcrumb, so we know which menu item we're on",
    step5title: 'Menu Bar',
    step5text: `All of the tabs from the old dashboard have been consolidated into a new menu bar.
         Mouse over a menu with a down arrow, or simply click the menu item to go to that page`,
    step6title: 'Finished',
    step6text: 'Well that is about it, thank you for taking the tour!',
    ch_rename_value_prop: 'Ability to rename the value Proposition',
    add_group: 'AddGroup',
    download_pdf: 'Download PDF',
  };
}
