import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Factor, FactorGroup } from '@data/services/valueposition/models/factor-group.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Items } from '../interfaces/items.interface';
import { DefaultTranslations } from './r_factors.translation';

@Component({
  selector: 'app-r-factors',
  templateUrl: './r_factors.component.html',
})
export class RFactorsFormComponent implements OnInit, OnDestroy {
  @Input() mode: string;
  @Input() title_factor = '';
  @Output() callback = new EventEmitter();

  ngUnsubscribe$ = new Subject();
  unitTypes: Items = [];
  companyField: Items = [];
  account_id: string;
  factorGroups: FactorGroup[] = [];

  @ViewChild('formAddEditFactor') formAddEditFactor: NgForm;

  @Input() formObjFactor: Factor = {
    id: '',
    name: '',
    description: '',
    unit_type_id: [],
    factor_precision: '',
    scales_by: [],
    scales_how: '',
    is_key_factor: '',
    literal_number: '',
    greater_than_zero: '',
    min: '',
    max: '',
    increment: '',
    company_field: [],
    account_factor_category_id: [],
  };

  showTranslate = false;

  constructor(
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service
  ) { }

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.title_factor = this.trans.trans.view_factor.value;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }
  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
