import { Injectable } from '@angular/core';

@Injectable ()

export class BenefitsSimpleTranslations {

    public config: any = {
        component: 'repSimpleBenefits',
        display_name: 'Rep Simple Benefits',
        description: 'Benefits in rep simple'
    };

    public trans: any = {
        active: 'Active',
        na: 'N/A',
        soft: 'Considered Soft',
        status: 'Status',
        benefits: 'Benefits',
        financial: 'Financial',
        nonFinancial: 'Non-Financial',
        choose: 'Choose',
        benefits_text: `The benefit calculations are aligned to the Value Messaging framework and
        this step lists the benefits at two levels: Functional Outcomes and Operational Outcomes.
        Use the toggle buttons to the right to enable and disable Functional Outcomes.
        Use the + buttons to expand Functional Outcomes and the Status
        buttons to disable specific Operational Outcomes (Benefits).
        Click on an Operational Outcome name to get more information.`,
        NoBenefitsFound: 'No Benefits Found',
        benefit_status: 'Change benefit status',
        ch_renameValueCategory: 'Rename the value category',
    };
}
