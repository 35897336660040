export enum MaterialIcon {
    SETTINGS = 'settings',
    PERSON = 'person',
    PEOPLE = 'people',
    WORK_OUTLINE = 'work_outline',
    WIDGETS = 'widgets',
    QUERY_STATS = 'query_stats',
    ADD_CHART = 'add_chart',
    INSIGHTS = 'insights',
    LIST_ALT = 'list_alt',
    CALCULATE = 'calculate',
    TUNE = 'tune',
    HELP_OUTLINE = 'help_outline',
    GPS_NOT_FIXED = 'gps_not_fixed',
    ASSIGNMENT = 'assignment',
    TRASLATE = 'translate',
    CURRENCY_EXCHANGE = 'currency_exchange',
    ROUTE = 'route',
}