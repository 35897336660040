import { NgModule } from '@angular/core';

import { DetailCardComponent } from './detail-card.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [DetailCardComponent],
  declarations: [DetailCardComponent],
  providers: [],
})
export class DetailCardModule {}
