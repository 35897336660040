import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { StyleService } from 'app/style.service';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-delete-menu',
  templateUrl: 'edit-delete-menu-row.component.html',
})
export class EditDeleteMenuRowComponent implements OnInit {
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  rowMenuDropdown: MenuItem[];
  style2022$: Observable<boolean>;

  constructor(private styleService: StyleService) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
  }

  funcBuildMenu(menu: Menu, event: Event): void {
    this.rowMenuDropdown = [
      {
        label: 'Edit',
        icon: 'fa fa-pencil',
        command: () => {
          this.emitEditEvent();
        },
      },
      {
        label: 'Delete',
        icon: 'fa fa-times',
        command: () => {
          this.emitDeleteEvent();
        },
      },
    ];
    menu.toggle(event);
  }

  emitEditEvent(): void {
    this.edit.emit();
  }

  emitDeleteEvent(): void {
    this.delete.emit();
  }
}
