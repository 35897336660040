import { Injectable } from '@angular/core';

@Injectable()

export class RBenefitsTranslations {

	public config: any = {
		component: 'readBenefits',
		display_name: 'Benefits View',
		description: 'View of an individual benefit'
	};

	public trans: any = {
		addBenefit: 'Add Benefit',
		editBenefit: 'Edit Benefit',
		viewBenefit: 'View Benefit',
		name: 'Name ',
		enterName: 'Enter Name',
		ch_benefitName: 'This is the name of your benefit.  Common language is "Improvement of / in etc.',
		benefitGroupBelong: 'To what Benefit Group does this benefit belong? ',
		ch_benefitGroup: 'This is the group where you want to, by default, roll up this benefit within.',
		description: 'Description ',
		ch_benefitDescription: `Describe your benefit in terms that is easy for
        a customer to understand. This will help you get agreement on its importance
         faster than a description that is uses very specific language to your organization.`,
		howImpactYourCustomer: 'How does this benefit impact your customer? ',
		ch_benefitImpact: `How does this benefit impact a customer's business?
         Some hints: Revenue can be revenue growth or protection, Cost can be cost cuts or avoidance,
          Productivity is usually reserved for benefits related to labor where you are not removing labor.`,
		isAnnualBenefit: 'Is this an annual benefit? ',
		ch_annualBenefit: `Classify the benefit as annual when -
         if you remove your solution the benefit will also go away.  Most benefit are annual and one-time
          benefits are often reserved for benefits related to implementation`,
		differentiated: 'Differentiated ',
		ch_differentiated: ` Choose yes if this benefit is something your
         solution uniquely provides or you have a competitive advantage which can be
          quantified. Choose "No" if you and your competition always provide this benefit at the same level.`,
		isDefault: 'Is this benefit N/A by default? ',
		ch_isDefault: `Choose yes if you would like this benefit to NOT
         be included by default in a business case. This is commonly used when a benefit
          is important but only in certain situations.`,
		yes: 'Yes',
		no: 'No',
		driver: 'Driver ',
		ch_driver: `If a Driver Factor is not chosen, a Driver Factor of "Not Applicable" will be assigned by default.`,
		driverFactor: 'Driver Factor ',
		ch_dirverFactor: `This is the piece within the formula which grows or shrinks based on the size
         of the customer.  Size is determined by items classified as Scale Factors.`,
		answer: 'Answer ',
		ch_driverBaselineAnswer: `Based on the size of a typical customer, enter the standard
         value for this factor here. It will be scaled in accordance with the scale
          definition for the factor.`,
		seedGrowthDecline: 'Seed Growth / Decline ? ',
		ch_seedGrowth: `Does this Driver Factor typically grow or shrink over time based on experience?`,
		improvement: 'Improvement',
		improvementFactor: 'Improvement Factor ',
		ch_improvementFactor: `This is the label for the benefit improvement your customer is expected to achieve`,
		units: 'Units ',
		ch_units: `How is this benefit measured, typically benefits are measured in time or percentage but sometimes in actual currency.`,
		ch_currentState: `What is the customers current state for this benefit.
         Typically for percentages this is going to be 100%, but for time based benefit it can range widely.`,
		currentState: 'Current State ',
		currentEffortPlaceholder: 'Enter Current Effort',
		conservative: 'Conservative ',
		ch_conservative: `The conservative improvement in this benefit the customer can expect based on all previous customer successes.`,
		probable: 'Probable ',
		ch_probable: `The typical improvement in this benefit the customer can expect based on all previous customer successes.`,
		aggressive: 'Aggressive ',
		ch_aggressive: `The aggressive improvement in this benefit the customer can expect based on all previous customer successes.`,
		financial: 'Financial ',
		ch_financial: `If a Financial Factor is not chosen, a Financial Factor of "Not Applicable" will be assigned by default.`,
		financialFactor: 'Financial Factor ',
		ch_financialFactor: `This is the piece within the formula which converts benefits measured in time or percentage into currency.`,
		hasScratchpad: `This factor has a scratchpad. Click to view or edit it.`,
		ch_baselineAnswer: `Enter the typical cost/revenue/ etc. value for this factor here.
          It will be scaled in accordance with the scale definition for the factor.`,
		optional: 'Optional',
		tcoName: 'TCO Name ',
		ch_tcoName: `This field is leveraged when the benefit is used in a TCO calculation.
          TCO typically is simply current vs. future state so terms like "Improvement of" are removed.`,
		valueStatemenet: 'Value Statement',
		ch_valueStatment: `Descriptive statement that explains the value you intend to provide.`,
		featCapabitiltiesHeader: 'Features/Capabilities ',
		featCapabitilties: 'Features And Capabilities',
		ch_featuresCapabilities: `Descriptive paragraph for what solution features
         and capabilities allow your customer to achieve this benefit.`,
		funcObj: 'Functional Objectives? ',
		ch_funcObj: `High level benefit category, can be used as an alternative
         to benefit group when you want to see your benefits grouped by a different mechanism.`,
		expenseType: 'Expense Type ',
		ch_expenseType: `Is the benefit going to improve operational or capital expenses (OPEX vs. CAPEX).`,
		benefitType: 'What benefit type is this?',
		ch_benefitType: `The type of benefit, financial = measured and quantified as currency to
         the business, environmental = measured and quantified as environmental impact, personal = measured as the
          business case for the person you are selling (think bonuses, upward mobility, etc.), strategic = measurement which may
           not be quantified in dollars and cents but are important to the business at the highest level`,
		clickSelectImage: 'Click here to select images to upload',
		acceptableFormat: 'Acceptable file formats: .jpg, .png',
		dragDropImage: `Drag and Drop metric images here or click to select images`,
		impactBusinessArea: 'Impacts Which Business Areas?',
		ch_impactBusiness: `Which lines of the customer’s business will be most impacted but this benefit?`,
		painPoints: 'Pain Points?',
		ch_painPoints: `What pain points does this benefit solve?`,
		capabilities: 'Capabilities?',
		ch_capabilities: `What capabilities (bullet points) are provided by the solution to achieve this benefit?`,
		opObjs: 'Operational Objectives?',
		ch_opObjs: `What objectives or initiatives is this benefit going to help the customer achieve?`,
		benefitPhasing: 'Benefits Phasing ',
		ch_benefitsPhasing: `What is the growth ramp for this benefit, assuming most customers do
         not achieve the full benefit within the first year.`,
		year1: 'Year 1',
		year2: 'Year 2',
		year3: 'Year 3',
		selected: 'selected',
		impactAreas: 'Impact Areas',
		painPointTitle: 'Pain Points',
		capabilitiesTitle: 'Capabilities',
		opObjTitle: 'Operational Objectives',
		debug: 'Debug',
		issues: "Issues",
		resolutions: "Resolutions"
	};
}
