import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { APIResult } from '@shared/models/result-response.model';
import { ROIData } from '@shared/models/roi-data.model';
import { PaybackData } from '@shared/models/payback-data.model';
import { NPVData } from '@shared/models/npv-data.model';
import { InactionCostData } from '@shared/models/inaction-cost-data.model';
import { VCData } from '@shared/models/vc-data.model';
import { TCOData } from '@shared/models/tco-data.model';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { Metric, UpdateValuePropDonePayload } from '@shared/models/value-prop.model';

@Injectable()
export class RepSimpleViewService {
  public refreshSimpleDashboard = new Subject<any>();

  public saveChanges = new Subject<any>();
  public navNotes = new Subject<any>();
  public navDiscoveryQuestions = new Subject<any>();
  public saveChangesCallback: BehaviorSubject<any> = new BehaviorSubject<any>(0);

  private subjectNotifyCallback = new Subject<any>();
  notifyCallback(res: any) {
    this.subjectNotifyCallback.next(res);
  }
  subscribeNotifyCallback(): Observable<any> {
    return this.subjectNotifyCallback.asObservable();
  }

  constructor(private CommonService: CommonService) {}

  public chartWithROI(vpid: string): Observable<APIResult<ROIData>> {
    let endpoint = 'valueProps/chartWithROI/' + vpid;
    return this.CommonService.getAPIService(endpoint);
  }

  public getTCOChart(vpid: string, all_metric: 0 | 1): Observable<APIResult<TCOData>> {
    let str = '';
    if (all_metric == 1) {
      str = '/includeAll/' + all_metric;
    }

    let endpoint = 'valueProps/TCOChart/' + vpid + str;
    return this.CommonService.getAPIService(endpoint);
  }

  public getPaybackChart(vpid: string): Observable<APIResult<PaybackData>> {
    let endpoint = 'valueProps/paybackChart/' + vpid;
    return this.CommonService.getAPIService(endpoint);
  }

  public getNPVChart(vpid: string): Observable<APIResult<NPVData>> {
    let endpoint = 'valueProps/NPVChart/' + vpid;
    return this.CommonService.getAPIService(endpoint);
  }

  public getCostOfInactionChart(vpid: string): Observable<APIResult<InactionCostData>> {
    let endpoint = 'valueProps/costOfInactionChart/' + vpid;
    return this.CommonService.getAPIService(endpoint);
  }

  public getVCChart(vpid: string): Observable<APIResult<VCData>> {
    let endpoint = 'valueProps/VCChart/' + vpid + `?order_flag=0`;
    return this.CommonService.getAPIService(endpoint);
  }

  public updateVPStep(vpid, payload) {
    let endpoint = 'valueProps/updateValuePropStep/' + vpid;
    return this.CommonService.putAPIService(endpoint, payload);
  }

  public getSituationsSimple(vpid) {
    let endpoint = 'valueProps/situationsSimple/' + vpid;
    return this.CommonService.getAPIService(endpoint);
  }

  public putDone(vpid: string, payload: UpdateValuePropDonePayload): Observable<APIResult<SuccessFailResponse>> {
    let endpoint = 'valueProps/updateValuePropDone/' + vpid;
    return this.CommonService.putAPIService(endpoint, payload);
  }

  getBenefitsByValueCategoryID(vpid: string, id: string | number): Observable<APIResult<{ metrics: Metric[] }>> {
    let endpoint = 'valueProps/metricsByValueCategory/' + vpid + '/valueCategoryId/' + id;
    return this.CommonService.getAPIService(endpoint);
  }
}
