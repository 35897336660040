<div class="row" *ngIf="showHelp">
  <div class="col-xs-12">
    <div class="well well-info">
      <b>{{ trans.trans.cashflow.value }}</b
      ><br />
      {{ trans.trans.ch_cashflow.value }}
    </div>
  </div>
</div>
<div>
  <div class="col-xs-12">
    <div class="table-responsive no-border">
      <table id="cashflow-simple" [ngClass]="+years === 3 ? 'table custom-table' : 'table table7 custom-table'" style="display: table; margin-bottom: 0px">
        <thead>
          <tr>
            <th style="padding-left: 15px !important">
              {{ trans.trans.cashfloww.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.cashfloww"
                (callback)="refreshTranslation()"
                key="trans.total"
              ></app-editTranslation>
            </th>
            <th class="text-right" *ngFor="let item of cols; let i = index">{{ item.col }}</th>
            <th class="text-right" style="padding-right: 15px !important">
              {{ trans.trans.total.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.total"
                (callback)="refreshTranslation()"
                key="trans.total"
              ></app-editTranslation>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="cashflow && cashflow !== null && cashflow.length > 0">
          <tr *ngFor="let cashflw of cashflow" class="grid-row">
            <td>{{ trans.trans['cashflow_' + cashflw.key].value }}</td>
            <td *ngFor="let item of cols; let i = index" class="text-right">{{ removeHyphen(getCVall(i, cashflw)) }}</td>
            <td class="text-right" [ngStyle]="{ color: colorRed(cashflw.Total_p2) }">{{ removeHyphen(cashflw.Total_p2) }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="cashflowLoader" class="col-sm-12 m-t-md text-center"></div>
      <div></div>
      <div *ngIf="(cashflow === null || cashflow.length === 0) && !cashflowLoader" class="m-t-md col-sm-12">
        <div class="searchTableContainer text-center">
          <h4>{{ trans.trans.no_cashflow_found.value }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
