<p-sidebar [(visible)]="sidebarOpen" (onHide)="onHide()" position="right" styleClass="p-sidebar-md">
  <h3>{{ trans.trans.editAssetTag.value }}</h3>
  <hr />
  <form [formGroup]="tagForm">
    <div class="field">
      <app-error-input-wrapper [errorMessage]="errorDict.get('name')" [showOnTouched]="true">
        <h5 class="labelsRequired">{{ trans.trans.tagId.value }}</h5>
        <input type="text" pInputText class="form-control" formControlName="name" autocomplete="off" />
      </app-error-input-wrapper>
    </div>

    <div class="field">
      <h5 class="labelsRequired">
        {{ trans.trans.factorValueTag.value }}
        <span class="material-icons-outlined tooltip-custom info-icon light" [matTooltip]="trans.trans.tooltip.value" matTooltipPosition="right">info</span>
      </h5>
      <app-error-input-wrapper [errorMessage]="errorDict.get('factorValueTag')" [showOnTouched]="true">
        <input type="text" pInputText class="form-control" formControlName="factorValueTag" autocomplete="off" />
        <small *ngIf="factorValueTag.value && !errorDict.get('factorValueTag')" class="onshow-tag">{{ factorValueTag.value | assetTagFormat }}</small>
      </app-error-input-wrapper>
    </div>

    <div class="field preview-tags" *ngIf="factorValueTag.value !== '' && !errorDict.get('factorValueTag')">
      <ng-container [ngSwitch]="this.tagSelected.metadata.array_name">
        <ng-container *ngSwitchCase="AssetTagArray.Factors">
          <h5>{{ trans.trans.factorNameTag.value }}</h5>
          <small *ngIf="factorValueTag.value" class="onshow-tag">{{ factorValueTag.value | assetTagFormat: 'name' }}</small>
          <h5>{{ trans.trans.factorDescriptionTag.value }}</h5>
          <small *ngIf="factorValueTag.value" class="onshow-tag">{{ factorValueTag.value | assetTagFormat: 'description' }}]</small>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTagArray.Benefits">
          <ng-template
            [ngTemplateOutlet]="tagString"
            [ngTemplateOutletContext]="{ tagStrings: tagStringsBenefit, yearTag: 'benefitYear', phasingYearTag: 'phasingPercentYear', subtractionValue: 5 }"
          ></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTagArray.BenefitGroups">
          <ng-template
            [ngTemplateOutlet]="tagString"
            [ngTemplateOutletContext]="{ tagStrings: tagStringsBenefitGroup, yearTag: 'benefitGroupYear', subtractionValue: 2 }"
          ></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTagArray.BenefitTypes">
          <ng-template
            [ngTemplateOutlet]="tagString"
            [ngTemplateOutletContext]="{ tagStrings: tagStringsBenefitType, yearTag: 'benefitTypeYear', subtractionValue: 1 }"
          ></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTagArray.FunctionalObjectives">
          <ng-template
            [ngTemplateOutlet]="tagString"
            [ngTemplateOutletContext]="{ tagStrings: tagStringsFunctionalObjective, yearTag: 'foYear', subtractionValue: 1 }"
          ></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTagArray.Capabilities">
          <ng-template
            [ngTemplateOutlet]="tagString"
            [ngTemplateOutletContext]="{ tagStrings: tagStringsCapabilities, yearTag: 'capabilityYear', subtractionValue: 1 }"
          ></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTagArray.OperationalObjectives">
          <ng-template [ngTemplateOutlet]="tagString" [ngTemplateOutletContext]="{ tagStrings: tagStringsOO, yearTag: 'ooYear', subtractionValue: 1 }"></ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="AssetTagArray.PainPoints">
          <ng-template [ngTemplateOutlet]="tagString" [ngTemplateOutletContext]="{ tagStrings: tagStringsPP, yearTag: 'ppYear', subtractionValue: 1 }"></ng-template>
        </ng-container>
      </ng-container>
      <ng-template #tagString let-tagStrings="tagStrings" let-yearTag="yearTag" let-phasingYearTag="phasingYearTag" let-subtractionValue="subtractionValue">
        <ng-container *ngFor="let tagString of tagStrings; index as i">
          <h5 *ngIf="tagString.label !== yearTag && tagString.label !== phasingYearTag">{{ trans.trans[tagString.label].value }}</h5>
          <h5 *ngIf="tagString.label === yearTag">{{ trans.trans[tagString.label].value }} {{ i - subtractionValue }} {{ trans.trans.benefitsTag.value }}</h5>
          <h5 *ngIf="tagString.label === phasingYearTag">{{ trans.trans[tagString.label].value }} {{ i - subtractionValue - 12 }} {{ trans.trans.tag.value }}</h5>
          <small *ngIf="factorValueTag.value" class="onshow-tag">{{ factorValueTag.value | assetTagFormat: tagString.key }}</small>
        </ng-container>
      </ng-template>
    </div>

    <h5>{{ trans.trans.status.value }}</h5>
    <p-inputSwitch formControlName="active"></p-inputSwitch>
  </form>
  <div class="btn_container">
    <div class="row">
      <div class="col-xs-12">
        <button
          pButton
          type="button"
          iconPos="right"
          [label]="trans.trans.save.value"
          [disabled]="tagForm.invalid"
          [loading]="isLoading"
          class="pull-right marg-h btn btn_customizable"
          (click)="onSave()"
        ></button>
      </div>
    </div>
  </div>
</p-sidebar>
