<div class="row" *ngIf="loadingLanguages">
  <div class="col-xs-12">
    {{ trans.trans.loadingLanguage.value }}<br />
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
</div>

<form name="AccountAssetsTemplatesForm" id="ngForm" #AccountAssetsTemplatesForm="ngForm">
  <div class="row">
    <div class="col-xs-12">
      <button class="btn btn_customizable pull-right" (click)="addTemplate()"><i class="fa fa-plus"></i> {{ trans.trans.addTemplate.value }}</button>
    </div>
  </div>

  <div *ngFor="let newTemplate of templates; let index = index" style="margin-bottom: 20px">
    <p-panel [header]="'Asset ' + (index + 1)" [collapsed]="false" [toggleable]="true">
      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label labelsRequired">{{ trans.trans.templateName.value }}</label>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.templateName"
            (callback)="getTranslations()"
          ></app-editTranslation>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name_{{ index }}"
            [placeholder]="trans.trans.templateName.value"
            [(ngModel)]="newTemplate.name"
            (input)="notifyChanges()"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label">{{ trans.trans.description.value }}</label>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.description"
            (callback)="getTranslations()"
          ></app-editTranslation>
          <textarea
            type="text"
            class="form-control"
            id="description"
            name="description_{{ index }}"
            [(ngModel)]="newTemplate.description"
            (input)="notifyChanges()"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label labelsRequired">{{ trans.trans.language.value }}</label>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.language"
            (callback)="getTranslations()"
          ></app-editTranslation>
          <select class="form-control role-dropdown" (change)="notifyChanges()" [(ngModel)]="newTemplate.language_type_id" name="language_type_id_{{ index }}" type="text">
            <option disabled>{{ trans.trans.ChooseOne.value }}</option>
            <option *ngFor="let lang of languages" [ngValue]="lang.id">{{ lang.name }}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label labelsRequired">{{ trans.trans.artifactName.value }}</label>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.artifactName"
            (callback)="getTranslations()"
          ></app-editTranslation>
          <input
            type="text"
            class="form-control"
            id="artifact_name"
            name="artifact_name{{ index }}"
            [placeholder]="trans.trans.artifactName.value"
            [(ngModel)]="newTemplate.artifact_name"
            (input)="notifyChanges()"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <fieldset class="checkbox custom-checkbox" (click)="newTemplate.pdf = !newTemplate.pdf; notifyChanges()">
            <input type="checkbox" class="custom-checkbox" [(ngModel)]="newTemplate.pdf" name="pdf{{ index }}" style="margin-right: 10px" />
            <label class="sidebar_label" style="margin: auto">{{ trans.trans.addPdf.value }}</label>
            <span class="check"></span>
          </fieldset>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.addPdf"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12" style="margin-bottom: 10px;">
          <p-multiSelect
            dataKey="id"
            [options]="solutions"
            [(ngModel)]="newTemplate.solutionsSelected"
            (onChange)="solutionsOptionsChanges(newTemplate)"
            optionLabel="name"
            class="form-control"
            [ngModelOptions]="{ standalone: true }"
            [defaultLabel]="trans.trans.noModelsSelected.value"
            appendTo='body'
          ></p-multiSelect>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <label class="sidebar_label labelsRequired">{{ trans.trans.yourAssetTemplateFile.value }}</label>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.yourAssetTemplateFile"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" style="display: flex;">
          <input
            type="file"
            name="media_1_{{ index }}"
            [(ngModel)]="newTemplate.tmpfile"
            [accept]="extensions"
            (change)="handleInputChange($event, index)"
            required
          />
          <span
            class="material-icons-outlined tooltip-custom fa fa-upload"
            [matTooltip]="trans.trans.uploadFile.value"
            matTooltipPosition="above"
            container="body"
            *ngIf="newTemplate.file"
            style="padding-left: 10px;"
            (click)="saveUploadedFile()"
          >
          </span>
        </div>
      </div>

      <div *ngIf="newTemplate.files?.length > 0 || newTemplate.potential_files?.length > 0">
        <div class="row" *ngIf="newTemplate.files?.length > 0">
          <div class="col-xs-12" style="margin-top: 10px;">
            <label class="heavy-label">{{trans.trans.existingFiles.value}}</label>
            <span
              class="material-icons-outlined tooltip-custom info-icon"
              [matTooltip]="trans.trans.existingFilesTooltip.value"
              matTooltipPosition="above"
              container="body"
            >
              info
            </span>
          </div>
          <div *ngFor=" let file of newTemplate.files" class="col-xs-12" style="display: flex;">
            <a class="link" target="_blank" [href]="file.link" download
              >{{ file.label }}</a>
            <span style="margin-left: auto;">
              <app-are-you-sure
                [message]="trans.trans.areYouSure.value"
                text=""
                [tooltip]="trans.trans.delete.value"
                icon="fa fa-times"
                [class]="'no_btn pull-right'"
                iconColor="#ff5d5d"
                (callbackYes)="deleteTemplateFile(file)"
              ></app-are-you-sure>
            </span>
          </div>
        </div>

        <div class="row" style="padding-left: 15px;" id="potential-container" *ngIf="newTemplate.potential_files?.length > 0">
          <div class="col-xs-12 row" style="margin-top: 10px; text-align: center;">
            <label (click)="showPotentialFiles = !showPotentialFiles" class="potential-label" *ngIf="!showPotentialFiles">
              {{trans.trans.potentialFilesShow.value}}
            </label>
            <label (click)="showPotentialFiles = !showPotentialFiles" class="potential-label" *ngIf="showPotentialFiles">
              {{trans.trans.potentialFilesHide.value}}
            </label>
            <span
              class="material-icons-outlined tooltip-custom info-icon"
              [matTooltip]="trans.trans.potentialFilesTooltip.value"
              matTooltipPosition="above"
              container="body"
            >
              info
            </span>
          </div>
          <div *ngFor=" let potential_file of newTemplate.potential_files" class="col-xs-12 row">
            <a *ngIf="showPotentialFiles" class="link" target="_blank" [href]="potential_file.link" download
              >{{ potential_file.label }}</a>
            <span
              *ngIf="showPotentialFiles"
              class="material-icons-outlined tooltip-custom pull-right"
              [matTooltip]="trans.trans.connectTooltip.value"
              matTooltipPosition="above"
              container="body"
              (click)="manuallyAssign(newTemplate, potential_file)"
            >
              check
            </span>
          </div>
        </div>

        <div class="row" style="padding: 10px;" *ngIf="newTemplate.files?.length > 0">
          <iframe [src]="newTemplate.iframe_url" width="100%" height="400px" frameborder="0"></iframe>
        </div>

        <app-asset-preview
          [template]="newTemplate"
          *ngIf="selectedAsset.section_type_id === '1' && newTemplate.files?.length > 0"
        >
        </app-asset-preview>
        

        <div class="row" *ngIf="newTemplate.files == undefined || newTemplate.files?.length == 0">
          <div class="col-xs-12" style="margin-top: 10px;">
            <label class="heavy-label">{{trans.trans.noExistingFiles.value}}</label>
          </div>
        </div>
      </div>
      <div *ngIf="newTemplate" class="delete-container">
        <app-are-you-sure 
          [matTooltip]="'Delete'" 
          matTooltipPosition="above" 
          message='Are you sure?' 
          [text]="trans.trans.deleteTemplate.value"
          icon='fieldset_icon fa fa-trash' 
          (callbackYes)='deleteTemplate.emit(newTemplate)'
        ></app-are-you-sure>
      </div>
    </p-panel>
  </div>

</form>