import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AEMaturityComponent } from './ae_maturity.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';

import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuModule } from 'primeng/menu';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { TableModule } from 'primeng/table';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { TreeTableModule } from 'primeng/treetable';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { DropdownModule } from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {InputMaskModule} from 'primeng/inputmask';
import { CXOService } from '@data/services/cxo/cxo.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [
		InputMaskModule,
		TableModule,
		SelectButtonModule,
		InputSwitchModule,
		DropdownModule,
		DLMenubarModule,
		TreeTableModule,
		InputSwitchModule,
		AreYouSureModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		TableModule,
    MatTooltipModule,
		MenuModule,
		NgbModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		ApplicationPipesModule,
	],
	declarations: [

		AEMaturityComponent
	],
	exports: [AEMaturityComponent],
	providers: [CXOService]
})
export class AEMaturityModule { }
