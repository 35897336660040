<div>
  <div
    #global_search_container
    class="input-group keywest_search global_search_container"
    style="border: 0px; position: relative; opacity: 100; top: 13px"
  >
    <span
      #global_input
      class="input-group-addon"
      [class.input-group-addon-left]="!(style2022$ | async)"
      [class.bg-white]="!(style2022$ | async)"
      [ngClass]="!toggleGlobalSearch ? 'left_border_on' : 'left_border_off'"
      ><i class="fa fa-search"></i
    ></span>
    <ng-container *ngIf="style2022$ | async; else default">
      <input
        [(ngModel)]="strSearch"
        type="text"
        [placeholder]="trans.trans.search.value"
        class="form-control"
      />
      <span
        [hidden]="toggleGlobalSearch"
        role="button"
        (click)="funcGlobalSearch()"
        class="btn btn_customizable input-group-addon border-radius-0-left"
        >{{ trans.trans.search.value }}</span
      >
      <span
        [hidden]="!toggleGlobalSearch"
        role="button"
        #global_close
        (click)="funcCloseSearch()"
        class="btn btn_customizable input-group-addon border-radius-0-left"
      >
        <i class="fa fa-times"></i>
      </span>
    </ng-container>
    <ng-template #default>
      <input
        [(ngModel)]="strSearch"
        (keyup)="funcGlobalSearch()"
        type="text"
        placeholder="Search"
        class="form-control bg-white"
      />
      <span
        #global_close
        (click)="funcCloseSearch()"
        class="input-group-addon clickable input-group-addon-right bg-white"
        [ngClass]="!toggleGlobalSearch ? 'right_border_on' : 'right_border_off'"
        ><i class="fa fa-times"></i
      ></span>
    </ng-template>
  </div>
</div>

<div
  #g_overlay
  class="global_overlay"
  [style.width]="global_search_container.offsetWidth + 'px'"
  [style.left]="global_search_container.offsetLeft + 'px'"
>
  <div
    style="overflow-y: auto; height: 350px; padding-bottom: 50px; padding: 20px"
  >
    <table>
      <tr
        *ngFor="let elem of rollup"
        style="display: block; margin-bottom: 10px; font-size: 14px"
      >
        <td>
          <i
            *ngIf="elem.type == 'hypothesis'"
            class="fa fa-pie-chart"
            style="margin-right: 10px"
          ></i>
          <i
            *ngIf="elem.type == 'realization'"
            class="icon-loop"
            style="margin-right: 10px"
          ></i>
          <i
            *ngIf="elem.type == 'proposition'"
            class="fa fa-bullhorn"
            style="margin-right: 10px"
          ></i>
          <a [routerLink]="elem.url" (click)="funcCloseSearch()">{{
            elem.name
          }}</a>
        </td>
      </tr>
    </table>
  </div>
  <div class="global_search_footer" *ngIf="strSearch">
    <a (click)="funcShowAllResults()" class="clickable">View all Results</a>
  </div>
</div>
