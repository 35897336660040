import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Goal } from '@shared/models/goal-group.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AeGoalsTranslations } from './ae-goals.translation';

@Component({
  selector: 'app-ae-goals',
  templateUrl: './ae-goals.component.html',
})
export class AeGoalsComponent implements OnInit, OnDestroy {
  @Input() goalSelected: Goal;

  showTranslate = false;
  ngUnsubscribe$ = new Subject();

  constructor(public trans: AeGoalsTranslations, private translationsService: TranslationsV2Service, private commonService: CommonService) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationsService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationsService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
