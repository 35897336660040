import { Component, OnInit, HostListener, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, ViewEncapsulation, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { CompanyService } from '@data/services/company/company.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepositionService } from '@data/services/valueposition/valueposition.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Location } from '@angular/common';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { Subscription, Subject } from 'rxjs';

import { DynamicComponentLoader } from './dynamic-component-loader/dynamic-component-loader.service';
import { AddVPGenericComponent } from './dynamic_components/generic.component';
import { GuidedTour, Orientation } from 'ngx-guided-tour';
import { GuidedTourService } from 'ngx-guided-tour';
import { CreateVPService } from '@data/services/create_vp/create_vp.service';
import { DefaultTranslations } from './add-value-positionv2.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { CompanyDetails } from '@data/services/valueposition/models/company-details.interface';
import { AllScaler } from '@data/services/valueposition/models/all-scaler.interface';
import { MasterScaler } from '@data/services/valueposition/models/master-scaler.interface';
import { AvailableSolution } from '@data/services/valueposition/models/available-solutions.interface';
import { VpProject, VpProjectChildren } from '@data/services/valueposition/models/master-vp-projects.interface';
import { MasterValueProp } from '@data/services/valueposition/models/mast-value-prop.interface';

interface Step {
  title?: string;
  content?: string;
  orientation?: string;
  selector?: string;
  progress?: any;
  component?: any;
  badge?: any;
  complete?: any;
  vpid?: any;
  vpgroup?: any;
  vp?: any;
  index?: number | string;
  parent?: any;
  step?: string | number;
  requireOppID?: any;
  requireVPID?: any;
  requireCompanyID?: any;
}

@Component({
  selector: 'app-add-value-position-v2',
  templateUrl: './add-value-position.component.v2.html',
  styleUrls: ['./add-value-position.component.v2.scss'],
  providers: [CompanyService, ValuepositionService, MasterValuePropService],
  encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: component-class-suffix
export class AddValuePositionComponentV2 implements OnInit, OnDestroy {

  @Input() dealdesk: boolean;
  @Input() masterValueProp: MasterValueProp;
  @Input() isvalueProp: boolean;
  @Input() value_prop_group_id: number;

  @Output() quickCreate = new EventEmitter();
  @Output() masterValuePropCreate = new EventEmitter();
  @Output() CallbackDone = new EventEmitter();
  @Input() addJustCase = false;
  @Input() companyDetails: CompanyDetails;
  isVMWare = false;
  detailedDescription = true;
  companyId: number;
  showActionLoader = false;
  showActionLoaderSituation = false;
  showActionLoaderModel = false;
  showActionLoaderScaler = false;
  showModelsLoader = false;
  finishandReviewButton = false;
  showSituation = false;
  showCategoryLoader = false;
  valuePropId: string = null;
  fullImagePath: string;
  quickCompanyDetailsDescription = true;
  image_url: string;
  addvaluePositionLoader = false;
  scalersLoader = false;
  showScalers = false;
  addValueForm = true;
  quickFinish = true;
  allScalersList: AllScaler[] = [];
  MasterScalerList: MasterScaler[] = [];
  selectedModels: Array<{solutionId: string | number; quantity: string | number}> = [];
  step2Wrapper = true;
  step3Wrapper = true;
  categoryType: any;
  availableSolutions: AvailableSolution[];
  scalersCreated: boolean;
  solutionCategoryList: Array<{id: string | number, category: string | number}> = [];
  showCategoryTypeLoader = false;
  selectedSolutionsLength = 0;
  crm: {
    sfoide?: string;
    company_id?: number;
    opportunity_id?: string;
    account_defined_id?: string
  } = {};
  sfoide: string;
  ValuePropTerm: number;

  masterCalculateLoading = false;

  contextualHelp: any;

  projectFeature = false;
  feature26 = false;

  projects: Array<string | number>;
  availableProjects: Array<{name?: string; id?: string | number; isChecked?: boolean}>;
  projectToggle = false;
  projModToggle = false;
  productToggle = false;
  cameFromProjects = false;
  VMWareprojectInit = false;
  projectInit = false;
  solutionMode: number;

  stepNumber: number;
  @ViewChild('stepMoveBackConfirmation') stepMoveBackConfirmation: ElementRef;

  // Master Value Prop
  masterValuePropId: string = null;
  showMasterValuePropProjects = false;
  availableMasterValuePropProjects: VpProject[];
  selectedProjects: VpProjectChildren[] = [];
  showActionLoaderProjects = false;

  // Discovery
  guidedDiscoverySub: Subscription;
  DiscoveryHeader = 'Mandatory Discovery Questions';

  // Oppurtunity Feature
  isOppurtunityEnabled = false;
  aid: string;
  opportunityId: string;
  accountDefinedId: string;

  subscriptiongetProjects: Subscription;
  subscriptioncreateProjects: Subscription;
  subscriptiongetAvailableSolutionsPlain: Subscription;
  subscriptiongetSolutionCategoryList: Subscription;
  subscriptiongetBusinessModelsQuickCreate: Subscription;
  subscriptiongetCompanySummary: Subscription;
  subscriptioncreateValueProp: Subscription;
  subscriptiongetCompanyExecutive: Subscription;
  subscriptionsaveSolutions: Subscription;
  subscriptionquickCreateSolution: Subscription;
  subscriptionreadAvailableScalers: Subscription;
  subscriptiongetAvailableScalers: Subscription;
  subscriptioncreateScalers: Subscription;
  subscriptionquickCreateScaler: Subscription;
  subscriptioncreateMasterValueProp: Subscription;
  subscriptionreadAvailableProjects: Subscription;
  subscriptionupdateValuePropOpportunityId: Subscription;
  contextualHelpVP: any = [];
  header_color: string;

  activeIndex: number | string = 0;
  steps: Step[] = [];
  valueProp: any;
  @Input() value_prop_group: MasterValueProp;
  showProgress = true;
  progressHasData = false;
  showHelp = false;

  startOver = false;
  showTranslate = false;
  valueVP: {createVP?: any};

  @ViewChild('tabOutlet', { read: ViewContainerRef, static: true }) tabOutlet: ViewContainerRef;
  ngUnsubscribe$ = new Subject();

  public createVPTour: GuidedTour = {
    tourId: 'createVP-tour',
    useOrb: false,
    steps: [
      {
        title: 'Welcome',
        content: `Let's take a quick walk through of the new changes of Creating a Value Prop`,
        orientation: Orientation.Center
      },
      {
        title: 'Navigation',
        content: `As your progress through the steps, we'll show your progress here.`,
        selector: '.vpNav',
        orientation: Orientation.Right
      },
      {
        title: 'Options',
        content: `This is your working area. We'll present the various options here.`,
        selector: '.createContent',
        orientation: Orientation.Bottom
      },
      {
        title: 'Progress',
        content: `Your choices from the steps will be here as your work through them so you can see what you've chosen`,
        selector: '.createProgress',
        orientation: Orientation.Left
      },
    ]
  };

  constructor(
    private dynamicComponentLoader: DynamicComponentLoader,
    private companyService: CompanyService,
    private valuepositionService: ValuepositionService,
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private location: Location,
    private masterValuePropService: MasterValuePropService,
    private cd: ChangeDetectorRef,
    private guidedTourService: GuidedTourService,
    private VPGroup: CreateVPService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

    if (sessionStorage.getItem('crm')) {
      this.crm = JSON.parse(sessionStorage.getItem('crm'));
      this.sfoide = this.crm.sfoide;
      if (this.crm.company_id) {
        this.companyId = this.crm.company_id;
        this.getCompanyDetail();
      }

      if (this.crm.opportunity_id) {
        this.opportunityId = this.crm.opportunity_id;
      }
      if (this.crm.account_defined_id) {
        this.accountDefinedId = this.crm.account_defined_id;
      }
    }
  }

  ngOnInit(): void {
    this.commonService.refreshVPGroupDashboard.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.CallbackDone.emit();
    });

    if (localStorage.getItem('showHelp') == null) { localStorage.setItem('showHelp', this.showHelp.toString()); }
    this.showHelp = JSON.parse(localStorage.getItem('showHelp'));
    this.header_color = localStorage.getItem('header_color');

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'customercolors';

    const containerInterior = '.btn-selected { color: #fff !important; border-color: ' + this.header_color + ' !important; background-color: ' + this.header_color + ' !important; }';
    style.appendChild(document.createTextNode(containerInterior));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      this.contextualHelp = response['Valueprop'];
      this.contextualHelpVP = response['Valueposition'];
    });

    this.scalersCreated = false;

    this.aid = sessionStorage.getItem('aid');

    if (this.commonService.checkFeature('13')) {
      this.projectFeature = true;
    }

    if (this.commonService.checkFeature('22')) {

      this.isOppurtunityEnabled = true;
      this.VMWareprojectInit = true;
      this.projectInit = false;
    }
    if (this.aid === '20') {
      this.isVMWare = true;
    } else {
      this.isVMWare = false;
    }

    if (this.commonService.checkFeature('26')) {
      this.feature26 = true;
    }

    if (this.projectFeature) {
      this.productToggle = false;
      if (!this.VMWareprojectInit) {
        this.projectInit = true;
      }
      this.solutionMode = 1;
      this.projects = [];
    } else {
      this.productToggle = true;
    }

    this.guidedDiscoverySub = this.valuepropService.guidedDiscoverySave.pipe(takeUntil(this.ngUnsubscribe$)).subscribe();

    if (this.aid !== '20') {

      this.VMWareprojectInit = false;
    }

    this.commonService.callbackVPCreateSave.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(value => {
      this.valueVP = value;
      if (value) {
        if (value.valuePropId) { this.valuePropId = value.valuePropId; }
        if (value.masterValueProp) { this.masterValueProp = value.masterValueProp; }
        if (value.ValuePropTerm) { this.ValuePropTerm = value.ValuePropTerm; }
        if (value.isvalueProp) { this.isvalueProp = value.isvalueProp; }
        if (value.accountDefinedId) { this.accountDefinedId = value.accountDefinedId; }
        if (value.opportunityId) { this.opportunityId = value.opportunityId; }
        if (value.isOppurtunityEnabled) { this.isOppurtunityEnabled = value.isOppurtunityEnabled; }
        if (value.aid) { this.aid = value.aid; }
        if (value.companyDetails) { this.companyDetails = value.companyDetails; }
        if (value.masterValuePropId) { this.masterValuePropId = value.masterValuePropId; }
        if (value.valueProp) { this.valueProp = value.valueProp; }
        if (value.availableSolutions) { this.availableSolutions = value.models; }
        if (value.value_prop_group) { this.value_prop_group = value.value_prop_group; }

        if (value.progress && !value.createVP) {
          this.progressHasData = true;

          const index = value.index ? value.index : 0;
          this.steps[index].progress = value.progress;
        }
        if (value.badge) {
          const index = value.index ? value.index : 0;
          switch (value.badge) {
            case 'complete':
              this.steps[index].complete = true;
              break;
            default:
              this.steps[index].badge = value.badge;
              break;
          }
        }

        if (value.createVP) {
          const vp = value.createVP;
          let index = this.steps.length;
          index = index + 1;
          this.funcBuildSubMenu(vp);
          this.valueProp = null;
          this.valuePropId = null;
          this.steps[index - 1].progress = value.progress;
          this.activeIndex = index;
          this.GotoStep(index);
          return;
        }
        if (value.goto) {
          this.loadComponent(value.goto);
        }

        if (value.deleteVP) {
          this.steps = this.steps.filter(obj => obj.vpid !== value.deleteVP);
          this.recalcIndexes();
          this.GotoStep(2);
          return;
        }
      }
      if (!value.goto) {
        this.Next();
      }
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
       this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      if (this.steps.length === 0) {
        this.funcBuildMenu();
        this.loadComponent(this.steps[0].component);
      } else if (this.steps.length < 5) {
        this.steps = [];
        this.funcBuildMenu();
      } else if (this.steps.length > 5) {
        this.steps = [];
        this.funcBuildMenu();
        this.funcBuildSubMenu(this.valueVP.createVP);
      }
    });
  }


  funcBuildMenu(): void {
    const m1 = '';
    const m2 = this.trans.trans.pick_your_company.value;
    const m3 = this.trans.trans.add_usecase.value;

    if (!this.addJustCase) {
      if (this.commonService.checkFeature('22')) {
        this.steps.push({ title: (this.steps.length + 1) + '. ' + m1, parent: '', badge: '', index: (this.steps.length ? this.steps.length : '0'), progress: [], component: 'opportunityid', requireOppID: this.isVMWare, requireVPID: false });
      }
      this.steps.push({ title: (this.steps.length + 1) + '. ' + m2, parent: '', badge: '', index: this.steps.length, progress: [], component: 'addyouraccount', requireOppID: this.isVMWare, requireVPID: false });
      this.steps.push({ title: (this.steps.length + 1) + '. ' + m3, parent: '', badge: '', index: this.steps.length, progress: [], component: 'usecase', requireOppID: false, requireCompanyID: true, requireVPID: false });
    } else {
      this.steps.push({ title: (this.steps.length + 1) + '. ' + m3, parent: '', badge: '', index: this.steps.length, progress: [], component: 'usecase', requireOppID: false, requireCompanyID: true, requireVPID: false });
    }
  }

  funcShowTranslate(): void {
    this.commonService.notifyEditTranslations.next();
    this.showTranslate = !this.showTranslate;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.id === 'startOver1') { return; }
    this.startOver = false;
  }

  funcStartOver(): void {
    this.steps = [];
    if (this.value_prop_group && this.value_prop_group.id) {
      this.VPGroup.deleteValuePropGroup(this.value_prop_group.id).subscribe(() => {
        this.valueProp = null;
        this.valuePropId = null;
        this.value_prop_group = null;
        this.ValuePropTerm = null;
        this.companyDetails = null;
        this.companyId = null;
        this.opportunityId = null;
        this.startOver = false;
        this.activeIndex = 0;
        this.loadComponent(this.steps[0].component);
      });
    } else {
      this.valueProp = [];
      this.valuePropId = null;
      this.value_prop_group = {};
      this.ValuePropTerm = null;
      this.companyDetails = {};
      this.companyId = null;
      this.opportunityId = null;
      this.startOver = false;
      this.activeIndex = 0;
      this.loadComponent(this.steps[0].component);
    }
  }

  startTour(): void {
    this.guidedTourService.startTour(this.createVPTour);
  }
  recalcIndexes(): void {
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].index = i;
    }
  }

  funcBuildSubMenu(elem): void {
    const m1 = this.trans.trans.pick_your_models.value;
    const m2 = this.trans.trans.pick_your_situations.value;
    const m3 = this.trans.trans.scale_use_case.value;
    const m4 = this.trans.trans.finish.value;

    const index = this.steps.length;
    this.steps.splice(index, 0,
      { title: elem.name, parent: '', step: 1, badge: '', vp: elem.vp, vpid: elem.vpid, complete: true, index: this.steps.length, progress: [], component: 'usecase_' + index, requireOppID: false, requireCompanyID: true, requireVPID: true },
      { title: 'I. ' + m1, step: 2, parent: 'usecase', vp: elem.vp, vpid: elem.vpid, badge: '', index: this.steps.length, progress: [], component: 'models_' + index, requireOppID: false, requireCompanyID: false, requireVPID: true },
      { title: 'II. ' + m2, step: 2, parent: 'usecase', vp: elem.vp, vpid: elem.vpid, badge: '', index: this.steps.length, progress: [], component: 'situations_' + index, requireOppID: false, requireCompanyID: false, requireVPID: true },
      { title: 'III. ' + m3, step: 2, parent: 'usecase', vp: elem.vp, vpid: elem.vpid, badge: '', index: this.steps.length, progress: [], component: 'scalers_' + index, requireOppID: false, requireCompanyID: false, requireVPID: true },
      { title: 'IV. ' + m4, step: 2, parent: 'usecase', badge: '', vp: elem.vp, vpid: elem.vpid, index: this.steps.length, progress: [], component: 'refine_' + index, requireOppID: false, requireCompanyID: false, requireVPID: true }
    );
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].index = i;
    }
  }

  funcCheckRequired(step): boolean {
    if (step.requireOppID) {
      if (this.opportunityId) { return false; }
      return true;
    }

    if (step.requireVPID === true) {
      if ((this.valuePropId) || (step.vpid)) { return false; }
      return true;
    }

    if (step.requireCompanyID === true) {
      if (this.companyDetails === undefined) { return true; }
      return false;
    }
  }

  trackByFn(index) {
    return index;
  }

  loadComponent(component: any): void {
    const step = this.steps.find(x => x.component == component);
    const index = step.index;
    this.activeIndex = index;
    this.tabOutlet.clear();

    let vp, vpid, vpgroup: any;
    if (step.vp) { vp = step.vp; } else { vp = this.valueProp; }
    if (step.vpid) { vpid = step.vpid; } else { vpid = this.valuePropId; }
    if (step.vpgroup) { vpgroup = step.vpgroup; } else { vpgroup = this.value_prop_group; }

    this.dynamicComponentLoader
      .getComponentFactory<AddVPGenericComponent>(component)
      .subscribe({
        next: componentFactory => {
          if (!this.tabOutlet) {
            return;
          }

          const ref = this.tabOutlet.createComponent(componentFactory);
          ref.instance.contextualHelp = this.contextualHelp;
          ref.instance.showTranslate = this.showTranslate;
          ref.instance.valuePropId = vpid;
          ref.instance.masterValueProp = this.masterValueProp;
          ref.instance.ValuePropTerm = this.ValuePropTerm;
          ref.instance.isvalueProp = this.isvalueProp;
          ref.instance.accountDefinedId = this.accountDefinedId;
          ref.instance.opportunityId = this.opportunityId;
          ref.instance.isOppurtunityEnabled = this.isOppurtunityEnabled;
          ref.instance.aid = this.aid;
          ref.instance.masterValuePropId = this.masterValuePropId;
          ref.instance.isVMWare = this.isVMWare;
          ref.instance.valueProp = vp;
          ref.instance.availableSolutions = this.availableSolutions;
          ref.instance.index = `${index}`;
          ref.instance.value_prop_group = vpgroup;
          ref.instance.companyDetails = this.companyDetails;
          ref.instance.showHelp = this.showHelp;
          ref.instance.addJustCase = this.addJustCase;
          ref.changeDetectorRef.detectChanges();
        },
        error: () => {

        }
      });
  }

  funcShowHelp(): void {
    this.showHelp = !this.showHelp;
    localStorage.setItem('showHelp', this.showHelp.toString());
    this.loadComponent(this.steps[this.activeIndex].component);
  }

  Next() : void{
    this.activeIndex = (this.activeIndex == this.steps.length ? this.activeIndex = this.steps.length : this.activeIndex = Number(this.activeIndex) + 1);

    this.loadComponent(this.steps[this.activeIndex].component);
    this.cd.detectChanges();
  }

  Back(): void {
    this.activeIndex = (this.activeIndex == 0 ? 0 : this.activeIndex = +this.activeIndex - 1);
    this.loadComponent(this.steps[this.activeIndex].component);
  }

  GotoStep(step): void {
    this.activeIndex = step;
    this.loadComponent(this.steps[step].component);
  }

  ngOnDestroy(): void {
    this.guidedDiscoverySub.unsubscribe();

    if (this.subscriptiongetProjects) { this.subscriptiongetProjects.unsubscribe(); }
    if (this.subscriptioncreateProjects) { this.subscriptioncreateProjects.unsubscribe(); }
    if (this.subscriptiongetAvailableSolutionsPlain) { this.subscriptiongetAvailableSolutionsPlain.unsubscribe(); }
    if (this.subscriptiongetSolutionCategoryList) { this.subscriptiongetSolutionCategoryList.unsubscribe(); }
    if (this.subscriptiongetBusinessModelsQuickCreate) { this.subscriptiongetBusinessModelsQuickCreate.unsubscribe(); }
    if (this.subscriptiongetCompanySummary) { this.subscriptiongetCompanySummary.unsubscribe(); }
    if (this.subscriptioncreateValueProp) { this.subscriptioncreateValueProp.unsubscribe(); }
    if (this.subscriptiongetCompanyExecutive) { this.subscriptiongetCompanyExecutive.unsubscribe(); }
    if (this.subscriptionsaveSolutions) { this.subscriptionsaveSolutions.unsubscribe(); }
    if (this.subscriptionquickCreateSolution) { this.subscriptionquickCreateSolution.unsubscribe(); }
    if (this.subscriptionreadAvailableScalers) { this.subscriptionreadAvailableScalers.unsubscribe(); }
    if (this.subscriptiongetAvailableScalers) { this.subscriptiongetAvailableScalers.unsubscribe(); }
    if (this.subscriptioncreateScalers) { this.subscriptioncreateScalers.unsubscribe(); }
    if (this.subscriptionquickCreateScaler) { this.subscriptionquickCreateScaler.unsubscribe(); }
    if (this.subscriptioncreateMasterValueProp) { this.subscriptioncreateMasterValueProp.unsubscribe(); }
    if (this.subscriptionreadAvailableProjects) { this.subscriptionreadAvailableProjects.unsubscribe(); }
    if (this.subscriptionupdateValuePropOpportunityId) { this.subscriptionupdateValuePropOpportunityId.unsubscribe(); }

    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();

  }

  createMasterScaler(): void {
    this.subscriptioncreateProjects = this.valuepropService.createMasterScaler(this.masterValuePropId, {}).subscribe();
  }


  getCompanyDetail(): void {
    this.showActionLoader = true;
    this.subscriptiongetCompanySummary = this.companyService.getCompanySummary(this.companyId).subscribe((result) => {
      this.companyDetails = result.result.company;
      this.showActionLoader = false;
    });
  }

  checkModels(params, quantity, event): void {
    if (event.target.checked === true) {
      this.showCategoryLoader = true;
      const tempModel = {
        'solutionId': params,
        'quantity': quantity
      };
      this.selectedModels.push(tempModel);
    } else if (event.target.checked === false) {
      this.selectedModels = this.selectedModels.filter((impact: any) => {
        return impact['solutionId'] !== params;
      });

    }
  }

  quantityAssign(id, quantityValue): void {
    const quantityCheck = this.selectedModels.filter(item => {
      return item['solutionId'] == id;
    });

    if (quantityCheck.length > 0) {
      for (let i = 0; i < this.selectedModels.length; i++) {
        if (this.selectedModels[i]['solutionId'] == id) {
          this.selectedModels[i]['quantity'] = quantityValue;
        }
      }
    }
  }

  quantityEnabled(event): void {
    event.preventDefault();
  }

  backClicked(): void {
    this.location.back();
  }

  readAvailableMasterValuePropProjects(): void {
    this.showActionLoaderProjects = true;
    this.availableMasterValuePropProjects = [];
    this.subscriptionreadAvailableProjects = this.masterValuePropService.readAvailableProjects(this.masterValuePropId).subscribe(response => {
      if (response.result && response.result.projects) {
        this.availableMasterValuePropProjects = response.result.projects;
      }
      this.showActionLoaderProjects = false;
    });
  }

  moveSelectedProject(): void {
    this.selectedProjects = [];

    this.availableMasterValuePropProjects.forEach(projects => {
      if (projects['children'] && projects['children'].length > 0) {
        projects['children'].forEach(children => {
          if (children['selected']) {
            children['parent'] = projects['name'];
            this.selectedProjects.push(children);
          }
        });
      }
    });
  }

  removeSelectedProject(project_id): void {
    const index = this.selectedProjects.findIndex(project => project['id'] == project_id);

    if (index !== -1) {
      this.selectedProjects.splice(index, 1);
    }
  }

  createProjects(): void {
    const payload = {
      'account_project_id': this.selectedProjects.map(project => project['id']).join(',')
    };

    this.showActionLoaderProjects = true;
    this.subscriptioncreateProjects = this.masterValuePropService.createProjects(this.masterValuePropId, payload).subscribe(() => {

      this.showActionLoaderProjects = false;
    });
  }

  changeDiscoveryHeader(txt): void {
    this.DiscoveryHeader = txt;
  }

}
