import { NgModule } from '@angular/core';
import { ChartModule } from 'primeng/chart';

import { TrackerStackedBarComponent } from './trackers-stacked-bar.component';

@NgModule({
    imports: [
        ChartModule
    ],
    exports: [TrackerStackedBarComponent],
    declarations: [TrackerStackedBarComponent],
    providers: [],
})
export class TrackerStackedBarModule { }
