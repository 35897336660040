import { WorkflowQuestion } from '@data/services/workflow/models/workflow-question.interface';
import { UserGroup } from '@shared/models/account-groups.models';

export interface Workflow {
  id?: string;
  sequence: string;
  workflow_type_id: string;
  name: string;
  description: string;
  active: '0' | '1';

  activeBool?: boolean;
  questions?: WorkflowQuestion[];
}

export interface ConfigurableWorkflow {
  id?: string;
  account_id?: string;
  workflow_type_id: string;
  active: '0' | '1';
  created?: string;
  modified?: string;
  name: string;
  description: string;
  icon: string;
  sequence: string;
  system_defined?: '0' | '1';
  creator?: string;
  user_groups?: UserGroup[];
  workflow_sections?: WorkflowSection[];
  all_users?: string;
  all_templates?: string;
}

export interface WorkflowSection {
  name: string;
  description: string;
  sequence: string;
  element_type_id: string;
  default_member_id?: string;
  workflow_data_source_id: string;
  active: string;
  members: number[];
  created?: string;
  id?: string;
  modified?: string;
}

export interface ConversationWorkflow {
  id?: string;
  account_id: string;
  workflow_type_id: string;
  active: '0' | '1';
  created: string;
  modified: string;
  name: string;
  conversations: Conversation[];
  description: string;
  icon: string;
  sequence: string;
  system_defined: '0' | '1';
  user_id: string;
  label?: string;
  command?: any;
  automationId?: string;
}

export interface Conversation {
  account_workflow_id: string;
  account_workflow_section_id: string;
  created: string;
  description: string;
  id: string;
  member_id: string;
  modified: string;
  name: string;
  workflow_data_source_id: string;
  is_default_member?: boolean;
}

export interface WorkflowDataResponse {
  workflow_data: WorkflowData[];
}

export interface WorkflowData {
  account_id?: string;
  created?: string;
  description: string;
  id?: string;
  member_id: number | string;
  modified?: string;
  name: string;
  sequence?: string;
  is_default_member?: boolean;
}

export enum GuidedDiscoveryElementTypes {
  singleSelect = 1,
  multiSelect = 2,
  dropdownSingle = 3,
}

export interface WorkflowAssignmentResponse {
  workflow_assignments: WorkflowAssignment[]
}

export interface WorkflowAssignment {
  id?: string;
  account_id: string;
  workflow_type_id: string;
  active: '0' | '1';
  created: string;
  modified: string;
  name: string;
  default_assignment: string;
  conversations: Conversation[];
  description: string;
  icon: string;
  sequence: string;
  system_defined: '0' | '1';
  user_id: string;
}

export interface WorkflowAssignmentUpdateRequest {
  account_workflow_id: number;
  default_assignment?: number;
}

export interface WorkflowUserAssignment {
  id?: string;
  active: string;
  name: string;
  default_assignment?: string;
  assignment_color: string;
}
