import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { BenefitsImplementation } from '../../benefits.implementation';
import { Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { take, takeUntil } from 'rxjs/operators';
import { BenefitsMenuTranslations } from '../menu/menu.translation';
import { BenefitType } from '@shared/models/benefit-type.model';

@Component({
  selector: 'app-benefit-actions-menu',
  templateUrl: './benefit-menu-actions-2022.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class BenefitActionsMenuComponent implements OnInit, OnDestroy {
  @Input() step2 = false;
  @Input() benefitOnFly = false;
  @Input() benefitsListByTypeFromFull: BenefitType[];
  @Output() addBenefit = new EventEmitter();
  ngUnsubscribe = new Subject();
  showTranslate = false;

  actionsList: any[] = [];
  constructor(public trans: BenefitsMenuTranslations, private translationService: TranslationsV2Service, private CommonService: CommonService, public bI: BenefitsImplementation) {}

  ngOnInit() {
    this.getTranslations();
    this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.bI.refreshActionMenu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.setActionMenu();
    });

    this.setActionMenu();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);

        this.setActionMenu();
      });
  }

  showDiscoveryQuestions() {
    this.bI.showDiscoveryQuestions = true;
  }

  showAddBenefit() {
    this.addBenefit.emit();
  }

  setActionMenu() {
    this.actionsList = [];

    const actionItems = {
      label: 'Actions',
      items: this.bI.actions,
    };
    this.actionsList.push(actionItems);

    if (this.bI && this.bI.has_sequence) {
      const sortItem = {
        label: this.trans.trans.sort.value,
        items: this.bI.sort,
      };
      this.actionsList = this.actionsList.concat(sortItem);
    }

    if (this.bI && (this.bI.has_pain_point || this.bI.has_funcobj || this.bI.has_capabilities || this.bI.has_opobj) && this.bI.feature48) {
      const groupMenuItem = {
        label: this.trans.trans.group.value,
        items: this.bI.group,
      };

      this.actionsList.push(groupMenuItem);
    }

    if (this.bI.featureDiscoveryQuestionsUpFront && this.step2) {
      const questionsMenuItem = {
        label: this.trans.trans.discovery_questions.value,
        command: () => this.showDiscoveryQuestions(),
      };
    }
  }

  clearSearch() {
    this.bI.menuFilter = '';
  }
}
