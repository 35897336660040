import { NgModule } from '@angular/core';

import { RBenefitsCasestudyComponent } from "./r_benefits-casestudy.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';
 
import { SidebarModule } from 'primeng/sidebar';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RBenefitsCasestudyTranslations } from './r_benefits-casestudy.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';


@NgModule({
	declarations: [
		RBenefitsCasestudyComponent,
	],
	imports: [
		ApplicationPipesModule,
		SidebarModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		TabViewModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		RBenefitsCasestudyTranslations
	],
	entryComponents: [
		RBenefitsCasestudyComponent,
	],
	exports: [RBenefitsCasestudyComponent]
})
export class RModelBenefitsCaseStudyModule { }