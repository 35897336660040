import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { UserService } from '@data/services/user/user.service';
import { Currency, NumberFormat } from '@data/services/valueprop/models/situation-opportunity.model';
import { AlertService } from '@services/alert.service';
import { NotificationService } from '@services/notification.service';
import { LanguageType } from '@shared/models/user-info.model';
import { EditDirectiveService } from '@shared_components/editTranslation/edit.service';
import { ApiServiceService } from 'app/api-service.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EditProfileInfoComponent, UserUpdate } from '../edit-profile-info/edit-profile-info.component';
import { CurrentUser } from '../user-profile.component';
import { UserProfileTranslation } from '../user-profile.translation';
import { CountryFlag, CountryFlagService } from '@data/services/country-flag/country-flag.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
	selector: 'app-user-card',
	templateUrl: './user-card.component.html',
	styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit, OnDestroy {

	@Input() user: CurrentUser;
	@Input() languageTypes: LanguageType[] = [];
	@Input() currencyTypes: Currency[] = [];
	@Input() numberFormats: NumberFormat[] = [];

	@ViewChild('passwordForm', {static:false}) passwordForm: ChangePasswordComponent;
	@ViewChild('editUserForm', {static: false}) editUserForm: EditProfileInfoComponent;

	private files: File[] = [];
	private flagMap: Map<string, CountryFlag>;

	public change_pwd = false;
	public edit_info = false;
	public profileIconName = "";
	public actionLoader = false;
	public userProfileImage: string | ArrayBuffer;
	public style2022$: Observable<boolean>;
	public profilepicdefaultpath = 'https://media.value-cloud.com/images/default_user.png';

	private unsubscribe = new Subject<void>();

	constructor(
		public trans: UserProfileTranslation,
		private styleService: StyleService,
		private userService: UserService,
		private NotificationService: NotificationService,
		private apiServiceService: ApiServiceService,
		private alertService: AlertService,
		private commonService: CommonService,
		private editService: EditDirectiveService,
		private FlagService: CountryFlagService
	) { }

	ngOnInit() {
		this.FlagService.countryFlagMap$
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(map => this.flagMap = map);

		this.style2022$ = this.styleService.style2022;
	}

	public handleInputChange(event): void {
		this.files = event.target.files;
		const reader = new FileReader();
		reader.readAsDataURL(this.files[0]);
		reader.onload = () => { this.userProfileImage = reader.result; };
		reader.onerror = function () { };
		this.profileIconName = (this.files && this.files[0].name) ? this.files[0].name : "";
		this.updateProfilePic();
	}

	public updateProfilePic(): void {
		if (this.files && this.files[0]) {
			this.actionLoader = true;
			let payload = new FormData();
			payload.append('user_profile_icon', this.files[0]);
			this.userService.updateUserProfileIcon(payload).pipe(take(1)).subscribe((result) => {
				this.NotificationService.success("Profile Image updated successfully", false);
				this.user['profilePicFilepath'] = result['result']['profile_pic_filepath'];
				this.apiServiceService.profilePic.next(result['result']['profile_pic_filepath']);
				this.userService.profileImage.next(this.user.profilePicFilepath);
				this.actionLoader = false;
			},
				error => { this.NotificationService.error(error.result, false); });
		}
	}

	public openPopup(): void {
		this.change_pwd = true;
	}

	public openEditFlyOut(): void {
		this.edit_info = true;
	}

	public closePopup(event: boolean): void {
		this.change_pwd = event;
	}

	public closeFlyout(event: boolean): void {
		this.edit_info = event;
	}

	public updateUser(): void {
		this.alertService.clear();
		var data = {
			phone: this.user.phone,
			email: this.user.email,
			currency_type_id: this.user.currencyTypeId,
			manager_email: this.user.managerEmail,
			number_format: this.user.numberFormat,
		};
		this.userService.updateUser(parseInt(this.user.id), data)
			.pipe(take(1))
			.subscribe((user) => {
				    this.getUser();
					this.NotificationService.success(user.result, false);
				},
				error => this.NotificationService.error(error, false)
			);
	}

	private getUser(): void {
		this.userService.getUser(+this.user.id).pipe(take(1)).subscribe();
	}

	public onEditUserSidebarSave(userData: UserUpdate): void {
		this.user.phone = userData.phone;
		this.updateUser();
		this.closeFlyout(false);
	}

	public setLanguage(): void {
		sessionStorage.setItem('language_type_id', this.user.languageTypeId);
		let payload = {
			language_type_id: this.user.languageTypeId,
		};
		this.editService.updateLanguage(this.user.id, payload)
			.pipe(take(1))
			.subscribe(() => {
				this.commonService.notifyChangeLanguage.next(),
					this.NotificationService.success(this.trans.trans.langchangedSuccessful.value, false)
			}, error => this.NotificationService.error(error, false));
	}

	public getFlag(lang: string): string {
		const flag = this.flagMap.get(lang);
		if (!flag) {
			return '';
		}
		return flag.unicodeIcon;
	}

	public resetPassForm() {
		if(this.passwordForm) {
			this.passwordForm.resetForm();
		}
	}

	public resetUserForm() {
		if (this.editUserForm){
			this.editUserForm.formUser = {...this.user};
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
