<div class="form-group col-md-12">
  <label>{{ trans.trans.selectGuided.value }}</label>

  <app-editTranslation
    *ngIf="showTranslate"
    [isTranslationV2]="true"
    [component]="trans.config.component"
    [transObj]="trans.trans.selectGuided"
    (callback)="getTranslations()"
  ></app-editTranslation>

  <div class="workflow clickable" *ngFor="let workflow of workflows" (click)="changeStep(workflow)">
    {{workflow.name}}
  </div>
  
</div>
