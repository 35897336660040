import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { AssetListTranslations } from './assets.translation';

@Component({
  selector: 'app-asset-list',
  templateUrl: 'assets.component.html',
})
export class AssetListComponent implements OnInit {
  @Input() assetList: any;
  @Input() valuePropId;

  mappedAssetList: any[];

  assetGenerator: string;
  userId: string;
  token: string;
  constructor(private CommonService: CommonService, public trans: AssetListTranslations) {}

  ngOnInit() {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    this.userId = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');

    this.mappedAssetList = this.assetList
      ? this.assetList.map((asst) => ({
          label: asst.name,
          icon: this.getFAIcon(asst.format),
          url: this.getAssetURL(asst),
          target: '_blank',
          description: asst.description,
          format: asst.format,
        }))
      : [];
  }

  getAssetURL(asset) {
    return (
      this.CommonService.getAssetUrl() +
      'assets/' +
      this.assetGenerator +
      '?account_asset_template_id=' +
      asset.account_asset_template_id +
      '&user_id=' +
      this.userId +
      '&token=' +
      this.token +
      '&value_prop_id=' +
      this.valuePropId
    );
  }

  getFAIcon(format) {
    switch (format) {
      case 'MS Word':
        return 'fa fa-file-word-o';

      case 'MS Excel':
        return 'fa fa-file-excel-o';

      case 'MS PowerPoint':
        return 'fa fa-file-powerpoint-o';

      case 'MS PDF':
        return 'fa fa-file-pdf-o';

      default:
        return 'fa fa-cloud-download';
    }
  }

  getFAColor(format) {
    switch (format) {
      case 'MS Word':
        return { 'color': '#2e5b97', 'margin-right': '5px' };

      case 'MS Excel':
        return { 'color': '#3a6e46', 'margin-right': '5px' };

      case 'MS PowerPoint':
        return { 'color': '#c55737', 'margin-right': '5px' };

      case 'MS PDF':
        return { 'color': '#c60a09', 'margin-right': '5px' };

      default:
        return { 'color': 'black', 'margin-right': '5px' };
    }
  }
}
