import { Injectable } from '@angular/core';

@Injectable()
export class QuoteTranslations {
	public config: any = {
		component: 'quote',
		display_name: 'Case Study Quote v2',
		description: 'List of quote\'s for each case study'
	};

	public trans: any = {
		add_testimonial: 'Add Testimonial',
		delete: 'Delete',
		edit: 'Edit',
		areYousure: 'Are you sure?',
		first_name: 'First Name',
		is_required: 'Is Required',
		last_name: 'Last Name',
		no_testimonials_found: 'No Testimonials Found',
		testimonial: 'Testimonial',
		title: 'Title',
        edit_translations: 'Edit Translations'
	};
}
