<div class="row" *ngIf="style2022$ | async">
	<div class="col-md-4 mr-auto breadcrumb-container">
	  <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
	</div>
  </div>

  <div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10 old-breadcrumb">
	  <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToProfile.value }}</a>
	</div>
  </div>

<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>
						{{trans.trans.solution_reports.value}}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='profile.administrator_options.solution_reports'
						[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.solution_reports"></app-editTranslation>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12">
				<div class='models_panel' style='margin-bottom: 20px;'>
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-10'>
									<div class='title accordion-title'>
										<i class='fa fa-chevron-right clickable' *ngIf="!factorExpand" (click)='factorExpand = !factorExpand'></i>
										<i class='fa fa-chevron-down clickable' *ngIf="factorExpand" (click)='factorExpand = !factorExpand'></i>
										{{trans.trans.factorsWithBiggestVariations.value}}
										<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.factorsWithBiggestVariations'
										[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.factorsWithBiggestVariations"></app-editTranslation>
									</div>
								</div>
							</div>
						</div>

						<div class="row" *ngIf="factorExpand">
							<div class="col-xs-12">

								<p-tabView>
									<p-tabPanel header="{{trans.trans.driverFactor.value}}">
											<div class="table-responsive">
												<div class="searchTableContainer">
													<div>
														<table class="table custom-table">
															<thead>
																<tr>
																	<th>{{trans.trans.name_label.value}}
																		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
																	</th>
																	<th class="text-right">{{trans.trans.baseline.value}}
																		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.baseline'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.baseline"></app-editTranslation>
																	</th>
																	<th class="text-right">{{trans.trans.standardDeviation.value}}
																		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.standardDeviation'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.standardDeviation"></app-editTranslation>
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr class="grid-row" *ngFor="let driverFactor of driverFactorList">
																	<td>{{driverFactor.name}}</td>
																	<td class="text-right preventWordBreak">{{driverFactor.avg_driver_est_fmt}}</td>
																	<td class="text-right preventWordBreak">{{driverFactor.avg_diff_est_ovr_fmt}}</td>
																</tr>
															</tbody>
														</table>

													</div>
												</div>
											</div>

									</p-tabPanel>
									<p-tabPanel header="{{trans.trans.financialFactor.value}}">
											<div class="table-responsive">
												<div class="searchTableContainer">
													<div>
														<table class="table custom-table">
															<thead>
																<tr>
																	<th>{{trans.trans.name_label.value}}
																		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
																	</th>
																	<th class="text-right">{{trans.trans.baseline.value}}
																		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.baseline'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.baseline"></app-editTranslation>
																	</th>
																	<th class="text-right">{{trans.trans.standardDeviation.value}}
																			<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.standardDeviation'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.standardDeviation"></app-editTranslation>
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr class="grid-row" *ngFor="let factor of financialFactorList">
																	<td>
																		{{factor.name}}
																	</td>
																	<td class="text-right preventWordBreak">{{factor.avg_fin_est_fmt}}</td>
																	<td class="text-right preventWordBreak">{{factor.avg_diff_est_ovr_fmt}}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
									</p-tabPanel>
									<p-tabPanel header="{{trans.trans.improvementFactor.value}}">
											<div class="table-responsive">
												<div class="searchTableContainer">
													<div>
														<table class="table custom-table">
															<thead>
																<tr>
																	<th>{{trans.trans.name_label.value}}
																		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
																	</th>
																	<th class="text-right">{{trans.trans.baseline.value}}
																		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.baseline'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.baseline"></app-editTranslation>
																	</th>
																	<th class="text-right">{{trans.trans.standardDeviation.value}}
																			<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.standardDeviation'
																		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.standardDeviation"></app-editTranslation>
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr class="grid-row" *ngFor="let factor of improvementFactorList">
																	<td>
																		{{factor.metric_name}}
																	</td>
																	<td class="text-right preventWordBreak">{{factor.avg_impact_ovr_fmt}}</td>
																	<td class="text-right preventWordBreak">{{factor.avg_diff_prob_ovr_fmt }}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
									</p-tabPanel>
								</p-tabView>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class='models_panel' style='margin-bottom: 20px;'>
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-10'>
									<div class='title accordion-title'>
										<i class='fa fa-chevron-right clickable' *ngIf="!topOnTheFlyBenefitsExpand" (click)='topOnTheFlyBenefitsExpand = !topOnTheFlyBenefitsExpand'></i>
										<i class='fa fa-chevron-down clickable' *ngIf="topOnTheFlyBenefitsExpand" (click)='topOnTheFlyBenefitsExpand = !topOnTheFlyBenefitsExpand'></i>
										{{trans.trans.topOnTheFlyBenefits.value}}
										<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.topOnTheFlyBenefits'
										[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.topOnTheFlyBenefits"></app-editTranslation>
									</div>
								</div>
							</div>
						</div>
						<div class='row' *ngIf="topOnTheFlyBenefitsExpand">
							<div class='col-xs-12'>

								<table class="table custom-table">
									<thead>
										<tr>
											<th>{{trans.trans.name_label.value}}
												<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
												[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
											</th>
											<th>{{trans.trans.business_case.value}}
												<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
												[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.business_case"></app-editTranslation>
											</th>
											<th>{{trans.trans.name.value}}
												<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
												[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name"></app-editTranslation>
											</th>
											<th>{{trans.trans.modified.value}}
												<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
												[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.modified"></app-editTranslation>
											</th>
											<th class="text-right">{{trans.trans.changed.value}}
												<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.changed'
												[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.changed"></app-editTranslation>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr class="grid-row" *ngFor="let benefit of topOnFlyBenefitsList">
											<td>{{benefit.metric_name}}</td>
											<td>{{benefit.value_prop_name}}</td>
											<td>{{benefit.user_name}} </td>
											<td>{{benefit.modified}}</td>
											<td class="text-right preventWordBreak">{{benefit.CHANGED}}</td>
										</tr>
									</tbody>
									<tbody *ngIf="!topOnFlyBenefitsList.length" class='no_data'>
										<td colspan='2'>{{trans.trans.noDataFound.value}}</td>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class='models_panel' style='margin-bottom: 20px;'>
					<div class='models_page_header_container_small'>
						<div class='keywest_header'>
							<div class='row'>
								<div class='col-xs-10'>
									<div class='title accordion-title'>
										<i class='fa fa-chevron-right clickable' *ngIf="!benefitsOftenRemovedExpand" (click)='benefitsOftenRemovedExpand = !benefitsOftenRemovedExpand'></i>
										<i class='fa fa-chevron-down clickable' *ngIf="benefitsOftenRemovedExpand" (click)='benefitsOftenRemovedExpand = !benefitsOftenRemovedExpand'></i>

										{{trans.trans.benefitsOftenRemoved.value}}
										<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.benefitsOftenRemoved'
										[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.benefitsOftenRemoved"></app-editTranslation>
									</div>
								</div>
							</div>
						</div>
						<div class='row' *ngIf="benefitsOftenRemovedExpand">
							<div class='col-xs-12'>
								<table class="table custom-table">
									<thead>
										<tr>
											<th>{{trans.trans.name_label.value}}
												<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
												[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
											</th>
											<th class="text-right">{{trans.trans.frequency.value}}
												<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.frequency'
												[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.frequency"></app-editTranslation>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr class="grid-row" *ngFor="let benefit of benefitsRemovedList">
											<td>{{benefit.metric_name}}</td>
											<td class="text-right preventWordBreak">{{benefit.CHANGED}} / {{benefit.value_prop_cnt}}</td>
										</tr>
									</tbody>
									<tbody *ngIf="!benefitsRemovedList.length" class='no_data'>
										<td colspan='2'>{{trans.trans.noDataFound.value}}</td>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-6">
			<div class='models_panel' style='margin-bottom: 20px;'>
				<div class='models_page_header_container_small'>
					<div class='keywest_header'>
						<div class='row'>
							<div class='col-xs-10'>
								<div class='title accordion-title'>
									<i class='fa fa-chevron-right clickable' *ngIf="!lowestRatedBenefitsExpand" (click)='lowestRatedBenefitsExpand = !lowestRatedBenefitsExpand'></i>
									<i class='fa fa-chevron-down clickable' *ngIf="lowestRatedBenefitsExpand" (click)='lowestRatedBenefitsExpand = !lowestRatedBenefitsExpand'></i>

									{{trans.trans.lowestRatedBenefits.value}}
									<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.lowestRatedBenefits'
									[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.lowestRatedBenefits"></app-editTranslation>
								</div>
							</div>
						</div>
					</div>
					<div class='row' *ngIf="lowestRatedBenefitsExpand">
						<div class='col-xs-12'>
							<table class="table custom-table lowestRatingTable">
								<thead>
									<tr>
										<th width="75%">{{trans.trans.name_label.value}}
											<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
											[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
										</th>
										<th class="text-right">{{trans.trans.frequency.value}}
											<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.frequency'
											[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.frequency"></app-editTranslation>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr class="grid-row" *ngFor="let benefit of LowestRatedBenefits">
										<td>{{benefit.metric_name}}</td>
										<td>
											<ngb-rating [rate]="+benefit.avg_score" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="!LowestRatedBenefits.length" class='no_data'>
									<td colspan='2'>{{trans.trans.noDataFound.value}}</td>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class='col-xs-6'>
			<div class='models_panel' style='margin-bottom: 20px;'>
				<div class='models_page_header_container_small'>
					<div class='keywest_header'>
						<div class='row'>
							<div class='col-xs-10'>
								<div class='title accordion-title'>
									<i class='fa fa-chevron-right clickable' *ngIf="!userCommentsOnBenefitsExpand" (click)='userCommentsOnBenefitsExpand = !userCommentsOnBenefitsExpand'></i>
									<i class='fa fa-chevron-down clickable' *ngIf="userCommentsOnBenefitsExpand" (click)='userCommentsOnBenefitsExpand = !userCommentsOnBenefitsExpand'></i>

									{{trans.trans.userCommentsOnBenefits.value}}
									<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.userCommentsOnBenefits'
									[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.userCommentsOnBenefits"></app-editTranslation>
								</div>
							</div>
						</div>
					</div>
					<div class='row' *ngIf="userCommentsOnBenefitsExpand">
						<div class='col-xs-12'>
							<table class="table custom-table lowestRatingTable">
								<thead>
									<tr>
										<th width="75%">{{trans.trans.name_label.value}}
											<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='general.name_label'
											[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name_label"></app-editTranslation>
										</th>
										<th class="text-right">{{trans.trans.frequency.value}}
											<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='rofile.solutionReport.frequency'
											[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.frequency"></app-editTranslation>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr class="grid-row" *ngFor="let benefit of UserCommentsBenefits">
										<td class="user_cmts_metric" (click)="showComments(showUserCommentsModal, benefit)">{{benefit.metric_name}}</td>
										<td>
											<ngb-rating [rate]="benefit.average_score" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="!UserCommentsBenefits.length" class='no_data'>
									<td colspan='2'>{{trans.trans.noDataFound.value}}</td>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #showUserCommentsModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{userBenefit.metric_name}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="reviewList">
			<div class="review" *ngFor="let comment of userBenefit.comments">
				<div class="row">
					<div class="col-md-2 col-xs-4 text-center">
						<h5>{{comment.user_name}}</h5>
					</div>
					<div class="col-md-10 col-xs-8">
						<p>{{comment.comment}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #t let-fill="fill">
	<span class="star" [class.full]="fill === 100">
		<span class="half" [style.width.%]="fill"><i class="fa fa-star"></i></span>
		<i class="fa fa-star-o borderStar"></i>
	</span>
</ng-template>