<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>
						Case Studies
					</div>
				</div>
				<div class='col-xs-6' *ngIf="can_edit">
					<a routerLink='/case_study/new' class="btn btn_customizable pull-right" matTooltip="Add Case Study" matTooltipPosition="below" *ngIf="showAddButtons">
						<i class="fa fa-plus"></i> Add</a>
				</div>
			</div>
		</div>
		<div class='row marg-v'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tableValuePosition.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='Search' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableValuePosition.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
			<div class='col-xs-8'>
				<span class="clickable pull-right" (click)='row.toggle($event)'>
					Row Limit: {{pageLimit}} <i class='fa fa-chevron-down'></i>
				</span>
			</div>
		</div>
	</div>

	<div class='row'>
		<div class='col-xs-12'>
			<h3 (click)='toggleShowTags = !toggleShowTags' class='clickable'>
				<i style="margin-right: 8px" class='fa fa-chevron-right' *ngIf="!toggleShowTags"></i>
				<i style="margin-right: 8px" class='fa fa-chevron-down' *ngIf="toggleShowTags"></i>
				Tags
			</h3>
		</div>
	</div>

	<div class='row' *ngIf="toggleShowTags">
		<div class='col-xs-12'>
			<span [id]="item" (click)='funcFilterByTag(item)' *ngFor="let item of tagList; let i = index" class='btn casestudy_tag' [ngStyle]="styleTag(item)">
				{{item}}
			</span>
		</div>
	</div>

	<div class='models_page_header_container_small'>
		<div class='row'>
			<div class='col-xs-12'>
				<p-progressBar *ngIf="loading" class='keywest_progressbar' mode="indeterminate"></p-progressBar>
				<p-table #tableValuePosition [columns]="columns" [value]="caseStudies" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" [pSortableColumn]="col.field" style='white-space: nowrap;'>
								{{col.header}}
								<p-sortIcon [field]="col.field"></p-sortIcon>
							</th>
							<th></th>
						</tr>
					</ng-template>

					<ng-template pTemplate="body" let-data let-rowIndex="rowIndex" let-columns="columns">
						<tr>
							<td>
								<a [routerLink]="['/case_study/' + data.id + '/' + data.is_account_solution]" class='link clickable'>{{data.name}}</a>
								<i matTooltip='Download Asset' (click)='downloadAsset(data.id)' class='clickable fa fa-download' style='margin-left: 10px;'></i>
							</td>
							<td>{{data.created}}</td>
							<td>{{data.testimonials_total}}</td>
							<td>{{data.sections_total}}</td>
							<td>{{data.benefits_total}}</td>
							<td>
								<span class='keywest_icon_group pull-right' *ngIf="can_edit">
									<span><a [routerLink]="'/case_study/' + data.id + '/' + data.is_account_solution"><i class='fa fa-pencil' matTooltip='Edit'></i></a></span>
									<app-are-you-sure [message]="'Are you sure you want to delete ' + data.name +'?'" text='' icon='fa fa-trash' [class]='"btn btn-danger pull-right"' (callbackYes)='deleteCaseStudy(data)'></app-are-you-sure>
								</span>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddCaseStudies' styleClass="keywest_sidebar p-sidebar-lg" position='right' (onHide)="onCloseSidebar()">
	<app-add-casestudy *ngIf="toggleAddCaseStudies" (callback)='getData()' [solutionID]='solutionID' [mode]='mode' [caseStudyEdit]="caseStudyEdit"></app-add-casestudy>
</p-sidebar>