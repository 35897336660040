import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { BasicInfo } from '@shared/models/basic-info.model';
import { BenefitType, BenefitVCS, BenefitVCSMetric } from '@shared/models/benefit-type.model';
import { Metric, ValueProp } from '@shared/models/value-prop.model';
import { BenefitsImplementation } from 'app/value-prop/value-prop-dashboard/benefits_v2/benefits.implementation';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ReviewBaselineBenefitsTranslations } from './review-baseline-benefits.translation';

@Component({
  selector: 'app-review-baseline-benefits',
  templateUrl: './review-baseline-benefits.component.html',
  styleUrls: ['review-baseline-benefits.component.scss'],
})
export class ReviewBaselineBenefitsComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() trackerId: string;
  @Input() valueProp: ValueProp;

  benefitTypeList: BenefitType[];
  searchedBenefitList: BenefitType[];
  strSearch = '';
  ngUnsubscribe = new Subject();
  loading = false;

  showBenefitScratchpad = false;
  benefitScratchpad: Metric;

  statusTypes: BasicInfo[];
  filterOptions: MenuItem[];
  currentFilter: string[] = ['Active'];
  benefitOnTheFlyPrivilege: boolean;
  showAddBenefit = false;
  modelsv2 = this.commonService.checkFeature(38);
  solutionId: number;

  constructor(
    public trans: ReviewBaselineBenefitsTranslations,
    private translationService: TranslationsV2Service,
    public bI: BenefitsImplementation,
    private commonService: CommonService,
    private valuePropService: ValuepropService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.getBenefitList(this.valuePropId);

    const privs = sessionStorage.getItem('privileges');
    const privileges = privs.split(',');
    if (privileges.includes('6')) {
      this.benefitOnTheFlyPrivilege = true;
    }
    this.getSolutionId();
    this.bI.loadBenefits$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getBenefitList(this.valuePropId, true);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.statusTypes = [
          { name: this.trans.trans.active.value, id: '1' },
          { name: this.trans.trans.na.value, id: '0' },
          { name: this.trans.trans.soft.value, id: '3' },
        ];
        this.setFilterOptions();
      });
  }

  getBenefitList(valuePropId: string, update = false) {
    if (valuePropId) {
      if (!update) {
        this.loading = true;
      }
      this.valuePropService
        .getValuePropAllBenefitsTurboSummedByCategory(valuePropId, 1, 0, 'value_category', true)
        .pipe(take(1))
        .subscribe((data) => {
          this.benefitTypeList = data.result.benefit_types;
          if (!update) {
            this.searchBenefits();
            this.loading = false;
          } else {
            this.searchBenefits(this.strSearch, true);
            if (this.benefitScratchpad) {
              const benefit = this.searchedBenefitList
                .find((benefitType) => benefitType.id === this.benefitScratchpad.benefit_type_id)
                .vcs.find((vcs) => vcs.id === this.benefitScratchpad.value_category_id)
                .metrics.find((metric) => metric.id === this.benefitScratchpad.id);
              this.benefitScratchpad = benefit as unknown as Metric;
            }
          }
        });
    }
  }

  getSolutionId() {
    this.valuePropService
      .readSolutionList(this.valuePropId)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.length > 0) {
          this.solutionId = Number(response.result[0]['account_solution_id']);
        }
      });
  }

  toggleAddBenefit() {
    this.showAddBenefit = !this.showAddBenefit;
  }

  savedBenefit() {
    this.toggleAddBenefit();
    this.getBenefitList(this.valuePropId);
  }

  toggleBenefitTable(benefitCategory) {
    benefitCategory.expand = !benefitCategory.expand;
  }

  statusChange(benefitId: string, status: string) {
    const payload = {
      value_prop_metric_id: benefitId,
      active: status,
    };
    this.valuePropService
      .updateMetricActive(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(({ result }) => {
        if (result.success) {
          this.notificationService.success(this.trans.trans.changeStatusSuccess.value, false);
          this.searchBenefits(this.strSearch, true);
        }
      });
  }

  searchBenefits(strSearch = '', statusUpdate = false) {
    this.strSearch = strSearch.toLowerCase();
    this.searchedBenefitList = this.benefitTypeList.map((type) => ({
      ...type,
      vcs: type.vcs.map((category) => ({
        ...category,
        expand: statusUpdate ? this.retrieveExpandedStatus(type.id, category.id) : !!strSearch,
        metrics: this.filterSearch(category, strSearch),
      })),
    }));
  }

  openBenefitScratchpad(benefit: Metric) {
    this.showBenefitScratchpad = true;
    this.benefitScratchpad = benefit;
    this.bI.loadBenefits(this.trackerId);
    this.bI.expandBenefit(benefit);
  }

  closeBenefitScratchpad() {
    this.showBenefitScratchpad = false;
    this.benefitScratchpad = null;
  }

  noBenefitGroupToShow(vcs: BenefitVCS[]): boolean {
    return vcs.every((vcs) => vcs.metrics.length === 0);
  }

  displayAllFilter(): void {
    if (this.currentFilter.length < 3) {
      this.currentFilter = ['Active', 'N/A', 'Soft'];
    } else {
      this.currentFilter = [];
    }
    this.setFilterOptions();
    this.searchBenefits(this.strSearch, true);
  }

  private setFilterOptions(): void {
    this.filterOptions = [
      {
        label: this.trans.trans.active.value,
        icon: this.currentFilter.includes('Active') ? 'active' : null,
        command: () => {
          this.setFilter('Active');
        },
      },
      {
        label: this.trans.trans.na.value,
        icon: this.currentFilter.includes('N/A') ? 'active' : null,
        command: () => {
          this.setFilter('N/A');
        },
      },
      {
        label: this.trans.trans.soft.value,
        icon: this.currentFilter.includes('Soft') ? 'active' : null,
        command: () => {
          this.setFilter('Soft');
        },
      },
    ];
  }

  private setFilter(filter: string): void {
    if (this.currentFilter.includes(filter)) {
      const filterIndex = this.currentFilter.indexOf(filter);
      this.currentFilter.splice(filterIndex, 1);
    } else {
      this.currentFilter.push(filter);
    }
    this.setFilterOptions();
    this.searchBenefits(this.strSearch, true);
  }

  private filterSearch(category: BenefitVCS, searchParam: string): BenefitVCSMetric[] {
    return category.metrics.filter((benefit) => benefit.metric_name.toLowerCase().includes(searchParam) && this.checkStatus(benefit.active));
  }

  private checkStatus(activeStatus: string): boolean {
    let correctStatus: boolean;
    switch (activeStatus) {
      case '1':
        correctStatus = this.currentFilter.includes('Active');
        break;
      case '2':
      case '3':
      case '5':
        correctStatus = this.currentFilter.includes('Soft');
        break;
      case '0':
      case '4':
        correctStatus = this.currentFilter.includes('N/A');
        break;
      default:
        correctStatus = false;
        break;
    }
    return correctStatus;
  }

  public onBenefitDetailChanges() {
    this.getBenefitList(this.valuePropId);
  }

  private retrieveExpandedStatus(typeId: string, categoryId: string): boolean {
    return this.searchedBenefitList.find((type) => type.id === typeId)?.vcs.find((category) => category.id === categoryId)?.expand;
  }
}
