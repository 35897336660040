import { RequiredProperties, WalkMeVariables } from "./analytic.interface";

export function setWalkmeVariales(userId: string, variables: RequiredProperties) {
  const win = window as Window & typeof globalThis & WalkMeVariables;
  const { userName, userEmail, account, managerEmail, userGroup, role, activePrivileges } = variables;
  win.user_id = userId;
  win.user_name = userName;
  win.user_email = userEmail;
  win.account = account;
  win.manager_email = managerEmail;
  win.user_group = userGroup;
  win.role = role;
  win.active_privileges = activePrivileges;
}