import { Component, OnInit } from '@angular/core';
import { AppImplementation } from '../../app.implementation';
import { Translations } from '@data/services/translation/translation.service';
import { Observable } from 'rxjs';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-component-system',
	templateUrl: './system.component.html',
	styleUrls: ['./system.component.scss']
})
export class AppSystemComponent implements OnInit {
  style2022$: Observable<boolean>;

	constructor(
		public trans: Translations,
		public aI: AppImplementation,
		private styleService: StyleService
	) {	}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;
	}
}