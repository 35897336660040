<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="'style-2022'"></p-menu>
<p-menu #menuStatus appendTo="body" [popup]="true" [model]="valuePropStatusTypes" [styleClass]="'style-2022'"></p-menu>
<app-choose-adventure-internal
  #chooseAdventure
  (onHidden)="onHidden($event)"
  [ff134]="ff134"
  [showStepSelection]="showStepSelection"
  [workflows]="workflows"
></app-choose-adventure-internal>
<div class="row" style="margin-bottom: 20px">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs *ngIf="breadcrumbKeyValues" [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>
<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header"></div>
    <div class="row">
      <div class="col-xs-12 col-md-3">
        <div id="case-search" class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input
            [(ngModel)]="strSearch"
            (keyup)="tableValuePosition.filter($any($event.target).value, 'name', 'contains')"
            type="text"
            placeholder="{{ trans.trans.search.value }}"
            class="form-control"
          />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableValuePosition.filter('', 'name', 'contains')" class="input-group-addon clickable"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <p-selectButton [options]="sharedWith" (onChange)="loadValueProps()" [(ngModel)]="creator_filter" styleClass="keywest_select_button"></p-selectButton>
      </div>
      <div class="col-xs-12 col-md-5" style="display: flex; justify-content: end;">
        <p-checkbox 
          [binary]="true" 
          class="marg-h" 
          *ngIf="hasUnfinishedVps && viewAll" 
          name="hasUnfinished" 
          [(ngModel)]="includeCalcs"
          [label]="trans.trans.showUnfinished.value"
          (onChange)="loadValueProps()"
        >
        </p-checkbox>
        <a
          class="btn btn_customizable pull-right"
          (click)="onAddHypoClicked('valueprop')"
          [matTooltip]="trans.trans.ch_add_value_prop.value"
          matTooltipPosition="below"
          container="body"
          placement="bottom"
          *ngIf="showAddButton"
        >
          <i class="fa fa-plus"></i>{{ trans.trans.addNew.value }}
        </a>
      </div>
    </div>
  </div>

  <div class="models_page_header_container_small" [ngClass]="{ 'full-height': (tableRecords$ | async) === 0 }">
    <div class="row">
      <div class="col-xs-12" style="max-width: 100%">
        <p-progressBar *ngIf="loading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
        <p-table
          #tableValuePosition
          [columns]="columns"
          [value]="valueProps"
          (sortFunction)="funcSort($event)"
          [customSort]="true"
          [paginator]="true"
          [rows]="pageLimit"
          styleClass="keywest_table"
          tableStyleClass="keywest_table"
          [rowsPerPageOptions]="[10, 25, 50, 100]"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="white-space: nowrap">
                {{
                  trans.trans && trans.trans[col.field]
                    ? trans.trans[col.field].value
                    : col.header
                }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="refreshTranslation()"
                  [key]="col.field"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]"
                >
                </app-editTranslation>
              </th>
              <th style="text-align: center">
                <p-sortIcon (click)="resetSort()" *ngIf="sorted" [matTooltip]="trans.trans.unsort.value" style="cursor: pointer"></p-sortIcon>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-valueprop let-rowIndex="rowIndex">
            <tr>
              <td style="width: 200px" class="link">
                <a *ngIf="!valueprop.is_group" (click)="chooseStep('valueprop', valueprop.id)" class="clickable link">{{ valueprop.name }}</a>
                <a *ngIf="valueprop.is_group" (click)="chooseStep('groupv1', valueprop.id)" class="clickable link">{{ valueprop.name }}</a>
                <span
                  *ngIf="!valueprop.is_group"
                  class="material-icons-outlined tooltip-custom info-icon light"
                  (mouseout)="op.toggle($event); containerMargin = '0px'"
                  (mouseover)="getValuePropDetails(valueprop, op, $event, valueprop.id, rowIndex)"
                >
                  info
                </span>
                <i class="fa fa-plus" *ngIf="valueprop.value_props" (click)="getValuePropGroup(valueprop, group, $event)" [attr.aria-expanded]="valueprop.isCollapsed"></i>

                <ng-template #tipContent class="toolTipContent">
                  <div>
                    <p>
                      <label>{{ trans.trans.name.value }} </label>:&nbsp;
                      <span>{{ valueprop.name }}</span>
                    </p>
                    <p *ngIf="valueprop.solutions.length">
                      <label>{{ trans.trans.models.value }}</label
                      >:&nbsp;
                      <span *ngFor="let elem of valueprop.solutions">{{ elem.name }} </span>
                      <img class="actionLoader" *ngIf="showTooltipActionLoader" [src]="fullImagePath" width="10px" />
                    </p>
                    <p *ngIf="valueprop.year1_benefits_fmt">
                      <label>{{ trans.trans.year1.value }} </label>:&nbsp;
                      <span>{{ valueprop.year1_benefits_fmt }}</span>
                    </p>
                    <p>
                      <label>{{ trans.trans.total_benefits.value }} </label>:&nbsp;
                      <span>{{ valueprop.total_benefits_fmt }}</span>
                    </p>
                    <p *ngIf="valueprop.roi_decorated">
                      <label>{{ trans.trans.roi.value }} </label>:&nbsp;
                      <span>{{ valueprop.roi_decorated }}</span>
                    </p>
                    <p *ngIf="valueprop.payback">
                      <label>{{ trans.trans.payback.value }} </label>:&nbsp;{{ valueprop.payback }}
                      <span>{{ trans.trans.months.value }}</span>
                    </p>
                  </div>
                </ng-template>
              </td>
              <td style="width: 200px">{{ valueprop.company_name }}</td>
              <td>{{ valueprop.models }}</td>
              <td class="preventWordBreak">
                {{ valueprop.total_benefits_fmt }}
              </td>
              <td class="preventWordBreak">
                {{ valueprop.total_costs_fmt }}
              </td>
              <td class="wordBreakNormal">
                {{ valueprop.vp_creator_name }}
              </td>
              <td style="white-space: nowrap">{{ valueprop.created | safariDate | date: 'MMM d, y, HH:mm' }}</td>

              <td>
                <div (click)="getValuePropStatusTypes(valueprop); menuStatus.toggle($event)" class="status-wrapper">
                  <span class="prop-status">{{ valueprop.status }} </span>
                  <span class="material-icons-outlined dropdown-icon">arrow_drop_down</span>
                </div>
              </td>
              <td>
                <app-ellipsis-menu>
                  <ul class="list-group">
                    <!-- Share -->
                    <li class="list-group-item" (click)="selectedVP = valueprop; toggleShare = !toggleShare">
                      <span style="font-size: 13px; margin-right: 2px" class="material-icons"> share </span>{{ trans.trans.share.value }}
                    </li>
                    <!-- Clone -->
                    <li class="list-group-item" (click)="selectedVP = valueprop; showClone = !showClone">
                      <span style="font-size: 13px; margin-right: 2px" class="material-icons-outlined"> file_copy </span>{{ trans.trans.clone.value }}
                    </li>
                    <!-- Delete -->
                    <li *ngIf="valueprop | functionPipe: checkDeletePermission:{ priv1, priv12 }" class="list-group-item">
                      <app-are-you-sure
                        style="color: red"
                        (callbackYes)="deleteValueProp(valueprop.id)"
                        [text]="trans.trans.Delete.value"
                        icon="fa fa-trash"
                        [message]="trans.trans.areYouSure.value"
                      ></app-are-you-sure>
                    </li>
                  </ul>
                </app-ellipsis-menu>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading" class="not-hover">
              <td [attr.colspan]="columns.length + 1" style="padding-top: 100px">
                <app-no-records width="200px" [records]="valueProps.length > 0">
                  <ng-container *ngIf="valueProps.length === 0">
                    {{ trans.trans.noRecords.value }}
                  </ng-container>
                  <ng-container *ngIf="valueProps.length > 0"> {{ trans.trans.noResults.value }} "{{ strSearch }}" </ng-container>
                </app-no-records>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<p-sidebar *ngIf="toggleShare" [(visible)]="toggleShare" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-share-v2
    [fromRepSimple]="false"
    [embedded]="false"
    (callbackBack)="toggleShare = !toggleShare"
    [showTranslate]="showTranslate"
    [data]="selectedVP"
    (callback)="toggleShare = false"
  ></app-share-v2>
</p-sidebar>

<p-sidebar *ngIf="showClone" [(visible)]="showClone" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <app-vp-clone [valueProp]="selectedVP" (callbackCancel)="showClone = !showClone"></app-vp-clone>
</p-sidebar>

<p-sidebar [(visible)]="showRouteOptions" *ngIf="showRouteOptions" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ trans.trans.addVPOptionTitle.value }}
    </div>
    <div class="sidebar_container" *ngIf="rtOptionFeature">
      <div class="keywest_panel">
        <div class="controls radio-control">
          <label>
            <input type="radio" name="controloption" [(ngModel)]="rtOption" value="/repv2" id="rep" />&nbsp;&nbsp;
            <span>{{ trans.trans.stepByStep.value }}</span>
          </label>
          <label>
            <input type="radio" name="controloption" [(ngModel)]="rtOption" value="addvalueprop" id="traditional" />&nbsp;&nbsp;
            <span>{{ trans.trans.traditionalCreate.value }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="btn_container">
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn_customizable pull-right" (click)="optionsNavigate()">Create</button>
      </div>
    </div>
  </div>
</p-sidebar>

<p-overlayPanel #group [dismissable]="true" appendTo="body" [showCloseIcon]="true" styleClass="keywest_tooltip_overlay">
  <div *ngIf="valueposition">
    <div class="row" *ngFor="let v of valueposition.value_props">
      <div class="col-xs-12">
        <a routerLink="/valueposition/{{ v.id }}" class="link">{{ v.name }}</a>
      </div>
    </div>
  </div>
</p-overlayPanel>

<ng-template #addValuePositionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.chooseOptionToContinue.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body valuePositionModal">
    <div class="control-group m-b-sm">
      <div class="controls radio-control">
        <label *ngIf="!masterValuePropFeature">
          <input type="radio" name="controloption" value="quickcreate" id="quickcreate" checked />&nbsp;&nbsp;
          <span *ngIf="accountID !== '20'">{{ trans.trans.quickCreate.value }}</span>
          <span *ngIf="accountID === '20'">{{ trans.trans.typical.value }}</span>
        </label>
        <label *ngIf="masterValuePropFeature">
          <input type="radio" name="controloption" value="masterValueProp" id="masterValueProp" />&nbsp;&nbsp;
          <span>{{ trans.trans.masterValueProp.value }}</span>
        </label>
        <label *ngIf="guidedDiscoveryFeature">
          <input type="radio" name="controloption" value="guideddiscovery" id="guideddiscovery" />&nbsp;&nbsp;
          <span>{{ trans.trans.guidedDiscovery.value }}</span>
        </label>
        <label *ngIf="dealdeskFeature">
          <input type="radio" name="controloption" value="dealdesk" id="dealdesk" />&nbsp;&nbsp;
          <span *ngIf="accountID !== '20'">{{ trans.trans.dealdesk.value }}</span>
          <span *ngIf="accountID === '20'">{{ trans.trans.custom.value }}</span>
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="selectOptions()">
      {{ trans.trans.start.value }}
    </button>
  </div>
</ng-template>

<p-overlayPanel #op appendTo="body" styleClass="keywest_tooltip_overlay_wide">
  <p-progressBar *ngIf="loadingVPDetails" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
  <div *ngIf="valueposition && !loadingVPDetails">
    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Account:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.company_name }}</span>
      </div>
    </div>
    <div class="row" *ngIf="valueposition.solutions && valueposition.solutions.length" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">{{ trans.trans.models.value }}:</label>
      </div>
      <div class="col-xs-7">
        <div *ngFor="let elem of valueposition.solutions">{{ elem.name }}<br /></div>
      </div>
    </div>

    <div class="row" *ngIf="feature22" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Opportunity ID:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.opportunity_id }}</span>
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Status:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.status }}</span>
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">{{ trans.trans.total_benefits.value }}:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.total_benefits_fmt }}</span>
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Total Cost:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.total_costs_fmt }}</span>
      </div>
    </div>

    <div class="row" *ngIf="valueposition.roi_decorated" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">{{ trans.trans.roi.value }}:</label>
      </div>
      <div class="col-xs-7">
        {{ valueposition.roi_decorated }}
      </div>
    </div>

    <div class="row" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">Last Modified:</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.modified }}</span>
      </div>
    </div>

    <div class="row" *ngIf="valueposition.payback" style="clear: both; margin-bottom: 10px">
      <div class="col-xs-5">
        <label class="sidebar_label_readonly">{{ trans.trans.payback.value }}:</label>
      </div>
      <div class="col-xs-7">{{ valueposition.payback }}<span>months</span></div>
    </div>
  </div>
</p-overlayPanel>
