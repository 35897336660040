import { Component, EventEmitter, HostBinding, Input, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DroppedFile } from '@shared/models/dropped-file.model';

@Component({
  selector: 'app-dl-dragdrop',
  templateUrl: 'upload.component.html',
  styleUrls: ['upload.component.scss'],
})
export class DLDragDropComponent {
  @Output() callback = new EventEmitter<DroppedFile[]>();
  @Output() callbackDelete = new EventEmitter<any>();
  @Output() callbackInvalidSize = new EventEmitter<string>();
  @Output() callbackInvalidType = new EventEmitter<string>();

  @Input() title = '';
  @Input() msg = '';
  @Input() canEdit = false;
  @Input() maxSizeKB = 3000;
  @Input() validTypes: string[] = ['image/jpeg', 'image/png', 'application/pdf'];
  @Input() useDark = false;
  @Input() multiple = true;

  @HostBinding('style.background-color') background = '#f5fcff';
  @HostBinding('style.opacity') opacity = '1';

  @Input() files: DroppedFile[] = [];
  @Input() set reset(update: boolean) {
    if (!update) {
      return;
    }
    const input = document.getElementById('fileUploader') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
    this.files = [];
  }

  constructor(private sanitizer: DomSanitizer) {}

  cols: { field: string; header: string }[] = [
    { field: 'name', header: 'Name' },
    { field: 'type', header: 'Type' },
    { field: 'size', header: 'Size' },
  ];

  updateDesc(): void {
    this.callback.emit(this.files);
  }

  uploadFile(event: DroppedFile[]): void {
    for (let i = 0; i < event.length; i++) {
      event[i].on_server = 0;
      if (!this.validTypes.includes(event[i].type)) {
        this.callbackInvalidType.emit(event[i].name);
      } else if (!(event[i].size / 1000 <= this.maxSizeKB)) {
        this.callbackInvalidSize.emit(event[i].name);
      } else {
        event[i].image_name = event[i].name;
        event[i].image_size = this.humanFileSize(event[i].size);
        const preview = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(URL.createObjectURL(event[i])));
        event[i].path = preview;
        this.files.push(event[i]);
      }
    }
    this.callback.emit(this.files);
  }

  imageType(type: string): string {
    if (type === 'image/png') {
      return '/assets/images/png.png';
    }
    if (type === 'image/jpeg') {
      return '/assets/images/jpg.png';
    }
  }

  humanFileSize(bytes: number, si = false): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  funcDeleteFileServer(index: number, file: DroppedFile): void {
    this.files.splice(index, 1);
    this.callbackDelete.emit(file);
  }
}
