import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AddVPCompanyComponent } from './add-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldsetModule } from 'primeng/fieldset';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { DefaultTranslations } from './add-company.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';


export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}


export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	imports: [
		FieldsetModule,
		NgbModule,
		CommonModule,
    MatTooltipModule,
		FormsModule,
		EditTranslationsModule	
	],
	declarations: [
		AddVPCompanyComponent
	],
	providers: [DefaultTranslations, TranslationsV2Service],
	entryComponents: [AddVPCompanyComponent],
	exports: [AddVPCompanyComponent]
})
export class AddVPCompanyModule { }
