import { BenefitElement } from './../benefits.model';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { Benefit } from '@shared/models/value-prop.model';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, ValidatorFn } from '@angular/forms';
import { BenefitTypesService } from '@data/services/benefit-types/benefit-types.service';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { DroppedFile } from '@shared/models/dropped-file.model';
import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import { finalize, map, pluck, switchMap, take, takeUntil } from 'rxjs/operators';
import { AEBenefitsTranslations } from './ae_benefits.translation';
import { snakeCase } from 'lodash';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';

import { cloneDeep } from 'lodash';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';
import { AccrualTypeId } from '@data/services/valueprop/models/cost.model';
import { ErrorMessagesService } from '@services/error-messages.service';
import { formatNumber } from '@angular/common';
import { CustomValidators } from 'app/_utils/custom-validators';
import { BenefitsImplementation } from 'app/value-prop/value-prop-dashboard/benefits_v2/benefits.implementation';

@Component({
  selector: 'app-ae-benefits',
  templateUrl: './ae_benefits.component.html',
  styleUrls: ['./ae_benefits.component.scss'],
})
export class AEBenefitsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() solutionId: string | number;
  @Input() selectedBenefit: Benefit;
  @Input() default_term = '3';
  @Input() mode: 'add' | 'edit' = 'add';
  @Input() can_edit = false;
  @Input() isOnTheFlyBenefit = false;
  @Input() valuePropId: string;
  @Input() surveyFields: { [klass: string]: any };
  @Input() categoryFlyId: number | string;

  @Output() callback = new EventEmitter();
  @Output() reloadVP = new EventEmitter();
  @Output() cancelBenefitCallback = new EventEmitter();
  @ViewChild('addBenefitsForm') addBenefitsForm: NgForm;
  sidebar_title = 'Create new benefit';

  ngUnsubscribe = new Subject();

  isDev = this.commonService.isDev$;
  hasFeature46 = this.commonService.checkFeature(46);
  hasFeature84 = this.commonService.checkFeature(84);
  featureNonFinancial = this.commonService.checkFeature(94);
  featureRealizationName = this.commonService.checkFeature(103);
  softFeature = this.commonService.checkFeature(111);
  account_id = sessionStorage.getItem('aid');
  masterValuePropFeature = this.commonService.checkFeature(15);
  accountFeatures = this.commonService.checkFeature(14);

  impactAreaToggle = false;
  painPointToggle = false;
  capabilitiesToggle = false;
  opObjectivesToggle = false;

  driver_term: { [klass: string]: any }[] = [];
  phasingTermGroup = this.fb.group({ phasingTermArray: new FormArray([]) });

  get phasingTermArray() {
    return this.phasingTermGroup.get('phasingTermArray') as FormArray;
  }

  dropdownValues: { [klass: string]: any };
  dropdownValues$: Observable<{ [klass: string]: any }>;
  benefitOntheFlyDropdownValues$: Observable<{ [klass: string]: any }>;

  dropdownValuesLoading = false;
  files: DroppedFile[] = [];
  hardSoftDropdown = [
    { value: 0, label: 'Hard' },
    { value: 1, label: 'Soft' },
    { value: 2, label: 'Strategic' },
  ];

  addDriver = false;
  addImprovement = false;
  addFinancial = false;

  scratchpadPropertyToEdit: string;
  showFactor = false;
  editFactor = false;
  selectedFactor: Factor;
  showTranslate = false;

  factorFields: { [factorKey: string]: string[] } = {
    driver: ['driver_baseline_answer'],
    improvement: ['conservative', 'probable', 'aggressive'],
    financial: ['financial_baseline_answer'],
  };
  @Input() selectedElem: BenefitElement = {
    metric_name: '',
    value_realization_name: '',
    value_category_id: '',
    description: '',
    impact_type_id: '',
    accrual_type_id: '',
    scale_type_id: '',
    unique_type_id: undefined,
    default_na: undefined,
    driver_toggle: true,
    driver_factor_id: '2',
    driver_baseline_answer: '1',
    improvement_toggle: true,
    improvement_factor_id: '',
    unit_type_id: '',
    current_effort: '',
    conservative: '',
    probable: '',
    aggressive: '',
    financial_toggle: true,
    financial_factor_id: '2',
    financial_baseline_answer: '1',
    optional_toggle: '',
    tco_name: '',
    value_statement: '',
    features: '',
    account_functional_objective_id: '',
    project_id: '',
    expense_type_id: '',
    area_type_id: '',
    account_painpoint_id: '',
    account_capability_id: '',
    account_operational_objective_id: '',
    benefit_type_id: 1,
    account_solution_id: '',
    has_phasing: false,
    term0: '0',
    term1: '0',
    term2: '0',
    term3: '0',
    term4: '0',
    term5: '0',
    term6: '0',
    term7: '0',
    term8: '0',
    term9: '0',
    term_val0: '',
    term_val1: '',
    term_val2: '',
    term_val3: '',
    term_val4: '',
    term_val5: '',
    term_val6: '',
    term_val7: '',
    term_val8: '',
    term_val9: '',
    selectedFuncObjectives: '',
    selectedPainPoints: '',
    areas: undefined,
    painPoints: undefined,
    capabilities: undefined,
    opObjectives: undefined,
    phase0: 100,
    phase1: 100,
    phase2: 100,
    phase3: 100,
    phase4: 100,
    course_correction: '',
    quantified: '1',
    soft: '0',
    cumulative_bens: 0,
    valueTag: null,
    nameTag: null,
    has_tag: false,
  };
  toggleDebug = false;
  accrualTypes = ['', 'oneTime', 'annual', '', '', '', '', '', 'max100'];
  errorDict$ = this.errorMessagesService.formGroupObsFactoryCrossField(this.phasingTermGroup);

  constructor(
    private solutionService: SolutionService,
    private notificationService: NotificationService,
    private benefitTypeService: BenefitTypesService,
    public trans: AEBenefitsTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private customAssetTagService: CustomAssetTagService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private errorMessagesService: ErrorMessagesService,
    private bI: BenefitsImplementation
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.createBenefitOnTheFlyDropDownObservable();
    this.createDropdownObservable();
    this.editPhasingTerms();
    this.getDropdownValues();
  }

  ngAfterViewInit() {
    this.setFormValue();
    this.cdr.detectChanges();
  }

  createDropdownObservable() {
    const payload = {
      driver_factor_type_id: '0',
      account_solution_id: this.solutionId,
      exclude_dns: '0',
    };
    this.dropdownValues$ = iif(
      () => this.isOnTheFlyBenefit,
      this.benefitOntheFlyDropdownValues$,
      combineLatest([
        this.solutionService.getBenefitsDropdownAggregate(this.account_id, payload),
        this.benefitTypeService.getBenefitTypes().pipe(
          pluck('result', 'data'),
          map((types) => types.concat({ name: 'Financial', id: 1 }))
        ),
      ]).pipe(
        map(([dropdownItems, benefitQuantificationTypes]) => ({
          result: {
            ...dropdownItems.result,
            benefitQuantificationTypes,
          },
        }))
      )
    );
  }

  createBenefitOnTheFlyDropDownObservable() {
    const payload = {
      driver_factor_type_id: '0',
      account_solution_id: this.solutionId,
      exclude_dns: '0',
    };

    this.benefitOntheFlyDropdownValues$ = combineLatest([
      this.solutionService.getBenefitsDropdownAggregate(this.account_id, payload),
      this.benefitTypeService.getBenefitTypes().pipe(
        pluck('result', 'data'),
        map((types) => types.concat({ name: 'Financial', id: 1 }))
      ),
    ]).pipe(
      map(([dropdowns, benefitQuantificationTypes]) => ({
        result: {
          ...dropdowns.result,
          benefitQuantificationTypes,
          readAccountCapabilities: dropdowns.result.readAccountCapabilities,
          readAccountFunctionalObjectives: dropdowns.result.readAccountFunctionalObjectives,
          readPainPoints: dropdowns.result.readPainPoints,
          readAccountOperationalObjectives: dropdowns.result.readAccountOperationalObjectives,
        },
      }))
    );
  }

  setFormValue() {
    this.selectedElem.driver_toggle = true;
    this.selectedElem.improvement_toggle = true;
    this.selectedElem.financial_toggle = true;

    if (this.mode === 'edit') {
      this.selectedElem.default_na = Number(this.selectedElem.default_na) === 1;
      this.selectedElem.unique_type_id = Number(this.selectedElem.unique_type_id) === 1;
      this.selectedElem.quantified = Number(this.selectedElem.quantified) === 1;
      this.selectedElem.soft = Number(this.selectedElem.soft) === 1;
      this.selectedElem.cumulative_bens = Number(this.selectedElem.cumulative_bens) === 1;
      if (this.hasFeature46) {
        if (this.hardSoftDropdown && this.hardSoftDropdown.length) {
          this.selectedBenefit.default_na = this.hardSoftDropdown.find((val) => val.value === this.selectedElem.default_na);
        } else {
          return [];
        }
      }

      this.files = this.selectedElem.images;

      if (
        this.selectedElem.tco_name ||
        this.selectedElem.value_statement ||
        this.selectedElem.features ||
        this.selectedElem.selectedFuncObjectives ||
        this.selectedElem.expense_type_id ||
        this.selectedElem.situations
      ) {
        this.selectedElem.optional_toggle = true;
      }

      const isOneTimeAccrual = this.selectedElem.accrual_type_id && (+this.selectedElem.accrual_type_id === 1 || +this.selectedElem.accrual_type_id.id === 1);
      const isAnnualAccrual = this.selectedElem.accrual_type_id && (+this.selectedElem.accrual_type_id === 2 || +this.selectedElem.accrual_type_id.id === 2);
      const isMax100 = +this.selectedElem.accrual_type_id === 8 || +this.selectedElem.accrual_type_id.id === 8;
      if (isAnnualAccrual || isMax100) {
        this.editPhasingTerms();
      }

      for (let i = 0; i < (isOneTimeAccrual ? 1 : +this.default_term + 1); i++) {
        this.driver_term[i].val = this.selectedElem['driver_phase_' + (i + 1)];
        if (this.selectedElem['phase_' + i]) {
          this.phasingTermArray.at(i).setValue({ val: this.selectedElem['phase_' + i] });
        }
      }

      if (this.isOnTheFlyBenefit) {
        this.selectedElem.conservative = this.selectedElem.impact_conservative;
        this.selectedElem.probable = this.selectedElem.impact_probable;
        this.selectedElem.aggressive = this.selectedElem.impact_aggressive;

        this.selectedElem.areas = this.selectedElem.area_types;

        for (let i = 0; i < +this.default_term + 1; i++) {
          this.phasingTermArray.at(i).setValue({ val: this.selectedElem['phase_' + i] });
        }
      }
    }
    if (this.selectedElem.has_tag) {
      this.selectedElem.nameTag = this.selectedElem.tag_data.name;
      this.selectedElem.valueTag = this.selectedElem.tag;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  cancel(): void {
    this.cancelBenefitCallback.emit();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.sidebar_title = this.mode === 'add' ? this.trans.trans.addBenefit.value : this.trans.trans.editBenefit.value;
      });
  }

  switchChanged(event, val) {
    val = event.checked;
  }

  funcSetFactor(field, val) {
    this.selectedElem[field] = val.toString();
  }

  getDropdownValues() {
    this.dropdownValuesLoading = true;
    this.dropdownValues = undefined;
    this.dropdownValues$
      .pipe(
        take(1),
        finalize(() => {
          if (this.mode !== 'edit') {
            this.dropdownValuesLoading = false;
          }
        })
      )
      .subscribe((res) => {
        this.dropdownValues = res.result;

        if (this.mode === 'edit') {
          setTimeout(() => {
            this.funcPopulateDropdowns();
          }, 0);
        } else {
          this.selectedElem.benefit_type_id = this.selectedElem.benefit_type_id ? this.selectedElem.benefit_type_id : this.funcFindObject('benefitTypes', 'id', '1');
          if (this.isOnTheFlyBenefit && this.categoryFlyId) {
            this.selectedElem.value_category_id = this.funcFindObject('value_categories', 'value_category_id', this.categoryFlyId);
          }
        }

        this.selectedElem.driver_factor_id = this.funcFindObject(
          'readAccountFactorCategoriesType',
          'id',
          this.selectedElem.driver_factor_id?.id || this.selectedElem.driver_factor_id
        );
        this.selectedElem.improvement_factor_id = this.funcFindObject(
          'readAccountFactorCategoriesType',
          'id',
          this.selectedElem.improvement_factor_id?.id || this.selectedElem.improvement_factor_id
        );
        this.selectedElem.financial_factor_id = this.funcFindObject(
          'readAccountFactorCategoriesType',
          'id',
          this.selectedElem.financial_factor_id?.id || this.selectedElem.financial_factor_id
        );
      });
  }

  translateAccrualType(accrual_type_id: AccrualTypeId, args: { trans: any; accrualTypes: string[] }) {
    return args.trans.trans[args.accrualTypes[accrual_type_id]].value
      ? args.trans.trans[args.accrualTypes[accrual_type_id]].value
      : args.trans.trans[args.accrualTypes[accrual_type_id]];
  }

  funcPopulateDropdowns() {
    this.selectedElem.value_category_id = this.funcFindObject(
      'value_categories',
      'value_category_id',
      this.selectedElem.value_category_id.value_category_id ? this.selectedElem.value_category_id.value_category_id : this.selectedElem.value_category_id
    );
    this.selectedElem.impact_type_id = this.funcFindObject(
      'readImpactTypes',
      'id',
      this.selectedElem.impact_type_id.id ? this.selectedElem.impact_type_id.id : this.selectedElem.impact_type_id
    );
    this.selectedElem.accrual_type_id = this.funcFindObject(
      'readAccrualTypes',
      'id',
      this.selectedElem.accrual_type_id.id ? this.selectedElem.accrual_type_id.id : this.selectedElem.accrual_type_id
    );
 

    this.selectedElem.driver_factor_id = this.funcFindObject(
      'readAccountFactorCategoriesType',
      'id',
      this.selectedElem.driver_factor_id?.id ? this.selectedElem.driver_factor_id.id : this.selectedElem.driver_factor_id
    );


    this.selectedElem.improvement_factor_id = this.funcFindObject(
      'readAccountFactorCategoriesType',
      'id',
      this.selectedElem.improvement_factor_id?.id ? this.selectedElem.improvement_factor_id.id : this.selectedElem.improvement_factor_id
    );
    this.selectedElem.unit_type_id = this.funcFindObject(
      'readUnitTypes',
      'id',
      this.selectedElem.unit_type_id.id ? this.selectedElem.unit_type_id.id : this.selectedElem.unit_type_id
    );
    this.selectedElem.financial_factor_id = this.funcFindObject(
      'readAccountFactorCategoriesType',
      'id',
      this.selectedElem.financial_factor_id?.id ? this.selectedElem.financial_factor_id.id : this.selectedElem.financial_factor_id
    );

    this.selectedElem.expense_type_id = this.funcFindObject(
      'expense',
      'id',
      this.selectedElem.expense_type_id && this.selectedElem.expense_type_id.id ? this.selectedElem.expense_type_id.id : this.selectedElem.expense_type_id
    );
    this.selectedElem.benefit_type_id = this.funcFindObject(
      'benefitTypes',
      'id',
      this.selectedElem.benefit_type_id && this.selectedElem.benefit_type_id.id ? this.selectedElem.benefit_type_id.id : this.selectedElem.benefit_type_id
    );

    if ( this.selectedElem.functional_objectives && this.selectedElem.functional_objectives.length > 0) {
      this.selectedElem.functional_objectives = this.funcFindObject(
        'readAccountFunctionalObjectives',
        'id',
        this.selectedElem.functional_objectives[0].account_functional_objective_id
      );
    } else if (this.selectedElem.functional_objectives && this.selectedElem.functional_objectives.id) {
      this.selectedElem.functional_objectives = this.funcFindObject('readAccountFunctionalObjectives', 'id', this.selectedElem.functional_objectives.id);
    } else {
      this.selectedElem.functional_objectives = [];
    }
    this.dropdownValuesLoading = false;
  }

  funcFindObject(dropdown, left, right) {
    if (this.dropdownValues && this.dropdownValues[dropdown] && this.dropdownValues[dropdown].length) {
      if (dropdown == 'readAccountFactorCategoriesType') {
        for (let p of this.dropdownValues[dropdown]) {
          for (let i of p.factors) {
            if (i.id === right) {
              return i;
            }
          }
        }
      } else {
        return this.dropdownValues[dropdown].find((x) => x[left] == right);
      }
    } else {
        return [];
    }
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element);
    }
  }

  funcGetSelectedDropdownElem(arr: [], param, val) {
    let out = [];
    if (arr && arr.length) {
      out = arr.find((x) => x[param] === val);
    }

    return out;
  }

  editPhasingTerms() {
    this.driver_term = [];
    this.phasingTermArray.clear();
    const isOneTimeAccrual = this.selectedElem.accrual_type_id && (+this.selectedElem.accrual_type_id === 1 || +this.selectedElem.accrual_type_id.id === 1);
    const defaultTerm = isOneTimeAccrual ? 1 : Number(this.default_term);
    const isMax100 = this.selectedElem.accrual_type_id && (+this.selectedElem.accrual_type_id === 8 || +this.selectedElem.accrual_type_id.id === 8);

    for (let i = 0; i < defaultTerm + 1; i++) {
      this.driver_term.push({ val: 0 });
      this.phasingTermArray.push(this.fb.group({ val: this.resolvePhasingTermValue(i, isMax100) }));
    }
    if (isMax100) {
      this.phasingTermGroup.setValidators([CustomValidators.maxTotalValueValidator(100)]);
    } else {
      this.phasingTermGroup.clearValidators();
    }
  }

  resolvePhasingTermValue(i: number, isMax100: boolean) {
    if (i === 0) {
      return 0;
    } else if (i === 2 && isMax100) {
      return 0;
    } else if (i === 1 || !isMax100) {
      return 100;
    } else {
      return 0;
    }
  }

  funcParseList(val, field): string {
    const arr = [];
    if (val) {
      for (let i = 0; i < val.length; i++) {
        arr.push(val[i][field] ?? val[i]['id']);
      }
      return arr.join(',');
    } else {
      return '';
    }
  }

  saveBenefits(form: NgForm, elem) {
    const improvement_factor_id = form.controls.improvement_factor_id ? form.controls.improvement_factor_id.value.id : '';

    const benfitsObj = {
      metric_name: form.controls.metric_name.value,
      value_category_id: form.controls.value_category_id.value ? parseInt(form.controls.value_category_id.value.value_category_id) : '',
      description: form.controls.description ? form.controls.description.value : '',
      impact_type_id: form.controls.impact_type_id.value ? parseInt(form.controls.impact_type_id.value.id) : '',
      accrual_type_id: parseInt(form.controls.accrual_type_id ? form.controls.accrual_type_id.value.id : ''),
      value_realization_name: form.controls.value_realization_name ? form.controls.value_realization_name.value : '',

      driver_factor_id: form.controls.driver_factor_id.value.id ? parseInt(form.controls.driver_factor_id.value.id) : 2,
      driver_baseline_answer: form.controls.driver_baseline_answer && form.controls.driver_baseline_answer.value ? form.controls.driver_baseline_answer.value : '1',

      improvement_factor_id: improvement_factor_id,
      unit_type_id: form.controls.unit_type_id ? parseInt(form.controls.unit_type_id.value.id) : '',
      current_effort: form.controls.current_effort ? form.controls.current_effort.value : '',
      conservative: form.controls.conservative && improvement_factor_id !== 2 ? form.controls.conservative.value : '',
      probable: form.controls.probable && improvement_factor_id !== 2 ? form.controls.probable.value : '',
      aggressive: form.controls.aggressive && improvement_factor_id !== 2 ? form.controls.aggressive.value : '',

      financial_factor_id: form.controls.financial_factor_id.value.id ? parseInt(form.controls.financial_factor_id.value.id) : 2,
      financial_baseline_answer: form.controls.financial_baseline_answer && form.controls.financial_baseline_answer.value ? form.controls.financial_baseline_answer.value : '1',

      tco_name: form.controls.tco_name ? form.controls.tco_name.value : '',
      value_statement: form.controls.value_statement ? form.controls.value_statement.value : '',
      features: form.controls.features ? form.controls.features.value : '',
      account_functional_objective_id: form.controls.functional_objectives && form.controls.functional_objectives.value ? form.controls.functional_objectives.value.id : '',
      project_id: form.controls.project_id ? form.controls.project_id.value : '',
      expense_type_id: form.controls.expense_type_id && form.controls.expense_type_id.value ? form.controls.expense_type_id.value.id : '',

      area_type_id: this.funcParseList(this.selectedElem.areas, 'area_type_id'),
      account_painpoint_id: this.funcParseList(this.selectedElem.pain_points, 'account_painpoint_id'),
      account_capability_id: this.funcParseList(this.selectedElem.capabilities, 'account_capability_id'),
      account_operational_objective_id: this.funcParseList(this.selectedElem.operational_objectives, 'account_operational_objective_id'),
      account_solution_id: +this.solutionId,
      benefit_type_id: form.controls.benefit_type_id.value ? form.controls.benefit_type_id.value.id : 1,
      course_correction: form.controls.course_correction ? form.controls.course_correction.value : '',
      cumulative_bens: this.selectedElem.cumulative_bens ? 1 : 0,
      quantified: 1,
    };
    if (this.hasFeature84) {
      benfitsObj['metric_note_2'] = form.controls.metric_note_2 ? form.controls.metric_note_2.value : '';
      benfitsObj['metric_note_3'] = form.controls.metric_note_3 ? form.controls.metric_note_3.value : '';
    }

    if (this.hasFeature46) {
      benfitsObj['default_na'] = form.controls.default_na.value.value;
    } else {
      benfitsObj['default_na'] = form.controls.default_na.value === true ? 1 : 0;
      benfitsObj['unique_type_id'] = form.controls.unique_type_id && form.controls.unique_type_id.value === true ? 1 : this.isOnTheFlyBenefit ? 1 : 3;
    }

    if (this.softFeature) {
      benfitsObj['soft'] = form.controls.soft.value ? 1 : 0;
    }

    if (form.controls.has_phasing && form.controls.has_phasing.value) {
      for (let i = 0; i < this.driver_term.length; i++) {
        benfitsObj['driver_phase_' + (i + 1)] = this.driver_term[i].val;
      }
    } else {
      for (let i = 0; i < this.driver_term.length; i++) {
        benfitsObj['driver_phase_' + (i + 1)] = 0;
      }
    }

    this.phasingTermArray.controls.forEach((control, index) => {
      benfitsObj['phase_' + index] = control.value.val;
    });

    if (this.surveyFields) {
      benfitsObj['value_survey_id'] = this.surveyFields;
      benfitsObj['value_survey_benefit'] = 1;
    }

    if (this.mode === 'add') {
      if (this.isOnTheFlyBenefit) {
        this.saveBenefitOnTheFly(benfitsObj, elem);
      } else {
        this.saveBenefit(benfitsObj, elem);
      }
    } else {
      benfitsObj['account_solution_metric_id'] = this.selectedElem['id'];
      benfitsObj['sequence'] = this.selectedElem['sequence'];
      if (this.isOnTheFlyBenefit) {
        this.updateBenefitOnTheFly(benfitsObj, elem);
      } else {
        this.updateBenefit(benfitsObj, elem);
      }
    }
  }

  saveBenefit(benfitsObj, elem) {
    this.solutionService
      .saveSolutionBenefits(benfitsObj)
      .pipe(
        switchMap((benefitRes) => {
          if (this.selectedElem.has_tag) {
            const payload = this.customAssetTagService.createTagPayload(benefitRes.result.id, this.selectedElem.nameTag, this.selectedElem.valueTag, AssetTagArray.Benefits, true);
            return this.customAssetTagService.createAssetTag(payload).pipe(map(() => benefitRes));
          } else {
            return of(benefitRes);
          }
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((res) => {
        const { id } = res.result;

        if (id) {
          if (this.files && this.files.length) {
            for (let i = 0; i < this.files.length; i++) {
              const out: FormData = new FormData();
              const elem = this.files[i];
              const elem_fl = this.files[i];
              out.append('file', elem_fl);
              out.append('description', elem['description']);

              this.solutionService.postFiles(out, id).subscribe(() => {
                this.notify('benefitAdded');
                this.valuepropService.refreshBenefitDetail.next();
                this.callback.emit('');
              });
            }
          } else {
            this.notify('benefitAdded');
            this.valuepropService.refreshBenefitDetail.next();
            this.callback.emit('');
          }
        } else {
          this.notify('saveFailed', 'error');
          elem.funcCancel();
        }
      });
  }

  updateBenefit(benfitsObj, elem) {
    this.solutionService
      .updateSolutionBenefits(this.solutionId, benfitsObj)
      .pipe(
        pluck('result', 'res'),
        switchMap((benefitData) => {
          if (this.selectedElem.has_tag) {
            const assetId = this.selectedElem.tag_data?.id;
            const payload = this.customAssetTagService.createTagPayload(
              this.selectedElem.id,
              this.selectedElem.nameTag,
              this.selectedElem.valueTag,
              AssetTagArray.Benefits,
              true,
              assetId
            );
            return this.customAssetTagService.editAssetTag(payload).pipe(map(() => benefitData.account_solution_id));
          } else {
            return of(benefitData.account_solution_id);
          }
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((factorId) => {
        if (factorId) {
          if (this.files && this.files.length) {
            for (let i = 0; i < this.files.length; i++) {
              const out: FormData = new FormData();
              const elem = this.files[i];

              const elem_fl = this.files[i];
              out.append('file', elem_fl);
              out.append('description', elem['description']);
              if (!elem.on_server) {
                elem.on_server = 0;
              }
              if (elem.on_server === 0) {
                this.solutionService.postFiles(out, this.selectedElem.id).subscribe(() => {
                  this.notify('benefitUpdated');
                  this.valuepropService.refreshBenefitDetail.next();
                  this.callback.emit('');
                });
              } else {
                const payload = { description: elem.description };
                this.solutionService.putFiles(payload, elem.id).subscribe(() => {
                  this.notify('benefitUpdated');
                  this.valuepropService.refreshBenefitDetail.next();
                  this.callback.emit('');
                });
              }
            }
          } else {
            this.notify('benefitUpdated');
            this.valuepropService.refreshBenefitDetail.next();
            this.callback.emit('');
          }
        } else {
          this.notify('saveFailed', 'error');
          elem.funcCancel();
        }
      });
  }

  saveBenefitOnTheFly(benfitsObj, elem) {
    benfitsObj['value_prop_id'] = parseInt(this.valuePropId);

    this.valuepropService.saveValuePropsBenefits(this.valuePropId, benfitsObj).subscribe((response) => {
      if (response.result) {
        if (this.files && this.files.length) {
          for (let i = 0; i < this.files.length; i++) {
            const out: FormData = new FormData();
            const elem = this.files[i];
            const elem_fl = this.files[i];
            out.append('file', elem_fl);
            out.append('description', elem['description']);

            this.solutionService.postFiles(out, response.result.id).subscribe(() => {
              this.notify('benefitAdded');
              this.valuepropService.refreshBenefitDetail.next();
              this.bI.firstLoad = true;
              this.reloadVP.emit('');
              this.callback.emit('');
            });
          }
        } else {
          this.notify('benefitAdded');
          this.valuepropService.refreshBenefitDetail.next();
          this.bI.firstLoad = true;
          this.reloadVP.emit('');
          this.callback.emit('');
        }
      } else {
        this.notify('saveFailed', 'error');
        elem.funcCancel();
      }
    });
  }

  updateBenefitOnTheFly(benfitsObj, elem) {
    benfitsObj['value_prop_metric_id'] = parseInt(this.selectedElem.value_prop_metric_id, 10);
    benfitsObj['account_solution_metric_id'] = this.selectedElem['account_solution_metric_id'];
    this.valuepropService
      .updateValuePropsBenefits(this.valuePropId, benfitsObj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result.success) {
          if (this.files && this.files.length) {
            for (let i = 0; i < this.files.length; i++) {
              const out: FormData = new FormData();
              const elem = this.files[i];

              const elem_fl = this.files[i];
              out.append('file', elem_fl);
              out.append('description', elem['description']);
              if (!elem.on_server) {
                elem.on_server = 0;
              }
              if (elem.on_server === 0) {
                this.solutionService
                  .postFiles(out, this.selectedElem.id)
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe(() => {
                    this.notify('benefitUpdated');
                    this.bI.firstLoad = true;
                    this.valuepropService.refreshBenefitDetail.next();
                    this.callback.emit('');
                  });
              } else {
                const payload = { description: elem.description };
                this.solutionService
                  .putFiles(payload, elem.id)
                  .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe(() => {
                    this.notify('benefitUpdated');
                    this.bI.firstLoad = true;
                    this.reloadVP.emit('');
                    this.callback.emit('');
                    this.valuepropService.refreshBenefitDetail.next();
                  });
              }
            }
          } else {
            this.reloadVP.emit('');
            this.callback.emit('');
            this.notify('benefitUpdated');
            this.bI.firstLoad = true;
            this.valuepropService.refreshBenefitDetail.next();
          }
        } else {
          this.notify('saveFailed', 'error');
          elem.funcCancel();
        }
      });
  }

  deleteBenefitFile(file) {
    if (!file.id) {
      return;
    }
    this.solutionService
      .deleteFiles(file.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notify('fileRemoved');
        this.valuepropService.refreshBenefitDetail.next();
      });
  }

  openEditFactor(factor: Factor): void {
    if (factor.id !== '1' && factor.id !== '2') {    
      this.selectedFactor = cloneDeep(factor);
      this.editFactor = true;
    }
  }

  clearEditFactor() {
    this.editFactor = false;
    this.selectedFactor = null;
  }

  openScratchpad(factor: any, property: string) {
    this.showFactor = true;
    this.scratchpadPropertyToEdit = property;
    this.selectedFactor = factor;
  }

  closeScratchpad(scratchpadTotal: number) {
    this.showFactor = false;
    this.getDropdownValues();
    if (scratchpadTotal) {
      this.selectedElem[this.scratchpadPropertyToEdit] = scratchpadTotal;
    }
    this.scratchpadPropertyToEdit = null;
    this.selectedFactor = null;
  }

  setFactorToggle(field: string, toggle: boolean, fields: string[]) {
    // const notApplicableFactor = this.dropdownValues.readAccountAggregatedFactors.find((factor) => factor.id === '2');
    const notApplicableFactor = this.funcFindObject('readAccountFactorCategoriesType', 'id', '2');

    this.selectedElem[field] = toggle ? null : notApplicableFactor;
    fields.forEach((benefitField) => (this.selectedElem[benefitField] = toggle ? null : 1));
  }

  generateCustomAsset(tagChange) {
    if (tagChange.checked) {
      this.selectedElem = {
        ...this.selectedElem,
        nameTag: this.selectedElem.metric_name,
        valueTag: snakeCase(this.selectedElem.metric_name),
      };
    } else if (this.selectedElem.tag_data?.id) {
      this.notify('removeTag');
    }
  }

  private notify(transString: string, type: 'error' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
