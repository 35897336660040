import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

import { SurveyQuestion, SurveyTemplate, SurveyTemplateWithQuestions } from '@data/services/valueprop/models/survey-template';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';
import { ValueRealizedSurvey } from '@data/services/valuerealization/models/value-realized-survey.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Subject, Observable } from 'rxjs';
import { pluck, take, takeUntil } from 'rxjs/operators';
import { TrackValueDashboardTranslation } from '../track-value-dashboard/track-value-dashboard.translation';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';

@Component({
  selector: 'app-new-survey-sidebar',
  templateUrl: './new-survey-sidebar.component.html',
  styleUrls: ['./new-survey-sidebar.component.scss'],
})
export class NewSurveySidebarComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() survey: ValueRealizedSurvey;
  @Output() sidebarClosed = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022$: Observable<boolean>;
  breadcrumbKeyValues: Breadcrumbs;
  surveyTemplates: SurveyTemplateWithQuestions[];
  surveys: any[];
  selectedTemplate: SurveyTemplateWithQuestions;
  selectedSurvey: SurveyTemplateWithQuestions;
  questions: SurveyQuestion[];
  surveyTemplateLoader = false;
  customSurveyDate;
  selectedSurveyId: string;
  businessReviewData: BusinessReviewData[];
  businessReview: BusinessReviewData;

  constructor(
    private commonService: CommonService,
    private valueRealizationService: ValuerealizationService,
    public trans: TrackValueDashboardTranslation,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
    public businessReviewService: TrackerBusinessReviewService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getTemplateList();

    this.businessReviewService.businessReviews$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((businessReviews) => {
      this.businessReviewData = businessReviews;
      if (this.survey) {
        this.businessReview = this.businessReviewData.find((review) => review.id === +this.survey.business_review_id);
      }
    });
    if (this.survey) {
      this.selectedSurveyId = this.survey.value_survey_id;
      this.getActiveSurveyTemplate();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getTemplateList(): void {
    const aid = sessionStorage.getItem('aid');
    this.valueRealizationService
      .getValueRealization_SurveyTemplates(aid)
      .pipe(pluck('result'))
      .pipe(take(1))
      .subscribe((templates) => {
        this.surveyTemplates = templates.filter((template) => +template.is_active === 1);
        if (this.survey) {
          this.selectedTemplate = this.surveyTemplates.find((template) => template.id === this.survey.account_question_template_id);
        }
      });
  }

  selectSurveyTemplate(template: SurveyTemplateWithQuestions): void {
    if (!this.businessReview) {
      this.notificationService.error(this.trans.trans.businessReviewError.value, false);
    } else {
      this.selectedTemplate = template;
      this.createSurvey();
    }
  }

  closeCreateSurvey(): void {
    this.selectedTemplate = null;
    this.selectedSurvey = null;
  }

  closeSidebar(): void {
    this.sidebarClosed.emit();
    this.selectedSurvey = null;
    this.selectedTemplate = null;
    this.selectedSurveyId = null;
    this.businessReview = null;
  }

  getActiveSurveyTemplate(): void {
    let accountId = sessionStorage.getItem('aid');
    this.valueRealizationService.readActiveSurveyTemplate(accountId).subscribe((response) => {
      if (response.result) {
        this.selectedSurvey = response.result.find((template) => template.id === this.selectedTemplate.id);
      }
    });
  }

  createSurvey(): void {
    const surveyDate = new Date(this.businessReview.start_date);
    let formattedD = surveyDate.toISOString().replace(/[a-z]/gi, ' ');

    let params = {
      name: '',
      value_prop_id: this.valuePropId,
      account_question_template_id: this.selectedTemplate.id,
      custom_survey_date: formattedD,
      business_review_id: this.businessReview.id,
    };

    this.valueRealizationService
      .createSurveys(params)
      .pipe(pluck('result', 'value_survey'), takeUntil(this.ngUnsubscribe))
      .subscribe((value_survey) => {
        if (value_survey) {
          this.selectedSurveyId = value_survey.id;
          this.getActiveSurveyTemplate();
        }
      });
  }
}
