import { Injectable } from '@angular/core';

@Injectable ()

export class ValuePropGroupDashboardTranslations {

    public config: any = {
        component: 'valuepropgroupDashboard',
        display_name: 'Value Prop Group Dashboard',
        description: 'Value prop group in main dashboard'
    };

    public trans: any = {
        name: 'Name',
        total_benefits: 'Total Benefits',
        total_costs: 'Total Costs',
        value_prop_groups: 'Value Prop Groups',
        see_more: 'See More',
        create_value_prop: 'Create Value Prop',
        add: 'Add',
        benefits: 'Benefits',
        costs: 'Costs',
    };
}
