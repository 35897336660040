import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Table } from 'primeng/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValueTrackerDetailTranslations } from './realization-detail.translation';

@Component({
  selector: 'app-realization-detail',
  templateUrl: 'realization-detail.component.html',
})
export class ValueRealizationDetail2Component implements OnInit, OnDestroy {
  tableElem: Table;
  lastSurveyId: any;
  @ViewChild('tableSolutionList') set tableSolutionList(tabl: Table) {
    if (tabl && !this.tableElem) {
      this.tableElem = tabl;
      this.tableElem.filter(this.activeFilter, 'active', 'equals');
    }
  }
  ngUnsubscribe = new Subject();
  showTranslate = false;

  valuePropId: string;
  vpRealization: any;
  valueProp: any = {};

  chartView = 'line chart';
  lineChartData: any;
  barChartDataPercent: any;
  barChartDataDollars: any;
  chartColors: any;

  benefits: any[];
  financialBenefits: any[];
  nonFinancialBenefits: any[];
  benefitTableCols = [
    { field: 'metric_name', header: 'Benefit Name', sort: true, sortField: 'metric_name' },
    { field: 'active', header: 'Status' },
    { field: 'benefit_type_id', header: 'Benefit Type' },
    { field: 'realized_to_date_fmt', header: 'Achieved', sort: true, sortField: 'realized_to_date' },
    { field: 'expected_to_date_fmt', header: 'Expected', sort: true, sortField: 'expected_to_date' },
  ];
  showBenefitSidebar = false;
  selectedBenefit: any;
  activeSurveyTemplate: any;

  showAssetSidebar = false;
  showMarketingSidebar = false;
  showBenefitNotes = false;
  benefitNotes: any;

  activeTypes = [];
  activeFilter = '1';

  pageLimit = 10;
  rowLimit = [];
  statusMenu = [];

  customSurveyDate;
  surveyName: string;
  surveyDatePicker = false;
  solutionId;
  valueRealizationCurrency: string;

  modelsv2 = true;
  benefitView = false;

  showTrackerShare: boolean = false;

  can_edit: boolean = false;
  can_view: boolean = false;
  toggleAssetDownload: boolean = false;
  isCustomerSuccessManager = false;

  surveyTemplate: any;
  templates: any[] = [];
  style2022$: Observable<boolean>;

  constructor(
    private translationService: TranslationsV2Service,
    public trans: ValueTrackerDetailTranslations,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private valuePropService: ValuepropService,
    private valuerealizationService: ValuerealizationService,
    private notificationService: NotificationService,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.getTemplates();
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      this.valuePropId = params.id;
      this.getValueRealization(this.valuePropId);
    });

    this.modelsv2 = this.commonService.checkFeature('38');
    this.isCustomerSuccessManager = this.commonService.checkPrivilege('14');

    this.activeTypes = [
      { label: 'N/A', value: '0' },
      { label: 'Active', value: '1' },
      { label: 'All', value: '' },
    ];

    this.funcBuildPageLimit();

    this.getActiveSurveyTemplate();
    this.chartColors = this.commonService.getChartColors();
    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  closeTrackerShare(): void {
    this.showTrackerShare = false;
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  funcOpensidebar(benefit) {
    this.selectedBenefit = benefit;
    this.showBenefitSidebar = true;
  }

  funcCloseSidebar() {
    this.selectedBenefit = undefined;
    this.showBenefitSidebar = false;
    this.showAssetSidebar = false;
    this.showMarketingSidebar = false;
  }

  funcCloseBenefits(refresh) {
    this.benefitView = false;
    if (refresh) {
      this.getValueRealization(this.valuePropId);
    }
  }

  funcBuildStatusMenu(menu, benefit, event) {
    this.statusMenu = [
      {
        label: 'Active',
        command: () => {
          this.updateBenefitStatus(benefit, 1);
        },
      },
      {
        label: 'N/A',
        command: () => {
          this.updateBenefitStatus(benefit, 0);
        },
      },
    ];

    menu.toggle(event);
  }

  setRowLimit(num) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit == 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit == 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit == 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit == 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  getTemplates() {
    let aid = sessionStorage.getItem('aid');
    this.valuerealizationService
      .getValueRealization_SurveyTemplates(aid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.templates = response.result;
      });
  }
  getValueRealization(id, benId?) {
    this.valuerealizationService
      .getValueRealizationDetail(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.vpRealization = response.result;
        this.valueRealizationCurrency = this.vpRealization.currency_symbol;

        this.lastSurveyId = this.vpRealization.survey_ids[this.vpRealization.survey_ids.length - 1];

        this.benefits = response.result.metrics.map((met) => ({
          ...met,
          active: met.active == String(3) ? 0 : met.active,
        }));

        this.benefits = this.benefits.map((benefit) => {
          benefit.benefit_type_id === '1' ? (benefit.benefit_type_id = 'Financial') : (benefit.benefit_type_id = 'Non-Financial');
          return benefit;
        });

        this.funcCreateLineChart(response.result.line_graph);
        if (response.result.bar_chart_dollar) {
          this.funcCreateBarChartDollars(response.result.bar_chart_dollar);
          if (response.result.bar_chart_percentage) {
            this.funcCreateBarChartPercent(response.result.bar_chart_percentage, response.result.bar_chart_dollar.labels);
          }
        }

        this.can_edit = response.result.vp_can_edit;
        this.can_view = response.result.vp_can_view;

        if (benId) {
          this.selectedBenefit = this.benefits.find((ben) => ben.account_solution_metric_id === benId);
        }
      });
  }

  sendToMarketing(benefit) {
    this.showMarketingSidebar = true;
    this.selectedBenefit = benefit;
  }

  funcShowBenefitNotes(benefit) {
    this.showBenefitNotes = true;

    this.benefitNotes = benefit;
  }

  funcCloseBenefitNotes(event) {
    this.saveNote(event);
  }

  saveNote(note) {
    this.valuerealizationService
      .addValueRealizationBenefitTestimonial(note)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result.success) {
          this.showBenefitNotes = false;
        }
      });
  }

  determineGainLoss(num, num2 = '0') {
    if (typeof num === 'string' && num.includes(',')) {
      num = num.replace(/,/g, '');
    }
    if (typeof num2 === 'string' && num2.includes(',')) {
      num2 = num2.replace(/,/g, '');
    }

    let num1 = Number(num);
    let num22 = Number(num2);
    return num1 - num22 >= 0;
  }

  getStatus(status) {
    let statusType;

    switch (status) {
      case '0':
      case '3':
      case 3: {
        statusType = 'N/A';
        break;
      }
      case '1': {
        statusType = 'Active';
        break;
      }
      case '2': {
        statusType = 'Soft';
        break;
      }
      default: {
        statusType = 'N/A';
      }
    }

    return statusType;
  }

  updateBenefitStatus(benefit, status) {
    const payload = {
      value_prop_metric_id: benefit.value_prop_metric_id,
      active: status,
    };
    this.valuePropService
      .updateMetricActive(this.vpRealization.value_prop_id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.result.success) {
          this.getValueRealization(this.valuePropId);
        }
      });
  }

  funcCreateLineChart(data) {
    this.lineChartData = {
      labels: data.labels,
      datasets: [
        {
          label: 'Start',
          data: data.start,
          fill: false,
          borderColor: this.chartColors[2],
        },
        {
          label: 'Expected',
          data: data.expected_by_quarter,
          fill: false,
          borderColor: this.chartColors[0],
        },
        {
          label: 'Achieved',
          data: data.realized_by_quarter,
          fill: false,
          borderColor: this.chartColors[1],
        },
      ],
    };
  }

  funcCreateBarChartPercent(data, labels) {
    this.barChartDataPercent = {
      labels: labels,
      datasets: [
        {
          label: 'Start',
          backgroundColor: this.chartColors[2],
          borderColor: this.chartColors[2],
          data: data.prior,
        },
        {
          label: 'Expected',
          backgroundColor: this.chartColors[0],
          borderColor: this.chartColors[0],
          data: data.expected_percent,
        },
        {
          label: 'Achieved',
          backgroundColor: this.chartColors[1],
          borderColor: this.chartColors[1],
          data: data.realized_percent,
        },
      ],
    };
  }

  funcCreateBarChartDollars(data) {
    this.barChartDataDollars = {
      labels: data.labels,
      datasets: [
        {
          label: 'Start',
          backgroundColor: this.chartColors[2],
          borderColor: this.chartColors[2],
          data: data.prior,
        },
        {
          label: 'Expected',
          backgroundColor: this.chartColors[0],
          borderColor: this.chartColors[0],
          data: data.expected_to_date,
        },
        {
          label: 'Achieved',
          backgroundColor: this.chartColors[1],
          borderColor: this.chartColors[1],
          data: data.realized_to_date,
        },
      ],
    };
  }

  formatNumber(num) {
    return Number(num).toLocaleString('en');
  }

  navToVp(id) {
    const isRepUser = this.commonService.checkFeature('34');

    const loc = window.origin;

    const route = isRepUser ? '/repv2/' + id + '/4' : '/valueprop/' + id;
    window.open(loc + route, '_blank');
  }

  navToSurveys(id) {
    this.router.navigate(['valuerealization/' + id + '/survey']);
  }

  getActiveSurveyTemplate(surveyCallBack?) {
    let accountId = sessionStorage.getItem('aid');
    this.valuerealizationService.readActiveSurveyTemplate(accountId).subscribe((response) => {
      if (response.result) {
        this.activeSurveyTemplate = response.result[0];

        if (surveyCallBack) {
          this.surveyCallBack();
        }
      } else {
        this.notificationService.error('The survey could not be created, please check that you have created a survey template', false);
      }
    });
  }

  pickDateForNewSurvey() {
    this.surveyDatePicker = true;
  }

  clearSurveyForm() {
      this.surveyDatePicker = false;
      this.surveyName = null;
      this.surveyTemplate = null;
      this.customSurveyDate = null;
  }

  surveyCallBack() {
    let formattedD = this.formattDate(this.customSurveyDate);

    let params = {
      name: this.surveyName,
      value_prop_id: this.vpRealization.value_prop_id,
      account_question_template_id: this.surveyTemplate.id,
      custom_survey_date: formattedD,
    };

    this.valuerealizationService.createSurveys(params).subscribe((response) => {
      this.surveyDatePicker = false;
      if (response.result && response.result['value_survey']) {
        this.valuerealizationService.readSurveyList.next(response.result['value_survey']);
        this.getValueRealization(this.valuePropId);
        this.notificationService.success(this.trans.trans.surveyCreated.value, false);
        this.clearSurveyForm();
      }
    });
  }

  filterActive(event, table, col) {
    table.filter(event, col.field, 'equals');
  }

  addBenefit() {
    this.getSolutionId();
  }

  getSolutionId() {
    this.valuePropService
      .readSolutionList(this.vpRealization.value_prop_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.solutionId = response.result[0]['account_solution_id'];
          this.benefitView = true;
        }
      });
  }

  formattDate(dateObj) {
    let customD = dateObj ? dateObj.toISOString() : new Date().toISOString();

    return customD.replace(/[a-z]/gi, ' ');
  }

  refreshBenefit(benId) {
    this.getValueRealization(this.valuePropId, benId);
  }
}
