import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { APIResult } from '@shared/models/api-result.model';
import { BenefitTypeCreateResponse, BenefitTypePayload, BenefitTypeResponse } from '@shared/models/benefits.models';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { Observable } from 'rxjs';

@Injectable()
export class BenefitTypesService {
	serviceUrl: string;

	constructor(private commonService: CommonService) {
		this.serviceUrl = this.commonService.getServiceUrl();
	}

	public postBenefitType(payload: BenefitTypePayload): Observable<APIResult<BenefitTypeCreateResponse>> {
		let endpoint = "account/benefits/create/benefitTypes";
		return this.commonService.postAPIService(endpoint, payload);
	}

	public getBenefitTypes(): Observable<APIResult<BenefitTypeResponse>> {
		let endpoint = "account/benefits/benefitTypes";
		return this.commonService.getAPIService(endpoint);
	}

	public putBenefitType(payload: BenefitTypePayload): Observable<APIResult<{ message: string }>> {
		let endpoint = "account/benefits/update/benefitTypes/update";
		return this.commonService.putAPIService(endpoint, payload);
	}

	public deleteBenefitType(accountBenefitTypeId: string): Observable<APIResult<SuccessFailResponse>> {
		let endpoint = "account/benefits/delete/benefitTypes/" + accountBenefitTypeId;
		return this.commonService.deleteAPIService(endpoint);
	}
}
