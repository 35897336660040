import { Injectable } from '@angular/core';

@Injectable()
export class WorkFlowTranslation {
  public config: any = {
    component: 'ChooseAdventureInternal',
    display_name: 'Work Flow',
    description: 'Work flow selector dialog'
  };

  public trans: any = {
    expressValueInsights: 'Use Express Value Insights Process',
    standardProcess: 'Use Classic Process',
    chooseWorkFlow: 'Choose Conversation Workflow to Use',
    expressDetail: 'Workflow to support early stage conversation prep, define your selling scenario and view your results',
    classicDetail: 'Traditional 5 Step workflow for building, revising and collaborating on your value proposition'
  };
}
