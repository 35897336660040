<div class='keywest_header'>
	<div class='row'>
		<div class='col-xs-6'>
			<div class='title'>{{trans.trans.thresholds.value}}
				<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
				[transObj]="trans.trans.thresholds" (callback)="getTranslations()"></app-editTranslation>
			</div>
		</div>
		<div class='col-xs-6'>
			<button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="funcShowAddThreshold()" [matTooltip]="trans.trans.addNew.value" matTooltipPosition="above">
				<i class="fa fa-plus"></i> {{trans.trans.addNew.value}}</button>
				<!-- <button class="btn btn_customizable pull-right" (click)="showTranslate = !showTranslate" style='margin-left: 10px;'>
					<i class="fa fa-exchange" [matTooltip]="trans.trans.edit_translations.value" matTooltipPosition="below" [ngClass]="{'edit_translation': showTranslate }" aria-hidden="true"></i>
				</button> -->
		</div>
	</div>
</div>

<div class='row marg-v'>
	<div class='col-xs-4'>
		<div class="input-group keywest_search">
			<span class="input-group-addon"><i class='fa fa-search'></i></span>
			<input (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" [(ngModel)]="strSearch" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
			<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
		</div>
	</div>
</div>

<div class='row'>
	<div class='col-xs-12'>
		<p-table #tableSolutionList [value]="solutionThreshold" [columns]="cols" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [pSortableColumn]="col.field">
						{{trans.trans[col.field].value}}
						<p-sortIcon [field]="col.field"></p-sortIcon>
						<app-editTranslation *ngIf="showTranslate"  [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
					</th>
					<th></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-threshold>
				<tr>
					<td (click)='funcToggleView(threshold)' class='clickable'>
                        <span class="clickable">
                            {{threshold.name}}
                        </span>
                    </td>
					<td>{{threshold.min}}</td>
					<td>{{threshold.max}}</td>
					<td>{{threshold.margin}}</td>
					<td>
						<span *ngIf="can_edit" class='keywest_icon_group pull-right'>
							<span><i (click)='funcToggleEdit(threshold)' [matTooltip]="trans.trans.edit.value" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
							<span>
								<app-are-you-sure [matTooltip]="trans.trans.delete.value" matTooltipPosition="above" [message]='trans.trans.areYouSure.value' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deleteThreshold(threshold.threshold_type_id)'></app-are-you-sure>
							</span>
						</span>
						<span *ngIf="!can_edit" class='keywest_icon_group pull-right'>
							<span><i (click)='funcToggleView(threshold)' class='fa fa-eye clickable pull-right' [matTooltip]="trans.trans.view.value" matTooltipPosition="above"></i></span>
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddThreshold' styleClass="keywest_sidebar p-sidebar-lg" position='right' (onHide)="resetThreshold()">
	<app-ae-thresholds *ngIf="toggleAddThreshold && can_edit" [thresholdFormObj]='thresholdFormObj' (callback)='getThresholdList(); toggleAddThreshold = false;' [mode]="mode" [sidebar_title]="sidebar_title"></app-ae-thresholds>
	<app-r-thresholds [thresholdFormObj]='thresholdFormObj' *ngIf="toggleAddThreshold && !can_edit"></app-r-thresholds>
</p-sidebar>