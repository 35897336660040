import { Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BenefitsImplementation } from '../../benefits.implementation';
import { Translations } from '@data/services/translation/translation.service';

@Component({
	selector: 'app-benefit-grid',
	templateUrl: './grid.component.html',

})
export class BenefitsGridComponent implements OnInit {
	constructor(
		private translateService: TranslateService,
		public trans: Translations,
		public bI: BenefitsImplementation
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit(): void {

	}

}