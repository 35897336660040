import { Component, ViewChild, OnDestroy, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { NotificationService } from '@services/notification.service';
import { Subject, forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AESituationTranslations } from './ae_sitations.translation';
import { ErrorMessagesService } from '@services/error-messages.service';
import { formatNumber } from '@angular/common';
import { CustomValidators } from 'app/_utils/custom-validators';

@Component({
  selector: 'app-ae-detail-situation',
  templateUrl: './ae_situations.component.html',
  styleUrls: ['./ae_situations.component.scss'],
})
export class AESituationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() solutionId: string;
  @Input() tabInit: number;
  @Input() can_edit = false;
  @Input() set modeType(type: string) {
    this.mode = type;
    if (type === 'add') {
      this.resetSituation();
    }
  }
  @Input() situationEdit: any;
  @Input() default_term: number = 3;
  @Output() backCallback = new EventEmitter();

  @ViewChild('situationForm') situationForm: NgForm;

  phasingTermGroup = this.fb.group({ phasingTermArray: new FormArray([]) });

  get phasingTermArray() {
    return this.phasingTermGroup.get('phasingTermArray') as FormArray;
  }
  errorDict$ = this.errorMessagesService.formGroupObsFactoryCrossField(this.phasingTermGroup);

  mode = 'add';
  defaultSituation = {
    situation_type_id: null,
    situation_element: undefined,
    situation_element_id: undefined,
    description: undefined,
    defaultActive: false,
    guided_default_active: false,
    guided_discovery: false,
    guidedDefaultActive: undefined,
  };
  situationObj = this.defaultSituation;

  ngUnsubscribe = new Subject();

  customcheckboxlabel: string;
  toggles = {
    addType: false,
    showSituationForm: false,
    addElement: false,
    defineBenefits: false,
    defineCosts: false,
    showList: true,
    showBenefitsCosts: false,
    situation_type_stack_order: false,
    situation_type_edit: false,
  };
  account_solution_id: number | string;
  account_situation_id: number;
  situation_type_id: number;
  situation_type_id_obj: Array<object> = [];
  modalReference: any;
  situationTypes: any[];
  situationType: any;
  accountSituationType: Array<object> = [];
  situationElementTypes: any[];
  situation: any;
  situationElement: any;
  situationElements: any[];
  situationTypeElements: any[];
  situationBenefits: any[];
  situationCosts: any[];
  situations: any[];
  costs: any[];
  cost: any;
  benefits: any;
  caseStudies: any;
  assets: any[] = [];
  collateral: any[] = [];
  benefit: any;
  benefitConf: any;
  costConf: any;
  // dropdown
  dropdownList = [];
  selectedItems = [];
  SituationTypeElementsSettings = {};
  isActive = false;
  situationTypeElementsLoader = false;
  accrualTypes: any[];
  scaleTypes: any[];
  stack_order: any = 0;
  tempSituationList: Array<Object>;
  stackOrderEnabledAll = false;
  // edit = false;
  situationLoader = false;
  testTree: any;
  showTranslate = false;
  breadcrumb: string;
  loaded = false;

  // Loader Images
  fullImagePath: string;

  // Contextual Help
  contextualHelp: object = {};

  // Nested Situations
  // toggles
  elementChildren1Toggle: boolean;
  elementChildren2Toggle: boolean;
  elementChildren3Toggle: boolean;
  elementChildren4Toggle: boolean;
  elementChildren5Toggle: boolean;

  elementChildren1 = [];
  elementChildren2 = [];
  elementChildren3 = [];
  elementChildren4 = [];
  elementChildren5 = [];

  parent1Name: string;
  parent2Name: string;
  parent3Name: string;
  parent4Name: string;
  parent5Name: string;
  parent1: string;
  parent2: string;
  parent3: string;
  parent4: string;
  parent5: string;

  situationTypeChildElements = [];

  tempEls = [];

  dropdownSettings: object;

  masterValuePropFeature: boolean;
  projectsList: any = [];
  selectedProject: object;

  tree: any;
  showAddView: boolean = false;
  addNewObj = {
    name: '',
    description: '',
    parent_id: '',
    account_situation_type_id: '',
  };

  benefitsCols = [
    { field: 'metric_name', header: 'Name' },
    { field: 'conservative', header: 'Conservative' },
    { field: 'probable', header: 'Probable' },
    { field: 'aggressive', header: 'Aggressive' },
    { field: 'current_effort', header: 'Current Effort' },
  ];
  factorCols = [
    { field: 'name', header: 'Name' },
    { field: 'driver_value', header: 'Model Baseline' },
    { field: 'factor_baseline_answer', header: 'Situation Override' },
  ];

  costsCols = [{ field: 'name', header: 'Name' }];

  caseStudiesCols = [{ field: 'name', header: 'Name' }];

  assetsCols = [{ field: 'name', header: 'Name' }];

  collateralCols = [{ field: 'name', header: 'Name' }];

  projectCols = [{ field: 'name', header: 'Name' }];

  situationsItemsMenuView = false;

  strSearchBenefits = '';
  strSearchCosts = '';
  strSearchCaseStudies = '';
  strSearchAssets = '';
  strSearchCollateral = '';
  strSearchProjects = '';
  strSearchFactors = '';

  toggleBenefits = false;
  toggleCosts = false;
  toggleCaseStudies = false;
  toggleAssets = false;
  toggleCollateral = false;
  toggleProjects = false;

  showBenefitSidebar = false;
  showCostsSidebar = false;

  toggleOptions: boolean = false;
  expanded: boolean = false;
  rows: number = 10;

  factors: any[] = [];
  toggleFactors: boolean = false;

  feature88: boolean = false;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private factsService: FactsService,
    private notificationService: NotificationService,
    public trans: AESituationTranslations,
    private translationService: TranslationsV2Service,
    private fb: FormBuilder,
    private errorMessagesService: ErrorMessagesService
  ) {
    const image_url = this.commonService.getImageUrl();
    this.fullImagePath = image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.feature88 = this.commonService.checkFeature(88);
    this.getTranslations();
    this.account_solution_id = this.solutionId;
    this.situations = [];
    this.benefits = [];
    this.costs = [];
    this.caseStudies = [];
    this.cost = {};
    this.situationTypeElements = [];
    this.benefit = {};
    this.benefitConf = {};

    this.situation = {
      account_solution_id: this.account_solution_id,
    };

    this.situationType = {
      account_solution_id: this.account_solution_id,
    };

    this.situationElement = {
      account_situation_id: this.account_situation_id,
    };

    this.customcheckboxlabel = 'No';

    forkJoin({
      situationTypes: this.solutionService.getSituationTypes(),
      elementsTypes: this.factsService.getSituationElementTypes(),
    }).subscribe((response) => {
      this.situationTypes = response.situationTypes.result.map((sitTypes) => ({
        label: sitTypes.name,
        value: sitTypes.id,
        id: sitTypes.id,
      }));

      this.situationElementTypes = response.elementsTypes.result ? response.elementsTypes.result : [];

      if (this.mode === 'edit') {
        this.assignSituationTypeId();
        this.showEditView(this.situationEdit);
      }
    });

    this.getAccrualTypes();
    this.getScaleTypes();

    this.masterValuePropFeature = false;
    if (this.commonService.checkFeature('15')) {
      this.masterValuePropFeature = true;
    }

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    // end init
    this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.elementChildren1Toggle = false;
    this.elementChildren2Toggle = false;
    this.elementChildren3Toggle = false;
    this.elementChildren4Toggle = false;
    this.elementChildren5Toggle = false;

    if (this.mode === 'edit') {
      this.situations = this.situationEdit;
      this.situationObj.situation_type_id = this.situationEdit.situation_type_id;
      this.situationObj.situation_element = this.situationEdit.situation_element_id;
      this.assignSituationTypeId();
    } else {
      this.loaded = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.mode === 'edit') {
        this.situationObj.situation_type_id = this.situationEdit.situation_type_id;
        this.situationObj.situation_element = this.situationEdit.situation_element_id;
        this.situationObj.defaultActive = this.situationEdit.default_active;
        this.situationObj.guided_discovery = this.situationEdit.guided_discovery;
        if (this.feature88) {
          this.situationObj.guided_default_active = this.situationEdit.guided_default_active;
          this.situationForm.controls.guidedDefaultActive.setValue(this.situationEdit.guided_default_active !== 0);
        }
        this.situationForm.controls.description.setValue(this.situationEdit.description);
        this.situationForm.controls.defaultActive.setValue(this.situationEdit.default_active !== 0);
        this.toggles.showBenefitsCosts = true;
        this.situationsItemsMenuView = true;
      }
    }, 0);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  back(): void {
    this.backCallback.emit();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  toggleExpandCollapse() {
    this.expanded = !this.expanded;
    this.toggleBenefits = this.expanded;
    this.toggleCosts = this.expanded;
    this.toggleCaseStudies = this.expanded;
    this.toggleAssets = this.expanded;
    this.toggleFactors = this.expanded;
    this.toggleCollateral = this.expanded;
    this.toggleProjects = this.expanded;
    this.toggleOptions = false;
  }

  setRows(rows) {
    this.rows = rows;
    this.toggleOptions = false;
  }

  postFactorBaseline(factor) {
    let payload = {
      account_solution_situation_id: this.situation.id,
      account_factor_id: factor.id,
      factor_baseline_answer: factor.factor_old_baseline_answer,
    };
    factor.factor_baseline_answer = factor.factor_old_baseline_answer;
    this.solutionService
      .postFactorBaseline(this.situation.id, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => {
          if (result.result) {
            factor.edit = false;
            factor.factor_baseline_answer = factor.factor_old_baseline_answer;
            this.notificationService.success(`${this.trans.trans.values_changed.value} !`, false);
          }
        },
        (error) => {
          factor.factor_old_baseline_answer = factor.factor_baseline_answer;
        }
      );
  }

  cancelOverride(factor) {
    factor.edit = false;
    factor.factor_old_baseline_answer = factor.factor_baseline_answer;
  }

  getSituationTypes() {
    this.solutionService
      .getSituationTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.situationTypes = result.result.map((sitTypes) => ({
          label: sitTypes.name,
          value: sitTypes.id,
          id: sitTypes.id,
        }));
      });
  }

  getAccountSituationTypes() {
    this.accountSituationType = [];
    this.solutionService
      .getAccountSituationTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.accountSituationType = result.result;
        }
      });
  }

  getElementTypes() {
    this.factsService
      .getSituationElementTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.situationElementTypes = result.result;
      });
  }

  assignSituationTypeId() {
    this.situation_type_id = this.situationObj.situation_type_id;
    this.getSituationTypeElements(this.situation_type_id);
  }

  getSituationTree() {
    this.solutionService
      .getSituationsBySolutionTreeV3(this.situationEdit.id, this.solutionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.tree = res.result.data;
        this.breadcrumb = '';
        if (this.mode === 'edit') {
          this.getBreadCrumbForEdit(this.tree[0]);
        }

        let currentSituation = this.getlastChild(this.tree[0]);

        this.benefits = currentSituation.benefits;
        this.factors = currentSituation.factors.map((factor) => ({
          ...factor,
          factor_old_baseline_answer: factor.factor_baseline_answer,
        }));
        this.costs = currentSituation.costs;
        this.caseStudies = currentSituation.case_studies;
        this.assets = currentSituation.assets.map((asst) => ({
          ...asst,
          icon: this.getFAIcon(asst.format),
        }));
        this.collateral = currentSituation.collaterals;
        this.projectsList = currentSituation.projects;
        this.loaded = true;
      });
  }

  saveSituation(form: NgForm, div = undefined) {
    if (this.mode === 'add') {
      this.situation.account_solution_id = this.account_solution_id;
      this.situation.situation_type_id = form.controls.situation_type_id.value;
      this.situation.description = form.controls.description.value;

      this.situation['default_active'] = form.controls.defaultActive.value ? 1 : 0;
      if (this.feature88) {
        this.situation['guided_default_active'] = form.controls.guidedDefaultActive.value ? 1 : 0;
      }
      let situation = Object.assign({}, this.situation);
      situation['guided_discovery'] = this.situationObj['guided_discovery'] ? '1' : '0';
      this.solutionService
        .saveSituation(situation)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          let success = result.result.success;
          if (success) {
            this.account_situation_id = result.result.message.id;
            this.notificationService.success(this.trans.trans.situation_created_success.value, false);
            this.situationsItemsMenuView = true;
            this.situationEdit = result.result.message;
            this.getSituationTree();
            div.funcCancel();
            this.showEditView(result.result.message);
            this.solutionService.removeSituationTreeMap(this.account_solution_id.toString());
          } else {
            this.notificationService.error(result.result.message, false);
          }
        });
    } else {
      // update Situation

      let situation = Object.assign({}, this.situation);
      situation['account_solution_situation_id'] = situation['id'];
      situation['default_active'] = form.controls.defaultActive.value ? 1 : 0;
      if (this.feature88) {
        situation['guided_default_active'] = form.controls.guidedDefaultActive.value ? 1 : 0;
      }
      situation['guided_discovery'] = this.situationObj['guided_discovery'] ? '1' : '0';
      situation['description'] = form.controls.description.value;

      for (let i = 0; i < this.situationTypeElements.length; i++) {
        if (situation['situation_element_id'] === this.situationTypeElements[i]['id']) {
          situation['name'] = this.situationTypeElements[i]['name'];
        }
      }

      const payload = {
        account_situations: [situation],
      };
      this.solutionService
        .updateSituation(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result) => {
          this.account_situation_id = result.result[0]['solution_situation']['id'];
          this.situation['guided_discovery'] = result.result[0]['solution_situation']['guided_discovery'] == '1' ? true : false;
          this.getSituationsBySolution();
          div.funcCancel();
          this.solutionService.removeSituationTreeMap(this.account_solution_id.toString());
          this.notificationService.success(this.trans.trans.situation_updated_success.value, false);
        });
    }
  }

  saveSituationElement() {
    if (!this.account_situation_id) {
      this.saveSituation(this.situationForm);
    }

    this.solutionService
      .saveSituationElement(this.situationElement)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {});
  }

  getSituationsBySolution() {
    this.situationLoader = true;

    this.solutionService
      .getSituationsBySolutionTree(this.account_solution_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.situationLoader = false;
        if (!Array.isArray(result)) {
          this.situations = result.result.data;
        } else {
          this.situation = result;
        }
      });
  }

  resetCosts() {
    if (this.costs) {
      this.costs = this.costs.map((cost) => {
        cost['changeToggleAvailable'] = null;
        cost['changeToggleAutoload'] = null;
        return cost;
      });
    }
  }

  getSituationTypeElements(id) {
    this.situation.situation_type_id = id;
    this.situationTypeElementsLoader = true;
    this.situationTypeElements = [];
    this.solutionService
      .getSituationTypeElements(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.situationTypeElementsLoader = false;
        if (response.result['message'] && response.result['message']['available_elements']) {
          this.situationTypeElements = response.result['message']['available_elements'];
        } else {
          if (id == 3 && response.result['message']) {
            // for GEO elements
            delete response.result['message']['available_elements'];
            let responseGeo = response.result['message'];
            this.situationTypeElements = Array.from(Object.keys(responseGeo), (k) => responseGeo[k]);
          }
        }
      });
  }

  showBenefitControls(benefit) {
    this.benefitConf = benefit;
    this.showBenefitSidebar = true;
    const isOneTimeAccrual = this.benefitConf.accrual_type_id && (+this.benefitConf.accrual_type_id === 1 || +this.benefitConf.accrual_type_id.id === 1);
    const isAnnualAccrual = this.benefitConf.accrual_type_id && (+this.benefitConf.accrual_type_id === 2 || +this.benefitConf.accrual_type_id.id === 2);
    const isMax100 = +this.benefitConf.accrual_type_id === 8 || +this.benefitConf.accrual_type_id.id === 8;
    if (isAnnualAccrual || isMax100) {
      this.editPhasingTerms();
    }

    for (let i = 0; i < (isOneTimeAccrual ? 1 : Number(this.default_term)); i++) {
      this.phasingTermArray.at(i).setValue({ val: this.benefitConf['phase_' + i] });
    }
  }

  editPhasingTerms() {
    this.phasingTermArray.clear();
    const isOneTimeAccrual = this.benefitConf.accrual_type_id && (+this.benefitConf.accrual_type_id === 1 || +this.benefitConf.accrual_type_id.id === 1);
    const defaultTerm = isOneTimeAccrual ? 1 : Number(this.default_term);
    const isMax100 = this.benefitConf.accrual_type_id && (+this.benefitConf.accrual_type_id === 8 || +this.benefitConf.accrual_type_id.id === 8);
    const defaultPhasingTerm = !isMax100 ? 100 : 100 / defaultTerm;
    const defaultPhasingTermString = formatNumber(defaultPhasingTerm, 'en-US', '1.0-2');
    for (let i = 0; i < defaultTerm; i++) {
      this.phasingTermArray.push(this.fb.group({ val: defaultPhasingTermString }));
    }
    if (isMax100) {
      this.phasingTermGroup.setValidators([CustomValidators.maxTotalValueValidator(100)]);
    } else {
      this.phasingTermGroup.clearValidators();
    }
  }

  overrideBenefit(benefitSituation) {
    benefitSituation.conservative = benefitSituation.sit_conservative || benefitSituation.conservative;
    benefitSituation.probable = benefitSituation.sit_probable || benefitSituation.probable;
    benefitSituation.aggressive = benefitSituation.sit_aggressive || benefitSituation.aggressive;
    benefitSituation.current_effort = benefitSituation.sit_current_effort || benefitSituation.current_effort;
    return benefitSituation;
  }

  getAccrualTypes() {
    this.factsService
      .getAccrualTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.accrualTypes = result.result;
      });
  }

  getScaleTypes() {
    this.factsService
      .getScaleTypes(1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.scaleTypes = result.result;
      });
  }

  saveSituationBenefitActive(active, benefit_id) {
    const data = {
      account_solution_situation_id: this.mode === 'edit' ? this.situationEdit.id : this.account_situation_id,
      account_solution_metric_id: benefit_id,
      active: active,
    };

    const resetPayload = {
      ...data,
      aggressive: null,
      conservative: null,
      current_effort: null,
      probable: null,
    };

    this.solutionService
      .saveSituationBenefitActive(data)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          if (active === 'NULL') {
            this.resetBenefitSitValues(resetPayload);
          }
        })
      )
      .subscribe((result) => {
        if (result.result.success) {
          this.notificationService.success(this.trans.trans.change_benefits_status_success.value, false);
          let updateBen = this.benefits.findIndex((x) => x.id === benefit_id);
          this.benefits[updateBen].changeToggle = true;
          this.benefits[updateBen].active = active;

          this.solutionService.triggerBenefit.next('reloadBenefits');
          this.getSituationTree();
        }
      });
  }

  resetBenefitSitValues(payload: any): void {
    this.solutionService
      .saveSolutionSituationBenefit(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result.success) {
          this.notificationService.success(this.trans.trans.benefit_reset_success.value, false);
          const updateBen = this.benefits.findIndex((benefit) => benefit.id === payload.account_solution_metric_id);
          this.benefits[updateBen].changeToggle = true;
          this.benefits[updateBen].active = 'NULL';
          this.solutionService.triggerBenefit.next('reloadBenefits');
          this.getSituationTree();
        } else {
          this.notificationService.error(this.trans.trans.change_benefits_status_fail.value, false);
        }
      });
  }

  saveSolutionSituationBenefit() {
    this.benefitConf.account_solution_situation_id = this.mode === 'edit' ? this.situationEdit.id : this.account_situation_id;
    this.benefitConf.account_solution_metric_id = this.benefitConf.id;
    this.benefitConf = this.overrideBenefit(this.benefitConf);
    this.phasingTermArray.controls.forEach((control, index) => {
      this.benefitConf['phase_' + index] = control.value.val;
    });
    this.solutionService
      .saveSolutionSituationBenefit(this.benefitConf)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result.success) {
          this.notificationService.success('Changes to ' + this.benefitConf.metric_name + ' saved!', false); // Params {message, islogout}
          this.benefits.find((x) => x.id === this.benefitConf.id).valueChangeToggle = true;
          this.solutionService.triggerBenefit.next('reloadBenefits');
          this.getSituationTree();
        } else {
          this.notificationService.error(this.trans.trans.something_went_wrong.value, false);
        }
        this.showBenefitSidebar = false;
      });
  }

  // updateBenefit(benefit) {
  //   this.situations.forEach((situation, index) => {
  //     if (this.situation['id'] === situation['id']) {
  //       if (this.situations[index]['benefits']) {
  //         this.situations[index]['benefits'].push(benefit[0]);
  //       } else {
  //         this.situations[index]['benefits'] = benefit;
  //       }
  //     }
  //   });
  // }

  deleteSituation(id) {
    this.situationLoader = true;
    this.solutionService
      .deleteSituation(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.situationLoader = false;
        this.notificationService.success(this.trans.trans.situation_deleted.value, false);
        this.getSituationsBySolution();
      });
  }

  saveSituationTypeElement() {
    this.situationElement.account_situation_type_id = this.situationType.id;
    this.solutionService
      .saveSituationTypeElement(this.situationElement)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.element_created_add_new.value, false);
        this.clearElement();
      });
  }

  clearElement() {
    this.situationElement = {};
  }

  clearSituationType() {
    this.situationType = {};
    if (this.toggles.situation_type_edit) {
      this.toggles.situation_type_edit = false;
      this.toggles.showList = false;
      this.toggles.situation_type_stack_order = true;
      this.toggles.addType = false;
    }
  }

  clearSituation() {
    this.situation = {};
  }

  // end class

  stackOrder(index, change, value) {
    if (!this.stackOrderEnabledAll || this.situations[index]['stackOrderEnabled']) {
      if (change === 'increase') {
        const x = parseInt(value) + 1;
        this.situations[index]['stack_order'] = x;
      } else if (change === 'decrease') {
        if (value > 0) {
          const x = parseInt(value) - 1;
          this.situations[index]['stack_order'] = x;
        } else {
          this.notificationService.error(this.trans.trans.no_negative.value, false);
        }
      }
      this.situations[index]['stackOrderEnabled'] = true;
      this.stackOrderEnabledAll = true;
      this.situations = this.situations.sort(function (a, b) {
        return a.stack_order - b.stack_order;
      });
    }
  }

  type: string;
  saveSituationCosts(cost, type) {
    this.type = type;

    let payload = {
      account_solution_situation_id: this.mode === 'edit' ? this.situationEdit.id : this.account_situation_id,
      account_solution_cost_id: cost['id'],
    };

    if (type === 'available') {
      payload['available'] = 1;
      payload['active'] = cost['changeToggleAutoload'];
    } else if (type === 'exclude') {
      payload['available'] = 0;
      payload['active'] = cost['changeToggleAutoload'];
    } else if (type === 'autoload') {
      payload['active'] = 1;
      payload['available'] = cost['changeToggleAvailable'];
    } else if (type === 'reset') {
    } else if (type === 'removeauto') {
      payload['active'] = 0;
      payload['available'] = cost['changeToggleAvailable'];
    }

    let payloadArray = {
      situation_costs: [payload],
    };

    this.solutionService
      .saveSituationCost(payloadArray, this.solutionId, cost['id'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          if (this.type === 'available') {
            this.notificationService.success(this.trans.trans.cost_added_success.value, false);
          } else if (this.type === 'exclude') {
            this.notificationService.success(this.trans.trans.cost_excluded_success.value, false);
          } else if (this.type === 'autoload') {
            this.notificationService.success(this.trans.trans.cost_autoload_success.value, false);
          }

          this.costs.find((x) => x.id === cost['id']).available = response.result[0]['available'];
          this.costs.find((x) => x.id === cost['id']).active = response.result[0]['active'];

          this.solutionService.triggerCost.next('reloadCosts');
        }
      });
  }

  resetAllCost() {
    let payloadArray = {
      situation_costs: [],
    };

    for (let i = 0; i < this.costs.length; i++) {
      payloadArray['situation_costs'].push({
        account_solution_situation_id: this.account_situation_id,
        account_solution_cost_id: this.costs[i]['id'],
      });
    }

    this.solutionService
      .saveSituationCost(payloadArray, this.account_situation_id, 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.resetCosts();
          this.getSituationsBySolution();
          this.solutionService.triggerCost.next('reloadCosts');
        }
      });
  }

  showEditView(situation) {
    this.mode = 'edit';
    this.situation = situation;
    this.situation['default_active'] = parseInt(situation['default_active']);
    if (this.feature88) {
      this.situation['guided_default_active'] = parseInt(situation['guided_default_active']);
    }
    this.situation_type_id = this.situation['situation_type_id'];
    this.account_situation_id = this.situation['id'];
    this.getSituationTypeElements(this.situation_type_id);
    this.getSituationTree();
    this.toggles.showList = false;
    this.toggles.addType = false;
    this.toggles.showSituationForm = true;
    this.toggles.showBenefitsCosts = true;

    this.selectedProject = undefined;
  }

  updateSituationTypeStackOrder() {
    const situationStackOrder = this.accountSituationType.map((item, index) => {
      const situation = {};
      situation['stack_order'] = index;
      situation['situation_type_id'] = item['id'];
      return situation;
    });

    const payload = {
      situation_types: situationStackOrder,
    };

    this.solutionService
      .updateSituationTypeStackOrder(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result['success']) {
          this.accountSituationType = response.result['message'];
          this.notificationService.success(this.trans.trans.stack_order_updated_success.value, false);
        }
      });
  }

  checkForChildren1(element_id) {
    this.parent1 = element_id;
    if (this.situation_type_id != 3) {
      this.situationTypeElements.forEach((element) => {
        if (element.id == element_id) {
          if (element.has_children) {
            this.parent1Name = element.name;
            this.solutionService
              .getSituationElementChildren(element_id)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result) => {
                if (result.result.success) {
                  this.elementChildren1 = result.result.message;
                  this.situation.situation_element_id = null;
                }
              });
          } else {
            this.situation.situation_element_id = element_id;
            this.elementChildren1 = [];
          }
          this.parent2 = null;
          this.parent3 = null;
          this.parent4 = null;
          this.parent5 = null;
          this.elementChildren2 = [];
          this.elementChildren3 = [];
          this.elementChildren4 = [];
          this.elementChildren5 = [];
        }
      });
    } else {
      let situationType = this.situationTypeElements.find((item) => item.id == element_id);
      this.parent1Name = situationType.name;
      this.elementChildren1 = situationType['children'];
    }
  }

  checkForChildren2(element_id) {
    this.parent2 = element_id;
    this.elementChildren1.forEach((element) => {
      if (element.id == element_id) {
        if (element.has_children) {
          this.parent2Name = element.name;
          this.solutionService
            .getSituationElementChildren(element_id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
              if (result.result.success) {
                this.elementChildren2 = result.result.message;
                this.situation.situation_element_id = null;
              }
            });
        } else {
          this.situationObj.situation_element_id = element_id;
          this.situation.situation_element_id = element_id;
          this.elementChildren2 = [];
        }

        this.parent3 = null;
        this.parent4 = null;
        this.parent5 = null;
        this.elementChildren3 = [];
        this.elementChildren4 = [];
        this.elementChildren5 = [];
      }
    });
  }

  checkForChildren3(element_id) {
    this.parent3 = element_id;
    this.elementChildren2.forEach((element) => {
      if (element.id == element_id) {
        if (element.has_children) {
          this.parent3Name = element.name;
          this.solutionService
            .getSituationElementChildren(element_id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
              if (result.result.success) {
                this.elementChildren3 = result.result.message;
                this.situation.situation_element_id = null;
              }
            });
        } else {
          this.situationObj.situation_element_id = element_id;
          this.situation.situation_element_id = element_id;
          this.elementChildren3 = [];
        }

        this.parent4 = null;
        this.parent5 = null;
        this.elementChildren4 = [];
        this.elementChildren5 = [];
      }
    });
  }

  checkForChildren4(element_id) {
    this.parent4 = element_id;
    this.elementChildren3.forEach((element) => {
      if (element.id == element_id) {
        if (element.has_children) {
          this.parent4Name = element.name;
          this.solutionService
            .getSituationElementChildren(element_id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
              if (result.result.success) {
                this.elementChildren4 = result.result.message;
                this.situation.situation_element_id = null;
              }
            });
        } else {
          this.situationObj.situation_element_id = element_id;
          this.situation.situation_element_id = element_id;
          this.elementChildren4 = [];
        }
        this.parent5 = null;
        this.elementChildren5 = [];
      }
    });
  }

  checkForChildren5(element_id) {
    this.parent5 = element_id;
    this.elementChildren4.forEach((element) => {
      if (element.id == element_id) {
        if (element.has_children) {
          this.parent5Name = element.name;
          this.solutionService
            .getSituationElementChildren(element_id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
              if (result.result.success) {
                this.elementChildren5 = result.result.message;
                this.situation.situation_element_id = null;
              }
            });
        } else {
          this.situationObj.situation_element_id = element_id;
          this.situation.situation_element_id = element_id;
        }
      }
    });
  }

  saveSituationCaseStudyActive(active, cs_id) {
    const data = {
      situation_case_studies: [
        {
          account_solution_situation_id: this.mode === 'edit' ? this.situationEdit.id : this.account_situation_id,
          account_case_study_id: cs_id,
          active: active,
        },
      ],
    };

    this.solutionService
      .saveSituationCaseStudyActive(data, this.solutionId, cs_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notificationService.success(this.trans.trans.case_study_changed.value, false);
          let caseIndex = this.caseStudies.findIndex((x) => x.id === cs_id);
          this.caseStudies[caseIndex].active = active;
          this.getSituationsBySolution();
        }
      });
  }

  saveSituationAssetsActive(active, as_id) {
    const data = {
      situation_assets: [
        {
          account_solution_situation_id: this.mode === 'edit' ? this.situationEdit.id : this.account_situation_id,
          account_asset_template_id: as_id,
          active: active,
        },
      ],
    };

    this.solutionService
      .saveSituationAssetActive(data, this.solutionId, as_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notificationService.success(this.trans.trans.asset_status_changed.value, false);
          this.assets.find((x) => x.id === as_id).changedActive = response.result[0]['active'];
          this.getSituationTree();
        }
      });
  }

  saveSituationCollateralActive(active, cl_id) {
    const data = {
      situation_collaterals: [
        {
          account_solution_situation_id: this.mode === 'edit' ? this.situationEdit.id : this.account_situation_id,
          account_collateral_id: cl_id,
          active: active,
        },
      ],
    };

    this.solutionService
      .saveSituationCollateralActive(data, this.solutionId, cl_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notificationService.success(this.trans.trans.collateral_status_changed.value, false); // Params {message, islogout}
          this.collateral.find((x) => x.id === cl_id).changedActive = response.result[0]['active'];
          this.getSituationTree();
        }
      });
  }

  saveSituationProjectsActive(type, project) {
    let payload = {
      account_solution_situation_id: this.mode === 'edit' ? this.situationEdit.id : this.account_situation_id,
      account_project_type_id: project['account_project_type_id'],
    };

    if (type === 'addToList') {
      payload['active'] = 1;
      payload['recommend'] = project['changedRecommend'] === undefined ? null : project['changedRecommend'];
    } else if (type === 'removeFromList') {
      payload['active'] = 0;
      payload['recommend'] = project['changedRecommend'] === undefined ? null : project['changedRecommend'];
    } else if (type === 'addAndRecommend') {
      payload['active'] = 1;
      payload['recommend'] = 1;
    } else if (type === 'reset') {
    }

    let payloadArray = {
      situation_projects: [payload],
    };

    this.solutionService
      .saveSituationProjectsActive(payloadArray)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.notificationService.success(this.trans.trans.project_status_changed.value, false);
          this.projectsList.find((x) => x.id === project['id']).changedActive = response.result[0]['active'];
          this.projectsList.find((x) => x.id === project['id']).changedRecommend = response.result[0]['recommend'];
          this.getSituationsBySolution();
          this.getSituationTree();
        }
      });
  }

  getFAIcon(format) {
    switch (format) {
      case 'MS Word':
        return 'fa fa-file-word-o';

      case 'MS Excel':
        return 'fa fa-file-excel-o';

      case 'MS PowerPoint':
        return 'fa fa-file-powerpoint-o';

      case 'MS PDF':
        return 'fa fa-file-pdf-o';

      default:
        return 'fa fa-cloud-download';
    }
  }

  getFAColor(format) {
    switch (format) {
      case 'MS Word':
        return { 'color': '#2e5b97', 'margin-right': '5px' };

      case 'MS Excel':
        return { 'color': '#3a6e46', 'margin-right': '5px' };

      case 'MS PowerPoint':
        return { 'color': '#c55737', 'margin-right': '5px' };

      case 'MS PDF':
        return { 'color': '#c60a09', 'margin-right': '5px' };

      default:
        return { 'color': 'black', 'margin-right': '5px' };
    }
  }

  getBreadCrumbForEdit(situationData) {
    this.breadcrumb = situationData.data.name ? this.breadcrumb + '> ' + situationData.data.name + ' ' : '';

    if (situationData.children) {
      this.getBreadCrumbForEdit(situationData.children[0]);
    } else {
      return situationData;
    }
  }

  getlastChild(situationTree) {
    let last;

    let getLast = (sit) => {
      if (sit.children) {
        getLast(sit.children[0]);
      } else {
        last = sit.data;
      }
    };
    getLast(situationTree);
    return last;
  }

  resetSituation() {
    this.situationObj = this.defaultSituation;
    this.situationForm.reset();
    this.situation.situation_element_id = null;
    this.parent1 = null;
    this.parent2 = null;
    this.parent3 = null;
    this.parent4 = null;
    this.parent5 = null;
    this.elementChildren1 = [];
    this.elementChildren2 = [];
    this.elementChildren3 = [];
    this.elementChildren4 = [];
    this.elementChildren5 = [];
    this.situationsItemsMenuView = false;
  }
}
