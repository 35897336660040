import { Injectable } from '@angular/core';

@Injectable()
export class AccountVpDashTranslations {
  public config: any = {
    component: 'accountVpDash',
    display_name: 'Value Prop Account',
    description: 'Account tab in value prop dashboard bottom panel',
  };

  public trans: any = {
    account: 'Account',
    addr1: 'Address 1',
    addr2: 'Address 2',
    city: 'City',
    company_name: 'Company Name',
    country: 'Country',
    zip: 'Zip Code',
    edit: 'Edit',
    editing: 'Editing Company Info',
    employees: 'Employees',
    gross: 'Gross Profits',
    id: 'Account ID',
    optional: 'Optional Information',
    phone: 'Phone #',
    required: 'Required',
    revenue: 'Revenue',
    save: 'Save',
    state: 'State',
    ticker: 'Ticker',
    website: 'Website URL',
    industry_sector: 'Industry Sector',
    industry: 'Industry',
    key_figures: 'Key Figures',
    name: 'Name',
    createdBy: 'Created By',
    ch_account: `The definition of your sales opportunity. Who are you
        selling, what are you selling, and what special situations
        are you aware of.`,
    vcAccountId: 'VC Account ID',
    crmAccountID: 'CRM Account ID',
    revenueM: 'Revenue M',
  };
}
