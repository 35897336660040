import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProblemComponent } from './problem.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuModule } from 'primeng/menu';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ProblemVpDashTranslations } from './problem.translation';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	imports: [
		MenuModule,
    MatTooltipModule,
		NgbModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		ApplicationPipesModule
	],
	declarations: [

		ProblemComponent
	],
	providers: [
		TranslationsV2Service,
		ProblemVpDashTranslations
	],
	exports: [ProblemComponent]
})
export class ProblemModule { }
