import { Injectable } from '@angular/core';

@Injectable ()

export class CaseStudiesVpDashTranslations {

    public config: any = {
        component: 'caseStudiesVpDash',
        display_name: 'Value Prop Case Studies',
        description: 'Case studies tab in value prop dashboard'
    };

    public trans: any = {
        name: 'Name',
        noCaseStudies: 'No case studies found',
        ch_case_studies: `Case Studies are a structured capture of Testimonial,
        objectives, and specific benefit improvements achieved by
        a particular customer and value proposition after they have
        implemented your solution.`,
        case_studies: 'Case Studies',
        back: 'Back'
    };
}
