<div class="row" *ngIf="style2022$ | async">
	<div class="col-md-6 mr-auto breadcrumb-container">
		<app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
	</div>
	<div class='col-xs-2 col-md-6 pl-auto'>
		<a (click)='startTour()' class='pull-right link clickable' style='margin-top: 8px; margin-left: 10px;'>{{trans.trans.take_a_tour.value }}</a>
	</div>
</div>

<div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10 old-breadcrumb">
	  <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToProfile.value }}</a>
	</div>
	<div class='col-xs-2 col-md-6 pl-auto'>
		<a (click)='startTour()' class='pull-right link clickable' style='margin-top: 8px; margin-left: 10px;'>{{trans.trans.take_a_tour.value }}</a>
	</div>
</div>

<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-6'>
					<div class='title'>
						{{trans.trans.adoptionReort.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
						[transObj]="trans.trans.adoptionReort" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>

				<div class='col-xs-3'>
					<p-dropdown appendTo="body" [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''" [options]="ChartColorOptions" [(ngModel)]="selectedChartColor" (onChange)='funcSetChartColors()' class='keywest_dropdown pull-right'></p-dropdown>
				</div>
				<div class='col-xs-3'>
					<a href='{{btn1URL}}' class="btn btn_customizable pull-right" target='_blank'>
						<i class="fa fa-download"></i>
						{{trans.trans.download_report.value}}
					</a>
				</div>
			</div>
		</div>

		<div class='models_panel' style='margin-bottom: 20px;'>
			<div class='models_page_header_container_small'>
				<div class='keywest_header'>
					<div class='row'>
						<div class='col-xs-6'>
							<div class='title accordion-title' (click)='showExclude = !showExclude'>
								<i *ngIf="!showExclude" class='fa fa-chevron-right'></i>
								<i *ngIf="showExclude" class='fa fa-chevron-down'></i>

								{{trans.trans.excluded_user_list.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
								[transObj]="trans.trans.excluded_user_list" (callback)="getTranslations()"></app-editTranslation>
							</div>
						</div>
					</div>
				</div>

				<div class='row' *ngIf="showExclude">
					<div class='col-xs-12'>

						<p-autoComplete [multiple]="true" placeholder='{{trans.trans.searchForUser.value}}' field='name' [(ngModel)]="selectedExcludedList" [suggestions]="filteredUserList" (completeMethod)="funcExclude($event)" [ngStyle]="{'width': '90%'}"></p-autoComplete>
						<a (click)='updateExcludeList()' class='btn btn-success btn_customizable'>{{trans.trans.save.value}}</a>
					</div>
				</div>
			</div>
		</div>

		<div class='models_panel' style='margin-bottom: 20px;'>
			<div class='models_page_header_container_small'>
				<div class='keywest_header'>
					<div class='row'>
            <div class="col-xs-2">
              <p-multiSelect
                [panelStyleClass]="(style2022$ | async) ? 'multi-select-item-font' : ''"
                id='tourShowHidePanels'
                appendTo='body'
                [options]="panels"
                [placeholder]="trans.trans?.choose?.value"
                [(ngModel)]="selectedPanels"
                [selectedItemsLabel]="'{0} items selected'"
                (onChange)='multiSelectChanged()'
              ></p-multiSelect>
            </div>
						<div class='col-xs-10'>
							<span id='tourDates'>
								<p-calendar [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''" appendTo='body' dateFormat='yy-mm-dd' [showIcon]='true' icon='fa fa-calendar' placeholder='{{trans.trans.dateStart.value}}' [(ngModel)]="dteStart" [showTime]="true" hourFormat="24"></p-calendar>
								<p-calendar [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''" appendTo='body' dateFormat='yy-mm-dd' [showIcon]='true' icon='fa fa-calendar' placeholder='{{trans.trans.dateEnd.value}}' [(ngModel)]="dteEnd" [showTime]="true" hourFormat="24"></p-calendar>
								<a (click)='funcGetAdoptionReport()' class='btn btn-success btn_customizable marg-h' style='height: 34px;'><i class='fa fa-filter'></i> {{trans.trans.filter.value}}</a>
								<a (click)='dteStart = ""; dteEnd = ""; funcGetAdoptionReport()' class='btn btn-info btn_customizable marg-h' style='height: 34px;'><i class='fa fa-filter'></i> {{trans.trans.get_all_data.value}}</a>
							</span>
						</div>
					</div>
				</div>

				<div class='row' *ngIf="loaded">
					<div class='col-xs-12'>
						<app-table-chart *ngIf="selectedPanels.indexOf('noLogin') > -1" identifier='noLogin' [data]='data.no_logins_data' [cols]='data.no_logins_cols' [toggleable]='false' [title]='trans.trans.no_logins.value' [onLabel]='trans.trans.chart.value'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('topCustomerValue') > -1" identifier='topCustomerValue' [data]='data.top_cust_value_data' [cols]='data.top_cust_value_cols' [toggleable]='true' [title]='trans.trans.top_customer_value.value' [onLabel]='trans.trans.chart.value' chartType='bar' [chartData]='data.top_cust_value_chartdata'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('top_acct_value') > -1" identifier='top_acct_value' [data]='data.top_acct_value_data' [cols]='data.top_acct_value_cols' [toggleable]='true' [title]='trans.trans.top_account_value.value' [onLabel]='trans.trans.chart.value' chartType='bar' [chartData]='data.top_acct_value_chartdata'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('top_delivered') > -1" identifier='top_delivered' [data]='data.top_delivered_data' [cols]='data.top_delivered_cols' [toggleable]='true' [title]='trans.trans.top_delivered.value' [onLabel]='trans.trans.chart.value' chartType='pie' [chartData]='data.top_delivered_chartdata'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('noVPs') > -1" identifier='noVPs' [data]='data.no_vps_data' [cols]='data.no_vps_cols' [toggleable]='false' [title]='trans.trans.no_value_props.value' [onLabel]='trans.trans.chart.value'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('top_creators') > -1" identifier='top_creators' [data]='data.top_creators_data' [cols]='data.top_creators_cols' [toggleable]='true' [title]='trans.trans.top_creators.value' [onLabel]='trans.trans.chart.value' chartType='bar' [chartData]='data.top_creators_chartdata'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('login_by_month') > -1" identifier='login_by_month' [data]='data.login_by_month_data' [cols]='data.login_by_month_cols' [toggleable]='true' [title]='trans.trans.logins_by_month.value' [onLabel]='trans.trans.chart.value' chartType='bar' [chartData]='data.login_by_month_chartdata'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('vp_modified_by_month') > -1" identifier='vp_modified_by_month' [data]='data.vp_modified_by_month_data' [cols]='data.vp_modified_by_month_cols' [toggleable]='true' [title]="trans.trans.vps_modified_by_month.value" [onLabel]='trans.trans.chart.value' chartType='bar' [chartData]='data.vp_modified_by_month_chartdata'></app-table-chart>
						<app-table-chart *ngIf="selectedPanels.indexOf('vp_created_by_month') > -1" identifier='vp_created_by_month' [data]='data.vp_created_by_month_data' [cols]='data.vp_created_by_month_cols' [toggleable]='true' [title]="trans.trans.vps_created_by_month.value" [onLabel]='trans.trans.chart.value' chartType='bar' [chartData]='data.vp_created_by_month_chartdata'></app-table-chart>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ngx-guided-tour></ngx-guided-tour>