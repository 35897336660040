<div class="row" *ngIf="loadingAssetElementTypes">
  <div class="col-xs-12">
    {{ trans.trans.loadingElements.value }}<br />
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
</div>

<form name="AccountAssetsElementsForm" id="ngForm" #AccountAssetsElementsForm="ngForm">
  <div class="row">
    <div class="col-xs-12">
      <h4>
        {{ trans.trans.pick_asset_element.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.pick_asset_element"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </h4>
    </div>
  </div>

  <div class="row" style="margin-bottom: 20px" *ngFor="let elementType of assetElementTypes">
    <div class="col-xs-12" *ngIf="selectedElem?.format_type_id != '1' || elementType.id != '1'">
      <p-panel [header]="elementType.name" [collapsed]="false" [toggleable]="true">
        <ng-container [ngSwitch]="elementType.id">
          <ng-container *ngSwitchCase="'1'">
            <p-multiSelect
              [options]="elementType.elements"
              (onChange)="notifyChanges()"
              [(ngModel)]="selectedSlides"
              [defaultLabel]="trans.trans.noSlidesSelected.value"
              optionLabel="name"
              appendTo="body"
              [ngModelOptions]="{ standalone: true }"
            ></p-multiSelect>
            <div class="asset-row" *ngFor="let element of selectedSlides; let i = index">
              <div class="asset-grid">
                <div>
                  <label class="sidebar_label" style="margin: auto">{{ element.name }}</label>
                </div>
                <div>
                  <input
                    autocomplete="false"
                    id="search-1password-disable"
                    type="number"
                    (input)="element.update = true; notifyChanges()"
                    min="1"
                    [placeholder]="trans.trans.page.value"
                    [name]="'elements_page' + element.id"
                    [(ngModel)]="element.page"
                    myNumberOnly
                  />
                </div>
              </div>
              <div class="asset-grid">
                <div></div>
                <div class="requirements" [class.invalid]="!element.page">
                  {{ trans.trans.pageNumberError.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.pageNumberError"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'2'">
            <p-multiSelect
              [options]="elementType.elements"
              (onChange)="notifyChanges()"
              [(ngModel)]="selectedCharts"
              [defaultLabel]="trans.trans.noChartsSelected.value"
              optionLabel="name"
              appendTo="body"
              [ngModelOptions]="{ standalone: true }"
            ></p-multiSelect>
            <div *ngIf="elementType.id === '2'">
              <div class="row">
                <div class="col-sm-12">
                  <h3>{{ trans.trans.orderSelected.value }} {{ elementType.name }} {{ trans.trans.here.value }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7">
                  <strong
                    >{{ trans.trans.element_name.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.element_name"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </strong>
                </div>
                <div class="col-md-4 text-center">
                  <strong
                    >{{ trans.trans.stack_order.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.stack_order"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </strong>
                </div>
              </div>

              <div class="row dragObjectsParent" cdkDropList (cdkDropListDropped)="drop($event, selectedCharts)">
                <div *ngFor="let element of selectedCharts; let i = index" class="row dragObjects clickable" cdkDrag [cdkDragData]="element" >
                  <div class="col-md-7">
                    <p cdkDragHandle>{{ element.name }}</p>
                  </div>
                  <div class="col-md-4 text-center">
                    <strong>{{ i + 1 }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'3'">
            <p-multiSelect
              [options]="elementType.elements"
              [(ngModel)]="selectedBlocks"
              [defaultLabel]="trans.trans.noBlockSelected.value"
              optionLabel="name"
              (onChange)="notifyChanges()"
              [ngModelOptions]="{ standalone: true }"
            ></p-multiSelect>
            <div class="asset-row" *ngFor="let element of selectedBlocks; let i = index">
              <div class="asset-grid">
                <div>
                  <label class="sidebar_label">{{ element.name }}</label>
                </div>
                <div>
                  <input
                    autocomplete="false"
                    id="search-1password-disable"
                    type="number"
                    (input)="element.update = true; notifyChanges()"
                    min="1"
                    [placeholder]="trans.trans.page.value"
                    [name]="'elements_page' + element.id"
                    [(ngModel)]="element.page"
                    myNumberOnly
                  />
                </div>
              </div>
              <div class="asset-grid">
                <div></div>
                <div class="requirements" [class.invalid]="!element.page">
                  {{ trans.trans.blockNumberError.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.blockNumberError"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </p-panel>
    </div>
  </div>
</form>