import { Injectable } from '@angular/core';
import { BusinessFactor } from '@data/services/goal-groups/models/business-goals-response.interface';
import { BusinessReviewData, BusinessReviewDataWithNote } from '@data/services/valuerealization/models/business-review.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { ValuePropGoal } from '@shared/models/goal-group.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TrackerBusinessReviewService {
  selectedBusinessReview: BusinessReviewData;
  valuePropId: string;
  businessReviews: BusinessReviewData[];
  businessReviews$ = new BehaviorSubject<BusinessReviewData[]>(null);
  private businessReview$ = new BehaviorSubject<BusinessReviewData>(null);
  private businessReviewEdition$ = new BehaviorSubject<boolean>(false);
  private businessReviewRefresh$ = new BehaviorSubject<boolean>(false);
  public benefitRefresh$ = new BehaviorSubject<boolean>(false);
  private newBusinessReview$ = new Subject<number>();
  private goals$ = new Subject<ValuePropGoal[]>();
  private canEdit$ = new BehaviorSubject<boolean>(false);
  public showBenefitSaved = false;
  public brsWithNotes$ = new BehaviorSubject<BusinessReviewDataWithNote[]>(null);

  constructor(private valueRealizationService: ValuerealizationService) {}

  get newBusinessReview(): Observable<number> {
    return this.newBusinessReview$.asObservable();
  }

  get businessReviewEdition(): Observable<boolean> {
    return this.businessReviewEdition$.asObservable();
  }

  get businessReviewRefresh(): Observable<boolean> {
    return this.businessReviewRefresh$.asObservable();
  }

  benefitRefresh(): void {
    this.benefitRefresh$.next(true);
  }

  get businessReview(): Observable<BusinessReviewData> {
    return this.businessReview$.asObservable();
  }

  get goals(): Observable<ValuePropGoal[]> {
    return this.goals$.asObservable();
  }

  get canEdit(): Observable<boolean> {
    return this.canEdit$.asObservable();
  }

  businessReviewData(valuePropId?: string): Observable<BusinessReviewData[]> {
    const vpId = valuePropId ? valuePropId : this.valuePropId;
    return this.valueRealizationService.getBusinessReviewData(vpId).pipe(
      tap((reviews) => {
        this.businessReviews = reviews;
        this.businessReviews$.next(reviews);
      })
    );
  }

  selectBusinessReview(review: BusinessReviewData): void {
    this.selectedBusinessReview = review;
    this.businessReview$.next(review);
  }

  toggleEdition(state: boolean) {
    this.businessReviewEdition$.next(state);
  }

  refreshBusinessReview() {
    this.businessReviewRefresh$.next(true);
  }

  shareNewBusinessReviewId(id: number): void {
    this.newBusinessReview$.next(id);
  }

  saveGoalsInMemory(goals: ValuePropGoal[]): void {
    this.goals$.next(goals);
  }

  setCanEdit(state: boolean) {
    this.canEdit$.next(state);
  }

  deleteBusinessReview(id: number) {
    this.businessReviews = this.businessReviews.filter((review) => review.id !== id);
    this.businessReviews$.next(this.businessReviews);
    const newReview = this.businessReviews[0];
    this.selectBusinessReview(newReview);
    this.toggleEdition(false);
  }

  clearBusinessReviews() {
    this.selectedBusinessReview = null;
    this.valuePropId = null;
    this.businessReviews = null;
    this.businessReviews$.next(null);
    this.businessReview$.next(null);
  }
}
