import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DirectivesModule } from 'app/_directives/directives.module';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { AEFactorsFormComponent } from './ae_factors.component';
import { DefaultTranslations } from './ae_factors.translation';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FactorsUsedTableComponent } from './factors-used-table/factors-used-table.component';
import { FactorsRelationTableComponent } from './factors-relation-table/factors-relation-table.component';
import { FactorsSituationTableComponent } from './factors-situation-table/factors-situation-table.component';
import { TableModule } from 'primeng/table';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { TreeTableModule } from 'primeng/treetable';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    MatTooltipModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    CommonModule,
    FormsModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    EditTranslationsModule,
    DirectivesModule,
    AreYouSureModule,
    InputNumberModule,
    SelectButtonModule,
    TableModule,
    TreeTableModule,
    NoRecordsModule,
    ProgressSpinnerModule,
  ],
  declarations: [AEFactorsFormComponent, FactorsUsedTableComponent, FactorsRelationTableComponent, FactorsSituationTableComponent],
  exports: [AEFactorsFormComponent],
  providers: [DefaultTranslations, TranslationsV2Service],
})
export class AEFactorsFormModule {}
