<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="'style-2022'"></p-menu>
<p-menu #shared appendTo="body" [popup]="true" [model]="sharedWith" [styleClass]="'style-2022'"></p-menu>
<p-menu #menuStatus appendTo="body" [popup]="true" [model]="valuePropStatusTypes" [styleClass]="'style-2022'"></p-menu>
<app-choose-adventure-internal
  #chooseAdventure
  (onHidden)="onHidden($event)"
  [ff134]="ff134"
  [showStepSelection]="showStepSelection"
  [workflows]="workflows"
></app-choose-adventure-internal>

<div class="row" style="margin-bottom: 20px">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs *ngIf="breadcrumbKeyValues" [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6"></div>
      </div>
    </div>
    <div class="row marg-v">
      <div class="col-xs-12 col-md-3">
        <div id="hypo-search" class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input
            [(ngModel)]="strSearch"
            (keyup)="tableValuePosition.filter($any($event).target.value, 'name', 'contains')"
            type="text"
            placeholder="{{ trans.trans.search.value }}"
            class="form-control"
          />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableValuePosition.filter('', 'name', 'contains')" class="input-group-addon clickable"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <p-selectButton [options]="sharedWith" (onChange)="getValuePositionList()" [(ngModel)]="creator_filter" styleClass="keywest_select_button"></p-selectButton>
      </div>
      <div class="col-xs-12 col-md-5" style="display: flex; justify-content: end;">
        <p-checkbox 
          [binary]="true" 
          class="marg-h" 
          *ngIf="hasUnfinishedVps && viewAll" 
          name="hasUnfinished" 
          [(ngModel)]="includeCalcs"
          [label]="trans.trans.showUnfinished.value"
          (onChange)="getValuePositionList()"
        >
        </p-checkbox>
        <button
          class="btn btn_customizable pull-right marg-h"
          (click)="onAddHypoClicked('valueposition')"
          [matTooltip]="trans.trans.ch_add.value"
          matTooltipPosition="below"
          *ngIf="showAddButtons"
        >
          <i class="fa fa-plus"></i>{{ trans.trans.addNew.value }}
        </button>
      </div>
    </div>
  </div>
  <div class="models_page_header_container_small" [ngClass]="{ 'full-height': (tableRecords$ | async) === 0 }">
    <div class="row">
      <div class="col-xs-12">
        <!-- Skeleton Loader -->
        <app-table-skeleton [trans]="trans.trans" *ngIf="loading" [columns]="columns" [rowLimit]="pageLimit"></app-table-skeleton>
        <p-table
          [ngClass]="loading ? 'hidden-table' : ''"
          #tableValuePosition
          [columns]="columns"
          [value]="valuePositionList"
          (sortFunction)="funcSort($event)"
          [customSort]="true"
          [paginator]="true"
          responsiveLayout="scroll"
          [rows]="pageLimit"
          styleClass="keywest_table"
          tableStyleClass="keywest_table"
          [rowsPerPageOptions]="[10, 25, 50, 100]"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.sortField" style="white-space: nowrap">
                {{ trans.trans[col.field].value }}
                <p-sortIcon [field]="col.sortField"></p-sortIcon>
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th style="text-align: center">
                <p-sortIcon (click)="resetSort()" *ngIf="sorted" [matTooltip]="trans.trans.unsort.value" style="cursor: pointer"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
            <tr *ngIf="!loading">
              <td>
                <a *ngIf="!data.is_group" (click)="chooseStep('valueposition', data.id)" class="link">{{ data.name }}</a>
                <a *ngIf="data.is_group" (click)="chooseStep('groupv1', data.id)" class="link">{{ data.name }}</a>
                <span
                  *ngIf="!data.is_group"
                  class="material-icons-outlined tooltip-custom info-icon light"
                  (mouseout)="op.toggle($event)"
                  (mouseover)="getValuePropDetails(data, op, $event, data.id)"
                >
                  info
                </span>
                <i
                  class="fa fa-plus"
                  style="margin-left: 10px"
                  *ngIf="data.value_props"
                  (click)="getValuePropGroup(data, group, $event)"
                  [attr.aria-expanded]="data.isCollapsed"
                ></i>
              </td>
              <td>{{ data.company_name }}</td>
              <td>
                <span>
                  {{ data.solutions_display }}
                </span>
              </td>
              <td>{{ data.total_benefits_fmt }}</td>
              <td>{{ data.total_costs_fmt }}</td>
              <td>{{ data.vp_creator_name }}</td>
              <td>
                <span class="date">{{ data.created | safariDate | date: 'MMM d, y, HH:mm ' }}</span>
              </td>
              <td>
                <span (click)="getValuePropStatusTypes(data); menuStatus.toggle($event)" class="status-wrapper">
                  <span class="prop-status">{{ data.status }} </span>
                  <span class="material-icons-outlined dropdown-icon">arrow_drop_down</span>
                </span>
              </td>
              <td>
                <app-ellipsis-menu>
                  <ul class="list-group">
                    <!-- Share -->
                    <li class="list-group-item" (click)="selectedCase = data; toggleShare = !toggleShare">
                      <span style="font-size: 13px; margin-right: 2px" class="material-icons"> share </span>
                      {{ this.trans.trans.share.value }}
                    </li>
                    <!-- Clone -->
                    <li class="list-group-item" (click)="selectedCase = data; showClone = !showClone">
                      <span style="font-size: 13px; margin-right: 2px" class="material-icons-outlined"> file_copy </span>{{ this.trans.trans.clone.value }}
                    </li>
                    <!-- Delete -->
                    <li *ngIf="data | functionPipe: checkDeletePermission:{ priv1, priv12 }" class="list-group-item">
                      <app-are-you-sure
                        style="color: red"
                        (callbackYes)="deleteValueProp(data)"
                        [text]="trans.trans.delete.value"
                        icon="fa fa-trash"
                        [message]="trans.trans.areYouSure.value"
                      ></app-are-you-sure>
                    </li>
                  </ul>
                </app-ellipsis-menu>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!loading" class="not-hover">
              <td [attr.colspan]="columns.length + 1" style="padding-top: 100px">
                <app-no-records width="200px" [records]="valuePositionList.length > 0">
                  <ng-container *ngIf="valuePositionList.length === 0">
                    {{ trans.trans.noRecords.value }}
                  </ng-container>
                  <ng-container *ngIf="valuePositionList.length > 0"> {{ trans.trans.noResults.value }} "{{ strSearch }}" </ng-container>
                </app-no-records>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<p-sidebar *ngIf="toggleShare" [(visible)]="toggleShare" styleClass="keywest_sidebar p-sidebar-xl" position="right">
  <app-share-v2
    [fromRepSimple]="false"
    [embedded]="false"
    (callbackBack)="toggleShare = !toggleShare"
    [showTranslate]="showTranslate"
    [showHelp]="false"
    [data]="selectedCase"
  ></app-share-v2>
</p-sidebar>

<p-sidebar *ngIf="showClone" [(visible)]="showClone" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <app-vp-clone [valueProp]="selectedCase" (callbackCancel)="showClone = !showClone"></app-vp-clone>
</p-sidebar>

<p-overlayPanel #group [dismissable]="true" appendTo="body" [showCloseIcon]="true" styleClass="keywest_tooltip_overlay">
  <div *ngIf="valueposition">
    <div class="row" *ngFor="let v of valueposition.value_props">
      <div class="col-xs-12">
        <a routerLink="/valueposition/{{ v.id }}" class="link">{{ v.name }}</a>
      </div>
    </div>
  </div>
</p-overlayPanel>

<p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="keywest_tooltip_overlay">
  <p-progressBar *ngIf="loadingVPDetails" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
  <div *ngIf="valueposition && !loadingVPDetails">
    <div class="row">
      <div class="col-xs-5">
        <label>{{ trans.trans.vp_name.value }} </label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.name }}</span>
      </div>
    </div>
    <div class="row" *ngIf="valueposition.solutionItems && valueposition.solutionItems.length">
      <div class="col-xs-5">
        <label>{{ trans.trans.models.value }}</label>
      </div>
      <div class="col-xs-7">
        <div *ngFor="let elem of valueposition.solutionItems">{{ elem.name }}<br /></div>
      </div>
    </div>
    <div class="row" *ngIf="valueposition.year1_benefits_fmt">
      <div class="col-xs-5">
        <label>{{ trans.trans.year1.value }}</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.year1_benefits_fmt }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-5">
        <label>{{ trans.trans.total_benefits.value }}</label>
      </div>
      <div class="col-xs-7">
        <span>{{ valueposition.total_benefits_fmt }}</span>
      </div>
    </div>
    <div class="row" *ngIf="valueposition.roi_decorated">
      <div class="col-xs-5">
        <label>{{ trans.trans.roi.value }}</label>
      </div>
      <div class="col-xs-7">
        {{ valueposition.roi_decorated }}
      </div>
    </div>
    <div class="row" *ngIf="valueposition.payback">
      <div class="col-xs-5">
        <label>{{ trans.trans.payback.value }}</label>
      </div>
      <div class="col-xs-7">{{ valueposition.payback }}<span>months</span></div>
    </div>
  </div>
</p-overlayPanel>
