import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AssetType, StandardPath } from '@data/services/valueprop/models/asset.model';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AssetsManageProfileTranslations } from '../assets.translation';
import { SelectedAsset } from '../interfaces/selected-asset.interface';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-asset-basics',
  templateUrl: './asset-basics.component.html',
  styleUrls: ['./asset-basics.component.scss'],
})
export class AssetBasicsComponent implements OnInit, OnDestroy {

  assetTypes: AssetType[];
  ngUnsubscribe = new Subject();
  showTranslate = false;
  loadingAssetTypes = false;
  standardPaths: StandardPath[] = [];
  lightTheme: boolean = false;

  @Input() selectedAsset: SelectedAsset = {
    asset_type_id: '1',
    customer_facing: false
  };
  @Input() mode: 'add' | 'edit' = 'add';
  @Output() changes = new EventEmitter();

  constructor(
    public trans: AssetsManageProfileTranslations,
    private factsService: FactsService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    this.lightTheme = document.getElementById('custom-theme') !== null;

    this.getAssetTypes();

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAssetTypes(): void {
    this.loadingAssetTypes = true;

    this.factsService
      .getAssetTypes()
      .pipe(
        finalize(() => this.loadingAssetTypes = false)
      )
      .subscribe((response) => {
        this.assetTypes = response.result;

        let selectedType = this.assetTypes.find((elem) => elem.id == this.selectedAsset.asset_type_id);
    
        if ( selectedType.standard_paths?.length > 0 ) {
          this.standardPaths = selectedType.standard_paths.filter((elem) => elem.files?.length > 0);
          this.standardPaths.map((elem) => elem.expanded = true);
        } else {
          this.standardPaths = [];
        }
      });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  notifyChanges() {
    let selectedType = this.assetTypes.find((elem) => elem.id == this.selectedAsset.asset_type_id);
    
    if ( selectedType.standard_paths?.length > 0 ) {
      this.standardPaths = selectedType.standard_paths.filter((elem) => elem.files?.length > 0);
      this.standardPaths.map((elem) => elem.expanded = true);
    } else {
      this.standardPaths = [];
    }

    this.changes.emit();

  }

}
