import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tracker-benefit-notes',
  templateUrl: 'tracker-benefit-notes.component.html',
})
export class TrackerBenefitNotesComponent implements OnInit {
  @Input() benefit: any;
  @Input() can_edit: boolean = false;

  @Output() callback = new EventEmitter();

  note: {
    title: string;
    name: string;
    note: string;
  } = {
    title: undefined,
    name: undefined,
    note: undefined,
  };

  constructor() {}

  ngOnInit() {
    this.note.note = this.benefit.testimonial;
    this.note.title = this.benefit.testimonial_title;
    this.note.name = this.benefit.testimonial_name;
  }

  saveNote() {
    let payload = {
      value_survey_id: this.benefit.recent_survey_id,
      testimonial_title: this.note.title,
      testimonial_name: this.note.name,
      testimonial: this.note.note,
      account_solution_metric_id: this.benefit.account_solution_metric_id,
    };

    this.callback.emit(payload);
  }
}
