import { ValueProp } from '@shared/models/value-prop.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InfoActionsHeaderTranslation } from './info-actions-header.translation';
import { MenuItem } from 'primeng/api';
import { VpTooltipPipe } from 'app/pipe/vp-tooltip.pipe';
import { StepperMenuItem } from '@shared/models/stepper-menu.modles';

@Component({
  selector: 'app-value-prop-info-actions-header',
  templateUrl: './info-actions-header.component.html',
  styleUrls: ['./info-actions-header.component.scss'],
})
export class InfoActionsHeaderComponent implements OnInit, OnDestroy {
  @Input() set valueprop(valueP: ValueProp) {
    this.valueProp = valueP;
    this.isHypothesis = Number(this.valueProp.value_prop_status) <= 3;
    this.locked = this.valueProp.closed === '1';
  }
  @Input() isNotSalesForce = true;
  @Input() showLocked = false;
  @Input() menu: StepperMenuItem[];
  @Input() showTranslate = false;
  @Input() showAllTcoMetrics = false;
  @Input() step = 0;

  @Output() reloadVP = new EventEmitter();

  changeVPName = false;
  valueProp: ValueProp;
  isHypothesis = false;
  locked = false;
  valuePropStatusTypes: MenuItem[];
  ngUnsubscribe = new Subject();
  public vpTooltip = '';

  constructor(
    private factsService: FactsService,
    private notificationService: NotificationService,
    private valuePropService: ValuepropService,
    private translationService: TranslationsV2Service,
    public trans: InfoActionsHeaderTranslation,
    private commonService: CommonService,
    private vpTooltipPipe: VpTooltipPipe,
  ) {}

  ngOnInit(): void {
    this.getValuePropStatusTypes();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('uid'),
      component: this.trans.config.component,
      lang: langAbbr,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getValuePropStatusTypes(): void {
    this.factsService
      .getValuePropStatusTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.valuePropStatusTypes = response.result.map((elem) => ({ label: elem.name, command: () => this.updateValuePropStatus(elem.id) }));
      });
  }

  updateValuePropStatus(statusTypeid): void {
    if (!statusTypeid) {
      return;
    }
    const statusTypeObj = { value_prop_status_type_id: statusTypeid };

    this.valuePropService.updateValuePropStatus(this.valueProp.id, statusTypeObj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.notificationService.success('Status updated successfully', false);
          this.reloadVP.emit();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false);
        }
      });
  }

  lockValueProp(): void {
    if (this.valueProp.vp_can_edit) {
      const payload = {
        closed: this.locked ? '0' : '1',
      };

      this.valuePropService.lockValueProp(this.valueProp.id, payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          if (response.result) {
            this.reloadVP.emit();
            this.notificationService.success(`Value prop has been ${payload.closed === '1' ? 'locked' : 'unlocked'} successfully`, false);
          }
        });
    }
  }

  cancelChangeName(): void {
    this.changeVPName = false;
  }

  submitChangeName(): void {
    this.valuePropService.updateValueProp(this.valueProp.id, { name: this.valueProp.name }).subscribe(() => {
      this.valueProp = { ...this.valueProp, name: this.valueProp.name };
      this.vpTooltip = this.vpTooltipPipe.transform(this.valueProp, this.trans);
      this.changeVPName = false;
      this.notificationService.success('Name updated successfully', false);

      this.changeVPName = false;
    });
  }
}
