<p-menu #menu appendTo="body" [popup]="true" [model]="menuPartners" styleClass="style-2022"></p-menu>
<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-6">
          <div class="title">
            {{ trans.trans.models.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.models"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-6">
          <a *ngIf="can_edit" (click)="openAddEditModel()" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i>{{ trans.trans.add_new.value }}</a>
          <a *ngIf="filterList !== ''" (click)="removeFilter()" class="btn btn_customizable pull-right left-button"
            ><i class="fa fa-filter"></i>{{ trans.trans.remove_filter.value }}</a
          >
        </div>
      </div>
    </div>
    <div class="row marg-v">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input [(ngModel)]="strSearch" (keyup)="tableSolutionList.filter($any($event).target.value, 'name', 'contains')" type="text" placeholder="Search" class="form-control" />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableSolutionList.filter('', 'name', 'contains')" class="input-group-addon clickable"
            ><i class="fa fa-times"></i
          ></span>
        </div>
      </div>
    </div>
  </div>

  <div class="models_page_header_container_small">
    <div class="row">
      <div class="col-xs-12">
        <p-progressBar *ngIf="loading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
        <p-table #tableSolutionList *ngIf="!loading" [columns]="cols" [value]="solutions" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns">
                <th *ngIf="col.sortBy; else noSort" [pSortableColumn]="col.sortBy">
                  {{ trans.trans[col.field].value }}
                  <p-sortIcon [field]="col.sortBy"></p-sortIcon>
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans[col.field]"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </th>
                <ng-template #noSort>
                  <th>
                    {{ trans.trans[col.field].value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans[col.field]"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </th>
                </ng-template>
              </ng-container>
              <th *ngIf="isSolutionAdmin"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-solution>
            <tr>
              <td style="white-space: nowrap">
                <a routerLink="/modelv2/{{ solution.id }}" class="link">{{ solution.name }}</a>
                <span *ngIf="solution.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="solution.description" matTooltipPosition="above">
                  info
                </span>
              </td>
              <td>
                <div class="d-inline-flex">
                  <a *ngIf="solution.userReleaseStatusId == 0" class="link" style="color: red">Not Released</a>
                  <a *ngIf="solution.userReleaseStatusId == 2" class="link" style="color: green">{{ trans.trans.released_to_users.value }} </a>
                  <i
                    style="margin-left: 10px"
                    matTooltip="Release to all Users"
                    (click)="releaseToAllUsers(solution.id)"
                    *ngIf="solution.share_role_type_id != 2"
                    class="clickable fa fa-arrow-right"
                  ></i>
                </div>
              </td>
              <td>
                <div class="d-inline-flex">
                  <a *ngIf="solution.partnerReleaseStatusId == 1" style="color: red">Not Released</a>
                  <a *ngIf="solution.partnerReleaseStatusId == 2" style="color: green">{{ trans.trans.released_to_users.value }} </a>
                  <a
                    *ngIf="solution.partnerReleaseStatusId == 3"
                    (mouseover)="funcMenuPartners(solution); menu.toggle($event)"
                    (mouseout)="menu.toggle($event); menuPartners = []"
                    style="color: green"
                    >{{ trans.trans.specific.value }}</a
                  >
                  <a
                    *ngIf="solution.partnerReleaseStatusId == 4"
                    (mouseover)="funcMenuPartners(solution); menu.toggle($event)"
                    (mouseout)="menu.toggle($event); menuPartners = []"
                    style="color: green"
                    >{{ trans.trans.group.value }}</a
                  >
                  <i
                    style="margin-left: 10px"
                    matTooltip="Release to Partners"
                    (click)="toggleReleaseToPartners = !toggleReleaseToPartners; selectedSolutionId = solution.id"
                    *ngIf="solution.partnerReleaseStatusId == 1 && solution.share_role_type_id != 2"
                    class="clickable fa fa-arrow-right"
                  ></i>
                  <span style="margin-left: 10px" *ngIf="solution.partnerReleaseStatusId != 1 && solution.share_role_type_id != 2">
                    <app-are-you-sure
                      matTooltip="Remove from Partners"
                      matTooltipPosition="above"
                      message="Are you sure?"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="removeFromPartners(solution.id)"
                    ></app-are-you-sure>
                  </span>
                </div>
              </td>
              <td>
                <a (click)="toggleModelShare = !toggleModelShare; selectedModel = solution" class="clickable">{{ trans.trans.view.value }}</a>
              </td>
              <td>
                <span *ngIf="can_edit && !solution.cloneInProgress" class="keywest_icon_group pull-right">
                  <span><i (click)="funcToggleEdit(solution)" [matTooltip]="trans.trans.edit.value" matTooltipPosition="above" class="clickable fa fa-pencil"></i></span>
                  <span><i (click)="funcCloneModel(solution)" [matTooltip]="trans.trans.clone.value" matTooltipPosition="above" class="clickable fa fa-files-o"></i></span>
                  <span>
                    <app-are-you-sure
                      [matTooltip]="trans.trans.delete.value"
                      matTooltipPosition="above"
                      message="Are you sure?"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="deleteSolution(solution.id)"
                    ></app-are-you-sure>
                  </span>
                </span>
                <span *ngIf="can_edit && solution.cloneInProgress" class="keywest_icon_group pull-right">
                  {{ trans.trans.cloning_in_progress.value }}
                </span>
                <span *ngIf="!can_edit" class="keywest_icon_group pull-right">
                  <span><i (click)="funcToggleView(solution)" class="fa fa-eye clickable" [matTooltip]="trans.trans.view.value" matTooltipPosition="above"></i></span>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<p-menu #rowMenu appendTo="body" [popup]="true" [model]="dropdown" styleClass="style-2022"></p-menu>

<p-sidebar [(visible)]="toggleModelShare" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-modelv2-share *ngIf="toggleModelShare" [models]="selectedModel" (close)="closeModelShare()"></app-modelv2-share>
</p-sidebar>

<p-sidebar [(visible)]="toggleAddEditModel" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-ae-solution-list
    [frmSolutionObj]="frmSolutionObj"
    [mode]="mode"
    (callback)="getSolutions(); toggleAddEditModel = false"
    (closeCallback)="closeAddEditModel()"
    *ngIf="toggleAddEditModel && can_edit"
  ></app-ae-solution-list>
  <app-r-solution-list [frmSolutionObj]="frmSolutionObj" *ngIf="toggleAddEditModel && !can_edit"></app-r-solution-list>
</p-sidebar>

<p-sidebar [(visible)]="toggleReleaseToPartners" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="closeReleaseToPartners()">
  <div class="sidebar_wrapper" *ngIf="toggleReleaseToPartners">
    <div class="sidebar_header">
      {{ trans.trans.release_to_partners.value }}
    </div>

    <div class="sidebar_container">
      <div class="keywest_panel">
        <div class="row" *ngFor="let releaseType of releaseTypes">
          <div class="col-xs-12">
            <label *ngIf="releaseType.id != '1'">
              <input type="radio" name="releaseType" (change)="selectPartnerTypes(releaseType.id)" />&nbsp;&nbsp;
              <span>{{ releaseType.name }}</span>
            </label>
          </div>
        </div>

        <div class="row" *ngIf="partnerTypeListSpecific && partnerTypeListSpecific.length && releaseTypeId == '3'">
          <div class="col-xs-12">
            <label for="">{{ trans.trans.partners.value }}</label>
            <p-multiSelect
              class="form-control"
              [options]="partnerTypeListSpecific"
              ret-data="Partners"
              name="partnerTypeListSpecific"
              [(ngModel)]="formObj.partnerTypeListSpecific"
              optionLabel="PartnerName"
            ></p-multiSelect>
          </div>
        </div>

        <div class="row" *ngIf="partnerTypeListGroup && partnerTypeListGroup.length && releaseTypeId == '4'">
          <div class="col-xs-12">
            <label for="">{{ trans.trans.partners.value }}</label>
            <p-multiSelect
              class="form-control"
              [options]="partnerTypeListGroup"
              ret-data="Partners"
              name="partnerTypeListGroup"
              [(ngModel)]="formObj.partnerTypeListGroup"
              optionLabel="name"
            ></p-multiSelect>
          </div>
        </div>
      </div>
    </div>
    <div class="btn_container">
      <div class="col-md-12">
        <button type="submit" class="btn btn_customizable pull-right" (click)="realeaseToPartners()">{{ trans.trans.save.value }}</button>
        <app-are-you-sure
          matTooltipPosition="above"
          [message]="trans.trans.are_you_sure.value"
          class="btn cancel-confirm pull-right marg-h"
          icon=""
          [text]="trans.trans.back.value"
          (callbackYes)="closeReleaseToPartners()"
        ></app-are-you-sure>
      </div>
    </div>
  </div>
</p-sidebar>
