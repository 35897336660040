import { Injectable } from '@angular/core';

@Injectable()

export class ModelsVpDashboardTranslations {

  public config: any = {
    component: 'modelsVpDashboard',
    display_name: 'Value Prop Models',
    description: 'Models tab in value prop dashboard bottom panel'
  };

  public trans: any = {
    models: 'Models',
    ch_models: 'The catalog of your value usually at the solution level',
    choose_category_type: 'Choose Category Type:',
    save: 'Save',
    no_modelscategory_try_different_selecting_dropdown_above: `No Models for this category, please
        try a different one by selecting from the dropdown above.`,
    areYouSureModels: 'Are you sure? This could remove changes you made to a currently included model.'
  };
}
