import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { AccountFormComponent } from './account-form/account-form.component';

@NgModule({
  declarations: [AccountFormComponent],
  exports: [AccountFormComponent],
  imports: [CommonModule, EditTranslationsModule, DropdownModule, FormsModule, AreYouSureModule, DLProgressbarModule, FieldsetModule],
})
export class AccountFormModule {}
