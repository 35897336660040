import { Injectable } from '@angular/core';

@Injectable ()

export class DefaultTranslations {

    public config: any = {
        component: 'fastFill',
        display_name: 'Fast Fill ',
        description: 'Fast Fill data for VP'
    };

    public trans: any = {
        inputs: 'Inputs',
        unit_type: 'Unit Type',
        units: 'Units',
        status: 'Status',
        current_effort: 'Current Effort',
        with: 'With',
        save: 'Save',
        title: 'Data & Assumptions',
        ch_fastFillAssumptions: `In this screen, please enter the common values
         which provide the most precision on your business case.`,
        editScratchpad: 'Edit Scratchpad',
        verifiedBy: 'Verified By' 
    };
}
