<div class='row models_page_header_container'>
	<div [ngClass]="isSolutionAdmin ? 'col-md-9 col-xs-12' : 'col-xs-12'" *ngIf="chartVC">
		<div class='models_panel tour_overview'>
			<div class='chart_title '>
				{{trans.trans.models_category_overview.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
				[transObj]="trans.trans.models_category_overview" (callback)="getTranslations()"></app-editTranslation>
			</div>
			<app-doughnut-models-chart [legend]='chartVCLegend' (modelClick)='filterList = $event' [data]='chartVC'></app-doughnut-models-chart>
		</div>
	</div>
	<div class='col-md-3 col-xs-12' *ngIf="chartVC && isSolutionAdmin">
		<div class='models_panel tour_actions' style='height: 363px;'>
			<div class='chart_title '>
				{{trans.trans.actions.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
				[transObj]="trans.trans.actions" (callback)="getTranslations()"></app-editTranslation>
			</div>
			<a (click)='startTour()' class='keywest_action_rollup clickable'>{{trans.trans.take_a_tour.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
				[transObj]="trans.trans.take_a_tour" (callback)="getTranslations()"></app-editTranslation>
			</a>
			<a (click)='funcAddModel(models_content);' class='clickable keywest_action_rollup clickable'>{{trans.trans.add_new_model.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
				[transObj]="trans.trans.add_new_model" (callback)="getTranslations()"></app-editTranslation>
			</a><br>
			<a (click)='funcAddFactors(models_content);' class='clickable keywest_action_rollup clickable'>{{trans.trans.add_factors.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
				[transObj]="trans.trans.add_factors" (callback)="getTranslations()"></app-editTranslation>
			</a><br>
			<a (click)='funcAddSituations(models_content);' class='clickable keywest_action_rollup clickable'>{{trans.trans.add_situations.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
				[transObj]="trans.trans.add_situations" (callback)="getTranslations()"></app-editTranslation>
			</a>

		</div>
	</div>
</div>
<div class='row models_page_header_container'>
	<div class='col-md-6 col-xs-12' *ngIf="barChartData">
		<div class='models_panel tour_top5' style='height: 366px;'>
			<div class='chart_title '>
				{{trans.trans.top5_models.value}}
				<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
				[transObj]="trans.trans.top5_models" (callback)="getTranslations()"></app-editTranslation>
			</div>
			<app-bar-models *ngIf='barChartData' [chartData]='barChartData'></app-bar-models>
		</div>
	</div>
	<div class='col-md-6 col-xs-12' *ngIf="totals">
		<div class='row'>
			<div class='col-xs-12'>
				<div class='models_panel tour_required' style='padding: 20px; margin-bottom: 20px;'>
					<div class='row'>
						<div class='col-xs-12'>
							<span class='keywest_small_title '>{{trans.trans.required_input.value}} 
								<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
								[transObj]="trans.trans.required_input" (callback)="getTranslations()"></app-editTranslation>
								<i *ngIf="!(style2022$ | async)" [matTooltip]='trans.trans.ch_required_input.value' matTooltipPosition="above" class='fa fa-info-circle'></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_required_input.value" matTooltipPosition="above">
									info
								</span>
							</span>
						</div>
					</div>
					<div class='row'>
						<div class='col-xs-4 clickable' (click)='funcLoadView("model_categories")'>
							<div [ngClass]="totals?.modelsGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals?.modelsGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.model_groups.value}}</span>
							</div>
						</div>

						<div class='col-xs-4 clickable' (click)='funcLoadView("value_category")'>
							<div [ngClass]="totals.benefitGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals.benefitGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.benefit_groups.value}}</span>
							</div>
						</div>

						<div class='col-xs-4 clickable' (click)='funcLoadView("factors")'>
							<div [ngClass]="totals.factorGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals.factorGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.factor_groups.value}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<div class='models_panel tour_optional' style='padding: 20px;'>

					<div class='row'>
						<div class='col-xs-12'>
							<span class='keywest_small_title '>{{trans.trans.optional_inputs.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="'models'" [isTranslationV2]="true" 
								[transObj]="trans.trans.optional_inputs" (callback)="getTranslations()"></app-editTranslation>
							</span>
						</div>
					</div>
					<div class='row'>
						<div class='col-20 clickable' (click)='funcLoadView("situations")'>
							<div [ngClass]="totals.situationGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals.situationGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.situations.value}}</span>
							</div>
						</div>
						<div class='col-20 clickable' (click)='funcLoadView("pain_points")'>
							<div [ngClass]="totals.painPointGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals.painPointGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.pain_points.value}}</span>
							</div>
						</div>
						<div class='col-20 clickable' (click)='funcLoadView("capabilities")'>
							<div [ngClass]="totals.capabilitiesGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals.capabilitiesGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.capabilities.value}}</span>
							</div>
						</div>
						<div class='col-20 clickable' (click)='funcLoadView("operational_objectives")'>
							<div [ngClass]="totals.operationalObjectivesGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals.operationalObjectivesGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.operational_objectives.value}}</span>
							</div>
						</div>

						<div class='col-20 clickable' (click)='funcLoadView("functional_objectives")'>
							<div [ngClass]="totals.functionalObjectivesGroupTotal > 0 ? 'keywest_rollup_small_green' : 'keywest_rollup_small_red'">
								<span class='rollup_val'>{{totals.functionalObjectivesGroupTotal}}</span><br>
								<span class='rollup_text small-heading'>{{trans.trans.functional_objectives.value}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<dl-menubar styleClass='details_menu_bar' class='details_menu_bar' [model]="items">
	<span *ngIf="isDev" (click)='isSolutionAdmin = !isSolutionAdmin' class='clickable pull-right'>{{isSolutionAdmin ? "Switch to View Only Mode" : "Switch to Edit Mode"}}</span>
</dl-menubar>
<div #models_content class='tour_content'>
  <app-solution-list #solution_list [filterList]='filterList' *ngIf="view == 'solution'" [can_edit]='isSolutionAdmin'></app-solution-list>
  <app-maintenance-situations [solutionId]='solutionId' *ngIf="view == 'situations'" [can_edit]='isSolutionAdmin'></app-maintenance-situations>
  <app-value-map-pain-points *ngIf="view == 'pain_points'" [can_edit]='isSolutionAdmin'></app-value-map-pain-points>
  <app-value-capabilities *ngIf="view == 'capabilities'" [can_edit]='isSolutionAdmin'></app-value-capabilities>
  <app-value-map-op-objectives *ngIf="view == 'operational_objectives'" [can_edit]='isSolutionAdmin'></app-value-map-op-objectives>
  <app-value-map-func-objectives *ngIf="view == 'functional_objectives'" [can_edit]='isSolutionAdmin'></app-value-map-func-objectives>
  <app-value-category *ngIf="view == 'value_category'" [can_edit]='isSolutionAdmin' [solutionId]="solutionId"></app-value-category>
  <app-model-category *ngIf="view == 'model_categories'" [can_edit]='isSolutionAdmin'></app-model-category>
  <app-value-map-factors *ngIf="view == 'factors'" [can_edit]='isSolutionAdmin'></app-value-map-factors>
  <app-benefit-types *ngIf="view == 'benefit_types'" [can_edit]='isSolutionAdmin'></app-benefit-types>
  <app-goal-groups *ngIf="view == 'goal_groups'" [canEdit]="isSolutionAdmin"></app-goal-groups>
</div>

<ngx-guided-tour [scrollReference]="tourScrollReference"></ngx-guided-tour>