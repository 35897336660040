import { Injectable } from '@angular/core';

@Injectable ()

export class CreditsTranslation {

    public config: any = {
        component: 'creditsComponent',
        display_name: 'Credit Component',
        description: 'Credit component in profile section'
    };

    public trans: any = {
        heading: 'Credits',
        my_credits: 'My Credits',
        account_credits: 'Account Credits',
        assignCreditslabel: 'Assign Credits',
        selectUser: 'Select User',
        save: 'Save',
        Cancel: 'Cancel',
        credits: 'Credits'
    };
}
