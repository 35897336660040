<p-menu #menuStatus appendTo="body" [popup]="true" [model]="valuePropStatusTypes" styleClass="style-2022"></p-menu>

<ng-container *ngIf="valueProp && changeVPName === false" class="full-width">
  <div class="action-header-container">
    <div class="left-info-container">
      <div 
        matTooltipClass="title-tooltip" 
        [matTooltip]="vpTooltip" 
        class="breadcrumb-title-active margin-0-y no-wrap ellipsis flex-aic vp-name"
      >
        {{ valueProp.name }}
      </div>
      <div class="flex-jbetween mr-2">
        <span class="icon-background">
          <h1 class="breadcrumb-title mr-0 clickable" [matTooltip]="trans.trans.renameBusinessCase.value" (click)="changeVPName = !changeVPName">
            <img src="assets/images/icons/pencil.png"/>
          </h1>
        </span>
        <span class="icon-background">
          <h1 class="breadcrumb-title mr-0 clickable" [matTooltip]="locked ? trans.trans.unlock.value : trans.trans.lock.value" (click)="lockValueProp()">
            <img src="assets/images/icons/unlock.png"/>
          </h1>
        </span>
        <span class="icon-background">
          <h1 class="breadcrumb-title mr-0 clickable" [matTooltip]="trans.trans.valuePropStatus.value + ': ' + valueProp.vp_status" (click)="menuStatus.toggle($event)">
            <img src="assets/images/icons/status.png"/>
          </h1>
        </span>
      </div>
    </div>
    <div class="toolbar-position" *ngIf="valueProp && valueProp.solutions && valueProp.solutions?.length > 0">
      <app-rsv2-toolbar [showTranslate]="showTranslate" [valueProp]="valueProp" [step]="step" [menu]="menu" [showAllTcoMetrics]="showAllTcoMetrics"></app-rsv2-toolbar>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="changeVPName == true">
  <div class="action-header-container">
    <div class="left-info-container">
      <div class="breadcrumb-title-active margin-0-y no-wrap ellipsis flex-aic vp-name">
        <input class="input-textfield" [(ngModel)]="valueProp.name"/>
      </div>
      <div class="breadcrumb-title-active margin-0-y no-wrap ellipsis flex-aic vp-name">
        <span class="input" role="textbox" [(ngModel)]="valueProp.name"></span>
      </div>
      <div class="flex-jbetween mr-2">
        <span class="icon-background">
          <h1 
            class="breadcrumb-title mr-0 clickable" 
            [matTooltip]="trans.trans.renameBusinessCase.value" 
            (click)="submitChangeName()">
            <img src="assets/images/icons/save.png"/>
          </h1>
        </span>
        <span class="icon-background">
          <h1 class="breadcrumb-title mr-0 clickable" [matTooltip]="trans.trans.renameBusinessCase.value" (click)="changeVPName = !changeVPName">
            <img src="assets/images/icons/x-circle-1.png"/>
          </h1>
        </span>
      </div>
    </div>
  </div>
</ng-container>
