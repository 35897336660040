<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

<ng-template #loadedContent>
  <div class="benefits-tab">
    <div class="flex-jend marg-v">
      <button *ngIf="businessReviewService.canEdit | async" class="btn btn_customizable" (click)="showAddNewSurvey = true">
        <i class="fa fa-plus"></i>
        {{ trans.trans.addNew.value }}
      </button>
    </div>

    <ng-container *ngFor="let template of surveyTemplates | functionPipe: filterTemplates">
      <div class="bordered-panel-tile">
        <span class="material-icons-outlined accordion" (click)="toggleSurveyTable(template)">
          {{ template.expand ? 'arrow_drop_down' : 'arrow_right' }}
        </span>

        <span class="clickable benefit-text" (click)="toggleSurveyTable(template)">
          {{ template.account_question_template_name }}

          <span
            *ngIf="template.description"
            class="material-icons-outlined tooltip-custom info-icon"
            [matTooltip]="template.description"
            matTooltipPosition="above"
            container="body"
          >
            info
          </span> </span
        ><br />

        <div *ngIf="template.expand" class="benefits-table padding-15-top">
          <p-table [value]="template.surveys" responsiveLayout="scroll" styleClass="keywest_table" tableStyleClass="keywest_table">
            <ng-template pTemplate="header">
              <tr class="clear">
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{ trans.trans[col.field].value }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-survey>
              <tr>
                <td class="clickable" (click)="openSurvey(survey)">
                  {{ survey.respondent }}
                </td>
                <td>
                  {{ survey.created_by }}
                </td>
                <td>
                  {{ survey.completed_date | date: 'medium' }}
                </td>
                <td>
                  {{ survey.business_review_name }}
                </td>
                <td>
                  <app-ellipsis-menu *ngIf="businessReviewService.canEdit | async">
                    <ul class="list-group">
                      <li class="list-group-item" (click)="openSurvey(survey)">
                        {{ trans.trans.edit.value }}
                      </li>
                      <li class="list-group-item">
                        <app-are-you-sure
                          (callbackYes)="deleteSurvey(survey)"
                          [icon]="null"
                          [text]="trans.trans.delete.value"
                          [message]="trans.trans.areYouSure.value"
                        ></app-are-you-sure>
                      </li>
                    </ul>
                  </app-ellipsis-menu>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<p-sidebar [(visible)]="showAddNewSurvey" *ngIf="showAddNewSurvey" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="closeSidebar()">
  <app-new-survey-sidebar *ngIf="showAddNewSurvey" [valuePropId]="valuePropId" [survey]="selectedSurvey" (sidebarClosed)="closeSidebar()"></app-new-survey-sidebar>
</p-sidebar>
