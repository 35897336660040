import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { CashflowSimpleComponent } from './cashflow_simple.component';
import { CashflowRepSimpleTranslations } from './cashflow_simple.translation';

@NgModule({
  imports: [CommonModule, FormsModule, EditTranslationsModule, ApplicationPipesModule],
  declarations: [CashflowSimpleComponent],
  providers: [TranslationsV2Service, CashflowRepSimpleTranslations],
  exports: [CashflowSimpleComponent],
  entryComponents: [CashflowSimpleComponent],
})
export class RSV2CashflowSimpleModule {}
