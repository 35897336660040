import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { StepperMenuItem } from '@shared/models/stepper-menu.modles';
import { Benefit, ValueProp } from '@shared/models/value-prop.model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-result-details',
  templateUrl: './result-details.component.html',
  styleUrls: ['./result-details.component.scss'],
})
export class ResultDetailsComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  @Input() firstLoad = true;
  @Input() hasKeyAssumptions: boolean;
  @Input() discoveryQuestions: boolean;
  @Input() isDev: boolean;
  @Input() dashboard: string;
  @Input() view: string;
  @Input() showTranslate = false;
  @Input() showHelp = false;
  @Input() contextualHelp: any[];
  @Input() year1BreakDownChartData: { [klass: string]: any };
  @Input() costBenefitChartData: { [klass: string]: any };
  @Input() menu: StepperMenuItem[];
  @Output() CallbackSelectedBenefit = new EventEmitter();
  @Output() callbackExplained = new EventEmitter();

  modelsV2 = false;

  ngUnsubscribe$ = new Subject();

  selectedBenefit: Benefit;
  solutionId: string | number;

  valuePropId: string | number;
  canEdit = false;
  constructor(private valuePropService: ValuepropService) {}

  ngOnInit(): void {
    if (!this.valuePropId) {
      this.valuePropId = this.valueProp.id ? Number(this.valueProp.id) : this.valuePropId;
    }
    this.canEdit = this.valueProp.vp_can_edit;

    this.valuePropService.gotoMenuItem.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      const menu = res.menu;
      const data = res.data;
      const panel = res.panel;

      localStorage.setItem(res.panel, menu);
      if (panel == 'vpdashboardMenuBottom') {
        this.view = menu;
      } else {
      }
      switch (menu) {
        case 'benefit_detail':
          this.selectedBenefit = data;
          break;
      }
    });
    this.parseFeatures();
    this.getSolutionId();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  funCcallbackSelectedBenefit(metric): void {
    this.CallbackSelectedBenefit.emit(metric);
  }

  funcGotoExplain(event): void {
    this.callbackExplained.emit(event);
  }

  private getSolutionId(): void {
    this.valuePropService
      .readSolutionList(this.valueProp.id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.length > 0) {
          this.solutionId = Number(response.result[0]['account_solution_id']);
        }
      });
  }

  reloadDashboard(): void {
    this.valuePropService.refreshDashboard.next();
  }

  private parseFeatures(): void {
    const features = sessionStorage.getItem('features');
    if (features) {
      const accountFeatures = features.split(',');
      if (accountFeatures.indexOf('38') >= 0) {
        this.modelsV2 = true;
      }
    }
  }
}
