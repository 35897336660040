import { Injectable } from '@angular/core';

@Injectable ()

export class TrackValueSuccessTabTranslations {

    public config: any = {
        component: 'trackValueSuccessTab',
        display_name: 'Track Value Success Criteria Tab',
        description: 'The success criteria tab found in the track value detail dashboard'
    };

    public trans: any = {};
}