<div class="row">
  <div class="col-xs-12">
    <div class="btn-group pull-right marg-v">
      <button
        matTooltip="{{ trans.vp_dashboard.assumptions.scroll_to_drivers | translate }}"
        matTooltipPosition="above"
        (click)="scroll('driverPanel')"
        class="btn btn_customizable btn-sm"
      >
        <i class="fa fa-arrow-right"></i> {{ trans.vpDashBoard.Assumptions.drivers | translate }}
      </button>
      <button
        matTooltip="{{ trans.vp_dashboard.assumptions.scroll_to_financials | translate }}"
        matTooltipPosition="above"
        (click)="scroll('financialsPanel')"
        class="btn btn_customizable btn-sm"
      >
        <i class="fa fa-arrow-right"></i> {{ trans.vpDashBoard.Assumptions.financials | translate }}
      </button>
      <button
        matTooltip="{{ trans.vp_dashboard.assumptions.scroll_to_scratchpads | translate }}"
        matTooltipPosition="above"
        (click)="scroll('scratchpadsPanel')"
        class="btn btn_customizable btn-sm"
      >
        <i class="fa fa-arrow-right"></i> {{ trans.vpDashBoard.Assumptions.scratchpads | translate }}
      </button>

      <button *ngIf="!expandAll" (click)="funcExpand(false)" class="btn btn_customizable btn-sm">
        <i class="fa fa-minus"></i> {{ trans.vpDashBoard.Assumptions.collapse | translate }}
      </button>
      <button *ngIf="expandAll" (click)="funcExpand(true)" class="btn btn_customizable btn-sm">
        <i class="fa fa-plus"></i>{{ trans.vpDashBoard.Assumptions.expand | translate }}
      </button>
    </div>
  </div>
</div>
<div #driverPanel id="driverPanel" class="panel panel-white">
  <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
    <div class="row">
      <div class="col-xs-12 clickable" style="padding-right: 30px">
        <table style="table-layout: fixed; width: 100%">
          <tr>
            <td width="20px" style="vertical-align: top" (click)="showDrivers = !showDrivers">
              <i class="fa fa-plus clickable" *ngIf="!showDrivers" style="margin-right: 5px"></i>
              <i class="fa fa-minus clickable" *ngIf="showDrivers" style="margin-right: 5px"></i>
            </td>
            <td width="100%" (click)="showDrivers = !showDrivers">
              <span class="clickable panel-title">{{ trans.vpDashBoard.Assumptions.drivers | translate }}</span
              ><br />
            </td>
            <td style="width: 200px">
              <input type="text" [(ngModel)]="searchTextDrivers" class="form-control" placeholder="Search" />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              {{ drivers.length }} {{ trans.vpDashBoard.Assumptions.drivers | translate }}
              <span style="color: orange">{{ driversStarred }} {{ trans.vpDashBoard.Assumptions.starred | translate }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body" *ngIf="showDrivers">
    <div class="table-responsive col-sm-12 drivers-table">
      <table id="drivers" class="table custom-table dataTable">
        <thead>
          <tr>
            <th style="border-bottom: 0px; width: 40px"></th>
            <th style="border-bottom: 0px">{{ trans.props.tabDetails.discovery.assumptions.DriverFactor | translate }}</th>
            <th style="border-bottom: 0px">Min Value</th>
            <th style="border-bottom: 0px">Max Value</th>
            <th style="border-bottom: 0px">Increment</th>

            <th style="border-bottom: 0px" class="text-right">{{ trans.props.tabDetails.discovery.assumptions.DriverValue | translate }}</th>
          </tr>
        </thead>

        <tbody *ngIf="driversLoader">
          <tr>
            <td colspan="6">
              <p-progressBar mode="indeterminate"></p-progressBar>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="(drivers == null || drivers.length == 0) && !driversLoader">
          <tr>
            <td colspan="3">{{ trans.props.tabDetails.discovery.assumptions.NoDriversFound | translate }}</td>
          </tr>
        </tbody>

        <tbody *ngIf="drivers && drivers !== null && drivers.length > 0">
          <tr *ngFor="let driver of drivers | filterAssumptionsDrivers: searchTextDrivers" class="grid-row">
            <td>
              <div *ngIf="!canEdit">
                <i class="fa fa-star" [ngStyle]="{ color: driver.is_key == 1 ? 'gold' : 'grey' }"></i>
              </div>
              <div *ngIf="canEdit">
                <i class="fa fa-star clickable" (click)="toggleStared(driver, 2)" [ngStyle]="{ color: driver.is_key == 1 ? 'gold' : 'grey' }"></i>
              </div>
            </td>
            <td>
              {{ driver.driver_factor }}
              <!-- <i class="icon-info m-l-xxs tooltip-custom" (click)= ></i>
							<p-overlayPanel>
								<p>{{trans.props.tabDetails.discovery.assumptions.Includedbenefits | translate }}:</p>
								<ul *ngFor="let metric of driver.metrics">
									<li>{{metric.metric_name}}</li>
								</ul>
							</p-overlayPanel> -->
              <i class="icon-info m-l-xxs tooltip-custom" [ngbTooltip]="tipContentMetric" container="body" placement="right"></i>
              <ng-template #tipContentMetric class="toolTipContent">
                <p>{{ trans.props.tabDetails.discovery.assumptions.Includedbenefits | translate }}:</p>
                <ul *ngFor="let metric of driver.metrics">
                  <li>{{ metric.metric_name }}</li>
                </ul>
              </ng-template>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ driver.min }}</div>
              <div *ngIf="canEdit">
                <span (click)="driver.showeditable = !driver.showeditable" *ngIf="!driver.showeditable && driver.is_key == 1" class="editable">
                  {{ driver.min }}
                </span>
                <div class="input-group pull-right" *ngIf="driver.showeditable && driver.is_key == 1">
                  <input type="number" name="min" [(ngModel)]="driver.min" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ driver.max }}</div>
              <div *ngIf="canEdit">
                <span (click)="driver.showeditable = !driver.showeditable" *ngIf="!driver.showeditable && driver.is_key == 1" class="editable">
                  {{ driver.max }}
                </span>
                <div class="input-group pull-right" *ngIf="driver.showeditable && driver.is_key == 1">
                  <input type="number" name="max" [(ngModel)]="driver.max" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ driver.increment }}</div>
              <div *ngIf="canEdit">
                <span (click)="driver.showeditable = !driver.showeditable" *ngIf="!driver.showeditable && driver.is_key == 1" class="editable">
                  {{ driver.increment }}
                </span>
                <div class="input-group pull-right" *ngIf="driver.showeditable && driver.is_key == 1">
                  <input type="number" name="increment" [(ngModel)]="driver.increment" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ driver.driver_value_un_fmt }}</div>
              <div *ngIf="canEdit">
                <span (click)="driver.showeditable = !driver.showeditable" *ngIf="!driver.showeditable && driver.driver_value_fmt" class="editable">
                  {{ driver.driver_value_un_fmt }}
                </span>
                <div class="input-group pull-right" *ngIf="driver.showeditable">
                  <input type="number" name="driverFactor" [(ngModel)]="driver.driver_value" class="form-control" />
                  <div class="input-group-btn">
                    <button (click)="editDriverValue(driver); driver.showeditable = !driver.showeditable" class="btn btn-success">
                      <i class="fa fa-check"></i>
                    </button>
                    <button (click)="driver.showeditable = !driver.showeditable" class="btn btn-danger">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div #financialsPanel id="financialsPanel" class="panel panel-white">
  <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
    <div class="row">
      <div class="col-xs-12 clickable" style="padding-right: 30px">
        <table style="table-layout: fixed; width: 100%">
          <tr>
            <td width="20px" style="vertical-align: top" (click)="showFinancial = !showFinancial">
              <i class="fa fa-plus clickable" *ngIf="!showFinancial" style="margin-right: 5px"></i>
              <i class="fa fa-minus clickable" *ngIf="showFinancial" style="margin-right: 5px"></i>
            </td>
            <td width="100%" (click)="showFinancial = !showFinancial">
              <span class="clickable">{{ trans.vpDashBoard.Assumptions.financials | translate }}</span
              ><br />
            </td>
            <td style="width: 200px">
              <input type="text" [(ngModel)]="searchTextFinancial" class="form-control" placeholder="Search" />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              {{ drivers.length }} {{ trans.vpDashBoard.Assumptions.financials | translate }}
              <span style="color: orange">{{ financialsStarred }}{{ trans.vpDashBoard.Assumptions.starred | translate }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body" *ngIf="showFinancial">
    <div class="table-responsive col-sm-12 financials-table">
      <table id="financials" class="table custom-table dataTable">
        <thead>
          <tr>
            <th style="border-bottom: 0px; width: 40px"></th>
            <th style="border-bottom: 0px">{{ trans.props.tabDetails.discovery.assumptions.FinancialFactor | translate }}</th>
            <th style="border-bottom: 0px">Min Value</th>
            <th style="border-bottom: 0px">Max Value</th>
            <th style="border-bottom: 0px">Increment</th>
            <th style="border-bottom: 0px" class="text-right">{{ trans.props.tabDetails.discovery.assumptions.FinancialValue | translate }}</th>
          </tr>
        </thead>

        <tbody *ngIf="financialsLoader">
          <tr>
            <td colspan="6">
              <p-progressBar mode="indeterminate"></p-progressBar>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="(financials == null || financials.length == 0) && !financialsLoader">
          <tr>
            <td>{{ trans.props.tabDetails.discovery.assumptions.NoFinancialsFound | translate }}</td>
          </tr>
        </tbody>

        <tbody *ngIf="financials && financials !== null && financials.length > 0">
          <tr *ngFor="let financial of financials | filterAssumptionsFinancial: searchTextFinancial" class="grid-row">
            <td>
              <div *ngIf="!canEdit">
                <i class="fa fa-star" [ngStyle]="{ color: financial.is_key == 1 ? 'gold' : 'grey' }"></i>
              </div>
              <div *ngIf="canEdit">
                <i class="fa fa-star clickable" (click)="toggleStared(financial, 4)" [ngStyle]="{ color: financial.is_key == 1 ? 'gold' : 'grey' }"></i>
              </div>
            </td>
            <td>
              {{ financial.financial_factor }}
              <i class="icon-info m-l-xxs tooltip-custom" [ngbTooltip]="tipContentMetric" container="body" placement="right"></i>
              <ng-template #tipContentMetric class="toolTipContent">
                <p>{{ trans.props.tabDetails.discovery.assumptions.Includedbenefits | translate }}:</p>
                <ul *ngFor="let metric of financial.metrics">
                  <li>{{ metric.metric_name }}</li>
                </ul>
              </ng-template>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ financial.min }}</div>
              <div *ngIf="canEdit">
                <span (click)="financial.showeditable = !financial.showeditable" *ngIf="!financial.showeditable && financial.is_key == 1" class="editable">
                  {{ financial.min }}
                </span>
                <div class="input-group pull-right" *ngIf="financial.showeditable && financial.is_key == 1">
                  <input type="number" name="min" [(ngModel)]="financial.min" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ financial.max }}</div>
              <div *ngIf="canEdit">
                <span (click)="financial.showeditable = !financial.showeditable" *ngIf="!financial.showeditable && financial.is_key == 1" class="editable">
                  {{ financial.max }}
                </span>
                <div class="input-group pull-right" *ngIf="financial.showeditable && financial.is_key == 1">
                  <input type="number" name="max" [(ngModel)]="financial.max" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ financial.increment }}</div>
              <div *ngIf="canEdit">
                <span (click)="financial.showeditable = !financial.showeditable" *ngIf="!financial.showeditable && financial.is_key == 1" class="editable">
                  {{ financial.increment }}
                </span>
                <div class="input-group pull-right" *ngIf="financial.showeditable && financial.is_key == 1">
                  <input type="number" name="increment" [(ngModel)]="financial.increment" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ financial.financial_value_un_fmt }}</div>
              <div *ngIf="canEdit">
                <span (click)="financial.showeditable = !financial.showeditable" *ngIf="!financial.showeditable && financial.financial_value_fmt" class="editable">
                  {{ financial.financial_value_un_fmt }}
                </span>
                <div class="input-group pull-right" *ngIf="financial.showeditable">
                  <input type="number" name="financialFactor" [(ngModel)]="financial.financial_value" class="form-control" />
                  <div class="input-group-btn">
                    <button (click)="editFinancialValue(financial); financial.showeditable = !financial.showeditable" class="btn btn-success">
                      <i class="fa fa-check"></i>
                    </button>
                    <button (click)="financial.showeditable = !financial.showeditable" class="btn btn-danger">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="panel panel-white">
  <div class="panel-heading" style="height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px">
    <div class="row">
      <div class="col-xs-12 clickable" style="padding-right: 30px">
        <table style="table-layout: fixed; width: 100%">
          <tr>
            <td width="20px" style="vertical-align: top" (click)="showScratch = !showScratch">
              <i class="fa fa-plus clickable" *ngIf="!showScratch" style="margin-right: 5px"></i>
              <i class="fa fa-minus clickable" *ngIf="showScratch" style="margin-right: 5px"></i>
            </td>
            <td width="100%" (click)="showScratch = !showScratch">
              <span class="clickable">{{ trans.vpDashBoard.Assumptions.scratchpads | translate }}</span
              ><br />
            </td>
            <td style="width: 200px">
              <input type="text" [(ngModel)]="searchTextScratchpads" class="form-control" placeholder="Search" />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              {{ drivers.length }} {{ trans.vpDashBoard.Assumptions.scratchpads | translate }}
              <span style="color: orange">{{ scratchStarred }}{{ trans.vpDashBoard.Assumptions.starred | translate }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-body" *ngIf="showScratch">
    <div class="table-responsive col-sm-12 dataTable">
      <table class="table custom-table">
        <thead>
          <tr>
            <th style="border-bottom: 0px; width: 40px"></th>
            <th style="border-bottom: 0px">{{ 'props.tabDetails.discovery.assumptions.ScratchpadUnits' | translate }}</th>
            <th style="border-bottom: 0px">Min Value</th>
            <th style="border-bottom: 0px">Max Value</th>
            <th style="border-bottom: 0px">Increment</th>
            <th style="border-bottom: 0px">&nbsp;</th>
          </tr>
        </thead>

        <tbody *ngIf="scratchPadLoader">
          <tr>
            <td colspan="3">
              <p-progressBar mode="indeterminate"></p-progressBar>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="(scratchPads == null || scratchPads.length == 0) && !financialsLoader">
          <tr>
            <td colspan="3">{{ 'props.tabDetails.discovery.assumptions.NoScratchpadUnitsFound' | translate }}</td>
          </tr>
        </tbody>

        <tbody *ngIf="scratchPads && scratchPads !== null && scratchPads.length > 0">
          <tr *ngFor="let scratchPad of scratchPads | filterAssumptionsScratchpads: searchTextScratchpads" class="grid-row">
            <td>
              <div *ngIf="!canEdit">
                <i class="fa fa-star" [ngStyle]="{ color: scratchPad.is_key == 1 ? 'gold' : 'grey' }"></i>
              </div>
              <div *ngIf="canEdit">
                <i class="fa fa-star" (click)="toggleStared(scratchPad, 6)" [ngStyle]="{ color: scratchPad.is_key == 1 ? 'gold' : 'grey' }"></i>
              </div>
            </td>
            <td>
              {{ scratchPad.name }}
              <i class="icon-info m-l-xxs tooltip-custom" [ngbTooltip]="tipContentMetric" container="body" placement="right"></i>
              <ng-template #tipContentMetric class="toolTipContent">
                <p>{{ 'props.tabDetails.discovery.assumptions.Includedbenefits' | translate }}:</p>
                <ul *ngFor="let metric of scratchPad.metrics">
                  <li>{{ metric.metric_name }}</li>
                </ul>
              </ng-template>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ scratchPad.min }}</div>
              <div *ngIf="canEdit">
                <span (click)="scratchPad.showeditable = !scratchPad.showeditable" *ngIf="!scratchPad.showeditable && scratchPad.is_key == 1" class="editable">
                  {{ scratchPad.min }}
                </span>
                <div class="input-group pull-right" *ngIf="scratchPad.showeditable && scratchPad.is_key == 1">
                  <input type="number" name="min" [(ngModel)]="scratchPad.min" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ scratchPad.max }}</div>
              <div *ngIf="canEdit">
                <span (click)="scratchPad.showeditable = !scratchPad.showeditable" *ngIf="!scratchPad.showeditable && scratchPad.is_key == 1" class="editable">
                  {{ scratchPad.max }}
                </span>
                <div class="input-group pull-right" *ngIf="scratchPad.showeditable && scratchPad.is_key == 1">
                  <input type="number" name="max" [(ngModel)]="scratchPad.max" class="form-control" />
                </div>
              </div>
            </td>

            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ scratchPad.increment }}</div>
              <div *ngIf="canEdit">
                <span (click)="scratchPad.showeditable = !scratchPad.showeditable" *ngIf="!scratchPad.showeditable && scratchPad.is_key == 1" class="editable">
                  {{ scratchPad.increment }}
                </span>
                <div class="input-group pull-right" *ngIf="scratchPad.showeditable && scratchPad.is_key == 1">
                  <input type="number" name="increment" [(ngModel)]="scratchPad.increment" class="form-control" />
                </div>
              </div>
            </td>
            <td class="text-right preventWordBreak">
              <div *ngIf="!canEdit">{{ scratchPad.operand }}</div>
              <div *ngIf="canEdit">
                <span (click)="scratchPad.showeditable = !scratchPad.showeditable" *ngIf="!scratchPad.showeditable && scratchPad.operand" class="editable">
                  {{ scratchPad.operand }}
                </span>
                <div class="input-group pull-right" *ngIf="scratchPad.showeditable">
                  <input type="number" name="scratchpadUnit" [(ngModel)]="scratchPad.operand" class="form-control" />
                  <div class="input-group-btn">
                    <button (click)="editScratchpadUnit(scratchPad); scratchPad.showeditable = !scratchPad.showeditable" class="btn btn-success">
                      <i class="fa fa-check"></i>
                    </button>
                    <button (click)="scratchPad.showeditable = !scratchPad.showeditable" class="btn btn-danger">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
