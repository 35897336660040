import { Component, Input, ViewEncapsulation, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-are-you-sure',
  templateUrl: './areyousure.component.html',
  styleUrls: ['./areyousure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AreYouSureComponent implements OnInit {
  @Output() callbackYes = new EventEmitter();
  @Output() callbackNo = new EventEmitter();
  @Input() message = 'Are you sure?';
  @Input() yes = 'Yes';
  @Input() no = 'No';
  @Input() icon = 'fa fa-times';
  @Input() iconColor = null;
  @Input() textColor = null;
  @Input() useGoogleFontIcon = false;
  @Input() class = '';
  @Input() text = 'Delete';
  @Input() tooltip = '';
  @Input() noLoader = false;

  @ViewChild('op') op: OverlayPanel;

  loading = false;
  @Input() set isLoading(load: boolean) {
    this.loading = load;
  }
  fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';

  iconStyle: { [cssSelector: string]: string };
  textStyle: { [cssSelector: string]: string };

  ngOnInit() {
    if (this.iconColor) {
      this.iconStyle = { color: this.iconColor };
    }
    if (this.textColor) {
      this.textStyle = { color: this.textColor };
    }
  }

  funcYes() {
    this.loading = true;
    this.callbackYes.emit();
    this.op.hide();
    this.loading = false;
  }

  public funcCancel() {
    this.loading = false;
    this.callbackNo.emit();
    this.op.hide();
  }
}
