import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { BusinessReviewData, DriverScratchpadClass, Metric } from '@data/services/valuerealization/models/value-tracker.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { ScracthpadOperatorType } from '@shared/models/facts.models';
import { UpdateBrScratchpadPayload } from '@shared/models/update-br-scratchpad.model';
import { UserInfo } from '@shared/models/user-info.model';
import { defaultUnitTypes } from '@shared/utils';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { BenefitFlyoutInputTableTranslation } from './benefit-flyout-input-table.translation';
import { cloneDeep } from 'lodash';
import { Table } from 'primeng/table';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

export class BenefitFlyoutInputTableDriverFactor {
  constructor(
    public factorTypeId = 1,
    public formula: string = '',
    public editable: boolean = true,
    public type: string = 'driver',
    public calc: string = 'x',
    public description: string = '',
    public driver_factor_id: string = '',
    public factor_id: string = '',
    public driver_source_type_id: string = '',
    public driver_override: string = '',
    public benefitField = 'value_survey_driver',
    public value_survey_driver: string = '',
    public year1_has_scrachpad: boolean = false,
    public year1_scratchpad_id: string = '',
    public year1_scratchpad: string = '',
    public year1_fmt: string = '',
    public year1: string = '',
    public precision = 0
  ) {}
}

export class BenefitFlyoutInputTableImprovementFactor {
  constructor(
    public formula: string = '',
    public factorTypeId: number = 0,
    public hasScratchpad: boolean = false,
    public editable: boolean = true,
    public calc: string = 'x',
    public type: string = 'improvement',
    public description: string = '',
    public future_effort: string = '',
    public future_effort_fmt: string = '',
    public improvement_factor_id: string = '',
    public factor_id: string = '',
    public impact: string = '',
    public impact_source_type_id = '1',
    public value_prop_metric_id: string = '',
    public benefitField: string = 'value_survey_improvement',
    public value_survey_improvement: string = '',
    public year1_has_scrachpad: boolean = false,
    public year1_scratchpad_id: string = '',
    public year1_scratchpad: string = '',
    public year1_fmt: string = '',
    public year1: string = '',
    public precision = 0,
    public cost?: string,
    public cost_fmt?: string
  ) {}
}

export class BenefitFlyoutInputTableFinancialFactor {
  constructor(
    public formula: string = '',
    public factorTypeId: number = 2,
    public hasScratchpad: boolean = false,
    public editable: boolean = true,
    public calc: string = 'x',
    public type: string = 'financial',
    public description: string = '',
    public financial_factor_id: string = '',
    public factor_id: string = '',
    public financial_override: string = '',
    public financial_source_type_id: string = '',
    public cost: string = '',
    public benefitField: string = 'value_survey_financial',
    public value_survey_financial: string = '',
    public precision = 0
  ) {}
}

export class BenefitFlyoutInputTableHeader {
  constructor(
    public formula = 'periodOfPerformance',
    public editable = false,
    public benefitField = 'period_of_performance',
    public year_1_fmt: string = '',
    public year1: string = '',
    public factor_id: string = '0'
  ) {}
}

export class BenefitFlyoutInputTableIncremental {
  constructor(
    public formula = 'valueAchieved',
    public cost = '',
    public hasScratchpad = false,
    public editable = false,
    public year1_fmt = '',
    public year1 = '',
    public factor_id: string = '0'
  ) {}
}

export class BenefitFlyoutInputTableTotal {
  constructor(
    public formula = 'valueAchievedToDate',
    public cost = '',
    public type = 'finalTotal',
    public hasScratchpad = false,
    public editable = false,
    public year1_fmt = '',
    public year1 = '',
    public factor_id: string = '0'
  ) {}
}

export class BenefitFlyoutInputTableExpectedValue {
  constructor(
    public formula = 'expectedValueToDate',
    public cost = '',
    public type = 'total',
    public hasScratchpad = false,
    public editable = false,
    public year1_fmt = '',
    public year1 = '',
    public factor_id: string = '0'
  ) {}
}

export class BenefitFlyoutInputTableVariance {
  constructor(
    public formula = 'varianceToDate',
    public cost = '',
    public type = 'total',
    public hasScratchpad = false,
    public editable = false,
    public year1_fmt = '',
    public year1 = '',
    public factor_id: string = '0'
  ) {}
}

@Component({
  selector: 'app-benefit-flyout-input-table',
  templateUrl: './benefit-flyout-input-table.component.html',
  styleUrls: ['./benefit-flyout-input-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitFlyoutInputTableComponent implements OnInit, OnDestroy {
  @Input() set benefitData(ben: any) {
    this.benefit = { ...ben };
    if (this.benefit.business_review_column?.length > 0) {
      this.businessReviews = this.benefit.business_review_column;
      this.businessReviewsCache = cloneDeep(this.businessReviews);
      this.benefitSurveyTableColumns = [];
      this.benefitSurveyTable = [];
      this.buildBenefitSurveyDataTableColumns();
      this.createBenefitSurveyDataTable();
    }
  }

  @Input() set toggleEditExpected(toggle: boolean) {
    this.editExpected = toggle;
  }

  @Input() set resetChanges(toggle: number) {
    if (toggle > 0) {
      this.businessReviews = cloneDeep(this.businessReviewsCache);
      this.benefitSurveyTable = cloneDeep(this.benefitSurveyTableCache);

      // Only toggle the row if the scratchpad is open
      if ( this.scratchpadOpen !== null ) {
        this.table.toggleRow(this.toggledRow);
      }

      this.scratchpadOpen = null
      this.toggledRow = null;
      this.scratchPadEditIndex = null;
      this.selectedScratchpad = null;
      this.benefitUpdated = null;
      this.cd.markForCheck();
    }
  }
  @ViewChild(Table) table: Table;
  @Input() valuePropId: string = '';
  @Output() update = new EventEmitter();
  @Output() closeSidebar = new EventEmitter();
  @Output() benefitUpdatedTrigger = new EventEmitter<number>();
  @Output() scratchpadToggledTrigger = new EventEmitter<number>();

  benefit: Metric;
  editExpected = true;
  benefitSurveyTableColumns = [];
  businessReviews: BusinessReviewData[];
  businessReviewsCache: BusinessReviewData[];

  operatorList: ScracthpadOperatorType[] = [];
  benefitSurveyTable: (
    | BenefitFlyoutInputTableDriverFactor
    | BenefitFlyoutInputTableImprovementFactor
    | BenefitFlyoutInputTableFinancialFactor
    | BenefitFlyoutInputTableHeader
    | BenefitFlyoutInputTableIncremental
    | BenefitFlyoutInputTableTotal
  )[] = [];

  benefitSurveyTableCache: (
    | BenefitFlyoutInputTableDriverFactor
    | BenefitFlyoutInputTableImprovementFactor
    | BenefitFlyoutInputTableFinancialFactor
    | BenefitFlyoutInputTableHeader
    | BenefitFlyoutInputTableIncremental
    | BenefitFlyoutInputTableTotal
  )[] = [];

  selectedCol: number = 0;
  selectedColPadding: number[] = [];
  selectedColLabelPadding: number = 0;
  selectedScratchpad: DriverScratchpadClass;
  scratchpadCalcTotalToggle: boolean = false;
  scratchpadCalcTotal: string;
  scratchPadBenefitField: string;
  ngUnsubscribe$ = new Subject();
  benefitUpdated: number | null = null;
  scratchpadOpen: number | null = null;
  userData: UserInfo;
  numberLocales = ['en-US', 'en-US', 'de-DE', 'fr-FR'];
  isLoading = false;
  businessReviewIndex: number;
  scratchPadEditIndex: number | null = null;
  toggledRow;
  canEdit = false;
  public factorNameFormArray: FormArray = this.fb.array([]);
  defaultUnitTypes = defaultUnitTypes;

  constructor(
    private valuePropService: ValuepropService,
    private valueRealizationService: ValuerealizationService,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    public trans: BenefitFlyoutInputTableTranslation,
    private translationService: TranslationsV2Service,
    private userService: UserService,
    private businessReviewService: TrackerBusinessReviewService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.scratchpadOperatorTypes();
    this.userService.user.pipe(take(1)).subscribe((userInfo) => {
      this.userData = userInfo;
    });
    this.valueRealizationService.saveBenefitPerformance$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      if ( !this.selectedScratchpad ) {
        this.saveBenefitPerformance();
      } else {
        this.saveScratchpad();
      }
    });
    this.businessReviewService.canEdit.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((canEdit) => {
      this.canEdit = canEdit;
    });
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
        this.buildBenefitSurveyDataTableColumns();
        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  buildBenefitSurveyDataTableColumns() {
    this.insertBusinessReviews(0);

    this.cd.detectChanges();
  }

  scratchpadOperatorTypes() {
    this.valuePropService
      .scratchpadOperatorTypes()
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.operatorList = response.result;
        }
      });
  }

  createBenefitSurveyDataTable() {
    this.addHeaderRow();
    this.factorNameFormArray.clear();

    if (this.benefit.driver_factor) {
      this.addDriverFactorRow();
    }

    if (this.benefit.improvement_factor) {
      this.addImprovementFactorRow();
    }

    if (this.benefit.financial_factor) {
      this.addFinancialFactorRow();
    }

    this.addSecondHeader();

    this.addIncrementalRow();
    this.addTotalRow();
    this.addExpectedValueToDateRow();
    this.addExpectedVarianceToDateRow();

    this.benefitSurveyTable = this.benefitSurveyTable.filter((x: any) => +x?.improvement_factor_id !== 2 && +x?.financial_factor_id !== 2 && +x?.driver_factor_id !== 2);
    this.benefitSurveyTableCache = cloneDeep(this.benefitSurveyTable);
  }

  formatDateRange(dateRange: string): string {
    // Split the input string by ' - '
    const dates = dateRange.split(' - ');

    // Extract the year and month from each date and format them
    const formattedDates = dates.map((date) => {
      const [year, month] = date.split('-');
      const shortYear = year.slice(-2);
      return `${month}/${shortYear}`;
    });

    // Join the formatted dates with ' - '
    const result = formattedDates.join(`<b style='color: #f4f4f4;'> - </b>`);
    return result;
  }

  addHeaderRow() {
    const headerRow = new BenefitFlyoutInputTableHeader();

    this.businessReviews.forEach((br, i) => {
      headerRow['year' + i + '_fmt'] = this.formatDateRange(br.period_of_performance);
      headerRow['year' + i] = this.formatDateRange(br.period_of_performance);
    });

    this.benefitSurveyTable.push(headerRow);
  }

  addDriverFactorRow() {
    this.factorNameFormArray.push(
      this.fb.group({
        name: new FormControl(this.benefit.driver_factor, [Validators.maxLength(200), Validators.required]),
        editing: new FormControl(false),
      })
    );
    const driverRow = new BenefitFlyoutInputTableDriverFactor();
    driverRow.formula = this.benefit.driver_factor;
    driverRow.driver_factor_id = this.benefit.driver_factor_id;
    driverRow.factor_id = this.benefit.driver_factor_id;
    driverRow.driver_source_type_id = this.benefit.driver_source_type_id;
    driverRow.driver_override = this.benefit['year' + 1 + '_driver'];
    driverRow.precision = +this.benefit.d_factor_precision;
    this.businessReviews.forEach((br, i) => {
      driverRow['year' + i + '_has_scratchpad'] = br.has_driver_scratchpad;

      if (br.has_driver_scratchpad) {
        driverRow['year' + i + '_scratchpad'] = br.driver_scratchpad;
      }
      driverRow['year' + i + '_scratchpad_used'] = br.driver_scratchpad_used;

      driverRow['year' + i + '_fmt'] = br.value_survey_driver;
      driverRow['year' + i] = i == 1 ? br.value_survey_driver : br.value_survey_driver;
    });

    this.benefitSurveyTable.push(driverRow);
  }

  addImprovementFactorRow() {
    const ifRow = new BenefitFlyoutInputTableImprovementFactor();

    let improvementName: string;
    if (this.benefit.goal_factor_name && this.benefit.goal_factor_name !== '') {
      improvementName = this.benefit.goal_factor_name;
    } else {
      improvementName = this.benefit.improvement_factor;
    }

    this.factorNameFormArray.push(
      this.fb.group({
        name: new FormControl(improvementName, [Validators.maxLength(200), Validators.required]),
        editing: new FormControl(false),
      })
    );

    ifRow.formula = improvementName;
    ifRow.factorTypeId = 3;

    ifRow.description = this.benefit.improvement_description;
    ifRow.improvement_factor_id = this.benefit.improvement_factor_id;
    ifRow.factor_id = this.benefit.improvement_factor_id;
    ifRow.impact = this.benefit.impact_fmt;
    ifRow.value_prop_metric_id = this.benefit.value_prop_metric_id;
    ifRow.precision = +this.benefit.i_factor_precision;

    this.businessReviews.forEach((br, i) => {
      const improvementScratchpad = br.has_improvement_scratchpad && br.value_survey_improvement != null && br.goal_value == null;
      const goalScratchpad = br.has_goal_factor_scratchpad && br.goal_value != null;
      ifRow['year' + i + '_has_scratchpad'] = improvementScratchpad || goalScratchpad;

      if (goalScratchpad) {
        ifRow.benefitField = 'goal_value';
        ifRow['year' + i + '_scratchpad'] = br.goal_factor_scratchpad;
      } else if (improvementScratchpad) {
        ifRow['year' + i + '_scratchpad'] = br.improvement_scratchpad;
      }
      ifRow['year' + i + '_scratchpad_used'] = br.improvement_scratchpad_used;

      ifRow['year' + i + '_fmt'] = this.benefit.abbr == '%' ? br.value_survey_improvement + this.benefit.abbr : this.benefit.abbr + br.value_survey_improvement;
      ifRow['year' + i] =
        i == 1 ? br.value_survey_improvement : this.benefit.abbr == '%' ? br.value_survey_improvement + this.benefit.abbr : this.benefit.abbr + br.value_survey_improvement;
    });
    this.benefitSurveyTable.push(ifRow);
  }

  addFinancialFactorRow() {
    const ffRow = new BenefitFlyoutInputTableFinancialFactor();
    this.factorNameFormArray.push(
      this.fb.group({
        name: new FormControl(this.benefit.financial_factor, [Validators.maxLength(200), Validators.required]),
        editing: new FormControl(false),
      })
    );
    ffRow.formula = this.benefit.financial_factor;
    ffRow.description = this.benefit.financial_description;
    ffRow.financial_factor_id = this.benefit.financial_factor_id;
    ffRow.factor_id = this.benefit.financial_factor_id;
    ffRow.financial_override = this.benefit.financial_override;
    ffRow.financial_source_type_id = this.benefit.financial_source_type_id;
    ffRow.precision = +this.benefit.f_factor_precision;
    this.businessReviews.forEach((br, i) => {
      ffRow['year' + i + '_has_scratchpad'] = br.has_financial_scratchpad;
      ffRow['year' + i + '_scratchpad_id'] = br?.financial_scratchpad_id;

      if (br.has_financial_scratchpad) {
        ffRow['year' + i + '_scratchpad'] = br.financial_scratchpad;
      }
      ffRow['year' + i + '_scratchpad_used'] = br.financial_scratchpad_used;

      ffRow['year' + i + '_fmt'] = br.value_survey_financial;
      ffRow['year' + i] = i == 1 ? br.value_survey_financial : br.value_survey_financial;
    });
    this.benefitSurveyTable.push(ffRow);
  }

  addIncrementalRow() {
    const incrementalRow = new BenefitFlyoutInputTableIncremental();

    this.businessReviews.forEach((br, i) => {
      incrementalRow['year_' + i + '_fmt'] = br.incremental_value_this_survey_period_fmt;
      incrementalRow['year' + i] = br.incremental_value_this_survey_period_fmt;
    });
    this.benefitSurveyTable.push(incrementalRow);
  }

  addTotalRow() {
    const totalRow = new BenefitFlyoutInputTableTotal();

    this.businessReviews.forEach((br, i) => {
      totalRow['year_' + i + '_fmt'] = br.total_since_contract_date_fmt;
      totalRow['year' + i] = br.total_since_contract_date_fmt;
    });
    this.benefitSurveyTable.push(totalRow);
  }

  addExpectedValueToDateRow() {
    const evdRow = new BenefitFlyoutInputTableExpectedValue();

    this.businessReviews.forEach((br, i) => {
      evdRow['year_' + i + '_fmt'] = br.expected_benefits_to_date_fmt;
      evdRow['year' + i] = br.expected_benefits_to_date_fmt;
    });
    this.benefitSurveyTable.push(evdRow);
  }

  addExpectedVarianceToDateRow() {
    const evdRow = new BenefitFlyoutInputTableVariance();

    this.businessReviews.forEach((br, i) => {
      evdRow['year' + i] = br.variance_to_date_fmt;
      evdRow['year_' + i + '_fmt'] = br.variance_to_date_fmt;
    });
    this.benefitSurveyTable.push(evdRow);
  }

  funcGetScratchpadPadding(i: number, row) {
    if (this.scratchPadEditIndex !== null) {
      this.scratchpadToggledTrigger.emit(null);
      this.scratchpadOpen = null;
      if ( this.benefitUpdated !== null ) {
        // Something was updated, throw a warning but don't unset values
        this.notificationService.warning(this.trans.trans.unsavedChanges.value, false);
        return;
      }

      this.scratchPadEditIndex = null;
      this.toggledRow = null;
      this.selectedScratchpad = null;
      this.scratchPadBenefitField = null;

      return;
    } else {
      this.scratchpadToggledTrigger.emit(i);
      this.scratchpadOpen = i;
      this.toggledRow = row;
      this.scratchPadEditIndex = i;
    }
    let a = i - 2;
    this.selectedColLabelPadding = i + 1;
    this.selectedColPadding = [];
    this.selectedCol = a;
    for (let i = 0; i < this.selectedCol; i++) {
      this.selectedColPadding.push(i);
    }

    let s = this.businessReviewIndex + i - 2;
    this.selectedScratchpad = row['year' + s + '_scratchpad'];
    this.scratchPadBenefitField = row.benefitField;

    this.calcScratchpad();
  }

  markScratchpadAsTouched() {
    this.inputUpdated(2);
    this.calcScratchpad();
  }

  calcScratchpad() {
    let factor_id = [];
    let operand = [];
    let operator_id = [];
    let open_paren = [];
    let close_paren = [];

    this.selectedScratchpad.units.forEach((elem) => {
      factor_id.push(elem.factor_id);
      operand.push(elem.operand);
      operator_id.push(elem.operator_id);
      open_paren.push(elem.open_paren);
      close_paren.push(elem.close_paren);
    });
    let payload = {
      scratchpad_factor_id: this.selectedScratchpad.factor_id,
      scratchpad_unit_type_id: this.selectedScratchpad.unit_type_id,
      factor_id: factor_id.join(','),
      operand: operand.join(','),
      operator_id: operator_id.join(','),
      open_paren: open_paren.join(','),
      close_paren: close_paren.join(','),
    };

    this.valueRealizationService
      .postCalcScratchpadTotal(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.scratchpadCalcTotal = res.result.total;
        this.scratchpadCalcTotalToggle = true;
        this.cd.detectChanges();
      });
  }

  saveScratchpad() {
    this.isLoading = true;
    const brUpdated = this.businessReviews.find((br) => br.business_review_id == this.selectedScratchpad.business_review_id);
    const payload: UpdateBrScratchpadPayload = {
      value_survey_id: this.selectedScratchpad.value_survey_id,
      business_review_id: this.selectedScratchpad.business_review_id,
      units: this.selectedScratchpad.units,
    };
    if (brUpdated) {
      this.valueRealizationService.putBusinessReviewScratchpad(this.valuePropId, payload).subscribe(
        () => {
          brUpdated[this.scratchPadBenefitField] = this.scratchpadCalcTotal;
          this.notificationService.success(this.trans.trans.scratchpadSuccess.value, false);
          this.cd.detectChanges();
          this.update.emit();
          this.isLoading = false;
        },
        () => {
          this.notificationService.error(this.trans.trans.scratchpadError.value, false);
          this.isLoading = false;
        }
      );
    }
  }

  saveBenefitPerformance() {
    this.isLoading = true;
    let payload = {
      value_prop_id: this.benefit.value_prop_id,
      surveys: this.businessReviews,
    };
    this.valueRealizationService.updateValueSurveyMetricFresh(this.benefit.account_solution_metric_id, payload).subscribe(() => {
      this.businessReviewService.showBenefitSaved = true;
      this.update.emit();
      this.isLoading = false;
    });
  }

  inputUpdated(i: number) {
    if (this.benefitUpdated === null) {
      this.benefitUpdated = i;
    } else {
      this.benefitUpdated = Math.min(this.benefitUpdated, i);
    }
    this.benefitUpdatedTrigger.emit(this.benefitUpdated);
  }

  removeNegatives(value: number): number {
    return value < 0 ? 0 : Math.min(value, 9);
  }

  closeSidebarCallback() {
    this.closeSidebar.emit();
  }

  prevReview(): void {
    if (this.businessReviewIndex > 0) {
      this.businessReviewIndex -= 1;
      this.insertBusinessReviews(this.businessReviewIndex);
    }
  }

  nextReview(): void {
    if (this.businessReviewIndex < this.businessReviews.length - 3) {
      this.businessReviewIndex += 1;
      this.insertBusinessReviews(this.businessReviewIndex);
    }
  }

  private addSecondHeader(): void {
    const headerRow = new BenefitFlyoutInputTableHeader('Benefit Performance', false, '');
    headerRow['type'] = 'secondHeader';

    this.benefitSurveyTable.push(headerRow);
  }

  private insertBusinessReviews(index: number): void {
    this.benefitSurveyTableColumns = [];

    this.benefitSurveyTableColumns.push({ field: 'formula', style: 'left', header: this.trans.trans.achievedValue.value, width: '240px', editable: false });
    this.benefitSurveyTableColumns.push({ field: 'calc', style: 'center', header: '+ / -', width: '40px', editable: false });

    this.businessReviewIndex = index;
    const entries = this.businessReviews.length > 3 ? 3 : this.businessReviews.length;

    for (let index = 0; index < entries; index++) {
      const brTitle = {
        field: 'year' + (index + this.businessReviewIndex),
        style: 'left',
        header: this.businessReviews[index + this.businessReviewIndex].business_review_name,
        width: '220px',
        editable: true,
        index: index + this.businessReviewIndex,
        type: index === 0 ? 'carouselPrev' : index === entries - 1 ? 'carouselNext' : '',
      };
      this.benefitSurveyTableColumns.push(brTitle);
    }
  }

  editFactorName(index: number, factorName: string | null = null, editing = null): void {
    if (factorName) {
      this.factorNameFormArray.at(index).patchValue({
        name: factorName,
        editing: editing ?? !this.factorNameFormArray.at(index).get('editing').value,
      });
    } else {
      this.factorNameFormArray.at(index).patchValue({
        editing: editing ?? !this.factorNameFormArray.at(index).get('editing').value,
      });
    }
  }

  saveFactorName(index: number, factor_id: number): void {
    if (this.factorNameFormArray.at(index).get('name').invalid) {
      return;
    }
    const vr_override_name = this.factorNameFormArray.at(index).get('name').value;
    const payload = {
      factor_id,
      vr_override_name,
    };

    this.valuePropService
      .postEditFactorNameFromBenefits(this.valuePropId, payload)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.factorNameUpdated.value, false);
        this.editFactorName(index, vr_override_name);
        this.updateNameInGroup(factor_id, vr_override_name);
        this.businessReviewService.benefitRefresh();
      });
  }

  private updateNameInGroup(factorId: number, vr_override_name: string): void {
    const factorNameGroup = this.benefitSurveyTable.find((row) => {
      if (row.factor_id) {
        return row.factor_id === factorId.toString();
      }
    });
    factorNameGroup.formula = vr_override_name;
  }
}
