<div class="row" *ngIf="style2022$ | async">
    <div class="col-md-4 mr-auto breadcrumb-container">
      <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
    </div>
  </div>
  
  <div class="row" *ngIf="!(style2022$ | async)">
    <div class="col-md-10 old-breadcrumb">
      <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.back_to_profile.value }}</a>
    </div>
  </div>
  <div class="models_panel" style="margin-bottom: 20px; height: 100%">
    <div class="models_page_header_container_small" style="height: 100%">
      <div *ngIf="customAnalyticsEnabled === false" class="keywest_header_small" height="height: 75vh">
        <div class="" >
          <div>
            <p class="analytics-message">{{ trans.trans.contact_admin.value }}</p>
            <img class="mt-5" src="assets/images/no-data-placeholder.svg"  />
          </div>
        </div>
      </div>
    </div>
  </div>