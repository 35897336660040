import { Injectable } from '@angular/core';

@Injectable ()

export class ListDetailTranslations {

    public config: any = {
        component: 'DLListDetail',
        display_name: 'List of Value Props',
        description: 'Table to select multiple value props'
    };

    public trans: any = {
        search: 'Search',
        no_records: 'No records found',
        info: 'info',
        add: 'Add',
        selected: 'Selected',
        clear_all: 'Clear all',
        click_action: 'Click add on the left to add a Value Prop',
        save: 'Save',
        are_you_sure: 'Are you sure?',
        back: 'Back'
    };
}
