import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';

@Injectable()
export class SupportService {

  service_url: string;
  constructor(private CommonService: CommonService) {
    this.service_url = this.CommonService.getServiceUrl();
  }

  public getFaq(searchTerm) {
    let endpoint = "faqs/list/" + searchTerm;
    return this.CommonService.getAPIService(endpoint);
  }
  public postQueries(payload) {
    let endpoint = "users/submit/Issue";
    return this.CommonService.postAPIService(endpoint, payload, 'multipart/form-data');
  }

}
