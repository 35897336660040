<div class="col-md-6 " style='margin-bottom: 10px;'>
	<div class='models_panel dashboard-card' style='height: 400px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>{{ trans.trans.valueRealizationHeading.value}}<app-editTranslation *ngIf="showTranslate" (callback)='dbI.refreshTranslation();' key='main_dashboard.valueRealizationsHeading'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.valueRealizationsHeading"></app-editTranslation>
						</div>
					</div>
					<div class='col-xs-2'>
						<button *ngIf="dbI.showAddButtons" routerLink="/valuerealization" class="btn btn_customizable pull-right"><i class='fa fa-plus'></i>
							{{ trans.trans.add.value}}</button>
					</div>
				</div>
			</div>
		</div>


		<div style='overflow-y: auto; max-height: 270px;'>
			<div *ngIf="loading">
				<p-progressBar mode="indeterminate"></p-progressBar>
			</div>
			
			<table *ngIf="!loading" class="table custom-table dashboard-table fixedTable">
				<thead>
					<tr>
						<th width="60%">{{ trans.trans.vr_name.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='dbI.refreshTranslation();' key='main_dashboard.vr_name'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.vr_name"></app-editTranslation>
						</th>
						<th width="20%" class="text-right">
							{{ trans.trans.vr_expected.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='dbI.refreshTranslation();' key='main_dashboard.vr_expected'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.vr_expected"></app-editTranslation>
						</th>
						<th width="20%" class="text-right">
							{{ trans.trans.vr_realized.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='dbI.refreshTranslation();' key='main_dashboard.vr_realized'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.vr_realized"></app-editTranslation>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="grid-row company-row" *ngFor="let retain of retainList">
						<td>
							<a routerLink="/valuerealization/{{retain.id}}" class='link'>{{retain.name}}</a>
							<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle tooltip-custom" [ngbTooltip]="retain.name" placement="bottom" container="body"></i>
							<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="retain.name">
								info
							</span>
						</td>
						<td class="text-right">{{retain.total_benefits_fmt}}</td>
						<td class="text-right">
							{{retain.improvement_fmt}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="retainList.length==0" class="m-t-md col-sm-12">
			<div class="text-center">
				<h4>{{ trans.trans.vr_noValueRealizations.value}}
					<app-editTranslation *ngIf="showTranslate" (callback)='dbI.refreshTranslation();' key='main_dashboard.vr_seeMore'
					[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.vr_noValueRealizations"></app-editTranslation></h4>
			</div>
		</div>
		<a routerLink="/valuerealization" class="keywest_seeMore">{{ trans.trans.vr_seeMore.value }}</a>
		<app-editTranslation *ngIf="showTranslate" (callback)='dbI.refreshTranslation();' key='main_dashboard.vr_seeMore'
		[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.vr_seeMore"></app-editTranslation>

	</div>

</div>