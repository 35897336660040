import { NgModule } from '@angular/core';

import { SpaceToUnderscoreDirective } from './space-to-underscore.directive';
import { SpecialCharacterValidatorDirective } from './special-character-validator';

@NgModule({
    imports: [],
    exports: [SpaceToUnderscoreDirective, SpecialCharacterValidatorDirective],
    declarations: [SpaceToUnderscoreDirective, SpecialCharacterValidatorDirective],
    providers: [],
})
export class DirectivesModule { }
