import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
})
export class LineChartComponent implements OnInit {
  @Input('data') chartData: any;
  @Input() style2022 = false;
  @Input() height = '300px';

  id = '';
  chart: any;
  options: any;

  ngOnInit(): void {
    this.buildChart(this.chartData);
  }

  buildChart(chartData: any): void {
    this.chart = {
      labels: this.chartData.data.labels,
      datasets: this.chartData.data.datasets,
    };
    this.options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                const number = value;
                const numberLength = number.toString().length;
                let unit = '';

                if (numberLength >= 4 && numberLength <= 6) {
                  unit = 'K';
                } else if (numberLength >= 7 && numberLength <= 9) {
                  unit = 'M';
                } else if (numberLength >= 10 && numberLength <= 13) {
                  unit = 'B';
                }

                switch (unit) {
                  case 'K':
                    return Math.round(Number(number) / 1000) + unit;
                  case 'M':
                    return Math.round(Number(number) / 1000000) + unit;
                  case 'B':
                    return Math.round(Number(number) / 1000000000) + unit;
                  default:
                    return number;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        xAlign: 'left',
        yAlign: 'center',
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            if (tooltipItem.datasetIndex == 0) {
              if (chartData['d0']) {
                label += chartData['d0'][tooltipItem.index];
              }
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          fontSize: 10,
        },
      },
      animation: {
        duration: 100,
      },
    };
    if (this.style2022) {
      this.options = {
        ...this.options,
        legend: {
          position: 'bottom',
          labels: {
            fontSize: 10,
            padding: 20,
            boxWidth: 10,
          },
        },
      };
    }
  }
}
