import { Pipe, PipeTransform } from '@angular/core';
import { OperatorTypeList } from 'app/value-map-maintenance/views/factors/interfaces/factors.interface';

@Pipe({
  name: 'getScrachpadSign'
})
export class GetScrachpadSignPipe implements PipeTransform {

  transform(id: string | number, operatorList: OperatorTypeList): string | number {
    const out = operatorList.filter((x) => x['id'] == id);
    return out.length ? out[0].sign : '';
  }

}
