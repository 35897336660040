import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { DiscoveryQuestionsComponent } from './questions.component';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {InputSwitchModule} from 'primeng/inputswitch';

import {FieldsetModule} from 'primeng/fieldset';

import { Translations } from '@data/services/translation/translation.service';
import { EditTranslationsModule} from '@shared_components/editTranslation/edit.directive.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DiscoverQuestionsVpDashTranslations } from './question.translation';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
	imports: [
		CommonModule,
		NgbModule,
		FormsModule,
		EditTranslationsModule,
		AddCompanyModule,
		InputSwitchModule,
		FieldsetModule,
    MatTooltipModule,
	],
	declarations: [
		DiscoveryQuestionsComponent
	],
	providers: [
		Translations,
		TranslationsV2Service,
		DiscoverQuestionsVpDashTranslations
	],
	exports: [DiscoveryQuestionsComponent]
})
export class DiscoveryQuestionsModule { }
