
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { PainPointsService } from '@data/services/pain_points/pain.service';
import { CommonService } from '@data/services/common/common.service';
import { Observable } from 'rxjs';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-value-map-pain-points',
	templateUrl: './pain.component.html',
	styleUrls: ['./pain.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ValueMapPainPointsComponent implements OnInit, OnDestroy {
	id: string = "";
	name: string = "";
	description: string = "";
	showAdd: boolean = false;
	painPoints: any[] = [];
	account_id: any;

	// new
	addEditPainPoints: any;
	loadingSave: boolean = false;
	togglePainPoints: boolean = false;
	sidebar_title: string = "Add New Pain Point";
	addedit: string = "add";
	cols = [
		{ field: 'name', header: 'Name' },
		{ field: 'description', header: 'Description' }
	]

	menu: any[] = [];
	fullImagePath: string;
	style2022$: Observable<boolean>;

	constructor(
		private painPoint: PainPointsService,
		private commonService: CommonService,
		private styleService: StyleService
	) {
		this.fullImagePath = this.commonService.getLoaderImageUrl();
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.account_id = sessionStorage.getItem("aid");
		this.funcGet();
	}

	ngOnDestroy() {

	}

	funcBuildMenu(elem, menu, event) {
		this.menu = [
			{
				label: "Edit", icon: "fa fa-pencil", command: () => {
					this.funcEdit();
					this.id = elem.id;
					this.name = elem.name;
					this.description = elem.description;
					this.addedit = "edit";
				}
			},
			{ label: "Delete", icon: "fa fa-times", command: () => { this.funcDelete(elem); } }
		]
		menu.toggle(event)
	}

	funcGet() {
		this.painPoint.getPainPoints(this.account_id).subscribe(res => {
			if (res.result) {
				this.painPoints = res.result;
			}
		})
	}

	funcSave() {
		if (this.addedit == "edit") {
			this.funcSaveEdit();
			return;
		}
		this.loadingSave = true;
		let payload = {
			name: this.name,
			description: this.description
		}

		this.painPoint.postPainPoint(this.account_id, payload).subscribe(() => {
			this.funcGet();
			this.loadingSave = false;
			this.togglePainPoints = false;
			this.name = "";
			this.description = "";
			this.showAdd = false;
		})
	}

	funcShowAddPainPoint() {
		this.addedit = "add";
		this.togglePainPoints = !this.togglePainPoints;
		this.sidebar_title = "Add Pain Point";
		this.id = "";
		this.name = "";
		this.description = "";
	}

	funcDelete(elem) {
		this.painPoint.deletePainPoint(this.account_id, elem.id).subscribe(() => {
			this.funcGet();
		})
	}

	funcEdit() {
		this.sidebar_title = "Edit Pain Point";
		this.togglePainPoints = !this.togglePainPoints;
	}

	funcSaveEdit() {
		this.loadingSave = true;

		let payload = {
			account_painpoint_id: this.id,
			name: this.name,
			description: this.description
		}
		this.painPoint.putPainPoint(this.account_id, payload).subscribe(() => {
			this.funcGet();
			this.loadingSave = false;
			this.togglePainPoints = false;
		})
	}
}