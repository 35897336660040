<h2>{{'props.tabDetails.benefits.Subtypesof' | translate}}: {{valueMetric.driver_factor}}</h2>

<div class="clearfix">
	<table id="benefits" class="table custom-table benefitSubDriverTable">
		<thead>
			<tr>
				<th>{{'props.tabDetails.benefits.SubType' | translate}}</th>
				<th>{{'props.tabDetails.benefits.Value' | translate}}</th>
				<th>{{'props.tabDetails.benefits.Improvement' | translate}} [{{valueMetric.abbr}}]</th>
				<th>{{'props.tabDetails.benefits.FinancialFactor' | translate}}</th>
				<th>{{'props.tabDetails.benefits.FinancialValue' | translate}}</th>
				<th></th>
			</tr>
		</thead>
		<tbody *ngIf="benefitsSubTypesList && benefitsSubTypesList!==null && benefitsSubTypesList.length>0">
			<tr *ngFor="let benefitSubType of benefitsSubTypesList" class="grid-row">
				<td>
					<input type="text" class="form-control" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.name}}" [(ngModel)]="benefitSubType.name" />
				</td>
				<td [hidden]="true">
					<input type="text" class="form-control" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.driver_factor_id}}" [(ngModel)]="benefitSubType.driver_factor_id" />
				</td>
				<td>
					<input type="text" class="form-control inlineInput" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.driver_value}}" [(ngModel)]="benefitSubType.driver_value" /> X
				</td>
				<td>
					<input type="text" class="form-control inlineInput" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.improvement_value}}" [(ngModel)]="benefitSubType.improvement_value" /> X
				</td>
				<td>{{benefitSubType.financial_factor}}</td>
				<td>
					<input type="text" class="form-control" [disabled]='!isSubTypeEnabled' value="{{benefitSubType.financial_value}}" [(ngModel)]="benefitSubType.financial_value" />
				</td>
				<td class="benefitSubTypesClose">
					<span (click)="textClose(benefitSubType.id)">
						<i class="fa fa-times subTypesClose"></i>
					</span>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="pull-right">
		<button class="btn btn-primary float-right" (click)="addSubTypes()" [disabled]='!isSubTypeEnabled'>
			<span class="glyphicon glyphicon-plus"></span>&nbsp;{{ 'general.add' | translate}}</button>
	</div>
</div>
<div class="modal-footer subType-footer">
	<button class="btn btn-primary" (click)="saveSubType()" [disabled]='!isSubTypeEnabled'>{{ 'general.save' | translate}}</button>
	<button class="btn btn-primary" (click)="disableSubType(isSubTypeEnabled)">
		{{ !isSubTypeEnabled ? 'Enable' : 'Disable' }}
	</button>
	<button (click)="sendNotification()" class="btn btn-danger">
		<i class="fa fa-times"></i>&nbsp;{{ 'general.Cancel' | translate }}
	</button>
</div>