import { Component, OnInit } from '@angular/core';
import { BenefitsImplementation } from '../../benefits.implementation';
import { Translations } from '@data/services/translation/translation.service';
import { Observable } from 'rxjs';
import { StyleService } from 'app/style.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-benefit-panel',
  templateUrl: './benefits-panel.component.html',
})
export class BenefitPanelComponent implements OnInit {
  benefitsList: any[] = [];
  style2022$: Observable<boolean>;
  canEdit = true;

  constructor(public trans: Translations, public bI: BenefitsImplementation, private styleService: StyleService) {}

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.canEdit = this.bI.valueProp.vp_can_edit;
  }

  drop(dropEvent: CdkDragDrop<any[]>, dropArray: any[]) {
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
    this.bI.saveDropEvent(dropArray);
  }
}
