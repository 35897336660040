<div *ngIf="loading" class="text-center">
  <img [src]="fullImagePath" class="loader" /><br />
  <p>{{ trans.trans.loading.value }}</p>
</div>
<div class="panel rsv2-sc-component">
  <div class="panel-heading rsv2-sc-heading">
    <p>
      {{ trans.trans.ch_scenario.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.ch_scenario"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </p>
  </div>
</div>

<div class="row" *ngIf="!loading">
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <div class="panel rsv2-sc-component">
      <div class="panel-heading rsv2-sc-heading">
        <span>
          {{ trans.trans.account.value }}
          <i *ngIf="company && company.can_edit && !locked" (click)="showAccountEdit = !showAccountEdit" class="fa fa-pencil pull-right clickable"></i>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.account"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </span>
      </div>
      <div class="panel-body rsv2-sc-body" *ngIf="company && company.name">
        <span>{{ company.name }}</span>
        <ng-container *ngIf="!(isShared || feature101)">
          <div class="panel panel-sub">
            <div class="row">
              <div class="col-xs-6">
                <span class="key"
                  >{{ trans.trans.Revenue.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.Revenue"
                    (callback)="getTranslations()"
                  ></app-editTranslation> </span
                ><br />
                <span class="param" style="padding-left: 0px">{{ company.revenue_fmt }}</span>
              </div>
              <div class="col-xs-6">
                <span class="key"
                  >{{ trans.trans.employees.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.employees"
                    (callback)="getTranslations()"
                  ></app-editTranslation> </span
                ><br />
                <span class="param" style="padding-left: 0px">{{ company.employees == 0 ? 'N/A' : company.employees }}</span>
              </div>
            </div>
          </div>
          <table style="width: 100%" class="table-spaced">
            <tr *ngIf="company.name">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.account_id.value }}:
              </td>
              <td class="param">{{ company.account_defined_id }}</td>
            </tr>
            <tr *ngIf="company.address1 && company.address1 != 'NULL' && company.address1 != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.addr1.value }}:
              </td>
              <td class="param">{{ company.address1 }}</td>
            </tr>
            <tr *ngIf="company.address2 && company.address2 != 'NULL' && company.address2 != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.addr2.value }}:
              </td>
              <td class="param">{{ company.address2 }}</td>
            </tr>
            <tr *ngIf="company.city && company.city != 'NULL' && company.city != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.city.value }}:
              </td>
              <td class="param">{{ company.city }}</td>
            </tr>
            <tr *ngIf="company.state && company.state != 'NULL' && company.state != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.state.value }}:
              </td>
              <td class="param">{{ company.state }}</td>
            </tr>
            <tr *ngIf="company.zip && company.zip != 'NULL' && company.zip != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.zip.value }}:
              </td>
              <td class="param">{{ company.zip }}</td>
            </tr>
            <tr *ngIf="company.country && company.country != 'NULL' && company.country != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.country.value }}:
              </td>
              <td class="param">{{ company.country }}</td>
            </tr>
            <tr *ngIf="company.phone && company.phone != 'NULL' && company.phone != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.phone.value }}:
              </td>
              <td class="param">{{ company.phone }}</td>
            </tr>
            <tr *ngIf="company.url && company.url != 'NULL' && company.url != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.website.value }}:
              </td>
              <td class="param">{{ company.url }}</td>
            </tr>
            <tr *ngIf="company.ticker && company.ticker != 'NULL' && company.ticker != ''">
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.ticker.value }}:
              </td>
              <td class="param">{{ company.ticker }}</td>
            </tr>
            <tr>
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.industry.value }}:
              </td>
              <td class="param">{{ company.dl_sector_name }}</td>
            </tr>
            <tr>
              <td class="key">
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.account_id"
                  (callback)="getTranslations()"
                ></app-editTranslation
                >{{ trans.trans.industry_sector.value }}:
              </td>
              <td class="param">{{ company.industry_name }}</td>
            </tr>
          </table>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <div class="panel rsv2-sc-component">
      <div class="panel-heading rsv2-sc-heading">
        <span>
          {{ trans.trans.models.value }}
          <i
            *ngIf="!hasModels && view == 'simple'"
            ngbTooltip="Models are required to continue"
            container="body"
            placement="right"
            class="fa fa-exclamation-triangle"
            style="color: red"
          ></i>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.models"
            (callback)="getTranslations()"
          ></app-editTranslation>
          <i *ngIf="canEdit && view == 'simple' && !locked" (click)="showModelsEditing = !showModelsEditing" class="fa fa-pencil pull-right clickable"></i>
        </span>
      </div>
      <div class="panel-body rsv2-sc-body">
        <div *ngFor="let item of availableSolutions">
          <div *ngIf="item.isChecked" class="models">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <app-situations-shared [valueProp]="valueProp" [isExternalShare]="true" [canEdit]="canEdit"></app-situations-shared>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
    <div class="panel rsv2-sc-component" *ngIf="scalers.length">
      <div class="panel-heading rsv2-sc-heading">
        <span>
          {{ trans.trans.size_your_value.value }}
          <i *ngIf="isDefault == '1'" [matTooltip]="trans.trans.ch_warning.value" matTooltipPosition="right" class="fa fa-exclamation-triangle" style="color: red"></i>
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.size_your_value"
            (callback)="getTranslations()"
          ></app-editTranslation>
          <i *ngIf="canEdit && !locked" (click)="showSizeYourValue = !showSizeYourValue" class="fa fa-pencil pull-right clickable icon-primary"></i>
        </span>
      </div>
      <div class="panel-body rsv2-sc-body">
        <table style="width: 100%" class="table-spaced" *ngIf="masterScalerList.length">
          <tr *ngFor="let item of masterScalerList; let i = index">
            <td class="key" style="width: 60%">{{ item.name }}</td>
            <td class="param">{{ item.value }}</td>
          </tr>
        </table>
        <table style="width: 100%" class="table-spaced">
          <tr *ngFor="let scaler of scalers; let index = index">
            <td class="key" style="width: 60%">{{ scaler.name }}</td>
            <td class="param">{{ scaler.value_fmt }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <p-sidebar [(visible)]="showSizeYourValue" position="right" styleClass="keywest_sidebar p-sidebar-lg">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.size_your_value.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.size_your_value"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div *ngIf="feature26 && masterScalerList.length">
            <div class="row">
              <div class="col-xs-12">
                <h3 style="margin-top: 0px">
                  {{ trans.trans.primary_scalers.value
                  }}<app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.primary_scalers"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-3" *ngFor="let item of masterScalerList; let i = index">
                <b>{{ item.name }}</b> <input type="text" name="masterscaler{{ item.id }}" class="form-control" [required]="item.greater_than_zero" [(ngModel)]="item.value" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6">
                <img class="actionLoader" *ngIf="masterCalculateLoading" [src]="fullImagePath" />
                <span *ngIf="!masterCalculateLoading" class="btn btn-primary btn-sm clickable" (click)="masterScalerCalculate()"
                  ><i class="fa fa-calculator"></i> {{ trans.trans.calculate.value }}</span
                >
              </div>
            </div>
          </div>
          <div class="row" *ngFor="let scaler of scalers; let index = index">
            <div class="col-xs-12 scaler-list" *ngIf="scaler.name != 'Does Not Scale'">
              <label style="font-weight: bold">{{ scaler.name }}</label
              ><br />
              <small *ngIf="scaler.description !== scaler.name">{{ scaler.description }}</small>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12 scaler-list">
              <input type="number" class="form-control" min="0" id="" [(ngModel)]="scaler.value" [required]="scaler.greater_than_zero" name="scaler_{{ scaler.id }}" />
            </div>
          </div>
          <div [ngClass]="{ row: crm, btn_footer: !crm }">
            <div class="col-xs-12">
              <img class="actionLoader pull-right" *ngIf="scalersLoader" [src]="fullImagePath" />
              <button *ngIf="!scalersLoader" type="button" class="btn btn_customizable pull-right" (click)="createScaler()">{{ trans.trans.save.value }}</button>
              <app-are-you-sure
                #scalerCancel
                *ngIf="!scalersLoader"
                matTooltipPosition="above"
                [message]="trans.trans.are_you_sure.value"
                class="btn pull-right cancel-confirm"
                icon=""
                [text]="trans.trans.back.value"
                (callbackYes)="closeScaler(scalerCancel)"
              ></app-are-you-sure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>

  <p-sidebar [(visible)]="showModelsEditing" *ngIf="company" position="right" styleClass="keywest_sidebar p-sidebar-lg">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.models.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.models"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="form-group">
            <div class="row" *ngIf="user == 'system'">
              <div class="col-md-12">
                <label class="categoryType">
                  {{ trans.trans.choose_category_type.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.choose_category_type"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <select
                  *ngIf="solutionCategoryList && solutionCategoryList.length"
                  type="text"
                  (change)="getSolution(categoryType)"
                  class="form-control"
                  name="categoryType"
                  [(ngModel)]="categoryType"
                >
                  <option *ngFor="let solutionCategory of solutionCategoryList" [value]="solutionCategory.id">
                    {{ solutionCategory.category }}
                  </option>
                </select>
                <div class="text-center">
                  <img class="actionLoader" *ngIf="showCategoryTypeLoader" [src]="fullImagePath" />
                </div>
              </div>
            </div>
            <div *ngFor="let item of availableSolutions">
              <div class="row" *ngIf="user == 'system' && item.selectedCategory">
                <div class="col-md-12">
                  <div [ngClass]="{ 'checkbox': !hasRadioButtonFeature, 'custom-checkbox': !hasRadioButtonFeature, 'keywest_radio_list': hasRadioButtonFeature }">
                    <label>
                      <span *ngIf="item.quantity_enabled == '1'">
                        <span (click)="item.showeditable = !item.showeditable; item.tempQuantity = item.quantity" *ngIf="!item.showeditable">
                          <span *ngIf="item.quantity" class="quantityCheck">{{ item.quantity }}</span>
                        </span>

                        <div *ngIf="item.showeditable" class="input-group" style="max-width: 150px; float: left">
                          <input type="text" name="quantity" class="form-control" [(ngModel)]="item.quantity" />
                          <div class="input-group-btn">
                            <button (click)="quantityAssign(item.id, item.quantity); item.showeditable = !item.showeditable" class="btn btn-success">
                              <i class="fa fa-check"></i>
                            </button>
                            <button (click)="item.quantity = item.tempQuantity; item.showeditable = !item.showeditable" class="btn btn-danger">
                              <i class="fa fa-times"></i>
                            </button>
                          </div>
                        </div>

                        &nbsp;of
                      </span>

                      <input
                        *ngIf="hasRadioButtonFeature"
                        type="radio"
                        class="solutionCheckbox"
                        name="item.isChecked"
                        (change)="item.isChecked = !item.isChecked"
                        [checked]="item.isChecked"
                        (click)="checkModels(item.id, item.quantity, $event)"
                      />
                      <span [ngClass]="{ keywest_radiobutton: hasRadioButtonFeature }">
                        {{ item.name }}
                      </span>

                      <input
                        *ngIf="!hasRadioButtonFeature"
                        type="checkbox"
                        class="solutionCheckbox"
                        name="item.isChecked"
                        (change)="item.isChecked = !item.isChecked"
                        [checked]="item.isChecked"
                        (click)="checkModels(item.id, item.quantity, $event)"
                      />

                      <span class="check"></span>
                    </label>
                  </div>
                </div>

                <div class="col-xs-12" *ngIf="availableSolutions && selectedSolutionsLength == 0 && !showModelsLoader">
                  <p class="well">
                    {{ trans.trans.no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_above.value }}
                  </p>
                </div>
              </div>
            </div>
            <div [ngClass]="{ btn_footer: !crm }">
              <div class="form-group text-right" *ngIf="user == 'system'">
                <img class="actionLoader" *ngIf="showActionLoaderModel" [src]="fullImagePath" />
                <button *ngIf="!showActionLoaderModel" type="button" class="btn btn_customizable pull-right" (click)="createSolution()">{{ trans.trans.save.value }}</button>
                <app-are-you-sure
                  #solutionCancel
                  *ngIf="!showActionLoaderModel"
                  matTooltipPosition="above"
                  [message]="trans.trans.are_you_sure.value"
                  class="btn pull-right cancel-confirm"
                  icon=""
                  [text]="trans.trans.back.value"
                  (callbackYes)="closeSolution(solutionCancel)"
                ></app-are-you-sure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>

  <p-sidebar [(visible)]="showAccountEdit" *ngIf="company" position="right" styleClass="keywest_sidebar p-sidebar-lg">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.account_info.value }}
      </div>

      <div class="sidebar_container">
        <div class="keywest_panel">
          <p-fieldset [legend]="trans.trans.required.value">
            <div class="row">
              <div class="col-xs-12">
                <label>
                  {{ trans.trans.company_name.value }}
                  <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.account.company_name"></app-editTranslation>
                </label>
                <input type="text" [(ngModel)]="company.name" class="form-control" required />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.country.value }}</label>
                <p-dropdown
                  appendTo="body"
                  name="country"
                  [autoDisplayFirst]="false"
                  dataKey="id"
                  [options]="countries"
                  optionLabel="name"
                  (onChange)="getStatesByCountries()"
                  [(ngModel)]="countryDetails"
                  [filter]="true"
                  class="keywest_dropdown"
                ></p-dropdown>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.Revenue.value }}</label>
                <input type="text" [(ngModel)]="company.revenue" class="form-control" />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.employees.value }}</label>
                <input type="text" [(ngModel)]="company.employees" class="form-control" />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.industry.value }}</label>
                <p-dropdown
                  appendTo="body"
                  name="industryCategory"
                  [autoDisplayFirst]="false"
                  (onChange)="getIndustryBySectors()"
                  [options]="industrySectors"
                  dataKey="id"
                  optionLabel="name"
                  [(ngModel)]="industrySector"
                  [filter]="true"
                  class="keywest_dropdown"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="row" *ngIf="industrySector && industrySector.id">
              <div class="col-xs-12">
                <label>{{ trans.trans.industry_sector.value }}</label>

                <p-dropdown
                  appendTo="body"
                  name="industry"
                  [autoDisplayFirst]="false"
                  [options]="industries"
                  optionLabel="name"
                  [(ngModel)]="industryId"
                  [filter]="true"
                  class="keywest_dropdown"
                ></p-dropdown>
              </div>
            </div>
          </p-fieldset>
          <p-fieldset [legend]="trans.trans.optional.value" [toggleable]="true" [collapsed]="true">
            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.account_id.value }}</label>
                <input type="text" [(ngModel)]="company.account_defined_id" class="form-control" />
                <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.account.id"></app-editTranslation>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.addr1.value }}</label>
                <input type="text" [(ngModel)]="company.address1" class="form-control" />
                <app-editTranslation *ngIf="showTranslate" (callback)="refreshTranslation()" key="vp_dashboard.account.addr1"></app-editTranslation>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.addr2.value }}</label>
                <input type="text" [(ngModel)]="company.address2" class="form-control" />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.city.value }}</label>
                <input type="text" class="form-control" id="city" [(ngModel)]="company.city" name="city" placeholder="" />
              </div>
            </div>

            <div class="row" *ngIf="countryDetails && countryDetails.id">
              <div class="col-xs-12">
                <label>{{ trans.trans.state.value }}</label>
                <p-dropdown
                  appendTo="body"
                  name="state"
                  [autoDisplayFirst]="false"
                  [options]="states"
                  optionLabel="name"
                  [(ngModel)]="stateId"
                  [filter]="true"
                  class="keywest_dropdown"
                ></p-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.phone.value }}</label>
                <input type="text" [(ngModel)]="company.phone" class="form-control" />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.website.value }}</label>
                <input type="text" [(ngModel)]="company.url" class="form-control" />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.ticker.value }}</label>
                <input type="text" [(ngModel)]="company.ticker" class="form-control" />
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <label>{{ trans.trans.GrossProfitMargin.value }}</label>
                <input type="text" [(ngModel)]="company.gross_profit_fmt" class="form-control" />
              </div>
            </div>
          </p-fieldset>
          <div [ngClass]="{ row: crm, btn_footer: !crm }">
            <div class="col-xs-12">
              <a (click)="funcUpdateCompany()" class="btn btn_customizable pull-right">{{ trans.trans.save.value }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>
</div>
