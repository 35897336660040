import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2RCapabilitiesTranslations {
  public config: any = {
    component: 'r_capabilities',
    display_name: 'Capabilities Read Only',
    description: 'Capabilities Read Only '
  };

  public trans: any = {
    name: 'Name',
    description: 'Description',
    view_capability: 'View Capability',
  };
}
