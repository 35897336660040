<!-- Page Wrapper -->
<div class="validation-page-wrapper">
  <!-- Button Selection -->
  <div class="row select-button-wrapper">
    <p-selectButton [options]="viewOptions" [(ngModel)]="currentView" optionLabel="label" optionValue="value" styleClass="keywest_select_button"> </p-selectButton>
  </div>
  <!-- Benefit Validations -->
  <app-benefits [trans]="trans" [validations]="benefitValidations" *ngIf="currentView === 'benefits'"></app-benefits>
  <!-- Factor Validations -->
  <app-factors (onUpdate)="update()" [trans]="trans" [validations]="factorValidations" [solutionID]="solutionID" *ngIf="currentView === 'factors'"></app-factors>
</div>
