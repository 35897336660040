import { Injectable } from '@angular/core';

@Injectable ()

export class TeamDetailTranslation {

    public config: any = {
        component: 'teamDetail',
        display_name: 'Team Detail',
        description: 'Team list in profile section'
    };

    public trans: any = {
        addTeamMember: 'Add Team Member',
        deleteMember: 'Delete Member?',
        deleteTeamMemberConfirm: 'Are you sure that you want to remove this team member?',
        heading: 'Your Team',
        selectPrivileges: 'Select Privileges',
        selectUser: 'Select User',
        add: 'Add',
        name: 'Name',
        delete: 'Delete',
        areYouSure: 'Are you sure?',
    };
}
