import { NgModule } from '@angular/core';

import { RCostComponent } from "./r_cost.component";

import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RCostTranslations } from './r_cost.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		RCostComponent,
	],
	imports: [
		InputSwitchModule,
		SidebarModule,
		CommonModule,
		TabViewModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		DropdownModule,
		EditTranslationsModule
	],
	providers: [
		TranslationsV2Service,
		RCostTranslations,
	],
	entryComponents: [
		RCostComponent,
	],
	exports: [RCostComponent]
})
export class RCostsModule { }