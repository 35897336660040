<div class="row" *ngIf="style2022$ | async">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="row" *ngIf="!(style2022$ | async)">
  <div class="col-md-10 old-breadcrumb">
    <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToProfile.value }}</a>
  </div>
</div>

<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div *ngIf="loading">
  <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
</div>

<div *ngIf="!loading" class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-7">
          <div class="title">
            {{ trans.trans.manageAssetTemplates.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.manageAssetTemplates"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-5">
          <button class="btn btn_customizable pull-right" (click)="funcToggleAdd()"><i class="fa fa-plus"></i> {{ trans.trans.addTemplate.value }}</button>
          <button *ngIf="assetDefaultThemesFeatureFlag" style="margin-right: 5px;" class="btn btn_customizable pull-right" (click)="toogleAddTheme = !toogleAddTheme"><i class="fa fa-plus"></i> {{ trans.trans.addTheme.value }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="row" style="margin-bottom: 20px">
          <div class="col-xs-4">
            <div class="input-group keywest_search">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input [(ngModel)]="strSearch" (keyup)="tableAssets.filter($any($event).target.value, 'name', 'contains')" type="text" placeholder="Search" class="form-control" />
              <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableAssets.filter('', 'name', 'contains')" class="input-group-addon clickable">
                <i class="fa fa-times"></i>
              </span>
            </div>
          </div>
          <div class="col-xs-8">
            <span class="clickable pull-right" (click)="row.toggle($event)">Row Limit {{ pageLimit }} <i class="fa fa-chevron-down"></i></span>
          </div>
        </div>

        <p-table #tableAssets [value]="assets" [columns]="cols" [paginator]="true" [rows]="pageLimit" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans[col.field]"
                  (callback)="getTranslations()"
                ></app-editTranslation>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td>
                <a (click)="funcToggleEdit(rowData)" class="clickable">{{ rowData.name }}</a>
                <i *ngIf="rowData.description && !(style2022$ | async)" [matTooltip]="rowData.description" class="fa fa-info-circle"></i>
                <span *ngIf="rowData.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.description"> info </span>
              </td>
              <td>{{ rowData.format }}</td>
              <td>
                <app-ellipsis-menu>
                  <ul class="list-group">
                    <li class="list-group-item" (click)="funcToggleEdit(rowData)">
                      {{ trans.trans.edit.value }}
                    </li>
                    <li class="list-group-item">
                      <app-are-you-sure (callbackYes)="deleteAsset(rowData)" [text]="trans.trans.delete.value" icon="" [message]="trans.trans.areYouSure.value"></app-are-you-sure>
                    </li>
                    <li class="list-group-item" (click)="viewLog(rowData)">{{ trans.trans.viewLog.value }}</li>
                  </ul>
                </app-ellipsis-menu>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<p-sidebar (onHide)="hideSidebar()" *ngIf="toggleAddAsset" [(visible)]="toggleAddAsset" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-ae-assets
    *ngIf="toggleAddAsset"
    (warningNoSave)="showNoSaveWarning = $event"
    (callback)="getData()"
    [mode]="editPage"
    [selectedElem]="selectedElem"
    [solutions]="solutions"
  ></app-ae-assets>
</p-sidebar>

<p-sidebar (onHide)="hideSidebar()" *ngIf="toogleAddTheme" [(visible)]="toogleAddTheme" styleClass="keywest_sidebar p-sidebar-lg" position="right">
<app-asset-theme (onStandardAssetsGenerated)="onStandardAssetsGenerated()"></app-asset-theme>
</p-sidebar>

