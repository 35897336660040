import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TrackerBusinessReviewService } from '../tracker-business-review.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { BusinessReviewData, BusinessReviewDataWithNote } from '@data/services/valuerealization/models/business-review.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { TrackerNote } from '@data/services/valuerealization/models/value-realized-company.model';
import { ReviewBaselineTranslation } from '../review-baseline/review-baseline.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { Table } from 'primeng/table';
export type TranslationStrings = keyof typeof ReviewBaselineTranslation.prototype.trans;

@Component({
  selector: 'app-business-review-notes-table',
  templateUrl: './business-review-notes-table.component.html',
  styleUrls: ['./business-review-notes-table.component.scss'],
})
export class BusinessReviewNotesTableComponent implements OnInit, OnDestroy {
  @Input() valuePropId: string;
  @Input() objectId: string | number;
  @Input() section: string;
  @Input() disableEdit = false;

  @Input() set newNote(note: TrackerNote) {
    if (note) {
      const foundNoteIndex = this.businessReviewsWithNotes.findIndex((n) => n.noteId.toString() === note.id.toString());
      if (foundNoteIndex > -1) {
        this.businessReviewsWithNotes[foundNoteIndex].note = note.note;
        this.businessReviewService.brsWithNotes$.next(this.businessReviewsWithNotes);
      } else {
        this.notes.push(note);
        this.addNoteToTable(note);
      }
    }
  }
  notes: TrackerNote[] = [];
  businessReviews: BusinessReviewData[];
  businessReviewsWithNotes: BusinessReviewDataWithNote[] = [];
  isLoading = true;
  canEdit = false;
  private unsubscribe$ = new Subject<void>();

  @Output() editNote = new EventEmitter<BusinessReviewDataWithNote>();

  @ViewChild(Table) table: Table;

  constructor(
    public trans: ReviewBaselineTranslation,
    private translationService: TranslationsV2Service,
    public businessReviewService: TrackerBusinessReviewService,
    private valueRealizationService: ValuerealizationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.businessReviewService.brsWithNotes$.pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response) {
        this.businessReviewsWithNotes = response;
        this.getBrs();
        this.isLoading = false;
      } else {
        this.getBrsWithNotes();
      }
    });
    this.businessReviewService.canEdit.pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      this.canEdit = response;
    });
  }

  getBrs() {
    this.businessReviewService.businessReviews$.pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response) {
        this.businessReviews = response;
      }
    });
  }

  getBrsWithNotes() {
    this.businessReviewService.businessReviews$.pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response) {
        this.businessReviews = response;

        // Create an array of Observables
        const observables = this.businessReviews.map((review) => this.getNotes(review.id));

        // Use forkJoin to wait for all Observables to complete
        forkJoin(observables)
          .pipe(
            finalize(() => (this.isLoading = false)),
            take(1)
          )
          .subscribe(() => {
            this.notes.forEach((note) => {
              this.addNoteToTable(note);
            });
            this.businessReviewService.brsWithNotes$.next(this.businessReviewsWithNotes);
          });
      }
    });
  }

  addNoteToTable(note: TrackerNote) {
    const br = this.businessReviews.find((br) => br.id === note.business_review_id);
    const brCopy: BusinessReviewDataWithNote = { ...br };
    brCopy.note = note.note;
    brCopy.noteId = note.id.toString();
    this.businessReviewsWithNotes.push(brCopy);
    this.businessReviewsWithNotes.sort((a, b) => {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      return dateA.getTime() - dateB.getTime();
    });
    this.businessReviewService.brsWithNotes$.next(this.businessReviewsWithNotes);
    if (this.table) {
      this.table.reset();
    }
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
      });
  }

  getNotes(brId: number): Observable<TrackerNote[]> {
    return this.valueRealizationService.getTrackerNotes(this.valuePropId, brId.toString(), this.objectId.toString(), this.section).pipe(
      take(1),
      tap((response) => {
        if (response && response.length > 0) {
          response.forEach((note: TrackerNote) => {
            this.notes.push(note);
          });
        }
      })
    );
  }

  deleteNote(id: string) {
    this.valueRealizationService.deleteTrackerNote(this.valuePropId, id).subscribe(
      (response) => {
        if (response && response.result === 'The note was deleted successfully') {
          this.businessReviewsWithNotes = this.businessReviewsWithNotes.filter((br) => br.noteId !== id);
          this.notify('noteDeleted');
          this.businessReviewService.brsWithNotes$.next(this.businessReviewsWithNotes);
          if (this.table) {
            this.table.reset();
          }
        }
      },
      () => {
        this.notify('noteNotDeleted', 'error');
      }
    );
  }

  private notify(transString: TranslationStrings, type: 'error' | 'success' | 'warning' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }

  setEditNote(note: BusinessReviewDataWithNote) {
    this.editNote.emit(note);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
