<div class="chartcontainer" *ngIf="chartData">
  <div class="row">
    <div class="col-md-4" style="padding-left: 50px; padding-right: 40px">
      <p-chart type="doughnut" height="300px" [options]="options" [data]="chart"></p-chart>
      <div class="absolute-center text-center" [ngStyle]="{ color: selectedCLR }">
        <span class="chart_val" style="padding-bottom: 12px">{{ selectedVal | numberTruncate }}</span
        ><br /><br />
        <span class="chart_text">{{ selectedName }}</span>
      </div>
    </div>
    <div class="col-md-8" style="padding-top: 40px; overflow-y: auto; max-height: 250px">
      <div class="row" style="margin-left: 0px; margin-right: 15px; margin-bottom: 10px">
        <div class="col-xs-6" *ngFor="let elem of legend">
          <div class="row flex-align">
            <div class="col-xs-1" style="padding-left: 0px">
              <p class="chart-icon clickable" (click)="clickBenefitClick(elem)" [ngStyle]="{ 'border-color': elem.color }"></p>
            </div>
            <div class="col-xs-1" style="padding-right: 0px">
              <div class="val clickable" (click)="clickBenefitClick(elem)">
                {{ elem.total }}
              </div>
            </div>
            <div class="col-xs-10" style="padding-right: 0px">
              <div (click)="clickBenefitClick(elem)" class="clickable label" style="width: 100%; display: block; text-align: left; white-space: pre-line">
                {{ elem.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
