import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { CommonService } from '@data/services/common/common.service';
import { CompanyService } from '@data/services/company/company.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-casestudy',
  templateUrl: './add-casestudy.component.html',
  styleUrls: ['./add-casestudy.component.scss'],
  providers: [CompanyService, AccountService],
})
export class AddCasestudyComponent implements OnDestroy {
  @Input() solutionId: number | string;
  @Input() contextualHelp: { [klass: string]: any };

  @Output() createCaseStudy = new EventEmitter();
  companyname: string;
  showActionLoader = false;
  companyList = true;
  companiesNoResult = false;
  detailedDescription = true;
  companyDetails: { [klass: string]: any } = {};
  companiesList: { [klass: string]: any }[] = [];
  companyId: string | number;
  showCompanyDetails = false;
  isRedact = false;
  tags: any;
  openAddCompany: boolean;
  fullImagePath: string;
  enableSaveBtn: boolean;
  payload: { [klass: string]: any };

  pageNumberSearch = 1;

  pageLimitSearch = 100;
  order = 'asc';
  sortBy = 'name';

  subscriptiongetSearchCompanies: Subscription;
  subscriptiongetCompanySummary: Subscription;
  subscriptionsaveCaseStudy: Subscription;
  columns: [
    { header: 'Name'; field: 'name' },
    { header: 'Net Sales'; field: 'net_sales' },
    { header: 'Employees'; field: 'employees' },
    { header: 'Ticker'; field: 'ticker' },
    { header: 'City'; field: 'city' },
    { header: 'Country'; field: 'country' }
  ];

  constructor(
    private commonService: CommonService,
    private accountService: AccountService,
    private companyService: CompanyService,
    public activeModal: NgbActiveModal,
    private solutionService: SolutionService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    const imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnDestroy() {
    if (this.subscriptiongetSearchCompanies) {
      this.subscriptiongetSearchCompanies.unsubscribe();
    }
    if (this.subscriptiongetCompanySummary) {
      this.subscriptiongetCompanySummary.unsubscribe();
    }
    if (this.subscriptionsaveCaseStudy) {
      this.subscriptionsaveCaseStudy.unsubscribe();
    }
  }

  getRecentSearchComp() {
    if (this.companyname && this.companyname !== null && this.companyname !== '') {
      this.companyList = true;
      this.companiesNoResult = false;
      this.showActionLoader = true;
      this.payload = {
        searchTerm: this.companyname,
        publicOnly: 0,
        synopsisOnly: 1,
      };
      this.payload['limit'] = this.pageLimitSearch;
      this.payload['page'] = this.pageNumberSearch;
      this.payload['sortBy'] = this.sortBy;
      this.payload['order'] = this.order;
      this.subscriptiongetSearchCompanies = this.accountService.getSearchCompanies(this.payload).subscribe(
        (response) => {
          if (response.result && response.result.data && response.result.data.length > 0) {
            this.companiesList = response.result.data;
            this.companyList = false;
            this.detailedDescription = true;
          } else {
            this.companiesNoResult = true;
            this.detailedDescription = true;
          }
          this.showActionLoader = false;
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );
    } else {
      this.companiesList = [];
      this.companiesNoResult = true;
      this.companyList = true;
      this.detailedDescription = true;
      this.companyId = '';
    }
  }

  selectCompany(params: string | number) {
    this.companyList = true;
    this.companyId = params;
    this.getCompanyDetail();
  }

  getCompanyDetail() {
    this.showActionLoader = true;
    this.subscriptiongetCompanySummary = this.companyService.getCompanySummary(Number(this.companyId)).subscribe((result) => {
      if (result.result) {
        this.companyDetails = result.result.company;
      }
      this.showCompanyDetails = true;
      this.showActionLoader = false;
      this.detailedDescription = false;
    });
  }

  saveCaseStudy() {
    const payload = {
      company_id: this.companyId,
      account_solution_id: this.solutionId,
      redacted: this.isRedact ? 1 : 0,
      tag: this.tags ? this.tags.trim() : null,
    };

    this.subscriptionsaveCaseStudy = this.solutionService.saveCaseStudy(payload).subscribe((response) => {
      if (response.result) {
        this.activeModal.close();
        this.solutionService.readCaseStudy.next('loadCaseStudies');
        this.solutionService.triggerTabs.next('caseStudy');
        this.notificationService.success('Case Study created successfully', false);
        this.createCaseStudy.emit();
      }
    });
  }
  addCompanyDisplay() {
    this.openAddCompany = true;
  }
  companyCreated(event: { [klass: string]: any }) {
    this.openAddCompany = false;
    this.companyname = event.name;
    this.getRecentSearchComp();
  }
  enableSave(event: boolean) {
    this.enableSaveBtn = event;
  }
}
