import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'valuePosition',
    display_name: 'Value Position',
    description: 'Value Position main'
  };

  public trans: any = {
    your_value_position: 'Your Value Position',
    valuePosition: 'Your Value Hypothesis',
    add: 'Add',
    ch_add: 'Add Value Position',
    search: 'Search',
    row_limit: 'Row Limit',
    name: 'Name',
    company_name: 'Company',
    total_benefits: 'Total Benefits',
    total_costs: 'Total Costs',
    total_benefits_fmt: 'Benefits',
    total_costs_fmt: 'Costs',
    vp_creator_name: 'Creator',
    created: 'Created On',
    status: 'Status',
    all: 'All',
    mine: 'Mine',
    shared_with_me: 'Shared with me',
    value_position: 'Value Position',
    vp_name: 'Value Prop Name',
    models: 'Model(s)',
    year1: 'Year 1 Benefits',
    roi: 'ROI',
    payback: 'Payback Period',
    months: 'months',
    share: 'Share',
    delete: 'Delete',
    clone: 'Clone',
    areYouSure: 'Are you sure?',
    dashboard: 'Dashboard',
    hypotheses: 'Hypotheses',
    addNew: 'Add New',
    sharedWithMe: 'Shared with me',
    unsort: 'Reset Sort',
    creator: 'Creator',
    costs_total: 'Costs',
    benefits_total: 'Benefits',
    created_on: 'Created On',
    noResults: 'No results for ',
    noRecords: 'Click Add New to Create a Business Case',
    addNewHypo: 'Add New Hypotheses',
    expressValueInsights: 'Use Express Value Insights Process',
    expressValueNote: 'build a new hypotheses with precise information',
    standardProcess: 'Use Classic Process',
    standardProcessNote: 'build a new hypotheses with detailed information',
    chooseWorkFlow: 'Choose Conversation Workflow to Use',
    expressDetail: 'Workflow to support early stage conversation prep, define your selling scenario and view your results',
    classicDetail: 'Traditional 5 Step workflow for building, revising and collaborating on your value proposition',
    showUnfinished: 'Show unfinished web calculator hypotheses?'
  };
}
