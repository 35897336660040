import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { WorkflowQuestionAnswers } from '@data/services/workflow/models/workflow-question.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuestionsAnswersGuidedDiscoveryTranslations } from './questions-answers-guided-discovery.translation';
import { cloneDeep } from 'lodash';
import { GuidedDiscoveryElementTypes } from '@data/services/workflow/models/workflow.interface';

@Component({
  selector: 'app-questions-answers-guided-discovery',
  templateUrl: './questions-answers-guided-discovery.component.html',
  styleUrls: ['./questions-answers-guided-discovery.component.scss']
})
export class QuestionsAnswersGuidedDiscoveryComponent implements OnInit, OnChanges {

  @Input() questions: WorkflowQuestionAnswers[];
  @Output() saveAnswers = new EventEmitter<WorkflowQuestionAnswers[]>();
  @Output() back = new EventEmitter();
  showTranslate: boolean;
  ngUnsubscribe$ = new Subject();
  elementTypes = GuidedDiscoveryElementTypes;

  constructor(
    private commonService: CommonService,
    public trans: QuestionsAnswersGuidedDiscoveryTranslations,
    private translationService: TranslationsV2Service,
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.questions && changes.questions.currentValue) {
      this.setAnswers();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  setAnswers(): void {
    for(let i = 0; i < this.questions.length; i++) {
      const question = this.questions[i];
      let selectedMembers = [];

      if(+question.element_type_id === this.elementTypes.singleSelect){
        selectedMembers = question.members.filter(member => member.selected);
        if(selectedMembers.length > 0) {
          question.singleSelectAnswer = selectedMembers[0].id;
        }
      }
      else if(+question.element_type_id === this.elementTypes.multiSelect){
        selectedMembers = question.members.filter(member => member.selected);
        if(selectedMembers.length > 0) {
          question.multiSelectAnswer = selectedMembers.map(member => member.id);
        }
      }
      else if(+question.element_type_id === this.elementTypes.dropdownSingle){
        selectedMembers = question.members.filter(member => member.selected);
        if(selectedMembers.length > 0) {
          question.dropdownSingleAnswer = selectedMembers[0];
        }
      }
    }
  }

  onSaveAnswers(): void {
    const payload = cloneDeep(this.questions);

    for(let i = 0; i < this.questions.length; i++) {
      const question = payload[i];

      if(+question.element_type_id === this.elementTypes.singleSelect){
        question.members = question.members.filter(member => question.singleSelectAnswer == member.id);
      }
      else if(+question.element_type_id === this.elementTypes.multiSelect){
        question.members = question.members.filter(member => question.multiSelectAnswer.includes(member.id));
      }
      else if(+question.element_type_id === this.elementTypes.dropdownSingle){
        if(!question.dropdownSingleAnswer) {
          question.members = [];
        }
        else{
          question.members = question.members.filter(member => question.dropdownSingleAnswer.id == member.id);
        }
      }
    }

    this.saveAnswers.emit(payload);
  }

  goBack(): void {
    this.back.emit();
  }

  get canSave(): boolean {
    return this.questions.some(q => q.singleSelectAnswer || (q.multiSelectAnswer && q.multiSelectAnswer.length > 0) || q.dropdownSingleAnswer);
  }
}