import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader } from '@ngx-translate/core';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { ValuePropFlowButtonsModule } from 'app/repv2/components/value-prop-flow-buttons/value-prop-flow-buttons.module';
import { AddCostModalModule } from 'app/value-prop/common/add-cost-modal/add-cost-modal.module';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CostsSimpleComponent } from './costs.simple.component';
import { DefaultTranslations } from './costs.simple.translation';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    MatTooltipModule,
    MenuModule,
    AddCostModalModule,
    ApplicationPipesModule,
    SidebarModule,
    CommonModule,
    EditTranslationsModule,
    NgbModule,
    FormsModule,
    AreYouSureModule,
    ValuePropFlowButtonsModule,
    EllipsisMenuModule,
  ],
  declarations: [CostsSimpleComponent],
  providers: [DefaultTranslations, TranslationsV2Service],
  exports: [CostsSimpleComponent],
})
export class RSV2CostsSimpleModule {}
