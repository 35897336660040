import { Pipe, PipeTransform } from '@angular/core';
import { ValueProp } from '@shared/models/value-prop.model';
import { RepSimpleTranslations } from 'app/repv2/simple.translation';

@Pipe({
  name: 'vpTooltip',
})
export class VpTooltipPipe implements PipeTransform {
  transform(valueProp: ValueProp, trans: RepSimpleTranslations): string {
    if (!valueProp || !valueProp.name) {
      return '';
    }
    return `${valueProp.name}\n ${trans.trans.vpCreatedBy.value} ${valueProp.creators_name} \n ${valueProp.last_modified_by_name && trans.trans.modifiedBy.value} ${
      valueProp.last_modified_by_name && valueProp.last_modified_by_name
    }`;
  }
}
