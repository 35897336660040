<div class="models_panel" style="height: 100%; overflow: hidden">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-12">
          <div class="title">
            {{ trans.trans.share.value }}
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_share.value"> info </span>
            <app-editTranslation
              *ngIf="showTranslate"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.share"
              (callback)="getTranslations()"
            ></app-editTranslation>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div [ngClass]="editing || report ? 'col-xs-6' : 'col-xs-12'" *ngIf="view === 'share'">
        <div class="table-responsive soln-table table-scroll" style="display: flex; flex-direction: column">
          <div class="searchTableContainer">
            <div class="panel panel-white" [ngStyle]="{ 'box-shadow': '0 2px 8px -2px ' + header_color }">
              <div class="panel-heading" style="height: inherit !important" [ngStyle]="{ 'color': '#fff', 'background-color': header_color, 'border-color': header_color }">
                <div class="panel-title">
                  {{ trans.trans.find.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.find"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </div>
              </div>
              <div class="panel-body">
                <p class="red-alert-text" *ngIf="featureDisableExternalShare && !manualExternalShareDeactivate">{{ trans.trans.externalDisabled.value }}</p>
                <p *ngIf="!manualExternalShareDeactivate; else shortDescription">
                  {{ trans.trans.desc.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.desc"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </p>
                <ng-template #shortDescription>
                  <p>{{ trans.trans.descShort.value }}</p>
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.descShort"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </ng-template>
                <div class="input-group" style="width: 100%">
                  <dl-progressbar *ngIf="loadingUsers" class="dl_progress" [showValue]="true" [value]="'Loading Users. Please Wait'" mode="indeterminate"></dl-progressbar>
                  <p-autoComplete
                    *ngIf="!loadingUsers"
                    [multiple]="false"
                    [placeholder]="trans.trans.search_for_users_by_name.value"
                    field="name"
                    [(ngModel)]="email"
                    [suggestions]="filteredUserList"
                    (completeMethod)="funcSearch($event)"
                    [ngStyle]="{ width: '100%' }"
                  ></p-autoComplete>
                  <div class="input-group-btn" *ngIf="!addingInternalUser">
                    <a *ngIf="filteredUserList && filteredUserList.length" (click)="funcAddExistingUser()" class="btn btn-success">{{ trans.trans.add.value }}</a>
                  </div>
                  <div class="input-group-btn" *ngIf="addingInternalUser">
                    <span class="btn btn-info" style="padding: 6px"><i class="fa fa-spinner fa-spin fa-3x fa-fw" style="font-size: 16px"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <table id="shares" class="table custom-table" style="width: 100%; max-height: 400px; overflow-y: scroll; margin-bottom: 140px">
              <thead>
                <tr>
                  <th>
                    {{ trans.trans.who.value }} ( {{ shares.length }} )
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.who"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </th>

                  <th>
                    <input
                      type="text"
                      [(ngModel)]="search"
                      placeholder="{{ trans.trans.search.value }}"
                      class="pull-right form-control"
                      style="max-width: 200px; font-weight: normal !important"
                    />
                  </th>
                </tr>
              </thead>

              <tbody *ngIf="shares && shares !== null && shares.length > 0">
                <tr *ngFor="let share of shares | filter: search:'user_name'" class="grid-row">
                  <td>
                    <i [matTooltip]="trans.trans.internalUser.value" matTooltipPosition="above" *ngIf="!share.is_external" class="fa fa-users" aria-hidden="true"></i>
                    <i [matTooltip]="trans.trans.externalUser.value" matTooltipPosition="above" *ngIf="+share.is_external === 1" class="fa fa-address-card" aria-hidden="true"></i>

                    {{ share.user_name }}<br />
                    <a href="mailto:{{ share.email }}" style="font-size: 70%">{{ share.email }}</a
                    ><br />
                    <span style="color: green; font-size: 70%">{{ +share.share_role_type_id === 5 ? 'Creator' : '' }}</span>
                  </td>

                  <td>
                    <div class="pull-right" *ngIf="+share.share_role_type_id !== 5">
                      <a (click)="funcShowReport(share)" class="btn btn-info btn-padding-sm"><i class="fa fa-bar-chart"></i></a>
                      <a (click)="toggleEditShare(share)" class="btn btn-success btn-padding-sm"><i role="button" class="fa fa-pencil"></i></a>
                      <a *ngIf="!share.deleteUser" id="startOver1" (click)="share.deleteUser = !share.deleteUser" class="startOver btn btn-danger btn-padding-sm"
                        ><i class="fa fa-times"></i
                      ></a>
                      <a *ngIf="share.deleteUser" id="startOver2" (click)="deleteValuePropUser(share)" class="startOver btn btn-warning btn-sm btn-padding-sm"
                        ><i class="fa fa-times"></i> {{ trans.trans.are_you_sure.value }}</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="(shares === null || shares.length === 0) && !sharesLoader" class="m-t-md col-sm-12">
          <div class="searchTableContainer text-center">
            <h4>
              {{ trans.trans.no_shares_found.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.no_shares_found"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </h4>
          </div>
        </div>
      </div>

      <div *ngIf="report" class="col-xs-6">
        <h3>
          {{ trans.trans.activity_log_for.value }} {{ user.user_name }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.activity_log_for"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </h3>
        <table class="table custom-table">
          <tbody *ngIf="!reportList">
            <tr>
              <td>
                {{ trans.trans.no_activity_found.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.no_activity_found"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr class="grid-row" *ngFor="let item of reportList">
              <td>
                <b>{{ item.user_name }}</b
                ><br />
                {{ item.log_text }}
              </td>
              <td>{{ item.modified }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="(editing || !foundUser) && !user.is_external && !customerCollaboration" class="col-xs-6">
        <h3 *ngIf="!foundUser">
          {{ trans.trans.create_a_new_share_for.value }} {{ user.user_name }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.create_a_new_share_for"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </h3>
        <h3 *ngIf="user.user_name && foundUser" style="margin: 0px; margin-top: 19px">{{ user.user_name }} <span *ngIf="+user.uid === +user_id">(You)</span></h3>
        <a *ngIf="user.user_name && foundUser" href="mailto:{{ user.email }}" style="font-size: 70%">{{ user.email }}</a>

        <a *ngIf="!user.user_name" href="mailto:{{ user.email }}">{{ user.email }}</a>

        <div class="table-responsive soln-table">
          <div class="searchTableContainer">
            <table class="table custom-table">
              <thead></thead>
              <tbody>
                <tr class="grid-row">
                  <td colspan="3">
                    <select [(ngModel)]="user.share_role_type_id" class="form-control" name="shareRoleType" required>
                      <option selected value="">{{ trans.trans.choose_one.value }}</option>
                      <option *ngFor="let shareRole of shareRoles" [value]="shareRole.id">
                        {{ shareRole.name }}
                      </option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td colspan="3">
                    <button pButton iconPos="right" [loading]="savingShare" (click)="funcSaveShareSettings(false, true)" class="btn btn_customizable pull-right">
                      {{ trans.trans.save.value }}
                    </button>
                    <button [disabled]="savingShare" (click)="onCancel()" class="btn cancel-confirm pull-right marg-h">{{ trans.trans.cancel.value }}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div *ngIf="(editing || !foundUser) && user.is_external === 1 && !customerCollaboration" class="col-xs-6">
        <h3 *ngIf="!foundUser">
          {{ trans.trans.create_a_new_share_for.value }} {{ user.user_name }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.create_a_new_share_for"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </h3>
        <h3 *ngIf="user.user_name && foundUser" style="margin: 0px; margin-top: 19px">{{ user.user_name }} <span *ngIf="+user.uid === +user_id">(You)</span></h3>
        <a *ngIf="user.user_name && foundUser" href="mailto:{{ user.email }}" style="font-size: 70%">{{ user.email }}</a>
        <a *ngIf="!user.user_name" href="mailto:{{ user.email }}">{{ user.email }}</a>

        <div class="table-responsive soln-table">
          <div class="searchTableContainer" [formGroup]="nameEmailForm">
            <table class="table custom-table">
              <thead></thead>
              <tbody>
                <tr class="grid-row">
                  <td colspan="3">
                    <input type="text" pInputText formControlName="name" placeholder="{{ trans.trans.name.value }}" class="form-control" autocomplete="off" />
                  </td>
                </tr>

                <tr class="grid-row">
                  <td colspan="3">
                    <app-error-input-wrapper [errorMessage]="errorDict.get('email')" [showOnTouched]="nameEmailForm.controls.email.touched">
                      <input type="text" pInputText formControlName="email" placeholder="{{ trans.trans.email.value }}" class="form-control" autocomplete="off" />
                    </app-error-input-wrapper>
                  </td>
                </tr>

                <tr class="grid-row">
                  <td style="vertical-align: inherit">
                    <label for="expires" class="labelsRequired">{{ trans.trans.expires.value }}</label>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.expires"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </td>
                  <td colspan="2" class="text-right">
                    <app-error-input-wrapper [errorMessage]="errorDict.get('expires')" [showOnTouched]="nameEmailForm.controls.expires.touched">
                      <p-calendar appendTo="body" formControlName="expires" [showIcon]="true" icon="fa fa-calendar" id="expires"></p-calendar>
                    </app-error-input-wrapper>
                  </td>
                </tr>

                <tr>
                  <th style="width: 33%">
                    {{ trans.trans.feature.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.feature"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </th>
                  <th style="width: 33%; text-align: right">
                    {{ trans.trans.visible.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.visible"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </th>
                  <th style="width: 33%; text-align: right">
                    {{ trans.trans.editable.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.editable"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </th>
                </tr>

                <tr class="grid-row">
                  <td>
                    {{ trans.trans.account_info.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.account_info"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </td>
                  <td class="text-right">
                    <a (click)="menuAccountView.toggle($event)" class="link clickable">{{ user.account.view }}</a>
                    <p-menu #menuAccountView appendTo="body" [popup]="true" [model]="arrAccountView" [styleClass]="'style-2022'"></p-menu>
                  </td>
                  <td class="text-right">
                    <a (click)="menuAccountEditable.toggle($event)" class="link clickable">{{ user.account.editable }}</a>
                    <p-menu #menuAccountEditable appendTo="body" [popup]="true" [model]="arrAccountEditable" [styleClass]="'style-2022'"></p-menu>
                  </td>
                </tr>

                <tr class="grid-row">
                  <td>
                    Benefits
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.benefits"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </td>
                  <td class="text-right">
                    <a (click)="menuBenefitsView.toggle($event)" class="link clickable">{{ user.benefits.view }}</a>
                    <p-menu #menuBenefitsView appendTo="body" [popup]="true" [model]="arrBenefitsView" [styleClass]="'style-2022'"></p-menu>
                  </td>
                  <td class="text-right">
                    <a (click)="menuBenefitsEditable.toggle($event)" class="link clickable">{{ user.benefits.editable }}</a>
                    <p-menu #menuBenefitsEditable appendTo="body" [popup]="true" [model]="arrBenefitsEditable" [styleClass]="'style-2022'"></p-menu>
                  </td>
                </tr>
                <tr class="grid-row">
                  <td>
                    {{ trans.trans.assumptions.value }}

                    <span
                      class="material-icons-outlined tooltip-custom info-icon"
                      [matTooltip]="trans.trans.ch_assumtpions.value"
                      matTooltipPosition="below"
                      container="body"
                      placement="top"
                      style="color: red"
                    >
                      info
                    </span>
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.assumptions"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </td>
                  <td class="text-right">
                    <a (click)="menuAssumptionsView.toggle($event)" class="link clickable">{{ user.assumptions.view }}</a>
                    <p-menu #menuAssumptionsView appendTo="body" [popup]="true" [model]="arrAssumptionsView" [styleClass]="'style-2022'"></p-menu>
                  </td>
                  <td class="text-right">
                    <a (click)="menuAssumptionsEditable.toggle($event)" class="link clickable">{{ user.assumptions.editable }}</a>
                    <p-menu #menuAssumptionsEditable appendTo="body" [popup]="true" [model]="arrAssumptionsEditable" [styleClass]="'style-2022'"></p-menu>
                  </td>
                </tr>
                <tr class="grid-row">
                  <td>
                    {{ trans.trans.costs.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.costs"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </td>
                  <td class="text-right">
                    <a (click)="menuCostsView.toggle($event)" class="link clickable">{{ user.costs.view }}</a>
                    <p-menu #menuCostsView appendTo="body" [popup]="true" [model]="arrCostsView" [styleClass]="'style-2022'"></p-menu>
                  </td>
                  <td class="text-right">
                    <a (click)="menuCostsEditable.toggle($event)" class="link clickable">{{ user.costs.editable }}</a>
                    <p-menu #menuCostsEditable appendTo="body" [popup]="true" [model]="arrCostsEditable" [styleClass]="'style-2022'"></p-menu>
                  </td>
                </tr>

                <tr class="grid-row">
                  <td>
                    {{ trans.trans.results.value }}
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.results"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </td>
                  <td class="text-right">
                    <a (click)="menuResultView.toggle($event)" class="link clickable">{{ user.results.view }}</a>
                    <p-menu #menuResultView appendTo="body" [popup]="true" [model]="arrResultView" [styleClass]="'style-2022'"></p-menu>
                  </td>
                  <td class="text-right">
                    <a (click)="menuResultEditable.toggle($event)" class="link clickable">{{ user.results.editable }}</a>
                    <p-menu #menuResultEditable appendTo="body" [popup]="true" [model]="arrResultsEditable" [styleClass]="'style-2022'"></p-menu>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <!-- Put feature flag here to show or hide email me button -->
                    <button *ngIf="emailMeFF" pButton iconPos="right" [loading]="savingShare" (click)="funcSaveShareSettings(true)" id="email-me" class="btn btn_customizable pull-right ml">
                      {{ trans.trans.emailMe.value }}
                    </button>
                    <button pButton iconPos="right" [loading]="savingShare" (click)="funcSaveShareSettings()" class="btn btn_customizable pull-right">
                      {{ trans.trans.emailCustomer.value }}
                    </button>
                    <button [disabled]="savingShare" (click)="onCancel()" class="marg-h btn cancel-confirm pull-right">{{ trans.trans.cancel.value }}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn_container">
    <div class="col-md-12">
      <app-are-you-sure
        matTooltipPosition="above"
        [message]="trans.trans.are_you_sure.value"
        class="btn cancel-confirm pull-right marg-h"
        icon=""
        [text]="trans.trans.back.value"
        (callbackYes)="close()"
      ></app-are-you-sure>
    </div>
  </div>
</div>
