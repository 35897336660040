import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssetTagArray } from '@data/services/custom-asset-tags/asset-tag-array.enum';
import { CustomAssetTagService } from '@data/services/custom-asset-tags/custom-asset-tag.service';
import { ErrorMessagesService } from '@services/error-messages.service';
import { NotificationService } from '@services/notification.service';
import { AssetTags, Tag } from '@shared/models/asset-tag.models';
import { CustomValidators } from 'app/_utils/custom-validators';
import { Observable, Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { CustomAssetTagsTranslation } from '../../custom-asset-tags.translation';

@Component({
  selector: 'app-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss'],
})
export class ManageTagsComponent implements OnInit, OnDestroy {
  @Input() sidebarOpen: boolean;
  @Input() tagSelected: Tag;
  @Input() trans: CustomAssetTagsTranslation;
  @Output() sidebarClose: EventEmitter<boolean> = new EventEmitter();
  @Output() updatedTags: EventEmitter<string> = new EventEmitter();

  public tagForm: FormGroup;
  public style2022$: Observable<boolean>;

  private ngUnsubscribe: Subject<any> = new Subject();
  public errorDict = new Map<string, string>();

  AssetTagArray = AssetTagArray;

  isLoading = false;

  tagStringsBenefit: Array<{ key: string; label: string }> = [
    { key: 'metric_name', label: 'benefitNameTag' },
    { key: 'description', label: 'benefitDescriptionTag' },
    { key: 'tco_name', label: 'benefitTcoNameTag' },
    { key: 'metric_note', label: 'benefitNotesTag' },
    { key: 'metric_note_2', label: 'benefitNotes2Tag' },
    { key: 'metric_note_3', label: 'benefitsNotes3Tag' },
    { key: 'year1_benefits', label: 'benefitYear' },
    { key: 'year2_benefits', label: 'benefitYear' },
    { key: 'year3_benefits', label: 'benefitYear' },
    { key: 'year4_benefits', label: 'benefitYear' },
    { key: 'year5_benefits', label: 'benefitYear' },
    { key: 'year6_benefits', label: 'benefitYear' },
    { key: 'year7_benefits', label: 'benefitYear' },
    { key: 'year8_benefits', label: 'benefitYear' },
    { key: 'year9_benefits', label: 'benefitYear' },
    { key: 'year10_benefits', label: 'benefitYear' },
    { key: 'total_benefits', label: 'benefitsTotalTag' },
    { key: 'metric_phase_0', label: 'phasingPercentYear' },
    { key: 'metric_phase_1', label: 'phasingPercentYear' },
    { key: 'metric_phase_2', label: 'phasingPercentYear' },
    { key: 'metric_phase_3', label: 'phasingPercentYear' },
    { key: 'metric_phase_4', label: 'phasingPercentYear' },
    { key: 'metric_phase_5', label: 'phasingPercentYear' },
    { key: 'metric_phase_6', label: 'phasingPercentYear' },
    { key: 'metric_phase_7', label: 'phasingPercentYear' },
    { key: 'metric_phase_8', label: 'phasingPercentYear' },
    { key: 'metric_phase_9', label: 'phasingPercentYear' },
    { key: 'metric_phase_10', label: 'phasingPercentYear' },
  ];

  tagStringsFactor: string[] = ['name', 'description'];

  tagStringsBenefitGroup: Array<{ key: string; label: string }> = [
    { key: 'name', label: 'benefitGroupNameTag' },
    { key: 'description', label: 'benefitGroupDescriptionTag' },
    { key: 'tco_name', label: 'benefitGroupTcoNameTag' },
    { key: 'vc_yr1_total', label: 'benefitGroupYear' },
    { key: 'vc_yr2_total', label: 'benefitGroupYear' },
    { key: 'vc_yr3_total', label: 'benefitGroupYear' },
    { key: 'vc_yr4_total', label: 'benefitGroupYear' },
    { key: 'vc_yr5_total', label: 'benefitGroupYear' },
    { key: 'vc_yr6_total', label: 'benefitGroupYear' },
    { key: 'vc_yr7_total', label: 'benefitGroupYear' },
    { key: 'vc_yr8_total', label: 'benefitGroupYear' },
    { key: 'vc_yr9_total', label: 'benefitGroupYear' },
    { key: 'vc_yr10_total', label: 'benefitGroupYear' },
    { key: 'vc_total', label: 'benefitGroupTotalTag2' },
  ];

  tagStringsFunctionalObjective: Array<{ key: string; label: string }> = [
    { key: 'name', label: 'foName' },
    { key: 'description', label: 'foDescription' },
    { key: 'vc_yr1_benefits', label: 'foYear' },
    { key: 'vc_yr2_benefits', label: 'foYear' },
    { key: 'vc_yr3_benefits', label: 'foYear' },
    { key: 'vc_yr4_benefits', label: 'foYear' },
    { key: 'vc_yr5_benefits', label: 'foYear' },
    { key: 'vc_yr6_benefits', label: 'foYear' },
    { key: 'vc_yr7_benefits', label: 'foYear' },
    { key: 'vc_yr8_benefits', label: 'foYear' },
    { key: 'vc_yr9_benefits', label: 'foYear' },
    { key: 'vc_yr10_benefits', label: 'foYear' },
    { key: 'vc_total', label: 'foTotal' },
  ];

  tagStringsCapabilities: Array<{ key: string; label: string }> = [
    { key: 'name', label: 'capabilityNameTag' },
    { key: 'description', label: 'capabilityDescriptionTag' },
    { key: 'vc_yr1_benefits', label: 'capabilityYear' },
    { key: 'vc_yr2_benefits', label: 'capabilityYear' },
    { key: 'vc_yr3_benefits', label: 'capabilityYear' },
    { key: 'vc_yr4_benefits', label: 'capabilityYear' },
    { key: 'vc_yr5_benefits', label: 'capabilityYear' },
    { key: 'vc_yr6_benefits', label: 'capabilityYear' },
    { key: 'vc_yr7_benefits', label: 'capabilityYear' },
    { key: 'vc_yr8_benefits', label: 'capabilityYear' },
    { key: 'vc_yr9_benefits', label: 'capabilityYear' },
    { key: 'vc_yr10_benefits', label: 'capabilityYear' },
    { key: 'vc_total', label: 'capabilityTotalBenefitsTag' },
  ];

  tagStringsBenefitType: Array<{ key: string; label: string }> = [
    { key: 'name', label: 'benefitTypeNameTag' },
    { key: 'description', label: 'benefitTypeDescriptionTag' },
    { key: 'year1_total', label: 'benefitTypeYear' },
    { key: 'year2_total', label: 'benefitTypeYear' },
    { key: 'year3_total', label: 'benefitTypeYear' },
    { key: 'year4_total', label: 'benefitTypeYear' },
    { key: 'year5_total', label: 'benefitTypeYear' },
    { key: 'year6_total', label: 'benefitTypeYear' },
    { key: 'year7_total', label: 'benefitTypeYear' },
    { key: 'year8_total', label: 'benefitTypeYear' },
    { key: 'year9_total', label: 'benefitTypeYear' },
    { key: 'year10_total', label: 'benefitTypeYear' },
    { key: 'total', label: 'benefitTypeTotalTag2' },
  ];

  tagStringsOO: Array<{ key: string; label: string }> = [
    { key: 'name', label: 'ooNameTag' },
    { key: 'description', label: 'ooDescriptionTag' },
    { key: 'vc_yr1_benefits', label: 'ooYear' },
    { key: 'vc_yr2_benefits', label: 'ooYear' },
    { key: 'vc_yr3_benefits', label: 'ooYear' },
    { key: 'vc_yr4_benefits', label: 'ooYear' },
    { key: 'vc_yr5_benefits', label: 'ooYear' },
    { key: 'vc_yr6_benefits', label: 'ooYear' },
    { key: 'vc_yr7_benefits', label: 'ooYear' },
    { key: 'vc_yr8_benefits', label: 'ooYear' },
    { key: 'vc_yr9_benefits', label: 'ooYear' },
    { key: 'vc_yr10_benefits', label: 'ooYear' },
    { key: 'vc_total', label: 'ooTotal' },
  ];

  tagStringsPP: Array<{ key: string; label: string }> = [
    { key: 'name', label: 'ppNameTag' },
    { key: 'description', label: 'ppDescriptionTag' },
    { key: 'vc_yr1_benefits', label: 'ppYear' },
    { key: 'vc_yr2_benefits', label: 'ppYear' },
    { key: 'vc_yr3_benefits', label: 'ppYear' },
    { key: 'vc_yr4_benefits', label: 'ppYear' },
    { key: 'vc_yr5_benefits', label: 'ppYear' },
    { key: 'vc_yr6_benefits', label: 'ppYear' },
    { key: 'vc_yr7_benefits', label: 'ppYear' },
    { key: 'vc_yr8_benefits', label: 'ppYear' },
    { key: 'vc_yr9_benefits', label: 'ppYear' },
    { key: 'vc_yr10_benefits', label: 'ppYear' },
    { key: 'vc_total', label: 'ppTotal' },
  ];

  constructor(
    private fb: FormBuilder,
    private customAssetTagService: CustomAssetTagService,
    private notificationService: NotificationService,
    private errorMessageService: ErrorMessagesService
  ) {
    this.tagForm = this.fb.group({
      name: ['', Validators.required],
      factorValueTag: ['', [Validators.required, Validators.maxLength(255), CustomValidators.noSpecialCharacterValidator()]],
      factorDescription: [''],
      active: [''],
    });
  }

  get name() {
    return this.tagForm.get('name');
  }
  get factorValueTag() {
    return this.tagForm.get('factorValueTag');
  }
  get factorDescription() {
    return this.tagForm.get('factorDescription');
  }
  get active() {
    return this.tagForm.get('active');
  }

  ngOnInit(): void {
    if (this.tagSelected) {
      const { name, tag, active, description } = this.tagSelected;

      this.tagForm.setValue({
        name,
        factorValueTag: tag,
        factorDescription: description,
        active: active,
      });
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.tagForm.controls));
    }

    this.tagForm.valueChanges.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.errorDict = new Map(this.errorMessageService.getFormErrors(this.tagForm.controls));
    });

    this.factorValueTag.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
      this.factorValueTag.patchValue(val.replace(' ', '_'), { emitEvent: false });
    });

    this.active.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (!res) this.notificationService.warning(this.trans.trans.statusOff.value, false);
    });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.next();
  }

  onSave() {
    const payload = this.customAssetTagService.createTagPayload(
      this.tagSelected.metadata.id,
      this.name.value,
      this.tagForm.value.factorValueTag,
      this.tagSelected.metadata.array_name as AssetTagArray,
      this.active.value,
      this.tagSelected.id
    );

    if (this.tagSelected) {
      this.customAssetTagService
        .editAssetTag(payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((response) => {
          if (response?.result && response.result === 'The tags must be unique.') {
            this.notify('tagMustBeUnique', 'error');
            return;
          }
          this.updatedTags.emit(this.tagSelected.metadata.array_name);
          this.notify('saveSuccess');
          this.onHide();
        });
    }
  }

  public onHide() {
    this.sidebarClose.emit(false);
  }

  private notify(transString: string, type: 'error' | 'success' = 'success') {
    this.notificationService[type](this.trans.trans[transString].value, false);
  }
}
