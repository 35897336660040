import { Component, Input, forwardRef, ViewEncapsulation, Output, EventEmitter, ElementRef } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Fieldset } from 'primeng/fieldset';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { StyleService } from 'app/style.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'dl-fieldset',
  templateUrl: './fieldset.component.html',
  styleUrls: ['./fieldset.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fieldsetContent', [
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition('visible <=> hidden', [style({ overflow: 'hidden' }), animate('{{transitionParams}}')]),
      transition('void => *', animate(0)),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DLFieldsetComponent),
    },
  ],
})
export class DLFieldsetComponent extends Fieldset {
  style2022$: Observable<boolean>;

  constructor(el: ElementRef, private styleService: StyleService) {
    super(el);
    this.style2022$ = this.styleService.style2022;
  }

  @Output() editCallback = new EventEmitter();
  @Output() deleteCallback = new EventEmitter();

  @Input() editCallbackVisible: boolean = false;
  @Input() deleteCallbackVisible: boolean = false;
  @Input() info: string = '';
  @Input() scratchpad: boolean = false;

  funcEditCallback() {
    this.editCallback.emit();
  }

  funcDeleteCallback() {
    this.deleteCallback.emit();
  }

  toggle(_event): boolean {
    return (this.collapsed = !this.collapsed);
  }
}
