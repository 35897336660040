import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SituationElement } from '@data/services/valueprop/models/situation.model';
import { NotificationService } from '@services/notification.service';
import { Table } from 'primeng/table';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DefaultTranslations } from './situations.translation';

@Component({
  selector: 'app-maintenance-situations',
  templateUrl: './situations.component.html',
  styleUrls: ['./situations.component.scss'],
})
export class MaintenanceSituationsComponent implements OnInit, OnDestroy {
  @Input() solutionId: string | number;
  @Input() can_edit: boolean;

  fullImagePath: string;
  situations: SituationElement[] = [];

  objSituation: Partial<SituationElement> = {
    name: '',
    description: '',
    type: '',
    id: '',
    situation_type_id: '',
    has_tag: false,
    has_block_tag: false,
  };

  mode = 'add';
  modeChild = 'add';
  cols: Array<{ field: string; header: string }>;

  sidebar_title: string;
  toggleAddEditSituation = false;
  ngUnsubscribe$ = new Subject();
  strSearch = '';
  loading = true;
  showTranslate = false;
  account_id: string;

  hideMenu: Observable<boolean>;
  private hideMenu$ = new Subject<boolean>();

  showSidebar = false;
  indexOfSelection: number;
  currentSequenceEdited = false;
  forceRefresh = false;
  moveDirection: 'up' | 'down' = 'up';
  indexToMove = 0;
  selectedMove: SituationElement;
  @ViewChild('tableSituations') table: Table;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service
  ) {
    const image_url = this.commonService.getImageUrl();
    this.fullImagePath = image_url + '/images/jamaica/reload.gif';
    this.hideMenu = this.hideMenu$.asObservable();
  }

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');

    this.funcGetSituationList();

    this.solutionService.gotoAddSituation.subscribe(() => {
      this.funcAddSituation();
    });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  closeAddEditSituation(): void {
    this.toggleAddEditSituation = false;
  }

  getTranslations(): void {
    this.translationService
      .trans(this.trans)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans = res;
        this.cols = [
          { field: 'name', header: this.trans.trans.name.value },
          { field: 'processingOrder', header: this.trans.trans.processingOrder.value },
          { field: 'sequence', header: this.trans.trans.sequence.value },
        ];
        this.sidebar_title = this.trans.trans.addTitle.value;
      });
  }

  funcEditSituation(elem: SituationElement): void {
    this.mode = 'edit';
    this.sidebar_title = this.trans.trans.editTitle.value;
    this.funcToggleAddEdit(elem);

    this.toggleAddEditSituation = true;
  }

  funcAddSituation(): void {
    this.mode = 'add';
    this.objSituation = {
      name: '',
      description: '',
      type: '',
      id: '',
      situation_type_id: '',
      has_tag: false,
      has_block_tag: false,
    };
    this.sidebar_title = this.trans.trans.addTitle.value;
    this.toggleAddEditSituation = true;
  }

  funcToggleAddEdit(elem: SituationElement): void {
    this.mode = 'edit';
    this.objSituation = elem;
    this.toggleAddEditSituation = true;
  }

  funcToggleAddEditChild(rowData): void {
    rowData.childName = '';
    rowData.childDescription = '';
    this.modeChild = 'add';
    rowData.expanded = !rowData.expanded;
  }

  funcViewSituation(situation: SituationElement): void {
    this.objSituation = situation;
    this.toggleAddEditSituation = true;
  }

  funcCloseSidebar(): void {
    this.toggleAddEditSituation = false;
    this.funcGetSituationList();
  }

  funcGetSituationList(): void {
    this.loading = true;

    this.solutionService
      .getAccountSituationTypes()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.situations = res.result.map((situation, index) => ({ ...situation, processingSequence: situation.stack_order }));
        this.loading = false;
      });
  }

  drop(): void {
    this.updateProcessingSequence();
    this.currentSequenceEdited = true;
  }

  funcUpdateSituationOrder(): void {
    if (!this.currentSequenceEdited) {
      return;
    }
    const situationStackOrder = this.situations.map((item, index) => {
      const situation = {
        stack_order: index,
        situation_type_id: item.id,
      };
      return situation;
    });

    const payload = {
      situation_types: situationStackOrder,
    };

    this.solutionService
      .updateSituationTypeStackOrder(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.result && response.result['success']) {
          this.notificationService.success(this.trans.trans.orderUpdate.value, false);
          this.funcGetSituationList();
        }
      });
  }

  resetValues(): void {
    this.objSituation = {
      name: '',
      description: '',
      type: '',
      id: '',
      situation_type_id: '',
    };
  }

  findOriginalIndex(id: string): number {
    return this.situations.findIndex((element) => +element.id === +id);
  }

  moveUp(id: string): void {
    this.hideMenu$.next(true);
    const foundIndex = this.findOriginalIndex(id);
    moveItemInArray(this.situations, foundIndex, foundIndex - 1);
    this.currentSequenceEdited = true;
    this.forceRefresh = !this.forceRefresh;
    this.strSearch = '';
    this.table.filter('', 'name', 'contains');
    this.updateProcessingSequence();
  }

  moveDown(id: string): void {
    this.hideMenu$.next(true);
    const foundIndex = this.findOriginalIndex(id);
    moveItemInArray(this.situations, foundIndex, foundIndex + 1);
    this.currentSequenceEdited = true;
    this.forceRefresh = !this.forceRefresh;
    this.strSearch = '';
    this.table.filter('', 'name', 'contains');
    this.updateProcessingSequence();
  }

  handleCustomSequenceClick(id: string): void {
    this.showSidebar = true;
    this.indexOfSelection = this.findOriginalIndex(id);
  }

  saveMoveForm(): void {
    const index = this.indexOfSelection;

    if (this.moveDirection === 'up') {
      moveItemInArray(this.situations, index, this.indexToMove);
    } else {
      moveItemInArray(this.situations, index, this.indexToMove);
    }
    this.currentSequenceEdited = true;
    this.forceRefresh = !this.forceRefresh;
    this.strSearch = '';
    this.table.filter('', 'name', 'contains');
    this.closeSidebar();
    this.hideMenu$.next(true);
    this.updateProcessingSequence();
  }

  closeSidebar(): void {
    this.showSidebar = false;
  }

  handleDropdownChange(_event): void {
    this.indexToMove = this.situations.findIndex((x) => x.name === this.selectedMove.name);
    this.currentSequenceEdited = true;
  }

  updateProcessingSequence(): void {
    this.situations = this.situations.map((situation, index) => ({ ...situation, processingSequence: index }));
  }
}
