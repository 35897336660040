<div class="col-md-12 tie-to-benefits-buttons">
  <button class="btn btn-default pull-right" (click)="openTieToBenefits(tieToBenefits)">{{ trans.trans.tieToBenefit.value }}</button>
</div>
<ng-container *ngIf="caseStudy['benefits']">
  <div class="row" id="benefits-wrapper" class="grid-row" *ngFor="let benefit of caseStudy['benefits']">
    <div class="col-md-12">
      <div class="panel panel-white blue-head">
        <div class="panel-heading clearfix">
          <h4 class="panel-title">
            {{ trans.trans.benefit.value }}
            <i class="icon-pencil m-l-xxl" (click)="openEditBenefit(editBenefitModal, benefit)"></i>
            <i class="icon-trash m-l-xs" (click)="deleteBenefit(benefit)"></i>
          </h4>
        </div>
        <div class="panel-body">
          <div id="benefits">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ trans.trans.name_label.value }}</th>
                  <th>{{ trans.trans.improvement.value }} {{ benefit.improvement_type_id == '1' ? '%' : '$' }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="soln_row">
                  <td>
                    {{ benefit.metric_name }}
                  </td>
                  <td>{{ benefit.improvement }}</td>
                </tr>
              </tbody>
            </table>
            <div [hidden]="!actionLoader" class="col-12 text-center">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </div>
            <div class="benefits-desc">
              <div class="col-md-2">
                <div class="benefit-name">
                  {{ trans.trans.description.value }}
                </div>
              </div>
              <div class="col-md-10">
                {{ benefit.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #tieToBenefits let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.tieToBenefit.value }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="tieToBenefitsBody">
    <div class="panel-body col-md-5 benefits-list">
      <div class="panel-group accordion" id="accordion1" role="tablist" aria-multiselectable="true">
        <div class="panel panel-default" *ngFor="let benefit of BenefitsList" [ngClass]="{ selected: selectedBenefitMove && selectedBenefitMove.id === benefit.id }">
          <div class="panel-heading" role="tab" id="headingOne">
            <h4 class="panel-title">
              <a
                data-toggle="collapse"
                data-parent="#accordion1"
                href="#{{ benefit.id }}"
                (click)="selectBenefitToMove(benefit)"
                aria-expanded="false"
                aria-controls="collapseOne"
                class="collapsed"
              >
                {{ benefit.metric_name }}
              </a>
            </h4>
          </div>
          <div id="{{ benefit.id }}" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false" style="height: 0px">
            <div class="panel-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ trans.trans.improvement.value }}</th>
                    <th>{{ trans.trans.improvement.value }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="soln_row">
                    <td>
                      <input type="text" myNumberOnly class="form-control m-t-xxs text-input" [(ngModel)]="benefitImpValue" id="improvement_value" name="improvement_value" />
                    </td>
                    <td>
                      <select [(ngModel)]="benefitImpType" class="form-control role-dropdown" name="benefitImpType">
                        <option *ngFor="let impType of impTypesList" [value]="impType.id" [selected]="impType.id === benefitImpType">
                          {{ impType.abbr }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="BenefitsList == null || BenefitsList.length == 0">
          <p class="m-t-xxl text-center">{{ trans.trans.no_benefits_found.value }}</p>
        </div>
        <div [hidden]="!actionLoader" class="col-12 text-center">
          <img class="actionLoader" src="{{ fullImagePath }}" />
        </div>
      </div>
    </div>
    <div class="d-flex panel-body col-md-2 action-icons-wrapper">
      <div class="action-icons icon-arrow-right" (click)="moveSelectedBenefit()"></div>
      <div class="action-icons icon-arrow-left" (click)="removeSelectedBenefit()"></div>
    </div>
    <div class="panel-body col-md-5 benefits-list">
      <div class="panel-group accordion" id="accordion" role="tablist" aria-multiselectable="true">
        <div class="panel panel-default" *ngFor="let benefit of selectedBenefits" [ngClass]="{ selected: selectedBenefitRemove && selectedBenefitRemove.id === benefit.id }">
          <div class="panel-heading" role="tab" id="headingOne">
            <h4 class="panel-title">
              <a
                data-toggle="collapse"
                data-parent="#accordion"
                (click)="selectBenefitToRemove(benefit)"
                href="#selected_{{ benefit.id }}"
                aria-expanded="false"
                aria-controls="collapseOne"
                class="collapsed"
              >
                {{ benefit.metric_name }}
              </a>
            </h4>
          </div>
          <div id="selected_{{ benefit.id }}" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" aria-expanded="false" style="height: 0px">
            <div class="panel-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ trans.trans.improvement.value }}</th>
                    <th>{{ trans.trans.improvement.value }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="soln_row">
                    <td>{{ benefit.improvement.value }}</td>
                    <td>
                      {{ benefit.improvement.typeAbbr }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary pull-right done-btn" (click)="benefitsSave()" [disabled]="selectedBenefits.length == 0">
      {{ trans.trans.save.value }}
    </button>
  </div>
</ng-template>

<ng-template #editBenefitModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ trans.trans.update_benefit.value }} - {{ selectedBenefit.metric_name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="editBenefitForm" #editBenefitForm="ngForm">
      <table class="table">
        <thead>
          <tr>
            <th>{{ trans.trans.improvement.value }}</th>
            <th>{{ trans.trans.type.value }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="soln_row">
            <td>
              <input type="text" myNumberOnly class="form-control m-t-xxs text-input" [(ngModel)]="benefitImpValue" id="improvement_value" name="improvement_value" required />
            </td>
            <td>
              <select [(ngModel)]="benefitImpType" class="form-control role-dropdown" name="benefitImpType" required>
                <option *ngFor="let impType of impTypesList" [value]="impType.id" [selected]="impType.id === benefitImpType">
                  {{ impType.abbr }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary m-t-xs m-b-xs" (click)="editBenefit()" [disabled]="editBenefitForm.invalid">{{ trans.trans.update.value }}</button>
  </div>
</ng-template>
