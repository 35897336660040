import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { BenefitsImplementation } from '../../benefits.implementation';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BenefitsMiniTranslations } from './mini.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { Metric } from '@shared/models/value-prop.model';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-benefits-mini-v2',
  templateUrl: './mini.component.html',
})
export class BenefitsMiniComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set benefits(metrics: Metric[]) {
    const list = JSON.parse(sessionStorage.getItem('metricsExpandedMini'));
    const currentBenefit = sessionStorage.getItem('benefit_index');
    if (!this.valueMetrics || this.benefitOnView !== currentBenefit || currentBenefit === null) {
      this.valueMetrics = list ? this.setBenefitOpen(list, metrics) : metrics;
      this.benefitOnView = currentBenefit;
    } else {
      this.manualUpdate(list ? this.setBenefitOpen(list, metrics) : metrics);
    }
  }

  get benefits() {
    return this.valueMetrics;
  }
  @Input() clean: boolean = false;
  @Input() locked: boolean = false;
  @Input() permissions: any;
  @Input() shareView: string = 'simple';
  @Input() str: string = '';
  @Input() vcName: string = '';
  @Input() desc: string;
  @Input() categoryId: number | string;
  @Output() reloadVP = new EventEmitter();
  @Input() valueProp: ValueProp;
  @Input() repV2 = false;
  @Input() manualUpdateRefresh = false;
  ngUnsubscribe = new Subject();
  feature57: boolean = false;
  feature70 = false;
  featureNonFinancial = false;
  canEdit = true;
  valueMetrics: Metric[];
  editingVC: boolean = false;
  alt_name: string;

  showTranslate = false;
  cols: any[];
  benefitFlyPrivilege = false;
  benfitFlyOpen = false;
  disableEdits: boolean = false;
  solutionId: number;
  benefitOnView: string;

  constructor(
    private valuePropService: ValuepropService,
    private translationService: TranslationsV2Service,
    public trans: BenefitsMiniTranslations,
    public bI: BenefitsImplementation,
    private RS: RepSimpleViewService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.bI.benefitOnTheFlyPrivilege = this.commonService.checkPrivilege(6);

    this.feature57 = this.commonService.checkFeature('57'); // benefit fly out
    this.feature70 = this.commonService.checkFeature('70'); // benefit fly out
    this.featureNonFinancial = this.commonService.checkFeature('94');
    this.disableEdits = this.commonService.checkFeature('151');

    this.cols = [
      { field: 'metric_name', sort: 'metric_name', header: 'Benefit Name', conditionalField: true },
      { field: 'quantification_type', sort: 'quantification_type_id', header: 'Benefit Unit', conditionalField: this.featureNonFinancial },
      { field: 'impact_type', sort: 'impact_type', header: 'Impact Type', conditionalField: true },
      { field: 'impact', sort: 'impact', header: 'Improvement', conditionalField: true },
      { field: 'year1_benefits_fmt', sort: 'year1_benefits', header: 'Year 1', conditionalField: true },
      { field: 'total_benefits_fmt', sort: 'total_benefits', header: 'Total', conditionalField: true },
    ];

    if (this.feature70) {
      this.cols.splice(2, 1, { field: 'benefit_type', sort: 'soft', header: 'Benefit Type', conditionalField: true });
    }

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(take(1)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(take(1)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.funcFixVal();

    if (!this.clean) {
      this.valuePropService.refreshBenefitDetail.pipe(take(1)).subscribe(() => {
        this.RS.getBenefitsByValueCategoryID(this.valueProp.id, sessionStorage.getItem('repsimple_results_benefit_id'))
          .pipe(take(1))
          .subscribe((res) => {
            this.manualUpdate(res.result.metrics);
          });
      });

      this.RS.getBenefitsByValueCategoryID(this.valueProp.id, sessionStorage.getItem('repsimple_results_benefit_id'))
        .pipe(take(1))
        .subscribe(() => {
          this.funcFixVal();
        });
    }

    if (this.manualUpdateRefresh) {
      this.bI.benefitsListByType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.extractInfo(this.valueMetrics[0]);
      });
      this.bI.benefitsUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((id) => {
        const updatedBenefit = this.valueMetrics.find((metric) => metric.id);
        if (updatedBenefit) {
          this.extractInfo(updatedBenefit);
        } else if (id === 'all' || id === 'allExpand') {
          this.extractInfo(this.valueMetrics[0]);
        }
      });
    }
    this.canEdit = this.valueProp && this.valueProp.vp_can_edit && this.permissions && this.permissions.result_editable;
    if (this.repV2) {
      this.bI.view = 'rep-simple';
    }
    if (this.shareView == 'simple') {
      this.canEdit = true;
    }

    this.getSolutionId();
    const privs = sessionStorage.getItem('privileges');
    const privileges = privs.split(',');
    if (privileges.length) {
      if (privileges.indexOf('6') >= 0) {
        this.benefitFlyPrivilege = this.valueProp && this.valueProp.vp_can_edit && this.permissions && this.permissions.result_editable;
      }
    }

    if (!this.desc && this.valueProp.benefits) {
      const vc = this.valueProp.benefits.find((benefitCategory) => Number(benefitCategory.value_category_id) === Number(this.categoryId));
      this.desc = vc ? vc.description : null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.bI.valueProp = this.valueProp;
  }

  funcFixVal() {
    for (let i = 0; i < this.benefits.length; i++) {
      this.benefits[i].year1_benefits = parseInt(this.benefits[i].year1_benefits as string);
      this.benefits[i].total_benefits = parseInt(this.benefits[i].total_benefits as string);
    }
  }

  editValueCategory() {
    this.alt_name = this.alt_name ?? this.vcName;
    this.editingVC = !this.editingVC;
  }

  closeValueCategoryEdit() {
    this.editingVC = !this.editingVC;
  }

  updateValueCategoryName() {
    this.editingVC = !this.editingVC;

    const params = {
      name: this.alt_name,
      value_category_id: this.categoryId,
    };

    this.valuePropService
      .updateValueCategoryOverride(this.valueProp.id, params)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.reload();
        }
      });
  }

  reload() {
    this.RS.getBenefitsByValueCategoryID(this.valueProp.id, this.categoryId || sessionStorage.getItem('repsimple_results_benefit_id'))
      .pipe(take(1))
      .subscribe((res) => {
        this.manualUpdate(res.result.metrics);
        if (!this.manualUpdateRefresh) {
          this.reloadVP.emit();
        } else {
          this.valuePropService.refreshDashboard.next('refreshDashboard');
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.bI.benefitFirstLoad = true;
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  expandBenefitMini(benefit) {
    benefit.expanded = !benefit.expanded;
    this.setExpandBenefitMini();
  }

  setExpandBenefitMini() {
    if (!this.feature57) {
      let out = [];
      for (let m = 0; m < this.benefits.length; m++) {
        let metric = this.benefits[m];
        if (metric.expanded) {
          out.push(metric.value_prop_metric_id);
        }
      }

      sessionStorage.setItem('metricsExpandedMini', JSON.stringify(out));
    }
  }

  getBenefitType(status) {
    const benefitTypes = {
      '0': 'Hard',
      '1': 'Soft',
      '2': 'Strategic',
    };
    return benefitTypes[status];
  }

  getSolutionId() {
    this.valuePropService
      .readSolutionList(this.valueProp.id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.length > 0) {
          this.solutionId = Number(response.result[0]['account_solution_id']);
        }
      });
  }

  setBenefitOpen(openId: number[], benefits: Metric[]): Metric[] {
    const openIdMap: {
      [key: number]: boolean;
    } = openId.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: true,
      };
    }, {});
    return benefits.map((benefit) => ({
      ...benefit,
      expanded: openIdMap[benefit.value_prop_metric_id],
    }));
  }

  private manualUpdate(metrics: Metric[]) {
    this.valueMetrics.forEach((benefit) => {
      const updatedBenefit = metrics.find((metric) => metric.id === benefit.id);
      if (updatedBenefit) {
        benefit.alt_metric_name = updatedBenefit.alt_metric_name;
        benefit.metric_name = updatedBenefit.metric_name;
        benefit.description = updatedBenefit.description;
        benefit.vp_specific = updatedBenefit.vp_specific;
        benefit.benefit_type = updatedBenefit.benefit_type;
        benefit.impact_type = updatedBenefit.impact_type;
        benefit.soft = updatedBenefit.soft;
        benefit.impact = updatedBenefit.impact;
        benefit.abbr = updatedBenefit.abbr;
        benefit.year1_benefits_fmt = updatedBenefit.year1_benefits_fmt;
        benefit.total_benefits_fmt = updatedBenefit.total_benefits_fmt;
      }
    });
    this.funcFixVal();
  }

  private extractInfo(benefit: Metric) {
    if (this.bI.benefitsListByType) {
      const type = this.bI.benefitsListByType.find((type) => type.id === benefit.benefit_type_id);
      const updatedVCS = type.vcs.find((category) => category.id === benefit.value_category_id);
      if (updatedVCS) {
        this.manualUpdate(
          updatedVCS.metrics.map((metric) => {
            return {
              ...metric,
              impact: metric.abbr === '%' && typeof metric.impact === 'string' ? (+metric.impact * 100).toFixed(Number(metric.i_precision)) : metric.impact,
            };
          }) as unknown as Metric[]
        );
      }
    }
  }
}
