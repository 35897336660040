import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-btn-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NGFormSaveButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: NgForm;

  @Input() text: string;

  @ViewChild('op') op: OverlayPanel;

  @Output() callback = new EventEmitter();

  class = '';
  icon = '';
  @Input() running = false;

  controlList: { key: string; name: string }[] = [];

  ngOnInit(): void {
    this.running = false;
  }

  ngOnChanges(): void {
    this.running = false;
  }

  ngOnDestroy(): void {
    this.running = false;
  }

  funcVerifyForm(event: Event): void {
    if (this.form.valid) {
      this.running = true;
      this.callback.emit();
    } else {
      this.running = false;
      this.controlList = [];
      Object.keys(this.form.controls).forEach((key) => {
        if (!this.form.controls[key].valid) {
          const tmp = document.getElementsByName(key)[0];

          if (tmp.getAttribute('ret-data')) {
            this.controlList.push({ key: key, name: tmp.getAttribute('ret-data') });
          }
        }
      });
      this.running = false;
      this.op.toggle(event);
    }
  }

  funcScrollTo(key: string): void {
    this.op.hide();
    const tmp = document.querySelector(`[name="${key}"]`);
    tmp.scrollIntoView();
  }

  funcCancel(): void {
    this.running = false;
  }
}
