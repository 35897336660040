<dl-menubar styleClass="details_menu_bar" class="details_menu_bar tourMenubar" [model]="menuTop">
  <i *ngIf="showTranslate" class="fa fa-exchange clickable" style="margin-top: 10px; margin-left: 10px"></i>
</dl-menubar>

<div *ngIf="viewTop == 'interstitial'">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>

<app-value-prop-dashboard-roi
  [valueProp]="valueProp"
  [showTranslate]="showTranslate"
  [isDev]="isDev"
  [dashboard]="dashboard"
  [showHelp]="showHelp"
  *ngIf="viewTop == 'roi'"
  [contextualHelp]="contextualHelp"
  [year1BreakDownChartData]="year1BreakDownChartData"
  [costBenefitChartData]="costBenefitChartData"
></app-value-prop-dashboard-roi>
<app-cashflow
  [showTranslate]="showTranslate"
  *ngIf="viewTop == 'cashflow' && valueProp && valueProp['id'] && valueProp['id'] != null"
  [contextualHelp]="contextualHelp"
  [valueProp]="valueProp"
></app-cashflow>
<app-tco
  [showTranslate]="showTranslate"
  [showHelp]="showHelp"
  *ngIf="viewTop == 'tco' && valueProp && valueProp['id'] && valueProp['id'] != null"
  [contextualHelp]="contextualHelp"
  [valueProp]="valueProp"
></app-tco>
<app-competitive-tco *ngIf="viewTop === 'competitive-tco' && valueProp && valueProp['id'] && valueProp['id'] != null" [valueProp]="valueProp"></app-competitive-tco>
<app-notes
  [showTranslate]="showTranslate"
  shareView="simple"
  [showHelp]="showHelp"
  *ngIf="viewTop == 'notes' && valueProp && valueProp['id'] && valueProp['id'] != null"
  [contextualHelp]="contextualHelp"
  [valueProp]="valueProp"
></app-notes>
<app-log
  [showTranslate]="showTranslate"
  [showHelp]="showHelp"
  *ngIf="viewTop == 'log' && valueProp && valueProp['id'] && valueProp['id'] != null"
  [contextualHelp]="contextualHelp"
  [valueProp]="valueProp"
></app-log>
<app-score-card
  [showTranslate]="showTranslate"
  [showHelp]="showHelp"
  *ngIf="viewTop == 'scorecard' && valueProp && valueProp['id'] && valueProp['id'] != null"
  [contextualHelp]="contextualHelp"
  [valueProp]="valueProp"
></app-score-card>
<app-share-v2 [showTranslate]="showTranslate" [showHelp]="showHelp" *ngIf="viewTop == 'share' && valueProp && valueProp['id'] && valueProp['id'] != null" [data]="valueProp">
</app-share-v2>
