import { Component, OnInit, OnDestroy } from '@angular/core';
import { ScbService } from '@data/services/scb/scb.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { DownloadDetails } from './download-details';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit, OnDestroy {
  downloadDetails: DownloadDetails[];

  subscriptionScbService: Subscription;

  constructor(private ScbService: ScbService, private router: Router, private translateService: TranslateService, public trans: Translations) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.getDownloadData();
  }

  ngOnDestroy() {
    if (this.subscriptionScbService) {
      this.subscriptionScbService.unsubscribe();
    }
  }

  public getDownloadData() {
    this.subscriptionScbService = this.ScbService.scbDownloadList().subscribe((result) => {
      this.downloadDetails = result['result']['downloads'];
    });
  }

  selectCompany(companyID: number) {
    this.router.navigate(['/company/' + companyID]);
  }
}
