import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { CollapseContainerModule } from '@shared_components/collapsible-container/collapsible-container.module';
import { DetailCardModule } from '@shared_components/detail-card/detail-card.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { TrackerLineModule } from 'app/common/chart/tracker-line/tracker-line.module';
import { TrackerStackedBarModule } from 'app/common/chart/trakcers-stacked-bar/trackers-stacked-bar.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { ModelAEBenefits } from 'app/value-mapv2/solution-detail/benefits/ae_benefits/ae_benefits.module';
import { AddBenefitsModule } from 'app/value-prop/value-prop-dashboard/add-benefits-component/add-benefits-component.module';
import { BenefitsPhasingModule } from 'app/value-prop/value-prop-dashboard/benefits_v2/views/phasing/phasing.module';
import { ValueRealizationModule } from 'app/value-realization/value-realization.module';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ValueRealizationDetail2Component } from '../realization-detail2/realization-detail.component';
import { ValueTrackerDetailTranslations } from '../realization-detail2/realization-detail.translation';
import { ValueTrackerShareModule } from '../share/value-tracker-share.module';
import { ValueTrackerAssetsModule } from '../tracker-assets/assets.module';
import { TrackerBenefitNotesModule } from '../tracker-benefit-notes/tracker-benefit-notes.module';
import { TrackerCaseStudiesModule } from '../tracker-case-studies/tracker-case-studies.module';
import { TrackerCostsComponentModule } from '../tracker-costs/tracker-costs.module';
import { TrackersContainerComponent } from './trackers-container.component';
import { ValueTrackerTableTranslation } from './trackers-container.translation';
import { AssetListModule } from './views/assets/assets.module';
import { NotifyMarketingComponent } from './views/notify-marketing/notify-marketing.component';
import { NotifyMarketingTranslation } from './views/notify-marketing/notify-marketing.translation';
import { TrackerBenefitComponent } from './views/tracker-benefits/tracker-benefits.component';
import { TrackerGoalsModule } from '../tracker-goals/tracker-goals.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
  {
    path: 'trackers',
    component: TrackersContainerComponent,
  },
  {
    path: 'trackers/:id',
    component: ValueRealizationDetail2Component,
  },
];

@NgModule({
  imports: [
    BenefitsPhasingModule,
    ValueTrackerAssetsModule,
    CommonModule,
    DLMenubarModule,
    TableModule,
    AreYouSureModule,
    FormsModule,
    EditTranslationsModule,
    TrackerLineModule,
    TrackerStackedBarModule,
    SidebarModule,
    MenuModule,
    AssetListModule,
    ApplicationPipesModule,
    DropdownModule,
    ValueRealizationModule,
    CalendarModule,
    MatTooltipModule,
    AddBenefitsModule,
    ModelAEBenefits,
    DetailCardModule,
    ProgressBarModule,
    CollapseContainerModule,
    TrackerCaseStudiesModule,
    TrackerCostsComponentModule,
    TrackerBenefitNotesModule,
    TrackerGoalsModule,
    ValueTrackerShareModule,
    NoRecordsModule,
    FunctionPipeModule,
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
  ],
  exports: [TrackersContainerComponent],
  declarations: [TrackersContainerComponent, ValueRealizationDetail2Component, TrackerBenefitComponent, NotifyMarketingComponent],
  entryComponents: [TrackersContainerComponent],
  providers: [TranslationsV2Service, ValueTrackerTableTranslation, NotifyMarketingTranslation, ValueTrackerDetailTranslations],
})
export class TrackersContainerModule {}
