import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
})
export class NameInitialsPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value === 'string') {
      const splitName = value.split(' ');

      value = '';
      splitName.forEach((word) => {
        word !== '' ? (value += word[0]) : value;
      });
      return value.substring(0, 2);
    } else {
      return value;
    }
  }
}
