import { Location } from '@angular/common';
import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../_services/alert.service';

@Component({
  selector: 'app-solution-detail',
  templateUrl: './solution-detail.component.html',
  styleUrls: ['./solution-detail.component.scss'],
})
export class SolutionDetailComponent implements OnInit, OnDestroy, AfterViewChecked {
  activeTab = 1;
  rating = 4;
  solutionId: number | string;
  solutionDetail: { [klass: string]: any };
  valueCategory: { [klass: string]: any };
  currentValueCategory: string;
  currentValueCategoryName: string;
  currentBenefit: { [klass: string]: any };
  benefitLoader = false;
  loadSolution = false;
  imageUrl: string;
  fullImagePath: string;
  isSolutionAdmin = false;
  toggleMaintenance = false;
  validationCount = '0';
  breadcrumbValues: Breadcrumbs;

  canEdit = false;

  contextualHelp: { [klass: string]: string } = {};

  defaultTerm = '3';

  masterValuePropFeature = false;

  loaded = false;

  subscriptioncontextualHelp: Subscription;
  subscriptiongetSolutionDetail: Subscription;
  subscriptionreadBenefitsByValueCategory: Subscription;
  subscriptionSubscribeLoggedIn: Subscription;

  isDev = false;
  caseStudyId: string | number;
  activeIndex = 0;

  constructor(
    private solutionService: SolutionService,
    private location: Location,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private alertService: AlertService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }
  currentJustify = 'center';

  ngOnInit() {
    this.isDev = this.commonService.isDev$;

    const features = sessionStorage.getItem('features');

    if (features.indexOf('15') >= 0) {
      this.masterValuePropFeature = true;
    }

    this.getSolutionDetail();
    this.isSolutionAdmin = this.solutionService.getIsSolutionAdmin();
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Model'];
    });

    this.subscriptionSubscribeLoggedIn = this.alertService.subscribeLoggedIn().subscribe(() => {
      this.getSolutionDetail();
    });

    this.route.params.subscribe((params) => (this.caseStudyId = params['caseid']));
  }

  ngAfterViewChecked() {
    if (this.caseStudyId) {
      this.activeTab = 7;
    }
  }

  private buildBreadcrumbs() {
    console.log();
    this.breadcrumbValues = {
      [this.trans.solution_detail.Dashboard.split('.')[1]]: { routerLink: '/dashboard', active: false },
      [this.trans.solution_detail.Models.split('.')[1]]: { routerLink: '/models', active: false },
      [this.solutionDetail.name]: { routerLink: `/model/${this.solutionId}`, active: true },
    };
  }

  ngOnDestroy() {
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptiongetSolutionDetail) {
      this.subscriptiongetSolutionDetail.unsubscribe();
    }
    if (this.subscriptionreadBenefitsByValueCategory) {
      this.subscriptionreadBenefitsByValueCategory.unsubscribe();
    }
    if (this.subscriptionSubscribeLoggedIn) {
      this.subscriptionSubscribeLoggedIn.unsubscribe();
    }
  }

  getSolutionDetail() {
    this.route.params.subscribe((params) => (this.solutionId = params['id']));

    this.subscriptiongetSolutionDetail = this.solutionService.getSolutionDetail(this.solutionId).subscribe((response) => {
      this.canEdit = response.result.can_edit;
      this.defaultTerm = response.result.default_term;
      if (!response.result['share_role_type_id']) {
        this.location.back();
        this.loaded = false;
        return false;
      }

      this.solutionDetail = response.result;
      this.valueCategory = this.solutionDetail.value_categories;
      this.currentValueCategory = 'all';
      this.loadSolution = true;

      this.solutionService.readSolutionShareType.next(this.solutionDetail['share_role_type_id']);
      this.loaded = true;
      this.buildBreadcrumbs();
    });
  }

  getCurrentBenefit() {
    this.benefitLoader = false;
    for (let i = 0; i < this.valueCategory.length; i++) {
      // tslint:disable-next-line: triple-equals
      if (this.valueCategory[i].id == this.currentValueCategory) {
        this.currentValueCategoryName = this.valueCategory[i].name;
      }
    }
    this.readBenefitsByValueCategory(this.currentValueCategory, this.solutionId);
  }

  readBenefitsByValueCategory(valueCategoryId: string | number, solutionId: string | number) {
    this.subscriptionreadBenefitsByValueCategory = this.solutionService.readBenefitsByValueCategory(String(valueCategoryId), +solutionId).subscribe((response) => {
      this.currentBenefit = response.result;
      this.benefitLoader = true;
    });
  }

  backClicked() {
    this.location.back();
  }
}
