export interface SequencePayload {
  elements: SequenceElement[];
}

export interface SequenceResPayload {
  message: SequenceMessage | string;
  status_code: number;
}

export interface SequenceMessage {
  invalid_elements: string;
  updated_elements: string;
}

export interface SequenceElement {
  id: string;
  sequence: string;
}

export enum SequencePage {
  Benefits = 'benefits',
  ValueCategories = 'value_categories',
  FactorGroups = 'factor_groups',
  Factors = 'factors',
  OperationalObjectives = 'operational_objectives',
  FunctionalObjectives = 'functional_objectives',
  PainPoints = 'pain_points',
  Capabilities = 'capabilities',
  Assets = 'account_asset_templates',
  DiscoveryQuestions = 'discovery_question',
  Costs = 'costs',
  Models = 'models',
  ModelCategories = 'model_categories',
  AccountWorkflows = 'account_workflow',
  Situations = 'situation_types',
  GoalGroups = 'goal_groups',
  Goals = 'goals',
}
