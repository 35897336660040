<div class='row'>
	<div class='col-xs-12'>
		<h3>{{trans.trans.add_a_company.value}}
			<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.add_a_company" (callback)="getTranslations()"></app-editTranslation>
		</h3>
	</div>
</div>
<div style='overflow-y: scroll; height: 100%; padding-bottom: 100px;'>
	<form name="addCompanyForm" class="col-xs-12" #addCompanyForm="ngForm" (ngSubmit)="addCompany()">
		<p-fieldset id='required' [legend]="trans.trans.required.value">
			<div class="row">
				<div class="col-xs-12">
					<label class="labelsRequired">{{trans.trans.name.value}}</label>
					<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
					<input type="text" class="form-control" id="name" [(ngModel)]="company.name" name="name" placeholder="" required>
					<i class="fa fa-search name-search" matTooltip="add_new" matTooltipPosition="above" (click)="searchWithScraper()"></i>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label class="labelsRequired">{{trans.trans.industry_sector.value}}</label>
					<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.industry_sector" (callback)="getTranslations()"></app-editTranslation>
					<select (change)="getIndustryBySectors(company.industry_sector_id)" class="form-control" name="industryCategory" required [(ngModel)]="company.industry_sector_id">
						<option value="" disabled="disabled">{{trans.trans.select_industry_sector.value}}</option>
						<option *ngFor="let sector of industrySectors" [id]="sector.id" [ngValue]="sector.id">
							{{ sector.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label class="labelsRequired">
						{{trans.trans.industry.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.industry" (callback)="getTranslations()"></app-editTranslation>
					</label>
					<img class="industryloader loader" [src]="fullImagePath" *ngIf="industriesLoader" />

					<select [disabled]="industriesShow" class="form-control" name="industry" required [(ngModel)]="company.industry_id">
						<option value="" disabled="disabled">{{trans.trans.select_industry.value}}</option>
						<option *ngFor="let industry of industries" [id]="industry.id" [ngValue]="industry.id">
							{{ industry.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label class="labelsRequired">{{trans.trans.country.value}}</label>
					<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.country" (callback)="getTranslations()"></app-editTranslation>
					<select class="form-control" name="country" required [(ngModel)]="countryDetails" (change)="getStatesByCountries(countryDetails)">
						<option value="" disabled="disabled">{{trans.trans.select_country.value}}</option>
						<option *ngFor="let country of countries" [id]="country.id" [ngValue]="country">
							{{ country.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label class="labelsRequired">{{trans.trans.revenue_millions.value}}</label>
					<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.revenue_millions" (callback)="getTranslations()"></app-editTranslation>
					<input type="number" class="form-control" id="revenue" [(ngModel)]="company.net_sales" name="revenue" placeholder="" required>
				</div>
			</div>
		</p-fieldset>
		<p-fieldset [toggleable]="true" [collapsed]='true' [legend]='trans.trans.additional_info.value'> 

			<div class="row">
				<div class="col-xs-12">
					<label>{{trans.trans.employees.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>
					</label>
					<input type="number" class="form-control" id="employees" [(ngModel)]="company.employees" name="employees" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label>{{trans.trans.address.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.address" (callback)="getTranslations()"></app-editTranslation>
					</label>
					<input type="text" class="form-control" id="address" [(ngModel)]="company.address1" name="address" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label>{{trans.trans.city.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.city" (callback)="getTranslations()"></app-editTranslation>
					</label>
					<input type="text" class="form-control" id="city" [(ngModel)]="company.city" name="city" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label for="name">{{trans.trans.state.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.state" (callback)="getTranslations()"></app-editTranslation>
					</label>
					<select [disabled]="statesLoader" class="form-control" name="state" [(ngModel)]="company.state">
						<option value="" disabled="disabled">{{trans.trans.select_state.value}}</option>
						<option *ngFor="let state of states" [id]="state.id" [ngValue]="state.abbreviation">
							{{ state.name }}
						</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<label>{{trans.trans.postal_code.value}}</label>
					<app-editTranslation *ngIf="showTranslate" [component]="'add-company'" [isTranslationV2]="true" [transObj]="trans.trans.postal_code" (callback)="getTranslations()"></app-editTranslation>
					<input type="text" class="form-control" id="zipcode" [(ngModel)]="company.zip" name="zipcode" placeholder="">
				</div>
			</div>
		</p-fieldset>
	</form>
	<div class='row'>
		<div class='col-xs-12'>
			<button type="submit" *ngIf="!addCompanyLoader" [disabled]="addCompanyForm.invalid" class="btn btn-success" (click)="addCompany()">
				{{trans.trans.save.value}}
			</button>
			<img *ngIf="addCompanyLoader" class="loader" [src]="fullImagePath" />
		</div>
	</div>
</div>