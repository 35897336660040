import { Injectable } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import { ValueGroup, ValueGroupUpdatePayload } from '@shared/models/group.model';
import { AvailableValueProp, CreateValueGroupPayload, ValueGroupsResponse, ValueGroupTemplate, ValueProp } from '@shared/models/value-prop.model';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';


@Injectable()
export class VGPService {
	constructor(private CommonService: CommonService) { }

	public getVPGGroupList(uid: string, treeview = 0, limit?: string): Observable<APIResult<ValueGroupsResponse>>  {
		let endpoint = "valueProps/valuePropGroups/" + uid + "?ngtree=" + treeview + (limit ? limit : "");
		return this.CommonService.getAPIService(endpoint);
	}

	public getVPGGroupListArray(uid: string) {
		let endpoint = "valueProps/valuePropGroups/" + uid;
		return this.CommonService.getAPIService(endpoint);
	}

	public getVPGGroup(id) {
		let endpoint = "valueProps/valuePropGroup/" + id;
		return this.CommonService.getAPIService(endpoint);
	}

	public getVPGGroupCompare(group_id, payload) {
		let endpoint = "valueProps/valuePropGroupCompare/" + group_id;
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getFullVpValuePositionRealizationList(): Observable<APIResult<{ available_value_props: AvailableValueProp[]}>> {
		let endpoint = "valueProps/availableValueProps";
		return this.CommonService.getAPIService(endpoint);
	}

	public postCreateVPG(payload: CreateValueGroupPayload): Observable<APIResult<{ value_prop_group: ValueProp}>> {
		let endpoint = "valueProps/valuePropGroup/create";
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public putRenameVPG(payload, id) {
		let endpoint = "valueProps/name/update/" + id;
		return this.CommonService.putAPIService(endpoint, payload);
	}

    public updateValuePropGroup(payload: ValueGroupUpdatePayload, value_prop_group_id: number) {
        const endpoint = `valueProps/valuePropGroup/${value_prop_group_id}`
        return this.CommonService.putAPIService(endpoint, payload);
    }

	public removeVPfromVPG(vpid, vpgid) {
		let endpoint = "valueProps/valuePropGroupMember/" + vpgid + "/valuePropId/" + vpid;
		return this.CommonService.deleteAPIService(endpoint);
	}

	public addVPtoVPG(vpgid: string | number, payload: { members: string[] }): Observable<APIResult<ValueGroup>> {
		let endpoint = "valueProps/valuePropGroupMember/" + vpgid + "/rebuild/1";
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public addSingleVPtoVPG(vpgid: string | number, payload: { members: string[] }): Observable<APIResult<ValueGroup>> {
		let endpoint = "valueProps/valuePropGroupMember/" + vpgid + "/rebuild/0";
		return this.CommonService.postAPIService(endpoint, payload);
	}

	public getVPGAssets(vpg_id: string | number): Observable<APIResult<ValueGroupTemplate[]>> {
		let endpoint = "asset/valuePropGroupTemplates/" + vpg_id;
		return this.CommonService.getAPIService(endpoint);
	}

	public deleteVPG(vpg_id) {
		let endpoint = "valueProps/valuePropGroup/" + vpg_id;
		return this.CommonService.deleteAPIService(endpoint);
	}
}