import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { VmwareComponent } from './vmware.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { AddCompanyModule } from 'app/scb/add-company/add-company.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Routes, RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';


export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}


const routes: Routes = [
	{
		path: 'vmware/discovery',
		component: VmwareComponent
	}
];
@NgModule({
	imports: [
		RouterModule.forChild(routes),
		CommonModule,
		NgbModule,
    MatTooltipModule,
		FormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AddCompanyModule
	],
	declarations: [
		VmwareComponent
	],
	providers: [],
	exports: [VmwareComponent]
})
export class VMWareModule { }
