import { Component, OnDestroy, OnInit } from '@angular/core';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { StyleService } from 'app/style.service';
import { Subscription } from 'rxjs';
import { ApiServiceService } from '../../api-service.service';

@Component({
  selector: 'app-valuemaps',
  templateUrl: './valuemaps.component.html',
  styleUrls: ['./valuemaps.component.scss'],
  providers: [ApiServiceService],
})
export class ValuemapsComponent implements OnInit, OnDestroy {
  subscriptionValidateUser: Subscription;

  constructor(private apiServiceService: ApiServiceService, private translateService: TranslateService, public trans: Translations, private styleService: StyleService) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  isChartActive = true;
  isCategoryActive = false;
  isListActive = false;

  ngOnInit() {
    const loginDetails = {
      action: 'Validate',
      loginPassword: 'demo',
      loginUsername: 'vadivel@test.com',
    };

    if (sessionStorage.getItem('vcu') === null) {
      this.subscriptionValidateUser = this.apiServiceService.validateUser(loginDetails).subscribe((result) => {
        sessionStorage.setItem('uid', result.user_id);
        sessionStorage.setItem('vcu', result.token);
        sessionStorage.setItem('aid', result.account_id);
        sessionStorage.setItem('features', result.features);
        this.styleService.updateStyle2022();
        sessionStorage.setItem('rid', result.role_type_id);
        sessionStorage.setItem('utype', result.user_type_id);
        sessionStorage.setItem('logo', result.logo_filepath);
      });
    }
  }

  ngOnDestroy() {
    if (this.subscriptionValidateUser) {
      this.subscriptionValidateUser.unsubscribe();
    }
  }
}
