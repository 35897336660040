import { Component, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { GoalGroupsService } from '@data/services/goal-groups/goal-groups.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { map, switchMap, takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TrackerBaselineViewTranslation } from './tracker-baseline-view.translation';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { Company } from '@shared/models/company.model';
import { NotificationService } from '@services/notification.service';
import { CompanyService } from '@data/services/company/company.service';
import { UserService } from '@data/services/user/user.service';
import { Observable } from 'rxjs';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { RealizedFilter } from '@data/services/valuerealization/models/value-realized-company.model';
import { ValueTracker } from '@data/services/valuerealization/models/value-tracker.model';
import { ValueProp } from '@shared/models/value-prop.model';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';

@Component({
  selector: 'app-tracker-baselines',
  templateUrl: './tracker-baseline-view.component.html',
  styleUrls: ['./tracker-baseline-view.component.scss'],
})
export class TrackerBaselineViewComponent implements OnInit {
  companyId: string;
  trackerId: string;
  breadcrumbKeyValues: Breadcrumbs;
  companyDetails: Company;
  userName$: Observable<string>;
  trackerInfo: ValueTracker;
  stepIndex = 0;
  ngUnsubscribe = new Subject();
  valuePropId: string;
  valueProp: ValueProp;
  changeVPName = false;
  showFinishModal = false;
  isLoading = false;

  constructor(
    public trans: TrackerBaselineViewTranslation,
    private translationService: TranslationsV2Service,
    private goalGroupsService: GoalGroupsService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private userService: UserService,
    private valueRealizationService: ValuerealizationService,
    private valuePropService: ValuepropService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.companyId = this.route.snapshot.paramMap.get('companyId');
    this.trackerId = this.route.snapshot.paramMap.get('trackerId');
    this.getCompanyDetail(this.companyId);
    this.userName$ = this.userService.user.pipe(map((user) => (user ? user.first_name + ' ' + user.last_name : null)));
    if (this.trackerId) {
      this.nextStep(this.trackerId);
      this.getTrackerDetails();
    }
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        let name;
        if (this.breadcrumbKeyValues) {
          const crumbs = Object.keys(this.breadcrumbKeyValues);
          name = crumbs[crumbs.length - 1];
        }

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.valueRealized.value]: { routerLink: '/valuerealized', active: false },
          [this.trans.trans.addNew.value]: { routerLink: null, active: false },
        };

        if (name) {
          this.breadcrumbKeyValues = {
            ...this.breadcrumbKeyValues,
            [name]: { routerLink: null, active: true },
          };
        }
      });
  }

  getCompanyDetail(id: string): void {
    this.companyService
      .getCompanySummary(+id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result) => {
          if (result.result) {
            this.companyDetails = result.result.company;
            this.breadcrumbKeyValues = {
              ...this.breadcrumbKeyValues,
              [this.companyDetails.name]: { routerLink: null, active: true },
            };
          }
        },
        (error) => {
          this.notificationService.error(error.result, false);
        }
      );
  }

  getValuePropDetails() {
    this.valuePropService
      .getValuePropDetail(this.valuePropId.toString())
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.valueProp = response.result.value_prop;
        }
      });
  }

  cancelChangeName(): void {
    this.changeVPName = false;
  }

  hideVPName(event: boolean): void {
    this.changeVPName = event;
  }

  submitChangeName(newName: string): void {
    this.valuePropService.updateValueProp(this.valueProp.id, { name: newName }).subscribe(() => {
      this.valueProp = { ...this.valueProp, name: newName };
      this.changeVPName = false;
      this.notificationService.success(this.trans.trans.nameUpdateSuccess.value, false);
    });
  }

  nextStep(valuePropId = null) {
    this.stepIndex += 1;

    if (valuePropId && !this.valueProp) {
      this.valuePropId = valuePropId;
      this.getValuePropDetails();
    }
  }

  moveBack() {
    this.stepIndex -= 1;
  }

  cancel() {
    this.router.navigate(['valuerealized']);
  }

  finish(): void {
    this.showFinishModal = true;
  }

  navigateToAllTrackers() {
    this.showFinishModal = false;

    this.valueRealizationService.getBusinessReviewData(this.valuePropId).subscribe(response => {
      let vr_reviews = response;

      if ( vr_reviews.length === 0 ) {
        // No Business Reviews created, create one
        this.goalGroupsService.saveBusinessReview(this.valuePropId, { name: null }).subscribe(({ result }) => {
          this.showFinishModal = false;
          this.router.navigate([`valuerealized/company/${this.companyId}/trackers`]);
        });
      } else {
        // Already has business reviews, go to tracker
        this.router.navigate([`valuerealized/company/${this.companyId}/trackers`]);
      }
    });
  }

  navigateToThisTracker() {
    this.isLoading = true;
    this.valueRealizationService.getBusinessReviewData(this.valuePropId).subscribe(response => {
      let vr_reviews = response;

      if ( vr_reviews.length === 0 ) {
        // No Business Reviews created, create one
        this.goalGroupsService.saveBusinessReview(this.valuePropId, { name: null }).subscribe(({ result }) => {
          this.showFinishModal = false;
          this.router.navigate([`valuerealized/company/${this.companyId}/trackers/${this.valuePropId}`]);
        });
      } else {
        // Already has business reviews, go to tracker
        this.router.navigate([`valuerealized/company/${this.companyId}/trackers/${this.valuePropId}`]);
      }
    });
  }

  private getTrackerDetails() {
    this.valueRealizationService.getTrackers(Number(this.companyId), RealizedFilter.all).subscribe(({ result }) => {
      this.trackerInfo = result.trackers.find((tracker) => Number(tracker.id) === Number(this.trackerId));
    });
  }
}
