import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Translations } from '@data/services/translation/translation.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader } from '@ngx-translate/core';
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { QuoteComponent } from './quote.component';
import { CasstudyQuoteTranslations } from './quote.translation';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  declarations: [QuoteComponent],
  imports: [NgbModule, InputMaskModule, FormsModule, CommonModule, TabViewModule, MatTooltipModule],
  providers: [Translations, TranslationsV2Service, CasstudyQuoteTranslations],
  entryComponents: [QuoteComponent],
  exports: [QuoteComponent],
})
export class ModelQuoteModule {}
