import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NameInitialsPipe } from './name-initials.pipe';

@NgModule({
  declarations: [NameInitialsPipe],
  imports: [CommonModule],
  exports: [NameInitialsPipe],
})
export class NameInitialsModule {}
