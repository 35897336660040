import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FactsService } from '@data/services/facts/facts.service';
import { CompanyService } from '@data/services/company/company.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Translations } from '@data/services/translation/translation.service';
import { Company } from '@shared/models/company.model';


@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
  providers: [CompanyService],
})
export class AddCompanyComponent implements OnInit, OnDestroy {
  @Input() modalCreate: boolean;
  @Output() companyCreated: EventEmitter<{ [klass: string]: any }> = new EventEmitter();
  @Output() enableSave: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('addCompanyForm', { static: true }) addCompanyForm;

  name: string;
  content: string;
  company: Partial<Company>;
  industrySectors: { [klass: string]: any }[];
  countries: { [klass: string]: any }[];
  industries: { [klass: string]: any }[];
  industriesShow = true;
  industriesLoader = false;
  statesLoader = true;
  states: { [klass: string]: any }[];
  addCompanyLoader: boolean;
  fullImagePath: string;
  countryDetails: { id: string; name: string; };
  contextualHelp: { [klass: string]: any };
  scraperCompany: { [klass: string]: any };

  subscriptionstatusChanges: Subscription;
  subscriptioncontextualHelp: Subscription;
  subscriptiongetSectors: Subscription;
  subscriptiongetCountries: Subscription;
  subscriptiongetStatesByCountries: Subscription;
  subscriptiongetIndustriesBySector: Subscription;
  subscriptionaddCompany: Subscription;
  subscriptionsearchWithScraper: Subscription;

  constructor(
    private factsService: FactsService,
    private companyService: CompanyService,
    private location: Location,
    private router: Router,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);

    const imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = imageUrl + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.countryDetails = null;
    this.company = {
      name: '',
      industry_sector_id: '',
      industry_id: '',
      address1: '',
      city: '',
      state: '',
      country_id: '',
      employees: '',
      net_sales: 0,
      zip: '',
      country: '',
    };
    this.subscriptionstatusChanges = this.addCompanyForm.statusChanges.subscribe((data: string) => {
      if (data === 'VALID') {
        this.enableSave.emit(true);
      } else {
        this.enableSave.emit(false);
      }
    });
    this.getIndustrySectors();
    this.getCountries();
    this.getContextualHelp();
  }

  ngOnDestroy(): void {
    if (this.subscriptionstatusChanges) {
      this.subscriptionstatusChanges.unsubscribe();
    }
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptiongetSectors) {
      this.subscriptiongetSectors.unsubscribe();
    }
    if (this.subscriptiongetCountries) {
      this.subscriptiongetCountries.unsubscribe();
    }
    if (this.subscriptiongetStatesByCountries) {
      this.subscriptiongetStatesByCountries.unsubscribe();
    }
    if (this.subscriptiongetIndustriesBySector) {
      this.subscriptiongetIndustriesBySector.unsubscribe();
    }
    if (this.subscriptionaddCompany) {
      this.subscriptionaddCompany.unsubscribe();
    }
    if (this.subscriptionsearchWithScraper) {
      this.subscriptionsearchWithScraper.unsubscribe();
    }
  }

  getContextualHelp(): void {
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.subscribe((response) => {
      this.contextualHelp = response['Valueprop'];
    });
  }
  getIndustrySectors(): void {
    this.subscriptiongetSectors = this.factsService.getSectors().subscribe((response) => {
      this.industrySectors = response.result;
    });
  }
  getCountries(): void {
    this.subscriptiongetCountries = this.factsService.getCountries().subscribe((response) => {
      this.countries = response.result.countries;
    });
  }
  getStatesByCountries(country: { id: string; name: string }): void {
    this.company.country_id = country.id;
    this.company.country = country.name;
    this.statesLoader = true;
    this.subscriptiongetStatesByCountries = this.factsService.getStatesByCountries(country.id).subscribe((response) => {
      this.statesLoader = false;
      this.states = response.result.states;
    });
  }
  getIndustryBySectors(sectorId: string | number): void {
    this.industriesLoader = this.industriesShow = true;
    this.subscriptiongetIndustriesBySector = this.factsService.getIndustriesBySector(sectorId).subscribe((response) => {
      this.industriesLoader = this.industriesShow = false;
      this.industries = response.result.dl_industries;
    });
  }
  addCompany(): void {
    this.addCompanyLoader = true;
    this.subscriptionaddCompany = this.companyService.addCompany(this.company).subscribe((response) => {
      this.addCompanyLoader = false;
      if (response.result && response.result.id && !this.modalCreate) {
        this.router.navigate(['company/' + response.result.id]);
      } else if (response.result && response.result.id && this.modalCreate) {
        this.companyCreated.emit(response.result);
        // tslint:disable-next-line: triple-equals
      } else if (response.result && 'success' in response.result && response.result.success == false) {
        this.notificationService.error(response.result.message, false);
      }
    });
  }

  backClicked(): void {
    this.location.back();
  }

  searchWithScraper(): void {
    const payload = {
      companyName: this.company.name,
    };

    this.subscriptionsearchWithScraper = this.companyService.searchWithScraper(payload).subscribe((response) => {
      this.scraperCompany = response.result;
    });
  }
}
