import { Injectable } from '@angular/core';

@Injectable ()

export class ForgotPasswordTranslations {

    public config: any = {
        component: 'forgotPassword',
        display_name: 'Forgot Password',
        description: 'Reset password request when forgot password'
    };

    public trans: any = {
        decisionlink: 'Xfactor.io',
        valueCloudDecisionlink: 'Value Execution by Xfactor.io',
        emailAddress: 'Email Address',
        resetPassword: 'Reset Password',
        forgot_your_password_enter_your_email_address: 'Forgot Your Password? Enter your email address',
		login: 'Login'
    };
}
