import { Injectable } from '@angular/core';

@Injectable()
export class BenefitsTranslations {
  public config: any = {
    component: 'benefits',
    display_name: 'Solution Benefits',
    description: 'All the benefits in a solution',
  };

  public trans: any = {
    accrual: 'Accrual',
    addNew: 'Add New',
    aggressive: 'Aggressive',
    areYouSure: 'Are you sure?',
    backToBenefits: 'Back to benefits',
    benefitAttributes: 'Benefit Attributes',
    benefits: 'Benefits',
    collapseAll: 'Collapse All',
    conservative: 'Conservative',
    created: 'Created',
    current_effort: 'Current Effort',
    defaultOn: 'On by default',
    defaultOnToggle: 'On by default',
    defaultOff: 'Off by default',
    defaultOffToggle: 'N/A by default',
    delete: 'Delete',
    differentiated: 'Differentiated',
    driver_baseline_answer: 'Driver Answer',
    driver_factor: 'Driver Factor',
    edit: 'Edit',
    edit_translations: 'Edit Translations',
    expandAll: 'Expand All',
    files: 'Files',
    financial_baseline_answer: 'Financial Factor Answer',
    financial_factor: 'Financial Factor',
    impact_type: 'Impact Type',
    improvement_factor_name: 'Improvement Factor',
    metric_name: 'Benefit Name',
    modified: 'Modified',
    probable: 'Probable',
    search: 'Search',
    tco_name: 'TCO Name',
    unique_type: 'Unique Type',
    units: 'Units',
    value_statement: 'Value Statement',
    view: 'View',
    edit_benefit_subtypes: 'Edit Benefit Subtypes',
    enter_types: 'Enter the different types of',
    covers: 'this covers',
    add: 'Add',
    subtype: 'Subtype',
    value: 'Value',
    improvement: 'Improvement',
    enter_name: 'Enter name',
    driver_value: 'Driver Value',
    improvement_value: 'Improvement Value',
    are_you_sure_delete: 'Are you sure you want to delete all subtypes?',
    back: 'Back',
    delete_all: 'Delete All',
    save: 'Save',
    benefitSelected: 'Benefit(s) Selected',
    cancel: 'Cancel',
    clone: 'Clone',
    move: 'Move',
    cloneBenefit: 'Clone Benefit',
    benefitName: 'Benefit Name',
    benefitsName: 'Benefits Name',
    cloneTo: 'Select Model that benefits should be cloned into:',
    benefitsClonedSuccess: 'Benefit(s) cloned successfully',
    benefitsCloneError: 'There was a problem while cloning the benefit(s)',
    areYouSureBenefits: 'Are you sure you want to delete the selected benefits?',
    partialDelete: 'There was an error deleting the following benefits:',
  };
}
