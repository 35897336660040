import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Subject } from 'rxjs';


@Injectable()
export class GlobalSearchService {
	public notifySearch = new Subject<any>();

	constructor(
		private CommonService: CommonService
	) { }

	public getGlobalSearch(payload) {
		let endpoint = "search";
		return this.CommonService.postAPIService(endpoint, payload);
	}
}