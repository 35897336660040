export interface AssetTags {
  tags: Tag[];
}

export interface Tag {
  id?: string;
  active?: boolean | string;
  name: string;
  description: string;
  tag?: string;
  metadata: TagMetadata;
}

export interface TagMetadata {
  tag?: string;
  array_name: string;
  id?: string;
  field?: string;
  format_type?: string;
  type?: string;
  additional_tags?: Array<{ field: string; format_type?: string }>;
}

export interface CreateCostTagData {
  costId: string;
  tagId: string;
  name: string;
  value: string;
  description?: string;
}

export interface TagData {
  account_id: string;
  created: string;
  description: string;
  id: string;
  metadata: TagMetadata;
  modified: string;
  name: string;
  tag: string;
}