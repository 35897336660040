import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { TcoComponent } from './tco.component';
import { TcoTablesTranslations } from './tco.translation';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    EditTranslationsModule,
    ApplicationPipesModule,
    ProgressBarModule,
  ],
  providers: [TcoTablesTranslations],
  declarations: [TcoComponent],
  exports: [TcoComponent],
})
export class TCOModule {}
