<div class="models_panel">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10"></div>
        <div class="col-xs-2">
          <button class="btn btn_customizable pull-right" (click)="funcOpenAddDeliverable()"><i class="fa fa-plus"></i>{{ trans.trans.add.value }}</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="table-responsive col-sm-12 costs-table" style="padding: 0px">
          <table id="deliverables" class="table custom-table designer-table">
            <thead>
              <tr>
                <th>
                  {{ trans.trans.name.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    (callback)="refreshTranslation()"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.name"
                    key="vp_dashboard.deliverable.name"
                  ></app-editTranslation>
                </th>
                <th style="width: 120px; white-space: nowrap"></th>
              </tr>
            </thead>
            <tbody *ngIf="deliverables && deliverables.length">
              <tr *ngFor="let d of deliverables">
                <td>
                  <a href="{{ assetUrl }}{{ d.location }}">{{ d.name }}</a>
                </td>
                <td></td>
                <td class="">
                  <app-ellipsis-menu [horizontal]="true">
                    <ul class="list-group">
                      <li (click)="funcOpenEditDeliverable(d)" class="list-group-item"><i class="icon-pencil"></i> {{ trans.trans.edit.value }}</li>
                      <li (click)="funcDeleteDeliverable(d.id)" class="list-group-item"><i class="icon-trash"></i> {{ trans.trans.delete.value }}</li>
                    </ul>
                  </app-ellipsis-menu>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!deliverables.length">
              <tr>
                <td>{{ trans.trans.no_deliverables_available.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="uploadToggle || editToggle" position="right" (onHide)="onSidebarClose()" [modal]="false" styleClass="keywest_sidebar p-sidebar-lg">
  <form name="FinalDeliverableForm" #FinalDeliverableForm="ngForm">
    <div class="sidebar_wrapper">
      <div class="sidebar_header" *ngIf="uploadToggle">
        {{ trans.trans.upload_flyout_title.value }}
      </div>
      <div class="sidebar_header" *ngIf="editToggle">
        {{ trans.trans.edit_flyout_title.value }}
      </div>

      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-xs-12">
              {{ trans.trans.file.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="refreshTranslation()"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.file"
                key="vp_dashboard.deliverable.file"
              ></app-editTranslation>
              <div *ngIf="editToggle && !file">
                <a href="{{ assetUrl }}{{ file_param.location }}">{{ file_param.name }}</a>
              </div>
              <input #fileInput type="file" name="file" id="fileUploaded" accept=".xls, .xlsx, .ppt, .doc, .docs, .docx, .pptx" (change)="handleInputChange($event)" required />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              {{ trans.trans.filename.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="refreshTranslation()"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.filename"
                key="vp_dashboard.deliverable.filename"
              ></app-editTranslation>
              <input type="text" name="filename" [(ngModel)]="file_param.name" class="form-control" />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              {{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="refreshTranslation()"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.description"
                key="vp_dashboard.deliverable.description"
              ></app-editTranslation>
              <textarea name="description" class="form-control" [(ngModel)]="file_param.description" rows="1">{{ file_param.description }}</textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_container">
        <a (click)="editToggle ? funcUpdateDeliverable() : funcUploadDeliverable()" class="btn btn_customizable pull-right"
          ><i class="fa fa-cloud-upload"></i>
          <span *ngIf="editToggle">
            {{ trans.trans.edit.value }}
          </span>
          <span *ngIf="!editToggle">
            {{ trans.trans.upload.value }}
          </span>
        </a>
      </div>
    </div>
  </form>
</p-sidebar>
