<p-sidebar [(visible)]="sidebarOpen" (onHide)="onHide()" position="right" styleClass="p-sidebar-lg">
    <h3 class="workflow-title">{{ workflowTitle }}</h3>
    <p *ngIf="workflowSubTitle" class="workflow-subtitle"><em>{{ workflowSubTitle }} [{{workflowName.value}}]</em></p>
    <hr />

    <form class="wf-form" [formGroup]="createWorkflowForm">
      <div class="workflow-row">
        <div class="field">
          <h5>{{ trans.trans.workflowName.value}}:</h5>
          <input type="text" pInputText class="form-control" formControlName="workflowName" autocomplete="off" />
        </div>
        <div class="field status">
          <h5>{{ trans.trans.status.value }}:</h5>
          <div class="switch">
            <p-inputSwitch formControlName="status"></p-inputSwitch>
            <small class="switch-text">{{ status.value ? trans.trans.enabled.value : trans.trans.disabled.value }}</small>
          </div>
        </div>
        <div class="field workflow-icon" [hidden]="true">
          <h5>{{ trans.trans.workflowIcon.value}}:</h5>
        </div>
      </div>

      <div class="workflow-row">
        <div class="field">
          <h5>{{ trans.trans.userGroupsAvailable.value }}:</h5>
          <div class="switch my">
            <p-inputSwitch formControlName="allUsers"></p-inputSwitch>
            <small class="switch-text">{{allUsers.value ? trans.trans.allUsers.value : trans.trans.userGroups.value}}</small>
          </div>
          <div *ngIf="!allUsers.value">
            <p-multiSelect class="group-select" [options]="workflowUserGroups" formControlName="selectedUserGroups" optionLabel="name"></p-multiSelect>
          </div>
        </div>
      </div>

      <div class="workflow-row">
        <div class="field">
          <h5>{{ trans.trans.description.value }}:</h5>
          <textarea [rows]="5" formControlName="description" pInputTextarea></textarea>
          </div>
      </div>

      <div class="workflow-row">
        <p-table 
        [value]="workflowSections" 
        [columns]="cols" 
        [(selection)]="selectedSections"
        styleClass="keywest_table"
        tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
              <tr>
                  <th style="width:5rem"></th>
                  <th *ngFor="let col of columns">
                      {{col.header}}
                  </th>
                  <th>
                    {{ trans.trans.defaultStep.value }}
                  </th>
                  <!-- <th style="width:5rem"></th> Keep for next release -->
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
              <tr>
                  <td [ngClass]="{'first': (index === 0 || workflowSelected?.system_defined === '1')}">
                    <p-tableCheckbox [value]="workflowSections[index]"></p-tableCheckbox>
                  </td>
                  <td *ngFor="let col of columns">
                      {{rowData[col.field]}}
                  </td>
                  <td>
                    <input
                      name="default_member" 
                      type="radio"
                      [checked]="workflowSections[index].is_default_member"
                      [attr.disabled]="radioDisabled(workflowSections[index].member_id)"
                      (change)="radioClicked(workflowSections[index].member_id)"
                    />
                  </td>
              </tr>
          </ng-template>
      </p-table>
      </div>

      <div  class="workflow-row">
        <div class="field">
          <h5>{{ trans.trans.assets.value }}:</h5>
          <div class="switch my">
            <p-inputSwitch formControlName="allTemplates"></p-inputSwitch>
            <small class="switch-text">{{allTemplates.value ? trans.trans.allTemplates.value : trans.trans.specificTemplates.value}}</small>
          </div>
          <div class="select-wrapper" *ngIf="!allTemplates.value">
            <p-multiSelect class="group-select" [options]="workflowAssets" formControlName="selectedAssets" optionLabel="name"></p-multiSelect>
          </div>
        </div>
      </div>
    
    </form>
    <div class="button-wrapper">
      <div class="row">
        <div class="col-xs-12">
          <button type="submit" class="btn btn_customizable pull-right xf-continue-button" (click)="onButtonClick()" [disabled]="createWorkflowForm.invalid">{{ trans.trans.save.value }}</button>
          <button type="submit" class="btn cancel-confirm pull-right" (click)="onCancelClick()">{{ trans.trans.cancel.value }}</button>
        </div>
      </div>
    </div>
    
</p-sidebar>
  