import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';

@Component({
	selector: 'app-benefits-mini',
	templateUrl: './mini.component.html',
	styleUrls: ['./mini.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BenefitsMiniComponent implements OnInit, OnDestroy {
	@Input() benefits: any[] = [];

	constructor(
		private valuePropService: ValuepropService,
		private translateService: TranslateService,
		public trans: Translations
	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
	}

	ngOnInit() {

	}

	ngOnDestroy() {

	}

	gotoBenefit(event) {
		this.valuePropService.gotoMenuItem.next({ panel: "vpdashboardMenuBottom", menu: "benefit_detail", data: event })
	}

	getBenefits(benefitStatus) {
		let out = benefitStatus.active_type;
		if (!out) { out = benefitStatus.active; }
		benefitStatus = out;

		switch (benefitStatus) {
			case '1':
				return 'green';
			case 'Not a Priority':
				return 'violet';
			case '0':
				return 'red';
			case '4':
				return 'red';
			case '2':
				return 'orange';
			case '3':
				return 'orange';
		}
	}
}