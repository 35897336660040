<p-table
  *ngIf="businessReviewsWithNotes.length > 0"
  [value]="businessReviewsWithNotes"
  responsiveLayout="scroll"
  [paginator]="businessReviewsWithNotes.length > 10"
  [rows]="10"
  styleClass="keywest_table"
  tableStyleClass="keywest_table"
  [scrollable]="true"
  scrollHeight="800px"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 15rem" pSortableColumn="name">{{ trans.trans.periodName.value }}<p-sortIcon field="name"></p-sortIcon></th>
      <th>{{ trans.trans.notes.value }}</th>
      <th style="width: 3rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-businessReview>
    <tr>
      <td style="width: 15rem" (click)="setEditNote(businessReview)" [class.br-edit]="!disableEdit">
        <span *ngIf="!disableEdit" [matTooltip]="trans.trans.editNote.value">{{ businessReview.name }}</span>
        <span *ngIf="disableEdit">{{ businessReview.name }}</span>
      </td>
      <td style="overflow-wrap: break-word">{{ businessReview.note }}</td>
      <td style="width: 4rem">
        <app-are-you-sure
          *ngIf="canEdit"
          text=""
          [tooltip]="trans.trans.delete.value"
          [message]="trans.trans.areYouSure.value"
          icon="fa fa-trash"
          (callbackYes)="deleteNote(businessReview.noteId)"
        ></app-are-you-sure>
      </td>
    </tr>
  </ng-template>
</p-table>
