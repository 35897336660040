import { Injectable } from '@angular/core';

@Injectable()
export class TrackerBaselineTranslation {
  public config: any = {
    component: 'tracker-baseline',
    display_name: 'Create Tracker Baseline',
    description: 'Create a new tracker baseline in value realized',
  };
  public trans: any = {
    dateToBegin: 'Date to begin measuring',
    name: 'Name',
    goLiveDate: 'Go Live Date',
    totalBenefits: 'Total Benefits',
    cancel: 'Cancel',
    back: 'Back',
    next: 'Next',
    selectValuePosition: 'Select Value Proposition to create a new Value Tracker',
    vpTooltip: 'Indicate the Value Proposition that you want to use as the baseline for your Value Tracker',
    createTrackerBaseline: 'Create Tracker Baseline',
    incrementType: 'Increment Type',
    createTracker: 'Create Tracker',
    explaination: 'Indicate the Value Propositions that you want to use as the baseline for your Value Tracker',
    vpName: 'Value Proposition Name',
    createdBy: 'Created By',
    createdDate: 'Created Date',
    totalCosts: 'Total Costs',
    status: 'Status',
    lastModifiedBy: 'Last Modified By',
    lastModifiedDate: 'Last Modified Date',
    products: 'Product(s)',
    multiple: 'MULTIPLE',
    noRecords: 'Value Trackers require a valid Value Proposition to exist.  No valid Value Propositions exist for this account.  To resolve you can create a new value proposition within Value Execution OR if you are operating within your CRM, you can create a new value proposition from a valid opportunity for this account'
  };
}
