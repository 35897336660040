import { NgModule } from '@angular/core';

import { AECompetitorsComponent } from "./ae_competitors.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AECompetitorTranslation } from './ae_competitors.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';

@NgModule({
	declarations: [
		AECompetitorsComponent
	],
	imports: [
		SidebarModule,
		RouterModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		ReactiveFormsModule,
		EditTranslationsModule,
		AreYouSureModule
	],
	providers: [
		TranslationsV2Service,
		AECompetitorTranslation
	],
	entryComponents: [
		AECompetitorsComponent,
	],
	exports: [AECompetitorsComponent]
})
export class AECompetitorsModule { }