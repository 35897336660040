import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SFDCService {
  constructor(private http: HttpClient) {}

  configHeaders(endPointValue, cuie1, dlke2) {
    let endpoint = endPointValue;

    let header = {
      'crm-user-id': cuie1,
      'dl-key': dlke2,
      'Content-Type': ' application/json',
    };

		let headers = new HttpHeaders(header);
		return { endpoint: endpoint, headers };
	}

	postAPIService(endPointValue, cuie1, dlke2) {
		let option: {
			endpoint: string;
			headers: HttpHeaders
		};
		option = this.configHeaders(endPointValue, cuie1, dlke2);

		return this.http.post(option.endpoint, {}, { headers: option.headers });
	}

	public getSFDC(cuie1, dlke2): Observable<{[klass: string]: any}> {
		let endpoint = "https://jamaicaservices.value-cloud.com/CRMValidate";
		return this.postAPIService(endpoint, cuie1, dlke2);
	}
}
