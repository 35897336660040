import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountService } from '@data/services/account/account.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { take, takeUntil } from 'rxjs/operators';
import { CompetitorsProfileTranslations } from './competitors.translation';
import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { StyleService } from 'app/style.service';
import { CompanyRequestPayload, Competitor, TranslationItem } from '@shared/models/account.models';
import { Company } from '@shared/models/company.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { MenuItem } from 'primeng/api/menuitem';

type SearchTypeItem = {
	name: string;
	value: number;
}

@Component({
	selector: 'app-competitors',
	styleUrls: ['./competitors.component.scss'],
	templateUrl: './competitors.component.html',
	providers: [AccountService]
})

export class CompetitorsComponent implements OnInit, OnDestroy {
	user_id: string;
	competitorsListdatum: any;
	competitorsListdata: Competitor[];
	fullImagePath: string;
	loadCompetitorTable: boolean = false;
	showCompetitorsList: boolean = false;
	competitor: string;
	companyname: string;
	companydescription: string;
	competitorFormAction: string;
	competitorId: string;
	deleteCompetitorId: string;
	modalReference: NgbModalRef;
	hideActionLoader: boolean = true;
	searchResults: Company[];
	searchTable: boolean = false;
	searchTableNoResult: boolean = false;
	currentCompany: string;
	companyid: string;
	isTestItem: boolean = false;
	payload: CompanyRequestPayload;
	searchTypeList: SearchTypeItem[] = [
		{
			name: 'Search By Name',
			value: 1
		}
	];
	searchType: number = 1;
	breadcrumbKeyValues: Breadcrumbs;

	callbk: any;
	callbkSearch: any;

	pageNumger: number;
	pageNumberSearch: number = 1;
	pageLimit: number = 10;
	pageLimitSearch: number = 10;
	order: string = 'desc';
	sortBy: string = 'created';
	showTranslate: boolean = false;
	sidebarTitle: TranslationItem = {key: null, value: null};
	ngUnsubscribe = new Subject<boolean>();
	rowLimit: MenuItem[] = [];
	strSearch = '';
	style2022$: Observable<boolean>;

	constructor(
		private AccountService: AccountService,
		private router: Router,
		private modalService: NgbModal,
		private notification: NotificationService,
		private CommonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: CompetitorsProfileTranslations,
		private styleService: StyleService,
	) {}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022
        this.funcBuildPageLimit();
		this.getTranslations();
		this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
		  this.getTranslations();
		});
		this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
		  this.showTranslate = res;
		});
		this.loadDataTable();
	}


	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	   this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);

	   this.breadcrumbKeyValues = {
		[this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
		[this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
		[this.trans.trans.manage_competitors.value]: { routerLink: '', active: true },
	  };
	 });
	}

	setRowLimit(num) {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit() {
		this.rowLimit = [
			{ label: "10", icon: this.pageLimit == 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: "25", icon: this.pageLimit == 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: "50", icon: this.pageLimit == 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: "100", icon: this.pageLimit == 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		]
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	loadDataTable() {
		let queryString = "/limit/all/page/all/search/all" + "/sortby/" + this.sortBy + "/order/" + this.order;

		this.AccountService.getCompetitors(queryString)
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe((result) => {
			const {data} = result.result;
			this.competitorsListdata = data;
			this.loadCompetitorTable = true;
		});
	}

	getCompetitorsList(order) {
		this.user_id = sessionStorage.getItem("uid");
		if (this.user_id) {
			this.loadCompetitorTable = false;

			if (order == 'changeOrder') {
				this.sortBy = 'created';
				this.order = 'desc';
			}
			else {
				this.loadDataTable();
			}

		} else {
			heapReset();
			localStorage.removeItem('AnalyticsSession');
			this.router.navigate(['/login']);
		}
	}

	ngOnSubmit(form: NgForm) {
		this.hideActionLoader = false;
		if (form.valid) {
			if (form.value.companyname == '') {
				form.value.companyid = '';
			}
			let payload = {
				"name": form.value.competitor,
				"description": form.value.companydescription,
				"company_id": form.value.companyid,
				"Test_Status": form.value.isTestItem,
				competitor_id: this.competitorId ? this.competitorId : ""
			}

			if (this.competitorFormAction == 'add') {

				this.AccountService.createCompetitors(payload)
				.pipe(take(1))
				.subscribe((result) => {
						this.loadDataTable();
						this.notification.success(result.result, false);
						form.reset();
						this.hideActionLoader = true;
						this.getCompetitorsList('changeOrder');
						this.showCompetitorsList = false;
				});
			}
			if (this.competitorFormAction == 'update') {
				this.AccountService.updateCompetitors(payload, this.competitorId)
				.pipe(take(1))
				.subscribe((result) => {
						this.notification.success(result.result, false);
						form.reset();
						this.loadDataTable();
						this.hideActionLoader = true;
						this.getCompetitorsList('noChangeOrder');
						this.showCompetitorsList = false;
				});
			}
		}
	}


	createUpdateCompetitors(action: 'add' | 'update', name?: string, description?: string, companyid?: string, id?: string, test_status?:boolean): void {
		this.competitorFormAction = action;
		this.competitorId = id;
		this.showCompetitorsList = !this.showCompetitorsList;

		this.searchTable = false;
		this.searchTableNoResult = false;
		this.searchType = this.searchTypeList[0]['value'];

		if (action == 'add') {
			this.competitor = '';
			this.companydescription = '';
			this.companyname = '';
			this.companyid = '';
			this.isTestItem = false;
			this.sidebarTitle = this.trans.trans.addCompetitor;
		}
		if (action == 'update') {
			this.competitor = name;
			this.companydescription = description;
			this.companyid = companyid;
			this.isTestItem = test_status;
			this.competitorId = id;
			this.sidebarTitle = this.trans.trans.editCompetitor;

		}
	}

	deleteCompetitor(id) {
		this.AccountService.deleteCompetitors(id)
		.pipe(take(1))
		.subscribe((result) => {
				this.notification.success(result.result, false);
				this.hideActionLoader = true;
				this.getCompetitorsList('noChangeOrder');
		});
	}

	open(content, id) {
		this.deleteCompetitorId = '';
		this.deleteCompetitorId = id;
		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}

	searchCompanyList(searchterm) {
		this.searchTableNoResult = false;
		this.searchTable = false;
		if (searchterm != null && searchterm != "") {
			this.hideActionLoader = false;
			this.payload = {
				"searchTerm": searchterm,
				"searchOption": this.searchType,
				"publicOnly": 0,
				"synopsisOnly": 1
			};
			this.loadDataTableSearch();
		}
	}

	loadDataTableSearch() {
		this.payload['limit'] = this.pageLimitSearch;
		this.payload['page'] = this.pageNumberSearch;
		this.AccountService.getSearchCompanies(this.payload)
		.pipe(take(1))
		.subscribe((result) => {
			const {data} = result.result
				if (data) {
					this.searchResults = data;
					this.searchTable = true;
				}
				else {
					this.searchTableNoResult = true;
				}
				this.hideActionLoader = true;
		});
	}

	selectCompany(company: Company) {
		this.competitor = company.name;
		this.companyname = company.name;
		this.currentCompany = company.name;
		this.companyid = company.id;
		this.searchTable = false;
		this.searchTableNoResult = false;
	}

	cancelEdit(): void {
		this.showCompetitorsList = false;
	}
}
