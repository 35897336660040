import { Injectable } from '@angular/core';

@Injectable ()

export class MVPListTranslations {

    public config: any = {
        component: 'mvpList',
        display_name: 'Master Valu Prop List',
        description: 'List of all master value props'
    };

    public trans: any = {
        addNew: 'Add New',
        add: 'Add',
        search: 'Search',
        rowLimit: 'Row limit:',
        areYouSure: 'Are you sure you want to delete',
        your_master_value_props: 'Your Master Business Cases / Value Assessments',
        name: 'Name',
        total_benefits_fmt: 'Total Benefits',
        total_costs_fmt: 'Total Costs',
        created: 'Created Date'
    };
}
