import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DefaultTranslations } from 'app/value-prop/value-prop-dashboard/sharev2/share.translation';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-name',
  templateUrl: './edit-name.component.html',
  styleUrls: ['./edit-name.component.scss'],
})
export class EditNameComponent implements OnDestroy {
  originalName = '';
  changeName: boolean;
  valueName: string;
  unsubscribe = new Subject();
  @Input() set name(name: string) {
    this.originalName = name;
    this.valueName = name || '';
  }
  @Input() editTip = 'Rename';
  @Input() set closeEdition(close: boolean) {
    this.changeName = !close;
  }

  @Output() cancel = new EventEmitter();
  @Output() submit = new EventEmitter<string>();
  @Output() hideValue = new EventEmitter<boolean>(false);

  constructor(public trans: DefaultTranslations) {}

  onCancel() {
    this.cancel.emit();
    this.changeName = false;
    this.valueName = this.originalName;
  }

  onSubmit() {
    this.submit.emit(this.valueName);
    this.submit.pipe(takeUntil(this.unsubscribe)).subscribe(() => (this.valueName = this.originalName));
  }

  editValue() {
    this.changeName = !this.changeName;
    this.hideValue.emit(true);
  }

  ngOnDestroy() {
    this.unsubscribe.next(false);
    this.unsubscribe.complete();
  }
}
