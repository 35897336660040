<p-progressBar *ngIf="loading; else loadedContent" class="keywest_progressbar" mode="indeterminate"></p-progressBar>
  <ng-template #loadedContent>
    <div class="row">
      <div class="col-xs-12">
        <div class="btn-container">
          <p-selectButton 
            class="btn-left" 
            [options]="selectOptions" 
            (onChange)="funcOptionChange($event)" 
            [(ngModel)]="selectedOption" 
            styleClass="keywest_select_button"
            optionLabel="label"
            optionValue="key"
          ></p-selectButton>
          <button class="btn btn_customizable btn-right" *ngIf="goalOnTheFlyPrivilege && valueProp" class="btn btn_customizable" [matTooltip]="trans.trans.newGoalTooltip.value" (click)="onAddGoalClick()">
            <i class="fa fa-plus"></i>{{ trans.trans.addGoal.value }}
          </button>
        </div>

        <div >
          <div >
            <p-accordion id="goal-group-accordion" [multiple]="true">
              <ng-container *ngFor="let group of goalGroupList; index as goalGroupIndex">
                <p-accordionTab style="padding: 1rem" [header]="group.name" *ngIf="group.should_show" [selected]="true">
                  <p-table
                    *ngIf="goalsLoaded"
                    #tableGoalsList
                    [value]="group.factors"
                    styleClass="keywest_table"
                    tableStyleClass="keywest_table"
                    dataKey="value.id"
                    [paginator]="true"
                    [rows]="10"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th>
                          {{ trans.trans.addToTracker.value }}
                        </th>
                        <th pSortableColumn="value.name">
                          {{ trans.trans.goalName.value }}
                          <p-sortIcon field="value.name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="value.linkedBenefit">
                          {{ trans.trans.linkedFactors.value }}
                          <p-sortIcon field="value.linkedBenefit"></p-sortIcon>
                        </th>
                        <th>
                          {{ trans.trans.baselineValue.value }}
                        </th>
                        <th>
                          {{ trans.trans.targetValue.value }}
                        </th>
                        <th>
                          {{ trans.trans.unitType.value }}
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                      <tr *ngIf="rowData.should_show">
                        <td class="col-checkbox">
                          <p-checkbox [binary]="true" [(ngModel)]="rowData.selected" (click)="setFactorActive(goalGroupIndex, rowIndex, rowData)"></p-checkbox>
                        </td>
                        <td class="col-name">
                          <ng-container *ngIf="rowData.editing === false">
                            <span class="clickable" (click)="onGoalClick(rowData)">
                              {{ rowData.name }}
                            </span>
                            <span *ngIf="rowData.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.description"> info </span>
                            <i
                              *ngIf="rowData.scratchpad_str !== '' && +rowData.scratchpad_used === 1"
                              class="clickable fa fa-calculator calculator-icon"
                              (click)="toggleScratchpadSidebar(rowData)"
                            ></i>
                            <img
                              *ngIf="rowData.scratchpad_str !== '' && +rowData.scratchpad_used === 0"
                              class="clickable calculator-icon-inactive"
                              src="assets/images/scratchpad/calculator-inactive.png"
                              (click)="toggleScratchpadSidebar(rowData)"
                            />
                            <i (click)="editName(goalGroupIndex, rowIndex, rowData.name)" class="clickable fa fa-pencil" style="margin-left: 10px"></i>
                            <ng-container *ngIf="rowData.name !== rowData.original_factor_name">
                              <br />
                              <span class="small italic"> {{ trans.trans.originalGoalName.value }}: {{ rowData.original_factor_name }}</span>
                            </ng-container>
                          </ng-container>
                          <ng-container>
                            <app-error-input-wrapper *ngIf="rowData.editing === true">
                              <div class="input-group">
                                <input
                                  [(ngModel)]="rowData.nameCache"
                                  type="text"
                                  class="form-control"
                                  (keydown.Enter)="saveName(goalGroupIndex, rowIndex, rowData.accountFactorId)"
                                />
                                <span class="clickable input-group-addon" (click)="editName(goalGroupIndex, rowIndex, rowData.nameCache)"><i class="fa fa-times"></i></span>
                                <span class="clickable input-group-addon" (click)="saveName(goalGroupIndex, rowIndex, rowData.accountFactorId)"><i class="fa fa-save"></i></span>
                              </div>
                            </app-error-input-wrapper>
                          </ng-container>
                        </td>
                        <td class="col-benefit-baseline">
                          <span>{{ rowData.connected_factors_seprated_by_commas }}</span>
                        </td>
                        <td class="col-baseline">
                          <p-inputNumber
                            *ngIf="rowData.capture_baseline === '1' && rowData.selected === true"
                            [(ngModel)]="rowData.baseline_value"
                            mode="decimal"
                            [locale]="numberLocales[+userData?.number_format] ?? 'en-US'"
                            [minFractionDigits]="rowData.factor_precision | functionPipe: removeNegatives"
                            [maxFractionDigits]="rowData.factor_precision | functionPipe: removeNegatives"
                            (onInput)="goalUpdated(goalGroupIndex, rowIndex)"
                          >
                          </p-inputNumber>
                        </td>
                        <td class="col-value">
                          <p-inputNumber
                            *ngIf="rowData.capture_target === '1' && rowData.selected === true"
                            [(ngModel)]="rowData.value"
                            mode="decimal"
                            [locale]="numberLocales[+userData?.number_format] ?? 'en-US'"
                            [minFractionDigits]="rowData.factor_precision | functionPipe: removeNegatives"
                            [maxFractionDigits]="rowData.factor_precision | functionPipe: removeNegatives"
                            (onInput)="goalUpdated(goalGroupIndex, rowIndex)"
                          >
                          </p-inputNumber>
                        </td>
                        <td class="col-value">
                          {{ rowData.unit_type }}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="5" class="text-center">{{ trans.trans.emptyTable.value }}</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </p-accordionTab>
              </ng-container>
            </p-accordion>
          </div>
        </div>
      </div>
    </div>

    <p-sidebar [(visible)]="isSidebarOpen" styleClass="keywest_sidebar p-sidebar-lg" position="right">
      <ng-container *ngIf="sidebarMode === 'edit' && isSidebarOpen">
        <app-goal-sidebar [selectedGoal]="selectedGoal" (close)="isSidebarOpen = false"></app-goal-sidebar>
      </ng-container>
      <ng-container *ngIf="sidebarMode === 'add' && isSidebarOpen">
        <app-goal-form
          *ngIf="isSidebarOpen"
          [goalData]="selectedGoal"
          [modelGoalIds]="goalIdsList"
          [hideCreateNew]="!goalOnTheFlyPrivilege"
          [vpId]="valueProp?.id"
          [loadingSave]="false"
          (saveClick)="addGoalToVp($event)"
          (cancelClick)="isSidebarOpen = false"
        >
        </app-goal-form>
      </ng-container>
    </p-sidebar>
  
    <p-sidebar styleClass="keywest_sidebar p-sidebar-lg" (onHide)="closeSidebars()" [(visible)]="toggleScratchpadView" position="right">
      <div class="sidebar_wrapper">
        <div class="sidebar_header">{{ trans.trans.editScratchpad.value }}</div>
        <div class="sidebar_container">
          <div class="keywest_panel">
            <app-scratchpad-component
              *ngIf="toggleScratchpadView"
              (SaveAndUpdateCallback)="scratchpadSave()"
              factorTypeId="0"
              [valueMetricId]="scratchpadGoalSelected.account_factor_id"
              [valuePropId]="valuePropId"
              [valueMetric]="scratchpadGoalSelected"
              [goal]="true"
              [year]="1"
            ></app-scratchpad-component>
          </div>
        </div>
      </div>
    </p-sidebar>
  </ng-template>