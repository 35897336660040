<div class="row" *ngIf="style2022$ | async">
	<div class="col-md-4 mr-auto breadcrumb-container">
	  <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
	</div>
  </div>

  <div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10 old-breadcrumb">
	  <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToProfile.value }}</a>
	</div>
  </div>

<p-menu #row appendTo='body' [popup]="true" [model]="rowLimit" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>
						{{trans.trans.manage_partners.value}}
						<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.manage_partners'
						[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.manage_partners"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-2'>
					<button class="btn btn_customizable pull-right" (click)="addPartner()">
						<i class="fa fa-plus"></i>{{trans.trans.add.value}}</button>
				</div>
			</div>

		</div>
	</div>

	<div class='row' style='margin-bottom: 20px;'>
		<div class='col-xs-4'>
			<div class="input-group keywest_search">
				<span class="input-group-addon"><i class='fa fa-search'></i></span>
				<input [(ngModel)]="strSearch" (keyup)="tablePartners.filter($any($event).target.value, 'PartnerName', 'contains')" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
				<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tablePartners.filter('', 'PartnerName', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
			</div>
		</div>
		<div class='col-xs-8'>
			<span class="clickable pull-right" (click)='row.toggle($event)'>{{trans.trans.rowLimit.value}} {{pageLimit}} <i class='fa fa-chevron-down'></i></span>
		</div>
	</div>

	<div class='row'>
		<div class='col-xs-12'>
			<p-table #tablePartners [value]="partnersListdata" [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th>{{trans.trans.name.value}}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.name'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.name"></app-editTranslation>
						</th>
						<th>{{trans.trans.type.value}}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.type'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.type"></app-editTranslation>
						</th>

						<th>{{trans.trans.actions.value}}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.actions'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.actions"></app-editTranslation>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-partners let-rowData let-rowIndex="rowIndex">
					<tr>
						<td width="30%">{{partners.PartnerName}}</td>
						<td width="50%">
							<span class="badge badge-primary" *ngFor="let partnersList of partners.truncatedpartnertype">
								{{partnersList}}
							</span>
							<span class="read_more" (click)="expandlist(rowIndex)">
								<i *ngIf="partners.showtruncateinitial&&partners.showtruncate" class="icon-arrow-right"></i>
								<i *ngIf="!partners.showtruncate" class="icon-arrow-left"></i>
							</span>
						</td>
						<td width="20%">
							<span class='keywest_icon_group pull-right'>
								<span><i (click)='editPartner(partners)' matTooltip="Edit" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
								<span>
									<app-are-you-sure matTooltip="Delete" matTooltipPosition="above" message='Are you sure?' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='deletePartner(partners.id)'></app-are-you-sure>
								</span>
							</span>

						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleEditPartner' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<form name="PartnerForm" #PartnerForm="ngForm" (ngSubmit)="ngOnSubmit(PartnerForm)" class="full-height">
		<div class='sidebar_wrapper'>
			<div *ngIf="!isAddingPartner" class='sidebar_header'>
				{{trans.trans.editPartner.value}}
			</div>
			<div *ngIf="isAddingPartner" class='sidebar_header'>
				{{trans.trans.addPartner.value}}
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class="row">
						<div class="col-xs-12">
							<label for="partner" class="sidebar_label">{{trans.trans.owner.value}}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='trans.name'
							[isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.owner"></app-editTranslation>
							</label>
							<p-dropdown appendTo="body"
								[panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
								name='owner'
								class="keywest_dropdown dropdown-theme"
								[options]="ownerList"
								[required]="true"
								[(ngModel)]="selectedOwner"
								(onChange)="onOwnerSelect($event)"
								optionLabel="name" >
							</p-dropdown>
						</div>
					</div>

					<div class="row">
						<div class="col-xs-12">
							<label for="partner" class="sidebar_label">{{trans.trans.availableParnters.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
								[transObj]="trans.trans.availableParnters" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<p-dropdown appendTo="body"
								[panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
								name='availPartner'
								class="keywest_dropdown dropdown-theme"
								[disabled]="showEditPartner || availPartnerList.length==0"
								[options]="availPartnerList"
								[required]="true"
								[(ngModel)]="selectedPartner"
								(onChange)="onPartnerSelect($event)"
								optionLabel="account_name" >
							</p-dropdown>
						</div>
					</div>

					<div class="row">
						<div class="col-xs-12">
							<label for="partner" class="sidebar_label">{{trans.trans.partnerType.value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
								[transObj]="trans.trans.partnerType" (callback)="getTranslations()"></app-editTranslation>
							</label>
							<div class='input-group keywest_input_group'>
								<p-multiSelect
								[panelStyleClass]="(style2022$ | async) ? 'multi-select-item-font' : ''"
								[options]="items"
								[(ngModel)]="selectedItems"
								class='keywest_dropdown multi-select-theme'
								name="parnerType"
								[defaultLabel]="trans.trans.partnerType.value"
								(onChange)="onPartnerTypeChange()"></p-multiSelect>
								<div class='input-group-addon'>
									<button type="button" class="btn btn_customizable" style="height: 30px;" (click)="addPartnerType()" id="createPartnerType">
										<i class="fa fa-plus"></i>&nbsp; {{trans.trans.addPartnerType.value}}</button>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="showNewPartner">
						<form class="form-horizontal" name="PartnerTypeForm" #PartnerTypeForm="ngForm" (ngSubmit)="savePartnerType(PartnerTypeForm)">
							<div class='row'>
								<div class="col-xs-12">
									<label for="partner" class="sidebar_label">{{trans.trans.newPartnerType.value}}
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
										[transObj]="trans.trans.newPartnerType" (callback)="getTranslations()"></app-editTranslation>
									</label>
									<input type="text" class="form-control" name="newPartnerType" maxlength="30" id="newPartnerType" [placeholder]="trans.trans.newPartnerType.value" required ngModel>
								</div>
							</div>
							<div class='row'>
								<div class="col-xs-12">
									<label for="partner" class="sidebar_label">{{trans.trans.description.value}}
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
										[transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
									</label>
									<input type="text" class="form-control" name="PartnerTypeDes" id="PartnerTypeDes" [placeholder]="trans.trans.description.value" ngModel>
								</div>
							</div>
							<div class='row'>
								<div class="col-xs-12 marg-v">
									<button type="submit" class="btn btn_customizable" [disabled]="PartnerTypeForm.invalid">{{trans.trans.save.value}}</button>
									<button type="button" class="btn marg-h btn-secondary" (click)="hidePartnerType()">{{trans.trans.Cancel.value}}</button>
									<img class="actionLoader" *ngIf="showActionLoader" [src]="fullImagePath" />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<div class='row'>
					<div class="col-md-12">
						<button type="submit" class="btn btn_customizable pull-right" *ngIf="!showEditPartner" [disabled]="PartnerForm.invalid">{{trans.trans.Create.value}}</button>
						<button type="submit" class="btn btn_customizable pull-right" *ngIf="showEditPartner" [disabled]="PartnerForm.invalid">{{trans.trans.Update.value}}</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>
