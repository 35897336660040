import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { FactorsService } from '@data/services/factors/factors.service';
import { FactsService } from '@data/services/facts/facts.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { ErrorMessagesService } from '@services/error-messages.service';
import { NotificationService } from '@services/notification.service';
import { Goal } from '@shared/models/goal-group.model';
import { StyleService } from 'app/style.service';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, finalize, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OperatorTypeList, Parenthesis, ScratchpadDefault, ScratchpadFields } from '../interfaces/factors.interface';
import { Items } from '../interfaces/items.interface';
import { DefaultTranslations } from './ae_scratchpads.translation';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-ae-scratchpads',
  templateUrl: './ae_scratchpads.component.html',
  styleUrls: ['./ae_scratchpads.component.scss'],
})
export class AEScratchpadsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() callback = new EventEmitter();
  @Input() factor: Factor | Goal;
  @Input() calledFrom: string;
  @Input() embed = false;
  @Input() templateSetting: string;

  @ViewChild('addDriverFactorForm') addDriverFactorForm: NgForm;

  errorDict = new Map<string, string>();

  isGoal = false;
  sidebar_title = 'Add Scratchpad';
  operatorTypesList: OperatorTypeList = [];
  has_scratchpad = false;
  scratchpad_loading = true;
  newFactor = '';
  driverScratchpadFactor_default: ScratchpadDefault;
  mode = 'add';
  ngUnsubscribe$ = new Subject();
  parenthesis: Parenthesis[] = [
    { name: 'openParen', sign: '(' },
    { name: 'closeParen', sign: ')' },
  ];
  driverScratchpadFields: ScratchpadFields[] = [
    {
      id: 1,
      factor: null,
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: undefined,
    },
  ];

  benefitLoader: boolean;

  OpenParen_default: string;
  CloseParen_default: string;
  driverScratchpadFactorValue_default: string;
  account_id: string;
  scaledBy: Items = [];
  groupedFactors: Items = [];
  showTranslate = false;

  createNewFactor = false;
  scratchpadCalculation$: Observable<number>;
  calculate$ = new Subject<boolean>();
  style2022$: Observable<boolean>;

  editFactor = false;
  selectedFactor: Factor;

  constructor(
    private factsService: FactsService,
    private factorsService: FactorsService,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private commonService: CommonService,
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService,
    private errorMessageService: ErrorMessagesService
  ) {}

  ngOnInit(): void {
    this.account_id = sessionStorage.getItem('aid');
    this.has_scratchpad = this.factor && this.factor.has_scratchpad === 1 ? true : false;
    this.style2022$ = this.styleService.style2022;
    this.isGoal = this.templateSetting === 'goalFactor' ? true : false;

  

    this.getScaledBy();
    this.getGroupedFactors();

    this.getScratchpadOperatorTypes();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.scratchpadCalculation$ = this.calculate$.pipe(
      switchMap((calculate) => {
        if (!calculate) {
          return of(null);
        }
        if (calculate) {
            const payload = this.buildScratchpad(this.addDriverFactorForm);
            if(payload && Object.keys(payload).length !== 0){
              return this.solutionService.getScratchpadCaclulation(payload).pipe(
                filter((res) => res.result.success),
                finalize(() => {
                  this.scratchpad_loading = false;
              }),
              pluck('result', 'total')
          );
            }
            
        }
       
      })
    );
  }

  ngAfterViewInit(): void {
    this.loadErrors();
    this.addDriverFactorForm.form.valueChanges.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.checkFactorDropdowns();
      this.loadErrors();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  loadErrors(): void {
    this.errorDict = new Map(this.errorMessageService.getFormErrors(this.addDriverFactorForm.controls));
  }

  checkFactorDropdowns() {
    const defaultDriverControl = this.addDriverFactorForm.controls.driverScratchpadFactor_default;
    if (defaultDriverControl?.value && defaultDriverControl?.value.id === 0) {
      defaultDriverControl.patchValue(null);
    }

    this.driverScratchpadFields.forEach(({ factor }, i) => {
      if (factor && factor.id === 0) {
        const driverControl = this.addDriverFactorForm.controls['scratchpadDriverFactor_' + i];
        driverControl.patchValue(null);
      }
    });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        if (this.factor && this.factor.has_scratchpad === 1) {
          this.sidebar_title = this.trans.trans.edit_scratchpad.value;
        } else {
          this.sidebar_title = this.trans.trans.add_scratchpad.value;
        }
      });
  }

  getScratchpadOperatorTypes(): void {
    this.factsService.getScatchpadOperatorTypes().subscribe((response) => {
      this.operatorTypesList = response.result.filter((operator: any) => {
        return operator.id !== '5';
      });
    });
  }

  getScaledBy(reload = true): void {
    this.factorsService
      .getScaledBy(this.account_id, this.factor.id, this.isGoal)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.scaledBy = res && res.result && res.result.length ? res.result : [];
        if (reload) {
          this.getSolutionsScratchpad();
        }
      });
  }

  getGroupedFactors(reload = true): void {
    this.factorsService
      // .getScaledBy(this.account_id, this.factor.id, this.isGoal)
      .getFactorCategories(this.account_id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.groupedFactors = res && res.result && res.result.length ? res.result : [];
        if (reload) {
          this.getSolutionsScratchpad();
        }
      });
  }

  driverScratchpadClose(params: string | number): any {
    this.driverScratchpadFields = this.driverScratchpadFields.map((driverField: any) => {
      if (driverField.id === params) {
        driverField.factor = [];
        driverField.operator = undefined;
        driverField.openParen = undefined;
        driverField.closeParen = undefined;
        driverField.operand = 0;
      }
      return driverField;
    });
    this.driverScratchpadFields = this.driverScratchpadFields.filter((driverField: any) => {
      return driverField.id !== params;
    });
  }

  addDriverScratchpad(): void {
    const idRandom = Math.floor(Math.random() * 1000);
    const driverFactor = {
      id: idRandom,
      factor: [],
      operator: undefined,
      openParen: undefined,
      closeParen: undefined,
      operand: undefined,
    };
    this.driverScratchpadFields.push(driverFactor);
  }

  getSolutionsScratchpad(): void {
    const driverScratchpadFields = [];

    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';
    this.solutionService.getSolutionsScratchpads(typeId, this.factor.id, 0).subscribe((response) => {
      if (response.result.units) {
          if (!this.isGoal) {
        for (let f = 0; f < this.groupedFactors.length; f++) {
            let cat_factors = this.groupedFactors[f]['factors'];
            for (let l = 0; l < cat_factors.length; l++) {
              const factor = cat_factors[l];
              if (factor.id == response.result.units[0].factor_id) {
                this.driverScratchpadFactor_default = factor;
              }
          }
        }
          }else{
        for (let f = 0; f < this.scaledBy.length; f++) {
              let factor = this.scaledBy[f];
                if (factor.id == response.result.units[0].factor_id) {
                this.driverScratchpadFactor_default = factor;
              }
          }
        
        }
        this.OpenParen_default = response.result.units[0].open_paren === '1' ? '(' : undefined;
        this.CloseParen_default = response.result.units[0].close_paren === '1' ? ')' : undefined;
        this.driverScratchpadFactorValue_default = response.result.units[0].operand;
        for (let i = 1; i < response.result.units.length; i++) {
          const j = i - 1; //what is this for?
          let out_factor;
          if (!this.isGoal) {
          for (let f = 0; f < this.groupedFactors.length; f++) {
          let these_factors = this.groupedFactors[f]['factors'];
            for (let e = 0; e < these_factors.length; e++) {
              let factor = these_factors[e];
                if (factor.id == response.result.units[i].factor_id) {
                  out_factor = factor;
                }
              }
            }
            }else{
          for (let f = 0; f < this.scaledBy.length; f++) {
                const factor = this.scaledBy[f];
                if (factor.id == response.result.units[i].factor_id) {
                  out_factor = factor;
                }
            }
          }

          const scratchpadInput = {
            id: response.result.units[i].id,
            factor: out_factor,
            operator: response.result.units[j].operator_id,
            openParen: response.result.units[i].open_paren === '1' ? '(' : undefined,
            closeParen: response.result.units[i].close_paren === '1' ? ')' : undefined,
            operand: response.result.units[i].operand,
          };
          driverScratchpadFields.push(scratchpadInput);
        }
        this.benefitLoader = false;
        this.driverScratchpadFields = driverScratchpadFields;
        if (this.calculate$) {
          setTimeout(() => {
            this.calculate$.next(true);
          }, 0);
      }
      } else {
        this.benefitLoader = false;
      }
    });
  }

  deleteScratchpad(): void {
    this.solutionService.deleteSolutionsScratchpads(0, this.factor.id, 0).subscribe((result) => {
      this.callback.emit('');
      this.notification.success(result.result, false);
    });
  }

  saveDriverScratchpads(ngForm: NgForm): void {
    if (ngForm.form.invalid) {
      return;
    }
    this.benefitLoader = true;

    const payload = this.buildScratchpad(ngForm);

    this.solutionService.createSolutionScratchpad(payload).subscribe((result) => {
      if (result.result.message) {
        this.callback.emit(result.result.total);
        this.notification.success(result.result.message, false);
      } else {
        this.callback.emit(result.result.total);
        this.notification.success(this.trans.trans.scratchpad_added.value, false);
      }
    });
  }

  buildScratchpad(form: NgForm): any {
    if (!this.driverScratchpadFactor_default) {
      return;
    }
    let factorValue = this.driverScratchpadFactor_default.id + (this.driverScratchpadFactor_default.id ? ',' : '');
    let openParenValue = (this.OpenParen_default ? '1' : '0') + (this.OpenParen_default ? ',' : ',');
    let closeParenValue = (this.CloseParen_default ? '1' : '0') + (this.CloseParen_default ? ',' : ',');
    let operand = this.driverScratchpadFactorValue_default + ',';
    let operatorValue = '';
    for (const i in form.value) {
      if (i.includes('scratchpadDriverFactor') && form.value[i] !== undefined) {
        factorValue += form.value[i]['id'] + ',';
      } else if (i.includes('scratchpadDriverValue')) {
        operand += form.value[i] + ',';
      } else if (form.value[i] && i.includes('selectOperator')) {
        operatorValue += form.value[i] + ',';
      } else if (i.includes('scratchpadOpenParen')) {
        if (form.value[i]) {
          openParenValue += (form.value[i] === '(' ? '1' : '0') + ',';
        } else {
          openParenValue += '0,';
        }
      } else if (i.includes('scratchpadCloseParen')) {
        if (form.value[i]) {
          closeParenValue += (form.value[i] === ')' ? '1' : '0') + ',';
        } else {
          closeParenValue += '0,';
        }
      }
    }
    factorValue = factorValue.slice(0, -1);
    operatorValue = operatorValue.slice(0, -1);
    openParenValue = openParenValue.slice(0, -1);
    closeParenValue = closeParenValue.slice(0, -1);
    operand = operand.slice(0, -1);

    if (this.isGoal) {
      operand = operand.split(',').fill('0', 0).join(',');
    }

    const typeId = this.templateSetting === 'driverFactor' ? '1' : this.templateSetting === 'financialFactor' ? '2' : '3';

    return {
      scratchpad_factor_id: this.factor.id,
      scratchpad_factor_type_id: typeId,
      account_solution_metric_id: 0,
      factor_id: factorValue,
      operator_id: operatorValue + (operatorValue ? ',5' : ''),
      open_paren: openParenValue,
      close_paren: closeParenValue,
      operand: operand,
    };
  }

  funcGetSign(id: string | number): string {
    const out = this.operatorTypesList.filter((x) => x['id'] == id);
    const stringOutput = out.length ? out[0][this.isGoal ? 'sign' : 'name'] : '';
    return stringOutput as string;
  }

  funcShowAddFactor(): void {
    this.createNewFactor = true;
  }

  calculate(): void {
    this.calculate$.next(true);
  }

  editingScratchpad(): void {
    this.calculate$.next(false);
  }

  openEditFactor(factor: Factor): void {
    this.editFactor = true;
    this.selectedFactor = cloneDeep(factor);
  }

  clearEditFactor(reloadScaledBy: boolean) {
    this.editFactor = false;
    this.selectedFactor = null;
    this.getScaledBy(reloadScaledBy);
    this.getGroupedFactors(reloadScaledBy);
  }
}
