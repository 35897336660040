import { Injectable } from '@angular/core';

@Injectable ()

export class TrackValueCostTabTranslations {

    public config: any = {
        component: 'trackValueCostTab',
        display_name: 'Track Value Cost Tab',
        description: 'The cost tab found in the track value detail dashboard'
    };

    public trans: any = {};
}