import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { RepSimpleResultsTranslaions } from 'app/repv2/views/results/results.translation';
import { DashboardSimpleImplementation } from 'app/value-prop/value-prop-dashboard/dashboard_simple/simple.implementation';
import { take, takeUntil } from 'rxjs/operators';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';
import { Subject } from 'rxjs';
import { ValueProp } from '@shared/models/value-prop.model';

@Component({
  selector: 'app-results-analysis',
  templateUrl: './results-analysis.component.html',
  styleUrls: ['./results-analysis.component.scss'],
})
export class ResultsAnalysisComponent implements OnInit, OnDestroy {
  @Input() discoveryQuestions: any;
  @Input() hasKeyAssumptions: boolean;
  @Input() showTranslate: boolean = false;
  @Input() valueProp: ValueProp;

  competitiveTco = false;
  feature106 = false;
  feature59 = false;
  feature62 = false;
  feature76 = false;
  hideNPV = false;
  hidePaybackFeature = false;
  public hideCashFlowFF = false;

  ngUnsubscribe$ = new Subject<void>();
  showTCO = false;
  view = 'case';
  noCostSuperSimpleRep = false;
  constructor(
    private commonService: CommonService,
    public trans: RepSimpleResultsTranslaions,
    private repSimpleService: RepSimpleViewService,
    public sI: DashboardSimpleImplementation,
    private valuePropService: ValuepropService,
    public rAS: ResultsAnalysisService
  ) {}

  ngOnInit(): void {
    this.competitiveTco = this.commonService.checkFeature('75');
    this.feature106 = this.commonService.checkFeature('106');
    this.feature59 = this.commonService.checkFeature('59');
    this.feature62 = this.commonService.checkFeature('62');
    this.feature76 = this.commonService.checkFeature('76');
    this.hideNPV = this.commonService.checkFeature('37');
    this.showTCO = this.commonService.checkFeature('10');
    this.hidePaybackFeature = this.commonService.checkFeature(121);
    this.hideCashFlowFF = this.commonService.checkFeature('137');
    this.noCostSuperSimpleRep = +this.valueProp.total_costs === 0;

    this.refreshTranslation();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.refreshTranslation();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.repSimpleService.navDiscoveryQuestions.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.view = 'discovery';
      var divElem = document.getElementById('panel_wrapper');
      var chElem = document.getElementById('panel_container');
      var topPos = divElem.offsetTop;
      divElem.scrollTop = topPos - chElem.offsetTop;
    });

    this.rAS.headerColor = sessionStorage.getItem('header_color');

    this.valuePropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.refreshTranslation();
      this.getValuePropDetails();
    });

    if (this.commonService.checkFeature('10')) {
      this.rAS.tcoFeature = true;
    }

    this.valuePropService
      .getDiscoveryQuestions(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(({ result }) => {
        if (result.length) {
          this.discoveryQuestions = true;
          this.rAS.funcGetCharts(this.valueProp, this.view, this.feature62);
        }
      });
  }

  funcChangeView(view) {
    this.view = view;
  }

  refreshTranslation(): void {
    this.rAS.getTranslations(this.hasKeyAssumptions, this.discoveryQuestions, this.valueProp);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getValuePropDetails() {
    if (!this.valueProp.id) {
      return false;
    }
    this.sI.valuePropLoader = true;
    this.valuePropService
      .getValuePropDetail(this.valueProp.id)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result) {
          this.valueProp = response.result.value_prop;
          this.valueProp.benefits_loading = true;

          this.noCostSuperSimpleRep = +this.valueProp.total_costs === 0;

          this.valuePropService
            .quickFillGetBenefits(this.valueProp.id)
            .pipe(take(1))
            .subscribe((response) => {
              this.valueProp.benefits = response.result.vcs;
              let chartColors = this.commonService.getChartColors();

              this.valueProp.benefits.forEach((elem, index: number) => {
                elem.color = chartColors[index];
              });

              this.valueProp.benefits_loading = false;
            });
        }
      });
  }

  onBenefitClick(item, index: number) {
    const benefit = this.valueProp.benefits.find((benefit) => benefit.value_category_id === item.id);
    this.rAS.clickBenefitClick(benefit, index, null, true);
  }
}
