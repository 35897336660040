import { Injectable } from '@angular/core';

@Injectable()
export class AEThresholdTranslations {

    public config = {
        component: 'addEditThreshold',
        display_name: 'Thresholds Form',
		description: 'Form for adding and editing Thresholds'
    };

    public trans: any = {
        addTitle: 'Add Thresholds',
        editTitle: 'Edit Thresholds',
        name: 'Name',
        min: 'Min',
        chooseOne: 'Choose One',
        max: 'Max',
        margin: 'Margin (%)',
        save: 'Save',
        addNew: 'Add New',
        placeholderMin: 'Enter Min',
        placeholderMax: 'Enter Max',
        placeholderMargin: 'Enter Margin',
        edit_translations: 'Edit Translations',
        successThreshold: 'Threshold updated successfully',
        successThresholdUpdate: 'Threshold created successfully',
        thresholdError: 'You must delete an existing threshold before creating a new one or just edit existing threshold.',
        minMaxError: 'You must delete an existing threshold before creating a new one or just edit existing threshold.',
        back: 'Back',
        are_you_sure: 'Are you sure?'
    };
}
