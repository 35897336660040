<div class='row' *ngIf="!benefitsLoader">
	<div class='col-xs-12'>
		<a *ngIf="benefitsList && benefitsList.length" (click)='funcSaveBenefits()' class='btn btn-success'>{{ trans.vp_dashboard.next | translate }} <i class='fa fa-chevron-right'></i></a>
	</div>
</div>
<div *ngFor="let benefitCategory of benefitsList; let i = index">
	<div class='panel panel-white' *ngIf="funcIsActive(benefitCategory.metrics)">
		<div class="panel-heading" style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
			<div class='row'>
				<div class='col-xs-12 clickable' style='padding-right: 30px;'>
					<table style='table-layout:fixed;width:100%'>
						<tr>
							<td width='20px' style='vertical-align: top;' (click)='updateBenefitsLocalStorage(benefitCategory.value_category_id);'>
								<i class='fa fa-plus clickable' *ngIf="!benefitsListExpanded[i].expand" style='margin-right: 5px;'></i>
								<i class='fa fa-minus clickable' *ngIf="benefitsListExpanded[i].expand" style='margin-right: 5px;'></i>
							</td>
							<td width='100%' (click)='updateBenefitsLocalStorage(benefitCategory.value_category_id);'>
								<span class='clickable'>{{benefitCategory.name}}</span><br>
								<small style='font-size: 70%; color: green; margin: 5px;'>{{filterActive(benefitCategory, 1)}} {{'vpdashboard.benefits.active' | translate}}</small>
								<small style='font-size: 70%; color: red; margin: 5px;'>{{filterActive(benefitCategory, 0)}} {{'vpdashboard.benefits.n_a' | translate}}</small>
								<small style='font-size: 70%; color: orange; margin: 5px;'>{{filterActive(benefitCategory, 3)}} {{'vpdashboard.benefits.soft' | translate}}</small>
							</td>

							<td style='text-align: right;' width='90px'>
								{{'vpdashboard.benefits.include' | translate}}
								<dl-inputswitch (onChange)='benefitsListExpanded[i].active = !benefitsListExpanded[i].active'  onLabel='No' onColor='red' offColor='green' offLabel='Yes'></dl-inputswitch>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="panel-body" *ngIf="getBenefitsListExpanded(benefitCategory.value_category_id)">
			<div *ngIf="isTable" class="table-responsive col-sm-12 soln-table" style='padding: 0px;'>
				<table id="benefits" class="table custom-table" style='margin-bottom: 0px;' style='table-layout: fixed; width: 100%;'>
					<thead>
						<tr>
							<th style="width: 80%">{{ 'props.tabDetails.benefits.Benefits' | translate }}</th>
							<th style="width: 150px" *ngIf="benefitCategory.is_financial == 0">{{'vpdashboard.benefits.current_effort' | translate}}</th>
							<th style="width: 150px" *ngIf="benefitCategory.is_financial == 0">{{'vpdashboard.benefits.with_us' | translate}}</th>
							<th style="width: 150px" *ngIf="benefitCategory.is_financial == 2">Improvement</th>
							<th style="width: 150px" *ngIf="benefitCategory.is_financial == 1 || benefitCategory.is_financial == 2">{{'vpdashboard.benefits.financial_factor' | translate}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let benefit of benefitCategory.metrics | filterBenefits : menuFilter: null | filterStatus: showActive : showNA : showSoft " class="grid-row">
							<td class="benefit-status" [matTooltip]="benefit.description" matTooltipPosition="above">
								<span class='link'>
									{{benefit.alt_metric_name?benefit.alt_metric_name:benefit.metric_name}}
								</span>
							</td>
							<td *ngIf="benefitCategory.is_financial == 0"><input type='number' name='current_effort' (change)='benefit.updated = true;' [(ngModel)]="benefit.current_effort" class='form-control'></td>
							<td *ngIf="benefitCategory.is_financial == 0"><input type='number' name='with_us' (change)='benefit.updated = true;' [(ngModel)]="benefit.future_effort" class='form-control'></td>
							<td *ngIf="benefitCategory.is_financial == 2">
								<input type='number' name='improvement_factor' (change)='benefit.updated = true;' [(ngModel)]="benefit.impact" class='form-control'>
								{{benefit.impact_type}}
							</td>
							<td *ngIf="benefitCategory.is_financial == 1 || benefitCategory.is_financial == 2"><input type='number' name='financial_factor' (change)='benefit.updated = true;' [(ngModel)]="benefit.financial_value" class='form-control'></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div *ngIf="benefitsLoader" class="col-sm-12 m-t-md text-center">
				<p class="text-center">
					<img class="loader" src="{{fullImagePath}}" />
				</p>
			</div>
			<div *ngIf="(benefitsList == null || benefitsList.length==0) && !benefitsLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4>{{ 'props.tabDetails.benefits.NoBenefitsFound' | translate }}</h4>
				</div>
			</div>
		</div>
	</div>
</div>
<div class='row' *ngIf="!benefitsLoader">
	<div class='col-xs-12'>
		<a  *ngIf="benefitsList && benefitsList.length" (click)='funcSaveBenefits()' class='btn btn-success'>{{'vpdashboard.benefits.save' | translate}} <i class='fa fa-chevron-right'></i></a>
	</div>
</div>