import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Category } from '@data/services/valueposition/models/category.interface';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelsV2AEModelCategoryTranslations } from './ae_category.translation';

@Component({
  selector: 'app-ae-model-category',
  templateUrl: './ae_category.component.html',
})
export class AEModelCategoryComponent implements OnInit, OnDestroy {
  @Input() can_edit = false;
  @Input() mode = 'add';
  @Output() callback = new EventEmitter();
  @Output() closeCallback = new EventEmitter();

  modelCategoryList: Category[] = [];
  toggleAddEditCategory = false;

  @ViewChild('formAddEditCategory') formAddEditCategory: NgForm;

  cols: Array<{field: string; header: string}> = [{ field: 'category', header: 'Category' }];
  strSearch = '';

  @Input() selectedElem: Category = {
    category: '',
    description: '',
    id: '',
  };

  private ngUnsubscribe$ = new Subject();
  showTranslate = false;

  constructor(
    private solutionService: SolutionService,
    private notification: NotificationService,
    private commonService: CommonService,
    public trans: ModelsV2AEModelCategoryTranslations,
    private translationService: TranslationsV2Service
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  close(): void {
    this.closeCallback.emit();
  }

  insertModelCategory(form: NgForm): void {
    const payload = {
      category: form.controls.category.value,
      description: form.controls.description.value,
    };

    this.solutionService
      .createSolutionCategory(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.notification.success('Solution Category created successfully', false);
        this.toggleAddEditCategory = false;
        this.callback.emit('');
        this.solutionService.refreshModelsTotals.next();
      });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  updateModelCategory(form: NgForm): void {
    const payload = {
      category: form.controls.category.value,
      description: form.controls.description.value,
      solution_category_id: form.controls.id.value,
    };
    this.solutionService
      .updateSolutionCategory(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.notification.success('Solution Category updated successfully', false);
        this.toggleAddEditCategory = false;
        this.callback.emit('');
        this.solutionService.refreshModelsTotals.next();
      });
  }
}
