<!-- <p-menu [styleClass]="'style-2022'" #menu appendTo="body" [popup]="true" [model]="items"></p-menu> -->
<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="'style-2022'"></p-menu>
<h3 style="font-weight: bold" *ngIf="viewSimple" [ngStyle]="{ color: headerColor }">{{ trans.trans.add_quotes_costs.value }}</h3>

<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <!-- Search bar -->
          <div style="max-width: 356px" class="input-group keywest_search no-background">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input [(ngModel)]="strSearch" (keyup)="searchCosts($event)" type="text" [placeholder]="trans.trans.search.value" class="form-control" />
            <span *ngIf="strSearch !== ''" (click)="strSearch = ''; loadCosts()" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
          </div>
        </div>
        <div class="col-xs-2">
          <button
            *ngIf="!locked && !isCustomerShareType && valueProp.vp_can_edit"
            [matTooltip]="trans.trans.ch_add_cost.value"
            matTooltipPosition="above"
            class="btn btn_customizable pull-right"
            (click)="openAddCosts()"
          >
            <i class="fa fa-plus"></i>{{ trans.trans.addNew.value }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 table-responsive">
        <table id="costs-simple" class="table custom-table">
          <thead>
            <tr>
              <th [style.width]="'50%'" style="min-width: 235px">
                {{ trans.trans.name.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.Type"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th style="white-space: nowrap; width: 100px">
                {{ trans.trans.Type.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.Accrues"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th style="white-space: nowrap; width: 100px">
                {{ trans.trans.Accrues.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.Accrues"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th style="white-space: nowrap" class="text-right">
                {{ trans.trans.UnitPrice.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.UnitPrice"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th *ngIf="!feature97" matTooltip="Quantity" matTooltipPosition="above" style="white-space: nowrap" class="text-right">
                {{ trans.trans.qty.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.qty"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th *ngIf="!feature97" style="white-space: nowrap" class="text-right">
                {{ trans.trans.Subtotal.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.Subtotal"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th *ngIf="!feature97" matTooltip="Discount" matTooltipPosition="above" style="white-space: nowrap" class="text-right" [hidden]="hideDiscountFeature">
                {{ trans.trans.DISC.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.DISC"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th style="white-space: nowrap" *ngIf="term > 3 && years && start !== 1">
                <i class="fa fa-angle-left clickable" (click)="start = rangeLeftArrow(start)" style="float: left; margin-top: 15px"></i>
              </th>

              <th *ngFor="let year of years | rangePipe: start; let i = index" style="white-space: nowrap" class="text-right">{{ getYearTrans(year.label) }}</th>

              <th style="white-space: nowrap" *ngIf="term > 3 && years && start !== term - 2">
                <i class="fa fa-angle-right clickable" (click)="start = rangeRightArrow(start)" style="margin-top: 15px"></i>
              </th>

              <th style="white-space: nowrap" class="text-right">
                {{ trans.trans.GrandTotal.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.GrandTotal"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </th>
              <th *ngIf="(valueProp.vp_can_edit && !isCustomerShareType) || dealdesk"></th>
            </tr>
          </thead>
          <tbody *ngIf="costsLoader">
            <tr>
              <td [attr.colspan]="colspan">
                <p-progressBar mode="indeterminate"></p-progressBar>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!costs && !costsLoader">
            <tr>
              <td [attr.colspan]="colspan">
                {{ trans.trans.NoCostsFound.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.NoCostsFound"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="costs && costs.length">
            <ng-container *ngFor="let cost of costs">
              <ng-container *ngIf="!costCategories">
                <ng-container *ngTemplateOutlet="costRow; context: { cost: cost }"></ng-container>
              </ng-container>

              <ng-container *ngIf="costCategories">
                <tr class="costGroupHeader">
                  <td [attr.colspan]="colspan">
                    <b class="group-badge">{{ cost.name }}</b>
                  </td>
                </tr>

                <ng-container *ngIf="cost.costs && cost.costs.length > 0">
                  <ng-container *ngFor="let cost of cost.costs">
                    <ng-container *ngTemplateOutlet="costRow; context: { cost: cost }"></ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #costRow let-cost="cost" let-index>
  <tr class="grid-row">
    <td>
      <div *ngIf="!cost.editing">
        <a *ngIf="!locked" (click)="editValuePropCosts(cost)" class="clickable">{{ cost.cost_type }}</a>
        <span *ngIf="locked">{{ cost.cost_type }}</span>
        <span
          *ngIf="cost.cost_description || cost.alt_cost_description"
          class="material-icons-outlined tooltip-custom info-icon"
          [matTooltip]="cost.alt_cost_description ? cost.alt_cost_description : cost.cost_description"
        >
          info
        </span>
      </div>

      <div class="input-group" *ngIf="cost.editing">
        <input class="form-control" [(ngModel)]="cost.cost_type" />
        <div class="input-group-btn">
          <a role="button" (click)="cost.editing = !cost.editing" class="closebtn btn"><i class="fa fa-times"></i></a>
          <a role="button" (click)="funcUpdateCostTitle(cost)" class="savebtn btn"><i class="fa fa-save"></i></a>
        </div>
      </div>
    </td>
    <td class="tab-original-name">{{ cost.original_name }}</td>
    <td style="text-align: center; padding: 10px 0 !important; white-space: nowrap !important">
      <div [ngClass]="cost.accrual_type === 'One Time' ? 'one-time' : cost.accrual_type" class="group-badge">{{ cost.accrual_type }}</div>
    </td>
    <td class="text-right" style="padding-right: 7px !important; white-space: nowrap !important">{{ cost.cost_fmt }}</td>
    <td class="text-right" *ngIf="!feature97" style="padding-right: 7px !important; white-space: nowrap !important">{{ cost.quantity_fmt }}</td>
    <td class="text-right" *ngIf="!feature97" style="padding-right: 7px !important; white-space: nowrap !important">{{ cost.subtotal_fmt }}</td>
    <td class="text-right" *ngIf="!feature97" style="padding-right: 7px !important; white-space: nowrap !important" [hidden]="hideDiscountFeature">{{ cost.discount }}%</td>
    <td *ngIf="term > 3 && cost.years && start !== 1"></td>
    <td *ngFor="let year of cost.years | rangePipe: start; let i = index" class="text-right" style="padding-right: 7px !important; white-space: nowrap">{{ year.value }}</td>
    <td *ngIf="term > 3 && cost.years && start !== term - 2"></td>
    <td class="text-right" style="padding-right: 7px !important; white-space: nowrap !important">{{ cost.grand_total_fmt }}</td>
    <td *ngIf="(valueProp.vp_can_edit && !isCustomerShareType) || dealdesk" style="width: 30px">
      <app-ellipsis-menu>
        <ul class="list-group" style="width: 70px">
          <!-- edit -->
          <li class="list-group-item" style="padding-left: 12px" (click)="editValuePropCosts(cost)">
            {{ trans.trans.edit.value }}
          </li>
          <!-- rename -->
          <li class="list-group-item" style="padding-left: 12px" (click)="cost.editing = !cost.editing">
            {{ trans.trans.rename.value }}
          </li>
          <!-- Delete -->
          <li class="list-group-item">
            <app-are-you-sure (callbackYes)="deleteValuePropCosts(cost.id)" [message]="trans.trans.areYouSure.value" icon="" iconColor="red" textColor="warning"></app-are-you-sure>
          </li>
        </ul>
      </app-ellipsis-menu>
    </td>
  </tr>
</ng-template>

<p-sidebar [(visible)]="showAddCost" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="loadCosts()">
  <app-add-cost-modal
    [has_scratchpad]="costModal.has_scratchpad"
    *ngIf="showAddCost && costModal.cost"
    (callback)="showAddCost = false"
    [edit]="!!costModal.edit"
    [data]="costModal.valueProp"
    [cost]="costModal.cost"
  ></app-add-cost-modal>
</p-sidebar>
