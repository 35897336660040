<a class="back-profile" routerLink="/profile" class='clickable'><i class="fa fa-chevron-left"></i>Back to Profile</a>

<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>
						Maturity

					</div>
				</div>
			</div>
		</div>

		<div class='row marg-v'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input [(ngModel)]="strSearch" (keyup)="tableMaturity.filter($any($event).target.value, 'name', 'contains')" type='text' placeholder='Search' class='form-control'>
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableMaturity.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
			<div class='col-xs-8'>
				<a (click)='funcAddMaturity()' class='btn btn_customizable pull-right'><i class='fa fa-plus'></i>Add</a>
			</div>
		</div>


		<div class='row'>
			<div class='col-xs-12'>
				<p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>

				<p-table #tableMaturity [value]="maturity" [paginator]="true" [rows]="10" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th>Name</th>
							<th></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-elem>
						<tr>
							<td>{{elem.name}}
								<i *ngIf="!(style2022$ | async)" [matTooltip]='elem.description' class='fa fa-info-circle'></i>
								<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]='elem.description'>
									info
								</span>
							</td>
							<td>
								<span class='keywest_icon_group pull-right'>
									<span><i (click)='funcEditMaturity(elem)' matTooltip="Edit" matTooltipPosition="above" class='clickable fa fa-pencil'></i></span>
									<span>
										<app-are-you-sure matTooltip="Delete" matTooltipPosition="above" message='Are you sure?' text='' icon='fieldset_icon fa fa-trash' (callbackYes)='funcDelete(elem)'></app-are-you-sure>
									</span>
								</span>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</div>
<p-sidebar *ngIf="toggleAdd" [(visible)]='toggleAdd' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-ae-maturity [elem]='elem' [mode]='mode' (callback)='toggleAdd = !toggleAdd; getData()'></app-ae-maturity>
</p-sidebar>