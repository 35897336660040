import { NgModule } from '@angular/core';
import { CaseStudiesRealizationsComponent } from './case-studies.component';
import { AddCasestudyRealizationComponent } from './add-casestudy-realization/add-casestudy-realization.component';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from 'primeng/button';
import { FieldsetModule } from 'primeng/fieldset';
import { ModelQuoteModule } from 'app/value-mapv2/solution-detail/case-studies/quote/quote.module';
import { ModelSectionsModule } from 'app/value-mapv2/solution-detail/case-studies/sections/sections.module';
import { ModelBenefitsCaseStudyModule } from 'app/value-mapv2/solution-detail/case-studies/benefits-casestudy/benefits-casestudy.module';
import { ChipsModule } from 'primeng/chips';

@NgModule({
  imports: [
    CommonModule,
    AreYouSureModule,
    TableModule,
    MenuModule,
    SidebarModule,
    MatTooltipModule,
    EditTranslationsModule,
    SharedModule,
    DLMenubarModule,
    CalendarModule,
    InputSwitchModule,
    DropdownModule,
    ButtonModule,
    FieldsetModule,
    ModelQuoteModule,
    ModelSectionsModule,
    ModelBenefitsCaseStudyModule,
    ChipsModule,
  ],
  exports: [CaseStudiesRealizationsComponent, AddCasestudyRealizationComponent],
  declarations: [CaseStudiesRealizationsComponent, AddCasestudyRealizationComponent],
  providers: [],
})
export class RealizationCaseStudyModule {}
