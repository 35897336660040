import { StyleService } from 'app/style.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SupportService } from '@data/services/support/support.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FaqTranslations } from './faq.translation';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit, OnDestroy {
  faQuestions: { question: string; answer: string; expand?: boolean }[] = [];

  faqLoader = false;
  searchTerm = 'all';
  fullImagePath: string;

  subscriptionGetFaq: Subscription;
  txtSearch = '';

  ngUnsubscribe = new Subject();
  showTranslate = false;

  style2022$: Observable<boolean>;

  constructor(
    private commonService: CommonService,
    private supportService: SupportService,
    private translationService: TranslationsV2Service,
    public trans: FaqTranslations,
    private styleService: StyleService
  ) {
    this.fullImagePath = this.commonService.getLoaderImageUrl();
  }

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.loadFaq();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();

    if (this.subscriptionGetFaq) {
      this.subscriptionGetFaq.unsubscribe();
    }
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  loadFaq() {
    const searchLimit = this.searchTerm + '/limit/all/page/1';
    this.subscriptionGetFaq = this.supportService.getFaq(searchLimit).subscribe((response) => {
      if (response.result) {
        this.faQuestions = response.result.data;
      }
    });
  }
}
