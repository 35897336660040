import { Component, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SettingsLink, SettingsService } from '@data/services/settings/settings.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsTranslation } from './settings.translation';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public breadcrumbKeyValues: Breadcrumbs;
  public style2022$: Observable <boolean>;
  private unsubscribe = new Subject<void>();
  public manageSettingsLinks: SettingsLink[] = [];
  public reportLinks: SettingsLink[] = [];
  public designCenterLinks: SettingsLink[] = [];
  public advancedFeaturesLinks: SettingsLink[] = [];
  public localizationLinks: SettingsLink[] = [];

  constructor(
    public trans: SettingsTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private styleService: StyleService,
    private settingsService: SettingsService
    ) { }

  ngOnInit() {
    this.settingsService.userLinkprivileges = this.settingsService.getUserLinkPrilages();
    this.settingsService.feature74 = this.commonService.checkFeature(74);
    this.settingsService.feature87 =  this.commonService.checkFeature(87);
    this.settingsService.bulkUpdateEnabled =  this.commonService.checkFeature(146);
    this.settingsService.guidedDiscoveryEnabled = this.commonService.checkFeature(124);
    this.getTranslations();
    this.style2022$ = this.styleService.style2022;
  }

  getTranslations() {
		const langId = sessionStorage.getItem('language_type_id');
		const langAbbr = this.translationService.getLanguageAbbr(langId);
		const payload = {
			account_id: sessionStorage.getItem('aid'),
			component: this.trans.config.component,
			lang: langAbbr,
      localTranslations: this.trans.trans
		};

		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
			this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
			this.breadcrumbKeyValues = {
				[this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
				[this.trans.trans.settings.value]: { routerLink: '/settings', active: true },
			  };
        this.gennerateLinks();
		});
	}

  private gennerateLinks() {
    this.settingsService.trans = this.trans;
    this.manageSettingsLinks = this.settingsService.getManageSettingsLinks();
    this.reportLinks = this.settingsService.getReportLinks();
    this.designCenterLinks = this.settingsService.getDesignCenterLinks();
    this.advancedFeaturesLinks = this.settingsService.getAdvancedFeaturesLinks();
    this.localizationLinks = this.settingsService.getLocalizationLinks();
  }
}