import { Injectable } from '@angular/core';

@Injectable()
export class ModelsV2AECapabilitiesTranslations {
  public config: any = {
    component: 'ae_capabilities',
    display_name: 'Capabilities Add/Edit',
    description: 'Adding Capabilities'
  };

  public trans: any = {
    name: 'Name',
    description: 'Description',
    add_capability: 'Add Capability',
    edit_capability: 'Edit Capability',
    save: 'Save',
    update: 'Update',
    value_category_name: 'Value Category Name',
    are_you_sure: 'Are you sure?',
    back: 'Back',
    addedSuccessfully: 'Capability added successfully',
    updatedSuccessfully: 'Capability updated successfully',
    customCapabilityTagWarning: 'Ensure that you have removed this custom capability tag from your assets to avoid disruption.',
    createCustomAssetTag: 'Create custom asset tag for this Capability?',
    capabilityTag: 'Capability Tag',
    tagsSpecialCharactersError: 'Tags cannot contain special characters',
    valueTag: 'Value Tag',
    valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    changesUpdatedSuccess: 'Functional Objective updated successfully',
    value: 'value',
    tagMustBeUnique: 'Tags must be unique',

  };
}
