<a class="back-profile" routerLink="/profile"><i class="fa fa-chevron-left"></i>{{trans.trans.back_to_profile.value}}</a>

<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>

		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-7'>
					<div class='title'>
						{{trans.trans.translations.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.translations" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>

				<div class="col-xs-5">
					<label class=sidebar_label>Selected Language: {{currentLang.header}}</label>
					<p-dropdown appendTo="body" [options]="languages" [optionLabel]="'header'" class="keywest_dropdown" (onChange)="onLangChange($event)" [(ngModel)]="currentLang"></p-dropdown></div>
			</div>
		</div>
		<div class='row' style='margin-bottom: 20px;'>
			<div class='col-xs-4'>
				<div class="input-group keywest_search">
					<span class="input-group-addon"><i class='fa fa-search'></i></span>
					<input type="text" class="form-control" [(ngModel)]="strSearch" (input)="admin_translations.filterGlobal($any($event).target.value, 'contains')">
					<span *ngIf="strSearch !== ''" (click)="strSearch = ''; admin_translations.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<p-table #admin_translations dataKey='formula' [scrollable]="true" [paginator]="true" [rows]="50" [columns]="columns" [value]="tableData" [resizableColumns]="true">
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" pResizableColumn style="width:250px" [pSortableColumn]="col.field">
								{{trans.trans[col.field].value}}
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-editing="editing" let-rowData let-columns='columns'>
						<tr>
							<td *ngFor="let col of columns" style="width:250px" [ngStyle]="{'color': rowData[col.field].updated ? 'green': ''}" [pEditableColumn]="col.field !== 'k' ? true : false">
								<p-cellEditor *ngIf="col.field !== 'k'">
									<ng-template pTemplate="input">
										<input pInputText type="text" (keyup)='rowData[col.field].updated = true; edited = true;' [(ngModel)]="rowData[col.field].value" required>
									</ng-template>
									<ng-template pTemplate="output">
										{{rowData[col.field].value}}
									</ng-template>
								</p-cellEditor>
								<span *ngIf="col.field == 'k'">{{rowData[col.field]}}</span>

							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</div>