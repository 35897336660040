import { Injectable } from '@angular/core';

@Injectable()
export class GuidedModelTranslation {
  public config: any = {
    component: 'Guided Discovery step 3',
    display_name: 'Guided Discovery value rep step 3',
    description: '',
  };

  public trans: any = {
    bestMatch: 'Best Match Based On Your Answers',
    otherOptions: 'Other Options Based On Your Answers',
    replaceOptions: 'Replace Existing Models',
    addSelected: 'Add Selected Models'
  };
}
