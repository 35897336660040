<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>{{trans.trans.benefits.value}}
						<app-editTranslation *ngIf="showTranslate" [isTranslationV2]="true" [component]="trans.config.component"
						[transObj]="trans.trans.benefits" (callback)="getTranslations()"></app-editTranslation>
						<i *ngIf="!(style2022$ | async)" [matTooltip]='trans.trans.ch_benefits.value' class='fa fa-info-circle'></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_benefits.value">
							info
					</span>
					</div>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<app-benefit-menu></app-benefit-menu>
				<div *ngIf="bI.benefitsLoader" style='margin-top: 10px;'>
					<p-progressBar mode="indeterminate"></p-progressBar>
				</div>
				<div *ngIf="!bI.benefitsLoader">
					<div *ngFor="let benefitType of bI.benefitsListByType | filterBenefitTypeByStatus : bI.showActive : bI.showNA : bI.showSoft" class="table-responsive col-sm-12 soln-table">
						<h2 style="font-weight: bold" *ngIf="benefitType.id === '1'">{{trans.trans?.benefits.value}} - {{trans.trans?.financial.value}}</h2>
						<h2 style="font-weight: bold" *ngIf="benefitType.id !== '1'">{{trans.trans?.benefits.value}} - {{trans.trans?.nonFinancial.value}}: {{benefitType.name}}</h2>
						<div *ngFor="let benefitCategory of benefitType.vcs">
							<div *ngIf="bI.showGroup(benefitCategory.metrics);">
								<h3 style='margin-bottom: 0px;'>
									{{benefitCategory.name}}<br>
									<small style='font-size: 70%; color: green; margin: 5px;'>{{benefitCategory.active_count}} {{trans.trans.active.value }}
										<app-editTranslation *ngIf="showTranslate" [isTranslationV2]="true" [component]="trans.config.component"
											[transObj]="trans.trans.active" (callback)="getTranslations()"></app-editTranslation>
									</small>
									<small style='font-size: 70%; color: red; margin: 5px;'>{{benefitCategory.na_count}} {{trans.trans.na.value }}
										<app-editTranslation *ngIf="showTranslate" [isTranslationV2]="true" [component]="trans.config.component"
											[transObj]="trans.trans.na" (callback)="getTranslations()"></app-editTranslation>
									</small>
									<small style='font-size: 70%; color: orange; margin: 5px;' *ngIf="!bI.feature46">{{benefitCategory.soft_count}} {{trans.trans.soft.value }}
										<app-editTranslation *ngIf="showTranslate" [isTranslationV2]="true" [component]="trans.config.component"
											[transObj]="trans.trans.soft" (callback)="getTranslations()"></app-editTranslation>
									</small>
								</h3>

								<app-benefit-full [benefitCategory]='benefitCategory'></app-benefit-full>
							</div>
						</div>

						<div *ngIf="(bI.benefitsList == null || bI.benefitsList.length==0) && !bI.benefitsLoader" class="m-t-md col-sm-12">
							<div class="searchTableContainer text-center">
								<h4>{{ trans.trans.no_benefits_found.value }}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
