import { NgModule } from '@angular/core';

import { RCollateralComponent } from "./r_collateral.component";

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 
import { RouterModule } from '@angular/router';

import { Translations } from '@data/services/translation/translation.service';
import { EditTranslationsModule} from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RCollateralTranslations } from './r_collateral.translation';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	declarations: [
		RCollateralComponent,
	],
	imports: [
		EditTranslationsModule,
		RouterModule,
		NgbModule,
		FormsModule,
		CommonModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule	
	],
	providers: [ 
		TranslationsV2Service,
		RCollateralTranslations,
		Translations ],
	entryComponents: [
		RCollateralComponent,
	],
	exports: [RCollateralComponent]
})
export class RCollateralModule { }