import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserProfileTranslation } from '../user-profile.translation';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss']
})
export class EditTeamComponent implements OnInit, OnDestroy {

  public breadcrumbKeyValues: Breadcrumbs;
  public style2022 = false;
  private unsubscribe = new Subject<void>();

  constructor(
    public trans: UserProfileTranslation,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private styleService: StyleService
  ) { }

  ngOnInit() {
    this.styleService.style2022
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(style2022 => this.style2022 = style2022);

    this.getTranslations();
  }

  private getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem("aid"),
      component: this.trans.config.component,
      lang: langAbbr,
			localTranslations: this.trans.trans
    };
    this.translationService.getComponentTrans(payload).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      this.breadcrumbKeyValues = {
        [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
        [this.trans.trans.profile.value]: { routerLink: '/full-profile', active: false },
        [this.trans.trans.team.value]: { routerLink: '/team', active: true },
      };
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}