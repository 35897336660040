import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { AlertService } from '../../_services/alert.service';
import { ValueRealizationTranslations } from './value-realization.translation';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-value-realization',
	templateUrl: './value-realization.component.html',
	styleUrls: ['./value-realization.component.scss']
})
export class ValueRealizationComponent implements OnInit, OnDestroy {
	pageNumber: number;
	pageLimit: number = 10;
	order: string = 'desc';
	sortBy: string = 'modified';

	valueRealization: any = [];
	modalReference: any;

	loadValueRealizationTable: boolean = false;

	fullImagePath: string;
	image_url: string;
	searchTypeList: Array<Object> = [
		{
			name: 'Search By Name',
			value: 'name'
		},
		{
			name: 'Search By Account',
			value: 'account'
		},
		{
			name: 'Search By Creator',
			value: 'creator'
		}
	];
	searchType;
	showActionLoader: boolean = false;
	valuerealizationname: string;
	hideActionLoader: boolean = true;

	isGuest: boolean = false;
	isAdmin: boolean = false;
	showAddButton: boolean = true;

	subscriptiongetValueRealization: Subscription;
	subscriptiondeleteValueProp: Subscription;
	subscriptionSubscribeLoggedIn: Subscription;
	showTranslate: boolean = false;
	vpRouterLink = '/valueprop/';
	// funcTranslate: funcTranslate;

	strSearch: string = "";
	rowLimit: any[] = [];
	columns: any[] = [
		{ field: "name", header: "Name" },
		{ field: "companyname", header: "Company" },
		{ field: "total_benefits_fmt", header: "Expected to Date" },
		{ field: "improvementfmt", header: "Realized to Date" }
	];

	ngUnsubscribe = new Subject();
	style2022$: Observable<boolean>;

	constructor(
		private commonService: CommonService,
		private valuerealizationService: ValuerealizationService,
		private notificationService: NotificationService,
		private valuepropService: ValuepropService,
		private alertService: AlertService,
		private translationService: TranslationsV2Service,
		public trans: ValueRealizationTranslations,
		private styleService: StyleService
	) {


		this.image_url = this.commonService.getImageUrl();

		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {
		this.style2022$ = this.styleService.style2022;
		this.getTranslations();
		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});
		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});

		this.isAdmin = (sessionStorage.getItem("isAdmin") == "true" ? true : false);
		this.searchType = this.searchTypeList[0]['value'];
		this.loadValueRealization();
		this.funcBuildPageLimit();

		const rid = sessionStorage.getItem('rid');
		if (rid == "7") {
			this.showAddButton = false;
		}
		if (this.commonService.checkFeature(41)) { this.showAddButton = false; }
		if (this.commonService.checkFeature(34)) {
			this.vpRouterLink = '/repv2/';
		}

		this.subscriptionSubscribeLoggedIn = this.alertService.subscribeLoggedIn().subscribe(() => {
			this.loadValueRealization();
		});
	}

	ngOnDestroy() {
		if (this.subscriptiongetValueRealization) { this.subscriptiongetValueRealization.unsubscribe(); }
		if (this.subscriptiondeleteValueProp) { this.subscriptiondeleteValueProp.unsubscribe(); }
		if (this.subscriptionSubscribeLoggedIn) { this.subscriptionSubscribeLoggedIn.unsubscribe(); }

		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	getTranslations() {

	 let langId = sessionStorage.getItem('language_type_id');
	 let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

	 this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
	    this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

	   this.searchTypeList = [
			{
				name: this.trans.trans.searchName.value,
				value: 'name'
			},
			{
				name: this.trans.trans.searchAccount.value,
				value: 'account'
			},
			{
				name: this.trans.trans.searchCreator.value,
				value: 'creator'
			}
		];
	 });
	}

	setRowLimit(num) {
		this.pageLimit = num;
		this.funcBuildPageLimit();
	}

	funcBuildPageLimit() {
		this.rowLimit = [
			{ label: "10", icon: this.pageLimit == 10 ? 'fa fa-check' : null, command: () => { this.setRowLimit(10); } },
			{ label: "25", icon: this.pageLimit == 25 ? 'fa fa-check' : null, command: () => { this.setRowLimit(25); } },
			{ label: "50", icon: this.pageLimit == 50 ? 'fa fa-check' : null, command: () => { this.setRowLimit(50); } },
			{ label: "100", icon: this.pageLimit == 100 ? 'fa fa-check' : null, command: () => { this.setRowLimit(100); } },
		]
	}

	refreshTranslation() {
		this.getTranslations();
	}

	loadValueRealization() {
		let searchLimit = "/limit/all/page/" + this.pageNumber + "/sortby/" + this.sortBy + "/order/" + this.order;
		this.subscriptiongetValueRealization = this.valuerealizationService.getValueRealization(searchLimit).subscribe((response) => {
			if (response.result) {
				this.valueRealization = response.result.data;

				this.loadValueRealizationTable = true;
			}
		});
	}

	deleteValueRealization(id) {
		this.subscriptiondeleteValueProp = this.valuepropService.deleteValueProp(id).subscribe((response) => {
			if (response.result) {
				this.notificationService.success(response.result, false); // Params {message, islogout}
				this.loadValueRealization();
			}
		});
	}

}
