<form [hidden]="!initialized" name="formAddEditFactor" #formAddEditFactor="ngForm" class="full-height">
  <div [ngClass]="!embed ? 'sidebar_wrapper embed' : null">
    <div class="sidebar_header">
      <i [matTooltip]="trans.trans.back_to_scratchpad.value" *ngIf="back" (click)="close()" class="fa fa-chevron-left clickable"></i>
      {{ title_factor }}
      <app-editTranslation
        *ngIf="showTranslate && mode === 'add' && !onScratchpad"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.add_factor"
        (callback)="getTranslations()"
      ></app-editTranslation>
      <app-editTranslation
        *ngIf="showTranslate && mode === 'edit' && !onScratchpad"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.edit_factor"
        (callback)="getTranslations()"
      ></app-editTranslation>
      <app-editTranslation
        *ngIf="showTranslate && onScratchpad"
        [component]="'ae_factors'"
        [isTranslationV2]="true"
        [transObj]="trans.trans.back_to_scratchpad"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </div>
    <div [ngClass]="!embed ? 'sidebar_container' : 'sidebar_container_skinny'">
      <div class="keywest_panel">
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditFactor.controls.name">
            <label for="name" class="sidebar_label">{{ trans.trans.name.value }}</label>
            <input type="text" class="form-control" ret-data="Name" [(ngModel)]="formObjFactor.name" name="name" [placeholder]="trans.trans.name.value" required />
            <span *ngIf="formObjFactor.has_tag" class="hint light-h"
              >[{{ trans.trans.onShow.value }}{{ formObjFactor.valueTag ? formObjFactor.valueTag : trans.trans.value.value }}{{ trans.trans._name.value }}]
            </span>
          </div>
        </div>
        <div class="row" *ngIf="hasFeature103">
          <div class="col-md-12" appNGError [elemControl]="formAddEditFactor.controls.value_realization_name">
            <label for="name" class="sidebar_label">Value Realization Name (Optional)</label>
            <input
              type="text"
              class="form-control"
              ret-data="value_realization_name"
              [(ngModel)]="formObjFactor.value_realization_name"
              name="value_realization_name"
              placeholder="Value Realization Name"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" appNGError [elemControl]="formAddEditFactor.controls.description">
            <label for="description" class="sidebar_label"
              >{{ trans.trans.description.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.description"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <textarea name="description" ret-data="Description" [(ngModel)]="formObjFactor.description" rows="4" class="form-control"></textarea>
            <span *ngIf="formObjFactor.has_tag" class="hint light-h"
              >[{{ trans.trans.onShow.value }}{{ formObjFactor.valueTag ? formObjFactor.valueTag : trans.trans.value.value }}{{ trans.trans._description.value }}]
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="account_factor_category_id" class="sidebar_label"
              >{{ trans.trans.factor_groups.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.factor_groups"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <p-multiSelect
              name="account_factor_category_id"
              [options]="factorGroups"
              [(ngModel)]="formObjFactor.account_factor_category_id"
              optionLabel="name"
              class="form-control"
            ></p-multiSelect>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="name" class="sidebar_label">{{ trans.trans.unit_type.value }}</label>
            <p-dropdown
              appendTo="body"
              name="unit_type_id"
              optionLabel="name"
              [options]="unitTypes"
              [filter]="true"
              filterBy="name"
              [autoDisplayFirst]="false"
              [(ngModel)]="formObjFactor.unit_type_id"
              class="keywest_dropdown"
              [panelStyleClass]="'dropdown-item-font'"
            ></p-dropdown>
          </div>
          <div class="col-md-6" appNGError [elemControl]="formAddEditFactor.controls.factor_precision">
            <label for="name" class="sidebar_label"
              >{{ trans.trans.precision.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.precision"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </label>
            <p-inputNumber
              inputStyleClass="form-control"
              [(ngModel)]="formObjFactor.factor_precision"
              name="factor_precision"
              [placeholder]="trans.trans.precision.value"
              [required]="true"
              [attr.ret-data]="trans.trans.precision.value"
              [min]="0"
              [max]="5"
              [showButtons]="true"
            ></p-inputNumber>
          </div>
        </div>

        <div [hidden]="isOnTheFlyBenefit">
          <div class="row">
            <div class="col-md-4">
              <label for="name" class="sidebar_label">{{ trans.trans.scaled_by.value }}</label>
              <p-dropdown
                appendTo="body"
                name="scales_by"
                optionLabel="name"
                [options]="scaledBy"
                [filter]="true"
                filterBy="name"
                [autoDisplayFirst]="false"
                [(ngModel)]="formObjFactor.scales_by"
                class="keywest_dropdown"
                [panelStyleClass]="'dropdown-item-font dropdown-items-fixed-width dropdown-items-w400'"
              ></p-dropdown>
            </div>
            <div class="col-md-4" appNGError [elemControl]="formAddEditFactor.controls.scales_how" *ngIf="+formObjFactor.scales_by?.id !== 0">
              <label for="name" class="sidebar_label">{{ trans.trans.scales_how.value }}</label>
              <p-dropdown
                appendTo="body"
                name="scales_how"
                optionLabel="name"
                [options]="scalesHow"
                [filter]="true"
                filterBy="name"
                [autoDisplayFirst]="false"
                [(ngModel)]="formObjFactor.scales_how"
                class="keywest_dropdown"
                [panelStyleClass]="'dropdown-item-font'"
                [required]="true"
                ret-data="Scales How?"
              ></p-dropdown>
            </div>
            <div class="col-xs-4" appNGError [elemControl]="formAddEditFactor.controls.ratio" *ngIf="+formObjFactor.scales_how?.id === 2 && +formObjFactor.scales_by?.id !== 0">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.ratio.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.ratio"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input type="text" ret-data="Ratio" class="form-control" [(ngModel)]="formObjFactor.ratio" name="ratio" [placeholder]="trans.trans.ratio.value" required />
            </div>
            <div class="col-xs-4" appNGError [elemControl]="formAddEditFactor.controls.formula" *ngIf="+formObjFactor.scales_how?.id === 3 && +formObjFactor.scales_by?.id !== 0">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.formula.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.formula"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input type="text" ret-data="Formula" class="form-control" [(ngModel)]="formObjFactor.formula" name="formula" [placeholder]="trans.trans.formula.value" required />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.is_key_factor.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.is_key_factor"
                  (callback)="getTranslations()"
                ></app-editTranslation> </label
              ><br />
              <p-inputSwitch [(ngModel)]="+formObjFactor.is_key_factor" name="is_key_factor"></p-inputSwitch>
              {{ +formObjFactor.is_key_factor ? trans.trans.yes.value : trans.trans.no.value }}
            </div>
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.include_with_scalers.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.include_with_scalers"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.includeWithScalersTooltip.value">info</span>
              <br />
              <p-inputSwitch [(ngModel)]="+formObjFactor.scalers_page" name="scalers_page"></p-inputSwitch>
              {{ +formObjFactor.scalers_page ? trans.trans.yes.value : trans.trans.no.value }}
            </div>
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.literal_number.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.literal_number"
                  (callback)="getTranslations()"
                ></app-editTranslation> </label
              ><br />
              <p-inputSwitch [(ngModel)]="+formObjFactor.literal_number" name="literal_number"></p-inputSwitch>
              {{ +formObjFactor.literal_number ? trans.trans.yes.value : trans.trans.no.value }}
            </div>
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.normalize_for_value.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.normalize_for_value"
                  (callback)="getTranslations()"
                ></app-editTranslation> </label
              ><br />
              <p-inputSwitch [(ngModel)]="+formObjFactor.vr_normalized" name="vr_normalized"></p-inputSwitch>
              {{ +formObjFactor.vr_normalized ? trans.trans.yes.value : trans.trans.no.value }}
            </div>
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.greater_than_zero.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.greater_than_zero"
                  (callback)="getTranslations()"
                ></app-editTranslation> </label
              ><br />
              <p-inputSwitch [(ngModel)]="+formObjFactor.greater_than_zero" name="greater_than_zero"></p-inputSwitch>
              {{ +formObjFactor.greater_than_zero ? trans.trans.yes.value : trans.trans.no.value }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.min.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.min"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input type="number" class="form-control" [(ngModel)]="formObjFactor.min" name="min" [placeholder]="trans.trans.min.value" />
            </div>
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.max.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.max"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input type="number" class="form-control" [(ngModel)]="formObjFactor.max" name="max" [placeholder]="trans.trans.max.value" />
            </div>
            <div class="col-md-4">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.increment.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.increment"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <input type="number" class="form-control" [(ngModel)]="formObjFactor.increment" name="increment" [placeholder]="trans.trans.increment.value" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="name" class="sidebar_label">{{ trans.trans.company_element.value }}</label>
              <div class="input-group keywest_search">
                <span matTooltip="Clear Selection" class="input-group-addon"><i (click)="formObjFactor.company_field = null" class="clickable fa fa-times"></i></span>
                <p-dropdown
                  appendTo="body"
                  name="company_field"
                  optionLabel="name"
                  [options]="companyField"
                  [filter]="true"
                  filterBy="name"
                  [autoDisplayFirst]="false"
                  [(ngModel)]="formObjFactor.company_field"
                  class="keywest_dropdown"
                  [panelStyleClass]="'dropdown-item-font'"
                ></p-dropdown>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="name" class="sidebar_label">{{ trans.trans.icon.value }}</label>
              <p-dropdown
                appendTo="body"
                name="icon"
                optionLabel="label"
                dataKey="id"
                [options]="faIcons"
                [filter]="true"
                filterBy="label, value"
                [autoDisplayFirst]="false"
                [(ngModel)]="formObjFactor.icon"
                class="keywest_dropdown"
                [panelStyleClass]="'dropdown-item-font'"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <i [class]="item.value" style="float: left; display: block; font-size: 18px; margin-right: 10px"></i>
                  <span style="vertical-align: middle">{{ item.label }}</span>
                </ng-template>
                <ng-template let-icons pTemplate="item">
                  <div class="p-helper-clearfix" style="position: relative; height: 25px">
                    <i [class]="icons.value" style="float: left; display: block; font-size: 18px; margin-right: 10px"></i>
                    <span style="vertical-align: middle">{{ icons.label }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-md-4">
              <label for="has_tag" class="sidebar_label">{{ trans.trans.createCustomAssetTag.value }}</label>
              <br />
              <p-inputSwitch [(ngModel)]="formObjFactor.has_tag" (onChange)="generateCustomAsset($event)" name="has_tag"></p-inputSwitch>
            </div>
            <!-- Factor Value Tag -->
            <div
              *ngIf="formObjFactor.has_tag"
              class="col-md-4"
              appNGError
              [elemControl]="formAddEditFactor.controls.valueTag"
              [ngClass]="{ 'field-error': formObjFactor.nameTag === formObjFactor.valueTag }"
            >
              <label for="name" class="sidebar_label labelsRequired">{{ trans.trans.factorValueTag.value }}</label>
              <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.valueTagTooltip.value">info</span>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="formObjFactor.valueTag"
                name="valueTag"
                ret-data="Value Tag"
                maxlength="255"
                appSpaceToUnderscore
                appSpecialCharacterValidator
                [required]="formObjFactor.has_tag"
              />
              <span *ngIf="formObjFactor.has_tag" class="hint light-h"
                >[{{ trans.trans.onShow.value }}{{ formObjFactor.valueTag ? formObjFactor.valueTag : trans.trans.value.value }}]</span
              >
              <span class="input-error-warn" *ngIf="formAddEditFactor.controls.valueTag?.errors?.noSpecialCharacter"> {{ trans.trans.tagsSpecialCharactersError.value }} </span>
            </div>
          </div>
          <div class="row" appNGError [elemControl]="formAddEditFactor.controls.nameTag" *ngIf="formObjFactor.has_tag">
            <div [hidden]="true" class="col-md-4" [ngClass]="{ 'has-error': formObjFactor.nameTag === formObjFactor.valueTag }">
              <label for="name" class="sidebar_label">{{ trans.trans.nameTag.value }} </label>
              <input type="text" class="form-control" [(ngModel)]="formObjFactor.nameTag" name="nameTag" ret-data="Name Tag" [required]="formObjFactor.has_tag" />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isOnTheFlyBenefit">
        <!-- Select Buttons -->
        <p-selectButton
          styleClass="keywest_select_button"
          class="table-select"
          [options]="tableOptions"
          name="tableOption"
          [(ngModel)]="selectedTable"
          optionLabel="label"
          optionValue="value"
          (onChange)="onTableSelectionChange()"
        ></p-selectButton>
        <!-- Relation Table -->
        <app-factors-relation-table *ngIf="selectedTable === 'relates' && formObjFactor.id" [trans]="trans" [factorId]="formObjFactor.id.toString()"> </app-factors-relation-table>
        <!-- Situation Table -->
        <app-factors-situation-table *ngIf="selectedTable === 'situations' && formObjFactor.id" [trans]="trans" [factorId]="formObjFactor.id.toString()">
        </app-factors-situation-table>
        <!-- Where Used Table -->
        <app-factors-used-table *ngIf="selectedTable === 'used' && formObjFactor.id" [trans]="trans" [factorId]="formObjFactor.id.toString()"> </app-factors-used-table>
      </div>
    </div>

    <div [ngClass]="!embed ? 'btn_container' : null">
      <div class="row">
        <div class="col-md-12">
          <input type="hidden" class="form-control" name="id" [(ngModel)]="formObjFactor.id" />
          <app-btn-save
            #btnSave
            *ngIf="mode === 'add'"
            [form]="formAddEditFactor"
            (callback)="insertFactor(formAddEditFactor, btnSave)"
            [text]="trans.trans.save.value"
            [running]="loading"
            class="btn btn_customizable pull-right xf-continue-button"
          ></app-btn-save>
          <app-btn-save
            #btnSave
            *ngIf="mode === 'edit'"
            [form]="formAddEditFactor"
            (callback)="updateFactor(formAddEditFactor)"
            [text]="trans.trans.update.value"
            [running]="loading"
            class="btn btn_customizable pull-right xf-continue-button"
          ></app-btn-save>
          <app-are-you-sure
            matTooltipPosition="above"
            [message]="trans.trans.are_you_sure.value"
            class="btn cancel-confirm pull-right marg-h"
            icon=""
            [text]="onScratchpad ? trans.trans.cancel.value : trans.trans.back.value"
            (callbackYes)="close()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </div>
</form>

<div *ngIf="!initialized" [ngClass]="!embed ? 'sidebar_wrapper embed' : null">
  <div [ngClass]="!embed ? 'sidebar_container' : 'sidebar_container_skinny'">
    <div class="keywest_panel">
      <div class="flex-omni-c">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
      </div>
    </div>
  </div>
</div>
