<div class='sidebar_wrapper'>
	<div class='sidebar_header'>
		{{trans.trans.viewCollateral.value}}
		<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
		[isTranslationV2]="true" [transObj]="trans.trans.viewCollateral" (callback)="getTranslations()"></app-editTranslation>
	</div>

	<div class='sidebar_container'>
		<div class='keywest_panel'>

			<div class='row'>
				<div class="col-md-12">
					<label class="sidebar_label_readonly">{{ trans.trans.format.value }}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.format" (callback)="getTranslations()"></app-editTranslation>
					</label>
				</div>
			</div>

			<div class='row'>
				<div class="col-md-12">
					<label class="sidebar_label_readonly"> {{ trans.trans.name.value }}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{collateralFormObj.name}}
				</div>
			</div>

			<div class='row'>
				<div class="col-md-12">
					<label for="name" class="sidebar_label_readonly"> {{ trans.trans.url.value }}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.url" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{collateralFormObj.url}}
				</div>
			</div>

			<div class='row'>
				<div class="col-md-12">
					<label for="alterEgo" class='sidebar_label_readonly'>{{ trans.trans.description.value }}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.description" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{collateralFormObj.description}}
				</div>
			</div>
		</div>
	</div>
</div>