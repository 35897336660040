import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { takeUntil } from 'rxjs/operators';
import { SupportTranslations } from './support.translation';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
})
export class SupportComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [];
  menuView = 'faq';
  ngUnsubscribe = new Subject();
  showTranslate = false;
  style2022: boolean;
  constructor(private styleService: StyleService, private commonService: CommonService, private translationService: TranslationsV2Service, public trans: SupportTranslations) {}
  ngOnInit() {
    this.styleService.style2022.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.style2022 = res;
    });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.funcBuildMenu();
      });
  }

  funcBuildMenu() {
    this.menuItems = [
      {
        label: this.trans.trans.faq.value,
        styleClass: this.menuView === 'faq' ? 'menuSelected' : '',
        command: () => {
          this.menuView = 'faq';
          this.funcBuildMenu();
        },
      },
      {
        label: this.style2022 ? 'Submit Comments & Concerns' : this.trans.trans.submitCommentsAndConcerns.value,
        styleClass: this.menuView === 'issues' ? 'menuSelected' : '',
        command: () => {
          this.menuView = 'issues';
          this.funcBuildMenu();
        },
      },
    ];
  }
}
