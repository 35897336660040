import { SurveyTemplate } from '@data/services/valueprop/models/survey-template';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FactsService } from '@data/services/facts/facts.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SurveyTemplateCreateDefaultTranslations } from './survey-template-create.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { StyleService } from 'app/style.service';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Question } from '@data/services/valueprop/models/question.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-survey-template-create',
  templateUrl: './survey-template-create.component.html',
  styleUrls: ['./survey-template-create.component.scss'],
})
export class SurveyTemplateCreateComponent implements OnInit, OnDestroy {
  @Input() surveyTemplateId = '';
  @Input() surveyTemplate: SurveyTemplate;
  @Output() valueChange = new EventEmitter();
  @Output() hideform = new EventEmitter<boolean>();

  surveyDetails: string;
  accountId: string;
  sliderStartValue = 2;

  questionTypes: Array<{ label: string; value: string }> = [];
  loadSurveysTable = false;
  surveys: SurveyTemplate[] = [];
  valuePropId: string;
  fullImagePath: string;
  image_url: string;

  savedQuestions: Array<Question> = [];
  surveyTemplateLoader = true;
  actionLoader = false;
  enableAddQuestion = false;

  questions: Array<Question> = [
    {
      id: 1,
      question: '',
      questionType: '',
      questionOverlay: false,
      options: [
        {
          option: '',
        },
      ],
    },
    {
      id: 2,
      question: '',
      questionType: '',
      questionOverlay: true,
      options: [
        {
          option: '',
        },
      ],
    },
  ];
  options: BasicInfo[] = [
    {
      id: '1',
      name: 'Active',
    },
    {
      id: '0',
      name: 'Inactive',
    },
  ];
  is_active: BasicInfo = this.options[0];

  questionTrack = 1;
  template_name = '';

  subscriptiongetSurveyQuestion: Subscription;
  subscriptiongetQuestionTypes: Subscription;
  subscriptionsaveSurveyQuestion: Subscription;
  showTranslate = false;
  ngUnsubscribe = new Subject();
  style2022$: Observable<boolean>;

  constructor(
    private factsService: FactsService,
    private valuerealizationService: ValuerealizationService,
    private commonService: CommonService,
    public trans: SurveyTemplateCreateDefaultTranslations,
    private translationService: TranslationsV2Service,
    private styleService: StyleService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit(): void {
    this.style2022$ = this.styleService.style2022;
    this.accountId = sessionStorage.getItem('aid');
    this.savedQuestions = [];

    if (this.surveyTemplateId) {
      this.template_name = this.surveyTemplate.name;
      this.is_active = this.options.find((option) => option.id === this.surveyTemplate.is_active);
      this.getSurveyDetail();
    } else {
      this.surveyTemplateLoader = false;
    }
    this.getQuestionTypes();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }
  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
  ngOnDestroy(): void {
    if (this.subscriptiongetSurveyQuestion) {
      this.subscriptiongetSurveyQuestion.unsubscribe();
    }
    if (this.subscriptiongetQuestionTypes) {
      this.subscriptiongetQuestionTypes.unsubscribe();
    }
    if (this.subscriptionsaveSurveyQuestion) {
      this.subscriptionsaveSurveyQuestion.unsubscribe();
    }
  }

  getSurveyDetail(): void {
    this.subscriptiongetSurveyQuestion = this.valuerealizationService.getSurveyQuestion(this.accountId, this.surveyTemplateId).subscribe((response) => {
      if (response.result) {
        this.questions = response.result.map((item) => {
          item['question'] = item['label'];
          item['options'] = item['question_options'] ? Array.from(item['question_options']) : [];
          return item;
        });
      }
      this.surveyTemplateLoader = false;
    });
  }

  deleteQuestion(id): void {
    this.questions.splice(id, 1);
  }

  questionTypeChange(question_type_id, id): void {
    if (question_type_id === '3') {
      setTimeout(function () {
        (<any>$('.' + id + ' input:checkbox')).uniform();
      }, 10);
    }
  }

  checkAddQuestion(index): boolean {
    if (!index) {
      index = this.questions.length;
    }
    if (this.questions[index - 1]['question'] !== '' && this.questions[index - 1]['questionType']) {
      return true;
    } else {
      return false;
    }
  }

  enableQuestion(): void {
    this.questions.push({
      id: Date.now(),
      question: '',
      questionType: '',
      questionTypeId: null,
      options: [
        {
          option: '',
        },
      ],
    });
  }

  getQuestionTypes() {
    this.subscriptiongetQuestionTypes = this.factsService.getQuestionTypes().subscribe((response) => {
      if (response.result) {
        this.questionTypes = response.result.map((type) => ({
          label: type.name,
          value: type.id,
        }));
      }
    });
  }

  addOptions(i): void {
    this.questions[i]['options'].push({
      option: '',
    });
  }

  deleteOptions(question, option): void {
    question.options = question.options.filter((el) => el !== option);
  }

  createSurveyTemplate(): boolean {
    if (!this.template_name) {
      return false;
    }
    const accountId = sessionStorage.getItem('aid');
    const procesedQuestions = [];
    this.questions.forEach((question) => {
      if ((question.question_type_id === '2' || question.question_type_id === '5') && question.options.length > 1) {
        procesedQuestions.push({
          question_type_id: question.question_type_id,
          label: question.question,
          options: question.options.map((option) => {
            return option.name;
          }),
        });
      } else {
        procesedQuestions.push({
          question_type_id: question.question_type_id,
          label: question.question,
        });
      }
    });

    const payload = {
      account_survey_questions_template_name: this.template_name,
      questions: procesedQuestions,
      status: this.is_active.id,
    };

    this.valuerealizationService.createTemplate(accountId, payload).subscribe((response) => {
      if (response.result) {
        this.template_name = '';
        this.valueChange.emit('');
      }
    });
  }

  updateSurveyTemplate(): boolean {
    if (!this.template_name) {
      return false;
    }
    const accountId = sessionStorage.getItem('aid');

    const questions = [];

    this.questions.forEach((data) => {
      const question = {};
      if (data.question != '' && data.question_type_id != '') {
        question['question_type_id'] = data.question_type_id;
        question['label'] = data.question;
        question['question_id'] = data.id;

        if (data.question_type_id === '2' || data.question_type_id === '5') {
          const options = [];
          data.options.forEach((option) => {
            if (option.name != '') {
              options.push(option.name);
            }
          });
          question['options'] = options;
        }
        questions.push(question);
      }
    });

    const payload = {
      account_survey_questions_template_id: this.surveyTemplateId,
      name: this.template_name,
      questions: questions,
      status: this.is_active.id,
    };

    this.valuerealizationService.updateTemplate(accountId, payload).subscribe((response) => {
      if (response.result) {
        this.template_name = '';
        this.valueChange.emit('');
      }
    });
  }

  hideForm(): void {
    this.hideform.emit(false);
  }
}
