import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BenefitsComponent } from '../benefits.component';

@Component({
	selector: 'app-benefit-table',
	templateUrl: './benefits-table.component.html',
	styleUrls: ['./benefits-table.component.scss']

})
export class BenefitTableComponent extends BenefitsComponent {
	@Input() benefitsList: any;
	@Input() valueProp: any;
	@Input() contextualHelp: object;
	@Input() dealdesk: boolean;
	@Output() openBenefitCallback = new EventEmitter();
	@Output() openAddPhasingCallback = new EventEmitter();
	@Output() callbackChangeView = new EventEmitter();
	@Input() addPhasingModal: any;
	@Input() showBatchUpdateCheckboxes: boolean = false;
	@Input() benefitOnTheFlyPrivilege: boolean;
	@Input() menuFilter: any;
	@Input() showTranslate: boolean = false;
	
	@Output() openUpdateBenefitCallback = new EventEmitter();
	searchText: string;
	showDeleteCustomBenefit: boolean = false;
	@Output() deleteCustomBenefitCallback = new EventEmitter();
	deleteBenefit: any;
	benefitsPanel: boolean;

	@Input() showActive: boolean = false;
	@Input() showNA: boolean = false;
	@Input() showSoft: boolean = false;

	openBenefitDetail(benefit) {
		this.openBenefitCallback.emit(benefit);
	}

	openAddPhasing(content, id) {
		this.openAddPhasingCallback.emit({ content: content, id: id });
	}
	
	openUpdateBenefit(benefit) {
		
		this.openUpdateBenefitCallback.emit({ menuItem: "addbenefit", data: benefit });
		// this.valueProp.loadMenuItem.next();
	}
	
	filterActive(arr: any[], id) {
		if (!arr) { return 0; }
		let tot = 0;
		// for (let i = 0; i < arr.length; i++) {
		for (let m = 0; m < arr['metrics'].length; m++) {

			if (arr['metrics'][m].active == id) { tot++; }
		}
		// }
		return tot;
	}
	
	opendeleteCustomBenefit(benefit) {
		
		this.deleteBenefit = benefit;
		this.showDeleteCustomBenefit = !this.showDeleteCustomBenefit;
	}
	
	deleteCustomBenefit() {
		this.deleteCustomBenefitCallback.emit(this.deleteBenefit);
		this.deleteBenefit = null;
		this.showDeleteCustomBenefit = !this.showDeleteCustomBenefit;
	}

	changeViewCallback(event) {
		this.callbackChangeView.emit(event);
	}

	showGroup(metric) {
		// 
		let found = false;
		if (this.showActive) {
			let res = metric.filter(x => x.active_type == "Active");
			// 
			if (res.length) { found = true; }
		}

		if (this.showNA) {
			let res = metric.filter(x => x.active_type == "N/A");
			if (res.length) { found = true; }
		}

		if (this.showSoft) {
			let res = metric.filter(x => x.active_type == "Considered Soft");
			if (res.length) { found = true; }
		}
		return found;
	}
}