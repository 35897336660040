<p-menu [styleClass]="style2022 ? 'style-2022' : null" #menu appendTo='body' [popup]="true" [model]="menuItems"></p-menu>
<div class='models_panel wrapper'>
	<div id="page-wrapper" class='models_page_header_container_small page-wrapper'>
		<div id="header" class='keywest_header head'>
			<div class='row'>
				<div class='col-xs-10'>
					<!-- Search bar -->
					<div *ngIf="style2022"  style="max-width: 356px;" class="input-group keywest_search">
						<span class="input-group-addon"><i class='fa fa-search'></i></span>
						<input [(ngModel)]="strSearch" (keyup)="searchNotes($event)" type='text' placeholder='Search' class='form-control'>
						<span *ngIf="strSearch !== ''" (click)="strSearch = ''; loadNotes();" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
					</div>
					<div *ngIf="!style2022" class='title'>{{trans.trans.notes.value}}<i [matTooltip]='trans.trans.ch_notes.value' class='fa fa-info-circle'></i>
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.notes" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-2'>
					<button *ngIf="valueProp.vp_can_edit && !locked" class="btn btn_customizable pull-right" (click)="openAddNotes()">
						<i class="fa fa-plus"></i>{{ style2022 !== true ? trans.trans.add_note.value : trans.trans.add.value}}</button>
				</div>
			</div>
		</div>

		<div class='row'>
			<div class='col-xs-12'>
				<app-no-records *ngIf="!notes || notes.length === 0">No Notes Added</app-no-records>
				<p-table *ngIf="notes && notes.length" #tableUsers [value]="notes" [columns]='cols' [paginator]="notes.length > 3" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns; " [pSortableColumn]="col.field">
								{{trans.trans[col.field].value}}
								<p-sortIcon [field]="col.field"></p-sortIcon>
								<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
							</th>
                            <th></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowIndex="rowIndex" let-elem>
						<tr>
							<td  (click)="openEditNote(elem);">
								<a class="link">{{elem.name}}</a>
							</td>
							<td *ngIf="style2022">
								{{elem.user_name}}
							</td>
							<td *ngIf="!style2022">{{elem.user_name}}</td>
							<td *ngIf="style2022"><span [ngClass]="elem.customer_facing === '0' ? 'non-facing' : 'facing'"> {{elem.customer_facing === '0' ? 'No' : 'Yes'}} </span></td>
							<td *ngIf="!style2022">{{elem.customer_facing === '0' ? 'No' : 'Yes'}}</td>
							<td>{{elem.created | safariDate | date: 'MMM d, y, hh:mm'}}</td>
							<td>{{elem.note}}</td>
							<td *ngIf="valueProp.vp_can_edit && !locked">
								<i class="fa fa-ellipsis-v clickable pull-right" *ngIf="!locked" (click)='funcBuildSubMenu(elem, $event)'></i>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>
</div>

<p-sidebar [(visible)]="showAddNote" *ngIf="showAddNote" position='right' styleClass="keywest_sidebar p-sidebar-lg">
	<form name="AddNotesForm" #AddNotesForm="ngForm">
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				{{isNewNote !== true ? trans.trans.edit_note.value : trans.trans.add_note.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.add_note" (callback)="getTranslations()"></app-editTranslation>
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>

					<div class='row'>
						<div class="col-sm-12">
							<div class="form-group" [ngClass]="{'has-error': (AddNotesForm.controls.name?.invalid && AddNotesForm.controls.name?.touched)}">
								<label class="labelsRequired">{{trans.trans.name.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation></label>
								<input type="text" class="form-control" [(ngModel)]="note.name" id="" name="name" [placeholder]="trans.trans.name.value" required>
							</div>

							<div class="form-group" [ngClass]="{'has-error': (AddNotesForm.controls.note?.invalid && AddNotesForm.controls.note?.touched)}">
								<label class="labelsRequired">{{trans.trans.note.value}}<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.note" (callback)="getTranslations()"></app-editTranslation></label>
								<textarea class="form-control" [(ngModel)]="note.note" name="note" [placeholder]="trans.trans.note.value" required></textarea>
							</div>
							<div *ngIf="shareView == 'simple'" class="form-group">
								<span *ngIf="!style2022">
									<label>{{ trans.trans.customer_facing.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.customer_facing" (callback)="getTranslations()"></app-editTranslation></label>
									<div class="checkbox">
										<span [className]="note.customer_facing ? 'checked' : ''">
											<input type="checkbox" [(ngModel)]="note.customer_facing" name="isCustomerFacing">
										</span>
									</div>
								</span>


								<fieldset *ngIf="style2022" class="checkbox custom-checkbox" (click)="note.customer_facing = !note.customer_facing">
									<input type="checkbox" [(ngModel)]="note.customer_facing" name="isCustomerFacing">
									<label class="sidebar_label" style="margin: auto;">{{ trans.trans.customer_facing.value}}</label>
									<span class="check"></span>
								</fieldset>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<div class="col-xs-12">
					<button *ngIf="!notesLoader" class="btn btn_customizable pull-right" [disabled]="AddNotesForm.invalid" (click)="saveNote()">
						{{trans.trans.save.value}}</button>
					<app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='showAddNote = !showAddNote'></app-are-you-sure>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>