import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SolutionService } from '@data/services/solution/solution.service';
import { Factor } from '@data/services/valueposition/models/factor-group.interface';
import { NotificationService } from '@services/notification.service';
import { FactorValidation, FactorValidationUpdate, FactorValidationValueChange } from '@shared/models/factors.models';
import { BehaviorSubject, of, zip } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ValidationTranslations } from '../validation.translations';
import { FactorValidationTableTypes } from './factor-validation-table/factor-validation-table.component';

@Component({
  selector: 'app-factors',
  templateUrl: './factors.component.html',
  styleUrls: ['./factors.component.scss'],
})
export class FactorsComponent {
  @Input() trans: ValidationTranslations;
  @Input() validations: FactorValidation;
  @Input() solutionID: string = null;

  @Output() onUpdate = new EventEmitter();

  public tableTypes = FactorValidationTableTypes;
  public factors: Factor[];
  public validationFactorMap = new Map<string, FactorValidationValueChange>();
  public cancelTriggered$ = new BehaviorSubject<boolean>(false);

  constructor(private notificationService: NotificationService, private solutionService: SolutionService) {}

  public save() {
    const values: FactorValidationValueChange[] = Array.from(this.validationFactorMap.values());
    // go through array and save values based on table type
    const factorsWithVal0: FactorValidationUpdate[] = [];
    const scaleFactorsWithNoDefaultValue: FactorValidationUpdate[] = [];
    values.forEach((value) => {
      switch (value.tableType) {
        case this.tableTypes.VALUE_OF_0:
          factorsWithVal0.push(value.update);
          break;
        case this.tableTypes.NO_DEFAULT_VALUE:
          scaleFactorsWithNoDefaultValue.push(value.update);
          break;
        default:
          factorsWithVal0.push(value.update);
          break;
      }
    });
    const updateCalls = [];
    if (factorsWithVal0.length) {
      let payload = { factors: factorsWithVal0 };
      updateCalls.push(this.solutionService.updateFactorList(this.solutionID, payload));
    }

    if (scaleFactorsWithNoDefaultValue.length) {
      updateCalls.push(
        this.solutionService.getFactorList(this.solutionID).pipe(
          switchMap((res) => this.getAccountSolutionScalers(res.result.factors, scaleFactorsWithNoDefaultValue)),
          switchMap((res) => this.solutionService.updateScalers(this.solutionID, { account_solution_scalers: res }))
        )
      );
    }
    zip(...updateCalls)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.success(this.trans.trans.updatesMadeSuccessful.value, false);
        this.onUpdate.emit();
      });
  }

  public getAccountSolutionScalers(factors, updateScalers) {
    const foundFactors = factors.filter((f) => {
      return updateScalers.some((s) => {
        return s.factor_id === f.id;
      });
    });
    let accountSolutionScalers = [];
    foundFactors.forEach((f) => {
      accountSolutionScalers.push({
        scale_type_id: f.scale_type_id,
        value: this.validationFactorMap.get(f.id).update.value,
      });
    });
    return of(accountSolutionScalers);
  }

  public cancel() {
    this.validationFactorMap = new Map<string, FactorValidationValueChange>();
    this.cancelTriggered$.next(true);
  }

  public onValueUpdate(valueChange: FactorValidationValueChange) {
    this.validationFactorMap.set(valueChange.update.factor_id, valueChange);
  }
}
