<div class="sidebar_wrapper">
  <div class="sidebar_header">
    <ng-container *ngIf="!goalGroupSelected">
      {{trans.trans.addGoalGroup.value}}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.addGoalGroup"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </ng-container>

    <ng-container *ngIf="goalGroupSelected">
      {{trans.trans.editGoalGroup.value}}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.addGoalGroup"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </ng-container>
  </div>

  <div class="sidebar_container">
    <div class="keywest_panel">
      <form [formGroup]="goalGroupForm">
        <div class="row">
          <div class="col-xs-12">
            <app-error-input-wrapper [errorMessage]="errorDict.get('name')" [showOnTouched]="true">
              <label class="sidebar_label labelsRequired">{{trans.trans.nameLabel.value}}</label>
              <input class="form-control" type="text" [placeholder]="trans.trans.nameLabel.value" formControlName="name">
            </app-error-input-wrapper>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-12">
            <label class="sidebar_label">{{trans.trans.descriptionLabel.value}}</label>
            <textarea class="form-control" id="" cols="30" rows="5" [placeholder]="trans.trans.descriptionLabel.value" formControlName="description"></textarea>
          </div>
        </div>
      </form>
    </div>

    <div class="btn_container">
      <div class="row">
        <p-progressBar *ngIf="isLoading" class="keywest_progressbar" mode="indeterminate"></p-progressBar>

        <div class="col-xs-12">
          <button class="btn btn_customizable pull-right mr-50" (click)="saveGoalGroup()" [disabled]="goalGroupForm.invalid">{{trans.trans.save.value}}</button>
          <button class="btn btn_cancel pull-right" style="margin-right: 10px;" (click)="goBack()">{{trans.trans.back.value}}</button>
        </div>
      </div>
    </div>
  </div>
</div>