<div class="flex-jend marg-v">
  <ng-container *ngIf="businessReviewService.canEdit | async">
    <button class="btn btn_customizable" *ngIf="businessReviewCount > 0" (click)="setReviewToDelete()" style="margin-right: 10px;">
      {{ trans.trans.deleteLatest.value }}
    </button>
    <button [disabled]="addingNewData" class="btn btn_customizable marg-r" (click)="createNewBusinessReview()">
      <i *ngIf="addingNewData" class="fa fa-spinner fa-spin"></i>
      {{ trans.trans.addNewData.value }}
    </button>
    <ng-container *ngIf="businessReviewCount > 0">
      <button *ngIf="!showInputs; else editActions" class="btn btn_customizable" (click)="toggleInputs(true)">
        {{ trans.trans.editExistingData.value }}
      </button>
      <ng-template #editActions>
        <button class="btn btn_customizable marg-r" (click)="cancelBusinessReview()">
          {{ trans.trans.cancel.value }}
        </button>
        <button class="btn btn_customizable" (click)="saveReviewData()">
          {{ trans.trans.saveChanges.value }}
        </button>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<div class="row">
  <div class="col-md-12" appHeightCache [trigger]="tableRefreshing$">
    <p-accordion id="goal-group-accordion" [multiple]="true">
      <p-accordionTab style="padding: 1rem" [header]="group.name" *ngFor="let group of goalGroups; index as goalGroupIndex" [selected]="true">
        <p-table
          #tableGoalsList
          [value]="group.goalList"
          [columns]="cols"
          styleClass="keywest_table"
          [paginator]="true"
          [rows]="10"
          (sortFunction)="customSort($event)"
          [customSort]="true"
          tableStyleClass="keywest_table"
          dataKey="value.id"
          *ngIf="!(tableRefreshing$ | async)"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns">
                <th *ngIf="col.sortBy; else noSort" [pSortableColumn]="col.sortBy" (click)="setGoalsTrend(goalList)" [ngClass]="col.width">
                  {{ col.header }}
                  <p-sortIcon [field]="col.sortBy"></p-sortIcon>
                </th>
                <ng-template #noSort>
                  <th [ngClass]="col.width">
                    <button
                      *ngIf="col.field === 'prevReview' && businessReviewCount > 3"
                      [disabled]="businessReviewIndex === 0"
                      (click)="prevReview()"
                      pButton
                      pRipple
                      type="button"
                      icon="fa fa-caret-left"
                      class="p-button-rounded p-button-text"
                    ></button>
                    <ng-container *ngIf="col.field !== 'cDynamic'">
                      {{ col.header }}
                    </ng-container>
                    <ng-container *ngIf="col.field === 'cDynamic'">
                      <ng-container *ngIf="!(tableHeaderFormArrays[goalGroupIndex] | arrayToFormControl: tableHeaderIndexMap.get(col.businessReviewId):'editing')?.value">
                        <div class="ticker-pencil-grid">
                          <div ticker>{{ col.header }}</div>
                          <i
                            *ngIf="canEdit"
                            (click)="editSurveyName(tableHeaderIndexMap.get(col.businessReviewId), col.header, goalGroupIndex)"
                            class="clickable fa fa-pencil"
                            style="margin-left: 10px"
                            [matTooltip]="t.editName"
                          ></i>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="(tableHeaderFormArrays[goalGroupIndex] | arrayToFormControl: tableHeaderIndexMap.get(col.businessReviewId):'editing')?.value">
                        <app-error-input-wrapper
                          [errorMessage]="(tableHeaderFormArrays[goalGroupIndex] | returnErrorObservable: tableHeaderIndexMap.get(col.businessReviewId) | async)?.get('name')"
                          [showFormGroup]="false"
                        >
                          <div class="input-group header-input">
                            <input
                              [formControl]="tableHeaderFormArrays[goalGroupIndex] | arrayToFormControl: tableHeaderIndexMap.get(col.businessReviewId):'name'"
                              type="text"
                              (keydown.Enter)="saveSurveyName(tableHeaderIndexMap.get(col.businessReviewId), goalGroupIndex, col.businessReviewId)"
                              class="form-control"
                            />
                            <span class="clickable input-group-addon" (click)="editSurveyName(tableHeaderIndexMap.get(col.businessReviewId), col.header, goalGroupIndex)"
                              ><i class="fa fa-times"></i
                            ></span>
                            <span class="clickable input-group-addon" (click)="saveSurveyName(tableHeaderIndexMap.get(col.businessReviewId), goalGroupIndex, col.businessReviewId)"
                              ><i class="fa fa-save"></i
                            ></span>
                          </div>
                        </app-error-input-wrapper>
                      </ng-container>
                    </ng-container>
                    <button
                      *ngIf="col.field === 'nextReview' && businessReviewCount > 3"
                      [disabled]="businessReviewIndex >= businessReviewCount - 4"
                      (click)="nextReview()"
                      pButton
                      pRipple
                      type="button"
                      icon="fa fa-caret-right"
                      class="p-button-rounded p-button-text"
                    ></button>
                  </th>
                </ng-template>
              </ng-container>
            </tr>
            <tr>
              <td [ngClass]="col.width" class="period-of-perfomance" *ngFor="let col of columns; index as colIndex">
                <ng-container *ngIf="colIndex === 0"> {{ trans.trans.periodOfPerformance.value }} </ng-container>
                <ng-container *ngIf="col.field === 'cDynamic'">
                  {{ businessReviewData[col.dynamicIndex].start_date | safariDate | date: 'MM/YY' }} -
                  <ng-container *ngIf="col.dynamicIndex === businessReviewData.length - 1; else notEndDate">
                    <ng-container *ngIf="!showEndDateInput">
                      {{ businessReviewData[col.dynamicIndex].end_date | safariDate | date: 'MM/YY' }}
                      <ng-container *ngIf="col.dynamicIndex === businessReviewData.length - 1"
                        ><i *ngIf="canEdit" (click)="op.toggle($event)" class="clickable fa fa-pencil" style="margin-left: 10px" [matTooltip]="t.editDate"></i
                      ></ng-container>

                      <p-overlayPanel #op appendTo="body">
                        <ng-template pTemplate>
                          <div [formGroup]="endDateGroup" class="calendar-overlay">
                            <p-calendar
                              formControlName="endDate"
                              view="month"
                              dateFormat="mm/yy"
                              [placeholder]="t.editDate"
                              [disabled]="savingEndDate"
                              [minDate]="businessReviewData[col.dynamicIndex].start_date | dateStringToDate"
                              [defaultDate]="businessReviewData[col.dynamicIndex].end_date | dateStringToDate"
                              [readonlyInput]="true"
                            ></p-calendar>
                          </div>
                          <div>
                            <p-button icon="pi pi-times" (click)="hideOverlayPanel()" class="cancel-button" [label]="t.cancel" [disabled]="savingEndDate"></p-button>
                            <p-button
                              [icon]="savingEndDate ? 'fa fa-spinner fa-spin' : 'pi pi-check'"
                              (click)="editEndDate(businessReviewData[col.dynamicIndex].id, businessReviewData[col.dynamicIndex].name)"
                              [label]="t.save"
                              [disabled]="savingEndDate"
                            ></p-button>
                          </div>
                        </ng-template>
                      </p-overlayPanel>
                    </ng-container>
                  </ng-container>
                  <ng-template #notEndDate>
                    {{ businessReviewData[col.dynamicIndex].end_date | safariDate | date: 'MM/YY' }}
                  </ng-template>
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
              <td *ngFor="let col of cols; index as i" class="table-value">
                <span [ngClass]="col.class">
                  <ng-container *ngIf="col.field === 'trendChart'; else fieldText">
                    <dl-chart *ngIf="allTrendData[goalGroupIndex][rowIndex]?.datasets && allTrendData[goalGroupIndex][rowIndex].datasets[0].data.length > 1"
                      #goalTrend
                      idd="goalTrend"
                      type="line"
                      [responsive]="true"
                      height="30px"
                      width="100px"
                      [options]="goalTrendOptions"
                      [data]="allTrendData[goalGroupIndex][rowIndex]"
                    ></dl-chart>
                    <span *ngIf="!allTrendData[goalGroupIndex][rowIndex]?.datasets || allTrendData[goalGroupIndex][rowIndex].datasets[0].data.length < 2">{{ goalTrans.trans.notEnoughData.value }}</span>
                  </ng-container>
                  <ng-template #fieldText>
                    <div *ngIf="col.field === 'cDynamic'; else regular" [ngClass]="{ 'table-input-field': rowData.scratchpad_str && canEdit }">
                      <ng-container *ngIf="!showInputs; else editMode">{{
                        rowData.survey_factors[col.dynamicIndex]?.factor_value_fmt === '' ? 0 : rowData.survey_factors[col.dynamicIndex]?.factor_value_fmt
                      }}</ng-container>
                      <ng-template #editMode>
                        <p-inputNumber
                          *ngIf="rowData.survey_factors[col.dynamicIndex]"
                          [(ngModel)]="rowData.survey_factors[col.dynamicIndex].currentValue"
                          mode="decimal"
                          [locale]="numberLocales[+userData.number_format] ?? 'en-US'"
                          [minFractionDigits]="rowData.factor_precision"
                          [maxFractionDigits]="rowData.factor_precision"
                          id="survey-factor-input"
                        >
                        </p-inputNumber>
                        <i
                          *ngIf="rowData.scratchpad_str && canEdit && rowData.survey_factors[col.dynamicIndex]"
                          class="clickable fa fa-calculator"
                          style="margin-left: 10px; color: 'green'"
                          (click)="toggleScratchpadSidebar(rowData, rowData.survey_factors[col.dynamicIndex])"
                        ></i>
                      </ng-template>
                    </div>
                    <ng-template #regular>
                      <ng-container *ngIf="col.field !== 'factor_name' && col.field !== 'unit_type_id' && col.field !== 'baseline_value_fmt' && col.field !== 'value_fmt'">{{
                        rowData[col.field]
                      }}</ng-container>
                      <app-unit-type *ngIf="col.field === 'unit_type_id'" [unitType]="rowData?.unit_type_id"></app-unit-type>
                      <ng-container *ngIf="col.field === 'factor_name'">
                        <ng-container *ngIf="!(goalGroupArrays[goalGroupIndex] | arrayToFormControl: rowIndex:'editing')?.value">
                          <span (click)="toggleScratchpadSidebar(rowData, null)" class="name-span">
                            {{ rowData[col.field] }}
                          </span>
                          <span *ngIf="rowData.factor_description !== ''">
                            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="rowData.factor_description"> info </span>
                          </span>

                          <i (click)="editName(goalGroupIndex, rowIndex, rowData)" *ngIf="canEdit" class="clickable fa fa-pencil" style="margin-left: 10px"></i>
                          <ng-container *ngIf="rowData.original_goal_name !== rowData.factor_name">
                            <br />
                            <span class="small italic"> {{ trans.trans.originalGoalName.value }}: {{ rowData.original_goal_name }}</span>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="(goalGroupArrays[goalGroupIndex] | arrayToFormControl: rowIndex:'editing')?.value">
                          <app-error-input-wrapper
                            [errorMessage]="(goalGroupArrays[goalGroupIndex] | returnErrorObservable: rowIndex | async)?.get('name')"
                            [showFormGroup]="false"
                          >
                            <div class="input-group">
                              <input
                                [formControl]="goalGroupArrays[goalGroupIndex] | arrayToFormControl: rowIndex:'name'"
                                type="text"
                                (keydown.Enter)="saveName(goalGroupIndex, rowIndex, rowData.account_factor_id)"
                                class="form-control"
                              />
                              <span class="clickable input-group-addon" (click)="editName(goalGroupIndex, rowIndex, rowData)"><i class="fa fa-times"></i></span>
                              <span class="clickable input-group-addon" (click)="saveName(goalGroupIndex, rowIndex, rowData.account_factor_id)"><i class="fa fa-save"></i></span>
                            </div>
                          </app-error-input-wrapper>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="col.field === 'baseline_value_fmt' || col.field === 'value_fmt'">
                        <ng-container
                          *ngIf="
                            (col.field === 'baseline_value_fmt' && +rowData.capture_baseline === 1) || (col.field === 'value_fmt' && +rowData.capture_target === 1);
                            else NAValue
                          "
                        >
                          {{ rowData[col.field] }}
                        </ng-container>
                        <ng-template #NAValue>
                          {{ goalTrans.trans.na.value }}
                        </ng-template>
                      </ng-container>
                    </ng-template>
                  </ng-template>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>

<p-sidebar [(visible)]="reviewGoalSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-goal-sidebar
    *ngIf="!!selectedGoalOrignal && reviewGoalSidebar"
    (closeSidebar)="closeSidebar($event)"
    [selectedGoal]="selectedGoal"
    [selectedGoalOrignal]="selectedGoalOrignal"
    [valuePropId]="valuePropId"
    [inputView]="scratchpadEdit && !!selectedGoal?.scratchpadString"
  ></app-goal-sidebar>
</p-sidebar>

<p-dialog 
  [modal]="true" 
  [breakpoints]="{ '640px': '100vw' }" 
  [(visible)]="reviewToDelete"
  [closable]="false"
  [resizable]="false"
  [draggable]="false"
  [dismissableMask]="true"
  (onHide)="cancelDeleteLatestReview()"
  [showHeader]="false"
  contentStyleClass="content-border"
>
  <div class="confirm-dialog-container" style="text-align: center;">
    <span class="p-dialog-header">{{trans.trans.deleteMessage.value}}</span>
    <div>
      <h1 class="confirm-dialog">{{trans.trans.confirmMessageBegin.value}} <strong class="confirm-dialog-bold">{{reviewToDelete?.name}}</strong>?</h1>
    </div>
    <div style="margin-top: 20px;">
      <button (click)="cancelDeleteLatestReview()" class="btn btn_customizable confirm-button">
        {{trans.trans.cancel.value}}
      </button>
      <button (click)="deleteLatestReview()" class="btn btn_customizable reverse confirm-button">
        {{trans.trans.delete.value}}
      </button>
    </div>
  </div>
</p-dialog>
