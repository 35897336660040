<div class="sidebar_wrapper">
  <div class="sidebar_header">
    <ng-container *ngIf="!goalSelected">
      {{ trans.trans.addGoal.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.addGoal"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </ng-container>

    <ng-container *ngIf="goalSelected">
      {{ trans.trans.editGoal.value }}
      <app-editTranslation
        *ngIf="showTranslate"
        [component]="trans.config.component"
        [isTranslationV2]="true"
        [transObj]="trans.trans.editGoal"
        (callback)="getTranslations()"
      ></app-editTranslation>
    </ng-container>
  </div>

  <div class="sidebar_container">
    <ng-content></ng-content>
  </div>
</div>
