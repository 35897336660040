import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuepropNameChangerComponent } from './valueprop-name-changer.component';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ValuePropNameChangerTranslations } from '../valueprop-name-changer.translation';



@NgModule({
  declarations: [ValuepropNameChangerComponent],
  imports: [
    CommonModule,		
    FormsModule,
    ReactiveFormsModule,
    AreYouSureModule
  ],
  providers: [ValuePropNameChangerTranslations],
  exports: [ValuepropNameChangerComponent]
})
export class ValuepropNameChangerModule { }
