import { Injectable } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { APIResult } from '@shared/models/api-result.model';
import { Factor } from '@shared/models/calculator.models';
import {
  AccountFactorResponse,
  AccountFactorTagPayload,
  CompanyField,
  CreateTagData,
  EscaleHowResponse,
  EscalerFactor,
  FactorCategoriesResponse,
  FactorRelationship,
  FactorTagData,
  UsedBenefitFactor,
} from '@shared/models/factors.models';
import { SuccessFailResponse } from '@shared/models/success-fail-response.model';
import { SituationOnFactorRes } from '@shared/models/value-prop.model';
import { Observable } from 'rxjs';
import { ExpenseType } from '../valueprop/models/cost.model';

@Injectable()
export class FactorsService {
  service_url: string;

  constructor(private commonService: CommonService) {
    this.service_url = this.commonService.getServiceUrl();
  }

  public getFactorCategories(accountId: string): Observable<APIResult<FactorCategoriesResponse[]>> {
    let endpoint = 'account/factorCategories/' + accountId;
    return this.commonService.getAPIService(endpoint);
  }

  public getUnitTypes(): Observable<APIResult<ExpenseType[]>> {
    let endpoint = 'fact/unitTypes';
    return this.commonService.getAPIService(endpoint);
  }

  public getCompanyField(): Observable<APIResult<CompanyField[]>> {
    let endpoint = 'fact/companyFields';
    return this.commonService.getAPIService(endpoint);
  }

  public getScaledBy(accountId: string, excludeId?: string | number, isGoal = false): Observable<APIResult<EscalerFactor[]>> {
    let endpoint = 'account/factors/' + accountId + '/exclude/';

    if (isGoal) {
      endpoint += `${excludeId}?scratchpad_safe=1&function_type_id=all`;
    }

    return this.commonService.getAPIService(endpoint);
  }
  public getBenefitsUsingFactor(accountFactorId: string): Observable<APIResult<UsedBenefitFactor[]>> {
    const endpoint = `account/benefits-using-factor/${accountFactorId}`;
    return this.commonService.getAPIService(endpoint);
  }

  public getFactorRelationships(accountFactorId: string): Observable<APIResult<FactorRelationship>> {
    const endpoint = `account/factor-relationship/${accountFactorId}`;
    return this.commonService.getAPIService(endpoint);
  }

  public getFactorSituationUsage(accountFactorId: string): Observable<APIResult<SituationOnFactorRes>> {
    const endpoint = `account/situations-using-factor/${accountFactorId}`;
    return this.commonService.getAPIService(endpoint);
  }

  public getScalesHow(): Observable<APIResult<EscaleHowResponse>> {
    let endpoint = 'fact/scalingOptions';
    return this.commonService.getAPIService(endpoint);
  }

  public postFactorCategory(accountId: string, payload: { name: string; description: string }): Observable<APIResult<number>> {
    let endpoint = 'account/factorCategory/' + accountId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public putFactorGroup(accountId: string, payload: { name: string; description: string; account_factor_category_id: string }): Observable<APIResult<string>> {
    let endpoint = 'account/factorCategory/' + accountId;
    return this.commonService.putAPIService(endpoint, payload);
  }
  public deleteFactorGroup(accountId: string, accountFactorCategoryId: string | number): Observable<APIResult<string>> {
    let endpoint = 'account/factorCategory/' + accountId + '/delete/' + accountFactorCategoryId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public deleteFactorV2(factorId: string | number): Observable<APIResult<SuccessFailResponse>> {
    let endpoint = 'account/deleteAccountfactor/' + factorId;
    return this.commonService.deleteAPIService(endpoint);
  }

  public postSaveFactor(accountId: string, payload: Partial<Factor>): Observable<APIResult<AccountFactorResponse>> {
    let endpoint = 'account/factor/' + accountId;
    return this.commonService.postAPIService(endpoint, payload);
  }

  public putSaveFactor(accountId: string, payload: Partial<Factor>): Observable<APIResult<AccountFactorResponse>> {
    let endpoint = 'account/factor/' + accountId;
    return this.commonService.putAPIService(endpoint, payload);
  }

  public deleteAssetTag(tagId: string): Observable<APIResult<any>> {
    const endpoint = `account/delete-asset-tag/${tagId}`;
    return this.commonService.deleteAPIService(endpoint);
  }

  saveFactorTags(data: CreateTagData, createAssetTag = false): Observable<APIResult<FactorTagData[]>> {
    const tags = {
      id: data.assetId, // Send if updating, if not sent, new tag is created
      name: data.name,
      description: 'new tag',
      tag: data.value,
      metadata: {
        array_name: 'tmp_factor_indexed_by_id',
        id: data.factorId, // account_factor_id
        field: 'value',
        format_type: 'check_unit',
        additional_tags: [{ field: `name` }, { field: `description` }, { field: `abbr` }],
      },
    };
    const payload = { tags: [tags] };
    const endpoint = 'account/asset-tags';
    return this.commonService.postAPIService(endpoint, payload);
  }
}
