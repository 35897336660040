import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { extractInitials } from '@shared/utils';
import { StyleService } from 'app/style.service';
import { Table } from 'primeng/table';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil, finalize, map } from 'rxjs/operators';
import { ValueTrackerTableTranslation } from './trackers-container.translation';

@Component({
  selector: 'app-tracker-container',
  templateUrl: './trackers-container.component.html',
  styleUrls: ['./trackers-container.component.scss'],
})
export class TrackersContainerComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  showTranslate = false;
  realizations = [];
  strSearch = '';
  loading = false;

  style2022$: Observable<boolean>;
  
  @ViewChild('tableSolutionList', {static: true}) table: Table;
  tableRecords$: Observable<number>;
  currentTableRecords$ = new Subject<number>();

  columns = [
    { header: this.trans.trans.name, field: 'value_prop_name' },
    { header: this.trans.trans.company_name, field: 'company_name' },
    { header: this.trans.trans.assigned_by, field: 'assigned_by' },
    { header: this.trans.trans.created, field: 'created' },
  ];

  constructor(
    private CommonService: CommonService,
    private ValuerealizationService: ValuerealizationService,
    private ValuepropService: ValuepropService,
    private NotificationService: NotificationService,
    public trans: ValueTrackerTableTranslation,
    private translationService: TranslationsV2Service,
    private router: Router,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getValueRealization();
    this.subscribeToTableRecords();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);
      });
  }

  subscribeToTableRecords(): void {
    this.tableRecords$ = merge(
      this.table.onFilter.pipe(
        map(source => source.filteredValue.length)
      ),
      this.currentTableRecords$
    );
  }

  getValueRealization() {
    this.loading = true;

    this.ValuerealizationService.getAllValueRealizationsV2()
      .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.loading = false))
      .subscribe((response) => {
        this.realizations = response.result;
        this.currentTableRecords$.next(this.realizations.length);
      });
  }

  deleteValueRealization(id) {
    this.ValuepropService.deleteValueProp(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.NotificationService.success(response.result, false);
          this.getValueRealization();
        }
      });
  }

  getInitials(name: string): string {
    return extractInitials(name);
  }

  navToDetail(realization) {
    this.router.navigate(['trackers/' + realization.value_prop_id]);
  }
}
