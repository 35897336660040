import { Workflow } from '@data/services/workflow/models/workflow.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { WorkflowQuestionAnswers } from '@data/services/workflow/models/workflow-question.interface';
import { NotificationService } from '@services/notification.service';
import { GuidedDiscoveryTranslation } from './guided-discovery.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';

enum Steps {
  selectWorkflow = 0,
  answerQuestions = 1,
  modelResults = 2
}

@Component({
  selector: 'app-guided-discovery',
  templateUrl: './guided-discovery.component.html',
  styleUrls: ['./guided-discovery.component.scss'],
})
export class GuidedDiscoveryComponent implements OnInit {
  @Input() valuePropId: string | number;
  @Output() updateWorkflow = new EventEmitter();
  workflows$: Observable<Workflow[]>;
  steps = Steps;
  currentStep: Steps = 0;
  ngUnsubscribe$ = new Subject();
  loading = false;

  questions$: Observable<WorkflowQuestionAnswers[]>;
  showTranslate = false;


  constructor(
    private workflowService: AccountWorkflowService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: GuidedDiscoveryTranslation
  ) {}

  ngOnInit() {
    this.getWorkflows();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  changeStepToStepTwo(workflowId: string): void {
    this.getQuestions(workflowId);
    this.currentStep++;
  }

  getQuestions(workflowId: string): void {
    this.questions$ =  this.workflowService
      .getQuestionsVP(this.valuePropId.toString(), workflowId)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        shareReplay(1)
      )
  }

  getWorkflows(): void {
    this.workflows$ = this.workflowService
      .getAccountWorkflows(true)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        shareReplay(1)
      )
  }

  closeGuidedDiscovery(): void{
    this.notificationService.success(this.trans.trans.modelsUpdated.value, false);
    this.updateWorkflow.emit();
  }

  saveAnswers(answers: any): void {
    this.loading = true;
    this.workflowService.saveAnswers(this.valuePropId.toString(), answers)
    .pipe(
      takeUntil(this.ngUnsubscribe$),
    )
    .subscribe(res => {
      if(res.success) {
        this.notificationService.success(this.trans.trans.saveAnswersSuccess.value, false);
        this.currentStep = this.steps.modelResults;
        this.loading = false;
        return;
      }

      this.notificationService.error(this.trans.trans.saveAnswersError.value, false);
    })
  }

  setStep(step: Steps): void {
    this.currentStep = step;
  }

}
