
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-solution',
	templateUrl: './solution.component.html'
})
export class SolutionComponent implements OnInit, OnDestroy {
	@Input() valueProp: any;
	
	ngUnsubscribe = new Subject();
	constructor(
		private ValuepropService: ValuepropService,
		private NotificationService: NotificationService
	) {

	}

	ngOnInit() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	ngOnDestroy() {

	}

	funcSaveQ2() {
		if (!this.valueProp.vmw_q_2) { return false; }
		let payload = { answer: this.valueProp.vmw_q_2 };
		this.ValuepropService.putVMWareQ2(this.valueProp.id, payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.NotificationService.success("Question Updated Successfully", false);
		});
	}
}