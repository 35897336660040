<div *ngIf="!showDetail">

     <div style="margin: 15px 0">
         <button *ngIf="can_edit" class="btn btn_customizable pull-right" (click)="createCaseStudy = true">
             <i class="fa fa-plus"></i>Create Case Study
         </button>
     </div>
    <app-case-studies-realizations [isTracker]="true" (callback)="funcOpenDetail($event)" [companyId]="vpRealization.company_id"></app-case-studies-realizations>
</div>

<div *ngIf="showDetail">
    <div class="case-studies-wrapper">
        <div (click)="funcCloseDetail()" role="button" class="m-b-xxl m-l-xxl a-link-color">
            <i class="fa fa-chevron-left"></i> Back
        </div>
        <app-casestudy-detail2 [caseStudyId]="caseStudyId" [type]="'valueprop'" [myCaseStudy]="caseStudy" [canEdit]="can_edit"></app-casestudy-detail2>
    </div>
</div>

<p-sidebar [(visible)]='createCaseStudy' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-add-casestudy-realization *ngIf="createCaseStudy" (callback)='closeCreateCaseStudy()' [companyID]='vpRealization.company_id' [valuepropId]='vpRealization.value_prop_id' [solutions]='vpRealization.solutions'></app-add-casestudy-realization>
</p-sidebar>