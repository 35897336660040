<div *ngIf="showSurveysList">
	<div class="soln_table_cont">
		<div class="table-responsive col-sm-12 soln-table">
			<div class="searchTableContainer">
				<div class="">
					<table id="surveys" class="table custom-table">
						<thead>
							<tr>
								<th>{{trans.value_realization.name | translate}}</th>
								<th>{{trans.realization.surveyCreatedDate | translate}}</th>
								<th>{{trans.general.actions | translate}}</th>
							</tr>
						</thead>

						<tbody *ngIf="loadSurveysTable">
							<tr *ngFor="let survey of surveys" class="grid-row">
								<td>
									<a (click)="openSurveyDetail(survey)" class='link'>{{ survey.name }}</a>
								</td>

								<td>
									{{ survey.created }}
								</td>
								<td class="">
									<div class="dropdown" *ngIf="can_edit">
										<span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
										<ul class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="dLabel">
											<li class="dropdown-menu-list" (click)="downAsset(survey)">
												<i class="icon-cloud-download"></i> {{trans.general.download | translate}} </li>

											<li class="dropdown-menu-list" (click)="delete(survey.id)">
												<i class="icon-trash"></i> {{trans.general.Delete | translate}} </li>

											<li class="dropdown-menu-list" (click)="openSurveySettings(SurveySettingsModal, survey)">
												<i class="icon-settings"></i> {{trans.survey.settings | translate}} </li>
										</ul>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div *ngIf="!loadSurveysTable" class="col-12 text-center">
					<img class="actionLoader" [src]="fullImagePath" />
				</div>
				<div *ngIf="surveys.length == 0 && loadSurveysTable" class="m-t-md col-sm-12">
					<div>
						<h4>{{trans.survey.no_surveys_found | translate}}</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="clearfix"></div>
<div *ngIf="!showSurveysList">
	<div (click)="showSurveysList = !showSurveysList" role="button" class="m-b-xxl m-l-xxl">
		<i class="fa fa-chevron-left"></i> {{trans.general.back | translate}}
	</div>
	<app-create-survey [surveyId]="+surveyId" [activeSurveyTemplate]="activeSurveyTemplate" [account_question_template_id]="account_question_template_id" (close)="closeCreateSurvey()"></app-create-survey>
</div>

<div *ngIf="surveyDetailLoader" class="col-12 text-center">
	<img class="actionLoader" [src]="fullImagePath" />
</div>

<ng-template #SurveySettingsModal let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title">{{trans.survey.survey_settings | translate}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<form name="SurveySettingsForm" #SurveySettingsForm="ngForm">
			<div class="row">
				<div class="col-md-8">
					<div class="form-group" [ngClass]="{'has-error': (SurveySettingsForm.controls.surveyName?.invalid && SurveySettingsForm.controls.surveyName?.touched)}">
						<label class="labelsRequired">{{trans.survey.survey_name | translate}}</label>
						<input type="text" class="form-control" name="surveyName" [(ngModel)]="surveyName" required />
					</div>
					<div class="form-group" [ngClass]="{'has-error': (SurveySettingsForm.controls.surveyName?.invalid && SurveySettingsForm.controls.surveyName?.touched)}">
						<label class="labelsRequired">{{trans.survey.quarters_in | translate}}</label>
						<select class="form-control" name="quaters_in" [(ngModel)]="quaters_in" required>
							<option *ngFor="let quarter of quatersList" [ngValue]="quarter.value">
								{{quarter.name}}
							</option>
						</select>
					</div>
				</div>
			</div>


			<button type="button" class="btn btn-primary m-t-xs m-b-xs" (click)="updateSurveySettings();c('Close click')" [disabled]="SurveySettingsForm.invalid">{{'general.Update' | translate}}</button>

			<button type="button" class="btn btn-default m-t-xs m-b-xs" (click)="c('Close click')">{{trans.general.Cancel | translate}}</button>
		</form>
	</div>
</ng-template>