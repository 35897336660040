import { Injectable } from '@angular/core';

@Injectable ()

export class CostVpDashboardTranslation {

    public config: any = {
        component: 'costVpDash',
        display_name: 'Value Prop Cost',
        description: 'Cost tab in value prop dashboard'
    };

    public trans: any = {
        costs: 'Costs',
        edit: 'Edit',
        rename: 'Rename',
        delete: 'Delete',
        add_quotes_costs: 'Add Quotes / Costs',
        ch_costs: 'The investment required to achieve the benefits in scope,',
        add: 'Add',
        addNew: 'Add New',
        ch_add_cost: 'Add Cost',
        Type: 'Type',
        name: 'Name',
        Accrues: 'Accrues',
        UnitPrice: 'Unit Price',
        ch_quantity: 'Quantity',
        qty: 'QTY',
        Subtotal: 'Subtotal',
        ch_discount: 'Discount',
        DISC: 'DISC',
        GrandTotal: 'Grand Total',
        rowLimit: 'Row limit:',
        search: 'Search',
        NoCostsFound: 'No Costs Found',
        'Year 1': 'Year 1',
        'Year 2': 'Year 2',
        'Year 3': 'Year 3',
        'Year 4': 'Year 4',
        'Year 5': 'Year 5',
        yes: 'Yes',
        no: 'No',
        areYouSure: 'Are you sure?',
    };
}
