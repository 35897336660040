import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Competitor } from './models/competitor.model';

@Component({
  selector: 'app-solutioncompetitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.scss'],
})
export class SolutionCompetitorsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() canEdit = false;

  @Input() solutionId: number | string;;
  @Input() tabInit: number;

  solutionCompetitors: Competitor[] = [];
  competitors: Competitor[] = [];
  modalReference: NgbModalRef;
  loadcompetitorTable = false;
  accountSolutionId: number | string;
  competitorModel: { [klass: string]: any };
  id: number;
  description: string;
  landmines: string;
  theyllSay: string;
  theirLandmines: string;
  ourResponse: string;
  name: string;
  formData: { [klass: string]: any };
  imageUrl: string;
  fullImagePath: string;
  competitorName: string;

  contextualHelp: { [klass: string]: any } = {};

  style2022$: Observable<boolean>;
  ngUnsubscribe = new Subject();

  subscriptioncontextualHelp: Subscription;
  subscriptiongetCompetitors: Subscription;
  subscriptiongetSolutionCompetitorList: Subscription;
  subscriptiondeleteSolutionCompetitor: Subscription;
  subscriptioneditSolutionCompetitor: Subscription;
  subscriptioncreateCompetitor: Subscription;
  subscriptionupdateCompetitor: Subscription;

  constructor(
    private solutionService: SolutionService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private notification: NotificationService,
    private translateService: TranslateService,
    public trans: Translations,
    private styleService: StyleService
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => (this.accountSolutionId = params['id']));

    if (!this.accountSolutionId) {
      this.accountSolutionId = this.solutionId;
    }
    this.subscriptioncontextualHelp = this.commonService.contextualHelp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
      this.contextualHelp = response['Model'];
    });
    this.style2022$ = this.styleService.style2022;
  }

  ngOnDestroy() {
    if (this.subscriptioncontextualHelp) {
      this.subscriptioncontextualHelp.unsubscribe();
    }
    if (this.subscriptiongetCompetitors) {
      this.subscriptiongetCompetitors.unsubscribe();
    }
    if (this.subscriptiongetSolutionCompetitorList) {
      this.subscriptiongetSolutionCompetitorList.unsubscribe();
    }
    if (this.subscriptiondeleteSolutionCompetitor) {
      this.subscriptiondeleteSolutionCompetitor.unsubscribe();
    }
    if (this.subscriptioneditSolutionCompetitor) {
      this.subscriptioneditSolutionCompetitor.unsubscribe();
    }
    if (this.subscriptioncreateCompetitor) {
      this.subscriptioncreateCompetitor.unsubscribe();
    }
    if (this.subscriptionupdateCompetitor) {
      this.subscriptionupdateCompetitor.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    if (this.tabInit === 8) {
      this.getCompetitorList();
      this.getCompetitorsByAccount();
    }
  }

  getCompetitorsByAccount() {
    const aid = sessionStorage.getItem('aid');
    this.subscriptiongetCompetitors = this.solutionService.getCompetitors(aid).subscribe((result) => {
      this.competitors = result.result.data;
    });
  }
  getCompetitorList() {
    this.loadcompetitorTable = true;
    this.subscriptiongetSolutionCompetitorList = this.solutionService.getSolutionCompetitorList(this.accountSolutionId)
    .pipe(finalize(() => this.loadcompetitorTable = false))
    .subscribe((response) => {
      if (response.result) {
        this.solutionCompetitors = response.result;
      } else {
        this.solutionCompetitors = [];
      }
    });
  }
  openAddCompetitors(content: any) {
    this.competitorName = 'Add Competitor';
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.finally(
      () => {
        this.resetvalues();
      }
    );
  }
  deleteCompetitors(id: string | number) {
    this.loadcompetitorTable = true;
    this.subscriptiondeleteSolutionCompetitor = this.solutionService.deleteSolutionCompetitor(id).subscribe(() => {
      this.loadcompetitorTable = false;
      this.getCompetitorList();
      this.notification.success('Solution Competitor deleted successfully', false);
    });
  }
  editAddCompetitors(content: any, id: string | number) {
    this.competitorName = 'Edit Competitor';
    this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: 'static', keyboard: false });
    this.modalReference.result.finally(
      () => {
        this.resetvalues();
      }
    );

    this.subscriptioneditSolutionCompetitor = this.solutionService.editSolutionCompetitor(id)
    .pipe(finalize(() => this.loadcompetitorTable = false))
    .subscribe((response) => {
      if (response.result) {
        this.id = response.result.id;
        this.name = response.result.account_competitor_id;
        this.description = response.result.description;
        this.landmines = response.result.landmines;
        this.theyllSay = response.result.theyll_say;
        this.theirLandmines = response.result.their_landmines;
        this.ourResponse = response.result.our_response;
      }
    });
  }
  SaveCompetitor(form: NgForm) {
    this.competitorModel = {
      competitor_id: form.value.id,
      account_solution_id: this.accountSolutionId,
      account_competitor_id: form.value.name,
      description: form.value.description,
      landmines: form.value.landmines,
      theyll_say: form.value.theyll_say,
      their_landmines: form.value.their_landmines,
      our_response: form.value.our_response,
    };

    // tslint:disable-next-line: triple-equals
    if (form.value.id == 0 || form.value.id == null) {
      this.subscriptioncreateCompetitor = this.solutionService.createCompetitor(this.competitorModel).subscribe(() => {
        this.notification.success('Competitor created successfully', false);
        this.getCompetitorList();
      });
    } else {
      this.subscriptionupdateCompetitor = this.solutionService.updateCompetitor(this.competitorModel).subscribe(() => {
        this.notification.success('Competitor updated successfully', false);
        this.getCompetitorList();
      });
    }
    this.modalReference.close();
  }

  resetvalues() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.landmines = '';
    this.theyllSay = '';
    this.theirLandmines = '';
    this.ourResponse = '';
  }
}
