<div class="sidebar_wrapper">
  <div class="sidebar_header">
    {{ trans.trans.addNew.value }}
  </div>
  <div class="sidebar_container">
    <div class="keywest_panel" [formGroup]="selectedCompanyForm">
      <div *ngIf="!!companyDetails" id="custom-auto-full-width">
        <p-autoComplete
          formControlName="selectedCompany"
          [suggestions]="companiesList"
          (completeMethod)="searchCompanies($any($event).query)"
          (onSelect)="getCompanyDetails($any($event).id)"
          field="name"
          [placeholder]="trans.trans.searchCompanyPlaceholder.value"
        ></p-autoComplete>
      </div>
      <ng-container *ngIf="!companyDetails">
        <div class="search-company-container">
          <div class="co-title">{{ trans.trans.letsCreate.value }}</div>
          <div class="co-subtitle">{{ trans.trans.searchCompany.value }}</div>
          <div id="custom-auto">
            <p-autoComplete
              formControlName="selectedCompany"
              [suggestions]="companiesList"
              (completeMethod)="searchCompanies($any($event).query)"
              (onSelect)="getCompanyDetails($any($event).id)"
              field="name"
              [placeholder]="trans.trans.searchCompanyPlaceholder.value"
            ></p-autoComplete>
          </div>

          <div></div>
        </div>
        <div class="suggested-cos">{{ trans.trans.suggestedCompanies.value }}</div>
        <div class="table-grid">
          <div class="example-loading-shade table-child" *ngIf="isLoadingResults">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
          </div>
          <div class="table-child">
            <p-table [value]="companiesListSuggested" styleClass="keywest_table" tableStyleClass="keywest_table">
              <ng-template pTemplate="header">
                <tr>
                  <th></th>
                  <th pSortableColumn="name">{{ trans.trans.companyName.value }} <p-sortIcon field="name"></p-sortIcon></th>
                  <th pSortableColumn="business_case_count">{{ trans.trans.valuePropositions.value }} <p-sortIcon field="business_case_count"></p-sortIcon></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-company>
                <tr (click)="getCompanyDetails(company.id)" class="clickable">
                  <td>
                    <span *ngIf="company | companyUrlPipe | async as img"><img style="width: 40px" [src]="img" /></span>
                  </td>
                  <td>{{ company.name }}</td>
                  <td>{{ company?.business_case_count }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-container>
      <div *ngIf="!!companyDetails" class="dflex f-column">
        <div class="company-logo">
          <span *ngIf="companyDetails | companyUrlPipe | async as img"><img style="width: 40px" [src]="img" /></span>
        </div>
        <div class="company-name">{{ companyDetails.name }}</div>
        <div class="company-desc" *ngIf="companyDetails.description">{{ companyDetails.description }}</div>
        <div class="company-grid">
          <div>
            <div *ngIf="!feature101" class="company-details">
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.vcAccountId.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.id | functionPipe: defaultValue }}
                </div>
              </div>
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.crmAccountId.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.account_defined_id | functionPipe: defaultValue }}
                </div>
              </div>
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.city.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.city | functionPipe: defaultValue }}
                </div>
              </div>
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.state.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.state | functionPipe: defaultValue }}
                </div>
              </div>
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.country.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.country | functionPipe: defaultValue }}
                </div>
              </div>
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.revenueM.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.revenue_fmt | functionPipe: defaultValue }}
                </div>
              </div>
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.employees.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.employees | functionPipe: defaultValue }}
                </div>
              </div>
              <div class="company-row">
                <div></div>
                <div class="company-left-col">
                  {{ trans.trans.sector.value }}
                </div>
                <div></div>
                <div class="company-right-col">
                  {{ companyDetails.industry_name | functionPipe: defaultValue }}
                </div>
              </div>
            </div>
            <button class="btn btn_customizable custom-continue" (click)="defineTracker(companyDetails.id)">{{ trans.trans.continue.value }}</button>
          </div>
          <div class="sector-card-wrapper">
            <div class="card-left-border">
              <div class="sector-title">
                {{ trans.trans.totalValuePropositions.value }}
              </div>
              <div class="sector-value">
                {{ companyDetails.business_case_count ?? 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
