<div class="col-md-6" style='margin-bottom: 10px;'>
	<div class='models_panel dashboard-card' style='height: 400px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>{{ trans.trans.caseStudiesHeading.value}}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.caseStudiesHeading' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.caseStudiesHeading"></app-editTranslation>
						</div>
					</div>
					<div class='col-xs-2'>
						<a routerLink='/case_study/new' *ngIf="dbI.showAddButtons" class="btn btn_customizable pull-right"><i class='fa fa-plus'></i>
							{{ trans.trans.add.value }}</a>
					</div>
				</div>
			</div>

			<div style='overflow-y: auto; max-height: 270px;'>
				<div [hidden]="!loading">
					<app-table-skeleton rowHeight="35px" [columns]="columns" [trans]="trans.trans" [rowLimit]="5"></app-table-skeleton>
				</div>
				<table *ngIf="!loading" class="table custom-table dashboard-table">
					<thead>
						<tr>
							<th>
								{{ trans.trans.case_name.value }}<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.case_name' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.case_name"></app-editTranslation>
							</th>

						</tr>
					</thead>
					<tbody>
						<tr class="grid-row company-row" *ngFor="let caseStudy of caseStudies">
							<td class="caseStudyName">
								<a [routerLink]="funcGetModelsLink(caseStudy)" class='link clickable'>{{caseStudy.name}}</a>
								<i *ngIf="!(style2022$ | async)" matTooltip='Download Asset' (click)='downloadAsset(caseStudy.id)' class='clickable fa fa-download' style='margin-left: 10px;'></i>
								<span *ngIf="(style2022$ | async)" matTooltip='Download Asset' (click)='downloadAsset(caseStudy.id)' class='material-icons tooltip-custom info-icon' >download</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div *ngIf="caseStudies.length==0" class="m-t-md col-sm-12">
				<div class="text-center">
					<h4>{{ trans.trans.noCaseStudies.value}}<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.noCaseStudies' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.noCaseStudies"></app-editTranslation>
					</h4>
				</div>
			</div>
			<a routerLink="/case_study" class="keywest_seeMore">See {{seeMore ? "Less" : "More"}}</a>

		</div>
	</div>
</div>

<p-sidebar [(visible)]='toggleAddCaseStudy' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<app-add-casestudy *ngIf="toggleAddCaseStudy" (callback)='funcAddedCaseStudy()' mode='add'></app-add-casestudy>
</p-sidebar>
