import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValuemapsComponent } from './valuemaps/valuemaps.component';
import { SolutionCategoryComponent } from './solution-category/solution-category.component';
import { SolutionListComponent } from './solution-list/solution-list.component';
import { SolutionDetailComponent } from './solution-detail/solution-detail/solution-detail.component';
import { AddSolutionComponent } from './add-solution/add-solution.component';
import { CreateMapComponent } from './create-map/create-map.component';
const routes: Routes = [
  {
    path: 'valuemaps',
    component: ValuemapsComponent,
    children: [
      {
        path: 'solutionCategory',
        component: SolutionCategoryComponent,
        data: { page: 'solutionCategory' },
      },
    ],
  },
  {
    path: 'models',
    component: SolutionListComponent,
    data: { page: 'models', breadcrumb: 'Models' },
  },

  {
    path: 'addmodel',
    component: AddSolutionComponent,
    data: { page: 'addmodel' },
  },
  {
    path: 'model/create',
    component: CreateMapComponent,
    data: { page: 'Create' },
  },
  {
    path: 'model/update/:id',
    component: CreateMapComponent,
    data: { page: 'Update' },
  },
  {
    path: 'model/:id',
    component: SolutionDetailComponent,
    data: { page: 'model', breadcrumb: 'Model Details' },
  },
  {
    path: 'model/:id/case',
    component: SolutionDetailComponent,
    data: { page: 'model', breadcrumb: 'Model Details' },
  },
  {
    path: 'model/:id/case/:caseid',
    component: SolutionDetailComponent,
    data: { page: 'model', breadcrumb: 'Model Details' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ValueMapRoutingModule {}
