import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { FactsService } from '@data/services/facts/facts.service';
import { Subject } from 'rxjs';
import { Menu } from 'primeng/menu';
import { CostVpDashboardTranslation } from './cost.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { takeUntil } from 'rxjs/operators';
import { ValueProp } from '@shared/models/value-prop.model';
import { AccrualType, CostCategoryType, CostResponse, CostType } from '@data/services/valueprop/models/cost.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from 'primeng/api';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';

@Component({
  selector: 'app-costs-valueprop',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss'],
})
export class CostsComponent implements OnInit, OnDestroy {
  @Input() dealdesk: boolean;
  @Input() showHelp: boolean;
  @Input() showTranslate = false;
  @Input() tabInit: number;
  @Input() valueProp: ValueProp;
  @Input() viewSimple = false;

  @ViewChild('menu') menu: Menu;

  accountId: string;
  accrualTypes: AccrualType[] = [];
  colspan: number;
  cost: number;
  costCategories = false;
  costCategoryTypes: CostCategoryType[];
  costModal: { valueProp?: ValueProp; edit?: string; cost?: CostResponse; has_scratchpad?: number | boolean } = {};
  costTypes: CostType[] = [];
  costs: { [klass: string]: any }[] = [];
  costsLoader = true;
  edit: boolean;
  fullImagePath: string;
  grouped_costs: CostResponse[] = [];
  headerColor = 'blue';
  hideDiscountFeature = false;
  image_url: string;
  isCustomerShareType = true;
  items: { [klass: string]: any }[] = [];
  locked = false;
  modalReference: NgbModalRef;
  ngUnsubscribe: Subject<any> = new Subject();
  pageLimit = 10;
  rowLimit: MenuItem[] = [];
  showAddCost = false;
  start = 1;
  strSearch = '';
  term = 3;
  valuePropCosts: { [klass: string]: any }[] = [];
  years: any[] = [];
  feature97 = this.commonService.checkFeature(97);
  searchTimeout;
  searchDebounceTime = 750;
  feature62 = this.commonService.checkFeature('62');

  constructor(
    private commonService: CommonService,
    private valuePropService: ValuepropService,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private translationService: TranslationsV2Service,
    public trans: CostVpDashboardTranslation,
    private rAS: ResultsAnalysisService
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.hideDiscountFeature = this.commonService.checkFeature(105);
    this.locked = +this.valueProp.closed === 1;
    this.funcBuildPageLimit();
    this.headerColor = sessionStorage.getItem('header_color');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.loadCosts();

    this.valuePropService.refreshCosts.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadCosts();
      this.valuePropService.refreshDashboard.next('changeCost');
    });

    if (parseInt(this.valueProp['share_role_type_id']) != 4) {
      this.isCustomerShareType = false;
    }

    this.valuePropService
      .getAccrualTypes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.accrualTypes = response.result;
        }
      });
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  setRowLimit(num) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit == 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit == 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit == 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit == 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  refreshTranslation() {
    this.getTranslations();
  }

  // Fix to call API's on tab first time load alone and skip next loads

  deleteValuePropCosts(id: string) {
    this.valuePropService
      .deleteValuePropCosts(this.valueProp.id, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.valuePropService.refreshDashboard.next('no_reload');
          if (this.costs.length === 1) {
            this.rAS.chartROI = null;
          }
          this.rAS.refreshROIChart(this.valueProp, this.feature62);
          this.costTypes = response.result;
          this.loadCosts();
        } else if (response.result.success === false) {
          this.notificationService.error(response.result.message, false); // Params {message, islogout}
        }
      });
  }

  loadCosts() {
    let searchLimit = this.valueProp.id;
    this.costsLoader = true;
    this.valuePropService
      .getValuePropCosts(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.grouped_costs = response.result.grouped_costs;
          this.valuePropCosts = response.result.value_prop_costs;
          let grouped = true;
          if (this.grouped_costs && this.grouped_costs.length === 1 && this.grouped_costs[0].id === '0') {
            this.costCategories = false;
            this.costs = response.result.value_prop_costs;
            grouped = false;
          } else {
            this.costCategories = true;
            this.costs = response.result.grouped_costs;
          }

          if (this.costs) {
            if (grouped) {
              this.term = this.costs[0].costs[0].term;
              for (let m = 0; m < this.costs.length; m++) {
                this.costs.forEach((group) => {
                  group.costs.forEach((elem) => {
                    // Clear cost_fmt if cost is variable
                    if ( elem['accrual_type_id'] === '3' ) {
                      elem['cost_fmt'] = '';
                    }

                    let out = [];
                    for (let t = 1; t <= this.term; t++) {
                      out.push({ label: 'Year ' + t, value: elem['yr' + t + '_costs_fmt'] });
                    }
                    elem.years = out;
                  });
                });
              }
            } else {
              this.term = this.costs[0].term;
              this.costs.forEach((cost) => {
                // Clear cost_fmt if cost is variable
                if ( cost['accrual_type_id'] === '3' ) {
                  cost['cost_fmt'] = '';
                }

                let out = [];
                for (let t = 1; t <= this.term; t++) {
                  out.push({ label: 'Year ' + t, value: cost['yr' + t + '_costs_fmt'] });
                }
                cost.years = out;
              });
            }

            this.years = [];
            for (let t = 1; t <= this.term; t++) {
              this.years.push({ label: 'Year ' + t });
            }
          }

          this.colspan = this.term + 8;

          this.costsLoader = false;
        }
      });
  }

  funcUpdateCostTitle(cost) {
    cost.alt_cost_name = cost.cost_type;
    this.costsLoader = true;
    this.valuePropService
      .updateCostTitle(this.valueProp.id, cost)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.costsLoader = false;
          this.loadCosts();
        }
      });
  }

  openCosts(edit, cost) {
    this.costModal.edit = edit;
    this.costModal.cost = cost;
    this.costModal.valueProp = this.valueProp;
    this.showAddCost = !this.showAddCost;
  }

  openAddCosts() {
    const cost = {
      accrual_type_id: '',
      cost: '',
      cost_type_id: '',
      discount: '',
      quantity: '',
      value_prop_id: this.valueProp.id,
      yr1_costs: '',
      yr2_costs: '',
      yr3_costs: '',
      cost_category_type_id: '0',
      account_competitor_id: '',
      present_value: '',
      rate: '',
      lease_term: '',
      residual_value: '',
      buyout_value: '',
    };
    this.openCosts(false, cost);
  }

  getCostCategoryTypes() {
    this.accountId = sessionStorage.getItem('aid');
    this.factsService
      .getAccountCostCategoryTypes(this.accountId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.costCategoryTypes = result.result;
      });
  }

  editValuePropCosts(cost) {
    const costObj = {
      ...cost,
      value_prop_id: this.valueProp.id,
      alt_cost_description: cost.alt_cost_description !== '' ? cost.alt_cost_description : cost.cost_description,
      alt_cost_name: cost.alt_cost_name,
      value_prop_cost_id: cost.id,
    };

    this.openCosts(true, costObj);
  }

  rangeRightArrow(start) {
    if (!start) {
      return 1;
    } else {
      return start + 1;
    }
  }

  rangeLeftArrow(start) {
    if (!start) {
      return 1;
    } else {
      return start - 1;
    }
  }

  getYearTrans(year: string): string {
    const yearTrans = this.trans.trans[year];
    return yearTrans ? yearTrans.value : year;
  }

  searchCosts(event: KeyboardEvent) {
    if (this.strSearch === '') {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.loadCosts();
      }, this.searchDebounceTime);
    } else if (event && event.key === 'Backspace') {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.loadCosts();
        this.costs = this.filterCosts();
      }, this.searchDebounceTime);
    } else {
      this.costs = this.filterCosts();
    }
  }

  private filterCosts() {
    const normSearch = this.strSearch.toLowerCase().trim();
    return this.costs.filter((cost) => cost.original_name.toLowerCase().trim().includes(normSearch) || cost.cost_type.toLowerCase().trim().includes(normSearch));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }
}
