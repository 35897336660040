<div class="row" *ngIf="style2022$ | async">
    <div class="col-md-4 mr-auto breadcrumb-container">
      <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
    </div>
  </div>
  
  <div class="row" *ngIf="!(style2022$ | async)">
    <div class="col-md-10 old-breadcrumb">
      <a [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.back_to_profile.value }}</a>
    </div>
  </div>
  <div class="models_panel" style="margin-bottom: 20px; height: 2050px">
    <div class="models_page_header_container_small" style="height: 100%">
      <div class="keywest_header_small">
        <div class="tab-container">
            <div style="height: 2000px">
                <iframe id="sigmaUserAdoptioniFrame" width="100%" height="100%" [src]="sigmaUserAdoptionUrl"
                style="border: none; "></iframe>
            </div>
        </div>
      </div>
    </div>
  </div>