<div id="case-container" class='models_panel plr'>
	<div class='models_page_header_container_small case-studies-wrapper'>
		<div class='keywest_header pb'>
			<div class='row'>
				<div class='col-xs-12 header-wrapper' >
					<div id="title" class='title case-studies-title'>{{trans.trans.case_studies.value}} 
						<i *ngIf="!(style2022$ | async)" [matTooltip]='trans.trans.ch_case_studies.value' class='fa fa-info-circle'></i>
						<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans.ch_case_studies.value">
							info
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12 table-wrapper'>
				<table class="table custom-table">
					<thead>
						<tr>
							<th id="name" class="header">{{ trans.trans.name.value }}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" key='vp_dashboard.case_studies.name'></app-editTranslation>
							</th>
						</tr>
					</thead>

					<tbody *ngIf="caseStudiesLoader">
						<tr>
							<td style='border-top: 0px;'>
								<img class="actionLoader" src="{{fullImagePath}}" />
							</td>
						</tr>
					</tbody>
					<tbody *ngIf="(caseStudies == null || caseStudies.length==0) && !caseStudiesLoader">
						<tr>
							<td style='border-top: 0px;'>
								{{ trans.trans.noCaseStudies.value }}
								<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.noCaseStudies" key='vp_dashboard.case_studies.noCaseStudies'></app-editTranslation>
							</td>
						</tr>
					</tbody>

					<tbody *ngIf="caseStudies">
						<tr *ngFor="let caseStudy of caseStudies" class="grid-row">
							<td>
								<a *ngIf="!disableCaseStudiesLink; else caseStudyTitle" role="button" (click)="navigateCaseStudy(caseStudy)" class='link'>{{caseStudy.name}}</a>
								<ng-template #caseStudyTitle>
									{{ caseStudy.name}}
								</ng-template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>


<div class="detail-page" *ngIf="showCaseStudiesList">
	<div (click)="showCaseStudiesList = !showCaseStudiesList" role="button" class="m-b-xxl m-l-xxl a-link-color">
		<i class="fa fa-chevron-left"></i> {{ trans.trans.back.value }}
	</div>

	<app-casestudy-detail2 [caseStudyId]="caseStudyId.toString()" [type]="'solution'" [myCaseStudy]="caseStudy"></app-casestudy-detail2>
</div>
