import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { AssetElement, AssetElementType, AssetType } from '@data/services/valueprop/models/asset.model'
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FactsService } from '@data/services/facts/facts.service';
import { AssetElementPayload, AssetTemplate, ElementRequest } from '@shared/models/asset.models';
import { AssetsManageProfileTranslations } from '../assets.translation';
import {CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';


const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@Component({
  selector: 'app-asset-settings',
  templateUrl: './asset-settings.component.html',
  styleUrls: ['./asset-settings.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class AssetSettingsComponent implements OnInit, OnDestroy {

  assetElementTypes: AssetElementType[] = [];
  loadingAssetElementTypes = false;
  selectedCharts: AssetElement[] = [];
  selectedSlides: AssetElement[] = [];
  selectedBlocks: AssetElement[] = [];
  assetTypes: AssetType[];

  ngUnsubscribe = new Subject();
  showTranslate = false;

  @Input() selectedElem: Partial<AssetTemplate>;
  @Input() accountAssetId: string;
  @Input() mode: 'add' | 'edit' = 'add';
  @Output() reload = new EventEmitter<void>();
  @Output() changes = new EventEmitter<void>();
  @Output() save = new EventEmitter<AssetElementPayload>();

  constructor(
    public trans: AssetsManageProfileTranslations,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private factsService: FactsService,
    private translationService: TranslationsV2Service,
  ) { }

  ngOnInit(): void {
    this.getAssetElementTypes();

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  drop(dropEvent: CdkDragDrop<any[]>, dropArray: any[]) {
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
    this.notifyChanges();
  }


  getAssetElementTypes(): void {
    this.loadingAssetElementTypes = true;
    this.factsService
      .getAssetElementTypesCombined(this.accountAssetId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.assetElementTypes = response.result.types;

        //Reset the elements arrays so we don't show duplicates
        this.selectedCharts = [];
        this.selectedSlides = [];
        this.selectedBlocks = [];

        this.assetElementTypes.forEach((elem) => {
          elem.elements.forEach((el) => {
            if (el.element_type_id === '2' && el.update) {
              this.selectedCharts.push(el);
            } else if (el.element_type_id === '1' && el.update) {
              this.selectedSlides.push(el);
            } else if (el.element_type_id === '3' && el.update) {
              this.selectedBlocks.push(el);
            }
          });
        });
        this.selectedCharts.sort((a, b) => (a.page > b.page ? 1 : -1));
        this.loadingAssetElementTypes = false;
      });
  }

  populateSaveSettings(): void {
    const out: ElementRequest[] = [];

    if (this.selectedSlides.find((elem) => !elem.page) || this.selectedBlocks.find((elem) => !elem.page)) {
      this.notificationService.error(this.trans.trans.formSaveError.value, false);
      return;
    }

    this.selectedCharts.forEach((elem, i) => {
      out.push({ element_id: elem.id, page: i + 1 + '' });
    });
    this.selectedSlides.forEach((elem) => {
      out.push({ element_id: elem.id, page: elem.page });
    });
    this.selectedBlocks.forEach((elem) => {
      out.push({ element_id: elem.id, page: elem.page });
    });

    if (out.length > 0) {
      const payload: AssetElementPayload = {
        account_asset_id: this.accountAssetId,
        elements: out,
      };

      this.save.emit(payload);
    } else {
      this.reload.emit();
    }
  }

  notifyChanges() {
    this.changes.emit();
  }
}
