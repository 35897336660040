import { Injectable } from '@angular/core';

@Injectable ()

export class ValueTrackerTableTranslation {

    public config: any = {
        component: 'valueTrackerTable',
        display_name: 'Value Tracker List',
        description: 'A list of all value realizations'
    };

    public trans: any = {
        name: 'Name',
        companyname: 'Company',
        total_benefits_fmt: 'Expected To Date',
        total_realized: 'Realized To Date',
        valueRealization: 'Value Realization',
        value_prop_name: 'Name',
        search: 'Search',
        delete: 'Delete',
        company_name: 'Account Contact',
        assigned_by: 'Assigned By',
        created: 'Date',
        noRecords: 'No records found',
        noResults: 'No results for ',
        valueRealizationHeading: 'Recent Value Realizations',
        seeMore: 'See More',
    };
}
