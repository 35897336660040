<div class="sidebar_wrapper">
  <div class="sidebar_container" id="padding-override">
    <div class="asset-group" *ngIf="menuAssets?.length">
      <h2 class="group-header">{{ trans.trans.valueCloudAssets.value }}</h2>
      <ng-container *ngIf="(businessReview && businessReviewRequired) || !businessReviewRequired">
        <div *ngFor="let asset of menuAssets; let i=index">
          <div class="col-xs-12">
            <div class="asset-item">
              <div class="asset-heading">{{ asset.label }}</div>
              <a [href]="asset.url" target="_blank" [attr.data-heap]="'value-execution-add-a-new-value-proposition-value-execution-assets-type-' + asset.format.toLowerCase().split(' ').join('-') + '-name-' + asset.label.toLowerCase().split(' ').join('-')" [ngClass]="'value-execution-add-a-new-value-proposition-value-execution-assets-type-' + i">
                <ng-container *ngIf="asset.showImage; else fontIcon">
                  <app-svg-icon [name]="asset.icon"></app-svg-icon>
                </ng-container>
                <ng-template #fontIcon>
                  <i [class]="asset.icon" [ngStyle]="asset.faStyle"></i>
                </ng-template>
                <ng-template></ng-template>
                {{ trans.trans.download.value }}
              </a>
              <a *ngIf="asset.showPDF" style="margin-left: 12px" [href]="asset.pdf" target="_blank" [attr.data-heap]="'value-execution-add-a-new-value-proposition-value-execution-assets-type-pdf-name-' +  trans.trans.downloadPdf.value.toLowerCase().split(' ').join('-')" [ngClass]="'value-execution-add-a-new-value-proposition-value-execution-assets-type-pdf-name-' +  i">
                <app-svg-icon name="pdf-file"></app-svg-icon>
                {{ trans.trans.downloadPdf.value }}
              </a>
              <p [innerHTML]="asset.description | linky"></p>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="!businessReview && businessReviewRequired">
        {{ trans.trans.addBusinessReview.value }}
      </div>
    </div>
    <div class="asset-group" *ngIf="collaterals?.length">
      <h2 class="group-header">{{ trans.trans.supportContent.value }}</h2>
      <div *ngFor="let collateral of collaterals">
        <div class="col-xs-12">
          <div class="asset-item">
            <div class="flex-aic">
              <a [href]="collateral.url | functionPipe: prependHttp" target="_blank" class="item-heading" target="_blank"
                ><i [class]="collateral.icon" [ngStyle]="collateral.faStyle"></i> {{ collateral.name }}</a
              >
            </div>
            <p>{{ collateral.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="asset-group" *ngIf="caseStudies?.length">
      <h2 class="group-header">{{ trans.trans.proofPoints.value }}</h2>
      <div *ngFor="let case of caseStudies">
        <div class="col-xs-12">
          <div class="asset-item flex-aic">
            <ng-container *ngIf="case.reference_url !== ''; else generateAsset">
              <i class="fa fa-link"></i> <a class="case-study-link item-heading" [href]="case.reference_url" target="_blank"> {{ case.name }} </a>
            </ng-container>
            <ng-template #generateAsset>
              <i class="fa fa-cloud-download"></i><a class="case-study-link item-heading" href="javascript:void(0)" (click)="downloadCaseStudyAsset(case.id)"> {{ case.name }} </a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
