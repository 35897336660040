import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { PromoteRealizationTranslations } from './promote-realization.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { UserService } from '@data/services/user/user.service';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { ValueProp } from '@shared/models/value-prop.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessagesService } from '@services/error-messages.service';
import { UserInfo } from '@shared/models/user-info.model';

@Component({
  selector: 'app-promote-realization',
  templateUrl: 'promote-realization.component.html',
})
export class PromoteRealizationComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  @Output() callback = new EventEmitter();

  ngUnsubscribe = new Subject();
  showTranslate = false;
  users: Array<{ [key: string]: unknown }>;
  promoteForm: FormGroup;
  crm: string;
  errorDict: Map<string, string> = new Map();
  userData: UserInfo;
  isLoading = false;

  constructor(
    public trans: PromoteRealizationTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private userService: UserService,
    private fb: FormBuilder,
    private errorMessagesService: ErrorMessagesService,
    private valueRealizationService: ValuerealizationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.promoteForm = this.fb.group({
      user: ['', Validators.required],
      realizationName: ['', Validators.required],
      customDate: [''],
    });

    this.errorDict = new Map(this.errorMessagesService.getFormErrors(this.promoteForm.controls));

    this.promoteForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe), debounceTime(100)).subscribe(() => {
      this.errorDict = new Map(this.errorMessagesService.getFormErrors(this.promoteForm.controls));
    });

    this.userService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userInfo) => {
      this.userData = userInfo;
    });

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  close(): void {
    this.callback.emit();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getUsers(): void {
    const query = '?role_type_id=8';

    this.userService
      .getUsersList(query)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.users = response.result.data;
      });
  }

  userHasPermission(user: UserInfo): boolean {
    if (!user || !user.products) {
      return true;
    } else if (user && user.products.find((product) => +product.product_id === 5)) {
      return false;
    }
    return true;
  }

  saveToRealization(): void {
    this.isLoading = true;
    const customD = this.promoteForm.value.customDate ? this.promoteForm.value.customDate.toISOString() : new Date().toISOString();

    const formattedD = customD.replace(/[a-z]/gi, ' ');
    const payload = {
      name: this.promoteForm.value.realizationName,
      user_id: this.promoteForm.value.user.id,
      is_promote: 1,
      custom_value_realized_date: this.userHasPermission(this.userData) ? formattedD : null,
    };

    this.valueRealizationService
      .promoteVpToValueRealization(this.valueProp.id, payload)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((response) => {
        if (response.result.success) {
          this.notificationService.success(this.trans.trans.success.value, false);
          this.callback.emit();
        } else {
          this.notificationService.error(this.trans.trans.error.value, false);
        }
      });
  }
}
