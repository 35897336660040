import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { Translations } from '@data/services/translation/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-model-assumptions',
  templateUrl: './assumptions.component.html',
  styleUrls: ['./assumptions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssumptionsModelComponent implements OnInit, OnDestroy {
  @Input() solutionId: number | string;
  @Input() tabInit: number;
  @Input() canEdit = false;

  fullImagePath: string;
  imageUrl: string;
  driversLoader = false;
  drivers: { [klass: string]: any }[] = [];
  financialsLoader = false;
  financials: { [klass: string]: any }[] = [];
  scratchPadLoader = false;
  scratchPads: { [klass: string]: any }[] = [];
  sourceTypes: { [klass: string]: any }[] = [];

  subscriptiontriggerTabs: Subscription;
  subscriptiontoggleStaredKeyAssumption: Subscription;
  subscriptiongetDrivers: Subscription;
  subscriptiongetFinancials: Subscription;
  subscriptiongetScratchPadUnits: Subscription;
  subscriptioneditbaseLineAnswer: Subscription;
  subscriptioneditScratchpadUnit: Subscription;

  showDrivers = true;
  driversStarred = 0;
  searchTextDrivers = '';

  showFinancial = true;
  financialsStarred = 0;
  searchTextFinancial = '';

  showScratch = true;
  scratchStarred = 0;
  searchTextScratchpads = '';
  expandAll: boolean;

  constructor(
    private commonService: CommonService,
    private solutionService: SolutionService,
    private notification: NotificationService,
    private translateService: TranslateService,
    public trans: Translations
  ) {
    this.imageUrl = this.commonService.getImageUrl();
    this.fullImagePath = this.imageUrl + '/images/jamaica/reload.gif';
    this.translateService.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit() {
    this.loadDrivers();
    this.loadFinancials();
    this.loadScratchPadUnits();

    this.subscriptiontriggerTabs = this.solutionService.triggerTabs.subscribe((response) => {
      if (response === 'benefits') {
        this.loadDrivers();
        this.loadFinancials();
        this.loadScratchPadUnits();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptiontriggerTabs) {
      this.subscriptiontriggerTabs.unsubscribe();
    }
    if (this.subscriptiontoggleStaredKeyAssumption) {
      this.subscriptiontoggleStaredKeyAssumption.unsubscribe();
    }
    if (this.subscriptiongetDrivers) {
      this.subscriptiongetDrivers.unsubscribe();
    }
    if (this.subscriptiongetFinancials) {
      this.subscriptiongetFinancials.unsubscribe();
    }
    if (this.subscriptiongetScratchPadUnits) {
      this.subscriptiongetScratchPadUnits.unsubscribe();
    }
    if (this.subscriptioneditbaseLineAnswer) {
      this.subscriptioneditbaseLineAnswer.unsubscribe();
    }
    if (this.subscriptioneditScratchpadUnit) {
      this.subscriptioneditScratchpadUnit.unsubscribe();
    }
  }

  scroll(el: string) {
    const targetElm = document.querySelector('#' + el);
    targetElm.scrollIntoView({ behavior: 'smooth' });
  }

  funcExpand(expand?: boolean) {
    this.expandAll = !this.expandAll;
    this.showDrivers = expand ? expand : !this.showDrivers;
    this.showFinancial = expand ? expand : !this.showFinancial;
    this.showScratch = expand ? expand : !this.showScratch;
  }

  toggleStared(data: { [klass: string]: any }, typeId: number) {
    let factorId: unknown;
    let load: Function;
    switch (typeId) {
      case 2:
        factorId = data.driver_factor_id;
        load = () => this.loadDrivers();
        break;
      case 4:
        factorId = data.financial_factor_id;
        load = () => this.loadFinancials();
        break;

      case 6:
        factorId = data.factor_id;
        load = () => this.loadScratchPadUnits();
        break;
    }

    const payload = {
      factor_id: factorId,
      factor_type_id: typeId,
      // tslint:disable-next-line: triple-equals
      action: data.is_key == 1 ? 'Delete' : 'Create',
    };

    this.subscriptiontoggleStaredKeyAssumption = this.solutionService.toggleStaredKeyAssumption(this.solutionId, payload).subscribe(() => {
      load();
    });
  }

  loadDrivers() {
    this.driversLoader = true;
    this.subscriptiongetDrivers = this.solutionService
      .getDrivers(this.solutionId)
      .pipe(finalize(() => (this.driversLoader = false)))
      .subscribe((response) => {
        if (response.result) {
          this.drivers = response.result.drivers;

          // tslint:disable-next-line: triple-equals
          this.driversStarred = this.drivers.filter((x) => x.is_key == 1).length;
        }
      });
  }

  loadFinancials() {
    this.financialsLoader = true;
    this.subscriptiongetFinancials = this.solutionService
      .getFinancials(this.solutionId)
      .pipe(finalize(() => (this.financialsLoader = false)))
      .subscribe((response) => {
        if (response.result) {
          this.financials = response.result.financials;
          // tslint:disable-next-line: triple-equals
          this.financialsStarred = this.financials.filter((x) => x.is_key == 1).length;
        }
      });
  }

  loadScratchPadUnits() {
    this.scratchPadLoader = true;
    this.subscriptiongetScratchPadUnits = this.solutionService
      .getScratchPadUnits(this.solutionId)
      .pipe(finalize(() => (this.scratchPadLoader = false)))
      .subscribe((response) => {
        if (response.result && response.result.units) {
          this.scratchPads = response.result.units;
          // tslint:disable-next-line: triple-equals
          this.scratchStarred = this.scratchPads.filter((x) => x.is_key == 1).length;
        }
      });
  }

  editDriverValue(driver) {
    const payload = {
      solution_id: this.solutionId,
      account_solution_metric_id: driver.account_solution_metric_id,
      key: 'driver_baseline_answer',
      value: driver.driver_value,
      factor: 'driver_factor_id',
      factor_id: driver.driver_factor_id,
      min: driver.min,
      max: driver.max,
      increment: driver.increment,
    };

    this.subscriptioneditbaseLineAnswer = this.solutionService.editbaseLineAnswer(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.loadDrivers();
        this.solutionService.triggerBenefit.next('reloadBenefits');
      }
    });
  }

  editFinancialValue(financial) {
    const payload = {
      solution_id: this.solutionId,
      account_solution_metric_id: financial.account_solution_metric_id,
      key: 'financial_baseline_answer',
      value: financial.financial_value,
      factor: 'financial_factor_id',
      factor_id: financial.financial_factor_id,
      min: financial.min,
      max: financial.max,
      increment: financial.increment,
    };

    this.subscriptioneditbaseLineAnswer = this.solutionService.editbaseLineAnswer(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.loadFinancials();
        this.solutionService.triggerBenefit.next('reloadBenefits');
      }
    });
  }

  editScratchpadUnit(unit: { [klass: string]: any }) {
    const payload = {
      factor_id: unit['factor_id'],
      operand: unit['operand'],
      account_scratchpad_id: unit['scratchpad_id'],
      min: unit.min,
      max: unit.max,
      increment: unit.increment,
    };

    this.subscriptioneditScratchpadUnit = this.solutionService.editScratchpadUnit(payload).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.loadScratchPadUnits();
        this.solutionService.triggerBenefit.next('reloadBenefits');
      }
    });
  }
}
