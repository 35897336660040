import { Injectable } from '@angular/core';

@Injectable()
export class ActionsVpDashboardTranslations {
  public config: any = {
    component: 'actionsVpDashboard',
    display_name: 'Value Prop Actions',
    description: 'Actions for value prop dashboard',
  };

  public trans: any = {
    actions: 'Actions',
    lock: 'Lock Value Prop',
    unlock: 'Unlock Value Prop',
    share: 'Share',
    share_this_value_prop: 'Click to share this ValueProp',
    clone: 'Clone',
    clone_this_value_prop: 'Clone this Value Prop',
    refresh: 'Refresh Data',
    hide_help: 'Hide Help',
    show_help: 'Show Help',
    edit_translations: 'Edit Translations',
    download_assets: 'Download Assets',
    download: 'Download',
    take_a_tour: 'Take a tour',
    discovery_questions: 'Discovery Questions',
    edit_status: 'Click to edit status',
    ch_lock: 'Value Prop status and the ability to lock to prevent change.',
    ch_share: 'Click to share this ValueProp.',
    ch_clone: 'Click to clone this ValueProp',
    valuePropShares: 'Business Case / Value Assessment Shares',
  };
}
