import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitTypeComponent } from './unit-type.component';
import { UnitTypeTranslations } from './unit-type.translation';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';

@NgModule({
  declarations: [UnitTypeComponent],
  imports: [CommonModule, EditTranslationsModule],
  exports: [UnitTypeComponent],
  providers: [UnitTypeTranslations],
})
export class UnitTypeModule {}
