import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { CaseStudyTagsModule } from 'app/value-mapv2/solution-detail/case-studies/tags/tags.module';
import { InputMaskModule } from 'primeng/inputmask';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ModelCaseStudyDetailModule as ModelV2CaseStudyDetailModule } from '../../../value-mapv2/solution-detail/case-studies/casestudy-detail/casestudy-detail.module';
import { AddCaseStudyModule } from './add-casestudy/add-case-study.module';
import { CaseStudiesComponent } from './case-studies.component';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [CaseStudiesComponent],
  imports: [
    CaseStudyTagsModule,
    ApplicationPipesModule,
    NgbModule,
    MatTooltipModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    TableModule,
    AddCaseStudyModule,
    SidebarModule,
    ModelV2CaseStudyDetailModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  entryComponents: [CaseStudiesComponent],
  exports: [CaseStudiesComponent],
})
export class ModelCaseStudiesModule {}
