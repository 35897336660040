import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { AssetsManageProfileTranslations } from '../assets.translation';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GenerateStandardAssetReq } from '@data/services/valueprop/models/asset.model';

enum AssetDocumentType {
  PowerPointPortrait = 0,
  Word = 1,
  PowerPointLandscape = 2,
}

@Component({
  selector: 'app-asset-theme',
  templateUrl: './asset-theme.component.html',
  styleUrls: ['./asset-theme.component.scss']
})
export class AssetThemeComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();
  public showTranslate = false;
  public themes: any[] = [];
  themesObj: any = { powerpointLandScape: {}, powerpointPortrait: {}, word: {} };
  public overrideExistingtemplates = false;
  public loading = false;
  public
  @Output() onStandardAssetsGenerated = new EventEmitter();

  public selectedPPLSFile: { file: File, name: string } = { file: null, name: null };
  public selectedWordFile: { file: File, name: string } = { file: null, name: null };
  public selectedPPPTFile: { file: File, name: string } = { file: null, name: null };
  @ViewChild('fileUploadWord') fileUploadWord: ElementRef;
  @ViewChild('fileUploadPPLS') fileUploadPPLS: ElementRef;
  @ViewChild('fileUploadPPPT') fileUploadPPPT: ElementRef;

  constructor(
    private assetService: AssetService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: AssetsManageProfileTranslations,
    private commonService: CommonService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getThemeTemplates();
    this.getTranslations();
    this.commonService.notifyEditTranslation$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.showTranslate = res;
      });
  }

  private getThemeTemplates() {
    this, this.assetService.getAssetThemeTemplates()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          this.themesObj = {};
          this.themes = data.result.theme_templates;
          data.result.theme_templates
          this.themes.forEach((theme) => {
            if (theme.format_type_id === "3") {
              if (theme.portrait === "1") {
                this.themesObj.powerpointPortrait = theme;
              } else {
                this.themesObj.powerpointLandScape = theme;
              }
            } else if (theme.format_type_id === "1") {
              this.themesObj.word = theme;
            }
            theme.download_url = this.generateIFrameUrl(theme.download_url);
          })
        }
      })
  }

  public triggerFileInput(type: number) {
    switch (type) {
      case AssetDocumentType.PowerPointPortrait: this.fileUploadPPPT.nativeElement.click(); break;
      case AssetDocumentType.Word: this.fileUploadWord.nativeElement.click(); break;
      case AssetDocumentType.PowerPointLandscape: this.fileUploadPPLS.nativeElement.click(); break;
    }
  }

  public onFileChange(event: Event, type: number) {
    const input = event.target as HTMLInputElement;
    if (input.files.length > 0) {
      const file = input.files[0];
      switch (type) {
        case AssetDocumentType.PowerPointPortrait: this.selectedPPPTFile.file = file;
          this.selectedPPPTFile.name = file.name;
          break;
        case AssetDocumentType.Word: this.selectedWordFile.file = file;
          this.selectedWordFile.name = file.name;
          break;
        case AssetDocumentType.PowerPointLandscape: this.selectedPPLSFile.file = file;
          this.selectedPPLSFile.name = file.name;
          break;
      }
    }
  }

  public getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  private generateIFrameUrl(link: string) {
    let gDriveUrl = 'https://docs.google.com/gview';
    const iframe_url = `${gDriveUrl}?url=${link}&embedded=true`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(iframe_url);
  }

  public uploadFile(type: AssetDocumentType) {
    let selectedFile;
    switch (type) {
      case AssetDocumentType.PowerPointPortrait: selectedFile = this.selectedPPPTFile; break;
      case AssetDocumentType.Word: selectedFile = this.selectedWordFile; break;
      case AssetDocumentType.PowerPointLandscape: selectedFile = this.selectedPPLSFile; break;
    }
    if (selectedFile?.file) {
      this.loading = true;
      let typeId = 0;
      switch (selectedFile.file.type) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': typeId = 1; break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': typeId = 3; break;
        default: typeId = 0; break;
      }
      const formData = new FormData();
      formData.append('assetTemplateFile', selectedFile.file);
      formData.append('format_type_id', typeId.toString());
      formData.append('name', selectedFile.name);
      formData.append('description', '');
      formData.append('portrait', type === 0 ? '1' : '0');

      this.assetService.uploadThemeTemplate(formData)
        .pipe(
          take(1),
          finalize(() => this.loading = false)
        )
        .subscribe(data => {
          if (data) {
            this.clearSelectedFiles();
            this.notificationService.success(data.result, false);
            this.getThemeTemplates();
          }
        });
    }
  }

  public onGenerateStandardAssetsClick() {
    this.loading = true;
    const payload: GenerateStandardAssetReq = {
      override_existing: this.overrideExistingtemplates
    }
    this.assetService.generateStandardAssests(payload)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.loading = false)
      )
      .subscribe((res) => {
        if (res && res.result.length) {
          this.notificationService.success(this.trans.trans.standardAssetsGenerated.value, false);
          // update asset list
          this.onStandardAssetsGenerated.emit();
        }
      })
  }

  public deleteAsset(asset: any) {
    this.assetService.deleteThemeTemplate(asset.id)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.result === null) {
          this.notificationService.success(this.trans.trans.deleteSuccessfull.value, false);
          this.clearSelectedFiles();
          this.getThemeTemplates();
        }
      })
  }

  private clearSelectedFiles() {
    this.selectedPPPTFile = { file: null, name: null };
    this.selectedWordFile = { file: null, name: null };
    this.selectedPPLSFile = { file: null, name: null };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }
}
