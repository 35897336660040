import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyComponent } from './company/company.component';

const routes: Routes = [
  {
    path: 'scb',
    component: CompaniesComponent,
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    data: { page: 'companies' },
    children: [],
  },
  {
    path: 'company/add',
    loadChildren: () => import('./add-company/add-company.module').then((m) => m.AddCompanyModule),
    data: { page: 'addCompany' },
  },
  {
    path: 'company/:id',
    component: CompanyComponent,
    data: { page: 'company' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScbRoutingModule {}
