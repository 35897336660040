import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Benefit } from '@shared/models/value-prop.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RBenefitsCasestudyTranslations } from './r_benefits-casestudy.translation';

@Component({
  selector: 'app-r-benefits-casestudy',
  templateUrl: './r_benefits-casestudy.component.html',
  styleUrls: ['./r_benefits-casestudy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RBenefitsCasestudyComponent implements OnInit, OnDestroy {
  @Input() benefitView: Partial<Benefit> = {
    metric_name: undefined,
    improvement: undefined,
    improvement_type_id: undefined,
    improvement_type_name: undefined,
  };
  sidebar_title = 'View Benefit';
  showTranslate = false;
  ngUnsubscribe = new Subject();

  constructor(
    private commonService: CommonService,
    private solutionService: SolutionService,
    public trans: RBenefitsCasestudyTranslations,
    private translationService: TranslationsV2Service
  ) {}

  ngOnInit(): void {
    this.getImpTypes();

    this.getTranslations();

    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });

    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  getImpTypes(): void {
    this.solutionService.getImpTypes().subscribe((response) => {
      if (response.result) {
        this.benefitView.improvement_type_name = response.result.improvementTypes.find((type) => this.benefitView.improvement_type_id === type.id);
      }
    });
  }
}
