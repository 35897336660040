import { Component, OnInit, Input } from '@angular/core';
import { PrimengChartData, PrimengData } from '@shared/models/primeng-chart-data.model';
import { CustomChartOptions } from '@shared/models/primeng-chartoptions.model';

@Component({
  selector: 'app-horizontal-bar',
  templateUrl: './horizontalbar.component.html',
  styleUrls: ['./horizontalbar.component.scss'],
})
export class HorizontalBarComponent implements OnInit {
  @Input('data') chartData: any;
  chart: any;
  @Input() options: any;
  @Input() height = '300px';
  @Input() legend = true;
  @Input() tooltip = true;
  @Input() style2022 = false;
  constructor() {}

  ngOnInit(): void {
    this.buildChart(this.chartData);
  }

  buildChart(chartData: any): void {
    this.chart = {
      labels: this.chartData.data.labels,
      datasets: this.chartData.data.datasets,
    };
    this.options = {
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                const number = value;
                const numberLength = number.toString().length;
                let unit = '';

                if (numberLength >= 4 && numberLength <= 6) {
                  unit = 'K';
                } else if (numberLength >= 7 && numberLength <= 9) {
                  unit = 'M';
                } else if (numberLength >= 10 && numberLength <= 13) {
                  unit = 'B';
                } else if (numberLength >= 14 && numberLength <= 17) {
                  unit = 'T';
                }
                switch (unit) {
                  case 'K':
                    return Math.round(Number(number) / 1000) + unit;
                  case 'M':
                    return Math.round(Number(number) / 1000000) + unit;
                  case 'B':
                    return Math.round(Number(number) / 1000000000) + unit;
                  case 'T':
                    return Math.round(Number(number) / 1000000000000) + unit;
                  default:
                    return number;
                }
              },
            },
          },
        ],
      },
      legend: {
        display: this.legend,
        position: 'right',
        labels: {
          fontSize: 10,
        },
      },
      animation: {
        duration: 100,
      },
    };

    if (this.tooltip) {
      this.options.tooltips = {
        callbacks: {
          label: function (tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (tooltipItem.datasetIndex == 0) {
              if (chartData['d0']) {
                label += chartData['d0'][tooltipItem.index];
              }
            }
            if (tooltipItem.datasetIndex == 1) {
              if (chartData['d1']) {
                label += chartData['d1'][tooltipItem.index];
              }
            }
            if (tooltipItem.datasetIndex == 2) {
              if (chartData['d2']) {
                label += chartData['d2'][tooltipItem.index];
              }
            }
            return label;
          },
        },
      };
    } else {
      this.options.tooltips = {
        enabled: false,
      };
    }

    if (this.style2022) {
      this.options = {
        ...this.options,
        scales: {
          ...this.options.scales,
          yAxes: [
            {
              barThickness: 28,
              maxBarThickness: 28,
            },
          ],
        },
        legend: {
          position: 'bottom',
          labels: {
            fontSize: 10,
            padding: 20,
            boxWidth: 10,
          },
        },
      };
    }
  }
}
