<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="'style-2022'"></p-menu>

<div class="row" style="margin-bottom: 20px">
  <div class="col-xs-4">
    <div class="input-group keywest_search">
      <span class="input-group-addon"><i class="fa fa-search"></i></span>
      <input [(ngModel)]="strSearch" (keyup)="tableCompanies.filter($any($event).target.value, 'name', 'contains')" type="text" placeholder="Search" class="form-control" />
      <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableCompanies.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
    </div>
  </div>
  <div class="col-xs-8">
    <span class="clickable pull-right" (click)="row.toggle($event)">Row limit: {{ pageLimit }} <i class="fa fa-chevron-down"></i></span>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <p-table #tableCompanies [value]="companiesList" [paginator]="true" [rows]="pageLimit" styleClass="keywest_table" tableStyleClass="keywest_table">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ trans.general.name_label | translate }}</th>
          <th class="text-right">{{ trans.research.revenue | translate }}</th>
          <th class="text-right">{{ trans.research.employees | translate }}</th>
          <th>{{ trans.research.ticker | translate }}</th>
          <th>{{ trans.research.city | translate }}</th>
          <th>{{ trans.research.country | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company>
        <tr (click)="selectCompany(company)">
          <td class="link clickable">
            {{ company.name }}
            <span class="material-icons-outlined tooltip-custom info-icon" [ngbTooltip]="tipContent" placement="right" container="body"> info </span>
          </td>
          <td data-title="Revenue" class="text-right">{{ company.revenue_fmt }}</td>
          <td data-title="Employees" class="text-right">{{ company.employees_fmt }}</td>
          <td data-title="Ticker">{{ company.ticker }}</td>
          <td data-title="City">{{ company.city }}</td>
          <td data-title="Country">{{ company.country }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
