<div class="col-sm-12 collateraltab">
  <div class="pull-right m-b-xxl">
    <button class="btn btn_customizable" *ngIf="canEdit" (click)="openAddCompetitors(addCompetitorModal)" matTooltip="Add new COmpetitor" matTooltipPosition="above">
      <i class="fa fa-plus"></i>{{ trans.Competitors.add_new | translate }}
    </button>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <table id="searchCompetitors" class="table table-responsive custom-table searchCompetitors">
        <thead>
          <tr>
            <th style="border: 0px">{{ trans.Competitors.name | translate }}</th>
            <th style="border: 0px">{{ trans.Competitors.description | translate }}</th>
            <th style="border: 0px"></th>
          </tr>
        </thead>
        <tbody [hidden]="!loadcompetitorTable">
          <tr>
            <td colspan="3" style="border: 0px">
              <img class="actionLoader" src="{{ fullImagePath }}" />
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="solutionCompetitors.length == 0 && !loadcompetitorTable">
          <tr>
            <td colspan="3">
              {{ trans.Competitors.no_competitors_found | translate }}
            </td>
          </tr>
        </tbody>

        <tbody>
          <tr *ngFor="let competitor of solutionCompetitors" class="grid-row company-row">
            <td style="white-space: nowrap">
              <a routerLink="/model/{{ competitor.account_solution_id }}">{{ competitor.name }}</a>
            </td>
            <td>{{ competitor.description }}</td>
            <td>
              <div *ngIf="canEdit" class="dropdown pull-right">
                <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                  <li (click)="editAddCompetitors(addCompetitorModal, competitor.id)"><i class="icon-pencil"></i> {{ trans.Competitors.edit | translate }}</li>
                  <li (click)="deleteCompetitors(competitor.id)"><i class="icon-trash"></i> {{ trans.Competitors.delete | translate }}</li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #addCompetitorModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ competitorName }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="AddCompetitorForm" id="ngForm" #AddCompetitorForm="ngForm">
        <div class="row">
          <div class="col-md-6">
            <label id="id" [(ngModel)]="id" name="id"></label>
            <div class="form-group col-md-12" [ngClass]="{ 'has-error': AddCompetitorForm.controls.name?.invalid && AddCompetitorForm.controls.name?.touched }">
              <label for="alterEgo" class="labelsRequired">{{ trans.Competitors.name | translate }}</label>
              <select class="form-control role-dropdown" [(ngModel)]="name" placeholder="Select Competitors" name="name" type="text" required>
                <option disabled="disabled">{{ trans.Competitors.choose_one | translate }}</option>
                <option *ngFor="let competitor of competitors" [value]="competitor.id">
                  {{ competitor.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-12" [ngClass]="{ 'has-error': AddCompetitorForm.controls.description?.invalid && AddCompetitorForm.controls.description?.touched }">
              <label for="alterEgo" class="labelsRequired">{{ trans.Competitors.description | translate }}</label>
              <textarea class="form-control" id="Enter Description" [(ngModel)]="description" name="description" placeholder="Enter Description" required></textarea>
            </div>
            <div class="form-group col-md-12">
              <label for="landmines"> {{ trans.Competitors.landmines | translate }}</label>
              <textarea class="form-control" id="landmines" [(ngModel)]="landmines" name="landmines" placeholder="Enter Landmines" required></textarea>
            </div>
            <div class="form-group col-md-12">
              <label for="name">{{ trans.Competitors.they_ll_say | translate }}</label>
              <textarea class="form-control" id="theyll_say" [(ngModel)]="theyllSay" name="theyll_say" placeholder="Enter they'll Say" required></textarea>
            </div>
            <div class="form-group col-md-12">
              <label for="their_landmines">{{ trans.Competitors.their_landmines | translate }}</label>
              <textarea class="form-control" id="their_landmines" [(ngModel)]="theirLandmines" name="their_landmines" placeholder="Enter Their Landmines" required></textarea>
            </div>
            <div class="form-group col-md-12">
              <label for="our_response">{{ trans.Competitors.our_response | translate }}</label>
              <textarea class="form-control" id="our_response" [(ngModel)]="ourResponse" name="our_response" placeholder="Enter Their Landmines" required></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-sm-9">
                <button type="submit" class="btn btn_customizable" (click)="SaveCompetitor(AddCompetitorForm)" [disabled]="AddCompetitorForm.invalid">Save</button>
              </div>
            </div>
          </div>
          <div class="pull-right">
            <label class="labelsRequiredInfo">{{ trans.Competitors.is_required | translate }}</label>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
