import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from '@shared/models/asset-tag.models';

@Pipe({
  name: 'filterTags',
})
export class FilterTagsPipe implements PipeTransform {
  transform(tags: Tag[], searchString: string): Tag[] {
    if (this.isEmptyInputValue(searchString)) {
      return tags;
    }
    return tags.filter(
      (tag) => tag.name.trim().toLowerCase().includes(searchString.trim().toLowerCase()) || tag.tag.trim().toLowerCase().includes(searchString.trim().toLowerCase())
    );
  }

  isEmptyInputValue(value: any): boolean {
    return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
  }
}
