import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '@data/services/user/user.service';
import { NgForm } from '@angular/forms';
import { AlertService } from '@services/alert.service';
import { Router } from '@angular/router';
import { ApiServiceService } from '../api-service.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Translations } from '@data/services/translation/translation.service';

@Component({
  selector: 'app-intake',
  templateUrl: './intake.component.html',
  styleUrls: ['./intake.component.scss'],
  providers: [UserService],
})
export class IntakeComponent implements OnInit, OnDestroy {
  termsOfUseContent: string;
  checkboxValue: boolean;
  showPasswordScrn = false;
  userId: string;
  userToken: string;
  result: { result: string };
  message: string;
  errors: string;
  modalReference: NgbModalRef;
  termsAccepted = true;

  subscriptionreadUserTerms: Subscription;
  subscriptionacceptUserTerms: Subscription;
  subscriptionresetPassword: Subscription;
  subscriptionchangeTempPassword: Subscription;

  constructor(
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private apiServiceService: ApiServiceService,
    public translate: TranslateService,
    public trans: Translations
  ) {
    this.translate.setDefaultLang('defaultLang');
    this.trans.funcLoadOverrides(this.trans);
  }

  ngOnInit(): void {
    this.userId = sessionStorage.getItem('uid');
    this.userToken = sessionStorage.getItem('vcu');
    this.termsAccepted = sessionStorage.getItem('termsAccepted') === 'true' ? true : false;
    this.readUserTerms();
  }

  ngOnDestroy(): void {
    if (this.subscriptionreadUserTerms) {
      this.subscriptionreadUserTerms.unsubscribe();
    }
    if (this.subscriptionacceptUserTerms) {
      this.subscriptionacceptUserTerms.unsubscribe();
    }
    if (this.subscriptionresetPassword) {
      this.subscriptionresetPassword.unsubscribe();
    }
    if (this.subscriptionchangeTempPassword) {
      this.subscriptionchangeTempPassword.unsubscribe();
    }
  }

  readUserTerms(): void {
    let content = '';
    this.subscriptionreadUserTerms = this.userService.readUserTerms().subscribe((result) => {
      content = result.result;
      this.termsOfUseContent = content.replace(new RegExp('\n', 'g'), '<br />');
    });
  }

  acceptTermsOfUse(): void {
    this.subscriptionacceptUserTerms = this.userService.acceptUserTerms().subscribe(() => {
      this.message = this.result.result;
      this.alertService.success(this.message);
      sessionStorage.setItem('intake', 'false');
      this.apiServiceService.changeIntakeVal(false);
      this.router.navigate(['/dashboard']);
    });
  }

  ngOnSubmit(form: NgForm): void {
    this.alertService.clear();

    const payload = {
      current_password: form.value.currentPassword,
      new_password: form.value.newPassword,
      confirm_password: form.value.confirmPassword,
    };

    if (form.valid) {
      this.subscriptionresetPassword = this.userService.resetPassword(payload).subscribe(
        (result) => {
          this.result = result;
          this.subscriptionchangeTempPassword = this.userService.changeTempPassword().subscribe(() => {
            this.acceptTermsOfUse();
          });
        },
        (error) => {
          this.errors = error;
          this.message = this.errors;
          this.alertService.error(this.message);
        }
      );
    }
  }

  open(content): void {
    this.modalReference = this.modalService.open(content, { windowClass: 'TACModal', backdrop: 'static', keyboard: false });
  }
}
