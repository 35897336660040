import { NgModule } from '@angular/core';

import { ModelsComponent } from "./models.component";
import { InputMaskModule } from 'primeng/inputmask';

import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TabViewModule } from 'primeng/tabview';

import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';

import { MaintenanceSituationsModule } from '../../value-map-maintenance/views/situations/situations.module';
import { ModelValueCategoryModule } from '../../value-map-maintenance/views/value-category/value-category.module';

import { DoughnutModelsChartModule } from 'app/common/chart/doughnut-models/doughnut-models-chart.module';
import { BarModelsModule } from 'app/common/chart/bar-models/bar-models.module';
import { SolutionListModule } from '../solution-list/solution-list.module';
import { ValueMapFactorsModule } from 'app/value-map-maintenance/views/factors/factors.module';
import { GuidedTourModule } from './guided-tour/guided-tour.module';
import { GuidedTourService } from './guided-tour/guided-tour.service';


import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Translations } from '@data/services/translation/translation.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ModelsV2ListTranslations } from './models.translation';
import { GoalGroupsModule } from 'app/value-map-maintenance/views/goal-groups/goal-groups.module';
import { MatTooltipModule } from '@angular/material/tooltip';
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		ModelsComponent,
	],
	imports: [
		GuidedTourModule.forRoot(),
    MatTooltipModule,
		ValueMapFactorsModule,
		SolutionListModule,
		DoughnutModelsChartModule,
		BarModelsModule,
		ModelValueCategoryModule,
		MaintenanceSituationsModule,
		DLMenubarModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		MenuModule,
		SidebarModule,
		TableModule,
		NgbModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
		TabViewModule,
		EditTranslationsModule,
    GoalGroupsModule
	],
	providers: [
		GuidedTourService, Translations,  TranslationsV2Service, ModelsV2ListTranslations
	],
	entryComponents: [
		ModelsComponent,
	],
	exports: [ModelsComponent]
})
export class ModelsModule { }