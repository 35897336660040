import { Quote } from "app/value-map/solution-detail/case-studies/quote/models/quote.model";


export class CaseStudy {
 constructor(
    public account_id: number = null,
    public account_solution_id: number = null,
    public benefits: CaseStudyBenefit[] = [],
    public benefits_total: number = null,
    public company_id: string= null,
    public created: string= null,
    public dep_casestudy_id: number= null,
    public id: string= null,
    public is_account_solution: string = null,
    public modified: string = null,
    public name: string= null,
    public quotes: Quote[] =[],
    public redacted: number= null,
    public reference_url: string= null,
    public sections: CaseStudySection[] = [],
    public sections_total: number= null,
    public tag: string= null,
    public testimonials_total: number= null,
    public value_prop_id: number= null,
    public tags?: string[],
 ){}
}

export interface CaseStudySection {
    account_case_study_id: number;
    case_study_section_id?: number;
    case_study_section_images?: CaseStudyImage[];
    created?: string;
    dep_case_study_section_id?: number;
    description: string;
    id: number;
    modified?: string;
    name: string;
    section_type_id: number;
    type?: string;
    url?: string;
    expand?: boolean;
    sectionType?: CaseStudySectionType;
}

export interface CaseStudyBenefit {
    account_id: number;
    account_solution_metric_id: number;
    case_study_id: number;
    company_id: number;
    created: string;
    id: number;
    improvement: number;
    improvement_type_id: number;
    is_solution_metric: number;
    modified: string;
    value_prop_metric_id: number;
}

export interface CaseStudyQuote {
    case_study_id: number;
    created: string;
    first_name: string;
    id: number;
    last_name: string;
    modified: string;
    quote: string;
    title: string;
}

export interface CaseStudyImage {
    account_case_study_section_id: number;
    mediaType?: CaseStudyMediaType;
    created?: string;
    description: string;
    id: string;
    image_name: string;
    image_size: string;
    modified?: string;
    path: string;
    type: CaseStudyMediaType;
}

export interface CaseStudyMediaObject {
    type: string;
    path: string;
    description?: string;
}

export type CaseStudyMediaType = 'media' | 'link';

export interface CaseStudySectionType {
    created: string;
    description: string;
    id: string;
    modified: string;
    name: string;
}