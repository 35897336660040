import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { BenefitsImplementation } from '../../benefits.implementation';
import { DashboardSimpleImplementation } from 'app/value-prop/value-prop-dashboard/dashboard_simple/simple.implementation';
import { Subject } from 'rxjs';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BenefitsSimpleTranslations } from './benefits-panel-simple.translation';
import { takeUntil, take } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-benefit-panel-simple',
  templateUrl: './benefits-panel-simple.component.html',
})
export class BenefitPanelSimpleComponent implements OnInit, OnDestroy {
  showTranslate: boolean = false;
  ngUnsubscribe = new Subject();
  canEdit = false;
  feature57: boolean = false;
  disableEdits: boolean = false;
  crm: string;
  @Input() showContinue: boolean = true;
  @Output() back = new EventEmitter();

  constructor(
    private translationService: TranslationsV2Service,
    public trans: BenefitsSimpleTranslations,
    private commonService: CommonService,
    public bI: BenefitsImplementation,
    public sI: DashboardSimpleImplementation
  ) {}

  ngOnInit() {
    this.crm = sessionStorage.getItem('crm');
    this.feature57 = this.commonService.checkFeature('57'); // benefit fly out
    this.disableEdits = this.commonService.checkFeature('151');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.canEdit = this.bI.permissions && this.bI.permissions.benefits_editable && this.bI.valueProp.vp_can_edit;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  nextStep() {
    this.bI.callbackNext.emit();
  }

  goBack() {
    this.bI.back.emit();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  refreshTranslation() {
    this.getTranslations();
  }

  drop(dropEvent: CdkDragDrop<unknown[]>, dropArray: any[]) {
    moveItemInArray(dropArray, dropEvent.previousIndex, dropEvent.currentIndex);
    this.bI.saveDropEvent(dropArray);
  }

  editValueCategory( benefitCategory ) {
    benefitCategory.alt_name = benefitCategory.alt_name ?? benefitCategory.name;
    benefitCategory.edit_value_category = !benefitCategory.edit_value_category;
  }

  closeValueCategoryEdit( benefitCategory ) {
    benefitCategory.edit_value_category = !benefitCategory.edit_value_category;
  }

  updateValueCategoryName( benefitCategory ) {
    benefitCategory.edit_value_category = !benefitCategory.edit_value_category;

    const params = {
      name: benefitCategory.alt_name,
      value_category_id: benefitCategory.value_category_id,
    };

    this.bI.valuePropService
      .updateValueCategoryOverride(this.bI.valueProp.id, params)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.result && response.result.success !== false) {
          this.bI.valuePropService.refreshDashboard.next();
          this.bI.loadBenefits();
        }
      });
  }
}
