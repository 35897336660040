import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'r_factors',
    display_name: 'Factors read',
    description: 'Read Factors'
  };

  public trans: any = {
    factor_groups: 'Factor Groups',
    unit_type: 'Unit Type',
    name: 'Name',
    description: 'Description',
    precision: 'Precision',
    scaled_by: 'Scaled By',
    scales_how: 'Scales How?',
    ratio: 'Ratio',
    formula: 'Formula',
    is_key_factor: 'Is Key Factor?',
    literal_number: 'Literal Number',
    greater_than_zero: 'Greater Than Zero',
    min: 'Min',
    max: 'Max',
    increment: 'Increment',
    company_element: 'Company Element',
    view_factor: 'View Factor'
  };
}
