import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DashboardTopComponent } from './top.component';
import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ValuePropDashboardROIModule } from '../roi/roi.module';
import { CashflowModule } from '../cashflow/cashflow.module';
import { TCOModule } from '../tco/tco.module';
import { NotesModule } from '../notes/notes.module';
import { LogModule } from '../log/log.module';
import { ScoreCardModule } from '../score-card/score-card.module';
import { ShareV1Module } from '../share/share.module';
import { ShareModule } from '../sharev2/share.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { DefaultTranslations } from './top.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CompetitiveTCOModule } from '../competitive-tco/competitive-tco.module';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string) {
		return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
	}
}

@NgModule({
	imports: [
		DLMenubarModule,
		EditTranslationsModule,
		FormsModule,
		CommonModule,
		ValuePropDashboardROIModule,
		CashflowModule,
		TCOModule,
		NotesModule,
		LogModule,
		ScoreCardModule,
		ShareModule,
		ProgressBarModule,
		DropdownModule,
    ShareV1Module,
    CompetitiveTCOModule
	],
	declarations: [
		DashboardTopComponent
	],
	providers: [DefaultTranslations, TranslationsV2Service],
	exports: [DashboardTopComponent]
})
export class DashboardTopModule { }
