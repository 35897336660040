import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { CompetitorsComponent } from './competitors/competitors.component';
import { PartnersComponent } from './partners/partners.component';
import { SolutionReportComponent } from './solution-report/solution-report.component';
import { DladminComponent } from './dladmin/dladmin.component';
import { ApplicationsSettingsComponent } from './applications-settings/applications-settings.component';
import { AssetsComponent } from './assets/assets.component';
import { AdminDiscoveryQuestionsComponent } from './discovery-questions/discovery-questions.component';
import { AdminUserGroupsComponent } from './user_groups/groups.component';
import { SurveyTemplateComponent } from './survey-template/survey-template.component';
import { AdminAuthGuard } from './admin-auth-guard/admin.guard.service';
import { UserAuthGuard } from './admin-auth-guard/user.guard.service';
import { AssetAuthGuard } from './admin-auth-guard/asset.guard.service';
import { NoPrivilegesGuard } from './admin-auth-guard/no-privileges.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { EditTeamComponent } from './user-profile/edit-team/edit-team.component';
import { OtherReportsComponent } from './other-reports/other-reports.component';
import { SettingsComponent } from './settings/settings.component';
import { ModelAdminGuard } from './admin-auth-guard/model-admin.guard';
import { EditSequencesComponent } from './edit-sequences/edit-sequences.component';
import { CustomAssetTagsComponent } from './custom-asset-tags/custom-asset-tags.component';
import { BulkUpdateComponent } from './bulk-update/bulk-update.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AssetLogComponent } from './assets/asset-log/asset-log.component';
import { ConfigurableWorkflowsComponent } from './configurable-workflows/configurable-workflows.component';
import { WebCalcGuard } from './admin-auth-guard/web-calc.guard';

const routes: Routes = [
  {
    path: 'profile',
    component: UserProfileComponent,
    children: [],
  },
  {
    path: 'full-profile',
    component: UserProfileComponent,
    children: [],
  },
  {
    path: 'profile-settings',
    component: UserProfileComponent,
    canActivate: [NoPrivilegesGuard],

    children: [],
  },
  {
    path: 'team',
    component: EditTeamComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [NoPrivilegesGuard],

    children: [],
  },
  {
    path: 'custom-asset-tags',
    component: CustomAssetTagsComponent,
    canActivate: [AdminAuthGuard],
    children: [],
  },
  {
    path: 'bulk-update',
    component: BulkUpdateComponent,
    canActivate: [AdminAuthGuard],
    children: [],
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'calc',
    loadChildren: () => import('./calc/calc.module').then((m) => m.AdminCalcModule),
    data: { page: 'calc', currentNav: 'settings' },
    canActivate: [WebCalcGuard],
  },

  {
    path: 'calc/:id',
    loadChildren: () => import('./calc/ae-calc/ae-calc.module').then((m) => m.AECalcModule),
    data: { page: 'calc', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },

  {
    path: 'features',
    loadChildren: () => import('./features/features.module').then((m) => m.AdminFeaturesModule),
    data: { page: 'features', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },

  {
    path: 'solution-report',
    component: SolutionReportComponent,
    data: { page: 'solution-report', currentNav: 'settings' },
    canActivate: [ModelAdminGuard],
  },
  {
    path: 'other-reports',
    component: OtherReportsComponent,
    data: { page: 'solution-reports', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    data: { page: 'users', currentNav: 'settings' },
    canActivate: [UserAuthGuard],
  },
  {
    path: 'user_groups',
    component: AdminUserGroupsComponent,
    data: { page: 'user_groups', currentNav: 'settings' },
    canActivate: [UserAuthGuard],
  },
  {
    path: 'competitors',
    component: CompetitorsComponent,
    data: { page: 'competitors', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'partners',
    component: PartnersComponent,
    data: { page: 'partners', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'edit-sequences',
    component: EditSequencesComponent,
    data: { page: 'edit-sequences' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'configurable-workflows',
    component: ConfigurableWorkflowsComponent,
    data: { page: 'configurable-workflows' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'dlAdmin',
    component: DladminComponent,
    data: { page: 'dlAdmin' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'applications-settings',
    component: ApplicationsSettingsComponent,
    data: { page: 'applications-settings', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'asset-management',
    component: AssetsComponent,
    data: { page: 'asset-management', currentNav: 'settings' },
    canActivate: [AssetAuthGuard],
  },
  {
    path: 'discovery-questions',
    component: AdminDiscoveryQuestionsComponent,
    data: { page: 'discovery-questions', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'survey-templates',
    component: SurveyTemplateComponent,
    data: { page: 'survey-templates', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'guided-discovery',
    loadChildren: () => import('./guided-discovery-settings/guided-discovery-settings.module').then((m) => m.GuidedDiscoverySettingsModule),
    data: { page: 'guided-discovery', currentNav: 'settings' },
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'asset-log',
    component: AssetLogComponent,
    data: { page: 'app-asset-log' },
    canActivate: [AdminAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
