import { NgModule } from '@angular/core';
import { FactsService } from '@data/services/facts/facts.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { SharedModule } from '../shared/shared.module';
import { ValueMapModule } from '../value-map/value-map.module';
import { AddCasestudyRealizationComponent } from './case-studies/add-casestudy-realization/add-casestudy-realization.component';
import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { SurveyComponent } from './survey/survey.component';
import { ValueRealizationDetailComponent } from './value-realization-detail/value-realization-detail.component';
import { ValueRealizationRoutingModule } from './value-realization-routing.module';
import { ValueRealizationComponent } from './value-realization/value-realization.component';
import { ModelCaseStudyDetailModule } from 'app/value-map/solution-detail/case-studies/casestudy-detail/casestudy-detail.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { SidebarModule } from 'primeng/sidebar';
import { DashboardBottomModule } from 'app/value-prop/value-prop-dashboard/panel_bottom/bottom.module';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValueRealizationTranslations } from './value-realization/value-realization.translation';
import { ValueRealizedDetailTranslations } from './value-realization-detail/value-realization-detail.translation';
import { CreateSurveyRealizationTranslation } from './create-survey/create-survey.translation';
import { RealizationSurveysTranslations } from './survey/survey.translation';
import { CasestudiesValueRealizedTranslations } from './case-studies/case-studies.translation';
import { AddCasestudyRealizationTranslations } from './case-studies/add-casestudy-realization/add-casestudy-realization.translation';
import { DLMenubarModule } from '@shared_components/primeng_menubar/menubar.module';
import { CalendarModule } from 'primeng/calendar';
import { RealizationCaseStudyModule } from './case-studies/case-studies.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CreateSurveyCorrectModule } from './create-survey/create-survey-correct.module';
import { MatTooltipModule } from '@angular/material/tooltip';
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    AreYouSureModule,
    TableModule,
    MenuModule,
    DashboardBottomModule,
    SidebarModule,
    MatTooltipModule,
    EditTranslationsModule,
    SharedModule,
    ValueRealizationRoutingModule,
    ValueMapModule,
    ModelCaseStudyDetailModule,
    DLMenubarModule,
    CalendarModule,
    RealizationCaseStudyModule,
    InputSwitchModule,
    CreateSurveyCorrectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  declarations: [
    ValueRealizationComponent,
    ValueRealizationDetailComponent,
    SurveyComponent,
  ],
  exports: [
    SurveyComponent,
  ],
  providers: [
    ValuerealizationService,
    FactsService,
    Translations,
    TranslationsV2Service,
    ValueRealizationTranslations,
    ValueRealizedDetailTranslations,
    CreateSurveyRealizationTranslation,
    RealizationSurveysTranslations,
    CasestudiesValueRealizedTranslations,
    AddCasestudyRealizationTranslations
  ],
  entryComponents: [AddCasestudyRealizationComponent],
})
export class ValueRealizationModule {}
