import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolutionService } from '@data/services/solution/solution.service';
import { CommonService } from '@data/services/common/common.service';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AECompetitorTranslation } from './ae_competitors.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';

@Component({
	selector: 'app-ae-competitors',
	templateUrl: './ae_competitors.component.html'
})
export class AECompetitorsComponent implements OnInit, OnDestroy {
	@Input() solutionID;
	@Input() can_edit: boolean = false;
	@Input() mode: string = "add";
	@Output() callback = new EventEmitter();

	ngUnsubscribe = new Subject();

	solutionCompetitors: any = [];
	competitors: any = [];
	loadcompetitorTable: boolean = false;
	accountSolutionId: number;

	image_url: string;
	fullImagePath: string;
	competitorName: any;

	addEditThreshold: any;
	loadingSave: boolean = false;
	toggleAddCompetitor: boolean = false;
	dropdown: any[] = [];
	sidebar_title: any;
	showTranslate = false;

	cols = [
		{ field: 'name', header: 'Name' }
	]

	@ViewChild('formAddCompetitor') formAddCompetitor: NgForm;

	@Input() competitorFormObj = {
		id: undefined,
		description: undefined,
		landmines: undefined,
		theyll_say: undefined,
		their_landmines: undefined,
		our_response: undefined,
		competitor_id: undefined,
		account_competitor_id: undefined
	};

	constructor(
		private solutionService: SolutionService,
		private route: ActivatedRoute,
		private commonService: CommonService,
		private notification: NotificationService,
		public trans: AECompetitorTranslation,
		private translationService: TranslationsV2Service
	) {
		this.image_url = this.commonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
	}

	ngOnInit() {

		this.getTranslations();

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
		
		this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => this.accountSolutionId = params['id']);

		if (!this.accountSolutionId) {
			this.accountSolutionId = this.solutionID;
		}

		this.getCompetitorList();
		this.getCompetitorsByAccount();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}

	close(): void {
		this.callback.emit();
	}
		
	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

			this.sidebar_title = this.mode == 'add' ? this.trans.trans.addcompetitor :
			this.trans.trans.editcompetitor;
		});
	}

	getCompetitorsByAccount() {
		let aid = sessionStorage.getItem('aid')
		this.solutionService.getCompetitors(aid).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
			this.competitors = result.result.data;
		});
	}

	getCompetitorList() {
		this.loadcompetitorTable = true;
		this.solutionService.getSolutionCompetitorList(this.accountSolutionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response) => {
			if (response.result) {
				this.solutionCompetitors = response.result;
			} else {
				this.solutionCompetitors = [];
			}
			this.loadcompetitorTable = false;
		});
	}

	saveCompetitor(form: NgForm) {
		this.loadingSave = true;
		let payload = {
			"competitor_id": form.controls.id.value,
			"account_solution_id": this.accountSolutionId,
			"account_competitor_id": form.controls.competitor_id.value,
			"description": form.controls.description.value,
			"landmines": form.controls.landmines.value,
			"theyll_say": form.controls.theyll_say.value,
			"their_landmines": form.controls.their_landmines.value,
			"our_response": form.controls.our_response.value
		};

		if (this.mode == "add") {
			this.solutionService.createCompetitor(payload).subscribe(() => {
				this.notification.success("Competitor created successfully", false); // Params {message, islogout}
				this.getCompetitorList();
				this.callback.emit("");
			});
		} else {
			this.solutionService.updateCompetitor(payload).subscribe(() => {
				this.notification.success("Competitor updated successfully", false); // Params {message, islogout}
				this.getCompetitorList();
				this.callback.emit("");
			});
		}
	}
}
