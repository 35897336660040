import { Injectable } from '@angular/core';

@Injectable()
export class CompanySearchTranslation {
  public config: any = {
    component: 'companySearch',
    display_name: 'Company Search Modal',
    description: 'Modal used to search and select a company',
  };

  public trans: any = {
    add: 'Add New',
    all: 'All',
    company_name: 'Account',
    created: 'Date Created',
    dashboard: 'Dashboard',
    expected: 'Expected',
    mine: 'Mine',
    name: 'Name',
    number_of_trackers: 'Trackers Created',
    realized: 'Achieved',
    search: 'Search',
    shared: 'Shared with Me',
    valueRealized: 'Value Realized',
    addNew: 'Add New Value Realization',
    letsCreate: "Let's create a new value realization",
    searchCompany: 'Search for the company below',
    searchCompanyPlaceholder: 'Select the Company',
    suggestedCompanies: 'Suggested Companies',
    companyName: 'Company Name',
    accountId: 'Account ID',
    vcAccountId: 'VC Account ID',
    crmAccountId: 'CRM Account ID',
    revenueM: 'Revenue (M)',
    city: 'City',
    state: 'State',
    country: 'Country',
    revenue: 'Revenue',
    employees: 'Empoyees',
    sector: 'Sector',
    continue: 'Continue',
    valuePropositions: 'Value Propositions',
    totalValuePropositions: 'Total Value Propositions Created',
  };
}
