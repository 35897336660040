
<p-table [value]="items" responsiveLayout="scroll" styleClass='keywest_table' tableStyleClass='keywest_table' >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns">{{useTanslations ? useHeaderValues ? trans[col.header].value : trans[col.field].value  : useHeaderValues ? col.header : col.field }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-items>
      <tr >
        <td  [style.height]="rowHeight" *ngFor="let col of columns"><p-skeleton  width="100%" height="2rem" ></p-skeleton></td>
      </tr>
    </ng-template>
  </p-table>