import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { ChipsModule } from 'primeng/chips';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { ModelBenefitsCaseStudyModule } from '../benefits-casestudy/benefits-casestudy.module';
import { ModelQuoteModule } from '../quote/quote.module';
import { ModelSectionsModule } from '../sections/sections.module';
import { CaseStudyTagsModule } from '../tags/tags.module';
import { CasestudyDetailComponent } from './casestudy-detail.component';
import { CaseStudyDetailTranslations } from './casestudy-detail.translation';

@NgModule({
  declarations: [CasestudyDetailComponent],
  imports: [
    CaseStudyTagsModule,
    ChipsModule,
    SidebarModule,
    FieldsetModule,
    ModelQuoteModule,
    ModelBenefitsCaseStudyModule,
    ModelSectionsModule,
    ModelQuoteModule,
    InputMaskModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    EditTranslationsModule,
    AreYouSureModule,
    ErrorInputWrapperModule,
    ReactiveFormsModule,
  ],
  providers: [TranslationsV2Service, CaseStudyDetailTranslations],
  entryComponents: [CasestudyDetailComponent],
  exports: [CasestudyDetailComponent],
})
export class ModelCaseStudyDetailModule {}
