import { Injectable } from '@angular/core';

@Injectable()
export class BenefitDetailTranslations {
  public config: any = {
    component: 'benefitDetail',
    display_name: 'Benefit Details',
    description: 'Details for a benefit',
  };

  public trans: any = {
    ch_models: 'The catalog of your value usually at the solution level',
    tco_name: 'TCO Name:',
    experience_an_improvement_of: 'experience an improvement of',
    with_this_benefit: 'with this benefit.',
    accrual: 'Accrual',
    is_this_benefit_considered_soft: 'Is this benefit considered soft?',
    yes: 'Yes',
    no: 'No',
    expense_type: 'Expense Type',
    what_type_of_benefit_is_this: 'What type of benefit is this?',
    should_this_benefit_be_quantified: 'Should this benefit be quantified?',
    edit: 'Edit',
    save_changes: 'Save Changes',
    cancel: 'Cancel',
    model: 'Model:',
    ch_renameBenefit: 'Rename the benefit',
    yearr: 'Year ',
    rateThisBenefit: 'Rate this benefit',
    addRating: 'Add a rating',
    tco: 'TCO Name',
    capabilities: 'Capabilities',
    subtypes: 'Subtypes',
    scratchpad: 'Scratchpad',
    note: 'Note',
    caseStudies: 'Case Studies',
    additionalDetails: 'Additional Details',
    painPoints: 'Pain Points',
    change_benefits_status: 'Change Benefits Status',
    formula: 'Formula',
    current_effort: 'Current Effort',
    with: 'With',
    original_name: 'Original Name',
    total_benefit: 'Total Benefit',
    description: 'Description',
    features: 'Features',
    case_studies: 'Case Studies',
    add_note: 'Add Note',
    GrowthAfter1stYear: 'Growth after 1st Year',
    save: 'Save',
    save_note: 'Save Note',
    save_description: 'Save Description',
    save_features: 'Save Features',
    delete_note: 'Delete Note',
    are_you_sure_you_want_to_delete_this_note: 'Are you sure you want to delete this note?',
    applyToAll: 'Apply to all',
    sub_types: 'SubTypes',
    edit_note: 'Edit Note',
    growth: 'Growth / Decline',
    active: 'Active',
    na: 'N/A',
    soft: 'Considered Soft',
    year: 'Year',
    benefits: 'Benefits',
    impact: 'Impact Type',
    choose: 'Choose',
    pain_points: 'Pain Points',
    my_comments: 'My Comments',
    noComment: 'no comment entered',
    noneProvided: 'None Provided',
    yourRating: 'Your Rating',
    allRatings: 'All Ratings',
    writeNoteHere: 'Write your note here',
    benefitDetail: 'Benefit Detail',
    hard: 'Hard',
    strategic: 'Strategic',
    notes: 'Notes',
    notes2: 'Issues',
    notes3: 'Resolutions',
    operationalObjectives: 'Operational Objectives',
    benefitName: 'Benefit Name',
    phasing: 'Benefit Ramp',
    inactiveScratchpad: 'Scratchpad is inactive',
    growthAndDecline: 'Growth and Decline',
    benefitUpdated: 'Benefit updated successfully',
    TCONameSaved: 'Successfully saved your TCO Name',
    somethingWrong: 'Something went wrong',
    inlineSave: 'Inline Save Successful',
    benefitStatusSaved: 'Benefit status updated Successfully',
    factorNameSaved: 'Factor name updated successfully',
    metricNameSaved: 'Successfully saved your metric name',
    metricDescriptionSaved: 'Successfully saved your metric description',
    metricFeaturesSaved: 'Successfully saved your metric features',
    noteError: 'Your Note did not save',
    benefitNoteDelete: 'Successfully deleted your benefit note',
    saveSuccess: 'Successfully saved',
    clearOverrides: 'Clear All Overrides?',
    containsOverriddenValue: '* Contains manually overridden value',
    clearOverridesWarning: 'Clearing Overrides will return ALL factors in this benefit to default values including scratchpads. Are you sure?',
    phasingContainsOverriddenValue: '* This year has been manually overridden so changes to growth and decline will have no impact without first clearing the Overrides',
  };
}
