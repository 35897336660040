<div class='models_panel' style='margin-bottom: 20px;' *ngIf="valuePropTemplates && valuePropTemplates.length" [hidden]="enablePropSection">
	<div class='models_page_header_container_small'>
		<div class='keywest_header_small'>
			<div class='row'>
				<div class='col-xs-12'>
					<div class='title'>
						{{ trans.trans.title.value }} <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();'
						[component]="trans.config.component" [isTranslationV2]='true' [transObj]="trans.trans.title" key='vp_dashboard.assets.title'>
						</app-editTranslation>
					</div>
				</div>
			</div>
		</div>

		<div class='row' *ngIf="dashboard == 'v2' && showHelp">
			<div class='col-xs-12'>
				<div class='well well-info'>
					{{trans.trans.ch_assetsPanel.value}}
				</div>
			</div>
		</div>
		<div class='row' *ngFor="let template of valuePropTemplates" style='margin-bottom: 20px;'>
			<div class="col-md-12">
				<i [ngClass]="getFAIcon(template.format)" [ngStyle]="getFAColor(template.format)"></i>
				<a *ngIf="valueProp.calculator_public_key" href="{{assetUrl}}assets/{{assetGenerator}}?account_asset_template_id={{template.account_asset_template_id}}&user_id={{userId}}&token={{token}}&value_prop_id={{valueProp.id}}&public_key={{valueProp.calculator_public_key}}" target="_blank" [ngbTooltip]="template.description" container="body" placement="right" class='link' style='padding-left: 5px;'>{{template.name}}</a>
				<a *ngIf="!valueProp.calculator_public_key" href="{{assetUrl}}assets/{{assetGenerator}}?account_asset_template_id={{template.account_asset_template_id}}&user_id={{userId}}&token={{token}}&value_prop_id={{valueProp.id}}" target="_blank" [ngbTooltip]="template.description" container="body" placement="right" class='link' style='padding-left: 5px;'>{{template.name}}</a>
			</div>
		</div>
	</div>
</div>