
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CapabilitiesService } from '@data/services/capabilities/capabilities.service';
import { StyleService } from 'app/style.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-value-capabilities',
	templateUrl: './capabilities.component.html',
	styleUrls: ['./capabilities.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ValueMapCapabilitiesComponent implements OnInit {

	id: string = "";
	name: string = "";
	description: string = "";
	showAdd: boolean = false;
	painPoints: any[] = [];
	account_id: any;

	menu: any[] = [];

	// new
	addEditPainPoints: any;
	loadingSave: boolean = false;
	togglePainPoints: boolean = false;
	sidebar_title: string = "Add New Pain Point";
	addedit: string = "add";
	cols = [
		{ field: 'name', header: 'Name' },
		{ field: 'description', header: 'Description' }
	]

	fullImagePath: string;
	style2022$: Observable<boolean>;

	constructor(
		private painPoint: CapabilitiesService,
		private styleService: StyleService
	) {

	}

	ngOnInit() {
		this.account_id = sessionStorage.getItem("aid");
		this.funcGet();
		this.style2022$ = this.styleService.style2022;
	}

	funcBuildMenu(elem, menu, event) {
		this.menu = [
			{
				label: "Edit", icon: "fa fa-pencil", command: () => {
					this.funcEdit();
					this.id = elem.id;
					this.name = elem.name;
					this.description = elem.description;
					this.addedit = "edit";
				}
			},
			{ label: "Delete", icon: "fa fa-times", command: () => { this.funcDelete(elem); } }
		]
		menu.toggle(event)
	}

	funcShowAddPainPoint() {
		this.addedit = "add";
		this.togglePainPoints = !this.togglePainPoints;
		this.sidebar_title = "Add Capability";
		this.id = "";
		this.name = "";
		this.description = "";
	}

	funcGet() {
		this.painPoint.getCapabilities(this.account_id).subscribe(res => {
			if (res.result) {
				this.painPoints = res.result;
			}
		})
	}

	funcSave() {
		if (this.addedit == "edit") {
			this.funcSaveEdit();
			return;
		}
		let payload = {
			name: this.name,
			description: this.description
		}

		this.painPoint.postCapability(this.account_id, payload).subscribe(() => {
			this.funcGet();
			this.loadingSave = false;
			this.togglePainPoints = false;
			this.name = "";
			this.description = "";
			this.showAdd = false;
		})
	}

	funcDelete(elem) {
		this.painPoint.deleteCapability(this.account_id, elem.id).subscribe(() => {
			this.funcGet();
		})
	}

	funcEdit() {
		this.sidebar_title = "Edit Capability";
		this.togglePainPoints = !this.togglePainPoints;
	}

	funcSaveEdit() {
		let payload = {
			account_capability_id: this.id,
			name: this.name,
			description: this.description
		}
		this.painPoint.putCapability(this.account_id, payload).subscribe(() => {
			this.funcGet();
			this.loadingSave = false;
			this.togglePainPoints = false;
		})
	}
}