import { Injectable } from '@angular/core';

@Injectable()
export class DefaultTranslations {
  public config: any = {
    component: 'combined',
    display_name: 'Rep Simple Scenario',
    description: 'Scenario Section in rep simple'
  };

  public trans: any = {
    loading: 'Loading Scenario Details',
    ch_scenario: 'Here is a brief summary of the Account. Please choose the Model(s) you want to use and make updates to any other fields as required.',
    account: 'Account',
    Revenue: 'Revenue(M)',
    employees: 'Employees',
    addr1: 'Address 1',
    addr2: 'Address 2',
    city: 'City',
    company_name: 'Company Name',
    country: 'Country',
    zip: 'Zip Code',
    edit: 'Edit',
    editing: 'Editing Company Info',
    gross: 'Gross Profits',
    account_id: 'Account ID',
    optional: 'Optional Information',
    phone: 'Phone #',
    required: 'Required',
    save: 'Save',
    state: 'State',
    ticker: 'Ticker',
    website: 'Website URL',
    industry_sector: 'Industry Sector',
    industry: 'Industry',
    key_figures: 'Key Figures',
    name: 'Name',
    special_situations: 'Special Situations',
    no_special_situations: 'No special situations selected, click edit to select any that apply',
    models: 'Models',
    ch_models: 'Models are required to continue',
    ch_warning: 'Please check these scalers before proceeding',
    advanced_options: 'Advanced Options',
    currency: 'Currency',
    baseline_type: 'Baseline Type',
    ch_baseline_type: 'Set all of your projected improvements to conservative, probable, or aggressive.  This will not effect any improvements you have manually overwritten.',
    term: 'Term',
    ch_term: 'The length in years of evaluating the value to a customer.',
    contact: 'Please Contact your Model Administrator',
    view_more: 'View More',
    view_less: 'View Less',
    size_your_value: 'Size Your Value',
    primary_scalers: 'Primary Scalers',
    choose_one: 'Choose One',
    cost_of_cap: 'Cost of Capital',
    ch_cost_of_cap: 'The internal interest rate assigned for a company; this helps to define the future value of money calculations like NPV.',
    customer_type: 'Customer Type',
    deployment_time: 'Deployment Time',
    CustomerType: 'Customer Type',
    ChooseOne: 'Choose One',
    BaselineType: 'Baseline Type',
    CostofCapital: 'Cost of Capital (%)',
    Term: 'Term',
    DeploymentTime: 'Deployment Type',
    ch_deployment_time: 'Number of months it will take the customer after contract signing to start achieving any value.',
    GrossProfitMargin: 'Gross Profit Margin (%)',
    ch_gross_profit_margin: 'The revenue achieved by a company minus the cost of goods sold.',
    opportunity_id: 'Opportunity ID',
    ch_opportunity_id: 'Your CRM opportunity ID which will help administrators report on attach rates of your Value Propositions.',
    currency_format: 'Number Format',
    currency_type_id: 'Currency Type',
    ch_currency: 'The currency you would like your Value Proposition displayed.',
    conversion_rate: 'Conversion Rate',
    ch_conversion_rate: 'The current conversion rate between your desired currency and the US Dollar.',
    roi_range: 'Roi Range',
    choose_category_type: 'Choose Category Type',
    account_info: 'Account Info',
    calculate: 'Calculate',
    are_you_sure: 'Are you sure?',
    no_models_for_this_category_please_try_a_different_one_by_selecting_from_the_dropdown_above: 'No Models for this category, please try a different one by selecting from the dropdown above.',
    back: 'Back',
    assetLanguage: 'Asset Language',
  };
}
