<app-choose-adventure-internal #chooseAdventure (onHidden)="onHidden($event)" [ff134]="ff134" [showStepSelection]="showStepSelection" [workflows]="workflows"></app-choose-adventure-internal>
<div class="col-md-6 " style='margin-bottom: 10px;'>
	<div class='models_panel dashboard-card' style='height: 400px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-10'>
						<div class='title'>{{ trans.trans.valuePositionsHeading.value}} <app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.valuePositionsHeading' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.valuePositionsHeading"></app-editTranslation>
						</div>
					</div>
					<div class='col-xs-2'>
						<button *ngIf="dbI.showAddButtons" (click)="onAddHypoClicked('valueposition')" class="btn btn_customizable pull-right value-execution-dashboard-add-a-new-value-proposition-button" data-heap="value-execution-dashboard-add-a-new-value-proposition-button">
							<i class='fa fa-plus'></i> {{ trans.trans.add.value }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div style='overflow-y: auto; max-height: 270px;'>
			<div [hidden]="!loading">
				<app-table-skeleton rowHeight="35px" [columns]="columns" [trans]="trans.trans" [rowLimit]="5"></app-table-skeleton>
			</div>
			<table *ngIf="!loading" class="table custom-table dashboard-table fixedTable">
				<thead>
					<tr>
						<th width="70%">{{ trans.trans.valuepos_name.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.valuepos_name' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.valuepos_name"></app-editTranslation>
						</th>
						<th width="30%" class="text-right">
							{{ trans.trans.valuepos_totalbenefits.value }}
							<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.valuepos_totalbenefits' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.valuepos_totalbenefits">
							</app-editTranslation>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr class="grid-row company-row" *ngFor="let reach of reachList; let index = index">
						<td>

							<a *ngIf="!reach.is_group" (click)="chooseStep('valueposition', reach.id)" class='link'>{{reach.name}}</a>
							<a *ngIf="reach.is_group" (click)="chooseStep('groupv1', reach.id)" class='link'>{{reach.name}}</a>
							<i *ngIf="!reach.is_group && !(style2022$ | async)" class="fa fa-info-circle tooltip-custom" style='margin-left: 10px;' [ngbTooltip]="tipContent" placement="bottom" container="body" (mouseover)="getValuePositionDetails(reach.id,index)"></i>
							<span *ngIf="!reach.is_group && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" style='margin-left: 10px;' [ngbTooltip]="tipContent" placement="bottom" container="body" (mouseover)="getValuePositionDetails(reach.id,index)">info</span>
							<i class="fa fa-plus" *ngIf="reach.value_props" style='margin-left: 10px;' (click)="reach.isCollapsed = !reach.isCollapsed" [attr.aria-expanded]="reach.isCollapsed"></i>

							<table [ngbCollapse]="!reach.isCollapsed">
								<tr *ngFor="let v of reach.value_props">
									<td width="80%">
										<a routerLink="/valueposition/{{v.id}}" class='link'>{{v.name}}</a>
										<i *ngIf="!(style2022$ | async)" class="fa fa-info-circle tooltip-custom" style='margin-right: 10px;' [ngbTooltip]="v.solutions" placement="bottom" container="body" (mouseover)="getSubValuePositionDetails(v)"></i>
										<span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" style='margin-right: 10px;' [ngbTooltip]="v.solutions" placement="bottom" container="body" (mouseover)="getSubValuePositionDetails(v)">info</span>
									</td>
									<td>{{v.total_benefits_fmt}}</td>
								</tr>
							</table>

							<ng-template #tipContent class="toolTipContent">
								<div>
									<p>
										<label>{{ trans.trans.valueprop_valuepropname.value }}</label>:&nbsp;
										<span>{{reach.name}}</span>
									</p>
									<p *ngIf="reach?.solutionItems?.length">
										<label>{{ trans.trans.valueprop_models.value }}</label>:&nbsp;
										<span >{{reach.solutionItems}}
										</span>
									</p>

									<p>
										<label>{{ trans.trans.valueprop_totalBenefits.value }}</label>:&nbsp;
										<span>{{reach.total_benefits_fmt}}</span>
									</p>
									<p *ngIf="reach.roi_decorated">
										<label>{{ trans.trans.valueprop_ROI.value }}</label>:&nbsp;{{reach.roi_decorated}}
										<span></span>
									</p>
									<p *ngIf="reach.payback">
										<label>{{ trans.trans.valueprop_paybackPeriod.value }}</label>:&nbsp;{{reach.payback}}
										<span>
											{{ trans.trans.months.value }}</span>
									</p>
									<p *ngIf="reach.vp_creator_name">
										<label>{{ trans.trans.valueprop_creatorName.value }}
										</label>:&nbsp;{{reach.vp_creator_name}}
									</p>

								</div>
							</ng-template>

						</td>
						<td class="text-right">{{reach.total_benefits_fmt}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="reachList.length==0 && !loading" class="m-t-md col-sm-12">
			<div class="text-center">
				<h4>{{ trans.trans.valuepos_noValuePositions.value }}
					<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.valuepos_noValuePositions' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.valuepos_noValuePositions">
					</app-editTranslation>
				</h4>
			</div>
		</div>

		<a routerLink="/valueposition" class="keywest_seeMore">{{ trans.trans.valuepos_seemore.value }}</a>
		<app-editTranslation *ngIf="showTranslate" (callback)='getTranslations();' key='main_dashboard.valuepos_seemore' [isTranslationV2]="true" [component]="trans.config.component" [transObj]="trans.trans.valuepos_seemore"></app-editTranslation>
	</div>
</div>
