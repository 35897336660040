import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValuePropFlowButtonsTranslations } from './value-prop-flow-buttons.translation';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';

@Component({
  selector: 'app-value-prop-flow-buttons',
  templateUrl: `./value-prop-flow-buttons.component.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValuePropFlowButtonsComponent implements OnInit, AfterViewInit {
  @Input() next = true;
  @Input() back = true;
  @Input() cancel = false;
  @Input() disabled = false;
  @Input() moveToBottom = true;
  @Input() jumpToResults = false;
  @Output() goBack = new EventEmitter();
  @Output() goNext = new EventEmitter();
  @Output() jumpResults = new EventEmitter();

  ngUnsubscribe = new Subject();
  jumpToResultsFeature = false;
  workflow: ConversationWorkflow;
  workflowFallback: number;

  constructor(
    private commonService: CommonService,
    public trans: ValuePropFlowButtonsTranslations,
    private translationService: TranslationsV2Service,
    private cdr: ChangeDetectorRef
  ) {
    this.jumpToResultsFeature = this.commonService.checkFeature(109);
  }

  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
  
    this.setSelectedWorkflow();
  }

  setSelectedWorkflow() {
    this.workflow = JSON.parse(sessionStorage.getItem('selectedConversationWorkflow'));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.workflowFallback = +JSON.parse(sessionStorage.getItem('workflowFallback')); // Use to handle situations where no workflow is found, checking number of steps.
      this.cdr.markForCheck();
    }, 200);
  }

  get workflowContinueCheck(): boolean {
    return this.workflow ? this.workflow.conversations.length > 1 : true;
  }

  get workflowReviewResultsCheck(): boolean {
    return this.workflow ? this.workflow.conversations.length > 4 : true;
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.cdr.detectChanges();
      });
  }
}
