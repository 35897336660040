import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ErrorMessagesService } from '@services/error-messages.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'arrayToFormControl',
})
export class ArrayToFormControlPipe implements PipeTransform {
  transform(formArray: FormArray, index: number, controlName: string): FormControl {
    if (!formArray || !controlName) {
      return null;
    }
    const formGroup = formArray.at(index) as FormGroup;
    if (!formGroup) {
      return null;
    }
    return formGroup.controls[controlName] as FormControl;
  }
}

@Pipe({
  name: 'returnErrorObservable',
})
export class ReturnErrorObservable implements PipeTransform {
  constructor(private errorMessagesService: ErrorMessagesService) {}
  transform(formArray: FormArray, index: number): Observable<Map<string, string>> {
    const formGroup = formArray.at(index) as FormGroup;
    const foundControl = formGroup.controls['name'] as FormControl;
    return this.errorMessagesService.formContrlObsFactory(foundControl, 'name');
  }
}
