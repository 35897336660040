import { Injectable } from '@angular/core';

@Injectable()
export class CaseStudySidebarTranslations {
  public config: any = {
    component: 'Case Study Edit Sidebar',
    display_name: 'Case Study Edit Sidebar',
    description: 'Flyout to edit name of case study and update tags',
  };

  public trans: any = {
    edit_case_study: 'Edit Case Study',
    name: 'Name',
    tags: 'Tags',
    save: 'Save',
    are_you_sure: 'Are you sure?',
    back: 'Back',
  };
}
