import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { RepSimpleEventBusService } from '@data/services/rep_simple_view/bus.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AccountWorkflowService } from '@data/services/workflow/account-workflow.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { EditNameModule } from '@shared_components/edit-name/edit-name.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLProgressbarModule } from '@shared_components/primeng_progress/progress.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { VpTooltipPipe } from 'app/pipe/vp-tooltip.pipe';
import { SearchCompanyV3Module } from 'app/value-position/search-companyv3/search-companyv3.module';
import { CompetitiveTCOModule } from 'app/value-prop/value-prop-dashboard/competitive-tco/competitive-tco.module';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { BenefitsV2Module } from '../value-prop/value-prop-dashboard/benefits_v2/benefits.module';
import { BenefitDetailsModule } from '../value-prop/value-prop-dashboard/benefits_v2/views/detail/benefit-detail.module';
import { ValuePropCloneModule } from '../value-prop/value-prop-dashboard/clone/clone.module';
import { AssumptionsModule } from '../value-prop/value-prop-dashboard/discovery/assumptions/assumptions.module';
import { AssumptionsFastFillModule } from '../value-prop/value-prop-dashboard/discovery/fast_fill/assumptions.module';
import { NotesModule } from '../value-prop/value-prop-dashboard/notes/notes.module';
import { DashboardBottomModule } from '../value-prop/value-prop-dashboard/panel_bottom/bottom.module';
import { DashboardTopModule } from '../value-prop/value-prop-dashboard/panel_top/top.module';
import { PromoteRealizationModule } from '../value-prop/value-prop-dashboard/promote-realization/promote-realization.module';
import { AccountModule } from '../value-prop/value-prop-dashboard/scenario/account/account.module';
import { ModelsModule } from '../value-prop/value-prop-dashboard/scenario/models/models.module';
import { SearchCompanyCloneModule } from '../value-prop/value-prop-dashboard/search-company-clone/search-companyv3.module';
import { ValuepropNameChangerModule } from './../shared_components/valueprop-name-changer/valueprop-name-changer/valueprop-name-changer.module';
import { RSV2Component } from './simple.component';
import { SimpleImplementation } from './simple.implementation';
import { RepSimpleTranslations } from './simple.translation';
import { RSV2CostsSimpleModule } from './views/costs_simple/costs.simple.module';
import { RSV2CreateModule } from './views/create/create.module';
import { InfoDetailComponent } from './views/info-detail/info-detail.component';
import { InfoDetailTranslation } from './views/info-detail/info-detail.translation';
import { InfoActionsHeaderComponent } from './views/info-actions-header/info-actions-header.component';
import { InfoActionsHeaderTranslation } from './views/info-actions-header/info-actions-header.translation';
import { RSV2NavModule } from './views/nav/nav.module';
import { CaseTypeResolver } from './views/resolvers/case-type.resolver';
import { RSV2DashboardResultsModule } from './views/results/results.module';
import { RSV2ScenarioCombinedModule } from './views/scenario_combined/combined.module';
import { SCVPGModule } from './views/scenario_combined_vpg/sc_vpg.module';
import { RSV2ToolbarModule } from './views/toolbar/toolbar.module';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

const routes: Routes = [
  {
    path: 'repv2/:vpid',
    component: RSV2Component,
    data: { view: 'simple', page: 'dashboard', currentNav: 'value-case' },
    resolve: { caseType: CaseTypeResolver }
  },
  {
    path: 'repv2/:vpid/:step',
    component: RSV2Component,
    data: { view: 'simple', page: 'dashboard', currentNav: 'value-case' },
    resolve: { caseType: CaseTypeResolver }
  },
  {
    path: 'repv2',
    loadChildren: () => import('./views/create/create.module').then((m) => m.RSV2CreateModule),
    data: { view: 'simple', page: 'dashboard' },
  },
];
@NgModule({
  imports: [
    ConfirmDialogModule,
    EditNameModule,
    CompetitiveTCOModule,
    RSV2ToolbarModule,
    DLProgressbarModule,
    RSV2CreateModule,
    RSV2NavModule,
    SCVPGModule,
    AssumptionsFastFillModule,
    ValuePropCloneModule,
    InputSwitchModule,
    SearchCompanyCloneModule,
    NotesModule,
    GuidedTourModule,
    MatTooltipModule,
    InputTextModule,
    NgbModule,
    CommonModule,
    FormsModule,
    EditTranslationsModule,
    ApplicationPipesModule,
    AccountModule,
    ModelsModule,
    BenefitDetailsModule,
    BenefitsV2Module,
    AssumptionsModule,
    RSV2CostsSimpleModule,
    MenubarModule,
    SidebarModule,
    MenuModule,
    SearchCompanyV3Module,
    ProgressBarModule,
    RSV2DashboardResultsModule,
    DashboardBottomModule,
    DashboardTopModule,
    RSV2ScenarioCombinedModule,
    PromoteRealizationModule,
    EditTranslationsModule,
    ValuepropNameChangerModule,
    RouterModule.forChild(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    DropdownModule,
  ],
  declarations: [RSV2Component, RSV2Component, InfoDetailComponent, InfoActionsHeaderComponent],
  providers: [
    GuidedTourService,
    RepSimpleViewService,
    RepSimpleEventBusService,
    TranslationsV2Service,
    RepSimpleTranslations,
    AccountWorkflowService,
    InfoDetailTranslation,
    InfoActionsHeaderTranslation,
    ConfirmationService,
    SimpleImplementation,
    CaseTypeResolver,
    VpTooltipPipe
  ],
  exports: [RSV2Component],
  entryComponents: [RSV2Component],
})
export class RSV2Module {}
