import { Injectable } from '@angular/core';

@Injectable ()

export class ExplainedVpDashTranslations {

    public config: any = {
        component: 'expalinedVpDash',
        display_name: 'Value Prop Explained',
        description: 'Explained tab in value prop dashboard bottom panel'
    };

    public trans: any = {
        name: 'Name',
        value: 'Value',
        factor_type: 'Factor Type'
    };
}
