import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DashboardModelsComponent } from './models.component';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { ModelsVpDashboardTranslations } from './models.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
	imports: [
		ProgressBarModule,
    MatTooltipModule,
		NgbModule,
		CommonModule,
		FormsModule,
		EditTranslationsModule,
		ApplicationPipesModule,
		AreYouSureModule
	],
	declarations: [
		DashboardModelsComponent
	],
	providers: [
		TranslationsV2Service,
		ModelsVpDashboardTranslations
	],
	exports: [DashboardModelsComponent]
})
export class ModelsModule { }
