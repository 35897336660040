<p-treeTable #treeTable [value]="situations" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th>{{trans.trans.situations.value}}</th>
            <th>{{trans.trans.model.value}}</th>
            <th>{{trans.trans.overrideValue.value}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr>
            <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                {{rowData.name}}
            </td>
            <td>{{rowData.solution_name}}</td>
            <td>{{rowData.factor_override}}</td>
        </tr>
    </ng-template>
</p-treeTable>
<app-no-records class="no-records" *ngIf="!situations || !situations.length">{{trans.trans.noRecords.value}}</app-no-records>