import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ShareFeatureModule } from '../share-feature/share-feature.module';
import { ValuePropRoutingModule } from './value-prop-routing.module';
import { ValuepropComponent } from './valueprop/valueprop.component';
import { FeedComponent } from './feed/feed.component';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ValuePropDashboardComponent } from './value-prop-dashboard/dashboard_system/value-prop-dashboard.component';
import { CashflowModule } from './value-prop-dashboard/cashflow/cashflow.module';
import { AddValuePropComponent } from './add-value-prop/add-value-prop.component';
import { ValuePositionModule } from '../value-position/value-position.module';
import { ContactsComponent } from './value-prop-dashboard/scenario/contacts/contacts.component';
import { KeyFiguresModule } from './value-prop-dashboard/key_figures/key_figures.module';
import { ValueMapModule } from '../value-map/value-map.module';
import { MasterValuePropComponent } from './master-value-prop/master-value-prop.component';
import { MasterValuePropService } from '@data/services/master-value-prop/master-value-prop.service';
import { CompareComponent } from './master-value-prop/compare/compare.component';
import { MasterShareComponent } from './master-value-prop/master-share/master-share.component';
import { MasterValuePropListComponent } from './master-value-prop/master-value-prop-list/master-value-prop-list.component';
import { ScratchpadModalComponent } from './common/scratchpad-modal/scratchpad-modal.component';
import { AddCostModalModule } from './common/add-cost-modal/add-cost-modal.module';
import { FeatureOpportunityIdModule } from '@features/opportunity_id/opportunity_id.module';
import { EditorModule } from 'primeng/editor';
import { TabViewModule } from 'primeng/tabview';
import { ValuePropDashboardActionsComponent } from './value-prop-dashboard/actions/actions.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { MenubarModule } from 'primeng/menubar';
import { ValuePropDashboardAssetsPanelComponent } from './value-prop-dashboard/assets_panel/assetspanel.component';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { FieldsetModule } from 'primeng/fieldset';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { BenefitsModule } from './value-prop-dashboard/benefits/benefits.module';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AddValuePositionModule } from 'app/value-position/add-value-positionv2/add-value-position.module';
import { CompanyService } from '@data/services/company/company.service';
import { TableChartModule } from '@shared_components/tablechart/tablechart.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Translations } from '@data/services/translation/translation.service';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CustomerShareServices } from '@data/services/customer_share/share.service';
import { ExplainedModule } from './value-prop-dashboard/explained/explained.module';
import { ValuePropDashboardROIModule } from './value-prop-dashboard/roi/roi.module';
import { DashboardTopModule } from './value-prop-dashboard/panel_top/top.module';
import { DashboardBottomModule } from './value-prop-dashboard/panel_bottom/bottom.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { AssetsPanelTranslations } from './value-prop-dashboard/assets_panel/assetspanel.translation';
import { ActionsVpDashboardTranslations } from './value-prop-dashboard/actions/actions.translation';
import { ValuePrpDashboardTranslations } from './value-prop-dashboard/dashboard_system/value-prop-dashboard.translation';
import { ValuePropTranslations } from './valueprop/valueprop.translation';
import { ValuePropCloneModule } from './value-prop-dashboard/clone/clone.module';
import { MVPListTranslations } from './master-value-prop/master-value-prop-list/master-value-prop-list.translation';
import { PromoteRealizationModule } from './value-prop-dashboard/promote-realization/promote-realization.module';
import { ShareModule } from './value-prop-dashboard/sharev2/share.module';
import { MasterValuePropTranslations } from './master-value-prop/master-value-prop.translation';
import { ErrorInputWrapperModule } from '@shared_components/error-input-wrapper/error-input-wrapper.module';
import { EllipsisMenuModule } from '@shared_components/ellipsis-menu/ellipsis-menu.module';
import { BreadcrumbsModule } from '@shared_components/breadcrumbs/breadcrumbs.module';
import { UnitTypeModule } from '@shared/unit-type/unit-type.module';
import { NoRecordsModule } from '@shared_components/no-records/no-records.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChooseAdventureInternalModule } from '@shared/choose-adventure-internal/choose-adventure-internal.module';
import { FunctionPipeModule } from '@shared/pipes/function-pipe.module';
import { ListService } from '@data/services/list/list.service';
import { CheckboxModule } from 'primeng/checkbox';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    ShareModule,
    ValuePropCloneModule,
    SelectButtonModule,
    AreYouSureModule,
    MenuModule,
    MatTooltipModule,
    CashflowModule,
    DashboardTopModule,
    ExplainedModule,
    AutoCompleteModule,
    CalendarModule,
    OverlayPanelModule,
    EditTranslationsModule,
    TableChartModule,
    AddValuePositionModule,
    ToggleButtonModule,
    PanelModule,
    ApplicationPipesModule,
    GuidedTourModule,
    DropdownModule,
    MenuModule,
    SharedModule,
    ShareFeatureModule,
    ValuePropRoutingModule,
    ValuePositionModule,
    ValueMapModule,
    FeatureOpportunityIdModule,
    EditorModule,
    TabViewModule,
    ProgressBarModule,
    MenubarModule,
    TableModule,
    SidebarModule,
    MultiSelectModule,
    FieldsetModule,
    AddCostModalModule,
    BenefitsModule,
    ValuePropDashboardROIModule,
    KeyFiguresModule,
    DashboardBottomModule,
    PromoteRealizationModule,
    ErrorInputWrapperModule,
    TabViewModule,
    EllipsisMenuModule,
    BreadcrumbsModule,
    UnitTypeModule,
    NoRecordsModule,
    ChooseAdventureInternalModule,
    CheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    FunctionPipeModule,
  ],
  declarations: [
    ValuePropDashboardAssetsPanelComponent,
    ContactsComponent,
    ValuepropComponent,
    FeedComponent,
    ValuePropDashboardComponent,
    AddValuePropComponent,
    MasterValuePropComponent,
    CompareComponent,
    MasterShareComponent,
    MasterValuePropListComponent,
    ScratchpadModalComponent,
    ValuePropDashboardActionsComponent,
  ],
  providers: [
    ValuePrpDashboardTranslations,
    GuidedTourService,
    ValuepropService,
    MasterValuePropService,
    CompanyService,
    Translations,
    CustomerShareServices,
    TranslationsV2Service,
    AssetsPanelTranslations,
    ActionsVpDashboardTranslations,
    ValuePropTranslations,
    MVPListTranslations,
    MasterValuePropTranslations,
    ListService,
  ],
  entryComponents: [ScratchpadModalComponent],
})
export class ValuePropModule {}
