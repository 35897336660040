import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from '@data/services/common/common.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { RThresholdTranslations } from './r_thresholds.translation';

@Component({
	selector: 'app-r-thresholds',
	templateUrl: './r_thresholds.component.html',
})
export class RThresholdsComponent implements OnInit, OnDestroy {
	@Input() solutionID: number;
	@Input() tabInit: number;


	ngUnsubscribe = new Subject();

	solutionThreshold: any[] = [];
	accountSolutionId: number;
	accountId: number;
	loadthresholdTable: boolean;
	thresholdTypes: any = [];
	// Contextual Help
	contextualHelp: object = {};
	fullImagePath: string = "";
	strSearch: string = "";
	showTranslate = false;

	@Input() can_edit: boolean = false; // can the record be edited or not

	@ViewChild('formAddThreshold') formAddThreshold: NgForm;

	@Input() thresholdFormObj = {
		threshold_type_id: undefined,
		name: '',
		min: undefined,
		max: undefined,
		margin: undefined,
		id: 0
	};

	// new
	addEditThreshold: any;
	loadingSave: boolean = false;
	toggleAddThreshold: boolean = false;
	dropdown: any[] = [];
	@Input() sidebar_title: string = "Add New Threshold";

	selectedThreshold: any;

	constructor(
		private commonService: CommonService,
		private translationService: TranslationsV2Service,
		public trans: RThresholdTranslations
	) {
		this.fullImagePath = commonService.getLoaderImageUrl();

	}

	ngOnInit() {

		this.getTranslations();
		this.sidebar_title = this.trans.trans.viewThresholds.value;

		this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
			this.getTranslations();
		});

		this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
			this.showTranslate = res;
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(false);
		this.ngUnsubscribe.complete();
	}


	getTranslations() {

		let langId = sessionStorage.getItem('language_type_id');
		let langAbbr = this.translationService.getLanguageAbbr(langId);

		let payload = {
			'account_id': sessionStorage.getItem("aid"),
			'component': this.trans.config.component,
			'lang': langAbbr,
			localTranslations: this.trans.trans
		}
		this.translationService.getComponentTrans(payload).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
			 this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
		});
	}

}


