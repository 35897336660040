import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterAssumptionsDrivers',
	pure: false
})
export class filterAssumptionsPipe implements PipeTransform {
	transform(drivers: any[], filter: string): any {
		if (!drivers || !filter) {
			return drivers;
		}
		return drivers.filter(item => item.driver_factor.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
	}
}

@Pipe({
	name: 'filterAssumptionsAll',
	pure: false
})
export class filterAssumptionsAllPipe implements PipeTransform {
	transform(drivers: any[], filter: string): any {
		if (!drivers || !filter) {
			return drivers;
		}
		let out = [];
		out = drivers.filter(function (item) {
			return (item.driver_factor ? item.driver_factor.toLowerCase().indexOf(filter.toLowerCase()) !== -1 : []) &&
				(item.financial_factor ? item.financial_factor.toLowerCase().indexOf(filter.toLowerCase()) !== -1 : []) &&
				(item.name ? item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 : [])
		})
		return out;
	}
}
@Pipe({
	name: 'filterAssumptionsFinancial',
	pure: false
})
export class filterAssumptionsFinancialPipe implements PipeTransform {
	transform(drivers: any[], filter: string): any {
		if (!drivers || !filter) {
			return drivers;
		}
		return drivers.filter(item => item.financial_factor.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
	}
}

@Pipe({
	name: 'filterAssumptionsScratchpads',
	pure: false
})
export class filterAssumptionsScratchpadsPipe implements PipeTransform {
	transform(drivers: any[], filter: string): any {
		if (!drivers || !filter) {
			return drivers;
		}
		return drivers.filter(item => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
	}
}