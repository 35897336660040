import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Cashflow, CashflowsStats, ValueProp } from '@shared/models/value-prop.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CashflowRepSimpleTranslations } from './cashflow_simple.translation';

@Component({
  selector: 'app-cashflow-simple',
  templateUrl: './cashflow_simple.component.html',
  styleUrls: ['./cashflow_simple.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CashflowSimpleComponent implements OnInit, OnDestroy {
  @Input() showHelp: boolean;
  @Input() valueProp: ValueProp;
  @Input() tabInit: number;
  @Input() showTranslate = false;

  fullImagePath: string;
  image_url: string;
  cashflowLoader = false;
  cashflowObj: Cashflow;

  cashflow: CashflowsStats[] = [];

  years = 3;
  subscriptiongetCashflow: Subscription;
  subscriptionrefreshDashboard: Subscription;

  cols: { col: string }[] = [];

  ngUnsubscribe = new Subject();

  constructor(
    private commonService: CommonService,
    private valuepropService: ValuepropService,
    private translationService: TranslationsV2Service,
    public trans: CashflowRepSimpleTranslations
  ) {
    this.image_url = this.commonService.getImageUrl();
    this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';
  }
  ngOnInit() {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.valuepropService.refreshDashboard.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadCashflow();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.loadCashflow();
      });
  }

  refreshTranslation() {
    this.getTranslations();
  }

  loadCashflow() {
    const searchLimit = this.valueProp.id;
    this.cashflowLoader = true;
    this.valuepropService
      .getCashflow(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.cashflowLoader = false;
        if (response.result) {
          this.cols = [];
          for (let idx = 1; idx < 11; idx++) {
            if (response.result.benefits[`Year${idx}`]) {
              this.years = idx;
              const yearTrans = this.trans.trans['year' + idx];

              const yearLabel = yearTrans ? yearTrans.value : `${this.trans.trans.year1.value.split(' ')[0]} ${idx}`;
              this.cols.push({ col: yearLabel });
            } else {
              break;
            }
          }
          this.cashflowObj = response.result;
          this.cashflow = Object.keys(this.cashflowObj).map((key) => {
            const cashflowItem = this.cashflowObj[key];
            cashflowItem.key = key === 'disc_cash_flow' ? key.replace(/disc/, 'dis') : key;
            return cashflowItem;
          });
        }
      });
  }

  getCVall(i, row) {
    const ii = i + 1;
    return row['Year' + ii + '_p2'];
  }

  colorRed(str) {
    if (str) {
      return str.substring(0, 1) === '-' ? 'red' : 'inherit';
    }
    return 'inherit';
  }

  removeHyphen(str) {
    if (str) {
      return str.substring(0, 1) === '-' ? '(' + str.substr(1) + ')' : str;
    }
    return str;
  }
}
