import { NgModule } from '@angular/core';

import { CostComponent } from "./cost.component";
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { RCostsModule } from './r_cost/r_cost.module';
import { AECostsModule } from './ae_cost/ae_cost.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { CostTranslations } from './cost.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [
		CostComponent,
	],
	imports: [
		ProgressBarModule,
		RCostsModule,
		AECostsModule,
		AreYouSureModule,
		TableModule,
		SidebarModule,
		MenuModule,
		InputMaskModule,
		FormsModule,
		CommonModule,
    MatTooltipModule,
		DLNGErrorDirectiveModule,
		NGFormSaveButtonModule,
		EditTranslationsModule,
	],
	providers: [
		TranslationsV2Service,
		CostTranslations,
	],
	entryComponents: [
		CostComponent,
	],
	exports: [CostComponent]
})
export class ModelCostsModule { }