import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class SolutionAdoptionTranslation extends TranslationsBaseService {

  public config: any = {
    component: 'solution-adoption',
    display_name: 'Solutions and Value Props',
    description: 'Solutions and Value Props',
  };

  public trans: any = {
    settings: 'Settings',
    dashboard: 'Dashboard',
    solution_adoption: 'Solutions and Value Props',
    back_to_profile: 'Back to Profile',
    modelDocumentation: 'Value Model Documentation',
    valueProps: 'Value Propositions'
  };
}
