import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconRegistryService } from './services/svg-icon-registry.service';

@Component({
  selector: 'app-svg-icon',
  template: `<img [src]="svgUrl" [ngStyle]="styles" />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  svgUrl = '';
  defaultStyles: { [cssSelector: string]: any } = { 'height': '0.9em', 'width': '0.9em', 'display': 'inline-block', 'margin-bottom': '2px' };
  @Input() styles: { [cssSelector: string]: any } = null;

  @Input() set name(iconName) {
    this.svgUrl = this.iconRegistryService.getIcon(iconName);
  }

  constructor(private iconRegistryService: SvgIconRegistryService) {
    this.styles = this.styles ?? this.defaultStyles;
  }
}
