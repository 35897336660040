import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FactorsService } from '@data/services/factors/factors.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader } from '@ngx-translate/core';
import { AreYouSureModule } from '@shared_components/areyousure/areyousure.module';
import { EditTranslationsModule } from '@shared_components/editTranslation/edit.directive.module';
import { DLNGErrorDirectiveModule } from '@shared_components/ngError/error.module';
import { NGFormSaveButtonModule } from '@shared_components/ngForm_save_button/save.module';
import { DLFieldsetModule } from '@shared_components/primeng_fieldset/fieldset.module';
import { ApplicationPipesModule } from 'app/pipe/pipe.module';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AEFactorsFormModule } from './ae_factors/ae_factors.module';
import { AEScratchpadsModule } from './ae_scratchpads/ae_scratchpads.module';
import { FactorScaledComponent } from './factor-scaled/factor-scaled.component';
import { FactorScaledByTranslations } from './factor-scaled/factor-scaled.translation';
import { ValueMapFactorsComponent } from './factors.component';
import { DefaultTranslations } from './factors.translation';
import { RFactorsFormModule } from './r_factors/r_factors.module';
import { RScratchpadsModule } from './r_scratchpads/r_scratchpads.module';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../../../data/services/translation/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  imports: [
    ProgressBarModule,
    AEScratchpadsModule,
    RScratchpadsModule,
    AreYouSureModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    DLFieldsetModule,
    SidebarModule,
    DLNGErrorDirectiveModule,
    NGFormSaveButtonModule,
    TableModule,
    MatTooltipModule,
    MenuModule,
    NgbModule,
    CommonModule,
    FormsModule,
    EditTranslationsModule,
    ApplicationPipesModule,
    AEFactorsFormModule,
    RFactorsFormModule,
    DropdownModule,
    ReactiveFormsModule,
  ],
  declarations: [ValueMapFactorsComponent, FactorScaledComponent],
  exports: [ValueMapFactorsComponent],
  providers: [FactorsService, DefaultTranslations, TranslationsV2Service, FactorScaledByTranslations],
})
export class ValueMapFactorsModule {}
