import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DataTablesModule } from 'angular-datatables';
import { DownloadsComponent } from '.././main-dashboard/downloads/downloads.component';
import { CategoryPipe } from '../customfilter.pipe';
import { ApplicationPipesModule } from '../pipe/pipe.module';
import { NumberOnlyDirective } from '../_directives/number.directive';

import { AddCompanyModule } from '../scb/add-company/add-company.module';
import { BenefitsModule } from '../value-prop/value-prop-dashboard/benefits/benefits.module';
import { CostsModule } from '../value-prop/value-prop-dashboard/costs/costs.module';
import { AssumptionsModule } from '../value-prop/value-prop-dashboard/discovery/assumptions/assumptions.module';
import { AlertComponent } from '../_directives/alert.component';
import { NotificationComponent } from '../_directives/notification/notification.component';
import { AlertService } from '@services/alert.service';
import { NotificationService } from '@services/notification.service';
import { SpinnerComponent } from '../_directives/spinner/spinner.component';
import { SpinnerService } from '@services/spinner.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { AddCostModalModule } from 'app/value-prop/common/add-cost-modal/add-cost-modal.module';

import { DropdownModule } from 'primeng/dropdown';
import { SpaceToUnderscoreDirective } from 'app/_directives/space-to-underscore.directive';
import { TableSkeletonComponent } from './table-skeleton/table-skeleton.component';
import { SkeletonModule } from 'primeng/skeleton';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    AssumptionsModule,
    BenefitsModule,
    ApplicationPipesModule,
    CostsModule,
    MenubarModule,
    DropdownModule,
    AddCostModalModule,
    TableModule,
    SidebarModule,
    MultiSelectModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    AlertService,
    NgbModule,
    DataTablesModule,
    AddCompanyModule,
    SkeletonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SpinnerService,
  ],
  declarations: [NumberOnlyDirective, AlertComponent, NotificationComponent, CategoryPipe, DownloadsComponent, SpinnerComponent, TableSkeletonComponent],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    AlertComponent,
    NotificationComponent,
    AlertService,
    NgbModule,
    CategoryPipe,
    TranslateModule,
    DownloadsComponent,
    SpinnerComponent,
    SpinnerService,
    NumberOnlyDirective,
    TableSkeletonComponent,
  ],
})
export class SharedModule {}
