<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">
            {{ trans.research.searchCompany | translate }}
          </div>
        </div>
        <div class="col-xs-2">
          <button class="btn btn_customizable pull-right" routerLink="/company/add"><i class="fa fa-plus"></i>{{ trans.general.addNew | translate }}</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>{{ trans.research.recentlyViewed | translate }}</a>
            <ng-template ngbNavContent>
              <div class="companySearchSCB">
                <app-company-search [page]="page"></app-company-search>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>"{{ trans.research.recentlyDownloaded | translate }}</a>
            <ng-template ngbNavContent>
              <ng-template ngbTabContent>
                <app-downloads></app-downloads>
              </ng-template>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
