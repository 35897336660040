import { Injectable } from '@angular/core';

@Injectable ()

export class UserAdoptionsReportTranslations {

    public config: any = {
        component: 'userAdoptionReport',
        display_name: 'User Adoption Report',
        description: 'User adoption reports in profile'
    };

    public trans: any = {
        choose: 'Choose',
        chart: 'Chart',
        excluded_user_list: 'Excluded User List',
        filter: 'Filter',
        get_all_data: 'Get All Data',
        logins_by_month: 'Logins By Month',
        no_logins: 'No Logins',
        no_value_props: 'No Value Props',
        save: 'Save',
        take_a_tour: 'Take a tour',
        top_account_value: 'Top Account Value',
        top_creators: 'Top Creators',
        top_customer_value: 'Top Customer Value',
        top_delivered: 'Top Delivered',
        vps_created_by_month: 'VP\'s Created By Month',
        vps_modified_by_month: 'VP\'s Modified By Month',
        backToProfile: 'Back to Profile',
        download_report: 'Download report',
        dateStart: 'Start Date',
        dateEnd: 'End Date',
        adoptionReort: 'Adoption Report',
        searchForUser: 'Search for users by name',
        tourTitleWelcome: 'Welcome',
        tourContentWelcome: 'Let\'s take a quick walk through of the new changes in Adoption Reports',
        tourTitleShowHide: 'Show / Hide Panels',
        tourContentShowHide: 'You can show and hide which datasets are most relevant to you here',
        tourTitleFilter: 'Filter',
        tourContentCalendar: 'Use these calendar dropdowns to select a date range and filter your results',
        tourTitleExpandCollapse: 'Expand / Collapse',
        tourContentExpandCollapse: `These panels can contain a lot of data.
        Use these expand / collapse icons to make a panel full width to see more data`,
        tourTitleChangeChart: 'Change Chart Type',
        tourContentChangeChart: 'Like bar graphs? Line graphs? Or prefer a Pie chart? Select how your data is displayed here',
        tourTitleData: 'Data Toggle',
        tourContentDataToggle: 'Use this button to toggle between Chart View and Table View',
        tourTitleExport: 'Export Table',
        tourContentExport: 'Export all of the table data to a CSV / Excel sheet',
        tourTitleExportSelection: 'Export Selection',
        tourContentSelectRows: 'Select a few rows in the table and export that data to a CSV / Excel sheet',
        tourTitleFinished: 'Finished',
        tourContentFinished: 'Well that is about it, thank you for taking the tour!',
        email: 'Email',
        first_name: 'First Name',
        last_name: 'Last Name',
        username: 'Username',
        total_benefits: 'Total Benefits',
        count: 'Count',
        total_costs: 'Total Costs',
        date: 'Date',
        name: 'Name',
        total: 'Total',
        systemColors: 'System Colors',
        lightColors: 'Light Colors',
        mediumColors: 'Medium Colors',
        darkColors: 'Dark Colors',
        settings: 'Settings',
        dashboard: 'Dashboard',
    };
}
