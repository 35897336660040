import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { FactsService } from '@data/services/facts/facts.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Company } from '@shared/models/company.model';
import { Industry, State } from '@shared/models/facts.models';
import { DefaultTranslations } from 'app/repv2/views/scenario_combined/combined.translation';
import { StyleService } from 'app/style.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent implements OnInit, OnDestroy {
  companyAccount: Company;
  industryId: { id: string; name: string };
  industrySector: { id: string; name: string };
  stateId: { id?: string; name: string };
  countries: BasicInfo[];
  countryDetails: { id: string; name: string };
  industries: Industry[];
  industriesLoader: boolean;
  industriesShow: boolean;
  industrySectors: BasicInfo[];
  loading = false;
  ngUnsubscribe = new Subject();
  showAccountEdit = false;
  states: State[];
  statesLoader: boolean;
  supplementals: { [klass: string]: any };
  showTranslate = false;
  crm: string;
  style2022$: Observable<boolean>;

  @Input() set company(comp) {
    this.setValuePropCompany(comp);
  }

  @Output() onSubmit = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
    public trans: DefaultTranslations,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    private factsService: FactsService,
    private styleService: StyleService
  ) {}

  ngOnInit(): void {
    this.crm = sessionStorage.getItem('crm');
    this.style2022$ = this.styleService.style2022;
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
    this.getCountries();
    this.getIndustrySectors();
  }

  setValuePropCompany(company): void {
    this.loading = true;
    if (!!company) {
      this.countryDetails = {
        id: company.country_id,
        name: company.country,
      };
      this.industrySector = {
        id: company.dl_sector_id,
        name: company.dl_sector_name,
      };
      this.industryId = {
        id: company.industry_id,
        name: company.industry_name,
      };

      this.stateId = { name: company.state };
      this.companyAccount = { ...company };
      this.supplementals = company.supplementals;

      this.getStatesByCountries();
      this.getIndustryBySectors();

      this.loading = false;
    }
  }

  getStatesByCountries(): void {
    const country_id = this.countryDetails.id;
    this.statesLoader = true;
    this.factsService.getStatesByCountries(country_id).subscribe((response) => {
      this.statesLoader = false;
      this.states = response.result.states;
    });
  }

  getIndustryBySectors(): void {
    const sectorId = this.industrySector.id;

    this.industriesLoader = this.industriesShow = true;
    this.factsService.getIndustriesBySector(sectorId).subscribe((response) => {
      this.industriesLoader = this.industriesShow = false;
      this.industries = response.result.dl_industries;
      this.industryId = this.industries ? this.industries.filter((x) => x.id === this.companyAccount.dl_industry_id)[0] : null;
    });
  }

  getCountries(): void {
    this.factsService.getCountries().subscribe((response) => {
      this.countries = response.result.countries;
    });
  }

  getIndustrySectors(): void {
    this.factsService.getSectors().subscribe((response) => {
      this.industrySectors = response.result;
    });
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  close(): void {
    this.loading = false;
    this.onCancel.emit();
  }

  submit(): boolean {
    this.loading = true;
    if (!this.companyAccount.name) {
      return false;
    }

    if (!this.countryDetails.id) {
      return false;
    }
    this.companyAccount.country_id = this.countryDetails.id;
    this.companyAccount.country = this.countryDetails.name;

    if (!this.industrySector.id) {
      return false;
    }
    this.companyAccount.dl_sector_id = this.industrySector.id;
    this.companyAccount.dl_sector_name = this.industrySector.name;
    if (!this.industryId.id) {
      return false;
    }
    this.companyAccount.industry_id = this.industryId.id;
    this.companyAccount.industry_name = this.industryId.name;
    this.companyAccount.dl_industry_id = this.industryId.id;

    if (!this.companyAccount.revenue_fmt) {
      return false;
    }
    if (!this.companyAccount.employees) {
      return false;
    }

    this.companyAccount.state_id = this.stateId.id;
    this.companyAccount.state = this.stateId.name;
    this.companyAccount.revenue = this.companyAccount.revenue_fmt;

    this.onSubmit.emit(this.companyAccount);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }
}
