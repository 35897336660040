<div *ngIf="loading">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>
<div *ngIf="!loading" class="phasing-container">
  <div class="row">
    <div class="col-xs-1"></div>
    <div class="col-xs-10">
      <h2>
        {{ trans.trans.impact_over_time.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.impact_over_time"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </h2>
    </div>
    <div class="col-xs-1"></div>
  </div>

  <form name="AddPhasingForm" #AddPhasingForm="ngForm">
    <div [formGroup]="phasingTermGroup">
      <div formArrayName="phasingTermArray">
        <app-error-input-wrapper [errorMessage]="(errorDict$ | async)?.get('crossField')">
          <div class="row" *ngFor="let term of phasingTermArray.controls; let i = index">
            <div [formGroupName]="i">
              <div class="col-xs-2" *ngIf="i === 0; else yearLabel">
                <label class="sidebar_label">
                  {{ trans.trans.current_state.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.current_state"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
              </div>
              <ng-template #yearLabel>
                <div class="col-sm-2" *ngIf="i > 0">
                  <label class="sidebar_label">
                    {{ trans.trans.year.value }} {{ i }}:
                    <app-editTranslation
                      *ngIf="showTranslate"
                      [component]="trans.config.component"
                      [isTranslationV2]="true"
                      [transObj]="trans.trans.year"
                      (callback)="getTranslations()"
                    ></app-editTranslation>
                  </label>
                </div>
              </ng-template>
              <div class="col-xs-8">
                <div class="col-xs-6 minmax">0</div>
                <div class="col-xs-6 minmax text-right">100</div>
                <input type="range" [min]="0" [max]="100" formControlName="val" [value]="phasingTermArray.value[i].val" />
              </div>
              <div class="col-xs-2">
                <input type="text" formControlName="val" class="form-control" [value]="phasingTermArray.value[i].val" />
              </div>
            </div>
          </div>
        </app-error-input-wrapper>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn_customizable pull-right marg-v a-white" [disabled]="phasingTermGroup.invalid" (click)="menu.toggle($event)">
          {{ trans.trans.save.value }} <i class="fa fa-chevron-down"></i>
        </button>
        <p-menu #menu appendTo="body" [popup]="true" [model]="menuItems" [styleClass]="'style-2022'"></p-menu>
      </div>
    </div>
  </form>
</div>
