<!-- This component is only for feature 88 -->
<ng-container [ngSwitch]="situation.child_element_type_id > 0 ? situation.child_element_type_id : elementTypeId">
  <ng-container *ngSwitchCase="SituationInputType.Dropdown">
    <p-dropdown
      [options]="situation.children"
      [(ngModel)]="model[0]"
      [panelStyleClass]="'dropdown-item-font'"
      optionLabel="name"
      class="keywest_dropdown dropdown-theme"
      appendTo="body"
      [filter]="true"
      filterBy="name"
      [disabled]="canEdit"
      [autoDisplayFirst]="false"
      (onChange)="onChange($event)"
    >
      <ng-template let-child pTemplate="selectedItem">
        <span>
          {{ child.name }}
          <span *ngIf="child?.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child?.description"> info </span>
        </span>
      </ng-template>

      <ng-template let-child pTemplate="item">
        <span>
          {{ child.name }}
          <span *ngIf="child?.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child?.description"> info </span>
        </span>
      </ng-template>
    </p-dropdown>
  </ng-container>
  <ng-container *ngSwitchCase="SituationInputType.MultiSelect">
    <p-multiSelect
      [options]="situation.children"
      [(ngModel)]="model"
      [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
      [disabled]="canEdit"
      styleClass="panel-multi-select"
      optionLabel="name"
      (onChange)="onChange($event)"
    >
      <ng-template let-child pTemplate="selectedItems">
        <ng-container *ngFor="let option of model">
          <span>
            {{ option.name }}
            <span *ngIf="option.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="option.description"> info </span>
          </span>
        </ng-container>
      </ng-template>

      <ng-template let-child pTemplate="item">
        <span>
          {{ child.name }}
          <span *ngIf="child?.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child?.description"> info </span>
        </span>
      </ng-template>
    </p-multiSelect>
  </ng-container>
  <ng-container *ngSwitchCase="SituationInputType.Radio">
    <ng-container *ngFor="let child of situation.children">
      <div class="radio-select">
        <p-radioButton (onChange)="onChange($event)" [name]="child.id" [value]="child" [label]="child.name" [disabled]="canEdit" [(ngModel)]="model[0]"></p-radioButton>
        <span *ngIf="child.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child.description"> info </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngFor="let child of situation.children">
      <div class="radio-select">
        <p-radioButton (onChange)="onChange($event)" [name]="child.id" [value]="child" [label]="child.name" [disabled]="canEdit" [(ngModel)]="model[0]"></p-radioButton>
        <span *ngIf="child.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child.description"> info </span>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="SituationInputType.Checkbox">
    <div class="two-columns">
      <div class="checkbox-left">
        <ng-container *ngFor="let child of situation.children; let even = even; let odd = odd">
          <ng-container *ngIf="odd">
            <div class="checkbox-select">
              <p-checkbox (onChange)="onChange($event)" [disabled]="canEdit" [name]="child.id" [value]="child" [label]="child.name" [(ngModel)]="model"></p-checkbox>
              <i class="fa fa-info-circle" *ngIf="child.description" [matTooltip]="child.description"></i>
              <span *ngIf="child.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child.description"> info </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="checkbox-right">
        <ng-container *ngFor="let child of situation.children; let even = even; let odd = odd">
          <ng-container *ngIf="even">
            <div class="checkbox-select">
              <p-checkbox (onChange)="onChange($event)" [disabled]="canEdit" [name]="child.id" [value]="child" [label]="child.name" [(ngModel)]="model"></p-checkbox>
              <i class="fa fa-info-circle" *ngIf="child.description" [matTooltip]="child.description"></i>
              <span *ngIf="child.description && (style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child.description"> info </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>




