<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>{{ trans.trans.pick_your_account.value}} <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.pick_your_account" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-2' *ngIf="showCompanyAdd">
					<button class="btn btn_customizable pull-right" (click)="addCompany = !addCompany" [ngbTooltip]="contextualHelp['Position 2']" placement="left" container="body">
						<i class="fa fa-plus"></i>{{ trans.trans.add_new.value }}
					</button>
				</div>
			</div>
		</div>
		<div class="row" style='margin-bottom: 20px;'>
			<div class="col-md-12">
				<form name="CompanySearchForm" #CompanySearchForm="ngForm" (ngSubmit)="getRecentSearchComp()">
					<div class="input-group keywest_search">
						<span class="input-group-addon"><i class='fa fa-search'></i></span>
						<input type="text" class="form-control" style='width: 100% !important;' name="companyname" id="companyname" (keyup.enter)='getRecentSearchComp()' [placeholder]="trans.trans.search_for_a_company.value" [(ngModel)]="companyname">
						<span *ngIf="companyname !== ''" (click)="companyname = '';" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
						<a (click)='getRecentSearchComp()' class='input-group-addon clickable'>Search</a>
					</div>
				</form>
			</div>
		</div>
		<div class='row' [hidden]="companyList">
			<div class="col-md-12">
				<div class="table-responsive">
					<div class="searchTableContainer">
						<p-table [value]="companiesList" [columns]='cols' [paginator]="true" [rows]="pageLimit" styleClass='keywest_table' tableStyleClass='keywest_table'>
							<ng-template pTemplate="header" let-columns>
								<tr>
									<th *ngFor="let col of cols; " [pSortableColumn]="col.field">
										{{ trans.trans[col.field].value}}
										<p-sortIcon [field]="col.field"></p-sortIcon>
										<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans[col.field]" (callback)="getTranslations()"></app-editTranslation>
									</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-company>
								<tr>
									<td>
										<a (click)="selectCompany(company.id)" class='clickable'>{{company.name}}</a>
										<i class="fa fa-info-circle" [ngbTooltip]="tipContent" placement="right" container="body"></i>
										<!-- <span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="tipContent" placement="right" container="body">
                      info
                    </span> -->
										<ng-template #tipContent class="toolTipContent">
											<p>
												<strong>{{company.name}} </strong>
											</p>
											<div *ngIf="company.account_defined_id">{{company.account_defined_id}}</div>
											<div *ngIf="company.location_type">{{company.location_type}}</div>
											<div *ngIf="company.country">{{company.country}}</div>
											<div>
												<div *ngIf="company.address1">{{company.address1}}</div>
												<div *ngIf="company.city && company.state && company.zip">{{company.city}}, {{company.state}}
													{{company.zip}}
												</div>
											</div>
										</ng-template>
									</td>
									<td data-title="Account ID">{{company.account_defined_id}}</td>
									<td data-title="Revenue">{{company.revenue_fmt}}(M)</td>
									<td data-title="Employees">{{company.employees_fmt}}</td>
									<td data-title="Ticker">{{company.ticker}}</td>
									<td data-title="City">{{company.city}}</td>
									<td data-title="Country">{{company.country}}</td>
								</tr>
							</ng-template>
						</p-table>

					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<div class='models_panel' style='margin-bottom: 20px;' *ngIf="!quickCompanyDetailsDescription">
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>{{ trans.trans.heres_what_we_know_about_the_account_you_have_selected.value}}</div>
				</div>
			</div>
		</div>

		<div class="row" [hidden]="detailedDescription">
			<div class="col-md-5">
				<div class="key-features">
					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.name.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							{{companyDetails?.name}}
						</div>
					</div>

					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.account_id.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.account_id" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							{{companyDetails?.account_defined_id}}
						</div>
					</div>

					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.address.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.address" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							{{companyDetails?.address1}}
						</div>
					</div>

					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.country.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.country" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							{{companyDetails?.country}}
						</div>
					</div>

					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.sector.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.sector" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							{{companyDetails?.dl_sector_name}}
						</div>
					</div>

					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.website.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.website" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							<a [href]='companyDetails.url.substring(0,4) == "http" ? companyDetails.url : "https://" + companyDetails.url' target='_blank' class='clickable'>{{companyDetails?.url}}</a>
						</div>
					</div>

					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.revenue.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.revenue" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							{{companyDetails?.revenue_fmt}} (M)
						</div>
					</div>

					<div class="key-features-list">
						<div class="key-features-label">
							{{ trans.trans.employees.value}}
							<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true" [transObj]="trans.trans.employees" (callback)="getTranslations()"></app-editTranslation>

						</div>
						<div class="key-features-value">
							{{companyDetails?.employees_fmt}}
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-7" *ngIf="companyDetails?.synopsis">
				<div style='font-size: 18px; font-weight: bold; margin: 0px;'>{{ trans.trans.description.value}}</div>
				<div>
					<p>{{companyDetails.synopsis}}</p>
				</div>
			</div>
		</div>
	</div>
</div>


<p-sidebar position='right' styleClass="keywest_sidebar p-sidebar-lg" [(visible)]="addCompany">
	<app-vp-add-company-no-trans *ngIf="addCompany" [modalCreate]="true" (companyCreated)="companyCreated($event)" (enableSave)="enableSave($event)" (cancelCreation)="cancelCompany()"></app-vp-add-company-no-trans>
</p-sidebar>