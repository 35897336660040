import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AssetGenerator } from '@data/services/account/models/asset-generator.interface';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { NotificationService } from '@services/notification.service';
import { AssetTemplate, Template } from '@shared/models/asset.models';
import { CaseStudy } from '@shared/models/case-study.model';
import { ValuePropCollateral } from '@shared/models/collateral.model';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RSV2ToolbarTranslations } from '../toolbar.translation';
import { ResultsAnalysisService } from '@data/services/results-analysis/results-analysis.service';

interface AssetListItem {
  label: string;
  icon: string;
  url: string;
  pdf: string;
  showPDF: boolean;
  target: string;
  description: string;
  format: string;
  account_asset_template_id?: string;
  showImage: boolean;
  faStyle?: { [klass: string]: any };
}
@Component({
  selector: 'app-toolbar-assets',
  styleUrls: ['./toolbar-assets.component.scss'],
  templateUrl: './toolbar-assets.component.html',
})
export class ToolbarAssetsComponent implements OnInit, OnDestroy {
  @Input() valuePropId: number;
  @Input() showAllTcoMetrics: boolean;
  @Input() calculatorPublicKey: string;
  @Input() isValueTracker = false;
  @Input() businessReview: BusinessReviewData;
  @Input() businessReviewRequired = false;
  assetGenerator: AssetGenerator;
  assetURL: string;
  userId: string;
  token: string;
  menuAssets: AssetListItem[];
  featureShowMoreTcoCosts = false;
  hasFeature123 = false;
  ngUnsubscribe = new Subject();
  assetGeneratorTypes = AssetGenerator;
  collaterals: (ValuePropCollateral & { icon: string; faStyle?: { [klass: string]: any } })[];
  caseStudies: CaseStudy[] = [];

  constructor(
    private valuepropService: ValuepropService,
    private commonService: CommonService,
    public trans: RSV2ToolbarTranslations,
    private configurableWorkflowService: ConfigurableWorkflowService,
    private assetService: AssetService,
    private notificationService: NotificationService,
    private valueRealizationService: ValuerealizationService,
    private rAS: ResultsAnalysisService
  ) { }

  ngOnInit() {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file') as AssetGenerator;
    this.userId = sessionStorage.getItem('uid');
    this.token = sessionStorage.getItem('vcu');
    this.featureShowMoreTcoCosts = this.commonService.checkFeature(76);
    this.hasFeature123 = this.commonService.checkFeature(123);
    if (this.isValueTracker) {
      this.funcGetTrackerAssets();
    } else {
      this.configurableWorkflowService.selectedConversationWorkflow$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((wf: ConversationWorkflow) => {
        if (wf) {
          this.funcGetAssets(wf);
        } else {
          this.funcGetAssets();
        }
      });
    }
    this.assetURL = this.commonService.getAssetUrl();

    this.getCollaterals();
    this.readCaseStudies();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  downloadCaseStudyAsset(id: string): void {
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.assetService.getCaseStudyAssetTemplate().subscribe((response) => {
      if (response && response !== null && typeof response !== 'string') {
        const downloadUrl = `${this.assetURL}assets/${this.assetGenerator}?account_asset_template_id=${response.result.id
          }&user_id=${userId}&token=${token}&case_study_id=${id}&is_account_solution=${1}`;
        window.location.assign(downloadUrl);
      } else {
        this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }

  funcGetAssets(wf: ConversationWorkflow = null) {
    let call;
    if (wf) {
      call = this.valuepropService.readValuePropAssetTemplatesForWfs(this.valuePropId, wf.id);
    } else {
      call = this.valuepropService.readValuePropAssetTemplatesForWfs(this.valuePropId);
    }
    call.pipe(take(1)).subscribe((result) => {
      if (result.result) {
        this.mapAssets(result.result);
      }
    });
  }

  funcGetTrackerAssets() {
    this.valueRealizationService
      .getValueTrackerAssets(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        if (result.result) {
          this.mapAssets(result.result);
        }
      });
  }

  getAssetURL(asset: AssetTemplate | Template, pdf: boolean) {
    let url = `${this.commonService.getAssetUrl()}assets/${this.assetGenerator}?account_asset_template_id=${asset.account_asset_template_id ? asset.account_asset_template_id : asset.id
      }&user_id=${this.userId}&token=${this.token}&value_prop_id=${this.valuePropId}`;

    if (pdf) {
      url = url + '&pdf=1';
    }
    if (this.calculatorPublicKey && this.calculatorPublicKey !== '') {
      url = url + '&public_key=' + this.calculatorPublicKey;
    }
    if (this.featureShowMoreTcoCosts && this.rAS.showAllMetrics) {
      url = url + '&tco_include_all=1';
    }
    if (this.isValueTracker && this.businessReview) {
      url = url + `&business_review_id=${this.businessReview.id}`;
    }
    return url;
  }

  getGoogleDriveIcon(): string {
    return 'google-drive';
  }

  // Imported from https://www.npmjs.com/package/prepend-http
  prependHttp(url: string, { https = true } = {}) {
    url = url.trim();
    if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
      return url;
    }
    return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
  }

  getGoogleIcons(format: string): string {
    switch (format) {
      case 'MS Word':
        return 'google-docs';

      case 'MS Excel':
        return 'google-sheets';

      case 'MS PowerPoint':
        return 'google-slides';

      case 'MS PDF':
        return 'pdf-file';

      default:
        return 'google-drive';
    }
  }

  getMicrosoftIcons(format: string): string {
    switch (format) {
      case 'MS Word':
        return 'fa fa-file-word-o';

      case 'MS Excel':
        return 'fa fa-file-excel-o';

      case 'MS PowerPoint':
        return 'fa fa-file-powerpoint-o';

      case 'MS PDF':
        return 'fa fa-file-pdf-o';

      case 'PDF':
        return 'fa fa-file-pdf-o';

      case 'Image':
        return 'fa fa-file-image-o';

      case 'Video':
        return 'fa fa-file-movie-o';

      case 'ODT':
        return 'fa fa-file-text-o';

      case 'Website':
        return 'fa fa-globe';

      default:
        return 'fa fa-cloud-download';
    }
  }

  getFAColor(format: string): { [klass: string]: any } {
    switch (format) {
      case 'MS Word':
        return { 'color': '#2e5b97', 'margin-right': '5px' };

      case 'MS Excel':
        return { 'color': '#3a6e46', 'margin-right': '5px' };

      case 'MS PowerPoint':
        return { 'color': '#c55737', 'margin-right': '5px' };

      case 'MS PDF':
        return { 'color': '#c60a09', 'margin-right': '5px' };

      default:
        return { 'color': 'black', 'margin-right': '5px' };
    }
  }

  private getCollaterals(): void {
    this.valuepropService.readCollateral(this.valuePropId).subscribe((response) => {
      if (response.result) {
        this.collaterals = response.result.map((collateral) => {
          return {
            ...collateral,
            name: collateral.name,
            icon: this.getMicrosoftIcons(collateral.format),
            faStyle: this.getFAColor(collateral.format),
            url: collateral.url,
            target: '_blank',
            description: collateral.description,
            format: collateral.format,
          };
        });
      } else {
        this.collaterals = [];
      }
    });
  }

  private readCaseStudies() {
    this.valuepropService
      .readCaseStudies(this.valuePropId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.caseStudies = [];
        if (result.result) {
          if (this.hasFeature123) {
            // We may need to unset some case studies so they don't show
            result.result.forEach(element => {
              if (element.reference_url !== '') {
                // Reference url is not empty, add it
                this.caseStudies.push(element);
              }
            });
          } else {
            this.caseStudies = result.result;
          }
        }
      });
  }

  private mapAssets(assets: (AssetTemplate | Template)[]) {
    this.menuAssets = [];

    const isDriveGenerator = this.assetGenerator === AssetGenerator.saveToGdrive;

    this.menuAssets = assets.map((asset) => {
      return {
        label: asset.name,
        icon: isDriveGenerator ? this.getGoogleIcons(asset.format) : this.getMicrosoftIcons(asset.format),
        showImage: isDriveGenerator,
        faStyle: !isDriveGenerator ? this.getFAColor(asset.format) : null,
        url: this.getAssetURL(asset, false),
        pdf: this.getAssetURL(asset, true),
        showPDF: +asset.pdf === 1 ? true : false,
        target: '_blank',
        description: asset.description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
        format: asset.format,
      };
    });
  }
}
