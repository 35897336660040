import { Injectable } from '@angular/core';
import { APIResult } from '@shared/models/api-result.model';
import { CXOMaturity, CXOPayload } from '@shared/models/cxo.models';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';


@Injectable()
export class CXOService {
	constructor(private commonService: CommonService) { }

	public adminGetCXO(accountId: string): Observable<APIResult<CXOMaturity[]>> {
		let endpoint = "/account/maturityModels/" + accountId;
		return this.commonService.getAPIService(endpoint);
	}

	public adminPostCXO(accountId: string, payload: CXOPayload): Observable<APIResult<number>> {
		let endpoint = "/account/maturityModel/" + accountId;
		return this.commonService.postAPIService(endpoint, payload);
	}

	public adminPutCXO(accountId: string, payload: CXOPayload): Observable<APIResult<string>> {
		let endpoint = "/account/maturityModel/" + accountId;
		return this.commonService.putAPIService(endpoint, payload);
	}

	public adminDeleteCXO(accountId: string, maturityId: string): Observable<APIResult<string>> {
		let endpoint = "/account/maturityModel/" + accountId + "/delete/" + maturityId;
		return this.commonService.deleteAPIService(endpoint);
	}

	public getValuePropMaturity(vpid: string): Observable<APIResult<CXOMaturity[]>> {
		let endpoint = "/valueProps/valuePropMaturityModels/" + vpid;
		return this.commonService.getAPIService(endpoint);
	}

	public postValuePropMaturity(vpid: string, payload: Partial<CXOPayload>): Observable<APIResult<string>> {
		let endpoint = "/valueProps/valuePropMaturityModels/" + vpid;
		return this.commonService.postAPIService(endpoint, payload);
	}

	public downloadAsset(vpid: string): Observable<APIResult<{ id: string }>> {
		let endpoint = "asset/readAssetTemplateByAssetTypeId/24/valuePropId/" + vpid;
		return this.commonService.getAPIService(endpoint);
	}
}