import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AssetService } from '@data/services/asset/asset.service';
import { CommonService } from '@data/services/common/common.service';
import { SolutionService } from '@data/services/solution/solution.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { NotificationService } from '@services/notification.service';
import { StyleService } from 'app/style.service';
import { RowLimit } from 'app/_models/row-limit.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as $ from 'jquery';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case_studies.component.html',
})
export class CaseStudiesComponent implements OnInit, OnDestroy {
  caseStudies: { [klass: string]: any }[] = [];
  caseStudiesCopy: { [klass: string]: any }[] = [];
  loading = false;
  columns: { field?: string; header?: string; key?: string }[] = [];
  pageLimit = 10;
  ngUnsubscribe = new Subject<boolean>();
  toggleAddCaseStudies = false;
  solutionID: string;
  mode: string;
  caseStudyEdit: { [klass: string]: any };
  caseStudyId: string;
  caseStudy: { [klass: string]: any };
  rowLimit: MenuItem[] = [];
  showAddButtons = false;
  strSearch: string;
  assetGenerator: string;
  assetURL: string;
  tagList: string[] = [];
  toggleShowTags = false;
  isSolutionAdmin = false;
  isCaseStudyAdmin = false;
  can_edit = false;

  selectedTag:  { [klass: string]: any }[] = [];
  style2022$: Observable<boolean>;

  constructor(
    private solutionService: SolutionService,
    private commonService: CommonService,
    private assetService: AssetService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private valuerealizationService: ValuerealizationService,
    private styleService: StyleService
  ) {}

  ngOnInit() {
    this.isSolutionAdmin = this.commonService.checkPrivilege(2);
    this.isCaseStudyAdmin = this.commonService.checkPrivilege(9);
    this.style2022$ = this.styleService.style2022;
    if (this.isSolutionAdmin || this.isCaseStudyAdmin) {
      this.can_edit = true;
    }

    this.assetURL = this.commonService.getAssetUrl();

    this.showAddButtons = !this.commonService.checkFeature(41);

    this.columns.push({ header: 'Name', field: 'name' });
    this.columns.push({ header: 'Date Created', field: 'created' });
    this.columns.push({ header: 'Testimonials', field: 'testimonials_total' });
    this.columns.push({ header: 'Sections', field: 'sections_total' });
    this.columns.push({ header: 'Benefits', field: 'benefits_total' });
    this.getData();
    this.funcBuildPageLimit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getData(): void {
    this.toggleAddCaseStudies = false;
    this.loading = true;
    const searchLimit = '/limit/all/page/' + 1;

    this.solutionService
      .getRecentCaseStudies(searchLimit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response.result) {
          this.caseStudies = response.result.case_studies.data;
          this.caseStudiesCopy = this.caseStudies;

          this.caseStudies.forEach((elem) => {
            const tags = elem && elem.tag ? elem.tag.split(',') : [];
            if (tags && tags.length) {
              tags.forEach((tag) => {
                this.tagList.push(tag);
              });
            }
          });
          this.tagList.push('All');

          const uniqueTags = [];
          $.each(this.tagList, function (_i, el) {
            if ($.inArray(el, uniqueTags) === -1) {
              uniqueTags.push(el);
            }
          });
          this.tagList = uniqueTags;
          this.loading = false;
        }
      });
  }

  setRowLimit(num): void {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit(): void {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  navigateCaseStudy(caseStudy): void {
    this.toggleAddCaseStudies = true;
    this.mode = 'edit';
    this.caseStudyId = caseStudy['id'];
    this.caseStudy = caseStudy;
    this.caseStudyEdit = caseStudy;
  }

  onCloseSidebar(): void {
    this.getData();
  }

  downloadAsset(id): void {
    this.assetGenerator = sessionStorage.getItem('asset_gen_file');
    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');
    this.assetService.getCaseStudyAssetTemplate().subscribe((response) => {
      if (response && response !== null && typeof response !== 'string') {
        const downloadUrl =
          this.assetURL +
          'assets/' +
          this.assetGenerator +
          '?account_asset_template_id=' +
          response.result.id +
          '&user_id=' +
          userId +
          '&token=' +
          token +
          '&case_study_id=' +
          id +
          '&is_account_solution=' +
          1;

        window.location.assign(downloadUrl);
      } else {
        this.notificationService.error('You need a template file in order to download this asset. Contact your administrator.', false);
      }
    });
  }

  funcFilterByTag(tag): void {
    if (tag === 'All') {
      this.caseStudies = this.caseStudiesCopy;
      this.selectedTag = [];
      return;
    }

    const uniqueTags = [];
    $.each(this.selectedTag, function (_i, el) {
      if ($.inArray(el, uniqueTags) === -1) {
        uniqueTags.push(el);
      }
    });

    this.selectedTag = uniqueTags;

    const index = this.selectedTag.indexOf(tag);
    if (index !== -1) {
      this.selectedTag.splice(index, 1);
      this.cd.detectChanges();
    } else {
      this.selectedTag.push(tag);
    }

    const tmp = [];

    this.caseStudiesCopy.forEach((elem) => {
      if (elem.tag) {
        const tags = elem.tag.split(',');
        this.selectedTag.forEach((s) => {
          if (tags.filter((x) => x === s).length) {
            tmp.push(elem);
          }
        });
      }
    });

    const uniqueCaseStudies = [];
    $.each(tmp, function (_i, el) {
      if ($.inArray(el, uniqueCaseStudies) === -1) {
        uniqueCaseStudies.push(el);
      }
    });
    this.caseStudies = uniqueCaseStudies;
  }

  removeTag(index): void {
    this.selectedTag.slice(index, 1);
  }

  styleTag(item): { 'background-color': 'green', 'color': 'white' } {
    if (this.selectedTag.filter((x) => x === item).length) {
      return { 'background-color': 'green', 'color': 'white' };
    } else {
      return;
    }
  }

  tagClose(item): boolean {
    if (this.selectedTag.filter((x) => x === item).length) {
      return true;
    } else {
      return false;
    }
  }

  deleteCaseStudy(caseStudy): void {
    this.valuerealizationService.deleteCaseStudy(caseStudy.id).subscribe((response) => {
      this.getData();
    });
  }
}
