import { Injectable } from '@angular/core';
import { TranslationsBaseService } from '@shared/translations-base/translations-base.service';

@Injectable()
export class BenefitsScratchpadTranslations extends TranslationsBaseService {
  public config: any = {
    component: 'scratchpads',
    display_name: 'Scrathpads',
    description: 'Scratchpad for a benefit',
  };

  public trans: any = {
    save: 'Save',
    Calculate: 'Calculate',
    Clear: 'Clear',
    Total: 'Total',
    answer: 'Answer',
    calculatedValue: 'Calculated Value',
    cancel: 'Cancel',
    dataSaved: 'Data saved Successfully.',
    nameUpdateSucess: 'Scratchpad factor name succesfully updated',
    nameUpdateFailure: 'There was an error while updating the scratchpad factor name',
    noChanges: 'At least one factor must be changed before scratchpad will save',
  };
}
