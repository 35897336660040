<div class='models_panel' style='margin-bottom: 20px;'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header_small'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>{{ trans.trans.heading.value }}</div>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				{{ trans.trans.my_credits.value }}
				<p class="pull-right"><a routerLink="/plans">{{userCredits}} <i class="fa fa-plus"></i></a> </p>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				{{ trans.trans.account_credits.value }}
				<p class="pull-right">{{accountCredits}}</p>
			</div>
		</div>
		<div class='row'>
			<div class='col-xs-12'>
				<a class="assign-credit" (click)="assignCredits = true">{{ trans.trans.assignCreditslabel.value }}</a>
			</div>
		</div>
		<form class="form-horizontal m-t-xs" name="AddCredits" #AddCredits="ngForm" (ngSubmit)="ngOnSubmit()">
			<div class='row'>
				<div class='col-xs-12'>
					<div class="form-group col-md-12" [ngClass]="{'has-error': (AddCredits.controls.accountusers?.invalid && AddCredits.controls.accountusers?.touched)}">
						<select type="text" class="form-control" name="accountusers" required [(ngModel)]="selectedUser">
							<option value="0" disabled="disabled">{{trans.trans.selectUser.value}}</option>
							<option *ngFor="let user of accountUsers" id="{{user.id}}" [ngValue]="user.id">
								{{ user.name }}
							</option>
						</select>
					</div>
					<div class="form-group col-md-12" [ngClass]="{'has-error': (AddCredits.controls.AssignedCredit?.invalid && AddCredits.controls.AssignedCredit?.touched)}">
						<input type="number" min="0" class="form-control" name="AssignedCredit" maxlength="30" id="AssignedCredit" placeholder="{{trans.trans.credits.value}}" required [(ngModel)]="AssignedCredit">
					</div>
					<button type="submit" class="btn btn_customizable" (click)="assignUserCredits()" [disabled]="AddCredits.invalid">{{ trans.trans.save.value }}</button>
					<button type="button" class="btn btn_customizable" (click)="ClearValues()">{{ trans.trans.Cancel.value }}</button>
				</div>
			</div>
		</form>
	</div>
</div>