<div class="sidebar_wrapper">
	<div class='sidebar_header'>
		{{trans.trans.viewThresholds.value}}
		<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
		[isTranslationV2]="true" [transObj]="trans.trans.viewThresholds" (callback)="getTranslations()"></app-editTranslation>
	</div>

	<div class='sidebar_container'>
		<div class='keywest_panel'>
			<div class="row">
				<div class="col-md-12">
					<label for="alterEgo" class="sidebar_label_readonly">{{trans.trans.name.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					{{thresholdFormObj.name}}
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<label for="name" class="sidebar_label_readonly"> {{trans.trans.min.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.min" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					<data>{{thresholdFormObj.min}}</data>
				</div>

				<div class="col-md-4">
					<label for="max" class="sidebar_label_readonly">{{trans.trans.max.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.max" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					<data>{{thresholdFormObj.max}}</data>
				</div>

				<div class="col-md-4">
					<label for="margin" class="sidebar_label_readonly">{{trans.trans.margin.value}}
						<app-editTranslation *ngIf="showTranslate" [component]="trans.config.component"
						[isTranslationV2]="true" [transObj]="trans.trans.margin" (callback)="getTranslations()"></app-editTranslation>
					</label><br>
					<data>{{thresholdFormObj.margin}}</data>
				</div>
			</div>
		</div>
	</div>

</div>