import { Injectable } from '@angular/core';

@Injectable ()

export class CasestudiesValueRealizedTranslations {

    public config: any = {
        component: 'casestudiesValueRealized',
        display_name: 'Case Studies Value Realized',
        description: 'Case studies in value realized'
    };

    public trans: any = {
        searchCaseStudies: 'Search Case Studies',
        search: 'Search',
        name_label: 'Name',
        noCaseStudies: 'No Case Studies',
        searchByName: 'Search By Name',
        searchByTag: 'Search By tags',
        rowLimit: 'Row Limit:',
        areYouSure: 'Are you sure?',
        delete: 'Delete',
    };
}
