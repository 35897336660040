<div class="row margin-spacer" *ngIf="style2022$ | async">
    <div class="col-md-4 mr-auto breadcrumb-container">
        <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
    </div>
</div>
<div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10">
	  <a class="back-profile" [routerLink]="(style2022$ | async) ? ['/settings'] : ['/profile']"><i class="fa fa-chevron-left"></i> {{ (style2022$ | async) ? 'Back to Settings' : trans.trans.backToProfile.value }}</a>
	</div>
  </div>
<div class='models_panel report-card-title-wrapper'>
    <div class='models_page_header_container_small'>
        <div class='keywest_header'></div>
    </div>
    <div class='row margin-spacer'>
		<div class='col-xs-4'>
			<div class="input-group keywest_search">
				<span class="input-group-addon"><i class='fa fa-search'></i></span>
				<input [(ngModel)]="strSearch" (keyup)="searchReports($event)" type='text' placeholder='{{trans.trans.search.value}}' class='form-control'>
				<span *ngIf="strSearch !== ''" (click)="clearSearch()" class="input-group-addon clickable"><i class='fa fa-times'></i></span>
			</div>
		</div>
	</div>
        <div class='row' style="overflow: auto;">
            <div *ngFor="let report of reports" class='col-xs-12 col-sm-6 col-lg-4' >
                <div class="download-report-card report-card" >
                    <div class="row report-card-text-wrapper">
                        <div class='col-xs-9 text-col'>
                            <span class="report-card-text">{{report.name}}</span>
                        </div>
                        <div class='col-xs-2 icon-col'>
                            <a [matTooltip]="trans.trans.downloadTooltip.value" [href]="generateLink(report)" target="_blank">
                                <span class="material-icons-outlined download-icon">
                                    file_download
                                </span>
                            </a>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>