import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitsComponent } from './benefits/benefits.component';
import { ValidationComponent } from './validation.component';
import { FactorsComponent } from './factors/factors.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import { ValidationTranslations } from './validation.translations';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import {AccordionModule} from 'primeng/accordion';
import { BenefitValidationTableComponent } from './benefits/benefit-validation-table/benefit-validation-table.component';
import { FactorValidationTableComponent } from './factors/factor-validation-table/factor-validation-table.component';
import { SolutionValidationService } from '@data/services/validation/solution-validation.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    BenefitsComponent,
    ValidationComponent,
    FactorsComponent,
    BenefitValidationTableComponent,
    FactorValidationTableComponent,
  ],
  imports: [
    CommonModule,
    SelectButtonModule,
    FormsModule,
    CheckboxModule,
    TableModule,
    AccordionModule,
    MatTooltipModule
  ],
  providers: [ValidationTranslations, SolutionValidationService],
  exports:[ValidationComponent]
})
export class ValidationModule { }
