<div class="col-md-6" style="margin-bottom: 10px">
  <div class="models_panel dashboard-card" style="height: 400px">
    <div class="models_page_header_container_small">
      <div class="keywest_header">
        <div class="row">
          <div class="col-xs-10">
            <div class="title">
              {{ trans.trans.valueRealizationHeading.value
              }}<app-editTranslation
                *ngIf="showTranslate"
                (callback)="getTranslations()"
                key="main_dashboard.valueRealizationsHeading"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.valueRealizationsHeading"
              ></app-editTranslation>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="overflow-y: hidden; max-height: 270px">
      <div [hidden]="!loading">
        <app-table-skeleton rowHeight="35px" [columns]="columns" [trans]="trans.trans" [rowLimit]="5"></app-table-skeleton>
      </div>

      <p-table #tableSolutionList [value]="realizations" [columns]="columns" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ trans.trans[col.field].value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans[col.field]"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tracker>
          <tr>
            <td *ngFor="let col of columns">
              <span *ngIf="col.field !== 'assigned_by'; else assignedBy" (click)="navToDetail(tracker)">
                <span [ngClass]="{ 'clickable link': col.field === 'value_prop_name' }">
                  {{ tracker[col.field] }}
                </span>
              </span>
              <ng-template #assignedBy>
                <span class="'field-with-avatar'">
                  <div class="avatar-circle">
                    {{ tracker[col.field] | functionPipe: getInitials }}
                  </div>
                </span>
              </ng-template>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr *ngIf="!loading" class="not-hover">
            <td [attr.colspan]="columns.length + 1" style="padding-top: 100px">
              <app-no-records width="200px" [records]="realizations.length > 0">
                <ng-container *ngIf="realizations.length === 0">
                  {{ trans.trans.noRecords.value }}
                </ng-container>
                <ng-container *ngIf="realizations.length > 0"> {{ trans.trans.noResults.value }} "{{ strSearch }}" </ng-container>
              </app-no-records>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <a routerLink="/trackers" class="keywest_seeMore">{{ trans.trans.seeMore.value }}</a>
    <app-editTranslation
      *ngIf="showTranslate"
      (callback)="getTranslations()"
      key="main_dashboard.vr_seeMore"
      [isTranslationV2]="true"
      [component]="trans.config.component"
      [transObj]="trans.trans.vr_seeMore"
    ></app-editTranslation>
  </div>
</div>