<div *ngIf="!benefitsLoader" class='well well-info'>
	<p>
		{{trans.vp_dashboard.benefits_panel_content | translate }}
	</p>
</div>

<div *ngFor="let benefitCategory of benefitsList; let b = index" cdkDropList (cdkDropListDropped)="drop($event, benefitsList)" class='dragObjectsParent'>
	<!-- {{benefitCategory.metrics[0] | json}} -->
	<div class='panel panel-white panel-dashed dragObjects' cdkDrag *ngIf="showGroup(benefitCategory.metrics);">
		<div class="panel-heading" style='height: inherit !important; background-color: #f5f5f5; padding-bottom: 0px;'>
			<div class='row'>
				<div class='col-xs-12' style='padding-right: 30px;'>
					<table style='table-layout:fixed;width:100%'>
						<tr>
							<td width='20px' style='vertical-align: top;' (click)='updateBenefitsLocalStorage(benefitCategory.value_category_id);'>
								<i class='fa fa-plus clickable' *ngIf="!benefitsListExpanded[b].expand" style='margin-right: 5px;'></i>
								<i class='fa fa-minus clickable' *ngIf="benefitsListExpanded[b].expand" style='margin-right: 5px;'></i>
							</td>
							<td width='100%' (click)='updateBenefitsLocalStorage(benefitCategory.value_category_id);'>
								<a class='title clickable' style='font-weight: bold; text-decoration: none;'>{{benefitCategory.name}}</a><br>
								<small style='font-size: 70%; color: green; margin: 5px;'>{{filterActive(benefitCategory, 1)}} {{trans.vp_dashboard.benefits.active | translate }}</small>
								<small style='font-size: 70%; color: red; margin: 5px;'>{{filterActive(benefitCategory, 0)}} {{trans.vp_dashboard.benefits.na | translate }}</small>
								<small style='font-size: 70%; color: orange; margin: 5px;'>{{filterActive(benefitCategory, 3)}} {{trans.vp_dashboard.benefits.soft | translate }}</small>
							</td>
							<td>
								<dl-inputswitch class='pull-right' (onChange)='benefitsListExpanded[b].active = !benefitsListExpanded[b].active' onLabel='No' onColor='red' offColor='green' offLabel='Yes'></dl-inputswitch>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="panel-body" *ngIf="getBenefitsListExpanded(benefitCategory.value_category_id)">
			<div *ngIf="isTable" class="table-responsive col-sm-12 soln-table" style='padding: 0px;'>
				<table class="table custom-table benefits-simple">
					<thead>
						<tr>
							<th style='width: 100px;'>{{ trans.vp_dashboard.benefits.status | translate }}</th>
							<th style="width: 100%">{{ trans.vp_dashboard.benefits.benefits | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let benefit of benefitCategory.metrics | filter : menuFilter : 'metric_name' | filterStatus: showActive : showNA : showSoft; let i = index">
							<tr class="grid-row">
								<td class="benefit-status benefitStatusElement text-center" *ngIf="(!inputOpened || !benefit.changeActive)" (click)="openActiveTypes(benefit)" matTooltip="{{trans.contextual_help.vp_dashboard.benefit_status | translate}}" matTooltipPosition="above">
									<a class="dashedLink benefitStatusElement" data-toggle="tooltip" title="Change Benefits Status">
										<span class="activeStatusType benefitStatusElement" [ngStyle]="{'background-color':getBenefits(benefit.active_type)}"></span>
									</a>
								</td>
								<td *ngIf="inputOpened && benefit.changeActive" class="benefitStatusElement">
									<form class="form-inline benefitStatusElement">
										<div class="editable-controls form-group benefitStatusElement">
											<select class="form-control active-type benefitStatusElement" name="benefit_active_{{benefit.id}}" [(ngModel)]="benefit.active" (change)="statusChange(benefit)">
												<option value="" class="benefitStatusElement" disabled>{{ trans.vp_dashboard.benefits.choose | translate }}</option>
												<option *ngFor="let activeType of activeTypes" [value]="activeType.value" class="benefitStatusElement">
													{{activeType.name}}
												</option>
											</select>
										</div>
									</form>
								</td>
								<td class="benefit-status" [matTooltip]="benefit.description" matTooltipPosition="above">

									<a class='title clickable' (click)="benefit.showDetails = !benefit.showDetails">
										<i class='fa fa-chevron-right' *ngIf="!benefit.showDetails"></i>
										<i class='fa fa-chevron-down' *ngIf="benefit.showDetails"></i>
										{{benefit.alt_metric_name ? benefit.alt_metric_name : benefit.metric_name}}
									</a>
								</td>
							</tr>
							<tr *ngIf="benefit.showDetails">
								<td colspan='3'>
									<app-benefit-detail (refreshBenefit)='loadBenefits()' [embedded]='true' [selectedBenefit]='benefit' [data]="benefit" [data]='benefit' [valueProp]="valueProp" [contextualHelp]="contextualHelp" (closeBenefitDetail)="benefit.showDetails = false"></app-benefit-detail>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>

			</div>
			<div *ngIf="benefitsLoader" class="col-sm-12 m-t-md text-center">
				<p class="text-center">
					<img class="loader" src="{{fullImagePath}}" />
				</p>
			</div>
			<div *ngIf="(benefitsList == null || benefitsList.length==0) && !benefitsLoader" class="m-t-md col-sm-12">
				<div class="searchTableContainer text-center">
					<h4>{{ 'props.tabDetails.benefits.NoBenefitsFound' | translate }}</h4>
				</div>
			</div>
		</div>
	</div>
</div>
