<p-menu #tagMenu appendTo="body" [popup]="true" [model]="tagList" [styleClass]="(style2022$ | async) ? 'style-2022' : ''"></p-menu>
<div class="row">
  <div class="col-xs-12" *ngIf="!accessFromVP">
    <a [routerLink]="'/case_study'" class="clickable link"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToCaseStudy.value }}</a>
  </div>
  <div class="col-xs-12" *ngIf="accessFromVP">
    <a [routerLink]="['/repv2', this.previousVPId, '4']" class="clickable link"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToValueProp.value }}</a>
  </div>
</div>

<div class="models_panel" *ngIf="caseStudy || !case_study_id">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-8">
          <div class="title" style="padding-top: 0px" *ngIf="!editingName">
            {{ title }}
            <i
              *ngIf="can_edit && !accessFromVP"
              matTooltip="{{ trans.trans.editCaseStudyName.value }}"
              (click)="caseStudy?.id ? funcToggleEditCaseStudy() : changeEditStatus()"
              class="fa fa-pencil clickable"
              style="margin-left: 10px"
            ></i>
          </div>

          <div class="title" style="padding-top: 0px" *ngIf="editingName">
            <div class="input-group">
              <input type="text" [(ngModel)]="caseStudy.name_edited" class="form-control" />
              <span class="input-group-addon" (click)="editingName = !editingName" matTooltip="{{ trans.trans.cancel.value }}"><i class="fa fa-times"></i></span>
            </div>
          </div>
        </div>
        <div class="col-xs-4">
          <button *ngIf="!accessFromVP" [disabled]="(selectedModel ? false : true) || referenceUrlControl.invalid" type="submit" class="btn btn_customizable pull-right marg-h" (click)="UpdateCaseStudy()">{{ trans.trans.save.value }}</button>
          <a (click)="downloadAsset(case_study_id)" class="btn btn_customizable pull-right">{{ trans.trans.downloadAsset.value }}</a>
        </div>
      </div>
    </div>

    <div *ngIf="showTopSection()" class="row">
      <div class="col-xs-6">
        <div class="col-xs-12">
          <label for="models" class="sidebar_label">{{ trans.trans.selectModel.value }}</label>
        </div>
        <div class="col-xs-12">
          <p-dropdown
            *ngIf="can_edit"
            name="models"
            optionLabel="name"
            appendTo="body"
            [options]="modelList"
            [filter]="true"
            filterBy="name"
            [autoDisplayFirst]="false"
            [(ngModel)]="selectedModel"
            class="keywest_dropdown"
            [panelStyleClass]="(style2022$ | async) ? 'style-2022' : null"
          ></p-dropdown>
          <span *ngIf="!can_edit">{{ selectedModel.name }}</span>
        </div>
      </div>

      <div class="col-xs-6">
        <div class="row">
          <div class="col-xs-12">
            <label for="" class="sidebar_label">Redact
              <i *ngIf="!(style2022$ | async)" matTooltip="Redact sensative information" class="fa fa-info-circle"></i>
              <span *ngIf="(style2022$ | async)" class="material-icons-outlined tooltip-custom info-icon" matTooltip='Redact sensative information'>
                info
              </span>
            </label>
          </div>
          <div class="col-xs-12">
            <p-inputSwitch *ngIf="can_edit" [(ngModel)]="redacted"></p-inputSwitch>
            <span class="switch_inline">{{ redacted ? 'Yes' : 'No' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!detailedDescription && !accessFromVP">
      <div class="row" style="margin-top: 30px">
        <div class="col-xs-12">
          <form class="" name="CompanySearchForm" #CompanySearchForm="ngForm" (ngSubmit)="getRecentSearchComp()">
            <label for="" class="sidebar_label">{{ trans.trans.selectAccount.value }} </label>
            <div class="input-group keywest_search">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input [(ngModel)]="companyName" (key.enter)="getRecentSearchComp()" type="text" name="companyName" placeholder="Search Companies" class="form-control" />
              <span *ngIf="companyName !== ''" (click)="clearCompanyList()" class="input-group-addon clickable"
                ><i class="fa fa-times"></i
              ></span>
            </div>
          </form>
        </div>
      </div>

      <div class="row" *ngIf="showActionLoader">
        <div class="col-xs-12">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </div>

      <div class="row" *ngIf="companiesList.length && !detailedDescription">
        <div class="col-xs-12">
          <p-table #tableSolutionList [value]="companiesList" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
            <ng-template pTemplate="header">
              <tr>
                <th>{{ trans.trans.companyName.value }}</th>
                <th class="text-right">{{ trans.trans.companyRevenue.value }}</th>
                <th class="text-right">{{ trans.trans.companyNumEmployees.value }}</th>
                <th>{{ trans.trans.companyTicker.value }}</th>
                <th>{{ trans.trans.companyCity.value }}</th>
                <th>{{ trans.trans.companyCountry.value }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-company>
              <tr (click)="selectCompany(company.id)" class="clickable">
                <td>{{ company.name }}</td>
                <td>{{ company.net_sales }}</td>
                <td>{{ company.employees }}</td>
                <td>{{ company.ticker }}</td>
                <td>{{ company.city }}</td>
                <td>{{ company.country }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="companiesNoResult">
      <div class="m-t-md col-sm-12">
        <div class="searchTableContainer text-center">
          <h4>{{ trans.trans.noCompaniesFound.value }}</h4>
        </div>
      </div>
    </div>

    <div *ngIf="detailedDescription" style="padding-top: 10px">
      <div *ngIf="showTopSection()" class="row">
        <div class="col-xs-3">
          <img onerror="this.src='/assets/images/missing_logo.jpg'" src="//logo.clearbit.com/{{ getHostnameFromRegex(companyDetails.url) }}" class="img-responsive" />
        </div>
        <div class="col-xs-3">
          <div class="row">
            <div class="col-md-4 account-label">{{ trans.trans.companyName.value }}</div>
            <div class="col-md-8 account-info">{{ companyDetails.name }}</div>
          </div>
          <div class="row">
            <div class="col-md-4 account-label">{{ trans.trans.companyAddress.value }}</div>
            <div class="col-md-8 account-info">{{ companyDetails.address1 }}</div>
          </div>
          <div class="row">
            <div class="col-md-4 account-label">{{ trans.trans.companySector.value }}</div>
            <div class="col-md-8 account-info">{{ companyDetails.dl_sector_name }}</div>
          </div>
          <div class="row">
            <div class="col-md-4 account-label">{{ trans.trans.companyWebsite.value }}</div>
            <div class="col-md-8 account-info">{{ companyDetails.url }}</div>
          </div>
          <div class="row">
            <div class="col-md-4 account-label">{{ trans.trans.companyRevenue.value }}</div>
            <div class="col-md-8 account-info">{{ companyDetails.revenue }}</div>
          </div>
          <div class="row">
            <div class="col-md-4 account-label">{{ trans.trans.companyEmployees.value }}</div>
            <div class="col-md-8 account-info">{{ companyDetails.employees }}</div>
          </div>
        </div>
        <div class="col-xs-6">
          <h3>{{ trans.trans.tags.value }}</h3>
          
          <div class="row" *ngIf="((style2022$ | async) && !can_edit) || !(style2022$ | async)">
            <div class="col-xs-12">
              <span *ngFor="let item of tags; let i = index" class="btn casestudy_tag">
                {{ item }}
                <i
                  *ngIf="can_edit"
                  (click)="removeTag(i)"
                  matTooltip="Remove Tag"
                  class="clickable link fa fa-times"
                  style="color: red; position: absolute; top: -10px; right: -10px"
                ></i>
              </span>
            </div>
          </div>

          <div class="row" *ngIf="can_edit">
            <div class="col-md-12">
              <div class="input-group">
                <span matTooltip="Previously used tags" class="input-group-addon btn_customizable left-select-addon" (click)="tagMenu.toggle($event)">
                  <i class="fa fa-chevron-down"></i>
                </span>
                <p-chips [(ngModel)]="tags" class="tag_menu"></p-chips>
              </div>
            </div>
          </div>

          <h3>{{ trans.trans.referenceUrl.value }}</h3>
          
          <div class="row">
            <div class="col-xs-6">
              <app-error-input-wrapper [showOnTouched]="true" [errorMessage]="errorDict.get('referenceUrlControl')">
                <input placeholder="https://www.value-cloud.com" type="text" class="form-control" [formControl]="referenceUrlControl" />
              </app-error-input-wrapper>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="showTopSection()" class="row" style="margin-top: 15px">
        <div class="col-xs-6">
          <p>{{ companyDetails.synopsis == '0' ? '' : companyDetails.synopsis }}</p>
        </div>
      </div>

      <div class="row" *ngIf="caseStudy">
        <div class="col-xs-12">
          <p-fieldset styleClass="casestudy_legend" class="casestudy_legend" legend="Testimonials" [toggleable]="true">
            <app-quote [canEdit]="can_edit" [caseStudyId]="case_study_id" [quotes]="caseStudy.quotes" (valueChange)="getCaseStudyDetail()"></app-quote>
          </p-fieldset>

          <p-fieldset styleClass="casestudy_legend" class="casestudy_legend" legend="Sections" [toggleable]="true">
            <app-sections [canEdit]="can_edit" [caseStudy]="caseStudy" type="solution" (valueChange)="getCaseStudyDetail()"></app-sections>
          </p-fieldset>

          <p-fieldset styleClass="casestudy_legend" class="casestudy_legend" legend="Benefits" [toggleable]="true">
            <app-benefits-casestudy [can_edit]="can_edit" [caseStudy]="caseStudy" type="solution" (valueChange)="getCaseStudyDetail()"></app-benefits-casestudy>
          </p-fieldset>
        </div>
      </div>
    </div>
  </div>
</div>
<app-case-study-sidebar (refreshCaseDetails)="closeSidebar()" [tags]="tags" [toggleEditCaseStudy]="toggleEditCaseStudy" [id]="caseStudy.id" [caseStudyEdit]="caseStudyEdit">
</app-case-study-sidebar>
