import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { CustomValidators } from './../_utils/custom-validators';

@Directive({
  selector: '[appSpecialCharacterValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SpecialCharacterValidatorDirective, multi: true }],
})
export class SpecialCharacterValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return CustomValidators.noSpecialCharacterValidator()(control);
  }
}
