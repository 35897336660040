import { Injectable } from '@angular/core';

@Injectable()
export class SurveyTemplateDefaultTranslations {
	public config: any = {
		component: 'survey-template',
		display_name: 'Survey Template',
		description: 'Survey templates in profile'
    }
    public trans: any = {
        back_to_profile: 'Back to Profile',
        survey_templates: 'Survey Templates',
        create_survey: 'Create Survey',
        name: 'Name',
        created_date: 'Template Created Date',
        status: 'Status',
        back: 'Back',
        active: 'Active',
        inactive: 'Inactive',
        settings: 'Settings',
        dashboard: 'Dashboard',
    }
}