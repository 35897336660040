<p-sidebar
  (onHide)="refreshCaseDetails.emit()"
  [(visible)]="toggleEditCaseStudy"
  *ngIf="caseStudyEdit"
  styleClass="keywest_sidebar p-sidebar-lg"
  position="right"
>
  <ng-container *ngIf="toggleEditCaseStudy">
    <form name="editCaseStudy" #editCaseStudy="ngForm">
      <div class="sidebar_wrapper">
        <div class="sidebar_header">{{ trans.trans.edit_case_study.value }}</div>
        <div class="sidebar_container">
          <div class="keywest_panel">
            <div class="row">
              <div class="col-md-12" appNGError [elemControl]="editCaseStudy.controls.name">
                <label class="sidebar_label">{{ trans.trans.name.value }} </label>
                <input type="text" name="name" [(ngModel)]="caseStudyEdit.name" class="form-control" ret-data="Name" required />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 tags-column">
                <label class="sidebar_label">{{ trans.trans.tags.value }}</label>
                <p-chips name="tags" ret-data="Tags" [(ngModel)]="tags" [ngModelOptions]="{ standalone: true }"></p-chips>
              </div>
            </div>
          </div>
        </div>

        <div class="btn_container">
          <div class="col-md-12">
            <app-btn-save [form]="editCaseStudy" (callback)="updateCaseDetails()" [text]="trans.trans.save.value" class="btn btn_customizable pull-right"></app-btn-save>
            <app-are-you-sure matTooltipPosition="above" [message]='trans.trans.are_you_sure.value' class="btn cancel-confirm pull-right marg-h" icon='' [text]='trans.trans.back.value' (callbackYes)='close()'></app-are-you-sure>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</p-sidebar>
