<div class="col-md-6 " style='margin-bottom: 10px;'>
	<div class='models_panel dashboard-card' style='height: 400px;'>
		<div class='models_page_header_container_small'>
			<div class='keywest_header'>
				<div class='row'>
					<div class='col-xs-6'>
						<div class='title'>{{trans.trans.value_prop_groups.value}} <app-editTranslation *ngIf="showTranslate" [component]="'vpg'" [isTranslationV2]="true"
							[transObj]="trans.trans.value_prop_groups" (callback)="getTranslations()"></app-editTranslation>
						</div>
					</div>
					<div class='col-xs-6'>
						<button *ngIf="dbI.showAddButtons" (click)="openAddVPG()" class="btn btn_customizable pull-right"><i class='fa fa-plus'></i>{{trans.trans.add.value}}</button>
					</div>
				</div>
			</div>
		</div>

		<div style='overflow-y: auto; max-height: 270px !important;'>
			<p-treeTable [value]="vpgList">
				<ng-template pTemplate="header">
					<tr>
						<th>{{trans.trans.name.value}} <app-editTranslation *ngIf="showTranslate" [component]="'vpg'" [isTranslationV2]="true"
							[transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation></th>
						<th>{{trans.trans.benefits.value}} <app-editTranslation *ngIf="showTranslate" [component]="'vpg'" [isTranslationV2]="true"
							[transObj]="trans.trans.benefits" (callback)="getTranslations()"></app-editTranslation></th>
						<th>{{trans.trans.costs.value}} <app-editTranslation *ngIf="showTranslate" [component]="'vpg'" [isTranslationV2]="true"
							[transObj]="trans.trans.costs" (callback)="getTranslations()"></app-editTranslation></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
					<tr>
						<td>
							<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
							<a *ngIf="rowData.parent" class="xf-vpg-label" routerLink='/vpg/{{rowData.id}}'>{{rowData.name}}</a>
							<a *ngIf="!rowData.parent" [routerLink]="commonService.getLinkVPRoute('valueprop', rowData.id, 4)" class='link'>{{rowData.name}}</a>
							<span *ngIf="rowData.parent">{{rowNode.children ? '(' + rowNode.children.length + ')' : ''}}</span>
						</td>
						<td>{{rowData.total_benefits_fmt}}</td>
						<td>{{rowData.total_costs_fmt}}</td>
					</tr>
				</ng-template>
			</p-treeTable>
		</div>
		<a routerLink="/vpg" class="keywest_seeMore pull-right" style='margin-top: 10px;'>{{trans.trans.see_more.value}}</a>
	</div>
</div>

<p-sidebar [(visible)]='toggleAdd' styleClass="keywest_sidebar p-sidebar-lg" position='right'>
	<form name="formAddVPG" #formAddVPG="ngForm">
		<div class='sidebar_wrapper'>
			<div class='sidebar_header'>
				{{trans.trans.create_value_prop.value}}
			</div>
			<div class='sidebar_container'>
				<div class='keywest_panel'>
					<div class="row">
						<div class="col-md-12" appNGError [elemControl]='formAddVPG.controls.name'>
							<label for="name" class="sidebar_label">{{trans.trans.name.value}}</label>
							<input type="text" class="form-control" ret-data='Name' [(ngModel)]="selectedElem.name" name="name" [placeholder]="trans.trans.name.value" required>
						</div>
					</div>
				</div>
			</div>
			<div class='btn_container'>
				<div class='row'>
					<div class="col-md-12">
						<app-btn-save [form]='formAddVPG' (callback)='funcPostGroup(formAddVPG)' text='Save' class='btn btn_customizable pull-right'></app-btn-save>
					</div>
				</div>
			</div>
		</div>
	</form>
</p-sidebar>