<div class="col-sm-12 solutionCosttab">
  <div class="pull-right m-b-xxl">
    <button class="btn btn_customizable" *ngIf="canEdit" (click)="openAddSolutionCost(addSolutionCostModal)" matTooltip="Add Cost" matTooltipPosition="above">
      <i class="fa fa-plus"></i> {{ trans.Cost.add_new | translate }}
    </button>
  </div>
  <div class="soln_table_cont">
    <div class="table-responsive col-sm-12 soln-table">
      <div class="searchTableContainer">
        <div class="">
          <table class="table custom-table">
            <thead>
              <tr>
                <th>{{ trans.Cost.line_item | translate }}</th>
                <th class="text-right">{{ trans.Cost.unit_price | translate }}</th>
                <th *ngIf="!readOnly"></th>
              </tr>
            </thead>

            <tbody *ngIf="loadSolutionCosts" class="col-12 text-center">
              <tr>
                <td colspan="3" style="border: 0px">
                  <img class="actionLoader" src="{{ fullImagePath }}" />
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="(costs == null || costs.length == 0) && !loadSolutionCosts" class="col-sm-12">
              <tr>
                <td colspan="3" style="border: 0px">
                  {{ trans.Cost.no_model_costs_found | translate }}
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="costs && costs !== null && costs.length > 0 && !loadSolutionCosts">
              <tr *ngFor="let cost of costs; let i = index" class="grid-row">
                <td>
                  {{ cost.name }}
                </td>
                <td class="text-right preventWordBreak">
                  {{ cost.cost }}
                </td>
                <td class="" *ngIf="!readOnly">
                  <div class="dropdown pull-right">
                    <span *ngIf="canEdit" class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                    <ul class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel">
                      <li (click)="editSolutionCosts(addSolutionCostModal, cost.id, i)" form="ngForm">
                        <i class="icon-pencil">{{ trans.Cost.edit | translate }}</i>
                      </li>
                      <li (click)="open(deleteSolutionCostModal, cost.id)"><i class="icon-trash"></i>{{ trans.Cost.delete | translate }}</li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #addSolutionCostModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ costType }} {{ trans.Cost.costs | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="AddSolutionCostsForm" #AddSolutionCostsForm="ngForm">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group col-md-9" [ngClass]="{ 'has-error': AddSolutionCostsForm.controls.name?.invalid && AddSolutionCostsForm.controls.name?.touched }">
              <label for="name" class="labelsRequired">{{ trans.Cost.add_new | translate }}</label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="name" placeholder="Enter Cost Type" required />
            </div>
            <div
              class="form-group col-md-9"
              [ngClass]="{ 'has-error': AddSolutionCostsForm.controls.costaccrualType?.invalid && AddSolutionCostsForm.controls.costaccrualType?.touched }"
            >
              <label for="alterEgo" class="labelsRequired">{{ trans.Cost.how_does_this_accrue | translate }}</label>
              <select class="form-control role-dropdown" [(ngModel)]="costaccrualType" name="costaccrualType" type="text" required>
                <option disabled="disabled">Choose One</option>
                <option *ngFor="let accrualType of accrualTypes" [value]="accrualType.id">
                  {{ accrualType.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-9" [ngClass]="{ 'has-error': AddSolutionCostsForm.controls.cost?.invalid && AddSolutionCostsForm.controls.cost?.touched }">
              <label for="name" class="labelsRequired">{{ trans.Cost.unit_price | translate }}</label>
              <input type="number" class="form-control" id="cost" name="cost" [(ngModel)]="cost" placeholder="Enter Unit Price" required />
            </div>
            <div class="form-group col-md-9" [ngClass]="{ 'has-error': AddSolutionCostsForm.controls.scaleFactor?.invalid && AddSolutionCostsForm.controls.scaleFactor?.touched }">
              <label class="labelsRequired">{{ trans.Cost.scale_factor | translate }}</label>

              <select class="form-control role-dropdown" [(ngModel)]="scaleFactor" name="scaleFactor" type="text" required>
                <option disabled="disabled">{{ trans.Cost.choose_one | translate }}</option>
                <option *ngFor="let scaleType of scaleTypes" [ngValue]="scaleType">
                  {{ scaleType.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3" *ngIf="scaleFactor">
              <label class="m-t-xxl">{{ scaleFactor.value }}</label>
            </div>
            <div class="form-group col-md-9">
              <label for="cost_category_type_id">
                {{ trans.Cost.driver_factor_optional | translate }}
              </label>
              <select class="form-control role-dropdown" [(ngModel)]="driverFactorId" name="driver_factor_id" type="text">
                <option disabled="disabled">{{ trans.Cost.choose_one | translate }}</option>
                <option *ngFor="let driver of drivers" [value]="driver.driver_factor_id">
                  {{ driver.driver_factor }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-9">
              <label for="expense_type_id">{{ trans.Cost.cost_answer_baseline | translate }}</label>
              <input type="text" class="form-control" name="cost_baseline_answer" [(ngModel)]="costBaselineAnswer" placeholder="Enter Cost Answer Baseline" required />
            </div>
            <div class="form-group col-md-9">
              <label for="cost_category_type_id">
                {{ trans.Cost.cost_category_optional | translate }}
                <i class="icon-info tooltip-custom" matTooltip="{{ trans.models_contextual_help.help_assumptions | translate }}" matTooltipPosition="right"></i>
              </label>
              <select class="form-control role-dropdown" [(ngModel)]="costCategoryTypeId" name="cost_category_type_id" type="text">
                <option disabled="disabled">{{ trans.Cost.choose_one | translate }}</option>
                <option *ngFor="let costCategoryType of costCategoryTypes" [value]="costCategoryType.id">
                  {{ costCategoryType.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <div class="btnInline m-t-xxl">
                <a href="javascript:;" class="driverFactor" (click)="openAddCostCategory(addCostCategoryModal)">
                  <span class="icon-plus"></span>
                </a>
              </div>
            </div>
            <div class="form-group col-md-9">
              <label for="expense_type_id">{{ trans.Cost.expense_type | translate }}</label>
              <select class="form-control role-dropdown" [(ngModel)]="expenseTypeId" name="expense_type_id" type="text">
                <option disabled="disabled">{{ trans.Cost.choose_one | translate }}</option>
                <option *ngFor="let expenseType of expenseTypes" [value]="expenseType.id">
                  {{ expenseType.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-9" *ngIf="situationsTied.length > 0">
              <label for="">{{ trans.Cost.situations | translate }}</label>
              <ul>
                <li *ngFor="let situation of situationsTied">
                  {{ situation.name }}
                </li>
              </ul>
            </div>

            <div class="form-group col-md-12" *ngIf="hasOurCostFeature">
              <div class="checkbox custom-checkbox">
                <fieldset class="checkbox custom-checkbox">
                  <label>
                    Revenue to Us
                    <input type="checkbox" name="revenueToUs" [(ngModel)]="revenueToUs" />
                    <span class="check"></span>
                  </label>
                </fieldset>
              </div>
            </div>

            <div class="form-group col-md-12">
              <label for=""></label>
              <div class="checkbox custom-checkbox">
                <label>
                  {{ trans.Cost.include_this_cost_by_default_in_valueprops | translate }}
                  <input type="checkbox" name="costInValueprop" [(ngModel)]="costInValueprop" (change)="costInValueprop == 0 ? (costInValueprop = 0) : (costInValueprop = 1)" />
                  <span class="check"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-sm-9">
                <button type="submit" class="btn btn_customizable" (click)="SaveCosts(AddSolutionCostsForm)" [disabled]="AddSolutionCostsForm.invalid">
                  {{ trans.General.save | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="pull-right">
            <label class="labelsRequiredInfo">{{ trans.Cost.is_required | translate }}</label>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #deleteSolutionCostModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.Cost.delete_solution_cost | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ trans.Cost.are_you_sure_you_want_to_remove_this_solution_cost | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn_customizable" (click)="deleteSolutionCost()">{{ trans.General.Yes | translate }}</button>
      <button type="button" class="btn btn-light marg-h" (click)="c('Close click')">{{ trans.General.No | translate }}</button>
      <img class="actionLoader" [hidden]="hideActionLoader" src="{{ fullImagePath }}" />
    </div>
  </div>
</ng-template>
<ng-template #addCostCategoryModal let-c="close" let-d="dismiss">
  <div [ngClass]="{ 'style-2022': style2022$ | async }">
    <div class="modal-header">
      <h4 class="modal-title">{{ trans.Cost.add_cost_category | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="addNewCostCategoryForm" #addNewCostCategoryForm="ngForm">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group" [ngClass]="{ 'has-error': addNewCostCategoryForm.controls.newCategory?.invalid && addNewCostCategoryForm.controls.newCategory?.touched }">
              <label for="benefitsName" class="benefitsName labelsRequired">{{ trans.Cost.category_name | translate }}</label>
              <p-dropdown appendTo="body"
                class="keywest_dropdown"
                [options]="factCostCategoryTypes"
                name="newCostCategory"
                [(ngModel)]="newCostCategory"
                placeholder="Select Cost Category"
                [panelStyleClass]="(style2022$ | async) ? 'dropdown-item-font' : ''"
              ></p-dropdown>
            </div>
          </div>
        </div>

        <button type="button" class="btn btn_customizable marg-h m-t-xs m-b-xs" (click)="addNewCostCategory(); c('Close click')" [disabled]="addNewCostCategoryForm.invalid">
          {{ trans.Cost.add | translate }}
        </button>

        <button type="button" class="btn btn-light m-t-xs m-b-xs" (click)="c('Close click')">{{ trans.Cost.cancel | translate }}</button>
      </form>
    </div>
  </div>
</ng-template>
