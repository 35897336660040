import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';
import { ConfigurableWorkflow, Conversation, WorkflowData, WorkflowDataResponse } from '@data/services/workflow/models/workflow.interface';
import { APIResult } from '@shared/models/api-result.model';
import { PTableColumn } from '@shared/models/p-table.model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ConfigurableWorkFlowsTranslations } from '../configurable-workflows.translation';

@Component({
  selector: 'app-create-workflow',
  templateUrl: './create-workflow.component.html',
  styleUrls: ['./create-workflow.component.scss'],
})
export class CreateWorkflowComponent implements OnInit, OnDestroy {
  @Input() sidebarOpen: boolean;
  @Input() trans: ConfigurableWorkFlowsTranslations;
  @Input() workflowSelected: ConfigurableWorkflow;
  @Input() workflowTitle: string;

  @Output() onSaveEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() sidebarClose: EventEmitter<boolean> = new EventEmitter();

  public createWorkflowForm: FormGroup;
  public cols: PTableColumn[];
  public workflowUserGroups: WorkflowData[];
  public workflowAssets: WorkflowData[];
  public workflowSections: WorkflowData[];
  public selectedSections: WorkflowData[] = [];
  public selectedWorkflowSections: WorkflowData[];
  public workflowSubTitle: string;
  public default_member_id: string = null;

  private end$: Subject<any> = new Subject();

  constructor(private fb: FormBuilder, private workflowService: ConfigurableWorkflowService) {
    this.createWorkflowForm = this.fb.group({
      workflowName: [''],
      status: [false],
      workflowIcon: [''],
      selectedUserGroups: [''],
      description: [''],
      selectedAssets: [''],
      allUsers: [false],
      allTemplates: [false],
      sectionChecked: [[]],
    });
  }

  get workflowName(): AbstractControl {
    return this.createWorkflowForm.get('workflowName');
  }
  get status(): AbstractControl {
    return this.createWorkflowForm.get('status');
  }
  get workflowIcon(): AbstractControl {
    return this.createWorkflowForm.get('workflowIcon');
  }
  get selectedUserGroups(): AbstractControl {
    return this.createWorkflowForm.get('selectedUserGroups');
  }
  get description(): AbstractControl {
    return this.createWorkflowForm.get('description');
  }
  get selectedAssets(): AbstractControl {
    return this.createWorkflowForm.get('selectedAssets');
  }
  get sectionChecked(): AbstractControl {
    return this.createWorkflowForm.get('sectionChecked');
  }
  get allTemplates(): AbstractControl {
    return this.createWorkflowForm.get('allTemplates');
  }
  get allUsers(): AbstractControl {
    return this.createWorkflowForm.get('allUsers');
  }

  ngOnInit(): void {
    if (this.workflowSelected && this.workflowSelected.id) {
      const { system_defined } = this.workflowSelected;
      this.workflowSubTitle = system_defined === '1' ? this.trans.trans.systemDefined.value : null;

      this.workflowService
        .getAccountWorkflowSections(this.workflowSelected.id)
        .pipe(takeUntil(this.end$))
        .subscribe((res: any) => {
          const { name, active, description, all_users, all_templates } = this.workflowSelected;

          this.selectedWorkflowSections = res;
          this.createWorkflowForm.patchValue({
            workflowName: name,
            status: active,
            allUsers: all_users === '1' ? true : false,
            allTemplates: all_templates === '1' ? true : false,
            workflowIcon: '',
            selectedUserGroups: res[res.findIndex((r) => r.sequence === '1')].members.map((d) => this.normalizeWorkflowData(d)),
            description: description,
            selectedAssets: res[res.findIndex((r) => r.sequence === '3')].members.map((d) => this.normalizeWorkflowData(d)),
          });

          this.default_member_id = res[res.findIndex((r) => r.sequence === '2')].default_member_id;
          this.selectedSections = res[res.findIndex((r) => r.sequence === '2')].members.map((d) => this.normalizeWorkflowData(d, true));
          
          this.getWorkflowData(7);
          this.getWorkflowData(8);
          this.getWorkflowData(9);
        });

      if (+system_defined) {
        this.workflowName.disable();
        this.description.disable();
      }

    } else {
      this.getWorkflowData(7);
      this.getWorkflowData(8);
      this.getWorkflowData(9);
    }

    this.cols = [
      { field: 'member_id', header: this.trans.trans.position.value },
      { field: 'name', header: this.trans.trans.standardStep.value },
      { field: 'name', header: this.trans.trans.stepName.value },
      { field: 'description', header: this.trans.trans.stepDescription.value },
    ];
  }

  ngOnDestroy(): void {
    this.end$.complete();
    this.end$.next();
  }

  getWorkflowData(set: number) {
    this.workflowService
      .getWorkflowData(set)
      .pipe(takeUntil(this.end$))
      .subscribe((res) => {
        switch (set) {
          case 7:
            this.workflowUserGroups = res.result.workflow_data.map((d) => this.normalizeWorkflowData(d));
            break;
          case 8:
            this.workflowAssets = res.result.workflow_data.map((d) => this.normalizeWorkflowData(d));
            break;
          case 9:
            this.workflowSections = res.result.workflow_data.map((d) => this.normalizeWorkflowData(d, true));

            if ( this.selectedSections.length == 0 ) {
              // This is a new workflow, make sure step 1 is selected
              this.selectedSections.push(this.workflowSections[0]);
            }
            break;
          default:
            break;
        }
      });
  }

  normalizeWorkflowData(workflowData: WorkflowData, checkDefault: boolean = false) {
    if ( checkDefault ) {
      
      return {
        description: workflowData.description,
        member_id: +workflowData.member_id,
        name: workflowData.name,
        is_default_member: workflowData.member_id == this.default_member_id
      };
    } else {

      return {
        description: workflowData.description,
        member_id: +workflowData.member_id,
        name: workflowData.name,
      };
    }
  }

  onCancelClick() {
    this.onHide();
  }

  onButtonClick() {
    const body: ConfigurableWorkflow = {
      name: this.workflowName.value,
      description: this.description.value,
      sequence: this.workflowSelected ? this.workflowSelected.sequence : '0',
      active: this.status.value ? '1' : '0',
      workflow_type_id: '2',
      icon: this.workflowIcon.value,
      all_users: this.allUsers.value ? '1' : '0',
      all_templates: this.allTemplates.value ? '1' : '0',
      workflow_sections: [
        {
          name: 'User Groups',
          description: '',
          sequence: '1',
          element_type_id: '4',
          workflow_data_source_id: '7',
          active: '1',
          members: this.allUsers.value
            ? ([...this.workflowUserGroups.map((w) => w.member_id)] as number[])
            : ([...new Set(this.selectedUserGroups.value.map((group) => parseInt(group.member_id)))] as number[]),
        },
        {
          name: 'Asset Templates',
          description: '',
          sequence: '3',
          element_type_id: '4',
          workflow_data_source_id: '8',
          active: '1',
          members: this.allTemplates.value
            ? ([...this.workflowAssets.map((w) => w.member_id)] as number[])
            : ([...new Set(this.selectedAssets.value.map((asset) => parseInt(asset.member_id)))] as number[]), // what are these?
        },
        {
          name: 'Conversations',
          description: '',
          sequence: '2',
          element_type_id: '2',
          default_member_id: this.default_member_id,
          workflow_data_source_id: '9',
          active: '1',
          members: [...new Set(this.selectedSections.map((section) => section.member_id))] as number[], // what are these?
        },
      ],
    };

    if (this.workflowSelected) {
      body.id = this.workflowSelected.id;
      this.selectedWorkflowSections.forEach((section, i) => {
        const index = this.selectedWorkflowSections.findIndex((s) => s.sequence === section.sequence);
        body.workflow_sections[index].id = section.id;
      });

      this.workflowService
        .updateWorkflow(body)
        .pipe(take(1))
        .subscribe((res) => {
          this.onHide();
          this.onSave();
        });
    } else {
      this.workflowService
        .createWorkflow(body)
        .pipe(take(1))
        .subscribe((res) => {
          this.onHide();
          this.onSave();
        });
    }
  }

  radioDisabled(member_id) {
    let retval = '';

    this.selectedSections.forEach(section => {
      if ( section.member_id == member_id ) {
        retval = null;
      }
    });

    return retval;
  }

  radioClicked(member_id) {
    this.workflowSections.forEach(section => {
      if ( section.member_id == member_id ) {
        this.default_member_id = member_id;
        section.is_default_member = true;
      } else {
        section.is_default_member = false;
      }
    });
  }

  onSave() {
    this.onSaveEmitter.emit();
  }

  onHide() {
    this.sidebarClose.emit(false);
  }
}
