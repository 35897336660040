import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { ValuerealizationService } from '@data/services/valuerealization/valuerealization.service';
import { extractInitials } from '@shared/utils';
import { ValueTrackerTableTranslation } from 'app/value-trackers/trackers_container/trackers-container.translation';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-trackers',
  templateUrl: './trackers.component.html',
  styleUrls: ['./trackers.component.scss']
})
export class TrackersComponent implements OnInit {
  ngUnsubscribe = new Subject();
  showTranslate = false;
  realizations = [];
  strSearch = '';
  loading = false;
  tableRecords$: Observable<number>;
  currentTableRecords$ = new Subject<number>();
  columns = [
    { header: this.trans.trans.name, field: 'value_prop_name' },
    { header: this.trans.trans.company_name, field: 'company_name' },
    { header: this.trans.trans.assigned_by, field: 'assigned_by' },
  ];
  constructor( 
    public trans: ValueTrackerTableTranslation,
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    private router: Router,
    private valuerealizationService: ValuerealizationService,
    ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getValueRealization();
  }

  private getValueRealization() {
    this.loading = true;

    this.valuerealizationService
    .getAllValueRealizationsV2()
      .pipe(
        takeUntil(this.ngUnsubscribe), 
        finalize(() => this.loading = false))
      .subscribe((response) => {
        this.realizations = response.result;
        this.currentTableRecords$.next(this.realizations.length);
      });
  }

  public getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  public getInitials(name: string): string {
    return extractInitials(name);
  }

  public navToDetail(realization) {
    this.router.navigate(['trackers/' + realization.value_prop_id]);
  }
}
