import { Injectable } from '@angular/core';

@Injectable ()

export class AssetPrompterVpDashTranslations {

    public config: any = {
        component: 'assetPrompterVpDash',
        display_name: 'Asset Value Prompter for Value Prop',
        description: 'Asset value prompter in value prop dashboard'
    };

    public trans: any = {
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        value_prompters: 'Value Prompter',
		file: 'File',
		name: 'Name',
		description: 'Description',
		no_prompters_available: 'No Prompters Available',
		upload: 'Upload'
    };
}
