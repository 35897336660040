<div *ngIf="!showCaseStudiesList" class="row">
  <div class="col-sm-8 caseStudiestab">
    <form name="caseStudiesSearchForm" #caseStudiesSearchForm="ngForm" (ngSubmit)="searchCaseStudies()">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-btn">
            <input
              type="text"
              class="form-control marg-h"
              name="caseStudiesName"
              id="caseStudiesName"
              placeholder="Search Case Studies by Tag"
              [(ngModel)]="caseStudiesName"
              style="width: 300px"
            />
            <button type="submit" class="btn btn_customizable search-btn" matTooltip="Search" matTooltipPosition="below">Search</button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-sm-4">
    <div class="pull-right m-b-xxl">
      <button *ngIf="canEdit" class="btn btn_customizable" (click)="openAddCaseStudy()" matTooltip="Add new" matTooltipPosition="above"><i class="fa fa-plus"></i> Add New</button>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4">
      <div class="input-group keywest_search">
        <span class="input-group-addon"><i class="fa fa-search"></i></span>
        <input (keyup)="caseStudiesTable.filter($any($event).target.value, 'name', 'contains')" [(ngModel)]="searchText" type="text" placeholder="Search" class="form-control" />
        <span *ngIf="searchText !== ''" (click)="searchText = ''; caseStudiesTable.filter('', 'name', 'contains')" class="input-group-addon clickable"
          ><i class="fa fa-times"></i
        ></span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <p-table #caseStudiesTable [value]="caseStudiesList" [columns]="columns" [paginator]="true" [rows]="10" styleClass="keywest_table" tableStyleClass="keywest_table">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
            </th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-caseStudy>
          <tr class="grid-row company-row">
            <td style="width: 100%">
              <a role="button" (click)="navigateCaseStudy(caseStudy)" class="link">{{ caseStudy.name }}</a>
            </td>
            <td>
              <div *ngIf="canEdit" class="dropdown" container="body" placement="right">
                <span class="glyphicon glyphicon-option-horizontal dot-icon-grid dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"></span>
                <ul class="dropdown-menu dropdown-menu-left" role="menu" placement="right" aria-labelledby="dLabel">
                  <li class="dropdown-menu-list" (click)="downloadAsset(caseStudy)"><i class="icon-cloud-download"></i> Download</li>

                  <li class="dropdown-menu-list" (click)="deleteCaseStudy(caseStudy.id)"><i class="icon-trash"></i> Delete</li>

                  <li class="dropdown-menu-list" (click)="openModal(modalList, caseStudy)"><i class="icon-docs"></i> Clone to Another Model</li>
                  <li class="dropdown-menu-list" (click)="openModal(valuepropList, caseStudy)"><i class="icon-docs"></i> Assign to ValueProp</li>
                </ul>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<div class="clearfix"></div>

<div class="detail-page" *ngIf="showCaseStudiesList">
  <div (click)="funcNavBack()" role="button" class="a-link-color m-b-xxl m-l-xxl"><i class="fa fa-chevron-left"></i> Back</div>
  <app-casestudy-detail2 [caseStudyId]="caseStudyId" [type]="'solution'" [myCaseStudy]="caseStudy"></app-casestudy-detail2>
</div>

<ng-template #modalList let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'menu.model' | translate }}
      <span *ngIf="cloneRename && solutionSelected"> - {{ solutionSelected.name }}</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!cloneRename">
      <div class="form-group col-md-7">
        <input type="text" class="form-control" placeholder="Search" name="searchText" [(ngModel)]="searchText" />
      </div>
      <div class="clearfix"></div>
      <div class="form-group m-l-xxl">
        <ng-container *ngFor="let solution of solutions | filter: searchText: null">
          <fieldset class="radio-control">
            <label>
              <input type="radio" name="solution" [value]="solution" [(ngModel)]="solutionSelected" />&nbsp;&nbsp;
              <span>{{ solution.name }}</span>
            </label>
          </fieldset>
        </ng-container>
      </div>
      <div class="pull-right">
        <button type="button" class="btn btn_customizable m-t-xs m-b-xs" (click)="cloneModel()" [disabled]="!solutionSelected">{{ 'general.clone' | translate }}</button>
        <button type="button" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="c('Close click')">{{ 'general.Cancel' | translate }}</button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div *ngIf="cloneRename">
      <div class="form-group">
        <input type="text" class="form-control" name="newCaseStudyName" [(ngModel)]="newCaseStudyName" />
      </div>
      <div class="pull-right">
        <button type="button" class="btn btn_customizable m-t-xs m-b-xs" (click)="c('Close click'); saveModel()" [disabled]="!solutionSelected">
          {{ 'general.save' | translate }}
        </button>
        <button type="button" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="cloneRename = !cloneRename">{{ 'general.Cancel' | translate }}</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</ng-template>

<ng-template #valuepropList let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      <strong>Value Props</strong>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label><strong>Value Props</strong></label>
      <select class="form-control" [(ngModel)]="valuePropId">
        <option *ngFor="let vp of valueProps" [value]="vp.id">{{ vp.name }}</option>
      </select>
    </div>
    <div class="pull-right">
      <button type="button" class="btn btn_customizable m-t-xs m-b-xs" (click)="c('Close click'); updatecaseStudy()" [disabled]="!valuePropId">
        {{ 'general.save' | translate }}
      </button>
      <button type="button" class="btn btn-light marg-h m-t-xs m-b-xs" (click)="c('Close click')">{{ 'general.Cancel' | translate }}</button>
    </div>
    <div class="clearfix"></div>
  </div>
</ng-template>

<p-sidebar [(visible)]="showAddCase" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="closeSidebar()">
  <app-add-casestudy [solutionId]="solutionId" [contextualHelp]="contextualHelp" (createCaseStudy)="closeSidebar()"></app-add-casestudy>
</p-sidebar>
