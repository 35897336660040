import { Injectable } from '@angular/core';

@Injectable()
export class ModelV2AEPainPointTranslations {
  public config: any = {
    component: 'ae_painpoints',
    display_name: 'Pain Points Add/Edit',
    description: 'Add/Edit Pain Points '
  };

  public trans: any = {
    add_painpoint: 'Add Pain Point',
    edit_painpoint: 'Edit Pain Point',
    name: 'Name',
    description: 'Description',
    save: 'Save',
    update: 'Update',
    painpoint_name: 'Pain Point Name',
    are_you_sure: 'Are you sure?',
    back: 'Back',
    successUpdate: 'Pain Point updated successfully.',
    successCreate: 'Pain Point created successfully.',
    tagMustBeUnique: 'Tags must be unique.',
    createCustomAssetTag: 'Create custom asset tag for this Pain Point?',
    ppTag: 'Pain Point Tag',
    value: 'value',
    valueTagTooltip:
      'To change the value tag to display zero decimal places, all decimal places, or shortened (7,500 to 7.5k or 7,500,000 to 7.5M) add _p2, _fr, or _fmt, respectively. Add this suffix to the end of the value tag to make this change. Additionally, use the _abbr suffix to add a supported unit type abbreviation',
    ppTagWarning: 'Ensure that you have removed this pain point tag from your assets to avoid disruption.',
  };
}
