
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { timer as timerRxjs } from "rxjs";
import { StyleService } from 'app/style.service';

@Component({
	selector: 'app-table-chart',
	templateUrl: './tablechart.component.html',
	styleUrls: ["./tablechart.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class TableChartComponent implements OnInit {

	@Input() title: string = ""; // panel title
	@Input() onLabel: string = "Chart"; // chart? pie? line? bar?
	@Input() class;
	@Input() condensed: boolean = false;

	@Input() system_chart_colors: any[] = [];
	medium_chart_colors: any[] = ['#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784', '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d', '#ff8a65', '#a1887f', '#e0e0e0', '#90a4ae'];
	@Input() col: string = "col-md-6 col-sm-12 col-xs-12";

	@Input() cols: any;
	@Input() data: any[] = [];
	@Input() selectedRows: any;
	@Input() toggleable: boolean = false;

	@Input() chartType: any = "";
	@Input() chartData: any[] = [];

	@Input() identifier: string = "";

	@Input() legendPosition: string = "top";


	@Output() callbackExpand = new EventEmitter();
	expand: boolean = false;
	toggle: boolean = true;
	header_color: string;

	reload: boolean = false;

	LSSettings: TableChartLSObject = new TableChartLSObject;

	options: any;

	chartTypes: any[] = [
		{ label: "Pie", value: "pie" },
		{ label: "Bar", value: "bar" },
		{ label: "Horizontal Bar", value: "horizontalBar" },
		{ label: "Line", value: "line" },
		{ label: "Polar Area", value: "polarArea" },
		{ label: "Doughnut", value: "doughnut" },
		{ label: "Radar", value: "radar" },

	]
	myBarChart: any;


	subscription: Subscription;

	style2022$: Observable<boolean>;

	constructor(private styleService: StyleService) {
	}

	ngOnInit(): void {
		this.style2022$ = this.styleService.style2022;
		if (!this.system_chart_colors.length) {
			this.system_chart_colors = this.medium_chart_colors;
		}


		if (localStorage.getItem(this.identifier)) {
			this.LSSettings = JSON.parse(localStorage.getItem(this.identifier));

			this.expand = this.LSSettings.expand;
			this.chartType = this.LSSettings.chart;
			this.toggle = this.LSSettings.toggle;
		} else {
			this.LSSettings.expand = this.expand;
			this.LSSettings.chart = this.chartType;
			this.LSSettings.toggle = this.toggle;
		}


		if (!this.chartType) { this.toggle = !this.toggle };
		this.header_color = localStorage.getItem("header_color");

		this.options = {};

		this.funcUpdateChartColors();
		this.funcUpdateGrid();

		this.options.legend = {
			display: true,
			labels: {
				fontSize: 10
			},
			position: this.legendPosition
		}
		this.options.tooltips = {

			callbacks: {
				label: function (tooltipItem, data) {
					let label = data.datasets[tooltipItem.datasetIndex].label || '';

					if (label) {
						label += ': ';
					}
					if (data.datasets[tooltipItem.datasetIndex].data_fmt) {
						label += data.datasets[tooltipItem.datasetIndex].data_fmt[tooltipItem.index];
					} else {
						label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					}
					return label;
				}

			}
		}
	}

	funcUpdateChartColors() {
		if (!this.chartData) { return; }


		if (this.chartData && this.chartData['datasets'] && this.chartData['datasets'].length > 1) {
			let count = 0;
			for (let i = 0; i < this.chartData['datasets'].length; i++) {
				this.chartData['datasets'][i].backgroundColor = this.system_chart_colors[count];
				count++;
				if (count > this.system_chart_colors.length) { count = 0; }
			}
		} else {
			if (this.chartData['datasets'] && this.chartData['datasets'][0]) { this.chartData['datasets'][0].backgroundColor = this.system_chart_colors; }
		}
	}

	funcUpdateGrid() {
		if (['bar', 'horizontalBar', 'line'].indexOf(this.chartType) >= 0) {
			this.options.scales = {
				yAxes: [{
					ticks: {
						beginAtZero: true,
						callback: function (value) {
							let number = value;
							if (typeof number === 'string') { return number; }
							let numberLength = number.toString().length;
							let unit = '';

							if (numberLength >= 4 && numberLength <= 6) {
								unit = 'K';
							}
							else if (numberLength >= 7 && numberLength <= 9) {
								unit = 'M';
							}
							else if (numberLength >= 10 && numberLength <= 13) {
								unit = 'B';
							}
							else if (numberLength >= 14 && numberLength <= 17) {
								unit = 'T';
							}
							else if (numberLength >= 18 && numberLength <= 21) {
								unit = 'Quad';
							}
							else if (numberLength >= 22 && numberLength <= 25) {
								unit = 'Quin';
							}

							switch (unit) {
								case 'K':
									return Math.round((number / 1000)) + unit;
								case 'M':
									return Math.round((number / 1000000)) + unit;
								case 'B':
									return Math.round((number / 1000000000)) + unit;
								case 'T':
									return Math.round((number / 1000000000000)) + unit;
								case 'Quan':
									return Math.round((number / 1000000000000000)) + unit;
								case 'Quin':
									return Math.round((number / 1000000000000000000)) + unit;
								default:
									return number;
							}
						}
					}
				}],
				xAxes: [{
					ticks: {
						beginAtZero: true,
						callback: function (value) {
							let number = value;
							if (typeof number === 'string') { return number; }
							let numberLength = number.toString().length;
							let unit = '';

							if (numberLength >= 4 && numberLength <= 6) {
								unit = 'K';
							}
							else if (numberLength >= 7 && numberLength <= 9) {
								unit = 'M';
							}
							else if (numberLength >= 10 && numberLength <= 13) {
								unit = 'B';
							}
							else if (numberLength >= 14 && numberLength <= 17) {
								unit = 'T';
							}
							else if (numberLength >= 18 && numberLength <= 21) {
								unit = 'Quad';
							}
							else if (numberLength >= 22 && numberLength <= 25) {
								unit = 'Quin';
							}

							switch (unit) {
								case 'K':
									return Math.round((number / 1000)) + unit;
								case 'M':
									return Math.round((number / 1000000)) + unit;
								case 'B':
									return Math.round((number / 1000000000)) + unit;
								case 'T':
									return Math.round((number / 1000000000000)) + unit;
								case 'Quan':
									return Math.round((number / 1000000000000000)) + unit;
								case 'Quin':
									return Math.round((number / 1000000000000000000)) + unit;
								default:
									return number;
							}
						}
					}
				}]
			}
		} else {
			this.options.scales = {};
		};
	}

	funcUpdateLS() {
		localStorage.setItem(this.identifier, JSON.stringify(this.LSSettings));
	}

	funcDropdownChanged() {
		this.LSSettings.chart = this.chartType;
		this.funcUpdateLS();

		this.reload = true;


		let timer = timerRxjs(500, 500);
		this.subscription = timer.subscribe(() => {
			this.reload = false;
			this.funcUpdateGrid();
			this.funcUpdateChartColors();

			this.subscription.unsubscribe();
		});
	}

	funcToggleExpand() {
		this.expand = !this.expand;
		this.LSSettings.expand = this.expand;
		this.funcUpdateLS();
	}

	funcChartToggle() {
		this.LSSettings.toggle = this.toggle;
		this.funcUpdateLS();
	}
}

export class TableChartLSObject {
	chart: string;
	toggle: boolean;
	expand: boolean;

}