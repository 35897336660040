import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnitTypeTranslations } from './unit-type.translation';

@Component({
  selector: 'app-unit-type',
  templateUrl: './unit-type.component.html',
  styleUrls: ['./unit-type.component.scss'],
})
export class UnitTypeComponent implements OnInit, OnDestroy {
  @Input() unitType: string;
  @Input() showAbbrivations = false;

  ngUnsubscribe = new Subject();
  showTranslate = false;
  unitTypes = [
    '',
    'currency',
    'currencyThousands',
    'currencyMillions',
    'currencyBillions',
    'thousands',
    'millions',
    'billions',
    'seconds',
    'minutes',
    'hours',
    'days',
    'months',
    'years',
    'percent',
    'basisPoints',
    'miles',
    'number',
    'weeks',
  ];

  unitTypesAbbreviated = [
    '',
    'currencyAbbr',
    'currencyThousandsAbbr',
    'currencyMillionsAbbr',
    'currencyBillionsAbbr',
    'thousandsAbbr',
    'millionsAbbr',
    'billionsAbbr',
    'secondsAbbr',
    'minutesAbbr',
    'hoursAbbr',
    'daysAbbr',
    'monthsAbbr',
    'yearsAbbr',
    'percentAbbr',
    'basisPointsAbbr',
    'milesAbbr',
    'numberAbbr',
    'weeksAbbr',
  ];

  constructor(private commonService: CommonService, public trans: UnitTypeTranslations, private translationService: TranslationsV2Service) {}

  ngOnInit(): void {
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }
}
