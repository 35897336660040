import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@data/services/user/user.service';
import { AlertService } from '@services/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { ForgotPasswordTranslations } from './forgot-password.translation';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  showActionLoader: boolean = false;
  fullImagePath: string = '';
  currYear = new Date().getFullYear();

  ngUnsubscribe = new Subject();
  showTranslate = false;
  emailSuccess = false;

  passwordForm: FormGroup;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    public translate: TranslateService,
    private translationService: TranslationsV2Service,
    private commonService: CommonService,
    public trans: ForgotPasswordTranslations,
    private fb: FormBuilder
  ) {
    this.fullImagePath = 'https://media.value-cloud.com/images/jamaica/reload.gif';
  }

  ngOnInit() {
    this.passwordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.alertService.redirectLogin = true;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
         this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  Translate() {
    this.translate.setDefaultLang('en_US');
    if (sessionStorage.getItem('lang_file')) {
      this.translate.use(sessionStorage.getItem('lang_file'));
    } else {
      if (sessionStorage.getItem('user_lang') !== 'false' && sessionStorage.getItem('user_lang') !== null) {
        this.translate.use(sessionStorage.getItem('user_lang'));
      }
    }
  }

  submit() {
    this.showActionLoader = true;
    let postData = {
      email: this.passwordForm.get('email').value,
    };
    this.userService.forgotPwdReq(postData).subscribe(
      () => {
        this.showActionLoader = false;
        this.emailSuccess = true;
      },
      (error) => {
        this.alertService.error(error);
        this.showActionLoader = false;
      }
    );
  }

  showLogin() {
    this.alertService.notifyShowLogin();
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }
}
