<div [class.row]="!(style2022$ | async)" class="row-maui-no-margin">
  <div class="col-md-6 col-60-maui">
    <div class="models_panel" style="margin-bottom: 20px" *ngIf="user">
      <div class="models_page_header_container_small">
        <div class="keywest_header old-style-only">
          <div class="row">
            <div class="col-xs-12">
              <div class="title">
                {{ trans.trans.heading.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.heading"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div [class.text-center]="!(style2022$ | async)" class="profileImage padding-12-x ml-4 row-maui-no-margin">
            <img class="user-profile-image img-circle detail-profile-image" [src]="userProfileImage" />
            <p *ngIf="!(style2022$ | async)">
              <a role="button" (click)="uploadInput.click()">{{ trans.trans.updateProfileIcon.value }} </a>
              <img *ngIf="actionLoader" class="actionLoader" src="{{ fullImagePath }}" width="30px" />
            </p>
            <div *ngIf="style2022$ | async">
              <i (click)="uploadInput.click()" *ngIf="!actionLoader" style="font-size: 14px" class="keywest_header_icon fa fa-pencil pull-right clickable edit-profile-icon"></i>
            </div>
            <input style="display: none" type="file" class="form-control" name="profile_image" accept="image/*" (change)="handleInputChange($event)" #uploadInput />
          </div>
        </div>
        <div class="row-maui-no-margin">
          <div [class.row]="!(style2022$ | async)" class="col-50-maui" style="margin-bottom: 20px">
            <div class="col-xs-3 form-label">
              {{ trans.trans.name.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.name"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </div>
            <div class="col-xs-9 w-100">
              <input *ngIf="style2022$ | async" class="form-control" type="text" readonly value="{{ user.first_name + ' ' + user.last_name }}" />{{
                (style2022$ | async) ? '' : user.first_name + ' ' + user.last_name
              }}
            </div>
          </div>

          <div [class.row]="!(style2022$ | async)" class="col-50-maui" style="margin-bottom: 20px">
            <div class="col-xs-3 form-label">
              {{ trans.trans.role.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.role"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </div>
            <div class="col-xs-9 w-100">
              <input *ngIf="style2022$ | async" class="form-control" type="text" readonly value="{{ user.role_name }}" />{{ (style2022$ | async) ? '' : user.role_name }}
            </div>
          </div>
        </div>
        <form name="profileForm" #profileForm="ngForm" *ngIf="!isGuest">
          <div class="row-maui-no-margin">
            <div [class.row]="!(style2022$ | async)" class="col-50-maui" style="margin-bottom: 20px">
              <div class="col-xs-3 form-label">
                {{ trans.trans.location.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.location"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
              <div class="col-xs-9 w-100">
                <input
                  *ngIf="style2022$ | async"
                  class="form-control"
                  type="text"
                  readonly
                  value="{{ (user.city && user.city != 'false' ? user.city : '') + ' ' + (user.state && user.state != 'false' ? user.state : '') }}"
                />{{ (style2022$ | async) ? '' : user.role_name }}

                <span *ngIf="user.city && user.city != 'false' && !(style2022$ | async)">{{ user.city }}, </span>
                <span *ngIf="user.state && user.state != 'false' && !(style2022$ | async)">{{ user.state }}</span>
              </div>
            </div>
            <div [class.row]="!(style2022$ | async)" class="col-50-maui" style="margin-bottom: 20px">
              <div class="col-xs-3 form-label">
                {{ trans.trans.Email.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.Email"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>

              <div class="col-xs-9 w-100">
                <input *ngIf="style2022$ | async" class="form-control" type="text" readonly value="{{ user.email }}" />{{ (style2022$ | async) ? '' : user.email }}
              </div>
            </div>

            <div [class.row]="!(style2022$ | async)" class="col-50-maui" style="margin-bottom: 20px">
              <div class="col-xs-3 form-label">
                {{ trans.trans.manager.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.manager"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
              <div class="col-xs-9 w-100">
                <input class="form-control" type="text" name="manager_email" value="{{ user.manager_email }}" [(ngModel)]="manager_email" />
              </div>
            </div>
            <div [class.row]="!(style2022$ | async)" class="col-50-maui" style="margin-bottom: 20px">
              <div class="col-xs-3 form-label">
                {{ trans.trans.mobile.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.mobile"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
              <div class="col-xs-9 w-100"><input class="form-control" type="text" name="mobile" value="{{ user.mobile }}" [(ngModel)]="mobile" /></div>
            </div>
            <div [class.row]="!(style2022$ | async)" class="col-50-maui" style="margin-bottom: 20px">
              <div class="col-xs-3 form-label">
                {{ trans.trans.phone.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.phone"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
              <div class="col-xs-9 w-100"><input class="form-control" type="text" name="phone" [(ngModel)]="phone" value="{{ user.phone }}" /></div>
            </div>

            <div [class.row]="!(style2022$ | async)" class="col-25-maui" style="margin-bottom: 20px">
              <div class="col-xs-3 form-label text-nowrap">
                {{ trans.trans.currencyType.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.currencyType"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
              <div class="col-xs-9 w-100">
                <select
                  name="currencyType"
                  id="currencyType"
                  type="text"
                  class="form-control profile-dropdown"
                  (change)="onCurrencySelect($event)"
                  [(ngModel)]="currencyType"
                  required
                >
                  <option value="" disabled>
                    {{ trans.trans.select.value }}
                  </option>
                  <option [ngValue]="currency.name" *ngFor="let currency of currencyTypes">{{ currency.name }}</option>
                </select>
              </div>
            </div>
            <div [class.row]="!(style2022$ | async)" class="col-25-maui" style="margin-bottom: 20px">
              <div class="col-xs-3 form-label text-nowrap">
                {{ trans.trans.numberFormat.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.numberFormat"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
              <div class="col-xs-9 w-100">
                <select name="numberFormat" type="text" class="form-control profile-dropdown" [(ngModel)]="numberFormat" required>
                  <option value="" disabled>
                    {{ trans.trans.select.value }}
                  </option>
                  <option [ngValue]="numberFormat.id" *ngFor="let numberFormat of numberFormats">{{ numberFormat.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" style="margin-bottom: 20px">
            <div [class.col-xs-12]="!(style2022$ | async)" class="col-xs-12 padding-15-x margin-15-x dflex f-row-reverse">
              <button [disabled]="user.loadImgIcon || profileForm.invalid" class="btn btn_customizable" (click)="updateUser(user)">{{ trans.trans.save.value }}</button>
              <img *ngIf="user.loadImgIcon" class="loader" src="{{ fullImagePath }}" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-40-maui">
    <div class="models_panel" style="margin-bottom: 20px" *ngIf="user">
      <div class="models_page_header_container_small">
        <div class="keywest_header_small">
          <div class="row">
            <div class="col-xs-12">
              <div [class.title]="!(style2022$ | async)" class="settings-title">
                {{ trans.trans.changePassword.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.changePassword"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <ul class="list-unstyled" id="js-alerts">
              <li *ngIf="!change_pwd">
                <i class="fa fa-lock"></i>
                <span class="ml-2">**********</span>
                <span class="change_pwd_text clickable" (click)="showPwdForm()"> {{ trans.trans.editPwd.value }}</span>
              </li>
              <li *ngIf="change_pwd">
                <div class="change-password center">
                  <form [class.m-t-md]="!(style2022$ | async)" class="form-horizontal" name="changePwdForm" #changePwdForm="ngForm" (ngSubmit)="ngOnSubmit(changePwdForm)">
                    <div class="form-group">
                      <label for="currentPassword" class="col-sm-4 control-label labelsRequired form-label w-100"
                        >{{ trans.trans.currentPassword.value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.currentPassword"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                      </label>
                      <div class="col-sm-8 w-100">
                        <input type="password" class="form-control" name="currentPassword" placeholder="{{ trans.trans.currentPassword.value }}" required ngModel />
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="newPassword" class="col-sm-4 control-label labelsRequired w-100 form-label"
                        >{{ trans.trans.newPassword.value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.newPassword"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                      </label>
                      <div class="col-sm-8 w-100">
                        <input type="password" class="form-control" name="newPassword" placeholder="{{ trans.trans.newPassword.value }}" required ngModel />
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="confirmPassword" class="col-sm-4 control-label w-100 labelsRequired form-label"
                        >{{ trans.trans.confirmPassword.value }}
                        <app-editTranslation
                          *ngIf="showTranslate"
                          [component]="trans.config.component"
                          [isTranslationV2]="true"
                          [transObj]="trans.trans.confirmPassword"
                          (callback)="getTranslations()"
                        ></app-editTranslation>
                      </label>
                      <div class="col-sm-8 w-100">
                        <input type="password" class="form-control" name="confirmPassword" placeholder="{{ trans.trans.confirmPassword.value }}" required ngModel />
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="reverse-button-layout" [ngClass]="{ 'col-sm-offset-4': !(style2022$ | async), 'col-sm-8': !(style2022$ | async) }">
                        <button type="submit" class="btn btn_customizable ml-1" [disabled]="changePwdForm.invalid">{{ trans.trans.changePassword.value }}</button>
                        <button class="btn btn_customizable" (click)="cancelChangePwd()">{{ trans.trans.Cancel.value }}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </li>
            </ul>
            <div *ngIf="loaderImg" class="m-t-lg">
              <p class="text-center">
                <img class="loader" src="{{ fullImagePath }}" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>