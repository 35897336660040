import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BenefitsImplementation } from '../../benefits.implementation';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { Subject } from 'rxjs';
import { BenefitsMenuTranslations } from './menu.translation';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { CommonService } from '@data/services/common/common.service';
import { take, takeUntil } from 'rxjs/operators';
import { DiscoveryQuestion } from '@data/services/valueprop/models/discovery-question.model';

@Component({
  selector: 'app-benefit-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BenefitsMenuComponent implements OnInit, OnDestroy {
  @Input() step2 = false;
  ngUnsubscribe = new Subject();
  showTranslate = false;
  public showDiscoveryQuestionsToggle: boolean;

  constructor(
    public trans: BenefitsMenuTranslations,
    private translationService: TranslationsV2Service,
    private CommonService: CommonService,
    public bI: BenefitsImplementation,
    private valuepropService: ValuepropService,
  ) {}

  ngOnInit() {
    this.getTranslations();
    this.CommonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.CommonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.valuepropService
      .getDiscoveryQuestions(this.bI.getValueProp(), '38')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: { result: DiscoveryQuestion[] }) => {
        const questionsArray = response.result;

        this.showDiscoveryQuestionsToggle = questionsArray.length > 0;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations() {
    let langId = sessionStorage.getItem('language_type_id');
    let langAbbr = this.translationService.getLanguageAbbr(langId);
    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans
    };
    this.translationService
      .getComponentTrans(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.trans.trans = this.CommonService.mergeObject(this.trans.trans, res);
      });
  }

  showDiscoveryQuestions() {
    this.bI.showDiscoveryQuestions = true;
  }
}
