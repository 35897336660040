import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfigurableWorkflow } from '@data/services/workflow/models/workflow.interface';
import { Subject } from 'rxjs';
import { ConfigurableWorkFlowsTranslations } from '../configurable-workflows.translation';

@Component({
  selector: 'app-workflow-table',
  templateUrl: './workflow-table.component.html',
  styleUrls: ['./workflow-table.component.scss']
})
export class WorkflowTableComponent implements OnInit, OnDestroy {
  
  @Input() trans: ConfigurableWorkFlowsTranslations;
  @Input() workflowList: ConfigurableWorkflow[];
  @Input() columns: Array<{ field: string; header: string; }> = [];

  @Output() onAdd = new EventEmitter<void>();
  @Output() onEdit = new EventEmitter<ConfigurableWorkflow>();
  @Output() onClone = new EventEmitter<ConfigurableWorkflow>();
  @Output() onDelete = new EventEmitter<ConfigurableWorkflow>();
  @Output() onStatusChange = new EventEmitter<ConfigurableWorkflow>();

  public strSearch: string = '';
  public pageLimit = 10;
  private unsubscribe$ = new Subject<void>();

  constructor() { }

  ngOnInit(): void {}

  public add() {this.onAdd.emit();}
  public edit(workflow: ConfigurableWorkflow) {this.onEdit.emit(workflow);}
  public clone(workflow: ConfigurableWorkflow) {this.onClone.emit(workflow);}
  public delete(workflow: ConfigurableWorkflow) {this.onDelete.emit(workflow);}
  public statusChange(workflow: ConfigurableWorkflow) { this.onStatusChange.emit(workflow);}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
