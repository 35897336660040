<div class="row" *ngIf="style2022$ | async">
	<div class="col-md-4 mr-auto breadcrumb-container">
	  <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
	</div>
  </div>

  <div class="row" *ngIf="!(style2022$ | async)">
	<div class="col-md-10 old-breadcrumb">
	  <a class="back-profile" [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.back_to_profile.value }}</a>
	</div>
  </div>

<p-menu #menu appendTo='body' [popup]="true" [model]="items" [styleClass]="(style2022$ | async) ? 'style-2022' : null"></p-menu>

<div class='models_panel'>
	<div class='models_page_header_container_small'>
		<div class='keywest_header'>
			<div class='row'>
				<div class='col-xs-10'>
					<div class='title'>
						{{trans.trans.survey_templates.value}}
						<app-editTranslation *ngIf="showTranslate" component="survey_template" [isTranslationV2]="true" [transObj]="trans.trans.survey_templates" (callback)="getTranslations()"></app-editTranslation>
					</div>
				</div>
				<div class='col-xs-2'>
					<a class='btn btn_customizable pull-right' *ngIf="showSurveyQuestionsList" (click)='surveyTemplateId = ""; formVisible();'>{{trans.trans.create_survey.value}}</a>
				</div>
			</div>
		</div>
	</div>

	<div class="row" [ngClass]="{ 'style-2022': style2022$ | async }" *ngIf="showSurveyQuestionsList">
		<div class='col-xs-12'>
			<table id="surveys" class="table custom-table">
				<thead>
					<tr>
						<th>{{trans.trans.name.value}}
							<app-editTranslation *ngIf="showTranslate" component="survey_template" [isTranslationV2]="true" [transObj]="trans.trans.name" (callback)="getTranslations()"></app-editTranslation>
						</th>
						<th>{{trans.trans.created_date.value}}
							<app-editTranslation *ngIf="showTranslate" component="survey_template" [isTranslationV2]="true" [transObj]="trans.trans.created_date" (callback)="getTranslations()"></app-editTranslation>
						</th>
						<th>{{trans.trans.status.value}}
							<app-editTranslation *ngIf="showTranslate" component="survey_template" [isTranslationV2]="true" [transObj]="trans.trans.status" (callback)="getTranslations()"></app-editTranslation>
						</th>
						<th></th>
					</tr>
				</thead>

				<tbody *ngIf="loadSurveysTable">
					<tr *ngFor="let survey of surveys" class="grid-row">
						<td>
							<a (click)="openSurveyTemplateDetail(survey)" class='clickable link'>{{ survey.name }}</a>
						</td>

						<td>
							{{ survey.created }}
						</td>

						<td>
							<div class="score-card-list">
								<div class="score-label">{{(+survey.is_active) ?  (trans.trans.active.value):(trans.trans.inactive.value)}}</div>
								<div class="score" [ngClass]="[+survey.is_active ? 'green' : 'red']">
									<i class="fa" [ngClass]="[+survey.is_active ? 'fa-check' : 'fa-times']"></i>
								</div>
							</div>
						</td>
						<td>
							<i class="fa fa-ellipsis-v clickable pull-right" (click)='funcBuildSubMenu(survey, $event)'></i>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<app-survey-template-create *ngIf="!showSurveyQuestionsList" [surveyTemplate]='surveyTemplate' [surveyTemplateId]="surveyTemplateId" (valueChange)="gettemplateList()" (hideform)="formVisible()"></app-survey-template-create>
</div>

