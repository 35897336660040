import { Component, OnInit, HostListener, Input, OnDestroy, ChangeDetectorRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { AddBenefitsModelComponent } from '../add-benefits-model/add-benefits-model.component';
import { AlertService } from '@services/alert.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@data/services/translation/translation.service';
import { RepSimpleViewService } from '@data/services/rep_simple_view/rep_simple_view.service';
import { Benefit, ValueProp } from '@shared/models/value-prop.model';
import { FeedbackResponse } from '@shared/models/value-prop.model';

interface Feedback {
	rating: number;
	comment: string;
}

interface ActiveType {
	name: string;
	value: string;
}

@Component({
	selector: 'app-benefits-valueprop',
	templateUrl: './benefits.component.html',
	styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit, OnDestroy {
	@Input() menuFilter = "";
	@Input() showHelp: boolean;
	@Input('data') valueProp: ValueProp;
	@Input('dealdesk') dealdesk: boolean;
	@Input() refreshBenefits: boolean;
	@Input() tabInit: number;
	@Input() contextualHelp: object;
	@Input() loadBenefit = false;
	@Input() dashboard = "";
	@Input() panel = "";
	@Input() benefitsSimple = false;
	@Input() showTranslate = false;
	@Input() selectedBenefit: Benefit;
	@Input() benefitDetail = false;
	@Input() showContinue: boolean = true;

	@Output() funcCallback = new EventEmitter();
	@Output() callbackUpdateCurrent = new EventEmitter();

	showTranslateSidebar = false;
	statusFilter = "";
	dtOptions: any = {};
	fullImagePath: string;
	modalReference: any;
	image_url: string;
	benefitsLoader = false;
	benefits: any = [];
	benefitsList: any = [];
	benefitsListExpanded: any = [];
	metricsid: number;
	metricbenefits: any = [];
	metricbenefitsLoader = false;
	isTable = true;
	inputOpened = false;
	showAllBenefitsCheck = true;
	feedback: any;
	feedbackList: FeedbackResponse;
	featuresOptions: any;
	featuresopt: any;

	subscriptionloadBenefits: Subscription;
	subscriptionupdateMetricActive: Subscription;
	subscriptiongetValuePropPhasing: Subscription;
	subscriptionupdateValuePropPhasing: Subscription;
	subscriptionsaveBenefitFeedback: Subscription;
	subscriptionreadFeedback: Subscription;
	subscriptionupdateMetricsActive: Subscription;
	subscriptionsaveChanges: Subscription;

	activeTypes = [
		{
			'name': 'Active',
			'value': '1',
		},
		{
			'name': 'N/A',
			'value': '0',

		},
		{
			'name': 'Considered Soft',
			'value': '3',

		}
	];

	showActive = true;
	showNA = false;
	showSoft = false;
	privileges: any;
	benefitOnTheFlyPrivilege = false;
	showBatchUpdateCheckboxes = false;
	batchActive = '1';
	benefitsPanel = false;
	active_count = 0;
	na_count = 0;
	soft_count = 0;
	statusActive = 0;
	statusNA = 0;
	statusSoft = 0;
	modalLoading: boolean;
	showPhasing = false;
	openDashboardUpdateBenefitCallback = new EventEmitter();
	menuBenefitList: any[] = [];
	showAll: any;
	header_color: string;

	constructor(
		private CommonService: CommonService,
		private ValuepropService: ValuepropService,
		private modalService: NgbModal,
		private NotificationService: NotificationService,
		private alertservice: AlertService,
		private cd: ChangeDetectorRef,
		private translateService: TranslateService,
		public trans: Translations,
		private RepSimpleService: RepSimpleViewService

	) {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);

		this.image_url = this.CommonService.getImageUrl();
		this.fullImagePath = this.image_url + '/images/jamaica/reload.gif';

		this.alertservice.subscribeLoggingOut().subscribe(() => {
			this.ngOnDestroy();
		})

	}

	expandAll: boolean = false;

	ngOnInit() {
		localStorage.setItem("vpdashboardMenuBottom", "benefits");
		this.header_color = localStorage.getItem("header_color");

		if (localStorage.getItem("showActive")) { this.showActive = JSON.parse(localStorage.getItem("showActive")) } else { localStorage.setItem("showActive", "true") };
		if (localStorage.getItem("showNA")) { this.showNA = JSON.parse(localStorage.getItem("showNA")) }
		if (localStorage.getItem("showSoft")) { this.showSoft = JSON.parse(localStorage.getItem("showSoft")) }

		if (this.panel !== "") { return; }



		if (localStorage.getItem("BenefitsListExpand" + this.valueProp.id)) { this.benefitsListExpanded = JSON.parse(localStorage.getItem("BenefitsListExpand" + this.valueProp.id)); }
		this.featuresOptions = sessionStorage.getItem('features');
		this.featuresopt = this.featuresOptions.split(',');

		if (!this.benefitsSimple) {
			if (this.featuresopt.indexOf('25') >= 0) {
				this.benefitsPanel = true;
			}
		}

		if (this.featuresopt.indexOf('34') >= 0) {
			this.benefitsSimple = true;
		}
		// this.valueProp;


		this.feedback = {
			rating: 0,
			comment: ""
		};

		const privs = sessionStorage.getItem('privileges');
		this.privileges = privs.split(",");
		if (this.privileges.length) {
			if (this.privileges.indexOf('6') >= 0) {
				this.benefitOnTheFlyPrivilege = true;

			}
		}

		if (this.loadBenefit) {
			this.openBenefitDetail(this.loadBenefit);
		}

		this.loadBenefits();


		// this.ValuepropService.refreshBenefits.subscribe(res => {

		// 	this.funcBuildMenu();

		// 	this.loadBenefits();
		// 	this.countStatus();
		// })
		this.funcBuildMenu();
		this.subscriptionsaveChanges = this.RepSimpleService.saveChanges.subscribe(res => {
			console.log("saveChangesCalled");
			this.funcSaveBenefits(res);
		})
	}


	ngOnDestroy(): void {
		if (this.subscriptionsaveChanges) { this.subscriptionsaveChanges.unsubscribe(); }
		if (this.subscriptionloadBenefits) { this.subscriptionloadBenefits.unsubscribe(); }
		if (this.subscriptionupdateMetricActive) { this.subscriptionupdateMetricActive.unsubscribe(); }
		if (this.subscriptiongetValuePropPhasing) { this.subscriptiongetValuePropPhasing.unsubscribe(); }
		if (this.subscriptionupdateValuePropPhasing) { this.subscriptionupdateValuePropPhasing.unsubscribe(); }
		if (this.subscriptionsaveBenefitFeedback) { this.subscriptionsaveBenefitFeedback.unsubscribe(); }
		if (this.subscriptionreadFeedback) { this.subscriptionreadFeedback.unsubscribe(); }
		if (this.subscriptionupdateMetricsActive) { this.subscriptionupdateMetricsActive.unsubscribe(); }
	}

	sleep(milliseconds) {
		var start = new Date().getTime();
		for (var i = 0; i < 1e7; i++) {
			if ((new Date().getTime() - start) > milliseconds) {
				break;
			}
		}
	}

	funcBuildMenu() {

		this.menuBenefitList = [];
		this.menuBenefitList.push({
			label: this.trans.vp_dashboard.benefits.show_all, style: { "color": this.showAll ? "green" : "black" }, command: () => {
				this.showAll = !this.showAll;
				this.showActive = true;
				this.showNA = true;
				this.showSoft = true;
				this.funcBuildMenu();
				localStorage.setItem("showAll", this.showAll.toString());
			}
		})
		this.menuBenefitList.push({
			label: this.trans.vp_dashboard.benefits.active + " (" + this.active_count + ")", style: { "color": this.showActive ? "green" : "black" }, command: () => {
				this.showActive = !this.showActive;
				if (!this.showActive) { this.showAll = false; }
				this.statusFilter = "Active"; this.funcBuildMenu();
				localStorage.setItem("showActive", this.showActive.toString());
			}
		})
		this.menuBenefitList.push({
			label: this.trans.vp_dashboard.benefits.na + " (" + this.na_count + ")", style: { "color": this.showNA ? "red" : "black" }, command: () => {
				this.showNA = !this.showNA;
				if (!this.showNA) { this.showAll = false; }
				this.statusFilter = "N/A"; this.funcBuildMenu();
				localStorage.setItem("showNA", this.showNA.toString());
			}
		})
		this.menuBenefitList.push({
			label: this.trans.vp_dashboard.benefits.soft + " (" + this.soft_count + ")", style: { "color": this.showSoft ? "orange" : "black" }, command: () => {
				this.showSoft = !this.showSoft;
				if (!this.showSoft) { this.showAll = false; }
				this.statusFilter = "Considered Soft"; this.funcBuildMenu();
				localStorage.setItem("showSoft", this.showSoft.toString());
			}
		})
		this.cd.markForCheck();

	}

	refreshTranslation() {
		this.translateService.setDefaultLang('defaultLang');
		this.trans.funcLoadOverrides(this.trans);
		this.funcBuildMenu();
	}

	funcExpand(expand?) {
		if (expand) {
			this.expandAll = expand;
		} else {
			this.expandAll = !this.expandAll;
		}
		this.benefitsListExpanded.forEach(elem => {
			elem.expand = (expand ? expand : this.expandAll);
		})
	}

	funcOpenUpdateBenefitCallback(event) {
		this.ValuepropService.loadMenuItem.next(event);
	}


	callbackUpdate() {
		this.callbackUpdateCurrent.emit();
		this.CommonService.refreshVPGroupDashboard.next();

		//
	}
	funcSaveBenefits(res?) {

		let out: any[] = [];
		for (let j = 0; j < this.benefitsList.length; j++) {
			for (let i = 0; i < this.benefitsList[j].metrics.length; i++) {
				let metric = this.benefitsList[j].metrics[i];
				if (metric.updated) {

					out.push(metric);
				}
			}
		}
		let payload = { metrics: out };

		// let out2: any[] = [];
		for (let j = 0; j < this.benefitsListExpanded.length; j++) {
			// if (!this.benefitsListExpanded[j].active) {
			let metric = this.benefitsListExpanded[j];
			let payload2 = {
				active: (this.benefitsListExpanded[j].active == true ? "1" : "0"),
				value_category_id: metric.value_category_id
			}

			this.ValuepropService.UpdateBenefitsActive(this.valueProp.id, payload2).subscribe(() => {

			});
			// }
		}

		if (!out.length) {
			this.callbackUpdateCurrent.emit();
			this.cd.detectChanges();
		} else {
			this.ValuepropService.UpdateBenefitsCurrentFuture(this.valueProp.id, payload).subscribe(() => {
				this.callbackUpdateCurrent.emit();
				this.cd.detectChanges();
			});
		}
		this.funcCallback.emit(res);
		this.NotificationService.success("Benefits Updated Successfully", false);
	}



	getBenefits(benefitStatus) {
		switch (benefitStatus) {
			case 'Active':
				return 'green';
			case 'Not a Priority':
				return 'violet';
			case 'N/A':
				return 'red';
			case 'Considered Soft':
				return 'orange';
		}
	}


	// Fix to call API's on tab first time load alone and skip next loads
	ngOnChanges() {
		// if (this.dealdesk) {
		// 	this.loadBenefits();
		// }
		// if (this.tabInit == 1) {
		// 	this.loadBenefits();
		// 	(<any>$("input:checkbox")).uniform();
		// 	this.closeSetActive();
		// }
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		let className = event.target.className.split(' ');
		if (className.indexOf('benefitStatusElement') === -1) {
			if (this.inputOpened) {
				this.inputOpened = false;
				if (this.benefitsList && this.benefitsList.length) {
					this.benefitsList.forEach(benefitCategory => {
						benefitCategory.metrics.forEach(benefit => {
							benefit['changeActive'] = false;
						});
					});
				}
			}
		}
	}


	getBenefitsListExpanded(id) {
		let out = this.benefitsListExpanded.filter(x => x.value_category_id == id);
		//
		//
		if (out[0].value_category_id == id) {
			//
			return out[0].expand;
		}
	}


	updateBenefitsLocalStorage(id) {
		if (this.benefitsListExpanded && this.benefitsListExpanded.length) {
			for (let i = 0; i < this.benefitsListExpanded.length; i++) {
				if (this.benefitsListExpanded[i].value_category_id == id) { this.benefitsListExpanded[i].expand = !this.benefitsListExpanded[i].expand; }
			}
		}
		localStorage.removeItem("BenefitsListExpand" + this.valueProp.id);
		localStorage.setItem("BenefitsListExpand" + this.valueProp.id, JSON.stringify(this.benefitsListExpanded));
	}

	setTotals(response) {
		let benefitsList = response.result.vcs;

		if (benefitsList && benefitsList.length) {
			for (let j = 0; j < benefitsList.length; j++) {
				// benefitsList[j].start = 1;
				//

				for (let i = 0; i < benefitsList[j].metrics.length; i++) {
					if (this.benefitsList[j].metrics[i]) {
						switch (benefitsList[j].metrics[i].active) {
							case "0":
								this.benefitsList[j].metrics[i].active_type = this.trans.vp_dashboard.benefits.na;
								break;
							case "1":
								this.benefitsList[j].metrics[i].active_type = this.trans.vp_dashboard.benefits.active;
								break;
							case "2":
								this.benefitsList[j].metrics[i].active_type = this.trans.vp_dashboard.benefits.not_priority;
								break;
							case "3":
								this.benefitsList[j].metrics[i].active_type = this.trans.vp_dashboard.benefits.soft;
								break;
							case "4":
								this.benefitsList[j].metrics[i].active_type = this.trans.vp_dashboard.benefits.na;
								break;
							default:
								this.benefitsList[j].metrics[i].active_type = "";
								break;
						}
					}
				}
			}
		}
	}

	setData(response) {
		if (response.result) {

			this.active_count = response.result.active_count;
			this.na_count = response.result.na_count;
			this.soft_count = response.result.soft_count;
			this.benefits = response.result.vcs;
			this.funcBuildMenu();
			//

			this.benefitsList = response.result.vcs;
			//
			this.setTotals(response);
			if (this.dealdesk) {
				this.benefitsListExpanded = [];
			}
			if (!this.benefitsListExpanded.length) {
				for (let i = 0; i < this.benefitsList.length; i++) {
					if (this.dealdesk) {
						this.benefitsListExpanded.push({ active: true, expand: false, value_category_id: this.benefitsList[i].value_category_id });
					} else {
						this.benefitsListExpanded.push({ active: true, expand: false, value_category_id: this.benefitsList[i].value_category_id });
					}
				}
				//
			} else {
				// we have an expanded benefits list

				// clear the list, incase we have new ones
				for (let i = 0; i < this.benefitsList.length; i++) {
					this.benefitsList[i].expand = false;
					this.benefitsList[i].active = true;
				}

				// first copy expand to benefitslist
				for (let i = 0; i < this.benefitsList.length; i++) {
					this.benefitsList[i].metrics = this.benefitsList[i].metrics.filter(x => x.active !== "4");
					//
					for (let e = 0; e < this.benefitsListExpanded.length; e++) {
						if (this.benefitsList[i].value_category_id == this.benefitsListExpanded[e].value_category_id) {
							this.benefitsList[i].expand = this.benefitsListExpanded[e].expand;
						}
					}
				}
				this.benefitsListExpanded = [];
				for (let i = 0; i < this.benefitsList.length; i++) {
					this.benefitsListExpanded.push({ active: this.benefitsList[i].active, expand: this.benefitsList[i].expand, value_category_id: this.benefitsList[i].value_category_id });
				}
			}

			// if (this.benefitsList && this.benefitsList.length) { this.countStatus(); }
			if (this.benefits.length) {
				for (let m = 0; m < this.benefits.length; m++) {
					// this.benefits[m].metrics = this.benefits[m].metrics.filter(x => x.active !== 4);
					//
					let out = [];
					for (let t = 1; t <= this.benefits[m].term; t++) {
						out.push({ label: "Year " + t, value: this.benefits[m]["vc_yr" + t + "_total_fmt"] })
						for (let mm = 0; mm < this.benefits[m].metrics.length; mm++) {
							let out2 = [];
							for (let tt = 1; tt <= this.benefits[m].term; tt++) {
								out2.push({ label: "Year " + tt, value: this.benefits[m].metrics[mm]["year" + tt + "_benefits_fmt"] })
							}
							this.benefits[m].metrics[mm].years = out2;
						}


					}
					this.benefits[m].years = out;
				}
			}
			//
			if (this.benefitsList && this.benefitsList.length) {
				for (let j = 0; j < this.benefitsList.length; j++) {
					this.benefitsList[j].start = 1;
					//

					for (let i = 0; i < this.benefitsList[j].metrics.length; i++) {
						this.benefitsList[j].metrics[i].updateBatch = false;
						switch (this.benefitsList[j].metrics[i].active) {
							case "0":
								this.benefitsList[j].metrics[i].active_type = "N/A";
								break;
							case "1":
								this.benefitsList[j].metrics[i].active_type = "Active";
								break;
							case "2":
								this.benefitsList[j].metrics[i].active_type = "Not a Priority";
								break;
							case "3":
								this.benefitsList[j].metrics[i].active_type = "Considered Soft";
								break;
							case "4":
								this.benefitsList[j].metrics[i].active_type = "N/A";
								break;
							default:
								this.benefitsList[j].metrics[i].active_type = "";
								break;
						}
					}
				}

			}
		}
		this.countStatus();

		// this.funcBuildMenu();
		this.benefitsLoader = false;
	}

	funcGetActiveType(active) {
		switch (active) {
			case "0":
				return "N/A";
			case "1":
				return "Active";
			case "2":
				return "Not a Priority";
			case "3":
				return "Considered Soft";
			case "4":
				return "N/A";
		}
	}

	loadBenefits() {
		this.benefitsList = [];
		this.benefitsLoader = true;
		//this.benefitsListExpanded = [];
		let searchLimit = this.valueProp.id;
		if (searchLimit) {
			this.benefitsLoader = true;
			if (this.showAllBenefitsCheck) {
				this.subscriptionloadBenefits = this.ValuepropService.getValuePropAllBenefitsTurboSummed(parseInt(searchLimit), 1, 0).subscribe((response) => {
					this.setData(response);
				});
			} else {
				this.subscriptionloadBenefits = this.ValuepropService.getValuePropAllBenefitsTurboSummed(parseInt(searchLimit), 3, 1).subscribe((response) => {
					this.setData(response);

				});
			}
			// this.subscriptionloadBenefits
		}
	}
	showAllBenefits() {
		this.benefitsLoader = true;
		this.showAllBenefitsCheck = !this.showAllBenefitsCheck;
		// setTimeout(function () {
		this.loadBenefits();
		// }, 300);
		this.funcBuildMenu();

	}

	countStatus() {
		let ticks = 0;
		this.statusActive = null;
		this.statusNA = 0;
		this.statusSoft = 0;
		let tmpActive = null;
		this.cd.markForCheck();

		let interval = setInterval(() => {
			this.statusActive = null;
			this.statusNA = 0;
			this.statusSoft = 0;
			tmpActive = 0;

			ticks++;
			// the following is required, otherwise the view will not be updated
			// this.cd.markForCheck();
			this.benefitsList.forEach(benefitList => {
				let benefitGroup = benefitList['metrics'];
				benefitGroup.forEach(benefit => {
					if (benefit.active == 0) { this.statusNA++; }
					if (benefit.active == 1) { tmpActive++; }
					if (benefit.active == 3) { this.statusSoft++; }
					// this.cd.detectChanges();
				});
				// this.cd.markForCheck();

			});

			if (ticks > 1) {

				clearInterval(interval);
				// this.funcBuildMenu();
				this.statusActive = tmpActive;
				this.cd.markForCheck();

			};
			// interval
		}, 1000);

		// this.menuBenefitList = [];
		//

		//
		//
		//
		// if (this.menuBenefitList.length) {
		// 	this.menuBenefitList[0].label = this.trans.vp_dashboard.benefits.active + " (" + this.statusActive + ")";
		// 	this.menuBenefitList[1].label = this.trans.vp_dashboard.benefits.na + " (" + this.statusNA + ")";
		// 	this.menuBenefitList[2].label = this.trans.vp_dashboard.benefits.soft + " (" + this.statusSoft + ")";
		// } else {
		// this.funcBuildMenu();
		// }

	}

	changeView(type) {
		// this.benefitsPanel = !this.benefitsPanel;
		if (type == 'table') {
			this.isTable = true;
		} else {
			this.isTable = false;
		}
	}
	openActiveTypes(benefit) {
		if (this.valueProp['vp_can_edit']) {
			this.inputOpened = true;
			this.selectedBenefit = benefit;
			benefit.changeActive = true;
		}
	}

	statusChange(benefit) {
		localStorage.setItem("vpdashboardMenuBottom", "benefits");
		this.statusActive = 0;
		// this.menuBenefitList = [];
		this.cd.detectChanges();

		if (this.inputOpened) {
			const payload = {
				"value_prop_metric_id": benefit.id,
				"active": benefit.active
			}
			this.benefitsLoader = true;
			this.inputOpened = false;
			this.subscriptionupdateMetricActive = this.ValuepropService.updateMetricActive(this.valueProp.id, payload).subscribe((response) => {
				if (response.result && (response.result.success !== false)) {
					// if (this.dealdesk) {
					// this.loadBenefits();
					// this.countStatus();
					// benefit.
					// }
					benefit.active_type = this.funcGetActiveType(benefit.active);
					benefit.changeActive = false;
					this.ValuepropService.refreshDashboard.next();
					this.NotificationService.success("Benefit status updated Successfully", false);
				}
				else if (response.result.success === false) {
					benefit.changeActive = false;
					this.NotificationService.error(response.result.message, false); // Params {message, islogout}
				}
				this.benefitsLoader = false;
				// this.selectedBenefit = {};
			});
		}
	}


	openBenefitDetail(benefit) {
		this.loadBenefit = true;
		this.selectedBenefit = benefit;
		this.ValuepropService.gotoMenuItem.next({ panel: "vpdashboardMenuBottom", menu: "benefit_detail", data: benefit })

		//
		// this.benefitDetail = true;
		// this.selectedBenefit = benefit;
		// this.cd.detectChanges();

	}
	closeBenefitDetail() {

		this.benefitDetail = false;
		this.selectedBenefit = null;
		this.loadBenefits();
	}

	openAddPhasingFunc(event) {

		this.openAddPhasing(event.content, event.id);
		this.cd.detectChanges();
	}


	openAddPhasing(content, id) {
		this.showPhasing = !this.showPhasing;
		// this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		// this.modalReference.result.then((result) => {
		// }, (reason) => {
		// });
		this.metricsid = id;
		this.metricbenefits = [];
		this.metricbenefitsLoader = true;
		this.subscriptiongetValuePropPhasing = this.ValuepropService.getValuePropPhasing(this.valueProp.id, id).subscribe((response) => {
			if (response.result) {
				let phasing = response.result.metric_phasing;
				this.metricbenefits = Object.keys(phasing).map(function (key) {
					return {
						key: key,
						value: phasing[key]
					};
				});

			}
			this.metricbenefitsLoader = false;
			this.cd.detectChanges();
		});
	}


	updatePhasing(apply_all) {

		// for (let i = 0; i < this.benefitsList.length; i++) {
		// 	this.benefitsListExpanded.push({ expand: this.benefitsList[i].expand });
		// 	// this.benefitsListExpanded['i'].expand = false;
		// }
		//
		if (apply_all > 0) {
			let res = confirm("Are you sure?");
			if (!res) { this.modalLoading = false; return; }
		}
		this.modalLoading = true;

		let MetricsPhasingModel = {
			"value_prop_id": this.valueProp.id,
			"value_prop_metric_id": this.metricsid,
			"apply_all": apply_all
		};

		for (let i = 0; i < this.metricbenefits.length; i++) {
			let key = this.metricbenefits[i]['key'];
			let value = this.metricbenefits[i]['value'];
			MetricsPhasingModel[key] = value;
		}

		this.subscriptionupdateValuePropPhasing = this.ValuepropService.updateValuePropPhasing(MetricsPhasingModel, this.valueProp.id).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				this.modalLoading = false;
				// this.modalReference.close();
				this.showPhasing = false;
				this.NotificationService.success("Phasing updated Successfully", false);
				this.ngOnInit();
			}
			else if (response.result.success === false) {
				this.modalLoading = false;
				this.showPhasing = false;
				this.NotificationService.error(response.result.message, false); // Params {message, islogout}
			}
			this.ValuepropService.refreshDashboard.next();

		});
	}

	changePhase(value, element) {
		this.metricbenefits[value] = element.from;
	}


	// openAddBenefit() {
	// 	const modalReference = this.modalService.open(AddBenefitsModelComponent, { windowClass: 'deleteModal', size: 'lg', backdrop: "static", keyboard: false });
	// 	modalReference.componentInstance.valuePropId = this.valueProp.id;
	// 	// if (benefit) {
	// 	//   modalReference.componentInstance.selectedBenefit = benefit;
	// 	// }
	// 	modalReference.result.then((result) => {
	// 	}, (reason) => {
	// 	});
	// }

	openBenefitRating(content) {
		this.modalReference = this.modalService.open(content, { windowClass: 'deleteModal', backdrop: "static", keyboard: false });
		this.modalReference.result.then(() => {
		}, () => {
		});
	}

	saveBenefitRating() {

		let ratingObj = {
			account_solution_metric_id: this.selectedBenefit.account_solution_metric_id,
			score: this.feedback.rating,
			comment: this.feedback.comment
		};

		this.subscriptionsaveBenefitFeedback = this.ValuepropService.saveBenefitFeedback(ratingObj, this.selectedBenefit.id).subscribe(() => {
			this.modalReference.close();
			this.ngOnInit();
			this.ValuepropService.refreshDashboard.next('refreshDashboard');
		});

	}

	deleteCustomBenefit(benefit) {

		this.ValuepropService.deleteCustomBenefit(this.valueProp.id, benefit.value_prop_metric_id).subscribe((response) => {
			if (response.result && (response.result.success !== false)) {
				// this.ValuepropService.refreshDashboard.next('benefitChange');
				this.NotificationService.success("Successfully deleted your benefit", false);
				this.loadBenefits();
			}
			else if (response.result.success === false) {
				this.NotificationService.error(response.result.message, false); // Params {message, islogout}
			}
			else if (response.result.errors) {
				this.NotificationService.error("Something went wrong: " + response.result.errors.reason, false);
			}
		},
			(error) => {
				this.NotificationService.error("Something went wrong: " + error.result, false);
			});
	}

	showFeedback(content, benefitId) {
		this.modalService.open(content, { windowClass: 'deleteModal', size: 'lg', backdrop: "static", keyboard: false });
		this.subscriptionreadFeedback = this.ValuepropService.readFeedback(benefitId).subscribe(response => {
			if (response.result) {
				this.feedbackList = response.result;
			}
		});
	}

	editBenefit(selectedBenefit) {
		const modalReference = this.modalService.open(AddBenefitsModelComponent, { windowClass: 'deleteModal', size: 'lg', backdrop: "static", keyboard: false });
		modalReference.componentInstance.selectedBenefit = selectedBenefit;
		modalReference.componentInstance.valuePropId = this.valueProp.id;
		modalReference.result.then(() => {
		}, () => {
		});
	}

	openSetActive() {
		this.showBatchUpdateCheckboxes = true;
		if (!this.showAllBenefitsCheck) {
			this.showAllBenefits();
		}
	}

	closeSetActive() {
		this.showBatchUpdateCheckboxes = false;
	}

	statusBatchUpdate() {
		let payloadArray = [];
		this.benefitsLoader = true;

		this.cd.detectChanges();
		this.benefitsList.forEach(benefitList => {
			let benefitGroup = benefitList['metrics'];
			benefitGroup.forEach(benefit => {

				if (benefit.updateBatch) {
					let out = {
						'value_prop_metric_id': benefit['id'],
						'active': this.batchActive
					};

					payloadArray.push(out);
				}
			});
		});

		const payload = {
			"value_prop_metrics": payloadArray
		}


		this.subscriptionupdateMetricsActive = this.ValuepropService.updateMetricsActive(this.valueProp.id, payload).subscribe((response) => {

			if (response.result && (response.result.success !== false)) {
				// if (this.dealdesk) {
				this.loadBenefits();
				// }
				this.ValuepropService.refreshDashboard.next();
				this.NotificationService.success("Benefit status updated Successfully", false);
				this.benefitsLoader = false;
				this.closeSetActive();

			}
			else {
				if (response.result && response.result.message) { this.NotificationService.error(response.result.message, false); }
				this.benefitsLoader = false;
				this.closeSetActive();


			}
		});
	}

}
